import {
  IBaseSelectItem,
  ISelectSection,
  emptySelectionItem,
} from '@kontent-ai/component-library/Selects';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { getSortedContentTypesByName } from '../../../contentModels/shared/utils/typeUtils.ts';

const allContentTypesOption: IBaseSelectItem = {
  ...emptySelectionItem,
  label: 'All content types',
};

export const getContentPaceTypeSelectOptions = (
  contentTypes: ReadonlyArray<IContentType>,
): ReadonlyArray<IBaseSelectItem | ISelectSection<IBaseSelectItem>> => {
  const contentTypesSection: ISelectSection<IBaseSelectItem> = {
    id: 'content-types-list',
    type: 'section',
    items: getSortedContentTypesByName(contentTypes).map((type) => ({
      id: type.id,
      label: type.name,
    })),
  };

  return [allContentTypesOption, contentTypesSection];
};
