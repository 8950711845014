import { memoize } from '@kontent-ai/memoization';
import {
  ICollectionGroupRoles,
  IProjectContributorRole,
} from '../../../../data/models/users/ProjectContributor.ts';
import {
  roleBuilderAnyCollectionOptionId,
  roleBuilderAnyLanguageOptionId,
} from '../constants/roleBuilder.ts';

const getInitialUserRoles = memoize.maxOne(
  (userRoles: readonly IProjectContributorRole[]): readonly IProjectContributorRole[] =>
    userRoles.map((role) =>
      role.languageIds.length
        ? role
        : {
            ...role,
            languageIds: [roleBuilderAnyLanguageOptionId],
          },
    ),
);

export const getInitialUserDetailCollectionGroups = memoize.maxOne(
  (collectionGroups: readonly ICollectionGroupRoles[]): readonly ICollectionGroupRoles[] =>
    collectionGroups.map((group) =>
      group.collectionIds.length === 0
        ? {
            roles: getInitialUserRoles(group.roles),
            collectionIds: [roleBuilderAnyCollectionOptionId],
          }
        : {
            ...group,
            roles: getInitialUserRoles(group.roles),
          },
    ),
);
