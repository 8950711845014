import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { pasteContent as pasteContentToContent } from '../../../utils/import/editorImportUtils.ts';
import { ClipboardPlugin } from '../ClipboardPlugin.tsx';

export const editorClipboardApi: EditorApiImplementation<ClipboardPlugin> = {
  pasteContent(api, editorState, selection, pastedContent, allowUndo = true) {
    api.getStatistics?.()?.contentPasted(pastedContent.content);

    return api.executeContentChange(
      editorState,
      selection,
      (input) => pasteContentToContent(input, pastedContent.content),
      'insert-fragment',
      allowUndo,
    );
  },
};
