import { useCallback, useRef } from 'react';
import { UseCustomHandleClickOutside } from '../../applications/itemEditor/features/ContentItemEditing/components/editingActions/EditingLeafActionForm.tsx';

type UseClickOutsideCallbackParams = {
  initialOnClickOutside: () => void;
  initialShouldCloseOnClickOutside?: (element: HTMLElement) => boolean;
};

export type UseClickOutsideCallbackReturnValue = [
  () => void,
  (element: HTMLElement) => boolean,
  UseCustomHandleClickOutside,
];

export const useClickOutsideCallbackState = (
  props: UseClickOutsideCallbackParams,
): UseClickOutsideCallbackReturnValue => {
  const onClickOutsideCallbackRef = useRef<() => void>(props.initialOnClickOutside);
  const shouldCloseOnClickOutsideCallbackRef = useRef<
    ((element: HTMLElement) => boolean) | undefined
  >(props.initialShouldCloseOnClickOutside);

  const setNewCallbacks = useCallback(
    (
      newOnClickOutsideCallback: () => void,
      newShouldCloseOnClickOutsideCallback?: (element: HTMLElement) => boolean,
    ) => {
      onClickOutsideCallbackRef.current = newOnClickOutsideCallback;
      shouldCloseOnClickOutsideCallbackRef.current = newShouldCloseOnClickOutsideCallback;
    },
    [],
  );

  const onClickOutsideCallback = useCallback(() => onClickOutsideCallbackRef.current(), []);

  const shouldCloseOnClickOutsideCallback = useCallback(
    (element: HTMLElement) => shouldCloseOnClickOutsideCallbackRef.current?.(element) ?? true,
    [],
  );

  return [onClickOutsideCallback, shouldCloseOnClickOutsideCallback, setNewCallbacks];
};
