import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { openInventoryWithFilter } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { FilterRouteParams } from '../constants/routePaths.ts';

export const FilterSmartLink: React.FC = () => {
  const { projectId, filterId } = useParams<FilterRouteParams>();

  const params = useMemo(
    () => ({
      projectId,
      filterId,
    }),
    [projectId, filterId],
  );

  useThunkPromise(openInventoryWithFilter, params);

  return <SmartLink />;
};
