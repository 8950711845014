import { assert, Collection } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionAdminServerModel } from '../../../../../repositories/serverModels/SubscriptionAdminServerModel.type.ts';
import {
  ISubscriptionAdmin,
  createSubscriptionAdminDomainModel,
} from '../../../shared/models/SubscriptionAdmin.ts';
import { ILoadSubscriptionUsage } from '../../../shared/types/ILoadSubscriptionUsage.type.ts';
import {
  Subscription_RevokeInvitation_Failed,
  Subscription_RevokeInvitation_Finished,
  Subscription_RevokeInvitation_Started,
} from '../../constants/actionTypes.ts';

interface IRevokeAdminDependencies {
  readonly subscriptionRepository: {
    readonly revokeAdmin: (
      subscriptionId: Uuid,
      adminToRevoke: UserId,
    ) => Promise<ISubscriptionAdminServerModel[]>;
  };
  readonly loadSubscriptionUsage: ILoadSubscriptionUsage;
  readonly loadSubscriptionUsers: (subscriptionId: Uuid) => ThunkPromise;
}

const start = (adminId: UserId) =>
  ({
    type: Subscription_RevokeInvitation_Started,
    payload: {
      adminId,
    },
  }) as const;

const success = (revokedAdmin: ISubscriptionAdmin, subscriptionId: Uuid) =>
  ({
    type: Subscription_RevokeInvitation_Finished,
    payload: {
      revokedAdmin,
      subscriptionId,
    },
  }) as const;

const failure = (adminId: UserId) =>
  ({
    type: Subscription_RevokeInvitation_Failed,
    payload: {
      adminId,
    },
  }) as const;

export type RevokeAdminActionsType = ReturnType<typeof success | typeof start | typeof failure>;

export const revokeAdminCreator =
  (dependencies: IRevokeAdminDependencies) =>
  (subscriptionId: Uuid, userId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(start(userId));

    try {
      const revokedAdminServerModel = await dependencies.subscriptionRepository.revokeAdmin(
        subscriptionId,
        userId,
      );

      await Promise.all([
        dispatch(dependencies.loadSubscriptionUsage(subscriptionId)),
        dispatch(dependencies.loadSubscriptionUsers(subscriptionId)),
      ]);

      const firstAdmin = Collection.getFirst(revokedAdminServerModel);
      assert(!!firstAdmin, () => 'Subscription admin is falsy.');
      dispatch(success(createSubscriptionAdminDomainModel(firstAdmin), subscriptionId));
    } catch (error) {
      dispatch(failure(userId));
      throw error;
    }
  };
