export const ContentTypeSnippet_Creator_CreationFinished =
  'ContentTypeSnippet_Creator_CreationFinished';
export const ContentTypeSnippet_Creator_CreationStarted =
  'ContentTypeSnippet_Creator_CreationStarted';
export const ContentTypeSnippet_Creator_InitFinished = 'ContentTypeSnippet_Creator_InitFinished';
export const ContentTypeSnippet_Creator_InitStarted = 'ContentTypeSnippet_Creator_InitStarted';
export const ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning =
  'ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning';
export const ContentTypeSnippet_Editor_ChangeCodename = 'ContentTypeSnippet_Editor_ChangeCodename';
export const ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename =
  'ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename';
export const ContentTypeSnippet_Editor_ChangeName = 'ContentTypeSnippet_Editor_ChangeName';
export const ContentTypeSnippet_Editor_ChangeTypeElementCodename =
  'ContentTypeSnippet_Editor_ChangeTypeElementCodename';
export const ContentTypeSnippet_Editor_FocusFirstInvalidElement =
  'ContentTypeSnippet_Editor_FocusFirstInvalidElement';
export const ContentTypeSnippet_Editor_HideConfiguration =
  'ContentTypeSnippet_Editor_HideConfiguration';
export const ContentTypeSnippet_Editor_InitFinished = 'ContentTypeSnippet_Editor_InitFinished';
export const ContentTypeSnippet_Editor_InitStarted = 'ContentTypeSnippet_Editor_InitStarted';
export const ContentTypeSnippet_Editor_MoveTypeElement =
  'ContentTypeSnippet_Editor_MoveTypeElement';
export const ContentTypeSnippet_Editor_MultipleChoiceCreateOption =
  'ContentTypeSnippet_Editor_MultipleChoiceCreateOption';
export const ContentTypeSnippet_Editor_MultipleChoiceDeleteOption =
  'ContentTypeSnippet_Editor_MultipleChoiceDeleteOption';
export const ContentTypeSnippet_Editor_MultipleChoiceMoveOption =
  'ContentTypeSnippet_Editor_MultipleChoiceMoveOption';
export const ContentTypeSnippet_Editor_MultipleChoiceOptionDeselected =
  'ContentTypeSnippet_Editor_MultipleChoiceOptionDeselected';
export const ContentTypeSnippet_Editor_MultipleChoiceOptionDropped =
  'ContentTypeSnippet_Editor_MultipleChoiceOptionDropped';
export const ContentTypeSnippet_Editor_MultipleChoiceOptionLabelChanged =
  'ContentTypeSnippet_Editor_MultipleChoiceOptionLabelChanged';
export const ContentTypeSnippet_Editor_MultipleChoiceOptionPickedUp =
  'ContentTypeSnippet_Editor_MultipleChoiceOptionPickedUp';
export const ContentTypeSnippet_Editor_MultipleChoiceOptionSelected =
  'ContentTypeSnippet_Editor_MultipleChoiceOptionSelected';
export const ContentTypeSnippet_Editor_MultipleChoiceUpdateOption =
  'ContentTypeSnippet_Editor_MultipleChoiceUpdateOption';
export const ContentTypeSnippet_Editor_RemoveTypeElement =
  'ContentTypeSnippet_Editor_RemoveTypeElement';
export const ContentTypeSnippet_Editor_SavingFinished = 'ContentTypeSnippet_Editor_SavingFinished';
export const ContentTypeSnippet_Editor_SavingStarted = 'ContentTypeSnippet_Editor_SavingStarted';
export const ContentTypeSnippet_Editor_ShowConfiguration =
  'ContentTypeSnippet_Editor_ShowConfiguration';
export const ContentTypeSnippet_Editor_TypeElementCreated =
  'ContentTypeSnippet_Editor_TypeElementCreated';
export const ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd =
  'ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd';
export const ContentTypeSnippet_Editor_TypeElementDropped =
  'ContentTypeSnippet_Editor_TypeElementDropped';
export const ContentTypeSnippet_Editor_TypeElementPickedUp =
  'ContentTypeSnippet_Editor_TypeElemnetPickedUp';
export const ContentTypeSnippet_Editor_TypeElementUpdated =
  'ContentTypeSnippet_Editor_TypeElementUpdated';
export const ContentTypeSnippet_Editor_TypeElementValidated =
  'ContentTypeSnippet_Editor_TypeElementValidated';
export const ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished =
  'ContentTypeSnippet_Listing_ArchiveContentTypesFinished';
export const ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted =
  'ContentTypeSnippet_Listing_ArchiveContentTypesStarted';
export const ContentTypeSnippet_Listing_FilterSearchPhraseChanged =
  'ContentTypeSnippet_Listing_FilterSearchPhraseChanged';
export const ContentTypeSnippet_Listing_ResetOperationState =
  'ContentTypeSnippet_Listing_ResetOperationState';
export const ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished =
  'ContentTypeSnippet_Listing_RestoreContentTypesFinished';
export const ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted =
  'ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted';
export const ContentTypeSnippet_Listing_RouteEntered = 'ContentTypeSnippet_Listing_RouteEntered';
export const ContentTypeSnippet_Listing_SelectedTypeSnippetsUpdated =
  'ContentTypeSnippet_Listing_SelectedTypesUpdated';
export const ContentTypeSnippet_Listing_InitStarted = 'ContentTypeSnippet_Listing_InitStarted';
export const ContentTypeSnippet_Listing_InitFinished = 'ContentTypeSnippet_Listing_InitFinished';
