import { IListingContentItem } from '../../data/models/listingContentItems/IListingContentItem.ts';
import { DefaultVariantId } from '../constants/variantIdValues.ts';
import { ContentItemId } from '../models/ContentItemId.ts';

export const getListingContentItem = (
  { itemId, variantId }: ContentItemId,
  selectedLanguageId: Uuid,
  byId: Immutable.Map<Uuid, IListingContentItem>,
  defaultById: Immutable.Map<Uuid, IListingContentItem>,
): IListingContentItem | null => {
  const data =
    variantId === DefaultVariantId && selectedLanguageId !== variantId ? defaultById : byId;

  return data.get(itemId) ?? null;
};
