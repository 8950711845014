import { Action } from '../../../../@types/Action.type.ts';
import {
  Project_CloneProject_Failed,
  Project_Clone_ShowModal,
  Project_Create_ShowModal,
  Project_NewProject_ClearCopyProjectDataInfo,
  Project_NewProject_CopyProjectDataInfoObtained,
} from '../../constants/projectActionTypes.ts';
import { ICopyProjectDataInfo } from '../../models/ICopyProjectDataInfo.type.ts';

export const copyProjectDataInfo = (
  state: ICopyProjectDataInfo | null = null,
  action: Action,
): ICopyProjectDataInfo | null => {
  switch (action.type) {
    case Project_NewProject_CopyProjectDataInfoObtained:
      return action.payload.copyProjectDataInfo;

    case Project_Create_ShowModal:
    case Project_Clone_ShowModal:
    case Project_CloneProject_Failed:
    case Project_NewProject_ClearCopyProjectDataInfo:
      return null;

    default:
      return state;
  }
};
