import { notNullNorUndefined } from '@kontent-ai/utils';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { RichTextInputCommand } from '../../../keyboardShortcuts/api/EditorCommand.ts';
import { ToolbarButtonInputProps, getToolbarButtonProps } from '../../utils/toolbarUtils.ts';
import { EditorToolbarMenu, EditorToolbarMenuItem } from './EditorToolbarMenu.tsx';

export type CommandToolbarMenuItem = Pick<
  EditorToolbarMenuItem,
  'label' | 'shortcuts' | 'uiAction'
> &
  Partial<Pick<EditorToolbarMenuItem, 'disabled' | 'tooltipText'>> & {
    readonly command: RichTextInputCommand;
    readonly iconName?: IconName;
  };

type Props = Pick<
  ToolbarButtonInputProps,
  | 'entityMap'
  | 'fullBlockTypesAtSelection'
  | 'hidesDisallowedFeatures'
  | 'limitations'
  | 'onCommand'
> & {
  readonly items: ReadonlyArray<CommandToolbarMenuItem>;
};

export const EditorCommandMenu = ({
  entityMap,
  fullBlockTypesAtSelection,
  hidesDisallowedFeatures,
  items,
  limitations,
  onCommand,
}: Props) => {
  const inputProps: ToolbarButtonInputProps = {
    currentVisualStyle: null,
    entityMap,
    fullBlockTypesAtSelection,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection: null,
    onCommand,
    selectionContainsText: null,
  };

  const menuItems = items
    .map((item) => getToolbarMenuItem(item, inputProps))
    .filter(notNullNorUndefined);

  return <EditorToolbarMenu items={menuItems} />;
};

export const getToolbarMenuItem = (
  { command, disabled, tooltipText, iconName, label, shortcuts, uiAction }: CommandToolbarMenuItem,
  inputProps: ToolbarButtonInputProps,
): EditorToolbarMenuItem | null => {
  const buttonProps = getToolbarButtonProps(command, inputProps);

  return (
    buttonProps && {
      ...buttonProps,
      disabled: disabled || buttonProps.disabled,
      tooltipText:
        tooltipText ||
        (buttonProps.disabled || buttonProps.isViolated ? buttonProps.tooltipText : undefined),
      iconName,
      id: command,
      label,
      shortcuts,
      uiAction,
    }
  );
};
