import { Pathname } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStep,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import {
  canUpdateContentGroup,
  hasActiveVariantCapabilityForEditedItem,
} from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getSimpleTextValue } from '../../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { isUrlSlugElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getEditedContentItemType } from '../../../../selectors/getEditedContentItemType.ts';
import { getContentGroupForElement } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { AutoGenerateUrlSlugAction } from './autoGenerateUrlSlug.ts';

type AutoGenerateAllUrlSlugsDependencies = {
  readonly autoGenerateUrlSlug: AutoGenerateUrlSlugAction;
};

const canUpdateElementGroup = (elementId: Uuid, state: IStore): boolean => {
  const { editedContentItem, editorUi, loadedContentItemTypes } = state.contentApp;
  if (!editedContentItem) {
    return false;
  }

  const contentType = loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId);

  return canUpdateContentGroup(
    getContentGroupForElement(contentType?.contentElements ?? [], elementId),
    editorUi,
  );
};

const canUpdateVariant = (state: IStore): boolean => {
  const { editedContentItemVariant } = state.contentApp;
  if (!editedContentItemVariant) {
    return false;
  }

  const canUpdate = hasActiveVariantCapabilityForEditedItem(
    ActiveCapabilityType.UpdateContent,
    state,
  );
  const isVariantInArchivedStep = isArchivedWorkflowStep(
    editedContentItemVariant.assignment.workflowStatus,
  );
  const isVariantPublishedOrScheduled = isPublishingStepSelected(
    editedContentItemVariant.assignment,
  );

  return canUpdate && !isVariantInArchivedStep && !isVariantPublishedOrScheduled;
};

export type AutoGenerateAllUrlSlugsAction = (
  params: Readonly<{
    overwriteExisting: boolean;
    pathname: Pathname;
  }>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

export const createAutoGenerateAllUrlSlugsAction =
  (deps: AutoGenerateAllUrlSlugsDependencies): AutoGenerateAllUrlSlugsAction =>
  ({ overwriteExisting, pathname }, abortSignal) =>
  async (dispatch, getState) => {
    const state = getState();
    if (!canUpdateVariant(state)) {
      return;
    }

    const isDefaultLanguage = getSelectedLanguageIdOrThrow(state) === DefaultVariantId;
    const editedContentItemType = getEditedContentItemType(state);

    const nonLocalizableElementIds = new Set(
      editedContentItemType?.contentElements
        .filter((el) => el.isNonLocalizable)
        .map((el) => el.elementId),
    );

    const { editedContentItemVariantElements } = state.contentApp;
    const elementsToUpdate = editedContentItemVariantElements.filter((element) => {
      if (
        (isDefaultLanguage || !nonLocalizableElementIds.has(element.elementId)) &&
        isUrlSlugElement(element)
      ) {
        return (
          element.mode === UrlSlugMode.Auto &&
          (overwriteExisting || !getSimpleTextValue(element._editorState)) &&
          canUpdateElementGroup(element.elementId, state)
        );
      }
      return false;
    });

    // Due to the content type limitations, there will be 1 url slug at maximum.
    for (const elementData of elementsToUpdate) {
      await dispatch(
        deps.autoGenerateUrlSlug(
          {
            elementId: elementData.elementId,
            pathname,
          },
          abortSignal,
        ),
      );
    }
  };
