import { CustomApp } from '../../../../data/models/customApps/CustomApp.ts';
import { CustomAppFormShape } from '../models/CustomAppFormShape.type.ts';
import { handleAllRolesRoleOnSubmit } from './roleUtils.ts';

export const createCustomAppFromFormValues = (
  formValues: CustomAppFormShape,
  originalCustomApp: CustomApp,
): CustomApp => {
  return {
    id: originalCustomApp.id,
    config: formValues.config,
    name: formValues.name,
    sourceUrl: formValues.sourceUrl || '',
    codename: originalCustomApp.codename,
    allowedRoles: handleAllRolesRoleOnSubmit(formValues.allowedRoleIds),
  };
};
