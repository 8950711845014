import styled, { css } from 'styled-components';
import { Paper } from '../../../containers/Paper/Paper.tsx';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { CountStatusStyle } from '../types.ts';

const countStatusBorderRadius = BorderRadius.S;
const countStatusPadding = Spacing.XS;
const countStatusMinWidth = Size.XS;

type StyledCountStatusProps = Readonly<{
  $color: BaseColor;
  countStatusStyle: CountStatusStyle;
  isFocusVisible?: boolean;
}>;

export const StyledCountStatus = styled.span<StyledCountStatusProps>`
  display: inline-flex;
  align-items: normal;
  justify-content: center;
  border-radius: ${px(countStatusBorderRadius)};
  height: ${px(Size.XS)};
  min-width: ${px(countStatusMinWidth)};

  ${({ countStatusStyle, $color }) =>
    (countStatusStyle === 'default' || countStatusStyle === 'inverse') &&
    css`
    border: ${px(borderWidthDefault)} solid ${$color};
    padding: 0 ${px(countStatusPadding)};
  `};

  &:focus {
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }
`;

export const StyledCountStatusFloating = styled(Paper).attrs({
  component: 'span',
  paddingX: countStatusPadding,
  borderRadius: countStatusBorderRadius,
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${px(countStatusMinWidth)};
  text-align: center;
  z-index: -1;
`;
