import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { RegularWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';

export const isRoleUsedByWorkflowStep = (
  role: IRoleWithSettings,
  workflows: ReadonlyMap<Uuid, Workflow>,
): boolean =>
  Array.from(workflows.values()).some(
    (workflow) =>
      workflow.steps.some((step: RegularWorkflowStep) => step.roleIds.has(role.id)) ||
      workflow.archivedStep.roleIds.has(role.id) ||
      workflow.scheduledStep.roleIds.has(role.id) ||
      workflow.publishedStep.roleIdsForUnpublish.has(role.id) ||
      workflow.publishedStep.roleIdsForCreateNew.has(role.id),
  );
