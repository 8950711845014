import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import {
  canAccessEnvironmentSettings,
  environmentSettingsRequiredCapabilities,
} from '../../applications/environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { compareUserEnvironments } from '../../applications/projectSettings/environments/utils/environmentsUtils.ts';
import { ProjectUiTransitionState } from '../../applications/projects/types/projectUiTransitionState.ts';
import { isProjectInSettingUpUiTransitionState } from '../../applications/projects/utils/projectUtils.ts';
import { IUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import {
  isProjectPlanExpired,
  isProjectPlanSuspended,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { EnvironmentOption } from '../utils/environments/environmentUtils.ts';
import { getUserCapability } from '../utils/permissions/capability.ts';
import { getEnvironmentSwitchUrlFactory } from '../utils/routing/projectMenuUtils.ts';

export const getEnvironmentsForMenu = memoize.maxOne(
  (
    projects: Immutable.Map<Uuid, IUserProjectInfo>,
    projectsUiTransitionState: Immutable.Map<Uuid, ProjectUiTransitionState>,
    currentMasterEnvironmentId: Uuid,
    pathname: string,
    state: IStore,
  ): Immutable.List<EnvironmentOption> => {
    const buildEnvironmentSwitchRoute = getEnvironmentSwitchUrlFactory(pathname);

    return projects
      .filter(
        (project: IUserProjectInfo) =>
          !isProjectInSettingUpUiTransitionState(project.projectId, projectsUiTransitionState) &&
          project.isSetUpCorrectly &&
          project.masterEnvironmentId === currentMasterEnvironmentId,
      )
      .sort((a, b) => compareUserEnvironments(a, b))
      .map((project: IUserProjectInfo): EnvironmentOption => {
        const planIsExpired = isProjectPlanExpired(state, project.projectId);
        const planIsSuspended = isProjectPlanSuspended(state, project.projectId);

        return {
          environmentName: project.environmentName,
          environmentId: project.projectId,
          link: buildEnvironmentSwitchRoute(
            project.projectId,
            planIsExpired || planIsSuspended,
            getUserCapability(project).canOneOf(environmentSettingsRequiredCapabilities()),
          ),
          hasAccessToSettings: canAccessEnvironmentSettings(state, project.projectId),
        };
      })
      .toList();
  },
  { maxArgs: 4 },
);
