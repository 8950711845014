import { isElementFullyVisible } from '@kontent-ai/DOM';
import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useAttachRef, usePrevious } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';

export interface IDropDownOptionDataProps {
  readonly scrollIntoView?: boolean;
  readonly className?: string;
  readonly dataUiAttributes?: ObjectWithDataAttribute;
  readonly isDestructive?: boolean;
  readonly isDisabled?: boolean;
  readonly isHighlighted?: boolean;
  readonly isOutwardLink?: boolean;
  readonly isSelected?: boolean;
  readonly linkPath?: string;
  readonly slim?: boolean;
  readonly tooltipText?: string;
}

export interface IDropDownOptionCallbackProps {
  readonly onClick?: MouseEventHandler<HTMLElement>;
  readonly onMouseDown?: MouseEventHandler<HTMLElement>;
  readonly onMouseOver?: MouseEventHandler<HTMLElement>;
}

interface IDropDownOptionProps
  extends IDropDownOptionDataProps,
    IDropDownOptionCallbackProps,
    IForwardedRefProps<HTMLDivElement> {
  readonly children: React.ReactNode;
}

const DropDownOption: React.FC<React.PropsWithChildren<IDropDownOptionProps>> = ({
  children,
  className,
  dataUiAttributes,
  forwardedRef,
  isDestructive,
  isDisabled,
  isHighlighted,
  isOutwardLink,
  isSelected,
  linkPath,
  onClick,
  onMouseDown,
  onMouseOver,
  scrollIntoView,
  slim,
  tooltipText,
}) => {
  const { refObject: scrollTargetRef, refToForward } = useAttachRef(forwardedRef);

  const makeFullyVisible = useCallback(() => {
    if (scrollTargetRef.current && !isElementFullyVisible(scrollTargetRef.current)) {
      scrollTargetRef.current?.scrollIntoView();
    }
  }, [scrollTargetRef]);

  // Make the option fully visible when requesting scroll into view
  useEffect(() => {
    if (
      scrollIntoView &&
      scrollTargetRef.current &&
      !isElementFullyVisible(scrollTargetRef.current)
    ) {
      makeFullyVisible();
    }
  }, [scrollTargetRef, scrollIntoView, makeFullyVisible]);

  // Make the option fully visible when it gets selected
  const previousIsSelected = usePrevious(isSelected);
  useEffect(() => {
    if (isSelected && !previousIsSelected) {
      makeFullyVisible();
    }
  }, [isSelected, previousIsSelected, makeFullyVisible]);

  const renderDropDownOptions = (): JSX.Element => {
    if (isOutwardLink && linkPath) {
      return (
        <OutwardLink
          className={classNames('dropdown-option__pane', { 'dropdown-option__pane--slim': slim })}
          href={linkPath}
        >
          {children}
        </OutwardLink>
      );
    }
    if (linkPath && !isDisabled) {
      return (
        <Link
          to={linkPath}
          className={classNames('dropdown-option__pane', { 'dropdown-option__pane--slim': slim })}
        >
          {children}
        </Link>
      );
    }
    return (
      <div className={classNames('dropdown-option__pane', { 'dropdown-option__pane--slim': slim })}>
        {children}
      </div>
    );
  };

  return (
    <Tooltip placement="left" tooltipText={tooltipText}>
      <div
        ref={refToForward}
        onClick={isDisabled ? undefined : onClick}
        onMouseDown={isDisabled ? undefined : onMouseDown}
        onMouseOver={onMouseOver}
        className={classNames('dropdown-option', 'switch-icon-select', className, {
          'dropdown-option--is-selected': isSelected,
          'dropdown-option--is-disabled': isDisabled,
          'dropdown-option--is-destructive': isDestructive,
          'dropdown-option--is-highlighted': isHighlighted,
        })}
        {...dataUiAttributes}
      >
        {renderDropDownOptions()}
      </div>
    </Tooltip>
  );
};

DropDownOption.displayName = 'DropDownOption';

const DropDownOptionFRC = forwardRef(DropDownOption);
export { DropDownOptionFRC as DropDownOption };
