import { ContentBlock } from 'draft-js';
import { DiffType } from '../../../../itemEditor/features/Revisions/utils/DiffType.ts';
import { getDiffType } from '../api/editorDiffUtils.ts';

export const getDiffBlockClassNames = (block: ContentBlock): ReadonlyRecord<string, boolean> => {
  const data = block.getData();
  const diffType = getDiffType(block);

  return {
    'rte__block--is-disabled rte__block--is-removed': diffType === DiffType.Removed,
    'rte__block--is-added': diffType === DiffType.Added,
    'rte__block--is-changed': diffType === DiffType.Changed,
    'rte__block--is-dummy': !!data.get('dummy'),
  };
};
