import { Action } from '../../../../../../@types/Action.type.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_AssignmentSubmittingStarted,
} from '../../../../constants/editorActionTypes.ts';
import { AssignmentSections } from '../../../../features/ContentItemEditing/constants/AssignmentSections.ts';
import { ContentItemEditing_Init_Started } from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

export function submittingSection(
  state: AssignmentSections | null = null,
  action: Action,
): AssignmentSections | null {
  switch (action.type) {
    case ContentItemEditing_Init_Started:
      return null;

    case Content_Editing_AssignmentSubmittingStarted:
      return action.payload.submittingSection;

    case Content_Editing_AssignmentSubmittingFailed:
    case Content_Editing_AssignmentSubmittingFinished:
      return null;

    default:
      return state;
  }
}
