import { Action } from '../../../../../@types/Action.type.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { Collections_FilterSearchPhrase_Changed } from '../../constants/actionTypes.ts';
import { ICollectionsAppStoreStateType } from '../ICollectionsAppStoreState.type.ts';

export const initialFilterSearchPhrase: ICollectionsAppStoreStateType['filterSearchPhrase'] = '';

export const filterSearchPhrase = (state = initialFilterSearchPhrase, action: Action): string => {
  switch (action.type) {
    case Collections_FilterSearchPhrase_Changed: {
      const searchPhrase = action.payload.filterSearchPhrase;
      return isEmptyOrWhitespace(searchPhrase) ? initialFilterSearchPhrase : searchPhrase;
    }
    default:
      return state;
  }
};
