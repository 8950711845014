import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { clearFilter, searchAllUsersByName } from '../../actions/thunkUsersActions.ts';
import {
  isFilteredOnlyByName as isListingFilteredOnlyByName,
  isFilteredOnlyBySelections as isListingFilteredOnlyBySelections,
} from '../../selectors/userListingFilterSelectors.ts';
import { UserListingFilterEmptyStateMessage } from './UserListingFilterEmptyStateMessage.tsx';

export const UserListingFilterEmptyState: React.FC = () => {
  const isFilteredOnlyByName = useSelector((s) => isListingFilteredOnlyByName(s.usersApp.filter));
  const isFilteredOnlyBySelections = useSelector((s) =>
    isListingFilteredOnlyBySelections(s.usersApp.filter),
  );

  if (isFilteredOnlyByName) {
    return (
      <UserListingFilterEmptyStateMessage
        action={searchAllUsersByName}
        actionText="Search among deactivated users"
        text="Try searching also among deactivated users."
        tooltipText="Switch to search among both active and deactivated users."
      />
    );
  }

  if (isFilteredOnlyBySelections) {
    return (
      <UserListingFilterEmptyStateMessage
        action={clearFilter}
        actionText="Clear filters"
        text="Try using different filters or searching also among deactivated users."
      />
    );
  }

  return (
    <UserListingFilterEmptyStateMessage
      action={searchAllUsersByName}
      actionText="Clear all filters"
      text="Try using different filters or searching also among deactivated users."
      tooltipText="Clear all filters and search among both active and deactivated users."
    />
  );
};

UserListingFilterEmptyState.displayName = 'UserListingFilterEmptyState';
