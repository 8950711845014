import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AgendaItemStatus } from '../../constants/AgendaItemStatus.ts';
import { AgendaItemStatusView } from '../AgendaItemStatusView.tsx';

interface LegendItemProps {
  readonly itemState: AgendaItemStatus;
}

const legendItemTooltipText: ReadonlyMap<AgendaItemStatus, string> = new Map([
  [AgendaItemStatus.OnTrack, 'Content items with a due date in the future'],
  [AgendaItemStatus.Delayed, 'Content items with a due date in the past'],
  [AgendaItemStatus.Published, 'Published content items'],
  [AgendaItemStatus.Scheduled, 'Content items scheduled to be published'],
]);

const LegendItem: React.FC<LegendItemProps> = ({ itemState }) => (
  <AgendaItemStatusView
    itemState={itemState}
    tooltipText={legendItemTooltipText.get(itemState) || ''}
    tooltipPlacement="bottom"
  />
);

LegendItem.displayName = 'LegendItem';

export const Legend: React.FC = () => (
  <Box padding={Spacing.S} {...getDataUiElementAttribute(DataUiElement.CalendarLegend)}>
    <Inline spacing={Spacing.S} align="center">
      <Box color={colorTextDefault} typography={Typography.HeadlineSmall}>
        Legend
      </Box>
      <LegendItem itemState={AgendaItemStatus.Published} />
      <LegendItem itemState={AgendaItemStatus.Scheduled} />
      <LegendItem itemState={AgendaItemStatus.Delayed} />
      <LegendItem itemState={AgendaItemStatus.OnTrack} />
    </Inline>
  </Box>
);

Legend.displayName = 'Legend';
