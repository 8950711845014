import Immutable from 'immutable';
import React from 'react';
import { emailNotificationsBeingChanged } from '../../../_shared/actions/sharedActions.ts';
import { updateEmailPreferences } from '../../../_shared/actions/thunkSharedActions.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { EmailPreferenceType } from '../../../_shared/models/EmailPreferenceTypeEnum.ts';
import { UserEmailPreferences } from '../components/UserEmailPreferences.tsx';
import { getEmailPreferences } from '../utils/emailPreferencesUtils.ts';

const UserEmailPreferencesContainer: React.FC = () => {
  const isUpdating = useSelector((s) => s.sharedApp.userProperties.emailNotificationsBeingSaved);

  const emailPreferences = useSelector((s) =>
    getEmailPreferences(
      s.sharedApp.userProperties.emailNotificationReportsWithZeroPriceDisabled,
      s.sharedApp.userProperties.emailNotificationCommentsDisabled,
      s.sharedApp.userProperties.emailNotificationAssignmentDisabled,
      s.sharedApp.userProperties.emailNotificationMentionsDisabled,
      s.sharedApp.userProperties.emailNotificationTasksDisabled,
      s.sharedApp.userProperties.emailNotificationWebhookNotificationsDisabled,
    ),
  );

  const dispatch = useDispatch();
  const change = (changed: boolean) => dispatch(emailNotificationsBeingChanged(changed));
  const update = (newPreferences: Immutable.Map<EmailPreferenceType, boolean>) =>
    dispatch(updateEmailPreferences(newPreferences));

  return (
    <UserEmailPreferences
      initialEmailPreferences={emailPreferences}
      isUpdating={isUpdating}
      onChange={change}
      onUpdate={update}
    />
  );
};

UserEmailPreferencesContainer.displayName = 'UserEmailPreferencesContainer';

export { UserEmailPreferencesContainer as UserEmailPreferences };
