export enum SpaceDeletionState {
  Confirmation = 'confirmation',
  Done = 'done',
}

export type SpaceDeletionData = {
  readonly id: Uuid;
  readonly name: string;
};

export type SpaceDeletion = {
  readonly state: SpaceDeletionState;
  readonly data: SpaceDeletionData;
};
