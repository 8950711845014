import { Action } from '../../../../../@types/Action.type.ts';
import {
  ILanguage,
  createLanguageFromServerModel,
} from '../../../../../data/models/languages/Language.ts';
import {
  SubscriptionUsers_EnvironmentFilter_Updated,
  SubscriptionUsers_LanguageFilter_LoadLanguages_Started,
  SubscriptionUsers_LanguageFilter_LoadLanguages_Succeeded,
  SubscriptionUsers_ProjectFilter_Updated,
} from '../../constants/actionTypes.ts';

const initialState: ReadonlyArray<ILanguage> = [];

export const languages = (
  state: ReadonlyArray<ILanguage> = initialState,
  action: Action,
): ReadonlyArray<ILanguage> => {
  switch (action.type) {
    case SubscriptionUsers_LanguageFilter_LoadLanguages_Started:
    case SubscriptionUsers_ProjectFilter_Updated:
    case SubscriptionUsers_EnvironmentFilter_Updated: {
      return initialState;
    }

    case SubscriptionUsers_LanguageFilter_LoadLanguages_Succeeded: {
      const { data } = action.payload;
      return [
        createLanguageFromServerModel(data.defaultLanguage),
        ...data.languages.map((languageServerModel) =>
          createLanguageFromServerModel(languageServerModel),
        ),
      ];
    }

    default:
      return state;
  }
};
