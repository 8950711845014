import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../@types/Dispatcher.type.ts';
import { closeNotificationBar } from '../actions/sharedActions.ts';
import {
  INotificationBarDispatchProps,
  INotificationBarStateProps,
  NotificationBar as NotificationBarComponent,
} from '../components/NotificationBar.tsx';
import { IStore } from '../stores/IStore.type.ts';
import { DataUiElement } from '../utils/dataAttributes/DataUiAttributes.ts';

function mapStateToProps(state: IStore): INotificationBarStateProps {
  const { text, type } = state.sharedApp.notificationBar;

  return {
    type,
    message: text,
    uiElement: DataUiElement.NotificationMessageError,
  };
}

function mapDispatchToProps(dispatch: Dispatch): INotificationBarDispatchProps {
  return {
    onClosePanel: () => dispatch(closeNotificationBar()),
  };
}

/**
 * @deprecated Use NotificationBar from Component Library
 */
export const NotificationBar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBarComponent);
