export const emptyRolesStepErrorText = 'Select a role for this step.';

export const emptyTransitionStepErrorText = 'Select a transition for this step.';

export const emptyTransitionsPanelText =
  'Workflow cannot be saved yet. Make sure all the highlighted steps have transitions set up.';

export const stepWithoutPathToPublishedErrorText =
  'Add a transition that can lead to Scheduled / Published.';

export const noPathToPublishedPanelText =
  'Cannot save project workflow. Make sure all highlighted steps can transition to Scheduled / Published.';

export const defaultStepErrorText = 'There is something wrong with this step.';

export const genericWorkflowError = 'The workflow is invalid and cannot be saved.';

export const stepContainsDeletedRoleError = 'Please remove the deleted role from this step.';

export const stepContainsDeletedRolesError = 'Please remove all deleted roles from this step.';
