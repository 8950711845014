import { memoize } from '@kontent-ai/memoization';

export enum EditorPaperThreshold {
  None = 0,
  SmallContentItemEditor = 540,
  MediumContentItemEditor = 680,
  UpToThreeQuickActions = 695,
  LargeContentItemEditor = 840,
  UpToFourQuickActions = 875,
}

const isEditorPaperThresholdKey = (
  value: string | EditorPaperThreshold,
): value is EditorPaperThreshold => !Number.isNaN(Number(value));

export const defaultPaperThresholds: ReadonlySet<EditorPaperThreshold> = new Set([
  EditorPaperThreshold.None,
]);

const getPaperThresholdsMemoized = memoize.allForever(
  (...thresholds: ReadonlyArray<EditorPaperThreshold>): ReadonlySet<EditorPaperThreshold> =>
    new Set(thresholds),
);

export const getReachedThresholds = (paperWidthPx: number): ReadonlySet<EditorPaperThreshold> => {
  const thresholds = Object.values(EditorPaperThreshold)
    .filter(isEditorPaperThresholdKey)
    .filter((threshold) => paperWidthPx >= threshold);

  return getPaperThresholdsMemoized(...thresholds);
};
