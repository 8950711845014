import { Box } from '@kontent-ai/component-library/Box';
import { Toggle } from '@kontent-ai/component-library/Toggle';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React from 'react';
import {
  DataUiCollection,
  DataUiSwitchAction,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { DefaultSkinSidebar } from '../../../../contentInventory/shared/components/DefaultSkinSidebar.tsx';
import { RevisionTimelineList } from '../containers/RevisionTimelineList.tsx';

export interface IRevisionCompareTimelineStateProps {
  readonly isInitialized: boolean;
  readonly paths: Immutable.Map<Uuid, string> | null;
}

export interface IRevisionCompareTimelineDispatchProps {
  readonly onCloseComparison: () => void;
}

type RevisionCompareTimelineProps = IRevisionCompareTimelineStateProps &
  IRevisionCompareTimelineDispatchProps;

export const RevisionCompareTimeline: React.FC<RevisionCompareTimelineProps> = (props) =>
  props.isInitialized ? (
    <DefaultSkinSidebar
      key="versions"
      {...getDataUiCollectionAttribute(DataUiCollection.Revisions)}
    >
      <Box padding={Spacing.XXL} paddingBottom={Spacing.XL}>
        <Toggle
          status="on"
          labelText="Compare versions"
          onToggleOff={props.onCloseComparison}
          {...getDataUiActionAttribute(DataUiSwitchAction.SwitchDifferences)}
        />
      </Box>
      <RevisionTimelineList paths={props.paths} />
    </DefaultSkinSidebar>
  ) : null;

RevisionCompareTimeline.displayName = 'RevisionCompareTimeline';
