import React from 'react';
import { useSpaceData } from '../../spaces/hooks/useSpaceData.ts';
import { CollectionBarSpaces as CollectionBarSpacesComponent } from '../components/BarItems/CollectionBarSpaces.tsx';

type Props = {
  readonly collectionId: Uuid;
};

export const CollectionBarSpaces: React.FC<Props> = ({ collectionId }) => {
  const spaceNamesMetadata = useSpaceData(collectionId);

  return <CollectionBarSpacesComponent spaceData={spaceNamesMetadata} />;
};

CollectionBarSpaces.displayName = 'CollectionBarSpaces';
