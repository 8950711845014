import React from 'react';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { allCapabilities } from '../../../_shared/utils/permissions/capability.ts';
import { Quickstart } from '../../quickstart/containers/Quickstart.tsx';

export const QuickstartSection: React.FC = () => (
  <AuthorizedSection appName={AppNames.Quickstart} requiresOneOfCapabilities={allCapabilities}>
    <Quickstart />
  </AuthorizedSection>
);

QuickstartSection.displayName = 'QuickstartSection';
