import { isAbortError } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { History } from 'history';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  EnvironmentRouteParams,
  TaxonomyGroupsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { redirectToDefaultRoute } from '../../../../../_shared/utils/routing/redirectToDefaultRoute.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  ITaxonomyGroup,
  createTaxonomyGroupDomainModel,
} from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { VariantUsageQueryServerModel } from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { getTaxonomyGroupItemFilterServerModel } from '../../../../contentInventory/content/models/filter/contentItemFilterUtils.ts';
import {
  Taxonomy_GroupEdit_Cleared,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Group_UsageOfEditedInPublishedItems,
} from '../../constants/taxonomyActionTypes.ts';

interface ICreateInitTaxonomyGroupEditActionDependencies {
  readonly taxonomyRepository: {
    getTaxonomyGroup: (
      taxonomyGroupId: Uuid,
      abortSignal: AbortSignal,
    ) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly contentItemRepository: {
    readonly projectContainsPublishedItems: (
      filter: VariantUsageQueryServerModel,
      abortSignal: AbortSignal,
    ) => Promise<boolean>;
  };
}

const taxonomyEditingCleared = () =>
  ({
    type: Taxonomy_GroupEdit_Cleared,
  }) as const;

const editedGroupInitialized = (editedTaxonomyGroup: ITaxonomyGroup) =>
  ({
    type: Taxonomy_GroupEdit_Initialized,
    payload: { editedTaxonomyGroup },
  }) as const;

const taxonomyGroupUsageInPublishedItems = (isUsed: boolean) =>
  ({
    type: Taxonomy_Group_UsageOfEditedInPublishedItems,
    payload: { isUsed },
  }) as const;

export type InitTaxonomyGroupEditActionsType = ReturnType<
  | typeof taxonomyEditingCleared
  | typeof editedGroupInitialized
  | typeof taxonomyGroupUsageInPublishedItems
>;

export const createInitTaxonomyGroupEditAction =
  (deps: ICreateInitTaxonomyGroupEditActionDependencies) =>
  (taxonomyGroupId: Uuid, history: History, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(taxonomyEditingCleared());
    const projectId = getState().sharedApp.currentProjectId;

    const serverGroup = await deps.taxonomyRepository
      .getTaxonomyGroup(taxonomyGroupId, abortSignal)
      .catch((error) => {
        if (!isAbortError(error)) {
          redirectToDefaultRoute({
            currentProjectId: projectId,
            history,
            error,
          });
        }
        throw error;
      });

    const editedGroup = createTaxonomyGroupDomainModel(serverGroup || null);

    if (editedGroup.isArchived) {
      history.push(buildPath<EnvironmentRouteParams>(TaxonomyGroupsRoute, { projectId }));
      return;
    }

    const termIds = Collection.getKeys(editedGroup.terms);
    if (termIds.length) {
      const isUsed = await deps.contentItemRepository.projectContainsPublishedItems(
        {
          taxonomiesByGroupId: {
            [editedGroup.id]: getTaxonomyGroupItemFilterServerModel(termIds),
          },
        },
        abortSignal,
      );
      dispatch(taxonomyGroupUsageInPublishedItems(isUsed));
    } else {
      dispatch(taxonomyGroupUsageInPublishedItems(false));
    }

    dispatch(editedGroupInitialized(editedGroup));
  };
