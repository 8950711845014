import { InvariantException } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import {
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
  EnvironmentRoute,
  EnvironmentRouteParams,
} from '../../../_shared/constants/routePaths.ts';
import { DefaultVariantId } from '../../../_shared/constants/variantIdValues.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUrlFactory } from '../../../_shared/utils/urlFactory.ts';
import { IWebSpotlightPreviewResolution } from '../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import {
  EditItemRoute,
  EditItemRouteParams,
  SharedWebSpotlightPreviewParams,
  WebSpotlightSharedPreviewRoute,
} from '../constants/routePaths.ts';

export function getItemSmartLink(currentPath: string, itemId: Uuid): string {
  const matchedItemsParams = matchPath<ContentItemsRouteParams>(currentPath, ContentItemsRoute);
  if (!matchedItemsParams) {
    const matchedProjectParams = matchPath<EnvironmentRouteParams>(currentPath, EnvironmentRoute);
    if (!matchedProjectParams) {
      throw InvariantException('Current route does not fit to ProjectRoute');
    }

    const itemLink = `${getUrlFactory().getDraftUiRootUrl()}${buildPath<EditItemRouteParams>(
      EditItemRoute,
      {
        projectId: matchedProjectParams.projectId,
        itemGuid: itemId,
        variantGuid: DefaultVariantId,
      },
    )}`;

    return itemLink;
  }

  const localizedItemLink = `${getUrlFactory().getDraftUiRootUrl()}${buildPath<EditItemRouteParams>(
    EditItemRoute,
    {
      projectId: matchedItemsParams.projectId,
      itemGuid: itemId,
      variantGuid: matchedItemsParams.variantId,
    },
  )}`;

  return localizedItemLink;
}

export interface ISmartLinkTarget {
  readonly path: string;
  readonly itemId: Uuid;
  readonly variantId: Uuid;
}

export function getSmartLinkTarget<TParams extends ContentItemRouteParams<UuidArray>>(
  projectId: Uuid | undefined,
  variantId: Uuid | undefined,
  itemId: Uuid,
  currentPath: string,
  contentItemRoute = ContentItemRoute,
  extraParams?: Omit<TParams, keyof ContentItemRouteParams<UuidArray>>,
): ISmartLinkTarget {
  // When we are in Web spotlight preview and project matches, we navigate directly using item editing routes
  const matchedItemParams = matchPath<ContentItemRouteParams<string>>(
    currentPath,
    ContentItemRoute,
  );
  if (matchedItemParams && (!projectId || matchedItemParams.projectId === projectId)) {
    // If item ID is already present, cut the path to the given ID
    const itemIds = parseContentItemIds(matchedItemParams.contentItemIds);

    const matchingItemIdIndex =
      itemIds[itemIds.length - 1] === itemId
        ? // When last item is the requested one, keep the path
          itemIds.length - 1
        : // When requested ID is in path, trim it to its first occurence
          itemIds.indexOf(itemId);

    const targetVariantId = variantId ?? matchedItemParams.variantId;

    if (matchingItemIdIndex >= 0) {
      const trimmedPath = buildPath<ContentItemRouteParams<UuidArray>>(contentItemRoute, {
        app: matchedItemParams.app,
        projectId: matchedItemParams.projectId,
        variantId: targetVariantId,
        spaceId: matchedItemParams.spaceId,
        contentItemIds: itemIds.slice(0, matchingItemIdIndex + 1),
        ...extraParams,
      });
      return {
        path: trimmedPath,
        itemId,
        variantId: targetVariantId,
      };
    }

    // When requested ID is not present, append to the current path to create a nested path
    const nestedPath = buildPath<ContentItemRouteParams<UuidArray>>(contentItemRoute, {
      app: matchedItemParams.app,
      projectId: matchedItemParams.projectId,
      variantId: targetVariantId,
      spaceId: matchedItemParams.spaceId,
      contentItemIds: [...itemIds, itemId],
      ...extraParams,
    });

    return {
      path: nestedPath,
      itemId,
      variantId: targetVariantId,
    };
  }

  const matchedProjectParams = matchPath<EnvironmentRouteParams>(currentPath, EnvironmentRoute);
  const targetProjectId = projectId ?? matchedProjectParams?.projectId;
  assert(
    targetProjectId,
    () =>
      'Current route does not belong to EnvironmentRoute routes neither target project ID was specified.',
  );

  const matchedContentItemsRouteParams = matchPath<ContentItemsRouteParams>(
    currentPath,
    ContentItemsRoute,
  );
  const targetVariantId =
    variantId ?? matchedContentItemsRouteParams?.variantId ?? DefaultVariantId;
  const path = buildPath<ContentItemRouteParams<UuidArray>>(contentItemRoute, {
    app: ContentItemsAppRouteSegment.WebSpotlight,
    projectId: targetProjectId,
    variantId: targetVariantId,
    spaceId: matchedContentItemsRouteParams?.spaceId,
    contentItemIds: [itemId],
    ...extraParams,
  });

  return {
    path,
    itemId,
    variantId: targetVariantId,
  };
}

export function getFloatingEditorSmartLinkTarget(
  projectId: Uuid | undefined,
  variantId: Uuid | undefined,
  itemId: Uuid,
  currentPath: string,
): ISmartLinkTarget {
  const matchedItemParams = matchPath<ContentItemRouteParams<string>>(
    currentPath,
    ContentItemRoute,
  );
  if (!matchedItemParams) {
    return getSmartLinkTarget(projectId, variantId, itemId, currentPath);
  }

  const targetVariantId = variantId ?? matchedItemParams.variantId;
  const path = buildPath<ContentItemPreviewWithEditorRouteParams<UuidArray>>(
    ContentItemPreviewWithEditorRoute,
    {
      app: matchedItemParams.app,
      projectId: matchedItemParams.projectId,
      variantId: targetVariantId,
      spaceId: matchedItemParams.spaceId,
      contentItemIds: parseContentItemIds(matchedItemParams.contentItemIds),
      editedItemId: itemId,
    },
  );

  return {
    path,
    itemId,
    variantId: targetVariantId,
  };
}

export const getSharedWebSpotlightPreviewSmartLink = (
  projectId: Uuid,
  variantId: Uuid,
  spaceId: Uuid,
  itemId: Uuid,
  previewUrl: string,
  previewResolution: IWebSpotlightPreviewResolution | null,
): string => {
  const searchParams = new URLSearchParams({
    url: previewUrl,
    ...(previewResolution !== null
      ? {
          width: previewResolution.width.toString(),
          height: previewResolution.height.toString(),
        }
      : null),
  });

  return `${getUrlFactory().getDraftUiRootUrl()}${buildPath<SharedWebSpotlightPreviewParams>(
    WebSpotlightSharedPreviewRoute,
    {
      projectId,
      itemGuid: itemId,
      variantGuid: variantId,
      spaceId,
    },
  )}?${searchParams.toString()}`;
};
