import Immutable from 'immutable';
import {
  isEveryImageResponsive,
  validateFileSizeForAssets,
  validateHeightForAssets,
  validateWidthForAssets,
} from '../../../_shared/utils/assets/assetValidationUtils.ts';
import {
  validateMaxChars,
  validateMaxWords,
} from '../../../_shared/utils/validation/limitations/baseTextLimitValidator.ts';
import { IAssetRendition } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';
import {
  CompiledTypeElementType,
  ElementType,
  NonEditableElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { EditableTypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { getViolatedFeaturesInContentState } from '../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { getContentComponentIds } from '../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { hasExistingImage } from '../../richText/plugins/images/api/editorImageUtils.ts';
import {
  getModularContentItemIds,
  hasContentComponent,
  hasExistingModularContent,
} from '../../richText/plugins/linkedItems/api/editorModularUtils.ts';
import {
  getCharacterCount,
  getWordCount,
  hasNonWhitespaceChar,
} from '../../richText/plugins/textApi/api/editorTextUtils.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { getAssetItemElementValidationWarning } from './itemElementWarningCheckers/assetItemElementWarningChecker.ts';
import { getCustomItemElementValidationWarning } from './itemElementWarningCheckers/customItemElementWarningChecker.ts';
import { getDateTimeItemElementValidationWarning } from './itemElementWarningCheckers/dateTimeItemElementWarningChecker.ts';
import { getLinkedItemElementValidationWarning } from './itemElementWarningCheckers/modularContentItemElementWarningChecker.ts';
import { getMultipleChoiceItemElementValidationWarning } from './itemElementWarningCheckers/multipleChoiceItemElementWarningChecker.ts';
import { getNumberItemElementValidationWarning } from './itemElementWarningCheckers/numberItemElementWarningChecker.ts';
import { getRichTextItemElementValidationWarningCreator } from './itemElementWarningCheckers/richTextItemElementWarningChecker.ts';
import { getTaxonomyItemElementValidationWarning } from './itemElementWarningCheckers/taxonomyItemElementWarningChecker.ts';
import { getTextItemElementValidationWarning } from './itemElementWarningCheckers/textItemElementWarningChecker.ts';
import { ItemElementWarningResult } from './itemElementWarningCheckers/types/Warnings.ts';
import { getUrlSlugItemElementValidationWarning } from './itemElementWarningCheckers/urlSlugItemElementWarningChecker.ts';
import { getInlineImagesUsedInEditorState } from './itemElementWarningCheckers/utils/getInlineImagesUsedInEditorState.ts';

const getRichTextItemElementValidationWarning = getRichTextItemElementValidationWarningCreator({
  getCharacterCount,
  getContentComponentIds,
  getInlineImagesUsedInEditorState,
  getModularContentItemIds,
  getViolatedFeaturesInContentState,
  getWordCount,
  hasContentComponent,
  hasExistingImage,
  hasExistingModularContent,
  hasNonWhitespaceChar,
  isEveryImageResponsive,
  validateFileSizeForAssets,
  validateHeightForAssets,
  validateMaxChars,
  validateMaxWords,
  validateWidthForAssets,
});

export interface IGetItemElementWarningCheckerParams<
  TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData,
> {
  readonly areAssetRenditionsEnabled: boolean;
  readonly contentComponentTypeIds: ReadonlyMap<Uuid, Uuid>;
  readonly itemElement: TItemElement;
  readonly loadedAssetRenditions: ReadonlyMap<Uuid, IAssetRendition>;
  readonly loadedAssets: Immutable.Map<Uuid, IAsset>;
  readonly loadedContentTypes: Immutable.Map<Uuid, ICompiledContentType>;
  readonly loadedEntries: Immutable.Map<Uuid, IListingContentItem>;
  readonly taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>;
  readonly typeElement: EditableTypeElement;
}

export type IGetItemElementWarnings<
  TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData,
  TParams extends Partial<
    IGetItemElementWarningCheckerParams<TItemElement>
  > = IGetItemElementWarningCheckerParams<TItemElement>,
> = (params: TParams) => ItemElementWarningResult;

type AllWarningCheckersMap = ReadonlyRecord<
  CompiledTypeElementType,
  IGetItemElementWarnings<any, any>
>;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WarningCheckersMap = Omit<AllWarningCheckersMap, NonEditableElementType>;

const itemElementWarningCheckersMap: WarningCheckersMap = {
  [ElementType.Asset]: getAssetItemElementValidationWarning,
  [ElementType.Subpages]: getLinkedItemElementValidationWarning,
  [ElementType.Custom]: getCustomItemElementValidationWarning,
  [ElementType.DateTime]: getDateTimeItemElementValidationWarning,
  [ElementType.LinkedItems]: getLinkedItemElementValidationWarning,
  [ElementType.MultipleChoice]: getMultipleChoiceItemElementValidationWarning,
  [ElementType.Number]: getNumberItemElementValidationWarning,
  [ElementType.RichText]: getRichTextItemElementValidationWarning,
  [ElementType.Taxonomy]: getTaxonomyItemElementValidationWarning,
  [ElementType.Text]: getTextItemElementValidationWarning,
  [ElementType.UrlSlug]: getUrlSlugItemElementValidationWarning,
};

export const getItemElementWarnings = (
  params: IGetItemElementWarningCheckerParams,
): ItemElementWarningResult => {
  const getWarningResults = itemElementWarningCheckersMap[params.typeElement.type];
  return getWarningResults(params);
};
