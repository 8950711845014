import React, { RefObject } from 'react';
import {
  DatetimePicker,
  DatetimePickerRefType,
} from '../../../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { DateTime as DateTimeShape } from '../../../../../../../_shared/models/DateTime.ts';
import { DatetimePickerPlaceholder } from '../../../../../../projects/constants/UIConstants.ts';
import { DateTimeItemElement } from '../../../../../models/contentItemElements/DateTimeItemElement.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../models/ReadonlyEmptyElementPlaceholder.ts';

export type DateTimePickerInElementProps = {
  readonly autoFocus: boolean;
  readonly datetimePickerRef: RefObject<DatetimePickerRefType>;
  readonly disabled: boolean;
  readonly elementData: DateTimeItemElement;
  readonly onChange: (dateTime: DateTimeShape, newTimeZoneId: string | null) => void;
};

export const DateTimePickerInElement: React.FC<DateTimePickerInElementProps> = ({
  autoFocus,
  datetimePickerRef,
  disabled,
  elementData,
  onChange,
}) => (
  <DatetimePicker
    autoFocus={autoFocus}
    disabled={disabled}
    hideUtcLabel
    defaultTime="0:00"
    onChange={(dateTime) => onChange(dateTime, elementData.displayTimeZone)}
    placeholder={
      disabled ? ReadonlyEmptyElementPlaceholder.DateTimeElement : DatetimePickerPlaceholder
    }
    ref={datetimePickerRef}
    timeZoneId={elementData.displayTimeZone ?? undefined}
    value={elementData.value}
  />
);

DateTimePickerInElement.displayName = 'DateTimePickerInElement';
