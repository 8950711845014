import React from 'react';
import { LinkLike } from '../../../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { DataUiWorkflowAction } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IPlaceholderProps {
  readonly children: string;
  readonly dataUiAttribute: DataUiWorkflowAction;
  readonly onClick: () => void;
  readonly className?: string;
}

export const Placeholder: React.FC<IPlaceholderProps> = ({
  children,
  dataUiAttribute,
  onClick,
  className,
}) => (
  <LinkLike onClick={onClick} dataUiAction={dataUiAttribute} className={className}>
    {children}
  </LinkLike>
);

Placeholder.displayName = 'Placeholder';
