type HashAlgorithm = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512';

export async function digestToBuffer(algorithm: HashAlgorithm, text: string): Promise<ArrayBuffer> {
  return await crypto.subtle.digest(algorithm, new TextEncoder().encode(text));
}

export async function digestToString(algorithm: HashAlgorithm, text: string): Promise<string> {
  const hashAsArrayBuffer = await digestToBuffer(algorithm, text);
  const uint8ViewOfHash = new Uint8Array(hashAsArrayBuffer);
  return Array.from(uint8ViewOfHash)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}
