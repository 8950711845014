import { UserFeedbackTimestamps } from '../../data/models/user/UserFeedbackTimestamps.type.ts';
import { IUser } from '../../data/reducers/user/IUser.type.ts';
import { isProjectTrial } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isMonthsPeriodPassed, isTimeInPast } from '../utils/dateTime/timeUtils.ts';
import { isEmployee } from '../utils/subscriptionUsageUtils.ts';
import { getCurrentUser } from './getCurrentUser.ts';

const isNewUser = (user: IUser) => !isMonthsPeriodPassed(1, new Date(), user.info.createdAt);

const hasAlreadySeenSurvey = (
  userFeedback: UserFeedbackTimestamps,
  surveyAvailableFrom: Date,
): boolean =>
  !!userFeedback.displayedAt && !isTimeInPast(surveyAvailableFrom, userFeedback.displayedAt);

const hasAlreadySubmitted = (
  userFeedback: UserFeedbackTimestamps,
  surveyAvailableFrom: Date,
): boolean =>
  !!userFeedback.answeredAt && !isTimeInPast(surveyAvailableFrom, userFeedback.answeredAt);

const isHelpUsImproveSurveyAvailable = (state: IStore): boolean => {
  const {
    sharedApp: { generalProperties, currentProjectId, userProperties },
  } = state;

  return (
    !!generalProperties.enableUmuxSurvey &&
    isTimeInPast(new Date(), generalProperties.enableUmuxSurvey) &&
    !isEmployee(getCurrentUser(state).email) &&
    !isProjectTrial(state, currentProjectId) &&
    !hasAlreadySubmitted(userProperties.userFeedback, new Date(generalProperties.enableUmuxSurvey))
  );
};

export const shouldShowHelpUsImproveSurveyAutomatically = (state: IStore): boolean => {
  const {
    sharedApp: { userProperties, generalProperties },
    data: { user },
  } = state;

  return (
    !!generalProperties.enableUmuxSurvey &&
    isHelpUsImproveSurveyAvailable(state) &&
    !isNewUser(user) &&
    !hasAlreadySeenSurvey(userProperties.userFeedback, new Date(generalProperties.enableUmuxSurvey))
  );
};

export const shouldShowHelpUsImproveSurveyReminder = (state: IStore): boolean => {
  const {
    sharedApp: { userProperties, generalProperties },
    data: { user },
  } = state;

  return (
    !!generalProperties.enableUmuxSurvey &&
    isHelpUsImproveSurveyAvailable(state) &&
    !isNewUser(user) &&
    hasAlreadySeenSurvey(userProperties.userFeedback, new Date(generalProperties.enableUmuxSurvey))
  );
};
