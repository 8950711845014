import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { CanUpdateContent } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';

type ReadonlyPluginProps = {
  // We don't want the disabled property to be settable from outside anymore
  readonly disabled?: never;
  readonly isLocked?: never;
  readonly isViewOnly?: boolean;
};

export type ReadonlyPlugin = EditorPlugin<None, ReadonlyPluginProps>;

const canUpdateContent: Decorator<CanUpdateContent> = () => () => false;

export const ReadonlyPlugin: PluginComponent<ReadonlyPlugin> = (props) => {
  const apply: Apply<ReadonlyPlugin> = useCallback((state) => {
    state.canUpdateContent.decorate(canUpdateContent);
    return {};
  }, []);

  const propsWithDisabled = {
    ...props,
    disabled: true,
  };

  return useEditorWithPlugin(propsWithDisabled, { apply });
};
