import { Action } from '../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { RequestTokenFactory } from '../../../../../_shared/utils/requestTokenUtils.ts';
import { getListingContentItemFromJS } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import { IContentItemRepository } from '../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  ContentItemFilterWithContinuationServerModel,
  DateRangesServerModel,
  DateTimeField,
  OrderByServerModel,
} from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { getOrderBy } from '../../../../contentInventory/content/models/filter/contentItemFilterUtils.ts';
import { loadCalendarItemsWithVariantActiveInPeriodFinished } from '../calendarActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
  readonly createContentItemFilterServerModelFromFilter: (
    filter: IListingFilter,
    workflows: ReadonlyMap<Uuid, Workflow>,
  ) => ContentItemFilterWithContinuationServerModel;
  readonly requestTokenFactory: RequestTokenFactory<LoadItemWithVariantActiveInPeriodStartedCreator>;
}

type LoadItemWithVariantActiveInPeriodStartedCreator = (period: DateRange) => Action;

const maximumNumberOfItemsPerPeriod = 300;

const createDateRangesFilter = (period: DateRange): DateRangesServerModel[] => [
  {
    ...period,
    fields: [
      DateTimeField.AssignmentDue,
      DateTimeField.LastPublishedAt,
      DateTimeField.ScheduledPublishAt,
    ],
  },
];

const orderByLastModifiedAtDesc: OrderByServerModel = getOrderBy({
  columnCode: 'lastModifiedAt',
  direction: OrderByDirection.Descending,
});

export const createLoadItemsMatchingFilterWithVariantActiveInPeriod =
  (deps: IDeps) =>
  (period: DateRange, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const {
      contentApp: {
        listingUi: { filter },
      },
      data: { workflows },
    } = getState();

    const {
      tokenizedActionCreator: loadItemWithVariantActiveInPeriodStarted,
      isCurrentTokenValid,
    } = deps.requestTokenFactory(getState);

    dispatch(loadItemWithVariantActiveInPeriodStarted(period));

    const userFilter = deps.createContentItemFilterServerModelFromFilter(filter, workflows.byId);
    const dateRanges = createDateRangesFilter(period);
    const completeFilter: ContentItemFilterWithContinuationServerModel = {
      ...userFilter,
      dateRanges,
      orderBy: orderByLastModifiedAtDesc,
    };

    const result = await deps.contentItemRepository.getPlanningItems(
      selectedLanguageId,
      completeFilter,
      maximumNumberOfItemsPerPeriod,
      isCurrentTokenValid,
      abortSignal,
    );

    if (isCurrentTokenValid()) {
      const itemsForPeriod = result.data.map(getListingContentItemFromJS);
      dispatch(
        loadCalendarItemsWithVariantActiveInPeriodFinished(
          itemsForPeriod,
          period,
          filter,
          result.wasDataCountLimitExceeded,
        ),
      );
    }
  };
