import { IContentTypeBase } from '../../../data/models/contentModelsApp/sharedContentTypeModels.ts';

export const anyContentTypeContentType: IContentTypeBase = {
  id: 'anyContentTypeContentType',
  codename: '',
  name: 'Any content type',
  typeElements: [],
  lastModified: null,
  lastModifiedBy: null,
  isArchived: false,
};
