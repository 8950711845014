import { assert } from '@kontent-ai/utils';
import { BaseBlockType, BlockType } from '../../../blocks/blockType.ts';
import { TagData } from './tags.ts';

export const VirtualBlockType = {
  Table: 'table',
  TableBody: 'table-body',
  TableRow: 'table-row',
  OrderedList: 'ordered-list',
  UnorderedList: 'unordered-list',
} as const;
export type VirtualBlockType = (typeof VirtualBlockType)[keyof typeof VirtualBlockType];

export const blockTypeToTagName: ReadonlyRecord<BaseBlockType | VirtualBlockType, string | null> = {
  [BlockType.ContentComponent]: null,
  [BlockType.ContentModule]: null,
  [BlockType.HeadingFive]: 'h5',
  [BlockType.HeadingFour]: 'h4',
  [BlockType.HeadingOne]: 'h1',
  [BlockType.HeadingSix]: 'h6',
  [BlockType.HeadingThree]: 'h3',
  [BlockType.HeadingTwo]: 'h2',
  [BlockType.Image]: null,
  [BlockType.NewBlockPlaceholder]: null,
  [BlockType.OrderedListItem]: 'li',
  [BlockType.TableCell]: 'td',
  [BlockType.UnorderedListItem]: 'li',
  [BlockType.Unstyled]: 'p',
  [VirtualBlockType.OrderedList]: 'ol',
  [VirtualBlockType.Table]: 'table',
  [VirtualBlockType.TableBody]: 'tbody',
  [VirtualBlockType.TableRow]: 'tr',
  [VirtualBlockType.UnorderedList]: 'ul',
};

export const getBlockTagData = (blockType: BaseBlockType | VirtualBlockType): TagData => {
  const tagName = blockTypeToTagName[blockType];
  assert(
    tagName,
    () => `Block type ${blockType} is not supposed to be mapped directly to a HTML tag`,
  );
  return { tagName };
};
