import {
  DefaultGettingStartedProjectName,
  DefaultSampleProjectName,
} from '../constants/defaultNames.ts';
import { DancingGoatSampleItemId } from '../constants/sampleContentItems.ts';

export function isSampleContentItem(itemId: Uuid) {
  return itemId === DancingGoatSampleItemId;
}

interface ISampleProjectIdentifier {
  readonly projectName: string;
}

export function isSampleProject(project: ISampleProjectIdentifier): boolean {
  return project.projectName.startsWith(DefaultSampleProjectName); // This is because sample-project-configurator postfixes names with current date
}

export function isGettingStartedProject(project: ISampleProjectIdentifier): boolean {
  return project.projectName === DefaultGettingStartedProjectName;
}
