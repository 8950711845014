import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { GuidelinesEventTypes } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { IContentTypeSnippet } from '../../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { isTypeValid } from '../../../utils/typeValidationUtils.ts';
import {
  contentTypeSnippetElementGeneratesUrlSlug,
  getUrlSlugGeneratedByContentTypeSnippetElement,
} from '../../../utils/urlSlugUtils.ts';
import {
  clearTypeEditorErrorMessage,
  typeElementUpdated,
} from '../../sharedContentModelsActions.ts';
import { createValidateTypeElementAction } from './validateTypeElement.ts';

interface IDeps {
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createUpdateTypeElementAction =
  (deps: IDeps) =>
  (updatedTypeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch, state) => {
    if (updatedTypeElement.type === ElementType.Guidelines) {
      dispatch(
        trackUserEventWithData(TrackedEvent.Guidelines, {
          'content-item-id': updatedTypeElement.elementId,
          action: GuidelinesEventTypes.Updated,
        }),
      );
    }
    dispatch(deps.validateTypeElement(updatedTypeElement));
    dispatch(typeElementUpdated(updatedTypeElement));

    if (isTypeValid(state().contentModelsApp.typeEditor.validationResults)) {
      dispatch(clearTypeEditorErrorMessage());
    }

    if (updatedTypeElement.type === ElementType.ContentTypeSnippet) {
      const snippets = state().data.snippets.byId;
      const contentElements = state().contentModelsApp.typeEditor.editedType.typeElements;
      const snippetElement = snippets.find((snippet: IContentTypeSnippet) =>
        contentTypeSnippetElementGeneratesUrlSlug(contentElements, snippets, snippet.id),
      );

      if (snippetElement) {
        const urlSlug = getUrlSlugGeneratedByContentTypeSnippetElement(
          contentElements,
          snippets,
          snippetElement.id,
        );
        if (urlSlug) {
          const validateAction = createValidateTypeElementAction()(urlSlug);
          dispatch(validateAction);
        }
      }
    }
  };
