import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { roleBuilderAnyCollectionOptionId } from '../constants/roleBuilder.ts';

type Params = {
  readonly allSelectedCollectionIds: ReadonlySet<Uuid>;
  readonly collections: CollectionsMap;
  readonly groupCollectionIds: UuidArray;
};

export const getRemainingCollectionsCount = (params: Params): number => {
  const { allSelectedCollectionIds, collections, groupCollectionIds } = params;

  const selectedCollectionIdsWithoutAnyInOtherGroups = [...allSelectedCollectionIds].filter(
    (id) =>
      id !== roleBuilderAnyCollectionOptionId &&
      collections.has(id) &&
      !groupCollectionIds.includes(id),
  );

  return collections.size - selectedCollectionIdsWithoutAnyInOtherGroups.length;
};
