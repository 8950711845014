import { combineReducers } from '@reduxjs/toolkit';
import { draggedElementId } from './typeEditor/draggedElementId.ts';
import { editedType } from './typeEditor/editedType.ts';
import { editedTypeIsModified } from './typeEditor/editedTypeIsModified.ts';
import { firstInvalidElementId } from './typeEditor/firstInvalidElementId.ts';
import { lastAction } from './typeEditor/lastAction.ts';
import { lastAddedElementId } from './typeEditor/lastAddedElementId.ts';
import { lastMovedElementId } from './typeEditor/lastMovedElementId.ts';
import { linkedItemsDefaultValueReferences } from './typeEditor/linkedItemsDefaultValueReferences.ts';
import { loadingStatus } from './typeEditor/loadingStatus.ts';
import { typeIsBeingSaved } from './typeEditor/typeIsBeingSaved.ts';
import { validationResults } from './typeEditor/validationResults.ts';

export const typeEditorReducer = combineReducers({
  draggedElementId,
  editedType,
  editedTypeIsModified,
  firstInvalidElementId,
  lastAction,
  lastAddedElementId,
  lastMovedElementId,
  linkedItemsDefaultValueReferences,
  loadingStatus,
  typeIsBeingSaved,
  validationResults,
});
