import Immutable from 'immutable';
import {
  CustomElementHostMessageType,
  ElementValue,
  IAssetDetails,
  ICustomElementHostMessage,
  IItemChangedDetails,
  IItemExtendedData,
  ISelectedAsset,
  ISelectedItem,
} from '../../../types/CustomElementApi.ts';
import { CustomElementSandboxProps } from '../components/elements/customElement/CustomElementSandbox.tsx';

export const createInitResponseMessage = (
  params: CustomElementSandboxProps,
  requestId: Uuid,
): ICustomElementHostMessage => {
  const {
    value,
    disabled,
    typeElement,
    projectId,
    itemId,
    itemObservedData,
    variantId,
    languageCodename,
  } = params;

  const config = typeElement.config && JSON.parse(typeElement.config);

  return {
    type: CustomElementHostMessageType.InitDataResponse,
    requestId,
    data: {
      element: {
        value,
        disabled,
        config,
      },
      context: {
        projectId,
        item: {
          id: itemId,
          codename: itemObservedData.codename,
          collection: itemObservedData.collection,
          name: itemObservedData.name,
        },
        variant: {
          id: variantId,
          codename: languageCodename,
        },
      },
    },
  };
};

export const createSelectAssetsResponseMessage = (
  requestId: Uuid,
  error: string,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.SelectAssetsResponse,
    requestId,
    data: {
      error,
    },
  };
};

export const createSelectItemsResponseMessage = (
  requestId: Uuid,
  error: string,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.SelectItemsResponse,
    requestId,
    data: {
      error,
    },
  };
};

export const createSetValueResponseMessage = (
  requestId: Uuid,
  error?: string,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.SetValueResponse,
    requestId,
    data: {
      error,
    },
  };
};

export const createGetElementValueFailedResponseMessage = (
  customElementCodename: string,
  elementCodename: string,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.GetValueResponse,
    requestId,
    data: {
      value: null,
      error: `Unable to read value of element ${elementCodename} from element ${customElementCodename}.
The element type is not supported, the element does not exist, or access to the element is not allowed.
Please check the content type configuration.`,
    },
  };
};

export const createGetItemDetailsFailedResponseMessage = (
  itemIds: UuidArray,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.GetItemDetailsResponse,
    requestId,
    data: {
      details: null,
      error: `No details were found for items with the IDs ${itemIds}.`,
    },
  };
};

export const createGetAssetDetailsFailedResponseMessage = (
  assetIds: UuidArray,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.GetAssetDetailsResponse,
    requestId,
    data: {
      details: null,
      error: `No details were found for assets with the IDs ${assetIds}.`,
    },
  };
};

export const createGetElementValueResponseMessage = (
  value: ElementValue,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.GetValueResponse,
    requestId,
    data: {
      value,
    },
  };
};

export const createGetItemDetailsResponseMessage = (
  details: ReadonlyArray<IItemExtendedData>,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.GetItemDetailsResponse,
    requestId,
    data: {
      details,
    },
  };
};

export const createGetAssetDetailsResponseMessage = (
  details: ReadonlyArray<IAssetDetails>,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.GetAssetDetailsResponse,
    requestId,
    data: {
      details,
    },
  };
};

export const createDisabledChangeMessage = (disabled: boolean): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.OnDisabledChanged,
    data: {
      disabled,
    },
  };
};

export const createElementsChangedResponseMessage = (
  elementCodenames: Immutable.Set<string>,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.OnElementsChanged,
    requestId,
    data: {
      elements: elementCodenames.toArray(),
    },
  };
};

export const createItemChangedResponseMessage = (
  itemChangedDetails: IItemChangedDetails,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.OnItemChanged,
    requestId,
    data: {
      itemChangedDetails,
    },
  };
};

export const createItemsSelectedResponseMessage = (
  ids: ReadonlyArray<ISelectedItem> | null,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.SelectItemsResponse,
    requestId,
    data: {
      items: ids,
    },
  };
};

export const createAssetsSelectedResponseMessage = (
  ids: ReadonlyArray<ISelectedAsset> | null,
  requestId: Uuid,
): ICustomElementHostMessage => {
  return {
    type: CustomElementHostMessageType.SelectAssetsResponse,
    requestId,
    data: {
      assets: ids,
    },
  };
};
