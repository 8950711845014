import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../_shared/constants/trackedEvent.ts';
import { YourContentTabName } from '../../../_shared/constants/yourContentTabName.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../contentInventory/shared/actions/contentInventoryActions.ts';
import { openYourContentTab } from '../actions/yourContentActions.ts';

export interface Props {
  readonly tabName: YourContentTabName;
  readonly itemCount: number;
}

export const YourContentTabSaver: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(openYourContentTab(props.tabName));
    dispatch(
      contentItemsBreadcrumbsOriginChanged({
        path: location.pathname,
        title: AppNames.YourContent,
      }),
    );
    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightContent, {
        'items-count': props.itemCount,
        name: FindRightContentTrackedEvent.YourContentItemsLoaded,
        tab: props.tabName,
      }),
    );
  }, [location.pathname, props.itemCount, props.tabName]);

  return <>{props.children}</>;
};

YourContentTabSaver.displayName = 'YourContentTabSaver';
