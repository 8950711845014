import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  IProjectContributor,
  createProjectContributorFromServerModel,
} from '../../../../../data/models/users/ProjectContributor.ts';
import {
  Invite_SubmitInvitations_Finished,
  UserDetail_UpdateCollectionGroups_Finished,
  User_ResendInvite_Failed,
  User_ResendInvite_Finished,
  User_ResendInvite_Started,
  User_RevokeInvite_Failed,
  User_RevokeInvite_Started,
  User_ToggleActiveState_Failed,
  User_ToggleActiveState_Started,
  Users_ProjectContributorsLoaded,
} from '../../constants/usersActionTypes.ts';

const initialState: ReadonlyMap<Uuid, IProjectContributor> = new Map();

export function projectContributors(
  state: ReadonlyMap<Uuid, IProjectContributor> = initialState,
  action: Action,
): ReadonlyMap<Uuid, IProjectContributor> {
  switch (action.type) {
    case Invite_SubmitInvitations_Finished: {
      const { users } = action.payload;

      return Collection.addMany(
        state,
        users.map((u) => [u.userId, createProjectContributorFromServerModel(u)] as const),
      );
    }

    case User_ToggleActiveState_Started: {
      const { userId, activate } = action.payload;

      return Collection.replaceWith(state, userId, (u) => ({
        ...u,
        isActivating: activate,
        isDeactivating: !activate,
      }));
    }

    case User_ToggleActiveState_Failed: {
      const { userId } = action.payload;

      return Collection.replaceWith(state, userId, (u) => ({
        ...u,
        isActivating: false,
        isDeactivating: false,
      }));
    }

    case User_ResendInvite_Started: {
      const { userId } = action.payload;

      return Collection.replaceWith(state, userId, (u) => ({
        ...u,
        isInvitationPending: true,
        isInviteSending: true,
      }));
    }

    case User_ResendInvite_Finished:
    case User_ResendInvite_Failed: {
      const { userId } = action.payload;

      return Collection.replaceWith(state, userId, (u) => ({
        ...u,
        isInvitationPending: true,
        isInviteSending: false,
        isInviteSent: true,
      }));
    }

    case User_RevokeInvite_Started: {
      const { userId } = action.payload;

      return Collection.replaceWith(state, userId, (u) => ({ ...u, isInviteRevoking: true }));
    }

    case User_RevokeInvite_Failed: {
      const { userId } = action.payload;

      return Collection.replaceWith(state, userId, (u) => ({ ...u, isInviteRevoking: false }));
    }

    case UserDetail_UpdateCollectionGroups_Finished: {
      const { user } = action.payload;

      return Collection.add(state, [user.userId, user]);
    }

    case Users_ProjectContributorsLoaded: {
      return new Map(
        action.payload.usersServerModel.map(
          (u) => [u.userId, createProjectContributorFromServerModel(u)] as const,
        ),
      );
    }

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
