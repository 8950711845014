import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_SubpagesPermissionCheck_Failed,
  WebSpotlight_SubpagesPermissionCheck_Finished,
  WebSpotlight_SubpagesPermissionCheck_Started,
} from '../constants/webSpotlightActionTypes.ts';
import { WebSpotlightTreeOperationsCheckResult } from '../types/IWebSpotlightTreeActionsChekTypes.type.ts';

export interface IMoreActionsCheckResult {
  readonly createNewItem: WebSpotlightTreeOperationsCheckResult;
  readonly linkExistingItem: WebSpotlightTreeOperationsCheckResult;
}

export const moreActionsCheckResult = (
  state: IMoreActionsCheckResult | null = null,
  action: Action,
): IMoreActionsCheckResult | null => {
  switch (action.type) {
    case WebSpotlight_SubpagesPermissionCheck_Started:
      return {
        createNewItem: WebSpotlightTreeOperationsCheckResult.Loading,
        linkExistingItem: WebSpotlightTreeOperationsCheckResult.Loading,
      };

    case WebSpotlight_SubpagesPermissionCheck_Finished:
      return action.payload.result;

    case WebSpotlight_SubpagesPermissionCheck_Failed:
      return {
        createNewItem: WebSpotlightTreeOperationsCheckResult.Unknown,
        linkExistingItem: WebSpotlightTreeOperationsCheckResult.Unknown,
      };

    default:
      return state;
  }
};
