import { createEnsureSelectedVariantInRouteAction } from '../../../../_shared/actions/thunks/ensureSelectedVariantInRoute.ts';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { getPathWithReplacedVariantIdForContentInventoryTypeRoute } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadCollections, loadLanguages } from '../../../../data/actions/thunkDataActions.ts';
import { contentVariantIdStorage } from '../../../../localStorages/contentVariantStorage.ts';
import { localizedRouteEntered } from './contentInventoryActions.ts';
import { createLoadFullTextSearchStatusAction } from './thunks/loadFullTextSearchStatus.ts';

const { projectRepository } = repositoryCollection;

export const ensureSelectedVariantForInventory = createEnsureSelectedVariantInRouteAction({
  appName: AppNames.Content,
  loadCollections,
  loadLanguages,
  contentVariantIdStorage,
  getPathWithReplacedVariantId: getPathWithReplacedVariantIdForContentInventoryTypeRoute,
  localizedRouteEntered,
});

export const loadFullTextSearchStatus = createLoadFullTextSearchStatusAction({
  projectRepository,
});
