import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { WebSpotlight_Space_Selected } from '../../../webSpotlight/constants/webSpotlightActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

export const shouldResetEditedItemState = (action: Action) => {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
    case WebSpotlight_Space_Selected:
    case ContentListing_Init_Started:
    case ContentItemEditing_Init_Started:
      return true;

    default:
      return false;
  }
};
