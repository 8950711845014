import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  Data_CustomApps_Started,
  Data_CustomApps_Success,
} from '../../../constants/dataActionTypes.ts';
import { CustomApp } from '../../../models/customApps/CustomApp.ts';

type CustomAppsStateType = ReadonlyMap<Uuid, CustomApp>;
const initialState: CustomAppsStateType = new Map();

export function byId(state: CustomAppsStateType = initialState, action: Action) {
  switch (action.type) {
    case Data_CustomApps_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_CustomApps_Success:
      return new Map(action.payload.data.map((app) => [app.id, app]));

    default:
      return state;
  }
}
