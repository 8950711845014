import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../../../../_shared/models/LoadingStatusEnum.ts';
import { areWorkflowsLoaded } from '../../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';

interface IDeps {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSitemap: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createEnsureInventoryRelatedDataAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();

    const dataRequirements: ReadonlyArray<
      readonly [shouldLoad: boolean, loader: (abortSignal?: AbortSignal) => ThunkPromise]
    > = [
      [state.data.contentTypes.loadingStatus !== LoadingStatus.Loaded, deps.loadContentTypes],
      [state.data.collections.loadingStatus !== LoadingStatus.Loaded, deps.loadCollections],
      [state.data.sitemap.loadingStatus !== LoadingStatus.Loaded, deps.loadSitemap],
      [state.data.spaces.loadingStatus !== LoadingStatus.Loaded, deps.loadSpaces],
      [state.data.taxonomyGroups.loadingStatus !== LoadingStatus.Loaded, deps.loadTaxonomyGroups],
      [Collection.isEmpty(state.data.users.usersById), deps.loadUsers],
      [!areWorkflowsLoaded(state), deps.loadWorkflows],
    ];

    const loaders = dataRequirements
      .filter(([shouldLoad]) => shouldLoad)
      .map(([, loader]) => dispatch(loader(abortSignal)));

    await Promise.all(loaders);
  };
