import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ILoadRichTextReferencesAction } from '../../../../itemEditor/features/LoadedItems/actions/thunks/loadRichTextReferences.ts';
import { EmptyContentComponents } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { isGuidelinesTypeElement } from '../../types/typeElementTypeGuards.ts';
import { ILoadAssetDefaultValueReferencesAction } from './loadAssetDefaultValueReferences.ts';
import { ILoadLinkedItemsDefaultValueReferencesAction } from './loadLinkedItemsDefaultValueReferences.ts';

export type ILoadContentTypeReferencesForTypeEditingAction = (
  typeElements: ReadonlyArray<TypeElement | IBaseTypeElementData>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface ILoadContentTypeReferencesForTypeEditingActionDependencies {
  readonly loadAssetDefaultValueReferences: ILoadAssetDefaultValueReferencesAction;
  readonly loadGuidelinesRichTextReferences: ILoadRichTextReferencesAction;
  readonly loadLinkedItemsDefaultValueReferences: ILoadLinkedItemsDefaultValueReferencesAction;
}

export const createLoadContentTypeReferencesForTypeEditingAction =
  (
    deps: ILoadContentTypeReferencesForTypeEditingActionDependencies,
  ): ILoadContentTypeReferencesForTypeEditingAction =>
  (
    typeElements: ReadonlyArray<TypeElement | IBaseTypeElementData>,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch) => {
    const contents = typeElements
      .filter(isGuidelinesTypeElement)
      .map((typeElement) => typeElement._editorState.getCurrentContent());

    await Promise.all([
      dispatch(deps.loadAssetDefaultValueReferences(typeElements, abortSignal)),
      dispatch(deps.loadLinkedItemsDefaultValueReferences(typeElements, abortSignal)),
      dispatch(
        deps.loadGuidelinesRichTextReferences(contents, EmptyContentComponents, abortSignal),
      ),
    ]);
  };
