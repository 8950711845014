import {
  NewVariantDialog_Initialized,
  NewVariantDialog_SelectedWorkflowChanged,
} from '../constants/newVariantActionTypes.ts';

export const newVariantDialogInitialized = () =>
  ({
    type: NewVariantDialog_Initialized,
  }) as const;

export const newVariantSelectedWorkflowChanged = (workflowId: Uuid) =>
  ({
    type: NewVariantDialog_SelectedWorkflowChanged,
    payload: { workflowId },
  }) as const;
