import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderBy } from '../../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IAssignedItemViewModel } from '../../../../yourContent/components/AssignedToYou/ContentAssignedToYou.tsx';
import { decorateItemsAssignedToYou } from '../../../../yourContent/utils/yourContentItemDecorationUtils.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { ItemsAssignedToYouWidgetDetail } from '../components/ItemsAssignedToYouWidgetDetail.tsx';
import { ItemsAssignedToYouWidgetDetailRow } from '../components/ItemsAssignedToYouWidgetDetailRow.tsx';
import { AssignedContentItemsOrderByColumnCode } from '../constants/assignedContentItemsOrderByColumnCode.ts';

type ItemsAssignedToYouWidgetDetailContainerProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly onChangeOrderBy: (
    orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    numberOfItemsToFetch: number,
  ) => void;
  readonly onItemClick?: () => void;
  readonly dataState: WidgetListingState;
  readonly orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>;
  readonly itemsAssignedToYou: ReadonlyArray<IAssignedItemViewModel>;
}>;

export const ItemsAssignedToYouWidgetDetailContainer: React.FC<
  ItemsAssignedToYouWidgetDetailContainerProps
> = ({ ariaLabelledBy, onChangeOrderBy, onItemClick, dataState, orderBy, itemsAssignedToYou }) => {
  const loadingDependenciesFinished = useSelector(
    (s) =>
      s.data.contentTypes.loadingStatus === LoadingStatus.Loaded &&
      s.data.languages.byIdLoadingStatus === LoadingStatus.Loaded,
  );

  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const languages = useSelector((s) => s.data.languages.byId);
  const viewableLanguages = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );

  const decoratedItemsAssignedToYou: ReadonlyArray<IAssignedItemViewModel> =
    loadingDependenciesFinished
      ? decorateItemsAssignedToYou(
          Immutable.List(itemsAssignedToYou),
          languages,
          viewableLanguages,
          contentTypes,
        ).toArray()
      : [];

  return (
    <ItemsAssignedToYouWidgetDetail
      ariaLabelledBy={ariaLabelledBy}
      onChangeOrderBy={onChangeOrderBy}
      orderBy={orderBy}
      state={loadingDependenciesFinished ? dataState : WidgetListingState.Loading}
    >
      {decoratedItemsAssignedToYou.map((itemAssignedToYou) => (
        <ItemsAssignedToYouWidgetDetailRow
          key={stringifyContentItemId(itemAssignedToYou.id)}
          onItemClick={onItemClick}
          item={itemAssignedToYou}
          rowFocusLinkAriaLabel={`visit content item — ${itemAssignedToYou.name}`}
        />
      ))}
    </ItemsAssignedToYouWidgetDetail>
  );
};
