import { Button } from '@kontent-ai/component-library/Button';
import { noOperation } from '@kontent-ai/utils';
import { memo } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../utils/func/compose.ts';
import { preventDefault } from '../../utils/func/functionalTools.ts';
import { CodenameAppBarButton } from '../Codename/CodenameAppBarButton.tsx';
import { DuplicateButton } from '../DuplicateButton.tsx';
import { SaveChangesButton } from '../SaveChangesButton.tsx';

interface Props {
  readonly codename?: string | null;
  readonly isBeingSaved: boolean;
  readonly isCodenameEditable?: boolean;
  readonly isDuplicateDisabled?: boolean;
  readonly isDeleteDisabled?: boolean;
  readonly isLoading: boolean;
  readonly disabledDeleteTooltip?: string;
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly onCodenameSave?: (codename: string) => Promise<void> | void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onDuplicate?: () => void;
  readonly onDelete?: () => void;
  readonly onSave: () => void;
}

export const StatusBarActions = memo((props: Props) => {
  const onControlSHandler = compose(
    props.isBeingSaved ? noOperation : props.onSave,
    preventDefault,
  );

  if (props.isLoading) {
    return null;
  }

  return (
    <>
      <div className="status-bar__button status-bar__save-button">
        <SaveChangesButton
          buttonStyle="inverse"
          isBeingSaved={props.isBeingSaved}
          onSave={props.onSave}
        />
      </div>
      {!!props.onDuplicate && (
        <div className="status-bar__button status-bar__duplicate-button">
          <DuplicateButton
            disabled={props.isDuplicateDisabled}
            onDuplicate={props.onDuplicate}
            tooltipText={
              props.isDuplicateDisabled
                ? 'Save all changes before duplicating this content type'
                : 'Duplicate content type'
            }
          />
        </div>
      )}
      {!!props.onDelete && (
        <div className="status-bar__button status-bar__delete-button">
          <Button
            tooltipText={props.isDeleteDisabled ? props.disabledDeleteTooltip : 'Delete'}
            tooltipPlacement="bottom-end"
            buttonStyle="secondary"
            destructive
            size="medium"
            disabled={props.isDeleteDisabled}
            onClick={props.onDelete}
            {...getDataUiActionAttribute(DataUiAction.Delete)}
          >
            Delete
          </Button>
        </div>
      )}
      {props.codename && (
        <CodenameAppBarButton
          codename={props.codename}
          isCodenameEditable={props.isCodenameEditable}
          onCodenameSave={props.onCodenameSave}
          onCodenameCopy={props.onCodenameCopy}
          onCodenameEdit={props.onCodenameEdit}
          onCtrlSHandler={props.isLoading ? undefined : onControlSHandler}
          relatedCodenames={props.relatedCodenames}
        />
      )}
    </>
  );
});
