import { memoize } from '@kontent-ai/memoization';
import { assert } from '@kontent-ai/utils';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import { getSortedRolesImmutableMemoized } from '../../roles/selectors/getSortedRolesList.ts';
import { roleBuilderAnyLanguageOptionId } from '../constants/roleBuilder.ts';

export const getInitialUserRoles = memoize.maxOne(
  (roles: ReadonlyMap<Uuid, IRoleWithSettings>): ReadonlyArray<IProjectContributorRole> => {
    const allRoles = getSortedRolesImmutableMemoized(roles);
    const firstRole = allRoles.first();

    assert(firstRole, () => 'Did not find any role');

    return [
      {
        languageIds: [roleBuilderAnyLanguageOptionId],
        roleId: firstRole.id,
      },
    ];
  },
);
