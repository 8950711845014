import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import {
  IMoveAssetItemToFolderServerModel,
  IMoveAssetsToFolderResultServerModel,
} from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  AssetLibrary_Assets_MoveBackToFolderCompleted,
  AssetLibrary_Assets_MoveBackToFolderFailed,
  AssetLibrary_Assets_MoveBackToFolderStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { AssetFolderReference, isMoveAssetsToFolderAction } from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IDeps {
  readonly assetService: {
    readonly moveAssetsToFolder: (
      items: ReadonlyArray<IMoveAssetItemToFolderServerModel>,
    ) => Promise<IMoveAssetsToFolderResultServerModel>;
  };
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const started = () =>
  ({
    type: AssetLibrary_Assets_MoveBackToFolderStarted,
  }) as const;

const completed = (successfulIds: ReadonlyMap<Uuid, AssetFolderReference>, openedFolderId: Uuid) =>
  ({
    type: AssetLibrary_Assets_MoveBackToFolderCompleted,
    payload: {
      openedFolderId,
      successfulIds,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Assets_MoveBackToFolderFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type MoveAssetsBackToFolderActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Args = {
  readonly orderBy: AssetsOrderBy;
};

export const createMoveAssetsBackToFolderAction =
  (deps: IDeps) =>
  ({ orderBy }: Args): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      assetLibraryApp: { lastAction, openedFolderId },
    } = getState();

    if (!isMoveAssetsToFolderAction(lastAction)) {
      throw new Error(
        `${__filename}: Expected last action to be ${
          StatusMessageAction.MovedAssetsToFolder
        }, but was ${JSON.stringify(lastAction)}.`,
      );
    }

    dispatch(started());

    try {
      const itemsToMove = Collection.getEntries(lastAction.successfulIds).map(
        ([assetId, folderReference]): IMoveAssetItemToFolderServerModel => ({
          assetId,
          targetFolderId: folderReference.id,
        }),
      );

      const result = await deps.assetService.moveAssetsToFolder(itemsToMove);
      const movedAssetsIds = new Map(
        Collection.getEntries(lastAction.successfulIds).filter(([assetId]) =>
          result.movedAssetsIds.includes(assetId),
        ),
      );

      dispatch(completed(movedAssetsIds, openedFolderId));
      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.MovedBackToFolder,
          count: movedAssetsIds.size,
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          orderBy,
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
        }),
      );
    } catch (error) {
      dispatch(failed('Assets could not be moved back.'));
      throw InvariantException(`${__filename}: ${error}`);
    }
  };
