import { Callout } from '@kontent-ai/component-library/Callout';
import { LinkLike } from '../../../../uiComponents/LinkLike/LinkLike.tsx';
import { IncompleteElementsError } from '../../constants/uiConstants.ts';
import { ModalSection } from '../ModalSection.tsx';

export interface IPublishingErrorSectionStateProps {
  readonly displayIncompleteElementsError: boolean;
}

export interface IPublishingErrorSectionDispatchProps {
  readonly onClick: () => void;
}

type Props = IPublishingErrorSectionStateProps & IPublishingErrorSectionDispatchProps;

export const PublishingErrorSection = ({ displayIncompleteElementsError, onClick }: Props) => {
  return displayIncompleteElementsError ? (
    <ModalSection>
      <Callout calloutType="warning" headline="Content cannot be published yet">
        <span>{IncompleteElementsError}</span> <LinkLike onClick={onClick}>Fix them</LinkLike>
      </Callout>
    </ModalSection>
  ) : null;
};
