import React from 'react';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { WebSpotlightTabs } from './WebSpotlightTabs.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';

export const WebSpotlightLoaderView: React.FC = () => (
  <>
    <WebSpotlightTabs />
    <WebSpotlightWorkspace>
      <Loader />
    </WebSpotlightWorkspace>
  </>
);

WebSpotlightLoaderView.displayName = 'WebSpotlightLoaderView';
