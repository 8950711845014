import React from 'react';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { ContentItemPaneElementsGroup } from '../../../../itemEditor/features/ContentItemEditing/components/ContentItemPaneElementsGroup.tsx';
import { AssetTitleEditorElement } from '../../containers/AssetEditing/AssetTitleEditorElement.tsx';
import { AssetTitleEditorInModalDialogElement } from '../../containers/AssetEditing/AssetTitleEditorInModalDialogElement.tsx';

export interface IAssetTitleProps {
  readonly asset: IAsset;
  readonly canUpdateAssets: boolean;
  readonly isInModal: boolean;
  readonly onChange: (updatedAsset: IAsset) => void;
}

export const AssetTitle: React.FC<IAssetTitleProps> = ({
  asset,
  canUpdateAssets,
  isInModal,
  onChange,
}) => {
  return (
    <ContentItemPaneElementsGroup
      isDisabled={!canUpdateAssets}
      hasTopRoundedCorners
      hasBottomRoundedCorners
    >
      {isInModal ? (
        <AssetTitleEditorInModalDialogElement
          editedAsset={asset}
          readOnly={!canUpdateAssets}
          onChangeAsset={onChange}
        />
      ) : (
        <AssetTitleEditorElement editedAsset={asset} readOnly={!canUpdateAssets} />
      )}
    </ContentItemPaneElementsGroup>
  );
};

AssetTitle.displayName = 'AssetTitle';
