import Immutable from 'immutable';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getCurrentUserRoleForCollectionForLanguage } from '../../../../../_shared/utils/permissions/getContributorRole.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  filterContentTypesByCapability,
  getSortedContentTypesByName,
} from '../../../../contentModels/shared/utils/typeUtils.ts';

export const getAvailableContentTypesForContentItem = (
  selectedLanguageId: string,
  selectedCollectionId: string | null,
  state: IStore,
): Immutable.List<IContentType> => {
  if (!selectedCollectionId) {
    return Immutable.List();
  }

  const currentUserRole = getCurrentUserRoleForCollectionForLanguage(
    state,
    selectedCollectionId,
    selectedLanguageId,
  );

  if (!currentUserRole) {
    return Immutable.List();
  }

  const contentTypes = state.data.contentTypes.byId;

  const availableContentTypes = filterContentTypesByCapability(
    contentTypes.toArray(),
    currentUserRole.settings,
    Capability.CreateContent,
  );

  return Immutable.List(getSortedContentTypesByName(availableContentTypes));
};
