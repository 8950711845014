import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { HandleClickOutside } from '../../../../_shared/components/HandleClickOutside.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICalendarEvent } from '../models/CalendarModels.type.ts';

type AgendaItemProps = {
  readonly event: ICalendarEvent;
};

const StyledRow = styled.div`
  height: 100%;
`;

export const AgendaItem: React.FC<AgendaItemProps> = ({ event: { status, name } }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSelected, setIsSelected] = useState(false);

  const handleClickOutside = (): void => {
    setIsSelected(false);
  };

  const onAgendaItemClicked = (): void => {
    setIsSelected(true);
  };

  return (
    <HandleClickOutside observedRefs={ref} onClickOutside={handleClickOutside}>
      <div
        className={classNames(`agenda-item agenda-item--${status}`, {
          'agenda-item--is-selected': isSelected,
        })}
        ref={ref}
        onClick={onAgendaItemClicked}
        {...getDataUiElementAttribute(DataUiElement.AgendaItem)}
      >
        <Row alignY="center" component={StyledRow} noWrap spacing={Spacing.XS}>
          <Column>
            <Box
              typography={Typography.TitleMedium}
              data-hj-suppress=""
              overflow="hidden"
              whiteSpace="nowrap"
              css="text-overflow: ellipsis;"
              {...getDataUiElementAttribute(DataUiElement.AgendaItemName)}
            >
              {name}
            </Box>
          </Column>
        </Row>
      </div>
    </HandleClickOutside>
  );
};

AgendaItem.displayName = 'AgendaItem';
