import { Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export const PageTitle = styled.h1.attrs((props) => ({
  ...props,
  ...getDataUiElementAttribute(DataUiElement.PageTitle),
}))`
  ${Typography.PageTitle};
  color: ${colorTextDefault};
  margin: 0;
`;
