import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_Editor_Canceled,
  Collections_Editor_Deleted,
  Collections_Editor_Opened,
  Collections_Editor_Submitted,
  Collections_Listing_Initialized,
} from '../../constants/actionTypes.ts';

export const editedCollectionId = (state = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Collections_Editor_Opened:
      return action.payload.collectionId;

    case Collections_Editor_Canceled:
    case Collections_Editor_Submitted:
    case Collections_Editor_Deleted:
    case Collections_Listing_Initialized:
      return null;

    default:
      return state;
  }
};
