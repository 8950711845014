import { Box } from '@kontent-ai/component-library/Box';
import {
  MultiSelect,
  PublicMultiSelectState,
  multiSelectTextFieldMinWidth,
} from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem, Option } from '@kontent-ai/component-library/Selects';
import { px, spacingBetweenTags } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import { RemovalState } from '../../../../../../component-library/components/Tag/removalStateEnum.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../utils/dataAttributes/DataUiAttributes.ts';

export interface IWorkflowStepSelectorStateProps {
  readonly selectedOptions: ReadonlyArray<IWorkflowStep>;
}

export interface IWorkflowStepSelectorOwnProps {
  readonly options: ReadonlyArray<IWorkflowStep>;
  readonly onSelectorValueChanged: (optionId: Uuid) => void;
}

type WorkflowStepSelectorProps = IWorkflowStepSelectorStateProps & IWorkflowStepSelectorOwnProps;

type WorkflowStepOption = IWorkflowStep & ISelectItem<WorkflowStepOption>;

export const WorkflowStepSelector: React.FC<WorkflowStepSelectorProps> = ({
  onSelectorValueChanged,
  options,
  selectedOptions,
}) => {
  const [localSelectedOptionIds, setLocalSelectedOptionIds] = useState(
    selectedOptions.map((selectedOption) => selectedOption.id),
  );

  const onSelectionChanged = (
    newSelectedOptions: ReadonlySet<Uuid> | null,
    closeMenu: PublicMultiSelectState<WorkflowStepOption>['closeMenu'],
  ): void => {
    const selectedOptionId = Collection.getLast(newSelectedOptions ? [...newSelectedOptions] : []);

    if (selectedOptionId) {
      setLocalSelectedOptionIds([selectedOptionId]);
      onSelectorValueChanged(selectedOptionId);
    }
    closeMenu();
  };

  const items: ReadonlyArray<WorkflowStepOption> = options.map((item) => ({
    ...item,
    label: item.name,
  }));

  return (
    <div className="workflow-step-selector">
      <MultiSelect<WorkflowStepOption>
        items={items}
        onSelectionChange={onSelectionChanged}
        placeholder="Select a workflow step"
        renderMenuOption={(props) =>
          props.item.value && (
            <Option {...props} {...getDataUiObjectNameAttribute(props.item.value.label)} />
          )
        }
        renderSelectedOption={(_id, selectedOption, defaultTagProps) => (
          // We’re using MultiSelect as a "single choice" component here.
          // We want to prevent the MultiSelect’s text field from wrapping when empty — keep it in a single line with the selected option tag.
          <Box maxWidth={`calc(100% - ${px(multiSelectTextFieldMinWidth + spacingBetweenTags)}`}>
            <Tag
              {...defaultTagProps}
              removalState={RemovalState.NoRemoval}
              background={renderWorkflowStepColor(selectedOption.color)}
            />
          </Box>
        )}
        selectedItemIds={localSelectedOptionIds}
        {...getDataUiCollectionAttribute(DataUiCollection.WorkflowStepDropdownItems)}
        {...getDataUiInputAttribute(DataUiInput.WorkflowStepName)}
      />
    </div>
  );
};

WorkflowStepSelector.displayName = 'WorkflowStepSelector';
