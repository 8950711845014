import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Data_AssetFolders_Failed,
  Data_AssetFolders_Started,
  Data_AssetFolders_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly getAll: (abortSignal?: AbortSignal) => Promise<ITaxonomyGroupServerModel>;
  };
}

const start = () => ({ type: Data_AssetFolders_Started }) as const;
const success = (data: ITaxonomyGroupServerModel) =>
  ({
    type: Data_AssetFolders_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_AssetFolders_Failed }) as const;

export type LoadAssetFoldersActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadAssetFoldersAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const taxonomyGroup = await deps.assetFolderRepository.getAll(abortSignal);

      dispatch(success(taxonomyGroup));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
