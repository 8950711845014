import {
  Relations_CollapseAllNodes,
  Relations_CollapseNode,
  Relations_HideRootSelector,
  Relations_ShowRootSelector,
} from '../constants/relationsActionTypes.ts';

export const collapseRelationsNode = (nodeId: string) =>
  ({
    type: Relations_CollapseNode,
    payload: { nodeId },
  }) as const;

export const collapseAllRelationsNodes = () =>
  ({
    type: Relations_CollapseAllNodes,
  }) as const;

export const hideRelationsRootSelector = () =>
  ({
    type: Relations_HideRootSelector,
  }) as const;

export const showRelationsRootSelector = () =>
  ({
    type: Relations_ShowRootSelector,
  }) as const;
