import React from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getCannotViewItemMessage } from '../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { UsageItemReference } from '../../../../../../_shared/utils/usage/usageUtils.ts';
import { getTypeName } from '../../../../../../data/reducers/contentTypes/selectors/getTypeName.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { SingleContentItemUsage as SingleContentItemUsageComponent } from '../../components/details/SingleContentItemUsage.tsx';

type SingleContentItemUsageProps = {
  readonly contentItemId: Uuid | undefined;
  readonly usage: UsageItemReference;
};

export const SingleContentItemUsage: React.FC<SingleContentItemUsageProps> = ({
  contentItemId,
  usage,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const projectId = useSelector((s) => getCurrentProject(s).projectId);
  const typeName = useSelector((s) => getTypeName(usage.primary.typeId, s));

  const navigateToItem = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.UsedIn,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  return (
    <SingleContentItemUsageComponent
      contentItemId={contentItemId}
      currentPath={location.pathname}
      disabledMessage={getCannotViewItemMessage(usage.primary)}
      onNavigateToItem={navigateToItem}
      projectId={projectId}
      typeName={typeName}
      usage={usage}
    />
  );
};

SingleContentItemUsage.displayName = 'SingleContentItemUsage';
