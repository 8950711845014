import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { useState } from 'react';
import { isCodenameValid } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import { getSnippetCodenameElementPrefix } from '../../../applications/contentModels/shared/utils/typeElementCodenameComposer.ts';
import { ValidationConstants } from '../../constants/validationConstants.ts';
import { LabelFor } from '../../uiComponents/LabelFor/LabelFor.tsx';
import { TextField } from '../../uiComponents/TextField/TextField.tsx';
import { DataUiInput } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Warning } from '../infos/Warning.tsx';
import { CodenameActionButtonPane } from './CodenameActionButtonPane.tsx';
import { CodenameErrorMessage } from './CodenameErrorMessage.tsx';

interface Props {
  readonly codename: string;
  readonly friendlyWarningMessage?: string;
  readonly hideTitle?: boolean;
  readonly isBeingSaved?: boolean;
  readonly isCodenameUnique: (codename: string) => boolean;
  readonly maxLength?: number;
  readonly mode: 'save' | 'confirm';
  readonly onChange?: () => void;
  readonly onDiscard: () => void;
  readonly onSave?: (codename: string) => void;
  readonly snippetCodename?: string | null;
}

export const EditCodename = (props: Props) => {
  const [codename, setCodename] = useState(props.codename);

  const isNewCodenameUnique = props.isCodenameUnique(codename);
  const canBeConfirmed = isNewCodenameUnique && isCodenameValid(codename);

  const save = (): void => {
    if (canBeConfirmed) {
      props.onSave?.(codename);
    }
  };

  useHotkeys({
    escape: props.onDiscard,
    ...(props.mode === 'save'
      ? {
          [ShortcutsConfig.ControlS]: (event: KeyboardEvent): void => {
            event.preventDefault();
            if (props.isBeingSaved) return;
            save();
          },
        }
      : { enter: save }),
  });

  return (
    <>
      <LabelFor
        isHidden={props.hideTitle}
        target={(inputId) => (
          <div className="form__input-with-buttons-wrapper align-items--center form__input-with-buttons-wrapper--is-edited">
            {props.snippetCodename && (
              <div className="codename-editor__snippet-prefix">
                {getSnippetCodenameElementPrefix(props.snippetCodename)}
              </div>
            )}
            <TextField
              ref={(element) => element?.focus()}
              dataUiInputName={DataUiInput.Codename}
              hasError={!canBeConfirmed}
              inputId={inputId}
              maxLength={props.maxLength || ValidationConstants.CodenameMaxLength}
              onChange={(event) => {
                setCodename(event.target.value);
                props.onChange?.();
              }}
              placeholder=""
              value={codename}
            />
          </div>
        )}
      >
        Codename
      </LabelFor>
      <CodenameErrorMessage
        isCodenameUnique={isNewCodenameUnique}
        hasCodenameErrors={!canBeConfirmed}
      />
      {props.friendlyWarningMessage && (
        <div className="codename-editor__warning">
          <Warning subtitle="Change the codename?">{props.friendlyWarningMessage}</Warning>
        </div>
      )}
      <CodenameActionButtonPane
        isConfirmDisabled={!canBeConfirmed}
        isSaving={props.isBeingSaved}
        onConfirm={save}
        onDiscard={props.onDiscard}
        mode={props.mode}
      />
    </>
  );
};
