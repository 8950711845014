import { Action } from '../../../../../../@types/Action.type.ts';
import { isEmptyOrWhitespace } from '../../../../../../_shared/utils/stringUtils.ts';
import { Data_ListingContentItems_FullTextSearchTurnedOff } from '../../../../../../data/constants/dataActionTypes.ts';
import { getListingFilterFromSavedFilter } from '../../../../../../data/models/filters/ISavedFilter.ts';
import {
  Content_ContentItemListingFilter_Clear,
  Content_Filter_Clear,
} from '../../../constants/contentActionTypes.ts';
import { ContentListing_Filter_SearchPhraseChanged } from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  ContentItemFilter_SetUp,
  ListingFilter_CollectionFilterChanged,
  ListingFilter_ContentItemStatusChanged,
  ListingFilter_ContentTypeFilterChanged,
  ListingFilter_ContributorsFilterChanged,
  ListingFilter_PublishingStatusFilterChanged,
  ListingFilter_SavedFilterSelected,
  ListingFilter_SearchScopeChanged,
  ListingFilter_SitemapFilterChanged,
  ListingFilter_SpaceFilterChanged,
  ListingFilter_TaxonomyGroupsFilterChanged,
  ListingFilter_WorkflowStatusesFilterChanged,
} from '../../../features/ListingFilter/constants/listingFilterActionTypes.ts';
import { IListingFilter, emptyListingFilter } from '../../../models/filter/IListingFilter.ts';

const initialFilter: IListingFilter = emptyListingFilter;

export function filter(state: IListingFilter = initialFilter, action: Action): IListingFilter {
  switch (action.type) {
    case ContentItemFilter_SetUp: {
      return action.payload.filter;
    }

    case ContentListing_Filter_SearchPhraseChanged: {
      const { searchPhrase } = action.payload;

      return {
        ...state,
        searchPhrase: isEmptyOrWhitespace(searchPhrase) ? initialFilter.searchPhrase : searchPhrase,
      };
    }

    case ListingFilter_SearchScopeChanged: {
      return {
        ...state,
        searchScope: action.payload.scope,
      };
    }

    case ListingFilter_CollectionFilterChanged: {
      return {
        ...state,
        selectedCollectionsNodes: action.payload.selectedCollections,
      };
    }

    case ListingFilter_SpaceFilterChanged: {
      return {
        ...state,
        selectedSpacesNodes: action.payload.selectedSpaces,
      };
    }

    case ListingFilter_ContentTypeFilterChanged: {
      return {
        ...state,
        selectedContentTypesNodes: action.payload.selectedContentTypes,
      };
    }

    case ListingFilter_WorkflowStatusesFilterChanged: {
      const { selectedWorkflowNodes: currentOptions } = state;
      const {
        workflowToSelectedStepIds: newOptionsFromAction,
        workflowToAllStepIds: workflowsById,
      } = action.payload;

      const newOptions = new Map(newOptionsFromAction);

      for (const [workflowId, stepIds] of newOptions) {
        const currentStepIds = currentOptions.get(workflowId);
        if (!currentStepIds) {
          continue;
        }

        if (wasLastWorkflowStepRemoved(currentStepIds, stepIds)) {
          newOptions.delete(workflowId);
        } else if (wasLastWorkflowStepAdded(currentStepIds, stepIds, workflowId, workflowsById)) {
          newOptions.set(workflowId, new Set());
        }
      }

      return {
        ...state,
        selectedWorkflowNodes: newOptions,
      };
    }

    case ListingFilter_SitemapFilterChanged: {
      return {
        ...state,
        selectedSitemapNodes: action.payload.selectedSitemapNodes,
      };
    }

    case ListingFilter_ContributorsFilterChanged: {
      return {
        ...state,
        selectedContributorsNodes: action.payload.selectedContributors,
      };
    }

    case ListingFilter_TaxonomyGroupsFilterChanged: {
      return {
        ...state,
        selectedTaxonomyNodes: action.payload.selectedTaxonomyTerms,
      };
    }

    case ListingFilter_ContentItemStatusChanged: {
      return {
        ...state,
        selectedContentItemStatus: action.payload.selectedContentItemStatus,
      };
    }

    case ListingFilter_PublishingStatusFilterChanged: {
      return {
        ...state,
        selectedPublishingStateNodes: action.payload.selectedPublishingStatuses,
      };
    }

    case ListingFilter_SavedFilterSelected: {
      return {
        ...state,
        ...getListingFilterFromSavedFilter(
          action.payload.filter,
          action.payload.forcedContentTypeIds,
        ),
      };
    }

    case Data_ListingContentItems_FullTextSearchTurnedOff: {
      return {
        ...state,
        searchScope: initialFilter.searchScope,
      };
    }

    case Content_Filter_Clear: {
      return {
        ...initialFilter,
        searchPhrase: state.searchPhrase,
        searchScope: state.searchScope,
      };
    }

    case Content_ContentItemListingFilter_Clear: {
      return {
        ...initialFilter,
        searchPhrase: state.searchPhrase,
        searchScope: state.searchScope,
        selectedContentTypesNodes: action.payload.forcedContentTypeIds
          ? action.payload.forcedContentTypeIds
          : initialFilter.selectedContentTypesNodes,
      };
    }

    default:
      return state;
  }
}

const wasLastWorkflowStepRemoved = (
  currentStepIds: ReadonlySet<Uuid>,
  stepIds: ReadonlySet<Uuid>,
): boolean => currentStepIds.size === 1 && stepIds.size === 0;

const wasLastWorkflowStepAdded = (
  currentStepIds: ReadonlySet<Uuid>,
  stepIds: ReadonlySet<Uuid>,
  workflowId: Uuid,
  workflowsToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
): boolean => {
  const allWorkflowStepIds = workflowsToAllStepIds.get(workflowId);
  if (!allWorkflowStepIds) {
    return false;
  }

  return currentStepIds.size + 1 === stepIds.size && stepIds.size === allWorkflowStepIds.length;
};
