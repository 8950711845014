import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { EntityWebhookStatusToggleWarning } from '../EntityWebhookStatusToggleWarning.tsx';

type EntityWebhookDisableConfirmationModalProps = {
  readonly entityWebhookName: string;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
} & IAnimatedModalDialogProps;

export const EntityWebhookDisableConfirmationModal = ({
  entityWebhookName,
  isOpen,
  onCancel,
  onConfirm,
}: EntityWebhookDisableConfirmationModalProps) => (
  <ModalDialog
    headline="Disable webhook"
    isOpen={isOpen}
    onClose={onCancel}
    isDismissable
    primaryAction={{
      text: 'Disable',
      onClick: onConfirm,
    }}
    cancelAction={{
      onClick: onCancel,
    }}
  >
    <EntityWebhookStatusToggleWarning entityWebhookName={entityWebhookName} />
  </ModalDialog>
);

EntityWebhookDisableConfirmationModal.displayName = 'EntityWebhookDisableConfirmationModal';
