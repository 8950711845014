import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import { PublishingStateListingFilterOption } from './ListingFilterOption.type.ts';

export interface IPublishingState {
  readonly published: boolean;
  readonly unpublished: boolean;
  readonly none: boolean;
}

export const isPublishingState = (input: unknown): input is IPublishingState => {
  const publishingStateKeys = Object.keys(emptyPublishingState);
  return input instanceof Object && publishingStateKeys.every((key) => Object.hasOwn(input, key));
};

export const emptyPublishingState: IPublishingState = {
  published: false,
  unpublished: false,
  none: false,
};

const PublishedOption = {
  id: PublishingState.Published,
  label: 'Published',
} satisfies PublishingStateListingFilterOption;

const UnpublishedOption = {
  id: PublishingState.Unpublished,
  label: 'Unpublished',
} satisfies PublishingStateListingFilterOption;

const NoneOption = {
  id: PublishingState.None,
  label: 'Not published yet',
} satisfies PublishingStateListingFilterOption;

export const PublishingStateOptions = [PublishedOption, UnpublishedOption, NoneOption] as const;

export function checkEqualityOfPublishingStates(
  first: IPublishingState,
  second: IPublishingState,
): boolean {
  return (
    first.published === second.published &&
    first.unpublished === second.unpublished &&
    first.none === second.none
  );
}

export function getPublishingStateFromIds(
  publishingStateIds: ReadonlySet<string>,
): IPublishingState {
  return {
    published: publishingStateIds.has(PublishedOption.id),
    unpublished: publishingStateIds.has(UnpublishedOption.id),
    none: publishingStateIds.has(NoneOption.id),
  };
}

export const isPublishingStateEmpty = (publishingState: IPublishingState): boolean =>
  !(publishingState.published || publishingState.unpublished || publishingState.none);

export function convertPublishingStateToQueryParams(
  publishingState: IPublishingState,
): ReadonlyArray<PublishingState> {
  const used: PublishingState[] = [];

  if (publishingState.published) {
    used.push(PublishedOption.id);
  }

  if (publishingState.unpublished) {
    used.push(UnpublishedOption.id);
  }

  if (publishingState.none) {
    used.push(NoneOption.id);
  }

  return used;
}
