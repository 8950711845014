import { PortalContainerContextProvider } from '@kontent-ai/component-library/context';
import { BaseColor, easing } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import React, { HTMLAttributes, PropsWithChildren, useEffect } from 'react';
import styled, { Keyframes, css } from 'styled-components';
import { ScrollContainerContextProvider } from '../../../ScrollContainer/ScrollContainerContext.tsx';
import { fadeIn, fadeOut, getAnimationTime } from '../../animations.ts';
import { ArrowSize } from '../ArrowSizeEnum.ts';
import { Arrow } from './Arrow.tsx';

export interface IAnimatedPopoverDialogProps extends HTMLAttributes<HTMLDivElement> {
  readonly allowAnimation?: boolean;
  readonly arrowColor?: BaseColor;
  readonly arrowSize?: ArrowSize;
  readonly autoFocusRef: React.RefObject<HTMLElement>;
  readonly isOpen: boolean;
}

function getAnimation(allowAnimation: boolean, isOpen: boolean): Keyframes | 'none' {
  if (!allowAnimation) {
    return 'none';
  }

  return isOpen ? fadeIn : fadeOut;
}

const AnimatedDiv = styled.div<
  Required<Pick<IAnimatedPopoverDialogProps, 'allowAnimation' | 'isOpen'>>
>`
  animation: ${({ allowAnimation, isOpen }) =>
    css`${getAnimation(allowAnimation, isOpen)} ${
      allowAnimation ? getAnimationTime() : 0
    }ms ${easing}`};
`;

export const AnimatedPopoverDialog = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<IAnimatedPopoverDialogProps>
>(
  (
    { allowAnimation = true, arrowColor, arrowSize, autoFocusRef, children, isOpen, ...otherProps },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef<HTMLDivElement>(forwardedRef);

    useEffect(() => {
      autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    return (
      <PortalContainerContextProvider portalContainerRef={refObject}>
        <ScrollContainerContextProvider
          // We reset the tippy boundary, as at this level it is already guaranteed
          // that the popover (e.g. RTE toolbar) won't overflow the scrolling container
          // If there was a nested component positioned with Tippy (e.g. RTE toolbar button menu)
          // we want it to be able to leverage the whole viewport as it is already guaranteed
          // by the above that it's trigger will be visible
          tippyBoundaryRef={null}
        >
          <AnimatedDiv
            allowAnimation={allowAnimation}
            isOpen={isOpen}
            ref={refToForward}
            {...otherProps}
          >
            {children}
            <Arrow color={arrowColor} data-popper-arrow="" arrowSize={arrowSize} />
          </AnimatedDiv>
        </ScrollContainerContextProvider>
      </PortalContainerContextProvider>
    );
  },
);

AnimatedPopoverDialog.displayName = 'AnimatedPopoverDialog';
