import { isCumulatedOperationResponseMessage } from '../../../repositories/serverModels/ai/AiServerModels.response.ts';
import { AnyAiMessagePayload } from '../../services/signalR/signalRClient.type.ts';

export const findHighestSequenceNumber = (messages: ReadonlyArray<AnyAiMessagePayload>): number =>
  messages.reduce(
    (highestSequenceNumber, message) =>
      isCumulatedOperationResponseMessage(message) &&
      message.result.sequenceNumber > highestSequenceNumber
        ? message.result.sequenceNumber
        : highestSequenceNumber,
    0,
  );
