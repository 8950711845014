import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { IInviteRepository } from '../../../../../repositories/interfaces/IInviteRepository.type.ts';
import {
  User_ResendInvite_Failed,
  User_ResendInvite_Finished,
  User_ResendInvite_Started,
} from '../../constants/usersActionTypes.ts';

interface IDeps {
  readonly inviteRepository: Pick<IInviteRepository, 'resendInvite'>;
}

const started = (userId: Uuid) =>
  ({
    type: User_ResendInvite_Started,
    payload: { userId },
  }) as const;

const finished = (userId: Uuid) =>
  ({
    type: User_ResendInvite_Finished,
    payload: { userId },
  }) as const;

const failed = (userId: Uuid) =>
  ({
    type: User_ResendInvite_Failed,
    payload: { userId },
  }) as const;

export type ResendInviteActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const createResendInviteAction =
  ({ inviteRepository }: IDeps) =>
  (userId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      usersApp: { projectContributors },
    } = getState();

    const selectedContributor = projectContributors.get(userId);
    if (!selectedContributor) {
      logError(`There is no project contributor with the provided id (${userId}).`);
      dispatch(failed(userId));
      return;
    }

    dispatch(started(userId));
    try {
      await inviteRepository.resendInvite({ invitedEmail: selectedContributor.email });
      dispatch(finished(userId));
    } catch (e) {
      logError(e);
      dispatch(failed(userId));
    }
  };
