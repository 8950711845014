import Immutable from 'immutable';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import { PredefinedRoles } from '../../roles/constants/predefinedRoles.ts';
import { IRoleOption } from '../components/roleBuilder/RoleSelector.tsx';

const getDisabledTooltip = (isSelectedSomewhereElse: boolean): string | undefined =>
  isSelectedSomewhereElse ? 'This role has already assigned language' : undefined;

export const getRoleBuilderRoleOptions = (
  userRole: IProjectContributorRole,
  roles: Immutable.List<IRole>,
  usedRoleIds: Immutable.Set<Uuid>,
): ReadonlyArray<IRoleOption> =>
  roles.toArray().map((role) => {
    const isSelectedSomewhereElse = role.id !== userRole.roleId && usedRoleIds.has(role.id);

    const disabledTooltip = getDisabledTooltip(isSelectedSomewhereElse);

    return {
      disabledTooltip,
      id: role.id,
      isProjectManagerRole: role.codename === PredefinedRoles.ProjectManager,
      name: role.name,
    };
  });
