import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { LoadListingContentItemsByIdsAction } from '../../../../../../data/actions/thunks/listingContentItems/loadListingContentItemsByIds.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingContentItemServerModel } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { listingItemsLoaded } from '../../../../actions/contentActions.ts';

export type ILoadListingItemsAction = (
  contentItemIds: ReadonlyArray<Uuid>,
  abortSignal?: AbortSignal,
) => ThunkPromise<ReadonlyArray<IListingContentItem>>;

interface IDeps {
  readonly loadListingContentItemsByIds: LoadListingContentItemsByIdsAction;
}

export const createLoadListingItemsAction =
  (deps: IDeps): ILoadListingItemsAction =>
  (contentItemIds, abortSignal) =>
  async (dispatch, getState) => {
    if (contentItemIds.length === 0) {
      return [];
    }

    const variantId = getSelectedLanguageIdOrThrow(getState());

    const data = await dispatch(
      deps.loadListingContentItemsByIds(variantId, contentItemIds, abortSignal),
    );
    const contentItems = data
      .filter((item: IListingContentItemServerModel) => !!item)
      .map(getListingContentItemFromJS);

    dispatch(listingItemsLoaded(contentItems));

    return contentItems;
  };
