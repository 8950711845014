import { Button } from '@kontent-ai/component-library/Button';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { createGuid } from '@kontent-ai/utils';
import { animated, useTransition } from '@react-spring/web';
import { FC, useState } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CollectionsMap } from '../../../../../data/models/collections/Collection.ts';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import { getAnyCollectionOptionName } from '../../utils/getAnyCollectionOptionName.ts';
import { CollectionsGroup } from './CollectionsGroup.tsx';

type Props = {
  readonly addCollectionGroupDisabledTooltip: string | undefined;
  readonly allSelectedCollectionIds: ReadonlySet<Uuid>;
  readonly canAddNewGroupInCurrentPlan: boolean;
  readonly canChangeCollectionsInCurrentPlan: boolean;
  readonly collections: CollectionsMap;
  readonly collectionsGroups: readonly ICollectionGroupRoles[];
  readonly contributorId?: Uuid | undefined;
  readonly disabledTooltip: string | undefined;
  readonly isLastGroupFocused: boolean;
  readonly isProjectManagerSelectionValid: boolean;
  readonly onGroupAdd: () => void;
  readonly onGroupChange: (
    originalGroup: ICollectionGroupRoles,
    updatedGroup: ICollectionGroupRoles,
  ) => void;
  readonly onGroupRemove: ((group: ICollectionGroupRoles) => void) | null;
};

const AnimatedCollectionsGroup = animated(CollectionsGroup);

const getInitialAnimationKeys = (groups: readonly ICollectionGroupRoles[]): readonly string[] =>
  groups.map(() => createGuid());

const getTopMarginForCollectionsGroup = (index: number): string =>
  index === 0 ? '0px' : px(Spacing.L);

export const CollectionGroups: FC<Props> = ({
  addCollectionGroupDisabledTooltip,
  allSelectedCollectionIds,
  canAddNewGroupInCurrentPlan,
  canChangeCollectionsInCurrentPlan,
  collections,
  collectionsGroups,
  contributorId,
  disabledTooltip,
  isLastGroupFocused,
  isProjectManagerSelectionValid,
  onGroupAdd,
  onGroupChange,
  onGroupRemove,
}) => {
  const [animationKeys, setAnimationKeys] = useState(() =>
    getInitialAnimationKeys(collectionsGroups),
  );

  const transitions = useTransition(collectionsGroups, {
    keys: (group) => animationKeys[collectionsGroups.indexOf(group)] || '',
    enter: (group) => ({
      marginTop: getTopMarginForCollectionsGroup(collectionsGroups.indexOf(group)),
      paddingTop: px(Spacing.XL),
      paddingBottom: px(Spacing.XL),
      opacity: 1,
    }),
    leave: {
      height: '0px',
      marginTop: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      opacity: 0,
    },
  });

  return (
    <>
      {transitions((style, group, _, index) => {
        const anyCollectionOptionName = getAnyCollectionOptionName({
          allSelectedCollectionIds,
          collections,
          collectionsGroups,
          groupCollectionIds: group.collectionIds,
        });

        const onRemove = onGroupRemove
          ? () => {
              setAnimationKeys((prevKeys) => {
                const newKeys = [...prevKeys];
                newKeys.splice(index, 1);
                return newKeys;
              });
              onGroupRemove(group);
            }
          : null;

        const isLastGroup = index === collectionsGroups.length - 1;

        return (
          <AnimatedCollectionsGroup
            style={style}
            allSelectedCollectionIds={allSelectedCollectionIds}
            anyCollectionOptionName={anyCollectionOptionName}
            autoFocus={isLastGroupFocused && isLastGroup}
            canChangeCollectionsInCurrentPlan={canChangeCollectionsInCurrentPlan}
            contributorId={contributorId}
            disabledTooltip={disabledTooltip}
            group={group}
            isProjectManagerSelectionValid={isProjectManagerSelectionValid}
            onChange={(updatedGroup) => onGroupChange(group, updatedGroup)}
            onRemove={onRemove}
          />
        );
      })}
      {canAddNewGroupInCurrentPlan && (
        <Button
          tooltipText={disabledTooltip || addCollectionGroupDisabledTooltip}
          tooltipPlacement="top-start"
          buttonStyle="primary"
          disabled={!!disabledTooltip || !!addCollectionGroupDisabledTooltip}
          onClick={() => {
            setAnimationKeys((prevKeys) => [...prevKeys, createGuid()]);
            onGroupAdd();
          }}
          css={`
              margin-top: ${px(Spacing.XL)};
            `}
          {...getDataUiActionAttribute(DataUiAction.Add)}
        >
          Assign role in another collection
        </Button>
      )}
    </>
  );
};

CollectionGroups.displayName = 'CollectionGroups';
