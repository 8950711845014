import { SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';
import { formatDateTimeToReadableWithAt } from '../../../../_shared/components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import { isTimeInPast } from '../../../../_shared/utils/dateTime/timeUtils.ts';

const isApiKeyExpired = (time?: string | null) => !!time && isTimeInPast(new Date(), time);

interface ApiKeyExpirationStatusProps {
  readonly expiresAt: string;
}

export const ApiKeyExpirationStatus: React.FC<ApiKeyExpirationStatusProps> = ({ expiresAt }) => {
  const expirationInReadableFormat = formatDateTimeToReadableWithAt(new Date(expiresAt));

  if (isApiKeyExpired(expiresAt)) {
    return (
      <SimpleStatusError
        label={expirationInReadableFormat}
        tooltipText={`The API key expired on ${expirationInReadableFormat}.`}
      />
    );
  }

  return <span>{expirationInReadableFormat}</span>;
};
