import { getDataAttribute } from '../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { ObjectWithDataAttribute } from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export enum ElementAttributes {
  AssetId = 'data-asset-id',
  RichTextCommentSegmentId = 'data-comment-segment-id',
  BlurCommentThreadOnClick = 'data-blur-comment-thread-on-click',
  ContentComponentId = 'data-content-component-id',
  ElementId = 'data-element-id',
  ElementSupportsComments = 'data-element-supports-comments',
  LinkedItemId = 'data-linked-item-id',
}

export const getAssetIdAttribute = (assetId: string): ObjectWithDataAttribute =>
  getDataAttribute(ElementAttributes.AssetId, assetId);

export const getContentComponentIdAttribute = (
  contentComponentId: string,
): ObjectWithDataAttribute =>
  getDataAttribute(ElementAttributes.ContentComponentId, contentComponentId);

export const getElementIdAttribute = (objectGuid: string): ObjectWithDataAttribute =>
  getDataAttribute(ElementAttributes.ElementId, objectGuid);

export const getElementSupportsCommentsAttribute = (elementSupportsComments: boolean) =>
  elementSupportsComments && getDataAttribute(ElementAttributes.ElementSupportsComments, 'true');

export const getLinkedItemIdAttribute = (linkedItemId: Uuid): ObjectWithDataAttribute =>
  getDataAttribute(ElementAttributes.LinkedItemId, linkedItemId);
