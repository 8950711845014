import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { Languages } from '../../../../../../data/models/languages/Language.ts';
import { ILanguagesData } from '../../../../../../data/reducers/languages/ILanguagesData.type.ts';
import { getAllActiveLanguagesForCurrentUserForCollection } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { IUser } from '../../../../../../data/reducers/user/IUser.type.ts';
import { IContentItemVariantReference } from '../../../../models/contentItem/ContentItemVariantReference.ts';

type MenuOption = {
  readonly variantId: Uuid;
  readonly name: string;
  readonly isDisabled: boolean;
};

function createLanguageOptions(
  languages: Languages,
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
): ReadonlyArray<MenuOption> {
  const options = languages
    .valueSeq()
    .toArray()
    .filter((language) => {
      const variant = contentItemVariants.get(language.id);
      const doesVariantExist = !!variant && !variant.isArchived;
      return doesVariantExist;
    })
    .map((language) => ({
      variantId: language.id,
      name: language.name,
      isDisabled: !hasActiveVariantCapability(
        ActiveCapabilityType.ViewContent,
        contentItemVariants.get(language.id),
      ),
    }));

  return options;
}

function removeAlreadyEditedVariantOption(
  options: ReadonlyArray<MenuOption>,
  selectedLanguageId: Uuid,
): ReadonlyArray<MenuOption> {
  return options.filter((option) => selectedLanguageId !== option.variantId);
}

function getLanguageOptions(
  languages: ILanguagesData,
  selectedLanguageId: Uuid,
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
  user: IUser,
  projectId: Uuid,
  collectionId: Uuid | null,
): ReadonlyArray<MenuOption> {
  const allLanguages = getAllActiveLanguagesForCurrentUserForCollection(
    user,
    projectId,
    collectionId,
    languages,
    Capability.ViewContent,
  );
  const allOptions = createLanguageOptions(allLanguages, contentItemVariants);
  return removeAlreadyEditedVariantOption(allOptions, selectedLanguageId);
}

export const getLanguageOptionsMemoized = memoize.maxN(getLanguageOptions, 5);
