import { subHours } from 'date-fns';
import { useCallback } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { TaskWidgetDetailTableDemoContainer } from '../../shared/containers/TaskWidgetDetailTableDemoContainer.tsx';
import { createDemoTask } from '../../shared/utils/createDemoTask.ts';
import { MostOverdueTasksWidget } from '../components/MostOverdueTasksWidget.tsx';

const today = new Date();
const createDemoData = (userId: UserId) => [
  createDemoTask({
    description:
      'Hey, can you take a quick look at this article and make sure it’s ready to go live?',
    due: subHours(today, 100).toISOString(),
    owner: userId,
    assignees: [userId],
  }),
  createDemoTask({
    description: 'Please check if this link is working as expected.',
    due: subHours(today, 30).toISOString(),
    owner: userId,
    assignees: [userId],
  }),
  createDemoTask({
    description: 'Could you update the metadata for better SEO on this piece?',
    due: subHours(today, 10).toISOString(),
    owner: userId,
    assignees: [userId],
  }),
  createDemoTask({
    description: 'Let’s ensure all the images have the correct attributions and licenses.',
    due: subHours(today, 1).toISOString(),
    owner: userId,
    assignees: [userId],
  }),
];

export const MostOverdueTasksWidgetDemoContainer = () => {
  const currentUserId = useSelector(getCurrentUserId);

  const tasksFetcher = useCallback(
    () => Promise.resolve({ data: createDemoData(currentUserId), continuationToken: null }),
    [currentUserId],
  );

  return (
    <MostOverdueTasksWidget
      tasksFetcher={tasksFetcher}
      renderWidgetDetail={(tasks, state) => (
        <TaskWidgetDetailTableDemoContainer tasks={tasks} state={state} />
      )}
    />
  );
};
