import React from 'react';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DisabledUsageInItemVariant } from '../../../../../../_shared/components/Usages/DisabledUsageInItemVariant.tsx';
import { EnabledUsageInItemVariant } from '../../../../../../_shared/components/Usages/EnabledUsageInItemVariant.tsx';
import { createBuildLinkForVariantReference } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { UsageItemReference } from '../../../../../../_shared/utils/usage/usageUtils.ts';

type SingleContentItemUsageProps = {
  readonly contentItemId: Uuid | undefined;
  readonly currentPath: string;
  readonly disabledMessage: string | undefined;
  readonly onNavigateToItem: () => void;
  readonly projectId: Uuid;
  readonly typeName: string | null;
  readonly usage: UsageItemReference;
};

export const SingleContentItemUsage: React.FC<SingleContentItemUsageProps> = ({
  contentItemId,
  currentPath,
  disabledMessage,
  onNavigateToItem,
  projectId,
  typeName,
  usage,
}) => {
  // Scroll to the first usage of this content item
  if (!disabledMessage && contentItemId) {
    const scrollId = CreateAutoScrollId.forUsageItem(usage.primary.id.itemId);
    const toScrollId = CreateAutoScrollId.forContentItem(contentItemId);

    return (
      <EnabledUsageInItemVariant
        buildLink={createBuildLinkForVariantReference(projectId, currentPath)}
        onNavigateToItem={onNavigateToItem}
        scrollId={scrollId}
        toScrollId={toScrollId}
        typeName={typeName}
        usage={usage}
      />
    );
  }

  return (
    <DisabledUsageInItemVariant message={disabledMessage || ''} variantReference={usage.primary} />
  );
};

SingleContentItemUsage.displayName = 'SingleContentItemUsage';
