import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  ContentTypeSelectorState,
  getContentTypeSelectorState,
} from '../utils/getContentTypeSelectorState.ts';
import { getAllowedContentTypes } from './getAllowedContentTypes.ts';
import { getAvailableContentTypes } from './getAvailableContentTypes.ts';
import { getSuggestedContentTypes } from './getSuggestedContentTypes.ts';

const getContentTypeSelectorStateSelector = (state: IStore): ContentTypeSelectorState => {
  const availableContentTypes = getAvailableContentTypes(state);
  const allowedContentTypes = getAllowedContentTypes(state, availableContentTypes);
  const suggestedContentTypes = getSuggestedContentTypes(state, availableContentTypes);

  return getContentTypeSelectorState({
    allowedContentTypesCount: allowedContentTypes.size,
    suggestedContentTypesCount: suggestedContentTypes.size,
  });
};

export { getContentTypeSelectorStateSelector as getContentTypeSelectorState };
