import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { hasProjectMultipleWorkflows } from '../../../../../../../_shared/selectors/Workflows/hasProjectMultipleWorkflows.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishedWorkflowStep,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import {
  canChangeWorkflowForEditedItem,
  hasActiveVariantCapabilityForEditedItem,
} from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getWorkflow } from '../../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import {
  getSelectableWorkflowStepsForEditedItemDefaultVariant,
  getSelectableWorkflowStepsForEditedVariant,
} from '../../../../../../contentInventory/content/features/ContentItemInventory/selectors/getSelectableWorkflowSteps.ts';
import { EditedContentItemVariant } from '../../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { getWorkflowsCurrentUserCanMoveEditedVariantTo } from '../../../../../selectors/workflows/getWorkflowsCurrentUserCanMoveEditedVariantTo.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

const canUpdateAssignment = (state: IStore) =>
  hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateAssignment, state);

const isVariantInEditableWorkflowStep = (variant: EditedContentItemVariant) =>
  !isPublishingStepSelected(variant.assignment) &&
  !isArchivedWorkflowStepSelected(variant.assignment);

const isPublishStepAvailable = (
  editedContentItemVariant: EditedContentItemVariant,
  state: IStore,
): boolean => {
  const workflow = getWorkflow(
    state,
    editedContentItemVariant.assignment.workflowStatus.workflowId,
  );

  return getSelectableWorkflowStepsForEditedVariant(
    editedContentItemVariant,
    workflow ?? null,
  ).some(isPublishedWorkflowStep);
};

export const isPublishingStepAvailableForEditedItemDefaultVariant = (
  listingItemWithDefaultVariant: IListingContentItem | null,
  state: IStore,
): boolean => {
  if (!listingItemWithDefaultVariant?.variant) {
    return false;
  }

  const workflow = getWorkflow(
    state,
    listingItemWithDefaultVariant.variant.assignment.workflowStatus.workflowId,
  );
  return getSelectableWorkflowStepsForEditedItemDefaultVariant(
    listingItemWithDefaultVariant,
    workflow ?? null,
  ).some(isPublishedWorkflowStep);
};

export const isAssignPeopleAvailable: ContentItemEditingActionAvailabilityProvider = (state) =>
  canUpdateAssignment(state);

export const isChangeDueDateAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) =>
  isVariantInEditableWorkflowStep(variant) &&
  canUpdateAssignment(state) &&
  !!variant.assignment.due;

export const isSetDueDateAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) =>
  isVariantInEditableWorkflowStep(variant) && canUpdateAssignment(state) && !variant.assignment.due;

export const isChangeNoteAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) => canUpdateAssignment(state) && !!variant.assignment.note;

export const isSetNoteAvailable: ContentItemEditingActionAvailabilityProvider = (state, variant) =>
  canUpdateAssignment(state) && !variant.assignment.note;

export const isChangeWorkflowAvailable = (state: IStore): boolean =>
  canChangeWorkflowForEditedItem(state) &&
  hasProjectMultipleWorkflows(state) &&
  areThereAnyWorkflowsUserCanMoveVariantTo(state);

const areThereAnyWorkflowsUserCanMoveVariantTo = (state: IStore): boolean =>
  getWorkflowsCurrentUserCanMoveEditedVariantTo(state).length > 0;

export const isChangeWorkflowStepAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) => isVariantInEditableWorkflowStep(variant) && canUpdateAssignment(state);

export const isPublishAvailable: ContentItemEditingActionAvailabilityProvider = (state, variant) =>
  isVariantInEditableWorkflowStep(variant) &&
  canUpdateAssignment(state) &&
  isPublishStepAvailable(variant, state);
