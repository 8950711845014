import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  allFiltersCleared,
  nameListingFilterUpdated,
} from '../actions/subscriptionUserListingActions.ts';
import { SubscriptionUsersScrollTableEmptyState as Component } from '../components/SubscriptionUsersScrollTableEmptyState.tsx';

interface ISubscriptionUsersScrollTableEmptyStateOwnProps {
  readonly anyFilteredResults: boolean;
}

export const SubscriptionUsersScrollTableEmptyState: React.FC<
  ISubscriptionUsersScrollTableEmptyStateOwnProps
> = ({ anyFilteredResults }) => {
  const dispatch = useDispatch();
  const searchPhrase = useSelector((state) => state.subscriptionApp.users.listingUi.filter.byName);

  return (
    <Component
      searchPhrase={searchPhrase}
      noFilteredResults={!anyFilteredResults}
      onClearFilter={() => dispatch(allFiltersCleared())}
      onClearSearchPhrase={() => dispatch(nameListingFilterUpdated(''))}
    />
  );
};

SubscriptionUsersScrollTableEmptyState.displayName = 'SubscriptionUsersScrollTableEmptyState';
