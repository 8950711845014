import { Action } from '../../../../../@types/Action.type.ts';
import { Environment_EditedEnvironmentName_Updated } from '../../constants/generalSettingsActionTypes.ts';

const defaultState = '';

export const editedEnvironmentName = (state: string = defaultState, action: Action): string => {
  switch (action.type) {
    case Environment_EditedEnvironmentName_Updated: {
      return action.payload.name;
    }

    default:
      return state;
  }
};
