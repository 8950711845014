import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapabilityInLanguage } from '../../../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { getCurrentProject } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { spacesContainCollection } from '../../../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly onSpaceSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly selectedLanguageId: Uuid;
  readonly selectedSpaces: ReadonlySet<Uuid>;
  readonly user: IUser;
};

export const SpacesFilterSelector = (props: Props) => {
  const collectionsById = useSelector((state) => state.data.collections.byId);
  const collections = useMemo(() => Collection.getValues(collectionsById), [collectionsById]);
  const { projectId } = useSelector(getCurrentProject);
  const spacesById = useSelector((state) => state.data.spaces.byId);
  const spaces = useMemo(() => Collection.getValues(spacesById), [spacesById]);
  const label = 'Select a space';

  const availableCollections = useMemo(
    () =>
      filterCollectionsByCapabilityInLanguage(
        collections,
        props.selectedLanguageId,
        Capability.ViewContent,
        projectId,
        props.user,
      ),
    [collections, projectId, props.user, props.selectedLanguageId],
  );

  const areAllCollectionsAvailable = availableCollections.length === collections.length;
  const availableSpaces = useMemo(
    () =>
      areAllCollectionsAvailable
        ? spaces
        : spacesContainCollection(
            spaces,
            availableCollections.map((collection) => collection.id),
          ),
    [spaces, availableCollections, areAllCollectionsAvailable],
  );

  const selectedOptionIds = useMemo(
    () => availableSpaces.filter((space) => props.selectedSpaces.has(space.id)).map((c) => c.id),
    [props.selectedSpaces, availableSpaces],
  );

  const options: ReadonlyArray<IBaseSelectItem> = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return availableSpaces.length ? (
    <ListingFilterCategorySelector
      options={options}
      onChange={props.onSpaceSelectionChanged}
      collection={DataUiCollection.Spaces}
      placeholder={label}
      ariaLabel={label}
      selectedOptionIds={selectedOptionIds}
      title="Space"
    />
  ) : null;
};
