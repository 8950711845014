import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { notUndefined } from '@kontent-ai/utils';
import { mergeProps } from '@react-aria/utils';
import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  AiMenuItem,
  createDiscardAiAction,
} from '../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';

type InstructionMenuProps = {
  readonly isActionFailed: boolean;
  readonly isActionFinished: boolean;
  readonly onAccept?: () => void;
  readonly onDiscard?: () => void;
  readonly onEditInstruction?: () => void;
  readonly onTryAgain?: () => void;
};

export const InstructionMenu: React.FC<InstructionMenuProps> = ({
  isActionFailed,
  isActionFinished,
  onAccept,
  onDiscard,
  onEditInstruction,
  onTryAgain,
}) => {
  const acceptAction: AiMenuItem | undefined = onAccept && {
    id: 'accept',
    label: 'Accept generated text',
    iconName: IconName.Check,
    onClick: onAccept,
  };
  const editInstructionsAction: AiMenuItem | undefined = onEditInstruction && {
    id: 'edit-instructions',
    label: 'Edit instructions',
    iconName: IconName.Edit,
    onClick: onEditInstruction,
  };
  const tryAgainAction: AiMenuItem | undefined =
    !isActionFailed && onTryAgain
      ? {
          id: 'try-again',
          label: 'Try again',
          iconName: IconName.RotateDoubleRight,
          onClick: onTryAgain,
        }
      : undefined;

  const menuItems: ReadonlyArray<AiMenuItem> = [
    acceptAction,
    editInstructionsAction,
    tryAgainAction,
    onDiscard && createDiscardAiAction(onDiscard, isActionFinished, isActionFailed),
  ].filter(notUndefined);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } =
    useVerticalMenu<AiMenuItem>(menuItems);

  return (
    <VerticalMenu
      {...mergeProps(verticalMenuProps)}
      aria-label="Instruction menu"
      state={verticalMenuState}
      renderItem={({ item }) =>
        item.value && (
          <MenuItemWithMouseHover
            menuItemState="default"
            text={item.value.label}
            leadingElement={<Icon iconName={item.value.iconName} />}
            onPress={item.value.onClick}
          />
        )
      }
    />
  );
};

InstructionMenu.displayName = 'InstructionMenu';
