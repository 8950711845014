import { Collection } from '@kontent-ai/utils';
import { OptionType } from '../../../../_shared/components/MultipleOptionSelect/MultipleSelectDropDownOption.tsx';
import { IHierarchicalItem } from '../../../../_shared/utils/hierarchicalOptionsUtils.ts';
import {
  ITaxonomyTerm,
  emptyTaxonomyTerm,
} from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

export const DeletedTaxonomyTermName = 'Deleted taxonomy term';

const getDeletedTaxonomyTermHierarchicalItem = (termId: Uuid): IHierarchicalItem => ({
  id: termId,
  name: DeletedTaxonomyTermName,
  selectedName: DeletedTaxonomyTermName,
  indentation: 1,
  type: OptionType.Item,
  path: [],
});

export const getDeletedTaxonomyTermObject = (termId: Uuid): ITaxonomyTerm => ({
  ...emptyTaxonomyTerm,
  id: termId,
  name: DeletedTaxonomyTermName,
});

export const getDeletedTaxonomyTermIds = (
  selectedOptionIds: ReadonlySet<Uuid>,
  flatTaxonomyGroup: ReadonlyArray<IHierarchicalItem>,
): ReadonlyArray<Uuid> => {
  const existingTermIds = flatTaxonomyGroup.map((term) => term.id);
  return [...Collection.removeMany(selectedOptionIds, existingTermIds)];
};

export const getDeletedTaxonomyTermOptions = (
  selectedOptionIds: UuidArray,
  flatTaxonomyGroup: ReadonlyArray<IHierarchicalItem>,
): ReadonlyArray<IHierarchicalItem> => {
  const deletedTaxonomyTerms = selectedOptionIds.filter(
    (termId) => !flatTaxonomyGroup.some((option) => option?.id === termId),
  );
  return deletedTaxonomyTerms.map((deletedTaxonomyTerm) =>
    getDeletedTaxonomyTermHierarchicalItem(deletedTaxonomyTerm),
  );
};
