import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  ExpiredSubscriptionsWarning as ExpiredSubscriptionsWarningComponent,
  ISubscriptionWarningLinkData,
} from '../components/ExpiredSubscriptionsWarning.tsx';

function getSubscriptionLinks(
  projects: Immutable.List<IProjectDetails>,
): ReadonlyArray<ISubscriptionWarningLinkData> {
  return projects
    .groupBy((p: IProjectDetails) => p.subscriptionId)
    .toArray()
    .map((g: Immutable.Map<number, IProjectDetails>) => {
      const firstDetail = g.first();
      return firstDetail
        ? {
            subscriptionId: firstDetail.subscriptionId,
            subscriptionName: firstDetail.subscriptionName,
          }
        : null;
    })
    .filter(notNull);
}

export const ExpiredSubscriptionsWarning: React.FC = () => {
  const projects = useSelector((state) => state.data.projects.byId);
  const administratedSubscriptionsIds = useSelector(
    (state) => state.data.subscriptions.administratedIds,
  );

  const expiredProjects = projects
    .filter((project: IProjectDetails) => project.planIsExpired)
    .toList();
  const administratedExpiredProjects = expiredProjects
    .filter((p: IProjectDetails) => administratedSubscriptionsIds.contains(p.subscriptionId))
    .toList();
  const administratedSubscriptionLinks = getSubscriptionLinks(administratedExpiredProjects);
  const notAdministratedProjects = expiredProjects
    .filter((p: IProjectDetails) => !administratedSubscriptionsIds.contains(p.subscriptionId))
    .toList();
  const notAdministratedSubscriptionLinks = getSubscriptionLinks(notAdministratedProjects);

  if (expiredProjects.isEmpty()) {
    return null;
  }

  return (
    <ExpiredSubscriptionsWarningComponent
      administratedSubscriptionLinks={administratedSubscriptionLinks}
      notAdministratedSubscriptionLinks={notAdministratedSubscriptionLinks}
    />
  );
};

ExpiredSubscriptionsWarning.displayName = 'ExpiredSubscriptionsWarningContainer';
