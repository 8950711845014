import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

export const getTaxonomyTermCodenames = memoize.weak(
  (taxonomyTermsById: ReadonlyMap<Uuid, ITaxonomyTerm>): ReadonlySet<string> =>
    new Set(Collection.getValues(taxonomyTermsById).map((taxonomy) => taxonomy.codename)),
);

export const getTaxonomyGroupCodenamesWithoutEditedTaxonomyGroupCodename = memoize.maxOne(
  (
    taxonomyGroupsById: Immutable.Map<Uuid, ITaxonomyGroup>,
    editedTaxonomyGroupId: Uuid,
  ): ReadonlySet<string> => {
    const codenames = new Set(
      taxonomyGroupsById
        .valueSeq()
        .map((taxonomy: ITaxonomyGroup) => taxonomy.codename)
        .toArray(),
    );
    const editedTaxonomy = taxonomyGroupsById.get(editedTaxonomyGroupId);

    if (!!editedTaxonomy && editedTaxonomy.codename) {
      return Collection.remove(codenames, editedTaxonomy.codename);
    }

    return codenames;
  },
);
