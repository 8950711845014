import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback, useMemo } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { setRichTextClipboard } from '../../../../../../richText/actions/thunkRichTextActions.ts';
import { SimpleMultilineTextDiff } from '../../../../../../richText/editors/simpleText/SimpleMultilineTextDiff.tsx';
import { EmptyMetadata } from '../../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import {
  createSimpleTextValueContent,
  createSimpleTextValueEditorState,
} from '../../../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { EmptyContentComponents } from '../../../../../models/contentItem/ContentComponent.ts';
import { ICustomItemElement } from '../../../../../models/contentItemElements/CustomItemElement.ts';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';

interface ICustomElementDiffProps
  extends Omit<IRevisionDiffElementProps<ICustomItemElement>, 'typeElement'> {}

export const CustomElementDiff: React.FC<ICustomElementDiffProps> = ({
  elementData,
  originalElementData,
}) => {
  const value = useMemo(
    () => createSimpleTextValueEditorState(elementData.value || ''),
    [elementData],
  );
  const originalValue = useMemo(
    () =>
      originalElementData
        ? createSimpleTextValueContent(originalElementData.value || '')
        : undefined,
    [originalElementData],
  );

  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  return (
    <SimpleMultilineTextDiff
      editorState={value}
      originalContent={originalValue}
      setRichTextClipboard={onSetRichTextClipboard}
    />
  );
};

CustomElementDiff.displayName = 'CustomDiff';
