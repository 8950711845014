import { InvariantException } from '@kontent-ai/errors';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';
import { IConvertElementsToDomainModel } from '../../../../stores/utils/contentItemHelperMethods.ts';
import { ILoadRelatedContentItemElementsDataAction } from '../../../ContentItemEditing/actions/thunks/loadRelatedContentItemElementsData.ts';

interface ILoadRevisionActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly trackUserEvent?: TrackUserEventWithDataAction;
  readonly convertElementsToDomainModel: IConvertElementsToDomainModel;
  readonly revisionLoaded: (
    timelineItem: TimelineItem,
    elements: ReadonlyArray<ICompiledContentItemElementData>,
  ) => Action;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
}

export const loadRevisionActionCreator =
  (deps: ILoadRevisionActionDependencies) =>
  (contentItemId: Uuid, timelineItem: TimelineItem, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const revisionId = timelineItem.revisionId;
    if (!revisionId) {
      throw InvariantException(
        'Cannot load revision for timeline item whose revisionId does not exist.',
      );
    }

    const revision = await deps.contentItemRepository.getVariantRevision(
      contentItemId,
      selectedLanguageId,
      revisionId,
      abortSignal,
    );
    const elements = deps.convertElementsToDomainModel(revision.contentElements);

    await dispatch(
      deps.loadRelatedContentItemElementsData(
        contentItemId,
        selectedLanguageId,
        elements,
        null,
        abortSignal,
      ),
    );

    dispatch(deps.revisionLoaded(timelineItem, elements));

    if (deps.trackUserEvent) {
      dispatch(
        deps.trackUserEvent(TrackedEvent.ItemRevisionOpened, {
          'revision-id': revisionId,
          'entry-id': contentItemId,
          age: undefined,
        }),
      );
    }
  };
