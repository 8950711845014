import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useEventListener } from '../../../../_shared/hooks/useEventListener.ts';
import { getNativeDomSelection } from '../../../../_shared/utils/selectionUtils.ts';

type SelectionRepresentation = string | null;

class UserSelectionChangeDetector {
  private _lastSelection: SelectionRepresentation = null;

  private readonly _getSelectionRepresentation = (
    selection: Selection | null,
  ): SelectionRepresentation => (selection ? selection.toString() : null);

  private readonly _isSelectionChangeSignificant = (
    before: SelectionRepresentation,
    after: SelectionRepresentation,
  ) => before !== after;

  saveNewSelection = (newSelection: Selection | null): void => {
    this._lastSelection = this._getSelectionRepresentation(newSelection);
  };

  hasSelectionChanged = (currentSelection: Selection | null): boolean => {
    const currentSelectionRepresentation = this._getSelectionRepresentation(currentSelection);
    return this._isSelectionChangeSignificant(currentSelectionRepresentation, this._lastSelection);
  };
}

export interface IHideDuringSelectionProps {
  readonly observeRef: React.RefObject<HTMLElement>;
}

const MaxDelayBetweenSelectionChangesMs = 500;

export const HideDuringSelection: React.FC<React.PropsWithChildren<IHideDuringSelectionProps>> = ({
  children,
  observeRef,
}) => {
  // By default, assume that there is ongoing selection, otherwise child could briefly show even in ongoing selection
  const [isSelecting, setIsSelecting] = useState(true);

  const scheduleExpectedSelectionEnd = useDebouncedCallback(
    () => setIsSelecting(false),
    MaxDelayBetweenSelectionChangesMs,
  );

  useEffect(() => scheduleExpectedSelectionEnd.cancel, [scheduleExpectedSelectionEnd]);

  const assumeOngoingSelection = useCallback(() => {
    setIsSelecting(true);
    scheduleExpectedSelectionEnd();
  }, [scheduleExpectedSelectionEnd]);

  const userSelectionChangeDetector = useMemo(() => new UserSelectionChangeDetector(), []);

  const refreshUserSelection = useCallback(() => {
    const currentSelection = getNativeDomSelection();

    // Only handle the selection when it happens inside the observed container
    if (
      !currentSelection?.anchorNode ||
      !observeRef.current?.contains(currentSelection.anchorNode)
    ) {
      return;
    }

    if (userSelectionChangeDetector.hasSelectionChanged(currentSelection)) {
      assumeOngoingSelection();
      userSelectionChangeDetector.saveNewSelection(currentSelection);
    }
  }, [assumeOngoingSelection, userSelectionChangeDetector, observeRef]);

  useEventListener('selectionchange', refreshUserSelection, self, true);

  return isSelecting ? null : <>{children}</>;
};

HideDuringSelection.displayName = 'HideDuringSelection';
