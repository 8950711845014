import { combineReducers } from '@reduxjs/toolkit';
import { apiKeysSettingsLoadingStatus } from './internalReducers/apiKeysSettingsLoadingStatus.ts';
import { deliveryKeysLoadingStatus } from './internalReducers/deliveryKeysLoadingStatus.ts';
import { detailActionStatus } from './internalReducers/detailActionStatus.ts';
import { detailLoadingStatus } from './internalReducers/detailLoadingStatus.ts';
import { hasAnyDapiKeys } from './internalReducers/hasAnyDapiKeys.ts';
import { hasAnyMapiKeys } from './internalReducers/hasAnyMapiKeys.ts';
import { managementKeysLoadingStatus } from './internalReducers/managementKeysLoadingStatus.ts';

export const apiKeysAppReducer = combineReducers({
  apiKeysSettingsLoadingStatus,
  deliveryKeysLoadingStatus,
  detailLoadingStatus,
  detailActionStatus,
  hasAnyDapiKeys,
  hasAnyMapiKeys,
  managementKeysLoadingStatus,
});
