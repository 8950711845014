import { BaseColor, colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getSubscriptionUserSlotStats } from '../../../../_shared/utils/subscriptionUsageUtils.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { getPlanName } from '../../shared/utils/planUtils.ts';
import {
  IDataPoint,
  IHighlightedDataPoint,
  OverviewBar,
} from '../components/OverviewBar/OverviewBar.tsx';

type SubscriptionOverviewBarProps = {
  readonly subscriptionPlan: IPlan;
};

export const SubscriptionOverviewBar: React.FC<SubscriptionOverviewBarProps> = ({
  subscriptionPlan,
}) => {
  const subscriptionUserSlotStats = useSelector(getSubscriptionUserSlotStats);
  const planName = getPlanName(subscriptionPlan.name);
  if (!planName) {
    throw InvariantException(
      `SubscriptionOverviewBarContainer.tsx: Cannot find plan name for ${subscriptionPlan.name}`,
    );
  }

  const dataPoints: ReadonlyArray<IDataPoint> = [
    {
      label: 'Your plan',
      content: subscriptionPlan.displayName,
    },
    {
      label: 'Current usage',
      content: `${subscriptionUserSlotStats.usedUserSlots} ${
        subscriptionUserSlotStats.usedUserSlots === 1 ? 'user' : 'users'
      }`,
    },
    {
      label: 'Users in your plan',
      content: subscriptionUserSlotStats.userSlotLimit,
    },
  ];

  const highlightedDataPoint: IHighlightedDataPoint = {
    label: 'Free seats in your plan',
    content: subscriptionUserSlotStats.availableFreeUserSlots,
    backgroundColor:
      subscriptionUserSlotStats.availableFreeUserSlots > 0
        ? BaseColor.NeonGreen40
        : colorAlertBackgroundInverse,
    contentUiElement: DataUiElement.SubscriptionUsersRemainingSeats,
  };

  return <OverviewBar dataPoints={dataPoints} highlightedDataPoint={highlightedDataPoint} />;
};

SubscriptionOverviewBar.displayName = 'SubscriptionOverviewBar';
