import { ContentTypeConversionOptions } from '../../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import {
  DefaultValueServerModelExtension,
  LinkedItemsRelationTypeServerModel,
  LinkedItemsTypeElementServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { IModularContentItemReferenceServerModel } from '../../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  ILinkedItemsTypeElementData,
  LinkedItemsRelationType,
  getLinkedItemsTypeElementDataFromServerModel,
} from '../../models/elements/LinkedItemsTypeElementData.ts';
import { hasLinkedItemsDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';
import { getIntegerFromString } from './utils/typeElementDataConvertersUtils.ts';

function convertTypeElementRecordToServerModel(
  typeElement: ILinkedItemsTypeElementData,
): LinkedItemsTypeElementServerModel {
  const minItems = typeElement.minItems && getIntegerFromString(typeElement.minItems);
  const maxItems = typeElement.maxItems && getIntegerFromString(typeElement.maxItems);

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    allowedTypes: typeElement.allowedTypes,
    default: convertDefaultValueToServerModel(typeElement),
    maxItems: maxItems && maxItems > 0 ? maxItems : null,
    minItems: minItems && minItems > 0 ? minItems : null,
    relationType:
      typeElement.relationType === LinkedItemsRelationType.Subpages
        ? LinkedItemsRelationTypeServerModel.Subpages
        : LinkedItemsRelationTypeServerModel.LinkedItems,
    type: ElementType.LinkedItems,
  };
}

function convertDefaultValueToServerModel(
  typeElement: ILinkedItemsTypeElementData,
): DefaultValueServerModelExtension<ReadonlyArray<IModularContentItemReferenceServerModel>> {
  if (!hasLinkedItemsDefaultValue(typeElement)) {
    return null;
  }

  const value = typeElement.defaultValue.map(
    (id): IModularContentItemReferenceServerModel => ({ _id: id }),
  );

  return {
    global: { value },
  };
}

export function createModularContentTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (
      serverTypeElement: LinkedItemsTypeElementServerModel,
      conversionOptions: ContentTypeConversionOptions,
    ) => getLinkedItemsTypeElementDataFromServerModel(serverTypeElement, conversionOptions),
    convertTypeElementRecordToServerModel: (typeElement: ILinkedItemsTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
