import { Box } from '@kontent-ai/component-library/Box';
import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import {
  DataUiAction,
  DataUiAppName,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookSetting } from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { EnvironmentSettingsAppNames } from '../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { EntityWebhookStatusTransitionState } from '../types/EntityWebhookStatusTransitionState.ts';
import { EntityWebhookQuickTip } from './EntityWebhooksQuickTip.tsx';
import { EntityWebhookDataTable } from './listing/EntityWebhookDataTable.tsx';
import { EntityWebhookEmptyState } from './listing/EntityWebhookEmptyState.tsx';

type EntityWebhookListingProps = {
  readonly createNewEntityWebhookPath: string;
  readonly onEnable: (webhookId: Uuid) => void;
  readonly onDisable: (webhookId: Uuid) => void;
  readonly onReset: (webhookId: Uuid) => void;
  readonly webhooks: ReadonlyArray<IEntityWebhookSetting>;
  readonly webhooksStatusTransitionStates: ReadonlyMap<Uuid, EntityWebhookStatusTransitionState>;
};

export const EntityWebhookListing = ({
  createNewEntityWebhookPath,
  onEnable,
  onDisable,
  onReset,
  webhooks,
  webhooksStatusTransitionStates,
}: EntityWebhookListingProps) => {
  if (webhooks.length === 0) {
    return (
      <div {...getDataUiAppNameAttribute(DataUiAppName.Webhooks)}>
        <Stack spacing={Spacing.XL}>
          <PageTitle>{EnvironmentSettingsAppNames.Webhooks}</PageTitle>
          <EntityWebhookEmptyState createNewEntityWebhookPath={createNewEntityWebhookPath} />
        </Stack>
      </div>
    );
  }

  return (
    <div {...getDataUiAppNameAttribute(DataUiAppName.Webhooks)}>
      <Row spacing={Spacing.XL}>
        <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
          <Stack spacing={Spacing.XL}>
            <Row>
              <Column>
                <PageTitle>{EnvironmentSettingsAppNames.Webhooks}</PageTitle>
              </Column>
              <Column width="fit-content">
                <RouterLinkButton
                  buttonStyle="primary"
                  to={createNewEntityWebhookPath}
                  {...getDataUiActionAttribute(DataUiAction.CreateNew)}
                >
                  Create new webhook
                </RouterLinkButton>
              </Column>
            </Row>
            <Row>
              <Box typography={Typography.SubheadlineLarge} color={colorTextDefault}>
                {webhooks.length} {webhooks.length === 1 ? 'webhook' : 'webhooks'}
              </Box>
            </Row>
            <EntityWebhookDataTable
              webhooks={webhooks}
              onEnable={onEnable}
              onDisable={onDisable}
              onReset={onReset}
              webhooksStatusTransitionStates={webhooksStatusTransitionStates}
            />
          </Stack>
        </Column>
        <Column flexFactor={1} flexBasis={250}>
          <EntityWebhookQuickTip />
        </Column>
      </Row>
    </div>
  );
};

EntityWebhookListing.displayName = 'EntityWebhookListing';
