import { memoize } from '@kontent-ai/memoization';
import { IListingContentItem } from '../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../data/models/listingContentItems/IListingVariantData.ts';
import { IStore } from '../stores/IStore.type.ts';

export const getTranslatedVariants = (
  itemId: Uuid,
  state: IStore,
): ReadonlyArray<IListingVariantData> | null => {
  const listingItems = state.data.listingContentItemsWithAllVariants.byId.get(itemId);
  return listingItems ? getVariantDataOfListingItemsMemoized(listingItems) : null;
};

const getVariantDataOfListingItemsMemoized = memoize.maxOne(
  (listingItems: ReadonlyArray<IListingContentItem>): ReadonlyArray<IListingVariantData> =>
    listingItems.flatMap((item) => item.variant ?? []),
);
