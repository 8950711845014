import React from 'react';
import { RichTextItemElementContextProvider } from '../../ContentItemEditing/context/RichTextItemElementContext.tsx';
import {
  ExpandedRichTextElement,
  ExpandedRichTextElementProps,
} from './ExpandedRichTextElement.tsx';

export const ExpandedItemRichTextElement: React.FC<ExpandedRichTextElementProps> = (props) => (
  <RichTextItemElementContextProvider rootRichTextElementId={props.elementData.elementId}>
    <ExpandedRichTextElement {...props} />
  </RichTextItemElementContextProvider>
);

ExpandedItemRichTextElement.displayName = 'ExpandedItemRichTextElement';
