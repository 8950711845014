import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { ContentListingEventActionTypes } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import {
  buildPath,
  matchPath,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createNewContentItem } from '../../../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { createContentItemFromContentTypeCreator } from '../../../../../../itemEditor/features/NewContentItem/actions/thunks/createContentItemFromContentType.ts';

const redirectToItem = (history: History, itemId: Uuid): void => {
  const matchParams = matchPath<ContentItemsRouteParams>(
    history.location.pathname,
    ContentItemsRoute,
  );
  if (!matchParams) {
    throw InvariantException('Current route does not belong to ContentItemsRoute routes');
  }
  const contentItemPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
    app: matchParams.app,
    projectId: matchParams.projectId,
    variantId: matchParams.variantId,
    spaceId: matchParams.spaceId,
    contentItemIds: [itemId],
  });
  history.push(contentItemPath);
};

export const createNewContentItemFromListing = createContentItemFromContentTypeCreator({
  createNewContentItem,
  redirectToItem,
  trackItemCreated: (contentTypeSection, collectionSection) =>
    trackUserEventWithData(TrackedEvent.ContentListing, {
      action: ContentListingEventActionTypes.CreateContentItem,
      'collection-section': collectionSection,
      'content-type-section': contentTypeSection,
    }),
});
