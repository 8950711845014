import { notNullNorUndefined } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  ICommentServerModel,
  ICommentThreadItemServerModel,
  ICommentThreadServerModel,
  ISuggestionServerModel,
} from '../../../../repositories/serverModels/CommentThreadServerModel.type.ts';
import { createCommentDomainModel } from './Comment.ts';
import { CommentThreadItemType, ICommentThreadItem } from './CommentThreadItem.ts';
import { createSuggestionDomainModel } from './Suggestion.ts';

export const DummyFirstRelevantCommentThreadId: Uuid = '00000000-0000-0000-0000-000000000000';

export enum CommentThreadType {
  RichText = 'rich-text',
  Asset = 'asset',
  Global = 'global',
  Number = 'number',
  Taxonomy = 'taxonomy',
  MultipleChoice = 'multiple-choice',
  InlineImage = 'inline-image',
  DateTime = 'date-time',
  CustomElement = 'custom-element',
  RichTextLinkedItem = 'richtext-linked-item',
  ElementLinkedItem = 'element-linked-item',
}

export interface ICommentThread {
  readonly id: Uuid;
  readonly contentItemId: Uuid;
  readonly variantId: Uuid;
  readonly contentComponentId: Uuid | null;
  readonly externalSegmentId: Uuid | null;
  readonly threadType: CommentThreadType;
  readonly elementId: Uuid | null;
  readonly elementSegment: string | null;
  readonly resolvedAt: DateTimeStamp | null;
  readonly resolvedBy: UserId | null;
  readonly threadItems: Immutable.List<ICommentThreadItem>;
  // ui properties
  readonly isReplying: boolean;
  readonly isSubmitting: boolean;
  readonly isUnsaved: boolean;
  readonly isInUndoResolvedState: boolean;
}

export interface IInlineCommentThread extends ICommentThread {
  readonly externalSegmentId: Uuid;
  readonly elementId: Uuid;
}

export const emptyCommentThread: ICommentThread = {
  id: '',
  contentItemId: '',
  variantId: '',
  contentComponentId: null,
  externalSegmentId: null,
  threadType: CommentThreadType.Global,
  elementId: null,
  elementSegment: null,
  resolvedAt: null,
  resolvedBy: null,
  threadItems: Immutable.List(),
  // ui properties
  isReplying: false,
  isSubmitting: false,
  isUnsaved: false,
  isInUndoResolvedState: false,
};

const isSuggestionServerModel = (
  item: ICommentThreadItemServerModel,
): item is ISuggestionServerModel => item.type === CommentThreadItemType.Suggestion;

const isCommentServerModel = (item: ICommentThreadItemServerModel): item is ICommentServerModel =>
  item.type === CommentThreadItemType.Comment;

function createCommentThreadItemDomainModel(
  item: ICommentThreadItemServerModel,
): ICommentThreadItem | null {
  if (isSuggestionServerModel(item)) {
    return createSuggestionDomainModel(item);
  }
  if (isCommentServerModel(item)) {
    return createCommentDomainModel(item);
  }
  return null;
}

export function createCommentThreadDomainModel(
  serverModel: ICommentThreadServerModel,
): ICommentThread {
  return {
    ...emptyCommentThread,
    contentComponentId: serverModel.contentComponentId,
    contentItemId: serverModel.contentItemId.itemId,
    elementId: serverModel.elementId,
    elementSegment: serverModel.elementSegment,
    id: serverModel._id,
    externalSegmentId: serverModel.externalSegmentId,
    threadType: serverModel.threadType,
    resolvedAt: serverModel.resolvedAt,
    resolvedBy: serverModel.resolvedBy,
    threadItems: Immutable.List(
      serverModel.threadItems.map(createCommentThreadItemDomainModel).filter(notNullNorUndefined),
    ),
    variantId: serverModel.contentItemId.variantId,
  };
}
