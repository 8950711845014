import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { getAvailableContentTypes as getAvailableContentTypesUtils } from '../utils/getAvailableContentTypes.ts';
import {
  getNewContentItemDialogProperties,
  getNewContentItemForm,
} from './newContentItemDialog.ts';

export const getAvailableContentTypes = (state: IStore): Immutable.List<IContentType> => {
  const { origin } = getNewContentItemDialogProperties(state);
  const { collectionId, languageId } = getNewContentItemForm(state);

  return getAvailableContentTypesUtils(origin, state, languageId, collectionId);
};
