import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { loadWebhookMessageContent } from '../../actions/thunkWebhooksActions.ts';
import { WebhookMessageContentButton as WebhookMessageContentButtonComponent } from '../../components/webhookMessageDialog/WebhookMessageContentButton.tsx';

type Props = {
  readonly webhookId: Uuid;
  readonly messageIndex: string;
};

export const WebhookMessageContentButton: React.FC<Props> = ({ webhookId, messageIndex }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    () => dispatch(loadWebhookMessageContent({ webhookId, messageIndex })),
    [webhookId, messageIndex],
  );

  const messageContent = useSelector((s) => s.webhooksApp.webhookMessageContent); //memoize
  const loadingStatus = useSelector((s) => s.webhooksApp.webhookMessageContentLoadingStatus); //memoize

  return (
    <WebhookMessageContentButtonComponent
      content={messageContent}
      isLoading={loadingStatus === LoadingStatus.Loading}
      onClick={onClick}
    />
  );
};
