import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  readonly spacesAppRoute: string;
};

export const SpacesQuickTipSection: React.FC<Props> = ({ spacesAppRoute }) => {
  return (
    <Callout calloutType="quickTip">
      <p>
        Set up <Link to={spacesAppRoute}>space</Link> domains and use them as macros for content
        preview.
      </p>
    </Callout>
  );
};

SpacesQuickTipSection.displayName = 'SpacesQuickTipSection';
