import Immutable from 'immutable';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { CapabilityScope } from '../../../applications/environmentSettings/roles/models/CapabilityScope.ts';
import { IRoleSettingsServerModel } from '../../../repositories/serverModels/IRoleServerModel.type.ts';

export interface IRoleSettings {
  readonly canRules: ReadonlyArray<IRuleWithScope>;
  readonly cannotRules: ReadonlyArray<IRule>;
}

export interface IRule {
  readonly capabilities: ReadonlyArray<Capability>;
  readonly types: ReadonlyArray<ITypeRuleSetting>;
}

export interface IRuleWithScope extends IRule {
  readonly scope: CapabilityScope;
}

export interface ITypeRuleSetting {
  readonly typeId: Uuid;
  readonly contentGroupIds: Immutable.Set<Uuid>;
}

export const getRoleSettingsFromServerModel = (
  settings: IRoleSettingsServerModel,
): IRoleSettings => ({
  canRules: settings.canRules.map((rule) => ({
    scope: rule.scope,
    capabilities: rule.capabilities,
    types: rule.types.map((type) => ({
      typeId: type.typeId,
      contentGroupIds: Immutable.Set(type.contentGroups?.map((g) => g.contentGroupId) ?? []),
    })),
  })),
  cannotRules: settings.cannotRules.map((rule) => ({
    capabilities: rule.capabilities,
    types: rule.types.map((type) => ({
      typeId: type.typeId,
      contentGroupIds: Immutable.Set(type.contentGroups?.map((g) => g.contentGroupId) ?? []),
    })),
  })),
});
