import classNames from 'classnames';
import { forwardRef } from 'react';
import { getDropDown } from '../../../../_shared/components/DropDown.tsx';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getShortenedTextWithEllipsis } from '../../../../_shared/utils/stringUtils.ts';
import { IBaseProject } from '../../models/IBaseProject.type.ts';

export interface IProjectTemplateSelectorStateProps {
  readonly dropdownId?: string;
  readonly selectedProjectTemplateId?: string | null;
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly className?: string;
  readonly disabled?: boolean;
}

interface IProjectTemplateSelectorDispatchProps {
  readonly onSelectionChanged: (projectTemplateId: string) => void;
}

type ProjectTemplateSelectorProps = IProjectTemplateSelectorStateProps &
  IProjectTemplateSelectorDispatchProps;

const DropDown = getDropDown<IBaseProject>();

export const ProjectTemplateSelector = forwardRef<HTMLDivElement, ProjectTemplateSelectorProps>(
  (
    {
      dropdownId,
      selectedProjectTemplateId,
      availableProjectTemplates,
      className,
      disabled,
      onSelectionChanged,
    },
    ref,
  ): JSX.Element | null => {
    if (!availableProjectTemplates) {
      return null;
    }

    const selectedOption = availableProjectTemplates.find(
      (projectTemplate) =>
        !!projectTemplate && projectTemplate.projectId === selectedProjectTemplateId,
    );
    const maxDisplayedProjectNameLength = 50;

    return (
      <EnterHotkeyDisabler>
        <DropDown
          ref={ref}
          id={dropdownId}
          className={classNames('combo-box', className)}
          onSelect={(projectTemplate) => onSelectionChanged(projectTemplate.projectId)}
          renderOptionName={(projectTemplate) =>
            projectTemplate &&
            getShortenedTextWithEllipsis(projectTemplate.projectName, maxDisplayedProjectNameLength)
          }
          getOptionId={(projectTemplate) => projectTemplate.projectId}
          options={availableProjectTemplates}
          disabled={disabled}
          selectedOption={selectedOption}
          dataUiCollectionName={DataUiCollection.ProjectTemplates}
        />
      </EnterHotkeyDisabler>
    );
  },
);

ProjectTemplateSelector.displayName = 'ProjectTemplateSelector';
