import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { DateTime as DateTimeShape } from '../../../../../../_shared/models/DateTime.ts';
import { isDateTimeElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { onDateTimeElementValueChange } from '../../actions/thunkContentItemEditingActions.ts';
import {
  DateTime as DateTimeComponent,
  DateTimeProps,
} from '../../components/elements/dateTime/DateTime.tsx';

type Props = Omit<DateTimeProps, 'onValueChanged'>;

export const DateTime: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId },
  } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );

  const onValueChanged = useCallback(
    (dateTime: DateTimeShape, displayTimeZone: string) =>
      dispatch(onDateTimeElementValueChange(elementId, dateTime, displayTimeZone)),
    [elementId],
  );

  if (!isDateTimeElement(elementData)) {
    return null;
  }

  return <DateTimeComponent {...props} elementData={elementData} onValueChanged={onValueChanged} />;
};

DateTime.displayName = 'DateTimeContainer';
