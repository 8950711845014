import { DateTime, createDateTime } from '../../../../../_shared/models/DateTime.ts';
import { DateTimeTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IDateTimeTypeElementData extends IBaseTypeElementData {
  readonly defaultValue: DateTime;
}

export const EmptyDateTimeTypeElementData: IDateTimeTypeElementData = {
  ...EmptyBaseTypeElementData,
  defaultValue: createDateTime(),
  _configurability: TypeElementConfigurability.Recommended,
  type: ElementType.DateTime,
};

export function getDateTimeTypeElementDataFromServerModel(
  serverModel: DateTimeTypeElementServerModel,
): IDateTimeTypeElementData {
  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyDateTimeTypeElementData._configurability,
    defaultValue: createDateTime({
      value: serverModel.default?.global.value ?? '',
    }),
    type: ElementType.DateTime,
  };
}
