import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  doesItemSatisfyFilter,
  doesItemWithVariantSatisfyFilter,
} from '../../../../_shared/utils/filter/itemFilterUtils.ts';
import { shouldUseLastContinuationToken } from '../../../../_shared/utils/scrollTableUtils.ts';
import { ContentListing_Filter_Changed } from '../../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { LocalizedRouteLeft } from '../../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { Calendar_Page_Left } from '../../../../applications/contentPlanning/calendar/constants/calendarActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../applications/features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_ArchiveTemporaryContentItem_Finished,
  ContentItemEditing_ArchiveTemporaryContentItem_Started,
  ContentItemEditing_Archiving_Finished,
  ContentItemEditing_CopyContentFromVariant_Finished,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Duplicating_Finished,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LinkedEntityChanged_ItemRefreshed,
} from '../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  Data_ListingContentItems_FullTextSearchBecameUnavailable,
  Data_ListingContentItems_Started,
  Data_ListingContentItems_Success,
} from '../../../constants/dataActionTypes.ts';
import { IListingContentItem } from '../../../models/listingContentItems/IListingContentItem.ts';

const initialState: Immutable.List<Uuid> | null = null;

export function allIds(
  state: Immutable.List<Uuid> | null = initialState,
  action: Action,
): Immutable.List<Uuid> | null {
  switch (action.type) {
    case Data_ListingContentItems_Started: {
      return shouldUseLastContinuationToken(action.payload.requestTrigger) ? state : initialState;
    }

    case Data_ListingContentItems_Success: {
      const newItemIds = action.payload.contentItems.map(
        (item: IListingContentItem) => item.item.id,
      );

      return state === null || !shouldUseLastContinuationToken(action.payload.requestTrigger)
        ? Immutable.List(newItemIds)
        : state.concat(newItemIds).toList();
    }

    case ContentItemEditing_ArchiveTemporaryContentItem_Started:
    case ContentItemEditing_ArchiveTemporaryContentItem_Finished: {
      return state?.filter((id) => id !== action.payload.itemId).toList() ?? null;
    }

    case ContentItemEditing_Archiving_Finished: {
      const payload = action.payload;
      if (state && payload.itemWithVariant.item.archived) {
        return state.filter((id) => id !== payload.itemWithVariant.item.id).toList();
      }

      return state;
    }

    case ContentItemEditing_LinkedEntityChanged_ItemRefreshed:
    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentItemEditing_CopyContentFromVariant_Finished:
    case ContentItemEditing_CreateNewVersion_Finished:
    case ContentItemEditing_Duplicating_Finished:
    case ContentItemEditing_Init_Ready:
    case ContentItemEditing_ItemElementsSaving_Finished: {
      const { filter, itemWithVariant, usedSearchMethod } = action.payload;
      const itemId = itemWithVariant.item.id;

      if (state && !doesItemWithVariantSatisfyFilter(itemWithVariant, filter, usedSearchMethod)) {
        return state.filter((id) => id !== itemId).toList();
      }

      return state === null || state.contains(itemId) ? state : state.insert(0, itemId);
    }

    case ContentItemEditing_ItemSaving_Finished: {
      const { filter, itemServerModel: item, usedSearchMethod } = action.payload;

      if (state && !doesItemSatisfyFilter(item, filter, usedSearchMethod)) {
        return state.filter((id) => id !== item.id).toList();
      }

      return state;
    }

    case Calendar_Page_Left:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFailed:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentListing_Filter_Changed:
    case LocalizedRouteLeft:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_ListingContentItems_FullTextSearchBecameUnavailable:
      return action.payload.shouldResetLoadedItems ? initialState : state;

    default:
      return state;
  }
}
