import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import React, { useEffect } from 'react';
import { anyProjectOption } from '../../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ProjectFilterOption } from '../../../../../_shared/models/UserListingFilterOption.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getProjectsSortedByProjectName } from '../../../../projects/selectors/projectSelectors.ts';
import {
  projectFilterInit,
  projectFilterUpdated,
} from '../../actions/subscriptionUserDetailActions.ts';
import { getUserMasterEnvironments } from '../../selectors/subscriptionUsersUsageSelectors.ts';
import { getProjectFilterOptions } from '../../utils/getFilterOptions.ts';

const getProjectOptions = (s: IStore, userId: Uuid): ReadonlyArray<ProjectFilterOption> => {
  const userMasterEnvironments = getUserMasterEnvironments(s, userId);
  const sortedUserMasterEnvironments = getProjectsSortedByProjectName(userMasterEnvironments || []);
  const projectOptions = getProjectFilterOptions(sortedUserMasterEnvironments);
  return projectOptions;
};

type SubscriptionUserDetailProjectFilterProps = {
  readonly userId: Uuid;
};

export const SubscriptionUserDetailProjectFilter: React.FC<
  SubscriptionUserDetailProjectFilterProps
> = ({ userId }) => {
  const dispatch = useDispatch();

  const projects = useSelector((s) => getProjectOptions(s, userId));
  const selectedProjectIds = useSelector(
    (s) => s.subscriptionApp.users.userDetailUi.filter.byProject,
  );

  useEffect(() => {
    dispatch(projectFilterInit());
  }, []);

  return (
    <MultiSelect
      label="Project"
      placeholder={anyProjectOption.label}
      placeholderType="tag"
      items={projects}
      selectedItemIds={selectedProjectIds}
      onSelectionChange={(selectedIds) => dispatch(projectFilterUpdated(selectedIds))}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.ProjectsList)}
      {...getDataUiCollectionAttribute(DataUiCollection.ProjectsList)}
    />
  );
};

SubscriptionUserDetailProjectFilter.displayName = 'SubscriptionUserDetailProjectFilter';
