import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { workflowStepMoved } from '../actions/workflowActions.ts';
import { WorkflowSteps as WorkflowStepsComponent } from '../components/WorkflowSteps.tsx';
import { getOrderedRegularSteps } from '../selectors/getOrderedWorkflowSteps.ts';

export const WorkflowStepsContainer: React.FC = () => {
  const editedStepId = useSelector(
    (s) => s.workflowsApp.editorUi.editedWorkflowStepId || undefined,
  );
  const publishedStep = useSelector((s) => s.workflowsApp.editorUi.publishedWorkflowStep);
  const archivedStep = useSelector((s) => s.workflowsApp.editorUi.archivedWorkflowStep);
  const regularSteps = useSelector((s) =>
    getOrderedRegularSteps(
      s.workflowsApp.editorUi.regularWorkflowSteps,
      s.workflowsApp.editorUi.regularWorkflowStepsOrder,
    ),
  );

  const dispatch = useDispatch();

  return (
    <WorkflowStepsComponent
      editedStepId={editedStepId}
      publishedStep={publishedStep}
      archivedStep={archivedStep}
      regularSteps={regularSteps}
      onMove={(sourceId, targetId) => dispatch(workflowStepMoved(sourceId, targetId))}
    />
  );
};

WorkflowStepsContainer.displayName = 'WorkflowStepsContainer';
export { WorkflowStepsContainer as WorkflowSteps };
