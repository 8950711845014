import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_SelectSubscription } from '../../shared/constants/subscriptionManagementActionTypes.ts';
import {
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_AdminListing_Select,
  Subscription_AdminListing_Unselect,
} from '../constants/actionTypes.ts';

export const selectedAdminIds = (
  state = Immutable.Set<UserId>(),
  action: Action,
): Immutable.Set<UserId> => {
  switch (action.type) {
    case Subscription_AdminListing_Select:
      return state.add(action.payload.userId);

    case Subscription_AdminListing_Unselect:
      return state.remove(action.payload.userId);

    case Subscription_AdminListing_DeleteAdminsFinished:
    case Subscription_SelectSubscription:
      return Immutable.Set<Uuid>();

    default:
      return state;
  }
};
