import { Card } from '@kontent-ai/component-library/Card';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import React, { ReactNode } from 'react';
import { ApiKeyRegenerationButton } from '../../../../../_shared/components/ApiKeys/ApiKeyRegenerationButton.tsx';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MapiKeyOwnerLimitedAccess } from '../../constants/ApiKeyDetailTemplates.ts';
import { ApiKey } from '../../models/ApiKey.ts';
import { ExpirationDateStatus } from './ExpirationDateStatus.tsx';

export type MapiKeyDetailProps = {
  readonly apiKey: ApiKey;
  readonly onRegenerate?: () => void;
  readonly renderDatePicker?: () => ReactNode;
  readonly renderEnvironmentsSelector: () => ReactNode;
  readonly renderNameInput: () => ReactNode;
  readonly renderPermissions?: () => ReactNode;
  readonly renderSharedWithUsers?: () => ReactNode;
};

export const MapiKeyDetail: React.FC<MapiKeyDetailProps> = ({
  apiKey,
  onRegenerate,
  renderDatePicker,
  renderEnvironmentsSelector,
  renderNameInput,
  renderPermissions,
  renderSharedWithUsers,
}) => {
  const isDisabled = isApiKeyTokenDefaultValue(apiKey);
  const regenerationButton = !!onRegenerate && !isDisabled && (
    <ApiKeyRegenerationButton
      onConfirm={onRegenerate}
      onRegenerate={noOperation}
      requireConfirmation
    />
  );

  return (
    <>
      <Card cardLabel="General" component="section">
        <Card.Headline>General</Card.Headline>
        <Card.Body>
          <Stack spacing={Spacing.XL}>
            <Clipboard
              auxiliaryElement={regenerationButton}
              {...getDataUiInputAttribute(DataUiInput.ApiKey)}
              value={apiKey.apiKey}
              type={InputType.Password}
              label="API key"
              inputState={isDisabled ? InputState.Disabled : InputState.ReadOnly}
              placeholder={
                apiKey.tokenSeedId
                  ? MapiKeyOwnerLimitedAccess(apiKey.name)
                  : 'To generate the API key, save your changes first.'
              }
              renderClipboardButton={({ ref }) => (
                <Clipboard.Button
                  ref={ref}
                  isDisabled={isDisabled}
                  tooltipText={getClipboardButtonTooltip(isDisabled, apiKey)}
                  {...getDataUiActionAttribute(DataUiAction.CopyToClipboard)}
                />
              )}
            />
            {apiKey.expiresAt ? (
              <ExpirationDateStatus expirationDate={apiKey.expiresAt} />
            ) : (
              renderDatePicker?.()
            )}
            {renderNameInput()}
            {renderSharedWithUsers?.()}
          </Stack>
        </Card.Body>
      </Card>
      <Card cardLabel="Limitations" component="section">
        <Card.Headline>Limitations</Card.Headline>
        <Card.Body>{renderEnvironmentsSelector()}</Card.Body>
      </Card>
      {!!renderPermissions && (
        <Card cardLabel="Permissions" component="section">
          <Card.Headline>Permissions</Card.Headline>
          <Card.Body>{renderPermissions()}</Card.Body>
        </Card>
      )}
    </>
  );
};

MapiKeyDetail.displayName = 'MapiKeyDetail';

const isApiKeyTokenDefaultValue = (apiKey: ApiKey): boolean => apiKey.apiKey === '';

const getClipboardButtonTooltip = (isDisabled: boolean, apiKey: ApiKey): string | undefined => {
  if (isDisabled) {
    return apiKey.tokenSeedId ? MapiKeyOwnerLimitedAccess(apiKey.name) : '';
  }

  return undefined;
};
