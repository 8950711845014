import { Backend, BackendFactory, DragDropManager, DragDropMonitor } from 'dnd-core';
import { DropTargetOptions } from 'react-dnd';
import { HTML5Backend, HTML5BackendContext, NativeTypes } from 'react-dnd-html5-backend';

class CustomBackendImpl implements Backend {
  readonly #originalBackend: Backend;

  constructor(manager: DragDropManager, context?: HTML5BackendContext) {
    this.#originalBackend = HTML5Backend(manager, context);
  }

  public setup() {
    this.#originalBackend.setup();
  }

  public teardown() {
    this.#originalBackend.teardown();
  }

  public connectDragSource(...args: Parameters<Backend['connectDragSource']>) {
    return this.#originalBackend.connectDragSource(...args);
  }

  public connectDragPreview(...args: Parameters<Backend['connectDragPreview']>) {
    return this.#originalBackend.connectDragPreview(...args);
  }

  public connectDropTarget(...args: Parameters<Backend['connectDropTarget']>) {
    return this.#originalBackend.connectDropTarget(...args);
  }

  public profile() {
    return this.#originalBackend.profile();
  }
}

export const CustomDndBackend: BackendFactory = (
  manager: DragDropManager,
  context?: HTML5BackendContext,
): Backend => {
  const decoratedBackend = setDropEffectWhenFileDraggingDecorator(
    fixDraggingOverIframeDecorator(CustomBackendImpl),
  );
  return new decoratedBackend(manager, context);
};

/**
 * This decorator patches dnd issue in Chrome browser on macOS [KCL-7865].
 *
 * When users drag elements over the iframe, all drag related events go directly to iframe context and can not be caught in the main window.
 * This causes drag & drop to freeze until cursor is moved outside iframe area. What makes it even worse is that, because of a known bug,
 * macOS Chrome browser ignores positioned elements located over the iframe and sends event directly to the underlying iframe, which
 * makes drag & drop totally unusable inside In-context editor in Web Spotlight.
 *
 * Chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=984891
 *
 * The only workaround we found is to apply `pointer-events: none;` rule to an iframe to make it ignore all pointer events. But to make it
 * work we have to apply it as soon as possible, otherwise the iframe may be fast enough to steal events. That is why we are applying
 * `pointer-events: none` rule to all iframe elements on the page during drag-source mousedown event and resetting it during
 * global mouseup and dragend events.
 */
function fixDraggingOverIframeDecorator(backendCtor: new (..._args: any[]) => CustomBackendImpl) {
  const DndBlockingElementsCssSelector = 'iframe';
  const IgnorePointerEventsDuringDndCssClass = 'content--ignore-pointer-events';

  const enablePointerEventsOnDndBlockingElements = () => {
    document
      .querySelectorAll(DndBlockingElementsCssSelector)
      .forEach((element) => element.classList.remove(IgnorePointerEventsDuringDndCssClass));
  };

  const ignorePointerEventsOnDndBlockingElements = () => {
    document
      .querySelectorAll(DndBlockingElementsCssSelector)
      .forEach((element) => element.classList.add(IgnorePointerEventsDuringDndCssClass));
  };

  return class extends backendCtor {
    public setup() {
      super.setup();
      window.addEventListener('mouseup', enablePointerEventsOnDndBlockingElements, true);
      window.addEventListener('dragend', enablePointerEventsOnDndBlockingElements, true);
    }

    public teardown() {
      super.teardown();
      window.removeEventListener('mouseup', enablePointerEventsOnDndBlockingElements, true);
      window.removeEventListener('dragend', enablePointerEventsOnDndBlockingElements, true);
    }

    public connectDropTarget(sourceId: string, node: HTMLElement, options: DropTargetOptions) {
      const unsubscribe = super.connectDropTarget(sourceId, node, options);
      node.addEventListener('mousedown', ignorePointerEventsOnDndBlockingElements, true);

      return () => {
        unsubscribe();
        node.removeEventListener('mousedown', ignorePointerEventsOnDndBlockingElements, true);
      };
    }
  };
}

/**
 * This decorator patches the dnd, so it sets 'dropEffect' based on the option value when dragging a file. [KCL-11364].
 *
 * It registers a custom dragover listener and overrides the default behavior of HTML5Backend, which sets the 'dropEffect'
 * property to 'copy' whenever dragging native types such as files, URLs, text, and HTML.
 *
 * GitHub HTML5Backend: https://github.com/react-dnd/react-dnd/blob/7c88c37489a53b5ac98699c46a506a8e085f1c03/packages/backend-html5/src/HTML5BackendImpl.ts#L278
 * Jira issue: https://kontent-ai.atlassian.net/browse/KCL-11364
 */
function setDropEffectWhenFileDraggingDecorator(
  backendCtor: new (..._args: any[]) => CustomBackendImpl,
) {
  return class extends backendCtor {
    readonly #monitor: DragDropMonitor;

    constructor(manager: DragDropManager, context?: HTML5BackendContext) {
      super(manager, context);
      this.#monitor = manager.getMonitor();
    }

    public connectDropTarget(sourceId: string, node: HTMLElement, options: DropTargetOptions) {
      const unsubscribe = super.connectDropTarget(sourceId, node, options);
      const handleDragging = (e: DragEvent) =>
        this.#setDropEffectForFileTypeListener(e, sourceId, options);
      window.addEventListener('dragover', handleDragging);
      window.addEventListener('dragenter', handleDragging);

      return () => {
        unsubscribe();
        window.removeEventListener('dragover', handleDragging);
        window.removeEventListener('dragenter', handleDragging);
      };
    }

    readonly #setDropEffectForFileTypeListener = (
      e: DragEvent,
      dropzoneId: string,
      options: DropTargetOptions,
    ) => {
      if (!e.dataTransfer || !this.#monitor || !dropzoneId || !options || !options.dropEffect) {
        return;
      }

      if (
        this.#monitor.getItemType() === NativeTypes.FILE &&
        this.#monitor.isOverTarget(dropzoneId)
      ) {
        e.dataTransfer.dropEffect = options.dropEffect;
      }
    };
  };
}
