import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { QueryStringParams } from '../../../../_shared/utils/buildUrlQueryString.ts';
import { ContentTypeSnippetsServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import {
  Data_Snippets_Failed,
  Data_Snippets_Started,
  Data_Snippets_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly contentTypeSnippetRepository: {
    readonly getContentTypeSnippets: (
      queryParams: QueryStringParams | null,
      abortSignal?: AbortSignal,
    ) => Promise<ContentTypeSnippetsServerModel>;
  };
}

const start = () => ({ type: Data_Snippets_Started }) as const;
const success = (data: ContentTypeSnippetsServerModel) =>
  ({
    type: Data_Snippets_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_Snippets_Failed }) as const;

export type LoadSnippetsActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadSnippetsAction =
  ({ contentTypeSnippetRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const contentTypesSnippetsServerModel =
        await contentTypeSnippetRepository.getContentTypeSnippets({}, abortSignal);
      dispatch(success(contentTypesSnippetsServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
