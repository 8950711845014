import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';

export const getListingItemsCount = memoize.maxOne(
  (listingContentItems: {
    readonly allIds: Immutable.List<Uuid> | null;
  }): number => {
    const { allIds } = listingContentItems;

    return allIds ? allIds.size : 0;
  },
);
