import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { InvariantException } from '@kontent-ai/errors';
import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IPlanningStatusTileDetails {
  readonly caption: string;
  readonly disabledTooltip: string;
  readonly enabledTooltip: string;
}

export enum PlanningStatusTileStatus {
  Loading = 'loading',
  Disabled = 'disabled',
  Enabled = 'enabled',
}

interface IPlanningStatusTileProps {
  readonly count: number;
  readonly details: IPlanningStatusTileDetails;
  readonly status: PlanningStatusTileStatus;

  readonly onClick: () => void;
}

const getTooltip = (
  status: PlanningStatusTileStatus,
  details: IPlanningStatusTileDetails,
): string | undefined => {
  switch (status) {
    case PlanningStatusTileStatus.Loading:
      return undefined;

    case PlanningStatusTileStatus.Disabled:
      return details.disabledTooltip;

    case PlanningStatusTileStatus.Enabled:
      return details.enabledTooltip;

    default:
      throw InvariantException(
        `PlanningStatusTile.tsx[${details.caption}]: Status ${status} is not known.`,
      );
  }
};

export const PlanningStatusTile: React.FC<IPlanningStatusTileProps> = ({
  count,
  details,
  onClick,
  status,
}) => {
  return (
    <Tooltip tooltipText={getTooltip(status, details)} placement="bottom">
      <div
        className={classNames('planning-status-tile', `planning-status-tile--${status}`)}
        onClick={status === PlanningStatusTileStatus.Enabled ? onClick : undefined}
      >
        <div
          className="planning-status-tile__content"
          {...getDataUiElementAttribute(DataUiElement.PlanningStatusTile)}
        >
          <div
            className="planning-status-tile__caption"
            {...getDataUiElementAttribute(DataUiElement.PlanningStatusTileCaption)}
          >
            {details.caption}
          </div>
          <div
            className={classNames(
              'planning-status-tile__number',
              `planning-status-tile__number--${status}`,
            )}
            {...(status === PlanningStatusTileStatus.Loading
              ? getDataUiElementAttribute(DataUiElement.Loader)
              : undefined)}
          >
            {status === PlanningStatusTileStatus.Loading ? undefined : count}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

PlanningStatusTile.displayName = 'PlanningStatusTile';
