import { ILanguage } from '../../../data/models/languages/Language.ts';

export const anyLanguageLanguage: ILanguage = {
  id: 'anyLanguageLanguage',
  codename: '',
  name: 'Any language',
  fallbackLanguageId: null,
  inactive: false,
  etag: '',
};
