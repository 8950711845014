import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IContentItemServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { untitledContentItem } from '../../../../contentInventory/content/constants/uiConstants.ts';

const unifiedUntitledContentItem = untitledContentItem.toLocaleLowerCase();
const unifiedOldUntitledContentEntry = 'Untitled content entry'.toLocaleLowerCase();

const isUntitledContentItem = (name: string): boolean => {
  const unifiedName = name.toLocaleLowerCase();

  return (
    unifiedName === unifiedUntitledContentItem || unifiedName === unifiedOldUntitledContentEntry
  );
};

export interface IEditedContentItem {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string;
  readonly collectionId: Uuid;
  readonly editedContentItemTypeId: Uuid;
  readonly sitemapLocation: Immutable.Set<Uuid>;
  readonly lastModifiedAt: DateTimeStamp | null;
  readonly lastModifiedBy: string;
  readonly isArchived: boolean;
}

export const emptyEditedContentItem: IEditedContentItem = {
  id: '',
  name: '',
  codename: '',
  collectionId: '',
  editedContentItemTypeId: '',
  sitemapLocation: Immutable.Set<Uuid>(),
  lastModifiedAt: null,
  lastModifiedBy: '',
  isArchived: false,
};

export const mapEditedContentItemFromServerModel = (
  serverModel: IContentItemServerModel,
): IEditedContentItem => {
  assert(!!serverModel, () => 'EditedContentItem server model is falsy.');

  return {
    id: serverModel.id ?? emptyEditedContentItem.id,
    name: isUntitledContentItem(serverModel.name) ? '' : serverModel.name,
    collectionId: serverModel.collectionId ?? emptyEditedContentItem.collectionId,
    codename: serverModel.codeName ?? emptyEditedContentItem.codename,
    editedContentItemTypeId:
      serverModel.type?._id ?? emptyEditedContentItem.editedContentItemTypeId,
    sitemapLocation: Immutable.Set<Uuid>(serverModel.sitemapLocation ?? []),
    lastModifiedAt: serverModel.lastModified ?? emptyEditedContentItem.lastModifiedAt,
    lastModifiedBy: serverModel.lastModifiedBy ?? emptyEditedContentItem.lastModifiedBy,
    isArchived: serverModel.archived ?? emptyEditedContentItem.isArchived,
  };
};
