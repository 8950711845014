import { WorkflowStepColor } from '../../../../data/constants/WorkflowStepColor.ts';
import {
  PublishedStep,
  PublishedWorkflowStepId,
  ScheduledStep,
  ScheduledWorkflowStepId,
  WorkflowStepAction,
} from '../../../../data/models/workflow/WorkflowStep.ts';

export const publishedStepConstantValues: Pick<
  PublishedStep,
  'id' | 'name' | 'codename' | 'color' | 'transitionsTo' | 'action'
> = {
  id: PublishedWorkflowStepId,
  name: 'Published',
  codename: 'published',
  color: WorkflowStepColor.PersianGreen,
  transitionsTo: new Set<Uuid>(),
  action: WorkflowStepAction.Publish as const,
};

export const scheduledStepConstantValues: Pick<
  ScheduledStep,
  'id' | 'name' | 'codename' | 'color' | 'action'
> = {
  id: ScheduledWorkflowStepId,
  name: 'Scheduled',
  codename: 'scheduled',
  color: WorkflowStepColor.DarkBlue,
  action: WorkflowStepAction.ScheduleToPublish as const,
};

export const ScheduledOrPublished = 'Scheduled / Published';
