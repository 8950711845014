import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IValidationResult } from '../../../../../../_shared/utils/validation/ValidationResult.ts';
import { getItemValidationResult } from '../../../../utils/getItemValidationResult.ts';
import { ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished } from '../../constants/contentItemEditingActionTypes.ts';

const bulkContentItemVariantElementsRevalidationFinished = (payload: IValidationResult) =>
  ({
    type: ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished,
    payload,
  }) as const;

export type RevalidateEditedContentItemVariantElementsActionsType = ReturnType<
  typeof bulkContentItemVariantElementsRevalidationFinished
>;

export const createRevalidateEditedContentItemVariantElementsAction =
  () => (): ThunkFunction => (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: { editedContentItemVariantElements, loadedContentItemTypes, editedContentItem },
    } = state;

    if (!editedContentItem) {
      return;
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    if (!editedContentItemType) {
      return;
    }

    const itemValidationResult = getItemValidationResult(
      editedContentItem.id,
      editedContentItemVariantElements,
      editedContentItemType.contentElements,
      state,
    );

    dispatch(bulkContentItemVariantElementsRevalidationFinished(itemValidationResult));
  };
