import { Action } from '../../../../@types/Action.type.ts';
import { getWebhookMessageContentFromServerModel } from '../../../../data/models/webhooks/WebhookMessageContent.ts';
import { Webhooks_WebhookMessageContent_LoadFinished } from '../../constants/webhooksActionTypes.ts';

export function webhookMessageContent(state: string = '', action: Action): string {
  switch (action.type) {
    case Webhooks_WebhookMessageContent_LoadFinished:
      return getWebhookMessageContentFromServerModel(action.payload.webhookMessageContent);

    default:
      return state;
  }
}
