import Immutable from 'immutable';
import React from 'react';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleSettings } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { CapabilityConfigurationCard } from '../CapabilityConfigurationCard.tsx';
import { AssetsCapabilitiesSection } from './AssetsCapabilitiesSection.tsx';
import { ContentItemsCapabilitiesSection } from './ContentItemsCapabilitiesSection.tsx';

type Props = {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly isDisabled: boolean;
  readonly onSettingsChanged: (settings: IRoleSettings) => void;
  readonly projectId: Uuid;
  readonly roleSettings: IRoleSettings;
  readonly status: RoleEditorStatus;
};

export const ContentProductionCapabilitiesConfigurationCard: React.ComponentType<Props> = (
  props,
) => (
  <CapabilityConfigurationCard
    title="Content production"
    dataUiObjectName="content-production-tile"
    hasFullWidth
  >
    <div className="card__content">
      <ContentItemsCapabilitiesSection {...props} />
      <AssetsCapabilitiesSection {...props} />
    </div>
  </CapabilityConfigurationCard>
);

ContentProductionCapabilitiesConfigurationCard.displayName =
  'ContentProductionCapabilitiesConfigurationCard';
