import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from '../../../../_shared/utils/permissions/getContributorRole.ts';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { getEditedVariantWorkflowId } from '../getEditedVariantWorkflowId.ts';
import { getVariantLanguage } from '../variantLanguage.ts';
import { WorkflowsByPermission, getWorkflowsByPermission } from './getWorkflowsByPermission.ts';
import { getWorkflowsSortedByName } from './getWorkflowsSortedByName.ts';

export const getWorkflowsCurrentUserCanMoveEditedVariantTo = (state: IStore): readonly Workflow[] =>
  getWorkflowsOtherThanCurrentForEditedVariant(state).allowedWorkflows;

const getWorkflowsOtherThanCurrentForEditedVariant = (state: IStore): WorkflowsByPermission => {
  const allWorkflows = getWorkflowsSortedByName(state.data.workflows.byId);
  const collectionId = state.contentApp.editedContentItem?.collectionId;
  const contentTypeId = state.contentApp.editedContentItem?.editedContentItemTypeId;
  const userRole = getUserRoleForEditedVariant(state);
  const editedVariantWorkflowId = getEditedVariantWorkflowId(state);

  const { allowedWorkflows, forbiddenWorkflows } = getWorkflowsByPermission(
    allWorkflows,
    collectionId,
    contentTypeId,
    userRole?.id,
  );

  return {
    allowedWorkflows: allowedWorkflows.filter((w) => w.id !== editedVariantWorkflowId),
    forbiddenWorkflows: forbiddenWorkflows.filter((w) => w.id !== editedVariantWorkflowId),
  };
};

const getUserRoleForEditedVariant = (state: IStore): IRoleWithSettings | null => {
  const collectionId = state.contentApp.editedContentItem?.collectionId;
  const languageId = getEditedVariantLanguageId(state);

  return collectionId && languageId
    ? getCurrentUserRoleForCollectionForLanguageForUser(
        state.data.user,
        state.sharedApp.currentProjectId,
        collectionId,
        languageId,
      )
    : null;
};

const getEditedVariantLanguageId = ({
  contentApp: { editedContentItemVariant: variant },
  data: { languages },
}: IStore): Uuid | undefined => (variant ? getVariantLanguage(variant, languages).id : undefined);

export enum WorkflowsCurrentUserCanMoveEditedVariantToHint {
  None = 'None',
  RoleCannotMoveVariantToAnyOtherWorkflows = 'RoleCannotMoveVariantToAnyOtherWorkflows',
  VariantCanBeMovedToOnlyOneOtherWorkflow = 'VariantCanBeMovedToOnlyOneOtherWorkflow',
  VariantCannotBeMovedToAnyOtherWorkflow = 'VariantCannotBeMovedToAnyOtherWorkflow',
}

export const getHintForWorkflowsCurrentUserCanMoveEditedVariantTo = (
  state: IStore,
): WorkflowsCurrentUserCanMoveEditedVariantToHint => {
  const { allowedWorkflows, forbiddenWorkflows } =
    getWorkflowsOtherThanCurrentForEditedVariant(state);

  if (allowedWorkflows.length === 0 && forbiddenWorkflows.length === 0) {
    return WorkflowsCurrentUserCanMoveEditedVariantToHint.VariantCannotBeMovedToAnyOtherWorkflow;
  }

  if (allowedWorkflows.length <= 1 && forbiddenWorkflows.length > 0) {
    return WorkflowsCurrentUserCanMoveEditedVariantToHint.RoleCannotMoveVariantToAnyOtherWorkflows;
  }

  if (allowedWorkflows.length === 1 && forbiddenWorkflows.length === 0) {
    return WorkflowsCurrentUserCanMoveEditedVariantToHint.VariantCanBeMovedToOnlyOneOtherWorkflow;
  }

  return WorkflowsCurrentUserCanMoveEditedVariantToHint.None;
};
