import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';

type WidgetListingContentShowMoreProps = {
  readonly onClick: () => void;
};

export const WidgetListingContentShowMoreButton: React.FC<WidgetListingContentShowMoreProps> = ({
  onClick,
}) => {
  return (
    <Button buttonStyle="secondary" size="small" onClick={onClick}>
      Show more
      <Button.Icon icon={Icons.Ellipsis} />
    </Button>
  );
};

WidgetListingContentShowMoreButton.displayName = 'WidgetListingContentShowMoreButton';
