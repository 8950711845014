import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import {
  WebSpotlight_SubpagesPermissionCheck_Failed,
  WebSpotlight_SubpagesPermissionCheck_Finished,
  WebSpotlight_SubpagesPermissionCheck_Started,
} from '../../constants/webSpotlightActionTypes.ts';
import { IWebSpotlightTreeOperationsCheckServerModel } from '../../types/IWebSpotlightTreeActionsChekTypes.type.ts';

interface ILoadSubpagesActionsPermissionsDependencies {
  readonly webSpotlightRepository: IWebSpotlightRepository;
}

const permissionCheckStarted = (contentItemId: string) =>
  ({
    type: WebSpotlight_SubpagesPermissionCheck_Started,
    payload: {
      contentItemId,
    },
  }) as const;

const permissionCheckFinished = (
  contentItemId: string,
  result: IWebSpotlightTreeOperationsCheckServerModel,
) =>
  ({
    type: WebSpotlight_SubpagesPermissionCheck_Finished,
    payload: {
      contentItemId,
      result,
    },
  }) as const;

const permissionCheckFailed = (contentItemId: string, errorMessage?: string) =>
  ({
    type: WebSpotlight_SubpagesPermissionCheck_Failed,
    payload: {
      contentItemId,
      errorMessage,
    },
  }) as const;

export type CheckSubpagesPermissionsActionTypes = ReturnType<
  typeof permissionCheckStarted | typeof permissionCheckFinished | typeof permissionCheckFailed
>;

export const loadSubpagesActionPermissionsForVariantActionCreator =
  (deps: ILoadSubpagesActionsPermissionsDependencies) =>
  (variantId: Uuid, itemId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(permissionCheckStarted(itemId));

      const result = await deps.webSpotlightRepository.checkSubpagesOperationsStatus(
        variantId,
        itemId,
      );

      dispatch(permissionCheckFinished(itemId, result));
    } catch {
      dispatch(permissionCheckFailed(itemId));
    }
  };
