import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { TagColor } from '../../../data/constants/tagColor.ts';
import { Tag } from '../../uiComponents/Tag/Tag.tsx';

type BarItemTitleTagProps = {
  readonly color: TagColor;
  readonly children: string;
};

const StyledBarItemTitleTag = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${px(Spacing.L)};
`;

export const BarItemTitleTag: React.FC<BarItemTitleTagProps> = (props) => (
  <StyledBarItemTitleTag>
    <Tag color={props.color}>{props.children}</Tag>
  </StyledBarItemTitleTag>
);

BarItemTitleTag.displayName = 'BarItemTitleTag';
