export function tagRecording(tag: HotjarTag): void {
  const hj = (window as any).hj;
  if (hj) {
    hj('tagRecording', [tag]);
  }
}

/**
 * Trigger recording using a JS custom trigger. The given tag name is used as a trigger name.
 * However, tag name needs to be manually added to recording triggers in Hotjar.
 * This tutorial might help https://help.hotjar.com/hc/en-us/articles/115015712548-How-to-Use-JavaScript-Triggers-to-Start-Recordings
 * @param {HotjarTag} tag Hotjar tag and trigger name
 */
export function triggerRecording(tag: HotjarTag): void {
  ensureHotjar();

  const hj = (window as any).hj;
  if (hj) {
    hj('trigger', tag);
    hj('tagRecording', [tag]);
  }
}

/**
 * Trigger heat map using a JS custom trigger.
 * The trigger needs to be manually added to heat map triggers in Hotjar.
 * This tutorial might help https://help.hotjar.com/hc/en-us/articles/115011867948-Using-JavaScript-to-Trigger-Heatmaps
 * @param {HeatMapTrigger} trigger Hotjar trigger name
 */
export function triggerHeatMap(trigger: HeatMapTrigger): void {
  ensureHotjar();

  const hj = (window as any).hj;
  if (hj) {
    hj('trigger', trigger);
  }
}

export function ensureHotjar(): void {
  const ensure = (window as any).ensureHotjar;
  if (ensure) {
    ensure();
  }
}

export enum HotjarTag {
  CalendarOpened = 'calendar-opened',
  RecentlyEditedItemsOpened = 'my-content-recently-edited-opened',
  ContentInventoryOpened = 'content-inventory-opened',
  CascadePublish = 'cascade-publish',
  DisabledPreviewButtonClicked = 'disabled-preview-button-clicked',
  PreviewButtonClicked = 'preview-button-clicked',
  SampleContentItemOpened = 'sample-content-item-opened',
  HelpButtonClicked = 'help-button-clicked',
  SampleWebAppOpened = 'sample-web-app-opened',
  SampleMobileAppOpened = 'sample-mobile-app-opened',
  RelationsTabOpened = 'relations-tab-opened',
  RoleEditorOpened = 'role-editor-opened',
}

export enum HeatMapTrigger {
  RecentlyEdited = 'recently-edited-heat-map',
  ContentInventoryListing = 'content-inventory-heat-map',
}
