import { InvariantException } from '@kontent-ai/errors';
import {
  IUserIdentifier,
  createUserIdentifier,
} from '../../../../_shared/models/UserIdentifier.ts';
import {
  IAssignmentWorkflowStep,
  emptyAssignmentWorkflowStep,
  mapToAssignmentWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import {
  IAssignmentFullServerModel,
  IAssignmentUpdateServerModel,
  IBulkAssignmentUpdateServerModel,
} from '../../../../repositories/serverModels/IAssignmentServerModel.type.ts';

export interface INote {
  readonly noteText: string;
  readonly addedBy: UserId | null;
  readonly addedAt: DateTimeStamp | null;
}

export const emptyNote: INote = {
  noteText: '',
  addedBy: null,
  addedAt: null,
};

export interface IAssignment {
  readonly id: Uuid;
  readonly createdAt: DateTimeStamp | null;
  readonly note: INote | null;
  readonly assignees: ReadonlySet<IUserIdentifier>;
  readonly assignedBy: IUserIdentifier | null;
  readonly due: DateTimeStamp | null;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledPublishDisplayTimeZone: string | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly scheduledUnpublishDisplayTimeZone: string | null;
  readonly workflowStatus: IAssignmentWorkflowStep;
}

export const emptyAssignment: IAssignment = {
  id: '',
  createdAt: null,
  note: null,
  assignees: new Set<IUserIdentifier>(),
  assignedBy: null,
  due: null,
  scheduledToPublishAt: null,
  scheduledPublishDisplayTimeZone: null,
  scheduledToUnpublishAt: null,
  scheduledUnpublishDisplayTimeZone: null,
  workflowStatus: emptyAssignmentWorkflowStep,
};

export function getAssignmentFromServerModel(assignment: IAssignmentFullServerModel): IAssignment {
  if (!assignment.workflowStatus) {
    throw InvariantException('Assignment server model: workflow status is falsy');
  }

  const noteExists = assignment.note && assignment.note.length > 0;
  const assignedBy = assignment.assignedBy
    ? createUserIdentifier(assignment.assignedBy.userId)
    : null;
  const createdAt = assignment.createdAt;

  const note: INote | null = noteExists
    ? {
        noteText: assignment.note || '',
        addedBy: assignment.noteAddedBy
          ? assignment.noteAddedBy
          : assignment.assignedBy
            ? assignment.assignedBy.userId
            : null,
        addedAt: assignment.noteAddedAt ? assignment.noteAddedAt : createdAt,
      }
    : null;

  const assignees = assignment.assignees
    ? new Set<IUserIdentifier>(assignment.assignees.map((a) => createUserIdentifier(a.userId)))
    : new Set<IUserIdentifier>();

  return {
    id: assignment.id,
    createdAt,
    note,
    scheduledToPublishAt: assignment.publishScheduleTime,
    scheduledPublishDisplayTimeZone: assignment.publishScheduleDisplayTimeZone,
    scheduledToUnpublishAt: assignment.unpublishScheduleTime,
    scheduledUnpublishDisplayTimeZone: assignment.unpublishScheduleDisplayTimeZone,
    assignees,
    assignedBy,
    due: assignment.due,
    workflowStatus: mapToAssignmentWorkflowStep(assignment.workflowStatus),
  };
}

export const convertAssignmentToServerModel = ({
  id,
  createdAt,
  note,
  assignees,
  assignedBy,
  due,
  workflowStatus,
  scheduledToPublishAt,
  scheduledPublishDisplayTimeZone,
  scheduledToUnpublishAt,
  scheduledUnpublishDisplayTimeZone,
}: IAssignment): IAssignmentUpdateServerModel => ({
  id,
  createdAt,
  note: note ? note.noteText : '',
  noteAddedAt: note ? note.addedAt : null,
  noteAddedBy: note ? note.addedBy : null,
  assignees: Array.from(assignees),
  assignedBy,
  due,
  workflowStatus: workflowStatus && {
    _id: workflowStatus.id,
    workflowId: workflowStatus.workflowId,
  },
  publishScheduleTime: scheduledToPublishAt,
  publishScheduleDisplayTimeZone: scheduledPublishDisplayTimeZone,
  unpublishScheduleTime: scheduledToUnpublishAt,
  unpublishScheduleDisplayTimeZone: scheduledUnpublishDisplayTimeZone,
});

export const convertAssignmentToUpdateServerModel = ({
  note,
  assignees,
  due,
  workflowStatus,
}: IAssignment): IBulkAssignmentUpdateServerModel => ({
  note: note ? note.noteText : '',
  assignees: Array.from(assignees),
  due,
  workflowStatus: workflowStatus && {
    id: workflowStatus.id,
    workflowId: workflowStatus.workflowId,
  },
});
