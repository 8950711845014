import { memoize } from '@kontent-ai/memoization';
import { FileSizeUnit } from '../../applications/contentInventory/content/models/fileSizeOptions.ts';
import { FormattedFileSize } from '../../applications/contentModels/shared/utils/typeElementDataConverters/utils/types/IFormattedFileSize.type.ts';
import { removeTrailingZeros } from './stringUtils.ts';

const truncate = (fileSize: number, decimalDigits: number): string => {
  const value = fileSize.toString();
  const decimalStart = value.indexOf('.');

  return decimalStart > -1
    ? removeTrailingZeros(value.slice(0, decimalStart + decimalDigits + 1))
    : value;
};

const composeFileSizeObject = (unit: FileSizeUnit, size: number): FormattedFileSize => ({
  fileSize: truncate(size, 2),
  unit,
});

const orderOfMagnitudeOfFileSize = 1024;
const bytesInKiloByte = orderOfMagnitudeOfFileSize;
const bytesInMegaByte = bytesInKiloByte * orderOfMagnitudeOfFileSize;
const bytesInGigaByte = bytesInMegaByte * orderOfMagnitudeOfFileSize;

export const getFileSizeObject = memoize.allForever(
  (fileSizeInBytes: number): FormattedFileSize | null => {
    const minValue = 0.01;

    if (fileSizeInBytes / bytesInGigaByte >= 1) {
      return composeFileSizeObject(FileSizeUnit.Gigabyte, fileSizeInBytes / bytesInGigaByte);
    }

    if (fileSizeInBytes / bytesInMegaByte >= 1) {
      return composeFileSizeObject(FileSizeUnit.Megabyte, fileSizeInBytes / bytesInMegaByte);
    }

    // workaround for units of bytes edge case -> 1byte = 0.0009765625
    if (fileSizeInBytes / bytesInKiloByte >= minValue) {
      return composeFileSizeObject(FileSizeUnit.Kilobyte, fileSizeInBytes / bytesInKiloByte);
    }

    if (fileSizeInBytes > 0) {
      return {
        fileSize: minValue.toString(),
        unit: FileSizeUnit.Kilobyte,
      };
    }

    return null;
  },
);

export const convertFileSizeToBytes = memoize.allForever(
  (fileSize: number | null, fileSizeUnit: FileSizeUnit): number | null => {
    if (fileSize) {
      const ceil = Math.ceil;
      switch (fileSizeUnit) {
        case FileSizeUnit.Kilobyte: {
          return ceil(fileSize * bytesInKiloByte);
        }
        case FileSizeUnit.Megabyte: {
          return ceil(fileSize * bytesInMegaByte);
        }
        case FileSizeUnit.Gigabyte: {
          return ceil(fileSize * bytesInGigaByte);
        }
        default: {
          break;
        }
      }
    }
    return fileSize;
  },
);
