import { combineReducers } from '@reduxjs/toolkit';
import { cancellationToken } from './reducers/cancellationToken.ts';
import { continuationToken } from './reducers/continuationToken.ts';
import { items } from './reducers/items.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';
import { visibleItemsToUserCount } from './reducers/visibleItemsToUserCount.ts';

export const contentTypeComponentsUsage = combineReducers({
  cancellationToken,
  continuationToken,
  items,
  loadingStatus,
  visibleItemsToUserCount,
});
