import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { archiveAssets as archiveAssetsAction } from '../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../types/orderBy.type.ts';

type Args = {
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

export const useArchiveSelectedAssets = ({ deselectAssets, orderBy, selectedAssets }: Args) => {
  const dispatch = useDispatch();

  const archiveAssets = useCallback(async (): Promise<void> => {
    const archivedAssetIds = await dispatch(
      archiveAssetsAction({
        orderBy,
        selectedAssets,
      }),
    );

    deselectAssets(archivedAssetIds);
  }, [deselectAssets, orderBy, selectedAssets]);

  return archiveAssets;
};
