export enum UserFeedbackSource {
  // How do you like feedback
  Ai = 'Ai',
  Calendar = 'Calendar',
  ContentModeling = 'Content Modeling',
  HelpUsImprove = 'Help us improve',
  YourContent = 'Your content',
}

export interface IUmuxFeedbackIntercomMessage {
  readonly source: UserFeedbackSource;
  readonly ease: string | null;
  readonly requirements: string | null;
  readonly comment: string;
}
