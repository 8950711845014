import React from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import {
  PopoverMessage,
  enhanceWithStickyPopover,
} from '../../../../_shared/uiComponents/Popover/Popover.tsx';
import { SquareButton } from '../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import { IForwardedRefProps } from '../../../../_shared/utils/forwardedRefProps.tsx';

type DismissiblePopoverProps = IForwardedRefProps<HTMLDivElement> & {
  readonly headline?: string;
  readonly onClick?: (e: React.MouseEvent) => void;
  readonly onDismiss: (event: React.MouseEvent) => void;
};

const DismissiblePopover: React.FC<React.PropsWithChildren<DismissiblePopoverProps>> = ({
  children,
  forwardedRef,
  onClick,
  onDismiss,
  headline,
}) => (
  <div className="dismissible-popover flex" onClick={onClick} ref={forwardedRef}>
    <PopoverMessage>
      {headline && <h4 className="dismissible-popover__title">{headline}</h4>}
      {children}
    </PopoverMessage>
    <SquareButton
      className="dismissible-popover__button"
      iconName={IconName.Times}
      onClick={onDismiss}
      ariaLabel="Dismiss message"
      size={SquareButtonSize.Quinary}
      style={SquareButtonStyle.Quinary}
    />
  </div>
);

DismissiblePopover.displayName = 'DismissiblePopover';

const EnhancedDismissiblePopover =
  enhanceWithStickyPopover<DismissiblePopoverProps>(DismissiblePopover);

export { EnhancedDismissiblePopover as DismissiblePopover };
