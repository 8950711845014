import { Card } from '@kontent-ai/component-library/Card';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IUserDetailInfoProps {
  readonly user: IUserInfo;
}

export const UserDetailInfo: React.FC<IUserDetailInfoProps> = ({ user }) => {
  return (
    <Card component="section" cardLabel="user data">
      <Card.Body>
        <Row spacing={Spacing.L}>
          <Column>
            <Stack spacing={Spacing.L}>
              <Input
                inputState={InputState.ReadOnly}
                label="First name"
                value={user.firstName}
                {...getDataUiInputAttribute(DataUiInput.Text)}
              />
              <Input
                inputState={InputState.ReadOnly}
                label="Email"
                value={user.email}
                {...getDataUiInputAttribute(DataUiInput.Text)}
              />
            </Stack>
          </Column>
          <Column>
            <Input
              inputState={InputState.ReadOnly}
              label="Last name"
              value={user.lastName}
              {...getDataUiInputAttribute(DataUiInput.Text)}
            />
          </Column>
        </Row>
      </Card.Body>
    </Card>
  );
};

UserDetailInfo.displayName = 'UserDetailInfo';
