import { EditingAction as EditingActionEnum } from '../../models/EditingAction.ts';

// The actions below render a list of dropdown-items while the rest provides its own layout → these were originally not mean to be usable
// in editing actions (be it quick- or menu-actions), however, they are partially hacked-in now.

const actionsWithOptions = [
  EditingActionEnum.showDuplicationOptions,
  EditingActionEnum.copyFromLanguage,
  EditingActionEnum.previewItemInMultipleSpaces,
  EditingActionEnum.translateVariant,
];

export const doesActionRepresentMenuWithMultipleOptions = (
  editingAction: EditingActionEnum,
): boolean => actionsWithOptions.includes(editingAction);
