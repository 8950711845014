import React, { useMemo } from 'react';
import { ItemColumnCode } from '../../../../../../../_shared/constants/itemColumnCode.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../../../_shared/selectors/contentCollections.ts';
import { compose } from '../../../../../../../_shared/utils/func/compose.ts';
import { areSpacesInCollectionsAvailable } from '../../../../selectors/areSpacesInCollectionsAvailable.ts';
import { updateColumnFilter } from '../../actions/thunkContentItemInventoryActions.ts';
import {
  ColumnSettings as ColumnSettingsComponent,
  IColumnSettingsOwnProps,
} from '../../components/ItemInventoryScrollTable/ColumnSettings.tsx';

const allSelectableColumns = [
  ItemColumnCode.WorkflowStep,
  ItemColumnCode.ContentType,
  ItemColumnCode.Collection,
  ItemColumnCode.Space,
  ItemColumnCode.DueDate,
  ItemColumnCode.LastPublishedAt,
  ItemColumnCode.LastModifiedAt,
] as const;

export const ColumnSettings: React.FC<IColumnSettingsOwnProps> = (props) => {
  const areCollectionsVisible = useSelector(areCollectionsVisibleForCurrentUser);
  const areSpacesInCollectionsEnabled = useSelector(areSpacesInCollectionsAvailable);

  const selectableColumns = useMemo(
    () =>
      allSelectableColumns
        .filter((c) => c !== ItemColumnCode.Collection || areCollectionsVisible)
        .filter((c) => c !== ItemColumnCode.Space || areSpacesInCollectionsEnabled),
    [areCollectionsVisible, areSpacesInCollectionsEnabled],
  );

  return (
    <ColumnSettingsComponent
      source={props.source}
      hiddenColumns={useSelector((s) => s.contentApp.listingUi.hiddenColumns)}
      selectableColumns={selectableColumns}
      updateColumnFilter={compose(useDispatch(), updateColumnFilter)}
    />
  );
};

ColumnSettings.displayName = 'ColumnSettings';
