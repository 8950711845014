import React from 'react';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { Assignment, AssignmentWorkflowStatus } from '../../uiComponents/Assignment/Assignment.tsx';
import { ItemDetailSection } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';

type Props = {
  readonly date: DateTimeStamp | null;
};

const archivedVariantAssignment: AssignmentWorkflowStatus = {
  color: WorkflowStepColor.Gray,
  name: 'Archived',
};

export const ArchivedVariantAssignment: React.FC<Props> = ({ date }) => (
  <Assignment
    dataUiObjectName={ItemDetailSection.CurrentStatus}
    workflowStatusColor={archivedVariantAssignment.color}
  >
    <Assignment.Details suffix={`On ${renderDatetimeString(date)}`}>
      {archivedVariantAssignment.name}
    </Assignment.Details>
  </Assignment>
);

ArchivedVariantAssignment.displayName = 'ArchivedVariantAssignment';
