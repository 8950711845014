import React from 'react';
import { modalWithPropertiesOpened } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const DiscardNewVersionAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      performAction={(actionOrigin: ContentItemEditingEventOrigins) => {
        dispatch(
          trackContentItemEditingAction(
            modalWithPropertiesOpened({
              dialogType: ModalDialogType.DiscardNewVersionConfirmationDialog,
              actionOrigin,
            }),
            ContentItemEditingEventTypes.DiscardNewVersion,
          )(actionOrigin),
        );
      }}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
