import React, { useCallback, useRef } from 'react';
import { DragLayerMonitor, useDragLayer } from 'react-dnd';
import { createPortal } from 'react-dom';
import { DragObject } from './dragDrop.type.ts';

interface IDragPreviewProps {
  readonly renderPreview: () => React.ReactNode;
  readonly sourceId: string;
  readonly sourceRef: React.RefObject<HTMLDivElement>;
}

export function DragPreview({ renderPreview, sourceId, sourceRef }: IDragPreviewProps) {
  const previewRef = useRef<HTMLDivElement>(null);
  const lastTransform = useRef<string | null>(null);
  const isBusy = useRef(false);

  const getShouldRenderPreview = useCallback(
    (monitor: DragLayerMonitor<DragObject>): boolean => {
      const isDragging = monitor.isDragging();
      const item = monitor.getItem();
      if (!isDragging || item.sourceId !== sourceId) {
        lastTransform.current = null;
        isBusy.current = false;
        return false;
      }

      if (isBusy.current) {
        return !!lastTransform.current;
      }

      const initialOffset = monitor.getInitialSourceClientOffset();
      const currentOffset = monitor.getSourceClientOffset();

      if (!initialOffset || !currentOffset) {
        lastTransform.current = null;
        isBusy.current = false;
        return false;
      }

      const { x, y } = currentOffset;

      const newTransform = `translate(${Math.round(x)}px, ${Math.round(y)}px)`;
      if (newTransform !== lastTransform.current) {
        isBusy.current = true;
        lastTransform.current = newTransform;
        requestAnimationFrame(() => {
          if (previewRef.current) {
            previewRef.current.style.transform = newTransform;
            requestAnimationFrame(() => {
              isBusy.current = false;
            });
          }
        });
      }
      return true;
    },
    [sourceId],
  );

  const shouldRenderPreview = useDragLayer(getShouldRenderPreview);
  if (!shouldRenderPreview) {
    return null;
  }

  const width = sourceRef.current?.offsetWidth;

  return createPortal(
    <div
      className="drag-preview"
      style={{
        ...(width ? { width: `${width}px` } : undefined),
        ...(lastTransform.current ? { transform: lastTransform.current } : undefined),
      }}
      ref={previewRef}
    >
      {renderPreview()}
    </div>,
    document.body,
  );
}

DragPreview.displayName = 'DragPreview';
