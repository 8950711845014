import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { isShorterThanValidationBuilder } from '../../../../_shared/utils/validation/isShorterThan.ts';
import {
  emptyRolesStepErrorText,
  emptyTransitionStepErrorText,
} from '../constants/workflowErrorMessages.ts';
import { IWorkflowStepFormShape } from '../model/IWorkflowStepFormShape.type.ts';
import { containsDeletedRole } from './containsDeletedRole.ts';

const nameRequired = nonEmptyValidationBuilder('step name', 'workflow');
const shorterThan = isShorterThanValidationBuilder(ValidationConstants.WorkflowStatusNameMaxLength);

const hasTransition = (steps: ReadonlyArray<string>): string | undefined => {
  return steps.length === 0 ? emptyTransitionStepErrorText : undefined;
};

export const hasSelectedAnyRole = (roles: ReadonlyArray<string>): string | undefined => {
  return roles.length === 0 ? emptyRolesStepErrorText : undefined;
};

export const workflowStepValidationConfig: FormValidatorConfig<IWorkflowStepFormShape> = {
  name: [nameRequired, shorterThan],
  transitionToStepIds: [hasTransition],
  roleIds: [containsDeletedRole, hasSelectedAnyRole],
};
