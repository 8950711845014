import { memoize } from '@kontent-ai/memoization';
import { ICommentThread } from '../models/comments/CommentThreads.ts';
import { CommentThreadState } from '../types/CommentThreadState.ts';
import { isThreadInline, isThreadResolved, isThreadSaved } from '../utils/commentUtils.ts';

export const getTextElementCommentThreadsByExternalSegmentId = memoize.weak(
  (
    threads: ReadonlyArray<ICommentThread>,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyMap<Uuid, CommentThreadState> =>
    new Map(
      threads
        .filter(
          (thread) =>
            thread.elementId === elementId && thread.contentComponentId === contentComponentId,
        )
        .filter(isThreadInline)
        .map((thread) => {
          if (!isThreadSaved(thread)) {
            return [thread.externalSegmentId, CommentThreadState.Unsaved];
          }
          if (!isThreadResolved(thread)) {
            return [thread.externalSegmentId, CommentThreadState.Unresolved];
          }
          return [thread.externalSegmentId, CommentThreadState.Resolved];
        }),
    ),
);

export const getCommentThreadIdByExternalSegmentIdMappingForElement = memoize.weak(
  (
    threads: ReadonlyArray<ICommentThread>,
    elementId: Uuid,
    contentComponentId: Uuid | null,
  ): ReadonlyMap<Uuid, Uuid> =>
    new Map(
      threads
        .filter(
          (thread) =>
            thread.elementId === elementId && thread.contentComponentId === contentComponentId,
        )
        .filter(isThreadInline)
        .map((thread) => [thread.externalSegmentId, thread.id]),
    ),
);

export const getFocusedCommentThreadIdForElement = memoize.weak(
  (
    threads: ReadonlyArray<ICommentThread>,
    elementId: Uuid,
    contentComponentId: Uuid | null,
    focusedThreadId: Uuid | null,
  ): Uuid | null =>
    threads.find(
      (thread) =>
        thread.id === focusedThreadId &&
        thread.elementId === elementId &&
        thread.contentComponentId === contentComponentId,
    )?.id ?? null,
);
