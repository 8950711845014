import { Loader } from '../../../_shared/components/Loader.tsx';
import { RetrievingItemsMessage } from '../../../_shared/components/RetrievingItemsMessage.tsx';
import { UseThunkPromiseStatus, useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { initializePreviewConfiguration } from '../actions/thunkPreviewConfigurationActions.ts';
import { PreviewConfiguration } from './PreviewConfiguration.tsx';

export const PreviewConfigurationApp = () => {
  const [isInitThunkDone, , status] = useThunkPromise(initializePreviewConfiguration);

  if (status === UseThunkPromiseStatus.Failed) {
    return <RetrievingItemsMessage message="Loading failed" />;
  }

  return isInitThunkDone ? <PreviewConfiguration /> : <Loader />;
};
