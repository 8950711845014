import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';

export const WorkflowStepsQuickTip: React.FC = () => (
  <Callout calloutType="quickTip">
    <p>
      Create new workflow steps or customize the existing ones. Make sure each workflow step
      eventually transitions to the <strong>Published step</strong>.
    </p>
    <p>Use colors for a clear visualization of your content production progress.</p>
    <p>Changes to workflow steps affect both existing and new content items immediately.</p>
  </Callout>
);

WorkflowStepsQuickTip.displayName = 'WorkflowStepsQuickTip';
