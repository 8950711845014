import { DataAttributes } from '../../../../../_shared/utils/dataAttributes/DataAttributes.ts';

const isElement = (target: any): target is HTMLElement => target instanceof HTMLElement;
export const TableCellResizerClassName = 'rte__table-cell-resizer';

export const resizeTableColumns = (
  event: PointerEvent,
  onResize: (colIndex: number, delta: number) => Promise<number>,
) => {
  if (!(isElement(event.target) && event.target.classList.contains(TableCellResizerClassName))) {
    return;
  }

  const resizerElement = event.target;

  const colIndexValue = resizerElement.getAttribute(DataAttributes.RteColumnIndex);
  if (!colIndexValue) {
    return;
  }

  const colIndex = Number.parseInt(colIndexValue, 10);

  event.preventDefault();
  event.stopPropagation();

  let lastAnchorX = event.screenX;
  let lastX = event.screenX;

  const onPointerMove = ({ screenX }: PointerEvent) => {
    const delta = screenX - lastX;
    lastX = screenX;

    // We only send the resize request in case it is valid
    // When returning back from pointer dragged behind the resizer which no longer moves we ignore it until it crosses the resizer in the backward direction
    if ((delta > 0 && lastX >= lastAnchorX) || (delta < 0 && lastX <= lastAnchorX)) {
      onResize(colIndex, delta).then((usedDelta) => {
        lastAnchorX += usedDelta;
      });
    }
  };
  resizerElement.addEventListener('pointermove', onPointerMove);
  resizerElement.setPointerCapture(event.pointerId);

  const onPointerUp = (e: PointerEvent) => {
    if (resizerElement) {
      resizerElement.removeEventListener('pointermove', onPointerMove);
      resizerElement.releasePointerCapture(e.pointerId);
      resizerElement.removeEventListener('pointerup', onPointerUp);
    }
  };
  resizerElement.addEventListener('pointerup', onPointerUp);
};
