export enum CustomElementDialogType {
  ItemSelector = 'ItemSelector',
  AssetSelector = 'AssetSelector',
  ImageSelector = 'ImageSelector',
}

export enum CustomElementDialogSelectionMode {
  Single = 'Single',
  Multiple = 'Multiple',
}
