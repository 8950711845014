import { useBreadcrumbItem } from '@react-aria/breadcrumbs';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';

type Props = {
  readonly isInTooltip?: boolean;
  readonly onClick: () => void;
};

const HOME_ITEM_NAME = 'Assets';

export const AssetFolderHomeBreadcrumbItem: React.FC<Props> = ({ isInTooltip, onClick }) => {
  const ref = useRef(null);

  const { itemProps } = useBreadcrumbItem(
    { children: HOME_ITEM_NAME, elementType: 'div', onPress: onClick },
    ref,
  );

  return (
    <div
      className={classNames(
        'asset-breadcrumbs__item',
        'asset-breadcrumbs__item--is-clickable',
        'asset-breadcrumbs__item--is-first',
        {
          'asset-breadcrumbs__item--is-in-tooltip': isInTooltip,
        },
      )}
      ref={ref}
      {...itemProps}
    >
      <Icon iconName={IconName.Home} className="asset-breadcrumbs__item-icon" />
      <span className="asset-breadcrumbs__item-name">{HOME_ITEM_NAME}</span>
    </div>
  );
};

AssetFolderHomeBreadcrumbItem.displayName = 'AssetFolderHomeBreadcrumbItem';
