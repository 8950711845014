export enum EditorLinkStatus {
  // Can be edited and unlinked
  Allowed = 'Allowed',

  // Can be unlinked, but NOT edited
  NotAllowed = 'NotAllowed',

  // Can NOT be edited, nor unlinked
  InNotAllowedText = 'InNotAllowedText ',
}
