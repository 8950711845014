import { assert } from '@kontent-ai/utils';
import { ReactNode, createContext, useContext, useSyncExternalStore } from 'react';
import { GetAuthToken } from '../types/GetAuthToken.type.ts';
import { createAuthorizationProvider } from '../utils/authorization/authorizationProvider.ts';

type Props = {
  readonly children: ReactNode;
};

const authorizationProvider = createAuthorizationProvider();

const AuthTokenContext = createContext<ReturnType<typeof createAuthorizationProvider> | null>(null);
AuthTokenContext.displayName = 'AuthToken';

export const AuthTokenProvider = (props: Props) => {
  return (
    <AuthTokenContext.Provider value={authorizationProvider}>
      {props.children}
    </AuthTokenContext.Provider>
  );
};

type UseAuthTokenReturn = {
  readonly getAuthToken: GetAuthToken;
  readonly refreshAuthToken: () => void;
};

export const useAuthToken = (): UseAuthTokenReturn => {
  const authProvider = useContext(AuthTokenContext);
  assert(
    authProvider,
    () => `useAuthToken: ${AuthTokenContext.displayName} is missing in the current tree.`,
  );
  return useSyncExternalStore(authProvider.subscribe, authProvider.getSnapshot);
};
