import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { capabilitiesChecked, capabilitiesUnchecked } from '../actions/rolesActions.ts';
import {
  CapabilityOption as CapabilityOptionComponent,
  ICapabilityOptionCallbackProps,
  ICapabilityOptionDataProps,
} from '../components/configurator/CapabilityOption.tsx';
import { isRoleEditingDisabled } from '../utils/roleEditorStatusUtils.ts';

interface IOwnProps {
  readonly checkboxOption: Capability | Immutable.Set<Capability>;
  readonly optionName: string;
}

const isSingleCapability = (
  checkboxOption: Capability | Immutable.Set<Capability>,
): checkboxOption is Capability => typeof checkboxOption === 'string';

// not memoize.maxOne because there are too many options on the page
const getOptions = memoize.allForever(
  (checkboxOption: Capability | Immutable.Set<Capability>): Immutable.Set<Capability> =>
    isSingleCapability(checkboxOption) ? Immutable.Set([checkboxOption]) : checkboxOption,
);

const mapStateToProps = (state: IStore, ownProps: IOwnProps): ICapabilityOptionDataProps => {
  const options = getOptions(ownProps.checkboxOption);

  return {
    disabled: isRoleEditingDisabled(state),
    checked: options.every((c: Capability) =>
      state.rolesApp.editorUi.editedRole.capabilities.contains(c),
    ),
    optionName: ownProps.optionName,
    option: options,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IOwnProps,
): ICapabilityOptionCallbackProps => {
  const options = getOptions(ownProps.checkboxOption);

  return {
    onChecked: () => dispatch(capabilitiesChecked(options)),
    onUnchecked: () => dispatch(capabilitiesUnchecked(options)),
  };
};

export const CapabilityOption: React.ComponentType<React.PropsWithChildren<IOwnProps>> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CapabilityOptionComponent);
