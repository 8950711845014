import { ISpaceServerResponseModel } from '../../../repositories/serverModels/SpacesServerModel.type.ts';

export interface ISpace {
  readonly id: Uuid;
  readonly name: string;
  readonly codeName: string;
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly webSpotlightRootItemId: Uuid | null;
}

export const emptySpace: ISpace = {
  id: '',
  name: '',
  codeName: '',
  collectionIds: [],
  webSpotlightRootItemId: null,
};

export type SpacesMap = ReadonlyMap<Uuid, ISpace>;

export const createSpaceFromServerModel = (space: ISpaceServerResponseModel): ISpace => ({
  id: space.id,
  name: space.name,
  codeName: space.codeName,
  collectionIds: space.collections,
  webSpotlightRootItemId: space.webSpotlightRootItemId ?? null,
});

export const createSpacesFromServerModel = (
  spaces: ReadonlyArray<ISpaceServerResponseModel>,
): SpacesMap =>
  new Map<Uuid, ISpace>(spaces.map((space) => [space.id, createSpaceFromServerModel(space)]));
