import { useCallback, useContext, useState } from 'react';
import { FocusVisibilityContext } from '../components/_contexts/FocusVisibilityContext.tsx';

export const useOurFocusRing = (isDisabled: boolean = false) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const focusProps = {
    onFocus,
    onBlur,
  };

  const { isFocusVisible } = useContext(FocusVisibilityContext);

  const [prevIsDisabled, setPrevIsDisabled] = useState(isDisabled);

  if (prevIsDisabled !== isDisabled) {
    setPrevIsDisabled(isDisabled);
    if (isDisabled) {
      setIsFocused(false);
    }
  }

  return {
    isFocusVisible: isFocused && isFocusVisible,
    focusProps,
  };
};
