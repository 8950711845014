import { IUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import { isAdminOnSubscription } from '../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { IUser } from '../../data/reducers/user/IUser.type.ts';
import {
  getProjectInfo,
  getSortedUserProjects,
  isProjectPlanCanceled,
  isProjectPlanExpired,
  isProjectPlanSuspended,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { projectIdStorage } from '../../localStorages/projectIdStorage.ts';
import {
  EnsuredSubscriptionRouteParams,
  EnvironmentRoute,
  EnvironmentRouteParams,
  PlanSelectionRoute,
  ProjectsRoute,
  QuickstartRoute,
} from '../constants/routePaths.ts';
import { IStore } from '../stores/IStore.type.ts';
import { buildPath } from './routing/routeTransitionUtils.ts';
import { projectHasQuickstart } from './trialUtils.ts';

export const getProjectIdToRedirect = (user: IUser): Uuid | undefined => {
  const lastOpenedProjectId = projectIdStorage.load() || '';
  const userProjectInfo =
    user.projectsInfoById.get(lastOpenedProjectId) || user.projectsInfoById.first();
  return userProjectInfo?.projectId;
};

const isProjectToRedirectActive = (state: IStore, projectId: Uuid) =>
  !isProjectPlanExpired(state, projectId) &&
  !isProjectPlanSuspended(state, projectId) &&
  !isProjectPlanCanceled(state, projectId);

export const getPathToRedirectToFromRoot = (state: IStore): string => {
  const {
    data: { user },
  } = state;

  if (user.projectsInfoById.isEmpty()) {
    return ProjectsRoute;
  }

  const projectIdToRedirect = getProjectIdToRedirect(user);

  if (
    projectIdToRedirect &&
    projectHasQuickstart(state, getProjectInfo(state, projectIdToRedirect))
  ) {
    return buildPath<EnvironmentRouteParams>(QuickstartRoute, { projectId: projectIdToRedirect });
  }

  if (projectIdToRedirect && isProjectToRedirectActive(state, projectIdToRedirect)) {
    return buildPath<EnvironmentRouteParams>(EnvironmentRoute, { projectId: projectIdToRedirect });
  }

  const sortedUserProjects = getSortedUserProjects(user.projectsInfoById);
  const firstActiveProject = sortedUserProjects.find((project: IUserProjectInfo) =>
    isProjectToRedirectActive(state, project.projectId),
  );
  if (firstActiveProject) {
    return buildPath<EnvironmentRouteParams>(EnvironmentRoute, {
      projectId: firstActiveProject.projectId,
    });
  }

  const firstNotActiveUserProject = sortedUserProjects.first();
  return !isAdminOnSubscription(state, firstNotActiveUserProject?.subscriptionId ?? '') ||
    !firstNotActiveUserProject
    ? ProjectsRoute
    : buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
        subscriptionId: firstNotActiveUserProject.subscriptionId,
      });
};
