import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { isProjectManagerInAnyEnvironment } from '../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { EnvironmentOption } from './getSelectedEnvironmentOptions.ts';

export const hiddenEnvironmentsOptionId = 'da77a80c-e962-4420-8015-00f83e019496';

export const getHiddenEnvironmentOption = (state: IStore) =>
  hiddenEnvironmentCountMemoized(
    isProjectManagerInAnyEnvironment(state),
    state.data.apiKeys.keyDetail.environments,
    state.data.projects.byId,
  );

const hiddenEnvironmentCountMemoized = memoize.maxOne(
  (
    isProjectManager: boolean,
    environments: ReadonlyArray<Uuid>,
    projectsById: Immutable.Map<Uuid, IProjectDetails>,
  ): EnvironmentOption | null => {
    if (isProjectManager) {
      return null;
    }

    const hiddenEnvironmentsCount = environments.filter((e) => !projectsById.has(e)).length;

    if (hiddenEnvironmentsCount === 0) {
      return null;
    }

    return {
      id: hiddenEnvironmentsOptionId,
      label: `+${hiddenEnvironmentsCount} Other`,
    };
  },
);
