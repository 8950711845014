import { feedbackModalSubmitted, modalDismissed } from '../../actions/sharedActions.ts';
import { dismissUserFeedback } from '../../actions/thunks/dismissUserFeedback.ts';
import { submitUserFeedback } from '../../actions/thunks/submitUserFeedback.ts';
import { UmuxFeedbackModal } from '../../components/UmuxFeedback/UmuxFeedbackModal.tsx';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { UserFeedbackServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';

export const HelpUsImproveSurveyDialog = () => {
  const dispatch = useDispatch();

  const feedbackTimestamps = useSelector((s) => s.sharedApp.userProperties.userFeedback);

  const close = () => {
    dispatch(
      dismissUserFeedback(
        UserFeedbackSource.HelpUsImprove,
        UserFeedbackServerKey,
        feedbackTimestamps,
      ),
    );
    dispatch(modalDismissed());
  };

  const submit = async (ease: string, requirements: string, comment: string): Promise<void> => {
    await dispatch(
      submitUserFeedback({
        source: UserFeedbackSource.HelpUsImprove,
        propertyServerKey: UserFeedbackServerKey,
        feedbackTimestamps,
        ease,
        requirements,
        comment,
      }),
    );
    dispatch(feedbackModalSubmitted());
  };

  return (
    <UmuxFeedbackModal
      dismissFeedbackButtonText="I don’t want to answer now"
      easeTitle="Kontent.ai is easy to use."
      headerContent="What is your overall experience with Kontent.ai?"
      onClose={close}
      onSubmit={submit}
      requirementsTitle="Kontent.ai’s functionality meets my needs."
    />
  );
};
