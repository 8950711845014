import { ContentItemEditorReachedThresholds } from '../../../../../applications/itemEditor/types/ContentItemEditorReachedThresholds.type.ts';
import { EditorPaperThreshold } from '../paperThresholds.ts';

export const getReachedPaperThresholds = (
  paperThresholds: ReadonlySet<EditorPaperThreshold>,
): ContentItemEditorReachedThresholds => {
  const sizeL = paperThresholds.has(EditorPaperThreshold.LargeContentItemEditor);
  const sizeM = paperThresholds.has(EditorPaperThreshold.MediumContentItemEditor) && !sizeL;
  const sizeS =
    paperThresholds.has(EditorPaperThreshold.SmallContentItemEditor) && !sizeM && !sizeL;
  const sizeXS = paperThresholds.has(EditorPaperThreshold.None) && !sizeS && !sizeM && !sizeL;

  return {
    sizeXS,
    sizeS,
    sizeM,
    sizeL,
  };
};
