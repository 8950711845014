import React from 'react';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SitemapSelector } from '../../containers/details/SitemapSelector.tsx';

export interface ISitemapSectionProps {
  readonly isHidden: boolean;
}

export const SitemapSection: React.FC<ISitemapSectionProps> = ({ isHidden }) =>
  isHidden ? null : (
    <div
      className="sidebar__section sidebar__section--is-last-in-details-tab"
      {...getDataUiObjectNameAttribute(ItemDetailSection.Sitemap)}
    >
      <h2 className="sidebar__section-title">Location in sitemap</h2>
      <SitemapSelector />
    </div>
  );

SitemapSection.displayName = 'SitemapSection';
