import React from 'react';
import {
  FriendlyWarningReason,
  IFriendlyWarning,
} from '../../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';

interface IProps {
  readonly friendlyWarnings: ReadonlyArray<IFriendlyWarning>;
}

export const FriendlyWarningMessageForListing: React.FC<IProps> = ({ friendlyWarnings }) => {
  const hasBrokenModulars = friendlyWarnings.some(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.BrokenModularsInListing,
  );
  const hasBrokenLinks = friendlyWarnings.some(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.BrokenLinksInListing,
  );

  return (
    <div>
      <p>Some of the content items contain:</p>
      <ul>
        {hasBrokenModulars && <li>Content items that are not published</li>}
        {hasBrokenLinks && <li>Links to content items that are not published</li>}
      </ul>
      <p>
        Publishing the items highlighted in yellow might lead to <strong>broken links</strong> or{' '}
        <strong>break your app</strong>
      </p>
    </div>
  );
};

FriendlyWarningMessageForListing.displayName = 'FriendlyWarningMessageForListing';
