import { INewContentItemDialogProperties } from '../reducers/INewContentItemStoreState.type.ts';
import { NewContentItemOrigin } from './newContentItemOrigin.ts';
import {
  requiredNewItemSelectorsForContentComponent,
  requiredNewItemSelectorsForContentStatus,
  requiredNewItemSelectorsForLinkedItems,
  requiredNewItemSelectorsForListing,
  requiredNewItemSelectorsForProjectOverview,
  requiredNewItemSelectorsForSpaces,
  requiredNewItemSelectorsForWebSpotlight,
  requiredNewItemSelectorsForYourContent,
} from './requiredNewContentItemSelectors.ts';

export const newContentItemDialogPropertiesForContentStatus: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.ContentStatus,
  selectors: requiredNewItemSelectorsForContentStatus,
};

export const newContentItemDialogPropertiesForListing: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.Listing,
  selectors: requiredNewItemSelectorsForListing,
};

export const newContentItemDialogPropertiesForProjectOverview: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.ProjectOverview,
  selectors: requiredNewItemSelectorsForProjectOverview,
};

export const newContentItemDialogPropertiesForYourContent: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.YourContent,
  selectors: requiredNewItemSelectorsForYourContent,
};

export const newContentItemDialogPropertiesForWebSpotlight: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.WebSpotlight,
  selectors: requiredNewItemSelectorsForWebSpotlight,
};

export const newContentItemDialogPropertiesForSpaces: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.Space,
  selectors: requiredNewItemSelectorsForSpaces,
};

export const newContentItemDialogPropertiesForLinkedItems: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.Other,
  selectors: requiredNewItemSelectorsForLinkedItems,
};

export const newContentItemDialogPropertiesForContentComponent: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.ContentComponent,
  selectors: requiredNewItemSelectorsForContentComponent,
};
