import React, { Ref } from 'react';
import { IconButton } from '../../Button/IconButton/IconButton.tsx';

export type ClipboardButtonInjectedProps = Readonly<{
  readonly ref: Ref<HTMLButtonElement>;
  readonly isDisabled: boolean;
}>;

export const renderDefaultClipboardButton = (props: ClipboardButtonInjectedProps) => (
  <ClipboardButton {...props} />
);

export interface IClipboardButtonProps {
  readonly isDisabled?: boolean;
  readonly tooltipText?: string;
}

export const ClipboardButton = React.forwardRef<HTMLButtonElement, IClipboardButtonProps>(
  ({ isDisabled, tooltipText, ...otherProps }, forwardedRef) => (
    <IconButton
      key="clipboard-button"
      buttonState={isDisabled ? 'disabled' : 'default'}
      buttonStyle="primary"
      iconName="DocCopy"
      ref={forwardedRef}
      size="medium"
      tooltipPlacement="top-end"
      tooltipText={tooltipText ?? 'Copy to clipboard'}
      type="button"
      {...otherProps}
    />
  ),
);

ClipboardButton.displayName = 'ClipboardButton';
