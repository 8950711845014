import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { memoize } from '@kontent-ai/memoization';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import React, { useMemo } from 'react';
import { anyRoleOption } from '../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { RoleFilterOption } from '../../../../_shared/models/UserListingFilterOption.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { roleFilterUpdated } from '../actions/subscriptionUserListingActions.ts';

const getSortedRoles = memoize.weak(
  (roles: ReadonlyArray<IRole>): ReadonlyArray<IRole> =>
    roles.toSorted(
      createCompare({
        compare: alphabetically,
        select: (x) => x.name,
      }),
    ),
);

export const SubscriptionUsersRoleFilter: React.FC = () => {
  const loadedRoles = useSelector((s) => getSortedRoles(s.subscriptionApp.users.listingUi.roles));
  const isEnvironmentSelected = useSelector(
    (s) => !!s.subscriptionApp.users.listingUi.filter.byEnvironment,
  );
  const selectedRoleOptionIds = useSelector((s) => s.subscriptionApp.users.listingUi.filter.byRole);

  const loadedRoleOptions = useMemo(
    () =>
      loadedRoles.map((role) => ({
        ...role,
        label: role.name,
      })),
    [loadedRoles],
  );

  const dispatch = useDispatch();
  const onUpdateFilter = (updatedRoleIds: ReadonlySet<RoleFilterOption['id']>) => {
    dispatch(roleFilterUpdated(updatedRoleIds));
  };

  const placeholderProps = isEnvironmentSelected
    ? {
        placeholder: anyRoleOption.label,
        placeholderType: 'tag' as const,
      }
    : {
        placeholder: 'Select environment first',
      };

  return (
    <MultiSelect<RoleFilterOption>
      inputState={
        !isEnvironmentSelected || !loadedRoles.length ? InputState.Disabled : InputState.Default
      }
      label="Role"
      onSelectionChange={onUpdateFilter}
      items={loadedRoleOptions}
      selectedItemIds={selectedRoleOptionIds}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.RoleList)}
      {...getDataUiCollectionAttribute(DataUiCollection.RoleList)}
      {...placeholderProps}
    />
  );
};

SubscriptionUsersRoleFilter.displayName = 'SubscriptionUsersRoleFilter';
