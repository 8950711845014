import { assert } from '@kontent-ai/utils';
import React, { SetStateAction, useMemo, useState } from 'react';

type ActiveMenuContextState = {
  readonly setActiveMenuId: React.Dispatch<SetStateAction<string | null>>;
  readonly activeMenuId: string | null;
};

const defaultContext: ActiveMenuContextState = {
  setActiveMenuId: () =>
    assert(false, () => 'You must use ActiveMenuContextProvider at the top level of the page'),
  activeMenuId: null,
};

export const ActiveMenuContext = React.createContext<ActiveMenuContextState>(defaultContext);

type ActiveMenuContextProviderProps = {
  readonly children: React.ReactNode;
};

export const ActiveMenuContextProvider: React.FC<ActiveMenuContextProviderProps> = ({
  children,
}) => {
  const [activeMenuId, setActiveMenuId] = useState<string | null>(null);

  const contextState = useMemo<ActiveMenuContextState>(
    () => ({ activeMenuId, setActiveMenuId }),
    [activeMenuId],
  );

  return <ActiveMenuContext.Provider value={contextState}>{children}</ActiveMenuContext.Provider>;
};

ActiveMenuContextProvider.displayName = 'ActiveMenuContextProvider';
