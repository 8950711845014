import classNames from 'classnames';
import { ReactNode } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { DropDownOptionsTitle } from './DropDownOptionsTitle.tsx';

type Props = {
  readonly dataUiAttributes?: ObjectWithDataAttribute;
  readonly isScrollable?: boolean;
  readonly title?: ReactNode;
  readonly children: ReactNode;
};

export const DropDownOptionsGroup = ({
  dataUiAttributes,
  children,
  isScrollable,
  title,
}: Props) => (
  <div
    className={classNames('dropdown-options-group', {
      'dropdown-options-group--is-scrollable': isScrollable,
    })}
    {...dataUiAttributes}
  >
    {title && <DropDownOptionsTitle>{title}</DropDownOptionsTitle>}
    {children}
  </div>
);
