import { useCallback } from 'react';
import { ElementReference } from '../../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { AiMessageErrorCode } from '../../../../repositories/serverModels/ai/AiMessageErrorCode.ts';
import {
  ElementOperationTrackingData,
  createElementOperationTrackingData,
} from '../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { trackUserEventWithData } from '../../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../constants/trackedEvent.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { ContentItemId } from '../../../models/ContentItemId.ts';
import {
  AiElementEventContext,
  AiEventContext,
  AiFinishedAction,
  AiFollowingActionEventData,
  AiStartingActionEventData,
} from '../../../models/events/AiActionEventData.type.ts';
import { AiErrorCode } from '../types/aiErrors.ts';

export type GetElementOperationTrackingData = (aiSessionId: Uuid) => ElementOperationTrackingData;

type OmitFromUnion<T, K extends string | number | symbol> = T extends any ? Omit<T, K> : never;

type StartingActionParams<TContext extends AnyObject = object> = TContext &
  OmitFromUnion<AiStartingActionEventData, keyof AiElementEventContext>;

type FollowingActionParams<TContext extends AnyObject = object> = TContext &
  OmitFromUnion<AiFollowingActionEventData, keyof AiElementEventContext>;

export type FinishedActionParams<TContext extends AnyObject = object> = TContext & {
  readonly operationId: Uuid;
  readonly hasResult: boolean;
  readonly errorCode: AiMessageErrorCode | AiErrorCode.InactivityTimeout | undefined;
};

export type TrackStartingAction<TContext extends AnyObject = object> = (
  params: StartingActionParams<TContext>,
) => void;
export type TrackFollowingAction<TContext extends AnyObject = object> = (
  params: FollowingActionParams<TContext>,
) => void;
export type TrackFinishedAction<TContext extends AnyObject = object> = (
  params: FinishedActionParams<TContext>,
) => void;

const getFinishedActionName = <TContext extends AnyObject = object>({
  hasResult,
  errorCode,
}: FinishedActionParams<TContext>): AiFinishedAction => {
  if (!!errorCode && hasResult) {
    return AiFinishedAction.ResultWithError;
  }

  if (errorCode) {
    return AiFinishedAction.Error;
  }

  return AiFinishedAction.Result;
};

const emptyContentItemId: ContentItemId = {
  itemId: '00000000-0000-0000-0000-000000000000',
  variantId: '00000000-0000-0000-0000-000000000000',
};

// This hook can be used to track any AI activity
// Note there is useAiActionTrackingWithSession with smaller boilerplate available for simple scenarios
export const useAiActionTracking = <TContext extends AiEventContext = AiEventContext>(
  element: ElementReference,
): {
  readonly getElementOperationTrackingData: GetElementOperationTrackingData;
  readonly trackFinishedAction: TrackFinishedAction<TContext>;
  readonly trackFollowingAction: TrackFollowingAction<TContext>;
  readonly trackStartingAction: TrackStartingAction<TContext>;
} => {
  const dispatch = useDispatch();

  const getElementOperationTrackingData: GetElementOperationTrackingData = useCallback(
    (aiSessionId) =>
      createElementOperationTrackingData(
        element.itemId ?? emptyContentItemId,
        element.elementId,
        aiSessionId,
      ),
    [element],
  );

  const trackFollowingAction: TrackFollowingAction<TContext> = useCallback(
    (params) => {
      dispatch(
        trackUserEventWithData(TrackedEvent.AiAction, {
          elementId: element.elementId,
          elementType: element.elementType,
          ...params,
        }),
      );
    },
    [element],
  );

  const trackFinishedAction: TrackFinishedAction<TContext> = useCallback((params) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.AiAction, {
        action: getFinishedActionName(params),
        ...params,
      }),
    );
  }, []);

  const trackStartingAction: TrackStartingAction<TContext> = useCallback(
    (params) => {
      dispatch(
        trackUserEventWithData(TrackedEvent.AiAction, {
          elementId: element.elementId,
          elementType: element.elementType,
          ...params,
        }),
      );
    },
    [element],
  );

  return {
    getElementOperationTrackingData,
    trackFinishedAction,
    trackFollowingAction,
    trackStartingAction,
  };
};

export const useTrackingMethodWithContext = <
  TParams extends AnyObject,
  TContext extends AiEventContext,
>(
  track: (params: TParams) => void,
  context: TContext,
): ((params: Omit<TParams, keyof TContext>) => void) =>
  useCallback(
    (params: Omit<TParams, keyof TContext>) => track({ ...params, ...context } as TParams),
    [context, track],
  );
