import { useCallback, useState } from 'react';
import { useEventListener } from '../../../../_shared/hooks/useEventListener.ts';
import { AssetUploadFinishedEventForLocalState } from '../../../../_shared/utils/assets/AssetUploadFinishedEvent.ts';
import { CustomEventName } from '../../../../_shared/utils/events/KontentEventMap.ts';
import { ModalAssetEditor } from '../../../itemEditor/features/ModalAssetEdit/containers/ModalAssetEditor.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { IsEditorLocked } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { ModalsPlugin, OnCloseModal } from '../ModalsPlugin.tsx';
import { DragDropPlugin } from '../dragDrop/DragDropPlugin.tsx';

type EditAsset = (assetId: Uuid) => void;

type EditAssetPluginState = {
  readonly editAsset: EditAsset;
};

export type EditAssetPlugin = EditorPlugin<
  EditAssetPluginState,
  None,
  None,
  None,
  [DragDropPlugin, ModalsPlugin]
>;

export const EditAssetPlugin: PluginComponent<EditAssetPlugin> = (props) => {
  const [editedAssetId, setEditedAssetId] = useState<Uuid | null>(null);

  const handleAssetUploadFinishedEvent = useCallback(
    (event: AssetUploadFinishedEventForLocalState) => {
      const { oldAssetId, newAssetId } = event.detail;

      setEditedAssetId((prevState) => (prevState === oldAssetId ? newAssetId : prevState));
    },
    [],
  );

  useEventListener(
    CustomEventName.assetUploadFinishedForLocalState,
    handleAssetUploadFinishedEvent,
    self,
  );

  const editAsset = useCallback((assetId: Uuid): void => {
    setEditedAssetId(assetId);
  }, []);

  const isEditorLocked: Decorator<IsEditorLocked> = useCallback(
    (baseIsEditorLocked) => () => !!editedAssetId || baseIsEditorLocked(),
    [editedAssetId],
  );

  const apply: Apply<EditAssetPlugin> = useCallback(
    (state) => {
      state.isEditorLocked.decorate(isEditorLocked);

      const renderModalToViewer: Decorator<Render<EditAssetPlugin>> =
        (baseRenderModalToViewer) => (baseState) => {
          if (editedAssetId) {
            return (
              <ModalAssetEditor
                key={editedAssetId}
                assetId={editedAssetId}
                onAssetEditingFinished={() => {
                  closeEditAssetDialog();
                  baseState.focus();
                }}
              />
            );
          }
          return baseRenderModalToViewer(baseState);
        };

      state.renderModalToViewer.decorate(renderModalToViewer);

      const closeEditAssetDialog = (): void => {
        setEditedAssetId(null);
        state.focus();
      };

      const onCloseModal: Decorator<OnCloseModal> = (baseOnCloseModal) => () => {
        if (editedAssetId) {
          closeEditAssetDialog();
          return true;
        }
        return baseOnCloseModal();
      };

      state.onCloseModal.decorate(onCloseModal);

      return {
        editAsset,
      };
    },
    [editAsset, editedAssetId, isEditorLocked],
  );

  return useEditorWithPlugin(props, { apply });
};
