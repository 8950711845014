import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { BarItemAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ILinkedItemDuplicateActionProps {
  readonly isCompact?: boolean;
  readonly onClick: () => void;
}

export const LinkedItemDuplicateAction: React.FC<ILinkedItemDuplicateActionProps> = ({
  isCompact,
  onClick,
}) => {
  return (
    <Tooltip tooltipText="Duplicate" placement="bottom">
      <BarItemAction
        iconName={IconName.DocCopy}
        isCompact={isCompact}
        onClick={onClick}
        screenReaderText="Duplicate"
        dataUiActionName={DataUiAction.Duplicate}
      />
    </Tooltip>
  );
};

LinkedItemDuplicateAction.displayName = 'LinkedItemDuplicateAction';
