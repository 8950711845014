import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import { IProjectContributorServerModel } from '../../../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';
import { Users_ProjectContributorsLoaded } from '../../constants/usersActionTypes.ts';

interface IDeps {
  readonly peopleRepository: Pick<IPeopleRepository, 'getProjectContributors'>;
}

const projectContributorsLoaded = (
  usersServerModel: ReadonlyArray<IProjectContributorServerModel>,
) =>
  ({
    type: Users_ProjectContributorsLoaded,
    payload: { usersServerModel },
  }) as const;

export type LoadProjectContributorsActionsType = ReturnType<typeof projectContributorsLoaded>;

export const createLoadProjectContributorsAction =
  ({ peopleRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const contributorsServerModel = await peopleRepository.getProjectContributors(abortSignal);
    dispatch(projectContributorsLoaded(contributorsServerModel));
  };
