import { Textarea } from '@kontent-ai/component-library/Input';
import React, { useCallback } from 'react';
import { INote } from '../../../../../applications/itemEditor/models/contentItem/Assignment.ts';
import { EnterHotkeyDisabler } from '../../../../components/Hotkeys/EnterHotkeyDisabler.tsx';
import {
  DataUiInput,
  DataUiWorkflowAction,
  getDataUiInputAttribute,
  getDataUiWorkflowActionAttribute,
} from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { ModalSection } from '../ModalSection.tsx';

interface INoteSectionProps {
  readonly autoFocus?: boolean;
  readonly note: INote | null;
  readonly onNoteChanged: (note: string) => void;
}

export const NoteSection: React.FC<INoteSectionProps> = ({ autoFocus, note, onNoteChanged }) => {
  const changeNote = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>): void => onNoteChanged(event.target.value),
    [onNoteChanged],
  );

  return (
    <ModalSection key="noteSection">
      <EnterHotkeyDisabler>
        <Textarea
          label="Note"
          placeholder="Add any additional instructions or notes for other contributors"
          value={note?.noteText ?? ''}
          onChange={changeNote}
          rows={3}
          autoFocus={autoFocus}
          {...getDataUiWorkflowActionAttribute(DataUiWorkflowAction.AddNoteForContributors)}
          {...getDataUiInputAttribute(DataUiInput.Text)}
        />
      </EnterHotkeyDisabler>
    </ModalSection>
  );
};

NoteSection.displayName = 'NoteSection';
