import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { AssetDetail } from '../../../../contentInventory/assets/containers/AssetDetail.tsx';
import { isAssetUncategorized } from '../../../../contentInventory/assets/utils/assetTaxonomyUtils.ts';
import { DefaultSkinSidebar } from '../../../../contentInventory/shared/components/DefaultSkinSidebar.tsx';
import { AssetDetailUsage } from '../containers/AssetDetailUsage.tsx';

export interface IAssetEditorDialogProps {
  readonly editedAsset: IAsset | null;
  readonly taxonomyGroups: Immutable.Map<string, ITaxonomyGroup>;
}

export const AssetEditorDialogSidebar: React.FC<IAssetEditorDialogProps> = ({
  editedAsset,
  taxonomyGroups,
}) => {
  const isUncategorized = useSelector(
    (state) =>
      editedAsset &&
      isAssetUncategorized(editedAsset, state.data.assetTypes.defaultAssetType, taxonomyGroups),
  );

  return (
    <section className="canvas__sidebar asset-library-sidebar">
      <DefaultSkinSidebar sidebarContentClassNames="asset-library-sidebar__content">
        {editedAsset && (
          <>
            <AssetDetail
              key="asset_details"
              assetId={editedAsset.id}
              isUncategorized={isUncategorized}
            />
            <AssetDetailUsage key="asset_usage" assetId={editedAsset.id} isInDialog />
          </>
        )}
      </DefaultSkinSidebar>
    </section>
  );
};

AssetEditorDialogSidebar.displayName = 'AssetEditorDialogSidebar';
