import styled, { StyledComponent } from 'styled-components';
import { colorInputBackground, colorTextLowEmphasis } from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';

export const InputOverlayMessage: StyledComponent<'span', any> = styled.span.attrs(() =>
  getDataUiComponentAttribute(InputOverlayMessage),
)`
  position: absolute; 
  top: 0;
  left: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  ${Typography.UIParagraph};
  color: ${colorTextLowEmphasis};
  text-shadow: 0 0 ${Spacing.S} ${colorInputBackground};
`;

InputOverlayMessage.displayName = 'InputOverlayMessage';
