import { WorkflowStepColor } from '../../../../data/constants/WorkflowStepColor.ts';
import {
  ArchivedStep,
  ArchivedWorkflowStepId,
  WorkflowStepAction,
} from '../../../../data/models/workflow/WorkflowStep.ts';

export const archivedStepConstantValues: Pick<
  ArchivedStep,
  'id' | 'name' | 'codename' | 'color' | 'transitionsTo' | 'action'
> = {
  id: ArchivedWorkflowStepId,
  name: 'Archived',
  codename: 'archived',
  color: WorkflowStepColor.Gray,
  transitionsTo: new Set<Uuid>(),
  action: WorkflowStepAction.NoAction as const,
};
