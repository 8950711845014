import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import classNames from 'classnames';
import React from 'react';
import { CopyToClipboardIcon } from './CopyToClipboardIcon.tsx';

interface ICodePaneStateProps {
  readonly code: string;
}

interface ICodePaneState {
  readonly showAction: boolean;
}

export class CodePane extends React.PureComponent<ICodePaneStateProps, ICodePaneState> {
  static displayName = 'CodePane';

  readonly state: ICodePaneState = {
    showAction: false,
  };
  private showMessageDelay: ICancellablePromise | null = null;

  private readonly showAction = (): void => {
    this.setState(() => ({ showAction: true }));

    this.showMessageDelay = delay(2000)
      .then(() => {
        this.setState(() => ({ showAction: false }));
        this.showMessageDelay = null;
      })
      .catch(swallowCancelledPromiseError);
  };

  componentWillUnmount() {
    if (this.showMessageDelay) {
      this.showMessageDelay.cancel();
    }
  }

  render() {
    const { code } = this.props;
    const { showAction } = this.state;

    return (
      <div className="technology-pane__code-pane-container">
        <div className="technology-pane__code-pane">
          <code
            className={classNames('technology-pane__code-data', {
              'technology-pane__code-data--is-hidden': showAction,
            })}
          >
            {code}
          </code>
          {showAction && <span className="technology-pane__code-action-label">Copied!</span>}
        </div>
        <CopyToClipboardIcon text={code} onSuccess={this.showAction} />
      </div>
    );
  }
}
