export enum NewContentItemOrigin {
  ContentComponent = 'Content component',
  ContentStatus = 'Content status',
  Listing = 'Listing',
  None = 'None',
  Other = 'Other',
  ProjectOverview = 'Project overview',
  Space = 'Space',
  WebSpotlight = 'Web Spotlight',
  YourContent = 'Your content',
}
