import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import {
  formatUserName,
  userFirstNameComparer,
  userLastNameComparer,
} from '../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { UserListingColumnCode } from '../reducers/IUsersAppState.type.ts';

type Payload = (user1: IProjectContributor, user2: IProjectContributor) => number;

const getSorter = (sortByField: UserListingColumnCode): Payload => {
  switch (sortByField) {
    case UserListingColumnCode.LastName:
      return userLastNameComparer;

    case UserListingColumnCode.FirstName:
      return userFirstNameComparer;

    case UserListingColumnCode.Status:
      return createCompare({
        compare: alphabetically,
        select: (user) =>
          (user.isInvitationPending ? 'b' : user.inactive ? 'c' : 'a') +
          (user.isAdminOnProject ? 'b' : 'a') +
          formatUserName(user),
      });

    default:
      throw InvariantException(`Unknown sort field '${sortByField}'`);
  }
};

export const sortUsers = memoize.maxN(
  (
    users: ReadonlyArray<IProjectContributor>,
    sortBy: UserListingColumnCode,
  ): ReadonlyArray<IProjectContributor> => users.toSorted(getSorter(sortBy)),
  5,
);
