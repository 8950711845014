import { History } from 'history';
import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { EnvironmentRouteParams, UsersRoute } from '../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createSubscriptionFromServerModel } from '../../../../data/models/subscriptions/Subscription.ts';
import { IProjectServerModel } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import { ISubscriptionServerModel } from '../../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import { INewSubscriptionFormShape } from '../../../subscriptionManagement/SubscriptionListing/components/NewSubscriptionForm.tsx';
import {
  Subscription_ActivateTrial_Failed,
  Subscription_ActivateTrial_Finished,
  Subscription_ActivateTrial_Started,
} from '../../constants/projectActionTypes.ts';

interface IDeps {
  readonly subscriptionRepository: {
    readonly activateTrial: (
      subscriptionName: string,
      acceptTerms: boolean,
      isTrialExtended: boolean,
    ) => Promise<ISubscriptionServerModel>;
  };
  readonly createGettingStartedProject: (subscriptionId: Uuid) => Promise<IProjectServerModel>;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
}

const start = () =>
  ({
    type: Subscription_ActivateTrial_Started,
  }) as const;

const finish = () =>
  ({
    type: Subscription_ActivateTrial_Finished,
  }) as const;

const fail = () =>
  ({
    type: Subscription_ActivateTrial_Failed,
  }) as const;

export type ActivateTrialSubscriptionActionsType = ReturnType<
  typeof start | typeof finish | typeof fail
>;

export const activateTrialSubscriptionCreator =
  (deps: IDeps) =>
  (values: INewSubscriptionFormShape, history: History): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(start());
    try {
      const subscriptionServerModel = await deps.subscriptionRepository.activateTrial(
        values.subscriptionName,
        values.acceptTerms,
        false,
      );
      const createdSubscription = createSubscriptionFromServerModel(subscriptionServerModel);

      const gettingStartedProject = await deps.createGettingStartedProject(
        createdSubscription.subscriptionId,
      );
      await dispatch(deps.loadUserProjectsInfo());
      await dispatch(deps.loadAdministratedSubscriptions());

      dispatch(finish());
      history.push(
        buildPath<EnvironmentRouteParams>(UsersRoute, {
          projectId: gettingStartedProject.projectGuid,
        }),
      );
    } catch (error) {
      dispatch(fail());
      throw new Error(`Could not activate a trial subscription: ${error}`);
    }
  };
