import { createGuid } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { WorkflowScope } from '../../../../../data/models/workflow/Workflow.ts';
import {
  Workflow_Editing_CollectionsScopeChanged,
  Workflow_Editing_ContentTypesScopeChanged,
  Workflow_Editing_Left,
  Workflow_Editing_ScopeCreated,
  Workflow_Editing_ScopeDeleted,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
} from '../../constants/workflowActionTypes.ts';

export const editedWorkflowScopesById = (
  state: ReadonlyMap<Uuid, WorkflowScope> = new Map(),
  action: Action,
): ReadonlyMap<Uuid, WorkflowScope> => {
  switch (action.type) {
    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished:
      return new Map(action.payload.workflow.scopes.map((scopes) => [scopes.id, scopes]));

    case Workflow_Editing_ContentTypesScopeChanged: {
      const editedScope = state.get(action.payload.scopeId);
      if (!editedScope) {
        return state;
      }

      const newState = new Map(state);
      newState.set(action.payload.scopeId, {
        ...editedScope,
        contentTypes: action.payload.contentTypes,
      });
      return newState;
    }

    case Workflow_Editing_CollectionsScopeChanged: {
      const editedScope = state.get(action.payload.scopeId);
      if (!editedScope) {
        return state;
      }

      const newState = new Map(state);
      newState.set(action.payload.scopeId, {
        ...editedScope,
        collections: action.payload.collections,
      });
      return newState;
    }

    case Workflow_Editing_ScopeCreated: {
      const newScope: WorkflowScope = {
        id: createGuid(),
        collections: new Set(),
        contentTypes: new Set(),
      };
      return new Map([...state, [newScope.id, newScope]]);
    }

    case Workflow_Editing_ScopeDeleted: {
      const newState = new Map(state);
      newState.delete(action.payload.scopeId);
      return newState;
    }

    case Workflow_Editing_Left:
      return new Map();

    default:
      return state;
  }
};
