import { Dimensions } from '../types/ImageEditorTypes.type.ts';

export type MaxDimensionsLimits = {
  readonly maxHeight: number | null;
  readonly maxWidth: number | null;
};

export const FastlyMaxSupportedOutputDimension: number = 8192;

export const createInitialOutputDimensions = (
  originalDimensions: Dimensions,
  limitations: MaxDimensionsLimits,
): Dimensions => ({
  width: Math.min(
    originalDimensions.width,
    limitations.maxWidth ?? Number.POSITIVE_INFINITY,
    FastlyMaxSupportedOutputDimension,
  ),
  height: Math.min(
    originalDimensions.height,
    limitations.maxHeight ?? Number.POSITIVE_INFINITY,
    FastlyMaxSupportedOutputDimension,
  ),
});
