import React from 'react';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebSpotlightContent } from '../containers/WebSpotlightContent.tsx';

export const WebSpotlight: React.FC = () => {
  return (
    <div
      className="canvas web-spotlight__layout-guard"
      {...getDataUiAppNameAttribute(DataUiAppName.WebSpotlight)}
    >
      <HtmlPageTitle appName={AppNames.WebSpotlight} />
      <WebSpotlightContent />
    </div>
  );
};

WebSpotlight.displayName = 'WebSpotlight';
