import { NumberTypeElementValidationResult } from '../types/NumberTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const NumberInitialValidationResult: NumberTypeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  isDefaultValueValid: true,
  isGuidelinesValid: true,
  isNameValid: true,
  isRangeValid: true,
  errorMessages: [],
};
