import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getEditedContentItemVariantId } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { EditorChangeCallback } from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { isRichTextElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { richStringElementValueChanged } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  RichString as RichStringComponent,
  RichStringProps,
} from '../../../components/elements/RichString.tsx';

type Props = Omit<RichStringProps, 'elementData' | 'onChange'>;

export const RichString: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId },
  } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );

  const editedContentItemId = useSelector(getEditedContentItemVariantId);

  const onChange: EditorChangeCallback = useCallback(
    (newEditorState) => {
      if (editedContentItemId) {
        dispatch(
          richStringElementValueChanged(editedContentItemId, elementId, {
            _editorState: newEditorState,
          }),
        );
      }
      return Promise.resolve();
    },
    [editedContentItemId, elementId],
  );

  if (!isRichTextElement(elementData)) {
    return null;
  }

  return <RichStringComponent {...props} elementData={elementData} onChange={onChange} />;
};

RichString.displayName = 'RichStringContainer';
