import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ContentItemId,
  ContentItemIdString,
  ItemId,
  VariantId,
} from '../../../../../_shared/models/ContentItemId.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import {
  contentStatusCommentCountsLoaded,
  contentStatusCommentCountsStarted,
  contentStatusTaskCountsLoaded,
  contentStatusTaskCountsStarted,
} from '../commentOrTasks.ts';

type Deps = Readonly<{
  getCommentOrTaskCounts:
    | MissionControlRepository['getUnresolvedCommentCounts']
    | MissionControlRepository['getUnresolvedTaskCounts'];
  startAction: typeof contentStatusCommentCountsStarted | typeof contentStatusTaskCountsStarted;
  successAction: typeof contentStatusCommentCountsLoaded | typeof contentStatusTaskCountsLoaded;
}>;

export type LoadContentStatusCommentOrTaskCountsParams = Readonly<{
  contentItemIds: ReadonlyArray<ContentItemId>;
}>;

export const createLoadContentStatusCommentOrTaskCountsAction =
  (deps: Deps) =>
  (params: LoadContentStatusCommentOrTaskCountsParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const contentItemIdStrings = params.contentItemIds.map((contentItemId) =>
      stringifyContentItemId(contentItemId),
    );

    dispatch(
      deps.startAction({
        loadedContentItemIds: contentItemIdStrings,
      }),
    );

    const countsFromServer: ReadonlyRecord<`${ItemId}:${VariantId}`, number> =
      await deps.getCommentOrTaskCounts(params.contentItemIds, abortSignal);

    const counts: ReadonlyArray<readonly [ContentItemIdString, number]> = params.contentItemIds.map(
      (contentItemId) => {
        const key = stringifyContentItemId(contentItemId);
        const value: number = countsFromServer[key] ?? 0;
        return [key, value];
      },
    );

    dispatch(
      deps.successAction({
        counts,
      }),
    );
  };
