import { Action } from '../../../../../@types/Action.type.ts';
import { IRole, getRoleFromServerModel } from '../../../../../data/models/roles/IRole.ts';
import {
  Environment_CreateEnvironment_CloseDialog,
  Environment_CreateEnvironment_LoadRoles_Started,
  Environment_CreateEnvironment_LoadRoles_Success,
} from '../../constants/environmentActionTypes.ts';

const initialState: ReadonlyMap<Uuid, IRole> = new Map();

export function environmentRoles(
  state: ReadonlyMap<Uuid, IRole> = initialState,
  action: Action,
): ReadonlyMap<Uuid, IRole> {
  switch (action.type) {
    case Environment_CreateEnvironment_LoadRoles_Started:
    case Environment_CreateEnvironment_CloseDialog: {
      return initialState;
    }

    case Environment_CreateEnvironment_LoadRoles_Success: {
      const { data } = action.payload;

      const roles = data.reduce((reduced, roleServerModel) => {
        return reduced.set(roleServerModel.id, getRoleFromServerModel(roleServerModel));
      }, new Map<Uuid, IRole>());

      return roles;
    }

    default:
      return state;
  }
}
