import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { compareUserEnvironments } from '../../applications/projectSettings/environments/utils/environmentsUtils.ts';
import { CopyState } from '../../applications/projects/constants/copyState.ts';
import { ProjectUiTransitionState } from '../../applications/projects/types/projectUiTransitionState.ts';
import { compareUserProjects } from '../../applications/projects/utils/compareUserProjects.ts';
import { isProjectInSettingUpUiTransitionState } from '../../applications/projects/utils/projectUtils.ts';
import { IUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import {
  isProjectPlanExpired,
  isProjectPlanSuspended,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { ProjectOption } from '../utils/projects/projectUtils.ts';
import {
  getEnvironmentSwitchUrlFactory,
  getProjectSwitchUrlFactory,
  isAtProjectContainerLevel,
} from '../utils/routing/projectMenuUtils.ts';

export const getProjectsForMenu = memoize.maxOne(
  (
    projects: Immutable.Map<Uuid, IUserProjectInfo>,
    projectsUiTransitionState: Immutable.Map<Uuid, ProjectUiTransitionState>,
    pathname: string,
    state: IStore,
  ): Immutable.List<ProjectOption> => {
    const isProjectContainerLevel = isAtProjectContainerLevel(pathname);
    const buildProjectSwitchRoute = isProjectContainerLevel
      ? getProjectSwitchUrlFactory(pathname)
      : getEnvironmentSwitchUrlFactory(pathname);
    const projectsData = state.data.projects.byId;

    return projects
      .filterNot(
        (project: IUserProjectInfo) =>
          projectsData.get(project.projectId)?.copyState === CopyState.Failed,
      )
      .valueSeq()
      .filter(
        (project: IUserProjectInfo) =>
          !isProjectInSettingUpUiTransitionState(project.projectId, projectsUiTransitionState),
      )
      .groupBy((project: IUserProjectInfo) => project.masterEnvironmentId)
      .map((groupedProjects: Immutable.Map<number, IUserProjectInfo>) =>
        groupedProjects
          .valueSeq()
          .sort((a: IUserProjectInfo, b: IUserProjectInfo) => compareUserEnvironments(a, b))
          .first(),
      )
      .sort((a: IUserProjectInfo, b: IUserProjectInfo) => compareUserProjects(state, a, b))
      .map((project: IUserProjectInfo): ProjectOption => {
        const planIsExpired = isProjectPlanExpired(state, project.projectId);
        const planIsSuspended = isProjectPlanSuspended(state, project.projectId);

        return {
          projectName: project.projectName,
          masterEnvironmentId: project.masterEnvironmentId,
          planIsExpired,
          planIsSuspended,
          projectContainerId: project.projectContainerId,
          link: buildProjectSwitchRoute(
            isProjectContainerLevel ? project.projectContainerId : project.projectId,
            planIsExpired || planIsSuspended,
            true, //Environments are visible at all times
          ),
        };
      })
      .toList();
  },
  { maxArgs: 3 },
);
