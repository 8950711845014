import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import { CollectionGroupRoleBuilder } from '../../containers/roleBuilder/CollectionGroupRoleBuilder.tsx';
import { CollectionsGroupHeader } from './CollectionsGroupHeader.tsx';

type Props = IForwardedRefProps<HTMLDivElement> & {
  readonly allSelectedCollectionIds: ReadonlySet<Uuid>;
  readonly anyCollectionOptionName: string;
  readonly autoFocus?: boolean;
  readonly canChangeCollectionsInCurrentPlan: boolean;
  readonly contributorId?: Uuid | undefined;
  readonly disabledTooltip: string | undefined;
  readonly group: ICollectionGroupRoles;
  readonly isProjectManagerSelectionValid: boolean;
  readonly onChange: (group: ICollectionGroupRoles) => void;
  readonly onRemove: (() => void) | null;
};

const CollectionsGroup: React.FC<Props> = ({
  allSelectedCollectionIds,
  anyCollectionOptionName,
  autoFocus,
  canChangeCollectionsInCurrentPlan,
  contributorId,
  disabledTooltip,
  forwardedRef,
  group,
  isProjectManagerSelectionValid,
  onChange,
  onRemove,
}) => {
  const changeCollectionGroup =
    <TProp extends keyof ICollectionGroupRoles>(prop: TProp) =>
    (newValue: ICollectionGroupRoles[TProp]): void => {
      onChange({ ...group, [prop]: newValue });
    };

  return (
    <div
      ref={forwardedRef}
      className="collections-group"
      {...getDataUiElementAttribute(DataUiElement.CollectionsGroup)}
    >
      <CollectionsGroupHeader
        allSelectedCollectionIds={allSelectedCollectionIds}
        anyCollectionOptionName={anyCollectionOptionName}
        autoFocus={autoFocus}
        canChangeCollectionsInCurrentPlan={canChangeCollectionsInCurrentPlan}
        collectionIds={group.collectionIds}
        disabledTooltip={disabledTooltip}
        onCollectionIdsChange={changeCollectionGroup('collectionIds')}
        onRemove={onRemove}
      />
      <CollectionGroupRoleBuilder
        contributorId={contributorId}
        disabledTooltip={disabledTooltip}
        isProjectManagerSelectionValid={isProjectManagerSelectionValid}
        onChange={changeCollectionGroup('roles')}
        userRoles={group.roles}
      />
    </div>
  );
};

CollectionsGroup.displayName = 'CollectionsGroup';

const CollectionsGroupRVFC = forwardRef(CollectionsGroup);
export { CollectionsGroupRVFC as CollectionsGroup };
