import { memoize } from '@kontent-ai/memoization';
import { ContentBlock, ContentState, EntityInstance } from 'draft-js';
import Immutable from 'immutable';
import { isContentComponent, isContentModule } from '../blocks/blockTypeUtils.ts';

export const getBlocks = memoize.weak(
  (content: ContentState): ReadonlyArray<ContentBlock> => content.getBlocksAsArray(),
);

export const getContentModuleBlocks = memoize.weak((content: ContentState) =>
  getBlocks(content).filter(isContentModule),
);

export const getContentComponentBlocks = memoize.weak((content: ContentState) =>
  getBlocks(content).filter(isContentComponent),
);

export type EntityMap = Immutable.Map<string, EntityInstance> & {
  // This is to avoid deprecated warning as regular "get" logs it
  // https://github.com/facebook/draft-js/blob/master/src/model/immutable/ContentState.js#L163
  __get: (entityKey: string) => EntityInstance;
};

export function getEntityMap(content: ContentState): EntityMap {
  return (content as any).getEntityMap() as EntityMap;
}
