import { EditorState } from 'draft-js';
import React, { useCallback, useRef } from 'react';
import { ITextTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { EditorChangeCallback } from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { IBaseTextItemElement } from '../../../../../models/contentItemElements/IBaseTextItemElement.type.ts';
import { ITextItemElement } from '../../../../../models/contentItemElements/TextItemElement.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { SimpleMultilineTextInput } from '../../../containers/elements/stringElement/SimpleMultilineTextInput.tsx';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';

export type StringElementProps = IItemElementComponentOwnProps<
  ITextItemElement,
  ITextTypeElement
> & {
  readonly onChange: (newState: EditorState) => void;
};

export const StringElement: React.FC<StringElementProps> = ({
  elementData,
  typeElement,
  disabled,
  autoFocus,
  onChange,
}) => {
  const editorRef = useRef<IFocusable>(null);

  const focusEditorAtTheStart = useCallback(() => editorRef.current?.focusAtTheStart(), []);
  const focusEditorAtTheEnd = useCallback(() => editorRef.current?.focusAtTheEnd(), []);

  const onContentChange: EditorChangeCallback = useCallback(
    (editorState) => {
      onChange(editorState);
      return Promise.resolve();
    },
    [onChange],
  );

  return (
    <ItemElement
      typeElement={typeElement}
      disabled={disabled}
      onHeaderClick={focusEditorAtTheStart}
      onContentClick={focusEditorAtTheEnd}
    >
      <ItemElementRefresher
        elementData={elementData}
        renderInput={(refresherItemElement: IBaseTextItemElement) => (
          <SimpleMultilineTextInput
            autoFocus={autoFocus}
            className="rte--in-content-item-element"
            disabled={disabled}
            editorState={refresherItemElement._editorState}
            onContentChange={onContentChange}
            ref={editorRef}
            typeElement={typeElement}
          />
        )}
      />
    </ItemElement>
  );
};

StringElement.displayName = 'StringElement';
