export enum BusinessRole {
  NotProvided = 'not-provided',
  CEO = 'ceo',
  CTO = 'cto',
  Developer = 'developer',
  ProjectManager = 'project-manager',
  MarketingManager = 'marketing-manager',
  Other = 'other',
  ContentStrategistContentContributor = 'content-strategist_content-contributor',
  ContentCreatorManager = 'content-creator_manager',
}
