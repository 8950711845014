import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  AssetLibraryQueryStorage,
  AssetLibraryQueryWithoutMimeType,
} from '../../../../../localStorages/assetLibraryQueryStorage.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import {
  AssetLibrary_Query_Loaded,
  AssetLibrary_Query_Loading,
} from '../../constants/assetLibraryActionTypes.ts';
import {
  IAssetLibraryQuery,
  areAssetLibraryQueriesEqual,
} from '../../models/IAssetLibraryQuery.ts';
import {
  AssetQueryOrigin,
  getConsistentAssetLibraryQuery,
} from '../../utils/assetLibraryQueryUtils.ts';

const getStorage = (
  origin: AssetQueryOrigin,
): IProjectSpecificStorage<AssetLibraryQueryWithoutMimeType> => {
  switch (origin) {
    case AssetQueryOrigin.AssetLibrary: {
      return AssetLibraryQueryStorage;
    }
    default: {
      throw InvariantException(`Unknown query origin ${origin}`);
    }
  }
};

const loading = (origin: AssetQueryOrigin) =>
  ({
    type: AssetLibrary_Query_Loading,
    payload: { origin },
  }) as const;

const loaded = (query: IAssetLibraryQuery, origin: AssetQueryOrigin) =>
  ({
    type: AssetLibrary_Query_Loaded,
    payload: {
      query,
      origin,
    },
  }) as const;

export type InitializeAssetQueryActionsType = ReturnType<typeof loaded | typeof loading>;

interface IDeps {
  readonly ensureAssetLibraryRelatedData: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createLoadAndFixAssetQueryAction =
  (deps: IDeps) =>
  (origin: AssetQueryOrigin, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      sharedApp: { currentProjectId },
      assetLibraryApp: { query },
    } = state;

    dispatch(loading(origin));

    const storage = getStorage(origin);

    const loadedQuery = storage.load(currentProjectId);
    const queryFromStorage: IAssetLibraryQuery = {
      ...loadedQuery,
      mimeType: '',
      selectedCollections: loadedQuery.selectedCollections,
    };

    if (areAssetLibraryQueriesEqual(query, queryFromStorage)) {
      dispatch(loaded(query, origin));
      return;
    }

    await dispatch(deps.ensureAssetLibraryRelatedData(abortSignal));

    // Remove not existing data from query
    const updatedData = getState().data;
    const consistentQuery = getConsistentAssetLibraryQuery(updatedData, queryFromStorage);
    storage.save(consistentQuery, currentProjectId);
    dispatch(loaded(consistentQuery, origin));
  };
