import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';
import { IWebhookMessageContentServerModel } from '../../../../repositories/serverModels/WebhookMessageContentServerModel.type.ts';
import {
  Webhooks_WebhookMessageContent_LoadFinished,
  Webhooks_WebhookMessageContent_LoadStarted,
} from '../../constants/webhooksActionTypes.ts';

interface IDependencies {
  readonly webhookRepository: IWebhookRepository;
}

interface IActionParams {
  readonly webhookId: Uuid;
  readonly messageIndex: string;
}

const started = { type: Webhooks_WebhookMessageContent_LoadStarted } as const;

const finished = (webhookId: Uuid, webhookMessageContent: IWebhookMessageContentServerModel) =>
  ({
    type: Webhooks_WebhookMessageContent_LoadFinished,
    payload: {
      webhookId,
      webhookMessageContent,
    },
  }) as const;

export type LoadWebhookMessageContentActionsType = typeof started | ReturnType<typeof finished>;

export const createLoadWebhookMessageContentCreator =
  (dependencies: IDependencies) =>
  ({ webhookId, messageIndex }: IActionParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started);

    const messageContent: IWebhookMessageContentServerModel =
      await dependencies.webhookRepository.getMessageDetail(webhookId, messageIndex, abortSignal);

    dispatch(finished(webhookId, messageContent));
  };
