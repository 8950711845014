import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { FC, ReactNode, RefObject } from 'react';
import { createDropDown } from '../../../../../../../_shared/uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MenuOption } from './VariantQuickActionsNotificationBar.tsx';

type VariantQuickActionMenuProps = {
  readonly buttonContent: ReactNode;
  readonly buttonStyle?: ButtonStyle;
  readonly dataUiAction: DataUiAction;
  readonly disabledOptionTooltipText: string;
  readonly menuOptions: ReadonlyArray<MenuOption>;
  readonly onVariantSelect: (variantId: Uuid) => void;
};

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'bottom-start',
  offset: [0, 3],
};

const onOptionClick = (selectedOption: MenuOption, onVariantClick: (variantId: Uuid) => void) =>
  onVariantClick(selectedOption.variantId);

const DropDown = createDropDown<MenuOption>();

export const VariantQuickActionMenu: FC<VariantQuickActionMenuProps> = ({
  buttonContent,
  buttonStyle,
  dataUiAction,
  disabledOptionTooltipText,
  menuOptions,
  onVariantSelect,
}) => (
  <DropDown
    optionListDataUiAttributes={getDataUiCollectionAttribute(
      DataUiCollection.CopyFromLanguageVariant,
    )}
    options={menuOptions}
    renderSelectedOption={(ref: RefObject<HTMLButtonElement>, toggleMenu) => (
      <Button
        {...getDataUiActionAttribute(dataUiAction)}
        buttonStyle={buttonStyle || 'secondary'}
        onClick={toggleMenu}
        ref={ref}
        size="small"
      >
        {buttonContent}
      </Button>
    )}
    renderOption={(hideMenu, option: MenuOption) => (
      <DropDownOption
        key={option.variantId}
        tooltipText={option.isDisabled ? disabledOptionTooltipText : undefined}
        onClick={() => {
          hideMenu();
          if (!option.isDisabled) {
            onOptionClick(option, onVariantSelect);
          }
        }}
        isDisabled={option.isDisabled}
      >
        <DropDownOptionName text={option.name} />
      </DropDownOption>
    )}
    tippyOptions={tippyOptions}
  />
);

VariantQuickActionMenu.displayName = 'VariantQuickActionMenu';
