import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Environment_ApiStatus_Loaded,
  Environment_ContentManagementApiActivation_Finished,
  Environment_ContentManagementApiDeactivation_Finished,
  Environment_DeliveryApiSecureAccessActivation_Finished,
  Environment_DeliveryApiSecureAccessDeactivation_Finished,
} from '../../../../applications/environmentSettings/general/constants/generalSettingsActionTypes.ts';
import { ApiKeys_ApiStatus_Loaded } from '../../../../applications/projectSettings/apiKeys/constants/apiKeysActionTypes.ts';
import { ApiStatus } from '../ApiKeyStatus.type.ts';

const initialState = new Map<Uuid, ApiStatus>([]);

export const apiStatusPerEnvironment = (
  state: ReadonlyMap<Uuid, ApiStatus> = initialState,
  action: Action,
): ReadonlyMap<Uuid, ApiStatus> => {
  switch (action.type) {
    case ApiKeys_ApiStatus_Loaded: {
      return action.payload.apiKeysStatus;
    }

    case Environment_ApiStatus_Loaded: {
      return Collection.add(state, [action.payload.environmentId, action.payload.apiStatus]);
    }

    case Environment_ContentManagementApiActivation_Finished: {
      return Collection.replaceWith(state, action.payload.environmentId, (apiStatus) => ({
        ...apiStatus,
        isManagementApiEnabled: true,
      }));
    }

    case Environment_ContentManagementApiDeactivation_Finished: {
      return Collection.replaceWith(state, action.payload.environmentId, (apiStatus) => ({
        ...apiStatus,
        isManagementApiEnabled: false,
      }));
    }

    case Environment_DeliveryApiSecureAccessActivation_Finished: {
      return Collection.replaceWith(state, action.payload.environmentId, (apiStatus) => ({
        ...apiStatus,
        isSecuredDeliveryApiEnabled: true,
      }));
    }

    case Environment_DeliveryApiSecureAccessDeactivation_Finished: {
      return Collection.replaceWith(state, action.payload.environmentId, (apiStatus) => ({
        ...apiStatus,
        isSecuredDeliveryApiEnabled: false,
      }));
    }

    default:
      return state;
  }
};
