import {
  EntityTransformationOperationInput,
  StringOperationInput,
} from '../AiServerModels.input.type.ts';
import {
  AssetOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type DescribeImageParams =
  MultipleInputsOperationParamsModelBase<AssetOperationTrackingData> & {
    readonly actionName: 'DescribeImage';
    readonly inputs: {
      readonly asset: EntityTransformationOperationInput;
      readonly targetLanguageName: StringOperationInput;
      readonly targetLanguageCode: StringOperationInput;
    };
  };

export const createDescribeImageParams = (
  assetId: Uuid,
  targetLanguageName: string,
  targetLanguageCode: string,
  trackingData: AssetOperationTrackingData,
): DescribeImageParams => ({
  actionName: 'DescribeImage',
  type: 'multiple-inputs-request-v1',
  inputs: {
    asset: {
      type: 'entity-transformation',
      value: {
        entityId: assetId,
        entityTypeName: 'asset',
        transformationName: 'to-link',
      },
    },
    targetLanguageName: {
      type: 'string',
      value: targetLanguageName,
    },
    targetLanguageCode: {
      type: 'string',
      value: targetLanguageCode,
    },
  },
  trackingData,
});

export type DescribeImageResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isDescribeImageMessage = (
  message: OperationResponseMessage,
): message is DescribeImageResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
