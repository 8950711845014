import Immutable from 'immutable';
import { IAssetTypeElement } from '../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import {
  EmptyAssetItemElement,
  IAssetItemElement,
} from '../../models/contentItemElements/AssetItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createAssetItemElement: ItemElementCreator<IAssetTypeElement, IAssetItemElement> = (
  assetTypeElement,
  omitDefaultValue,
): IAssetItemElement => ({
  ...EmptyAssetItemElement,
  elementId: assetTypeElement.elementId,
  value: omitDefaultValue
    ? Immutable.OrderedMap()
    : Immutable.OrderedMap(assetTypeElement.defaultValue.map((asset) => [asset.id, asset])),
});
