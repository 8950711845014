export const Users_ProjectContributorsLoaded = 'Users_ProjectContributorsLoaded';
export const Users_UsersCountedInUsage_Loaded = 'Users_UsersCountedInUsage_Loaded';

export const UserListing_Init_Finished = 'UserListing_Init_Finished';
export const UserListing_Init_Started = 'UserListing_Init_Started';
export const UserListing_CollectionsFilter_Updated = 'UserListing_CollectionsFilter_Updated';
export const UserListing_LanguagesFilter_Updated = 'UserListing_LanguagesFilter_Updated';
export const UserListing_NameListingFilter_Changed = 'UserListing_NameListingFilter_Changed';
export const UserListing_RolesFilter_Updated = 'UserListing_UpdateRolesFilter_Updated';
export const UserListing_SearchPhrase_Cleared = 'UserListing_SearchPhrase_Cleared';
export const UserListing_SortBy_DirectionToggled = 'UserListing_SortBy_DirectionToggled';
export const UserListing_SortByLastName_Selected = 'UserListing_SortByLastName_Selected';
export const UserListing_SortByFirstName_Selected = 'UserListing_SortByFirstName_Selected';
export const UserListing_SortByStatus_Selected = 'UserListing_SortByStatus_Selected';
export const UserListing_StatusFilter_Updated = 'UserListing_StatusFilter_Updated';
export const UserListing_Left = 'UserListing_Left';

export const User_ResendInvite_Failed = 'User_ResendInvite_Failed';
export const User_ResendInvite_Finished = 'User_ResendInvite_Finished';
export const User_ResendInvite_Started = 'User_ResendInvite_Started';

export const User_RevokeInvite_Failed = 'User_RevokeInvite_Failed';
export const User_RevokeInvite_Finished = 'User_RevokeInvite_Finished';
export const User_RevokeInvite_Started = 'User_RevokeInvite_Started';

export const User_ToggleActiveState_Started = 'User_ToggleActiveState_Started';
export const User_ToggleActiveState_Finished = 'User_ToggleActiveState_Finished';
export const User_ToggleActiveState_Failed = 'User_ToggleActiveState_Failed';

export const UserDetail_UpdateCollectionGroups_Failed = 'UserDetail_UpdateCollectionGroups_Failed';
export const UserDetail_UpdateCollectionGroups_Finished =
  'UserDetail_UpdateCollectionGroups_Finished';
export const UserDetail_UpdateCollectionGroups_Started =
  'UserDetail_UpdateCollectionGroups_Started';
export const UserDetail_CollectionGroupsChanged = 'UserDetail_CollectionGroupsChanged';
export const UserDetail_Initialized = 'UserDetail_Initialized';
export const UserDetail_UserNotFound = 'UserDetail_UserNotFound';
export const UserDetail_NotificationBar_Hidden = 'UserDetail_NotificationBar_Hidden';
export const UserDetail_Closed = 'UserDetail_Closed';

export const Invite_Closed = 'Invite_Closed';
export const Invite_NotificationBar_Hidden = 'Invite_NotificationBar_Hidden';
export const Invite_Initialize = 'Invite_Initialize';
export const Invite_Invitations_Changed = 'Invite_Invitations_Changed';
export const Invite_SelectedCollectionGroups_Changed = 'Invite_SelectedCollectionGroups_Changed';
export const Invite_SearchText_Changed = 'Invite_SearchText_Changed';
export const Invite_ExistingUsers_Loaded = 'Invite_ExistingUsers_Loaded';
export const Invite_SubmitInvitations_Started = 'Invite_SubmitInvitations_Started';
export const Invite_SubmitInvitations_Finished = 'Invite_SubmitInvitations_Finished';
export const Invite_SubmitInvitations_Failed = 'Invite_SubmitInvitations_Failed';
