import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { EmailPreferenceType } from '../../../_shared/models/EmailPreferenceTypeEnum.ts';

export const getEmailPreferences = memoize.maxOne(
  (
    emailNotificationReportsWithZeroPriceDisabled: string,
    emailNotificationCommentsDisabled: string,
    emailNotificationAssignmentDisabled: string,
    emailNotificationMentionsDisabled: string,
    emailNotificationTasksDisabled: string,
    emailNotificationWebhookNotificationsDisabled: string,
  ): Immutable.Map<EmailPreferenceType, boolean> => {
    const emailPreferences = [
      [EmailPreferenceType.Assignment, emailNotificationAssignmentDisabled === 'false'],
      [EmailPreferenceType.Comments, emailNotificationCommentsDisabled === 'false'],
      [
        EmailPreferenceType.ReportsWithZeroPrice,
        emailNotificationReportsWithZeroPriceDisabled === 'false',
      ],
      [EmailPreferenceType.Mentions, emailNotificationMentionsDisabled === 'false'],
      [EmailPreferenceType.Tasks, emailNotificationTasksDisabled === 'false'],
      [
        EmailPreferenceType.WebhookNotifications,
        emailNotificationWebhookNotificationsDisabled === 'false',
      ],
    ];

    return Immutable.Map(emailPreferences);
  },
);
