import { assert } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ITaxonomyTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { ITaxonomyItemElement } from '../../../../models/contentItemElements/TaxonomyItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { TaxonomyDiff } from './subelements/TaxonomyDiff.tsx';

type TaxonomyProps = IRevisionItemElementProps<ITaxonomyItemElement, ITaxonomyTypeElement>;

export const Taxonomy: React.FC<TaxonomyProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => {
  const { taxonomyGroupId } = typeElement;
  assert(taxonomyGroupId, () => 'typeElement.taxonomyGroupId is falsy.');

  const taxonomyGroup = useSelector((s) => s.data.taxonomyGroups.byId.get(taxonomyGroupId));

  return (
    <ItemElementRefresher
      elementData={elementData}
      renderInput={() => (
        <ItemElement
          disabled
          hideValidationStatus={hideValidationStatus}
          isCompact
          isDisplayOnly
          title={taxonomyGroup?.name}
          typeElement={typeElement}
          revisionChangedBy={revisionElementModifiedBy}
        >
          <TaxonomyDiff
            elementData={elementData}
            originalElementData={originalElementData}
            typeElement={typeElement}
          />
        </ItemElement>
      )}
    />
  );
};

Taxonomy.displayName = 'Taxonomy (Revisions)';
