import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import { StatusBarActions } from '../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { WebhooksRoute, WebhooksRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  disableEntityWebhook,
  enableEntityWebhook,
} from '../../actions/thunkEntityWebhookActions.ts';
import { DefaultEntityWebhookId } from '../../constants/entityWebhookValues.ts';
import { EntityWebhookDisableConfirmationModal } from './EntityWebhookDisableConfirmationModal.tsx';

const { entityWebhookRepository } = repositoryCollection;

type EntityWebhookCreatorOrEditorToolbarActionsProps = {
  readonly enabled: boolean;
  readonly entityWebhookActionIsInProgress: boolean;
  readonly entityWebhookId: Uuid;
  readonly entityWebhookName: string;
  readonly onSubmit: (e?: KeyboardEvent | React.BaseSyntheticEvent) => Promise<void>;
  readonly onSetEntityWebhookActionIsInProgress: (entityWebhookActionIsInProgress: boolean) => void;
};

export const EntityWebhookCreatorOrEditorToolbarActions = ({
  enabled,
  entityWebhookActionIsInProgress,
  entityWebhookId,
  entityWebhookName,
  onSetEntityWebhookActionIsInProgress,
  onSubmit,
}: EntityWebhookCreatorOrEditorToolbarActionsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);
  const [showDisableConfirmationModal, setShowDisableConfirmationModal] = useState<boolean>(false);
  const [isSettingEntityWebhookStateInProgress, setIsSettingEntityWebhookStateInProgress] =
    useState<boolean>(false);

  const setProgress = (inProgress: boolean): void => {
    setIsSettingEntityWebhookStateInProgress(inProgress);
    onSetEntityWebhookActionIsInProgress(inProgress);
  };

  const performAction = async (action: () => Promise<void>): Promise<void> => {
    setProgress(true);
    await action().catch((): void => setProgress(false));
    setProgress(false);
  };

  return (
    <Row spacing={Spacing.M}>
      {enabled && (
        <Column>
          <StatusBarActions
            isBeingSaved={entityWebhookActionIsInProgress}
            isDuplicateDisabled
            isLoading={false}
            onSave={onSubmit}
          />
        </Column>
      )}
      {entityWebhookId !== DefaultEntityWebhookId &&
        (enabled ? (
          <Column>
            <EnterHotkeyDisabler>
              <div className="status-bar__button status-bar__action-button">
                <Button
                  buttonStyle="secondary"
                  destructive
                  destructiveIcon={Icons.Drawers}
                  disabled={entityWebhookActionIsInProgress}
                  onClick={() => setShowDisableConfirmationModal(true)}
                  size="medium"
                  {...getDataUiActionAttribute(DataUiAction.Disable)}
                >
                  <Button.Label>
                    {isSettingEntityWebhookStateInProgress ? 'Disabling' : 'Disable'}
                  </Button.Label>
                </Button>
              </div>
            </EnterHotkeyDisabler>
          </Column>
        ) : (
          <>
            <Column>
              <EnterHotkeyDisabler>
                <div className="status-bar__button status-bar__action-button">
                  <Button
                    tooltipShortcuts={ShortcutSymbols.Enter}
                    buttonStyle="primary"
                    disabled={entityWebhookActionIsInProgress}
                    onClick={() =>
                      performAction(() => dispatch(enableEntityWebhook(entityWebhookId)))
                    }
                    size="medium"
                    tooltipPlacement="bottom-start"
                    {...getDataUiActionAttribute(DataUiAction.Enable)}
                  >
                    <Button.Label>
                      {isSettingEntityWebhookStateInProgress ? 'Enabling' : 'Enable'}
                    </Button.Label>
                  </Button>
                </div>
              </EnterHotkeyDisabler>
            </Column>
            <Column>
              <EnterHotkeyDisabler>
                <div className="status-bar__button status-bar__action-button">
                  <Button
                    buttonStyle="secondary"
                    destructive
                    destructiveIcon={Icons.Bin}
                    disabled={entityWebhookActionIsInProgress}
                    onClick={async () => {
                      await performAction(async () => {
                        await entityWebhookRepository.archive(entityWebhookId);
                      });
                      history.push(
                        buildPath<WebhooksRouteParams>(WebhooksRoute, {
                          projectId,
                        }),
                      );
                    }}
                    size="medium"
                    {...getDataUiActionAttribute(DataUiAction.Delete)}
                  >
                    <Button.Label>Delete</Button.Label>
                  </Button>
                </div>
              </EnterHotkeyDisabler>
            </Column>
          </>
        ))}
      <EntityWebhookDisableConfirmationModal
        entityWebhookName={entityWebhookName}
        isOpen={showDisableConfirmationModal}
        onConfirm={async () => {
          await performAction(() => dispatch(disableEntityWebhook(entityWebhookId)));
          setShowDisableConfirmationModal(false);
        }}
        onCancel={() => setShowDisableConfirmationModal(false)}
      />
    </Row>
  );
};

EntityWebhookCreatorOrEditorToolbarActions.displayName =
  'EntityWebhookCreatorOrEditorToolbarActions';
