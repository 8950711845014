import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentProject } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getLanguageOptionsMemoized } from '../../../containers/selectors/copyContentFromVariantMenuOptions.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

export const isCopyFromLanguageAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) => {
  if (
    isPublishingStepSelected(variant.assignment) ||
    isArchivedWorkflowStepSelected(variant.assignment) ||
    !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state)
  ) {
    return false;
  }

  const {
    contentApp: { contentItemVariants, editedContentItem },
    data: { languages, user },
  } = state;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  const currentProject = getCurrentProject(state);
  const collectionId = editedContentItem?.collectionId ?? null;
  const copyContentLanguageOptions = getLanguageOptionsMemoized(
    languages,
    selectedLanguageId,
    contentItemVariants,
    user,
    currentProject.projectId,
    collectionId,
  );

  return copyContentLanguageOptions.length > 0;
};
