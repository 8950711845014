import React from 'react';
import { Guidelines as GuidelinesElement } from '../../../../../../_shared/containers/Guidelines.tsx';
import { IGuidelinesTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { ItemElement } from '../../containers/elements/ItemElement.tsx';

interface IGuidelinesProps {
  readonly disabled: boolean;
  readonly typeElement: IGuidelinesTypeElement;
}

const Guidelines: React.FC<IGuidelinesProps> = ({ disabled, typeElement }) => (
  <ItemElement typeElement={typeElement} disabled={disabled}>
    <GuidelinesElement typeElement={typeElement} />
  </ItemElement>
);

Guidelines.displayName = 'Guidelines';

export { Guidelines };
