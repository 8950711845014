import { checkPublishWarnings } from '../../../../applications/contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { displayWarningsOnPublishSelected } from '../../../../applications/itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { openBulkChangeWorkflowStepModal } from './thunks/openBulkChangeWorkflowStepModal.ts';
import { createOpenBulkPublishModalAction } from './thunks/openBulkPublishModal.ts';
import { openChangeWorkflowStepModal } from './thunks/openChangeWorkflowStepModal.ts';
import { createOpenPublishModalAction } from './thunks/openPublishModal.tsx';
import { createOpenRestoreFromArchivedStepModalAction } from './thunks/openRestoreFromArchivedStepModal.ts';
import { createOpenUnpublishAndArchiveModalAction } from './thunks/openUnpublishAndArchiveModal.ts';
import { createOpenUpdateStepModalAction } from './thunks/openUpdateStepModal.ts';

export const openUpdateStepModal = createOpenUpdateStepModalAction({
  displayWarningsOnPublishSelected,
  openChangeWorkflowStepModal,
});

export const openPublishModal = createOpenPublishModalAction({
  displayWarningsOnPublishSelected,
  openChangeWorkflowStepModal,
});

export const openRestoreItemFromArchivedStepModal = createOpenRestoreFromArchivedStepModalAction({
  openChangeWorkflowStepModal,
});

export const openUnpublishAndArchiveModal = createOpenUnpublishAndArchiveModalAction({
  openChangeWorkflowStepModal,
});

export const openBulkPublishModal = createOpenBulkPublishModalAction({
  checkPublishWarnings,
  openBulkChangeWorkflowStepModal,
});
