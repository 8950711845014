import { Action } from '../../../../@types/Action.type.ts';
import { ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set } from '../constants/changeWorkflowStepActionTypes.ts';

export function isInBulk(state = false, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.isInBulk;

    default:
      return state;
  }
}
