import { Link } from 'react-router-dom';

type Props = {
  readonly onNavigate: () => void;
  readonly settingsPath: string;
};

export const NotSetupPreviewPopupForm = ({ settingsPath, onNavigate }: Props) => {
  return (
    <div className="content-editing-actions__preview-form">
      <span>To preview your content, </span>
      <Link to={settingsPath} onClick={onNavigate} className="content-editing-actions__link">
        set up a preview URL
      </Link>
      <span> first.</span>
    </div>
  );
};
