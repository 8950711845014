import React, { memo } from 'react';
import { IAuditEvent } from '../../../../data/models/auditLog/AuditEvent.ts';
import { AuditLogEvent } from '../components/AuditLogEvent.tsx';

export interface IAuditEventTile {
  readonly item: IAuditEvent;
}

const AuditLogEventTile: React.FC<IAuditEventTile> = (auditEventTile) => {
  return <AuditLogEvent auditLogEvent={auditEventTile.item} />;
};

AuditLogEventTile.displayName = 'AuditLogEventTile';

const AuditLogEventTileMemo = memo(AuditLogEventTile);
AuditLogEventTileMemo.displayName = `memo(${AuditLogEventTileMemo.displayName})`;

export { AuditLogEventTileMemo as AuditLogEventTile };
