import { AiErrorCode, AiErrorCodeMap } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';

export const translateContentErrorMessageByErrorCode: AiErrorCodeMap = {
  ...baseErrorMessageByErrorCode,
  [AiErrorCode.ActionNotSupported]: 'An error occurred. Please try again.',
  [AiErrorCode.General]: 'An error occurred. Please try again.',
  [AiErrorCode.InactivityTimeout]: 'An error occurred. Please try again.',
  [AiErrorCode.InputContentFilter]: 'An error occurred. Please try again.',
  [AiErrorCode.InputTooLong]: 'An error occurred. Please try again.',
  [AiErrorCode.InputTooShort]:
    'The AI didn’t find enough content in the source language to translate.',
  [AiErrorCode.InvalidData]: 'An error occurred. Please try again.',
  [AiErrorCode.NoResult]: 'An error occurred. Please try again.',
  [AiErrorCode.OutputIncomplete]: 'An error occurred. Please try again.',
  [AiErrorCode.ResponseContentFilter]: 'An error occurred. Please try again.',
  [AiErrorCode.Unable]: 'Unknown source or target language name.',
};
