import { createGuid } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IMultipleChoiceOptionData } from '../../../../_shared/models/MultipleChoiceOption.type.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Creator_CreationStarted,
  ContentType_Creator_InitFinished,
  ContentType_Creator_InitStarted,
  ContentType_Editor_AnyInvalidElement_ShowWarning,
  ContentType_Editor_ChangeCodename,
  ContentType_Editor_ChangeContentTypeName,
  ContentType_Editor_ChangeMultipleChoiceOptionCodename,
  ContentType_Editor_ChangeTypeElementCodename,
  ContentType_Editor_ContentGroupCodenameRenamed,
  ContentType_Editor_ContentGroupCreated,
  ContentType_Editor_ContentGroupDeleted,
  ContentType_Editor_ContentGroupMoveFinished,
  ContentType_Editor_ContentGroupMoveStarted,
  ContentType_Editor_ContentGroupMoved,
  ContentType_Editor_ContentGroupRenamed,
  ContentType_Editor_DuplicatingFailed,
  ContentType_Editor_DuplicatingFinished,
  ContentType_Editor_DuplicatingStarted,
  ContentType_Editor_HideConfiguration,
  ContentType_Editor_InitFinished,
  ContentType_Editor_MultipleChoiceCreateOption,
  ContentType_Editor_MultipleChoiceDeleteOption,
  ContentType_Editor_MultipleChoiceMoveOption,
  ContentType_Editor_MultipleChoiceOptionDeselected,
  ContentType_Editor_MultipleChoiceOptionDropped,
  ContentType_Editor_MultipleChoiceOptionLabelChanged,
  ContentType_Editor_MultipleChoiceOptionPickedUp,
  ContentType_Editor_MultipleChoiceOptionSelected,
  ContentType_Editor_MultipleChoiceUpdateOption,
  ContentType_Editor_SavingFinished,
  ContentType_Editor_SelectedContentGroupChanged,
  ContentType_Editor_SelectedTypeElementsSentToContentGroup,
  ContentType_Editor_SelectedTypeElementsSentToNewContentGroup,
  ContentType_Editor_ShowConfiguration,
  ContentType_Editor_TypeElementSelectionToggled,
  ContentType_Listing_FilterSearchPhraseChanged,
  ContentType_Listing_ResetOperationState,
  ContentType_Listing_RestoreContentTypesFinished,
  ContentType_Listing_RestoreContentTypesStarted,
  ContentType_Listing_RouteEntered,
  ContentType_Listing_SelectedTypesUpdated,
  ContentType_LoadComponentUsage_Dismissed,
} from '../constants/contentTypesActionTypes.ts';
import { ContentTypeListingOperationStatus } from '../utils/contentTypeListingOperationStatus.ts';

export const createMultipleChoiceOption = (option: IMultipleChoiceOptionData, elementId: Uuid) =>
  ({
    type: ContentType_Editor_MultipleChoiceCreateOption,
    payload: {
      option,
      elementId,
    },
  }) as const;

export const updateMultipleChoiceOption = (option: IMultipleChoiceOptionData, elementId: Uuid) =>
  ({
    type: ContentType_Editor_MultipleChoiceUpdateOption,
    payload: {
      option,
      elementId,
    },
  }) as const;

export const multipleChoiceOptionSelected = (option: IMultipleChoiceOptionData, elementId: Uuid) =>
  ({
    type: ContentType_Editor_MultipleChoiceOptionSelected,
    payload: {
      elementId,
      option,
    },
  }) as const;

export const multipleChoiceOptionDeselected = () =>
  ({
    type: ContentType_Editor_MultipleChoiceOptionDeselected,
  }) as const;

export const multipleChoiceOptionLabelChanged = (label: string) =>
  ({
    type: ContentType_Editor_MultipleChoiceOptionLabelChanged,
    payload: { label },
  }) as const;

export const deleteMultipleChoiceOption = (optionId: Uuid, elementId: Uuid) =>
  ({
    type: ContentType_Editor_MultipleChoiceDeleteOption,
    payload: {
      optionId,
      elementId,
    },
  }) as const;

export const multipleChoiceOptionPickedUp = (optionId: Uuid) =>
  ({
    type: ContentType_Editor_MultipleChoiceOptionPickedUp,
    payload: { optionId },
  }) as const;

export const multipleChoiceOptionDropped = () =>
  ({
    type: ContentType_Editor_MultipleChoiceOptionDropped,
  }) as const;

export const moveMultipleChoiceOption = (
  draggedOptionId: Uuid,
  targetOptionId: Uuid,
  elementId: Uuid,
) =>
  ({
    type: ContentType_Editor_MultipleChoiceMoveOption,
    payload: {
      draggedOptionId,
      targetOptionId,
      elementId,
    },
  }) as const;

export const changeContentTypeName = (name: string) =>
  ({
    type: ContentType_Editor_ChangeContentTypeName,
    payload: { name },
  }) as const;

export const changeContentTypeCodename = (codename: string) =>
  ({
    type: ContentType_Editor_ChangeCodename,
    payload: { codename },
  }) as const;

export const changeContentTypeElementCodename = (codename: string, elementId: Uuid) =>
  ({
    type: ContentType_Editor_ChangeTypeElementCodename,
    payload: {
      codename,
      elementId,
    },
  }) as const;

export const changeContentTypeMultipleChoiceOptionCodename = (
  codename: string,
  elementId: Uuid,
  optionId: Uuid,
) =>
  ({
    type: ContentType_Editor_ChangeMultipleChoiceOptionCodename,
    payload: {
      codename,
      elementId,
      optionId,
    },
  }) as const;

export const showTypeElementConfiguration = (elementId: Uuid) =>
  ({
    type: ContentType_Editor_ShowConfiguration,
    payload: {
      elementId,
    },
  }) as const;

export const hideTypeElementConfiguration = (elementId: Uuid) =>
  ({
    type: ContentType_Editor_HideConfiguration,
    payload: {
      elementId,
    },
  }) as const;

export const saveEditedContentTypeFinished = (contentType: IContentType) =>
  ({
    type: ContentType_Editor_SavingFinished,
    payload: {
      contentType,
    },
  }) as const;

export const initContentTypeEditorFinished = (isEditedContentTypeUsedInPublishedItem: boolean) =>
  ({
    type: ContentType_Editor_InitFinished,
    payload: {
      isEditedContentTypeUsedInPublishedItem,
    },
  }) as const;

export const contentTypeInvalidElementShowWarning = () =>
  ({
    type: ContentType_Editor_AnyInvalidElement_ShowWarning,
  }) as const;

export const contentTypeCreatorInitStarted = () =>
  ({
    type: ContentType_Creator_InitStarted,
  }) as const;

export const contentTypeCreatorInitFinished = () =>
  ({
    type: ContentType_Creator_InitFinished,
  }) as const;

export const contentTypeCreationStarted = () =>
  ({
    type: ContentType_Creator_CreationStarted,
  }) as const;

export const contentTypeCreationFinished = (editedContentType: IContentType) =>
  ({
    type: ContentType_Creator_CreationFinished,
    payload: {
      editedContentType,
    },
  }) as const;

export const selectedContentTypesUpdated = (selectedContentTypeIds: Immutable.Set<Uuid>) =>
  ({
    type: ContentType_Listing_SelectedTypesUpdated,
    payload: { selectedContentTypeIds },
  }) as const;

export const restoreContentTypesStarted = (affectedContentTypes: Immutable.Set<IContentType>) =>
  ({
    type: ContentType_Listing_RestoreContentTypesStarted,
    payload: { affectedContentTypes },
  }) as const;

export const restoreContentTypesFinished = (
  affectedContentTypeIds: Immutable.Set<Uuid>,
  successfulIds: Immutable.Set<Uuid>,
) =>
  ({
    type: ContentType_Listing_RestoreContentTypesFinished,
    payload: {
      affectedContentTypeIds,
      successfulIds,
    },
  }) as const;

export const resetContentTypeListingOperationState = (
  operationToReset: ContentTypeListingOperationStatus,
) =>
  ({
    type: ContentType_Listing_ResetOperationState,
    payload: {
      operationToReset,
    },
  }) as const;

export const contentTypeListingFilterSearchPhraseChanged = (searchPhrase: string) =>
  ({
    type: ContentType_Listing_FilterSearchPhraseChanged,
    payload: { searchPhrase },
  }) as const;

export const contentTypeListingRouteEntered = () =>
  ({
    type: ContentType_Listing_RouteEntered,
  }) as const;

export const selectedContentGroupChanged = (contentGroupId: Uuid | null) =>
  ({
    type: ContentType_Editor_SelectedContentGroupChanged,
    payload: {
      contentGroupId,
    },
  }) as const;

export const newContentGroupCreated = (contentGroupName: string) =>
  ({
    type: ContentType_Editor_ContentGroupCreated,
    payload: {
      contentGroup: {
        id: createGuid(),
        name: contentGroupName,
        codename: null,
      },
    },
  }) as const;

export const contentGroupDeleted = (contentGroupId: Uuid) =>
  ({
    type: ContentType_Editor_ContentGroupDeleted,
    payload: {
      contentGroupId,
    },
  }) as const;

export const contentGroupRenamed = (contentGroupId: Uuid, newContentGroupName: string) =>
  ({
    type: ContentType_Editor_ContentGroupRenamed,
    payload: {
      contentGroupId,
      newContentGroupName,
    },
  }) as const;

export const contentGroupCodenameRenamed = (
  contentGroupId: Uuid,
  newContentGroupCodename: string,
) =>
  ({
    type: ContentType_Editor_ContentGroupCodenameRenamed,
    payload: {
      contentGroupId,
      newContentGroupCodename,
    },
  }) as const;

export const contentGroupMoveStarted = (contentGroupId: Uuid) =>
  ({
    type: ContentType_Editor_ContentGroupMoveStarted,
    payload: {
      contentGroupId,
    },
  }) as const;

export const contentGroupMoved = (movedContentGroupId: Uuid, targetContentGroupId: Uuid) =>
  ({
    type: ContentType_Editor_ContentGroupMoved,
    payload: {
      movedContentGroupId,
      targetContentGroupId,
    },
  }) as const;

export const contentGroupMoveFinished = () =>
  ({
    type: ContentType_Editor_ContentGroupMoveFinished,
  }) as const;

export const selectedTypeElementsSentToContentGroup = (
  selectedTypeElementIds: Array<Uuid>,
  contentGroupId: Uuid,
) =>
  ({
    type: ContentType_Editor_SelectedTypeElementsSentToContentGroup,
    payload: {
      selectedTypeElementIds,
      contentGroupId,
    },
  }) as const;

export const selectedTypeElementsSentToNewContentGroup = (
  selectedTypeElementIds: Array<Uuid>,
  contentGroupName: string,
) =>
  ({
    type: ContentType_Editor_SelectedTypeElementsSentToNewContentGroup,
    payload: {
      selectedTypeElementIds,
      contentGroup: {
        id: createGuid(),
        name: contentGroupName,
        codename: null,
      },
    },
  }) as const;

export const typeElementSelectionToggled = (elementId: Uuid, isSelected: boolean) =>
  ({
    type: ContentType_Editor_TypeElementSelectionToggled,
    payload: {
      elementId,
      isSelected,
    },
  }) as const;

export const contentTypeDuplicatingStarted = () =>
  ({
    type: ContentType_Editor_DuplicatingStarted,
  }) as const;

export const contentTypeDuplicatingFinished = (contentType: IContentType) =>
  ({
    type: ContentType_Editor_DuplicatingFinished,
    payload: {
      contentType,
    },
  }) as const;

export const contentTypeDuplicatingFailed = () =>
  ({
    type: ContentType_Editor_DuplicatingFailed,
  }) as const;

export const contentTypeAsComponentUsageModalDismissed = () =>
  ({
    type: ContentType_LoadComponentUsage_Dismissed,
  }) as const;
