import React, { ComponentProps } from 'react';
import { Tag } from '../../../../../client/component-library/components/Tag/Tag.tsx';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
type CountStatusProps = ComponentProps<typeof Tag.CountStatus>;
type GeneralTagSpecificProps = Readonly<{
  countValue: CountStatusProps['value'];
  countPlacement: 'before' | 'after';
  label: string;
  children?: never;
}>;

type Props = Omit<ComponentProps<typeof Tag>, 'background'> & GeneralTagSpecificProps;

export const GeneralTag = React.forwardRef<HTMLButtonElement, Props>(
  ({ countValue, countPlacement, label, ...otherProps }, forwardedRef) => (
    <Tag background={BaseColor.Orange30} label={label} ref={forwardedRef} {...otherProps}>
      {countPlacement === 'before' && <Tag.CountStatus value={countValue} />}
      <Tag.Label>{label}</Tag.Label>
      {countPlacement === 'after' && <Tag.CountStatus value={countValue} />}
    </Tag>
  ),
);

GeneralTag.displayName = 'GeneralTag';
