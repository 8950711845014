import { usePrevious } from '@kontent-ai/hooks';
import { useContext, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { LanguageFilterContext } from '../../../../../unifiedMissionControl/contentStatus/contexts/LanguageFilter.tsx';
import { IListingFilter, areListingFiltersEqual } from '../../../models/filter/IListingFilter.ts';
import { ItemListingFilterDebounceTime } from '../constants/listingFilterConstants.ts';

export const useFilterChanged = (
  onFilterChange: () => void,
  filter: IListingFilter,
  listingItemsLoadingStatus: LoadingStatus,
): void => {
  const { selectedLanguageIds } = useContext(LanguageFilterContext);
  const onFilterChangedDebounced = useDebouncedCallback(
    onFilterChange,
    ItemListingFilterDebounceTime,
  );
  const previousFilter = usePrevious(filter);
  const previousSelectedLanguageIds = usePrevious(selectedLanguageIds);

  useEffect(() => {
    if (
      listingItemsLoadingStatus === LoadingStatus.InitialEmpty ||
      !areListingFiltersEqual(filter, previousFilter) ||
      selectedLanguageIds !== previousSelectedLanguageIds
    ) {
      onFilterChangedDebounced();
    }
  }, [
    onFilterChangedDebounced,
    filter,
    previousFilter,
    listingItemsLoadingStatus,
    selectedLanguageIds,
    previousSelectedLanguageIds,
  ]);

  // Cancellation should not happen on filter change.
  useEffect(() => onFilterChangedDebounced.cancel, [onFilterChangedDebounced]);
};
