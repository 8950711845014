import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  Calendar_Initialization_Started,
  Calendar_LoadItemsWithVariantActiveInPeriod_Finished,
  Calendar_LoadItemsWithVariantActiveInPeriod_Started,
} from '../../constants/calendarActionTypes.ts';

const initialState: Immutable.OrderedSet<Uuid> = Immutable.OrderedSet();

export const orderedItemIds = (
  state: Immutable.OrderedSet<Uuid> = initialState,
  action: Action,
): Immutable.OrderedSet<Uuid> => {
  switch (action.type) {
    case Calendar_LoadItemsWithVariantActiveInPeriod_Finished: {
      return Immutable.OrderedSet(
        action.payload.contentItems.map((item: IListingContentItem) => item.item.id),
      );
    }

    case Calendar_Initialization_Started:
    case Calendar_LoadItemsWithVariantActiveInPeriod_Started:
      return initialState;

    default:
      return state;
  }
};
