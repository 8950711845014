import { ReactNode } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { loadTimeline } from '../../Timeline/actions/thunkTimelineActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureEntryTimeline = ({ children }: Props) => {
  const [isLoaded] = useThunkPromise(loadTimeline);
  const isStateEnsured = useSelector(
    (state) => isLoaded && state.contentApp.entryTimeline !== null,
  );

  return isStateEnsured ? children : <Loader />;
};
