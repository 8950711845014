import { InvariantException } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import {
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getRevisionToRestore } from '../../../../../../_shared/selectors/StatusBar/revisions/revisionSelectors.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishedWorkflowStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IUpdateAssignmentSectionParams } from '../../../ContentItemEditing/actions/thunks/updateAssignment.ts';
import { AssignmentSections } from '../../../ContentItemEditing/constants/AssignmentSections.ts';
import {
  ContentRevisions_RevisionRestoreFinished,
  ContentRevisions_RevisionRestoreStarted,
} from '../../constants/revisionActionTypes.ts';
import { RevisionRestoredQuery } from '../../constants/uiConstants.ts';
import { createAssignmentWithFirstWorkflowStepFromArchived } from '../../utils/createAssignmentWithFirstWorkflowStepFromArchived.ts';

interface IRestoreRevisionActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly createNewVersion: (onNewVersionCreated?: () => void) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventWithDataAction;
  readonly updateAssignment: (params: IUpdateAssignmentSectionParams) => ThunkPromise;
}

const revisionRestoreStarted = () =>
  ({
    type: ContentRevisions_RevisionRestoreStarted,
  }) as const;

const revisionRestoreFinished = (itemId: Uuid) =>
  ({
    type: ContentRevisions_RevisionRestoreFinished,
    payload: { itemId },
  }) as const;

export type RestoreRevisionActionsType = ReturnType<
  typeof revisionRestoreStarted | typeof revisionRestoreFinished
>;

export const restoreRevisionActionCreator =
  (deps: IRestoreRevisionActionDependencies) =>
  (history: History, match: ContentItemRouteParams<string>): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();
    const { editedContentItemVariant, entryTimeline } = state.contentApp;

    const params: ContentItemRouteParams<UuidArray> = {
      app: match.app,
      projectId: match.projectId,
      variantId: match.variantId,
      spaceId: match.spaceId,
      contentItemIds: parseContentItemIds(match.contentItemIds),
    };

    if (!entryTimeline) {
      throw InvariantException('Item timeline is missing in state');
    }

    const revisionToRestore = getRevisionToRestore(state);

    assert(revisionToRestore, () => `${__filename}: revisionToRestore is falsy`);

    const revisionId = revisionToRestore.revisionId;
    const contentItemId = revisionToRestore.contentItemId;

    assert(revisionId, () => 'Cannot restore revision with a falsy revision id');

    const timelineItemIndex = entryTimeline.findIndex(
      (item) => !!item && item.revisionId === revisionId,
    );

    assert(
      timelineItemIndex >= 0,
      () => `Can’t find a timeline item with revision id "${revisionId}"`,
    );

    dispatch(revisionRestoreStarted());

    const timelineItem = entryTimeline.get(timelineItemIndex);

    const userEventData = {
      'entry-id': contentItemId.itemId,
      'revision-id': revisionId,
      'revision-created-at': timelineItem?.time || '',
      'revision-index': timelineItemIndex,
    };

    assert(editedContentItemVariant, () => 'Edited content item variant is falsy.');

    if (isPublishedWorkflowStepSelected(editedContentItemVariant.assignment)) {
      await dispatch(deps.createNewVersion());
    } else if (isArchivedWorkflowStepSelected(editedContentItemVariant.assignment)) {
      await dispatch(
        deps.updateAssignment({
          actionOrigin: ContentItemEditingEventOrigins.Revisions,
          getUpdatedAssignment: () => createAssignmentWithFirstWorkflowStepFromArchived(state),
          history,
          submittingSection: AssignmentSections.WorkflowStep,
        }),
      );
    }

    // Restore revision
    await deps.contentItemRepository.restoreVariantRevision(
      contentItemId.itemId,
      contentItemId.variantId,
      revisionId,
    );

    dispatch(revisionRestoreFinished(contentItemId.itemId));
    dispatch(deps.trackUserEvent(TrackedEvent.ItemRevisionRestored, userEventData));

    const editingPath = `${buildPath<ContentItemRouteParams<UuidArray>>(
      ContentItemRoute,
      params,
    )}?${RevisionRestoredQuery}`;
    history.push(editingPath);
  };
