import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { BaseColor, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import { IForwardedRefProps, forwardRef } from '../../../../../_shared/utils/forwardedRefProps.tsx';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

interface IAssetFoldersBreadcrumbsTooltipOwnProps {
  readonly onFolderOpen: (folderId: Uuid) => void;
  readonly onFolderReset: () => void;
  readonly openedFolders: Immutable.List<ITaxonomyTerm>;
}

type Props = IAssetFoldersBreadcrumbsTooltipOwnProps & IForwardedRefProps<HTMLDivElement>;

const homeItemTooltip = 'Assets';

const AssetFoldersBreadcrumbsTooltip: React.FC<Props> = ({
  onFolderOpen,
  onFolderReset,
  openedFolders,
}) => {
  const folderNodes = openedFolders.toArray().map((folder, index): JSX.Element => {
    const isLastFolder = index === openedFolders.count() - 1;
    const onClick = () => {
      onFolderOpen(folder.id);
    };

    return (
      <Menu.Item
        key={folder.id}
        label={folder.name}
        id={folder.id}
        onAction={onClick}
        nestedLevel={index + 1}
        menuItemState={isLastFolder ? 'disabled' : 'default'}
        leadingElement={<Icons.FolderInverted color={BaseColor.Yellow30} />}
      />
    );
  });

  return (
    <>
      <Menu.Item
        label={homeItemTooltip}
        id={homeItemTooltip}
        onAction={onFolderReset}
        className={classNames('asset-breadcrumbs__item--is-first')}
        leadingElement={<Icons.Home color={colorTextLowEmphasis} />}
      />
      {folderNodes}
    </>
  );
};

AssetFoldersBreadcrumbsTooltip.displayName = 'AssetFoldersBreadcrumbsTooltip';

const AssetFoldersBreadcrumbsTooltipFRC = forwardRef(AssetFoldersBreadcrumbsTooltip);
export { AssetFoldersBreadcrumbsTooltipFRC as AssetFoldersBreadcrumbsTooltip };
