import React from 'react';
import { useLocation } from 'react-router-dom';
import { UserState } from '../../../data/models/user/CurrentUserInfo.ts';
import { RedirectIf } from '../../components/routing/RedirectIf.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getUrlFactory } from '../../utils/urlFactory.ts';

const getUserIsIncomplete = (s: IStore) => s.data.user.info.state !== UserState.Complete;

export const RedirectIfUserIsIncomplete: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const userIsIncomplete = useSelector(getUserIsIncomplete);
  const { pathname } = useLocation();
  const redirectUrl = `${getUrlFactory().getDraftUiRootUrl()}${pathname}`;
  const welcomePageUrl = getUrlFactory().getWelcomePageUrl(redirectUrl);

  return (
    <RedirectIf doRedirect={userIsIncomplete} redirectTo={welcomePageUrl}>
      {children}
    </RedirectIf>
  );
};

RedirectIfUserIsIncomplete.displayName = 'RedirectIfUserIsIncomplete';
