import { Action } from '../../../../../@types/Action.type.ts';
import { Taxonomy_Group_UsageOfEditedInPublishedItems } from '../../constants/taxonomyActionTypes.ts';

export const editedTaxonomyGroupIsUsedInPublishedItems = (
  state = false,
  action: Action,
): boolean => {
  switch (action.type) {
    case Taxonomy_Group_UsageOfEditedInPublishedItems:
      return action.payload.isUsed;

    default:
      return state;
  }
};
