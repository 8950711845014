import { ContentState } from 'draft-js';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { DraftJsOperationInput, StringOperationInput } from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export enum Tone {
  Professional = 'Professional',
  Persuasive = 'Persuasive',
  Straightforward = 'Straightforward',
  Confident = 'Confident',
  Friendly = 'Friendly',
}

export type ChangeToneParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: 'ChangeTone';
    readonly inputs: {
      readonly content: DraftJsOperationInput;
      readonly tone: StringOperationInput<Tone>;
    };
  };

export const createChangeToneParams = (
  content: ContentState,
  tone: Tone,
  trackingData: ElementOperationTrackingData,
): ChangeToneParams => ({
  actionName: 'ChangeTone',
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(content),
    },
    tone: {
      type: 'string',
      value: tone,
    },
  },
  trackingData,
});

export type ChangeToneResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isChangeToneMessage = (
  message: OperationResponseMessage,
): message is ChangeToneResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
