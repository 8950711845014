import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ApiKeyListing_Route_Left,
  DapiKeyListing_Loading_Finished,
  DapiKeyListing_Loading_Started,
} from '../../constants/apiKeysActionTypes.ts';

export const deliveryKeysLoadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case DapiKeyListing_Loading_Started:
      return LoadingStatus.Loading;
    case DapiKeyListing_Loading_Finished:
      return LoadingStatus.Loaded;
    case ApiKeyListing_Route_Left:
      return LoadingStatus.InitialEmpty;
    default:
      return state;
  }
};
