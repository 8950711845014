import { Button } from '@kontent-ai/component-library/Button';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { ComponentType } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IContentProductionRulesEmptyCanRulesProps {
  readonly isDisabled: boolean;
  readonly onAddRule: () => void;
}

export const ContentProductionRulesEmptyCanRules: ComponentType<
  IContentProductionRulesEmptyCanRulesProps
> = (props) => (
  <div className="capability-rules__pane">
    <p className="capability-rules__title">Users with this role can:</p>
    <Button
      buttonStyle="tertiary"
      onClick={props.onAddRule}
      disabled={props.isDisabled}
      css={`
        max-width: fit-content;
        margin-bottom: ${px(Spacing.XXL)};
      `}
      {...getDataUiActionAttribute(DataUiAction.Add)}
    >
      Add rule
    </Button>
  </div>
);

ContentProductionRulesEmptyCanRules.displayName = 'ContentProductionRulesEmptyCanRules';
