import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

export interface IRoleListingOperationStatus {
  readonly message: string;
  readonly messageStyle: StatusMessageStyle;
  readonly operationType: RoleListingOperationType;
  readonly affectedRole: IRoleWithSettings | null;
}

export enum RoleListingOperationType {
  NoOperation = 'nooperation',
  Deleting = 'deleting',
  Deleted = 'deleted',
  Restoring = 'restoring',
  Restored = 'restored',
}
