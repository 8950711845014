import { memoize } from '@kontent-ai/memoization';
import { DraftCSSProperties, DraftInlineStyle } from 'draft-js';

export const CommentStyleIdPrefix = 'COMMENT-';

export const isCommentIdStyle = (style: string): boolean => style.startsWith(CommentStyleIdPrefix);

export const getCommentSegmentId = (style: string): Uuid =>
  style.substring(CommentStyleIdPrefix.length);

export const getCommentInlineStyle = (segmentId: Uuid): string =>
  `${CommentStyleIdPrefix}${segmentId}`;

export const getCommentClassName = (externalSegmentId: Uuid): string =>
  `rte__comment--${externalSegmentId}`;

export const getCommentStyle = memoize.weak((styles: DraftInlineStyle): DraftInlineStyle => {
  return styles.filter(isCommentIdStyle).toOrderedSet();
});

const EmptyCSSProperties: DraftCSSProperties = {};

export const getInlineStyleWithCommentIds = memoize.weak(
  (style: DraftInlineStyle): DraftCSSProperties => {
    if (style.isEmpty()) {
      return EmptyCSSProperties;
    }

    const externalSegmentIds = getCommentStyle(style).map(getCommentSegmentId);
    if (externalSegmentIds.isEmpty()) {
      return EmptyCSSProperties;
    }

    const className = externalSegmentIds.map(getCommentClassName).join(' ');

    return { className };
  },
);
