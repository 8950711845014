import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_CollapseAllNodes,
  Relations_CollapseNode,
  Relations_ExpandNode_Failed,
  Relations_ExpandNode_Started,
} from '../constants/relationsActionTypes.ts';

const initialState = Immutable.Map<string, IExpandedNodeData>();

export interface IExpandedNodeData {
  readonly itemId: Uuid;
  readonly childrenIds: UuidArray;
}

export function expandedNodesData(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_CollapseAllNodes:
      return initialState;

    case Relations_ExpandNode_Started:
      return state.set(action.payload.nodeId, action.payload.nodeData);

    case Relations_ExpandNode_Failed:
    case Relations_CollapseNode:
      return state.remove(action.payload.nodeId);

    default:
      return state;
  }
}
