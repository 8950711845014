import { Collection } from '@kontent-ai/utils';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { SavingFailedNotificationBar as SavingFailedNotificationBarComponent } from '../../components/contentItemEditorNotifications/SavingFailedNotificationBar.tsx';
import { getEditedContentItemTypeElementData } from '../selectors/getEditedContentItemTypeElementData.ts';

export const ValidationErrorNotificationBar = () => {
  const validationErrors = useSelector((store) => {
    const {
      contentApp: { itemValidationErrors },
    } = store;
    return Collection.getEntries(itemValidationErrors).map(([elementId, errorInfo]) => {
      const elementData = getEditedContentItemTypeElementData(store, elementId);

      return {
        elementId,
        errorInfo,
        elementName: elementData?.name,
      };
    });
  });

  return validationErrors.map((validationError) => {
    return (
      <SavingFailedNotificationBarComponent
        key={validationError.elementId}
        message={`We couldn’t save the item because ${
          validationError.elementName ?? 'an element'
        } contains an error.`}
      />
    );
  });
};
