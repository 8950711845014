import { IEventTrackingListener, eventTrackingService } from '../services/eventTrackingService.ts';
import { getEmailDomain } from './emailUtils.ts';

export type AmplitudeUser = {
  readonly app_id: string;
  readonly business_role?: string;
  readonly business_role_other?: string;
  readonly business_type?: string;
  readonly created_at?: number;
  readonly design_version?: string;
  readonly email?: string;
  readonly highest_plan?: string;
  readonly provided_company_name?: string;
  readonly sign_up_date?: string;
  readonly user_id?: string;
};

const logEvent: IEventTrackingListener = (eventName, metadata): void => {
  const client = getAmplitudeClient();
  if (!self._envConfig.isAmplitudeEnabled || !client) {
    return;
  }

  client.logEvent(eventName, metadata);
};

export const boot = (user: AmplitudeUser): void => {
  const client = getAmplitudeClient();

  if (!self._envConfig.isAmplitudeEnabled || !client) {
    return;
  }

  if (user.user_id) {
    client.setUserId(user.user_id);
  }

  client.setUserProperties({
    app_id: user.app_id,
    business_type: user.business_type,
    business_role: user.business_role,
    business_role_other: user.business_role_other,
    created_at: user.created_at,
    design_version: user.design_version,
    email_domain: getEmailDomain(user.email),
    plan: user.highest_plan,
    provided_company_name: user.provided_company_name,
    sign_up_date: user.sign_up_date,
    user_id: user.user_id,
  });

  eventTrackingService.subscribe(logEvent);
};

export const shutdown = (): void => {
  eventTrackingService.unsubscribe(logEvent);
};

function getAmplitudeClient(): AmplitudeClient | null {
  if (window.amplitude) {
    return window.amplitude.getInstance();
  }

  return null;
}
