import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { NodeId } from '../../types/nodeId.type.ts';
import { ILinkPageItemsAction } from './linkPageItems.ts';
import { INodeWithSubpagesParams } from './reloadWebSpotlightNode.ts';

interface IDeps {
  readonly linkPageItems: ILinkPageItemsAction;
  readonly reloadWebSpotlightNode: (nodeParams: INodeWithSubpagesParams) => ThunkPromise;
}

export type ILinkPageItemsAndReloadAction = (
  itemId: Uuid,
  variantId: Uuid,
  subpagesElementId: Uuid,
  nodeId: NodeId,
  contentItemIds: UuidArray,
) => ThunkPromise;

export const linkPageItemsAndReloadActionCreator =
  (deps: IDeps) =>
  (
    itemId: Uuid,
    variantId: Uuid,
    subpagesElementId: Uuid,
    nodeId: NodeId,
    contentItemIds: UuidArray,
  ): ThunkPromise =>
  async (dispatch: Dispatch) => {
    await dispatch(deps.linkPageItems(itemId, variantId, subpagesElementId, contentItemIds));
    await dispatch(
      deps.reloadWebSpotlightNode({
        itemId,
        newSubpagesIds: contentItemIds,
        nodeId,
      }),
    );
  };
