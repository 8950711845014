import { Box } from '@kontent-ai/component-library/Box';
import { getReadableTextColor } from '@kontent-ai/component-library/utils';
import React from 'react';
import { WorkflowStepColor } from '../../../../../../data/constants/WorkflowStepColor.ts';
import { renderWorkflowStepColor } from '../../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';

interface IStepColorPinProps {
  readonly color: WorkflowStepColor;
  readonly onClick?: () => void;
}

const StepColorPin: React.FC<IStepColorPinProps> = ({ color, onClick }) => {
  const renderedColor = renderWorkflowStepColor(color);

  return (
    <div className="workflow-step__color-label-pane">
      <Box
        className="workflow-step__color-label"
        backgroundColor={renderedColor}
        color={getReadableTextColor(renderedColor)}
        onClick={onClick}
      />
    </div>
  );
};

StepColorPin.displayName = 'StepColorPin';

export { StepColorPin };
