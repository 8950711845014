import { DefaultVariantId } from '../../../_shared/constants/variantIdValues.ts';
import { ElementStatusOption } from '../../../_shared/uiComponents/ElementStatus/ElementStatus.tsx';
import {
  EditableTypeElement,
  TypeElement,
} from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';

export const getElementStatus = (
  showLimitationWarning: boolean | undefined,
  validationPassed: boolean,
): ElementStatusOption =>
  validationPassed
    ? ElementStatusOption.FilledCorrectly
    : showLimitationWarning
      ? ElementStatusOption.FilledIncorrectly
      : ElementStatusOption.Empty;

export const getRelevantEditableElements = (
  contentElements: ReadonlyArray<TypeElement>,
  variantId: Uuid,
): ReadonlyArray<EditableTypeElement> =>
  (variantId === DefaultVariantId
    ? contentElements
    : contentElements.filter((element) => !element.isNonLocalizable)
  ).filter(isEditableElement);

export const isElementEditableInVariant = (
  typeElement: TypeElement | undefined,
  variantId: Uuid | undefined,
): typeElement is EditableTypeElement =>
  isEditableElement(typeElement) &&
  (variantId === DefaultVariantId || !typeElement.isNonLocalizable);
