import { Stack } from '@kontent-ai/component-library/Stack';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { WorkflowName } from '../containers/WorkflowName.tsx';
import { WorkflowLimitations } from '../containers/limitations/WorkflowLimitations.tsx';
import { WorkflowStepList } from './WorkflowStepList.tsx';

interface Props {
  readonly hasUnsavedChanges: boolean;
  readonly isAdvancedEditorEnabled: boolean;
  readonly isBeingSaved: boolean;
  readonly isCentered: boolean;
  readonly onSaveWorkflow: () => void;
  readonly onUnsavedNavigation: (onSuccess: () => void, onFail: () => void) => void;
  readonly pageTitle?: string;
}

export const WorkflowEditor = ({
  hasUnsavedChanges,
  isAdvancedEditorEnabled,
  isBeingSaved,
  isCentered,
  onSaveWorkflow,
  onUnsavedNavigation,
  pageTitle,
}: Props) => {
  useHotkeys({
    [ShortcutsConfig.ControlS]: (event: KeyboardEvent) => {
      event.preventDefault();
      if (!isBeingSaved) {
        onSaveWorkflow();
      }
    },
  });

  return (
    <>
      <HtmlSettingsPageTitle
        settingsAppName={EnvironmentSettingsAppNames.Workflow}
        customName={pageTitle}
      />
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={onUnsavedNavigation}
      />
      <div
        className={classNames(
          'canvas__inner-section',
          'canvas__inner-section--restricted-width',
          'workflow-settings',
          {
            'canvas__inner-section--centered': isCentered,
          },
        )}
      >
        <Stack spacing={Spacing.XL}>
          {isAdvancedEditorEnabled && (
            <>
              <WorkflowName />
              <WorkflowLimitations />
            </>
          )}
          <WorkflowStepList isAdvancedEditorEnabled={isAdvancedEditorEnabled} />
        </Stack>
      </div>
    </>
  );
};
