import { isElementFullyVisible, scrollToView } from '@kontent-ai/DOM';
import { getInteractionModality } from '@react-aria/interactions';
import { Key, RefObject } from 'react';
import { VariableSizeList as VirtualizedList } from 'react-window';
import { VerticalMenuItem } from '../types.ts';
import { VerticalMenuState } from '../useNonAccessibleVerticalMenu.ts';
import { memoizeLastFlattenAndNormalizedItems } from './flattenItems.ts';

const baseScrollItemIntoView = (itemKey: Key) => (fn: (itemKey: Key) => void) => {
  const modality = getInteractionModality();

  // we don't want to scroll on focus triggered by mouse (items are "focused" on mouse hover)
  if (itemKey && modality !== 'pointer') {
    fn(itemKey);
  }
};

export const scrollItemIntoView = <_TItem extends VerticalMenuItem<_TItem>>(
  itemKey: Key,
  menuRef: RefObject<HTMLDivElement>,
) => {
  baseScrollItemIntoView(itemKey)((key) => {
    const element = menuRef.current?.querySelector<HTMLElement>(`[data-key="${key}"]`);
    if (element && !isElementFullyVisible(element)) {
      // behavior 'auto' to make the scrolling instant when holding up or down key
      scrollToView(element, 'nearest', 'auto');
    }
  });
};

export const scrollVirtualizedItemIntoView = <TItem extends VerticalMenuItem<TItem>>(
  collection: VerticalMenuState<TItem>['collection'],
  itemKey: Key,
  virtualizedListRef: RefObject<VirtualizedList<HTMLDivElement>>,
) => {
  const flattenedItems = memoizeLastFlattenAndNormalizedItems([...collection]);

  baseScrollItemIntoView(itemKey)((key) => {
    const scrollToIndex = flattenedItems.findIndex((item) => item.key === key);
    if (scrollToIndex !== undefined) {
      virtualizedListRef.current?.scrollToItem(scrollToIndex);
    }
  });
};
