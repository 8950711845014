import { Action } from '../../../../@types/Action.type.ts';
import {
  AssetLibrary_Asset_ArchiveCompleted,
  AssetLibrary_Asset_ArchiveFailed,
  AssetLibrary_Asset_ArchiveStarted,
  AssetLibrary_Asset_ReplaceFinished,
  AssetLibrary_Assets_ArchiveCompleted,
  AssetLibrary_Assets_ArchiveStarted,
  AssetLibrary_Assets_AssignTermsCompleted,
  AssetLibrary_Assets_AssignTermsStarted,
  AssetLibrary_Assets_MoveBackToCollectionCompleted,
  AssetLibrary_Assets_MoveBackToCollectionStarted,
  AssetLibrary_Assets_MoveBackToFolderCompleted,
  AssetLibrary_Assets_MoveBackToFolderStarted,
  AssetLibrary_Assets_MoveToCollectionCompleted,
  AssetLibrary_Assets_MoveToCollectionStarted,
  AssetLibrary_Assets_MoveToFolderCompleted,
  AssetLibrary_Assets_MoveToFolderStarted,
  AssetLibrary_Assets_RestoreCompleted,
  AssetLibrary_Assets_RestoreStarted,
  AssetLibrary_Assets_ToggleAssetSelection,
  AssetLibrary_Assets_UploadFinished,
  AssetLibrary_Folders_ArchiveCompleted,
  AssetLibrary_Folders_ArchiveStarted,
  AssetLibrary_Folders_MovingBackCompleted,
  AssetLibrary_Folders_MovingBackStarted,
  AssetLibrary_Folders_MovingCompleted,
  AssetLibrary_Folders_MovingStarted,
  AssetLibrary_Folders_Navigating,
  AssetLibrary_Folders_RestoreCompleted,
  AssetLibrary_Folders_RestoreStarted,
  AssetLibrary_Initialized,
  AssetLibrary_Query_Changed,
  AssetLibrary_Query_FilterCleared,
} from '../constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import { StatusMessageAction } from '../stores/IAssetLibraryState.ts';
import { LastAssetsAction } from '../types/lastAssetsAction.ts';

const initialState: LastAssetsAction = {
  type: StatusMessageAction.None,
};

export const lastAction = (state = initialState, action: Action): LastAssetsAction => {
  switch (action.type) {
    case AssetLibrary_Asset_ArchiveFailed:
      return {
        type: StatusMessageAction.ArchivedAssets,
        successfulIds: new Set(),
        failedIds: new Set([action.payload.asset.id]),
      };

    case AssetLibrary_Asset_ArchiveCompleted:
      return {
        type: StatusMessageAction.ArchivedAssets,
        successfulIds: new Set([action.payload.asset.id]),
        failedIds: new Set(),
      };

    case AssetLibrary_Assets_ArchiveCompleted:
      return {
        type: StatusMessageAction.ArchivedAssets,
        successfulIds: action.payload.successfulIds,
        failedIds: action.payload.failedIds,
      };

    case AssetLibrary_Assets_RestoreCompleted:
      return {
        type: StatusMessageAction.RestoredAssets,
        successfulIds: new Set(action.payload.assets.keySeq().toArray()),
      };

    case AssetLibrary_Assets_MoveToFolderCompleted:
      return {
        type: StatusMessageAction.MovedAssetsToFolder,
        successfulIds: new Map(action.payload.successfulIds),
      };

    case AssetLibrary_Assets_MoveBackToFolderCompleted:
      return {
        type: StatusMessageAction.MovedAssetsBackToFolder,
        successfulIds: action.payload.successfulIds,
      };

    case AssetLibrary_Assets_MoveToCollectionCompleted:
      return {
        type: StatusMessageAction.MovedAssetsToCollection,
        successfulIds: action.payload.successfulIds,
      };

    case AssetLibrary_Assets_MoveBackToCollectionCompleted:
      return {
        type: StatusMessageAction.MovedAssetsBackToCollection,
        successfulIds: action.payload.successfulIds,
      };

    case AssetLibrary_Folders_ArchiveCompleted:
      return {
        type: StatusMessageAction.ArchivedFolder,
        failedAssetsIds: action.payload.failedAssetsIds,
        successfulAssetsIds: action.payload.successfulAssetsIds,
        successfulFoldersIds: action.payload.successfulFoldersIds,
        usedAssetsIds: action.payload.usedAssetsIds,
      };

    case AssetLibrary_Folders_RestoreCompleted:
      return {
        type: StatusMessageAction.RestoredFolder,
        successfulAssetsIds: new Set(action.payload.assets.keySeq().toArray()),
        successfulFoldersIds: action.payload.successfulFoldersIds,
      };

    case AssetLibrary_Folders_MovingCompleted:
      return {
        type: StatusMessageAction.MovedFolder,
        originalParentFolderId: action.payload.movedFolderOriginalParentId,
      };

    case AssetLibrary_Assets_AssignTermsCompleted:
      return {
        type: StatusMessageAction.AssignedTerms,
        successfulIds: action.payload.successfulIds,
        failedIds: action.payload.failedIds,
      };

    case AssetLibrary_Assets_UploadFinished:
    case AssetLibrary_Asset_ReplaceFinished:
    case AssetLibrary_Folders_Navigating:
    case AssetLibrary_Initialized:
    case AssetLibrary_Query_Changed:
    case AssetLibrary_Query_FilterCleared:
    case AssetSelector_Opened:
      return initialState;

    case AssetLibrary_Asset_ArchiveStarted:
    case AssetLibrary_Assets_ArchiveStarted:
      return { type: StatusMessageAction.Archiving };

    case AssetLibrary_Assets_MoveToCollectionStarted:
    case AssetLibrary_Assets_MoveToFolderStarted:
      return { type: StatusMessageAction.Moving };

    case AssetLibrary_Folders_MovingStarted:
      return { type: StatusMessageAction.MovingFolder };

    case AssetLibrary_Assets_RestoreStarted:
      return { type: StatusMessageAction.Restoring };

    case AssetLibrary_Folders_RestoreStarted:
      return { type: StatusMessageAction.RestoringFolder };

    case AssetLibrary_Assets_MoveBackToFolderStarted:
      return { type: StatusMessageAction.MovingAssetsBackToFolder };

    case AssetLibrary_Folders_MovingBackStarted:
      return { type: StatusMessageAction.MovingFolderBack };

    case AssetLibrary_Folders_MovingBackCompleted:
      return { type: StatusMessageAction.MovedFolderBack };

    case AssetLibrary_Assets_ToggleAssetSelection:
      return { type: StatusMessageAction.Selected };

    case AssetLibrary_Folders_ArchiveStarted:
      return { type: StatusMessageAction.ArchivingFolder };

    case AssetLibrary_Assets_AssignTermsStarted:
      return { type: StatusMessageAction.AssigningTerms };

    case AssetLibrary_Assets_MoveBackToCollectionStarted:
      return { type: StatusMessageAction.MovingAssetsBackToCollection };

    default:
      return state;
  }
};
