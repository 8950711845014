import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getSelectedSubscriptionIdOrThrow } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { SubscriptionUsers_UsersUsage_Loaded } from '../../constants/actionTypes.ts';
import {
  ISubscriptionUsersUsage,
  getSubscriptionUsersUsageFromServerModel,
} from '../../models/SubscriptionUsersUsage.ts';

interface IDeps {
  readonly subscriptionRepository: ISubscriptionRepository;
}

const subscriptionUsersUsageLoaded = (data: ISubscriptionUsersUsage) =>
  ({
    type: SubscriptionUsers_UsersUsage_Loaded,
    payload: { data },
  }) as const;

export type LoadSubscriptionUsersUsageActionsType = ReturnType<typeof subscriptionUsersUsageLoaded>;

export const createLoadSubscriptionUsersUsageAction =
  ({ subscriptionRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const subscriptionId = getSelectedSubscriptionIdOrThrow(getState());

    const subscriptionsUsersUsageServerModel =
      await subscriptionRepository.getSubscriptionUsersUsage(subscriptionId, abortSignal);
    const subscriptionsUsersUsage = getSubscriptionUsersUsageFromServerModel(
      subscriptionsUsersUsageServerModel,
    );

    dispatch(subscriptionUsersUsageLoaded(subscriptionsUsersUsage));
  };
