import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../../@types/Dispatcher.type.ts';
import { activateContentItemEditingAction } from '../../../actions/contentItemEditingActions.ts';
import {
  ActionsMenuSubMenu as ActionMenuSubMenuComponent,
  ActionsMenuSubMenuDispatchProps,
  ActionsMenuSubMenuOwnProps,
} from '../../../components/editingActions/actionsMenu/ActionsMenuSubMenu.tsx';
import { EditingAction } from '../../../models/EditingAction.ts';

const mapDispatchToProps = (dispatch: Dispatch): ActionsMenuSubMenuDispatchProps => ({
  onNavigateBack: () => dispatch(activateContentItemEditingAction(EditingAction.showMainMenu)),
});

export const ActionsMenuSubMenu: React.ComponentType<
  React.PropsWithChildren<ActionsMenuSubMenuOwnProps>
> = connect(undefined, mapDispatchToProps)(ActionMenuSubMenuComponent);
