import { ICustomTypeElement } from '../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import { validElementWarningResult } from '../../constants/validElementWarningResults.ts';
import { IsRequiredWarning } from '../../constants/warningMessageTemplates.ts';
import { ICustomItemElement } from '../../models/contentItemElements/CustomItemElement.ts';
import { ItemElementWarningResult, emptyItemElementWarningResult } from './types/Warnings.ts';

const validateRequiredLimit = (
  typeElement: ICustomTypeElement,
  itemElement: ICustomItemElement,
): boolean => {
  return typeElement.isRequired ? itemElement.value !== null : true;
};

interface IParams {
  readonly typeElement: ICustomTypeElement;
  readonly itemElement: ICustomItemElement;
}

const invalidElementResult: ItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  requiredMessage: IsRequiredWarning,
};

export const getCustomItemElementValidationWarning = ({
  typeElement,
  itemElement,
}: IParams): ItemElementWarningResult => {
  const isRequiredLimitMet = validateRequiredLimit(typeElement, itemElement);

  return isRequiredLimitMet ? validElementWarningResult : invalidElementResult;
};
