import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { InvariantException } from '@kontent-ai/errors';
import {
  IArchivedWorkflowStepServerModel,
  IPublishedWorkflowStepServerModel,
  IRegularWorkflowStepServerModel,
  IScheduledWorkflowStepServerModel,
  IVariantWorkflowStepServerModel,
  WorkflowActionServerModel,
} from '../../../repositories/serverModels/WorkflowServerModel.type.ts';
import { WorkflowStepColor } from '../../constants/WorkflowStepColor.ts';
import { getWorkflowStepColorFromServerString } from './utils/getWorkflowStepColorFromServerString.ts';

export const ArchivedWorkflowStepId = '7a535a69-ad34-47f8-806a-def1fdf4d391';
export const PublishedWorkflowStepId = 'c199950d-99f0-4983-b711-6c4c91624b22';
export const ScheduledWorkflowStepId = '9d2b0228-4d0d-4c23-8b49-01a698857709';

export enum WorkflowStepAction {
  NoAction = 'noAction',
  Publish = 'publish',
  ScheduleToPublish = 'scheduleToPublish',
}

export interface IWorkflowStep {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string;
  readonly color: WorkflowStepColor;
  readonly action: WorkflowStepAction;
  readonly transitionsTo: ReadonlySet<Uuid>;
}

export type WorkflowStepOption = IWorkflowStep & ISelectItem<WorkflowStepOption>;

export interface IAssignmentWorkflowStep extends IWorkflowStep {
  readonly workflowId: Uuid;
}

export const createAssignmentWorkflowStep = (
  workflowStep: IWorkflowStep,
  workflowId: Uuid,
): IAssignmentWorkflowStep => ({
  ...workflowStep,
  workflowId,
});

export const mapToAssignmentWorkflowStep = (
  variantWorkflowStep: IVariantWorkflowStepServerModel,
): IAssignmentWorkflowStep => ({
  id: getStepIdFromServerModel(variantWorkflowStep),
  workflowId: getWorkflowIdFromServerModel(variantWorkflowStep),
  name: getStepNameFromServerModel(variantWorkflowStep),
  codename: getStepCodenameFromServerModel(variantWorkflowStep),
  color: getWorkflowStepColorFromServerString(variantWorkflowStep.color),
  action: parseActionFromServerModel(variantWorkflowStep),
  transitionsTo: new Set(variantWorkflowStep.transitionsTo),
});

export type RegularWorkflowStep = IWorkflowStep & {
  readonly action: WorkflowStepAction.NoAction;
  readonly roleIds: ReadonlySet<Uuid>;
};

export type ScheduledStep = IWorkflowStep & {
  readonly action: WorkflowStepAction.ScheduleToPublish;
  readonly roleIds: ReadonlySet<Uuid>;
};

export type PublishedStep = IWorkflowStep & {
  readonly action: WorkflowStepAction.Publish;
  readonly roleIdsForCreateNew: ReadonlySet<Uuid>;
  readonly roleIdsForUnpublish: ReadonlySet<Uuid>;
};

export type ArchivedStep = IWorkflowStep & {
  readonly roleIds: ReadonlySet<Uuid>;
};

export const emptyWorkflowStep: RegularWorkflowStep = {
  id: '',
  name: '',
  codename: '',
  color: WorkflowStepColor.Gray,
  action: WorkflowStepAction.NoAction,
  transitionsTo: new Set(),
  roleIds: new Set(),
};

export const emptyAssignmentWorkflowStep = createAssignmentWorkflowStep(emptyWorkflowStep, '');

function getStepIdFromServerModel(serverModel: { readonly _id: string }): string {
  if (!serverModel._id) {
    throw InvariantException('Workflow step server model: _id is falsy');
  }

  return serverModel._id;
}

function getWorkflowIdFromServerModel(serverModel: { readonly workflowId: string }): string {
  if (!serverModel.workflowId) {
    throw InvariantException('Workflow step server model: workflowId is falsy');
  }

  return serverModel.workflowId;
}

function getStepNameFromServerModel(serverModel: { readonly name: string }): string {
  return serverModel.name;
}

function getStepCodenameFromServerModel(serverModel: { readonly codeName: string }): string {
  return serverModel.codeName;
}

function parseActionFromServerModel(serverModel: {
  readonly action: WorkflowActionServerModel;
}): WorkflowStepAction {
  switch (serverModel.action) {
    case 'publish':
      return WorkflowStepAction.Publish;
    case 'scheduleToPublish':
      return WorkflowStepAction.ScheduleToPublish;
    default:
      return WorkflowStepAction.NoAction;
  }
}

export function getWorkflowStepFromServerModel(
  serverModel: IRegularWorkflowStepServerModel,
): RegularWorkflowStep {
  return {
    id: getStepIdFromServerModel(serverModel),
    name: getStepNameFromServerModel(serverModel),
    codename: getStepCodenameFromServerModel(serverModel),
    color: getWorkflowStepColorFromServerString(serverModel.color),
    action: WorkflowStepAction.NoAction,
    transitionsTo: new Set(serverModel.transitionsTo),
    roleIds: new Set(serverModel.roles),
  };
}

export function getPublishedWorkflowStepFromServerModel(
  serverModel: IPublishedWorkflowStepServerModel,
): PublishedStep {
  return {
    id: getStepIdFromServerModel(serverModel),
    name: getStepNameFromServerModel(serverModel),
    codename: getStepCodenameFromServerModel(serverModel),
    color: getWorkflowStepColorFromServerString(serverModel.color),
    action: WorkflowStepAction.Publish,
    transitionsTo: new Set(serverModel.transitionsTo),
    roleIdsForUnpublish: new Set(serverModel.unpublishRoleIds),
    roleIdsForCreateNew: new Set(serverModel.createNewVersionRoleIds),
  };
}

export function getScheduledWorkflowStepFromServerModel(
  serverModel: IScheduledWorkflowStepServerModel,
): ScheduledStep {
  return {
    id: getStepIdFromServerModel(serverModel),
    name: getStepNameFromServerModel(serverModel),
    codename: getStepCodenameFromServerModel(serverModel),
    color: getWorkflowStepColorFromServerString(serverModel.color),
    action: WorkflowStepAction.ScheduleToPublish,
    transitionsTo: new Set(serverModel.transitionsTo),
    roleIds: new Set(serverModel.roles),
  };
}

export function getArchivedWorkflowStepFromServerModel(
  serverModel: IArchivedWorkflowStepServerModel,
): ArchivedStep {
  return {
    id: getStepIdFromServerModel(serverModel),
    name: getStepNameFromServerModel(serverModel),
    codename: getStepCodenameFromServerModel(serverModel),
    color: getWorkflowStepColorFromServerString(serverModel.color),
    action: WorkflowStepAction.NoAction,
    transitionsTo: new Set(serverModel.transitionsTo),
    roleIds: new Set(serverModel.roles),
  };
}

export const mapWorkflowStepToOption = (step: IWorkflowStep): WorkflowStepOption => {
  return {
    ...step,
    label: step.name,
  };
};
