import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { sortAndOrderAssetUsages } from '../../../../../_shared/utils/assets/assetUtils.ts';
import { UsageItemReference } from '../../../../../_shared/utils/usage/usageUtils.ts';
import {
  AssetUsage as AssetUsageComponent,
  IAssetUsageOwnProps,
  IAssetUsageStateProps,
} from '../../../../contentInventory/assets/components/AssetEditing/AssetUsage.tsx';

interface IContainerOwnProps extends IAssetUsageOwnProps {
  readonly assetId: Uuid;
}

type ContainerProps = IContainerOwnProps;

const mapStateToProps = (state: IStore, ownProps: ContainerProps): IAssetUsageStateProps => {
  const {
    assetLibraryApp: { usage },
    sharedApp: { currentProjectId },
    data: { languages },
  } = state;

  const { assetId } = ownProps;
  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  const assetUsage = usage.get(assetId);

  // When asset dialog is opened from content item, the remembered id is the id of the asset tile
  // This causes navigation back to content item scroll to the asset tile as the dialog is not displayed in that case
  const scrollId = CreateAutoScrollId.forAssetTile(assetId);
  const assetUsageForCurrentLanguage = (assetUsage || Immutable.Set<UsageItemReference>())
    .filter(
      (usageReference: UsageItemReference) =>
        usageReference.primary.id.variantId === selectedLanguageId,
    )
    .toSet();

  const sortedAndOrderedAssetUsage = sortAndOrderAssetUsages(
    assetUsageForCurrentLanguage,
    languages.byId,
  );

  return {
    assetId,
    languages: languages.byId,
    defaultLanguage: languages.defaultLanguage,
    projectId: currentProjectId,
    scrollId,
    sortedLanguageUsage: sortedAndOrderedAssetUsage,
    isUsageLoaded: !!assetUsage,
  };
};

export const AssetDetailUsage: React.ComponentType<React.PropsWithChildren<ContainerProps>> =
  connect(mapStateToProps)(AssetUsageComponent);
