import React from 'react';
import { DebouncedFunction, debounce } from '../../../../_shared/utils/func/debounce.ts';

const NotifyDebounce = 200;
const MainButton = 0;

export type IMouseMainButtonStatusChangeNotificationPayload =
  | { isMouseDown: true; target: EventTarget | null }
  | { isMouseDown: false };

const MouseUpNotification: IMouseMainButtonStatusChangeNotificationPayload = { isMouseDown: false };

export type IMouseMainButtonStatusChangeNotification = (
  props: IMouseMainButtonStatusChangeNotificationPayload,
) => void;

// MouseMainButtonStatusProvider is a singleton to reduce the overhead of event handling and debounce
class MouseMainButtonStatusObserver {
  private readonly _changedCallbacks: Array<IMouseMainButtonStatusChangeNotification> = [];
  private readonly _debouncedNotifyStatusChanged: DebouncedFunction;
  private _target: EventTarget | null = null;
  private _isMouseDown = false;

  constructor() {
    this._debouncedNotifyStatusChanged = debounce(this._notifyStatusChanged, NotifyDebounce);

    // Listening from the very beginning so that newly mounted components get proper initial status notification
    this._startObserving();
  }

  private readonly _startObserving = (): void => {
    // We are using capture events so that the observer is not influenced by a possible stopPropagation from an element
    document.addEventListener('mousedown', this._mouseDown, true);
    document.addEventListener('mouseup', this._mouseUp, true);
  };

  private readonly _statusChanged = (): void => {
    this._debouncedNotifyStatusChanged();
  };

  private readonly _getSelectionNotification = () => ({
    target: this._target,
    isMouseDown: this._isMouseDown,
  });

  private readonly _notifyStatusChanged = (): void => {
    this._changedCallbacks.forEach((callback) => {
      if (this._isMouseDown) {
        callback(this._getSelectionNotification());
      } else {
        callback(MouseUpNotification);
      }
    });
  };

  private readonly _mouseDown = (e: MouseEvent): void => {
    if (e.button !== MainButton) {
      return;
    }
    this._target = e.target;
    this._isMouseDown = true;
    this._statusChanged();
  };

  private readonly _mouseUp = (e: MouseEvent): void => {
    if (e.button !== MainButton) {
      return;
    }
    this._target = e.target;
    this._isMouseDown = false;
    this._statusChanged();
  };

  public subscribe = (changedCallback: IMouseMainButtonStatusChangeNotification): void => {
    this._changedCallbacks.push(changedCallback);

    // If currently selecting, let the wrapped component know ASAP to catch up with the fact
    if (this._isMouseDown) {
      changedCallback(this._getSelectionNotification());
    }
  };

  public unsubscribe = (changedCallback: IMouseMainButtonStatusChangeNotification): void => {
    const changedCallbackIndex = this._changedCallbacks.indexOf(changedCallback);
    if (changedCallbackIndex >= 0) {
      this._changedCallbacks.splice(changedCallbackIndex, 1);
    }
  };
}

const observer = new MouseMainButtonStatusObserver();

interface IMouseMainButtonStatusProxyProps {
  readonly statusChanged: IMouseMainButtonStatusChangeNotification;
}

class MouseMainButtonStatusProxy extends React.PureComponent<IMouseMainButtonStatusProxyProps> {
  static displayName = 'MouseMainButtonStatusProxy';

  _statusChanged = (props: IMouseMainButtonStatusChangeNotificationPayload) => {
    if (this.props.statusChanged) {
      this.props.statusChanged(props);
    }
  };

  componentDidMount(): void {
    observer.subscribe(this._statusChanged);
  }

  componentWillUnmount(): void {
    observer.unsubscribe(this._statusChanged);
  }

  render() {
    return null;
  }
}

export { MouseMainButtonStatusProxy as MouseMainButtonStatusObserver };
