import {
  ScrollElementsToViewOptions,
  isElement,
  placeFocusToElement,
  scrollElementsToView,
} from '@kontent-ai/DOM';
import React, { RefObject, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { forgetScrollId } from '../actions/sharedActions.ts';
import {
  areAutoScrollIdsEqual,
  isAutoScrollWithInternalTarget,
} from '../components/AutoScroll/AutoScrollId.ts';
import { ScrollOptionsContext } from '../components/AutoScroll/ScrollOptionsContext.tsx';
import { AutoScrollProps } from '../components/AutoScroll/autoScrollProps.type.ts';
import { waitUntilFocusAndScrollAreNotDeferred } from '../utils/autoScrollUtils.ts';
import { useDispatch } from './useDispatch.ts';
import { useSelector } from './useSelector.ts';

export type ScrollExecutedCallback = () => void;
type CleanupCallback = () => void;
export type CustomOnScrollCallback = (
  targetId: string,
  scrollOptions: ScrollElementsToViewOptions,
  onScrollExecuted: ScrollExecutedCallback,
) => CleanupCallback;

export type UseAutoScrollProps<TElement extends HTMLElement> = AutoScrollProps & {
  readonly scrollTargetRef: RefObject<TElement>;
  readonly customOnScroll?: CustomOnScrollCallback;
};

// Default automatic scrolling offset in order to prevent the scrolled item being pushed to the very edge of the view
export const DefaultScrollOffset = 8;

function scrollToElement<TElement extends HTMLElement>(
  { current: scrollTargetElement }: React.RefObject<TElement>,
  options: ScrollElementsToViewOptions | undefined,
  onScrollExecuted: ScrollExecutedCallback,
): void {
  if (isElement(scrollTargetElement)) {
    scrollElementsToView([scrollTargetElement], {
      offset: DefaultScrollOffset,
      ...options,
    });

    placeFocusToElement(scrollTargetElement, { preventScroll: true });
  }

  onScrollExecuted();
}

export function useAutoScroll<TElement extends HTMLElement>({
  alternativeScrollIds,
  customOnScroll,
  scrollTargetRef,
  scrollId,
  scrollOptions,
}: UseAutoScrollProps<TElement>): void {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const wantedScrollId = useSelector((state) => state.sharedApp.scrollIdByPath.get(pathname));
  const { scrollOptions: contextScrollOptions } = useContext(ScrollOptionsContext);

  useEffect(() => {
    const isScrollIdRequested = !!scrollId && areAutoScrollIdsEqual(wantedScrollId, scrollId);
    const isAlternativeScrollIdRequested = alternativeScrollIds?.some((id) =>
      areAutoScrollIdsEqual(id, wantedScrollId),
    );

    if (isScrollIdRequested || isAlternativeScrollIdRequested) {
      const useScrollOptions = {
        ...contextScrollOptions,
        ...scrollOptions,
      };

      // Forget the scroll location pathname automatically so that other scroll components do not react to the same request.
      const onScrollExecuted = () => dispatch(forgetScrollId(pathname));

      const customTargetId =
        !!wantedScrollId && isAutoScrollWithInternalTarget(wantedScrollId)
          ? wantedScrollId.internalTargetId
          : null;

      if (customOnScroll && customTargetId) {
        return customOnScroll(customTargetId, useScrollOptions, onScrollExecuted);
      }

      const executeScroll = waitUntilFocusAndScrollAreNotDeferred(scrollToElement);
      executeScroll(scrollTargetRef, useScrollOptions, onScrollExecuted);
      return executeScroll.cancel;
    }

    return;
  }, [
    alternativeScrollIds,
    contextScrollOptions,
    customOnScroll,
    pathname,
    scrollId,
    scrollOptions,
    scrollTargetRef,
    wantedScrollId,
  ]);
}
