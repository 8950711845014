import { isDefinedEntry } from '@kontent-ai/utils';

type KnownCustomHeaders = 'Content-type' | 'ClientVersion' | 'Authorization' | 'X-AppInstanceId';
export type CustomHeaders = Partial<ReadonlyRecord<KnownCustomHeaders, string>>;

export type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

type ProgressCallbackEvent = {
  loaded: number;
  total: number;
};

export type ProgressCallback = (event: ProgressCallbackEvent) => void;

export type AjaxOptions = {
  readonly customHeaders?: CustomHeaders;
  readonly abortSignal?: AbortSignal;
};

export interface ICreateAjax {
  readonly request: (
    type: RequestType,
    url: string,
    data: any,
    options?: AjaxOptions,
  ) => Promise<XMLHttpRequest>;
  readonly requestFile: (
    type: RequestType,
    url: string,
    data: any,
    options?: AjaxOptions,
  ) => Promise<XMLHttpRequest>;
  readonly upload: (
    url: string,
    formData: FormData,
    uploadProgressCallback: ProgressCallback,
    options?: AjaxOptions,
  ) => Promise<XMLHttpRequest>;
}

const executeNewRequest = (
  createPromise: (request: XMLHttpRequest) => Promise<XMLHttpRequest>,
  abortSignal: AbortSignal | undefined,
): Promise<XMLHttpRequest> => {
  const request = getNewEmptyRequest();
  const abort = () => {
    request.abort();
  };

  const requestPromise = createPromise(request);
  if (abortSignal?.aborted) {
    abort();
  } else {
    abortSignal?.addEventListener('abort', abort);
    requestPromise.finally(() => abortSignal?.removeEventListener('abort', abort));
  }

  return requestPromise;
};

function createAjax(withCredentials: boolean): ICreateAjax {
  return {
    request(
      type: RequestType,
      url: string,
      data: any,
      options?: AjaxOptions,
    ): Promise<XMLHttpRequest> {
      return executeNewRequest(
        (request) =>
          new Promise<XMLHttpRequest>((resolve, reject) => {
            try {
              request.open(type, url, true);
              request.withCredentials = withCredentials;

              addCustomHeaders(request, options?.customHeaders);

              request.onreadystatechange = () => {
                if (request.readyState === 4) {
                  resolve(request);
                }
              };

              request.send(data);
            } catch (e) {
              reject(e);
            }
          }),
        options?.abortSignal,
      );
    },

    requestFile(
      type: RequestType,
      url: string,
      data: any,
      options?: AjaxOptions,
    ): Promise<XMLHttpRequest> {
      return executeNewRequest(
        (request) =>
          new Promise<XMLHttpRequest>((resolve) => {
            request.open(type, url, true);
            request.responseType = 'blob';
            request.withCredentials = withCredentials;

            addCustomHeaders(request, options?.customHeaders);

            request.onreadystatechange = () => {
              if (request.readyState === 4) {
                resolve(request);
              }
            };

            request.send(data);
          }),
        options?.abortSignal,
      );
    },

    upload(
      url: string,
      formData: FormData,
      uploadProgressCallback: ProgressCallback,
      options?: AjaxOptions,
    ): Promise<XMLHttpRequest> {
      return executeNewRequest(
        (request) =>
          new Promise((resolve) => {
            request.open('POST', url, true);

            addCustomHeaders(request, options?.customHeaders);

            request.onreadystatechange = () => {
              if (request.readyState === 4) {
                resolve(request);
              }
            };

            request.upload.addEventListener('progress', uploadProgressCallback);
            request.send(formData);
          }),
        options?.abortSignal,
      );
    },
  };
}

function addCustomHeaders(request: XMLHttpRequest, customHeaders?: CustomHeaders) {
  if (customHeaders) {
    Object.entries(customHeaders)
      .filter(isDefinedEntry)
      .forEach(([key, value]) => request.setRequestHeader(key, value));
  }
}

function getNewEmptyRequest(): XMLHttpRequest {
  if (XMLHttpRequest) {
    return new XMLHttpRequest();
  }
  // Set ajax to correct XHR type. Source: https://gist.github.com/jed/993585
  return new ActiveXObject('MSXML2.XMLHTTP.3.0');
}

export function createAjaxWithCredentials(): ICreateAjax {
  return createAjax(true);
}

export function createAjaxWithoutCredentials(): ICreateAjax {
  return createAjax(false);
}
