import { UnreachableCaseException } from '@kontent-ai/errors';
import { Property } from 'csstype';
import { InputState } from '../inputStateEnum.ts';
import { InputStyleProps } from './inputStyleProps.type.ts';

export const getInputCursor = ({ $inputState }: InputStyleProps): Property.Cursor => {
  switch ($inputState) {
    case InputState.Disabled:
      return 'not-allowed';
    case InputState.Alert:
    case InputState.Default:
    case InputState.ReadOnly:
      return 'text';
    default:
      throw UnreachableCaseException($inputState);
  }
};
