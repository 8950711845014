import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { Subscription_SelectSubscription_Clear } from '../../../shared/constants/subscriptionManagementActionTypes.ts';
import {
  SubscriptionUsers_AllFilters_Cleared,
  SubscriptionUsers_CollectionFilter_Removed,
  SubscriptionUsers_CollectionFilter_Updated,
  SubscriptionUsers_EnvironmentFilter_Updated,
  SubscriptionUsers_Filter_Initialized,
  SubscriptionUsers_LanguageFilter_Removed,
  SubscriptionUsers_LanguageFilter_Updated,
  SubscriptionUsers_NameFilter_Updated,
  SubscriptionUsers_ProjectFilter_Updated,
  SubscriptionUsers_RoleFilter_Removed,
  SubscriptionUsers_RoleFilter_Updated,
  SubscriptionUsers_StatusFilter_Updated,
} from '../../constants/actionTypes.ts';
import {
  ISubscriptionUsersListingFilter,
  emptySubscriptionListingUserFilter,
} from '../../models/SubscriptionUserListingFilter.ts';

export const filter = (
  state: ISubscriptionUsersListingFilter = emptySubscriptionListingUserFilter,
  action: Action,
): ISubscriptionUsersListingFilter => {
  switch (action.type) {
    case SubscriptionUsers_NameFilter_Updated:
      return {
        ...state,
        byName: action.payload.name,
      };

    case SubscriptionUsers_StatusFilter_Updated:
      return {
        ...state,
        byStatus: action.payload.status,
      };

    case SubscriptionUsers_ProjectFilter_Updated:
      return {
        ...state,
        byProject: action.payload.projectId,
        byEnvironment: emptySubscriptionListingUserFilter.byEnvironment,
        byRole: emptySubscriptionListingUserFilter.byRole,
        byCollection: emptySubscriptionListingUserFilter.byCollection,
        byLanguage: emptySubscriptionListingUserFilter.byLanguage,
      };

    case SubscriptionUsers_EnvironmentFilter_Updated:
      return {
        ...state,
        byEnvironment: action.payload.environmentId,
        byRole: emptySubscriptionListingUserFilter.byRole,
        byCollection: emptySubscriptionListingUserFilter.byCollection,
        byLanguage: emptySubscriptionListingUserFilter.byLanguage,
      };

    case SubscriptionUsers_RoleFilter_Updated:
      return {
        ...state,
        byRole: action.payload.roleIds,
      };

    case SubscriptionUsers_RoleFilter_Removed:
      return {
        ...state,
        byRole: Collection.remove(state.byRole, action.payload.roleId),
      };

    case SubscriptionUsers_CollectionFilter_Updated:
      return {
        ...state,
        byCollection: action.payload.collectionIds,
      };

    case SubscriptionUsers_CollectionFilter_Removed:
      return {
        ...state,
        byCollection: Collection.remove(state.byCollection, action.payload.collectionId),
      };

    case SubscriptionUsers_LanguageFilter_Updated:
      return {
        ...state,
        byLanguage: action.payload.languageIds,
      };

    case SubscriptionUsers_LanguageFilter_Removed:
      return {
        ...state,
        byLanguage: Collection.remove(state.byLanguage, action.payload.languageId),
      };

    case SubscriptionUsers_Filter_Initialized:
      return {
        ...action.payload.filter,
      };

    case SubscriptionUsers_AllFilters_Cleared:
    case Subscription_SelectSubscription_Clear:
      return emptySubscriptionListingUserFilter;

    default:
      return state;
  }
};
