import { Loader } from '../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { initEntityWebhookEditor } from '../actions/thunkEntityWebhookActions.ts';
import { EntityWebhookEditorForm } from './EntityWebhookEditorForm.tsx';

type EntityWebhookEditorProps = {
  readonly editedEntityWebhookId: Uuid;
};

export const EntityWebhookEditor = ({ editedEntityWebhookId }: EntityWebhookEditorProps) => {
  const [isInitThunkDone] = useThunkPromise(initEntityWebhookEditor, editedEntityWebhookId);

  const entityWebhookSetting = useSelector((s) =>
    s.data.entityWebhooks.byId.get(editedEntityWebhookId),
  );

  return isInitThunkDone && entityWebhookSetting ? (
    <EntityWebhookEditorForm entityWebhookSetting={entityWebhookSetting} />
  ) : (
    <Loader />
  );
};
