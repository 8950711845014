import React from 'react';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { ScrollTableHeadColumn } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadColumnClickable } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumnClickable.tsx';
import { ScrollTableHeadRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionUserListingColumnCode } from '../reducers/subscriptionUsersListingUi/sortBy.ts';

interface ISubscriptionUsersScrollTableHeadProps {
  readonly usersListingOrderBy: OrderBy<SubscriptionUserListingColumnCode> | null;
  readonly onColumnHeadClicked: (columnCode: SubscriptionUserListingColumnCode) => void;
}

export const SubscriptionUsersScrollTableHead: React.FC<ISubscriptionUsersScrollTableHeadProps> = ({
  usersListingOrderBy,
  onColumnHeadClicked,
}) => (
  <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
    <ScrollTableHeadColumnClickable
      name="Name"
      size={10}
      code={SubscriptionUserListingColumnCode.Name}
      currentOrdering={usersListingOrderBy}
      onColumnHeadClicked={onColumnHeadClicked}
    />
    <ScrollTableHeadColumn name="Project" size={10} />
    <ScrollTableHeadColumn name="Environment" size={10} />
    <ScrollTableHeadColumn name="Role" size={10} />
    <ScrollTableHeadColumnClickable
      name="Last activity"
      code={SubscriptionUserListingColumnCode.LastLogin}
      currentOrdering={usersListingOrderBy}
      onColumnHeadClicked={onColumnHeadClicked}
      size={5}
    />
    <ScrollTableHeadColumnClickable
      name="Subscription status"
      code={SubscriptionUserListingColumnCode.Status}
      currentOrdering={usersListingOrderBy}
      onColumnHeadClicked={onColumnHeadClicked}
      size={5}
    />
  </ScrollTableHeadRow>
);

SubscriptionUsersScrollTableHead.displayName = 'SubscriptionUsersScrollTableHead';
