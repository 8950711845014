import { Collection } from '@kontent-ai/utils';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';

export const getAvailableCollectionsForContentComponent = (
  state: IStore,
): ReadonlyArray<ICollection> =>
  Collection.getValues(state.data.collections.byId).filter(
    (collection) => collection.id === state.contentApp.editedContentItem?.collectionId,
  );
