import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ISitemapRepository } from '../../../../repositories/interfaces/ISitemapRepository.type.ts';
import { ISitemapServerModel } from '../../../../repositories/serverModels/contentModels/SitemapServerModel.type.ts';
import {
  Data_Sitemap_Failed,
  Data_Sitemap_Started,
  Data_Sitemap_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly sitemapRepository: Pick<ISitemapRepository, 'getSitemap'>;
}

const start = () => ({ type: Data_Sitemap_Started }) as const;
const success = (data: ISitemapServerModel) =>
  ({
    type: Data_Sitemap_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_Sitemap_Failed }) as const;

export type LoadSitemapActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadSitemapAction =
  ({ sitemapRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const sitemapServerModel = await sitemapRepository.getSitemap(abortSignal);
      dispatch(success(sitemapServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
