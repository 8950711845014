import { assetTileBorderRadius } from '../../../../client/component-library/components/AssetTile/decisionTokens.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { px } from '../../tokens/utils/utils.ts';

export const skeletonTypes = ['circle', 'rectangle', 'text'] as const;
export type SkeletonType = (typeof skeletonTypes)[number];

const SkeletonBorderRadius = {
  Default: px(BorderRadius.L),
  Pill: px(BorderRadius.Pill),
} as const;

export type CustomSkeletonBorderRadius = typeof assetTileBorderRadius;
export type SkeletonBorderRadius =
  | CustomSkeletonBorderRadius
  | (typeof SkeletonBorderRadius)[keyof typeof SkeletonBorderRadius];

export const SkeletonTypeToSkeletonBorderRadiusMap: Record<SkeletonType, SkeletonBorderRadius> = {
  rectangle: SkeletonBorderRadius.Default,
  circle: SkeletonBorderRadius.Pill,
  text: SkeletonBorderRadius.Default,
};
