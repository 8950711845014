import { Action } from '../../../../../@types/Action.type.ts';
import {
  IContentType,
  emptyContentType,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { AssetType_Editor_SavingFinished } from '../../../assetTypes/constants/assetTypeActionTypes.ts';
import {
  ContentModels_TypeEditor_AssetTypeLoaded,
  ContentModels_TypeEditor_TypeLoaded,
} from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Creator_InitStarted,
  ContentType_Editor_DuplicatingFinished,
  ContentType_Editor_SavingFinished,
} from '../../constants/contentTypesActionTypes.ts';

const defaultState = emptyContentType;

export function originalContentType(
  state: IContentType = defaultState,
  action: Action,
): IContentType {
  switch (action.type) {
    case ContentType_Creator_CreationFinished:
    case ContentModels_TypeEditor_AssetTypeLoaded:
    case ContentModels_TypeEditor_TypeLoaded: {
      return action.payload.editedContentType;
    }

    case ContentType_Editor_DuplicatingFinished:
    case ContentType_Editor_SavingFinished:
    case AssetType_Editor_SavingFinished: {
      return action.payload.contentType;
    }

    case ContentType_Creator_InitStarted: {
      return defaultState;
    }

    default:
      return state;
  }
}
