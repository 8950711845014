import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isMultipleChoiceElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { onMultipleChoiceOptionsChange } from '../../actions/thunkContentItemEditingActions.ts';
import {
  MultipleChoice as MultipleChoiceComponent,
  MultipleChoiceProps,
} from '../../components/elements/multipleChoice/MultipleChoice.tsx';

type Props = Omit<MultipleChoiceProps, 'onOptionClicked'>;

export const MultipleChoice: React.FC<Props> = (props) => {
  const { typeElement } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(typeElement.elementId, state.contentApp.editedContentItemVariantElements),
  );

  const onSelectedOptionsUpdate = useCallback(
    (selectedOptions: readonly Uuid[]) => {
      dispatch(onMultipleChoiceOptionsChange(selectedOptions, typeElement));
    },
    [typeElement],
  );

  if (!isMultipleChoiceElement(elementData)) {
    return null;
  }

  return (
    <MultipleChoiceComponent
      {...props}
      elementData={elementData}
      onSelectedOptionsUpdate={onSelectedOptionsUpdate}
    />
  );
};

MultipleChoice.displayName = 'MultipleChoiceContainer';
