import { ContentState, EntityInstance } from 'draft-js';
import { isAbsoluteWebUrl as isAbsoluteWebUrlImported } from '../../../../../_shared/utils/urlUtils.ts';
import { getAttributesFromEntityData as baseGetAttributesFromEntityData } from '../../../plugins/entityApi/api/editorEntityUtils.ts';
import { isLink } from '../../../plugins/links/api/LinkEntity.ts';
import { getBlockHierarchy } from '../../blocks/editorHierarchyUtils.ts';
import { getBlocks } from '../../general/editorContentGetters.ts';
import { GetValueFromContentState } from '../GetValueFromContentState.type.ts';
import { getLinkData } from './elements/entities.ts';
import { HtmlData } from './elements/tags.ts';
import { generateHtml } from './generateHtml.ts';

export const checkLinkValidityCreator =
  (isAbsoluteWebUrl: (url: string) => boolean) =>
  (data: { href?: string }): boolean => {
    const url = data.href;
    return !url || isAbsoluteWebUrl(url);
  };

const checkLinkValidity = checkLinkValidityCreator(isAbsoluteWebUrlImported);

const getAttributesFromEntityData = (entity: EntityInstance): AnyObject | null => {
  const baseAttributes = baseGetAttributesFromEntityData(entity);
  if (baseAttributes && isLink(entity)) {
    return checkLinkValidity(baseAttributes)
      ? {
          ...baseAttributes,
          target: '_blank',
        }
      : null;
  }

  return baseAttributes;
};

const getEntityTagsForServer = (entity: EntityInstance, content: HtmlData): HtmlData | null => {
  if (isLink(entity)) {
    return getLinkData(entity, content, getAttributesFromEntityData);
  }
  return null;
};

// Generates HTML for guidelines type element value
export const exportGuidelinesToServerHtml: GetValueFromContentState = (content: ContentState) =>
  generateHtml(
    {
      nodes: getBlockHierarchy(getBlocks(content)),
      entityMap: content.getEntityMap(),
    },
    {
      getEntityData: getEntityTagsForServer,
    },
  );
