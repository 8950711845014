import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ILanguageRepository } from '../../../../repositories/interfaces/ILanguageRepository.type.ts';
import { ILanguageUsagesServerModel } from '../../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';
import {
  Data_LanguageUsages_Failed,
  Data_LanguageUsages_Started,
  Data_LanguageUsages_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly languageRepository: Pick<ILanguageRepository, 'getLanguageUsages'>;
}

const start = () => ({ type: Data_LanguageUsages_Started }) as const;
const success = (data: ILanguageUsagesServerModel) =>
  ({
    type: Data_LanguageUsages_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_LanguageUsages_Failed }) as const;

export type LoadLanguageUsagesActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadLanguageUsagesAction =
  ({ languageRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const languageUsageServerModel = await languageRepository.getLanguageUsages(abortSignal);
      dispatch(success(languageUsageServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
