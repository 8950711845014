import React from 'react';
import { Link } from 'react-router-dom';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import {
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LinkLike } from '../../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { IntercomUtils } from '../../../../../_shared/utils/intercomUtils.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isAdminOnSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
  isProjectPlanSuspended,
} from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isSubscriptionSuspendedAutomatically } from '../../../../subscriptionManagement/shared/utils/subscriptionStatusUtils.ts';

const intercomLinkElement = (
  <LinkLike onClick={IntercomUtils.showIntercom}>contact our support</LinkLike>
);

export const SuspendedSubscriptionWarning: React.FC = () => {
  const currentProjectId = useSelector((state) => state.sharedApp.currentProjectId);
  const isPlanPrepaid = useSelector(getCurrentProjectPlan).isPrepaid;
  const isPlanSuspended = useSelector((state) => isProjectPlanSuspended(state, currentProjectId));
  const subscription = useSelector(getCurrentProjectSubscription);
  const isSubscriptionAdmin = useSelector((state) =>
    isAdminOnSubscription(state, subscription.subscriptionId),
  );

  if (!isPlanSuspended) {
    return null;
  }

  const { subscriptionId, subscriptionName, statusReason } = subscription;

  const isSuspendedAutomatically = isSubscriptionSuspendedAutomatically(statusReason);

  const renderPaymentLink = (): JSX.Element => {
    const billingInformationPath = buildPath<EnsuredSubscriptionRouteParams>(
      BillingInformationRoute,
      { subscriptionId },
    );

    return <Link to={billingInformationPath}>select a payment method</Link>;
  };

  const renderActionToRenew = (): JSX.Element => {
    if (!isSubscriptionAdmin) {
      return <>contact an admin</>;
    }

    return isPlanPrepaid || !isSuspendedAutomatically ? intercomLinkElement : renderPaymentLink();
  };

  const action = renderActionToRenew();

  const showIntercomLink = !isSubscriptionAdmin || (!isPlanPrepaid && isSuspendedAutomatically);

  return (
    <div className="user-listing__suspended-subscription-warning">
      <Warning hideTitle subtitle={`The subscription “${subscriptionName}” has been suspended.`}>
        <p>
          This means you can’t currently make any changes to the structure of projects created under
          this subscription.
          <br />
          To continue working on your projects, {action} to renew the subscription.
          {showIntercomLink && (
            <>
              <br />
              If you have any questions, {intercomLinkElement}.
            </>
          )}
        </p>
      </Warning>
    </div>
  );
};

SuspendedSubscriptionWarning.displayName = 'SuspendedSubscriptionWarning';
