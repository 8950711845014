// order as per https://kontent-ai.atlassian.net/browse/ET-444
export const orderedPlans = [
  'Unknown',
  'Employee',
  'Starter',
  'Developer',
  'Trial',
  'Delivery',
  'Professional',
  'Business',
  'Premium',
  'Enterprise',
  'Partner',
] as const;

export type PlanType = (typeof orderedPlans)[number];
