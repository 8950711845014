import { TextTypeElementValidationResult } from '../types/TextTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';
import { BaseTextInitialValidationResult } from './baseTextInitialValidationResult.ts';

export const TextInitialValidationResult: TextTypeElementValidationResult = {
  isDefaultValueValid: true,
  isRegexPatternValid: true,
  isRegexLengthValid: true,
  isRegexValidationMessageLengthValid: true,
  ...BaseTextInitialValidationResult,
  conditionValidationResult: InitialConditionValidationResult,
};
