import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { CreditCardInput } from '../containers/CreditCardInput.tsx';
import { ISubscriptionBillingInformation } from '../models/SubscriptionBillingInformation.ts';
import { BillingInformationDetails } from './BillingInformationDetails.tsx';

interface IBillingInformationProps {
  readonly billingInformation: ISubscriptionBillingInformation | null;
  readonly isMarketplaceSubscription: boolean;
  readonly subscriptionId: Uuid;
}

export const BillingInformation: React.FC<IBillingInformationProps> = ({
  billingInformation,
  isMarketplaceSubscription,
  subscriptionId,
}) => {
  return (
    <div
      className="canvas__content-pane canvas__content-pane--is-not-project-specific"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionBillingInfo)}
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.BillingInfo} />
      <div className="canvas__inner-section--restricted-width">
        <Stack spacing={Spacing.XL}>
          <PageTitle>{SubscriptionTabName.BillingInfo}</PageTitle>
          <div className="subscription-billing-information">
            {!isMarketplaceSubscription && <CreditCardInput />}
            <BillingInformationDetails
              billingInformation={billingInformation}
              subscriptionId={subscriptionId}
            />
          </div>
        </Stack>
      </div>
    </div>
  );
};

BillingInformation.displayName = 'BillingInformation';
