import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';

export const getElementsWithAffectedLocalization = (
  editedTypeOrSnippet: IContentType | IContentTypeSnippet,
  originalTypeOrSnippetElements: readonly IBaseTypeElementData[] | undefined,
): ReadonlyArray<IBaseTypeElementData> => {
  const originalNonLocalizableElements = originalTypeOrSnippetElements?.filter(
    (e) => e.isNonLocalizable,
  );

  return editedTypeOrSnippet.typeElements.filter((editedElement) => {
    const originalTypeElement = originalNonLocalizableElements?.find(
      (originalElement) => originalElement.elementId === editedElement.elementId,
    );
    return originalTypeElement && !editedElement.isNonLocalizable;
  });
};
