import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_InitFailed,
  Relations_InitFinished,
  Relations_InitStarted,
} from '../constants/relationsActionTypes.ts';

export function initialized(state = false, action: Action) {
  switch (action.type) {
    case Relations_InitStarted:
    case Relations_InitFailed:
      return false;

    case Relations_InitFinished:
      return true;

    default:
      return state;
  }
}
