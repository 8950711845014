import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useContext } from 'react';
import { SubscriptionUsersCollectionFilter } from '../../containers/SubscriptionUsersCollectionFilter.tsx';
import { SubscriptionUsersEnvironmentFilter } from '../../containers/SubscriptionUsersEnvironmentFilter.tsx';
import { SubscriptionUsersLanguageFilter } from '../../containers/SubscriptionUsersLanguageFilter.tsx';
import { SubscriptionUsersProjectFilter } from '../../containers/SubscriptionUsersProjectFilter.tsx';
import { SubscriptionUsersRoleFilter } from '../../containers/SubscriptionUsersRoleFilter.tsx';
import { SubscriptionUsersStatusFilter } from '../../containers/SubscriptionUsersStatusFilter.tsx';
import { SubscriptionCapabilitiesContext } from '../../context/SubscriptionCapabilitiesContext.tsx';

export const ExpandedSubscriptionUserListingFilter: React.FC = () => {
  const capabilities = useContext(SubscriptionCapabilitiesContext);

  return (
    <>
      <Box paddingBottom={Spacing.L}>
        <Row spacing={Spacing.S}>
          <Column flexFactor={1}>
            <SubscriptionUsersStatusFilter />
          </Column>
          <Column flexFactor={1}>
            <SubscriptionUsersProjectFilter />
          </Column>
          <Column flexFactor={1}>
            <SubscriptionUsersEnvironmentFilter />
          </Column>
        </Row>
      </Box>
      <Box>
        <Row spacing={Spacing.S}>
          <Column width="1/3">
            <SubscriptionUsersRoleFilter />
          </Column>
          {capabilities.areCollectionRolesEnabled && (
            <Column width="1/3">
              <SubscriptionUsersCollectionFilter />
            </Column>
          )}
          {capabilities.areLanguageRolesEnabled && (
            <Column width="1/3">
              <SubscriptionUsersLanguageFilter />
            </Column>
          )}
        </Row>
      </Box>
    </>
  );
};

ExpandedSubscriptionUserListingFilter.displayName = 'ExpandedSubscriptionUserListingFilter';
