import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_AssetEditorDialog_InitFinished,
  AssetLibrary_AssetEditorDialog_InitStarted,
} from '../../constants/assetLibraryActionTypes.ts';

interface IInitializeAssetEditDialogDependencies {
  readonly assetService: IAssetService;
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadAssetUsage: (assetId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = {
  type: AssetLibrary_AssetEditorDialog_InitStarted,
} as const;

const finished = (asset: IAsset) =>
  ({
    type: AssetLibrary_AssetEditorDialog_InitFinished,
    payload: {
      asset,
    },
  }) as const;

export type InitializeAssetEditDialogActionsType = typeof started | ReturnType<typeof finished>;

export const createInitializeAssetEditDialogAction =
  (deps: IInitializeAssetEditDialogDependencies) =>
  (assetId: Uuid, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started);

    const [asset] = await Promise.all([
      deps.assetService.getAsset(assetId, abortSignal),
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadAssetUsage(assetId, abortSignal)),
      dispatch(deps.loadUsers(abortSignal)),
    ]);

    dispatch(finished(asset));
  };
