import { pluralizeWithoutCount } from '../../../../_shared/utils/stringUtils.ts';

export const invitationInProgress = 'Invitation is in progress, hold on a moment.';
export const userNotFound = 'The URL you used to edit the user is invalid. Check for typos.';
export const revokeFailed =
  'We couldn’t revoke the invitation. Please try again. If this keeps happening, let us know.';
export const activationFailed =
  'We couldn’t activate the user. Please try again. If this keeps happening, let us know.';
export const deactivationFailed =
  'We couldn’t deactivate the user. Please try again. If this keeps happening, let us know.';
export const saveFailed =
  'We couldn’t save your changes. Please try again. If this keeps happening, let us know.';
export const inviteFailed =
  'We couldn’t send the invitation. Please try again. If this keeps happening, let us know.';
export const someInvitesFailed = (emails: ReadonlyArray<string>): string =>
  `We couldn’t send invitation to the following users: ${emails.join(
    ', ',
  )}. Please try again. If this keeps happening, let us know.`;
export const invalidInvitationsFieldError =
  'Enter a valid email address like email@example.com to invite the user.';
export const invalidInvitationsInviteButtonTooltip =
  'Correct the email addresses to invite the users';
export const usersAlreadyOnTheProject =
  'Some of these users are already in the project. Remove these users from the list.';
export const duplicateInvitation =
  'The provided emails must be unique. To invite the users, remove the emails marked in red.';
export const invitingUsersAboveSubscriptionLimit = (
  numberOfNewUsersAboveSubscriptionLimit: number,
  numberOfMaxActiveUsers: number,
): string =>
  `Too many invitees. To continue, remove ${numberOfNewUsersAboveSubscriptionLimit} highlighted ${pluralizeWithoutCount(
    'user',
    numberOfNewUsersAboveSubscriptionLimit,
  )} to fit within your subscription limit of ${numberOfMaxActiveUsers} users. To invite all, upgrade your subscription or deactivate existing users.`;
