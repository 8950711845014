import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, { useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getAllApplicableRolesForLanguage } from '../../../../_shared/utils/permissions/getContributorRole.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import {
  getCurrentProjectId,
  getNormalizedRolesWithSettingsForUser,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { filterContentTypesByCapability } from '../../../contentModels/shared/utils/typeUtils.ts';
import { getContentPaceTypeSelectOptions } from '../utils/getContentPaceTypeSelectOptions.ts';

interface IContentPaceContentTypeSelectorProps {
  readonly selectedContentTypeId: Uuid | null;
  readonly onSelectionChange: (selectedContentTypeId: Uuid | null) => void;
}

export const ContentPaceContentTypeSelector: React.FC<IContentPaceContentTypeSelectorProps> = ({
  selectedContentTypeId,
  onSelectionChange,
}) => {
  const user = useSelector((s) => s.data.user);
  const currentProjectId = useSelector(getCurrentProjectId);
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);
  const contentTypes = useMemo(() => contentTypesById.valueSeq().toArray(), [contentTypesById]);

  const languagesWithViewCapability = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      user,
      currentProjectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );

  const options = useMemo(() => {
    const userRoles = getNormalizedRolesWithSettingsForUser(user, currentProjectId);
    const rolesInAllLanguages = languagesWithViewCapability
      .toArray()
      .flatMap((language) =>
        getAllApplicableRolesForLanguage(userRoles.collectionGroups, language.id),
      );

    const uniqueRolesInAllLanguages = new Set(rolesInAllLanguages);
    const uniqueAllowedContentTypes = new Set(
      [...uniqueRolesInAllLanguages].flatMap((role) =>
        filterContentTypesByCapability(contentTypes, role.settings, Capability.ViewContent),
      ),
    );

    return getContentPaceTypeSelectOptions([...uniqueAllowedContentTypes]);
  }, [user, currentProjectId, languagesWithViewCapability, contentTypes]);

  if (contentTypes.length < 2) {
    return null;
  }

  return (
    <Inline align="center" spacingX={Spacing.S}>
      <Box<'label'>
        component="label"
        color={colorTextDefault}
        htmlFor="content-type"
        typography={Typography.BodyMedium}
      >
        Content type:
      </Box>
      <SingleSelect
        id="content-type"
        items={options}
        selectedItemId={selectedContentTypeId}
        onSelectionChange={onSelectionChange}
        placeholder="All content types"
      />
    </Inline>
  );
};

ContentPaceContentTypeSelector.displayName = 'ContentPaceContentTypeSelector';
