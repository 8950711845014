import { PortalContainerContext } from '@kontent-ai/component-library/context';
import { Typography } from '@kontent-ai/component-library/tokens';
import Tippy from '@tippyjs/react/headless';
import React, { ReactElement, useContext, useRef } from 'react';
import styled from 'styled-components';
import {
  ArrowPosition,
  PopoverIsPointing,
} from '../../../../_shared/uiComponents/Popover/Popover.tsx';
import { stopPropagation } from '../../../../_shared/utils/func/functionalTools.ts';
import { DismissiblePopover } from '../../../contentInventory/shared/components/DismissiblePopover.tsx';

const PopoverMessage = styled.p`
  ${Typography.LabelMedium};
`;

interface IEnvironmentTilePopover {
  readonly children: ReactElement;
  readonly isVisible: boolean;
  readonly onDismiss: () => void;
}

export const EnvironmentRenameAdvicePopover: React.FC<IEnvironmentTilePopover> = ({
  children,
  isVisible,
  onDismiss,
}) => {
  const popoverRef = useRef(null);
  const { portalContainerRef } = useContext(PortalContainerContext);

  return (
    <Tippy
      appendTo={portalContainerRef.current ?? document.body}
      interactive
      placement="bottom"
      reference={popoverRef}
      zIndex={9998}
      visible={isVisible}
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: { fallbackPlacements: ['bottom'] },
          },
        ],
      }}
      render={() => (
        <DismissiblePopover
          arrowPosition={ArrowPosition.Middle}
          attachedTo={popoverRef}
          isDark
          onDismiss={(event) => {
            onDismiss();
            event.preventDefault();
          }}
          onClick={stopPropagation}
          orientation={PopoverIsPointing.Up}
          popoverRef={popoverRef}
        >
          <PopoverMessage>
            Be sure to rename this environment
            <br />
            to avoid confusion.
          </PopoverMessage>
        </DismissiblePopover>
      )}
    >
      {children}
    </Tippy>
  );
};

EnvironmentRenameAdvicePopover.displayName = 'EnvironmentRenameAdvicePopover';
