import { Collection, isArray, isArrayOf, isRecordOf } from '@kontent-ai/utils';
import {
  CalendarFilterConfigStorageKey,
  ContentItemListingFilterConfigStorageKey,
  ContentStatusFilterConfigStorageKey,
  ProjectOverviewFilterConfigStorageKey,
} from '../_shared/constants/localStorageKeys.ts';
import { ContentItemFilterOrigin } from '../_shared/models/events/ContentItemFilterEventData.type.ts';
import { isString } from '../_shared/utils/stringUtils.ts';
import { isUuid } from '../_shared/utils/validation/typeValidators.ts';
import { isContentItemStatus } from '../applications/contentInventory/content/models/filter/IContentItemStatus.ts';
import {
  IListingFilter,
  isSearchScope,
} from '../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { isPublishingState } from '../applications/contentInventory/content/models/filter/IPublishingState.ts';
import {
  StorageListingFilterVersion4,
  emptyStorageListingFilterVersion4,
} from '../applications/contentInventory/content/models/filter/StorageListingFilterVersion4.ts';
import {
  IProjectSpecificStorage,
  StorageParams,
  getProjectSpecificStorage,
} from './projectSpecificStorage.ts';

const currentStorageFilterVersion: StorageListingFilterVersion4['version'] = 4;

type StorageListingFilter = StorageListingFilterVersion4;

type FilterStorageOrigin = Omit<
  ContentItemFilterOrigin,
  | ContentItemFilterOrigin.ModalContentItemSelector
  | ContentItemFilterOrigin.ModalMultipleContentItemsSelector
>;

const toStorageListingFilter = (listingFilter: IListingFilter): StorageListingFilter => {
  return {
    selectedPublishingStateNodes: listingFilter.selectedPublishingStateNodes,
    searchPhrase: listingFilter.searchPhrase,
    searchScope: Array.from(listingFilter.searchScope),
    selectedTaxonomyNodes: Object.fromEntries(
      Collection.getEntries(listingFilter.selectedTaxonomyNodes).map(([groupId, termIds]) => [
        groupId,
        Array.from(termIds),
      ]),
    ),
    selectedCollectionsNodes: Array.from(listingFilter.selectedCollectionsNodes),
    selectedContentTypesNodes: Array.from(listingFilter.selectedContentTypesNodes),
    selectedContributorsNodes: Array.from(listingFilter.selectedContributorsNodes),
    selectedRequiredElementsNodes: listingFilter.selectedContentItemStatus,
    selectedSitemapNodes: Array.from(listingFilter.selectedSitemapNodes),
    selectedSpacesNodes: Array.from(listingFilter.selectedSpacesNodes),
    selectedWorkflowNodes: Collection.getEntries(listingFilter.selectedWorkflowNodes).map(
      ([workflowId, stepIds]) => [workflowId, Array.from(stepIds)],
    ),
    version: currentStorageFilterVersion,
  };
};

const getSelectedTaxonomyNodes = (
  rawTaxonomyFilter: ReadonlyRecord<Uuid, ReadonlyArray<Uuid>>,
): ReadonlyMap<Uuid, ReadonlySet<Uuid>> =>
  new Map(
    Object.entries(rawTaxonomyFilter).map(
      ([groupId, termIds]) => [groupId, new Set(termIds)] as const,
    ),
  );

const fromStorageValue = (storageValue: JsonParseOutput): StorageListingFilter => {
  if (!(storageValue instanceof Object) || !('version' in storageValue)) {
    return emptyStorageListingFilterVersion4;
  }

  if (storageValue.version === currentStorageFilterVersion) {
    return {
      searchPhrase: isString(storageValue?.searchPhrase)
        ? storageValue.searchPhrase
        : emptyStorageListingFilterVersion4.searchPhrase,
      searchScope: isArrayOf(storageValue.searchScope, isSearchScope)
        ? storageValue.searchScope
        : emptyStorageListingFilterVersion4.searchScope,
      selectedCollectionsNodes: isArrayOf(storageValue.selectedCollectionsNodes, isUuid)
        ? storageValue.selectedCollectionsNodes
        : emptyStorageListingFilterVersion4.selectedCollectionsNodes,
      selectedContentTypesNodes: isArrayOf(storageValue.selectedContentTypesNodes, isUuid)
        ? storageValue.selectedContentTypesNodes
        : emptyStorageListingFilterVersion4.selectedContentTypesNodes,
      selectedContributorsNodes: isArrayOf(storageValue.selectedContributorsNodes, isString)
        ? storageValue.selectedContributorsNodes
        : emptyStorageListingFilterVersion4.selectedContributorsNodes,
      selectedPublishingStateNodes: isPublishingState(storageValue.selectedPublishingStateNodes)
        ? storageValue.selectedPublishingStateNodes
        : emptyStorageListingFilterVersion4.selectedPublishingStateNodes,
      selectedRequiredElementsNodes: isContentItemStatus(storageValue.selectedRequiredElementsNodes)
        ? storageValue.selectedRequiredElementsNodes
        : emptyStorageListingFilterVersion4.selectedRequiredElementsNodes,
      selectedSitemapNodes: isArrayOf(storageValue.selectedSitemapNodes, isUuid)
        ? storageValue.selectedSitemapNodes
        : emptyStorageListingFilterVersion4.selectedSitemapNodes,
      selectedSpacesNodes: isArrayOf(storageValue.selectedSpacesNodes, isUuid)
        ? storageValue.selectedSpacesNodes
        : emptyStorageListingFilterVersion4.selectedSpacesNodes,
      selectedTaxonomyNodes: isRecordOf(
        storageValue.selectedTaxonomyNodes,
        isUuid,
        (value: unknown): value is ReadonlyArray<Uuid> => isArrayOf(value, isUuid),
      )
        ? storageValue.selectedTaxonomyNodes
        : emptyStorageListingFilterVersion4.selectedTaxonomyNodes,
      selectedWorkflowNodes: isArrayOf(
        storageValue.selectedWorkflowNodes,
        (value: unknown): value is readonly [Uuid, ReadonlyArray<Uuid>] =>
          isArray(value) && isUuid(value[0]) && isArrayOf(value[1], isUuid),
      )
        ? storageValue.selectedWorkflowNodes
        : emptyStorageListingFilterVersion4.selectedWorkflowNodes,
      version: storageValue.version,
    } satisfies StorageListingFilterVersion4;
  }

  return emptyStorageListingFilterVersion4;
};

const fromStorageListingFilter = (storageListingFilter: StorageListingFilter): IListingFilter => {
  return {
    searchPhrase: storageListingFilter.searchPhrase,
    searchScope: new Set(storageListingFilter.searchScope),
    selectedCollectionsNodes: new Set(storageListingFilter.selectedCollectionsNodes),
    selectedContentItemStatus: storageListingFilter.selectedRequiredElementsNodes,
    selectedContentTypesNodes: new Set(storageListingFilter.selectedContentTypesNodes),
    selectedContributorsNodes: new Set(storageListingFilter.selectedContributorsNodes),
    selectedPublishingStateNodes: storageListingFilter.selectedPublishingStateNodes,
    selectedSitemapNodes: new Set(storageListingFilter.selectedSitemapNodes),
    selectedSpacesNodes: new Set(storageListingFilter.selectedSpacesNodes),
    selectedTaxonomyNodes: getSelectedTaxonomyNodes(storageListingFilter.selectedTaxonomyNodes),
    selectedWorkflowNodes: new Map(
      storageListingFilter.selectedWorkflowNodes.map(([workflowId, stepIds]) => [
        workflowId,
        new Set(stepIds),
      ]),
    ),
  };
};

const ContentItemFilterOriginToStorageKey = {
  [ContentItemFilterOrigin.Calendar]: CalendarFilterConfigStorageKey,
  [ContentItemFilterOrigin.ContentInventory]: ContentItemListingFilterConfigStorageKey,
  [ContentItemFilterOrigin.ProjectOverview]: ProjectOverviewFilterConfigStorageKey,
  [ContentItemFilterOrigin.ContentStatus]: ContentStatusFilterConfigStorageKey,
} as const;

const isContentItemFilterOriginRepresentedInLocalStorage = (
  origin: FilterStorageOrigin,
): origin is keyof typeof ContentItemFilterOriginToStorageKey =>
  (Object.keys(ContentItemFilterOriginToStorageKey) as ReadonlyArray<FilterStorageOrigin>).includes(
    origin,
  );

export const getFilterStorage = (
  origin: FilterStorageOrigin,
): IProjectSpecificStorage<IListingFilter> | null => {
  if (!isContentItemFilterOriginRepresentedInLocalStorage(origin)) {
    return null;
  }

  const filterStorageParams: StorageParams<StorageListingFilter> = {
    key: ContentItemFilterOriginToStorageKey[origin],
    initialState: emptyStorageListingFilterVersion4,
    fromJS: fromStorageValue,
    shouldReplaceStoredValueOnLoad: (storageValue) =>
      storageValue.version !== currentStorageFilterVersion,
  };

  const storage = getProjectSpecificStorage<StorageListingFilter>(filterStorageParams);

  const save = (filter: IListingFilter, projectId: Uuid): void => {
    storage.save(toStorageListingFilter(filter), projectId);
  };

  const update = (filter: Partial<IListingFilter>, projectId: Uuid): void => {
    const originalFilter = load(projectId);
    const updatedFilter: IListingFilter = {
      ...originalFilter,
      ...filter,
    };

    storage.update(toStorageListingFilter(updatedFilter), projectId);
  };

  const forget = (projectId: Uuid): void => {
    storage.forget(projectId);
  };

  const load = (projectId: Uuid): IListingFilter =>
    fromStorageListingFilter(storage.load(projectId));

  return {
    save,
    update,
    forget,
    load,
  };
};
