import { memoize } from '@kontent-ai/memoization';
import { EditorPaperThreshold } from '../../../../../../_shared/utils/editorViewContext/editorPaperContext/paperThresholds.ts';
import { EditingAction } from '../../models/EditingAction.ts';
import { EditingQuickAction } from '../../models/EditingQuickAction.ts';
import { doesUserViewSupportQuickActions } from './doesUserViewSupportQuickActions.ts';
import { getAvailableQuickActions } from './getActionsForPresetQuickActions.ts';

type ViewContext = {
  readonly hasUserScrolledUp: boolean;
  readonly isPaperScrolledOut: boolean;
  readonly paperThresholds: ReadonlySet<EditorPaperThreshold>;
};

const getMaximumQuickActionsNumber = (paperThresholds: ReadonlySet<EditorPaperThreshold>) => {
  if (paperThresholds.has(EditorPaperThreshold.UpToFourQuickActions)) {
    return 4;
  }

  if (paperThresholds.has(EditorPaperThreshold.UpToThreeQuickActions)) {
    return 3;
  }

  return 0;
};

type AvailableActionsSplit = {
  readonly menuActions: readonly EditingAction[];
  readonly quickActions: readonly EditingAction[];
};

const createMemoizedActionsSplit = memoize.weak(
  (
    availableActions: readonly EditingAction[],
    presetQuickActions: readonly EditingQuickAction[],
    maximumQuickActionsNumber: number,
  ): AvailableActionsSplit => {
    const availableQuickActions = getAvailableQuickActions(availableActions, presetQuickActions);

    const quickActions = availableQuickActions.slice(0, maximumQuickActionsNumber);
    const menuActions = availableActions.filter((action) => !quickActions.includes(action));

    return {
      menuActions,
      quickActions,
    };
  },
);

export const splitContentItemEditingActions = (
  availableActions: readonly EditingAction[],
  presetQuickActions: readonly EditingQuickAction[],
  viewContext: ViewContext,
  areQuickActionsAllowed: boolean,
): AvailableActionsSplit => {
  const allowQuickActions = areQuickActionsAllowed && doesUserViewSupportQuickActions(viewContext);

  const maximumQuickActionsNumber = allowQuickActions
    ? getMaximumQuickActionsNumber(viewContext.paperThresholds)
    : 0;

  return createMemoizedActionsSplit(
    availableActions,
    presetQuickActions,
    maximumQuickActionsNumber,
  );
};
