import {
  DOMRectLike,
  getNodesClosestParentElement,
  getParent,
  getSelfOrParent,
  isElement,
} from '@kontent-ai/DOM';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { assert, Collection, notNull } from '@kontent-ai/utils';
import getBoundingClientRect from '@popperjs/core/lib/dom-utils/getBoundingClientRect';
import { DragSourceElementClassname } from '../../../../../_shared/components/DragDrop/dragDropConstants.ts';
import { getMax, getMin } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { DataDraftJsAttributes } from '../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import {
  DOMSelectionLike,
  getNodesInSelection,
} from '../../../../../_shared/utils/selectionUtils.ts';
import { isHighlighted } from '../../../components/utility/RichTextHighlighter.tsx';
import {
  BlockClassName,
  EmptyTextBlockClassName,
  SelectableContainerClassName,
  TextBlockClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';

// We consider neighbouring rectangles with a small distance as continuous rectangle to avoid fusing too far rectangles
const selectionRectangleDiscontinuityTolerance = Spacing.S;

// When merging lines, there must be at least 50% height overlap between sibling rectangles
// otherwise table cell resizer could merge with the line of content in a table cell
const minimumLineHeightOverlap = 0.5;

export type NumberRange = {
  readonly min: number;
  readonly max: number;
};

export type NodePredicate = (node: Node) => boolean;

type SelectionDOMRect = DOMRectLike & {
  readonly element?: Element;
  readonly keepWithoutMatchingNodeRectangle?: boolean;
};

// We need to round rects because the algorithm compares selection rect against element rect
// they may be slightly different due to zoom or minor differences in browser rendering
export const getClonedRoundedRect = (rect: DOMRect, element?: HTMLElement): SelectionDOMRect => {
  const bottom = Math.round(rect.bottom);
  const left = Math.round(rect.left);
  const right = Math.round(rect.right);
  const top = Math.round(rect.top);

  return {
    bottom,
    height: bottom - top,
    left,
    right,
    top,
    width: right - left,
    element,
  };
};

// This includes asset images, linked items, components => Their adequately sized containers
export const isObjectBlockWrapper: NodePredicate = (node) =>
  isElement(node) && node.classList.contains(DragSourceElementClassname);

// This includes all the empty block <br>s and text containing <span>s
// But we don't want the ones in dummy blocks.
export const isTextBlockContent: NodePredicate = (node) =>
  isElement(node) &&
  node.getAttribute(DataDraftJsAttributes.Text) === 'true' &&
  node.matches(`.${TextBlockClassName} *`);

export const isEmptyTextBlock: NodePredicate = (node) =>
  isElement(node) && node.classList.contains(EmptyTextBlockClassName);

export const getDistanceFromRange = (interval: NumberRange, number: number): number => {
  return Math.max(0, interval.min - number, number - interval.max);
};

export const getTargetSelectionRectangle = (
  selectionRectangles: ReadonlyArray<DOMRectLike>,
  isUpright: boolean,
): DOMRectLike =>
  isUpright
    ? getMin(selectionRectangles, (rect) => rect.top)
    : getMax(selectionRectangles, (rect) => rect.bottom);

/**
 * The method takes nodes and filters out the irrelevant ones, then also maps them to their
 * closest parent HTMLElement while keeping the collection of HTMLElements duplicate less.
 * It's all done in one go for efficiency reasons.
 * @param nodes
 * @param nodePredicate
 */
const filterOutIrrelevantAndDuplicateNodes = (
  nodes: ReadonlyArray<Node>,
  nodePredicate: NodePredicate,
): ReadonlyArray<HTMLElement> => {
  if (nodes.length <= 1) {
    const firstNode = nodes[0];
    assert(!!firstNode, () => `${__filename}: The first node is falsy.`);
    // If we only have one node, we just need to take it no matter if it is matching the predicate or not
    // it will likely contain only a text node anyway
    const element = getNodesClosestParentElement(firstNode);
    return element ? [element] : [];
  }
  const reductionResult = nodes.reduce(
    (aggregate, node) => {
      if (nodePredicate(node)) {
        const parentElement = getNodesClosestParentElement(node);
        if (parentElement && !aggregate.set.has(parentElement)) {
          aggregate.array.push(parentElement);
          aggregate.set.add(parentElement);
        }
      }
      return aggregate;
    },
    {
      array: new Array<HTMLElement>(),
      set: new Set<HTMLElement>(),
    },
  );

  return reductionResult.array;
};

const getElementsDomRects = (element: HTMLElement): ReadonlyArray<SelectionDOMRect> =>
  Array.from(element.getClientRects()).map((rect) => getClonedRoundedRect(rect, element));

const isBlockElement = (element: Element) => element.classList.contains(BlockClassName);
const getBlockElement = (rect: SelectionDOMRect): Element | undefined =>
  rect.element && (getSelfOrParent(rect.element, isBlockElement) ?? undefined);

function fuseRectangles(
  first: SelectionDOMRect,
  second: SelectionDOMRect,
  element: Element | undefined,
): SelectionDOMRect {
  const top = Math.min(first.top, second.top);
  const bottom = Math.max(first.bottom, second.bottom);
  const left = Math.min(first.left, second.left);
  const right = Math.max(first.right, second.right);

  return {
    top,
    bottom,
    height: bottom - top,
    left,
    right,
    width: right - left,
    element,
    ...(first.keepWithoutMatchingNodeRectangle && second.keepWithoutMatchingNodeRectangle
      ? { keepWithoutMatchingNodeRectangle: true }
      : undefined),
  };
}

const fuseSameLineRectangles = (
  rectangles: ReadonlyArray<SelectionDOMRect>,
): ReadonlyArray<SelectionDOMRect> => {
  if (rectangles.length <= 1) {
    return rectangles;
  }

  return rectangles.slice(1).reduce(
    (fusedRectangles, currentRectangle) => {
      const previousRectangle = Collection.getLast(fusedRectangles) as SelectionDOMRect;

      // Only fuse rectangles within the same editor block or outside block to avoid fusing two neighbouring table cell contents
      const currentBlockElement = getBlockElement(currentRectangle);
      const previousBlockElement = getBlockElement(previousRectangle);

      if (currentBlockElement !== previousBlockElement) {
        fusedRectangles.push(currentRectangle);
        return fusedRectangles;
      }

      const maxTop = Math.max(previousRectangle.top, currentRectangle.top);
      const minBottom = Math.min(previousRectangle.bottom, currentRectangle.bottom);
      const overlapHeight = minBottom - maxTop;
      const overlapRatio = overlapHeight > 0 ? overlapHeight / previousRectangle.height : 0;

      if (
        overlapRatio > minimumLineHeightOverlap &&
        currentRectangle.left - selectionRectangleDiscontinuityTolerance <=
          previousRectangle.right &&
        // We could technically fuse explicitly added rectangles with native rectangles, but we don't know all possible edge cases
        // so it is safer to keep them independent, it could result in a too large explicit rectangle otherwise
        !!currentRectangle.keepWithoutMatchingNodeRectangle ===
          !!previousRectangle.keepWithoutMatchingNodeRectangle
      ) {
        if (currentRectangle.right > previousRectangle.right) {
          fusedRectangles[fusedRectangles.length - 1] = fuseRectangles(
            previousRectangle,
            currentRectangle,
            currentBlockElement,
          );
          return fusedRectangles;
        }
      }

      fusedRectangles.push(currentRectangle);
      return fusedRectangles;
    },
    [Collection.getFirst(rectangles)].filter(notNull),
  );
};

const areRectanglesOverlapping = (a: DOMRectLike, b: DOMRectLike): boolean =>
  a.top <= b.bottom && b.top <= a.bottom && a.left <= b.right && b.left <= a.right;

const isRectangleContainedWithin = (rectangle: DOMRectLike, container: DOMRectLike) => {
  return (
    rectangle.top >= container.top &&
    rectangle.left >= container.left &&
    rectangle.bottom <= container.bottom &&
    rectangle.right <= container.right
  );
};

const isBlockContainer = (element: Element): boolean =>
  element.classList.contains(SelectableContainerClassName);

function getRelevantSelectionRectangles(
  fusedElementRectangles: ReadonlyArray<SelectionDOMRect>,
  selectionRectangle: SelectionDOMRect,
): ReadonlyArray<SelectionDOMRect> {
  const relevantElementRectangles: Array<SelectionDOMRect> = [];
  for (let i = 0; i < fusedElementRectangles.length; i++) {
    const elementRectangle = fusedElementRectangles[i] as SelectionDOMRect;
    if (isRectangleContainedWithin(elementRectangle, selectionRectangle)) {
      // When the candidate element is wrapped in a selectable container (e.g. table cell), use the container rectangle instead
      // we highlight the container even when the DOM selection is just inside it and this makes the toolbar positioning consistent
      // between inside and outside selection of the container
      const parentContainer =
        elementRectangle.element && getParent(elementRectangle.element, isBlockContainer);
      if (parentContainer && isHighlighted(parentContainer)) {
        // Selectable container may be wrapped in another (e.g. table cell in a table)
        // if all its content is selected, we want to keep the top level container and prevent fallback just to its child
        const higherParentContainer = getParent(parentContainer, isBlockContainer);
        if (
          higherParentContainer &&
          (isHighlighted(higherParentContainer) ||
            Array.from(
              higherParentContainer.querySelectorAll(`.${SelectableContainerClassName}`),
            ).every(isHighlighted))
        ) {
          const topHighlightedContainerRect = getClonedRoundedRect(
            higherParentContainer.getBoundingClientRect(),
            parentContainer,
          );
          return [topHighlightedContainerRect];
        }

        const highlightedContainerRect = getClonedRoundedRect(
          parentContainer.getBoundingClientRect(),
          parentContainer,
        );
        return [highlightedContainerRect];
      }

      // Element rectangle is a subset of the selection
      relevantElementRectangles.push(elementRectangle);
    }
  }

  if (relevantElementRectangles.length > 0) {
    return relevantElementRectangles;
  }

  if (
    // When no better suitable selection rectangle found but the selection rectangle overlaps with some element rectangles, we can keep it
    // We also keep in case the selection rectangle was added manually via an explicit fix, such selection rectangles have the element set as well
    // e.g. rectangles for empty text blocks
    selectionRectangle.keepWithoutMatchingNodeRectangle ||
    fusedElementRectangles.some((elementRectangle) =>
      areRectanglesOverlapping(elementRectangle, selectionRectangle),
    )
  ) {
    return [selectionRectangle];
  }

  // When there are no overlapping element rectangles, we skip even the selection rectangle as an outlier
  // we know this happens in case of more complex components (e.g. linked item) where there seems to be something weird in the layout
  // that positions the selection rectangle outside the elements, but we are not able to tell what exactly for now
  // Anyway, we are not interested in possible selection that cannot be highlighted so we can skip such selection rectangle
  return [];
}

const isEmptyBlockDummyContent = (onlyChild: HTMLElement): boolean =>
  onlyChild.tagName === 'BR' && onlyChild.getAttribute('data-text') === 'true';

function getRectanglesForEmptyTextBlockOrEmpty(node: Node | null): ReadonlyArray<SelectionDOMRect> {
  if (isElement(node) && node.children.length === 1) {
    const onlyChild = node.children[0];
    if (
      isElement(onlyChild) &&
      isEmptyBlockDummyContent(onlyChild) &&
      getParent(node, isEmptyTextBlock)
    ) {
      return [
        {
          ...getClonedRoundedRect(onlyChild.getBoundingClientRect()),
          keepWithoutMatchingNodeRectangle: true,
        },
      ];
    }
  }

  return [];
}

function ensureEmptyBlockRectanglesAtSelectionEdges(
  rangeRectangles: ReadonlyArray<DOMRect>,
  selection: DOMSelectionLike,
): ReadonlyArray<DOMRectLike> {
  return [
    ...getRectanglesForEmptyTextBlockOrEmpty(selection.anchorNode),
    ...rangeRectangles.map((rect) => getClonedRoundedRect(rect)),
    ...getRectanglesForEmptyTextBlockOrEmpty(selection.focusNode),
  ];
}

function getMissingRectangleForStartOfCurrentLine(
  endOfPreviousLineRectangle: DOMRect,
  anchorNode: Node,
): SelectionDOMRect {
  const textLineHeight = anchorNode.parentElement
    ? Number.parseFloat(window.getComputedStyle(anchorNode.parentElement).lineHeight)
    : endOfPreviousLineRectangle.height;
  const verticalSpacingBetweenTextLines = textLineHeight - endOfPreviousLineRectangle.height;
  const topOfCurrentLine = endOfPreviousLineRectangle.bottom + verticalSpacingBetweenTextLines;
  const leftOfCurrentLine = anchorNode.parentElement
    ? getBoundingClientRect(anchorNode.parentElement).left
    : endOfPreviousLineRectangle.left;

  // We first calculate the next rectangle and round as the last step to prevent accumulating rounding errors
  const startOfCurrentLineRectangle: SelectionDOMRect = {
    ...getClonedRoundedRect(endOfPreviousLineRectangle),
    left: leftOfCurrentLine,
    right: leftOfCurrentLine + endOfPreviousLineRectangle.width,
    top: Math.round(topOfCurrentLine),
    bottom: Math.round(topOfCurrentLine + textLineHeight),
    keepWithoutMatchingNodeRectangle: true,
  };
  return startOfCurrentLineRectangle;
}

function getSelectionRectangles(selection: DOMSelectionLike): ReadonlyArray<SelectionDOMRect> {
  const range = selection.range;
  if (!range) {
    return [];
  }

  const rangeRectangles = Array.from(range.getClientRects());

  if (!selection.isCollapsed || !selection.anchorNode) {
    // In Chrome, when selection starts or ends in an empty block (which only contains BR), its rectangle is not included in selection rectangles
    // In FF, they are included, but later filtered out through missing node rectangles
    // We include them explicitly with a link to the empty block element to make sure they stay,
    // and the toolbar can position to the empty block at the edge of the selection
    return ensureEmptyBlockRectanglesAtSelectionEdges(rangeRectangles, selection);
  }

  // When caret is after new line character in FF, the DOM selection contains only one rectangle from the end of the previous line
  // which doesn't correspond to where the caret is placed physically. That causes the block toolbar to be placed at incorrect position
  // In Chrome it contains both end of the previous line rectangle and the start of the next line rectangle
  // which allows positioning the toolbar correctly to the caret position
  // We detect this FF situation and provide the estimation of the second rectangle to fix the issue
  const isCaretAfterNewLine =
    selection.anchorNode.textContent?.[selection.anchorOffset - 1] === '\n';
  if (isCaretAfterNewLine) {
    if (rangeRectangles.length <= 1) {
      const endOfPreviousLineRectangle = rangeRectangles[0];
      if (endOfPreviousLineRectangle) {
        return [
          getClonedRoundedRect(endOfPreviousLineRectangle),
          getMissingRectangleForStartOfCurrentLine(
            endOfPreviousLineRectangle,
            selection.anchorNode,
          ),
        ];
      }
    }

    // In Chrome and Safari, when there are new lines at the end of the element (even inside the block when span breaks due to formatting)
    // it does not expand its bounding rectangle and omits the next line rectangle from the result
    // we keep the last rectangle explicitly in this case so that it gets included in the result correctly
    const lastRectangle = rangeRectangles[rangeRectangles.length - 1];
    if (lastRectangle) {
      return [
        ...rangeRectangles
          .slice(0, rangeRectangles.length - 1)
          .map((rect) => getClonedRoundedRect(rect)),
        {
          ...getClonedRoundedRect(lastRectangle),
          keepWithoutMatchingNodeRectangle: true,
        },
      ];
    }
  }

  return rangeRectangles.map((rect) => getClonedRoundedRect(rect));
}

export const getRelevantRectanglesForPositioning = (
  selection: DOMSelectionLike,
  nodePredicate: NodePredicate,
): ReadonlyArray<DOMRectLike> => {
  const rangeRectangles = getSelectionRectangles(selection);
  if (rangeRectangles.length === 0) {
    return [];
  }

  // We're getting nodes and consequently elements, because the rectangles provided by range.getClientRects()
  // are not representative of the highlighted areas especially in Firefox and for fully selected lines.
  const nodes = getNodesInSelection(selection);
  const relevantElements = filterOutIrrelevantAndDuplicateNodes(nodes, nodePredicate);
  if (relevantElements.length === 0) {
    return [];
  }

  const elementRectangles = relevantElements.flatMap(getElementsDomRects);
  // Different inline style ranges in editor result in several spans which result in several DOMRectLikes for really one visible rectangle.
  const fusedElementRectangles = fuseSameLineRectangles(elementRectangles);
  const fusedSelectionRectangles = fuseSameLineRectangles(rangeRectangles);

  const correctedRectangles = fusedSelectionRectangles.flatMap((selectionRectangle) =>
    getRelevantSelectionRectangles(fusedElementRectangles, selectionRectangle),
  );

  return correctedRectangles;
};
