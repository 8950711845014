import { Size, Spacing, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const StyledSelectGroup = styled.div`
  display: inline-flex;
  max-width: 100%;
  height: ${px(Size.S)};
  gap: ${px(Spacing.S)}
`;

export const StyledSelectGroupPopup = styled(StyledSelectGroup)`
  height: ${px(Size.L)};
  gap: 0;
`;
