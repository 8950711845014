import { memoize } from '@kontent-ai/memoization';
import { compareByMultipleFactors } from '@kontent-ai/utils';
import { Task, TaskStatus } from '../../../../../_shared/models/Task.ts';

const compareLastEditDatesNewestFirst = (task1: Task, task2: Task) =>
  task2.lastModifiedAt.localeCompare(task1.lastModifiedAt);

/**
 * Compares the tasks by their due date.
 * Tasks past due have the lowest ordinal value. (They are then first in the list.)
 * Tasks without a due date have the highest ordinal value. (They are then last in the list.)
 */
const compareDueDates = (task1: Task, task2: Task) => {
  if (task1.dueDate === task2.dueDate) {
    return 0;
  }
  if (task2.dueDate === null) {
    return -1;
  }
  if (task1.dueDate === null) {
    return 1;
  }
  return task1.dueDate.localeCompare(task2.dueDate);
};

// keep in order
enum TaskCategory {
  OpenMine = 0,
  OpenNotMine = 1,
  CompletedMine = 2,
  CompletedNotMine = 3,
}

const getTaskCategory = (task: Task, isAssignedToMe: (task: Task) => boolean): TaskCategory => {
  const isMine = isAssignedToMe(task);
  if (task.status === TaskStatus.Open) {
    return isMine ? TaskCategory.OpenMine : TaskCategory.OpenNotMine;
  }

  return isMine ? TaskCategory.CompletedMine : TaskCategory.CompletedNotMine;
};

export const orderTasks = memoize.maxOne(
  (tasks: readonly Task[], currentUserId: Uuid): ReadonlyArray<Task> => {
    const getCategory = (task: Task) =>
      getTaskCategory(task, (t) => t.assignees.has(currentUserId));
    const compareCategories = (task1: Task, task2: Task) => getCategory(task1) - getCategory(task2);

    return tasks.toSorted(
      compareByMultipleFactors(compareCategories, compareDueDates, compareLastEditDatesNewestFirst),
    );
  },
);
