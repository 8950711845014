import { assert } from '@kontent-ai/utils';
import { buildQueryString } from './routing/queryParamsUtils.ts';
import { isUuid } from './validation/typeValidators.ts';

export interface IUrlFactory {
  readonly getAuth0SignInContinueUrl: (state: string) => string;
  readonly getDeliverLiveUrl: () => string;
  readonly getDeployedPreviewAppUrl: (projectId: Uuid) => string;
  readonly getDeployedSampleAppDomain: () => string;
  readonly getDraftApiUrl: () => string;
  readonly getDraftLogoutCallbackUrl: () => string;
  readonly getDraftProjectApiUrl: (projectId: Uuid | undefined) => string;
  readonly getDraftProjectContainerApiUrl: (projectContainerId: Uuid | undefined) => string;
  readonly getDraftProjectManagementApiUrl: (projectId: Uuid | undefined) => string;
  readonly getDraftSubscriptionApiUrl: (subscriptionId: Uuid | undefined) => string;
  readonly getDraftSignalRUrl: () => string;
  readonly getDraftUiRootUrl: () => string;
  readonly getJsonPrettifierUrl: (jsonSource: string) => string;
  readonly getLandingPageUrl: () => string;
  readonly getLearnPortalUrl: () => string;
  readonly getPardotUrl: () => string;
  readonly getProductUpdateEndpoint: () => string;
  readonly getVerifyEmailPageUrl: (desiredRedirectUrl?: string) => string;
  readonly getWelcomePageUrl: (desiredRedirectUrl?: string) => string;
  readonly isAllowedRedirectUrl: (url: string) => boolean;
}

let savedFactory: IUrlFactory | null = null;

export const registerUrlFactory = (factory: IUrlFactory) => {
  savedFactory = factory;
};

export const getUrlFactory = (): IUrlFactory => {
  assert(savedFactory, () => 'Cannot get urlFactory before registering any.');

  return savedFactory;
};

export const createUrlFactory = (envConfig: EnvironmentConfig): IUrlFactory => ({
  getDraftUiRootUrl() {
    return self.location.origin;
  },
  getDraftLogoutCallbackUrl() {
    return `${this.getDraftUiRootUrl()}/logout`;
  },
  getVerifyEmailPageUrl(desiredRedirectUrl?: string) {
    const url = `${this.getDraftUiRootUrl()}/verify-email`;
    if (desiredRedirectUrl) {
      const redirectUrl = this.isAllowedRedirectUrl(desiredRedirectUrl)
        ? desiredRedirectUrl
        : this.getDraftUiRootUrl();
      return url + buildQueryString({ redirect_uri: redirectUrl });
    }
    return url;
  },
  getWelcomePageUrl(desiredRedirectUrl?: string) {
    const url = `${this.getDraftUiRootUrl()}/welcome`;
    if (desiredRedirectUrl) {
      const redirectUrl = this.isAllowedRedirectUrl(desiredRedirectUrl)
        ? desiredRedirectUrl
        : this.getDraftUiRootUrl();
      return url + buildQueryString({ redirect_uri: redirectUrl });
    }
    return url;
  },
  getDraftApiUrl() {
    return `${self.location.origin}/api`;
  },
  // this route is used for Fastly routing and also for setting project context on draft BE
  getDraftProjectApiUrl(projectId?: Uuid) {
    assert(
      isUuid(projectId),
      () =>
        `${__filename}: getDraftProjectApiUrl - provided project id '${projectId}' is not valid.`,
    );

    return `${this.getDraftApiUrl()}/project/${projectId}`;
  },
  getDraftProjectContainerApiUrl(projectContainerId?: Uuid) {
    assert(
      isUuid(projectContainerId),
      () =>
        `${__filename}: getDraftProjectContainerApiUrl - provided project container id '${projectContainerId}' is not valid.`,
    );

    return `${this.getDraftApiUrl()}/project-container/${projectContainerId}`;
  },
  getDraftProjectManagementApiUrl(projectId?: Uuid) {
    assert(
      isUuid(projectId),
      () =>
        `${__filename}: getDraftProjectManagementApiUrl - provided project id '${projectId}' is not valid.`,
    );

    return `${this.getDraftApiUrl()}/project-management/${projectId}`;
  },
  getDraftSubscriptionApiUrl(subscriptionId?: Uuid) {
    assert(
      isUuid(subscriptionId),
      () =>
        `${__filename}: getDraftSubscriptionApiUrl - provided subscription id '${subscriptionId}' is not valid.`,
    );

    return `${this.getDraftApiUrl()}/subscription/${subscriptionId}`;
  },
  getDraftSignalRUrl() {
    return `${envConfig.draftNotificationUrl}/api/signalr`;
  },
  isAllowedRedirectUrl(url: string): boolean {
    const decodedUrl = decodeURI(url);
    const draftUiServerUrl = this.getDraftUiRootUrl();
    const auth0SignInContinueUrl = this.getAuth0SignInContinueUrl('');
    const learnPortalUrl = this.getLearnPortalUrl();

    // trailing slash is here to prevent an open redirect (DRAFT-4569)
    return (
      decodedUrl === draftUiServerUrl ||
      decodedUrl.startsWith(`${draftUiServerUrl}/`) ||
      decodedUrl.startsWith(auth0SignInContinueUrl) ||
      decodedUrl.startsWith(`${learnPortalUrl}/`)
    );
  },
  getDeliverLiveUrl() {
    return `https://${envConfig.deliverLiveContentDomain}`;
  },
  getJsonPrettifierUrl(jsonSource: string) {
    return `${envConfig.jsonPrettifierUrl}?jsonUrl=${encodeURIComponent(jsonSource)}`;
  },
  getDeployedSampleAppDomain() {
    return envConfig.demoSampleAppDomain;
  },
  getDeployedPreviewAppUrl(projectId: Uuid) {
    return `${envConfig.demoPreviewAppUrl}/${projectId}`;
  },
  getPardotUrl() {
    return envConfig.pardotUrl;
  },
  getLandingPageUrl() {
    return envConfig.landingPageUrl;
  },
  getProductUpdateEndpoint() {
    return envConfig.productUpdateEndpoint;
  },
  getAuth0SignInContinueUrl(state: string) {
    return `https://${envConfig.auth0Domain}/continue?state=${state}`;
  },
  getLearnPortalUrl() {
    return envConfig.learnPortalUrl;
  },
});
