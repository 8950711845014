import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  SubscriptionHasNoProjectsHeading,
  SubscriptionHasNoProjectsText,
} from '../constants/UIConstants.ts';

export interface IListingProjectPlaceholderProps {
  readonly creatingProject: boolean;
  readonly onCreateButtonClick?: () => void;
}

const creatingProjectTooltipText =
  'We’re creating another project for you.\nPlease wait till it’s done.';

export const ListingProjectPlaceholder: React.FC<IListingProjectPlaceholderProps> = ({
  creatingProject,
  onCreateButtonClick,
}) => {
  if (!onCreateButtonClick) {
    return null;
  }

  return (
    <EmptyState>
      <EmptyState.Title>{SubscriptionHasNoProjectsHeading}</EmptyState.Title>
      <EmptyState.Content>{SubscriptionHasNoProjectsText}</EmptyState.Content>
      <EmptyState.Footer>
        <Button
          buttonStyle="primary"
          disabled={creatingProject}
          onClick={onCreateButtonClick}
          tooltipText={creatingProject ? creatingProjectTooltipText : undefined}
        >
          Create new project
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};

ListingProjectPlaceholder.displayName = 'ListingProjectPlaceholder';
