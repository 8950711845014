import { connect } from 'react-redux';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getFocusedCommentThread } from '../../../../selectors/commentThreads.ts';
import {
  FocusedCommentThreadScroller as FocusedCommentThreadScrollerComponent,
  IFocusedCommentThreadScrollerProps,
} from '../../components/comments/FocusedCommentThreadScroller.tsx';

function mapStateToProps(state: IStore): IFocusedCommentThreadScrollerProps {
  return {
    focusedThread: getFocusedCommentThread(state),
  };
}

export const FocusedCommentThreadScroller = connect(mapStateToProps)(
  FocusedCommentThreadScrollerComponent,
);
