import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { ContentType_Creator_InitStarted } from '../../../contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_RemoveTypeElement,
  ContentModels_TypeEditor_TypeElementCreated,
  ContentModels_TypeEditor_TypeElementValidated,
} from '../../constants/sharedContentModelsActionTypes.ts';
import { getInitialTypeElementValidationResult } from '../../utils/getInitialTypeElementValidationResult.ts';
import { TypeElementValidationResult } from '../../utils/typeElementValidators/types/TypeElementValidationResult.type.ts';

const initialState = new Map<Uuid, TypeElementValidationResult>();

export function validationResults(
  state = initialState,
  action: Action,
): ReadonlyMap<Uuid, TypeElementValidationResult> {
  switch (action.type) {
    case ContentModels_TypeEditor_TypeElementValidated: {
      const { elementId, validationResult } = action.payload;
      return Collection.add(state, [elementId, validationResult]);
    }

    case ContentModels_TypeEditor_RemoveTypeElement: {
      return Collection.remove(state, action.payload.typeElementId);
    }

    case ContentModels_TypeEditor_TypeElementCreated: {
      const { elementId, type } = action.payload.typeElementData;
      const validationResult = getInitialTypeElementValidationResult(type);
      return Collection.add(state, [elementId, validationResult]);
    }

    case ContentModels_TypeEditor_InitStarted:
    case ContentType_Creator_InitStarted: {
      return initialState;
    }

    default:
      return state;
  }
}
