import { ExportedTypes } from '../types/ExportedTypes.type.ts';
import {
  EnableCustomApplicationsServerKey,
  EnableSpacesInCollectionsServerKey,
  EnableUmuxSurveyServerKey,
} from './GeneralPropertiesServerKeys.ts';

/**
 * Ensure that the default value of added property won't affect UI tests or Screen Comparison.
 * You can set the right value in KenticoCloud.UI.Tests repository at TestDataFactory\Provider\GeneralPropertiesProvider.cs.
 * If you are removing the property from here, make sure code managing the property in UI tests is also removed.
 */
export type GeneralPropertiesModel = {
  readonly enableSpacesInCollections: boolean;
  readonly enableUmuxSurvey: DateTimeStamp | null;
  readonly enableCustomApplications: boolean;
};

export type GeneralPropertyServerKey = ExportedTypes<
  typeof import('./GeneralPropertiesServerKeys')
>;

export const GeneralPropertiesStateKeysByServerKeys: Record<
  GeneralPropertyServerKey,
  keyof GeneralPropertiesModel
> = {
  [EnableSpacesInCollectionsServerKey]: 'enableSpacesInCollections',
  [EnableUmuxSurveyServerKey]: 'enableUmuxSurvey',
  [EnableCustomApplicationsServerKey]: 'enableCustomApplications',
};
