import { Collection } from '@kontent-ai/utils';
import { useState } from 'react';
import { Loader } from '../../../_shared/components/Loader.tsx';
import {
  EnvironmentRouteParams,
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionEnvironmentWebhooksCreatingRoute,
  WebhooksCreatingRoute,
} from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadEntityWebhooks } from '../../../data/actions/thunkDataActions.ts';
import {
  disableEntityWebhook,
  enableEntityWebhook,
  resetEntityWebhook,
} from '../actions/thunkEntityWebhookActions.ts';
import { EntityWebhookListing as EntityWebhooksListingComponent } from '../components/EntityWebhookListing.tsx';
import { EntityWebhookStatusTransitionState } from '../types/EntityWebhookStatusTransitionState.ts';

type Props = {
  readonly projectId: Uuid;
  readonly subscriptionId: Uuid;
};

const getPath = (projectId: Uuid, subscriptionId: Uuid) => {
  return subscriptionId === ''
    ? buildPath<EnvironmentRouteParams>(WebhooksCreatingRoute, { projectId })
    : buildPath<SubscriptionEnvironmentSettingsRouteParams>(
        SubscriptionEnvironmentWebhooksCreatingRoute,
        { projectId, subscriptionId },
      );
};

export const EntityWebhookListing = ({ projectId, subscriptionId }: Props) => {
  const dispatch = useDispatch();
  const [webhooksStatusTransitionStates, setWebhooksStatusTransitionStates] = useState<
    ReadonlyMap<Uuid, EntityWebhookStatusTransitionState>
  >(new Map());
  const webhooks = useSelector((s) => s.data.entityWebhooks.byId);

  const [isLoaded] = useThunkPromise(loadEntityWebhooks);

  if (!isLoaded) {
    return <Loader />;
  }

  const onEnable = async (webhookId: Uuid) => {
    setWebhooksStatusTransitionStates(
      Collection.add(webhooksStatusTransitionStates, [
        webhookId,
        EntityWebhookStatusTransitionState.Enabling,
      ]),
    );
    await dispatch(enableEntityWebhook(webhookId));
    setWebhooksStatusTransitionStates(Collection.remove(webhooksStatusTransitionStates, webhookId));
  };
  const onDisable = async (webhookId: Uuid) => {
    setWebhooksStatusTransitionStates(
      Collection.add(webhooksStatusTransitionStates, [
        webhookId,
        EntityWebhookStatusTransitionState.Disabling,
      ]),
    );
    await dispatch(disableEntityWebhook(webhookId));
    setWebhooksStatusTransitionStates(Collection.remove(webhooksStatusTransitionStates, webhookId));
  };
  const onReset = (webhookId: Uuid) => dispatch(resetEntityWebhook(webhookId));

  return (
    <EntityWebhooksListingComponent
      createNewEntityWebhookPath={getPath(projectId, subscriptionId)}
      onEnable={onEnable}
      onDisable={onDisable}
      onReset={onReset}
      webhooks={Collection.getValues(webhooks)}
      webhooksStatusTransitionStates={webhooksStatusTransitionStates}
    />
  );
};
