import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

type Props = {
  readonly showSpaces: boolean;
};

export const CollectionsQuickTip: React.FC<Props> = ({ showSpaces }) => (
  <Callout
    calloutType="quickTip"
    headline="Divide your project into manageable parts"
    maxWidth={1000}
  >
    <Stack spacing={Spacing.XL}>
      <ul>
        <li>
          Create collections for your teams and departments. Find more{' '}
          <OutwardLink href={documentationLinks.collections}>
            examples on using collections
          </OutwardLink>{' '}
          to make your work easier.
        </li>
        <li>
          <OutwardLink href={documentationLinks.collectionsPermissions}>
            Assign user role permissions for collections
          </OutwardLink>{' '}
          in the details of each user for better content governance.
        </li>
        {showSpaces && (
          <li>
            <OutwardLink href={documentationLinks.collectionsAndSpacesConnection}>
              Connect collections and spaces
            </OutwardLink>{' '}
            to level up your content access permission model.
          </li>
        )}
      </ul>
    </Stack>
  </Callout>
);

CollectionsQuickTip.displayName = 'CollectionsQuickTip';
