import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import {
  colorBackgroundLowEmphasis,
  colorBorderLowEmphasis,
} from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';

export const VerticalTabsSidebar = styled(Box).attrs({
  backgroundColor: colorBackgroundLowEmphasis,
  paddingX: Spacing.S,
  paddingY: Spacing.L,
  width: '100%',
})`
  border-right: 1px solid ${colorBorderLowEmphasis};
`;
