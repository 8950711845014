import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { ContentItemFilterWithContinuationServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { IListingFilter } from '../models/filter/IListingFilter.ts';
import { getOrderBy } from '../models/filter/contentItemFilterUtils.ts';
import { createContentItemFilterServerModelFromFilter } from './contentItemListModelConverters.ts';

export type BuildFilterForListingContentItemsParams = {
  continuationToken: string | null;
  filter: IListingFilter;
  includePublishedVersions: boolean;
  maxItemsCount: number | null;
  orderBy: OrderBy<ItemColumnCode> | null;
  useBackUpSearchMethod: boolean;
};

export function buildFilterWithContinuationForListingContentItems({
  continuationToken,
  filter,
  includePublishedVersions,
  maxItemsCount,
  orderBy,
  useBackUpSearchMethod,
}: BuildFilterForListingContentItemsParams): Readonly<ContentItemFilterWithContinuationServerModel> {
  const result: ContentItemFilterWithContinuationServerModel =
    createContentItemFilterServerModelFromFilter(filter);

  if (orderBy) {
    result.orderBy = getOrderBy(orderBy);
  }

  if (continuationToken) {
    result.continuationToken = continuationToken;
  }

  if (maxItemsCount !== null) {
    result.maxItemsCount = maxItemsCount;
  }

  result.useBackUpSearchMethod = useBackUpSearchMethod;
  result.includePublishedVersions = includePublishedVersions;

  return result;
}
