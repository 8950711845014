import React from 'react';
import styled, { css } from 'styled-components';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { CheckboxStylingProps, checkboxPadding, getCheckboxTokens } from '../utils/stylingUtils.ts';
import { CheckedIcon } from './CheckedIcon.tsx';
import { IndeterminateIcon } from './IndeterminateIcon.tsx';
import { StyledCheckboxWrapper } from './StyledCheckboxWrapper.tsx';

type CheckboxSymbolProps = CheckboxStylingProps;

const ProtectiveArea = styled.span.attrs(getCheckboxTokens)<CheckboxSymbolProps>`
  display: block;
  padding: ${px(checkboxPadding)};
  cursor: ${({ checkboxState }) => (checkboxState !== 'disabled' ? 'pointer' : 'not-allowed')};
`;

const IconWrapper = styled.span.attrs(getCheckboxTokens)<CheckboxSymbolProps>`
  ${({
    iconBackgroundColor,
    iconBackgroundColorHover,
    iconBorderColor,
    iconBorderColorHover,
    iconColor,
    iconColorHover,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${px(Spacing.L)};
    height: ${px(Spacing.L)};
    border-radius: ${px(BorderRadius.S)};
    border: ${px(borderWidthDefault)} solid ${iconBorderColor};

    background-color: ${iconBackgroundColor};
    color: ${iconColor};
    transition: background-color ${transition250}, border-color ${transition250};

    ${StyledCheckboxWrapper}:hover && {
        border-color: ${iconBorderColorHover};
        background-color: ${iconBackgroundColorHover};
        color: ${iconColorHover};
      }
  `};
`;

export const CheckboxSymbol: React.FC<CheckboxSymbolProps> = (props) => {
  const { checkboxStatus } = props;

  return (
    <ProtectiveArea {...props}>
      <IconWrapper {...props}>
        {checkboxStatus === 'indeterminate' && <IndeterminateIcon />}
        {checkboxStatus === 'checked' && <CheckedIcon />}
      </IconWrapper>
    </ProtectiveArea>
  );
};

CheckboxSymbol.displayName = 'CheckboxSymbol';
