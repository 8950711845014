import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IUrlSlugTypeElementData } from '../../../models/elements/UrlSlugTypeElementData.ts';
import { getUrlSlugSelectedOption } from '../../../selectors/contentTypeElementSelector.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { UrlSlugTypeElementValidationResult } from '../../../utils/typeElementValidators/types/UrlSlugTypeElementValidationResult.type.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';
import { getTypeElementRegexValidationResult } from './validateTypeElementRegex.ts';

function getUrlSlugTypeElementValidationResult(
  deps: ITypeElementValidatorDependencies,
  typeElement: IUrlSlugTypeElementData,
  hasSelectedOption: boolean,
  conditionValidationData: ConditionValidationData,
): UrlSlugTypeElementValidationResult {
  const { getNameValidationResult, getGuidelinesValidationResult } =
    deps.typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);
  const errors: Array<string> = [];
  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errors.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errors.push(guidelinesValidationResult.errorMessage);
  }

  const displayDependedOnTextElementError =
    !typeElement._canBeGeneratedFromDeprecatedItemName && !hasSelectedOption;

  if (displayDependedOnTextElementError) {
    errors.push('Select a text element or create a new one to generate URL slug.');
  }

  const regexValidationResult = getTypeElementRegexValidationResult(
    deps,
    typeElement.validationRegex,
  );

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isMaxCharsValid: true,
    isDependedOnValidTextElement: !displayDependedOnTextElementError,
    isRegexPatternValid: regexValidationResult.isRegexPatternValid,
    isRegexLengthValid: regexValidationResult.isRegexLengthValid,
    isRegexValidationMessageLengthValid: regexValidationResult.isRegexValidationMessageLengthValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errors,
      ...regexValidationResult.errorMessages,
    ],
  };
}

export const createValidateUrlSlugTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IUrlSlugTypeElementData): ThunkFunction<UrlSlugTypeElementValidationResult> =>
  (dispatch, getState) => {
    const typeElements = getState().contentModelsApp.typeEditor.editedType.typeElements;
    const snippets = getState().data.snippets;

    const selectedOption = getUrlSlugSelectedOption(typeElement, typeElements, snippets);
    const validationResult = getUrlSlugTypeElementValidationResult(
      deps,
      typeElement,
      !!selectedOption,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
