import { isAbortError } from '@kontent-ai/errors';
import { notNull } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ApiKeyType, DapiKeyTypes } from '../../../../../_shared/models/ApiKeyType.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKeysSettings_Init_Failed,
  ApiKeysSettings_Init_Finished,
  ApiKeysSettings_Init_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { createApiKeyFilterServerModel, emptyApiKeyFilter } from '../../models/ApiKeyFilter.ts';
import { createApiKeyListingDataFromServerModel } from '../../models/ApiKeyListingData.ts';

const started = () =>
  ({
    type: ApiKeysSettings_Init_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeysSettings_Init_Failed,
  }) as const;

const success = (hasAnyDapiKeys: boolean, hasAnyMapiKeys: boolean) =>
  ({
    type: ApiKeysSettings_Init_Finished,
    payload: { hasAnyDapiKeys, hasAnyMapiKeys },
  }) as const;

interface ILoadApiKeysSettingsDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'getApiKeyListing'>;
}

export type LoadApiKeysSettingsActionsType = ReturnType<
  typeof started | typeof failed | typeof success
>;

export const loadApiKeysSettingsDataCreator =
  (dependencies: ILoadApiKeysSettingsDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());
      const filterModel = createApiKeyFilterServerModel(emptyApiKeyFilter, [
        ...DapiKeyTypes,
        ApiKeyType.MAPI,
      ]);
      const serverModel = await dependencies.apiKeysRepository.getApiKeyListing(
        filterModel,
        abortSignal,
      );

      const apiKeyListingData = serverModel
        .map(createApiKeyListingDataFromServerModel)
        .filter(notNull);

      const dapiKeyListingData = apiKeyListingData.filter((d) => DapiKeyTypes.includes(d.type));
      const mapiKeyListingData = apiKeyListingData.filter((d) => d.type === ApiKeyType.MAPI);
      const hasAnyDapiKeys = dapiKeyListingData.length > 0;
      const hasAnyMapiKeys = mapiKeyListingData.length > 0;

      dispatch(success(hasAnyDapiKeys, hasAnyMapiKeys));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
