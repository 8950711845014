import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { LocalizedRouteLeft } from '../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { Calendar_Page_Left } from '../../../../contentPlanning/calendar/constants/calendarActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { Data_ContentStatusContentItems_Started } from '../../constants/contentStatusActionTypes.ts';

const initialState: Uuid = '';

export const raceConditionToken = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Data_ContentStatusContentItems_Started:
      return action.payload.raceConditionToken;

    case Shared_CurrentProjectId_Updated:
    case LocalizedRouteLeft:
    case Calendar_Page_Left:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
      return initialState;

    default:
      return state;
  }
};
