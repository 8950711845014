import { AuthOptions, AuthorizeOptions, WebAuth } from 'auth0-js';
import { detect } from 'detect-browser';
import { logEventToPardot } from '../../../../../views/welcome/scripts/actions/thunks/logEventToPardot.ts';
import { PardotEventTypes } from '../../../../../views/welcome/scripts/constants/pardotEventTypes.ts';
import { accessTokenStorage } from '../../../localStorages/accessTokenStorage.ts';
import { auth0RedirectUriStorage } from '../../../localStorages/auth0RedirectUriStorage.ts';
import { oAuthIntegrationNameStorage } from '../../../localStorages/oAuthIntegrationStorage.ts';
import { getMilliseconds } from '../dateTime/timeUtils.ts';
import { logError } from '../logError.ts';
import { getRedirectUri } from '../routing/queryParamsUtils.ts';
import { getUrlFactory } from '../urlFactory.ts';
import { parseJwtExpiresAt } from './accessTokenUtils.ts';

const auth0ConnectionDbName = 'Username-Password-Authentication';
const fiveMinutesInSeconds = 60 * 5;

const auth0Config: AuthOptions = {
  audience: self._envConfig.auth0ApiIdentifier,
  clientID: self._envConfig.auth0ClientId,
  domain: self._envConfig.auth0Domain,
  leeway: fiveMinutesInSeconds,
  redirectUri: `${self.location.origin}/callback`,
  responseType: 'token id_token',
};

export const getWebAuth = (function () {
  let webAuth: WebAuth | null = null;

  return (): WebAuth => {
    webAuth ??= new WebAuth(auth0Config);
    return webAuth;
  };
})();

export type CustomAuthorizeOptions = {
  'ext-signup'?: boolean;
};

function authorize(signUp: boolean) {
  const options: CustomAuthorizeOptions & AuthorizeOptions = {};

  if (signUp) {
    options.screen_hint = 'signup';
    options['ext-signup'] = true;
  }

  getWebAuth().authorize(options);
}

export function goToLogin(): void {
  auth0RedirectUriStorage.save(window.location.href);
  authorize(false);
}

export function goToLoginAfterLogout(): void {
  authorize(false);
}

export function goToSignUp(): void {
  authorize(true);
}

export function goToLearnSignUp(): void {
  const redirectUri = getUrlFactory().getVerifyEmailPageUrl(getRedirectUri(location));
  auth0RedirectUriStorage.save(redirectUri);
  authorize(true);
}

const browser = detect();
export const isEnvironmentThatBlocksCheckSession =
  process.env.NODE_ENV !== 'production' &&
  (browser?.name === 'firefox' || browser?.name === 'safari');

export function isTokenCloseToExpiration(accessToken: AuthToken): boolean {
  const expiresAt = parseJwtExpiresAt(accessToken);
  const thresholdBeforeExpirationInMs = getMilliseconds({ seconds: 10 });
  const thresholdTime = expiresAt.getTime() - thresholdBeforeExpirationInMs;
  return new Date().getTime() >= thresholdTime;
}

export function processAuth0SingInCallback(): void {
  getWebAuth().parseHash((err, authResult) => {
    if (authResult?.idToken) {
      if (isEnvironmentThatBlocksCheckSession) {
        if (authResult.accessToken) {
          accessTokenStorage.save(authResult.accessToken);
        }
      }

      const redirectUriFromStorage = auth0RedirectUriStorage.load();
      auth0RedirectUriStorage.forget();

      (async () => {
        if (authResult.idTokenPayload[`${self._envConfig.auth0ClaimNamespace}/user-state`]) {
          await logEventToPardot(PardotEventTypes.SignIn, {
            email: authResult.idTokenPayload.email,
          });
        }
        const redirectUri = redirectUriFromStorage
          ? redirectUriFromStorage
          : getUrlFactory().getDraftUiRootUrl();
        window.location.replace(redirectUri);
      })();
    } else if (err || (!err && !authResult)) {
      goToDraftRoot();
    } else {
      logError('Unexpected Auth0 login handler behavior.');
    }
  });
}

export async function requestChangePassword(email: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    getWebAuth().changePassword(
      {
        connection: auth0ConnectionDbName,
        email,
      },
      (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      },
    );
  });
}

export function logout(): void {
  getWebAuth().logout({
    returnTo: getUrlFactory().getDraftLogoutCallbackUrl(),
    client_id: auth0Config.clientID,
  } as any);
}

export function finishRegistration(): void {
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  const accessToken = urlParams.get('token');
  const integrationName = urlParams.get('integration');
  if (state && accessToken && integrationName) {
    oAuthIntegrationNameStorage.save(integrationName);
    const continueUri = getUrlFactory().getAuth0SignInContinueUrl(state);
    goToEmailVerifyPage(continueUri, accessToken);
  } else {
    goToDraftRoot();
  }
}

function goToEmailVerifyPage(continueUri: string, accessToken: AuthToken): void {
  if (isEnvironmentThatBlocksCheckSession) {
    accessTokenStorage.save(accessToken);
  }
  const redirectUri = getUrlFactory().getVerifyEmailPageUrl(continueUri);
  window.location.replace(redirectUri);
}

function goToDraftRoot() {
  window.location.replace(getUrlFactory().getDraftUiRootUrl());
}
