import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { UserFeedbackTimestamps } from '../../../data/models/user/UserFeedbackTimestamps.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { UserPropertyServerKey } from '../../models/UserPropertiesModel.ts';
import { UmuxSurveyEventTypes } from '../../models/events/UmuxSurveyEventData.type.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { upsertUserProperty } from '../thunkSharedActions.ts';
import { trackUserEventWithData } from './trackUserEvent.ts';

export const showUserFeedback =
  (
    source: UserFeedbackSource,
    propertyServerKey: UserPropertyServerKey,
    feedbackTimestamps: UserFeedbackTimestamps,
  ): ThunkPromise =>
  async (dispatch) => {
    const payload: UserFeedbackTimestamps = {
      ...feedbackTimestamps,
      displayedAt: new Date().toISOString(),
    };
    const userFeedbackString = JSON.stringify(payload);

    dispatch(
      trackUserEventWithData(TrackedEvent.UmuxSurvey, {
        action: UmuxSurveyEventTypes.AutomaticallyShown,
        source,
      }),
    );

    await dispatch(upsertUserProperty(propertyServerKey, userFeedbackString));
  };
