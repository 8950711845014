import { assert } from '@kontent-ai/utils';
import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../../@types/Dispatcher.type.ts';
import { ICreateNewContentItemAction } from '../../../itemEditor/features/NewContentItem/actions/thunks/createNewContentItem.ts';
import { CollectionSection } from '../../../itemEditor/features/NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../itemEditor/features/NewContentItem/constants/contentTypeSection.ts';
import { getNewContentItemForm } from '../../../itemEditor/features/NewContentItem/selectors/newContentItemDialog.ts';
import {
  WebSpotlight_CreatePageItem_Started,
  WebSpotlight_NewNode_Linked,
} from '../../constants/webSpotlightActionTypes.ts';
import { getNodeIdFromPath } from '../../utils/webSpotlightUtils.ts';
import { ILinkPageItemsAndReloadAction } from './linkPageItemsAndReload.ts';

interface IDeps {
  readonly createNewContentItem: ICreateNewContentItemAction;
  readonly linkPageItemsAndReload: ILinkPageItemsAndReloadAction;
  readonly trackItemCreated: (
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
  ) => ThunkFunction;
}

export interface ICreateAndLinkContentItemActionParams {
  readonly contentCollectionId?: Uuid | undefined;
  readonly contentTypeId?: Uuid | undefined;
  readonly itemId: Uuid;
  readonly parentPath: string;
  readonly subpagesElementId: Uuid;
  readonly variantId: Uuid;
}

const createPageItemStarted = {
  type: WebSpotlight_CreatePageItem_Started,
} as const;

const newNodeLinked = () =>
  ({
    type: WebSpotlight_NewNode_Linked,
  }) as const;

export type CreateAndLinkContentItemActionTypes =
  | ReturnType<typeof newNodeLinked>
  | typeof createPageItemStarted;

export const createAndLinkContentItemActionCreator =
  (deps: IDeps) =>
  ({
    contentTypeId,
    contentCollectionId,
    itemId,
    parentPath,
    subpagesElementId,
    variantId,
  }: ICreateAndLinkContentItemActionParams): ThunkPromise<Uuid> =>
  async (dispatch: Dispatch, getState: GetState): Promise<Uuid> => {
    const {
      collectionId: selectedCollectionId,
      collectionSection: selectedCollectionSection,
      contentTypeId: selectedContentTypeId,
      contentTypeSection: selectedContentTypeSection,
    } = getNewContentItemForm(getState());

    const collectionId = contentCollectionId || selectedCollectionId;
    const typeId = contentTypeId || selectedContentTypeId;

    assert(
      collectionId,
      () => `${__filename}: Collection must be defined for direct item creation`,
    );
    assert(typeId, () => `${__filename}: Content type must be defined for direct item creation`);

    dispatch(createPageItemStarted);
    dispatch(deps.trackItemCreated(selectedContentTypeSection, selectedCollectionSection));

    const createdItem = await dispatch(
      deps.createNewContentItem({
        typeId,
        collectionId,
      }),
    );
    const createdItemId = createdItem.item.id;

    const parentNodeId = getNodeIdFromPath(parentPath);
    await dispatch(
      deps.linkPageItemsAndReload(itemId, variantId, subpagesElementId, parentNodeId, [
        createdItemId,
      ]),
    );
    dispatch(newNodeLinked());
    return createdItemId;
  };
