import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { Task } from '../../../../_shared/models/Task.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentStatusTasksTableRow } from '../containers/ContentStatusTasksTableRow.tsx';

type ContentStatusTasksTableProps = Readonly<{
  isLoading: boolean;
  itemId: Uuid;
  tasks: ReadonlyArray<Task>;
  variantId: Uuid;
}>;

export const ContentStatusTasksTable: React.FC<ContentStatusTasksTableProps> = ({
  isLoading,
  itemId,
  tasks,
  variantId,
}) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <DataTable
      dataUiCollectionName={DataUiCollection.ContentTasks}
      header={<DataTableHeadRow columns={tasksTableHeadColumns} />}
    >
      {tasks.map((task) => (
        <ContentStatusTasksTableRow
          key={task.id}
          task={task}
          itemId={itemId}
          variantId={variantId}
        />
      ))}
    </DataTable>
  );
};

ContentStatusTasksTable.displayName = 'ContentStatusTasksTable';

const tasksTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Task',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-15',
  },
  {
    columnName: 'Due date',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: 'Assigned to',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
]);
