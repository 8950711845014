import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentTypeSnippet_Editor_MultipleChoiceOptionDropped,
  ContentTypeSnippet_Editor_MultipleChoiceOptionPickedUp,
} from '../../constants/snippetActionTypes.ts';

const initialState = '';

export function draggedMultipleChoiceOptionId(state: Uuid = initialState, action: Action): Uuid {
  switch (action.type) {
    case ContentTypeSnippet_Editor_MultipleChoiceOptionPickedUp: {
      return action.payload.optionId;
    }

    case ContentTypeSnippet_Editor_MultipleChoiceOptionDropped: {
      return initialState;
    }

    default:
      return state;
  }
}
