import React from 'react';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { AssetFolder } from '../../containers/AssetFolders/AssetFolder.tsx';
import { AssetFolderInAssetSelector } from '../../containers/AssetFolders/AssetFolderInAssetSelector.tsx';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

type Props = {
  readonly clearAssetSelection: () => void;
  readonly folders: ReadonlyArray<ITaxonomyTerm>;
  readonly isAssetSelectorOpen: boolean;
  readonly onRename: ((folder: ITaxonomyTerm) => void) | null;
  readonly orderBy: AssetsOrderBy;
};

export const AssetFolders: React.FC<Props> = ({
  clearAssetSelection,
  folders,
  isAssetSelectorOpen,
  onRename,
  orderBy,
}) => (
  <>
    {folders.map((folder) =>
      isAssetSelectorOpen ? (
        <AssetFolderInAssetSelector
          clearAssetSelection={clearAssetSelection}
          key={folder.id}
          assetFolder={folder}
          orderBy={orderBy}
        />
      ) : (
        <AssetFolder
          clearAssetSelection={clearAssetSelection}
          key={folder.id}
          assetFolder={folder}
          onRename={onRename ? () => onRename(folder) : undefined}
        />
      ),
    )}
  </>
);
