import { useSliderThumb } from '@react-aria/slider';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { SliderState } from '@react-stately/slider';
import { FocusableProps } from '@react-types/shared';
import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import {
  sliderThumbBackground,
  sliderThumbBackgroundDisabled,
  sliderThumbBackgroundHover,
  sliderThumbSize,
  sliderThumbSizeActive,
} from '../tokens.ts';

interface IStyledThumbProps {
  readonly $disabled?: boolean;
  readonly $isFocusVisible: boolean;
  readonly $thumbPercentage: number;
}

const thumbInteractiveStyles = css`
  transform: scale(${sliderThumbSizeActive / sliderThumbSize});
  background-color: ${sliderThumbBackgroundHover};
`;

const StyledThumb = styled.div.attrs<IStyledThumbProps>(({ $thumbPercentage }) => ({
  style: {
    left: `${$thumbPercentage * 100}%`,
  },
}))<IStyledThumbProps>`
  position: absolute;
  top: 50%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${px(sliderThumbSizeActive)};
  height: ${px(sliderThumbSizeActive)};
  border-radius: ${px(BorderRadius.Pill)};
  
  // focus ring
  &:before {
    content: '';
    position: absolute;
    inset: -2px;
    
    display: block;
    border-radius: inherit;
    
    ${shadowFocusStyles};
    opacity: 0;
    transition: opacity ${transition250};
  }

  ${({ $isFocusVisible }) =>
    $isFocusVisible &&
    css`
    &:before {
      opacity: 1;
    }
  `};
`;

interface IStyledThumbIndicatorProps {
  readonly $disabled?: boolean;
  readonly $isDragging: boolean;
  readonly $isFocusVisible: boolean;
}

const StyledThumbIndicator = styled.div<IStyledThumbIndicatorProps>`
  position: relative;
  width: ${px(sliderThumbSize)};
  height: ${px(sliderThumbSize)};
  border-radius: inherit;
  
  background-color: ${({ $disabled }) =>
    $disabled ? sliderThumbBackgroundDisabled : sliderThumbBackground};
  transition: transform ${transition250}, background-color ${transition250};
  
  ${({ $disabled }) =>
    !$disabled &&
    css`
    // && to force styled-components to use dynamic className instead of the static one
    ${StyledThumb}:hover && {
      ${thumbInteractiveStyles};
    }
  `};
  
  ${({ $isDragging, $isFocusVisible }) =>
    ($isDragging || $isFocusVisible) && thumbInteractiveStyles};
`;

interface IThumbProps extends ReturnType<typeof useOurFocusRing>, FocusableProps {
  readonly disabled?: boolean;
  readonly state: SliderState;
  readonly trackRef: RefObject<HTMLDivElement>;
  readonly index?: number;
}

export const Thumb = React.forwardRef<HTMLDivElement, IThumbProps>(
  (
    { disabled, focusProps, index = 0, isFocusVisible, state, trackRef, ...otherProps },
    forwardedRef,
  ) => {
    const inputRef = React.useRef(null);
    const { thumbProps, inputProps } = useSliderThumb(
      {
        ...otherProps,
        index,
        trackRef,
        inputRef,
        isDisabled: disabled,
      },
      state,
    );

    return (
      <StyledThumb
        $disabled={disabled}
        $isFocusVisible={isFocusVisible}
        $thumbPercentage={state.getThumbPercent(index)}
        ref={forwardedRef}
        {...thumbProps}
      >
        <StyledThumbIndicator
          $disabled={disabled}
          $isDragging={state.isThumbDragging(index)}
          $isFocusVisible={isFocusVisible}
        >
          <VisuallyHidden>
            <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
          </VisuallyHidden>
        </StyledThumbIndicator>
      </StyledThumb>
    );
  },
);

Thumb.displayName = 'Thumb';
