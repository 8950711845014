import { BaseColor, BorderRadius, Size, Spacing, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderWorkflowStepColor } from '../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { IWorkflowStepOverview } from '../utils/createWorkflowOverview.ts';

interface IWorkflowStepOverviewBarProps {
  readonly onClick: (() => void) | undefined;
  readonly step: IWorkflowStepOverview;
  readonly total: number;
}

const StyledWorkflowOverviewBarRow = styled.div`
  height: ${px(Size.S)};
  margin-right: ${px(Spacing.S)};
  padding: ${px(Spacing.XS)};
`;

type StyledWorkflowOverviewBarProps = {
  readonly $width: string;
  readonly $background: BaseColor;
};

const StyledWorkflowOverviewBar = styled.div.attrs<StyledWorkflowOverviewBarProps>(
  ({ $width, $background }) => ({
    style: {
      width: $width,
      backgroundColor: $background.toString(),
    },
  }),
)<StyledWorkflowOverviewBarProps>`
  display: inline-block;
  height: 100%;
  border-radius: ${px(BorderRadius.M)};
  cursor: pointer;
`;

export const WorkflowStepOverviewBar: React.FC<IWorkflowStepOverviewBarProps> = ({
  step,
  total,
  onClick,
}) => (
  <StyledWorkflowOverviewBarRow>
    <StyledWorkflowOverviewBar
      $width={`${(step.count / total) * 100}%`}
      $background={renderWorkflowStepColor(step.color)}
      onClick={onClick}
      {...getDataUiActionAttribute(DataUiAction.WorkflowStepOverviewBarItem)}
    />
  </StyledWorkflowOverviewBarRow>
);
WorkflowStepOverviewBar.displayName = 'WorkflowStepOverviewBar';
