import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';

export const YourTasksEmptyState: React.FC = () => (
  <EmptyState>
    <EmptyState.Title>
      <span role="img" aria-label="Sun emoticon">
        &#9728;&#65039;
      </span>
      Enjoy your day!
    </EmptyState.Title>
    <EmptyState.Content>
      You don’t have any tasks assigned to you at this moment.
    </EmptyState.Content>
  </EmptyState>
);

YourTasksEmptyState.displayName = 'YourTasksEmptyState';
