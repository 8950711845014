import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { loadMoreTimelineItems } from '../../Timeline/actions/thunkTimelineActions.ts';
import {
  RevisionTimelineList as RevisionTimelineListComponent,
  RevisionTimelineListOwnProps,
} from '../components/RevisionTimelineList.tsx';

export const RevisionTimelineList: React.FC<RevisionTimelineListOwnProps> = (props) => {
  const variantTimeline = useSelector((s) => s.contentApp.entryTimeline);
  const workflows = useSelector((s) => s.data.workflows.byId);
  const projectContributors = useSelector((s) => s.data.users.usersById);
  const currentUserId = useSelector((s) => getCurrentUser(s).userId);
  const dispatch = useDispatch();
  const onLoadMoreItems = () => dispatch(loadMoreTimelineItems());
  const isLoadMoreItemsInProgress = useSelector(
    (s) => s.contentApp.isLoadMoreTimelineItemsInProgress,
  );
  const renderMoreItemsButton = useSelector((s) => !!s.contentApp.timelineContinuationToken);

  return (
    <RevisionTimelineListComponent
      variantTimeline={variantTimeline}
      workflows={workflows}
      projectContributors={projectContributors}
      currentUserId={currentUserId}
      paths={props.paths}
      renderMoreItemsButton={() =>
        renderMoreItemsButton ? (
          <Box display="flex" justifyContent="center" paddingY={Spacing.XL}>
            <Button
              disabled={isLoadMoreItemsInProgress}
              buttonStyle="tertiary"
              onClick={onLoadMoreItems}
            >
              {isLoadMoreItemsInProgress && <Button.ProgressIcon />}
              Load older versions
            </Button>
          </Box>
        ) : null
      }
    />
  );
};

RevisionTimelineList.displayName = 'RevisionTimelineList';
