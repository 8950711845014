import { BaseColor, Color } from '../../../../tokens/quarks/colors.ts';

export const getSvgMainCircleFillColor = (
  isDisabled: boolean,
  isIconHovered: boolean,
  isLabelHovered: boolean,
  colorPrimaryHoverInverse: Color,
  backgroundColor: Color,
) => {
  if (isDisabled) {
    return backgroundColor;
  }

  if (isIconHovered) {
    return colorPrimaryHoverInverse;
  }

  if (isLabelHovered) {
    return BaseColor.White;
  }

  return backgroundColor;
};
