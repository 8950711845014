import { createGuid, groupBy } from '@kontent-ai/utils';
import { IPreviewConfigurationServerModel } from '../../../repositories/serverModels/IPreviewConfigurationServerModel.type.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import { IPreviewConfiguration, ISpaceDomain } from '../models/PreviewConfiguration.type.ts';
import { anySpacesOptionId } from './getSpacesOptions.ts';

export const getPreviewConfigurationFromServerModel = (
  serverModel: IPreviewConfigurationServerModel,
  originalPreviewConfiguration: IPreviewConfiguration | null,
  insertAnyOption: boolean,
): IPreviewConfiguration => {
  if (typeof serverModel !== 'object') {
    throw new Error('Preview url record is not an object');
  }

  const previewUrlPatternsEntries = Object.entries(serverModel.previewUrlPatterns ?? {}).map(
    ([contentTypeId, patterns]): [Uuid, ReadonlyArray<IPreviewUrlPattern>] => {
      const previewUrlPatterns =
        originalPreviewConfiguration?.previewUrlPatterns.get(contentTypeId);

      return [
        contentTypeId,
        Array.from(groupBy(patterns, (pattern) => pattern.urlPattern).entries()).map(
          ([urlPattern, spacesForPattern], index): IPreviewUrlPattern => {
            const spaceIds = spacesForPattern.map(({ spaceId }) => spaceId);
            const enabled = spacesForPattern.some((p) => p.enabled);
            const rowId = previewUrlPatterns?.[index]?.rowId ?? createGuid();

            return {
              rowId,
              urlPattern,
              enabled,
              spaces: spaceIds.includes(null)
                ? new Set(insertAnyOption ? [anySpacesOptionId] : [])
                : new Set(spaceIds as ReadonlyArray<Uuid>),
            };
          },
        ),
      ];
    },
  );

  const spaceDomains = (serverModel.spaceDomains ?? []).map(
    (space): ISpaceDomain => ({
      spaceId: space.spaceId,
      domain: space.domain,
    }),
  );

  return {
    previewUrlPatterns: new Map(previewUrlPatternsEntries),
    spaceDomains,
  };
};
