import React from 'react';
import { IAssetTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import { IAssetItemElement } from '../../../models/contentItemElements/AssetItemElement.ts';
import { AssetDiff } from '../../Revisions/components/elements/subelements/AssetDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedAssetElementProps = ExpandedItemElementDataProps<IAssetTypeElement, IAssetItemElement>;

export const ExpandedAssetElement: React.FC<ExpandedAssetElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => (
  <ExpandedItemElement
    className={className}
    disabled
    hideValidationStatus
    typeElement={typeElement}
  >
    <AssetDiff
      elementData={elementData}
      originalElementData={originalElementData}
      typeElement={typeElement}
    />
  </ExpandedItemElement>
);

ExpandedAssetElement.displayName = 'ExpandedAssetElement';
