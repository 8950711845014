import { ElementAttributes } from '../../../applications/itemEditor/constants/elementAttributes.ts';
import { DataDraftJsAttributes } from './DataDraftJsAttributes.ts';
import { DataUiAttributes, ObjectWithDataAttribute } from './DataUiAttributes.ts';

export enum DataAttributes {
  Badge = 'data-badge',
  RteColumnIndex = 'data-rte-column-index',
  Rate = 'data-rate',
  CommentsOnRemovedContentHeader = 'data-comments-on-removed-content-header',
  CommentThreadId = 'data-comment-thread-id',
}

export type DataAttributeName =
  | DataUiAttributes
  | DataAttributes
  | DataDraftJsAttributes
  | ElementAttributes;

export const getDataAttribute = (
  name: DataAttributeName,
  value: string | number | undefined,
): ObjectWithDataAttribute => (value ? { [name]: value } : {});
