import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IValidationResult } from '../../../../../../_shared/utils/validation/ValidationResult.ts';
import { IContentItemWithVariant } from '../../../../models/contentItem/ContentItemWithVariant.ts';
import { getItemValidationResult } from '../../../../utils/getItemValidationResult.ts';
import { ContentItemEditing_LinkedItemVariantElementsRevalidationFinished } from '../../constants/contentItemEditingActionTypes.ts';

interface ILinkedItemVariantElementsRevalidationFinishedPayload {
  readonly contentItem: IContentItemWithVariant;
  readonly itemValidationResult: IValidationResult;
}

const linkedItemVariantElementsRevalidationFinished = (
  payload: ILinkedItemVariantElementsRevalidationFinishedPayload,
) =>
  ({
    type: ContentItemEditing_LinkedItemVariantElementsRevalidationFinished,
    payload,
  }) as const;

export type RevalidateLinkedItemVariantElementsActionsType = ReturnType<
  typeof linkedItemVariantElementsRevalidationFinished
>;

export const createRevalidateLinkedItemVariantElementsAction =
  () =>
  (contentItem: IContentItemWithVariant, contentTypeId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: { loadedContentItemTypes },
    } = state;

    if (!contentItem || !contentItem.variant) {
      return;
    }

    const contentItemType = loadedContentItemTypes.get(contentTypeId);
    if (!contentItemType) {
      return;
    }

    const itemValidationResult = getItemValidationResult(
      contentItem.variant.id.itemId,
      contentItem.variant.elements,
      contentItemType.contentElements,
      state,
    );

    dispatch(
      linkedItemVariantElementsRevalidationFinished({
        contentItem,
        itemValidationResult,
      }),
    );
  };
