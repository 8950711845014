import React from 'react';
import { Controller } from 'react-hook-form';
import { HookFormProps } from '../../../../../../../../_shared/types/hookFormProps.type.ts';
import { IProjectContributor } from '../../../../../../../../data/models/users/ProjectContributor.ts';
import { IContributorsFormShape } from '../../../../models/IContributorsFormShape.type.ts';
import { FormPane } from '../../FormPane.tsx';
import { ContributorsSectionDescriptionField } from './ContributorsSectionDescription.tsx';
import { ContributorsSelectorField } from './ContributorsSelectorField.tsx';

type Props = {
  readonly activeContributors: ReadonlyArray<IProjectContributor>;
  readonly availableContributors: ReadonlyArray<IProjectContributor>;
  readonly currentUserId: UserId;
  readonly formProps: HookFormProps<IContributorsFormShape>;
  readonly isSectionSubmitting: boolean;
  readonly formRef: React.RefObject<HTMLFormElement>;
  readonly onReset: () => void;
  readonly onSubmit: () => void;
  readonly showAll: boolean;
  readonly workflowStepName: string;
};

export const ContributorsFormBase: React.FC<Props> = ({
  activeContributors,
  availableContributors,
  currentUserId,
  formProps,
  formRef,
  isSectionSubmitting,
  onReset,
  onSubmit,
  showAll,
  workflowStepName,
}) => (
  <FormPane onReset={onReset} onSubmit={onSubmit} ref={formRef}>
    <Controller
      control={formProps.control}
      name="contributors"
      render={({ field, fieldState }) => (
        <ContributorsSelectorField
          ref={field.ref}
          activeContributors={activeContributors}
          allAssignedContributors={field.value}
          availableContributors={availableContributors}
          currentUserId={currentUserId}
          disabled={isSectionSubmitting}
          error={fieldState.error}
          formProps={formProps}
          onChange={field.onChange}
          showAll={showAll}
        />
      )}
    />
    <Controller
      control={formProps.control}
      name="showAll"
      render={({ field }) => (
        <ContributorsSectionDescriptionField
          onChange={field.onChange}
          showAll={field.value}
          workflowStepName={workflowStepName}
        />
      )}
    />
  </FormPane>
);

ContributorsFormBase.displayName = 'ContributorsFormBase';
