import React from 'react';
import { FlexingTile } from './FlexingTile.tsx';

export const AssetSkelet: React.FC = () => (
  <FlexingTile>
    <div className="asset-thumbnail asset-thumbnail--is-in-asset-library asset-thumbnail--is-skelet">
      <div className="asset-thumbnail-skelet__summary">
        <span className="asset-thumbnail-skelet__text" />
        <span className="asset-thumbnail-skelet__text asset-thumbnail-skelet__text--is-short" />
      </div>
    </div>
  </FlexingTile>
);

AssetSkelet.displayName = 'AssetSkelet';
