import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isPublishingStepSelected } from '../../../../_shared/utils/contentItemUtils.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { getItemElementEqualityChecker } from '../../utils/getItemElementsEqualityChecker.ts';

export const shouldElementDispatch = (
  state: IStore,
  newElementData: ICompiledContentItemElementData,
  oldElementData: ICompiledContentItemElementData | null,
): boolean => {
  const editedVariant = state.contentApp.editedContentItemVariant;

  const isVariantInPublishingStep =
    editedVariant && isPublishingStepSelected(editedVariant.assignment);
  if (isVariantInPublishingStep) {
    return false;
  }

  return shouldElementDispatchIncludingPublishedStep(state, newElementData, oldElementData);
};

export const shouldElementDispatchIncludingPublishedStep = (
  state: IStore,
  newElementData: ICompiledContentItemElementData,
  oldElementData: ICompiledContentItemElementData | null,
): boolean => {
  if (state.contentApp.itemValidationErrors.get(newElementData.elementId)) {
    return false;
  }

  if (state.contentApp.editorUi.refreshedElementIds.contains(newElementData.elementId)) {
    return false;
  }

  if (!oldElementData) {
    return true;
  }

  const isElementValueTheSame = getItemElementEqualityChecker(newElementData.type);
  return !isElementValueTheSame(oldElementData, newElementData);
};
