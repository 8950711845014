import { HotkeysConfig, ShortcutsConfig } from '@kontent-ai/component-library/hooks';
import { EditorProps } from 'draft-js';
import React, { useCallback } from 'react';
import { DecoratedEditorProps } from '../../../editorCore/types/Editor.decorated.type.ts';
import { PluginState } from '../../../editorCore/types/Editor.plugins.type.ts';
import { ActiveFinishedInstructionHighlighter } from '../../../plugins/inlineAi/instructions/ActiveFinishedInstructionHighlighter.tsx';
import { ActiveInstructionContextProvider } from '../../../plugins/inlineAi/instructions/ActiveInstructionContext.tsx';
import { isNewAiInstruction } from '../../../plugins/inlineAi/utils/InstructionEntity.ts';
import { getActiveInstructionEntityKey } from '../../../plugins/inlineAi/utils/editorInlineAiUtils.ts';
import { InlineAiPlugin } from '../InlineAiPlugin.type.ts';

type EditorWithInlineAiProps = DecoratedEditorProps<
  InlineAiPlugin,
  {
    readonly activeFinishedInstructionSessionId: Uuid | null;
    readonly onSubmit: (entityKey: string) => void;
    readonly onEscape: () => Promise<boolean>;
  }
>;

export const EditorWithInlineAi: React.FC<EditorWithInlineAiProps> = ({
  activeFinishedInstructionSessionId,
  baseRender,
  onSubmit,
  onEscape,
  state,
}) => {
  const { handleReturn: parentHandleReturn } = state.editorProps;

  const selection = state.editorState.getSelection();
  const content = state.editorState.getCurrentContent();
  const activeInstructionEntityKey = selection.getHasFocus()
    ? getActiveInstructionEntityKey(content, selection, isNewAiInstruction)
    : null;

  const handleReturn: Required<EditorProps>['handleReturn'] = useCallback(
    (event, editorState) => {
      // Ignore editor return when we are within new instruction to make sure it finished correctly
      if (!event.shiftKey && !event.ctrlKey && activeInstructionEntityKey) {
        onSubmit(activeInstructionEntityKey);
        return 'handled';
      }

      return parentHandleReturn?.(event, editorState) ?? 'not-handled';
    },
    [parentHandleReturn, onSubmit, activeInstructionEntityKey],
  );

  const stateWithInstructions: PluginState<InlineAiPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      handleReturn,
    },
    getHotKeysHandlers: () => {
      const base = state.getHotKeysHandlers();

      return {
        ...base,
        [ShortcutsConfig.Escape]: (e, he) => {
          (async () => {
            if (!(await onEscape())) {
              base[ShortcutsConfig.Escape]?.(e, he);
            }
          })();
        },
      } satisfies HotkeysConfig;
    },
  };

  const hasFocus = state.editorState.getSelection().getHasFocus();

  return (
    <ActiveInstructionContextProvider activeInstructionEntityKey={activeInstructionEntityKey}>
      {baseRender(stateWithInstructions)}
      <ActiveFinishedInstructionHighlighter
        activeInstructionAiSessionId={hasFocus ? activeFinishedInstructionSessionId : null}
      />
    </ActiveInstructionContextProvider>
  );
};

EditorWithInlineAi.displayName = 'EditorWithInlineAi';
