import { memoize } from '@kontent-ai/memoization';
import { ILanguage, Languages } from '../../../../data/models/languages/Language.ts';
import { LanguageFallbackOption } from '../models/LanguageFallbackOption.type.ts';
import { OtherLanguage } from '../models/OtherLanguage.type.ts';

export const mapLanguageToOption = (language: ILanguage): LanguageFallbackOption => ({
  id: language.id,
  label: language.name,
  inactive: language.inactive,
});

const getLanguageOptions = <T>(
  languages: Languages,
  mapLanguageToOptionFunc: (defaultLanguage: ILanguage) => T,
  excludedLanguageId?: Uuid,
  defaultLanguage?: ILanguage,
): ReadonlyArray<T> => {
  const start: ReadonlyArray<T> = defaultLanguage ? [mapLanguageToOptionFunc(defaultLanguage)] : [];
  const transformedLanguages = excludedLanguageId
    ? languages.remove(excludedLanguageId)
    : languages;
  return [...start, ...transformedLanguages.toArray().map(mapLanguageToOptionFunc)];
};

const getOtherLanguageOptions = (
  languages: Languages,
  excludedLanguageId?: Uuid,
  defaultLanguage?: ILanguage,
): ReadonlyArray<OtherLanguage> => {
  return getLanguageOptions<OtherLanguage>(
    languages,
    (defaultLang) => defaultLang,
    excludedLanguageId,
    defaultLanguage,
  );
};

const getFallbackLanguageOptions = (
  languages: Languages,
  excludedLanguageId: Uuid,
  defaultLanguage: ILanguage,
): ReadonlyArray<LanguageFallbackOption> => {
  const options = getLanguageOptions<LanguageFallbackOption>(
    languages,
    mapLanguageToOption,
    excludedLanguageId,
    defaultLanguage,
  );

  return options.filter((language: LanguageFallbackOption) => !language.inactive);
};

const getOtherLanguageOptionsMemoized = memoize.allForever(getOtherLanguageOptions);
const getFallbackLanguageOptionsMemoized = memoize.allForever(getFallbackLanguageOptions);

export {
  getOtherLanguageOptionsMemoized as getOtherLanguageOptions,
  getFallbackLanguageOptionsMemoized as getFallbackLanguageOptions,
};
