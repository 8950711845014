import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { clearListingFilter } from '../../../itemEditor/actions/contentActions.ts';
import {
  CalendarEmptyState as CalendarEmptyStateComponent,
  ICalendarEmptyStateDispatchProps,
  ICalendarEmptyStateOwnProps,
} from '../components/CalendarEmptyState.tsx';

const mapDispatchToProps = (dispatch: Dispatch): ICalendarEmptyStateDispatchProps => ({
  onClearFilter: () => dispatch(clearListingFilter()),
});

export const CalendarEmptyState: React.ComponentType<
  React.PropsWithChildren<ICalendarEmptyStateOwnProps>
> = connect(null, mapDispatchToProps)(CalendarEmptyStateComponent);
