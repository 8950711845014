import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { InputState } from '@kontent-ai/component-library/Input';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useMemo, useRef } from 'react';
import { DialogState } from '../../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { IPopoverTargetProps } from '../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { usePopoverDialog } from '../../../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { addFlipping } from '../../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { IDialogAction } from '../../../../../../../component-library/components/Dialogs/components/DialogFooterAction.tsx';
import { HandleClickOutside } from '../../../../../../_shared/components/HandleClickOutside.tsx';
import { documentationLinks } from '../../../../../../_shared/constants/documentationLinks.ts';
import { useWorkflowSelection } from '../../../../../../_shared/hooks/WorkflowSelect/useWorkflowSelection.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  Popovers,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowIsRequiredWarning } from '../../../../../itemEditor/constants/warningMessageTemplates.ts';

interface IProps {
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onConvert: (selectedWorkflowId?: Uuid) => void;
  readonly renderTarget: (targetProps: IPopoverTargetProps) => React.ReactElement;
  readonly requireWorkflowSelection: boolean;
  readonly workflows: readonly WorkflowSelectorItem[];
  readonly workflowTooltipText?: string;
}

export type { IProps as IContentComponentConversionConfirmationDialogProps };

export type WorkflowSelectorItem = {
  readonly id: string;
  readonly name: string;
};

const CalloutMaxWidth = 480;

export const ContentComponentConversionConfirmationDialog: React.FC<IProps> = ({
  isOpen,
  onCancel,
  onConvert,
  renderTarget,
  requireWorkflowSelection,
  workflows,
  workflowTooltipText,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { disableSelection, selectedWorkflowId, setSelectedWorkflowId } = useWorkflowSelection(
    workflows,
    isOpen,
  );

  const selectableWorkflows = useMemo(
    () =>
      workflows.map(({ id, name }) => ({
        id,
        label: name,
      })),
    [workflows],
  );

  const { PopoverDialog, popoverDialogProps, targetProps } = usePopoverDialog({
    adjustTippyOptions: (tippyOptions) => addFlipping(tippyOptions),
    dialogState: DialogState.Default,
    popoverRef,
    headline: 'Want to reuse this elsewhere?',
    placement: 'top-end',
  });

  const workflowRequiredPrimaryAction = {
    disabled: !selectedWorkflowId,
    tooltipText: selectedWorkflowId ? undefined : WorkflowIsRequiredWarning,
    onClick: () => {
      if (selectedWorkflowId) {
        onConvert(selectedWorkflowId);
      }
    },
  };

  const defaultPrimaryAction = {
    onClick: () => onConvert(),
  };

  return (
    <HandleClickOutside observedRefs={popoverRef} onClickOutside={onCancel}>
      {renderTarget(targetProps)}
      {isOpen && (
        <PopoverDialog
          {...popoverDialogProps}
          isOpen={isOpen}
          onClose={onCancel}
          primaryAction={{
            text: 'Convert',
            ...getDataUiActionAttribute(DataUiAction.ConvertComponentToItemVariant),
            ...(requireWorkflowSelection ? workflowRequiredPrimaryAction : defaultPrimaryAction),
          }}
          cancelAction={{ ...getDataUiActionAttribute(DataUiAction.Cancel) } as IDialogAction}
          {...getDataUiElementAttribute(DataUiElement.Popover)}
          {...getDataUiObjectNameAttribute(Popovers.ContentComponentConversionConfirmationDialog)}
        >
          <Stack spacing={Spacing.XL}>
            {requireWorkflowSelection && (
              <SingleSelect
                items={selectableWorkflows}
                onSelectionChange={setSelectedWorkflowId}
                selectedItemId={selectedWorkflowId}
                inputState={disableSelection ? InputState.Disabled : undefined}
                label="Select a workflow"
                placeholder="Select a workflow"
                tooltipText={workflowTooltipText}
                verticalMenuDataAttributes={getDataUiCollectionAttribute(
                  DataUiCollection.Workflows,
                )}
                {...getDataUiCollectionAttribute(DataUiCollection.Workflows)}
              />
            )}
            <Callout calloutType="friendlyWarning" maxWidth={CalloutMaxWidth}>
              <p>
                This content exists only here. To reuse it elsewhere, convert it to an item, give it
                a proper name, and insert it in other items.
              </p>
              <p>
                The conversion keeps all your content, but <strong>cannot be undone</strong>.
              </p>
              <p>
                Find more about{' '}
                <OutwardLink href={documentationLinks.contentItems}>items</OutwardLink> and{' '}
                <OutwardLink href={documentationLinks.components}>components</OutwardLink>.
              </p>
            </Callout>
          </Stack>
        </PopoverDialog>
      )}
    </HandleClickOutside>
  );
};

ContentComponentConversionConfirmationDialog.displayName =
  'ContentComponentConversionConfirmationDialog';
