import { Action } from '../../../../@types/Action.type.ts';
import { RecentlyUsedContentTypesForEditing_Loaded } from '../../../../applications/itemEditor/features/NewContentItem/constants/newContentItemActionTypes.ts';
import { Shared_CurrentProjectId_Updated } from '../../../constants/sharedActionTypes.ts';

const initialState: ReadonlyArray<Uuid> | null = null;

export function contentTypesRecentlyUsedInEditing(
  state = initialState,
  action: Action,
): ReadonlyArray<Uuid> | null {
  switch (action.type) {
    case RecentlyUsedContentTypesForEditing_Loaded:
      return action.payload.recentlyUsedTypeIds;

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
