import React from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { YourContentTabName } from '../../../../_shared/constants/yourContentTabName.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentRecentlyEditedByYou as ContentRecentlyEditedByYouComponent } from '../../components/RecentlyEditedBy/ContentRecentlyEditedByYou.tsx';
import { decorateItemsRecentlyEditedByYou } from '../../utils/yourContentItemDecorationUtils.ts';

export const ContentRecentlyEditedByYou: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingFinished = useSelector(
    (s) =>
      s.data.contentTypes.loadingStatus === LoadingStatus.Loaded &&
      s.data.recentlyEditedContentItems.loadingStatus === LoadingStatus.Loaded &&
      s.data.languages.byIdLoadingStatus === LoadingStatus.Loaded,
  );

  const contentItems = useSelector((s) => s.data.recentlyEditedContentItems.items);
  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const languages = useSelector((s) => s.data.languages.byId);
  const viewableLanguages = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );

  const decoratedItems = loadingFinished
    ? decorateItemsRecentlyEditedByYou(contentItems, languages, viewableLanguages, contentTypes)
    : contentItems.toList();

  const onItemClick = (itemId: Uuid, index: number) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightContent, {
        index,
        itemId,
        name: FindRightContentTrackedEvent.OpenedItemEditingFromYourContent,
        tab: YourContentTabName.RecentlyEditedByYou,
      }),
    );

  return (
    <ContentRecentlyEditedByYouComponent
      loadingFinished={loadingFinished}
      contentItems={decoratedItems}
      currentPath={location.pathname}
      onItemClick={onItemClick}
    />
  );
};

ContentRecentlyEditedByYou.displayName = 'ContentRecentlyEditedByYou';
