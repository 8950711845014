import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { modalWithPropertiesOpened } from '../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemEditingEventTypes } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ContentItemEditingModalProperties } from '../../../../../../_shared/types/ModalDialogProperties.type.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';

type ActionType =
  | ContentItemEditingEventTypes.ChangeDueDateSelected
  | ContentItemEditingEventTypes.AssignContributorsSelected
  | ContentItemEditingEventTypes.NoteSelected
  | ContentItemEditingEventTypes.TasksSelected;

const sectionToAction: ReadonlyRecord<ContentItemSidebarEditableSection, ActionType> = {
  [ContentItemSidebarEditableSection.Contributors]:
    ContentItemEditingEventTypes.AssignContributorsSelected,
  [ContentItemSidebarEditableSection.DueDate]: ContentItemEditingEventTypes.ChangeDueDateSelected,
  [ContentItemSidebarEditableSection.Note]: ContentItemEditingEventTypes.NoteSelected,
  [ContentItemSidebarEditableSection.Tasks]: ContentItemEditingEventTypes.TasksSelected,
};

type IParams = {
  readonly defaultFocus: ContentItemSidebarEditableSection;
};

export const createOpenItemDetailsWithFocusedSection =
  ({ defaultFocus }: IParams) =>
  (
    origin: ContentItemEditingModalProperties['actionOrigin'],
    dialogType: ContentItemEditingModalProperties['dialogType'],
  ): ThunkFunction =>
  (dispatch): void => {
    dispatch(modalWithPropertiesOpened({ dialogType, actionOrigin: origin }));
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: sectionToAction[defaultFocus],
        origin,
      }),
    );
  };
