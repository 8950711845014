import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventData } from '../../../../../../_shared/models/TrackUserEvent.type.ts';

interface IEnableComparisonActionDependencies {
  trackUserEvent: TrackUserEventData;
  comparisonEnabled: () => Action;
}

export const enableComparisonActionCreator =
  ({ trackUserEvent, comparisonEnabled }: IEnableComparisonActionDependencies) =>
  (): ThunkFunction =>
  (dispatch) => {
    dispatch(trackUserEvent(TrackedEvent.ItemRevisionCompareOpened));
    dispatch(comparisonEnabled());
  };
