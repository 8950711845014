import { ConfirmationDialog } from '../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';

type CustomAppDeleteConfirmationModalProps = {
  readonly customAppName: string;
  readonly isOpen: boolean;
  readonly onConfirm: () => void;
  readonly onHideDeleteConfirmationModal: () => void;
};

export const CustomAppDeleteConfirmationModal = ({
  customAppName,
  isOpen,
  onConfirm,
  onHideDeleteConfirmationModal,
}: CustomAppDeleteConfirmationModalProps) => {
  return (
    <ConfirmationDialog
      alert
      confirmButtonText="Delete"
      headerContent={`Delete ${customAppName} app?`}
      isOpen={isOpen}
      onClose={onHideDeleteConfirmationModal}
      onConfirm={onConfirm}
    >
      <Warning hideTitle>
        <p>
          Deleting this app will remove it from the Kontent.ai and users will not be able to use it
          anymore.
        </p>
      </Warning>
    </ConfirmationDialog>
  );
};
