import React, { PropsWithChildren } from 'react';
import { tabbableProps } from '../utils.ts';
import { MenuOutwardLink, MenuOutwardLinkProps } from './MenuOutwardLink.tsx';

export const TabbableMenuOutwardLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<MenuOutwardLinkProps>
>((props, forwardedRef) => {
  return <MenuOutwardLink {...props} ref={forwardedRef} {...tabbableProps} />;
});
