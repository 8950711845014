import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import { DraftBlockRenderMap } from 'draft-js';
import React, { CSSProperties, MouseEvent, forwardRef, useCallback, useRef } from 'react';
import {
  SelectableContainerClassName,
  getBlockFromBlockElement,
  getBlockIdClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { isEmptyParagraph } from '../../../utils/blocks/blockTypeUtils.ts';
import { ApplyNestedBlockWrappers } from './ApplyNestedBlockWrappers.tsx';
import { TableColumnResizer } from './TableColumnResizer.tsx';

export interface ITableCellProps {
  readonly blockKey: string;
  readonly blockRenderMap: DraftBlockRenderMap;
  readonly children: ReadonlyArray<React.ReactElement<any>>;
  readonly columnIndex: number;
  readonly hasResizer: boolean;
  readonly onContextMenu?: (
    e: MouseEvent,
    blockKey: string,
    cellRef: React.RefObject<HTMLDivElement>,
  ) => void;
  readonly style?: CSSProperties;
}

export const TableCell = forwardRef<HTMLDivElement, ITableCellProps>((props, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { blockKey, blockRenderMap, children, columnIndex, hasResizer, onContextMenu, style } =
    props;

  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      if (onContextMenu) {
        onContextMenu(e, blockKey, contentRef);
      }
    },
    [blockKey, onContextMenu],
  );

  const lastContentBlockElement = Collection.getLast(children);
  const lastContentBlock =
    lastContentBlockElement && getBlockFromBlockElement(lastContentBlockElement);

  const isEmptyCell = children.length <= 1 && isEmptyParagraph(lastContentBlock);

  return (
    <div
      className={classNames(
        SelectableContainerClassName,
        'rte__table-cell',
        getBlockIdClassName(blockKey),
        { 'rte__table-cell--is-empty': isEmptyCell },
      )}
      ref={ref}
      onContextMenu={handleContextMenu}
      style={style}
    >
      <div className="rte__table-cell-content-wrapper">
        <div ref={contentRef} className="rte__table-cell-content">
          <ApplyNestedBlockWrappers blockRenderMap={blockRenderMap}>
            {children}
          </ApplyNestedBlockWrappers>
        </div>
        {hasResizer && <TableColumnResizer columnIndex={columnIndex} />}
      </div>
    </div>
  );
});

TableCell.displayName = 'TableCell';
