import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISpace } from '../../../../../data/models/space/space.ts';
import { ISpacesRepository } from '../../../../../repositories/interfaces/ISpacesRepository.type.ts';
import { IContentItemServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  ISpaceServerResponseModel,
  IUpdateSpaceRequestServerModel,
} from '../../../../../repositories/serverModels/SpacesServerModel.type.ts';
import {
  Spaces_Update_Failed,
  Spaces_Update_Started,
  Spaces_Update_Succeeded,
} from '../../constants/spacesActionTypes.ts';
import { SpacesActionFailedMessage } from '../../constants/spacesUiConstants.ts';

type Deps = {
  readonly ensureRootItemCreated: () => ThunkPromise<IContentItemServerModel>;
  readonly spacesRepository: Pick<ISpacesRepository, 'update'>;
};

const started = (space: ISpace) =>
  ({
    type: Spaces_Update_Started,
    payload: {
      space,
    },
  }) as const;

const updated = (space: ISpaceServerResponseModel) =>
  ({
    type: Spaces_Update_Succeeded,
    payload: {
      space,
    },
  }) as const;

const failed = () =>
  ({
    type: Spaces_Update_Failed,
    payload: {
      errorMessage: SpacesActionFailedMessage`update`,
    },
  }) as const;

export type UpdateSpaceActionType = ReturnType<typeof started | typeof updated | typeof failed>;

export const createUpdateSpaceAction =
  (deps: Deps) =>
  (space: ISpace, shouldCreateNewRootItem: boolean): ThunkPromise =>
  async (dispatch) => {
    dispatch(started(space));

    try {
      let webSpotlightRootItemId = space.webSpotlightRootItemId;

      if (!space.webSpotlightRootItemId && shouldCreateNewRootItem) {
        const rootItem = await dispatch(deps.ensureRootItemCreated());

        webSpotlightRootItemId = rootItem.id;
      }

      const requestModel: IUpdateSpaceRequestServerModel = {
        id: space.id,
        name: space.name,
        codeName: space.codeName,
        collections: space.collectionIds,
        webSpotlightRootItemId,
      };

      const response = await deps.spacesRepository.update(requestModel);
      dispatch(updated(response));
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
