import React, { ReactNode } from 'react';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IMyProjectsProps {
  readonly children: ReactNode;
}

export const MyProjectsPage: React.FC<IMyProjectsProps> = ({ children }) => (
  <div className="canvas">
    <div className="canvas__workspace">
      <section className="canvas__content" {...getDataUiAppNameAttribute(DataUiAppName.Projects)}>
        <HtmlPageTitle appName={AppNames.Projects} />
        <div
          className="canvas__content-pane canvas__content-pane--is-not-project-specific my-projects-pane"
          data-hj-suppress=""
        >
          {children}
        </div>
      </section>
    </div>
  </div>
);

MyProjectsPage.displayName = 'MyProjectsPage';
