import { Box } from '@kontent-ai/component-library/Box';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Caption } from '../../../../../../../_shared/components/DateTimeWithTimeZonePicker/Caption.tsx';
import { dateTimeInputMaxWidth } from '../../../../../../../_shared/components/DatetimePicker/InternalFiles/DatetimePickerInput.tsx';
import { TimeZoneSelect } from '../../../../../../../_shared/components/TimeZoneSelect/TimeZoneSelect.tsx';
import {
  DateTimePickerInElement,
  DateTimePickerInElementProps,
} from './DateTimePickerInElement.tsx';

type DateTimeInputsInFloatingEditorProps = DateTimePickerInElementProps;

export const DateTimeInputsInFloatingEditor: React.FC<DateTimeInputsInFloatingEditorProps> = (
  props,
) => (
  <Stack spacing={Spacing.L}>
    <DateTimePickerInElement
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      onChange={props.onChange}
      elementData={props.elementData}
      datetimePickerRef={props.datetimePickerRef}
    />
    <Box width={dateTimeInputMaxWidth}>
      <TimeZoneSelect
        selectedTimeZoneId={props.elementData.displayTimeZone}
        isDisabled={props.disabled}
        onChange={(tz) => props.onChange(props.elementData._dateTime, tz)}
      />
    </Box>
    <Caption
      renderStyledText={(text) => <Label size={LabelSize.M}>{text}</Label>}
      date={props.elementData.value}
      timeZone={props.elementData.displayTimeZone}
    />
  </Stack>
);

DateTimeInputsInFloatingEditor.displayName = 'DateTimeInputsInFloatingEditor';
