import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { MentionsPlugin } from '../MentionsPlugin.tsx';
import { applyNewMention, createMention } from './editorMentionUtils.ts';

export const editorMentionApi: EditorApiImplementation<MentionsPlugin> = {
  applyNewMention(api, editorState) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      applyNewMention,
      'apply-entity',
    );
  },

  createMention(api, editorState, selection, userId) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => createMention(input, userId),
      'apply-entity',
      false,
    );
  },
};
