import Immutable from 'immutable';
import { repositoryCollection } from '../../../../../../_shared/repositories/repositories.ts';
import { ProgressCallback } from '../../../../../../_shared/utils/ajax.ts';
import { processWithConcurrencyLimit } from '../../../../../../_shared/utils/processWithConcurrencyLimit.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import {
  createAssetFromServerModel,
  createAssetRequestServerModel,
} from '../../../../../../data/models/assets/assetModelUtils.ts';
import { IAssetRepository } from '../../../../../../repositories/interfaces/IAssetRepository.type.ts';
import {
  IAssetInsertMetadataServerModel,
  IAssetLinksServerModel,
  IAssetResponseServerModel,
  IAssetsArchiveResultServerModel,
  IMoveAssetItemToCollectionServerModel,
  IMoveAssetItemToFolderServerModel,
  IMoveAssetsToCollectionResultServerModel,
  IMoveAssetsToFolderResultServerModel,
} from '../../../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  IServerApiError,
  tryParseApiError,
} from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { IItemElementServerModelConverterDataDependencies } from '../../../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';

const { assetRepository } = repositoryCollection;

export interface IAssetService {
  readonly archiveAsset: (assetId: Uuid, abortSignal?: AbortSignal) => Promise<IAsset>;
  readonly archiveAssets: (
    assetsIds: ReadonlySet<Uuid>,
    abortSignal?: AbortSignal,
  ) => Promise<IAssetsArchiveResultServerModel>;
  readonly createAsset: (
    file: File,
    metadata: IAssetInsertMetadataServerModel,
    uploadProgressCallback: ProgressCallback,
    abortSignal?: AbortSignal,
  ) => Promise<IAsset>;
  readonly replaceAsset: (
    assetId: Uuid,
    file: File,
    uploadProgressCallback: ProgressCallback,
    abortSignal?: AbortSignal,
  ) => Promise<IAsset>;
  readonly getAsset: (assetId: Uuid, abortSignal?: AbortSignal) => Promise<IAsset>;
  readonly getAssetsByIds: (
    assetIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ) => Promise<ReadonlyArray<IAsset>>;
  readonly moveAssetsToCollection: (
    items: readonly IMoveAssetItemToCollectionServerModel[],
    abortSignal?: AbortSignal,
  ) => Promise<IMoveAssetsToCollectionResultServerModel>;
  readonly moveAssetsToFolder: (
    items: readonly IMoveAssetItemToFolderServerModel[],
    abortSignal?: AbortSignal,
  ) => Promise<IMoveAssetsToFolderResultServerModel>;
  readonly restoreAsset: (assetId: Uuid, abortSignal?: AbortSignal) => Promise<IAsset>;
  readonly restoreAssets: (
    assetIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ) => Promise<Immutable.Map<Uuid, IAsset>>;
  readonly updateAsset: (
    asset: IAsset,
    elementDependencies: IItemElementServerModelConverterDataDependencies,
    abortSignal?: AbortSignal,
  ) => Promise<IAsset>;
  readonly bulkUpdateAssets: (
    assets: ReadonlyArray<IAsset>,
    elementDependencies: IItemElementServerModelConverterDataDependencies,
    abortSignal?: AbortSignal,
  ) => Promise<{
    successfulAssets: Immutable.Map<Uuid, IAsset>;
    failedAssetErrors: Immutable.Map<Uuid, IServerApiError | null>;
  }>;
}

async function loadAssetLinks(
  asset: IAsset,
  repository: IAssetRepository,
  abortSignal?: AbortSignal,
): Promise<IAsset> {
  const links = await repository.getAssetLinks(asset.id, abortSignal);

  return decorateAssetWithLinks(asset, links);
}

function decorateAssetWithLinks(asset: IAsset, links: IAssetLinksServerModel | null): IAsset {
  return links
    ? {
        ...asset,
        _link: links.link || '',
        _downloadLink: links.downloadLink || '',
        _thumbnailLink: links.thumbnailLink || '',
        _deliveryLink: links.deliveryLink || '',
      }
    : asset;
}

export function processRestoredAssets(
  assets: ReadonlyArray<IAssetResponseServerModel>,
  assetsLinks: ReadonlyArray<IAssetLinksServerModel>,
) {
  const assetsDomains = (assets || []).reduce(
    (reduced, assetInfo) => reduced.set(assetInfo._id, createAssetFromServerModel(assetInfo)),
    Immutable.Map<Uuid, IAsset>(),
  );
  const linksByAssetId = (assetsLinks || []).reduce(
    (reduced, links) => reduced.set(links.assetId, links),
    Immutable.Map<Uuid, IAssetLinksServerModel>(),
  );

  return (assetsDomains || []).reduce((reduced: Immutable.Map<Uuid, IAsset>, assetInfo: IAsset) => {
    return reduced.set(
      assetInfo.id,
      decorateAssetWithLinks(assetInfo, linksByAssetId.get(assetInfo.id) ?? null),
    );
  }, Immutable.Map<Uuid, IAsset>());
}

export const assetService: IAssetService = {
  getAsset: async (assetId: Uuid, abortSignal?: AbortSignal): Promise<IAsset> => {
    const asset = createAssetFromServerModel(await assetRepository.getAsset(assetId, abortSignal));
    return await loadAssetLinks(asset, assetRepository, abortSignal);
  },

  createAsset: async (
    file: File,
    metadata: IAssetInsertMetadataServerModel,
    uploadProgressCallback: ProgressCallback,
    abortSignal?: AbortSignal,
  ): Promise<IAsset> => {
    const assetServerModel = await assetRepository.createAsset(
      file,
      metadata,
      uploadProgressCallback,
      abortSignal,
    );
    const createdAsset = createAssetFromServerModel(assetServerModel);

    return await loadAssetLinks(createdAsset, assetRepository, abortSignal);
  },

  replaceAsset: async (
    assetId: Uuid,
    file: File,
    uploadProgressCallback: ProgressCallback,
    abortSignal?: AbortSignal,
  ): Promise<IAsset> => {
    const assetServerModel = await assetRepository.replaceAsset(
      assetId,
      file,
      uploadProgressCallback,
      abortSignal,
    );
    const createdAsset = createAssetFromServerModel(assetServerModel);

    return await loadAssetLinks(createdAsset, assetRepository, abortSignal);
  },

  restoreAsset: async (assetId: Uuid, abortSignal?: AbortSignal): Promise<IAsset> => {
    const restoredAsset = createAssetFromServerModel(
      await assetRepository.restoreAsset(assetId, abortSignal),
    );
    return await loadAssetLinks(restoredAsset, assetRepository, abortSignal);
  },

  restoreAssets: async (
    assetIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<Immutable.Map<Uuid, IAsset>> => {
    const { assets, assetsLinks } = await assetRepository.restoreAssets(assetIds, abortSignal);

    return processRestoredAssets(assets, assetsLinks);
  },

  archiveAsset: async (assetId: Uuid, abortSignal?: AbortSignal): Promise<IAsset> => {
    const archivedAsset = createAssetFromServerModel(
      await assetRepository.archiveAsset(assetId, abortSignal),
    );

    return archivedAsset;
  },

  archiveAssets: async (
    assetsIds: ReadonlySet<string>,
    abortSignal?: AbortSignal,
  ): Promise<IAssetsArchiveResultServerModel> => {
    return await assetRepository.archiveAssets(assetsIds, abortSignal);
  },

  moveAssetsToCollection: async (
    items: readonly IMoveAssetItemToCollectionServerModel[],
    abortSignal?: AbortSignal,
  ): Promise<IMoveAssetsToCollectionResultServerModel> => {
    return await assetRepository.moveAssetsToCollection(items, abortSignal);
  },

  moveAssetsToFolder: async (
    items: readonly IMoveAssetItemToFolderServerModel[],
    abortSignal?: AbortSignal,
  ): Promise<IMoveAssetsToFolderResultServerModel> => {
    return await assetRepository.moveAssetsToFolder(items, abortSignal);
  },

  updateAsset: async (
    asset: IAsset,
    elementDependencies: IItemElementServerModelConverterDataDependencies,
    abortSignal?: AbortSignal,
  ): Promise<IAsset> => {
    const serverAsset = createAssetRequestServerModel(asset, elementDependencies);
    const updatedAsset = createAssetFromServerModel(
      await assetRepository.updateAsset(serverAsset, abortSignal),
    );

    return await loadAssetLinks(updatedAsset, assetRepository);
  },

  getAssetsByIds: async (
    assetIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<ReadonlyArray<IAsset>> => {
    if (assetIds.length === 0) {
      return [];
    }

    const assetsPromise = assetRepository.getAssetsByIds(new Set(assetIds), abortSignal);

    const serverAssets = (await assetsPromise).data;
    return serverAssets.map(createAssetFromServerModel);
  },

  bulkUpdateAssets: async (
    assets: ReadonlyArray<IAsset>,
    elementDependencies: IItemElementServerModelConverterDataDependencies,
    abortSignal?: AbortSignal,
  ): Promise<{
    successfulAssets: Immutable.Map<Uuid, IAsset>;
    failedAssetErrors: Immutable.Map<Uuid, IServerApiError | null>;
  }> => {
    const successfulAssets = new Map<Uuid, IAsset>();
    const failedAssets = new Map<Uuid, IServerApiError | null>();
    const levelOfConcurrency = 5;
    const updateAssetsPromise = processWithConcurrencyLimit(
      levelOfConcurrency,
      assets.entries(),
      async ([, asset]) => {
        try {
          const serverAsset = createAssetRequestServerModel(asset, elementDependencies);
          const updatedAsset = createAssetFromServerModel(
            await assetRepository.updateAsset(serverAsset, abortSignal),
          );

          const assetWithLinks: IAsset = {
            ...updatedAsset,
            _link: asset._link,
            _deliveryLink: asset._deliveryLink,
            _downloadLink: asset._downloadLink,
            _thumbnailLink: asset._thumbnailLink,
          };

          successfulAssets.set(updatedAsset.id, assetWithLinks);
        } catch (e) {
          failedAssets.set(asset.id, tryParseApiError(e));
        }
      },
    );

    await Promise.all(updateAssetsPromise);

    return {
      successfulAssets: Immutable.Map(successfulAssets),
      failedAssetErrors: Immutable.Map(failedAssets),
    };
  },
};
