import { InvariantException } from '@kontent-ai/errors';
import { parseToRgb, rgb } from 'polished';
import { RgbColor, RgbaColor } from 'polished/lib/types/color';
import { BaseColor, TransparentColor } from '../tokens/quarks/colors.ts';

export const rgba = (color: BaseColor, opacity: number): TransparentColor => {
  if (color === BaseColor.Transparent) {
    throw InvariantException(`rgba: cannot convert color '${color}' to rgba`);
  }
  const r = Number.parseInt(`${color[1]}${color[2]}`, 16);
  const g = Number.parseInt(`${color[3]}${color[4]}`, 16);
  const b = Number.parseInt(`${color[5]}${color[6]}`, 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})` as TransparentColor;
};

// additive blend: https://stackoverflow.com/a/9014763
const overlay = (foreground: number, foregroundAlpha: number, background: number): number =>
  Math.floor(foreground * foregroundAlpha + background * (1 - foregroundAlpha));
const channels = ['red', 'green', 'blue'] as const;
const hasAlphaChannel = (color: RgbColor | RgbaColor): color is RgbaColor =>
  !!(color as RgbaColor).alpha;

/**
 * Overlays two colors like they were stacked one over the other.
 * @param foregroundColor top color - must be transparent
 * @param backgroundColor bottom color - nontransparent
 * @returns a hex of a new color
 */
export const overlayColors = (foregroundColor: string, backgroundColor: string): string => {
  const [foreground, background] = [foregroundColor, backgroundColor].map(parseToRgb);
  if (!foreground || !background) {
    throw new Error(
      `overlayColors: foreground '${foregroundColor}' or background '${backgroundColor}' color could not be parsed properly.`,
    );
  }
  if (!hasAlphaChannel(foreground)) {
    throw new Error(
      `overlayColors: foreground color must be transparent but is '${foregroundColor}'.`,
    );
  }
  if (hasAlphaChannel(background)) {
    throw new Error(
      `overlayColors: background color must not be transparent but is '${backgroundColor}'.`,
    );
  }
  const [red, green, blue] = channels.map((channel) =>
    overlay(foreground[channel], foreground.alpha, background[channel]),
  ) as [number, number, number];
  return rgb(red, green, blue);
};
