import { ContentItemIdString } from '../../../_shared/models/ContentItemId.ts';
import { ActiveCapabilityType } from '../../../_shared/models/activeCapability.type.ts';
import { stringifyContentItemId } from '../../../_shared/models/utils/contentItemIdUtils.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingContentItemServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { TimeSpentInWorkflowStep } from '../../unifiedMissionControl/repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { ListingContentItemWithRequiredVariant } from '../widgets/unchangedUnpublishedContent/types/UnchangedUnpublishedItem.type.ts';

export type ContentPaceDetailContentItem = Readonly<{
  itemWithVariant: IListingContentItem;
  durationInSeconds: number;
}>;

export type ContentPaceDetailData = {
  readonly itemsWithDuration: ReadonlyArray<ContentPaceDetailContentItem> | null;
  readonly isListPartial: boolean;
};

export const getDetailContentItemsFromServerModel = (
  listingContentItemsServerModel: ReadonlyArray<IListingContentItemServerModel> | null,
  itemIdsWithTimeSpent: ReadonlyArray<TimeSpentInWorkflowStep>,
  deletedItemsCount: number,
): ContentPaceDetailData => {
  if (listingContentItemsServerModel === null) {
    return {
      itemsWithDuration: null,
      isListPartial: false,
    };
  }

  const listingContentItemMap = new Map(
    listingContentItemsServerModel
      .map(getListingContentItemFromJS)
      .filter((item): item is ListingContentItemWithRequiredVariant => item.variant !== null)
      .filter((item) =>
        item.activeCapabilities.variantCapabilities.includes(ActiveCapabilityType.ViewContent),
      )
      .map<[ContentItemIdString, IListingContentItem]>((item) => [
        stringifyContentItemId(item.variant.id),
        item,
      ]),
  );

  const itemsWithDuration = itemIdsWithTimeSpent
    .map((item) => {
      return {
        itemWithVariant: listingContentItemMap.get(stringifyContentItemId(item.contentItemId)),
        durationInSeconds: item.durationInSeconds,
      };
    })
    .filter((item): item is ContentPaceDetailContentItem => item.itemWithVariant !== undefined);

  return {
    itemsWithDuration: itemsWithDuration.toSorted(
      (a, b) => b.durationInSeconds - a.durationInSeconds,
    ),
    isListPartial: itemIdsWithTimeSpent.length !== itemsWithDuration.length + deletedItemsCount,
  };
};
