import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';

export const QuickstartQuickTip: React.FC = () => {
  const dispatch = useDispatch();
  const onLinkClick = () =>
    dispatch(
      trackUserEventWithData(TrackedEvent.QuickstartLinkOpened, {
        'link-id': 'get-started',
        target: documentationLinks.getStarted,
      }),
    );

  return (
    <Callout calloutType="quickTip" headline="Learn at your own pace">
      <p>Master Kontent.ai with our e-learning courses and certifications.</p>
      <p>
        <OutwardLink onClick={onLinkClick} href={documentationLinks.getStarted}>
          Get started with Kontent.ai
        </OutwardLink>{' '}
        to learn what headless is all about!
      </p>
    </Callout>
  );
};

QuickstartQuickTip.displayName = 'QuickstartQuickTip';
