import { getParent, getSelfOrParent } from '@kontent-ai/DOM';
import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import { detect } from 'detect-browser';
import { Identifier, XYCoord } from 'dnd-core';
import {
  ContentBlock,
  ContentState,
  DraftBlockRenderConfig,
  DraftCSSProperties,
  EditorState,
  SelectionState,
} from 'draft-js';
import React from 'react';
import { DropFilesTarget } from '../../../../_shared/components/DragDrop/FileDropContainer.tsx';
import { AllRichTextObjectBlocks, DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { filterOutNullish } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  DataUiAttributes,
  DataUiElement,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditorFeatureLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  getAllDisallowedTableFeatures,
  getAllDisallowedTopLevelFeatures,
} from '../../plugins/apiLimitations/api/editorLimitationUtils.ts';
import {
  AdjacentBlockType,
  BaseBlockType,
  BlockType,
  isBlockTypeWithSleeves,
  isHeadingBlockType,
  isNestedBlockType,
  isTextBlockType,
} from '../../utils/blocks/blockType.ts';
import {
  CustomBlockSleevePosition,
  isEmptyParagraph,
  isEmptyTextBlock,
  isTableCell,
  isTopLevelBlock,
} from '../../utils/blocks/blockTypeUtils.ts';
import {
  getBaseBlockType,
  getFullBlockType,
  getNextBlockType,
  getPreviousBlockType,
} from '../../utils/blocks/editorBlockGetters.ts';
import { setContentSelection } from '../../utils/editorSelectionUtils.ts';
import { SelectionAfter, executeContentChange } from '../../utils/editorStateUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../utils/general/editorContentUtils.ts';

export const RichTextContentChangeCallbackDebounce = 300;
export const RichTextSelectionChangeCallbackDebounce = 100;

export const RichTextHighlightUpdateThrottleInterval = 10;

export const BlockClassName = 'rte__block';
export const SelectableContainerClassName = 'rte__selectable-container';

export const CustomBlockSleeveClassname = 'rte__custom-block-sleeve';
export const CustomBlockSleeveBeforeClassname = `${CustomBlockSleeveClassname}--is-before`;
export const CustomBlockSleeveAfterClassname = `${CustomBlockSleeveClassname}--is-after`;

export const UnstyledClassName = 'rte__paragraph';
export const TextBlockClassName = 'rte__text-block';
export const EmptyTextBlockClassName = `${TextBlockClassName}--is-empty`;
export const ListItemClassName = 'rte__list-item';

export const TopLevelBlockClassName = 'rte__top-level-block';
export const LastBlockClassName = 'rte__block--is-last';
export const FirstBlockClassName = 'rte__block--is-first';

const browser = detect();

// Grammarly sets DOM selection and triggers paste with replacement, but the editor doesn't pick up the selection soon enough in Firefox
// It seems that Firefox fires the selectionchange event asynchronously with some delay
// We need to give it a bit of time to process the selectionchange event before handling the actual paste
export const ShouldDeferGrammarlyReplace = browser?.name === 'firefox';

interface IUpdatedInputComponentState {
  editorState: EditorState;
  lastForcedSelection: SelectionState | null;
}

function applyNewContentToContentState(
  input: IContentChangeInput,
  newContent: ContentState,
  originalSelection: SelectionState,
): IContentChangeResult {
  return {
    // We get the updated content, but with original selection kept
    content: setContentSelection(
      newContent,
      input.content.getSelectionBefore(),
      originalSelection,
      originalSelection.getHasFocus(),
    ),
    selection: originalSelection,
    wasModified: true,
  };
}

function applyNewContent(editorState: EditorState, newEditorState: EditorState): EditorState {
  const selection = editorState.getSelection();

  // Currently only changes left from Redux state are comment styles and regenerating URL slug
  // Having said that, we know for sure undo or selection change is never needed
  return executeContentChange(
    editorState,
    selection,
    (input) => applyNewContentToContentState(input, newEditorState.getCurrentContent(), selection),
    newEditorState.getLastChangeType(),
    false,
    SelectionAfter.NewWithOriginalFocus,
  );
}

export function getNewEditorStateForComponent(
  editorState: EditorState,
  newEditorState: EditorState,
  lastForcedSelection: SelectionState | null,
): IUpdatedInputComponentState | null {
  if (editorState !== newEditorState) {
    const content = editorState.getCurrentContent();
    const newContent = newEditorState.getCurrentContent();

    // Get selection state from props only in case the external selection is forced, but only for the first time, remember it
    // Otherwise apply only content and keep current selection
    const newSelection = newEditorState.getSelection();
    const forcedSelection =
      newEditorState.mustForceSelection() && lastForcedSelection !== newSelection
        ? newSelection
        : null;

    if (content !== newContent || forcedSelection) {
      const updatedEditorState =
        content !== newContent ? applyNewContent(editorState, newEditorState) : editorState;
      const updatedEditorStateWithSelection = forcedSelection
        ? EditorState.forceSelection(updatedEditorState, newEditorState.getSelection())
        : updatedEditorState;

      return {
        editorState: updatedEditorStateWithSelection,
        lastForcedSelection: forcedSelection || lastForcedSelection,
      };
    }
  }
  return null;
}

export const getContentOverlayClass = (editorId: string) => {
  return `overlay_${editorId}`;
};

export const getContentOverlayId = (editorId: string, blockKey: string) => {
  return `overlay_${editorId}_${blockKey}`;
};

export const mergeInlineStyles = memoize.weak(
  (
    newStyles: DraftCSSProperties,
    originalStyles: DraftCSSProperties | undefined,
  ): DraftCSSProperties => ({
    ...originalStyles,
    ...newStyles,
    ...(newStyles.className && {
      className: classNames(originalStyles?.className, newStyles.className),
    }),
  }),
);

export type BaseBlockRenderMap = Immutable.Map<BaseBlockType, DraftBlockRenderConfig>;
export type BlockRenderMap = Immutable.Map<BlockType, DraftBlockRenderConfig>;

export const mergeBlockRenderMaps = memoize.weak(
  (
    first: BaseBlockRenderMap,
    ...renderMaps: ReadonlyArray<BaseBlockRenderMap>
  ): BaseBlockRenderMap => renderMaps.reduce((merged, item) => merged.merge(item), first),
);

// Only use this method for rendering as it relies on specicic block data. Use getCustomBlockSleevePosition for editor API methods.
export function getCustomBlockSleevePositionForRendering(
  block: ContentBlock,
): CustomBlockSleevePosition {
  if (!isEmptyParagraph(block)) {
    return CustomBlockSleevePosition.None;
  }

  const nextBlockType = getNextBlockType(block);
  const previousBlockType = getPreviousBlockType(block);

  if (nextBlockType !== AdjacentBlockType.None && isBlockTypeWithSleeves(nextBlockType)) {
    return CustomBlockSleevePosition.BeforeOwner;
  }
  if (previousBlockType !== AdjacentBlockType.None && isBlockTypeWithSleeves(previousBlockType)) {
    return CustomBlockSleevePosition.AfterOwner;
  }
  return CustomBlockSleevePosition.None;
}

type BlockClassNames = ReadonlyRecord<string, boolean>;

export function getEditorIdClassName(editorId: string): string {
  return `rte--id-${editorId}`;
}

export function getBlockIdClassName(blockKey: string): string {
  return `rte__block--id-${blockKey}`;
}

export function getBlockCssSelector(editorId: string, blockKey: string = ''): string {
  // Only highlight in the context of specific editor, as two editors could technically have blocks with the same block key
  return `.rte__content.${getEditorIdClassName(editorId)} .${getBlockIdClassName(blockKey)}`;
}

export function getBlockClassNames(block: ContentBlock): BlockClassNames {
  const type = getBaseBlockType(block);
  const depth = block.getDepth();
  const nextBlockType = getNextBlockType(block);
  const previousBlockType = getPreviousBlockType(block);

  const customBlockSleevePosition = getCustomBlockSleevePositionForRendering(block);

  const baseClassNames: BlockClassNames = {
    [TopLevelBlockClassName]: isTopLevelBlock(block),
    [FirstBlockClassName]: previousBlockType === AdjacentBlockType.None,
    [LastBlockClassName]: nextBlockType === AdjacentBlockType.None,
    [getBlockIdClassName(block.getKey())]: true,
  };

  const specificClassNames: BlockClassNames =
    customBlockSleevePosition === CustomBlockSleevePosition.None
      ? // Regular block
        {
          [TextBlockClassName]: isTextBlockType(type),
          [EmptyTextBlockClassName]: isEmptyTextBlock(block),
          [UnstyledClassName]: type === BlockType.Unstyled,
          rte__heading: isHeadingBlockType(type),
          'rte__heading-one': type === BlockType.HeadingOne,
          'rte__heading-two': type === BlockType.HeadingTwo,
          'rte__heading-three': type === BlockType.HeadingThree,
          'rte__heading-four': type === BlockType.HeadingFour,
          'rte__heading-five': type === BlockType.HeadingFive,
          'rte__heading-six': type === BlockType.HeadingSix,
          'rte__table-cell-content': type === BlockType.TableCell,
          [`${ListItemClassName} rte__list-item--depth${depth} rte__olist-item`]:
            type === BlockType.OrderedListItem,
          [`${ListItemClassName} rte__list-item--depth${depth} rte__ulist-item`]:
            type === BlockType.UnorderedListItem,
          'rte__content-module': type === BlockType.ContentModule,
          'rte__content-image': type === BlockType.Image,
          'rte__content-component': type === BlockType.ContentComponent,
          'rte__new-block-placeholder': type === BlockType.NewBlockPlaceholder,
        }
      : // Custom block sleeve
        {
          [CustomBlockSleeveClassname]: true,
          [CustomBlockSleeveBeforeClassname]:
            customBlockSleevePosition === CustomBlockSleevePosition.BeforeOwner,
          [CustomBlockSleeveAfterClassname]:
            customBlockSleevePosition === CustomBlockSleevePosition.AfterOwner,
          [`rte__next--is-${nextBlockType}`]: !!nextBlockType,
          [`rte__previous--is-${previousBlockType}`]: !!previousBlockType,
        };

  return {
    [BlockClassName]: true,
    ...baseClassNames,
    ...specificClassNames,
  };
}

export const getBlockClass = memoize.weak((block: ContentBlock): string => {
  return classNames(getBlockClassNames(block));
});

export const getBlockFromBlockElement = (element: React.ReactElement): ContentBlock =>
  element.props.children.props.block;

export function isCellBoundary(current: React.ReactElement): boolean {
  const currentBlock = getBlockFromBlockElement(current);

  return isTableCell(currentBlock);
}

export const getDisallowedFeaturesClasses = memoize.weak(
  (limitations: EditorFeatureLimitations): ReadonlyArray<string> => {
    const allDisallowedTopLevelFeatures = getAllDisallowedTopLevelFeatures(limitations);
    const disallowedTopLevelFeaturesClasses = Collection.getValues(
      allDisallowedTopLevelFeatures,
    ).map((feature) => `rte__content--${feature}-forbidden`);

    const allDisallowedTableFeatures = getAllDisallowedTableFeatures(limitations);
    const disallowedTableFeaturesClasses = Collection.getValues(allDisallowedTableFeatures).map(
      (feature) => `rte__content--table-${feature}-forbidden`,
    );

    return [...disallowedTopLevelFeaturesClasses, ...disallowedTableFeaturesClasses];
  },
);

export function getAcceptedDropTypes(block: ContentBlock): Identifier | ReadonlyArray<Identifier> {
  return isNestedBlockType(getFullBlockType(block))
    ? DndTypes.Rich_Text_Image
    : AllRichTextObjectBlocks;
}

export const RteClassName = 'rte';
export const RteAcceptsFilesClassName = `${RteClassName}--accepts-files`;

function isRichText(element: Element): boolean {
  return element.classList.contains(RteClassName);
}

function isRichTextEditorAcceptingFiles(element: Element): boolean {
  return isRichText(element) && element.classList.contains(RteAcceptsFilesClassName);
}

function isUploadDropZone(element: Element): boolean {
  return element.getAttribute(DataUiAttributes.Element) === DataUiElement.UploadDropzone;
}

function isActiveFileDropContainer(element: Element): boolean {
  return isRichTextEditorAcceptingFiles(element) || isUploadDropZone(element);
}

export function isContentBlockElement(element: Element): element is HTMLElement {
  return element.classList.contains(BlockClassName);
}

export function findDropFilesTarget(
  editorElement: HTMLElement,
  clientOffset: XYCoord,
  lastTarget: DropFilesTarget | null,
): DropFilesTarget | null {
  const elementsFromPoint = document.elementsFromPoint(clientOffset.x, clientOffset.y);

  // Check whether the closest container receiving files is the current editor, if not, don't allow drop
  const topMostElementFromPoint = elementsFromPoint[0];
  if (
    !topMostElementFromPoint ||
    getSelfOrParent(topMostElementFromPoint, isActiveFileDropContainer) !== editorElement
  ) {
    return null;
  }

  // Collect possible targets, omit targets whose editors do not support file drop
  const targetCandidates = filterOutNullish(
    elementsFromPoint
      .filter((element, index) => index === 0 || isContentBlockElement(element))
      .map((element) => {
        const targetBlockElement = getSelfOrParent(element, isContentBlockElement);
        if (!targetBlockElement) {
          return null;
        }
        const editor = getParent(targetBlockElement, isRichText);
        if (!editor || !isRichTextEditorAcceptingFiles(editor)) {
          return null;
        }
        return {
          targetBlockElement,
          editor,
        };
      }),
  );

  const topMostTargetCandidate = targetCandidates[0];
  if (topMostTargetCandidate && topMostTargetCandidate.editor === editorElement) {
    const targetBlockElement = topMostTargetCandidate.targetBlockElement;
    const targetBlockKey = topMostTargetCandidate.targetBlockElement
      .getAttribute('data-offset-key')
      ?.split('-')[0];
    if (targetBlockKey) {
      return {
        element: targetBlockElement,
        id: targetBlockKey,
      };
    }
  }

  // Reaching here means we are in the right editor, but didn't find a proper target
  // keep the last target to prevent target flickering when moving over inactive places
  return lastTarget;
}
