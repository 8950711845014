import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { SimpleScrollTable } from '../../../../_shared/uiComponents/ScrollTable/SimpleScrollTable.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createSubscriptionUserDetailLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { sortByChanged } from '../actions/subscriptionUserListingActions.ts';
import { SubscriptionUsersScrollTableHead } from '../components/SubscriptionUsersScrollTableHead.tsx';
import { SubscriptionUsersScrollTableRow } from '../components/SubscriptionUsersScrollTableRow.tsx';
import { ISubscriptionUserWithSettings } from '../models/SubscriptionUserWithSettings.ts';
import { SubscriptionUserListingColumnCode } from '../reducers/subscriptionUsersListingUi/sortBy.ts';
import { SubscriptionProjectsAndEnvironments } from '../selectors/getSubscriptionProjectsAndEnvironments.ts';
import {
  decorateFilteredUsers,
  getFilteredUsers,
} from '../utils/subscriptionUserUsageFilterUtils.ts';
import { SubscriptionUsersScrollTableEmptyState } from './SubscriptionUsersScrollTableEmptyState.tsx';

type SubscriptionUsersScrollTableContainerProps = {
  readonly subscriptionId: Uuid;
  readonly subscriptionProjectsAndEnvironments: SubscriptionProjectsAndEnvironments;
  readonly subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>;
};

export const SubscriptionUsersScrollTable: React.FC<SubscriptionUsersScrollTableContainerProps> = ({
  subscriptionId,
  subscriptionProjectsAndEnvironments,
  subscriptionUsers,
}) => {
  const dispatch = useDispatch();
  const usersListingOrderBy = useSelector((state) => state.subscriptionApp.users.listingUi.sortBy);

  const { projects, environments, byMasterEnvironmentId } = subscriptionProjectsAndEnvironments;
  const filters = useSelector((state) => state.subscriptionApp.users.listingUi.filter);

  const selectedProjectCollections = useSelector(
    (s) => s.subscriptionApp.users.listingUi.collections,
  );
  const selectedProjectLanguages = useSelector((s) => s.subscriptionApp.users.listingUi.languages);

  const filteredUsers = getFilteredUsers(
    byMasterEnvironmentId,
    subscriptionUsers,
    selectedProjectCollections,
    selectedProjectLanguages,
    filters,
  );
  const decoratedUsers = decorateFilteredUsers(
    filteredUsers,
    projects,
    environments,
    usersListingOrderBy,
  );
  const anyFilteredResults = filteredUsers && filteredUsers.length > 0;

  const onCreateUserDetailLink = useCallback(
    (userId: Uuid) =>
      createSubscriptionUserDetailLink({
        subscriptionId,
        userId,
      }),
    [subscriptionId],
  );

  const onSortDirectionToggled = (columnCode: SubscriptionUserListingColumnCode) =>
    dispatch(sortByChanged(columnCode));

  return (
    <SimpleScrollTable
      renderTitle={() => (
        <ListingMessage
          statusInfoMessage={{ text: pluralizeWithCount('user', filteredUsers.length) }}
        />
      )}
      collectionName={DataUiCollection.SubscriptionUsers}
      renderHead={() => (
        <SubscriptionUsersScrollTableHead
          usersListingOrderBy={usersListingOrderBy}
          onColumnHeadClicked={onSortDirectionToggled}
        />
      )}
      renderEmptyState={() => (
        <SubscriptionUsersScrollTableEmptyState anyFilteredResults={anyFilteredResults} />
      )}
    >
      {anyFilteredResults &&
        decoratedUsers.map(
          (user, index) =>
            user &&
            index !== undefined && (
              <SubscriptionUsersScrollTableRow
                key={index}
                subscriptionUserViewModel={user}
                onCreateUserDetailLink={onCreateUserDetailLink}
              />
            ),
        )}
    </SimpleScrollTable>
  );
};

SubscriptionUsersScrollTable.displayName = 'SubscriptionUsersScrollTable';
