import { memoize } from '@kontent-ai/memoization';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { INote } from '../../../../models/contentItem/Assignment.ts';
import { IContributorNote } from '../../models/IContributorNote.type.ts';

export interface INoteSectionStateProps {
  readonly canEdit: boolean;
  readonly isHidden: boolean;
  readonly note: IContributorNote | null;
}

const getNoteForDetailsSection = memoize.maxOne(
  (
    note: INote | null,
    usersById: ReadonlyMap<UserId, IProjectContributor>,
  ): IContributorNote | null => {
    const addedBy = (note?.addedBy && usersById.get(note.addedBy)) || null;

    return note
      ? {
          addedAt: note.addedAt,
          addedBy,
          text: note.noteText,
        }
      : null;
  },
);

export const getNoteState = (state: IStore): INoteSectionStateProps => {
  const {
    contentApp: { editedContentItemVariant },
  } = state;

  const canEdit = hasActiveVariantCapabilityForEditedItem(
    ActiveCapabilityType.UpdateAssignment,
    state,
  );
  const note =
    editedContentItemVariant &&
    getNoteForDetailsSection(editedContentItemVariant.assignment.note, state.data.users.usersById);

  return {
    canEdit,
    isHidden: !note && !canEdit,
    note,
  };
};
