import { History } from 'history';
import { Dispatch, GetState } from '../../../../../../@types/Dispatcher.type.ts';
import {
  ContentItemRevisionViewerRoute,
  ContentItemRevisionViewerRouteParams,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { comparisonClosed } from '../revisionsActions.ts';

export const closeComparison =
  (history: History, match: ContentItemRouteParams<string>) =>
  (dispatch: Dispatch, getState: GetState): void => {
    const { selectedRevision, selectedComparisonRevision } = getState().contentApp;

    const params: Omit<ContentItemRevisionViewerRouteParams<UuidArray>, 'timelineItemId'> = {
      app: match.app,
      projectId: match.projectId,
      variantId: match.variantId,
      spaceId: match.spaceId,
      contentItemIds: parseContentItemIds(match.contentItemIds),
    };

    const comparisonIsNewer =
      !!selectedRevision &&
      !!selectedComparisonRevision &&
      selectedRevision.time < selectedComparisonRevision.time;
    const selected = comparisonIsNewer ? selectedComparisonRevision : selectedRevision;

    if (selected) {
      const revisionPath = buildPath<ContentItemRevisionViewerRouteParams<UuidArray>>(
        ContentItemRevisionViewerRoute,
        {
          ...params,
          timelineItemId: selected.itemId,
        },
      );
      history.push(revisionPath);
    }

    dispatch(comparisonClosed());
  };
