import { assert } from '@kontent-ai/utils';
import { addDays, subDays } from 'date-fns';
import { useCallback } from 'react';
import {
  DefaultLanguageId,
  DefaultWorkflowId,
} from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import { getActiveLanguageIds } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { UnchangedUnpublishedItemsServerModel } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { UnchangedUnpublishedWidget as UnchangedUnpublishedContentWidgetComponent } from '../components/UnchangedUnpublishedWidget.tsx';
import { createDemoUnchangedUnpublishedItem } from '../utils/createDemoUnchangedUnpublishedItem.ts';
import { getDemoWorkflowStepServerModel } from '../utils/getDemoWorkflowStepFromServer.ts';

const createDemoData = (
  itemNames: ReadonlyArray<string>,
  workflows: ReadonlyMap<Uuid, Workflow>,
  languageIds: ReadonlyArray<Uuid>,
  assignee: UserId,
): ReadonlyArray<UnchangedUnpublishedItemsServerModel> => {
  const defaultWorkflow = workflows.get(DefaultWorkflowId);
  assert(defaultWorkflow, () => 'Default workflow must be defined');

  const oldestStepChange = subDays(new Date(), 80);
  return itemNames.map((name, index) =>
    createDemoUnchangedUnpublishedItem(
      name,
      languageIds[index % languageIds.length] ?? DefaultLanguageId,
      getDemoWorkflowStepServerModel(defaultWorkflow, index % defaultWorkflow.steps.length),
      addDays(oldestStepChange, index * 4),
      assignee,
    ),
  );
};

const demoItemNames: ReadonlyArray<string> = [
  'AI Boosts Physical Therapy',
  'Big Data’s Health Influence',
  'Boost Efficiency in Content Creation',
  'Streamline Content Workflow: A Guide',
  '2024 Healthtech Trends Overview',
  'AI Impact on Healthcare: 2024 Report',
  'Data-Driven Health: Tech Fighting Global Health Crises',
  'The Digital Doctor: VR in Mental Health Therapy',
  'Tech in Health Monitoring',
  'Telemedicine’s Patient Impact',
];

export const UnchangedUnpublishedWidgetDemoContainer: React.FC = () => {
  const workflows = useSelector((s) => s.data.workflows.byId);
  const activeLanguages = useSelector((s) => getActiveLanguageIds(s.data.languages));
  const currentUserId = useSelector(getCurrentUserId);

  const itemsFetcher = useCallback(
    () =>
      Promise.resolve({
        data: createDemoData(demoItemNames, workflows, activeLanguages.toArray(), currentUserId),
        continuationToken: null,
      }),
    [workflows, activeLanguages, currentUserId],
  );

  return (
    <UnchangedUnpublishedContentWidgetComponent itemsFetcher={itemsFetcher} useRowLinks={false} />
  );
};

UnchangedUnpublishedWidgetDemoContainer.displayName = 'UnchangedUnpublishedWidgetDemoContainer';
