import classNames from 'classnames';
import React, { forwardRef, useRef } from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { useScrollInfo } from '../../../../../_shared/hooks/useScrollInfo.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { stopPropagation } from '../../../../../_shared/utils/func/functionalTools.ts';

type CalendarPopupProps = Readonly<{
  className?: string;
  headerContent: React.ReactNode;
  bodyContent: React.ReactNode;
  onClose: () => void;
  backButton?: React.ReactElement;
}>;

export const CalendarPopup = forwardRef<HTMLDivElement, CalendarPopupProps>(
  (props, forwardedRef) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const { isScrollable } = useScrollInfo(contentRef);

    return (
      <HotkeysHandler
        handlers={{
          onEscape: props.onClose,
        }}
        mode={HotkeysMode.Dual}
      >
        <div
          ref={forwardedRef}
          className={classNames('calendar-popup', props.className)}
          onClick={stopPropagation}
          {...getDataUiElementAttribute(DataUiElement.CalendarPopup)}
        >
          <div className="calendar-popup__header">
            {props.backButton}
            <div className="calendar-popup__header-title">{props.headerContent}</div>
            <SquareButton
              iconName={IconName.Times}
              onClick={props.onClose}
              style={SquareButtonStyle.Quinary}
              tooltipPlacement="bottom-end"
              tooltipShortcuts="Esc"
              tooltipText="Close"
              dataUiAction={DataUiAction.CloseDialog}
              size={SquareButtonSize.Quinary}
            />
          </div>
          <div
            ref={contentRef}
            className={classNames('calendar-popup__content', {
              'calendar-popup__content--with-dividers': isScrollable,
            })}
          >
            {props.bodyContent}
          </div>
        </div>
      </HotkeysHandler>
    );
  },
);

CalendarPopup.displayName = 'CalendarPopup';
