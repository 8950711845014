import { EditorBlock } from 'draft-js';
import React from 'react';
import { DraggedFilesContext } from '../../../../../_shared/components/DragDrop/DraggedFilesContext.tsx';
import { DropTarget } from '../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { getDataAttribute } from '../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ElementAttributes } from '../../../../itemEditor/constants/elementAttributes.ts';
import { getAcceptedDropTypes } from '../../../editorCore/utils/editorComponentUtils.ts';
import { getStopEventsConsumedByDraftJs } from '../../../editorCore/utils/stopEventsConsumedByDraftJs.ts';
import { IEditorBlockProps } from '../../../utils/blocks/editorBlockUtils.ts';
import { DroppableBlockWrapperBase, IDroppableBlockProps } from './DroppableBlockWrapperBase.tsx';

export interface IDroppableCustomBlockWrapperProps
  extends IDroppableBlockProps,
    Omit<IEditorBlockProps, 'blockProps'> {
  readonly className: string;
  readonly uiElement?: DataUiElement;
  readonly children?: React.ReactNode;
}

export class DroppableCustomBlockWrapper extends DroppableBlockWrapperBase<IDroppableCustomBlockWrapperProps> {
  static displayName = 'DroppableCustomBlockWrapper';

  declare context: React.ContextType<typeof DraggedFilesContext>;
  static contextType = DraggedFilesContext;

  private editorBlockForUpdateCheck!: EditorBlock;

  constructor(props: IDroppableCustomBlockWrapperProps) {
    super(props);

    this.updateEditorBlockForUpdateCheck();
  }

  componentDidUpdate(): void {
    this.updateEditorBlockForUpdateCheck();
  }

  shouldComponentUpdate(nextProps: IDroppableCustomBlockWrapperProps) {
    if (super.shouldComponentUpdate(nextProps)) {
      return true;
    }

    return this.shouldBlockUpdate(this.editorBlockForUpdateCheck, nextProps);
  }

  private readonly updateEditorBlockForUpdateCheck = (): void => {
    // We maintain extra instance of EditorBlock for delegation of shouldComponentUpdate to prevent copying of the logic from DraftJS code
    // The underlying instance cannot be referenced directly due to too many nesting levels of underlying components
    this.editorBlockForUpdateCheck = new EditorBlock(this.props);
  };

  render() {
    const { block, canUpdate, className, hoveringCollisionStrategy, onMove, parentId, uiElement } =
      this.props;

    return (
      <DropTarget<HTMLDivElement>
        accept={getAcceptedDropTypes(block)}
        canDrop={canUpdate}
        hoveringCollisionStrategy={hoveringCollisionStrategy}
        onMove={onMove}
        parentId={parentId}
        renderDroppable={(ref) => (
          <div
            className={className}
            ref={ref}
            // Custom blocks are complex components with their own live cycle isolated from that of rich text editor.
            // That is why we disable propagation of their events into the editor. Editor handlers could conflict with those of custom block component.
            // Specifically when a comment is added to the custom block, the editor reacts by updating its selection
            // as the focus and therefore DOM selection moves to the comment button, and it immediately blurs the comment
            {...getStopEventsConsumedByDraftJs(this.context.isDraggingFiles)}
            // The entire rich text editor has got the automatic comment blur disabled because it handles comment focus and blur explicitly from its selection change handler
            // We need to re-enable comment blur on custom blocks because their events are not propagated to the editor and therefore the editor wouldn't fire the explicit blur for them
            {...getDataAttribute(ElementAttributes.BlurCommentThreadOnClick, 'true')}
            {...(uiElement && getDataUiElementAttribute(uiElement))}
          >
            {this.props.children}
          </div>
        )}
        targetId={block.getKey()}
      />
    );
  }
}
