import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { GetAuthToken } from '../types/GetAuthToken.type.ts';
import { parseEmailVerified } from '../utils/authorization/accessTokenUtils.ts';
import { getUrlFactory } from '../utils/urlFactory.ts';

/**
 * Use this hook when you need to ensure a user has a verified email.
 * If the email is not verified, the user will be redirected to the Email verify page.
 * @param getAuthToken - callback to get a valid authToken
 * @param canRedirectToVerification (optional) - specifies if redirection can be started
 *
 * Returns a boolean value if the email is verified.
 */
export const useHandleEmailVerification = (
  getAuthToken: GetAuthToken,
  canRedirectToVerification: boolean = true,
): boolean => {
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(getAuthToken)
      .then((authToken) => {
        const isEmailFromReceivedTokenVerified = parseEmailVerified(authToken);
        setIsEmailVerified(isEmailFromReceivedTokenVerified);
        if (authToken && !isEmailFromReceivedTokenVerified && canRedirectToVerification) {
          self.location.replace(getUrlFactory().getVerifyEmailPageUrl(window.location.href));
        }
      })
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [getAuthToken, canRedirectToVerification]);

  return isEmailVerified;
};
