import { ContentBlock } from 'draft-js';
import React from 'react';
import { getModularContentItemId } from '../../api/editorModularUtils.ts';
import { ExpandedModularContent } from './ExpandedModularContent.tsx';

interface IExpandedModularContentsProps {
  readonly contentModuleBlocks: ReadonlyArray<ContentBlock>;
  readonly disabled: boolean;
  readonly editorId: string;
  readonly expandedBlockKeys: ReadonlySet<string>;
  readonly highlightedBlockKeys: ReadonlySet<string>;
  readonly isDragging: boolean;
}

export const ExpandedModularContents: React.FC<IExpandedModularContentsProps> = ({
  contentModuleBlocks,
  editorId,
  expandedBlockKeys,
  highlightedBlockKeys,
  isDragging,
}) => (
  <div>
    {contentModuleBlocks.map((block: ContentBlock) => {
      const blockKey = block.getKey();
      if (!expandedBlockKeys.has(blockKey)) {
        return null;
      }

      const contentItemId = getModularContentItemId(block);
      if (!contentItemId) {
        return null;
      }

      const isHighlighted = highlightedBlockKeys.has(blockKey);

      return (
        <ExpandedModularContent
          blockKey={blockKey}
          contentItemId={contentItemId}
          editorId={editorId}
          isDragging={isDragging}
          isHighlighted={isHighlighted}
          key={blockKey}
        />
      );
    })}
  </div>
);

ExpandedModularContents.displayName = 'ExpandedModularContents';
