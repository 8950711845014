import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { Data_Languages_Success } from '../../../constants/dataActionTypes.ts';
import {
  ILanguage,
  createLanguage,
  createLanguageFromServerModel,
} from '../../../models/languages/Language.ts';

const initialState: ILanguage = createLanguage({ id: DefaultVariantId });

export function defaultLanguage(state: ILanguage = initialState, action: Action): ILanguage {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_Languages_Success:
      return createLanguageFromServerModel(action.payload.data.defaultLanguage);

    default:
      return state;
  }
}
