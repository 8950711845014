import { Dispatch, ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { ISavedFilter } from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { IFilterRepository } from '../../../../../../../repositories/interfaces/IFilterRepository.type.ts';
import {
  ListingFilter_DeleteSavedFilter_Failed,
  ListingFilter_DeleteSavedFilter_Finished,
  ListingFilter_DeleteSavedFilter_Started,
} from '../../constants/listingFilterActionTypes.ts';
import { UnableToDeleteErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly filterRepository: IFilterRepository;
}

interface IParams {
  readonly filter: ISavedFilter;
}

const started = (filter: ISavedFilter) =>
  ({
    type: ListingFilter_DeleteSavedFilter_Started,
    payload: { filter },
  }) as const;

const finished = (filter: ISavedFilter) =>
  ({
    type: ListingFilter_DeleteSavedFilter_Finished,
    payload: { filter },
  }) as const;

const failed = (filter: ISavedFilter, errorMessage: string) =>
  ({
    type: ListingFilter_DeleteSavedFilter_Failed,
    payload: {
      errorMessage,
      filter,
    },
  }) as const;

export type DeleteSavedFilterActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createDeleteSavedFilterAction =
  (deps: IDeps) =>
  ({ filter }: IParams): ThunkFunction =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(filter));

    try {
      await deps.filterRepository.deleteFilter(filter.id);
    } catch (e) {
      dispatch(failed(filter, UnableToDeleteErrorMessage));
      throw e;
    }

    dispatch(finished(filter));
  };
