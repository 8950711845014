import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Webhooks_Webhook_DisableCompleted,
  Webhooks_Webhook_DisableFailed,
  Webhooks_Webhook_DisableStarted,
  Webhooks_Webhook_EnableCompleted,
  Webhooks_Webhook_EnableFailed,
  Webhooks_Webhook_EnableStarted,
} from '../../constants/webhooksActionTypes.ts';

type IsSettingWebhookStateInProgressType = ReadonlyMap<Uuid, boolean>;
const initialState: IsSettingWebhookStateInProgressType = new Map<Uuid, boolean>();

export const isSettingWebhookStateInProgress = (
  state: IsSettingWebhookStateInProgressType = initialState,
  action: Action,
): IsSettingWebhookStateInProgressType => {
  switch (action.type) {
    case Webhooks_Webhook_EnableStarted:
    case Webhooks_Webhook_DisableStarted:
      return Collection.add(state, [action.payload.webhook.id, true]);

    case Webhooks_Webhook_EnableCompleted:
    case Webhooks_Webhook_EnableFailed:
    case Webhooks_Webhook_DisableCompleted:
    case Webhooks_Webhook_DisableFailed:
      return Collection.add(state, [action.payload.webhook.id, false]);

    default:
      return state;
  }
};
