import { IUserInfo } from '../../../../_shared/models/UserInfo.ts';
import { ISubscriptionUserServerModel } from '../../../../repositories/serverModels/ISubscriptionUserServerModel.type.ts';

export interface ISubscriptionUser extends IUserInfo {
  readonly createdAt: DateTimeStamp;
  readonly hadTrial: boolean;
  readonly isEmailVerified: boolean;
  readonly state: string;
  readonly updatedAt: DateTimeStamp;
}

const emptyObject: ISubscriptionUser = {
  createdAt: '',
  email: '',
  firstName: '',
  hadTrial: false,
  isEmailVerified: false,
  lastName: '',
  state: '',
  updatedAt: '',
  userId: '',
};

export function createSubscriptionUser(params?: Partial<ISubscriptionUser>): ISubscriptionUser {
  return {
    ...emptyObject,
    ...params,
  };
}

export function createSubscriptionUserFromServerModel(
  serverModel: ISubscriptionUserServerModel,
): ISubscriptionUser {
  if (typeof serverModel !== 'object') {
    throw new Error('Subscription user is not an object');
  }

  return createSubscriptionUser({
    createdAt: serverModel.createdAt || '',
    email: serverModel.email || '',
    firstName: serverModel.firstName || '',
    hadTrial: serverModel.hadTrial || false,
    isEmailVerified: serverModel.isEmailVerified || false,
    lastName: serverModel.lastName || '',
    state: serverModel.state || '',
    updatedAt: serverModel.updatedAt || '',
    userId: serverModel.userId || '',
  });
}
