import { ScheduleMethod } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/scheduleMethods.ts';
import { IChangeWorkflowStepModalData } from '../../../../../../_shared/features/ChangeWorkflowStepModal/reducers/IChangeWorkflowStepModalData.ts';
import { isTimeInPast } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';

export enum PublishingValidationResult {
  Ok = 'Ok',
  PublishDateIsEmptyOrInvalid = 'PublishDateIsEmptyOrInvalid',
  PublishDateIsInPast = 'PublishDateIsInPast',
  PublishDateIsSameOrAfterUnpublishDate = 'PublishDateIsSameOrAfterUnpublishDate',
}

export const validatePublishingConfiguration = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): PublishingValidationResult => {
  if (changeWorkflowStepModalData.scheduleMethod === ScheduleMethod.Now) {
    return PublishingValidationResult.Ok;
  }

  if (!changeWorkflowStepModalData.scheduledToPublishAt) {
    return PublishingValidationResult.PublishDateIsEmptyOrInvalid;
  }

  const scheduleToPublishAt = changeWorkflowStepModalData.scheduledToPublishAt;
  if (isTimeInPast(new Date(), scheduleToPublishAt)) {
    return PublishingValidationResult.PublishDateIsInPast;
  }

  const scheduledToUnpublishAt = changeWorkflowStepModalData.scheduledToUnpublishAt;

  if (
    !!scheduledToUnpublishAt &&
    new Date(scheduleToPublishAt) >= new Date(scheduledToUnpublishAt)
  ) {
    return PublishingValidationResult.PublishDateIsSameOrAfterUnpublishDate;
  }

  return PublishingValidationResult.Ok;
};
