import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import {
  IWebhookSetting,
  createWebhookSettingDomainModel,
} from '../../../../data/models/webhooks/WebhookSetting.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';
import { IWebhookServerModel } from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  Webhooks_Webhook_ResetCompleted,
  Webhooks_Webhook_ResetFailed,
  Webhooks_Webhook_ResetStarted,
} from '../../constants/webhooksActionTypes.ts';

interface IResetWebhookDependencies {
  readonly webhookRepository: IWebhookRepository;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const started = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_ResetStarted,
    payload: {
      webhook,
    },
  }) as const;

const completed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_ResetCompleted,
    payload: {
      webhook,
    },
  }) as const;

const failed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_ResetFailed,
    payload: {
      webhook,
    },
  }) as const;

export type ResetWebhookActionsType = ReturnType<typeof started | typeof completed | typeof failed>;

export const createResetWebhookAction =
  (deps: IResetWebhookDependencies) =>
  (webhook: IWebhookSetting): ThunkPromise =>
  async (dispatch): Promise<void> => {
    dispatch(started(webhook));

    try {
      const response: IWebhookServerModel = await deps.webhookRepository.reset(webhook.id);
      dispatch(completed(createWebhookSettingDomainModel(response)));
      dispatch(deps.trackUserEvent(TrackedEvent.WebhookReset, { url: response.url }));
    } catch {
      dispatch(failed(webhook));
    }
  };
