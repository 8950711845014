import React from 'react';
import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ClickableNoteSection } from '../../../containers/details/Note/SectionTypes/ClickableNoteSection.tsx';
import { NonClickableNoteSection } from '../../../containers/details/Note/SectionTypes/NonClickableNoteSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

interface INoteSectionProps {
  readonly sectionType: SectionType;
}

export const NoteSection: React.FC<INoteSectionProps> = ({ sectionType }) => (
  <Section
    ClickableSectionComponent={ClickableNoteSection}
    NonClickableSectionComponent={NonClickableNoteSection}
    ReadonlySectionComponent={() => null}
    title="Note"
    type={sectionType}
    uiObjectNameAttribute={ItemDetailSection.NoteSection}
  />
);

NoteSection.displayName = 'NoteSection';
