import { Callout } from '@kontent-ai/component-library/Callout';
import { forwardRef } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly actionName: string;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
  readonly warningMessage?: string;
};

export const RevokeApiKeyDialog = forwardRef<HTMLDivElement, Props>(
  ({ actionName, isOpen, onClose, onConfirm, warningMessage }, ref) => {
    const renderBody = () =>
      warningMessage ? (
        <Callout calloutType="warning">{warningMessage}</Callout>
      ) : (
        <p>You won’t be able to access this key anymore.</p>
      );

    return (
      <ModalDialog
        cancelAction={{
          onClick: onClose,
          ...getDataUiActionAttribute(DataUiAction.Cancel),
        }}
        headline={`${actionName} API key?`}
        isDismissable
        isOpen={isOpen}
        onClose={onClose}
        primaryAction={{
          onClick: onConfirm,
          text: `${actionName} key`,
          destructive: true,
          ...getDataUiActionAttribute(DataUiAction.Continue),
        }}
        ref={ref}
        shouldCloseOnInteractOutside={() => true}
      >
        {renderBody()}
      </ModalDialog>
    );
  },
);

RevokeApiKeyDialog.displayName = 'RevokeApiKeyDialog';
