import { addMinutes, addMonths, addYears, startOfMinute } from 'date-fns';
import { ApiKeyType } from '../models/ApiKeyType.ts';

type TokenExpirationInfo = {
  getMinimalExpiration: (now: Date) => Date;
  getDefaultExpiration: (now: Date) => Date;
  getMaximalExpiration: (now: Date) => Date;
};

const ApiKeysExpiration: ReadonlyMap<ApiKeyType, TokenExpirationInfo> = new Map([
  [
    ApiKeyType.MAPIPat,
    {
      getMinimalExpiration: (now: Date) => addMinutes(startOfMinute(now), 1),
      getDefaultExpiration: (now: Date) => addMonths(startOfMinute(now), 6),
      getMaximalExpiration: (now: Date) => addYears(startOfMinute(now), 2),
    },
  ],
  [
    ApiKeyType.MAPI,
    {
      getMinimalExpiration: (now: Date) => addMinutes(startOfMinute(now), 1),
      getDefaultExpiration: (now: Date) => addMonths(startOfMinute(now), 6),
      getMaximalExpiration: (now: Date) => addYears(startOfMinute(now), 2),
    },
  ],
  [
    ApiKeyType.DAPI,
    {
      getMinimalExpiration: (now: Date) => addMinutes(startOfMinute(now), 1),
      getDefaultExpiration: (now: Date) => addYears(startOfMinute(now), 1),
      getMaximalExpiration: (now: Date) => addYears(startOfMinute(now), 5),
    },
  ],
  [
    ApiKeyType.PAPIPrimary,
    {
      getMinimalExpiration: (now: Date) => addMinutes(startOfMinute(now), 1),
      getDefaultExpiration: (now: Date) => addYears(startOfMinute(now), 1),
      getMaximalExpiration: (now: Date) => addYears(startOfMinute(now), 5),
    },
  ],
  [
    ApiKeyType.SAPI,
    {
      getMinimalExpiration: (now: Date) => addMinutes(startOfMinute(now), 1),
      getDefaultExpiration: (now: Date) => addMonths(startOfMinute(now), 6),
      getMaximalExpiration: (now: Date) => addYears(startOfMinute(now), 2),
    },
  ],
]);

export const getMinimalExpiration = (type: ApiKeyType): Date =>
  ApiKeysExpiration.get(type)?.getMinimalExpiration(new Date()) ?? new Date();

export const getMaximalExpiration = (type: ApiKeyType): Date =>
  ApiKeysExpiration.get(type)?.getMaximalExpiration(new Date()) ?? new Date();

export const getDefaultExpiration = (type: ApiKeyType): string =>
  ApiKeysExpiration.get(type)?.getDefaultExpiration(new Date()).toISOString() ?? '';
