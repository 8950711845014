import styled from 'styled-components';
import { Paper } from '../../../containers/Paper/Paper.tsx';

/**
 * Internal component only, not be exported outside the Card component.
 */
export const CardContainer = styled(Paper)<{ readonly borderColor?: string }>`
  grid-template-areas:
    "overline close"
    "headline close"
    "body     body"
    "footer   footer";
  grid-template-columns: minMax(auto, 1fr) auto;
  grid-template-rows: auto auto 1fr auto;
  border-color: ${({ borderColor }) => borderColor};
`;
