import { assert, notUndefined } from '@kontent-ai/utils';
import DiffMatchPatch from 'diff-match-patch';

export interface IMergedListItem<T> {
  readonly value: T;
  readonly added?: boolean;
  readonly removed?: boolean;
}

const LINE_SEPARATOR = '\n';

export function getListDiff<T extends string | ReadonlyRecord<unknown, unknown>>(
  oldList: ReadonlyArray<T> | null,
  newList: ReadonlyArray<T>,
  getKey: (item: T) => string,
): ReadonlyArray<IMergedListItem<T>> {
  if (!oldList || oldList.length === 0) {
    return newList.map((value: T) => ({
      value,
      added: true,
    }));
  }

  if (!newList || newList.length === 0) {
    return oldList.map((value: T) => ({
      value,
      removed: true,
    }));
  }

  // Extra line break added to the end to keep all lines format consistent for comparison
  const oldListAsLines = oldList.map(getKey).join(LINE_SEPARATOR) + LINE_SEPARATOR;
  const newListAsLines = newList.map(getKey).join(LINE_SEPARATOR) + LINE_SEPARATOR;

  const dmp = new DiffMatchPatch.diff_match_patch();
  const mappedLines = dmp.diff_linesToChars_(oldListAsLines, newListAsLines);
  const lineText1 = mappedLines.chars1;
  const lineText2 = mappedLines.chars2;
  const diffs = dmp.diff_main(lineText1, lineText2, false);

  let oldItemIndex = 0;
  let newItemIndex = 0;
  let i: number | null = null;

  const resultIds = Array<IMergedListItem<T>>();

  const oldItemDoesNotExistMessage = (index: number) => () =>
    `Old item at index "${index}" does not exist.`;
  const newItemDoesNotExistMessage = (index: number) => () =>
    `New item at index "${index}" does not exist.`;

  diffs.map((diff) => {
    const type = diff[0];
    const text = diff[1];

    for (i = 0; i < (text.length || 0); i++) {
      if (type === DiffMatchPatch.DIFF_DELETE) {
        // Removed item (might also have changed order)
        const oldItem = oldList[oldItemIndex];
        assert(notUndefined(oldItem), oldItemDoesNotExistMessage(oldItemIndex));
        resultIds.push({
          value: oldItem,
          removed: true,
        });
        oldItemIndex++;
      } else if (type === DiffMatchPatch.DIFF_INSERT) {
        // Added item (might also have changed order)
        const newItem = newList[newItemIndex];
        assert(notUndefined(newItem), newItemDoesNotExistMessage(newItemIndex));
        resultIds.push({
          value: newItem,
          added: true,
        });
        newItemIndex++;
      } else {
        // Item in both at the same position
        const item = oldList[oldItemIndex];
        assert(notUndefined(item), oldItemDoesNotExistMessage(oldItemIndex));
        resultIds.push({
          value: item,
        });
        oldItemIndex++;
        newItemIndex++;
      }
    }
  });

  return resultIds;
}
