import { isRevisionCurrent } from '../../../../applications/itemEditor/features/Revisions/utils/revisionUtils.ts';
import { TimelineItem } from '../../../../applications/itemEditor/models/revisions/TimeLineItem.ts';
import { ActiveCapabilityType } from '../../../models/activeCapability.type.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import { RestoreConfirmation } from '../../../types/RestoreConfirmation.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishedWorkflowStepSelected,
  isScheduleToPublishWorkflowStepSelected,
} from '../../../utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../utils/permissions/activeCapabilities.ts';

export const canRestoreRevision = (state: IStore): boolean => {
  const revisionToRestore = getRevisionToRestore(state);
  const {
    contentApp: { editedContentItemVariant },
  } = state;

  return !!(
    revisionToRestore &&
    editedContentItemVariant &&
    !isScheduleToPublishWorkflowStepSelected(editedContentItemVariant.assignment) &&
    (!isPublishedWorkflowStepSelected(editedContentItemVariant.assignment) ||
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.CreateNewVersion, state)) &&
    (!isArchivedWorkflowStepSelected(editedContentItemVariant.assignment) ||
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateAssignment, state)) &&
    (isComparingRevisions(state) ||
      !isRevisionCurrent(state.contentApp.entryTimeline, revisionToRestore)) &&
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state)
  );
};

export const getRevisionToRestore = (state: IStore): TimelineItem | null => {
  const { selectedRevision, selectedComparisonRevision } = state.contentApp;

  if (!selectedRevision) {
    return null;
  }

  if (isComparingRevisions(state) && !!selectedComparisonRevision) {
    const olderRevision =
      selectedRevision.time < selectedComparisonRevision.time
        ? selectedRevision
        : selectedComparisonRevision;

    return olderRevision;
  }
  return selectedRevision;
};

const isComparingRevisions = (state: IStore): boolean =>
  !!state.contentApp.selectedComparisonRevision;

export const getRestoreConfirmation = ({
  contentApp: { editedContentItemVariant },
}: IStore): RestoreConfirmation => {
  if (isPublishedWorkflowStepSelected(editedContentItemVariant?.assignment)) {
    return RestoreConfirmation.ConfirmationInPublish;
  }
  if (isArchivedWorkflowStepSelected(editedContentItemVariant?.assignment)) {
    return RestoreConfirmation.ConfirmationInArchived;
  }

  return RestoreConfirmation.NoConfirmation;
};
