import Immutable from 'immutable';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { editItemVariant } from '../actions/thunkSmartLinkActions.ts';
import { FocusTarget, IItemElement } from '../actions/thunks/editItemVariant.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { CommentRouteParams } from '../constants/routePaths.ts';

export const CommentSmartLink = () => {
  const {
    projectId,
    variantGuid: variantId,
    itemGuid: itemId,
    commentThreadId,
  } = useParams<CommentRouteParams>();

  const params = useMemo(
    () => ({
      projectId,
      variantId,
      path: Immutable.List<IItemElement>([{ itemId }]),
      predefinedFocus: {
        target: FocusTarget.CommentThread,
        commentThreadId,
      } as const,
    }),
    [projectId, variantId, itemId, commentThreadId],
  );

  useThunkPromise(editItemVariant, params);

  return <SmartLink />;
};
