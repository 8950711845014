import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ILinkedItemsTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { ILinkedItemsElement } from '../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { getLinkedItemsElementErrors } from '../../../../utils/elementErrorCheckers/linkedItemsElementErrorChecker.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getItemElementValueForErrorValidation } from '../../../../utils/getItemElementValueForErrorValidation.ts';
import { getLinkedItemElementValidationFriendlyWarning } from '../../../../utils/itemElementFriendlyWarningCheckers/modularItemElementFriendlyWarningChecker.ts';
import { getLinkedItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/modularContentItemElementWarningChecker.ts';
import { mapElementErrorResultToItemElementErrorResult } from '../../../../utils/mapElementErrorResultToItemElementErrorResult.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

export const createLinkedItemsElementValueChangedAction =
  () =>
  (itemElement: ILinkedItemsElement, typeElement: ILinkedItemsTypeElement): ThunkFunction =>
  (dispatch, getState) => {
    const {
      data: { listingContentItems },
      contentApp: { editedContentItem },
    } = getState();

    if (!editedContentItem) {
      throw InvariantException(
        `modularContentElementValueChanged.ts: "editedContentItem" is ${editedContentItem}`,
      );
    }

    const errorResult = getLinkedItemsElementErrors({
      value: getItemElementValueForErrorValidation(itemElement),
    });

    const warningResult = getLinkedItemElementValidationWarning({
      typeElement,
      itemElement,
      loadedEntries: listingContentItems.byId,
    });

    const friendlyWarningResult = getLinkedItemElementValidationFriendlyWarning({
      itemElement,
      loadedEntries: listingContentItems.byId,
    });

    const validationResult = createValidationResult(
      mapElementErrorResultToItemElementErrorResult(errorResult, typeElement.type),
      warningResult,
      friendlyWarningResult,
      itemElement.elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: itemElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
