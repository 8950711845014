import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type ContentTypeOption = {
  readonly id: Uuid;
  readonly label: string;
  readonly isArchived: boolean;
};

interface IContentTypesLimitationsProps {
  readonly allContentTypes: readonly ContentTypeOption[];
  readonly isDisabled: boolean;
  readonly label?: string;
  readonly onSelectedContentTypesChanged: (options: ReadonlySet<Uuid>) => void;
  readonly selectedContentTypesIds: ReadonlySet<Uuid>;
  readonly tooltipText?: string;
}

const anyContentTypeLabel: string = 'Any content type';

export const ContentTypesLimitations: React.FC<IContentTypesLimitationsProps> = ({
  allContentTypes,
  isDisabled,
  label,
  onSelectedContentTypesChanged,
  selectedContentTypesIds,
  tooltipText,
}) => (
  <Box>
    <MultiSelect<ContentTypeOption>
      inputState={isDisabled ? InputState.Disabled : InputState.Default}
      items={allContentTypes}
      label={label}
      onSelectionChange={onSelectedContentTypesChanged}
      placeholder={anyContentTypeLabel}
      placeholderType="tag"
      renderMenuOption={(optionProps) => (optionProps.item.value?.isArchived ? '' : undefined)}
      renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
        selectedItem.isArchived ? (
          <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
        ) : (
          <DefaultTag {...defaultTagProps} />
        )
      }
      selectedItemIds={selectedContentTypesIds}
      tooltipText={tooltipText}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
    />
  </Box>
);

ContentTypesLimitations.displayName = 'ContentTypesLimitations';
