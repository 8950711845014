import React, { useEffect } from 'react';

export interface IElementLocksObserverStateProps {
  readonly hasLockedElements: boolean;
  readonly releaseLockTimeout: number;
}

export interface IElementLocksObserverDispatchProps {
  readonly releaseExpiredElementLocks: () => void;
}

type ElementLocksObserverProps = IElementLocksObserverStateProps &
  IElementLocksObserverDispatchProps;

export const ElementLocksObserver: React.FC<ElementLocksObserverProps> = (props) => {
  const { hasLockedElements, releaseLockTimeout, releaseExpiredElementLocks } = props;

  useEffect(() => {
    const timeoutId = hasLockedElements
      ? self.setTimeout(() => releaseExpiredElementLocks(), releaseLockTimeout)
      : undefined;
    return () => self.clearTimeout(timeoutId);
  }, [hasLockedElements, releaseLockTimeout, releaseExpiredElementLocks]);

  return null;
};

ElementLocksObserver.displayName = 'ElementLocksObserver';
