import { GetState, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../../../_shared/constants/variantIdValues.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DoesCurrentStateStillMatchArguments,
  ILoadListingItemsParams,
} from '../../../../../../../../data/actions/thunks/listingContentItems/loadListingContentItems.ts';
import { getCurrentProjectId } from '../../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getContentItemScrollTableRowHeightPx } from '../../../../../constants/uiConstants.ts';
import { ensureContentTypesLoadedForAllContentItems } from './ensureContentTypesLoadedForAllContentItems.ts';

interface IDeps {
  readonly loadListingContentItems: (
    params: ILoadListingItemsParams,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

const doesCurrentStateStillMatchArgumentsIncludingLanguage: DoesCurrentStateStillMatchArguments = (
  route,
  args,
) => route.projectId === args.projectId && route.languageId === args.languageId;

export const createLoadListingContentItemsForInventoryAction =
  (deps: IDeps) =>
  (scrollPositionChanged?: boolean, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      listingUi: { filter, orderBy },
    } = state.contentApp;

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const currentProjectId = getCurrentProjectId(state);

    await dispatch(
      deps.loadListingContentItems(
        {
          doesCurrentStateStillMatchArguments: doesCurrentStateStillMatchArgumentsIncludingLanguage,
          filter,
          getScrollTableState: (getFreshState: GetState) =>
            getFreshState().contentApp.listingUi.contentItemListingScrollTableState,
          itemHeightPx: getContentItemScrollTableRowHeightPx(),
          orderBy,
          projectId: currentProjectId,
          scrollPositionChanged: !!scrollPositionChanged,
          languageId: selectedLanguageId,
        },
        abortSignal,
      ),
    );

    await dispatch(ensureContentTypesLoadedForAllContentItems(abortSignal));
  };

const doesCurrentStateStillMatchArgumentsIgnoringLanguage: DoesCurrentStateStillMatchArguments = (
  route,
  args,
) => route.projectId === args.projectId;

export const createLoadListingContentItemsForContentTypesAction =
  (deps: IDeps) =>
  (scrollPositionChanged?: boolean, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      listingUi: { filter, orderBy },
    } = state.contentApp;

    const currentProjectId = getCurrentProjectId(state);

    await dispatch(
      deps.loadListingContentItems(
        {
          doesCurrentStateStillMatchArguments: doesCurrentStateStillMatchArgumentsIgnoringLanguage,
          filter,
          getScrollTableState: (getFreshState: GetState) =>
            getFreshState().contentApp.listingUi.contentItemListingScrollTableState,
          itemHeightPx: getContentItemScrollTableRowHeightPx(),
          orderBy,
          projectId: currentProjectId,
          scrollPositionChanged: !!scrollPositionChanged,
          languageId: DefaultVariantId,
        },
        abortSignal,
      ),
    );

    await dispatch(ensureContentTypesLoadedForAllContentItems(abortSignal));
  };
