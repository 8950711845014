import { Redirect, Route, Switch } from 'react-router';
import { RedirectIf } from '../../_shared/components/routing/RedirectIf.tsx';
import { AppNames } from '../../_shared/constants/applicationNames.ts';
import {
  AccessDeniedRouteParams,
  AccessDeniedToContentItemRoute,
  AccessDeniedToContentItemRouteParams,
  AccessDeniedToLanguageRoute,
  AccessDeniedToLanguageRouteParams,
  AccessLostDueToDeactivatedLanguageRoute,
  EnvironmentAccessDeniedGeneralSettingsRoute,
  EnvironmentAccessDeniedRoute,
  EnvironmentWarningRoute,
  PageNotFoundRoute,
  RootRoute,
} from '../../_shared/constants/routePaths.ts';
import { IRouteContext, RouteContext } from '../../_shared/containers/routing/RouteContext.tsx';
import { AccessDenied } from './components/AccessDenied.tsx';
import { AccessDeniedToContentItemAction } from './constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedNoPermissionsInActiveLanguages } from './containers/AccessDeniedNoPermissionsInActiveLanguages.tsx';
import { AccessDeniedToContentItem } from './containers/AccessDeniedToContentItem.tsx';
import { AccessDeniedToLanguage } from './containers/AccessDeniedToLanguage.tsx';
import { isAnyKnownAppName } from './utils/isAnyKnownAppName.ts';

export const EnvironmentErrorHandlingRoutes: React.FC = () => {
  return (
    <Route key={AppNames.ErrorHandling} path={EnvironmentWarningRoute}>
      <Switch>
        <Route path={EnvironmentAccessDeniedGeneralSettingsRoute}>
          <AccessDenied
            title="Do you need to change the general settings of environment?"
            descriptionParagraphs={[
              'You can’t make any changes here.',
              'Contact your project manager for more information.',
            ]}
          />
        </Route>
        <Route path={EnvironmentAccessDeniedRoute}>
          <RouteContext>
            {({ match }: IRouteContext<AccessDeniedRouteParams>) => (
              <RedirectIf
                redirectTo={RootRoute}
                doRedirect={!isAnyKnownAppName(decodeURIComponent(match.params.requestedAppName))}
              >
                <AccessDenied
                  title={`Do you need to work with ${decodeURIComponent(
                    match.params.requestedAppName,
                  )}?`}
                  descriptionParagraphs={[
                    'You can’t make any changes here.',
                    'Contact your project manager for more information.',
                  ]}
                />
              </RedirectIf>
            )}
          </RouteContext>
        </Route>
        <Route path={AccessLostDueToDeactivatedLanguageRoute}>
          <AccessDeniedNoPermissionsInActiveLanguages />
        </Route>
        <Route path={AccessDeniedToLanguageRoute}>
          <RouteContext<AccessDeniedToLanguageRouteParams>>
            {({ match }) => <AccessDeniedToLanguage languageId={match.params.variantId} />}
          </RouteContext>
        </Route>
        <Route path={AccessDeniedToContentItemRoute}>
          <RouteContext>
            {({ match }: IRouteContext<AccessDeniedToContentItemRouteParams>) => (
              <RedirectIf
                redirectTo={RootRoute}
                doRedirect={
                  !Object.values(AccessDeniedToContentItemAction).includes(
                    match.params.requestedAction,
                  )
                }
              >
                <AccessDeniedToContentItem requestedAction={match.params.requestedAction} />
              </RedirectIf>
            )}
          </RouteContext>
        </Route>
        <Route>
          <Redirect to={PageNotFoundRoute} />
        </Route>
      </Switch>
    </Route>
  );
};
