import { IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { createContentTypeSelectorOptions } from '../utils/createContentTypeSelectorOptions.ts';
import { getAllowedContentTypes } from './getAllowedContentTypes.ts';
import { getAvailableContentTypes } from './getAvailableContentTypes.ts';
import { getContentTypeSelectorState } from './getContentTypeSelectorState.ts';
import { getSuggestedContentTypes } from './getSuggestedContentTypes.ts';

export const getContentTypeOptions = (
  state: IStore,
): ReadonlyArray<ISelectSection<IBaseSelectItem>> => {
  const availableContentTypes = getAvailableContentTypes(state);
  const allowedContentTypes = getAllowedContentTypes(state, availableContentTypes);
  const suggestedContentTypes = getSuggestedContentTypes(state, availableContentTypes);
  const selectorState = getContentTypeSelectorState(state);

  return createContentTypeSelectorOptions(
    suggestedContentTypes,
    allowedContentTypes,
    availableContentTypes,
    selectorState,
  );
};
