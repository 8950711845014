import { useCallback, useEffect, useRef, useState } from 'react';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createCancelOperationParams } from '../../../../../repositories/serverModels/ai/AiServerModels.cancelOperation.ts';
import { AiActionNameToParameters } from '../../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { repositoryCollection } from '../../../../repositories/repositories.ts';
import { AiActionNameToMessagePayload } from '../../../../services/signalR/signalRClient.type.ts';
import { MessageThrottleWaitMs } from '../../constants/uiConstants.ts';
import { OnAiTaskUpdate } from '../../types/OnAiTaskUpdate.type.ts';
import { IsFinished, ResultSelector } from '../../types/ResultSelector.type.ts';
import { throttleUntil } from '../../utils/throttleUntil.ts';
import { MatchAiTask } from './matchAiTask.type.ts';

/**
 * Creates a new task for given action
 *
 * @param actionParameters A set of inputs required by the action
 * @param aiTaskContext Any additional context client was to receive back in each result
 * @returns A promise of the request starting the action
 */
type Run<TActionName extends AiActionName> = (
  actionParameters: AiActionNameToParameters[TActionName],
  aiTaskContext?: ReadonlyRecord<string, unknown>,
) => Promise<void>;

type UseAiTaskResult<TActionName extends AiActionName, TResult extends IsFinished> = {
  // Cancels a previously-run task and notifies backend about cancellation intent
  readonly cancel: () => Promise<void>;
  // Returns `true` if a task was run and is still running
  readonly isRunning: boolean;
  // Returns latest message received (can be just a partial result, is also throttled)
  readonly result: ReturnType<ResultSelector<TResult>>;
  // Creates a new task for given action
  readonly run: Run<TActionName>;
};

/**
 * Hook that can create a new AI task for AI operation. When all necessary parameters provided, it
 * takes over already running AI task when re-mounted. Hook also controls how often it notifies its
 * users about a change by throttling how often the new `result` value is set.
 *
 * @param actionName An AI action name.
 * @param selectResult Transforms AI action messages and context into a result.
 * @param matchAiTask A function that receives information about all running AI tasks and returns an id of the task the hook should take over.
 * @param onUpdateInBackground A callback that is called when the hook is not rendered and new AI message related to the AI task is received.
 * @typeParam TActionName type of action that this hook will manage (run, cancel, read (incremental) results of)
 * @typeParam TResult type of content messages returned by the task, must extend `IsFinish` type
 * @returns {UseAiTaskResult}
 * @see IsFinished
 * @see throttleUntil
 * @see MessageThrottleWaitMs
 */
export const useAiTask = <TActionName extends AiActionName, TResult extends IsFinished>(
  actionName: TActionName,
  selectResult: ResultSelector<TResult>,
  matchAiTask?: MatchAiTask,
  onUpdateInBackground?: OnAiTaskUpdate<AiActionNameToMessagePayload[TActionName]>,
): UseAiTaskResult<TActionName, TResult> => {
  const aiTaskManager = useAiTaskManager();

  const [aiTaskId, setAiTaskId] = useState<Uuid | null>(
    () => matchAiTask?.(aiTaskManager.getAllTasks())?.id ?? null,
  );

  const selectResultRef = useRef(selectResult);
  selectResultRef.current = selectResult;

  const [result, setResult] = useState(() => {
    const emptyResult = selectResultRef.current([], { hasTimedOut: false });
    if (!aiTaskId) return emptyResult;
    const currentTaskActionStatus = aiTaskManager.getTaskActionStatus(aiTaskId);
    const currentTaskActionMessages = aiTaskManager.getTaskActionMessages(aiTaskId);
    if (!currentTaskActionStatus || !currentTaskActionMessages) return emptyResult;
    return selectResultRef.current(currentTaskActionMessages, currentTaskActionStatus);
  });

  const cancel = useCallback(async (): Promise<void> => {
    setResult(selectResultRef.current([], { hasTimedOut: false }));

    if (!aiTaskId) return;

    setAiTaskId(null);

    await aiTaskManager.cancel(aiTaskId, (operationId) =>
      repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
    );
  }, [aiTaskId, aiTaskManager]);

  useEffect(() => {
    if (!aiTaskId) return;
    const setResultThrottled = throttleUntil<[value: TResult], void>(
      setResult,
      (actionResult) => !actionResult.isFinished,
      MessageThrottleWaitMs,
    );
    aiTaskManager.changeProcessMessagesCallback<typeof actionName>(
      aiTaskId,
      (messages, aiActionStatus) => {
        const actionResult = selectResultRef.current(messages, aiActionStatus);
        setResultThrottled(actionResult);
        return actionResult;
      },
    );

    return () => {
      if (onUpdateInBackground) {
        aiTaskManager.changeProcessMessagesCallback<typeof actionName>(
          aiTaskId,
          onUpdateInBackground,
        );
      }
    };
  }, [aiTaskId, aiTaskManager, onUpdateInBackground]);

  const run = useCallback(
    async (
      actionParameters: AiActionNameToParameters[TActionName],
      aiTaskContext?: ReadonlyRecord<string, unknown>,
    ): Promise<void> => {
      cancel();

      const taskId = await aiTaskManager.createTask(
        actionName,
        actionParameters,
        (parameters) => repositoryCollection.aiRepository.createAction(parameters),
        (messages, aiActionStatus) => {
          const actionResult = selectResultRef.current(messages, aiActionStatus);
          setResult(actionResult);
          return actionResult;
        },
        aiTaskContext,
      );

      setAiTaskId(taskId);
    },
    [cancel, aiTaskManager, actionName],
  );

  const cleanupCancelRef = useRef<() => Promise<void>>(() => Promise.resolve());
  cleanupCancelRef.current = async (): Promise<void> => {
    if (!aiTaskId || onUpdateInBackground) return;
    await aiTaskManager.cancel(aiTaskId, (operationId) =>
      repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
    );
  };

  useEffect(() => {
    return () => {
      cleanupCancelRef.current();
    };
  }, []);

  return {
    isRunning: !!aiTaskId && !result.isFinished,
    result,
    cancel,
    run,
  };
};
