import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { SimultaneousEditingSaveConflictReason } from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { ElementLockStatus } from '../../../../../../_shared/services/signalR/signalRClient.type.ts';

interface IDeps {
  readonly projectNotificationService: {
    readonly requestElementLock: (
      itemVariantId: ContentItemId,
      elementId: Uuid,
    ) => Promise<ElementLockStatus | null>;
  };
}

export const createCheckConcurrentConflictsBeforeSave =
  (deps: IDeps) =>
  (
    contentItemId: ContentItemId,
    elementId: Uuid,
  ): ThunkPromise<SimultaneousEditingSaveConflictReason | null> =>
  async (_dispatch, getState) => {
    const {
      contentApp: {
        editorUi: { outdatedElementIds },
      },
    } = getState();

    if (outdatedElementIds.contains(elementId)) {
      return SimultaneousEditingSaveConflictReason.ElementOutdated;
    }

    const elementLockStatus = await deps.projectNotificationService.requestElementLock(
      contentItemId,
      elementId,
    );

    if (elementLockStatus === ElementLockStatus.LockFailed) {
      return SimultaneousEditingSaveConflictReason.ElementLocked;
    }

    return null;
  };
