import { TippyProps } from '@tippyjs/react';
import { useContext, useRef } from 'react';
import { Instance as TippyInstance } from 'tippy.js';
import { ScrollContainerContext } from '../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { useEventListener } from './useEventListener.ts';

/**
 * Use this hook when you are using nested Tippy placed inside another Tippy content
 * The hook provides properties for the Tippy component and triggers extra sync in case the scroll container of the scrolledContent scrolls
 * This fixes the flip behavior, but may fix also other out-of sync scenarios in this case
 * Receives:
 * - A RefObject which targets any content in the mentioned scrolling container
 * - Optional flag whether the sync is enabled which should be used in case the Tippy is controlled via visible property to enable the sync only when the content is displayed
 * Returns an object with Tippy component properties which should be passed to the Tippy component:
 * - 'onMount', 'onHide'
 */
export function useUpdateTippyWithScroll(
  enabled: boolean = true,
): Pick<TippyProps, 'onMount' | 'onHide'> | undefined {
  const { scrollContainerRef } = useContext(ScrollContainerContext);
  const tippyRef = useRef<TippyInstance | null>(null);

  // Tippy placed in a parent which is placed in a body scrolls with the parent, but doesn't properly flip on scroll, we need to force update on scroll
  useEventListener(
    'scroll',
    () => {
      const tippy = tippyRef.current;
      if (tippy && enabled) {
        tippy.popperInstance?.forceUpdate();
      }
    },
    scrollContainerRef.current,
  );

  const setTippyInstance = (instance: TippyInstance): void => {
    tippyRef.current = instance;
  };
  const resetTippyInstance = (): void => {
    tippyRef.current = null;
  };

  return {
    onMount: setTippyInstance,
    onHide: resetTippyInstance,
  };
}
