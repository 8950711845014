import { useEffect, useState } from 'react';
import { WorkflowSelectorItem } from '../../../applications/itemEditor/features/ChangeWorkflowDialog/components/ChangeWorkflowDialog.tsx';

export function useWorkflowSelection(
  workflowOptions: readonly WorkflowSelectorItem[],
  isParentDialogOpen: boolean,
) {
  const selectContainsSingleOption = workflowOptions.length === 1;

  const preselectedWorkflowId =
    selectContainsSingleOption && workflowOptions[0]?.id ? workflowOptions[0].id : null;

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<Uuid | null>(preselectedWorkflowId);

  useEffect(() => {
    if (isParentDialogOpen) {
      setSelectedWorkflowId(preselectedWorkflowId);
    }
  }, [isParentDialogOpen, preselectedWorkflowId]);

  return {
    disableSelection: selectContainsSingleOption,
    selectedWorkflowId,
    setSelectedWorkflowId,
  };
}
