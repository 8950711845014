import { createGuid } from '@kontent-ai/utils';
import {
  loadAssets,
  loadListingContentItemsByIds,
} from '../../../../data/actions/thunkDataActions.ts';
import { loadGuidelinesRichTextReferences } from '../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { createDefaultTypeElementData } from '../utils/defaultTypeElementDataCreator.ts';
import { setFirstInvalidElement } from './sharedContentModelsActions.ts';
import { createLoadAssetDefaultValueReferencesAction } from './thunks/loadAssetDefaultValueReferences.ts';
import { createLoadContentTypeReferencesForTypeEditingAction } from './thunks/loadContentTypeReferencesForTypeEditing.ts';
import { createLoadLinkedItemsDefaultValueReferencesAction } from './thunks/loadLinkedItemsDefaultValueReferences.ts';
import { createLoadNewLinkedItemsDefaultValueReferences } from './thunks/loadNewLinkedItemsDefaultValueReferences.ts';
import {
  createAddNewTypeElementAction,
  createInsertNewTypeElementBeforeAction,
} from './thunks/typeEditor/addNewTypeElement.ts';
import { createFocusFirstIncompleteElementAction } from './thunks/typeEditor/focusFirstInvalidElement.ts';
import { createRemoveTypeElementWithDependenciesAction } from './thunks/typeEditor/removeTypeElementWithDependencies.ts';
import { createUpdateTypeElementAction } from './thunks/typeEditor/updateTypeElement.ts';
import { createValidateTypeElementAction } from './thunks/typeEditor/validateTypeElement.ts';

export const addNewTypeElement = createAddNewTypeElementAction({
  createDefaultTypeElementData,
  createGuid,
});

export const insertNewTypeElementBefore = createInsertNewTypeElementBeforeAction({
  createDefaultTypeElementData,
  createGuid,
});

export const validateTypeElement = createValidateTypeElementAction();

export const updateTypeElement = createUpdateTypeElementAction({
  validateTypeElement,
});

export const removeTypeElementWithDependencies = createRemoveTypeElementWithDependenciesAction({
  updateTypeElement,
});

export const focusFirstInvalidElement = createFocusFirstIncompleteElementAction({
  setFirstInvalidElement,
});

export const loadAssetDefaultValueReferences = createLoadAssetDefaultValueReferencesAction({
  loadAssets,
});

export const loadLinkedItemsDefaultValueReferences =
  createLoadLinkedItemsDefaultValueReferencesAction({
    loadListingContentItemsByIds,
  });

export const loadNewLinkedItemsDefaultValueReferences =
  createLoadNewLinkedItemsDefaultValueReferences();

export const loadContentTypeReferencesForTypeEditing =
  createLoadContentTypeReferencesForTypeEditingAction({
    loadAssetDefaultValueReferences,
    loadGuidelinesRichTextReferences,
    loadLinkedItemsDefaultValueReferences,
  });
