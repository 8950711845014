import { FeatureToggleConsent } from '../types/InnovationLabProjectProperty.type.ts';

type ProtoFeatureToggleConsentInfo = Record<'Date' | 'UserId', string>;

const isProtoFeatureToggleConsentInfo = (
  dictionary: Dictionary<string>,
): dictionary is ProtoFeatureToggleConsentInfo => {
  return (
    typeof dictionary.UserId === 'string' &&
    typeof dictionary.Date === 'string' &&
    !!dictionary.UserId &&
    !!dictionary.Date
  );
};

export const parseInnovationLabConsentInfo = (value: string | null): FeatureToggleConsent => {
  try {
    const parsedDictionary: Dictionary<string> | null = value && JSON.parse(value);
    if (parsedDictionary && isProtoFeatureToggleConsentInfo(parsedDictionary)) {
      return {
        date: new Date(parsedDictionary.Date),
        userId: parsedDictionary.UserId,
      };
    }
    return null;
  } catch {
    return null;
  }
};
