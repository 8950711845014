import { WorkflowScope } from '../../../../data/models/workflow/Workflow.ts';
import { CollectionOption } from '../components/limitations/CollectionsLimitations.tsx';

export const createDeletedCollection = (id: Uuid): CollectionOption => ({
  id,
  label: 'Deleted collection',
  isArchived: true,
});

export const getSelectedCollections = (
  editedWorkflowScopesById: ReadonlyMap<Uuid, WorkflowScope>,
  scopeId: Uuid | undefined,
  collections: readonly CollectionOption[],
): readonly CollectionOption[] => {
  if (!scopeId) {
    return [];
  }

  const workflowScope = editedWorkflowScopesById.get(scopeId);

  if (!workflowScope) {
    return [];
  }

  return Array.from(workflowScope.collections).map(
    (id) => collections.find((collection) => collection.id === id) ?? createDeletedCollection(id),
  );
};
