const isString = (arg: any): arg is string => typeof arg === 'string';

export const getHttpRequestErrorMessage = (status: number): string => {
  return `XMLHttpRequest:\nCode ${status}`;
};

export function isError(arg: any): arg is Error {
  return arg !== null && typeof arg === 'object' && arg instanceof Error;
}

export function isXMLHttpRequest(arg: any): arg is XMLHttpRequest {
  return arg !== null && typeof arg === 'object' && arg instanceof XMLHttpRequest;
}

export function swallowXMLHttpRequestWithStatus(error: unknown, status: number) {
  if (!(isXMLHttpRequest(error) && error.status === status)) {
    throw error;
  }
}

export function isAbortedXMLHttpRequest(arg: any): arg is XMLHttpRequest {
  return isXMLHttpRequest(arg) && arg.readyState === 0 && arg.status === 0;
}

export function parseError(exception: unknown, error?: unknown): Error {
  if (isString(exception)) {
    if (isError(error)) {
      try {
        error.message = `${exception}: ${error.message}`;
      } catch {
        // if error.message is getter-only, return the original error message.
      }

      return error;
    }

    if (isXMLHttpRequest(error)) {
      const message = `${exception}\n${getHttpRequestErrorMessage(error.status)}\nResponse: ${
        error.response
      }`;
      return new Error(message);
    }

    return new Error(exception);
  }

  if (isError(exception)) {
    return exception;
  }

  if (isXMLHttpRequest(exception)) {
    const message = `${getHttpRequestErrorMessage(exception.status)}\nResponse: ${
      exception.response
    }`;
    return new Error(message);
  }

  const message = `Unexpected arguments.\nFirst argument\n\tType: ${typeof exception}\n\tValue: ${JSON.stringify(
    exception,
  )}\nSecond argument\n\tType: ${typeof error}\n\tValue: ${JSON.stringify(error)}`;
  return new Error(message);
}
