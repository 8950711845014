import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_BillingHistory_Select,
  Subscription_BillingHistory_Unselect,
} from '../constants/actionTypes.ts';

export const selectedItemId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Subscription_BillingHistory_Select:
      return action.payload.billingHistoryItemId;

    case Subscription_BillingHistory_Unselect:
      return null;

    default:
      return state;
  }
};
