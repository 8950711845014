import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import { sortSpacesByName } from '../../../_shared/utils/spaces/sortSpacesByName.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';

export type SpaceOption = ISelectItem<SpaceOption>;

export const anySpacesOptionId = 'any-space-option-id';

const anySpacesOption: SpaceOption = {
  id: anySpacesOptionId,
  label: 'All spaces',
};

const getAnyRemainingSpacesOption = (remainingSpacesCount: number): SpaceOption => ({
  id: anySpacesOptionId,
  label: `All remaining spaces (${remainingSpacesCount})`,
  disabledTooltipText: 'All remaining spaces option is already selected',
});

export const getSpacesOptions = (
  spaces: SpacesMap,
  previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>,
): ReadonlyArray<SpaceOption> => {
  const sortedSpaces = sortSpacesByName(spaces.values());

  const spaceOptions = sortedSpaces.map(
    ({ id, name }): SpaceOption => ({
      id,
      label: name,
      disabledTooltipText: 'Space is already selected',
    }),
  );

  const remainingSpacesCount =
    spaceOptions.length -
    previewUrlPatterns.flatMap((previewUrlPattern) =>
      Collection.getValues(previewUrlPattern.spaces).filter(
        (spaceId) => spaceId !== anySpacesOptionId,
      ),
    ).length;

  const anySpaces =
    previewUrlPatterns.length > 1
      ? getAnyRemainingSpacesOption(remainingSpacesCount)
      : anySpacesOption;

  return [anySpaces].concat(spaceOptions);
};
