import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Typography, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import styled from 'styled-components';
import { WorkflowStepTag } from '../../../../_shared/components/Workflow/WorkflowStepTag.tsx';
import { isArchivedWorkflowStep } from '../../../../_shared/utils/contentItemUtils.ts';
import { isDefined } from '../../../../_shared/utils/filter/isDefined.ts';
import {
  IWorkflowStep,
  WorkflowStepAction,
} from '../../../../data/models/workflow/WorkflowStep.ts';

export const numberOfTagsSizeM = 3;
export const numberOfTagsSizeL = 6;

const convertListTo5ItemsPerLineString = (names: ReadonlyArray<string>): string =>
  names
    .reduce(
      (acc: string[][], name: string) => {
        if (acc.length === 1 && acc[0] && acc[0]?.length < 3) {
          return acc.map((a) => a.concat(name));
        }
        const last = Collection.getLast(acc);
        if (acc.length > 1 && last && last.length < 5) {
          last.push(name);
          return acc;
        }
        acc[acc.length] = [name];
        return acc;
      },
      [[]],
    )
    .map((line) => line.join(', '))
    .join(',\n');

const getMoreTransitionsToTag = (names: ReadonlyArray<string>): JSX.Element | null => {
  if (names.length === 0) {
    return null;
  }

  return (
    <Tooltip
      key="moreTransitionsId"
      tooltipText={`Transitions to: ${convertListTo5ItemsPerLineString(names)}`}
      placement="top"
    >
      <div className="workflow-step__text-label">{`And ${names.length} more`}</div>
    </Tooltip>
  );
};

const StyledLabel = styled.div`
  ${Typography.LabelLarge}
  color: ${colorTextLowEmphasis};
`;

const allStepsLabel = <StyledLabel>Transitions to any step</StyledLabel>;

const noStepsLabel = <StyledLabel>No transitions</StyledLabel>;

const getCollapsedTags = (
  step: IWorkflowStep,
  allSteps: ReadonlyArray<IWorkflowStep>,
  maxNumberOfTags: number,
) => {
  const numberOfTagsWithoutCollapse =
    step.transitionsTo.size > maxNumberOfTags ? maxNumberOfTags - 1 : maxNumberOfTags;
  return Collection.getValues(step.transitionsTo)
    .slice(0, numberOfTagsWithoutCollapse)
    .map((id: Uuid) => {
      const currentStep = allSteps.find((s) => !!s && s.id === id);
      return (
        currentStep && (
          <div key={currentStep.id} className="bar-item__tag">
            <WorkflowStepTag
              color={currentStep.color}
              title={currentStep.name}
              tooltipText={`Transitions to ${currentStep.name}`}
            />
          </div>
        )
      );
    })
    .concat(
      getMoreTransitionsToTag(
        Collection.getValues(step.transitionsTo)
          .slice(numberOfTagsWithoutCollapse)
          .map((id: Uuid) => allSteps.find((s: IWorkflowStep) => s.id === id)?.name)
          .filter(isDefined),
      ) || [],
    );
};

export const getWorkflowTagsForTransitionsTo = (
  stepId: Uuid,
  allSteps: ReadonlyArray<IWorkflowStep>,
  maxNumberOfTags: number,
) => {
  const step = allSteps.find(({ id }) => id === stepId);
  if (!step || step.action !== WorkflowStepAction.NoAction || isArchivedWorkflowStep(step)) {
    return null;
  }
  const numberOfStepsWithoutSchedule = allSteps.filter(
    (s: IWorkflowStep) => s.id !== step.id && s.action !== WorkflowStepAction.ScheduleToPublish,
  ).length;

  switch (step.transitionsTo.size) {
    case 0:
      return noStepsLabel;

    case numberOfStepsWithoutSchedule:
      return allStepsLabel;

    default:
      return getCollapsedTags(step, allSteps, maxNumberOfTags);
  }
};
