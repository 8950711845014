import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_CurrentSpending_Clear,
  Subscription_CurrentSpending_LoadFinished,
} from '../../../../applications/subscriptionManagement/CurrentUsage/constants/actionTypes.ts';
import { IBillingPeriodReport } from '../../../../applications/subscriptionManagement/CurrentUsage/models/BillingPeriodReport.ts';

const emptyReport: IBillingPeriodReport = {
  createdAt: '',
  extraCosts: 0,
  fupMetricSummaries: Immutable.List(),
  monthlyFee: 0,
  packagesCosts: 0,
  packageSummaries: Immutable.List(),
  paidMetricSummaries: Immutable.List(),
  periodEnd: '',
  periodStart: '',
  planId: '',
  reportId: '',
  reportType: '',
  subscriptionId: '',
  totalCosts: 0,
};

export const subscriptionCurrentSpending = (
  state: IBillingPeriodReport | null = emptyReport,
  action: Action,
): IBillingPeriodReport | null => {
  switch (action.type) {
    case Subscription_CurrentSpending_Clear:
      return emptyReport;

    case Subscription_CurrentSpending_LoadFinished:
      return action.payload.report;

    default:
      return state;
  }
};
