import { notNullNorUndefined } from '@kontent-ai/utils';
import React from 'react';
import { PortalPlaceholder } from '../../../../../../_shared/components/PortalPlaceholder.tsx';

export const getAiElementStatusPlaceholderId = (
  elementId: Uuid,
  contentComponentId?: Uuid | null,
): string => `ai_status_${[contentComponentId, elementId].filter(notNullNorUndefined).join('_')}`;

export const AiElementStatusPlaceholder: React.FC<{
  readonly contentComponentId?: Uuid | null;
  readonly elementId: Uuid;
}> = ({ contentComponentId, elementId }) => (
  <PortalPlaceholder id={getAiElementStatusPlaceholderId(elementId, contentComponentId)} />
);
