import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  AssetLibrary_AssetEditorDialog_InitFinished,
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitFinished,
  AssetLibrary_AssetEditor_InitStarted,
} from '../../constants/assetLibraryActionTypes.ts';

export function assetEditorLoadingStatus(
  state = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditorDialog_InitStarted: {
      return LoadingStatus.Loading;
    }

    case AssetLibrary_AssetEditor_InitFinished:
    case AssetLibrary_AssetEditorDialog_InitFinished: {
      return LoadingStatus.Loaded;
    }

    default:
      return state;
  }
}
