import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SpaceDeletion, SpaceDeletionState } from '../types/spaceDeletion.type.ts';
import { DeletedSpaceListingMessage } from './DeletedSpaceListingMessage.tsx';

type Props = {
  readonly onCreate: () => void;
  readonly onRestore: (spaceId: Uuid) => void;
  readonly spaceDeletion: SpaceDeletion | null;
};

export const SpacesEmptyState: React.FC<Props> = ({ onCreate, onRestore, spaceDeletion }) => {
  return (
    <Stack spacing={Spacing.XL}>
      {spaceDeletion?.state === SpaceDeletionState.Done && (
        <DeletedSpaceListingMessage deletedSpace={spaceDeletion.data} onRestore={onRestore} />
      )}
      <EmptyState size="shrink">
        <EmptyState.Title>Add more context to your content</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>
              Do you need to sort out your content for different brands or channels? Spaces help you
              with that!
            </p>
            <p>Tip: With spaces, you can have multiple previews set up as well.</p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
        <EmptyState.Footer>
          <Button
            buttonStyle="primary"
            onClick={onCreate}
            {...getDataUiActionAttribute(DataUiAction.CreateFirst)}
          >
            Create new space
          </Button>
        </EmptyState.Footer>
      </EmptyState>
    </Stack>
  );
};

SpacesEmptyState.displayName = 'SpacesEmptyState';
