import React, { useContext } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { isMissionControlFeatureEnabled } from '../selectors/isMissionControlFeatureEnabled.ts';

const IsMissionControlEapEnabled = React.createContext<boolean>(false);

export const useIsMissionControlEapEnabled = () => useContext(IsMissionControlEapEnabled);

export const IsMissionControlEapEnabledProvider: React.FC<React.PropsWithChildren<EmptyObject>> = ({
  children,
}) => {
  const isMissionControlEnabledOnCurrentProject = useSelector((state): boolean =>
    isMissionControlFeatureEnabled(state),
  );

  return (
    <IsMissionControlEapEnabled.Provider value={isMissionControlEnabledOnCurrentProject}>
      {children}
    </IsMissionControlEapEnabled.Provider>
  );
};
