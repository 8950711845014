import { SelectorType } from './orderedSelectors.ts';

export const disabledCreateButtonTooltip = 'Select from the options to create the item.';
export const disabledContentTypeSelectorTooltip = 'You have only one content type available.';
export const disabledCollectionSelectorTooltip = 'You have only one collection available.';

export const selectorHeaders: Record<SelectorType, string> = {
  [SelectorType.Collection]: 'Select a collection',
  [SelectorType.ContentType]: 'Select a content type',
  [SelectorType.Language]: 'Select a language',
  [SelectorType.Name]: 'Name your root item',
  [SelectorType.Workflow]: 'Select a workflow',
};
