import { History, Location } from 'history';
import { ReactNode } from 'react';
import { match as Match, useHistory, useLocation, useRouteMatch } from 'react-router';

export interface IRouteContext<MatchType extends AnyObject> {
  readonly match: Match<MatchType>;
  readonly history: History<MatchType>;
  readonly location: Location<MatchType>;
}

export interface IRouteContextProps<MatchType extends AnyObject> {
  readonly children: (params: IRouteContext<MatchType>) => ReactNode;
}

/**
 * @deprecated Use the react-router properly instead of this helper thing.
 */
export const RouteContext = <MatchType extends AnyObject>({
  children,
}: IRouteContextProps<MatchType>) => {
  const match = useRouteMatch<MatchType>();
  const history = useHistory<MatchType>();
  const location = useLocation<MatchType>();

  return children({
    match,
    history,
    location,
  });
};
