import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentRevisions_ComparisonRevisionLoaded,
  ContentRevisions_RevisionLoaded,
} from '../../features/Revisions/constants/revisionActionTypes.ts';

const initialState: Uuid | null = null;

export function lastSelectedRevisionId(state = initialState, action: Action): Uuid | null {
  switch (action.type) {
    case ContentRevisions_RevisionLoaded:
      return action.payload.selectedRevision.itemId;

    case ContentRevisions_ComparisonRevisionLoaded:
      return action.payload.comparisonRevision.itemId;

    default:
      return state;
  }
}
