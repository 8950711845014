import { Button } from '@kontent-ai/component-library/Button';
import { NotificationBarSuccess } from '@kontent-ai/component-library/NotificationBar';
import { ReactNode } from 'react';

type Props = {
  readonly buttonMessage: string;
  readonly message: ReactNode;
  readonly onButtonClick: () => void;
  readonly onClose: () => void;
};

export const OnboardingNotificationBar = ({
  buttonMessage,
  message,
  onButtonClick,
  onClose,
}: Props) => {
  return (
    <div className="onboarding-notification-bar__pane">
      <NotificationBarSuccess
        actions={{
          alignment: 'left',
          renderActions: () => (
            <Button size="small" buttonStyle="primary-inverse" onClick={onButtonClick}>
              {buttonMessage}
            </Button>
          ),
        }}
        onDismiss={onClose}
        {...{ className: 'onboarding-notification-bar' }}
      >
        <span>{message}</span>
      </NotificationBarSuccess>
    </div>
  );
};
