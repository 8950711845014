import Immutable from 'immutable';
import { ILanguageServerModel } from '../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';

export interface ILanguage {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string;
  readonly fallbackLanguageId: Uuid | null;
  readonly inactive: boolean;
  readonly etag: string;
}

export type Languages = Immutable.OrderedMap<Uuid, ILanguage>;

export function createLanguage(props?: Partial<ILanguage>): ILanguage {
  return {
    id: '',
    name: '',
    codename: '',
    fallbackLanguageId: null,
    inactive: false,
    etag: '',
    ...props,
  };
}

export function createLanguageFromServerModel(serverModel: ILanguageServerModel): ILanguage {
  const emptyObject = createLanguage();
  return {
    codename: serverModel.codeName ?? emptyObject.codename,
    name: serverModel.name ?? emptyObject.name,
    id: serverModel.id ?? emptyObject.id,
    fallbackLanguageId: serverModel.fallbackLanguageId ?? emptyObject.fallbackLanguageId,
    inactive: serverModel.inactive ?? emptyObject.inactive,
    etag: serverModel.etag,
  };
}

export function createLanguageServerModel({ codename, ...rest }: ILanguage): ILanguageServerModel {
  return {
    ...rest,
    codeName: codename,
  };
}
