import { Box } from '@kontent-ai/component-library/Box';
import {
  Spacing,
  spacingElementEdgeHorizontal,
  spacingElementEdgeVertical,
} from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';

type Props = {
  readonly children: ReactNode;
};

export const WorkflowSettingsPage = ({ children }: Props) => (
  <>
    <Box
      paddingTop={spacingElementEdgeVertical}
      paddingX={spacingElementEdgeHorizontal}
      css={`
        &:empty {
          display: none;
        }
      `}
    >
      <NotificationBar />
    </Box>
    <Box padding={Spacing.XL} className="canvas" overflow="auto">
      {children}
    </Box>
  </>
);
