import { ContentState } from 'draft-js';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { AiActionName } from '../AiActionName.type.ts';
import { DraftJsOperationInput } from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import { CumulatedOperationResponseMessageTemplate } from '../AiServerModels.response.ts';
import { CumulatedDraftJsResult } from '../AiServerModels.result.ts';
import {
  InlineInstructionBaseInputParams,
  InlineInstructionBaseInputs,
  getInlineInstructionBaseInputs,
} from './AiServerModels.inlineInstruction.ts';

export type InlineRichTextInstructionParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: AiActionName.RichTextInlineInstruction;
    readonly inputs: InlineInstructionBaseInputs & {
      readonly content: DraftJsOperationInput;
    };
  };

type InlineRichTextInstructionInputParams = InlineInstructionBaseInputParams & {
  readonly contentState: ContentState;
};

export const createInlineRichTextInstructionParams = (
  { contentState, ...baseParams }: InlineRichTextInstructionInputParams,
  trackingData: ElementOperationTrackingData,
): InlineRichTextInstructionParams => ({
  actionName: AiActionName.RichTextInlineInstruction,
  type: 'multiple-inputs-request-v1',
  inputs: {
    ...getInlineInstructionBaseInputs(baseParams),
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
  },
  trackingData,
});

export type InlineRichTextInstructionResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;
