import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForAssets } from '../../../../_shared/selectors/contentCollections.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getTimeAndAuthorOfEntityChange } from '../../../../_shared/utils/entityChangeUtils.ts';
import { AssetDetail } from '../components/AssetDetail.tsx';

export interface IAssetDetailOwnProps {
  readonly assetId?: Uuid | null;
  readonly isUncategorized: boolean | null;
}

export const AssetDetailContainer: React.FC<IAssetDetailOwnProps> = ({
  assetId,
  isUncategorized,
}) => {
  const assetKey = assetId || '';
  const asset = useSelector((state) => state.data.assets.byId.get(assetKey));
  const currentUserId = useSelector((state) => state.data.user.info.userId);
  const projectContributors = useSelector((state) => state.data.users.usersById);
  const isCollectionSectionVisible = useSelector((state) =>
    areCollectionsVisibleForAssets(state, Collection.getValues(state.data.collections.byId)),
  );
  const collectionName = useSelector(getEditedAssetCollectionName);

  if (!asset) {
    return null;
  }

  const createdText = getTimeAndAuthorOfEntityChange({
    currentUserId,
    projectContributors,
    entityChangedAt: asset.created,
    entityChangedBy: asset.createdBy,
  });

  const lastModifiedText = getTimeAndAuthorOfEntityChange({
    currentUserId,
    projectContributors,
    entityChangedAt: asset.lastModified,
    entityChangedBy: asset.lastModifiedBy,
  });

  return (
    <AssetDetail
      collectionName={collectionName}
      createdText={createdText}
      fileName={asset.filename}
      fileSize={asset.fileSize}
      height={asset.height}
      isCollectionSectionVisible={isCollectionSectionVisible}
      isUncategorized={isUncategorized}
      lastModifiedText={lastModifiedText}
      tags={asset.tags}
      width={asset.width}
    />
  );
};

AssetDetailContainer.displayName = 'AssetDetailContainer';

export { AssetDetailContainer as AssetDetail };

const getEditedAssetCollectionName = (state: IStore): string | null => {
  const collectionId = state.assetLibraryApp.assetUiEditor.editedAsset?.collectionId;

  const collection = collectionId ? state.data.collections.byId.get(collectionId) : null;

  return collection?.name ?? 'The asset isn’t in any collection';
};
