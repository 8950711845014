export enum OrderByDirection {
  None = 'None',
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export type OrderBy<TColumnCode> = {
  readonly columnCode: TColumnCode;
  readonly direction: OrderByDirection;
};
