import { connect } from 'react-redux';
import { Dispatch, ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { withAutoDispatcher } from '../../../../../../../_shared/components/AutoDispatcher.tsx';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  IScrollTableCallbacksProps,
  IScrollTableDataProps,
  IScrollTableOwnProps,
  ScrollTable,
} from '../../../../../../../_shared/uiComponents/ScrollTable/ScrollTable.tsx';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { shouldDispatchOnScrollStateChange } from '../../../../../../../_shared/utils/scrollTableUtils.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getContentItemScrollTableRowHeightPx } from '../../../../constants/uiConstants.ts';
import { ScrollTableState } from '../../../../models/ScrollTableState.type.ts';
import { contentListingScrollTableStateUpdated } from '../../actions/contentItemInventoryActions.ts';

function mapStateToProps(
  state: IStore,
  ownProps: IScrollTableOwnProps<IListingContentItem>,
): IScrollTableDataProps<IListingContentItem> {
  return {
    collectionName: DataUiCollection.ContentItems,
    currentProjectId: state.sharedApp.currentProjectId,
    itemHeight: getContentItemScrollTableRowHeightPx(),
    items: ownProps.items,
    scrollTableState: state.contentApp.listingUi.contentItemListingScrollTableState,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IScrollTableCallbacksProps {
  return {
    onChange: (state: ScrollTableState) => dispatch(contentListingScrollTableStateUpdated(state)),
  };
}

interface IScrollTableObservedState {
  readonly availableHeightPx: number;
  readonly onLoadContentItems: ThunkPromise;
  readonly scrollPositionPx: number;
}

function mapObservedState(
  state: IStore,
  ownProps: IScrollTableOwnProps<IListingContentItem>,
): IScrollTableObservedState {
  return {
    ...state.contentApp.listingUi.contentItemListingScrollTableState,
    onLoadContentItems: ownProps.onLoadContentItems,
  };
}

export const ContentItemScrollTable = withAutoDispatcher<
  IScrollTableOwnProps<IListingContentItem>,
  IScrollTableObservedState
>(
  mapObservedState,
  (props) => props.onLoadContentItems,
  100,
  shouldDispatchOnScrollStateChange,
)(connect(mapStateToProps, mapDispatchToProps)(ScrollTable));
