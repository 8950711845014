import Immutable from 'immutable';
import { ILanguage } from '../../../../../../data/models/languages/Language.ts';
import { IAssetService } from '../../../../../contentInventory/content/features/Asset/services/assetService.ts';
import { IAssetDescription, IAssetDetails } from '../../../../types/CustomElementApi.ts';

const toAssetDetailsDescription = (
  language: ILanguage | undefined,
  description: string,
  defaultLanguage: ILanguage,
): IAssetDescription => {
  const foundLanguage = language || defaultLanguage;
  return {
    language: {
      id: foundLanguage.id,
      codename: foundLanguage.codename,
    },
    description,
  };
};

interface ICreateGetAssetDetailsDeps {
  readonly assetService: IAssetService;
}

export const createGetAssetDetails =
  (deps: ICreateGetAssetDetailsDeps) =>
  async (
    assetIds: UuidArray,
    languages: Immutable.OrderedMap<Uuid, ILanguage>,
    defaultLanguage: ILanguage,
  ): Promise<ReadonlyArray<IAssetDetails> | null> => {
    try {
      const assets = await deps.assetService.getAssetsByIds(assetIds);

      return assets
        .filter((asset) => !asset.archived)
        .map((asset) => {
          return {
            fileName: asset.filename,
            descriptions: asset.descriptions
              .map((description: string, key: Uuid) =>
                toAssetDetailsDescription(languages.get(key), description, defaultLanguage),
              )
              .toArray(),
            id: asset.id,
            imageHeight: asset.height,
            imageWidth: asset.width,
            name: asset.filename,
            size: asset.fileSize,
            thumbnailUrl: asset._downloadLink,
            title: asset.title,
            type: asset.type,
            url: asset._deliveryLink,
          };
        });
    } catch {
      return null;
    }
  };
