import { Dispatch, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../models/TrackUserEvent.type.ts';
import { ProductUpdateOpenedAtServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { logError } from '../../utils/logError.ts';

interface IDependencies {
  readonly upsertUserProperty: (propertyKey: string, propertyValue: string) => ThunkPromise;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createProductUpdateLinkOpenedAction =
  ({ upsertUserProperty, trackUserEventWithData }: IDependencies) =>
  (url: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const visitedAt = new Date().toUTCString();

    try {
      await dispatch(upsertUserProperty(ProductUpdateOpenedAtServerKey, visitedAt));
      dispatch(
        trackUserEventWithData(TrackedEvent.FeatureUsed, {
          feature: 'product-update--opened',
          info: url,
        }),
      );
    } catch (e) {
      logError(e);
    }
  };
