import { Action } from '../../../../@types/Action.type.ts';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  ServerErrorStatusMessage,
  StatusMessage,
} from '../../../../_shared/models/StatusMessage.ts';
import {
  PreviewConfiguration_Saving_Finished,
  PreviewConfiguration_Saving_Started,
  PreviewConfiguration_ServerError_Show,
} from '../../constants/previewConfigurationActionTypes.ts';

export function status(state = SavedStatusMessage, action: Action): StatusMessage {
  switch (action.type) {
    case PreviewConfiguration_ServerError_Show:
      return ServerErrorStatusMessage;

    case PreviewConfiguration_Saving_Started:
      return SavingStatusMessage;

    case PreviewConfiguration_Saving_Finished:
      return SavedStatusMessage;

    default:
      return state;
  }
}
