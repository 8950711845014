import { combineReducers } from '@reduxjs/toolkit';
import { collectionsRecentlyUsedInEditing } from './uiPreferences/collectionsRecentlyUsedInEditing.ts';
import { collectionsRecentlyUsedInListing } from './uiPreferences/collectionsRecentlyUsedInListing.ts';
import { contentTypesRecentlyUsedInEditing } from './uiPreferences/contentTypesRecentlyUsedInEditing.ts';
import { contentTypesRecentlyUsedInListing } from './uiPreferences/contentTypesRecentlyUsedInListing.ts';

export const uiPreferences = combineReducers({
  collectionsRecentlyUsedInEditing,
  collectionsRecentlyUsedInListing,
  contentTypesRecentlyUsedInEditing,
  contentTypesRecentlyUsedInListing,
});
