import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IRoleWithSettings } from '../../../../../data/models/roles/IRoleWithSettings.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { UnableToDeleteErrorMessage } from '../../constants/UIConstants.ts';
import {
  Role_Delete_Failed,
  Role_Delete_Finished,
  Role_Delete_Started,
} from '../../constants/rolesActionTypes.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'deleteRole'>;
}

const started = () =>
  ({
    type: Role_Delete_Started,
  }) as const;

const finished = (role: IRoleWithSettings) =>
  ({
    type: Role_Delete_Finished,
    payload: { role },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Role_Delete_Failed,
    payload: { errorMessage },
  }) as const;

export type DeleteEditedRoleActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const makeDeleteEditedRoleAction =
  (deps: IDeps) =>
  (originalEditedRole: IRoleWithSettings): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());
      await deps.roleRepository.deleteRole(originalEditedRole.id);
      dispatch(finished(originalEditedRole));
    } catch (error) {
      dispatch(failed(UnableToDeleteErrorMessage));
      throw error;
    }
  };
