import { Direction } from '@kontent-ai/types';
import { assert } from '@kontent-ai/utils';
import { ContentBlock, ContentState, SelectionState } from 'draft-js';
import { BaseBlockType } from '../../../utils/blocks/blockType.ts';
import {
  findParentBlock,
  findSiblingBlock,
  getParentBlockTypes,
  isBlockNestedIn,
  isEmptyParagraph,
  isTableCell,
} from '../../../utils/blocks/blockTypeUtils.ts';
import {
  IRawBlock,
  createContent,
  createContentFromRawBlocks,
  createEmptyRawParagraph,
  findBlockIndex,
  getUnstyledBlock,
  updateBlockDepth,
} from '../../../utils/blocks/editorBlockUtils.ts';
import {
  createSelection,
  moveCaretToBlock,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  insertBlocksAtSelection,
} from '../../../utils/general/editorContentUtils.ts';
import { getCoordinatesFromDepth } from './depthCoordinatesConversion.ts';
import { createEmptyCell, getRawTable, getRawTableCellBlocks } from './tableGenerator.ts';

function getRowLength(blocks: ReadonlyArray<ContentBlock>, rowStartIndex: number): number {
  const firstCellInRow = blocks[rowStartIndex];
  assert(
    firstCellInRow,
    () => `${__filename}.getRowLength: The item at index ${rowStartIndex} is not a content block.`,
  );
  assert(
    isTableCell(firstCellInRow),
    () => `${__filename}.getRowLength: The item at index ${rowStartIndex} is not a table cell.`,
  );

  const firstCellCoords = getCoordinatesFromDepth(firstCellInRow.getDepth());

  let maxX = firstCellCoords.x;
  let index = rowStartIndex;
  do {
    const nextCell = findSiblingBlock(index, blocks, Direction.Forward);
    if (!nextCell.block || !isTableCell(nextCell.block)) {
      break;
    }
    const nextCoords = getCoordinatesFromDepth(nextCell.block.getDepth());
    if (nextCoords.y !== firstCellCoords.y) {
      break;
    }
    maxX = Math.max(maxX, nextCoords.x);
    index = nextCell.index;
  } while (index < blocks.length);

  return maxX + 1;
}

function getNewRow(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  rowLength: number,
  y: number,
): ReadonlyArray<ContentBlock> {
  const newRawBlocks: Array<IRawBlock> = [];
  for (let x = 0; x < rowLength; x++) {
    newRawBlocks.push(...getRawTableCellBlocks({ x, y }));
  }

  return getBlocks(createContentFromRawBlocks(parentBlockTypes, newRawBlocks));
}

function moveCellsOneRow(
  blocks: ReadonlyArray<ContentBlock>,
  direction: Direction,
): ReadonlyArray<ContentBlock> {
  const newBlocks = [...blocks];
  const offset = direction === Direction.Backward ? -1000 : 1000;

  let index = 0;
  do {
    const block = newBlocks[index];
    if (!block || !isTableCell(block)) {
      break;
    }
    newBlocks[index] = updateBlockDepth(block, (depth) => depth + offset);
    const nextCell = findSiblingBlock(index, newBlocks, Direction.Forward);
    if (!nextCell.block) {
      break;
    }
    index = nextCell.index;
  } while (index < blocks.length);

  return newBlocks;
}

function removeColumnFromBlocks(
  blocks: ReadonlyArray<ContentBlock>,
  tableStartIndex: number,
  x: number,
): ReadonlyArray<ContentBlock> {
  const newBlocks = [...blocks];

  let index = tableStartIndex;
  do {
    const block = newBlocks[index];
    if (!block || !isTableCell(block)) {
      break;
    }
    const coords = getCoordinatesFromDepth(block.getDepth());
    const nextCell = findSiblingBlock(index, newBlocks, Direction.Forward);

    if (coords.x > x) {
      newBlocks[index] = updateBlockDepth(block, (depth) => depth - 1);
    } else if (coords.x === x) {
      // Remove cell and continue
      newBlocks.splice(index, (nextCell?.index ?? newBlocks.length) - index);
      continue;
    }

    if (!nextCell.block) {
      break;
    }
    index = nextCell.index;
  } while (index < blocks.length);

  return newBlocks;
}

function insertColumnToBlocks(
  blocks: ReadonlyArray<ContentBlock>,
  tableStartIndex: number,
  x: number,
): ReadonlyArray<ContentBlock> {
  const newBlocks = [...blocks];
  const isLastCellInRow = (nextBlock: ContentBlock | null, rowY: number): boolean =>
    !nextBlock ||
    !isTableCell(nextBlock) ||
    getCoordinatesFromDepth(nextBlock.getDepth()).y !== rowY;

  const tableStartBlock = blocks[tableStartIndex];
  assert(
    tableStartBlock,
    () => `${__filename}: Table start block at index ${tableStartIndex} is not a content block.`,
  );
  const parentBlockTypes = getParentBlockTypes(tableStartBlock);

  let index = tableStartIndex;
  do {
    const block = newBlocks[index];
    if (!block || !isTableCell(block)) {
      break;
    }
    const coords = getCoordinatesFromDepth(block.getDepth());
    const nextCell = findSiblingBlock(index, newBlocks, Direction.Forward);

    // Shift existing cells right
    if (coords.x >= x) {
      newBlocks[index] = updateBlockDepth(block, (depth) => depth + 1);
    }

    const isReplacingExisting = coords.x === x;
    if (isReplacingExisting || (coords.x < x && isLastCellInRow(nextCell.block, coords.y))) {
      // Insert new cell
      const newCell = createEmptyCell(parentBlockTypes, { x, y: coords.y });
      const newCellBlocks = [newCell.cellBlock, ...newCell.contentBlocks];

      const targetIndex = isReplacingExisting ? index : nextCell.index;
      newBlocks.splice(targetIndex, 0, ...newCellBlocks);

      index = nextCell.index + newCellBlocks.length;
      if (!nextCell.block) {
        break;
      }
      continue;
    }

    if (!nextCell.block) {
      break;
    }
    index = nextCell.index;
  } while (index < newBlocks.length);

  return newBlocks;
}

function getRowStartIndex(
  blocks: ReadonlyArray<ContentBlock>,
  starterCellIndex: number,
  direction: Direction,
): number | null {
  const starterCell = blocks[starterCellIndex];
  if (!starterCell || !isTableCell(starterCell)) {
    return null;
  }

  const starterCellCoords = getCoordinatesFromDepth(starterCell.getDepth());

  let rowStartIndex = starterCellIndex;
  while (rowStartIndex > 0 && rowStartIndex < blocks.length) {
    const siblingCell = findSiblingBlock(rowStartIndex, blocks, direction);
    if (!siblingCell.block || !isTableCell(siblingCell.block)) {
      // No more rows in the forward direction
      if (direction === Direction.Forward) {
        return null;
      }
      break;
    }
    const previousCoords = getCoordinatesFromDepth(siblingCell.block.getDepth());
    if (previousCoords.y !== starterCellCoords.y) {
      if (direction === Direction.Forward) {
        return siblingCell.index;
      }
      break;
    }
    rowStartIndex = siblingCell.index;
  }

  return rowStartIndex;
}

function getTableStartIndex(
  blocks: ReadonlyArray<ContentBlock>,
  starterCellIndex: number,
): number | null {
  const starterCell = blocks[starterCellIndex];
  if (!starterCell || !isTableCell(starterCell)) {
    return null;
  }

  let tableStartIndex = starterCellIndex;
  while (tableStartIndex > 0) {
    const previous = findSiblingBlock(tableStartIndex, blocks, Direction.Backward);
    if (!previous.block || !isTableCell(previous.block)) {
      break;
    }
    tableStartIndex = previous.index;
  }

  return tableStartIndex;
}

function getTableEndIndex(
  blocks: ReadonlyArray<ContentBlock>,
  starterCellIndex: number,
): number | null {
  const starterCell = blocks[starterCellIndex];
  if (!starterCell || !isTableCell(starterCell)) {
    return null;
  }

  let tableEndIndex = starterCellIndex;
  while (tableEndIndex < blocks.length) {
    const nextCell = findSiblingBlock(tableEndIndex, blocks, Direction.Forward);
    if (!nextCell.block || !isTableCell(nextCell.block)) {
      // Next block is after current table
      return nextCell.index;
    }
    tableEndIndex = nextCell.index;
  }

  // Reached the end of the content
  return blocks.length;
}

export function insertRowAbove(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const rowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Backward);
  if (!rowStartIndex) {
    return input;
  }

  // Insert new row
  const rowLength = getRowLength(blocks, rowStartIndex);
  const cellCoordinates = getCoordinatesFromDepth(starterCellBlock.getDepth());
  const parentBlockTypes = getParentBlockTypes(starterCellBlock);
  const newRowBlocks = getNewRow(parentBlockTypes, rowLength, cellCoordinates.y);
  const newBlocks = [
    ...blocks.slice(0, rowStartIndex),
    ...newRowBlocks,
    ...moveCellsOneRow(blocks.slice(rowStartIndex), Direction.Forward),
  ];

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, selection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection,
  };
}

export function insertRowBelow(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const rowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Backward);
  if (rowStartIndex === null) {
    return input;
  }

  // Make room for the new row
  const nextRowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Forward);

  const rowEndIndex =
    nextRowStartIndex ?? getTableEndIndex(blocks, starterCellIndex) ?? rowStartIndex;
  if (rowEndIndex === rowStartIndex) {
    return input;
  }

  // Insert new row
  const rowLength = getRowLength(blocks, rowStartIndex);
  const cellCoordinates = getCoordinatesFromDepth(starterCellBlock.getDepth());
  const parentBlockTypes = getParentBlockTypes(starterCellBlock);
  const newRowBlocks = getNewRow(parentBlockTypes, rowLength, cellCoordinates.y + 1);
  const newBlocks = [
    ...blocks.slice(0, rowEndIndex),
    ...newRowBlocks,
    ...moveCellsOneRow(blocks.slice(rowEndIndex), Direction.Forward),
  ];

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, selection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection,
  };
}

export function removeRow(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const rowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Backward);
  if (rowStartIndex === null) {
    return input;
  }

  // Pull the next rows up
  const nextRowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Forward);

  // Remove the row
  const rowEndIndex =
    nextRowStartIndex ?? getTableEndIndex(blocks, starterCellIndex) ?? rowStartIndex;
  if (rowEndIndex === rowStartIndex) {
    return input;
  }
  const newBlocks = [
    ...blocks.slice(0, rowStartIndex),
    ...moveCellsOneRow(blocks.slice(rowEndIndex), Direction.Backward),
  ];

  const nonEmptyBlocks = newBlocks.length ? newBlocks : [getUnstyledBlock([])];

  // Selection after deleting the row is placed just before the row, or to the start of the next block if there are no blocks before
  const newIndex = Math.max(rowStartIndex - 1, 0);
  const newSelectionBlock = nonEmptyBlocks[newIndex];
  assert(
    newSelectionBlock,
    () =>
      `${__filename}.removeRow: New selection block at index ${newIndex} is not a content block.`,
  );
  const newSelectionOffset = rowStartIndex === 0 ? 0 : newSelectionBlock.getLength();
  const newSelection = createSelection(newSelectionBlock.getKey(), newSelectionOffset);

  const newContent = createContent(nonEmptyBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

export function insertColumnLeft(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const rowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Backward);
  if (rowStartIndex === null) {
    return input;
  }

  const tableStartIndex = getTableStartIndex(blocks, rowStartIndex);
  if (tableStartIndex === null) {
    return input;
  }

  const cellCoordinates = getCoordinatesFromDepth(starterCellBlock.getDepth());
  const newBlocks = insertColumnToBlocks(blocks, tableStartIndex, cellCoordinates.x);

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, selection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection,
  };
}

export function insertColumnRight(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const cellCoordinates = getCoordinatesFromDepth(starterCellBlock.getDepth());
  const rowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Backward);
  if (rowStartIndex === null) {
    return input;
  }

  const tableStartIndex = getTableStartIndex(blocks, rowStartIndex);
  if (tableStartIndex === null) {
    return input;
  }

  const newBlocks = insertColumnToBlocks(blocks, tableStartIndex, cellCoordinates.x + 1);

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, selection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection,
  };
}

export function removeColumn(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const cellCoordinates = getCoordinatesFromDepth(starterCellBlock.getDepth());
  const rowStartIndex = getRowStartIndex(blocks, starterCellIndex, Direction.Backward);
  if (rowStartIndex === null) {
    return input;
  }

  const tableStartIndex = getTableStartIndex(blocks, starterCellIndex);
  if (tableStartIndex === null) {
    return input;
  }

  // Remove the column
  const newBlocks = removeColumnFromBlocks(blocks, tableStartIndex, cellCoordinates.x);

  // Put selection just before the deleted content
  const blockBefore = blocks[starterCellIndex - 1];
  const newSelection = blockBefore ? createSelection(blockBefore.getKey()) : selection;

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

export function removeTable(
  input: IContentChangeInput,
  starterCellBlock: ContentBlock,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const starterCellIndex = findBlockIndex(blocks, starterCellBlock.getKey());
  if (starterCellIndex < 0) {
    return input;
  }

  const tableStartIndex = getTableStartIndex(blocks, starterCellIndex);
  if (tableStartIndex === null) {
    return input;
  }

  const tableEndIndex = getTableEndIndex(blocks, starterCellIndex);
  if (tableEndIndex === null) {
    return input;
  }

  // Delete table
  const tablePreviousSiblingBlock = blocks[tableStartIndex - 1];
  const removeFromIndex = isEmptyParagraph(tablePreviousSiblingBlock)
    ? tableStartIndex - 1
    : tableStartIndex;
  const tableLastBlock = blocks[tableEndIndex] ?? null;
  const removeToIndex = isEmptyParagraph(tableLastBlock) ? tableEndIndex + 1 : tableEndIndex;
  const newBlocks = [...blocks.slice(0, removeFromIndex), ...blocks.slice(removeToIndex)];

  if (newBlocks.length === 0) {
    newBlocks.push(getUnstyledBlock([]));
  }

  const nonEmptyBlocks = newBlocks.length ? newBlocks : [getUnstyledBlock([])];

  // Selection after deleting the table is placed just before the table, or to the start of the next block if there are no blocks before
  const newIndex = Math.max(removeFromIndex - 1, 0);
  const newSelectionBlock = nonEmptyBlocks[newIndex];
  assert(
    newSelectionBlock,
    () =>
      `${__filename}.removeTable: New selection block at index ${newIndex} is not a content block.`,
  );
  const newSelectionOffset = removeFromIndex === 0 ? 0 : newSelectionBlock.getLength();
  const newSelection = createSelection(newSelectionBlock.getKey(), newSelectionOffset);

  const newContent = createContent(nonEmptyBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

export function insertTable(
  input: IContentChangeInput,
  x: number,
  y: number,
): IContentChangeResult {
  const tableBlocks = getRawTable(x, y);
  const newBlocks = [createEmptyRawParagraph(), ...tableBlocks, createEmptyRawParagraph()];

  const withInsertedTable = insertBlocksAtSelection(input, newBlocks);
  const firstTableBlock = tableBlocks[0];
  assert(!!firstTableBlock, () => `${__filename}.insertTable: Item at index 0 is falsy.`);
  const withSelectionInFirstCell = moveCaretToBlock(withInsertedTable, firstTableBlock.key);

  return withSelectionInFirstCell;
}

export function getClosestCollapsedSelectionInTableCell(
  content: ContentState,
  selection: SelectionState,
  cellBlockKey: string,
): SelectionState {
  const blocks = getBlocks(content);
  const focusKey = selection.getFocusKey();
  const focusBlockIndex = findBlockIndex(blocks, focusKey);
  if (
    focusBlockIndex >= 0 &&
    findParentBlock(focusBlockIndex, blocks)?.block.getKey() === cellBlockKey
  ) {
    // Focus (caret) is inside the table cell, keep that
    return selection.isCollapsed()
      ? selection
      : createSelection(focusKey, selection.getFocusOffset());
  }

  const anchorKey = selection.getAnchorKey();
  const anchorBlockIndex = findBlockIndex(blocks, anchorKey);
  if (
    anchorBlockIndex >= 0 &&
    findParentBlock(anchorBlockIndex, blocks)?.block.getKey() === cellBlockKey
  ) {
    // Anchor is inside the table cell, keep that
    return createSelection(anchorKey, selection.getAnchorOffset());
  }

  // Otherwise just place the caret to the beginning of the table cell
  const cellBlock = content.getBlockForKey(cellBlockKey);
  const firstCellContentBlock = content.getBlockAfter(cellBlockKey);
  if (firstCellContentBlock && isBlockNestedIn(firstCellContentBlock, cellBlock)) {
    return createSelection(firstCellContentBlock.getKey());
  }
  return createSelection(cellBlockKey);
}
