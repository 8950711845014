import classNames from 'classnames';
import React from 'react';
import { DataUiElement } from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { StatusMessage } from './StatusMessage.tsx';

interface IRegexValidationStatusProps {
  readonly isHidden?: boolean;
  readonly isRegexValidationMet: boolean;
  readonly message: string;
}

export const RegexValidationStatus: React.FC<IRegexValidationStatusProps> = ({
  isHidden,
  isRegexValidationMet,
  message,
}): JSX.Element => {
  return (
    <span
      className={classNames('content-item-element__status-segment', {
        'content-item-element__status-segment--is-hidden': isHidden,
      })}
    >
      <StatusMessage
        isInvalid={!isRegexValidationMet}
        message={message}
        dataUiElement={DataUiElement.ElementLimitations}
      />
    </span>
  );
};

RegexValidationStatus.displayName = 'RegexValidationStatus';
