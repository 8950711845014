import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  ProvideANonNegativeNumberForLimitLength,
  ProvideAPositiveNumberForLimitLength,
} from '../../../constants/errorMessageTemplates.ts';
import {
  IBaseTypeElementData,
  ITypeElementDataWithTextLimit,
} from '../../../models/elements/types/TypeElementData.ts';
import { BaseTextTypeElementValidationResult } from '../../../utils/typeElementValidators/types/BaseTextTypeElementValidationResult.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';

type BaseTextTypeElementData = IBaseTypeElementData & ITypeElementDataWithTextLimit;

export function getBaseTextTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: BaseTextTypeElementData,
  allowZeroLimits = false,
): BaseTextTypeElementValidationResult {
  const { isLimitValueValid, getNameValidationResult, getGuidelinesValidationResult } =
    typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);
  const maxChars = typeElement.maxChars;
  const maxWords = typeElement.maxWords;
  const isMaxCharsValid = isLimitValueValid(maxChars) || (allowZeroLimits && maxChars === '0');
  const isMaxWordsValid = isLimitValueValid(maxWords) || (allowZeroLimits && maxWords === '0');

  const errors: Array<string> = [];
  if (!isMaxCharsValid || !isMaxWordsValid) {
    errors.push(
      allowZeroLimits
        ? ProvideANonNegativeNumberForLimitLength
        : ProvideAPositiveNumberForLimitLength,
    );
  }

  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errors.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errors.push(guidelinesValidationResult.errorMessage);
  }

  return {
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isMaxCharsValid,
    isMaxWordsValid,
    errorMessages: errors,
  };
}

export const createValidateBaseTextTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch) => {
    const validationResult = getBaseTextTypeElementValidationResult(
      deps,
      typeElement as BaseTextTypeElementData,
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
  };
