import { usePrevious } from '@kontent-ai/hooks';
import {
  ICancellablePromise,
  areShallowEqual,
  delay,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import { useCallback, useEffect, useRef, useState } from 'react';

type UseGradualSequenceOptions = {
  readonly interval?: number;
  readonly chunkSize?: number;
  readonly maxChunks?: number;
};

const DefaultMaxChunks = 100;
const DefaultInterval = 0;

export const getInternalOptions = (
  options: UseGradualSequenceOptions | undefined,
  collectionLength: number,
): {
  readonly chunkSize: number;
  readonly interval: number;
} => {
  const maxChunks = Math.max(options?.maxChunks ?? Math.min(collectionLength, DefaultMaxChunks), 1);
  const minChunkSize = Math.max(Math.ceil(collectionLength / maxChunks), 1);
  const chunkSize = Math.max(options?.chunkSize ?? 1, minChunkSize);
  const interval = options?.interval ?? DefaultInterval;

  return {
    chunkSize,
    interval,
  };
};

const filterAllowed = <TItem, TKey>(
  collection: ReadonlyArray<TItem>,
  allowedOutputKeys: ReadonlySet<TKey>,
  getKey: (item: TItem) => TKey,
): ReadonlyArray<TItem> => collection.filter((item) => allowedOutputKeys.has(getKey(item)));

const useForceRerender = (): (() => void) => {
  // eslint-disable-next-line react/hook-use-state
  const [, updateState] = useState({});
  return useCallback(() => updateState({}), []);
};

/**
 * Populates the given collection in time by chunks until the whole collection is at the output
 * Use for lists of components with expensive subtrees to reduce the total continuous CPU time spent on mount
 * to give breathing space to other processes, such as animations and user input
 */
export const useGradualSequence = <TItem extends AnyObject | Uuid, TKey = TItem>(
  collection: ReadonlyArray<TItem>,
  getKey: (item: TItem) => TKey,
  options?: UseGradualSequenceOptions,
): {
  readonly renderItems: ReadonlyArray<TItem>;
  readonly isComplete: boolean;
} => {
  const allowedOutputKeys = useRef(new Set<TKey>());
  const nextPromise = useRef<ICancellablePromise | null>(null);

  const { chunkSize, interval } = getInternalOptions(options, collection.length);

  const forceRerender = useForceRerender();

  const addNext = useCallback<(collection: ReadonlyArray<TItem>) => void>(
    (_collection) => {
      const originalSize = allowedOutputKeys.current.size;
      for (let i = 0; i < chunkSize; i++) {
        const nextIndex = _collection.findIndex(
          (item) => !allowedOutputKeys.current.has(getKey(item)),
        );
        const nextItem = _collection[nextIndex];
        if (nextIndex < 0 || !nextItem) {
          break;
        }
        allowedOutputKeys.current.add(getKey(nextItem));
      }
      if (allowedOutputKeys.current.size !== originalSize) {
        forceRerender();
      }
    },
    [chunkSize, forceRerender, getKey],
  );

  const cancelNext = useCallback(() => {
    nextPromise.current?.cancel();
    nextPromise.current = null;
  }, []);

  const scheduleNext = useCallback<(collection: ReadonlyArray<TItem>) => void>(
    (_collection) => {
      cancelNext();
      nextPromise.current = delay(interval)
        .then(() => {
          addNext(_collection);
          if (allowedOutputKeys.current.size < _collection.length) {
            scheduleNext(_collection);
            return;
          }
        })
        .catch(swallowCancelledPromiseError);
    },
    [addNext, interval, cancelNext],
  );

  const previousCollection = usePrevious(collection);
  const renderItems = filterAllowed(collection, allowedOutputKeys.current, getKey);
  const isResultComplete = renderItems.length === collection.length;

  useEffect(() => {
    const needsInitialization = !isResultComplete && !nextPromise.current;
    if (needsInitialization || !areShallowEqual(collection, previousCollection)) {
      // Filter allowed only to existing ones to make sure the size of allowed items is always <= collection size
      allowedOutputKeys.current = new Set(
        filterAllowed(collection, allowedOutputKeys.current, getKey).map(getKey),
      );

      if (allowedOutputKeys.current.size === 0) {
        addNext(collection);
      }
      if (allowedOutputKeys.current.size < collection.length) {
        scheduleNext(collection);
      }
    }

    return cancelNext;
  }, [cancelNext, collection, previousCollection, scheduleNext, addNext, getKey, isResultComplete]);

  return {
    renderItems,
    isComplete: renderItems.length === collection.length,
  };
};
