import React, { useCallback } from 'react';
import { IMultipleChoiceTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { IMultipleChoiceItemElement } from '../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { MultipleChoiceInput } from '../../ContentItemEditing/components/elements/multipleChoice/MultipleChoiceInput.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

interface IMultipleChoiceProps
  extends IContentComponentItemElementOwnProps<
    IMultipleChoiceItemElement,
    IMultipleChoiceTypeElement
  > {}

export const ContentComponentMultipleChoice: React.FC<IMultipleChoiceProps> = ({
  elementData,
  typeElement,
  disabled,
  validationResultSelectorId,
  contentComponentId,
  autoFocus,
  onUpdate,
}) => {
  const onSelectedOptionsUpdate = useCallback(
    (newSelectedOptions: readonly string[]): void => {
      const updatedElement = { ...elementData, options: newSelectedOptions };
      onUpdate(updatedElement);
    },
    [elementData, onUpdate],
  );

  return (
    <ContentComponentItemElement
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
      disabled={disabled}
      contentComponentId={contentComponentId}
      isCompact
    >
      <MultipleChoiceInput
        elementData={elementData}
        typeElement={typeElement}
        onSelectedOptionsUpdate={onSelectedOptionsUpdate}
        disabled={disabled}
        autoFocus={autoFocus}
        defaultValue={typeElement.defaultValue}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentMultipleChoice.displayName = 'ContentComponentMultipleChoice';
