import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { GeneralRouteSegment } from '../constants/routeSegments.ts';
import { GeneralProjectSettingsSavingStatus } from '../containers/statusMessages/GeneralProjectSettingsSavingStatus.tsx';

export const ProjectSettingsSavingStatus: React.FC = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/${GeneralRouteSegment}`}>
        <GeneralProjectSettingsSavingStatus />
      </Route>
    </Switch>
  );
};

ProjectSettingsSavingStatus.displayName = 'ProjectSettingsSavingStatus';
