import { Box } from '@kontent-ai/component-library/Box';
import React, { ReactNode } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IBarItemExpandedNoActionsProps {
  readonly children: ReactNode;
  readonly dataUiElement?: DataUiElement;
}

export const BarItemExpandedNoActions: React.FC<IBarItemExpandedNoActionsProps> = (props) => (
  <Box
    className="bar-item__expansion bar-item__expansion--no-actions-bar"
    {...(props.dataUiElement && getDataUiElementAttribute(props.dataUiElement))}
  >
    {props.children}
  </Box>
);
