import { useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { leaveAuditLog } from '../actions/auditLogBaseActions.ts';
import { searchAuditEvents } from '../actions/thunkAuditLogActions.ts';
import { AuditLog as AuditLogComponent } from '../components/AuditLog.tsx';
import { isFilterSet as isFilterSetFunc } from '../models/AuditEventFilter.ts';

export const AuditLog = () => {
  const dispatch = useDispatch();
  const hasAnyResults = useSelector((state) => state.data.auditLog.hasAnyResults);
  const areEventsLoaded = useSelector(
    (state) => state.data.auditLog.loadingStatus === LoadingStatus.Loaded,
  );
  const isFilterSet = useSelector((state) => isFilterSetFunc(state.auditLogApp.auditLogFilter));

  const [isSearchThunkDone] = useThunkPromise(searchAuditEvents);

  useEffect(() => {
    return () => {
      dispatch(leaveAuditLog());
    };
  }, []);

  if (!isSearchThunkDone || (!hasAnyResults && !areEventsLoaded)) {
    return <Loader />;
  }

  return (
    <AuditLogComponent
      areEventsLoaded={areEventsLoaded}
      hasAnyResults={hasAnyResults}
      isFilterSet={isFilterSet}
    />
  );
};
