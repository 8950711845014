import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface ILinkDetailActionsProps {
  readonly isEditable?: boolean;
  readonly onUnlink?: () => void;
  readonly onEdit?: () => void;
}

export const LinkDetailActions: React.FC<ILinkDetailActionsProps> = ({
  isEditable,
  onUnlink,
  onEdit,
}) => {
  if (!onUnlink && !onEdit) {
    return null;
  }

  return (
    <div css={`padding-right: ${px(Spacing.S)}`}>
      <Row alignY="center" spacing={Spacing.S} noWrap>
        {isEditable && onEdit && (
          <Column width="content">
            <QuinaryButton
              onClick={onEdit}
              tooltipPlacement="bottom"
              tooltipText="Edit"
              {...getDataUiActionAttribute(DataUiAction.Edit)}
            >
              <QuinaryButton.Icon icon={Icons.Edit} screenReaderText="Edit" />
            </QuinaryButton>
          </Column>
        )}
        {onUnlink && (
          <Column width="content">
            <QuinaryButton
              onClick={onUnlink}
              tooltipPlacement="bottom"
              tooltipText="Unlink"
              {...getDataUiActionAttribute(DataUiRteAction.Unlink)}
            >
              <QuinaryButton.Icon icon={Icons.ChainSlash} />
            </QuinaryButton>
          </Column>
        )}
      </Row>
    </div>
  );
};

LinkDetailActions.displayName = 'LinkDetailActions';
