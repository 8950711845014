import { InvariantException, isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { delay } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { getProjectDetail } from '../../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import { touchProjectsDependencies } from '../../../../repositories/cacheKeys/projectCacheUtils.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { CopyType } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import { ProjectStatusPollingInterval } from '../../constants/copyState.ts';
import { ICloneProjectFormShape } from '../../models/ICloneProjectFormShape.type.ts';
import { ICopyProjectDataInfo } from '../../models/ICopyProjectDataInfo.type.ts';
import {
  ICanProjectBeCopied,
  IGetCopyProjectValidationData,
} from '../../utils/copyProjectUtils.ts';
import { isCloningDone, isCloningFailed } from '../../utils/copyStateUtils.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  cloneProjectValidationFailed,
  failCloningProject,
  finishCloningProject,
  startCloningProject,
} from '../cloneProjectActions.ts';
import { failCreatingNewProject, setProjectDataInfo } from '../createProjectActions.ts';
import {
  projectCloningInitialized,
  projectCopyStateFinished,
  projectCopyStateStart,
  projectCopyStateUpdated,
} from '../projectsActions.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly loadUserProjectsInfo: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly getCopyProjectValidationData: IGetCopyProjectValidationData;
  readonly canProjectBeCopied: ICanProjectBeCopied;
}

const getSourceProject = (
  dispatch: Dispatch,
  state: IStore,
  sourceProjectId: string,
): IProjectDetails | null => {
  const sourceProject = getProjectDetail(state, sourceProjectId);
  if (!sourceProject) {
    dispatch(failCreatingNewProject('Source project is not loaded.'));
    return null;
  }

  return sourceProject;
};

export const startPollingCloningState = (
  projectRepository: IProjectRepository,
  projectId: Uuid,
  timeout: number,
  reloadProjects: (abortSignal?: AbortSignal) => ThunkPromise,
  abortSignal?: AbortSignal,
): ThunkPromise => {
  return async (dispatch, getState) => {
    dispatch(projectCopyStateStart(projectId));

    while (getState().projectsApp.cloningProjectsIds.keySeq().toArray().includes(projectId)) {
      const projectCopyState = await projectRepository.getCopyState(projectId, abortSignal);

      dispatch(projectCopyStateUpdated(projectId, projectCopyState));

      if (isCloningDone(projectCopyState)) {
        touchProjectsDependencies();
        await dispatch(reloadProjects(abortSignal));
      }

      if (isCloningDone(projectCopyState) || isCloningFailed(projectCopyState)) {
        dispatch(projectCopyStateFinished(projectId));
        return;
      }

      await delay(timeout);
    }
  };
};

export const startPollingForProjectCopyState = (
  projectRepository: IProjectRepository,
  reloadProjects: (abortSignal?: AbortSignal) => ThunkPromise,
  abortSignal?: AbortSignal,
): ThunkPromise => {
  return async (dispatch, getState) => {
    const cloningProjectIds = getState()
      .projectsApp.cloningProjectsIds.filter((isPolling) => !isPolling)
      .keySeq()
      .toArray();
    const promises = cloningProjectIds.map((projectId) =>
      dispatch(
        startPollingCloningState(
          projectRepository,
          projectId,
          ProjectStatusPollingInterval,
          reloadProjects,
          abortSignal,
        ),
      ),
    );
    await Promise.all(promises);
  };
};

const isCloneProjectDataInfoValid = async (
  deps: IDeps,
  sourceProjectId: Uuid,
  targetSubscriptionId: Uuid,
  dispatch: Dispatch,
  getState: GetState,
  abortSignal?: AbortSignal,
): Promise<boolean> => {
  const copyProjectDataInfo: ICopyProjectDataInfo = await deps.projectRepository.getProjectDataInfo(
    sourceProjectId,
    abortSignal,
  );
  dispatch(setProjectDataInfo(copyProjectDataInfo));
  const copyProjectValidationData = deps.getCopyProjectValidationData(
    getState(),
    sourceProjectId,
    targetSubscriptionId,
  );

  return deps.canProjectBeCopied(copyProjectValidationData);
};

export const createCloneProjectAction =
  (deps: IDeps) =>
  (
    sourceProjectId: Uuid,
    formValues: ICloneProjectFormShape,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const sourceProject = getSourceProject(dispatch, getState(), sourceProjectId);
    if (!sourceProject) {
      return;
    }

    try {
      dispatch(startCloningProject());

      if (
        !(await isCloneProjectDataInfoValid(
          deps,
          sourceProject.projectId,
          formValues.destinationSubscriptionId,
          dispatch,
          getState,
          abortSignal,
        ))
      ) {
        dispatch(cloneProjectValidationFailed());
        return;
      }

      const clonedProject = await deps.projectRepository.copyProject(
        sourceProjectId,
        {
          destinationLocationId: formValues.destinationLocationId,
          projectName: formValues.projectName,
          destinationSubscriptionId: formValues.destinationSubscriptionId,
          includeContent: formValues.includeContent,
          copyType: CopyType.Clone,
        },
        abortSignal,
      );

      await waitUntilProjectIsActive(
        clonedProject.projectGuid,
        deps.projectRepository,
        abortSignal,
      );
      dispatch(projectCloningInitialized(clonedProject.projectGuid));
      await dispatch(deps.loadProjects(abortSignal));
      dispatch(
        startPollingCloningState(
          deps.projectRepository,
          clonedProject.projectGuid,
          ProjectStatusPollingInterval,
          deps.loadProjects,
          abortSignal,
        ),
      );
      dispatch(finishCloningProject());
      await dispatch(deps.loadUserProjectsInfo(abortSignal));
    } catch (error) {
      if (!isAbortError(error) && isXMLHttpRequest(error)) {
        const errorMessage =
          JSON.parse(error.response)?.description ??
          'The project wasn’t cloned as we’re unable to reach the servers';
        dispatch(failCloningProject(sourceProjectId, errorMessage));
        throw InvariantException(error.response);
      }

      throw error;
    }
  };
