import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusAlign,
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IPendingStatusProps {
  readonly errorLabel: string;
  readonly finishedLabel: string;
  readonly hasError: boolean;
  readonly iconAlign?: SimpleStatusAlign;
  readonly isPending: boolean;
  readonly pendingLabel: string;
}

export const PendingStatus: React.FC<IPendingStatusProps> = ({
  errorLabel,
  hasError,
  finishedLabel,
  iconAlign,
  isPending,
  pendingLabel,
}) => {
  if (hasError) {
    return (
      <SimpleStatusError
        label={errorLabel}
        iconAlign={iconAlign ?? SimpleStatusAlign.Left}
        {...getDataUiElementAttribute(DataUiElement.StatusPaneError)}
      />
    );
  }

  return isPending ? (
    <SimpleStatusDefault
      label={pendingLabel}
      icon={AnimatedProgressIcon}
      iconAlign={iconAlign ?? SimpleStatusAlign.Left}
      {...getDataUiElementAttribute(DataUiElement.StatusPaneInProgress)}
    />
  ) : (
    <SimpleStatusSuccess
      label={finishedLabel}
      icon={Icons.CbCheckPreview}
      iconAlign={iconAlign ?? SimpleStatusAlign.Left}
      disabledFocus
      {...getDataUiElementAttribute(DataUiElement.StatusPaneSuccess)}
    />
  );
};

PendingStatus.displayName = 'PendingStatus';
