import { ITaxonomyGroup } from 'client/app/data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup';

export const anyTaxonomyTaxonomy: ITaxonomyGroup = {
  id: 'anyTaxonomyTaxonomy',
  name: 'Any taxonomy group',
  codename: '',
  childIds: [],
  terms: new Map(),
  lastModified: '',
  lastModifiedBy: '',
  isArchived: false,
  etag: null,
};
