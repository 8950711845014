import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { isProjectManagerInAnyEnvironment } from '../../../../applications/projectSettings/root/selectors/isProjectManagerInAnyEnvironment.ts';
import { IPeopleRepository } from '../../../../repositories/interfaces/IPeopleRepository.type.ts';
import { IProjectContainerActiveUserSeverModel } from '../../../../repositories/serverModels/IProjectContainerActiveUserSeverModel.type.ts';
import {
  Data_ProjectContainer_LoadActiveUsers_Failed,
  Data_ProjectContainer_LoadActiveUsers_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly peopleRepository: Pick<IPeopleRepository, 'getProjectContainerActiveUsers'>;
}

const success = (
  projectContainerActiveUsers: ReadonlyArray<IProjectContainerActiveUserSeverModel>,
) =>
  ({
    type: Data_ProjectContainer_LoadActiveUsers_Success,
    payload: { projectContainerActiveUsers },
  }) as const;

const failed = () =>
  ({
    type: Data_ProjectContainer_LoadActiveUsers_Failed,
  }) as const;

export type LoadProjectContainerActiveUsersActionsType = ReturnType<typeof success | typeof failed>;

export const createLoadProjectContainerActiveUsersAction =
  ({ peopleRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      const projectContainerActiveUsersServerModel =
        await peopleRepository.getProjectContainerActiveUsers(abortSignal);
      dispatch(success(projectContainerActiveUsersServerModel));
    } catch (error) {
      if (
        isXMLHttpRequest(error) &&
        error.status === 403 &&
        !isProjectManagerInAnyEnvironment(getState())
      ) {
        return; //it's expected that non-PM user cannot obtain the list of project container active users
      }

      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
