import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_Editor_Left } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { HighlightedTask_Ensured } from '../../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { ContentModels_TypeEditor_Left } from '../../../../../contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
} from '../../../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_ContentDetailsButton_Toggled,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_HighlightOpenTasks_Finished,
  ContentItemEditing_Sidebar_Closed,
  ContentItemEditing_Sidebar_Opened,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

export function isOpen(state = false, action: Action): boolean {
  switch (action.type) {
    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case ContentItemEditing_CreateNewVersion_Finished:
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_HighlightOpenTasks_Finished:
    case ContentItemEditing_Sidebar_Opened:
    case HighlightedTask_Ensured:
      return true;

    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left:
    case ContentItemEditing_Sidebar_Closed:
      return false;

    case ContentItemEditing_ContentDetailsButton_Toggled: {
      return !state;
    }

    default:
      return state;
  }
}
