import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ILanguage,
  createLanguageFromServerModel,
} from '../../../../../data/models/languages/Language.ts';
import {
  LocalizationEditor_Init_Started,
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangCreator_Show,
  LocalizationEditor_Language_Activate,
  LocalizationEditor_Language_Add,
  LocalizationEditor_Language_Deactivate,
  LocalizationEditor_Language_Update,
  LocalizationEditor_LanguagesLoading_Success,
} from '../../constants/localizationActionTypes.ts';

const initialState: Immutable.Map<Uuid, ILanguage> = Immutable.Map();

export const languages = (
  state: Immutable.Map<Uuid, ILanguage> = initialState,
  action: Action,
): Immutable.Map<Uuid, ILanguage> => {
  switch (action.type) {
    case LocalizationEditor_Init_Started: {
      return initialState;
    }

    case LocalizationEditor_LanguagesLoading_Success: {
      const loadedProjectLanguages = action.payload.projectLanguages;
      const newProjectLanguages = loadedProjectLanguages.languages.reduce((reducedItems, item) => {
        return reducedItems.set(item.id, createLanguageFromServerModel(item));
      }, Immutable.Map<Uuid, ILanguage>());
      return newProjectLanguages;
    }

    case LocalizationEditor_Language_Add:
    case LocalizationEditor_Language_Update: {
      const { languageId, formValues } = action.payload;
      const existingItem = state.get(languageId);
      if (!existingItem) {
        return state;
      }

      return state.set(languageId, {
        etag: existingItem.etag,
        id: existingItem.id,
        inactive: existingItem.inactive,
        codename: formValues.codename,
        fallbackLanguageId: formValues.fallbackLanguageId,
        name: formValues.name,
      });
    }

    case LocalizationEditor_Language_Deactivate: {
      return state.update(action.payload.id, (language) => ({
        ...language,
        inactive: true,
        fallbackLanguageId: null,
      }));
    }

    case LocalizationEditor_Language_Activate: {
      return state.update(action.payload.id, (language) => ({
        ...language,
        inactive: false,
      }));
    }

    case LocalizationEditor_LangCreator_Show: {
      const { language } = action.payload;
      return state.set(language.id, language);
    }

    case LocalizationEditor_LangCreator_Hide: {
      return state.remove(action.payload.languageId);
    }

    default:
      return state;
  }
};
