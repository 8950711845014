import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import { Shared_FallbacksForLinkedContentStatus_Loaded } from '../../constants/sharedActionTypes.ts';
import { FallbacksForLinkedContentStatus } from '../../models/FallbacksForLinkedContentStatus.type.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
}

const fallbacksForLinkedContentStatus = (data: FallbacksForLinkedContentStatus) =>
  ({
    type: Shared_FallbacksForLinkedContentStatus_Loaded,
    payload: { data },
  }) as const;

export type LoadFallbacksForLinkedContentStatus = ReturnType<
  typeof fallbacksForLinkedContentStatus
>;

export const createLoadFallbacksForLinkedContentStatus =
  ({ projectRepository }: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const status = await projectRepository.getFallbacksForLinkedContentStatus(
      projectId,
      abortSignal,
    );
    dispatch(fallbacksForLinkedContentStatus(status));
  };
