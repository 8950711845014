import { Collection } from '@kontent-ai/utils';
import { List } from 'immutable';
import React, { useMemo } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { formatUserName, userLastNameComparer } from '../../../../../_shared/utils/usersUtils.ts';
import { changeSelectedSubscriptionUsers } from '../../../shared/actions/subscriptionManagementActions.ts';
import { ISubscriptionUser } from '../../../shared/models/SubscriptionUserInfo.ts';
import { SubscriptionUsers as SubscriptionUsersComponent } from '../../components/promoteUsersModal/SubscriptionUsers.tsx';

export type UserOption = {
  id: UserId;
  email: string;
  label: string;
};

const getUserOptions = (users: List<ISubscriptionUser>): ReadonlyArray<UserOption> =>
  users
    .sort(userLastNameComparer)
    .toArray()
    .map((user) => ({
      id: user.userId,
      email: user.email,
      label: formatUserName(user),
    }));

const userExistsInOptions = (users: ReadonlyArray<UserOption>) => (userId: UserId) =>
  !!users.find((user) => user.id === userId);

export const SubscriptionUsers: React.FC = () => {
  const users = useSelector((store: IStore) => store.data.subscriptions.subscriptionUsers);
  const userOptions = useMemo(() => getUserOptions(users), [users]);

  const selectedUserIds = useSelector(
    (store: IStore) => store.subscriptionApp.admins.selectedSubscriptionUsers,
  );
  const existingSelectedUserIds = useMemo(
    () => Collection.filter(selectedUserIds, userExistsInOptions(userOptions)),
    [selectedUserIds, userOptions],
  );

  const dispatch = useDispatch();
  const changeSelectedUsers = (userIds: ReadonlySet<UserId>) => {
    dispatch(changeSelectedSubscriptionUsers(userIds));
  };

  return (
    <SubscriptionUsersComponent
      userOptions={userOptions}
      selectedUserIds={existingSelectedUserIds}
      changeSelectedUsers={changeSelectedUsers}
    />
  );
};

SubscriptionUsers.displayName = 'SubscriptionUsers';
