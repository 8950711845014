import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { isGettingStartedProject, isSampleProject } from '../../utils/sampleItemsUtils.ts';
import { BusinessRole } from '../BusinessRole.ts';
import { ProjectType } from '../ProjectType.ts';

const technicalRoles: readonly BusinessRole[] = [BusinessRole.CTO, BusinessRole.Developer];

export const isTechnicalRole = (role: BusinessRole): boolean => technicalRoles.includes(role);

export const getProjectType = (project: IUserProjectInfo): ProjectType => {
  if (isSampleProject(project)) {
    return ProjectType.DancingGoat;
  }
  if (isGettingStartedProject(project)) {
    return ProjectType.GettingStarted;
  }
  return ProjectType.UserProject;
};
