import { WebhookMessageFilterOptionId } from '../constants/webhookMessageFilterOptions.ts';
import {
  Webhooks_CancelEditing,
  Webhooks_CancelEditingInitial,
  Webhooks_InitEdit,
  Webhooks_ListingClose_Finished,
  Webhooks_OnboardingStep_Updated,
  Webhooks_WebhookMessageListing_Filtered,
} from '../constants/webhooksActionTypes.ts';

export const webhookEditInitialCancelled = (id: Uuid) =>
  ({
    type: Webhooks_CancelEditingInitial,
    payload: {
      id,
    },
  }) as const;

export const webhookEditCancelled = (id: Uuid) =>
  ({
    type: Webhooks_CancelEditing,
    payload: {
      id,
    },
  }) as const;

export const webhookEditInit = (id: Uuid) =>
  ({
    type: Webhooks_InitEdit,
    payload: {
      id,
    },
  }) as const;

export const webhookMessageListingFiltered = (
  webhookMessagesFilter: WebhookMessageFilterOptionId,
) =>
  ({
    type: Webhooks_WebhookMessageListing_Filtered,
    payload: {
      webhookMessagesFilter,
    },
  }) as const;

export const webhookListingLeft = () =>
  ({
    type: Webhooks_ListingClose_Finished,
  }) as const;

export const webhooksOnboardingStepUpdated = (step: number) =>
  ({
    type: Webhooks_OnboardingStep_Updated,
    payload: {
      step,
    },
  }) as const;
