import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';

export const UnableToExpandNodeErrorMessage =
  'We were unable to expand this node. Please try again later.';
export const UnableToLoadNodeErrorMessage =
  'We were unable to load this node. Please try again later.';
export const UnableToLoadErrorMessage =
  'We were unable to load Web Spotlight. Please try again later.';

export const WebSpotlightTreeActionsDisabledTooltipConnectionLost = 'Connection lost';
export const WebSpotlightTreeActionsDisabledTooltipContentPublished = 'Content is published.';
export const WebSpotlightTreeActionsDisabledTooltipContentScheduledToPublish =
  'Content is scheduled to publish.';
export const WebSpotlightTreeActionsDisabledTooltipMissingPermission =
  'Your role prevents creating pages.';
export const WebSpotlightTreeActionsDisabledTooltipNotTranslated = 'Content is not translated.';

export enum WebSpotlightTreeActions {
  CreateNewItem = 'Create new page',
  LinkExistingItems = 'Link existing pages',
}

export const WebSpotlightPreviewMaxResolutionPx = 9999;
export const WebSpotlightPreviewMinResolutionPx = 320;

export const WebSpotlightResolutionInputMaxWidth = gridUnit * 14;
export const WebSpotlightResolutionSelectMaxWidth = gridUnit * 21;

export const WebSpotlightPreviewContainerOffset = Spacing.L;
