import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { EditableTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isCustomTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { isCustomElement } from '../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getItemElementOrDefault } from '../../../stores/utils/contentItemElementsUtils.ts';
import { CustomElement } from '../../Revisions/components/elements/CustomElement.tsx';
import { getItemElementRevisionComponent } from '../../Revisions/utils/itemElementRevisionComponentCreator.ts';
import { getElementValue } from '../utils/getElementValueForCustomElement.ts';

interface INonEditableContentItemElementProps {
  readonly typeElement: EditableTypeElement;
}

export const NonEditableContentItemElement: React.FC<INonEditableContentItemElementProps> = ({
  typeElement,
}): JSX.Element | null => {
  const elementData = useSelector((state) =>
    getItemElementOrDefault(typeElement, state.contentApp.editedContentItemVariantElements),
  );

  if (isCustomTypeElement(typeElement)) {
    return isCustomElement(elementData) ? (
      <CustomElement
        elementData={elementData}
        originalElementData={null}
        typeElement={typeElement}
        getElementValue={() => getElementValue(elementData, typeElement)}
      />
    ) : null;
  }

  const ElementComponent = getItemElementRevisionComponent(typeElement.type);

  return (
    <ElementComponent
      elementData={elementData}
      originalElementData={null}
      typeElement={typeElement}
    />
  );
};

NonEditableContentItemElement.displayName = 'NonEditableContentItemElement';
