import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getDefaultAssetTypeOrThrow } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  IServerApiError,
  ServerApiErrorCode,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import { ITaxonomyItemElement } from '../../../../itemEditor/models/contentItemElements/TaxonomyItemElement.ts';
import { isTaxonomyElement } from '../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getItemElementOrDefault } from '../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import {
  GetAssets,
  IItemElementServerModelConverterDataDependencies,
} from '../../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import { ICompiledContentType } from '../../../content/models/CompiledContentType.ts';
import { isEditableElement } from '../../../content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import {
  AssetLibrary_Assets_AssignTermsCompleted,
  AssetLibrary_Assets_AssignTermsStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { IAssetLibraryQuery } from '../../models/IAssetLibraryQuery.ts';

interface IDeps {
  readonly assetService: IAssetServiceDep;
  readonly loadAssetType: () => ThunkPromise;
}

type IAssetServiceDep = Pick<IAssetService, 'bulkUpdateAssets' | 'getAssetsByIds' | 'updateAsset'>;

const started = () =>
  ({
    type: AssetLibrary_Assets_AssignTermsStarted,
  }) as const;

const completed = (
  successfulIds: ReadonlySet<Uuid>,
  failedIds: ReadonlySet<Uuid>,
  assets: Immutable.Map<Uuid, IAsset>,
  query: IAssetLibraryQuery,
) =>
  ({
    type: AssetLibrary_Assets_AssignTermsCompleted,
    payload: {
      successfulIds,
      failedIds,
      assets,
      query,
    },
  }) as const;

export type AssignTermsAssetsActionsType = ReturnType<typeof started | typeof completed>;

type IBulkUpdateResult = {
  readonly successfulAssets: Immutable.Map<Uuid, IAsset>;
  readonly failedAssetIds: ReadonlySet<Uuid>;
  readonly assetIdsToBeUpdated: ReadonlySet<Uuid>;
};

const assignTermsToElement = (
  taxonomyElement: ITaxonomyItemElement,
  terms: ReadonlySet<Uuid>,
): ITaxonomyItemElement => {
  return {
    ...taxonomyElement,
    value: Collection.addMany(taxonomyElement.value, [...terms]),
  };
};

const assignTermsToAsset = (
  asset: IAsset,
  assetType: ICompiledContentType,
  termsPerElement: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
): IAsset => {
  const elementsWithUpdatedTerms = assetType.contentElements
    .filter(isEditableElement)
    .map((typeElement) => {
      const element = getItemElementOrDefault(typeElement, asset.nonLocalizableElements);

      if (!isTaxonomyElement(element) || !termsPerElement.has(element.elementId)) {
        return element;
      }

      return assignTermsToElement(element, termsPerElement.get(element.elementId) ?? new Set());
    });

  return {
    ...asset,
    nonLocalizableElements: elementsWithUpdatedTerms,
  };
};

const getAssetTerms = (asset: IAsset | undefined): ReadonlyArray<Uuid> =>
  asset?.nonLocalizableElements
    .filter(isTaxonomyElement)
    .flatMap((element) => [...element.value]) ?? [];

const bulkAssetUpdate = async (
  previousResult: IBulkUpdateResult,
  retryTimes: number,
  assetType: ICompiledContentType,
  selectedTermsPerElement: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
  assetService: IAssetServiceDep,
  elementDependencies: IItemElementServerModelConverterDataDependencies,
): Promise<IBulkUpdateResult> => {
  if (!previousResult.assetIdsToBeUpdated.size || retryTimes <= 0) {
    return {
      assetIdsToBeUpdated: new Set(),
      successfulAssets: previousResult.successfulAssets,
      failedAssetIds: Collection.addMany(
        previousResult.failedAssetIds,
        Array.from(previousResult.assetIdsToBeUpdated),
      ),
    };
  }

  const freshAssets = (
    await assetService.getAssetsByIds(Array.from(previousResult.assetIdsToBeUpdated))
  ).reduce(
    (map: Immutable.Map<Uuid, IAsset>, asset: IAsset) => map.set(asset.id, asset),
    Immutable.Map<Uuid, IAsset>(),
  );

  const assetTypeElementsIds = new Set(
    assetType.contentElements.map((element) => element.elementId),
  );
  const assetsWithoutDeletedElements: Immutable.Map<Uuid, IAsset> = freshAssets
    .map((asset: IAsset) => ({
      ...asset,
      nonLocalizableElements: asset.nonLocalizableElements.filter((element) =>
        assetTypeElementsIds.has(element.elementId),
      ),
    }))
    .toMap();

  const assetsWithUpdatedTerms = assetsWithoutDeletedElements
    .map((asset: IAsset) => assignTermsToAsset(asset, assetType, selectedTermsPerElement))
    .filter(
      (asset: IAsset) =>
        getAssetTerms(asset).length !==
        getAssetTerms(assetsWithoutDeletedElements.get(asset.id)).length,
    )
    .toMap();
  const nonModifiedAssets = freshAssets
    .filter((asset: IAsset) => !assetsWithUpdatedTerms.has(asset.id))
    .toMap();

  const bulkResult = await assetService.bulkUpdateAssets(
    assetsWithUpdatedTerms.valueSeq().toArray(),
    elementDependencies,
  );

  const assetIdsForRetry = bulkResult.failedAssetErrors
    .filter(
      (error: IServerApiError | null) => error?.code === ServerApiErrorCode.ContentWasModified,
    )
    .keySeq();
  const currentFailedAssetIds = bulkResult.failedAssetErrors
    .filter((_, id: Uuid) => !assetIdsForRetry.contains(id))
    .keySeq();

  const result: IBulkUpdateResult = {
    assetIdsToBeUpdated: new Set(assetIdsForRetry.toArray()),
    successfulAssets: previousResult.successfulAssets
      .merge(bulkResult.successfulAssets)
      .merge(nonModifiedAssets),
    failedAssetIds: Collection.addMany(
      previousResult.failedAssetIds,
      currentFailedAssetIds.toArray(),
    ),
  };

  return bulkAssetUpdate(
    result,
    retryTimes - 1,
    assetType,
    selectedTermsPerElement,
    assetService,
    elementDependencies,
  ).catch(() => ({
    ...result,
    failedAssetIds: Collection.addMany(
      result.failedAssetIds,
      Array.from(result.assetIdsToBeUpdated),
    ),
    assetIdsToBeUpdated: new Set(),
  }));
};

type Args = {
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly selectedTermsPerElement: ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
};

export const createAssignTaxonomyTermsToAssetsAction =
  (deps: IDeps) =>
  ({ selectedAssets, selectedTermsPerElement }: Args): ThunkPromise<ReadonlySet<Uuid>> =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<ReadonlySet<Uuid>> => {
    await dispatch(deps.loadAssetType());
    const assetService = deps.assetService;
    const assetType = getDefaultAssetTypeOrThrow(getState());
    const getAssets: GetAssets = () => getState().data.assets.byId;
    const dataDependencies: IItemElementServerModelConverterDataDependencies = { getAssets };

    dispatch(started());

    const retryTimes = 3;
    const { successfulAssets, failedAssetIds } = await bulkAssetUpdate(
      {
        assetIdsToBeUpdated: selectedAssets,
        successfulAssets: Immutable.Map(),
        failedAssetIds: new Set(),
      },
      retryTimes,
      assetType,
      selectedTermsPerElement,
      assetService,
      dataDependencies,
    ).catch(() => ({
      failedAssetIds: selectedAssets,
      successfulAssets: Immutable.Map<Uuid, IAsset>(),
    }));

    const successfulAssetsIds = successfulAssets.map((asset: IAsset) => asset.id);
    const successfulIdsWithFolders = Collection.filter(selectedAssets, (assetId) =>
      successfulAssetsIds.includes(assetId),
    );
    dispatch(
      completed(
        successfulIdsWithFolders,
        failedAssetIds,
        successfulAssets,
        getState().assetLibraryApp.query,
      ),
    );

    return new Set(successfulAssets.keySeq().toArray());
  };
