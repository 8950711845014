import { InvariantException, isXMLHttpRequest } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  failCreatingNewProject,
  finishCreatingNewProject,
  startCreatingNewProject,
} from '../createProjectActions.ts';
import { projectCreationInitialized } from '../projectsActions.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
}

export const createCreateEmptyProjectAction =
  (deps: IDeps) =>
  (project: INewProjectFormShape): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(startCreatingNewProject());

      const createdProject = await deps.projectRepository.createProject(project);
      dispatch(projectCreationInitialized(createdProject.projectGuid));
      await waitUntilProjectIsActive(createdProject.projectGuid, deps.projectRepository);

      await Promise.all([dispatch(deps.loadUserProjectsInfo()), dispatch(deps.loadProjects())]);
      dispatch(finishCreatingNewProject(createdProject.projectGuid));

      // if you are going to change the code below, please, consider whether you should make the same changes in createProjectFromTemplate.ts
      dispatch(deps.trackUserEventWithData(TrackedEvent.ProjectCreated, {}));
    } catch (error) {
      if (isXMLHttpRequest(error)) {
        const errorMessage =
          JSON.parse(error.response)?.description ??
          'The project wasn’t created as we’re unable to reach the servers.';
        dispatch(failCreatingNewProject(errorMessage));
        throw InvariantException(error.response);
      }
    }
  };
