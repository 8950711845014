import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  ContentAppEntryRoute,
  ContentAppEntryRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { BuildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  getListingFilterFromSavedFilter,
  getSavedFilterFromServerModel,
} from '../../../../data/models/filters/ISavedFilter.ts';
import { IProjectSpecificStorage } from '../../../../localStorages/projectSpecificStorage.ts';
import { IFilterRepository } from '../../../../repositories/interfaces/IFilterRepository.type.ts';
import {
  IListingFilter,
  emptyListingFilter,
} from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import {
  LinkIsInvalidMessage,
  NoAccessToProjectLinkText,
  NoAccessToProjectMessage,
} from '../../constants/uiConstants.ts';
import { handleSuccessfulRedirect, handleUnsuccessfulRedirect } from '../smartLinkActions.ts';

interface IDeps {
  readonly buildPath: BuildPath;
  readonly contentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly filterRepository: IFilterRepository;
  readonly getSignInPageUrl: (includeRedirectUri?: boolean) => string;
}

interface IParams {
  readonly filterId: Uuid;
  readonly projectId: Uuid;
}

export const openInventoryWithFilterCreator =
  (deps: IDeps) =>
  (params: IParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const { projectId, filterId } = params;
    const {
      data: { user },
    } = getState();

    try {
      const projectToSelect = user.projectsInfoById.get(projectId);
      if (projectToSelect) {
        const filterServerModel = await deps.filterRepository.getLinkedFilter(
          projectId,
          filterId,
          abortSignal,
        );
        const savedFilter = getSavedFilterFromServerModel(filterServerModel);
        const listingFilter = getListingFilterFromSavedFilter(savedFilter);

        deps.contentItemFilterStorage?.save({ ...emptyListingFilter, ...listingFilter }, projectId);

        dispatch(
          handleSuccessfulRedirect(
            deps.buildPath<ContentAppEntryRouteParams>(ContentAppEntryRoute, {
              projectId: projectToSelect.projectId,
              variantId: undefined,
            }),
          ),
        );
      } else {
        dispatch(
          handleUnsuccessfulRedirect(
            NoAccessToProjectLinkText,
            NoAccessToProjectMessage,
            deps.getSignInPageUrl(false),
          ),
        );
      }
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));
      }

      throw error;
    }
  };
