import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { highlightIncompleteContentElements } from '../../../../../applications/itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { getElementsWithWarnings } from '../../../../../applications/itemEditor/selectors/itemValidations.ts';
import { modalDismissed } from '../../../../actions/sharedActions.ts';
import { IStore } from '../../../../stores/IStore.type.ts';
import {
  IPublishingErrorSectionDispatchProps,
  IPublishingErrorSectionStateProps,
  PublishingErrorSection as PublishingErrorSectionComponent,
} from '../../components/Elements/PublishingErrorSection.tsx';

const mapStateToProps = (state: IStore): IPublishingErrorSectionStateProps => {
  return {
    displayIncompleteElementsError: !!getElementsWithWarnings(state).length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IPublishingErrorSectionDispatchProps => {
  return {
    onClick: () => {
      dispatch(modalDismissed());
      dispatch(highlightIncompleteContentElements());
    },
  };
};

export const PublishingErrorSection: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublishingErrorSectionComponent);
