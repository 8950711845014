import React from 'react';
import { AgendaGroup } from '../../containers/Calendar/AgendaGroup.tsx';
import { IGroupedEvent } from './AgendaGroup.tsx';

type Props = {
  readonly groupedEvents: ReadonlyArray<IGroupedEvent>;
};

export const AgendaView: React.FC<Props> = ({ groupedEvents }) => (
  <div className="agenda-view">
    <div className="agenda-view__content">
      {groupedEvents.map((groupedEvent) => (
        <AgendaGroup key={groupedEvent.date} groupedEvent={groupedEvent} />
      ))}
    </div>
  </div>
);

AgendaView.displayName = 'AgendaView';
