import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ICollectionGroupRolesChangedEventData,
  IRoleLanguagesEventData,
  IUserCollectionGroupsChangedEventData,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import {
  ICollectionGroupRoles,
  IProjectContributor,
  createCollectionGroupRolesServerModel,
  createProjectContributorFromServerModel,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import {
  ICollectionGroupServerModel,
  IProjectContributorRoleServerModel,
} from '../../../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';
import {
  UserDetail_UpdateCollectionGroups_Failed,
  UserDetail_UpdateCollectionGroups_Finished,
  UserDetail_UpdateCollectionGroups_Started,
} from '../../constants/usersActionTypes.ts';

const createEventData = (
  collectionGroups: readonly ICollectionGroupServerModel[],
): IUserCollectionGroupsChangedEventData => {
  const collectionsEventData = collectionGroups.map(
    (collection: ICollectionGroupServerModel): ICollectionGroupRolesChangedEventData => {
      const roles = collection.roles.map(
        (role: IProjectContributorRoleServerModel): IRoleLanguagesEventData => ({
          roleId: role.roleId,
          languageIds: role.languageIds,
        }),
      );

      return {
        collectionIds: collection.collectionIds,
        roles,
      };
    },
  );

  const languageSpecificRolesCount = collectionGroups
    .flatMap((c) => c.roles)
    .filter((role: IProjectContributorRoleServerModel) => !!role.languageIds.length).length;

  return {
    languageSpecificRolesCount,
    collectionGroups: collectionsEventData,
    collectionGroupsCount: collectionsEventData.length,
  };
};

interface IDeps {
  readonly loadProjectContributors: () => ThunkPromise;
  readonly peopleRepository: Pick<IPeopleRepository, 'updateUserRoles'>;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const started = () =>
  ({
    type: UserDetail_UpdateCollectionGroups_Started,
  }) as const;

const finished = (user: IProjectContributor) =>
  ({
    type: UserDetail_UpdateCollectionGroups_Finished,
    payload: { user },
  }) as const;

const failed = () =>
  ({
    type: UserDetail_UpdateCollectionGroups_Failed,
  }) as const;

export type UpdateCollectionGroupsActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

interface IParams {
  readonly userId: Uuid;
  readonly collectionGroups: readonly ICollectionGroupRoles[];
}

export const createUpdateCollectionGroups =
  (deps: IDeps) =>
  ({ userId, collectionGroups }: IParams): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());

      const serverModel = createCollectionGroupRolesServerModel(collectionGroups);
      const serverResult = await deps.peopleRepository.updateUserRoles(userId, serverModel);

      dispatch(deps.trackUserEvent(TrackedEvent.RolesChanged, createEventData(serverModel)));
      await dispatch(deps.loadProjectContributors());
      dispatch(finished(createProjectContributorFromServerModel(serverResult)));
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
