import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ContentRevisions_ComparisonClosed,
  ContentRevisions_ComparisonRevisionLoaded,
} from '../../features/Revisions/constants/revisionActionTypes.ts';
import { TimelineItem } from '../../models/revisions/TimeLineItem.ts';

const initialState: TimelineItem | null = null;

export function selectedComparisonRevision(
  state = initialState,
  action: Action,
): TimelineItem | null {
  switch (action.type) {
    case ContentRevisions_ComparisonRevisionLoaded:
      return action.payload.comparisonRevision;

    case ContentRevisions_ComparisonClosed:
    case ContentItemEditing_Init_Started:
    case ContentListing_Init_Started:
      return initialState;

    default:
      return state;
  }
}
