import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentListing_PublishItems_Failed,
  ContentListing_ScheduledPublishItems_Failed,
  ContentListing_ScheduledUnpublishItems_Failed,
  ContentListing_UnpublishItems_Failed,
  ContentListing_UpdateWorkflowStep_Failed,
} from '../../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_PublishContentItemVariantFailed,
  Content_Editing_ScheduledPublishingFailed,
  Content_Editing_ScheduledUnpublishingFailed,
  Content_Editing_UnpublishContentItemVariantFailed,
} from '../../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_NotificationBar_Hidden,
  ChangeWorkflowStep_WorkflowStep_Selected,
} from '../constants/changeWorkflowStepActionTypes.ts';

const emptyState = null;

export function savingError(state = emptyState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.savingError;

    case ChangeWorkflowStep_WorkflowStep_Selected:
      return action.payload.errorMessage ? action.payload.errorMessage : emptyState;

    case Content_Editing_PublishContentItemVariantFailed:
    case Content_Editing_UnpublishContentItemVariantFailed:
    case Content_Editing_ScheduledPublishingFailed:
    case Content_Editing_ScheduledUnpublishingFailed:
    case Content_Editing_AssignmentSubmittingFailed:
    case ContentListing_PublishItems_Failed:
    case ContentListing_UnpublishItems_Failed:
    case ContentListing_ScheduledPublishItems_Failed:
    case ContentListing_ScheduledUnpublishItems_Failed:
    case ContentListing_UpdateWorkflowStep_Failed:
      return action.payload.errorMessage;

    case ChangeWorkflowStep_NotificationBar_Hidden:
      return emptyState;

    default:
      return state;
  }
}
