import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { openAssetFolder } from '../../actions/thunkAssetLibraryActions.ts';
import { AssetFolder as AssetFolderComponent } from '../../components/AssetFolders/AssetFolder.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

type Props = {
  readonly assetFolder: ITaxonomyTerm;
  readonly clearAssetSelection: () => void;
  readonly orderBy: AssetsOrderBy;
};

export const AssetFolderInAssetSelector: React.FC<Props> = ({
  assetFolder,
  clearAssetSelection,
  orderBy,
}) => {
  const dispatch = useDispatch();

  return (
    <AssetFolderComponent
      assetFolder={assetFolder}
      onOpen={async () => {
        clearAssetSelection();

        const { folderOpened } = await dispatch(
          openAssetFolder({ assetFolderId: assetFolder.id, orderBy }),
        );
        if (!folderOpened) {
          await dispatch(openAssetFolder({ assetFolderId: RootFolderId, orderBy }));
        }
      }}
    />
  );
};
