import { ITextTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { createSimpleTextValueEditorState } from '../../../richText/utils/editorSimpleTextValueUtils.ts';
import { serializeContentState } from '../../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  ITextItemElement,
  newTextItemElement,
} from '../../models/contentItemElements/TextItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createTextItemElement: ItemElementCreator<ITextTypeElement, ITextItemElement> = (
  textTypeElement,
  omitDefaultValue,
): ITextItemElement => {
  if (omitDefaultValue) {
    return newTextItemElement({
      elementId: textTypeElement.elementId,
    });
  }
  const editorState = createSimpleTextValueEditorState(textTypeElement.defaultValue);
  const jsonValue = serializeContentState(editorState.getCurrentContent());

  return newTextItemElement({
    _editorState: editorState,
    elementId: textTypeElement.elementId,
    jsonValue,
  });
};
