import { Typography, colorPrimary, colorPrimaryHover } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const StyledShowMoreButton = styled.div`
  ${Typography.ActionMedium};
  color: ${colorPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: uppercase;
  
  :hover {
    color: ${colorPrimaryHover};
    text-decoration: underline;
  }
`;
