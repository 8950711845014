import { ITaxonomyGroup } from '../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { INormalizedProjectUserCollectionGroupsServerModel } from '../../repositories/serverModels/IRoleServerModel.type.ts';
import { AutoScrollId } from '../components/AutoScroll/AutoScrollId.ts';
import { ModalDialogType } from '../constants/modalDialogType.ts';
import {
  Shared_AccessUnauthorized,
  Shared_Breadcrumbs_Changed,
  Shared_Browser_IsOffline,
  Shared_Browser_IsOnline,
  Shared_CurrentProjectContainerId_Updated,
  Shared_CurrentProjectId_Updated,
  Shared_Editor_Left,
  Shared_EmailNotifications_Changed,
  Shared_EmailNotifications_Saving,
  Shared_FeedbackModal_Submitted,
  Shared_ModalDismissed,
  Shared_ModalOpened,
  Shared_ModalOpenedWithProperties,
  Shared_NotificationBar_Close,
  Shared_NotificationBar_ShowError,
  Shared_NotificationBar_ShowSuccess,
  Shared_OnboardingNotification_Closed,
  Shared_ProjectLocations_LoadingFinished,
  Shared_ProjectLocations_LoadingInit,
  Shared_ReturnScrollId_Apply,
  Shared_ReturnScrollId_Remember,
  Shared_ScrollId_Forget,
  Shared_ScrollId_Remember,
  Shared_SignalR_Connected,
  Shared_SignalR_ConnectingFailed,
  Shared_SmartLinkCommand_Forget,
  Shared_SmartLinkCommand_Remember,
  Shared_TaxonomyTermsCollapsion_GroupCollapsed,
  Shared_TaxonomyTermsCollapsion_GroupExpanded,
  Shared_TaxonomyTermsCollapsion_TermToggled,
  Shared_UiBlockingMessage_Dismissed,
} from '../constants/sharedActionTypes.ts';
import { Breadcrumb } from '../models/Breadcrumb.type.ts';
import { IProjectLocation } from '../models/ProjectLocation.ts';
import { SmartLinkCommand } from '../models/SmartLinkCommand.ts';
import { SignalRConnectionFailReason } from '../services/signalR/signalRClient.type.ts';
import { ModalTypesWithRequiredProperties } from '../types/ModalDialogProperties.type.ts';

export const breadcrumbsChanged = (breadcrumbs: ReadonlyArray<Breadcrumb>) =>
  ({
    type: Shared_Breadcrumbs_Changed,
    payload: {
      breadcrumbs,
    },
  }) as const;

export const browserIsOffline = () =>
  ({
    type: Shared_Browser_IsOffline,
  }) as const;

export const browserIsOnline = () =>
  ({
    type: Shared_Browser_IsOnline,
  }) as const;

export const closeNotificationBar = () =>
  ({
    type: Shared_NotificationBar_Close,
  }) as const;

export const currentProjectContainerUpdated = (currentProjectContainerId: Uuid) =>
  ({
    type: Shared_CurrentProjectContainerId_Updated,
    payload: {
      currentProjectContainerId,
    },
  }) as const;

export const currentProjectUpdated = (
  currentProjectId: Uuid,
  currentMasterEnvironmentId: Uuid,
  roles: INormalizedProjectUserCollectionGroupsServerModel,
) =>
  ({
    type: Shared_CurrentProjectId_Updated,
    payload: {
      currentProjectId,
      currentMasterEnvironmentId,
      roles,
    },
  }) as const;

export const sharedEditorLeft = () =>
  ({
    type: Shared_Editor_Left,
  }) as const;

export const emailNotificationsBeingChanged = (changed: boolean) =>
  ({
    type: Shared_EmailNotifications_Changed,
    payload: {
      changed,
    },
  }) as const;

export const emailNotificationsBeingSaved = (saving: boolean) =>
  ({
    type: Shared_EmailNotifications_Saving,
    payload: {
      saving,
    },
  }) as const;

export const forgetScrollId = (path: string) =>
  ({
    type: Shared_ScrollId_Forget,
    payload: { path },
  }) as const;

export const feedbackModalSubmitted = () =>
  ({
    type: Shared_FeedbackModal_Submitted,
  }) as const;

export const modalDismissed = () =>
  ({
    type: Shared_ModalDismissed,
  }) as const;

export const modalWithPropertiesOpened = <
  TModalDialogType extends keyof ModalTypesWithRequiredProperties,
>(
  properties: ModalTypesWithRequiredProperties[TModalDialogType],
) =>
  ({
    type: Shared_ModalOpenedWithProperties,
    payload: {
      properties,
    },
  }) as const;

export const modalOpened = (
  type: Exclude<ModalDialogType, keyof ModalTypesWithRequiredProperties>,
) =>
  ({
    type: Shared_ModalOpened,
    payload: { type },
  }) as const;

export const onAccessUnauthorized = () =>
  ({
    type: Shared_AccessUnauthorized,
  }) as const;

export const onboardingNotificationClosed = () =>
  ({
    type: Shared_OnboardingNotification_Closed,
  }) as const;

export const projectLocationsLoadingFinished = (
  projectLocations: ReadonlyArray<IProjectLocation>,
) =>
  ({
    type: Shared_ProjectLocations_LoadingFinished,
    payload: {
      projectLocations,
    },
  }) as const;

export const projectLocationsLoadingInit = () =>
  ({
    type: Shared_ProjectLocations_LoadingInit,
  }) as const;

export type AutoScrollTarget = {
  readonly path: string;
  readonly scrollId: AutoScrollId;
};

export const rememberScrollId = (target: AutoScrollTarget, returnTarget?: AutoScrollTarget) =>
  ({
    type: Shared_ScrollId_Remember,
    payload: {
      target,
      returnTarget,
    },
  }) as const;

export const rememberReturnScrollId = (targetPath: string, returnTarget: AutoScrollTarget) =>
  ({
    type: Shared_ReturnScrollId_Remember,
    payload: {
      targetPath,
      returnTarget,
    },
  }) as const;

export const applyReturnScrollId = (targetPath: string, returnTarget: AutoScrollTarget) =>
  ({
    type: Shared_ReturnScrollId_Apply,
    payload: {
      targetPath,
      returnTarget,
    },
  }) as const;

export const showErrorNotification = (errorMessage: string) =>
  ({
    type: Shared_NotificationBar_ShowError,
    payload: { errorMessage },
  }) as const;

export const showSuccessNotification = (message: string) =>
  ({
    type: Shared_NotificationBar_ShowSuccess,
    payload: { message },
  }) as const;

export const taxonomyGroupCollapsed = (taxonomyGroup: ITaxonomyGroup) =>
  ({
    type: Shared_TaxonomyTermsCollapsion_GroupCollapsed,
    payload: { taxonomyGroup },
  }) as const;

export const taxonomyGroupExpanded = (taxonomyGroupId: Uuid) =>
  ({
    type: Shared_TaxonomyTermsCollapsion_GroupExpanded,
    payload: { taxonomyGroupId },
  }) as const;

export const taxonomyTermCollapsionToggled = (taxonomyGroupId: Uuid, termId: Uuid) =>
  ({
    type: Shared_TaxonomyTermsCollapsion_TermToggled,
    payload: {
      taxonomyGroupId,
      termId,
    },
  }) as const;

export const rememberSmartLinkCommand = (command: SmartLinkCommand) =>
  ({
    type: Shared_SmartLinkCommand_Remember,
    payload: {
      command,
    },
  }) as const;

export const forgetSmartLinkCommand = () =>
  ({
    type: Shared_SmartLinkCommand_Forget,
  }) as const;

export const onSignalRConnected = () =>
  ({
    type: Shared_SignalR_Connected,
  }) as const;

export const onSignalRConnectingFailed = (lastConnectionFailReason: SignalRConnectionFailReason) =>
  ({
    type: Shared_SignalR_ConnectingFailed,
    payload: { lastConnectionFailReason },
  }) as const;

export const dismissUiBlockingMessage = () =>
  ({
    type: Shared_UiBlockingMessage_Dismissed,
  }) as const;
