import { noOperation } from '@kontent-ai/utils';
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import {
  EditorPaperThreshold,
  defaultPaperThresholds,
  getReachedThresholds,
} from '../utils/editorViewContext/editorPaperContext/paperThresholds.ts';

type UpdateCallback = (paperWidth: number) => void;

export type EditorPaperContextValue = {
  // Contains all thresholds that are so each entity depending on this property can check whether its threshold is met
  readonly thresholds: ReadonlySet<EditorPaperThreshold>;
  readonly update: UpdateCallback;
};

export const defaultEditorPaperContextValue: EditorPaperContextValue = {
  thresholds: defaultPaperThresholds,
  update: noOperation,
};

export const EditorPaperContext = createContext<EditorPaperContextValue>(
  defaultEditorPaperContextValue,
);

export const EditorPaperContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const [thresholds, setThresholds] = useState(defaultPaperThresholds);
  const update = useCallback<UpdateCallback>((paperWidth) => {
    const newThresholds = getReachedThresholds(paperWidth);
    setThresholds(newThresholds);
  }, []);

  const throttledUpdate = useThrottledCallback(update, 100);
  const contextValue = useMemo(
    () => ({
      thresholds,
      update: throttledUpdate,
    }),
    [thresholds, throttledUpdate],
  );

  return <EditorPaperContext.Provider value={contextValue}>{children}</EditorPaperContext.Provider>;
};

export const useUpdatePaperContext = (
  paperRef: React.RefObject<HTMLElement>,
): {
  readonly isEditorPaperSet: boolean;
} => {
  const [isEditorPaperSet, setIsEditorPaperSet] = useState(false);
  const wrappedPaperRef = useRef(paperRef);
  wrappedPaperRef.current = paperRef;

  const { update } = useContext(EditorPaperContext);
  const onResize = useCallback(() => {
    const paper = wrappedPaperRef.current?.current;
    if (paper) {
      update(paper.offsetWidth);
      setIsEditorPaperSet(true);
    }
  }, [update]);
  useResizeObserver({ ref: paperRef, onResize });

  return {
    isEditorPaperSet,
  };
};
