import { combineReducers } from '@reduxjs/toolkit';
import Immutable from 'immutable';
import { defaultRootId } from './defaultRootId.ts';
import { IExpandedNodeData, expandedNodesData } from './expandedNodesData.ts';
import { initialized } from './initialized.ts';
import { isDefaultRootBeingChanged } from './isDefaultRootBeingChanged.ts';
import { isDefaultRootBeingFetched } from './isDefaultRootBeingFetched.ts';
import { isRootBeingPrepared } from './isRootBeingPrepared.ts';
import { itemSelectorVisible } from './itemSelectorVisible.ts';
import { nodesBeingProcessed } from './nodesBeingProcessed.ts';
import { selectedRootId } from './selectedRootId.ts';

export interface IRelationsAppStoreState {
  readonly defaultRootId: Uuid | null;
  readonly expandedNodesData: Immutable.Map<string, IExpandedNodeData>;
  readonly initialized: boolean;
  readonly isDefaultRootBeingChanged: boolean;
  readonly isDefaultRootBeingFetched: boolean;
  readonly isRootBeingPrepared: boolean;
  readonly itemSelectorVisible: boolean;
  readonly nodesBeingProcessed: Immutable.Set<string>;
  readonly selectedRootId: Uuid | null;
}

export const relationsAppReducer = combineReducers({
  defaultRootId,
  expandedNodesData,
  initialized,
  isDefaultRootBeingChanged,
  isDefaultRootBeingFetched,
  isRootBeingPrepared,
  itemSelectorVisible,
  nodesBeingProcessed,
  selectedRootId,
});
