import { noOperation } from '@kontent-ai/utils';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';

type ContextValue = {
  readonly isJustCreatedItemClone: boolean;
  readonly setIsJustCreatedItemClone: (isJustCreatedItemClone: boolean) => void;
};

const defaultValue: ContextValue = {
  isJustCreatedItemClone: false,
  setIsJustCreatedItemClone: noOperation,
};

const IsJustCreatedItemCloneContext = createContext<ContextValue>(defaultValue);
IsJustCreatedItemCloneContext.displayName = 'IsJustCreatedItemClone';

type Props = { readonly children: ReactNode };

export const IsJustCreatedItemCloneProvider = (props: Props) => {
  const [isJustCreatedItemClone, setIsJustCreatedItemClone] = useState(
    defaultValue.isJustCreatedItemClone,
  );

  const value = useMemo(
    () => ({
      isJustCreatedItemClone,
      setIsJustCreatedItemClone,
    }),
    [isJustCreatedItemClone],
  );

  return (
    <IsJustCreatedItemCloneContext.Provider value={value}>
      {props.children}
    </IsJustCreatedItemCloneContext.Provider>
  );
};

export const useIsJustCreatedItemClone = (): ContextValue =>
  useContext(IsJustCreatedItemCloneContext);
