import { GuidelinesTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { exportGuidelinesToServerHtml } from '../../../../richText/utils/export/html/exportGuidelinesToServerHtml.ts';
import { ICreateRichTextServerModel } from '../../../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  IGuidelinesTypeElementData,
  getGuidelinesTypeElementDataFromServerModel,
} from '../../models/elements/GuidelinesTypeElementData.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';

export interface IGuidelinesTypeElementDataConverterDependencies {
  createRichTextServerModel: ICreateRichTextServerModel;
}

function convertTypeElementRecordToServerModel(
  dependencies: IGuidelinesTypeElementDataConverterDependencies,
  typeElement: IGuidelinesTypeElementData,
): GuidelinesTypeElementServerModel {
  const richTextServerModel = dependencies.createRichTextServerModel(
    typeElement._editorState.getCurrentContent(),
    { getValue: exportGuidelinesToServerHtml },
  );

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    guidelinesHtml: richTextServerModel.value,
    guidelinesJson: richTextServerModel.jsonValue,
    isRequired: false, // always false as it does not make sense for this element
    type: ElementType.Guidelines,
  };
}

export function createGuidelinesTypeElementDataConverter(
  deps: IGuidelinesTypeElementDataConverterDependencies,
): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: GuidelinesTypeElementServerModel) =>
      getGuidelinesTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IGuidelinesTypeElementData) =>
      convertTypeElementRecordToServerModel(deps, typeElement),
  };
}
