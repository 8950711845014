import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IPublishedWorkflowStepFormShape } from '../model/IPublishedWorkflowStepFormShape.type.ts';
import { containsDeletedRole } from './containsDeletedRole.ts';
import { hasSelectedAnyRole } from './workflowStepValidation.ts';

const noTransitionErrorMessage = 'At least one step has to have transition to Published step.';

const hasTransition = (stepIds: ReadonlyArray<string>): string | undefined => {
  return stepIds.length === 0 ? noTransitionErrorMessage : undefined;
};

export const publishedWorkflowStepValidationConfig: FormValidatorConfig<IPublishedWorkflowStepFormShape> =
  {
    transitionFrom: [hasTransition],
    rolesForNewVersion: [containsDeletedRole, hasSelectedAnyRole],
    rolesForUnpublish: [containsDeletedRole, hasSelectedAnyRole],
  };
