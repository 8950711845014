import { getElementOffset } from '@kontent-ai/DOM';
import { ICommentThread } from '../../models/comments/CommentThreads.ts';
import { IAssetItemElement } from '../../models/contentItemElements/AssetItemElement.ts';
import { getCommentedAssetSelector } from '../commentUtils.ts';
import { getAssetElementCommentThreadReferences } from './assetCommentUtils.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

export function getAssetItemElementCommentManager(): IItemElementCommentManager<IAssetItemElement> {
  return {
    getCommentThreadReferences: (element, threads, _loadedContentItemTypes, contentComponentId) =>
      getAssetElementCommentThreadReferences(element, contentComponentId, threads),
    getCommentThreadOffset,
  };
}

function getCommentThreadOffset(commentThread: ICommentThread): number | null {
  const cssSelector = getCommentedAssetSelector(commentThread);
  return cssSelector ? getElementOffset(cssSelector) : null;
}
