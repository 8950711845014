import { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isDisabled } from '../../../../../_shared/utils/contentItemUtils.ts';
import {
  canUpdateContentGroup,
  hasActiveVariantCapabilityForEditedItem,
} from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { isEditableElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getIsoLanguageCodename } from '../../../../contentInventory/content/utils/getIsoLanguageCodename.ts';
import { areSpacesInCollectionsEnabled } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import {
  getEditedItemViewableContentGroups,
  getElementById,
  getSelectedContentGroupIdFromStateOrFirst,
  getTypeElementsInContentGroup,
} from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  prioritizeFailures,
  shouldDisableFormBasedOnFailureType,
} from '../../../utils/editingSaveFailureutils.ts';
import { isElementVisible } from '../../../utils/itemElementConditionUtils.ts';
import { ContentItemElements as ContentItemElementsComponent } from '../components/ContentItemElements.tsx';
import { CreateContentGroupTabsId } from '../utils/contentGroupTabsId.ts';

export const ContentItemElements = () => {
  const activeCapabilities = useSelector(
    (s) => s.contentApp.editorUi.activeCapabilities.variantCapabilities,
  );

  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);

  const editedContentItemType = useSelector((s) => {
    return s.contentApp.editedContentItem
      ? s.contentApp.loadedContentItemTypes.get(
          s.contentApp.editedContentItem.editedContentItemTypeId,
        )
      : null;
  });

  const workflowStepId = useSelector(
    (s) => s.contentApp.editedContentItemVariant?.assignment.workflowStatus.id ?? '',
  );

  const isCollectionInMainPaneEnabled = useSelector(
    (s) => areSpacesInCollectionsEnabled(s) && areCollectionsVisibleForCurrentUser(s),
  );

  const disabled = useSelector((s) => {
    const [highestPriorityFailureEntry] = prioritizeFailures(
      s.contentApp.editedContentItemStatus.failures,
    );
    const [, highestPriorityFailure] = highestPriorityFailureEntry ?? [];
    const isDisabledBecauseSaveFailure =
      !!highestPriorityFailure &&
      shouldDisableFormBasedOnFailureType(highestPriorityFailure.status);
    return isDisabled(false, s.contentApp.editedContentItemVariant) || isDisabledBecauseSaveFailure;
  });

  const selectedLanguageIsoCodename = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    const currentLanguage =
      (selectedLanguageId && s.data.languages.byId.get(selectedLanguageId)) ||
      s.data.languages.defaultLanguage;
    return getIsoLanguageCodename(currentLanguage.name, currentLanguage.codename);
  });

  const selectedContentGroupId = useSelector((s) => {
    return s.contentApp.editedContentItem
      ? getSelectedContentGroupIdFromStateOrFirst(
          CreateContentGroupTabsId.forContentItem(s.contentApp.editedContentItem.id),
          getEditedItemViewableContentGroups(s),
          s,
        )
      : null;
  });

  const canUpdateContent = useSelector(
    (s) =>
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s) &&
      canUpdateContentGroup(selectedContentGroupId, s.contentApp.editorUi),
  );

  const editedContentItemVariantElements = useSelector(
    (s) => s.contentApp.editedContentItemVariantElements,
  );

  const typeElementsToRender = useMemo(() => {
    const allTypeElementsInContentGroup = getTypeElementsInContentGroup(
      editedContentItemType?.contentElements ?? [],
      selectedContentGroupId,
    );

    return allTypeElementsInContentGroup
      .filter(
        (typeElement) =>
          !isEditableElement(typeElement) ||
          getElementById(typeElement.elementId, editedContentItemVariantElements),
      )
      .filter((typeElement) => isElementVisible(typeElement, editedContentItemVariantElements));
  }, [editedContentItemType, selectedContentGroupId, editedContentItemVariantElements]);

  return editedContentItemType ? (
    <ContentItemElementsComponent
      activeCapabilities={activeCapabilities}
      areAnyContentGroupsVisible={areAnyContentGroupsVisible}
      canUpdateContent={canUpdateContent}
      contentType={editedContentItemType}
      disabled={disabled}
      isCollectionInMainPaneEnabled={isCollectionInMainPaneEnabled}
      selectedLanguageIsoCodename={selectedLanguageIsoCodename}
      typeElementsToRender={typeElementsToRender}
      workflowStepId={workflowStepId}
    />
  ) : null;
};
