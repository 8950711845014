import {
  SelectionAfter,
  applyEditorStateChanges as defaultApplyEditorStateChanges,
  executeContentChange as defaultExecuteContentChange,
} from '../../utils/editorStateUtils.ts';
import { WithApiParameter } from '../types/Editor.api.type.ts';
import { BaseEditorApi } from './baseEditorApi.type.ts';

export const baseEditorApi: WithApiParameter<BaseEditorApi> = {
  applyEditorStateChanges(_, newState, oldState, allowEditContent) {
    // Note: We wrap this method into API so it can be overriden by higher API implementations
    return defaultApplyEditorStateChanges(newState, oldState, allowEditContent);
  },

  executeContentChange(
    _,
    editorState,
    selection,
    change,
    changeType,
    allowUndo = true,
    selectionAfter = SelectionAfter.NewWithFocus,
  ) {
    return defaultExecuteContentChange(
      editorState,
      selection,
      change,
      changeType,
      allowUndo,
      selectionAfter,
    );
  },
};
