import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_Users_Started, Data_Users_Success } from '../../../constants/dataActionTypes.ts';
import {
  IProjectContributor,
  createProjectContributorFromServerModel,
} from '../../../models/users/ProjectContributor.ts';

const initialState: ReadonlyMap<Uuid, IProjectContributor> = new Map<Uuid, IProjectContributor>();

export function usersById(
  state = initialState,
  action: Action,
): ReadonlyMap<Uuid, IProjectContributor> {
  switch (action.type) {
    case Data_Users_Started:
    case Shared_CurrentProjectId_Updated: {
      return initialState;
    }

    case Data_Users_Success: {
      const { data } = action.payload;

      return data.reduce((reduced, userServerModel) => {
        return reduced.set(
          userServerModel.userId,
          createProjectContributorFromServerModel(userServerModel),
        );
      }, new Map<Uuid, IProjectContributor>());
    }

    default:
      return state;
  }
}
