import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { DirectionItemEnum } from '../../../sitemap/constants/DirectionItemEnum.ts';
import { Taxonomy_Terms_TermMoved } from '../../constants/taxonomyActionTypes.ts';
import {
  getLeftSiblingId,
  getParentNode,
  getRightSiblingId,
  moveTaxonomyTerm as moveTermInTaxonomy,
} from '../../utils/taxonomyTreeUtils.ts';

interface IMoveTaxonomyTermActionPayload {
  readonly currentLeftSibling: Uuid | null;
  readonly currentParent: Uuid;
  readonly currentRightSibling: Uuid | null;
  readonly editedTaxonomyGroup: ITaxonomyGroup;
}

const moveTaxonomyTerm = (payload: IMoveTaxonomyTermActionPayload) =>
  ({
    type: Taxonomy_Terms_TermMoved,
    payload,
  }) as const;

export type MoveTaxonomyTermActionsType = ReturnType<typeof moveTaxonomyTerm>;

export const createMoveTaxonomyTermAction =
  () =>
  (targetTermId: Uuid, directions: ReadonlySet<DirectionItemEnum>): ThunkFunction =>
  (dispatch, getState) => {
    const {
      taxonomyApp: { draggedTermId, editedTaxonomyGroup },
      sharedApp: { collapsedTaxonomyTermIds },
    } = getState();

    if (editedTaxonomyGroup && draggedTermId) {
      const collapsedTermIds = collapsedTaxonomyTermIds.get(editedTaxonomyGroup.id) || new Set();

      const newTaxonomyGroup = moveTermInTaxonomy(
        editedTaxonomyGroup,
        collapsedTermIds,
        draggedTermId,
        targetTermId,
        directions,
      );

      const draggedTermParentNode = getParentNode(newTaxonomyGroup, draggedTermId);

      if (!draggedTermParentNode) {
        throw InvariantException(`Parent of term ${draggedTermId} not found`);
      }

      const currentDraggedTermLeftSibling = getLeftSiblingId(draggedTermParentNode, draggedTermId);
      const currentDraggedTermRightSibling = getRightSiblingId(
        draggedTermParentNode,
        draggedTermId,
      );

      dispatch(
        moveTaxonomyTerm({
          currentLeftSibling: currentDraggedTermLeftSibling,
          currentParent: draggedTermParentNode.id,
          currentRightSibling: currentDraggedTermRightSibling,
          editedTaxonomyGroup: newTaxonomyGroup,
        }),
      );
    }
  };
