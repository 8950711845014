import { Action } from '../../../../../@types/Action.type.ts';
import { IRole, getRoleFromServerModel } from '../../../../../data/models/roles/IRole.ts';
import {
  SubscriptionUsers_EnvironmentFilter_Updated,
  SubscriptionUsers_ProjectFilter_Updated,
  SubscriptionUsers_RoleFilter_LoadRoles_Started,
  SubscriptionUsers_RoleFilter_LoadRoles_Succeeded,
} from '../../constants/actionTypes.ts';

const initialState: ReadonlyArray<IRole> = [];

export const roles = (
  state: ReadonlyArray<IRole> = initialState,
  action: Action,
): ReadonlyArray<IRole> => {
  switch (action.type) {
    case SubscriptionUsers_RoleFilter_LoadRoles_Started:
    case SubscriptionUsers_ProjectFilter_Updated:
    case SubscriptionUsers_EnvironmentFilter_Updated: {
      return initialState;
    }

    case SubscriptionUsers_RoleFilter_LoadRoles_Succeeded: {
      const { data } = action.payload;
      return data.map((roleServerModel) => getRoleFromServerModel(roleServerModel));
    }

    default:
      return state;
  }
};
