import styled, { css } from 'styled-components';
import { colorIconDefault, colorIconDisabled } from '../../../tokens/decision/colors.ts';
import { InputState } from '../inputStateEnum.ts';
import { InputStyleProps } from './inputStyleProps.type.ts';

export const InputAddon = styled.span<InputStyleProps>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  
  ${({ $inputState }) =>
    $inputState === InputState.Disabled
      ? css`
    color: ${colorIconDisabled};  
  `
      : css`
    color: ${colorIconDefault};
  `};
`;
InputAddon.displayName = 'InputAddon';
