import { spacingPopupDistance } from '../../tokens/decision/spacing.ts';
import { Spacing, gridUnit } from '../../tokens/quarks/spacing.ts';
import { px } from '../../tokens/utils/utils.ts';

export const verticalMenuMaxHeight = '40vh';

export const verticalMenuMaxWidth = `calc(100vw - ${px(2 * spacingPopupDistance)})`;
export const verticalMenuMinWidth = px(25 * gridUnit).toString();

export const verticalMenuScrollablePartPadding = Spacing.S;
