import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorTextDefault,
  colorTextLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export type AssignmentWorkflowStatus = Readonly<{
  color: WorkflowStepColor;
  name: string;
}>;

type AssignmentProps = Readonly<{
  dataUiObjectName?: ItemDetailSection;
  workflowStatusColor: WorkflowStepColor;
}>;

const Details: React.FC<
  React.PropsWithChildren<
    Readonly<{
      prefix?: string;
      suffix?: React.ReactNode;
    }>
  >
> = ({ children, suffix, prefix }) => (
  <Box marginX={Spacing.S} flexGrow={1} overflow="hidden" whiteSpace="nowrap" data-hj-suppress="">
    <Stack spacing={Spacing.S}>
      {prefix && (
        <Box typography={Typography.LabelLarge} color={colorTextLowEmphasis}>
          {prefix}
        </Box>
      )}
      <Title>{children}</Title>
      {suffix && (
        <Box
          typography={Typography.LabelLarge}
          color={colorTextDefault}
          {...getDataUiObjectNameAttribute(ItemDetailSection.AssignmentNote)}
        >
          {suffix}
        </Box>
      )}
    </Stack>
  </Box>
);

Details.displayName = 'AssignmentDetails';

const Action: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Box display="flex" alignItems="center" css="align-self: start;">
    {children}
  </Box>
);

Action.displayName = 'AssignmentAction';

const Title: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Box
    typography={Typography.HeadlineSmall}
    overflow="hidden"
    css="text-overflow: ellipsis;"
    color={colorTextDefault}
    {...getDataUiObjectNameAttribute(ItemDetailSection.StatusName)}
  >
    {children}
  </Box>
);

Title.displayName = 'AssignmentTitle';

const AssignmentComponent = styled.div.attrs<AssignmentProps>(
  ({ workflowStatusColor, dataUiObjectName }) => ({
    ...(dataUiObjectName && getDataUiObjectNameAttribute(dataUiObjectName)),
    style: {
      '--assignment-bg-color': renderWorkflowStepColor(workflowStatusColor),
    },
  }),
)<AssignmentProps>`
  display: flex;
  min-width: 0;

  &:before {
    display: flex;
    min-width: ${px(6)};
    background-color: var(--assignment-bg-color);
    border-radius: ${px(BorderRadius.M)};
    content: "";
  }
`;

AssignmentComponent.displayName = 'Assignment';

export const Assignment = Object.assign(AssignmentComponent, {
  Action,
  Details,
});
