import { ContentState, SelectionState } from 'draft-js';
import { createSelection } from '../../../utils/editorSelectionUtils.ts';

const getContentBlockKeys = (content: ContentState): ReadonlyArray<string> =>
  content.getBlocksAsArray().map((block) => block.getKey());

export const createSelectionForSuggestedContent = (
  originalContent: ContentState,
  updatedContent: ContentState,
  originalSelection: SelectionState,
  updatedSelection: SelectionState,
): SelectionState => {
  const originalContentKeys = getContentBlockKeys(originalContent);
  const updatedContentKeys = getContentBlockKeys(updatedContent);

  // suggestion selection starts at the index of the original selection
  const originalSelectionStartIndex = originalContentKeys.indexOf(originalSelection.getStartKey());
  const anchorKey =
    updatedContentKeys.at(originalSelectionStartIndex) ?? updatedSelection.getAnchorKey();

  return createSelection(
    anchorKey,
    originalSelection.getStartOffset(),
    updatedSelection.getEndKey(),
    updatedSelection.getEndOffset(),
  );
};
