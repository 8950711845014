import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';

interface IUserListingFilterEmptyStateMessageProps {
  readonly action: () => ThunkFunction;
  readonly actionText?: string;
  readonly text?: string;
  readonly tooltipText?: string;
}

export const UserListingFilterEmptyStateMessage: React.FC<
  IUserListingFilterEmptyStateMessageProps
> = ({ action, actionText, text, tooltipText }) => {
  const dispatch = useDispatch();

  return (
    <EmptyState>
      <EmptyState.Title>We couldn’t find any match</EmptyState.Title>
      <EmptyState.Content>{text}</EmptyState.Content>
      <EmptyState.Footer>
        <Button
          buttonStyle="primary"
          onClick={compose(dispatch, action)}
          tooltipText={tooltipText}
          {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
        >
          {actionText}
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};

UserListingFilterEmptyStateMessage.displayName = 'UserListingFilterEmptyStateMessage';
