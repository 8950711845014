import { SubmenuTriggerAria } from '@react-aria/menu';
import { SubmenuTriggerState } from '@react-stately/menu';
import { Key } from '@react-types/shared';
import React, { RefObject, PropsWithChildren, useMemo, useState } from 'react';

type SubmenuContextState = Readonly<{
  submenuTriggerKey?: Key;
  setSubmenuTriggerKey: React.Dispatch<React.SetStateAction<Key>>;

  submenuTriggerProps?: SubmenuTriggerAria<any>['submenuTriggerProps'];
  submenuTriggerRef: RefObject<HTMLDivElement>;
  submenuTriggerState?: SubmenuTriggerState;

  submenuListProps?: SubmenuTriggerAria<any>['submenuProps'];
  submenuListRef: RefObject<HTMLDivElement>;

  popoverProps?: SubmenuTriggerAria<any>['popoverProps'];
}>;

export const SubmenuContext = React.createContext<SubmenuContextState | null>(null);

type SubmenuContextProviderProps = SubmenuContextState;

export const SubmenuContextProvider: React.FC<PropsWithChildren<SubmenuContextProviderProps>> = ({
  children,
  submenuTriggerKey,
  setSubmenuTriggerKey,

  submenuTriggerProps,
  submenuTriggerRef,
  submenuTriggerState,

  submenuListProps,
  submenuListRef,

  popoverProps,
}) => {
  const contextState = useMemo<SubmenuContextState>(
    () => ({
      submenuTriggerKey,
      setSubmenuTriggerKey,
      submenuTriggerProps,
      submenuTriggerRef,
      submenuTriggerState,

      submenuListProps,
      submenuListRef,

      popoverProps,
    }),
    [
      submenuTriggerKey,
      setSubmenuTriggerKey,
      submenuTriggerProps,
      submenuTriggerRef,
      submenuTriggerState,
      submenuListProps,
      submenuListRef,
      popoverProps,
    ],
  );

  return <SubmenuContext.Provider value={contextState}>{children}</SubmenuContext.Provider>;
};

export const useSubmenuContextInit = () => {
  const [submenuTriggerKey, setSubmenuTriggerKey] = useState<Key>();

  return {
    submenuTriggerKey,
    setSubmenuTriggerKey,
  };
};
