import { Button } from '@kontent-ai/component-library/Button';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly onFilterRestore: () => void;
  readonly restoreActionInProgress: boolean;
};

export const RestoreSavedFilterButton = ({ onFilterRestore, restoreActionInProgress }: Props) => {
  return (
    <Button
      buttonStyle="tertiary"
      size="small"
      onClick={onFilterRestore}
      disabled={restoreActionInProgress}
      {...getDataUiActionAttribute(DataUiAction.Undo)}
    >
      Undo
    </Button>
  );
};
