import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { getUniqueSortedRoleNamesTitle } from '../../applications/environmentSettings/utils/getUserRolesTitle.ts';
import { IProjectDetailsUserRole } from '../../data/models/projects/ProjectDetails.ts';

export const RoleNameForProjectListing: React.FC<{
  readonly isAdmin: boolean;
  readonly roles: ReadonlyArray<IProjectDetailsUserRole>;
}> = ({ roles, isAdmin }) => {
  if (!roles.length) {
    return null;
  }

  const firstRole = Collection.getFirst(roles);
  if (isAdmin && firstRole) {
    // Each admin is a PM in all collections and languages
    return (
      <>
        You are <b>{firstRole.roleName}</b>, <b>Admin</b>
      </>
    );
  }

  const decorateRoleName = (roleName: string): JSX.Element => <b key={roleName}>{roleName}</b>;
  const appendDecoratedRoleName = (result: JSX.Element[], roleName: string) => [
    ...result,
    ', ',
    decorateRoleName(roleName),
  ];

  const sortedRoleNames = getUniqueSortedRoleNamesTitle(roles.map((r) => r.roleName));
  const [firstSortedRole, ...restSortedRoles] = sortedRoleNames;
  const initialRoleNames = firstSortedRole ? [decorateRoleName(firstSortedRole)] : [];
  const roleNames = restSortedRoles.reduce(appendDecoratedRoleName, initialRoleNames);

  return <span title={sortedRoleNames.join(', ')}>You are {roleNames}</span>;
};

RoleNameForProjectListing.displayName = 'RoleNameForProjectListing';
