import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { ContentItemEditing_Init_Ready } from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState = Immutable.Set<Uuid>();

export function multipleContentItemsModalSelectorAlreadyLinkedItemIds(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case ModalMultipleContentItemsSelector_Opened:
      return action.payload.alreadyLinkedItemIds;

    case ModalMultipleContentItemsSelector_Closed:
    case ContentItemEditing_Init_Ready:
      return initialState;

    default:
      return state;
  }
}
