import { NumberValue, createNumberValue } from '../../../../_shared/models/NumberValue.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.type.ts';

interface INumberItemElementValue {
  readonly _number: NumberValue;
  readonly value: string;
}

export interface INumberItemElement
  extends INumberItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyNumberItemElementValue: INumberItemElementValue = {
  _number: createNumberValue(),
  value: '',
};

export const EmptyNumberItemElement: INumberItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Number,
  ...EmptyNumberItemElementValue,
};

export const newNumberItemElement = (params: Partial<INumberItemElement>): INumberItemElement => ({
  ...EmptyNumberItemElement,
  ...params,
});
