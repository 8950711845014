import React from 'react';
import { StyledSkeleton } from './components/StyledSkeleton.tsx';
import { SkeletonTypeToSkeletonBorderRadiusMap } from './types.ts';

type CircleSkeletonProps = Readonly<{
  diameter: number;
  children?: never;
}>;

export const CircleSkeleton = React.forwardRef<HTMLElement, CircleSkeletonProps>((props, ref) => (
  <StyledSkeleton
    $width={props.diameter}
    $height={props.diameter}
    borderRadius={SkeletonTypeToSkeletonBorderRadiusMap.circle}
    ref={ref}
  />
));

CircleSkeleton.displayName = 'CircleSkeleton';
