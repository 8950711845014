import { Hint } from '@kontent-ai/component-library/Hint';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { SimpleStatusWarning } from '@kontent-ai/component-library/SimpleStatus';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FormattedFileSize } from '../../../itemEditor/features/ContentItemEditing/components/elements/asset/FormattedFileSize.tsx';
import { ImageTags } from './ImageTags.tsx';

interface IAssetDetailProps {
  readonly collectionName: string | null;
  readonly createdText: string;
  readonly fileName: string | null;
  readonly fileSize: number | null;
  readonly height: number | null;
  readonly isCollectionSectionVisible: boolean;
  readonly isUncategorized: boolean | null;
  readonly lastModifiedText: string;
  readonly tags: ReadonlyArray<string>;
  readonly width: number | null;
}

const hintText =
  'These labels represent what AI sees in the image. You can use them to find images by their content.';

export const AssetDetail: React.FC<IAssetDetailProps> = (props) => (
  <>
    {props.isUncategorized && (
      <div className="sidebar__section">
        <SimpleStatusWarning
          icon={Icons.Drawers}
          label="No taxonomy terms assigned"
          tooltipText="Assign terms to a taxonomy element"
          tooltipPlacement="top-start"
          {...getDataUiElementAttribute(DataUiElement.UncategorizedLabel)}
        />
      </div>
    )}
    <div className="sidebar__section" data-hj-suppress="">
      <h3 className="sidebar__section-title">File name</h3>
      <span className="sidebar__section-content">{props.fileName || '-'}</span>
    </div>
    <div className="sidebar__section">
      <h3 className="sidebar__section-title">File size</h3>
      <FormattedFileSize className="sidebar__section-content" fileSizeInBytes={props.fileSize} />
    </div>
    {props.width && (
      <div className="sidebar__section">
        <h3 className="sidebar__section-title">Image width</h3>
        <span className="sidebar__section-content">{props.width}&nbsp;px</span>
      </div>
    )}
    {props.height && (
      <div className="sidebar__section">
        <h3 className="sidebar__section-title">Image height</h3>
        <span className="sidebar__section-content">{props.height}&nbsp;px</span>
      </div>
    )}
    {props.tags && props.tags.length !== 0 && (
      <div className="sidebar__section">
        <h3 className="sidebar__section-title">
          <Inline spacing={Spacing.XS}>
            What’s in the image?
            <Hint tooltipText={hintText} />
          </Inline>
        </h3>
        <span className="sidebar__section-content">
          <ImageTags tags={props.tags} />
        </span>
      </div>
    )}
    <div className="sidebar__section">
      <h3 className="sidebar__section-title">Created</h3>
      <span className="sidebar__section-content">{props.createdText}</span>
    </div>
    <div className="sidebar__section">
      <h3 className="sidebar__section-title">Last updated</h3>
      <span className="sidebar__section-content">{props.lastModifiedText}</span>
    </div>
    {props.isCollectionSectionVisible && (
      <div
        className="sidebar__section"
        {...getDataUiElementAttribute(DataUiElement.SidebarSection)}
      >
        <h3 className="sidebar__section-title">Collection</h3>
        <span
          className="sidebar__section-content"
          {...getDataUiElementAttribute(DataUiElement.AssetCollection)}
        >
          {props.collectionName}
        </span>
      </div>
    )}
  </>
);

AssetDetail.displayName = 'AssetDetail';
