import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isAdminOnSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProjectContainer } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

export const isProjectGeneralSettingsTabVisible = (state: IStore): boolean => {
  const currentProject = getCurrentProjectContainer(state);
  const currentSubscriptionId = currentProject.subscriptionId;

  return isAdminOnSubscription(state, currentSubscriptionId);
};
