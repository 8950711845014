import React from 'react';

export const BarItemSkelet: React.FC = () => (
  <div className="bar-item__node">
    <div className="bar-item__wrapper">
      <div className="bar-item__pane">
        <div className="bar-item__bar bar-item__bar--is-skeleton">
          <div className="bar-item__skelet-pane" />
        </div>
      </div>
    </div>
  </div>
);

BarItemSkelet.displayName = 'BarItemSkelet';
