import {
  EditorScrollContextValue,
  EditorScrollProviderState,
} from '../createEditorScrollContextValue.ts';

const ScrollUpThresholdPx = 100;

type HasUserScrolledUpSelector = (
  currentState: EditorScrollProviderState,
  previousState: EditorScrollProviderState,
  previousValue: EditorScrollContextValue,
) => boolean;

export const hasUserScrolledUpSelector: HasUserScrolledUpSelector = (
  currentState,
  previousState,
  previousValue,
) =>
  previousState.scrollTopPx > currentState.scrollTopPx + ScrollUpThresholdPx ||
  (previousValue.hasUserScrolledUp && previousState.scrollTopPx >= currentState.scrollTopPx);
