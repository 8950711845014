import { Action } from '../../../../../@types/Action.type.ts';
import { Sitemap_InitSitemap } from '../../constants/sitemapActionTypes.ts';

export const projectContainsPublishedItems = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case Sitemap_InitSitemap:
      return action.payload.projectContainsPublishedItems;

    default:
      return state;
  }
};
