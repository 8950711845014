import { Codename } from '../../../../../../../_shared/components/Codename/Codename.tsx';
import { gridUnit } from '../../../../../../../_shared/constants/styleConstants.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly codename: string;
  readonly disabledEditingMessage?: string | null;
  readonly footnoteMessage?: string | null;
  readonly friendlyWarningMessage?: string;
  readonly isCodenameUnique: boolean;
  readonly isBeingSaved?: boolean;
  readonly onSave?: (codename: string, onSaved?: () => void) => Promise<void> | void;
  readonly onChange?: () => void;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onDiscardChanges?: () => void;
  readonly onEdited?: () => void;
};

export const codenameFormWidth = 37 * gridUnit;

export const CodenameForm = (props: Props) => (
  <div
    className="form__group form__group--clipboard form__group--medium-width"
    style={{
      width: codenameFormWidth,
    }}
    {...getDataUiElementAttribute(DataUiElement.CodenameForm)}
  >
    <Codename
      {...props}
      hideTitle
      isCodenameUnique={() => props.isCodenameUnique}
      isEditable
      mode="save"
    />
  </div>
);
