import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { areContentCollectionsEnabledForCurrentProjectPlan } from '../../selectors/allowedFeaturesUtils.ts';
import { openCreator } from '../actions/baseActions.ts';

export const CollectionsEmptyMessage: React.FC = () => {
  const dispatch = useDispatch();

  const isCollectionsFeatureEnabled = useSelector(
    areContentCollectionsEnabledForCurrentProjectPlan,
  );
  const isCreatorDisabled = !isCollectionsFeatureEnabled;

  const filterSearchPhrase = useSelector((s) => s.collectionsApp.filterSearchPhrase);

  const onCreateClick = () => {
    dispatch(openCreator(filterSearchPhrase));
  };

  return (
    <EmptyState size="shrink">
      <EmptyState.Title>We couldn’t find any match.</EmptyState.Title>
      <EmptyState.Content>
        Looks like a collection named “{filterSearchPhrase}” doesn’t exist. Do you want to create
        it?
      </EmptyState.Content>
      <Button
        buttonStyle="primary"
        disabled={isCreatorDisabled}
        onClick={onCreateClick}
        {...getDataUiActionAttribute(DataUiAction.CreateNew)}
      >
        Create “{filterSearchPhrase}”
      </Button>
    </EmptyState>
  );
};

CollectionsEmptyMessage.displayName = 'CollectionsEmptyMessage';
