import React from 'react';
import { useForm } from 'react-hook-form';
import { upsertUserProperty } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { variantCountWarningLimit } from '../../../../../_shared/constants/limitConstants.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LongProcessingChangesWarningDismissedServerKey } from '../../../../../_shared/models/UserPropertiesServerKeys.ts';
import {
  FormValidatorConfig,
  createFormValidationResolver,
} from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  hideDefaultLanguageEditor,
  saveEditedDefaultLanguage,
} from '../../actions/localizationActions.ts';
import { DefaultLanguageEditor } from '../../components/languageEditors/DefaultLanguageEditor.tsx';
import { DefaultLanguageFormShape } from '../../models/languageFormShape.type.ts';
import { getOtherLanguageOptions } from '../../utils/getLanguageOptions.ts';
import {
  LanguageDependantProps,
  languageValidationConfig,
} from '../../validation/languageValidation.ts';

const DefaultLanguageItemEditorContainer: React.FC = () => {
  const otherLanguages = useSelector((s) => getOtherLanguageOptions(s.localizationApp.languages));
  const defaultCodename = useSelector((s) => s.localizationApp.defaultLanguage.codename);
  const defaultName = useSelector((s) => s.localizationApp.defaultLanguage.name);
  const showLargeProjectSaveWarningForUser = useSelector(
    (s) =>
      s.localizationApp.activeLanguagesItemVariantCount > variantCountWarningLimit &&
      !s.sharedApp.userProperties.longProcessingChangesWarningDismissed,
  );

  const dispatch = useDispatch();
  const onCancel = () => dispatch(hideDefaultLanguageEditor());
  const onDismissLongProcessingChangesWarning = () =>
    dispatch(upsertUserProperty(LongProcessingChangesWarningDismissedServerKey, 'true'));

  const formProps = useForm<DefaultLanguageFormShape>({
    defaultValues: {
      codename: defaultCodename,
      name: defaultName,
    },
    resolver: createFormValidationResolver(
      languageValidationConfig as FormValidatorConfig<
        DefaultLanguageFormShape,
        LanguageDependantProps
      >,
      { otherLanguages },
    ),
  });

  const { handleSubmit, watch, formState } = formProps;

  const submitForm = handleSubmit((values) =>
    dispatch(
      saveEditedDefaultLanguage({
        ...values,
        name: values.name.trim(),
      }),
    ),
  );

  const isCodenameModified = formState.dirtyFields.codename || false;
  const languageName = watch('name');

  const showLargeProjectSaveWarning = showLargeProjectSaveWarningForUser && isCodenameModified;

  return (
    <DefaultLanguageEditor
      formProps={formProps}
      languageName={languageName}
      onCancel={onCancel}
      onSubmit={submitForm}
      showLargeProjectSaveWarning={showLargeProjectSaveWarning}
      onDismissLongProcessingChangesWarning={onDismissLongProcessingChangesWarning}
    />
  );
};

DefaultLanguageItemEditorContainer.displayName = 'DefaultLanguageItemEditorContainer';
export { DefaultLanguageItemEditorContainer as DefaultLanguageItemEditor };
