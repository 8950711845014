import { Collection, notNull } from '@kontent-ai/utils';
import { getAllWorkflowSteps } from '../../../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { ISitemap } from '../../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { ITaxonomyGroup } from '../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { Workflow } from '../../../../../../data/models/workflow/Workflow.ts';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';
import { uncategorizedItemFilterTaxonomyTermId } from '../constants/listingFilterConstants.ts';

export function getConsistentCollectionFilter(
  collectionIds: ReadonlyArray<Uuid | null>,
  collectionFilter: ReadonlyArray<Uuid | null>,
  allowedVirtualTermIds: ReadonlyArray<Uuid | null> = [],
): ReadonlyArray<Uuid | null> {
  const allowedIds = new Set([...collectionIds, ...allowedVirtualTermIds]);

  return collectionFilter.filter((id) => allowedIds.has(id));
}

function getConsistentTaxonomyTerms(
  termIds: ReadonlyArray<Uuid>,
  possiblyInconsistentTermIds: ReadonlySet<Uuid>,
  allowedVirtualTermIds: ReadonlyArray<Uuid> = [],
): ReadonlySet<Uuid> {
  return new Set(
    [...termIds, ...allowedVirtualTermIds].filter((id) => possiblyInconsistentTermIds.has(id)),
  );
}

export function getConsistentTaxonomyFilter(
  taxonomyGroups: ReadonlyArray<ITaxonomyGroup>,
  taxonomyFilter: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
  allowedVirtualTermIds: ReadonlyArray<Uuid> = [],
): ReadonlyMap<Uuid, ReadonlySet<Uuid>> {
  const newPairs = Collection.getEntries(taxonomyFilter)
    .map(([filterGroupId, filterTermIds]): readonly [groupId: Uuid, termIds: ReadonlySet<Uuid>] => {
      const group = taxonomyGroups.find(({ id }) => id === filterGroupId);
      return group
        ? [
            filterGroupId,
            getConsistentTaxonomyTerms(
              Collection.getValues(group.terms).map((t) => t.id),
              filterTermIds,
              allowedVirtualTermIds,
            ),
          ]
        : [filterGroupId, new Set()];
    })
    .filter(([, termIds]) => termIds.size);

  return new Map(newPairs);
}

export const getConsistentWorkflowFilter = (
  existingWorkflows: ReadonlyArray<Workflow>,
  filter: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
): ReadonlyMap<Uuid, ReadonlySet<Uuid>> => {
  const consistentPairs = Collection.getEntries(filter)
    .map(([selectedWorkflowId, selectedStepIds]) => {
      const existingSelectedWorkflow = existingWorkflows.find(
        (workflow) => workflow.id === selectedWorkflowId,
      );
      return existingSelectedWorkflow
        ? ([existingSelectedWorkflow, selectedStepIds] as const)
        : null;
    })
    .filter(notNull)
    .map(([selectedWorkflow, selectedStepIds]) => {
      const consistentSelectedStepIds = Array.from(selectedStepIds).filter((stepId) =>
        getAllWorkflowSteps(selectedWorkflow)
          .map((s) => s.id)
          .includes(stepId),
      );
      return [selectedWorkflow.id, new Set(consistentSelectedStepIds)] as const;
    })
    .filter(([selectedWorkflowId, consistentSelectedStepIds]) => {
      const originalFilterWorkflowStepIds = filter.get(selectedWorkflowId);
      return !(consistentSelectedStepIds.size === 0 && originalFilterWorkflowStepIds?.size);
    });

  return new Map(consistentPairs);
};

export type ConsistencyFilterEntities = {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly contentTypeIds: ReadonlyArray<Uuid>;
  readonly sitemap: ISitemap;
  readonly spaceIds: ReadonlyArray<Uuid>;
  readonly taxonomyGroups: ReadonlyArray<ITaxonomyGroup>;
  readonly users: ReadonlyArray<IProjectContributor>;
  readonly workflows: ReadonlyArray<Workflow>;
};

export function getConsistentFilter(
  entities: ConsistencyFilterEntities,
  filterFromStorage: IListingFilter,
): IListingFilter {
  const selectedTaxonomyNodes = getConsistentTaxonomyFilter(
    entities.taxonomyGroups,
    filterFromStorage.selectedTaxonomyNodes,
    [uncategorizedItemFilterTaxonomyTermId],
  );
  const selectedWorkflowNodes = getConsistentWorkflowFilter(
    entities.workflows,
    filterFromStorage.selectedWorkflowNodes,
  );

  return {
    ...filterFromStorage,
    selectedSpacesNodes: new Set(
      Collection.getValues(filterFromStorage.selectedSpacesNodes).filter((nodeId) =>
        entities.spaceIds.find((id) => nodeId === id),
      ),
    ),
    selectedCollectionsNodes: new Set(
      Collection.getValues(filterFromStorage.selectedCollectionsNodes).filter((nodeId) =>
        entities.collectionIds.find((id) => nodeId === id),
      ),
    ),
    selectedContentTypesNodes: new Set(
      Collection.getValues(filterFromStorage.selectedContentTypesNodes).filter((nodeId) =>
        entities.contentTypeIds.find((id) => nodeId === id),
      ),
    ),
    selectedContributorsNodes: new Set(
      Collection.getValues(filterFromStorage.selectedContributorsNodes).filter((nodeId) =>
        entities.users.find((u) => !u.inactive && nodeId === u.userId),
      ),
    ),
    selectedSitemapNodes: new Set(
      Collection.getValues(filterFromStorage.selectedSitemapNodes).filter((nodeId) =>
        entities.sitemap.nodes.has(nodeId),
      ),
    ),
    selectedWorkflowNodes,
    selectedTaxonomyNodes,
  };
}
