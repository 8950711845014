import { getElementOffset } from '@kontent-ai/DOM';
import { memoize } from '@kontent-ai/memoization';
import { ICommentThread, IInlineCommentThread } from '../../models/comments/CommentThreads.ts';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import {
  EmptyThreadReferences,
  ICommentThreadWithLocation,
  getCommentedLinkedItemSelector,
  isThreadInline,
} from '../commentUtils.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

const getLinkedItemCommentThreadReferences = memoize.allForever(
  (
    element: ILinkedItemsElement,
    contentComponentId: Uuid | null,
    commentThreads: ReadonlyArray<ICommentThread>,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    if (commentThreads.length === 0) {
      return EmptyThreadReferences;
    }

    const elementThreads = commentThreads.filter(
      (commentThread: IInlineCommentThread) =>
        commentThread.elementId === element.elementId &&
        commentThread.contentComponentId === contentComponentId,
    );

    const threadReferences = element.value.toArray().flatMap((linkedItemId) =>
      elementThreads
        .filter(isThreadInline)
        .filter((commentThread) => commentThread.externalSegmentId === linkedItemId)
        .map((commentThread) => ({
          commentThread,
          componentPath: null,
        })),
    );

    return threadReferences;
  },
);

export function getLinkedItemCommentManager(): IItemElementCommentManager<ILinkedItemsElement> {
  return {
    getCommentThreadReferences: (element, threads, _loadedContentItemTypes, contentComponentId) =>
      getLinkedItemCommentThreadReferences(element, contentComponentId, threads),
    getCommentThreadOffset,
  };
}

function getCommentThreadOffset(commentThread: ICommentThread): number | null {
  return getElementOffset(getCommentedLinkedItemSelector(commentThread));
}
