import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  canActivateApiStatus,
  canManageApiKey,
  canManageEnvironment,
  canManageWebSpotlight,
} from '../../selectors/environmentSettingsMenuCustomVisibilities.ts';
import { loadEnvironmentApiStatus } from '../actions/thunkApiActivationStatusActions.ts';
import { GeneralSettings as GeneralSettingsComponent } from '../components/GeneralSettings.tsx';
import { ApiActivationStatusCard } from './ApiActivationStatusCard.tsx';
import { EnvironmentIdCard } from './EnvironmentIdCard.tsx';
import { EnvironmentNameCard } from './EnvironmentNameCard.tsx';
import { WebSpotlightStatusTile } from './WebSpotlightStatusTile.tsx';

export const GeneralSettings = () => {
  const isEnvironmentNameVisible = useSelector((s) => canManageEnvironment(getCurrentProject(s)));
  const isEnvironmentIdVisible = useSelector((s) => canManageApiKey(getCurrentProject(s)));
  const isWebSpotlightVisible = useSelector(canManageWebSpotlight);
  const isApiActivationStatusVisible = useSelector(canActivateApiStatus);
  const currentEnvironmentId = useSelector(getCurrentProjectId);

  const [isLoadThunkDone] = useThunkPromise(loadEnvironmentApiStatus, currentEnvironmentId, {
    canRun: isApiActivationStatusVisible,
  });

  if (isApiActivationStatusVisible && !isLoadThunkDone) {
    return <Loader />;
  }

  return (
    <GeneralSettingsComponent
      renderBody={() => (
        <>
          {isEnvironmentNameVisible && <EnvironmentNameCard />}
          {isEnvironmentIdVisible && <EnvironmentIdCard />}
          {isApiActivationStatusVisible && <ApiActivationStatusCard />}
          {isWebSpotlightVisible && <WebSpotlightStatusTile />}
        </>
      )}
    />
  );
};
