import { Box } from '@kontent-ai/component-library/Box';
import { ReactNode, useRef } from 'react';
import { Breadcrumbs as NewBreadcrumbs } from '../../../component-library/components/Breadcrumbs/Breadcrumbs.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export type NavigationBreadcrumb = {
  readonly label: string;
  readonly to: string;
};

type Props = {
  readonly breadcrumbs: ReadonlyArray<NavigationBreadcrumb>;
  readonly renderSavingStatusComponent?: () => ReactNode;
};

export const Breadcrumbs = ({ renderSavingStatusComponent, breadcrumbs }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      display="flex"
      flexBasis="auto"
      flexGrow={1}
      flexShrink={1}
      minWidth={0}
      ref={containerRef}
    >
      <NewBreadcrumbs {...getDataUiCollectionAttribute(DataUiCollection.Breadcrumbs)}>
        <NewBreadcrumbs.Items containerRef={containerRef} items={breadcrumbs} />
        <NewBreadcrumbs.Cell>{renderSavingStatusComponent?.()}</NewBreadcrumbs.Cell>
      </NewBreadcrumbs>
    </Box>
  );
};
