import { ElementType } from '../ContentItemElementType.ts';
import { IAssetTypeElement } from './AssetTypeElement.ts';
import { ICustomTypeElement } from './CustomTypeElement.ts';
import { IDateTimeTypeElement } from './DateTimeTypeElement.ts';
import { IGuidelinesTypeElement } from './GuidelinesTypeElement.ts';
import { ILinkedItemsTypeElement } from './LinkedItemsTypeElement.ts';
import { INumberTypeElement } from './NumberTypeElement.ts';
import { IRichTextTypeElement } from './RichTextTypeElement.ts';
import { ITaxonomyTypeElement } from './TaxonomyTypeElement.ts';
import { ITextTypeElement } from './TextTypeElement.ts';
import { EditableTypeElement, TypeElement } from './TypeElement.type.ts';
import { IUrlSlugTypeElement } from './UrlSlugTypeElement.ts';

export const isEditableElement = (
  arg: TypeElement | undefined | null,
): arg is EditableTypeElement => !!arg && arg.type !== ElementType.Guidelines;

export const isNonLocalizableElement = (
  arg: TypeElement | undefined | null,
): arg is EditableTypeElement => isEditableElement(arg) && arg.isNonLocalizable;

export const isCustomTypeElement = (
  arg: TypeElement | undefined | null,
): arg is ICustomTypeElement => arg?.type === ElementType.Custom;

export const isGuidelinesTypeElement = (
  arg: TypeElement | undefined | null,
): arg is IGuidelinesTypeElement => arg?.type === ElementType.Guidelines;

export const isLinkedItemsTypeElement = (
  arg: TypeElement | undefined | null,
): arg is ILinkedItemsTypeElement => arg?.type === ElementType.LinkedItems;

export const isSubpagesTypeElement = (
  arg: TypeElement | undefined | null,
): arg is ILinkedItemsTypeElement => arg?.type === ElementType.Subpages;

export const isAssetTypeElement = (arg: TypeElement | undefined | null): arg is IAssetTypeElement =>
  arg?.type === ElementType.Asset;

export const isTaxonomyTypeElement = (
  arg: TypeElement | undefined | null,
): arg is ITaxonomyTypeElement => arg?.type === ElementType.Taxonomy;

export const isNumberTypeElement = (
  arg: TypeElement | undefined | null,
): arg is INumberTypeElement => arg?.type === ElementType.Number;

export const isDateTimeTypeElement = (
  arg: TypeElement | undefined | null,
): arg is IDateTimeTypeElement => arg?.type === ElementType.DateTime;

export const isTextTypeElement = (arg: TypeElement | undefined | null): arg is ITextTypeElement =>
  arg?.type === ElementType.Text;

export const isRichTextTypeElement = (
  arg: TypeElement | undefined | null,
): arg is IRichTextTypeElement => arg?.type === ElementType.RichText;

export const isUrlSlugTypeElement = (
  arg: TypeElement | undefined | null,
): arg is IUrlSlugTypeElement => arg?.type === ElementType.UrlSlug;
