import classNames from 'classnames';
import React from 'react';

type Props = {
  readonly hiddenActionsCount: number;
  readonly isCompact?: boolean;
};

export const LinkedItemHiddenActions: React.FC<Props> = ({ hiddenActionsCount, isCompact }) => {
  const hiddenActionElements = Array.from(Array(hiddenActionsCount).keys()).map((i) => (
    <div
      key={i}
      className={classNames('bar-item__action bar-item__action--is-hidden', {
        'bar-item__action--size-xs': isCompact,
      })}
    />
  ));

  return <>{hiddenActionElements}</>;
};

LinkedItemHiddenActions.displayName = 'LinkedItemHiddenActions';
