import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { openMoveToCollectionDialog } from '../../../../../MoveToCollectionDialog/thunks/openMoveToCollectionDialog.ts';
import { ClickableSection } from '../../../../components/sidebar/sectionTypes/ClickableSection.tsx';
import { getCurrentCollectionName } from '../../../selectors/getCurrentCollectionName.ts';

export const ClickableCollectionSection = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(openMoveToCollectionDialog(ContentItemEditingEventOrigins.ItemDetails));
  const currentCollectionName = useSelector(getCurrentCollectionName);

  return (
    <ClickableSection
      onEditClick={onClick}
      dataUiActionAttribute={DataUiAction.OpenCollectionSelection}
      dataUiNameAttribute={ItemEditingForms.Collection}
    >
      {currentCollectionName}
    </ClickableSection>
  );
};
