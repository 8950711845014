import { Collection } from '@kontent-ai/utils';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IPreviewConfigurationServerModel } from '../../../repositories/serverModels/IPreviewConfigurationServerModel.type.ts';
import { IPreviewUrlPatternServerModel } from '../../../repositories/serverModels/IPreviewUrlPatternServerModel.type.ts';
import { ISpaceDomainServerModel } from '../../../repositories/serverModels/ISpaceDomainServerModel.type.ts';
import { IPreviewConfiguration } from '../models/PreviewConfiguration.type.ts';
import { anySpacesOptionId } from './getSpacesOptions.ts';

export const createPreviewConfigurationServerModel = (
  previewConfiguration: IPreviewConfiguration,
  existingContentTypes: Immutable.Map<Uuid, IContentType>,
): IPreviewConfigurationServerModel => {
  const previewUrlPatterns = Collection.getEntries(previewConfiguration.previewUrlPatterns)
    .filter(([contentTypeId]) => existingContentTypes.get(contentTypeId))
    .reduce<ReadonlyRecord<Uuid, ReadonlyArray<IPreviewUrlPatternServerModel>>>(
      (reducedPatterns, [contentTypeId, patterns]) => {
        const contentTypePreviewUrlPatterns = patterns.flatMap(
          (previewUrlPattern): ReadonlyArray<IPreviewUrlPatternServerModel> =>
            previewUrlPattern.spaces.has(anySpacesOptionId)
              ? [
                  {
                    spaceId: null,
                    urlPattern: previewUrlPattern.urlPattern,
                    enabled: previewUrlPattern.enabled,
                  },
                ]
              : Collection.getValues(previewUrlPattern.spaces).map(
                  (spaceId): IPreviewUrlPatternServerModel => ({
                    spaceId,
                    urlPattern: previewUrlPattern.urlPattern,
                    enabled: previewUrlPattern.enabled,
                  }),
                ),
        );

        return {
          ...reducedPatterns,
          [contentTypeId]: contentTypePreviewUrlPatterns,
        };
      },
      {},
    );

  const spaceDomains = previewConfiguration.spaceDomains
    .filter((domain) => !!domain.domain)
    .map(
      (domain): ISpaceDomainServerModel => ({
        spaceId: domain.spaceId,
        domain: domain.domain,
      }),
    );

  return {
    previewUrlPatterns,
    spaceDomains,
  };
};
