import { assert } from '@kontent-ai/utils';
import { DraftInlineStyleType, RawDraftContentState } from 'draft-js';
import Immutable from 'immutable';
import {
  createShadowElement,
  getNodesFromHtmlString,
} from '../../../../_shared/utils/DOM/shadowDOMUtils.ts';
import {
  SPACE,
  removeNewLinesAndDoubledWhitespace,
  trimStart,
} from '../../../../_shared/utils/textUtils.ts';
import { EntityMutability, EntityType } from '../../plugins/entityApi/api/Entity.ts';
import { getEntityDataFromAttributes } from '../../plugins/entityApi/api/editorEntityUtils.ts';
import { DraftJSInlineStyle } from '../../plugins/inlineStyles/api/inlineStyles.ts';
import { getRawTableCellBlocks } from '../../plugins/tables/api/tableGenerator.ts';
import { BaseBlockType, BlockType, ListBlockType, getBaseType } from '../blocks/blockType.ts';
import { IRawBlock, createEmptyRawParagraph, createRawBlock } from '../blocks/editorBlockUtils.ts';

enum TagNames {
  A = 'A',
  B = 'B',
  BR = 'BR',
  DIV = 'DIV',
  EM = 'EM',
  FIGURE = 'FIGURE',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  I = 'I',
  IMG = 'IMG',
  MARK = 'MARK',
  P = 'P',
  STRONG = 'STRONG',
  TABLE = 'TABLE',
  TBODY = 'TBODY',
  TD = 'TD',
  TR = 'TR',
}

type Malleable<T> = {
  -readonly [P in keyof T]: T[P];
};

type IGetLinkEntityRange = (node: HTMLElement, length: number) => any;

function getInlineStyle(node: HTMLElement): string | null {
  const nodeTag = node.tagName;
  switch (nodeTag) {
    case TagNames.B:
    case TagNames.STRONG:
      return DraftJSInlineStyle.Bold;

    case TagNames.I:
    case TagNames.EM:
      return DraftJSInlineStyle.Italic;

    case TagNames.MARK:
      return `COMMENT-${node.getAttribute('data-thread-id')}`;

    default:
      return null;
  }
}

function extractTextFromNode(
  node: HTMLElement,
  targetBlock: IRawBlock,
  getLinkEntityRange: IGetLinkEntityRange,
): IRawBlock {
  const writableTargetBlock = targetBlock as Malleable<IRawBlock>;
  const extractText = (workedNode: HTMLElement) => {
    if (workedNode.nodeType === document.TEXT_NODE) {
      const textToAppend = removeNewLinesAndDoubledWhitespace((workedNode as any).data);
      if (writableTargetBlock.text.endsWith(SPACE) && textToAppend.startsWith(SPACE)) {
        writableTargetBlock.text += trimStart(textToAppend);
      } else {
        writableTargetBlock.text += textToAppend;
      }
    } else if (workedNode.tagName === TagNames.BR) {
      writableTargetBlock.text = `${writableTargetBlock.text}\n`;
    } else if (workedNode.tagName === TagNames.A) {
      const linkRange = getLinkEntityRange(workedNode, writableTargetBlock.text.length);
      if (linkRange) {
        writableTargetBlock.entityRanges.push(linkRange);
      }

      Array.prototype.forEach.call(workedNode.childNodes, extractText);
    } else {
      const offset = writableTargetBlock.text.length;

      Array.prototype.forEach.call(workedNode.childNodes, extractText);

      const style = getInlineStyle(workedNode);
      if (style) {
        writableTargetBlock.inlineStyleRanges.push({
          offset,
          length: writableTargetBlock.text.length - offset,
          style: style as DraftInlineStyleType,
        });
      }
    }
  };

  extractText(node);
  return writableTargetBlock;
}

function isImage(node: HTMLElement): boolean {
  return !!node.getAttribute?.('data-asset-id');
}

function getBlockType(node: HTMLElement): BaseBlockType | null {
  const tagName = node.tagName;

  switch (tagName) {
    case TagNames.FIGURE:
    case TagNames.IMG: {
      return isImage(node) ? BlockType.Image : null;
    }

    case TagNames.H1:
      return BlockType.HeadingOne;
    case TagNames.H2:
      return BlockType.HeadingTwo;
    case TagNames.H3:
      return BlockType.HeadingThree;
    case TagNames.H4:
      return BlockType.HeadingFour;
    case TagNames.H5:
      return BlockType.HeadingFive;
    case TagNames.H6:
      return BlockType.HeadingSix;

    default:
      // Everything unknown converted to unstyled
      return BlockType.Unstyled;
  }
}

function getBlockForElement(node: HTMLElement, depth?: number): IRawBlock | null {
  const blockType = getBlockType(node);
  if (blockType) {
    return createRawBlock({ type: blockType, depth });
  }
  return null;
}

function getGenericBlock(
  node: HTMLElement,
  getLinkEntityRange: IGetLinkEntityRange,
): Malleable<IRawBlock> | null {
  const block = getBlockForElement(node);
  if (!block) {
    return null;
  }

  const genericBlock = extractTextFromNode(node, block, getLinkEntityRange);
  return genericBlock;
}

function getListItemBlockType(tagName: string, ordered: boolean): ListBlockType | null {
  if (tagName === 'LI') {
    return ordered ? BlockType.OrderedListItem : BlockType.UnorderedListItem;
  }

  return null;
}

function getListItemBlock(
  listItemNode: HTMLLIElement,
  depth: number,
  ordered: boolean,
  getLinkEntityRange: IGetLinkEntityRange,
): IRawBlock | null {
  const blockType = getListItemBlockType(listItemNode.tagName, ordered);
  if (blockType) {
    let block = createRawBlock({ type: blockType, depth });
    block = extractTextFromNode(listItemNode, block, getLinkEntityRange);

    return block;
  }

  return null;
}

function getListBlocks(
  listNode: HTMLUListElement | HTMLOListElement,
  depth: number,
  ordered: boolean,
  getLinkEntityRange: IGetLinkEntityRange,
): Array<IRawBlock> {
  const blocks: Array<IRawBlock> = [];
  if (listNode.childNodes) {
    Array.prototype.forEach.call(listNode.childNodes, (listItem) => {
      const subList =
        listItem.childNodes &&
        Array.prototype.find.call(
          listItem.childNodes,
          (node) => node.tagName === 'OL' || node.tagName === 'UL',
        );
      if (subList) {
        const subListBlocks = getListBlocks(subList, depth + 1, ordered, getLinkEntityRange);
        blocks.push(...subListBlocks);
      } else {
        const block = getListItemBlock(listItem, depth, ordered, getLinkEntityRange);
        if (block) {
          blocks.push(block);
        }
      }
    });
  }

  return blocks;
}

function getTableCellBlocks(
  td: HTMLTableCellElement,
  rowNumber: number,
  columnNumber: number,
  getLinkEntityRange: IGetLinkEntityRange,
): ReadonlyArray<IRawBlock> {
  const cellBlocks = getRawTableCellBlocks({ x: columnNumber, y: rowNumber });
  const contentBlock = cellBlocks[1];
  assert(!!contentBlock, () => `${__filename}.getTableCellBlocks: The item at index 1 is falsy.`);
  extractTextFromNode(td, contentBlock, getLinkEntityRange);
  const emptyCellRegex = /^\s+$/;
  if (emptyCellRegex.test(contentBlock.text)) {
    contentBlock.text = '';
  } else {
    contentBlock.text = contentBlock.text.trim();
  }

  return cellBlocks;
}

function getTableCells(
  table: HTMLTableElement,
  getLinkEntityRange: IGetLinkEntityRange,
): Array<IRawBlock> {
  const blocks: Array<IRawBlock> = [];
  const tBody = Array.prototype.find.call(
    table.childNodes,
    (childNode) => childNode.tagName === TagNames.TBODY,
  );
  if (tBody?.childNodes) {
    Array.prototype.filter
      .call(tBody.childNodes, (tr) => tr && tr.tagName === TagNames.TR && tr.childNodes)
      .forEach((tr, rowNumber) => {
        Array.prototype.filter
          .call(tr.childNodes, (td) => td && td.tagName === TagNames.TD)
          .forEach((td, columnNumber) => {
            blocks.push(...getTableCellBlocks(td, rowNumber, columnNumber, getLinkEntityRange));
          });
      });
  }

  return blocks;
}

function importRichTextFromDOMNodes(nodesMap: Array<Node>): RawDraftContentState {
  const blocks: Array<IRawBlock> = [];
  const entityMap: RawDraftContentState['entityMap'] = {};

  let key = 0;
  const getLinkEntityRange: IGetLinkEntityRange = (linkNode, offset) => {
    const untypedNode = linkNode as any;
    if (linkNode.tagName === TagNames.A && untypedNode.text) {
      const text = untypedNode.text;
      const data = getEntityDataFromAttributes(linkNode, EntityType.Link);
      if (data) {
        const entityKey = key++;
        const entity = {
          type: EntityType.Link,
          mutability: EntityMutability.Mutable,
          data,
        };
        const entityRange = {
          offset,
          length: text.length,
          key: entityKey,
        };
        entityMap[entityKey] = entity;

        return entityRange;
      }
    }

    return undefined;
  };

  // Get only relevant nodes
  const nodes = Array.prototype.filter.call(nodesMap, (node) => {
    return (
      node && (node.nodeType === document.ELEMENT_NODE || node.nodeType === document.TEXT_NODE)
    );
  });

  // Filter empty newlines which are not standard HTML newlines
  const withoutEmptyNewLines = nodes.filter(
    (node) =>
      node.nodeType !== document.TEXT_NODE ||
      !String.prototype.match.call(node.textContent, /^\s*$/),
  );

  withoutEmptyNewLines.forEach((node) => {
    if (node.tagName === TagNames.TABLE) {
      blocks.push(...getTableCells(node as HTMLTableElement, getLinkEntityRange));
    } else if (isImage(node)) {
      const assetId = node.getAttribute('data-asset-id');
      const block = getGenericBlock(node, getLinkEntityRange);
      if (block) {
        block.data = Immutable.Map({ guid: assetId });
        blocks.push(block);
      }
    } else if (node.tagName === 'OL' || node.tagName === 'UL') {
      blocks.push(
        ...getListBlocks(node as HTMLUListElement, 0, node.tagName === 'OL', getLinkEntityRange),
      );
    } else if (node.nodeType === document.TEXT_NODE) {
      node.textContent.split(/[\n\r]+/).forEach((text: string) => {
        const textBlock = createEmptyRawParagraph();
        textBlock.text = text;
        blocks.push(textBlock);
      });
    } else {
      const block = getGenericBlock(node, getLinkEntityRange);
      if (block) {
        blocks.push(block);
      }
    }
  });

  const withoutEmptyParagraphs = blocks.filter(
    (block) =>
      getBaseType(block.type) !== BlockType.Unstyled || (!!block.text && !/^\s*$/.exec(block.text)),
  );
  const nonEmptyBlocks = blocks.length ? withoutEmptyParagraphs : [createEmptyRawParagraph()];

  return {
    entityMap,
    blocks: nonEmptyBlocks,
  };
}

export type IImportFromValue = (value: string) => RawDraftContentState;

export const importSimpleTextFromHtml: IImportFromValue = (html: string): RawDraftContentState => {
  let block = createEmptyRawParagraph();
  if (html) {
    const node = createShadowElement(TagNames.P);
    node.innerHTML = html;
    block = extractTextFromNode(node, block, () => null);
  }

  return {
    entityMap: {},
    blocks: [block],
  };
};

export const importRichTextFromHtml: IImportFromValue = (html: string): RawDraftContentState => {
  const elements = getNodesFromHtmlString(html);
  for (let i = 0; i < elements.length; i++) {
    let node = elements[i];
    if (node && node.nodeType === document.ELEMENT_NODE) {
      let lastChild = node.lastChild;
      while (lastChild) {
        if (lastChild && lastChild.nodeName === TagNames.BR) {
          node.removeChild(lastChild);
          break;
        }
        node = lastChild;
        lastChild = node.lastChild;
      }
    }
  }

  return importRichTextFromDOMNodes(elements);
};
