import { IContentItemWithVariantServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ILinkedItemsElementServerModel } from '../../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ITemporaryContentItem } from '../../../models/ITemporaryContentItem.type.ts';
import { ILinkedItemsElement } from '../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import {
  linkedItemsElementDomainModelConverter,
  linkedItemsElementServerModelConverter,
} from '../../../utils/itemElementDataConverters/linkedItemsElementConverter.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from '../../../utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';

type IFilterOutModularItemReference = (
  itemElement: ILinkedItemsElement,
  referenceId: Uuid,
) => ILinkedItemsElement;

export const filterOutModularItemReference = (
  itemElement: ILinkedItemsElement,
  referenceId: Uuid,
): ILinkedItemsElement => ({
  ...itemElement,
  value: itemElement.value.filter((id: Uuid) => id !== referenceId).toList(),
});

type IReplaceElement = <T>(
  elements: ReadonlyArray<T>,
  originalElement: T,
  newElement: T,
) => ReadonlyArray<T>;

export const replaceElement = <T>(
  elements: ReadonlyArray<T>,
  originalElement: T,
  newElement: T,
): ReadonlyArray<T> => {
  const elementIndex = elements.indexOf(originalElement);
  return [...elements.slice(0, elementIndex), newElement, ...elements.slice(elementIndex + 1)];
};

export interface IRemoveTemporaryItemReferenceDependencies {
  readonly filterOutModularItemReference: IFilterOutModularItemReference;
  readonly linkedItemsElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
    ILinkedItemsElementServerModel,
    ILinkedItemsElement
  >;
  readonly linkedItemsElementServerModelConverter: ISimpleItemElementServerModelConverter<
    ILinkedItemsElement,
    ILinkedItemsElementServerModel
  >;
  readonly replaceElement: IReplaceElement;
}

export const removeTemporaryItemReferenceFactory =
  (deps: IRemoveTemporaryItemReferenceDependencies) =>
  (
    itemWithVariant: IContentItemWithVariantServerModel,
    temporaryItem: ITemporaryContentItem,
  ): IContentItemWithVariantServerModel => {
    const elements = itemWithVariant.variant.contentElements;
    const element = elements.find((e) => e.elementId === temporaryItem.parentItem?.elementId);
    if (!element || element.type !== ElementType.LinkedItems) {
      return itemWithVariant;
    }

    const convertedElement = deps.linkedItemsElementDomainModelConverter(
      element as ILinkedItemsElementServerModel,
    );
    const editingElement = deps.filterOutModularItemReference(
      convertedElement,
      temporaryItem.itemId,
    );
    const modifiedElement = deps.linkedItemsElementServerModelConverter(editingElement);
    const contentElements = deps.replaceElement(elements, element, modifiedElement);

    return {
      ...itemWithVariant,
      variant: {
        ...itemWithVariant.variant,
        contentElements,
      },
    };
  };

export const removeTemporaryItemReference = removeTemporaryItemReferenceFactory({
  filterOutModularItemReference,
  linkedItemsElementDomainModelConverter,
  linkedItemsElementServerModelConverter,
  replaceElement,
});
