import { ContentState, EntityInstance } from 'draft-js';
import { getAttributesFromEntityData } from '../../../plugins/entityApi/api/editorEntityUtils.ts';
import { isAiInstruction } from '../../../plugins/inlineAi/utils/InstructionEntity.ts';
import { isLink } from '../../../plugins/links/api/LinkEntity.ts';
import { getBlockHierarchy } from '../../blocks/editorHierarchyUtils.ts';
import { getBlocks } from '../../general/editorContentGetters.ts';
import { GetValueFromContentState } from '../GetValueFromContentState.type.ts';
import { getLinkData } from './elements/entities.ts';
import { HtmlData } from './elements/tags.ts';
import { generateHtml } from './generateHtml.ts';

const getEntityTagsForServer = (entity: EntityInstance, content: HtmlData): HtmlData | null => {
  if (isLink(entity)) {
    return getLinkData(entity, content, getAttributesFromEntityData);
  }
  if (isAiInstruction(entity)) {
    return [];
  }
  return null;
};

// Generates HTML for rich text element value
export const exportRichTextToServerHtml: GetValueFromContentState = (content: ContentState) =>
  generateHtml(
    {
      nodes: getBlockHierarchy(getBlocks(content)),
      entityMap: content.getEntityMap(),
    },
    {
      getEntityData: getEntityTagsForServer,
    },
  );
