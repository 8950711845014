import { useIdWithPrefix } from '@kontent-ai/hooks';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { ComponentProps, MouseEvent } from 'react';
import styled from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { offsetFocus } from '../../../tokens/decision/focus.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Placement } from '../../../types/placement.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Anchor, RouterLink, RouterNavLink } from '../../Anchor/Anchor.tsx';
import { IconName } from '../../Icons/types.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../../Tooltip/constants.ts';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { ButtonBadge, getButtonBadgeVariables } from '../ButtonBadge.tsx';
import { ButtonSize } from '../buttonSize.ts';
import { ButtonStyle } from '../buttonStyle.ts';
import { getBadgeSpacing } from '../components/StyledButtonBadgePositioner.tsx';
import { StyledIconButton } from '../components/StyledIconButton.tsx';
import { getTokens, shouldWrapperHandleFocus } from '../utils/stylingUtils.ts';
import { StyledIconButtonIcon } from './IconButtonIcon.tsx';
import { IconButtonState } from './IconButtonState.ts';

export interface IIconButtonProps extends TooltipPropsExtension {
  readonly activated?: boolean;
  readonly ariaLabel?: string;
  readonly badgeValue?: number;
  readonly buttonState?: IconButtonState;
  readonly buttonStyle: ButtonStyle;
  readonly children?: never;
  readonly className?: string;
  readonly component?: 'button' | typeof Anchor | typeof RouterLink | typeof RouterNavLink;
  readonly destructive?: boolean;
  readonly iconName: IconName;
  readonly id?: string;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly size: ButtonSize;
  readonly tooltipPlacement: Placement;
  /** Used as aria-label when ariaLabel not provided */
  readonly tooltipText: string;
  readonly type?: ComponentProps<typeof StyledIconButton>['type'];
}

const isIconButtonDisabled = (buttonState: IconButtonState) => {
  return buttonState === 'disabled' || buttonState === 'in-progress';
};

const StyledFocusWrapper = styled.div.attrs(getTokens)<{
  readonly $activated?: boolean;
  readonly $buttonStyle: ButtonStyle;
  readonly $size: ButtonSize;
  readonly $destructive?: boolean;
  readonly $disabled?: boolean;
  readonly isFocusVisible: boolean;
}>`
  position: relative;
  display: inline-block;
  border-radius: ${px(BorderRadius.Pill)};

  ${getBadgeSpacing};
  ${getButtonBadgeVariables};

  &:before {
    content: '';
    position: absolute;
    inset: ${px(-1 * offsetFocus)};
    border-radius: inherit;

    pointer-events: none;
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }
`;

export const IconButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<IIconButtonProps>
>(
  (
    {
      activated,
      ariaLabel,
      badgeValue,
      buttonState = 'default',
      buttonStyle,
      children,
      className,
      component = 'button',
      destructive,
      iconName,
      id,
      onClick,
      size,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement,
      tooltipShortcuts,
      tooltipText,
      ...props
    },
    forwardedRef,
  ) => {
    const disabled = isIconButtonDisabled(buttonState);
    const { isFocusVisible, focusProps } = useOurFocusRing(disabled);
    const { isHovered, hoverProps } = useHover({});

    const tooltipId = useIdWithPrefix('icon-button');

    return (
      <Tooltip
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        placement={tooltipPlacement}
        shortcuts={tooltipShortcuts}
        tooltipText={activated ? '' : tooltipText}
        visible={isFocusVisible || isHovered}
        tooltipTextId={tooltipId}
        tippyOptions={disabledAriaTippyOptions}
      >
        <StyledFocusWrapper
          $activated={activated}
          $buttonStyle={buttonStyle}
          $destructive={destructive}
          $disabled={disabled}
          $size={size}
          isFocusVisible={shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
        >
          <StyledIconButton
            $activated={activated}
            $size={size}
            as={component}
            $buttonStyle={buttonStyle}
            className={className}
            $destructive={destructive}
            disabled={disabled}
            id={id}
            $isFocusVisible={!shouldWrapperHandleFocus(buttonStyle) && isFocusVisible}
            onClick={onClick}
            ref={forwardedRef}
            aria-label={ariaLabel ?? tooltipText}
            aria-describedby={ariaLabel && tooltipId}
            {...mergeProps(focusProps, hoverProps, props)}
            {...getDataUiComponentAttribute(IconButton)}
          >
            <StyledIconButtonIcon
              iconName={iconName}
              buttonStyle={buttonStyle}
              buttonState={buttonState}
              size={size}
              disabled={disabled}
              destructive={destructive}
              $activated={activated}
            />
            {!!badgeValue && <ButtonBadge value={badgeValue} />}
          </StyledIconButton>
        </StyledFocusWrapper>
      </Tooltip>
    );
  },
);

IconButton.displayName = 'IconButton';
