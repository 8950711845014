import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { GeneralPropertyServerModel } from '../../../repositories/serverModels/GeneralPropertyServerModel.type.ts';
import { Shared_GeneralProperties_LoadingFinished } from '../../constants/sharedActionTypes.ts';

interface IDeps {
  readonly generalPropertyRepository: {
    readonly getProperties: (
      abortSignal?: AbortSignal,
    ) => Promise<ReadonlyArray<GeneralPropertyServerModel>>;
  };
}

const finished = (properties: ReadonlyArray<GeneralPropertyServerModel>) =>
  ({
    type: Shared_GeneralProperties_LoadingFinished,
    payload: { properties },
  }) as const;

export type LoadGeneralPropertiesActionsType = ReturnType<typeof finished>;

export const createLoadGeneralPropertiesAction =
  ({ generalPropertyRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const properties = await generalPropertyRepository.getProperties(abortSignal);
    dispatch(finished(properties));
  };
