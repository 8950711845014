import { List } from 'immutable';
import { ICompiledContentType } from '../../applications/contentInventory/content/models/CompiledContentType.ts';
import { IContentGroup } from '../../applications/contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { EditedContentItemVariant } from '../../applications/itemEditor/models/contentItem/edited/EditedContentItemVariant.ts';
import { IListingContentItem } from '../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ArchivedWorkflowStepId,
  IWorkflowStep,
  WorkflowStepAction,
} from '../../data/models/workflow/WorkflowStep.ts';
import {
  HasActiveCapabilityParams,
  canViewContentGroup,
} from './permissions/activeCapabilities.ts';

export enum ContentLinkState {
  Valid = 'Valid',
  NonTranslated = 'NonTranslated',
  Archived = 'Archived',
  NonExisting = 'NonExisting',
  Redacted = 'Redacted',
  NonTranslatedReadonly = 'NonTranslatedReadonly',
}

interface IAssignment {
  readonly workflowStatus: IWorkflowStep;
}

export function isPublishedWorkflowStepSelected(updatedWorkflowStep?: IAssignment | null): boolean {
  if (!updatedWorkflowStep) {
    return false;
  }
  return isPublishedWorkflowStep(updatedWorkflowStep.workflowStatus);
}

export function isArchivedWorkflowStepSelected(updatedWorkflowStep?: IAssignment | null): boolean {
  if (!updatedWorkflowStep) {
    return false;
  }
  return isArchivedWorkflowStep(updatedWorkflowStep.workflowStatus);
}

export function isScheduleToPublishWorkflowStepSelected(
  updatedWorkflowStep?: IAssignment,
): boolean {
  if (!updatedWorkflowStep) {
    return false;
  }
  return isScheduledToPublishWorkflowStep(updatedWorkflowStep.workflowStatus);
}

export function isPublishingStepSelected(updatedWorkflowStep?: IAssignment): boolean {
  if (!updatedWorkflowStep) {
    return false;
  }
  return (
    isPublishedWorkflowStep(updatedWorkflowStep.workflowStatus) ||
    isScheduledToPublishWorkflowStep(updatedWorkflowStep.workflowStatus)
  );
}

export function isPublishedWorkflowStep(workflowStep?: IWorkflowStep | null): boolean {
  return !!workflowStep && workflowStep.action === WorkflowStepAction.Publish;
}

export function isArchivedWorkflowStep(workflowStep?: IWorkflowStep | null): boolean {
  return !!workflowStep && workflowStep.id === ArchivedWorkflowStepId;
}

export function isScheduledToPublishWorkflowStep(workflowStep?: IWorkflowStep): boolean {
  return !!workflowStep && workflowStep.action === WorkflowStepAction.ScheduleToPublish;
}

export function isDisabled(
  isDisplayedInRevisionOfItem: boolean,
  editedContentItemVariant: EditedContentItemVariant | null,
): boolean {
  return (
    isDisplayedInRevisionOfItem ||
    !editedContentItemVariant ||
    isPublishingStepSelected(editedContentItemVariant.assignment) ||
    isArchivedWorkflowStepSelected(editedContentItemVariant.assignment)
  );
}

export function isContentItemVariantTranslated(item: IListingContentItem | undefined): boolean {
  return !!item?.variant && !item.variant.isArchived;
}

export function getContentLinkState(
  contentItem: IListingContentItem | undefined,
  canCreateContent: boolean,
  canViewVariant: boolean,
): ContentLinkState {
  if (!contentItem) {
    return ContentLinkState.NonExisting;
  }

  // Full archived item has higher priority than not translated
  if (contentItem.item.archived) {
    return ContentLinkState.Archived;
  }

  // One might not be able to see not-translated item
  if (!canViewVariant) {
    return ContentLinkState.Redacted;
  }

  const isItemNotTranslated = !isContentItemVariantTranslated(contentItem);
  if (isItemNotTranslated) {
    return canCreateContent
      ? ContentLinkState.NonTranslated
      : ContentLinkState.NonTranslatedReadonly;
  }

  return ContentLinkState.Valid;
}

export const getViewableContentGroups = (
  contentType: ICompiledContentType,
  withActiveCapabilities: HasActiveCapabilityParams,
): List<IContentGroup> =>
  contentType.contentGroups
    .filter((group: IContentGroup) => canViewContentGroup(group.id, withActiveCapabilities))
    .toList();

export const getNotTranslatedListingContentItemIds = (
  items: ReadonlyArray<IListingContentItem>,
): UuidArray =>
  items.filter((item) => !isContentItemVariantTranslated(item)).map((item) => item.item.id);
