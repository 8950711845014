import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { getEmailPreferences } from '../../../applications/userProfile/utils/emailPreferencesUtils.ts';
import { EmailPreferenceType } from '../../models/EmailPreferenceTypeEnum.ts';
import { emailNotificationsBeingSaved } from '../sharedActions.ts';

export type IUpdateEmailPreferencesAction = (
  newPreferences: Immutable.Map<EmailPreferenceType, boolean>,
) => ThunkPromise;

interface IDeps {
  readonly upsertUserProperty: (propertyKey: string, propertyValue: string) => ThunkPromise;
}

function updateEmailPreferencesAction(
  newPreferences: Immutable.Map<EmailPreferenceType, boolean>,
  deps: IDeps,
): ThunkPromise {
  return async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    const {
      sharedApp: {
        userProperties: {
          emailNotificationReportsWithZeroPriceDisabled,
          emailNotificationCommentsDisabled,
          emailNotificationAssignmentDisabled,
          emailNotificationMentionsDisabled,
          emailNotificationTasksDisabled,
          emailNotificationWebhookNotificationsDisabled,
        },
      },
    } = getState();

    dispatch(emailNotificationsBeingSaved(true));

    const emailPreferences = getEmailPreferences(
      emailNotificationReportsWithZeroPriceDisabled,
      emailNotificationCommentsDisabled,
      emailNotificationAssignmentDisabled,
      emailNotificationMentionsDisabled,
      emailNotificationTasksDisabled,
      emailNotificationWebhookNotificationsDisabled,
    );

    const updatePromises = newPreferences.map(
      async (enabled: boolean, type: EmailPreferenceType) => {
        if (emailPreferences.get(type) !== enabled) {
          await dispatch(
            deps.upsertUserProperty(
              `EmailNotificationDisabled_${type}`,
              enabled ? 'false' : 'true',
            ),
          );
        }
      },
    );
    await Promise.all(updatePromises.toArray());

    dispatch(emailNotificationsBeingSaved(false));
  };
}

export function updateEmailPreferencesActionCreator(deps: IDeps): IUpdateEmailPreferencesAction {
  return (newPreferences: Immutable.Map<EmailPreferenceType, boolean>) =>
    updateEmailPreferencesAction(newPreferences, deps);
}
