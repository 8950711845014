import {
  HotkeysCallback,
  HotkeysConfig,
  ShortcutsConfig,
} from '@kontent-ai/component-library/hooks';
import { DraftDecorator, EditorState } from 'draft-js';
import React, { useCallback, useRef } from 'react';
import { FormFieldError } from '../../../../_shared/uiComponents/FormAlert/FormFieldError.tsx';
import { formatUserName } from '../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { NewMentionHandle } from '../../../itemEditor/features/ContentItemEditing/components/comments/input/NewMention.tsx';
import { MentionedUsersCannotSeeMentionErrorMessage } from '../../../itemEditor/features/ContentItemEditing/constants/uiConstants.ts';
import { NewMention } from '../../../itemEditor/features/ContentItemEditing/containers/comments/input/NewMention.tsx';
import { UserMention } from '../../../itemEditor/features/ContentItemEditing/containers/comments/input/UserMention.tsx';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { ApplyEditorStateChanges } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditorProps } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  Init,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  CanHandleNewCharsNatively,
  CustomInputHandlingPlugin,
  PostProcessInsertedChars,
} from '../customInputHandling/CustomInputHandlingPlugin.tsx';
import { EntityApiPlugin } from '../entityApi/EntityApiPlugin.tsx';
import {
  EntityDecoratorProps,
  isSelectionEdgeWithinEntity,
} from '../entityApi/api/editorEntityUtils.ts';
import { AtomicEntity } from '../entityApi/components/AtomicEntity.tsx';
import { KeyboardShortcutsPlugin } from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { TextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { EditorMentionApi } from './api/EditorMentionApi.type.ts';
import { isFinishedMention, isNewMention } from './api/MentionEntity.ts';
import { editorMentionApi } from './api/editorMentionApi.ts';
import {
  MentionStartingChar,
  findAllUserIdsMentionedInContentState,
  findFinishedMentions,
  findMentions,
} from './api/editorMentionUtils.ts';

type MentionsPluginProps = {
  readonly usersThatCannotViewEditedGroup: ReadonlyMap<Uuid, IProjectContributor>;
};

export type MentionsPlugin = EditorPlugin<
  None,
  MentionsPluginProps,
  EditorMentionApi,
  None,
  [
    StylesPlugin,
    EntityApiPlugin,
    CustomInputHandlingPlugin,
    KeyboardShortcutsPlugin<TextInputCommand>,
  ]
>;

type NewMentionHotKeyHandler = (
  e: KeyboardEvent | React.KeyboardEvent,
  newMention: NewMentionHandle,
) => void;
type HotKeyHandler = (e: KeyboardEvent | React.KeyboardEvent) => void;

type CreateMention = (entityKey: string, userId: UserId) => void;

type EditorProps = DecoratedEditorProps<
  MentionsPlugin,
  MentionsPluginProps & {
    readonly newMentionRef: React.RefObject<NewMentionHandle>;
    readonly onEscape: () => void;
  }
>;

const EditorWithMentions: React.FC<EditorProps> = ({
  baseRender,
  newMentionRef,
  onEscape,
  state,
  usersThatCannotViewEditedGroup,
}) => {
  const content = state.editorState.getCurrentContent();
  const mentionedUserIds = findAllUserIdsMentionedInContentState(content);

  const mentionedUsersThatCannotSeeComment = mentionedUserIds
    .filter((userId: Uuid) => usersThatCannotViewEditedGroup.has(userId))
    .map((userId: Uuid) => formatUserName(usersThatCannotViewEditedGroup.get(userId)))
    .toArray();

  const createNewMentionHotKeyHandlerWithFallback =
    (
      newMentionHotkeyHandler: NewMentionHotKeyHandler,
      fallbackHandler?: HotkeysCallback,
    ): HotkeysCallback =>
    (e, handler) => {
      const newMentionComponent = newMentionRef.current;

      if (newMentionComponent) {
        newMentionHotkeyHandler(e, newMentionComponent);
      } else {
        fallbackHandler?.(e, handler);
      }
    };

  const handleOnEscape: HotKeyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onEscape();
  };

  const stateWithMentions: PluginState<MentionsPlugin> = {
    ...state,
    wrapperProps: {
      ...state.wrapperProps,
      onKeyDown: (e) => {
        // Enter handler. In the HotkeysHandler it is also triggered in combination with command key and the parent event handler won't receive such event to submit the comment
        // https://github.com/greena13/react-hotkeys/issues/296
        if (!e.ctrlKey && !e.metaKey && e.keyCode === 13) {
          newMentionRef.current?.onEnter(e);
        }
      },
    },
    getHotKeysHandlers: () => {
      const base = state.getHotKeysHandlers();

      return {
        ...base,
        [ShortcutsConfig.Up]: createNewMentionHotKeyHandlerWithFallback(
          (e, newMention) => newMention.onArrowUp(e),
          base[ShortcutsConfig.Up],
        ),
        [ShortcutsConfig.Down]: createNewMentionHotKeyHandlerWithFallback(
          (e, newMention) => newMention.onArrowDown(e),
          base[ShortcutsConfig.Down],
        ),
        [ShortcutsConfig.Escape]: createNewMentionHotKeyHandlerWithFallback(
          (e) => handleOnEscape(e),
          base[ShortcutsConfig.Escape],
        ),
      } satisfies HotkeysConfig;
    },
  };

  return (
    <>
      {baseRender(stateWithMentions)}
      <FormFieldError isDisplayed={!!mentionedUsersThatCannotSeeComment.length}>
        {MentionedUsersCannotSeeMentionErrorMessage(mentionedUsersThatCannotSeeComment)}
      </FormFieldError>
    </>
  );
};

EditorWithMentions.displayName = 'EditorWithMentions';

const canHandleNewCharsNatively: Decorator<CanHandleNewCharsNatively> =
  (baseCanHandleNewCharsNatively) => (params) => {
    if (!baseCanHandleNewCharsNatively(params)) {
      return false;
    }
    return !params.chars.endsWith(MentionStartingChar);
  };

type MentionEntityCustomProps = {
  readonly newMentionRef: React.RefObject<NewMentionHandle>;
  readonly onSubmit: (entityKey: string, userId: UserId) => void;
  readonly onCancel: () => void;
};

const NewMentionEntity: React.FC<EntityDecoratorProps<MentionEntityCustomProps>> = ({
  decoratedText,
  entityKey,
  children,
  newMentionRef,
  onCancel,
  onSubmit,
}) => {
  return (
    <NewMention
      key={entityKey}
      text={decoratedText}
      onUserSelected={(userId: Uuid) => onSubmit(entityKey, userId)}
      onCancel={onCancel}
      ref={newMentionRef}
    >
      {children}
    </NewMention>
  );
};

const MentionEntity: React.FC<
  React.PropsWithChildren<EntityDecoratorProps<MentionEntityCustomProps>>
> = (props) => {
  const contentState = props.contentState;
  const entity = props.entityKey ? contentState.getEntity(props.entityKey) : undefined;

  if (isFinishedMention(entity)) {
    return FinishedMentionEntity(props);
  }
  if (isNewMention(entity)) {
    return NewMentionEntity(props);
  }

  return <>{props.children}</>;
};

const FinishedMentionEntity: React.FC<
  React.PropsWithChildren<EntityDecoratorProps<MentionEntityCustomProps>>
> = (props) => {
  const { children, contentState, entityKey, offsetKey } = props;

  const entity = contentState.getEntity(entityKey);
  if (!isFinishedMention(entity)) {
    return children;
  }

  const { userId } = entity.getData();

  return (
    <AtomicEntity
      {...props}
      key={entityKey}
      renderContent={(content) => (
        <UserMention offsetKey={offsetKey} userId={userId}>
          {content}
        </UserMention>
      )}
    >
      {children}
    </AtomicEntity>
  );
};

FinishedMentionEntity.displayName = 'FinishedMentionEntity';

export const MentionsPlugin: PluginComponent<MentionsPlugin> = (props) => {
  const { usersThatCannotViewEditedGroup } = props;

  const newMentionRef = useRef<NewMentionHandle>(null);

  const { decorateWithEditorStateCallbacks, canUpdateContent, executeChange, getApi } =
    useEditorStateCallbacks<MentionsPlugin>();

  const createMention: CreateMention = useCallback(
    (entityKey, userId) => {
      if (canUpdateContent()) {
        executeChange((editorState) => {
          const selection = getApi().getSelectionForEntity(editorState, entityKey);
          if (selection) {
            return getApi().createMention(editorState, selection, userId);
          }
          return editorState;
        });
      }
    },
    [canUpdateContent, executeChange, getApi],
  );

  const cancelNewMention = useCallback((): void => {
    if (canUpdateContent()) {
      executeChange((editorState) =>
        getApi().removeEntities(editorState, isNewMention, undefined, false),
      );
    }
  }, [canUpdateContent, executeChange, getApi]);

  const init: Init = useCallback(
    (state) => {
      const mentionCustomProps: MentionEntityCustomProps = {
        newMentionRef,
        onSubmit: createMention,
        onCancel: cancelNewMention,
      };
      const mentionsDecorator: DraftDecorator<MentionEntityCustomProps> = {
        strategy: findMentions,
        component: MentionEntity,
        props: mentionCustomProps,
      };

      return {
        decorators: [...state.decorators, mentionsDecorator],
      };
    },
    [createMention, cancelNewMention],
  );

  const render: Decorator<Render<MentionsPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithMentions
        baseRender={baseRender}
        newMentionRef={newMentionRef}
        onEscape={cancelNewMention}
        state={state}
        usersThatCannotViewEditedGroup={usersThatCannotViewEditedGroup}
      />
    ),
    [cancelNewMention, usersThatCannotViewEditedGroup],
  );

  const apply: Apply<MentionsPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      state.render.decorate(render);

      const onNewCharsInserted: Decorator<PostProcessInsertedChars> =
        (baseOnNewCharsInserted) => (params) => {
          const newEditorState = baseOnNewCharsInserted(params);
          const withNewMention = state.getApi().applyNewMention(newEditorState);

          return withNewMention;
        };

      state.canHandleNewCharsNatively.decorate(canHandleNewCharsNatively);
      state.postProcessInsertedChars.decorate(onNewCharsInserted);

      const removeAbandonedMention = (editorState: EditorState): EditorState => {
        // Make sure that the new mention is cancelled in case the selection leaves the new mention
        const selection = editorState.getSelection();
        if (
          !selection.getHasFocus() ||
          !isSelectionEdgeWithinEntity(editorState.getCurrentContent(), selection, isNewMention)
        ) {
          const withoutNewMention = state.getApi().removeEntities(editorState, isNewMention);
          return withoutNewMention;
        }
        return editorState;
      };

      const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
        (baseApplyEditorStateChanges) => (params) => {
          const allowedNewState = baseApplyEditorStateChanges(params);
          return removeAbandonedMention(allowedNewState);
        };

      state.applyEditorStateChanges.decorate(applyEditorStateChanges);

      return {};
    },
    [decorateWithEditorStateCallbacks, render],
  );

  const { getApiMethods } = useEditorApi<MentionsPlugin>(editorMentionApi);

  return useEditorWithPlugin(props, {
    init,
    apply,
    getApiMethods,
  });
};

type DisplayMentionsPlugin = EditorPlugin;

export const DisplayMentionsPlugin: PluginComponent<DisplayMentionsPlugin> = (props) => {
  const init: Init = useCallback((state) => {
    const mentionsDecorator: DraftDecorator = {
      strategy: findFinishedMentions,
      component: FinishedMentionEntity,
    };

    return {
      decorators: [...state.decorators, mentionsDecorator],
    };
  }, []);

  return useEditorWithPlugin(props, { init });
};
