import Immutable from 'immutable';
import {
  AssetRenditionReference,
  IAssetItemElement,
} from '../../models/contentItemElements/AssetItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export const areAssetItemElementsTheSame = (
  itemElement1: IAssetItemElement,
  itemElement2: IAssetItemElement,
): boolean =>
  basePropertiesAreTheSame(itemElement1, itemElement2) &&
  Immutable.is(itemElement1.value.toList(), itemElement2.value.toList());

export const areRenditionReferencesEquivalent = (
  renditions1: ReadonlyArray<AssetRenditionReference>,
  renditions2: ReadonlyArray<AssetRenditionReference>,
): boolean =>
  renditions1.length === renditions2.length &&
  renditions1.every((renditionReference1) =>
    renditions2.some((renditionReference2) => renditionReference2.id === renditionReference1.id),
  );
