import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  getSelectedLanguageId,
  isSelectedLanguageIdEnsured,
} from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getListingLanguageSwitcherOptionsCreator } from '../../../../../../_shared/utils/languageSwitchers/getListingLanguageSwitcherOptions.ts';
import { getPathWithReplacedVariantIdForContentInventoryTypeRoute } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectId } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentListingLanguageSwitcher as ContentListingLanguageSwitcherComponent } from '../components/ContentListingLanguageSwitcher/ContentListingLanguageSwitcher.tsx';

const getListingLanguageSwitcherOptions = getListingLanguageSwitcherOptionsCreator(
  getPathWithReplacedVariantIdForContentInventoryTypeRoute,
);

export const ContentListingLanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const isSelectedLanguageEnsured = useSelector(isSelectedLanguageIdEnsured);
  const languageOptions = useSelector((store) =>
    getListingLanguageSwitcherOptions(
      getCurrentProjectId(store),
      store.data.user,
      store.data.languages,
      pathname,
    ),
  );

  return (
    <ContentListingLanguageSwitcherComponent
      languages={languageOptions}
      selectedLanguageId={selectedLanguageId}
      isSelectedLanguageEnsured={isSelectedLanguageEnsured}
    />
  );
};
