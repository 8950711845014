import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { TaxonomyTermAssignedSourceType } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { isTaxonomyElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { ITaxonomyItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/ITaxonomyItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/Warnings.ts';
import { onTaxonomyElementValueChange } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  Taxonomy as TaxonomyComponent,
  TaxonomyExtraProps,
  TaxonomyProps,
} from '../../../components/elements/taxonomy/Taxonomy.tsx';

const defaultValidationResult: ITaxonomyItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  isNumberOfTermsLimitMet: true,
};

type Props = Omit<TaxonomyProps, keyof TaxonomyExtraProps>;

export const Taxonomy: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId, taxonomyGroupId },
  } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );

  const isNumberOfTermsLimitMet = useSelector(
    (state) =>
      (
        (state.contentApp.itemValidationWarnings.get(
          elementId,
        ) as ITaxonomyItemElementWarningResult) ?? defaultValidationResult
      ).isNumberOfTermsLimitMet,
  );
  const taxonomyGroup = useSelector(
    (state) => taxonomyGroupId && state.data.taxonomyGroups.byId.get(taxonomyGroupId),
  );

  const onTermSelectionChanged = useCallback(
    (termIds: ReadonlySet<Uuid>, source: TaxonomyTermAssignedSourceType) =>
      dispatch(onTaxonomyElementValueChange(elementId, termIds, source)),
    [elementId],
  );

  if (!isTaxonomyElement(elementData) || !taxonomyGroup) {
    return null;
  }

  return (
    <TaxonomyComponent
      {...props}
      elementData={elementData}
      isNumberOfTermsLimitMet={isNumberOfTermsLimitMet}
      onTermSelectionChanged={onTermSelectionChanged}
      taxonomyGroup={taxonomyGroup}
    />
  );
};

Taxonomy.displayName = 'TaxonomyContainer';
