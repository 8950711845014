import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { trimAndRemoveMultipleSpaces } from '../../../../_shared/utils/stringUtils.ts';
import { deleteProject } from '../../actions/thunkProjectsActions.ts';
import { ProjectDeleteConfirmationDialog as ProjectDeleteConfirmationDialogComponent } from '../../components/projects/ProjectDeleteConfirmationDialog.tsx';

type Props = {
  readonly projectId: Uuid;
  readonly onClose: () => void;
};

export const ProjectDeleteConfirmationDialog: React.FC<Props> = (props) => {
  const projectName = useSelector((state) =>
    trimAndRemoveMultipleSpaces(state.data.projects.byId.get(props.projectId)?.projectName ?? ''),
  );

  const dispatch = useDispatch();
  const onProjectDelete = () => {
    props.onClose();
    dispatch(deleteProject(props.projectId));
  };

  return (
    <ProjectDeleteConfirmationDialogComponent
      onClose={props.onClose}
      onProjectDelete={onProjectDelete}
      projectName={projectName}
    />
  );
};

ProjectDeleteConfirmationDialog.displayName = 'ProjectDeleteConfirmationDialogContainer';
