import { Action } from '../../../../@types/Action.type.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ShowAllContributors_Selected,
  ChangeWorkflowStep_ShowAvailableContributors_Selected,
} from '../constants/changeWorkflowStepActionTypes.ts';

const defaultState = false;

export function showAllContributors(state = defaultState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.showAllContributors;

    case ChangeWorkflowStep_ShowAllContributors_Selected:
      return true;

    case ChangeWorkflowStep_ShowAvailableContributors_Selected:
      return false;

    default:
      return state;
  }
}
