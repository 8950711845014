import React, { useEffect } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../_shared/selectors/getCurrentUser.ts';
import { requestChangePassword } from '../actions/thunkUserProfileActions.ts';
import { userProfileRouteEntered, userProfileRouteLeft } from '../actions/userProfileActions.ts';
import { UserProfileEditor as UserProfileEditorComponent } from '../components/UserProfileEditor.tsx';

export const UserProfileEditor: React.FC = () => {
  const accountCapabilities = useSelector((s) => getCurrentUser(s).accountCapabilities);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userProfileRouteEntered());

    return () => {
      dispatch(userProfileRouteLeft());
    };
  }, []);

  const requestPasswordChange = () => dispatch(requestChangePassword());

  return (
    <UserProfileEditorComponent
      accountCapabilities={accountCapabilities}
      onRequestPasswordChange={requestPasswordChange}
    />
  );
};

UserProfileEditor.displayName = 'UserProfileEditor';
