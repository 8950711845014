import React, { useMemo } from 'react';
import { useAutoFocus } from '../../../../../_shared/hooks/useAutoFocus.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionPermissionsEnabledForCurrentProject } from '../../../../../_shared/selectors/contentCollections.ts';
import { replace } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import { CollectionGroups } from '../../components/collections/CollectionGroups.tsx';
import { roleBuilderAnyCollectionOptionId } from '../../constants/roleBuilder.ts';
import { getInitialUserRoles } from '../../selectors/getInitialUserRoles.ts';

type Props = {
  readonly collectionsGroups: readonly ICollectionGroupRoles[];
  readonly contributorId?: Uuid | undefined;
  readonly initiallyContainedCustomCollection: boolean;
  readonly disabledTooltip: string | undefined;
  readonly onCollectionGroupsChange: (groups: readonly ICollectionGroupRoles[]) => void;
  readonly isProjectManagerSelectionValid: boolean;
};

const CollectionGroupsContainer: React.FC<Props> = ({
  collectionsGroups,
  contributorId,
  disabledTooltip,
  initiallyContainedCustomCollection,
  isProjectManagerSelectionValid,
  onCollectionGroupsChange,
}) => {
  const allSelectedCollectionIds = useMemo<ReadonlySet<Uuid>>(() => {
    return new Set(collectionsGroups.flatMap((c) => c.collectionIds));
  }, [collectionsGroups]);

  const roles = useSelector((state) => state.data.roles.rolesById);
  const collections = useSelector((state) => state.data.collections.byId);
  const areCollectionPermissionsEnabled = useSelector(
    areCollectionPermissionsEnabledForCurrentProject,
  );

  const { focus: focusLastGroup, isFocused: isLastGroupFocused } = useAutoFocus();

  const changeGroup = (
    originalGroup: ICollectionGroupRoles,
    updatedGroup: ICollectionGroupRoles,
  ): void => {
    const updatedGroups = replace(collectionsGroups, originalGroup, updatedGroup);

    onCollectionGroupsChange(updatedGroups);
  };

  const removeGroup = (groupToRemove: ICollectionGroupRoles): void => {
    const updatedGroups = collectionsGroups.filter((group) => group !== groupToRemove);

    onCollectionGroupsChange(updatedGroups);
  };

  const addGroup = (): void => {
    const newGroup: ICollectionGroupRoles = {
      roles: getInitialUserRoles(roles),
      collectionIds: [],
    };

    onCollectionGroupsChange([...collectionsGroups, newGroup]);
    focusLastGroup();
  };

  const hasOnlyOneGroup = collectionsGroups.length === 1;
  const nonDeletedCollectionGroups = collectionsGroups.filter(
    (g) =>
      g.collectionIds.length === 0 ||
      g.collectionIds.includes(roleBuilderAnyCollectionOptionId) ||
      g.collectionIds.some((collectionId) => collections.has(collectionId)),
  );
  const addCollectionGroupDisabledTooltip =
    nonDeletedCollectionGroups.length === collections.size + 1
      ? 'You’ve already assigned roles for all collections.'
      : undefined;

  return (
    <CollectionGroups
      addCollectionGroupDisabledTooltip={addCollectionGroupDisabledTooltip}
      allSelectedCollectionIds={allSelectedCollectionIds}
      canAddNewGroupInCurrentPlan={areCollectionPermissionsEnabled}
      canChangeCollectionsInCurrentPlan={
        areCollectionPermissionsEnabled || initiallyContainedCustomCollection
      }
      collections={collections}
      collectionsGroups={collectionsGroups}
      contributorId={contributorId}
      disabledTooltip={disabledTooltip}
      isLastGroupFocused={isLastGroupFocused}
      isProjectManagerSelectionValid={isProjectManagerSelectionValid}
      onGroupAdd={addGroup}
      onGroupChange={changeGroup}
      onGroupRemove={hasOnlyOneGroup ? null : removeGroup}
    />
  );
};

CollectionGroupsContainer.displayName = 'CollectionGroupsContainer';
export { CollectionGroupsContainer as CollectionGroups };
