import { getDataUiComponentAttribute } from '@kontent-ai/component-library/utils';
import React from 'react';
import {
  BaseModalDialogComponent,
  BaseModalSizingProps,
  IBaseModalDialogProps,
} from './BaseModalDialogComponent.tsx';

export interface IModalDialogProps extends IBaseModalDialogProps, BaseModalSizingProps {}

export const ModalDialog = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IModalDialogProps>
>(({ children, ...props }, forwardedRef) => (
  <BaseModalDialogComponent
    ref={forwardedRef}
    {...props}
    {...getDataUiComponentAttribute(ModalDialog)}
  >
    {children}
  </BaseModalDialogComponent>
));

ModalDialog.displayName = 'ModalDialog';
