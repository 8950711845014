import { areShallowEqual, createGuid } from '@kontent-ai/utils';
import React, { Component, ComponentType, memo } from 'react';
import { Dispatch, ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { createAutoDispatcherRunner } from '../../../../../../_shared/utils/AutoDispatcherRunner.ts';

export type SaveOperation = {
  readonly id: Uuid;
  readonly elementIdsToSave: ReadonlyArray<Uuid>;
};

type SaveOperationCallback = (operation: SaveOperation) => ThunkFunction;

export type CheckOperation = {
  readonly id: Uuid;
};

type CheckOperationCallback = (operation: CheckOperation) => ThunkFunction;

type SaveElementValues<IObservedProps> = (
  props: IObservedProps,
  operation: SaveOperation,
) => ThunkPromise;

type GetChangedElementIds<TObservedProps> = (
  nextState: IStore,
  currentObserved: TObservedProps,
  nextObserved: TObservedProps,
) => ReadonlyArray<Uuid>;

type DispatchProps = {
  readonly dispatch: Dispatch;
};

interface IAutoDispatcherProps<TObservedProps extends AnyObject> {
  readonly state: IStore;
  readonly observed: TObservedProps;
}

const NoElementIds: ReadonlyArray<Uuid> = [];

function createAutoDispatchComponent<TObservedProps extends AnyObject>(
  saveElementValues: SaveElementValues<TObservedProps>,
  debounceTime: number,
  getChangedElementIds: GetChangedElementIds<TObservedProps>,
  onSavePending: SaveOperationCallback,
  onSaveCancelled: SaveOperationCallback,
  onCheckPending: CheckOperationCallback,
  onCheckFinished: CheckOperationCallback,
): ComponentType<React.PropsWithChildren<IAutoDispatcherProps<TObservedProps> & DispatchProps>> {
  type AutoDispatcherProps = IAutoDispatcherProps<TObservedProps> & DispatchProps;

  class AutoDispatcher extends Component<AutoDispatcherProps> {
    static displayName = 'ElementsAutoDispatcher';

    private _originalObservedState!: TObservedProps; // We need to compare against the state before the last debounce. Not only last two changes.

    private readonly _storeOriginalState = (): void => {
      this._originalObservedState = this.props.observed;
    };

    private readonly _saveOperationRunner = createAutoDispatcherRunner<SaveOperation>(
      (operation) => {
        this._storeOriginalState();

        return Promise.resolve(
          this.props.dispatch(saveElementValues(this.props.observed, operation)),
        );
      },
      debounceTime,
      (cancelledOperation) => {
        this.props.dispatch(onSaveCancelled(cancelledOperation));
      },
    );

    private readonly _getIdsOfElementsChangedSinceLastSave = (
      nextObservedState: TObservedProps,
    ): ReadonlyArray<Uuid> => {
      if (areShallowEqual(nextObservedState, this._originalObservedState)) {
        return NoElementIds;
      }

      return getChangedElementIds(this.props.state, this._originalObservedState, nextObservedState);
    };

    private readonly _saveChanges = (
      idsOfElementsChangedSinceLastSave: ReadonlyArray<Uuid>,
    ): void => {
      const saveOperation: SaveOperation = {
        id: createGuid(),
        elementIdsToSave: idsOfElementsChangedSinceLastSave,
      };

      this.props.dispatch(onSavePending(saveOperation));
      this._saveOperationRunner.run(saveOperation);
    };

    private readonly _cancelSavingChanges = (): void => {
      this._saveOperationRunner.cancel();
    };

    private readonly _checkElementChanges = (nextObservedState: TObservedProps): void => {
      const idsOfElementsChangedSinceLastSave =
        this._getIdsOfElementsChangedSinceLastSave(nextObservedState);
      if (idsOfElementsChangedSinceLastSave.length) {
        this._saveChanges(idsOfElementsChangedSinceLastSave);
      } else {
        // If state reverted back to previous which wasn't saved yet, cancel the saving as it is not needed anymore
        this._cancelSavingChanges();
      }
    };

    private readonly _pendingChecksQueue: CheckOperation[] = [];

    private readonly _shouldElementsBeSavedCheckPending = () => {
      const pendingCheck: CheckOperation = {
        id: createGuid(),
      };
      this._pendingChecksQueue.push(pendingCheck);
      this.props.dispatch(onCheckPending(pendingCheck));
    };

    private readonly _shouldElementsBeSavedCheckFinished = () => {
      const pendingCheck = this._pendingChecksQueue.shift();
      if (pendingCheck) {
        this.props.dispatch(onCheckFinished(pendingCheck));
      }
    };

    componentDidMount(): void {
      this._storeOriginalState();
    }

    shouldComponentUpdate(nextProps: AutoDispatcherProps): boolean {
      // Only re-evaluate after a change between immediately following observed props
      // Otherwise you risk frequent UI updates to starve your auto-dispatch
      if (!areShallowEqual(this.props.observed, nextProps.observed)) {
        this._shouldElementsBeSavedCheckPending();
        // give React some breathing room
        window.setTimeout(() => {
          try {
            this._checkElementChanges(nextProps.observed);
          } finally {
            this._shouldElementsBeSavedCheckFinished();
          }
        }, 0);
      }

      return false;
    }

    render() {
      return null;
    }
  }

  return AutoDispatcher;
}

export function createElementsAutoDispatcher<TObservedProps extends AnyObject>(
  mapObservedState: (state: IStore) => TObservedProps,
  saveElementValues: SaveElementValues<TObservedProps>,
  debounceTime: number,
  getChangedElementIds: GetChangedElementIds<TObservedProps>,
  onSavePending: SaveOperationCallback,
  onSaveCancelled: SaveOperationCallback,
  onCheckPending: CheckOperationCallback,
  onCheckFinished: CheckOperationCallback,
): React.FC {
  const AutoDispatcher = createAutoDispatchComponent<TObservedProps>(
    saveElementValues,
    debounceTime,
    getChangedElementIds,
    onSavePending,
    onSaveCancelled,
    onCheckPending,
    onCheckFinished,
  );

  const ConnectedElementsAutoDispatcher: React.FC = () => {
    const dispatch = useDispatch();

    const autoDispatcherProps: IAutoDispatcherProps<TObservedProps> = useSelector(
      (state) => ({
        state,
        observed: mapObservedState(state),
      }),
      areShallowEqual,
    );

    return <AutoDispatcher {...autoDispatcherProps} dispatch={dispatch} />;
  };

  ConnectedElementsAutoDispatcher.displayName = 'ConnectedElementsAutoDispatcher';

  return memo(ConnectedElementsAutoDispatcher);
}
