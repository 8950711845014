import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_SelectedWorkflowChanged,
} from '../../constants/newContentItemActionTypes.ts';

export function workflowId(state: string | null = null, action: Action): Uuid | null {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.workflowId;

    case NewContentItem_SelectedWorkflowChanged:
      return action.payload.workflowId;

    default:
      return state;
  }
}
