import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export enum Color {
  Red = 'dropdown-option__badge--red',
  Orange = 'dropdown-option__badge--orange',
}

interface IDropDownOptionBadgeStateProps {
  readonly badgeValue?: number;
  readonly badgeColor?: Color;
}

export const DropDownOptionBadge: React.FC<IDropDownOptionBadgeStateProps> = ({
  badgeValue,
  badgeColor,
}) => (
  <div className="dropdown-option__badge-pane">
    <div
      className={classNames('dropdown-option__badge', badgeColor)}
      {...getDataUiElementAttribute(DataUiElement.Badge)}
    >
      {badgeValue}
    </div>
  </div>
);

DropDownOptionBadge.displayName = 'DropDownOptionBadge';
