import { Action } from '../../../../../@types/Action.type.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { Content_AssignedContentItems_OrderByChanged } from '../../../../contentInventory/content/constants/contentActionTypes.ts';

export enum OrderByColumn {
  LastModified = 'lastModifiedAt',
  Due = 'due',
}

export type AssignedContentItemsOrderBy = 'due' | 'lastModifiedAt';

const initialState: OrderBy<AssignedContentItemsOrderBy> = {
  columnCode: OrderByColumn.LastModified,
  direction: OrderByDirection.Descending,
};

export const assignedItemsOrdering = (
  state: OrderBy<AssignedContentItemsOrderBy> = initialState,
  action: Action,
) => {
  switch (action.type) {
    case Content_AssignedContentItems_OrderByChanged: {
      return action.payload.orderBy;
    }

    default: {
      return state;
    }
  }
};
