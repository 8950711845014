import { IDateTimeTypeElement } from '../../../contentInventory/content/models/contentTypeElements/DateTimeTypeElement.ts';
import { validElementWarningResult } from '../../constants/validElementWarningResults.ts';
import { DateTimeIsRequiredWarning } from '../../constants/warningMessageTemplates.ts';
import { DateTimeItemElement } from '../../models/contentItemElements/DateTimeItemElement.ts';
import { ItemElementWarningResult, emptyItemElementWarningResult } from './types/Warnings.ts';

const isRequiredLimitValid = (
  typeElement: IDateTimeTypeElement,
  itemElement: DateTimeItemElement,
): boolean => !typeElement.isRequired || !!itemElement.value;

interface IParams {
  readonly typeElement: IDateTimeTypeElement;
  readonly itemElement: DateTimeItemElement;
}

const invalidElementResult: ItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  requiredMessage: DateTimeIsRequiredWarning,
};

export const getDateTimeItemElementValidationWarning = ({
  typeElement,
  itemElement,
}: IParams): ItemElementWarningResult => {
  const isRequiredLimitMet = isRequiredLimitValid(typeElement, itemElement);

  return isRequiredLimitMet ? validElementWarningResult : invalidElementResult;
};
