import { ThrottleOptions, ThrottledFunction, throttle } from '../../../utils/func/throttle.ts';

/**
 * Behaves exactly like throttle function before provided `shouldThrottle` returns `false` for the
 * first time; always invokes provided `func` afterward.
 * @param func a function to be only invoked once per given time window
 * @param shouldThrottle a function that always returns true as long as throttling is required, current invocation arguments are always available to be taken into an account
 * @param wait minimal number of milliseconds to wait between each invocation of the throttled function (see WaitBehavior for details on how wait is applied)
 * @param options additional settings available for situations when default behavior is not completely applicable * @param func
 * @see throttle
 */
export function throttleUntil<A extends unknown[], R>(
  func: (...args: A) => R,
  shouldThrottle: (...args: A) => boolean,
  wait: number,
  options: ThrottleOptions = {},
): ThrottledFunction<(...args: A) => R> {
  let wasConditionMet = false;
  const funcThrottled = throttle(func, wait, options);

  const resultFunc = function (this: any): R | undefined {
    // biome-ignore lint/style/noArguments:
    const args: any = arguments;

    if (wasConditionMet) {
      return func.apply(this, args);
    }

    let result = funcThrottled.apply(this, args);
    if (!shouldThrottle.apply(this, args)) {
      funcThrottled.cancel();
      result = func.apply(this, args);
      wasConditionMet = true;
    }

    return result;
  };

  return Object.assign(resultFunc, {
    cancel: funcThrottled.cancel,
  } as any);
}
