import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import {
  LegacyWebhookMessagesRoute,
  LegacyWebhookMessagesRouteParams,
  SubscriptionEnvironmentLegacyWebhookMessagesRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IWebhookSetting } from '../../../../data/models/webhooks/WebhookSetting.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { resetWebhook } from '../../actions/thunkWebhooksActions.ts';
import { webhookEditInit } from '../../actions/webhooksActions.ts';
import {
  IWebhookBarCallbackProps,
  IWebhookBarDataProps,
  WebhookBar,
} from '../../components/webhookBarItems/WebhookBar.tsx';
import { hasValidWebhookTriggerConfiguration } from '../../utils/webhookSettingsTriggers.ts';

export interface IWebhookItemBarContainerProps {
  readonly webhookSetting: IWebhookSetting;
}

const getSubscriptionWebhookMessagesPath = (
  projectId: Uuid,
  webhookId: Uuid,
  subscriptionId: Uuid,
): string => {
  return buildPath<SubscriptionEnvironmentLegacyWebhookMessagesRouteParams>(
    SubscriptionEnvironmentLegacyWebhookMessagesRoute,
    {
      projectId,
      webhookId,
      subscriptionId,
    },
  );
};

const getWebhookMessagesPath = (projectId: Uuid, webhookId: Uuid): string => {
  return buildPath<LegacyWebhookMessagesRouteParams>(LegacyWebhookMessagesRoute, {
    projectId,
    webhookId,
  });
};

const mapStateToProps = (
  state: IStore,
  ownProps: IWebhookItemBarContainerProps,
): IWebhookBarDataProps => {
  const selectedSubscription = getSelectedSubscription(state);
  const projectId = state.sharedApp.currentProjectId;
  const webhookSetting = ownProps.webhookSetting;
  const hasLoader =
    state.webhooksApp.isResettingWebhookInProgress.get(webhookSetting.id) ||
    state.webhooksApp.isSettingWebhookStateInProgress.get(webhookSetting.id);
  const subscriptionId = selectedSubscription ? selectedSubscription.subscriptionId : null;
  const pathToMessages = subscriptionId
    ? getSubscriptionWebhookMessagesPath(projectId, webhookSetting.id, subscriptionId)
    : getWebhookMessagesPath(projectId, webhookSetting.id);

  return {
    hasLoader,
    webhookSetting: ownProps.webhookSetting,
    isEditable: !state.webhooksApp.editedWebhookId,
    pathToMessages,
    isTriggerConfigurationValid: hasValidWebhookTriggerConfiguration(state, webhookSetting.id),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: IWebhookItemBarContainerProps,
): IWebhookBarCallbackProps => ({
  onShowEditor: () => dispatch(webhookEditInit(ownProps.webhookSetting.id)),
  onReset: () => dispatch(resetWebhook(ownProps.webhookSetting)),
});

const WebhookItemBarContainer: React.ComponentType<IWebhookItemBarContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebhookBar);

export { WebhookItemBarContainer as WebhookBar };
