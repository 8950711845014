import { ILanguage } from '../../../data/models/languages/Language.ts';
import { anyLanguageLanguage } from '../constants/anyLanguageLanguage.ts';

export const handleAnyLanguageLanguageOnLanguagesChangedInFormInput = <TLanguage extends ILanguage>(
  formValues: ReadonlyArray<TLanguage>,
  previousValues: ReadonlyArray<TLanguage>,
): ReadonlyArray<TLanguage> => {
  const oldValuesContainAnyLanguage = previousValues.some(
    (language) => language.id === anyLanguageLanguage.id,
  );
  const newValuesContainAnyLanguage = formValues.some(
    (language) => language.id === anyLanguageLanguage.id,
  );
  const newValuesContainNormalLanguage = formValues.some(
    (language) => language.id !== anyLanguageLanguage.id,
  );
  return (oldValuesContainAnyLanguage && newValuesContainNormalLanguage) ||
    !newValuesContainAnyLanguage
    ? formValues.filter((language) => language.id !== anyLanguageLanguage.id)
    : formValues.filter((language) => language.id === anyLanguageLanguage.id);
};
