import { Button } from '@kontent-ai/component-library/Button';
import classNames from 'classnames';
import Immutable from 'immutable';
import Ordinal from 'ordinal';
import React from 'react';
import { DataTable } from '../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableAction } from '../../../_shared/components/DataTable/DataTableActions.tsx';
import { DataTableCell } from '../../../_shared/components/DataTable/DataTableCell.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../_shared/components/DataTable/DataTableRow.tsx';
import { EmptyState } from '../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlSettingsPageTitle } from '../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { OrderByDirection } from '../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../_shared/utils/dateTime/timeUtils.ts';
import { TagColor } from '../../../data/constants/tagColor.ts';
import {
  IEntityWebhookSetting,
  emptyEntityWebhook,
} from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IWebhookMessage } from '../../../data/models/webhooks/WebhookMessage.ts';
import { IWebhookSetting, emptyObject } from '../../../data/models/webhooks/WebhookSetting.ts';
import { EnvironmentSettingsAppNames } from '../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { WebhookMessageFilterOptionId } from '../constants/webhookMessageFilterOptions.ts';
import { WebhookMessageContentButton } from '../containers/webhookMessageDialog/WebhookMessageContentButton.tsx';
import { WebhookMessageListingFilter } from './WebhookMessageFilter.tsx';
import { WebhookMessageLastResponseButton } from './webhookMessageDialog/WebhookMessageLastResponseButton.tsx';

const webhookMessageColumns = Immutable.List.of<Column>(
  {
    columnName: 'Last response',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Sent',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Notification contents',
    orderBy: OrderByDirection.None,
    className: 'webhook-message-listing__table-cell--message-content',
  },
);

const getWebhookName = (
  entityWebhookSetting: IEntityWebhookSetting,
  webhookSetting: IWebhookSetting,
): string | undefined => {
  if (entityWebhookSetting !== emptyEntityWebhook) {
    return entityWebhookSetting.name;
  }

  if (webhookSetting !== emptyObject) {
    return webhookSetting.name;
  }

  return undefined;
};

const getStatusText = (isDelivered: boolean, failureCount: number): string => {
  const textLabel = isDelivered ? 'delivered' : 'failed';

  return failureCount > 0 ? `${textLabel} (${Ordinal(failureCount)} attempt)` : textLabel;
};

const isWebhookDelivered = (statusCode: number): boolean => {
  return statusCode >= 200 && statusCode < 300;
};

const generateStatusError = (lastAttemptStatusCode: number): string => {
  let status = '';

  if (lastAttemptStatusCode === -1) {
    status = 'Timeout';
  } else if (lastAttemptStatusCode === -2) {
    status = 'Error';
  }

  return status;
};

const generateStatus = (lastAttemptStatusCode: number): string => {
  if (lastAttemptStatusCode > 100) {
    return lastAttemptStatusCode.toString();
  }

  return generateStatusError(lastAttemptStatusCode);
};

interface IDeliveringStatusProps {
  readonly statusCode: number;
  readonly failureCount: number;
}

const DeliveringStatus: React.FC<IDeliveringStatusProps> = ({ statusCode, failureCount }) => {
  const status = generateStatus(statusCode);
  const isDelivered = isWebhookDelivered(statusCode);
  const statusText = getStatusText(isDelivered, failureCount);

  return (
    <div className="webhook-message-listing__status">
      <Tag
        color={isDelivered ? TagColor.LightGreen : TagColor.Red}
        customClass="webhook-message-listing__tag"
      >
        {status}
      </Tag>
      <span className="webhook-message-listing__text">{statusText}</span>
    </div>
  );
};

DeliveringStatus.displayName = 'DeliveringStatus';

type WebhookMessageListingProps = {
  readonly entityWebhookSetting: IEntityWebhookSetting;
  readonly onFilter: (WebhookMessageFilterOptionId: WebhookMessageFilterOptionId) => void;
  readonly onRefresh: () => void;
  readonly webhookMessages: ReadonlyArray<IWebhookMessage>;
  readonly webhookMessagesListingFilter: WebhookMessageFilterOptionId;
  readonly webhookMessagesLoadingStatus: LoadingStatus;
  readonly webhookSetting: IWebhookSetting;
};

export const WebhookMessageListing: React.FC<WebhookMessageListingProps> = ({
  entityWebhookSetting,
  onFilter,
  onRefresh,
  webhookMessages,
  webhookMessagesListingFilter,
  webhookMessagesLoadingStatus,
  webhookSetting,
}) => {
  const filterMessage = (webhookMessage: IWebhookMessage): boolean => {
    switch (webhookMessagesListingFilter) {
      case WebhookMessageFilterOptionId.ShowAllFailed:
        return webhookMessage.failureCount > 0;
      case WebhookMessageFilterOptionId.ShowLastResponseFailed:
        return !isWebhookDelivered(webhookMessage.lastAttemptStatusCode);
      default:
        return true;
    }
  };

  const getContentPane = () => {
    const refreshAction: DataTableAction = {
      text: 'Refresh',
      onClick: onRefresh,
      isDestructive: false,
      isDisabled: false,
      dataUiAction: DataUiAction.Refresh,
    };

    if (webhookMessagesLoadingStatus === LoadingStatus.Loading) {
      return <Loader />;
    }

    if (webhookMessages.length <= 0) {
      return (
        <EmptyState>
          <EmptyState.Title>
            No webhooks have been triggered in the past three days.
          </EmptyState.Title>
          <EmptyState.Content>
            Modify your published content to see webhooks appear here.
          </EmptyState.Content>
          <EmptyState.Footer>
            <Button
              buttonStyle="primary"
              onClick={onRefresh}
              {...getDataUiActionAttribute(DataUiAction.Refresh)}
            >
              Refresh
            </Button>
          </EmptyState.Footer>
        </EmptyState>
      );
    }

    return (
      <div className="webhook-message-listing">
        <WebhookMessageListingFilter
          onSelectionChanged={onFilter}
          selectedOptionId={webhookMessagesListingFilter}
        />
        <DataTable
          dataUiCollectionName={DataUiCollection.WebhookMessages}
          actions={[refreshAction]}
          title={<ListingMessage statusInfoMessage={{ text: 'Webhook notifications' }} />}
          header={<DataTableHeadRow columns={webhookMessageColumns} />}
        >
          {webhookMessages.filter(filterMessage).map((webhookMessage: IWebhookMessage) => (
            <DataTableRow
              id={webhookMessage.messageIndex}
              key={webhookMessage.messageIndex}
              dataUiObjectName={webhookMessage.messageIndex}
            >
              <DataTableCell className="webhook-message-listing__table-cell--actions">
                <DeliveringStatus
                  statusCode={webhookMessage.lastAttemptStatusCode}
                  failureCount={webhookMessage.failureCount}
                />
                <WebhookMessageLastResponseButton content={webhookMessage.lastFailureResponse} />
              </DataTableCell>
              <DataTableCell>
                {renderDatetimeString(webhookMessage.lastAttemptTimestamp, true)}
              </DataTableCell>
              <DataTableCell
                className={classNames(
                  'webhook-message-listing__table-cell--actions',
                  'webhook-message-listing__table-cell--message-content',
                )}
              >
                <WebhookMessageContentButton
                  webhookId={webhookMessage.webhookId}
                  messageIndex={webhookMessage.messageIndex}
                />
              </DataTableCell>
            </DataTableRow>
          ))}
        </DataTable>
      </div>
    );
  };

  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.WebhookMessages)}>
      <HtmlSettingsPageTitle
        settingsAppName={
          webhookSetting === emptyObject
            ? EnvironmentSettingsAppNames.Webhooks
            : EnvironmentSettingsAppNames.LegacyWebhooks
        }
        customName={getWebhookName(entityWebhookSetting, webhookSetting)}
      />
      <section className="canvas__workspace">
        <section className="canvas__content">
          <div className="canvas__content-pane canvas__content-pane--no-bottom-offset">
            <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
              {getContentPane()}
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

WebhookMessageListing.displayName = 'WebhookMessageListing';
