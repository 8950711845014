import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { RequiredAssetCreationMetadata } from '../../../../repositories/serverModels/AssetServerModels.type.ts';
import { uploadAssets as uploadAssetsAction } from '../actions/thunkAssetLibraryActions.ts';
import { FileWithThumbnail } from '../models/FileWithThumbnail.type.ts';

type Args = {
  readonly assetTypeSelectionFilter?: (assetType: string) => boolean;
  readonly selectAssets?: (assets: ReadonlyArray<Uuid>) => void;
  readonly targetFolderId: Uuid;
};

const noFilter = (): boolean => true;

export const useUploadAndPreselectAssets = ({
  assetTypeSelectionFilter = noFilter,
  selectAssets,
  targetFolderId,
}: Args) => {
  const dispatch = useDispatch();

  const uploadAssets = useCallback(
    async (files: ReadonlyArray<FileWithThumbnail>, collectionId: Uuid | null): Promise<void> => {
      const metadata: RequiredAssetCreationMetadata = {
        folderId: targetFolderId,
        collectionId,
      };

      const uploadedAssets = await dispatch(
        uploadAssetsAction({
          files,
          metadata,
        }),
      );

      if (selectAssets) {
        const assetsToSelect = uploadedAssets.filter((a) =>
          assetTypeSelectionFilter(a.newAsset.type),
        );

        selectAssets(assetsToSelect.map((a) => a.newAsset.id));
      }
    },
    [assetTypeSelectionFilter, selectAssets, targetFolderId],
  );

  return uploadAssets;
};
