import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Webhooks_Webhook_ResetCompleted,
  Webhooks_Webhook_ResetFailed,
  Webhooks_Webhook_ResetStarted,
} from '../../constants/webhooksActionTypes.ts';

type IsResettingWebhookInProgressType = ReadonlyMap<Uuid, boolean>;
const initialState: IsResettingWebhookInProgressType = new Map<Uuid, boolean>();

export const isResettingWebhookInProgress = (
  state: IsResettingWebhookInProgressType = initialState,
  action: Action,
): IsResettingWebhookInProgressType => {
  switch (action.type) {
    case Webhooks_Webhook_ResetStarted:
      return Collection.add(state, [action.payload.webhook.id, true]);

    case Webhooks_Webhook_ResetCompleted:
    case Webhooks_Webhook_ResetFailed:
      return Collection.add(state, [action.payload.webhook.id, false]);

    default:
      return state;
  }
};
