import { assert, noOperation } from '@kontent-ai/utils';
import { ContentBlock, ContentState, Modifier } from 'draft-js';
import Immutable from 'immutable';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import { createSelection, setContentSelection } from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  insertNewChars,
} from '../../../utils/general/editorContentUtils.ts';
import { EntityMutability, EntityType } from '../../entityApi/api/Entity.ts';
import { removeMatchingEntities } from '../../entityApi/api/editorEntityUtils.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';
import { isFinishedMention, isMention, isNewMention } from './MentionEntity.ts';

export const MentionStartingChar = '@';

export function findMentions(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (!entityKey) {
      return false;
    }
    const entity = contentState.getEntity(entityKey);
    return isMention(entity);
  }, callback);
}

export function findFinishedMentions(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (!entityKey) {
      return false;
    }
    const entity = contentState.getEntity(entityKey);
    return isFinishedMention(entity);
  }, callback);
}

export function findAllUserIdsMentionedInContentState(
  content: ContentState,
): Immutable.OrderedSet<Uuid> {
  const foundUserIds = getBlocks(content).flatMap((block: ContentBlock) => {
    const userIdsInBlock: Uuid[] = [];
    block.findEntityRanges((character) => {
      const charEntityKey = character.getEntity();
      if (!charEntityKey) {
        return false;
      }
      const contentEntity = content.getEntity(charEntityKey);
      if (isFinishedMention(contentEntity)) {
        userIdsInBlock.push(contentEntity.getData().userId);
      }
      return false;
    }, noOperation);

    return userIdsInBlock;
  });

  return Immutable.OrderedSet(foundUserIds);
}

export function createNewMention(input: IContentChangeInput): IContentChangeResult {
  // Only one open new mention is allowed at a time
  const withoutNewMentions = removeMatchingEntities(input, isNewMention);

  const content = withoutNewMentions.content;
  const selection = withoutNewMentions.selection;

  const contentStateWithMention = content.createEntity(
    EntityType.Mention,
    EntityMutability.Mutable,
    {},
  );
  const entityKey = contentStateWithMention.getLastCreatedEntityKey();

  const newContent = Modifier.applyEntity(content, selection, entityKey);
  const newSelection = createSelection(selection.getEndKey(), selection.getEndOffset());
  const newContentWithSelection = setContentSelection(newContent, newSelection, newSelection);

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
}

export function applyNewMention(input: IContentChangeInput): IContentChangeResult {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const selectionStartKey = selection.getStartKey();
  const block = content.getBlockForKey(selectionStartKey);

  if (block) {
    const blockText = block.getText();
    const lastCharOffset = selection.getStartOffset() - 1;
    const lastChar = blockText[lastCharOffset];
    if (lastChar === MentionStartingChar) {
      // If there is a non-whitespace char before the @, we don't want to initiate the new mention, it is part of something continuous, e.g. e-mail address
      if (lastCharOffset > 0) {
        const previousCharOffset = lastCharOffset - 1;
        const previousChar = blockText[previousCharOffset];
        assert(isString(previousChar), () => 'Previous char is not string.');
        const isPreviousCharWhitespace = /\s/.test(previousChar);
        if (!isPreviousCharWhitespace) {
          // Except for the previous char being a mention, because mentions are atomic and two mentions next to each other are OK
          const previousCharEntityKey = block.getEntityAt(previousCharOffset);
          const previousCharEntity =
            previousCharEntityKey && content.getEntity(previousCharEntityKey);
          const isPreviousCharMention = previousCharEntity && isFinishedMention(previousCharEntity);
          if (!isPreviousCharMention) {
            return input;
          }
        }
      }

      const lastCharEntity = block.getEntityAt(lastCharOffset);

      // Already some entity present, do not allow mention
      if (lastCharEntity) {
        return input;
      }

      return createNewMention({
        content: input.content,
        selection: createSelection(
          selectionStartKey,
          lastCharOffset,
          selectionStartKey,
          lastCharOffset + 1,
        ),
      });
    }
  }

  return input;
}

export function createMention(input: IContentChangeInput, userId: UserId): IContentChangeResult {
  const oneChar = updateText(input, MentionStartingChar);

  const content = oneChar.content;
  const selection = oneChar.selection;

  const contentStateWithMention = content.createEntity(
    EntityType.Mention,
    EntityMutability.Immutable,
    { userId },
  );
  const entityKey = contentStateWithMention.getLastCreatedEntityKey();

  const newContent = Modifier.applyEntity(content, selection, entityKey);
  const newSelection = createSelection(selection.getEndKey(), selection.getEndOffset());
  const newContentWithSelection = setContentSelection(newContent, newSelection, newSelection);

  const withMention = {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };

  return insertNewChars(withMention, ' ');
}
