import { memoize } from '@kontent-ai/memoization';
import { Collection, alphabetically } from '@kontent-ai/utils';
import { doesEntitySatisfyFilterPhrase } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ISubscriptionUserDetailFilter } from '../../shared/stores/ISubscriptionStoreState.type.ts';
import { IUserProjectSettings } from '../models/UserProjectSettings.ts';

export type ProjectSettingsSearchResult = {
  fullTextMatches?: ProjectSettingsFullTextFields;
  project: IProjectDetails;
  searchPhrase: string;
};

export type ProjectSettingsFullTextFields = {
  readonly environmentNames: Array<string>;
  readonly roleNames: Set<string>;
  readonly languageNames: Set<string>;
};

const createEmptyFullTextFields = (): ProjectSettingsFullTextFields => ({
  environmentNames: [],
  roleNames: new Set(),
  languageNames: new Set(),
});

type FullTextFieldsByProject = ReadonlyMap<Uuid, ProjectSettingsFullTextFields>;

const searchResultComparer = (a: ProjectSettingsSearchResult, b: ProjectSettingsSearchResult) =>
  alphabetically(a.project.projectName, b.project.projectName);

export const getFilteredProjectSettings = memoize.weak(
  (
    allEnvironments: ReadonlyArray<IProjectDetails>,
    userEnvironments: ReadonlyArray<IProjectDetails>,
    userProjectSettings: ReadonlyArray<IUserProjectSettings>,
    filter: ISubscriptionUserDetailFilter,
    areLanguageRolesEnabled: boolean,
  ): ReadonlyArray<ProjectSettingsSearchResult> => {
    const fullTextFields = getFullTextFieldsByProject(
      userProjectSettings,
      allEnvironments,
      areLanguageRolesEnabled,
    );
    const filteredFullTextFields = getFilteredFullTextFieldsByProject(
      fullTextFields,
      filter.searchPhrase,
    );

    const filteredUserEnvironments = userEnvironments
      .filter(
        (environment) =>
          !filter.byProject.size || filter.byProject.has(environment.masterEnvironmentId),
      )
      .filter((environment) => filteredFullTextFields.has(environment.projectId));

    const filteredUserMasterEnvironments = allEnvironments.filter((environment) =>
      filteredUserEnvironments.some(
        (filteredEnv) => filteredEnv.masterEnvironmentId === environment.projectId,
      ),
    );

    const searchResults: ReadonlyArray<ProjectSettingsSearchResult> =
      filteredUserMasterEnvironments.map((masterEnvironment) => {
        const environmentIds = getProjectEnvironmentIds(
          userEnvironments,
          masterEnvironment.projectId,
        );
        return {
          fullTextMatches: getAggregatedFullTextMatches(filteredFullTextFields, environmentIds),
          project: masterEnvironment,
          searchPhrase: filter.searchPhrase,
        };
      });

    return searchResults.toSorted(searchResultComparer);
  },
);

const getProjectEnvironmentIds = memoize.weak(
  (allProjects: ReadonlyArray<IProjectDetails>, masterEnvironmentId: Uuid) =>
    allProjects
      .filter((environment) => environment.masterEnvironmentId === masterEnvironmentId)
      .map((environment) => environment.projectId),
);

const getAggregatedFullTextMatches = (
  fullTextFieldsByProject: FullTextFieldsByProject,
  projectIds: ReadonlyArray<Uuid>,
): ProjectSettingsFullTextFields =>
  projectIds.reduce((aggregated, projectId): ProjectSettingsFullTextFields => {
    const currentProjectFields = fullTextFieldsByProject.get(projectId);
    if (!currentProjectFields) {
      return aggregated;
    }

    return {
      environmentNames: [...aggregated.environmentNames, ...currentProjectFields.environmentNames],
      roleNames: new Set([...aggregated.roleNames, ...currentProjectFields.roleNames]),
      languageNames: new Set([...aggregated.languageNames, ...currentProjectFields.languageNames]),
    };
  }, createEmptyFullTextFields());

export const shortenDefaultLanguageName = (languageName: string): string =>
  languageName === 'Default project language' ? 'Default' : languageName;

const getFullTextFieldsByProject = memoize.weak(
  (
    userProjectSettings: ReadonlyArray<IUserProjectSettings>,
    allProjects: ReadonlyArray<IProjectDetails>,
    areLanguageRolesEnabled: boolean,
  ): FullTextFieldsByProject => {
    const map = new Map<Uuid, ProjectSettingsFullTextFields>();

    userProjectSettings.forEach((settings) => {
      const fullTextFields = createEmptyFullTextFields();
      const environmentName = allProjects.find(
        (project) => project.projectId === settings.projectId,
      )?.environmentName;
      if (environmentName) {
        fullTextFields.environmentNames.push(environmentName);
      }

      settings.collectionGroups.forEach((colGroup) => {
        colGroup.roles.forEach((role) => {
          fullTextFields.roleNames.add(role.name);
          if (areLanguageRolesEnabled) {
            role.languages.forEach((language) => {
              fullTextFields.languageNames.add(shortenDefaultLanguageName(language.name));
            });
          }
        });
      });

      map.set(settings.projectId, fullTextFields);
    });

    return map;
  },
);

const filterByName = (name: string, filterPhrase: string): boolean =>
  !filterPhrase || doesEntitySatisfyFilterPhrase(filterPhrase, name, [(item) => item]);

const getFilteredFullTextFieldsByProject = (
  fullTextFieldsByProject: FullTextFieldsByProject,
  searchPhrase: string,
): FullTextFieldsByProject => {
  const map = new Map<Uuid, ProjectSettingsFullTextFields>();

  fullTextFieldsByProject.forEach((fullTextFields, key) => {
    const filteredEnvironmentNames = fullTextFields.environmentNames.filter((name) =>
      filterByName(name, searchPhrase),
    );
    const filteredRoleNames = Collection.getValues(fullTextFields.roleNames).filter((name) =>
      filterByName(name, searchPhrase),
    );
    const filteredLanguageNames = Collection.getValues(fullTextFields.languageNames).filter(
      (name) => filterByName(name, searchPhrase),
    );

    if (
      filteredEnvironmentNames.length ||
      filteredRoleNames.length ||
      filteredLanguageNames.length
    ) {
      map.set(key, {
        ...fullTextFields,
        environmentNames: filteredEnvironmentNames,
        roleNames: new Set(filteredRoleNames),
        languageNames: new Set(filteredLanguageNames),
      });
    }
  });

  return map;
};
