import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { renderDatetimeString } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import {
  joinWithOxfordComma,
  makeSpacesUnbreakable,
  pluralizeWithCount,
} from '../../../../../_shared/utils/stringUtils.ts';
import { formatCurrentUserName, formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

const MaxFellowUserCount = 4;

interface IRevisionItemProps {
  readonly contributors: ReadonlyArray<IUserInfo>;
  readonly currentUserId: UserId;
  readonly isCurrent: boolean;
  readonly item: TimelineItem;
}

export const RevisionItem: React.FC<IRevisionItemProps> = ({
  item,
  isCurrent,
  currentUserId,
  contributors,
}): JSX.Element => {
  const displayedUsers = contributors
    .slice(0, MaxFellowUserCount)
    .map((u) => formatCurrentUserName(currentUserId, false)(u));

  const overlapCount = contributors.length - MaxFellowUserCount;
  const overlappedUserNames = contributors.slice(MaxFellowUserCount).map((u) => formatUserName(u));

  const titleDateTimeString = makeSpacesUnbreakable(renderDatetimeString(item.time));

  return (
    <div className="revision-item">
      <div className="revision-item__details-pane">
        {isCurrent && <div className="revision-item__current-version-label">Current version</div>}
        <div className="revision-item__title" title={titleDateTimeString}>
          {titleDateTimeString}
        </div>
        {contributors.length > 0 && (
          <div className="revision-item__detail-note">
            updated by {joinWithOxfordComma(displayedUsers)}
            {overlapCount > 0 && (
              <>
                {' '}
                <Tooltip placement="top" tooltipText={joinWithOxfordComma(overlappedUserNames)}>
                  <span className="revision-item__detail-note-tooltip">
                    +{pluralizeWithCount('other', overlapCount)}
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

RevisionItem.displayName = 'RevisionItem';
