import { DynamicActionDetail } from '../../../utils/editingActions/mergeActionDetails.ts';

export type EditingActionDynamicDetailProviderOwnProps = {
  readonly children: (dynamicDetail: DynamicActionDetail) => JSX.Element | null;
};

type EditingActionDynamicDetailProviderProps = EditingActionDynamicDetailProviderOwnProps &
  DynamicActionDetail;

export const EditingActionDynamicDetailProvider = ({
  children,
  ...dynamicDetail
}: EditingActionDynamicDetailProviderProps) => children(dynamicDetail);
