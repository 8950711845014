import { CustomTypeElementValidationResult } from '../types/CustomTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const CustomInitialValidationResult: CustomTypeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  isGuidelinesValid: true,
  isNameValid: true,
  isConfigValid: true,
  isSourceUrlValid: true,
  errorMessages: [],
  isAvailableInPlan: true,
};
