import React from 'react';
import { DefaultWorkflowId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { createWorkflowScope, deleteWorkflowScope } from '../../actions/workflowActions.ts';
import { WorkflowLimitations as WorkflowLimitationsComponent } from '../../components/limitations/WorkflowLimitations.tsx';

export const WorkflowLimitations: React.FC = () => {
  const isDefaultWorkflow = useSelector(
    (state) => state.workflowsApp.editorUi.editedWorkflowId === DefaultWorkflowId,
  );
  const workflowScopesIds = useSelector(
    (state) => Array.from(state.workflowsApp.editorUi.editedWorkflowScopesById.keys()),
    shallowEqual,
  );
  const hasUnlimitedScopes = useSelector((state) =>
    Array.from(state.workflowsApp.editorUi.editedWorkflowScopesById.values()).some(
      (scope) => scope.contentTypes.size === 0 && scope.collections.size === 0,
    ),
  );

  const dispatch = useDispatch();
  const onWorkflowScopeCreated = () => dispatch(createWorkflowScope());
  const onWorkflowScopeDeleted = (scopeId: Uuid) => dispatch(deleteWorkflowScope(scopeId));

  return (
    <WorkflowLimitationsComponent
      isDefaultWorkflow={isDefaultWorkflow}
      workflowScopesIds={workflowScopesIds}
      hasUnlimitedScopes={hasUnlimitedScopes}
      onWorkflowScopeCreated={onWorkflowScopeCreated}
      onWorkflowScopeDeleted={onWorkflowScopeDeleted}
    />
  );
};

WorkflowLimitations.displayName = 'WorkflowLimitationsContainer';
