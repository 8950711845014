import React from 'react';
import { Link } from 'react-router-dom';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import {
  PlanSelectionRoute,
  SubscriptionRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areCollectionPermissionsEnabledForCurrentProject } from '../../../../_shared/selectors/contentCollections.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getSubscriptionIdFromCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { areContentCollectionsEnabledForCurrentProjectPlan } from '../../selectors/allowedFeaturesUtils.ts';

export const CollectionPermissionsQuickTip: React.FC = () => {
  const subscriptionId = useSelector(getSubscriptionIdFromCurrentProject);
  const areCollectionPermissionsEnabled = useSelector(
    areCollectionPermissionsEnabledForCurrentProject,
  );
  const areCollectionsEnabled = useSelector(areContentCollectionsEnabledForCurrentProjectPlan);

  if (!areCollectionsEnabled || areCollectionPermissionsEnabled) {
    return null;
  }

  const subscriptionDetailsLink = buildPath<SubscriptionRouteParams>(PlanSelectionRoute, {
    subscriptionId,
  });

  return (
    <QuickTip>
      <p>
        If you need to assign collection-specific permissions,{' '}
        <Link to={subscriptionDetailsLink}>upgrade to a higher plan</Link>.
      </p>
    </QuickTip>
  );
};
