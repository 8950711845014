import { useCallback } from 'react';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ListingRequestOptionsModel } from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../../../unifiedMissionControl/widgets/shared/hooks/tracking.ts';
import { TaskWidgetDetailTableLiveContainer } from '../../shared/containers/TaskWidgetDetailTableLiveContainer.tsx';
import { TasksAssignedByYouWidget } from '../components/TasksAssignedByYouWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const TasksAssignedByYouWidgetLiveContainer = () => {
  const tasksFetcher = useCallback(
    (requestOptions: ListingRequestOptionsModel, abortSignal: AbortSignal) =>
      missionControlRepository.getTasksCreatedByCurrentUser(requestOptions, abortSignal),
    [],
  );

  const trackWidgetDetailItemClicked = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.TasksAssignedByYou,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.TasksAssignedByYou,
  );

  return (
    <TasksAssignedByYouWidget
      onShowMoreClick={trackWidgetShowMoreButtonClick}
      tasksFetcher={tasksFetcher}
      renderWidgetDetail={(tasks, state) => (
        <TaskWidgetDetailTableLiveContainer
          onItemClick={trackWidgetDetailItemClicked}
          tasks={tasks}
          state={state}
        />
      )}
    />
  );
};
