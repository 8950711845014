import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import {
  IContentItemWithVariantsServerModel,
  INewContentItemServerModel,
  RequiredItemCreationParams,
  createEmptyContentItem,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';

export type ICreateContentItemAction = (
  params: RequiredItemCreationParams,
) => ThunkPromise<IContentItemWithVariantsServerModel>;

interface ICreateNewContentItemDependencies {
  readonly contentItemRepository: {
    readonly createItem: (
      item: INewContentItemServerModel,
    ) => Promise<IContentItemWithVariantsServerModel>;
  };
}

export const createContentItemActionCreator =
  ({ contentItemRepository }: ICreateNewContentItemDependencies): ICreateContentItemAction =>
  ({ typeId, collectionId, name }) =>
  async (): Promise<IContentItemWithVariantsServerModel> => {
    const item = createEmptyContentItem({ typeId, collectionId, name });

    return await contentItemRepository.createItem(item);
  };
