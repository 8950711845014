import { Spacing, gridUnit } from '../quarks/spacing.ts';

export const Size = {
  XS: Spacing.XL,
  S: Spacing.XXL,
  M: Spacing.XXXL,
  L: Spacing.XXXXL,
} as const;

export type Size = (typeof Size)[keyof typeof Size];

export const spacingElementLabel = Spacing.S;
export const spacingElementCaption = Spacing.S;
export const spacingElementEdgeHorizontal = Spacing.L;
export const spacingElementEdgeVertical = Spacing.L;

export const spacingPopupDistance = Spacing.XS;
export const spacingPopupLarge = Spacing.XXL;

export const spacingInputSmall = Spacing.S;
export const spacingInputLarge = Spacing.L;

export const spacingBetweenButtonsHorizontal = Spacing.S;
export const spacingBetweenButtonsVertical = Spacing.L;
export const spacingBetweenCards = Spacing.M;
export const spacingBetweenTags = Spacing.XS;

export const spacingHierarchyIndent = Spacing.XL;

export const spacingToggleVertical = Spacing.S;
export const spacingToggleHorizontal = Spacing.M;

export const spacingNavigationBarToggle = Spacing.S;

export const spacingCard = Spacing.XL;

export const spacingSimpleStatusProtectiveArea = Spacing.XS;

export const sizeGuard = 12 * gridUnit;

export const sizeSidebarPlaceholderWidth = Size.S;
export const sizeSidebarWidth = 46 * gridUnit;

export const sizeFilterSidebarWidth = 36 * gridUnit;

export const sizeHorizontalTabHeight = Size.S;

export const sizeBarItemBar = Size.L;

export const spacingMainLayoutLeft = Spacing.XXL;
export const spacingMainLayoutTop = Spacing.XXL;
export const spacingMainLayoutRight = Spacing.XL;
export const spacingMainLayoutBottom = Spacing.XXXXXL;

export const sizeSelectMaxWidth = 36 * gridUnit;
