export enum ZIndex {
  OneHundred = 100,
  TwoHundred = 200,
  FiveHundred = 500,
  SixHundred = 600,
  SevenHundred = 700,
  EightHundred = 800,
  NineHundred = 900,
  TwoThousandFourHundred = 2400,
  NineThousandNineHundredNinetyNine = 9999, // z-index of tippy https://atomiks.github.io/tippyjs/v6/all-props/#zindex
  TenThousand = 10000,
}
