import { memoize } from '@kontent-ai/memoization';
import { hasUserScrolledUpSelector } from './selectors/hasUserScrolledUpSelector.ts';
import { isCandidSelector } from './selectors/isCandidSelector.ts';
import { isPaperScrolledOutSelector } from './selectors/isPaperScrolledOutSelector.ts';

export type EditorScrollContextValue = {
  readonly hasUserScrolledUp: boolean;
  // Should be false if other values could be incorrect (e.g. for initial state – before flags are computed for the first time). Flag allows
  // UI not to render elements before their props are correctly computed (e.g. supported number of quick actions or text in menu button).
  readonly isCandid: boolean;
  readonly isPaperScrolledOut: boolean;
};

export type EditorScrollProviderState = {
  readonly isCandid: boolean;
  readonly offsetTopPx: number;
  readonly scrollTopPx: number;
};

const getMemoizedContextValue = memoize.maxN(
  (
    hasUserScrolledUp: boolean,
    isCandid: boolean,
    isPaperScrolledOut: boolean,
  ): EditorScrollContextValue => ({
    hasUserScrolledUp,
    isCandid,
    isPaperScrolledOut,
  }),
  2,
);

const memoizeContextValue = (contextValue: EditorScrollContextValue): EditorScrollContextValue =>
  getMemoizedContextValue(
    contextValue.hasUserScrolledUp,
    contextValue.isCandid,
    contextValue.isPaperScrolledOut,
  );

type CreateEditorScrollContextValue = (
  currentState: EditorScrollProviderState,
  previousState: EditorScrollProviderState,
  previousValue: EditorScrollContextValue,
) => EditorScrollContextValue;

export const createMemoizedEditorScrollContextValue: CreateEditorScrollContextValue = (
  currentState,
  previousState,
  previousValue,
) =>
  memoizeContextValue({
    hasUserScrolledUp: hasUserScrolledUpSelector(currentState, previousState, previousValue),
    isCandid: isCandidSelector(currentState),
    isPaperScrolledOut: isPaperScrolledOutSelector(currentState),
  });
