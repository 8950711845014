import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ItemVariantTimeline_Load_Initial_Finished,
  ItemVariantTimeline_Load_MoreItems_Finished,
} from '../../features/Timeline/constants/timelineActionTypes.ts';

const initialState: string | null = null;

export function timelineContinuationToken(state = initialState, action: Action) {
  switch (action.type) {
    case ItemVariantTimeline_Load_MoreItems_Finished:
    case ItemVariantTimeline_Load_Initial_Finished: {
      return action.payload.timelineContinuationToken;
    }

    case ContentItemEditing_Init_Started:
    case ContentListing_Init_Started: {
      return initialState;
    }

    default:
      return state;
  }
}
