import { IProjectLocationServerModel } from '../../repositories/serverModels/ProjectLocationServerModel.type.ts';

export interface IProjectLocation {
  readonly projectLocationId: string;
  readonly projectLocationName: string;
  readonly isDefault: boolean;
  readonly isDedicated: boolean;
}

export const getProjectLocationFromServerModel = (
  rawProjectLocation: IProjectLocationServerModel,
): IProjectLocation => {
  if (typeof rawProjectLocation !== 'object') {
    throw new Error('Project location is not an object');
  }

  return {
    projectLocationId: rawProjectLocation.projectLocationId || '',
    projectLocationName: rawProjectLocation.projectLocationName || '',
    isDefault: rawProjectLocation.isDefault || false,
    isDedicated: rawProjectLocation.isDedicated || false,
  };
};
