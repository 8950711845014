import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

export const LegacyWebhookQuickTip: React.FC = () => {
  return (
    <Callout calloutType="friendlyWarning" maxWidth={1000}>
      <Stack spacing={Spacing.XL}>
        <p>
          <b>Legacy webhooks are deprecated</b>
        </p>
        <p>
          We recommend that you{' '}
          <OutwardLink href={documentationLinks.migrateFromLegacyWebhooks}>
            migrate from legacy webhooks
          </OutwardLink>{' '}
          to the new webhooks experience for better performance, advanced filtering, and more.
        </p>
        <p>
          Legacy webhooks soon reach their end of life. Plan ahead and prepare your migration in
          time.
        </p>
      </Stack>
    </Callout>
  );
};

LegacyWebhookQuickTip.displayName = 'LegacyWebhookQuickTip';
