import styled from 'styled-components';
import { StyledSkeleton } from './StyledSkeleton.tsx';

export const StyledTextSkeleton = styled(StyledSkeleton)`
  &:before {
    content: 'A';
    display: block;
    visibility: hidden;
  }
`;
