import { Action } from '../../../../@types/Action.type.ts';
import { emptyAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_WorkflowStep_Selected,
} from '../constants/changeWorkflowStepActionTypes.ts';

export function workflowStep(state = emptyAssignmentWorkflowStep, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.workflowStep;

    case ChangeWorkflowStep_WorkflowStep_Selected:
      return action.payload.workflowStep;

    default:
      return state;
  }
}
