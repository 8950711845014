import { isToday } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { calendarPopupClosed } from '../../actions/calendarActions.ts';
import {
  AgendaGroup as AgendaGroupComponent,
  IAgendaGroupDispatchProps,
  IAgendaGroupOwnProps,
  IAgendaGroupStateProps,
} from '../../components/Calendar/AgendaGroup.tsx';
import { CalendarPopupType } from '../../components/Popups/CalendarPopupType.ts';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { ItemDetails } from '../../types/ItemDetails.type.ts';
import { areDatesSame } from '../../utils/calendarUtils.ts';

const getPopupType = (
  itemDetail: ItemDetails | null,
  events: ReadonlyArray<ICalendarEvent>,
): CalendarPopupType => {
  if (
    !!itemDetail &&
    events.find(
      (event) => event.itemId === itemDetail.id && areDatesSame(itemDetail.date, event.date),
    )
  ) {
    return CalendarPopupType.ItemDetail;
  }

  return CalendarPopupType.None;
};

const mapStateToProps = (
  state: IStore,
  { groupedEvent }: IAgendaGroupOwnProps,
): IAgendaGroupStateProps => ({
  popupType: getPopupType(state.calendarApp.itemDetails, groupedEvent.events),
  isToday: isToday(
    groupedEvent.events[0]?.date ? new Date(groupedEvent.events[0].date) : new Date(),
  ), // events are grouped by the same date and groupedEvent.date property is just a derivation of the particular date in "DD dddd" format, therefore the first element of the collection is used
});

const mapDispatchToProps = (dispatch: Dispatch): IAgendaGroupDispatchProps => ({
  onDismiss: () => dispatch(calendarPopupClosed()),
});

export const AgendaGroup: React.ComponentType<React.PropsWithChildren<IAgendaGroupOwnProps>> =
  connect(mapStateToProps, mapDispatchToProps)(AgendaGroupComponent);
