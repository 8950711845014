import Immutable from 'immutable';
import { IMultipleChoiceOptionData } from '../../../../_shared/models/MultipleChoiceOption.type.ts';
import { IBaseTypeElementData } from '../../shared/models/elements/types/TypeElementData.ts';
import { TypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/TypeElementValidationResult.type.ts';
import {
  ContentTypeSnippet_Creator_InitFinished,
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning,
  ContentTypeSnippet_Editor_ChangeCodename,
  ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename,
  ContentTypeSnippet_Editor_ChangeName,
  ContentTypeSnippet_Editor_ChangeTypeElementCodename,
  ContentTypeSnippet_Editor_FocusFirstInvalidElement,
  ContentTypeSnippet_Editor_HideConfiguration,
  ContentTypeSnippet_Editor_MoveTypeElement,
  ContentTypeSnippet_Editor_MultipleChoiceCreateOption,
  ContentTypeSnippet_Editor_MultipleChoiceDeleteOption,
  ContentTypeSnippet_Editor_MultipleChoiceMoveOption,
  ContentTypeSnippet_Editor_MultipleChoiceOptionDeselected,
  ContentTypeSnippet_Editor_MultipleChoiceOptionDropped,
  ContentTypeSnippet_Editor_MultipleChoiceOptionLabelChanged,
  ContentTypeSnippet_Editor_MultipleChoiceOptionPickedUp,
  ContentTypeSnippet_Editor_MultipleChoiceOptionSelected,
  ContentTypeSnippet_Editor_MultipleChoiceUpdateOption,
  ContentTypeSnippet_Editor_RemoveTypeElement,
  ContentTypeSnippet_Editor_ShowConfiguration,
  ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd,
  ContentTypeSnippet_Editor_TypeElementDropped,
  ContentTypeSnippet_Editor_TypeElementPickedUp,
  ContentTypeSnippet_Editor_TypeElementUpdated,
  ContentTypeSnippet_Editor_TypeElementValidated,
  ContentTypeSnippet_Listing_FilterSearchPhraseChanged,
  ContentTypeSnippet_Listing_RouteEntered,
  ContentTypeSnippet_Listing_SelectedTypeSnippetsUpdated,
} from '../constants/snippetActionTypes.ts';

export const removeTypeElement = (typeElementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_RemoveTypeElement,
    payload: { typeElementId },
  }) as const;

export const typeElementPickedUp = (typeElementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_TypeElementPickedUp,
    payload: { typeElementId },
  }) as const;

export const typeElementDropped = () =>
  ({
    type: ContentTypeSnippet_Editor_TypeElementDropped,
  }) as const;

export const moveTypeElement = (sourceId: Uuid, targetId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_MoveTypeElement,
    payload: {
      sourceId,
      targetId,
    },
  }) as const;

export const createMultipleChoiceOption = (option: IMultipleChoiceOptionData, elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceCreateOption,
    payload: {
      option,
      elementId,
    },
  }) as const;

export const updateMultipleChoiceOption = (option: IMultipleChoiceOptionData, elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceUpdateOption,
    payload: {
      option,
      elementId,
    },
  }) as const;

export const multipleChoiceOptionSelected = (option: IMultipleChoiceOptionData, elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceOptionSelected,
    payload: {
      option,
      elementId,
    },
  }) as const;

export const multipleChoiceOptionDeselected = () =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceOptionDeselected,
  }) as const;

export const multipleChoiceOptionLabelChanged = (label: string) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceOptionLabelChanged,
    payload: { label },
  }) as const;

export const deleteMultipleChoiceOption = (optionId: Uuid, elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceDeleteOption,
    payload: {
      optionId,
      elementId,
    },
  }) as const;

export const multipleChoiceOptionPickedUp = (optionId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceOptionPickedUp,
    payload: { optionId },
  }) as const;

export const multipleChoiceOptionDropped = () =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceOptionDropped,
  }) as const;

export const moveMultipleChoiceOption = (
  draggedOptionId: Uuid,
  targetOptionId: Uuid,
  elementId: Uuid,
) =>
  ({
    type: ContentTypeSnippet_Editor_MultipleChoiceMoveOption,
    payload: {
      draggedOptionId,
      targetOptionId,
      elementId,
    },
  }) as const;

export const newSnippetTypeElementCreatedAtTheEnd = (typeElementData: IBaseTypeElementData) =>
  ({
    type: ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd,
    payload: { typeElementData },
  }) as const;

export const snippetTypeElementValidated = (
  elementId: Uuid,
  validationResult: TypeElementValidationResult,
) =>
  ({
    type: ContentTypeSnippet_Editor_TypeElementValidated,
    payload: {
      elementId,
      validationResult,
    },
  }) as const;

export const typeSnippetElementUpdated = (updatedSnippetTypeElement: IBaseTypeElementData) =>
  ({
    type: ContentTypeSnippet_Editor_TypeElementUpdated,
    payload: { updatedSnippetTypeElement },
  }) as const;

export const changeContentTypeSnippetName = (name: string) =>
  ({
    type: ContentTypeSnippet_Editor_ChangeName,
    payload: { name },
  }) as const;

export const changeContentTypeSnippetCodename = (codename: string) =>
  ({
    type: ContentTypeSnippet_Editor_ChangeCodename,
    payload: { codename },
  }) as const;

export const changeContentTypeSnippetElementCodename = (codename: string, elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_ChangeTypeElementCodename,
    payload: {
      codename,
      elementId,
    },
  }) as const;

export const changeContentTypeSnippetMultipleChoiceOptionCodename = (
  codename: string,
  elementId: Uuid,
  optionId: Uuid,
) =>
  ({
    type: ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename,
    payload: {
      codename,
      elementId,
      optionId,
    },
  }) as const;

export const showTypeElementConfiguration = (elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_ShowConfiguration,
    payload: { elementId },
  }) as const;

export const hideTypeElementConfiguration = (elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_HideConfiguration,
    payload: { elementId },
  }) as const;

export const snippetSetFirstInvalidElement = (elementId: Uuid) =>
  ({
    type: ContentTypeSnippet_Editor_FocusFirstInvalidElement,
    payload: { elementId },
  }) as const;

export const contentTypeSnippetInvalidElementShowWarning = () =>
  ({
    type: ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning,
  }) as const;

export const contentTypeSnippetCreatorInitStarted = () =>
  ({
    type: ContentTypeSnippet_Creator_InitStarted,
  }) as const;

export const contentTypeSnippetCreatorInitFinished = () =>
  ({
    type: ContentTypeSnippet_Creator_InitFinished,
  }) as const;

export const selectedContentTypeSnippetsUpdated = (
  selectedContentTypeSnippetIds: Immutable.Set<Uuid>,
) =>
  ({
    type: ContentTypeSnippet_Listing_SelectedTypeSnippetsUpdated,
    payload: { selectedContentTypeSnippetIds },
  }) as const;

export const contentTypeSnippetListingFilterSearchPhraseChanged = (searchPhrase: string) =>
  ({
    type: ContentTypeSnippet_Listing_FilterSearchPhraseChanged,
    payload: { searchPhrase },
  }) as const;

export const contentTypeSnippetListingRouteEntered = () =>
  ({
    type: ContentTypeSnippet_Listing_RouteEntered,
  }) as const;
