import { ActiveCapabilityType } from '../../../../_shared/models/activeCapability.type.ts';
import { EmptyAsset, IAsset } from '../../../../data/models/assets/Asset.ts';

export const hasPermissionsForAssets = (
  allAssets: Immutable.Map<Uuid, IAsset>,
  assetIds: ReadonlySet<Uuid>,
  capability: ActiveCapabilityType,
): boolean => {
  const assets = [...assetIds].map((id) => allAssets.get(id) ?? EmptyAsset);
  return assets.every((asset) => asset.capabilities.activeCapabilities.includes(capability));
};
