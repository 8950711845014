import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';

export function getSelectedRootItem(state: IStore): IListingContentItem | undefined {
  const {
    data: { listingContentItems },
    relationsApp: { selectedRootId },
  } = state;

  if (!selectedRootId) {
    return undefined;
  }

  return listingContentItems.byId.get(selectedRootId);
}
