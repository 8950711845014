import { EditorState } from 'draft-js';
import { areTextEditorStatesTheSame } from '../../../richText/utils/compare/areTextEditorStatesTheSame.ts';
import { IUrlSlugItemElement } from '../../models/contentItemElements/UrlSlugItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

function areValuesTheSame(editorState1: EditorState, editorState2: EditorState) {
  return (!editorState1 && !editorState2) || areTextEditorStatesTheSame(editorState1, editorState2);
}

export function areUrlSlugsItemElementsTheSame(
  itemElement1: IUrlSlugItemElement,
  itemElement2: IUrlSlugItemElement,
): boolean {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }

  const modesAreTheSame = itemElement1.mode === itemElement2.mode;
  const valuesAreTheSame = areValuesTheSame(itemElement1._editorState, itemElement2._editorState);

  return modesAreTheSame && valuesAreTheSame;
}
