import { History } from 'history';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { isCheckedValidationBuilder } from '../../../../_shared/utils/validation/isChecked.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { SubscriptionFormContext } from '../../shared/components/SubscriptionFormContext.tsx';
import {
  INewSubscriptionFormShape,
  NewSubscriptionForm,
} from '../components/NewSubscriptionForm.tsx';

type Props = {
  readonly children: React.ReactNode;
  readonly onFormSubmit: (values: INewSubscriptionFormShape, history: History) => void;
};

const NewSubscriptionFormContainer: React.FC<Props> = ({ children, onFormSubmit }) => {
  const usersFirstName = useSelector((s) => s.data.user.info.firstName);

  const formProps = useForm<INewSubscriptionFormShape>({
    defaultValues: {
      subscriptionName: `${usersFirstName}'s subscription`,
      acceptTerms: false,
    },
    resolver: createFormValidationResolver(
      {
        subscriptionName: [nonEmptyValidationBuilder('subscription name')],
        acceptTerms: [isCheckedValidationBuilder('You have to accept the Terms of Service.')],
      },
      {},
    ),
  });

  const { handleSubmit } = formProps;

  const history = useHistory();
  const { submitForm, setSubmitFormCallback } = useContext(SubscriptionFormContext);

  useEffect(() => {
    const callback = handleSubmit((values) => onFormSubmit(values, history));

    setSubmitFormCallback(callback);
  }, [handleSubmit, history, onFormSubmit, setSubmitFormCallback]);

  return (
    <NewSubscriptionForm formProps={formProps} onSubmit={submitForm}>
      {children}
    </NewSubscriptionForm>
  );
};

NewSubscriptionFormContainer.displayName = 'NewSubscriptionFormContainer';
export { NewSubscriptionFormContainer as NewSubscriptionForm };
