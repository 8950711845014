import { AiErrorCode, AiErrorCodeMap } from '../types/aiErrors.ts';

export const baseErrorMessageByErrorCode: AiErrorCodeMap = {
  [AiErrorCode.ActionNotSupported]: 'This AI skill is not yet supported.',
  [AiErrorCode.General]:
    'The AI didn’t provide a meaningful response. Try again later. If this keeps happening, let us know via chat.',
  [AiErrorCode.InactivityTimeout]:
    'The connection to the AI got lost. Check your Internet connection and try again. If your connection is fine but this still keeps happening, let us know via chat.',
  [AiErrorCode.InputContentFilter]:
    'The selected text contains content that doesn’t meet our content policy. Select another text and try again.',
  [AiErrorCode.InputTooLong]: 'The text selection is too long. Select less text and try again.',
  [AiErrorCode.InputTooShort]: 'Select more text to get useful results from the AI.',
  [AiErrorCode.InvalidData]:
    'The AI didn’t provide a meaningful response. Try again later. If this keeps happening, let us know via chat.',
  [AiErrorCode.NoResult]:
    'The AI didn’t provide a meaningful response. Try again later. If this keeps happening, let us know via chat.',
  [AiErrorCode.OutputIncomplete]:
    'The AI response is partial and might contain incomplete sentences.',
  [AiErrorCode.ResponseContentFilter]:
    'The AI responded with content that doesn’t meet our content policy. Try again. If this keeps happening, let us know via chat.',
  [AiErrorCode.Unable]:
    'The selected text doesn’t contain enough information for the AI to provide a meaningful response. Select more text, a different part of your text, or rephrase the text.',
};
