import React, { memo } from 'react';
import { Guidelines } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/Guidelines.tsx';
import { ICompiledContentItemElementData } from '../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import { TypeElement } from '../../../../content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { IAssetElementProps } from './AssetElementProps.type.ts';
import { getAssetElementEditComponent } from './assetElementEditComponentCreator.ts';

const AssetElement: React.FC<
  React.PropsWithChildren<IAssetElementProps<ICompiledContentItemElementData, TypeElement>>
> = (props): JSX.Element | null => {
  if (isGuidelinesTypeElement(props.typeElement)) {
    return <Guidelines typeElement={props.typeElement} disabled={props.disabled} />;
  }

  const ElementComponent = getAssetElementEditComponent(props.typeElement.type);

  return <ElementComponent {...props} />;
};

AssetElement.displayName = 'AssetElement';

const AssetElementMemo = memo(AssetElement);
export { AssetElementMemo as AssetElement };
