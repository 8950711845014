import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Typography } from '../../tokens/quarks/typography.ts';

export const menuItemPaddingY = Spacing.S;
export const menuItemPrimaryLabel = Typography.Subheadline;
export const menuItemSecondaryLabel = Typography.LabelSmall;

const menuItemPrimaryLabelLineHeight = Number.parseInt(menuItemPrimaryLabel.lineHeight, 10);

// "Simple" menu item — no leading or trailing elements, no complementary text. Used in SingleSelect component for virtualization.
export const simpleMenuItemHeight = 2 * menuItemPaddingY + menuItemPrimaryLabelLineHeight;
