import { Action } from '../../../../../@types/Action.type.ts';
import {
  Sitemap_CancelNodeEditing,
  Sitemap_ConfirmEditedNode,
  Sitemap_InitSitemap,
  Sitemap_SelectEditedNode,
  Sitemap_StartSaving,
} from '../../constants/sitemapActionTypes.ts';

export const editedNodeId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Sitemap_SelectEditedNode:
      return action.payload.id;

    case Sitemap_CancelNodeEditing: {
      const nodeId = action.payload.nodeId;
      if (state === nodeId) {
        return null;
      }
      return state;
    }

    case Sitemap_ConfirmEditedNode: {
      const node = action.payload.node;
      if (state === node.id) {
        return null;
      }
      return state;
    }

    case Sitemap_InitSitemap:
    case Sitemap_StartSaving:
      return null;

    default:
      return state;
  }
};
