import { ISelectItem } from '@kontent-ai/component-library/Selects';

export enum WorkflowStage {
  AllItems = 'AllItems',
  NewItems = 'NewItems',
  NewLocalizations = 'NewLocalizations',
  UpdatedItems = 'UpdatedItems',
  UpdatedLocalizations = 'UpdatedLocalizations',
}

const workflowStageToLabelMap = {
  [WorkflowStage.AllItems]: 'All content phases',
  [WorkflowStage.NewItems]: 'New content',
  [WorkflowStage.UpdatedItems]: 'Updates to published content',
  [WorkflowStage.NewLocalizations]: 'New localizations',
  [WorkflowStage.UpdatedLocalizations]: 'Updates to localized content',
} as const;

const workflowStageToComplementaryTextMap = {
  [WorkflowStage.AllItems]: 'New items, localizations, and their updates',
  [WorkflowStage.NewItems]: 'From creation to first publication',
  [WorkflowStage.UpdatedItems]: 'From new version to re-publication',
  [WorkflowStage.NewLocalizations]: 'From first translation to publication',
  [WorkflowStage.UpdatedLocalizations]: 'From new version to re-publication',
};

export interface IWorkflowStageSelectOption extends ISelectItem<IWorkflowStageSelectOption> {
  readonly id: WorkflowStage;
  readonly label: string;
  readonly complementaryText: string;
}

export const workflowStages: ReadonlyArray<IWorkflowStageSelectOption> = Object.entries(
  workflowStageToLabelMap,
).map(([id, label]) => ({
  id,
  label,
  complementaryText: workflowStageToComplementaryTextMap[id],
}));
