import {
  BaseColor,
  BorderRadius,
  Spacing,
  colorBorderDefault,
  colorBorderHover,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import { useHover } from '@react-aria/interactions';
import React from 'react';
import styled from 'styled-components';
import { WebSpotlightZIndex } from '../../../constants/WebSpotlightZIndex.ts';
import { WebSpotlightPreviewResizingDirection } from '../../../models/WebSpotlightPreviewResizingDirection.tsx';

const ElementWrapper = styled.div<{ readonly isHovered: boolean }>`
  width: 100%;
  height: ${px(Spacing.L)};
  position: absolute;
  left: 0;
  bottom: ${px(-Spacing.L)};
  border-top: 2px solid;
  cursor: n-resize;
  user-select: none;

  ${(props) =>
    props.isHovered
      ? `
    border-top-color: ${colorBorderHover};
    z-index: ${WebSpotlightZIndex.PreviewIFrame};
  `
      : `
    border-top-color: ${colorBorderDefault};
    z-index: ${WebSpotlightZIndex.InactiveResizeHandle};
  `};
`;

const ResizeButton = styled.button<{ readonly isHovered: boolean }>`
  width: ${px(gridUnit * 10)};
  height: ${px(gridUnit)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  border: 0 transparent;
  border-bottom-left-radius: ${px(BorderRadius.L)};
  border-bottom-right-radius: ${px(BorderRadius.L)};
  cursor: n-resize;

  ${(props) =>
    props.isHovered
      ? `
    background-color: ${colorBorderHover};
    border-radius: ${px(BorderRadius.L)};
    color: ${BaseColor.Gray20};
    height: ${px(gridUnit * 2)};
    top: -50%;
  `
      : `
    background-color: ${colorBorderDefault};
    color: ${BaseColor.Gray80};
  `};
`;

interface IWebSpotlightPreviewVerticalResizeBarProps {
  readonly isBorderHovered: boolean;
  readonly onResizeStart: (event: React.MouseEvent<HTMLElement>) => void;
  readonly resizingDirection: WebSpotlightPreviewResizingDirection;
}

export const WebSpotlightPreviewVerticalResizeBar: React.FC<
  IWebSpotlightPreviewVerticalResizeBarProps
> = ({ isBorderHovered, onResizeStart, resizingDirection }) => {
  const { isHovered, hoverProps } = useHover({});

  const isResizing = resizingDirection !== WebSpotlightPreviewResizingDirection.None;

  const isWrapperHovered = isResizing
    ? [
        WebSpotlightPreviewResizingDirection.Vertical,
        WebSpotlightPreviewResizingDirection.Diagonal,
      ].includes(resizingDirection)
    : isHovered || isBorderHovered;

  const isButtonHovered = isResizing
    ? resizingDirection === WebSpotlightPreviewResizingDirection.Vertical
    : isHovered;

  return (
    <ElementWrapper isHovered={isWrapperHovered} onMouseDown={onResizeStart} {...hoverProps}>
      <ResizeButton isHovered={isButtonHovered}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="5"
          viewBox="0 0 33 5"
          fill="none"
        >
          <line y1="0.595215" x1="0.660156" y2="0.595215" x2="32.6602" stroke="currentcolor" />
          <line y1="3.59521" x1="0.660156" y2="3.59521" x2="32.6602" stroke="currentcolor" />
        </svg>
      </ResizeButton>
    </ElementWrapper>
  );
};

WebSpotlightPreviewVerticalResizeBar.displayName = 'WebSpotlightPreviewVerticalResizeBar';
