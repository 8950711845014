import { Box } from '@kontent-ai/component-library/Box';
import { Callout, CalloutType } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ContentItemVariantWithLanguageName } from '../../utils/getContentItemVariantsWithLanguageNames.tsx';
import { ContentItemVariantLink } from '../ContentItemVariantLink/ContentItemVariantLink.tsx';

interface IContentItemVariantsCalloutProps {
  readonly calloutType: CalloutType;
  readonly children: React.ReactNode;
  readonly headline?: string;
  readonly variants: ReadonlyArray<ContentItemVariantWithLanguageName>;
}

const DisplayedVariantsCount = 2;

export const ContentItemVariantsCallout: React.FC<IContentItemVariantsCalloutProps> = ({
  calloutType,
  children,
  headline,
  variants,
}) => {
  if (variants.length === 0) {
    return null;
  }

  const moreVariantsCount = variants.length - DisplayedVariantsCount;

  return (
    <Callout calloutType={calloutType} headline={headline}>
      {children}
      <Box marginY={Spacing.S}>
        <Stack>
          {variants
            .slice(0, DisplayedVariantsCount)
            .map((variant: ContentItemVariantWithLanguageName) => (
              <ContentItemVariantLink key={variant.id.variantId} variant={variant} />
            ))}
        </Stack>
      </Box>
      {moreVariantsCount > 0 && (
        <p>... and {moreVariantsCount} more. See the language selector to find the whole list.</p>
      )}
    </Callout>
  );
};

ContentItemVariantsCallout.displayName = 'ContentItemVariantsCallout';
