import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ICurrentUserInfo } from '../../../../data/models/user/CurrentUserInfo.ts';
import { IUpdateNameServerModel } from '../../../../repositories/serverModels/AccountServerModels.type.ts';
import { UserProfile_Saved, UserProfile_Saving } from '../../constants/userProfileActionTypes.ts';
import { IUserProfileFormShape } from '../../models/IUserProfileFormShape.type.ts';

interface IDeps {
  readonly accountRepository: {
    readonly updateName: (userId: Uuid, params: IUpdateNameServerModel) => Promise<void>;
  };
  readonly loadUserInfo: () => ThunkPromise<ICurrentUserInfo>;
  readonly loadUserProperties: (userId: Uuid) => ThunkPromise;
}

const showSavingMessage = { type: UserProfile_Saving } as const;
const showSavedMessage = { type: UserProfile_Saved } as const;

export type SubmitUserProfileActionsType = typeof showSavingMessage | typeof showSavedMessage;

export const createSubmitProfileFormAction =
  (deps: IDeps) =>
  (values: IUserProfileFormShape): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { user },
    } = getState();

    dispatch(showSavingMessage);

    await deps.accountRepository.updateName(user.info.userId, values);
    const userInfo = await dispatch(deps.loadUserInfo());
    await dispatch(deps.loadUserProperties(userInfo.userId));
    dispatch(showSavedMessage);
  };
