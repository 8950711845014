import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { documentationLinks } from '../../constants/documentationLinks.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { FormFieldError } from '../../uiComponents/FormAlert/FormFieldError.tsx';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';

interface ICodenameErrorMessageDataProps {
  readonly isCodenameUnique: boolean;
  readonly hasCodenameErrors: boolean;
}

export const CodenameErrorMessage: React.FC<ICodenameErrorMessageDataProps> = ({
  isCodenameUnique,
  hasCodenameErrors,
}) => {
  if (!hasCodenameErrors) {
    return null;
  }

  if (!isCodenameUnique) {
    return <FormFieldError isDisplayed>Provide a unique codename.</FormFieldError>;
  }

  return (
    <FormFieldError isDisplayed>
      Provide a{' '}
      <OutwardLink href={documentationLinks.codenameLimitations}>
        valid codename <Icon iconName={IconName.ArrowRightTopSquare} />
      </OutwardLink>
      .
    </FormFieldError>
  );
};

CodenameErrorMessage.displayName = 'CodenameErrorMessage';
