import React from 'react';
import { IAsset } from '../../../data/models/assets/Asset.ts';

type AssetTileProgressBarProps = {
  readonly asset: IAsset;
};

export const AssetTileProgressBar: React.FC<AssetTileProgressBarProps> = ({ asset }) => {
  return asset._uploading ? (
    <div className="asset-thumbnail__progress-bar">
      <div
        className="asset-thumbnail__progress-bar-line"
        style={{ width: `${asset._uploaded}%` }}
      />
    </div>
  ) : null;
};

AssetTileProgressBar.displayName = 'AssetTileProgressBar';
