import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getEmptyInventoryInfoMessage } from '../../../shared/utils/getEmptyInventoryInfoMessage.ts';
import { queryChanged, queryFilterCleared } from '../../actions/assetLibraryActions.ts';
import { EmptyAssetLibraryMessage } from '../../components/EmptyState/EmptyAssetLibraryMessage.tsx';
import { NoAssetsFoundMessage } from '../../components/EmptyState/NoAssetsFoundMessage.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { AssetLibraryEmptyState } from '../../models/AssetLibraryEmptyStateEnum.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { getAssetLibraryEmptyState } from '../../selectors/getAssetLibraryEmptyState.ts';

export interface IEmptyStateHandlerProps {
  readonly onCreateFolder: (() => void) | null;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
}

export const EmptyStateHandler: React.FC<React.PropsWithChildren<IEmptyStateHandlerProps>> = ({
  children,
  onCreateFolder,
  onUpload,
}) => {
  const emptyState = useSelector(getAssetLibraryEmptyState);

  const dispatch = useDispatch();
  const noAssetsFoundTitle = useSelector((state) =>
    state.assetLibraryApp.openedFolderId !== RootFolderId
      ? 'No Assets found in this folder.'
      : 'No Assets found.',
  );

  switch (emptyState) {
    case AssetLibraryEmptyState.EmptyLibrary:
      return <EmptyAssetLibraryMessage onCreateFolder={onCreateFolder} onUpload={onUpload} />;

    case AssetLibraryEmptyState.NoAssetsFound_FilledSearchPhrase:
    case AssetLibraryEmptyState.NoAssetsFound_FilledSearchPhraseAndFilter:
      return (
        <NoAssetsFoundMessage
          buttonText="Clear your search phrase"
          onButtonClick={() => dispatch(queryChanged({ searchPhrase: '' }))}
          infoMessage={getEmptyInventoryInfoMessage({
            isFilterEmpty: emptyState === AssetLibraryEmptyState.NoAssetsFound_FilledSearchPhrase,
            isSearchPhraseEmpty: false,
          })}
          title={noAssetsFoundTitle}
        />
      );

    case AssetLibraryEmptyState.NoAssetsFound_FilledFilter:
      return (
        <NoAssetsFoundMessage
          buttonText="Clear filters"
          onButtonClick={() => dispatch(queryFilterCleared())}
          infoMessage={getEmptyInventoryInfoMessage({
            isFilterEmpty: false,
            isSearchPhraseEmpty: true,
          })}
          title={noAssetsFoundTitle}
        />
      );

    case AssetLibraryEmptyState.None:
      return <>{children}</>;

    default:
      throw InvariantException(
        `EmptyStateHandler.tsx: Unknown asset library empty state: ${emptyState}`,
      );
  }
};

EmptyStateHandler.displayName = 'EmptyStateHandler';
