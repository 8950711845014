import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { OrderBy } from '../../../../../_shared/models/OrderBy.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { AssetOrderingCode } from '../../utils/assetListingOrderingUtils.ts';
import { assetListingOrderingUpdated } from '../assetLibraryActions.ts';

interface IDeps {
  readonly loadListingAssets: LoadListingAssets;
}

export const createUpdateAssetListingOrderingAction =
  (deps: IDeps) =>
  (orderBy: OrderBy<AssetOrderingCode>): ThunkPromise =>
  async (dispatch) => {
    dispatch(assetListingOrderingUpdated(orderBy));
    await dispatch(
      deps.loadListingAssets({ requestTrigger: AssetsRequestTrigger.Ordering, orderBy }),
    );
  };
