import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { InvalidExactMatchPhraseErrorMessage } from '../constants/fullTextSearch.ts';

type Params = {
  readonly fullTextSearchStatus: FullTextSearchStatus;
  readonly searchPhrase: string;
};

export const validateFullTextSearchPhrase = ({
  fullTextSearchStatus,
  searchPhrase,
}: Params): string | undefined => {
  if (fullTextSearchStatus === FullTextSearchStatus.Unavailable && /^".*"$/.test(searchPhrase)) {
    return InvalidExactMatchPhraseErrorMessage;
  }

  return undefined;
};
