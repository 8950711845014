import { isElement } from '@kontent-ai/DOM';
import { detect } from 'detect-browser';
import React from 'react';
import { useEventListener } from './useEventListener.ts';

const browser = detect();

const isChrome = browser?.name === 'chrome';

function blurActiveElementWithAutoComplete() {
  if (isChrome) {
    const activeElement = document.activeElement;
    if (isElement(activeElement) && activeElement.getAttribute('autocomplete') === 'on') {
      activeElement.blur();
    }
  }
}

/*
 * This fixes a Chrome bug with autocomplete not scrolling when inside a scrollable DIV https://bugs.chromium.org/p/chromium/issues/detail?id=674447
 * Attach to a ref of the scrollable DIV
 * It blurs the input with autocomplete on scroll
 */
export const useFixAutoCompleteOnScroll = (scrollContainerRef: React.RefObject<HTMLElement>) => {
  useEventListener('scroll', blurActiveElementWithAutoComplete, scrollContainerRef.current);
};
