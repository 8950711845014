import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_UserNormalizedRoleWithSettings_Success } from '../../../constants/dataActionTypes.ts';
import {
  INormalizedRolesWithSettings,
  getNormalizedRoleWithSettingsFromServerModel,
} from '../../../models/roles/INormalizedRolesWithSettings.ts';

const initialState: Immutable.Map<Uuid, INormalizedRolesWithSettings> = Immutable.Map();

export function normalizedRolesWithSettingsPerProject(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, INormalizedRolesWithSettings> {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated: {
      const { roles, currentProjectId } = action.payload;
      return state.set(currentProjectId, getNormalizedRoleWithSettingsFromServerModel(roles));
    }

    case Data_UserNormalizedRoleWithSettings_Success: {
      const { roles, projectId } = action.payload.data;
      return state.set(projectId, getNormalizedRoleWithSettingsFromServerModel(roles));
    }

    default:
      return state;
  }
}
