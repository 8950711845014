import { Workflow } from '../../../data/models/workflow/Workflow.ts';

export const allRolesCanDoSomethingInStep = (stepId: Uuid, workflow: Workflow): boolean => {
  if (workflow.scheduledStep.id === stepId) {
    return workflow.scheduledStep.roleIds.size === 0;
  }

  if (workflow.archivedStep.id === stepId) {
    return workflow.archivedStep.roleIds.size === 0;
  }

  if (workflow.publishedStep.id === stepId) {
    return (
      workflow.publishedStep.roleIdsForCreateNew.size === 0 ||
      workflow.publishedStep.roleIdsForUnpublish.size === 0
    );
  }

  const regularStep = workflow.steps.find((step) => step.id === stepId);
  if (regularStep) {
    return regularStep.roleIds.size === 0;
  }

  return false;
};

export const canRoleDoSomethingInStep = (
  roleId: Uuid | null | undefined,
  stepId: Uuid,
  workflow: Workflow,
): boolean => {
  if (!roleId) {
    return false;
  }

  if (allRolesCanDoSomethingInStep(stepId, workflow)) {
    return true;
  }

  if (workflow.scheduledStep.id === stepId) {
    return workflow.scheduledStep.roleIds.has(roleId);
  }

  if (workflow.archivedStep.id === stepId) {
    return workflow.archivedStep.roleIds.has(roleId);
  }

  if (workflow.publishedStep.id === stepId) {
    return (
      workflow.publishedStep.roleIdsForCreateNew.has(roleId) ||
      workflow.publishedStep.roleIdsForUnpublish.has(roleId)
    );
  }

  const regularStep = workflow.steps.find((step) => step.id === stepId);
  if (regularStep) {
    return regularStep.roleIds.has(roleId);
  }

  return false;
};
