import { IItemVariantCountServerModel } from '../../../../repositories/serverModels/ItemVariantCountServerModels.type.ts';

export type ItemVariantCount = {
  readonly activeLanguagesItemVariantCount: number;
};

export const getItemVariantCountFromServerModel = (
  rawItemVariantCountServerModel: IItemVariantCountServerModel,
): ItemVariantCount => {
  if (typeof rawItemVariantCountServerModel !== 'object') {
    throw new Error('Item variant count model is not an object');
  }

  return {
    activeLanguagesItemVariantCount: rawItemVariantCountServerModel.activeLanguagesItemVariantCount,
  };
};
