import { forwardRef, useCallback } from 'react';
import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { setCurrentRelationsRootAsDefault } from '../actions/thunkRelationsActions.ts';
import { getSelectedRootItem } from '../selectors/getSelectedRootItem.ts';

export const ChangeDefaultRootConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const root = useSelector((state: IStore) => getSelectedRootItem(state));
  const dispatch = useDispatch();

  const handleConfirm = useCallback(() => dispatch(setCurrentRelationsRootAsDefault()), []);

  const handleClose = useCallback(() => dispatch(modalDismissed()), []);

  return (
    <ConfirmationDialog
      confirmButtonText="Set as default"
      headerContent={`Set ${root?.item?.name} as default`}
      onConfirm={handleConfirm}
      onClose={handleClose}
      ref={ref}
      {...props}
    >
      <p className="confirmation-dialog__section">
        This will set the content item <strong>{root?.item?.name}</strong> as the default home item
        for <strong>everyone in this project</strong>.
      </p>
      <p className="confirmation-dialog__section">
        Do you want to set <strong>{root?.item?.name}</strong> as the default home item?
      </p>
    </ConfirmationDialog>
  );
});

ChangeDefaultRootConfirmationDialog.displayName = 'ChangeDefaultRootConfirmationDialog';
