import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UnableToRestoreProjectLimitReachedMessage } from '../../../constants/UIConstants.ts';
import { IProjectViewModel } from '../../../selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../../types/projectUiTransitionState.ts';

interface IActivateActionButtonProps {
  readonly project: IProjectViewModel;
  readonly isStatusChanging: boolean;
  readonly onActivate?: () => void;
}

export const ProjectTransitionButton: FC<IActivateActionButtonProps> = ({
  project,
  onActivate,
  isStatusChanging,
}) => {
  const hasCapacityToActivateProject =
    !project.subscriptionProjectsLimit ||
    project.subscriptionProjectsLimit > project.subscriptionProjectsCount;

  return (
    <div className="my-project__button-wrapper">
      <Button
        buttonStyle="secondary"
        buttonDisplay="block"
        disabled={isStatusChanging || !hasCapacityToActivateProject}
        onClick={onActivate}
        tooltipText={
          hasCapacityToActivateProject
            ? undefined
            : UnableToRestoreProjectLimitReachedMessage(
                project.subscriptionProjectsLimit,
                project.subscriptionName,
              )
        }
        tooltipPlacement="bottom"
        {...getDataUiActionAttribute(DataUiAction.Activate)}
      >
        {getProjectTransitionButtonText(project)}
      </Button>
    </div>
  );
};

ProjectTransitionButton.displayName = 'ProjectTransitionButton';

const getProjectTransitionButtonText = (project: IProjectViewModel): string => {
  if (project.uiTransitionState === ProjectUiTransitionState.Activating) {
    return 'Activating';
  }
  if (project.uiTransitionState === ProjectUiTransitionState.Deactivating) {
    return 'Archiving';
  }
  if (project.inactive) {
    return 'Restore project';
  }
  if (project.isBeingDeleted) {
    return 'Deleting project';
  }
  return '';
};
