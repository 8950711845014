import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  IWebhookMessage,
  getWebhookMessageFromServerModel,
} from '../../../../data/models/webhooks/WebhookMessage.ts';
import { IWebhookMessageServerModel } from '../../../../repositories/serverModels/WebhookMessageServerModel.type.ts';
import { Webhooks_WebhookMessageListing_LoadFinished } from '../../constants/webhooksActionTypes.ts';

type WebhookMessagesType = ReadonlyMap<Uuid, ReadonlyArray<IWebhookMessage>>;

export const webhookMessages = (
  state: WebhookMessagesType = new Map<Uuid, ReadonlyArray<IWebhookMessage>>(),
  action: Action,
): WebhookMessagesType => {
  switch (action.type) {
    case Webhooks_WebhookMessageListing_LoadFinished:
      return Collection.add(state, [
        action.payload.webhookId,
        action.payload.webhookMessages.map((webhookMessage: IWebhookMessageServerModel) =>
          getWebhookMessageFromServerModel(webhookMessage),
        ),
      ]);

    default:
      return state;
  }
};
