import { ContentState } from 'draft-js';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { ILoadAssetsAction } from '../../../../../../data/actions/thunks/assets/loadAssets.ts';
import { IContentComponent } from '../../../../models/contentItem/ContentComponent.ts';
import {
  ReferenceType,
  getAssetReferencesInContentState,
  getReferencedContentItemIdsInContentState,
  getUsedContentTypeIdsInContentState,
} from '../../../../utils/itemReferences/itemElementReferencesUtils.ts';
import { IEnsureLoadedContentTypesAction } from './ensureLoadedContentTypes.ts';
import { ILoadListingItemsAction } from './loadListingItems.ts';

export type ILoadRichTextReferencesAction = (
  contents: ReadonlyArray<ContentState>,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface ILoadRichTextReferencesActionDependencies {
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction | null;
  readonly loadAssets: ILoadAssetsAction;
  readonly loadListingItems: ILoadListingItemsAction;
}

export const createLoadRichTextReferences =
  ({
    loadAssets,
    ensureLoadedContentTypes,
    loadListingItems,
  }: ILoadRichTextReferencesActionDependencies): ILoadRichTextReferencesAction =>
  (contents, contentComponents, abortSignal) => {
    return async (dispatch, getState) => {
      // Selected language is not initialized in case the user has no content permissions at all.
      // In such case we can't load neither content items nor assets.
      if (!canUserViewAnyActiveLanguage(getState())) {
        return;
      }

      // Ensure assets/items/content types
      const assetReferences = Immutable.OrderedSet.of(
        ...contents.flatMap((content) =>
          getAssetReferencesInContentState(content, contentComponents).toArray(),
        ),
      ).toArray();

      const itemIds = Immutable.OrderedSet.of(
        ...contents.flatMap((content) =>
          getReferencedContentItemIdsInContentState(
            content,
            ReferenceType.ModularItemsAndTextLinks,
            contentComponents,
          ).toArray(),
        ),
      ).toArray();

      const contentTypeIds = new Set(
        contents.flatMap((content) =>
          getUsedContentTypeIdsInContentState(content, contentComponents).toArray(),
        ),
      );

      const contentTypesPromise = ensureLoadedContentTypes
        ? dispatch(ensureLoadedContentTypes(contentTypeIds, abortSignal))
        : Promise.resolve();

      await Promise.all([
        dispatch(loadAssets(assetReferences, abortSignal)),
        dispatch(loadListingItems(itemIds, abortSignal)),
        contentTypesPromise,
      ]);
    };
  };
