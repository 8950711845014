import { AiErrorCode, AiErrorCodeMap } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';

export const taxonomiesErrorMessageByErrorCode: AiErrorCodeMap = {
  ...baseErrorMessageByErrorCode,
  [AiErrorCode.InputContentFilter]:
    'The AI can’t suggest any terms because the texts in this item don’t meet our content policy.',
  [AiErrorCode.InvalidData]:
    'The AI didn’t find enough textual content in this item to make meaningful suggestions or the taxonomy group is empty.',
  [AiErrorCode.NoResult]:
    'The AI didn’t find any taxonomy terms relevant to the texts found in this item. This may be because the texts in this item cover a different topic than the taxonomy group.',
  [AiErrorCode.Unable]:
    'The AI was unable to provide a meaningful response. This may be because the texts in this item cover a different topic than the taxonomy group.',
};
