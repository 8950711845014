import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_InitFinished,
  ContentTypeSnippet_Editor_InitStarted,
} from '../../constants/snippetActionTypes.ts';

export function isEditedContentTypeSnippetUsedInPublishedItem(
  state = false,
  action: Action,
): boolean {
  switch (action.type) {
    case ContentTypeSnippet_Editor_InitFinished: {
      return action.payload.isEditedContentTypeSnippetUsedInPublishedItem;
    }

    case ContentTypeSnippet_Creator_InitStarted:
    case ContentTypeSnippet_Editor_InitStarted: {
      return false;
    }

    default:
      return state;
  }
}
