import { createGuid } from '@kontent-ai/utils';
import { Pathname } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IUrlSlugItemElement } from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import {
  ContentItemEditing_RegenerateUrlSlug_Finished,
  ContentItemEditing_RegenerateUrlSlug_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { AutoGenerateUrlSlugAction } from './autoGenerateUrlSlug.ts';

const started = (operationId: Uuid) =>
  ({
    type: ContentItemEditing_RegenerateUrlSlug_Started,
    payload: {
      operationId,
    },
  }) as const;

const finished = (operationId: Uuid) =>
  ({
    type: ContentItemEditing_RegenerateUrlSlug_Finished,
    payload: {
      operationId,
    },
  }) as const;

export type RegenerateUrlSlugActionType = ReturnType<typeof started | typeof finished>;

type RegenerateUrlSlugDependencies = {
  readonly autoGenerateUrlSlug: AutoGenerateUrlSlugAction;
};

export type RegenerateUrlSlugAction = (
  params: Readonly<{ elementId: Uuid; pathname: Pathname }>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

export const createRegenerateUrlSlugAction =
  (deps: RegenerateUrlSlugDependencies): RegenerateUrlSlugAction =>
  ({ elementId, pathname }, abortSignal) =>
  async (dispatch, getState) => {
    const { editedContentItemVariantElements, editedContentItemVariant } = getState().contentApp;

    if (!editedContentItemVariant) {
      return;
    }

    const element = getElementById<IUrlSlugItemElement>(
      elementId,
      editedContentItemVariantElements,
    );
    if (!element) {
      return;
    }

    const operationId = createGuid();

    try {
      dispatch(started(operationId));

      await dispatch(deps.autoGenerateUrlSlug({ elementId, pathname }, abortSignal));
    } finally {
      dispatch(finished(operationId));
    }
  };
