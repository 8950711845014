import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { RequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import { shouldRequestNewItems } from '../../../../_shared/utils/scrollGridUtils.ts';
import { MaxLoadedEvents } from '../../../../applications/environmentSettings/auditLog/constants/auditLogProperties.ts';
import { IAuditLogRepository } from '../../../../repositories/interfaces/IAuditLogRepository.type.ts';
import {
  IAuditLogSearchServerRequestModel,
  IAuditLogSearchServerResponseModel,
} from '../../../../repositories/serverModels/IAuditLogServerModels.type.ts';
import {
  Data_AuditEvents_Failed,
  Data_AuditEvents_Started,
  Data_AuditEvents_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly auditLogRepository: Pick<IAuditLogRepository, 'search'>;
  readonly requestTokenFactory: RequestTokenFactory;
}

export const createTokenizedAuditLogEventsLoadingStarted = (cancellationToken: Uuid) => () =>
  ({
    type: Data_AuditEvents_Started,
    payload: {
      cancellationToken,
    },
  }) as const;

const success = (payload: IAuditLogSearchServerResponseModel) =>
  ({
    type: Data_AuditEvents_Success,
    payload,
  }) as const;

const failure = () => ({ type: Data_AuditEvents_Failed }) as const;

export type LoadAuditEventsActionsType = ReturnType<
  ReturnType<typeof createTokenizedAuditLogEventsLoadingStarted> | typeof success | typeof failure
>;

export const createLoadAuditEventsAction =
  (deps: IDeps) =>
  (scrollPositionChanged?: boolean, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      auditLogApp: {
        auditLogFilter: {
          selectedFromDate,
          selectedToDate,
          searchPhrase,
          selectedActionTypes,
          selectedObjectTypes,
        },
        auditLogScrollState,
      },
      data: {
        auditLog: { continuationToken, loadingStatus },
      },
      sharedApp: { currentProjectId },
    } = state;

    if (
      scrollPositionChanged &&
      !shouldRequestNewItems(loadingStatus, continuationToken, auditLogScrollState)
    ) {
      return;
    }

    const searchQuery: IAuditLogSearchServerRequestModel = {
      projectId: currentProjectId,
      from: selectedFromDate,
      to: selectedToDate,
      phrase: searchPhrase,
      actionTypes: selectedActionTypes,
      objectTypes: selectedObjectTypes,
      maxItemCount: MaxLoadedEvents,
      continuationToken: scrollPositionChanged ? continuationToken : null,
    };

    const { tokenizedActionCreator: start, isCurrentTokenValid } =
      deps.requestTokenFactory(getState);
    dispatch(start());

    try {
      const auditLogSearchServerModel = await deps.auditLogRepository.search(
        searchQuery,
        abortSignal,
      );
      if (isCurrentTokenValid()) {
        dispatch(success(auditLogSearchServerModel));
      }
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
