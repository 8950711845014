import { IEditedContentItemThreads } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ICommentThread } from '../../../../../models/comments/CommentThreads.ts';
import { isThreadResolved, isThreadSaved } from '../../../../../utils/commentUtils.ts';
import { selectUnresolvedInlineCommentThreads } from '../../../selectors/inlineCommentSelectors.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

const selectResolvedComments = (
  editedContentItemVariantComments: IEditedContentItemThreads,
): readonly ICommentThread[] =>
  editedContentItemVariantComments.commentThreads.filter(
    (thread) => isThreadSaved(thread) && isThreadResolved(thread),
  );

export const isOpenResolvedCommentsAvailable: ContentItemEditingActionAvailabilityProvider = ({
  contentApp: { editedContentItemVariantComments },
}) => !!selectResolvedComments(editedContentItemVariantComments).length;

export const isOpenInlineCommentsAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
) =>
  !!selectUnresolvedInlineCommentThreads(state).length && !state.contentApp.isCommentsPaneVisible;

export const isCloseInlineCommentsAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
) => !!selectUnresolvedInlineCommentThreads(state).length && state.contentApp.isCommentsPaneVisible;
