import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { clearSelectionFormatting } from '../../../utils/general/editorContentUtils.ts';
import { ClearFormattingPlugin } from '../ClearFormattingPlugin.tsx';

export const editorClearFormattingApi: EditorApiImplementation<ClearFormattingPlugin> = {
  clearSelectionFormatting(api, editorState) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      clearSelectionFormatting,
      'change-block-data',
    );
  },
};
