import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { TrackedEvent } from '../../_shared/constants/trackedEvent.ts';
import { repositoryCollection } from '../../_shared/repositories/repositories.ts';
import { parseQueryParams } from '../../_shared/utils/routing/queryParamsUtils.ts';
import { matchPath } from '../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  EditItemRoute,
  EditItemRouteParams,
} from '../../applications/smartLink/constants/routePaths.ts';

const { eventRepository } = repositoryCollection;

export const useLogIntroductionEvents = (): void => {
  const location = useLocation();
  const [areIntroductionEventsLogged, setAreIntroductionEventsLogged] = useState(false);

  useEffect(() => {
    if (areIntroductionEventsLogged) return;

    const linkId = parseQueryParams<{ linkId: string }>(location.search).linkId;
    const match = matchPath<EditItemRouteParams>(location.pathname, EditItemRoute);
    if (match && !/[sS]*[/]callback/.exec(document.referrer)) {
      eventRepository.trackAnonymousUserEventWithData(TrackedEvent.LinkVisited, {
        'journey-stage': 'landed',
        itemId: match.itemGuid,
        linkId: linkId || '',
        projectId: match.projectId,
        variantId: match.variantGuid,
      });
    }

    setAreIntroductionEventsLogged(true);
  }, [areIntroductionEventsLogged, location]);
};
