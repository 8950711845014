import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { getAvailableCollectionsForContentComponent } from './getAvailableCollectionsForContentComponent.ts';
import { getAvailableCollectionsForContentItem } from './getAvailableCollectionsForContentItem.ts';

export const getAvailableCollections = (
  origin: NewContentItemOrigin,
  state: IStore,
  languageId: Uuid,
): ReadonlyArray<ICollection> =>
  origin === NewContentItemOrigin.ContentComponent
    ? getAvailableCollectionsForContentComponent(state)
    : getAvailableCollectionsForContentItem(languageId, state);
