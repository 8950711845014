import { BaseColor, colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { allCollectionsOption, allLanguagesOption } from '../constants/userListingFilter.ts';
import {
  CollectionFilterOption,
  LanguageFilterOption,
} from '../models/UserListingFilterOption.type.ts';

export const getFilterTagColor = (
  option: CollectionFilterOption | LanguageFilterOption,
): BaseColor | undefined =>
  option === allCollectionsOption || option === allLanguagesOption ? BaseColor.Gray30 : undefined;

export const getFilterLanguageTagColor = (language: LanguageFilterOption): BaseColor | undefined =>
  language.inactive ? colorAlertBackgroundInverse : getFilterTagColor(language);
