import { notNull } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  AgendaItemsListingPopup as AgendaListingPopupComponent,
  IAgendaItemsListingPopupOwnProps,
  IAgendaItemsListingPopupStateProps,
} from '../../components/Popups/AgendaItemsListingPopup.tsx';
import { convertContentItemsToCalendarEvents } from '../../selectors/calendarSelectors.tsx';
import { areDatesSame, compareEventsByDate } from '../../utils/calendarUtils.ts';

export const mapStateToProps = (
  state: IStore,
  ownProps: IAgendaItemsListingPopupOwnProps,
): IAgendaItemsListingPopupStateProps => {
  const items = state.calendarApp.orderedItemIds
    .toArray()
    .map((id: Uuid) => state.data.listingContentItems.byId.get(id) ?? null)
    .filter(notNull);
  const events = convertContentItemsToCalendarEvents(...items)
    .filter((item) => areDatesSame(item.date, ownProps.currentDate))
    .sort(compareEventsByDate);

  return {
    items: events,
  };
};

export const AgendaItemsListingPopup: React.ComponentType<
  React.PropsWithChildren<
    IAgendaItemsListingPopupOwnProps & React.ComponentPropsWithRef<React.ComponentType>
  >
> = connect(mapStateToProps, null, undefined, {
  forwardRef: true,
})(AgendaListingPopupComponent);
