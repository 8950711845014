import { forwardRef } from 'react';
import { FieldError, useFormState } from 'react-hook-form';
import { HookFormProps } from '../../../../../../../../_shared/types/hookFormProps.type.ts';
import { getAvailableAssignedContributors } from '../../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { showFieldError } from '../../../../../../../../_shared/utils/validation/showFieldError.ts';
import { IProjectContributor } from '../../../../../../../../data/models/users/ProjectContributor.ts';
import { IContributorsFormShape } from '../../../../models/IContributorsFormShape.type.ts';
import { ContributorSelector } from '../../../ContributorSelector.tsx';

type Props = {
  readonly activeContributors: ReadonlyArray<IProjectContributor>;
  readonly allAssignedContributors: ReadonlyArray<IProjectContributor>;
  readonly availableContributors: ReadonlyArray<IProjectContributor>;
  readonly currentUserId: UserId;
  readonly disabled?: boolean;
  readonly error: FieldError | undefined;
  readonly formProps: HookFormProps<IContributorsFormShape>;
  readonly onChange: (contributors: ReadonlyArray<IProjectContributor>) => void;
  readonly showAll: boolean;
};

export const ContributorsSelectorField = forwardRef<HTMLDivElement, Props>(
  (
    {
      activeContributors,
      allAssignedContributors,
      availableContributors,
      currentUserId,
      disabled,
      error,
      formProps,
      onChange,
      showAll,
    },
    ref,
  ) => {
    const availableAssigned = getAvailableAssignedContributors(
      allAssignedContributors,
      availableContributors,
    );
    const assignedContributorsIds = (showAll ? allAssignedContributors : availableAssigned).map(
      (contributor) => contributor.userId,
    );

    const handleContributorListChanged = (
      selectedContributors: ReadonlyArray<IProjectContributor>,
    ) => {
      if (showAll) {
        onChange(selectedContributors);

        return;
      }

      const hiddenContributors = allAssignedContributors.filter(
        (assignedContributor: IProjectContributor) =>
          !availableContributors.some(
            (availableContributor: IProjectContributor) =>
              availableContributor.userId === assignedContributor.userId,
          ),
      );

      const selectedContributorsIncludingHiddenOnes =
        selectedContributors.concat(hiddenContributors);

      onChange(selectedContributorsIncludingHiddenOnes);
    };

    const formState = useFormState({ control: formProps.control, name: 'contributors' });
    const shouldShowError = showFieldError(formState, error);

    return (
      <ContributorSelector
        ref={ref}
        autoFocus
        assignedContributors={assignedContributorsIds}
        availableContributors={showAll ? activeContributors : availableContributors}
        currentUserId={currentUserId}
        isDisabled={disabled}
        onContributorListChanged={handleContributorListChanged}
        validationMessage={shouldShowError ? error?.message : undefined}
      />
    );
  },
);

ContributorsSelectorField.displayName = 'ContributorsSelectorField';
