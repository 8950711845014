import { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  UseThunkPromiseStatus,
  useThunkPromise,
} from '../../../../../_shared/hooks/useThunkPromise.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersInfo } from '../../../../../_shared/utils/usersUtils.ts';
import { loadWidgetListing } from '../actions/loadWidgetListing.ts';
import { TaskWidgetDetailTable } from '../components/TaskWidgetDetailTable.tsx';
import { TaskWidgetDetailTableRow } from '../components/TaskWidgetDetailTableRow.tsx';
import { WidgetListingState } from '../types/WidgetListingState.type.ts';

type Props = Readonly<{
  onItemClick?: () => void;
  state: WidgetListingState;
  tasks: ReadonlyArray<Task>;
}>;

export const TaskWidgetDetailTableLiveContainer = ({
  onItemClick,
  state: tasksState,
  tasks,
}: Props) => {
  const contentTypeByIds = useSelector((s) => s.data.contentTypes.byId);
  const listingContentItemById = useSelector((s) => s.data.listingContentItems.byId);
  const usersById = useSelector((s) => s.data.users.usersById);

  const contentItemIds = useMemo(() => tasks.map((task) => task.contentItemId.itemId), [tasks]);
  const [, , loadListingStatus] = useThunkPromise(loadWidgetListing, contentItemIds);
  const dependencyState = getListingDependencyState(loadListingStatus);

  const getContentItem = (itemId: Uuid) => {
    return listingContentItemById.get(itemId);
  };

  const getContentTypeForItemId = (itemId: Uuid) => {
    const typeId = getContentItem(itemId)?.item.typeId;
    return typeId ? contentTypeByIds.get(typeId) : undefined;
  };

  const getContributors = (task: Task) => {
    const userIdentifiers = new Set([...task.assignees].map((userId) => ({ userId })));
    return Array.from(getUsersInfo(userIdentifiers, usersById));
  };

  const getLinkPath = (task: Task) =>
    getContentItemPath(
      window.location.pathname,
      task.contentItemId.itemId,
      task.contentItemId.variantId,
    );

  return (
    <TaskWidgetDetailTable
      state={dependencyState === WidgetListingState.Loaded ? tasksState : dependencyState}
    >
      {tasks.map((task) => (
        <TaskWidgetDetailTableRow
          key={task.id}
          task={task}
          contentItem={getContentItem(task.contentItemId.itemId)}
          contentType={getContentTypeForItemId(task.contentItemId.itemId)}
          contributors={getContributors(task)}
          linkPath={getLinkPath(task)}
          onClick={onItemClick}
        />
      ))}
    </TaskWidgetDetailTable>
  );
};

const getListingDependencyState = (
  loadListingStatus: UseThunkPromiseStatus,
): WidgetListingState => {
  if (loadListingStatus === UseThunkPromiseStatus.Done) return WidgetListingState.Loaded;
  if (loadListingStatus === UseThunkPromiseStatus.Failed) return WidgetListingState.Error;
  return WidgetListingState.Loading;
};
