import React, { useRef } from 'react';
import { Callout, CalloutType } from '../../../../../_shared/components/infos/Callout.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { useUpdatePaperContext } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RevisionItemHeader } from '../../ContentItemEditing/containers/elements/RevisionItemHeader.tsx';
import { RevisionItemElements } from '../containers/RevisionItemElements.tsx';

type Props = {
  readonly showComparisonKey: boolean;
};

export const RevisionContent: React.FC<Props> = ({ showComparisonKey }) => {
  const revisionContentRef = useRef<HTMLDivElement>(null);

  // Do not render item content until paper context is updated because anything using it would rerender after it initializes
  // this mainly causes performance problems in case of many linked items
  const { isEditorPaperSet: canRenderPaperContent } = useUpdatePaperContext(revisionContentRef);

  return (
    <div className="content-item-pane content-item-pane--in-revisions">
      <div ref={revisionContentRef} className="content-item-pane__revision-pane">
        {canRenderPaperContent && (
          <>
            {showComparisonKey && (
              <Callout
                title="Comparison key"
                uiElement={DataUiElement.ComparisonKey}
                iconName={IconName.ICircle}
                type={CalloutType.Info}
                className="content-item-pane__revision-pane-callout"
              >
                <div className="diff-tip diff-tip--added">
                  <span className="diff-tip__content">Added</span>
                </div>
                <div className="diff-tip diff-tip--removed">
                  <span className="diff-tip__content">Removed</span>
                </div>
                <div className="diff-tip diff-tip--changed">
                  <span className="diff-tip__content">Format change</span>
                </div>
              </Callout>
            )}

            <RevisionItemHeader />
            <RevisionItemElements />
          </>
        )}
      </div>
    </div>
  );
};

RevisionContent.displayName = 'RevisionContent';
