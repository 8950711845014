import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { HighlightedTask_Ensured } from '../constants/contentInventoryActionTypes.ts';

type Props = {
  readonly taskId: Uuid;
};

const highlightTask = (pathToItem: string, taskId: Uuid) =>
  ({
    type: HighlightedTask_Ensured,
    payload: {
      pathToItem,
      taskId,
    },
  }) as const;

export type EnsureHighlightedTaskActionTypes = ReturnType<typeof highlightTask>;

export const EnsureHighlightedTask = ({ taskId }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(highlightTask(location.pathname, taskId));
  }, [location.pathname, taskId]);

  return null;
};
