import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IListingContentItemServerModel } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';

interface IDeps {
  readonly contentItemRepository: Pick<IContentItemRepository, 'getListingItemsByIds'>;
}

export type LoadListingContentItemsByIdsAction = (
  variantId: Uuid,
  contentItemIds: ReadonlyArray<Uuid>,
  abortSignal?: AbortSignal,
) => ThunkPromise<ReadonlyArray<IListingContentItemServerModel>>;

export const createLoadListingContentItemsByIdsAction =
  (deps: IDeps): LoadListingContentItemsByIdsAction =>
  (
    variantId: Uuid,
    contentItemIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): ThunkPromise<ReadonlyArray<IListingContentItemServerModel>> =>
  async () => {
    if (contentItemIds.length === 0) {
      return [];
    }

    const response = await deps.contentItemRepository.getListingItemsByIds(
      variantId,
      contentItemIds,
      abortSignal,
    );

    return response.data;
  };
