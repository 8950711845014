import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Route, Switch } from 'react-router';
import { MyProjectsTabs } from '../../applications/projects/components/MyProjectsTabs.tsx';
import { ProjectEditableAppBar } from '../../applications/projects/containers/ProjectEditableAppBar.tsx';
import { SubscriptionSavingStatus } from '../../applications/subscriptionManagement/SubscriptionListing/containers/SubscriptionSavingStatus.tsx';
import { UserProfileSavingStatus } from '../../applications/userProfile/containers/UserProfileSavingStatus.tsx';
import { AppNames } from '../constants/applicationNames.ts';
import {
  ActiveProjectsRoute,
  ArchivedProjectsRoute,
  SubscriptionRoute,
  SubscriptionsRoute,
  UserProfileRoute,
} from '../constants/routePaths.ts';
import { ApplicationBreadcrumbs } from '../containers/ApplicationBreadcrumbs.tsx';
import { HeaderNotificationBar } from '../containers/HeaderNotificationBar.tsx';
import { ProjectSortingDropdown } from '../containers/ProjectSortingDropdown.tsx';
import { EditableAppBar } from './EditableAppBar.tsx';
import { StatusBar } from './StatusBar/StatusBar.tsx';
import { HasProjectOrEnvironmentContext } from './routing/HasProjectOrEnvironmentContext.tsx';

export const Header: React.FC = () => (
  <Switch>
    <Route key={AppNames.Projects} path={[ActiveProjectsRoute, ArchivedProjectsRoute]}>
      <ProjectEditableAppBar
        renderLeftContainer={() => (
          <Box paddingRight={Spacing.S}>
            <ApplicationBreadcrumbs />
          </Box>
        )}
        renderCentralContainer={(ref) => <MyProjectsTabs containerRef={ref} />}
        renderRightContainer={() => <ProjectSortingDropdown />}
      />
    </Route>
    <Route key={AppNames.UserProfile} path={UserProfileRoute}>
      <EditableAppBar
        renderLeftContainer={() => null}
        renderCentralContainer={() => (
          <ApplicationBreadcrumbs renderSavingStatusComponent={() => <UserProfileSavingStatus />} />
        )}
        renderRightContainer={() => null}
      />
    </Route>
    <Route key={AppNames.Subscriptions} path={SubscriptionsRoute} exact>
      <EditableAppBar
        renderLeftContainer={() => null}
        renderCentralContainer={() => <ApplicationBreadcrumbs />}
        renderRightContainer={() => null}
      />
    </Route>
    <Route key={AppNames.SubscriptionManagement} path={SubscriptionRoute}>
      <HasProjectOrEnvironmentContext
        inProjectOrEnvironmentContext={() => (
          <>
            <HeaderNotificationBar />
            <StatusBar />
          </>
        )}
        outOfProjectOrEnvironmentContext={() => (
          <>
            <HeaderNotificationBar />
            <EditableAppBar
              renderLeftContainer={() => null}
              renderCentralContainer={() => (
                <ApplicationBreadcrumbs
                  renderSavingStatusComponent={() => <SubscriptionSavingStatus />}
                />
              )}
              renderRightContainer={() => null}
            />
          </>
        )}
      />
    </Route>
    <Route>
      <HeaderNotificationBar />
      <StatusBar />
    </Route>
  </Switch>
);

Header.displayName = 'Header';
