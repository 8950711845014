import { IStore } from '../../stores/IStore.type.ts';
import { hasCapabilityInLanguageForUser } from './capabilitiesInLanguageUtils.ts';
import { Capability } from './capability.ts';

export const canUserViewContentInDefaultLanguage = (store: IStore): boolean => {
  const {
    sharedApp: { currentProjectId },
    data: {
      user,
      languages: {
        defaultLanguage: { id },
      },
    },
  } = store;

  return hasCapabilityInLanguageForUser(user, currentProjectId, Capability.ViewContent, id);
};
