import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getSortedRolesImmutableMemoized } from '../../../roles/selectors/getSortedRolesList.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesUtils.ts';
import {
  IRoleBuilderOwnProps,
  IRoleBuilderStateProps,
  RoleBuilder as RoleBuilderComponent,
} from '../../components/roleBuilder/RoleBuilder.tsx';
import {
  createDisabledAddButtonTooltip,
  createDisabledLanguageDropDownTooltip,
} from '../../selectors/disabledTooltips.ts';
import { getBasicRoleBuilderErrorMessage } from '../../selectors/getBasicRoleBuilderErrorMessage.ts';
import { getRemainingRoles } from '../../utils/getRemainingRoles.ts';

const mapStateToProps = (state: IStore, ownProps: IRoleBuilderOwnProps): IRoleBuilderStateProps => {
  const {
    data: { languages, roles },
  } = state;

  const { userRoles } = ownProps;

  const allRoles = getSortedRolesImmutableMemoized(roles.rolesById);

  const areLanguageRolesEnabled = areLanguageRolesEnabledForCurrentProject(state);
  const errorMessage = getBasicRoleBuilderErrorMessage(userRoles, areLanguageRolesEnabled);

  const disabledLanguageDropDownTooltip = createDisabledLanguageDropDownTooltip({
    areLanguageRolesEnabled,
    isRoleBuilderValid: !errorMessage,
    languages,
  });

  return {
    areLanguageRolesEnabled,
    disabledAddTooltip: createDisabledAddButtonTooltip(userRoles, allRoles),
    disabledLanguageDropDownTooltip,
    remainingRoles: getRemainingRoles(userRoles, allRoles),
  };
};

export const RoleBuilder: React.ComponentType<IRoleBuilderOwnProps> =
  connect(mapStateToProps)(RoleBuilderComponent);
