import { Box } from '@kontent-ai/component-library/Box';
import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps, PropsWithChildren } from 'react';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';

type FeatureDeactivationDialogProps = {
  readonly isDeactivating: boolean;
  readonly onDeactivate?: () => void;
} & Omit<ComponentProps<typeof ModalDialog>, 'isDismissable' | 'primaryAction'>;

export const FeatureDeactivationDialog: React.FC<
  PropsWithChildren<FeatureDeactivationDialogProps>
> = ({ children, isDeactivating, onDeactivate, ...restProps }) => {
  return (
    <ModalDialog
      primaryAction={{
        text: isDeactivating ? 'Deactivating' : 'Deactivate',
        destructive: true,
        destructiveIcon: Icons.Drawers,
        disabled: isDeactivating,
        onClick: onDeactivate,
        iconAfter: isDeactivating ? AnimatedProgressIcon : undefined,
      }}
      isDismissable={!isDeactivating}
      {...restProps}
    >
      <Box maxWidth={800}>{children}</Box>
    </ModalDialog>
  );
};
