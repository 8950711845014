import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted,
  ContentItemEditing_ItemLiveUsers_Changed,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlySet<UserId> = new Set();

export function itemLiveUserIds(
  state: ReadonlySet<UserId> = initialState,
  action: Action,
): ReadonlySet<UserId> {
  switch (action.type) {
    case ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted: {
      return initialState;
    }

    case ContentItemEditing_ItemLiveUsers_Changed: {
      const userIds = action.payload.liveUserIds;

      // Keep previous order of live users, new ids go to the end
      return Collection.addMany(new Set(Collection.intersect([...state], userIds)), [...userIds]);
    }

    default:
      return state;
  }
}
