import React from 'react';
import { ContributorsSectionDescription } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/components/Elements/ContributorsSectionDescription.tsx';

type Props = {
  readonly onChange: (showAll: boolean) => void;
  readonly showAll: boolean;
  readonly workflowStepName: string;
};

export const ContributorsSectionDescriptionField: React.FC<Props> = ({
  onChange,
  showAll,
  workflowStepName,
}) => (
  <ContributorsSectionDescription
    onShowAllContributors={() => onChange(!showAll)}
    onShowAvailableContributors={() => onChange(!showAll)}
    showAllContributors={showAll}
    workflowStepName={workflowStepName}
  />
);

ContributorsSectionDescriptionField.displayName = 'ContributorsSectionDescriptionField';
