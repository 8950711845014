import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';

export const isElementsCompletenessError = (error: unknown): boolean => {
  const apiError = tryParseApiError(error);

  return (
    !!apiError &&
    (apiError.code === ServerApiErrorCode.CannotPublishIncompleteVariant ||
      apiError.code === ServerApiErrorCode.CannotScheduleIncompleteVariant)
  );
};

export const isNonLocalizableElementPublishError = (error: unknown): boolean => {
  const apiError = tryParseApiError(error);

  return (
    !!apiError &&
    (apiError.code === ServerApiErrorCode.CannotPublishVariantWithNonLocalizableElements ||
      apiError.code === ServerApiErrorCode.CannotScheduleVariantWithNonLocalizableElements)
  );
};
