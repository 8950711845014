import { Action } from '../../../../../../@types/Action.type.ts';
import {
  UserDetail_Closed,
  UserDetail_UpdateCollectionGroups_Failed,
  UserDetail_UpdateCollectionGroups_Finished,
  UserDetail_UpdateCollectionGroups_Started,
  User_RevokeInvite_Failed,
  User_RevokeInvite_Finished,
  User_RevokeInvite_Started,
} from '../../../constants/usersActionTypes.ts';

export const isSaving = (state = false, action: Action): boolean => {
  switch (action.type) {
    case UserDetail_UpdateCollectionGroups_Started:
    case User_RevokeInvite_Started:
      return true;

    case UserDetail_Closed:
    case UserDetail_UpdateCollectionGroups_Failed:
    case UserDetail_UpdateCollectionGroups_Finished:
    case User_RevokeInvite_Finished:
    case User_RevokeInvite_Failed:
      return false;

    default:
      return state;
  }
};
