import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import { OperationStatus } from '../../../../../_shared/models/OperationStatus.ts';
import {
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getMessageFromOperationStatus } from '../../../utils/savingStatusUtils.ts';
import { hasWorkflowEditorUnsavedChanges } from '../../../workflow/utils/hasWorkflowEditorUnsavedChanges.ts';

const getSavingStatusMessage = (state: IStore): StatusMessage => {
  const {
    projectsApp,
    workflowsApp: {
      editorUi: { editedWorkflowStepId, isEditedWorkflowStepNew },
    },
  } = state;

  const hasUnsavedChanges = hasWorkflowEditorUnsavedChanges(state);

  const isStepNewOrUpdated =
    (isEditedWorkflowStepNew || !!editedWorkflowStepId || hasUnsavedChanges) &&
    projectsApp.savingStatus.workflow.status !== OperationStatus.Pending;
  if (isStepNewOrUpdated) {
    return UnsavedStatusMessage;
  }

  return getMessageFromOperationStatus(projectsApp.savingStatus.workflow.status);
};

export const WorkflowSavingStatus: React.ComponentType =
  getConnectedStatusMessage(getSavingStatusMessage);
