import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../_shared/utils/permissions/capabilityUtils.ts';
import {
  countAvailableUserSlots,
  getMaxActiveUsers,
  getNumberOfUsersInActivation,
  getSubscriptionUsage,
  hasSubscriptionAvailableUserSlots,
  isUserCountedInUsage,
} from '../../../../_shared/utils/subscriptionUsageUtils.ts';
import { isEmail } from '../../../../_shared/utils/validation/isEmail.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { getCurrentProjectSubscription } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  duplicateInvitation,
  invalidInvitationsFieldError,
  invitingUsersAboveSubscriptionLimit,
  usersAlreadyOnTheProject,
} from '../constants/errorMessages.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';
import { findCollidingContributorEmails } from '../utils/findCollidingContributorEmails.ts';
import { findDuplicateInvitations } from '../utils/findInvitationDuplicates.ts';
import { getNewEmailsCountedInSubscriptionLimitsSelector } from '../utils/getNewEmailsCountedInSubscriptionLimits.ts';
import { hasUserDetailUnsavedChanges } from './hasUserDetailUnsavedChanges.ts';
import { hasInviteAtLeastOneUser } from './inviteModalValidators.ts';

export const createDisabledUserDetailTooltip = (
  state: IStore,
  contributor: IProjectContributor,
): string | undefined => {
  const {
    data: { user },
    usersApp: { userDetail },
  } = state;
  const isCurrentUser = user.info.userId === contributor.userId;
  const isSubscriptionAdmin = contributor.isAdminOnProject;
  const isActivationInProgress = contributor.isDeactivating || contributor.isActivating;
  const canManageUsers = currentUserHasCapabilities(state, Capability.ManageUsers);

  if (!canManageUsers) {
    return 'Your role prevents you from managing users.';
  }
  if (isCurrentUser) {
    return 'You can’t change your own role.';
  }
  if (isSubscriptionAdmin) {
    return 'You can’t change the role of a subscription admin.';
  }
  if (isActivationInProgress) {
    return 'Activation is in progress, hold on a moment.';
  }
  if (userDetail.isSaving) {
    return 'Saving is in progress, hold on a moment.';
  }
  return undefined;
};

const countCurrentSubscriptionAvailableUserSlots = (state: IStore) =>
  countAvailableUserSlots(
    getSubscriptionUsage(
      state.data.subscriptions.subscriptionUsages,
      getCurrentProjectSubscription(state).subscriptionId,
    ),
    state.data.plans.byId,
    getNumberOfUsersInActivation(state),
  );

export const getCurrentSubscriptionMaxActiveUsers = (state: IStore): number =>
  getMaxActiveUsers(
    getSubscriptionUsage(
      state.data.subscriptions.subscriptionUsages,
      getCurrentProjectSubscription(state).subscriptionId,
    ),
    state.data.plans.byId,
  );

export const getNumberOfUsersAboveSubscriptionLimit = (state: IStore): number => {
  const newInvitationsCount = getNewEmailsCountedInSubscriptionLimitsSelector(state).length;
  return newInvitationsCount - countCurrentSubscriptionAvailableUserSlots(state);
};

export const createDisabledInviteTooltip = (state: IStore): string | undefined => {
  if (!hasInviteAtLeastOneUser(state)) {
    return 'Specify at least one user to invite them.';
  }

  const inputValidationMessages = createInvitationEmailInputValidationMessages(state);

  if (inputValidationMessages.length > 0) {
    return inputValidationMessages[0];
  }
  if (state.usersApp.userInvitation.isSaving) {
    return 'Inviting is in progress, hold on a moment.';
  }

  return undefined;
};

const createInvitationEmailInputValidationMessagesSelector = memoize.maxOne(
  (
    projectContributors: ReadonlyMap<Uuid, IProjectContributor>,
    invitations: Immutable.List<Invitation>,
    numberOfNewUsersAboveSubscriptionLimit: number,
    numberOfMaxActiveUsers: number,
    triedInviteWithNonEmptyText: boolean,
  ) => {
    const invalidInvitationsErrorMessage =
      triedInviteWithNonEmptyText || invitations.some((i: Invitation) => !isEmail(i.email))
        ? invalidInvitationsFieldError
        : null;

    const collidingContributorErrorMessage =
      findCollidingContributorEmails(projectContributors, invitations).size === 0
        ? null
        : usersAlreadyOnTheProject;

    const subscriptionLimitReachedErrorMessage =
      numberOfNewUsersAboveSubscriptionLimit > 0
        ? invitingUsersAboveSubscriptionLimit(
            numberOfNewUsersAboveSubscriptionLimit,
            numberOfMaxActiveUsers,
          )
        : null;

    const duplicateInvitationErrorMessage =
      findDuplicateInvitations(invitations).size === 0 ? null : duplicateInvitation;

    return [
      invalidInvitationsErrorMessage,
      collidingContributorErrorMessage,
      subscriptionLimitReachedErrorMessage,
      duplicateInvitationErrorMessage,
    ].filter((m): m is string => !!m);
  },
);

export const createInvitationEmailInputValidationMessages = (
  state: IStore,
  triedInviteWithNonEmptyText: boolean = false,
): ReadonlyArray<string> => {
  const {
    projectContributors,
    userInvitation: { invitations },
  } = state.usersApp;

  const numberOfNewUsersAboveSubscriptionLimit = getNumberOfUsersAboveSubscriptionLimit(state);
  const numberOfMaxActiveUsers = getCurrentSubscriptionMaxActiveUsers(state);

  return createInvitationEmailInputValidationMessagesSelector(
    projectContributors,
    invitations,
    numberOfNewUsersAboveSubscriptionLimit,
    numberOfMaxActiveUsers,
    triedInviteWithNonEmptyText,
  );
};

const hasCurrentSubscriptionAvailableUserSlots = (state: IStore) =>
  hasSubscriptionAvailableUserSlots(
    state.data.subscriptions.subscriptionUsages,
    getCurrentProjectSubscription(state).subscriptionId,
    state.data.plans.byId,
    getNumberOfUsersInActivation(state),
  );

export const createDisabledUserDetailActivationTooltip = (
  state: IStore,
  user: IProjectContributor,
) => {
  const {
    usersApp: { userDetail },
  } = state;
  if (hasUserDetailUnsavedChanges(state, user)) {
    return 'Save your changes to do this.';
  }
  if (
    user.inactive &&
    !isUserCountedInUsage(state, user.userId) &&
    !hasCurrentSubscriptionAvailableUserSlots(state)
  ) {
    return 'You cannot invite any more users.';
  }
  if (userDetail.isSaving) {
    return 'Saving is in progress, hold on a moment.';
  }
  return undefined;
};
