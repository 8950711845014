import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { IRichTextTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import { RichTextDiff } from '../../../../richText/editors/richText/RichTextDiff.tsx';
import { IRichTextItemElement } from '../../../models/contentItemElements/RichTextItemElement.ts';
import { setRichTextElementClipboard } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { useItemElementReference } from '../../ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../Revisions/hooks/useRenderPlaceholderText.ts';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { NestableExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

export type ExpandedRichTextElementProps = NestableExpandedItemElementDataProps<
  IRichTextTypeElement,
  IRichTextItemElement
>;

export const ExpandedRichTextElement: React.FC<ExpandedRichTextElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => {
  const element = useItemElementReference(typeElement);
  const value = elementData._editorState;
  const originalValue = originalElementData?._editorState.getCurrentContent();
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.StringElement,
    originalElementData,
    typeElement,
  );

  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) => {
      if (element) {
        dispatch(setRichTextElementClipboard(e, editorState, element));
      }
    },
    [element],
  );

  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      typeElement={typeElement}
    >
      <RichTextDiff
        className="rte--in-content-item-element"
        editorState={value}
        originalContent={originalValue}
        placeholder={placeholderText ?? undefined}
        setRichTextClipboard={onSetRichTextClipboard}
        spellCheck
      />
    </ExpandedItemElement>
  );
};

ExpandedRichTextElement.displayName = 'ExpandedRichTextElement';
