import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { PlanLimitationWarning } from '../../../../_shared/containers/infos/PlanLimitationWarning.tsx';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { ProjectSettingsAppNames } from '../../root/constants/ProjectSettingsAppNames.ts';
import { CreateEnvironmentDialog } from '../containers/CreateEnvironmentDialog.tsx';
import { DeleteEnvironmentDialog } from '../containers/DeleteEnvironmentDialog.tsx';
import { EnvironmentTile } from '../containers/EnvironmentTile.tsx';
import { SwapEnvironmentDialog } from '../containers/SwapEnvironmentDialog.tsx';
import { EnvironmentListingModel } from '../selectors/getEnvironmentsForListing.ts';
import { EnvironmentPlaceholder } from './EnvironmentPlaceholder.tsx';
import { environmentTileWidth } from './EnvironmentTile.tsx';

const useIsCustomAssetDomainSet = (
  masterEnvironmentId: Uuid,
  environments: EnvironmentListingModel,
): boolean => {
  const isProductionAvailable = isUuid(environments.productionEnvironment?.environmentId);

  const [isCustomAssetDomainSet, setIsCustomAssetDomainSet] = useState(false);

  useEffect(() => {
    if (!isProductionAvailable) {
      setIsCustomAssetDomainSet(false);
      return;
    }

    const { cancel } = makeCancellablePromise(() =>
      repositoryCollection.projectRepository.getCustomAssetDomainStatus(masterEnvironmentId),
    )
      .then((status) => setIsCustomAssetDomainSet(status.enabled))
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [masterEnvironmentId, isProductionAvailable]);

  return isCustomAssetDomainSet;
};

type Props = {
  readonly environments: EnvironmentListingModel;
  readonly isEnvironmentsFeatureInPlan: boolean;
  readonly masterEnvironmentId: Uuid;
  readonly defaultCloneFromId: Uuid | null;
  readonly hasAnyEnvironmentToCloneFrom: boolean;
  readonly planLimitReached: boolean;
  readonly subscriptionId?: Uuid;
  readonly onCreateEnvironment: (cloneFromId: Uuid, isNewEnvironment: boolean) => void;
  readonly onDeleteEnvironment: (environmentId: Uuid) => void;
  readonly onSwapEnvironment: (environmentId: Uuid) => void;
};

const EnvironmentListHeadline = styled.h2`
  color: ${colorTextLowEmphasis};
  ${Typography.SubheadlineLarge};
`;

export const EnvironmentListing = ({
  environments,
  defaultCloneFromId,
  hasAnyEnvironmentToCloneFrom,
  isEnvironmentsFeatureInPlan,
  masterEnvironmentId,
  onCreateEnvironment,
  onDeleteEnvironment,
  onSwapEnvironment,
  planLimitReached,
  subscriptionId,
}: Props) => {
  const isCustomAssetDomainSet = useIsCustomAssetDomainSet(masterEnvironmentId, environments);

  return (
    <div {...getDataUiAppNameAttribute(DataUiAppName.Environments)}>
      <Stack spacing={Spacing.XL}>
        <HtmlSettingsPageTitle settingsAppName={ProjectSettingsAppNames.Environments} />
        <PageTitle>{ProjectSettingsAppNames.Environments}</PageTitle>
        <Stack spacing={Spacing.XXL}>
          {!!environments.productionEnvironment && (
            <Stack spacing={Spacing.L}>
              <EnvironmentListHeadline>Production environment</EnvironmentListHeadline>
              <Row spacing={Spacing.XL} alignX="start">
                <Column key={environments.productionEnvironment.environmentId} width="fit-content">
                  <EnvironmentTile
                    environment={environments.productionEnvironment}
                    onCreateEnvironment={() =>
                      onCreateEnvironment(
                        environments.productionEnvironment?.environmentId ?? '',
                        false,
                      )
                    }
                    onDeleteEnvironment={() =>
                      onDeleteEnvironment(environments.productionEnvironment?.environmentId ?? '')
                    }
                    onSwapEnvironment={() =>
                      onSwapEnvironment(environments.productionEnvironment?.environmentId ?? '')
                    }
                    planLimitReached={planLimitReached}
                    subscriptionId={subscriptionId}
                  />
                </Column>
                <Column
                  flexFactor={1}
                  maxWidth={2 * (environmentTileWidth + Spacing.XL)}
                  minWidth={environmentTileWidth + Spacing.XL}
                >
                  <Stack spacing={Spacing.XL}>
                    {!isEnvironmentsFeatureInPlan && (
                      <PlanLimitationWarning
                        showSimplified
                        introMessage="This feature is not available in your plan."
                      />
                    )}
                    <Callout
                      calloutType="quickTip"
                      headline="Safely make any changes before going live"
                    >
                      <Stack spacing={Spacing.XL}>
                        <div>
                          <p>
                            Clone your production environment to develop and test your changes
                            safely. Once you’re done, mark your testing environment as production.
                          </p>
                          <p>
                            <strong>Don’t make any changes to the production</strong> while working
                            in the non-production environment.
                          </p>
                        </div>
                        <p>
                          Learn more about{' '}
                          <OutwardLink href={documentationLinks.environmentsSwap}>
                            swapping environments
                          </OutwardLink>
                          .
                        </p>
                      </Stack>
                    </Callout>
                  </Stack>
                </Column>
              </Row>
            </Stack>
          )}
          <Stack spacing={Spacing.L}>
            <EnvironmentListHeadline>Non-production environments</EnvironmentListHeadline>
            <Row
              spacing={Spacing.XL}
              {...getDataUiCollectionAttribute(DataUiCollection.Environments)}
            >
              {environments.nonProductionEnvironments?.map((environment) => (
                <Column key={environment.environmentId} width="content">
                  <EnvironmentTile
                    environment={environment}
                    onCreateEnvironment={() =>
                      onCreateEnvironment(environment.environmentId, false)
                    }
                    onDeleteEnvironment={() => onDeleteEnvironment(environment.environmentId)}
                    onSwapEnvironment={() => onSwapEnvironment(environment.environmentId)}
                    planLimitReached={planLimitReached}
                    subscriptionId={subscriptionId}
                  />
                </Column>
              ))}
              <Column>
                <EnvironmentPlaceholder
                  onCreateEnvironment={() =>
                    defaultCloneFromId && onCreateEnvironment(defaultCloneFromId, true)
                  }
                  isDisabled={planLimitReached || !hasAnyEnvironmentToCloneFrom}
                />
              </Column>
            </Row>
          </Stack>
        </Stack>
      </Stack>
      <CreateEnvironmentDialog isCustomAssetDomainSet={isCustomAssetDomainSet} />
      <DeleteEnvironmentDialog />
      <SwapEnvironmentDialog isCustomAssetDomainSet={isCustomAssetDomainSet} />
    </div>
  );
};
