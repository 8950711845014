import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { shouldPreserveScrollPosition } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { Data_AssetListing_Success } from '../../../../../data/constants/dataActionTypes.ts';
import {
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_Asset_ReplaceStarted,
  AssetLibrary_Assets_CreateAssets,
  AssetLibrary_OrderBy_Changed,
} from '../../constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import { AssetOrderingCode } from '../../utils/assetListingOrderingUtils.ts';

const ByAll: ReadonlySet<AssetOrderingCode> = new Set(Object.values(AssetOrderingCode));
const ByNone: ReadonlySet<AssetOrderingCode> = new Set();

export const potentiallyUnsortedBy = (
  state: ReadonlySet<AssetOrderingCode> = ByNone,
  action: Action,
): ReadonlySet<AssetOrderingCode> => {
  switch (action.type) {
    case AssetLibrary_Asset_ReplaceStarted:
    case AssetLibrary_Assets_CreateAssets:
      return ByAll;

    case AssetLibrary_AssetEditor_SaveFinished: {
      const { asset, originalAsset } = action.payload;

      if (asset.title !== originalAsset?.title) {
        return Collection.add(state, AssetOrderingCode.Name);
      }

      return state;
    }

    case Data_AssetListing_Success: {
      return state !== ByNone && shouldPreserveScrollPosition(action.payload.requestTrigger)
        ? state
        : ByNone;
    }

    case AssetLibrary_OrderBy_Changed:
    case AssetSelector_Opened:
      return ByNone;

    default:
      return state;
  }
};
