import { Action } from '../../../../../@types/Action.type.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import {
  Role_Delete_Finished,
  Role_Restore_Finished,
  Role_Restore_Started,
  Roles_Listing_Left,
} from '../../constants/rolesActionTypes.ts';
import {
  IRoleListingOperationStatus,
  RoleListingOperationType,
} from '../../models/RoleListingOperation.ts';

const initialState: IRoleListingOperationStatus = {
  message: 'Roles in this project',
  messageStyle: StatusMessageStyle.Headline,
  operationType: RoleListingOperationType.NoOperation,
  affectedRole: null,
};

export const operationStatus = (
  state: IRoleListingOperationStatus = initialState,
  action: Action,
): IRoleListingOperationStatus => {
  switch (action.type) {
    case Roles_Listing_Left:
      return initialState;

    case Role_Restore_Finished: {
      return {
        message: 'Role restored',
        messageStyle: StatusMessageStyle.Updated,
        operationType: RoleListingOperationType.Restored,
        affectedRole: null,
      };
    }

    case Role_Restore_Started: {
      return {
        message: 'Undoing',
        messageStyle: StatusMessageStyle.Progress,
        operationType: RoleListingOperationType.Deleting,
        affectedRole: null,
      };
    }

    case Role_Delete_Finished: {
      return {
        message: 'Role deleted',
        messageStyle: StatusMessageStyle.Deleted,
        operationType: RoleListingOperationType.Deleted,
        affectedRole: action.payload.role,
      };
    }

    default:
      return state;
  }
};
