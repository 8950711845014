import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { NoteSection as NoteSectionComponent } from '../../../components/details/Note/NoteSection.tsx';
import { SectionType } from '../../../components/sidebar/sectionTypes/SectionType.tsx';
import { getNoteState } from '../../selectors/getNoteState.ts';

export const NoteSection = () => {
  const { canEdit, isHidden, note } = useSelector(getNoteState);

  const sectionItemType = canEdit && note ? SectionType.Clickable : SectionType.NonClickable;

  return isHidden ? null : <NoteSectionComponent sectionType={sectionItemType} />;
};
