import { assert } from '@kontent-ai/utils';
import { IContentManagementApiStatusServerModel } from '../../../repositories/serverModels/ContentManagementApiStatusServerModel.type.ts';
import { ISecuredDeliveryApiStatusServerModel } from '../../../repositories/serverModels/deliveryApiServerModels.type.ts';

export const getCmApiIsEnabledFromServerModel = (
  serverModel: IContentManagementApiStatusServerModel,
): boolean => {
  assert(!!serverModel, () => 'Content Management api status server model is falsy');

  return serverModel.isEnabled;
};

export const getSecuredDeliveryApiIsEnabledFromServerModel = (
  serverModel: ISecuredDeliveryApiStatusServerModel,
): boolean => {
  assert(!!serverModel, () => 'Secured Delivery api status server model is falsy');

  return serverModel.isEnabled;
};
