import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import { useMatchMedia } from '@kontent-ai/hooks';
import { Globals as ReactSpringGlobals } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { CustomEventName } from '../utils/events/KontentEventMap.ts';
import { useEventListener } from './useEventListener.ts';

/**
 * Enables or disables animations globally.
 * Reads user animation preference from OS level using media query and from browser level using clientConfig.
 * ClientConfig overrides media query.
 */
export const useAnimationsAccessibility = (): void => {
  const systemPrefersReducedMotion = useMatchMedia('(prefers-reduced-motion: reduce)');
  const getPrefersReducedMotion = () =>
    self._clientConfig.prefersReducedMotion ?? systemPrefersReducedMotion;
  const [reduceMotion, setReduceMotion] = useState(getPrefersReducedMotion());
  useEventListener(
    CustomEventName.clientConfigChanged,
    () => setReduceMotion(getPrefersReducedMotion()),
    self,
  );

  useEffect(() => {
    ReactSpringGlobals.assign({ skipAnimation: reduceMotion });
    ComponentLibraryGlobals.assign({ skipAnimation: reduceMotion });
  }, [reduceMotion]);
};
