import { Action } from '../../../../../@types/Action.type.ts';
import {
  AssetType_Editor_SavingFailed,
  AssetType_Editor_SavingFinished,
} from '../../../assetTypes/constants/assetTypeActionTypes.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Creator_CreationStarted,
  ContentType_Editor_SavingFinished,
} from '../../../contentTypes/constants/contentTypesActionTypes.ts';
import { ContentModels_TypeEditor_SavingStarted } from '../../../shared/constants/sharedContentModelsActionTypes.ts';

export const typeIsBeingSaved = (state = false, action: Action): boolean => {
  switch (action.type) {
    case ContentType_Creator_CreationStarted:
    case ContentModels_TypeEditor_SavingStarted: {
      return true;
    }

    case ContentType_Creator_CreationFinished:
    case ContentType_Editor_SavingFinished:
    case AssetType_Editor_SavingFinished:
    case AssetType_Editor_SavingFailed: {
      return false;
    }

    default:
      return state;
  }
};
