import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  AssetLibrary_Assets_CreateAssets,
  AssetLibrary_Assets_RemoveFailedAsset,
  AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers,
  AssetLibrary_Assets_UploadFailed,
  AssetLibrary_Assets_UploadFinished,
  AssetLibrary_Folders_Opened,
} from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../../../applications/contentInventory/assets/features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import { FileWithThumbnail } from '../../../../applications/contentInventory/assets/models/FileWithThumbnail.type.ts';
import { IAsset } from '../../../models/assets/Asset.ts';

const initialState = Immutable.Map<Uuid, Uuid | null>();

export function uploadingAssets(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, Uuid | null> {
  switch (action.type) {
    case AssetLibrary_Assets_CreateAssets: {
      const files = action.payload.files;
      if (!files) {
        return state;
      }

      return Array.from(files).reduce(
        (map: Immutable.Map<Uuid, Uuid | null>, [assetId]: [Uuid, FileWithThumbnail]) => {
          return map.set(assetId, null);
        },
        state,
      );
    }

    case AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers: {
      return state
        .filter((_, key) => !action.payload.oldAssetIds.find((id: Uuid) => id === key))
        .toMap();
    }

    case AssetLibrary_Assets_UploadFailed:
    case AssetLibrary_Assets_RemoveFailedAsset: {
      const assetId = action.payload.oldAssetId;
      const asset = state.get(assetId);
      if (!asset) {
        return state;
      }

      return state.remove(assetId);
    }

    case AssetLibrary_Assets_UploadFinished: {
      const assetId = action.payload.oldAssetId;
      const newAsset: IAsset = action.payload.newAsset;

      // Now we know a new asset ID
      return state.set(assetId, newAsset.id);
    }

    case AssetSelector_Opened:
    case AssetLibrary_Folders_Opened:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
