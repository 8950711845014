import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React from 'react';
import { AutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { NoUsage } from '../../../../../_shared/components/Usages/NoUsage.tsx';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UsageItemReference } from '../../../../../_shared/utils/usage/usageUtils.ts';
import { ILanguage, Languages } from '../../../../../data/models/languages/Language.ts';
import { getLanguageName } from '../../../../environmentSettings/localization/utils/languageUtils.ts';
import { SectionTitle } from '../../../../itemEditor/features/ContentItemEditing/components/details/SectionTitle.tsx';
import { assetHasNoUsage, loadingAssetUsage } from '../../constants/uiConstants.ts';
import { SingleAssetUsage } from '../../containers/AssetEditing/SingleAssetUsage.tsx';

export interface IAssetUsageStateProps {
  readonly assetId: Uuid | null;
  readonly defaultLanguage: ILanguage;
  readonly languages: Languages;
  readonly projectId: Uuid;
  readonly scrollId?: AutoScrollId;
  readonly sortedLanguageUsage: Immutable.OrderedMap<Uuid, Immutable.List<UsageItemReference>>;
  readonly isUsageLoaded: boolean;
}

export interface IAssetUsageOwnProps {
  readonly isInDialog: boolean;
}

type AssetUsageProps = IAssetUsageStateProps & IAssetUsageOwnProps;

export const AssetUsage: React.FC<AssetUsageProps> = ({
  assetId,
  sortedLanguageUsage,
  defaultLanguage,
  isInDialog,
  languages,
  projectId,
  scrollId,
  isUsageLoaded,
}) => {
  if (sortedLanguageUsage.isEmpty()) {
    return (
      <div className="sidebar__section sidebar__section--contains-hover asset-usage">
        <NoUsage>{isUsageLoaded ? assetHasNoUsage : loadingAssetUsage}</NoUsage>
      </div>
    );
  }

  return (
    <div className="sidebar__section sidebar__section--contains-hover asset-usage">
      {sortedLanguageUsage
        .map((usages: Immutable.List<UsageItemReference>, languageId: Uuid) => {
          const languageName = getLanguageName(languageId, defaultLanguage, languages);

          return (
            <div
              key={languageId}
              className="asset-usage__section"
              {...(languageName && getDataUiObjectNameAttribute(languageName))}
            >
              <Box paddingX={Spacing.XXL}>
                <SectionTitle>Used in {languageName}</SectionTitle>
              </Box>
              <div {...getDataUiCollectionAttribute(DataUiCollection.ContentItemUsages)}>
                {assetId &&
                  usages.toArray().map((assetUsage) => (
                    <div
                      key={assetUsage.primary.id.itemId}
                      {...getDataUiElementAttribute(DataUiElement.UsedInItem)}
                    >
                      <SingleAssetUsage
                        assetId={assetId}
                        isInDialog={isInDialog}
                        projectId={projectId}
                        scrollId={scrollId}
                        usage={assetUsage}
                      />
                    </div>
                  ))}
              </div>
            </div>
          );
        })
        .toArray()}
    </div>
  );
};

AssetUsage.displayName = 'AssetUsage';
