import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionPermissionsEnabledForCurrentProject } from '../../../../../_shared/selectors/contentCollections.ts';
import { hasToUsePaidUserSlot } from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesUtils.ts';
import { initializeInviteModal, submitInvitations } from '../../actions/thunkUsersActions.ts';
import {
  changeInvitations,
  changeSearchText,
  closeInviteModal,
  hideNotificationBarInInvitation,
  invitationCollectionGroupsChanged,
} from '../../actions/usersActions.ts';
import { InvitationModal as InvitationModalComponent } from '../../components/invitations/InvitationModal.tsx';
import { invalidInvitationsInviteButtonTooltip } from '../../constants/errorMessages.ts';
import { useProjectManagerSelectionValidation } from '../../hooks/useProjectManagerSelectionValidation.ts';
import { getAdvancedRoleBuilderErrorMessage } from '../../selectors/getAdvancedRoleBuilderErrorMessage.ts';
import { hasInviteUnsavedChanges } from '../../selectors/inviteModalValidators.ts';
import {
  createDisabledInviteTooltip,
  createInvitationEmailInputValidationMessages,
  getNumberOfUsersAboveSubscriptionLimit,
} from '../../selectors/userModalsMessageSelectors.ts';
import { findCollidingContributorEmails } from '../../utils/findCollidingContributorEmails.ts';
import { getNewEmailsCountedInSubscriptionLimitsSelector } from '../../utils/getNewEmailsCountedInSubscriptionLimits.ts';

type Props = {
  readonly createUserDetailLink: (userId: UserId) => string;
  readonly initialUrlEncodedEmail: string | undefined;
  readonly userListingLink: string;
};

export const InvitationModal = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      initializeInviteModal(
        props.initialUrlEncodedEmail && decodeURIComponent(props.initialUrlEncodedEmail),
      ),
    );

    return () => {
      dispatch(closeInviteModal());
    };
  }, [props.initialUrlEncodedEmail]);

  const [triedInviteWithNonEmptyText, setTriedInviteWithNonEmptyText] = useState(false);

  const collectionGroups = useSelector((s) => s.usersApp.userInvitation.collectionGroups);
  const existingUsersByEmail = useSelector((s) => s.usersApp.userInvitation.existingUsersByEmail);
  const invitations = useSelector((s) => s.usersApp.userInvitation.invitations);
  const isInitialized = useSelector((s) => s.usersApp.userInvitation.isInitialized);
  const isSaving = useSelector((s) => s.usersApp.userInvitation.isSaving);
  const savingError = useSelector((s) => s.usersApp.userInvitation.savingError);
  const isSearchTextEmpty = useSelector(
    (s) => s.usersApp.userInvitation.searchText.trim().length === 0,
  );
  const isProjectManagerSelectionValid = useProjectManagerSelectionValidation(collectionGroups);
  const collidingContributorEmails = useSelector((s) =>
    findCollidingContributorEmails(s.usersApp.projectContributors, invitations),
  );
  const disabledInviteTooltip = useSelector(createDisabledInviteTooltip);
  const emailInputValidationMessages = useSelector((s) =>
    createInvitationEmailInputValidationMessages(s, triedInviteWithNonEmptyText),
  );
  const hasUnsavedChanges = useSelector(hasInviteUnsavedChanges);
  const newEmails = useSelector(getNewEmailsCountedInSubscriptionLimitsSelector);
  const shouldConfirmInvite = useSelector(
    (s) => hasToUsePaidUserSlot(s, newEmails) && isSearchTextEmpty,
  );
  const shouldHighlightNewUsers = useSelector(
    (s) => getNumberOfUsersAboveSubscriptionLimit(s) > 0 || hasToUsePaidUserSlot(s, newEmails),
  );
  const environmentName = useSelector((s) => getCurrentProject(s).environmentName);
  const errorMessage = useSelector((s) =>
    getAdvancedRoleBuilderErrorMessage(
      collectionGroups,
      areLanguageRolesEnabledForCurrentProject(s),
      areCollectionPermissionsEnabledForCurrentProject(s),
      isProjectManagerSelectionValid,
    ),
  );

  const history = useHistory();

  const close = (): void => history.push(props.userListingLink);

  const submit = async (): Promise<void> => {
    if (!isSearchTextEmpty) {
      setTriedInviteWithNonEmptyText(true);
      return;
    }
    await dispatch(submitInvitations());
    close();
  };

  const submitUnsavedChangesDialog = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    if (!isSearchTextEmpty) {
      setTriedInviteWithNonEmptyText(true);
      return;
    }

    try {
      await dispatch(submitInvitations());
      onSuccess();
    } catch {
      onFail();
    }
  };

  return isInitialized ? (
    <InvitationModalComponent
      changeInvitations={(newSelected) => dispatch(changeInvitations(newSelected))}
      changeSearchText={(text): void => {
        setTriedInviteWithNonEmptyText(false);
        dispatch(changeSearchText(text));
      }}
      collectionGroups={collectionGroups}
      collidingContributorEmails={collidingContributorEmails}
      createUserDetailLink={props.createUserDetailLink}
      disabledInviteTooltip={
        !disabledInviteTooltip && triedInviteWithNonEmptyText
          ? invalidInvitationsInviteButtonTooltip
          : disabledInviteTooltip
      }
      emailInputValidationMessages={emailInputValidationMessages}
      emailsCountedInSubscriptionLimits={newEmails}
      environmentName={environmentName}
      errorMessage={errorMessage}
      existingUsersByEmail={existingUsersByEmail}
      hasUnsavedChanges={hasUnsavedChanges}
      hideNotificationBar={() => dispatch(hideNotificationBarInInvitation())}
      invitations={invitations}
      isInviting={isSaving}
      onCollectionGroupsChange={(newCollectionGroups) =>
        dispatch(invitationCollectionGroupsChanged(newCollectionGroups))
      }
      onClose={close}
      onSubmit={submit}
      onSubmitUnsavedChangesDialog={submitUnsavedChangesDialog}
      savingError={savingError}
      shouldConfirmInvite={shouldConfirmInvite}
      shouldHighlightNewUsers={shouldHighlightNewUsers}
    />
  ) : null;
};
