// Don't shorten imports. You'll run into circular dependencies.
import { rgba } from '../../utils/color.ts';
import { BaseColor } from '../quarks/colors.ts';

///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////// Decision tokens //////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const colorPrimary = BaseColor.DeepPurple60;
export const colorPrimaryBorder = BaseColor.DeepPurple50;
export const colorPrimaryInverse = BaseColor.DeepPurple30;
export const colorPrimaryHover = BaseColor.DeepPurple70;
export const colorPrimaryHoverInverse = BaseColor.DeepPurple20;
export const colorPrimarySelected = BaseColor.DeepPurple70;
export const colorPrimaryDisabled = BaseColor.DeepPurple30;

export const colorLink = BaseColor.DeepPurple70;
export const colorLinkHover = BaseColor.DeepPurple90;
export const colorLinkVisited = BaseColor.Purple70;
export const colorLinkInverse = BaseColor.DeepPurple30;
export const colorLinkHoverInverse = BaseColor.DeepPurple10;
export const colorLinkVisitedInverse = BaseColor.Purple30;

export const colorIconDefault = BaseColor.Black;
export const colorIconHint = BaseColor.Gray60;
export const colorIconLowEmphasis = BaseColor.Gray80;
export const colorIconDefaultInverse = BaseColor.White;
export const colorIconHintInverse = BaseColor.Gray40;
export const colorIconDisabledInverse = rgba(BaseColor.White, 0.35);

export const colorFocus = BaseColor.AzureBlue;
export const colorAccent = BaseColor.BrickOrange;

export const colorBackground = BaseColor.White;
export const colorBackgroundInverse = BaseColor.Gray100;
export const colorBackgroundDisabled = BaseColor.Gray20;
export const colorBackgroundDisabledComplementary = BaseColor.Gray30;
export const colorBackgroundDragged = colorBackgroundDisabled;
export const colorBackgroundHover = rgba(BaseColor.Gray100, 0.1);
export const colorBackgroundHighlighted = BaseColor.DeepPurple10;
export const colorBackgroundSelected = BaseColor.DeepPurple10;
export const colorBackgroundTextSelection = rgba(BaseColor.OceanBlue50, 0.3);
export const colorBackgroundBlockSelection = BaseColor.OceanBlue20;
export const colorBackgroundSelectedHover = BaseColor.DeepPurple20;

export const colorSuccessBackground = BaseColor.PersianGreen10;
export const colorSuccessBackgroundInverse = BaseColor.PersianGreen60;
export const colorSuccessText = BaseColor.PersianGreen60;
export const colorSuccessIcon = BaseColor.PersianGreen50;
export const colorSuccessTextInverse = BaseColor.PersianGreen30;
export const colorSuccessIconInverse = BaseColor.PersianGreen40;

export const colorInfoBackground = BaseColor.OceanBlue10;
export const colorInfoBackgroundInverse = BaseColor.OceanBlue60;
export const colorInfoText = BaseColor.OceanBlue60;
export const colorInfoIcon = BaseColor.OceanBlue50;
export const colorInfoTextInverse = BaseColor.OceanBlue40;
export const colorInfoIconInverse = BaseColor.OceanBlue30;

export const colorWarningBackground = BaseColor.Yellow10;
export const colorWarningBackgroundInverse = BaseColor.CheeseYellow;
export const colorWarningText = BaseColor.Yellow60;
export const colorWarningIcon = BaseColor.Yellow50;
export const colorWarningTextInverse = BaseColor.Yellow40;
export const colorWarningIconInverse = BaseColor.Yellow30;

export const colorAlertBackground = BaseColor.Red10;
export const colorAlertBackgroundInverse = BaseColor.Red60;
export const colorAlertText = BaseColor.Red60;
export const colorAlertIcon = BaseColor.Red50;
export const colorAlertTextInverse = BaseColor.Red40;
export const colorAlertIconInverse = BaseColor.Red50;

export const colorAlertHover = BaseColor.Red70;
export const colorAlertFocus = BaseColor.Red60;
export const colorAlertActive = colorAlertHover;

export const colorIconDisabled = BaseColor.Gray50;

export const colorBorderDefault = BaseColor.Gray40;
export const colorBorderAlert = BaseColor.Red60;
export const colorBorderDisabled = BaseColor.Gray30;
export const colorBorderActive = BaseColor.Gray100;
export const colorBorderHover = colorBorderActive;
export const colorBorderLowEmphasis = BaseColor.Gray30;

export const colorInputBackground = colorBackground;
export const colorInputBorder = colorBorderDefault;

export const colorForbiddenContent = colorBorderAlert;
export const colorForbiddenFormattingBackground = rgba(BaseColor.Red40, 0.35);

export const colorSkeletonBackground = BaseColor.Gray10;

export const colorTextDefault = BaseColor.Gray100;
export const colorTextDefaultInverse = BaseColor.White;
export const colorTextDisabled = BaseColor.Gray50;
export const colorTextHint = BaseColor.Gray60;
export const colorTextHintInverse = BaseColor.Gray40;
export const colorTextLowEmphasis = BaseColor.Gray70;
export const colorTextLowEmphasisInverse = BaseColor.Gray20;

export const colorToggleIconSelected = colorIconDefault;
export const colorToggleTextSelected = colorTextDefault;
export const colorToggleText = colorTextLowEmphasis;
export const colorToggleIcon = colorIconHint;

export const colorCommentBackgroundHighlighted = BaseColor.Yellow20;
export const colorCommentBackgroundSelected = BaseColor.Yellow30;
export const colorSuggestionBackgroundHighlighted = BaseColor.Purple20;
export const colorSuggestionBackgroundSelected = BaseColor.Purple30;

export const colorOverlayBackground = rgba(BaseColor.Gray70, 0.4);
export const colorBlockingMessageBackground = rgba(BaseColor.OceanBlue80, 0.8);
export const colorMonospaceBackground = rgba(BaseColor.Gray50, 0.35);
export const colorBackgroundOffState = BaseColor.Gray20;

export const colorDiffNegative = BaseColor.Red20;
export const colorDiffNegativeBorder = BaseColor.Red60;
export const colorDiffPositive = BaseColor.NeonGreen10;
export const colorDiffPositiveBorder = BaseColor.NeonGreen60;
export const colorDiffFormat = BaseColor.SkyBlue20;
export const colorDiffFormatBorder = BaseColor.SkyBlue60;

export const colorDividerDefault = colorBorderLowEmphasis;

export const colorEmphasizedAreaBackground = rgba(BaseColor.OceanBlue30, 0.2);
export const colorBackgroundLowEmphasis = BaseColor.Gray10;
export const colorBackgroundHoverInverse = rgba(BaseColor.White, 0.16);
export const colorCodeBackground = BaseColor.Gray50;
export const colorAiSuggestionBackgroundHighlighted = BaseColor.Purple20;
export const colorAiSuggestionBackgroundSelected = BaseColor.Purple30;

export const CoreColor = {
  Gray: BaseColor.Gray60,
  Red: BaseColor.Red60,
  Rose: BaseColor.Rose60,
  LightPurple: BaseColor.Purple30,
  DarkPurple: BaseColor.Purple60,
  DarkBlue: BaseColor.OceanBlue60,
  LightBlue: BaseColor.OceanBlue30,
  Skyblue: BaseColor.SkyBlue30,
  MintGreen: BaseColor.PersianGreen30,
  PersianGreen: BaseColor.PersianGreen60,
  DarkGreen: BaseColor.NeonGreen60,
  LightGreen: BaseColor.NeonGreen30,
  Yellow: BaseColor.Yellow30,
  Pink: BaseColor.Red30,
  Orange: BaseColor.Orange60,
  Brown: BaseColor.Yellow60,
} as const satisfies Dictionary<BaseColor>;

export type CoreColor = (typeof CoreColor)[keyof typeof CoreColor];
