import { Button } from '@kontent-ai/component-library/Button';
import { SaveChangesButton } from '../../../../_shared/components/SaveChangesButton.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';
import { isLoaded } from '../utils/roleEditorStatusUtils.ts';

type Props = {
  readonly isEditedRoleReadOnly: boolean;
  readonly isRoleUsedByUser: boolean;
  readonly isRoleUsedByWorkflowStep: boolean;
  readonly isRoleUsedByCustomApp: boolean;
  readonly onDelete?: () => void;
  readonly onSave: () => void;
  readonly readOnly: boolean;
  readonly status: RoleEditorStatus;
};

export const RoleEditorToolbarActions = ({
  isEditedRoleReadOnly,
  isRoleUsedByUser,
  isRoleUsedByWorkflowStep,
  isRoleUsedByCustomApp,
  onDelete,
  onSave,
  readOnly,
  status,
}: Props) => {
  const getTooltipOption = (): string | undefined => {
    if (isRoleUsedByUser && isRoleUsedByWorkflowStep) {
      return 'Users and workflows are using this custom role.';
    }

    if (isRoleUsedByWorkflowStep) {
      return 'Workflow step is using this custom role.';
    }

    if (isRoleUsedByUser) {
      return 'Users are using this custom role.';
    }

    if (isRoleUsedByCustomApp) {
      return 'Custom app is using this custom role.';
    }

    if (status === RoleEditorStatus.IsBeingSaved) {
      return 'Role is being saved';
    }

    return undefined;
  };

  return (
    <>
      {!readOnly && isLoaded(status) && (
        <div className="status-bar__button status-bar__save-button">
          <SaveChangesButton
            buttonStyle="inverse"
            isBeingSaved={status === RoleEditorStatus.IsBeingSaved}
            onSave={onSave}
          />
        </div>
      )}
      {!isEditedRoleReadOnly && onDelete && isLoaded(status) && (
        <div className="status-bar__button status-bar__delete-button">
          <Button
            tooltipText={getTooltipOption()}
            tooltipPlacement="bottom-end"
            buttonStyle="secondary"
            destructive
            size="medium"
            disabled={
              isRoleUsedByUser ||
              isRoleUsedByWorkflowStep ||
              isRoleUsedByCustomApp ||
              status === RoleEditorStatus.IsBeingSaved
            }
            onClick={onDelete}
            {...getDataUiActionAttribute(DataUiAction.Delete)}
          >
            Delete
          </Button>
        </div>
      )}
    </>
  );
};
