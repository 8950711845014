import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { RequiredAssetCreationMetadata } from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { removeTemporaryAssetIdentifiers } from '../assetLibraryActions.ts';
import { IAssetUploadResult, ICreateAssets } from './createAssets.ts';

interface IDeps {
  readonly createAssets: ICreateAssets;
}

export interface IDropAssetsParams {
  readonly files: ReadonlyArray<FileWithThumbnail>;
  readonly metadata: RequiredAssetCreationMetadata;
}

export const createUploadAssetsAction =
  (deps: IDeps) =>
  ({ files, metadata }: IDropAssetsParams): ThunkPromise<ReadonlyArray<IAssetUploadResult>> =>
  async (dispatch: Dispatch): Promise<ReadonlyArray<IAssetUploadResult>> => {
    const filesArray: ReadonlyArray<readonly [Uuid, FileWithThumbnail]> = files.map(
      (file: FileWithThumbnail) => [createGuid(), file],
    );

    const assets = await dispatch(deps.createAssets(new Map(filesArray), metadata));

    const assetsIds = assets.map((asset) => asset.oldAssetId);
    dispatch(removeTemporaryAssetIdentifiers(assetsIds));

    return assets;
  };
