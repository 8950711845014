import { alphabetically, notNull } from '@kontent-ai/utils';
import { DraftInlineStyle } from 'draft-js';
import { DraftJSInlineStyle } from '../../../../plugins/inlineStyles/api/inlineStyles.ts';
import { HtmlData, TagData } from './tags.ts';

const styleToTag: ReadonlyRecord<DraftJSInlineStyle, string | null> = {
  [DraftJSInlineStyle.AiHighlight]: null,
  [DraftJSInlineStyle.Bold]: 'strong',
  [DraftJSInlineStyle.Code]: 'code',
  [DraftJSInlineStyle.Italic]: 'em',
  [DraftJSInlineStyle.SelectionHighlight]: null,
  [DraftJSInlineStyle.Subscript]: 'sub',
  [DraftJSInlineStyle.Superscript]: 'sup',
};

// We sort styles in a descending manner because later reduce stacks them up bottom-top, resulting in deterministic ascending order of the tags
const stylesSortedByTagsDescending = Object.entries(styleToTag)
  .filter(([, tag]) => !!tag)
  .toSorted(([, tagA], [, tagB]) => -1 * alphabetically(tagA ?? '', tagB ?? ''))
  .map(([style]) => style);

export type GetStyleData = (styles: DraftInlineStyle, text: string) => HtmlData | null;

export const getStyleData: GetStyleData = (styles, text): HtmlData => {
  if (styles.isEmpty()) {
    return [text];
  }
  return [
    stylesSortedByTagsDescending
      .map((style) => (styles.has(style) ? styleToTag[style] : null))
      .filter(notNull)
      .reduce(
        (last: TagData | string, tagName: string) => ({
          tagName,
          children: [last],
        }),
        text,
      ),
  ];
};
