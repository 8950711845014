import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { StatusMessageAction } from '../../../../stores/IContentAppStoreState.ts';
import { isSomethingSelected } from '../../itemsSelection/selectors/itemsSelectionSelectors.ts';

export enum StatusMessageStyle {
  Updated = 'updated',
  Deleted = 'deleted',
  Failed = 'failed',
  Selected = 'selected',
  Progress = 'progress',
  Normal = 'normal',
  Headline = 'headline',
}

export const getStatusMessageStyle = (state: IStore): StatusMessageStyle => {
  const { contentApp: app } = state;
  const lastAction = app.listingUi.statusInfo.lastAction.lastActionType;
  switch (lastAction) {
    case StatusMessageAction.Archiving:
    case StatusMessageAction.Moving:
    case StatusMessageAction.Publishing:
    case StatusMessageAction.Updating:
    case StatusMessageAction.Undoing:
    case StatusMessageAction.Unpublishing:
      return StatusMessageStyle.Progress;

    default:
      break;
  }

  const anySelected = isSomethingSelected(state);
  if (anySelected) {
    return StatusMessageStyle.Selected;
  }

  switch (lastAction) {
    case StatusMessageAction.Moved:
    case StatusMessageAction.Undone:
    case StatusMessageAction.Published:
    case StatusMessageAction.PublishingUndone:
    case StatusMessageAction.ScheduledToPublish:
    case StatusMessageAction.ScheduledToUnpublish:
    case StatusMessageAction.SchedulingToPublishUndone:
    case StatusMessageAction.Unpublished:
    case StatusMessageAction.Cancelled:
    case StatusMessageAction.Restored:
    case StatusMessageAction.Updated:
      return StatusMessageStyle.Updated;

    case StatusMessageAction.Archived:
      return StatusMessageStyle.Deleted;

    case StatusMessageAction.None:
      return StatusMessageStyle.Normal;

    default:
      return StatusMessageStyle.Normal;
  }
};
