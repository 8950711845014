import classNames from 'classnames';
import React, { useRef } from 'react';
import {
  AnimatedChevron,
  RestDirection,
} from '../../../../../../_shared/uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import { SquareButtonShell } from '../../../../../../_shared/uiComponents/SquareButton/SquareButtonShell.tsx';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import { DataUiAction } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ContentComponentCollapseActionProps = {
  readonly isCollapsed: boolean;
  readonly isCompact?: boolean;
  readonly onClick: () => void;
};

export const ContentComponentCollapseAction: React.FC<ContentComponentCollapseActionProps> = ({
  isCollapsed,
  isCompact,
  onClick,
}) => {
  const { current: initialIsCollapsed } = useRef(isCollapsed);

  const tooltipText = isCollapsed ? 'Expand' : 'Collapse';

  return (
    <SquareButtonShell
      className={classNames('content-component__action', {
        'content-component__action--is-compact': isCompact,
      })}
      ariaLabel={tooltipText}
      style={SquareButtonStyle.Quinary}
      size={SquareButtonSize.Quinary}
      onClick={onClick}
      tooltipText={tooltipText}
      tooltipPlacement="bottom-start"
      dataUiAction={isCollapsed ? DataUiAction.Expand : DataUiAction.Collapse}
    >
      <AnimatedChevron
        isTurned={initialIsCollapsed !== isCollapsed}
        restDirection={initialIsCollapsed ? RestDirection.Down : RestDirection.Up}
      />
    </SquareButtonShell>
  );
};

ContentComponentCollapseAction.displayName = 'ContentComponentCollapseAction';
