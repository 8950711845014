import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import {
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_FilterSearchPhraseChanged,
} from '../../constants/snippetActionTypes.ts';

const initialFilterPhrase = '';

export function filterSearchPhrase(state = initialFilterPhrase, action: Action): string {
  switch (action.type) {
    case ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished:
    case Shared_CurrentProjectId_Updated: {
      return initialFilterPhrase;
    }

    case ContentTypeSnippet_Listing_FilterSearchPhraseChanged: {
      const { searchPhrase } = action.payload;
      return isEmptyOrWhitespace(searchPhrase) ? initialFilterPhrase : searchPhrase;
    }

    default:
      return state;
  }
}
