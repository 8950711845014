import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { getAvailableContentTypesForContentComponent } from './getAvailableContentTypesForContentComponent.ts';
import { getAvailableContentTypesForContentItem } from './getAvailableContentTypesForContentItem.ts';
import { getAvailableContentTypesForWebSpotlightRootItem } from './getAvailableContentTypesForWebSpotlightRootItem.ts';

export const getAvailableContentTypes = (
  origin: NewContentItemOrigin,
  state: IStore,
  languageId: Uuid,
  collectionId: Uuid | null,
): Immutable.List<IContentType> => {
  switch (origin) {
    case NewContentItemOrigin.ContentComponent:
      return getAvailableContentTypesForContentComponent(state);
    case NewContentItemOrigin.Space:
      return getAvailableContentTypesForWebSpotlightRootItem(state);
    default:
      return getAvailableContentTypesForContentItem(languageId, collectionId, state);
  }
};
