import { ReactNode, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import {
  ContentItemRevisionViewerRouteParams,
  ContentItemTimelineItemRoute,
  ContentItemTimelineRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  UseThunkPromiseStatus,
  useThunkPromise,
} from '../../../../../_shared/hooks/useThunkPromise.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { initCompareRevision } from '../../Revisions/actions/thunkRevisionsActions.ts';

type Props = {
  readonly children: ReactNode;
  readonly comparedTimelineId: Uuid;
  readonly contentItemId: Uuid;
  readonly variantId: Uuid;
};

export const EnsureComparedRevision = ({
  children,
  comparedTimelineId,
  contentItemId,
  variantId,
}: Props) => {
  const history = useHistory();
  const params = useParams<ContentItemRevisionViewerRouteParams<string>>();

  const thunkParams = useMemo(
    () => ({
      contentItemId,
      compareItemId: comparedTimelineId,
      history,
    }),
    [contentItemId, comparedTimelineId, history],
  );
  const [isInitThunkDone, , status] = useThunkPromise(initCompareRevision, thunkParams);

  const isStateEnsured = useSelector((s) => {
    const selectedComparisonRevision = s.contentApp.selectedComparisonRevision;
    return (
      isInitThunkDone &&
      !!selectedComparisonRevision &&
      selectedComparisonRevision.contentItemId.itemId === contentItemId &&
      selectedComparisonRevision.contentItemId.variantId === variantId &&
      selectedComparisonRevision.itemId === comparedTimelineId
    );
  });

  useEffect(() => {
    if (status === UseThunkPromiseStatus.Failed) {
      const fallbackPath = buildPath<ContentItemTimelineRouteParams<UuidArray>>(
        ContentItemTimelineItemRoute,
        {
          app: params.app,
          variantId: params.variantId,
          spaceId: params.spaceId,
          projectId: params.projectId,
          contentItemIds: parseContentItemIds(params.contentItemIds),
        },
      );
      history.push(fallbackPath);
    }
  }, [
    history,
    status,
    params.app,
    params.variantId,
    params.spaceId,
    params.projectId,
    params.contentItemIds,
  ]);

  return isStateEnsured ? children : <Loader />;
};
