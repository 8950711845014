import { RichTextServerModel } from '../../../applications/richText/utils/serverModel/editorServerModel.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  OperationResponseMessage,
  isCumulatedOperationResponseMessage,
} from './AiServerModels.response.ts';

export type CumulatedOperationResult =
  | CumulatedStringResult
  | CumulatedDraftJsResult
  | CumulatedIdsResult;

type CumulatedOperationResultTemplate<TType extends string, TValue> = {
  readonly type: TType;
  readonly value: TValue;
  readonly sequenceNumber: number;
  readonly isFinished: boolean;
};

export const cumulatedStringResultType = 'cumulated-string';
export type CumulatedStringResult = CumulatedOperationResultTemplate<
  typeof cumulatedStringResultType,
  string
>;
export const isCumulatedStringOperationResponseMessage = (
  message: OperationResponseMessage,
): message is CumulatedOperationResponseMessageTemplate<CumulatedStringResult> =>
  isCumulatedOperationResponseMessage(message) && message.result.type === cumulatedStringResultType;

export const cumulatedDraftJsResultType = 'cumulated-draft-js';
export type CumulatedDraftJsResult = CumulatedOperationResultTemplate<
  typeof cumulatedDraftJsResultType,
  RichTextServerModel
>;
export const isCumulatedDraftJsOperationResponseMessage = (
  message: OperationResponseMessage,
): message is CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult> =>
  isCumulatedOperationResponseMessage(message) &&
  message.result.type === cumulatedDraftJsResultType;

export const cumulatedIdsResultType = 'cumulated-ids';
export type CumulatedIdsResult = CumulatedOperationResultTemplate<
  typeof cumulatedIdsResultType,
  ReadonlyArray<Uuid>
>;
export const isCumulatedIdsOperationResponseMessage = (
  message: OperationResponseMessage,
): message is CumulatedOperationResponseMessageTemplate<CumulatedIdsResult> =>
  isCumulatedOperationResponseMessage(message) && message.result.type === cumulatedIdsResultType;
