import { IBaseSelectItem, ItemId } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { CollectionSelectOption } from '../../../../../_shared/containers/CollectionSelectOption.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly collectionSelectItems: ReadonlyArray<IBaseSelectItem>;
  readonly disabledCollectionSelectItemIds: Iterable<ItemId>;
  readonly disabledTooltipText: string | undefined;
  readonly handleClose: () => void;
  readonly handleSelect: (option: IBaseSelectItem) => void;
  readonly handleSelectedOptionChange: (option: IBaseSelectItem | null) => void;
  readonly headlineText: string;
  readonly labelText?: string;
  readonly primaryActionText: string;
  readonly selectedOption: IBaseSelectItem | null;
};

export const AssetsSelectCollectionDialog: React.FC<Props> = ({
  collectionSelectItems,
  disabledCollectionSelectItemIds,
  disabledTooltipText,
  handleClose,
  handleSelect,
  handleSelectedOptionChange,
  headlineText,
  labelText,
  primaryActionText,
  selectedOption,
}) => {
  return (
    <ModalDialog
      isOpen
      isDismissable
      onClose={handleClose}
      headline={headlineText}
      minWidth={px(50 * gridUnit)}
      primaryAction={{
        disabled: !!disabledTooltipText,
        onClick: () => {
          if (selectedOption) {
            handleSelect(selectedOption);
            handleClose();
          }
        },
        text: primaryActionText,
        tooltipText: disabledTooltipText,
        ...getDataUiActionAttribute(DataUiAction.MoveAssetsToCollection),
      }}
      cancelAction={{
        onClick: handleClose,
        ...getDataUiActionAttribute(DataUiAction.CloseDialog),
      }}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <SingleSelect<IBaseSelectItem>
        autoFocus
        delayAutoFocus={250}
        disabledItemIds={disabledCollectionSelectItemIds}
        items={collectionSelectItems}
        label={labelText}
        onSelectionChange={(_itemId, item) => handleSelectedOptionChange(item)}
        placeholder="Select a collection"
        selectedItemId={selectedOption?.id ?? null}
        renderMenuOption={(optionProps) =>
          optionProps.item.value && (
            <CollectionSelectOption
              collectionId={optionProps.item.value.id}
              {...getDataUiObjectNameAttribute(optionProps.item.value.id)}
              {...optionProps}
            />
          )
        }
        inputDataAttributes={{
          ...getDataUiActionAttribute(DataUiAction.OpenCollectionSelection),
          ...getDataUiInputAttribute(DataUiInput.Text),
        }}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      />
    </ModalDialog>
  );
};

AssetsSelectCollectionDialog.displayName = 'AssetsSelectCollectionDialog';
