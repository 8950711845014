import { areShallowEqual } from '@kontent-ai/utils';
import { IWebhookFormShape } from '../models/IWebhookFormShape.type.ts';

export const hasWebhookEditorUnsavedChanges = (
  original: IWebhookFormShape,
  current: IWebhookFormShape | undefined,
): boolean => {
  if (!current) {
    return false;
  }

  return (
    original.name !== current.name ||
    original.url !== current.url ||
    original.secret !== current.secret ||
    !areShallowEqual(
      original.triggers.deliveryApiItemContentTriggerIds,
      current.triggers.deliveryApiItemContentTriggerIds,
    ) ||
    !areShallowEqual(
      original.triggers.deliveryApiTaxonomyContentTriggerIds,
      current.triggers.deliveryApiTaxonomyContentTriggerIds,
    ) ||
    !areShallowEqual(
      original.triggers.previewDeliveryApiItemContentTriggerIds,
      current.triggers.previewDeliveryApiItemContentTriggerIds,
    ) ||
    !areShallowEqual(
      original.triggers.previewDeliveryApiTaxonomyContentTriggerIds,
      current.triggers.previewDeliveryApiTaxonomyContentTriggerIds,
    ) ||
    !areShallowEqual(
      original.triggers.managementApiItemContentTriggerIds,
      current.triggers.managementApiItemContentTriggerIds,
    ) ||
    !areShallowEqual(original.triggers.itemWorkflowStepIds, current.triggers.itemWorkflowStepIds)
  );
};
