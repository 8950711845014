import { assert } from '@kontent-ai/utils';
import { VariantIdMacro } from '../constants/variantIdValues.ts';
import { EnsuringStatus } from '../reducers/internalReducers/selectedLanguage.ts';
import { IStore } from '../stores/IStore.type.ts';

export const getSelectedLanguageId = (state: IStore): Uuid | null =>
  state.sharedApp.selectedLanguage.id;

export const getSelectedLanguageIdOrRouteMacro = (state: IStore): Uuid =>
  getSelectedLanguageId(state) ?? VariantIdMacro;

export const getSelectedLanguageIdOrThrow = (state: IStore): Uuid => {
  const { id: selectedLanguageId, ensuringStatus } = state.sharedApp.selectedLanguage;

  assert(
    selectedLanguageId,
    () => `Selected language id not set, ensuring status: '${ensuringStatus}'.`,
  );

  return selectedLanguageId;
};

export const isSelectedLanguageIdInitialized = (state: IStore): boolean => {
  const status = state.sharedApp.selectedLanguage.ensuringStatus;

  return status === EnsuringStatus.Ensured || status === EnsuringStatus.EnsuredButEmpty;
};

export const isSelectedLanguageIdEnsured = (state: IStore): boolean => {
  const status = state.sharedApp.selectedLanguage.ensuringStatus;

  return status === EnsuringStatus.Ensured;
};
