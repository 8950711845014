import { ContentState } from 'draft-js';
import React, { memo, useCallback, useMemo } from 'react';
import { ContentItemElementStatus } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/ContentItemElementStatus.tsx';
import { RichTextBlockLimitStatusPane } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/limitInfoMessages/RichTextBlockLimitStatusPane.tsx';
import { TextLimitStatusPane } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/limitInfoMessages/TextLimitStatusPane.tsx';
import { IRichTextWarningResult } from '../../../../../itemEditor/utils/itemElementWarningCheckers/types/IRichTextItemElementWarningResult.type.ts';
import { EditorLimitations } from '../../../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { TopLevelBlockCategoryFeature } from '../../../../plugins/apiLimitations/api/editorLimitationUtils.ts';
import {
  getTextLimitStatus,
  getTextSizeTooltip,
} from '../../../../plugins/textApi/api/editorTextUtils.ts';

interface IRichTextElementStatusProps {
  readonly content: ContentState;
  readonly editorHasFocus: boolean;
  readonly hideLimitationStatus?: boolean;
  readonly limitations: EditorLimitations;
  readonly onClick: () => void;
  readonly validationResult: IRichTextWarningResult;
}

export const RichTextElementStatus: React.FC<IRichTextElementStatusProps> = memo(
  (props: IRichTextElementStatusProps): JSX.Element => {
    const {
      content,
      editorHasFocus,
      hideLimitationStatus,
      limitations,
      onClick,
      validationResult,
    } = props;

    const textLimitStatus = useMemo(
      () => getTextLimitStatus(limitations, content),
      [limitations, content],
    );
    const getTooltipText = useCallback(() => getTextSizeTooltip(content), [content]);

    const textValidationPassed =
      validationResult.isWidthLimitMet &&
      validationResult.isHeightLimitMet &&
      validationResult.isFileSizeLimitMet &&
      validationResult.isResponsiveImageTypeLimitMet &&
      validationResult.containsOnlyAllowedContentTypes &&
      !validationResult.violatedFeatures.violatedTopLevelFeatures.size &&
      !validationResult.violatedFeatures.violatedTableFeatures.size;
    const areLimitsMet = textValidationPassed && textLimitStatus.isLimitValueMet;

    const textBlockAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);

    const isHidden = areLimitsMet && !editorHasFocus;

    return (
      <ContentItemElementStatus isHidden={isHidden} onClick={onClick}>
        {!hideLimitationStatus && (
          <RichTextBlockLimitStatusPane
            limitations={limitations}
            validationResult={validationResult}
          />
        )}
        {textBlockAllowed && (
          <TextLimitStatusPane
            getTooltipText={getTooltipText}
            isTooltipEnabled={!isHidden}
            textLimitStatus={textLimitStatus}
          />
        )}
      </ContentItemElementStatus>
    );
  },
);

RichTextElementStatus.displayName = 'RichTextElementStatus';
