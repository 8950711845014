export const gridUnit = 8;

export const Spacing = {
  None: 0,
  XXS: 1,
  XS: 4,
  S: 8,
  M: 12,
  L: 16,
  XL: 24,
  XXL: 32,
  XXXL: 40,
  XXXXL: 48,
  XXXXXL: 56,
} as const;

export type Spacing = (typeof Spacing)[keyof typeof Spacing];
