const processIterable =
  <T>(callback: (value: T) => Promise<void>) =>
  async (iterator: IterableIterator<T>) => {
    for (const value of iterator) {
      await callback(value);
    }
  };

export const processWithConcurrencyLimit = <T>(
  concurrencyLimit: number,
  iterator: IterableIterator<T>,
  callback: (value: T) => Promise<void>,
): Array<Promise<void>> =>
  new Array(concurrencyLimit).fill(iterator).map(processIterable(callback));
