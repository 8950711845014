import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  areAnyFeaturesWithSemanticSearchEnabled,
  isAiSemanticSearchEnabled,
} from '../../../../_shared/selectors/aiSelectors.ts';
import { InnovationLabFeatureCodename } from '../types/InnovationLabFeatureCodenames.ts';
import { deactivateInnovationLabFeature } from './deactivateInnovationLabFeature.ts';

export const deactivateInnovationLabFeatureWithSemanticSearch =
  (featureName: InnovationLabFeatureCodename): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    await dispatch(deactivateInnovationLabFeature(featureName));

    const state = getState();
    if (isAiSemanticSearchEnabled(state) && !areAnyFeaturesWithSemanticSearchEnabled(state)) {
      await dispatch(deactivateInnovationLabFeature('ai-semantic-search'));
    }
  };
