import { Dimensions } from '../types/ImageEditorTypes.type.ts';

type CalculateMinZoomParams = {
  readonly outputImageDimensions: Dimensions;
  readonly originalImageDimensions: Dimensions;
};

export const calculateMinZoom = ({
  originalImageDimensions,
  outputImageDimensions,
}: CalculateMinZoomParams) => {
  const widthRatio = outputImageDimensions.width / originalImageDimensions.width;
  const heightRatio = outputImageDimensions.height / originalImageDimensions.height;

  return Math.max(widthRatio, heightRatio);
};

type CalculateCurrentZoomParams = {
  readonly outputImageDimensions: Dimensions;
  readonly rectangleDimensions: Dimensions;
};

export const calculateCurrentZoom = ({
  rectangleDimensions,
  outputImageDimensions,
}: CalculateCurrentZoomParams) => outputImageDimensions.width / rectangleDimensions.width;
