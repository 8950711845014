export const customAppEmptyRolesErrorText = 'Select a role for custom app.';

export const customAppContainsDeletedRoleError =
  'Please remove the deleted role from this custom app.';

export const customAppContainsDeletedRolesError =
  'Please remove all deleted roles from this custom app.';

export const customAppLocalhostUrlErrorMessage =
  'Localhost addresses cannot work with custom apps.';
