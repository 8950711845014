import { OrderBy, OrderByDirection } from '../../../../_shared/models/OrderBy.ts';

export enum AssetOrderingCode {
  Name = 'name',
  CreatedAt = 'createdAt',
  FileSize = 'fileSize',
}

export const getColumnName = (orderBy: OrderBy<AssetOrderingCode>): string => {
  switch (orderBy.direction) {
    case OrderByDirection.Ascending:
      switch (orderBy.columnCode) {
        case AssetOrderingCode.Name:
          return 'Name, A -> Z';
        case AssetOrderingCode.CreatedAt:
          return 'Oldest first';
        case AssetOrderingCode.FileSize:
          return 'Smallest first';
        default:
          return '';
      }

    case OrderByDirection.Descending:
      switch (orderBy.columnCode) {
        case AssetOrderingCode.Name:
          return 'Name, Z -> A';
        case AssetOrderingCode.CreatedAt:
          return 'Newest first';
        case AssetOrderingCode.FileSize:
          return 'Largest first';
        default:
          return '';
      }
    default:
      return '';
  }
};

export const defaultOrdering: OrderBy<AssetOrderingCode> = {
  columnCode: AssetOrderingCode.CreatedAt,
  direction: OrderByDirection.Descending,
};

export const orderingOptions: ReadonlyArray<OrderBy<AssetOrderingCode>> = [
  {
    columnCode: AssetOrderingCode.Name,
    direction: OrderByDirection.Ascending,
  },
  {
    columnCode: AssetOrderingCode.Name,
    direction: OrderByDirection.Descending,
  },
  defaultOrdering,
  {
    columnCode: AssetOrderingCode.CreatedAt,
    direction: OrderByDirection.Ascending,
  },
  {
    columnCode: AssetOrderingCode.FileSize,
    direction: OrderByDirection.Ascending,
  },
  {
    columnCode: AssetOrderingCode.FileSize,
    direction: OrderByDirection.Descending,
  },
];
