import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForAssets } from '../../../../../../_shared/selectors/contentCollections.ts';
import { getRelevantCollections } from '../../../../../../_shared/selectors/getRelevantCollections.ts';
import { DataUiCollection } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { ConfigurableCollectionMultipleOptionSelect } from '../../../../shared/components/filter/ConfigurableCollectionMultipleOptionSelect.tsx';
import { ListingFilterCategory } from '../../../../shared/components/filter/ListingFilterCategory.tsx';
import { queryChanged } from '../../../actions/assetLibraryActions.ts';

export const AssetsCollectionFilterSelector: React.FC = () => {
  const dispatch = useDispatch();

  const collections = useSelector((s) => getRelevantCollections(s, Capability.ViewAssets));
  const selectedCollections = useSelector((s) => s.assetLibraryApp.query.selectedCollections);
  const shouldBeVisible = useSelector((s) =>
    areCollectionsVisibleForAssets(s, Collection.getValues(s.data.collections.byId)),
  );

  return shouldBeVisible ? (
    <ListingFilterCategory
      title="Collection"
      collectionName={DataUiCollection.ContentCollections}
      noHorizontalPadding
    >
      <ConfigurableCollectionMultipleOptionSelect
        collections={collections}
        selectedCollections={selectedCollections}
        onFilterChanged={(newSelectedCollections) =>
          dispatch(queryChanged({ selectedCollections: newSelectedCollections }))
        }
      />
    </ListingFilterCategory>
  ) : null;
};

AssetsCollectionFilterSelector.displayName = 'AssetsCollectionFilterSelector';
