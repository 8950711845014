import Immutable from 'immutable';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export const filterContentTypesByIds = (
  contentTypes: Immutable.List<IContentType>,
  contentTypeIds: Immutable.Set<Uuid> | null,
): Immutable.List<IContentType> => {
  return contentTypeIds && contentTypes
    ? contentTypes.filter((type: IContentType) => contentTypeIds.contains(type.id)).toList()
    : Immutable.List<IContentType>();
};
