import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { EnvironmentIdCard as EnvironmentIdCardComponent } from '../components/EnvironmentIdCard.tsx';

export const EnvironmentIdCard: React.FC = () => {
  const environmentName = useSelector((s) => getCurrentProject(s).environmentName);
  const currentProjectId = useSelector(getCurrentProjectId);

  return (
    <EnvironmentIdCardComponent
      environmentName={environmentName}
      currentProjectId={currentProjectId}
    />
  );
};

EnvironmentIdCard.displayName = 'EnvironmentIdCard';
