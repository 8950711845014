import styled, { css } from 'styled-components';
import { colorTextDefaultInverse } from '../../../tokens/decision/colors.ts';
import { FontFamily } from '../../../tokens/quarks/fontFamily.ts';
import { FontSize } from '../../../tokens/quarks/fontSize.ts';
import { FontWeight } from '../../../tokens/quarks/fontWeight.ts';
import { LineHeight } from '../../../tokens/quarks/lineHeight.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { AvatarSize } from '../avatarSize.ts';

interface IInitialsProps {
  readonly $size: AvatarSize;
}

const getInitialsStyles = (
  fontWeight: FontWeight,
  fontSize: FontSize,
  lineHeight: LineHeight,
) => css`
  font-weight: ${fontWeight};
  font-size: ${px(fontSize)};
  line-height: ${px(lineHeight)};
`;

const getInitialsSizeStyles = ({ $size }: IInitialsProps) => {
  switch ($size) {
    case AvatarSize.XS:
    case AvatarSize.S:
      return getInitialsStyles(FontWeight.Regular, FontSize.XS, LineHeight.XS);
    case AvatarSize.M:
      return getInitialsStyles(FontWeight.Bold, FontSize.M, LineHeight.M);
    default:
      return getInitialsStyles(FontWeight.Bold, FontSize.L, LineHeight.XL);
  }
};

export const Initials = styled.div<IInitialsProps>`
  ${getInitialsSizeStyles};
  font-family: ${FontFamily.ProductPrimary};
  color: ${colorTextDefaultInverse};
`;
