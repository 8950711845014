import { ILoaderProps, Loader } from './Loader.tsx';

export type AccesibleLoaderProps = ILoaderProps & {
  readonly screenReaderText: string;
};

export const AccessibleLoader = (props: AccesibleLoaderProps) => {
  const { screenReaderText, ...loaderProps } = props;
  return (
    <div role="alert" aria-label={screenReaderText}>
      <Loader {...loaderProps} />
    </div>
  );
};
