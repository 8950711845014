import { assert } from '@kontent-ai/utils';
import { ISubscriptionAdminServerModel } from '../../../../repositories/serverModels/SubscriptionAdminServerModel.type.ts';

export interface ISubscriptionAdmin {
  readonly userId: UserId;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly isInvitationPending: boolean;
}

export const emptySubscriptionAdmin: ISubscriptionAdmin = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  isInvitationPending: false,
};

export function createSubscriptionAdminDomainModel(
  serverModel: ISubscriptionAdminServerModel,
): ISubscriptionAdmin {
  assert(!!serverModel, () => 'Subscription admin server model is falsy.');

  return {
    userId: serverModel.userId ?? emptySubscriptionAdmin.userId,
    firstName: serverModel.firstName ?? emptySubscriptionAdmin.firstName,
    lastName: serverModel.lastName ?? emptySubscriptionAdmin.lastName,
    email: serverModel.email ?? emptySubscriptionAdmin.email,
    isInvitationPending:
      serverModel.isInvitationPending ?? emptySubscriptionAdmin.isInvitationPending,
  };
}
