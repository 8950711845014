import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';

export interface IDueDateSectionState {
  readonly canEdit: boolean;
  readonly initialDueDate: DateTimeStamp | null;
  readonly isHidden: boolean;
}

export const getDueDateState = (state: IStore): IDueDateSectionState => {
  const {
    contentApp: { editedContentItemVariant },
  } = state;

  const dueDate = editedContentItemVariant?.assignment.due ?? null;
  const isInPublishingStep =
    editedContentItemVariant && isPublishingStepSelected(editedContentItemVariant.assignment);
  const isInArchivingStep =
    editedContentItemVariant && isArchivedWorkflowStepSelected(editedContentItemVariant.assignment);
  const isInPublishingOrArchivingStep = isInArchivingStep || isInPublishingStep;
  const canEdit =
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateAssignment, state) &&
    !isInPublishingOrArchivingStep;
  const isHidden = !dueDate && !canEdit;

  return {
    canEdit,
    initialDueDate: dueDate,
    isHidden,
  };
};
