import { Collection } from '@kontent-ai/utils';
import { forwardRef, useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForAssets } from '../../../../../../../_shared/selectors/contentCollections.ts';
import { isCreatingRenditionsEnabled } from '../../../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getSelectedLanguageId } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCollectionName } from '../../../../../../../_shared/utils/assets/assetUtils.ts';
import {
  getAssetDataForValidation,
  isResponsiveImage,
  validateFileSize,
  validateHeight,
  validateWidth,
} from '../../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { isImageMimeType } from '../../../../../../../_shared/utils/fileTypeDetection.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { Asset, EmptyAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { getCurrentProjectPlan } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isAssetUncategorized } from '../../../../../../contentInventory/assets/utils/assetTaxonomyUtils.ts';
import { AssetFileTypeOption } from '../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { AssetLimitations } from '../../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  AssetTile as AssetTileComponent,
  IAssetTileOwnProps as IAssetTileOwnComponentProps,
} from '../../../components/elements/asset/AssetTile.tsx';
import { AssetTileAction } from '../../../components/elements/asset/AssetTileActions.tsx';

export interface IAssetValidationSettings {
  readonly fileSizeLimit?: number | null;
  readonly minWidth: number | null;
  readonly maxWidth: number | null;
  readonly minHeight: number | null;
  readonly maxHeight: number | null;
  readonly onlyResponsiveImages: boolean;
}

export const getAssetValidationSettings = (
  limitations: AssetLimitations,
): IAssetValidationSettings => {
  return {
    minWidth: limitations.minWidth,
    maxWidth: limitations.maxWidth,
    minHeight: limitations.minHeight,
    maxHeight: limitations.maxHeight,
    onlyResponsiveImages: limitations.fileType === AssetFileTypeOption.ResponsiveImages,
    fileSizeLimit: limitations.fileSize,
  };
};

export interface IAssetTileOwnProps extends IAssetTileOwnComponentProps {
  readonly assetId: Uuid;
  readonly renditionId?: Uuid;
  readonly validationSettings: IAssetValidationSettings;
  readonly onEdit?: () => void;
}

export const AssetTile = forwardRef<HTMLDivElement, IAssetTileOwnProps>(
  (
    {
      actions,
      assetId,
      onEdit,
      onOpenRenditionDialog,
      renditionId,
      validationSettings,
      ...otherProps
    },
    ref,
  ) => {
    const canViewAnyActiveLanguage = useSelector(canUserViewAnyActiveLanguage);
    const selectedLanguageId = useSelector(getSelectedLanguageId);

    const rendition = useSelector((state) =>
      state.data.assetRenditions.byId.get(renditionId ?? ''),
    );
    const currentPlan = useSelector(getCurrentProjectPlan);

    const asset = useSelector((state) =>
      canViewAnyActiveLanguage ? (state.data.assets.byId.get(assetId) ?? EmptyAsset) : EmptyAsset,
    );
    const collectionName = useSelector((state) =>
      areCollectionsVisibleForAssets(state, Collection.getValues(state.data.collections.byId))
        ? getCollectionName(asset.collectionId, state.data.collections.byId)
        : null,
    );

    const canViewAsset = Asset.hasCapability(asset, ActiveCapabilityType.ViewAssets);
    const assetExists = Asset.exists(asset);

    const isUncategorized = useSelector((state) => {
      const defaultAssetType = state.data.assetTypes.defaultAssetType;
      const taxonomyGroups = state.data.taxonomyGroups.byId;
      return isAssetUncategorized(asset, defaultAssetType, taxonomyGroups);
    });

    const validationResult = useMemo(() => {
      const assetValidationData = getAssetDataForValidation(asset, rendition);

      return {
        isAssetFileSizeValid: validateFileSize(
          assetValidationData,
          validationSettings.fileSizeLimit,
        ),
        isAssetFileTypeValid:
          !validationSettings.onlyResponsiveImages || isResponsiveImage(asset.type),
        isAssetWidthValid: validateWidth(
          assetValidationData,
          validationSettings.minWidth,
          validationSettings.maxWidth,
        ),
        isAssetHeightValid: validateHeight(
          assetValidationData,
          validationSettings.minHeight,
          validationSettings.maxHeight,
        ),
      };
    }, [asset, rendition, validationSettings]);

    const canOpenRenditionDialog =
      (rendition || isCreatingRenditionsEnabled(currentPlan)) &&
      isImageMimeType(asset.type) &&
      !otherProps.disabled;

    const availableActions = useMemo(
      () =>
        actions
          .filter(
            (action) =>
              canViewAsset ||
              action === AssetTileAction.AddComment ||
              action === AssetTileAction.Delete,
          )
          .filter((action) => assetExists || action !== AssetTileAction.Edit)
          .filter((action) => canOpenRenditionDialog || action !== AssetTileAction.OpenRendition),
      [actions, assetExists, canViewAsset, canOpenRenditionDialog],
    );

    const isHeightLimitationSet = !!validationSettings.minHeight || !!validationSettings.maxHeight;
    const isWidthLimitationSet = !!validationSettings.minWidth || !!validationSettings.maxWidth;

    return (
      <AssetTileComponent
        {...otherProps}
        actions={availableActions}
        asset={asset}
        canViewAnyActiveLanguage={canViewAnyActiveLanguage}
        canViewAsset={canViewAsset}
        collectionName={collectionName}
        isHeightLimitationSet={isHeightLimitationSet}
        isUncategorized={isUncategorized}
        isWidthLimitationSet={isWidthLimitationSet}
        onEdit={assetExists ? onEdit : undefined}
        onOpenRenditionDialog={assetExists ? onOpenRenditionDialog : undefined}
        ref={ref}
        rendition={rendition}
        searchPhrase=""
        selectedLanguageId={selectedLanguageId}
        validationResult={validationResult}
      />
    );
  },
);

AssetTile.displayName = 'AssetTileContainer';
