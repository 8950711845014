import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getApplicableContributorRoleId } from '../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { canRoleDoSomethingInStep } from '../../../../../../_shared/utils/permissions/roleInWorkflowStepUtils.ts';
import { RegularWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { getWorkflow } from '../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { emptyContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';

const hasCapabilityToWorkInWorkflowStep = (state: IStore): boolean => {
  const {
    contentApp: { editedContentItem, editedContentItemVariant },
    data: {
      user: {
        info: { userId },
      },
      users,
    },
  } = state;

  const currentUser = users.usersById.get(userId);
  const contentItemVariant = editedContentItemVariant || emptyContentItemVariant;
  const currentWorkflow = getWorkflow(
    state,
    contentItemVariant.assignment.workflowStatus.workflowId,
  );

  if (!currentWorkflow) {
    return false;
  }

  const languageId = contentItemVariant.id.variantId;
  const collectionId = editedContentItem?.collectionId ?? null;
  const currentUserRoleId = currentUser
    ? getApplicableContributorRoleId(currentUser, languageId, collectionId)
    : null;

  const currentWorkflowStep = contentItemVariant.assignment?.workflowStatus;
  const isCurrentWorkflowStepRegular =
    currentWorkflowStep &&
    currentWorkflow.steps.find((ws: RegularWorkflowStep) => ws.id === currentWorkflowStep.id);

  return (
    !isCurrentWorkflowStepRegular ||
    canRoleDoSomethingInStep(currentUserRoleId, currentWorkflowStep.id, currentWorkflow)
  );
};

export const MissingCapabilityNotificationBar: React.FC = () => {
  const isRequiredStateLoaded = useSelector((s) => !!s.contentApp.editedContentItemVariant);

  const hasCapabilityForWorkflowStep = useSelector((s) => hasCapabilityToWorkInWorkflowStep(s));

  return isRequiredStateLoaded && !hasCapabilityForWorkflowStep ? (
    <NotificationBar
      type={NotificationBarType.Warning}
      message="Your role prevents you from editing content in this workflow step."
    />
  ) : null;
};

MissingCapabilityNotificationBar.displayName = 'MissingCapabilityNotificationBar';
