import React, { ReactNode } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { AuthorizedSection } from '../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ProjectSettingsLayout } from '../../root/components/ProjectSettingsLayout.tsx';
import { ProjectSettingsWithMenuLayout } from '../../root/components/ProjectSettingsWithMenuLayout.tsx';
import { ProjectSettingsAppNames } from '../../root/constants/ProjectSettingsAppNames.ts';
import {
  DeliveryKeysRouteSegment,
  ManagementKeysRouteSegment,
  PersonalManagementKeyRouteSegment,
  TokenSeedIdSegment,
} from '../../root/constants/routeSegments.ts';
import { ApiKeyTab, getLastRouteSegmentForApiKeyTab } from '../constants/ApiKeyTab.ts';
import { DapiKeyDetail } from '../containers/ApiKeyDetail/DapiKeyDetail.tsx';
import { MapiKeyDetail } from '../containers/ApiKeyDetail/MapiKeyDetail.tsx';
import { NewDapiKeyDetail } from '../containers/ApiKeyDetail/NewDapiKeyDetail.tsx';
import { NewMapiKeyDetail } from '../containers/ApiKeyDetail/NewMapiKeyDetail.tsx';
import { NewPersonalMapiKeyDetail } from '../containers/ApiKeyDetail/NewPersonalMapiKeyDetail.tsx';
import { PersonalMapiKeyDetail } from '../containers/ApiKeyDetail/PersonalMapiKeyDetail.tsx';
import { DeliveryKeysListing } from '../containers/DeliveryKeysListing.tsx';
import { EnsureApiKeyListingInitialized } from '../containers/EnsureApiKeyListingInitialized.tsx';
import { ManagementKeysListing } from '../containers/ManagementKeysListing.tsx';

const getDefaultRedirectPath = (apiKeyTabs: ReadonlyArray<ApiKeyTab>): string =>
  apiKeyTabs.includes(ApiKeyTab.MapiKeys)
    ? getLastRouteSegmentForApiKeyTab(ApiKeyTab.MapiKeys)
    : getLastRouteSegmentForApiKeyTab(ApiKeyTab.DapiKeys);

type ApiKeysSettingsProps = {
  readonly masterEnvironmentId: Uuid;
  readonly projectContainerId: Uuid;
  readonly renderSettingsMenu: () => ReactNode;
  readonly visibleApiKeyTabs: ReadonlyArray<ApiKeyTab>;
};

export const ApiKeysSettings: React.FC<ApiKeysSettingsProps> = ({
  masterEnvironmentId,
  projectContainerId,
  renderSettingsMenu,
  visibleApiKeyTabs,
}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/${DeliveryKeysRouteSegment}`}>
        <EnsureApiKeyListingInitialized>
          <AuthorizedSection
            appName={ProjectSettingsAppNames.DeliveryKeys}
            isAuthorized={visibleApiKeyTabs.includes(ApiKeyTab.DapiKeys)}
            projectId={masterEnvironmentId}
            projectContainerId={projectContainerId}
          >
            <ProjectSettingsWithMenuLayout
              renderContent={() => <DeliveryKeysListing />}
              renderSettingsMenu={renderSettingsMenu}
            />
          </AuthorizedSection>
        </EnsureApiKeyListingInitialized>
      </Route>
      <Route exact path={`${path}/${ManagementKeysRouteSegment}`}>
        <EnsureApiKeyListingInitialized>
          <AuthorizedSection
            appName={ProjectSettingsAppNames.ManagementKeys}
            isAuthorized={visibleApiKeyTabs.includes(ApiKeyTab.MapiKeys)}
            projectId={masterEnvironmentId}
            projectContainerId={projectContainerId}
          >
            <ProjectSettingsWithMenuLayout
              renderContent={() => <ManagementKeysListing />}
              renderSettingsMenu={renderSettingsMenu}
            />
          </AuthorizedSection>
        </EnsureApiKeyListingInitialized>
      </Route>
      <Route
        path={`${path}/${ManagementKeysRouteSegment}/${PersonalManagementKeyRouteSegment}/${TokenSeedIdSegment}`}
      >
        <ProjectSettingsLayout renderContent={() => <PersonalMapiKeyDetail />} />
      </Route>
      <Route
        path={`${path}/${ManagementKeysRouteSegment}/${PersonalManagementKeyRouteSegment}/new`}
      >
        <ProjectSettingsLayout renderContent={() => <NewPersonalMapiKeyDetail />} />
      </Route>
      <Route path={`${path}/${ManagementKeysRouteSegment}/${TokenSeedIdSegment}`}>
        <ProjectSettingsLayout renderContent={() => <MapiKeyDetail />} />
      </Route>
      <Route path={`${path}/${ManagementKeysRouteSegment}/new`}>
        <ProjectSettingsLayout renderContent={() => <NewMapiKeyDetail />} />
      </Route>
      <Route path={`${path}/${DeliveryKeysRouteSegment}/${TokenSeedIdSegment}`}>
        <ProjectSettingsLayout renderContent={() => <DapiKeyDetail />} />
      </Route>
      <Route path={`${path}/${DeliveryKeysRouteSegment}/new`}>
        <ProjectSettingsLayout renderContent={() => <NewDapiKeyDetail />} />
      </Route>
      <Redirect from={path} to={`${path}/${getDefaultRedirectPath(visibleApiKeyTabs)}`} />
    </Switch>
  );
};

ApiKeysSettings.displayName = 'ApiKeysSettings';
