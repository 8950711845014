import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_LoadDefaultRoot_Failed,
  Relations_LoadDefaultRoot_Finished,
  Relations_LoadDefaultRoot_Started,
} from '../constants/relationsActionTypes.ts';

const initialState: boolean = false;

export function isDefaultRootBeingFetched(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_LoadDefaultRoot_Started:
      return true;

    case Relations_LoadDefaultRoot_Failed:
    case Relations_LoadDefaultRoot_Finished:
      return false;

    default:
      return state;
  }
}
