import { createEnsureSelectedVariantInRouteAction } from '../../../_shared/actions/thunks/ensureSelectedVariantInRoute.ts';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { WebSpotlightEventTypes } from '../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { getPathWithReplacedVariantIdForWebSpotlightRoute } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadSpaces,
} from '../../../data/actions/thunkDataActions.ts';
import { contentVariantIdStorage } from '../../../localStorages/contentVariantStorage.ts';
import { onLinkedItemsElementValueChange } from '../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { createItemParser } from '../../itemEditor/features/ContentItemEditing/utils/parseContentItem.ts';
import {
  loadDefaultListingItems,
  loadListingItems,
} from '../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import {
  createNewContentItem,
  prepareNewContentItemVariantInitFromNewContentItemForm,
} from '../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import {
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
} from '../../itemEditor/stores/utils/contentItemHelperMethods.ts';
import { setWebSpotlightStatusCreator } from './setWebSpotlightStatus.ts';
import { createAndLinkContentItemActionCreator } from './thunks/createAndLinkContentItem.ts';
import { createNewContentItemFromWebSpotlightCreator } from './thunks/createNewContentItemFromWebSpotlight.ts';
import { ensureContentLinksDefaultVariantsLoadedActionCreator } from './thunks/ensureContentLinksDefaultVariantsLoaded.ts';
import { createEnsureWebSpotlightAction } from './thunks/ensureWebSpotlight.ts';
import { expandSelectedWebSpotlightNodeAncestorsActionCreator } from './thunks/expandSelectedWebSpotlightNodeAncestors.ts';
import { expandWebSpotlightNodeActionCreator } from './thunks/expandWebSpotlightNode.ts';
import { getElementDataForPreviewActionCreator } from './thunks/getElementDataForPreview.ts';
import { linkPageItemsActionCreator } from './thunks/linkPageItems.ts';
import { linkPageItemsAndReloadActionCreator } from './thunks/linkPageItemsAndReload.ts';
import { loadElementActionPermissionsForVariantActionCreator } from './thunks/loadElementActionPermissionsForVariant.ts';
import { loadItemPreviewInfoActionCreator } from './thunks/loadItemPreviewInfo.ts';
import { loadSubpagesActionPermissionsForVariantActionCreator } from './thunks/loadSubpagesActionPermissionsForVariant.ts';
import { loadSubpagesIdsActionCreator } from './thunks/loadSubpagesIds.ts';
import { createLoadWebSpotlightTreeAction } from './thunks/loadWebSpotlightTree.ts';
import { reloadWebSpotlightNodeActionCreator } from './thunks/reloadWebSpotlightNode.ts';
import { createSelectSpaceAction } from './thunks/selectSpace.ts';
import { selectWebSpotlightNodeActionCreator } from './thunks/selectWebSpotlightNode.ts';
import { createStartPreviewApiChangesPollingAction } from './thunks/startPreviewApiChangesPolling.ts';
import { localizedRouteEntered } from './webSpotlightActions.ts';

const { contentItemRepository, webSpotlightRepository } = repositoryCollection;

export const loadSubpagesActionPermissionsForVariant =
  loadSubpagesActionPermissionsForVariantActionCreator({
    webSpotlightRepository,
  });

export const loadElementActionPermissionsForVariant =
  loadElementActionPermissionsForVariantActionCreator({
    webSpotlightRepository,
  });

export const ensureSelectedVariantForWebSpotlight = createEnsureSelectedVariantInRouteAction({
  appName: AppNames.WebSpotlight,
  loadCollections,
  loadLanguages,
  contentVariantIdStorage,
  getPathWithReplacedVariantId: getPathWithReplacedVariantIdForWebSpotlightRoute,
  localizedRouteEntered,
});

export const loadSubpagesIds = loadSubpagesIdsActionCreator({
  webSpotlightRepository,
});

export const expandWebSpotlightNode = expandWebSpotlightNodeActionCreator({
  loadSubpagesIds,
  loadListingItems,
});

export const expandSelectedWebSpotlightNodeAncestors =
  expandSelectedWebSpotlightNodeAncestorsActionCreator({
    loadSubpagesIds,
    loadListingItems,
  });

export const reloadWebSpotlightNode = reloadWebSpotlightNodeActionCreator({
  loadSubpagesIds,
  loadListingItems,
});

export const selectWebSpotlightNode = selectWebSpotlightNodeActionCreator({
  expandWebSpotlightNode,
});

export const loadWebSpotlightTree = createLoadWebSpotlightTreeAction({
  expandSelectedWebSpotlightNodeAncestors,
  expandWebSpotlightNode,
  loadListingItems,
  loadSubpagesIds,
});

export const selectSpace = createSelectSpaceAction({
  loadWebSpotlightTree,
});

export const ensureWebSpotlight = createEnsureWebSpotlightAction({
  loadSpaces,
  selectSpace,
});

const itemParser = createItemParser({
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
});

const linkPageItems = linkPageItemsActionCreator({
  contentItemRepository,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  onLinkedItemsElementValueChange,
});

export const linkPageItemsAndReload = linkPageItemsAndReloadActionCreator({
  linkPageItems,
  reloadWebSpotlightNode,
});

export const setWebSpotlightStatus = setWebSpotlightStatusCreator({
  loadContentTypes,
  webSpotlightRepository,
});

export const createAndLinkContentItem = createAndLinkContentItemActionCreator({
  createNewContentItem,
  linkPageItemsAndReload,
  trackItemCreated: (contentTypeSection, collectionSection) =>
    trackUserEventWithData(TrackedEvent.WebSpotlight, {
      action: WebSpotlightEventTypes.CreateContentItem,
      'collection-section': collectionSection,
      'content-type-section': contentTypeSection,
    }),
});

export const loadItemPreviewInfo = loadItemPreviewInfoActionCreator({
  contentItemRepository,
  loadListingItems,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  webSpotlightRepository,
});

export const startPreviewApiChangesPolling = createStartPreviewApiChangesPollingAction({
  webSpotlightRepository,
  trackUserEventWithData,
});

export const createNewContentItemFromWebSpotlight = createNewContentItemFromWebSpotlightCreator({
  createAndLinkContentItem,
  prepareNewContentItemVariantInit: prepareNewContentItemVariantInitFromNewContentItemForm,
});

export const ensureContentLinksDefaultVariantsLoaded =
  ensureContentLinksDefaultVariantsLoadedActionCreator({
    loadDefaultListingItems,
  });

export const getElementDataForPreview = getElementDataForPreviewActionCreator({
  ensureContentLinksDefaultVariantsLoaded,
});
