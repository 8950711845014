import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { SignalRConnectionFailReason } from '../../../../../_shared/services/signalR/signalRClient.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { RevisionRestoredQuery } from '../../Revisions/constants/uiConstants.ts';
import {
  ContentItemNotifications as ContentItemNotificationsComponent,
  IContentItemNotificationsOwnProps,
} from '../components/ContentItemNotifications.tsx';

const getShowSignalRTooManyTimeoutsWarning = (s: IStore): boolean =>
  s.sharedApp.isOnline &&
  s.sharedApp.signalRConnection.lastConnectionFailReason ===
    SignalRConnectionFailReason.TooManyStartTimeouts;

export const ContentItemNotifications: React.FC<IContentItemNotificationsOwnProps> = (props) => {
  const location = useLocation();

  const elementsAreInaccessibleTrigger = useSelector(
    (s) => s.contentApp.editorUi.elementsAreInaccessibleNotificationBarTrigger,
  );
  const showIncompleteDefaultVariantNotificationBar = useSelector(
    (s) => s.contentApp.showIncompleteDefaultVariantNotificationBar,
  );
  const showIncompleteItemNotificationBar = useSelector(
    (s) => s.contentApp.showIncompleteItemNotificationBar,
  );
  const showOverwriteNotification = useSelector((s) => !!s.contentApp.editedContentItemOverwritten);
  const showDefaultVariantOverwrittenNotification = useSelector(
    (s) => !!s.contentApp.defaultVariantOverwritten,
  );
  const showSignalRTooManyTimeoutsWarning = useSelector(getShowSignalRTooManyTimeoutsWarning);

  const showRestoredNotification = useSelector((s) => {
    const revisionRestoredRoute = location.search.includes(RevisionRestoredQuery);
    return !!s.contentApp.editorUi.restoredVersionId && revisionRestoredRoute;
  });

  return (
    <ContentItemNotificationsComponent
      {...props}
      elementsAreInaccessibleTrigger={elementsAreInaccessibleTrigger}
      showDefaultVariantOverwrittenNotification={showDefaultVariantOverwrittenNotification}
      showIncompleteDefaultVariantNotificationBar={showIncompleteDefaultVariantNotificationBar}
      showIncompleteItemNotificationBar={showIncompleteItemNotificationBar}
      showOverwriteNotification={showOverwriteNotification}
      showRestoredNotification={showRestoredNotification}
      showSignalRNotConnectedWarning={showSignalRTooManyTimeoutsWarning}
    />
  );
};

ContentItemNotifications.displayName = 'ContentItemNotifications';
