import React from 'react';

export const prettyStringify = (obj: any) => JSON.stringify(obj, null, 2);

type JoinItemsWithOxfordCommaProps<TItem extends { readonly id: string }> = {
  readonly items: ReadonlyArray<TItem>;
  readonly renderItem: (element: TItem) => React.ReactNode;
};

export const JoinItemsWithOxfordComma = <TElement extends { readonly id: string }>({
  items,
  renderItem,
}: JoinItemsWithOxfordCommaProps<TElement>) => {
  const [firstItem, secondItem] = items as [TElement, TElement];
  switch (items.length) {
    case 0: {
      return null;
    }
    case 1: {
      return renderItem(firstItem);
    }
    case 2: {
      return (
        <React.Fragment key={firstItem.id}>
          {renderItem(firstItem)} and {renderItem(secondItem)}
        </React.Fragment>
      );
    }
    default: {
      const lastIndex = items.length - 1;

      return items.map((item, index) => {
        return (
          <React.Fragment key={item.id}>
            {index !== 0 ? ', ' : ''}
            {index === lastIndex ? 'and ' : ''}
            {renderItem(item)}
          </React.Fragment>
        );
      });
    }
  }
};
