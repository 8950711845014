export enum ItemListingBulkAction {
  Delete = 'archive',
  MoveToCollection = 'move_to_collection',
  Publish = 'publish',
  Unpublish = 'unpublish',
  UpdateWorkflowStep = 'update_workflow_step',
  CancelScheduledPublish = 'cancel_schedule_publish',
  RestoreFromArchivedStep = 'restore_from_archived_step',
}

export const allItemListingBulkActions: ItemListingBulkAction[] =
  Object.values(ItemListingBulkAction);
