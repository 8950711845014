import { ICancellablePromise } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { TypeListOperationResultServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ContentTypeListingOperationType } from '../../utils/contentTypeListingOperationStatus.ts';
import {
  resetContentTypeListingOperationState,
  restoreContentTypesFinished,
  restoreContentTypesStarted,
} from '../contentTypesActions.ts';

interface IDeps {
  readonly contentTypeRepository: {
    readonly restoreContentTypes: (
      typeIds: UuidArray,
    ) => Promise<TypeListOperationResultServerModel>;
  };
  readonly loadContentTypesData: () => ThunkPromise;
  readonly delay: (duration: number) => ICancellablePromise;
}

export const createUndoContentTypeListingOperationAction =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { listingOperationStatus } = getState().contentModelsApp.contentTypes.listing;
    if (listingOperationStatus.operationType !== ContentTypeListingOperationType.Archived) {
      return;
    }

    const typesToBeRestored = listingOperationStatus.restorableContentTypes;
    dispatch(restoreContentTypesStarted(typesToBeRestored));
    const restorableTypeIds = typesToBeRestored.map((type: IContentType) => type.id).toSet();

    const operationResult = await deps.contentTypeRepository.restoreContentTypes(
      restorableTypeIds.toArray(),
    );
    dispatch(
      restoreContentTypesFinished(
        restorableTypeIds,
        Immutable.Set<Uuid>(operationResult.modifiedTypeIds),
      ),
    );
    const prevListingOperationStatus =
      getState().contentModelsApp.contentTypes.listing.listingOperationStatus;

    await dispatch(deps.loadContentTypesData());

    await deps.delay(5000);
    dispatch(resetContentTypeListingOperationState(prevListingOperationStatus));
  };
