import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';

export enum ObjectType {
  AssetType = 'Asset type',
  ContentType = 'Content type',
  ContentTypeSnippet = 'Content type snippet',
}

export const ObjectTypeList: ReadonlyArray<IBaseSelectItem> = [
  { id: ObjectType.AssetType, label: ObjectType.AssetType },
  { id: ObjectType.ContentType, label: ObjectType.ContentType },
  { id: ObjectType.ContentTypeSnippet, label: ObjectType.ContentTypeSnippet },
];
