import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

/**
 * @deprecated This hook is an escape hatch for old ModalDialog component's animation only. Before using reconsider your implementation and prefer react-springs when possible.<br>
 * Allows postponing an effect of a value change.<br>
 * Return `false` until given delayMs pass, then return `true` if hook is active.
 * @param delayMs Milliseconds that need to pass before `true` is returned
 * @param isActive If set to `false`, always returns `false`
 */
export const useWaitForAnimationDelay = (delayMs: number, isActive: boolean = true): boolean => {
  const [isDelayed, setIsDelayed] = useState(false);
  const memoizedSetIsDelayed = useCallback(() => {
    setIsDelayed(isActive);
  }, [isActive]);
  const callback = useDebouncedCallback(memoizedSetIsDelayed, delayMs);

  useEffect(() => {
    if (isActive) {
      callback();
    }

    return callback.cancel;
  }, [isActive, callback]);

  return isActive && isDelayed;
};
