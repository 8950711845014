import { isError } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionServerModel } from '../../../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import {
  Subscription_ChangePlan_Failed,
  Subscription_ChangePlan_Finished,
  Subscription_ChangePlan_Requested,
} from '../../constants/actionTypes.ts';

interface ICanChangeSubscriptionPlan {
  readonly changeSubscriptionPlan: (
    planId: Uuid,
    subscriptionId: Uuid,
    skipCreditCardCheck: boolean,
  ) => Promise<ISubscriptionServerModel>;
}

interface IChangeSubscriptionPlanDependencies {
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly subscriptionRepository: ICanChangeSubscriptionPlan;
  readonly loadPlansForSelection: () => ThunkPromise;
}

export const changingSubscriptionPlanRequested = () =>
  ({
    type: Subscription_ChangePlan_Requested,
  }) as const;

export const changingSubscriptionPlanFinished = (
  subscriptionServerModel: ISubscriptionServerModel,
) =>
  ({
    type: Subscription_ChangePlan_Finished,
    payload: {
      subscriptionServerModel,
    },
  }) as const;

export const changingSubscriptionPlanFailed = (error: string) =>
  ({
    type: Subscription_ChangePlan_Failed,
    payload: {
      error,
    },
  }) as const;

export type ChangeSubscriptionPlanActionsType = ReturnType<
  | typeof changingSubscriptionPlanRequested
  | typeof changingSubscriptionPlanFinished
  | typeof changingSubscriptionPlanFailed
>;

export const changeSubscriptionPlanCreator =
  (deps: IChangeSubscriptionPlanDependencies) =>
  (planName: string, subscriptionId: Uuid, skipCreditCardCheck?: boolean): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(changingSubscriptionPlanRequested());

    try {
      const subscriptionServerModel = await deps.subscriptionRepository.changeSubscriptionPlan(
        planName,
        subscriptionId,
        !!skipCreditCardCheck,
      );
      dispatch(changingSubscriptionPlanFinished(subscriptionServerModel));

      await Promise.all([
        dispatch(deps.loadAdministratedSubscriptions()),
        dispatch(deps.loadSubscriptionUsage(subscriptionId)),
        dispatch(deps.loadProjects()),
        dispatch(deps.loadUserProjectsInfo()),
        dispatch(deps.loadPlansForSelection()),
      ]);
    } catch (error) {
      if (isError(error)) {
        dispatch(changingSubscriptionPlanFailed(error.message));
      }

      throw error;
    }
  };
