import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { Editor } from '../Editor.tsx';
import { BaseEditor } from '../types/Editor.base.type.ts';
import { InternalPluginProps, PluginHooks } from '../types/Editor.composition.type.ts';
import { Contract, Props } from '../types/Editor.contract.type.ts';

/**
 * Includes the plugin to the component chain of the editor
 *
 * @param props - Editor props (given as plugin component input)
 * @param pluginHooks - Plugin hooks to attach to the life cycle of the editor
 */
export const useEditorWithPlugin = <TPlugin extends Contract>(
  props: Props<BaseEditor> & InternalPluginProps,
  pluginHooks: PluginHooks<TPlugin>,
) => {
  const { hooks, plugins, ...baseProps } = props;

  const { apply, finalize, getApiMethods, init } = pluginHooks;
  const memoizedPluginHooks = useMemo(
    (): PluginHooks => ({
      apply,
      finalize,
      getApiMethods,
      init,
    }),
    [apply, finalize, getApiMethods, init],
  );

  const allHooks = useMemo(
    () => [memoizedPluginHooks, ...(hooks ?? [])],
    [hooks, memoizedPluginHooks],
  );
  const remainingPlugins = useMemo(() => plugins?.slice(0, -1), [plugins]);

  const NextPlugin = Collection.getLast(plugins ?? []);
  if (!NextPlugin) {
    return <Editor {...baseProps} hooks={allHooks} />;
  }

  return <NextPlugin {...baseProps} hooks={allHooks} plugins={remainingPlugins} />;
};
