import { CompiledTypeElementType } from '../../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { ContentItemId } from '../ContentItemId.ts';

interface ILinkEventIntercomData extends ContentItemId {
  readonly linkId: string;
}

export interface ILinkVisitedIntercomData extends ILinkEventIntercomData {
  readonly 'journey-stage': string;
  readonly projectId: string;
}

export interface IContentItemLinkCopiedIntercomData extends ILinkEventIntercomData {
  readonly fieldName: string;
}

export enum CommentEventTypes {
  CommentCreated = 'comment-created',
  CommentHighlighted = 'comment-highlighted',
  CommentThreadResolved = 'comment-thread-resolved',
  CommentThreadUnresolved = 'comment-thread-unresolved',
  CopyLink = 'copy-link',
  SuggestionApproved = 'suggestion-approved',
  SuggestionCreated = 'suggestion-created',
  MentionCreated = 'mention-created',
}

export interface ICommentsIntercomData {
  readonly action: CommentEventTypes;
  readonly commentThreadId?: Uuid;
  readonly 'element-type'?: CompiledTypeElementType;
  readonly threadItemsDepth?: number;
}

export interface IEditorStatisticsIntercomData {
  // We are limited with 5 metadata fields per intercom event
  // for the sake of future extensibility the counters are serialized to JSON which we can parse at SQL level of DW
  readonly counters: string;
  readonly project: Uuid;
  readonly name: string;
}

export interface IContentEntryNameOrElementsChangedIntercomData {
  readonly 'autosaves-count': number;
  readonly savedElementIds: string;
}

export interface IContentEntryOverwrittenEventData {
  readonly changeByManageApi: boolean;
  readonly changeBySelf: boolean;
  readonly changeReason: string;
  readonly differenceKeys: ReadonlyArray<string>;
}

export enum SimultaneousEditingSaveConflictReason {
  ElementLocked = 'element-locked',
  ElementOutdated = 'element-outdated',
}

export interface IContentEntryElementData {
  readonly 'element-id': Uuid;
  readonly 'element-type': string;
}

export interface IContentEntryElementSaveConflictData extends IContentEntryElementData {
  readonly reason: SimultaneousEditingSaveConflictReason;
}

export interface IContentEntryElementRefreshedData extends IContentEntryElementData {
  readonly 'changed-by-self': boolean;
}

export interface IContentEntryLockedElementOverwrittenData extends IContentEntryElementData {
  readonly 'changed-by-manage-api': boolean;
  readonly 'changed-by-self': boolean;
}
