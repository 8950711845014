import { usePrevious } from '@kontent-ai/hooks';
import { delay } from '@kontent-ai/utils';
import { Key, MultipleSelection, Node, Selection } from '@react-types/shared';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IInputProps } from '../../Input/Input.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import {
  VerticalMenuState,
  useNonAccessibleVerticalMenu,
} from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { isItem, isItemNode } from '../../VerticalMenu/utils/utils.tsx';
import { emptySelectionItem } from '../emptySelectionItem.ts';
import { ISelectItem, ISelectSection, ItemId } from '../types.ts';
import { filterItems } from '../utils/filterItems.ts';
import { findItem } from '../utils/findItem.ts';
import { getExpandableItemIds } from '../utils/getExpandableItemIds.ts';

/***
 * @param item The item for which you need to evaluate the filter phrase for a match
 * @param filterPhrase The expression a user is filtering by. Always already transformed to lowercase and trimmed.
 * */
export type SingleSelectFilterPredicate<TItem extends ISelectItem<TItem>> = (
  item: ISelectSection<TItem> | TItem,
  filterPhrase: string | null,
) => boolean;

export type UseSingleSelectStateOptions<TItem extends ISelectItem<TItem>> = Pick<
  IInputProps,
  'inputState'
> & {
  /**
   * Memoize this callback! Providing a new reference (as with any prop)
   * will cause a full re-calculation and re-render.
   * */
  readonly customFilterPredicate?: SingleSelectFilterPredicate<TItem>;
  readonly defaultSelectedItemId?: ItemId | null;
  readonly disabledItemIds?: Iterable<ItemId>;
  readonly inputRef: RefObject<HTMLInputElement>;
  readonly items: ReadonlyArray<ISelectSection<TItem> | TItem>;
  readonly onSelectionChange?: (itemId: ItemId | null, item: TItem | null) => void;
  readonly selectedItemId?: ItemId | null;
};

export type SingleSelectState<TItem extends ISelectItem<TItem>> = VerticalMenuState<TItem> & {
  readonly clearFilterValue: () => void;
  readonly closeMenu: () => void;
  readonly filterValue: string;
  readonly filteredItems: ReadonlyArray<ISelectSection<TItem> | TItem>;
  readonly inputValue: string;
  readonly isOpen: boolean;
  readonly openMenu: () => void;
  readonly revertState: () => void;
  readonly setFilterValue: Dispatch<SetStateAction<string>>;
  readonly setInputValue: Dispatch<SetStateAction<string>>;
};

// Handle null, but keep undefined, otherwise it breaks the uncontrolled mode.
const resolveSelectedKeys = (
  selectedItemId: ItemId | null | undefined,
): MultipleSelection['selectedKeys'] => {
  if (selectedItemId === undefined) {
    return undefined;
  }

  if (selectedItemId === null) {
    return [emptySelectionItem.id];
  }

  return [selectedItemId];
};

const defaultFilterPredicate = <TItem extends ISelectItem<TItem>>(
  item: ISelectSection<TItem> | TItem,
  filterValue: string | null,
) => (filterValue ? !!item.label?.toLocaleLowerCase().includes(filterValue) : true);

export const useSingleSelectState = <TItem extends ISelectItem<TItem>>({
  customFilterPredicate = defaultFilterPredicate,
  defaultSelectedItemId,
  disabledItemIds,
  inputRef,
  inputState,
  items,
  onSelectionChange,
  selectedItemId,
}: UseSingleSelectStateOptions<TItem>): SingleSelectState<TItem> => {
  const isControlled = selectedItemId !== undefined;
  const isDisabled = inputState === InputState.Disabled;
  const isReadOnly = inputState === InputState.ReadOnly;

  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState<string>((): string => {
    const selectedId = selectedItemId ?? defaultSelectedItemId;
    const selectedItem: TItem | ISelectSection<TItem> | undefined = selectedId
      ? findItem(items, (item) => item.id === selectedId)
      : undefined;

    if (selectedItem && isItem(selectedItem)) {
      return selectedItem.label;
    }

    return '';
  });
  const lastInputValue = useRef(inputValue);

  const [filterValue, setFilterValue] = useState('');

  const changeInputValue = useCallback((item: Node<TItem>) => {
    const textValue = item.value?.id === emptySelectionItem.id ? '' : item.textValue;
    setInputValue(textValue);
    lastInputValue.current = textValue;
  }, []);

  const clearInputValue = useCallback(() => {
    setInputValue('');
    lastInputValue.current = '';
  }, []);

  const filteredItems = useMemo(() => {
    const filterPredicate = (item: ISelectSection<TItem> | TItem) => {
      return customFilterPredicate(item, filterValue);
    };

    return filterItems(items, filterPredicate);
  }, [filterValue, items, customFilterPredicate]);

  const handleSelectionChange = (keys: Selection) => {
    if (!isReadOnly && !isDisabled) {
      const selectedKey = [...keys][0]?.toString() ?? emptySelectionItem.id;
      const selectedItem = verticalMenuState.collection.getItem(selectedKey);

      if (selectedItem && isItemNode(selectedItem)) {
        if (!isControlled) {
          // We are in uncontrolled mode here
          changeInputValue(selectedItem);
        }

        if (selectedKey === emptySelectionItem.id) {
          onSelectionChange?.(null, null);
        } else {
          onSelectionChange?.(selectedKey, selectedItem.value);
        }
      } else {
        clearInputValue();
        onSelectionChange?.(null, null);
      }
    }

    closeMenu();
  };

  const expendableKeys = getExpandableItemIds(items);

  const { verticalMenuState } = useNonAccessibleVerticalMenu<TItem>(filteredItems, {
    disabledKeys: disabledItemIds,
    disallowEmptySelection: true,
    defaultSelectedKeys: defaultSelectedItemId ? [defaultSelectedItemId] : undefined,
    expandedKeys: expendableKeys,
    selectionMode: 'single',
    selectedKeys: resolveSelectedKeys(selectedItemId),
    onSelectionChange: handleSelectionChange,
  });

  const openMenu = () => {
    // Prevent setState on unmounted component
    if (!isDisabled && inputRef.current && !isOpen) {
      setIsOpen(true);
    }
  };

  const closeMenu = () => {
    // Delay is necessary for Link Options to click before the menu is removed or altered. Only causes trouble with reduced motion.
    return delay(0).then(() => {
      // Prevent setState on unmounted component
      if (inputRef.current) {
        setIsOpen(false);
      }
    });
  };

  const revertState = () => {
    closeMenu();
    // Filter value is cleared on menu unmount
    setInputValue(lastInputValue.current);
  };

  // Needs to be memoized because it is called as a ref callback
  const clearFilterValue = useCallback(() => {
    setFilterValue('');
  }, []);

  // Set input's value and virtually focused item in the vertical menu on every 'selectedItemId' change — that is in 'controlled' mode.
  // We don't want to change it on every collection change, because that would overwrite the input's value when typing (filtering items).
  const resolvedSelectedItemKey = selectedItemId ?? emptySelectionItem.id;
  const selectedItem = resolvedSelectedItemKey
    ? verticalMenuState.collection.getItem(resolvedSelectedItemKey)
    : null;
  const prevSelectedItemKey = usePrevious<Key | null>(resolvedSelectedItemKey);
  const isFiltering = filterValue !== '';

  useEffect(() => {
    if (isControlled) {
      if (selectedItem && isItemNode(selectedItem) && selectedItem.key !== prevSelectedItemKey) {
        changeInputValue(selectedItem);
        verticalMenuState.getSelectionManager().setFocusedKey(selectedItem.key);
      }
      if (!selectedItem && !isFiltering && selectedItemId !== prevSelectedItemKey) {
        clearInputValue();
      }
    }
  }, [
    clearInputValue,
    changeInputValue,
    isControlled,
    prevSelectedItemKey,
    selectedItem,
    selectedItemId,
    isFiltering,
    verticalMenuState,
  ]);

  return {
    ...verticalMenuState,
    clearFilterValue,
    closeMenu,
    filterValue,
    filteredItems,
    inputValue,
    isOpen,
    openMenu,
    revertState,
    setFilterValue,
    setInputValue,
  };
};
