import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_HideRootSelector,
  Relations_SetRoot_Started,
  Relations_ShowRootSelector,
} from '../constants/relationsActionTypes.ts';

const initialState: boolean = false;

export function itemSelectorVisible(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_SetRoot_Started:
    case Relations_HideRootSelector:
      return false;

    case Relations_ShowRootSelector:
      return true;

    default:
      return state;
  }
}
