import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { PublishingState } from '../../../../../../../data/constants/PublishingState.ts';
import { isUsedAsWebSpotlightRootItem } from '../../../../../../webSpotlight/utils/webSpotlightUtils.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

export const isDeleteItemAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) =>
  hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DeleteContent, state) &&
  variant.publishingState !== PublishingState.Published &&
  !isUsedAsWebSpotlightRootItem(state, variant.id.itemId);

export const isPublishPreventsDeletingItemAvailable: ContentItemEditingActionAvailabilityProvider =
  (state, variant) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DeleteContent, state) &&
    variant.publishingState === PublishingState.Published;

export const isWebSpotlightPreventsDeletingItemAvailable: ContentItemEditingActionAvailabilityProvider =
  (state, variant) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DeleteContent, state) &&
    variant.publishingState !== PublishingState.Published &&
    isUsedAsWebSpotlightRootItem(state, variant.id.itemId);
