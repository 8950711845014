import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IInitWorkflowStepsEditorActionDependencies {
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflowsUsage: (
    workflowIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

export const createInitWorkflowsListingAction =
  ({ loadWorkflows, loadWorkflowsUsage }: IInitWorkflowStepsEditorActionDependencies) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(loadWorkflowsUsage([], abortSignal)),
      dispatch(loadWorkflows(abortSignal)),
    ]);
  };
