import { Spacing } from '@kontent-ai/component-library/tokens';
import { UnreachableCaseException } from '@kontent-ai/errors';
import React, { Children, ReactElement, RefObject, useEffect, useState } from 'react';
import { DropDownPositioner } from '../../../../../_shared/uiComponents/DropDown/DropDownPositioner.tsx';
import { IDropdownTippyOptions } from '../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import { AgendaItemsListingPopup } from '../../containers/Popups/AgendaItemsListingPopup.tsx';
import { ItemDetails } from '../../containers/Popups/ItemDetails.tsx';
import { CalendarPopupType } from '../Popups/CalendarPopupType.ts';

export interface IDateCellWrapperOwnProps {
  readonly children: ReactElement;
  readonly value: Date;
}

export interface IDateCellWrapperStateProps {
  readonly popupType: CalendarPopupType;
}

export interface IDateCellWrapperDispatchProps {
  readonly onDismiss: () => void;
}

type DateCellWrapperWithPopupProps = IDateCellWrapperOwnProps &
  IDateCellWrapperStateProps &
  IDateCellWrapperDispatchProps;

export const DateCellWrapperWithPopup: React.FC<DateCellWrapperWithPopupProps> = ({
  popupType,
  onDismiss,
  value,
  children,
}) => {
  const [popupBoundary, setPopupBoundary] = useState<Element | null>(null);

  useEffect(() => {
    setPopupBoundary(document.querySelector('.canvas'));
  }, []);

  const tippyOptions: IDropdownTippyOptions = {
    placement: 'right',
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left', 'bottom', 'top'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            boundary: popupBoundary,
            padding: {
              bottom: Spacing.L,
            },
          },
        },
      ],
    },
  };

  const renderContent = (): JSX.Element | null => {
    switch (popupType) {
      case CalendarPopupType.None:
        return null;

      case CalendarPopupType.ItemDetail:
        return <ItemDetails onDismiss={onDismiss} />;

      case CalendarPopupType.AgendaListing:
        return <AgendaItemsListingPopup currentDate={value} onDismiss={onDismiss} />;

      default:
        throw UnreachableCaseException(
          popupType,
          `${__filename}: Unknown popup type: ${popupType}`,
        );
    }
  };

  return (
    <DropDownPositioner
      isOptionListVisible={popupType !== CalendarPopupType.None}
      onClickOutside={onDismiss}
      renderContent={renderContent}
      renderSelector={(ref) => (
        <div ref={ref as RefObject<HTMLDivElement>} className="calendar__date-cell">
          {Children.only(children)}
        </div>
      )}
      tippyOptions={tippyOptions}
    />
  );
};

DateCellWrapperWithPopup.displayName = 'DateCellWrapperWithPopup';
