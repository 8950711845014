import { History } from 'history';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { EnvironmentRouteParams, WebhooksRoute } from '../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IWebhookSetting } from '../../../../data/models/webhooks/WebhookSetting.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';
import { IWebhookMessageServerModel } from '../../../../repositories/serverModels/WebhookMessageServerModel.type.ts';
import {
  Webhooks_WebhookMessageListing_LoadFinished,
  Webhooks_WebhookMessageListing_LoadStarted,
} from '../../constants/webhooksActionTypes.ts';

interface IDependencies {
  readonly webhookRepository: IWebhookRepository;
  readonly loadWebhooks: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadEntityWebhooks: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = { type: Webhooks_WebhookMessageListing_LoadStarted } as const;

const finished = (webhookId: Uuid, webhookMessages: IWebhookMessageServerModel[]) =>
  ({
    type: Webhooks_WebhookMessageListing_LoadFinished,
    payload: {
      webhookId,
      webhookMessages,
    },
  }) as const;

export type LoadWebhookMessageListingActionsType = typeof started | ReturnType<typeof finished>;

export const createLoadWebhookMessageListingCreator =
  (dependencies: IDependencies) =>
  (webhookId: Uuid, history: History, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const projectId = getState().sharedApp.currentProjectId;

    dispatch(started);

    await dispatch(dependencies.loadWebhooks(abortSignal));
    await dispatch(dependencies.loadEntityWebhooks(abortSignal));

    const webhooksData = getState().data.webhooks.byId;
    const selectedWebhook = webhooksData.find(
      (webhook: IWebhookSetting) => webhook.id === webhookId,
    );
    const entityWebhooksData = getState().data.entityWebhooks.byId;
    const selectedEntityWebhook = entityWebhooksData.get(webhookId);

    if (!selectedWebhook && !selectedEntityWebhook) {
      history.push(buildPath<EnvironmentRouteParams>(WebhooksRoute, { projectId }));
      return;
    }

    const webhookMessages: IWebhookMessageServerModel[] =
      await dependencies.webhookRepository.getMessages(webhookId, abortSignal);

    dispatch(finished(webhookId, webhookMessages));
  };
