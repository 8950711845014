import { notNull } from '@kontent-ai/utils';
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

const tickInterval = 10;

type Callback = () => void;
type Unsubscribe = () => void;

interface ILayoutSynchronizationContextValue {
  readonly subscribeRead: (callback: Callback) => () => void;
  readonly subscribeUpdate: (callback: Callback) => () => void;
}

const throwOnCall = () => {
  throw new Error(
    'LayoutSynchronizationContext is not available, add LayoutSynchronizationContextProvider as a parent',
  );
};

const defaultContext: ILayoutSynchronizationContextValue = {
  subscribeRead: throwOnCall,
  subscribeUpdate: throwOnCall,
};

export const LayoutSynchronizationContext =
  React.createContext<ILayoutSynchronizationContextValue>(defaultContext);

// We use mutable array to get the best performance in case of huge number of subscribers
// (un)subscribe just modifies the existing array and cleanup is done within execution tick to bulk many unsubscriptions into one filter operation
type Callbacks = Array<Callback | null>;

// Safari doesn't support idle callbacks yet, so we use animation frame to defer the execution
const executeAsync = self.requestIdleCallback ?? self.requestAnimationFrame;

export const LayoutSynchronizationContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const timeoutId = useRef<number | null>(null);

  const readCallbacks = useRef<Callbacks>([]);
  const updateCallbacks = useRef<Callbacks>([]);

  const scheduleNextTick = useCallback(() => {
    // We don't use interval but rather individual timeouts to avoid contention in case CPU can't handle the load induced by the subscribers.
    // This way the browser gets some breathing space in the meantime
    timeoutId.current = window.setTimeout(() => {
      if (readCallbacks.current.length || updateCallbacks.current.length) {
        // We don't invoke the subscribers immediately to avoid unwanted force reflow during user input
        executeAsync(() => {
          const callAndFilter = (subscribedCallbacks: MutableRefObject<Callbacks>): void => {
            let hasUnsubscribed = false;
            subscribedCallbacks.current.forEach((callback) => {
              if (callback) {
                callback();
              } else {
                hasUnsubscribed = true;
              }
            });

            if (hasUnsubscribed) {
              subscribedCallbacks.current = subscribedCallbacks.current.filter(notNull);
            }
          };
          callAndFilter(readCallbacks);
          callAndFilter(updateCallbacks);
          scheduleNextTick();
        });
      } else {
        timeoutId.current = null;
      }
    }, tickInterval);
  }, []);

  const subscribe = useCallback(
    (callbacks: Callbacks, callback: Callback): Unsubscribe => {
      callbacks.push(callback);

      if (!timeoutId.current) {
        // Start the tick with a first subscriber
        scheduleNextTick();
      }

      const unsubscribe = (subscribedCallbacks: Callbacks, cb: Callback): void => {
        const index = subscribedCallbacks.indexOf(cb);
        if (index >= 0) {
          subscribedCallbacks[index] = null;
        }
      };

      return () => unsubscribe(callbacks, callback);
    },
    [scheduleNextTick],
  );

  const value = useMemo(
    () => ({
      subscribeRead: (callback: Callback): (() => void) =>
        subscribe(readCallbacks.current, callback),
      subscribeUpdate: (callback: Callback): (() => void) =>
        subscribe(updateCallbacks.current, callback),
    }),
    [subscribe],
  );

  useEffect(
    // Cleanup
    () => () => {
      if (timeoutId.current) {
        window.clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    },
    [],
  );

  return (
    <LayoutSynchronizationContext.Provider value={value}>
      {children}
    </LayoutSynchronizationContext.Provider>
  );
};

LayoutSynchronizationContextProvider.displayName = 'LayoutSynchronizationContextProvider';

const useTick = (subscribe: (callback: Callback) => Unsubscribe, callback: Callback): void => {
  const savedCallback = useRef<Callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const stableCallback = () => savedCallback.current?.();
    const unsubscribe = subscribe(stableCallback);
    return unsubscribe;
  }, [subscribe]);
};

export const useLayoutSynchronizationReadTick = (callback: Callback): void => {
  const { subscribeRead } = useContext(LayoutSynchronizationContext);
  useTick(subscribeRead, callback);
};

export const useLayoutSynchronizationUpdateTick = (callback: Callback): void => {
  const { subscribeUpdate } = useContext(LayoutSynchronizationContext);
  useTick(subscribeUpdate, callback);
};
