import { Action } from '../../../../@types/Action.type.ts';
import { Webhooks_OnboardingStep_Updated } from '../../constants/webhooksActionTypes.ts';

const defaultStep = 1;

export const newWebhooksOnboardingStep = (step: number = defaultStep, action: Action): number => {
  switch (action.type) {
    case Webhooks_OnboardingStep_Updated: {
      return action.payload.step;
    }

    default:
      return step;
  }
};
