const TrimStartingZeroesRegex = /^(-?)0+(?!\.|,|$)(.*)/;
const TrimEndingZeroesRegex = /^((-?)\d+[.,]\d*?)(0+)$/;
const TrimTrailingPoint = /[.,]$/;
const TrimMinusForZero = /-(0)$/;
const EnsureLeadingZero = /^(?<minus>-?)(?<dot>\.)/;

export function normalizeNumberString(numberValue: string): string {
  return numberValue
    .replace(TrimStartingZeroesRegex, '$1$2')
    .replace(TrimEndingZeroesRegex, '$1')
    .replace(TrimTrailingPoint, '')
    .replace(TrimMinusForZero, '$1')
    .replace(',', '.')
    .replace(EnsureLeadingZero, '$<minus>0$<dot>');
}

export function getNumberOfDecimalPlaces(num: string): number {
  const match = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/.exec(num);

  if (!match) {
    return 0;
  }

  const decimalPlaces = Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0),
  );

  // We support at most 10 decimal digits
  return decimalPlaces > 10 ? 10 : decimalPlaces;
}

export function isNumberTooLarge(numberValue: string): boolean {
  const trimmedNumber = normalizeNumberString(numberValue);
  const parsedNumber = Number.parseFloat(numberValue.replace(',', '.'));
  const numberInFixedFormat = parsedNumber.toFixed(getNumberOfDecimalPlaces(numberValue));

  return normalizeNumberString(numberInFixedFormat) !== trimmedNumber;
}
