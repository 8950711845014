import React, { RefObject, useContext, useMemo } from 'react';

type PortalContainerContext = {
  readonly portalContainerRef: RefObject<HTMLElement>;
  readonly overlayPortalContainerRef: RefObject<HTMLElement>;
};

const defaultPortalContainerElement = document.body;
const defaultPortalContainerContext: PortalContainerContext = {
  portalContainerRef: { current: defaultPortalContainerElement },
  overlayPortalContainerRef: { current: defaultPortalContainerElement },
};

export const PortalContainerContext = React.createContext<PortalContainerContext>(
  defaultPortalContainerContext,
);

type PortalContainerContextProviderProps = {
  readonly portalContainerRef: RefObject<HTMLElement>;
};

type RootPortalContainerContextProviderProps = PortalContainerContextProviderProps & {
  readonly overlayPortalContainerRef: RefObject<HTMLElement>;
};

/**
 * Provide reference for appending elements that we want rendered outside of the DOM hierarchy of the parent component.
 * This is usually done using React portal or Tippy — e.g. Tooltip, WorkflowStatus, etc.
 *
 * By default, we want these appended to the RootPortalContainer. You might want to change this behavior, for example to render Tooltip
 * inside the DOM of the Popover component.
 * @param portalContainerRef
 * @param children
 */
export const PortalContainerContextProvider: React.FC<
  React.PropsWithChildren<PortalContainerContextProviderProps>
> = ({ portalContainerRef, children }) => {
  const { overlayPortalContainerRef } = useContext(PortalContainerContext);

  const value = useMemo(
    () => ({
      portalContainerRef,
      overlayPortalContainerRef,
    }),
    [portalContainerRef, overlayPortalContainerRef],
  );

  return (
    <PortalContainerContext.Provider value={value}>{children}</PortalContainerContext.Provider>
  );
};

/**
 * This component should be used only once at the "root" of the app!
 *
 * Provide reference for appending elements that we want rendered outside of the DOM hierarchy of the parent component.
 * This is usually done using React portal or Tippy.
 * @param portalContainerRef
 * @param overlayPortalContainerRef — for OverlayContainer, used in Overlay component (ModalDialog and FullScreenModalDialog)
 * @param children
 */
export const RootPortalContainerContextProvider: React.FC<
  React.PropsWithChildren<RootPortalContainerContextProviderProps>
> = ({ portalContainerRef, overlayPortalContainerRef, children }) => {
  const { overlayPortalContainerRef: prevOverlayPortalContainerRef } =
    useContext(PortalContainerContext);

  if (
    overlayPortalContainerRef &&
    prevOverlayPortalContainerRef.current !== defaultPortalContainerElement
  ) {
    throw Error(
      `${RootPortalContainerContextProvider.displayName} should be used only once. You can’t set 'overlayPortalContainerRef' after it’s been set already. `,
    );
  }

  const value = useMemo(
    () => ({
      portalContainerRef,
      overlayPortalContainerRef: overlayPortalContainerRef
        ? overlayPortalContainerRef
        : prevOverlayPortalContainerRef,
    }),
    [portalContainerRef, overlayPortalContainerRef, prevOverlayPortalContainerRef],
  );

  return (
    <PortalContainerContext.Provider value={value}>{children}</PortalContainerContext.Provider>
  );
};
