import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { useCallback } from 'react';
import { buildAssetLink } from '../../../applications/itemEditor/features/ContentItemEditing/utils/renditionUtils.ts';
import { IImageTransformation } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { TrackUserEventWithData } from '../../models/TrackUserEvent.type.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';

type AssetDownloadButtonProps = {
  readonly asset: IAsset;
  readonly trackUserEvent?: TrackUserEventWithData;
  readonly transformation?: IImageTransformation;
};

export const AssetDownloadButton: React.FC<AssetDownloadButtonProps> = ({
  asset,
  trackUserEvent,
  transformation,
}) => {
  const trackAssetDownload = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      event.stopPropagation();
      if (trackUserEvent) {
        trackUserEvent(TrackedEvent.AssetDownloaded, {
          size: asset.fileSize || 0,
          type: asset.type,
          'asset-id': asset.id,
        });
      }
    },
    [asset, trackUserEvent],
  );

  if (!asset._downloadLink) {
    return null;
  }

  return (
    <Tooltip
      tooltipText={transformation ? 'Download customized image' : 'Download'}
      placement="bottom"
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        download={asset.filename}
        href={buildAssetLink(asset._downloadLink, transformation ?? null)}
        onClick={trackAssetDownload}
        className="asset-thumbnail__action asset-thumbnail__action--download"
      >
        <Icon iconName={IconName.ArrowDownLine} />
      </a>
    </Tooltip>
  );
};

AssetDownloadButton.displayName = 'AssetDownloadButton';
