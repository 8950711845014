import { IRole } from '../../../../data/models/roles/IRole.ts';
import { ICustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';

export const canCreateNewCustomRole = (limitInfo: ICustomRolesLimitInfo): boolean =>
  limitInfo.customRoleLimit === null || limitInfo.customRoleCount < limitInfo.customRoleLimit;

export const areCustomRolesOverLimit = (limitInfo: ICustomRolesLimitInfo): boolean =>
  limitInfo.customRoleLimit !== null && limitInfo.customRoleCount > limitInfo.customRoleLimit;

export const getNumberOfCustomRolesOverLimit = (limitInfo: ICustomRolesLimitInfo): number => {
  if (limitInfo.customRoleLimit === null) {
    return 0;
  }

  const overLimit = limitInfo.customRoleCount - limitInfo.customRoleLimit;

  return Math.max(0, overLimit);
};

export const getCustomRolesCount = (roles: ReadonlyMap<Uuid, IRole>): number =>
  [...roles.values()].filter((roleItem) => !roleItem.isReadonly).length;
