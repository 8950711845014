import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isElementEditableInVariant } from '../../../utils/itemElementUtils.ts';

export const useIsElementEditableInEditedVariant = (typeElement: TypeElement): boolean => {
  const editedVariantId = useSelector(
    (state) => state.contentApp.editedContentItemVariant?.id.variantId,
  );
  return isElementEditableInVariant(typeElement, editedVariantId);
};
