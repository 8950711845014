import { differenceInDays } from 'date-fns';

export const daysSinceDateTimeStamp = (timestamp: DateTimeStamp | null | undefined): number => {
  return daysSinceDate(timestamp ? new Date(timestamp) : null);
};

export const daysSinceDate = (timestamp: Date | null | undefined): number => {
  if (!timestamp) {
    return 0;
  }

  const now = new Date();
  return differenceInDays(now, timestamp);
};
