import { Dispatch, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { clearTypeEditorErrorMessage } from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import { typeSnippetElementUpdated } from '../snippetsActions.ts';

interface IDeps {
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createUpdateTypeSnippetElementAction =
  (deps: IDeps) =>
  (updatedTypeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch: Dispatch, getState): void => {
    dispatch(deps.validateTypeElement(updatedTypeElement));
    if (isTypeValid(getState().contentModelsApp.typeEditor.validationResults)) {
      dispatch(clearTypeEditorErrorMessage());
    }

    dispatch(typeSnippetElementUpdated(updatedTypeElement));
  };
