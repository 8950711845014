import { px } from '../utils/utils.ts';
import { FontFamily } from './fontFamily.ts';
import { FontSize } from './fontSize.ts';
import { FontWeight } from './fontWeight.ts';
import { LineHeight } from './lineHeight.ts';

export const LetterSpacing = {
  M: '0.06ch',
  L: '0.1ch',
} as const;

export type LetterSpacing = (typeof LetterSpacing)[keyof typeof LetterSpacing];

const uppercase = 'uppercase';

const HeadlineLarge = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.XL),
  fontWeight: FontWeight.Bold,
  lineHeight: px(LineHeight.XXL),
} as const;

const HeadlineMedium = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.L),
  fontWeight: FontWeight.Bold,
  lineHeight: px(LineHeight.XL),
} as const;

const HeadlineSmall = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Bold,
  lineHeight: px(LineHeight.M),
} as const;

const Subheadline = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.M),
} as const;

const SubheadlineLarge = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.L),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.L),
} as const;

const TitleLarge = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.M),
} as const;

const TitleMedium = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.M),
} as const;

const TitleSmall = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.XS),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.S),
} as const;

const BodyLarge = {
  fontFamily: FontFamily.ProductSecondary,
  fontSize: px(FontSize.L),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.XL),
} as const;

const BodyMedium = {
  fontFamily: FontFamily.ProductSecondary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.L),
} as const;

const BodySmall = {
  fontFamily: FontFamily.ProductSecondary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.M),
} as const;

const BodyLargeEmphasis = {
  fontFamily: FontFamily.ProductSecondary,
  fontSize: px(FontSize.L),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.XL),
} as const;

const BodyMediumEmphasis = {
  fontFamily: FontFamily.ProductSecondary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.L),
} as const;

const BodySmallEmphasis = {
  fontFamily: FontFamily.ProductSecondary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.M),
} as const;

const LabelLarge = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.M),
} as const;

const LabelMedium = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.M),
} as const;

const LabelSmall = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.XS),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.XS),
} as const;

const LabelLargeEmphasis = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.M),
} as const;

const LabelMediumEmphasis = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.M),
} as const;

const LabelSmallEmphasis = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.XS),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.XS),
} as const;

const Caption = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.XS),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.XS),
} as const;

const ActionLarge = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.M),
  fontWeight: FontWeight.Bold,
  lineHeight: px(LineHeight.XL),
  letterSpacing: LetterSpacing.L,
  textTransform: uppercase,
} as const;

const ActionMedium = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Bold,
  lineHeight: px(LineHeight.M),
  letterSpacing: LetterSpacing.L,
  textTransform: uppercase,
} as const;

const ActionSmall = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.S),
  fontWeight: FontWeight.Medium,
  lineHeight: px(LineHeight.M),
  letterSpacing: LetterSpacing.L,
  textTransform: uppercase,
} as const;

const UIParagraph = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.L),
  fontWeight: FontWeight.Regular,
  lineHeight: px(LineHeight.XL),
} as const;

const PageTitle = {
  fontFamily: FontFamily.ProductPrimary,
  fontSize: px(FontSize.XXL),
  fontWeight: FontWeight.Bold,
  lineHeight: px(LineHeight.XXXL),
} as const;

export const Typography = {
  HeadlineLarge,
  HeadlineMedium,
  HeadlineSmall,
  Subheadline,
  SubheadlineLarge,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  BodyLarge,
  BodyMedium,
  BodySmall,
  BodyLargeEmphasis,
  BodyMediumEmphasis,
  BodySmallEmphasis,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelLargeEmphasis,
  LabelMediumEmphasis,
  LabelSmallEmphasis,
  Caption,
  ActionLarge,
  ActionMedium,
  ActionSmall,
  UIParagraph,
  PageTitle,
} as const;

export type Typography = (typeof Typography)[keyof typeof Typography];
