import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { saveCalendarArrangement } from '../actions/thunkCalendarActions.ts';
import {
  CalendarArrangementSwitcher as CalendarArrangementSwitcherComponent,
  ICalendarArrangementSwitcherDispatchProps,
  ICalendarArrangementSwitcherStateProps,
} from '../components/CalendarArrangementSwitcher.tsx';
import { CalendarArrangement } from '../models/CalendarArrangement.ts';

const mapStateToProps = ({
  calendarApp: { selectedArrangement },
}: IStore): ICalendarArrangementSwitcherStateProps => ({
  selectedArrangement,
});

const mapDispatchToProps = (dispatch: Dispatch): ICalendarArrangementSwitcherDispatchProps => ({
  onArrangementChange: (selectedArrangement: CalendarArrangement) =>
    dispatch(saveCalendarArrangement(selectedArrangement)),
});

export const CalendarArrangementSwitcher = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarArrangementSwitcherComponent);
