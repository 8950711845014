import { Action } from '../../../../../@types/Action.type.ts';
import { IApprovedSuggestion } from '../../../../richText/plugins/comments/api/editorSuggestionUtils.ts';
import {
  ContentItemEditing_SuggestionApprovalInitiated,
  ContentItemEditing_SuggestionApproved,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlyArray<IApprovedSuggestion> = [];

export function approvedSuggestions(
  state: ReadonlyArray<IApprovedSuggestion> = initialState,
  action: Action,
): ReadonlyArray<IApprovedSuggestion> {
  switch (action.type) {
    case ContentItemEditing_SuggestionApproved: {
      const { commentThread, suggestionId } = action.payload;
      return state.filter(
        (s: IApprovedSuggestion) =>
          s.commentThreadId !== commentThread.id || s.suggestionId !== suggestionId,
      );
    }

    case ContentItemEditing_SuggestionApprovalInitiated: {
      const { commentThreadId, suggestionId, suggestedText } = action.payload;
      return [
        ...state,
        {
          commentThreadId,
          suggestionId,
          suggestedText,
        },
      ];
    }

    default:
      return state;
  }
}
