import { isElement } from '@kontent-ai/DOM';
import { useAttachRef } from '@kontent-ai/hooks';
import { Identifier } from 'dnd-core';
import React, { useCallback, useMemo } from 'react';
import { DropTargetHookSpec, DropTargetMonitor, useDrop } from 'react-dnd';
import { moveItemThrottled } from '../../utils/dragDrop/dragDropUtils.ts';
import {
  CollectedProps,
  DragMoveHandler,
  DragObject,
  HoveringCollisionStrategy,
} from './dragDrop.type.ts';

type DropTargetProps<TDropTargetElement extends HTMLElement> = {
  readonly accept: OneOrMore<Identifier>;
  readonly canDrop: boolean;
  readonly canDropToSelf?: boolean;
  readonly contentRef?: React.Ref<TDropTargetElement>;
  readonly hoveringCollisionStrategy: HoveringCollisionStrategy;
  readonly onMove: DragMoveHandler;
  readonly parentId: string;
  readonly renderDroppable: (ref: React.Ref<TDropTargetElement>) => React.ReactElement;
  readonly targetId: string;
};

export function DropTarget<TDropTargetElement extends HTMLElement>({
  accept,
  canDrop,
  canDropToSelf,
  contentRef,
  hoveringCollisionStrategy,
  onMove,
  parentId,
  renderDroppable,
  targetId,
}: DropTargetProps<TDropTargetElement>) {
  const { refObject: dropTargetRefObject, refToForward: dropTargetRefToForward } =
    useAttachRef<TDropTargetElement>(contentRef);

  const _canDrop = useCallback(
    (dragObject: DragObject): boolean => {
      const areBlocksSiblings = parentId === dragObject.parentId;
      return canDrop && areBlocksSiblings;
    },
    [canDrop, parentId],
  );

  const hover = useCallback(
    (dragObject: DragObject, monitor: DropTargetMonitor): void => {
      if (parentId !== dragObject.parentId) {
        return;
      }

      const pointer = monitor.getClientOffset();
      const dropTargetElement = dropTargetRefObject.current;

      if (
        !canDrop ||
        !dragObject.sourceId ||
        (dragObject.sourceId === targetId && !canDropToSelf) ||
        !isElement(dropTargetElement) ||
        !pointer
      ) {
        return;
      }

      const targetBoundingRect = dropTargetElement.getBoundingClientRect();

      const props = {
        monitor,
        pointer,
        sourceId: dragObject.sourceId,
        targetBoundingRect,
        targetId,
      };
      if (!hoveringCollisionStrategy(props)) {
        return;
      }

      moveItemThrottled(onMove, {
        pointer,
        sourceId: dragObject.sourceId,
        targetBoundingRect,
        targetId,
      });
    },
    [
      parentId,
      dropTargetRefObject,
      canDrop,
      targetId,
      canDropToSelf,
      hoveringCollisionStrategy,
      onMove,
    ],
  );

  const dropOptions = useMemo(
    (): DropTargetHookSpec<DragObject, never, CollectedProps> => ({
      accept: accept as Mutable<typeof accept>,
      canDrop: _canDrop,
      hover,
    }),
    [accept, _canDrop, hover],
  );

  const [, connectDropTarget] = useDrop<DragObject, never, CollectedProps>(dropOptions);

  const content = renderDroppable(dropTargetRefToForward);

  return connectDropTarget(content);
}

DropTarget.displayName = 'DropTarget';
