import { Action } from '../../../../../@types/Action.type.ts';
import {
  LocalizationEditor_Init_Started,
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangCreator_Show,
  LocalizationEditor_LangEditor_Hide,
  LocalizationEditor_LangEditor_Show,
  LocalizationEditor_Language_Activate,
  LocalizationEditor_Language_Add,
  LocalizationEditor_Language_Deactivate,
  LocalizationEditor_Language_Update,
  LocalizationEditor_LanguagesLoading_Success,
} from '../../constants/localizationActionTypes.ts';

export const editedLanguageId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case LocalizationEditor_LangEditor_Show:
      return action.payload.id;

    case LocalizationEditor_LangCreator_Show: {
      const { language } = action.payload;
      return language.id;
    }

    case LocalizationEditor_Init_Started:
    case LocalizationEditor_Language_Add:
    case LocalizationEditor_Language_Update:
    case LocalizationEditor_LangCreator_Hide:
    case LocalizationEditor_LangEditor_Hide:
    case LocalizationEditor_Language_Deactivate:
    case LocalizationEditor_Language_Activate:
    case LocalizationEditor_LanguagesLoading_Success:
      return null;

    default:
      return state;
  }
};
