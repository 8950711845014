import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { getNewContentItemDialogProperties } from './newContentItemDialog.ts';

export const hasNewContentItemFormAvailableContentTypes = (state: IStore): boolean => {
  if (getNewContentItemDialogProperties(state).origin === NewContentItemOrigin.Space) {
    return true;
  }

  const availableContentTypes = state.data.contentTypes.byId;

  return !availableContentTypes.isEmpty();
};
