import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import React, { RefAttributes, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside.ts';
import { stopPropagation } from '../../utils/func/functionalTools.ts';
import { HotkeysHandler } from '../Hotkeys/HotkeysHandler.tsx';
import { ITriggerProps } from './types/PopoverDialogContract.type.ts';

export const PopoverPlacement = {
  Sibling: 'sibling',
  Child: 'child',
};

interface IPopoverDialogOwnProps {
  readonly popoverClass: string;
  readonly popoverPlacement: string;
  readonly trigger: (injectedProps: ITriggerProps & RefAttributes<HTMLDivElement>) => JSX.Element;
  readonly popover: (togglePopover: () => void) => JSX.Element;
}

export const PopoverDialog: React.FC<IPopoverDialogOwnProps> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const innerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const toggle = () => setIsActive((prevState) => !prevState);
  const hidePopover = () => setIsActive(false);

  useOnClickOutside([innerRef, popoverRef], hidePopover);

  const transitions = useTransition(isActive, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const popoverAnimationWrapper = transitions(
    (style, item) =>
      item && (
        <animated.div
          style={style}
          ref={popoverRef}
          className={classNames('popover__dialog', props.popoverClass)}
          onClick={stopPropagation}
        >
          <HotkeysHandler
            handlers={{
              onEscape: hidePopover,
            }}
          >
            {props.popover(hidePopover)}
          </HotkeysHandler>
        </animated.div>
      ),
  );

  return (
    <>
      {props.trigger({
        popover: props.popoverPlacement === PopoverPlacement.Child ? popoverAnimationWrapper : null,
        isActive,
        onToggle: toggle,
        ref: innerRef,
      })}
      {props.popoverPlacement === PopoverPlacement.Child ? null : popoverAnimationWrapper}
    </>
  );
};

PopoverDialog.displayName = 'PopoverDialog';
