import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { Data_ListingContentItems_FullTextSearchTurnedOn } from '../../../../../../data/constants/dataActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { ContentListing_FullTextSearchContext_Changed } from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { IFullTextSearchContext } from '../../../stores/IContentAppStoreState.ts';

const initialState: IFullTextSearchContext | null = null;

export const fullTextSearchContext = (
  state: IFullTextSearchContext | null = initialState,
  action: Action,
): IFullTextSearchContext | null => {
  switch (action.type) {
    case ContentListing_FullTextSearchContext_Changed:
    case Data_ListingContentItems_FullTextSearchTurnedOn:
      return action.payload.fullTextSearchContext;

    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
};
