import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForAssets } from '../../../../../_shared/selectors/contentCollections.ts';
import { isDefaultAssetTypeCustomized } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { AssetListingActionPane as AssetListingActionPaneComponent } from '../../components/AssetListing/AssetListingActionPane/AssetListingActionPane.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import {
  cannotDeleteSelectedAssets,
  cannotEditSelectedAssets,
  selectedAssetsMaxCount,
  tooManyAssetsSelectedMessage,
} from '../../constants/uiConstants.ts';
import { updateInProgressActions } from '../../constants/updateInProgressActions.ts';
import { useArchiveAssetFolder } from '../../hooks/useArchiveAssetFolder.ts';
import { useArchiveSelectedAssets } from '../../hooks/useArchiveSelectedAssets.ts';
import { getSubFolderIdsOfOpenedAssetFolder } from '../../selectors/getOpenedAssetFolder.ts';
import { hasPermissionsForAssets } from '../../selectors/hasPermissionsForAssets.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { getParentFolderId } from '../../utils/getParentFolderId.ts';

type Props = {
  readonly clearAssetSelection: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly orderBy: AssetsOrderBy;
};

export const AssetListingActionPane: React.FC<Props> = ({
  clearAssetSelection,
  deselectAssets,
  orderBy,
  selectedAssets,
}) => {
  const isAnyAssetSelected = selectedAssets.size > 0;
  const tooManySelectedAssets = selectedAssets.size > selectedAssetsMaxCount;

  const isDefaultAssetTypeModified = useSelector((s) =>
    isDefaultAssetTypeCustomized(s.data.assetTypes.defaultAssetType),
  );
  const showAssignTermsButton = isAnyAssetSelected && isDefaultAssetTypeModified;

  const anyFoldersExist = useSelector((s) => !!s.data.assetFolders.taxonomyGroup.terms.size);
  const showMoveAssetsToFolderButton = isAnyAssetSelected && anyFoldersExist;

  const areCollectionsVisible = useSelector((s) =>
    areCollectionsVisibleForAssets(s, Collection.getValues(s.data.collections.byId)),
  );
  const showMoveAssetsToCollectionButton = isAnyAssetSelected && areCollectionsVisible;

  const hasUpdateAssetsCapability = useSelector((state) =>
    currentUserHasCapabilities(state, Capability.UpdateAssets),
  );
  const isAnyFolderOpened = useSelector(
    (state) => state.assetLibraryApp.openedFolderId !== RootFolderId,
  );
  const showFolderActionButtons =
    hasUpdateAssetsCapability && !isAnyAssetSelected && isAnyFolderOpened;

  const anyAssetsAreListed = useSelector((s) => !s.data.assets.listingAssets.listingIds.isEmpty());
  const currentFolderContainsFolders = useSelector(
    (state) => !!getSubFolderIdsOfOpenedAssetFolder(state).length,
  );
  const isDeleteConfirmationRequired = anyAssetsAreListed || currentFolderContainsFolders;

  const isAssetSelectorOpened = useSelector((s) => s.assetLibraryApp.isAssetSelectorOpened);
  const archiveAssetFolder = useArchiveAssetFolder({
    isInAssetSelector: isAssetSelectorOpened,
    orderBy,
  });

  const openedFolderId = useSelector((s) => s.assetLibraryApp.openedFolderId);
  const parentFolderId = useSelector((s) =>
    getParentFolderId(s.data.assetFolders.taxonomyGroup, s.assetLibraryApp.openedFolderId),
  );
  const onDeleteAssetFolder = showFolderActionButtons
    ? () => archiveAssetFolder({ folderId: openedFolderId, parentFolderId })
    : null;

  const archiveSelectedAssets = useArchiveSelectedAssets({
    deselectAssets,
    orderBy,
    selectedAssets,
  });
  const onDeleteAssets = isAnyAssetSelected ? archiveSelectedAssets : null;

  const isUpdateActionInProgress = useSelector((s) =>
    updateInProgressActions.includes(s.assetLibraryApp.lastAction.type),
  );
  const hasDeletePermissionForSelectedAssets = useSelector((s) =>
    hasPermissionsForAssets(s.data.assets.byId, selectedAssets, ActiveCapabilityType.DeleteAssets),
  );

  const deleteActionTooltip = tooManySelectedAssets
    ? tooManyAssetsSelectedMessage
    : hasDeletePermissionForSelectedAssets
      ? undefined
      : cannotDeleteSelectedAssets;

  const hasUpdatePermissionForSelectedAssets = useSelector((s) =>
    hasPermissionsForAssets(s.data.assets.byId, selectedAssets, ActiveCapabilityType.UpdateAssets),
  );

  const editActionTooltip = tooManySelectedAssets
    ? tooManyAssetsSelectedMessage
    : hasUpdatePermissionForSelectedAssets
      ? undefined
      : cannotEditSelectedAssets;

  const folderActionTooltip = tooManySelectedAssets ? tooManyAssetsSelectedMessage : undefined;

  return (
    <AssetListingActionPaneComponent
      clearAssetSelection={clearAssetSelection}
      deleteActionTooltip={deleteActionTooltip}
      deselectAssets={deselectAssets}
      editActionTooltip={editActionTooltip}
      folderActionTooltip={folderActionTooltip}
      isDeleteConfirmationRequired={isDeleteConfirmationRequired}
      isInAssetSelector={isAssetSelectorOpened}
      isUpdateActionInProgress={isUpdateActionInProgress}
      onDeleteAssetFolder={onDeleteAssetFolder}
      onDeleteAssets={onDeleteAssets}
      orderBy={orderBy}
      selectedAssets={selectedAssets}
      showAssignTermsButton={showAssignTermsButton}
      showMoveAssetsToCollectionButton={showMoveAssetsToCollectionButton}
      showMoveAssetsToFolderButton={showMoveAssetsToFolderButton}
      showMoveFolderButton={showFolderActionButtons}
    />
  );
};
