import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem, RenderSelectMenuOptionProps } from '@kontent-ai/component-library/Selects';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import { DefaultTag } from '../../../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../../../../component-library/components/Tag/Tag.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getTaxonomyTermByIdOrThrow,
  renderTaxonomyTermPath,
} from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { DeletedTaxonomyTermName } from '../../../../../../contentModels/taxonomy/utils/deletedTaxonomyUtils.ts';
import { areItemsEqual } from '../../../utils/selectItemUtils.ts';

type TaxonomySelectorProps = {
  readonly disabled: boolean;
  readonly onSelectionChange?: (selectedIds: ReadonlySet<Uuid>) => void;
  readonly selectedTermIds: ReadonlySet<Uuid>;
  readonly taxonomyGroup: ITaxonomyGroup;
  readonly tooltipText?: string;
};

interface ITaxonomyTerm extends ISelectItem<ITaxonomyTerm> {
  readonly isDeleted: boolean;
  readonly path: ReadonlyArray<string>;
}

const emptyPath: ReadonlyArray<string> = [];

const toSelectItem = (
  taxonomyGroup: ITaxonomyGroup,
  termId: Uuid,
  parentPath: ReadonlyArray<string>,
): ITaxonomyTerm => {
  const term = getTaxonomyTermByIdOrThrow(taxonomyGroup, termId);
  const path = [...parentPath, term.name];
  return {
    id: term.id,
    label: term.name,
    type: 'item',
    items: term.childIds.map((id) => toSelectItem(taxonomyGroup, id, path)),
    isDeleted: false,
    path,
  };
};

const getDeletedTerm = (id: Uuid): ITaxonomyTerm => ({
  id,
  label: DeletedTaxonomyTermName,
  isDeleted: true,
  path: emptyPath,
});

const renderMenuOption = (optionProps: RenderSelectMenuOptionProps<ITaxonomyTerm>) =>
  optionProps.item.value?.isDeleted ? '' : undefined;

const renderSelectedOption = (
  _: Uuid,
  item: ITaxonomyTerm,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) =>
  item.isDeleted ? (
    <Tag
      {...defaultTagProps}
      customTooltipText={renderTaxonomyTermPath(item.path)}
      background={colorAlertBackgroundInverse}
    />
  ) : (
    <DefaultTag {...defaultTagProps} customTooltipText={renderTaxonomyTermPath(item.path)} />
  );

export const CompactTaxonomySelector: React.FC<TaxonomySelectorProps> = ({
  disabled,
  onSelectionChange,
  selectedTermIds,
  taxonomyGroup,
  tooltipText,
}) => {
  const [allTerms, setAllTerms] = useState<ReadonlyArray<ITaxonomyTerm>>([]);

  const existingTerms = useMemo(
    () => taxonomyGroup.childIds.map((id) => toSelectItem(taxonomyGroup, id, emptyPath)),
    [taxonomyGroup],
  );

  useEffect(() => {
    const deletedTerms = [...selectedTermIds]
      .filter((id) => !taxonomyGroup.terms.has(id))
      .map(getDeletedTerm);

    const newAllTerms = [...deletedTerms, ...existingTerms];

    if (!areItemsEqual(allTerms, newAllTerms)) {
      setAllTerms(newAllTerms);
    }
  }, [allTerms, existingTerms, selectedTermIds, taxonomyGroup]);

  return (
    <MultiSelect<ITaxonomyTerm>
      inputState={disabled ? InputState.ReadOnly : InputState.Default}
      items={allTerms}
      onSelectionChange={onSelectionChange}
      placeholder={disabled ? 'There is no taxonomy term selected' : 'Select a taxonomy term'}
      renderMenuOption={renderMenuOption}
      renderSelectedOption={renderSelectedOption}
      selectedItemIds={selectedTermIds}
      tooltipText={tooltipText}
      tooltipPlacement="right"
      {...getDataUiCollectionAttribute(DataUiCollection.TaxonomyTerms)}
    />
  );
};

CompactTaxonomySelector.displayName = 'CompactTaxonomySelector';
