import { IconName } from '@kontent-ai/component-library/Icons';
import { ISelectItem } from '@kontent-ai/component-library/Selects';

export interface IWebSpotlightPreviewResolution {
  readonly width: number;
  readonly height: number;
  readonly scale: number;
}

export const fitToScreenResolution: IWebSpotlightPreviewResolution = {
  width: 0,
  height: 0,
  scale: 1,
};

export enum WebSpotlightPreviewResolutionType {
  Desktop = 'desktop',
  FitScreen = 'fit-screen',
  Mobile = 'mobile',
  Responsive = 'responsive',
  Tablet = 'tablet',
}

export type WebSpotlightPredefinedPreviewResolutionType = Exclude<
  WebSpotlightPreviewResolutionType,
  WebSpotlightPreviewResolutionType.FitScreen | WebSpotlightPreviewResolutionType.Responsive
>;

export interface IWebSpotlightPreviewResolutionTypeOption
  extends ISelectItem<IWebSpotlightPreviewResolutionTypeOption> {
  readonly id: WebSpotlightPreviewResolutionType;
  readonly label: string;
  readonly iconName: IconName;
  readonly complementaryText?: string;
}

export const webSpotlightPredefinedPreviewResolutions: ReadonlyRecord<
  WebSpotlightPredefinedPreviewResolutionType,
  IWebSpotlightPreviewResolution
> = {
  [WebSpotlightPreviewResolutionType.Desktop]: { width: 1920, height: 1080, scale: 1 },
  [WebSpotlightPreviewResolutionType.Tablet]: { width: 768, height: 1024, scale: 1 },
  [WebSpotlightPreviewResolutionType.Mobile]: { width: 360, height: 800, scale: 1 },
};

export const webSpotlightPreviewResolutionTypeIcons: ReadonlyRecord<string, IconName> = {
  [WebSpotlightPreviewResolutionType.Desktop]: 'Desktop',
  [WebSpotlightPreviewResolutionType.Tablet]: 'Tablet',
  [WebSpotlightPreviewResolutionType.Mobile]: 'Mobile',
  [WebSpotlightPreviewResolutionType.Responsive]: 'Expand',
  [WebSpotlightPreviewResolutionType.FitScreen]: 'ArrowsCorners',
};

export const webSpotlightPreviewResolutionTypeLabels: ReadonlyRecord<
  WebSpotlightPreviewResolutionType,
  string
> = {
  [WebSpotlightPreviewResolutionType.Desktop]: 'Desktop',
  [WebSpotlightPreviewResolutionType.Tablet]: 'Tablet',
  [WebSpotlightPreviewResolutionType.Mobile]: 'Mobile',
  [WebSpotlightPreviewResolutionType.Responsive]: 'Responsive',
  [WebSpotlightPreviewResolutionType.FitScreen]: 'Fit screen',
};

export const webSpotlightPreviewResolutionTypeOptions: ReadonlyArray<IWebSpotlightPreviewResolutionTypeOption> =
  [
    {
      id: WebSpotlightPreviewResolutionType.Desktop,
      label: webSpotlightPreviewResolutionTypeLabels[WebSpotlightPreviewResolutionType.Desktop],
      iconName: 'Desktop',
      complementaryText: '1920 x 1080',
    },
    {
      id: WebSpotlightPreviewResolutionType.Tablet,
      label: webSpotlightPreviewResolutionTypeLabels[WebSpotlightPreviewResolutionType.Tablet],
      iconName: 'Tablet',
      complementaryText: '768 x 1024',
    },
    {
      id: WebSpotlightPreviewResolutionType.Mobile,
      label: webSpotlightPreviewResolutionTypeLabels[WebSpotlightPreviewResolutionType.Mobile],
      iconName: 'Mobile',
      complementaryText: '360 x 800',
    },
    {
      id: WebSpotlightPreviewResolutionType.Responsive,
      label: webSpotlightPreviewResolutionTypeLabels[WebSpotlightPreviewResolutionType.Responsive],
      iconName: 'Expand',
      complementaryText: 'Resize manually',
    },
    {
      id: WebSpotlightPreviewResolutionType.FitScreen,
      label: webSpotlightPreviewResolutionTypeLabels[WebSpotlightPreviewResolutionType.FitScreen],
      iconName: 'ArrowsCorners',
    },
  ];

export const isPredefinedResolutionType = (
  type: WebSpotlightPreviewResolutionType,
): type is WebSpotlightPredefinedPreviewResolutionType =>
  [
    WebSpotlightPreviewResolutionType.Desktop,
    WebSpotlightPreviewResolutionType.Tablet,
    WebSpotlightPreviewResolutionType.Mobile,
  ].includes(type);
