import { Action } from '../../../../@types/Action.type.ts';
import {
  SmartLink_HandleSuccessfulRedirect,
  SmartLink_HandleSuccessfulRedirectToContentItem,
  SmartLink_HandleUnsuccessfulRedirect,
} from '../../constants/smartLinkActionTypes.ts';

export const linkLocation = (state: string | null = null, action: Action): string | null => {
  switch (action.type) {
    case SmartLink_HandleUnsuccessfulRedirect:
      return action.payload.linkLocation;

    case SmartLink_HandleSuccessfulRedirectToContentItem:
    case SmartLink_HandleSuccessfulRedirect:
      return null;

    default:
      return state;
  }
};
