import { Stack } from '@kontent-ai/component-library/Stack';
import { isSameMonth } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { currentLocale } from '../../../../../_shared/components/DatetimePicker/InternalFiles/localeUtils.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { NavigateAction } from '../../constants/NavigateAction.ts';
import { AgendaView } from '../../containers/Calendar/AgendaView.tsx';
import { CalendarToolbar } from '../../containers/Calendar/CalendarToolbar.tsx';
import { CalendarArrangement } from '../../models/CalendarArrangement.ts';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { CalendarView } from '../../models/CalendarView.ts';
import { getCurrentMonthFromRange } from '../../utils/calendarUtils.ts';
import { DayOfWeek } from '../../utils/useCalendarMatrix.ts';
import { MonthView } from './MonthView.tsx';

type CalendarWrapperProps = {
  readonly calendarView: CalendarView;
  readonly events: readonly ICalendarEvent[];
  readonly isCalendarViewLoaded: boolean;
  readonly onDrillDownDateSelected: (date: DateTimeStamp) => void;
  readonly onItemDetailSelected: (id: Uuid, date: DateTimeStamp) => void;
  readonly onNavigate: (action: NavigateAction) => void;
  readonly range: DateRange;
  readonly selectedArrangement: CalendarArrangement;
};

const StyledCalendarStack = styled(Stack)`
  // This is the only place in the app where we don’t want consistent bottom margin.
  // Styled components are not overwriting the styles in classnames that’s why the important is needed.
  margin-bottom: 0 !important;
  height: 100%;
`;

export const CalendarWrapper: React.FC<CalendarWrapperProps> = ({
  calendarView,
  events,
  isCalendarViewLoaded,
  onDrillDownDateSelected,
  onItemDetailSelected,
  onNavigate,
  range,
  selectedArrangement,
}) => {
  const startOfCurrentMonth = getCurrentMonthFromRange(range);
  return (
    <StyledCalendarStack {...getDataUiElementAttribute(DataUiElement.CalendarView)}>
      <CalendarToolbar
        onNavigate={onNavigate}
        label={startOfCurrentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
      />
      {selectedArrangement === CalendarArrangement.Month ? (
        <MonthView
          isCurrentMonthLoaded={isCalendarViewLoaded && calendarView === CalendarView.Items}
          events={events}
          onDrillDownDateSelected={onDrillDownDateSelected}
          onItemDetailSelected={onItemDetailSelected}
          startOfCurrentMonth={startOfCurrentMonth}
          startOfWeek={currentLocale.options?.weekStartsOn ?? DayOfWeek.Sunday}
        />
      ) : (
        <AgendaView
          events={events.filter((event) => isSameMonth(new Date(event.date), startOfCurrentMonth))}
        />
      )}
    </StyledCalendarStack>
  );
};

CalendarWrapper.displayName = 'CalendarWrapper';
