export type DateTime = {
  readonly value: DateTimeStamp;
  readonly isValid: boolean;
};

export const createDateTime = (props?: Partial<DateTime>): DateTime => ({
  value: '',
  isValid: true,
  ...props,
});
