export enum ApiKeyListingColumnCode {
  Environment = 'environment',
  ExpirationDate = 'expirationDate',
  Name = 'name',
  None = '',
}

export const translateColumnCodeToTitle = (column: ApiKeyListingColumnCode): string => {
  switch (column) {
    case ApiKeyListingColumnCode.Environment:
      return 'Environment';
    case ApiKeyListingColumnCode.ExpirationDate:
      return 'Expiration date';
    case ApiKeyListingColumnCode.Name:
      return 'Name';

    default:
      return ApiKeyListingColumnCode.None;
  }
};
