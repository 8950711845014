import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { IUserInfo } from '../../../../_shared/models/UserInfo.ts';
import {
  formatUserName,
  getExistingUserNewColorGradient,
  getUserInitials,
} from '../../../../_shared/utils/usersUtils.ts';

interface IAvatarOverlapProps {
  readonly overlappedUserNames: string;
  readonly overlapCount: number;
}

const AvatarOverlap: React.FC<IAvatarOverlapProps> = ({ overlappedUserNames, overlapCount }) => (
  <Tooltip tooltipText={overlappedUserNames} placement="bottom">
    <div className="avatar-chain__overlap">{`+${overlapCount}`}</div>
  </Tooltip>
);

AvatarOverlap.displayName = 'AvatarOverlap';

interface IContentItemScrollTableContributorsProps {
  readonly contributors: ReadonlyArray<IUserInfo>;
}

const MaxDisplayedContributorsCount = 3;

export const ScrollTableItemContributors: React.FC<IContentItemScrollTableContributorsProps> = ({
  contributors,
}) => {
  const displayedUsers = contributors.slice(0, MaxDisplayedContributorsCount);
  const overlapCount = contributors.length - MaxDisplayedContributorsCount;
  const overlappedUserNames = contributors
    .slice(MaxDisplayedContributorsCount, contributors.length)
    .map(formatUserName)
    .join('\n');

  return (
    <Box display="flex">
      {displayedUsers.map((user) => (
        <div className="avatar-chain__avatar" key={user.userId}>
          <Avatar
            backgroundGradient={getExistingUserNewColorGradient(user)}
            label={formatUserName(user)}
            initials={getUserInitials(user)}
            size={AvatarSize.S}
          />
        </div>
      ))}
      {overlapCount > 0 && (
        <AvatarOverlap overlapCount={overlapCount} overlappedUserNames={overlappedUserNames} />
      )}
    </Box>
  );
};

ScrollTableItemContributors.displayName = 'ScrollTableItemContributors';
