import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IListingContentItemWithMetadataServerModel } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  Data_ItemsRecentlyEditedByCurrentUser_Failed,
  Data_ItemsRecentlyEditedByCurrentUser_Started,
  Data_ItemsRecentlyEditedByCurrentUser_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly getItemsRecentlyEditedByCurrentUser: (
      abortSignal?: AbortSignal,
    ) => Promise<ReadonlyArray<IListingContentItemWithMetadataServerModel>>;
  };
}

const start = () => ({ type: Data_ItemsRecentlyEditedByCurrentUser_Started }) as const;
const success = (data: ReadonlyArray<IListingContentItemWithMetadataServerModel>) =>
  ({
    type: Data_ItemsRecentlyEditedByCurrentUser_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_ItemsRecentlyEditedByCurrentUser_Failed }) as const;

export type LoadContentItemsRecentlyEditedByCurrentUserActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadContentItemsRecentlyEditedByCurrentUserAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const contentItems =
        await deps.contentItemRepository.getItemsRecentlyEditedByCurrentUser(abortSignal);
      dispatch(success(contentItems));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
