import { assert } from '@kontent-ai/utils';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { IApiKeyListingDataServerModel } from '../../../../repositories/serverModels/IApiKeyListingDataServerModel.type.ts';

export interface IApiKeyListingData {
  readonly environments: ReadonlyArray<Uuid>;
  readonly expiresAt: DateTimeStamp | null;
  readonly hasAccessToAllEnvironments: boolean;
  readonly name: string;
  readonly seedId: Uuid;
  readonly type: ApiKeyType;
  readonly userId: string;
}

export const emptyApiKeyListing: IApiKeyListingData = {
  environments: [],
  expiresAt: null,
  hasAccessToAllEnvironments: false,
  name: '',
  seedId: '',
  type: ApiKeyType.Unknown,
  userId: '',
};

export const createApiKeyListingDataFromServerModel = (
  serverModel: IApiKeyListingDataServerModel,
): IApiKeyListingData => {
  assert(!!serverModel, () => 'Api key listing server model is falsy');

  return {
    ...emptyApiKeyListing,
    environments: serverModel.environments,
    expiresAt: serverModel.expires_at,
    hasAccessToAllEnvironments: serverModel.has_access_to_all_environments,
    name: serverModel.name,
    seedId: serverModel.token_seed_id,
    type: serverModel.type,
    userId: serverModel.userId,
  };
};
