import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { IconName } from '../../../constants/iconEnumGenerated.ts';
import { DataUiAction } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { BarItemAction } from './BarItemAction.tsx';

interface IBarItemEditAction {
  readonly disabled?: boolean;
  readonly disabledTooltipText?: string;
  readonly onClick?: () => void;
}

export const BarItemEditAction: React.FC<IBarItemEditAction> = ({
  disabled,
  disabledTooltipText,
  onClick,
}) => (
  <Tooltip
    tooltipText={disabled && disabledTooltipText ? disabledTooltipText : 'Edit'}
    placement="top"
  >
    <BarItemAction
      iconName={IconName.Edit}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      dataUiActionName={DataUiAction.Edit}
      screenReaderText="Edit"
    />
  </Tooltip>
);

BarItemEditAction.displayName = 'BarItemEditAction';
