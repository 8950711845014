import { assert } from '@kontent-ai/utils';
import React, { createContext, ReactNode, useContext, useRef } from 'react';
import { createVariantTranslationTaskManager } from '../utils/translations/variantTranslationTaskManager.ts';

type VariantTranslationManager = ReturnType<typeof createVariantTranslationTaskManager>;

const TranslationTaskManagerContext = createContext<VariantTranslationManager | null>(null);
TranslationTaskManagerContext.displayName = 'TranslationTaskManager';

export const TranslationTaskManagerProvider: React.FC<{
  readonly children: ReactNode;
}> = ({ children }) => {
  const translationTaskManagerRef = useRef(createVariantTranslationTaskManager());

  return (
    <TranslationTaskManagerContext.Provider value={translationTaskManagerRef.current}>
      {children}
    </TranslationTaskManagerContext.Provider>
  );
};

export const useTranslationTaskManager = (): VariantTranslationManager => {
  const variantTranslationManager = useContext(TranslationTaskManagerContext);

  assert(
    variantTranslationManager,
    () => `"${TranslationTaskManagerContext.displayName}" is missing in the current tree.`,
  );

  return variantTranslationManager;
};
