import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCheckboxCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCheckboxCell.tsx';
import { ScrollTableExpandToggleCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableExpandToggleCell.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RedactedItemName } from '../../../../contentInventory/content/constants/uiConstants.ts';
import {
  RedactedItemNameCellContent,
  WorkflowStatusWithFallbacksCellContent,
} from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { PlaceholderForCheckboxCell } from './PlaceholderForCheckboxCell.tsx';
import { ResponsiveCascadeCellsGroup } from './ResponsiveCascadeCellsGroup.tsx';

export interface IProps {
  readonly depth: number;
  readonly item: IListingContentItem;
  readonly tooltip: string;
  readonly variantId?: string;
}

export const RedactedChildRow: React.FC<IProps> = ({ depth, item, tooltip, variantId }) => {
  return (
    <ScrollTableRow objectName={item.item.name} elementName={DataUiElement.CascadePublishChildItem}>
      <ResponsiveCascadeCellsGroup>
        {Array(depth)
          .fill(null)
          .map((_val, index) => (
            <PlaceholderForCheckboxCell key={index} disabledMessage={tooltip} />
          ))}

        <ScrollTableCheckboxCell
          disabledMessage={tooltip}
          id={item.item.id}
          isItemChecked={false}
          name="Select item"
          onCheckboxClick={noOperation}
        />

        <ScrollTableExpandToggleCell
          isExpanded={false}
          isHidden
          onToggle={noOperation}
          disabledMessage={tooltip}
        />

        <ScrollTableCell size={10} title={RedactedItemName} disabledTooltip={tooltip}>
          <RedactedItemNameCellContent variantId={item.variant?.id.variantId || variantId} />
        </ScrollTableCell>

        <ScrollTableCell size={1} disabledTooltip={tooltip} />
      </ResponsiveCascadeCellsGroup>

      <ScrollTableCell size={4} isGrowing disabledTooltip={tooltip}>
        <WorkflowStatusWithFallbacksCellContent variant={item.variant} itemId={item.item.id} />
      </ScrollTableCell>

      <ScrollTableCell size={4} isGrowing disabledTooltip={tooltip} />
    </ScrollTableRow>
  );
};

RedactedChildRow.displayName = 'RedactedChildRow';
