import { findItem } from '@kontent-ai/component-library/Selects';
import { isSection } from '@kontent-ai/component-library/VerticalMenu';
import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  IAssetFolderOption,
  IMoveToAssetFolderDialogDisabledProps,
  IMoveToAssetFolderDialogOwnProps,
  MoveToAssetFolderDialog,
} from '../../components/AssetListing/MoveToAssetFolderDialog.tsx';
import { useMoveSelectedAssetsToFolder } from '../../hooks/useMoveSelectedAssetsToFolder.ts';
import { createMoveToFolderSelectorOptions } from '../../selectors/createMoveToFolderSelectorOptions.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

const disabledProps: IMoveToAssetFolderDialogDisabledProps = {
  tooltip: 'All of the selected assets are located in the selected folder',
};

type Props = IMoveToAssetFolderDialogOwnProps & {
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

export const AssetsMoveToFolderDialog: React.FC<Props> = (props) => {
  const assetsById = useSelector((s) => s.data.assets.byId);
  const moveSelectedAssets = useMoveSelectedAssetsToFolder({
    assetsById,
    deselectAssets: props.deselectAssets,
    orderBy: props.orderBy,
    selectedAssets: props.selectedAssets,
  });

  const folderOptions = useSelector((s) =>
    createMoveToFolderSelectorOptions(s.data.assetFolders.taxonomyGroup),
  );
  const preselectedOption = useSelector((s) => {
    const openedFolderId = s.assetLibraryApp.openedFolderId;
    const preselected = findItem(
      folderOptions,
      (option: IAssetFolderOption) => option.id === openedFolderId,
    );

    if (!preselected || isSection(preselected)) {
      throw InvariantException(`Folder option with id ${openedFolderId} was not found`);
    }

    return preselected;
  });

  const selectedAssets = useSelector((s) =>
    s.data.assets.byId.filter((_, assetId: Uuid) => props.selectedAssets.has(assetId)),
  );

  const getDisabledProps = (
    selectedOption: IAssetFolderOption,
  ): IMoveToAssetFolderDialogDisabledProps | null => {
    const areAllAssetsFromTargetFolder = selectedAssets.every(
      (asset: IAsset) => asset.folderId === selectedOption.id,
    );

    return areAllAssetsFromTargetFolder ? disabledProps : null;
  };

  if (!preselectedOption) {
    return null;
  }

  return (
    <MoveToAssetFolderDialog
      closeDialog={props.closeDialog}
      dataUiAction={DataUiAction.MoveAssetsToFolder}
      folderOptions={folderOptions}
      getDisabledProps={getDisabledProps}
      label={props.label}
      onMoveToFolder={moveSelectedAssets}
      preselectedOption={preselectedOption}
      primaryActionText={props.primaryActionText}
    />
  );
};

AssetsMoveToFolderDialog.displayName = 'AssetsMoveToFolderDialog';
