import { Action } from '../../../../@types/Action.type.ts';
import { Content_HideValidationResults } from '../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_CloseIncompleteDefaultVariantNotificationBar,
  ContentItemEditing_Init_Started,
  ContentItemEditing_ShowIncompleteDefaultVariantNotificationBar,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function showIncompleteDefaultVariantNotificationBar(
  state = initialState,
  action: Action,
): boolean {
  switch (action.type) {
    case ContentItemEditing_ShowIncompleteDefaultVariantNotificationBar: {
      return true;
    }

    case Content_HideValidationResults:
    case ContentItemEditing_Init_Started:
    case ContentItemEditing_CloseIncompleteDefaultVariantNotificationBar: {
      return initialState;
    }

    default:
      return state;
  }
}
