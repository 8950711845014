import { Collection } from '@kontent-ai/utils';
import { useSyncExternalStore } from 'react';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { MatchAiTasks } from './matchAiTask.type.ts';

export enum AiTaskProgress {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  Running = 'Running',
}

/**
 * Hook that observes progress of an AI task
 * @param matchAiTasks A function that receives information about AI tasks and returns the tasks of interest
 */
export const useAiTaskProgress = (matchAiTasks?: MatchAiTasks): AiTaskProgress => {
  const aiTaskManager = useAiTaskManager();
  const allTasksSnapshot = useSyncExternalStore(
    aiTaskManager.subscribe,
    aiTaskManager.getAllTasksSnapshot,
  );
  const allTasks = Collection.getValues(allTasksSnapshot);
  const matchingTasks = matchAiTasks ? matchAiTasks(allTasks) : allTasks;

  if (!matchingTasks.length) {
    return AiTaskProgress.NotStarted;
  }

  return matchingTasks.some((aiTask) => !aiTask.action.isFinished)
    ? AiTaskProgress.Running
    : AiTaskProgress.Completed;
};
