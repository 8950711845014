import { Button } from '@kontent-ai/component-library/Button';
import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { RestoreFromArchivedStep } from '../../../../../../_shared/constants/itemActions.ts';
import { openRestoreItemFromArchivedStepModal } from '../../../../../../_shared/features/ChangeWorkflowStepModal/actions/thunkChangeWorkflowStepModalActions.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { Tag } from '../../../../../../_shared/uiComponents/Tag/Tag.tsx';
import { isArchivedWorkflowStepSelected } from '../../../../../../_shared/utils/contentItemUtils.ts';
import {
  DataUiElement,
  DataUiWorkflowAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { TagColor } from '../../../../../../data/constants/tagColor.ts';
import { getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith } from '../../../../selectors/getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith.ts';

export const ArchivedStepNotificationBar: React.FC = () => {
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  const canRestoreFromArchivedStep = useSelector((state) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateAssignment, state),
  );
  const restoreToStepName = useSelector((state) =>
    getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith(state),
  );

  if (!editedContentItemVariant) {
    throw InvariantException('This is available only in edited content item');
  }

  const archivedDate = editedContentItemVariant.assignment.createdAt;
  const shouldDisplayArchived = isArchivedWorkflowStepSelected(editedContentItemVariant.assignment);

  const dispatch = useDispatch();
  const onRestoreFromArchivedStep = () =>
    dispatch(openRestoreItemFromArchivedStepModal(ContentItemEditingEventOrigins.NotificationBar));

  if (shouldDisplayArchived) {
    const archivedDateString = renderDatetimeString(archivedDate);

    const message = (
      <>
        This content was&nbsp;<Tag color={TagColor.Gray}>Archived</Tag> on{' '}
        <strong>{archivedDateString}</strong>.
        {canRestoreFromArchivedStep && ' Do you want to make changes?'}
      </>
    );

    return (
      <NotificationBar
        type={NotificationBarType.InfoLight}
        message={message}
        tools={
          canRestoreFromArchivedStep && (
            <Button
              size="small"
              buttonStyle="primary"
              onClick={onRestoreFromArchivedStep}
              {...getDataUiActionAttribute(DataUiWorkflowAction.RestoreFromArchivedStep)}
            >
              {RestoreFromArchivedStep(restoreToStepName)}
            </Button>
          )
        }
        uiElement={DataUiElement.NotificationBarInfo}
      />
    );
  }
  return null;
};

ArchivedStepNotificationBar.displayName = 'ArchivedNotificationBar';
