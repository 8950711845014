import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import {
  Workflow_Delete_Failed,
  Workflow_Delete_Finished,
  Workflow_Delete_Started,
} from '../../constants/workflowActionTypes.ts';
import { fireDeleteWorkflowEvents } from './fireWorkflowIntercomEvents.ts';

interface IDeps {
  readonly workflowRepository: {
    readonly archive: (workflowId: Uuid) => Promise<void>;
  };
}

const started = () =>
  ({
    type: Workflow_Delete_Started,
  }) as const;

const finished = (workflowId: Uuid) =>
  ({
    type: Workflow_Delete_Finished,
    payload: { workflowId },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Workflow_Delete_Failed,
    payload: { errorMessage },
  }) as const;

export type DeleteWorkflowActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createDeleteWorkflowAction =
  ({ workflowRepository }: IDeps) =>
  (workflow: Workflow): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());

      await workflowRepository.archive(workflow.id);

      dispatch(finished(workflow.id));
      dispatch(fireDeleteWorkflowEvents(workflow));
    } catch (error) {
      dispatch(failed('Failed to delete workflow.'));
      throw error;
    }
  };
