import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import {
  IContentItemWithVariantsServerModel,
  RequiredItemCreationParams,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  NewContentItem_ContentItemCreatedInListing,
  NewContentItem_CreatingContentItemInListing,
} from '../../constants/newContentItemActionTypes.ts';
import { ICreateContentItemAction } from './createContentItem.ts';

export type ICreateNewContentItemAction = (
  params: RequiredItemCreationParams,
) => ThunkPromise<IContentItemWithVariantsServerModel>;

interface ICreateNewContentItemActionDependencies {
  readonly createContentItem: ICreateContentItemAction;
  readonly updateCollectionsPreference: (collectionId: Uuid) => ThunkPromise;
  readonly updateTypesPreference: (contentTypeId: Uuid) => ThunkPromise;
}

const newContentItemIsBeingCreated = () =>
  ({
    type: NewContentItem_CreatingContentItemInListing,
  }) as const;

const newContentItemCreated = () =>
  ({
    type: NewContentItem_ContentItemCreatedInListing,
  }) as const;

export type CreateNewContentItemActionTypes = ReturnType<
  typeof newContentItemIsBeingCreated | typeof newContentItemCreated
>;

export const createNewContentItemActionCreator =
  (dependencies: ICreateNewContentItemActionDependencies): ICreateNewContentItemAction =>
  ({ typeId, collectionId }): ThunkPromise<IContentItemWithVariantsServerModel> =>
  async (dispatch) => {
    const { createContentItem, updateCollectionsPreference, updateTypesPreference } = dependencies;

    dispatch(newContentItemIsBeingCreated());

    const createdItem = await dispatch(createContentItem({ typeId, collectionId }));

    dispatch(newContentItemCreated());
    dispatch(updateTypesPreference(typeId));
    dispatch(updateCollectionsPreference(collectionId));

    return createdItem;
  };
