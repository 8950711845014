import {
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../../_shared/utils/contentItemUtils.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { LastCascadeAction } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { PublishingPrivileges } from '../models/PublishingPrivileges.ts';

type UserPrivileges = {
  readonly canCancelScheduling: boolean;
  readonly canCreateNewVersion: boolean;
  readonly canUnpublish: boolean;
};

export const getPublishingPrivileges = (
  { canCancelScheduling, canCreateNewVersion, canUnpublish }: UserPrivileges,
  workflowStatus: IWorkflowStep,
  lastAction: LastCascadeAction,
): PublishingPrivileges => {
  if (isPublishedWorkflowStep(workflowStatus)) {
    if (canUnpublish && lastAction !== LastCascadeAction.None) {
      return PublishingPrivileges.Unpublish;
    }

    if (canCreateNewVersion) {
      return PublishingPrivileges.CreateNewVersion;
    }

    return PublishingPrivileges.ViewPublishedVersion;
  }

  if (isScheduledToPublishWorkflowStep(workflowStatus)) {
    if (canCancelScheduling) {
      return PublishingPrivileges.CancelScheduling;
    }

    return PublishingPrivileges.ViewScheduledVersion;
  }

  return PublishingPrivileges.None;
};
