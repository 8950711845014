import { noOperation } from '@kontent-ai/utils';
import React, { createContext, useContext, useMemo, useState } from 'react';

interface IMissionControlDemoContextValue {
  readonly isDemoModeEnabled: boolean;
  readonly setIsDemoModeEnabled: (value: boolean) => void;
}

const defaultValue: IMissionControlDemoContextValue = {
  isDemoModeEnabled: false,
  setIsDemoModeEnabled: noOperation,
};

export const MissionControlDemoContext =
  createContext<IMissionControlDemoContextValue>(defaultValue);

export const MissionControlDemoContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isDemoModeEnabled, setIsDemoModeEnabled] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isDemoModeEnabled,
      setIsDemoModeEnabled,
    }),
    [isDemoModeEnabled],
  );

  return (
    <MissionControlDemoContext.Provider value={value}>
      {children}
    </MissionControlDemoContext.Provider>
  );
};

MissionControlDemoContextProvider.displayName = 'MissionControlDemoContextProvider';

export const useIsDemoModeEnabled = (): boolean =>
  useContext(MissionControlDemoContext).isDemoModeEnabled ?? false;
