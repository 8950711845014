import { Warning } from '../../../_shared/components/infos/Warning.tsx';

type EntityWebhookStatusToggleWarningProps = {
  readonly entityWebhookName: string;
};

export const EntityWebhookStatusToggleWarning = ({
  entityWebhookName,
}: EntityWebhookStatusToggleWarningProps) => (
  <Warning>
    <p>
      When you disable this webhook, two things will happen immediately:
      <br />
      <ul>
        <li>The webhook stops sending notifications to your app.</li>
        <li>Any unsent notifications will be deleted.</li>
      </ul>
    </p>
    <p>
      Do you want to disable the <b>{entityWebhookName}</b> webhook?
    </p>
  </Warning>
);

EntityWebhookStatusToggleWarning.displayName = 'EntityWebhookStatusToggleWarning';
