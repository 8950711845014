import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionEnvironmentWorkflowCreatingRoute,
  SubscriptionEnvironmentWorkflowEditingRoute,
  SubscriptionEnvironmentWorkflowEditingRouteParams,
  WorkflowCreatingRoute,
  WorkflowEditingRoute,
  WorkflowEditingRouteParams,
  WorkflowsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../../_shared/containers/routing/AuthorizedSection.tsx';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { RedirectIfMultipleWorkflowsAreNotAvailable } from '../containers/RedirectIfMultipleWorkflowsAreNotAvailable.tsx';
import { UpsellMultipleWorkflows } from '../containers/UpsellMultipleWorkflows.tsx';
import { WorkflowCreator } from '../containers/WorkflowCreator.tsx';
import { WorkflowEditor } from '../containers/WorkflowEditor.tsx';
import { WorkflowsListing } from '../containers/WorkflowsListing.tsx';
import { initialWorkflowListingOperationStatus } from '../model/WorkflowListingOperation.ts';

export const WorkflowSettings = () => {
  const editingRouteMatch = useRouteMatch<WorkflowEditingRouteParams>(WorkflowEditingRoute);

  const subscriptionEnvironmentEditingRouteMatch =
    useRouteMatch<SubscriptionEnvironmentWorkflowEditingRouteParams>(
      SubscriptionEnvironmentWorkflowEditingRoute,
    );

  const subscriptionEnvironmentSettingsRouteMatch =
    useRouteMatch<SubscriptionEnvironmentSettingsRouteParams>(
      SubscriptionEnvironmentWorkflowEditingRoute,
    );

  const creatingRouteMatch = useRouteMatch<
    WorkflowsRouteParams | SubscriptionEnvironmentSettingsRouteParams
  >([WorkflowCreatingRoute, SubscriptionEnvironmentWorkflowCreatingRoute]);

  const [operationStatus, setOperationStatus] = useState(initialWorkflowListingOperationStatus);

  if (editingRouteMatch) {
    return (
      <AuthorizedSection
        requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
        appName={EnvironmentSettingsAppNames.Workflow}
      >
        <WorkflowEditor
          workflowId={editingRouteMatch.params.workflowId}
          setOperationStatus={setOperationStatus}
        />
        <UpsellMultipleWorkflows />
      </AuthorizedSection>
    );
  }

  if (subscriptionEnvironmentEditingRouteMatch) {
    return (
      <AuthorizedSection
        requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
        appName={EnvironmentSettingsAppNames.Workflow}
      >
        <WorkflowEditor
          workflowId={subscriptionEnvironmentEditingRouteMatch.params.workflowId}
          setOperationStatus={setOperationStatus}
        />
      </AuthorizedSection>
    );
  }

  if (creatingRouteMatch) {
    return (
      <AuthorizedSection
        requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
        appName={EnvironmentSettingsAppNames.MultipleWorkflows}
      >
        <RedirectIfMultipleWorkflowsAreNotAvailable>
          <WorkflowCreator />
        </RedirectIfMultipleWorkflowsAreNotAvailable>
      </AuthorizedSection>
    );
  }

  return (
    <AuthorizedSection
      requiresOneOfCapabilities={[Capability.ConfigureWorkflow]}
      appName={EnvironmentSettingsAppNames.MultipleWorkflows}
    >
      <RedirectIfMultipleWorkflowsAreNotAvailable>
        <WorkflowsListing
          subscriptionId={subscriptionEnvironmentSettingsRouteMatch?.params.subscriptionId}
          operationStatus={operationStatus}
          setOperationStatus={setOperationStatus}
        />
      </RedirectIfMultipleWorkflowsAreNotAvailable>
    </AuthorizedSection>
  );
};
