import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  ContentItemEditing_Elements_FocusFirstIncomplete,
  ContentItemEditing_FocusValidationWarningsBeforePublish,
  ContentItemEditing_Init_Started,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: Uuid = '';

export function firstIncompleteElementId(state = initialState, action: Action): Uuid {
  switch (action.type) {
    case ContentItemEditing_Init_Started:
    case ContentListing_Init_Started:
    case ContentItemEditing_Elements_FocusFirstIncomplete: {
      return initialState;
    }

    case ContentItemEditing_FocusValidationWarningsBeforePublish: {
      return action.payload.incompleteElementIds[0] || initialState;
    }

    default:
      return state;
  }
}
