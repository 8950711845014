import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextDefault,
  colorTextHint,
  gridUnit,
} from '@kontent-ai/component-library/tokens';
import { forwardRef, useMemo, useState } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { ContentItemVariantLink } from '../../../../../_shared/components/ContentItemVariantLink/ContentItemVariantLink.tsx';
import { AssignedSpacesMessage } from '../../../../../_shared/components/Spaces/AssignedSpacesMessage.tsx';
import { isVariantPublished } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemVariantWithLanguageName } from '../../../../../_shared/utils/getContentItemVariantsWithLanguageNames.tsx';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { SpacesMap } from '../../../../../data/models/space/space.ts';
import { CollectionSingleSelect } from '../../../../contentInventory/shared/components/CollectionSingleSelect.tsx';
import {
  spaceNames,
  spacesByCollectionId,
} from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';

interface IMoveToCollectionDialogProps {
  readonly contentItemVariants: ReadonlyArray<ContentItemVariantWithLanguageName>;
  readonly editedItemCollectionName: string;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onMove: (collectionId: Uuid) => void;
  readonly options: ReadonlyArray<ICollection>;
  readonly unmovableVariantIdsPerCollection: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>;
  readonly spacesMap: SpacesMap | null;
  readonly variantIdsUnmovableFromSource: ReadonlyArray<Uuid>;
  readonly showWorkflowWarning: boolean;
}

export const MoveToCollectionDialog = forwardRef<HTMLDivElement, IMoveToCollectionDialogProps>(
  (
    {
      contentItemVariants,
      editedItemCollectionName,
      isOpen,
      onClose,
      onMove,
      options,
      unmovableVariantIdsPerCollection,
      spacesMap,
      variantIdsUnmovableFromSource,
      showWorkflowWarning,
    },
    ref,
  ) => {
    const [targetCollectionId, setTargetCollectionId] = useState<Uuid | null>(null);
    const isAnyVariantPublished = contentItemVariants.some(
      (variant) => !!variant && isVariantPublished(variant),
    );
    const variantIdsUnmovableToTarget =
      targetCollectionId && unmovableVariantIdsPerCollection.get(targetCollectionId);
    const isSomeUnmovableVariantsPresent =
      !!variantIdsUnmovableFromSource.length || !!variantIdsUnmovableToTarget?.length;
    const contentWidth = 92 * gridUnit;

    const collectionSpaceNames = useMemo(() => {
      const spaceIds =
        targetCollectionId && spacesMap && spacesByCollectionId(spacesMap, targetCollectionId);
      return (
        spaceIds &&
        spaceNames(
          spacesMap,
          spaceIds.map((space) => space.id),
        )
      );
    }, [targetCollectionId, spacesMap]);

    const collectionName =
      options.find((collection: ICollection) => collection.id === targetCollectionId)?.name ?? '';

    const getDisabledMoveButtonMessage = (): string | undefined => {
      if (variantIdsUnmovableFromSource.length) {
        return `You do not have sufficient permissions in the ${editedItemCollectionName} collection.`;
      }
      if (!targetCollectionId) {
        return 'Select a collection';
      }
      if (variantIdsUnmovableToTarget?.length) {
        return `You do not have sufficient permissions in the ${collectionName} collection.`;
      }
      return undefined;
    };

    const disabledDropdownMessage = variantIdsUnmovableFromSource.length
      ? `You do not have sufficient permissions in the ${editedItemCollectionName} collection.`
      : undefined;

    const disabledMoveButtonMessage = getDisabledMoveButtonMessage();
    const showPublishedItemsWarning =
      isAnyVariantPublished && targetCollectionId && !isSomeUnmovableVariantsPresent;

    const closeModal = (): void => {
      setTargetCollectionId(null);
      onClose();
    };

    const move = (): void => {
      if (targetCollectionId) {
        onMove(targetCollectionId);
        closeModal();
      }
    };

    const collectionAssignedSpacesMessage = collectionSpaceNames && (
      <Box color={colorTextHint} typography={Typography.BodyMedium}>
        <AssignedSpacesMessage
          spaceData={collectionSpaceNames}
          renderSpaceName={({ name }) => (
            <Box
              component="span"
              color={colorTextDefault}
              typography={Typography.BodyMediumEmphasis}
            >
              {name}
            </Box>
          )}
        />
      </Box>
    );

    return (
      <ModalDialog
        headline="Change collection"
        isDismissable
        isOpen={isOpen}
        primaryAction={{
          disabled: !!disabledMoveButtonMessage,
          onClick: move,
          text: 'Move',
          tooltipPlacement: 'top-end',
          tooltipText: disabledMoveButtonMessage,
          ...getDataUiActionAttribute(DataUiAction.Move),
        }}
        cancelAction={{
          onClick: closeModal,
          ...getDataUiActionAttribute(DataUiAction.CloseDialog),
        }}
        onClose={closeModal}
        ref={ref}
        shouldCloseOnInteractOutside={() => false}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
      >
        <Box width={contentWidth}>
          <Stack spacing={Spacing.XL}>
            <Stack spacing={Spacing.M}>
              <Inline align="center" spacingX={Spacing.M}>
                <Label size={LabelSize.L}>Move item to</Label>
                <CollectionSingleSelect
                  collectionOptions={options}
                  currentCollectionId={targetCollectionId}
                  customClassName="move-to-collection-modal__selector"
                  disabledTooltipMessage={disabledDropdownMessage}
                  onChange={setTargetCollectionId}
                  shouldHighlightAsError={!!variantIdsUnmovableToTarget?.length}
                />
              </Inline>
              {collectionAssignedSpacesMessage}
            </Stack>
            <Stack spacing={Spacing.XL}>
              {showWorkflowWarning && (
                <Callout calloutType="friendlyWarning">
                  The item will continue in its current workflow. You can change the workflow
                  manually later.
                </Callout>
              )}
              {showPublishedItemsWarning && (
                <Callout calloutType="friendlyWarning">
                  Moving items to another collection also affects their published content in all
                  languages.
                  <br />
                  This action may break your app.
                </Callout>
              )}
              <Callout
                calloutType={isSomeUnmovableVariantsPresent ? 'friendlyWarning' : 'quickTip'}
                headline="Language variants moving with this item"
                hideSubheadline
                {...(isSomeUnmovableVariantsPresent
                  ? getDataUiElementAttribute(DataUiElement.CalloutWarning)
                  : getDataUiElementAttribute(DataUiElement.CalloutQuickTip))}
              >
                <Stack spacing={Spacing.S}>
                  {isSomeUnmovableVariantsPresent && (
                    <p>
                      You need edit permission for all item variants to change this item’s
                      collection. See exclamation marks for details.
                    </p>
                  )}

                  <Box>
                    {contentItemVariants.map((variant: ContentItemVariantWithLanguageName) => {
                      const getTooltipMessage = (): string | undefined => {
                        if (variantIdsUnmovableFromSource.includes(variant.id.variantId)) {
                          return `You don’t have permission to edit ${variant.languageName} content in the ${editedItemCollectionName} collection.`;
                        }

                        if (variantIdsUnmovableToTarget?.includes(variant.id.variantId)) {
                          return `You don’t have permission to edit ${variant.languageName} content in the ${collectionName} collection.`;
                        }

                        return undefined;
                      };

                      return (
                        <ContentItemVariantLink
                          key={variant.id.variantId}
                          variant={variant}
                          tooltipText={getTooltipMessage()}
                        />
                      );
                    })}
                  </Box>
                </Stack>
              </Callout>
            </Stack>
          </Stack>
        </Box>
      </ModalDialog>
    );
  },
);

MoveToCollectionDialog.displayName = 'MoveToCollectionDialog';
