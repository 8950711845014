import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { Column } from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { OrderBy, OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { updateSavedConfig } from '../actions/thunkUsersActions.ts';
import {
  userListingSortDirectionToggled,
  userListingSortedByFirstName,
  userListingSortedByLastName,
  userListingSortedByStatus,
} from '../actions/usersActions.ts';
import { UserListingColumnCode } from '../reducers/IUsersAppState.type.ts';

const createActions = (dispatch: Dispatch) => ({
  selectLastName: (): void => {
    dispatch(userListingSortedByLastName());
    dispatch(updateSavedConfig());
  },
  selectFirstName: (): void => {
    dispatch(userListingSortedByFirstName());
    dispatch(updateSavedConfig());
  },
  selectStatus: (): void => {
    dispatch(userListingSortedByStatus());
    dispatch(updateSavedConfig());
  },
  toggleSortDirection: (): void => {
    dispatch(userListingSortDirectionToggled());
    dispatch(updateSavedConfig());
  },
});

const getTableHeadData = memoize.maxOne(
  (sortBy: OrderBy<UserListingColumnCode>, dispatch: Dispatch): Immutable.List<Column> => {
    const { selectLastName, selectStatus, toggleSortDirection, selectFirstName } =
      createActions(dispatch);

    const sortDirection =
      sortBy.direction === OrderByDirection.Ascending
        ? OrderByDirection.Ascending
        : OrderByDirection.Descending;

    return Immutable.List.of<Column>(
      {
        columnName: 'First name',
        orderBy:
          sortBy.columnCode === UserListingColumnCode.FirstName
            ? sortDirection
            : OrderByDirection.None,
        onClick:
          sortBy.columnCode === UserListingColumnCode.FirstName
            ? toggleSortDirection
            : selectFirstName,
        className: 'data-table__column--3-and-half',
      },
      {
        columnName: 'Last name',
        orderBy:
          sortBy.columnCode === UserListingColumnCode.LastName
            ? sortDirection
            : OrderByDirection.None,
        onClick:
          sortBy.columnCode === UserListingColumnCode.LastName
            ? toggleSortDirection
            : selectLastName,
        className: 'data-table__column--3-and-half',
      },
      {
        columnName: 'Email',
        orderBy: OrderByDirection.None,
      },
      {
        columnName: 'Roles',
        orderBy: OrderByDirection.None,
      },
      {
        columnName: 'Status',
        orderBy:
          sortBy.columnCode === UserListingColumnCode.Status
            ? sortDirection
            : OrderByDirection.None,
        className: 'data-table__column--6',
        onClick:
          sortBy.columnCode === UserListingColumnCode.Status ? toggleSortDirection : selectStatus,
      },
    );
  },
);

export const useUserListingTableHeadData = (): Immutable.List<Column> => {
  const sortBy = useSelector((state) => state.usersApp.sortBy);
  const dispatch = useDispatch();

  return getTableHeadData(sortBy, dispatch);
};
