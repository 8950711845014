import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import {
  EditContentTypeRoute,
  EditContentTypeRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { validateTypeElement } from '../../../shared/actions/thunkSharedContentModelsActions.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import { contentTypeInvalidElementShowWarning } from '../../actions/contentTypesActions.ts';
import { createContentType } from '../../actions/thunkContentTypesActions.ts';

export const ContentTypeCreatorToolbarActions = () => {
  const dispatch = useDispatch();
  const codename = useSelector((s) => s.contentModelsApp.typeEditor.editedType.codename);
  const isBeingSaved = useSelector((s) => s.contentModelsApp.typeEditor.typeIsBeingSaved);
  const isLoading = useSelector(
    (s) => s.contentModelsApp.typeEditor.loadingStatus !== LoadingStatus.Loaded,
  );
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);
  const editedType = useSelector((s) => s.contentModelsApp.typeEditor.editedType);

  const isValid = (editedContentType: IContentType): boolean => {
    const validationResults = new Map(
      editedContentType.typeElements.map((element) => [
        element.elementId,
        dispatch(validateTypeElement(element)),
      ]),
    );
    return isTypeValid(validationResults);
  };

  const history = useHistory();
  const onSave = async (): Promise<void> => {
    if (isValid(editedType)) {
      const createdContentType = await dispatch(createContentType(editedType));
      history.push(
        buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
          projectId: currentProjectId,
          contentTypeId: createdContentType.id,
        }),
      );
    } else {
      dispatch(contentTypeInvalidElementShowWarning());
    }
  };

  return (
    <StatusBarActions
      codename={codename}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      onSave={onSave}
    />
  );
};
