import React from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { WidgetListingState } from '../types/WidgetListingState.type.ts';

type WidgetListingContentProps = React.PropsWithChildren & {
  readonly state: WidgetListingState;
};

export const WidgetDetailTableStateContent: React.FC<WidgetListingContentProps> = ({
  children,
  state,
}) => {
  switch (state) {
    case WidgetListingState.Loading:
      return <Loader />;
    case WidgetListingState.Error:
      return null;
    case WidgetListingState.Empty:
      return null;
    case WidgetListingState.Loaded:
    case WidgetListingState.LoadingMore:
      return children;
    default:
      return null;
  }
};

WidgetDetailTableStateContent.displayName = 'WidgetDetailTableStateContent';
