import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { touchProjectsDependencies } from '../../../../../repositories/cacheKeys/projectCacheUtils.ts';
import { touchSubscriptionAndItsDependencies } from '../../../../../repositories/cacheKeys/subscriptionCacheUtils.ts';
import { Subscription_BillingInformation_FastSpringConnected } from '../../constants/actionTypes.ts';

interface IDeps {
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
  readonly loadSubscriptionBillingInformation: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
}

const fastSpringAccountConnected = (subscriptionId: Uuid) =>
  ({
    type: Subscription_BillingInformation_FastSpringConnected,
    payload: {
      subscriptionId,
    },
  }) as const;

export type FastSpringAccountConnectionCompletedActionsType = ReturnType<
  typeof fastSpringAccountConnected
>;

export const createFastSpringAccountConnectionCompleted =
  (deps: IDeps) =>
  (subscriptionId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    await Promise.all([dispatch(fastSpringAccountConnected(subscriptionId))]).then(() => {
      setTimeout(() => {
        touchProjectsDependencies();
        touchSubscriptionAndItsDependencies(subscriptionId);
        Promise.all([
          dispatch(deps.loadSubscriptionBillingInformation(subscriptionId)),
          dispatch(deps.loadProjects()),
          dispatch(deps.loadUserProjectsInfo()),
        ]);
      }, 30000);
    });
  };
