import {
  Dispatch,
  GetState,
  ThunkFunction,
  ThunkPromise,
} from '../../../@types/Dispatcher.type.ts';
import { initializeRepositories } from '../../../_shared/repositories/repositories.ts';
import { GetAuthToken } from '../../../_shared/types/GetAuthToken.type.ts';
import { isUuid } from '../../../_shared/utils/validation/typeValidators.ts';

const getAppInstanceId = (_dispatch: Dispatch, getState: GetState): string =>
  getState().sharedApp.appInstanceId;

const getCurrentProjectId = (_dispatch: Dispatch, getState: GetState): Uuid | null => {
  const { currentProjectId } = getState().sharedApp;
  return isUuid(currentProjectId) ? currentProjectId : null;
};

const getCurrentProjectContainerId = (_dispatch: Dispatch, getState: GetState): Uuid | null => {
  const { currentProjectContainerId } = getState().sharedApp;
  return isUuid(currentProjectContainerId) ? currentProjectContainerId : null;
};

const getCurrentMasterEnvironmentId = (_dispatch: Dispatch, getState: GetState): Uuid | null => {
  const { currentMasterEnvironmentId } = getState().sharedApp;
  return isUuid(currentMasterEnvironmentId) ? currentMasterEnvironmentId : null;
};

interface IDeps {
  readonly handleServerResponse: (response: XMLHttpRequest) => ThunkFunction<any>;
  readonly handleServerErrorResponse: (
    request: XMLHttpRequest,
    abortSignal: AbortSignal,
  ) => ThunkPromise;
}

export const createInitializeRepositoriesAction =
  ({ handleServerResponse, handleServerErrorResponse }: IDeps) =>
  (getAuthToken: GetAuthToken, abortSignal: AbortSignal): ThunkFunction =>
  (dispatch) => {
    initializeRepositories({
      getAppInstanceId: () => dispatch(getAppInstanceId),
      getAuthToken,
      getCurrentMasterEnvironmentId: () => dispatch(getCurrentMasterEnvironmentId),
      getCurrentProjectContainerId: () => dispatch(getCurrentProjectContainerId),
      getCurrentProjectId: () => dispatch(getCurrentProjectId),
      handleServerErrorResponse: (request: XMLHttpRequest) =>
        dispatch(handleServerErrorResponse(request, abortSignal)),
      handleServerResponse: (request: XMLHttpRequest) => dispatch(handleServerResponse(request)),
    });
  };
