import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';

export const FailedAssetUploadActions: React.FC<{ readonly onRemove?: () => void }> = ({
  onRemove,
}) => (
  <div className="asset-thumbnail__alert">
    <div className="asset-thumbnail__alert-message">Asset upload failed</div>
    <div className="asset-thumbnail__alert-action">
      <Tooltip tooltipText="Remove" placement="bottom">
        <div className="asset-thumbnail__action asset-thumbnail__action--remove" onClick={onRemove}>
          <Icon iconName={IconName.Times} />
        </div>
      </Tooltip>
    </div>
  </div>
);
FailedAssetUploadActions.displayName = 'FailedAssetUploadActions';
