import { combineReducers } from '@reduxjs/toolkit';
import { contentTypeSnippetEditingLoadingStatus } from './editor/contentTypeSnippetEditingLoadingStatus.ts';
import { contentTypeSnippetIsBeingSaved } from './editor/contentTypeSnippetIsBeingSaved.ts';
import { draggedElementId } from './editor/draggedElementId.ts';
import { draggedMultipleChoiceOptionId } from './editor/draggedMultipleChoiceOptionId.ts';
import { editedContentTypeSnippet } from './editor/editedContentTypeSnippet.ts';
import { editedContentTypeSnippetIsModified } from './editor/editedContentTypeSnippetIsModified.ts';
import { editedMultipleChoiceElement } from './editor/editedMultipleChoiceElement.ts';
import { firstInvalidElementId } from './editor/firstInvalidElementId.ts';
import { isEditedContentTypeSnippetUsedInPublishedItem } from './editor/isEditedContentTypeSnippetUsedInPublishedItem.ts';
import { lastAddedElementId } from './editor/lastAddedElementId.ts';
import { showConfigurationForElements } from './editor/showConfigurationForElements.ts';
import { validationResults } from './editor/validationResults.ts';

export const editorReducer = combineReducers({
  contentTypeSnippetEditingLoadingStatus,
  contentTypeSnippetIsBeingSaved,
  draggedElementId,
  draggedMultipleChoiceOptionId,
  editedContentTypeSnippet,
  editedContentTypeSnippetIsModified,
  editedMultipleChoiceElement,
  firstInvalidElementId,
  isEditedContentTypeSnippetUsedInPublishedItem,
  lastAddedElementId,
  showConfigurationForElements,
  validationResults,
});
