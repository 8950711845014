import { Collection } from '@kontent-ai/utils';
import { useEffect, useMemo } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getFilterStorage } from '../../../../../../localStorages/getFilterStorage.ts';
import { IListingFilter, emptyListingFilter } from '../../../models/filter/IListingFilter.ts';
import { ConsistencyFilterEntities, getConsistentFilter } from '../utils/consistencyUtils.ts';

export type PreselectedFilterIds = {
  readonly collectionIds?: ReadonlyArray<Uuid>;
  readonly contentTypeIds?: ReadonlyArray<Uuid>;
  readonly spaceIds?: ReadonlyArray<Uuid>;
};

export const useSetUpContentItemFilter = (
  origin: ContentItemFilterOrigin,
  filterSetUp: (filter: IListingFilter) => void,
  filterLeft: () => void,
  preselected: PreselectedFilterIds | undefined,
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined,
): void => {
  const { projectId } = useSelector(getCurrentProject);
  const collectionsById = useSelector((state) => state.data.collections.byId);
  const contentTypesById = useSelector((state) => state.data.contentTypes.byId);
  const usersById = useSelector((state) => state.data.users.usersById);
  const workflowsById = useSelector((state) => state.data.workflows.byId);
  const sitemap = useSelector((state) => state.data.sitemap.data);
  const spacesById = useSelector((state) => state.data.spaces.byId);
  const taxonomyGroupsById = useSelector((s) => s.data.taxonomyGroups.byId);

  const entities = useMemo((): ConsistencyFilterEntities => {
    return {
      collectionIds: Collection.getKeys(collectionsById),
      contentTypeIds: contentTypesById.keySeq().toArray(),
      taxonomyGroups: taxonomyGroupsById.valueSeq().toArray(),
      workflows: Collection.getValues(workflowsById),
      sitemap,
      spaceIds: Collection.getKeys(spacesById),
      users: Collection.getValues(usersById),
    };
  }, [
    collectionsById,
    contentTypesById,
    taxonomyGroupsById,
    workflowsById,
    sitemap,
    spacesById,
    usersById,
  ]);

  const filterFromStorage = useLoadFilterFromStorage(projectId, entities, origin);
  const preselectedFilter = usePreselectFilter(
    entities,
    filterFromStorage,
    preselected,
    forcedContentTypeIds,
  );

  useEffect(() => {
    filterSetUp(preselectedFilter);
    return () => {
      filterLeft();
    };
  }, [filterSetUp, filterLeft, preselectedFilter]);
};

const useLoadFilterFromStorage = (
  projectId: Uuid,
  entities: ConsistencyFilterEntities,
  origin: ContentItemFilterOrigin,
): IListingFilter => {
  return useMemo(() => {
    const potentiallyInconsistentFilter =
      getFilterStorage(origin)?.load(projectId) ?? emptyListingFilter;
    return getConsistentFilter(entities, potentiallyInconsistentFilter);
  }, [origin, entities, projectId]);
};

const usePreselectFilter = (
  entities: ConsistencyFilterEntities,
  filterFromStorage: IListingFilter,
  preselected: PreselectedFilterIds | undefined,
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined,
): IListingFilter => {
  return useMemo(() => {
    const preselectedFilter = {
      selectedContentTypesNodes:
        forcedContentTypeIds ||
        new Set(
          preselected?.contentTypeIds?.filter((preselectedId) =>
            entities.contentTypeIds.includes(preselectedId),
          ) ?? filterFromStorage.selectedContentTypesNodes,
        ),
      selectedCollectionsNodes: new Set(
        preselected?.collectionIds?.filter((preselectedId) =>
          entities.collectionIds.includes(preselectedId),
        ) ?? filterFromStorage.selectedCollectionsNodes,
      ),
      selectedSpacesNodes: new Set(
        preselected?.spaceIds?.filter((preselectedId) =>
          entities.spaceIds.includes(preselectedId),
        ) ?? filterFromStorage.selectedSpacesNodes,
      ),
    };

    return {
      ...filterFromStorage,
      ...preselectedFilter,
    };
  }, [filterFromStorage, entities, preselected, forcedContentTypeIds]);
};
