import { InvariantException } from '@kontent-ai/errors';
import { fromZonedTime, getTimezoneOffset, toZonedTime } from 'date-fns-tz';
import obsoleteTimeZones from '../../../../../timeZones/obsoleteTimeZones.generated.json';
import standardTimeZones from '../../../../../timeZones/standardTimeZones.generated.json';

export const ensureBrowserCompatibleTimeZone = (timeZoneId: string): string => {
  if (!Number.isNaN(getTimezoneOffset(timeZoneId))) {
    return timeZoneId;
  }

  const fallbackTimeZone = findBrowserCompatibleTimeZoneFallback(timeZoneId);
  if (!fallbackTimeZone) {
    throw InvariantException(
      `${timeZoneId} is not supported by the browser and has no fallback. (${ensureBrowserCompatibleTimeZone.name})`,
    );
  }

  return fallbackTimeZone;
};

const findBrowserCompatibleTimeZoneFallback = (timeZoneId: string): string | undefined =>
  standardTimeZones
    .find((tz) => tz.name === findCardinalTimeZoneForId(timeZoneId, standardTimeZones))
    ?.group.find((tz) => !Number.isNaN(getTimezoneOffset(tz)));

export const transferBetweenTimeZonesKeepingLocalTime = (
  utcDateTime: DateTimeStamp,
  fromTimeZoneId: string,
  toTimeZoneId: string,
): DateTimeStamp => {
  const ensuredFromTimeZoneId = ensureBrowserCompatibleTimeZone(fromTimeZoneId);
  const ensuredToTimeZoneId = ensureBrowserCompatibleTimeZone(toTimeZoneId);

  return fromZonedTime(
    toZonedTime(utcDateTime, ensuredFromTimeZoneId),
    ensuredToTimeZoneId,
  ).toISOString();
};

export const findCardinalTimeZoneForId = (
  id: string,
  timeZones: ReadonlyArray<{ readonly name: string; readonly group: ReadonlyArray<string> }>,
): string | null =>
  timeZones.find((tz) => tz.name === id)?.name ??
  timeZones.find((tz) => tz.group.includes(id))?.name ??
  null;

export const getObsoleteTimeZoneNameTooltipText = (id: string): string =>
  `This time zone name is obsolete. Please use the new name ${findCardinalTimeZoneForId(
    id,
    standardTimeZones,
  )}.`;

export const UtcTimeZoneId = 'UTC';
export const StandardTimeZoneIds = new Set<string>(standardTimeZones.map((tz) => tz.name));
export const ObsoleteTimeZoneIds = new Set<string>(obsoleteTimeZones.map((tz) => tz.name));
export const LocalTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone ?? UtcTimeZoneId;
export const CardinalLocalTimeZoneId = findCardinalTimeZoneForId(
  LocalTimeZoneId,
  standardTimeZones,
);
export const DefaultDisplayTimeZoneId = CardinalLocalTimeZoneId ?? UtcTimeZoneId;
