import { EditorState } from 'draft-js';
import { ITextItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { exportToServerPlainText } from '../../../richText/utils/export/plainText/exportToPlainText.ts';
import { importSimpleTextFromHtml } from '../../../richText/utils/import/importRichTextFromHtml.ts';
import {
  ICreateRichTextDomainModel,
  ICreateRichTextServerModel,
} from '../../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  ITextItemElement,
  newTextItemElement,
} from '../../models/contentItemElements/TextItemElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export interface ITextItemElementDomainModelConverterDependencies {
  readonly createRichTextDomainModel: ICreateRichTextDomainModel;
}

export interface ITextItemElementServerModelConverterDependencies {
  readonly createRichTextServerModel: ICreateRichTextServerModel;
}

function convertTextElementToDomainModel(
  dependencies: ITextItemElementDomainModelConverterDependencies,
  element: ITextItemElementServerModel,
): ITextItemElement {
  const { content, updatedServerValues } = dependencies.createRichTextDomainModel(element, {
    ...dependencies,
    importFromValue: importSimpleTextFromHtml,
    getValue: exportToServerPlainText,
  });

  return newTextItemElement({
    elementId: element.elementId,
    type: ElementType.Text,
    lastModifiedBy: element.lastModifiedBy ? element.lastModifiedBy : null,
    lastModifiedAt: element.lastModifiedAt ? element.lastModifiedAt : null,
    jsonValue: element.jsonValue ?? '',
    _editorState: EditorState.createWithContent(content),
    ...updatedServerValues,
  });
}

function convertTextElementToServerModel(
  dependencies: ITextItemElementServerModelConverterDependencies,
  element: ITextItemElement,
): ITextItemElementServerModel {
  const richTextServerModel = dependencies.createRichTextServerModel(
    element._editorState.getCurrentContent(),
    { getValue: exportToServerPlainText },
  );

  return {
    elementId: element.elementId,
    type: ElementType.Text,
    ...richTextServerModel,
    lastModifiedBy: element.lastModifiedBy,
    lastModifiedAt: element.lastModifiedAt,
  };
}

export const createTextElementDomainModelConverter =
  (
    dependencies: ITextItemElementDomainModelConverterDependencies,
  ): ISimpleItemElementDomainModelConverter<ITextItemElementServerModel, ITextItemElement> =>
  (element) =>
    convertTextElementToDomainModel(dependencies, element);

export const createTextElementServerModelConverter =
  (
    dependencies: ITextItemElementServerModelConverterDependencies,
  ): ISimpleItemElementServerModelConverter<ITextItemElement, ITextItemElementServerModel> =>
  (element) =>
    convertTextElementToServerModel(dependencies, element);
