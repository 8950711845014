import { Button } from '@kontent-ai/component-library/Button';
import classNames from 'classnames';
import { FC } from 'react';

interface ICardProps {
  readonly imageClassName: string;
  readonly title: string;
  readonly text: string;
  readonly buttonText: string;
  readonly onClick: () => void;
  readonly isButtonPrimary?: boolean;
}

export const OnboardingCard: FC<ICardProps> = (props) => (
  <div className="onboarding-card">
    <div className={classNames('onboarding-card__image', props.imageClassName)} />
    <div className="onboarding-card__body">
      <div className="onboarding-card__content">
        <span className="onboarding-card__title">{props.title}</span>
        <div className="onboarding-card__text">{props.text}</div>
      </div>
      <div className="onboarding-card__button">
        <Button
          buttonStyle={props.isButtonPrimary ? 'primary' : 'secondary'}
          onClick={props.onClick}
        >
          {props.buttonText}
        </Button>
      </div>
    </div>
  </div>
);

OnboardingCard.displayName = 'OnboardingCard';
