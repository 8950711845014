export const AssetLibrary_AssetEditor_ModalClosed = 'AssetLibrary_AssetEditor_ModalClosed';
export const AssetLibrary_AssetEditor_InitFinished = 'AssetLibrary_AssetEditor_InitFinished';
export const AssetLibrary_AssetEditor_InitStarted = 'AssetLibrary_AssetEditor_InitStarted';
export const AssetLibrary_AssetEditor_SaveFailed = 'AssetLibrary_AssetEditor_SaveFailed';
export const AssetLibrary_AssetEditor_SaveFinished = 'AssetLibrary_AssetEditor_SaveFinished';
export const AssetLibrary_AssetEditor_SaveStarted = 'AssetLibrary_AssetEditor_SaveStarted';
export const AssetLibrary_AssetEditorDialog_InitStarted =
  'AssetLibrary_AssetEditorDialog_InitStarted';
export const AssetLibrary_AssetEditorDialog_InitFinished =
  'AssetLibrary_AssetEditorDialog_InitFinished';
export const AssetLibrary_Asset_ArchiveCompleted = 'AssetLibrary_Asset_ArchiveCompleted';
export const AssetLibrary_Asset_ArchiveFailed = 'AssetLibrary_Asset_ArchiveFailed';
export const AssetLibrary_Asset_ArchiveStarted = 'AssetLibrary_Assets_ArchiveStarted';
export const AssetLibrary_Asset_AssetsInEditedItemValidationFinished =
  'AssetLibrary_Asset_AssetsInEditedItemValidationFinished';
export const AssetLibrary_Asset_Edit = 'AssetLibrary_Asset_Edit';
export const AssetLibrary_Asset_ReplaceFailed = 'AssetLibrary_Asset_ReplaceFailed';
export const AssetLibrary_Asset_ReplaceFinished = 'AssetLibrary_Asset_ReplaceFinished';
export const AssetLibrary_Asset_ReplaceStarted = 'AssetLibrary_Asset_ReplaceStarted';
export const AssetLibrary_Asset_TitleChanged = 'AssetLibrary_Asset_TitleChanged';
export const AssetLibrary_Asset_CodenameChanged = 'AssetLibrary_Asset_CodenameChanged';
export const AssetLibrary_Asset_UsageLoaded = 'AssetLibrary_Asset_UsageLoaded';
export const AssetLibrary_Assets_ArchiveCompleted = 'AssetLibrary_Assets_ArchiveCompleted';
export const AssetLibrary_Assets_ArchiveFailed = 'AssetLibrary_Assets_ArchiveFailed';
export const AssetLibrary_Assets_ArchiveStarted = 'AssetLibrary_Assets_ArchiveStarted';
export const AssetLibrary_Assets_AssignTermsStarted = 'AssetLibrary_Assets_AssignTermsStarted';
export const AssetLibrary_Assets_AssignTermsCompleted = 'AssetLibrary_Assets_AssignTermsCompleted';
export const AssetLibrary_Assets_CreateAssets = 'AssetLibrary_Assets_CreateAsset';
export const AssetLibrary_Assets_CreateAssetsFromEditing =
  'AssetLibrary_Assets_CreateAssetsFromEditing';
export const AssetLibrary_Assets_MoveBackToCollectionCompleted =
  'AssetLibrary_Assets_MoveBackToCollectionCompleted';
export const AssetLibrary_Assets_MoveBackToCollectionFailed =
  'AssetLibrary_Assets_MoveBackToCollectionFailed';
export const AssetLibrary_Assets_MoveBackToCollectionStarted =
  'AssetLibrary_Assets_MoveBackToCollectionStarted';
export const AssetLibrary_Assets_MoveBackToFolderCompleted =
  'AssetLibrary_Assets_MoveBackToFolderCompleted';
export const AssetLibrary_Assets_MoveBackToFolderFailed =
  'AssetLibrary_Assets_MoveBackToFolderFailed';
export const AssetLibrary_Assets_MoveBackToFolderStarted =
  'AssetLibrary_Assets_MoveBackToFolderStarted';
export const AssetLibrary_Assets_MoveToCollectionCompleted =
  'AssetLibrary_Assets_MoveToCollectionCompleted';
export const AssetLibrary_Assets_MoveToCollectionStarted =
  'AssetLibrary_Assets_MoveToCollectionStarted';
export const AssetLibrary_Assets_MovingToCollectionFailed =
  'AssetLibrary_Assets_MovingToCollectionFailed';
export const AssetLibrary_Assets_MoveToFolderCompleted =
  'AssetLibrary_Assets_MoveToFolderCompleted';
export const AssetLibrary_Assets_MoveToFolderStarted = 'AssetLibrary_Assets_MoveToFolderStarted';
export const AssetLibrary_Assets_MovingToFolderFailed = 'AssetLibrary_Assets_MovingToFolderFailed';
export const AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers =
  'AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers';
export const AssetLibrary_Asset_RenditionCreated = 'AssetLibrary_Asset_RenditionCreated';
export const AssetLibrary_Asset_RenditionUpdated = 'AssetLibrary_Asset_RenditionUpdated';
export const AssetLibrary_Assets_RemoveFailedAsset = 'AssetLibrary_Assets_RemoveFailedAsset';
export const AssetLibrary_Assets_ReportUploadProgress = 'AssetLibrary_Assets_ReportUploadProgress';
export const AssetLibrary_Assets_RestoreCompleted = 'AssetLibrary_Assets_RestoreCompleted';
export const AssetLibrary_Assets_RestoreFailed = 'AssetLibrary_Assets_RestoreFailed';
export const AssetLibrary_Assets_RestoreStarted = 'AssetLibrary_Assets_RestoreStarted';
export const AssetLibrary_Assets_ShowUnsupportedFileTypeError =
  'AssetLibrary_Assets_ShowUnsupportedFileTypeError';
export const AssetLibrary_Assets_ToggleAssetSelection = 'AssetLibrary_Assets_ToggleAssetSelection';
export const AssetLibrary_Assets_UploadFailed = 'AssetLibrary_Assets_UploadFailed';
export const AssetLibrary_Assets_UploadFinished = 'AssetLibrary_Assets_UploadFinished';
export const AssetLibrary_EditedAsset_DeletionFinished =
  'AssetLibrary_EditedAsset_DeletionFinished';
export const AssetLibrary_EditedAsset_DeletionStarted = 'AssetLibrary_EditedAsset_DeletionStarted';
export const AssetLibrary_Folders_ArchiveCompleted = 'AssetLibrary_Folders_ArchiveCompleted';
export const AssetLibrary_Folders_ArchiveFailed = 'AssetLibrary_Folders_ArchiveFailed';
export const AssetLibrary_Folders_ArchiveStarted = 'AssetLibrary_Folders_ArchiveStarted';
export const AssetLibrary_Folders_Created = 'AssetLibrary_Folders_Created';
export const AssetLibrary_Folders_MovingBackCompleted = 'AssetLibrary_Folders_MovingBackCompleted';
export const AssetLibrary_Folders_MovingBackFailed = 'AssetLibrary_Folders_MovingBackFailed';
export const AssetLibrary_Folders_MovingBackStarted = 'AssetLibrary_Folders_MovingBackStarted';
export const AssetLibrary_Folders_MovingCompleted = 'AssetLibrary_Folders_MovingCompleted';
export const AssetLibrary_Folders_MovingFailed = 'AssetLibrary_Folders_MovingFailed';
export const AssetLibrary_Folders_MovingStarted = 'AssetLibrary_Folders_MovingStarted';
export const AssetLibrary_Folders_Navigating = 'AssetLibrary_Folders_Navigating';
export const AssetLibrary_Folders_Opened = 'AssetLibrary_Folders_Opened';
export const AssetLibrary_Folders_RestoreCompleted = 'AssetLibrary_Folders_RestoreCompleted';
export const AssetLibrary_Folders_RestoreFailed = 'AssetLibrary_Folders_RestoreFailed';
export const AssetLibrary_Folders_RestoreStarted = 'AssetLibrary_Folders_RestoreStarted';
export const AssetLibrary_Folders_Updated = 'AssetLibrary_Folders_Updated';
export const AssetLibrary_Initialized = 'AssetLibrary_Initialized';
export const AssetLibrary_OrderBy_Changed = 'AssetLibrary_OrderBy_Changed';
export const AssetLibrary_Query_Changed = 'AssetLibrary_Query_Changed';
export const AssetLibrary_Query_FilterCleared = 'AssetLibrary_Query_FilterCleared';
export const AssetLibrary_Query_Loaded = 'AssetLibrary_Query_Loaded';
export const AssetLibrary_Query_Loading = 'AssetLibrary_Query_Loading';
export const AssetLibrary_Reloading_Started = 'AssetLibrary_Reloading_Started';
export const AssetLibrary_Route_Left = 'AssetLibrary_Route_Left';
export const AssetLibrary_ScrollGridState_Changed = 'AssetLibrary_ScrollGridState_Changed';
