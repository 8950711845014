import { RelationsConfigStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

export interface IRelationsConfigStorage {
  readonly rootId: Uuid | null;
}

const initialState: IRelationsConfigStorage = {
  rootId: null,
};

const relationsConfigStorageParams: StorageParams<IRelationsConfigStorage> = {
  key: RelationsConfigStorageKey,
  initialState,
  fromJS: (raw) => raw as any,
};

export const RelationsConfigStorage = getProjectSpecificStorage<IRelationsConfigStorage>(
  relationsConfigStorageParams,
);
