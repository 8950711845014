import { EditorState } from 'draft-js';
import {
  IRichTextItemElementServerModel,
  IUrlSlugItemElementServerModel,
} from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { exportToServerPlainText } from '../../../richText/utils/export/plainText/exportToPlainText.ts';
import { importSimpleTextFromHtml } from '../../../richText/utils/import/importRichTextFromHtml.ts';
import {
  ICreateRichTextDomainModel,
  ICreateRichTextServerModel,
  IRichTextServerModel,
} from '../../../richText/utils/serverModel/editorServerModelUtils.ts';
import { UrlSlugMode } from '../../constants/urlSlugMode.ts';
import {
  IUrlSlugItemElement,
  newUrlSlugItemElement,
} from '../../models/contentItemElements/UrlSlugItemElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export interface IUrlSlugElementDomainModelConverterDependencies {
  createRichTextDomainModel: ICreateRichTextDomainModel;
}

export interface IUrlSlugElementServerModelConverterDependencies {
  createRichTextServerModel: ICreateRichTextServerModel;
}

function getRichTextServerModelFromElement(
  elementServerModel: IRichTextItemElementServerModel,
): IRichTextServerModel {
  return {
    value: elementServerModel.value,
    jsonValue: elementServerModel.jsonValue,
  };
}

function convertUrlSlugElementToDomainModel(
  dependencies: IUrlSlugElementDomainModelConverterDependencies,
  element: IUrlSlugItemElementServerModel,
): IUrlSlugItemElement {
  const richTextServerModel = getRichTextServerModelFromElement(element);
  const { content, updatedServerValues } = dependencies.createRichTextDomainModel(
    richTextServerModel,
    {
      ...dependencies,
      importFromValue: importSimpleTextFromHtml,
      getValue: exportToServerPlainText,
    },
  );

  return newUrlSlugItemElement({
    elementId: element.elementId,
    type: ElementType.UrlSlug,
    jsonValue: element.jsonValue ?? '',
    mode: element.mode ?? UrlSlugMode.Auto,
    _editorState: EditorState.createWithContent(content),
    ...updatedServerValues,
    lastModifiedBy: element.lastModifiedBy ? element.lastModifiedBy : null,
    lastModifiedAt: element.lastModifiedAt ? element.lastModifiedAt : null,
  });
}

function convertUrlSlugElementToServerModel(
  dependencies: IUrlSlugElementServerModelConverterDependencies,
  element: IUrlSlugItemElement,
): IUrlSlugItemElementServerModel {
  const richTextServerModel = dependencies.createRichTextServerModel(
    element._editorState.getCurrentContent(),
    { getValue: exportToServerPlainText },
  );

  return {
    elementId: element.elementId,
    type: ElementType.UrlSlug,
    mode: element.mode,
    lastModifiedBy: element.lastModifiedBy,
    lastModifiedAt: element.lastModifiedAt,
    ...richTextServerModel,
  };
}

export const createUrlSlugElementDomainModelConverter =
  (
    dependencies: IUrlSlugElementDomainModelConverterDependencies,
  ): ISimpleItemElementDomainModelConverter<IUrlSlugItemElementServerModel, IUrlSlugItemElement> =>
  (element) =>
    convertUrlSlugElementToDomainModel(dependencies, element);

export const createUrlSlugElementServerModelConverter =
  (
    dependencies: IUrlSlugElementServerModelConverterDependencies,
  ): ISimpleItemElementServerModelConverter<IUrlSlugItemElement, IUrlSlugItemElementServerModel> =>
  (element) =>
    convertUrlSlugElementToServerModel(dependencies, element);
