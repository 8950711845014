import { OptionType } from '../components/MultipleOptionSelect/MultipleSelectDropDownOption.tsx';

export interface IPathItem {
  readonly id: Uuid;
  readonly name: string;
}

export interface IBaseMultipleOptionItem {
  readonly id: Uuid;
  readonly name: string;
  readonly selectedName: string;
  readonly type: OptionType;
}

export interface IHierarchicalItem extends IBaseMultipleOptionItem {
  readonly indentation: number;
  readonly path: ReadonlyArray<IPathItem>;
}

type IFlatten<T> = (
  topLevelItem: T,
  topLevelPath: ReadonlyArray<IPathItem>,
) => ReadonlyArray<IHierarchicalItem>;

export function createFlattenItems<T>(
  mapper: (source: T) => IBaseMultipleOptionItem,
  getChildren: (source: T) => ReadonlyArray<T>,
): IFlatten<T> {
  const flatten = (
    topLevelItem: T,
    topLevelPath: ReadonlyArray<IPathItem>,
  ): ReadonlyArray<IHierarchicalItem> => {
    const items: Array<IHierarchicalItem> = [];

    const processItem = (item: T, indentation: number, path: ReadonlyArray<IPathItem>): void => {
      const baseItem = mapper(item);
      const hierarchicalItem = {
        ...baseItem,
        indentation,
        path,
      };

      items.push(hierarchicalItem);
      const pathToChild = [
        ...path,
        {
          id: hierarchicalItem.id,
          name: hierarchicalItem.name,
        },
      ];
      const children = getChildren(item);
      children.forEach((child: T) => processItem(child, indentation + 1, pathToChild));
    };

    processItem(topLevelItem, 0, topLevelPath);
    return items;
  };

  return flatten;
}

export function getDuplicateNames<T>(
  items: ReadonlyArray<T>,
  getName: (item: T) => string,
): ReadonlySet<string> {
  const names = items.map(getName);
  return new Set(names.filter((item, index) => names.indexOf(item) !== index));
}
