import React from 'react';
import { getDataUiObjectNameAttribute } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionNameEditor } from '../containers/SubscriptionNameEditor.tsx';

export const SubscriptionNameTile: React.FC = () => {
  return (
    <div
      className="general-settings__tile"
      {...(SubscriptionNameTile.displayName &&
        getDataUiObjectNameAttribute(SubscriptionNameTile.displayName))}
    >
      <section className="card__pane">
        <div className="card__content-section">
          <div className="card__title-section">
            <h1 className="card__title">Subscription name</h1>
          </div>
          <SubscriptionNameEditor />
        </div>
      </section>
    </div>
  );
};

SubscriptionNameTile.displayName = 'SubscriptionNameTile';
