import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { ValidatedInput } from '../../../_shared/components/input/ValidatedInput.tsx';
import { HookFormProps } from '../../../_shared/types/hookFormProps.type.ts';
import { LabelFor } from '../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IUserProfileFormShape } from '../models/IUserProfileFormShape.type.ts';

type Props = {
  readonly email: string;
  readonly formProps: HookFormProps<IUserProfileFormShape>;
  readonly isPristine: boolean;
  readonly isSubmitting: boolean;
  readonly onSubmit: () => void;
};

export const UserProfileForm: FC<Props> = ({
  email,
  formProps,
  isPristine,
  isSubmitting,
  onSubmit,
}) => (
  <section className="card__pane">
    <form onSubmit={onSubmit}>
      <div className="user-profile__section-heading">
        <h2 className="user-profile__section-title user-profile__section-title--with-status-message">
          Profile details
        </h2>
      </div>
      <div className="user-profile__form-group">
        <LabelFor
          className="user-profile__label"
          target={(id: Uuid) => (
            <input
              id={id}
              autoComplete="off"
              type="text"
              value={email}
              className="form__text-field"
              disabled
              readOnly
            />
          )}
        >
          Email
        </LabelFor>
      </div>
      <div className="user-profile__form-group">
        <ValidatedInput<IUserProfileFormShape>
          label="First name"
          name="firstName"
          maxLength={200}
          formProps={formProps}
        />
      </div>
      <div className="user-profile__form-group">
        <ValidatedInput<IUserProfileFormShape>
          label="Last name"
          name="lastName"
          maxLength={200}
          formProps={formProps}
        />
      </div>
      <div className="user-profile__form-submit-group">
        <Button buttonStyle="primary" type="submit" disabled={isSubmitting || isPristine}>
          {isSubmitting && (
            <Button.ProgressIcon
              {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
            />
          )}
          <Button.Label>{isSubmitting ? 'Updating profile' : 'Update profile'}</Button.Label>
        </Button>
      </div>
    </form>
  </section>
);

UserProfileForm.displayName = 'UserProfileForm';
