import { ISubscriptionTransactionServerModel } from '../../../../repositories/serverModels/ISubscriptionTransactionServerModel.type.ts';

export interface ISubscriptionTransaction {
  readonly subscriptionId: Uuid;
  readonly amount: number;
  readonly description: string;
  readonly transactionTime: DateTimeStamp;
  readonly initiator: string;
  readonly type: string;
  readonly referenceId: string;
  readonly billingPeriodStart: DateTimeStamp;
  readonly billingPeriodEnd: DateTimeStamp;
}

export function getSubscriptionTransactionFromServerModel(
  rawSubscriptionTransaction?: Partial<ISubscriptionTransactionServerModel>,
): ISubscriptionTransaction {
  if (typeof rawSubscriptionTransaction !== 'object') {
    throw new Error('Subscription credit transaction is not an object.');
  }

  return {
    subscriptionId: rawSubscriptionTransaction.subscriptionId || '',
    amount: rawSubscriptionTransaction.amount || 0,
    description: rawSubscriptionTransaction.description || '',
    transactionTime: rawSubscriptionTransaction.transactionTime || '',
    initiator: rawSubscriptionTransaction.initiator || '',
    type: rawSubscriptionTransaction.type || '',
    referenceId: rawSubscriptionTransaction.referenceId || '',
    billingPeriodStart: rawSubscriptionTransaction.billingPeriodStart || '',
    billingPeriodEnd: rawSubscriptionTransaction.billingPeriodEnd || '',
  };
}
