import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import React from 'react';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly onChange: (value: string) => void;
  readonly onClear: () => void;
  readonly searchPhrase: string;
  readonly placeholderText: string;
  readonly isDisabled?: boolean;
  readonly disabledInputToolTipText?: string;
};

const clearActionToolTip = 'Clear the search phrase.';

export const SearchInput: React.FC<Props> = ({
  searchPhrase,
  onClear,
  onChange,
  placeholderText,
  isDisabled,
  disabledInputToolTipText,
}) => {
  const clearSearchButton =
    (searchPhrase.length > 0 && (
      <QuinaryButton
        tooltipPlacement="bottom"
        tooltipText={clearActionToolTip}
        onClick={() => onClear()}
        {...getDataUiActionAttribute(DataUiAction.ClearInput)}
      >
        <QuinaryButton.Icon icon={Icons.TimesCircle} screenReaderText={clearActionToolTip} />
      </QuinaryButton>
    )) ||
    undefined;

  return (
    <Input
      onChange={(e) => onChange(e.currentTarget.value)}
      placeholder={placeholderText}
      value={searchPhrase}
      inputState={isDisabled ? InputState.Disabled : InputState.Default}
      suffixes={[clearSearchButton]}
      tooltipText={disabledInputToolTipText}
      {...getDataUiInputAttribute(DataUiInput.Filter)}
    />
  );
};
