import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { useHotkeys } from '@kontent-ai/component-library/hooks';
import { spacingBetweenButtonsHorizontal } from '@kontent-ai/component-library/tokens';
import { ChangeEvent, useState } from 'react';
import { SaveChangesButton } from '../../../../../../../_shared/components/SaveChangesButton.tsx';
import { ShortcutSymbols } from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UntitledFilter } from '../../constants/uiConstants.ts';

export interface IFilterNameFormStateProps {
  readonly disabled: boolean;
}

export interface IFilterNameFormDispatchProps {
  readonly onSave: (name: string) => void;
}

export interface IFilterNameFormOwnProps {
  readonly name?: string;
  readonly onClose: () => void;
}

type FilterNameFormProps = IFilterNameFormStateProps &
  IFilterNameFormDispatchProps &
  IFilterNameFormOwnProps;

export const FilterNameForm = ({ disabled, name, onClose, onSave }: FilterNameFormProps) => {
  const [newName, setNewName] = useState<string>(name || '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value);

  const handleSave = () => {
    onSave(newName);
    onClose();
  };

  const hotkeysProps = useHotkeys({
    enter: handleSave,
  });

  return (
    <div className="filter-name-editor" {...getDataUiElementAttribute(DataUiElement.FilterForm)}>
      <div {...hotkeysProps}>
        <LabelFor
          target={(inputId: Uuid) => (
            <TextField
              autoFocus
              disabled={disabled}
              inputId={inputId}
              placeholder={UntitledFilter}
              value={newName}
              maxLength={ValidationConstants.FilterNameMaxLength}
              onChange={handleChange}
              dataUiInputName={DataUiInput.FilterName}
            />
          )}
        >
          Filter name
        </LabelFor>
      </div>
      <div className="filter-name-editor__action-button-pane">
        <Inline spacingX={spacingBetweenButtonsHorizontal}>
          <Button
            buttonStyle="secondary"
            onClick={onClose}
            tooltipPlacement="bottom-end"
            tooltipShortcuts={ShortcutSymbols.Escape}
            tooltipText="Cancel"
            {...getDataUiActionAttribute(DataUiAction.Cancel)}
          >
            Cancel
          </Button>
          <SaveChangesButton
            buttonStyle="default"
            disabled={disabled}
            isBeingSaved={false}
            onSave={handleSave}
            shortcuts={ShortcutSymbols.Enter}
            text="Save"
          />
        </Inline>
      </div>
    </div>
  );
};
