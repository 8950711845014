import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { applyReturnScrollId } from '../../actions/sharedActions.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';

export const ReturnAutoScrollHandler: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname: currentPath } = useLocation();
  const returnAutoScrollTarget = useSelector((state) =>
    state.sharedApp.returnAutoScrollTargetByPath.get(currentPath),
  );

  useEffect(() => {
    if (returnAutoScrollTarget) {
      dispatch(applyReturnScrollId(currentPath, returnAutoScrollTarget));
    }
  }, [currentPath, returnAutoScrollTarget]);

  return null;
};

ReturnAutoScrollHandler.displayName = 'ReturnAutoScrollHandler';
