import { ComponentProps } from 'react';
import {
  colorBackgroundHover,
  colorBackgroundHoverInverse,
  colorIconDefault,
  colorIconDefaultInverse,
} from '../../../tokens/decision/colors.ts';
import { BaseColor, Color } from '../../../tokens/quarks/colors.ts';
import { Hint } from '../Hint.tsx';

export interface IHintTokens {
  readonly backgroundColor: Color;
  readonly backgroundColorHover: Color;
  readonly iconColor: BaseColor;
  readonly iconColorHover: BaseColor;
}

const defaultTokens: IHintTokens = {
  backgroundColor: BaseColor.Transparent,
  backgroundColorHover: colorBackgroundHover,
  iconColor: colorIconDefault,
  iconColorHover: colorIconDefault,
};

const defaultInverseTokens: IHintTokens = {
  backgroundColor: BaseColor.Transparent,
  backgroundColorHover: colorBackgroundHoverInverse,
  iconColor: colorIconDefaultInverse,
  iconColorHover: colorIconDefaultInverse,
};

export const getHintTokens = (
  props: Pick<ComponentProps<typeof Hint>, 'hintStyle'>,
): IHintTokens => {
  const isInverse = props.hintStyle === 'inverse';

  return isInverse ? defaultInverseTokens : defaultTokens;
};
