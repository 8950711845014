import { memoize } from '@kontent-ai/memoization';
export enum AutoScrollTargetType {
  Asset = 'asset',
  AssetTile = 'asset_tile',
  CommentThread = 'commentthread',
  ComponentElement = 'componentelement',
  ContentComponent = 'contentcomponent',
  ContentItem = 'content_item',
  ContentLink = 'richtext_link',
  Element = 'element',
  LinkedItem = 'modularelement',
  RichTextBlock = 'richtext_block',
  Task = 'task',
  UsageItem = 'usage',
}

export interface AutoScrollId {
  readonly targetType: AutoScrollTargetType;
  readonly key: string;
}

export interface AutoScrollWithInternalTarget extends AutoScrollId {
  readonly internalTargetId: string | null;
}

export interface ElementAutoScrollId extends AutoScrollWithInternalTarget {
  readonly elementCodename: string;
  readonly contentItemId: Uuid;
}

export function isElementAutoScrollId(
  autoScrollId: AutoScrollId,
): autoScrollId is ElementAutoScrollId {
  return autoScrollId.targetType === AutoScrollTargetType.Element;
}

export interface ComponentElementAutoScrollId extends ElementAutoScrollId {
  readonly deliveryContentComponentId: Uuid;
}

export interface ContentComponentAutoScrollId extends AutoScrollId {
  readonly contentItemId: Uuid;
  readonly deliveryContentComponentId: Uuid;
}

export function isAutoScrollWithInternalTarget(
  autoScrollId: AutoScrollId,
): autoScrollId is AutoScrollWithInternalTarget {
  return 'internalTargetId' in autoScrollId;
}

export function isComponentElementAutoScrollId(
  autoScrollId: AutoScrollId,
): autoScrollId is ComponentElementAutoScrollId {
  return autoScrollId.targetType === AutoScrollTargetType.ComponentElement;
}

export function isContentComponentAutoScrollId(
  autoScrollId: AutoScrollId,
): autoScrollId is ContentComponentAutoScrollId {
  return autoScrollId.targetType === AutoScrollTargetType.ContentComponent;
}

export const CreateAutoScrollId = Object.freeze({
  forAsset: memoize.allForever((assetId: Uuid): AutoScrollId => {
    return {
      key: assetId,
      targetType: AutoScrollTargetType.Asset,
    };
  }),

  forAssetTile: memoize.allForever((assetId: Uuid): AutoScrollId => {
    return {
      targetType: AutoScrollTargetType.AssetTile,
      key: assetId,
    };
  }),

  forContentItem: memoize.allForever((contentItemId: Uuid): AutoScrollId => {
    return {
      key: contentItemId,
      targetType: AutoScrollTargetType.ContentItem,
    };
  }),

  forUsageItem: memoize.allForever((usageItemId: Uuid): AutoScrollId => {
    return {
      key: usageItemId,
      targetType: AutoScrollTargetType.UsageItem,
    };
  }),

  forContentLink: memoize.allForever(
    (elementId: Uuid, contentItemId: Uuid, text: string): AutoScrollId => {
      return {
        targetType: AutoScrollTargetType.ContentLink,
        key: `${elementId}_${contentItemId}_${text}`,
      };
    },
  ),

  forLinkedItem: memoize.allForever(
    (elementId: Uuid, contentComponentId: Uuid, contentItemId: Uuid): AutoScrollId => {
      return {
        key: `${elementId}_contentComponentId_${contentComponentId}_contentitem_${contentItemId}`,
        targetType: AutoScrollTargetType.LinkedItem,
      };
    },
  ),

  forRichTextBlock: memoize.allForever((blockKey: string): AutoScrollId => {
    return {
      targetType: AutoScrollTargetType.RichTextBlock,
      key: blockKey,
    };
  }),

  forElement: memoize.allForever(
    (
      elementCodename: string,
      contentItemId: Uuid,
      internalTargetId: string | null = null,
    ): ElementAutoScrollId => {
      return {
        contentItemId,
        internalTargetId,
        elementCodename,
        key: `${elementCodename}_${contentItemId}`,
        targetType: AutoScrollTargetType.Element,
      };
    },
  ),

  forComponentElement: memoize.allForever(
    (
      elementCodename: string,
      deliveryContentComponentId: Uuid,
      contentItemId: Uuid,
      internalTargetId: string | null = null,
    ): ComponentElementAutoScrollId => {
      return {
        contentItemId,
        internalTargetId,
        deliveryContentComponentId,
        elementCodename,
        key: `${elementCodename}_${deliveryContentComponentId}_${contentItemId}`,
        targetType: AutoScrollTargetType.ComponentElement,
      };
    },
  ),

  forContentComponent: memoize.allForever(
    (deliveryContentComponentId: Uuid, contentItemId: Uuid): ContentComponentAutoScrollId => {
      return {
        contentItemId,
        deliveryContentComponentId,
        key: `${deliveryContentComponentId}_${contentItemId}`,
        targetType: AutoScrollTargetType.ContentComponent,
      };
    },
  ),

  forCommentThread: memoize.allForever((threadId: Uuid): AutoScrollId => {
    return {
      key: threadId,
      targetType: AutoScrollTargetType.CommentThread,
    };
  }),

  forTask: memoize.allForever((taskId: Uuid): AutoScrollId => {
    return {
      key: taskId,
      targetType: AutoScrollTargetType.Task,
    };
  }),
});

export const areAutoScrollIdsEqual = (a?: AutoScrollId, b?: AutoScrollId): boolean => {
  return a === b || (!!a && !!b && a.targetType === b.targetType && a.key === b.key);
};
