import { Capability } from '../../../../_shared/utils/permissions/capability.ts';

export const contentRequiredCapabilities = [
  Capability.UpdateContent,
  Capability.ViewContent,
] as const;

export const assetsRequiredCapabilities = [Capability.ManageAssets, Capability.ViewAssets] as const;

export const relationsRequiredCapabilities = [Capability.ViewContent] as const;

export const contentInventoryRequiredCapabilities = [
  ...contentRequiredCapabilities,
  ...assetsRequiredCapabilities,
] as const;
