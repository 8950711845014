import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IPlan } from '../../../../../../data/models/plans/Plan.ts';
import { getSubscriptionPlan } from '../../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  FeatureUnavailableInCurrentPlan,
  ProvideAbsoluteUrl,
  ProvideValidJsonObject,
  UseSecureHTTP,
} from '../../../constants/errorMessageTemplates.ts';
import { ICustomTypeElementData } from '../../../models/elements/CustomTypeElementData.tsx';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { CustomTypeElementValidationResult } from '../../../utils/typeElementValidators/types/CustomTypeElementValidationResult.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

function getCustomTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  plan: IPlan,
  typeElement: ICustomTypeElementData,
  conditionValidationData: ConditionValidationData,
): CustomTypeElementValidationResult {
  const {
    getNameValidationResult,
    getGuidelinesValidationResult,
    isValidUrl,
    isValidJsonObject,
    urlStartsWithHttps,
  } = typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);
  const config = typeElement.config;
  const isSourceUrlValid = isValidUrl(typeElement.sourceUrl);
  const sourceUrlStartsWithHttps = urlStartsWithHttps(typeElement.sourceUrl);
  const isConfigValid = !config || isValidJsonObject(config);
  const isAvailableInPlan = plan.features.areCustomElementsEnabled;

  const errors: Array<string> = [];
  if (!isAvailableInPlan) {
    errors.push(FeatureUnavailableInCurrentPlan);
  }

  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errors.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errors.push(guidelinesValidationResult.errorMessage);
  }

  if (!isSourceUrlValid) {
    errors.push(ProvideAbsoluteUrl);
  }

  if (!sourceUrlStartsWithHttps && isSourceUrlValid) {
    errors.push(UseSecureHTTP);
  }

  if (!isConfigValid) {
    errors.push(ProvideValidJsonObject);
  }

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errors,
    ],
    isSourceUrlValid: isSourceUrlValid && sourceUrlStartsWithHttps,
    isConfigValid,
    isAvailableInPlan,
  };
}

export const createValidateCustomTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IBaseTypeElementData): ThunkFunction<CustomTypeElementValidationResult> =>
  (dispatch, getState) => {
    const currentProject = getCurrentProject(getState());
    const plan = getSubscriptionPlan(getState(), currentProject.subscriptionId);
    const validationResult = getCustomTypeElementValidationResult(
      deps,
      plan,
      typeElement as ICustomTypeElementData,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
