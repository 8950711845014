import {
  ContentItemOpenTaskRoute,
  ContentItemOpenTaskRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../_shared/models/Task.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersInfo } from '../../../../_shared/utils/usersUtils.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentStatusTasksTableRow as ContentStatusTasksTableRowComponent } from '../components/ContentStatusTasksTableRow.tsx';

type ContentStatusTasksTableRowProps = Readonly<{
  itemId: Uuid;
  task: Task;
  variantId: Uuid;
}>;

export const ContentStatusTasksTableRow: React.FC<ContentStatusTasksTableRowProps> = ({
  itemId,
  task,
  variantId,
}) => {
  const projectId = useSelector(getCurrentProjectId);
  const usersById = useSelector((s) => s.data.users.usersById);

  const linkPath = buildPath<ContentItemOpenTaskRouteParams<UuidArray>>(ContentItemOpenTaskRoute, {
    app: ContentItemsAppRouteSegment.Content,
    taskId: task.id,
    variantId,
    contentItemIds: [itemId],
    spaceId: undefined,
    projectId,
  });

  const userIdentifiers = new Set([...task.assignees].map((userId) => ({ userId })));
  const assignees = Array.from(getUsersInfo(userIdentifiers, usersById));

  return (
    <ContentStatusTasksTableRowComponent assignees={assignees} linkPath={linkPath} task={task} />
  );
};

ContentStatusTasksTableRow.displayName = 'ContentStatusTasksTableRow';
