import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { IAssetTileFolderSummary } from '../../../../../_shared/components/AssetTile/AssetTileFolderSummary.tsx';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { areCollectionsVisibleForAssets } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getCollectionName } from '../../../../../_shared/utils/assets/assetUtils.ts';
import {
  ImageLimitsConfig,
  isResponsiveImage,
  validateDimension,
  validateMaxFileSize,
} from '../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { isImageMimeType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetValidationResult } from '../../../../itemEditor/features/ContentItemEditing/components/elements/asset/AssetTile.tsx';
import {
  AssetTile as AssetTileComponent,
  IAssetTileDispatchProps,
  IAssetTileOwnProps,
  IAssetTileStateProps,
} from '../../components/AssetTile/AssetTile.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { UnknownFolderNamePlaceholder } from '../../constants/uiConstants.ts';
import { isAssetUncategorized } from '../../utils/assetTaxonomyUtils.ts';
import { backTrackOpenedAssetFolders } from '../../utils/backTrackOpenedAssetFolders.ts';

export enum AssetTileMode {
  Default = 'default',
  Search = 'search',
}

export interface IAssetItemConfig {
  readonly imageLimits?: ImageLimitsConfig;
  readonly allowSelect: boolean;
  readonly showImagesOnly?: boolean;
  readonly tileMode: AssetTileMode;
}

interface IContainerProps extends IAssetTileOwnProps {
  readonly config: IAssetItemConfig;
}

const defaultValidationResult: IAssetValidationResult = {
  isAssetFileSizeValid: true,
  isAssetFileTypeValid: true,
  isAssetHeightValid: true,
  isAssetWidthValid: true,
};

const getValidationResultForItem = memoize.weak(
  (item: IAsset, imageLimits: ImageLimitsConfig): IAssetValidationResult => {
    const validationResult = {
      isAssetFileSizeValid: validateMaxFileSize(item.fileSize, imageLimits.maxFileSize),
      isAssetFileTypeValid: !imageLimits.onlyResponsiveImages || isResponsiveImage(item.type),
      isAssetWidthValid: validateDimension(imageLimits.minWidth, imageLimits.maxWidth, item.width),
      isAssetHeightValid: validateDimension(
        imageLimits.minHeight,
        imageLimits.maxHeight,
        item.height,
      ),
    };

    return validationResult;
  },
);

const getValidationResult = (
  item: IAsset,
  imageLimits: ImageLimitsConfig | undefined,
): IAssetValidationResult => {
  return imageLimits ? getValidationResultForItem(item, imageLimits) : defaultValidationResult;
};

interface IDimensionsVisibility {
  readonly showWidth: boolean;
  readonly showHeight: boolean;
}

const getDimensionsVisibility = (imageLimits?: ImageLimitsConfig): IDimensionsVisibility => {
  if (!imageLimits) {
    return {
      showHeight: false,
      showWidth: false,
    };
  }

  const { minHeight, maxHeight, minWidth, maxWidth } = imageLimits;

  return {
    showWidth: !!minWidth || !!maxWidth,
    showHeight: !!minHeight || !!maxHeight,
  };
};

const getAssetFolderSummary = (
  assetFolderId: Uuid,
  state: IStore,
): IAssetTileFolderSummary | null => {
  const {
    data: { assetFolders },
  } = state;

  if (assetFolderId === RootFolderId) {
    return null;
  }

  const folder = assetFolders.taxonomyGroup.terms.get(assetFolderId);

  const openedPath = backTrackOpenedAssetFolders(assetFolders.taxonomyGroup, null, assetFolderId);
  const pathTooltip = openedPath.length > 1 ? openedPath.map((f) => f.name).join(' > ') : undefined;

  return {
    name: folder?.name ?? UnknownFolderNamePlaceholder,
    pathTooltip,
  };
};

const mapStateToProps = (state: IStore, ownProps: IContainerProps): IAssetTileStateProps => {
  const {
    data: {
      assetTypes: { defaultAssetType },
      taxonomyGroups: { byId: taxonomyGroupsById },
      collections: { byId: collectionsById },
    },
  } = state;

  const { config, item } = ownProps;

  const collectionName = areCollectionsVisibleForAssets(
    state,
    Collection.getValues(state.data.collections.byId),
  )
    ? getCollectionName(item.collectionId, collectionsById)
    : null;
  const folderSummary =
    config.tileMode === AssetTileMode.Search ? getAssetFolderSummary(item.folderId, state) : null;
  const { showWidth, showHeight } = getDimensionsVisibility(config.imageLimits);
  const validationResult = getValidationResult(item, config.imageLimits);
  const isSelectable = config.allowSelect && !item.archived && !item._uploading;
  const isUncategorized = isAssetUncategorized(item, defaultAssetType, taxonomyGroupsById);
  const isValidType = !config.showImagesOnly || isImageMimeType(item.type);

  const selectedLanguageId = getSelectedLanguageId(state) ?? DefaultVariantId;

  return {
    collectionName,
    folderName: folderSummary?.name,
    folderPathTooltip: folderSummary?.pathTooltip,
    isSelectable,
    isUncategorized,
    isValidType,
    selectedLanguageId,
    showHeight,
    showWidth,
    validationResult,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IAssetTileDispatchProps => ({
  trackUserEvent: (...args) => dispatch(trackUserEventWithData(...args)),
});

export const AssetTile: React.ComponentType<React.PropsWithChildren<IContainerProps>> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetTileComponent);
