import { Action } from '../../../../../@types/Action.type.ts';
import { WorkflowStepsUsage } from '../../../../../data/models/workflow/WorkflowStepUsage.type.ts';
import {
  Workflow_Editing_Left,
  Workflow_Editing_StepsUsageLoaded,
} from '../../constants/workflowActionTypes.ts';

const initialState: WorkflowStepsUsage = new Map<Uuid, boolean>();

export const workflowStepsUsage = (state = initialState, action: Action): WorkflowStepsUsage => {
  switch (action.type) {
    case Workflow_Editing_Left:
      return initialState;

    case Workflow_Editing_StepsUsageLoaded:
      return Object.entries(action.payload.usages).reduce(
        (usages, [stepId, usage]) => usages.set(stepId, usage),
        new Map<Uuid, boolean>(),
      );

    default: {
      return state;
    }
  }
};
