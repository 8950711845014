import { Action } from '../../../../@types/Action.type.ts';
import {
  LocalizationEditor_DefaultLangEditor_Saved,
  LocalizationEditor_Init_Started,
  LocalizationEditor_Language_Add,
  LocalizationEditor_Language_Update,
  LocalizationEditor_Saving_Failed,
  LocalizationEditor_Saving_Success,
} from '../../../environmentSettings/localization/constants/localizationActionTypes.ts';
import {
  UserDetail_UpdateCollectionGroups_Failed,
  UserDetail_UpdateCollectionGroups_Finished,
  UserDetail_UpdateCollectionGroups_Started,
  Users_ProjectContributorsLoaded,
} from '../../../environmentSettings/users/constants/usersActionTypes.ts';
import {
  Project_RenameProjectOnServer_Failed,
  Project_RenameProjectOnServer_Finished,
} from '../../../projectSettings/general/constants/generalSettingsActionTypes.ts';
import {
  Project_Delete_Failed,
  Project_Delete_Finished,
  Project_Delete_Started,
} from '../../constants/projectActionTypes.ts';

export const serverError = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case LocalizationEditor_DefaultLangEditor_Saved:
    case LocalizationEditor_Init_Started:
    case LocalizationEditor_Language_Add:
    case LocalizationEditor_Language_Update:
    case LocalizationEditor_Saving_Success:
    case Users_ProjectContributorsLoaded:
    case Project_Delete_Finished:
    case Project_Delete_Started:
    case Project_RenameProjectOnServer_Finished:
    case UserDetail_UpdateCollectionGroups_Finished:
    case UserDetail_UpdateCollectionGroups_Started:
      return false;

    case LocalizationEditor_Saving_Failed:
    case Project_Delete_Failed:
    case Project_RenameProjectOnServer_Failed:
    case UserDetail_UpdateCollectionGroups_Failed:
      return true;

    default:
      return state;
  }
};
