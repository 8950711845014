import { InvariantException } from '@kontent-ai/errors';
import { IUserInfo } from '../../../_shared/models/UserInfo.ts';
import { IUserInfoServerModel } from '../../../repositories/serverModels/UserServerModel.type.ts';

export enum UserState {
  RegistrationPending = 'registration-pending',
  InvitationPending = 'invitation-pending',
  Complete = 'complete',
  MarketplaceRegistrationPending = 'marketplace-registration-pending',
  ManualRegistrationPending = 'manual-registration-pending',
}

export interface IAccountCapabilities {
  readonly canChangePassword: boolean;
}

export interface ICurrentUserInfo extends IUserInfo {
  readonly accountCapabilities: IAccountCapabilities;
  readonly createdAt: DateTimeStamp;
  readonly hadTrial: boolean;
  readonly isBeingSaved: boolean;
  readonly isEmailVerified: boolean;
  readonly isSsoUser: boolean;
  readonly state: UserState;
  readonly userHash: string;
}

const emptyUserInfo: ICurrentUserInfo = {
  accountCapabilities: { canChangePassword: false },
  createdAt: '',
  email: '',
  firstName: '',
  hadTrial: false,
  isBeingSaved: false,
  isEmailVerified: false,
  isSsoUser: false,
  lastName: '',
  state: UserState.Complete,
  userHash: '',
  userId: '',
};

export function createCurrentUserInfo(params?: Partial<ICurrentUserInfo>) {
  return {
    ...emptyUserInfo,
    ...params,
  };
}

export function createCurrentUserInfoFromServerModel(
  rawUserInfo: IUserInfoServerModel,
): ICurrentUserInfo {
  if (typeof rawUserInfo !== 'object') {
    throw InvariantException('UserInfo is not an object');
  }

  const accountCapabilities: IAccountCapabilities = {
    canChangePassword:
      !!rawUserInfo.accountCapabilities && rawUserInfo.accountCapabilities.canChangePassword,
  };

  return {
    accountCapabilities,
    createdAt: rawUserInfo.createdAt || '',
    email: rawUserInfo.email || '',
    firstName: rawUserInfo.firstName || '',
    hadTrial: rawUserInfo.hadTrial,
    isBeingSaved: false,
    isEmailVerified: rawUserInfo.isEmailVerified,
    isSsoUser: rawUserInfo.isSsoUser,
    lastName: rawUserInfo.lastName || '',
    state: getUserInfoState(rawUserInfo),
    userHash: rawUserInfo.userHash || '',
    userId: rawUserInfo.userId || '',
  };
}

function getUserInfoState(rawUserInfo: IUserInfoServerModel): UserState {
  switch (rawUserInfo.state) {
    case 'registration-pending':
      return UserState.RegistrationPending;
    case 'invitation-pending':
      return UserState.InvitationPending;
    case 'complete':
      return UserState.Complete;
    case 'marketplace-registration-pending':
      return UserState.MarketplaceRegistrationPending;
    case 'manual-registration-pending':
      return UserState.ManualRegistrationPending;
    default:
      return UserState.Complete;
  }
}
