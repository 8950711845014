import { renderDateString, renderTimeString } from '../dateTime/timeUtils.ts';

const getDateInReadableFormat = (date: Date): string => {
  const dateString = date.toISOString();
  const renderDate = renderDateString(dateString);
  const renderTime = renderTimeString(dateString);

  return `${renderDate} ${renderTime}`;
};

const expirationDateIsInvalid = (from: string, to: string) =>
  `Select a valid date between ${from} and ${to}`;

export const getExpirationDateErrorMessage = (minDate: Date, maxDate: Date): string =>
  expirationDateIsInvalid(getDateInReadableFormat(minDate), getDateInReadableFormat(maxDate));
