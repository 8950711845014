import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { forwardRef } from 'react';
import { useHistory } from 'react-router';
import { getFirstLevelChildrenItemIds } from '../../../../applications/itemEditor/features/CascadePublish/selectors/getFirstLevelChildrenItemIds.ts';
import {
  changeWorkflowStep,
  publishOneOrOpenModal,
  restoreFromArchivedStep,
  scheduleOneOrOpenModal,
  scheduleUnpublishOfContentItemVariant,
  selectWorkflowStepForEditedItem,
  unpublishContentItemVariant,
} from '../../../../applications/itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { moveToArchivedStep } from '../../../../applications/itemEditor/features/ContentItemEditing/actions/thunks/moveToArchivedStep.ts';
import { getFriendlyWarningsForEditedElements } from '../../../../applications/itemEditor/features/ContentItemEditing/utils/itemValidationUtils.ts';
import { areAnyOperationsPending } from '../../../../applications/itemEditor/features/safeRedirect/selectors/areAnyOperationsPending.ts';
import { emptyContentItemVariant } from '../../../../applications/itemEditor/models/contentItem/edited/EditedContentItemVariant.ts';
import { getEditedContentItemType } from '../../../../applications/itemEditor/selectors/getEditedContentItemType.ts';
import { getElementsWithWarnings } from '../../../../applications/itemEditor/selectors/itemValidations.ts';
import {
  FriendlyWarningReason,
  IFriendlyWarning,
} from '../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { RegularWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { TasksData } from '../../../../data/reducers/tasks/tasks.ts';
import { IUsersData } from '../../../../data/reducers/users/IUsersData.type.ts';
import { getWorkflow } from '../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { modalDismissed } from '../../../actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { DefaultVariantId } from '../../../constants/variantIdValues.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { useSelector } from '../../../hooks/useSelector.ts';
import { ContentItemId } from '../../../models/ContentItemId.ts';
import { DateTime } from '../../../models/DateTime.ts';
import { Task, TaskStatus } from '../../../models/Task.ts';
import { getSelectedLanguageId } from '../../../selectors/getSelectedLanguageId.ts';
import { Notifications } from '../../../services/signalR/signalRClient.type.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import { getApplicableContributorRoleId } from '../../../utils/permissions/getContributorRole.ts';
import { canRoleDoSomethingInStep } from '../../../utils/permissions/roleInWorkflowStepUtils.ts';
import { shallowEqual } from '../../../utils/shallowEqual.ts';
import { formatUserName } from '../../../utils/usersUtils.ts';
import {
  dueDateChanged,
  hideNotificationBarInChangeWorkflowStepModal,
  noteChanged,
} from '../actions/changeWorkflowStepModalActions.ts';
import { ChangeWorkflowStepModal as ChangeWorkflowStepModalComponent } from '../components/ChangeWorkflowStepModal.tsx';
import { isPublishing } from '../utils/changeWorkflowStepModalActionUtils.ts';
import { getChangeWorkflowStepModalSubmitProps } from './selectors/getChangeWorkflowStepModalSubmitProps.ts';
import { getWorkflowStepModalTitle } from './selectors/getWorkflowStepModalTitle.ts';
import { isWorkflowStepModalSubmitDisabled } from './selectors/isWorkflowStepModalSubmitDisabled.ts';

type LockedElementsSessions =
  Notifications['lockedElementsChange']['payload']['lockedElementSessions'];

const getAllActiveUsersNames = memoize.maxOne(
  (
    lockedElements: LockedElementsSessions,
    users: IUsersData,
    currentUserId: UserId,
  ): ReadonlyArray<string> => {
    return lockedElements.reduce((result: Array<string>, lockedElement) => {
      if (lockedElement && lockedElement.userId !== currentUserId) {
        return result.concat(formatUserName(users.usersById.get(lockedElement.userId)));
      }

      return result;
    }, []);
  },
);

const getActiveUsersNamesWithoutAccessToWorkflowStep = memoize.maxOne(
  (
    lockedElements: LockedElementsSessions,
    users: IUsersData,
    currentUserId: UserId,
    nextWorkflowStepId: Uuid,
    workflow: Workflow,
    languageId: Uuid,
    collectionId: Uuid | null,
  ): ReadonlyArray<string> => {
    return lockedElements.reduce((result: Array<string>, lockedElement) => {
      if (lockedElement && lockedElement.userId !== currentUserId) {
        const user = users.usersById.get(lockedElement.userId);
        const userRoleId = user
          ? getApplicableContributorRoleId(user, languageId, collectionId)
          : null;
        const canUserWorkWithWorkflowStep = canRoleDoSomethingInStep(
          userRoleId,
          nextWorkflowStepId,
          workflow,
        );
        if (!canUserWorkWithWorkflowStep) {
          return result.concat(formatUserName(user));
        }
      }

      return result;
    }, []);
  },
);

const getActiveUsersNames = (state: IStore): ReadonlyArray<string> => {
  const {
    contentApp: {
      changeWorkflowStepModalData,
      editedContentItem,
      editedContentItemVariant,
      editorUi: { lockedElements },
    },
    data: {
      user: {
        info: { userId },
      },
      users,
    },
  } = state;

  const workflowId = editedContentItemVariant?.assignment.workflowStatus.workflowId;
  const currentWorkflow = workflowId ? getWorkflow(state, workflowId) : undefined;

  const isChangedToRegularWorkflowStep = !!currentWorkflow?.steps.find(
    (ws: RegularWorkflowStep) => ws.id === changeWorkflowStepModalData.workflowStep.id,
  );

  return isChangedToRegularWorkflowStep && currentWorkflow && editedContentItemVariant
    ? getActiveUsersNamesWithoutAccessToWorkflowStep(
        lockedElements,
        users,
        userId,
        changeWorkflowStepModalData.workflowStep.id,
        currentWorkflow,
        editedContentItemVariant.id.variantId,
        editedContentItem?.collectionId ?? null,
      )
    : getAllActiveUsersNames(lockedElements, users, userId);
};

const getOtherFriendlyWarnings = (
  activeUsersNames: ReadonlyArray<string>,
  tasks: TasksData,
): ReadonlyArray<IFriendlyWarning> => {
  const warnings: IFriendlyWarning[] = [];

  const hasIncompleteTasks = Collection.getValues(tasks.byId).some(
    (task: Task) => task.status === TaskStatus.Open,
  );
  if (hasIncompleteTasks) {
    warnings.push({ reason: FriendlyWarningReason.IncompleteTasks });
  }

  if (activeUsersNames.length) {
    warnings.push({ reason: FriendlyWarningReason.ItemIsEditedByMultipleUsers });
  }

  return warnings;
};

const getFriendlyWarningReasons = (
  state: IStore,
  activeUsersNames: ReadonlyArray<string>,
): ReadonlyArray<IFriendlyWarning> => {
  const friendlyWarningsForEditedElements = getFriendlyWarningsForEditedElements(
    state.contentApp.itemValidationFriendlyWarnings,
  );
  const otherFriendlyWarnings = getOtherFriendlyWarnings(activeUsersNames, state.data.tasks);

  return friendlyWarningsForEditedElements.concat(otherFriendlyWarnings);
};

export const ChangeWorkflowStepEditingModal = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  (props, ref) => {
    const isRequiredStateLoaded = useSelector((s) => !!s.contentApp.editedContentItemVariant);

    const changeWorkflowStepModalData = useSelector(
      (s) => s.contentApp.changeWorkflowStepModalData,
    );
    const modalTitle = getWorkflowStepModalTitle(changeWorkflowStepModalData.workflowStepAction);

    const activeUsersNames = useSelector((s) => getActiveUsersNames(s));
    const friendlyWarningReasons = useSelector((s) =>
      getFriendlyWarningReasons(s, activeUsersNames),
    );
    const selectedItemIds = useSelector(
      (s) =>
        new Set(
          s.contentApp.editedContentItem?.id ? [s.contentApp.editedContentItem.id] : undefined,
        ),
    );
    const contentItemUsages = useSelector((s) => s.data.listingContentItems.contentItemUsages);
    const hasPendingUpdates = useSelector(
      (s) =>
        areAnyOperationsPending(s) ||
        s.contentApp.editorUi.editedContentItemVariantUploadingAssets.length > 0,
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const onDueDateChange = (dateTime: DateTime) => dispatch(dueDateChanged(dateTime));
    const onNoteChanged = (newNote: string) => dispatch(noteChanged(newNote));
    const onPublish = (publishedContentItemId: ContentItemId) => () =>
      dispatch(publishOneOrOpenModal(publishedContentItemId));
    const onScheduledPublish = (scheduledContentItemId: ContentItemId) => () =>
      dispatch(scheduleOneOrOpenModal(scheduledContentItemId));
    const onUnpublish = (unpublishedContentItemId: ContentItemId) => () =>
      dispatch(unpublishContentItemVariant(unpublishedContentItemId));
    const onScheduledUnpublish = (scheduledContentItemId: ContentItemId) => () =>
      dispatch(scheduleUnpublishOfContentItemVariant(scheduledContentItemId));
    const onRestoreFromArchivedStep = () => dispatch(restoreFromArchivedStep(history));
    const onUpdate = () => dispatch(changeWorkflowStep(history));
    const { workflowId } = changeWorkflowStepModalData.workflowStep;
    const onMoveToArchivedStep = () => dispatch(moveToArchivedStep(workflowId));
    const onCloseModal = () => dispatch(modalDismissed());
    const onHideNotificationBar = () => dispatch(hideNotificationBarInChangeWorkflowStepModal());
    const onSelectorValueChanged = (workflowStatusId: Uuid) =>
      dispatch(selectWorkflowStepForEditedItem(workflowStatusId));

    const updateSubmitProps = useSelector((s) => {
      const selectedLanguageId = getSelectedLanguageId(s);

      if (!selectedLanguageId) {
        return null;
      }

      const isEditingDefaultVariant = selectedLanguageId === DefaultVariantId;

      const editedContentItemType = getEditedContentItemType(s);
      const containsNonLocalizableElements = !!editedContentItemType?.contentElements.some(
        (el) => el.isNonLocalizable,
      );

      const isCascadeAction =
        isPublishing(s.contentApp.changeWorkflowStepModalData.workflowStepAction) &&
        (getFirstLevelChildrenItemIds(s).size > 0 ||
          (!isEditingDefaultVariant && containsNonLocalizableElements));

      const isSubmitDisabled = isWorkflowStepModalSubmitDisabled(s);
      const hasElementsWithWarnings = !!getElementsWithWarnings(s).length;

      const editedContentItemVariant =
        s.contentApp.editedContentItemVariant || emptyContentItemVariant;

      return getChangeWorkflowStepModalSubmitProps(
        changeWorkflowStepModalData,
        isCascadeAction,
        isSubmitDisabled,
        hasElementsWithWarnings,
        null,
        {
          onMoveToArchivedStep,
          onUpdate,
          onScheduledPublish: onScheduledPublish(editedContentItemVariant.id),
          onPublish: onPublish(editedContentItemVariant.id),
          onScheduledUnpublish: onScheduledUnpublish(editedContentItemVariant.id),
          onUnpublish: onUnpublish(editedContentItemVariant.id),
          onRestoreFromArchivedStep,
        },
      );
    }, shallowEqual);

    return isRequiredStateLoaded && updateSubmitProps ? (
      <ChangeWorkflowStepModalComponent
        activeUsersNames={activeUsersNames}
        changeWorkflowStepModalData={changeWorkflowStepModalData}
        contentItemUsages={contentItemUsages}
        friendlyWarningReasons={friendlyWarningReasons}
        hasPendingUpdates={hasPendingUpdates}
        itemIds={selectedItemIds}
        modalTitle={modalTitle}
        onCloseModal={onCloseModal}
        onDueDateChange={onDueDateChange}
        onHideNotificationBar={onHideNotificationBar}
        onNoteChanged={onNoteChanged}
        onSelectorValueChanged={onSelectorValueChanged}
        updateSubmitProps={updateSubmitProps}
        ref={ref}
        {...props}
      />
    ) : null;
  },
);

ChangeWorkflowStepEditingModal.displayName = 'ChangeWorkflowStepEditingModal';
