import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly canViewOrCreate?: boolean;
  readonly children: NonEmptyReadonlyArray<ReactNode>;
  readonly dataAttributes: ObjectWithDataAttribute;
  readonly hasComment?: boolean;
  readonly hasFocusedComment?: boolean;
  readonly isClickable?: boolean;
  readonly isCompact?: boolean;
  readonly isDisabled: boolean;
  readonly isDragging: boolean;
  readonly isEntryTypeAllowed: boolean;
  readonly isExpandable?: boolean;
  readonly isExpanded?: boolean;
  readonly isTopLevel?: boolean;
  readonly isTranslated: boolean | null | undefined;
  readonly renderExpanded?: () => ReactNode;
};

export const LinkedItemNode: React.FC<Props> = ({
  cannotViewDisabledMessage,
  canViewOrCreate,
  children,
  dataAttributes,
  hasComment,
  hasFocusedComment,
  isClickable,
  isCompact,
  isDisabled,
  isDragging,
  isEntryTypeAllowed,
  isExpandable,
  isExpanded,
  isTopLevel = true,
  isTranslated,
  renderExpanded,
}) => (
  <div className="bar-item__node" {...dataAttributes}>
    <div className="bar-item__wrapper">
      <div
        className={classNames('bar-item__pane', 'linked-item', {
          'linked-item--is-dragging': !isDisabled && isDragging,
          'linked-item--is-disabled': isClickable && isDisabled,
          'linked-item--is-top-level': isTopLevel,
          'linked-item--has-comment': hasComment,
          'linked-item--has-focused-comment': hasFocusedComment,
          'linked-item--is-expanded': isExpanded,
          'linked-item--is-not-allowed': !isEntryTypeAllowed,
        })}
      >
        <div
          className={classNames('bar-item__bar', {
            'bar-item__bar--is-clickable':
              isClickable && isExpandable && canViewOrCreate && !isDragging,
            'bar-item__bar--is-disabled':
              isClickable && isTranslated && !!cannotViewDisabledMessage,
            'bar-item__bar--has-comment': hasComment,
            'bar-item__bar--has-focused-comment': hasFocusedComment,
            'bar-item__bar--is-expanded': isExpanded,
            'bar-item__bar--size-xs': isCompact,
          })}
        >
          {children}
        </div>
        {renderExpanded?.()}
      </div>
    </div>
  </div>
);

LinkedItemNode.displayName = 'LinkedItemNode';
