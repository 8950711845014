import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';

export function basePropertiesAreTheSame(
  itemElement1: ICompiledContentItemElementData,
  itemElement2: ICompiledContentItemElementData,
): boolean {
  if (!itemElement1 || !itemElement2) {
    return false;
  }

  const idsAreTheSame = itemElement1.elementId === itemElement2.elementId;
  const typesAreTheSame = itemElement1.type === itemElement2.type;

  return idsAreTheSame && typesAreTheSame;
}
