import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { usePreviewApiPolling } from '../hooks/usePreviewApiPolling.ts';

const expandedTreeStyle: string = '320px 1fr auto';
const narrowedTreeStyle: string = '44px 1fr auto';

export const WebSpotlightGrid: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const isTreeCollapsed = useSelector(
    (s: IStore) => s.sharedApp.userProperties.webSpotlightTreeCollapsed,
  );
  const transition = useSpring({
    gridTemplateColumns: isTreeCollapsed ? narrowedTreeStyle : expandedTreeStyle,
  });

  usePreviewApiPolling();

  return (
    <animated.div className="web-spotlight web-spotlight__layout-guard-content" style={transition}>
      {children}
    </animated.div>
  );
};

WebSpotlightGrid.displayName = 'WebSpotlightGrid';
