import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import {
  IRoleServerModel,
  IRoleUpdateServerModel,
} from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import { UnableToSaveErrorMessage } from '../../constants/UIConstants.ts';
import {
  Role_Update_Failed,
  Role_Update_Finished,
  Role_Update_Started,
} from '../../constants/rolesActionTypes.ts';

interface IDeps {
  readonly reloadNormalizedRoleWithSettings: (projectId: Uuid) => ThunkPromise;
  readonly roleRepository: Pick<IRoleRepository, 'updateRole'>;
}

const started = () =>
  ({
    type: Role_Update_Started,
  }) as const;

const finished = (role: IRoleServerModel, allTypes: Immutable.Map<Uuid, IContentType>) =>
  ({
    type: Role_Update_Finished,
    payload: {
      role,
      allTypes,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Role_Update_Failed,
    payload: { errorMessage },
  }) as const;

export type UpdateRoleActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const makeUpdateRoleAction =
  (deps: IDeps) =>
  (
    roleId: Uuid,
    role: IRoleUpdateServerModel,
    isRoleUsedByCurrentUser: boolean,
    contentTypesById: Immutable.Map<Uuid, IContentType>,
    currentProjectId: Uuid,
  ): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());
      const serverModel = await deps.roleRepository.updateRole(roleId, role);
      dispatch(trackUserEvent(TrackedEvent.CustomRoleEdited));
      if (isRoleUsedByCurrentUser) {
        await dispatch(deps.reloadNormalizedRoleWithSettings(currentProjectId));
      }
      dispatch(finished(serverModel, contentTypesById));
    } catch (error) {
      dispatch(failed(UnableToSaveErrorMessage));
      throw error;
    }
  };
