import { DraftBlockRenderConfig, EditorProps as DraftJSEditorProps } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useMemo } from 'react';
import { AssetViewerTile } from '../../../itemEditor/features/LinkedItems/containers/AssetViewerTile.tsx';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetBaseBlockRenderMap } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BaseBlockRenderMap,
  mergeBlockRenderMaps,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { BaseBlockType, BlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { CustomBlockWrapper } from '../customBlocks/components/CustomBlockWrapper.tsx';
import { getImageAssetReference } from './api/editorImageUtils.ts';

type DisplayImagesPluginProps = {
  readonly isViewOnly?: boolean;
};

export type DisplayImagesPlugin = EditorPlugin<None, DisplayImagesPluginProps>;

const ImageBlock: React.FC<IEditorBlockProps<DisplayImagesPluginProps>> = (props) => {
  const {
    block,
    blockProps: { isViewOnly },
  } = props;
  const assetReference = getImageAssetReference(block);
  if (!assetReference) {
    return null;
  }

  const blockKey = block.getKey();
  return (
    <CustomBlockWrapper className="rte__inline-image asset-tile-wrapper" key={blockKey}>
      <AssetViewerTile assetId={assetReference.id} isViewOnly={isViewOnly} />
    </CustomBlockWrapper>
  );
};

ImageBlock.displayName = 'ImageBlock';

const EditorWithImages: DecoratedEditor<DisplayImagesPlugin> = ({
  baseRender,
  isViewOnly,
  state,
}) => {
  const {
    editorProps: { blockRendererFn: baseBlockRendererFn },
  } = state;

  const blockProps = useMemo(() => ({ isViewOnly }), [isViewOnly]);

  const blockRendererFn = useCallback<Required<DraftJSEditorProps>['blockRendererFn']>(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.Image) {
        return {
          component: ImageBlock,
          props: blockProps,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn, blockProps],
  );

  const stateWithImages: PluginState<DisplayImagesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithImages);
};

EditorWithImages.displayName = 'EditorWithImages';

const imageRenderMap: BaseBlockRenderMap = Immutable.Map<BaseBlockType, DraftBlockRenderConfig>({
  [BaseBlockType.Image]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), imageRenderMap);

export const DisplayImagesPlugin: PluginComponent<DisplayImagesPlugin> = (props) => {
  const { isViewOnly } = props;

  const render: Decorator<Render<DisplayImagesPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithImages baseRender={baseRender} isViewOnly={isViewOnly} state={state} />
    ),
    [isViewOnly],
  );

  const apply: Apply<DisplayImagesPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);

      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { apply });
};
