import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly isCurrentLanguage: boolean;
  readonly isFallbackLanguage: boolean;
  readonly languageName: string;
};

export const FallbacksPopoverLanguageLabel: React.FC<Props> = ({
  isCurrentLanguage,
  isFallbackLanguage,
  languageName,
}) => (
  <Box typography={Typography.LabelLarge}>
    <Row spacingX={Spacing.XS} noWrap>
      <Column
        width="fit-content"
        {...getDataUiElementAttribute(DataUiElement.VariantLanguageLabel)}
      >
        <OptionalTooltip placement="top" text={languageName}>
          {languageName}
        </OptionalTooltip>
      </Column>
      <Column width="content">
        {isCurrentLanguage && (
          <>
            -{' '}
            <strong {...getDataUiElementAttribute(DataUiElement.CurrentLanguageTag)}>
              Current
            </strong>
          </>
        )}
        {isFallbackLanguage && (
          <>
            -{' '}
            <strong {...getDataUiElementAttribute(DataUiElement.FallbackLanguageTag)}>
              Fallback
            </strong>
          </>
        )}
      </Column>
    </Row>
  </Box>
);

FallbacksPopoverLanguageLabel.displayName = 'FallbacksPopoverVariantLanguage';
