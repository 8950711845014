import { combineReducers } from '@reduxjs/toolkit';
import { administratedIds } from './reducers/administratedIds.ts';
import { billingHistoryItems } from './reducers/billingHistoryItems.ts';
import { byId } from './reducers/byId.ts';
import { subscriptionAdministrators } from './reducers/subscriptionAdministrators.ts';
import { subscriptionBalance } from './reducers/subscriptionBalance.ts';
import { subscriptionBillingInformation } from './reducers/subscriptionBillingInformation.ts';
import { subscriptionCurrentSpending } from './reducers/subscriptionCurrentSpending.ts';
import { subscriptionTransactions } from './reducers/subscriptionTransactions.ts';
import { subscriptionUsages } from './reducers/subscriptionUsages.ts';
import { subscriptionUsers } from './reducers/subscriptionUsers.ts';
import { subscriptionUsersUsage } from './reducers/subscriptionUsersUsage.ts';

export const subscriptions = combineReducers({
  administratedIds,
  billingHistoryItems,
  byId,
  subscriptionAdministrators,
  subscriptionBalance,
  subscriptionBillingInformation,
  subscriptionCurrentSpending,
  subscriptionTransactions,
  subscriptionUsages,
  subscriptionUsers,
  subscriptionUsersUsage,
});
