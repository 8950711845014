import { combineReducers } from '@reduxjs/toolkit';
import { editedProjectName } from '../../projectSettings/general/reducers/internalReducers/editedProjectName.ts';
import { cloneProjectInfo } from './cloneProjectInfo/cloneProjectInfo.ts';
import { activatingTrial } from './internalReducers/activatingTrial.ts';
import { cloningProjectsIds } from './internalReducers/cloningProjectsIds.ts';
import { copyProjectDataInfo } from './internalReducers/copyProjectDataInfo.ts';
import { createProjectErrorMessage } from './internalReducers/createProjectErrorMessage.ts';
import { creatingProject } from './internalReducers/creatingProject.ts';
import { creatingSampleProject } from './internalReducers/creatingSampleProject.ts';
import { projectsUiTransitionState } from './internalReducers/projectsUiTransitionState.ts';
import { savingStatus } from './internalReducers/savingStatus.ts';
import { serverError } from './internalReducers/serverError.ts';

export const projectsAppReducer = combineReducers({
  activatingTrial,
  createProjectErrorMessage,
  creatingProject,
  creatingSampleProject,
  savingStatus,
  editedProjectName,
  serverError,
  copyProjectDataInfo,
  projectsUiTransitionState,
  cloneProjectInfo,
  cloningProjectsIds,
});
