import { ReactNode, useCallback, useRef, useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getIsAiOperationInProgress } from '../../../selectors/getIsAiOperationInProgress.ts';
import { pendingAiOperationsDiscarded } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { AiActionInProgressDialog } from '../components/AiActionInProgressDialog.tsx';

type LeaveCallback = () => void;
export type TryLeaveCallback = (leave: LeaveCallback) => void;

type Props = {
  readonly renderContent: (tryLeave: TryLeaveCallback) => ReactNode;
};

export const PendingAiChangesGuard = ({ renderContent }: Props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const pendingLeaveCallback = useRef<LeaveCallback | null>(null);

  const isAiOperationInProgress = useSelector(getIsAiOperationInProgress);

  const tryLeave = useCallback(
    (leave: LeaveCallback) => {
      if (isAiOperationInProgress) {
        pendingLeaveCallback.current = leave;
        setShowDialog(true);
      } else {
        leave();
      }
    },
    [isAiOperationInProgress],
  );

  const dispatch = useDispatch();

  const confirmLeave = useCallback((): void => {
    dispatch(pendingAiOperationsDiscarded());
    pendingLeaveCallback.current?.();
    reset();
  }, []);

  const reset = (): void => {
    pendingLeaveCallback.current = null;
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && <AiActionInProgressDialog onCancel={reset} onConfirm={confirmLeave} />}
      {renderContent(tryLeave)}
    </>
  );
};
