import { EditorState } from 'draft-js';
import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { ApplyEditorStateChanges } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';

export type ForceSelectionOnBlurPlugin = EditorPlugin;

const applyEditorStateChanges: Decorator<ApplyEditorStateChanges> =
  (baseApplyEditorStateChanges) => (params) => {
    const allowedNewState = baseApplyEditorStateChanges(params);
    const isBeingBlurred =
      !params.newState.getSelection().getHasFocus() && params.oldState.getSelection().getHasFocus();
    return isBeingBlurred ? EditorState.moveSelectionToEnd(allowedNewState) : allowedNewState;
  };

// This is here because the draft.js editor ignores any selection change in the onBlur event
// We don't know the exact scenarios which didn't work though (lost in history), we are just afraid to remove it at the moment
export const ForceSelectionOnBlurPlugin: PluginComponent<ForceSelectionOnBlurPlugin> = (props) => {
  const apply: Apply<ForceSelectionOnBlurPlugin> = useCallback((state) => {
    state.applyEditorStateChanges.decorate(applyEditorStateChanges);

    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
