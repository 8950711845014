import { memoize } from '@kontent-ai/memoization';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';

export const getAllProjectsMemoized = memoize.weak((byId: Immutable.Map<Uuid, IProjectDetails>) =>
  byId.toArray(),
);

export const getProjectsSortedByProjectName = memoize.weak(
  (projects: ReadonlyArray<IProjectDetails>) =>
    projects.toSorted(
      createCompare({
        compare: alphabetically,
        select: (x) => x.projectName,
      }),
    ),
);

export const getProjectsSortedByEnvironmentName = memoize.weak(
  (projects: ReadonlyArray<IProjectDetails>) =>
    projects.toSorted(
      createCompare({
        compare: alphabetically,
        select: (x) => x.environmentName,
      }),
    ),
);
