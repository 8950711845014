import { createGuid } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';

export interface IItemElementRefresherOwnProps {
  readonly elementData: ICompiledContentItemElementData;
  readonly renderInput: (elementData: ICompiledContentItemElementData) => JSX.Element;
}

export interface IItemElementRefresherStateProps {
  readonly refreshedElementData: ICompiledContentItemElementData | null;
}

export interface IItemElementRefresherDispatchProps {
  readonly onRefreshFinished: (refreshedElementData: ICompiledContentItemElementData) => void;
}

type ItemElementRefresherProps = IItemElementRefresherOwnProps &
  IItemElementRefresherStateProps &
  IItemElementRefresherDispatchProps;

export const ItemElementRefresher: React.FC<ItemElementRefresherProps> = (props): JSX.Element => {
  const { elementData, onRefreshFinished, refreshedElementData, renderInput } = props;

  const [state, setState] = useState({
    isInputUpdated: false,
    refreshKey: createGuid(),
  });

  const onRefreshFinishedCallback = useCallback(
    (itemElement: ICompiledContentItemElementData) => onRefreshFinished(itemElement),
    [onRefreshFinished],
  );

  // Fresh data arrived from the server, let's re-render the input by setting new key.
  useEffect(() => {
    if (refreshedElementData) {
      setState({
        isInputUpdated: true,
        refreshKey: createGuid(),
      });
    }
  }, [refreshedElementData]);

  // Input was successfully re-rendered, reset the internal state flag to be ready for another refresh
  // and raise the finish callback to update necessary props in redux state.
  useEffect(() => {
    if (state.isInputUpdated && refreshedElementData) {
      setState((prevState) => ({
        ...prevState,
        isInputUpdated: false,
      }));
      onRefreshFinishedCallback(refreshedElementData);
    }
  }, [state.isInputUpdated, onRefreshFinishedCallback, refreshedElementData]);

  return (
    <React.Fragment key={state.refreshKey}>
      {renderInput(refreshedElementData || elementData)}
    </React.Fragment>
  );
};

ItemElementRefresher.displayName = 'ItemElementRefresher';
