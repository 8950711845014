import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { filterOutNullish } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { Languages } from '../../../../data/models/languages/Language.ts';
import { IAssetItemElement } from '../../models/contentItemElements/AssetItemElement.ts';
import {
  ItemElementFriendlyWarningResult,
  MissingAssetDescriptionFriendlyWarningForAsset,
} from './types/FriendlyWarnings.ts';
import { getBrokenAssetWarningsWithDetails } from './utils/contentLinkUtils.ts';
import { assetsHaveRequiredDescriptions } from './utils/publicUtils.ts';

export interface IAssetItemElementFriendlyWarningCheckerParams {
  readonly itemElement: IAssetItemElement;
  readonly loadedAssets: Immutable.Map<Uuid, IAsset>;
  readonly currentLanguageId: Uuid;
  readonly languages: Languages;
}

export const getAssetItemElementValidationFriendlyWarning = memoize.weak(
  ({
    itemElement,
    loadedAssets,
    languages,
    currentLanguageId,
  }: IAssetItemElementFriendlyWarningCheckerParams): ItemElementFriendlyWarningResult => {
    const assetReferences = itemElement.value.valueSeq().toArray();
    const warningMessages = getBrokenAssetWarningsWithDetails(assetReferences, loadedAssets);

    const existingAssetsInElement = filterOutNullish(
      assetReferences.map((assetReference) => loadedAssets.get(assetReference.id)),
    );
    const haveDescription = assetsHaveRequiredDescriptions(
      existingAssetsInElement,
      currentLanguageId,
      languages,
    );
    const warningMessagesWithDescriptionCheck = haveDescription
      ? warningMessages
      : [...warningMessages, MissingAssetDescriptionFriendlyWarningForAsset];

    return {
      warningMessages: warningMessagesWithDescriptionCheck,
    };
  },
);
