import { createEnsureSelectedVariantInRouteAction } from '../../../../_shared/actions/thunks/ensureSelectedVariantInRoute.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { getPathWithReplacedVariantIdForRelationsRoute } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  loadCollections,
  loadContentTypes,
  loadLanguages,
} from '../../../../data/actions/thunkDataActions.ts';
import { contentVariantIdStorage } from '../../../../localStorages/contentVariantStorage.ts';
import { RelationsConfigStorage } from '../../../../localStorages/relationsConfigStorage.ts';
import { localizedRouteEntered } from '../../../home/actions/homeActions.ts';
import { loadListingItems } from '../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { expandRelationsNodeActionCreator } from './thunks/expandRelationsNode.ts';
import { initRelationsActionCreator } from './thunks/initRelations.ts';
import { loadDefaultRelationsRootActionCreator } from './thunks/loadDefaultRelationsRoot.ts';
import { resetRelationsRootActionCreator } from './thunks/resetRelationsRoot.ts';
import { setCurrentRelationsRootAsDefaultActionCreator } from './thunks/setCurrentRelationsRootAsDefault.ts';
import { setDefaultRelationsRootActionCreator } from './thunks/setDefaultRelationsRoot.ts';
import { setRelationsRootActionCreator } from './thunks/setRelationsRoot.ts';

const { relationRepository } = repositoryCollection;

export const expandRelationsNode = expandRelationsNodeActionCreator({
  loadListingItems,
});

export const loadDefaultRelationsRoot = loadDefaultRelationsRootActionCreator({
  relationRepository,
});

export const setDefaultRelationsRoot = setDefaultRelationsRootActionCreator({
  relationsConfigStorage: RelationsConfigStorage,
  relationRepository,
  trackUserEvent,
});

export const setCurrentRelationsRootAsDefault = setCurrentRelationsRootAsDefaultActionCreator({
  setDefaultRelationsRoot,
});

export const setRelationsRoot = setRelationsRootActionCreator({
  expandRelationsNode,
  loadListingItems,
  relationsConfigStorage: RelationsConfigStorage,
  setDefaultRelationsRoot,
});

export const resetRelationsRoot = resetRelationsRootActionCreator({
  setRelationsRoot,
});

export const initRelations = initRelationsActionCreator({
  loadCollections,
  loadContentTypes,
  loadDefaultRelationsRoot,
  relationsConfigStorage: RelationsConfigStorage,
  setRelationsRoot,
  trackUserEvent,
});

export const ensureSelectedVariantForRelationsTab = createEnsureSelectedVariantInRouteAction({
  appName: AppNames.Relations,
  loadCollections,
  loadLanguages,
  contentVariantIdStorage,
  getPathWithReplacedVariantId: getPathWithReplacedVariantIdForRelationsRoute,
  localizedRouteEntered,
});
