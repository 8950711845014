import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { filterOutNullish } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { removeNonExistingTaxonomyTerms } from '../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import {
  ITaxonomyTerm,
  emptyTaxonomyTerm,
} from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { isTaxonomyElement } from '../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { ICompiledContentType } from '../../content/models/CompiledContentType.ts';
import { ITaxonomyTypeElement } from '../../content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { isTaxonomyTypeElement } from '../../content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { uncategorizedAssetFilterTaxonomyTermId } from '../constants/taxonomyConstants.ts';

export function addUncategorizedFilterTerm(taxonomyGroup: ITaxonomyGroup): ITaxonomyGroup {
  const uncategorizedTerm: ITaxonomyTerm = {
    ...emptyTaxonomyTerm,
    id: uncategorizedAssetFilterTaxonomyTermId,
    name: `∅ Not in ${taxonomyGroup.name}`,
  };

  return {
    ...taxonomyGroup,
    childIds: [uncategorizedAssetFilterTaxonomyTermId, ...taxonomyGroup.childIds],
    terms: Collection.add(taxonomyGroup.terms, [
      uncategorizedAssetFilterTaxonomyTermId,
      uncategorizedTerm,
    ]),
  };
}

export const getAssetTaxonomyGroupIds = (
  defaultAssetType: ICompiledContentType | null,
): ReadonlyArray<Uuid> =>
  filterOutNullish(
    defaultAssetType?.contentElements
      .filter(isTaxonomyTypeElement)
      .map((element) => element.taxonomyGroupId) ?? [],
  );

export const getAssetTaxonomiesForFilter = (
  defaultAssetType: ICompiledContentType | null,
  taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
): Immutable.Map<Uuid, ITaxonomyGroup> => {
  if (!defaultAssetType) {
    return Immutable.Map<Uuid, ITaxonomyGroup>();
  }

  const assetTypeGroupIds = getAssetTaxonomyGroupIds(defaultAssetType);

  return taxonomyGroups
    .filter(
      (taxonomyGroup: ITaxonomyGroup) =>
        !!taxonomyGroup.childIds.length && assetTypeGroupIds.includes(taxonomyGroup.id),
    )
    .map(addUncategorizedFilterTerm)
    .toMap();
};

const getAssetTypeTaxonomyElements = (
  defaultAssetType: ICompiledContentType | null,
): ReadonlyArray<ITaxonomyTypeElement> =>
  defaultAssetType?.contentElements.filter(isTaxonomyTypeElement) ?? [];

export const isAssetUncategorized = (
  asset: IAsset,
  defaultAssetType: ICompiledContentType | null,
  taxonomyGroups: Immutable.Map<string, ITaxonomyGroup>,
): boolean | null => {
  const assetTypeTaxonomyElements = getAssetTypeTaxonomyElements(defaultAssetType);

  if (!assetTypeTaxonomyElements.length) {
    return null;
  }

  return asset.nonLocalizableElements.filter(isTaxonomyElement).every((assetTaxonomyElement) => {
    const typeTaxonomyElement = assetTypeTaxonomyElements.find(
      (element: ITaxonomyTypeElement) => element.elementId === assetTaxonomyElement.elementId,
    );

    const currentTaxonomyGroup = taxonomyGroups.get(assetTaxonomyElement?.groupId ?? '');
    const validTerms = currentTaxonomyGroup
      ? removeNonExistingTaxonomyTerms(assetTaxonomyElement.value, currentTaxonomyGroup)
      : Immutable.Set<Uuid>();

    return (
      !typeTaxonomyElement ||
      typeTaxonomyElement.taxonomyGroupId !== assetTaxonomyElement.groupId ||
      validTerms.size === 0
    );
  });
};
