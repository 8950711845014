import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { MouseEvent, MouseEventHandler } from 'react';

export const singleOrDoubleClick = <TTargetElement = HTMLElement>(
  onSingleClick: MouseEventHandler<TTargetElement> | undefined,
  onDoubleClick: MouseEventHandler<TTargetElement> | undefined,
  fallbackDoubleClickToSingle?: boolean,
): MouseEventHandler<TTargetElement> => {
  let count: number | null = null;
  let timer: ICancellablePromise | null = null;

  function handleClick(event: MouseEvent<TTargetElement>) {
    if (count === 0 || count === 1) {
      if (onSingleClick) {
        onSingleClick(event);
      }
    } else if (count === 2) {
      if (onDoubleClick) {
        onDoubleClick(event);
      } else if (fallbackDoubleClickToSingle && onSingleClick) {
        onSingleClick(event);
      }
    }
    count = null;
    if (timer) {
      timer.cancel();
      timer = null;
    }
  }

  return (event): void => {
    count = (event as any).detail;

    if (!timer) {
      timer = delay(200)
        .then(() => {
          handleClick(event);
        })
        .catch(swallowCancelledPromiseError);
    }
  };
};
