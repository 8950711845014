import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { ISubscription } from '../../../data/models/subscriptions/Subscription.ts';
import { getProjectDetail } from '../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import {
  getSubscriptionAvailableProjectLocations,
  getSubscriptionDefaultProjectLocation,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ICloneProjectFormShape } from '../models/ICloneProjectFormShape.type.ts';
import { createNameForClonedProject } from '../utils/copyProjectUtils.ts';

export const getCloneProjectFormDefaultValues = (
  state: IStore,
  sourceProjectId: Uuid,
  activeSubscriptions: ReadonlyArray<ISubscription>,
): ICloneProjectFormShape => {
  const sourceProjectDetail = sourceProjectId
    ? getProjectDetail(state, sourceProjectId)
    : undefined;

  const sourceProjectSubscriptionId = sourceProjectDetail?.subscriptionId;

  const availableDestinationSubscriptions = sourceProjectSubscriptionId
    ? activeSubscriptions.filter(
        (sub: ISubscription) => sub.subscriptionId === sourceProjectSubscriptionId,
      )
    : activeSubscriptions;

  const defaultDestinationSubscriptionId =
    availableDestinationSubscriptions[0]?.subscriptionId ?? '';

  const availableProjectLocations = getSubscriptionAvailableProjectLocations(
    state,
    defaultDestinationSubscriptionId,
  );

  const sourceProjectLocation = availableProjectLocations.find(
    (loc) => loc.projectLocationId === sourceProjectDetail?.projectLocationId,
  );

  const projectLocation = sourceProjectLocation
    ? sourceProjectLocation
    : getSubscriptionDefaultProjectLocation(state, defaultDestinationSubscriptionId);

  const defaultProjectLocationId = projectLocation?.projectLocationId || '';
  const sourceProjectName = sourceProjectDetail?.projectName;
  const defaultProjectName =
    (sourceProjectName && createNameForClonedProject(sourceProjectName)) || '';

  return {
    destinationLocationId: defaultProjectLocationId || '',
    destinationSubscriptionId: defaultDestinationSubscriptionId || '',
    includeContent: true,
    projectName: defaultProjectName,
  };
};
