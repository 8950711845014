import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { DefaultWebhookId } from '../../../../applications/webhooks/constants/webhookValues.ts';
import {
  Webhooks_CancelEditingInitial,
  Webhooks_CreateEmpty,
  Webhooks_Webhook_ArchiveStarted,
  Webhooks_Webhook_DisableCompleted,
  Webhooks_Webhook_EnableCompleted,
  Webhooks_Webhook_InsertCompleted,
  Webhooks_Webhook_ResetCompleted,
  Webhooks_Webhook_RestoreStarted,
  Webhooks_Webhook_UpdateCompleted,
} from '../../../../applications/webhooks/constants/webhooksActionTypes.ts';
import { IWebhookServerModel } from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  Data_Webhooks_Started,
  Data_Webhooks_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IWebhookSetting,
  createWebhookSettingDomainModel,
} from '../../../models/webhooks/WebhookSetting.ts';

type WebhooksType = Immutable.Map<Uuid, IWebhookSetting>;
const initialState: WebhooksType = Immutable.Map();

export function byId(state: WebhooksType = initialState, action: Action): WebhooksType {
  switch (action.type) {
    case Data_Webhooks_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_Webhooks_Success: {
      const webhooks = initialState.withMutations((mutableMap: WebhooksType) => {
        action.payload.data.forEach((webhooksServerModel: IWebhookServerModel) => {
          mutableMap.set(
            webhooksServerModel.id,
            createWebhookSettingDomainModel(webhooksServerModel),
          );
        });
      });
      return webhooks;
    }

    case Webhooks_CreateEmpty: {
      return state.set(action.payload.webhook.id, action.payload.webhook);
    }

    case Webhooks_CancelEditingInitial:
      return state.delete(action.payload.id);

    case Webhooks_Webhook_InsertCompleted:
      return state.delete(DefaultWebhookId).set(action.payload.webhook.id, action.payload.webhook);

    case Webhooks_Webhook_UpdateCompleted:
    case Webhooks_Webhook_DisableCompleted:
    case Webhooks_Webhook_EnableCompleted:
    case Webhooks_Webhook_ResetCompleted:
    case Webhooks_Webhook_RestoreStarted:
      return state.set(action.payload.webhook.id, action.payload.webhook);

    case Webhooks_Webhook_ArchiveStarted:
      return state.delete(action.payload.webhook.id);

    default:
      return state;
  }
}
