export enum DiffType {
  Mixed = 'mixed',
  Changed = 'changed',
  Added = 'added',
  Removed = 'removed',
  None = 'none',
  // Content which had to be created by diff to ensure proper consistency of the output
  // Used for table cells that never existed, but need to be filled in to make output diff table complete
  Dummy = 'dummy',
}
