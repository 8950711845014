import { useId } from 'react';

const codenamify = (text: string) => {
  return text.replace(/[^|a-zA-Z\d\s]/gi, '').replace(/\s/gi, '_');
};

export const useIdWithPrefix = (prefix: string): string => {
  const id = useId();
  const safePrefix = codenamify(prefix);
  return safePrefix ? `${safePrefix}_${id}` : id;
};
