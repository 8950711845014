import {
  TypeElementCondition,
  convertConditionFromServerModel,
} from '../../../../../../_shared/models/utils/TypeElementCondition.ts';
import { ContentTypeElementServerModel } from '../../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import { TypeElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetFileTypeOption } from '../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../../../../contentInventory/content/models/fileSizeOptions.ts';
import { QuantityOption } from '../../../../../contentInventory/content/models/quantityOptions.ts';
import { UnitOption } from '../../../../../contentInventory/content/models/textLengthOptions.ts';
import {
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
} from '../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { TypeElementConfigurability } from './elementConfigurabilityOptions.ts';

export interface IBaseTypeElementData {
  readonly _configurability: TypeElementConfigurability;
  readonly codename: string | null;
  readonly condition: TypeElementCondition | null;
  readonly contentGroupId: Uuid | null;
  readonly elementId: Uuid;
  readonly guidelines: string;
  readonly isNonLocalizable: boolean;
  readonly isRequired: boolean;
  readonly name: string;
  readonly type: TypeElementType;
}

export type BaseTypeElementEditableData = Omit<IBaseTypeElementData, '_configurability' | 'type'>;

export const EmptyBaseTypeElementData: BaseTypeElementEditableData = {
  codename: null,
  condition: null,
  contentGroupId: null,
  elementId: '',
  guidelines: '',
  isNonLocalizable: false,
  isRequired: false,
  name: '',
};

export interface ITypeElementDataWithNumberOfItemsLimit {
  readonly _quantityUnitOption: QuantityOption;
  readonly maxItems: string | null;
  readonly minItems: string | null;
}

export interface ITypeElementDataWithFileSizeLimit {
  readonly _fileSizeUnitOption: FileSizeUnit;
  readonly fileSize: string | null;
}

export interface ITypeElementDataWithAssetDimensions {
  readonly _heightOption: QuantityOption;
  readonly _widthOption: QuantityOption;
  readonly maxHeight: string | null;
  readonly maxWidth: string | null;
  readonly minHeight: string | null;
  readonly minWidth: string | null;
}

export interface ITypeElementDataWithAssetTypeLimitation {
  readonly fileType: AssetFileTypeOption;
}

export interface ITypeElementDataWithAllowedItemLinkTypesLimitation {
  readonly allowedItemLinkTypes: ReadonlyArray<Uuid>;
}

export interface ITypeElementDataWithAllowedContentTypesLimitation {
  readonly allowedTypes: ReadonlyArray<Uuid>;
}

export interface ITypeElementDataWithTextLimit {
  readonly _lengthUnitOption: UnitOption;
  readonly maxChars: string | null;
  readonly maxWords: string | null;
}

export interface ITypeElementDataWithAiGuidelinesIds {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
}

export type ValidationRegex = {
  readonly regex: string;
  readonly flags: string | null;
  readonly validationMessage: string | null;
  readonly isActive: boolean;
};

export const emptyValidationRegex: ValidationRegex = {
  regex: '',
  flags: null,
  validationMessage: null,
  isActive: false,
};

export interface ITypeElementDataWithValidationRegex {
  readonly validationRegex: ValidationRegex | null;
}

export interface ITypeElementDataWithLimitationCategories {
  readonly allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature>;
  readonly allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlySet<TextFormattingFeature>;
  readonly allowedTableBlocks: ReadonlySet<TableBlockCategoryFeature>;
  readonly allowedTableTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTableTextFormatting: ReadonlySet<TextFormattingFeature>;
}

export function getBaseTypeElementDataFromServerModel(
  serverModel: ContentTypeElementServerModel,
): Omit<IBaseTypeElementData, '_configurability' | 'type'> {
  return {
    codename: serverModel.codeName,
    condition: convertConditionFromServerModel(serverModel.conditions),
    contentGroupId: serverModel.contentGroupId,
    elementId: serverModel.elementId,
    guidelines: serverModel.guidelines || EmptyBaseTypeElementData.guidelines,
    isNonLocalizable: !!serverModel.isNonLocalizable,
    isRequired: !!serverModel.isRequired,
    name: serverModel.name,
  };
}
