import { forwardRef } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { FailedItemsModal } from '../../../../contentInventory/content/components/FailedItemsModal/FailedItemsModal.tsx';

export interface ICascadeFailedItemsModalProps extends IAnimatedModalDialogProps {
  readonly failedItemIds: ReadonlyArray<ContentItemId>;
  readonly modalTitle: string;
  readonly onClose: () => void;
  readonly statusInfoMessage: IStatusInfoMessage;
}

export const CascadeFailedItemsModal = forwardRef<HTMLDivElement, ICascadeFailedItemsModalProps>(
  (props, ref) => (
    <FailedItemsModal
      dataUiElement={DataUiElement.CascadePublishFailedItemsDialog}
      failedItemIds={props.failedItemIds}
      isOpen={props.isOpen}
      modalTitle={props.modalTitle}
      onClose={props.onClose}
      ref={ref}
      renderScrollTableTitle={() => <ListingMessage statusInfoMessage={props.statusInfoMessage} />}
      showCollectionColumn={false}
    />
  ),
);

CascadeFailedItemsModal.displayName = 'CascadeFailedItemsModal';
