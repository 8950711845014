import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { Subscription_SubscriptionUsers_Loaded } from '../../constants/subscriptionManagementActionTypes.ts';
import {
  ISubscriptionUser,
  createSubscriptionUserFromServerModel,
} from '../../models/SubscriptionUserInfo.ts';

interface ILoadSubscriptionUserDependencies {
  readonly subscriptionRepository: ISubscriptionRepository;
}

const subscriptionUsersLoaded = (subscriptionUsers: Immutable.List<ISubscriptionUser>) =>
  ({
    type: Subscription_SubscriptionUsers_Loaded,
    payload: {
      subscriptionUsers,
    },
  }) as const;

export type LoadSubscriptionUsersActionsType = ReturnType<typeof subscriptionUsersLoaded>;

export const loadSubscriptionUsersCreator =
  (dependencies: ILoadSubscriptionUserDependencies) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const userServerModels = await dependencies.subscriptionRepository.getSubscriptionUsers(
      subscriptionId,
      abortSignal,
    );
    const users = userServerModels.map(createSubscriptionUserFromServerModel);

    dispatch(subscriptionUsersLoaded(Immutable.List(users)));
  };
