import { logError } from '../../../../../../../_shared/utils/logError.ts';
import { IBaseTypeElementData } from '../../../../models/elements/types/TypeElementData.ts';
import { ConditionElementId } from '../../../../types/ConditionElementId.type.ts';
import { isMultipleChoiceTypeElement } from '../../../../types/typeElementTypeGuards.ts';
import {
  OptionsError,
  TriggerError,
  TypeElementConditionValidationResult,
} from '../../types/TypeElementWithConditionValidationResult.ts';

export type ConditionValidationData = {
  readonly activeConditionsBeforeLastSave: ReadonlyArray<ConditionElementId>;
  readonly typeElements: ReadonlyArray<IBaseTypeElementData>;
};

export const getConditionValidationResult = (
  typeElement: IBaseTypeElementData,
  { activeConditionsBeforeLastSave, typeElements }: ConditionValidationData,
): TypeElementConditionValidationResult => {
  const triggerElement = typeElements.find(
    (el) => el.elementId === typeElement.condition?.trigger.elementId,
  );

  if (
    !typeElement.condition?.isActive ||
    !shouldShowValidation(typeElement, activeConditionsBeforeLastSave)
  ) {
    return InitialConditionValidationResult;
  }

  if (!typeElement.condition.trigger.elementId) {
    return {
      ...InitialConditionValidationResult,
      triggerError: TriggerError.TriggerNotSet,
    };
  }

  if (!triggerElement) {
    return {
      ...InitialConditionValidationResult,
      triggerError: TriggerError.DeletedTrigger,
    };
  }

  if (!isMultipleChoiceTypeElement(triggerElement)) {
    logError(
      `${__filename}: Trigger in condition is not a multiple choice type element. Its type is: ${triggerElement.type}.`,
    );
    return {
      ...InitialConditionValidationResult,
      triggerError: TriggerError.TriggerIsNotMultipleChoice,
    };
  }

  if (typeElement.condition.trigger.optionIds.length === 0) {
    return {
      ...InitialConditionValidationResult,
      optionsError: OptionsError.NoOptionSet,
    };
  }

  const allOptionsExist = typeElement.condition.trigger.optionIds.every(
    (optionId) => !!triggerElement.options[optionId],
  );
  if (!allOptionsExist) {
    return {
      ...InitialConditionValidationResult,
      optionsError: OptionsError.DeletedOption,
    };
  }

  return InitialConditionValidationResult;
};

export const InitialConditionValidationResult: TypeElementConditionValidationResult = {
  optionsError: OptionsError.None,
  triggerError: TriggerError.None,
};

const shouldShowValidation = (
  typeElement: IBaseTypeElementData,
  activeConditionsBeforeLastSave: ReadonlyArray<ConditionElementId>,
): boolean =>
  !!activeConditionsBeforeLastSave.find(
    ({ conditionId, elementId }) =>
      elementId === typeElement.elementId && conditionId === typeElement.condition?.id,
  );
