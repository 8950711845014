import { RichTextElementUpdateData } from '@kontent-ai/smart-link';
import { ContentBlock, EntityInstance } from 'draft-js';
import { GetState } from '../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { isUrlSlugTypeElement } from '../../../contentModels/shared/types/typeElementTypeGuards.ts';
import { IRichTextItemElement } from '../../../itemEditor/models/contentItemElements/RichTextItemElement.ts';
import { getContentComponentId } from '../../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { getImageAssetReference } from '../../../richText/plugins/images/api/editorImageUtils.ts';
import { isAiInstruction } from '../../../richText/plugins/inlineAi/utils/InstructionEntity.ts';
import { getModularContentItemId } from '../../../richText/plugins/linkedItems/api/editorModularUtils.ts';
import {
  isAssetLink,
  isContentLink,
  isEmailLink,
  isLink,
  isPhoneLink,
  isWebLink,
} from '../../../richText/plugins/links/api/LinkEntity.ts';
import { BlockType } from '../../../richText/utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../../richText/utils/blocks/editorBlockGetters.ts';
import { getBlockHierarchy } from '../../../richText/utils/blocks/editorHierarchyUtils.ts';
import { KontentObjectType } from '../../../richText/utils/export/html/elements/objects.ts';
import { HtmlData } from '../../../richText/utils/export/html/elements/tags.ts';
import { generateHtml } from '../../../richText/utils/export/html/generateHtml.ts';
import { getBlocks } from '../../../richText/utils/general/editorContentGetters.ts';
import { ModularContentPreviewDataContext } from '../../types/ElementPreviewDataResolvers.type.ts';
import { getContentComponentDataForPreview } from './getContentComponentDataForPreview.ts';

type RichTextData = RichTextElementUpdateData['data'];

export const getRichTextData = (
  richTextElement: IRichTextItemElement,
  getState: GetState,
  context: ModularContentPreviewDataContext,
): RichTextData | null => {
  const {
    sharedApp: {
      selectedLanguage: { id: selectedLanguageId },
    },
    data: {
      assets: { byId: assetsById },
      listingContentItems: { byId: listingItemsById, defaultById: defaultListingItemsById },
      contentTypes: { byId: contentTypesById },
    },
    contentApp: { editedContentItem },
  } = getState();

  if (!selectedLanguageId || !editedContentItem) {
    return null;
  }

  const draftContentComponents = (context.rootRichTextElement ?? richTextElement).contentComponents;
  const editorContent = richTextElement._editorState.getCurrentContent();

  const images: RichTextData['images'] = [];
  const linkedItemCodenames: RichTextData['linkedItemCodenames'] = [];
  const linkedItems: RichTextData['linkedItems'] = [];
  const links: RichTextData['links'] = [];

  const getObjectBlockData = (block: ContentBlock): HtmlData | null => {
    const blockType = getBaseBlockType(block);
    switch (blockType) {
      case BlockType.Image: {
        const assetId = getImageAssetReference(block)?.id;
        if (!isUuid(assetId)) {
          return null;
        }

        const asset = assetsById.get(assetId);
        if (!asset?._deliveryLink) {
          return null;
        }

        images.push({
          imageId: assetId,
          url: asset._deliveryLink,
          height: asset.height,
          width: asset.width,
          description: asset.descriptions.get(selectedLanguageId) ?? null,
        });

        return [
          {
            tagName: 'figure',
            attributes: {
              'data-asset-id': assetId,
              'data-image-id': assetId,
            },
            children: [
              {
                tagName: 'img',
                attributes: {
                  src: asset._deliveryLink,
                  'data-asset-id': assetId,
                  alt: '',
                },
                selfClosing: true,
              },
            ],
          },
        ];
      }

      case BlockType.ContentModule: {
        const contentItemId = getModularContentItemId(block);
        if (!isUuid(contentItemId)) {
          return null;
        }

        const listingContentItem = listingItemsById.get(contentItemId);
        if (!listingContentItem) {
          return null;
        }

        linkedItemCodenames.push(listingContentItem.item.codeName);

        return [
          {
            tagName: 'object',
            attributes: {
              type: KontentObjectType,
              'data-type': 'item',
              'data-rel': 'link',
              'data-codename': listingContentItem.item.codeName,
            },
          },
        ];
      }

      case BlockType.ContentComponent: {
        const contentComponentId = getContentComponentId(block);
        if (!isUuid(contentComponentId)) {
          return null;
        }

        const draftContentComponent = draftContentComponents.get(contentComponentId);
        if (!draftContentComponent) {
          return null;
        }

        const deliveryContentComponent = getContentComponentDataForPreview(
          draftContentComponent,
          getState,
          context,
        );

        if (!deliveryContentComponent) {
          return null;
        }

        linkedItems.push(deliveryContentComponent);
        linkedItemCodenames.push(deliveryContentComponent.system.codename);

        return [
          {
            tagName: 'object',
            attributes: {
              type: KontentObjectType,
              'data-type': 'item',
              'data-rel': 'component',
              'data-codename': deliveryContentComponent.system.codename,
            },
          },
        ];
      }

      default:
        return null;
    }
  };

  const getEntityData = (entity: EntityInstance, content: HtmlData): HtmlData | null => {
    if (isLink(entity)) {
      if (isWebLink(entity)) {
        const data = entity.getData();

        return data.url
          ? [
              {
                tagName: 'a',
                attributes: {
                  href: data.url,
                  title: data.title || undefined,
                  ...(data.openInNewWindow
                    ? {
                        'data-new-window': 'true',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      }
                    : undefined),
                },
                children: content,
              },
            ]
          : null;
      }

      if (isEmailLink(entity)) {
        const data = entity.getData();
        return data.emailAddress
          ? [
              {
                tagName: 'a',
                attributes: {
                  'data-email-address': data.emailAddress,
                  href: `mailto:${data.emailAddress}${
                    data.emailSubject ? `?subject=${data.emailSubject}` : ''
                  }`,
                },
                children: content,
              },
            ]
          : null;
      }

      if (isPhoneLink(entity)) {
        const data = entity.getData();

        return data.phoneNumber
          ? [
              {
                tagName: 'a',
                attributes: {
                  'data-phone-number': data.phoneNumber,
                  href: `tel:${data.phoneNumber}`,
                },
                children: content,
              },
            ]
          : null;
      }

      if (isAssetLink(entity)) {
        const assetId = entity.getData().assetId;
        if (!isUuid(assetId)) {
          return null;
        }

        const asset = assetsById.get(assetId);

        return asset?._deliveryLink
          ? [
              {
                tagName: 'a',
                attributes: {
                  'data-asset-id': assetId,
                  href: asset._deliveryLink,
                },
                children: content,
              },
            ]
          : null;
      }

      if (isContentLink(entity)) {
        const itemId = entity.getData().itemId;
        if (!isUuid(itemId)) {
          return null;
        }

        const item = listingItemsById.get(itemId);
        if (!item) {
          return null;
        }

        const type = contentTypesById.get(item.item.typeId);
        if (!type?.codename) {
          return null;
        }

        const isDefaultVariant = selectedLanguageId === DefaultVariantId;
        const hasNonLocalizableSlug = type.typeElements.some(
          (el) => isUrlSlugTypeElement(el) && el.isNonLocalizable,
        );

        const urlSlug =
          hasNonLocalizableSlug && !isDefaultVariant
            ? defaultListingItemsById.get(item.item.id)?.variant?.urlSlug
            : item.variant?.urlSlug;

        links.push({
          codename: item.item.codeName,
          type: type.codename,
          linkId: itemId,
          urlSlug: urlSlug ?? '',
        });

        return item
          ? [
              {
                tagName: 'a',
                attributes: {
                  'data-item-id': itemId,
                  href: '',
                },
                children: content,
              },
            ]
          : null;
      }
    }
    if (isAiInstruction(entity)) {
      return [];
    }
    return null;
  };

  try {
    const value = generateHtml(
      {
        entityMap: editorContent.getEntityMap(),
        nodes: getBlockHierarchy(getBlocks(editorContent)),
      },
      {
        newLine: '',
        indent: '',
        getObjectBlockData,
        getEntityData,
      },
    );

    return {
      value,
      images,
      linkedItemCodenames,
      linkedItems,
      links,
    };
  } catch {
    return null;
  }
};
