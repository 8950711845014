import { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { ensureWebSpotlight } from '../actions/thunkWebSpotlightActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureWebSpotlight = ({ children }: Props) => {
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const history = useHistory();

  const [isEnsureThunkDone] = useThunkPromise(ensureWebSpotlight, projectId, history);

  return isEnsureThunkDone ? children : <Loader />;
};
