import { Action } from '../../../../@types/Action.type.ts';
import { IContentItemOverwritten } from '../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_CurrentItemOverwritten,
  Content_Editing_CurrentItemOverwrittenNotificationClosed,
} from '../../constants/editorActionTypes.ts';
import { ContentItemEditing_Init_Ready } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { shouldResetEditedItemState } from '../utils/shouldResetEditedItemState.ts';

const initialState: IContentItemOverwritten | null = null;

export function editedContentItemOverwritten(
  state = initialState,
  action: Action,
): IContentItemOverwritten | null {
  if (shouldResetEditedItemState(action)) {
    return initialState;
  }

  switch (action.type) {
    case Content_Editing_CurrentItemOverwritten: {
      return action.payload.params;
    }

    case ContentItemEditing_Init_Ready:
    case Content_Editing_CurrentItemOverwrittenNotificationClosed:
      return initialState;

    default:
      return state;
  }
}
