import { AiErrorCode, AiErrorCodeMap } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';

export const reviewByGuidelinesErrorMessageByErrorCode: AiErrorCodeMap = {
  ...baseErrorMessageByErrorCode,
  [AiErrorCode.ActionNotSupported]: 'An error occurred. Please try again.',
  [AiErrorCode.General]: 'An error occurred. Please try again.',
  [AiErrorCode.InactivityTimeout]: 'An error occurred. Please try again.',
  [AiErrorCode.InputContentFilter]: 'An error occurred. Please try again.',
  [AiErrorCode.InputTooLong]: 'An error occurred. Please try again.',
  [AiErrorCode.InputTooShort]:
    'This content is too short. To have it reviewed by AI, provide 10 words or more.',
  [AiErrorCode.InvalidData]: 'An error occurred. Please try again.',
  [AiErrorCode.NoResult]: 'An error occurred. Please try again.',
  [AiErrorCode.OutputIncomplete]: 'An error occurred. Please try again.',
  [AiErrorCode.ResponseContentFilter]: 'An error occurred. Please try again.',
  [AiErrorCode.Unable]: 'An error occurred. Please try again.',
};
