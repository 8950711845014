import { IListingContentItem } from '../../data/models/listingContentItems/IListingContentItem.ts';
import { DefaultVariantId } from '../constants/variantIdValues.ts';
import { IStore } from '../stores/IStore.type.ts';
import { getEditedContentItem } from './getEditedContentItem.ts';
import { getListingContentItem } from './getListingContentItem.ts';

export const getEditedContentItemDefaultVariant = (state: IStore): IListingContentItem | null => {
  const editedContentItemId = getEditedContentItem(state).id;
  return getListingContentItem(state, { itemId: editedContentItemId, variantId: DefaultVariantId });
};
