import { Action } from '../../../../../@types/Action.type.ts';
import { ByStatus } from '../../../../../_shared/constants/userListingFilter.ts';
import {
  UserListing_CollectionsFilter_Updated,
  UserListing_Init_Finished,
  UserListing_LanguagesFilter_Updated,
  UserListing_NameListingFilter_Changed,
  UserListing_RolesFilter_Updated,
  UserListing_SearchPhrase_Cleared,
  UserListing_StatusFilter_Updated,
} from '../../constants/usersActionTypes.ts';

export interface IUserFilterState {
  readonly byCollection: ReadonlySet<Uuid>;
  readonly byLanguage: ReadonlySet<Uuid>;
  readonly byName: string;
  readonly byRoles: ReadonlySet<Uuid>;
  readonly byStatus: ByStatus;
}

export const defaultUserFilter: IUserFilterState = {
  byCollection: new Set(),
  byLanguage: new Set(),
  byName: '',
  byRoles: new Set(),
  byStatus: ByStatus.Active,
};

export const filter = (
  state: IUserFilterState = defaultUserFilter,
  action: Action,
): IUserFilterState => {
  switch (action.type) {
    case UserListing_Init_Finished: {
      const { userListingConfig } = action.payload;

      return {
        byCollection: new Set(userListingConfig.collectionsFilter),
        byLanguage: new Set(userListingConfig.languagesFilter),
        byName: userListingConfig.userNameFilter,
        byRoles: new Set(userListingConfig.rolesFilter),
        byStatus: userListingConfig.statusFilter,
      };
    }

    case UserListing_NameListingFilter_Changed:
      return {
        ...state,
        byName: action.payload.name,
      };

    case UserListing_CollectionsFilter_Updated:
      return {
        ...state,
        byCollection: action.payload.collectionsIds,
      };

    case UserListing_LanguagesFilter_Updated:
      return {
        ...state,
        byLanguage: action.payload.languagesIds,
      };

    case UserListing_RolesFilter_Updated:
      return {
        ...state,
        byRoles: action.payload.rolesIds,
      };

    case UserListing_StatusFilter_Updated:
      return {
        ...state,
        byStatus: action.payload.status,
      };

    case UserListing_SearchPhrase_Cleared:
      return defaultUserFilter;

    default:
      return state;
  }
};
