import React from 'react';
import { EnvironmentSettingsAppNames } from '../../applications/environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';
import { ProjectSettingsAppNames } from '../../applications/projectSettings/root/constants/ProjectSettingsAppNames.ts';
import { AppNames } from '../constants/applicationNames.ts';
import { HtmlPageTitle } from './HtmlPageTitle.tsx';

type Props = {
  readonly customName?: string;
  readonly settingsAppName: EnvironmentSettingsAppNames | ProjectSettingsAppNames;
};

const getPageTitle = ({ settingsAppName, customName }: Props): string =>
  `${customName || settingsAppName} | ${AppNames.Settings}`;

export const HtmlSettingsPageTitle: React.FC<Props> = (props) => (
  <HtmlPageTitle appName={AppNames.Settings} customName={getPageTitle(props)} />
);

HtmlSettingsPageTitle.displayName = 'HtmlSettingsPageTitle';
