import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  ProvideAPositiveNumberForLimit,
  ProvideValidImageSizeLimit,
  SelectAtLeastOneLimitationCategory,
  SelectAtLeastOneTableTextBlock,
  SelectAtLeastOneTextBlock,
} from '../../../constants/errorMessageTemplates.ts';
import { IRichTextTypeElementData } from '../../../models/elements/RichTextTypeElementData.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { RichTextTypeElementValidationResult } from '../../../utils/typeElementValidators/types/RichTextTypeElementValidationResult.type.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';
import { getBaseTextTypeElementValidationResult } from './validateBaseTextTypeElement.ts';

function getRichTextTypeElementValidationResult(
  deps: ITypeElementValidatorDependencies,
  typeElement: IRichTextTypeElementData,
  conditionValidationData: ConditionValidationData,
): RichTextTypeElementValidationResult {
  const { isLimitValueValid, isFileSizeValid } = deps.typeElementValidationUtils;

  const baseTextResult = getBaseTextTypeElementValidationResult(deps, typeElement, true);
  const errors = baseTextResult.errorMessages;

  const isWidthValid =
    isLimitValueValid(typeElement.minWidth) && isLimitValueValid(typeElement.maxWidth);
  const isHeightValid =
    isLimitValueValid(typeElement.minHeight) && isLimitValueValid(typeElement.maxHeight);
  const isImageSizeValid = isFileSizeValid(typeElement.fileSize);
  const areAllowedBlocksValid = !!typeElement.allowedBlocks.size;
  const areAllowedTextBlocksValid = !!typeElement.allowedTextBlocks.size;
  const areAllowedTableTextBlocksValid = !!typeElement.allowedTableTextBlocks.size;

  if (!isImageSizeValid) {
    errors.push(ProvideValidImageSizeLimit);
  }
  if (!isWidthValid) {
    errors.push(ProvideAPositiveNumberForLimit(' for the image width'));
  }
  if (!isHeightValid) {
    errors.push(ProvideAPositiveNumberForLimit(' for the image height'));
  }
  if (!areAllowedBlocksValid) {
    errors.push(SelectAtLeastOneLimitationCategory);
  }
  if (!areAllowedTextBlocksValid) {
    errors.push(SelectAtLeastOneTextBlock);
  }
  if (!areAllowedTableTextBlocksValid) {
    errors.push(SelectAtLeastOneTableTextBlock);
  }

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    ...baseTextResult,
    conditionValidationResult,
    isWidthValid,
    isHeightValid,
    isImageSizeValid,
    areAllowedBlocksValid,
    areAllowedTextBlocksValid,
    areAllowedTableTextBlocksValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errors,
    ],
  };
}

export const createValidateRichTextTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IBaseTypeElementData): ThunkFunction<RichTextTypeElementValidationResult> =>
  (dispatch, getState) => {
    const validationResult = getRichTextTypeElementValidationResult(
      deps,
      typeElement as IRichTextTypeElementData,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
