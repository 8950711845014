import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { useCallback } from 'react';
import { useSelector } from '../../hooks/useSelector.ts';
import { SentryReportABugWidget } from '../../sentry.ts';

const actionDescription = 'Report a bug';

export const ReportBugButton = () => {
  const isEnabled = useSelector((s) => s.sharedApp.userProperties.isSentryBugReportWidgetEnabled);

  const buttonRef = useCallback(
    (button: HTMLButtonElement | null) => {
      if (button && isEnabled) {
        SentryReportABugWidget.enableWidget(button);
      } else {
        SentryReportABugWidget.disableWidget();
      }
    },
    [isEnabled],
  );

  if (!isEnabled) {
    return null;
  }

  return (
    <QuinaryButton ref={buttonRef} tooltipPlacement="right" tooltipText={actionDescription}>
      <QuinaryButton.Icon icon={Icons.Bug} screenReaderText={actionDescription} />
    </QuinaryButton>
  );
};
