import React, { createContext, useMemo } from 'react';
import { IPlan } from '../../../../data/models/plans/Plan.ts';

type SubscriptionCapabilitiesContextState = {
  readonly areCollectionRolesEnabled: boolean;
  readonly areLanguageRolesEnabled: boolean;
};

const defaultContext: SubscriptionCapabilitiesContextState = {
  areCollectionRolesEnabled: false,
  areLanguageRolesEnabled: false,
};

export const SubscriptionCapabilitiesContext =
  createContext<SubscriptionCapabilitiesContextState>(defaultContext);

type SubscriptionCapabilitiesContextProviderProps = {
  readonly subscriptionPlan: IPlan;
};

export const SubscriptionCapabilitiesContextProvider: React.FC<
  React.PropsWithChildren<SubscriptionCapabilitiesContextProviderProps>
> = ({ children, subscriptionPlan }) => {
  const contextState = useMemo<SubscriptionCapabilitiesContextState>(
    () => ({
      areCollectionRolesEnabled:
        subscriptionPlan.features.areContentCollectionsEnabled &&
        subscriptionPlan.features.areContentCollectionsPermissionsEnabled,
      areLanguageRolesEnabled: subscriptionPlan.features.areLanguageRolesEnabled,
    }),
    [subscriptionPlan],
  );

  return (
    <SubscriptionCapabilitiesContext.Provider value={contextState}>
      {children}
    </SubscriptionCapabilitiesContext.Provider>
  );
};
