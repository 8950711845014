import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ContentItemId } from '../../../_shared/models/ContentItemId.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { Task } from '../../../_shared/models/Task.ts';
import { IUserInfo } from '../../../_shared/models/UserInfo.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { getUserById } from '../../../_shared/utils/usersUtils.ts';
import { PublishingState } from '../../../data/constants/PublishingState.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILanguage } from '../../../data/models/languages/Language.ts';
import { IAssignmentWorkflowStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { untitledContentItem } from '../../contentInventory/content/constants/uiConstants.ts';
import { IYourTasksListingContentItem } from '../models/IYourTasksListingContentItem.type.ts';

type AssignedItemInfo = ContentItemId & {
  readonly contentItemDueDate: DateTimeStamp | null;
  readonly contentItemScheduledToPublishAt: DateTimeStamp | null;
  readonly contentItemScheduledToUnpublishAt: DateTimeStamp | null;
  readonly contentItemName: string;
  readonly contentItemVariantName: string;
  readonly contentType: string;
  readonly publishingState: PublishingState;
  readonly workflowStatus: IAssignmentWorkflowStep;
};

export type YourTask = AssignedItemInfo & {
  readonly description: string;
  readonly taskDueDate: string | null;
  readonly id: Uuid;
  readonly lastModified: string;
  readonly taskAuthor: IUserInfo;
};

type ValueHasSuccessfulResult<TValue> = {
  success: true;
  value: TValue;
};

type ValueWithResult<TValue> =
  | ValueHasSuccessfulResult<TValue>
  | {
      success: false;
    };

type TasksContentItemInfoResult = ValueWithResult<AssignedItemInfo>;

type BuiltYourTask = ValueHasSuccessfulResult<YourTask>;
type BuildYourTaskResult = ValueWithResult<YourTask>;

enum CompareResult {
  AIsLargerThanB = -1,
  BIsLargerThanA = 1,
  Same = 0,
}

const emptyString = '';

const NotFoundTaskContentItem: TasksContentItemInfoResult = {
  success: false,
};

const BuildYourTaskFailed: BuildYourTaskResult = {
  success: false,
};

const isResultSuccessful = <TValue>(
  valueWithResult: ValueWithResult<TValue>,
): valueWithResult is ValueHasSuccessfulResult<TValue> => valueWithResult.success;

const getTaskAuthor = (task: Task, state: IStore): IUserInfo =>
  getUserById(state.data.users.usersById, task.owner);

export const getYourTaskVariantIdentifier = (contentItemId: Uuid, variantId: Uuid): string =>
  `${contentItemId};${variantId}`;

const parseContentItemInfo = (
  itemVariant: IYourTasksListingContentItem,
  contentVariantId: Uuid,
  contentTypes: Immutable.Map<Uuid, IContentType>,
  languages: Immutable.Map<Uuid, ILanguage>,
) => {
  const {
    item: { id: contentItemId, typeId, name },
    variant: {
      publishingState,
      assignment: {
        due: contentItemDueDate,
        workflowStatus,
        scheduledToPublishAt: contentItemScheduledToPublishAt,
        scheduledToUnpublishAt: contentItemScheduledToUnpublishAt,
      },
    },
  } = itemVariant;

  const contentItemType = contentTypes.get(typeId);
  const contentItemLanguage = languages.get(contentVariantId);

  return {
    contentItemDueDate,
    contentItemScheduledToPublishAt,
    contentItemScheduledToUnpublishAt,
    itemId: contentItemId,
    contentItemName: name || untitledContentItem,
    contentItemVariantName: contentItemLanguage?.name ?? emptyString,
    contentType: contentItemType?.name ?? emptyString,
    variantId: contentVariantId,
    publishingState,
    workflowStatus,
  };
};

// memoization here relies on tasks collection being sorted by ItemId + VariantId before the getTasksContentItemInfo is called
const getTasksContentItemInfoMemoized = memoize.maxOne(
  (
    itemId: Uuid,
    variantId: Uuid,
    contentItemsWithTasks: ReadonlyMap<Uuid, IYourTasksListingContentItem>,
    contentTypes: Immutable.Map<Uuid, IContentType>,
    languages: Immutable.Map<Uuid, ILanguage>,
  ): TasksContentItemInfoResult => {
    const itemVariant = contentItemsWithTasks.get(getYourTaskVariantIdentifier(itemId, variantId));

    if (!itemVariant) {
      return NotFoundTaskContentItem;
    }

    return {
      success: true,
      value: parseContentItemInfo(itemVariant, variantId, contentTypes, languages),
    };
  },
);

const getTasksContentItemInfo = (task: Task, state: IStore): TasksContentItemInfoResult =>
  getTasksContentItemInfoMemoized(
    task.contentItemId.itemId,
    task.contentItemId.variantId,
    state.data.yourTasksContentItems.byId,
    state.data.contentTypes.byId,
    state.data.languages.byId,
  );

const buildYourTask = (task: Task, state: IStore): BuildYourTaskResult => {
  const { id, description, lastModifiedAt: lastModified, dueDate } = task;

  const contentItemInfoResult = getTasksContentItemInfo(task, state);
  if (!contentItemInfoResult.success) {
    return BuildYourTaskFailed;
  }

  const taskAuthor = getTaskAuthor(task, state);

  return {
    success: true,
    value: {
      description,
      taskDueDate: dueDate,
      id,
      lastModified,
      taskAuthor,
      ...contentItemInfoResult.value,
    },
  };
};

const compareDates = (a: string | null, b: string | null): CompareResult => {
  if (a === null) {
    if (b === null) {
      return CompareResult.Same;
    }

    return CompareResult.BIsLargerThanA;
  }

  if (b === null) {
    return CompareResult.AIsLargerThanB;
  }

  return a.localeCompare(b);
};

const reverseCompareResult = (result: CompareResult): CompareResult => {
  switch (result) {
    case CompareResult.AIsLargerThanB:
      return CompareResult.BIsLargerThanA;
    case CompareResult.BIsLargerThanA:
      return CompareResult.AIsLargerThanB;
    default:
      return CompareResult.Same;
  }
};

const compareByTaskDueDates = (a: YourTask, b: YourTask): CompareResult =>
  compareDates(a.taskDueDate, b.taskDueDate);

const compareLastModifiedDates = (a: YourTask, b: YourTask): CompareResult =>
  compareDates(a.lastModified, b.lastModified);

const compareTasksByItemId = (a: Task, b: Task): CompareResult => {
  const itemIdComparison = a.contentItemId.itemId.localeCompare(b.contentItemId.itemId);

  if (itemIdComparison !== CompareResult.Same) {
    return itemIdComparison;
  }

  return a.contentItemId.variantId.localeCompare(b.contentItemId.variantId);
};

const compareYourTasksByPriority = (taskA: YourTask, taskB: YourTask): CompareResult => {
  const comparisonByDueDate = compareByTaskDueDates(taskA, taskB);
  if (comparisonByDueDate === CompareResult.Same) {
    return reverseCompareResult(compareLastModifiedDates(taskA, taskB));
  }

  return comparisonByDueDate;
};

export const memoizeYourLatestTasks = memoize.maxNWithTransformedArgs(
  (tasks: ReadonlyArray<YourTask>): ReadonlyArray<YourTask> => tasks,
  (args) => args[0],
  1,
);

const createIsYourTask = (user: IUser) => (task: Task) => task.assignees.has(user.info.userId);

export const getYourTasks = (state: IStore): readonly YourTask[] => {
  const {
    data: { tasks, user, yourTasksContentItems },
  } = state;

  if (yourTasksContentItems.loadingStatus !== LoadingStatus.Loaded) {
    return memoizeYourLatestTasks([]);
  }
  const isYourTask = createIsYourTask(user);

  const yourTasks = Collection.getValues(tasks.byId)
    .filter(isYourTask)
    .sort(compareTasksByItemId)
    .map((task: Task) => buildYourTask(task, state))
    .filter(isResultSuccessful)
    .map((result: BuiltYourTask) => result.value)
    .sort(compareYourTasksByPriority);

  return memoizeYourLatestTasks(yourTasks);
};

export const getYourTasksCount = (state: IStore): number | undefined => {
  const {
    data: { tasks, user },
  } = state;

  if (tasks.loadingStatus !== LoadingStatus.Loaded) {
    return undefined;
  }

  const isYourTask = createIsYourTask(user);

  return Collection.getValues(tasks.byId).filter(isYourTask).length;
};
