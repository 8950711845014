import { Location } from 'history';
import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../../_shared/components/AppBar/BackNavigation.tsx';
import { AssetRoute, AssetRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getGoBackLinkByPath } from '../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { getPreviouslyOpenAssetFolderPath } from '../../../../../_shared/utils/breadcrumbs/getPreviouslyOpenAssetFolderPath.ts';
import { matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';

export const AssetBackNavigation: React.FC = () => {
  const location = useLocation();

  return (
    <BackNavigation
      to={useSelector((s) => getGoBackLink(s, location))}
      isDisabled={useSelector((s) => s.assetLibraryApp.assetUiEditor.isEditedAssetBeingSaved)}
    />
  );
};

AssetBackNavigation.displayName = 'AssetBackNavigation';

const getGoBackLink = (state: IStore, location: Location<unknown>): string => {
  const {
    assetLibraryApp: {
      assetUiEditor: { editedAsset },
    },
    contentInventory: { breadcrumbsOrigin },
  } = state;

  const projectId = matchPath<AssetRouteParams>(location.pathname, AssetRoute)?.projectId || '';
  const fallbackFolderId = editedAsset?.folderId || RootFolderId;
  const assetsPath = getPreviouslyOpenAssetFolderPath(
    breadcrumbsOrigin,
    projectId,
    fallbackFolderId,
  );

  return getGoBackLinkByPath(location.pathname, assetsPath);
};
