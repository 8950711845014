import { Textarea } from '@kontent-ai/component-library/Input';
import React, { ChangeEvent, useState } from 'react';
import { ModalDialog } from '../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ImproveContentDialogProps = {
  readonly initialInstructions: string;
  readonly isDialogOpen: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (instructions: string) => void;
};

export const ImproveContentDialog: React.FC<ImproveContentDialogProps> = ({
  initialInstructions,
  isDialogOpen,
  onClose,
  onSubmit,
}) => {
  const [contentInstructions, setContentInstructions] = useState(initialInstructions);

  const onContentInstructionsChanged = (e: ChangeEvent<HTMLTextAreaElement>): void =>
    setContentInstructions(e.currentTarget.value);

  return (
    <ModalDialog
      headline="Improve content"
      isOpen={isDialogOpen}
      isDismissable
      primaryAction={{
        text: 'Improve content',
        disabled: !contentInstructions,
        tooltipText: contentInstructions
          ? ''
          : 'Fill the Content Brief first. You can define attributes such as language, target audience, tone, length, formatting etc.',
        onClick: () => onSubmit(contentInstructions),
      }}
      onClose={onClose}
    >
      <Textarea
        autoFocus
        value={contentInstructions}
        placeholder="Write instructions for AI how to improve your content …"
        onChange={onContentInstructionsChanged}
        minRows={3}
        maxRows={10}
        label="Content instructions"
        caption="Your instructions can contain different attributes in your content, such as target audience, language, tone, and so on"
        {...getDataUiInputAttribute(DataUiInput.Text)}
      />
    </ModalDialog>
  );
};

ImproveContentDialog.displayName = 'ImproveContentDialog';
