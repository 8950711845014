import { isAbortedXMLHttpRequest } from './errorUtils.ts';

export class AbortError extends Error {
  constructor(message?: string) {
    super(message);

    this.name = 'AbortError';
  }
}

export function swallowAbortError(error: unknown): void | never {
  if (!isAbortError(error)) {
    throw error;
  }
}

export function isAbortError(error: unknown): error is AbortError | XMLHttpRequest {
  return (
    error instanceof AbortError ||
    isAbortedXMLHttpRequest(error) ||
    (error instanceof DOMException && error.name === 'AbortError') // Default abort reason https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/reason
  );
}
