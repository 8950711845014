import { gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
}

const ConfirmationDialogContentWrapper = styled.p`
  max-width: ${64 * gridUnit}px;
  min-width: ${64 * gridUnit}px;
`;

export const AssetRenditionRemoveConfirmationDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => (
  <ModalDialog
    isDismissable
    isOpen={isOpen}
    onClose={onClose}
    headline="Delete the customized image"
    {...getDataUiElementAttribute(DataUiElement.AssetRenditionRemoveConfirmationDialog)}
    primaryAction={{
      onClick: onConfirm,
      text: 'Delete',
      destructive: true,
      ...getDataUiActionAttribute(DataUiAction.Confirm),
    }}
    cancelAction={{
      onClick: onClose,
      tooltipText: 'Cancel',
      tooltipShortcuts: ShortcutSymbols.Escape,
      ...getDataUiActionAttribute(DataUiAction.Cancel),
    }}
  >
    <ConfirmationDialogContentWrapper>
      The customized image will be deleted from the content item and replaced by the original image.
      The original image will remain unchanged.
    </ConfirmationDialogContentWrapper>
  </ModalDialog>
);

AssetRenditionRemoveConfirmationDialog.displayName = 'AssetRenditionRemoveConfirmationDialog';
