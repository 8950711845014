import { TabBadgeType } from '../../../../../_shared/components/TabMenu/TabBadgeType.tsx';

export const getContentGroupBadgeType = (
  showIncompleteItemWarningsBeforePublish: boolean,
  hideValidationStatus: boolean | undefined,
): TabBadgeType | undefined => {
  const rawBadgeType = showIncompleteItemWarningsBeforePublish
    ? TabBadgeType.Error
    : TabBadgeType.Warning;
  return hideValidationStatus ? undefined : rawBadgeType;
};
