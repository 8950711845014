import { IBaseTypeElementData } from '../../../applications/contentModels/shared/models/elements/types/TypeElementData.ts';
import { getTypeElementRecord } from '../../../applications/contentModels/shared/utils/typeElementDataConverter.ts';
import {
  ContentTypeBaseServerModel,
  ContentTypeBaseServerModelWithElements,
} from '../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import { ContentTypeConversionOptions } from './ContentTypeConversionOptions.type.ts';

export interface IContentTypeBase {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string | null;
  readonly typeElements: ReadonlyArray<IBaseTypeElementData>;
  readonly lastModified: DateTimeStamp | null;
  readonly lastModifiedBy: UserId | null;
  readonly isArchived: boolean;
}

export const emptyContentTypeBase: IContentTypeBase = {
  id: '',
  name: '',
  codename: null,
  typeElements: [],
  lastModified: null,
  lastModifiedBy: null,
  isArchived: false,
};

export const getContentTypeBaseFromServerModel = (
  serverModel: ContentTypeBaseServerModelWithElements,
  conversionOptions: ContentTypeConversionOptions,
): IContentTypeBase => ({
  id: serverModel._id ?? emptyContentTypeBase.id,
  name: serverModel.name ?? emptyContentTypeBase.name,
  codename: serverModel.codeName ?? emptyContentTypeBase.codename,
  typeElements:
    serverModel?.contentElements?.map((el) => getTypeElementRecord(el, conversionOptions)) ??
    emptyContentTypeBase.typeElements,
  lastModified: serverModel.lastModified ?? emptyContentTypeBase.lastModified,
  lastModifiedBy: serverModel.lastModifiedBy ?? emptyContentTypeBase.lastModifiedBy,
  isArchived: serverModel.archived ?? emptyContentTypeBase.isArchived,
});

export const convertContentTypeBaseToServerModel = (
  contentTypeBase: IContentTypeBase,
): ContentTypeBaseServerModel => ({
  _id: contentTypeBase.id,
  archived: contentTypeBase.isArchived,
  codeName: contentTypeBase.codename,
  lastModified: contentTypeBase.lastModified,
  lastModifiedBy: contentTypeBase.lastModifiedBy,
  name: contentTypeBase.name,
});
