import { memoize } from '@kontent-ai/memoization';
import {
  EditableElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { EditableTypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { EmptyAssetItemElementValue } from '../models/contentItemElements/AssetItemElement.ts';
import { EmptyCustomItemElementValue } from '../models/contentItemElements/CustomItemElement.ts';
import { EmptyDateTimeItemElementValue } from '../models/contentItemElements/DateTimeItemElement.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { EmptyMultipleChoiceItemElementValue } from '../models/contentItemElements/MultipleChoiceItemElement.ts';
import { EmptyNumberItemElementValue } from '../models/contentItemElements/NumberItemElement.ts';
import { EmptyRichTextItemElementValue } from '../models/contentItemElements/RichTextItemElement.ts';
import { EmptyTaxonomyItemElementValue } from '../models/contentItemElements/TaxonomyItemElement.ts';
import { EmptyTextItemElementValue } from '../models/contentItemElements/TextItemElement.ts';
import { EmptyUrlSlugItemElementValue } from '../models/contentItemElements/UrlSlugItemElement.ts';
import { EmptyLinkedItemsElementValue } from '../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { createAssetItemElement } from './itemElementCreators/assetItemElementCreator.ts';
import { createCustomItemElement } from './itemElementCreators/createCustomElementCreator.ts';
import { createDateTimeItemElement } from './itemElementCreators/dateTimeItemElementCreator.ts';
import { createModularContentItemElement } from './itemElementCreators/modularContentItemElementCreator.ts';
import { createMultipleChoiceItemElement } from './itemElementCreators/multipleChoiceItemElementCreator.ts';
import { createNumberItemElement } from './itemElementCreators/numberItemElementCreator.ts';
import { createRichTextItemElement } from './itemElementCreators/richTextItemElementCreator.ts';
import { createTaxonomyItemElement } from './itemElementCreators/taxonomyItemElementCreator.ts';
import { createTextItemElement } from './itemElementCreators/textItemElementCreator.ts';
import { createUrlSlugItemElement } from './itemElementCreators/urlSlugItemElementCreator.ts';

export type ItemElementCreator<
  TTypeElement extends EditableTypeElement,
  TItemElementData extends ICompiledContentItemElementData,
> = (elementType: TTypeElement, omitDefaultValue: boolean) => TItemElementData;

const itemElementCreatorsMap: ReadonlyRecord<EditableElementType, ItemElementCreator<any, any>> = {
  [ElementType.Asset]: createAssetItemElement,
  [ElementType.Subpages]: createModularContentItemElement,
  [ElementType.DateTime]: createDateTimeItemElement,
  [ElementType.LinkedItems]: createModularContentItemElement,
  [ElementType.MultipleChoice]: createMultipleChoiceItemElement,
  [ElementType.Number]: createNumberItemElement,
  [ElementType.Custom]: createCustomItemElement,
  [ElementType.RichText]: createRichTextItemElement,
  [ElementType.Text]: createTextItemElement,
  [ElementType.Taxonomy]: createTaxonomyItemElement,
  [ElementType.UrlSlug]: createUrlSlugItemElement,
};

export const createItemElementWithInitialValue = memoize.maxN(
  (typeElement: EditableTypeElement): ICompiledContentItemElementData => {
    const createItemElement = itemElementCreatorsMap[typeElement.type];
    const itemElement = createItemElement(typeElement, true);
    return itemElement;
  },
  Object.keys(itemElementCreatorsMap).length,
);

export const createItemElementWithDefaultValue = memoize.maxN(
  (typeElement: EditableTypeElement): ICompiledContentItemElementData => {
    const createItemElement = itemElementCreatorsMap[typeElement.type];
    const itemElement = createItemElement(typeElement, false);
    return itemElement;
  },
  Object.keys(itemElementCreatorsMap).length,
);

export const clearItemElementValue = (
  element: ICompiledContentItemElementData,
): ICompiledContentItemElementData => ({
  ...element,
  ...elementTypeToEmptyItemElementValue[element.type],
});

const elementTypeToEmptyItemElementValue = {
  [ElementType.Asset]: EmptyAssetItemElementValue,
  [ElementType.Custom]: EmptyCustomItemElementValue,
  [ElementType.DateTime]: EmptyDateTimeItemElementValue,
  [ElementType.LinkedItems]: EmptyLinkedItemsElementValue,
  [ElementType.MultipleChoice]: EmptyMultipleChoiceItemElementValue,
  [ElementType.Number]: EmptyNumberItemElementValue,
  [ElementType.RichText]: EmptyRichTextItemElementValue,
  [ElementType.Taxonomy]: EmptyTaxonomyItemElementValue,
  [ElementType.Text]: EmptyTextItemElementValue,
  [ElementType.UrlSlug]: EmptyUrlSlugItemElementValue,
};

export const clearValuesOfElementsThatBecameHidden = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  idsOfElementsBecomingHidden: ReadonlyArray<Uuid>,
): ReadonlyArray<ICompiledContentItemElementData> =>
  elements.map((element) =>
    idsOfElementsBecomingHidden.find((elementId) => elementId === element.elementId)
      ? clearItemElementValue(element)
      : element,
  );
