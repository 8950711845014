import { Pathname } from 'history';
import { ComponentProps } from 'react';
import { BackNavigation } from './AppBar/BackNavigation.tsx';
import { Breadcrumbs } from './Breadcrumbs.tsx';

type Props = Pick<
  ComponentProps<typeof Breadcrumbs>,
  'breadcrumbs' | 'renderSavingStatusComponent'
> & {
  readonly navigateBackPath?: Pathname;
};

export const ApplicationBreadcrumbs = ({ navigateBackPath, ...breadcrumbsProps }: Props) => {
  return (
    <>
      {navigateBackPath && <BackNavigation to={navigateBackPath} />}
      <Breadcrumbs {...breadcrumbsProps} />
    </>
  );
};
