import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_DeleteItems_Finished,
  ContentListing_Init_Started,
  ContentListing_Items_Selected,
  ContentListing_Items_Unselected,
  ContentListing_MoveToCollection_Finished,
  ContentListing_Page_Left,
  ContentListing_PublishItems_Finished,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_SelectAll_Off,
  ContentListing_SelectAll_On,
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

const defaultState: Uuid | null = null;

export function lastSelectionTouchedItem(state = defaultState, action: Action): Uuid | null {
  switch (action.type) {
    case ContentListing_Items_Selected:
    case ContentListing_Items_Unselected:
      return action.payload.lastTouchedItemId;

    case ContentListing_Init_Started:
    case ContentListing_Page_Left:
    case ContentListing_SelectAll_On:
    case ContentListing_SelectAll_Off:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ContentListing_DeleteItems_Finished:
    case ContentListing_MoveToCollection_Finished:
    case ContentListing_PublishItems_Finished:
    case ContentListing_ScheduledPublishItems_Finished:
    case ContentListing_ScheduledUnpublishItems_Finished:
    case ContentListing_UnpublishItems_Finished:
    case ContentListing_CancelScheduledPublish_Finished:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentListing_UndoArchiveItems_Finished:
      return defaultState;

    default:
      return state;
  }
}
