import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export const NotTranslatedTagForVariant: React.FC = () => (
  <div
    className="bar-item__tag-not-translated-item"
    title="Not translated"
    {...getDataUiElementAttribute(DataUiElement.NotTranslatedTag)}
  >
    Not translated
  </div>
);

NotTranslatedTagForVariant.displayName = 'NotTranslatedTagForVariant';
