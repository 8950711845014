import { ITaxonomyItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ITaxonomyItemElement } from '../../models/contentItemElements/TaxonomyItemElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export const taxonomyElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
  ITaxonomyItemElementServerModel,
  ITaxonomyItemElement
> = (serverElement) => {
  const { elementId, groupId, lastModifiedAt, lastModifiedBy, value } = serverElement;

  return {
    elementId,
    groupId,
    type: ElementType.Taxonomy,
    value: new Set(value),
    lastModifiedBy: lastModifiedBy ? lastModifiedBy : null,
    lastModifiedAt: lastModifiedAt ? lastModifiedAt : null,
  };
};

export const taxonomyElementServerModelConverter: ISimpleItemElementServerModelConverter<
  ITaxonomyItemElement,
  ITaxonomyItemElementServerModel
> = (element) => {
  const { elementId, groupId, lastModifiedBy, lastModifiedAt, value } = element;

  return {
    elementId,
    groupId,
    type: ElementType.Taxonomy,
    value: [...value],
    lastModifiedBy,
    lastModifiedAt,
  };
};
