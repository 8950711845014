import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { taxonomyGroupSelectionChanged } from '../taxonomyActions.ts';

export const createUnselectTaxonomyGroupsAction =
  () =>
  (unselectedTaxonomyGroupIds: Immutable.Set<Uuid>): ThunkFunction =>
  (dispatch, getState) => {
    const updatedSelectedTaxonomyGroupIds =
      getState().taxonomyApp.selectedTaxonomyGroupIds.subtract(unselectedTaxonomyGroupIds);

    dispatch(taxonomyGroupSelectionChanged(updatedSelectedTaxonomyGroupIds));
  };
