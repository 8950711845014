import { CoreColor } from '@kontent-ai/component-library/tokens';
import { WorkflowStepColor } from '../../../constants/WorkflowStepColor.ts';

const workflowStepColorMap = {
  [WorkflowStepColor.Gray]: CoreColor.Gray,
  [WorkflowStepColor.Red]: CoreColor.Red,
  [WorkflowStepColor.Rose]: CoreColor.Rose,
  [WorkflowStepColor.LightPurple]: CoreColor.LightPurple,
  [WorkflowStepColor.DarkPurple]: CoreColor.DarkPurple,
  [WorkflowStepColor.DarkBlue]: CoreColor.DarkBlue,
  [WorkflowStepColor.LightBlue]: CoreColor.LightBlue,
  [WorkflowStepColor.SkyBlue]: CoreColor.Skyblue,
  [WorkflowStepColor.MintGreen]: CoreColor.MintGreen,
  [WorkflowStepColor.PersianGreen]: CoreColor.PersianGreen,
  [WorkflowStepColor.DarkGreen]: CoreColor.DarkGreen,
  [WorkflowStepColor.LightGreen]: CoreColor.LightGreen,
  [WorkflowStepColor.Yellow]: CoreColor.Yellow,
  [WorkflowStepColor.Pink]: CoreColor.Pink,
  [WorkflowStepColor.Orange]: CoreColor.Orange,
  [WorkflowStepColor.Brown]: CoreColor.Brown,
} as const satisfies Record<WorkflowStepColor, CoreColor>;

export const renderWorkflowStepColor = (workflowStepColor: WorkflowStepColor): CoreColor => {
  return workflowStepColorMap[workflowStepColor] ?? CoreColor.Gray;
};
