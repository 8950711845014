import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  WebhookHealthStatus,
  mapWebhookHealthStatusFromServerValue,
} from '../../../applications/webhooks/constants/webhookHealthStatuses.ts';
import { DefaultWebhookId } from '../../../applications/webhooks/constants/webhookValues.ts';
import {
  ContentTriggerOperation,
  IContentTriggerServerModel,
  IWebhookServerModel,
  IWorkflowTriggerServerModel,
  WebhookTriggerType,
} from '../../../repositories/serverModels/WebhookServerModel.ts';

export interface IWebhookSetting {
  readonly id: Uuid;
  readonly name: string;
  readonly enabled: boolean;
  readonly order: number;
  readonly secret: string;
  readonly url: string;
  readonly healthStatus: WebhookHealthStatus;
  readonly workflowStepChangeTriggers: Immutable.Map<WebhookTriggerType, ReadonlyArray<Uuid>>;
  readonly managementApiContentChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<ContentTriggerOperation>
  >;
  readonly deliveryApiContentChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<ContentTriggerOperation>
  >;
  readonly previewDeliveryApiContentChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<ContentTriggerOperation>
  >;
}

const defaultItemOperations: ReadonlyArray<ContentTriggerOperation> = [
  ContentTriggerOperation.Publish,
  ContentTriggerOperation.Archive,
  ContentTriggerOperation.Restore,
  ContentTriggerOperation.Unpublish,
];
const defaultAssetOperations: ReadonlyArray<ContentTriggerOperation> = [
  ContentTriggerOperation.Upsert,
];
const defaultTypeOperations: ReadonlyArray<ContentTriggerOperation> = [
  ContentTriggerOperation.Upsert,
];
const defaultTaxonomyOperations: ReadonlyArray<ContentTriggerOperation> = [
  ContentTriggerOperation.Archive,
  ContentTriggerOperation.Restore,
  ContentTriggerOperation.Upsert,
];
const defaultManagementApiItemOperations: ReadonlyArray<ContentTriggerOperation> = [];
const defaultPreviewItemOperations: ReadonlyArray<ContentTriggerOperation> = [];
const defaultPreviewTaxonomyOperations: ReadonlyArray<ContentTriggerOperation> = [];

export const emptyObject: IWebhookSetting = {
  healthStatus: WebhookHealthStatus.Unknown,
  id: DefaultWebhookId,
  name: '',
  enabled: true,
  order: 0,
  secret: '',
  url: '',
  workflowStepChangeTriggers: Immutable.Map(),
  managementApiContentChangeTriggers: Immutable.Map([
    [WebhookTriggerType.ContentItemVariant, defaultManagementApiItemOperations],
  ]),
  deliveryApiContentChangeTriggers: Immutable.Map([
    [WebhookTriggerType.ContentItemVariant, defaultItemOperations],
    [WebhookTriggerType.Asset, defaultAssetOperations],
    [WebhookTriggerType.ContentType, defaultTypeOperations],
    [WebhookTriggerType.Taxonomy, defaultTaxonomyOperations],
  ]),
  previewDeliveryApiContentChangeTriggers: Immutable.Map([
    [WebhookTriggerType.ContentItemVariant, defaultPreviewItemOperations],
    [WebhookTriggerType.Taxonomy, defaultPreviewTaxonomyOperations],
  ]),
};

export function createWebhookSettingDomainModel(rawWebhook: IWebhookServerModel): IWebhookSetting {
  const triggers = rawWebhook.triggers;

  const deliveryApiContentChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<ContentTriggerOperation>
  > = Immutable.Map(
    triggers.deliveryApiContentChanges.map((trigger: IContentTriggerServerModel) => [
      trigger.type,
      trigger.operations,
    ]),
  );

  const previewDeliveryApiContentChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<ContentTriggerOperation>
  > = Immutable.Map(
    triggers.previewDeliveryApiContentChanges.map((trigger: IContentTriggerServerModel) => [
      trigger.type,
      trigger.operations,
    ]),
  );

  const workflowStepChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<Uuid>
  > = Immutable.Map(
    triggers.workflowStepChanges.map((trigger: IWorkflowTriggerServerModel) => [
      trigger.type,
      trigger.transitionsTo,
    ]),
  );

  const managementApiContentChangeTriggers: Immutable.Map<
    WebhookTriggerType,
    ReadonlyArray<ContentTriggerOperation>
  > = Immutable.Map(
    triggers.managementApiContentChanges.map((trigger: IContentTriggerServerModel) => [
      trigger.type,
      trigger.operations,
    ]),
  );

  return {
    deliveryApiContentChangeTriggers,
    enabled: rawWebhook.enabled,
    healthStatus: mapWebhookHealthStatusFromServerValue(rawWebhook.healthStatus),
    id: rawWebhook.id,
    name: rawWebhook.name,
    order: rawWebhook.order,
    secret: rawWebhook.secret,
    url: rawWebhook.url,
    workflowStepChangeTriggers,
    managementApiContentChangeTriggers,
    previewDeliveryApiContentChangeTriggers,
  };
}

export function hasSelectedTrigger(value?: ReadonlyArray<Uuid>): boolean {
  return !!value && !Collection.isEmpty(value);
}

export function createWebhookSettingServerModel(
  webhookSetting: IWebhookSetting,
): IWebhookServerModel {
  const deliveryApiContentChanges: Array<IContentTriggerServerModel> =
    webhookSetting.deliveryApiContentChangeTriggers
      .filter(hasSelectedTrigger)
      .map((value: ReadonlyArray<ContentTriggerOperation>, key: WebhookTriggerType) => {
        return {
          type: key,
          operations: value,
        };
      })
      .toArray();
  const previewDeliveryApiContentChanges: Array<IContentTriggerServerModel> =
    webhookSetting.previewDeliveryApiContentChangeTriggers
      .filter(hasSelectedTrigger)
      .map((value: ReadonlyArray<ContentTriggerOperation>, key: WebhookTriggerType) => {
        return {
          type: key,
          operations: value,
        };
      })
      .toArray();
  const workflowStepChanges: Array<IWorkflowTriggerServerModel> =
    webhookSetting.workflowStepChangeTriggers
      .filter(hasSelectedTrigger)
      .map((value: ReadonlyArray<Uuid>, key: WebhookTriggerType) => {
        return {
          type: key,
          transitionsTo: value,
        };
      })
      .toArray();
  const managementApiContentChanges: Array<IContentTriggerServerModel> =
    webhookSetting.managementApiContentChangeTriggers
      .filter(hasSelectedTrigger)
      .map((value: ReadonlyArray<ContentTriggerOperation>, key: WebhookTriggerType) => {
        return {
          type: key,
          operations: value,
        };
      })
      .toArray();

  return {
    enabled: webhookSetting.enabled,
    healthStatus: webhookSetting.healthStatus,
    id: webhookSetting.id,
    name: webhookSetting.name,
    order: webhookSetting.order,
    secret: webhookSetting.secret,
    url: webhookSetting.url,
    triggers: {
      workflowStepChanges,
      deliveryApiContentChanges,
      managementApiContentChanges,
      previewDeliveryApiContentChanges,
    },
  };
}
