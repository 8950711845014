// biome-ignore lint/style/noNamespaceImport: We need to import all locales.
import * as locales from 'date-fns/locale';
import { Locale } from 'date-fns/locale';

const getLocaleForLanguageCode = (languageCode: string): Locale | null => {
  return Object.values(locales).find((locale) => locale.code === languageCode) ?? null;
};

const getMostPreferredLocale = (): Locale | null => {
  return navigator.languages.reduce((locale, language) => {
    if (locale) {
      return locale;
    }
    return getLocaleForLanguageCode(language);
  }, null);
};

const defaultLocale = locales.enUS;
export const currentLocale = getMostPreferredLocale() ?? defaultLocale;

export const defaultLocaleWithCorrectStartOfWeekDay: Locale = {
  ...defaultLocale,
  options: {
    ...defaultLocale.options,
    weekStartsOn: currentLocale.options?.weekStartsOn ?? defaultLocale.options?.weekStartsOn,
  },
};
