import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import {
  ContentType_Listing_ArchiveContentTypesFinished,
  ContentType_Listing_FilterSearchPhraseChanged,
} from '../../constants/contentTypesActionTypes.ts';

const initialFilterSearchPhrase = '';

export function filterSearchPhrase(state = initialFilterSearchPhrase, action: Action): string {
  switch (action.type) {
    case ContentType_Listing_ArchiveContentTypesFinished:
    case Shared_CurrentProjectId_Updated: {
      return initialFilterSearchPhrase;
    }

    case ContentType_Listing_FilterSearchPhraseChanged: {
      const { searchPhrase } = action.payload;
      return isEmptyOrWhitespace(searchPhrase) ? initialFilterSearchPhrase : searchPhrase;
    }

    default:
      return state;
  }
}
