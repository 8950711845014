import { TippyProps } from '@tippyjs/react';

export type IDropdownTippyOptions = Pick<
  TippyProps,
  'placement' | 'popperOptions' | 'appendTo' | 'offset'
>;

// Default options which are used in case no options are provided within tippyOptions prop
export const defaultDropdownTippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-end',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
    ],
  },
};
