import { useEffect, useState } from 'react';
import { IntercomUtils } from '../utils/intercomUtils.ts';

export const useIntercomBoot = (userId: Uuid, userHash: string): void => {
  const [isIntercomBooted, setIsIntercomBooted] = useState(false);

  useEffect(() => {
    if (isIntercomBooted) return;

    IntercomUtils.boot({
      app_id: self._envConfig.intercomAppId,
      user_id: userId,
      user_hash: userHash,
    });

    setIsIntercomBooted(true);
  }, [isIntercomBooted, userId, userHash]);
};
