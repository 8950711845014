import React from 'react';
import { ClipboardFormField } from '../../../../../../../_shared/uiComponents/Clipboard/ClipboardFormField.tsx';
import { PreviewLink as PreviewLinkType } from '../../../containers/selectors/selectPreviewLinks.ts';
import { codenameFormWidth } from './CodenameForm.tsx';

export enum FieldName {
  ItemLink = 'ItemLink',
  PreviewLink = 'PreviewLink',
}

type ShareItemFormProps = {
  readonly itemLink: string;
  readonly onCopyCompleted: () => void;
  readonly onCopyUsed: (fieldName: FieldName) => void;
  readonly previewLinks: ReadonlyArray<PreviewLinkType>;
};

export const ShareItemForm: React.FC<ShareItemFormProps> = ({
  itemLink,
  onCopyCompleted,
  onCopyUsed,
  previewLinks,
}) => {
  return (
    <div
      className="share-item-form"
      style={{
        width: codenameFormWidth,
      }}
    >
      <ClipboardFormField
        inputName={FieldName.ItemLink}
        inputText={itemLink}
        labelText="Share item link"
        onCopyCompleted={onCopyCompleted}
        onCopied={() => onCopyUsed(FieldName.ItemLink)}
      />
      {previewLinks
        .filter((link) => link.shouldShowPreviewLink && link.url)
        .map((link) => (
          <ClipboardFormField
            key={link.spaceId ?? 'no-space'}
            inputName={FieldName.PreviewLink}
            inputText={link.url ?? ''}
            labelText={`Share preview link${link.spaceName ? ` (${link.spaceName})` : ''}`}
            onCopied={() => onCopyUsed(FieldName.PreviewLink)}
            onCopyCompleted={onCopyCompleted}
          />
        ))}
    </div>
  );
};

ShareItemForm.displayName = 'ShareItemForm';
