import { useHistory, useLocation } from 'react-router';
import { restoreRevision } from '../../../../applications/itemEditor/features/Revisions/actions/thunkRevisionsActions.ts';
import { ContentItemRoute, ContentItemRouteParams } from '../../../constants/routePaths.ts';
import { matchPath } from '../../../utils/routing/routeTransitionUtils.ts';
import { useDispatch } from '../../useDispatch.ts';

export const useRestoreRevision = (): (() => Promise<void>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const match = matchPath<ContentItemRouteParams<string>>(pathname, ContentItemRoute);

  return match ? () => dispatch(restoreRevision(history, match)) : () => Promise.resolve();
};
