import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentType_LoadComponentUsage_Dismissed } from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import {
  Data_ContentTypeComponentUsage_Finished,
  Data_ContentTypeComponentUsage_Started,
} from '../../../constants/dataActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Data_ContentTypeComponentUsage_Started:
      return LoadingStatus.Loading;
    case Data_ContentTypeComponentUsage_Finished:
      return LoadingStatus.Loaded;
    case ContentType_LoadComponentUsage_Dismissed:
      return LoadingStatus.InitialEmpty;
    default:
      return state;
  }
};
