import { memoize } from '@kontent-ai/memoization';
import { ComponentProps, useMemo } from 'react';
import { useLocation } from 'react-router';
import { getCurrentProjectId } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { loadBreadcrumbs } from '../actions/thunkSharedActions.ts';
import { ApplicationBreadcrumbs as ApplicationBreadcrumbsComponent } from '../components/ApplicationBreadcrumbs.tsx';
import { NavigationBreadcrumb } from '../components/Breadcrumbs.tsx';
import { ProjectsRoute } from '../constants/routePaths.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { useThunkPromise } from '../hooks/useThunkPromise.ts';
import { Breadcrumb } from '../models/Breadcrumb.type.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { getBreadcrumbs } from '../selectors/breadcrumbs/getBreadcrumbs.ts';
import { getBreadcrumbsOrigin } from '../selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../selectors/getSelectedLanguageId.ts';
import { replaceLanguageMacroInBreadcrumbs } from '../utils/breadcrumbs/breadcrumbResolvers.ts';
import { canGoUpToMyProjects } from '../utils/breadcrumbs/breadcrumbsUtils.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';

type Props = Pick<
  ComponentProps<typeof ApplicationBreadcrumbsComponent>,
  'renderSavingStatusComponent'
>;

const getNavigateBackPath = (pathname: string, breadcrumbs: ReadonlyArray<Breadcrumb>): string => {
  const firstBreadcrumb = breadcrumbs[0];
  if (canGoUpToMyProjects(pathname) && firstBreadcrumb) {
    return breadcrumbs.length === 1 ? ProjectsRoute : firstBreadcrumb.path;
  }
  return '';
};

const decorateBreadcrumbs = memoize.maxOne(
  (
    currentProjectId: Uuid,
    breadcrumbs: ReadonlyArray<Breadcrumb>,
    selectedLanguageId: Uuid,
  ): ReadonlyArray<NavigationBreadcrumb> => {
    return replaceLanguageMacroInBreadcrumbs(breadcrumbs, selectedLanguageId).map(
      (breadcrumb: Breadcrumb) => ({
        label: breadcrumb.title,
        to: `${
          breadcrumb.useProjectIdPrefix && isUuid(currentProjectId) ? `/${currentProjectId}` : ''
        }${breadcrumb.path}`,
      }),
    );
  },
);

export const ApplicationBreadcrumbs = (props: Props) => {
  const location = useLocation();

  const breadcrumbs = useSelector(getBreadcrumbs);
  const breadcrumbsOrigin = useSelector(getBreadcrumbsOrigin);
  const isMultipleWorkflowsConfigurationAllowed = useSelector(
    isMultipleWorkflowsConfigurationVisible,
  );
  const listingContentItems = useSelector((state) => state.data.listingContentItems);
  const navigateBackPath = getNavigateBackPath(location.pathname, breadcrumbs);
  const projectId = useSelector(getCurrentProjectId);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrRouteMacro);

  const params = useMemo(
    () => ({
      location,
      breadcrumbsOrigin,
      isMultipleWorkflowsConfigurationAllowed,
      listingContentItems,
    }),
    [location, breadcrumbsOrigin, isMultipleWorkflowsConfigurationAllowed, listingContentItems],
  );
  useThunkPromise(loadBreadcrumbs, params);

  return breadcrumbs.length ? (
    <ApplicationBreadcrumbsComponent
      renderSavingStatusComponent={props.renderSavingStatusComponent}
      breadcrumbs={decorateBreadcrumbs(projectId, breadcrumbs, selectedLanguageId)}
      navigateBackPath={navigateBackPath}
    />
  ) : null;
};
