import { hiddenEnvironmentsOptionId } from '../selectors/getHiddenEnvironmentOption.ts';
import {
  AllEnvironmentsTagId,
  EnvironmentOption,
} from '../selectors/getSelectedEnvironmentOptions.ts';

export const getDisabledApiEnvironmentNames = (
  allEnvironmentOptions: ReadonlyArray<EnvironmentOption>,
  selectedEnvironments: ReadonlyArray<string>,
): ReadonlyArray<string> =>
  allEnvironmentOptions
    .filter(
      (environmentOption) =>
        environmentOption.id !== AllEnvironmentsTagId &&
        environmentOption.id !== hiddenEnvironmentsOptionId &&
        selectedEnvironments?.includes(environmentOption.id) &&
        !environmentOption.isApiEnabled,
    )
    .map((environmentOption) => environmentOption.label);
