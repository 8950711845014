import { Property } from 'csstype';
import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { borderWidthDefault } from '../../tokens/decision/border.ts';
import { colorBorderDefault, colorBorderLowEmphasis } from '../../tokens/decision/colors.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { Color } from '../../tokens/quarks/colors.ts';
import { GradientType } from '../../tokens/quarks/gradients.ts';
import { Opacity } from '../../tokens/quarks/opacity.ts';
import { BoxShadow } from '../../tokens/quarks/shadow.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { LetterSpacing, Typography } from '../../tokens/quarks/typography.ts';
import { ZIndex } from '../../tokens/quarks/zIndex.ts';
import { addBoxModelAttrs, px } from '../../tokens/utils/utils.ts';

export const BoxBorder = {
  None: 'none',
  Default: `${px(borderWidthDefault)} solid ${colorBorderDefault}`,
  LowEmphasis: `${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`,
} as const;

export type BoxBorder = (typeof BoxBorder)[keyof typeof BoxBorder];

type InsetProps = {
  readonly inset?: number | string;
  readonly insetX?: number | string;
  readonly insetY?: number | string;
  readonly top?: number | string;
  readonly right?: number | string;
  readonly bottom?: number | string;
  readonly left?: number | string;
};

export type PaddingProps = {
  readonly padding?: Spacing;
  readonly paddingX?: Spacing;
  readonly paddingY?: Spacing;
  readonly paddingTop?: Spacing;
  readonly paddingRight?: Spacing;
  readonly paddingBottom?: Spacing;
  readonly paddingLeft?: Spacing;
};

type MarginProps = {
  readonly margin?: Spacing | string;
  readonly marginX?: Spacing | string;
  readonly marginY?: Spacing | string;
  readonly marginTop?: Spacing | string;
  readonly marginRight?: Spacing | string;
  readonly marginBottom?: Spacing | string;
  readonly marginLeft?: Spacing | string;
};

type OverflowProps = {
  readonly overflow?: Property.Overflow;
  readonly overflowX?: Property.Overflow;
  readonly overflowY?: Property.Overflow;
  readonly overflowWrap?: Property.OverflowWrap;
};

type GapProps = {
  readonly columnGap?: number;
  readonly gap?: number;
  readonly rowGap?: number;
};

export type BaseBoxProps = PaddingProps &
  MarginProps &
  InsetProps &
  OverflowProps &
  GapProps & {
    readonly alignItems?: Property.AlignItems;
    readonly backgroundColor?: Color;
    readonly backgroundImage?: GradientType | string;
    readonly border?: BoxBorder;
    readonly borderColor?: Color;
    readonly borderRadius?: BorderRadius | 'inherit';
    readonly boxShadow?: BoxShadow;
    readonly className?: string;
    readonly color?: Color;
    readonly cursor?: Property.Cursor;
    readonly display?: Property.Display;
    readonly flexBasis?: Property.FlexBasis<number>;
    readonly flexDirection?: Property.FlexDirection;
    readonly flexGrow?: number;
    readonly flexShrink?: number;
    readonly flexWrap?: Property.FlexWrap;
    readonly height?: Property.Height<number | string>;
    readonly id?: string;
    readonly justifyContent?: Property.JustifyItems;
    readonly letterSpacing?: Property.LetterSpacing<LetterSpacing>;
    readonly maxHeight?: Property.MaxHeight<number | string>;
    readonly maxWidth?: Property.MaxWidth<number | string>;
    readonly minHeight?: Property.MinHeight<number | string>;
    readonly minWidth?: Property.MinWidth<number | string>;
    readonly opacity?: Opacity;
    readonly position?: Property.Position;
    readonly pointerEvents?: Property.PointerEvents;
    readonly style?: CSSProperties;
    readonly textAlign?: Property.TextAlign;
    readonly textOverflow?: Property.TextOverflow;
    readonly typography?: Typography;
    readonly visibility?: Property.Visibility;
    readonly whiteSpace?: Property.WhiteSpace;
    readonly width?: Property.Width<number | string>;
    readonly zIndex?: ZIndex;
  };

const getOverflowStyles = ({
  overflow,
  overflowX = overflow,
  overflowY = overflow,
  overflowWrap,
}: OverflowProps) => css`
  ${overflowX && `overflow-x: ${overflowX};`};
  ${overflowY && `overflow-y: ${overflowY};`};
  ${overflowWrap && `overflow-wrap: ${overflowWrap}`};
`;

const getInsetStyles = (insetProps: InsetProps) => {
  const { inset, insetX, insetY, top, right, bottom, left } = insetProps;
  return addBoxModelAttrs('inset', {
    short: inset,
    shortX: insetX,
    shortY: insetY,
    top,
    right,
    bottom,
    left,
  });
};

const getPaddingStyles = (paddingProps: PaddingProps) => {
  const { padding, paddingX, paddingY, paddingTop, paddingRight, paddingBottom, paddingLeft } =
    paddingProps;
  return addBoxModelAttrs('padding', {
    short: padding,
    shortX: paddingX,
    shortY: paddingY,
    top: paddingTop,
    right: paddingRight,
    bottom: paddingBottom,
    left: paddingLeft,
  });
};

const getMarginStyles = (marginProps: MarginProps) => {
  const { margin, marginX, marginY, marginTop, marginRight, marginBottom, marginLeft } =
    marginProps;

  return addBoxModelAttrs('margin', {
    short: margin,
    shortX: marginX,
    shortY: marginY,
    top: marginTop,
    right: marginRight,
    bottom: marginBottom,
    left: marginLeft,
  });
};

const getGapStyles = ({ gap, columnGap = gap, rowGap = gap }: GapProps) => ({
  columnGap,
  rowGap,
});

const blacklistedProps = [
  'color',
  'cursor',
  'display',
  'height',
  'opacity',
  'overflow',
  'visibility',
  'width',
];
const StyledBox = styled.div
  .withConfig({
    shouldForwardProp: (prop, isValidAttr) => !blacklistedProps.includes(prop) && isValidAttr(prop),
  })
  .attrs<BaseBoxProps>(({ width, height, minWidth, minHeight, maxWidth, maxHeight }) => ({
    style: {
      width,
      height,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
    },
  }))<BaseBoxProps>`
  ${({
    position,
    zIndex,

    flexGrow,
    flexShrink,
    flexBasis,

    display,
    justifyContent,
    alignItems,
    flexDirection,
    flexWrap,

    visibility,
    opacity,

    boxShadow,
    border,
    borderColor,
    borderRadius,

    backgroundColor,
    backgroundImage,
    cursor,
    typography,
    textAlign,
    textOverflow,
    letterSpacing,
    color,
    whiteSpace,
    pointerEvents,
  }) => ({
    position,
    zIndex,

    flexGrow,
    flexShrink,
    flexBasis,

    flexDirection,
    flexWrap,

    justifyContent,
    alignItems,

    display,
    visibility,
    opacity,

    boxShadow,
    border,
    borderColor,
    borderRadius,

    backgroundColor,
    backgroundImage: backgroundImage?.toString(),
    cursor,
    ...typography,
    textAlign,
    textOverflow,
    letterSpacing,
    color,
    whiteSpace,
    pointerEvents,
  })};
  ${getInsetStyles};
  ${getOverflowStyles};
  ${getMarginStyles};
  ${getPaddingStyles};
  ${getGapStyles};
`;

export const Box = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      children,
      component,
      ...otherProps
    }: React.PropsWithChildren<BaseBoxProps> & PolymorphicComponentProps<TElement>,
    forwardedRef: PolymorphicRef<TElement>,
  ) => (
    <StyledBox
      as={component ?? 'div'} // this must be here and not in the default props for styled components to infer resulting type properly
      ref={forwardedRef}
      {...otherProps}
    >
      {children}
    </StyledBox>
  ),
) as PolymorphicComponent<BaseBoxProps>;

Box.displayName = 'Box';
