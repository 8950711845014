import { Action } from '../../../../../../@types/Action.type.ts';
import {
  UserDetail_Closed,
  UserDetail_Initialized,
  UserDetail_UpdateCollectionGroups_Finished,
  User_RevokeInvite_Finished,
} from '../../../constants/usersActionTypes.ts';

export const isInitialized = (state = false, action: Action): boolean => {
  switch (action.type) {
    case UserDetail_Initialized:
      return true;
    case UserDetail_Closed:
    case UserDetail_UpdateCollectionGroups_Finished:
    case User_RevokeInvite_Finished:
      return false;
    default:
      return state;
  }
};
