import { StoryMenuItem, StoryMenuSection } from './StoryMenuItem.type.ts';

export const verticalMenuItemsWithSubmenuItems: ReadonlyArray<StoryMenuSection> = [
  {
    id: 'Comments&Suggestions',
    label: 'Comments and suggestions',
    type: 'section',
    items: [
      {
        id: 'hideComments',
        label: 'Hide comments',
        submenuItems: [
          {
            id: 'foo',
            label: 'bar',
          },
        ],
      },
      {
        id: 'showResolved',
        label: 'Show resolved',
      },
      {
        id: 'showDiscussion',
        label: 'Show discussion',
      },
    ],
  },
  {
    id: 'ContentItem',
    label: 'Content item',
    type: 'section',
    items: [
      {
        id: 'CopyFromLanguage',
        label: 'Copy from language',
      },
      {
        id: 'collapseComponents',
        label: 'Collapse components',
      },
      {
        id: 'compareVersions',
        label: 'Compare versions',
      },
      {
        id: 'share',
        label: 'Share',
      },
      {
        id: 'duplicate',
        label: 'Duplicate',
        submenuItems: [
          {
            id: 'includingContent',
            label: 'Including content',
          },
          {
            id: 'empty',
            label: 'Empty',
          },
        ],
      },
      {
        id: 'delete',
        label: 'Delete',
      },
    ],
  },
  {
    id: 'forDevz',
    label: 'For Developers',
    type: 'section',
    items: [
      {
        id: 'codename',
        label: 'Codename',
      },
    ],
  },
];

export const verticalMenuItems: ReadonlyArray<StoryMenuItem> = [
  {
    id: 'hideComments',
    label: 'Hide comments',
    items: [
      {
        id: 'foo',
        label: 'bar',
      },
    ],
  },
  {
    id: 'showResolved',
    label: 'Show resolved',
  },
  {
    id: 'showDiscussion',
    label: 'Show discussion',
  },
  {
    id: 'CopyFromLanguage',
    label: 'Copy from language',
  },
  {
    id: 'collapseComponents',
    label: 'Collapse components',
  },
  {
    id: 'compareVersions',
    label: 'Compare versions',
  },
  {
    id: 'share',
    label: 'Share',
  },
  {
    id: 'duplicate',
    label: 'Duplicate',
    items: [
      {
        id: 'includingContent',
        label: 'Including content',
      },
      {
        id: 'empty',
        label: 'Empty',
      },
    ],
  },
  {
    id: 'delete',
    label: 'Delete',
  },
  {
    id: 'codename',
    label: 'Codename',
  },
];

const sectionCount = 10;
const itemsPerSectionCount = 10;

export const virtualizedMenuItems: ReadonlyArray<StoryMenuSection> = Array.from(
  { length: sectionCount },
  (_sectionItem, sectionIndex) => {
    const sectionNumber = sectionIndex + 1;

    return {
      id: `section-${sectionNumber}`,
      label: `Section ${sectionNumber}`,
      type: 'section',
      items: Array.from({ length: itemsPerSectionCount }, (_item, itemIndex) => {
        const itemNumber = itemIndex + 1;

        return {
          id: `section-${sectionNumber}-item-${itemNumber}`,
          label: `Section ${sectionNumber} — Item ${itemNumber}`,
        };
      }),
    };
  },
);
