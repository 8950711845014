import React from 'react';
import { renderDatetimeString } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { joinWithOxfordComma } from '../../../../../../../_shared/utils/stringUtils.ts';
import { formatUserName } from '../../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import {
  IContentItemOverwritten,
  IContentItemOverwrittenDifference,
} from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ContentItemChangeReason } from '../../../../../models/contentItem/ContentItemChangeReason.type.ts';
import { getKeysOfDetectedDifferences } from '../../../../../utils/itemOverwriteUtils.ts';
import {
  ExtendedContentChange,
  IOverwriteNotificationBarStateProps,
} from './OverwriteNotificationBar.tsx';

const updateReasonNames: ReadonlyRecord<keyof IContentItemOverwrittenDifference, string> = {
  assignees: 'assignee',
  collection: 'collection',
  codename: 'codename',
  dueDate: 'due date',
  name: 'name',
  note: 'note',
  publishScheduleTime: 'publishing date',
  sitemap: 'sitemap',
  unpublishScheduleTime: 'unpublishing date',
  workflowStep: 'workflow step',
};

const hasUpdateReasonName = (key: string): key is keyof IContentItemOverwrittenDifference =>
  (Object.keys(updateReasonNames) as ReadonlyArray<string>).includes(key);

const getUpdateReasonsNames = (
  difference: IContentItemOverwrittenDifference,
): ReadonlyArray<string> =>
  getKeysOfDetectedDifferences(difference)
    .filter(hasUpdateReasonName)
    .map((transpiredReason) => updateReasonNames[transpiredReason]);

const getUpdateChangeReason = (changeContent: ExtendedContentChange): string => {
  const allReasons = getUpdateReasonsNames(changeContent.difference);

  return allReasons.length
    ? `just changed the ${joinWithOxfordComma(allReasons)} of this item`
    : 'just edited this item';
};

const changeReasons: Record<
  ContentItemChangeReason,
  (changeContent: ExtendedContentChange) => string
> = {
  [ContentItemChangeReason.Archive]: () => 'just deleted this item',
  [ContentItemChangeReason.DiscardVersion]: () => 'just discarded this version of the item',
  [ContentItemChangeReason.NewVersion]: () => 'just created a new version of this item',
  [ContentItemChangeReason.Publish]: () => 'just published this item',
  [ContentItemChangeReason.RestoreRevision]: () => 'just restored an older revision of this item',
  [ContentItemChangeReason.Unpublish]: () => 'just unpublished this item',
  [ContentItemChangeReason.Update]: getUpdateChangeReason,
};

const isKnownChangeReason = (
  key: IContentItemOverwritten['changeReason'],
): key is keyof typeof changeReasons =>
  (Object.keys(changeReasons) as ReadonlyArray<IContentItemOverwritten['changeReason']>).includes(
    key,
  );

const getReasonMessageText = (changeContent: ExtendedContentChange): string =>
  isKnownChangeReason(changeContent.changeReason)
    ? changeReasons[changeContent.changeReason](changeContent)
    : 'just edited this item';

const getChangeBy = (
  changeContent: ExtendedContentChange,
  changeByUser?: IProjectContributor,
): string => {
  if (changeContent.changeByManageApi) {
    return 'Someone';
  }

  if (changeContent.changeBySelf) {
    return 'You';
  }

  if (!changeByUser) {
    return 'Someone';
  }

  return formatUserName(changeByUser);
};

const getReasonMessageSuffixText = (changeContent: ExtendedContentChange): string => {
  if (changeContent.changeByManageApi) {
    return ' with the Management API';
  }

  if (changeContent.changeBySelf) {
    return ' in a different tab';
  }

  return '';
};

const getDifferenceValue = (changeContent: ExtendedContentChange): string => {
  const diffKeys = getKeysOfDetectedDifferences(changeContent.difference);
  if (changeContent.changeReason === ContentItemChangeReason.Update && diffKeys.length === 1) {
    const diffKey = diffKeys[0];

    switch (diffKey) {
      case 'dueDate':
      case 'publishScheduleTime':
      case 'unpublishScheduleTime': {
        const diffValue = changeContent.difference[diffKey];
        return renderDatetimeString(diffValue) ?? '';
      }
      case 'workflowStep': {
        const diffValue = changeContent.difference[diffKey];
        return diffValue?.workflowStatus.name ?? '';
      }
      default:
        return '';
    }
  }

  return '';
};

interface IOverwriteNotificationBarMessageProps extends IOverwriteNotificationBarStateProps {
  readonly showRefreshButtonPrefix: boolean;
  readonly overwrittenLanguageName?: string;
}

export const OverwriteNotificationBarMessage: React.FC<IOverwriteNotificationBarMessageProps> = ({
  changeByUser,
  contentChange,
  showRefreshButtonPrefix,
  overwrittenLanguageName,
}): JSX.Element => {
  const changedBy = getChangeBy(contentChange, changeByUser);
  const reasonMessage = getReasonMessageText(contentChange);
  const reasonMessageSuffix = getReasonMessageSuffixText(contentChange);
  const differenceValue = getDifferenceValue(contentChange);

  return (
    <>
      {contentChange.changeBySelf || contentChange.changeByManageApi ? (
        changedBy
      ) : (
        <strong>{changedBy}</strong>
      )}{' '}
      {reasonMessage}
      {overwrittenLanguageName && (
        <>
          {' in '}
          <strong>{overwrittenLanguageName}</strong>
        </>
      )}
      {reasonMessageSuffix}
      {differenceValue.length > 0 && (
        <>
          {' to '}
          <strong>{differenceValue}</strong>
        </>
      )}
      .
      {contentChange.changeReason === ContentItemChangeReason.Archive &&
        ' Copy your last changes to clipboard or go back to the inventory.'}
      {showRefreshButtonPrefix && ' Refresh to see the latest changes.'}
    </>
  );
};

OverwriteNotificationBarMessage.displayName = 'OverwriteNotificationBarMessage';
