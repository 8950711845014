import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { findElement } from '../../../../../richText/plugins/contentComponents/api/traverseComponentUtils.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import { ISelectContentGroupsByElementPath } from './selectContentGroupsByElementPath.ts';

export type ISelectContentGroupForElement = (
  reason: ContentGroupSelectionReason,
  elementId: Uuid,
  componentId: Uuid | undefined,
) => ThunkFunction;

interface IDeps {
  readonly selectContentGroupsByElementPath: ISelectContentGroupsByElementPath;
}

export const createSelectContentGroupForElementAction =
  (deps: IDeps): ISelectContentGroupForElement =>
  (reason, elementId, contentComponentId): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItem, editedContentItemVariantElements },
    } = getState();

    if (!editedContentItem) {
      return;
    }

    const findElementResult = findElement(
      editedContentItem,
      editedContentItemVariantElements,
      elementId,
      contentComponentId,
    );

    if (findElementResult.found) {
      dispatch(deps.selectContentGroupsByElementPath(reason, findElementResult.path));
    }
  };
