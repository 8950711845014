import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_Listing_Initialized,
  Collections_Save_Failed,
  Collections_Save_Finished,
  Collections_Save_Started,
} from '../../constants/actionTypes.ts';

const emptyState = false;

export const isBeingSaved = (state = emptyState, action: Action): boolean => {
  switch (action.type) {
    case Collections_Save_Failed:
    case Collections_Save_Finished:
      return false;

    case Collections_Save_Started:
      return true;

    case Collections_Listing_Initialized:
      return emptyState;

    default:
      return state;
  }
};
