import Immutable from 'immutable';
import { IContentItemVariantReference } from '../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { Languages } from '../../data/models/languages/Language.ts';
import {
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../constants/routePaths.ts';
import { buildPath } from './routing/routeTransitionUtils.ts';

export type ContentItemVariantWithLanguageName = IContentItemVariantReference & {
  readonly languageName: string;
  readonly linkPath: string;
};

export const getContentItemVariantsWithLanguageNames = (
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
  languages: Languages,
  currentProjectId: Uuid,
): ReadonlyArray<ContentItemVariantWithLanguageName> => {
  if (!currentProjectId) {
    return [];
  }

  return contentItemVariants
    .filter((variant) => !variant.isArchived)
    .map((variant) => {
      const linkPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
        projectId: currentProjectId,
        app: ContentItemsAppRouteSegment.Content,
        variantId: variant.id.variantId,
        spaceId: undefined,
        contentItemIds: [variant.id.itemId],
      });

      const language = languages.get(variant.id.variantId);
      return {
        ...variant,
        languageName: language?.name || '',
        linkPath,
      };
    })
    .toArray();
};
