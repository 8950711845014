import React from 'react';
import { useLocation } from 'react-router';
import {
  EnvironmentRouteParams,
  SubscriptionEnvironmentSettingsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { convertAppNameToId } from '../../utils/convertAppNameToId.ts';
import { environmentSettingsMenuStructure } from '../../utils/environmentSettingsMenuStructure.tsx';
import { getFilteredOutMenuStructure } from '../../utils/environmentSettingsMenuStructureUtils.tsx';
import {
  EnvironmentSettingsMenu as EnvironmentSettingsMenuComponent,
  IEnvironmentSettingsTabItemInfo,
  IFirstLevelEnvironmentSettingsTabItemInfo,
} from '../components/EnvironmentSettingsMenu.tsx';

const targetWithReplacedUrlIdentifiers = (
  projectId: string,
  routePath: string,
  subscriptionId: string | null,
) =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(routePath, {
        projectId,
        subscriptionId,
      })
    : buildPath<EnvironmentRouteParams>(routePath, { projectId });

const convertStaticMenuStructureToVerticalTabsMenuStructure = (
  state: IStore,
): ReadonlyArray<IFirstLevelEnvironmentSettingsTabItemInfo> => {
  const selectedSubscription = getSelectedSubscription(state);
  const subscriptionId = selectedSubscription ? selectedSubscription.subscriptionId : null;
  const currentProject = getCurrentProject(state);

  const settingsMenu = environmentSettingsMenuStructure(subscriptionId);
  const filteredMenu = getFilteredOutMenuStructure(state, settingsMenu);

  return filteredMenu.map(
    (item): IFirstLevelEnvironmentSettingsTabItemInfo => ({
      id: convertAppNameToId(item.text),
      isDisabled: item.isDisabled?.(state) ?? false,
      items: item.nestedItems?.map(
        (nestedItem): IEnvironmentSettingsTabItemInfo => ({
          id: convertAppNameToId(nestedItem.text),
          isDisabled: nestedItem.isDisabled?.(state) ?? false,
          label: nestedItem.text,
          renderAuxElements: nestedItem.renderAuxElements,
          url: targetWithReplacedUrlIdentifiers(
            currentProject.projectId,
            nestedItem.routePath,
            currentProject.subscriptionId,
          ),
          ...nestedItem.dataAttributes,
          ...getDataUiElementAttribute(DataUiElement.SettingGroupItem),
        }),
      ),
      label: item.text,
      renderAuxElements: item.renderAuxElements,
      renderCustomItem:
        !!item.customRender && (item.shouldCustomRender?.(state) ?? true)
          ? item.customRender
          : undefined,
      url: targetWithReplacedUrlIdentifiers(
        currentProject.projectId,
        item.routePath,
        currentProject.subscriptionId,
      ),
      ...item.dataAttributes,
      ...getDataUiElementAttribute(DataUiElement.SettingGroupItem),
    }),
  );
};

export const EnvironmentSettingsMenu: React.FC = () => {
  const location = useLocation();
  const settingsMenuStructure = useSelector(convertStaticMenuStructureToVerticalTabsMenuStructure);

  return (
    <EnvironmentSettingsMenuComponent pathname={location.pathname} items={settingsMenuStructure} />
  );
};

EnvironmentSettingsMenu.displayName = 'EnvironmentSettingsMenu';
