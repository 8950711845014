import React from 'react';
import { VariantCompletionStatus } from '../../../_shared/utils/contentItemVariantUtils.ts';
import { UnfinishedStatusIcon } from '../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/UnfinishedStatusIcon.tsx';

interface INameWithLanguageProps {
  readonly name: string;
  readonly language?: string;
  readonly variantCompletionStatus?: VariantCompletionStatus | null;
}

export const NameWithLanguage: React.FC<INameWithLanguageProps> = (props) => {
  const isVariantUnfinished = props.variantCompletionStatus === VariantCompletionStatus.unfinished;

  return (
    <div
      className="your-content__item-name-wrapper"
      title={props.language ? `${props.name} (${props.language})` : props.name}
    >
      {isVariantUnfinished && <UnfinishedStatusIcon />}
      <span className="your-content__item-name">{props.name}</span>
      {props.language && <span className="your-content__item-language">({props.language})</span>}
    </div>
  );
};

NameWithLanguage.displayName = 'NameWithLanguage';
