import { Action } from '../../../@types/Action.type.ts';
import {
  Shared_ProjectLocations_LoadingFinished,
  Shared_ProjectLocations_LoadingInit,
} from '../../constants/sharedActionTypes.ts';
import { IProjectLocation } from '../../models/ProjectLocation.ts';

const initialState: ReadonlyArray<IProjectLocation> = [];

export function availableProjectLocations(
  state = initialState,
  action: Action,
): ReadonlyArray<IProjectLocation> {
  switch (action.type) {
    case Shared_ProjectLocations_LoadingInit:
      return initialState;

    case Shared_ProjectLocations_LoadingFinished:
      return action.payload.projectLocations;

    default:
      return state;
  }
}
