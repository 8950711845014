import classNames from 'classnames';
import React from 'react';
import {
  DataAttributes,
  getDataAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { TableCellResizerClassName } from './tableColumnResizeHandlers.ts';

export const isPointerApiEnabled = 'PointerEvent' in window;

export interface ITableColumnResizeProps {
  readonly columnIndex: number;
}

export const TableColumnResizer: React.FC<ITableColumnResizeProps> = ({ columnIndex }) => {
  return (
    <div
      contentEditable={false}
      className={classNames(TableCellResizerClassName, {
        [`${TableCellResizerClassName}--is-enabled`]: isPointerApiEnabled,
      })}
      {...getDataAttribute(DataAttributes.RteColumnIndex, `${columnIndex}`)}
      // &zwnj; is required for caret position handlers to work
      // we need to be able to place caret on both sides of the resizer element
    >
      &zwnj;
    </div>
  );
};
TableColumnResizer.displayName = 'TableColumnResizer';
