import { combineReducers } from '@reduxjs/toolkit';
import { collectionId } from './form/collectionId.ts';
import { collectionSection } from './form/collectionSection.ts';
import { contentTypeId } from './form/contentTypeId.ts';
import { contentTypeSection } from './form/contentTypeSection.ts';
import { languageId } from './form/languageId.ts';
import { name } from './form/name.ts';
import { workflowId } from './form/workflowId.ts';

export const form = combineReducers({
  collectionId,
  collectionSection,
  contentTypeId,
  contentTypeSection,
  languageId,
  name,
  workflowId,
});
