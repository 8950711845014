import { noOperation } from '@kontent-ai/utils';
import React, { useCallback, useMemo, useState } from 'react';

type Callback = () => Promise<void> | void;

export interface ISubscriptionFormContext {
  readonly submitForm: Callback | null;
  readonly setSubmitFormCallback: (callback: Callback) => void;
}

export const SubscriptionFormContext = React.createContext<ISubscriptionFormContext>({
  setSubmitFormCallback: noOperation,
  submitForm: null,
});

export const SubscriptionFormContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const [submitForm, setSubmitForm] = useState<Callback | null>(null);

  const setSubmitFormCallback = useCallback(
    (submitFormCallback: Callback) => setSubmitForm(() => submitFormCallback),
    [],
  );

  const value = useMemo(
    (): ISubscriptionFormContext => ({
      submitForm,
      setSubmitFormCallback,
    }),
    [submitForm, setSubmitFormCallback],
  );

  return (
    <SubscriptionFormContext.Provider value={value}>{children}</SubscriptionFormContext.Provider>
  );
};

SubscriptionFormContextProvider.displayName = 'SubscriptionFormContextProvider';
