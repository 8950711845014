import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';

interface IPublishOrUnpublishInProgressProps {
  readonly actionType: ScheduledActionType;
  readonly children: ReactNode;
  readonly showTooltip?: boolean;
}

export enum ScheduledActionType {
  Publish = 'Publish',
  Unpublish = 'Unpublish',
}

const getTooltipText = (actionType: ScheduledActionType): string => {
  switch (actionType) {
    case ScheduledActionType.Publish:
      return 'This item’s being published now. It’ll be done in a few minutes.';
    case ScheduledActionType.Unpublish:
      return 'This item’s being unpublished and archived now. It’ll be done in a few minutes.';
    default:
      return '';
  }
};

const ScheduledActionInProgressIcon: React.FC = () => (
  <Box display="flex" flexDirection="column" justifyContent="center" marginRight={Spacing.S}>
    <Icon iconName={IconName.Spinner} className="spin-500" />
  </Box>
);

export const ScheduledActionInProgress: React.FC<IPublishOrUnpublishInProgressProps> = ({
  actionType,
  children,
  showTooltip,
}) => (
  <Tooltip tooltipText={showTooltip ? getTooltipText(actionType) : undefined} placement="top">
    <Box display="inline-flex">
      <ScheduledActionInProgressIcon />
      {children}
    </Box>
  </Tooltip>
);

ScheduledActionInProgress.displayName = 'ScheduledActionInProgress';
