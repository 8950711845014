import { Action } from '../../../../../../@types/Action.type.ts';
import { ItemColumnCode } from '../../../../../../_shared/constants/itemColumnCode.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { OrderBy, OrderByDirection } from '../../../../../../_shared/models/OrderBy.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { ContentListing_OrderBy_Changed } from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

export const initialContentListingOrderBy: OrderBy<ItemColumnCode> = {
  columnCode: ItemColumnCode.LastModifiedAt,
  direction: OrderByDirection.Descending,
};

export const notSpecifiedContentListingOrderBy: OrderBy<ItemColumnCode> = {
  columnCode: ItemColumnCode.None,
  direction: OrderByDirection.None,
};

export function orderBy(
  state: OrderBy<ItemColumnCode> = initialContentListingOrderBy,
  action: Action,
): OrderBy<ItemColumnCode> {
  switch (action.type) {
    case ContentListing_OrderBy_Changed:
      return action.payload.orderBy;

    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case Shared_CurrentProjectId_Updated:
      return initialContentListingOrderBy;

    default:
      return state;
  }
}
