import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';

type ScheduledUnpublishIconProps = {
  readonly tooltipText: string;
};

export const ScheduledUnpublishIcon: React.FC<
  React.PropsWithChildren<ScheduledUnpublishIconProps>
> = ({ tooltipText }) => {
  return (
    <Tooltip tooltipText={tooltipText} placement="right">
      <Icons.BoxClock screenReaderText={tooltipText} />
    </Tooltip>
  );
};

ScheduledUnpublishIcon.displayName = 'ScheduledUnpublishIcon';
