import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  EntityWebhooks_DisableWebhook_Finished,
  EntityWebhooks_EnableWebhook_Finished,
  EntityWebhooks_EntityWebhook_InsertCompleted,
  EntityWebhooks_EntityWebhook_UpdateCompleted,
  EntityWebhooks_InitCreate_Completed,
  EntityWebhooks_InitEdit_Completed,
  EntityWebhooks_ResetWebhook_Finished,
} from '../../../../applications/entityWebhooks/constants/entityWebhooksActionTypes.ts';
import {
  Data_EntityWebhooks_Started,
  Data_EntityWebhooks_Success,
} from '../../../constants/dataActionTypes.ts';
import { IEntityWebhookSetting } from '../../../models/webhooks/EntityWebhookSetting.ts';

type EntityWebhooksType = ReadonlyMap<Uuid, IEntityWebhookSetting>;
const initialState: EntityWebhooksType = new Map();

export function byId(state: EntityWebhooksType = initialState, action: Action): EntityWebhooksType {
  switch (action.type) {
    case Data_EntityWebhooks_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_EntityWebhooks_Success:
      return new Map(
        action.payload.data.map((webhook): [Uuid, IEntityWebhookSetting] => [webhook.id, webhook]),
      );

    case EntityWebhooks_InitCreate_Completed:
      return state;

    case EntityWebhooks_EntityWebhook_InsertCompleted:
    case EntityWebhooks_InitEdit_Completed:
    case EntityWebhooks_EntityWebhook_UpdateCompleted:
    case EntityWebhooks_EnableWebhook_Finished:
    case EntityWebhooks_DisableWebhook_Finished:
    case EntityWebhooks_ResetWebhook_Finished:
      return Collection.add(state, [action.payload.webhook.id, action.payload.webhook]);

    default:
      return state;
  }
}
