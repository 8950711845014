import { ContentItemId } from '../../_shared/models/ContentItemId.ts';
import { DateRange } from '../../_shared/models/DateRange.type.ts';
import { SearchScope } from '../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { PublishingState } from '../../data/constants/PublishingState.ts';
import { TaxonomiesByGroupIdServerModel } from './TaxonomyFilterServerModel.type.ts';

export enum DateTimeField {
  AssignmentDue = 'AssignmentDue',
  LastModifiedAt = 'LastModifiedAt',
  LastPublishedAt = 'LastPublishedAt',
  ScheduledPublishAt = 'ScheduledPublishAt ',
}

export type ItemCompletionServerStatus = 'notTranslated' | 'unfinished' | 'completed' | 'allDone';

export type OrderByServerModel = {
  readonly columnName: string;
  readonly direction: 'asc' | 'desc';
};

export interface ISearchParametersServerModel {
  readonly phrase: string;
  readonly scope: readonly SearchScope[];
}

export type ContentItemFilterServerModel = {
  assignees?: ReadonlyArray<string>;
  collectionIds?: UuidArray;
  due?: DateRange;
  itemCompletionStatuses?: ReadonlyArray<ItemCompletionServerStatus>;
  lastModifiedAt?: DateRange;
  lastPublishedAt?: DateRange;
  publishScheduleTime?: DateRange;
  publishingStates?: ReadonlyArray<PublishingState>;
  searchParameters?: ISearchParametersServerModel;
  sitemapLocations?: UuidArray;
  spaceIds?: UuidArray;
  taxonomiesByGroupId?: TaxonomiesByGroupIdServerModel;
  typeIds?: UuidArray;
  workflows?: ReadonlyRecord<Uuid, UuidArray>;
};

export type DateRangesServerModel = {
  readonly fields: DateTimeField[];
  readonly from: DateTimeStamp;
  readonly to: DateTimeStamp;
};

export type ContentItemFilterWithContinuationServerModel = ContentItemFilterServerModel & {
  excludeItemsLackingTheVariant?: boolean;
  orderBy?: OrderByServerModel;
  dateRanges?: DateRangesServerModel[];

  continuationToken?: ContinuationToken;
  maxItemsCount?: number;

  includePublishedVersions?: boolean;
  useBackUpSearchMethod?: boolean;
};

export interface IListItemsByIdsQueryServerModel {
  readonly itemIds: UuidArray;
}

export interface IListItemsWithVariantsByIdsQueryServerModel {
  readonly contentItemIdentifiers: ReadonlyArray<ContentItemId>;
}

export interface IListItemsByIdsInActiveLanguagesQueryServerModel {
  readonly itemIds: UuidArray;
  readonly activeLanguageIds: UuidArray;
}

export type VariantUsageQueryServerModel = ContentItemFilterServerModel;

export type GetVariantWithElementsRequestModel = {
  readonly elementIds: UuidArray;
};
