import { Action } from '../../../../../@types/Action.type.ts';
import {
  Taxonomy_GroupCreate_Initialized,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Terms_TermEditingAbandoned,
  Taxonomy_Terms_TermEditingInitiated,
  Taxonomy_Terms_TermNameChanged,
  Taxonomy_Terms_TermRemoved,
} from '../../constants/taxonomyActionTypes.ts';

export const editedTaxonomyTermId = (state: Uuid = '', action: Action): Uuid => {
  switch (action.type) {
    case Taxonomy_Terms_TermEditingInitiated:
      return action.payload.termId;

    case Taxonomy_GroupEdit_Initialized:
    case Taxonomy_GroupCreate_Initialized:
    case Taxonomy_Terms_TermEditingAbandoned:
    case Taxonomy_Terms_TermRemoved:
    case Taxonomy_Terms_TermNameChanged:
      return '';

    default:
      return state;
  }
};
