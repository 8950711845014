import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import { SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { ApiKeyRegenerationButton } from '../../../../_shared/components/ApiKeys/ApiKeyRegenerationButton.tsx';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ExpirationDateStatus } from '../../../projectSettings/apiKeys/components/ApiKeyDetail/ExpirationDateStatus.tsx';
import { ApiKey } from '../../../projectSettings/apiKeys/models/ApiKey.ts';
import {
  ApiKeyLoadingFailed,
  ApiKeyRegenerationFailed,
} from '../constants/errorMessageTemplates.ts';
import { KeyRegenerationStatus } from '../models/KeyRegenerationStatus.ts';

type Props = {
  readonly apiKey: ApiKey;
  readonly isLoadingFailed: boolean;
  readonly regenerationStatus: KeyRegenerationStatus;
  readonly onRegenerate: () => void;
};

const getLoadingMessage = (regenerationStatus: KeyRegenerationStatus): string | undefined => {
  switch (regenerationStatus) {
    case KeyRegenerationStatus.Started:
      return 'Generating API key…';
    case KeyRegenerationStatus.Finished:
      return 'New API key generated!';
    default:
      return undefined;
  }
};

const getErrorMessage = (
  isLoadingFailed: boolean,
  regenerationStatus: KeyRegenerationStatus,
): string | undefined => {
  if (isLoadingFailed) {
    return ApiKeyLoadingFailed;
  }

  if (regenerationStatus === KeyRegenerationStatus.Failed) {
    return ApiKeyRegenerationFailed;
  }

  return undefined;
};

export const SubscriptionApiKeyInput: React.FC<Props> = ({
  apiKey,
  isLoadingFailed,
  regenerationStatus,
  onRegenerate,
}) => {
  const loadingMessage = getLoadingMessage(regenerationStatus);
  const errorMessage = getErrorMessage(isLoadingFailed, regenerationStatus);

  const regenerationButton = !isLoadingFailed && (
    <ApiKeyRegenerationButton
      onConfirm={onRegenerate}
      onRegenerate={noOperation}
      requireConfirmation
    />
  );

  return (
    <Stack spacing={Spacing.XS}>
      <Clipboard
        auxiliaryElement={regenerationButton}
        hintText="This key is only for you. Other users have their own key."
        inputState={errorMessage ? InputState.Alert : InputState.ReadOnly}
        label="Your personal API key"
        onInputChange={noOperation}
        overlayMessage={loadingMessage}
        type={InputType.Password}
        value={apiKey.apiKey}
        renderClipboardButton={(props) => (
          <Clipboard.Button
            {...props}
            {...getDataUiActionAttribute(DataUiAction.CopyToClipboard)}
          />
        )}
        {...getDataUiInputAttribute(DataUiInput.ApiKey)}
      />
      {apiKey.expiresAt && !errorMessage && !loadingMessage && (
        <ExpirationDateStatus expirationDate={apiKey.expiresAt} />
      )}
      {errorMessage && !loadingMessage && <SimpleStatusError label={errorMessage} />}
    </Stack>
  );
};

SubscriptionApiKeyInput.displayName = 'SubscriptionApiKeyInput';
