import classNames from 'classnames';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { BarItemDragAction } from '../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';

interface ILanguageBarOwnProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
}

export interface ILanguageBarStateProps {
  readonly fallbackLanguageName: string;
  readonly isEditable: boolean;
  readonly language: ILanguage;
}

export interface ILanguageBarDispatchProps {
  readonly onShowEditor: () => void;
}

type ILanguageEditableBarProps = ILanguageBarOwnProps &
  ILanguageBarStateProps &
  ILanguageBarDispatchProps;

export const LanguageBar: React.FC<ILanguageEditableBarProps> = ({
  connectDragSource,
  fallbackLanguageName,
  isDragging,
  isEditable,
  language,
  onShowEditor,
}) => (
  <div className="bar-item__wrapper">
    <div
      className={classNames('bar-item__pane', {
        'bar-item__pane--is-dragging': isDragging,
      })}
    >
      <div
        className={classNames('bar-item__bar', {
          'bar-item__bar--is-clickable': isEditable && !isDragging,
        })}
      >
        <BarItemActions>
          <BarItemDragAction
            connectDragSource={connectDragSource}
            disabled={!isEditable || !isLanguageActive(language)}
          />
        </BarItemActions>
        <div className="bar-item__title-pane" onClick={isEditable ? onShowEditor : undefined}>
          <div className="bar-item__title">
            {language.name}
            {!isLanguageActive(language) && (
              <div className="language-settings__title-tag">
                <Icon iconName={IconName.Drawers} />
                {' Inactive'}
              </div>
            )}
          </div>
          <div className="bar-item__title">
            {isLanguageActive(language) && (
              <div>
                <span className="language-settings__subtitle">{'Fallback: '}</span>
                {fallbackLanguageName}
              </div>
            )}
          </div>
        </div>
        <BarItemActions>
          <BarItemEditAction onClick={onShowEditor} disabled={!isEditable} />
        </BarItemActions>
      </div>
    </div>
  </div>
);

LanguageBar.displayName = 'LanguageEditableBar';
