import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { LoadListingContentItemsByIdsAction } from '../../../../../../data/actions/thunks/listingContentItems/loadListingContentItemsByIds.ts';
import { IContentTypeRepository } from '../../../../../../repositories/interfaces/IContentTypeRepository.type.ts';
import {
  IListingContentItemServerModel,
  IListingItemDataServerModel,
} from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { ContentTypeServerModel } from '../../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { IItemExtendedData } from '../../../../types/CustomElementApi.ts';

interface ICreateGetItemDetailsDeps {
  readonly contentTypeRepository: Pick<IContentTypeRepository, 'getContentTypes'>;
  readonly loadListingContentItemsByIds: LoadListingContentItemsByIdsAction;
}

const getItemDetails = (
  types: ContentTypeServerModel[],
  item: IListingItemDataServerModel,
): IItemExtendedData => {
  const type = types.find((t: ContentTypeServerModel) => t._id === item.type._id);
  if (!type) {
    throw InvariantException(`No type was found for the item with the ID ${item.id}.`);
  }
  return {
    id: item.id,
    codename: item.codeName,
    name: item.name,
    collection: { id: item.collectionId },
    type: {
      id: type._id,
      codename: type.codeName || '',
    },
  };
};

export const createGetItemDetails =
  (deps: ICreateGetItemDetailsDeps) =>
  (
    itemIds: UuidArray,
    languageId: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise<Array<IItemExtendedData>> =>
  async (dispatch) => {
    const types = await deps.contentTypeRepository.getContentTypes({}, abortSignal);
    const data = await dispatch(
      deps.loadListingContentItemsByIds(languageId, itemIds, abortSignal),
    );

    return data
      .filter((item: IListingContentItemServerModel) => !item.item.archived)
      .map((item: IListingContentItemServerModel) => getItemDetails(types.data, item.item));
  };
