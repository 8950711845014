import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { forwardRef, useCallback } from 'react';
import { NumberValue } from '../../../../../_shared/models/NumberValue.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { INumberTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import { hasDefaultValue } from '../utils/defaultValueUtils.ts';
import { DeferredAutoFocus } from './DeferredAutoFocus.tsx';
import { DefaultValueStatus } from './elements/subComponents/limitInfoMessages/DefaultValueStatus.tsx';

export interface INumberInputOwnProps {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly isValid: boolean;
  readonly onChange: (value: NumberValue) => void;
  readonly placeholder?: string;
  readonly typeElement: INumberTypeElement;
  readonly value: string;
  readonly defaultValue: string;
  readonly ariaLabel?: string;
}

export const NumberInput = forwardRef<HTMLInputElement, INumberInputOwnProps>(
  (
    {
      autoFocus,
      className,
      defaultValue,
      disabled,
      isValid,
      onChange,
      placeholder,
      value,
      ariaLabel,
    },
    ref,
  ) => {
    const change = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange({
          isValid: event.target.validity.valid,
          value: event.target.value,
        });
      },
      [onChange],
    );

    const { refObject, refToForward } = useAttachRef(ref);

    const focus = useCallback(() => refObject.current?.focus(), [refObject]);

    if (disabled) {
      return (
        <div
          className={classNames('number__input', 'number__input--is-disabled', {
            'number__input--invalid': !isValid,
            'number__input--is-empty': !value,
          })}
        >
          {value || placeholder}
        </div>
      );
    }

    const isCurrentValueDefault = !!defaultValue && value === defaultValue;

    // Input with type="number" processes value of both an empty string (which we consider to be valid) and invalid input as "",
    // so onChange event is not triggered when there is an invalid value written into an empty input.
    // Therefore, there is onInput used instead of onChange.
    return (
      <>
        <input
          autoComplete="off"
          ref={refToForward}
          defaultValue={value || ''}
          className={classNames('number__input', className, {
            'number__input--invalid': !isValid,
          })}
          type="number"
          step="any"
          onInput={change}
          placeholder={placeholder}
          aria-label={ariaLabel}
          {...getDataUiInputAttribute(DataUiInput.Number)}
        />
        <DeferredAutoFocus autoFocus={autoFocus} onAutoFocus={focus} />
        <DefaultValueStatus
          isStatusRendered={hasDefaultValue(defaultValue) && !disabled}
          isValueDefault={isCurrentValueDefault}
        />
      </>
    );
  },
);

NumberInput.displayName = 'NumberInput';
