import { colorSuccessIcon } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { HorizontalProgressBar } from '../../shared/components/HorizontalProgressBar.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';

interface IUnchangedPublishedItemsWidgetRowProps {
  readonly id: ContentItemId;
  readonly itemName: string;
  readonly longestItemStaleness: number;
  readonly onClick?: () => void;
  readonly stalenessDaysCount: number;
  readonly useRowLinks: boolean;
}

export const UnchangedPublishedItemsWidgetRow: React.FC<IUnchangedPublishedItemsWidgetRowProps> = ({
  id,
  itemName,
  longestItemStaleness,
  onClick,
  stalenessDaysCount,
  useRowLinks,
}) => {
  const pathToItem = useRowLinks
    ? getContentItemPath(window.location.pathname, id.itemId, id.variantId)
    : null;

  const commonCellProps = {
    linkPath: pathToItem,
    onClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <StyledDataTableRow key={id.itemId} dataUiObjectName={id.itemId} id={id.itemId}>
      <StyledDataTableCell {...commonCellProps}>{itemName}</StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <HorizontalProgressBar
          $color={colorSuccessIcon}
          total={longestItemStaleness}
          value={stalenessDaysCount}
          itemTypeName="day"
        />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

UnchangedPublishedItemsWidgetRow.displayName = 'UnchangedPublishedItemsWidgetRow';
