import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { useCallback } from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type CommentButtonDispatchProps = {
  readonly onClick: () => void;
};

export type CommentButtonDataProps = {
  readonly hasComment: boolean;
  readonly isFocused: boolean;
};

export type CommentButtonProps = CommentButtonDispatchProps & CommentButtonDataProps;

export const CommentButton: React.FC<CommentButtonProps> = ({ hasComment, isFocused, onClick }) => {
  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  return (
    <Tooltip tooltipText={hasComment ? 'Show comment' : 'Add comment'} placement="bottom">
      <SquareButton
        className="comment-button"
        onClick={onButtonClick}
        iconName={hasComment ? IconName.BubbleWithLines : IconName.Bubble}
        isActive={isFocused}
        style={SquareButtonStyle.Quinary}
        size={SquareButtonSize.Quinary}
        ariaLabel="Comment"
        {...getDataUiElementAttribute(DataUiElement.CommentButton)}
      />
    </Tooltip>
  );
};

CommentButton.displayName = 'CommentButton';
