import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IWorkflowRepository } from '../../../../repositories/interfaces/IWorkflowRepository.type.ts';
import { WorkflowsUsageServerModel } from '../../../../repositories/serverModels/WorkflowServerModel.type.ts';
import {
  Data_WorkflowsUsage_Failed,
  Data_WorkflowsUsage_Started,
  Data_WorkflowsUsage_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly workflowRepository: Pick<IWorkflowRepository, 'getWorkflowsUsage'>;
}

const start = () => ({ type: Data_WorkflowsUsage_Started }) as const;
const success = (data: WorkflowsUsageServerModel) =>
  ({
    type: Data_WorkflowsUsage_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_WorkflowsUsage_Failed }) as const;

export type LoadWorkflowsUsageActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadWorkflowsUsageAction =
  ({ workflowRepository }: IDeps) =>
  (workflowIds: ReadonlyArray<Uuid>, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const workflowsUsageServerModel = await workflowRepository.getWorkflowsUsage(
        workflowIds,
        abortSignal,
      );
      dispatch(success(workflowsUsageServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
