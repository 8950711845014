import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import React from 'react';

type Props = {
  readonly isVisible: boolean;
  readonly onSortReset: () => void;
  readonly orderByColumnIsCustom: boolean;
};

export const ContentItemsSearchSort: React.FC<Props> = (props) => {
  if (!props.isVisible) {
    return null;
  }

  if (props.orderByColumnIsCustom) {
    return (
      <QuinaryButton onClick={props.onSortReset} tooltipText="">
        <QuinaryButton.Icon icon={Icons.RotateRight} />
        Reset to sorting by relevance
      </QuinaryButton>
    );
  }

  return (
    <Label size={LabelSize.L} color={BaseColor.Gray60}>
      Sorted by relevance
    </Label>
  );
};

ContentItemsSearchSort.displayName = 'ContentItemsSearchSort';
