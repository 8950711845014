import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly setRelationsRoot: (params: { rootId: Uuid | null }) => ThunkPromise;
}

export const resetRelationsRootActionCreator =
  (deps: IDeps) => (): ThunkPromise => async (dispatch: Dispatch, getState: GetState) => {
    const {
      relationsApp: { defaultRootId },
    } = getState();

    if (!defaultRootId) {
      throw new Error('Project has no default root.');
    }

    await dispatch(
      deps.setRelationsRoot({
        rootId: defaultRootId,
      }),
    );
  };
