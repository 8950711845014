import { combineReducers } from '@reduxjs/toolkit';
import { errorMessage } from './reducers/errorMessage.ts';
import { projectIsBeingCloned } from './reducers/projectIsBeingCloned.ts';
import { sourceProjectId } from './reducers/sourceProjectId.ts';

export const cloneProjectInfo = combineReducers({
  projectIsBeingCloned,
  errorMessage,
  sourceProjectId,
});
