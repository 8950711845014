import { Dispatch, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../models/events/ContentItemEditingEventData.type.ts';
import { OpenWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import { IOpenChangeWorkflowStepModal } from './openChangeWorkflowStepModal.ts';

interface IDeps {
  readonly openChangeWorkflowStepModal: IOpenChangeWorkflowStepModal;
}

export const createOpenRestoreFromArchivedStepModalAction =
  ({ openChangeWorkflowStepModal }: IDeps) =>
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch: Dispatch) => {
    dispatch(
      openChangeWorkflowStepModal(
        OpenWorkflowStepModalAction.RestoreFromArchivedStep,
        actionOrigin,
      ),
    );

    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.ChangeWorkflowStepSelected,
        origin: actionOrigin,
      }),
    );
  };
