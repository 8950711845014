import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getUserInviteLink } from '../../selectors/getUserInviteLink.ts';

export const UserListingUserIsNotInvitedYetEmptyState: React.FC = () => {
  const nameOrEmail = useSelector((state) => state.usersApp.filter.byName);
  const inviteLink = useSelector((state) => getUserInviteLink(state, nameOrEmail));

  return (
    <EmptyState>
      <EmptyState.Title>We couldn’t find any match.</EmptyState.Title>
      <EmptyState.Content>
        Try using a different search phrase. If you want to start working with “{nameOrEmail}”,
        invite them to your project.
      </EmptyState.Content>
      {inviteLink && (
        <EmptyState.Footer>
          <RouterLinkButton
            buttonStyle="primary"
            to={inviteLink}
            {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
          >
            Invite “{nameOrEmail}”
          </RouterLinkButton>
        </EmptyState.Footer>
      )}
    </EmptyState>
  );
};

UserListingUserIsNotInvitedYetEmptyState.displayName = 'UserListingUserIsNotInvitedYetEmptyState';
