import { Icon } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { TooltipPropsExtension } from '@kontent-ai/component-library/component-utils';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  BaseColor,
  BorderRadius,
  Color,
  GradientType,
  IconSize,
  Size,
  Spacing,
  Typography,
  px,
} from '@kontent-ai/component-library/tokens';
import { getReadableTextColor } from '@kontent-ai/component-library/utils';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { IgnoreByGrammarly } from '../../../../app/_shared/components/IgnoreByGrammarly.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../app/_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IWorkflowStepConfig extends TooltipPropsExtension {
  readonly background: Color | GradientType;
  readonly icon?: Icon | null;
  readonly name: string;
}

export interface IWorkflowStepProps extends IWorkflowStepConfig {
  readonly isOverflowing?: boolean;
}

export const workflowStepBorderRadius = BorderRadius.Pill;
export const workflowStepHeight = Size.XS;

const Container = styled.div<{
  readonly background: IWorkflowStepConfig['background'];
  readonly $color: BaseColor;
}>`
  ${Typography.TitleMedium};

  height: ${px(workflowStepHeight)};
  padding: 0 ${px(Spacing.M)};
  border-radius: ${px(workflowStepBorderRadius)};

  ${({ background, $color }) => css`
    background: ${background.toString()};
    color: ${$color};
  `};
`;

const StyledRow = styled.div`
  height: 100%;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WorkflowStep = forwardRef<HTMLDivElement, IWorkflowStepProps>(
  (
    {
      background,
      icon,
      isOverflowing,
      name,
      tooltipText,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'right',
      tooltipShortcuts,
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});

    const IconComponent = icon;

    const isNameVisible = !IconComponent || !isOverflowing;
    const isTooltipVisible = isFocusVisible || isHovered;

    return (
      <Tooltip
        tooltipText={isTooltipVisible ? tooltipText : undefined} // make the tooltip disappear immediately
        placement={tooltipPlacement}
        maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        shortcuts={tooltipShortcuts}
        visible={isTooltipVisible}
      >
        <Container
          background={background}
          $color={getReadableTextColor(background)}
          ref={forwardedRef}
          {...mergeProps(hoverProps, focusProps)}
          {...getDataUiElementAttribute(DataUiElement.WorkflowStepContainer)}
        >
          <Row alignY="center" component={StyledRow} noWrap spacing={Spacing.XS}>
            {IconComponent && (
              <Column width="content">
                <IconComponent size={IconSize.S} />
              </Column>
            )}
            {isNameVisible ? (
              <Column width="content">
                <Name>
                  <IgnoreByGrammarly text={name} />
                </Name>
              </Column>
            ) : (
              <SrOnly>{name}</SrOnly>
            )}
          </Row>
        </Container>
      </Tooltip>
    );
  },
);

WorkflowStep.displayName = 'WorkflowStep';
