import { IUpdateMessageData } from '@kontent-ai/smart-link/types/lib/IFrameCommunicatorTypes';
import { InsertPosition } from '../../../_shared/models/SmartLinkCommand.ts';
import {
  AddButtonElementType,
  AddButtonPermission,
  AddButtonPermissionOption,
} from './IAddButtonPermissionsServerModels.ts';

export type EditButtonSmartLinkSdkClientMessage =
  | IEditButtonClickedElementSmartLinkSdkClientMessage
  | IEditButtonClickedContentItemSmartLinkSdkClientMessage
  | IEditButtonClickedContentComponentSdkClientMessage;

export type SmartLinkSdkClientMessage =
  | IInitializedSmartLinkSdkClientMessage
  | EditButtonSmartLinkSdkClientMessage
  | IAddButtonInitialSmartLinkSdkClientMessage
  | IAddButtonActionSmartLinkSdkClientMessage
  | IPreviewIFrameCurrentUrlResponseSmartLinkSdkClientMessage;

export type SmartLinkSdkHostMessage =
  | IStatusSmartLinkSdkHostMessage
  | IAddButtonInitialSmartLinkSdkHostMessage
  | IInitializedResponseSmartLinkSdkHostMessage
  | IPreviewIFrameCurrentUrlRequestSmartLinkSdkHostMessage
  | IReloadPreviewSmartLinkSdkHostMessage
  | IUpdatePreviewSmartLinkSdkHostMessage;

export enum SmartLinkSdkClientMessageType {
  AddButtonAction = 'kontent-smart-link:add:action',
  AddButtonInitial = 'kontent-smart-link:add:initial',
  EditContentComponentClicked = 'kontent-smart-link:content-component:clicked',
  EditContentItemClicked = 'kontent-smart-link:content-item:clicked',
  EditElementClicked = 'kontent-smart-link:element:clicked',
  Initialized = 'kontent-smart-link:initialized',
  PreviewIFrameCurrentUrlResponse = 'kontent-smart-link:preview:current-url:response',
}

export enum SmartLinkSdkHostMessageType {
  AddButtonInitialResponse = 'kontent-smart-link:add:initial:response',
  InitializedResponse = 'kontent-smart-link:initialized:response',
  PreviewIFrameCurrentUrl = 'kontent-smart-link:preview:current-url',
  RefreshPreview = 'kontent-smart-link:preview:refresh',
  UpdatePreview = 'kontent-smart-link:preview:update',
  Status = 'kontent-smart-link:status',
}

export interface IInitializedSmartLinkSdkClientMessage {
  readonly data: IInitializedMessageData;
  readonly requestId: string;
  readonly type: SmartLinkSdkClientMessageType.Initialized;
}

export interface IEditButtonClickedElementSmartLinkSdkClientMessage {
  readonly data: IEditButtonElementClickedMessageData;
  readonly metadata: ISmartLinkSdkMessageMetadata;
  readonly type: SmartLinkSdkClientMessageType.EditElementClicked;
}

export interface IEditButtonClickedContentItemSmartLinkSdkClientMessage {
  readonly data: IEditButtonContentItemClickedMessageData;
  readonly metadata: ISmartLinkSdkMessageMetadata;
  readonly type: SmartLinkSdkClientMessageType.EditContentItemClicked;
}

export interface IEditButtonClickedContentComponentSdkClientMessage {
  readonly data: IEditButtonContentComponentClickedMessageData;
  readonly metadata: ISmartLinkSdkMessageMetadata;
  readonly type: SmartLinkSdkClientMessageType.EditContentComponentClicked;
}

export interface IAddButtonInitialSmartLinkSdkClientMessage {
  readonly data: IAddButtonInitialMessageData;
  readonly metadata: ISmartLinkSdkMessageMetadata;
  readonly requestId: string;
  readonly type: SmartLinkSdkClientMessageType.AddButtonInitial;
}

export interface IAddButtonActionSmartLinkSdkClientMessage {
  readonly data: IAddButtonActionMessageData;
  readonly metadata: ISmartLinkSdkMessageMetadata;
  readonly type: SmartLinkSdkClientMessageType.AddButtonAction;
}

export interface IPreviewIFrameCurrentUrlResponseSmartLinkSdkClientMessage {
  readonly type: SmartLinkSdkClientMessageType.PreviewIFrameCurrentUrlResponse;
  readonly data: IPreviewIFrameCurrentUrlMessageData;
}

export interface IStatusSmartLinkSdkHostMessage {
  readonly data: IHighlightStatusMessageData;
  readonly type: SmartLinkSdkHostMessageType.Status;
}

export interface IAddButtonInitialSmartLinkSdkHostMessage {
  readonly data: {
    readonly elementType: AddButtonElementType;
    readonly isParentPublished: boolean;
    readonly permissions: ReadonlyMap<AddButtonPermission, AddButtonPermissionOption>;
  };
  readonly requestId: string;
  readonly type: SmartLinkSdkHostMessageType.AddButtonInitialResponse;
}

export interface IInitializedResponseSmartLinkSdkHostMessage {
  readonly requestId: string;
  readonly type: SmartLinkSdkHostMessageType.InitializedResponse;
}

export interface IReloadPreviewSmartLinkSdkHostMessage {
  readonly type: SmartLinkSdkHostMessageType.RefreshPreview;
  readonly data?: IReloadPreviewMessageData;
  readonly metadata: IReloadPreviewMessageMetadata;
}

export interface IUpdatePreviewSmartLinkSdkHostMessage {
  readonly type: SmartLinkSdkHostMessageType.UpdatePreview;
  readonly data?: IUpdateMessageData;
}

export interface IPreviewIFrameCurrentUrlRequestSmartLinkSdkHostMessage {
  readonly type: SmartLinkSdkHostMessageType.PreviewIFrameCurrentUrl;
}

export interface IReloadPreviewMessageData {
  readonly projectId: string;
  readonly languageCodename: string;
  readonly updatedItemCodename: string;
}

export interface IPreviewIFrameCurrentUrlMessageData {
  readonly previewUrl: string;
}

export interface IReloadPreviewMessageMetadata {
  readonly manualRefresh: boolean;
}

export interface IHighlightStatusMessageData {
  readonly enabled: boolean;
}

export interface ISmartLinkSDKSupportedFeatures {
  readonly previewIFrameCurrentUrlHandler: boolean;
  readonly refreshHandler: boolean;
  readonly updateHandler: boolean;
}

export interface IInitializedMessageData {
  readonly enabled: boolean;
  readonly languageCodename: string | null;
  readonly projectId: string | null;
  readonly supportedFeatures?: ISmartLinkSDKSupportedFeatures;
}

export interface IMessageBaseData {
  readonly itemId: Uuid;
  readonly languageCodename: string;
  readonly projectId: string;
}

export interface IEditButtonContentItemClickedMessageData extends IMessageBaseData {}

export interface IEditButtonContentComponentClickedMessageData
  extends IEditButtonContentItemClickedMessageData {
  readonly contentComponentId: string;
}

export interface IEditButtonElementClickedMessageData
  extends IEditButtonContentItemClickedMessageData {
  readonly contentComponentId?: Uuid;
  readonly elementCodename: string;
}

export interface IAddButtonInitialMessageData extends IMessageBaseData {
  readonly contentComponentId?: Uuid;
  readonly elementCodename: string;
  readonly insertPosition: InsertPosition;
}

export interface IAddButtonActionMessageData extends IAddButtonInitialMessageData {
  readonly action: AddButtonAction;
}

export enum AddButtonAction {
  CreateComponent = 'CreateComponent',
  CreateLinkedItem = 'CreateLinkedItem',
  InsertLinkedItem = 'InsertLinkedItem',
}

export interface ISmartLinkSdkMessageMetadata {
  readonly elementRect: DOMRect;
}
