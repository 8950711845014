import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SelectSubscription_Clear,
} from '../../shared/constants/subscriptionManagementActionTypes.ts';
import { Subscription_SubscriptionRename_ChangeName } from '../constants/actionTypes.ts';

const defaultState = '';

export const editedSubscriptionName = (state: string = defaultState, action: Action): string => {
  switch (action.type) {
    case Subscription_SelectSubscription: {
      return action.payload.subscription.subscriptionName;
    }

    case Subscription_SubscriptionRename_ChangeName: {
      return action.payload.name;
    }

    case Subscription_SelectSubscription_Clear: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
};
