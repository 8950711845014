import Immutable from 'immutable';
import { BaseBlockType } from '../../../../utils/blocks/blockType.ts';
import { ObjectDataType } from '../../../../utils/export/html/elements/objects.ts';
import { DraftJSInlineStyle } from '../../../inlineStyles/api/inlineStyles.ts';

export class OutputLock {
  private readonly _lockedKeys: Immutable.Set<string>;

  constructor(lockedKeys: Immutable.Set<string> = Immutable.Set()) {
    this._lockedKeys = lockedKeys;
  }

  public isOpen = (): boolean => this._lockedKeys.isEmpty();

  public add = (key: string): OutputLock => {
    const lockedKeys = this._lockedKeys.add(key);
    return new OutputLock(lockedKeys);
  };

  public remove = (key: string): OutputLock => {
    const lockedKeys = this._lockedKeys.remove(key);
    return new OutputLock(lockedKeys);
  };
}

export class WriteContext {
  private _blockIdForWhitespaceWrite: string | null = null;
  private _whitespaceWriteFromPreviousTextNode: (() => void) | null = null;
  private _writeToNewBlock: boolean = true;

  public executeWhitespaceWriteFromPreviousTextNode = (blockId: string | null) => {
    if (blockId !== null && blockId === this._blockIdForWhitespaceWrite) {
      if (this._whitespaceWriteFromPreviousTextNode) {
        this._whitespaceWriteFromPreviousTextNode();
      }
      this._whitespaceWriteFromPreviousTextNode = null;
    }
  };

  public writeWhitespaceWithNextWriteToBlock = (blockId: string | null, write: () => void) => {
    this._blockIdForWhitespaceWrite = blockId;
    this._whitespaceWriteFromPreviousTextNode = write;
  };

  public shouldWriteToNewBlock = (): boolean => this._writeToNewBlock;

  public nextWriteToNewBlock = (): void => {
    this._writeToNewBlock = true;
  };

  public resetWriteToNewBlock = (): void => {
    this._writeToNewBlock = false;
  };
}

export type ListContext = {
  readonly blockType: BaseBlockType;
  readonly depth: number;
  readonly writeLockKey: string;
  readonly newBlockLockKey?: string;
};

export class TableContext {
  public readonly maxRowLength: number;
  public readonly writeLockKey: string;
  private _rowIndex: number = -1;
  private _cellIndex: number = -1;

  constructor(maxRowLength: number, writeLockKey: string) {
    this.maxRowLength = maxRowLength;
    this.writeLockKey = writeLockKey;
  }

  public addRow = (): void => {
    this._rowIndex++;
    this._cellIndex = -1;
  };

  public addCell = (): void => {
    this._cellIndex++;
  };

  public getRowIndex = (): number => this._rowIndex;

  public getCellIndex = (): number => this._cellIndex;
}

export type LinkContext = {
  readonly blockKey: string;
  readonly charOffset: number;
};

export class TextBlockContext {
  public readonly containsText: boolean;

  private _linesCount: number;

  constructor(containsText: boolean, initialLinesCount: number = 0) {
    this.containsText = containsText;
    this._linesCount = initialLinesCount;
  }

  public getLinesCount = (): number => this._linesCount;

  public incrementLinesCount = (): void => {
    this._linesCount++;
  };
}

type InlineStyleContext = {
  readonly allowedStyles: ReadonlyArray<DraftJSInlineStyle>;
  readonly contextualStyles: ReadonlyArray<DraftJSInlineStyle>;
};

type NestedContentContext = {
  readonly parentBlockTypes: ReadonlyArray<BaseBlockType>;
};

export type StackItem = {
  readonly currentProjectId: Uuid;
  readonly inlineStyleContext: InlineStyleContext;
  readonly linkContext: LinkContext | null;
  readonly listContext: ListContext | null;
  readonly metadata: ReadonlyMap<ObjectDataType, Uuid>;
  readonly nestedContentContext: NestedContentContext;
  readonly newBlockLock: OutputLock;
  readonly sourceProjectId: Uuid | null;
  readonly tableContext: TableContext | null;
  readonly textBlockContext: TextBlockContext | null;
  readonly writeContext: WriteContext;
  readonly writeLock: OutputLock;
};
