import { Action } from '../../../../../../@types/Action.type.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import { Shared_ModalOpenedWithProperties } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_TaskArchive_Finished,
  ContentItemEditing_TaskEditing_Cancelled,
  ContentItemEditing_TaskEditing_Started,
  ContentItemEditing_UpdateTask_Finished,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { NewTaskId } from '../../../../features/ContentItemEditing/constants/taskConstants.ts';

export const selectedTaskId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Shared_ModalOpenedWithProperties: {
      if (action.payload.properties.dialogType === ModalDialogType.ContentItemAddTaskDialog) {
        return NewTaskId;
      }

      return state;
    }

    case ContentItemEditing_TaskEditing_Cancelled:
    case ContentItemEditing_UpdateTask_Finished:
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_TaskArchive_Finished:
      return null;

    case ContentItemEditing_TaskEditing_Started:
      return action.payload.taskId;

    default: {
      return state;
    }
  }
};
