import { ContentTypeSnippetTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IContentTypeSnippetTypeElementData extends IBaseTypeElementData {
  readonly snippetId: Uuid | null;
}

export const EmptyContentTypeSnippetTypeElementData: IContentTypeSnippetTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.None,
  isRequired: false,
  snippetId: null,
  type: ElementType.ContentTypeSnippet,
};

export function getContentTypeSnippetTypeElementDataFromServerModel(
  serverModel: ContentTypeSnippetTypeElementServerModel,
): IContentTypeSnippetTypeElementData {
  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyContentTypeSnippetTypeElementData._configurability,
    isRequired: false, // always false as it does not make sense for this element
    snippetId: serverModel.snippetId ?? EmptyContentTypeSnippetTypeElementData.snippetId,
    type: EmptyContentTypeSnippetTypeElementData.type,
  };
}
