import { IServiceAgreementSignatureServerModel } from '../../../repositories/serverModels/SubscriptionServerModel.type.ts';

export interface IServiceAgreementSignature {
  readonly isInvalid: boolean;
  readonly invalidationReason: string | null;
}

export const EmptyServiceAgreementSignature: IServiceAgreementSignature = {
  isInvalid: true,
  invalidationReason: '',
};

export function createServiceAgreementSignatureDomainModel(
  serverModel: IServiceAgreementSignatureServerModel | null | undefined,
): IServiceAgreementSignature {
  if (!serverModel) {
    return EmptyServiceAgreementSignature;
  }

  return {
    invalidationReason:
      serverModel.invalidationReason || EmptyServiceAgreementSignature.invalidationReason,
    isInvalid: serverModel.isInvalid,
  };
}
