import Immutable from 'immutable';
import {
  isVariantArchived,
  isVariantPublished,
} from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { flattenTaxonomyGroupTerms } from '../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { Asset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getDeletedTaxonomyTermIds } from '../../../../contentModels/taxonomy/utils/deletedTaxonomyUtils.ts';
import {
  AssetReference,
  createAssetReferenceMemoized,
} from '../../../models/contentItemElements/AssetItemElement.ts';
import {
  FriendlyWarningReasonDetail,
  IFriendlyWarning,
  createBrokenAssetWarningsWithDetails,
  createBrokenLinkWarningsWithDetails,
  createBrokenModularWarningsWithDetails,
  createBrokenTaxonomyWarningsWithDetails,
} from '../types/FriendlyWarnings.ts';

export type IGetBrokenLinkWarningsWithDetails = (
  contentLinkIds: ReadonlyArray<Uuid>,
  listingItems: Immutable.Map<Uuid, IListingContentItem>,
  assetLinkIds: ReadonlyArray<Uuid>,
  assets: Immutable.Map<Uuid, IAsset>,
) => ReadonlyArray<IFriendlyWarning>;

export type IGetBrokenModularWarningsWithDetails = (
  modularItemIds: ReadonlyArray<Uuid | null>,
  listingItems: Immutable.Map<Uuid, IListingContentItem>,
) => ReadonlyArray<IFriendlyWarning>;

export type IGetBrokenAssetWarningsWithDetails = (
  assetReferences: ReadonlyArray<AssetReference>,
  assets: Immutable.Map<Uuid, IAsset>,
) => ReadonlyArray<IFriendlyWarning>;

export type IGetBrokenTaxonomyWithDetails = (
  taxonomyGroup: ITaxonomyGroup | null,
  selectedTermIds: ReadonlySet<Uuid> | null,
) => ReadonlyArray<IFriendlyWarning>;

const emptyFriendlyWarningsList: ReadonlyArray<IFriendlyWarning> = [];

const getBrokenModularWarningDetails = (
  modularItemIds: ReadonlyArray<Uuid | null>,
  listingItems: Immutable.Map<Uuid, IListingContentItem>,
): ReadonlyArray<FriendlyWarningReasonDetail> => {
  return modularItemIds.reduce((reasonDetails: Array<FriendlyWarningReasonDetail>, id: Uuid) => {
    const contentItem = listingItems.get(id);
    if (!contentItem) {
      reasonDetails.push(FriendlyWarningReasonDetail.NonExistingItem);
    } else if (isVariantArchived(contentItem.variant)) {
      reasonDetails.push(FriendlyWarningReasonDetail.DeletedItem);
    } else if (!isVariantPublished(contentItem.variant)) {
      reasonDetails.push(FriendlyWarningReasonDetail.UnpublishedItem);
    }

    return reasonDetails;
  }, []);
};

const getBrokenAssetWarningDetails = (
  assetReferences: ReadonlyArray<AssetReference>,
  assets: Immutable.Map<Uuid, IAsset>,
): Array<FriendlyWarningReasonDetail> => {
  return assetReferences.reduce((reasonDetails, assetReference) => {
    const asset = assets.get(assetReference.id);
    if (!asset || !Asset.exists(asset)) {
      reasonDetails.push(FriendlyWarningReasonDetail.NonExistingAsset);
    } else if (asset.archived) {
      reasonDetails.push(FriendlyWarningReasonDetail.DeletedAsset);
    }

    return reasonDetails;
  }, new Array<FriendlyWarningReasonDetail>());
};

export const getBrokenModularWarningsWithDetails: IGetBrokenModularWarningsWithDetails = (
  modularItemIds: ReadonlyArray<Uuid | null>,
  listingItems: Immutable.Map<Uuid, IListingContentItem>,
): ReadonlyArray<IFriendlyWarning> => {
  const reasons = getBrokenModularWarningDetails(modularItemIds, listingItems);

  return reasons.length
    ? createBrokenModularWarningsWithDetails(reasons)
    : emptyFriendlyWarningsList;
};

export const getBrokenAssetWarningsWithDetails: IGetBrokenAssetWarningsWithDetails = (
  assetReferences: ReadonlyArray<AssetReference>,
  assets: Immutable.Map<Uuid, IAsset>,
): ReadonlyArray<IFriendlyWarning> => {
  const reasons = getBrokenAssetWarningDetails(assetReferences, assets);

  return reasons.length ? createBrokenAssetWarningsWithDetails(reasons) : emptyFriendlyWarningsList;
};

export const getBrokenLinkWarningsWithDetails: IGetBrokenLinkWarningsWithDetails = (
  contentLinkIds: ReadonlyArray<Uuid>,
  listingItems: Immutable.Map<Uuid, IListingContentItem>,
  assetLinkIds: ReadonlyArray<Uuid>,
  assets: Immutable.Map<Uuid, IAsset>,
): ReadonlyArray<IFriendlyWarning> => {
  const brokenModulars = getBrokenModularWarningDetails(contentLinkIds, listingItems);
  const brokenAssets = getBrokenAssetWarningDetails(
    assetLinkIds.map((assetId) => createAssetReferenceMemoized(assetId)),
    assets,
  );
  const allReasons = brokenModulars.concat(brokenAssets);

  return allReasons.length
    ? createBrokenLinkWarningsWithDetails(allReasons)
    : emptyFriendlyWarningsList;
};

export const getBrokenTaxonomyWithDetails: IGetBrokenTaxonomyWithDetails = (
  taxonomyGroup: ITaxonomyGroup | null,
  selectedTermIds: ReadonlySet<Uuid> | null,
): ReadonlyArray<IFriendlyWarning> => {
  const deletedTerms =
    taxonomyGroup && selectedTermIds
      ? getDeletedTaxonomyTermIds(selectedTermIds, flattenTaxonomyGroupTerms(taxonomyGroup))
      : [];

  return deletedTerms.length
    ? createBrokenTaxonomyWarningsWithDetails([FriendlyWarningReasonDetail.DeletedTaxonomyTerm])
    : emptyFriendlyWarningsList;
};
