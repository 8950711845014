import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ItemColumnCode } from '../../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../../../_shared/models/OrderBy.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { SearchMethod } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { tryParseApiError } from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { JsonPatchOperation } from '../../../../../../repositories/utils/jsonPatchConstants.ts';
import { IListingFilter } from '../../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { ContentItemSavingOrigin } from '../../models/ContentItemSavingOrigin.ts';
import {
  contentItemSavingFailed,
  contentItemSavingFinished,
  contentItemSavingStarted,
} from '../contentItemEditingActions.ts';

interface ISaveContentItemCodenameToServerDependencies {
  readonly contentItemRepository: IContentItemRepository;
}

export const createSaveContentItemCodenameToServerAction =
  (deps: ISaveContentItemCodenameToServerDependencies) =>
  (
    /*
     * Some params are required just because of contentItemSavingFinished contract.
     * The action should not be used here. It doesn't fit to this use case.
     * Useless params are at least filter, orderBy, and usedSearchMethod.
     * Redux actions are not reusable in general as an action type must describe a specific event.
     */
    editedContentItemId: Uuid,
    itemPreviouslyUpdatedAt: string | null,
    codename: string,
    usedSearchMethod: SearchMethod,
    filter: IListingFilter,
    orderBy: OrderBy<ItemColumnCode>,
    contentItemSavingOrigin: ContentItemSavingOrigin,
  ): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(contentItemSavingStarted(itemEditorOperationIds.codename));

      const itemServerModel = await deps.contentItemRepository.patchItemProperty(
        editedContentItemId,
        JsonPatchOperation.Replace,
        itemEditorOperationIds.codename,
        codename,
      );

      dispatch(
        contentItemSavingFinished({
          filter,
          orderBy,
          itemPreviouslyUpdatedAt,
          itemServerModel,
          operationId: itemEditorOperationIds.codename,
          origin: contentItemSavingOrigin,
          usedSearchMethod,
        }),
      );
    } catch (error) {
      dispatch(
        contentItemSavingFailed({
          operationId: itemEditorOperationIds.codename,
          apiError: tryParseApiError(error),
        }),
      );
      throw error;
    }
  };
