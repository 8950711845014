import { FullTextSearchContextStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';
import { IFullTextSearchContext } from '../applications/contentInventory/content/stores/IContentAppStoreState.ts';
import { ListingContext } from './listingOrderByStorage.ts';

function getStorageKey(listingContext: ListingContext, projectId: Uuid): string {
  return `${FullTextSearchContextStorageKey}-${listingContext}-${projectId}`;
}

export interface IFullTextSearchContextStorage {
  readonly save: (
    context: IFullTextSearchContext,
    listingContext: ListingContext,
    projectId: Uuid,
  ) => void;
  readonly forget: (listingContext: ListingContext, projectId: Uuid) => void;
  readonly load: (listingContext: ListingContext, projectId: Uuid) => IFullTextSearchContext | null;
}

export const fullTextSearchContextStorage: IFullTextSearchContextStorage = {
  save(context: IFullTextSearchContext, listingContext: ListingContext, projectId: Uuid): void {
    localStorage.set(getStorageKey(listingContext, projectId), JSON.stringify(context));
  },

  forget(listingContext: ListingContext, projectId: Uuid): void {
    localStorage.remove(getStorageKey(listingContext, projectId));
  },

  load(listingContext: ListingContext, projectId: Uuid): IFullTextSearchContext | null {
    const stringValue = localStorage.get(getStorageKey(listingContext, projectId));
    return stringValue ? JSON.parse(stringValue) : null;
  },
};
