import { Collection } from '@kontent-ai/utils';
import { QuantityOption } from '../../../../../contentInventory/content/models/quantityOptions.ts';

export function getIntegerFromString(input: string, returnIfFail: number = 0): number {
  const parsedInput = Number.parseInt(input, 10);
  return Number.isNaN(parsedInput) ? returnIfFail : parsedInput;
}

export const getIntegerFromStringOrNull = (input: string | null | undefined): number | null =>
  input ? getIntegerFromString(input) : null;

export function getFloatFromString(input: string, returnIfFail: number = 0): number {
  const parsedInput = Number.parseFloat(input);
  return Number.isNaN(parsedInput) ? returnIfFail : parsedInput;
}

export const getFloatFromStringOrNull = (input: string | null | undefined): number | null =>
  input ? getFloatFromString(input) : null;

export function getQuantityUnitOption(
  minItems: number | null,
  maxItems: number | null,
): QuantityOption {
  if (maxItems && minItems) {
    return QuantityOption.Exactly;
  }
  if (maxItems) {
    return QuantityOption.Maximum;
  }
  return QuantityOption.Minimum;
}

export const getLimitationsDomainModel = <T>(
  serverModel: ReadonlyArray<T> | undefined,
  allLimitations: ReadonlySet<T>,
): ReadonlySet<T> => (serverModel?.length ? new Set<T>(serverModel) : allLimitations);

export const getLimitationsServerModel = <T>(
  domainModel: ReadonlySet<T>,
  allLimitations: ReadonlySet<T>,
): ReadonlyArray<T> =>
  allLimitations.isSubsetOf(domainModel) ? [] : Collection.getValues(domainModel);
