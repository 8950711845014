import { combineReducers } from '@reduxjs/toolkit';
import { filterSearchPhrase } from './listing/filterSearchPhrase.ts';
import { listingOperationStatus } from './listing/listingOperationStatus.ts';
import { loadingStatus } from './listing/loadingStatus.ts';
import { selectedContentTypes } from './listing/selectedContentTypes.ts';

export const listingReducer = combineReducers({
  filterSearchPhrase,
  listingOperationStatus,
  loadingStatus,
  selectedContentTypes,
});
