import Immutable from 'immutable';
import { GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { OnboardingNotificationsSettingsServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { isGettingStartedProject } from '../../../../_shared/utils/sampleItemsUtils.ts';
import { IOnboardingNotificationsServerModel } from '../../../models/onboardingNotifications/OnboardingNotificationsServerModel.type.ts';
import {
  AdditionalOnboardingNotificationData,
  ContentItemPublishedOnboardingNotificationData,
  ContentTypeEditedOnboardingNotificationData,
  OnboardingNotification,
} from '../../../models/user/OnboardingNotification.ts';
import { getCurrentProject } from '../../../reducers/user/selectors/userProjectsInfoSelectors.ts';
import { OnboardingNotification_Triggered } from '../../onboardingNotificationsActions.ts';

interface IDeps {
  readonly upsertUserProperty: (propertyKey: string, propertyValue: string) => ThunkPromise;
}

const mapToServerModel = (
  onboardingNotifications: Immutable.Map<OnboardingNotification, boolean>,
): IOnboardingNotificationsServerModel => ({
  showForTypeEditing: onboardingNotifications.get(
    OnboardingNotification.ContentTypeEditedNotification,
    false,
  ),
  showForItemPublishing: onboardingNotifications.get(
    OnboardingNotification.ContentPublishedNotification,
    false,
  ),
});

const onboardingNotificationTriggeredAction = (
  triggeredNotification: OnboardingNotification,
  shouldBeDisplayed: boolean,
  additionalData: AdditionalOnboardingNotificationData,
) =>
  ({
    type: OnboardingNotification_Triggered,
    payload: {
      triggeredNotification,
      shouldBeDisplayed,
      additionalData,
    },
  }) as const;

export type OnboardingNotificationTriggeredActionsType = ReturnType<
  typeof onboardingNotificationTriggeredAction
>;

const shouldDisplayOnboardingNotification = (
  triggeredNotification: OnboardingNotification,
  additionalData: AdditionalOnboardingNotificationData,
  getState: GetState,
): boolean => {
  if (!isGettingStartedProject(getCurrentProject(getState()))) {
    return false;
  }

  if (triggeredNotification === OnboardingNotification.ContentTypeEditedNotification) {
    const contentTypeEditedAdditionalData =
      additionalData as ContentTypeEditedOnboardingNotificationData;
    return !!contentTypeEditedAdditionalData.languageIdToCreateItem;
  }

  return true;
};

type OnboardingNotificationFunctionArguments =
  | [
      OnboardingNotification.ContentTypeEditedNotification,
      ContentTypeEditedOnboardingNotificationData,
    ]
  | [
      OnboardingNotification.ContentPublishedNotification,
      ContentItemPublishedOnboardingNotificationData,
    ];

export type OnboardingNotificationTriggeredAction = (
  ...args: OnboardingNotificationFunctionArguments
) => ThunkPromise;

export const onboardingNotificationTriggeredCreator =
  (deps: IDeps) =>
  (
    onboardingNotification: OnboardingNotification,
    additionalData: AdditionalOnboardingNotificationData,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: {
        userProperties: { onboardingNotificationsSettings },
      },
    } = getState();

    if (!onboardingNotificationsSettings.get(onboardingNotification)) {
      return;
    }
    const updatedOnboardingNotifications = onboardingNotificationsSettings.set(
      onboardingNotification,
      false,
    );
    const apiModel = mapToServerModel(updatedOnboardingNotifications);
    const shouldDisplay = shouldDisplayOnboardingNotification(
      onboardingNotification,
      additionalData,
      getState,
    );

    await Promise.all([
      dispatch(
        deps.upsertUserProperty(OnboardingNotificationsSettingsServerKey, JSON.stringify(apiModel)),
      ),
      dispatch(
        onboardingNotificationTriggeredAction(
          onboardingNotification,
          shouldDisplay,
          additionalData,
        ),
      ),
    ]);
  };
