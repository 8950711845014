import React from 'react';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { workflowNameChanged } from '../actions/workflowActions.ts';
import { WorkflowName as WorkflowNameComponent } from '../components/WorkflowName.tsx';

export const WorkflowNameContainer: React.FC = () => {
  const workflowName = useSelector((s) => s.workflowsApp.editorUi.editedWorkflowName);
  const dispatch = useDispatch();

  const setCurrentName = compose(dispatch, workflowNameChanged);

  const isDefaultWorkflow = useSelector(
    (s) => s.workflowsApp.editorUi.editedWorkflowId === DefaultWorkflowId,
  );

  return (
    <WorkflowNameComponent
      name={workflowName}
      onChange={setCurrentName}
      isDefaultWorkflow={isDefaultWorkflow}
    />
  );
};

WorkflowNameContainer.displayName = 'WorkflowNameContainer';
export { WorkflowNameContainer as WorkflowName };
