import React, { useCallback } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { ISubscriptionUsage } from '../../shared/models/SubscriptionUsage.ts';
import { PlanCard as PlanCardComponent } from '../components/PlanCard.tsx';

export type PlanCardProps = {
  readonly action?: ButtonAction;
  readonly plan?: IPlan;
  readonly planDescription: string;
  readonly planPrice: string;
  readonly subscriptionUsage?: ISubscriptionUsage;
  readonly title: string;
};

export type ButtonAction = {
  readonly title: string;
  readonly handler: () => void;
};

export const PlanCard: React.FC<PlanCardProps> = (props: PlanCardProps) => {
  const dispatch = useDispatch();
  const trackPlanDetailsOpened = useCallback(() => {
    dispatch(trackUserEvent(TrackedEvent.PlanDetailsOpened));
  }, []);

  return <PlanCardComponent {...props} trackPlanDetailsOpened={trackPlanDetailsOpened} />;
};

PlanCard.displayName = 'PlanCard';
