import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { AssetTilePreview } from '../../../../../_shared/components/AssetTile/AssetTilePreview.tsx';
import { AssetTileSummary } from '../../../../../_shared/components/AssetTile/AssetTileSummary.tsx';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../_shared/hooks/useAutoScroll.ts';
import { IAssetRendition } from '../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { AssetThumbnailBottomClassname } from '../../../../contentInventory/assets/components/AssetTile/AssetTile.tsx';
import { AssetTileActions } from '../../../../contentInventory/assets/components/AssetTile/AssetTileActions.tsx';
import { IAssetValidationResult } from '../../ContentItemEditing/components/elements/asset/AssetTile.tsx';

const AssetValidResult: IAssetValidationResult = {
  isAssetFileSizeValid: true,
  isAssetFileTypeValid: true,
  isAssetHeightValid: true,
  isAssetWidthValid: true,
};

type Props = {
  readonly altText: string;
  readonly asset: IAsset;
  readonly canViewAsset: boolean;
  readonly className: string | undefined;
  readonly collectionName: string | null;
  readonly isUncategorized: boolean | null;
  readonly isViewOnly: boolean;
  readonly rendition: IAssetRendition | undefined;
};

export const AssetViewerTile: React.FC<Props> = ({
  altText,
  asset,
  canViewAsset,
  className,
  collectionName,
  isUncategorized,
  isViewOnly,
  rendition,
}) => {
  const assetRef = useRef<HTMLDivElement>(null);
  const assetScrollId = CreateAutoScrollId.forAsset(asset.id || '');
  useAutoScroll({
    scrollId: assetScrollId,
    scrollTargetRef: assetRef,
  });

  const isAssetAccessible = canViewAsset && !asset.archived;

  const content = (
    <>
      <AssetTilePreview
        asset={asset}
        altText={altText}
        canViewAsset={canViewAsset}
        transformation={rendition?.transformation}
      />
      <div
        className={classNames(
          AssetThumbnailBottomClassname,
          `${AssetThumbnailBottomClassname}--with-padding`,
        )}
      >
        {!isAssetAccessible || !isViewOnly ? (
          <AssetTileSummary
            asset={asset}
            canViewAsset={canViewAsset}
            collectionName={collectionName}
            searchPhrase=""
            validationResult={AssetValidResult}
            rendition={rendition}
          />
        ) : undefined}
      </div>
    </>
  );

  const isClickable = isAssetAccessible && isViewOnly && asset._link;

  return (
    <div
      className={classNames('asset-thumbnail', 'asset-thumbnail--is-disabled', className, {
        'asset-thumbnail--is-not-clickable': !isClickable,
        'asset-thumbnail--is-viewonly': isViewOnly,
      })}
      ref={assetRef}
    >
      {isAssetAccessible && (
        <AssetTileActions
          asset={asset}
          isUncategorized={isUncategorized}
          showViewAction={isViewOnly}
          transformation={rendition?.transformation}
        />
      )}
      {isClickable ? <OutwardLink href={asset._link || ''}>{content}</OutwardLink> : content}
    </div>
  );
};

AssetViewerTile.displayName = 'AssetViewerTile';
