import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  AssetLibrary_AssetEditorDialog_InitFinished,
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitFinished,
  AssetLibrary_AssetEditor_InitStarted,
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_Asset_ArchiveCompleted,
  AssetLibrary_Asset_ReplaceFailed,
  AssetLibrary_Asset_ReplaceFinished,
  AssetLibrary_Assets_ArchiveCompleted,
  AssetLibrary_Assets_AssignTermsCompleted,
  AssetLibrary_Assets_CreateAssets,
  AssetLibrary_Assets_MoveBackToCollectionCompleted,
  AssetLibrary_Assets_MoveBackToFolderCompleted,
  AssetLibrary_Assets_MoveToCollectionCompleted,
  AssetLibrary_Assets_MoveToFolderCompleted,
  AssetLibrary_Assets_RemoveFailedAsset,
  AssetLibrary_Assets_ReportUploadProgress,
  AssetLibrary_Assets_RestoreCompleted,
  AssetLibrary_Assets_UploadFailed,
  AssetLibrary_Assets_UploadFinished,
  AssetLibrary_Folders_ArchiveCompleted,
  AssetLibrary_Folders_RestoreCompleted,
} from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { FileWithThumbnail } from '../../../../applications/contentInventory/assets/models/FileWithThumbnail.type.ts';
import { RequiredAssetCreationMetadata } from '../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  Data_AssetListing_Success,
  Data_Assets_Loaded,
} from '../../../constants/dataActionTypes.ts';
import { EmptyAsset, IAsset } from '../../../models/assets/Asset.ts';

const getAsset = (
  file: FileWithThumbnail,
  assetId: Uuid,
  { collectionId, folderId }: RequiredAssetCreationMetadata,
): IAsset => ({
  ...EmptyAsset,
  collectionId,
  id: assetId,
  fileSize: file.size,
  folderId,
  type: file.type,
  filename: file.name,
  _thumbnailLink: file.thumbnailLink,
  _sizeUploaded: 0,
  _uploaded: 0,
  _uploading: true,
});

const initialState = Immutable.Map<Uuid, IAsset>();

export function byId(state = initialState, action: Action): Immutable.Map<Uuid, IAsset> {
  switch (action.type) {
    case AssetLibrary_Assets_CreateAssets: {
      const { files, metadata } = action.payload;
      if (!files) {
        return state;
      }

      return Array.from(files).reduce(
        (newState: Immutable.Map<Uuid, IAsset>, [assetId, file]: [Uuid, FileWithThumbnail]) => {
          return newState.set(assetId, getAsset(file, assetId, metadata));
        },
        state,
      );
    }

    case Data_Assets_Loaded:
    case Data_AssetListing_Success: {
      const assets = action.payload.assets;
      return state.concat(assets.map((asset) => [asset.id, asset])).toMap();
    }

    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditorDialog_InitStarted: {
      return state || initialState;
    }

    case AssetLibrary_AssetEditor_InitFinished:
    case AssetLibrary_AssetEditorDialog_InitFinished: {
      return state.set(action.payload.asset.id, action.payload.asset);
    }

    case AssetLibrary_AssetEditor_SaveFinished:
    case AssetLibrary_Asset_ArchiveCompleted: {
      const asset = action.payload.asset;
      if (!asset || !state) {
        return state;
      }
      return state.set(asset.id, asset);
    }

    case AssetLibrary_Folders_RestoreCompleted:
    case AssetLibrary_Assets_RestoreCompleted:
    case AssetLibrary_Assets_AssignTermsCompleted:
      return state.merge(action.payload.assets);

    case AssetLibrary_Folders_ArchiveCompleted:
      return Immutable.Map<Uuid, IAsset>(
        state.filter((asset: IAsset) => !action.payload.successfulAssetsIds.has(asset.id)),
      );

    case AssetLibrary_Assets_ArchiveCompleted:
      return Immutable.Map<Uuid, IAsset>(
        state.filter((asset: IAsset) => !action.payload.successfulIds.has(asset.id)),
      );

    case AssetLibrary_Assets_ReportUploadProgress: {
      if (!state) {
        return state;
      }
      const assetId = action.payload.assetId;
      const asset = state.get(assetId);
      if (!asset) {
        return state;
      }
      const updatedFields = {
        _uploaded: action.payload.percentUploaded,
        _sizeUploaded: action.payload.sizeUploaded,
      };
      return state.set(assetId, { ...asset, ...updatedFields });
    }

    case AssetLibrary_Assets_UploadFailed:
    case AssetLibrary_Asset_ReplaceFailed: {
      if (!state) {
        return state;
      }
      const assetId = action.payload.oldAssetId;
      const asset = state.get(assetId);
      if (!asset) {
        return state;
      }
      const updatedFields = {
        _uploading: false,
        _failed: true,
      };
      return state.set(assetId, { ...asset, ...updatedFields });
    }

    case AssetLibrary_Assets_UploadFinished:
    case AssetLibrary_Asset_ReplaceFinished: {
      if (!state) {
        return state;
      }

      const assetId = action.payload.oldAssetId;
      const newAsset: IAsset = action.payload.newAsset;
      const asset = state.get(assetId);

      if (!asset) {
        return state;
      }

      const updatedFields = {
        _uploading: false,
        _failed: false,
      };

      return state.delete(assetId).set(newAsset.id, { ...asset, ...newAsset, ...updatedFields });
    }

    case AssetLibrary_Assets_MoveToCollectionCompleted:
    case AssetLibrary_Assets_MoveBackToCollectionCompleted:
      return Collection.getEntries(action.payload.successfulIds).reduce(
        (reducedState: Immutable.Map<Uuid, IAsset>, [assetId, collectionReference]) => {
          return reducedState.has(assetId)
            ? reducedState.update(assetId, (asset) => ({
                ...asset,
                collectionId: collectionReference?.id ?? null,
              }))
            : reducedState;
        },
        state,
      );

    case AssetLibrary_Assets_MoveToFolderCompleted:
    case AssetLibrary_Assets_MoveBackToFolderCompleted:
      return Collection.getEntries(action.payload.successfulIds).reduce(
        (reducedState: Immutable.Map<Uuid, IAsset>, [assetId, folderReference]) => {
          return reducedState.has(assetId)
            ? reducedState.update(assetId, (asset) => ({
                ...asset,
                folderId: folderReference.id,
              }))
            : reducedState;
        },
        state,
      );

    case AssetLibrary_Assets_RemoveFailedAsset:
      return state.delete(action.payload.oldAssetId);

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
