import React, { ReactNode } from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { captionTextColor, checkboxPadding, checkboxSymbolSize } from '../utils/stylingUtils.ts';

export const CheckboxCaption: React.FC<
  Readonly<{
    captionId: string;
    caption: ReactNode;
  }>
> = ({ caption, captionId }) => (
  <Box
    id={captionId}
    color={captionTextColor}
    overflowWrap="anywhere"
    typography={Typography.Caption}
    css={`padding-left: ${px(2 * checkboxPadding + checkboxSymbolSize)}`}
  >
    {caption}
  </Box>
);
