import { useCallback } from 'react';
import { GetApiMethods, WithApiParameter } from '../types/Editor.api.type.ts';
import { BaseEditor } from '../types/Editor.base.type.ts';
import { Api, Contract } from '../types/Editor.contract.type.ts';

export const useEditorApi = <TPlugin extends Contract, TEditor extends BaseEditor = BaseEditor>(
  api: WithApiParameter<Api<TPlugin>>,
): {
  readonly getApiMethods: GetApiMethods<TPlugin, TEditor>;
} => ({
  getApiMethods: useCallback(() => api, [api]),
});
