import React from 'react';
import { DragSource } from '../../../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DropTarget } from '../../../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../../../../_shared/constants/dndTypes.ts';
import { isWithinTargetInset20 } from '../../../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { IAssetValidationSettings } from '../../../containers/elements/asset/AssetTile.tsx';
import { AssetTileWithAutoScroll } from '../../../containers/elements/asset/AssetTileWithAutoScroll.tsx';
import { AssetTileAction } from './AssetTileActions.tsx';

interface IDraggableAssetProps {
  readonly assetId: Uuid;
  readonly renditionId?: Uuid;
  readonly contentComponentId: Uuid | null;
  readonly disabled: boolean;
  readonly elementId: Uuid;
  readonly onOpenRenditionDialog: () => void;
  readonly onDragEnd: () => void;
  readonly onDragStart: () => void;
  readonly onEdit: () => void;
  readonly onMove: DragMoveHandler;
  readonly onRemove?: () => void;
  readonly validationSettings: IAssetValidationSettings;
}

const assetActions: ReadonlyArray<AssetTileAction> = [
  AssetTileAction.Download,
  AssetTileAction.Delete,
  AssetTileAction.Edit,
  AssetTileAction.AddComment,
  AssetTileAction.OpenRendition,
];

export const DraggableAsset: React.FC<IDraggableAssetProps> = ({
  assetId,
  renditionId,
  contentComponentId,
  disabled,
  elementId,
  onOpenRenditionDialog,
  onDragEnd,
  onDragStart,
  onEdit,
  onMove,
  onRemove,
  validationSettings,
}) => {
  // setting different ids for different asset elements
  // it ensures that assets are not draggable/droppable in context of one rich text element between multiple asset elements
  const assetContentDndIdentifier = `${DndTypes.Asset_Item_Element_Dnd_Identifier}_${contentComponentId}`;

  return (
    <DropTarget<HTMLDivElement>
      targetId={assetId}
      onMove={onMove}
      canDrop={!disabled}
      parentId={elementId}
      accept={assetContentDndIdentifier}
      hoveringCollisionStrategy={isWithinTargetInset20}
      renderDroppable={(ref) => (
        <div ref={ref} className="col-sm-12 col-md-8 col-lg-8 asset-tile-wrapper">
          <DragSource
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            parentId={elementId}
            sourceId={assetId}
            type={assetContentDndIdentifier}
            renderDraggable={(connectDragSource, isDragging) => (
              <AssetTileWithAutoScroll
                connectDragSource={connectDragSource}
                isDragging={isDragging}
                disabled={disabled}
                assetId={assetId}
                renditionId={renditionId}
                elementId={elementId}
                contentComponentId={contentComponentId}
                onOpenRenditionDialog={onOpenRenditionDialog}
                onRemove={disabled ? undefined : onRemove}
                onEdit={onEdit}
                actions={assetActions}
                validationSettings={validationSettings}
              />
            )}
            renderPreview={() => (
              <AssetTileWithAutoScroll
                isDragging={false}
                disabled={disabled}
                assetId={assetId}
                elementId={elementId}
                contentComponentId={contentComponentId}
                actions={assetActions}
                validationSettings={validationSettings}
              />
            )}
          />
        </div>
      )}
    />
  );
};

DraggableAsset.displayName = 'DraggableAsset';
