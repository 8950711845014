import { Collection } from '@kontent-ai/utils';
import { Dispatch, GetState } from '../../../../../../@types/Dispatcher.type.ts';
import { Task, TaskStatus } from '../../../../../../_shared/models/Task.ts';
import { ContentItemEditing_HighlightOpenTasks_Finished } from '../../constants/contentItemEditingActionTypes.ts';

const tasksHighlighted = (currentPath: string, taskIds: UuidArray) =>
  ({
    type: ContentItemEditing_HighlightOpenTasks_Finished,
    payload: {
      currentPath,
      taskIds,
    },
  }) as const;

export type HighlightOpenTasksActionsType = ReturnType<typeof tasksHighlighted>;

export const highlightOpenTasks =
  (currentPath: string) => (dispatch: Dispatch, getState: GetState) => {
    const openTasks = Collection.getValues(getState().data.tasks.byId)
      .filter((t: Task) => t.status === TaskStatus.Open)
      .map((t: Task) => t.id);
    dispatch(tasksHighlighted(currentPath, openTasks));
  };
