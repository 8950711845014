import { memoize } from '@kontent-ai/memoization';
import { CharacterMetadata, ContentBlock, ContentState, Modifier, SelectionState } from 'draft-js';
import Immutable from 'immutable';
import { CommentThreadState } from '../../../../itemEditor/types/CommentThreadState.ts';
import {
  CustomBlockSleevePosition,
  getCustomBlockSleevePosition,
  isContentModule,
  isImage,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { RichTextCommentSegment, findBlockIndex } from '../../../utils/blocks/editorBlockUtils.ts';
import {
  createSelection,
  doesSelectionContainText,
  getMetadataAtSelection,
  isSelectionWithinOneBlock,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  setBlockData,
} from '../../../utils/general/editorContentUtils.ts';
import { getMetadataForBlockChars } from '../../../utils/metadata/editorMetadataUtils.ts';
import { IComponentPathItem } from '../../contentComponents/api/editorContentComponentUtils.ts';
import { findShortestStyle, modifyStyle } from '../../inlineStyles/api/editorStyleUtils.ts';
import {
  CommentStyleIdPrefix,
  getCommentInlineStyle,
  getCommentSegmentId,
  getCommentStyle,
  isCommentIdStyle,
} from './editorCommentStyleUtils.ts';

export type CommentChanges = {
  readonly newlyRemovedComments: ReadonlyArray<Uuid>;
  readonly newlyResolvedComments: ReadonlyArray<Uuid>;
  readonly newlyUnresolvedComments: ReadonlyArray<Uuid>;
};

export type RichTextBlockCommentData = {
  readonly componentId: Uuid | null;
  readonly externalSegmentId: Uuid;
};

export function isAtCommentEdge(block: ContentBlock, index: number): boolean {
  const beforeStyles = block.getInlineStyleAt(index - 1);
  const beforeCommentStyles = beforeStyles && getCommentStyle(beforeStyles).toSet();

  const currentStyles = block.getInlineStyleAt(index);
  const currentCommentStyles = currentStyles && getCommentStyle(currentStyles).toSet();

  return !beforeCommentStyles.equals(currentCommentStyles);
}

// GENERAL UTILS
export function findCommentChanges(
  oldThreadIdStateMapping: ReadonlyMap<Uuid, CommentThreadState>,
  newThreadIdStateMapping: ReadonlyMap<Uuid, CommentThreadState>,
): CommentChanges | null {
  if (newThreadIdStateMapping === oldThreadIdStateMapping) {
    return null;
  }

  const removedComments = Array<Uuid>();
  const resolvedComments = Array<Uuid>();
  const unresolvedComments = Array<Uuid>();

  oldThreadIdStateMapping.forEach((threadState: CommentThreadState, segmentId: Uuid) => {
    if (threadState === CommentThreadState.Unsaved) {
      if (!newThreadIdStateMapping.get(segmentId)) {
        removedComments.push(segmentId);
      }
    }

    if (
      threadState === CommentThreadState.Unresolved &&
      newThreadIdStateMapping.get(segmentId) === CommentThreadState.Resolved
    ) {
      resolvedComments.push(segmentId);
    }

    if (
      threadState === CommentThreadState.Resolved &&
      newThreadIdStateMapping.get(segmentId) === CommentThreadState.Unresolved
    ) {
      unresolvedComments.push(segmentId);
    }
  });

  if (
    removedComments.length === 0 &&
    resolvedComments.length === 0 &&
    unresolvedComments.length === 0
  ) {
    return null;
  }

  return {
    newlyResolvedComments: resolvedComments,
    newlyUnresolvedComments: unresolvedComments,
    newlyRemovedComments: removedComments,
  };
}

function isUnresolvedComment(
  style: string,
  threads: ReadonlyMap<Uuid, CommentThreadState>,
): boolean {
  if (style && isCommentIdStyle(style)) {
    const threadSegmentId = getCommentSegmentId(style);

    const state = threads.get(threadSegmentId);
    return !!state && state !== CommentThreadState.Resolved;
  }

  return false;
}

function getActiveCommentStylesAtPosition(
  threads: ReadonlyMap<Uuid, CommentThreadState>,
  block: ContentBlock,
  caretPosition: number,
): ReadonlyArray<string> | null {
  const metadataAtPosition = getMetadataForBlockChars(block, caretPosition - 1, caretPosition + 1);
  if (!metadataAtPosition) {
    return null;
  }

  const activeTopLevelCommentStyles =
    metadataAtPosition.styleAtAnyTopLevelChars
      ?.filter((style: string) => isUnresolvedComment(style, threads))
      .toArray() ?? [];
  const activeTableCommentStyles =
    metadataAtPosition.styleAtAnyTableChars
      ?.filter((style: string) => isUnresolvedComment(style, threads))
      .toArray() ?? [];

  const activeCommentStyles = [...activeTopLevelCommentStyles, ...activeTableCommentStyles];

  return activeCommentStyles.length ? activeCommentStyles : null;
}

function getSelectedThreadSegmentIdFromText(
  content: ContentState,
  selection: SelectionState,
  threads: ReadonlyMap<Uuid, CommentThreadState>,
): Uuid | null {
  if (!selection.isCollapsed()) {
    return null;
  }

  const block = content.getBlockForKey(selection.getStartKey());
  const caretPosition = selection.getStartOffset();

  const candidateCommentStyles = getActiveCommentStylesAtPosition(threads, block, caretPosition);
  if (!candidateCommentStyles) {
    return null;
  }

  const closestCommentStyle = findShortestStyle(candidateCommentStyles, block, caretPosition);
  if (!closestCommentStyle) {
    return null;
  }

  const commentThreadSegmentId = getCommentSegmentId(closestCommentStyle);
  return commentThreadSegmentId;
}

function getSelectedThreadSegmentIdFromCustomBlock(
  content: ContentState,
  selection: SelectionState,
  threads: ReadonlyMap<Uuid, CommentThreadState>,
): Uuid | null {
  if (!selection.isCollapsed()) {
    return null;
  }

  const blocks = getBlocks(content);
  const blockIndex = findBlockIndex(blocks, selection.getStartKey());
  const customBlockSleevePosition = getCustomBlockSleevePosition(blockIndex, blocks);
  if (customBlockSleevePosition === CustomBlockSleevePosition.None) {
    return null;
  }

  const customBlockOffset =
    customBlockSleevePosition === CustomBlockSleevePosition.BeforeOwner ? 1 : -1;
  const commentSegmentId =
    getCommentSegmentIdFromBlock(blocks[blockIndex + customBlockOffset]) ?? null;

  const state = commentSegmentId && threads.get(commentSegmentId);
  return !!state && state !== CommentThreadState.Resolved ? commentSegmentId : null;
}

export const getSelectedThreadSegmentId = (
  content: ContentState,
  selection: SelectionState,
  threads: ReadonlyMap<Uuid, CommentThreadState>,
): Uuid | null =>
  getSelectedThreadSegmentIdFromCustomBlock(content, selection, threads) ??
  getSelectedThreadSegmentIdFromText(content, selection, threads);

export function addCommentToText(
  input: IContentChangeInput,
  segmentId: Uuid,
): IContentChangeResult {
  const { content, selection } = input;

  if (selection.isCollapsed()) {
    return input;
  }

  const metadataAtSelection = getMetadataAtSelection(content, selection);
  if (!doesSelectionContainText(selection, metadataAtSelection)) {
    return input;
  }

  const withComment = Modifier.applyInlineStyle(
    content,
    selection,
    getCommentInlineStyle(segmentId),
  );

  const isOverlappingEndOfBlockSelected =
    selection.getEndOffset() === 0 && !isSelectionWithinOneBlock(selection);
  const secondToLastBlock = content.getBlockBefore(selection.getEndKey());

  const newSelection =
    secondToLastBlock && isOverlappingEndOfBlockSelected
      ? createSelection(
          selection.getStartKey(),
          selection.getStartOffset(),
          secondToLastBlock.getKey(),
          secondToLastBlock.getLength(),
        )
      : selection;
  const newContent = setContentSelection(withComment, selection, newSelection);

  return {
    wasModified: true,
    content: newContent,
    selection: newSelection,
  };
}

export function syncCommentStyles(
  input: IContentChangeInput,
  commentsChanges: CommentChanges,
): IContentChangeResult {
  const withRemoved = commentsChanges.newlyRemovedComments.reduce(
    (reductionInput: IContentChangeInput, removedId: Uuid) => {
      return removeComment(reductionInput, removedId);
    },
    input,
  );

  return withRemoved;
}

function removeComment(input: IContentChangeInput, segmentId: Uuid): IContentChangeResult {
  return modifyStyle(
    input,
    (c) => c.hasStyle(CommentStyleIdPrefix + segmentId),
    [CommentStyleIdPrefix + segmentId],
    [],
  );
}

export const setBlockCommentSegmentId = (
  input: IContentChangeInput,
  blockKey: string,
  segmentId: Uuid,
): IContentChangeResult => {
  const block = input.content.getBlockForKey(blockKey);
  const updatedData = block.getData().set(RichTextCommentSegment, segmentId);

  return setBlockData(input, blockKey, updatedData);
};

export const getBlockCommentSegmentId = (
  content: ContentState,
  blockKey: string,
): Uuid | undefined => getCommentSegmentIdFromBlock(content.getBlockForKey(blockKey));

export const getCommentSegmentIdFromBlock = (block: ContentBlock | undefined): Uuid | undefined =>
  block?.getData().get(RichTextCommentSegment) ?? null;

export const createBlockCommentData = (
  contentComponentId: Uuid | null,
  externalSegmentId: Uuid,
): RichTextBlockCommentData => ({
  componentId: contentComponentId,
  externalSegmentId,
});

export interface ICommentThreadSegment {
  readonly segmentId: Uuid;
  readonly componentPath: ReadonlyArray<IComponentPathItem> | null;
}

export const getBlockCommentThreadReferences = memoize.weak(
  (block: ContentBlock): ReadonlyArray<ICommentThreadSegment> => {
    const text = block.getText();
    const blockHasText = !!text;
    const blockIsImage = isImage(block);
    const blockIsLinkedItem = isContentModule(block);

    if (blockHasText || blockIsImage || blockIsLinkedItem) {
      const ids = Array<Uuid>();

      if (blockHasText) {
        let lastMetadata: CharacterMetadata | null = null;
        let lastStyles: Immutable.OrderedSet<string> | null = null;
        block.getCharacterList().forEach((c: CharacterMetadata, charIndex: number) => {
          // We can skip repetitive metadata because of CharacterMetadata pooling there are not too many variations typically
          if (c !== lastMetadata) {
            const styles = c.getStyle();
            // Same short-circuit for styles to reduce overhead
            if (styles !== lastStyles) {
              // KCL-13420 - We consider comments at non-highlightable chars as non-existing
              // as we have no way to tell the user to which content they relate
              if (isNonHighlightableChar(text[charIndex])) return;

              styles.forEach((style: string) => {
                if (isCommentIdStyle(style)) {
                  ids.push(getCommentSegmentId(style));
                }
              });

              lastStyles = styles;
            }
            lastMetadata = c;
          }
        });
      }

      if (blockIsImage || blockIsLinkedItem) {
        const segmentId = getCommentSegmentIdFromBlock(block);
        if (segmentId) {
          ids.push(segmentId);
        }
      }

      if (!ids.length) {
        return [];
      }

      const result = ids.map((id) => ({
        segmentId: id,
        componentPath: null,
      }));

      return result;
    }

    return [];
  },
);

// We include all zero width characters we are not able to highlight them in the editor
// \r\n   Standard new line characters
// \p{Cf} Format characters (e.g., zero-width space, zero-width non-joiner)
// \p{Mn} Non-spacing marks (e.g., combining diacritical marks)
// \p{Me} Enclosing marks (e.g., combining enclosing circle)
// \p{Zl} Line separators
// \p{Zp} Paragraph separators
const nonHighlightableCharRegex = /[\r\n\p{Cf}\p{Mn}\p{Me}\p{Zl}\p{Zp}]/u;

const isNonHighlightableChar = (char: string | undefined) =>
  !!char && nonHighlightableCharRegex.test(char);
