import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
} from '../../../constants/routePaths.ts';
import { buildPath } from '../../../utils/routing/routeTransitionUtils.ts';

type Props = {
  readonly subscriptionId: Uuid;
};

export const ExpiredSubscriptionWarningAction: React.FC<Props> = ({ subscriptionId }) => (
  <RouterLinkButton
    size="small"
    buttonStyle="primary-inverse"
    to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, { subscriptionId })}
  >
    Select a new plan
  </RouterLinkButton>
);

ExpiredSubscriptionWarningAction.displayName = 'ExpiredSubscriptionWarningAction';
