import { memoize } from '@kontent-ai/memoization';
import { EntityInstance } from 'draft-js';
import { LinkData } from '../../links/api/LinkData.type.ts';

export enum EntityType {
  Link = 'LINK',
  Mention = 'MENTION',
  AiInstruction = 'AI-INSTRUCTION',
}

export const getEntityType = memoize.weak(
  (entity: EntityInstance): EntityType => entity.getType() as any,
);

export enum EntityMutability {
  Immutable = 'IMMUTABLE',
  Mutable = 'MUTABLE',
  Segmented = 'SEGMENTED',
}

export type EntityData = LinkData;

export interface Entity<TData extends AnyObject> extends Omit<EntityInstance, 'getData'> {
  getData(): TData;
}
