import { useAttachRef } from '@kontent-ai/hooks';
import { usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { colorIconDefault, colorIconDisabled } from '../../../tokens/decision/colors.ts';
import { spacingInputLarge } from '../../../tokens/decision/spacing.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Chevron } from '../../Chevron/Chevron.tsx';
import { inputBorderRadius, inputMinHeight } from '../../Input/components/tokens.ts';
import { TriggerProps } from '../SingleSelect/useSingleSelect.ts';

const StyledButton = styled.button`
  align-self: start;
  
  height: ${px(inputMinHeight)};
  padding-left: ${px(Spacing.M)};
  padding-right: ${px(spacingInputLarge)};
  border: none;
  border-radius: 0 ${inputBorderRadius} ${inputBorderRadius} 0;
  margin-right: ${px(-1 * spacingInputLarge)};

  background: none;

  // override normalize.less styles
  && {
    cursor: ${({ disabled }) => disabled && 'not-allowed'};
  }
`;
export const ChevronTrigger = React.forwardRef<HTMLButtonElement, TriggerProps>(
  ({ isDisabled, isOpen, openMenu, closeMenu, children, ...otherProps }, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { pressProps } = usePress({
      ref: refObject,
      isDisabled,
      preventFocusOnPress: true,
    });

    // We need to use 'onClick' because 'onPress' from 'usePress' hook above isn't called when another component's overlay is open
    // (e.g. menu in another MultiSelect)
    const onClick: MouseEventHandler = () => {
      if (isOpen) {
        closeMenu();
      } else {
        openMenu();
      }
    };

    return (
      <StyledButton
        aria-label="Show suggestions"
        disabled={isDisabled}
        ref={refToForward}
        tabIndex={-1}
        type="button"
        {...mergeProps(pressProps, { onClick }, otherProps)}
      >
        <Chevron
          size={IconSize.S}
          color={isDisabled ? colorIconDisabled : colorIconDefault}
          direction={isOpen ? 'up' : 'down'}
        />
      </StyledButton>
    );
  },
);

ChevronTrigger.displayName = 'ChevronTrigger';
