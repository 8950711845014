import { combineReducers } from '@reduxjs/toolkit';
import { byId } from './reducers/byId.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';
import { snippetUsage } from './reducers/snippetUsage.ts';

export const snippets = combineReducers({
  byId,
  loadingStatus,
  snippetUsage,
});
