import { getAvailableAssignedContributors } from '../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { FormValidatorConfig } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { IContributorsFormShape } from '../models/IContributorsFormShape.type.ts';
import { getContributorsValidationErrorMessage } from '../utils/workflow/workflowValidationMessages.ts';

type Props = {
  readonly availableContributors: ReadonlyArray<IProjectContributor>;
  readonly workflowStepName: string;
};

const validateContributors = (
  value: ReadonlyArray<IProjectContributor> | undefined,
  _form: IContributorsFormShape,
  props: Props,
) => {
  const availableAssigned = value
    ? getAvailableAssignedContributors(value, props.availableContributors)
    : [];
  const hasAssignedAvailableContributor = !!availableAssigned.length;

  return hasAssignedAvailableContributor
    ? undefined
    : getContributorsValidationErrorMessage(props.workflowStepName);
};

export const contributorsFormBaseValidationConfig: FormValidatorConfig<
  IContributorsFormShape,
  Props
> = {
  contributors: [validateContributors],
};
