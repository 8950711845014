import { Action } from '../../../../../@types/Action.type.ts';
import {
  Environment_CreateEnvironment_CloseDialog,
  Environment_CreateEnvironment_Finished,
  Environment_CreateEnvironment_ShowDialog,
  Environment_DeleteEnvironment_CloseDialog,
  Environment_DeleteEnvironment_Finished,
  Environment_DeleteEnvironment_ShowDialog,
  Environment_SwapEnvironment_CloseDialog,
  Environment_SwapEnvironment_Finished,
  Environment_SwapEnvironment_ShowDialog,
} from '../../constants/environmentActionTypes.ts';

export interface IDialogsVisibilityState {
  readonly createDialog: boolean;
  readonly deleteDialog: boolean;
  readonly swapDialog: boolean;
}

const initialState: IDialogsVisibilityState = {
  createDialog: false,
  deleteDialog: false,
  swapDialog: false,
};

export const showDialogs = (
  state: IDialogsVisibilityState = initialState,
  action: Action,
): IDialogsVisibilityState => {
  switch (action.type) {
    case Environment_CreateEnvironment_ShowDialog:
      return {
        ...initialState,
        createDialog: true,
      };

    case Environment_DeleteEnvironment_ShowDialog:
      return {
        ...initialState,
        deleteDialog: true,
      };

    case Environment_SwapEnvironment_ShowDialog:
      return {
        ...initialState,
        swapDialog: true,
      };

    case Environment_CreateEnvironment_CloseDialog:
    case Environment_CreateEnvironment_Finished:
    case Environment_DeleteEnvironment_CloseDialog:
    case Environment_DeleteEnvironment_Finished:
    case Environment_SwapEnvironment_CloseDialog:
    case Environment_SwapEnvironment_Finished:
      return initialState;

    default:
      return state;
  }
};
