import { Collection } from '@kontent-ai/utils';
import { UserListingConfigStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { ByStatus } from '../_shared/constants/userListingFilter.ts';
import { OrderByDirection } from '../_shared/models/OrderBy.ts';
import { UserListingColumnCode } from '../applications/environmentSettings/users/reducers/IUsersAppState.type.ts';
import { defaultUserFilter } from '../applications/environmentSettings/users/reducers/internalReducers/filter.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

export interface IUserListingConfig {
  readonly collectionsFilter: ReadonlyArray<Uuid>;
  readonly languagesFilter: ReadonlyArray<Uuid>;
  readonly rolesFilter: ReadonlyArray<Uuid>;
  readonly sortByDirection: OrderByDirection;
  readonly sortByField: UserListingColumnCode;
  readonly statusFilter: ByStatus;
  readonly userNameFilter: string;
}

const initialState: IUserListingConfig = {
  collectionsFilter: Collection.getValues(defaultUserFilter.byCollection),
  languagesFilter: Collection.getValues(defaultUserFilter.byLanguage),
  rolesFilter: Collection.getValues(defaultUserFilter.byRoles),
  sortByDirection: OrderByDirection.Ascending,
  sortByField: UserListingColumnCode.LastName,
  statusFilter: defaultUserFilter.byStatus,
  userNameFilter: defaultUserFilter.byName,
};

const isAllowedColumn = (
  rawColumn: string | undefined | null,
): rawColumn is UserListingColumnCode =>
  !!rawColumn && Object.values(UserListingColumnCode).includes(rawColumn as UserListingColumnCode);

const parseSortColumn = (rawColumn: string | undefined | null): UserListingColumnCode =>
  isAllowedColumn(rawColumn) ? rawColumn : initialState.sortByField;

const userListingConfigStorage: StorageParams<IUserListingConfig> = {
  key: UserListingConfigStorageKey,
  initialState,
  fromJS: (x: any) => ({
    collectionsFilter: x.collectionsFilter || initialState.collectionsFilter,
    languagesFilter: x.languagesFilter || initialState.languagesFilter,
    rolesFilter: x.rolesFilter || initialState.rolesFilter,
    sortByDirection: x.sortByDirection || initialState.sortByDirection,
    sortByField: parseSortColumn(x.sortByField),
    statusFilter: x.statusFilter || initialState.statusFilter,
    userNameFilter: x.userNameFilter || initialState.userNameFilter,
  }),
};

export const UserListingConfigStorage =
  getProjectSpecificStorage<IUserListingConfig>(userListingConfigStorage);
