import { combineReducers } from '@reduxjs/toolkit';
import { allIds } from './allIds.ts';
import { byId } from './byId.ts';
import { loadingStatus } from './loadingStatus.ts';
import { nextContinuationToken } from './nextContinuationToken.ts';
import { raceConditionToken } from './raceConditionToken.ts';

export const contentStatusContentItems = combineReducers({
  allIds,
  byId,
  loadingStatus,
  nextContinuationToken,
  raceConditionToken,
});
