import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { Content_LoadedItems_DefaultListingItemsLoaded } from '../../../../applications/contentInventory/content/constants/contentActionTypes.ts';
import { LocalizedRouteLeft } from '../../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { ContentEditing_CascadeModal_ExpandNodeFinished } from '../../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { IListingContentItem } from '../../../models/listingContentItems/IListingContentItem.ts';

const initialState: Immutable.Map<Uuid, IListingContentItem> = Immutable.Map();

const addAllToState = (
  state: Immutable.Map<Uuid, IListingContentItem>,
  newItems: ReadonlyArray<IListingContentItem>,
): Immutable.Map<Uuid, IListingContentItem> =>
  state.withMutations((newState) =>
    newItems.forEach((contentItem) => newState.set(contentItem.item.id, contentItem)),
  );

export function defaultById(
  state: Immutable.Map<Uuid, IListingContentItem> = initialState,
  action: Action,
): Immutable.Map<Uuid, IListingContentItem> {
  switch (action.type) {
    case Content_LoadedItems_DefaultListingItemsLoaded: {
      const { contentItems } = action.payload;
      if (contentItems === null) {
        return state;
      }

      return addAllToState(state, contentItems);
    }

    case ContentEditing_CascadeModal_ExpandNodeFinished: {
      const { allChildNodes } = action.payload;

      const [, defaultVariantChildItems] = Array.from(allChildNodes.entries()).find(
        ([variantId]) => variantId === DefaultVariantId,
      ) ?? ['', []];

      return defaultVariantChildItems.length
        ? addAllToState(state, defaultVariantChildItems)
        : state;
    }

    case LocalizedRouteLeft:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
