import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { ContentItemFilterServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { ContentItemListingOperationQueryModel } from '../models/filter/ContentItemListOperationQueryModel.type.ts';
import { IListingFilter } from '../models/filter/IListingFilter.ts';
import {
  getItemCompletionStatus,
  getPublishingState,
  getTaxonomyGroupItemFilterServerModel,
} from '../models/filter/contentItemFilterUtils.ts';
import { getSelectedItems } from '../reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';

export const createContentItemFilterServerModelFromFilter = (
  filter: IListingFilter,
): ContentItemFilterServerModel => {
  const result: ContentItemFilterServerModel = {};
  if (filter.selectedContributorsNodes && filter.selectedContributorsNodes.size > 0) {
    result.assignees = [...filter.selectedContributorsNodes];
  }
  const searchPhrase = filter.searchPhrase.trim();
  if (searchPhrase) {
    result.searchParameters = {
      phrase: searchPhrase,
      scope: Array.from(filter.searchScope),
    };
  }
  const publishingState = getPublishingState(filter.selectedPublishingStateNodes);
  if (publishingState) {
    result.publishingStates = publishingState;
  }
  const itemCompletionStatus = getItemCompletionStatus(filter.selectedContentItemStatus);
  if (itemCompletionStatus) {
    result.itemCompletionStatuses = itemCompletionStatus;
  }
  if (filter.selectedSitemapNodes && filter.selectedSitemapNodes.size > 0) {
    result.sitemapLocations = [...filter.selectedSitemapNodes];
  }
  if (filter.selectedTaxonomyNodes.size > 0) {
    result.taxonomiesByGroupId = Object.fromEntries(
      Collection.getEntries(filter.selectedTaxonomyNodes).map(([groupId, termIds]) => [
        groupId,
        getTaxonomyGroupItemFilterServerModel([...termIds]),
      ]),
    );
  }
  if (filter.selectedCollectionsNodes.size > 0) {
    result.collectionIds = [...filter.selectedCollectionsNodes];
  }
  if (filter.selectedSpacesNodes.size > 0) {
    result.spaceIds = [...filter.selectedSpacesNodes];
  }
  if (filter.selectedContentTypesNodes.size > 0) {
    result.typeIds = [...filter.selectedContentTypesNodes];
  }
  if (filter.selectedWorkflowNodes.size > 0) {
    result.workflows = Object.fromEntries(
      Collection.getEntries(filter.selectedWorkflowNodes).map(([workflowId, stepIds]) => [
        workflowId,
        [...stepIds],
      ]),
    );
  }

  return result;
};

export const createGetItemListingOperationQueryFromFilter =
  (deps: {
    readonly getSelectedItems: (state: IStore) => Immutable.Set<Uuid>;
  }) =>
  (state: IStore): ContentItemListingOperationQueryModel => {
    const {
      contentApp: {
        listingUi: {
          itemsSelection: { selectAll, unselectedItemIds, selectedItemIds },
          filter,
        },
      },
    } = state;

    // if user explicitly selects some items, we ignore the rest of the filter
    if (!selectAll && !selectedItemIds.isEmpty()) {
      return { includedItemIds: selectedItemIds.toArray() };
    }

    // If full-text is used, bulk operations need to be performed for specific IDs
    if (selectAll && !isEmptyOrWhitespace(filter.searchPhrase)) {
      const materializedSelectedItemIds = deps.getSelectedItems(state);
      return { includedItemIds: materializedSelectedItemIds.toArray() };
    }

    const result: ContentItemListingOperationQueryModel =
      createContentItemFilterServerModelFromFilter(filter);

    if (selectAll) {
      result.excludedItemIds = unselectedItemIds.toArray();
    }
    return result;
  };

export const getItemListingOperationQueryFromFilter = createGetItemListingOperationQueryFromFilter({
  getSelectedItems,
});
