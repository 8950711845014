import Immutable from 'immutable';
import { ProjectMenuItem } from '../../components/ProjectMenu/selects/ProjectSelect.tsx';
import {
  ProjectRouteParams,
  ProjectSettingsRoute,
  ProjectsRoute,
} from '../../constants/routePaths.ts';
import { getProjectsForMenu } from '../../selectors/getProjectsForMenu.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { DataUiAppName, getDataUiNavAttribute } from '../dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../routing/routeTransitionUtils.ts';

export type ProjectOption = {
  readonly link: string;
  readonly masterEnvironmentId: Uuid;
  readonly planIsExpired: boolean;
  readonly planIsSuspended: boolean;
  readonly projectContainerId: Uuid;
  readonly projectName: string;
};

export const myProjectsItemId = 'projects-pinned';

export const getProjects = (state: IStore, pathname: string): Immutable.List<ProjectOption> => {
  const {
    data: {
      user: { projectsInfoById },
    },
    projectsApp: { projectsUiTransitionState },
  } = state;

  return getProjectsForMenu(projectsInfoById, projectsUiTransitionState, pathname, state);
};

export const getProjectItems = (
  projects: Immutable.List<ProjectOption>,
  currentContainerId: string,
): ReadonlyArray<ProjectMenuItem> =>
  projects
    .map(
      (p): ProjectMenuItem => ({
        dataAttributes: getDataUiNavAttribute(p?.projectName as DataUiAppName),
        id: p.masterEnvironmentId,
        isPlanExpired: p.planIsExpired,
        isPlanSuspended: p.planIsSuspended,
        isSelected: p?.projectContainerId === currentContainerId,
        label: p.projectName,
        link: p.link,
        settingsEnabled: true,
        settingsLink: getProjectSettingsPath(p?.projectContainerId),
        type: 'item',
      }),
    )
    .toArray()
    .concat([
      {
        dataAttributes: getDataUiNavAttribute(DataUiAppName.Projects),
        id: myProjectsItemId,
        isPlanExpired: false,
        isPlanSuspended: false,
        isSelected: false,
        label: 'Projects',
        link: getProjectListingPath(),
        settingsEnabled: false,
        settingsLink: '',
        type: 'item',
      },
    ]);

export const getProjectSettingsPath = (id: string) =>
  id ? buildPath<ProjectRouteParams>(ProjectSettingsRoute, { projectContainerId: id }) : '';

const getProjectListingPath = () => buildPath(ProjectsRoute, {});
