import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getUrlSlug } from '../../itemEditor/utils/previewUtils.ts';

export function useIsPreviewUrlOutdated(): boolean {
  const itemPreviewInfo = useSelector((state) => state.webSpotlightApp.itemPreviewInfo);
  const editedItemUrlSlug = useSelector(
    (state) => getUrlSlug(state.contentApp.editedContentItemVariantElements) || null,
  );
  const editedItemId = useSelector((state) => state.contentApp.editedContentItem?.id);

  const previewItemUrlSlug = itemPreviewInfo?.previewUrlInfo.usedUrlSlug || null;
  const isPreviewItemAlsoEditedItem =
    itemPreviewInfo?.previewContentItemInfo?.contentItemId === editedItemId;
  const isNotSameUrlSlug = !!previewItemUrlSlug && previewItemUrlSlug !== editedItemUrlSlug;

  return isPreviewItemAlsoEditedItem && isNotSameUrlSlug;
}
