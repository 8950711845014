import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { usePrevious } from '@kontent-ai/hooks';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import {
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  UnableToCloneSelectedProjectAdviceMessage,
  UnableToCloneSelectedProjectMessage,
  UnableToCreateNewProjectAdviceMessage,
  UnableToCreateNewProjectMessage,
} from '../../../constants/UIConstants.ts';
import { ICopyProjectErrors } from '../../../models/ICopyProjectValidationData.type.ts';

type Props = {
  readonly errorsConfig: ICopyProjectErrors;
  readonly isCreatingEmptyProject?: boolean;
  readonly subscriptionId: Uuid;
  readonly subscriptionName: string;
};

const CloneProjectMessage = ({
  errorsConfig,
  isCreatingEmptyProject,
  subscriptionId,
  subscriptionName,
}: Props) => {
  if (errorsConfig.secureDeliveryApiViolated) {
    return (
      <span>
        To clone the project, select a subscription that supports secure delivery access or turn it
        off in the project.
      </span>
    );
  }

  if (errorsConfig.collectionsRestrictionViolated) {
    return (
      <span>
        To clone the project, select a subscription that supports collections or remove your
        collections from the project.
      </span>
    );
  }

  if (
    errorsConfig.customElementsRestrictionViolated &&
    !errorsConfig.maxCustomRolesExceeded &&
    !errorsConfig.maxLanguagesExceeded
  ) {
    return (
      <>
        <span>To clone the project, remove custom elements or upgrade your subscription for </span>
        <Link
          to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, { subscriptionId })}
          {...getDataUiActionAttribute(DataUiAction.EditSubscription)}
        >
          {subscriptionName}
        </Link>
        <span>.</span>
      </>
    );
  }

  if (errorsConfig.maxSubscriptionProjectsReached) {
    const adviceMessage = isCreatingEmptyProject
      ? UnableToCreateNewProjectAdviceMessage(subscriptionName)
      : UnableToCloneSelectedProjectAdviceMessage(subscriptionName);
    return <span>{adviceMessage}</span>;
  }

  return (
    <>
      <span>To clone the project, upgrade your subscription for </span>
      <Link
        to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, { subscriptionId })}
        {...getDataUiActionAttribute(DataUiAction.EditSubscription)}
      >
        {subscriptionName}
      </Link>
      <span>.</span>
    </>
  );
};

export const CloneProjectErrors = ({
  errorsConfig,
  subscriptionName,
  subscriptionId,
  isCreatingEmptyProject,
}: Props) => {
  const cloneProjectErrorsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    cloneProjectErrorsRef.current?.scrollIntoView(true);
  }, []);

  const previousErrorsConfig = usePrevious(errorsConfig);
  useEffect(() => {
    if (previousErrorsConfig !== errorsConfig) {
      cloneProjectErrorsRef.current?.scrollIntoView(true);
    }
  }, [errorsConfig, previousErrorsConfig]);

  const errorDescription = isCreatingEmptyProject
    ? UnableToCreateNewProjectMessage
    : UnableToCloneSelectedProjectMessage;

  return (
    <div className="create-project-sidebar__increase-warning" ref={cloneProjectErrorsRef}>
      <Callout calloutType="warning" headline="Limitations exceeded">
        <p>{errorDescription}</p>
        <ul>
          {errorsConfig.collectionsRestrictionViolated && (
            <li>
              The project uses collections, which are not supported in the selected subscription
            </li>
          )}
          {errorsConfig.customElementsRestrictionViolated && (
            <li>The selected subscription doesn’t support Custom elements</li>
          )}
          {errorsConfig.maxCustomRolesExceeded && (
            <li>Max. number of custom roles in the selected subscription exceeded</li>
          )}
          {errorsConfig.maxLanguagesExceeded && (
            <li>Max. number of languages in the selected subscription exceeded</li>
          )}
          {errorsConfig.maxSubscriptionProjectsReached && (
            <li>Max. number of projects in the selected subscription reached</li>
          )}
          {errorsConfig.secureDeliveryApiViolated && (
            <li>
              The project has{' '}
              <OutwardLink href={documentationLinks.secureAccess}>secure access</OutwardLink>{' '}
              enabled, which is not supported in the selected subscription.
            </li>
          )}
        </ul>
        <p>
          <CloneProjectMessage
            errorsConfig={errorsConfig}
            subscriptionId={subscriptionId}
            subscriptionName={subscriptionName}
            isCreatingEmptyProject={isCreatingEmptyProject}
          />
        </p>
      </Callout>
    </div>
  );
};
