import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import { forwardRef, useState } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { LanguageContextProvider } from '../../../../itemEditor/features/ContentComponent/context/LanguageContext.tsx';
import { ContentItemPaneElementsGroup } from '../../../../itemEditor/features/ContentItemEditing/components/ContentItemPaneElementsGroup.tsx';
import { ITaxonomyItemElement } from '../../../../itemEditor/models/contentItemElements/TaxonomyItemElement.ts';
import { createTaxonomyItemElement } from '../../../../itemEditor/utils/itemElementCreators/taxonomyItemElementCreator.ts';
import { ITaxonomyTypeElement } from '../../../content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { AssetElement } from '../AssetEditing/Elements/AssetElement.tsx';

export interface IAssignTermsAssetsDialogStateProps {
  readonly isOpen: boolean;
  readonly numberOfSelectedAssets: number;
  readonly taxonomyElements: ReadonlyArray<ITaxonomyTypeElement>;
}

export interface IAssignTermsAssetsDialogDispatchProps {
  readonly onAssignTaxonomies: (
    selectedTermsPerElement: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
  ) => void;
  readonly onClose: () => void;
}

type Props = IAssignTermsAssetsDialogStateProps & IAssignTermsAssetsDialogDispatchProps;

const modalDialogWidth = 100 * gridUnit;

export const AssignTermsAssetsDialog = forwardRef<HTMLDivElement, Props>(
  ({ isOpen, numberOfSelectedAssets, onAssignTaxonomies, onClose, taxonomyElements }, ref) => {
    const [selectedTaxonomyElements, setSelectedTaxonomyElements] = useState<
      ReadonlyMap<Uuid, ITaxonomyItemElement>
    >(new Map());

    const onTermSelectionChanged = (updatedElement: ITaxonomyItemElement): void => {
      setSelectedTaxonomyElements(
        Collection.add(selectedTaxonomyElements, [updatedElement.elementId, updatedElement]),
      );
    };

    const assignTerms = (): void => {
      onAssignTaxonomies(
        new Map(
          Collection.getValues(selectedTaxonomyElements).map((taxonomyElement) => [
            taxonomyElement.elementId,
            taxonomyElement.value,
          ]),
        ),
      );
      onClose();
    };

    const renderTaxonomyElement = (
      typeElement: ITaxonomyTypeElement,
      elementData: ITaxonomyItemElement,
    ) => (
      <AssetElement
        disabled={false}
        elementData={elementData}
        key={elementData.elementId}
        onChange={(updatedElement) => onTermSelectionChanged(updatedElement)}
        typeElement={typeElement}
      />
    );

    const getTaxonomyElement = (typeElement: ITaxonomyTypeElement): ITaxonomyItemElement =>
      selectedTaxonomyElements.get(typeElement.elementId) ??
      createTaxonomyItemElement(typeElement, true);

    const renderedTaxonomyElements = taxonomyElements.map((typeElement: ITaxonomyTypeElement) =>
      renderTaxonomyElement(typeElement, getTaxonomyElement(typeElement)),
    );

    const isDisabled = Collection.getValues(selectedTaxonomyElements).every(
      (taxonomyElement) => !taxonomyElement?.value?.size,
    );

    return (
      <LanguageContextProvider languageId={DefaultVariantId}>
        <ModalDialog
          headline={`Assign taxonomy terms to ${pluralizeWithCount(
            'asset',
            numberOfSelectedAssets,
          )}`}
          isOpen={isOpen}
          isDismissable
          minWidth={px(modalDialogWidth).toString()}
          withDividers
          primaryAction={{
            disabled: isDisabled,
            onClick: assignTerms,
            text: 'Assign terms',
            tooltipPlacement: 'top-end',
            tooltipText: isDisabled ? 'Select at least one term.' : '',
            ...getDataUiActionAttribute(DataUiAction.AssignTerms),
          }}
          cancelAction={{
            onClick: onClose,
            ...getDataUiActionAttribute(DataUiAction.CloseDialog),
          }}
          onClose={onClose}
          ref={ref}
          shouldCloseOnInteractOutside={() => false}
          {...getDataUiElementAttribute(DataUiElement.Dialog)}
        >
          <ContentItemPaneElementsGroup
            isDisabled={false}
            hasBottomRoundedCorners
            hasTopRoundedCorners
          >
            {renderedTaxonomyElements}
          </ContentItemPaneElementsGroup>
        </ModalDialog>
      </LanguageContextProvider>
    );
  },
);

AssignTermsAssetsDialog.displayName = 'AssignTermsAssetsDialog';
