import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Menu } from '@kontent-ai/component-library/Menu';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../../data/models/languages/Language.ts';

interface ICopyFromLanguageButtonProps {
  readonly disabledTooltipText?: string;
  readonly languages: ReadonlyArray<ILanguage>;
  readonly onCopyFrom: (variantId: Uuid) => void;
}

export const CopyFromLanguageButton: React.FC<ICopyFromLanguageButtonProps> = ({
  disabledTooltipText,
  languages,
  onCopyFrom,
}) => {
  const optionGroupTitle = 'Copy from language';

  const getTooltipText = (isOpen: boolean) => {
    if (disabledTooltipText) {
      return disabledTooltipText;
    }

    return isOpen ? '' : optionGroupTitle;
  };

  if (languages.length === 0) {
    return null;
  }

  return (
    <Menu>
      <Menu.Trigger>
        {(ref, triggerProps, isOpen) => {
          const { children, ...triggerPropsWithoutChildren } = triggerProps;
          return (
            <Box paddingLeft={Spacing.S}>
              <IconButton
                activated={isOpen}
                buttonStyle="tertiary"
                buttonState={disabledTooltipText ? 'disabled' : 'default'}
                iconName="DocCopy"
                ref={ref as RefObject<HTMLButtonElement>}
                size="medium"
                ariaLabel="Copy from another language"
                tooltipPlacement="top-end"
                tooltipText={getTooltipText(isOpen)}
                {...triggerPropsWithoutChildren}
                {...getDataUiActionAttribute(DataUiAction.CopyFromLanguage)}
              />
            </Box>
          );
        }}
      </Menu.Trigger>
      <Menu.List {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}>
        <Menu.Section label={optionGroupTitle} isFirst>
          {languages.map((language) => (
            <Menu.Item
              key={language.id}
              id={language.id}
              onAction={() => onCopyFrom(language.id)}
              label={language.name}
              {...getDataUiObjectNameAttribute(language.name)}
            />
          ))}
        </Menu.Section>
      </Menu.List>
    </Menu>
  );
};

CopyFromLanguageButton.displayName = 'CopyFromLanguageButton';
