import { Duration, format, isMatch, parse } from 'date-fns';
import { millisecondsInMinute, minutesInHour } from 'date-fns/constants';
import { cartesianMultiply, toArray } from '../../../utils/arrayUtils/arrayUtils.ts';
import { isEmptyOrWhitespace } from '../../../utils/stringUtils.ts';
import { currentLocale } from './localeUtils.ts';

type ParsedDateInfo =
  | {
      readonly value: Date;
      readonly hasTimeInfo: boolean;
      readonly isValid: true;
    }
  | {
      readonly value: null;
      readonly hasTimeInfo: false;
      readonly isValid: false;
    };

export type IsoFormattedDateTime = string | null | undefined;

export const DateTimeDefaultFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const DateTimeISOFormat = "yyyy-MM-dd'T'HH:mm:ssX";
export const DateTimeISOFormatWithMilliseconds = "yyyy-MM-dd'T'HH:mm:ss.SSSX";
const DateReadableFormat = 'PP';
const TimeReadableFormat = 'p';
const localeSpecificDateFormats = ['P', 'PP'];
export const dateFormats = [
  'yyyy-MM-dd',
  'd.M.yyyy',
  'dd.MM.yyyy',
  'dd.MMM.yyyy',
  'MMM d, yyyy',
  ...localeSpecificDateFormats,
];
const localeSpecificTimeFormats = ['p', 'pp'];
export const timeFormats = [
  'h:mm aaaaa',
  'h:mm aaaa',
  'h:mm aaa',
  'h:mm aa',
  'h:mm a',
  'hh:mm aaaaa',
  'hh:mm aaaa',
  'hh:mm aaa',
  'hh:mm aa',
  'hh:mm a',
  'H:mm',
  'HH:mm',
  ...localeSpecificTimeFormats,
];

const datetimeFormats = cartesianMultiply(dateFormats, timeFormats)
  .flatMap(([dateFormat, timeFormat]) => {
    return [`${dateFormat} ${timeFormat}`, `${dateFormat}, ${timeFormat}`];
  })
  .concat(
    [DateTimeISOFormat, DateTimeISOFormatWithMilliseconds, DateTimeDefaultFormat],
    dateFormats,
    timeFormats,
  );

export const parseDate = (input: string, dateFormat: string | readonly string[]): Date | null => {
  const formats = toArray(dateFormat);
  if (input) {
    const matchingFormat = formats.find((timeFormat) => isMatch(input, timeFormat));
    if (matchingFormat) {
      const useLocale = localeSpecificDateFormats.includes(matchingFormat);
      return parse(
        input,
        matchingFormat,
        new Date(),
        useLocale ? { locale: currentLocale } : undefined,
      );
    }
  }

  return null;
};

export const parseDatetime = (datetimeString: string | null): ParsedDateInfo => {
  if (datetimeString && !isEmptyOrWhitespace(datetimeString)) {
    const timelessDate = parseDate(datetimeString, dateFormats);
    if (timelessDate) {
      return {
        value: timelessDate,
        hasTimeInfo: false,
        isValid: true,
      };
    }

    const datetime = parseDate(datetimeString, datetimeFormats);
    if (datetime) {
      return {
        hasTimeInfo: true,
        value: datetime,
        isValid: true,
      };
    }
  }

  return {
    hasTimeInfo: false,
    isValid: false,
    value: null,
  };
};

export const parseTime = (
  timeString: string,
  formats: string | string[] = timeFormats,
): Date | null => {
  const formatArray = toArray(formats);
  const matchingFormat = formatArray.find((timeFormat) => isMatch(timeString, timeFormat));
  if (matchingFormat) {
    const useLocale = localeSpecificTimeFormats.includes(matchingFormat);
    return parse(
      timeString,
      matchingFormat,
      new Date(),
      useLocale ? { locale: currentLocale } : undefined,
    );
  }

  return null;
};

export const formatDateToReadable = (date: Date) => format(date, DateReadableFormat);

export const formatTimeToReadable = (date: Date) =>
  format(date, TimeReadableFormat, { locale: currentLocale });

export const formatDateTimeToReadable = (date: Date) =>
  `${formatDateToReadable(date)}, ${formatTimeToReadable(date)}`;

export const formatDateTimeToReadableWithAt = (date: Date) =>
  `${formatDateToReadable(date)} at ${formatTimeToReadable(date)}`;

export const shiftByUTCOffsetForward = (date: Date): Date =>
  new Date(date.getTime() + date.getTimezoneOffset() * millisecondsInMinute);

export const shiftByUTCOffsetBackwards = (date: Date): Date =>
  new Date(date.getTime() + date.getTimezoneOffset() * millisecondsInMinute * -1);

export const isDatetimeWithinBounds = (
  value: Date | null,
  minValue: Date | undefined,
  maxValue: Date | undefined,
): boolean => {
  if (!value) {
    return false;
  }
  if (!!minValue && value < minValue) {
    return false;
  }
  if (!!maxValue && value > maxValue) {
    return false;
  }

  return true;
};

export const formatLocalDateTimeToReadableUtcOffset = (local: Date): string => {
  const offset = local.getTimezoneOffset();

  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset / minutesInHour);
  const textualHours = offset >= 0 ? `-${hours}` : `+${hours}`;

  const minutes = absoluteOffset % minutesInHour;
  const textualMinutes = minutes ? `:${minutes}` : '';

  return `UTC${textualHours}${textualMinutes}`;
};

export const areParsedDateTimesEqual = (a: ParsedDateInfo, b: ParsedDateInfo): boolean => {
  return (
    a.isValid === b.isValid &&
    a.hasTimeInfo === b.hasTimeInfo &&
    a.value?.valueOf() === b.value?.valueOf()
  );
};

export const IncrementUpMinutes: Duration = {
  minutes: 5,
};

export const IncrementUpHours: Duration = {
  hours: 1,
};

export const IncrementDownMinutes: Duration = {
  minutes: -5,
};

export const IncrementDownHours: Duration = {
  hours: -1,
};
