import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

interface IDuplicateButtonStateProps {
  readonly customClassName?: string;
  readonly disabled?: boolean;
  readonly tooltipText: string;
}

interface IDuplicateButtonDispatchProps {
  readonly onDuplicate: () => void;
}

type DuplicateButtonProps = IDuplicateButtonStateProps & IDuplicateButtonDispatchProps;

export const DuplicateButton: React.FC<DuplicateButtonProps> = ({
  disabled,
  onDuplicate,
  tooltipText,
}) => (
  <Button
    tooltipText={tooltipText}
    tooltipPlacement="bottom-end"
    buttonStyle="secondary"
    size="medium"
    onClick={onDuplicate}
    disabled={disabled}
    {...getDataUiActionAttribute(DataUiAction.Duplicate)}
  >
    Duplicate
  </Button>
);

DuplicateButton.displayName = 'DuplicateButton';
