import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentItemEditing_Init_Ready,
  ContentItemEditing_VariantQuickActionsNotification_Close,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function isVariantEditedForTheFirstTime(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_Init_Ready: {
      return action.payload.isContentItemVariantJustCreated;
    }

    case ContentItemEditing_VariantQuickActionsNotification_Close: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
