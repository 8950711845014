import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_AdminListing_DeleteAdminsFailed,
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_AdminListing_DeleteAdminsStarted,
} from '../constants/actionTypes.ts';

export const deletingSubscriptionAdmins = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_AdminListing_DeleteAdminsStarted:
      return true;

    case Subscription_AdminListing_DeleteAdminsFinished:
    case Subscription_AdminListing_DeleteAdminsFailed:
      return false;

    default:
      return state;
  }
};
