import React from 'react';
import { AutoScrollProps } from '../../../../../../_shared/components/AutoScroll/autoScrollProps.type.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { LinkDetail } from '../../../../../itemEditor/utils/itemElementDataConverters/utils/LinkDetail.tsx';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { NoPermissionsContentLink } from './ContentLinkDetailVariants.tsx';
import { SpecificContentLink } from './SpecificContentLink.tsx';

export interface IContentLinkDetailOwnProps extends AutoScrollProps {
  readonly disabled?: boolean;
  readonly isViewOnly?: boolean;
  readonly onEdit?: () => void;
  readonly onUnlink?: () => void;
  readonly status?: EditorLinkStatus;
}

export interface IContentLinkDetailStateProps {
  readonly canCreateContent: boolean;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly cannotCreateDisabledMessage: string | undefined;
  readonly canViewContent: boolean;
  readonly contentItem?: IListingContentItem;
  readonly contentItemPath?: string;
  readonly contentTypeName: string | null;
}

type ContentLinkDetailProps = IContentLinkDetailOwnProps & IContentLinkDetailStateProps;

export const ContentLinkDetail: React.FC<ContentLinkDetailProps> = ({
  alternativeScrollIds,
  canCreateContent,
  cannotCreateDisabledMessage,
  cannotViewDisabledMessage,
  contentItem,
  contentItemPath,
  contentTypeName,
  disabled,
  isViewOnly,
  onEdit,
  onUnlink,
  scrollId,
  scrollOptions,
  status,
}) => {
  const canViewContent = useSelector(canUserViewAnyActiveLanguage);

  return (
    <LinkDetail
      disabled={disabled}
      onEdit={canViewContent ? onEdit : undefined}
      onUnlink={onUnlink}
      status={status}
    >
      {canViewContent ? (
        <SpecificContentLink
          alternativeScrollIds={alternativeScrollIds}
          canCreateContent={canCreateContent}
          cannotCreateDisabledMessage={cannotCreateDisabledMessage}
          cannotViewDisabledMessage={cannotViewDisabledMessage}
          contentItem={contentItem}
          contentItemPath={contentItemPath}
          contentTypeName={contentTypeName}
          isViewOnly={isViewOnly}
          scrollId={scrollId}
          scrollOptions={scrollOptions}
        />
      ) : (
        <NoPermissionsContentLink />
      )}
    </LinkDetail>
  );
};

ContentLinkDetail.displayName = 'ContentLinkDetail';
