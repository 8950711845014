import { Action } from '../../../../@types/Action.type.ts';
import { WebhookMessageFilterOptionId } from '../../constants/webhookMessageFilterOptions.ts';
import { Webhooks_WebhookMessageListing_Filtered } from '../../constants/webhooksActionTypes.ts';

export function webhookMessagesListingFilter(
  state: WebhookMessageFilterOptionId = WebhookMessageFilterOptionId.ShowFailedAndDelivered,
  action: Action,
): WebhookMessageFilterOptionId {
  switch (action.type) {
    case Webhooks_WebhookMessageListing_Filtered:
      return action.payload.webhookMessagesFilter;

    default:
      return state;
  }
}
