import React, { TextareaHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { tagHeight } from '../../../../../client/component-library/components/Tag/tokens.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { px } from '../../../tokens/utils/utils.ts';
import { IInputHandlers } from '../../Input/components/BaseInputComponent.tsx';
import { TextareaControl } from '../../Input/components/InputControl.tsx';
import { inputTypography } from '../../Input/components/tokens.ts';
import { InputState } from '../../Input/inputStateEnum.ts';
import { multiSelectTagOffset, multiSelectTextFieldMinWidth } from '../MultiSelect/tokens.ts';

const textFieldStyles = css`
  min-height: ${px(tagHeight)};
  padding: calc((${px(tagHeight)} - ${inputTypography.lineHeight}) / 2) 0;
  padding-left: ${px(multiSelectTagOffset)};
  word-break: break-word;
`;

const TextField = styled(TextareaControl).attrs({
  as: 'textarea',
})`
  ${textFieldStyles};
  position: absolute;
  inset: 0;
  overflow: hidden;
`;

const TextFieldSizer = styled(TextareaControl).attrs({
  as: 'span',
})`
  ${textFieldStyles};
  min-width: ${px(multiSelectTextFieldMinWidth)};
  visibility: hidden;
  // treat whitespace like textarea does by default
  white-space: pre-wrap;
`;

type TextareaElementAttributes = Pick<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'autoComplete' | 'maxLength' | 'minLength' | 'name' | 'required' | 'tabIndex'
>;

type AutoGrowingInputProps = TextareaElementAttributes &
  IInputHandlers & {
    readonly defaultValue?: string;
    readonly disabled?: boolean;
    readonly inputState: InputState;
    readonly noWrap?: boolean;
    readonly placeholder?: string;
    readonly readOnly?: boolean;
    readonly value?: string;
  };

export const AutoGrowingTextField = React.forwardRef<HTMLTextAreaElement, AutoGrowingInputProps>(
  ({ inputState, noWrap, placeholder, value, ...otherProps }, forwardedRef) => {
    return (
      <Box component="span" position="relative">
        <TextField
          $inputState={inputState}
          placeholder={placeholder}
          ref={forwardedRef}
          value={value}
          {...otherProps}
          css={noWrap ? 'white-space: nowrap' : ''}
        />
        <TextFieldSizer $inputState={inputState} css={noWrap ? 'white-space: nowrap' : ''}>
          {value?.length ? value : placeholder}
        </TextFieldSizer>
      </Box>
    );
  },
);

AutoGrowingTextField.displayName = 'AutoGrowingTextField';
