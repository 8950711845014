import { combineReducers } from '@reduxjs/toolkit';
import { cancellationToken } from './reducers/cancellationToken.ts';
import { continuationToken } from './reducers/continuationToken.ts';
import { events } from './reducers/events.ts';
import { hasAnyResults } from './reducers/hasAnyResults.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';

export const auditLog = combineReducers({
  cancellationToken,
  continuationToken,
  events,
  hasAnyResults,
  loadingStatus,
});
