import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { AssetType_Editor_InitFinished } from '../../../assetTypes/constants/assetTypeActionTypes.ts';
import {
  ContentType_Creator_InitFinished,
  ContentType_Creator_InitStarted,
  ContentType_Editor_InitFinished,
} from '../../../contentTypes/constants/contentTypesActionTypes.ts';
import { ContentModels_TypeEditor_InitStarted } from '../../../shared/constants/sharedContentModelsActionTypes.ts';

export function loadingStatus(state = LoadingStatus.InitialEmpty, action: Action): LoadingStatus {
  switch (action.type) {
    case ContentType_Creator_InitStarted:
    case ContentModels_TypeEditor_InitStarted: {
      return LoadingStatus.Loading;
    }

    case ContentType_Creator_InitFinished:
    case ContentType_Editor_InitFinished:
    case AssetType_Editor_InitFinished: {
      return LoadingStatus.Loaded;
    }

    default:
      return state;
  }
}
