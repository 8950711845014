import { AriaRadioGroupProps, useRadioGroup } from '@react-aria/radio';
import { mergeProps } from '@react-aria/utils';
import { useRadioGroupState } from '@react-stately/radio';
import React, { ReactNode } from 'react';
import { Box, BoxBorder } from '../../layout/Box/Box.tsx';
import { Inline } from '../../layout/Inline/Inline.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import {
  colorAlertIcon,
  colorAlertText,
  colorTextDefault,
  colorTextHint,
} from '../../tokens/decision/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icons } from '../Icons/components/icons.ts';
import { RadioGroupContextProvider } from './radioGroupContext.tsx';
import { RadioGroupState } from './types.ts';

interface IGroupLabel {
  readonly groupLabel: string;
}

interface IAriaLabel {
  readonly ariaLabel: string;
}

type HasGroupLabelOrAriaLabel =
  | (IGroupLabel & Partial<IAriaLabel>)
  | (Partial<IGroupLabel> & IAriaLabel);

type RadioGroupBaseProps = {
  /** Message shown when RadioGroup is in 'alert' state */
  readonly alertMessage?: ReactNode;
  readonly defaultValue?: string;
  readonly groupSubLabel?: ReactNode;
  readonly inline?: boolean;
  readonly name: string;
  readonly onChange?: (value: string) => void;
  readonly radioGroupState?: RadioGroupState;
  readonly value?: string;
};

export type RadioGroupProps = RadioGroupBaseProps & HasGroupLabelOrAriaLabel;

export const RadioGroup = React.forwardRef<
  HTMLFieldSetElement,
  React.PropsWithChildren<RadioGroupProps>
>((props, forwardedRef) => {
  const {
    alertMessage,
    ariaLabel,
    children,
    groupLabel,
    groupSubLabel,
    inline = false,
    radioGroupState = 'default',
    ...otherProps
  } = props;

  const commonProps: AriaRadioGroupProps = {
    ...props,
    'aria-label': ariaLabel || groupLabel,
    description: groupSubLabel,
    errorMessage: alertMessage,
    isDisabled: radioGroupState === 'disabled',
    isInvalid: radioGroupState === 'alert',
  };

  const state = useRadioGroupState(commonProps);

  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(
    commonProps,
    state,
  );

  return (
    <Box
      component="fieldset"
      ref={forwardedRef}
      {...getDataUiComponentAttribute(RadioGroup)}
      {...mergeProps(radioGroupProps, otherProps)}
      // fieldset’s default styles reset
      padding={0}
      border={BoxBorder.None}
      margin={0}
    >
      <RadioGroupContextProvider state={state}>
        <Stack spacing={Spacing.L}>
          {(groupLabel || groupSubLabel) && (
            <Stack spacing={Spacing.L}>
              {groupLabel && (
                <Box
                  component="legend"
                  color={colorTextDefault}
                  overflowWrap="anywhere"
                  typography={Typography.LabelLarge}
                  {...labelProps}
                >
                  {groupLabel}
                </Box>
              )}
              {groupSubLabel && (
                <Box
                  color={colorTextHint}
                  overflowWrap="anywhere"
                  typography={Typography.LabelLarge}
                  {...descriptionProps}
                >
                  {groupSubLabel}
                </Box>
              )}
            </Stack>
          )}

          {inline ? (
            <Inline spacingX={Spacing.S}>{children}</Inline>
          ) : (
            <Stack spacing={Spacing.S}>{children}</Stack>
          )}

          {!!alertMessage && radioGroupState === 'alert' && (
            <Row spacing={Spacing.XS} noWrap>
              <Column width="content">
                <Icons.ExclamationTriangleInverted color={colorAlertIcon} size={IconSize.S} />
              </Column>
              <Column>
                <Box
                  color={radioGroupState === 'alert' ? colorAlertText : colorTextDefault}
                  overflowWrap="anywhere"
                  typography={Typography.TitleMedium}
                  {...errorMessageProps}
                >
                  {alertMessage}
                </Box>
              </Column>
            </Row>
          )}
        </Stack>
      </RadioGroupContextProvider>
    </Box>
  );
});

RadioGroup.displayName = 'RadioGroup';
