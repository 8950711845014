import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { IProjectIdStorage } from '../../../localStorages/projectIdStorage.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import { IRoleRepository } from '../../../repositories/interfaces/IRoleRepository.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { TrackUserEventData } from '../../models/TrackUserEvent.type.ts';
import { logError } from '../../utils/logError.ts';
import { currentProjectUpdated } from '../sharedActions.ts';

interface IDeps {
  readonly projectIdStorage: IProjectIdStorage;
  readonly loadProjectProperties: (projectId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSubscriptionProperties: (projectId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWebSpotlightConfiguration: (
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventData;
  readonly projectRepository: Pick<IProjectRepository, 'setLastLoginForCurrentUser'>;
  readonly roleRepository: Pick<IRoleRepository, 'getNormalizedRoleWithSettings'>;
}

export const createUpdateCurrentProjectAction =
  (deps: IDeps) =>
  (desiredUserProjectInfo: IUserProjectInfo, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const { masterEnvironmentId, projectId, subscriptionId } = desiredUserProjectInfo;

    const [role] = await Promise.all([
      deps.roleRepository.getNormalizedRoleWithSettings(projectId, abortSignal),
      dispatch(deps.loadProjectProperties(projectId, abortSignal)),
      dispatch(deps.loadSubscriptionProperties(subscriptionId, abortSignal)),
    ]);

    dispatch(currentProjectUpdated(projectId, masterEnvironmentId, role));
    deps.projectIdStorage.save(projectId);

    await Promise.all([
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadWebSpotlightConfiguration(projectId, abortSignal)),
      deps.projectRepository.setLastLoginForCurrentUser(projectId, abortSignal).catch((error) => {
        if (!isAbortError(error)) {
          logError('Could not store user’s last login', error);
        }
      }),
    ]);

    dispatch(deps.trackUserEvent(TrackedEvent.CurrentProjectUpdated));
  };
