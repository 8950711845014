import { memoize } from '@kontent-ai/memoization';
import { ILanguage } from '../../../data/models/languages/Language.ts';
import { ICollectionsData } from '../../../data/reducers/collections/ICollections.type.ts';
import { ILanguagesData } from '../../../data/reducers/languages/ILanguagesData.type.ts';
import { getAllActiveLanguagesForCurrentUserForCollection } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { isAllowedTypeForCapability } from './activeCapabilities.ts';
import { Capability } from './capability.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from './getContributorRole.ts';

const getAllowedLanguagesForType = memoize.maxOne(
  (
    user: IUser,
    projectId: Uuid,
    collectionId: Uuid,
    languages: ILanguagesData,
    capability: Capability,
    contentTypeId: Uuid,
  ): ReadonlyArray<ILanguage> =>
    getAllActiveLanguagesForCurrentUserForCollection(
      user,
      projectId,
      collectionId,
      languages,
      capability,
    )
      .filter((language: ILanguage) =>
        isAllowedTypeForCapability(
          getCurrentUserRoleForCollectionForLanguageForUser(
            user,
            projectId,
            collectionId,
            language.id,
          )?.settings,
          capability,
        )(contentTypeId),
      )
      .toArray(),
);

export const getFirstCollectionAndLanguageForContentTypeAndCurrentUser = (
  user: IUser,
  contentTypeId: Uuid,
  projectId: Uuid,
  languages: ILanguagesData,
  collections: ICollectionsData,
  capability: Capability,
): Readonly<{ collectionId?: Uuid; languageId?: Uuid }> => {
  const firstCollectionId = Array.from(collections.byId.keys()).find(
    (collectionId) =>
      getAllowedLanguagesForType(
        user,
        projectId,
        collectionId,
        languages,
        capability,
        contentTypeId,
      ).length > 0,
  );

  return firstCollectionId
    ? {
        collectionId: firstCollectionId,
        languageId:
          getAllowedLanguagesForType(
            user,
            projectId,
            firstCollectionId,
            languages,
            capability,
            contentTypeId,
          )[0]?.id ?? undefined,
      }
    : { collectionId: undefined, languageId: undefined };
};
