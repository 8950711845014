export const ListingFilter_SpaceFilterChanged = 'ListingFilter_SpaceFilterChanged';
export const ListingFilter_CollectionFilterChanged = 'ListingFilter_CollectionFilterChanged';
export const ListingFilter_ContentTypeFilterChanged = 'ListingFilter_ContentTypeFilterChanged';
export const ListingFilter_ContributorsFilterChanged = 'ListingFilter_ContributorsFilterChanged';
export const ListingFilter_PublishingStatusFilterChanged =
  'ListingFilter_PublishingStatusFilterChanged';
export const ListingFilter_ContentItemStatusChanged = 'ListingFilter_ContentItemStatusChanged';
export const ListingFilter_SearchScopeChanged = 'ListingFilter_SearchScopeChanged';
export const ListingFilter_SitemapFilterChanged = 'ListingFilter_SitemapFilterChanged';
export const ListingFilter_TaxonomyGroupsFilterChanged =
  'ListingFilter_TaxonomyGroupsFilterChanged';
export const ListingFilter_WorkflowStatusesFilterChanged =
  'ListingFilter_WorkflowStatusesFilterChanged';

export const ListingFilter_SavedFilterSelected = 'ListingFilter_SavedFilterSelected';

export const ListingFilter_HideRestoreButton = 'ListingInfo_HideRestoreButton';

export const ListingFilter_InitSavedFilters_Started = 'ListingFilter_InitSavedFilters_Started';
export const ListingFilter_InitSavedFilters_Finished = 'ListingFilter_InitSavedFilters_Finished';

export const ListingFilter_DeleteSavedFilter_Started = 'ListingFilter_DeleteSavedFilter_Started';
export const ListingFilter_DeleteSavedFilter_Finished = 'ListingFilter_DeleteSavedFilter_Finished';
export const ListingFilter_DeleteSavedFilter_Failed = 'ListingFilter_DeleteSavedFilter_Failed';

export const ListingFilter_SaveFilter_Started = 'ListingFilter_SaveFilter_Started';
export const ListingFilter_SaveFilter_Finished = 'ListingFilter_SaveFilter_Finished';
export const ListingFilter_SaveFilter_Failed = 'ListingFilter_SaveFilter_Failed';

export const ListingFilter_UpdateSavedFilter_Started = 'ListingFilter_UpdateSavedFilter_Started';
export const ListingFilter_UpdateSavedFilter_Finished = 'ListingFilter_UpdateSavedFilter_Finished';
export const ListingFilter_UpdateSavedFilter_Failed = 'ListingFilter_UpdateSavedFilter_Failed';

export const ListingFilter_CreateLinkedFilter_Started = 'ListingFilter_CreateLinkedFilter_Started';
export const ListingFilter_CreateLinkedFilter_Finished =
  'ListingFilter_CreateLinkedFilter_Finished';
export const ListingFilter_CreateLinkedFilter_Failed = 'ListingFilter_CreateLinkedFilter_Failed';

export const ListingFilter_RestoreSavedFilter_Started = 'ListingFilter_RestoreSavedFilter_Started';
export const ListingFilter_RestoreSavedFilter_Finished =
  'ListingFilter_RestoreSavedFilter_Finished';
export const ListingFilter_RestoreSavedFilter_Failed = 'ListingFilter_RestoreSavedFilter_Failed';

export const ContentItemFilter_SetUp = 'ContentItemFilter_SetUp';
export const ContentItemFilter_Left = 'ContentItemFilter_Left';
