import { Action } from '../../../../../@types/Action.type.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { Calendar_LoadItemsWithVariantActiveInPeriod_Started } from '../../constants/calendarActionTypes.ts';

const now = new Date().toJSON();

const initialState: DateRange = {
  from: now,
  to: now,
};

export const selectedPeriod = (state: DateRange = initialState, action: Action): DateRange => {
  switch (action.type) {
    case Calendar_LoadItemsWithVariantActiveInPeriod_Started: {
      return action.payload.period;
    }

    default:
      return state;
  }
};
