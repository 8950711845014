import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { showLanguageEditor } from '../../actions/localizationActions.ts';
import { DraggableLanguageBar as DraggableLanguageBarComponent } from '../../components/languageBarItems/DraggableLanguageBar.tsx';
import {
  ILanguageBarDispatchProps,
  ILanguageBarStateProps,
} from '../../components/languageBarItems/LanguageBar.tsx';

interface ILanguageEditableBarProps {
  readonly language: ILanguage;
}

const mapStateToProps = (
  state: IStore,
  ownProps: ILanguageEditableBarProps,
): ILanguageBarStateProps => {
  const {
    localizationApp: { languages, editedLanguageId, isDefaultLanguageEdited },
  } = state;

  const possibleFallback = languages.get(ownProps.language.fallbackLanguageId || '');
  const fallbackLanguageName = possibleFallback ? possibleFallback.name : 'Default language';

  return {
    fallbackLanguageName,
    isEditable: !editedLanguageId && !isDefaultLanguageEdited,
    language: ownProps.language,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: ILanguageEditableBarProps,
): ILanguageBarDispatchProps => ({
  onShowEditor: () => dispatch(showLanguageEditor(ownProps.language.id)),
});

export const DraggableLanguageBar: React.ComponentType<
  React.PropsWithChildren<ILanguageEditableBarProps>
> = connect(mapStateToProps, mapDispatchToProps)(DraggableLanguageBarComponent);
