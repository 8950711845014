import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightAssetTrackedEvent,
  TrackedEvent,
} from '../../../../../../../_shared/constants/trackedEvent.ts';
import { AssetsRequestTrigger } from '../../../../../../../_shared/utils/scrollGridUtils.ts';
import { AssetPickerRecentlyUsedFolderStorage } from '../../../../../../../localStorages/assetPickerRecentlyUsedFolderStorage.ts';
import { assetFolderOpened } from '../../../../actions/assetLibraryActions.ts';
import {
  IAssetLibraryQuery,
  emptyAssetLibraryQuery,
} from '../../../../models/IAssetLibraryQuery.ts';
import { LoadListingAssets } from '../../../../types/depsTypes.type.ts';
import { AssetsOrderBy } from '../../../../types/orderBy.type.ts';
import { assetFolderExists } from '../../../../utils/assetFolderExists.ts';
import { AssetSelector_Opened } from '../../constants/assetSelectorActionTypes.ts';

interface IInitializeAssetDialogDependencies {
  readonly loadAssetFolders: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadListingAssets: LoadListingAssets;
}

const assetSelectorOpened = (query: IAssetLibraryQuery) =>
  ({
    type: AssetSelector_Opened,
    payload: { query },
  }) as const;

export type InitializeAssetDialogActionsType = ReturnType<typeof assetSelectorOpened>;

export const createInitializeAssetDialogAction =
  (deps: IInitializeAssetDialogDependencies) =>
  (orderBy: AssetsOrderBy, showImagesOnly: boolean, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const query: IAssetLibraryQuery = {
      ...emptyAssetLibraryQuery,
      mimeType: showImagesOnly ? 'image/' : '',
    };

    const {
      sharedApp: { currentProjectId },
    } = getState();

    const recentlyOpenedFolderId = AssetPickerRecentlyUsedFolderStorage.load(currentProjectId);

    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightAsset, {
        name: FindRightAssetTrackedEvent.OpenedModalSelector,
      }),
    );
    dispatch(assetSelectorOpened(query));

    await dispatch(deps.loadAssetFolders(abortSignal));

    if (assetFolderExists(recentlyOpenedFolderId, getState())) {
      dispatch(assetFolderOpened(recentlyOpenedFolderId));
    }

    await dispatch(
      deps.loadListingAssets(
        {
          orderBy,
          requestTrigger: AssetsRequestTrigger.Search,
        },
        abortSignal,
      ),
    );
  };
