import { getFileSizeObject } from '../../../../_shared/utils/fileSizeUtils.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { getFileSizeOptionName } from '../../../contentInventory/content/models/fileSizeOptions.ts';

enum MetricTypeEnum {
  Cumulative = 'Cumulative',
  Maximum = 'Maximum',
  Sum = 'Sum',
}

export interface IMetricMetaData {
  displayName: string;
  type?: string;
  description?: string;
}

const Metrics: ReadonlyRecord<string, IMetricMetaData> = {
  ProjectAssetStorageUsed: {
    displayName: 'Asset storage',
    type: MetricTypeEnum.Sum,
    description:
      'The sum of asset storage used from project environments\nwith the largest asset storage.',
  },
  ProjectBandwidthUsed: {
    displayName: 'Bandwidth',
    type: MetricTypeEnum.Cumulative,
    description: 'Data transferred within the current billing period.',
  },
  ProjectApiCallsCount: {
    displayName: 'API calls',
    type: MetricTypeEnum.Cumulative,
    description: 'API calls made within the current billing period.',
  },
  ProjectContentItemsCount: {
    displayName: 'Content items',
    type: MetricTypeEnum.Sum,
    description: 'The sum of content items from project environments\nwith the most content items.',
  },
  ProjectContentVariantsCount: {
    displayName: 'Content items (all languages)',
    type: MetricTypeEnum.Sum,
    description:
      'The sum of the translated content items in all languages,\nthat is, their language variants, from project environments\nwith the most language variants.',
  },
  SubscriptionMaxUsers: {
    displayName: 'Users',
  },
  SubscriptionActiveUsers: {
    displayName: 'Active users',
    type: MetricTypeEnum.Sum,
    description: 'The sum of unique active users across your projects.',
  },
  ProjectCustomRolesCount: {
    displayName: 'Custom roles',
    type: MetricTypeEnum.Maximum,
    description:
      'This is the maximum number of custom roles that were\nactive in one project within the billing period.',
  },
  ProjectContentTypesCount: {
    displayName: 'Content types',
    type: MetricTypeEnum.Sum,
    description: 'The sum of content types from project environments\nwith the most content types.',
  },
  ProjectCollectionsCount: {
    displayName: 'Collections',
    type: MetricTypeEnum.Sum,
    description: 'The sum of collections from project environments\nwith the most collections.',
  },
  SubscriptionCustomAssetDomains: {
    displayName: 'Custom asset domains',
    type: MetricTypeEnum.Sum,
  },
};

export const getMetricFormattedValue = (name: string, value: number): string => {
  switch (name) {
    case 'ProjectAssetStorageUsed':
    case 'ProjectBandwidthUsed': {
      const fileSize = getFileSizeObject(value);
      return (fileSize && `${fileSize.fileSize} ${getFileSizeOptionName(fileSize.unit)}`) || '0';
    }
    default:
      return value.toLocaleString('en-US');
  }
};

export const getMetricMetaData = (name: string): IMetricMetaData | null => Metrics[name] ?? null;

export enum PlanPrepaidStatus {
  None = 'None',
  IsPrepaid = 'IsPrepaid',
  WasPrepaid = 'WasPrepaid',
}

export const getPlanPrepaidStatus = (plan: IPlan, hadPrepaidPlan: boolean): PlanPrepaidStatus => {
  if (plan.isPrepaid) {
    return PlanPrepaidStatus.IsPrepaid;
  }

  if (hadPrepaidPlan) {
    return PlanPrepaidStatus.WasPrepaid;
  }

  return PlanPrepaidStatus.None;
};
