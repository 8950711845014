import { Action } from '../../../../../@types/Action.type.ts';
import { IScrollState } from '../../../../../_shared/utils/scrollGridUtils.ts';
import {
  AuditLog_Listing_Left,
  AuditLog_ScrollState_Changed,
} from '../../constants/auditLogActionTypes.ts';

const initialState = {
  availableHeightPx: 0,
  scrollPositionPx: 0,
  scrollHeightPx: 0,
};

export const auditLogScrollState = (
  state: IScrollState = initialState,
  action: Action,
): IScrollState => {
  switch (action.type) {
    case AuditLog_ScrollState_Changed: {
      return {
        availableHeightPx: action.payload.availableHeightPx,
        scrollPositionPx: action.payload.scrollPositionPx,
        scrollHeightPx: action.payload.scrollHeightPx,
      };
    }

    case AuditLog_Listing_Left:
      return initialState;

    default:
      return state;
  }
};
