import { combineReducers } from '@reduxjs/toolkit';
import { listingAssets } from './listingAssets.ts';
import { byId } from './reducers/byId.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';
import { nextContinuationToken } from './reducers/nextContinuationToken.ts';
import { uploadingAssets } from './reducers/uploadingAssets.ts';

export const assets = combineReducers({
  byId,
  loadingStatus,
  listingAssets,
  uploadingAssets,
  nextContinuationToken,
});
