import { Action } from '../../../../../@types/Action.type.ts';
import {
  ArchivedStep,
  getArchivedWorkflowStepFromServerModel,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { archivedStepConstantValues } from '../../constants/archivedStepValues.ts';
import {
  Workflow_ArchivedStepEditor_Save,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
  Workflow_InitializeEditing_Started,
} from '../../constants/workflowActionTypes.ts';

const archivedStepInitialState: ArchivedStep = {
  ...archivedStepConstantValues,
  roleIds: new Set(),
};

export const archivedWorkflowStep = (
  state: ArchivedStep = archivedStepInitialState,
  action: Action,
): ArchivedStep => {
  switch (action.type) {
    case Workflow_InitializeEditing_Started: {
      return archivedStepInitialState;
    }

    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished: {
      return action.payload.workflow.archivedStep;
    }

    case Workflow_Editing_WorkflowSaveFinished: {
      return getArchivedWorkflowStepFromServerModel(action.payload.workflow.archivedStatus);
    }

    case Workflow_ArchivedStepEditor_Save: {
      const { roleIds } = action.payload;

      return {
        ...state,
        roleIds,
      };
    }

    default: {
      return state;
    }
  }
};
