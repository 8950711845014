import { useEffect, useRef } from 'react';

/**
 * Returns a value received in previous render.
 * On the first render returns current value.
 */
export function usePrevious<T>(prop: T): T {
  const previous = useRef(prop);
  useEffect(() => {
    previous.current = prop;
  });
  return previous.current;
}
