import { assert } from '@kontent-ai/utils';
import { IWebhookMessageServerModel } from '../../../repositories/serverModels/WebhookMessageServerModel.type.ts';

export interface IWebhookMessage {
  readonly createdTimestamp: DateTimeStamp;
  readonly failureCount: number;
  readonly lastAttemptRequestUrl: string;
  readonly lastAttemptStatusCode: number;
  readonly lastAttemptTimestamp: DateTimeStamp;
  readonly lastFailureResponse: string | null;
  readonly messageIndex: string;
  readonly webhookId: Uuid;
}

const emptyWebhookMessage: IWebhookMessage = {
  createdTimestamp: '',
  failureCount: 0,
  lastAttemptRequestUrl: '',
  lastAttemptStatusCode: 0,
  lastAttemptTimestamp: '',
  lastFailureResponse: null,
  messageIndex: '',
  webhookId: '',
};

export function getWebhookMessageFromServerModel(
  serverModel: IWebhookMessageServerModel,
): IWebhookMessage {
  assert(!!serverModel, () => 'Webhook message server model is falsy');

  return {
    createdTimestamp: serverModel.createdTimestamp ?? emptyWebhookMessage.createdTimestamp,
    failureCount: serverModel.failureCount ?? emptyWebhookMessage.failureCount,
    lastAttemptRequestUrl:
      serverModel.lastAttemptRequestUrl ?? emptyWebhookMessage.lastAttemptRequestUrl,
    lastAttemptStatusCode:
      serverModel.lastAttemptStatusCode ?? emptyWebhookMessage.lastAttemptStatusCode,
    lastAttemptTimestamp:
      serverModel.lastAttemptTimestamp ?? emptyWebhookMessage.lastAttemptTimestamp,
    lastFailureResponse: serverModel.lastFailureResponse ?? emptyWebhookMessage.lastFailureResponse,
    messageIndex: serverModel.messageIndex ?? emptyWebhookMessage.messageIndex,
    webhookId: serverModel.webhookId ?? emptyWebhookMessage.webhookId,
  };
}
