import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { forwardRef } from 'react';
import { DefaultTag } from '../../../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { RemovalState } from '../../../../../../../../component-library/components/Tag/removalStateEnum.ts';
import { AiResult } from '../../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { getAiErrorMessage } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import {
  FeedbackReasons,
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  renderTaxonomyGroupName,
  renderTaxonomyTermPath,
} from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { TaxonomyTermTag } from '../../../models/TaxonomyTermTag.type.ts';
import { taxonomiesErrorMessageByErrorCode } from '../../../utils/taxonomiesAiErrors.ts';

export const feedbackReasons: FeedbackReasons = [
  ...createFeedbackReasons([
    SpecificFeedbackReason.TaxonomyTermsIrrelevant,
    SpecificFeedbackReason.TaxonomyTermsTooMany,
    SpecificFeedbackReason.TaxonomyTermsTooFew,
    SpecificFeedbackReason.TaxonomyTermsNone,
  ]),
  {
    reason: SpecificFeedbackReason.ResultSlow,
    label: 'It took too long to suggest the terms',
  },
];

type Props = Readonly<{
  removeTerm: (removedTermId: string) => void;
  result: ActionResult<ReadonlyArray<Uuid>>;
  selectedTerms: ReadonlyArray<TaxonomyTermTag>;
  taxonomyElementName: string;
  taxonomyGroup: ITaxonomyGroup;
  width: number;
}>;

export const TaxonomyAiAssistantResult = forwardRef<HTMLDivElement, Props>(
  ({ removeTerm, result, selectedTerms, taxonomyElementName, taxonomyGroup, width }, ref) => {
    const hasResult = result.content || result.error;

    return (
      <AiResult
        actionName={AiActionName.CategorizeItem}
        actionTitle={
          result.isFinished && !result.error
            ? `Suggested terms from the ${renderTaxonomyGroupName(
                taxonomyGroup,
              )} taxonomy group in the ${taxonomyElementName} taxonomy element.`
            : 'Analyze the content to suggest taxonomy terms'
        }
        feedbackReasons={feedbackReasons}
        isLoading={!result.isFinished}
        ref={ref}
        renderResult={() =>
          hasResult && (
            <Stack spacing={Spacing.L}>
              {result.error && (
                <Callout
                  calloutType="warning"
                  {...getDataUiElementAttribute(DataUiElement.CalloutWarning)}
                >
                  {getAiErrorMessage(result.error, taxonomiesErrorMessageByErrorCode)}
                </Callout>
              )}
              {selectedTerms.length > 0 && (
                <Inline spacing={Spacing.XS}>
                  {selectedTerms.map((term) => (
                    <DefaultTag
                      key={term.id}
                      customTooltipText={renderTaxonomyTermPath(term.path)}
                      label={term.label}
                      removalState={
                        result.isFinished && !result.error
                          ? RemovalState.Allowed
                          : RemovalState.NoRemoval
                      }
                      removeButtonTooltipText="Remove term from selection."
                      onRemoveClick={() => removeTerm(term.id)}
                    />
                  ))}
                </Inline>
              )}
              {selectedTerms.length === 0 && !result.error && (
                <Box color={colorTextDefault} typography={Typography.UIParagraph}>
                  No terms selected
                </Box>
              )}
            </Stack>
          )
        }
        width={width}
      />
    );
  },
);

TaxonomyAiAssistantResult.displayName = 'TaxonomyAiAssistantResult';
