export const UnableToSaveErrorMessage =
  'We were unable to save this filter. Please try again later.';
export const UnableToCreateLinkErrorMessage =
  'We were unable to create link for this filter. Please try again later.';
export const UnableToDeleteErrorMessage =
  'We were unable to delete this filter. Please try again later.';
export const UnableToRenameErrorMessage =
  'We were unable to rename this filter. Please try again later.';
export const UnableToRestoreErrorMessage = 'We were unable to restore this filter.';

export const UntitledFilter = 'Untitled filter';
