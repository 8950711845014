import { Action } from '../../../../../@types/Action.type.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_AssignmentSubmittingStarted,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledPublishingStarted,
} from '../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_ArchiveTemporaryContentItem_Started,
  ContentItemEditing_CommentThread_SubmittingFinished,
  ContentItemEditing_CommentThread_SubmittingStarted,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_CreateTask_Started,
  ContentItemEditing_Duplicating_Finished,
  ContentItemEditing_Duplicating_Started,
  ContentItemEditing_ElementValue_Changed,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemName_Changed,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LanguageVariantSwitched,
  ContentItemEditing_MarkAsTemporary,
  ContentItemEditing_SitemapLocation_Toggled,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ContentEditing_MoveItemToCollection_Finished,
  ContentEditing_MoveItemToCollection_Started,
} from '../../../features/MoveToCollectionDialog/constants/moveToCollectionDialogActionTypes.ts';
import { ContentRevisions_RevisionRestoreStarted } from '../../../features/Revisions/constants/revisionActionTypes.ts';
import { ITemporaryContentItem } from '../../../models/ITemporaryContentItem.type.ts';

const initialState: ITemporaryContentItem | null = null;

export const temporaryItem = (
  state: ITemporaryContentItem | null = initialState,
  action: Action,
): ITemporaryContentItem | null => {
  switch (action.type) {
    case ContentItemEditing_MarkAsTemporary:
      return action.payload.temporaryItem;

    case ContentItemEditing_ItemName_Changed:
    case ContentItemEditing_ElementValue_Changed:
    case ContentItemEditing_SitemapLocation_Toggled:
    case Content_Editing_AssignmentSubmittingStarted:
    case Content_Editing_PublishContentItemVariantStarted:
    case ContentItemEditing_CommentThread_SubmittingStarted:
    case ContentRevisions_RevisionRestoreStarted:
    case ContentItemEditing_LanguageVariantSwitched:
    case Content_Editing_ScheduledPublishingStarted:
    case ContentItemEditing_Duplicating_Started:
    case ContentItemEditing_CreateTask_Started:
    case ContentEditing_MoveItemToCollection_Started:
      return !state || state.itemState === TemporaryContentItemState.Edited
        ? state
        : {
            ...state,
            itemState: TemporaryContentItemState.Edited,
          };

    case ContentItemEditing_ItemSaving_Finished:
    case ContentItemEditing_ItemElementsSaving_Finished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_AssignmentSubmittingFinished:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentItemEditing_CommentThread_SubmittingFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case ContentItemEditing_Duplicating_Finished:
    case ContentItemEditing_CreateTask_Finished:
    case ContentEditing_MoveItemToCollection_Finished:
      return state && state.itemState === TemporaryContentItemState.Edited ? initialState : state;

    case ContentItemEditing_Init_Ready:
      return state &&
        state.itemState === TemporaryContentItemState.Default &&
        state.itemId === action.payload.editedContentItem.id
        ? {
            ...state,
            itemState: TemporaryContentItemState.Loaded,
          }
        : initialState;

    case ContentItemEditing_ArchiveTemporaryContentItem_Started:
      return initialState;

    default:
      return state;
  }
};
