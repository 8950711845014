import { combineReducers } from '@reduxjs/toolkit';
import { apiStatusPerEnvironment } from './reducers/apiStatusPerEnvironment.ts';
import { dapiListingData } from './reducers/dapiListingData.ts';
import { keyDetail } from './reducers/keyDetail.ts';
import { mapiListingData } from './reducers/mapiListingData.ts';

export const apiKeys = combineReducers({
  apiStatusPerEnvironment,
  dapiListingData,
  keyDetail,
  mapiListingData,
});
