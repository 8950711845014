import { Collection } from '@kontent-ai/utils';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { DirectionItemEnum } from '../../sitemap/constants/DirectionItemEnum.ts';

function removeItemFromList<T>(list: ReadonlyArray<T>, item: T): ReadonlyArray<T> {
  return list.filter((listItem: T) => listItem !== item);
}

function insertItemToList<T>(list: ReadonlyArray<T>, index: number, item: T): ReadonlyArray<T> {
  return [...list.slice(0, index), item, ...list.slice(index)];
}

export function getNode(taxonomyGroup: ITaxonomyGroup, nodeId: Uuid): ITaxonomyTerm | null {
  if (taxonomyGroup.id === nodeId) {
    return taxonomyGroup;
  }
  return taxonomyGroup.terms.get(nodeId) ?? null;
}

function updateTaxonomyGroupTerm(
  taxonomyGroup: ITaxonomyGroup,
  term: ITaxonomyTerm,
): ITaxonomyGroup {
  const updatedTerms = Collection.add(taxonomyGroup.terms, [term.id, term]);

  return {
    ...taxonomyGroup,
    terms: updatedTerms,
  };
}

export function getParentNode(taxonomyGroup: ITaxonomyGroup, termId: Uuid): ITaxonomyTerm | null {
  return taxonomyGroup.childIds.includes(termId)
    ? taxonomyGroup
    : Collection.getValues(taxonomyGroup.terms).find((node: ITaxonomyTerm) =>
        node.childIds.includes(termId),
      ) || null;
}

export function getRightSiblingId(parentNode: ITaxonomyTerm, termId: Uuid): Uuid | null {
  if (termId === Collection.getLast(parentNode.childIds)) {
    return null;
  }

  const termIndex = getNodeIndex(parentNode.childIds, termId);
  return parentNode.childIds[termIndex + 1] ?? null;
}

export function getLeftSiblingId(parentNode: ITaxonomyTerm, termId: Uuid): Uuid | null {
  if (termId === parentNode.childIds[0]) {
    return null;
  }

  const termIndex = getNodeIndex(parentNode.childIds, termId);
  return parentNode.childIds[termIndex - 1] ?? null;
}

function getNodeIndex(childIds: ReadonlyArray<Uuid>, nodeId: Uuid): number {
  return childIds.findIndex((childId: Uuid) => childId === nodeId);
}

function findPrecedentSiblingId(taxonomyGroup: ITaxonomyGroup, targetNodeId: Uuid): Uuid | null {
  const parentNode = getParentNode(taxonomyGroup, targetNodeId);

  if (parentNode) {
    const targetIndex = getNodeIndex(parentNode.childIds, targetNodeId);
    if (targetIndex <= 0) {
      return null;
    }
    const precedentSiblingId = parentNode.childIds[targetIndex - 1];
    return precedentSiblingId || null;
  }

  return null;
}

function detachTerm(taxonomyGroup: ITaxonomyGroup, termId: Uuid): ITaxonomyGroup {
  let terms = taxonomyGroup.terms;
  const parent = getParentNode(taxonomyGroup, termId);
  if (parent) {
    const parentWithoutTheChild = {
      ...parent,
      childIds: removeItemFromList(parent.childIds, termId),
    };
    terms = Collection.add(terms, [parentWithoutTheChild.id, parentWithoutTheChild]);
  }

  return {
    ...taxonomyGroup,
    childIds: removeItemFromList(taxonomyGroup.childIds, termId),
    terms,
  };
}

function placeNodeAsSibling(
  taxonomyGroup: ITaxonomyGroup,
  targetNodeId: Uuid,
  nodeId: Uuid,
): ITaxonomyGroup {
  const parentNode = getParentNode(taxonomyGroup, targetNodeId);

  if (parentNode && parentNode.id === taxonomyGroup.id) {
    const childIds = taxonomyGroup.childIds;
    const index = getNodeIndex(childIds, targetNodeId);
    const updatedChildIds = insertItemToList(childIds, index + 1, nodeId);

    return {
      ...taxonomyGroup,
      childIds: updatedChildIds,
    };
  }

  if (parentNode) {
    const index = getNodeIndex(parentNode.childIds, targetNodeId);
    const updatedParentNode = {
      ...parentNode,
      childIds: insertItemToList(parentNode.childIds, index + 1, nodeId),
    };

    return updateTaxonomyGroupTerm(taxonomyGroup, updatedParentNode);
  }

  return taxonomyGroup;
}

function placeNodeAsLastChild(
  taxonomyGroup: ITaxonomyGroup,
  targetId: Uuid,
  nodeId: Uuid,
): ITaxonomyGroup {
  if (taxonomyGroup.id === targetId) {
    return {
      ...taxonomyGroup,
      childIds: [...taxonomyGroup.childIds, nodeId],
    };
  }

  const parentNode = getNode(taxonomyGroup, targetId);

  if (parentNode) {
    return updateTaxonomyGroupTerm(taxonomyGroup, {
      ...parentNode,
      childIds: [...parentNode.childIds, nodeId],
    });
  }

  return taxonomyGroup;
}

export function moveTermRight(
  taxonomyGroup: ITaxonomyGroup,
  collapsedTermIds: ReadonlySet<Uuid>,
  termId: Uuid,
): ITaxonomyGroup {
  const futureParentId = findPrecedentSiblingId(taxonomyGroup, termId);

  if (!futureParentId) {
    return taxonomyGroup;
  }

  const sitemapWithSourceNodeDetached = detachTerm(taxonomyGroup, termId);

  const isParentCollapsed = collapsedTermIds.has(futureParentId);
  if (isParentCollapsed) {
    return placeNodeAsSibling(sitemapWithSourceNodeDetached, futureParentId, termId);
  }

  return placeNodeAsLastChild(sitemapWithSourceNodeDetached, futureParentId, termId);
}

function insertTermAsChild(
  taxonomyGroup: ITaxonomyGroup,
  targetNode: ITaxonomyTerm,
  targetIndex: number,
  sourceNodeId: Uuid,
): ITaxonomyGroup {
  if (taxonomyGroup.id === targetNode.id) {
    return {
      ...taxonomyGroup,
      childIds: insertItemToList(taxonomyGroup.childIds, targetIndex, sourceNodeId),
    };
  }

  return updateTaxonomyGroupTerm(taxonomyGroup, {
    ...targetNode,
    childIds: insertItemToList(targetNode.childIds, targetIndex, sourceNodeId),
  });
}

function moveTermInTaxonomyGroup(
  taxonomyGroup: ITaxonomyGroup,
  draggedNodeId: Uuid,
  targetNodeId: Uuid,
  before: boolean = false,
): ITaxonomyGroup {
  const treeWithDraggedNodeDetached = detachTerm(taxonomyGroup, draggedNodeId);

  const targetNodeParent = getParentNode(treeWithDraggedNodeDetached, targetNodeId);

  if (!targetNodeParent || targetNodeParent.id === draggedNodeId) {
    //  Also if the dragged node is parent of the target
    return taxonomyGroup;
  }

  const children = targetNodeParent.childIds;
  let targetNodeIndex = getNodeIndex(children, targetNodeId);

  if (targetNodeIndex < 0) {
    return taxonomyGroup;
  }

  if (!before) {
    targetNodeIndex++;
  }

  return insertTermAsChild(
    treeWithDraggedNodeDetached,
    targetNodeParent,
    targetNodeIndex,
    draggedNodeId,
  );
}

function hasCommonAncestor(
  taxonomyGroup: ITaxonomyGroup,
  supposedChildId: Uuid,
  supposedAncestorId: Uuid,
): boolean {
  if (supposedAncestorId === taxonomyGroup.id) {
    return false;
  }

  const parentNodeOfSupposedChild = getParentNode(taxonomyGroup, supposedChildId);
  if (!parentNodeOfSupposedChild) {
    return false;
  }

  if (parentNodeOfSupposedChild.id === supposedAncestorId) {
    return true;
  }

  return hasCommonAncestor(taxonomyGroup, parentNodeOfSupposedChild.id, supposedAncestorId);
}

// Only references to the term are removed
// Deleted term's children are removed during conversion to the server model
export function removeTerm(taxonomyGroup: ITaxonomyGroup, termId: Uuid): ITaxonomyGroup {
  const groupWithRemovedTerms = detachTerm(taxonomyGroup, termId);
  return {
    ...groupWithRemovedTerms,
    terms: Collection.remove(groupWithRemovedTerms.terms, termId),
  };
}

export function moveTaxonomyTerm(
  taxonomyGroup: ITaxonomyGroup,
  collapsedTermIds: ReadonlySet<Uuid>,
  draggedTermId: Uuid,
  targetTermId: Uuid,
  directions: ReadonlySet<DirectionItemEnum>,
): ITaxonomyGroup {
  // if SourceNode does not exist => no movement
  const sourceNode = getNode(taxonomyGroup, draggedTermId);
  if (sourceNode === null) {
    return taxonomyGroup;
  }

  // This is for moving the node right
  if (
    draggedTermId === targetTermId ||
    hasCommonAncestor(taxonomyGroup, targetTermId, draggedTermId)
  ) {
    if (directions.has(DirectionItemEnum.Right)) {
      return moveTermRight(taxonomyGroup, collapsedTermIds, draggedTermId);
    }

    return taxonomyGroup;
  }

  if (
    directions.has(DirectionItemEnum.Up) ||
    directions.has(DirectionItemEnum.Down) ||
    !directions.has(DirectionItemEnum.Right)
  ) {
    return moveTermInTaxonomyGroup(
      taxonomyGroup,
      draggedTermId,
      targetTermId,
      directions.has(DirectionItemEnum.Up) && !directions.has(DirectionItemEnum.Down),
    );
  }

  return taxonomyGroup;
}
