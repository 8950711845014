import { EditorState } from 'draft-js';
import { IRichTextItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { exportRichTextToServerHtml } from '../../../richText/utils/export/html/exportRichTextToServerHtml.ts';
import { importRichTextFromHtml } from '../../../richText/utils/import/importRichTextFromHtml.ts';
import {
  ICreateRichTextDomainModel,
  ICreateRichTextServerModel,
} from '../../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  IContentComponentDomainModelConversionDependencies,
  IContentComponentServerModelConversionDependencies,
} from '../../features/ContentComponent/utils/contentComponentConversionUtils.ts';
import { IContentComponent } from '../../models/contentItem/ContentComponent.ts';
import {
  IRichTextItemElement,
  newRichTextItemElement,
} from '../../models/contentItemElements/RichTextItemElement.ts';
import {
  GetContentComponent,
  IItemElementDomainModelConverter,
  IItemElementDomainModelConverterDataDependencies,
  IItemElementServerModelConverter,
  IItemElementServerModelConverterDataDependencies,
  IStoreContentComponent,
} from './types/IItemElementDataConverters.type.ts';

export interface IRichTextItemElementDomainModelConverterDependencies {
  createRichTextDomainModel: ICreateRichTextDomainModel;
  contentComponentConversionDependencies: Omit<
    IContentComponentDomainModelConversionDependencies,
    'dataDependencies'
  >;
}

export interface IRichTextItemElementServerModelConverterDependencies {
  createRichTextServerModel: ICreateRichTextServerModel;
  contentComponentConversionDependencies: Omit<
    IContentComponentServerModelConversionDependencies,
    'dataDependencies'
  >;
}

function convertRichTextElementToDomainModel(
  dependencies: IRichTextItemElementDomainModelConverterDependencies,
  element: IRichTextItemElementServerModel,
  dataDependencies?: IItemElementDomainModelConverterDataDependencies,
): IRichTextItemElement {
  const contentComponents = new Map<Uuid, IContentComponent>();
  const storeContentComponentToRootElement: IStoreContentComponent = (contentComponent) => {
    contentComponents.set(contentComponent.id, contentComponent);
    return contentComponent;
  };

  const storeContentComponent =
    dataDependencies?.storeContentComponent ?? storeContentComponentToRootElement;

  const { content, updatedServerValues } = dependencies.createRichTextDomainModel(element, {
    ...dependencies,
    contentComponentConversionDependencies: {
      ...dependencies.contentComponentConversionDependencies,
      dataDependencies: {
        ...dataDependencies,
        storeContentComponent,
      },
    },
    importFromValue: importRichTextFromHtml,
    getValue: exportRichTextToServerHtml,
  });

  return newRichTextItemElement({
    elementId: element.elementId,
    type: ElementType.RichText,
    value: element.value ?? '',
    jsonValue: element.jsonValue ?? '',
    contentComponents,
    _editorState: EditorState.createWithContent(content),
    ...updatedServerValues,
    lastModifiedBy: element.lastModifiedBy ? element.lastModifiedBy : null,
    lastModifiedAt: element.lastModifiedAt ? element.lastModifiedAt : null,
  });
}

function convertRichTextElementToServerModel(
  dependencies: IRichTextItemElementServerModelConverterDependencies,
  element: IRichTextItemElement,
  dataDependencies: IItemElementServerModelConverterDataDependencies,
): IRichTextItemElementServerModel {
  const getContentComponentFromRootElement: GetContentComponent = (contentComponentId) =>
    element.contentComponents.get(contentComponentId);
  const richTextServerModel = dependencies.createRichTextServerModel(
    element._editorState.getCurrentContent(),
    {
      ...dependencies,
      contentComponentConversionDependencies: {
        ...dependencies.contentComponentConversionDependencies,
        dataDependencies: {
          ...dataDependencies,
          getContentComponent:
            dataDependencies.getContentComponent ?? getContentComponentFromRootElement,
        },
      },
      getValue: exportRichTextToServerHtml,
    },
  );

  return {
    elementId: element.elementId,
    type: ElementType.RichText,
    ...richTextServerModel,
    lastModifiedBy: element.lastModifiedBy,
    lastModifiedAt: element.lastModifiedAt,
  };
}

export const createRichTextElementDomainModelConverter =
  (
    dependencies: IRichTextItemElementDomainModelConverterDependencies,
  ): IItemElementDomainModelConverter<IRichTextItemElementServerModel, IRichTextItemElement> =>
  (serverElement, dataDependencies) =>
    convertRichTextElementToDomainModel(dependencies, serverElement, dataDependencies);

export const createRichTextElementServerModelConverter =
  (
    dependencies: IRichTextItemElementServerModelConverterDependencies,
  ): IItemElementServerModelConverter<IRichTextItemElement, IRichTextItemElementServerModel> =>
  (element, dataDependencies) =>
    convertRichTextElementToServerModel(dependencies, element, dataDependencies);
