import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  IContentItemWithVariant,
  mapContentItemWithVariantFromServerModel,
} from '../../../../models/contentItem/ContentItemWithVariant.ts';
import { IEnsureLoadedContentTypesAction } from '../../../LoadedItems/actions/thunks/ensureLoadedContentTypes.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';
import { ILoadContentItemReferencesAction } from './loadContentItemReferences.ts';

interface IExpandModularContentItemDeps {
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction;
  readonly contentItemRepository: IContentItemRepository;
  readonly loadContentItemReferences: ILoadContentItemReferencesAction;
  readonly loadDefaultListingItems: ILoadListingItemsAction;
  readonly revalidateLinkedItemVariantElements: (
    contentItem: IContentItemWithVariant,
    contentTypeId: Uuid,
  ) => ThunkFunction;
  readonly expandModularContentItemFinished: (contentItem: IContentItemWithVariant) => Action;
}

export const expandModularContentItemActionCreator =
  (deps: IExpandModularContentItemDeps) =>
  (contentItemId: Uuid, contentTypeId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const variantId = getSelectedLanguageIdOrThrow(state);
    const loadDefaultListingItems = async (): Promise<void> => {
      if (
        variantId !== DefaultVariantId &&
        hasCapabilityInLanguage(state, Capability.ViewContent, DefaultVariantId)
      ) {
        await dispatch(deps.loadDefaultListingItems([contentItemId], abortSignal));
      }
    };

    const contentItemWithVariantServerModel = await deps.contentItemRepository.getItemWithVariant(
      contentItemId,
      variantId,
      abortSignal,
    );
    const contentItem = mapContentItemWithVariantFromServerModel(contentItemWithVariantServerModel);

    const loadReferences = async (): Promise<void> => {
      if (contentItem.variant) {
        await dispatch(deps.loadContentItemReferences(contentItem.variant.elements, abortSignal));
      }
    };

    await Promise.all([
      dispatch(deps.ensureLoadedContentTypes(new Set([contentTypeId]), abortSignal)),
      loadDefaultListingItems(),
      loadReferences(),
    ]);

    dispatch(deps.revalidateLinkedItemVariantElements(contentItem, contentTypeId));
    dispatch(deps.expandModularContentItemFinished(contentItem));
  };
