import { ContentState } from 'draft-js';
import { exportToServerPlainText } from '../../../../applications/richText/utils/export/plainText/exportToPlainText.ts';
import { StringOperationInput } from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedStringResult,
  isCumulatedStringOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type CreateTitleParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: 'CreateTitle';
    readonly inputs: {
      readonly content: StringOperationInput;
    };
  };

export const createCreateTitleParams = (
  contentState: ContentState,
  trackingData: ElementOperationTrackingData,
): CreateTitleParams => ({
  actionName: 'CreateTitle',
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'string',
      value: exportToServerPlainText(contentState),
    },
  },
  trackingData,
});

export type CreateTitleResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedStringResult>;

export const isCreateTitleMessage = (
  message: OperationResponseMessage,
): message is CreateTitleResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedStringOperationResponseMessage(message) || isErrorOperationMessage(message);
