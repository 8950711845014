import { combineReducers } from '@reduxjs/toolkit';
import { allIds } from './reducers/allIds.ts';
import { byId } from './reducers/byId.ts';
import { contentItemUsages } from './reducers/contentItemUsages.ts';
import { defaultById } from './reducers/defaultById.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';
import { nextContinuationToken } from './reducers/nextContinuationToken.ts';
import { raceConditionToken } from './reducers/raceConditionToken.ts';
import { usedSearchMethod } from './reducers/usedSearchMethod.ts';

export const listingContentItems = combineReducers({
  allIds,
  byId,
  contentItemUsages,
  defaultById,
  loadingStatus,
  nextContinuationToken,
  raceConditionToken,
  usedSearchMethod,
});
