import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import {
  HomeRoute,
  QuickstartRoute,
  YourContentRoute,
} from '../../../_shared/constants/routePaths.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { HomeSections } from './HomeSections.tsx';
import { QuickstartSection } from './QuickstartSection.tsx';
import { YourContentSection } from './YourContentSection.tsx';

type HomeProps = {
  readonly shouldShowQuickstart: boolean;
  readonly defaultRoute: string;
};

export const Home: React.FC<HomeProps> = (props) => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.Home)}>
      <Switch>
        {props.shouldShowQuickstart && (
          <Route path={QuickstartRoute}>
            <QuickstartSection />
          </Route>
        )}
        <Route path={YourContentRoute}>
          <YourContentSection />
        </Route>
        <Route path={HomeRoute}>
          <HomeSections />
        </Route>
        <Route>
          <Redirect {...getRedirectPropsWithSameSearch({ to: props.defaultRoute })} />
        </Route>
      </Switch>
    </div>
  );
};

Home.displayName = 'Home';
