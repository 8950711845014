import { UrlSlugMacro } from './macros.ts';

export const invalidUrlPattern = 'Provide an absolute URL path starting with http:// or https://.';
export const urlPatternWithWhiteSpaces = 'Remove extra white spaces from the preview URL.';
export const excessiveUrlSlugMacro = (typeName: string) =>
  `To use the ${UrlSlugMacro} macro, first add a URL slug element to the ${typeName} content type.`;
export const missingSpaceDomain =
  'Some of the selected spaces are missing a domain. Define the space domains first.';
export const invalidContentType =
  'Changes to this content type can’t be saved. Make sure the highlighted fields are filled in correctly.';
