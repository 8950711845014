import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ILoadListingItemsAction } from '../../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';

type Deps = {
  readonly loadListingItems: ILoadListingItemsAction;
};

export const createPreloadWebSpotlightRootItem =
  (deps: Deps) =>
  (spaceId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { spaces },
    } = getState();
    const space = spaces.byId.get(spaceId);

    assert(space, () => `${__filename}: Space with id ${spaceId} was not found.`);

    if (space.webSpotlightRootItemId) {
      await dispatch(deps.loadListingItems([space.webSpotlightRootItemId], abortSignal));
    }
  };
