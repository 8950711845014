import { isFocusVisible, useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { Node, PressEvent } from '@react-types/shared';
import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-restricted-paths
import { SearchPhraseHighlighterElement } from '../../../../../client/app/_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import { getDataUiFocusedAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { MenuItem, MenuItemProps } from '../../MenuItem/MenuItem.tsx';
import { MenuItemState } from '../../MenuItem/menuItemState.ts';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { ISelectItem } from '../types.ts';
import { getOptionId } from '../utils/getOptionId.ts';

const MenuItemMemoized = React.memo(MenuItem);

const shouldUseVirtualFocus = true;

const getMenuItemState = (
  isSelected: boolean,
  isDisabled: boolean,
  isReadOnly?: boolean,
  isInErrorState?: boolean,
): MenuItemState => {
  if (isInErrorState && isSelected) {
    return 'error-selected';
  }
  if (isInErrorState) {
    return 'error';
  }

  if (isSelected) {
    return 'selected';
  }

  if (isDisabled) {
    return 'disabled';
  }

  if (isReadOnly) {
    return 'readonly';
  }

  return 'default';
};

export interface IOptionProps<TItem extends ISelectItem<TItem>>
  extends Pick<
    MenuItemProps,
    | 'leadingElement'
    | 'trailingElements'
    | 'tooltipPlacement'
    | 'tooltipText'
    | 'linkPath'
    | 'complementaryText'
    | 'renderComplementaryText'
  > {
  readonly disabledTooltipText?: ISelectItem<TItem>['disabledTooltipText'];
  readonly highlightPattern: string;
  readonly isInErrorState?: boolean;
  readonly isReadOnly?: boolean;
  readonly isVirtualized?: boolean;
  readonly item: Node<TItem>;
  readonly level: number;
  readonly onPress?: (e: PressEvent) => void;
  readonly state: VerticalMenuState<TItem>;
}

export const Option = React.forwardRef(
  <TItem extends ISelectItem<TItem>>(
    {
      disabledTooltipText,
      highlightPattern,
      isInErrorState,
      isReadOnly,
      isVirtualized,
      item,
      level,
      onPress,
      state,
      tooltipPlacement = 'top-start',
      tooltipText,
      ...otherProps
    }: IOptionProps<TItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const { getSelectionManager, selectionManager } = state;

    const isDisabled = state.disabledKeys.has(item.key);
    const isSelected = selectionManager.isSelected(item.key);
    const isInteractionSuppressed = isDisabled || isReadOnly;

    const id = getOptionId(state.componentId, item.key);

    const handlePress = useCallback(
      (e: PressEvent) => {
        getSelectionManager().select(item.key);
        onPress?.(e);
      },
      [getSelectionManager, item.key, onPress],
    );

    const { pressProps } = usePress({
      onPress: isDisabled ? undefined : handlePress,
      preventFocusOnPress: shouldUseVirtualFocus,
    });

    const isFocused = selectionManager.focusedKey === item.key;

    const onHoverStart = useCallback(() => {
      if (!isFocusVisible()) {
        const _selectionManager = getSelectionManager();
        _selectionManager.setFocused(true);
        _selectionManager.setFocusedKey(item.key);
      }
    }, [item.key, getSelectionManager]);

    const { hoverProps } = useHover({
      isDisabled: isInteractionSuppressed,
      onHoverStart,
    });

    const renderLabel = useCallback(
      (text: string) => (
        <SearchPhraseHighlighterElement searchPhrase={highlightPattern} text={text} />
      ),
      [highlightPattern],
    );

    const mergedProps = mergeProps(hoverProps, pressProps, otherProps);
    const menuItemState = getMenuItemState(isSelected, isDisabled, isReadOnly, isInErrorState);

    return (
      <MenuItemMemoized
        aria-disabled={isInteractionSuppressed}
        aria-label={item.textValue}
        aria-selected={isSelected}
        data-key={item.key}
        disabledFocus
        renderLabel={renderLabel}
        id={id}
        isHovered={isFocused}
        menuItemState={menuItemState}
        nestedLevel={level}
        ref={forwardedRef}
        role="option"
        text={item.textValue}
        tooltipPlacement={tooltipPlacement}
        tooltipText={
          menuItemState === 'disabled'
            ? (disabledTooltipText ?? item.value?.disabledTooltipText)
            : (tooltipText ?? item.value?.tooltipText)
        }
        {...mergedProps}
        {...getDataUiFocusedAttribute(isFocused)}
      />
    );
  },
);

Option.displayName = 'Option';
