import { UtcTimeZoneId } from '../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { IDateTimeTypeElement } from '../../../contentInventory/content/models/contentTypeElements/DateTimeTypeElement.ts';
import {
  DateTimeItemElement,
  EmptyDateTimeItemElement,
} from '../../models/contentItemElements/DateTimeItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createDateTimeItemElement: ItemElementCreator<
  IDateTimeTypeElement,
  DateTimeItemElement
> = ({ defaultValue, elementId }, omitDefaultValue): DateTimeItemElement => ({
  ...EmptyDateTimeItemElement,
  elementId,
  displayTimeZone: defaultValue ? UtcTimeZoneId : EmptyDateTimeItemElement.displayTimeZone,
  value: omitDefaultValue ? EmptyDateTimeItemElement.value : defaultValue,
});
