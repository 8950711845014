import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import {
  loadAssets,
  loadListingContentItemsByIds,
} from '../../../../../data/actions/thunkDataActions.ts';
import { createEnsureLoadedContentTypesAction } from './thunks/ensureLoadedContentTypes.ts';
import { createEnsureLoadedListingItemsAction } from './thunks/ensureLoadedListingItems.ts';
import { createLoadContentTypeReferencesForItemEditingAction } from './thunks/loadContentTypeReferencesForItemEditing.ts';
import { createLoadDefaultListingItemsAction } from './thunks/loadDefaultListingItems.ts';
import { createLoadListingItemsAction } from './thunks/loadListingItems.ts';
import { createLoadRichTextReferences } from './thunks/loadRichTextReferences.ts';

const { contentItemRepository } = repositoryCollection;

export const loadListingItems = createLoadListingItemsAction({
  loadListingContentItemsByIds,
});

export const loadDefaultListingItems = createLoadDefaultListingItemsAction({
  loadListingContentItemsByIds,
});

export const ensureLoadedListingItems = createEnsureLoadedListingItemsAction({ loadListingItems });

export const loadGuidelinesRichTextReferences = createLoadRichTextReferences({
  loadAssets,
  // Guidelines do not need content components so we don't provide ability to load content types here, yet share the code base with rich text.
  // If we did support components in guidelines, the loading could end up in an infinite loop when the type would reference itself.
  ensureLoadedContentTypes: null,
  loadListingItems,
});

export const loadContentTypeReferencesForItemEditing =
  createLoadContentTypeReferencesForItemEditingAction({
    loadGuidelinesRichTextReferences,
  });

export const ensureLoadedContentTypes = createEnsureLoadedContentTypesAction({
  contentItemRepository,
  loadContentTypeReferencesForItemEditing,
});

export const loadRichTextReferences = createLoadRichTextReferences({
  loadAssets,
  ensureLoadedContentTypes,
  loadListingItems,
});
