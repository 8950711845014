import { SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ActionItem } from '../../../../_shared/components/ActionMenu.tsx';
import { RoleNameForProjectListing } from '../../../../_shared/components/RoleNameForListings.tsx';
import { OptionalTooltip } from '../../../../_shared/uiComponents/Tooltip/OptionalTooltip.tsx';
import {
  DataUiAction,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CopyState } from '../../constants/copyState.ts';
import { IProjectViewModel } from '../../selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../types/projectUiTransitionState.ts';
import { isCloningFailed, isCloningInProgress } from '../../utils/copyStateUtils.ts';
import { CopyingDeliverDataActionButton } from './ButtonActions/CopyingDeliverDataActionButton.tsx';
import { CopyingDraftDataActionButton } from './ButtonActions/CopyingDraftDataActionButton.tsx';
import { FailedActionButton } from './ButtonActions/FailedActionButton.tsx';
import { OpenProjectActionButton } from './ButtonActions/OpenProjectActionButton.tsx';
import { ProjectTransitionButton } from './ButtonActions/ProjectTransitionButton.tsx';
import { DeactivationConfirmationDialog } from './DeactivationConfirmationDialog.tsx';
import { MyProjectMoreActions } from './MyProjectMoreActions.tsx';

interface IMyProjectProps {
  readonly isSettingUp: boolean;
  readonly onToggleProjectActiveState: (activate: ProjectUiTransitionState) => void;
  readonly onCloneProject: () => void;
  readonly onDeleteProject: () => void;
  readonly onRedirectToProjectSettings: () => void;
  readonly project: IProjectViewModel;
}

export const MyProject: React.FC<IMyProjectProps> = ({
  onCloneProject,
  onDeleteProject,
  project,
  onToggleProjectActiveState,
  onRedirectToProjectSettings,
  isSettingUp,
}) => {
  const [showDeactivationDialog, setShowDeactivationDialog] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSettingUp) {
      ref.current?.scrollIntoView(true);
    }
  }, [isSettingUp]);

  const renderStatusTag = (): ReactNode => {
    if (project.planIsExpired) {
      return (
        <div className="my-project__status">
          <SimpleStatusError
            label="Project Expired"
            tooltipText="Project Expired"
            tooltipPlacement="bottom"
          />
        </div>
      );
    }

    if (project.planIsSuspended) {
      return (
        <div className="my-project__status">
          <SimpleStatusError
            label="Project Suspended"
            tooltipText="Project Suspended"
            tooltipPlacement="bottom"
          />
        </div>
      );
    }

    return null;
  };

  const activateProject = (): void => {
    setShowDeactivationDialog(false);
    onToggleProjectActiveState(ProjectUiTransitionState.Activating);
  };

  const archiveProject = (): void => {
    setShowDeactivationDialog(false);
    onToggleProjectActiveState(ProjectUiTransitionState.Deactivating);
  };

  const isProjectInTransitionStatus =
    project.uiTransitionState === ProjectUiTransitionState.Activating ||
    project.uiTransitionState === ProjectUiTransitionState.Deactivating ||
    project.uiTransitionState === ProjectUiTransitionState.SettingUp ||
    project.isBeingDeleted;

  const renderDeactivationConfirmationDialog = (): ReactNode =>
    showDeactivationDialog ? (
      <DeactivationConfirmationDialog
        deactivateProject={archiveProject}
        closeDeactivationConfirmationDialog={() => setShowDeactivationDialog(false)}
        projectName={project.projectName}
        environmentName={project.environmentName}
      />
    ) : null;

  const renderMainButton = (): ReactNode => {
    const isProjectSettingUp = project.uiTransitionState === ProjectUiTransitionState.SettingUp;
    const canOpenProject = !project.inactive && !isProjectInTransitionStatus;

    if (project.copyState === CopyState.CopyingDeliver) {
      return <CopyingDeliverDataActionButton projectId={project.projectId} />;
    }

    if (project.copyState === CopyState.Failed) {
      return (
        <FailedActionButton
          onDelete={onDeleteProject}
          isBeingDeleted={project.isBeingDeleted}
          isAdminOnProject={project.isAdminOnProject}
        />
      );
    }

    if (isProjectSettingUp) {
      const buttonText = project.copyType === 'Clone' ? 'Cloning project' : 'Creating project';
      return <CopyingDraftDataActionButton text={buttonText} />;
    }

    if (project.isBeingDeleted) {
      return <ProjectTransitionButton isStatusChanging project={project} />;
    }

    if (!canOpenProject) {
      return (
        <ProjectTransitionButton
          project={project}
          isStatusChanging={isProjectInTransitionStatus}
          onActivate={activateProject}
        />
      );
    }

    if (!project.planIsExpired && !project.planIsSuspended) {
      return <OpenProjectActionButton projectId={project.projectId} />;
    }

    return null;
  };

  const renderButtons = (): ReactNode => {
    const hasAccessToMoreActions =
      !isCloningInProgress(project.copyState) && !isCloningFailed(project.copyState);
    const deleteAction: ActionItem = {
      text: 'Delete',
      onClick: onDeleteProject,
      disabledState: {
        isDisabled: !project.isAdminOnProject,
        disabledMessage: project.isAdminOnProject
          ? undefined
          : 'Only subscription admins can delete projects.',
      },
      isDestructive: true,
      hasCapabilityToExecuteAction: project.isAdminOnProject,
      dataUiAction: DataUiAction.Delete,
    };
    const archivedMoreActions: ReadonlyArray<ActionItem> = [deleteAction];
    const activeMoreActions: ReadonlyArray<ActionItem> = [
      {
        text: 'Project settings',
        onClick: onRedirectToProjectSettings,
        disabledState: { isDisabled: false },
        isDestructive: false,
        hasCapabilityToExecuteAction: true,
        dataUiAction: DataUiAction.OpenProjectMenu,
      },
      {
        text: 'Clone project',
        onClick: onCloneProject,
        disabledState: {
          isDisabled: !project.isAdminOnProject,
          disabledMessage: project.isAdminOnProject
            ? undefined
            : 'Only subscription admins can clone projects.',
        },
        isDestructive: false,
        hasCapabilityToExecuteAction: project.isAdminOnProject,
        dataUiAction: DataUiAction.Clone,
      },
      {
        text: 'Archive',
        onClick: () => setShowDeactivationDialog(true),
        disabledState: {
          isDisabled: !project.isAdminOnProject,
          disabledMessage: project.isAdminOnProject
            ? undefined
            : 'Only subscription admins can archive projects.',
        },
        isDestructive: true,
        hasCapabilityToExecuteAction: project.isAdminOnProject,
        dataUiAction: DataUiAction.Archive,
        withSeparator: true,
      },
      deleteAction,
    ];

    return (
      <>
        {renderMainButton()}
        {hasAccessToMoreActions && !isProjectInTransitionStatus && (
          <div className="my-project__more-actions">
            <MyProjectMoreActions
              actions={project.inactive ? archivedMoreActions : activeMoreActions}
            />
          </div>
        )}
      </>
    );
  };

  const isIndicatorShown =
    project.uiTransitionState === ProjectUiTransitionState.Activating ||
    project.uiTransitionState === ProjectUiTransitionState.Deactivating;

  const copyStatesWithMessage = [CopyState.CopyingDeliver, CopyState.Failed];

  return (
    <div
      className={classNames('my-project', {
        'my-project--is-archived': project.inactive,
        'my-project--is-failed':
          project.copyState === CopyState.Failed && !isProjectInTransitionStatus,
      })}
      key={project.projectId}
      ref={ref}
      {...getDataUiObjectNameAttribute(project.projectName)}
    >
      {isIndicatorShown && <div className="my-project__progress-indicator" />}
      {renderDeactivationConfirmationDialog()}
      <OptionalTooltip className="my-project__title" placement="bottom" text={project.projectName}>
        {project.projectName}
      </OptionalTooltip>
      <div className="my-project__subscription-name">{project.subscriptionName}</div>
      <div className="my-project__separator" />
      {!copyStatesWithMessage.includes(project.copyState) &&
        project.projectId === project.masterEnvironmentId && (
          <div className="my-project__user">
            <RoleNameForProjectListing roles={project.roles} isAdmin={project.isAdminOnProject} />
          </div>
        )}
      <div className="my-project__actions-pane">
        {renderStatusTag()}
        {renderButtons()}
      </div>
    </div>
  );
};

MyProject.displayName = 'MyProject';
