import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import {
  ContentType_Listing_ArchiveContentTypesFinished,
  ContentType_Listing_ArchiveContentTypesStarted,
  ContentType_Listing_ResetOperationState,
  ContentType_Listing_RestoreContentTypesFinished,
  ContentType_Listing_RestoreContentTypesStarted,
  ContentType_Listing_RouteEntered,
  ContentType_Listing_SelectedTypesUpdated,
} from '../../constants/contentTypesActionTypes.ts';
import {
  ContentTypeListingOperationStatus,
  ContentTypeListingOperationType,
  getContentTypeListingFailedOperationMessage,
  getContentTypeListingOperationMessage,
  getContentTypeListingOperationMessageStyle,
} from '../../utils/contentTypeListingOperationStatus.ts';

const initialListingOperationStatus: ContentTypeListingOperationStatus = {
  message: 'Content types',
  messageStyle: StatusMessageStyle.Normal,
  operationType: ContentTypeListingOperationType.NoOperation,
  affectedContentTypeIds: Immutable.Set<Uuid>(),
  restorableContentTypes: Immutable.Set<IContentType>(),
};

function getListingOperationStatus(
  operationType: ContentTypeListingOperationType,
  affectedTypeIds: Immutable.Set<Uuid>,
  restorableTypes: Immutable.Set<IContentType>,
  sucessfulIds: Immutable.Set<Uuid>,
  failedIds: Immutable.Set<Uuid>,
): ContentTypeListingOperationStatus {
  return {
    message:
      getContentTypeListingOperationMessage(operationType, sucessfulIds) +
      getContentTypeListingFailedOperationMessage(failedIds),
    messageStyle: getContentTypeListingOperationMessageStyle(operationType),
    operationType,
    affectedContentTypeIds: affectedTypeIds,
    restorableContentTypes: restorableTypes,
  };
}

export function listingOperationStatus(
  state: ContentTypeListingOperationStatus = initialListingOperationStatus,
  action: Action,
): ContentTypeListingOperationStatus {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
    case ContentType_Listing_RouteEntered: {
      return initialListingOperationStatus;
    }

    case ContentType_Listing_ResetOperationState: {
      if (state === action.payload.operationToReset) {
        return initialListingOperationStatus;
      }
      return state;
    }

    case ContentType_Listing_SelectedTypesUpdated: {
      const { selectedContentTypeIds } = action.payload;
      if (selectedContentTypeIds.isEmpty()) {
        return initialListingOperationStatus;
      }
      return getListingOperationStatus(
        ContentTypeListingOperationType.Selected,
        selectedContentTypeIds,
        state.restorableContentTypes,
        selectedContentTypeIds,
        Immutable.Set<Uuid>(),
      );
    }

    case ContentType_Listing_ArchiveContentTypesStarted: {
      const affectedContentTypeIds: Immutable.Set<Uuid> = action.payload.affectedContentTypeIds;
      return getListingOperationStatus(
        ContentTypeListingOperationType.Archiving,
        affectedContentTypeIds,
        Immutable.Set<IContentType>(),
        affectedContentTypeIds,
        Immutable.Set<Uuid>(),
      );
    }

    case ContentType_Listing_ArchiveContentTypesFinished: {
      const affectedContentTypes: Immutable.Set<IContentType> = action.payload.affectedContentTypes;
      const affectedContentTypeIds = affectedContentTypes
        .map((type: IContentType) => type.id)
        .toSet();
      const successfulIds: Immutable.Set<Uuid> = action.payload.successfulIds;
      const archivedContentTypes = affectedContentTypes
        .filter((type: IContentType) => successfulIds.has(type.id))
        .toSet();
      return getListingOperationStatus(
        ContentTypeListingOperationType.Archived,
        affectedContentTypeIds,
        archivedContentTypes,
        successfulIds,
        affectedContentTypeIds.subtract(successfulIds),
      );
    }

    case ContentType_Listing_RestoreContentTypesFinished: {
      const affectedContentTypeIds: Immutable.Set<Uuid> = action.payload.affectedContentTypeIds;
      const successfulIds = action.payload.successfulIds;
      return getListingOperationStatus(
        ContentTypeListingOperationType.Restored,
        affectedContentTypeIds,
        Immutable.Set<IContentType>(),
        successfulIds,
        affectedContentTypeIds.subtract(successfulIds),
      );
    }

    case ContentType_Listing_RestoreContentTypesStarted: {
      const affectedContentTypes: Immutable.Set<IContentType> = action.payload.affectedContentTypes;
      const affectedContentTypeIds = affectedContentTypes
        .map((type: IContentType) => type.id)
        .toSet();
      return getListingOperationStatus(
        ContentTypeListingOperationType.Restoring,
        affectedContentTypeIds,
        Immutable.Set<IContentType>(),
        affectedContentTypeIds,
        Immutable.Set<Uuid>(),
      );
    }

    default:
      return state;
  }
}
