import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { mergeRefs } from '@react-aria/utils';
import { Key } from '@react-types/shared';
import React, { useState, useMemo, useCallback, RefObject, RefCallback, Ref } from 'react';
import { VerticalMenuItem } from '../../VerticalMenu/types.ts';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { useVerticalMenu } from '../../VerticalMenu/useVerticalMenu.ts';
import { findDOMIndex } from '../utils.ts';

export type Descendant = Pick<VerticalMenuItem<any>, 'id' | 'label'> &
  Readonly<{
    href?: string;
    elementRef: RefObject<HTMLElement>;
    onAction?: (key: Key) => void;
    target?: string;
  }>;

type DescendantContextState = Readonly<{
  descendants: ReadonlyArray<Descendant>;
  registerDescendant: (menuItem: Descendant) => void;
  unregisterDescendant: (id: Descendant['id']) => void;
  state: VerticalMenuState<any>;
}>;

export const DescendantContext = React.createContext<DescendantContextState | null>(null);

type DescendantContextProviderProps = Readonly<{
  children: React.ReactNode;
  descendants: ReadonlyArray<Descendant>;
  setDescendants: React.Dispatch<React.SetStateAction<ReadonlyArray<Descendant>>>;
  state: VerticalMenuState<any>;
}>;

export const DescendantContextProvider: React.FC<DescendantContextProviderProps> = ({
  children,
  descendants,
  setDescendants,
  state,
}) => {
  const registerDescendant = useCallback(
    (newDescendant: Descendant) => {
      setDescendants((prevDescendants) => {
        const existingIndex = prevDescendants.findIndex(
          (descendant) => descendant.id === newDescendant.id,
        );

        if (existingIndex !== -1) {
          return prevDescendants.toSpliced(existingIndex, 1, newDescendant);
        }

        const index = findDOMIndex(prevDescendants, newDescendant.elementRef);

        return prevDescendants.toSpliced(index, 0, newDescendant);
      });
    },
    [setDescendants],
  );

  const unregisterDescendant = useCallback(
    (id: Descendant['id']) => {
      setDescendants((prevDescendants) =>
        prevDescendants.filter(({ id: itemId }) => itemId !== id),
      );
    },
    [setDescendants],
  );

  const contextState = useMemo<DescendantContextState>(
    () => ({
      descendants,
      registerDescendant,
      unregisterDescendant,
      state,
    }),
    [descendants, registerDescendant, unregisterDescendant, state],
  );

  return <DescendantContext.Provider value={contextState}>{children}</DescendantContext.Provider>;
};

export const useDescendantContextInit = () => {
  const [descendants, setDescendants] = useState<ReadonlyArray<Descendant>>([]);

  const { verticalMenuState } = useVerticalMenu(descendants);

  return {
    descendants,
    setDescendants,
    state: verticalMenuState,
  };
};

export const useDescendant = <TElement extends HTMLElement = HTMLElement>(
  descendantProps: Omit<Descendant, 'elementRef'>,
  ref: Ref<TElement>,
) => {
  const { registerDescendant, unregisterDescendant } = useEnsuredContext(DescendantContext);

  const { href, id, label, onAction, target } = descendantProps;
  const { refObject, refToForward } = useAttachRef(ref);

  const initializedRef: RefCallback<TElement> = useCallback(
    (element) => {
      if (element) {
        registerDescendant({
          href,
          id,
          label,
          onAction,
          target,
          elementRef: refObject,
        });
      } else {
        unregisterDescendant(id);
      }
    },
    [href, id, label, onAction, refObject, registerDescendant, unregisterDescendant, target],
  );

  const uninitializedRef: RefCallback<TElement> = useCallback(
    (element) => {
      if (element) {
        registerDescendant({
          href,
          id,
          label,
          onAction,
          target,
          elementRef: refObject,
        });
      }
    },
    [href, id, label, onAction, refObject, registerDescendant, target],
  );

  const mergedInitializedRef = useMemo(
    () => mergeRefs<TElement>(refToForward, initializedRef),
    [refToForward, initializedRef],
  );
  const mergedUninitializedRef = useMemo(
    () => mergeRefs<TElement>(refToForward, uninitializedRef),
    [refToForward, uninitializedRef],
  );

  return {
    uninitializedRef: mergedUninitializedRef,
    initializedRef: mergedInitializedRef,
  };
};
