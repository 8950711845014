import React from 'react';
import { BarItemError } from '../../../../../_shared/components/BarItems/BarItemError.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import {
  ContentChangesWarningModal,
  ContentChangesWarningSubtitle,
} from '../../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import { useScrollIntoViewOnMount } from '../../../../../_shared/hooks/useScrollIntoViewOnMount.ts';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { LanguageFallbackOption } from '../../models/LanguageFallbackOption.type.ts';
import { LanguageFormShape } from '../../models/languageFormShape.type.ts';
import { LanguageEditorBarItemActionButtons } from '../languageEditorActionButtons/LanguageEditorBarItemActionButtons.tsx';
import { LanguageFormFields } from './LanguageFormFields.tsx';

type LanguageEditorProps = {
  readonly language: ILanguage;
  readonly areLanguageRolesEnabled: boolean;
  readonly fallbackLanguageOptions: ReadonlyArray<LanguageFallbackOption>;
  readonly isCodenameOrFallbackModified: boolean;
  readonly isEditedLanguageNew: boolean;
  readonly isLanguageAlreadyUsed: boolean;
  readonly languageName: string;
  readonly formProps: HookFormProps<LanguageFormShape>;
  readonly isLanguageLimitReached: boolean;
  readonly isSetAsFallbackLanguage: boolean;
  readonly onActivate: (() => void) | null;
  readonly onCancel: () => void;
  readonly onDeactivate: (() => void) | null;
  readonly onSubmit: () => void;
  readonly onDismissLongProcessingChangesWarning: () => void;
  readonly showLargeProjectSaveWarning: boolean;
};

export const LanguageEditor: React.FC<LanguageEditorProps> = ({
  areLanguageRolesEnabled,
  fallbackLanguageOptions,
  formProps,
  isCodenameOrFallbackModified,
  isEditedLanguageNew,
  isLanguageAlreadyUsed,
  isLanguageLimitReached,
  isSetAsFallbackLanguage,
  language,
  languageName,
  onActivate,
  onCancel,
  onDeactivate,
  onSubmit,
  onDismissLongProcessingChangesWarning,
  showLargeProjectSaveWarning,
}) => {
  const scrollRef = useScrollIntoViewOnMount<HTMLDivElement>();
  const [warningMessage, setWarningMessage] = React.useState<string | undefined>(undefined);
  const [showModal, setShowModal] = React.useState(false);

  const onSave = () => {
    if (showLargeProjectSaveWarning && isCodenameOrFallbackModified) {
      setShowModal(true);
    } else {
      onSubmit();
    }
  };

  return (
    <HotkeysHandler
      handlers={{
        onEscape: onCancel,
        onEnter: isLanguageActive(language) ? onSave : undefined,
      }}
      mode={isLanguageActive(language) ? HotkeysMode.InPlace : HotkeysMode.WindowOnly}
    >
      <div
        className="bar-item__wrapper"
        ref={scrollRef}
        {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
      >
        <div className="bar-item__pane bar-item__pane--is-expanded">
          <BarItemExpandedSimpleHeader
            dataUiObjectName={languageName}
            isDraggable
            renderTitle={() => languageName}
          />

          <LanguageFormFields
            disabled={!isLanguageActive(language)}
            fallbackLanguageOptions={fallbackLanguageOptions}
            initialCodename={language.codename}
            formProps={formProps}
          />
          <LanguageEditorBarItemActionButtons
            isLanguageActive={isLanguageActive(language)}
            isLanguageLimitReached={isLanguageLimitReached}
            onCancel={onCancel}
            onSubmit={onSubmit}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
            onDismissLongProcessingChangesWarning={onDismissLongProcessingChangesWarning}
            isCodenameOrFallbackModified={isCodenameOrFallbackModified}
            showLargeProjectSaveWarning={showLargeProjectSaveWarning}
            isSetAsFallbackLanguage={isSetAsFallbackLanguage}
            setWarningMessage={setWarningMessage}
            isLanguageAlreadyUsed={isLanguageAlreadyUsed}
            areLanguageRolesEnabled={areLanguageRolesEnabled}
            language={language}
            isEditedLanguageNew={isEditedLanguageNew}
          />
          <BarItemError showError={!!warningMessage} error={warningMessage} />
          <ContentChangesWarningModal
            onCancel={() => setShowModal(false)}
            onConfirm={onSubmit}
            onDiscardChanges={onCancel}
            onDismissWarning={onDismissLongProcessingChangesWarning}
            isOpen={showModal}
            warningSubtitle={ContentChangesWarningSubtitle.LanguageSettingsEditing}
          />
        </div>
      </div>
    </HotkeysHandler>
  );
};

LanguageEditor.displayName = 'LanguageEditor';
