import React, { useCallback } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ICustomTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import { useContentComponent } from '../../../../richText/hooks/useContentComponent.ts';
import { ICustomItemElement } from '../../../models/contentItemElements/CustomItemElement.ts';
import { ElementValue } from '../../../types/CustomElementApi.ts';
import { CustomElementSandbox } from '../../ContentItemEditing/containers/elements/customElement/CustomElementSandbox.tsx';
import { getElementValueForCustomElement } from '../../ContentItemEditing/utils/getElementValueForCustomElement.ts';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

export const ContentComponentCustomElement: React.FC<
  IContentComponentItemElementOwnProps<ICustomItemElement, ICustomTypeElement>
> = ({
  autoFocus,
  contentComponentId,
  disabled,
  elementData,
  onUpdate,
  typeElement,
  validationResultSelectorId,
}) => {
  const contentComponent = useContentComponent(contentComponentId);
  const contentType = useSelector(
    (state) =>
      contentComponent &&
      (state.contentApp.loadedContentItemTypes.get(contentComponent.contentTypeId) ?? null),
  );

  const onContentChange = useCallback(
    (value: string): void => {
      onUpdate({
        ...elementData,
        value,
      });
    },
    [elementData, onUpdate],
  );

  const getElementValue = useCallback(
    (elementCodename: string): ElementValue | undefined =>
      contentComponent && contentType
        ? getElementValueForCustomElement(
            contentComponent.elements,
            typeElement.elementId,
            contentType.contentElements,
            elementCodename,
          )
        : undefined,
    [contentComponent, contentType, typeElement],
  );

  if (!contentComponent || !contentType) {
    return null;
  }

  return (
    <ContentComponentItemElement
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
      disabled={disabled}
      contentComponentId={contentComponentId}
    >
      <CustomElementSandbox
        typeElement={typeElement}
        value={elementData.value}
        onChange={onContentChange}
        typeElements={contentType.contentElements}
        elements={contentComponent.elements}
        getElementValue={getElementValue}
        autoFocus={autoFocus}
        className="custom-element__sandbox"
        disabled={disabled}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentCustomElement.displayName = 'ContentComponentCustomElement';
