import React from 'react';
import { DataUiElement } from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemElementStatus } from '../../ContentItemElementStatus.tsx';
import { StatusMessage } from './StatusMessage.tsx';

interface IDefaultValueStatusProps {
  readonly isStatusRendered: boolean;
  readonly isValueDefault: boolean;
}

export const DefaultValueStatus: React.FC<React.PropsWithChildren<IDefaultValueStatusProps>> = ({
  isStatusRendered,
  isValueDefault,
}): JSX.Element | null => {
  return isStatusRendered ? (
    <ContentItemElementStatus isHidden={!isValueDefault}>
      <span className="content-item-element__status-pane">
        <StatusMessage
          message="This is the element’s default value."
          dataUiElement={isValueDefault ? DataUiElement.DefaultValueStatusMessage : undefined}
        />
      </span>
    </ContentItemElementStatus>
  ) : null;
};

DefaultValueStatus.displayName = 'DefaultValueStatus';
