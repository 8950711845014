import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionAdminServerModel } from '../../../../../repositories/serverModels/SubscriptionAdminServerModel.type.ts';
import {
  ISubscriptionAdmin,
  createSubscriptionAdminDomainModel,
} from '../../../shared/models/SubscriptionAdmin.ts';
import {
  Subscription_AdminListing_DeleteAdminsFailed,
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_AdminListing_DeleteAdminsStarted,
} from '../../constants/actionTypes.ts';

interface IDeleteAdminsDependencies {
  readonly subscriptionRepository: {
    readonly deleteAdmins: (
      subscriptionId: Uuid,
      selectedAdminIds: UserId[],
    ) => Promise<ISubscriptionAdminServerModel[]>;
  };
  readonly deactivateUsersOnSubscriptionProjects: (
    userId: Immutable.Set<UserId>,
    subscriptionId: Uuid,
  ) => ThunkPromise;
  readonly loadSubscriptionUsers: (subscriptionId: Uuid) => ThunkPromise;
}

const started = () =>
  ({
    type: Subscription_AdminListing_DeleteAdminsStarted,
  }) as const;

const finished = (deletedAdmins: Immutable.List<ISubscriptionAdmin>, subscriptionId: Uuid) =>
  ({
    type: Subscription_AdminListing_DeleteAdminsFinished,
    payload: {
      deletedAdmins,
      subscriptionId,
    },
  }) as const;

const failed = () =>
  ({
    type: Subscription_AdminListing_DeleteAdminsFailed,
  }) as const;

export type DeleteAdminsActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const deleteAdminsCreator =
  (dependencies: IDeleteAdminsDependencies) =>
  (
    subscriptionId: Uuid,
    selectedAdminIds: Immutable.Set<UserId>,
    deactivateFromProjects: boolean,
  ): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started());

    try {
      const deletedAdmins = await dependencies.subscriptionRepository.deleteAdmins(
        subscriptionId,
        selectedAdminIds.toArray(),
      );
      const admins = Immutable.List(deletedAdmins.map(createSubscriptionAdminDomainModel));

      if (deactivateFromProjects) {
        dispatch(
          dependencies.deactivateUsersOnSubscriptionProjects(selectedAdminIds, subscriptionId),
        );
      }

      dispatch(dependencies.loadSubscriptionUsers(subscriptionId));
      dispatch(finished(admins, subscriptionId));
    } catch (error) {
      dispatch(failed());

      throw error;
    }
  };
