export enum InsertPositionPlacement {
  Start = 'start',
  Before = 'before',
  After = 'after',
  End = 'end',
}

export interface IFixedInsertPosition {
  readonly placement: InsertPositionPlacement.Start | InsertPositionPlacement.End;
}

export interface IRelativeInsertPosition {
  readonly targetId: string;
  readonly placement: InsertPositionPlacement.Before | InsertPositionPlacement.After;
}

export type InsertPosition = IFixedInsertPosition | IRelativeInsertPosition;

interface ISmartLinkCommandData {
  readonly itemId: Uuid;
  readonly projectId: string;
  readonly languageCodename: string;
  readonly contentComponentId?: Uuid;
  readonly elementCodename: string;
  readonly insertPosition: InsertPosition;
}

export enum SmartLinkCommandType {
  CreateComponent = 'CreateComponent',
  CreateLinkedItem = 'CreateLinkedItem',
  InsertLinkedItem = 'InsertLinkedItem',
}

interface ICreateComponentSmartLinkCommand {
  readonly type: SmartLinkCommandType.CreateComponent;
  readonly data: ISmartLinkCommandData;
}

interface ICreateLinkedItemSmartLinkCommand {
  readonly type: SmartLinkCommandType.CreateLinkedItem;
  readonly data: ISmartLinkCommandData;
}

interface IInsertLinkedItemSmartLinkCommand {
  readonly type: SmartLinkCommandType.InsertLinkedItem;
  readonly data: ISmartLinkCommandData;
}

export type SmartLinkCommand =
  | ICreateComponentSmartLinkCommand
  | ICreateLinkedItemSmartLinkCommand
  | IInsertLinkedItemSmartLinkCommand;
