import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { LoadListingContentItemsByIdsAction } from '../../../../../../data/actions/thunks/listingContentItems/loadListingContentItemsByIds.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingContentItemServerModel } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { Content_LoadedItems_DefaultListingItemsLoaded } from '../../../../../contentInventory/content/constants/contentActionTypes.ts';
import { ILoadListingItemsAction } from './loadListingItems.ts';

export const loaded = (contentItems: ReadonlyArray<IListingContentItem>) =>
  ({
    type: Content_LoadedItems_DefaultListingItemsLoaded,
    payload: {
      contentItems,
    },
  }) as const;

export type LoadDefaultListingItemsActionTypes = ReturnType<typeof loaded>;

interface IDeps {
  loadListingContentItemsByIds: LoadListingContentItemsByIdsAction;
}

export const createLoadDefaultListingItemsAction =
  (deps: IDeps): ILoadListingItemsAction =>
  (
    contentItemIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ): ThunkPromise<ReadonlyArray<IListingContentItem>> =>
  async (dispatch) => {
    if (contentItemIds.length === 0) {
      return [];
    }

    const data = await dispatch(
      deps.loadListingContentItemsByIds(DefaultVariantId, contentItemIds, abortSignal),
    );
    const contentItems = data
      .filter((item: IListingContentItemServerModel) => !!item)
      .map(getListingContentItemFromJS);

    dispatch(loaded(contentItems));

    return contentItems;
  };
