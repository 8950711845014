import { usePrevious } from '@kontent-ai/hooks';
import React from 'react';
import {
  UseClickOutsideCallbackReturnValue,
  useClickOutsideCallbackState,
} from '../../../../../../_shared/hooks/useClickOutsideCallbackState.ts';
import { EditingAction } from '../../models/EditingAction.ts';

export const useHandleClickOutsideInActionsMenu = (
  defaultHandleClickOutside: () => void,
  activatedAction: EditingAction,
): UseClickOutsideCallbackReturnValue => {
  const callbackState = useClickOutsideCallbackState({
    initialOnClickOutside: defaultHandleClickOutside,
  });
  const setHandleClickOutside = callbackState[2];

  // reset callback to default after return to main menu
  const previousActivatedAction = usePrevious(activatedAction);
  React.useEffect(() => {
    if (
      previousActivatedAction !== activatedAction &&
      activatedAction === EditingAction.showMainMenu
    ) {
      setHandleClickOutside(defaultHandleClickOutside);
    }
  }, [previousActivatedAction, activatedAction, defaultHandleClickOutside, setHandleClickOutside]);

  return callbackState;
};
