export const isValidJsonObject = (jsonString: string): boolean => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object' && !Array.isArray(o)) {
      return true;
    }
  } catch {
    return false;
  }

  return false;
};

export const compressJsonString = (jsonString: string): string => {
  return JSON.stringify(JSON.parse(jsonString));
};

export const createFormattedJsonString = (jsonString: string): string => {
  return JSON.stringify(JSON.parse(jsonString), null, 4);
};

export const jsonTryParse = (arg: string): unknown => {
  try {
    return JSON.parse(arg);
  } catch {
    return null;
  }
};
