import { ContentState } from 'draft-js';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { AiActionName } from '../AiActionName.type.ts';
import { DraftJsOperationInput, StringOperationInput } from '../AiServerModels.input.type.ts';
import {
  AssetOperationTrackingData,
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type TranslateContentParams = MultipleInputsOperationParamsModelBase<
  ElementOperationTrackingData | AssetOperationTrackingData
> & {
  readonly actionName: AiActionName.TranslateContent;
  readonly inputs: {
    readonly content: DraftJsOperationInput;
    readonly sourceLanguageName: StringOperationInput;
    readonly sourceLanguageCode: StringOperationInput;
    readonly targetLanguageName: StringOperationInput;
    readonly targetLanguageCode: StringOperationInput;
  };
};

export const createTranslateContentRequest = (
  contentState: ContentState,
  sourceLanguageName: string,
  sourceLanguageCode: string,
  targetLanguageName: string,
  targetLanguageCode: string,
  trackingData: ElementOperationTrackingData | AssetOperationTrackingData,
): TranslateContentParams => ({
  actionName: AiActionName.TranslateContent,
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
    sourceLanguageName: {
      type: 'string',
      value: sourceLanguageName,
    },
    sourceLanguageCode: {
      type: 'string',
      value: sourceLanguageCode,
    },
    targetLanguageName: {
      type: 'string',
      value: targetLanguageName,
    },
    targetLanguageCode: {
      type: 'string',
      value: targetLanguageCode,
    },
  },
  trackingData,
});

export type TranslateContentResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isTranslateContentMessage = (
  message: OperationResponseMessage,
): message is TranslateContentResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
