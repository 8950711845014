export const getAssetAlreadyModifiedErrorMessage = (isAiImageRecognitionEnabled: boolean) =>
  `This asset was recently updated by another user${isAiImageRecognitionEnabled ? ' or the AI assistant' : ''}. To save your changes, open the asset in a new tab and reapply your edits.`;

export const getAssetAlreadyModifiedSaveButtonTooltip = (isAiImageRecognitionEnabled: boolean) => {
  const assetAlreadyModifiedErrorMessage = getAssetAlreadyModifiedErrorMessage(
    isAiImageRecognitionEnabled,
  );

  return `Saving is disabled because ${assetAlreadyModifiedErrorMessage.substring(0, 1).toLowerCase()}${assetAlreadyModifiedErrorMessage.substring(1)}`;
};
