import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';

export const OriginIndexNotFoundEmptyState: React.FC = () => (
  <EmptyState>
    <EmptyState.Title>No suggestions yet</EmptyState.Title>
    <EmptyState.Content>
      We’re analyzing this content item to give you the right suggestions. Try again in a moment.
    </EmptyState.Content>
  </EmptyState>
);

OriginIndexNotFoundEmptyState.displayName = 'OriginIndexNotFoundEmptyState';
