import { useCallback, useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getSortedContentTypesByName } from '../../../../contentModels/shared/utils/typeUtils.ts';
import { getAllUniqueContentTypesWithViewCapability } from '../../../utils/getAllUniqueContentTypesWithViewCapability.ts';
import {
  useTrackWidgetOverviewItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../shared/hooks/tracking.ts';
import { UnchangedPublishedItemsWidget } from '../components/UnchangedPublishedItemsWidget.tsx';
import { ContentTypeOption } from '../types/ContentTypeOption.type.ts';
import { UnchangedPublishedItemsListingRequestOptionsModel } from '../types/UnchangedPublishedItemsListingRequestOptionsModel.type.ts';

const { missionControlRepository } = repositoryCollection;

export const UnchangedPublishedItemsWidgetLiveContainer: React.FC = () => {
  const user = useSelector((s) => s.data.user);
  const currentProjectId = useSelector(getCurrentProjectId);
  const languages = useSelector((s) => s.data.languages);
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);

  const availableContentTypes = useMemo(
    () =>
      getSortedContentTypesByName(
        getAllUniqueContentTypesWithViewCapability(
          user,
          currentProjectId,
          languages,
          contentTypesById.toArray(),
        ),
      ),
    [user, currentProjectId, languages, contentTypesById],
  );

  const contentTypeOptions: ContentTypeOption = useMemo(
    () =>
      availableContentTypes.map((contentType) => ({
        id: contentType.id,
        label: contentType.name,
      })),
    [availableContentTypes],
  );

  const itemsFetcher = useCallback(
    (
      requestOptions: UnchangedPublishedItemsListingRequestOptionsModel,
      abortSignal?: AbortSignal,
    ) => missionControlRepository.getUnchangedPublishedItems(requestOptions, abortSignal),
    [],
  );

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.UnchangedPublishedItems,
  );
  const trackWidgetShowMoreClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.UnchangedPublishedItems,
  );

  return (
    <UnchangedPublishedItemsWidget
      contentTypeOptions={contentTypeOptions}
      itemsFetcher={itemsFetcher}
      onOverviewItemClick={trackWidgetOverviewItemClick}
      onShowMoreClick={trackWidgetShowMoreClick}
      useRowLinks
    />
  );
};

UnchangedPublishedItemsWidgetLiveContainer.displayName =
  'UnchangedPublishedItemsWidgetLiveContainer';
