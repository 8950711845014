import { Callout } from '@kontent-ai/component-library/Callout';
import { forwardRef } from 'react';
import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import {
  getDefaultExpiration,
  getMaximalExpiration,
  getMinimalExpiration,
} from '../../../../_shared/constants/apiKeyExpirations.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { getExpirationDateErrorMessage } from '../../../../_shared/utils/apiKeys/getApiKeyErrorMessage.ts';
import { isTimeInPast } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { ApiKeyModalWithExpirationDate } from '../../../projectSettings/apiKeys/components/ApiKeyModalWithExpirationDate.tsx';
import { ExistingApiKeyWillBeInvalid } from '../../../projectSettings/apiKeys/constants/warningMessageTemplates.ts';
import { ApiKey, emptyApiKey } from '../../../projectSettings/apiKeys/models/ApiKey.ts';
import { getEditedSubscriptionId } from '../../shared/selectors/getEditedSubscriptionId.ts';
import {
  generateSubscriptionApiKey as generateSapiKey,
  regenerateSubscriptionApiKey as regenerateSapiKey,
} from '../actions/thunkSubscriptionApiActions.ts';

export const SubscriptionKeyGenerationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>(({ isOpen }, ref) => {
  const dispatch = useDispatch();
  const subscriptionId = useSelector(getEditedSubscriptionId) ?? '';
  const apiKey = useSelector((state) => state.data.apiKeys.keyDetail);

  const isRegenerating = apiKey.type === ApiKeyType.SAPI;
  const isValidApiKey = isRegenerating && !isTimeInPast(new Date(), apiKey.expiresAt);

  const onClose = () => dispatch(modalDismissed());

  const confirmDialog = (dateTime: DateTime): void => {
    if (isRegenerating) {
      dispatch(regenerateSapiKey(subscriptionId, apiKey.tokenSeedId, dateTime));
    } else {
      const newApiKey: ApiKey = {
        ...emptyApiKey,
        expiresAt: dateTime.value,
        type: ApiKeyType.SAPI,
      };
      dispatch(generateSapiKey(subscriptionId, newApiKey));
    }

    onClose();
  };

  return (
    <ApiKeyModalWithExpirationDate
      getDefaultExpirationDate={() => getDefaultExpiration(ApiKeyType.SAPI)}
      getMaxExpirationDate={() => getMaximalExpiration(ApiKeyType.SAPI)}
      getMinExpirationDate={() => getMinimalExpiration(ApiKeyType.SAPI)}
      getInvalidDateError={getExpirationDateErrorMessage}
      headline="Generate new Subscription API key?"
      infoChildElement={
        isValidApiKey && (
          <Callout calloutType="warning">
            <p>{ExistingApiKeyWillBeInvalid}</p>
          </Callout>
        )
      }
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirmDialog}
      ref={ref}
    />
  );
});

SubscriptionKeyGenerationDialog.displayName = 'SubscriptionKeyGenerationDialog';
