import { Box } from '@kontent-ai/component-library/Box';
import React from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';

type Props = {
  readonly closeDialog: () => void;
  readonly deleteFolder: () => void;
  readonly folderName: string;
};

export const AssetFolderDeleteDialog: React.FC<Props> = ({
  closeDialog,
  deleteFolder,
  folderName,
}) => (
  // KCL-9481 - This modal dialog should be replaced by ConfirmationDialog component back again when it is refactored to use component-library ModalDialog
  <ModalDialog
    headline={`Delete folder ${folderName}`}
    isOpen
    isDismissable
    onClose={closeDialog}
    primaryAction={{
      destructive: true,
      onClick: compose(deleteFolder, closeDialog),
      text: 'Delete folder',
      ...getDataUiActionAttribute(DataUiAction.Confirm),
    }}
    {...getDataUiElementAttribute(DataUiElement.ConfirmationDialog)}
  >
    <Box className="confirmation-dialog__body">
      <p className="confirmation-dialog__section">
        This will delete the folder and all assets it contains. If you only want to delete the
        folder, move its content first.
      </p>
      <p className="confirmation-dialog__section">
        Do you want to delete the folder <strong>{folderName}</strong> and all its content?
      </p>
    </Box>
  </ModalDialog>
);

AssetFolderDeleteDialog.displayName = 'AssetFolderDeleteDialog';
