import {
  IFixedInsertPosition,
  IRelativeInsertPosition,
  InsertPosition,
  InsertPositionPlacement,
  SmartLinkCommand,
} from '../SmartLinkCommand.ts';

export const isRelativeInsertPosition = (
  insertPosition: InsertPosition,
): insertPosition is IRelativeInsertPosition => {
  return (
    insertPosition.placement === InsertPositionPlacement.After ||
    insertPosition.placement === InsertPositionPlacement.Before
  );
};

export const isFixedInsertPosition = (
  insertPosition: InsertPosition,
): insertPosition is IFixedInsertPosition => {
  return (
    insertPosition.placement === InsertPositionPlacement.Start ||
    insertPosition.placement === InsertPositionPlacement.End
  );
};

export function isSmartLinkCommandRelevantToElement(
  command: SmartLinkCommand,
  contentItemId: Uuid,
  deliveryComponentId: Uuid | null,
  elementCodename: string | null,
): boolean {
  const { data } = command;

  const isCommandRelevantToCurrentContentItem = data.itemId === contentItemId;
  const isCommandRelevantToCurrentContentComponent =
    (!data.contentComponentId && !deliveryComponentId) ||
    data.contentComponentId === deliveryComponentId;
  const isCommandRelevantToCurrentElement = data.elementCodename === elementCodename;

  return (
    isCommandRelevantToCurrentContentItem &&
    isCommandRelevantToCurrentContentComponent &&
    isCommandRelevantToCurrentElement
  );
}
