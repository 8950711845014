import classNames from 'classnames';
import React from 'react';
import {
  ScrollTableColumnSize,
  translateColumnSizeToClassSuffix,
} from './types/scrollTableTypes.ts';

export interface IScrollTableCellProps {
  readonly isEmpty?: boolean;
  readonly size: ScrollTableColumnSize;
}

export const ScrollTableCellSkeleton: React.FC<IScrollTableCellProps> = ({ isEmpty, size }) => {
  const classSizeSuffix = translateColumnSizeToClassSuffix(size);

  return (
    <div className={classNames('scroll-table__cell', `scroll-table__column--${classSizeSuffix}`)}>
      <div className="scroll-table__cell-item">
        {!isEmpty && (
          <div className="cell-item-skelet">
            <div className="cell-item-skelet__pane" />
          </div>
        )}
      </div>
    </div>
  );
};

ScrollTableCellSkeleton.displayName = 'ScrollTableCellSkeleton';
