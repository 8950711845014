import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { ShortcutSymbols } from '../../constants/shortcutSymbols.ts';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

export type SidebarSubmitButtonProps = {
  readonly dataUiAttribute?: ObjectWithDataAttribute;
  readonly disabled: boolean;
  readonly isBeingSubmitted?: boolean;
  readonly onSubmit: (() => void) | undefined;
  readonly text: string;
  readonly tooltipText?: string;
  readonly children?: never;
};

export const SidebarSubmitButton: React.FC<SidebarSubmitButtonProps> = ({
  isBeingSubmitted,
  onSubmit,
  dataUiAttribute,
  disabled,
  text,
  tooltipText,
}) => (
  <Button
    buttonStyle="primary"
    tooltipText={tooltipText}
    disabled={disabled}
    buttonDisplay="block"
    onClick={disabled || isBeingSubmitted ? undefined : onSubmit}
    tooltipShortcuts={disabled || isBeingSubmitted ? undefined : ShortcutSymbols.Enter}
    {...dataUiAttribute}
  >
    {isBeingSubmitted && <Button.ProgressIcon />}
    <Button.Label>{text}</Button.Label>
  </Button>
);

SidebarSubmitButton.displayName = 'SidebarSubmitButton';
