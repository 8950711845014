import { SelectedLanguageNamePlaceholder } from '../constants/uiConstants.ts';
import { IStore } from '../stores/IStore.type.ts';
import { getLanguageName } from './getLanguageName.ts';
import { getSelectedLanguageId } from './getSelectedLanguageId.ts';

const getSelectedLanguageName = (state: IStore): string | null => {
  const selectedLanguageId = getSelectedLanguageId(state);
  return getLanguageName(state, selectedLanguageId);
};

export const getSelectedLanguageNameOrPlaceholder = (state: IStore): string =>
  getSelectedLanguageName(state) ?? SelectedLanguageNamePlaceholder;
