import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { OfflineStatusMessage, StatusMessage } from '../../models/StatusMessage.ts';
import { IStore } from '../../stores/IStore.type.ts';
import {
  IStatusMessageProps,
  StatusMessage as StatusMessageComponent,
} from '../../uiComponents/StatusMessage/StatusMessage.tsx';

type ApplicationStatusMessageTypeSelector = (state: IStore, dispatch: Dispatch) => StatusMessage;

const mapStateToProps = (state: IStore) => state;

const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const mapStateAndDispatchToPropsFactory =
  (typeSelector: ApplicationStatusMessageTypeSelector) =>
  (state: IStore, { dispatch }: { dispatch: Dispatch }): IStatusMessageProps => ({
    statusMessage: state.sharedApp.isOnline ? typeSelector(state, dispatch) : OfflineStatusMessage,
  });

export const getConnectedStatusMessage = (
  typeSelector: ApplicationStatusMessageTypeSelector,
): React.ComponentType =>
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mapStateAndDispatchToPropsFactory(typeSelector),
  )(StatusMessageComponent);
