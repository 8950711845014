import { Size } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ExpandCollapseAnimation } from '../ExpandCollapseAnimation/ExpandCollapseAnimation.tsx';
import { CreateNewBarItemButton } from './CreateNewBarItemButton.tsx';

interface Props {
  readonly children: React.ReactElement;
  readonly estimatedMaxHeightWhenExpanded: number;
  readonly isFormDisplayed: boolean;
  readonly isCreatingAllowed?: boolean;
  readonly itemName: string;
  readonly onCreateNewClick: () => void;
}

export const CreateNewBarItemHidingButtonAnimated: React.FC<Props> = ({
  children,
  estimatedMaxHeightWhenExpanded,
  isCreatingAllowed,
  isFormDisplayed,
  itemName,
  onCreateNewClick,
}) => {
  if (!isCreatingAllowed) {
    return null;
  }

  return (
    <ExpandCollapseAnimation
      estimatedMaxHeightWhenExpanded={estimatedMaxHeightWhenExpanded}
      heightWhenCollapsed={Size.M}
      renderCollapsed={() => (
        <CreateNewBarItemButton itemName={itemName} onCreateNewClick={onCreateNewClick} />
      )}
      renderExpanded={() => children}
      shouldBeExpanded={isFormDisplayed}
    />
  );
};

CreateNewBarItemHidingButtonAnimated.displayName = 'CreateNewBarItemHidingButtonAnimated';
