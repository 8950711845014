export const Environment_InitListing_Started = 'Environment_InitListing_Started';
export const Environment_InitListing_Finished = 'Environment_InitListing_Finished';

export const Environment_CreateEnvironment_CloseDialog =
  'Environment_CreateEnvironment_CloseDialog';
export const Environment_CreateEnvironment_ShowDialog = 'Environment_CreateEnvironment_ShowDialog';
export const Environment_CreateEnvironment_SourceEnvironmentChanged =
  'Environment_CreateEnvironment_SourceEnvironmentChanged';
export const Environment_CreateEnvironment_Failed = 'Environment_CreateEnvironment_Failed';
export const Environment_CreateEnvironment_Finished = 'Environment_CreateEnvironment_Finished';
export const Environment_CreateEnvironment_Initialized =
  'Environment_CreateEnvironment_Initialized';
export const Environment_CreateEnvironment_Started = 'Environment_CreateEnvironment_Started';

export const Environment_CreateEnvironment_LoadRoles_Failed =
  'Environment_CreateEnvironment_LoadRoles_Failed';
export const Environment_CreateEnvironment_LoadRoles_Started =
  'Environment_CreateEnvironment_LoadRoles_Started';
export const Environment_CreateEnvironment_LoadRoles_Success =
  'Environment_CreateEnvironment_LoadRoles_Success';

export const Environment_DeleteEnvironment_CloseDialog =
  'Environment_DeleteEnvironment_CloseDialog';
export const Environment_DeleteEnvironment_ShowDialog = 'Environment_DeleteEnvironment_ShowDialog';
export const Environment_DeleteEnvironment_Failed = 'Environment_DeleteEnvironment_Failed';
export const Environment_DeleteEnvironment_Finished = 'Environment_DeleteEnvironment_Finished';
export const Environment_DeleteEnvironment_Started = 'Environment_DeleteEnvironment_Started';

export const Environment_SwapEnvironment_CloseDialog = 'Environment_SwapEnvironment_CloseDialog';
export const Environment_SwapEnvironment_ShowDialog = 'Environment_SwapEnvironment_ShowDialog';
export const Environment_SwapEnvironment_Failed = 'Environment_SwapEnvironment_Failed';
export const Environment_SwapEnvironment_Finished = 'Environment_SwapEnvironment_Finished';
export const Environment_SwapEnvironment_Started = 'Environment_SwapEnvironment_Started';
