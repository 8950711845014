import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_TypeElementCreated,
} from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import { TypeElementConfigurability } from '../../../shared/models/elements/types/elementConfigurabilityOptions.ts';
import {
  ContentType_Creator_InitStarted,
  ContentType_Editor_HideConfiguration,
  ContentType_Editor_ShowConfiguration,
} from '../../constants/contentTypesActionTypes.ts';

const initialState = Immutable.Map<Uuid, boolean>();

export function showConfigurationForElements(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, boolean> {
  switch (action.type) {
    case ContentType_Editor_ShowConfiguration: {
      const elementId = action.payload.elementId;
      return state.set(elementId, true);
    }

    case ContentType_Editor_HideConfiguration: {
      const elementId = action.payload.elementId;
      return state.set(elementId, false);
    }

    case ContentModels_TypeEditor_TypeElementCreated: {
      const typeElementId = action.payload.typeElementData.elementId;
      const elementConfigurability = action.payload.typeElementData._configurability;
      if (
        elementConfigurability === TypeElementConfigurability.Required ||
        elementConfigurability === TypeElementConfigurability.Recommended
      ) {
        return state.set(typeElementId, true);
      }
      return state;
    }

    case ContentType_Creator_InitStarted:
    case ContentModels_TypeEditor_InitStarted: {
      return initialState;
    }

    default:
      return state;
  }
}
