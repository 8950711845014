import React from 'react';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ItemsPublishedOnTimeWidget } from '../components/ItemsPublishedOnTimeWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const ItemsPublishedOnTimeWidgetLiveContainer: React.FC = () => {
  return (
    <ItemsPublishedOnTimeWidget
      itemsPublishedOnTimeFetcher={missionControlRepository.getItemsPublishedOnTime}
    />
  );
};

ItemsPublishedOnTimeWidgetLiveContainer.displayName = 'ItemsPublishedOnTimeWidgetLiveContainer';
