import React, { useState } from 'react';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { CustomAppDeleteConfirmationModal } from './CustomAppDeleteConfirmationModal.tsx';

type CustomAppEditorToolbarActionsProps = {
  readonly customAppActionIsInProgress: boolean;
  readonly customAppCodename: string;
  readonly customAppName: string;
  readonly onSubmit: (e?: KeyboardEvent | React.BaseSyntheticEvent) => Promise<void>;
  readonly onDelete: () => void;
};

export const CustomAppEditorToolbarActions = ({
  customAppActionIsInProgress,
  customAppCodename,
  customAppName,
  onSubmit,
  onDelete,
}: CustomAppEditorToolbarActionsProps) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

  return (
    <>
      <StatusBarActions
        isBeingSaved={customAppActionIsInProgress}
        isLoading={false}
        onSave={onSubmit}
        codename={customAppCodename}
        isCodenameEditable={false}
        onDelete={() => setShowDeleteConfirmationModal(true)}
      />
      <CustomAppDeleteConfirmationModal
        customAppName={customAppName}
        onHideDeleteConfirmationModal={() => setShowDeleteConfirmationModal(false)}
        onConfirm={onDelete}
        isOpen={showDeleteConfirmationModal}
      />
    </>
  );
};
