import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

export const CopyProjectDocumentationLinks: React.FC = () => (
  <div className="create-project-form__learn-more-links">
    <span>Learn more about how to select </span>
    <OutwardLink href={documentationLinks.selectDataCenter}>data centers</OutwardLink>.
  </div>
);
