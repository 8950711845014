import { PluginState } from '../../../../editorCore/types/Editor.plugins.type.ts';
import {
  getEntitiesAtSelection,
  getFullBlockTypesAtSelection,
  getMetadataAtSelection,
} from '../../../../utils/editorSelectionUtils.ts';
import { getEntityMap } from '../../../../utils/general/editorContentGetters.ts';
import { TextFormattingFeature } from '../../../apiLimitations/api/editorLimitationUtils.ts';
import { isTextFormattingCommandAllowedAtSelection } from '../../../keyboardShortcuts/api/editorCommandUtils.ts';
import { LinksPlugin } from '../../LinksPlugin.type.ts';
import { isExistingLink } from '../../api/LinkEntity.ts';
import { isNewLinkAllowedAtSelection } from '../../api/editorLinkUtils.ts';
import { CreateLinkButton, CreateLinkOption } from './CreateLinkButton.tsx';
import { UnlinkButton } from './UnlinkButton.tsx';

type Props = {
  readonly allowAddLink: boolean;
  readonly disabled: boolean;
  readonly isViolated: boolean;
  readonly onUnlink?: () => void;
  readonly options: ReadonlyArray<CreateLinkOption>;
};

const LinkToolbarButton = ({ allowAddLink, disabled, isViolated, onUnlink, options }: Props) => {
  const isAddLinkAllowed = allowAddLink && !disabled && !!options.length;

  if (onUnlink) {
    return <UnlinkButton disabled={disabled} isViolated={isViolated} onClick={onUnlink} />;
  }

  return <CreateLinkButton isAddLinkAllowed={isAddLinkAllowed} options={options} />;
};

export const renderLinkToolbarButton = (
  state: PluginState<LinksPlugin>,
  isEditorDisabled: boolean,
) => {
  const { editorState, canUpdateContent, getApi, getLinkOptions } = state;
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const canUpdate = canUpdateContent();
  const metadataAtSelection = getMetadataAtSelection(content, selection);

  const { topLevelEntities, tableEntities } = getEntitiesAtSelection(
    metadataAtSelection,
    getEntityMap(content),
  );
  const topLevelLinks = topLevelEntities.filter(isExistingLink);
  const tableLinks = tableEntities.filter(isExistingLink);

  const isExistingLinkAtSelection = !!topLevelLinks.length || !!tableLinks.length;

  const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
  const limitations = getApi().getLimitations();
  const linkCommandAllowed = isTextFormattingCommandAllowedAtSelection(
    TextFormattingFeature.Link,
    fullBlockTypesAtSelection,
    metadataAtSelection,
    limitations,
    getEntityMap(content),
  );

  const allowUnlink =
    canUpdate && isExistingLinkAtSelection && !isEditorDisabled && linkCommandAllowed;
  const allowAddLink =
    canUpdate &&
    isNewLinkAllowedAtSelection(content, selection) &&
    !isEditorDisabled &&
    linkCommandAllowed;

  const options = getLinkOptions();
  const showLinkButton = canUpdate && (options.length || allowUnlink);
  if (!showLinkButton) {
    return null;
  }

  const isViolated =
    (!!topLevelLinks.length &&
      !limitations.allowedTextFormatting.has(TextFormattingFeature.Link)) ||
    (!!tableLinks.length &&
      !limitations.allowedTableTextFormatting.has(TextFormattingFeature.Link));

  return (
    <LinkToolbarButton
      allowAddLink={allowAddLink}
      disabled={!!isEditorDisabled || !linkCommandAllowed}
      isViolated={isViolated}
      onUnlink={allowUnlink ? state.unlinkAtSelection : undefined}
      options={options}
    />
  );
};
