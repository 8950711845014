import { memoize } from '@kontent-ai/memoization';
import { EditingAction } from '../../models/EditingAction.ts';
import { EditingQuickAction } from '../../models/EditingQuickAction.ts';

type QuickActionsMap = Record<EditingQuickAction, NonEmptyReadonlyArray<EditingAction>>;

const quickActionsMap: QuickActionsMap = {
  [EditingQuickAction.addTask]: [EditingAction.addTask],
  [EditingQuickAction.assignPeople]: [EditingAction.assignPeople],
  [EditingQuickAction.changeDueDate]: [EditingAction.changeDueDate, EditingAction.setDueDate],
  [EditingQuickAction.changeWorkflowStep]: [EditingAction.changeStep],
  [EditingQuickAction.changeNote]: [EditingAction.changeNote, EditingAction.setNote],
  [EditingQuickAction.previewItem]: [
    EditingAction.demonstrateDisabledPreviewItem,
    EditingAction.previewItem,
    EditingAction.previewItemInMultipleSpaces,
    EditingAction.previewSampleAppItem,
    EditingAction.setupItemPreview,
  ],
  [EditingQuickAction.publishItem]: [EditingAction.publishItem],
};

const emptyActions: readonly EditingAction[] = [];

const createIsActionAvailable =
  (availableActions: readonly EditingAction[]) =>
  (presetAction: EditingAction): boolean =>
    availableActions.includes(presetAction);

export const getAvailableQuickActions = memoize.weak(
  (
    availableActions: readonly EditingAction[],
    presetQuickActions: readonly EditingQuickAction[],
  ): readonly EditingAction[] => {
    const isActionAvailable = createIsActionAvailable(availableActions);

    return presetQuickActions
      .map((quickAction) => quickActionsMap[quickAction].filter(isActionAvailable).slice(0, 1))
      .reduce((allActions, actions) => allActions.concat(actions), emptyActions);
  },
);
