import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ILoadListingItemsAction } from '../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { UnableToExpandNodeErrorMessage } from '../../constants/uiConstants.ts';
import {
  WebSpotlight_ExpandNode_Failed,
  WebSpotlight_ExpandNode_Finished,
  WebSpotlight_ExpandNode_Started,
} from '../../constants/webSpotlightActionTypes.ts';
import { IExpandedNodesData } from '../../reducers/expandedNodesData.ts';
import { NodeId } from '../../types/nodeId.type.ts';
import { canDisplayItem } from '../../utils/webSpotlightUtils.ts';

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadSubpagesIds: (contentItemIds: UuidArray, abortSignal?: AbortSignal) => ThunkPromise;
}

export interface INodeParams {
  readonly itemId: Uuid;
  readonly nodeId: NodeId;
}

const started = (nodeId: NodeId, nodeData: IExpandedNodesData) =>
  ({
    type: WebSpotlight_ExpandNode_Started,
    payload: {
      nodeData,
      nodeId,
    },
  }) as const;

const finished = (nodeId: NodeId) =>
  ({
    type: WebSpotlight_ExpandNode_Finished,
    payload: {
      nodeId,
    },
  }) as const;

const failed = (nodeId: NodeId, errorMessage: string) =>
  ({
    type: WebSpotlight_ExpandNode_Failed,
    payload: {
      nodeId,
      errorMessage,
    },
  }) as const;

export type ExpandWebSpotlightNodeActionsType = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const expandWebSpotlightNodeActionCreator =
  (deps: IDeps) =>
  ({ itemId, nodeId }: INodeParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { listingContentItems },
      webSpotlightApp: { subpagesById },
    } = getState();

    try {
      const item = listingContentItems.byId.get(itemId);

      if (item && canDisplayItem(item)) {
        const subpagesIds = subpagesById.get(itemId);
        if (subpagesIds) {
          dispatch(
            started(nodeId, {
              itemId,
              subpagesIds,
            }),
          );

          if (subpagesIds.length > 0) {
            await Promise.all([
              dispatch(deps.loadListingItems(subpagesIds, abortSignal)),
              dispatch(deps.loadSubpagesIds(subpagesIds, abortSignal)),
            ]);
          }
        }
      }

      dispatch(finished(nodeId));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(nodeId, UnableToExpandNodeErrorMessage));
      }

      throw error;
    }
  };
