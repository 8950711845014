import { delay } from '@kontent-ai/utils';
import { Selection } from '@react-types/shared';
import { Dispatch, RefObject, SetStateAction, useCallback, useMemo, useState } from 'react';
import { IBaseInputProps } from '../../Input/components/BaseInputComponent.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import {
  VerticalMenuState,
  useNonAccessibleVerticalMenu,
} from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { isItem } from '../../VerticalMenu/utils/utils.tsx';
import { ISelectItem, ISelectSection, ItemId } from '../types.ts';
import { filterItems } from '../utils/filterItems.ts';
import { findItem } from '../utils/findItem.ts';
import { getItemIds } from '../utils/getItemIds.ts';

/***
 * @param item The item for which you need to evaluate the filter phrase for a match
 * @param filterPhrase The expression a user is filtering by. Always already transformed to lowercase and trimmed.
 * */
export type MultiSelectFilterPredicate<TItem extends ISelectItem<TItem>> = (
  item: ISelectSection<TItem> | TItem,
  filterPhrase: string | null,
) => boolean;

type ExpandedProps<_TItem extends ISelectItem<_TItem>> = Readonly<{
  onExpandedChange: (keys: Set<Uuid>) => void;
  expandedKeys: ReadonlySet<Uuid>;
}>;
export type ExpandableOrNot<TItem extends ISelectItem<TItem>> =
  | ExpandedProps<TItem>
  | Never<ExpandedProps<TItem>>;

export type UseMultiSelectStateOptions<TItem extends ISelectItem<TItem>> = Pick<
  IBaseInputProps,
  'inputState'
> &
  ExpandableOrNot<TItem> &
  Readonly<{
    /**
     * Memoize this callback! Providing a new reference (as with any prop)
     * will cause a full re-calculation and re-render.
     * */
    customFilterPredicate?: MultiSelectFilterPredicate<TItem>;
    defaultSelectedItemIds?: ReadonlyArray<ItemId> | ReadonlySet<ItemId> | null;
    disabledItemIds?: ReadonlyArray<ItemId> | ReadonlySet<ItemId>;
    inputRef: RefObject<HTMLTextAreaElement>;
    items: ReadonlyArray<ISelectSection<TItem> | TItem>;
    onInputChange?: (value: string) => void;
    onMenuClose?: () => void;
    onSelectionChange?: (itemIds: ReadonlySet<ItemId>, closeMenu: () => void) => void;
    selectedItemIds?: ReadonlyArray<ItemId> | ReadonlySet<ItemId> | null;
  }>;

export type MultiSelectState<TItem extends ISelectItem<TItem>> = VerticalMenuState<TItem> &
  Readonly<{
    closeMenu: () => void;
    filterValue: string;
    filteredItems: ReadonlyArray<ISelectSection<TItem> | TItem>;
    inputValue: string;
    isOpen: boolean;
    openMenu: (filterValue?: string) => void;
    resetFilterValue: () => void;
    revertState: () => void;
    setFilterValue: Dispatch<SetStateAction<string>>;
    setInputValue: Dispatch<SetStateAction<string>>;
  }>;

export type PublicMultiSelectState<TItem extends ISelectItem<TItem>> = Pick<
  MultiSelectState<TItem>,
  'collection' | 'disabledKeys' | 'expandedKeys' | 'closeMenu' | 'isOpen' | 'openMenu'
>;

const emptySelectedKeys: ReadonlySet<ItemId> = new Set();
const defaultFilterPredicate = <TItem extends ISelectItem<TItem>>(
  item: ISelectSection<TItem> | TItem,
  filterValue: string | null,
) => (filterValue ? !!item.label?.toLocaleLowerCase().includes(filterValue) : true);

export const useMultiSelectState = <TItem extends ISelectItem<TItem>>({
  customFilterPredicate = defaultFilterPredicate,
  defaultSelectedItemIds,
  disabledItemIds,
  expandedKeys,
  inputRef,
  inputState,
  items,
  onExpandedChange,
  onInputChange,
  onMenuClose,
  onSelectionChange,
  selectedItemIds,
}: UseMultiSelectStateOptions<TItem>): MultiSelectState<TItem> => {
  const isDisabled = inputState === InputState.Disabled;
  const isReadOnly = inputState === InputState.ReadOnly;

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState('');

  const setInputValueAndPropagate = useCallback(
    (value: string) => {
      setInputValue(value);
      onInputChange?.(value);
    },
    [onInputChange],
  );

  const clearInputValue = useCallback(() => {
    setInputValueAndPropagate('');
  }, [setInputValueAndPropagate]);

  const filteredItems = useMemo(() => {
    const filterPredicate = (item: ISelectSection<TItem> | TItem) => {
      return customFilterPredicate(item, filterValue);
    };

    return filterItems(items, filterPredicate);
  }, [filterValue, items, customFilterPredicate]);

  const openMenu = (value?: string) => {
    // Prevent setState on unmounted component
    if (!isDisabled && inputRef.current && !isOpen) {
      setIsOpen(true);
      resetFilterValue(value);
    }
  };

  const closeMenu = useCallback(async () => {
    // Delay is necessary for Link Options to click before the menu is removed or altered. Only causes trouble with reduced motion.
    await delay(0);
    // Prevent setState on unmounted component
    if (inputRef.current) {
      setIsOpen(false);
      onMenuClose?.();
    }
  }, [inputRef, onMenuClose]);

  const revertState = useCallback(() => {
    closeMenu();
    // Filter value is cleared on menu unmount
    clearInputValue();
  }, [clearInputValue, closeMenu]);

  // Needs to be memoized because it is called as a ref callback
  const resetFilterValue = useCallback((value: string = '') => {
    setFilterValue(value.toLocaleLowerCase().trim());
  }, []);

  const itemIds = useMemo(() => getItemIds(items), [items]);

  const handleSelectionChange = useCallback(
    (keys: Selection) => {
      if (!isReadOnly && !isDisabled) {
        const selectedKeys = (keys === 'all' ? itemIds : [...keys]).map((key) => key.toString());
        const selectedItems = selectedKeys.flatMap<TItem>((key) => {
          const item = findItem(items, ({ id }) => id === key);
          if (!item) {
            return [];
          }
          return isItem(item) ? [item] : [];
        });

        if (selectedItems.length) {
          onSelectionChange?.(new Set(selectedKeys), closeMenu);
        } else {
          onSelectionChange?.(emptySelectedKeys, closeMenu);
        }
      } else {
        closeMenu();
      }

      clearInputValue();
      resetFilterValue();
    },
    [
      isReadOnly,
      isDisabled,
      clearInputValue,
      resetFilterValue,
      itemIds,
      items,
      onSelectionChange,
      closeMenu,
    ],
  );

  const { verticalMenuState } = useNonAccessibleVerticalMenu<TItem>(filteredItems, {
    disabledKeys: disabledItemIds,
    disallowEmptySelection: false,
    defaultSelectedKeys: defaultSelectedItemIds ?? [],
    defaultExpandedKeys: itemIds,
    // 'defaultExpandedKeys' are not updated on re-render, so we need to set 'expandedKeys' as well with 'itemIds' fallback
    expandedKeys: expandedKeys ?? itemIds,
    onExpandedChange: expandedKeys ? onExpandedChange : undefined,
    selectionMode: 'multiple',
    // Handle null, but keep undefined, otherwise it breaks the uncontrolled mode.
    selectedKeys: selectedItemIds === null ? [] : selectedItemIds,
    onSelectionChange: handleSelectionChange,
  });

  return {
    ...verticalMenuState,
    resetFilterValue,
    closeMenu,
    filterValue,
    filteredItems,
    inputValue,
    isOpen,
    openMenu,
    revertState,
    setFilterValue,
    setInputValue: setInputValueAndPropagate,
  };
};
