import { memoize } from '@kontent-ai/memoization';
import { IContentItemOverwritten } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ExtendedContentChange } from '../components/contentItemEditorNotifications/overwriteNotification/OverwriteNotificationBar.tsx';

export const extendContentChange = memoize.weak(
  (contentChange: IContentItemOverwritten, currentUserId: Uuid): ExtendedContentChange => ({
    ...contentChange,
    changeBySelf: contentChange.changeBy === currentUserId,
  }),
);
