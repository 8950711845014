import { Action } from '../../../../../@types/Action.type.ts';
import { ServerApiErrorCode } from '../../../../../repositories/serverModels/ServerApiError.ts';
import {
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitStarted,
  AssetLibrary_AssetEditor_SaveFailed,
} from '../../constants/assetLibraryActionTypes.ts';

export function hasConflict(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditorDialog_InitStarted:
      return false;

    case AssetLibrary_AssetEditor_SaveFailed: {
      if (
        action.payload.apiError === ServerApiErrorCode.ContentWasModified ||
        action.payload.apiError === ServerApiErrorCode.CodenameIsNotUnique
      ) {
        return true;
      }
      return state;
    }

    default:
      return state;
  }
}
