import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { Collections_Listing_Initialized } from '../../constants/actionTypes.ts';

type Deps = {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
};

const finished = () =>
  ({
    type: Collections_Listing_Initialized,
  }) as const;

export type InitCollectionsListingActionsType = ReturnType<typeof finished>;

export const createInitCollectionsListing =
  (deps: Deps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadSpaces(abortSignal)),
    ]);

    dispatch(finished());
  };
