import React from 'react';
import { UmuxFeedbackModal } from '../../../../_shared/components/UmuxFeedback/UmuxFeedbackModal.tsx';

interface IContentModelingSurveyProps {
  readonly onClose: () => void;
  readonly onSubmit: (ease: string, requirements: string, comment: string) => Promise<void>;
}

export const ContentModelingSurveyQuestionnaire: React.FC<IContentModelingSurveyProps> = ({
  onClose,
  onSubmit,
}) => {
  return (
    <UmuxFeedbackModal
      easeTitle="Content modeling in Kontent.ai is easy."
      headerContent="Shape the future of Kontent.ai with just four clicks!"
      onClose={onClose}
      onSubmit={onSubmit}
      requirementsTitle="Kontent.ai meets my content modeling requirements."
      subtitle="Vote now and help us improve your content modeling experience."
      dismissFeedbackButtonText="Cancel"
    />
  );
};

ContentModelingSurveyQuestionnaire.displayName = 'ContentModelingSurveyQuestionnaire';
