const stop = (e: any) => {
  e.stopPropagation();
};

export const getStopEventsConsumedByDraftJs = (allowDrop: boolean) => ({
  onBeforeInput: stop,
  // We don't stop onBlur because when you click or TAB to a button inside a custom block it gets focus
  // If the focus then moves outside the editor, e.g. upon creating comment, the useFocusWithin hook
  // that we use to highlight focused elements needs to capture the blur event to remove the highlight
  onClick: stop,
  onCompositionEnd: stop,
  onCompositionStart: stop,
  onCopy: stop,
  onCut: stop,
  ...(allowDrop ? undefined : { onDrop: stop }),
  onInput: stop,
  onKeyDown: stop,
  onKeyPress: stop,
  onKeyUp: stop,
  onMouseDown: stop,
  onMouseUp: stop,
  onPaste: stop,
  onSelect: stop,

  // Element must have enabled focus, otherwise it would not catch and stop propagation of focus event
  // We capture the onFocus event to prevent editor stealing the focus from the focused elements
  // placed in custom components, e.g. buttons on custom blocks, inputs in link dialogs etc.
  tabIndex: 0,
  onFocus: stop,
});
