import { Action } from '../../../../../../@types/Action.type.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import { Shared_ModalOpenedWithProperties } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { memoizeContentItemId } from '../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { PublishingState } from '../../../../../../data/constants/PublishingState.ts';
import {
  ICascadeResult,
  LastCascadeAction,
} from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_CurrentItemOverwritten,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ICascadeResult = {
  lastAction: LastCascadeAction.None,
  failedItemIds: new Set(),
  modifiedItemIds: new Set(),
};

export const cascadeResult = (
  state: ICascadeResult = initialState,
  action: Action,
): ICascadeResult => {
  switch (action.type) {
    case Shared_ModalOpenedWithProperties: {
      if (action.payload.properties.dialogType === ModalDialogType.CascadePublishDialog) {
        return initialState;
      }

      return state;
    }
    case ContentEditing_CascadeAction_UndoFinished: {
      const { modifiedItemIds, failedItemIds, undoneAction } = action.payload;
      return {
        lastAction:
          undoneAction === LastCascadeAction.Publish
            ? LastCascadeAction.UndoPublish
            : LastCascadeAction.UndoSchedule,
        modifiedItemIds,
        failedItemIds,
      };
    }
    case ContentEditing_CascadeModal_PublishingFinished: {
      const { modifiedItemIds, failedItemIds } = action.payload;
      return {
        lastAction: LastCascadeAction.Publish,
        modifiedItemIds,
        failedItemIds,
      };
    }
    case ContentEditing_CascadeModal_SchedulingFinished: {
      const { modifiedItemIds, failedItemIds } = action.payload;
      return {
        lastAction: LastCascadeAction.Schedule,
        modifiedItemIds,
        failedItemIds,
      };
    }
    case Content_Editing_PublishContentItemVariantFinished: {
      const { itemVariantData } = action.payload;
      return {
        lastAction: LastCascadeAction.Publish,
        modifiedItemIds: new Set([
          memoizeContentItemId(itemVariantData.editedContentItemVariant.id),
        ]),
        failedItemIds: new Set(),
      };
    }
    case Content_Editing_ScheduledPublishingFinished: {
      const { itemVariantData } = action.payload;
      return {
        lastAction: LastCascadeAction.Schedule,
        modifiedItemIds: new Set([
          memoizeContentItemId(itemVariantData.editedContentItemVariant.id),
        ]),
        failedItemIds: new Set(),
      };
    }
    case Content_Editing_CurrentItemOverwritten: {
      return action.payload.params.difference.workflowStep?.publishingState ===
        PublishingState.Published
        ? initialState
        : state;
    }
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentItemEditing_Init_Started:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
      return initialState;

    default:
      return state;
  }
};
