import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  IElementPathItem,
  traverseFindElement,
} from '../../../../../richText/plugins/contentComponents/api/traverseComponentUtils.ts';
import { isAnyElementInContentComponentIncomplete } from '../../../../utils/itemHighlightingUtils.ts';
import { getIncompleteElementIdPaths } from '../../utils/itemValidationUtils.ts';

interface IDependencies {
  readonly contentComponentsExpanded: (contentComponentIds: Immutable.Set<Uuid>) => Action;
}

export const createExpandContentComponentsWithIncompleteElementsAction =
  (deps: IDependencies) => (): ThunkFunction => (dispatch, getState) => {
    const {
      editedContentItem,
      editedContentItemVariantElements,
      editorUi: { collapsedContentComponents },
      itemValidationErrors,
      itemValidationWarnings,
    } = getState().contentApp;

    if (!editedContentItem) {
      throw InvariantException(
        'createExpandContentComponentsWithIncompleteElementsAction.ts action: "editedContentItem" is not loaded',
      );
    }

    const incompleteElementIdPaths = getIncompleteElementIdPaths(
      itemValidationErrors,
      itemValidationWarnings,
    );

    const componentIdsToExpand = collapsedContentComponents
      .filter((collapsedComponentId: Uuid) => {
        const firstIncompleteElement = traverseFindElement(
          editedContentItem,
          editedContentItemVariantElements,
          (_elem, path) => {
            const componentId = path.last()?.itemId;
            return (
              path.some((p: IElementPathItem) => p.itemId === collapsedComponentId) &&
              !!componentId &&
              isAnyElementInContentComponentIncomplete(componentId, incompleteElementIdPaths)
            );
          },
        );
        return firstIncompleteElement.found;
      })
      .toSet();

    if (!componentIdsToExpand.isEmpty()) {
      dispatch(deps.contentComponentsExpanded(componentIdsToExpand));
    }
  };
