import { History } from 'history';
import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import { rememberScrollId } from '../../../../_shared/actions/sharedActions.ts';
import {
  AutoScrollId,
  CreateAutoScrollId,
} from '../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import { selectContentGroupForAutoScroll } from '../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import {
  EditItemElementVariantCodenameRoute,
  EditItemElementVariantCodenameRouteParams,
} from '../../../smartLink/constants/routePaths.ts';
import { getFloatingEditorSmartLinkTarget } from '../../../smartLink/utils/smartLinkUtils.ts';
import {
  IAddButtonInitialMessageData,
  IEditButtonElementClickedMessageData,
} from '../../types/SmartLinkSdkApi.ts';

export const navigateToElementBase =
  (
    messageData: IEditButtonElementClickedMessageData | IAddButtonInitialMessageData,
    history: History,
    targetScrollId: AutoScrollId,
  ): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
      data: { languages },
      sharedApp: { currentProjectId },
    } = getState();

    const currentPath = history.location.pathname;

    // Use smart link for redirection to different project.
    // It should properly handle the permissions and Web Spotlight when needed, and also includes navigation to a particular element
    if (messageData.projectId && messageData.projectId !== currentProjectId) {
      const smartLinkPath = buildPath<EditItemElementVariantCodenameRouteParams>(
        EditItemElementVariantCodenameRoute,
        {
          projectId: messageData.projectId,
          itemGuid: messageData.itemId,
          elementCodename: messageData.elementCodename,
          variantCodename: messageData.languageCodename,
        },
      );
      history.push(smartLinkPath);
    }

    const variantId = messageData.languageCodename
      ? (languages.byId.find(
          (language: ILanguage) => language.codename === messageData.languageCodename,
        )?.id ?? DefaultVariantId)
      : undefined;

    const target = getFloatingEditorSmartLinkTarget(
      messageData.projectId,
      variantId,
      messageData.itemId,
      currentPath,
    );

    if (target.path !== currentPath) {
      // Make a physical redirect (autoscroll included)
      dispatch(
        rememberScrollId(
          { path: target.path, scrollId: targetScrollId },
          messageData.itemId
            ? { path: currentPath, scrollId: CreateAutoScrollId.forContentItem(messageData.itemId) }
            : undefined,
        ),
      );
      history.push(target.path);
    } else {
      // Request just autoscroll
      dispatch(rememberScrollId({ path: currentPath, scrollId: targetScrollId }));
    }

    // In case of redirect to the same item variant just select the right content group for the element based on the target element autoscroll
    if (
      editedContentItemVariant &&
      editedContentItemVariant.id.itemId === target.itemId &&
      editedContentItemVariant.id.variantId === target.variantId
    ) {
      dispatch(selectContentGroupForAutoScroll(target.path));
    }
  };
