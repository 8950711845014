import { memoize } from '@kontent-ai/memoization';
import { Node } from '@react-types/shared';
import { VerticalMenuItem, VerticalMenuSection } from '../types.ts';
import { isSectionNode } from './utils.tsx';

const flattenAndNormalizeItems = <TItem extends VerticalMenuItem<TItem>>(
  items: ReadonlyArray<Node<VerticalMenuSection<TItem> | TItem>>,
  level: number = 0,
): ReadonlyArray<Node<VerticalMenuSection<TItem> | TItem>> =>
  items.reduce<Array<Node<VerticalMenuSection<TItem> | TItem>>>((result, item) => {
    result.push({
      ...item,
      level,
    });

    if (item.hasChildNodes) {
      const nextLevel = isSectionNode(item) ? level : level + 1;
      result.push(...flattenAndNormalizeItems([...item.childNodes], nextLevel));
    }

    return result;
  }, []);

/**
 * VerticalMenuFrame requires a flat array of all nodes (items and sections). However, items inside sections have greater level property,
 * because they are deeper within the hierarchy. But we want to keep them on the same level visually, so we "normalize" the levels here in
 * the resulting array.
 * @param items — array of nodes
 * @param level — nesting level (default is 0)
 */
export const memoizeLastFlattenAndNormalizedItems = memoize.maxNWithTransformedArgs(
  flattenAndNormalizeItems,
  (args) => args[0].map((item) => item.key),
  1,
);
