import { template } from '../../../_shared/utils/stringUtils.ts';

export const CreatingProjectTooltipMessage =
  'We’re creating another project for you.\nPlease wait till it’s done.';

export const CurrentSubscriptionProjectLimitReachedTooltipMessage = template`You’ve reached the limit of ${0} active \nprojects for this subscription.`;
export const SelectedSubscriptionProjectLimitReachedTooltipMessage = template`You’ve reached the limit of ${0} active \nprojects for the selected subscription.`;

export const UnableToCloneSelectedProjectMessage = 'The selected project cannot be cloned.';
export const UnableToCloneSelectedProjectAdviceMessage = template`To clone the project, archive a project in the "${0}" subscription or select another subscription.`;
export const UnableToCreateNewProjectMessage = 'A new project cannot be created.';
export const UnableToCreateNewProjectAdviceMessage = template`To create a new project, archive a project in the "${0}" subscription or select another subscription.`;
export const UnableToRestoreProjectLimitReachedMessage = template`You’ve reached the limit of ${0} active projects for this subscription. \nTo restore the project, archive or delete one of the active projects under "${1}" subscription.`;
export const DatetimePickerPlaceholder = 'Select a date…';
