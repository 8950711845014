import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { ElementError, ElementErrorResult, emptyElementErrorResult } from './types/Errors.ts';

interface IParams {
  readonly value: string | null;
}

const invalidElementResult: ElementErrorResult = {
  errors: [ElementError.TextTooLong],
};

export const getCustomElementErrors = ({ value }: IParams): ElementErrorResult => {
  const isValid = !value || value.length <= ValidationConstants.CustomElementValueMaxLength;

  return isValid ? emptyElementErrorResult : invalidElementResult;
};
