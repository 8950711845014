import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IRoleRepository } from '../../../../repositories/interfaces/IRoleRepository.type.ts';
import { IRoleServerModel } from '../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  Data_Roles_Failed,
  Data_Roles_Started,
  Data_Roles_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'getRoles'>;
}

const start = () => ({ type: Data_Roles_Started }) as const;
const success = (data: ReadonlyArray<IRoleServerModel>) =>
  ({
    type: Data_Roles_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_Roles_Failed }) as const;

export type LoadRolesActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadRolesAction =
  ({ roleRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const rolesServerModel = await roleRepository.getRoles(abortSignal);
      dispatch(success(rolesServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
