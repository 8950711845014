import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../../_shared/components/NotificationBar.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../../../_shared/utils/stringUtils.ts';
import { LastCascadeAction } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { isInPublishingStepOrAfterUndo } from '../../../utils/isInPublishingStepOrAfterUndo.ts';
import { INotUndoneProps, NotUndoneMessage } from './NotUndoneMessage.tsx';

type IPublishingNotificationBarProps = INotUndoneProps & {
  readonly lastAction: LastCascadeAction;
};

export const PublishingNotificationBar: React.FC<IPublishingNotificationBarProps> = (props) => {
  if (!isInPublishingStepOrAfterUndo(props.workflowStatus, props.lastAction)) {
    return null;
  }

  switch (props.lastAction) {
    case LastCascadeAction.UndoPublish: {
      return (
        <NotificationBar
          type={NotificationBarType.InfoDark}
          uiElement={DataUiElement.NotificationBarInfo}
          message={`${pluralizeWithCount(
            'content item',
            props.numberOfProcessedItems,
          )} unpublished and archived.`}
        />
      );
    }

    case LastCascadeAction.UndoSchedule: {
      return (
        <NotificationBar
          type={NotificationBarType.InfoDark}
          uiElement={DataUiElement.NotificationBarInfo}
          message={`${pluralizeWithCount(
            'content item',
            props.numberOfProcessedItems,
          )} unscheduled.`}
        />
      );
    }

    default: {
      return <NotUndoneMessage {...props} />;
    }
  }
};

PublishingNotificationBar.displayName = 'PublishingNotificationBar';
