import classNames from 'classnames';
import React from 'react';
import {
  AssetReference,
  IAssetItemElement,
} from '../../../../../models/contentItemElements/AssetItemElement.ts';
import { areRenditionReferencesEquivalent } from '../../../../../utils/itemElementsEqualityCheckers/assetItemElementsEqualityChecker.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../../hooks/useRenderPlaceholderText.ts';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';
import { IMergedListItem, getListDiff } from '../../../utils/getListDiff.ts';
import { SimpleAssetList } from './SimpleAssetList.tsx';

const getClassName = (assetWithDiffMetadata: IMergedListItem<AssetReference>): string => {
  return classNames({
    'asset-thumbnail--is-added': assetWithDiffMetadata.added,
    'asset-thumbnail--is-removed': assetWithDiffMetadata.removed,
  });
};

const extendUnchangedWithRenditionsDiff = (
  assetsDiff: ReadonlyArray<IMergedListItem<AssetReference>>,
  originalAssets: Array<AssetReference>,
  currentAssets: Array<AssetReference>,
): ReadonlyArray<IMergedListItem<AssetReference>> =>
  assetsDiff.flatMap((item) => {
    // If the asset is new or removed (could be also both due to order change), we just output it
    // as it already includes the respective rendition correctly
    if (item.removed || item.added) {
      return [item];
    }

    // If the item is unchanged but renditions have changed, split into added + removed tiles
    const assetId = item.value.id;
    const originalAsset = originalAssets.find((asset) => asset.id === assetId);
    const newAsset = currentAssets.find((asset) => asset.id === assetId);

    if (!originalAsset || !newAsset) {
      return [item];
    }

    if (!areRenditionReferencesEquivalent(originalAsset.renditions, newAsset.renditions)) {
      return [
        {
          value: originalAsset,
          removed: true,
        },
        {
          value: newAsset,
          added: true,
        },
      ];
    }

    return [item];
  });

interface IAssetDiffProps extends IRevisionDiffElementProps<IAssetItemElement> {}

export const AssetDiff: React.FC<IAssetDiffProps> = ({
  elementData,
  originalElementData,
  typeElement,
}) => {
  const currentAssets = elementData.value.toArray();
  const originalAssets = originalElementData?.value.toArray();
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.AssetElement,
    originalElementData,
    typeElement,
  );

  if (!originalAssets) {
    const assetReferences = currentAssets.map((assetReference) => ({ value: assetReference }));
    if (assetReferences.length === 0) {
      return <div className="content-item-element__placeholder">{placeholderText}</div>;
    }
    return <SimpleAssetList assetReferences={assetReferences} />;
  }

  const assetsDiff = getListDiff(
    originalAssets,
    currentAssets,
    (assetReference) => assetReference.id,
  );
  const assetsWithRenditionsDiff = extendUnchangedWithRenditionsDiff(
    assetsDiff,
    originalAssets,
    currentAssets,
  );

  return (
    <SimpleAssetList
      assetReferences={assetsWithRenditionsDiff}
      getAssetTileClassName={getClassName}
    />
  );
};

AssetDiff.displayName = 'AssetDiff';
