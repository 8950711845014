import { useEffect } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getProjectIdToRedirect } from '../../../_shared/utils/getPathToRedirectToFromRoot.ts';
import { logError } from '../../../_shared/utils/logError.ts';
import { buildAssetsRoutePath } from '../../contentInventory/assets/utils/assetsPathUtils.ts';
import { handleSuccessfulRedirect } from '../actions/smartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';

export const AssetLibrarySmartLink = () => {
  const dispatch = useDispatch();
  const projectId = useSelector((s) => getProjectIdToRedirect(s.data.user));
  const userId = useSelector((s) => s.data.user.info.userId);

  useEffect(() => {
    if (!projectId) {
      logError(
        `Trying to redirectToAssetsLibrary a user that has no projectId to redirect to. UserId: ${userId}`,
      );
      return;
    }
    const path = buildAssetsRoutePath({ projectId });
    dispatch(handleSuccessfulRedirect(path));
  }, [projectId, userId]);

  return <SmartLink />;
};
