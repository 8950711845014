import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ITaxonomyRepository } from '../../../../repositories/interfaces/ITaxonomyRepository.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Data_TaxonomyGroups_Failed,
  Data_TaxonomyGroups_Started,
  Data_TaxonomyGroups_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly taxonomyRepository: Pick<ITaxonomyRepository, 'getTaxonomyGroups'>;
}

const start = () => ({ type: Data_TaxonomyGroups_Started }) as const;
const success = (data: ReadonlyArray<ITaxonomyGroupServerModel>) =>
  ({
    type: Data_TaxonomyGroups_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_TaxonomyGroups_Failed }) as const;

export type LoadTaxonomyGroupsActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadTaxonomyGroupsAction =
  ({ taxonomyRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const taxonomyGroupsServerModel = await taxonomyRepository.getTaxonomyGroups(abortSignal);
      dispatch(success(taxonomyGroupsServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
