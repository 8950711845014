import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getGoBackLinkByPath } from '../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import {
  ApiKeyDetail_RevokeAndRedirect_Finished,
  ApiKeyDetail_RevokeAndRedirect_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { IApiKeyFilter, emptyApiKeyFilter } from '../../models/ApiKeyFilter.ts';

const started = () =>
  ({
    type: ApiKeyDetail_RevokeAndRedirect_Started,
  }) as const;

const finished = () =>
  ({
    type: ApiKeyDetail_RevokeAndRedirect_Finished,
  }) as const;

interface IRevokeApiKeyAndRedirectDependencies {
  readonly loadDapiKeyListing: (filter: IApiKeyFilter) => ThunkPromise;
  readonly loadMapiKeyListing: (filter: IApiKeyFilter) => ThunkPromise;
  readonly revokeApiKey: (tokenSeedId: Uuid) => ThunkPromise;
}

export type RevokeApiKeyAndRedirectActionsType = ReturnType<typeof started | typeof finished>;

export const revokeApiKeyAndRedirectCreator =
  ({
    loadDapiKeyListing,
    loadMapiKeyListing,
    revokeApiKey,
  }: IRevokeApiKeyAndRedirectDependencies) =>
  (history: History, tokenSeedId?: Uuid): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!tokenSeedId) {
      assert(tokenSeedId, () => 'revokeApiKeyAndRedirect.ts: tokenSeedId is undefined.');
    }

    dispatch(started());

    await dispatch(revokeApiKey(tokenSeedId));

    await Promise.all([
      dispatch(loadDapiKeyListing(emptyApiKeyFilter)),
      dispatch(loadMapiKeyListing(emptyApiKeyFilter)),
    ]);

    history.push(getGoBackLinkByPath(history.location.pathname));

    dispatch(finished());
  };
