import { memoize } from '@kontent-ai/memoization';
import { noOperation } from '@kontent-ai/utils';
import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import { IAssignmentWorkflowStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { DefaultWorkflowId } from '../../constants/variantIdValues.ts';
import { capitalizeFirstLetter } from '../stringUtils.ts';
import { PublishedStepType, getPublishedStepTypeInProjectContext } from './getPublishedStepType.ts';
import { getWorkflowTooltip } from './getWorkflowTooltip.ts';
import { isSpecialWorkflowStep } from './isSpecialWorkflowStep.ts';

const publishStepToWorkflowDescription: Record<PublishedStepType, string> = {
  [PublishedStepType.UniqueCustoms]: 'all workflows except the default one',
  [PublishedStepType.UniqueDefault]: 'the default workflow',
  [PublishedStepType.Shared]: 'all workflows',
};

export type CreateGetWorkflowDescription = (
  workflows: ReadonlyMap<Uuid, Workflow>,
  hasMultipleWorkflows: boolean,
) => (step: IAssignmentWorkflowStep) => string | undefined;

export const createGetWorkflowDescription = memoize.weak<CreateGetWorkflowDescription>(
  (workflows, hasMultipleWorkflows) => {
    const defaultWorkflow = workflows.get(DefaultWorkflowId);

    if (!hasMultipleWorkflows || !defaultWorkflow) {
      return noOperation;
    }

    return (step) => {
      const workflow = workflows.get(step.workflowId);
      if (!workflow) {
        return undefined;
      }

      if (workflow.publishedStep.id === step.id) {
        return publishStepToWorkflowDescription[
          getPublishedStepTypeInProjectContext(workflow, defaultWorkflow)
        ];
      }

      if (isSpecialWorkflowStep(workflow, step.id)) {
        return 'all workflows';
      }

      return getWorkflowTooltip(workflow.name);
    };
  },
);

export const createGetWorkflowDescriptionCapitalized = memoize.weak<CreateGetWorkflowDescription>(
  (workflows, hasMultipleWorkflows) => {
    const getWorkflowDescription = createGetWorkflowDescription(workflows, hasMultipleWorkflows);

    return (step: IAssignmentWorkflowStep) =>
      capitalizeFirstLetter(getWorkflowDescription(step) ?? '');
  },
);
