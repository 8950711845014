import Immutable from 'immutable';
import React, { forwardRef } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICommentThreadWithLocation, isThreadSaved } from '../../../../utils/commentUtils.ts';
import { CommentThread } from '../../containers/comments/CommentThread.tsx';
import { UnsavedInlineCommentThread } from '../../containers/comments/UnsavedInlineCommentThread.tsx';
import { CommentThreadPositioner } from './CommentThreadPositioner.tsx';
import { IThreadOffset } from './InlineCommentPane.tsx';

type CommentThreadsProps = Pick<
  InlineCommentPaneViewProps,
  | 'allowAnimation'
  | 'contentGroupInlineCommentThreads'
  | 'getRefForThread'
  | 'getUnresolvedCommentThreads'
  | 'onThreadFocused'
  | 'onThreadResized'
  | 'relativeThreadOffsets'
>;

const CommentThreads: React.FC<CommentThreadsProps> = (props) => {
  const {
    allowAnimation,
    contentGroupInlineCommentThreads,
    getRefForThread,
    onThreadFocused,
    onThreadResized,
    relativeThreadOffsets,
  } = props;

  let absoluteOffset = 0;
  return (
    <>
      {props
        .getUnresolvedCommentThreads(contentGroupInlineCommentThreads)
        .map((thread: ICommentThreadWithLocation) => {
          const commentThread = thread.commentThread;
          const threadOffset: IThreadOffset | undefined = relativeThreadOffsets.get(
            commentThread.id,
          );
          const hasKnownPosition = threadOffset !== undefined;
          absoluteOffset += threadOffset && hasKnownPosition ? threadOffset.relativeOffset : 0;
          const isNewComment = !isThreadSaved(commentThread);

          return (
            <CommentThreadPositioner
              key={commentThread.id}
              isNewComment={isNewComment}
              absoluteOffset={hasKnownPosition ? absoluteOffset : null}
              allowAnimation={allowAnimation}
            >
              {isNewComment ? (
                <UnsavedInlineCommentThread
                  ref={getRefForThread(commentThread.id)}
                  commentThread={commentThread}
                  onFocused={onThreadFocused}
                />
              ) : (
                <CommentThread
                  ref={getRefForThread(commentThread.id)}
                  showReferenceForInlineThreads={false}
                  className="comment-thread-list__thread"
                  commentThread={commentThread}
                  onFocused={onThreadFocused}
                  onResized={hasKnownPosition ? onThreadResized : undefined}
                />
              )}
            </CommentThreadPositioner>
          );
        })}
    </>
  );
};
CommentThreads.displayName = 'CommentThreads';

type InlineCommentPaneViewProps = {
  readonly allowAnimation: boolean;
  readonly contentGroupInlineCommentThreads: ReadonlyArray<ICommentThreadWithLocation>;
  readonly getRefForThread: (threadId: Uuid) => React.RefObject<any>;
  readonly getUnresolvedCommentThreads: (
    threads: ReadonlyArray<ICommentThreadWithLocation>,
  ) => ReadonlyArray<ICommentThreadWithLocation>;
  readonly minHeight?: number;
  readonly onThreadFocused: () => void;
  readonly onThreadResized: (resizedThreadId: Uuid, oldHeight: number, newHeight: number) => void;
  readonly relativeThreadOffsets: Immutable.Map<Uuid, IThreadOffset>;
};

export const InlineCommentPaneView = forwardRef<HTMLDivElement, InlineCommentPaneViewProps>(
  (props, ref) => {
    const {
      allowAnimation,
      contentGroupInlineCommentThreads,
      getRefForThread,
      getUnresolvedCommentThreads,
      onThreadFocused,
      onThreadResized,
      relativeThreadOffsets,
      minHeight,
    } = props;

    return (
      <div className="content-item-pane__comment-thread-list-pane">
        <div
          className="content-item-pane__comment-thread-list"
          ref={ref}
          style={{ minHeight }}
          {...getDataUiCollectionAttribute(DataUiCollection.CommentInlineThreads)}
        >
          <CommentThreads
            getUnresolvedCommentThreads={getUnresolvedCommentThreads}
            contentGroupInlineCommentThreads={contentGroupInlineCommentThreads}
            relativeThreadOffsets={relativeThreadOffsets}
            allowAnimation={allowAnimation}
            onThreadFocused={onThreadFocused}
            getRefForThread={getRefForThread}
            onThreadResized={onThreadResized}
          />
        </div>
      </div>
    );
  },
);
InlineCommentPaneView.displayName = 'InlineCommentPaneView';
