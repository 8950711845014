import { template } from '../stringUtils.ts';
import { ValidationFunction } from './createFormValidationResolver.ts';

type ObjectWithCodename = { codename: string };
type Collection<T> = { every: (predicate: (value?: T) => boolean) => boolean };
type IsPropertyUnique<TObject, TValue = string> = (
  _value: TValue,
  _collection: Collection<TObject>,
) => boolean;

const errorMessageTemplate = template`Provide a unique ${0}.`;

/**
 * Decides whether an object's property value is present within a collection
 * @param {(object: TObject) => TValue} valueSelector selects a value from collection of given objects
 * @returns {IsPropertyUnique<TObject, TValue>} bool function that tells whether given value is present as a property value of given collection of complex objects
 */
export const isPropertyUniqueBuilder = <TObject, TValue = string>(
  valueSelector: (object: TObject) => TValue,
): IsPropertyUnique<TObject, TValue> => {
  if (!valueSelector) {
    return () => false;
  }

  return (value: TValue, collection: Collection<TObject>) =>
    !collection ||
    collection.every((object?: TObject) => !object || valueSelector(object) !== value);
};

/**
 * Creates a function that returns undefined for unique value and an error message for recurrent values
 * @param {string} fieldName name field with unique value in lowercase singular form
 * @param {(props: TProps) => Collection<TObject>} collectionSelector collection of objects in props of an component
 * @param {(object: TObject) => TValue} valueSelector value of a property an object that determines whether provided value is unique
 * @return {ValidationFunction<TValue, {}, TProps>} function that validates of a value agains collection of complex objects in a component props
 */
export const uniquenessValidationBuilder = <TProps, TObject, TValue = string>(
  fieldName: string,
  collectionSelector: (props: TProps) => Collection<TObject>,
  valueSelector: (object: TObject) => TValue,
): ValidationFunction<TValue, AnyObject, TProps> => {
  if (!collectionSelector) {
    return () => `Uniquess of ${fieldName} cannot be determined`;
  }

  const isPropertyUnique = isPropertyUniqueBuilder<TObject, TValue>(valueSelector);
  const errorMessage = errorMessageTemplate(fieldName);

  return (value: TValue, _: AnyObject, props: TProps) => {
    const collectionFromProps = collectionSelector(props);

    return isPropertyUnique(value, collectionFromProps) ? undefined : errorMessage;
  };
};

/**
 * Creates a function that returns undefined for unique code name and an error message for recurrent code names
 * @param {(props: TProps) => Collection<ObjectWithCodename>} codenameCollectionSelector selects a collection from a component props with a codename property
 * @return {ValidationFunction<TValue, {}, TProps>} function that validates given valueagains collection of complex objects with codename property in a component props
 */
export const uniqueCodenameValidationBuilder = <TProps>(
  codenameCollectionSelector: (props: TProps) => Collection<ObjectWithCodename>,
) =>
  uniquenessValidationBuilder('codename', codenameCollectionSelector, (object) => object.codename);
