import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_ChangePlan_Finished,
  Subscription_PlanSelection_ClearConfirmation,
} from '../constants/actionTypes.ts';

export const showChangePlanSuccessMessage = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_ChangePlan_Finished:
      return true;

    case Subscription_PlanSelection_ClearConfirmation:
      return false;

    default:
      return state;
  }
};
