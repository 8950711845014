import { canRoleDoSomethingInStep } from '../../../../_shared/utils/permissions/roleInWorkflowStepUtils.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { splitWorkflowsByLimitations } from './splitWorkflowsByLimitations.ts';

export type WorkflowsByPermission = {
  allowedWorkflows: readonly Workflow[];
  forbiddenWorkflows: readonly Workflow[];
};

export const getWorkflowsByPermission = (
  workflows: readonly Workflow[],
  collectionId: Uuid | undefined,
  contentTypeId: Uuid | undefined,
  userRoleId: Uuid | undefined,
): WorkflowsByPermission => {
  if (!collectionId || !contentTypeId || !userRoleId) {
    return NoWorkflows;
  }

  const { workflowsWithValidScope, workflowsWithUnlimitedScope } = splitWorkflowsByLimitations(
    workflows,
    collectionId,
    contentTypeId,
  );

  if (workflowsWithValidScope.length > 0) {
    return splitByPermissions(workflowsWithValidScope, userRoleId);
  }

  if (workflowsWithUnlimitedScope.length > 0) {
    return splitByPermissions(workflowsWithUnlimitedScope, userRoleId);
  }

  return NoWorkflows;
};

const NoWorkflows: WorkflowsByPermission = {
  allowedWorkflows: [],
  forbiddenWorkflows: [],
};

const splitByPermissions = (
  workflows: readonly Workflow[],
  userRoleId: Uuid,
): WorkflowsByPermission => {
  const allowedWorkflows = filterWorkflowsRoleCanWorkWith(workflows, userRoleId);
  const forbiddenWorkflows = workflows.filter((w) => !allowedWorkflows.includes(w));

  return {
    allowedWorkflows,
    forbiddenWorkflows,
  };
};

const filterWorkflowsRoleCanWorkWith = (
  workflows: readonly Workflow[],
  userRoleId: Uuid,
): readonly Workflow[] =>
  workflows.filter((w) => canRoleDoSomethingInStep(userRoleId, w.steps[0].id, w));
