import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { createSelection } from '../../../utils/editorSelectionUtils.ts';
import { LinkedItemsPlugin } from '../LinkedItemsPlugin.tsx';
import {
  insertModularContentItemsToPlaceholder,
  insertMultipleModularContentItems,
} from './editorModularUtils.ts';

export const editorLinkedItemApi: EditorApiImplementation<LinkedItemsPlugin> = {
  insertModularContentItem(api, editorState, contentItemId, afterBlockKey) {
    const nextBlock = editorState.getCurrentContent().getBlockAfter(afterBlockKey);

    if (!nextBlock) {
      return editorState;
    }

    const blockLength = nextBlock.getLength();
    const selection = createSelection(nextBlock.getKey(), blockLength);

    return api.executeContentChange(
      editorState,
      selection,
      (input) => insertMultipleModularContentItems(input, [contentItemId]),
      'insert-fragment',
    );
  },

  insertModularContentItems(
    api,
    editorState,
    placeholderBlockKey,
    contentItemIds,
    allowUndo = true,
  ) {
    const selection = allowUndo
      ? createSelection(placeholderBlockKey)
      : editorState.getCurrentContent().getSelectionBefore();

    return api.executeContentChange(
      editorState,
      selection,
      (input) => insertModularContentItemsToPlaceholder(input, placeholderBlockKey, contentItemIds),
      'insert-fragment',
      allowUndo,
    );
  },
};
