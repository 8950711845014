import { Action } from '../../../../../@types/Action.type.ts';
import { Data_Collections_Success } from '../../../../../data/constants/dataActionTypes.ts';
import { createCollectionsFromServerModel } from '../../../../../data/models/collections/Collection.ts';
import {
  Collections_Creator_Submitted,
  Collections_Editor_CollectionMoved,
  Collections_Editor_Deleted,
  Collections_Listing_Left,
  Collections_Save_Finished,
} from '../../constants/actionTypes.ts';

const emptyState: ReadonlyArray<Uuid> = [];

export const collectionIds = (state = emptyState, action: Action): ReadonlyArray<Uuid> => {
  switch (action.type) {
    case Collections_Creator_Submitted: {
      return [action.payload.collectionId, ...state];
    }

    case Collections_Editor_Deleted: {
      const { collectionId } = action.payload;
      const index = state.indexOf(collectionId);
      if (index > -1) {
        const newState = [...state];
        newState.splice(index, 1);
        return newState;
      }

      return state;
    }

    case Collections_Editor_CollectionMoved: {
      const { draggedCollectionId, targetCollectionId } = action.payload;

      const indexOfDragged = state.indexOf(draggedCollectionId);
      const indexOfTarget = state.indexOf(targetCollectionId);

      if (indexOfDragged === -1 || indexOfTarget === -1) {
        return state;
      }

      const newState = [...state];
      newState[indexOfDragged] = targetCollectionId;
      newState[indexOfTarget] = draggedCollectionId;

      return newState;
    }

    case Collections_Save_Finished:
    case Data_Collections_Success:
      return createCollectionsFromServerModel(action.payload).collectionIds;

    case Collections_Listing_Left:
      return emptyState;

    default:
      return state;
  }
};
