import { Button } from '@kontent-ai/component-library/Button';
import React, { useState } from 'react';
import {
  CancelScheduling,
  CancelSchedulingOfAll,
  CreateNewVersion,
  Unpublish,
  UnpublishAll,
} from '../../../../../../../_shared/constants/itemActions.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CancelBulkSchedulePublishConfirmationDialog } from '../../../../../../contentInventory/content/components/cancelConfirmation/CancelBulkSchedulePublishConfirmationDialog.tsx';
import { CancelSchedulePublishConfirmationDialog } from '../../../../../../contentInventory/content/components/cancelConfirmation/CancelSchedulePublishConfirmationDialog.tsx';
import { PublishingPrivileges } from '../../../models/PublishingPrivileges.ts';

export type IToolButtonsProps = ICancelSchedulingButtonProps &
  ICreateNewVersionButtonProps &
  IUnpublishButtonProps;

type IUnpublishButtonProps = {
  readonly numberOfProcessedItems: number;
  readonly onCascadeUnpublish: () => void;
  readonly onUnpublish: () => void;
};

const UnpublishButton: React.FC<IUnpublishButtonProps> = (props) =>
  props.numberOfProcessedItems > 1 ? (
    <Button
      size="small"
      buttonStyle="primary-inverse"
      onClick={props.onCascadeUnpublish}
      {...getDataUiActionAttribute(DataUiAction.Undo)}
    >
      {UnpublishAll}
    </Button>
  ) : (
    <Button
      size="small"
      buttonStyle="primary-inverse"
      onClick={props.onUnpublish}
      {...getDataUiActionAttribute(DataUiAction.Undo)}
    >
      {Unpublish}
    </Button>
  );

type ICreateNewVersionButtonProps = {
  readonly onCreateNewVersion: () => void;
};

const CreateNewVersionButton: React.FC<ICreateNewVersionButtonProps> = (props) => (
  <Button
    size="small"
    buttonStyle="primary-inverse"
    onClick={props.onCreateNewVersion}
    {...getDataUiActionAttribute(DataUiAction.CreateNewVersion)}
  >
    {CreateNewVersion}
  </Button>
);

type ICancelSchedulingButtonProps = {
  readonly numberOfProcessedItems: number;
  readonly onCancelScheduling: () => void;
  readonly onCascadeCancelScheduling: () => void;
};

enum CancelSchedulePublishDialogType {
  Closed = 'Closed',
  SingleItem = 'SingleItem',
  Bulk = 'Bulk',
}

const CancelSchedulingButton: React.FC<ICancelSchedulingButtonProps> = (props) => {
  const [cancelScheduleDialogType, setCancelScheduleDialogType] = useState(
    CancelSchedulePublishDialogType.Closed,
  );
  const openSingleItemDialog = () =>
    setCancelScheduleDialogType(CancelSchedulePublishDialogType.SingleItem);
  const openBulkDialog = () => setCancelScheduleDialogType(CancelSchedulePublishDialogType.Bulk);
  const closeDialog = () => setCancelScheduleDialogType(CancelSchedulePublishDialogType.Closed);

  const isBulkAction = props.numberOfProcessedItems > 1;
  const onButtonClick = isBulkAction ? openBulkDialog : openSingleItemDialog;

  const button = (
    <Button
      size="small"
      buttonStyle="primary-inverse"
      onClick={onButtonClick}
      {...getDataUiActionAttribute(DataUiAction.Cancel)}
    >
      {isBulkAction ? CancelSchedulingOfAll : CancelScheduling}
    </Button>
  );

  return (
    <>
      {button}
      <CancelSchedulePublishConfirmationDialog
        isOpen={cancelScheduleDialogType === CancelSchedulePublishDialogType.SingleItem}
        onCancel={closeDialog}
        onConfirm={props.onCancelScheduling}
      />
      <CancelBulkSchedulePublishConfirmationDialog
        isOpen={cancelScheduleDialogType === CancelSchedulePublishDialogType.Bulk}
        onCancel={closeDialog}
        onConfirm={props.onCascadeCancelScheduling}
      />
    </>
  );
};

type Tools = Record<PublishingPrivileges, JSX.Element | null>;

export const getTool = (
  privilege: PublishingPrivileges,
  props: IToolButtonsProps,
): JSX.Element | null => {
  const publishingPrivilegesToTools: Tools = {
    [PublishingPrivileges.CancelScheduling]: <CancelSchedulingButton {...props} />,
    [PublishingPrivileges.CreateNewVersion]: <CreateNewVersionButton {...props} />,
    [PublishingPrivileges.None]: null,
    [PublishingPrivileges.Unpublish]: <UnpublishButton {...props} />,
    [PublishingPrivileges.ViewPublishedVersion]: null,
    [PublishingPrivileges.ViewScheduledVersion]: null,
  };

  return publishingPrivilegesToTools[privilege];
};
