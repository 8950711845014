import { CascadeNodeId } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';

type Params = {
  readonly parentNodeId?: CascadeNodeId;
  readonly itemId: Uuid;
  readonly variantId: Uuid;
};

export const getCascadeNodeId = ({ itemId, parentNodeId, variantId }: Params): CascadeNodeId =>
  `${parentNodeId ?? RootParentNodeId}-${itemId}-${variantId}`;

const RootParentNodeId = 'Root';
