import { Action } from '../../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_CodenameEditor_Closed,
  ContentItemEditing_CodenameEditor_Opened,
  ContentItemEditing_Init_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function isVisible(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_CodenameEditor_Opened:
      return true;

    case ContentItemEditing_CodenameEditor_Closed:
    case ContentItemEditing_Init_Started:
      return initialState;

    default:
      return state;
  }
}
