import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  CustomApp,
  createCustomAppDomainModel,
} from '../../../../data/models/customApps/CustomApp.ts';
import { RoleOption } from '../../../../data/models/roles/RoleOption.ts';
import { getSortedRoleOptionsListFromServerModelWithAllRolesFirst } from '../utils/roleUtils.ts';
import { CustomAppEditorForm } from './CustomAppEditorForm.tsx';

const { customAppRepository, roleRepository } = repositoryCollection;

type CustomAppEditorProps = {
  readonly customAppId: Uuid;
  readonly subscriptionId?: Uuid;
};

export const CustomAppEditor = ({ customAppId, subscriptionId }: CustomAppEditorProps) => {
  const [customApp, setCustomApp] = useState<CustomApp | null>(null);
  const [roles, setRoles] = useState<ReadonlyArray<RoleOption> | null>(null);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(
      async () =>
        await Promise.all([customAppRepository.get(customAppId), roleRepository.getRoles()]),
    )
      .then(([customAppServerModel, rolesServerModel]) => {
        setCustomApp(createCustomAppDomainModel(customAppServerModel));
        setRoles(getSortedRoleOptionsListFromServerModelWithAllRolesFirst(rolesServerModel));
      })
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [customAppId]);

  if (!roles || !customApp) {
    return <Loader />;
  }

  return (
    <CustomAppEditorForm customApp={customApp} roles={roles} subscriptionId={subscriptionId} />
  );
};
