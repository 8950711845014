import React, { ComponentProps } from 'react';
import { Tag } from '../../../../../client/component-library/components/Tag/Tag.tsx';
import { colorAlertBackgroundInverse } from '../../../tokens/decision/colors.ts';
import { Icons } from '../../Icons/components/icons.ts';

type Props = Omit<ComponentProps<typeof Tag>, 'background'>;

export const ErrorTag = React.forwardRef<HTMLButtonElement, Props>((props, forwardedRef) => (
  <Tag background={colorAlertBackgroundInverse} ref={forwardedRef} {...props}>
    <Tag.Icon icon={Icons.ExclamationTriangleInverted} />
    <Tag.Label>{props.label ?? ''}</Tag.Label>
  </Tag>
));

ErrorTag.displayName = 'ErrorTag';
