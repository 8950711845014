import { combineReducers } from '@reduxjs/toolkit';
import { dialogProperties } from './dialogProperties.ts';
import { form } from './form.ts';
import { formConfig } from './formConfig.ts';

export const newContentItem = combineReducers({
  dialogProperties,
  form,
  formConfig,
});
