import { Action } from '../../../../@types/Action.type.ts';
import { IProjectContainerActiveUserSeverModel } from '../../../../repositories/serverModels/IProjectContainerActiveUserSeverModel.type.ts';
import {
  Data_ProjectContainer_LoadActiveUsers_Failed,
  Data_ProjectContainer_LoadActiveUsers_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IProjectContainerActiveUser,
  createProjectContainerActiveUserDomainModel,
} from '../../../models/projectContainerActiveUsers/ProjectContainerActiveUser.ts';

const initialState: ReadonlyMap<UserId, IProjectContainerActiveUser> = new Map<
  UserId,
  IProjectContainerActiveUser
>();

export function byId(
  state = initialState,
  action: Action,
): ReadonlyMap<UserId, IProjectContainerActiveUser> {
  switch (action.type) {
    case Data_ProjectContainer_LoadActiveUsers_Success: {
      const projectContainerActiveUsers = action.payload.projectContainerActiveUsers.map(
        (
          activeUser: IProjectContainerActiveUserSeverModel,
        ): readonly [UserId, IProjectContainerActiveUser] => [
          activeUser.userId,
          createProjectContainerActiveUserDomainModel(activeUser),
        ],
      );

      return new Map(projectContainerActiveUsers);
    }

    case Data_ProjectContainer_LoadActiveUsers_Failed:
      return initialState;

    default:
      return state;
  }
}
