import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';

interface DeactivationConfirmationDialogProps {
  readonly deactivateProject: () => void;
  readonly closeDeactivationConfirmationDialog: () => void;
  readonly projectName: string;
  readonly environmentName: string;
}

const getDeactivateProjectWarning = (envName: string): JSX.Element => (
  <Warning subtitle="What happens when you archive this project?">
    <ul>
      <li>The project’s content will become unavailable to you and other users.</li>
      <li>All users on the project will be deactivated.</li>
      <li>All languages will be deactivated.</li>
      <li>
        All created environments will be deleted. Default “{envName}” environment will remain.
      </li>
    </ul>
    <p>You can manually activate the users and languages later when you restore the project.</p>
  </Warning>
);

export const DeactivationConfirmationDialog = (props: DeactivationConfirmationDialogProps) => (
  <ConfirmationDialog
    alert
    confirmButtonIcon="Box"
    confirmButtonText="Archive project"
    headerContent={`Archiving project “${props.projectName}”`}
    onClose={props.closeDeactivationConfirmationDialog}
    onConfirm={props.deactivateProject}
    isOpen
  >
    {getDeactivateProjectWarning(props.environmentName)}
  </ConfirmationDialog>
);

DeactivationConfirmationDialog.displayName = 'DeactivationConfirmationDialog';
