import { combineReducers } from '@reduxjs/toolkit';
import { NotificationBarType } from '../components/NotificationBar.tsx';
import { text } from './notificationBar/text.ts';
import { type } from './notificationBar/type.ts';

export interface INotificationBarState {
  readonly text: string | null;
  readonly type: NotificationBarType;
}

export const notificationBar = combineReducers({
  text,
  type,
});
