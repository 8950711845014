import Immutable from 'immutable';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { AssetBackNavigation } from '../../../applications/contentInventory/assets/containers/appToolbar/AssetBackNavigation.tsx';
import { AssetSavingStatus } from '../../../applications/contentInventory/assets/containers/appToolbar/AssetSavingStatus.tsx';
import { ContentListingLanguageSwitcher } from '../../../applications/contentInventory/content/features/ContentItemInventory/containers/ContentListingLanguageSwitcher.tsx';
import { ILanguageSwitcherOption } from '../../../applications/contentInventory/content/models/ILanguageSwitcherOption.type.ts';
import { RelationsLanguageSwitcher } from '../../../applications/contentInventory/relations/containers/RelationsLanguageSwitcher.tsx';
import { ContentInventoryTabs } from '../../../applications/contentInventory/shared/containers/ContentInventoryTabs.tsx';
import { AssetTypeEditorToolbarActions } from '../../../applications/contentModels/assetTypes/containers/statusBar/AssetTypeEditorToolbarActions.tsx';
import { AssetTypeSavingStatus } from '../../../applications/contentModels/assetTypes/containers/statusBar/AssetTypeSavingStatus.tsx';
import { ContentModelsTabs } from '../../../applications/contentModels/contentTypes/containers/ContentModelsTabs.tsx';
import { ContentTypeBackNavigation } from '../../../applications/contentModels/contentTypes/containers/statusBar/ContentTypeBackNavigation.tsx';
import { ContentTypeCreatorToolbarActions } from '../../../applications/contentModels/contentTypes/containers/statusBar/ContentTypeCreatorToolbarActions.tsx';
import { ContentTypeEditorToolbarActions } from '../../../applications/contentModels/contentTypes/containers/statusBar/ContentTypeEditorToolbarActions.tsx';
import { ContentTypeSavingStatus } from '../../../applications/contentModels/contentTypes/containers/statusBar/ContentTypeSavingStatus.tsx';
import { ContentTypeSnippetBackNavigation } from '../../../applications/contentModels/snippets/containers/appToolbar/ContentTypeSnippetBackNavigation.tsx';
import { ContentTypeSnippetCreatorToolbarActions } from '../../../applications/contentModels/snippets/containers/appToolbar/ContentTypeSnippetCreatorToolbarActions.tsx';
import { ContentTypeSnippetEditorToolbarActions } from '../../../applications/contentModels/snippets/containers/appToolbar/ContentTypeSnippetEditorToolbarActions.tsx';
import { ContentTypeSnippetSavingStatus } from '../../../applications/contentModels/snippets/containers/appToolbar/ContentTypeSnippetSavingStatus.tsx';
import { TaxonomyBackNavigation } from '../../../applications/contentModels/taxonomy/containers/appToolbar/TaxonomyBackNavigation.tsx';
import { TaxonomyCreatorToolbarActions } from '../../../applications/contentModels/taxonomy/containers/appToolbar/TaxonomyCreatorToolbarActions.tsx';
import { TaxonomyEditorToolbarActions } from '../../../applications/contentModels/taxonomy/containers/appToolbar/TaxonomyEditorToolbarActions.tsx';
import { TaxonomySavingStatus } from '../../../applications/contentModels/taxonomy/containers/appToolbar/TaxonomySavingStatus.tsx';
import { EntityWebhookToolbarActions } from '../../../applications/entityWebhooks/components/toolbar/EntityWebhookToolbarActions.tsx';
import { EntityWebhookListingBackNavigation } from '../../../applications/entityWebhooks/containers/toolbar/EntityWebhookListingBackNavigation.tsx';
import { CollectionsToolbarActions } from '../../../applications/environmentSettings/collections/containers/CollectionsToolbarActions.tsx';
import { CustomAppListingBackNavigation } from '../../../applications/environmentSettings/customApps/components/toolbar/CustomAppListingBackNavigation.tsx';
import { CustomAppToolbarActionsPlaceholder } from '../../../applications/environmentSettings/customApps/components/toolbar/CustomAppToolbarActionsPlaceholder.tsx';
import { RoleBackNavigation } from '../../../applications/environmentSettings/roles/containers/RoleBackNavigation.tsx';
import { RoleCreatorToolbarActions } from '../../../applications/environmentSettings/roles/containers/RoleCreatorToolbarActions.tsx';
import { RoleEditorToolbarActions } from '../../../applications/environmentSettings/roles/containers/RoleEditorToolbarActions.tsx';
import { RoleSavingStatus } from '../../../applications/environmentSettings/roles/containers/RoleSavingStatus.tsx';
import { SubscriptionManagerRoleCreatorToolbarActions } from '../../../applications/environmentSettings/roles/containers/SubscriptionManagerRoleCreatorToolbarActions.tsx';
import { SubscriptionManagerRoleEditorToolbarActions } from '../../../applications/environmentSettings/roles/containers/SubscriptionManagerRoleEditorToolbarActions.tsx';
import { EnvironmentSettingsStatusMessage } from '../../../applications/environmentSettings/root/components/SettingsSavingStatus.tsx';
import { EnvironmentSettingsBackNavigation } from '../../../applications/environmentSettings/root/containers/EnvironmentSettingsBackNavigation.tsx';
import { UsersToolbarActions } from '../../../applications/environmentSettings/users/containers/UsersToolbarActions.tsx';
import { WorkflowBackNavigation } from '../../../applications/environmentSettings/workflow/containers/WorkflowBackNavigation.tsx';
import { HomeLanguageSwitcher } from '../../../applications/home/containers/HomeLanguageSwitcher.tsx';
import { HomeTabs } from '../../../applications/home/containers/HomeTabs.tsx';
import { ContentItemSavingStatus } from '../../../applications/itemEditor/features/ContentItemEditing/containers/ContentItemSavingStatus.tsx';
import { ContentEditorLanguageSwitcher } from '../../../applications/itemEditor/features/ContentItemEditing/containers/statusBar/ContentEditorLanguageSwitcher.tsx';
import { ContentItemBackNavigation } from '../../../applications/itemEditor/features/ContentItemEditing/containers/statusBar/ContentItemBackNavigation.tsx';
import { ContentItemEditorToolbarActions } from '../../../applications/itemEditor/features/ContentItemEditing/containers/statusBar/ContentItemEditorToolbarActions.tsx';
import { ContentItemRevisionBackNavigation } from '../../../applications/itemEditor/features/ContentItemEditing/containers/statusBar/ContentItemRevisionBackNavigation.tsx';
import { ContentItemRevisionToolbarActions } from '../../../applications/itemEditor/features/ContentItemEditing/containers/statusBar/ContentItemRevisionToolbarActions.tsx';
import { PreviewConfigurationToolbarActions } from '../../../applications/previewConfiguration/containers/PreviewConfigurationToolbarActions.tsx';
import { ApiKeyDetailBackNavigation } from '../../../applications/projectSettings/apiKeys/containers/ApiKeyDetail/ApiKeyDetailBackNavigation.tsx';
import { ApiKeyDetailToolbarActions } from '../../../applications/projectSettings/apiKeys/containers/ApiKeyDetail/ApiKeyDetailToolbarActions.tsx';
import { EnvironmentsToolbarActions } from '../../../applications/projectSettings/environments/containers/EnvironmentsToolbarActions.tsx';
import { ProjectSettingsSavingStatus } from '../../../applications/projectSettings/root/components/ProjectSettingsSavingStatus.tsx';
import { MissionControlLanguageSwitcher } from '../../../applications/unifiedMissionControl/containers/MissionControlLanguageSwitcher.tsx';
import { LanguagesFilterSelectorWrapper } from '../../../applications/unifiedMissionControl/contentStatus/components/LanguagesFilterSelectorWrapper.tsx';
import { useIsUnifiedMissionControlEnabled } from '../../../applications/unifiedMissionControl/contexts/IsUnifiedMissionControlEnabledContext.tsx';
import { WebSpotlightContentItemBackNavigation } from '../../../applications/webSpotlight/containers/WebSpotlightContentItemBackNavigation.tsx';
import { WebhookMessageListingBackNavigation } from '../../../applications/webhooks/containers/WebhookMessageListingBackNavigation.tsx';
import {
  AssetListingRoute,
  AssetRoute,
  AssetTypesRoute,
  AuditLogRoute,
  CalendarRoute,
  CollectionsRoute,
  ContentAssignedToYouRoute,
  ContentItemAccessDeniedRoute,
  ContentItemPreviewWithEditorAccessDeniedRoute,
  ContentItemRevisionCompareRoute,
  ContentItemRevisionViewerRoute,
  ContentItemRoute,
  ContentItemsRoute,
  ContentRecentlyEditedByYouRoute,
  ContentStatusRoute,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  CreateNewContentTypeRoute,
  CreateNewContentTypeSnippetsRoute,
  CreateNewTaxonomyGroupRoute,
  CustomAppCreatingRoute,
  CustomAppEditingRoute,
  DapiKeyDetailRoute,
  EditContentTypeRoute,
  EditContentTypeSnippetRoute,
  EditTaxonomyGroupRoute,
  EnvironmentSettingsRoute,
  EnvironmentsRoute,
  LegacyWebhookMessagesRoute,
  MapiKeyDetailRoute,
  MissionControlLanguageDependentRoute,
  MissionControlLanguageDependentRouteParams,
  MissionControlRootRoute,
  NewDapiKeyDetailRoute,
  NewMapiKeyDetailRoute,
  NewPersonalMapiKeyDetailRoute,
  PersonalMapiKeyDetailRoute,
  PreviewURLsRoute,
  ProjectOverviewRoute,
  ProjectSettingsEnvironmentsRoute,
  ProjectSettingsRoute,
  QuickstartRoute,
  RelationsEntryRoute,
  RelationsRoute,
  RoleCreatorRoute,
  RoleEditorRoute,
  RolesRoute,
  SitemapRoute,
  SubscriptionEnvironmentAuditLogRoute,
  SubscriptionEnvironmentCollectionsRoute,
  SubscriptionEnvironmentCustomAppCreatingRoute,
  SubscriptionEnvironmentCustomAppEditingRoute,
  SubscriptionEnvironmentEnvironmentsRoute,
  SubscriptionEnvironmentLegacyWebhookMessagesRoute,
  SubscriptionEnvironmentPreviewURLsRoute,
  SubscriptionEnvironmentRoleCreatorRoute,
  SubscriptionEnvironmentRoleEditorRoute,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentUsersRoute,
  SubscriptionEnvironmentWebhookMessagesRoute,
  SubscriptionEnvironmentWebhooksCreatingRoute,
  SubscriptionEnvironmentWebhooksEditingRoute,
  SubscriptionEnvironmentWorkflowCreatingRoute,
  SubscriptionEnvironmentWorkflowEditingRoute,
  SubscriptionEnvironmentWorkflowsRoute,
  TaxonomyGroupsRoute,
  UsersRoute,
  VariantRouteParams,
  WebSpotlightContentRoute,
  WebSpotlightRootRoute,
  WebhookMessagesRoute,
  WebhooksCreatingRoute,
  WebhooksEditingRoute,
  WorkflowCreatingRoute,
  WorkflowEditingRoute,
  WorkflowsRoute,
  YourContentRoute,
  YourTasksRoute,
} from '../../constants/routePaths.ts';
import { VariantIdMacro } from '../../constants/variantIdValues.ts';
import { Breadcrumbs } from '../../containers/Breadcrumbs.tsx';
import { ProjectMenu } from '../../containers/ProjectMenu.tsx';
import { ContentItemCompareRevisionTitle } from '../../containers/StatusBar/revisions/ContentItemCompareRevisionTitle.tsx';
import { ContentItemRevisionTitle } from '../../containers/StatusBar/revisions/ContentItemRevisionTitle.tsx';
import { RouteContext } from '../../containers/routing/RouteContext.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { LoadingStatus } from '../../models/LoadingStatusEnum.ts';
import { getSelectedLanguageId } from '../../selectors/getSelectedLanguageId.ts';
import { getEditingLanguageSwitcherOptions } from '../../utils/languageSwitchers/getEditingLanguageSwitcherOptions.ts';
import { getEditedContentItemIdFromPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';
import { ContentItemBreadcrumbs } from '../ContentItemBreadcrumbs.tsx';
import { MainLayoutGrid } from '../MainLayoutGrid.tsx';
import { ContainerContentType, StatusBarContainer } from './StatusBarContainer.tsx';

const environmentSettingsPathsWithoutActions = [
  AuditLogRoute,
  RolesRoute,
  SubscriptionEnvironmentAuditLogRoute,
  SubscriptionEnvironmentRolesRoute,
  SubscriptionEnvironmentWorkflowsRoute,
  WorkflowsRoute,
];

const isValidLanguageId = (variantId: string | null | undefined): variantId is Uuid =>
  isUuid(variantId) && variantId.toLowerCase() !== VariantIdMacro;

const ContentEditorLanguageSwitcherEnsurer: React.FC = () => {
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  const editedContentItem = useSelector((state) => state.contentApp.editedContentItem);
  const selectedLanguageId = useSelector(getSelectedLanguageId);

  const currentPath = useLocation().pathname;
  const contentItemId = getEditedContentItemIdFromPath(currentPath);

  const isStateEnsured =
    !!editedContentItemVariant &&
    !!editedContentItem &&
    !!selectedLanguageId &&
    editedContentItem.id === editedContentItemVariant.id.itemId &&
    editedContentItemVariant.id.itemId === contentItemId?.itemId &&
    editedContentItemVariant.id.variantId === contentItemId.variantId;

  const languageOptions = useSelector((state) =>
    isStateEnsured
      ? getEditingLanguageSwitcherOptions(state, currentPath)
      : Immutable.List.of<ILanguageSwitcherOption>(),
  );
  const showLanguageSwitcher = languageOptions.count() > 1;

  if (!isStateEnsured || !showLanguageSwitcher) {
    return null;
  }

  return (
    <ContentEditorLanguageSwitcher
      options={languageOptions}
      selectedLanguageId={selectedLanguageId}
    />
  );
};

export const StatusBar: React.FC = () => {
  const languages = useSelector((state) => state.data.languages);
  const isUnifiedMissionControlEnabled = useIsUnifiedMissionControlEnabled();

  return (
    <MainLayoutGrid.StatusBar>
      <Switch>
        <Route path={MissionControlRootRoute}>
          <Switch>
            <Route path={ContentStatusRoute}>
              <StatusBarContainer
                renderCentralContainer={() => <Breadcrumbs />}
                renderLeftContainer={() => <ProjectMenu />}
                renderRightContainer={() => <LanguagesFilterSelectorWrapper />}
              />
            </Route>
            <Route path={MissionControlLanguageDependentRoute}>
              <RouteContext<MissionControlLanguageDependentRouteParams>>
                {({
                  match: {
                    params: { variantId },
                  },
                }) => {
                  return (
                    <StatusBarContainer
                      renderCentralContainer={() => <Breadcrumbs />}
                      renderLeftContainer={() => <ProjectMenu />}
                      renderRightContainer={() =>
                        isValidLanguageId(variantId) &&
                        languages.byIdLoadingStatus === LoadingStatus.Loaded ? (
                          <MissionControlLanguageSwitcher />
                        ) : null
                      }
                      rightContentType={ContainerContentType.Shrinkable}
                    />
                  );
                }}
              </RouteContext>
            </Route>
            <Route>
              <StatusBarContainer
                renderCentralContainer={() => <Breadcrumbs />}
                renderLeftContainer={() => <ProjectMenu />}
                renderRightContainer={() => null}
              />
            </Route>
          </Switch>
        </Route>
        <Route path={ContentItemRevisionViewerRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ContentItemRevisionBackNavigation />}
            renderCentralContainer={() => (
              <Switch>
                <Route path={ContentItemRevisionCompareRoute}>
                  <ContentItemCompareRevisionTitle />
                </Route>
                <Route>
                  <ContentItemRevisionTitle />
                </Route>
              </Switch>
            )}
            renderRightContainer={() => <ContentItemRevisionToolbarActions />}
          />
        </Route>
        <Route path={ContentItemRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <Switch>
                  <Route path={WebSpotlightContentRoute}>
                    <WebSpotlightContentItemBackNavigation />
                  </Route>
                  <Route>
                    <ContentItemBackNavigation />
                  </Route>
                </Switch>
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <ContentItemBreadcrumbs
                renderSavingStatusComponent={() => <ContentItemSavingStatus />}
              />
            )}
            renderRightContainer={() => (
              <>
                <ContentEditorLanguageSwitcherEnsurer />
                <Switch>
                  <Route
                    path={[
                      ContentItemAccessDeniedRoute,
                      ContentItemPreviewWithEditorAccessDeniedRoute,
                    ]}
                  />
                  <Route>
                    <ContentItemEditorToolbarActions />
                  </Route>
                </Switch>
              </>
            )}
            rightContentType={ContainerContentType.Shrinkable}
          />
        </Route>
        <Route path={WebSpotlightRootRoute}>
          <StatusBarContainer
            renderCentralContainer={() => <Breadcrumbs />}
            renderLeftContainer={() => <ProjectMenu />}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={AssetRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <AssetBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <AssetSavingStatus />} />
            )}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={CreateNewContentTypeRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <ContentTypeBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ContentTypeSavingStatus />} />
            )}
            renderRightContainer={() => <ContentTypeCreatorToolbarActions />}
          />
        </Route>
        <Route path={EditContentTypeRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <ContentTypeBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ContentTypeSavingStatus />} />
            )}
            renderRightContainer={() => <ContentTypeEditorToolbarActions />}
          />
        </Route>
        <Route path={CreateNewContentTypeSnippetsRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <ContentTypeSnippetBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ContentTypeSnippetSavingStatus />} />
            )}
            renderRightContainer={() => <ContentTypeSnippetCreatorToolbarActions />}
          />
        </Route>
        <Route path={EditContentTypeSnippetRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <ContentTypeSnippetBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ContentTypeSnippetSavingStatus />} />
            )}
            renderRightContainer={() => <ContentTypeSnippetEditorToolbarActions />}
          />
        </Route>
        <Route path={CreateNewTaxonomyGroupRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <TaxonomyBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <TaxonomySavingStatus />} />
            )}
            renderRightContainer={() => <TaxonomyCreatorToolbarActions />}
          />
        </Route>
        <Route path={EditTaxonomyGroupRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <TaxonomyBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <TaxonomySavingStatus />} />
            )}
            renderRightContainer={() => <TaxonomyEditorToolbarActions />}
          />
        </Route>
        {!isUnifiedMissionControlEnabled && (
          <Route path={[CalendarRoute, ProjectOverviewRoute]}>
            <RouteContext<VariantRouteParams>>
              {({ match }) => {
                const {
                  params: { variantId },
                } = match;
                return (
                  <StatusBarContainer
                    renderLeftContainer={() => <ProjectMenu />}
                    renderCentralContainer={(ref) => <HomeTabs containerRef={ref} />}
                    centralContentType={ContainerContentType.Static}
                    renderRightContainer={() =>
                      isValidLanguageId(variantId) &&
                      languages.byIdLoadingStatus === LoadingStatus.Loaded ? (
                        <HomeLanguageSwitcher />
                      ) : null
                    }
                    rightContentType={ContainerContentType.Shrinkable}
                  />
                );
              }}
            </RouteContext>
          </Route>
        )}
        {!isUnifiedMissionControlEnabled && (
          <Route
            path={[
              QuickstartRoute,
              ContentAssignedToYouRoute,
              ContentRecentlyEditedByYouRoute,
              YourTasksRoute,
            ]}
          >
            <StatusBarContainer
              renderLeftContainer={() => <ProjectMenu />}
              renderCentralContainer={(ref) => <HomeTabs containerRef={ref} />}
              centralContentType={ContainerContentType.Static}
              renderRightContainer={() => null}
            />
          </Route>
        )}
        <Route path={RelationsRoute}>
          <RouteContext<VariantRouteParams>>
            {({ match }) => {
              const {
                params: { variantId },
              } = match;

              return (
                <StatusBarContainer
                  renderLeftContainer={() => <ProjectMenu />}
                  renderCentralContainer={(ref) => <ContentInventoryTabs containerRef={ref} />}
                  centralContentType={ContainerContentType.Static}
                  renderRightContainer={() =>
                    isValidLanguageId(variantId) &&
                    languages.byIdLoadingStatus === LoadingStatus.Loaded ? (
                      <RelationsLanguageSwitcher />
                    ) : null
                  }
                  rightContentType={ContainerContentType.Shrinkable}
                />
              );
            }}
          </RouteContext>
        </Route>
        <Route path={AssetListingRoute}>
          <RouteContext<VariantRouteParams>>
            {({ match }) => {
              const {
                params: { variantId },
              } = match;

              return (
                <StatusBarContainer
                  renderLeftContainer={() => <ProjectMenu />}
                  renderCentralContainer={(ref) => <ContentInventoryTabs containerRef={ref} />}
                  centralContentType={ContainerContentType.Static}
                  renderRightContainer={() =>
                    isValidLanguageId(variantId) &&
                    languages.byIdLoadingStatus === LoadingStatus.Loaded ? (
                      <ContentListingLanguageSwitcher />
                    ) : null
                  }
                  rightContentType={ContainerContentType.Shrinkable}
                />
              );
            }}
          </RouteContext>
        </Route>
        <Route path={ContentItemsRoute}>
          <RouteContext<VariantRouteParams>>
            {({ match }) => {
              const {
                params: { variantId },
              } = match;

              return (
                <StatusBarContainer
                  renderLeftContainer={() => <ProjectMenu />}
                  renderCentralContainer={(ref) => <ContentInventoryTabs containerRef={ref} />}
                  centralContentType={ContainerContentType.Static}
                  renderRightContainer={() =>
                    isValidLanguageId(variantId) &&
                    languages.byIdLoadingStatus === LoadingStatus.Loaded ? (
                      <ContentListingLanguageSwitcher />
                    ) : null
                  }
                  rightContentType={ContainerContentType.Shrinkable}
                />
              );
            }}
          </RouteContext>
        </Route>
        <Route path={ContentTypesRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => <ContentModelsTabs />}
            centralContentType={ContainerContentType.Static}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={ContentTypeSnippetsRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => <ContentModelsTabs />}
            centralContentType={ContainerContentType.Static}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={SitemapRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => <ContentModelsTabs />}
            centralContentType={ContainerContentType.Static}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={AssetTypesRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => (
              <>
                <ContentModelsTabs />
                <AssetTypeSavingStatus />
              </>
            )}
            renderRightContainer={() => <AssetTypeEditorToolbarActions />}
          />
        </Route>
        <Route path={TaxonomyGroupsRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => <ContentModelsTabs />}
            centralContentType={ContainerContentType.Static}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={[ContentItemsRoute, RelationsEntryRoute]}>
          <RouteContext<VariantRouteParams>>
            {({ match }) => {
              const {
                params: { variantId },
              } = match;
              return (
                <StatusBarContainer
                  renderLeftContainer={() => <ProjectMenu />}
                  renderCentralContainer={() => null}
                  renderRightContainer={() =>
                    isValidLanguageId(variantId) &&
                    languages.byIdLoadingStatus === LoadingStatus.Loaded ? (
                      <ContentListingLanguageSwitcher />
                    ) : null
                  }
                  rightContentType={ContainerContentType.Shrinkable}
                />
              );
            }}
          </RouteContext>
        </Route>
        {!isUnifiedMissionControlEnabled && (
          <Route path={[QuickstartRoute, YourContentRoute]}>
            <StatusBarContainer
              renderLeftContainer={() => <ProjectMenu />}
              renderCentralContainer={() => null}
              renderRightContainer={() => null}
              rightContentType={ContainerContentType.Shrinkable}
            />
          </Route>
        )}
        <Route path={RoleCreatorRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <RoleBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <RoleSavingStatus />} />
            )}
            renderRightContainer={() => <RoleCreatorToolbarActions />}
          />
        </Route>
        <Route path={RoleEditorRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <RoleBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <RoleSavingStatus />} />
            )}
            renderRightContainer={() => <RoleEditorToolbarActions />}
          />
        </Route>
        <Route path={SubscriptionEnvironmentRoleCreatorRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <RoleBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <RoleSavingStatus />} />
            )}
            renderRightContainer={() => <SubscriptionManagerRoleCreatorToolbarActions />}
          />
        </Route>
        <Route path={SubscriptionEnvironmentRoleEditorRoute}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <RoleBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <RoleSavingStatus />} />
            )}
            renderRightContainer={() => <SubscriptionManagerRoleEditorToolbarActions />}
          />
        </Route>
        <Route path={[WebhookMessagesRoute, SubscriptionEnvironmentWebhookMessagesRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EntityWebhookListingBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => <Breadcrumbs />}
            renderRightContainer={() => null}
          />
        </Route>
        <Route
          path={[
            WebhooksEditingRoute,
            SubscriptionEnvironmentWebhooksEditingRoute,
            WebhooksCreatingRoute,
            SubscriptionEnvironmentWebhooksCreatingRoute,
          ]}
        >
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EntityWebhookListingBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => <Breadcrumbs />}
            renderRightContainer={() => <EntityWebhookToolbarActions />}
          />
        </Route>
        <Route
          path={[LegacyWebhookMessagesRoute, SubscriptionEnvironmentLegacyWebhookMessagesRoute]}
        >
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <WebhookMessageListingBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => <Breadcrumbs />}
            renderRightContainer={() => null}
          />
        </Route>
        <Route
          path={[
            CustomAppEditingRoute,
            SubscriptionEnvironmentCustomAppEditingRoute,
            CustomAppCreatingRoute,
            SubscriptionEnvironmentCustomAppCreatingRoute,
          ]}
        >
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <CustomAppListingBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => <CustomAppToolbarActionsPlaceholder />}
          />
        </Route>
        <Route path={[WorkflowCreatingRoute, SubscriptionEnvironmentWorkflowCreatingRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => <Breadcrumbs />}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={[WorkflowEditingRoute, SubscriptionEnvironmentWorkflowEditingRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <WorkflowBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={[PreviewURLsRoute, SubscriptionEnvironmentPreviewURLsRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => <PreviewConfigurationToolbarActions />}
          />
        </Route>
        <Route path={environmentSettingsPathsWithoutActions}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => <Breadcrumbs />}
            renderRightContainer={() => null}
          />
        </Route>
        <Route path={[CollectionsRoute, SubscriptionEnvironmentCollectionsRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => <CollectionsToolbarActions />}
          />
        </Route>
        <Route path={[UsersRoute, SubscriptionEnvironmentUsersRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => <UsersToolbarActions />}
          />
        </Route>
        <Route path={[EnvironmentsRoute, SubscriptionEnvironmentEnvironmentsRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => <EnvironmentsToolbarActions />}
          />
        </Route>
        <Route path={[EnvironmentSettingsRoute, SubscriptionEnvironmentSettingsRoute]}>
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <EnvironmentSettingsBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs
                renderSavingStatusComponent={() => <EnvironmentSettingsStatusMessage />}
              />
            )}
            renderRightContainer={() => null}
          />
        </Route>
        <Route
          path={[
            PersonalMapiKeyDetailRoute,
            MapiKeyDetailRoute,
            DapiKeyDetailRoute,
            NewDapiKeyDetailRoute,
            NewMapiKeyDetailRoute,
            NewPersonalMapiKeyDetailRoute,
          ]}
        >
          <StatusBarContainer
            renderLeftContainer={() => (
              <>
                <ApiKeyDetailBackNavigation />
                <ProjectMenu />
              </>
            )}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ProjectSettingsSavingStatus />} />
            )}
            renderRightContainer={() => <ApiKeyDetailToolbarActions />}
          />
        </Route>
        <Route path={ProjectSettingsEnvironmentsRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ProjectSettingsSavingStatus />} />
            )}
            renderRightContainer={() => <EnvironmentsToolbarActions />}
          />
        </Route>
        <Route path={ProjectSettingsRoute}>
          <StatusBarContainer
            renderLeftContainer={() => <ProjectMenu />}
            renderCentralContainer={() => (
              <Breadcrumbs renderSavingStatusComponent={() => <ProjectSettingsSavingStatus />} />
            )}
            renderRightContainer={() => null}
          />
        </Route>
        <Route>
          <StatusBarContainer
            renderLeftContainer={() => null}
            renderCentralContainer={() => null}
            renderRightContainer={() => null}
          />
        </Route>
      </Switch>
    </MainLayoutGrid.StatusBar>
  );
};

StatusBar.displayName = 'StatusBar';
