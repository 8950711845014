import { ItemElementValidationResult } from '../../ItemElementValidationResult.type.ts';

export enum ElementError {
  InvalidDateTime = 'InvalidDateTime',
  InvalidLinkedItems = 'InvalidLinkedItems',
  InvalidNumber = 'InvalidNumber',
  InvalidTaxonomy = 'InvalidTaxonomy',
  NumberTooLarge = 'NumberTooLarge',
  TextTooLong = 'TextTooLong',
}

export type ElementErrorResult = {
  readonly errors: ReadonlyArray<ElementError>;
};

export const emptyElementErrorResult: ElementErrorResult = {
  errors: [],
};

export type ItemElementErrorResult = {
  readonly errorMessages: ReadonlyArray<string>;
} & ItemElementValidationResult;

export type ItemElementErrors = ReadonlyMap<UuidPath, ItemElementErrorResult>;

export const emptyItemElementErrorResult: ItemElementErrorResult = {
  errorMessages: [],
};
