import { usePrevious } from '@kontent-ai/hooks';
import { ContentBlock, ContentState } from 'draft-js';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { getEditorIdClassName } from '../../../editorCore/utils/editorComponentUtils.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../../utils/blocks/editorBlockGetters.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';

// In order for Safari to properly refresh CSS counters upon list changes we need it to force refresh the CSS
// Here is the trick how
// https://stackoverflow.com/questions/54822993/css-counter-reset-issue-in-safari
// We apply the refresh in case the ordered list item significantly change
// We only care about ordered list items and their depth in the comparison
// and the number of other blocks between them (in case they are non-continuous)
// anything before the first ordered list item and after last is ignored as it doesn't influence the counters
const getBlockSignature = (block: ContentBlock): string =>
  getBaseBlockType(block) === BlockType.OrderedListItem ? block.getDepth().toString() : '';

const getBlocksSignature = (content: ContentState): string =>
  getBlocks(content).map(getBlockSignature).join(' ').trim();

interface IRichTextListRefresher {
  readonly content: ContentState;
  readonly editorId: string;
  readonly editorRef: React.RefObject<HTMLElement>;
}

export const RichTextListRefresher: React.FC<IRichTextListRefresher> = ({
  content,
  editorId,
  editorRef,
}) => {
  const [editorRefreshing, setEditorRefreshing] = useState(false);

  // 1) Whenever the blocks change in a way that could influence numbering of list items, trigger the refresh
  const blocksSignature = useMemo(() => getBlocksSignature(content), [content]);
  const previousBlockSignature = usePrevious(blocksSignature);

  useLayoutEffect(() => {
    if (blocksSignature !== previousBlockSignature) {
      setEditorRefreshing(true);
    }
  }, [blocksSignature, previousBlockSignature]);

  // 2a) The refresh itself consists of changing the content of the list items
  const inlineStyleString = `.${getEditorIdClassName(editorId)} .rte__olist-item:before {
    content: "..." !important;
  }`;

  useLayoutEffect(() => {
    const editor = editorRef.current;
    if (editor && editorRefreshing) {
      // 2b) Which continues with forced layout recalculation by reading the offset dimension below
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      editor.offsetHeight; // no need to store this anywhere, the reference is enough

      // 3) After the reset is done, we return everything back to normal and wait for another significant change
      setEditorRefreshing(false);
    }
  }, [editorRefreshing, editorRef]);

  if (!editorRefreshing) {
    return null;
  }

  return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
};

RichTextListRefresher.displayName = 'RichTextListRefresher';
