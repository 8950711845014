import { Dispatch, GetState, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IContentType } from '../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { getWorkflowsCurrentUserCanCreateVariantIn } from '../../../../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import { CollectionSection } from '../../constants/collectionSection.ts';
import { getContentTypeSelectorState } from '../../selectors/getContentTypeSelectorState.ts';
import {
  getNewContentItemDialogProperties,
  getNewContentItemForm,
} from '../../selectors/newContentItemDialog.ts';
import { getAvailableCollections } from '../../utils/getAvailableCollections.ts';
import { getAvailableContentTypes } from '../../utils/getAvailableContentTypes.ts';
import { getPreselectedContentType } from '../../utils/getPreselectedContentType.ts';
import { getSuggestedContentTypeIds } from '../../utils/getSuggestedContentTypeIds.ts';
import {
  newContentItemSelectedCollectionChanged,
  newContentItemSelectedContentTypeChanged,
  newContentItemSelectedWorkflowChanged,
} from '../newContentItemActions.ts';

export const createValidateSelectedValuesAction =
  () =>
  (): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState): void => {
    const state = getState();
    const { languageId } = getNewContentItemForm(state);
    const collectionId = dispatch(ensureValidSelectedCollection(languageId));
    const contentTypeId = dispatch(ensureValidSelectedContentType(collectionId, languageId));
    dispatch(ensureValidSelectedWorkflow(collectionId, contentTypeId, languageId));
  };

const ensureValidSelectedCollection =
  (selectedLanguageId: Uuid): ThunkFunction<Uuid | null> =>
  (dispatch: Dispatch, getState: GetState): Uuid | null => {
    const state = getState();
    const { collectionId } = getNewContentItemForm(state);
    const { origin } = getNewContentItemDialogProperties(state);
    const availableCollections = getAvailableCollections(origin, state, selectedLanguageId);
    const isSelectedCollectionAvailable = availableCollections.find(
      (collection) => collection.id === collectionId,
    );

    if (isSelectedCollectionAvailable) {
      return collectionId;
    }

    const preselectedCollectionId =
      availableCollections.length === 1 ? (availableCollections[0]?.id ?? null) : null;
    const preselectedSection = preselectedCollectionId
      ? CollectionSection.AllCollections
      : CollectionSection.None;

    dispatch(newContentItemSelectedCollectionChanged(preselectedCollectionId, preselectedSection));

    return preselectedCollectionId;
  };

const ensureValidSelectedContentType =
  (selectedCollectionId: Uuid | null, selectedLanguageId: Uuid): ThunkFunction<Uuid | null> =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { contentTypeId } = getNewContentItemForm(state);
    const { origin } = getNewContentItemDialogProperties(state);
    const availableContentTypes = getAvailableContentTypes(
      origin,
      state,
      selectedLanguageId,
      selectedCollectionId,
    );

    const isSelectedContentTypeAvailable = availableContentTypes.find(
      (contentType: IContentType) => contentType.id === contentTypeId,
    );

    if (isSelectedContentTypeAvailable) {
      return contentTypeId;
    }

    const { contentType } = state.contentApp.newContentItem.formConfig;
    const contentTypeSelectorState = getContentTypeSelectorState(state);
    const suggestedContentTypeIds = getSuggestedContentTypeIds(
      availableContentTypes,
      contentType.suggestedContentTypeIds,
      contentType.recentlyUsedContentTypeIds,
    );
    const preselectedContentType = getPreselectedContentType(
      availableContentTypes,
      contentType.allowedContentTypeIds,
      suggestedContentTypeIds,
      contentTypeSelectorState,
    );

    dispatch(
      newContentItemSelectedContentTypeChanged(
        preselectedContentType.contentTypeId,
        preselectedContentType.contentTypeSection,
      ),
    );

    return preselectedContentType.contentTypeId;
  };

const ensureValidSelectedWorkflow =
  (
    selectedCollectionId: Uuid | null,
    selectedContentTypeId: Uuid | null,
    selectedLanguageId: Uuid,
  ): ThunkFunction<Uuid | null> =>
  (dispatch: Dispatch, getState: GetState): Uuid | null => {
    const state = getState();
    const { workflowId } = getNewContentItemForm(state);
    const availableWorkflows = getWorkflowsCurrentUserCanCreateVariantIn(
      state,
      selectedLanguageId,
      selectedCollectionId,
      selectedContentTypeId,
    );
    const isSelectedWorkflowAvailable = availableWorkflows.find(
      (workflow) => workflow.id === workflowId,
    );

    if (isSelectedWorkflowAvailable) {
      return workflowId;
    }

    const preselectedWorkflowId =
      availableWorkflows.length === 1 ? (availableWorkflows[0]?.id ?? null) : null;

    dispatch(newContentItemSelectedWorkflowChanged(preselectedWorkflowId));

    return preselectedWorkflowId;
  };
