import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForAssets } from '../../../../../_shared/selectors/contentCollections.ts';
import { isDefaultAssetTypeCustomized } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { FlexingTileContextProvider } from '../AssetTile/FlexingTileContext.tsx';

export const AssetLibraryLayout: React.FC<{
  readonly renderFilter: () => React.ReactNode;
  readonly renderContent: () => React.ReactNode;
}> = ({ renderContent, renderFilter }) => {
  const displayFilter = useSelector((s) => {
    return (
      isDefaultAssetTypeCustomized(s.data.assetTypes.defaultAssetType) ||
      areCollectionsVisibleForAssets(s, Collection.getValues(s.data.collections.byId))
    );
  });

  if (!displayFilter) {
    return <>{renderContent()}</>;
  }

  return (
    <Row
      noWrap
      spacing={Spacing.XL}
      // We need to apply min-height to the row's flex, otherwise the layout doesn't force maximum height and scrollbar doesn't display
      css={`
        flex: 1 1 auto;
        min-height: 0;
      `}
    >
      <Column width="content">{renderFilter()}</Column>
      <Column>
        <Box
          display="flex"
          maxHeight="100%"
          paddingTop={spacingMainLayoutTop}
          paddingRight={spacingMainLayoutRight}
        >
          <FlexingTileContextProvider tileClassName="col-sm-12 col-md-8 col-lg-6">
            {renderContent()}
          </FlexingTileContextProvider>
        </Box>
      </Column>
    </Row>
  );
};

AssetLibraryLayout.displayName = 'AssetLibraryLayout';
