import { memoize } from '@kontent-ai/memoization';
import { ILanguage, Languages } from '../../../../data/models/languages/Language.ts';

export const searchLanguageName = (
  languageId: Uuid,
  defaultLanguage: ILanguage,
  languages: Languages,
): string =>
  languageId === defaultLanguage.id ? defaultLanguage.name : languages.get(languageId)?.name || '';

export const getLanguageName = (
  languageId: Uuid,
  defaultLanguage: ILanguage,
  languages: Languages,
): string | null =>
  languages.isEmpty() ? null : searchLanguageName(languageId, defaultLanguage, languages);

const DefaultSuffix = '(Default)';
const InactiveSuffix = '(Inactive)';

export const addDefaultLanguageSuffix = memoize.maxOne((defaultLanguage: ILanguage): ILanguage => {
  if (!defaultLanguage.name.toLowerCase().includes('default')) {
    return {
      ...defaultLanguage,
      name: `${defaultLanguage.name} ${DefaultSuffix}`,
    };
  }
  return defaultLanguage;
});

export const addInactiveLanguageSuffix = memoize.maxOne((language: ILanguage): ILanguage => {
  if (language.inactive) {
    return {
      ...language,
      name: `${language.name} ${InactiveSuffix}`,
    };
  }
  return language;
});
