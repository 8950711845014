import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionAdminServerModel } from '../../../../../repositories/serverModels/SubscriptionAdminServerModel.type.ts';
import {
  ISubscriptionAdmin,
  createSubscriptionAdminDomainModel,
} from '../../../shared/models/SubscriptionAdmin.ts';
import {
  Subscription_PromoteAdmin_Failed,
  Subscription_PromoteAdmin_Finished,
  Subscription_PromoteAdmin_Started,
} from '../../constants/actionTypes.ts';

interface IPromoteUsersDependencies {
  readonly subscriptionRepository: {
    readonly promoteUsersToSubscriptionAdmins: (
      subscriptionId: Uuid,
      existingUsers: UserId[],
    ) => Promise<ISubscriptionAdminServerModel[]>;
  };
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
}

const started = () =>
  ({
    type: Subscription_PromoteAdmin_Started,
  }) as const;

const finished = (promotedUsers: ReadonlyArray<ISubscriptionAdmin>, subscriptionId: Uuid) =>
  ({
    type: Subscription_PromoteAdmin_Finished,
    payload: {
      promotedUsers,
      subscriptionId,
    },
  }) as const;

const failed = (errorMessage: string = '') =>
  ({
    type: Subscription_PromoteAdmin_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type PromoteUsersActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const promoteUsersCreator =
  (dependencies: IPromoteUsersDependencies) =>
  (subscriptionId: Uuid, existingUserIds: ReadonlySet<UserId>): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(started());
      const promotedUserServerModels =
        await dependencies.subscriptionRepository.promoteUsersToSubscriptionAdmins(
          subscriptionId,
          Array.from(existingUserIds),
        );
      const promotedUsers = promotedUserServerModels.map(createSubscriptionAdminDomainModel);

      dispatch(finished(promotedUsers, subscriptionId));

      await dispatch(dependencies.loadAdministratedSubscriptions());
    } catch (error) {
      dispatch(
        failed(
          'The user promotion failed as we’re unable to reach the servers. Check ' +
            'your internet connection or try promote users again in a couple of minutes.',
        ),
      );
      throw error;
    }
  };
