import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { IContentTypeSnippet } from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import {
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted,
  ContentTypeSnippet_Listing_ResetOperationState,
  ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted,
  ContentTypeSnippet_Listing_RouteEntered,
  ContentTypeSnippet_Listing_SelectedTypeSnippetsUpdated,
} from '../../constants/snippetActionTypes.ts';
import {
  ContentTypeSnippetListingOperationStatus,
  ContentTypeSnippetListingOperationType,
  getContentTypeSnippetListingFailedOperationMessage,
  getContentTypeSnippetListingOperationMessage,
  getContentTypeSnippetListingOperationMessageStyle,
} from '../../utils/contentTypeSnippetListingOperationStatus.ts';

const initialListingOperationSnippetStatus: ContentTypeSnippetListingOperationStatus = {
  affectedContentTypeIds: Immutable.Set<Uuid>(),
  message: 'Content type snippets',
  messageStyle: StatusMessageStyle.Normal,
  operationType: ContentTypeSnippetListingOperationType.NoOperation,
  restorableContentTypeSnippets: Immutable.Set<IContentTypeSnippet>(),
};

function getListingOperationStatus(
  operationType: ContentTypeSnippetListingOperationType,
  affectedSnippetIds: Immutable.Set<Uuid>,
  restorableSnippets: Immutable.Set<IContentTypeSnippet>,
  sucessfulIds: Immutable.Set<Uuid>,
  failedIds: Immutable.Set<Uuid>,
): ContentTypeSnippetListingOperationStatus {
  return {
    message:
      getContentTypeSnippetListingOperationMessage(operationType, sucessfulIds) +
      getContentTypeSnippetListingFailedOperationMessage(failedIds),
    messageStyle: getContentTypeSnippetListingOperationMessageStyle(operationType),
    operationType,
    affectedContentTypeIds: affectedSnippetIds,
    restorableContentTypeSnippets: restorableSnippets,
  };
}

export function listingOperationStatus(
  state = initialListingOperationSnippetStatus,
  action: Action,
): ContentTypeSnippetListingOperationStatus {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
    case ContentTypeSnippet_Listing_RouteEntered: {
      return initialListingOperationSnippetStatus;
    }

    case ContentTypeSnippet_Listing_ResetOperationState: {
      if (state === action.payload.operationToReset) {
        return initialListingOperationSnippetStatus;
      }
      return state;
    }

    case ContentTypeSnippet_Listing_SelectedTypeSnippetsUpdated: {
      const { selectedContentTypeSnippetIds } = action.payload;
      if (selectedContentTypeSnippetIds.isEmpty()) {
        return initialListingOperationSnippetStatus;
      }
      return getListingOperationStatus(
        ContentTypeSnippetListingOperationType.Selected,
        selectedContentTypeSnippetIds,
        state.restorableContentTypeSnippets,
        selectedContentTypeSnippetIds,
        Immutable.Set<Uuid>(),
      );
    }

    case ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted: {
      const affectedSnippetIds: Immutable.Set<Uuid> = action.payload.affectedContentTypeSnippetIds;
      return getListingOperationStatus(
        ContentTypeSnippetListingOperationType.Archiving,
        affectedSnippetIds,
        Immutable.Set<IContentTypeSnippet>(),
        affectedSnippetIds,
        Immutable.Set<Uuid>(),
      );
    }

    case ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished: {
      const affectedSnippets: Immutable.Set<IContentTypeSnippet> =
        action.payload.affectedContentTypeSnippets;
      const affectedSnippetIds = affectedSnippets
        .map((type: IContentTypeSnippet) => type.id)
        .toSet();
      const successfulIds: Immutable.Set<Uuid> = action.payload.successfulIds;
      const archivedSnippets = affectedSnippets
        .filter((snippet: IContentTypeSnippet) => successfulIds.has(snippet.id))
        .toSet();
      return getListingOperationStatus(
        ContentTypeSnippetListingOperationType.Archived,
        affectedSnippetIds,
        archivedSnippets,
        successfulIds,
        affectedSnippetIds.subtract(successfulIds),
      );
    }

    case ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished: {
      const affectedSnippetIds: Immutable.Set<Uuid> = action.payload.affectedContentTypeSnippetIds;
      const successfulIds = action.payload.successfulIds;
      return getListingOperationStatus(
        ContentTypeSnippetListingOperationType.Restored,
        affectedSnippetIds,
        Immutable.Set<IContentTypeSnippet>(),
        successfulIds,
        affectedSnippetIds.subtract(successfulIds),
      );
    }

    case ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted: {
      const affectedSnippets: Immutable.Set<IContentTypeSnippet> =
        action.payload.affectedContentTypeSnippets;
      const affectedSnippetIds = affectedSnippets
        .map((type: IContentTypeSnippet) => type.id)
        .toSet();
      return getListingOperationStatus(
        ContentTypeSnippetListingOperationType.Restoring,
        affectedSnippetIds,
        Immutable.Set<IContentTypeSnippet>(),
        affectedSnippetIds,
        Immutable.Set<Uuid>(),
      );
    }

    default:
      return state;
  }
}
