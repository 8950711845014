import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { IFriendlyWarning } from '../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ContentItemUsages } from '../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { DateTime } from '../../../models/DateTime.ts';
import { IChangeWorkflowStepModalData } from '../reducers/IChangeWorkflowStepModalData.ts';
import { ChangeWorkflowStepModalAction } from '../types/workflowStepModalActionTypes.ts';
import { AssignmentSection } from './ChangeAssignmentSections/AssignmentSection.tsx';
import { MoveToArchivedStepSection } from './ChangeAssignmentSections/MoveToArchivedStepSection.tsx';
import { PublishingSection } from './ChangeAssignmentSections/PublishingSection.tsx';
import { UnpublishingSection } from './ChangeAssignmentSections/UnpublishingSection.tsx';

export interface IChangeAssignmentSectionProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly changeWorkflowStepModalData: IChangeWorkflowStepModalData;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
  readonly onDueDateChange: (dateTime: DateTime) => void;
  readonly onNoteChanged: (newNote: string) => void;
  readonly itemIds: ReadonlySet<Uuid>;
  readonly contentItemUsages: ContentItemUsages;
}

export const ChangeAssignmentSection: React.FC<IChangeAssignmentSectionProps> = (props) => {
  switch (props.changeWorkflowStepModalData.workflowStepAction) {
    case ChangeWorkflowStepModalAction.UpdateStep:
    case ChangeWorkflowStepModalAction.RestoreFromArchivedStep:
      return (
        <AssignmentSection
          activeUsersNames={props.activeUsersNames}
          dueDate={props.changeWorkflowStepModalData.dueDate.value}
          friendlyWarningReasons={props.friendlyWarningReasons}
          note={props.changeWorkflowStepModalData.note}
          onDueDateChange={props.onDueDateChange}
          onNoteChanged={props.onNoteChanged}
          workflowStep={props.changeWorkflowStepModalData.workflowStep}
        />
      );

    case ChangeWorkflowStepModalAction.Publish:
    case ChangeWorkflowStepModalAction.ScheduleToPublish:
      return (
        <PublishingSection
          activeUsersNames={props.activeUsersNames}
          friendlyWarningReasons={props.friendlyWarningReasons}
          scheduledToUnpublishAt={props.changeWorkflowStepModalData.scheduledToUnpublishAt}
        />
      );

    case ChangeWorkflowStepModalAction.UnpublishAndArchive:
    case ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive:
      return (
        <UnpublishingSection contentItemUsages={props.contentItemUsages} itemIds={props.itemIds} />
      );

    case ChangeWorkflowStepModalAction.MoveToArchivedStep:
      return (
        <MoveToArchivedStepSection
          activeUsersNames={props.activeUsersNames}
          friendlyWarningReasons={props.friendlyWarningReasons}
        />
      );

    default:
      throw InvariantException(
        `ChangeAssignmentSection.tsx: Workflow step action ${props.changeWorkflowStepModalData.workflowStepAction} is not supported.`,
      );
  }
};

ChangeAssignmentSection.displayName = 'ChangeAssignmentSection';
