import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { getStatusMessageText } from './statusInfoMessage.ts';
import { AvailableUndoFunctions, IUndo, makeGetUndo } from './statusInfoUndoFunction.ts';
import { StatusMessageStyle, getStatusMessageStyle } from './statusMessageStyle.ts';

export type StatusInfo = {
  readonly text: string;
  readonly style: StatusMessageStyle;
  readonly undo: IUndo | undefined;
};

export const makeGetStatusInfo = (deps: AvailableUndoFunctions) => {
  const getUndoInfo = makeGetUndo(deps);

  return (store: IStore): StatusInfo => ({
    text: getStatusMessageText(store),
    style: getStatusMessageStyle(store),
    undo: getUndoInfo(store),
  });
};
