import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_Sitemap_Started, Data_Sitemap_Success } from '../../../constants/dataActionTypes.ts';
import {
  ISitemap,
  createSitemapFromServerModel,
  emptySitemap,
} from '../../../models/contentModelsApp/sitemap/Sitemap.ts';

const initialState: ISitemap = emptySitemap;

export function data(state: ISitemap = initialState, action: Action): ISitemap {
  switch (action.type) {
    case Data_Sitemap_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_Sitemap_Success:
      return createSitemapFromServerModel(action.payload.data);

    default:
      return state;
  }
}
