import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ContentItemRoute, ContentItemRouteParams } from '../../../_shared/constants/routePaths.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { matchPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { ItemSubpagesIds } from '../../../repositories/serverModels/SubpageItemIdsServerModel.type.ts';
import { isWebSpotlightEnabled } from '../selectors/webSpotlightSelectors.ts';
import { NodeId } from '../types/nodeId.type.ts';

const WebSpotlightNodeIdSeparator = '/';

export const canDisplayItem = (item: IListingContentItem): boolean | null =>
  item && !item.item.archived && item.variant && !item.variant.isArchived;

export const createSubpagesByIdMap = (
  itemSubpagesById: ReadonlyArray<ItemSubpagesIds>,
): Immutable.Map<Uuid, UuidArray> =>
  Immutable.Map(itemSubpagesById.map((item) => [item.itemId, item.subpagesIds]));

export const getRootNodeId = (contentItemId: Uuid): NodeId =>
  `${WebSpotlightNodeIdSeparator}${contentItemId}`;

export const getNodeIdFromPath = (path: string): NodeId => {
  const match = matchPath<ContentItemRouteParams<string>>(path, ContentItemRoute);
  if (!match) {
    return '';
  }

  return `${WebSpotlightNodeIdSeparator}${match.contentItemIds}`;
};

export const getNodeId = (parentPath: string, contentItemId: Uuid): NodeId => {
  const parentNodeId = getNodeIdFromPath(parentPath);

  return `${parentNodeId}${WebSpotlightNodeIdSeparator}${contentItemId}`;
};

export const getItemIdsFromPath = (path: string): UuidArray => {
  const nodeId = getNodeIdFromPath(path);
  return nodeId.split(WebSpotlightNodeIdSeparator).slice(1);
};

export const getSelectedItemIdFromPath = (path: string): Uuid | null => {
  const itemIds = getItemIdsFromPath(path);
  const lastItemId = Collection.getLast(itemIds);
  return lastItemId;
};

export const getNodeIdFromPathByIndex = (path: string, index: number): NodeId => {
  const itemIds = getItemIdsFromPath(path);
  return `${WebSpotlightNodeIdSeparator}${itemIds
    .slice(0, index + 1)
    .join(WebSpotlightNodeIdSeparator)}`;
};

export const checkIfItemHasSubpages = (
  subpagesById: Immutable.Map<Uuid, UuidArray>,
  contentItems: Immutable.Map<Uuid, IListingContentItem>,
  itemId: Uuid,
): boolean => {
  const subpagesIds = subpagesById.get(itemId);

  if (!subpagesIds || subpagesIds.length === 0) {
    return false;
  }
  const notArchivedSubpagesIds = contentItems
    ? subpagesIds.filter((subpageItemId) => !contentItems.get(subpageItemId)?.item.archived)
    : subpagesIds;

  return notArchivedSubpagesIds.length > 0;
};

export const isWebSpotlightRootType = (state: IStore, contentTypeId: Uuid): boolean => {
  return (
    isWebSpotlightEnabled(state) &&
    state.webSpotlightApp.configuration?.rootTypeId === contentTypeId
  );
};

export const isUsedAsWebSpotlightRootItem = (state: IStore, contentItemId: Uuid): boolean =>
  Collection.getValues(state.data.spaces.byId).some(
    (space) => space.webSpotlightRootItemId === contentItemId,
  );

export const getGreaterDate = (dateA: Date, dateB: Date) =>
  dateA.getTime() >= dateB.getTime() ? dateA : dateB;
