import { areShallowEqual } from '@kontent-ai/utils';
import { IUserFilterState, defaultUserFilter } from '../reducers/internalReducers/filter.ts';

const isAnySelectionFilterActive = (state: IUserFilterState): boolean => {
  const { byCollection, byLanguage, byStatus, byRoles } = state;

  return (
    byStatus !== defaultUserFilter.byStatus ||
    !areShallowEqual(byRoles, defaultUserFilter.byRoles) ||
    !areShallowEqual(byLanguage, defaultUserFilter.byLanguage) ||
    !areShallowEqual(byCollection, defaultUserFilter.byCollection)
  );
};

export const isAnyFilterActive = (state: IUserFilterState): boolean => {
  return state.byName !== defaultUserFilter.byName || isAnySelectionFilterActive(state);
};

export const isFilteredOnlyBySelections = (state: IUserFilterState): boolean => {
  return state.byName === defaultUserFilter.byName && isAnySelectionFilterActive(state);
};

export const isFilteredOnlyByName = (state: IUserFilterState): boolean => {
  return state.byName !== defaultUserFilter.byName && !isAnySelectionFilterActive(state);
};
