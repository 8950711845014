import React, { memo } from 'react';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EditorProps,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { DiffPlugin } from '../../plugins/diff/DiffPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useComposition as useViewerComposition } from './SimpleMultilineTextViewer.tsx';

const useComposition = () => usePluginComposition(useViewerComposition(), DiffPlugin);

export type SimpleMultilineTextDiffProps = EditorProps<typeof useComposition>;

export const SimpleMultilineTextDiff: React.FC<SimpleMultilineTextDiffProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    ...props,
    dataUiInput: props.dataUiInput ?? DataUiInput.ItemTextElement,
    keyCommandMap: textKeyCommandMap,
    limitations: SimpleTextEditorLimitations,
  });
});

SimpleMultilineTextDiff.displayName = 'SimpleMultilineTextDiff';
