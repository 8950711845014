import { connect } from 'react-redux';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { Asset, EmptyAsset } from '../../../../../../data/models/assets/Asset.ts';
import { buildAssetRoutePath } from '../../../../../contentInventory/assets/utils/assetsPathUtils.ts';
import {
  AssetLinkDetail as AssetLinkDetailComponent,
  IAssetLinkDetailOwnProps,
  IAssetLinkDetailStateProps,
} from '../components/AssetLinkDetail.tsx';

interface IAssetLinkDetailContainerProps extends IAssetLinkDetailOwnProps {
  readonly assetId: Uuid;
  readonly disabled?: boolean;
  readonly entityKey: string;
  readonly isViewOnly?: boolean;
}

function mapStateToProps(
  state: IStore,
  ownProps: IAssetLinkDetailContainerProps,
): IAssetLinkDetailStateProps {
  const { currentProjectId } = state.sharedApp;

  const asset = state.data.assets.byId.get(ownProps.assetId) ?? EmptyAsset;
  const assetName = asset ? asset.title || asset.filename : '';
  const assetArchived = asset ? asset.archived : true;
  const assetPath =
    ownProps.isViewOnly && asset._link
      ? asset._link
      : buildAssetRoutePath({ assetId: ownProps.assetId, projectId: currentProjectId });
  const canViewContent = canUserViewAnyActiveLanguage(state);

  return {
    assetName,
    assetPath,
    assetArchived,
    canViewContent,
    exists: Asset.exists(asset),
    isUploading: !!asset._uploading,
    uploadedSoFar: asset._uploaded,
  };
}

export const AssetLinkDetail = connect(mapStateToProps, undefined, undefined, { forwardRef: true })(
  AssetLinkDetailComponent,
);
