import Immutable from 'immutable';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

export type ContentTypeSnippetListingOperationStatus = {
  readonly message: string;
  readonly messageStyle: StatusMessageStyle;
  readonly operationType: ContentTypeSnippetListingOperationType;
  readonly affectedContentTypeIds: Immutable.Set<Uuid>;
  readonly restorableContentTypeSnippets: Immutable.Set<IContentTypeSnippet>;
};

export enum ContentTypeSnippetListingOperationType {
  NoOperation = 'nooperation',
  Archiving = 'archiving',
  Archived = 'archived',
  Restoring = 'restoring',
  Restored = 'restored',
  Selected = 'selected',
}

const operationTypeToMessageStyleMap = {
  [ContentTypeSnippetListingOperationType.NoOperation]: StatusMessageStyle.Normal,
  [ContentTypeSnippetListingOperationType.Archiving]: StatusMessageStyle.Deleted,
  [ContentTypeSnippetListingOperationType.Archived]: StatusMessageStyle.Deleted,
  [ContentTypeSnippetListingOperationType.Restoring]: StatusMessageStyle.Updated,
  [ContentTypeSnippetListingOperationType.Restored]: StatusMessageStyle.Updated,
  [ContentTypeSnippetListingOperationType.Selected]: StatusMessageStyle.Selected,
};

const operationTypeToMessageTemplateMap = {
  [ContentTypeSnippetListingOperationType.NoOperation]: () => 'Content type snippets',
  [ContentTypeSnippetListingOperationType.Archiving]: (typeIds: Immutable.Set<Uuid>) =>
    `Deleting ${pluralizeWithCount('content type snippet', typeIds.size)}`,
  [ContentTypeSnippetListingOperationType.Archived]: (typeIds: Immutable.Set<Uuid>) =>
    `${pluralizeWithCount('Content type snippet', typeIds.size)} deleted`,
  [ContentTypeSnippetListingOperationType.Restoring]: (typeIds: Immutable.Set<Uuid>) =>
    `Restoring ${pluralizeWithCount('content type snippet', typeIds.size)}`,
  [ContentTypeSnippetListingOperationType.Restored]: (typeIds: Immutable.Set<Uuid>) =>
    `${pluralizeWithCount('Content type snippet', typeIds.size)} restored`,
  [ContentTypeSnippetListingOperationType.Selected]: (typeIds: Immutable.Set<Uuid>) =>
    `${pluralizeWithCount('Content type snippet', typeIds.size)} selected`,
};

export const getContentTypeSnippetListingFailedOperationMessage = (
  failedIds: Immutable.Set<Uuid>,
) => (failedIds.size > 0 ? `, ${failedIds.size} failed` : '');

export function getContentTypeSnippetListingOperationMessage(
  operationType: ContentTypeSnippetListingOperationType,
  affectedContentTypeIds: Immutable.Set<Uuid>,
): string {
  return (operationTypeToMessageTemplateMap[operationType] as any)(affectedContentTypeIds);
}

export function getContentTypeSnippetListingOperationMessageStyle(
  operationType: ContentTypeSnippetListingOperationType,
): StatusMessageStyle {
  return operationTypeToMessageStyleMap[operationType];
}
