import React, { useContext } from 'react';
import { modalOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isProjectMultilingual } from '../../../../../_shared/selectors/isProjectMultilingual.ts';
import { getLanguageName } from '../../../../../_shared/utils/languageSwitchers/languageSwitcherUtils.ts';
import { hasCapabilityInLanguage } from '../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import {
  clearContentItemListingFilter,
  updateFilterSearchPhrase,
} from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import {
  areListingFilterAndSearchEmpty,
  isListingFilterEmpty,
} from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { getUniqueCollectionIdsFromSpaceIds } from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { prepareNewContentItemDialogForContentStatus } from '../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { ContentStatusScrollTableEmptyState as ContentStatusScrollTableEmptyStateComponent } from '../../components/ContentStatusScrollTable/ContentStatusScrollTableEmptyState.tsx';
import { LanguageFilterContext } from '../../contexts/LanguageFilter.tsx';

interface IContainerProps {
  readonly forcedContentTypeIds?: ReadonlySet<Uuid> | undefined;
  readonly isInDialog: boolean;
}

export const ContentStatusScrollTableEmptyState: React.FC<IContainerProps> = (props) => {
  const dispatch = useDispatch();
  const { selectedLanguageIds, setSelectedLanguageIds } = useContext(LanguageFilterContext);

  const listingContentItems = useSelector((state) => state.data.listingContentItems);
  const languages = useSelector((state) => state.data.languages);
  const filter = useSelector((state) => state.contentApp.listingUi.filter);

  const isMultilingual = useSelector((state) => isProjectMultilingual(state));
  const selectedLanguageId = useSelector((state) => getSelectedLanguageIdOrThrow(state));
  const canCreate = useSelector((state) =>
    hasCapabilityInLanguage(state, Capability.CreateContent, selectedLanguageId),
  );

  // KCL-12266 Remove isSpaceAndCollectionMismatched during April
  const spacesById = useSelector((state) => state.data.spaces.byId);
  const spaceCollectionIds = getUniqueCollectionIdsFromSpaceIds(
    spacesById,
    filter.selectedSpacesNodes,
  );
  const isSpaceAndCollectionMismatched = [...filter.selectedCollectionsNodes].some(
    (selectedCollectionId) => !spaceCollectionIds.has(selectedCollectionId),
  );

  const isAnyLanguageSelected = selectedLanguageIds.size !== 0;
  const filterIsActive = !areListingFilterAndSearchEmpty(filter) || isAnyLanguageSelected;
  const viewIsEmpty = !listingContentItems.allIds || listingContentItems.allIds.isEmpty();

  return (
    <ContentStatusScrollTableEmptyStateComponent
      canCreate={canCreate}
      inventoryIsEmpty={!filterIsActive && viewIsEmpty}
      isFilterEmpty={isListingFilterEmpty(filter) && !isAnyLanguageSelected}
      isMultilingualProject={isMultilingual}
      noFilteredResults={filterIsActive && viewIsEmpty}
      onClearFilter={() => {
        dispatch(clearContentItemListingFilter(props.forcedContentTypeIds));
        setSelectedLanguageIds(new Set());
      }}
      onClearSearchPhrase={() => dispatch(updateFilterSearchPhrase('', props.isInDialog))}
      onCreateNewItem={() => {
        dispatch(
          prepareNewContentItemDialogForContentStatus({
            allowedContentTypeIds: null,
          }),
        );
        dispatch(modalOpened(ModalDialogType.NewContentItemDialog));
      }}
      searchPhrase={filter.searchPhrase}
      selectedLanguageName={getLanguageName(
        selectedLanguageId,
        getAllLanguagesWithDefaultSuffix(languages),
      )}
      isSpaceAndCollectionMismatched={isSpaceAndCollectionMismatched}
    />
  );
};
