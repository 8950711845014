import { ICommentContentModel } from './Comment.ts';
import { ISuggestionContentModel } from './Suggestion.ts';

export enum CommentThreadItemType {
  Comment = 'Comment',
  Suggestion = 'Suggestion',
}

export interface ICommentThreadItemBaseContentModel {
  readonly type: CommentThreadItemType;
}

export interface ICommentThreadItem extends ICommentThreadItemBaseContentModel {
  readonly id: Uuid;
  readonly createdAt: DateTimeStamp;
  readonly updatedAt: DateTimeStamp;
  readonly createdBy: UserId;
  // ui properties
  readonly isSubmitting: boolean;
  readonly isEditing: boolean;
}

export type ICommentThreadItemContentModel = ICommentContentModel | ISuggestionContentModel;
