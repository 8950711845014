import { RefObject, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

type Size = {
  readonly width?: number;
  readonly height?: number;
};

/**
 * The resize observer only reports the size of the content box of observed element. This causes problems if you have paddings or borders
 * like for example buttons do. This hook solves this issue.
 *
 * Re-render is triggered by internal mechanics of the useResizeObserver hook.
 * @param ref Ref to the observed element.
 */
export const useBoundingResizeObserver = <Element extends HTMLElement>(
  ref: RefObject<Element>,
): Size => {
  const [width, setWidth] = useState<Size['width']>();
  const [height, setHeight] = useState<Size['height']>();

  useResizeObserver({
    ref,
    onResize: () => {
      const boundingRectangle = ref.current?.getBoundingClientRect();
      if (boundingRectangle) {
        setWidth(boundingRectangle.width);
        setHeight(boundingRectangle.height);
      }
    },
  });

  return { width, height };
};
