import { createGuid } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { buildPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUrlFactory } from '../../../../../../../_shared/utils/urlFactory.ts';
import {
  EditItemRoute,
  EditItemRouteParams,
} from '../../../../../../smartLink/constants/routePaths.ts';
import { deactivateContentItemEditingAction } from '../../../actions/contentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import {
  FieldName,
  ShareItemForm as ShareItemFormComponent,
} from '../../../components/editingActions/leafActionForms/ShareItemForm.tsx';
import { selectPreviewLinks } from '../../selectors/selectPreviewLinks.ts';

export const ShareItemForm: React.FC<LeafActionFormProps> = ({ actionOrigin }) => {
  // eslint-disable-next-line react/hook-use-state
  const [linkId] = useState(() => createGuid().replace(/-/g, ''));

  const itemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);
  const previewLinks = useSelector(selectPreviewLinks);
  const itemLink = useSelector((s) => getItemLink(s, linkId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!itemId) {
      return;
    }

    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'share-dialog--opened',
        info: JSON.stringify({
          itemId: itemId?.itemId,
          variantId: itemId?.variantId,
          linkId,
        }),
      }),
    );
  }, [itemId, linkId]);

  const onCopyUsed = (fieldName: FieldName) => {
    if (!itemId) {
      return;
    }

    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemLinkCopied, {
        itemId: itemId.itemId,
        variantId: itemId.variantId,
        fieldName,
        linkId,
      }),
    );

    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action:
          fieldName === FieldName.PreviewLink
            ? ContentItemEditingEventTypes.SharePreviewLink
            : ContentItemEditingEventTypes.ShareItemLink,
        origin: actionOrigin,
      }),
    );
  };

  const onCopyCompleted = useCallback(() => dispatch(deactivateContentItemEditingAction()), []);

  return (
    <ShareItemFormComponent
      itemLink={itemLink}
      onCopyCompleted={onCopyCompleted}
      onCopyUsed={onCopyUsed}
      previewLinks={previewLinks}
    />
  );
};

ShareItemForm.displayName = 'ShareItemFormContainer';

const getItemLink = (state: IStore, linkId: string): string => {
  const {
    sharedApp: { currentProjectId },
    contentApp: { editedContentItemVariant },
  } = state;

  const itemId = editedContentItemVariant?.id;

  if (!itemId) {
    return '';
  }

  return `${getUrlFactory().getDraftUiRootUrl()}${buildPath<EditItemRouteParams>(EditItemRoute, {
    projectId: currentProjectId,
    itemGuid: itemId.itemId,
    variantGuid: itemId.variantId,
  })}?linkId=${linkId}`;
};
