import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { NumberValue } from '../../../../../../_shared/models/NumberValue.ts';
import { isNumberElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { onNumberElementValueChange } from '../../actions/thunkContentItemEditingActions.ts';
import {
  NumberElement as NumberElementComponent,
  NumberElementProps,
} from '../../components/elements/NumberElement.tsx';

type Props = Omit<NumberElementProps, 'onValueChange'>;

export const NumberElement: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId },
  } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );

  const onValueChange = useCallback(
    (value: NumberValue) => dispatch(onNumberElementValueChange(elementId, value)),
    [elementId],
  );

  const showError = useSelector(
    (state) => !!state.contentApp.itemValidationErrors.get(elementId)?.errorMessages.length,
  );

  if (!isNumberElement(elementData)) {
    return null;
  }

  return (
    <NumberElementComponent
      {...props}
      elementData={elementData}
      onValueChange={onValueChange}
      showError={showError}
    />
  );
};

NumberElement.displayName = 'NumberContainer';
