import { Direction } from '@kontent-ai/types';
import { EditorState } from 'draft-js';
import {
  DecoratedApi,
  EditorApiImplementation,
} from '../../../editorCore/types/Editor.api.type.ts';
import {
  createSelection,
  getBoundingSelection,
  isSelectionWithinOneBlock,
  preserveSelectionOverContentChanges,
} from '../../../utils/editorSelectionUtils.ts';
import { createSimpleTextValueContent } from '../../../utils/editorSimpleTextValueUtils.ts';
import { SelectionAfter } from '../../../utils/editorStateUtils.ts';
import { exportToClipboardPlainText } from '../../../utils/export/plainText/exportToPlainText.ts';
import { removeRange } from '../../../utils/general/editorContentUtils.ts';
import {
  deleteAiContent,
  getAiContentSelections,
  replaceAiContent,
  unmarkAiContent,
} from '../../ai/utils/editorAiUtils.ts';
import { EditorClipboardApi } from '../../clipboard/api/EditorClipboardApi.type.ts';
import { removeMatchingEntities } from '../../entityApi/api/editorEntityUtils.ts';
import { getEntityKeyForNewContent } from '../../textApi/api/editorTextUtils.ts';
import { InlineAiPlugin } from '../InlineAiPlugin.type.ts';
import { atomicInstructionChar } from '../constants/aiConstants.ts';
import { isFinishedAiInstruction, isNewAiInstruction } from '../utils/InstructionEntity.ts';
import {
  applyNewInstruction,
  createFinishedInstruction,
  createNewInstruction,
  getActiveInstructionEntityKey,
} from '../utils/editorInlineAiUtils.ts';

export const createEditorInlineAiApi = (
  baseApi: DecoratedApi<EditorClipboardApi, 'pasteContent'>,
): EditorApiImplementation<InlineAiPlugin> => ({
  acceptFinishedAiInstruction(api, editorState, aiSessionId) {
    const existingResultSelections = getAiContentSelections(
      editorState.getCurrentContent(),
      aiSessionId,
    );
    if (!existingResultSelections.length) {
      return editorState;
    }

    const existingResultSelection = getBoundingSelection(existingResultSelections);

    return api.executeContentChange(
      editorState,
      existingResultSelection,
      (input) =>
        unmarkAiContent(
          removeMatchingEntities(
            input,
            (entity) =>
              isFinishedAiInstruction(entity) && entity.getData().aiSessionId === aiSessionId,
            () => '',
          ),
          aiSessionId,
        ),
      'apply-entity',
    );
  },

  applyNewAiInstruction(api, editorState, data) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => applyNewInstruction(input, data),
      'apply-entity',
    );
  },

  createFinishedAiInstruction(api, editorState, selection, data) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => createFinishedInstruction(input, data),
      'apply-entity',
    );
  },

  deleteAiContent(api, editorState, aiSessionId) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => deleteAiContent(input, aiSessionId),
      'insert-fragment',
    );
  },

  editFinishedAiInstruction(api, editorState, data, source, allowUndo = true) {
    const { aiSessionId, instruction } = data;

    const existingResultSelections = getAiContentSelections(
      editorState.getCurrentContent(),
      aiSessionId,
    );
    if (!existingResultSelections.length) {
      return editorState;
    }

    const existingResultSelection = getBoundingSelection(existingResultSelections);

    const newInstructionData = {
      aiSessionId,
      instruction,
      source,
    };

    return api.executeContentChange(
      editorState,
      existingResultSelection,
      (input) =>
        createNewInstruction(
          removeRange(input, Direction.Backward, true, true),
          newInstructionData,
        ),
      'apply-entity',
      allowUndo,
    );
  },

  pasteContent(api, editorState, selection, pastedContent) {
    const content = editorState.getCurrentContent();
    const selectionStart = createSelection(selection.getStartKey(), selection.getStartOffset());
    const entityKeyForNewContent = getEntityKeyForNewContent(content, selectionStart);

    const isPastingToInstruction =
      !!entityKeyForNewContent && isNewAiInstruction(content.getEntity(entityKeyForNewContent));
    const newPastedContent = isPastingToInstruction
      ? {
          ...pastedContent,
          // When pasting to instruction, we paste complex content as plain text
          // as the instruction is an entity that must be within a single block
          // and if we inserted complex content it could break into multiple blocks, or try to include unexpected metadata (links etc.)
          content: createSimpleTextValueContent(exportToClipboardPlainText(pastedContent.content)),
        }
      : pastedContent;

    return baseApi.pasteContent(api, editorState, selection, newPastedContent);
  },

  removeAbandonedEmptyInstruction(api, editorState, previousEditorState) {
    const oldContent = previousEditorState.getCurrentContent();
    const oldActiveInstructionEntityKey = getActiveInstructionEntityKey(
      oldContent,
      previousEditorState.getSelection(),
    );
    if (!oldActiveInstructionEntityKey) {
      return editorState;
    }

    const newActiveInstructionEntityKey = getActiveInstructionEntityKey(
      editorState.getCurrentContent(),
      editorState.getSelection(),
    );
    if (oldActiveInstructionEntityKey === newActiveInstructionEntityKey) {
      return editorState;
    }

    const oldEntity = oldContent.getEntity(oldActiveInstructionEntityKey);
    if (!isNewAiInstruction(oldEntity)) {
      return editorState;
    }

    const oldInstructionSelection = api.getSelectionForEntity(
      editorState,
      oldActiveInstructionEntityKey,
    );
    if (!oldInstructionSelection || !isSelectionWithinOneBlock(oldInstructionSelection)) {
      return editorState;
    }

    const isOldInstructionEmpty =
      oldInstructionSelection.getEndOffset() - oldInstructionSelection.getStartOffset() ===
      atomicInstructionChar.length;
    if (!isOldInstructionEmpty) {
      return editorState;
    }

    const withRemovedAbandonedInstruction = api.handleDeleteAtSelection(
      editorState,
      oldInstructionSelection,
      Direction.Backward,
    ).editorState;
    const newSelection = preserveSelectionOverContentChanges(
      editorState.getSelection(),
      oldInstructionSelection,
      withRemovedAbandonedInstruction.getSelection(),
    );

    return EditorState.forceSelection(withRemovedAbandonedInstruction, newSelection);
  },

  replaceAiContent(api, editorState, aiSessionId, result, allowUndo = true) {
    const selection = editorState.getSelection();

    return api.executeContentChange(
      editorState,
      selection,
      (input) => replaceAiContent(input, aiSessionId, result),
      'insert-fragment',
      allowUndo,
      SelectionAfter.NewWithOriginalFocus,
    );
  },
});
