import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import {
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangCreator_Show,
  LocalizationEditor_LanguageBar_Move,
  LocalizationEditor_Language_Activate,
  LocalizationEditor_Language_Deactivate,
  LocalizationEditor_LanguagesLoading_Success,
} from '../../constants/localizationActionTypes.ts';

const initialState: Immutable.List<Uuid> = Immutable.List();

export const languageItemsOrder = (
  state: Immutable.List<Uuid> = initialState,
  action: Action,
): Immutable.List<Uuid> => {
  switch (action.type) {
    case LocalizationEditor_LanguagesLoading_Success: {
      const loadedProjectLanguages = action.payload.projectLanguages;
      const languageItemsIds = loadedProjectLanguages.languages.map(
        (languageItem) => languageItem.id,
      );
      return Immutable.List(languageItemsIds);
    }

    case LocalizationEditor_LangCreator_Show: {
      return state.push(action.payload.language.id);
    }

    case LocalizationEditor_LangCreator_Hide: {
      const index = state.indexOf(action.payload.languageId);
      return state.remove(index);
    }

    case LocalizationEditor_Language_Deactivate: {
      const index = state.indexOf(action.payload.id);
      return state.remove(index).push(action.payload.id); // Put to bottom.
    }

    case LocalizationEditor_Language_Activate: {
      const { id, languages } = action.payload;
      const index = state.indexOf(id);
      const stateWithoutActivatedLanguage = state.remove(index);
      const firstInactiveIndex = stateWithoutActivatedLanguage.findIndex(
        (languageId) => !!languageId && !isLanguageActive(languages.get(languageId)),
      );
      return firstInactiveIndex >= 0
        ? stateWithoutActivatedLanguage.splice(firstInactiveIndex, 0, id).toList()
        : state;
    }

    case LocalizationEditor_LanguageBar_Move: {
      const { movedItemId, targetItemId } = action.payload;

      const movedItemIndex = state.indexOf(movedItemId);
      const targetItemIndex = state.indexOf(targetItemId);

      const updatedList = state.delete(movedItemIndex).insert(targetItemIndex, movedItemId);
      return updatedList;
    }

    default:
      return state;
  }
};
