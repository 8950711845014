import { Action } from '../../../../../../@types/Action.type.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import {
  Shared_ModalOpened,
  Shared_ModalOpenedWithProperties,
} from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { Content_Editing_AssignmentSubmittingFinished } from '../../../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_Action_Activated,
  ContentItemEditing_Action_Deactivated,
  ContentItemEditing_AssignmentChange_Abandoned,
  ContentItemEditing_CommentThread_HideAllComments,
  ContentItemEditing_CommentThread_ShowAllComments,
  ContentItemEditing_CopyContentFromVariant_Started,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_DiscussionsSidebarOpened,
  ContentItemEditing_Duplicating_Started,
  ContentItemEditing_ResolvedCommentsSidebarOpened,
  ContentItemEditing_TaskEditing_Cancelled,
  ContentItemEditing_VariantTranslation_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { EditingAction } from '../../../../features/ContentItemEditing/models/EditingAction.ts';

export const activatedAction = (state = EditingAction.none, action: Action): EditingAction => {
  switch (action.type) {
    case Shared_ModalOpenedWithProperties: {
      switch (action.payload.properties.dialogType) {
        case ModalDialogType.ChangeWorkflowDialog:
        case ModalDialogType.ChangeWorkflowStepDialog:
        case ModalDialogType.DiscardNewVersionConfirmationDialog:
          return EditingAction.none;

        default:
          return state;
      }
    }

    case Shared_ModalOpened: {
      switch (action.payload.type) {
        case ModalDialogType.ChangeContentItemCollectionDialog:
        case ModalDialogType.DeleteItemConfirmationDialog:
        case ModalDialogType.DuplicateItemWithContent:
        case ModalDialogType.DuplicateItemWithoutContent:
          return EditingAction.none;

        default:
          return state;
      }
    }

    case ContentItemEditing_Action_Deactivated:
    case ContentItemEditing_AssignmentChange_Abandoned:
    case ContentItemEditing_CommentThread_HideAllComments:
    case ContentItemEditing_CommentThread_ShowAllComments:
    case ContentItemEditing_CopyContentFromVariant_Started:
    case ContentItemEditing_DiscussionsSidebarOpened:
    case ContentItemEditing_Duplicating_Started:
    case ContentItemEditing_ResolvedCommentsSidebarOpened:
    case Content_Editing_AssignmentSubmittingFinished:
    case ContentItemEditing_TaskEditing_Cancelled:
    case ContentItemEditing_CreateTask_Finished:
    case ContentItemEditing_VariantTranslation_Started:
      return EditingAction.none;

    case ContentItemEditing_Action_Activated:
      return action.payload.activatedAction;

    default:
      return state;
  }
};
