export const Taxonomy_App_Initialized = 'Taxonomy_App_Initialized';
export const Taxonomy_Group_NameChanged = 'Taxonomy_Group_NameChanged';
export const Taxonomy_Group_CodenameChanged = 'Taxonomy_Group_CodenameChanged';
export const Taxonomy_Group_CreationFailed = 'Taxonomy_Group_CreationFailed';
export const Taxonomy_Group_CreationFinished = 'Taxonomy_Group_CreationFinished';
export const Taxonomy_Group_CreationStarted = 'Taxonomy_Group_CreationStarted';
export const Taxonomy_Group_SavingFailed = 'Taxonomy_Group_SavingFailed';
export const Taxonomy_Group_SavingFinished = 'Taxonomy_Group_SavingFinished';
export const Taxonomy_Group_SavingStarted = 'Taxonomy_Group_SavingStarted';
export const Taxonomy_Group_UsageOfEditedInPublishedItems =
  'Taxonomy_Group_UsageOfEditedInPublishedItems';
export const Taxonomy_GroupEdit_Cleared = 'Taxonomy_GroupEdit_Cleared';
export const Taxonomy_GroupCreate_Initialized = 'Taxonomy_GroupCreate_Initialized';
export const Taxonomy_GroupEdit_Initialized = 'Taxonomy_GroupEdit_Initialized';
export const Taxonomy_Groups_Archiving = 'Taxonomy_Groups_Archiving';
export const Taxonomy_Groups_Archived = 'Taxonomy_Groups_Archived';
export const Taxonomy_Groups_Loaded = 'Taxonomy_Groups_Loaded';
export const Taxonomy_Groups_LoadingStarted = 'Taxonomy_Groups_LoadingStarted';
export const Taxonomy_Groups_RestorationInfoFaded = 'Taxonomy_Groups_RestorationInfoFaded';
export const Taxonomy_Groups_Restored = 'Taxonomy_Groups_Restored';
export const Taxonomy_Groups_Restoring = 'Taxonomy_Groups_Restoring';
export const Taxonomy_Groups_SelectionChanged = 'Taxonomy_Groups_SelectionChanged';
export const Taxonomy_Terms_HideCreateNewForm = 'Taxonomy_Terms_HideCreateNewForm';
export const Taxonomy_Terms_ShowCreateNewForm = 'Taxonomy_Terms_ShowCreateNewForm';
export const Taxonomy_Terms_TermCreated = 'Taxonomy_Terms_TermCreated';
export const Taxonomy_Terms_TermRemoved = 'Taxonomy_Terms_TermRemoved';
export const Taxonomy_Terms_TermDropped = 'Taxonomy_Terms_TermDropped';
export const Taxonomy_Terms_TermEditingAbandoned = 'Taxonomy_Terms_TermEditingAbandoned';
export const Taxonomy_Terms_TermEditingInitiated = 'Taxonomy_Terms_TermEditingInitiated';
export const Taxonomy_Terms_TermMoved = 'Taxonomy_Terms_TermMoved';
export const Taxonomy_Terms_TermNameChanged = 'Taxonomy_Terms_TermNameChanged';
export const Taxonomy_Terms_TermCodenameChanged = 'Taxonomy_Terms_TermCodenameChanged';
export const Taxonomy_Terms_TermPickedUp = 'Taxonomy_Terms_TermPickedUp';
