import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { Subscription_Transactions_Loaded } from '../../constants/actionTypes.ts';
import {
  ISubscriptionTransaction,
  getSubscriptionTransactionFromServerModel,
} from '../../models/SubscriptionTransaction.ts';

interface ILoadSubscriptionTransactionsDependencies {
  readonly subscriptionRepository: Pick<ISubscriptionRepository, 'getSubscriptionTransactions'>;
}

export const subscriptionTransactionsLoaded = (
  subscriptionTransactions: ReadonlyArray<ISubscriptionTransaction>,
) =>
  ({
    type: Subscription_Transactions_Loaded,
    payload: {
      subscriptionTransactions,
    },
  }) as const;

export type LoadSubscriptionTransactionsActionsType = ReturnType<
  typeof subscriptionTransactionsLoaded
>;

export const loadSubscriptionTransactionsCreator =
  (dependencies: ILoadSubscriptionTransactionsDependencies) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const subscriptionTransactionServerModels =
      await dependencies.subscriptionRepository.getSubscriptionTransactions(
        subscriptionId,
        abortSignal,
      );
    const transactions = subscriptionTransactionServerModels.map((transaction) =>
      getSubscriptionTransactionFromServerModel(transaction),
    );
    dispatch(subscriptionTransactionsLoaded(transactions));
  };
