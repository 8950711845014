import { Direction } from '@kontent-ai/types';
import { assert } from '@kontent-ai/utils';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import Immutable from 'immutable';
import { splitToGroups } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { isNumeric } from '../../../../_shared/utils/validation/isNumeric.ts';
import {
  ICoordinates,
  getCoordinatesFromDepth,
  getDepthFromCoordinates,
} from '../../plugins/tables/api/depthCoordinatesConversion.ts';
import { ITableCell, createEmptyCell } from '../../plugins/tables/api/tableGenerator.ts';
import { updateWithUndoFlag } from '../../plugins/undoRedo/api/editorUndoUtils.ts';
import {
  AdjacentBlockType,
  BaseBlockType,
  isBlockTypeWithSleeves,
  parseBlockType,
} from '../blocks/blockType.ts';
import {
  findSiblingBlock,
  getNestingLevel,
  getParentBlockTypes,
  isEmptyParagraph,
  isInTable,
  isObjectBlock,
  isTableCell,
  isTableCellContent,
} from '../blocks/blockTypeUtils.ts';
import {
  getBaseBlockType,
  getFullBlockType,
  getNextBlockType,
  getPreviousBlockType,
} from '../blocks/editorBlockGetters.ts';
import {
  createContent,
  getUnstyledBlock,
  setBlockText,
  updateBlockDepth,
} from '../blocks/editorBlockUtils.ts';
import {
  IBlockHierarchyNode,
  flattenBlockHierarchy,
  getBlockHierarchy,
} from '../blocks/editorHierarchyUtils.ts';
import { createSelection, setContentSelection } from '../editorSelectionUtils.ts';
import { getBlocks } from '../general/editorContentGetters.ts';
import { IContentChangeInput, IContentChangeResult } from '../general/editorContentUtils.ts';

function replaceEditorContent(editorState: EditorState, newContent: ContentState): EditorState {
  const newEditorState = updateWithUndoFlag(
    editorState,
    (withUndoFlag) => {
      // Update current editor selection with before selection from new content to avoid overwriting it by EditorState.push
      const selectionBefore = newContent.getSelectionBefore();
      const stateWithUpdatedSelection =
        withUndoFlag.getSelection() !== selectionBefore
          ? EditorState.acceptSelection(withUndoFlag, selectionBefore)
          : withUndoFlag;

      const withPushedContent = EditorState.push(
        stateWithUpdatedSelection,
        newContent,
        editorState.getLastChangeType(),
      );
      // EditorState.push may force the selection, but we don't want forced selection unless it was already forced in the input
      const withoutForcedSelection = editorState.mustForceSelection()
        ? withPushedContent
        : removeForcedSelection(withPushedContent);

      return withoutForcedSelection;
    },
    false,
  );

  return newEditorState;
}

export function decorateBlocksWithAdjacentBlockTypes(content: ContentState): ContentState {
  const blocks: ReadonlyArray<ContentBlock> = getBlocks(content);
  let modifiedContent: ContentState = content;

  for (let i = 0; i < blocks.length; i++) {
    const previous = findSiblingBlock(i, blocks, Direction.Backward);
    const currentBlock = blocks[i];
    const next = findSiblingBlock(i, blocks, Direction.Forward);

    const previousBlockType = previous.block
      ? getBaseBlockType(previous.block)
      : AdjacentBlockType.None;
    const nextBlockType = next.block ? getBaseBlockType(next.block) : AdjacentBlockType.None;

    if (
      currentBlock &&
      (getPreviousBlockType(currentBlock) !== previousBlockType ||
        getNextBlockType(currentBlock) !== nextBlockType)
    ) {
      const currentBlockSelection = createSelection(currentBlock.getKey());
      modifiedContent = Modifier.mergeBlockData(
        modifiedContent,
        currentBlockSelection,
        Immutable.Map({
          previousBlockType,
          nextBlockType,
        }),
      );
    }
  }

  return modifiedContent;
}

function isEdgeWithCustomBlockSleeve(
  item: IBlockHierarchyNode,
  previousItem: IBlockHierarchyNode,
): boolean {
  return (
    isTableEdge(item.block, previousItem.block) ||
    isObjectBlock(item.block) ||
    isObjectBlock(previousItem.block)
  );
}

function ensureCustomBlockSleeves(
  nodes: ReadonlyArray<IBlockHierarchyNode>,
): ReadonlyArray<IBlockHierarchyNode> {
  const blockGroups = splitToGroups(nodes, isEdgeWithCustomBlockSleeve);
  const newBlocks: Array<IBlockHierarchyNode> = [];

  let changed = false;

  blockGroups.forEach((itemGroup, index) => {
    const secondLastBlock = newBlocks[newBlocks.length - 2];
    const lastBlock = newBlocks[newBlocks.length - 1];

    // Ensure block before if needed
    const firstBlock = itemGroup[0]?.block;
    assert(
      firstBlock,
      () =>
        `${__filename}.ensureCustomBlockSleeves: The first item of group index "${index}" is not a content block. Value: ${JSON.stringify(
          firstBlock,
        )}`,
    );
    const requiresBlockBefore = isBlockTypeWithSleeves(getBaseBlockType(firstBlock));

    const hasRequiredBlockBefore =
      lastBlock &&
      isEmptyParagraph(lastBlock.block) &&
      (!secondLastBlock || !isBlockTypeWithSleeves(getBaseBlockType(secondLastBlock.block)));

    const createBlockBefore = requiresBlockBefore && !hasRequiredBlockBefore;
    if (createBlockBefore) {
      const parentBlockTypes = getParentBlockTypes(firstBlock);
      const newRequiredBlock = getUnstyledBlock(parentBlockTypes);
      newBlocks.push({
        block: newRequiredBlock,
        childNodes: [],
      });
      changed = true;
    }

    const newNodeGroup = itemGroup.map((node) => {
      const newChildNodes = ensureCustomBlockSleeves(node.childNodes);
      if (newChildNodes !== node.childNodes) {
        changed = true;
        return {
          block: node.block,
          childNodes: newChildNodes,
        };
      }
      return node;
    });
    newBlocks.push(...newNodeGroup);

    // Ensure block after if needed
    const requiresBlockAfter = isBlockTypeWithSleeves(getBaseBlockType(firstBlock));
    const nextGroupFirstBlock = blockGroups[index + 1]?.[0]?.block ?? null;
    const createBlockAfter =
      requiresBlockAfter && (!nextGroupFirstBlock || !isEmptyParagraph(nextGroupFirstBlock));

    if (createBlockAfter) {
      const parentBlockTypes = getParentBlockTypes(firstBlock);
      const newRequiredBlock = getUnstyledBlock(parentBlockTypes);
      newBlocks.push({
        block: newRequiredBlock,
        childNodes: [],
      });
      changed = true;
    }
  });

  if (!changed) {
    return nodes;
  }
  return newBlocks;
}

export function ensureCustomBlockSleevesInContent(content: ContentState): ContentState {
  const blocks = getBlocks(content);
  const hierarchy = getBlockHierarchy(blocks);
  const newHierarchy = ensureCustomBlockSleeves(hierarchy);

  if (newHierarchy !== hierarchy) {
    const newBlocks = flattenBlockHierarchy(newHierarchy);
    const newContent = createContent(newBlocks);

    return setContentSelection(
      newContent,
      content.getSelectionBefore(),
      content.getSelectionAfter(),
    );
  }

  return content;
}

function getCorrectedSelection(
  oldSelection: SelectionState,
  startKey: string,
  startOffset: number,
  endKey: string,
  endOffset: number,
): SelectionState {
  const isBackward = oldSelection.getIsBackward();
  const hasFocus = oldSelection.getHasFocus();

  const newSelection = createSelection(
    isBackward ? endKey : startKey,
    isBackward ? endOffset : startOffset,
    isBackward ? startKey : endKey,
    isBackward ? startOffset : endOffset,
    isBackward ? Direction.Backward : Direction.Forward,
    hasFocus,
  );

  return newSelection;
}

interface ISelectionPoint {
  key: string;
  offset: number;
}

function getCorrectedSelectionPoint(
  content: ContentState,
  key: string,
  offset: number,
  onBlockMissing: () => ISelectionPoint,
  direction: Direction | null,
): ISelectionPoint | null {
  const block = content.getBlockForKey(key);
  if (!block) {
    return onBlockMissing();
  }

  // If selection is inside the block element (image, linked items) or table cell, correct it to the closest sibling in the current direction
  // This may happen in FF where the caret attempts to jump inside linked items making the selection inconsistent
  // or when selection ends in a table cell instead of in its content block
  const isInvalidSelectionBlock = isObjectBlock(block) || isTableCell(block);
  if (isInvalidSelectionBlock) {
    // If direction is not known, we estimate from where the selection was made
    // if it is at the beginning, caret arrived from left side, otherwise from right side
    const movingForward = direction ? direction === Direction.Forward : offset === 0;
    const correctionBlock = movingForward
      ? content.getBlockAfter(key)
      : content.getBlockBefore(key);

    if (correctionBlock) {
      return {
        key: correctionBlock.getKey(),
        offset: movingForward ? 0 : correctionBlock.getLength(),
      };
    }

    // When not successful, just place the selection at the edge of the editor
    const edgeBlock = movingForward ? content.getLastBlock() : content.getFirstBlock();
    return {
      key: edgeBlock.getKey(),
      offset: movingForward ? edgeBlock.getLength() : 0,
    };
  }

  // If offset is over the block, make a correction to the end of the block
  const offsetIsOver = block.getLength() < offset;
  if (offsetIsOver) {
    return {
      key,
      offset: block.getLength(),
    };
  }

  return null;
}

export function getValidSelection(
  content: ContentState,
  selection: SelectionState,
  selectionDirection: Direction | null,
): SelectionState {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();

  const startBlock = content.getBlockForKey(startKey);
  const endBlock = content.getBlockForKey(endKey);

  const correctedStart = getCorrectedSelectionPoint(
    content,
    startKey,
    startOffset,
    // If start block is missing, use beginning of end block as the new start if available
    // If end block also not available, reset to beginning of the editor, there is not much more we could do
    () =>
      endBlock
        ? {
            key: endBlock.getKey(),
            offset: 0,
          }
        : {
            key: content.getFirstBlock().getKey(),
            offset: 0,
          },
    selectionDirection,
  );
  const correctedEnd = getCorrectedSelectionPoint(
    content,
    endKey,
    endOffset,
    // In case the end block is missing, use end of the start block as the new end if available
    // If start block also not available, reset to beginning of the editor, there is not much more we could do
    () =>
      startBlock
        ? {
            key: startBlock.getKey(),
            offset: startBlock.getLength(),
          }
        : {
            key: content.getFirstBlock().getKey(),
            offset: 0,
          },
    selectionDirection,
  );

  if (correctedStart || correctedEnd) {
    const newSelection = getCorrectedSelection(
      selection,
      correctedStart ? correctedStart.key : startKey,
      correctedStart ? correctedStart.offset : startOffset,
      correctedEnd ? correctedEnd.key : endKey,
      correctedEnd ? correctedEnd.offset : endOffset,
    );
    return newSelection;
  }

  return selection;
}

export function ensureValidSelection(
  editorState: EditorState,
  selectionDirection: Direction | null,
): EditorState {
  const selection = editorState.getSelection();
  const newSelection = getValidSelection(
    editorState.getCurrentContent(),
    selection,
    selectionDirection,
  );
  if (newSelection === selection) {
    return editorState;
  }

  if (selection.getHasFocus()) {
    return EditorState.forceSelection(editorState, newSelection);
  }
  return EditorState.acceptSelection(editorState, newSelection);
}

function ensureConsistentCell(cell: ITableCell): ITableCell {
  const isCellConsistent = cell.contentBlocks.length > 0 && cell.cellBlock.getText().length === 0;
  if (isCellConsistent) {
    return cell;
  }

  // Make sure cell doesn't directly contain text, move it to an unstyled block if necessary
  const newCellBlock = setBlockText(cell.cellBlock, {
    text: '',
    characterList: Immutable.List<CharacterMetadata>(),
  });
  const parentBlockTypes = parseBlockType(getFullBlockType(cell.cellBlock));
  const newContentBlock = setBlockText(getUnstyledBlock(parentBlockTypes), {
    text: cell.cellBlock.getText(),
    characterList: cell.cellBlock.getCharacterList(),
  });

  return {
    cellBlock: newCellBlock,
    contentBlocks: [newContentBlock, ...cell.contentBlocks],
  };
}

type CreateMissingCell = (
  parentBlocksTypes: ReadonlyArray<BaseBlockType>,
  coords: ICoordinates,
) => ITableCell;

function ensureCellBlockAtTableStart(
  blocks: ReadonlyArray<ContentBlock>,
  createMissingCell: CreateMissingCell,
): ReadonlyArray<ContentBlock> {
  const firstBlock = blocks[0];
  if (firstBlock && isTableCellContent(firstBlock)) {
    const parentBlockTypes = getParentBlockTypes(firstBlock);
    const cellParentBlockTypes = parentBlockTypes.slice(0, -1);

    return [createMissingCell(cellParentBlockTypes, { x: 0, y: 0 }).cellBlock, ...blocks];
  }

  return blocks;
}

function buildConsistentTable(
  blocks: ReadonlyArray<ContentBlock>,
  createMissingCell: CreateMissingCell,
  mergeDuplicateCells: (existing: ITableCell, duplicate: ITableCell) => ITableCell,
): ReadonlyArray<ContentBlock> {
  const withProperTableStart = ensureCellBlockAtTableStart(blocks, createMissingCell);

  const firstCellBlock = withProperTableStart.find(isTableCell);
  if (!firstCellBlock) {
    return withProperTableStart;
  }

  const tableNestingLevel = getNestingLevel(firstCellBlock);
  const cellGroups = splitToGroups(
    withProperTableStart,
    (block) => isTableCell(block) && getNestingLevel(block) === tableNestingLevel,
  );

  // Collect table cells
  let changed = withProperTableStart !== blocks;
  let maxX = 0;
  let maxY = 0;

  const tableCells: Record<number, ITableCell> = {};
  const existingCols = new Set<number>();
  const existingRows = new Set<number>();

  for (let i = 0; i < cellGroups.length; i++) {
    const cellGroup = cellGroups[i];
    const cellBlock = cellGroup?.find(isTableCell);
    if (!cellGroup || !cellBlock) {
      continue;
    }

    const contentBlocks = cellGroup.filter((block: ContentBlock) => !isTableCell(block));
    const cell: ITableCell = {
      cellBlock,
      contentBlocks,
    };

    const depth = cell.cellBlock.getDepth();
    const coords = getCoordinatesFromDepth(depth);
    if (coords.x > maxX) {
      maxX = coords.x;
    }
    if (coords.y > maxY) {
      maxY = coords.y;
    }

    const consistentCell = ensureConsistentCell(cell);
    if (consistentCell !== cell) {
      changed = true;
    }

    existingCols.add(coords.x);
    existingRows.add(coords.y);

    const existing = tableCells[depth];
    if (existing) {
      changed = true;
      tableCells[depth] = mergeDuplicateCells(existing, consistentCell);
    } else {
      tableCells[depth] = consistentCell;
    }
  }

  // Build resulting table
  const newBlocks: ContentBlock[] = [];

  const rows = maxY + 1;
  const cols = maxX + 1;

  // Prepare offsets for individual rows / cols based on missing ones
  let cumulativeColOffset = 0;
  const colOffsets = [...Array(cols).keys()].map((x) =>
    existingCols.has(x) ? cumulativeColOffset : cumulativeColOffset++,
  );

  let cumulativeRowOffset = 0;
  const rowOffsets = [...Array(rows).keys()].map((y) =>
    existingRows.has(y) ? cumulativeRowOffset : cumulativeRowOffset++,
  );

  const parentBlockTypes = getParentBlockTypes(firstCellBlock);

  const rowOffsetNotANumberMessage = (index: number) => () =>
    `${__filename}: Row offset at index "${index}" is undefined.`;
  const columnOffsetNotANumberMessage = (index: number) => () =>
    `${__filename}: Column offset at index "${index}" is undefined.`;

  for (let y = 0; y < rows; y++) {
    // Skip whole non-existing rows
    if (!existingRows.has(y)) {
      changed = true;
      continue;
    }
    const rowOffset = rowOffsets[y];
    assert(isNumeric(rowOffset), rowOffsetNotANumberMessage(y));

    for (let x = 0; x < cols; x++) {
      // Skip whole non-existing columns
      if (!existingCols.has(x)) {
        changed = true;
        continue;
      }
      const colOffset = colOffsets[x];
      assert(isNumeric(colOffset), columnOffsetNotANumberMessage(x));

      const depth = getDepthFromCoordinates({
        x,
        y,
      });
      const cell = tableCells[depth];
      if (cell) {
        const updatedCellBlock =
          rowOffset || colOffset
            ? updateBlockDepth(cell.cellBlock, () =>
                getDepthFromCoordinates({
                  x: x - colOffset,
                  y: y - rowOffset,
                }),
              )
            : cell.cellBlock;

        newBlocks.push(updatedCellBlock);
        newBlocks.push(...cell.contentBlocks);
      } else {
        const missingCell = createMissingCell(parentBlockTypes, {
          x: x - colOffset,
          y: y - rowOffset,
        });
        newBlocks.push(missingCell.cellBlock);
        newBlocks.push(...missingCell.contentBlocks);
        changed = true;
      }
    }
  }

  if (changed) {
    return newBlocks;
  }
  return withProperTableStart;
}

function isTableEdge(block: ContentBlock, previousBlock: ContentBlock): boolean {
  const blockIsTable = isInTable(block);
  const previousIsTable = isInTable(previousBlock);

  return (blockIsTable && !previousIsTable) || (previousIsTable && !blockIsTable);
}

export function ensureConsistentTables(
  blocks: ReadonlyArray<ContentBlock>,
  createMissingCell: (
    parentBlocksTypes: ReadonlyArray<BaseBlockType>,
    coords: ICoordinates,
  ) => ITableCell,
  mergeDuplicateCells: (existing: ITableCell, duplicate: ITableCell) => ITableCell,
): ReadonlyArray<ContentBlock> {
  const blockGroups = splitToGroups(blocks, isTableEdge);
  const newBlocks: ContentBlock[] = [];
  let changed = false;

  blockGroups.forEach((group) => {
    const firstBlockInGroup = group[0];
    if (isInTable(firstBlockInGroup)) {
      const newTableBlocks = buildConsistentTable(group, createMissingCell, mergeDuplicateCells);
      if (newTableBlocks !== group) {
        newBlocks.push(...newTableBlocks);
        changed = true;
      } else {
        newBlocks.push(...group);
      }
    } else {
      newBlocks.push(...group);
    }
  });

  return changed ? newBlocks : blocks;
}

export function ensureTablesConsistency(
  content: ContentState,
  createMissingCell: (
    parentBlocksTypes: ReadonlyArray<BaseBlockType>,
    coords: ICoordinates,
  ) => ITableCell,
  mergeDuplicateCells: (existing: ITableCell, duplicate: ITableCell) => ITableCell,
): ContentState {
  const blocks = getBlocks(content);
  const newBlocks = ensureConsistentTables(blocks, createMissingCell, mergeDuplicateCells);
  if (newBlocks === blocks) {
    return content;
  }

  const newContent = createContent(newBlocks);
  return setContentSelection(newContent, content.getSelectionBefore(), content.getSelectionAfter());
}

export function ensureContentConsistency(content: ContentState): ContentState {
  const withConsistentTables = ensureTablesConsistency(
    content,
    createEmptyCell,
    // When duplicate cells are detected, only newer (latest) cell is kept
    // Alternatively it could merge the cells content
    (_existing, duplicate) => duplicate,
  );
  const withCustomBlockSleeves = ensureCustomBlockSleevesInContent(withConsistentTables);
  const contentWithAllEnsured = decorateBlocksWithAdjacentBlockTypes(withCustomBlockSleeves);

  if (contentWithAllEnsured !== content) {
    return setContentSelection(
      contentWithAllEnsured,
      content.getSelectionBefore(),
      content.getSelectionAfter(),
    );
  }

  return content;
}

export function ensureConsistency(
  input: IContentChangeInput,
  selectionDirection: Direction | null,
): IContentChangeResult {
  const { content, selection } = input;

  const newContent = ensureContentConsistency(content);
  const newSelection = getValidSelection(newContent, selection, selectionDirection);

  if (newContent === content && newSelection === selection) {
    return input;
  }

  const selectionAfterHasChanged =
    newContent.getSelectionAfter() === selection && selection !== newSelection;
  const newContentWithSelection = selectionAfterHasChanged
    ? setContentSelection(newContent, newContent.getSelectionBefore(), newSelection)
    : newContent;

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
}

export function ensureEditorStateConsistency(
  editorState: EditorState,
  selectionDirection: Direction | null,
): EditorState {
  const input = {
    content: editorState.getCurrentContent(),
    selection: editorState.getSelection(),
  };
  const consistent = ensureConsistency(input, selectionDirection);
  if (consistent === input) {
    return editorState;
  }

  const withUpdatedContent = replaceEditorContent(editorState, consistent.content);
  if (consistent.selection === withUpdatedContent.getSelection()) {
    return withUpdatedContent;
  }

  const hadFocus = editorState.getSelection().getHasFocus();
  const withUpdatedSelection = hadFocus
    ? EditorState.forceSelection(withUpdatedContent, consistent.selection)
    : EditorState.acceptSelection(withUpdatedContent, consistent.selection);

  return withUpdatedSelection;
}

export function removeForcedSelection(editorState: EditorState): EditorState {
  if (editorState.mustForceSelection()) {
    return EditorState.acceptSelection(editorState, editorState.getSelection());
  }
  return editorState;
}
