import { BaseColor, borderWidthDefault, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import { ArrowSize } from '../ArrowSizeEnum.ts';

interface IStyledArrowWrapperProps {
  readonly arrowSize?: ArrowSize;
}

const StyledArrowWrapper = styled.div<IStyledArrowWrapperProps>`
  ${({ arrowSize = ArrowSize.M }) => css`
    width: ${px(arrowSize)};
    height: ${px(arrowSize)};
    
    > svg {
      width: 100%;
      height: 100%;
    }
    
    [data-placement^="top"] && {
      bottom: ${px(-0.5 * arrowSize + borderWidthDefault)};
      > svg {
        transform: rotate(180deg);
      }
    }
    
    [data-placement^="right"] && {
      left: ${px(-0.5 * arrowSize + borderWidthDefault)};
      > svg { 
        transform: rotate(-90deg);
      }
    }
    
    [data-placement^="bottom"] && { 
      top: ${px(-0.5 * arrowSize + borderWidthDefault)};
    }
    
    [data-placement^="left"] && {
      right: ${px(-0.5 * arrowSize + borderWidthDefault)};
      
      > svg {
        transform: rotate(90deg);
      }
    }
  `};
`;

interface IArrowProps extends IStyledArrowWrapperProps, React.HTMLAttributes<HTMLDivElement> {
  readonly color?: BaseColor;
}

export const Arrow: React.FC<React.PropsWithChildren<IArrowProps>> = ({
  color = BaseColor.White,
  ...props
}) => (
  <StyledArrowWrapper {...props}>
    <svg viewBox="0 0 32 32" fill={color}>
      <path d="M15.293.707a1 1 0 011.414 0L32 16H0L15.293.707z" />
    </svg>
  </StyledArrowWrapper>
);
