import { UnreachableCaseException } from '@kontent-ai/errors';
import styled, { css } from 'styled-components';
import {
  colorAlertText,
  colorTextDefaultInverse,
  colorTextHint,
  colorTextLowEmphasis,
} from '../../../tokens/decision/colors.ts';
import { Color } from '../../../tokens/quarks/colors.ts';
import { MenuItemState } from '../menuItemState.ts';
import { getActiveColor, getHoverColor } from '../utils.ts';
import { StyledMenuItem } from './StyledMenuItem.tsx';

const getComplementaryColor = (state: MenuItemState): Color | 'inherit' => {
  switch (state) {
    case 'default':
    case 'destructive':
      return colorTextLowEmphasis;
    case 'readonly':
      return colorTextHint;
    case 'disabled':
    case 'selected':
      return 'inherit';
    case 'error':
      return colorAlertText;
    case 'error-selected':
      return colorTextDefaultInverse;
    default:
      throw UnreachableCaseException(state);
  }
};

interface IStyledComplementaryTextProps {
  readonly $state: MenuItemState;
  readonly $isHovered?: boolean;
  readonly $isExpanded?: boolean;
}
export const StyledComplementaryText = styled.div<IStyledComplementaryTextProps>`
  color: ${(props) => getComplementaryColor(props.$state)};

  ${StyledMenuItem}:active && {
    color: ${(props) => getActiveColor(props.$state)};
  }
  
  ${StyledMenuItem}:not(:active) && {
    ${({ $isHovered, $isExpanded, $state }) =>
      $state !== 'default' &&
      ($isHovered || $isExpanded) &&
      css`
      color: ${getHoverColor($state)};
    `};
  }
`;
