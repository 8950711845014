import React from 'react';
import { useHistory, useParams } from 'react-router';
import { AssetListingRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { navigatingToFolder } from '../../actions/assetLibraryActions.ts';
import { AssetFolder as AssetFolderComponent } from '../../components/AssetFolders/AssetFolder.tsx';
import { redirectToAssetFolder } from '../../utils/assetsPathUtils.ts';

type Props = {
  readonly assetFolder: ITaxonomyTerm;
  readonly clearAssetSelection: () => void;
  readonly onRename?: () => void;
};

export const AssetFolder: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<AssetListingRouteParams>();

  return (
    <AssetFolderComponent
      {...props}
      onOpen={() => {
        props.clearAssetSelection();
        dispatch(navigatingToFolder());
        redirectToAssetFolder({
          assetFolderId: props.assetFolder.id,
          projectId,
          history,
        });
      }}
    />
  );
};

AssetFolder.displayName = 'AssetFolder';
