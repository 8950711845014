import { Button } from '@kontent-ai/component-library/Button';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { EnsuredSubscriptionRouteParams, PlanSelectionRoute } from '../../constants/routePaths.ts';
import { AvailableSubscriptionAdmins } from '../../containers/infos/AvailableSubscriptionAdmins.tsx';
import { LinkLike } from '../../uiComponents/LinkLike/LinkLike.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { Warning } from './Warning.tsx';

type PlanLimitationWarningProps = {
  readonly description?: string;
  readonly editedSubscriptionId: Uuid;
  readonly introMessage: string;
  readonly showSimplified?: boolean;
  readonly title?: string;
  readonly userCanChangePlan: boolean;
};

export const PlanLimitationWarning: FC<PlanLimitationWarningProps> = ({
  description,
  editedSubscriptionId,
  introMessage,
  showSimplified,
  title,
  userCanChangePlan,
}) => {
  const [showAdminsDialog, setShowAdminsDialog] = useState(false);

  if (showSimplified) {
    return (
      <>
        <Warning hideTitle>
          {introMessage}
          <br />
          {userCanChangePlan ? (
            <Link
              to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
                subscriptionId: editedSubscriptionId,
              })}
              {...getDataUiActionAttribute(DataUiAction.EditSubscription)}
            >
              Upgrade your plan
            </Link>
          ) : (
            <LinkLike onClick={() => setShowAdminsDialog(true)}>
              Contact your subscription admin
            </LinkLike>
          )}
          .
        </Warning>
        {showAdminsDialog && (
          <AvailableSubscriptionAdmins onClose={() => setShowAdminsDialog(false)} />
        )}
      </>
    );
  }

  return (
    <>
      <Warning subtitle={title}>
        <div>
          {description && <p>{description}</p>}
          {userCanChangePlan ? (
            <p>{introMessage}, upgrade your subscription plan.</p>
          ) : (
            <p>
              {introMessage},{' '}
              <LinkLike onClick={() => setShowAdminsDialog(true)}>
                ask your subscription admin
              </LinkLike>{' '}
              to upgrade your subscription plan.
            </p>
          )}
          {userCanChangePlan && (
            <Link
              className="u-no-text-decoration"
              to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
                subscriptionId: editedSubscriptionId,
              })}
            >
              <Button
                buttonStyle="primary"
                {...getDataUiActionAttribute(DataUiAction.EditSubscription)}
              >
                Select plan
              </Button>
            </Link>
          )}
        </div>
      </Warning>
      {showAdminsDialog && (
        <AvailableSubscriptionAdmins onClose={() => setShowAdminsDialog(false)} />
      )}
    </>
  );
};

PlanLimitationWarning.displayName = 'PlanLimitationWarning';
