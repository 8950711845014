import { useHover } from '@react-aria/interactions';
import React, { useCallback } from 'react';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { StyledToggle, StyledToggleProps } from './components/StyledToggle.tsx';
import { StyledToggleIcon } from './components/StyledToggleIcon.tsx';
import { StyledToggleInput } from './components/StyledToggleInput.tsx';
import { StyledToggleLabel } from './components/StyledToggleLabel.tsx';
import { StyledToggleText } from './components/StyledToggleText.tsx';
import { ToggleStatus } from './types.ts';

export type ToggleProps = Omit<StyledToggleProps, 'isFocused'> &
  Pick<TooltipPropsExtension, 'tooltipText' | 'tooltipPlacement'> &
  Readonly<{
    labelText: string;
    tabIndex?: number;
    onChange?: (newStatus: ToggleStatus, event: React.ChangeEvent<HTMLInputElement>) => void;
    onToggleOn?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onToggleOff?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }>;

export const Toggle = React.forwardRef<HTMLLabelElement, ToggleProps>(
  (
    {
      status,
      onChange,
      onToggleOn,
      onToggleOff,
      disabled,
      tabIndex,
      labelText,
      tooltipText,
      tooltipPlacement = 'top',
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();
    const { isHovered, hoverProps } = useHover({});

    const onChangeHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newStatus = status === 'on' ? 'off' : 'on';
        if (onChange && !disabled) {
          onChange(newStatus, event);
        }
        if (onToggleOn && !disabled && newStatus === 'on') {
          onToggleOn(event);
        }
        if (onToggleOff && !disabled && newStatus === 'off') {
          onToggleOff(event);
        }
      },
      [disabled, onChange, onToggleOn, onToggleOff, status],
    );

    return (
      <Tooltip
        tooltipText={tooltipText}
        placement={tooltipPlacement}
        visible={isFocusVisible || isHovered}
      >
        <StyledToggleLabel
          tabIndex={tabIndex}
          $disabled={disabled}
          ref={forwardedRef}
          {...getDataUiComponentAttribute(Toggle)}
          {...hoverProps}
          {...otherProps}
        >
          <StyledToggleInput
            onChange={onChangeHandler}
            checked={status === 'on'}
            disabled={disabled}
            {...focusProps}
          />
          <StyledToggle status={status} disabled={disabled} isFocused={isFocusVisible} />
          <StyledToggleText>{labelText}</StyledToggleText>
          {status === 'on' && <StyledToggleIcon />}
        </StyledToggleLabel>
      </Tooltip>
    );
  },
);

Toggle.displayName = 'Toggle';
