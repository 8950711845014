import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKeyDetail_Revoke_Failed,
  ApiKeyDetail_Revoke_Finished,
  ApiKeyDetail_Revoke_Started,
} from '../../constants/apiKeysActionTypes.ts';

const started = () =>
  ({
    type: ApiKeyDetail_Revoke_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_Revoke_Failed,
  }) as const;

const success = () =>
  ({
    type: ApiKeyDetail_Revoke_Finished,
  }) as const;

interface IRevokeApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'revokeApiKey'>;
}

export type RevokeApiKeyActionsType = ReturnType<typeof started | typeof failed | typeof success>;

export const revokeApiKeyCreator =
  ({ apiKeysRepository }: IRevokeApiKeyDependencies) =>
  (tokenSeedId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(started());
      await apiKeysRepository.revokeApiKey(tokenSeedId);
      dispatch(success());
    } catch (error) {
      dispatch(failed());
      throw InvariantException(
        `Could not revoke API key because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
