import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { BarItemAnimation } from '../../../_shared/components/BarItems/BarItemAnimation.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { invalidContentType } from '../constants/errorMessageTemplates.ts';
import { DisabledFilterTooltipText } from '../constants/previewConfigurationUiConstants.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import {
  ContentTypeId,
  IPreviewUrlPatternError,
  SpaceRowId,
} from '../stores/IPreviewConfigurationAppStoreState.type.ts';
import { CollapsedPreviewUrlBar } from './CollapsedPreviewUrlBar.tsx';
import { ExpandedPreviewUrlBar } from './ExpandedPreviewUrlBar.tsx';
import { PreviewConfigurationEmptyState } from './PreviewConfigurationEmptyState.tsx';
import { PreviewUrlsHeader } from './PreviewUrlsHeader.tsx';
import { SearchInput } from './SearchInput.tsx';

const baseExpandedBarItemHeight = 304;
const urlPatternRowHeightIncrement = 64;

export type ConfiguredPatterns = {
  readonly contentTypeId: Uuid;
  readonly contentTypeName: string;
  readonly patterns: ReadonlyArray<IPreviewUrlPattern>;
};

type Props = {
  readonly configuredPatterns: ReadonlyArray<ConfiguredPatterns>;
  readonly disabledConfigureTooltipMessage: string | undefined;
  readonly editedContentTypeId: Uuid | undefined;
  readonly errors: ReadonlyMap<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>>;
  readonly isEditingEnabled: boolean;
  readonly onAddNewPattern: () => void;
  readonly onCancel: () => void;
  readonly onConfigureContentType: () => void;
  readonly onConfirm: () => void;
  readonly onDelete: () => void;
  readonly onDeletePattern: (spaceRowId: Uuid) => void;
  readonly onEdit: (
    contentTypeId: Uuid,
    initialPatterns: ReadonlyArray<IPreviewUrlPattern>,
  ) => void;
  readonly onChange: (previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>) => void;
  readonly onSearchPhraseChange: (searchPhrase: string) => void;
  readonly searchPhrase: string;
  readonly spaces: SpacesMap;
};

export const PreviewUrlsSection: React.FC<Props> = ({
  configuredPatterns,
  disabledConfigureTooltipMessage,
  editedContentTypeId,
  errors,
  isEditingEnabled,
  onAddNewPattern,
  onDeletePattern,
  onEdit,
  onCancel,
  onConfirm,
  onSearchPhraseChange,
  onConfigureContentType,
  onDelete,
  onChange,
  searchPhrase,
  spaces,
}) => {
  return (
    <Stack spacing={Spacing.XL}>
      <SearchInput
        onChange={onSearchPhraseChange}
        onClear={() => onSearchPhraseChange('')}
        placeholderText="Filter by content type"
        isDisabled={!isEditingEnabled}
        searchPhrase={searchPhrase}
        disabledInputToolTipText={isEditingEnabled ? undefined : DisabledFilterTooltipText}
      />
      <PreviewUrlsHeader
        contentTypesCount={configuredPatterns.length}
        disabledConfigureTooltipMessage={disabledConfigureTooltipMessage}
        onConfigure={onConfigureContentType}
      />
      {configuredPatterns.length === 0 && searchPhrase.length > 0 ? (
        <PreviewConfigurationEmptyState
          title="We couldn’t find any match."
          text={`Looks like a preview URL for content type named “${searchPhrase}” is not set.`}
          onCallToAction={() => onSearchPhraseChange('')}
          callToActionText="Clear search phrase"
        />
      ) : (
        <Stack
          component="ul"
          className="bar-item__list"
          spacing={Spacing.M}
          {...getDataUiElementAttribute(DataUiElement.BarItemList)}
        >
          {configuredPatterns.map(({ contentTypeId, contentTypeName, patterns }) => {
            const typeErrors = errors.get(contentTypeId) ?? new Map();
            const errorMessage = typeErrors.size ? invalidContentType : undefined;

            const allSelectedSpaceIds = new Set(
              patterns.flatMap((previewUrlPattern) =>
                Collection.getValues(previewUrlPattern.spaces),
              ),
            );

            return (
              <li key={contentTypeId} {...getDataUiElementAttribute(DataUiElement.BarItemNode)}>
                <BarItemAnimation
                  estimatedMaxHeightWhenExpanded={
                    baseExpandedBarItemHeight + urlPatternRowHeightIncrement * patterns.length
                  }
                  renderCollapsed={() => (
                    <CollapsedPreviewUrlBar
                      contentTypeName={contentTypeName}
                      errorMessage={errorMessage}
                      isEditingEnabled={isEditingEnabled}
                      onEdit={() => onEdit(contentTypeId, patterns)}
                      previewUrlPatterns={patterns}
                      spaces={spaces}
                      searchPhrase={searchPhrase}
                    />
                  )}
                  renderExpanded={() => (
                    <ExpandedPreviewUrlBar
                      contentTypeName={contentTypeName}
                      errorMessage={errorMessage}
                      errors={typeErrors}
                      onAddNewPattern={onAddNewPattern}
                      onCancel={onCancel}
                      onConfirm={onConfirm}
                      onDelete={onDelete}
                      onDeletePattern={onDeletePattern}
                      onChange={onChange}
                      previewUrlPatterns={patterns}
                      selectedSpaceIds={allSelectedSpaceIds}
                      spaces={spaces}
                      searchPhrase={searchPhrase}
                    />
                  )}
                  shouldBeExpanded={editedContentTypeId === contentTypeId}
                />
              </li>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};

PreviewUrlsSection.displayName = 'PreviewUrlsSection';
