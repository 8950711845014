import { combineReducers } from '@reduxjs/toolkit';
import { collectionGroups } from './userInvitation/collectionGroups.ts';
import { existingUsersByEmail } from './userInvitation/existingUsersByEmail.ts';
import { invitations } from './userInvitation/invitations.ts';
import { isInitialized } from './userInvitation/isInitialized.ts';
import { isSaving } from './userInvitation/isSaving.ts';
import { savingError } from './userInvitation/savingError.ts';
import { searchText } from './userInvitation/searchText.ts';

export const userInvitation = combineReducers({
  collectionGroups,
  existingUsersByEmail,
  invitations,
  isInitialized,
  isSaving,
  savingError,
  searchText,
});
