import {
  Project_CloneProjectValidation_Failed,
  Project_CloneProject_Failed,
  Project_CloneProject_Finished,
  Project_CloneProject_Started,
} from '../constants/projectActionTypes.ts';

export const startCloningProject = () =>
  ({
    type: Project_CloneProject_Started,
  }) as const;

export const finishCloningProject = () =>
  ({
    type: Project_CloneProject_Finished,
  }) as const;

export const failCloningProject = (sourceProjectId: Uuid, message: string = '') =>
  ({
    type: Project_CloneProject_Failed,
    payload: {
      sourceProjectId,
      message,
    },
  }) as const;

export const cloneProjectValidationFailed = () =>
  ({
    type: Project_CloneProjectValidation_Failed,
  }) as const;
