import { IContentItemStatus } from './IContentItemStatus.ts';
import { SearchScope, emptyListingFilter } from './IListingFilter.ts';
import { IPublishingState } from './IPublishingState.ts';

export type StorageListingFilterVersion4 = {
  readonly searchPhrase: string;
  readonly searchScope: ReadonlyArray<SearchScope>;
  readonly selectedCollectionsNodes: ReadonlyArray<Uuid>;
  readonly selectedContentTypesNodes: ReadonlyArray<Uuid>;
  readonly selectedContributorsNodes: ReadonlyArray<Uuid>;
  readonly selectedPublishingStateNodes: IPublishingState;
  readonly selectedRequiredElementsNodes: IContentItemStatus;
  readonly selectedSitemapNodes: ReadonlyArray<Uuid>;
  readonly selectedSpacesNodes: ReadonlyArray<Uuid>;
  readonly selectedTaxonomyNodes: ReadonlyRecord<Uuid, ReadonlyArray<Uuid>>;
  readonly selectedWorkflowNodes: ReadonlyArray<readonly [Uuid, UuidArray]>;
  readonly version: 4;
};

export const emptyStorageListingFilterVersion4: StorageListingFilterVersion4 = {
  searchPhrase: '',
  searchScope: [...emptyListingFilter.searchScope],
  selectedCollectionsNodes: [],
  selectedContentTypesNodes: [],
  selectedContributorsNodes: [],
  selectedPublishingStateNodes: emptyListingFilter.selectedPublishingStateNodes,
  selectedRequiredElementsNodes: emptyListingFilter.selectedContentItemStatus,
  selectedSitemapNodes: [],
  selectedSpacesNodes: [],
  selectedTaxonomyNodes: {},
  selectedWorkflowNodes: [],
  version: 4,
};
