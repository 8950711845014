export enum SortBy {
  Newest = 'newest',
  Oldest = 'oldest',
  ByNameAsc = 'by-name-asc',
  ByNameDesc = 'by-name-desc',
}

export enum Status {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
}
