import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ICustomAppRepository } from '../../../../repositories/interfaces/ICustomAppRepository.type.ts';
import {
  Data_CustomApps_Started,
  Data_CustomApps_Success,
} from '../../../constants/dataActionTypes.ts';
import { CustomApp, createCustomAppDomainModel } from '../../../models/customApps/CustomApp.ts';

interface IDeps {
  readonly customAppRepository: Pick<ICustomAppRepository, 'getAll'>;
}

const start = () => ({ type: Data_CustomApps_Started }) as const;
const success = (data: ReadonlyArray<CustomApp>) =>
  ({
    type: Data_CustomApps_Success,
    payload: { data },
  }) as const;

export type LoadCustomAppsActionsType = ReturnType<typeof start | typeof success>;

export const createLoadCustomAppsAction =
  ({ customAppRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    const customAppServerModels = await customAppRepository.getAll(abortSignal);
    const customApps = customAppServerModels.map(createCustomAppDomainModel);

    dispatch(success(customApps));
  };
