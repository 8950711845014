import { convertFileSizeToBytes } from '../../../../../_shared/utils/fileSizeUtils.ts';
import { AssetTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { IAssetReferenceServerModel } from '../../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetReference } from '../../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import {
  IAssetTypeElementData,
  getAssetTypeElementDataFromServerModel,
} from '../../models/elements/AssetTypeElementData.ts';
import { hasAssetDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';
import {
  getFloatFromString,
  getIntegerFromString,
} from './utils/typeElementDataConvertersUtils.ts';

const getDefault = (typeElement: IAssetTypeElementData) => {
  return hasAssetDefaultValue(typeElement)
    ? { global: { value: getAssetReferenceServerModel(typeElement.defaultValue) } }
    : null;
};

function convertTypeElementRecordToServerModel(
  typeElement: IAssetTypeElementData,
): AssetTypeElementServerModel {
  const minItems = typeElement.minItems && getIntegerFromString(typeElement.minItems);
  const maxItems = typeElement.maxItems && getIntegerFromString(typeElement.maxItems);
  const minWidth = typeElement.minWidth && getIntegerFromString(typeElement.minWidth);
  const maxWidth = typeElement.maxWidth && getIntegerFromString(typeElement.maxWidth);
  const minHeight = typeElement.minHeight && getIntegerFromString(typeElement.minHeight);
  const maxHeight = typeElement.maxHeight && getIntegerFromString(typeElement.maxHeight);
  const fileSize = typeElement.fileSize && getFloatFromString(typeElement.fileSize);
  const fileSizeUnit = typeElement._fileSizeUnitOption;

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    assetFileType: typeElement.fileType,
    default: getDefault(typeElement),
    fileSize:
      fileSize && fileSize > 0 && fileSizeUnit
        ? convertFileSizeToBytes(fileSize, fileSizeUnit)
        : null,
    maxHeight: maxHeight && maxHeight > 0 ? maxHeight : null,
    maxItems: maxItems && maxItems > 0 ? maxItems : null,
    maxWidth: maxWidth && maxWidth > 0 ? maxWidth : null,
    minHeight: minHeight && minHeight > 0 ? minHeight : null,
    minItems: minItems && minItems > 0 ? minItems : null,
    minWidth: minWidth && minWidth > 0 ? minWidth : null,
    type: ElementType.Asset,
  };
}

export function createAssetTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: AssetTypeElementServerModel) =>
      getAssetTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IAssetTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}

const getAssetReferenceServerModel = (
  domainModel: ReadonlyArray<AssetReference>,
): ReadonlyArray<IAssetReferenceServerModel> =>
  domainModel.map((model) => ({ _id: model.id, renditions: model.renditions }));
