import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_Editor_Left } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentModels_TypeEditor_Left } from '../../../contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ElementValue_Changed,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ItemElementErrorResult,
  ItemElementErrors,
} from '../../utils/elementErrorCheckers/types/Errors.ts';
import {
  updateStateForNonRichTextElement,
  updateStateForRichTextElement,
} from '../utils/validationResultUtils.ts';

const isErrorResultValid = (errorResult: ItemElementErrorResult) =>
  !!errorResult && !!errorResult.errorMessages.length;

const initialState: ItemElementErrors = new Map();

export function itemValidationErrors(state = initialState, action: Action): ItemElementErrors {
  switch (action.type) {
    case ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished: {
      return Collection.filter(action.payload.errors, isErrorResultValid);
    }

    case ContentItemEditing_ElementValue_Changed:
    case ContentItemEditing_ElementRefresh_Finished: {
      const elementData = action.payload.elementData;
      const validationResult = action.payload.validationResult;

      return elementData.type === ElementType.RichText
        ? updateStateForRichTextElement(
            state,
            validationResult.errors,
            elementData.elementId,
            isErrorResultValid,
          )
        : updateStateForNonRichTextElement(
            state,
            validationResult.errors,
            elementData.elementId,
            isErrorResultValid,
          );
    }

    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
