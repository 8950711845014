export const getContentItemScrollTableRowHeightPx = (): number => 50;

export const untitledContentItem = 'Untitled content item';

export const cannotOpenUsageDueToLackOfAccess = 'Your role cannot view this item.';

export const cannotOpenContentTypesApp = 'You are not allowed to configure content types.';

export const MaxFullTextSearchPhraseLength = 200;

export const RedactedItemName = 'Unavailable item';

export const ItemPublishedWithNewVersionTooltipText = 'This item has also a published version.';
