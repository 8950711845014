import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import { IEditedContentItemThreads } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';
import { ILoadContentItemReferencesAction } from './loadContentItemReferences.ts';

export type ILoadRelatedContentItemElementsDataAction = (
  contentItemId: Uuid,
  variantId: Uuid,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  commentThreadId: Uuid | null,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface ILoadRelatedContentItemElementsDataActionDependency {
  readonly loadContentItemReferences: ILoadContentItemReferencesAction;
  readonly loadDefaultListingItems: ILoadListingItemsAction;
  readonly commentRepository: Pick<ICommentRepository, 'getAll'>;
  readonly getCommentsForContentItemVariant: (
    commentThreads: ReadonlyArray<ICommentThread>,
    focusedCommentThreadId: Uuid | null,
  ) => IEditedContentItemThreads;
  readonly commentsLoaded: (comments: IEditedContentItemThreads) => Action;
}

export const loadRelatedContentItemElementsDataActionCreator =
  (
    deps: ILoadRelatedContentItemElementsDataActionDependency,
  ): ILoadRelatedContentItemElementsDataAction =>
  (contentItemId, variantId, elements, commentThreadId, abortSignal) =>
  async (dispatch, getState) => {
    const state = getState();

    const loadDefaultListingItems = async (): Promise<void> => {
      if (
        variantId !== DefaultVariantId &&
        hasCapabilityInLanguage(state, Capability.ViewContent, DefaultVariantId)
      ) {
        await dispatch(deps.loadDefaultListingItems([contentItemId], abortSignal));
      }
    };

    const loadComments = async (): Promise<void> => {
      const commentThreads = (
        await deps.commentRepository.getAll(contentItemId, variantId, false, abortSignal)
      ).map(createCommentThreadDomainModel);

      const comments = deps.getCommentsForContentItemVariant(commentThreads, commentThreadId);
      dispatch(deps.commentsLoaded(comments));
    };

    await Promise.all([
      loadComments(),
      loadDefaultListingItems(),
      dispatch(deps.loadContentItemReferences(elements, abortSignal)),
    ]);
  };
