import { IListingItemDataServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';

export interface IListingItemData {
  readonly codeName: string;
  readonly id: Uuid;
  readonly collectionId: Uuid;
  readonly spaceIds: ReadonlyArray<Uuid>;
  readonly name: string;
  readonly typeId: Uuid;
  readonly archived: boolean;
}

export const emptyItemData: IListingItemData = {
  codeName: '',
  id: '',
  collectionId: '',
  spaceIds: [],
  name: '',
  typeId: '',
  archived: false,
};

export const getItemDataFromJS = (serverModel: IListingItemDataServerModel): IListingItemData => ({
  codeName: serverModel.codeName,
  id: serverModel.id,
  collectionId: serverModel.collectionId ?? '',
  spaceIds: serverModel.spaceIds ?? [],
  name: serverModel.name,
  typeId: serverModel.type ? serverModel.type._id : '',
  archived: serverModel.archived,
});
