import React from 'react';
import { Route, Switch, useParams } from 'react-router';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  CalendarRoute,
  HomeRouteParams,
  ProjectOverviewRoute,
} from '../../../_shared/constants/routePaths.ts';
import { EnsureSelectedVariantIdInRoute } from '../../../_shared/containers/EnsureSelectedVariantIdInRoute.tsx';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { getSelectedLanguageId } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  contentInventoryRequiredCapabilities,
  contentRequiredCapabilities,
} from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { Calendar } from '../../contentPlanning/calendar/containers/Calendar/Calendar.tsx';
import { ProjectOverviewPage } from '../../contentPlanning/projectOverview/containers/ProjectOverviewPage.tsx';
import { localizedRouteLeft } from '../actions/homeActions.ts';
import { ensureSelectedVariantForHome } from '../actions/thunkHomeActions.ts';
import { EnsureSelectedArrangementForCalendar } from '../containers/EnsureSelectedArrangementForCalendar.tsx';

export const HomeSections: React.FC = () => {
  const { projectId, variantId } = useParams<HomeRouteParams>();

  return (
    <AuthorizedSection
      appName={AppNames.Home}
      requiresOneOfCapabilities={contentInventoryRequiredCapabilities}
    >
      <EnsureSelectedVariantIdInRoute
        key={`${projectId}${variantId}`}
        currentRouteVariantId={variantId}
        ensureSelectedVariant={ensureSelectedVariantForHome}
        getSelectedLanguageIdFromStore={getSelectedLanguageId}
        localizedRouteLeft={localizedRouteLeft}
      >
        <Switch>
          <Route exact path={ProjectOverviewRoute}>
            <AuthorizedSection
              appName={AppNames.ProjectOverview}
              requiresOneOfCapabilities={contentRequiredCapabilities}
            >
              <ProjectOverviewPage />
            </AuthorizedSection>
          </Route>
          <Route exact path={CalendarRoute}>
            <AuthorizedSection
              appName={AppNames.Calendar}
              requiresOneOfCapabilities={contentRequiredCapabilities}
            >
              <EnsureSelectedArrangementForCalendar>
                <Calendar />
              </EnsureSelectedArrangementForCalendar>
            </AuthorizedSection>
          </Route>
        </Switch>
      </EnsureSelectedVariantIdInRoute>
    </AuthorizedSection>
  );
};

HomeSections.displayName = 'HomeSections';
