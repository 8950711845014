import { IAssetTypeElementData } from '../models/elements/AssetTypeElementData.ts';
import { IDateTimeTypeElementData } from '../models/elements/DateTimeTypeElementData.ts';
import { ILinkedItemsTypeElementData } from '../models/elements/LinkedItemsTypeElementData.ts';
import { IMultipleChoiceTypeElementData } from '../models/elements/MultipleChoiceTypeElementData.ts';
import { INumberTypeElementData } from '../models/elements/NumberTypeElementData.ts';
import { ITaxonomyTypeElementData } from '../models/elements/TaxonomyTypeElementData.ts';
import { ITextTypeElementData } from '../models/elements/TextTypeElementData.ts';

export const hasTextDefaultValue = (typeElementData: ITextTypeElementData): boolean =>
  !!typeElementData.defaultValue;

export const hasNumberDefaultValue = (typeElementData: INumberTypeElementData): boolean =>
  !!typeElementData.defaultValue.value;

export const hasDateTimeDefaultValue = (typeElementData: IDateTimeTypeElementData): boolean =>
  !!typeElementData.defaultValue.value;

export const hasTaxonomyDefaultValue = (typeElementData: ITaxonomyTypeElementData): boolean =>
  typeElementData.defaultValue.length > 0;

export const hasMultipleChoiceDefaultValue = (
  typeElementData: IMultipleChoiceTypeElementData,
): boolean => typeElementData.defaultValue.length > 0;

export const hasAssetDefaultValue = (typeElementData: IAssetTypeElementData): boolean =>
  typeElementData.defaultValue.length > 0;

export const hasLinkedItemsDefaultValue = (typeElementData: ILinkedItemsTypeElementData): boolean =>
  typeElementData.defaultValue.length > 0;
