import { Action } from '../../../@types/Action.type.ts';
import { Shared_FallbacksForLinkedContentStatus_Loaded } from '../../constants/sharedActionTypes.ts';
import { FallbacksForLinkedContentStatus } from '../../models/FallbacksForLinkedContentStatus.type.ts';

const initialState: FallbacksForLinkedContentStatus = {
  enabled: false,
};

export function fallbacksForLinkedContentStatus(state = initialState, action: Action) {
  switch (action.type) {
    case Shared_FallbacksForLinkedContentStatus_Loaded:
      return action.payload.data;

    default:
      return state;
  }
}
