import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.type.ts';

interface IMultipleChoiceItemElementValue {
  readonly options: readonly string[];
}

export interface IMultipleChoiceItemElement
  extends IMultipleChoiceItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyMultipleChoiceItemElementValue: IMultipleChoiceItemElementValue = {
  options: [],
};

export const EmptyMultipleChoiceItemElement: IMultipleChoiceItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.MultipleChoice,
  ...EmptyMultipleChoiceItemElementValue,
};

export const newMultipleChoiceItemElement = (
  params: Partial<IMultipleChoiceItemElement>,
): IMultipleChoiceItemElement => ({
  ...EmptyMultipleChoiceItemElement,
  ...params,
});
