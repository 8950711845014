import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WidgetDetailTableStateContent } from '../../shared/components/WidgetDetailTableStateContent.tsx';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';

export type UnchangedUnpublishedWidgetDetailProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly state: WidgetListingState;
}>;

export const UnchangedUnpublishedWidgetDetail: React.FC<UnchangedUnpublishedWidgetDetailProps> = ({
  ariaLabelledBy,
  children,
  state,
}) => {
  return (
    <WidgetDetailTableStateContent state={state}>
      <DataTable
        ariaLabelledBy={ariaLabelledBy}
        dataUiCollectionName={DataUiCollection.ContentItems}
        header={<DataTableHeadRow columns={tableHeadColumns} />}
      >
        {children}
      </DataTable>
    </WidgetDetailTableStateContent>
  );
};

UnchangedUnpublishedWidgetDetail.displayName = 'UnchangedUnpublishedWidgetDetail';

const tableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-7',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Language),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Collection),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Contributors),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: 'Days since unchanged',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--growing-3',
  },
]);
