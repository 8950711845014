import { createGuid } from '@kontent-ai/utils';
import { TaskStatus } from '../../../../../_shared/models/Task.ts';
import { ITaskServerModel } from '../../../../../repositories/serverModels/ITaskServerModel.type.ts';

const defaultTask: ITaskServerModel = {
  id: '00000000-0000-0000-0000-000000000000',
  itemVariantId: {
    itemId: '00000000-0000-0000-0000-000000000000',
    variantId: '00000000-0000-0000-0000-000000000000',
  },
  description: 'Demo task',
  status: TaskStatus.Open,
  owner: '00000000-0000-0000-0000-000000000000',
  lastModifiedAt: '2023-10-27T09:20:53.15728Z',
  lastModifiedBy: '00000000-0000-0000-0000-000000000000',
  due: null,
  assignees: [],
};

export const createDemoTask = (partial?: Partial<ITaskServerModel>): ITaskServerModel => ({
  ...defaultTask,
  id: createGuid(),
  ...partial,
});
