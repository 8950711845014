import { forwardRef } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getSubscription,
  getSubscriptionAvailableProjectLocations,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ProjectLocationSelector } from '../../components/projects/ProjectLocationSelector.tsx';

type Props = {
  readonly destinationSubscriptionId: Uuid;
  readonly disabled: boolean | undefined;
  readonly dropdownId: string;
  readonly onSelectionChanged: (projectLocationId: string) => void;
  readonly selectedProjectLocationId: string;
};

const ProjectLocationSelectorContainer = forwardRef<HTMLDivElement, Props>(
  (
    {
      destinationSubscriptionId,
      disabled,
      dropdownId,
      onSelectionChanged,
      selectedProjectLocationId,
    },
    ref,
  ) => {
    const availableProjectLocations = useSelector((s) =>
      destinationSubscriptionId
        ? getSubscriptionAvailableProjectLocations(s, destinationSubscriptionId)
        : null,
    );

    const subscriptionName = useSelector((s) =>
      destinationSubscriptionId
        ? getSubscription(s, destinationSubscriptionId).subscriptionName
        : undefined,
    );

    return (
      <ProjectLocationSelector
        ref={ref}
        availableProjectLocations={availableProjectLocations}
        disabled={disabled}
        dropdownId={dropdownId}
        onSelectionChanged={onSelectionChanged}
        selectedProjectLocationId={selectedProjectLocationId}
        subscriptionName={subscriptionName}
      />
    );
  },
);

ProjectLocationSelectorContainer.displayName = 'ProjectLocationSelectorContainer';
export { ProjectLocationSelectorContainer as ProjectLocationSelector };
