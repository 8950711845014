import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { UsageItemReference, createUsages } from '../../../../../_shared/utils/usage/usageUtils.ts';
import { getContentItemVariantReferenceFromServerModel } from '../../../../itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { AssetLibrary_Asset_UsageLoaded } from '../../constants/assetLibraryActionTypes.ts';
import { AssetUsages } from '../../stores/IAssetLibraryState.ts';

const initialState: AssetUsages = Immutable.Map<Uuid, Immutable.Set<UsageItemReference>>();

export function usage(state: AssetUsages = initialState, action: Action): AssetUsages {
  switch (action.type) {
    case AssetLibrary_Asset_UsageLoaded: {
      const { usage: assetUsage, assetId } = action.payload;

      return state.set(
        assetId,
        createUsages(assetUsage.map(getContentItemVariantReferenceFromServerModel)),
      );
    }

    default:
      return state;
  }
}
