import { BaseColor, Spacing, colorDividerDefault } from '@kontent-ai/component-library/tokens';
import React, { HTMLAttributes } from 'react';
import {
  DividerDirection,
  StyledDivider,
  StyledDividerWrapper,
} from './components/StyledDivider.tsx';

export interface IDividerProps extends HTMLAttributes<HTMLElement> {
  readonly className?: string;
  readonly direction: DividerDirection;
  readonly color?: BaseColor;
  readonly offsetBefore: Spacing;
  readonly offsetAfter: Spacing;
}

export const Divider = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IDividerProps>>(
  (
    {
      className,
      direction,
      color = colorDividerDefault,
      offsetBefore,
      offsetAfter,
      children,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <StyledDividerWrapper
      className={className}
      ref={forwardedRef}
      $direction={direction}
      offsetAfter={offsetAfter}
    >
      {children}
      <StyledDivider
        $color={color}
        $direction={direction}
        offsetBefore={offsetBefore}
        {...otherProps}
      />
    </StyledDividerWrapper>
  ),
);

Divider.displayName = 'Divider';
