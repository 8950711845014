import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadAssetType, loadTaxonomyGroups } from '../../../../data/actions/thunkDataActions.ts';
import { validateTypeElement } from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { createInitAssetTypeEditorAction } from './thunks/initAssetTypeEditor.ts';
import { createSaveAssetTypeAction } from './thunks/saveAssetType.ts';

const { assetTypeRepository } = repositoryCollection;

export const initAssetTypeEditor = createInitAssetTypeEditorAction({
  assetTypeRepository,
  loadTaxonomyGroups,
  validateTypeElement,
});

export const saveEditedAssetType = createSaveAssetTypeAction({
  assetTypeRepository,
  loadAssetType,
  validateTypeElement,
});
