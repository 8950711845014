import { memoize } from '@kontent-ai/memoization';
import classNames from 'classnames';
import { DraftBlockRenderConfig, EditorState } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DropTarget } from '../../../../_shared/components/DragDrop/DropTarget.tsx';
import {
  DragMoveHandler,
  HoveringCollisionStrategy,
} from '../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { getItemsDirection } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { crossedHalfTargetHeight } from '../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { isWithinTargetInset20 } from '../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetBaseBlockRenderMap } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  EditorPlugin,
  Finalize,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { getAcceptedDropTypes } from '../../editorCore/utils/editorComponentUtils.ts';
import { BaseBlockType, isTextBlockType } from '../../utils/blocks/blockType.ts';
import { getBlockKey } from '../../utils/blocks/editorBlockGetters.ts';
import { getBlocks } from '../../utils/general/editorContentGetters.ts';
import { FocusPlugin } from '../behavior/FocusPlugin.tsx';
import { OnChangePlugin } from '../behavior/OnChangePlugin.tsx';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { EditorDragDropApi } from './api/EditorDragDropApi.type.ts';
import { editorDragDropApi } from './api/editorDragDropApi.ts';
import { DroppableNativeBlockWrapper } from './components/DroppableNativeBlockWrapper.tsx';

export type GetIsDragging = () => boolean;

type DragDropPluginState = {
  readonly draggedBlockKey: string | null;
  readonly hoveringCollisionStrategy: HoveringCollisionStrategy;
  readonly getIsDragging: DecorableFunction<GetIsDragging>;
  readonly onDragStart: (blockKey: string) => void;
  readonly onDragEnd: () => void;
  readonly onMoveBlocks: DragMoveHandler;
};

export type DragDropPlugin = EditorPlugin<
  DragDropPluginState,
  None,
  EditorDragDropApi,
  None,
  [FocusPlugin, StylesPlugin, OnChangePlugin]
>;

type EditorWithDragDropProps = {
  readonly disabled: boolean | undefined;
  readonly editorWrapperRef: React.RefObject<HTMLDivElement>;
};

const EditorWithDragDrop: DecoratedEditor<DragDropPlugin, EditorWithDragDropProps> = ({
  baseRender,
  disabled,
  editorWrapperRef,
  state,
}) => {
  const { editorState, getEditorId, onMoveBlocks } = state;

  const blockMap = editorState.getCurrentContent().getBlockMap();
  const lastBlock = blockMap.last();

  // Extra drop target at the end of the editor provides target position after a table placed at the end of the editor
  // as tables provide only target at their start, see DroppableTableWrapper.tsx
  const endDropTarget = (
    <DropTarget<HTMLDivElement>
      accept={lastBlock ? getAcceptedDropTypes(lastBlock) : []}
      canDrop={!disabled}
      onMove={onMoveBlocks}
      parentId={getEditorId()}
      hoveringCollisionStrategy={isWithinTargetInset20}
      renderDroppable={(ref) => (
        <div className="rte__droptarget" contentEditable={false} ref={ref} />
      )}
      targetId={lastBlock?.getKey() ?? ''}
    />
  );

  const stateWithDragDrop = {
    ...state,
    rteProps: {
      ...state.rteProps,
      className: classNames(state.rteProps.className, {
        'rte--is-dragging': state.getIsDragging(),
      }),
    },
  };

  return (
    <>
      {baseRender(stateWithDragDrop)}
      {editorWrapperRef.current && createPortal(endDropTarget, editorWrapperRef.current)}
    </>
  );
};

EditorWithDragDrop.displayName = 'EditorWithDragDrop';

const enhanceRenderMapWithTextBlockWrappers = memoize.weak(
  (
    baseBlockRenderMap: Immutable.Map<BaseBlockType, DraftBlockRenderConfig>,
    hoveringCollisionStrategy: HoveringCollisionStrategy,
    onMoveBlocks: DragMoveHandler,
    editorId: string,
    disabled: boolean | undefined,
  ) => {
    const blockRenderMapWithTextBlockWrappers = Immutable.Map<
      BaseBlockType,
      DraftBlockRenderConfig
    >(
      baseBlockRenderMap
        .map((config: DraftBlockRenderConfig, blockType: BaseBlockType) => {
          if (config.wrapper || !isTextBlockType(blockType)) {
            return [blockType, config];
          }
          return [
            blockType,
            {
              ...config,
              // New wrapper is returned each time in order to avoid merging same types of blocks into one wrapper
              get wrapper() {
                return (
                  <DroppableNativeBlockWrapper
                    canUpdate={!disabled}
                    hoveringCollisionStrategy={hoveringCollisionStrategy}
                    onMove={onMoveBlocks}
                    parentId={editorId}
                  />
                );
              },
            },
          ];
        })
        .toArray(),
    );
    return blockRenderMapWithTextBlockWrappers;
  },
);

export const DragDropPlugin: PluginComponent<DragDropPlugin> = (props) => {
  const { disabled } = props;

  const [draggedBlockKey, setDraggedBlockKey] = useState<string | null>(null);
  const isDraggingBlock = !!draggedBlockKey;

  const isUndoRecordedForShiftBlocks = useRef(false);

  const render: Decorator<Render<DragDropPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithDragDrop
        baseRender={baseRender}
        disabled={disabled}
        editorWrapperRef={state.getWrapperRef()}
        state={state}
      />
    ),
    [disabled],
  );

  const apply: Apply<DragDropPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);

      const getIsDragging = decorable<GetIsDragging>(() => isDraggingBlock);
      const isEditorLocked: Decorator<GetIsDragging> = (baseIsEditorLocked) => () =>
        baseIsEditorLocked() || getIsDragging();

      state.isEditorLocked.decorate(isEditorLocked);

      const hoveringCollisionStrategy: HoveringCollisionStrategy = ({
        sourceId: sourceKey,
        targetId: targetKey,
        targetBoundingRect,
        pointer,
      }) => {
        const blocks = getBlocks(state.getEditorState().getCurrentContent());
        const direction = getItemsDirection(blocks, sourceKey, targetKey, getBlockKey);
        return !!direction && crossedHalfTargetHeight(targetBoundingRect, pointer, direction);
      };

      const restoreSelection = () =>
        state.executeChange((editorState) => {
          // Selection needs to be restored as the editor is disabled while hovering over a custom block
          // Only restore selection if editor has focus to prevent forcing focus
          const selection = editorState.getSelection();
          if (selection.getHasFocus() && !disabled) {
            return EditorState.forceSelection(editorState, selection);
          }
          // Blur the editor if it was not focused before to prepare it for the upcoming focus
          state.blur();
          return editorState;
        }, EditorChangeReason.Internal);

      const onDragEnd = (): void => {
        setDraggedBlockKey(null);
        isUndoRecordedForShiftBlocks.current = false;

        state.propagateChanges(state.getEditorState(), EditorChangeReason.Regular);

        // Make sure that the editor reverts back to the edit mode, as it may miss drag end event and end up in 'drag' mode which doesn't listen to any events
        // https://github.com/facebook/draft-js/issues/1454
        state.getEditorRef().current?.exitCurrentMode();

        // Display the cursor at correct position after the drag ends.
        // If the editor was not focused before the drag start then the blur function inside restoreSelection is called
        // so that the dragging ends without editor focus as well. Otherwise, the cursor would to stick to one place
        // until the user blurs the editor manually.
        restoreSelection();

        // Now it's safe to focus the editor.
        state.focus();
      };

      const onMoveBlocks: DragMoveHandler = ({ sourceId, targetId }) =>
        state.executeChange((editorState) => {
          // Allow undo only for first shift blocks operation as we want to have only one undo stack for the dragging of one block.
          const allowUndo = !isUndoRecordedForShiftBlocks;
          const newEditorState = state
            .getApi()
            .shiftCustomBlock(editorState, sourceId, targetId, allowUndo);

          isUndoRecordedForShiftBlocks.current = true;

          return newEditorState;
        }, EditorChangeReason.Drag);

      return {
        draggedBlockKey,
        hoveringCollisionStrategy,
        getIsDragging,
        onDragStart: setDraggedBlockKey,
        onDragEnd,
        onMoveBlocks,
      };
    },
    [disabled, draggedBlockKey, isDraggingBlock, render],
  );

  const finalize: Finalize<DragDropPlugin> = useCallback(
    (state) => {
      const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> =
        (baseGetBaseBlockRenderMap) => () => {
          const baseBlockRenderMap = baseGetBaseBlockRenderMap();
          const { hoveringCollisionStrategy, onMoveBlocks, getEditorId } = state;
          const editorId = getEditorId();
          const blockRenderMapWithTextBlockWrappers = enhanceRenderMapWithTextBlockWrappers(
            baseBlockRenderMap,
            hoveringCollisionStrategy,
            onMoveBlocks,
            editorId,
            disabled,
          );

          return blockRenderMapWithTextBlockWrappers;
        };

      state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);
    },
    [disabled],
  );

  const { getApiMethods } = useEditorApi<DragDropPlugin>(editorDragDropApi);

  return useEditorWithPlugin(props, {
    apply,
    finalize,
    getApiMethods,
  });
};
