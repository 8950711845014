import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { NumberValue } from '../../../../../../_shared/models/NumberValue.ts';
import { isNumberTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { INumberItemElement } from '../../../../models/contentItemElements/NumberItemElement.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { getNumberElementErrors } from '../../../../utils/elementErrorCheckers/numberElementErrorChecker.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getItemElementValueForErrorValidation } from '../../../../utils/getItemElementValueForErrorValidation.ts';
import { emptyItemElementFriendlyWarningResult } from '../../../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { getNumberItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/numberItemElementWarningChecker.ts';
import { mapElementErrorResultToItemElementErrorResult } from '../../../../utils/mapElementErrorResultToItemElementErrorResult.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

export const createNumberElementValueChangedAction =
  () =>
  (elementId: Uuid, number: NumberValue): ThunkFunction =>
  (dispatch, getState) => {
    const { editedContentItem, editedContentItemVariantElements, loadedContentItemTypes } =
      getState().contentApp;

    if (!editedContentItem) {
      throw InvariantException('numberElementValueChanged: "editedContentItem" is not loaded');
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    if (!editedContentItemType) {
      throw InvariantException('numberElementValueChanged: "editedContentItemType" is not loaded');
    }

    const originalElement = getElementByIdOrThrow<INumberItemElement>(
      elementId,
      editedContentItemVariantElements,
    );
    const updatedElement: INumberItemElement = {
      ...originalElement,
      value: number.value,
      _number: number,
    };

    const errorResult = getNumberElementErrors({
      value: getItemElementValueForErrorValidation(updatedElement),
    });
    const typeElement = editedContentItemType.contentElements.find(
      (elType) => elType.elementId === elementId,
    );
    if (!isNumberTypeElement(typeElement)) {
      return;
    }

    const warningResult = getNumberItemElementValidationWarning({
      typeElement,
      itemElement: updatedElement,
    });

    const validationResult = createValidationResult(
      mapElementErrorResultToItemElementErrorResult(errorResult, typeElement.type),
      warningResult,
      emptyItemElementFriendlyWarningResult,
      elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: updatedElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
