import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import React, { ReactNode, useState } from 'react';
import { ModalDialog } from '../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { LongProcessingChangesWarning } from './LongProcessingChangesWarning.tsx';

export enum ContentChangesWarningSubtitle {
  ContentTypeEditing = 'This content type is used for a large amount of content. Processing the changes may take up to a few hours.',
  LanguageSettingsEditing = 'Your project contains a large amount of content. Processing the changes may take up to a few hours.',
}

type ContentChangesWarningModalProps = {
  readonly hideLongProcessingChangesWarning?: boolean;
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly onDiscardChanges?: () => void;
  readonly onDismissWarning: () => void;
  readonly renderAdditionalModalContent?: () => ReactNode;
  readonly warningSubtitle: ContentChangesWarningSubtitle;
};

export const ContentChangesWarningModal: React.FC<ContentChangesWarningModalProps> = ({
  hideLongProcessingChangesWarning,
  isOpen,
  onCancel,
  onConfirm,
  onDiscardChanges,
  onDismissWarning,
  renderAdditionalModalContent,
  warningSubtitle,
}) => {
  const [isLongProcessingWarningDismissed, setIsLongProcessingWarningDismissed] = useState(false);

  const onModalConfirmed = () => {
    if (isLongProcessingWarningDismissed) {
      onDismissWarning();
    }

    onConfirm();
  };

  return (
    <ModalDialog
      headline="Save changes"
      isDismissable
      isOpen={isOpen}
      onClose={onCancel}
      primaryAction={{
        onClick: onModalConfirmed,
        text: 'Save changes',
        ...getDataUiActionAttribute(DataUiAction.Confirm),
      }}
      cancelAction={{
        onClick: onCancel,
        ...getDataUiActionAttribute(DataUiAction.Cancel),
      }}
      extraAction={
        onDiscardChanges && {
          destructive: true,
          destructiveIcon: Icons.Bin,
          onClick: onDiscardChanges,
          text: 'Discard changes',
        }
      }
      {...getDataUiElementAttribute(DataUiElement.ContentChangesWarningDialog)}
    >
      <Box maxWidth={70 * gridUnit} paddingBottom={Spacing.S}>
        <Stack spacing={Spacing.XL}>
          <LongProcessingChangesWarning
            warningSubtitle={warningSubtitle}
            isLongProcessingWarningDismissed={isLongProcessingWarningDismissed}
            onLongProcessingWarningDismiss={() =>
              setIsLongProcessingWarningDismissed(!isLongProcessingWarningDismissed)
            }
            hideLongProcessingChangesWarning={hideLongProcessingChangesWarning}
          />
          {renderAdditionalModalContent?.()}
          <p>
            Do you want to save the changes{onDiscardChanges && ', discard them,'} or go back to
            editing?
          </p>
        </Stack>
      </Box>
    </ModalDialog>
  );
};

ContentChangesWarningModal.displayName = 'ContentChangesWarningModal';
