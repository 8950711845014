import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Hint } from '@kontent-ai/component-library/Hint';
import React from 'react';
import { EmptyState } from '../../../_shared/components/EmptyState/EmptyState.tsx';
import { documentationLinks } from '../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly actionPath: string;
  readonly actionText: string;
  readonly isActionAvailable: boolean;
};

const hintText =
  'You can set apart multiple brands or content channels by using dedicated spaces. In each space, you can use different content and preview it in Web Spotlight separately, but you can also share content among the spaces.';

export const WebSpotlightEmptyState: React.FC<Props> = ({
  actionPath,
  actionText,
  isActionAvailable,
}) => (
  <EmptyState size="fullOffset">
    <EmptyState.Title>Web Spotlight</EmptyState.Title>
    <EmptyState.Content>
      <EmptyState.ContentGroup>
        <p>Web Spotlight lets you view your website structure directly in Kontent.ai.</p>
        <p>You can edit the page items and preview the changes as you go.</p>
      </EmptyState.ContentGroup>
      <EmptyState.ContentGroup>
        <p>Web Spotlight is active. To use it with spaces, create a space and set</p>
        <p>
          a root item for Web Spotlight. <Hint tooltipText={hintText} tooltipPlacement="right" />
        </p>
      </EmptyState.ContentGroup>
      <OutwardLink href={documentationLinks.setUpWebSpotlight}>
        Find out how Web Spotlight works in detail
      </OutwardLink>
    </EmptyState.Content>
    <EmptyState.Footer>
      {isActionAvailable ? (
        <RouterLinkButton
          buttonStyle="primary"
          to={actionPath}
          {...getDataUiActionAttribute(DataUiAction.GoToSpaces)}
        >
          {actionText}
        </RouterLinkButton>
      ) : (
        <p>
          You don’t have permission to assign the root item. Ask your project manager to give you
          content creation and manage spaces permissions to do this.
        </p>
      )}
    </EmptyState.Footer>
  </EmptyState>
);

WebSpotlightEmptyState.displayName = 'WebSpotlightEmptyState';
