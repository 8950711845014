import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

interface IOpenProjectActionButton {
  readonly projectId: string;
}

export const OpenProjectActionButton: FC<IOpenProjectActionButton> = ({ projectId }) => (
  <div className="my-project__button-wrapper">
    <Link
      className="u-no-text-decoration"
      to={buildPath<EnvironmentRouteParams>(EnvironmentRoute, { projectId })}
      {...getDataUiActionAttribute(DataUiAction.OpenProject)}
    >
      <Button buttonStyle="primary" buttonDisplay="block">
        Open project
      </Button>
    </Link>
  </div>
);

OpenProjectActionButton.displayName = 'OpenProjectActionButton';
