import { ProjectIdStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

let _projectId: Uuid | null = null;

export interface IProjectIdStorage {
  readonly save: (projectId: Uuid) => void;
  readonly forget: () => void;
  readonly load: () => Uuid | null;
}

export const projectIdStorage = {
  save(projectId: Uuid) {
    localStorage.set(ProjectIdStorageKey, projectId);
    _projectId = projectId;
  },

  forget(): void {
    localStorage.remove(ProjectIdStorageKey);
    _projectId = null;
  },

  load(): Uuid | null {
    if (_projectId === null) {
      _projectId = localStorage.get(ProjectIdStorageKey);
    }

    return _projectId;
  },
};
