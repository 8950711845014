import { useIdWithPrefix } from '@kontent-ai/hooks';
import classNames from 'classnames';
import { ChangeEventHandler, useRef, useState } from 'react';
import { DialogState } from '../../../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { addFlipping } from '../../../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISavedFilter } from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { UntitledFilter } from '../../constants/uiConstants.ts';
import { EditSavedFilter } from './EditSavedFilter.tsx';

type Props = {
  readonly filter: ISavedFilter;
  readonly isActive: boolean;
  readonly isBeingDeleted: boolean;
  readonly isBeingSaved: boolean;
  readonly onFilterDelete: (filter: ISavedFilter) => void;
  readonly onFilterNameClick: (filter: ISavedFilter) => void;
  readonly onFilterRename: (filter: ISavedFilter) => void;
};

export const SavedFiltersListingItem = ({
  filter,
  isActive,
  isBeingDeleted,
  isBeingSaved,
  onFilterDelete,
  onFilterNameClick,
  onFilterRename,
}: Props) => {
  const [filterName, setFilterName] = useState<string>(filter.name);
  const inputId = useIdWithPrefix('FilterName');
  const filterNameInputRef = useRef<HTMLInputElement>(null);
  const handleNameClick = () => {
    if (!isBeingDeleted && !isActive) {
      onFilterNameClick(filter);
    }
  };
  const handleSave = () => {
    onFilterRename({
      ...filter,
      name: filterName,
    });
    closeDialog();
  };

  const {
    PopoverDialog,
    popoverDialogProps,
    isOpen: isEditOpen,
    openDialog,
    closeDialog,
    targetProps,
  } = usePopoverDialog({
    adjustTippyOptions: addFlipping,
    autoFocusRef: filterNameInputRef,
    dialogState: DialogState.Default,
    extraAction: {
      text: 'Delete',
      onClick: () => onFilterDelete(filter),
      destructive: true,
      ...getDataUiActionAttribute(DataUiAction.Delete),
    },
    headline: 'Filter name',
    onClose: () => setFilterName(filter.name),
    placement: 'right-start',
    primaryAction: {
      text: 'Save',
      onClick: handleSave,
      ...getDataUiActionAttribute(DataUiAction.Save),
    },
    cancelAction: { destructive: false, ...getDataUiActionAttribute(DataUiAction.Cancel) },
    shouldCloseOnBlur: false,
    shouldCloseOnInteractOutside: () => true,
  });

  const handleFilterNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilterName(event.target.value);
  };

  return (
    <div
      className={classNames('saved-filters__list-item', {
        'saved-filters__list-item--active': isActive,
        'saved-filters__list-item--disabled': isBeingDeleted,
      })}
      onClick={handleNameClick}
      {...getDataUiActionAttribute(DataUiAction.Select)}
    >
      <HotkeysHandler
        className={classNames('saved-filters__list-item-text', {
          'saved-filters__list-item-text--active': isActive,
          'saved-filters__list-item-text--disabled': isBeingDeleted,
        })}
        handlers={{
          onEnter: handleNameClick,
        }}
      >
        <span title={filter.name} tabIndex={isBeingDeleted || isActive ? undefined : 0}>
          {filter.name}
        </span>
      </HotkeysHandler>
      <div className="btn-wrapper" {...targetProps}>
        <EditSavedFilter
          isActive={isEditOpen}
          isAlwaysVisible={isEditOpen}
          isSaving={isBeingSaved || isBeingDeleted}
          onClick={openDialog}
        />
      </div>
      <PopoverDialog
        {...popoverDialogProps}
        {...getDataUiElementAttribute(DataUiElement.FilterForm)}
      >
        <HotkeysHandler handlers={{ onEnter: handleSave, onEscape: closeDialog }}>
          <TextField
            autoFocus
            dataUiInputName={DataUiInput.FilterName}
            disabled={isBeingSaved || isBeingDeleted}
            inputId={inputId}
            maxLength={ValidationConstants.FilterNameMaxLength}
            onChange={handleFilterNameChange}
            placeholder={UntitledFilter}
            ref={filterNameInputRef}
            value={filterName}
          />
        </HotkeysHandler>
      </PopoverDialog>
    </div>
  );
};
