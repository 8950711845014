import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../_shared/components/AppBar/BackNavigation.tsx';
import { getGoBackLinkByPath } from '../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';

export const EntityWebhookListingBackNavigation = () => {
  const location = useLocation();

  return <BackNavigation to={getGoBackLinkByPath(location.pathname)} isDisabled={false} />;
};

EntityWebhookListingBackNavigation.displayName = 'EntityWebhookListingBackNavigation';
