import styled, { css } from 'styled-components';
import { Color } from '../../tokens/quarks/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { transition250 } from '../../tokens/quarks/transitions.ts';
import { px } from '../../tokens/utils/utils.ts';

interface IIconWrapper {
  readonly $color?: Color;
  readonly $size?: IconSize;
}

export const IconWrapper = styled.span<IIconWrapper>`
  vertical-align: middle;
  transition: color ${transition250};
  
  ${({ $size }) =>
    $size &&
    css`
    font-size: ${px($size)};
  `};
  
  ${({ $color }) =>
    $color &&
    css`
    color: ${$color};
  `};
  
  > svg {
    display: block;
  }
`;
