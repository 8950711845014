import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { usePrevious } from '@kontent-ai/hooks';
import indefiniteArticles from 'articles';
import classNames from 'classnames';
import React, { memo, useContext, useEffect, useState, useId } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { AutoScrollLink } from '../../../../../../../_shared/components/AutoScroll/AutoScrollLink.tsx';
import { AutoScrollProps } from '../../../../../../../_shared/components/AutoScroll/autoScrollProps.type.ts';
import { BarItemAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { BarItemDragAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemActions } from '../../../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { LinkedItemHiddenActions } from '../../../../../../../_shared/components/linkedItems/LinkedItemHiddenActions.tsx';
import { LinkedItemNode } from '../../../../../../../_shared/components/linkedItems/LinkedItemNode.tsx';
import { LinkedItemOpenEditingAction } from '../../../../../../../_shared/components/linkedItems/LinkedItemOpenEditingAction.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { EditorPaperContext } from '../../../../../../../_shared/contexts/EditorPaperContext.tsx';
import {
  ExpandedLinkedItemLayoutType,
  IContentEntryLinkedItemExpandedData,
} from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { getDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiAction,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getReachedPaperThresholds } from '../../../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { useAvailableCollectionsForSelectedLanguage } from '../../../../../../contentInventory/content/features/ContentItemInventory/selectors/useAvailableCollections.ts';
import { CommentsContext } from '../../../../../components/CommentsContext.tsx';
import {
  ElementAttributes,
  getLinkedItemIdAttribute,
} from '../../../../../constants/elementAttributes.ts';
import { DuplicateToCollectionDialog } from '../../../../DuplicateToCollection/components/DuplicateToCollectionDialog.tsx';
import { LinkedItemContent } from '../../../../LinkedItems/components/LinkedItemContent.tsx';
import { CommentButton } from '../../../containers/elements/subComponents/CommentButton.tsx';
import { ContentNestingContext } from '../../../context/ContentNestingContext.tsx';
import { LinkedItemDuplicateAction } from './LinkedItemDuplicateAction.tsx';
import { LinkedItemMoreActionsMenu } from './LinkedItemMoreActionsMenu.tsx';

export interface ILinkedItemStateProps {
  readonly canCreate: boolean;
  readonly canDuplicateInSomeCollection: boolean;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly collectionName: string;
  readonly commentSegmentId?: Uuid;
  readonly contentItemId: Uuid;
  readonly contentItemPath?: string;
  readonly contentType?: IContentType;
  readonly currentLanguageName: string;
  readonly hasFocusedComment: boolean;
  readonly isEntryTypeAllowed: boolean;
  readonly isExpandable: boolean;
  readonly loadedContentItem?: IListingContentItem;
}

export interface ILinkedItemDispatchProps {
  readonly onTrackLinkedItemEditOpened: () => void;
  readonly onTrackLinkedItemExpanded:
    | ((eventData: IContentEntryLinkedItemExpandedData) => void)
    | null;
}

export interface ILinkedItemOwnProps extends AutoScrollProps {
  readonly commentThreadId: Uuid | null;
  readonly connectDragSource?: ConnectDragSource;
  readonly displayDragButton?: boolean;
  readonly isDisabled: boolean;
  readonly isDragging: boolean;
  readonly isPage?: boolean;
  readonly onDelete: (contentItemId: Uuid) => void;
  readonly onDuplicate?: (contentItemId: Uuid, destinationCollectionId: Uuid) => void;
  readonly onExpandedChanged?: (isExpanded: boolean) => void;
  readonly onNewComment: () => void;
  readonly renderExpanded?: (contentItemId: string, expandedItemId: string) => React.ReactNode;
}

export type LinkedItemProps = ILinkedItemStateProps &
  ILinkedItemDispatchProps &
  ILinkedItemOwnProps;

export const LinkedItem: React.FC<LinkedItemProps> = memo(
  ({
    alternativeScrollIds,
    canCreate,
    canDuplicateInSomeCollection,
    cannotViewDisabledMessage,
    collectionName,
    commentSegmentId,
    commentThreadId,
    connectDragSource,
    contentItemId,
    contentItemPath,
    contentType,
    currentLanguageName,
    displayDragButton,
    hasFocusedComment,
    isDisabled,
    isDragging,
    isEntryTypeAllowed,
    isExpandable,
    isPage,
    loadedContentItem,
    onDelete,
    onDuplicate,
    onExpandedChanged,
    onNewComment,
    onTrackLinkedItemEditOpened,
    onTrackLinkedItemExpanded,
    renderExpanded,
    scrollId,
    scrollOptions,
  }) => {
    const [isExpandedInternal, setIsExpandedInternal] = useState(false);
    const isExpanded = isExpandedInternal && !!renderExpanded;
    const previousIsExpanded = usePrevious(isExpanded);
    useEffect(() => {
      if (isExpanded !== previousIsExpanded) {
        onExpandedChanged?.(isExpanded);
      }
    }, [isExpanded, previousIsExpanded, onExpandedChanged]);

    const [showDuplicationToAnotherCollectionModal, setShowDuplicationToAnotherCollectionModal] =
      useState(false);

    const collectionsUserCanDuplicateTo = useAvailableCollectionsForSelectedLanguage(
      Capability.CreateContent,
      contentType?.id ?? '',
    );

    const { isItemExpandable, isTopLevel, nestedLevel } = useContext(ContentNestingContext);
    const paperContext = useContext(EditorPaperContext);
    const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

    const { allowNewComments } = useContext(CommentsContext);
    const expandableItemId = useId();

    const toggleExpand = (): void => {
      if (!isExpanded && onTrackLinkedItemExpanded) {
        onTrackLinkedItemExpanded({
          linkedItemLayoutType: ExpandedLinkedItemLayoutType.Regular,
          nestedLevel,
        });
      }
      setIsExpandedInternal(!isExpanded);
    };

    const onDuplicateItem = (): void => {
      if (loadedContentItem && onDuplicate) {
        onDuplicate(loadedContentItem.item.id, loadedContentItem.item.collectionId);
      }
    };

    const closeModal = (): void => setShowDuplicationToAnotherCollectionModal(false);

    const onDuplicateItemToAnotherCollection = (destinationCollectionId: string): void => {
      if (loadedContentItem && onDuplicate) {
        onDuplicate(contentItemId, destinationCollectionId);
        closeModal();
      }
    };

    const handleOnDuplicateClick = (): void => {
      if (canCreate) {
        onDuplicateItem();
        return;
      }

      if (canDuplicateInSomeCollection) {
        setShowDuplicationToAnotherCollectionModal(true);
      }
    };

    const onRemoveItem = (): void => {
      onDelete(contentItemId);
    };

    // Do not propagate focus into ItemElement.
    // This is needed so that ItemElement doesn't stay focused when linked item is removed.
    const onRemoveButtonFocus = (e: React.FocusEvent): void => {
      e.stopPropagation();
    };

    const trackLinkedItemEditOpened = (): void => {
      if (isExpandable) {
        onTrackLinkedItemEditOpened();
      }
    };

    const isExpandedAndNotDragging = isExpanded && !isDragging;

    const item = loadedContentItem?.item;
    const variant = loadedContentItem?.variant;

    const isTranslated = !!variant && !variant.isArchived;
    const isAvailable = !!item && !item.archived && !cannotViewDisabledMessage;
    const canViewOrCreate = !cannotViewDisabledMessage || (!isTranslated && canCreate);
    const editButtonTooltip = isTranslated
      ? 'Edit'
      : `Create ${indefiniteArticles.articlize(currentLanguageName)} variant`;

    const canDuplicate =
      (canDuplicateInSomeCollection || canCreate) &&
      isTranslated &&
      !!contentType &&
      !cannotViewDisabledMessage &&
      !isDisabled &&
      !!onDuplicate;

    const displayMoreActionMenu = canDuplicate && reachedThresholds.sizeXS;
    const displayRemoveButton = !isDisabled && !displayMoreActionMenu;
    const displayDuplicateButton = canDuplicate && !reachedThresholds.sizeXS;
    const allowDragging = !isDisabled && displayDragButton;
    const hasComment = !!commentThreadId;

    return (
      <>
        <DuplicateToCollectionDialog
          collectionName={collectionName}
          collectionOptions={collectionsUserCanDuplicateTo}
          itemName={loadedContentItem?.item.name ?? ''}
          onClose={closeModal}
          onDuplicate={onDuplicateItemToAnotherCollection}
          isOpen={showDuplicationToAnotherCollectionModal}
        />
        <LinkedItemNode
          cannotViewDisabledMessage={cannotViewDisabledMessage}
          canViewOrCreate={canViewOrCreate}
          dataAttributes={{
            ...getDataUiObjectNameAttribute(item?.name || contentItemId),
            ...getDataAttribute(ElementAttributes.RichTextCommentSegmentId, commentSegmentId),
            ...getLinkedItemIdAttribute(contentItemId),
          }}
          hasComment={hasComment}
          hasFocusedComment={hasFocusedComment}
          isClickable
          isCompact={reachedThresholds.sizeXS}
          isDisabled={isDisabled}
          isDragging={isDragging}
          isEntryTypeAllowed={isEntryTypeAllowed}
          isExpandable={isExpandable}
          isExpanded={isExpanded}
          isTopLevel={isTopLevel}
          isTranslated={isTranslated}
          renderExpanded={() =>
            isExpandedAndNotDragging && item && renderExpanded?.(item.id, expandableItemId)
          }
        >
          <BarItemActions>
            {allowDragging && (
              <BarItemDragAction
                connectDragSource={connectDragSource}
                isCompact={reachedThresholds.sizeXS}
              />
            )}
          </BarItemActions>
          <LinkedItemContent
            expandedItemId={expandableItemId}
            cannotViewDisabledMessage={cannotViewDisabledMessage}
            contentItem={loadedContentItem}
            contentType={contentType}
            doNotRenderToggle={!isItemExpandable}
            isInvalid={!isEntryTypeAllowed}
            isExpanded={isExpandedAndNotDragging}
            onToggleExpand={cannotViewDisabledMessage ? undefined : toggleExpand}
            isPage={isPage}
            reachedThresholds={reachedThresholds}
          />
          <BarItemActions>
            {!reachedThresholds.sizeXS && (
              <LinkedItemHiddenActions
                hiddenActionsCount={
                  [!isAvailable, !canDuplicate, isDisabled].filter(Boolean).length
                }
                isCompact={reachedThresholds.sizeXS}
              />
            )}
            {(allowNewComments || hasComment) && (
              <div
                className={classNames('bar-item__action bar-item__action--comment', {
                  'bar-item__action--size-xs': reachedThresholds.sizeXS,
                })}
              >
                <CommentButton
                  commentThreadId={commentThreadId}
                  hasFocusedComment={hasFocusedComment}
                  onNewComment={onNewComment}
                />
              </div>
            )}
            {displayDuplicateButton && (
              <LinkedItemDuplicateAction
                onClick={handleOnDuplicateClick}
                isCompact={reachedThresholds.sizeXS}
              />
            )}
            <LinkedItemOpenEditingAction
              canCreate={canCreate}
              dataUiAction={DataUiAction.Edit}
              icon={isTranslated ? Icons.Edit : Icons.DocPlus}
              isAvailable={isAvailable}
              isCompact={reachedThresholds.sizeXS}
              isTranslated={isTranslated}
              renderClickableWrapper={({ action, className }) => (
                <AutoScrollLink
                  onNavigated={trackLinkedItemEditOpened}
                  to={contentItemPath || ''}
                  className={className}
                  scrollId={scrollId}
                  alternativeScrollIds={alternativeScrollIds}
                  tabIndex={-1}
                  dataUiAction={DataUiAction.Edit}
                  scrollOptions={scrollOptions}
                >
                  {action}
                </AutoScrollLink>
              )}
              screenReaderText={editButtonTooltip}
              tooltipText={editButtonTooltip}
            />
            {displayRemoveButton && (
              <Tooltip tooltipText="Remove" placement="bottom">
                <BarItemAction
                  buttonClassName="btn--quinary-destructive"
                  className="bar-item__action--is-destructive"
                  iconName={IconName.Times}
                  isCompact={reachedThresholds.sizeXS}
                  screenReaderText="Remove"
                  onClick={onRemoveItem}
                  onFocus={onRemoveButtonFocus}
                  dataUiActionName={DataUiAction.Delete}
                />
              </Tooltip>
            )}
            {displayMoreActionMenu && (
              <Box marginX={Spacing.XS}>
                <LinkedItemMoreActionsMenu
                  onDuplicateItem={handleOnDuplicateClick}
                  onRemoveItem={onRemoveItem}
                />
              </Box>
            )}
          </BarItemActions>
        </LinkedItemNode>
      </>
    );
  },
);

LinkedItem.displayName = 'LinkedItem';
