import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type EnvironmentsToolbarActionsProps = {
  readonly onCreateEnvironment: () => void;
  readonly isDisabled: boolean;
  readonly tooltipText?: string;
};

export const EnvironmentsToolbarActions: React.FC<EnvironmentsToolbarActionsProps> = ({
  onCreateEnvironment,
  isDisabled,
  tooltipText,
}) => (
  <Button
    buttonStyle="secondary"
    onClick={() => onCreateEnvironment()}
    disabled={isDisabled}
    tooltipText={tooltipText}
    {...getDataUiActionAttribute(DataUiAction.CreateNew)}
  >
    Create new environment
  </Button>
);

EnvironmentsToolbarActions.displayName = 'EnvironmentsToolbarActions';
