import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { PlanLimitationWarning } from '../../../../_shared/components/infos/PlanLimitationWarning.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FeatureIsAvailableInPlans } from '../../../projectSettings/apiKeys/constants/warningMessageTemplates.ts';
import { ApiKey } from '../../../projectSettings/apiKeys/models/ApiKey.ts';
import { KeyRegenerationStatus } from '../models/KeyRegenerationStatus.ts';
import { SubscriptionApiKeySection } from './SubscriptionApiKeySection.tsx';

type Props = {
  readonly apiKey: ApiKey;
  readonly isApiAvailable: boolean;
  readonly isLoadingFailed: boolean;
  readonly regenerationStatus: KeyRegenerationStatus;
  readonly subscriptionId: Uuid;
  readonly onRegenerate: () => void;
};

export const SubscriptionApiKeyCard: React.FC<Props> = ({
  apiKey,
  isApiAvailable,
  isLoadingFailed,
  regenerationStatus,
  subscriptionId,
  onRegenerate,
}) => (
  <Card
    component="section"
    cardLabel="Subscription API"
    {...getDataUiElementAttribute(DataUiElement.ApiKeyCard)}
  >
    <Card.Headline {...getDataUiElementAttribute(DataUiElement.ApiKeyCardTitle)}>
      Subscription API
    </Card.Headline>
    <Card.Body>
      <Stack spacing={Spacing.XL}>
        <Box component="p">
          Manage users and projects under your subscription.
          {isApiAvailable && ' Requests to the API must be authenticated.'}
        </Box>
        <Box component="p">
          Learn more about the&nbsp;
          <OutwardLink href={documentationLinks.apiSubscription}>Subscription API</OutwardLink>.
        </Box>
        {isApiAvailable ? (
          <Box {...getDataUiElementAttribute(DataUiElement.ApiKeyInputGroup)}>
            <SubscriptionApiKeySection
              apiKey={apiKey}
              isLoadingFailed={isLoadingFailed}
              regenerationStatus={regenerationStatus}
              onRegenerate={onRegenerate}
            />
          </Box>
        ) : (
          <Box>
            <PlanLimitationWarning
              editedSubscriptionId={subscriptionId}
              introMessage={FeatureIsAvailableInPlans('Commercial')}
              showSimplified
              userCanChangePlan
            />
          </Box>
        )}
      </Stack>
    </Card.Body>
  </Card>
);

SubscriptionApiKeyCard.displayName = 'SubscriptionApiKeyCard';
