import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { BarItemAction } from '../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { BarItemActions } from '../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemTitle } from '../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RedactedItemName } from '../../../contentInventory/content/constants/uiConstants.ts';

type Props = {
  readonly contentItemName: string;
  readonly contentTypeName: string;
  readonly isRedacted: boolean;
  readonly onUnlink: () => void;
  readonly openInNewTabPath: string | null;
};

export const RootItemBar: React.FC<Props> = ({
  contentItemName,
  contentTypeName,
  isRedacted,
  onUnlink,
  openInNewTabPath,
}) => {
  return (
    <div className="bar-item__wrapper">
      <div className="bar-item__pane">
        <div className="bar-item__bar">
          <BarItemTitle
            className="bar-item__title--with-flex"
            dataUiObjectName={isRedacted ? '' : contentItemName}
          >
            <Box width="100%">
              <Row alignX="start" spacingX={Spacing.L}>
                <Column width="1/2">
                  <OptionalTooltip
                    placement="bottom-start"
                    text={isRedacted ? '' : contentItemName}
                  >
                    {isRedacted ? RedactedItemName : contentItemName}
                  </OptionalTooltip>
                </Column>
                <Column width="1/2">
                  <Box color={colorTextLowEmphasis}>
                    <OptionalTooltip placement="bottom-start" text={contentTypeName}>
                      {contentTypeName}
                    </OptionalTooltip>
                  </Box>
                </Column>
              </Row>
            </Box>
          </BarItemTitle>
          <BarItemActions>
            {openInNewTabPath && (
              <OutwardLink
                className="bar-item__action bar-item__action--is-clickable"
                href={openInNewTabPath}
                {...getDataUiActionAttribute(DataUiAction.OpenInNewTab)}
              >
                <QuinaryButton tooltipText="Open in new tab">
                  <QuinaryButton.Icon
                    icon={Icons.ArrowRightTopSquare}
                    screenReaderText="Open in new tab"
                  />
                </QuinaryButton>
              </OutwardLink>
            )}
            <BarItemAction
              iconName={IconName.ModalClose}
              onClick={onUnlink}
              dataUiActionName={DataUiAction.Unlink}
              screenReaderText="Unlink"
              tooltipText="Unlink"
            />
          </BarItemActions>
        </div>
      </div>
    </div>
  );
};

RootItemBar.displayName = 'RootItemBar';
