import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Environment_InitListing_Finished,
  Environment_InitListing_Started,
} from '../../constants/environmentActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Environment_InitListing_Started:
      return LoadingStatus.Loading;
    case Environment_InitListing_Finished:
      return LoadingStatus.Loaded;
    default:
      return state;
  }
};
