import { ContentBlock } from 'draft-js';
import { isString } from '../../../../../../_shared/utils/stringUtils.ts';
import { getUrlRelativeOrWithProtocol } from '../../../../../../_shared/utils/urlUtils.ts';
import {
  createSelection,
  getMetadataAtSelection,
  setContentSelection,
} from '../../../../utils/editorSelectionUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../../utils/general/editorContentUtils.ts';
import { WebLinkData } from '../LinkData.type.ts';
import { createLinkWithText } from '../editorLinkUtils.ts';
import { detectAutoConvertedLink } from './detectAutoConvertedLink.ts';

type InlineLinkConversion = {
  readonly convertedFrom: string;
  readonly convertedTo: string;
  readonly textAfterLink: string;
  readonly linkData: WebLinkData;
};

function isPossibleLinkCharacter(char: string): boolean {
  return char !== ' ';
}

export const evaluateLinkConversion = (
  evaluatedChars: string,
  lastTypedChar: string | null,
): InlineLinkConversion | null => {
  const endTextOfPossibleLink =
    evaluatedChars[evaluatedChars.length - 1 - (lastTypedChar?.length ?? 0)];

  if (
    !isString(endTextOfPossibleLink) ||
    !isPossibleLinkCharacter(endTextOfPossibleLink) ||
    (isString(lastTypedChar) && isPossibleLinkCharacter(lastTypedChar))
  ) {
    return null;
  }

  const textAfterLink = lastTypedChar ?? '';
  const possibleLinkEndIndex = evaluatedChars.length - textAfterLink.length;
  const textEndingWithPossibleLink = evaluatedChars.substring(0, possibleLinkEndIndex);

  const matchedLink = detectAutoConvertedLink(textEndingWithPossibleLink);
  if (!matchedLink) {
    return null;
  }
  const url = getUrlRelativeOrWithProtocol(matchedLink);

  return {
    convertedFrom: matchedLink,
    convertedTo: matchedLink,
    textAfterLink,
    linkData: {
      url,
      title: '',
    },
  };
};

export function applyAutomaticLinkConversion(
  input: IContentChangeInput,
  lastTypedChar: string | null,
): IContentChangeResult {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const currentBlockKey = selection.getStartKey();
  const currentPosition = selection.getStartOffset();
  const currentBlock = content.getBlockForKey(currentBlockKey) as ContentBlock | undefined;
  if (!currentBlock) {
    return input;
  }

  const previousCharacters = currentBlock.getText().substr(0, currentPosition);
  const conversion = evaluateLinkConversion(previousCharacters, lastTypedChar);
  if (!conversion) {
    return input;
  }

  const positionAfterLink = currentPosition - conversion.textAfterLink.length;
  const positionBeforeLink = positionAfterLink - conversion.convertedFrom.length;
  const selectionOverLink = createSelection(
    currentBlockKey,
    positionBeforeLink,
    currentBlockKey,
    positionAfterLink,
  );

  // Do not convert to link if an entity is already applied
  const metadataAtSelection = getMetadataAtSelection(content, selectionOverLink);
  if (
    !metadataAtSelection ||
    metadataAtSelection.entityKeyAtAnyTableChars?.isEmpty() === false ||
    metadataAtSelection.entityKeyAtAnyTopLevelChars?.isEmpty() === false
  ) {
    return input;
  }

  const withLink = createLinkWithText(
    {
      content: input.content,
      selection: selectionOverLink,
    },
    conversion.convertedTo,
    conversion.linkData,
    false,
  );

  // Move the selection back after trailing text
  const newSelection = createSelection(
    withLink.selection.getAnchorKey(),
    withLink.selection.getAnchorOffset() + conversion.textAfterLink.length,
  );

  const result = {
    content: setContentSelection(withLink.content, input.selection, newSelection),
    selection: newSelection,
    wasModified: true,
  };

  return result;
}
