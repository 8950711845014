import { uuidPattern } from '../../../../_shared/utils/validation/typeValidators.ts';
import { PendingOperationId } from '../stores/IContentAppStoreState.ts';

const aiOperationIdPrefix = 'aiOperation';

export const itemEditorOperationIds = {
  codename: '/codename',
  collection: '/collection',
  element: (operationId: Uuid, elementId: Uuid) =>
    `${operationId}/contentElements/${elementId}` as const,
  name: '/name',
  pendingElementsCheck: (operationId: Uuid) => `pendingElementsCheck/${operationId}` as const,
  sitemapLocation: '/sitemapLocation',
  aiOperation: (operationId: Uuid) => `${aiOperationIdPrefix}/${operationId}` as const,
} as const;

type Keys = keyof Omit<
  typeof itemEditorOperationIds,
  'element' | 'pendingElementsCheck' | 'aiOperation'
>;
export type ItemEditorOperationId =
  | (typeof itemEditorOperationIds)[Keys]
  | ReturnType<typeof itemEditorOperationIds.element>
  | ReturnType<typeof itemEditorOperationIds.pendingElementsCheck>
  | ReturnType<typeof itemEditorOperationIds.aiOperation>;

type ElementOperationIdParams = {
  readonly elementId: string | null;
  readonly operationId: string | null;
};

export const parseElementOperationId = (
  elementOperationId: ItemEditorOperationId,
): ElementOperationIdParams => {
  const match = RegExp(
    `(?<operationId>${uuidPattern})/contentElements/(?<elementId>${uuidPattern})`,
  ).exec(elementOperationId);
  const { elementId = null, operationId = null } = match?.groups ?? {};

  return { elementId, operationId };
};

export const isItemEditorAiOperationId = (operationId: PendingOperationId): boolean =>
  operationId.startsWith(aiOperationIdPrefix);
