import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentRevisions_RevisionLoaded } from '../../features/Revisions/constants/revisionActionTypes.ts';
import { TimelineItem } from '../../models/revisions/TimeLineItem.ts';

const initialState: TimelineItem | null = null;

export function selectedRevision(state = initialState, action: Action): TimelineItem | null {
  switch (action.type) {
    case ContentRevisions_RevisionLoaded:
      return action.payload.selectedRevision;

    case ContentItemEditing_Init_Started:
    case ContentListing_Init_Started:
      return null;

    default:
      return state;
  }
}
