import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import { StatusMessage } from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getMessageFromOperationStatus } from '../../../utils/savingStatusUtils.ts';

const getSavingStatusMessage = ({ projectsApp }: IStore): StatusMessage =>
  getMessageFromOperationStatus(projectsApp.savingStatus.collections.status);

export const CollectionsSavingStatus: React.ComponentType =
  getConnectedStatusMessage(getSavingStatusMessage);
