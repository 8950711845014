import { alphabetically, createCompare } from '@kontent-ai/utils';
import { CollectionsMap, ICollection } from '../../../data/models/collections/Collection.ts';
import { anyCollectionCollection } from '../constants/anyCollectionCollection.ts';
import { CollectionOption, mapCollectionToOption } from '../models/CollectionOption.ts';

export const getSortedCollectionOptionsListWithAnyCollectionFirst = (
  collectionsById: CollectionsMap,
): ReadonlyArray<CollectionOption> =>
  [anyCollectionCollection]
    .concat(getSortedCollections(collectionsById))
    .map(mapCollectionToOption);

const getSortedCollections = (collectionsById: CollectionsMap): ReadonlyArray<ICollection> =>
  [...collectionsById.values()].sort(
    createCompare({
      compare: alphabetically,
      select: (x) => x.name,
    }),
  );
