import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IContentComponent } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { IEditedContentItem } from '../../../../itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { ICompiledContentItemElementData } from '../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IRichTextItemElement } from '../../../../itemEditor/models/contentItemElements/RichTextItemElement.ts';
import { isRichTextElement } from '../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getUsedContentComponents } from './editorContentComponentUtils.ts';

export interface IElementPathItem {
  readonly itemId: Uuid;
  readonly elementId: Uuid;
  readonly typeId: Uuid;
}

export interface IFindElementResult {
  readonly found: boolean;
  readonly element?: ICompiledContentItemElementData;
  readonly path: Immutable.List<IElementPathItem>;
}

interface IFindElementContext {
  readonly itemId: Uuid;
  readonly typeId: Uuid;
}

function traverseElements(
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  predicate: (
    el: ICompiledContentItemElementData,
    componentPath?: Immutable.List<IElementPathItem>,
  ) => boolean,
  currentPath: Immutable.List<IElementPathItem>,
  context: IFindElementContext,
  rootRichTextElement?: IRichTextItemElement,
): IFindElementResult {
  return elements.reduce(
    (result: IFindElementResult, element: ICompiledContentItemElementData) => {
      if (result.found) {
        return result;
      }

      const elementPath = currentPath.push({
        elementId: element.elementId,
        ...context,
      });

      if (predicate(element, elementPath)) {
        return {
          found: true,
          element,
          path: elementPath,
        };
      }

      if (!isRichTextElement(element)) {
        return result;
      }

      const components = getUsedContentComponents(
        element._editorState.getCurrentContent(),
        rootRichTextElement?.contentComponents ?? element.contentComponents,
      );
      return traverseComponents(
        components,
        predicate,
        elementPath,
        result,
        rootRichTextElement ?? element,
      );
    },
    {
      found: false,
      path: currentPath,
    },
  );
}

export function traverseComponents(
  components: ReadonlyArray<IContentComponent>,
  predicate: (
    el: ICompiledContentItemElementData,
    componentPath?: Immutable.List<IElementPathItem>,
  ) => boolean,
  currentPath: Immutable.List<IElementPathItem>,
  currentResult: IFindElementResult,
  rootRichTextElement: IRichTextItemElement,
): IFindElementResult {
  return components.reduce((result: IFindElementResult, component: IContentComponent) => {
    if (result.found) {
      return result;
    }

    return traverseElements(
      component.elements,
      predicate,
      currentPath,
      {
        itemId: component.id,
        typeId: component.contentTypeId,
      },
      rootRichTextElement,
    );
  }, currentResult);
}

export const traverseFindElement = (
  editedItem: IEditedContentItem,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  predicate: (
    el: ICompiledContentItemElementData,
    componentPath: Immutable.List<IElementPathItem>,
  ) => boolean,
  rootRichTextElement?: IRichTextItemElement,
): IFindElementResult => {
  const path = Immutable.List<IElementPathItem>();
  return traverseElements(
    variantElements,
    predicate,
    path,
    {
      typeId: editedItem.editedContentItemTypeId,
      itemId: editedItem.id,
    },
    rootRichTextElement,
  );
};

export const findElement = (
  editedContentItem: IEditedContentItem,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  elementId: Uuid,
  contentComponentId: Uuid | undefined,
): IFindElementResult => {
  return traverseFindElement(
    editedContentItem,
    variantElements,
    (element: ICompiledContentItemElementData, currentPath: Immutable.List<IElementPathItem>) => {
      const lastPathItem = currentPath.last();
      const currentItemId = lastPathItem?.itemId;

      const elementMatch = element.elementId === elementId;
      const firstLevelElementMatch =
        !contentComponentId && elementMatch && currentItemId === editedContentItem.id;
      const componentElementMatch =
        !!contentComponentId && elementMatch && currentItemId === contentComponentId;

      return firstLevelElementMatch || componentElementMatch;
    },
  );
};

async function traverseElementsAsync(
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  predicate: (
    el: ICompiledContentItemElementData,
    componentPath?: Immutable.List<IElementPathItem>,
  ) => Promise<boolean>,
  currentPath: Immutable.List<IElementPathItem>,
  context: IFindElementContext,
  rootRichTextElement?: IRichTextItemElement,
): Promise<IFindElementResult> {
  const elementIsFalsyMessage = (index: number) => () =>
    `${__filename}.traverseElementsAsync: The element at index "${index}" is falsy.`;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    assert(!!element, elementIsFalsyMessage(i));
    const elementPath = currentPath.push({
      elementId: element.elementId,
      ...context,
    });

    if (await predicate(element, elementPath)) {
      return {
        found: true,
        element,
        path: elementPath,
      };
    }

    if (!isRichTextElement(element)) {
      continue;
    }

    const rootElement = rootRichTextElement ?? element;
    const components = getUsedContentComponents(
      element._editorState.getCurrentContent(),
      rootElement.contentComponents,
    );
    const componentsResult = await traverseComponentsAsync(
      components,
      predicate,
      elementPath,
      rootElement,
    );
    if (componentsResult.found) {
      return componentsResult;
    }
  }

  return {
    found: false,
    path: currentPath,
  };
}

async function traverseComponentsAsync(
  components: ReadonlyArray<IContentComponent>,
  predicate: (
    el: ICompiledContentItemElementData,
    componentPath?: Immutable.List<IElementPathItem>,
  ) => Promise<boolean>,
  currentPath: Immutable.List<IElementPathItem>,
  rootRichTextElement: IRichTextItemElement,
): Promise<IFindElementResult> {
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    assert(component, () => `Component at index ${i} is falsy. Value: ${component}`);

    const elementsResult = await traverseElementsAsync(
      component.elements,
      predicate,
      currentPath,
      {
        itemId: component.id,
        typeId: component.contentTypeId,
      },
      rootRichTextElement,
    );

    if (elementsResult.found) {
      return elementsResult;
    }
  }

  return {
    found: false,
    path: currentPath,
  };
}

export const traverseFindElementAsync = async (
  editedItem: IEditedContentItem,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  predicate: (
    el: ICompiledContentItemElementData,
    componentPath: Immutable.List<IElementPathItem>,
  ) => Promise<boolean>,
  rootRichTextElement?: IRichTextItemElement,
): Promise<IFindElementResult> => {
  const path = Immutable.List<IElementPathItem>();
  return await traverseElementsAsync(
    variantElements,
    predicate,
    path,
    {
      typeId: editedItem.editedContentItemTypeId,
      itemId: editedItem.id,
    },
    rootRichTextElement,
  );
};
