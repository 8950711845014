import { InnovationLabFeatureStatus } from '../types/InnovationLabFeatureStatus.ts';

export const isInnovationLabFeatureOnlyForEmployees = (
  status: InnovationLabFeatureStatus,
): boolean => status === InnovationLabFeatureStatus.EmployeeOnly;

export const isInnovationLabFeatureAvailable = (status: InnovationLabFeatureStatus): boolean => {
  switch (status) {
    case InnovationLabFeatureStatus.Available:
    case InnovationLabFeatureStatus.EmployeeOnly:
      return true;
    default:
      return false;
  }
};
