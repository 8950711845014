import { shareOldIconName } from '../../../../../../../_shared/constants/iconConventions.ts';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { DropDownOptionsGroup } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FilterActionsMenuText } from '../../constants/filterActionsMenuConstants.ts';
import { FilterAction } from '../../constants/listingFilterConstants.ts';
import { FilterActionsMenuItem } from './FilterActionsMenuItem.tsx';

type Props = {
  readonly canSaveCurrentFilter?: boolean;
  readonly onActionClick: (action: FilterAction) => void;
};

export const FilterActionsMainMenu = ({ canSaveCurrentFilter, onActionClick }: Props) => {
  const handleSaveClick = () => onActionClick(FilterAction.SaveFilter);
  const handleShareClick = () => onActionClick(FilterAction.ShareFilter);

  return (
    <DropDownOptionsGroup>
      <FilterActionsMenuItem
        iconName={IconName.PlusSquare}
        text={FilterActionsMenuText[FilterAction.SaveFilter]}
        disabled={!canSaveCurrentFilter}
        disabledTooltip="Filter is already saved"
        withSubmenu
        onClick={handleSaveClick}
        dataAttributes={getDataUiActionAttribute(DataUiAction.Save)}
      />
      <FilterActionsMenuItem
        iconName={shareOldIconName}
        text={FilterActionsMenuText[FilterAction.ShareFilter]}
        withSubmenu
        onClick={handleShareClick}
        dataAttributes={getDataUiActionAttribute(DataUiAction.Share)}
      />
    </DropDownOptionsGroup>
  );
};
