import { INumberTypeElement } from '../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import {
  EmptyNumberItemElement,
  INumberItemElement,
} from '../../models/contentItemElements/NumberItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createNumberItemElement: ItemElementCreator<INumberTypeElement, INumberItemElement> = (
  numberTypeElement,
  omitDefaultValue,
): INumberItemElement => ({
  ...EmptyNumberItemElement,
  elementId: numberTypeElement.elementId,
  value: omitDefaultValue ? EmptyNumberItemElement.value : numberTypeElement.defaultValue,
});
