import React, { PropsWithChildren, useMemo } from 'react';
import { CardVariant } from './variant.ts';

type CardContextState = Readonly<{
  variant: CardVariant;
}>;

export const CardContext = React.createContext<CardContextState>({ variant: 'default' });

export const CardContextProvider = ({ variant, children }: PropsWithChildren<CardContextState>) => {
  const contextValue = useMemo(
    () => ({
      variant,
    }),
    [variant],
  );

  return <CardContext.Provider value={contextValue}>{children}</CardContext.Provider>;
};
