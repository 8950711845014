import { Collection } from '@kontent-ai/utils';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { IPreviewConfiguration } from '../models/PreviewConfiguration.type.ts';
import { anySpacesOptionId } from './getSpacesOptions.ts';

export const filterPreviewConfigurationToExistingSpaces = (
  configuration: IPreviewConfiguration,
  spaces: SpacesMap,
): IPreviewConfiguration => ({
  spaceDomains: configuration.spaceDomains.filter((s) => spaces.has(s.spaceId)),
  previewUrlPatterns: new Map(
    Collection.getEntries(configuration.previewUrlPatterns).map(([contentTypeId, patterns]) => [
      contentTypeId,
      patterns.map((pattern) => ({
        ...pattern,
        spaces: new Set(
          [...pattern.spaces].filter(
            (spaceId) => spaceId === anySpacesOptionId || spaces.has(spaceId),
          ),
        ),
      })),
    ]),
  ),
});
