import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { useEffect } from 'react';
import { IContentItemVariantReference } from '../../../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { getEditedContentItemType } from '../../../../../applications/itemEditor/selectors/getEditedContentItemType.ts';
import { Languages } from '../../../../../data/models/languages/Language.ts';
import {
  getAllLanguagesWithDefaultSuffix,
  getDefaultLanguage,
} from '../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { trackUserEventWithData } from '../../../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../constants/variantIdValues.ts';
import { useDispatch } from '../../../../hooks/useDispatch.ts';
import { useSelector } from '../../../../hooks/useSelector.ts';
import { INonLocalizableElementsWarningType } from '../../../../models/TrackUserEventData.ts';
import { getSelectedLanguageId } from '../../../../selectors/getSelectedLanguageId.ts';
import { isScheduledToPublishWorkflowStep } from '../../../../utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../utils/contentItemVariantUtils.ts';
import {
  ContentItemVariantWithLanguageName,
  getContentItemVariantsWithLanguageNames,
} from '../../../../utils/getContentItemVariantsWithLanguageNames.tsx';
import { DependentsScheduledEarlierQuickTip } from '../../components/Elements/DependentsScheduledEarlierQuickTip.tsx';
import { ModalSection } from '../../components/ModalSection.tsx';
import { ScheduleMethod } from '../../constants/scheduleMethods.ts';

const getVariantsScheduledEarlier = memoize.maxOne(
  (
    variants: Immutable.Map<Uuid, IContentItemVariantReference>,
    languages: Languages,
    projectId: Uuid,
    scheduledAt: DateTimeStamp | null,
  ): ReadonlyArray<ContentItemVariantWithLanguageName> => {
    const contentItemVariantsWithLanguageNames = getContentItemVariantsWithLanguageNames(
      variants,
      languages,
      projectId,
    );

    return contentItemVariantsWithLanguageNames.filter((variant) => {
      if (
        !scheduledAt ||
        !variant?.assignment.scheduledToPublishAt ||
        !isScheduledToPublishWorkflowStep(variant.assignment.workflowStatus)
      ) {
        return false;
      }

      return new Date(scheduledAt) > new Date(variant.assignment.scheduledToPublishAt);
    });
  },
);

export const PublishingQuickTipSection = () => {
  const dispatch = useDispatch();
  const defaultLanguage = useSelector(getDefaultLanguage);

  const shouldDisplayQuickTip = useSelector((s): boolean => {
    const isEditingDefaultVariant = getSelectedLanguageId(s) === DefaultVariantId;
    const isDefaultPublished = isVariantPublished(s.contentApp.editedContentItemVariant);
    const isScheduling =
      s.contentApp.changeWorkflowStepModalData.scheduleMethod === ScheduleMethod.Schedule;

    if (!isEditingDefaultVariant || isDefaultPublished || !isScheduling) {
      return false;
    }

    return getEditedContentItemType(s)?.contentElements.some((el) => el.isNonLocalizable) ?? false;
  });

  const dependentVariantsScheduledEarlier = useSelector((s) =>
    getVariantsScheduledEarlier(
      s.contentApp.contentItemVariants,
      getAllLanguagesWithDefaultSuffix(s.data.languages),
      s.sharedApp.currentProjectId,
      s.contentApp.changeWorkflowStepModalData.scheduledToPublishAt,
    ),
  );

  const displayWarning = shouldDisplayQuickTip && dependentVariantsScheduledEarlier.length;

  useEffect(() => {
    if (displayWarning) {
      dispatch(
        trackUserEventWithData(TrackedEvent.NonLocalizableElementsWarningDisplayed, {
          type: INonLocalizableElementsWarningType.DependentVariantsScheduledEarlierQuickTip,
        }),
      );
    }
  }, [displayWarning]);

  return displayWarning ? (
    <ModalSection>
      <DependentsScheduledEarlierQuickTip
        defaultLanguageName={defaultLanguage.name}
        dependentVariants={dependentVariantsScheduledEarlier}
      />
    </ModalSection>
  ) : null;
};
