import { isItem } from '../../VerticalMenu/utils/utils.tsx';
import { ISelectItem, ISelectSection, ItemId } from '../types.ts';

export const getItemIds = <TItem extends ISelectItem<TItem>>(
  items: ReadonlyArray<ISelectSection<TItem> | TItem>,
): ReadonlyArray<ItemId> =>
  items.reduce<ItemId[]>((result, item) => {
    if (isItem(item)) {
      result.push(item.id);
    }

    if (item.items) {
      const keys = getItemIds(item.items);

      result.push(...keys);
    }

    return result;
  }, []);
