import { TransitionFn, animated } from '@react-spring/web';
import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock, { MoveFocusInside } from 'react-focus-lock';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../utils/func/compose.ts';
import { stopPropagation } from '../../utils/func/functionalTools.ts';
import { HotkeysHandler, HotkeysMode } from '../Hotkeys/HotkeysHandler.tsx';

type Props = {
  readonly dialogClass?: string;
  readonly underlayClass?: string;
  readonly underlayDataAttribute: DataUiElement;
  readonly underlayTransitions?: TransitionFn<boolean, { background: any }>;
  readonly onClose: () => void;
};

export const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  dialogClass,
  underlayClass,
  underlayDataAttribute,
  underlayTransitions,
  onClose,
  children,
}) => {
  const underlayRef = useRef<HTMLDivElement>(null);
  const [clickStartedOnUnderlay, setClickStartedOnUnderlay] = useState(false);

  const onUnderlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>): void => {
      // Do not let the event out of the modal
      e.stopPropagation();
      if (clickStartedOnUnderlay) {
        setClickStartedOnUnderlay(false);
        onClose();
      }
    },
    [clickStartedOnUnderlay, onClose],
  );

  const onMouseDown = useCallback((event: SyntheticEvent<HTMLDivElement>): void => {
    const target = event.target as Node;
    const underlay = underlayRef.current;

    setClickStartedOnUnderlay(!!underlay && underlay.isEqualNode(target));
  }, []);

  const whitelistElement = useCallback((activeElement: HTMLElement): boolean => {
    const underlay = underlayRef.current;
    return !!underlay && underlay.contains(activeElement);
  }, []);

  const modalContent = (
    <ReactFocusLock whiteList={whitelistElement}>
      <HotkeysHandler
        handlers={{
          onEscape: compose(onClose, stopPropagation),
        }}
        mode={HotkeysMode.Dual}
      >
        <MoveFocusInside>
          <div
            aria-modal
            className={dialogClass}
            // We need to stop mouse events from the modal content reaching underlay to make sure that its life cycle is consistent
            onClick={stopPropagation}
            onMouseDown={stopPropagation}
            role="dialog"
            tabIndex={0}
          >
            {children}
          </div>
        </MoveFocusInside>
      </HotkeysHandler>
    </ReactFocusLock>
  );

  const modal = underlayTransitions ? (
    underlayTransitions(
      (style, item) =>
        item && (
          <animated.div
            style={style}
            ref={underlayRef}
            className={underlayClass}
            onClick={onUnderlayClick}
            onMouseDown={onMouseDown}
            {...getDataUiElementAttribute(underlayDataAttribute)}
          >
            {modalContent}
          </animated.div>
        ),
    )
  ) : (
    <div
      ref={underlayRef}
      className={underlayClass}
      onClick={onUnderlayClick}
      onMouseDown={onMouseDown}
      {...getDataUiElementAttribute(underlayDataAttribute)}
    >
      {modalContent}
    </div>
  );

  const body = document.querySelector('body');
  return body ? createPortal(modal, body) : null;
};
