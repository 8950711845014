import classNames from 'classnames';
import { forwardRef, useContext } from 'react';
import { DraggedFilesContext } from '../../../../../../../_shared/components/DragDrop/DraggedFilesContext.tsx';
import { DeferAutoScrollCssClass } from '../../../../../../../_shared/utils/autoScrollUtils.ts';

interface ICustomElementSandboxIframe {
  readonly className: string | undefined;
  readonly heightInitialized: boolean;
  readonly height: number;
  readonly src: string;
}

export const CustomElementSandboxIframe = forwardRef<
  HTMLIFrameElement,
  ICustomElementSandboxIframe
>(({ className, heightInitialized, height, src }, ref) => {
  const { isDraggingFiles } = useContext(DraggedFilesContext);

  return (
    <iframe
      css={isDraggingFiles ? 'pointer-events: none' : undefined}
      className={classNames(className, {
        [DeferAutoScrollCssClass]: !heightInitialized,
      })}
      height={height}
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation"
      ref={ref}
      src={src}
      title="Custom element"
      width="100%"
    />
  );
});
CustomElementSandboxIframe.displayName = 'CustomElementSandboxIframe';
