import { ILanguage, createLanguage } from '../../../../data/models/languages/Language.ts';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import { addInactiveLanguageSuffix } from '../../localization/utils/languageUtils.ts';
import { LanguageOption } from '../components/roleBuilder/LanguagesSelector.tsx';
import { roleBuilderAnyLanguageOptionId } from '../constants/roleBuilder.ts';

type DisabledTooltipParams = {
  readonly areLanguageRolesEnabled: boolean;
  readonly isSelected: boolean;
  readonly isSelectedSomewhereElse: boolean;
  readonly languageId: string;
};

const getDisabledTooltip = (params: DisabledTooltipParams): string | undefined => {
  if (params.isSelected) {
    return undefined;
  }

  if (!params.areLanguageRolesEnabled && params.languageId !== roleBuilderAnyLanguageOptionId) {
    return 'Assign a single role to “Any language” to save your changes.';
  }

  if (params.isSelectedSomewhereElse) {
    return 'You’ve already selected this language in other role.';
  }

  return undefined;
};

type Params = {
  readonly anyLanguageOptionName: string;
  readonly areLanguageRolesEnabled: boolean;
  readonly languages: ReadonlyArray<ILanguage>;
  readonly selectedLanguageOptionIds: ReadonlySet<Uuid>;
  readonly userRole: IProjectContributorRole;
};

export const getRoleBuilderLanguageOptions = ({
  anyLanguageOptionName,
  areLanguageRolesEnabled,
  languages,
  selectedLanguageOptionIds,
  userRole,
}: Params): ReadonlyArray<LanguageOption> => {
  const anyLanguage: ILanguage = createLanguage({
    id: roleBuilderAnyLanguageOptionId,
    inactive: false,
    name: anyLanguageOptionName,
  });

  return [
    ...[anyLanguage, ...languages]
      .filter((language) => userRole.languageIds.includes(language.id) || !language.inactive)
      .map((language) => {
        const isSelected = userRole.languageIds.includes(language.id);
        const isSelectedSomewhereElse = selectedLanguageOptionIds.has(language.id);

        const disabledTooltipText = getDisabledTooltip({
          areLanguageRolesEnabled,
          isSelected,
          isSelectedSomewhereElse,
          languageId: language.id,
        });

        return {
          disabledTooltipText,
          id: language.id,
          inactive: language.inactive,
          label: addInactiveLanguageSuffix(language).name,
        };
      }),
  ];
};
