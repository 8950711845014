import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ContentTypeUsageServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { Data_ContentTypesUsage_Success } from '../../../constants/dataActionTypes.ts';
import {
  IContentTypeUsage,
  getContentTypeUsageFromServerModel,
} from '../../../models/contentModelsApp/contentTypes/ContentTypeUsage.ts';

type StateType = Immutable.Map<Uuid, IContentTypeUsage>;

const initialState: StateType = Immutable.Map();

export function byId(state: StateType = initialState, action: Action): StateType {
  switch (action.type) {
    case Data_ContentTypesUsage_Success: {
      const contentTypeUsage = action.payload.data.reduce(
        (reduced: Immutable.Map<Uuid, IContentTypeUsage>, item: ContentTypeUsageServerModel) =>
          reduced.set(item.typeId, getContentTypeUsageFromServerModel(item)),
        Immutable.Map(),
      );
      return contentTypeUsage;
    }

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
