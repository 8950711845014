import Immutable from 'immutable';
import { ItemColumnCode } from '../_shared/constants/itemColumnCode.ts';
import { ContentItemListingColumnFilterStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';
import { IColumnVisibility } from '../applications/contentInventory/content/features/ContentItemInventory/types/ColumnVisibility.type.ts';

function getStorageKey(projectId: Uuid, userId: Uuid): string {
  return `${ContentItemListingColumnFilterStorageKey}_${projectId}_${userId}`;
}

interface IListingColumnsFilter {
  readonly hiddenColumns: Immutable.Set<ItemColumnCode>;
}

const toListingColumnsFilterValue = (hiddenColumns: Immutable.Set<ItemColumnCode>): string =>
  JSON.stringify({ hiddenColumns } as IListingColumnsFilter);

export interface IContentListingHiddenColumnsStorage {
  readonly save: (columnVisibility: IColumnVisibility, projectId: Uuid, userId: Uuid) => void;
  readonly load: (projectId: Uuid, userId: Uuid) => Immutable.Set<ItemColumnCode>;
}

export const contentListingColumnsFilterStorage: IContentListingHiddenColumnsStorage = {
  save(columnVisibility: IColumnVisibility, projectId: Uuid, userId: Uuid): void {
    const hiddenColumns = contentListingColumnsFilterStorage.load(projectId, userId);
    if (columnVisibility.visible) {
      const updatedColumns = hiddenColumns
        .filter((column: ItemColumnCode) => column !== columnVisibility.columnCode)
        .toSet();
      localStorage.set(
        getStorageKey(projectId, userId),
        toListingColumnsFilterValue(updatedColumns),
      );
    } else {
      const updatedColumns = hiddenColumns.add(columnVisibility.columnCode);
      localStorage.set(
        getStorageKey(projectId, userId),
        toListingColumnsFilterValue(updatedColumns),
      );
    }
  },

  load(projectId: Uuid, userId: Uuid): Immutable.Set<ItemColumnCode> {
    try {
      const data = localStorage.get(getStorageKey(projectId, userId)) ?? '';
      const parsedData = JSON.parse(data) as IListingColumnsFilter;
      return parsedData?.hiddenColumns ? Immutable.Set(parsedData.hiddenColumns) : Immutable.Set();
    } catch {
      return Immutable.Set();
    }
  },
};
