import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  contentTypeCreatorInitFinished,
  contentTypeCreatorInitStarted,
} from '../contentTypesActions.ts';

interface IDeps {
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSnippets: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createInitContentTypeCreatorAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(contentTypeCreatorInitStarted());

    await Promise.all([
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
      dispatch(deps.loadSnippets(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
    ]);

    dispatch(contentTypeCreatorInitFinished());
  };
