import { EditorState } from 'draft-js';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { SmartLinkPlugin } from '../SmartLinkPlugin.tsx';
import { getSmartLinkTargetSelection } from './editorSmartLinkUtils.ts';

export const editorSmartLinkApi: EditorApiImplementation<SmartLinkPlugin> = {
  async setSmartLinkTargetSelection(
    _,
    editorState,
    command,
    contentItemIdentifier,
    rootRichTextElementId,
  ) {
    const content = editorState.getCurrentContent();

    const selection = await getSmartLinkTargetSelection(
      content,
      command,
      contentItemIdentifier,
      rootRichTextElementId,
    );

    return EditorState.forceSelection(editorState, selection);
  },
};
