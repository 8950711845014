import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import {
  EntityIdOperationInput,
  ItemVariantIdOperationInput,
} from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedIdsResult,
  isCumulatedIdsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type CategorizeItemParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: 'CategorizeItem';
    readonly inputs: {
      readonly itemVariantId: ItemVariantIdOperationInput;
      readonly taxonomyId: EntityIdOperationInput;
    };
  };

export const createCategorizeItemParams = (
  itemVariantId: ContentItemId,
  taxonomyId: Uuid,
  trackingData: ElementOperationTrackingData,
): CategorizeItemParams => ({
  actionName: 'CategorizeItem',
  type: 'multiple-inputs-request-v1',
  inputs: {
    itemVariantId: {
      type: 'item-variant-id',
      value: {
        itemId: itemVariantId.itemId,
        variantId: itemVariantId.variantId,
      },
    },
    taxonomyId: {
      type: 'entity-id',
      value: {
        entityId: taxonomyId,
        entityTypeName: 'taxonomy',
      },
    },
  },
  trackingData,
});

export type CategorizeItemResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedIdsResult>;

export const isCategorizeItemMessage = (
  message: OperationResponseMessage,
): message is CategorizeItemResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedIdsOperationResponseMessage(message) || isErrorOperationMessage(message);
