import { Collection } from '@kontent-ai/utils';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  matchPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { archiveTemporaryContentItem } from '../actions/thunkContentItemEditingActions.ts';

export const EnsureTemporaryItemDeletion = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const temporaryItem = useSelector((s) => s.contentApp.editorUi.temporaryItem);

  useEffect(() => {
    const unsubscribe = history.listen((location) => {
      if (!temporaryItem || temporaryItem.itemState !== TemporaryContentItemState.Loaded) return;
      if (
        isLeavingTheEditedContentItemVariant(
          location.pathname,
          temporaryItem.itemId,
          temporaryItem.variantId,
        )
      ) {
        dispatch(archiveTemporaryContentItem(temporaryItem));
        unsubscribe();
      }
    });

    return unsubscribe;
  }, [history, temporaryItem]);

  return null;
};

const isLeavingTheEditedContentItemVariant = (
  pathname: string,
  tempItemId: Uuid,
  tempVariantId: Uuid,
): boolean => {
  const match = matchPath<ContentItemRouteParams<string>>(pathname, ContentItemRoute);
  if (!match) return true;
  const lastContentItemId = Collection.getLast(parseContentItemIds(match.contentItemIds));
  return tempItemId !== lastContentItemId || tempVariantId !== match.variantId;
};
