export const emptyNameErrorMessage = 'Provide a collection name';
export const creatorIsOpenedDuringSave =
  'Cannot save collections. Confirm changes to the collection first.';

// save failed notification bar errors
export const failedToSaveCollections =
  'We couldn’t save your collections. Please try again. If this keeps happening, let us know';
export const collectionsWereEdited =
  'The collections have been edited by someone else. Refresh the screen and try again.';
export const collectionsConfigurationIsNotValid =
  'We couldn’t save your changes. Maybe you deleted a used collection? Refresh to try again.';

// delete disabled states
export const loadingCollectionUsage = 'Checking if this collection is used…';
export const unableToLoadCollectionUsage =
  'We couldn’t check if this collection is used. Refresh the page to try again.';
export const defaultCollectionCannotBeDeleted = 'You can’t delete the default collection.';
export const usedCollectionCannotBeDeleted =
  'This collection is used by some assets or content items.';
export const usedCollectionCannotBeDeletedSpacesInCollectionsVersion =
  'This collection is used by some spaces, assets or content items.';
