import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventData } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import {
  Project_Delete_Failed,
  Project_Delete_Finished,
  Project_Delete_Started,
} from '../../constants/projectActionTypes.ts';

interface IDeleteProjectCreatorDependencies {
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEvent: TrackUserEventData;
}

const deletingProjectStarted = (projectId: Uuid) =>
  ({
    type: Project_Delete_Started,
    payload: {
      projectId,
    },
  }) as const;

const deletingProjectFinished = (projectName: string) =>
  ({
    type: Project_Delete_Finished,
    payload: {
      projectName,
    },
  }) as const;

const deletingProjectFailed = (projectName: string, projectId: Uuid) =>
  ({
    type: Project_Delete_Failed,
    payload: {
      projectName,
      projectId,
    },
  }) as const;

export type DeleteProjectActionsType = ReturnType<
  typeof deletingProjectStarted | typeof deletingProjectFailed | typeof deletingProjectFinished
>;

export const deleteProjectCreator =
  (deps: IDeleteProjectCreatorDependencies) =>
  (projectId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const project = getState().data.projects.byId.get(projectId);
    if (!project) {
      throw InvariantException(`No project with id ${projectId} was found.`);
    }

    dispatch(deletingProjectStarted(projectId));

    try {
      await deps.projectRepository.deleteProject(projectId);
      await Promise.all([
        dispatch(deps.loadUserProjectsInfo()),
        dispatch(deps.loadSubscriptionUsage(project.subscriptionId)),
        dispatch(deps.loadAdministratedSubscriptions()),
        dispatch(deps.loadProjects()),
      ]);
      dispatch(deletingProjectFinished(project.projectName));

      dispatch(deps.trackUserEvent(TrackedEvent.ProjectDeleted));
    } catch (error) {
      dispatch(deletingProjectFailed(project.projectName, projectId));
      throw InvariantException(
        `Could not delete the project because of the following error: ${error}`,
      );
    }
  };
