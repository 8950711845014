import { Action } from '../../../../../@types/Action.type.ts';
import { OperationStatus } from '../../../../../_shared/models/OperationStatus.ts';
import {
  Environment_DeleteEnvironment_Failed,
  Environment_DeleteEnvironment_Finished,
  Environment_DeleteEnvironment_Started,
  Environment_SwapEnvironment_Failed,
  Environment_SwapEnvironment_Finished,
  Environment_SwapEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';
import { EnvironmentOperation } from '../../constants/environmentOperation.ts';
import { IEnvironmentSavingStatus } from '../../models/IEnvironmentSavingStatus.type.ts';

const initialState: IEnvironmentSavingStatus = {
  actionType: EnvironmentOperation.Default,
  environmentName: '',
  status: OperationStatus.Success,
};

export const savingStatus = (
  state: IEnvironmentSavingStatus = initialState,
  action: Action,
): IEnvironmentSavingStatus => {
  switch (action.type) {
    case Environment_DeleteEnvironment_Started: {
      return {
        ...state,
        actionType: EnvironmentOperation.Delete,
        environmentName: action.payload.environmentName,
        status: OperationStatus.Pending,
      };
    }

    case Environment_DeleteEnvironment_Finished: {
      return {
        ...state,
        actionType: EnvironmentOperation.Delete,
        environmentName: action.payload.environmentName,
        status: OperationStatus.Success,
      };
    }

    case Environment_DeleteEnvironment_Failed: {
      return {
        ...state,
        actionType: EnvironmentOperation.Delete,
        environmentName: action.payload.environmentName,
        status: OperationStatus.Failed,
      };
    }

    case Environment_SwapEnvironment_Started: {
      return {
        ...state,
        actionType: EnvironmentOperation.Swap,
        status: OperationStatus.Pending,
      };
    }

    case Environment_SwapEnvironment_Finished: {
      return {
        ...state,
        actionType: EnvironmentOperation.Swap,
        status: OperationStatus.Success,
      };
    }

    case Environment_SwapEnvironment_Failed: {
      return {
        ...state,
        actionType: EnvironmentOperation.Swap,
        status: OperationStatus.Failed,
      };
    }

    default: {
      return state;
    }
  }
};
