import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { IRole } from './IRole.ts';

export type RoleOption = IRole & ISelectItem<RoleOption>;

export const mapRoleToOption = (role: IRole): RoleOption => {
  return {
    ...role,
    label: role.name,
  };
};
