import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_LocalizedRoute_Left,
  WebSpotlight_NewNode_Linked,
  WebSpotlight_Node_Blurred,
  WebSpotlight_Node_Focused,
} from '../constants/webSpotlightActionTypes.ts';
import { NodeId } from '../types/nodeId.type.ts';

const initialState: NodeId = '';

export const focusedNodeId = (state = initialState, action: Action): NodeId => {
  switch (action.type) {
    case WebSpotlight_Node_Focused:
      return action.payload.nodeId;

    case WebSpotlight_LocalizedRoute_Left:
    case WebSpotlight_NewNode_Linked:
    case WebSpotlight_Node_Blurred:
      return initialState;

    default:
      return state;
  }
};
