export const isImmutableMap = <TValue, TKey>(arg: unknown): arg is Immutable.Map<TKey, TValue> =>
  Immutable.Map.isMap(arg);

export const isImmutableList = <TValue>(arg: unknown): arg is Immutable.List<TValue> =>
  Immutable.List.isList(arg);

export const isImmutableSet = <TValue>(arg: unknown): arg is Immutable.Set<TValue> =>
  Immutable.Set.isSet(arg);

export const isImmutableCollection = <TValue, TKey = unknown>(
  arg: unknown,
): arg is Immutable.Collection<TKey, TValue> =>
  isImmutableMap(arg) || isImmutableList(arg) || isImmutableSet(arg);
