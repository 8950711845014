import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { modalOpened } from '../../../../actions/sharedActions.ts';
import { ModalDialogType } from '../../../../constants/modalDialogType.ts';
import { createInitialChangeWorkflowStepModalDataForBulk } from '../../selectors/createInitialChangeWorkflowStepModalDataForBulk.ts';
import { OpenWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import { setInitialChangeWorkflowStepModalData } from '../changeWorkflowStepModalActions.ts';

export type IOpenBulkChangeWorkflowStepModal = (
  workflowAction: OpenWorkflowStepModalAction,
) => ThunkFunction;

export const openBulkChangeWorkflowStepModal =
  (workflowAction: OpenWorkflowStepModalAction): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const changeWorkflowStepModalData = createInitialChangeWorkflowStepModalDataForBulk(
      getState(),
      workflowAction,
    );

    assert(
      changeWorkflowStepModalData,
      () =>
        'openBulkChangeWorkflowStepModal.ts: Failed to create initial change workflow step modal data.',
    );

    dispatch(setInitialChangeWorkflowStepModalData(changeWorkflowStepModalData));
    dispatch(modalOpened(ModalDialogType.ChangeWorkflowStepListingDialog));
  };
