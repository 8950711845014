import { Placement } from '@kontent-ai/component-library/types';
import { BasePlacement, VirtualElement } from '@popperjs/core';
import getBasePlacement from '@popperjs/core/lib/utils/getBasePlacement';
import { GetPopperOffset } from './placementUtils.ts';

type ClientRect = Omit<DOMRectReadOnly, 'toJSON'>;

const defaultDomRect = { x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 };

// This method's core comes from inlinePositioning TippyJS plugin which is buggy, but the approach to is actually useful
// Source: https://github.com/atomiks/tippyjs/blob/master/src/plugins/inlinePositioning.ts
export function getInlineBoundingClientRect(
  currentBasePlacement: BasePlacement | null,
  reference: Element | VirtualElement,
): ClientRect {
  const boundingRect = reference.getBoundingClientRect();
  if (!(reference instanceof HTMLElement)) {
    return boundingRect;
  }

  const clientRects = Array.from(reference.getClientRects());

  // Not an inline element, or placement is not yet known
  if (clientRects.length < 2 || currentBasePlacement === null) {
    return boundingRect;
  }

  switch (currentBasePlacement) {
    case 'top':
    case 'bottom': {
      const firstRect = clientRects[0] ?? defaultDomRect;
      const lastRect = clientRects[clientRects.length - 1] ?? defaultDomRect;
      const isTop = currentBasePlacement === 'top';

      const top = firstRect.top;
      const bottom = lastRect.bottom;
      const left = isTop ? firstRect.left : lastRect.left;
      const right = isTop ? firstRect.right : lastRect.right;
      const width = right - left;
      const height = bottom - top;

      return {
        top,
        bottom,
        left,
        right,
        width,
        height,
        x: left,
        y: top,
      };
    }
    case 'left':
    case 'right': {
      const minLeft = Math.min(...clientRects.map((rects) => rects.left));
      const maxRight = Math.max(...clientRects.map((rects) => rects.right));
      const measureRects = clientRects.filter((rect) =>
        currentBasePlacement === 'left' ? rect.left === minLeft : rect.right === maxRight,
      );

      const firstMeasureRect = measureRects[0] ?? defaultDomRect;
      const top = firstMeasureRect.top;
      const lastMeasureRect = measureRects[measureRects.length - 1] ?? defaultDomRect;
      const bottom = lastMeasureRect.bottom;
      const left = minLeft;
      const right = maxRight;
      const width = right - left;
      const height = bottom - top;

      return {
        top,
        bottom,
        left,
        right,
        width,
        height,
        x: left,
        y: top,
      };
    }
    default: {
      return boundingRect;
    }
  }
}

function getSkiddingAdjustmentTowardsTarget(
  targetRect: DOMRect,
  inlineTargetRect: ClientRect,
  placement: Placement,
): number {
  switch (placement) {
    case 'top-start':
    case 'bottom-start':
      return inlineTargetRect.left - targetRect.left;

    case 'top':
    case 'bottom':
      return (
        inlineTargetRect.left +
        inlineTargetRect.width / 2 -
        (targetRect.left + targetRect.width / 2)
      );

    case 'top-end':
    case 'bottom-end':
      return inlineTargetRect.right - targetRect.right;

    case 'left-start':
    case 'right-start':
      return inlineTargetRect.top - targetRect.top;

    case 'left':
    case 'right':
      return (
        inlineTargetRect.top +
        inlineTargetRect.height / 2 -
        (targetRect.top + targetRect.height / 2)
      );

    case 'left-end':
    case 'right-end':
      return inlineTargetRect.bottom - targetRect.bottom;

    default:
      return 0;
  }
}

export const getOffsetToInlineTarget = (
  defaultOffset: ReturnType<GetPopperOffset> = [0, 0],
  placement: Placement,
  targetRef: React.RefObject<Element>,
): ReturnType<GetPopperOffset> => {
  const target = targetRef.current;
  if (!target) {
    return defaultOffset;
  }

  const targetRect = target.getBoundingClientRect();
  const basePlacement = getBasePlacement(placement);
  const inlineTargetRect = getInlineBoundingClientRect(basePlacement, target);
  const skiddingAdjustment = getSkiddingAdjustmentTowardsTarget(
    targetRect,
    inlineTargetRect,
    placement,
  );

  const [skidding, distance] = defaultOffset;
  return [skidding + skiddingAdjustment, distance];
};
