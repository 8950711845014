import React from 'react';
import { DragSource } from '../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { finishCollectionDragging, startCollectionDragging } from '../../actions/baseActions.ts';
import { CollectionBar, ICollectionBarBaseProps } from './CollectionBar.tsx';

export const DraggableCollectionBar: React.FC<ICollectionBarBaseProps> = (props) => {
  const dispatch = useDispatch();

  const onCollectionPickedUp = () => dispatch(startCollectionDragging(props.collection.id));
  const onCollectionDropped = () => dispatch(finishCollectionDragging());

  return (
    <DragSource
      parentId=""
      sourceId={props.collection.id}
      onDragStart={onCollectionPickedUp}
      onDragEnd={onCollectionDropped}
      renderDraggable={(connectDragSource, isDragging) => (
        <CollectionBar {...props} connectDragSource={connectDragSource} isDragging={isDragging} />
      )}
      renderPreview={() => <CollectionBar {...props} isDragging={false} />}
      type={DndTypes.Collection_Move}
    />
  );
};

DraggableCollectionBar.displayName = 'DraggableCollectionBar';
