import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ContentState, EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback, useMemo } from 'react';
import { Fly } from '../../../../../../../_shared/components/Fly.tsx';
import { TextToParagraph } from '../../../../../../../_shared/components/StringRenderers.tsx';
import { StructuredFeedbackButtons } from '../../../../../../../_shared/features/StructuredFeedback/containers/StructuredFeedbackButtons.tsx';
import {
  FeedbackReasons,
  SpecificFeedbackReason,
  createFeedbackReasons,
  getAiFeedbackFeature,
} from '../../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { DataDraftJsAttributes } from '../../../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { renderDatetimeString } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { formatUserName } from '../../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { AiAssistantId } from '../../../../../../../paperModels/aiReview/constants/commentsConstants.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { setRichTextClipboard } from '../../../../../../richText/actions/thunkRichTextActions.ts';
import { CommentViewer } from '../../../../../../richText/editors/comment/CommentViewer.tsx';
import { EmptyMetadata } from '../../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { getCommentClassName } from '../../../../../../richText/plugins/comments/api/editorCommentStyleUtils.ts';
import { ElementAttributes } from '../../../../../constants/elementAttributes.ts';
import { isComment } from '../../../../../models/comments/Comment.ts';
import { ICommentThreadItem } from '../../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../../models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { EmptyContentComponents } from '../../../../../models/contentItem/ContentComponent.ts';

export interface ICommentThreadItemContentProps {
  readonly commentThread: ICommentThread;
  readonly commentThreadItem: ICommentThreadItem;
  readonly suggestionApprovedBy: IProjectContributor | null;
  readonly isLastApprovedSuggestion?: boolean;
  readonly isApproveAnimating: boolean;
  readonly onApproveAnimationFinished?: () => void;
}

const CommentAnimation: React.FC<
  React.PropsWithChildren<
    Pick<ICommentThreadItemContentProps, 'commentThread' | 'onApproveAnimationFinished'>
  >
> = ({ children, commentThread, onApproveAnimationFinished }) => {
  const commentCssSelector = useMemo(() => {
    if (!commentThread.externalSegmentId) {
      return null;
    }

    const elementId = commentThread.elementId;
    const elementSelector = `.content-item-element[${ElementAttributes.ElementId}="${elementId}"]`;

    return `${elementSelector} span.${getCommentClassName(commentThread.externalSegmentId)}[${
      DataDraftJsAttributes.OffsetKey
    }]`;
  }, [commentThread]);

  if (!commentCssSelector) {
    return null;
  }

  return (
    <Fly to={commentCssSelector} duration={750} onFinished={onApproveAnimationFinished}>
      <div className="comment__fly">{children}</div>
    </Fly>
  );
};

const CommentContent: React.FC<{ readonly content: ContentState }> = ({ content }) => {
  const editorState = useMemo(() => EditorState.createWithContent(content), [content]);
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, state: EditorState) =>
      dispatch(setRichTextClipboard(e, state, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  return (
    <CommentViewer
      isViewOnly
      editorState={editorState}
      setRichTextClipboard={onSetRichTextClipboard}
    />
  );
};

CommentContent.displayName = 'CommentContent';

const ThreadItemContent: React.FC<Pick<ICommentThreadItemContentProps, 'commentThreadItem'>> = ({
  commentThreadItem,
}) => {
  if (isSuggestion(commentThreadItem)) {
    return <TextToParagraph text={commentThreadItem.suggestedText} />;
  }

  if (isComment(commentThreadItem)) {
    return <CommentContent content={commentThreadItem.content} />;
  }

  return null;
};

ThreadItemContent.displayName = 'ThreadItemContent';

const aiSuggestionFeedbackReasons: FeedbackReasons = [
  ...createFeedbackReasons([
    SpecificFeedbackReason.ReviewByGuidelinesResultMismatch,
    SpecificFeedbackReason.ReviewByGuidelinesSlow,
  ]),
];

export const CommentThreadItemContent: React.FC<ICommentThreadItemContentProps> = ({
  commentThread,
  commentThreadItem,
  isApproveAnimating,
  isLastApprovedSuggestion,
  onApproveAnimationFinished,
  suggestionApprovedBy,
}) => {
  const content = <ThreadItemContent commentThreadItem={commentThreadItem} />;
  const isApprovedSuggestion =
    isSuggestion(commentThreadItem) &&
    commentThreadItem.suggestionApprovedAt &&
    isLastApprovedSuggestion;

  const isAiSuggestion =
    commentThreadItem.createdBy === AiAssistantId && isSuggestion(commentThreadItem);

  return (
    <Stack className="comment__message" spacing={Spacing.XS}>
      {isSuggestion(commentThreadItem) && (
        <Tooltip
          tooltipText={
            (isApprovedSuggestion &&
              renderDatetimeString(commentThreadItem.suggestionApprovedAt)) ||
            undefined
          }
          placement="top"
        >
          <Box
            className={classNames('comment__suggestion-guideline', {
              'comment__suggestion-guideline--is-approved': isApprovedSuggestion,
            })}
          >
            {isApprovedSuggestion
              ? `${formatUserName(suggestionApprovedBy)} approved suggestion:`
              : 'Suggested to replace content with:'}
          </Box>
        </Tooltip>
      )}
      <Box
        className={classNames('comment__message-body', {
          'comment__message-body--is-suggestion': isSuggestion(commentThreadItem),
        })}
      >
        {isApproveAnimating && (
          <CommentAnimation
            commentThread={commentThread}
            onApproveAnimationFinished={onApproveAnimationFinished}
          >
            {content}
          </CommentAnimation>
        )}
        {content}
      </Box>
      {isAiSuggestion && (
        <Box>
          <StructuredFeedbackButtons
            feature={getAiFeedbackFeature(AiActionName.ReviewByGuidelines)}
            feedbackReasons={aiSuggestionFeedbackReasons}
          />
        </Box>
      )}
    </Stack>
  );
};

CommentThreadItemContent.displayName = 'CommentThreadItemContent';
