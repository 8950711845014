import Immutable from 'immutable';
import { IPublishWarningsServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';

export interface IPublishWarnings {
  readonly itemsWithBrokenLinks: Immutable.Set<Uuid>;
  readonly itemsWithBrokenModulars: Immutable.Set<Uuid>;
}

export const getPublishWarningsFromServerModel = (
  serverModel: IPublishWarningsServerModel,
): IPublishWarnings => ({
  itemsWithBrokenLinks: Immutable.Set(serverModel.itemsWithBrokenLinks),
  itemsWithBrokenModulars: Immutable.Set(serverModel.itemsWithBrokenModulars),
});

export const hasAnyWarning = (publishWarnings: IPublishWarnings): boolean =>
  !publishWarnings.itemsWithBrokenLinks.isEmpty() ||
  !publishWarnings.itemsWithBrokenModulars.isEmpty();
