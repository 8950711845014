import { TaxonomyGroupFilterServerModel } from '../../../../repositories/serverModels/TaxonomyFilterServerModel.type.ts';

export function getTaxonomyGroupFilterServerModel(
  termIds: ReadonlyArray<Uuid>,
  uncategorizedTermId: Uuid,
): TaxonomyGroupFilterServerModel {
  return {
    termIds: termIds.filter((id) => id !== uncategorizedTermId),
    includeUncategorized: termIds.includes(uncategorizedTermId),
  };
}
