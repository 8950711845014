import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { TypeElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { ContentTypeSnippet_Editor_TypeElementCreated } from '../../constants/snippetActionTypes.ts';

interface IDeps {
  readonly createGuid: () => Uuid;
  readonly createDefaultTypeElementData: (elementType: TypeElementType) => IBaseTypeElementData;
}

const prepareNewTypeElement = (
  { createGuid, createDefaultTypeElementData }: IDeps,
  elementType: TypeElementType,
): IBaseTypeElementData => {
  const defaultTypeElement = createDefaultTypeElementData(elementType);
  return {
    ...defaultTypeElement,
    elementId: createGuid(),
  };
};

const newSnippetTypeElementCreated = (typeElementData: IBaseTypeElementData, position: number) =>
  ({
    type: ContentTypeSnippet_Editor_TypeElementCreated,
    payload: {
      typeElementData,
      position,
    },
  }) as const;

export type AddNewSnippetTypeElementActionsType = ReturnType<typeof newSnippetTypeElementCreated>;

export const createAddNewSnippetTypeElementAction =
  (deps: IDeps) =>
  (elementType: TypeElementType): ThunkFunction =>
  (dispatch, getState) => {
    const { typeElements } = getState().contentModelsApp.snippets.editor.editedContentTypeSnippet;

    const newTypeElement = prepareNewTypeElement(deps, elementType);
    const insertAfterElement = typeElements[typeElements.length - 1];
    const insertAtIndex = insertAfterElement ? typeElements.indexOf(insertAfterElement) : 0;

    dispatch(newSnippetTypeElementCreated(newTypeElement, insertAtIndex));
  };

export const createInsertNewSnippetTypeElementBeforeAction =
  (deps: IDeps) =>
  (elementType: TypeElementType, insertBeforeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch, getState) => {
    const { typeElements } = getState().contentModelsApp.snippets.editor.editedContentTypeSnippet;

    const newTypeElement = prepareNewTypeElement(deps, elementType);
    const insertAtIndex = typeElements.indexOf(insertBeforeElement) - 1;

    dispatch(newSnippetTypeElementCreated(newTypeElement, insertAtIndex));
  };
