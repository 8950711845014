import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';

type AiMenuActionItemProps = {
  readonly complementaryText?: string;
  readonly iconName?: IconName;
  readonly label: string;
  readonly onPress: () => void;
};

export const AiMenuActionItem: React.FC<AiMenuActionItemProps> = ({
  iconName,
  label,
  onPress,
  ...otherProps
}) => (
  <MenuItemWithMouseHover
    leadingElement={iconName && <Icon iconName={iconName} />}
    menuItemState="default"
    onPress={onPress}
    text={label}
    {...otherProps}
  />
);

AiMenuActionItem.displayName = 'AiMenuActionItem';
