import { Column, Row } from '@kontent-ai/component-library/Row';
import { px, spacingCard } from '@kontent-ai/component-library/tokens';
import React, { useCallback, useState } from 'react';
import {
  ICropRectangle,
  IImageTransformation,
} from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IImage } from '../../../../data/models/assets/Image.ts';
import { AssetLimitations } from '../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { ImageEditor } from './ImageEditor.tsx';
import { ImageEditorSidebar } from './ImageEditorSidebar.tsx';
import { Dimensions } from './types/ImageEditorTypes.type.ts';
import { calculateCurrentZoom, calculateMinZoom } from './utils/imageZoomUtils.ts';

type Props = {
  readonly image: IImage;
  readonly transformation: IImageTransformation;
  readonly onChange: (
    updateCallback: (oldTransformation: IImageTransformation) => IImageTransformation,
  ) => void;
  readonly onValidityChange: (areInputsValid: boolean) => void;
  readonly limitations: AssetLimitations;
};

const maxZoom = 1;

export const ImageTransformationEditor: React.FC<Props> = (props) => {
  const { onChange } = props;
  const [zoom, setZoom] = useState(
    calculateCurrentZoom({
      outputImageDimensions: props.transformation,
      rectangleDimensions: props.transformation.rect,
    }),
  );

  const minZoom = calculateMinZoom({
    originalImageDimensions: props.image,
    outputImageDimensions: props.transformation,
  });

  const onRectChange = useCallback(
    (rect: ICropRectangle) =>
      onChange((prev) => ({
        ...prev,
        rect,
      })),
    [onChange],
  );
  const onOutputDimensionsChange = (dimensions: Dimensions) => {
    setZoom(1);
    onChange((prev) => ({
      ...prev,
      ...dimensions,
      rect: {
        ...prev.rect,
        ...dimensions,
      },
    }));
  };

  return (
    <Row noWrap css="height: 100%">
      <Column>
        <ImageEditor
          css="height: 100%; width: 95%"
          rectangle={props.transformation.rect}
          onChange={onRectChange}
          imageSrc={props.image._downloadLink}
          zoom={zoom}
          maxZoom={maxZoom}
          minZoom={minZoom}
          onZoomChange={setZoom}
        />
      </Column>
      <Column width="content" css={`margin-bottom: -${px(spacingCard)}`}>
        <ImageEditorSidebar
          image={props.image}
          onValidityChange={props.onValidityChange}
          transformation={props.transformation}
          onDimensionsChange={onOutputDimensionsChange}
          zoom={zoom}
          maxZoom={maxZoom}
          minZoom={minZoom}
          onZoomChange={setZoom}
          limitations={props.limitations}
        />
      </Column>
    </Row>
  );
};

ImageTransformationEditor.displayName = 'ImageTransformationEditor';
