import React, { ComponentProps } from 'react';
import { useWatch } from 'react-hook-form';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import { joinWithOxfordComma } from '../../../../../_shared/utils/stringUtils.ts';
import { ApiKeyEnvironmentOption } from '../../components/ApiKeyDetail/ApiKeyEnvironmentOption.tsx';
import { ValidatedApiEnvironmentsSelector } from '../../components/ApiKeyDetail/ValidatedApiEnvironmentsSelector.tsx';
import {
  MapiKeyDisabledEnvironment,
  MapiKeyDisabledEnvironments,
} from '../../constants/ApiKeyDetailTemplates.ts';
import { EnvironmentOption } from '../../selectors/getSelectedEnvironmentOptions.ts';
import { getDisabledApiEnvironmentNames } from '../../utils/getDisabledApiEnvironmentNames.ts';
import { MapiKeyFormShape } from '../../validation/mapiKeyValidationConfig.ts';

type ValidatedMapiKeyEnvironmentsSelectorProps = {
  readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
  readonly canUpdateKey: boolean;
  readonly disabledTooltipText: string | undefined;
  readonly formProps: HookFormProps<MapiKeyFormShape>;
};

export const ValidatedMapiKeyEnvironmentsSelector: React.FC<
  ValidatedMapiKeyEnvironmentsSelectorProps
> = ({ allEnvironmentOptions, canUpdateKey, disabledTooltipText, formProps }) => {
  const [selectedEnvironments] = useWatch({
    control: formProps.control,
    name: ['environments'],
  });
  const disabledApiEnvironmentNames = getDisabledApiEnvironmentNames(
    allEnvironmentOptions,
    selectedEnvironments,
  );

  return (
    <ValidatedApiEnvironmentsSelector
      allEnvironmentOptions={allEnvironmentOptions}
      canUpdateKey={canUpdateKey}
      caption={getCaption(disabledApiEnvironmentNames)}
      disabledTooltipText={disabledTooltipText}
      formProps={formProps}
      fieldName="environments"
      renderSelectedEnvironmentOption={renderSelectedEnvironmentOption(canUpdateKey)}
    />
  );
};

const renderSelectedEnvironmentOption =
  (canUpdateApiKey: boolean) =>
  (
    environmentOption: EnvironmentOption,
    defaultTagProps: ComponentProps<typeof DefaultTag>,
  ): JSX.Element => (
    <ApiKeyEnvironmentOption
      canUpdateApiKey={canUpdateApiKey}
      hasError={!environmentOption.isApiEnabled}
      tooltipText={MapiKeyDisabledEnvironment(environmentOption.label)}
      defaultTagProps={defaultTagProps}
      environmentOption={environmentOption}
    />
  );

const getCaption = (disabledApiEnvironmentNames: ReadonlyArray<string>): string => {
  switch (disabledApiEnvironmentNames.length) {
    case 0:
      return '';
    case 1:
      return MapiKeyDisabledEnvironment(disabledApiEnvironmentNames);
    default:
      return MapiKeyDisabledEnvironments(joinWithOxfordComma(disabledApiEnvironmentNames));
  }
};

ValidatedMapiKeyEnvironmentsSelector.displayName = 'ValidatedMapiKeyEnvironmentsSelector';
