import { IAiGuidelinesResponseServerModel } from '../../repositories/serverModels/AiGuidelinesServerModels.type.ts';

export type AiGuidelines = {
  readonly id: Uuid;
  readonly name: string;
  readonly guidelines: string;
};

export const createAiGuidelinesFromServerModel = (
  serverModel: IAiGuidelinesResponseServerModel,
): AiGuidelines => ({
  id: serverModel.id,
  name: serverModel.name,
  guidelines: serverModel.guidelines,
});
