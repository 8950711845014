export const AuditLog_Listing_Left = 'AuditLog_Listing_Left';

export const AuditLog_Filter_Clear = 'AuditLog_Filter_Clear';

export const AuditLog_Filter_ActionTypeChanged = 'AuditLog_Filter_ActionTypeChanged';
export const AuditLog_Filter_ObjectTypeChanged = 'AuditLog_Filter_ObjectTypeChanged';
export const AuditLog_Filter_FromDateChanged = 'AuditLog_Filter_FromDateChanged';
export const AuditLog_Filter_ToDateChanged = 'AuditLog_Filter_ToDateChanged';

export const AuditLog_Filter_SearchPhraseChanged = 'AuditLog_Filter_SearchPhraseChanged';

export const AuditLog_Search_Started = 'AuditLog_Search_Started';
export const AuditLog_Search_Finished = 'AuditLog_Search_Finished';

export const AuditLog_ScrollState_Changed = 'AuditLog_ScrollState_Changed';
