import {
  CompiledTypeElementType,
  TypeElementType,
} from '../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { AssetFileTypeOption } from '../../applications/contentInventory/content/models/assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../applications/contentInventory/content/models/fileSizeOptions.ts';
import { CollectionSection } from '../../applications/itemEditor/features/NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../applications/itemEditor/features/NewContentItem/constants/contentTypeSection.ts';
import { ResourceType } from '../../applications/quickstart/models/ResourceType.ts';
import { TimeInterval } from '../../applications/unifiedMissionControl/constants/timeIntervals.ts';
import { WorkflowStage } from '../../applications/unifiedMissionControl/constants/workflowStage.ts';
import { AddButtonAction } from '../../applications/webSpotlight/types/SmartLinkSdkApi.ts';
import { PublishingState } from '../../data/constants/PublishingState.ts';
import { FindRightAssetTrackedEvent, TrackedEvent } from '../constants/trackedEvent.ts';
import { ByStatus } from '../constants/userListingFilter.ts';
import { StructuredFeedbackPayload } from '../features/StructuredFeedback/structuredFeedback.ts';
import { AiActionEventData } from './events/AiActionEventData.type.ts';
import { IColumnSettingsData } from './events/ColumnSettingsEventData.type.ts';
import { ContentItemEditingIntercomData } from './events/ContentItemEditingEventData.type.ts';
import {
  ICommentsIntercomData,
  IContentEntryElementRefreshedData,
  IContentEntryElementSaveConflictData,
  IContentEntryLockedElementOverwrittenData,
  IContentEntryNameOrElementsChangedIntercomData,
  IContentEntryOverwrittenEventData,
  IContentItemLinkCopiedIntercomData,
  IEditorStatisticsIntercomData,
  ILinkVisitedIntercomData,
} from './events/ContentItemEventData.type.ts';
import { ISavedFilterSelectedEventData } from './events/ContentItemFilterEventData.type.ts';
import { IFeatureIntercomData } from './events/FeatureEventData.type.ts';
import {
  FindRightAssetEventActionData,
  FindRightContentEventActionData,
  IFindRightContentEventData,
  IOpenEditingEventData,
  IOpenEditingFromYourContentEventData,
  ISortEventData,
} from './events/FindRightContentEventData.type.ts';
import { RTECommandEventData } from './events/RTECommandEventData.type.ts';
import { TasksUserEventData } from './events/TaskEventData.type.ts';
import { UmuxSurveyIntercomData } from './events/UmuxSurveyEventData.type.ts';
import { IUmuxFeedbackIntercomMessage } from './events/UserFeedbackEventData.type.ts';
import {
  CreateWorkflowEventData,
  DeleteWorkflowEventData,
  DocsVisitManageWorkflowData,
  RestoreWorkflowEventData,
  UpdateWorkflowEventData,
} from './events/WorkflowsEventData.type.ts';

export interface IAppLoadedEventData {
  readonly 'business-role': string;
  readonly 'business-type': string;
  readonly 'email-domain': string;
  readonly 'filled-business-role': string;
  readonly 'provided-company-name': string;
  readonly 'sign-up-date': string;
  readonly plan?: string;
}

export interface IClientTimeZoneData {
  readonly localTimeZoneId: string;
}

export enum ConfirmationDialogEventTypes {
  Cancel = 'cancel',
  Confirm = 'confirm',
}

export interface IConfirmationDialogEventData {
  readonly action: ConfirmationDialogEventTypes;
}

type AiImageTaggingEventData = {
  readonly action: AiImageTaggingAction;
  readonly matchedByTag: boolean;
};

export enum AiImageTaggingAction {
  TaggedAssetInserted = 'TaggedAssetInserted',
  TaggedAssetOpenedFromLibrary = 'TaggedAssetOpenedFromLibrary',
}

export enum AssetBulkActionEventTypes {
  Deleted = 'deleted',
  DeletedFolder = 'deletedFolder',
  MovedToCollection = 'movedToCollection',
  MovedToFolder = 'movedToFolder',
  MovedBackToCollection = 'movedBackToCollection',
  MovedBackToFolder = 'movedBackToFolder',
  Restored = 'restored',
  RestoredFolder = 'restoredFolder',
}

export interface IAssetBulkActionEventData {
  readonly action: AssetBulkActionEventTypes;
  readonly count: number;
}

export interface IAssetIntercomData {
  readonly 'asset-id': Uuid;
  readonly size?: number;
  readonly type: string;
}

export enum AssetDescriptionSource {
  GeneratedByAi = 'generated-by-ai',
  GeneratedByAiAndModified = 'generated-by-ai-and-modified',
}

export interface IAssetUpdatedData extends IAssetIntercomData {
  readonly 'default-language-description-source'?: AssetDescriptionSource;
}

export interface IAssetLimitsIntercomData {
  readonly 'element-id': Uuid;
  readonly 'file-size': number | null;
  readonly 'file-size-unit-option': FileSizeUnit;
  readonly 'file-type': AssetFileTypeOption;
  readonly 'max-height': number | null;
  readonly 'max-width': number | null;
  readonly 'min-height': number | null;
  readonly 'min-width': number | null;
}

export interface ICollectionsUpdatedEventData {
  readonly 'collections-count': number;
  readonly 'collections-ids': readonly Uuid[];
}

export type CodenameEventData =
  | {
      readonly target: Exclude<CodenameTargetType, CodenameTargetType.WorkflowStep>;
      readonly type: CodenameEventType;
    }
  | {
      readonly target: CodenameTargetType.WorkflowStep;
      readonly type: CodenameEventType;
      readonly workflowId: Uuid;
    };

export enum CodenameEventType {
  Copy = 'Copy',
  Edit = 'Edit',
}

export enum CodenameTargetAge {
  Existing = 'Existing',
  New = 'New',
}

export enum CodenameTargetType {
  Asset = 'Asset',
  ContentElementOption = 'ContentElementOption',
  ContentItem = 'ContentItem',
  ContentType = 'ContentType',
  ContentTypeElement = 'ContentTypeElement',
  ContentTypeSnippet = 'ContentTypeSnippet',
  TaxonomyGroup = 'TaxonomyGroup',
  TaxonomyTerm = 'TaxonomyTerm',
  WorkflowStep = 'WorkflowStep',
}

export enum ContentComponentActionType {
  CollapseAll = 'collapse-all',
  CollapseOne = 'collapse-one',
  ExpandAll = 'expand-all',
  ExpandOne = 'expand-one',
}

export type EnvironmentEventData =
  | {
      readonly type: EnvironmentEventType;
      readonly environmentId: Uuid;
    }
  | {
      readonly type: EnvironmentEventType.Swap;
      readonly environmentId: Uuid;
      readonly oldProductionId: Uuid;
    };

export enum EnvironmentEventType {
  Create = 'Create',
  Delete = 'Delete',
  Rename = 'Rename',
  Swap = 'Swap',
}

export interface IContentComponentIntercomData {
  readonly action: ContentComponentActionType;
  readonly 'content-component-id'?: Uuid;
  readonly 'item-id': Uuid;
  readonly 'variant-id': Uuid;
}

export interface ICustomTypeElementIntercomData {
  readonly config: string | null;
  readonly 'element-id': Uuid;
  readonly url: string;
}

export interface IItemSidebarToggleIntercomData {
  readonly collapsed: boolean;
}

export interface IContentItemBulkMoveToCollectionEventData {
  readonly count: number;
  readonly 'failed-count': number;
}

export interface IContentItemBulkPublishIntercomData {
  readonly count: number;
}

export interface IContentItemBulkScheduledIntercomData {
  readonly count: number;
}

export interface IContentItemWorkflowStepBulkActionIntercomData {
  readonly count: number;
  readonly isRestore: boolean;
  readonly 'wf-step-id': Uuid;
  readonly 'wf-step-name': string;
}

export interface IQuickstartLinkIntercomData {
  readonly 'link-id'?: string;
  readonly linkPlacement?: string;
  readonly target?: string;
  readonly technology?: ResourceType;
}

export interface IContentElementChangedIntercomData {
  readonly 'element-action': 'updated' | 'created';
  readonly 'element-type': TypeElementType;
}

export interface INavigationLinkData {
  readonly 'link-id': string;
}

export interface IRevisionOpenedIntercomData {
  readonly 'entry-id': Uuid;
  readonly 'revision-id': Uuid;
  // Event is actually tracked twice when revision is opened. At first, the age is tracked correctly. On the second time,
  // 'Existing' is always tracked making the age unreliable in this event – but that is a task for another time.
  readonly age: undefined;
}

export interface IRevisionRestoredIntercomData {
  readonly 'entry-id': Uuid;
  readonly 'revision-created-at': DateTimeStamp;
  readonly 'revision-id': Uuid;
  readonly 'revision-index': number;
}

export interface ISitemapNodeCreatedIntercomData {
  readonly 'item-name': string;
}
export interface ISpaceEventData {
  readonly 'space-id': Uuid;
}
export interface ITaxonomyGroupIntercomData {
  readonly 'taxonomy-group-id': Uuid;
}

export interface ITaxonomyGroupsIntercomData {
  readonly 'taxonomy-group-count': number;
  readonly 'taxonomy-group-ids': string;
}

export interface ITaxonomyTermAssignedData {
  readonly 'assigned-terms-count': number;
  readonly 'element-id': Uuid;
  readonly source: TaxonomyTermAssignedSourceType;
}

export enum TaxonomyTermAssignedSourceType {
  AiAssistant = 'ai-assistant',
  ManualSelection = 'manual-selection',
}

export interface IWebhookIntercomData {
  readonly url: string;
}

export interface IEntityWebhookIntercomData {
  readonly url: string;
}

export enum ProjectOverviewEventTypes {
  CategoryViewed = 'category-viewed',
  CreateContentItem = 'create-content-item',
  ItemsFiltered = 'items-filtered',
  MaxItemsLimitExceeded = 'max-items-limit-exceeded',
  NoItemsFound = 'no-items-found',
  PageEntered = 'page-entered',
  PageLeft = 'page-left',
}

export type ProjectOverviewIntercomData =
  | {
      readonly action: ProjectOverviewEventTypes;
    }
  | {
      readonly action: ProjectOverviewEventTypes.CategoryViewed;
      readonly category: string;
    }
  | {
      readonly action: ProjectOverviewEventTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export enum ProjectSettingsSourceTypes {
  ProjectSelector = 'project-selector',
  ProjectTileMoreActions = 'project-tile-more-actions',
  EnvironmentSettingsQuickTip = 'environment-settings-quick-tip',
}

export type ProjectSettingsClickedEventData = {
  readonly source: ProjectSettingsSourceTypes;
};

export enum WebSpotlightEventTypes {
  CreateContentItem = 'create-content-item',
}

export type WebSpotlightIntercomData =
  | {
      readonly action: WebSpotlightEventTypes;
    }
  | {
      readonly action: WebSpotlightEventTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export interface IContentTypeIntercomData {
  readonly 'content-type-count': number;
}

export interface IContentTypeSnippetIntercomData {
  readonly 'content-type-snippet-count': number;
}

export interface IContributorIntercomData {
  readonly 'user-id': Uuid;
}

export interface IFeedbackSubmittedData {
  readonly feedbackedAction: string;
  readonly smileyFeedback: string | null;
  readonly textFeedback: string;
}

export enum FeedbackTextModalActionType {
  Open = 'open',
  Send = 'send',
}

export type FeedbackTextModalData =
  | {
      readonly action: FeedbackTextModalActionType.Open;
    }
  | {
      readonly action: FeedbackTextModalActionType.Send;
      readonly message: string;
      readonly isContactMeChecked: boolean;
    };

export enum SampleContentItemEventTypes {
  SampleItemOpened = 'sample-item-opened',
  ElementUpdated = 'element-updated',
}

export interface ISampleContentItemActionData {
  readonly action: SampleContentItemEventTypes;
  readonly 'element-id'?: Uuid;
  readonly 'element-type'?: CompiledTypeElementType;
}

export enum GuidelinesEventTypes {
  Created = 'created',
  Opened = 'opened',
  Updated = 'updated',
}

export interface IGuidelinesIntercomData {
  readonly action: GuidelinesEventTypes;
  readonly 'content-item-id'?: Uuid;
}

interface IContentTypeContainsNonLocalizableElementsEventData {
  readonly 'contains-non-localizable-elements': boolean;
}

export interface IContentTypeCreatedEventData
  extends IContentTypeContainsNonLocalizableElementsEventData {
  readonly 'added-elements': string | null;
  readonly 'contains-active-condition': boolean;
  readonly 'type-id': Uuid;
}

export interface IContentTypeDuplicatedEventData {
  readonly 'duplicated-elements': string | null;
}

export interface IContentTypeModifiedEventData extends IContentTypeCreatedEventData {
  readonly 'removed-elements': string | null;
}

export interface IResourcesUsedIntercomData {
  readonly resourceType: 'main' | 'e-learning' | 'keyboard-shortcuts' | 'roadmap' | 'ux-feedback';
}

export interface ICreateProjectIntercomData {
  readonly 'project-template-id'?: Uuid;
}

export interface ITemplateContentLimitExceededIntercomData {
  readonly 'has-custom-type-elements': boolean;
  readonly 'project-template-id': Uuid;
}

export interface IFindRightAssetEventData {
  readonly name: FindRightAssetTrackedEvent;
}

export interface IInsertAssetsEventData extends IFindRightAssetEventData {
  readonly 'asset-ids': string;
  readonly 'opened-folder-id': Uuid;
}

export interface ICascadePublishNoSelectedItemsData {
  readonly displayedItems: number;
  readonly formerPublishingState: PublishingState;
  readonly itemsAvailableForSelection: number;
}

export interface ICascadePublishUndoData {
  readonly failedItemsCount: number;
}

export interface ICascadePublishWithSelectedItemsData extends ICascadePublishNoSelectedItemsData {
  readonly allAvailableItemsSelected: boolean;
  readonly failedItemsCount: number;
  readonly selectedItems: number;
}

export type IDeleteContentItemData =
  | {
      readonly reason: 'no-changes';
    }
  | {
      readonly currentEditedItem: true;
      readonly workflowStepId: Uuid;
    };

export enum CalendarEventTypes {
  ArrangementSelected = 'arrangement-selected',
  CalendarLeft = 'calendar-left',
  CalendarOpened = 'calendar-opened',
  ContentItemSelected = 'content-item-selected',
  ItemDetailsSelected = 'item-details-selected',
  NextMonthSelected = 'next-month-selected',
  PreviousMonthSelected = 'previous-month-selected',
  VariantSelected = 'variant-selected',
}

export enum ContentListingEventActionTypes {
  CreateContentItem = 'create-content-item',
}

export type ContentListingIntercomData =
  | {
      readonly action: ContentListingEventActionTypes;
    }
  | {
      readonly action: ContentListingEventActionTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export type ContentStatusEventActionTypes = {
  readonly action: YourContentEventActionTypes.CreateContentItem;
  readonly 'collection-section': CollectionSection;
  readonly 'content-type-section': ContentTypeSection;
};

export enum ContentStatusContentItemClickOrigin {
  CommentButton = 'comment-button',
  ScrollTableRow = 'scroll-table-row',
  TaskButton = 'task-button',
}

export interface IContentStatusContentItemClickedData {
  readonly itemId: Uuid;
  readonly origin: ContentStatusContentItemClickOrigin;
  readonly variantId: Uuid | undefined;
}

export interface ICalendarIntercomData {
  readonly action: CalendarEventTypes;
}

export enum YourContentEventActionTypes {
  CreateContentItem = 'create-content-item',
}

export type YourContentEventData =
  | {
      readonly action: YourContentEventActionTypes;
    }
  | {
      readonly action: YourContentEventActionTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export interface IWebSpotlightPreviewAddButtonActionClickedEventData {
  readonly action: AddButtonAction;
}

export interface IWebSpotlightUserChangesReadyOnPreviewApi {
  readonly msSinceChangeSaved: number;
}

export enum WebSpotlightPreviewResolutionChangeOrigin {
  FitScreenButton = 'fit-screen-button',
  Inputs = 'inputs',
  ResizeHandle = 'resize-handle',
  ResolutionTypeSelector = 'resolution-type-selector',
  RotateButton = 'rotate-button',
}

export interface IWebSpotlightPreviewResolutionChanged {
  readonly origin: WebSpotlightPreviewResolutionChangeOrigin;
}

export interface IRoleLanguagesEventData {
  readonly roleId: Uuid;
  readonly languageIds: UuidArray;
}

export interface ICollectionGroupRolesChangedEventData {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly roles: ReadonlyArray<IRoleLanguagesEventData>;
}

export interface IUserCollectionGroupsChangedEventData {
  readonly collectionGroups: ReadonlyArray<ICollectionGroupRolesChangedEventData>;
  readonly collectionGroupsCount: number;
  readonly languageSpecificRolesCount: number;
}

export interface IUserListingFilterChangedData {
  readonly languageIds: UuidArray;
  readonly roleIds: UuidArray;
  readonly status: ByStatus;
}

export enum ExpandedLinkedItemLayoutType {
  Simple = 'simple', // SimpleLinkedItem component (only edit button is available)
  Regular = 'regular', // LinkedItem component (has more complex layout: duplicate, comment, edit buttons, etc.)
}

export interface IContentEntryLinkedItemExpandedData {
  readonly linkedItemLayoutType: ExpandedLinkedItemLayoutType;
  readonly nestedLevel: number;
}

export enum INonLocalizableElementsWarningType {
  DependentVariantsScheduledEarlierQuickTip = 'dependent-variants-scheduled-earlier-quick-tip',
  CancelDefaultVariantSchedulePublishFriendlyWarning = 'cancel-default-variant-schedule-publish-friendly-warning',
}

export interface INonLocalizableElementsWarningDisplayedEventData {
  readonly type: INonLocalizableElementsWarningType;
}

type ContentPaceOpenedData = Readonly<{
  contentTypeId: Uuid | null;
  timeInterval: TimeInterval;
  workflowStage: WorkflowStage;
}>;

type ContentPaceWorkflowDetailOpenedData = Readonly<{
  contentTypeId: Uuid | null;
  timeInterval: TimeInterval;
  workflowId: Uuid;
  workflowStage: WorkflowStage;
  workflowStepId: Uuid;
}>;

type ContentPaceContentItemClickedData = Readonly<{
  itemId: Uuid;
  variantId: Uuid;
}>;

type MissionControlWidgetLinkClickedData = Readonly<{
  widgetName: MissionControlTrackedWidgetName;
}>;

export enum MissionControlTrackedWidgetName {
  ItemsAssignedToYou = 'ItemsAssignedToYou',
  OverdueTasks = 'OverdueTasks',
  RecentlyEditedByYou = 'RecentlyEditedByYou',
  TasksAssignedByYou = 'TasksAssignedByYou',
  UnchangedPublishedItems = 'UnchangedPublishedItems',
  UnchangedUnpublishedItems = 'UnchangedUnpublishedItems',
  YourTasks = 'YourTasks',
}

type MissionControlWidgetShowMoreClickedData = Readonly<{
  widgetName: MissionControlTrackedWidgetName;
}>;

type MissionControlWidgetDetailItemClickedData = Readonly<{
  widgetName: MissionControlTrackedWidgetName;
}>;

export type MissionControlUserFeedbackDialogData = Readonly<{
  action: 'opened' | 'closed';
}>;

type MissionControlUserFeedbackDialogSentData = Readonly<{
  rating: string;
  comment: string;
}>;

export type TrackUserEventAllowedData =
  | ContentItemEditingIntercomData
  | ContentListingIntercomData
  | ContentPaceContentItemClickedData
  | ContentPaceOpenedData
  | ContentPaceWorkflowDetailOpenedData
  | DocsVisitManageWorkflowData
  | IAppLoadedEventData
  | IAssetBulkActionEventData
  | IAssetIntercomData
  | IAssetLimitsIntercomData
  | ICalendarIntercomData
  | ICascadePublishWithSelectedItemsData
  | ICollectionsUpdatedEventData
  | ICommentsIntercomData
  | IConfirmationDialogEventData
  | IContentComponentIntercomData
  | IContentElementChangedIntercomData
  | IContentEntryLinkedItemExpandedData
  | IContentEntryNameOrElementsChangedIntercomData
  | IContentEntryOverwrittenEventData
  | IContentItemBulkMoveToCollectionEventData
  | IContentItemBulkPublishIntercomData
  | IContentItemWorkflowStepBulkActionIntercomData
  | IContentTypeCreatedEventData
  | IContentTypeIntercomData
  | IContentTypeModifiedEventData
  | IContentTypeSnippetIntercomData
  | IContributorIntercomData
  | ICreateProjectIntercomData
  | ICustomTypeElementIntercomData
  | IDeleteContentItemData
  | IEditorStatisticsIntercomData
  | IEntityWebhookIntercomData
  | IFeatureIntercomData
  | IFeedbackSubmittedData
  | IFindRightAssetEventData
  | IFindRightContentEventData
  | IGuidelinesIntercomData
  | IItemSidebarToggleIntercomData
  | INavigationLinkData
  | IOpenEditingEventData
  | IOpenEditingFromYourContentEventData
  | IQuickstartLinkIntercomData
  | IResourcesUsedIntercomData
  | IRevisionOpenedIntercomData
  | IRevisionRestoredIntercomData
  | ISampleContentItemActionData
  | ISavedFilterSelectedEventData
  | ISitemapNodeCreatedIntercomData
  | ISortEventData
  | ISpaceEventData
  | ITaxonomyGroupIntercomData
  | ITaxonomyGroupsIntercomData
  | ITemplateContentLimitExceededIntercomData
  | IUmuxFeedbackIntercomMessage
  | IWebhookIntercomData
  | IWebSpotlightPreviewAddButtonActionClickedEventData
  | IWebSpotlightUserChangesReadyOnPreviewApi
  | MissionControlWidgetDetailItemClickedData
  | MissionControlWidgetLinkClickedData
  | MissionControlWidgetShowMoreClickedData
  | MissionControlUserFeedbackDialogData
  | MissionControlUserFeedbackDialogSentData
  | ProjectOverviewIntercomData
  | ProjectSettingsClickedEventData
  | UmuxSurveyIntercomData
  | WebSpotlightIntercomData
  | YourContentEventData;

export type TrackUserEventFunctionArguments =
  | [TrackedEvent.AiAction, AiActionEventData]
  | [TrackedEvent.AiImageTagging, AiImageTaggingEventData]
  | [TrackedEvent.AppLoaded, IAppLoadedEventData]
  | [TrackedEvent.AssetBulkAction, IAssetBulkActionEventData]
  | [TrackedEvent.AssetDeleted, IAssetIntercomData]
  | [TrackedEvent.AssetDownloaded, IAssetIntercomData]
  | [TrackedEvent.AssetLimitedInAssetElement, IAssetLimitsIntercomData]
  | [TrackedEvent.AssetLimitedInRichText, IAssetLimitsIntercomData]
  | [TrackedEvent.AssetReplaced, IAssetIntercomData]
  | [TrackedEvent.AssetRestored, IAssetIntercomData]
  | [TrackedEvent.AssetUpdated, IAssetUpdatedData]
  | [TrackedEvent.AssetUploaded, IAssetIntercomData]
  | [TrackedEvent.Calendar, ICalendarIntercomData]
  | [TrackedEvent.CascadePublishNoSelectedItems, ICascadePublishNoSelectedItemsData]
  | [TrackedEvent.CascadePublishUndo | TrackedEvent.CascadeScheduleUndo, ICascadePublishUndoData]
  | [TrackedEvent.CascadePublishWithSelectedItems, ICascadePublishWithSelectedItemsData]
  | [TrackedEvent.CascadeScheduleNoSelectedItems, ICascadePublishNoSelectedItemsData]
  | [TrackedEvent.CascadeScheduleWithSelectedItems, ICascadePublishWithSelectedItemsData]
  | [TrackedEvent.ClientTimeZoneUnsupported, IClientTimeZoneData]
  | [TrackedEvent.Codename, CodenameEventData]
  | [TrackedEvent.CollectionsUpdated, ICollectionsUpdatedEventData]
  | [TrackedEvent.ColumnSettings, IColumnSettingsData]
  | [TrackedEvent.Comments, ICommentsIntercomData]
  | [TrackedEvent.ContentComponentCollapse, IContentComponentIntercomData]
  | [TrackedEvent.ContentEntryBulkAssignment, IContentItemWorkflowStepBulkActionIntercomData]
  | [TrackedEvent.ContentEntryBulkMoveToCollection, IContentItemBulkMoveToCollectionEventData]
  | [TrackedEvent.ContentEntryBulkPublished, IContentItemBulkPublishIntercomData]
  | [TrackedEvent.ContentEntryBulkScheduled, IContentItemBulkScheduledIntercomData]
  | [TrackedEvent.ContentEntryBulkScheduleUnpublished, IContentItemBulkScheduledIntercomData]
  | [TrackedEvent.ContentEntryBulkUnpublished, IContentItemBulkPublishIntercomData]
  | [TrackedEvent.ContentEntryElementRefreshed, IContentEntryElementRefreshedData]
  | [TrackedEvent.ContentEntryElementSaveConflict, IContentEntryElementSaveConflictData]
  | [TrackedEvent.ContentEntryLinkedItemExpanded, IContentEntryLinkedItemExpandedData]
  | [TrackedEvent.ContentEntryLockedElementOverwritten, IContentEntryLockedElementOverwrittenData]
  | [TrackedEvent.ContentEntryNameOrElementsChanged, IContentEntryNameOrElementsChangedIntercomData]
  | [TrackedEvent.ContentEntryOverwriteNotificationDisplayed, IContentEntryOverwrittenEventData]
  | [TrackedEvent.ContentEntryOverwriteNotificationRefreshed, IContentEntryOverwrittenEventData]
  | [TrackedEvent.ContentItemDeleted, IDeleteContentItemData]
  | [TrackedEvent.ContentItemEditing, ContentItemEditingIntercomData]
  | [TrackedEvent.ContentItemLinkCopied, IContentItemLinkCopiedIntercomData]
  | [TrackedEvent.ContentListing, ContentListingIntercomData]
  | [TrackedEvent.ContentPaceContentItemClicked, ContentPaceContentItemClickedData]
  | [TrackedEvent.ContentPaceOpened, ContentPaceOpenedData]
  | [TrackedEvent.ContentPaceWorkflowDetailOpened, ContentPaceWorkflowDetailOpenedData]
  | [TrackedEvent.ContentStatus, ContentStatusEventActionTypes]
  | [TrackedEvent.ContentStatusContentItemClicked, IContentStatusContentItemClickedData]
  | [TrackedEvent.ContentTypeCreated, IContentTypeCreatedEventData]
  | [TrackedEvent.ContentTypeDeleted, IContentTypeIntercomData]
  | [TrackedEvent.ContentTypeDuplicated, IContentTypeDuplicatedEventData]
  | [TrackedEvent.ContentTypeSnippetDeleted, IContentTypeSnippetIntercomData]
  | [TrackedEvent.ContentTypeUpdated, IContentTypeModifiedEventData]
  | [TrackedEvent.ContributorActivated, IContributorIntercomData]
  | [TrackedEvent.ContributorDeactivated, IContributorIntercomData]
  | [TrackedEvent.CustomElementTypeUpsert, ICustomTypeElementIntercomData]
  | [TrackedEvent.DocsVisitManageWorkflows, DocsVisitManageWorkflowData]
  | [TrackedEvent.EntityWebhookSaved, IWebhookIntercomData]
  | [TrackedEvent.Environment, EnvironmentEventData]
  | [TrackedEvent.FeatureUsed, IFeatureIntercomData]
  | [TrackedEvent.FeedbackSubmitted, IFeedbackSubmittedData]
  | [TrackedEvent.FeedbackTextModal, FeedbackTextModalData]
  | [TrackedEvent.FindRightAsset, FindRightAssetEventActionData]
  | [TrackedEvent.FindRightContent, FindRightContentEventActionData]
  | [TrackedEvent.Guidelines, IGuidelinesIntercomData]
  | [TrackedEvent.ItemRevisionOpened, IRevisionOpenedIntercomData]
  | [TrackedEvent.ItemRevisionRestored, IRevisionRestoredIntercomData]
  | [TrackedEvent.ItemSidebarToggle, IItemSidebarToggleIntercomData]
  | [TrackedEvent.LinkVisited, ILinkVisitedIntercomData]
  | [TrackedEvent.MissionControlWidgetDetailItemClicked, MissionControlWidgetDetailItemClickedData]
  | [TrackedEvent.MissionControlWidgetOverviewItemClicked, MissionControlWidgetLinkClickedData]
  | [TrackedEvent.MissionControlWidgetShowMoreClicked, MissionControlWidgetShowMoreClickedData]
  | [TrackedEvent.MissionControlUserFeedbackDialog, MissionControlUserFeedbackDialogData]
  | [TrackedEvent.MissionControlUserFeedbackDialogSent, MissionControlUserFeedbackDialogSentData]
  | [TrackedEvent.MoveItemsToArchivedStepConfirmationDialog, IConfirmationDialogEventData]
  | [TrackedEvent.MoveItemToArchivedStepConfirmationDialog, IConfirmationDialogEventData]
  | [TrackedEvent.NavigationLinkOpened, INavigationLinkData]
  | [
      TrackedEvent.NonLocalizableElementsWarningDisplayed,
      INonLocalizableElementsWarningDisplayedEventData,
    ]
  | [TrackedEvent.ProjectCreated, ICreateProjectIntercomData]
  | [TrackedEvent.ProjectOverview, ProjectOverviewIntercomData]
  | [TrackedEvent.ProjectSettingsClicked, ProjectSettingsClickedEventData]
  | [TrackedEvent.QuickstartLinkOpened, IQuickstartLinkIntercomData]
  | [TrackedEvent.ResourcesUsed, IResourcesUsedIntercomData]
  | [TrackedEvent.RolesChanged, IUserCollectionGroupsChangedEventData]
  | [TrackedEvent.RTECommandUsed, RTECommandEventData]
  | [TrackedEvent.SampleContentItemAction, ISampleContentItemActionData]
  | [TrackedEvent.SavedFilterSelected, ISavedFilterSelectedEventData]
  | [TrackedEvent.SitemapNodeCreated, ISitemapNodeCreatedIntercomData]
  | [TrackedEvent.SpacePreviewOpened, ISpaceEventData]
  | [TrackedEvent.SpaceWebSpotlightSwitchSpace, ISpaceEventData]
  | [TrackedEvent.Statistics, IEditorStatisticsIntercomData]
  | [TrackedEvent.StructuredUserFeedback, StructuredFeedbackPayload]
  | [TrackedEvent.Tasks, TasksUserEventData]
  | [TrackedEvent.TaxonomyGroupArchived, ITaxonomyGroupsIntercomData]
  | [TrackedEvent.TaxonomyGroupCreated, ITaxonomyGroupIntercomData]
  | [TrackedEvent.TaxonomyTermAssigned, ITaxonomyTermAssignedData]
  | [TrackedEvent.TemplateContentLimitExceeded, ITemplateContentLimitExceededIntercomData]
  | [TrackedEvent.UmuxSurvey, UmuxSurveyIntercomData]
  | [TrackedEvent.UserFeedback, IUmuxFeedbackIntercomMessage]
  | [TrackedEvent.UserListingFilterChanged, IUserListingFilterChangedData]
  | [TrackedEvent.WebhookArchived, IWebhookIntercomData]
  | [TrackedEvent.WebhookDisabled, IWebhookIntercomData]
  | [TrackedEvent.WebhookEnabled, IWebhookIntercomData]
  | [TrackedEvent.WebhookReset, IWebhookIntercomData]
  | [TrackedEvent.WebhookSaved, IWebhookIntercomData]
  | [TrackedEvent.WebSpotlight, WebSpotlightIntercomData]
  | [
      TrackedEvent.WebSpotlightPreviewAddButtonActionClicked,
      IWebSpotlightPreviewAddButtonActionClickedEventData,
    ]
  | [TrackedEvent.WebSpotlightPreviewResolutionChanged, IWebSpotlightPreviewResolutionChanged]
  | [
      TrackedEvent.WebSpotlightUserChangesReadyOnPreviewApi,
      IWebSpotlightUserChangesReadyOnPreviewApi,
    ]
  | [TrackedEvent.WorkflowCreated, CreateWorkflowEventData]
  | [TrackedEvent.WorkflowDeleted, DeleteWorkflowEventData]
  | [TrackedEvent.WorkflowRestored, RestoreWorkflowEventData]
  | [TrackedEvent.WorkflowUpdated, UpdateWorkflowEventData]
  | [TrackedEvent.YourContent, YourContentEventData];
