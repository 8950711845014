import { Card } from '@kontent-ai/component-library/Card';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { ApiKeyRegenerationButton } from '../../../../../_shared/components/ApiKeys/ApiKeyRegenerationButton.tsx';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContainerActiveUser } from '../../../../../data/models/projectContainerActiveUsers/ProjectContainerActiveUser.ts';
import { ValidatedDapiKeyEnvironmentsSelector } from '../../containers/ApiKeyDetail/ValidatedDapiKeyEnvironmentsSelector.tsx';
import { ApiKey } from '../../models/ApiKey.ts';
import { EnvironmentOption } from '../../selectors/getSelectedEnvironmentOptions.ts';
import { isContainerScopedDapiKey } from '../../utils/isContainerScopedDapiKey.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';
import { ExpirationDateStatus } from './ExpirationDateStatus.tsx';
import { SharedWithUsersSelector } from './SharedWithUsersSelector.tsx';
import { ValidatedApiKeyExpirationDatePicker } from './ValidatedApiKeyExpirationDatePicker.tsx';
import { AccessTooltips, ValidatedDapiAccessCheckboxes } from './ValidatedDapiAccessCheckboxes.tsx';

type DapiKeyFormContentProps = {
  readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
  readonly apiKey: ApiKey;
  readonly canUpdateKey: boolean;
  readonly formProps: HookFormProps<DapiKeyFormShape>;
  readonly isProjectManager: boolean;
  readonly isSecureAccessAvailable: boolean;
  readonly projectContainerActiveUsers: ReadonlyArray<IProjectContainerActiveUser>;
  readonly onRegenerate?: () => void;
};

export const DapiKeyFormContent: React.FC<DapiKeyFormContentProps> = ({
  allEnvironmentOptions,
  apiKey,
  canUpdateKey,
  formProps,
  isProjectManager,
  isSecureAccessAvailable,
  projectContainerActiveUsers,
  onRegenerate,
}) => {
  const regenerationButton = isProjectManager && !!onRegenerate && (
    <ApiKeyRegenerationButton
      onConfirm={onRegenerate}
      onRegenerate={noOperation}
      requireConfirmation
    />
  );

  return (
    <>
      <Card cardLabel="General" component="section">
        <Card.Headline>General</Card.Headline>
        <Card.Body>
          <Stack spacing={Spacing.XL}>
            <Clipboard
              auxiliaryElement={regenerationButton}
              value={apiKey.apiKey}
              type={InputType.Password}
              label="API key"
              inputState={apiKey.tokenSeedId ? InputState.ReadOnly : InputState.Disabled}
              placeholder="To generate the API key, save your changes first."
              renderClipboardButton={({ ref }) => (
                <Clipboard.Button
                  ref={ref}
                  isDisabled={!apiKey.tokenSeedId}
                  tooltipText={apiKey.tokenSeedId ? undefined : ''}
                  {...getDataUiActionAttribute(DataUiAction.CopyToClipboard)}
                />
              )}
              {...getDataUiInputAttribute(DataUiInput.ApiKey)}
            />
            {apiKey.expiresAt ? (
              <ExpirationDateStatus expirationDate={apiKey.expiresAt} />
            ) : (
              <ValidatedApiKeyExpirationDatePicker<DapiKeyFormShape>
                apiKeyType={ApiKeyType.DAPI}
                formProps={formProps}
                name="expiresAt"
              />
            )}
            <ValidatedInput<DapiKeyFormShape>
              label="Name"
              name="name"
              maxLength={ValidationConstants.ApiKeyNameMaxLength}
              dataUiInputName={DataUiInput.EntityName}
              formProps={formProps}
              inputState={canUpdateKey ? InputState.Default : InputState.ReadOnly}
              tooltipText={getDisabledTooltipText(isProjectManager, canUpdateKey)}
              tooltipPlacement="top"
              placeholder="Name your API key. Example: Web app API key"
            />
            {isProjectManager && (
              <SharedWithUsersSelector<DapiKeyFormShape>
                formProps={formProps}
                name="sharedWithUsers"
                projectContainerActiveUsers={projectContainerActiveUsers}
                selectedUsersSelectorInputState={
                  canUpdateKey ? InputState.Default : InputState.Disabled
                }
              />
            )}
          </Stack>
        </Card.Body>
      </Card>
      <Card cardLabel="DapiAccess" component="section">
        <Card.Headline>Delivery API access</Card.Headline>
        <Card.Body>
          <ValidatedDapiAccessCheckboxes
            checkboxState={canUpdateKey && isSecureAccessAvailable ? 'default' : 'disabled'}
            formProps={formProps}
            name="access"
            tooltip={getAccessTooltip(apiKey, isProjectManager, isSecureAccessAvailable)}
          />
        </Card.Body>
      </Card>
      <Card cardLabel="EnvironmentLimitations" component="section">
        <Card.Headline>Limitations</Card.Headline>
        <Card.Body>
          <ValidatedDapiKeyEnvironmentsSelector
            allEnvironmentOptions={allEnvironmentOptions}
            canUpdateKey={canUpdateKey}
            disabledTooltipText={getDisabledTooltipText(isProjectManager, canUpdateKey)}
            formProps={formProps}
          />
        </Card.Body>
      </Card>
    </>
  );
};

DapiKeyFormContent.displayName = 'DapiKeyFormContent';

const getDisabledTooltipText = (
  isProjectManager: boolean,
  canUpdateKey: boolean,
): string | undefined => {
  if (isProjectManager && !canUpdateKey) {
    return 'This is a legacy API key. If you want to change the limitations, you need to regenerate the API key first.';
  }

  if (!isProjectManager && !canUpdateKey) {
    return 'Only Project managers can change this limitation.';
  }

  return undefined;
};

const getAccessTooltip = (
  apiKey: ApiKey,
  isProjectManager: boolean,
  isSecureAccessAvailable: boolean,
): AccessTooltips | undefined => {
  if (!isProjectManager) {
    return {
      contentPreviewTooltip: 'Only Project managers can change this limitation.',
      secureAccessTooltip: 'Only Project managers can change this limitation.',
    };
  }

  if (!isContainerScopedDapiKey(apiKey)) {
    return {
      contentPreviewTooltip:
        'This is a legacy API key. If you want to change the limitations, you need to regenerate the API key first.',
      secureAccessTooltip:
        'This is a legacy API key. If you want to change the limitations, you need to regenerate the API key first.',
    };
  }

  if (!isSecureAccessAvailable) {
    return {
      contentPreviewTooltip:
        'You need to enable secure access first. At least one of the options needs to be enabled.',
      secureAccessTooltip:
        'Secure access isn’t available for your subscription. To use secure access with this API key, upgrade your subscription plan.',
    };
  }

  return undefined;
};
