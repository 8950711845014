import { assert, Collection } from '@kontent-ai/utils';
import {
  DraftInlineStyleType,
  RawDraftContentState,
  RawDraftInlineStyleRange,
  genKey,
} from 'draft-js';
import { IContentComponentServerModel } from '../../../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import {
  BaseBlockType,
  BlockType,
  getBaseType,
  getNestedBlockType,
  parseBlockType,
} from '../../../../utils/blocks/blockType.ts';
import {
  IRawBlock,
  IRawBlockInput,
  createEmptyRawParagraph,
  createRawBlock,
} from '../../../../utils/blocks/editorBlockUtils.ts';
import { EntityData, EntityMutability, EntityType } from '../../../entityApi/api/Entity.ts';
import { DraftJSInlineStyle } from '../../../inlineStyles/api/inlineStyles.ts';

const createResultBlock = (builderBlock: BuilderRawBlock): IRawBlock => {
  const { chars, ...otherBlockProps } = builderBlock;

  return createRawBlock({
    ...otherBlockProps,
    text: chars.join(''),
  });
};
interface IExtendedIterator<T> {
  next(): IteratorResult<T>;

  current(): IteratorResult<T>;
}

export interface IRawStateBuilderDependencies {
  readonly storeContentComponent?: (contentComponent: IContentComponentServerModel) => Uuid;
}

type BuilderRawBlock = Omit<IRawBlock, 'text'> & {
  chars: Array<string>;
};

type BuilderRawState = {
  blocks: Array<BuilderRawBlock>;
  entityMap: RawDraftContentState['entityMap'];
};

export class RawStateBuilder {
  constructor(dependencies: IRawStateBuilderDependencies) {
    this._dependencies = dependencies;
  }

  private readonly _dependencies: IRawStateBuilderDependencies;

  private readonly _state: BuilderRawState = {
    blocks: [],
    entityMap: {},
  };

  private readonly _blockKeyGenerator: IExtendedIterator<string> = (function () {
    const iterator: Iterator<string> = (function* () {
      while (true) {
        yield genKey();
      }
    })();

    let currentValue = iterator.next();

    return {
      next: () => {
        currentValue = iterator.next();
        return currentValue;
      },
      current: () => currentValue,
    };
  })();

  private readonly _getCurrentBlock = (): BuilderRawBlock | null =>
    Collection.getLast(this._state.blocks);

  private readonly _ensureBlockForTextInput = (): BuilderRawBlock => {
    const currentBlock = this._getCurrentBlock();
    if (!currentBlock) {
      return this.addEmptyBlock([]);
    }
    if (getBaseType(currentBlock.type) === BlockType.TableCell) {
      // Table cell cannot contain text directly, we need to create a nested paragraph for the text
      return this.addEmptyBlock(parseBlockType(currentBlock.type));
    }
    return currentBlock;
  };

  public isCurrentBlockEmpty = (): boolean => {
    const lastBlock = this._getCurrentBlock();
    return lastBlock === null || !lastBlock.chars.length;
  };

  public isCurrentBlockTableCell = (): boolean => {
    const lastBlock = this._getCurrentBlock();
    return !!lastBlock && getBaseType(lastBlock.type) === BlockType.TableCell;
  };

  public getLastBlockCharacter = (): string | null => {
    const lastBlock = this._getCurrentBlock();
    return (lastBlock && Collection.getLast(lastBlock.chars)) || null;
  };

  public addBlock = (
    parentBlockTypes: ReadonlyArray<BaseBlockType>,
    blockProps: IRawBlockInput,
  ): BuilderRawBlock => {
    const {
      text,
      type,
      key, // We need to use the generator key to proper match entities to their blocks
      ...otherBlockProps
    } = blockProps;

    const rawBlock: BuilderRawBlock = {
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      chars: Array.from(text ?? ''),
      type: getNestedBlockType(parentBlockTypes, blockProps.type),
      key: this._blockKeyGenerator.current().value,
      ...otherBlockProps,
    };
    this._state.blocks.push(rawBlock);
    this._blockKeyGenerator.next();
    return rawBlock;
  };

  public addEmptyBlock = (parentBlockTypes: ReadonlyArray<BaseBlockType>): BuilderRawBlock => {
    const rawBlock = createEmptyRawParagraph();
    return this.addBlock(parentBlockTypes, rawBlock);
  };

  public addContentComponent = (contentComponent: IContentComponentServerModel): Uuid | null => {
    if (!this._dependencies.storeContentComponent) {
      return null;
    }
    return this._dependencies.storeContentComponent(contentComponent);
  };

  public writeText = (text: string, styles?: ReadonlyArray<DraftJSInlineStyle>): void => {
    const lastBlock = this._ensureBlockForTextInput();

    const inlineStyleRanges: Array<RawDraftInlineStyleRange> = [...lastBlock.inlineStyleRanges];
    if (styles) {
      styles.forEach((style) => {
        const continuationStyleRangeIndex = inlineStyleRanges.findIndex(
          (range) =>
            range.style === style && range.offset + range.length === lastBlock.chars.length,
        );

        if (continuationStyleRangeIndex >= 0) {
          const continuationStyleRange = inlineStyleRanges[continuationStyleRangeIndex];
          assert(
            !!continuationStyleRange,
            () => `${__filename}: Continuation style range is falsy.`,
          );
          const mergedStyleRange = {
            ...continuationStyleRange,
            length: continuationStyleRange.length + text.length,
          };
          inlineStyleRanges.splice(continuationStyleRangeIndex, 1, mergedStyleRange);
        } else {
          inlineStyleRanges.push({
            length: text.length,
            offset: lastBlock.chars.length,
            style: style as DraftInlineStyleType,
          });
        }
      });
    }

    const updatedBlock: BuilderRawBlock = {
      ...lastBlock,
      inlineStyleRanges,
      chars: [...lastBlock.chars, ...Array.from(text)],
    };

    this._state.blocks.splice(-1, 1, updatedBlock);
  };

  public getRawState = (): RawDraftContentState => ({
    ...this._state,
    blocks:
      this._state.blocks.length > 0
        ? this._state.blocks.map(createResultBlock)
        : [createEmptyRawParagraph()],
  });

  public getCurrentBlockKey = (): string | null => {
    const currentBlock = this._getCurrentBlock();
    return currentBlock ? currentBlock.key : null;
  };

  public getNextBlockKey = (): string => this._blockKeyGenerator.current().value;

  public getCurrentBlockTextOffset = (): {
    readonly blockKey: string;
    readonly charOffset: number;
  } => {
    const currentBlock = this._getCurrentBlock();

    return {
      blockKey: currentBlock ? currentBlock.key : this.getNextBlockKey(),
      charOffset: currentBlock ? currentBlock.chars.length : 0,
    };
  };

  public writeEntity = ({
    blockKey,
    type,
    data,
    length,
    offset,
  }: {
    readonly type: EntityType;
    readonly blockKey: string;
    readonly data: EntityData;
    readonly offset: number;
    readonly length: number;
  }): void => {
    const newEntityKey = Object.keys(this._state.entityMap).length;
    this._state.entityMap[newEntityKey] = {
      data,
      mutability: EntityMutability.Mutable,
      type,
    };

    const linkedBlock = this._state.blocks.find((block) => block.key === blockKey);
    assert(linkedBlock, () => 'writeEntity: Block with a given key was not found.');

    linkedBlock.entityRanges.push({
      key: newEntityKey,
      length,
      offset,
    });
  };
}
