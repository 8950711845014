import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { DashboardWidget } from '../../types/WidgetDescriptor.type.ts';

const StyledBox = styled(Box)`
  grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));
  // This should be removed right after we discuss with UX how to deal with multiple widget of different height
  @media (min-width: 1640px) {
    grid-template-columns: repeat(3, minmax(470px, 1fr));
  }
`;

type DashboardTabContentProps = Readonly<{
  widgets: ReadonlyArray<DashboardWidget>;
}>;

export const DashboardTabContent: React.FC<DashboardTabContentProps> = ({ widgets }) => {
  return (
    <div className="canvas">
      <div className="canvas__workspace">
        <div className="canvas__content">
          <div className="canvas__content-pane">
            <StyledBox display="grid" gap={Spacing.L}>
              {widgets.map((widget) => (
                <widget.component key={widget.uniqueKey} />
              ))}
            </StyledBox>
          </div>
        </div>
      </div>
    </div>
  );
};
