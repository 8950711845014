import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { calendarPopupClosed } from '../../actions/calendarActions.ts';
import {
  DateCellWrapperWithPopup,
  IDateCellWrapperDispatchProps,
  IDateCellWrapperOwnProps,
  IDateCellWrapperStateProps,
} from '../../components/Calendar/DateCellWrapperWithPopup.tsx';
import { CalendarPopupType } from '../../components/Popups/CalendarPopupType.ts';
import { ItemDetails } from '../../types/ItemDetails.type.ts';
import { areDatesSame } from '../../utils/calendarUtils.ts';

const getPopupType = (
  itemDetail: ItemDetails | null,
  drillDownDate: DateTimeStamp | null,
  currentDate: Date,
): CalendarPopupType => {
  if (!!itemDetail && areDatesSame(itemDetail.date, currentDate)) {
    return CalendarPopupType.ItemDetail;
  }

  if (!!drillDownDate && areDatesSame(drillDownDate, currentDate)) {
    return CalendarPopupType.AgendaListing;
  }

  return CalendarPopupType.None;
};

const mapStateToProps = (
  state: IStore,
  ownProps: IDateCellWrapperOwnProps,
): IDateCellWrapperStateProps => ({
  popupType: getPopupType(
    state.calendarApp.itemDetails,
    state.calendarApp.drillDownDate,
    ownProps.value,
  ),
});

const mapDispatchToProp = (dispatch: Dispatch): IDateCellWrapperDispatchProps => ({
  onDismiss: () => dispatch(calendarPopupClosed()),
});

export const DateCellWrapper: React.ComponentType<
  React.PropsWithChildren<IDateCellWrapperOwnProps>
> = connect(mapStateToProps, mapDispatchToProp)(DateCellWrapperWithPopup);
