import React from 'react';
import { StatusMessageTypes } from '../../../../../_shared/constants/statusMessageType.ts';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import { StatusMessage } from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

const getSavingStatusMessage = ({ previewConfigurationApp: { status } }: IStore): StatusMessage => {
  switch (status.messageType) {
    case StatusMessageTypes.Saving:
      return {
        messageType: StatusMessageTypes.Saving,
      };
    case StatusMessageTypes.ServerError:
      return {
        messageType: StatusMessageTypes.ServerError,
      };
    default:
      return status;
  }
};

export const PreviewConfigurationSavingStatus: React.ComponentType =
  getConnectedStatusMessage(getSavingStatusMessage);
