import {
  BaseColor,
  colorBackgroundDisabled,
  colorWarningBackgroundInverse,
} from '@kontent-ai/component-library/tokens';
import { Placement } from '@kontent-ai/component-library/types';
import { UnreachableCaseException } from '@kontent-ai/errors';
import React from 'react';
import { WorkflowStatus } from '../../../../../component-library/components/WorkflowStatus/WorkflowStatus.tsx';
import { IWorkflowStepConfig } from '../../../../../component-library/components/WorkflowStatus/components/WorkflowStep.tsx';
import { AgendaItemStatus } from '../constants/AgendaItemStatus.ts';

type AgendaItemStatusViewProps = Readonly<{
  itemState: AgendaItemStatus;
  tooltipText: string;
  tooltipPlacement: Placement;
}>;

const getAgendaStateVariant = (state: AgendaItemStatus): IWorkflowStepConfig => {
  switch (state) {
    case AgendaItemStatus.Delayed:
      return {
        background: colorWarningBackgroundInverse,
        name: 'Delayed',
      };
    case AgendaItemStatus.OnTrack:
      return {
        background: colorBackgroundDisabled,
        name: 'On track',
      };
    case AgendaItemStatus.Published:
      return {
        background: BaseColor.PersianGreen60,
        name: 'Published',
      };
    case AgendaItemStatus.Scheduled:
      return {
        background: BaseColor.OceanBlue60,
        name: 'Scheduled',
      };
    default:
      throw UnreachableCaseException(state, `Unknown AgendaItemStatus: ${state}`);
  }
};

export const AgendaItemStatusView: React.FC<AgendaItemStatusViewProps> = ({
  itemState,
  tooltipText,
  tooltipPlacement,
}) => {
  const legend = getAgendaStateVariant(itemState);

  return (
    <WorkflowStatus
      primary={{
        background: legend.background,
        name: legend.name,
        icon: legend.icon,
        tooltipText,
        tooltipPlacement,
      }}
    />
  );
};

AgendaItemStatusView.displayName = 'AgendaItemStatusView';
