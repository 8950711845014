import { isElement } from '@kontent-ai/DOM';
import { Button } from '@kontent-ai/component-library/Button';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { getDefaultExpiration } from '../../../../../_shared/constants/apiKeyExpirations.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { apiKeyFormSavingInterrupted } from '../../actions/apiKeysActions.ts';
import { ApiKeyDetailLayout } from '../../components/ApiKeyDetail/ApiKeyDetailLayout.tsx';
import { ApiKeyDetailToolbarSaveButtonElementId } from '../../components/ApiKeyDetail/ApiKeyDetailToolbarActions.tsx';
import { MapiKeyDetail } from '../../components/ApiKeyDetail/MapiKeyDetail.tsx';
import { ReadonlyMapiEnvironmentsSelector } from '../../components/ApiKeyDetail/ReadonlyMapiEnvironmentsSelector.tsx';
import { ValidatedApiKeyExpirationDatePicker } from '../../components/ApiKeyDetail/ValidatedApiKeyExpirationDatePicker.tsx';
import { ApiKey } from '../../models/ApiKey.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';
import { getAllEnvironmentOptions } from '../../selectors/getSelectedEnvironmentOptions.ts';
import {
  PersonalMapiKeyFormShape,
  personalMapiKeyValidationConfig,
} from '../../validation/personalMapiKeyValidationConfig.ts';

type Props = {
  readonly apiKey: ApiKey;
  readonly apiKeyActionStatus: ApiKeyActionStatus;
  readonly apiKeyName: string;
  readonly onAlertDismiss: () => void;
  readonly onSave: (updatedKey: PersonalMapiKeyFormShape) => Promise<void>;
  readonly onUnsavedNavigation: (
    onSuccess: () => void,
    onFail: () => void,
    updatedKey: PersonalMapiKeyFormShape,
  ) => Promise<void>;
};

export const PersonalMapiKeyDetailForm = ({
  apiKey,
  apiKeyActionStatus,
  apiKeyName,
  onAlertDismiss,
  onSave,
  onUnsavedNavigation,
}: Props) => {
  const dispatch = useDispatch();
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.MAPIPat),
  );

  const { current: apiKeyDetailToolbarSaveButtonContainerElement } = useObserveElementPresence(
    ApiKeyDetailToolbarSaveButtonElementId,
  );

  const defaultExpirationDate = getDefaultExpiration(ApiKeyType.MAPIPat);
  const formProps = useForm<PersonalMapiKeyFormShape>({
    defaultValues: {
      expiresAt: {
        value: defaultExpirationDate,
        isValid: true,
      },
    },
    resolver: createFormValidationResolver(personalMapiKeyValidationConfig, {}),
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formProps;

  const onSaveChanges = handleSubmit(
    async (values): Promise<void> => {
      reset(values);
      await onSave(values);
    },
    (): void => {
      dispatch(apiKeyFormSavingInterrupted());
    },
  );

  const unsavedNavigationHandler = useCallback(
    async (onSuccess: () => void, onFail: () => void): Promise<void> => {
      const submit = handleSubmit(
        async (values): Promise<void> => {
          reset(values);
          await onUnsavedNavigation(onSuccess, onFail, values);
          onSuccess();
        },
        (): void => {
          onFail();
          dispatch(apiKeyFormSavingInterrupted());
        },
      );
      await submit();
    },
    [onUnsavedNavigation, handleSubmit, reset],
  );

  return (
    <ApiKeyDetailLayout
      apiKeyActionStatus={apiKeyActionStatus}
      onAlertDismiss={onAlertDismiss}
      apiKeyName={apiKeyName}
    >
      <MapiKeyDetail
        apiKey={apiKey}
        renderDatePicker={() => (
          <ValidatedApiKeyExpirationDatePicker<PersonalMapiKeyFormShape>
            apiKeyType={ApiKeyType.MAPIPat}
            formProps={formProps}
            name="expiresAt"
          />
        )}
        renderNameInput={() => (
          <Input
            label="Name"
            defaultValue={apiKeyName}
            inputState={InputState.ReadOnly}
            tooltipPlacement="top"
            tooltipText="You cannot rename the API key."
            {...getDataUiInputAttribute(DataUiInput.EntityName)}
          />
        )}
        renderEnvironmentsSelector={() => (
          <ReadonlyMapiEnvironmentsSelector
            allEnvironmentOptions={allEnvironmentOptions}
            apiKey={apiKey}
          />
        )}
      />
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={isDirty}
        isBeingSaved={false}
        onSaveChanges={unsavedNavigationHandler}
      />
      {isElement(apiKeyDetailToolbarSaveButtonContainerElement) &&
        createPortal(
          <Button
            buttonStyle="primary"
            onClick={onSaveChanges}
            {...getDataUiActionAttribute(DataUiAction.Save)}
          >
            Save changes
          </Button>,
          apiKeyDetailToolbarSaveButtonContainerElement,
        )}
    </ApiKeyDetailLayout>
  );
};
