import { Action } from '../../../../../@types/Action.type.ts';
import { Data_Collections_Success } from '../../../../../data/constants/dataActionTypes.ts';
import {
  Collections_Listing_Left,
  Collections_Save_Finished,
} from '../../constants/actionTypes.ts';

const emptyState = '';

export const editedCollectionsETag = (state = emptyState, action: Action): string => {
  switch (action.type) {
    case Collections_Save_Finished:
    case Data_Collections_Success:
      return action.payload.eTag;

    case Collections_Listing_Left:
      return emptyState;

    default:
      return state;
  }
};
