import classNames from 'classnames';
import { ReactNode, RefObject, useRef } from 'react';
import { StatusBarLogo } from './components/StatusBarLogo.tsx';

export enum ContainerContentType {
  Static = '--with-static-content',
  Shrinkable = '--with-shrinkable-content',
}

interface Props {
  readonly centralContentType?: ContainerContentType;
  readonly renderLeftContainer: () => ReactNode;
  readonly renderCentralContainer: (ref: RefObject<HTMLDivElement>) => ReactNode;
  readonly renderRightContainer: () => ReactNode;
  readonly rightContentType?: ContainerContentType;
}

export const StatusBarRightPaneId = 'status-bar-right-pane';

export const StatusBarContainer = ({
  centralContentType = ContainerContentType.Shrinkable,
  renderLeftContainer,
  renderRightContainer,
  renderCentralContainer,
  rightContentType = ContainerContentType.Static,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <StatusBarLogo />
      <div className="status-bar__left-pane">{renderLeftContainer()}</div>
      <div
        className={classNames(
          'status-bar__central-pane',
          `status-bar__central-pane${centralContentType}`,
        )}
        ref={ref}
      >
        {renderCentralContainer(ref)}
      </div>
      <div
        className={classNames(
          'status-bar__right-pane',
          `status-bar__right-pane${rightContentType}`,
        )}
        id={StatusBarRightPaneId}
      >
        {renderRightContainer()}
      </div>
    </>
  );
};
