import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Link } from 'react-router-dom';
import {
  DataUiStatusBarActions,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly to: string;
};

export const ContentItemRevisionToolbarActions = ({ to }: Props) => (
  <Link
    className="u-no-text-decoration"
    to={to}
    {...getDataUiActionAttribute(DataUiStatusBarActions.CloseRevisions)}
  >
    <Button buttonStyle="tertiary" size="medium">
      <Button.Icon icon={Icons.Times} />
      Close
    </Button>
  </Link>
);
