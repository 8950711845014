import { Label } from '@kontent-ai/component-library/Label';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { TagContext } from '../TagContext.tsx';
import { tagLabelSize } from '../tokens.ts';

type Props = Readonly<{
  children: string | number;
}>;

const StyledTagLabel = styled(Label)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TagLabel: React.FC<Props> = (props) => {
  const context = useContext(TagContext);

  if (!context) {
    throw new Error('Tag.Label must be used within a Tag component');
  }

  const { labelColor, labelRef, setLabelText } = context;

  useEffect(() => {
    setLabelText(props.children.toString());
  }, [props.children, setLabelText]);

  return <StyledTagLabel color={labelColor} size={tagLabelSize} ref={labelRef} {...props} />;
};

TagLabel.displayName = 'TagLabel';
