import Immutable from 'immutable';

const getNumberOfChunks = (total: number, maxBatchSize: number): number =>
  Math.ceil(total / maxBatchSize);

const range = (num: number): number[] => [...Array(num).keys()];

export function reduceInChunks<K, V, T>(
  inputData: Immutable.Collection<K, V>,
  maxChunkSize: number,
  action: (
    previousValue: T,
    currentValue: Immutable.Iterable<unknown, V>,
    currentIndex: number,
    array: Immutable.Iterable<unknown, V>[],
  ) => T,
  initialValue: T,
): T {
  const chunks = range(getNumberOfChunks(inputData.size, maxChunkSize)).map((page) =>
    inputData.skip(page * maxChunkSize).take(maxChunkSize),
  );

  return chunks.reduce(action, initialValue);
}
