import { IWorkflowOptionSelectItem } from '../components/settings/filters/EntityWebhookExtendedWorkflowStepFilterSelector.tsx';
import { IStepSelectItem } from '../components/settings/filters/EntityWebhookWorkflowStepFilterSelector.tsx';
import { anyWorkflowStepWorkflowStep } from '../constants/anyWorkflowStepWorkflowStep.ts';

export const handleAnyWorkflowStepWorkflowStepOnWorkflowStepsChangedInFormInput = <
  TWorkflowStep extends IStepSelectItem & IWorkflowOptionSelectItem,
>(
  formValues: ReadonlyArray<TWorkflowStep>,
  previousValues: ReadonlyArray<TWorkflowStep>,
): ReadonlyArray<TWorkflowStep> => {
  const oldValuesContainAnyWorkflowStep = previousValues.some(
    (workflowStep) => workflowStep.id === anyWorkflowStepWorkflowStep.id,
  );
  const newValuesContainAnyWorkflowStep = formValues.some(
    (workflowStep) => workflowStep.id === anyWorkflowStepWorkflowStep.id,
  );
  const newValuesContainNormalWorkflowStep = formValues.some(
    (workflowStep) => workflowStep.id !== anyWorkflowStepWorkflowStep.id,
  );
  return (oldValuesContainAnyWorkflowStep && newValuesContainNormalWorkflowStep) ||
    !newValuesContainAnyWorkflowStep
    ? formValues.filter((workflowStep) => workflowStep.id !== anyWorkflowStepWorkflowStep.id)
    : formValues.filter((workflowStep) => workflowStep.id === anyWorkflowStepWorkflowStep.id);
};
