import { isAbortError } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ILoadListingItemsAction } from '../../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import {
  Relations_ExpandNode_Failed,
  Relations_ExpandNode_Finished,
  Relations_ExpandNode_Started,
} from '../../constants/relationsActionTypes.ts';
import { UnableToExpandNodeErrorMessage } from '../../constants/uiConstants.ts';
import { IExpandedNodeData } from '../../reducers/expandedNodesData.ts';
import { getModularItemIds } from '../../utils/relationsUtils.ts';

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
}

interface IParams {
  readonly itemId: Uuid;
  readonly nodeId: string;
}

const started = (nodeId: string, nodeData: IExpandedNodeData) =>
  ({
    type: Relations_ExpandNode_Started,
    payload: {
      nodeData,
      nodeId,
    },
  }) as const;

const finished = (nodeId: string) =>
  ({
    type: Relations_ExpandNode_Finished,
    payload: {
      nodeId,
    },
  }) as const;

const failed = (nodeId: string, errorMessage: string) =>
  ({
    type: Relations_ExpandNode_Failed,
    payload: {
      nodeId,
      errorMessage,
    },
  }) as const;

export type ExpandRelationsNodeActionsType = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const expandRelationsNodeActionCreator =
  (deps: IDeps) =>
  ({ itemId, nodeId }: IParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { listingContentItems },
      relationsApp: { initialized },
    } = getState();

    const loadedItemsId = initialized
      ? Immutable.Set<Uuid>(listingContentItems.byId.keys())
      : Immutable.Set<Uuid>();

    try {
      const item = listingContentItems.byId.get(itemId);
      assert(!!item, () => `Listing item with id "${itemId}" not found in the store.`);
      const modularItemIds = getModularItemIds(item);

      dispatch(
        started(nodeId, {
          itemId,
          childrenIds: modularItemIds.toArray(),
        }),
      );

      const itemsToLoad = modularItemIds.subtract(loadedItemsId).toArray();
      await dispatch(deps.loadListingItems(itemsToLoad, abortSignal));

      dispatch(finished(nodeId));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(nodeId, UnableToExpandNodeErrorMessage));
      }

      throw error;
    }
  };
