import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../_shared/components/AppBar/BackNavigation.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getGoBackLinkByPath } from '../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';

export const RoleBackNavigation: React.FC = () => {
  const location = useLocation();

  const isDisabled = useSelector(
    (s) => s.rolesApp.editorUi.status === RoleEditorStatus.IsBeingSaved,
  );

  return <BackNavigation to={getGoBackLinkByPath(location.pathname)} isDisabled={isDisabled} />;
};

RoleBackNavigation.displayName = 'RoleBackNavigation';
