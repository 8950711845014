import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  isAssetCollectionMandatoryForCurrentSubscription,
  isAssetCollectionMandatoryForCurrentSubscriptionOrProject,
} from '../../../selectors/allowedFeaturesUtils.ts';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { useGetFeatureActivationState } from '../../hooks/useGetFeatureActivationState.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';
import { FeatureActivationDialog } from '../FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from '../InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericConsent } from '../InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../InnovationLabGenericDeactivationWarning.tsx';

export const AssetCollectionMandatoryCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const isEnabledAtSubscriptionLevel = useSelector(
    isAssetCollectionMandatoryForCurrentSubscription,
  );
  const activationState = useGetFeatureActivationState(
    AssetCollectionMandatoryCard.isFeatureEnabledSelector,
  );
  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('asset-collection-mandatory'),
    deactivateInnovationLabFeature('asset-collection-mandatory'),
  );

  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard component="section" featureState={featureState}>
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {AssetCollectionMandatoryCard.featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>Improve your content governance by enforcing that all assets are in collections.</p>
            <p>
              With{' '}
              <OutwardLink href="https://kontent.ai/learn/docs/collections#a-govern-assets-with-role-permissions-in-collections">
                assets assigned to collections
              </OutwardLink>
              , you gain greater control over your content operations.
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
            />
          </Stack>
        </Card.Body>
        <Card.Footer>
          <InnovationLabFeatureButton
            activationState={activationState}
            canBeDeactivated={!isEnabledAtSubscriptionLevel}
            deactivationUnavailableExplanation="This feature is enabled for the entire subscription. You can’t deactivate it for individual projects."
            featureName="mandatory collections for assets"
            featureState={featureState}
            isAsyncOperationInProgress={isAsyncOperationInProgress}
            onClick={onCardActionButtonClick}
          />
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline="Activate mandatory collections for assets"
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <Callout calloutType="quickTip" hideSubheadline>
            <p>
              <b>
                Make sure you understand the effects of the feature and that you’re ready to use it:
              </b>
            </p>
            <ul>
              <li>All newly added assets need to be assigned to a collection.</li>
              <li>
                Assets that are without a collection will stay as they are until you assign them to
                a collection.
              </li>
              <li>
                You can move assets among collections but not leave them without a collection.
              </li>
              <li>
                Ensure correct role permissions setup so that everyone can access the assets they
                need.
              </li>
            </ul>
          </Callout>
          <InnovationLabGenericConsent />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline="Deactivate mandatory collections for assets"
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName="mandatory collections for assets" />
      </FeatureDeactivationDialog>
    </>
  );
};

AssetCollectionMandatoryCard.isFeatureEnabledSelector =
  isAssetCollectionMandatoryForCurrentSubscriptionOrProject;
AssetCollectionMandatoryCard.featureName = 'Mandatory collections for assets';
AssetCollectionMandatoryCard.releaseDate = '2023-06-14T00:00:00.000Z';
