import { AppNames } from '../../../_shared/constants/applicationNames.ts';

export const ContentItemDeletedMessage = 'This content item has been deleted.';
export const ContentItemNotFoundLinkText = `Go to ${AppNames.Content} to find it yourself »`;
export const ContentItemNotFoundMessage =
  'The link seems to be broken, we couldn’t locate your content item.';
export const CreateNewContentItemLinkText = `Go to ${AppNames.Content} to create a new content item »`;
export const LinkIsInvalidMessage = 'The link is invalid.';
export const NoAccessToProjectLinkText = 'Sign in to another project »';
export const NoAccessToProjectMessage =
  'You haven’t been granted access to this project yet. Ask the project manager (or the person who sent you this link) to grant you access to the project.';
export const ProjectNotFoundLinkText = 'Go to content inventory to see your other projects »';
export const ProjectNotFoundMessage =
  'The link seems to be broken, we couldn’t find the project you were invited to.';
export const SharedPreviewLinkDoesNotLeadAnywhereMessage = 'This link doesn’t lead anywhere.';
export const SharedLinkContentDoesNotExistMessage = `${SharedPreviewLinkDoesNotLeadAnywhereMessage} The specified content item doesn’t exist in this project.`;
export const SharedLinkHasInvalidVariantIdMessage = `${SharedPreviewLinkDoesNotLeadAnywhereMessage} Check the variant ID in the URL for typos.`;
export const SharedLinkHasNoUrlQueryParametersMessage = `${SharedPreviewLinkDoesNotLeadAnywhereMessage} Check if the preview URL in the “url” parameter is correct.`;
export const SharedLinkUserDoesNotHavePermissionToItemVariantMessage =
  'You don’t have permission to view this content item. Ask your project manager to grant you access to this item.';
export const SharedPreviewLinkHasInvalidSpaceIdMessage = `${SharedPreviewLinkDoesNotLeadAnywhereMessage} Check the space ID in the URL for typos.`;
export const SharedPreviewLinkUnsuccessfulRedirectText = `Go to ${AppNames.WebSpotlight}`;
