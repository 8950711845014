import { SelectionState } from 'draft-js';
import { isImageMimeType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { isImage } from '../../../utils/blocks/blockTypeUtils.ts';
import { getBlockKey } from '../../../utils/blocks/editorBlockGetters.ts';
import {
  IRawBlock,
  RawEntityMap,
  createContent,
  createRawBlock,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { createSelection, setContentSelection } from '../../../utils/editorSelectionUtils.ts';
import { getBlocks, getEntityMap } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  insertBlocksAtSelection,
} from '../../../utils/general/editorContentUtils.ts';
import { EntityMutability, EntityType } from '../../entityApi/api/Entity.ts';
import {
  getImageAssetReference,
  getImageRawBlocks,
  setImageAssetId,
} from '../../images/api/editorImageUtils.ts';
import { isLink } from '../../links/api/LinkEntity.ts';
import { LinkType } from '../../links/api/LinkType.ts';
import { createLinkWithText, getLinkType } from '../../links/api/editorLinkUtils.ts';
import { UploadedAsset } from './EditorUploadFilesApi.type.ts';

type ContentToInsert = {
  readonly entityMap: RawEntityMap;
  readonly blocks: ReadonlyArray<IRawBlock>;
};

export function insertFiles(
  input: IContentChangeInput,
  files: ReadonlyArray<UploadedAsset>,
): IContentChangeResult {
  const initialContentToInsert: ContentToInsert = {
    entityMap: {},
    blocks: [],
  };
  const contentToInsert = files.reduce((reduced, file, index) => {
    if (isImageMimeType(file.file.type)) {
      const imageBlocks = getImageRawBlocks([file.assetId], true);

      return {
        entityMap: reduced.entityMap,
        blocks: [...reduced.blocks, ...imageBlocks],
      };
    }

    const entityKey = index;
    const linkData = {
      assetId: file.assetId,
      uploading: true,
    };
    const text = file.file.name;
    const newEntityMap: RawEntityMap = {
      ...reduced.entityMap,
      [entityKey]: {
        type: EntityType.Link,
        mutability: EntityMutability.Mutable,
        data: linkData,
      },
    };

    const linkBlock = createRawBlock({
      type: BlockType.Unstyled,
      text,
      entityRanges: [
        {
          key: entityKey,
          offset: 0,
          length: text.length,
        },
      ],
    });

    return {
      entityMap: newEntityMap,
      blocks: [...reduced.blocks, linkBlock],
    };
  }, initialContentToInsert);

  return insertBlocksAtSelection(input, contentToInsert.blocks, contentToInsert.entityMap);
}

export function replaceAsset(
  input: IContentChangeInput,
  oldAssetId: Uuid,
  newAssetId: Uuid,
): IContentChangeResult {
  const { content, selection } = input;
  const blocks = getBlocks(content);

  const assetLinkSelections: SelectionState[] = [];
  const entityMap = getEntityMap(content);
  const blocksWithImagesReplaced = blocks.map((block) => {
    if (isImage(block)) {
      const assetReference = getImageAssetReference(block);
      return assetReference?.id === oldAssetId ? setImageAssetId(block, newAssetId, false) : block;
    }

    // For non-image blocks try to locate the links to asset
    const blockKey = getBlockKey(block);

    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (!entityKey) {
          return false;
        }
        const entity = entityMap.__get(entityKey);
        if (!isLink(entity) || getLinkType(entity) !== LinkType.Asset) {
          return false;
        }

        const { assetId } = entity.getData();
        return assetId === oldAssetId;
      },
      (start, end) => assetLinkSelections.push(createSelection(blockKey, start, blockKey, end)),
    );
    return block;
  });

  const withImagesReplaced = createContent(blocksWithImagesReplaced);
  const withLinksReplaced = assetLinkSelections.reduce(
    (updatedContent, assetLinkSelection) =>
      createLinkWithText(
        { content: updatedContent, selection: assetLinkSelection },
        '',
        { assetId: newAssetId },
        true,
      ).content,
    withImagesReplaced,
  );

  const newContentWithOriginalSelection = setContentSelection(
    withLinksReplaced,
    selection,
    selection,
  );
  return {
    content: newContentWithOriginalSelection,
    selection,
    wasModified: true,
  };
}
