import { assert } from '@kontent-ai/utils';
import { useLocation } from 'react-router';
import {
  ContentItemPreviewRoute,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../_shared/utils/routing/routeTransitionUtils.ts';

export interface IWebSpotlightTabRoutes {
  readonly previewPath: string;
  readonly editorPath: string;
  readonly previewMatchedParams: ContentItemRouteParams<string> | null;
}

export const useWebSpotlightTabRoutes = (): IWebSpotlightTabRoutes => {
  const location = useLocation();

  const matchedParams = matchPath<ContentItemRouteParams<string>>(
    location.pathname,
    ContentItemRoute,
  );
  assert(matchedParams, () => 'Current route does not fit to ContentItemRouteParams');

  const tabParams: ContentItemRouteParams<UuidArray> = {
    ...matchedParams,
    contentItemIds: parseContentItemIds(matchedParams.contentItemIds),
  };

  const previewPath = buildPath<ContentItemRouteParams<UuidArray>>(
    ContentItemPreviewRoute,
    tabParams,
  );
  const editorPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, tabParams);
  const previewMatchedParams = matchPath<ContentItemRouteParams<string>>(
    location.pathname,
    ContentItemPreviewRoute,
  );

  return {
    previewPath,
    editorPath,
    previewMatchedParams,
  };
};
