import { Action } from '../../../../@types/Action.type.ts';
import { ContentType_LoadComponentUsage_Dismissed } from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import { Data_ContentTypeComponentUsage_Finished } from '../../../constants/dataActionTypes.ts';

const initialState = null;

export function continuationToken(state = initialState, action: Action): string | null {
  switch (action.type) {
    case Data_ContentTypeComponentUsage_Finished: {
      const payload = action.payload;
      return payload.token || null;
    }

    case ContentType_LoadComponentUsage_Dismissed:
      return initialState;

    default:
      return state;
  }
}
