import { Redirect, Route, Switch } from 'react-router';
import { RedirectIf } from '../../_shared/components/routing/RedirectIf.tsx';
import { AppNames } from '../../_shared/constants/applicationNames.ts';
import {
  AccessDeniedGeneralSettingsRoute,
  AccessDeniedRoute,
  AccessDeniedRouteParams,
  PageNotFoundRoute,
  RootRoute,
  WarningRoute,
} from '../../_shared/constants/routePaths.ts';
import { IRouteContext, RouteContext } from '../../_shared/containers/routing/RouteContext.tsx';
import { AccessDenied } from './components/AccessDenied.tsx';
import { isAnyKnownAppName } from './utils/isAnyKnownAppName.ts';

export const getErrorHandlingRoutes = () => {
  return (
    <Route key={AppNames.ErrorHandling} path={WarningRoute}>
      <Switch>
        <Route exact path={WarningRoute}>
          <Redirect to={PageNotFoundRoute} />
        </Route>
        <Route path={PageNotFoundRoute} exact>
          <Redirect to={RootRoute} />
        </Route>
        <Route path={AccessDeniedGeneralSettingsRoute}>
          <AccessDenied
            title="Do you need to change the general settings of project?"
            descriptionParagraphs={[
              'You can’t make any changes here.',
              'Contact your subscription admin for more information.',
            ]}
          />
        </Route>
        <Route path={AccessDeniedRoute}>
          <RouteContext>
            {({ match }: IRouteContext<AccessDeniedRouteParams>) => (
              <RedirectIf
                redirectTo={RootRoute}
                doRedirect={!isAnyKnownAppName(decodeURIComponent(match.params.requestedAppName))}
              >
                <AccessDenied
                  title={`Do you need to work with ${decodeURIComponent(
                    match.params.requestedAppName,
                  )}?`}
                  descriptionParagraphs={[
                    'You can’t make any changes here.',
                    'Contact your project manager for more information.',
                  ]}
                />
              </RedirectIf>
            )}
          </RouteContext>
        </Route>
      </Switch>
    </Route>
  );
};
