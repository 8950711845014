import { assert } from '@kontent-ai/utils';
import { ReactNode, createContext, useContext, useRef } from 'react';
import { AiMessageBuffer, createAiMessageBuffer } from '../utils/aiTasks/aiMessageBuffer.ts';

const AiMessageBufferContext = createContext<AiMessageBuffer | null>(null);
AiMessageBufferContext.displayName = 'AiMessageBuffer';

type Props = {
  readonly children: ReactNode;
};

export const AiMessageBufferProvider = (props: Props) => {
  const { current: aiMessageBuffer } = useRef(createAiMessageBuffer());

  return (
    <AiMessageBufferContext.Provider value={aiMessageBuffer}>
      {props.children}
    </AiMessageBufferContext.Provider>
  );
};

export const useAiMessageBuffer = (): AiMessageBuffer => {
  const buffer = useContext(AiMessageBufferContext);
  assert(buffer, () => `"${AiMessageBufferContext.displayName}" is missing in the current tree.`);
  return buffer;
};
