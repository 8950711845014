import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { format } from 'date-fns';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

export const DateTimePickerTitle = () => (
  <div className="datetime-picker__title">Select a date</div>
);

type DateTimePickerHeaderProps = {
  readonly changeMonth: (month: number) => void;
  readonly changeYear: (year: number) => void;
  readonly date: Date;
  readonly decreaseMonth: () => void;
  readonly increaseMonth: () => void;
  readonly nextMonthButtonDisabled: boolean;
  readonly prevMonthButtonDisabled: boolean;
};

export const DateTimePickerHeader = (inline?: boolean) => (props: DateTimePickerHeaderProps) => {
  const readableDateTime = format(props.date, 'MMMM yyyy');

  return (
    <>
      {!inline && <DateTimePickerTitle />}
      <div className="datetime-picker__header">
        <QuinaryButton
          tooltipText="Select previous month"
          onClick={props.prevMonthButtonDisabled ? undefined : props.decreaseMonth}
          disabled={props.prevMonthButtonDisabled}
          {...getDataUiActionAttribute(DataUiAction.SelectPreviousMonth)}
        >
          <QuinaryButton.Icon icon={Icons.ChevronLeft} />
        </QuinaryButton>
        <div
          className="datetime-picker__current-month"
          {...getDataUiElementAttribute(DataUiElement.DateTimePickerCurrentMonth)}
        >
          {readableDateTime}
        </div>
        <QuinaryButton
          tooltipText="Select next month"
          onClick={props.nextMonthButtonDisabled ? undefined : props.increaseMonth}
          disabled={props.nextMonthButtonDisabled}
          {...getDataUiActionAttribute(DataUiAction.SelectNextMonth)}
        >
          <QuinaryButton.Icon icon={Icons.ChevronRight} />
        </QuinaryButton>
      </div>
    </>
  );
};
