import { IVariantTranslationTaskServerModel } from '../../../repositories/serverModels/VariantTranslationTaskServerModel.type.ts';
import {
  DurableTaskStatus,
  VariantTranslationTaskParams,
} from '../../services/signalR/VariantTranslationTaskParams.type.ts';
import { VariantTranslationParams, VariantTranslationTask } from './VariantTranslationTask.type.ts';
import { ElementTranslationStatus, VariantTranslationStatus } from './variantTranslationStatus.ts';

const getOverallStatus = (message: VariantTranslationTaskParams): VariantTranslationStatus => {
  if (message.allElementIds.length > 0 && message.failedElementIds.length > 0) {
    return message.successfulElementIds.length === 0
      ? VariantTranslationStatus.Failed
      : VariantTranslationStatus.PartiallyFailed;
  }

  switch (message.status) {
    case DurableTaskStatus.Completed:
      return VariantTranslationStatus.Completed;
    case DurableTaskStatus.Terminated:
      return VariantTranslationStatus.Cancelled;
    case DurableTaskStatus.Running:
    case DurableTaskStatus.New:
      return VariantTranslationStatus.InProgress;
  }
};

const getElementTranslationStatus = (
  elementId: Uuid,
  message: VariantTranslationTaskParams,
): ElementTranslationStatus => {
  if (message.successfulElementIds.includes(elementId)) {
    return ElementTranslationStatus.Succeeded;
  }

  if (message.failedElementIds.includes(elementId)) {
    return ElementTranslationStatus.Failed;
  }

  if (message.skippedElementIds.includes(elementId)) {
    return ElementTranslationStatus.Skipped;
  }

  return ElementTranslationStatus.Failed;
};

const getStatusPerElement = (
  message: VariantTranslationTaskParams,
): ReadonlyMap<Uuid, ElementTranslationStatus> =>
  new Map(
    message.allElementIds.map((elementId) => [
      elementId,
      getElementTranslationStatus(elementId, message),
    ]),
  );

const getParams = (message: VariantTranslationTaskParams): VariantTranslationParams => ({
  aiSessionId: message.aiSessionId,
  createdBy: message.createdBy,
  itemId: message.itemId,
  projectEnvironmentId: message.projectEnvironmentId,
  sourceLanguageId: message.sourceLanguageId,
  variantId: message.variantId,
});

export const convertToTask = (message: VariantTranslationTaskParams): VariantTranslationTask => ({
  params: getParams(message),
  statusPerElement: getStatusPerElement(message),
  overallProgress: getOverallStatus(message),
  completedAt: message.completedAt,
});

export const getVariantTaskFromServerModel = (
  projectEnvironmentId: Uuid,
  translationTaskServerModel: IVariantTranslationTaskServerModel,
): VariantTranslationTaskParams => ({
  projectEnvironmentId,
  aiSessionId: translationTaskServerModel.aiSessionId,
  allElementIds: translationTaskServerModel.processedElementIds,
  createdBy: translationTaskServerModel.createdBy,
  failedElementIds: translationTaskServerModel.failedElementIds,
  itemId: translationTaskServerModel.itemId,
  skippedElementIds: translationTaskServerModel.skippedElementIds,
  sourceLanguageId: translationTaskServerModel.sourceLanguageId,
  status: translationTaskServerModel.status,
  successfulElementIds: translationTaskServerModel.translatedElementIds,
  variantId: translationTaskServerModel.variantId,
  completedAt: translationTaskServerModel.completedAt,
});
