import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ListingFilter_CreateLinkedFilter_Failed,
  ListingFilter_CreateLinkedFilter_Finished,
  ListingFilter_CreateLinkedFilter_Started,
} from '../../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialState: boolean = false;

export function currentFilterLinkLoading(state = initialState, action: Action) {
  switch (action.type) {
    case ListingFilter_CreateLinkedFilter_Started:
      return true;

    case ListingFilter_CreateLinkedFilter_Failed:
    case ListingFilter_CreateLinkedFilter_Finished:
      return false;

    default:
      return state;
  }
}
