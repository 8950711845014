import { ContentState } from 'draft-js';
import { ActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { ResultSelector } from '../../../../../_shared/features/AI/types/ResultSelector.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { createSimpleTextFromContentState } from '../../../utils/editorSimpleTextValueUtils.ts';

export const createAiActionResultSelector = (
  elementType: ElementType,
  resultSelector: ResultSelector<ActionResult<ContentState>>,
): ResultSelector<ActionResult<ContentState>> =>
  elementType === ElementType.RichText
    ? resultSelector
    : compose(createSimpleTextContent, resultSelector);

const createSimpleTextContent = (
  original: ActionResult<ContentState>,
): ActionResult<ContentState> => ({
  ...original,
  content: original.content && createSimpleTextFromContentState(original.content),
});
