import { Collection } from '@kontent-ai/utils';
import { VariantTranslationTaskParams } from '../../services/signalR/VariantTranslationTaskParams.type.ts';
import { VariantTranslationParams, VariantTranslationTask } from './VariantTranslationTask.type.ts';
import { VariantTranslationStatus } from './variantTranslationStatus.ts';
import { convertToTask } from './variantTranslationTaskConversionUtils.ts';
import {
  VariantTranslationTaskId,
  makeVariantTranslationTaskId,
} from './variantTranslationTaskId.ts';

type Listener = () => void;
type Unsubscribe = () => void;

const getUpdateTasks = (
  tasks: ReadonlyMap<VariantTranslationTaskId, VariantTranslationTask>,
  taskId: VariantTranslationTaskId,
  task: VariantTranslationTask,
): ReadonlyMap<VariantTranslationTaskId, VariantTranslationTask> => {
  if (!tasks.has(taskId)) {
    return Collection.add(tasks, [taskId, task]);
  }

  const oldTask = tasks.get(taskId);
  if (oldTask?.overallProgress !== task.overallProgress) {
    return Collection.replace(tasks, taskId, task);
  }

  if (
    oldTask.overallProgress === VariantTranslationStatus.InProgress &&
    task.overallProgress === VariantTranslationStatus.InProgress &&
    task.statusPerElement.size > oldTask.statusPerElement.size
  ) {
    return Collection.replace(tasks, taskId, task);
  }

  return tasks;
};

export const createVariantTranslationTaskManager = () => {
  let tasks: ReadonlyMap<VariantTranslationTaskId, VariantTranslationTask> = new Map();
  let listeners: ReadonlySet<Listener> = new Set();

  const createTask = async (
    taskId: VariantTranslationTaskId,
    params: VariantTranslationParams,
    startTranslation: (parameters: VariantTranslationParams) => Promise<void>,
  ): Promise<void> => {
    await startTranslation(params);
    const task: VariantTranslationTask = {
      params,
      overallProgress: VariantTranslationStatus.InProgress,
      statusPerElement: new Map(),
      completedAt: null,
    };

    tasks = Collection.add(tasks, [taskId, task]);

    notifyListeners();
  };

  const cancelTask = async (
    taskId: VariantTranslationTaskId,
    cancelTranslation: () => Promise<void>,
  ): Promise<void> => {
    await cancelTranslation();

    const cancelledTask = tasks.get(taskId);
    if (cancelledTask) {
      tasks = Collection.replace(tasks, taskId, {
        ...cancelledTask,
        overallProgress: VariantTranslationStatus.Cancelled,
      });
    }

    notifyListeners();
  };

  const getTask = (taskId: VariantTranslationTaskId): VariantTranslationTask | null =>
    tasks.get(taskId) ?? null;

  const messageReceived = (message: VariantTranslationTaskParams): VariantTranslationTask => {
    const taskId = makeVariantTranslationTaskId(
      message.projectEnvironmentId,
      message.itemId,
      message.variantId,
    );

    const task = convertToTask(message);
    tasks = getUpdateTasks(tasks, taskId, task);

    notifyListeners();

    return task;
  };

  const subscribe = (listener: Listener): Unsubscribe => {
    listeners = Collection.add(listeners, listener);
    return () => {
      listeners = Collection.remove(listeners, listener);
    };
  };

  const notifyListeners = (): void => listeners.forEach((listener) => listener());

  return {
    cancelTask,
    createTask,
    getTask,
    messageReceived,
    subscribe,
  };
};
