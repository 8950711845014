import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import { IUiPreferenceRepository } from './interfaces/IUiPreferenceRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());

export enum UiPreference {
  collectionsRecentlyUsedInEditing = 'collectionsRecentlyUsedInEditing',
  collectionsRecentlyUsedInListing = 'collectionsRecentlyUsedInListing',
  contentTypesRecentlyUsedInEditing = 'contentTypesRecentlyUsedInEditing',
  contentTypesRecentlyUsedInListing = 'contentTypesRecentlyUsedInListing',
}

export const uiPreferenceRepository: RepositoryWithContext<IUiPreferenceRepository> = {
  async getOne<T>(
    requestContext: IRequestContext,
    name: string,
    abortSignal?: AbortSignal,
  ): Promise<T | null> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/user-ui-preference/${name}`;
    try {
      return await restProvider.get(url, null, abortSignal, requestContext);
    } catch {
      return null;
    }
  },
  upsertOne(
    requestContext: IRequestContext,
    name: string,
    value: string,
    abortSignal?: AbortSignal,
  ): Promise<void> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/user-ui-preference/${name}`;
    return restProvider.put(url, value, abortSignal, requestContext);
  },
};
