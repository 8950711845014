import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../_shared/actions/sharedActions.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { createTaxonomyGroupServerModel } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { TaxonomyTermEditorIsNotConfirmed } from '../../../shared/constants/errorMessageTemplates.ts';
import { CreateTaxonomyGroupErrorMessage } from '../../constants/uiConstants.ts';
import {
  taxonomyGroupCreationFailed,
  taxonomyGroupCreationFinished,
  taxonomyGroupCreationStarted,
} from '../taxonomyActions.ts';

interface ICreateNewTaxonomyGroupActionDependencies {
  readonly taxonomyRepository: {
    readonly createTaxonomyGroup: (
      taxonomyGroup: ITaxonomyGroupServerModel,
    ) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly trackUserEvent: TrackUserEventWithDataAction;
  readonly loadTaxonomyGroups: () => ThunkPromise;
}

export const createCreateNewTaxonomyGroupAction =
  ({
    taxonomyRepository,
    trackUserEvent,
    loadTaxonomyGroups,
  }: ICreateNewTaxonomyGroupActionDependencies) =>
  (): ThunkPromise<ITaxonomyGroupServerModel> =>
  async (dispatch, getState) => {
    const { editedTaxonomyGroup, editedTaxonomyTermId, isCreateNewFormDisplayed } =
      getState().taxonomyApp;

    if (editedTaxonomyTermId || isCreateNewFormDisplayed) {
      dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
      throw new Error(TaxonomyTermEditorIsNotConfirmed);
    }

    dispatch(taxonomyGroupCreationStarted());

    const newTaxonomyGroup = createTaxonomyGroupServerModel(editedTaxonomyGroup);

    try {
      const taxonomyGroup = await taxonomyRepository.createTaxonomyGroup(newTaxonomyGroup);
      await dispatch(loadTaxonomyGroups());
      dispatch(taxonomyGroupCreationFinished(taxonomyGroup));

      dispatch(
        trackUserEvent(TrackedEvent.TaxonomyGroupCreated, {
          'taxonomy-group-id': taxonomyGroup.id,
        }),
      );
      return taxonomyGroup;
    } catch (error) {
      dispatch(taxonomyGroupCreationFailed(CreateTaxonomyGroupErrorMessage));
      throw error;
    }
  };
