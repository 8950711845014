import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Textarea } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { ChangeEvent, forwardRef, useState } from 'react';
import { ModalDialog } from '../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { modalDismissed } from '../../actions/sharedActions.ts';
import { sendUserFeedback } from '../../actions/thunkSharedActions.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IAnimatedModalDialogProps } from '../ModalDialog/IAnimatedModalDialogProps.type.ts';

const textareaWidth = 90 * gridUnit;
const textareaRowsCount = 10;

export const SendYourFeedbackDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const [feedbackText, setFeedbackText] = useState<string>('');
    const [isContactMeChecked, setIsContactMeChecked] = useState(false);

    const onFeedbackTextChanged = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      setFeedbackText(event.currentTarget.value);
    };
    const onToggleContactMe = (): void => {
      setIsContactMeChecked(!isContactMeChecked);
    };

    const dispatch = useDispatch();
    const onClose = () => dispatch(modalDismissed());
    const onSave = () => dispatch(sendUserFeedback(feedbackText, isContactMeChecked));

    return (
      <ModalDialog
        headline="Send your feedback on Kontent.ai"
        isOpen={isOpen}
        isDismissable
        ref={ref}
        onClose={onClose}
        primaryAction={{
          disabled: !feedbackText,
          onClick: onSave,
          text: 'Send',
          ...getDataUiActionAttribute(DataUiAction.Confirm),
        }}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
        minWidth={px(textareaWidth).toString()}
      >
        <Stack spacing={Spacing.XL}>
          <Textarea
            placeholder="I have feedback on..."
            value={feedbackText}
            onChange={onFeedbackTextChanged}
            rows={textareaRowsCount}
            {...getDataUiInputAttribute(DataUiInput.Text)}
          />
          <Box width="fit-content">
            <Checkbox
              checkboxState="default"
              checked={isContactMeChecked}
              onToggle={onToggleContactMe}
            >
              You can contact me about this
            </Checkbox>
          </Box>
        </Stack>
      </ModalDialog>
    );
  },
);

SendYourFeedbackDialog.displayName = 'SendYourFeedbackDialog';
