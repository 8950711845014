import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useVerticalMenu } from '@kontent-ai/component-library/VerticalMenu';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { DropDownMenuControlled } from '../../../../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { AiTaskProgress } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type ActionStatusProps = {
  readonly availableLanguages: ReadonlyArray<IBaseSelectItem>;
  readonly aiOperationState: AiOperationState;
  readonly otherTasksProgress: AiTaskProgress;
};

type Props = ActionStatusProps & {
  readonly perform: (sourceLanguageId: string) => void;
};

export const AiTranslateButton: React.FC<Props> = (props) => {
  const { availableLanguages, perform } = props;

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } = useVerticalMenu([
    {
      id: 'availableLanguages',
      items: availableLanguages,
      label: 'Translate from',
      type: 'section',
    },
  ]);

  return (
    <DropDownMenuControlled
      onDropDownVisibilityChange={setIsMenuVisible}
      isDropDownVisible={isMenuVisible}
      tippyOptions={{
        placement: 'bottom-end',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-end'],
              },
            },
          ],
        },
        offset: [0, spacingPopupDistance],
      }}
      renderDropDown={() => (
        <VerticalMenu
          {...verticalMenuProps}
          aria-label="Translate from"
          renderItem={({ item }) => (
            <MenuItemWithMouseHover
              menuItemState="default"
              text={item.textValue}
              onPress={() => {
                if (item.value) {
                  perform(item.value.id);
                }
                setIsMenuVisible(false);
              }}
            />
          )}
          state={verticalMenuState}
          contain={false}
        />
      )}
      renderTrigger={(triggerProps) => {
        const disabledTooltipText = getDisabledTooltipText(props);

        return (
          <QuinaryButton
            tooltipText={isMenuVisible ? '' : (disabledTooltipText ?? 'Translate with AI')}
            tooltipPlacement="bottom"
            disabled={!!disabledTooltipText}
            activated={isMenuVisible}
            onClick={() => setIsMenuVisible(true)}
            ref={triggerProps.ref}
            {...getDataUiElementAttribute(DataUiElement.AiTranslateButton)}
          >
            <QuinaryButton.Icon icon={Icons.Translate} />
          </QuinaryButton>
        );
      }}
    />
  );
};

const getDisabledTooltipText = ({
  aiOperationState,
  availableLanguages,
  otherTasksProgress,
}: ActionStatusProps): string | null => {
  if (otherTasksProgress === AiTaskProgress.Running) {
    return 'Another action is running';
  }

  if (aiOperationState === AiOperationState.Pending) {
    return 'Translating';
  }

  if (!availableLanguages.length) {
    return 'Nothing to translate. Provide content in other language variants first.';
  }

  return null;
};
