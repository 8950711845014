import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import {
  ContentTypeSnippetElementType,
  ElementType,
} from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { createValidateAssetTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateAssetTypeElement.ts';
import { createValidateCustomTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateCustomTypeElement.ts';
import { createValidateDateTimeTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateDateTimeTypeElement.ts';
import { createValidateGuidelinesTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateGuidelinesTypeElement.ts';
import { createValidateModularContentTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateModularContentTypeElement.ts';
import { createValidateMultipleChoiceTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateMultipleChoiceTypeElement.ts';
import { createValidateNumberTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateNumberTypeElement.ts';
import { createValidateRichTextTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateRichTextTypeElement.ts';
import { createValidateTaxonomyTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateTaxonomyTypeElement.ts';
import { createValidateTextTypeElementAction } from '../../../shared/actions/thunks/elementValidation/validateTextTypeElement.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { ITypeElementValidationUtilsDependencies } from '../../../shared/utils/typeElementValidators/types/ITypeElementValidationUtilsDependencies.type.ts';
import { ITypeElementValidatorDependencies } from '../../../shared/utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { getConditionValidationDataForSnippetElement } from '../../../shared/utils/typeElementValidators/utils/conditions/getConditionValidationData.ts';
import { createTypeElementValidationUtils } from '../../../shared/utils/typeElementValidators/utils/typeElementValidationUtils.ts';
import { snippetTypeElementValidated } from '../snippetsActions.ts';

const typeElementValidationUtilsDependencies: ITypeElementValidationUtilsDependencies = {
  ValidationConstants,
};

const typeElementValidationUtils = createTypeElementValidationUtils(
  typeElementValidationUtilsDependencies,
);

const dependencies: ITypeElementValidatorDependencies = {
  ValidationConstants,
  getConditionValidationData: getConditionValidationDataForSnippetElement,
  typeElementValidationUtils,
  typeElementValidated: snippetTypeElementValidated,
};

const assetTypeElementValidator = createValidateAssetTypeElementAction(dependencies);
const customTypeElementValidator = createValidateCustomTypeElementAction(dependencies);
const dateTimeTypeElementValidator = createValidateDateTimeTypeElementAction(dependencies);
const guidelinesTypeElementValidator = createValidateGuidelinesTypeElementAction(dependencies);
const modularContentTypeElementValidator =
  createValidateModularContentTypeElementAction(dependencies);
const multipleChoiceTypeElementValidator =
  createValidateMultipleChoiceTypeElementAction(dependencies);
const numberTypeElementValidator = createValidateNumberTypeElementAction(dependencies);
const richTextTypeElementValidator = createValidateRichTextTypeElementAction(dependencies);
const taxonomyTypeElementValidator = createValidateTaxonomyTypeElementAction(dependencies);
const textTypeElementValidator = createValidateTextTypeElementAction(dependencies);

const validatorsMap: ReadonlyRecord<
  ContentTypeSnippetElementType,
  (typeElement: IBaseTypeElementData) => ThunkFunction
> = {
  [ElementType.Asset]: assetTypeElementValidator,
  [ElementType.Subpages]: modularContentTypeElementValidator,
  [ElementType.Custom]: customTypeElementValidator,
  [ElementType.DateTime]: dateTimeTypeElementValidator,
  [ElementType.Guidelines]: guidelinesTypeElementValidator,
  [ElementType.LinkedItems]: modularContentTypeElementValidator,
  [ElementType.MultipleChoice]: multipleChoiceTypeElementValidator,
  [ElementType.Number]: numberTypeElementValidator,
  [ElementType.RichText]: richTextTypeElementValidator,
  [ElementType.Taxonomy]: taxonomyTypeElementValidator,
  [ElementType.Text]: textTypeElementValidator,
};

const isKnownKey = (key: IBaseTypeElementData['type']): key is keyof typeof validatorsMap =>
  (Object.keys(validatorsMap) as ReadonlyArray<IBaseTypeElementData['type']>).includes(key);

export const createValidateTypeSnippetElementAction =
  () =>
  (typeElement: IBaseTypeElementData): ThunkFunction =>
  (dispatch) => {
    if (!isKnownKey(typeElement.type)) {
      throw InvariantException(`No registered validator for type "${typeElement.type}".`);
    }

    const validateElement = validatorsMap[typeElement.type];
    dispatch(validateElement(typeElement));
  };
