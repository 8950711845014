import { IUserIdentifier } from '../../../_shared/models/UserIdentifier.ts';
import { ArchivedStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { IAssignment, emptyAssignment } from '../models/contentItem/Assignment.ts';

export const IncompleteElementAttribute = '[data-validation-passed="false"]';

export const createAssignmentWithArchivedStep = (
  workflowId: Uuid,
  archivedStep: ArchivedStep,
  assignedBy: IUserIdentifier,
  contributors: ReadonlySet<IUserIdentifier> | null,
): IAssignment => {
  return {
    ...emptyAssignment,
    workflowStatus: { ...archivedStep, workflowId },
    assignedBy,
    assignees: contributors ?? new Set<IUserIdentifier>(),
  };
};
