import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { redirectToCompareVersions } from '../../../../../Revisions/actions/thunkRevisionsActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';

export const CompareVersionsAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <EditingActionDynamicDetailProvider
      performAction={(origin) => dispatch(redirectToCompareVersions(origin, history))}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};

CompareVersionsAction.displayName = 'CompareVersionsAction';
