import { ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import React, { useEffect, useState } from 'react';

type ScrollOptionsContextState = {
  readonly scrollOptions: ScrollElementsToViewOptions | undefined;
};

const defaultContext: ScrollOptionsContextState = {
  scrollOptions: undefined,
};

export const ScrollOptionsContext = React.createContext<ScrollOptionsContextState>(defaultContext);

type ScrollContextProviderProps = {
  readonly children: React.ReactNode;
  readonly scrollOptions: ScrollElementsToViewOptions;
};

export const ScrollOptionsContextProvider: React.FC<ScrollContextProviderProps> = ({
  scrollOptions,
  children,
}) => {
  const [value, setValue] = useState<ScrollOptionsContextState>({ scrollOptions });
  useEffect(() => setValue({ scrollOptions }), [scrollOptions]);

  return <ScrollOptionsContext.Provider value={value}>{children}</ScrollOptionsContext.Provider>;
};

ScrollOptionsContextProvider.displayName = 'ScrollOptionsContextProvider';
