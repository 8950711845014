import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalViewer } from '../../../../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../../../../_shared/components/Modal/ModalViewerPosition.ts';
import {
  SmartLinkCommand,
  SmartLinkCommandType,
} from '../../../../../../../_shared/models/SmartLinkCommand.ts';
import { isSmartLinkCommandRelevantToElement } from '../../../../../../../_shared/models/utils/smartLinkCommandUtils.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PreselectedFilterIds } from '../../../../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ILinkedItemsTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import {
  ItemIsAlreadyLinked,
  PageIsAlreadyLinked,
} from '../../../../../../features/ModalContentItemSelector/constants/uiConstants.ts';
import { ModalMultipleContentItemsSelector } from '../../../../../../features/ModalContentItemSelector/containers/ModalMultipleContentItemsSelector.tsx';
import { useItemVariantId } from '../../../../ContentComponent/context/ContentItemContext.tsx';
import { useDeliveryContentComponentId } from '../../../../ContentComponent/hooks/useDeliveryContentComponentId.tsx';
import { LinkedItemsAiAssistant } from '../../../../LinkedItemsAiAssistant/components/LinkedItemsAiAssistant.tsx';
import { NewContentItemDialog } from '../../../../NewContentItem/containers/NewContentItemDialog.tsx';
import { UserRoleCannotCreateNewItem } from '../../../constants/uiConstants.ts';
import {
  getSmartLinkInsertIndex,
  isSmartLinkCommandRelevantToLIEs,
} from '../../../utils/modularContentSmartLinkUtils.ts';

enum LinkedItemsActionType {
  None = 'None',
  LinkItems = 'LinkItems',
  CreateItem = 'CreateItem',
}

type Props = {
  readonly allowedContentTypeIds: ReadonlyArray<Uuid>;
  readonly alreadyLinkedItemIds: Immutable.List<Uuid>;
  readonly autoFocus: boolean | undefined;
  readonly canCreateNewItem: boolean;
  readonly collectionId?: Uuid;
  readonly modalDialogTitle: string;
  readonly onAddExistingItems: (itemsIds: ReadonlyArray<Uuid>, insertAtIndex?: number) => void;
  readonly onCreateNewItem: (insertAtIndex: number | null) => void;
  readonly onOpenNewContentItemDialog: () => void;
  readonly onPrepareNewContentItem: () => void;
  readonly onSmartLinkCommandExecuted: () => void;
  readonly showCreateNewItemButton: boolean;
  readonly smartLinkCommand: SmartLinkCommand | null;
  readonly spaceIds?: ReadonlyArray<Uuid>;
  readonly typeElement: ILinkedItemsTypeElement;
};

export const LinkedItemsActionsPane: React.FC<Props> = ({
  allowedContentTypeIds,
  alreadyLinkedItemIds,
  autoFocus,
  canCreateNewItem,
  collectionId,
  modalDialogTitle,
  onAddExistingItems,
  onCreateNewItem,
  onOpenNewContentItemDialog,
  onPrepareNewContentItem,
  onSmartLinkCommandExecuted,
  showCreateNewItemButton,
  smartLinkCommand,
  spaceIds,
  typeElement,
}) => {
  const [actionType, setActionType] = useState(LinkedItemsActionType.None);
  const [insertAtIndex, setInsertAtIndex] = useState<number | null>(null);

  const { itemId } = useItemVariantId();
  const deliveryContentComponentId = useDeliveryContentComponentId();

  const onSelectAndAssignExistingItems = (itemsIds: ReadonlyArray<Uuid>): void => {
    onAddExistingItems(itemsIds, insertAtIndex ?? undefined);
    finishCurrentAction();
  };

  const finishCurrentAction = useCallback((): void => {
    setInsertAtIndex(null);
    setActionType(LinkedItemsActionType.None);
  }, []);

  const onCreateAndAssignItem = useCallback((): void => {
    setActionType(LinkedItemsActionType.None);
    onCreateNewItem(insertAtIndex);
  }, [onCreateNewItem, insertAtIndex]);

  const createItemFromDialog = useCallback(() => {
    onPrepareNewContentItem();
    onCreateAndAssignItem();
  }, [onPrepareNewContentItem, onCreateAndAssignItem]);

  const initiateLinkingExistingItems = useCallback((insertIndex: number | null = null): void => {
    setInsertAtIndex(insertIndex);
    setActionType(LinkedItemsActionType.LinkItems);
  }, []);

  const initiateCreatingItem = useCallback(
    (insertIndex: number | null = null): void => {
      if (!canCreateNewItem) {
        return;
      }

      setInsertAtIndex(insertIndex);
      onOpenNewContentItemDialog();
      setActionType(LinkedItemsActionType.CreateItem);
    },
    [canCreateNewItem, onOpenNewContentItemDialog],
  );

  const handleSmartLinkCommand = useCallback(
    (command: SmartLinkCommand) => {
      const {
        data: { insertPosition },
        type,
      } = command;

      const insertIndex = getSmartLinkInsertIndex(insertPosition, alreadyLinkedItemIds);

      switch (type) {
        case SmartLinkCommandType.CreateLinkedItem: {
          initiateCreatingItem(insertIndex);
          break;
        }

        case SmartLinkCommandType.InsertLinkedItem: {
          initiateLinkingExistingItems(insertIndex);
          break;
        }

        default:
          throw new Error(`This command is not supported: ${type}`);
      }

      onSmartLinkCommandExecuted();
    },
    [
      alreadyLinkedItemIds,
      initiateCreatingItem,
      initiateLinkingExistingItems,
      onSmartLinkCommandExecuted,
    ],
  );

  useEffect(() => {
    if (!smartLinkCommand) {
      return;
    }

    finishCurrentAction();

    if (
      !isSmartLinkCommandRelevantToLIEs(smartLinkCommand) ||
      !isSmartLinkCommandRelevantToElement(
        smartLinkCommand,
        itemId,
        deliveryContentComponentId,
        typeElement.codename,
      )
    ) {
      return;
    }

    handleSmartLinkCommand(smartLinkCommand);
  }, [
    deliveryContentComponentId,
    finishCurrentAction,
    handleSmartLinkCommand,
    itemId,
    smartLinkCommand,
    typeElement.codename,
  ]);

  const buttons = (
    <Inline spacing={Spacing.S}>
      <LinkedItemsAiAssistant onAddExistingItems={onAddExistingItems} typeElement={typeElement} />
      <Button
        tooltipPlacement="right"
        autoFocus={autoFocus}
        buttonStyle="tertiary"
        size="small"
        onClick={() => initiateLinkingExistingItems(insertAtIndex)}
        {...getDataUiActionAttribute(DataUiAction.Add)}
      >
        Add existing items
      </Button>
      {showCreateNewItemButton && (
        <Button
          tooltipText={canCreateNewItem ? undefined : UserRoleCannotCreateNewItem}
          tooltipPlacement="right"
          disabled={!canCreateNewItem}
          size="small"
          buttonStyle="tertiary"
          onClick={() => initiateCreatingItem(insertAtIndex)}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create new item
        </Button>
      )}
    </Inline>
  );

  const preselectedFilterIds = useMemo<PreselectedFilterIds>(
    () => ({
      collectionIds: collectionId ? [collectionId] : undefined,
      contentTypeIds: allowedContentTypeIds,
      spaceIds,
    }),
    [collectionId, allowedContentTypeIds, spaceIds],
  );

  const alreadyLinkedItemIdsSet = useMemo(
    () => alreadyLinkedItemIds.toSet(),
    [alreadyLinkedItemIds],
  );

  const linkItemsModal =
    actionType === LinkedItemsActionType.LinkItems ? (
      <ModalMultipleContentItemsSelector
        preselectedFilterIds={preselectedFilterIds}
        alreadyLinkedItemIds={alreadyLinkedItemIdsSet}
        itemIsAlreadyLinkedMsg={
          typeElement.type === ElementType.Subpages ? PageIsAlreadyLinked : ItemIsAlreadyLinked
        }
        onClose={finishCurrentAction}
        onSelect={onSelectAndAssignExistingItems}
        submitButtonText="Add"
        titleBarText={modalDialogTitle}
      />
    ) : null;

  return (
    <>
      {buttons}
      <ModalViewer
        dialogClassName="dialog"
        isDialogVisible={!!linkItemsModal}
        onClose={finishCurrentAction}
        position={ModalViewerPosition.Center}
      >
        {linkItemsModal}
      </ModalViewer>
      <NewContentItemDialog
        onClose={finishCurrentAction}
        isOpen={actionType === LinkedItemsActionType.CreateItem}
        onSubmit={createItemFromDialog}
      />
    </>
  );
};

LinkedItemsActionsPane.displayName = 'LinkedItemsActionsPane';
