import { combineReducers } from '@reduxjs/toolkit';
import { lastSelectionTouchedItem } from './reducers/lastSelectionTouchedItem.ts';
import { selectAll } from './reducers/selectAll.ts';
import { selectedItemIds } from './reducers/selectedItemIds.ts';
import { unselectedItemIds } from './reducers/unselectedItemIds.ts';

export const itemsSelection = combineReducers({
  selectAll,
  selectedItemIds,
  unselectedItemIds,
  lastSelectionTouchedItem,
});
