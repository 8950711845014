import React from 'react';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ContentItemEditorToolbarActions as ContentItemEditorToolbarActionsComponent } from '../../components/statusBar/ContentItemEditorToolbarActions.tsx';
import { ContentItemEditing_ContentDetailsButton_Toggled } from '../../constants/contentItemEditingActionTypes.ts';

const toggleContentDetailsSidebar = () =>
  ({
    type: ContentItemEditing_ContentDetailsButton_Toggled,
  }) as const;

export type ContentItemEditorToolbarActionsType = ReturnType<typeof toggleContentDetailsSidebar>;

export const ContentItemEditorToolbarActions: React.FC = () => {
  const dispatch = useDispatch();

  const isSidebarOpen = useSelector((s) => s.contentApp.editorUi.contentItemSidebar.isOpen);
  const isSidebarToggleActive = useSelector(
    (s) =>
      s.contentApp.editorUi.contentItemSidebar.isOpen &&
      s.contentApp.editorUi.contentItemSidebar.isInitialized,
  );

  const toggleSidebar = (isOpen: boolean) => {
    dispatch(toggleContentDetailsSidebar());

    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: isOpen
          ? ContentItemEditingEventTypes.ContentItemDetailsSidebarClosed
          : ContentItemEditingEventTypes.ContentItemDetailsSidebarOpened,
        origin: ContentItemEditingEventOrigins.StatusBar,
      }),
    );
  };

  return (
    <ContentItemEditorToolbarActionsComponent
      isSidebarOpen={isSidebarOpen}
      isSidebarToggleActive={isSidebarToggleActive}
      onSidebarToggleClick={toggleSidebar}
    />
  );
};

ContentItemEditorToolbarActions.displayName = 'ContentItemEditorToolbarActions';
