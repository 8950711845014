import { Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { useFocusWithin } from '@react-aria/interactions';
import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { LabelFor } from '../uiComponents/LabelFor/LabelFor.tsx';
import { DataUiInput, getDataUiInputAttribute } from '../utils/dataAttributes/DataUiAttributes.ts';
import { MultilineInput } from './MultilineInput.tsx';

type NameEditorElementProps = {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly dataUiInputName: DataUiInput;
  readonly maxLength: number;
  readonly placeholder: string;
  readonly isMultiLine?: boolean;
  readonly readOnly?: boolean;
  readonly title?: string;
  readonly value: string;
  readonly onChange: (newName: string) => void;
};

export const NameEditorElement: React.FC<NameEditorElementProps> = ({
  autoFocus,
  className,
  dataUiInputName,
  isMultiLine,
  maxLength,
  placeholder,
  onChange,
  readOnly,
  title,
  value,
}) => {
  const [isCaretFocused, setIsCaretFocused] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setIsCaretFocused(isFocusWithin),
  });

  return (
    <div
      className={classNames('name-editor', className, {
        'name-editor--is-caret-focused': isCaretFocused,
      })}
      {...focusWithinProps}
    >
      <LabelFor
        className="name-editor__title"
        target={(id: Uuid) => (
          <div className="name-editor__content">
            {isMultiLine ? (
              <MultilineInput
                aria-label={title}
                autoFocus={autoFocus}
                color={colorTextDefault}
                dataUiInput={dataUiInputName}
                id={id}
                isDisabled={readOnly ?? false}
                maxLength={maxLength}
                onChange={onChange}
                placeholder={placeholder}
                typography={Typography.HeadlineLarge}
                value={value}
              />
            ) : (
              <input
                aria-label={title}
                autoFocus={autoFocus}
                autoComplete="off"
                className={classNames('name-editor__text-field', {
                  'name-editor__text-field--is-disabled': readOnly,
                })}
                id={id}
                maxLength={maxLength}
                onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
                  const name = event.target.value;
                  onChange?.(name);
                }}
                placeholder={placeholder}
                readOnly={readOnly}
                type="text"
                value={value}
                {...getDataUiInputAttribute(dataUiInputName)}
              />
            )}
          </div>
        )}
      >
        {title}
      </LabelFor>
    </div>
  );
};

NameEditorElement.displayName = 'NameEditorElement';
