import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { ReactNode } from 'react';
import { BarItemActionButtons } from '../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { HotkeysHandler } from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ActionInProgress } from '../types/acionInProgress.ts';

type Props = {
  readonly actionInProgress: ActionInProgress;
  readonly children: ReactNode;
  readonly disabledActionTooltipText: string | undefined;
  readonly disabledSaveTooltipText: string | undefined;
  readonly name: string;
  readonly onCancel: () => void;
  readonly onDelete: (() => void) | undefined;
  readonly onSubmit: () => void;
};

export const ExpandedSpaceBar = ({
  actionInProgress,
  children,
  disabledActionTooltipText,
  disabledSaveTooltipText,
  name,
  onCancel,
  onDelete,
  onSubmit,
}: Props) => {
  return (
    <HotkeysHandler
      className="bar-item__wrapper"
      handlers={{
        onEscape: onCancel,
        onEnter: onSubmit,
      }}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded">
        <BarItemExpandedSimpleHeader
          additionalTitleClass="bar-item__title--with-flex"
          dataUiObjectName={name}
          isDraggable
          renderTitle={() => (
            <OptionalTooltip placement="bottom-start" text={name}>
              {name}
            </OptionalTooltip>
          )}
        />
        <div className="bar-item__expansion">{children}</div>
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancel,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: actionInProgress === ActionInProgress.Saving ? 'Saving' : 'Save',
            isInProgress: actionInProgress === ActionInProgress.Saving,
            handler: onSubmit,
            dataUIActionName: DataUiAction.Confirm,
            shortcut: ShortcutSymbols.Enter,
            isDisabled: !!disabledSaveTooltipText,
            tooltipText: disabledSaveTooltipText,
          }}
          destructiveAction={
            onDelete && {
              handler: onDelete,
              isInProgress: actionInProgress === ActionInProgress.Deleting,
              text: actionInProgress === ActionInProgress.Deleting ? 'Deleting' : 'Delete',
              dataUIActionName: DataUiAction.Delete,
              icon: 'Bin',
              isDisabled: !!disabledActionTooltipText,
              tooltipText: disabledActionTooltipText,
            }
          }
        />
      </div>
    </HotkeysHandler>
  );
};
