import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_Asset_ArchiveCompleted,
  AssetLibrary_Asset_ArchiveFailed,
  AssetLibrary_Asset_ArchiveStarted,
} from '../../constants/assetLibraryActionTypes.ts';

interface IArchiveAssetDependencies {
  readonly assetService: IAssetService;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const started = (asset: IAsset) =>
  ({
    type: AssetLibrary_Asset_ArchiveStarted,
    payload: {
      asset,
    },
  }) as const;

const completed = (asset: IAsset) =>
  ({
    type: AssetLibrary_Asset_ArchiveCompleted,
    payload: {
      asset,
    },
  }) as const;

const failed = (asset: IAsset) =>
  ({
    type: AssetLibrary_Asset_ArchiveFailed,
    payload: {
      asset,
    },
  }) as const;

export type ArchiveAssetActionsType = ReturnType<typeof started | typeof completed | typeof failed>;

export const createArchiveAssetAction =
  (deps: IArchiveAssetDependencies): ((asset: IAsset) => ThunkPromise) =>
  (asset: IAsset): ThunkPromise =>
  (dispatch: Dispatch): Promise<void> => {
    dispatch(started(asset));

    return deps.assetService
      .archiveAsset(asset.id)
      .then((archivedAsset: IAsset) => {
        const updatedAsset: IAsset = { ...asset, ...archivedAsset };
        dispatch(completed(updatedAsset));
        dispatch(
          deps.trackUserEvent(TrackedEvent.AssetDeleted, {
            type: updatedAsset.type,
            'asset-id': updatedAsset.id,
          }),
        );
      })
      .catch(() => {
        dispatch(failed(asset));
      });
  };
