import { Action } from '../../../../@types/Action.type.ts';
import { DefaultDisplayTimeZoneId } from '../../../utils/dateTime/timeZoneUtils.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduledToUnpublish_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

export function scheduledUnpublishDisplayTimeZone(
  state = DefaultDisplayTimeZoneId,
  action: Action,
) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.scheduledUnpublishDisplayTimeZone;

    case ChangeWorkflowStep_ScheduledToUnpublish_Changed:
      return action.payload.timeZoneId;

    default:
      return state;
  }
}
