import { TaxonomyTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { QuantityOption } from '../../../../contentInventory/content/models/quantityOptions.ts';
import { getQuantityUnitOption } from '../../utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  ITypeElementDataWithNumberOfItemsLimit,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface ITaxonomyTypeElementData
  extends IBaseTypeElementData,
    ITypeElementDataWithNumberOfItemsLimit {
  readonly defaultValue: UuidArray;
  readonly taxonomyGroupId: Uuid | null;
}

export const EmptyTaxonomyTypeElementData: ITaxonomyTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  _quantityUnitOption: QuantityOption.Minimum,
  defaultValue: [],
  maxItems: null,
  minItems: null,
  taxonomyGroupId: null,
  type: ElementType.Taxonomy,
};

export function getTaxonomyTypeElementDataFromServerModel(
  serverModel: TaxonomyTypeElementServerModel,
): ITaxonomyTypeElementData {
  const quantityUnitOption = getQuantityUnitOption(serverModel.minItems, serverModel.maxItems);

  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyTaxonomyTypeElementData._configurability,
    _quantityUnitOption: quantityUnitOption,
    defaultValue: serverModel.default?.global.value || [],
    maxItems: serverModel.maxItems?.toString() ?? EmptyTaxonomyTypeElementData.maxItems,
    minItems: serverModel.minItems?.toString() ?? EmptyTaxonomyTypeElementData.minItems,
    taxonomyGroupId: serverModel.taxonomyGroupId ?? EmptyTaxonomyTypeElementData.taxonomyGroupId,
    type: EmptyTaxonomyTypeElementData.type,
  };
}
