import { OperationStatus } from '../../../_shared/models/OperationStatus.ts';
import { IWebhookSetting } from '../../../data/models/webhooks/WebhookSetting.ts';

export interface IWebhookOperation {
  readonly type: OperationType;
  readonly status: OperationStatus;
  readonly webhook?: IWebhookSetting;
}

export enum OperationType {
  Archive = 'Archive',
  Restore = 'Restore',
  Save = 'Save',
  Discard = 'Discard',
}
