import { Collection } from '@kontent-ai/utils';
import { DefaultVariantId } from '../../../_shared/constants/variantIdValues.ts';
import { ActiveCapabilityType } from '../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemDefaultVariant } from '../../../_shared/selectors/getEditedContentItemDefaultVariant.ts';
import { getSelectedLanguageId } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { distinctFilter } from '../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { hasActiveVariantCapability } from '../../../_shared/utils/permissions/activeCapabilities.ts';
import { isPublishingStepAvailableForEditedItemDefaultVariant } from '../features/ContentItemEditing/utils/editingActions/availabilityUtils/areAssignmentChangesAvailable.ts';
import { isDefaultVariantBlockingPublish } from '../features/ContentItemEditing/utils/itemValidationUtils.ts';
import { isElementReadyForPublish } from '../utils/getItemElementValidationResult.ts';
import { getEditedContentItemType } from './getEditedContentItemType.ts';

export const getElementsWithWarnings = (state: IStore): ReadonlyArray<Uuid> =>
  [
    ...Collection.getKeys(state.contentApp.itemValidationErrors),
    ...Collection.getKeys(
      Collection.filter(
        state.contentApp.itemValidationWarnings,
        (warningResult) => !isElementReadyForPublish(warningResult),
      ),
    ),
  ].filter(distinctFilter);

export const isPublishingBlockedByDefaultVariant = (state: IStore): boolean => {
  const selectedLanguageId = getSelectedLanguageId(state);
  const editedContentItemType = getEditedContentItemType(state);

  if (!selectedLanguageId || !editedContentItemType) {
    return false;
  }

  const editedContentItemDefaultVariant = getEditedContentItemDefaultVariant(state);
  const isEditingDefaultVariant = selectedLanguageId === DefaultVariantId;
  const canViewDefaultVariant = hasActiveVariantCapability(
    ActiveCapabilityType.ViewContent,
    editedContentItemDefaultVariant,
  );
  const canPublishDefaultVariant =
    isPublishingStepAvailableForEditedItemDefaultVariant(editedContentItemDefaultVariant, state) &&
    hasActiveVariantCapability(
      ActiveCapabilityType.UpdateAssignment,
      editedContentItemDefaultVariant,
    );

  return isDefaultVariantBlockingPublish(
    editedContentItemType,
    editedContentItemDefaultVariant,
    isEditingDefaultVariant,
    canViewDefaultVariant,
    canPublishDefaultVariant,
  );
};
