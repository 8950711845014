import React from 'react';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';

type MenuOption = {
  readonly variantId: Uuid;
  readonly name: string;
  readonly isDisabled: boolean;
};

type CopyFromLanguageOptionsProps = {
  readonly onSelectLanguage: (params: Uuid) => void;
  readonly menuOptions: ReadonlyArray<MenuOption>;
};

export const CopyFromLanguageOptions: React.FC<CopyFromLanguageOptionsProps> = (props) => (
  <>
    {props.menuOptions.map((option: MenuOption, index: number) => (
      <DropDownOption
        isDisabled={option.isDisabled}
        key={index}
        onClick={() => props.onSelectLanguage(option.variantId)}
        slim
        tooltipText={
          option.isDisabled
            ? 'You can only copy from language variants assigned to you.'
            : undefined
        }
      >
        <DropDownOptionName text={option.name} />
      </DropDownOption>
    ))}
  </>
);

CopyFromLanguageOptions.displayName = 'CopyFromLanguageOptions';
