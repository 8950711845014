import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isScheduleToPublishWorkflowStepSelected } from '../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { hasActiveVariantCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  canViewContentElement,
  getElementById,
  getTypeElementsInContentGroup,
} from '../../../stores/utils/contentItemElementsUtils.ts';
import { ItemElementValidationResult } from '../../../utils/ItemElementValidationResult.type.ts';
import {
  ItemElementErrorResult,
  ItemElementErrors,
} from '../../../utils/elementErrorCheckers/types/Errors.ts';
import {
  isElementReadyForPublish,
  isElementValidIfEmpty,
} from '../../../utils/getItemElementValidationResult.ts';
import {
  IFriendlyWarning,
  ItemElementFriendlyWarnings,
} from '../../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import {
  ItemElementWarningResult,
  ItemElementWarnings,
} from '../../../utils/itemElementWarningCheckers/types/Warnings.ts';
import { getValidationSelectorId } from '../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { isAnyElementInContentComponentIncomplete } from '../../../utils/itemHighlightingUtils.ts';
import { getAllContentComponentIdsFromElements } from './contentComponentUtils.ts';

export const isDefaultVariantRequiredForPublish = (
  editedContentItemType: ICompiledContentType,
  isEditingDefaultVariant: boolean,
  canViewDefaultVariant: boolean,
  itemWithDefaultVariant: IListingContentItem | null,
): boolean => {
  if (isEditingDefaultVariant) {
    return false;
  }

  const isDefaultVariantPublishedOrForbidden =
    !canViewDefaultVariant || isVariantPublished(itemWithDefaultVariant?.variant || null);
  const hasRequiredNonLocalizableElements = editedContentItemType.contentElements.some(
    (element) =>
      element.isNonLocalizable && isEditableElement(element) && !isElementValidIfEmpty(element),
  );

  return hasRequiredNonLocalizableElements && !isDefaultVariantPublishedOrForbidden;
};

export const isDefaultVariantBlockingPublish = (
  editedContentItemType: ICompiledContentType,
  itemWithDefaultVariant: IListingContentItem | null,
  isEditingDefaultVariant: boolean,
  canViewDefaultVariant: boolean,
  canPublishDefaultVariant: boolean,
): boolean => {
  if (isEditingDefaultVariant) {
    return false;
  }

  const isDefaultVariantComplete = itemWithDefaultVariant?.variant?.isComplete;
  const isDefaultVariantScheduled = isScheduleToPublishWorkflowStepSelected(
    itemWithDefaultVariant?.variant?.assignment,
  );
  const isDefaultVariantRequired = isDefaultVariantRequiredForPublish(
    editedContentItemType,
    isEditingDefaultVariant,
    canViewDefaultVariant,
    itemWithDefaultVariant,
  );

  return (
    isDefaultVariantRequired &&
    (!isDefaultVariantComplete || !canPublishDefaultVariant) &&
    !isDefaultVariantScheduled
  );
};

interface ItemReadyToPublishParams {
  canPublishDefaultVariant: boolean;
  canViewDefaultVariant: boolean;
  editedContentItemType: ICompiledContentType;
  isEditingDefaultVariant: boolean;
  itemValidationErrors: ItemElementErrors;
  itemValidationWarnings: ItemElementWarnings;
  itemWithDefaultVariant: IListingContentItem | null;
}

export const isItemReadyToPublish = ({
  canPublishDefaultVariant,
  canViewDefaultVariant,
  editedContentItemType,
  isEditingDefaultVariant,
  itemValidationErrors,
  itemValidationWarnings,
  itemWithDefaultVariant,
}: ItemReadyToPublishParams): boolean => {
  const hasError = !!itemValidationErrors.size;
  const elementsAreReadyForPublish =
    Collection.getValues(itemValidationWarnings).every(isElementReadyForPublish);
  const blockedByDefaultVariant = isDefaultVariantBlockingPublish(
    editedContentItemType,
    itemWithDefaultVariant,
    isEditingDefaultVariant,
    canViewDefaultVariant,
    canPublishDefaultVariant,
  );
  return !hasError && elementsAreReadyForPublish && !blockedByDefaultVariant;
};

export const getWarningValidationResult = memoize.weak(
  (warnings: ItemElementWarnings, elementId: string): ItemElementWarningResult | null => {
    return warnings.get(elementId) ?? null;
  },
);

export const getErrorValidationResult = memoize.weak(
  (errors: ItemElementErrors, elementId: string): ItemElementErrorResult | null => {
    return errors.get(elementId) ?? null;
  },
);

export const getFriendlyWarningsForEditedElements = memoize.weak(
  (friendlyWarnings: ItemElementFriendlyWarnings): ReadonlyArray<IFriendlyWarning> =>
    Collection.getValues(friendlyWarnings).flatMap(
      (friendlyWarningResult) => friendlyWarningResult.warningMessages,
    ),
);

export const getFriendlyWarningMessages = memoize.weak(
  (
    friendlyWarnings: ItemElementFriendlyWarnings,
    elementId: string,
    showFriendlyWarnings: boolean,
  ): ReadonlyArray<string> => {
    if (!showFriendlyWarnings) {
      return [];
    }

    const friendlyWarningResult = friendlyWarnings.get(elementId);

    if (!friendlyWarningResult || !friendlyWarningResult.warningMessages.length) {
      return [];
    }

    return friendlyWarningResult.warningMessages
      .map((warningMessage) => warningMessage.message || '')
      .filter(Boolean);
  },
);

const getAllWarningMessages = (
  warningResult: ItemElementWarningResult | undefined,
): ReadonlyArray<string> | null => {
  if (!warningResult) {
    return null;
  }
  if (warningResult.requiredMessage) {
    return [...warningResult.limitationMessages, warningResult.requiredMessage];
  }
  return warningResult.limitationMessages;
};

export const getErrorMessages = memoize.weak(
  (
    errors: ItemElementErrors,
    warnings: ItemElementWarnings,
    elementId: string,
    showIncompleteItemWarningsBeforePublish: boolean,
  ): ReadonlyArray<string> => {
    const errorResult = errors.get(elementId);
    const errorMessages = errorResult ? errorResult.errorMessages : [];

    if (!showIncompleteItemWarningsBeforePublish) {
      return errorMessages;
    }

    const warningResult = warnings.get(elementId);
    const warningMessages = getAllWarningMessages(warningResult);

    return warningMessages ? errorMessages.concat(warningMessages) : errorMessages;
  },
);

export const getRemoveAllValidationResultsWithinRichText =
  (elementId: string) =>
  (result: ItemElementValidationResult | undefined): boolean =>
    !!result && result.topLevelRichTextId !== elementId;

export const getFilterValidationResultForRichText =
  (elementId: Uuid) =>
  (result: ItemElementValidationResult | undefined): boolean =>
    !!result && result.topLevelRichTextId === elementId;

export const areElementsInContentComponentsIncomplete = (
  contentComponentIds: Immutable.Set<Uuid>,
  incompleteElementIds: ReadonlySet<string>,
) => {
  const areElementsInComponentsIncomplete = contentComponentIds.some((contentComponentId: Uuid) =>
    isAnyElementInContentComponentIncomplete(contentComponentId, incompleteElementIds),
  );

  return areElementsInComponentsIncomplete;
};

export const isElementDeepValid = (
  validationSelector: string,
  elementData: ICompiledContentItemElementData | null,
  incompleteElementIds: ReadonlySet<string>,
): boolean => {
  if (incompleteElementIds.has(validationSelector)) {
    return false;
  }

  const contentComponentIds = getAllContentComponentIdsFromElements(
    elementData ? [elementData] : [],
  );
  const areElementsInComponentsIncomplete = areElementsInContentComponentsIncomplete(
    contentComponentIds,
    incompleteElementIds,
  );

  return !areElementsInComponentsIncomplete;
};

export const getIncompleteElementsPerGroup = memoize.weak(
  (
    contentGroups: Immutable.List<IContentGroup>,
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    contentTypeElements: ReadonlyArray<TypeElement>,
    incompleteElementIds: ReadonlySet<Uuid>,
    contentComponentId: Uuid | undefined,
  ): Immutable.Map<Uuid, number> => {
    const incompleteElementsPerGroup = contentGroups.map((group: IContentGroup) => {
      const typeElementsInContentGroup = getTypeElementsInContentGroup(
        contentTypeElements,
        group.id,
      );
      const incompleteElementsCount = typeElementsInContentGroup.filter((element) => {
        const itemElementData = getElementById(element.elementId, elements);
        const validationSelector = getValidationSelectorId(element.elementId, contentComponentId);
        const elementIsDeepValid = isElementDeepValid(
          validationSelector,
          itemElementData,
          incompleteElementIds,
        );
        return !elementIsDeepValid;
      }).length;

      return [group.id, incompleteElementsCount];
    });

    return Immutable.Map(incompleteElementsPerGroup);
  },
);

const getTopLevelRichTextIdOrElementId = (
  validationResult: ItemElementValidationResult,
  elementPath: UuidPath,
): Uuid => validationResult.topLevelRichTextId || elementPath;

export const getIncompleteElementIdPathsAndTheirTopLevelElementId = memoize.weak(
  (
    itemValidationErrors: ItemElementErrors,
    itemValidationWarnings: ItemElementWarnings,
  ): ReadonlyMap<UuidPath, Uuid> => {
    const validationWarningEntries = Collection.getEntries(itemValidationWarnings)
      .filter(([, validationResult]) => !isElementReadyForPublish(validationResult))
      .map(([elementPath, validationResult]): [UuidPath, Uuid] => [
        elementPath,
        getTopLevelRichTextIdOrElementId(validationResult, elementPath),
      ]);

    const validationErrorEntries = Collection.getEntries(itemValidationErrors).map(
      ([elementPath, validationResult]): [UuidPath, Uuid] => [
        elementPath,
        getTopLevelRichTextIdOrElementId(validationResult, elementPath),
      ],
    );

    return new Map<UuidPath, Uuid>([...validationErrorEntries, ...validationWarningEntries]);
  },
);

export const getIncompleteElementIdPaths = memoize.maxOne(
  (
    itemValidationErrors: ItemElementErrors,
    itemValidationWarnings: ItemElementWarnings,
  ): ReadonlySet<UuidPath> =>
    new Set<UuidPath>(
      Collection.getKeys(
        getIncompleteElementIdPathsAndTheirTopLevelElementId(
          itemValidationErrors,
          itemValidationWarnings,
        ),
      ),
    ),
);

export const isAnyIncompleteElementAccessible = (state: IStore): boolean => {
  const { itemValidationErrors, itemValidationWarnings } = state.contentApp;

  return Collection.getValues(
    getIncompleteElementIdPathsAndTheirTopLevelElementId(
      itemValidationErrors,
      itemValidationWarnings,
    ),
  ).some((topLevelElementId) => canViewContentElement(topLevelElementId, state));
};

export const isDefaultVariantPublishedOrForbidden = (state: IStore, itemId: Uuid): boolean => {
  const defaultVariant = state.data.listingContentItems.defaultById.get(itemId) ?? null;
  const canViewDefaultVariant = hasActiveVariantCapability(
    ActiveCapabilityType.ViewContent,
    defaultVariant,
  );
  return !canViewDefaultVariant || isVariantPublished(defaultVariant?.variant ?? null);
};
