import React, {
  ForwardRefExoticComponent,
  PropsWithChildren,
  PropsWithoutRef,
  Ref,
  RefAttributes,
} from 'react';

type PossibleInstance = HTMLElement | AnyObject | null | React.Component;

/** @deprecated This HOC is deprecated. Use native React forwardRef with explicit typing. Example:<br>
 * &nbsp;<br>
 * import {<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;forwardRef,<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;PropsWithChildren,<br>
 * } from 'react';<br>
 * &nbsp;<br>
 * type Props = { ... };<br>
 * &nbsp;<br>
 * const MyComponent = forwardRef&lt;HTMLDivElement, Props&gt;((props, ref) => {<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;return <div ref={ref} />;<br>
 * });<br>
 * &nbsp;<br>
 * // Or with children if you need it:<br>
 * const MyComponent = forwardRef&lt;HTMLDivElement, PropsWithChildren&lt;Props&gt;&gt;((props, ref) => {<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;return <div ref={ref}>{props.children}</div>;<br>
 * });<br>
 * &nbsp;<br>
 */
export interface IForwardedRefProps<TInstance extends PossibleInstance> {
  readonly forwardedRef?: Ref<TInstance>;
}

/**
 * Default type definition for return type of React.forwardRef is missing children property.
 * Our FRC forces use of forwardedRef if needed and removes it from forwardRef HOC result.
 */
export type FRC<TRef extends PossibleInstance, TProps> = ForwardRefExoticComponent<
  PropsWithChildren<Omit<PropsWithoutRef<TProps>, 'forwardedRef'>> & RefAttributes<TRef>
>;

/** @deprecated This HOC is deprecated. Use native React forwardRef with explicit typing. Example:<br>
 * &nbsp;<br>
 * import {<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;forwardRef,<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;PropsWithChildren,<br>
 * } from 'react';<br>
 * &nbsp;<br>
 * type Props = { ... };<br>
 * &nbsp;<br>
 * const MyComponent = forwardRef&lt;HTMLDivElement, Props&gt;((props, ref) => {<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;return <div ref={ref} />;<br>
 * });<br>
 * &nbsp;<br>
 * // Or with children if you need it:<br>
 * const MyComponent = forwardRef&lt;HTMLDivElement, PropsWithChildren&lt;Props&gt;&gt;((props, ref) => {<br>
 * &nbsp;&nbsp;&nbsp;&nbsp;return <div ref={ref}>{props.children}</div>;<br>
 * });<br>
 * &nbsp;<br>
 */
export function forwardRef<TRef extends PossibleInstance, TProps extends IForwardedRefProps<TRef>>(
  Component: React.ComponentType<React.PropsWithChildren<TProps>>,
): FRC<TRef, TProps> {
  const forwardedRefComponent = React.forwardRef<TRef, React.PropsWithChildren<TProps>>(
    (props, ref) => (
      <Component {...props} forwardedRef={ref}>
        {props.children}
      </Component>
    ),
  ) as FRC<TRef, TProps>;

  forwardedRefComponent.displayName = Component.displayName;

  return forwardedRefComponent;
}
