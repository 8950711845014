import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IPreviewConfiguration } from '../models/PreviewConfiguration.type.ts';
import {
  ContentTypeId,
  IPreviewUrlPatternError,
  SpaceRowId,
} from '../stores/IPreviewConfigurationAppStoreState.type.ts';
import { contentTypeSpacesValidator } from './contentTypeSpacesValidator.ts';
import { contentTypeUrlPatternValidatorBuilder } from './contentTypeUrlPatternValidation.ts';

export type PreviewUrlsSectionFormValidatorProps = {
  readonly contentTypes: Immutable.Map<Uuid, IContentType>;
  readonly getDeployedSampleAppDomain: () => string;
};

const getUrlPatternValidator = (
  contentTypeId: Uuid,
  contentTypes: Immutable.Map<Uuid, IContentType>,
  getDeployedSampleAppDomain: () => string,
) => {
  const type: IContentType | undefined = contentTypes.get(contentTypeId);
  return contentTypeUrlPatternValidatorBuilder(type, getDeployedSampleAppDomain);
};

const getUrlPatternValidatorMemoized = memoize.allForever(getUrlPatternValidator);

export type PreviewConfigurationFormValidator = (
  previewConfiguration: IPreviewConfiguration,
) => ReadonlyMap<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>>;

export const createPreviewConfigurationValidator = (
  props: PreviewUrlsSectionFormValidatorProps,
): PreviewConfigurationFormValidator => {
  return (previewConfiguration) => {
    const errors = new Map<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>>();
    const patternsForExistingContentTypes = Collection.getEntries(
      previewConfiguration.previewUrlPatterns,
    ).filter(([contentTypeId]) => props.contentTypes.get(contentTypeId));

    for (const [contentTypeId, previewUrlPatterns] of patternsForExistingContentTypes) {
      const typeErrors = new Map<SpaceRowId, IPreviewUrlPatternError>();
      const urlPatternValidator = getUrlPatternValidatorMemoized(
        contentTypeId,
        props.contentTypes,
        props.getDeployedSampleAppDomain,
      );

      for (const previewUrlPattern of previewUrlPatterns) {
        if (!previewUrlPattern.enabled) {
          continue;
        }

        const urlPatternError = urlPatternValidator(previewUrlPattern);
        const otherUrlPatterns = previewUrlPatterns.filter(
          (pattern) => pattern !== previewUrlPattern,
        );
        const spacesError = contentTypeSpacesValidator(
          previewUrlPattern,
          previewConfiguration.spaceDomains,
          otherUrlPatterns,
        );

        if (urlPatternError || spacesError) {
          const error: IPreviewUrlPatternError = {
            urlPattern: urlPatternError,
            spaces: spacesError,
          };

          typeErrors.set(previewUrlPattern.rowId, error);
        }
      }

      if (typeErrors.size) {
        errors.set(contentTypeId, typeErrors);
      }
    }

    return errors;
  };
};
