import React from 'react';
import { DefaultCollectionId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { useSpaceData } from '../../../../../environmentSettings/spaces/hooks/useSpaceData.ts';
import { openMoveToCollectionDialog } from '../../../MoveToCollectionDialog/thunks/openMoveToCollectionDialog.ts';
import { ContentItemCollection as ContentItemCollectionComponent } from '../../components/elements/ContentItemCollection.tsx';

export const ContentItemCollection: React.FC = () => {
  const itemCollectionId = useSelector(
    (s) => s.contentApp.editedContentItem?.collectionId ?? DefaultCollectionId,
  );
  const itemCollectionName = useSelector(
    (s) => s.data.collections.byId.get(itemCollectionId)?.name ?? 'unknown collection',
  );

  const isSpaceDefined = useSelector((s) => s.data.spaces.byId.size > 0);
  const spaceNamesForItemCollection = useSpaceData(itemCollectionId);
  const dispatch = useDispatch();

  const changeCollection = () =>
    dispatch(openMoveToCollectionDialog(ContentItemEditingEventOrigins.ItemDetails));

  return (
    <ContentItemCollectionComponent
      onChange={changeCollection}
      collectionName={itemCollectionName}
      isSpaceDefined={isSpaceDefined}
      spaceData={spaceNamesForItemCollection}
    />
  );
};

ContentItemCollection.displayName = 'ContentItemCollection';
