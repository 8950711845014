import { Button } from '@kontent-ai/component-library/Button';

type Props = {
  readonly children: string;
  readonly onClick: () => void;
};

export const ShowAllSavedFiltersButton = ({ onClick, children }: Props) => (
  <div className="saved-filters__show-all-filters">
    <Button buttonStyle="tertiary" size="small" onClick={onClick}>
      {children}
    </Button>
  </div>
);
