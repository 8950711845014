import { useAttachRef } from '@kontent-ai/hooks';
import { areShallowEqual, createGuid } from '@kontent-ai/utils';
import { PropsWithChildren, RefObject, forwardRef, useCallback } from 'react';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import {
  CustomOnScrollCallback,
  useAutoScroll,
} from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { createUnknownUserInfo } from '../../../../../../_shared/models/UserInfo.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getCurrentUserId } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import { getEditedContentItem } from '../../../../../../_shared/selectors/getEditedContentItem.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { shouldShowAiReviewButton } from '../../../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/utils/shouldShowAiReviewButton.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { shouldShowAiTranslateButton } from '../../../../../richText/plugins/ai/actions/TranslateContent/shouldShowAiTranslateButton.ts';
import { CommentThreadItemType } from '../../../../models/comments/CommentThreadItem.ts';
import { IInlineCommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  areElementContentLimitationsMet,
  isElementReadyForPublish,
} from '../../../../utils/getItemElementValidationResult.ts';
import { getIsFocused, getIsHighlighted } from '../../../../utils/itemHighlightingUtils.ts';
import { focusFirstIncompleteItemElement } from '../../actions/contentItemEditingActions.ts';
import { startNewInlineCommentThread } from '../../actions/thunkContentItemEditingActions.ts';
import {
  IItemElementOwnProps,
  ItemElement as ItemElementComponent,
} from '../../components/elements/ItemElement.tsx';
import { ItemElementScrollOptions } from '../../constants/uiConstants.ts';
import { useIsElementEditableInEditedVariant } from '../../hooks/useIsElementEditableInEditedVariant.ts';
import {
  getElementCommentThreads,
  getUnresolvedElementCommentThreads,
} from '../../selectors/inlineCommentSelectors.ts';
import { areCommentSupported, getCommentThreadType } from '../../utils/inlineCommentUtils.ts';
import {
  getErrorMessages,
  getErrorValidationResult,
  getFriendlyWarningMessages,
  getIncompleteElementIdPaths,
  getWarningValidationResult,
} from '../../utils/itemValidationUtils.ts';

interface IItemElementContainerProps extends IItemElementOwnProps {
  readonly hideValidationStatus?: boolean;
  readonly revisionChangedBy?: string;
}

const getIsRequiredStateLoaded = (s: IStore): boolean =>
  !!s.contentApp.editedContentItem &&
  s.contentApp.loadedContentItemTypes.has(s.contentApp.editedContentItem.editedContentItemTypeId);

const getShowLimitationWarning = (
  s: IStore,
  elementId: Uuid,
  hideValidationStatus: boolean | undefined,
): boolean => {
  const warningValidationResult = getWarningValidationResult(
    s.contentApp.itemValidationWarnings,
    elementId,
  );
  return (
    !!warningValidationResult &&
    !areElementContentLimitationsMet(warningValidationResult) &&
    !hideValidationStatus
  );
};

const getValidationPassed = (s: IStore, elementId: Uuid): boolean => {
  const warningValidationResult = getWarningValidationResult(
    s.contentApp.itemValidationWarnings,
    elementId,
  );
  const errorValidationResult = getErrorValidationResult(
    s.contentApp.itemValidationErrors,
    elementId,
  );
  return !errorValidationResult && isElementReadyForPublish(warningValidationResult ?? undefined);
};

const getIsElementHighlighted = (
  s: IStore,
  elementId: Uuid,
  hideValidationStatus: boolean | undefined,
): boolean => {
  const incompleteElementIds = getIncompleteElementIdPaths(
    s.contentApp.itemValidationErrors,
    s.contentApp.itemValidationWarnings,
  );
  return (
    getIsHighlighted(elementId, incompleteElementIds) &&
    s.contentApp.showIncomplete &&
    !hideValidationStatus
  );
};

const getLockedByUser = (s: IStore, elementId: Uuid): IProjectContributor | null => {
  const lockedElementSession = s.contentApp.editorUi.lockedElements.find(
    (lockedElement) =>
      !!lockedElement &&
      lockedElement.elementId === elementId &&
      lockedElement.userId !== getCurrentUserId(s),
  );
  return lockedElementSession
    ? (s.data.users.usersById.get(lockedElementSession.userId) ?? null)
    : null;
};

const getElementThreads = (s: IStore, elementId: Uuid): ReadonlyArray<IInlineCommentThread> => {
  const { commentThreads } = s.contentApp.editedContentItemVariantComments;
  return commentThreads && getElementCommentThreads(commentThreads, elementId, null);
};

const getElementUnresolvedThreads = (
  s: IStore,
  elementId: Uuid,
): ReadonlyArray<IInlineCommentThread> => {
  const allThreads = getElementThreads(s, elementId);
  return getUnresolvedElementCommentThreads(allThreads, elementId, null);
};

const getHasFocusedComment = (s: IStore, typeElement: TypeElement): boolean => {
  const elementSupportsComments = areCommentSupported(typeElement.type);
  return (
    elementSupportsComments &&
    !!getElementThreads(s, typeElement.elementId).find(
      (thread: IInlineCommentThread) =>
        thread.id === s.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
    )
  );
};

const getElementErrorMessages = (s: IStore, elementId: Uuid): ReadonlyArray<string> => {
  const { itemValidationErrors, itemValidationWarnings, showIncompleteItemWarningsBeforePublish } =
    s.contentApp;
  return getErrorMessages(
    itemValidationErrors,
    itemValidationWarnings,
    elementId,
    showIncompleteItemWarningsBeforePublish,
  );
};

const getElementFriendlyWarningMessages = (s: IStore, elementId: Uuid): ReadonlyArray<string> => {
  const { itemValidationFriendlyWarnings, showFriendlyWarnings } = s.contentApp;
  return getFriendlyWarningMessages(
    itemValidationFriendlyWarnings,
    elementId,
    showFriendlyWarnings,
  );
};

const useAutoScrollItemElement = (
  typeElement: TypeElement,
  scrollTargetRef: RefObject<HTMLDivElement>,
  customOnScroll?: CustomOnScrollCallback,
): void => {
  const scrollId = useSelector((s) => {
    const editedContentItem = getEditedContentItem(s);
    return CreateAutoScrollId.forElement(typeElement.codename ?? '', editedContentItem.id);
  });
  useAutoScroll({
    scrollTargetRef,
    scrollId,
    scrollOptions: ItemElementScrollOptions,
    customOnScroll,
  });
};

export const ItemElement = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IItemElementContainerProps>
>(
  (
    {
      contentComponentId,
      typeElement,
      hideValidationStatus,
      revisionChangedBy,
      onCustomScrollToTarget,
      defaultTabIndex,
      disabled,
      onHeaderClick,
      onContentClick,
      title,
      isDisplayOnly,
      escapeHandledByEditor,
      headerClassName,
      isCompact,
      className,
      focusEditor,
      children,
    },
    ref,
  ) => {
    const currentUserId = useSelector(getCurrentUserId);
    const areContentItemElementsHighlighting = useSelector(
      (s) => s.contentApp.areContentItemElementsHighlighting,
    );
    const canUpdateContent = useSelector((s) =>
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s),
    );
    const errorMessages = useSelector(
      (s) => getElementErrorMessages(s, typeElement.elementId),
      areShallowEqual,
    );
    const friendlyWarningMessages = useSelector(
      (s) => getElementFriendlyWarningMessages(s, typeElement.elementId),
      areShallowEqual,
    );
    const isFocused = useSelector((s) =>
      getIsFocused(typeElement.elementId, s.contentApp.firstIncompleteElementId),
    );
    const isHighlighted = useSelector((s) =>
      getIsElementHighlighted(s, typeElement.elementId, hideValidationStatus),
    );
    const lockedByUser = useSelector((s) => getLockedByUser(s, typeElement.elementId));
    const isOutdated = useSelector((s) =>
      s.contentApp.editorUi.outdatedElementIds.contains(typeElement.elementId),
    );
    const isRefreshPending = useSelector((s) =>
      s.contentApp.editorUi.pendingRefreshElements.has(typeElement.elementId),
    );
    const isRequiredStateLoaded = useSelector(getIsRequiredStateLoaded);
    const revisionElementUser = useSelector((s) =>
      revisionChangedBy
        ? (s.data.users.usersById.get(revisionChangedBy) ??
          createUnknownUserInfo(revisionChangedBy))
        : undefined,
    );
    const showAiTranslateButton = useSelector((s) =>
      shouldShowAiTranslateButton(s, typeElement, contentComponentId),
    );
    const showAiReviewButton = useSelector((s) =>
      shouldShowAiReviewButton(s, typeElement, contentComponentId),
    );
    const showIncompleteItemWarningsBeforePublish = useSelector(
      (s) => s.contentApp.showIncompleteItemWarningsBeforePublish,
    );
    const showLimitationWarning = useSelector((s) =>
      getShowLimitationWarning(s, typeElement.elementId, hideValidationStatus),
    );

    const { firstUnresolvedThreadId, hasFocusedComment } = useSelector((s) => {
      const unresolvedThreads = getElementUnresolvedThreads(s, typeElement.elementId);
      return {
        firstUnresolvedThreadId: unresolvedThreads?.[0]?.id ?? null,
        hasFocusedComment: getHasFocusedComment(s, typeElement),
      };
    }, areShallowEqual);

    const validationPassed = useSelector((s) => getValidationPassed(s, typeElement.elementId));

    const isEditableInVariant = useIsElementEditableInEditedVariant(typeElement);
    const isInvalidNonEditableElement = !isEditableInVariant && !validationPassed;
    const showElementStatus =
      (typeElement.isRequired || isInvalidNonEditableElement) && !hideValidationStatus;

    const elementSupportsComments = areCommentSupported(typeElement.type);

    const dispatch = useDispatch();
    const focusItemElement = useCallback(() => dispatch(focusFirstIncompleteItemElement()), []);

    const { refObject: scrollTargetRef, refToForward } = useAttachRef(ref);
    useAutoScrollItemElement(typeElement, scrollTargetRef, onCustomScrollToTarget);

    const onNewComment = useCallback(() => {
      dispatch(
        startNewInlineCommentThread({
          externalSegmentId: createGuid(),
          elementId: typeElement.elementId,
          itemType: CommentThreadItemType.Comment,
          threadType: getCommentThreadType(typeElement.type),
          contentComponentId: contentComponentId ?? null,
          elementSegment: null,
        }),
      );
    }, [typeElement, contentComponentId]);

    if (!isRequiredStateLoaded) {
      return null;
    }

    return (
      <ItemElementComponent
        ref={refToForward}
        areContentItemElementsHighlighting={areContentItemElementsHighlighting}
        canUpdateContent={canUpdateContent}
        className={className}
        commentThreadId={firstUnresolvedThreadId}
        contentComponentId={contentComponentId}
        currentUserId={currentUserId}
        defaultTabIndex={defaultTabIndex}
        disabled={disabled}
        elementSupportsComments={elementSupportsComments}
        errorMessages={errorMessages}
        escapeHandledByEditor={escapeHandledByEditor}
        focusEditor={focusEditor}
        focusItemElement={focusItemElement}
        friendlyWarningMessages={friendlyWarningMessages}
        onNewComment={onNewComment}
        hasFocusedComment={hasFocusedComment}
        headerClassName={headerClassName}
        hideErrorMessages={hideValidationStatus}
        isCompact={isCompact}
        isDisplayOnly={isDisplayOnly}
        isFocused={isFocused}
        isHighlighted={isHighlighted}
        isLocked={!!lockedByUser}
        isOutdated={isOutdated}
        isRefreshPending={isRefreshPending}
        lockedByUser={lockedByUser}
        onContentClick={onContentClick}
        onHeaderClick={onHeaderClick}
        revisionChangedBy={revisionElementUser}
        showAiTranslateButton={showAiTranslateButton}
        showAiReviewButton={showAiReviewButton}
        showElementStatus={showElementStatus}
        showIncompleteItemWarningsBeforePublish={showIncompleteItemWarningsBeforePublish}
        showLimitationWarning={showLimitationWarning}
        title={title}
        typeElement={typeElement}
        validationPassed={validationPassed}
      >
        {children}
      </ItemElementComponent>
    );
  },
);

ItemElement.displayName = 'ItemElementContainer';
