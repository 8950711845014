import { Action } from '../../../../../../@types/Action.type.ts';
import {
  Invite_Closed,
  Invite_NotificationBar_Hidden,
  Invite_SubmitInvitations_Failed,
  Invite_SubmitInvitations_Finished,
} from '../../../constants/usersActionTypes.ts';

const emptyState = null;

export const savingError = (state: string | null = emptyState, action: Action): string | null => {
  switch (action.type) {
    case Invite_SubmitInvitations_Failed:
      return action.payload.errorMessage;
    case Invite_SubmitInvitations_Finished:
    case Invite_NotificationBar_Hidden:
    case Invite_Closed:
      return emptyState;
    default:
      return state;
  }
};
