import {
  IBaseSelectItem,
  Option,
  RenderSelectMenuOptionProps,
} from '@kontent-ai/component-library/Selects';
import React from 'react';
import {
  EmptySpaceNamesMetadata,
  SpaceNamesMetadata,
  getCollapsedSpaceNames,
} from '../../applications/environmentSettings/spaces/utils/spacesUtils.ts';
import { joinWithOxfordComma } from '../utils/stringUtils.ts';

type Props = RenderSelectMenuOptionProps<IBaseSelectItem> & {
  readonly collectionId: Uuid;
  readonly spaceData?: SpaceNamesMetadata;
};

export const CollectionSelectOption: React.FC<Props> = ({
  collectionId,
  spaceData,
  ...restProps
}) => {
  if (!spaceData || spaceData === EmptySpaceNamesMetadata) {
    return <Option {...restProps} />;
  }

  const collapsedDisplayNames = getCollapsedSpaceNames(spaceData);
  const displayNames = spaceData.usedSpaces.map(({ name }) => name);
  const serializedCollapsedSpaceNames = joinWithOxfordComma(collapsedDisplayNames);
  const serializedSpaceNames = joinWithOxfordComma(displayNames);

  const complementaryText =
    spaceData && spaceData.usedSpaces.length > 1
      ? `Shared collection: ${serializedCollapsedSpaceNames}`
      : `Space: ${serializedCollapsedSpaceNames}`;
  const complementaryTextTooltip =
    complementaryText && spaceData.usedSpaces.length > 1
      ? `\nSpaces: ${serializedSpaceNames}`
      : `\nSpace: ${serializedSpaceNames}`;

  return (
    <Option
      {...restProps}
      complementaryText={complementaryTextTooltip}
      renderComplementaryText={() => complementaryText}
    />
  );
};

CollectionSelectOption.displayName = 'CollectionSelectOption';
