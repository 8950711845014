import React from 'react';
import { ColumnSettingsSource } from '../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { ContentStatusScrollTableHead as ContentStatusScrollTableHeadComponent } from '../../components/ContentStatusScrollTable/ContentStatusScrollTableHead.tsx';
import { useHiddenColumns } from '../../hooks/useHiddenColumn.ts';

export const ContentStatusScrollTableHead: React.FC = () => {
  const hiddenColumns = useHiddenColumns();

  return (
    <ContentStatusScrollTableHeadComponent
      hiddenColumns={hiddenColumns}
      source={ColumnSettingsSource.ContentInventory}
    />
  );
};
