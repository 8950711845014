import { Size } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { ExpandCollapseAnimation } from '../../../../_shared/components/ExpandCollapseAnimation/ExpandCollapseAnimation.tsx';
import {
  ContentItemPreviewRoute,
  ContentItemRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { isVariantArchived } from '../../../../_shared/utils/contentItemVariantUtils.ts';
import { getCannotViewItemMessage } from '../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { getLinkedContentItemPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IEditedContentItem } from '../../../itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { IExpandedNodesData } from '../../reducers/expandedNodesData.ts';
import { NodeId } from '../../types/nodeId.type.ts';
import { WebSpotlightTabName } from '../../types/webSpotlightTabName.ts';
import { checkIfItemHasSubpages, getNodeId } from '../../utils/webSpotlightUtils.ts';
import { WebSpotlightRedactedTreeItem } from './WebSpotlightRedactedTreeItem.tsx';
import { WebSpotlightTreeItem } from './WebSpotlightTreeItem.tsx';

export interface IWebSpotlightTreeNodeStateProps {
  readonly contentItems: Immutable.Map<Uuid, IListingContentItem>;
  readonly editedContentItem: IEditedContentItem | null;
  readonly expandedNodesData: Immutable.Map<NodeId, IExpandedNodesData>;
  readonly nodesBeingLoaded: Immutable.Set<NodeId>;
  readonly selectedNodeId: NodeId;
  readonly selectedTab: WebSpotlightTabName;
  readonly selectedLanguageId: Uuid;
  readonly subpagesById: Immutable.Map<Uuid, UuidArray>;
}

export interface IWebSpotlightTreeNodeDispatchProps {
  readonly onNodeCollapsed: (nodeId: NodeId) => void;
  readonly onNodeExpanded: (nodeId: NodeId, itemId: Uuid) => void;
  readonly onNodeSelected: (nodeId: NodeId, itemId: Uuid, nodePath: string) => void;
}

interface IWebSpotlightTreeNodeOwnProps {
  readonly currentPath: string;
  readonly depth: number;
  readonly focusedNodeId: NodeId;
  readonly itemId: Uuid;
  readonly parentPath: string;
}

export type WebSpotlightTreeNodeProps = IWebSpotlightTreeNodeStateProps &
  IWebSpotlightTreeNodeDispatchProps &
  IWebSpotlightTreeNodeOwnProps;

const webSpotlightTreeNodeHeight = Size.S;

export const WebSpotlightTreeNode: React.FC<WebSpotlightTreeNodeProps> = (props) => {
  const {
    contentItems,
    depth,
    editedContentItem,
    expandedNodesData,
    focusedNodeId,
    itemId,
    nodesBeingLoaded,
    onNodeCollapsed,
    onNodeExpanded,
    onNodeSelected,
    parentPath,
    selectedNodeId,
    selectedLanguageId,
    selectedTab,
    subpagesById,
  } = props;

  const nodeId = getNodeId(parentPath, itemId);

  const isExpanded = expandedNodesData.has(nodeId);
  const isLoading = nodesBeingLoaded.has(nodeId);
  const isSelected = nodeId === selectedNodeId;
  const isFocused = nodeId === focusedNodeId;
  const item = contentItems.get(itemId);
  const subpagesIds = subpagesById.get(itemId);
  const hasSubpages = checkIfItemHasSubpages(subpagesById, contentItems, itemId);

  const isItemVariantArchived = item?.variant && isVariantArchived(item.variant);
  const isItemVariantTranslated = item?.variant && !isItemVariantArchived;

  const itemPath = getLinkedContentItemPath(
    parentPath,
    itemId,
    selectedTab === WebSpotlightTabName.Editor || !isItemVariantTranslated
      ? ContentItemRoute
      : ContentItemPreviewRoute,
  );

  const handleToggle = useCallback(
    () => (isExpanded ? onNodeCollapsed(nodeId) : onNodeExpanded(nodeId, itemId)),
    [nodeId, isExpanded, itemId, onNodeCollapsed, onNodeExpanded],
  );

  const handleNodeSelected = useCallback(() => {
    if (!isSelected) {
      onNodeSelected(nodeId, itemId, itemPath);
    }
  }, [isSelected, itemId, itemPath, nodeId, onNodeSelected]);

  if (!item || item.item?.archived) {
    return null;
  }

  const cannotViewDisabledMessage = getCannotViewItemMessage(item);

  if (cannotViewDisabledMessage) {
    return (
      <li>
        <WebSpotlightRedactedTreeItem tooltip={cannotViewDisabledMessage} isSelected={isSelected} />
      </li>
    );
  }

  return (
    <li>
      <WebSpotlightTreeItem
        depth={depth}
        editedContentItem={editedContentItem}
        hasSubpages={hasSubpages}
        isExpanded={isExpanded}
        isFocused={isFocused}
        isLoading={isLoading}
        isSelected={isSelected}
        isTranslated={!!isItemVariantTranslated}
        item={item}
        itemPath={itemPath}
        nodeId={nodeId}
        onNodeSelected={handleNodeSelected}
        onToggle={handleToggle}
        selectedLanguageId={selectedLanguageId}
      />
      {hasSubpages && isItemVariantTranslated && (
        <ExpandCollapseAnimation
          estimatedMaxHeightWhenExpanded={webSpotlightTreeNodeHeight * (subpagesIds?.length || 0)}
          heightWhenCollapsed={0}
          renderCollapsed={() => null}
          renderExpanded={() => (
            <ul className="web-spotlight-tree__node">
              {subpagesIds?.map((id: Uuid) => (
                <WebSpotlightTreeNode
                  {...props}
                  depth={depth + 1}
                  itemId={id}
                  key={id}
                  parentPath={itemPath}
                  selectedTab={selectedTab}
                />
              ))}
            </ul>
          )}
          shouldBeExpanded={isExpanded || depth === 0}
        />
      )}
    </li>
  );
};

WebSpotlightTreeNode.displayName = 'WebSpotlightTreeNode';
