import { ICollection } from '../../data/models/collections/Collection.ts';
import { getCurrentProjectId } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { Capability } from '../utils/permissions/capability.ts';
import { filterCollectionsByCapability } from '../utils/permissions/filterCollectionsByCapability.ts';

export const getRelevantCollections = (
  state: IStore,
  capability: Capability,
): ReadonlyArray<ICollection> =>
  filterCollectionsByCapability(
    capability,
    state.data.collections.byId,
    getCurrentProjectId(state),
    state.data.user,
  );
