import { memoize } from '@kontent-ai/memoization';
import { ApiStatus } from '../../../../_shared/models/ApiStatus.ts';

export const getApiSwitchLabelText = memoize.allForever(
  (apiStatus: ApiStatus, featureName?: string): string => {
    switch (apiStatus) {
      case ApiStatus.Activating:
        return featureName ? `${featureName} is being activated` : 'Activating';
      case ApiStatus.Deactivating:
        return featureName ? `${featureName} is being deactivated` : 'Deactivating';
      case ApiStatus.Enabled:
        return featureName ? `${featureName} is active` : 'Active';
      case ApiStatus.Disabled:
        return featureName ? `${featureName} is inactive` : 'Inactive';
    }
  },
);
