import {
  UserProfile_RouteEntered,
  UserProfile_RouteLeft,
} from '../constants/userProfileActionTypes.ts';

export const userProfileRouteEntered = () =>
  ({
    type: UserProfile_RouteEntered,
  }) as const;

export const userProfileRouteLeft = () =>
  ({
    type: UserProfile_RouteLeft,
  }) as const;
