import { assert, Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { MemoizedContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getMemoizedContentItemId } from '../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { areFallbacksForLinkedContentEnabled } from '../../../../../../_shared/selectors/fallbacksForLinkedContent.ts';
import { getListingContentItem } from '../../../../../../_shared/selectors/getListingContentItem.ts';
import { getNotTranslatedListingContentItemIds } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { CascadeNodeId } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { isDefaultVariantRequiredForPublish } from '../../../ContentItemEditing/utils/itemValidationUtils.ts';
import { IEnsureLoadedContentTypesAction } from '../../../LoadedItems/actions/thunks/ensureLoadedContentTypes.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';
import {
  ContentEditing_CascadeModal_ChildNodesInitialized,
  ContentEditing_CascadeModal_InitFinished,
  ContentEditing_CascadeModal_InitStarted,
} from '../../constants/cascadeModalActionTypes.ts';
import { getFirstLevelChildrenItemIds } from '../../selectors/getFirstLevelChildrenItemIds.ts';
import { getCascadeNodeId } from '../../utils/getCascadeNodeId.ts';
import { nodesSelected } from '../cascadeModalActions.ts';
import { IEnsureDefaultVariantsAction } from './ensureDefaultVariants.ts';

interface IDeps {
  readonly ensureDefaultVariants: IEnsureDefaultVariantsAction;
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadListingItemsWithAllVariants: (
    itemIds: UuidArray,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

type InitialChildNode = {
  readonly contentItemId: MemoizedContentItemId;
  readonly nodeId: CascadeNodeId;
};

const initCascadeModalStarted = () =>
  ({
    type: ContentEditing_CascadeModal_InitStarted,
  }) as const;

const initCascadeModalFinished = () =>
  ({
    type: ContentEditing_CascadeModal_InitFinished,
  }) as const;

const childNodesInitialized = (initialChildNodes: ReadonlyArray<InitialChildNode>) =>
  ({
    type: ContentEditing_CascadeModal_ChildNodesInitialized,
    payload: {
      initialChildNodes,
    },
  }) as const;

export type InitCascadeModalActionType = ReturnType<
  typeof initCascadeModalStarted | typeof initCascadeModalFinished | typeof childNodesInitialized
>;

export const initCascadeModalActionCreator =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const { editedContentItem, editedContentItemVariant, loadedContentItemTypes } =
      state.contentApp;

    dispatch(initCascadeModalStarted());

    assert(
      editedContentItem,
      () =>
        `${__filename}: Action cannot be processed when no editedContentItem is present in the state.`,
    );
    assert(
      editedContentItemVariant,
      () =>
        `${__filename}: Action cannot be processed when no editedContentItemVariant is present in the state.`,
    );

    const defaultItemId = getMemoizedContentItemId(
      editedContentItemVariant.id.itemId,
      DefaultVariantId,
    );
    const itemType = loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId);
    assert(
      itemType,
      () =>
        `${__filename}: Action cannot be processed when no edited item type is present in the state.`,
    );

    const hasNonLocalizableElements = itemType.contentElements.some(
      (element) => element.isNonLocalizable,
    );
    const firstLevelChildIds = getFirstLevelChildrenItemIds(state);
    const firstLevelDependencyIds = hasNonLocalizableElements
      ? Collection.add(firstLevelChildIds, defaultItemId)
      : firstLevelChildIds;

    const parentNodeId = getCascadeNodeId({
      itemId: editedContentItemVariant.id.itemId,
      variantId: editedContentItemVariant.id.variantId,
    });
    const initialChildNodes = Array.from(firstLevelDependencyIds).map(
      ({ itemId, variantId }): InitialChildNode => {
        const nodeId = getCascadeNodeId({
          itemId,
          parentNodeId,
          variantId,
        });

        return {
          contentItemId: getMemoizedContentItemId(itemId, variantId),
          nodeId,
        };
      },
    );

    dispatch(childNodesInitialized(initialChildNodes));

    const listingItemIdsToLoad = Array.from(firstLevelChildIds).map(
      (contentItemId) => contentItemId.itemId,
    );

    const loadedItems = await dispatch(
      deps.loadListingItems(
        [...listingItemIdsToLoad, editedContentItemVariant.id.itemId],
        abortSignal,
      ),
    );
    assert(loadedItems, () => 'Listing items failed to load');

    const typeIdsToLoad = new Set(loadedItems.map((item) => item.item.typeId));
    const loadedContentTypes = await dispatch(
      deps.ensureLoadedContentTypes(typeIdsToLoad, abortSignal),
    );
    assert(loadedContentTypes, () => 'Content types failed to load');

    if (areFallbacksForLinkedContentEnabled(state)) {
      const notTranslatedItemIds = getNotTranslatedListingContentItemIds(loadedItems);
      await dispatch(deps.loadListingItemsWithAllVariants(notTranslatedItemIds, abortSignal));
    }

    if (editedContentItemVariant.id.variantId !== DefaultVariantId) {
      const itemWithDefaultVariant = getListingContentItem(getState(), defaultItemId);
      const canViewDefaultVariant = hasActiveVariantCapability(
        ActiveCapabilityType.ViewContent,
        itemWithDefaultVariant,
      );
      const dependsOnDefaultVariant = isDefaultVariantRequiredForPublish(
        itemType,
        false,
        canViewDefaultVariant,
        itemWithDefaultVariant,
      );

      if (dependsOnDefaultVariant) {
        dispatch(nodesSelected([defaultItemId]));
      }

      await dispatch(deps.ensureDefaultVariants(loadedItems, loadedContentTypes, abortSignal));
    }

    dispatch(initCascadeModalFinished());
  };
