import { ContentBlock } from 'draft-js';
import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getBlockKey } from '../../../../utils/blocks/editorBlockGetters.ts';
import { getContentComponentId } from '../../api/editorContentComponentUtils.ts';
import { ContentComponentBlock } from './ContentComponentBlock.tsx';

interface IContentComponentBlocksProps {
  readonly collapsedContentComponentIds?: Immutable.Set<Uuid>;
  readonly contentComponentBlocks: ReadonlyArray<ContentBlock>;
  readonly disabled: boolean;
  readonly editorId: string;
  readonly highlightedBlockKeys: ReadonlySet<string>;
  readonly isDragging: boolean;
  readonly newComponentBlockKey: string | null;
  readonly onNewContentComponentMounted: () => void;
}

export const ContentComponentBlocks: React.FC<IContentComponentBlocksProps> = ({
  contentComponentBlocks,
  editorId,
  isDragging,
  highlightedBlockKeys,
  disabled,
  onNewContentComponentMounted,
  newComponentBlockKey,
}) => {
  const collapsedContentComponentIds = useSelector(
    (state) => state.contentApp.editorUi.collapsedContentComponents,
  );

  return (
    <div>
      {contentComponentBlocks.map((block: ContentBlock) => {
        const blockKey = getBlockKey(block);

        const contentComponentId = getContentComponentId(block);
        if (!contentComponentId) {
          return null;
        }

        const isCollapsed = !!collapsedContentComponentIds?.contains(contentComponentId);
        const isHighlighted = highlightedBlockKeys.has(blockKey);
        const shouldAutoFocus = !!newComponentBlockKey && blockKey === newComponentBlockKey;

        if (isCollapsed) {
          return null;
        }

        return (
          <ContentComponentBlock
            key={blockKey}
            block={block}
            disabled={disabled}
            editorId={editorId}
            isCollapsed={isCollapsed}
            isHighlighted={isHighlighted}
            isDragging={isDragging}
            newComponentBlockKey={newComponentBlockKey}
            onMount={onNewContentComponentMounted}
            shouldAutoFocus={shouldAutoFocus}
          />
        );
      })}
    </div>
  );
};

ContentComponentBlocks.displayName = 'ContentComponentBlocks';
