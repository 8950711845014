import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type NoAssetsFoundMessageProps = {
  readonly buttonText: string;
  readonly infoMessage: string;
  readonly onButtonClick: () => void;
  readonly title: string;
};

export const NoAssetsFoundMessage: FC<NoAssetsFoundMessageProps> = ({
  buttonText,
  infoMessage,
  title,
  onButtonClick,
}) => (
  <EmptyState dataUiElement={DataUiElement.AssetListingEmptySearch}>
    <EmptyState.Title>{title}</EmptyState.Title>
    <EmptyState.Content>{infoMessage}</EmptyState.Content>
    <EmptyState.Footer>
      <Button
        buttonStyle="primary"
        onClick={onButtonClick}
        {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
      >
        {buttonText}
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

NoAssetsFoundMessage.displayName = 'NoAssetsFoundMessage';
