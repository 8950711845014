import { AbortError, isAbortError } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { Dispatch, GetState, ThunkPromise } from '../../@types/Dispatcher.type.ts';
import { useDispatch } from './useDispatch.ts';

type Options = {
  readonly canRun: boolean;
};

const defaultOptions: Options = {
  canRun: true,
};

const isOptions = (input: any): input is AtLeastOnePropertyOf<Options> => {
  return (
    input instanceof Object &&
    Object.keys(input).length > 0 &&
    Object.keys(input).every((key) =>
      (Object.keys(defaultOptions) as ReadonlyArray<string>).includes(key),
    )
  );
};

export enum UseThunkPromiseStatus {
  Pending = 'pending',
  Running = 'running',
  Done = 'done',
  Failed = 'failed',
  Aborted = 'aborted',
}

/**
 * Use this hook when you need to run a thunk promise on-render. It dispatches the thunk promise action right after the first render.
 * The start can be managed through useThunkPromise options.canRun property.
 * The hook takes care of the abortion of the previous run in cases the thunk itself, or its params change.
 * It is required to use because of React StrictMode that runs useEffect twice: https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-re-running-effects-in-development
 */
export const useThunkPromise = <
  TThunkPromise extends (
    dispatch: Dispatch<any>,
    getState: GetState<any>,
  ) => Promise<any> = ThunkPromise,
  TParams extends ReadonlyArray<unknown> = never,
>(
  action: ((...args: readonly [...TParams, AbortSignal]) => TThunkPromise) | null,
  ...params: TParams extends []
    ? [options?: AtLeastOnePropertyOf<Options>]
    : [...TParams, options?: AtLeastOnePropertyOf<Options>]
):
  | Readonly<
      [isDone: true, result: Awaited<ReturnType<TThunkPromise>>, status: UseThunkPromiseStatus]
    >
  | Readonly<[isDone: false, result: undefined, status: UseThunkPromiseStatus]> => {
  const [status, setStatus] = useState(UseThunkPromiseStatus.Pending);
  const [result, setResult] = useState<Awaited<ReturnType<TThunkPromise>> | undefined>(undefined);
  const dispatch = useDispatch();

  const lastParam = Collection.getLast(params);
  const { canRun } = isOptions(lastParam) ? lastParam : defaultOptions;
  const actionParams = isOptions(lastParam) ? params.toSpliced(params.length - 1) : params;

  // biome-ignore lint/correctness/useExhaustiveDependencies(actionParams): We need to spread it as actionParams array reference is new on every render.
  useEffect(() => {
    setStatus(UseThunkPromiseStatus.Pending);
    setResult(undefined);

    if (!canRun || !action) return;

    setStatus(UseThunkPromiseStatus.Running);

    const abortController = new AbortController();
    dispatch(action(...(actionParams as any as TParams), abortController.signal))
      .then((value: Awaited<ReturnType<TThunkPromise>>) => {
        setStatus(UseThunkPromiseStatus.Done);
        setResult(value);
      })
      .catch((error) => {
        setStatus(() =>
          isAbortError(error) ? UseThunkPromiseStatus.Aborted : UseThunkPromiseStatus.Failed,
        );
        setResult(undefined);
      });

    return () => {
      abortController.abort(
        new AbortError('Running thunk promise aborted from useThunkPromise useEffect cleanup.'),
      );
      setStatus((prevStatus) =>
        prevStatus === UseThunkPromiseStatus.Running ? UseThunkPromiseStatus.Aborted : prevStatus,
      );
      setResult(undefined);
    };
  }, [action, ...actionParams, canRun]);

  return [status === UseThunkPromiseStatus.Done, result as any, status];
};
