import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isAnyFilterActive } from '../selectors/userListingFilterSelectors.ts';

export enum UserListingState {
  EmptyProject = 'EmptyProject',
  NoMatch = 'NoMatch',
  NotInvitedYet = 'NotInvitedYet',
  WithUsers = 'WithUsers',
}

export const getUserListingState = (
  state: IStore,
  displayedUsersCount: number,
  foundContributorsByName: boolean,
): UserListingState => {
  if (state.usersApp.projectContributors.size === 1) {
    return UserListingState.EmptyProject;
  }

  if (isAnyFilterActive(state.usersApp.filter) && displayedUsersCount === 0) {
    return foundContributorsByName ? UserListingState.NoMatch : UserListingState.NotInvitedYet;
  }

  return UserListingState.WithUsers;
};
