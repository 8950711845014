import { deletedRoleIdBase } from '../../constants/getDeletedRole.ts';
import {
  stepContainsDeletedRoleError,
  stepContainsDeletedRolesError,
} from '../constants/workflowErrorMessages.ts';

export const containsDeletedRole = (roles: ReadonlyArray<string>): string | undefined => {
  const deletedRoles = roles.filter((roleId) => roleId.includes(deletedRoleIdBase));

  switch (deletedRoles.length) {
    case 1:
      return stepContainsDeletedRoleError;

    case 0:
      return undefined;

    default:
      return stepContainsDeletedRolesError;
  }
};
