import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  IAssetTypeElementData,
  newAssetTypeElementData,
} from '../models/elements/AssetTypeElementData.ts';
import {
  EmptyContentTypeSnippetTypeElementData,
  IContentTypeSnippetTypeElementData,
} from '../models/elements/ContentTypeSnippetTypeElementData.ts';
import {
  EmptyCustomTypeElementData,
  ICustomTypeElementData,
} from '../models/elements/CustomTypeElementData.tsx';
import {
  EmptyDateTimeTypeElementData,
  IDateTimeTypeElementData,
} from '../models/elements/DateTimeTypeElementData.ts';
import {
  EmptyGuidelinesTypeElementData,
  IGuidelinesTypeElementData,
} from '../models/elements/GuidelinesTypeElementData.ts';
import {
  EmptyLinkedItemsTypeElementData,
  ILinkedItemsTypeElementData,
  LinkedItemsRelationType,
} from '../models/elements/LinkedItemsTypeElementData.ts';
import {
  IMultipleChoiceTypeElementData,
  createEmptyMultipleChoiceTypeElementData,
} from '../models/elements/MultipleChoiceTypeElementData.ts';
import {
  EmptyNumberTypeElementData,
  INumberTypeElementData,
} from '../models/elements/NumberTypeElementData.ts';
import {
  EmptyRichTextTypeElementData,
  IRichTextTypeElementData,
} from '../models/elements/RichTextTypeElementData.ts';
import {
  EmptyTaxonomyTypeElementData,
  ITaxonomyTypeElementData,
} from '../models/elements/TaxonomyTypeElementData.ts';
import {
  EmptyTextTypeElementData,
  ITextTypeElementData,
} from '../models/elements/TextTypeElementData.ts';
import {
  EmptyUrlSlugTypeElementData,
  IUrlSlugTypeElementData,
} from '../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';

const typeElementCreatorsMap: ReadonlyRecord<TypeElementType, () => IBaseTypeElementData> = {
  [ElementType.Asset]: (): IAssetTypeElementData => newAssetTypeElementData(),
  [ElementType.Subpages]: (): ILinkedItemsTypeElementData => ({
    ...EmptyLinkedItemsTypeElementData,
    type: ElementType.Subpages,
    relationType: LinkedItemsRelationType.Subpages,
  }),
  [ElementType.ContentTypeSnippet]: (): IContentTypeSnippetTypeElementData =>
    EmptyContentTypeSnippetTypeElementData,
  [ElementType.Custom]: (): ICustomTypeElementData => EmptyCustomTypeElementData,
  [ElementType.DateTime]: (): IDateTimeTypeElementData => EmptyDateTimeTypeElementData,
  [ElementType.Guidelines]: (): IGuidelinesTypeElementData => EmptyGuidelinesTypeElementData,
  [ElementType.LinkedItems]: (): ILinkedItemsTypeElementData => ({
    ...EmptyLinkedItemsTypeElementData,
    relationType: LinkedItemsRelationType.LinkedItems,
  }),
  [ElementType.MultipleChoice]: (): IMultipleChoiceTypeElementData =>
    createEmptyMultipleChoiceTypeElementData(),
  [ElementType.Number]: (): INumberTypeElementData => EmptyNumberTypeElementData,
  [ElementType.RichText]: (): IRichTextTypeElementData => EmptyRichTextTypeElementData,
  [ElementType.Taxonomy]: (): ITaxonomyTypeElementData => EmptyTaxonomyTypeElementData,
  [ElementType.Text]: (): ITextTypeElementData => EmptyTextTypeElementData,
  [ElementType.UrlSlug]: (): IUrlSlugTypeElementData => EmptyUrlSlugTypeElementData,
};

export function createDefaultTypeElementData(type: TypeElementType): IBaseTypeElementData {
  return typeElementCreatorsMap[type]();
}
