import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { AuditLog_Listing_Left } from '../../../../applications/environmentSettings/auditLog/constants/auditLogActionTypes.ts';
import {
  Data_AuditEvents_Failed,
  Data_AuditEvents_Started,
  Data_AuditEvents_Success,
} from '../../../constants/dataActionTypes.ts';

const initialState = LoadingStatus.InitialEmpty;

export const loadingStatus = (state = initialState, action: Action): LoadingStatus => {
  switch (action.type) {
    case Data_AuditEvents_Failed:
    case AuditLog_Listing_Left:
      return initialState;

    case Data_AuditEvents_Started:
      return LoadingStatus.Loading;

    case Data_AuditEvents_Success:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
};
