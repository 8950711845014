import { CollapsibleOption, ISelectItem } from '@kontent-ai/component-library/Selects';
import { Collection, notNullNorUndefined } from '@kontent-ai/utils';
import { useMemo, useState } from 'react';
import { DefaultTag } from '../../../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { capitalizeFirstLetter } from '../../../../../../../_shared/utils/stringUtils.ts';
import { getAllWorkflowSteps } from '../../../../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { getWorkflowTooltip } from '../../../../../../../_shared/utils/workflow/getWorkflowTooltip.ts';
import { Workflow } from '../../../../../../../data/models/workflow/Workflow.ts';
import { IWorkflowStep } from '../../../../../../../data/models/workflow/WorkflowStep.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly collection: DataUiCollection;
  readonly filter: IListingFilter;
  readonly onWorkflowsStepSelectionChanged: (
    workflowToSelectedStepIds: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    workflowToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
  ) => void;
  readonly placeholder: string;
  readonly title: string;
};

interface IWorkflowOptionSelectItem extends ISelectItem<IWorkflowOptionSelectItem> {
  readonly tooltip?: string;
}

const combineIds = (workflowId: Uuid, stepId: Uuid) => `${workflowId}/${stepId}`;

const toWorkflowStepSelectItem = (
  workflowStep: IWorkflowStep,
  workflow: Workflow,
): IWorkflowOptionSelectItem => ({
  id: combineIds(workflow.id, workflowStep.id),
  label: workflowStep.name,
  tooltip: capitalizeFirstLetter(getWorkflowTooltip(workflow.name)),
});

const toWorkflowSelectItem = (workflow: Workflow): IWorkflowOptionSelectItem => ({
  id: workflow.id,
  label: workflow.name,
  items: getAllWorkflowSteps(workflow).map((step) => toWorkflowStepSelectItem(step, workflow)),
});

export const ExtendedWorkflowStepFilterSelector = (props: Props) => {
  const workflowsById = useSelector((state) => state.data.workflows.byId);
  const workflows = useMemo(() => Collection.getValues(workflowsById), [workflowsById]);

  const selectedOptionIds = useMemo(() => {
    return Collection.getEntries(props.filter.selectedWorkflowNodes)
      .flatMap(([workflowId, stepIds]) => {
        if (stepIds.size) {
          return [...stepIds].map((stepId) => combineIds(workflowId, stepId));
        }

        const workflow = workflows.find(({ id }) => id === workflowId);
        const allSteps = workflow ? getAllWorkflowSteps(workflow) : [];
        return allSteps.map((step) => combineIds(workflowId, step.id));
      })
      .filter(notNullNorUndefined);
  }, [workflows, props.filter.selectedWorkflowNodes]);

  const items = useMemo(() => workflows.map(toWorkflowSelectItem), [workflows]);
  const [expandedWorkflowIds, setExpandedWorkflowIds] = useState<ReadonlySet<Uuid>>(
    () => new Set(items.map((item) => item.id)),
  );
  const [isCollapseDisabled, setIsCollapseDisabled] = useState(false);

  const onChange = (selectedIds: ReadonlySet<Uuid>): void => {
    const selectedStepIdsByWorkflowId = [...selectedIds].reduce((stepsIdsByWorkflowId, id) => {
      const [workflowId = '', stepId = null] = id.split('/');
      if (!stepId) {
        return stepsIdsByWorkflowId.set(workflowId, new Set());
      }

      const stepIds = stepsIdsByWorkflowId.get(workflowId);
      if (!stepIds) {
        return stepsIdsByWorkflowId.set(workflowId, new Set([stepId]));
      }

      if (!stepIds.size) {
        return stepsIdsByWorkflowId;
      }

      return stepsIdsByWorkflowId.set(workflowId, new Set([...stepIds, stepId]));
    }, new Map<Uuid, ReadonlySet<Uuid>>());

    const workflowToAllStepIds = new Map(
      workflows.map((w) => [w.id, getAllWorkflowSteps(w).map((step) => step.id)] as const),
    );
    props.onWorkflowsStepSelectionChanged(selectedStepIdsByWorkflowId, workflowToAllStepIds);
  };

  const onSearchTextChange = (searchText?: string) => {
    setIsCollapseDisabled(!!searchText);
    setExpandedWorkflowIds(new Set(items.map((item) => item.id)));
  };

  return (
    <ListingFilterCategorySelector
      {...props}
      options={items}
      onChange={onChange}
      onSearchTextChange={onSearchTextChange}
      onExpandedChange={setExpandedWorkflowIds}
      selectedOptionIds={selectedOptionIds}
      expandedKeys={expandedWorkflowIds}
      renderSelectedOption={(_id, selectedItem, defaultTagProps) => (
        <DefaultTag customTooltipText={selectedItem.tooltip} {...defaultTagProps} />
      )}
      renderOption={(optionProps) => (
        <CollapsibleOption
          isDisabled={isCollapseDisabled}
          disabledTooltipText="You can’t collapse the list when filtering."
          {...optionProps}
        />
      )}
    />
  );
};
