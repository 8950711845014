export interface ICoordinates {
  readonly x: number;
  readonly y: number;
}

export function getDepthFromCoordinates(coords: ICoordinates): number {
  if (Number.isInteger(coords.x) && Number.isInteger(coords.y) && coords.x < 1000) {
    return coords.y * 1000 + coords.x;
  }
  throw new Error(
    '[getDepthFromCoordinates] Provide two integers. The first must be lower than 1000.',
  );
}

export function getCoordinatesFromDepth(depth: number): ICoordinates {
  if (!Number.isInteger(depth)) {
    throw new Error('[getCoordinatesFromDepth] Provide an integer.');
  }

  return {
    x: depth % 1000,
    y: Math.floor(depth / 1000),
  };
}
