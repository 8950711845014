import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentListing_CheckPublishWarnings_Finished,
  ContentListing_Init_Started,
} from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFailed,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFailed,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_DiscardNewVersionFailed,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFailed,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFailed,
  Content_Editing_UnpublishContentItemVariantFailed,
  Content_Editing_UnpublishContentItemVariantFinished,
  Content_HideValidationResults,
  Content_ShowFriendlyWarnings,
} from '../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  NewContentItem_ContentItemCreatedInLinkedItems,
  NewContentItem_ContentItemCreatedInListing,
  NewContentItem_ContentItemCreatedInRichText,
} from '../../features/NewContentItem/constants/newContentItemActionTypes.ts';
import { hasAnyWarning } from '../../models/PublishWarnings.ts';

const initialState: boolean = false;

export function showFriendlyWarnings(state = initialState, action: Action): boolean {
  switch (action.type) {
    case Content_ShowFriendlyWarnings:
      return true;

    case ContentListing_CheckPublishWarnings_Finished:
      return hasAnyWarning(action.payload.publishWarnings);

    case ContentItemEditing_Init_Started:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_AssignmentSubmittingFailed:
    case Content_Editing_DiscardNewVersionFailed:
    case Content_Editing_UnpublishContentItemVariantFailed:
    case Content_Editing_ScheduledPublishingFailed:
    case Content_Editing_ScheduledUnpublishingFailed:
    case Content_Editing_CancelScheduledPublishingFailed:
    case Content_Editing_CancelScheduledUnpublishingFailed:
    case NewContentItem_ContentItemCreatedInRichText:
    case NewContentItem_ContentItemCreatedInLinkedItems:
    case NewContentItem_ContentItemCreatedInListing:
    case ContentListing_Init_Started:
    case Content_HideValidationResults: {
      return false;
    }

    default:
      return state;
  }
}
