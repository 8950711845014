export const SupportedAuditLogHistoryInDays = 90;

export const MaxSearchPhraseLength = 200;

export const DateTimePickerDefaultTime = '0:00';

export const AvailableSearchPhrasesHelpMessage =
  'Search for logs by user’s name, their e-mail, specific content type or snippet, or the type of action, such as “delete”.';

export const MaxLoadedEvents = 100;

export const LoadingMoreEventsDebounceTime = 100; // ms

export const AuditLogFilterDebounceTime = 700; // ms
