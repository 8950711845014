import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Typography, gridUnit, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  renderDateString,
  renderTimeString,
} from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { canAccessEnvironmentSettings } from '../../../environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { isCloningFailed, isCloningInProgress } from '../../../projects/utils/copyStateUtils.ts';
import { IEnvironmentViewModel } from '../selectors/getEnvironmentsForListing.ts';
import { EnvironmentMainButton } from './EnvironmentMainButton.tsx';
import { EnvironmentMoreActions } from './EnvironmentMoreActions.tsx';

export type EnvironmentTileOwnProps = {
  readonly environment: IEnvironmentViewModel;
  readonly planLimitReached: boolean;
  readonly subscriptionId?: Uuid;
  readonly onCreateEnvironment: (cloneFromId: Uuid) => void;
  readonly onDeleteEnvironment: (deletedEnvironmentId: Uuid) => void;
  readonly onSwapEnvironment: (environmentId: Uuid) => void;
};

type EnvironmentTileProps = {
  readonly hasManageEnvironmentCapability: boolean;
  readonly hasManageProductionEnvironmentCapability: boolean;
  readonly onDismissRenameAdvice: () => void;
  readonly onGoToEnvironment: () => void;
  readonly onGoToEnvironmentSettings: () => void;
  readonly showRenameAdvice: boolean;
};

type Props = EnvironmentTileProps & EnvironmentTileOwnProps;

interface IShortenedTextProps {
  readonly maxLines: number;
}

export const environmentTileWidth = 44 * gridUnit;

export const EnvironmentCard = styled(Card)`
  width: ${px(environmentTileWidth)};
  min-height: 100%;
`;

const shortenedTextStyles = css<IShortenedTextProps>`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ maxLines }) => maxLines};
`;

const EnvironmentInfoText = styled.p<IShortenedTextProps>`
  ${shortenedTextStyles};
  ${Typography.TitleMedium};
`;

const renderDateTimeWithCommaSeparator = (datetime: DateTimeStamp): string =>
  `${renderDateString(datetime)}, ${renderTimeString(datetime, false)}`;

const getEnvSourceText = (environment: IEnvironmentViewModel): string => {
  if (environment.isMasterEnvironment) {
    return '';
  }

  const sourceEnvText = `Cloned from ${
    environment.sourceEnvironmentName ? environment.sourceEnvironmentName : 'deleted environment'
  }`;

  return environment.createdAt
    ? `${sourceEnvText} on ${renderDateTimeWithCommaSeparator(environment.createdAt)}`
    : sourceEnvText;
};

const getEnvironmentInfo = (environment: IEnvironmentViewModel): string => {
  if (environment.isMasterEnvironment) {
    return environment.productionFrom
      ? `In production since ${renderDateTimeWithCommaSeparator(environment.productionFrom)}`
      : 'In production';
  }

  if (environment.productionFrom && environment.productionTo && !environment.isMasterEnvironment) {
    return `Was in production from ${renderDateTimeWithCommaSeparator(
      environment.productionFrom,
    )} to ${renderDateTimeWithCommaSeparator(environment.productionTo)}`;
  }

  return getEnvSourceText(environment);
};

export const EnvironmentTile: React.FC<Props> = ({
  environment,
  hasManageEnvironmentCapability,
  hasManageProductionEnvironmentCapability,
  onCreateEnvironment,
  onDeleteEnvironment,
  onDismissRenameAdvice,
  onGoToEnvironment,
  onGoToEnvironmentSettings,
  onSwapEnvironment,
  planLimitReached,
  showRenameAdvice,
}) => {
  const canAccessSettings = useSelector((s) =>
    canAccessEnvironmentSettings(s, environment.environmentId),
  );
  const hasAccessToMoreActions =
    !isCloningInProgress(environment.copyState) &&
    !isCloningFailed(environment.copyState) &&
    !environment.isBeingDeleted;

  const environmentInfo = getEnvironmentInfo(environment);

  return (
    <EnvironmentCard
      component="section"
      {...getDataUiElementAttribute(
        environment.isMasterEnvironment
          ? DataUiElement.ProductionEnvironmentCard
          : DataUiElement.EnvironmentCard,
      )}
    >
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.EnvironmentCardTitle)}>
        <OptionalTooltip text={environment.environmentName} placement="top">
          {environment.environmentName}
        </OptionalTooltip>
      </Card.Headline>
      <Card.Body>
        <OptionalTooltip
          text={environmentInfo}
          placement="top"
          customRenderText={(ref) => (
            <EnvironmentInfoText maxLines={2} ref={ref}>
              {environmentInfo}
            </EnvironmentInfoText>
          )}
        />
      </Card.Body>
      <Card.Footer>
        <Row>
          <Column>
            <EnvironmentMainButton
              environment={environment}
              hasManageEnvironmentCapability={hasManageEnvironmentCapability}
              onDeleteEnvironment={onDeleteEnvironment}
              onGoToEnvironment={onGoToEnvironment}
            />
          </Column>
          {hasAccessToMoreActions && (
            <Column width="content">
              <EnvironmentMoreActions
                disabled={!hasManageEnvironmentCapability && !canAccessSettings}
                environmentId={environment.environmentId}
                hasManageProductionEnvironmentCapability={hasManageProductionEnvironmentCapability}
                hasManageEnvironmentCapability={hasManageEnvironmentCapability}
                canAccessToSettings={canAccessSettings}
                isMasterEnvironment={environment.isMasterEnvironment}
                onCreateEnvironment={onCreateEnvironment}
                onDeleteEnvironment={onDeleteEnvironment}
                onDismissRenameAdvice={onDismissRenameAdvice}
                onGoToEnvironment={onGoToEnvironment}
                onGoToEnvironmentSettings={onGoToEnvironmentSettings}
                onSwapEnvironment={onSwapEnvironment}
                planLimitReached={planLimitReached}
                showRenameAdvice={showRenameAdvice}
              />
            </Column>
          )}
        </Row>
      </Card.Footer>
    </EnvironmentCard>
  );
};

EnvironmentTile.displayName = 'EnvironmentTile';
