import { Collection } from '@kontent-ai/utils';
import {
  EntityWebhookAction,
  EntityWebhookDeliverySlot,
  EntityWebhookEventsFilter,
  contentItemLiveTriggerActions,
  contentItemPreviewTriggerActions,
} from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookTriggers } from '../models/IEntityWebhookFormShape.type.ts';

export const areAllEntityTriggersEmpty = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  [
    triggers.assetTrigger.actions,
    triggers.contentItemTrigger.actions,
    triggers.contentTypeTrigger.actions,
    triggers.languageTrigger.actions,
    triggers.taxonomyTrigger.actions,
  ].every(Collection.isEmpty);

export const hasCheckedEntityTriggerWithNotSelectedOption = (
  triggers: IEntityWebhookTriggers,
): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  ((triggers.assetTrigger.checked && Collection.isEmpty(triggers.assetTrigger.actions)) ||
    (triggers.contentItemTrigger.checked &&
      triggers.slot === EntityWebhookDeliverySlot.Published &&
      !anyCurrentlyVisibleEventIsSelected(
        triggers.contentItemTrigger.actions,
        contentItemLiveTriggerActions,
      )) ||
    (triggers.contentItemTrigger.checked &&
      triggers.slot === EntityWebhookDeliverySlot.Preview &&
      !anyCurrentlyVisibleEventIsSelected(
        triggers.contentItemTrigger.actions,
        contentItemPreviewTriggerActions,
      )) ||
    (triggers.contentTypeTrigger.checked &&
      Collection.isEmpty(triggers.contentTypeTrigger.actions)) ||
    (triggers.languageTrigger.checked && Collection.isEmpty(triggers.languageTrigger.actions)) ||
    (triggers.taxonomyTrigger.checked && Collection.isEmpty(triggers.taxonomyTrigger.actions)));

export const hasNoCheckedEntityTrigger = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  !triggers.assetTrigger.checked &&
  !triggers.contentItemTrigger.checked &&
  !triggers.contentTypeTrigger.checked &&
  !triggers.languageTrigger.checked &&
  !triggers.taxonomyTrigger.checked;

export const hasMissingContentItemCollectionFilter = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.contentItemTrigger.checked &&
  triggers.contentItemTrigger.collectionIds.length < 1;

export const hasMissingContentItemContentTypeFilter = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.contentItemTrigger.checked &&
  triggers.contentItemTrigger.contentTypeIds.length < 1;

export const hasMissingContentItemLanguageFilter = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.contentItemTrigger.checked &&
  triggers.contentItemTrigger.languageIds.length < 1;

export const hasMissingContentTypeContentTypeFilter = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.contentTypeTrigger.checked &&
  triggers.contentTypeTrigger.contentTypeIds.length < 1 &&
  !(
    triggers.contentTypeTrigger.actions.length === 1 &&
    triggers.contentTypeTrigger.actions[0] === EntityWebhookAction.Created
  );

export const hasMissingLanguageLanguageFilter = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.languageTrigger.checked &&
  triggers.languageTrigger.languageIds.length < 1 &&
  !(
    triggers.languageTrigger.actions.length === 1 &&
    triggers.languageTrigger.actions[0] === EntityWebhookAction.Created
  );

export const hasMissingTaxonomyTaxonomyFilter = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.taxonomyTrigger.checked &&
  triggers.taxonomyTrigger.taxonomyIds.length < 1 &&
  !(
    triggers.taxonomyTrigger.actions.length === 1 &&
    triggers.taxonomyTrigger.actions[0] === EntityWebhookAction.Created
  );

export const hasMissingWorkflowSteps = (triggers: IEntityWebhookTriggers): boolean =>
  triggers.events === EntityWebhookEventsFilter.Specific &&
  triggers.slot === EntityWebhookDeliverySlot.Preview &&
  triggers.contentItemTrigger.checked &&
  triggers.contentItemTrigger.actions.includes(EntityWebhookAction.WorkflowStepChanged) &&
  triggers.contentItemTrigger.workflowSteps.length < 1;

export const anyCurrentlyVisibleEventIsSelected = (
  selectedEvents: ReadonlyArray<string>,
  allActionOptions: ReadonlyArray<EntityWebhookAction>,
): boolean => {
  return Collection.intersect(selectedEvents, allActionOptions).length > 0;
};
