import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadAssetType: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createEnsureAssetLibraryRelatedDataAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadAssetType(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadSpaces(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
    ]);
  };
