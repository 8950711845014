import { useCallback, useEffect, useState } from 'react';

/**
 * Use this hook to auto-focus an element.
 * E.g. when adding new roles in the user configuration. Call the `focus()` and use the flag
 * to pass autoFocus flag to the newly created component.
 * Returns an object with properties:
 * - 'focus' The callback to ensure the autoFocus state is set to true.
 * - 'isFocused' The flag containing information about the autoFocus.
 */
export const useAutoFocus = () => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      setIsFocused(false);
    }
  }, [isFocused]);

  const focus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return { focus, isFocused };
};
