import { Checkbox, CheckboxState } from '@kontent-ai/component-library/Checkbox';
import classNames from 'classnames';
import React from 'react';
import { TreeNode } from '../../../../../../../_shared/components/TreeList/TreeNode.tsx';
import { getDataUiObjectNameAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

export interface IGetTaxonomySelectorNodeClassNameInput {
  readonly isSelected: boolean;
  readonly term: ITaxonomyTerm;
}

interface ITaxonomySelectorNodeProps {
  readonly autoFocus?: boolean;
  readonly disabled: boolean;
  readonly getNodeClassName?: (input: IGetTaxonomySelectorNodeClassNameInput) => string;
  readonly isDeleted?: boolean;
  readonly onClick?: (termId: Uuid) => void;
  readonly selectedTermIds: ReadonlySet<Uuid>;
  readonly taxonomyGroup: ITaxonomyGroup;
  readonly term: ITaxonomyTerm | null;
  readonly tooltipText?: string;
}

const getTaxonomyNodeCheckboxState = (disabled: boolean, deleted?: boolean): CheckboxState => {
  if (disabled) {
    return 'disabled';
  }
  if (deleted) {
    return 'alert';
  }

  return 'default';
};

export class TaxonomySelectorNode extends React.PureComponent<ITaxonomySelectorNodeProps> {
  static displayName = 'TaxonomySelectorNode';

  private readonly _termToggled = (): void => {
    if (this.props.term) {
      this.props.onClick?.(this.props.term.id);
    }
  };

  render() {
    const {
      autoFocus,
      disabled,
      getNodeClassName,
      isDeleted,
      onClick,
      selectedTermIds,
      taxonomyGroup,
      term,
      tooltipText,
    } = this.props;

    const isSelected = !!term && selectedTermIds.has(term.id);
    const className = term && getNodeClassName?.({ isSelected, term });

    return (
      <TreeNode
        collapsed={false}
        disabled={disabled}
        disabledExpand={!term?.childIds.length}
        onToggle={undefined}
        nodeItem={
          term && (
            <div className={classNames('tree-node__item', className)}>
              <Checkbox
                autoFocus={autoFocus}
                checkboxState={getTaxonomyNodeCheckboxState(disabled, isDeleted)}
                checked={isSelected}
                key={`${term?.id}|${disabled}`}
                onToggle={this._termToggled}
                tooltipText={tooltipText}
                {...getDataUiObjectNameAttribute(term.name)}
              >
                {term.name}
              </Checkbox>
            </div>
          )
        }
      >
        {term?.childIds.map((childTermId) => (
          <TaxonomySelectorNode
            taxonomyGroup={taxonomyGroup}
            disabled={disabled}
            key={childTermId}
            term={taxonomyGroup.terms.get(childTermId) ?? null}
            selectedTermIds={selectedTermIds}
            onClick={onClick}
            getNodeClassName={getNodeClassName}
            tooltipText={tooltipText}
          />
        ))}
      </TreeNode>
    );
  }
}
