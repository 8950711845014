import { Action } from '../../../../../../@types/Action.type.ts';
import {
  Invite_Closed,
  Invite_Initialize,
  Invite_SubmitInvitations_Finished,
} from '../../../constants/usersActionTypes.ts';

export const isInitialized = (state = false, action: Action): boolean => {
  switch (action.type) {
    case Invite_Initialize:
      return true;
    case Invite_Closed:
    case Invite_SubmitInvitations_Finished:
      return false;
    default:
      return state;
  }
};
