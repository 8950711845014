import { ActiveCapabilityType } from '../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { isDisabled } from '../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../_shared/utils/permissions/activeCapabilities.ts';

export function getCanEditGroupContent(state: IStore): boolean {
  const {
    contentApp: { editedContentItemVariant },
  } = state;

  const disabled = isDisabled(false, editedContentItemVariant);
  const canUpdateContent = hasActiveVariantCapabilityForEditedItem(
    ActiveCapabilityType.UpdateContent,
    state,
  );
  const canEditGroupContent = !disabled && canUpdateContent;

  return canEditGroupContent;
}
