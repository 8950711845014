import { ZIndex } from '../quarks/zIndex.ts';

// z-index of tippy https://atomiks.github.io/tippyjs/v6/all-props/#zindex
export const defaultTippyZIndex = ZIndex.NineThousandNineHundredNinetyNine;

// We need to place tooltips over other tippy components and it's z-index is 9999
// See https://kontent-ai.atlassian.net/browse/CL-68 fore more info
export const tooltipZIndex = ZIndex.TenThousand;

export const overlayZIndex = ZIndex.TenThousand;
