import { Collection } from '@kontent-ai/utils';
import { FindRightContentTrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { FindRightContentEventActionData } from '../../../../../../_shared/models/events/FindRightContentEventData.type.ts';
import { MapUtils } from '../../../../../../_shared/utils/mapUtils/mapUtils.ts';
import { SetUtils } from '../../../../../../_shared/utils/setUtils/setUtils.ts';
import { checkEqualityOfContentItemStatus } from '../../../models/filter/IContentItemStatus.ts';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';
import { checkEqualityOfPublishingStates } from '../../../models/filter/IPublishingState.ts';

export function* getFindRightContentTrackingPayloads(
  oldFilterState: IListingFilter,
  newFilterState: IListingFilter,
): Generator<FindRightContentEventActionData> {
  const {
    searchPhrase: oldSearchPhrase,
    searchScope: oldScope,
    selectedCollectionsNodes: oldCollections,
    selectedContentTypesNodes: oldTypes,
    selectedSitemapNodes: oldNodes,
    selectedWorkflowNodes: oldStatuses,
    selectedContributorsNodes: oldContributors,
    selectedTaxonomyNodes: oldTaxonomyTerms,
    selectedContentItemStatus: oldSelectedContentItemStatus,
    selectedPublishingStateNodes: oldPublishingStateNodes,
  } = oldFilterState;
  const {
    searchPhrase: newSearchPhrase,
    searchScope: newScope,
    selectedCollectionsNodes: newCollections,
    selectedContentTypesNodes: newTypes,
    selectedSitemapNodes: newNodes,
    selectedWorkflowNodes: newStatuses,
    selectedContributorsNodes: newContributors,
    selectedTaxonomyNodes: newTaxonomyTerms,
    selectedContentItemStatus: newSelectedContentItemStatus,
    selectedPublishingStateNodes: newPublishingStateNodes,
  } = newFilterState;

  if (
    (!oldSearchPhrase && !!newSearchPhrase) ||
    (!newSearchPhrase.includes(oldSearchPhrase) && !oldSearchPhrase.includes(newSearchPhrase)) ||
    !SetUtils.isEqual(oldScope, newScope)
  ) {
    yield {
      name: FindRightContentTrackedEvent.FilteredByName,
      'search-phrase': newSearchPhrase,
      'search-scope': [...newScope],
    };
  }

  if (!SetUtils.isEqual(newTypes, oldTypes)) {
    yield { name: FindRightContentTrackedEvent.FilteredByType };
  }

  if (!SetUtils.isEqual(newNodes, oldNodes)) {
    yield { name: FindRightContentTrackedEvent.FilteredBySitemap };
  }

  if (!SetUtils.isEqual(newCollections, oldCollections)) {
    yield { name: FindRightContentTrackedEvent.FilteredByCollections };
  }

  if (!MapUtils.isEqual(newStatuses, oldStatuses, SetUtils.isEqual)) {
    yield {
      name: FindRightContentTrackedEvent.FilteredByWorkflow,
      selectedWorkflowNodes: JSON.stringify(
        Object.fromEntries(
          Collection.getEntries(newFilterState.selectedWorkflowNodes).map(
            ([workflowId, stepIds]) => [workflowId, [...stepIds]],
          ),
        ),
      ),
    };
  }

  if (!SetUtils.isEqual(newContributors, oldContributors)) {
    yield { name: FindRightContentTrackedEvent.FilteredByContributors };
  }

  if (!MapUtils.isEqual(oldTaxonomyTerms, newTaxonomyTerms, SetUtils.isEqual)) {
    yield { name: FindRightContentTrackedEvent.FilteredByTaxonomy };
  }

  if (
    !checkEqualityOfContentItemStatus(newSelectedContentItemStatus, oldSelectedContentItemStatus)
  ) {
    yield { name: FindRightContentTrackedEvent.FilteredByRequiredElements };
  }

  if (!checkEqualityOfPublishingStates(newPublishingStateNodes, oldPublishingStateNodes)) {
    yield { name: FindRightContentTrackedEvent.FilteredByPublishingState };
  }
}
