import React from 'react';
import { Route, Switch } from 'react-router';
import {
  CollectionsRoute,
  CustomAppEditingRoute,
  EnvironmentsRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhooksRoute,
  LocalizationRoute,
  PreviewURLsRoute,
  SpacesRoute,
  SubscriptionEnvironmentCollectionsRoute,
  SubscriptionEnvironmentCustomAppEditingRoute,
  SubscriptionEnvironmentEnvironmentsRoute,
  SubscriptionEnvironmentGeneralSettingsRoute,
  SubscriptionEnvironmentLegacyWebhooksRoute,
  SubscriptionEnvironmentLocalizationRoute,
  SubscriptionEnvironmentPreviewURLsRoute,
  SubscriptionEnvironmentSpacesRoute,
  SubscriptionEnvironmentUsersRoute,
  SubscriptionEnvironmentWorkflowEditingRoute,
  UsersRoute,
  WorkflowEditingRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { EnvironmentSavingStatus } from '../../../projectSettings/environments/containers/statusMessages/EnvironmentSavingStatus.tsx';
import { CustomAppSavingStatusPlaceholder } from '../../customApps/components/toolbar/CustomAppSavingStatusPlaceholder.tsx';
import { CollectionsSavingStatus } from '../containers/statusMessages/CollectionsSavingStatus.ts';
import { GeneralEnvironmentSettingsSavingStatus } from '../containers/statusMessages/GeneralEnvironmentSettingsSavingStatus.tsx';
import { LanguageSavingStatus } from '../containers/statusMessages/LanguageSavingStatus.tsx';
import { PreviewConfigurationSavingStatus } from '../containers/statusMessages/PreviewConfigurationSavingStatus.tsx';
import { SpacesSavingStatus } from '../containers/statusMessages/SpacesSavingStatus.tsx';
import { UsersSavingStatus } from '../containers/statusMessages/UsersSavingStatus.tsx';
import { WebhookSavingStatus } from '../containers/statusMessages/WebhookSavingStatus.tsx';
import { WorkflowSavingStatus } from '../containers/statusMessages/WorkflowSavingStatus.tsx';

export const EnvironmentSettingsStatusMessage: React.FC = () => {
  return (
    <Switch>
      <Route path={[UsersRoute, SubscriptionEnvironmentUsersRoute]}>
        <UsersSavingStatus />
      </Route>
      <Route path={[WorkflowEditingRoute, SubscriptionEnvironmentWorkflowEditingRoute]}>
        <WorkflowSavingStatus />
      </Route>
      <Route path={[LocalizationRoute, SubscriptionEnvironmentLocalizationRoute]}>
        <LanguageSavingStatus />
      </Route>
      <Route path={[GeneralEnvironmentSettingsRoute, SubscriptionEnvironmentGeneralSettingsRoute]}>
        <GeneralEnvironmentSettingsSavingStatus />
      </Route>
      <Route path={[PreviewURLsRoute, SubscriptionEnvironmentPreviewURLsRoute]}>
        <PreviewConfigurationSavingStatus />
      </Route>
      <Route path={[LegacyWebhooksRoute, SubscriptionEnvironmentLegacyWebhooksRoute]}>
        <WebhookSavingStatus />
      </Route>
      <Route path={[EnvironmentsRoute, SubscriptionEnvironmentEnvironmentsRoute]}>
        <EnvironmentSavingStatus />
      </Route>
      <Route path={[CollectionsRoute, SubscriptionEnvironmentCollectionsRoute]}>
        <CollectionsSavingStatus />
      </Route>
      <Route path={[SpacesRoute, SubscriptionEnvironmentSpacesRoute]}>
        <SpacesSavingStatus />
      </Route>
      <Route path={[CustomAppEditingRoute, SubscriptionEnvironmentCustomAppEditingRoute]}>
        <CustomAppSavingStatusPlaceholder />
      </Route>
    </Switch>
  );
};

EnvironmentSettingsStatusMessage.displayName = 'EnvironmentSettingsStatusMessage';
