import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  getDate,
  getISODay,
  getMonth,
  isAfter,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { DateRange } from '../../../../_shared/models/DateRange.type.ts';
import { AgendaItemStatus } from '../constants/AgendaItemStatus.ts';
import { ICalendarEvent } from '../models/CalendarModels.type.ts';

export const getDateRangeForGivenDateEntry = (
  dateTimeStamp: DateTimeStamp,
): {
  from: Date;
  to: Date;
} => {
  const date = new Date(dateTimeStamp);

  const startOfMonthDate = startOfMonth(date);
  const firstWeekDayOfMonth = getISODay(startOfMonthDate); // 1 is Monday, 7 is Sunday
  const beginningOfCalendar = subDays(startOfMonthDate, firstWeekDayOfMonth - 1);

  const endOfMonthDate = endOfMonth(date);
  const lastDayOfMonth = getISODay(endOfMonthDate);
  const endOfCalendar = addDays(endOfMonthDate, 7 - lastDayOfMonth);

  return {
    from: startOfDay(beginningOfCalendar),
    to: startOfDay(endOfCalendar),
  };
};

export const getFormattedDateRangeForGivenDateEntry = (
  dateTimeStamp: DateTimeStamp,
  formatString = "yyyy-MM-dd'T'HH:mm:ss",
): DateRange => {
  const { from, to } = getDateRangeForGivenDateEntry(dateTimeStamp);
  return {
    from: format(from, formatString),
    to: format(to, formatString),
  };
};

const isLastDayOfPreviousMonth = (day: Date, currentMonth: Date) => {
  const isPreviousMonth = (getMonth(day) + 1) % 12 === getMonth(currentMonth);
  return isPreviousMonth && getDate(day) === getDate(endOfMonth(subMonths(currentMonth, 1)));
};

const isFirstDayOfNextMonth = (day: Date, currentMonth: Date) => {
  const isNextMonth = getMonth(day) === (getMonth(currentMonth) + 1) % 12;
  return isNextMonth && getDate(day) === getDate(startOfMonth(addMonths(currentMonth, 1)));
};

export const getFormattedDate = (date: Date, compareTo: Date): string => {
  if (isLastDayOfPreviousMonth(date, compareTo) || isFirstDayOfNextMonth(date, compareTo)) {
    return format(date, 'd MMMM');
  }

  return format(date, 'd');
};

/**
 * Returns the start of the current month for the given range
 * @param  range — DateRange
 */
export const getCurrentMonthFromRange = (range: DateRange): Date => {
  const parsed = {
    from: new Date(range.from),
    to: new Date(range.to),
  };

  if (getDate(parsed.from) === 1) {
    return startOfMonth(parsed.from);
  }

  if ((getMonth(parsed.from) + 2) % 12 === getMonth(parsed.to)) {
    return startOfMonth(addMonths(parsed.from, 1));
  }

  return startOfMonth(parsed.to);
};

export const areDatesSame = (a: Date | DateTimeStamp, b: Date | DateTimeStamp): boolean =>
  startOfDay(new Date(a)).getTime() === startOfDay(new Date(b)).getTime();

export const getAgendaStateForDueItems = (
  due: DateTimeStamp,
  getCurrentDateTime: () => Date,
): AgendaItemStatus =>
  isAfter(new Date(due), getCurrentDateTime())
    ? AgendaItemStatus.OnTrack
    : AgendaItemStatus.Delayed;

export const getCurrentDate = (): Date => new Date();

export const compareEventsByDate = (a: ICalendarEvent, b: ICalendarEvent) =>
  a.date.localeCompare(b.date);

export const isDateInRange = (
  date: Date | DateTimeStamp,
  from: Date | DateTimeStamp,
  to: Date | DateTimeStamp,
): boolean =>
  isWithinInterval(new Date(date), {
    start: startOfDay(new Date(from)),
    end: endOfDay(new Date(to)),
  });
