import React, { ReactNode } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly children?: ReactNode;
  readonly text?: string;
}

export class Guidelines extends React.PureComponent<IProps> {
  static displayName = 'Guidelines';

  render() {
    const { text, children } = this.props;
    const isTextDefined = text && text.length > 0;

    if (!isTextDefined && !children) {
      return null;
    }

    const paragraphs = (text || '').split(/[\r\n]/);

    return (
      <div
        className="content-item-element__guidelines"
        {...getDataUiElementAttribute(DataUiElement.ContentElementGuidelines)}
      >
        {isTextDefined &&
          paragraphs.map((paragraph, position) => {
            return <p key={position}>{paragraph}</p>;
          })}
        {children}
      </div>
    );
  }
}
