import { useAttachRef } from '@kontent-ai/hooks';
import { useLink } from '@react-aria/link';
import { AriaLinkProps } from '@react-types/link';
import React from 'react';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { RouterLinkProps } from '../../Anchor/Anchor.tsx';
import { TooltipPropsExtension } from '../../_utils/propPrefabs.ts';
import { ButtonBadge } from '../ButtonBadge.tsx';
import { ButtonIcon } from '../ButtonIcon.tsx';
import { IBaseButtonProps } from '../components/BaseButton.ts';
import { BaseButtonComponent } from '../components/BaseButtonComponent.tsx';
import { StyledRouterLinkButton } from '../components/StyledButton.tsx';

type RouterLinkButtonProps = TooltipPropsExtension &
  Pick<IBaseButtonProps, 'buttonStyle' | 'size' | 'buttonDisplay' | 'disableTabulator'> &
  Pick<RouterLinkProps, 'to'> &
  Pick<AriaLinkProps, 'aria-label' | 'onPress' | 'aria-describedby'> & {
    readonly className?: string;
  };

const RouterLinkButtonWithRef = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<RouterLinkButtonProps>
>(
  (
    {
      buttonDisplay = 'inline',
      buttonStyle,
      children,
      className,
      size = 'medium',
      to,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'top',
      tooltipShortcuts,
      tooltipText,
      ...props
    },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef<HTMLAnchorElement>(forwardedRef);
    const { linkProps } = useLink({ ...props, elementType: 'a' }, refObject);

    return (
      <BaseButtonComponent
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        tooltipMaxGridUnitsWidth={tooltipMaxGridUnitsWidth}
        tooltipShortcuts={tooltipShortcuts}
        buttonStyle={buttonStyle}
        buttonDisplay={buttonDisplay}
        size={size}
        renderButtonComponent={(injectedProps) => (
          <StyledRouterLinkButton
            className={className}
            innerRef={refToForward}
            to={to}
            {...linkProps}
            {...injectedProps}
            {...getDataUiComponentAttribute(RouterLinkButtonWithRef)}
          />
        )}
      >
        {children}
      </BaseButtonComponent>
    );
  },
);

RouterLinkButtonWithRef.displayName = 'RouterLinkButton';

const routerLinkButtonComposition = {
  Badge: ButtonBadge,
  Icon: ButtonIcon,
} as const;

export const RouterLinkButton = Object.assign(RouterLinkButtonWithRef, routerLinkButtonComposition);
