import {
  DeliveryKeysRouteSegment,
  ManagementKeysRouteSegment,
} from '../../root/constants/routeSegments.ts';

export enum ApiKeyTab {
  MapiKeys = 'mapi-keys',
  DapiKeys = 'dapi-keys',
}

export const areAnyKeyTabsVisible = (visibleTabs: ReadonlyArray<ApiKeyTab>): boolean =>
  visibleTabs.length > 0;

export const getLastRouteSegmentForApiKeyTab = (apiKeyTab: ApiKeyTab): string => {
  switch (apiKeyTab) {
    case ApiKeyTab.DapiKeys:
      return DeliveryKeysRouteSegment;
    case ApiKeyTab.MapiKeys:
      return ManagementKeysRouteSegment;
    default:
      return '';
  }
};

export const getApiKeyTabLabel = (apiKeyTab: ApiKeyTab): string => {
  switch (apiKeyTab) {
    case ApiKeyTab.DapiKeys:
      return 'Delivery API keys';
    case ApiKeyTab.MapiKeys:
      return 'Management API keys';
    default:
      return '';
  }
};
