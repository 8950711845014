export const Collections_Save_Started = 'Collections_Save_Started';
export const Collections_Save_Finished = 'Collections_Save_Finished';
export const Collections_Save_Failed = 'Collections_Save_Failed';
export const Collections_Listing_Initialized = 'Collections_Listing_Initialized';
export const Collections_Listing_Left = 'Collections_Listing_Left';

export const Collections_Creator_Opened = 'Collections_Creator_Opened';
export const Collections_Creator_Canceled = 'Collections_Creator_Canceled';
export const Collections_Creator_Submitted = 'Collections_Creator_Submitted';

export const Collections_Editor_Opened = 'Collections_Editor_Opened';
export const Collections_Editor_Canceled = 'Collections_Editor_Canceled';
export const Collections_Editor_Submitted = 'Collections_Editor_Submitted';
export const Collections_Editor_Deleted = 'Collections_Editor_Deleted';

export const Collections_Editor_CollectionDraggingStarted =
  'Collections_Editor_CollectionDraggingStarted';
export const Collections_Editor_CollectionDraggingFinished =
  'Collections_Editor_CollectionDraggingFinished';
export const Collections_Editor_CollectionMoved = 'Collections_Editor_CollectionMoved';

export const Collections_FilterSearchPhrase_Changed = 'Collections_FilterSearchPhrase_Changed';

export const Collections_CollectionNameChanged = 'Collections_CollectionNameChanged';
