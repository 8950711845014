import {
  Subscription_BillingHistory_Select,
  Subscription_BillingHistory_Unselect,
} from '../constants/actionTypes.ts';

export const selectBillingHistoryItem = (billingHistoryItemId: Uuid) =>
  ({
    type: Subscription_BillingHistory_Select,
    payload: {
      billingHistoryItemId,
    },
  }) as const;

export const unselectBillingHistoryItem = () =>
  ({
    type: Subscription_BillingHistory_Unselect,
  }) as const;
