import { createGuid } from '@kontent-ai/utils';
import { useCallback, useState } from 'react';

export type ResetAiSessionId = () => void;

export const useAiSessionId = (): {
  readonly aiSessionId: Uuid;
  readonly resetAiSessionId: ResetAiSessionId;
} => {
  const [aiSessionId, setAiSessionId] = useState<Uuid>(createGuid());

  const resetAiSessionId: ResetAiSessionId = useCallback(() => {
    setAiSessionId(createGuid());
  }, []);

  return {
    aiSessionId,
    resetAiSessionId,
  };
};
