import React from 'react';
import { useHistory } from 'react-router';
import { modalOpened } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { duplicateItemWithContent } from '../../../../../DuplicateToCollection/actions/thunkDuplicateItemToCollectionActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { useIsJustCreatedItemClone } from '../../../../context/IsJustCreatedItemCloneContext.tsx';
import { redirectToItem } from '../../../../utils/redirectToItem.ts';

export const DuplicateItemWithContentAction: React.FC<
  React.PropsWithChildren<EditingActionDynamicDetailProviderOwnProps>
> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showModalDialog = useSelector(
    (state) => !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.CreateContent, state),
  );

  const openModalDialog = () => dispatch(modalOpened(ModalDialogType.DuplicateItemWithContent));

  const { setIsJustCreatedItemClone } = useIsJustCreatedItemClone();

  const duplicate = async (): Promise<void> => {
    const duplicateItem = await dispatch(duplicateItemWithContent());
    if (!duplicateItem) return;
    setIsJustCreatedItemClone(true);
    redirectToItem(history, duplicateItem.item.id);
  };

  const performAction = showModalDialog ? openModalDialog : duplicate;

  return (
    <EditingActionDynamicDetailProvider performAction={performAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
