import { ThunkFunction } from '../../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { IListingFilter } from '../../../../../models/filter/IListingFilter.ts';
import { getFindRightContentTrackingPayloads } from '../../../utils/getFindRightContentTrackingPayloads.ts';

export const trackFindRightContentInInventory =
  (oldFilterState: IListingFilter, newFilterState: IListingFilter): ThunkFunction =>
  (dispatch) =>
    Array.from(getFindRightContentTrackingPayloads(oldFilterState, newFilterState)).forEach(
      (payload) => dispatch(trackUserEventWithData(TrackedEvent.FindRightContent, payload)),
    );
