import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import { Users_UsersCountedInUsage_Loaded } from '../../constants/usersActionTypes.ts';

interface ILoadUsersCountedInUsageDependencies {
  readonly peopleRepository: Pick<IPeopleRepository, 'getPeopleIdsCountedInUsage'>;
}

const usersCountedInUsageLoaded = (usersCountedInUsage: ReadonlyArray<string>) =>
  ({
    type: Users_UsersCountedInUsage_Loaded,
    payload: { usersCountedInUsage },
  }) as const;

export type LoadUsersCountedInUsage = ReturnType<typeof usersCountedInUsageLoaded>;

export const loadUsersCountedInUsageCreator =
  (dependencies: ILoadUsersCountedInUsageDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const usersCountedInUsageServerModel =
      await dependencies.peopleRepository.getPeopleIdsCountedInUsage(abortSignal);

    dispatch(usersCountedInUsageLoaded(usersCountedInUsageServerModel.activeUserIds));
  };
