import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { FullTextSearchStatus_Loaded } from '../../constants/contentInventoryActionTypes.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
}

const loaded = (isFullTextSearchEnabled: boolean) =>
  ({
    type: FullTextSearchStatus_Loaded,
    payload: {
      isFullTextSearchEnabled,
    },
  }) as const;

export type LoadFullTextSearchStatusActionType = ReturnType<typeof loaded>;

export const createLoadFullTextSearchStatusAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const response = await deps.projectRepository.getFullTextSearchStatus(abortSignal);
    dispatch(loaded(response.enabled));
  };
