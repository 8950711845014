import { ReactNode } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { loadFullTextSearchStatus } from '../actions/thunkContentInventoryActions.ts';
import { FullTextSearchStatus } from '../reducers/IContentInventoryStoreState.type.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureFullTextSearchStatus = (props: Props) => {
  const [isLoadThunkDone] = useThunkPromise(loadFullTextSearchStatus);

  const isEnsured = useSelector(
    (s) =>
      isLoadThunkDone && s.contentInventory.fullTextSearchStatus !== FullTextSearchStatus.Initial,
  );

  return isEnsured ? props.children : <Loader />;
};
