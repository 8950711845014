import { Card } from '@kontent-ai/component-library/Card';
import { Input } from '@kontent-ai/component-library/Input';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Tag } from '../../../../../component-library/components/Tag/Tag.tsx';
import { RemovalState } from '../../../../../component-library/components/Tag/removalStateEnum.ts';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UntitledWorkflowSurrogateName } from '../../roles/constants/UIConstants.ts';

interface IWorkflowNameProps {
  readonly name: string;
  readonly isDefaultWorkflow: boolean;
  readonly onChange: (newName: string) => void;
}

export const WorkflowName: React.FC<IWorkflowNameProps> = ({
  name,
  isDefaultWorkflow,
  onChange,
}) => (
  <Card component="section" cardLabel="Workflow name">
    <Card.Headline
      renderAuxiliaryElements={() =>
        isDefaultWorkflow && (
          <Tag
            label="Default"
            background={BaseColor.Gray60}
            removalState={RemovalState.NoRemoval}
          />
        )
      }
    >
      Workflow name
    </Card.Headline>
    <Card.Body>
      <Input
        autoFocus
        maxLength={ValidationConstants.WorkflowNameMaxLength}
        onChange={(event) => onChange(event.target.value)}
        value={name}
        placeholder={UntitledWorkflowSurrogateName}
        {...getDataUiInputAttribute(DataUiInput.EntityName)}
      />
    </Card.Body>
  </Card>
);

WorkflowName.displayName = 'WorkflowName';
