import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  YourContent_InitYourTasks_Finished,
  YourContent_InitYourTasks_Started,
} from '../../../../applications/yourContent/constants/yourContentActionTypes.ts';

export function loadingStatus(
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case YourContent_InitYourTasks_Started:
      return LoadingStatus.Loading;

    case YourContent_InitYourTasks_Finished:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
}
