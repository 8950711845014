import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { SidebarNames } from '../../../../../../_shared/models/SidebarNames.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getResolvedCommentThreads } from '../../../../utils/commentUtils.ts';
import { resolvedCommentsSidebarClosed } from '../../actions/contentItemEditingActions.ts';
import {
  IResolvedCommentsSidebarDispatchProps,
  IResolvedCommentsSidebarStateProps,
  ResolvedCommentsSidebar as ResolvedCommentsSidebarComponent,
} from '../../components/comments/ResolvedCommentsSidebar.tsx';

const mapStateToProps = (state: IStore): IResolvedCommentsSidebarStateProps => {
  const {
    sharedApp: { sidebarStatus },
    contentApp: { editedContentItemVariantComments },
  } = state;

  const isResolvedCommentsSidebarOpen =
    sidebarStatus.isVisible && sidebarStatus.sidebarName === SidebarNames.ResolvedCommentsSidebar;

  const commentThreads = getResolvedCommentThreads(editedContentItemVariantComments.commentThreads);

  return {
    isOpened: isResolvedCommentsSidebarOpen,
    commentThreads,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IResolvedCommentsSidebarDispatchProps => ({
  onClose: () => dispatch(resolvedCommentsSidebarClosed()),
});

export const ResolvedCommentsSidebar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResolvedCommentsSidebarComponent);
