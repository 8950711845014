import { disabledAriaTippyOptions } from '@kontent-ai/component-library/Tooltip';
import { PortalContainerContext } from '@kontent-ai/component-library/context';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { DropShadow, px } from '@kontent-ai/component-library/tokens';
import { getDataUiComponentAttribute } from '@kontent-ai/component-library/utils';
import Tippy from '@tippyjs/react/headless';
import { FC, useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../app/_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IWorkflowStepConfig, workflowStepHeight } from './components/WorkflowStep.tsx';
import {
  WorkflowSteps,
  secondaryWorkflowStepOffset,
  workflowStepMinWidth,
} from './components/WorkflowSteps.tsx';

const Container = styled.div`
  position: relative;
  display: flex;
  height: ${px(workflowStepHeight)};
`;
const InnerContainer = styled.div`
  min-width: 0;
`;

interface IRealWorkflowStepsProps {
  readonly $isVisible: boolean;
}

const RealWorkflowSteps = styled(WorkflowSteps)<IRealWorkflowStepsProps>`
  opacity: 1;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
    opacity: 0;
  `};
`;

const FakeWorkflowSteps = styled(WorkflowSteps)`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;

interface IExpandedWorkflowStepsProps extends IRealWorkflowStepsProps {
  readonly $cursor: string | undefined;
}

const ExpandedWorkflowSteps = styled(WorkflowSteps).attrs<IExpandedWorkflowStepsProps>((props) => ({
  style: {
    cursor: props.$cursor,
  },
}))<IExpandedWorkflowStepsProps>`
  filter: ${DropShadow.L};
  opacity: 0;
  visibility: hidden;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
    opacity: 1;
    visibility: visible;
  `};
`;

const popperOptions = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        roundOffsets: false, // rounding leads to slight positioning issues so it's disabled
      },
    },
  ],
};

export interface IWorkflowStatusProps {
  readonly primary: IWorkflowStepConfig;
  readonly secondary?: IWorkflowStepConfig;
}

export const WorkflowStatus: FC<IWorkflowStatusProps> = ({ primary, secondary, ...otherProps }) => {
  const [isPrimaryOverflowing, setIsPrimaryOverflowing] = useState(false);
  const [isSecondaryOverflowing, setIsSecondaryOverflowing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { isFocusVisible, focusProps } = useOurFocusRing();

  const containerRef = useRef<HTMLDivElement>(null);
  const primaryRef = useRef<HTMLDivElement>(null);
  const secondaryRef = useRef<HTMLDivElement>(null);

  const { portalContainerRef } = useContext(PortalContainerContext);

  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      if (!width) {
        return;
      }

      const containerEl = containerRef.current;
      const primaryEl = primaryRef.current;
      const secondaryEl = secondaryRef.current;

      if (!containerEl) {
        return;
      }

      const containerRect = containerEl.getBoundingClientRect();

      if (primaryEl) {
        const primaryRect = primaryEl.getBoundingClientRect();
        const primaryOverflowOffset = secondaryEl
          ? workflowStepMinWidth + secondaryWorkflowStepOffset
          : 0;
        const primaryOffsetLeft = primaryRect.left - containerRect.left;
        const newIsPrimaryOverflowing =
          Math.round(primaryOffsetLeft + primaryRect.width + primaryOverflowOffset) > width;
        setIsPrimaryOverflowing(newIsPrimaryOverflowing);
      }

      if (secondaryEl) {
        const secondaryRect = secondaryEl.getBoundingClientRect();
        const secondaryOffsetLeft = secondaryRect.left - containerRect.left;
        const newIsSecondaryOverflowing =
          Math.round(secondaryOffsetLeft + secondaryRect.width) > width;
        setIsSecondaryOverflowing(newIsSecondaryOverflowing);
      }
    },
  });

  const isOverflowing = isPrimaryOverflowing || isSecondaryOverflowing;
  const isFakeVisible = isOverflowing && (isHovered || isFocusVisible);

  const containerEl = containerRef.current;
  const cursor = containerEl ? window.getComputedStyle(containerEl).cursor : undefined;

  return (
    <Container
      ref={containerRef}
      {...otherProps}
      {...getDataUiElementAttribute(DataUiElement.WorkflowStatus)}
    >
      <InnerContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FakeWorkflowSteps
          aria-hidden
          isFullSize
          primary={primary}
          primaryRef={primaryRef}
          secondary={secondary}
          secondaryRef={secondaryRef}
        />
        <Tippy
          render={() => (
            <ExpandedWorkflowSteps
              aria-hidden
              $cursor={cursor}
              isFocusVisible={isFocusVisible}
              isFullSize
              $isVisible={isFakeVisible}
              primary={primary}
              secondary={secondary}
            />
          )}
          interactive
          placement="top-start"
          popperOptions={popperOptions}
          appendTo={portalContainerRef.current ?? document.body}
          offset={[0, -1 * workflowStepHeight]}
          visible={isFakeVisible}
          {...disabledAriaTippyOptions}
        >
          <RealWorkflowSteps
            isFocusVisible={isFocusVisible}
            isPrimaryOverflowing={isPrimaryOverflowing}
            isSecondaryOverflowing={isSecondaryOverflowing}
            $isVisible={!isFakeVisible}
            primary={primary}
            secondary={secondary}
            tabIndex={0}
            {...focusProps}
            {...getDataUiComponentAttribute(WorkflowSteps)}
          />
        </Tippy>
      </InnerContainer>
    </Container>
  );
};

WorkflowStatus.displayName = 'WorkflowStatus';
