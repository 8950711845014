import { Action } from '../../../../../@types/Action.type.ts';
import { ISitemap } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import {
  Sitemap_BackupOriginal,
  Sitemap_InitSitemap,
  Sitemap_StartSaving,
} from '../../constants/sitemapActionTypes.ts';

export const originalSitemap = (state: ISitemap | null = null, action: Action): ISitemap | null => {
  switch (action.type) {
    case Sitemap_BackupOriginal:
      return action.payload.sitemap;

    case Sitemap_InitSitemap:
    case Sitemap_StartSaving:
      return null;

    default:
      return state;
  }
};
