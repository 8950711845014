import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadEnvironmentApiStatusCreator } from './thunks/loadEnvironmentApiStatusCreator.ts';
import { switchManagementApiStatusCreator } from './thunks/switchManagementApiStatus.ts';
import { switchSecuredDeliveryApiStatusCreator } from './thunks/switchSecuredDeliveryApiStatus.ts';

const { projectRepository } = repositoryCollection;

export const switchManagementApiStatus = switchManagementApiStatusCreator({
  projectRepository,
});

export const switchSecuredDeliveryApiStatus = switchSecuredDeliveryApiStatusCreator({
  projectRepository,
});

export const loadEnvironmentApiStatus = loadEnvironmentApiStatusCreator({
  projectRepository,
});
