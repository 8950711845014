import React, { useCallback, useMemo } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isMissingItemIdentifier } from '../../../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getVariantIdLanguage } from '../../../../../selectors/variantLanguage.ts';
import { useContentItemInCustomElement } from '../../../../ContentComponent/context/ContentItemContext.tsx';
import { getAssetDetails, getItemDetails } from '../../../actions/customElementActions.ts';
import {
  CustomElementSandbox as CustomElementSandboxComponent,
  ICustomElementSandboxOwnProps,
  IItemObservedData,
} from '../../../components/elements/customElement/CustomElementSandbox.tsx';

export const CustomElementSandbox: React.FC<ICustomElementSandboxOwnProps> = (ownProps) => {
  const dispatch = useDispatch();
  const { itemId, variantId, itemCodename, itemCollectionId, itemName } =
    useContentItemInCustomElement();
  const languages = useSelector((state) => state.data.languages);
  const currentProjectId = useSelector((state) => state.sharedApp.currentProjectId);

  const itemObservedData = useMemo<IItemObservedData>(
    () => ({
      collection: { id: itemCollectionId },
      codename: itemCodename,
      name: itemName,
    }),
    [itemCollectionId, itemName, itemCodename],
  );

  const getItemDetailsCallback = useCallback(
    (itemIds: UuidArray) => dispatch(getItemDetails(itemIds, variantId)),
    [variantId],
  );

  if (isMissingItemIdentifier(itemId)) {
    return null;
  }

  return (
    <CustomElementSandboxComponent
      getAssetDetails={(assetId) =>
        getAssetDetails(assetId, languages.byId, languages.defaultLanguage)
      }
      getItemDetails={getItemDetailsCallback}
      itemObservedData={itemObservedData}
      itemId={itemId}
      languageCodename={getVariantIdLanguage(variantId, languages).codename}
      projectId={currentProjectId}
      variantId={variantId}
      {...ownProps}
    />
  );
};
