import { usePrevious } from '@kontent-ai/hooks';
import { animated, useSpring } from '@react-spring/web';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';

export enum RestDirection {
  Up = 'up',
  Down = 'down',
}

type Props = {
  readonly className?: string;
  readonly isTurned: boolean;
  readonly restDirection?: RestDirection;
};

const getRotateValue = (degrees: number): string => `rotate(${degrees}deg)`;

const initialTurnedRotation = 180;

export const AnimatedChevron = ({ isTurned, restDirection, className }: Props) => {
  const degreesRef = useRef(isTurned ? initialTurnedRotation : 0);
  const [springProps, springRef] = useSpring(() => ({
    from: { rotate: getRotateValue(degreesRef.current) },
  }));
  const previousIsTurned = usePrevious(isTurned);

  useEffect(() => {
    if (previousIsTurned !== isTurned) {
      degreesRef.current += 180 * (isTurned ? 1 : -1);

      springRef.start({ rotate: getRotateValue(degreesRef.current) });
    }
  }, [previousIsTurned, isTurned, springRef]);

  return (
    <animated.i
      aria-hidden
      className={classNames(
        className,
        !restDirection || restDirection === RestDirection.Down
          ? IconName.ChevronDown
          : IconName.ChevronUp,
      )}
      style={{ transform: springProps.rotate }}
    />
  );
};
