import { Action } from '../../../@types/Action.type.ts';
import { ProjectContainer_ProjectContainerContext_Left } from '../../../applications/projectSettings/root/constants/projectSettingsActionTypes.ts';
import { ProjectApp_RouteLeft } from '../../../applications/projects/constants/projectActionTypes.ts';
import { SmartLink_RouteLeft } from '../../../applications/smartLink/constants/smartLinkActionTypes.ts';
import { SubscriptionManagement_RouteLeft } from '../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';
import { UserProfile_RouteLeft } from '../../../applications/userProfile/constants/userProfileActionTypes.ts';
import { Shared_CurrentProjectContainerId_Updated } from '../../constants/sharedActionTypes.ts';

const initialState: Uuid = '';

export function currentProjectContainerId(state: Uuid = initialState, action: Action): Uuid {
  switch (action.type) {
    case Shared_CurrentProjectContainerId_Updated: {
      return action.payload.currentProjectContainerId;
    }
    case ProjectApp_RouteLeft:
    case SubscriptionManagement_RouteLeft:
    case UserProfile_RouteLeft:
    case SmartLink_RouteLeft:
    case ProjectContainer_ProjectContainerContext_Left: {
      return initialState;
    }

    default:
      return state;
  }
}
