import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_Configuration_Loaded,
  WebSpotlight_SetStatus_Finished,
} from '../constants/webSpotlightActionTypes.ts';

export interface IWebSpotlightConfiguration {
  readonly isEnabled: boolean;
  readonly rootTypeId: Uuid | null;
}

const initialState: IWebSpotlightConfiguration | null = null;

export const configuration = (
  state = initialState,
  action: Action,
): IWebSpotlightConfiguration | null => {
  switch (action.type) {
    case WebSpotlight_Configuration_Loaded:
    case WebSpotlight_SetStatus_Finished:
      return action.payload.configuration;

    default:
      return state;
  }
};
