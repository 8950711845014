import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';
import { TimeInterval } from '../../constants/timeIntervals.ts';
import { WorkflowStage } from '../../constants/workflowStage.ts';
import { ContentPaceBody } from '../components/ContentPaceBody.tsx';
import {
  TimeSpentInWorkflowStepByWorkflow,
  getTimeSpentInWorkflowStepByWorkflowFromServerModel,
} from '../getTimeSpentInWorkflowStepByWorkflowFromServerModel.ts';
import { getContentPaceRequestModel } from '../utils/getContentPaceRequestModel.ts';

const { missionControlRepository } = repositoryCollection;

export type ContentPaceBodyContainerProps = Readonly<{
  contentTypeId: Uuid | null;
  onChartItemClick?: (
    averageTimeInSeconds: number,
    workflowId: Uuid,
    workflowStepId: Uuid,
    workflowStepName: string,
  ) => void;
  timeInterval: TimeInterval;
  workflowStage: WorkflowStage;
}>;

export const ContentPaceBodyLiveContainer: React.FC<ContentPaceBodyContainerProps> = ({
  contentTypeId,
  onChartItemClick,
  timeInterval,
  workflowStage,
}) => {
  const workflows = useSelector((s) => getWorkflowsSortedByName(s.data.workflows.byId));

  const [timeSpentInWorkflowStepByWorkflow, setTimeSpentInWorkflowStepByWorkflow] =
    useState<TimeSpentInWorkflowStepByWorkflow | null>(null);

  useEffect(() => {
    setTimeSpentInWorkflowStepByWorkflow(null);

    const { cancel } = makeCancellablePromise(async () => {
      const requestModel = getContentPaceRequestModel(timeInterval, workflowStage, contentTypeId);
      const contentPaceResponse = await missionControlRepository.getContentPace(requestModel);

      return getTimeSpentInWorkflowStepByWorkflowFromServerModel(
        contentPaceResponse.timeSpentInWorkflowStepByWorkflow,
      );
    })
      .then((value) => setTimeSpentInWorkflowStepByWorkflow(value))
      .catch(swallowCancelledPromiseError)
      .catch((error) => {
        logError(`${__filename}: Failed to fetch. `, error);
      });

    return cancel;
  }, [timeInterval, workflowStage, contentTypeId]);

  return (
    <ContentPaceBody
      onChartItemClick={onChartItemClick}
      timeSpentInWorkflowStepByWorkflow={timeSpentInWorkflowStepByWorkflow}
      workflows={workflows}
    />
  );
};

ContentPaceBodyLiveContainer.displayName = 'ContentPaceBodyLiveContainer';
