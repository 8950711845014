import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { ElementError, ElementErrorResult, emptyElementErrorResult } from './types/Errors.ts';

interface IParams {
  readonly value: DateTime;
}

const invalidElementResult: ElementErrorResult = {
  errors: [ElementError.InvalidDateTime],
};

export const getDateTimeElementErrors = ({ value }: IParams): ElementErrorResult =>
  value.isValid ? emptyElementErrorResult : invalidElementResult;
