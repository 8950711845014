import { PublishingPrivileges } from '../../../models/PublishingPrivileges.ts';

const defaultMessage = 'Do you want to make changes?';
type Messages = Record<PublishingPrivileges, string>;

export const getMessage = (
  privilege: PublishingPrivileges,
  numberOfProcessedItems: number,
): string => {
  const publishingPrivilegesToMessages: Messages = {
    [PublishingPrivileges.CancelScheduling]: defaultMessage,
    [PublishingPrivileges.CreateNewVersion]: defaultMessage,
    [PublishingPrivileges.None]: '',
    [PublishingPrivileges.Unpublish]:
      numberOfProcessedItems > 1
        ? 'Do you want to unpublish and archive these items?'
        : 'Do you want to unpublish and archive this item?',
    [PublishingPrivileges.ViewPublishedVersion]:
      'To create a new version, ask a colleague with enough permissions.',
    [PublishingPrivileges.ViewScheduledVersion]:
      'To remove publishing schedule, ask a colleague with enough permissions.',
  };

  return publishingPrivilegesToMessages[privilege];
};
