import { ContentItemEditingEventTypes } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { deactivateContentItemEditingAction } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import {
  createNewItemFromType,
  duplicateEditedContentItem,
} from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { trackContentItemEditingAction } from '../../ContentItemEditing/utils/editingActions/trackContentItemEditingAction.ts';
import { createDuplicateItemWithContentAction } from './duplicateItemWithContent.ts';
import { createDuplicateItemWithoutContentAction } from './duplicateItemWithoutContent.ts';

const trackContentItemEditingCreateNewAction = trackContentItemEditingAction(
  deactivateContentItemEditingAction(),
  ContentItemEditingEventTypes.CreateNew,
);

export const duplicateItemWithoutContent = createDuplicateItemWithoutContentAction({
  createNewItemFromType,
  trackContentItemEditingCreateNewAction,
});

export const duplicateItemWithContent = createDuplicateItemWithContentAction({
  duplicateEditedContentItem,
});
