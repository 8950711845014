import { IWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import {
  isArchivedWorkflowStep,
  isPublishedWorkflowStep,
} from '../../../utils/contentItemUtils.ts';
import { ScheduleMethod } from '../constants/scheduleMethods.ts';
import { ChangeWorkflowStepModalAction } from '../types/workflowStepModalActionTypes.ts';

export const isUpdatingWorkflowStepOrPublishing = (
  workflowStepAction: ChangeWorkflowStepModalAction | null,
): boolean => isUpdatingWorkflow(workflowStepAction) || isPublishing(workflowStepAction);

export const isUnpublishingOrRestoringFromArchivedStep = (
  workflowStepAction: ChangeWorkflowStepModalAction | null,
): boolean => isUnpublishing(workflowStepAction) || isRestoringFromArchivedStep(workflowStepAction);

export const isPublishing = (workflowStepAction: ChangeWorkflowStepModalAction | null): boolean =>
  workflowStepAction === ChangeWorkflowStepModalAction.Publish ||
  workflowStepAction === ChangeWorkflowStepModalAction.ScheduleToPublish;

export const isUnpublishing = (workflowStepAction: ChangeWorkflowStepModalAction | null): boolean =>
  workflowStepAction === ChangeWorkflowStepModalAction.UnpublishAndArchive ||
  workflowStepAction === ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive;

export const isScheduling = (workflowStepAction: ChangeWorkflowStepModalAction | null): boolean =>
  workflowStepAction === ChangeWorkflowStepModalAction.ScheduleToPublish ||
  workflowStepAction === ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive;

const isUpdatingWorkflow = (workflowStepAction: ChangeWorkflowStepModalAction | null): boolean =>
  workflowStepAction === ChangeWorkflowStepModalAction.UpdateStep ||
  workflowStepAction === ChangeWorkflowStepModalAction.MoveToArchivedStep;

export const isRestoringFromArchivedStep = (
  workflowStepAction: ChangeWorkflowStepModalAction | null,
): boolean => workflowStepAction === ChangeWorkflowStepModalAction.RestoreFromArchivedStep;

export const getWorkflowActionForUpdateStep = (
  selectedWorkflowStep: IWorkflowStep | undefined,
  scheduleMethod?: ScheduleMethod,
): ChangeWorkflowStepModalAction => {
  if (isArchivedWorkflowStep(selectedWorkflowStep)) {
    return ChangeWorkflowStepModalAction.MoveToArchivedStep;
  }

  if (!isPublishedWorkflowStep(selectedWorkflowStep)) {
    return ChangeWorkflowStepModalAction.UpdateStep;
  }

  if (scheduleMethod === ScheduleMethod.Schedule) {
    return ChangeWorkflowStepModalAction.ScheduleToPublish;
  }

  return ChangeWorkflowStepModalAction.Publish;
};
