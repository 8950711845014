import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadSnippets: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSnippetsUsage: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createLoadSnippetsDataAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadSnippets(abortSignal)),
      dispatch(deps.loadSnippetsUsage(abortSignal)),
    ]);
  };
