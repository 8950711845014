import { ChangeWorkflowStep, Restore, Unpublish } from '../../../../constants/itemActions.ts';
import { ChangeWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import {
  isRestoringFromArchivedStep,
  isUnpublishing,
} from '../../utils/changeWorkflowStepModalActionUtils.ts';

export const getWorkflowStepModalTitle = (
  workflowStepAction: ChangeWorkflowStepModalAction | null,
): string => {
  if (isUnpublishing(workflowStepAction)) {
    return Unpublish;
  }
  if (isRestoringFromArchivedStep(workflowStepAction)) {
    return Restore;
  }
  return ChangeWorkflowStep;
};
