import { areShallowEqual } from '@kontent-ai/utils';
import { areTheSame } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  ICollectionGroupRoles,
  IProjectContributorRole,
} from '../../../../data/models/users/ProjectContributor.ts';

const areCollectionIdsTheSame = (newIds: UuidArray, originalIds: UuidArray): boolean =>
  areTheSame(newIds, originalIds, (newId, originalId) => newId === originalId);

const areUserRolesTheSame = (
  newUserRoles: readonly IProjectContributorRole[],
  originalUserRoles: readonly IProjectContributorRole[],
): boolean =>
  areTheSame(
    newUserRoles,
    originalUserRoles,
    (newRole, originalRole) =>
      newRole.roleId === originalRole.roleId &&
      areShallowEqual(newRole.languageIds, originalRole.languageIds),
  );

export const areCollectionGroupsTheSame = (
  newCollectionGroups: readonly ICollectionGroupRoles[],
  originalCollectionGroups: readonly ICollectionGroupRoles[],
): boolean =>
  areTheSame(
    newCollectionGroups,
    originalCollectionGroups,
    (newGroup, originalGroup) =>
      areCollectionIdsTheSame(newGroup.collectionIds, originalGroup.collectionIds) &&
      areUserRolesTheSame(newGroup.roles, originalGroup.roles),
  );
