import { notUndefined } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import {
  WorkflowWithApplicableScopes,
  WorkflowsWithApplicableScopesByLimitations,
  doesWorkflowHaveLimitedScope,
  doesWorkflowHaveUnlimitedScope,
} from '../../../../_shared/utils/workflow/workflowLimitations.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { Workflow, WorkflowScope } from '../../../../data/models/workflow/Workflow.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';

const doesWorkflowHaveLimitedScopeForAllTypes = (workflow: WorkflowWithApplicableScopes): boolean =>
  workflow.applicableScopes.some(
    (scope) => scope.collections.size > 0 && scope.contentTypes.size === 0,
  );

const isUsableForCollection = (scope: WorkflowScope, collectionId: Uuid): boolean =>
  scope.collections.has(collectionId) || scope.collections.size === 0;

const toWorkflowWithApplicableScopesForCollection = (
  workflow: Workflow,
  collectionId: Uuid,
): WorkflowWithApplicableScopes => ({
  id: workflow.id,
  name: workflow.name,
  applicableScopes: workflow.scopes.filter((s) => isUsableForCollection(s, collectionId)),
});

export const splitCollectionApplicableWorkflows = (
  workflows: ReadonlyMap<Uuid, Workflow>,
  collectionId: Uuid,
): WorkflowsWithApplicableScopesByLimitations => {
  const sortedWorkflows = getWorkflowsSortedByName(workflows);

  const applicableWorkflows = sortedWorkflows
    .map((workflow) => toWorkflowWithApplicableScopesForCollection(workflow, collectionId))
    .filter(
      (workflow) => workflow.applicableScopes.length > 0 || workflow.id === DefaultWorkflowId,
    );

  const limitedWorkflows = applicableWorkflows.filter(doesWorkflowHaveLimitedScope);
  const unlimitedWorkflows = limitedWorkflows.some(doesWorkflowHaveLimitedScopeForAllTypes)
    ? []
    : applicableWorkflows.filter(doesWorkflowHaveUnlimitedScope);

  return { limitedWorkflows, unlimitedWorkflows };
};

export const getLimitedApplicableWorkflowsCount = (
  workflows: ReadonlyMap<Uuid, Workflow>,
  collectionId: Uuid,
): number => splitCollectionApplicableWorkflows(workflows, collectionId).limitedWorkflows.length;

export const getContentTypeNames = (
  workflow: WorkflowWithApplicableScopes,
  allContentTypes: Immutable.Map<Uuid, IContentType>,
): ReadonlySet<string> | null => {
  if (doesWorkflowHaveLimitedScopeForAllTypes(workflow)) {
    // An empty set means that the workflow is applicable to all content types
    return new Set();
  }

  const contentTypeNames = new Set(
    workflow.applicableScopes.flatMap((scope) =>
      [...scope.contentTypes]
        .map((contentTypeId) => allContentTypes.get(contentTypeId)?.name)
        .filter(notUndefined),
    ),
  );

  // If the set is empty, applicable contentTypes have been deleted and workflow is not applicable to any content type
  return contentTypeNames.size > 0 ? contentTypeNames : null;
};
