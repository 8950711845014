import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_BillingInformation_FastSpringConnected } from '../../../../applications/subscriptionManagement/BillingInformation/constants/actionTypes.ts';
import {
  Data_AdministratedSubscriptions_Success,
  Data_UserProjectsInfo_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  ISubscription,
  SubscriptionStatus,
  createSubscriptionFromServerModel,
} from '../../../models/subscriptions/Subscription.ts';

const initialState: Immutable.Map<Uuid, ISubscription> = Immutable.Map();

export function byId(
  state: Immutable.Map<Uuid, ISubscription> = initialState,
  action: Action,
): Immutable.Map<Uuid, ISubscription> {
  switch (action.type) {
    case Data_UserProjectsInfo_Success:
    case Data_AdministratedSubscriptions_Success: {
      const { subscriptions } = action.payload.data;
      return (subscriptions || []).reduce(
        (reduced, item) =>
          reduced.set(item.subscriptionId, createSubscriptionFromServerModel(item)),
        state,
      );
    }

    case Subscription_BillingInformation_FastSpringConnected: {
      const subscriptionId = action.payload.subscriptionId;
      const subscription = state.get(subscriptionId);
      return subscription
        ? state.set(subscriptionId, {
            ...subscription,
            isFsSubscriptionConnected: true,
            status: subscription.isActive ? SubscriptionStatus.Active : subscription.status,
          })
        : state;
    }

    default:
      return state;
  }
}
