import { ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import React, { useCallback } from 'react';
import { ScrollExecutedCallback } from '../../../../../_shared/hooks/useAutoScroll.ts';
import { ILinkedItemsTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { ILinkedItemsElement } from '../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { deferredScrollToLinkedItemElementTarget } from '../../../utils/customScrollTargetUtils.ts';
import { LinkedItemsComponent } from '../../ContentItemEditing/containers/elements/modularContent/LinkedItemsComponent.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

interface IModularContentElementProps
  extends IContentComponentItemElementOwnProps<ILinkedItemsElement, ILinkedItemsTypeElement> {}

export const ContentComponentModularContentElement: React.FC<IModularContentElementProps> = ({
  contentComponentId,
  disabled,
  elementData,
  onUpdate,
  typeElement,
  validationResultSelectorId,
}) => {
  const onValueChanged = useCallback(
    (value: ILinkedItemsElement): void => onUpdate(value),
    [onUpdate],
  );

  const onScrollToTarget = useCallback(
    (
      targetId: string,
      scrollOptions: ScrollElementsToViewOptions,
      onScrollExecuted: ScrollExecutedCallback,
    ) => {
      return deferredScrollToLinkedItemElementTarget(
        targetId,
        elementData.elementId,
        scrollOptions,
        contentComponentId,
        onScrollExecuted,
      );
    },
    [contentComponentId, elementData.elementId],
  );

  return (
    <ContentComponentItemElement
      contentComponentId={contentComponentId}
      disabled={disabled}
      onCustomScrollToTarget={onScrollToTarget}
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
    >
      <LinkedItemsComponent
        contentComponentId={contentComponentId}
        elementData={elementData}
        isDisabled={disabled}
        onValueChanged={onValueChanged}
        typeElement={typeElement}
        validationResultSelectorId={validationResultSelectorId}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentModularContentElement.displayName = 'ContentComponentModularContentElement';
