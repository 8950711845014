// Those constants are used as suffix in css class names as well
enum BaseTagColor {
  None = '',
  CoolGray = 'cool-gray',
  LightGray = 'light-gray',
  Product = 'product',
  ProductDisabled = 'product-disabled',
  Warning = 'warning',
}

export const TagColor = {
  Gray: 'gray',
  Red: 'red',
  Rose: 'rose',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  DarkBlue: 'dark-blue',
  LightBlue: 'light-blue',
  SkyBlue: 'sky-blue',
  MintGreen: 'mint-green',
  PersianGreen: 'persian-green',
  DarkGreen: 'dark-green',
  LightGreen: 'light-green',
  Yellow: 'yellow',
  Pink: 'pink',
  Orange: 'orange',
  Brown: 'brown',
  ...BaseTagColor,
} as const;

type TagColorValue = (typeof TagColor)[keyof typeof TagColor];

export type TagColor = BaseTagColor | TagColorValue;
