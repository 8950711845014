import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { getContentTypeConversionOptions } from '../../../../applications/contentModels/shared/selectors/contentTypeElementSelector.ts';
import { IContentTypeRepository } from '../../../../repositories/interfaces/IContentTypeRepository.type.ts';
import { ContentTypeServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  Data_ContentTypes_Failed,
  Data_ContentTypes_Started,
  Data_ContentTypes_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IContentType,
  getContentTypeFromServerModel,
} from '../../../models/contentModelsApp/contentTypes/ContentType.ts';

interface IDeps {
  readonly contentTypeRepository: Pick<IContentTypeRepository, 'getContentTypes'>;
}

const start = () => ({ type: Data_ContentTypes_Started }) as const;
const success = (data: IContentType[]) =>
  ({
    type: Data_ContentTypes_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_ContentTypes_Failed }) as const;

export type LoadContentTypesActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadContentTypesAction =
  ({ contentTypeRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(start());

    try {
      const contentTypesServerModel = await contentTypeRepository.getContentTypes({}, abortSignal);
      const compiledContentTypes = contentTypesServerModel.data.map(
        (item: ContentTypeServerModel) =>
          getContentTypeFromServerModel(item, getContentTypeConversionOptions(getState())),
      );
      dispatch(success(compiledContentTypes));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
