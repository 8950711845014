import { connect } from 'react-redux';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  ISitemapSectionProps,
  SitemapSection as SitemapSectionComponent,
} from '../../components/details/SitemapSection.tsx';

const mapStateToProps = ({ data: { sitemap } }: IStore): ISitemapSectionProps => ({
  isHidden: sitemap.loadingStatus !== LoadingStatus.Loaded || !sitemap.data.nodes.size,
});

export const SitemapSection = connect(mapStateToProps)(SitemapSectionComponent);
