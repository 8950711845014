import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MissingPermissionToCreateWebSpotlightRootItemMessage } from '../constants/spacesUiConstants.ts';

type Props = {
  readonly canUserCreateContent: boolean;
  readonly onOpenCreateNewItemDialog: () => void;
  readonly onOpenLinkExistingItemDialog: () => void;
};

export const RootItemEmptyState: React.FC<Props> = ({
  canUserCreateContent,
  onOpenCreateNewItemDialog,
  onOpenLinkExistingItemDialog,
}) => {
  if (!canUserCreateContent) {
    return (
      <Box typography={Typography.Subheadline}>
        {MissingPermissionToCreateWebSpotlightRootItemMessage}
      </Box>
    );
  }

  return (
    <Row spacingX={Spacing.S}>
      <Column width="content">
        <Button
          buttonStyle="tertiary"
          onClick={onOpenCreateNewItemDialog}
          size="small"
          {...getDataUiActionAttribute(DataUiAction.CreateNewRootItem)}
        >
          Create new root item
        </Button>
      </Column>
      <Column width="content">
        <Button
          buttonStyle="tertiary"
          onClick={onOpenLinkExistingItemDialog}
          size="small"
          {...getDataUiActionAttribute(DataUiAction.AddRootItem)}
        >
          Add existing root item
        </Button>
      </Column>
    </Row>
  );
};

RootItemEmptyState.displayName = 'RootItemEmptyState';
