import React from 'react';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { PreviewLinksForm as PreviewLinksFormComponent } from '../../../components/editingActions/leafActionForms/PreviewLinksForm.tsx';
import { selectPreviewLinks } from '../../../containers/selectors/selectPreviewLinks.ts';

export const PreviewLinksForm: React.FC<LeafActionFormProps> = (props) => {
  const dispatch = useDispatch();
  const previewLinks = useSelector(selectPreviewLinks);

  const onPreviewOpened = (spaceId: Uuid | null) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.SpacePreviewOpened, { 'space-id': spaceId ?? '' }),
    );
  };

  return (
    <PreviewLinksFormComponent
      previewLinks={previewLinks}
      onPreviewOpened={onPreviewOpened}
      {...props}
    />
  );
};

PreviewLinksForm.displayName = 'PreviewLinksForm';
