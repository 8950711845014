import { memoize } from '@kontent-ai/memoization';
import { ICommentThread } from '../../models/comments/CommentThreads.ts';
import { IAssetItemElement } from '../../models/contentItemElements/AssetItemElement.ts';
import {
  EmptyThreadReferences,
  ICommentThreadWithLocation,
  isThreadInline,
} from '../commentUtils.ts';

export const getAssetElementCommentThreadReferences = memoize.allForever(
  (
    element: IAssetItemElement,
    contentComponentId: Uuid | null,
    commentThreads: ReadonlyArray<ICommentThread>,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    if (commentThreads.length === 0) {
      return EmptyThreadReferences;
    }

    const elementThreads = commentThreads
      .filter(isThreadInline)
      .filter(
        (commentThread) =>
          commentThread.elementId === element.elementId &&
          commentThread.contentComponentId === contentComponentId,
      );

    const threadReferences = element.value.toArray().flatMap((assetReference) =>
      elementThreads
        .filter((commentThread) => commentThread.externalSegmentId === assetReference.id)
        .map((commentThread) => ({
          commentThread,
          componentPath: null,
        })),
    );

    return threadReferences;
  },
);
