import { createGuid } from '@kontent-ai/utils';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { initializeRequestTokenFactory } from '../../../_shared/utils/requestTokenUtils.ts';
import {
  loadCollections,
  loadContentItemsAssignedToCurrentUser,
  loadContentItemsRecentlyEditedByCurrentUser,
  loadContentTypes,
  loadLanguages,
  loadListingContentItemsByIds,
  loadSpaces,
  loadWorkflows,
} from '../../../data/actions/thunkDataActions.ts';
import { createInitContentItemsAssignedToYouAction } from './thunks/initContentItemsAssignedToYouAction.ts';
import { createInitContentRecentlyEditedByYouAction } from './thunks/initContentRecentlyEditedByYou.ts';
import {
  createInitYourTasks,
  createTokenizedInitYourTasksStarted,
} from './thunks/initYourTasks.ts';
import { createLoadRelatedItems } from './thunks/loadRelatedItems.ts';
import { createOrderAssignedContentItemsAction } from './thunks/orderAssignedContentItems.ts';

const { taskRepository } = repositoryCollection;

const yourTasksRequestTokenFactory = initializeRequestTokenFactory(
  (state) => state.yourContentApp.yourTasks.cancellationToken,
  createTokenizedInitYourTasksStarted,
  createGuid,
);

export const initAssignedContentItemsToMe = createInitContentItemsAssignedToYouAction({
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadContentItemsAssignedToCurrentUser,
  loadSpaces,
  loadWorkflows,
});

export const orderAssignedContentItems = createOrderAssignedContentItemsAction({
  loadContentItemsAssignedToCurrentUser,
});

export const initContentRecentlyEditedByYou = createInitContentRecentlyEditedByYouAction({
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadContentItemsRecentlyEditedByCurrentUser,
  loadSpaces,
  loadWorkflows,
});

export const loadRelatedItems = createLoadRelatedItems({
  loadListingContentItemsByIds,
});

export const initYourTasks = createInitYourTasks({
  loadRelatedItems,
  taskRepository,
  requestTokenFactory: yourTasksRequestTokenFactory,
});
