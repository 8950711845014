import { OperationStatus } from '../../../_shared/models/OperationStatus.ts';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  ServerErrorStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../_shared/models/StatusMessage.ts';

export const getMessageFromOperationStatus = (operationStatus: OperationStatus): StatusMessage => {
  switch (operationStatus) {
    case OperationStatus.Pending: {
      return SavingStatusMessage;
    }
    case OperationStatus.Failed: {
      return ServerErrorStatusMessage;
    }
    case OperationStatus.Initialized: {
      return UnsavedStatusMessage;
    }
    default: {
      return SavedStatusMessage;
    }
  }
};
