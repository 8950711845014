import { DropDownCaptionOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownCaptionOption.tsx';
import { DropDownOptionsGroup } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  FilterActionsMenuLeafNames,
  isFilterActionsMenuLeafNamesKey,
} from '../../constants/filterActionsMenuConstants.ts';
import { FilterAction } from '../../constants/listingFilterConstants.ts';

type Props = {
  readonly activeAction: FilterAction;
  readonly onBackClick: () => void;
  readonly renderLeafForm: () => JSX.Element;
};

export const FilterActionsLeafMenu = (props: Props) => {
  return (
    <>
      <DropDownOptionsGroup>
        <DropDownCaptionOption
          name={
            isFilterActionsMenuLeafNamesKey(props.activeAction)
              ? FilterActionsMenuLeafNames[props.activeAction]
              : ''
          }
          onClick={props.onBackClick}
        />
      </DropDownOptionsGroup>
      <DropDownOptionsGroup>
        <div className="dropdown-item">{props.renderLeafForm()}</div>
      </DropDownOptionsGroup>
    </>
  );
};
