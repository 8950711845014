import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { trimStart } from '../../../../_shared/utils/textUtils.ts';

export const getDaysAndHoursFromHours = (totalHours: number): string => {
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  const daysText = days !== 0 ? pluralizeWithCount('day', days) : '';
  const hoursText = days === 0 || hours !== 0 ? ` ${pluralizeWithCount('hour', hours)}` : '';

  return `~${trimStart(daysText + hoursText)}`;
};
