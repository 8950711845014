import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_InitStarted,
  Relations_SetDefaultRoot_Failed,
  Relations_SetDefaultRoot_Finished,
  Relations_SetDefaultRoot_Started,
  Relations_SetRoot_Started,
} from '../constants/relationsActionTypes.ts';

const initialState: boolean = false;

export function isDefaultRootBeingChanged(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_SetDefaultRoot_Started:
      return true;

    case Relations_InitStarted:
    case Relations_SetRoot_Started:
    case Relations_SetDefaultRoot_Finished:
    case Relations_SetDefaultRoot_Failed:
      return false;

    default:
      return state;
  }
}
