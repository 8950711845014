import { alphabetically } from '@kontent-ai/utils';
import { isDefined } from '../../../_shared/utils/filter/isDefined.ts';
import { logError } from '../../../_shared/utils/logError.ts';
import { IRole } from '../../../data/models/roles/IRole.ts';
import { ICollectionGroupRoles } from '../../../data/models/users/ProjectContributor.ts';

export const getUserRolesTitle = (
  collectionGroups: ReadonlyArray<ICollectionGroupRoles>,
  allRoles: ReadonlyMap<Uuid, IRole>,
): string => {
  const roleNames = collectionGroups
    .flatMap((g: ICollectionGroupRoles) => g.roles)
    .map(({ roleId }) => {
      const role = allRoles.get(roleId);

      if (!role) {
        logError(`Role with id ${roleId} was not loaded.`);
      }

      return role?.name.trim();
    })
    .filter(isDefined);

  return getUniqueSortedRoleNamesTitle(roleNames).join(', ');
};

export const getUniqueSortedRoleNamesTitle = (
  roleNames: ReadonlyArray<string>,
): ReadonlyArray<string> => Array.from(new Set(roleNames)).sort(alphabetically);
