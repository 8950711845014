import classNames from 'classnames';
import React, { useRef } from 'react';
import { getDataAttributeProps } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type DefaultSkinSidebarProps = {
  readonly sidebarContentClassNames?: string;
};

export const DefaultSkinSidebar: React.FC<React.PropsWithChildren<DefaultSkinSidebarProps>> = (
  props,
) => {
  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames('canvas__sidebar--default-skin')}>
      <div
        ref={parentRef}
        className={classNames('canvas__sidebar-content', props.sidebarContentClassNames)}
        {...getDataAttributeProps(props)}
      >
        {props.children}
      </div>
    </div>
  );
};

DefaultSkinSidebar.displayName = 'DefaultSkinSidebar';
