import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  Taxonomy_Groups_Archived,
  Taxonomy_Groups_LoadingStarted,
  Taxonomy_Groups_RestorationInfoFaded,
  Taxonomy_Groups_Restored,
  Taxonomy_Groups_SelectionChanged,
} from '../../constants/taxonomyActionTypes.ts';

const initialState: Immutable.Set<Uuid> = Immutable.Set();

export const deletedTaxonomyGroupIds = (
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> => {
  switch (action.type) {
    case Taxonomy_Groups_LoadingStarted:
    case Taxonomy_Groups_SelectionChanged:
    case Taxonomy_Groups_Restored:
    case Taxonomy_Groups_RestorationInfoFaded:
      return initialState;

    case Taxonomy_Groups_Archived:
      return action.payload.groupIds;

    default:
      return state;
  }
};
