import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { ApiLimitationsPlugin } from '../ApiLimitationsPlugin.tsx';
import { EditorFeatureLimitations } from './EditorFeatureLimitations.ts';

export const createEditorLimitationsApi = (
  limitations: EditorFeatureLimitations,
): EditorApiImplementation<ApiLimitationsPlugin> => ({
  getLimitations() {
    return limitations;
  },
});
