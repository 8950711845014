import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ILatestProductUpdateInfoServerModel } from '../../../../repositories/serverModels/ILatestProductUpdateInfoServerModel.type.ts';
import {
  Data_LatestProductUpdate_Failed,
  Data_LatestProductUpdate_Started,
  Data_LatestProductUpdate_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly productUpdateRepository: {
    readonly getLatest: (abortSignal?: AbortSignal) => Promise<ILatestProductUpdateInfoServerModel>;
  };
}

const start = () => ({ type: Data_LatestProductUpdate_Started }) as const;

const success = (data: ILatestProductUpdateInfoServerModel) =>
  ({
    type: Data_LatestProductUpdate_Success,
    payload: { data },
  }) as const;

const failure = () => ({ type: Data_LatestProductUpdate_Failed }) as const;

export type LoadLatestProductUpdateInfoActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadLatestProductUpdateAction =
  ({ productUpdateRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const latestProductInfo = await productUpdateRepository.getLatest(abortSignal);
      dispatch(success(latestProductInfo));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
