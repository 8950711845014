import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

export const isRevisionCurrent = (
  entryTimeline: Immutable.List<TimelineItem> | null,
  revision: TimelineItem | null,
): boolean => {
  return !!entryTimeline && !!revision && entryTimeline.first()?.itemId === revision.itemId;
};

const isSelectedTimelineItem = (item: TimelineItem, clickedItemId: Uuid): boolean =>
  item.itemId === clickedItemId;

const hasRevisionId = (item: TimelineItem): boolean => !!item.revisionId;

export const getSelectedItemOrFallbackWithRevisionId = (
  timeline: Immutable.List<TimelineItem> | null,
  timelineItemId: Uuid,
): TimelineItem => {
  const firstTimelineItem = timeline?.first();
  if (!timeline || !firstTimelineItem) {
    throw InvariantException('Timeline must not be empty and include at least the current item');
  }

  const firstPrecedingTimelineItemWithRevisionId = () =>
    timeline
      .skipUntil((item: TimelineItem) => isSelectedTimelineItem(item, timelineItemId))
      .skipUntil((item: TimelineItem) => hasRevisionId(item))
      .first();

  const firstFollowingTimelineItemWithRevisionId = () =>
    timeline
      .reverse()
      .skipUntil((item: TimelineItem) => isSelectedTimelineItem(item, timelineItemId))
      .skipUntil((item: TimelineItem) => hasRevisionId(item))
      .first();

  return (
    firstPrecedingTimelineItemWithRevisionId() ||
    firstFollowingTimelineItemWithRevisionId() ||
    firstTimelineItem
  );
};

export const shouldDisplayDiffForElement = (typeElement: TypeElement, variantId: Uuid): boolean =>
  variantId === DefaultVariantId || !typeElement.isNonLocalizable;

export const getUserForRevisionElement = (
  elementId: Uuid,
  variantId: Uuid,
  typeElement: TypeElement,
  elementsChangedBy: ReadonlyMap<Uuid, UserId> | undefined,
): UserId | undefined => {
  if (!shouldDisplayDiffForElement(typeElement, variantId)) {
    return undefined;
  }

  return elementsChangedBy?.get(elementId);
};
