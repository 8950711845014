import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { IAssetTypeElementData } from '../models/elements/AssetTypeElementData.ts';
import { IContentTypeSnippetTypeElementData } from '../models/elements/ContentTypeSnippetTypeElementData.ts';
import { ICustomTypeElementData } from '../models/elements/CustomTypeElementData.tsx';
import { IGuidelinesTypeElementData } from '../models/elements/GuidelinesTypeElementData.ts';
import { ILinkedItemsTypeElementData } from '../models/elements/LinkedItemsTypeElementData.ts';
import { IMultipleChoiceTypeElementData } from '../models/elements/MultipleChoiceTypeElementData.ts';
import { IRichTextTypeElementData } from '../models/elements/RichTextTypeElementData.ts';
import { ITaxonomyTypeElementData } from '../models/elements/TaxonomyTypeElementData.ts';
import { ITextTypeElementData } from '../models/elements/TextTypeElementData.ts';
import { IUrlSlugTypeElementData } from '../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';

export const isAssetTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is IAssetTypeElementData => arg?.type === ElementType.Asset;

export const isCustomTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is ICustomTypeElementData => arg?.type === ElementType.Custom;

export const isSnippetTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is IContentTypeSnippetTypeElementData => arg?.type === ElementType.ContentTypeSnippet;

export const isUrlSlugTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is IUrlSlugTypeElementData => arg?.type === ElementType.UrlSlug;

export const isTextTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is ITextTypeElementData => arg?.type === ElementType.Text;

export const isRichTextTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is IRichTextTypeElementData => arg?.type === ElementType.RichText;

export const isSubpagesTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is ILinkedItemsTypeElementData => arg?.type === ElementType.Subpages;

export const isLinkedItemsTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is ILinkedItemsTypeElementData => arg?.type === ElementType.LinkedItems;

export const isTypeElementWithLinkedItems = (
  arg: IBaseTypeElementData | undefined | null,
): arg is ILinkedItemsTypeElementData =>
  isLinkedItemsTypeElement(arg) || isSubpagesTypeElement(arg);

export const isGuidelinesTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is IGuidelinesTypeElementData => arg?.type === ElementType.Guidelines;

export const isMultipleChoiceTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is IMultipleChoiceTypeElementData => arg?.type === ElementType.MultipleChoice;

export const isTaxonomyTypeElement = (
  arg: IBaseTypeElementData | undefined | null,
): arg is ITaxonomyTypeElementData => arg?.type === ElementType.Taxonomy;
