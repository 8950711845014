import React from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { renderDatetimeUtcString } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { IBillingPeriodReport } from '../models/BillingPeriodReport.ts';

export interface ICurrentUsageReportUpdateMessage {
  readonly report: IBillingPeriodReport;
}

export const CurrentUsageReportUpdateMessage: React.FC<ICurrentUsageReportUpdateMessage> = ({
  report,
}) => (
  <QuickTip hideTitle subtitle="Daily usage reports">
    <p>
      This report captures your usage as it was on{' '}
      <strong>{renderDatetimeUtcString(report.periodEnd)}</strong>. We generate new usage reports
      every 24 hours. Any recent changes to your subscription plan, current usage, or extras will be
      reflected in the next report.
    </p>
  </QuickTip>
);

CurrentUsageReportUpdateMessage.displayName = 'CurrentUsageReportUpdateMessage';
