import { Icon, Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { WorkflowStatus } from '../../../../../../component-library/components/WorkflowStatus/WorkflowStatus.tsx';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { valueTrend } from '../../../constants/valueTrends.ts';

const StateToWorkflowStepColor: ReadonlyRecord<valueTrend, WorkflowStepColor> = {
  [valueTrend.Positive]: WorkflowStepColor.PersianGreen,
  [valueTrend.Neutral]: WorkflowStepColor.Gray,
  [valueTrend.Negative]: WorkflowStepColor.Red,
};

const StateToIcon: ReadonlyRecord<valueTrend, Icon> = {
  [valueTrend.Positive]: Icons.CaretUp,
  [valueTrend.Neutral]: Icons.Minus,
  [valueTrend.Negative]: Icons.CaretDown,
};

export const WidgetWorkflowStep: React.FC<
  Readonly<{
    percentage: number;
    state: valueTrend;
  }>
> = ({ percentage, state }) => (
  <WorkflowStatus
    primary={{
      background: renderWorkflowStepColor(StateToWorkflowStepColor[state]),
      icon: StateToIcon[state],
      name: `${Math.abs(percentage).toFixed(2)}%`,
    }}
  />
);
