import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { EnterHotkeyDisabler } from '../../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { LanguageEditorDeactivateButton } from './LanguageEditorDeactivateButton.tsx';
import { LanguageEditorPrimaryButton } from './LanguageEditorPrimaryButton.tsx';

interface ILanguageEditorBarItemActionButtonsProps {
  readonly isLanguageActive: boolean;
  readonly isLanguageLimitReached: boolean;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
  readonly onActivate: (() => void) | null;
  readonly onDeactivate: (() => void) | null;
  readonly onDismissLongProcessingChangesWarning: () => void;
  readonly isCodenameOrFallbackModified: boolean;
  readonly showLargeProjectSaveWarning: boolean;
  readonly isSetAsFallbackLanguage: boolean;
  readonly setWarningMessage: (message: string | undefined) => void;
  readonly isLanguageAlreadyUsed: boolean;
  readonly areLanguageRolesEnabled: boolean;
  readonly language: ILanguage;
  readonly isEditedLanguageNew: boolean;
}

export const LanguageEditorBarItemActionButtons: React.FC<
  ILanguageEditorBarItemActionButtonsProps
> = ({
  isLanguageActive,
  isLanguageLimitReached,
  onCancel,
  onSubmit,
  onActivate,
  onDeactivate,
  onDismissLongProcessingChangesWarning,
  isCodenameOrFallbackModified,
  showLargeProjectSaveWarning,
  isSetAsFallbackLanguage,
  setWarningMessage,
  isLanguageAlreadyUsed,
  areLanguageRolesEnabled,
  language,
  isEditedLanguageNew,
}) => {
  return (
    <div className="bar-item__expansion-actions-bar">
      <Row spacing={Spacing.S}>
        <Column>
          {isLanguageActive && onDeactivate && !isEditedLanguageNew && (
            <LanguageEditorDeactivateButton
              onDeactivate={onDeactivate}
              isSetAsFallbackLanguage={isSetAsFallbackLanguage}
              setWarningMessage={setWarningMessage}
              isLanguageAlreadyUsed={isLanguageAlreadyUsed}
              showLargeProjectSaveWarning={showLargeProjectSaveWarning}
              onCancel={onCancel}
              onDismissLongProcessingChangesWarning={onDismissLongProcessingChangesWarning}
              areLanguageRolesEnabled={areLanguageRolesEnabled}
              language={language}
            />
          )}
        </Column>
        <Column width="fit-content">
          <Inline spacing={Spacing.S}>
            <EnterHotkeyDisabler>
              <Button
                buttonStyle="secondary"
                onClick={onCancel}
                size="small"
                tooltipPlacement="bottom-end"
                tooltipShortcuts={ShortcutSymbols.Escape}
                tooltipText="Cancel"
                type="button"
                {...getDataUiActionAttribute(DataUiAction.Cancel)}
              >
                Cancel
              </Button>
            </EnterHotkeyDisabler>
            <LanguageEditorPrimaryButton
              isLanguageActive={isLanguageActive}
              isLanguageLimitReached={isLanguageLimitReached}
              onActivate={onActivate}
              onSubmit={onSubmit}
              onCancel={onCancel}
              onDismissLongProcessingChangesWarning={onDismissLongProcessingChangesWarning}
              showLargeProjectSaveWarning={showLargeProjectSaveWarning}
              isCodenameOrFallbackModified={isCodenameOrFallbackModified}
            />
          </Inline>
        </Column>
      </Row>
    </div>
  );
};

LanguageEditorBarItemActionButtons.displayName = 'LanguageEditorBarItemActionButtons';
