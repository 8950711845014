import { ContentBlock, ContentState, EntityInstance } from 'draft-js';
import {
  containsMatchingEntities,
  removeMatchingEntities,
} from '../../plugins/entityApi/api/editorEntityUtils.ts';
import { isUploadingImage } from '../../plugins/images/api/editorImageUtils.ts';
import { isNewLink } from '../../plugins/links/api/LinkEntity.ts';
import { isUploadingLink } from '../../plugins/links/api/editorLinkUtils.ts';
import { isNewMention } from '../../plugins/mentions/api/MentionEntity.ts';
import { isNewBlockPlaceholder } from '../blocks/blockTypeUtils.ts';
import { createSelection } from '../editorSelectionUtils.ts';
import { getBlocks } from './editorContentGetters.ts';
import { removeCustomBlocks } from './editorContentUtils.ts';

const isNonPersistentBlock = (block: ContentBlock): boolean =>
  isNewBlockPlaceholder(block) || isUploadingImage(block);

export const removeNonPersistentBlocks = (content: ContentState): ContentState | null =>
  removeCustomBlocks(content, isNonPersistentBlock);

const isNonPersistentEntity = (entity: EntityInstance): boolean =>
  isNewLink(entity) || isUploadingLink(entity) || isNewMention(entity);

export const containsNonPersistentEntities = (content: ContentState): boolean =>
  containsMatchingEntities(content, isNonPersistentEntity);

export function removeNonPersistentEntities(content: ContentState): ContentState {
  const input = {
    content,
    selection: createSelection(content.getFirstBlock().getKey()),
  };
  const withRemoved = removeMatchingEntities(input, isNonPersistentEntity);
  if (!withRemoved.wasModified) {
    return content;
  }

  return withRemoved.content;
}

export function containsNonPersistentContent(content: ContentState): boolean {
  return getBlocks(content).some(isNonPersistentBlock) || containsNonPersistentEntities(content);
}
