import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import { trackUserEventWithData } from '../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../../../../_shared/models/Task.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { TasksEventActions } from '../../../../../../../../_shared/models/events/TaskEventData.type.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { addTask } from '../../../../actions/thunkContentItemEditingActions.ts';
import {
  CollapsedTaskListMaxItemCount,
  TasksList,
} from '../../../../components/details/Tasks/TasksList.tsx';

const getCollapsedTaskList = memoize.maxOne((tasks: readonly Task[]): readonly Task[] =>
  tasks.slice(0, CollapsedTaskListMaxItemCount),
);

const getExpandedTaskList = (state: IStore): readonly Task[] => {
  return state.contentApp.editorUi.tasks.orderedTaskIds
    .map((taskId: Uuid) => state.data.tasks.byId.get(taskId) ?? null)
    .filter(notNull);
};

export const NonClickableTasksSection = () => {
  const dispatch = useDispatch();
  const onAddTaskClick = () =>
    dispatch(
      addTask(ContentItemEditingEventOrigins.ItemDetails, ModalDialogType.ContentItemAddTaskDialog),
    );
  const onShowMoreClick = () =>
    dispatch(
      trackUserEventWithData(TrackedEvent.Tasks, {
        action: TasksEventActions.TaskListExpanded,
      }),
    );
  const expandedTasksList = useSelector(getExpandedTaskList);
  const collapsedTasksList = getCollapsedTaskList(expandedTasksList);
  const highlightedTaskIds = useSelector(
    (state) => state.contentApp.editorUi.tasks.highlightedTaskIds,
  );

  return (
    <>
      <Box paddingX={Spacing.XXL} display="inline" cursor="default">
        <Button
          buttonStyle="tertiary"
          size="small"
          onClick={onAddTaskClick}
          {...getDataUiActionAttribute(DataUiAction.AddTask)}
        >
          <Button.Icon icon={Icons.Plus} />
          Add task
        </Button>
      </Box>
      <TasksList
        collapsedTasksList={collapsedTasksList}
        expandedTasksList={expandedTasksList}
        highlightedTaskIds={highlightedTaskIds}
        onShowMoreClick={onShowMoreClick}
      />
    </>
  );
};
