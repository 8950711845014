import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  spacingMainLayoutBottom,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ProjectSettingsWithMenuLayoutProps = {
  readonly renderContent: () => ReactNode;
  readonly renderSettingsMenu: () => ReactNode;
};

const StyledRow = styled(Row)`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const ScrollableColumn = styled(Column)`
  overflow-x: hidden;
  overflow-y: auto;
`;

const LeftMenuScrollableColumn = styled(ScrollableColumn)`
  flex-shrink: 0;
  display: flex;
`;

export const ProjectSettingsWithMenuLayout: React.FC<ProjectSettingsWithMenuLayoutProps> = ({
  renderContent,
  renderSettingsMenu,
}) => (
  <>
    <HtmlPageTitle appName={AppNames.ProjectSettings} />
    <Box
      flexGrow={1}
      minHeight={0}
      display="flex"
      className="canvas"
      {...getDataUiAppNameAttribute(DataUiAppName.ProjectSettings)}
    >
      <StyledRow spacingX={Spacing.L} noWrap>
        <LeftMenuScrollableColumn width="fit-content">
          {renderSettingsMenu()}
        </LeftMenuScrollableColumn>
        <ScrollableColumn>
          <Box
            marginLeft={spacingMainLayoutLeft}
            marginTop={spacingMainLayoutTop}
            marginRight={spacingMainLayoutRight}
            marginBottom={spacingMainLayoutBottom}
          >
            {renderContent()}
          </Box>
        </ScrollableColumn>
      </StyledRow>
    </Box>
  </>
);

ProjectSettingsWithMenuLayout.displayName = 'ProjectSettingsWithMenuLayout';
