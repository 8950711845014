import React, { useCallback } from 'react';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ColumnSettingsSource } from '../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import {
  loadListingContentItemsForInventory,
  orderContentItems,
} from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTableHead } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/ContentItemScrollTableHead.tsx';
import { getContentItemListingOrderBy } from '../../../../contentInventory/content/reducers/listingUi/selectors/getContentItemListingOrderBy.ts';
import { getContentItemListingHiddenColumns } from '../../../../contentInventory/content/selectors/getContentItemListingHiddenColumns.ts';
import { variantSpecificColumns } from '../../constants/variantSpecificColumns.ts';

type Props = {
  readonly hideVariantSpecificInfo?: boolean;
};

export const MultipleContentItemsSelectorScrollTableHead: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const contentItemListingOrderBy = useSelector(getContentItemListingOrderBy);
  const hiddenColumns = useSelector(getContentItemListingHiddenColumns);

  const onColumnHeadClicked = useCallback((columnCode: ItemColumnCode) => {
    dispatch(orderContentItems(columnCode, false));
    dispatch(loadListingContentItemsForInventory());
  }, []);

  return (
    <ContentItemScrollTableHead
      contentListingOrderBy={contentItemListingOrderBy}
      hiddenColumns={props.hideVariantSpecificInfo ? variantSpecificColumns : hiddenColumns}
      hideColumnSettingsButton={props.hideVariantSpecificInfo}
      onColumnHeadClicked={onColumnHeadClicked}
      renderCheckboxPlaceholder
      renderOpenItemInNewTab
      source={ColumnSettingsSource.ModalItemSelector}
    />
  );
};
