import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { ICommentThread } from '../models/comments/CommentThreads.ts';

export const getFocusedCommentThread = (state: IStore): ICommentThread | null => {
  const { editedContentItemVariantComments } = state.contentApp;

  const focusedThread =
    editedContentItemVariantComments.commentThreads.find(
      (thread: ICommentThread) =>
        thread.id === editedContentItemVariantComments.focusedCommentThreadId,
    ) || null;

  return focusedThread;
};
