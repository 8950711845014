import { ISavedFilter } from '../../../../../../data/models/filters/ISavedFilter.ts';
import { IContentItemStatus } from '../../../models/filter/IContentItemStatus.ts';
import { SearchScope } from '../../../models/filter/IListingFilter.ts';
import { IPublishingState } from '../../../models/filter/IPublishingState.ts';
import {
  ListingFilter_CollectionFilterChanged,
  ListingFilter_ContentItemStatusChanged,
  ListingFilter_ContentTypeFilterChanged,
  ListingFilter_ContributorsFilterChanged,
  ListingFilter_HideRestoreButton,
  ListingFilter_PublishingStatusFilterChanged,
  ListingFilter_SavedFilterSelected,
  ListingFilter_SearchScopeChanged,
  ListingFilter_SitemapFilterChanged,
  ListingFilter_SpaceFilterChanged,
  ListingFilter_TaxonomyGroupsFilterChanged,
  ListingFilter_WorkflowStatusesFilterChanged,
} from '../constants/listingFilterActionTypes.ts';

export const onSpaceFilterChanged = (selectedSpaces: ReadonlySet<Uuid>) =>
  ({
    type: ListingFilter_SpaceFilterChanged,
    payload: {
      selectedSpaces,
    },
  }) as const;

export const onCollectionFilterChanged = (selectedCollections: ReadonlySet<Uuid>) =>
  ({
    type: ListingFilter_CollectionFilterChanged,
    payload: {
      selectedCollections,
    },
  }) as const;

export const onContentTypeFilterChanged = (selectedContentTypes: ReadonlySet<Uuid>) =>
  ({
    type: ListingFilter_ContentTypeFilterChanged,
    payload: {
      selectedContentTypes,
    },
  }) as const;

export const onWorkflowStatusFilterChanged = (
  workflowToSelectedStepIds: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
  workflowToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
) =>
  ({
    type: ListingFilter_WorkflowStatusesFilterChanged,
    payload: {
      workflowToSelectedStepIds,
      workflowToAllStepIds,
    },
  }) as const;

export const onSitemapFilterChanged = (selectedSitemapNodes: ReadonlySet<Uuid>) =>
  ({
    type: ListingFilter_SitemapFilterChanged,
    payload: {
      selectedSitemapNodes,
    },
  }) as const;

export const onContributorsFilterChanged = (selectedContributors: ReadonlySet<Uuid>) =>
  ({
    type: ListingFilter_ContributorsFilterChanged,
    payload: {
      selectedContributors,
    },
  }) as const;

export const onTaxonomyGroupsFilterChanged = (
  selectedTaxonomyTerms: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
) => {
  return {
    type: ListingFilter_TaxonomyGroupsFilterChanged,
    payload: {
      selectedTaxonomyTerms,
    },
  } as const;
};

export const onContentItemStatusFilterChanged = (selectedContentItemStatus: IContentItemStatus) =>
  ({
    type: ListingFilter_ContentItemStatusChanged,
    payload: {
      selectedContentItemStatus,
    },
  }) as const;

export const onPublishingStatusFilterChanged = (selectedPublishingStatuses: IPublishingState) =>
  ({
    type: ListingFilter_PublishingStatusFilterChanged,
    payload: {
      selectedPublishingStatuses,
    },
  }) as const;

export const onSearchScopeChanged = (scope: ReadonlySet<SearchScope>) =>
  ({
    type: ListingFilter_SearchScopeChanged,
    payload: {
      scope,
    },
  }) as const;

export const onSelectSavedFilter = (
  filter: ISavedFilter,
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined,
) =>
  ({
    type: ListingFilter_SavedFilterSelected,
    payload: {
      filter,
      forcedContentTypeIds,
    },
  }) as const;

export const onHideRestoreButton = () =>
  ({
    type: ListingFilter_HideRestoreButton,
  }) as const;
