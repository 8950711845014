import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getAssetDescriptionBySelectedLanguage } from '../../../../../_shared/selectors/AssetTile/getAssetDescription.ts';
import { areCollectionsVisibleForAssets } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCollectionName } from '../../../../../_shared/utils/assets/assetUtils.ts';
import { Asset, EmptyAsset } from '../../../../../data/models/assets/Asset.ts';
import { isAssetUncategorized } from '../../../../contentInventory/assets/utils/assetTaxonomyUtils.ts';
import { AssetViewerTile as AssetViewerTileComponent } from '../components/AssetViewerTile.tsx';

type Props = {
  readonly assetId: Uuid;
  readonly className?: string;
  readonly isViewOnly?: boolean;
  readonly renditionId?: Uuid;
};

export const AssetViewerTile: React.FC<Props> = ({
  assetId,
  className,
  isViewOnly,
  renditionId,
}) => {
  const asset = useSelector((s) => s.data.assets.byId.get(assetId) ?? EmptyAsset);
  const collectionName = useSelector((s) =>
    areCollectionsVisibleForAssets(s, Collection.getValues(s.data.collections.byId))
      ? getCollectionName(asset.collectionId, s.data.collections.byId)
      : null,
  );

  const canViewAsset = Asset.hasCapability(asset, ActiveCapabilityType.ViewAssets);

  const altText = useSelector((s) =>
    getAssetDescriptionBySelectedLanguage(asset.descriptions, getSelectedLanguageIdOrThrow(s)),
  );
  const isUncategorized = useSelector((s) =>
    isAssetUncategorized(asset, s.data.assetTypes.defaultAssetType, s.data.taxonomyGroups.byId),
  );
  const rendition = useSelector((s) => s.data.assetRenditions.byId.get(renditionId ?? ''));

  return (
    <AssetViewerTileComponent
      altText={altText}
      asset={asset}
      canViewAsset={canViewAsset}
      className={className}
      collectionName={collectionName}
      isUncategorized={isUncategorized}
      isViewOnly={!!isViewOnly}
      rendition={rendition}
    />
  );
};

AssetViewerTile.displayName = 'AssetViewerTile';
