import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { template } from '../../../../_shared/utils/stringUtils.ts';

export const SelectAtLeastOneLimitationCategory =
  'Allow at least one type of input (text, image, table or components and items).';
export const SelectAtLeastOneTableTextBlock =
  'Allow at least one text block in tables (paragraph, heading 1, heading 2, ...).';
export const SelectAtLeastOneTextBlock =
  'Allow at least one text block (paragraph, heading 1, heading 2, ...).';
export const ConditionTriggerNotSet =
  'Select an element to set visibility condition or disable the condition.';
export const DefaultValueDoesNotMeetLimitations =
  'The default value doesn’t meet the element’s limitations.';
export const DeletedAssetsInDefaultValue =
  'Some of the selected assets have been deleted. Select other assets for the element’s default value to continue.';
export const DeletedAssetsInGuidelines =
  'Some assets used in the guidelines have been deleted. Use other assets to continue.';
export const DeletedLinkedItemsInDefaultValue =
  'Some of the selected linked items have been deleted. Select other items for the element’s default value to continue.';
export const DeletedTaxonomyTermsInDefaultValue =
  'Some of the selected terms have been deleted (highlighted in red). Select other terms for the element’s default value to continue.';
export const FeatureUnavailableInCurrentPlan =
  'This feature is not available in the current subscription plan. Delete the element so that you can save the changes made to your content type.';
export const NotAllRequiredFieldsAreFilledIn =
  'Content type cannot be saved. Correct the highlighted elements first.';
export const NotAllRequiredFieldsInAssetTypeAreFilledIn =
  'Asset type cannot be saved. Correct the highlighted elements first.';
export const NotAllRequiredFieldsInSnippetAreFilledIn =
  'Content type snippet cannot be saved. Correct the highlighted elements first.';
export const ProvideANonNegativeNumberForLimitLength =
  'Provide a non-negative number to limit length.';
export const ProvideAPositiveNumberForLimit = template`Provide a positive number${0}.`;
export const ProvideAPositiveNumberForLimitLength = 'Provide a positive number to limit length.';
export const ProvideAbsoluteUrl =
  'Provide a URL address that uses a secure version of the HTTP protocol.';
export const ProvideRegexWithValidLengthLimit = `Provide a regex pattern that fits within ${ValidationConstants.RegexPatternMaxLength} characters.`;
export const ProvideValidImageSizeLimit =
  'Provide a positive number with a maximum of two decimal places for the image size limit.';
export const ProvideValidJsonObject = 'Provide a valid JSON object.';
export const ProvideValidRegexPattern = 'Provide a valid regex pattern.';
export const ProvideValidSizeLimit =
  'Provide a positive number with a maximum of two decimal places for the file size limit.';
export const ProvideValidationMessageWithValidLengthLimit = `Provide a validation message that fits within ${ValidationConstants.RegexCustomValidationMessageMaxLength} characters.`;
export const TaxonomyTermEditorIsNotConfirmed =
  'Cannot save taxonomy group. Confirm changes to the taxonomy term first.';
export const UseSecureHTTP = 'Start your URL with “https://”.';
export const WorkflowStepEditorIsNotConfirmed =
  'Cannot save project workflow. Confirm changes to the workflow step first.';
