import {
  ApiKeyDetail_Alert_Dismissed,
  ApiKeyDetail_SavingInterrupted_InvalidForm,
  ApiKeyListing_Route_Left,
  ApiKeysSettings_Route_Left,
} from '../constants/apiKeysActionTypes.ts';

export const apiKeysListingLeft = () =>
  ({
    type: ApiKeyListing_Route_Left,
  }) as const;

export const apiKeysSettingsLeft = () =>
  ({
    type: ApiKeysSettings_Route_Left,
  }) as const;

export const apiKeyDetailsAlertDismissed = () =>
  ({
    type: ApiKeyDetail_Alert_Dismissed,
    payload: {},
  }) as const;

export const apiKeyFormSavingInterrupted = () =>
  ({
    type: ApiKeyDetail_SavingInterrupted_InvalidForm,
  }) as const;
