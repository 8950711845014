import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import React from 'react';
import { AiErrorCode } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import {
  GenericTranslateError,
  UnableToTranslateDescriptionError,
} from '../../../../utils/imageDescriptionAiErrors.ts';

interface IProps {
  readonly dismissError: () => void;
  readonly errorCode: AiErrorCode;
  readonly targetLanguageName: string;
}

export const TranslateWithAiError: React.FC<IProps> = ({
  dismissError,
  errorCode,
  targetLanguageName,
}) => (
  <NotificationBarAlert onDismiss={dismissError}>
    {errorCode === AiErrorCode.Unable
      ? UnableToTranslateDescriptionError(targetLanguageName)
      : GenericTranslateError}
  </NotificationBarAlert>
);
