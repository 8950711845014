import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ContentState, EditorState } from 'draft-js';
import { forwardRef, useMemo, useState } from 'react';
import { ModalViewer } from '../../../../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { ActionMenuPositioner } from '../../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createCopyToClipboardAiAction,
  createDiscardAiActionFromResult,
  createEditInputsAiAction,
  createRejectSuggestionAiSection,
  createReplaceSelectionAiAction,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { AiResult } from '../../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { getAiErrorMessage } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import {
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { ModalContentItemSelector } from '../../../../../../features/ModalContentItemSelector/containers/ModalContentItemSelector.tsx';
import { RichAiSuggestionViewer } from '../../../containers/viewers/RichAiSuggestionViewer.tsx';
import { matchWritingStyleErrorMessageByErrorCode } from '../../../utils/matchWritingStyleOfItemErrors.ts';

const feedbackReasons = createFeedbackReasons([
  SpecificFeedbackReason.VoiceAndToneWrong,
  SpecificFeedbackReason.ContentChangedTooMuch,
  SpecificFeedbackReason.ContentTooSimilar,
  SpecificFeedbackReason.ResultSlow,
  SpecificFeedbackReason.ContentChangedMeaning,
]);

type Props = {
  readonly onClosePropsDialog: () => void;
  readonly onCopyToClipboard?: () => void;
  readonly onDiscard: () => void;
  readonly onInputsEdited: () => void;
  readonly onReplaceSelection?: () => void;
  readonly onSubmit: (contentItemId: Uuid) => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
  readonly result: ActionResult<ContentState>;
  readonly resultWidth: number;
};

export const MatchWritingStyleOfItemAction = forwardRef<HTMLElement, Props>(
  (
    {
      onClosePropsDialog,
      onCopyToClipboard,
      onDiscard,
      onInputsEdited,
      onReplaceSelection,
      onSubmit,
      onTryAgain,
      preferMenuOnTop,
      result,
      resultWidth,
    },
    ref,
  ) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const changeReferenceItemAction = useMemo(
      () =>
        result.isFinished
          ? () => {
              setIsDialogOpen(true);
              onInputsEdited();
            }
          : undefined,
      [result.isFinished, onInputsEdited],
    );

    const menuOptions = [
      createUseSuggestionAiSection(
        [
          createReplaceSelectionAiAction(onReplaceSelection),
          createCopyToClipboardAiAction(onCopyToClipboard),
        ],
        result,
      ),
      createRejectSuggestionAiSection([
        createEditInputsAiAction('Change referenced item', changeReferenceItemAction),
        createTryAgainAiAction(result, onTryAgain),
        createDiscardAiActionFromResult(onDiscard, result),
      ]),
    ];

    const resultEditorState = useMemo(
      () => (result.content ? EditorState.createWithContent(result.content) : null),
      [result.content],
    );
    const hasResult = !!result.error || !!resultEditorState;

    const handleSubmit = (contentItemId: Uuid) => {
      setIsDialogOpen(false);
      onSubmit(contentItemId);
    };

    const handleClose = (): void => {
      setIsDialogOpen(false);
      onClosePropsDialog();
    };

    return (
      <>
        <ModalViewer
          dialogClassName="dialog"
          isDialogVisible={isDialogOpen}
          position={ModalViewerPosition.Center}
          onClose={handleClose}
        >
          <ModalContentItemSelector
            getItemDisabledTooltipMessage={(item: IListingContentItem) =>
              item.variant ? undefined : 'Translate this item if you want to use it as a reference.'
            }
            titleBarText="Select a content item as a reference"
            onClose={handleClose}
            onSelect={handleSubmit}
          />
        </ModalViewer>
        {!isDialogOpen && (
          <ActionMenuPositioner
            preferMenuOnTop={preferMenuOnTop}
            ref={ref}
            renderMenu={() => <AiActionMenu options={menuOptions} />}
            renderResult={(resultRef) => (
              <AiResult
                ref={resultRef}
                actionName={AiActionName.MatchWritingStyleOfItem}
                actionTitle="Match voice and tone to content"
                feedbackReasons={feedbackReasons}
                isLoading={!result.isFinished}
                renderResult={() =>
                  hasResult && (
                    <Stack spacing={Spacing.L}>
                      {result.error && (
                        <Callout
                          calloutType="warning"
                          {...getDataUiElementAttribute(DataUiElement.CalloutWarning)}
                        >
                          {getAiErrorMessage(
                            result.error,
                            matchWritingStyleErrorMessageByErrorCode,
                          )}
                        </Callout>
                      )}
                      {resultEditorState && (
                        <RichAiSuggestionViewer editorState={resultEditorState} />
                      )}
                    </Stack>
                  )
                }
                width={resultWidth}
              />
            )}
          />
        )}
      </>
    );
  },
);

MatchWritingStyleOfItemAction.displayName = 'MatchWritingStyleOfItemAction';
