import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IWebhookServerModel } from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  Data_Webhooks_Failed,
  Data_Webhooks_Started,
  Data_Webhooks_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly webhookRepository: {
    readonly getAll: (abortSignal?: AbortSignal) => Promise<ReadonlyArray<IWebhookServerModel>>;
  };
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

const start = () => ({ type: Data_Webhooks_Started }) as const;
const success = (data: ReadonlyArray<IWebhookServerModel>) =>
  ({
    type: Data_Webhooks_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_Webhooks_Failed }) as const;

export type LoadWebhooksActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadWebhooksAction =
  ({ webhookRepository, loadWorkflows }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const webhooksServerModel = await webhookRepository.getAll(abortSignal);
      await dispatch(loadWorkflows(abortSignal));
      dispatch(success(webhooksServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
