import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Callout, CalloutType } from './Callout.tsx';

type QuickTipProps = {
  readonly className?: string;
  readonly hideTitle?: boolean;
  readonly subtitle?: string;
  readonly uiElement?: DataUiElement;
};

export const QuickTip: React.FC<React.PropsWithChildren<QuickTipProps>> = ({
  children,
  className,
  hideTitle,
  subtitle,
  uiElement,
}) => (
  <Callout
    className={className}
    iconName={IconName.ICircle}
    subtitle={subtitle}
    title={hideTitle ? undefined : 'Quick tip'}
    type={CalloutType.Info}
    uiElement={uiElement}
  >
    {children}
  </Callout>
);

QuickTip.displayName = 'QuickTip';
