import { NotificationBarFriendlyWarning } from '@kontent-ai/component-library/NotificationBar';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { identity } from '@kontent-ai/utils';
import React, { memo, forwardRef } from 'react';
import { DialogState } from '../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { useGradualSequence } from '../../../../../_shared/hooks/useGradualSequence.ts';
import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { ScrollTableHeadColumn } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import { SimpleScrollTable } from '../../../../../_shared/uiComponents/ScrollTable/SimpleScrollTable.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  DataUiWorkflowAction,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { LazyChildRow } from '../containers/ChildRow.tsx';
import { ParentItemDefaultVariantRow } from '../containers/ParentItemDefaultVariantRow.tsx';
import { ParentItemRow } from '../containers/ParentItemRow.tsx';
import { ResponsiveCascadeCellsGroup } from './ResponsiveCascadeCellsGroup.tsx';

type ModalBodyProps = {
  readonly firstLevelChildIds: ReadonlyArray<MemoizedContentItemId>;
  readonly statusInfoMessage: IStatusInfoMessage;
};

const ModalBody: React.FC<ModalBodyProps> = (props) => (
  <div className="modal-dialog__scroll-table-wrapper">
    <SimpleScrollTable
      collectionName={DataUiCollection.ContentItems}
      fillAvailableSpace
      noShadow
      renderTitle={() => (
        <div
          css={`
            width: 100%;
            display: flex;
            gap: ${px(Spacing.S)};
            justify-content: space-between;
          `}
        >
          <ListingMessage statusInfoMessage={props.statusInfoMessage} />
          <NotificationBarFriendlyWarning>
            Leaving linked items or pages unpublished might lead to broken links or break your app.
          </NotificationBarFriendlyWarning>
        </div>
      )}
      renderHead={(showScrollBar: boolean) => (
        <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
          <ResponsiveCascadeCellsGroup>
            <ScrollTableHeadColumn name="" size={1} />
            <ScrollTableHeadColumn name="" size={1} />
            <ScrollTableHeadColumn
              name={translateColumnCodeToTitle(ItemColumnCode.Name)}
              size={10}
            />
            <ScrollTableHeadColumn name="" size={1} />
          </ResponsiveCascadeCellsGroup>
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
            size={4}
            isGrowing
          />
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
            size={4}
            isGrowing
          />
          {showScrollBar && <div className="scroll-table__head-scroll" />}
        </ScrollTableHeadRow>
      )}
    >
      <ParentItemRow />
      <ParentItemDefaultVariantRow />
      <ChildItems childContentItemIds={props.firstLevelChildIds} />
    </SimpleScrollTable>
  </div>
);

ModalBody.displayName = 'ModalBody';

type ChildItemsProps = {
  readonly childContentItemIds: ReadonlyArray<MemoizedContentItemId>;
};

const ChildItems: React.FC<ChildItemsProps> = memo(({ childContentItemIds }) => {
  // When we are dealing with more than 1000 items, mount them in chunks to avoid browser getting stuck on too many new components
  const { renderItems } = useGradualSequence(childContentItemIds, identity, { chunkSize: 1000 });
  const hideOutsideViewport = childContentItemIds.length > 10;

  return (
    <>
      {renderItems.map((contentItemId) => (
        <LazyChildRow
          key={stringifyContentItemId(contentItemId)}
          contentItemId={contentItemId}
          depth={1}
          hideOutsideViewport={hideOutsideViewport}
        />
      ))}
    </>
  );
});

ChildItems.displayName = 'ChildItems';

interface ICascadeModalProps extends ModalBodyProps {
  readonly isModalContentLoaded: boolean;
  readonly onClose: () => void;
}

export type CascadeModalOwnProps = {
  readonly modalTitle: string;
  readonly primaryActionAttrs: {
    readonly tooltipText: string | undefined;
    readonly callToAction: string;
    readonly onClick: () => void;
  };
  readonly statusInfoMessage: IStatusInfoMessage;
};

type Props = IAnimatedModalDialogProps & ICascadeModalProps & CascadeModalOwnProps;

export const CascadeModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      isModalContentLoaded,
      primaryActionAttrs,
      modalTitle,
      firstLevelChildIds,
      statusInfoMessage,
      onClose,
      isOpen,
    },
    ref,
  ) => {
    const { tooltipText, onClick, callToAction: text } = primaryActionAttrs;

    return (
      <FullScreenModalDialog
        state={isModalContentLoaded ? undefined : DialogState.InProgress}
        onClose={onClose}
        cancelAction={{
          onClick: onClose,
          ...getDataUiActionAttribute(DataUiAction.CloseDialog),
        }}
        headline={modalTitle}
        isDismissable
        isOpen={isOpen}
        primaryAction={{
          text,
          tooltipText,
          onClick,
          disabled: !isModalContentLoaded,
          ...getDataUiActionAttribute(DataUiWorkflowAction.Publish),
        }}
        {...getDataUiElementAttribute(DataUiElement.CascadePublishDialog)}
        {...{ maxWidth: px(140 * gridUnit) }}
        ref={ref}
      >
        {isModalContentLoaded ? (
          <ModalBody
            firstLevelChildIds={firstLevelChildIds}
            statusInfoMessage={statusInfoMessage}
          />
        ) : (
          <Loader />
        )}
      </FullScreenModalDialog>
    );
  },
);

CascadeModal.displayName = 'CascadeModal';
