import { Button } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  DisabledCreationButtonLimitReachedTooltipText,
  DisabledCreationButtonTooltipText,
} from '../constants/spacesUiConstants.ts';

type Props = {
  readonly isCreatingEnabled: boolean;
  readonly isPlanLimitReached: boolean;
  readonly planLimit: number | null;
  readonly listingMessage: ReactNode;
  readonly onOpenCreateForm: () => void;
};

export const SpacesHeader: React.FC<Props> = ({
  isCreatingEnabled,
  isPlanLimitReached,
  listingMessage,
  onOpenCreateForm,
  planLimit,
}) => {
  const disabledCreatingTooltip = isCreatingEnabled
    ? undefined
    : isPlanLimitReached
      ? DisabledCreationButtonLimitReachedTooltipText(planLimit)
      : DisabledCreationButtonTooltipText;

  return (
    <Row alignY="center" spacing={Spacing.L}>
      <Column>{listingMessage}</Column>
      <Column width="content">
        <Button
          disabled={!isCreatingEnabled}
          buttonStyle="primary"
          size="medium"
          onClick={isCreatingEnabled ? onOpenCreateForm : undefined}
          tooltipText={disabledCreatingTooltip}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create new space
        </Button>
      </Column>
    </Row>
  );
};

SpacesHeader.displayName = 'SpacesHeader';
