import { Action } from '../../../../../@types/Action.type.ts';
import {
  ApiKeysSettings_Init_Finished,
  ApiKeysSettings_Route_Left,
} from '../../constants/apiKeysActionTypes.ts';

export const hasAnyDapiKeys = (state = false, action: Action): boolean => {
  switch (action.type) {
    case ApiKeysSettings_Init_Finished:
      return action.payload.hasAnyDapiKeys;

    case ApiKeysSettings_Route_Left:
      return false;

    default:
      return state;
  }
};
