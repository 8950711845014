import { Collection } from '@kontent-ai/utils';
import { uncategorizedAssetFilterTaxonomyTermId } from '../../../applications/contentInventory/assets/constants/taxonomyConstants.ts';
import { IAssetLibraryQuery } from '../../../applications/contentInventory/assets/models/IAssetLibraryQuery.ts';
import { isTaxonomyElement } from '../../../applications/itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { doesEntitySatisfyFilterPhrase } from './nameFilterUtils.ts';

export const doesAssetMatchFilterQuery = (query: IAssetLibraryQuery, asset: IAsset): boolean => {
  const { searchPhrase, selectedCollections, selectedTaxonomyNodes } = query;

  return (
    (!searchPhrase || doesAssetSatisfyNameFilter(searchPhrase, asset)) &&
    (!selectedCollections.length ||
      doesAssetSatisfyCollectionsFilter(asset, selectedCollections)) &&
    (!selectedTaxonomyNodes.size || doesAssetSatisfyTaxonomyFilter(asset, selectedTaxonomyNodes))
  );
};

const doesAssetSatisfyNameFilter = (searchPhrase: string, asset: IAsset): boolean =>
  doesEntitySatisfyFilterPhrase(searchPhrase, asset, [
    (a) => a.title ?? '',
    (a) => a.filename,
    (a) => a.descriptions.join(' '),
  ]);

const doesAssetSatisfyCollectionsFilter = (
  asset: IAsset,
  selectedCollections: ReadonlyArray<Uuid | null>,
): boolean => selectedCollections.some((id) => asset.collectionId === id);

const doesAssetSatisfyTaxonomyFilter = (
  asset: IAsset,
  selectedTaxonomyNodes: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
): boolean => {
  return Collection.getEntries(selectedTaxonomyNodes).every(([groupId, termIds]) => {
    const elementsWithMatchingGroupId = asset.nonLocalizableElements
      .filter(isTaxonomyElement)
      .filter((element) => element.groupId === groupId);

    const isMatchingUncategorized =
      termIds.has(uncategorizedAssetFilterTaxonomyTermId) &&
      elementsWithMatchingGroupId.every((element) => !element.value.size);

    const isMatchingSpecificTerm = elementsWithMatchingGroupId.some(
      (element) => Collection.intersect([...element.value], termIds).length > 0,
    );

    return isMatchingUncategorized || isMatchingSpecificTerm;
  });
};
