import React from 'react';
import { Box } from '../../../../layout/Box/Box.tsx';
import { colorPrimaryHoverInverse } from '../../../../tokens/decision/colors.ts';
import { BaseColor } from '../../../../tokens/quarks/colors.ts';
import { transition250 } from '../../../../tokens/quarks/transitions.ts';
import { RadioGroupState } from '../../types.ts';
import { mainCircleSize, protectiveZone } from '../tokens.ts';
import { getRadioButtonIconColors } from '../utils/getRadioButtonIconColors.ts';
import { getSvgMainCircleFillColor } from '../utils/getSvgMainCircleFillColor.ts';
import { getSvgMainCircleStrokeColor } from '../utils/getSvgMainCircleStrokeColor.ts';

export type RadioButtonIconProps = {
  readonly isDisabled: boolean;
  readonly isFocusVisible: boolean;
  readonly isIconHovered: boolean;
  readonly isRadioButtonHovered: boolean;
  readonly isSelected: boolean;
  readonly radioButtonState: RadioGroupState;
};

export const RadioButtonIcon: React.FC<RadioButtonIconProps> = ({
  isDisabled,
  isFocusVisible,
  isSelected,
  isIconHovered,
  isRadioButtonHovered,
  radioButtonState,
  ...otherProps
}) => {
  const {
    backgroundColor,
    fillColor,
    fillColorHover,
    selectedStrokeColor,
    strokeColor,
    selectedStrokeColorHover,
  } = getRadioButtonIconColors(radioButtonState);
  const mainCircleStrokeColor = getSvgMainCircleStrokeColor(
    isDisabled,
    isSelected,
    isRadioButtonHovered,
    selectedStrokeColor,
    strokeColor,
    selectedStrokeColorHover,
  );

  const selectedFillColor = isRadioButtonHovered && !isDisabled ? fillColorHover : fillColor;
  const svgSize = mainCircleSize;
  const svgRadius = svgSize / 2;
  const mainCircleRadius = mainCircleSize / 2;
  const smallCircleRadius = mainCircleRadius / 2;
  const strokeWidth = 1;

  return (
    <Box component="span" display="block" padding={protectiveZone} {...otherProps}>
      <Box
        component="svg"
        aria-hidden="true"
        display="block"
        height={svgSize}
        width={svgSize}
        css="overflow: visible;"
      >
        <circle
          cy={svgRadius}
          cx={svgRadius}
          fill={getSvgMainCircleFillColor(
            isDisabled,
            isIconHovered,
            isRadioButtonHovered,
            colorPrimaryHoverInverse,
            backgroundColor,
          )}
          r={mainCircleRadius - strokeWidth * 0.5}
          stroke={mainCircleStrokeColor}
          strokeWidth={strokeWidth}
          css={`transition: fill ${transition250}, stroke ${transition250};`}
        />
        <circle
          cy={svgRadius}
          cx={svgRadius}
          fill={isSelected ? selectedFillColor : BaseColor.Transparent}
          r={smallCircleRadius}
          stroke="none"
          strokeWidth={0}
          css={`transition: fill ${transition250};`}
        />
      </Box>
    </Box>
  );
};

RadioButtonIcon.displayName = 'RadioButtonIcon';
