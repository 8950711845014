import { notNull } from '@kontent-ai/utils';
import { detect } from 'detect-browser';
import { useEffect } from 'react';

/**
 * Introduced due to https://kontent-ai.atlassian.net/browse/KCL-10590
 * We need a way to detect Safari reliably.
 * Since Safari since version 16.4 does not match the selector @supports not(counter-set:none)
 * nor @supports not(-moz-appearance:none) it sabotages our effort to make counters work in it.
 * Because even though it claims to support counter-set, it actually does not. This is a problem
 * with more CSS properties. Hence, this hook was introduced and labels the <body> according
 * to the browser.
 *
 * READ BEFORE DELETING THIS!!!
 * Some other fixes may use the activation classes. Make sure to scour the code for them.
 * If written correctly, they should match this Regex: /body\.\w+.*\{/
 */

const browser = detect();

const makeIntoClassName = (str: string | null | undefined): string | null => {
  return str?.toLowerCase().replaceAll(/\s/g, '-').replaceAll('.', '_') ?? null;
};

const applyActivationClasses = () => {
  const nameClass = makeIntoClassName(browser?.name);
  const versionClass = makeIntoClassName(browser?.version);
  const osClass = makeIntoClassName(browser?.os);

  const classesToAdd = [nameClass, versionClass, osClass].filter(notNull);

  document.body.classList.add(...classesToAdd);

  return () => {
    document.body.classList.remove(...classesToAdd);
  };
};

export const useBrowserActivationClasses = () => {
  useEffect(applyActivationClasses, []);
};
