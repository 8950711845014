import deepEqual from 'deep-equal';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EditorState,
  EntityInstance,
} from 'draft-js';
import Immutable from 'immutable';
import { GetItemEqualityCheckerType } from '../../../itemEditor/utils/itemElementsEqualityCheckers/types/IEqualityCheckerDependencies.type.ts';
import { getEntityType } from '../../plugins/entityApi/api/Entity.ts';
import { NextBlockType, PreviousBlockType } from '../blocks/blockData.ts';
import { getFullBlockType } from '../blocks/editorBlockGetters.ts';
import { getBlocks } from '../general/editorContentGetters.ts';

function areStylesTheSame(
  styles1: Immutable.OrderedSet<string>,
  styles2: Immutable.OrderedSet<string>,
): boolean {
  if (styles1 === styles2) {
    return true;
  }

  return styles1.size === styles2.size && styles1.intersect(styles2).size === styles1.size;
}

function areEntityInstancesTheSame(entity1: EntityInstance, entity2: EntityInstance) {
  if (entity1 === entity2) {
    return true;
  }

  if (getEntityType(entity1) !== getEntityType(entity2)) {
    return false;
  }

  if (entity1.getMutability() !== entity2.getMutability()) {
    return false;
  }

  return deepEqual(entity1.getData(), entity2.getData());
}

function areEntitiesTheSame(
  entityKey1: string | null,
  entityKey2: string | null,
  content1: ContentState,
  content2: ContentState,
): boolean {
  // We can do this simplified comparison because entities are globally pooled
  // https://github.com/facebook/draft-js/blob/0.10-stable/src/model/entity/DraftEntity.js

  // This may however not be true for eternity, as they seem to have some plans with it, if there is a problem in newer version, check if pooling stayed
  // https://github.com/facebook/draft-js/blob/0.10-stable/src/model/immutable/ContentState.js#L176
  if (entityKey1 === entityKey2) {
    return true;
  }

  if (!entityKey1 || !entityKey2) {
    return false;
  }

  try {
    const entity1 = content1.getEntity(entityKey1);
    const entity2 = content2.getEntity(entityKey2);
    return areEntityInstancesTheSame(entity1, entity2);
  } catch {
    // Entity with requested key is missing in ContentState
    return false;
  }
}

function areCharsTheSame(
  ch1: CharacterMetadata,
  ch2: CharacterMetadata,
  content1: ContentState,
  content2: ContentState,
): boolean {
  if (!areEntitiesTheSame(ch1.getEntity() || null, ch2.getEntity() || null, content1, content2)) {
    return false;
  }

  return areStylesTheSame(ch1.getStyle(), ch2.getStyle());
}

function areBlockDataTheSame(block1: ContentBlock, block2: ContentBlock): boolean {
  if (block1.getData() === block2.getData()) {
    return true;
  }

  const excludedProperties = {
    [NextBlockType]: null,
    [PreviousBlockType]: null,
  };

  const blockData1 = {
    ...block1.getData().toJS(),
    ...excludedProperties,
  };

  const blockData2 = {
    ...block2.getData().toJS(),
    ...excludedProperties,
  };

  return deepEqual(blockData1, blockData2);
}

function areBlocksTheSame(
  block1: ContentBlock,
  block2: ContentBlock,
  contentState1: ContentState,
  contentState2: ContentState,
): boolean {
  if ((!block1 && !block2) || block1 === block2) {
    return true;
  }
  if (!block1 || !block2) {
    return false;
  }

  if (getFullBlockType(block1) !== getFullBlockType(block2)) {
    return false;
  }

  if (block1.getDepth() !== block2.getDepth()) {
    return false;
  }

  if (block1.getText() !== block2.getText()) {
    return false;
  }

  if (!areBlockDataTheSame(block1, block2)) {
    return false;
  }

  const chars1 = block1.getCharacterList();
  const chars2 = block2.getCharacterList();
  if (chars1 === chars2) {
    return true;
  }

  const charsAreTheSame = chars1.every((char1: CharacterMetadata, charIndex: number) => {
    const char2 = chars2.get(charIndex);
    if (!char2) {
      return false;
    }
    return areCharsTheSame(char1, char2, contentState1, contentState2);
  });
  return charsAreTheSame;
}

export function areContentsTheSame(content1: ContentState, content2: ContentState): boolean {
  if (content1 === content2) {
    return true;
  }

  const blocks1 = getBlocks(content1);
  const blocks2 = getBlocks(content2);

  if (blocks1.length !== blocks2.length) {
    return false;
  }

  const blocksAreTheSame = blocks1.every((block1: ContentBlock, blockIndex: number): boolean => {
    const block2 = blocks2[blockIndex];
    return !!block2 && areBlocksTheSame(block1, block2, content1, content2);
  });
  return blocksAreTheSame;
}

export type IAreTextEditorStatesTheSame = (
  editorState1: EditorState,
  editorState2: EditorState,
  getItemElementEqualityChecker?: GetItemEqualityCheckerType,
) => boolean;

export const areTextEditorStatesTheSame: IAreTextEditorStatesTheSame = (
  editorState1: EditorState,
  editorState2: EditorState,
): boolean =>
  (!editorState1 && !editorState2) ||
  areContentsTheSame(editorState1.getCurrentContent(), editorState2.getCurrentContent());
