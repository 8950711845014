import { Action } from '../../../../../../@types/Action.type.ts';
import { CollectionSection } from '../../constants/collectionSection.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_SelectedCollectionChanged,
} from '../../constants/newContentItemActionTypes.ts';

const initialState = CollectionSection.None;

export const collectionSection = (state = initialState, action: Action): CollectionSection => {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.collectionSection;

    case NewContentItem_SelectedCollectionChanged:
      return action.payload.collectionSection;

    default:
      return state;
  }
};
