import React, { useEffect } from 'react';
import { Redirect, RedirectProps, Route, Switch } from 'react-router';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  AdministratorsRoute,
  BillingHistoryRoute,
  BillingInformationRoute,
  EnsuredSubscriptionRoute,
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
  ProjectsInSubscriptionRoute,
  SubscriptionApiKeysRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
  SubscriptionGeneralRoute,
  SubscriptionOverviewRoute,
  SubscriptionRoute,
  SubscriptionUsersRoute,
  SubscriptionsRoute,
  TransactionsRoute,
} from '../../../_shared/constants/routePaths.ts';
import { IRouteContext, RouteContext } from '../../../_shared/containers/routing/RouteContext.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { SubscriptionAdminsManagement } from '../Admins/containers/SubscriptionAdminsManagement.tsx';
import { SubscriptionApiKeyTile } from '../ApiKeys/containers/SubscriptionApiKeyTile.tsx';
import { BillingHistory } from '../BillingHistory/containers/BillingHistory.tsx';
import { BillingInformation } from '../BillingInformation/containers/BillingInformation.tsx';
import { CurrentUsage } from '../CurrentUsage/containers/CurrentUsage.tsx';
import { SubscriptionGeneral } from '../General/components/SubscriptionGeneral.tsx';
import { PlanSelection } from '../PlanSelection/containers/PlanSelection.tsx';
import { SubscriptionUsers } from '../SubscriptionUsers/containers/SubscriptionUsers.tsx';
import { Transactions } from '../Transactions/containers/Transactions.tsx';
import {
  subscriptionManagementRouteEntered,
  subscriptionManagementRouteLeft,
} from './actions/subscriptionManagementActions.ts';
import { RedirectIfNoSubscriptionsAvailable } from './components/RedirectIfNoSubscriptionsAvailable.tsx';
import { SubscriptionEdit } from './components/SubscriptionEdit.tsx';
import { SubscriptionManagement } from './components/SubscriptionManagement.tsx';
import { EnforceServiceAgreement } from './containers/EnforceServiceAgreement.tsx';
import { EnsureSelectedSubscription } from './containers/EnsureSelectedSubscription.tsx';
import { EnsureSubscriptionCurrentProject } from './containers/EnsureSubscriptionCurrentProject.tsx';
import { EnvironmentSettings } from './containers/EnvironmentSettings/EnvironmentSettings.tsx';
import { SubscriptionProjects } from './containers/SubscriptionProjects/SubscriptionProjects.tsx';

const SubscriptionsApp: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subscriptionManagementRouteEntered());

    return () => {
      dispatch(subscriptionManagementRouteLeft());
    };
  }, []);

  return <>{children}</>;
};

export const getSubscriptionManagementRoutes = (
  getRedirectPropsWithSameSearch: (redirectProps: RedirectProps) => RedirectProps,
) => {
  return (
    <Route path={SubscriptionsRoute}>
      <SubscriptionsApp>
        <RedirectIfNoSubscriptionsAvailable>
          <Switch>
            <Route exact path={SubscriptionsRoute}>
              <HtmlPageTitle appName={AppNames.Subscriptions} />
              <SubscriptionManagement />
            </Route>
            <Route path={SubscriptionRoute}>
              <EnsureSelectedSubscription>
                <HtmlPageTitle appName={AppNames.SubscriptionManagement} />
                <EnforceServiceAgreement>
                  <Switch>
                    <Route path={EnsuredSubscriptionRoute}>
                      <RouteContext>
                        {(props: IRouteContext<EnsuredSubscriptionRouteParams>) => (
                          <Switch>
                            <Route path={SubscriptionEnvironmentSettingsRoute}>
                              <RouteContext>
                                {({
                                  match,
                                }: IRouteContext<SubscriptionEnvironmentSettingsRouteParams>) => (
                                  <EnsureSubscriptionCurrentProject key={match.params.projectId}>
                                    <EnvironmentSettings key={match.params.projectId} />
                                  </EnsureSubscriptionCurrentProject>
                                )}
                              </RouteContext>
                            </Route>
                            <Route path={EnsuredSubscriptionRoute}>
                              <SubscriptionEdit>
                                <Switch>
                                  <Route path={SubscriptionOverviewRoute}>
                                    <CurrentUsage />
                                  </Route>
                                  <Route exact path={SubscriptionGeneralRoute}>
                                    <SubscriptionGeneral />
                                  </Route>
                                  <Route exact path={BillingInformationRoute}>
                                    <BillingInformation />
                                  </Route>
                                  <Route path={BillingHistoryRoute}>
                                    <BillingHistory />
                                  </Route>
                                  <Route path={PlanSelectionRoute}>
                                    <PlanSelection />
                                  </Route>
                                  <Route path={TransactionsRoute}>
                                    <Transactions />
                                  </Route>
                                  <Route path={AdministratorsRoute}>
                                    <SubscriptionAdminsManagement />
                                  </Route>
                                  <Route exact path={ProjectsInSubscriptionRoute}>
                                    <SubscriptionProjects />
                                  </Route>
                                  <Route path={SubscriptionUsersRoute}>
                                    <SubscriptionUsers />
                                  </Route>
                                  <Route path={SubscriptionApiKeysRoute}>
                                    <SubscriptionApiKeyTile />
                                  </Route>
                                  <Route>
                                    <Redirect
                                      {...getRedirectPropsWithSameSearch({
                                        to: buildPath<EnsuredSubscriptionRouteParams>(
                                          SubscriptionOverviewRoute,
                                          { subscriptionId: props.match.params.subscriptionId },
                                        ),
                                      })}
                                    />
                                  </Route>
                                </Switch>
                              </SubscriptionEdit>
                            </Route>
                            <Route>
                              <Redirect
                                {...getRedirectPropsWithSameSearch({
                                  to: buildPath<EnsuredSubscriptionRouteParams>(
                                    SubscriptionOverviewRoute,
                                    { subscriptionId: props.match.params.subscriptionId },
                                  ),
                                })}
                              />
                            </Route>
                          </Switch>
                        )}
                      </RouteContext>
                    </Route>
                  </Switch>
                </EnforceServiceAgreement>
              </EnsureSelectedSubscription>
            </Route>
            <Route>
              <Redirect {...getRedirectPropsWithSameSearch({ to: SubscriptionsRoute })} />
            </Route>
          </Switch>
        </RedirectIfNoSubscriptionsAvailable>
      </SubscriptionsApp>
    </Route>
  );
};
