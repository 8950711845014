import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type DescribeWithAiButtonProps = {
  readonly isDisabled: boolean;
  readonly onGenerateDescription: () => void;
};

export const DescribeWithAiButton: React.FC<DescribeWithAiButtonProps> = ({
  isDisabled,
  onGenerateDescription,
}) => (
  <Box paddingLeft={Spacing.S}>
    <Button
      buttonStyle="tertiary"
      onClick={() => onGenerateDescription()}
      disabled={isDisabled}
      size="medium"
      tooltipPlacement="top"
      tooltipText={
        isDisabled
          ? 'Describing the image...'
          : 'Let the AI describe the image based on its content. If the AI doesn’t recognize your language, the description is generated in English.'
      }
      type="button"
      {...getDataUiActionAttribute(DataUiAction.DescribeWithAi)}
    >
      <Button.Icon icon={Icons.KopilotAi} />
      <Button.Label>Describe with AI</Button.Label>
    </Button>
  </Box>
);

DescribeWithAiButton.displayName = 'DescribeWithAiButton';
