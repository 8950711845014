import { ILanguage, Languages } from '../../data/models/languages/Language.ts';
import { IListingVariantData } from '../../data/models/listingContentItems/IListingVariantData.ts';
import { isVariantPublished } from './contentItemVariantUtils.ts';

export function getLanguageCodename(
  languageId: Uuid,
  defaultLanguage: ILanguage,
  languages: Languages,
): string {
  const language = languages.get(languageId);
  return language ? language.codename : defaultLanguage.codename;
}

export const findNearestTranslatedFallbackLanguageId = (
  translatedLanguageIds: ReadonlyArray<Uuid>,
  projectLanguages: ReadonlyArray<ILanguage>,
  startFromLanguageId: Uuid,
): Uuid | null => {
  if (!translatedLanguageIds.length) {
    return null;
  }

  const evaluatedLanguageIds = new Set<Uuid>();
  let currentlyEvaluatedLanguage: ILanguage | undefined = getLanguageWithMatchingId(
    projectLanguages,
    startFromLanguageId,
  );

  while (currentlyEvaluatedLanguage) {
    if (evaluatedLanguageIds.has(currentlyEvaluatedLanguage.id)) {
      return null; // A cycle in the fallback path detected
    }

    if (translatedLanguageIds.includes(currentlyEvaluatedLanguage.id)) {
      return currentlyEvaluatedLanguage.id;
    }

    evaluatedLanguageIds.add(currentlyEvaluatedLanguage.id);
    currentlyEvaluatedLanguage = getLanguageWithMatchingId(
      projectLanguages,
      currentlyEvaluatedLanguage.fallbackLanguageId,
    );
  }

  return null;
};

const getLanguageWithMatchingId = (
  languages: ReadonlyArray<ILanguage>,
  languageId: Uuid | null,
): ILanguage | undefined => languages.find((l) => l.id === languageId);

export const getFallbackLanguageIdForItem = (
  translatedVariants: ReadonlyArray<IListingVariantData>,
  activeLanguages: ReadonlyArray<ILanguage>,
  selectedLanguageId: Uuid,
): Uuid | null => {
  const publishedItemVariantIds = translatedVariants
    .filter(isVariantPublished)
    .map((v) => v.id.variantId);
  const translatedLanguageIds = translatedVariants.map((v) => v.id.variantId);

  return (
    findNearestTranslatedFallbackLanguageId(
      publishedItemVariantIds,
      activeLanguages,
      selectedLanguageId,
    ) ??
    findNearestTranslatedFallbackLanguageId(
      translatedLanguageIds,
      activeLanguages,
      selectedLanguageId,
    )
  );
};
