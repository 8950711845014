import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.type.ts';

interface ITaxonomyItemElementValue {
  readonly value: ReadonlySet<Uuid>;
}

export interface ITaxonomyItemElement
  extends ITaxonomyItemElementValue,
    ICompiledContentItemElementData {
  readonly groupId: Uuid | null;
}

export const EmptyTaxonomyItemElementValue: ITaxonomyItemElementValue = {
  value: new Set(),
};

export const EmptyTaxonomyItemElement: ITaxonomyItemElement = {
  elementId: '',
  groupId: null,
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Taxonomy,
  ...EmptyTaxonomyItemElementValue,
};

export const newTaxonomyElement = (
  params: Partial<ITaxonomyItemElement>,
): ITaxonomyItemElement => ({
  ...EmptyTaxonomyItemElement,
  ...params,
});
