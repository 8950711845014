import React from 'react';
import { Link } from 'react-router-dom';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { cannotOpenContentTypesApp } from '../../../../../contentInventory/content/constants/uiConstants.ts';
import { SectionTitle } from './SectionTitle.tsx';

export interface ITypeSectionDataProps {
  readonly isClickable: boolean;
  readonly typeLink: string;
  readonly typeName: string;
}

export interface ITypeSectionCallbackProps {
  readonly onClick: () => void;
}

type TypeSectionProps = ITypeSectionCallbackProps & ITypeSectionDataProps;

export const TypeSection: React.FC<TypeSectionProps> = ({
  isClickable,
  typeLink,
  typeName,
  onClick,
}) => (
  <div
    className="sidebar__section"
    {...getDataUiObjectNameAttribute(ItemDetailSection.ContentType)}
  >
    <SectionTitle>Content type</SectionTitle>
    <div
      className="sidebar__section-item"
      data-hj-suppress=""
      title={isClickable ? undefined : cannotOpenContentTypesApp}
    >
      {isClickable ? (
        <Link to={typeLink} onClick={onClick} className="sidebar__section-item-link">
          {typeName}
        </Link>
      ) : (
        typeName
      )}
    </div>
  </div>
);

TypeSection.displayName = 'TypeSection';
