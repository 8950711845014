import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  AuditLog_Filter_ActionTypeChanged,
  AuditLog_Filter_Clear,
  AuditLog_Filter_FromDateChanged,
  AuditLog_Filter_ObjectTypeChanged,
  AuditLog_Filter_SearchPhraseChanged,
  AuditLog_Filter_ToDateChanged,
} from '../../constants/auditLogActionTypes.ts';
import { IAuditLogFilter, emptyAuditLogFilter } from '../../models/AuditEventFilter.ts';

export const auditLogFilter = (
  state: IAuditLogFilter = emptyAuditLogFilter,
  action: Action,
): IAuditLogFilter => {
  switch (action.type) {
    case AuditLog_Filter_ActionTypeChanged: {
      return {
        ...state,
        selectedActionTypes: action.payload.selectedActionTypes,
      };
    }

    case AuditLog_Filter_ObjectTypeChanged: {
      return {
        ...state,
        selectedObjectTypes: action.payload.selectedObjectTypes,
      };
    }

    case AuditLog_Filter_ToDateChanged: {
      return {
        ...state,
        selectedToDate: action.payload.selectedToDate.value,
        isFilterValid: action.payload.selectedToDate.isValid,
      };
    }

    case AuditLog_Filter_FromDateChanged: {
      return {
        ...state,
        selectedFromDate: action.payload.selectedFromDate.value,
        isFilterValid: action.payload.selectedFromDate.isValid,
      };
    }

    case AuditLog_Filter_Clear:
    case Shared_CurrentProjectId_Updated:
      return emptyAuditLogFilter;

    case AuditLog_Filter_SearchPhraseChanged: {
      return {
        ...state,
        searchPhrase: action.payload.changedSearchPhrase,
      };
    }

    default:
      return state;
  }
};
