import { ByStatus } from '../../../../_shared/constants/userListingFilter.ts';
import {
  SubscriptionUsers_AllFilters_Cleared,
  SubscriptionUsers_CollectionFilter_Removed,
  SubscriptionUsers_CollectionFilter_Updated,
  SubscriptionUsers_EnvironmentFilter_Updated,
  SubscriptionUsers_LanguageFilter_Removed,
  SubscriptionUsers_LanguageFilter_Updated,
  SubscriptionUsers_Left,
  SubscriptionUsers_NameFilter_Updated,
  SubscriptionUsers_ProjectFilter_Updated,
  SubscriptionUsers_RoleFilter_Removed,
  SubscriptionUsers_RoleFilter_Updated,
  SubscriptionUsers_SortBy_Changed,
  SubscriptionUsers_StatusFilter_Updated,
} from '../constants/actionTypes.ts';
import { SubscriptionUserListingColumnCode } from '../reducers/subscriptionUsersListingUi/sortBy.ts';

export const subscriptionUsersLeft = () =>
  ({
    type: SubscriptionUsers_Left,
  }) as const;

export const sortByChanged = (columnCode: SubscriptionUserListingColumnCode) =>
  ({
    type: SubscriptionUsers_SortBy_Changed,
    payload: { columnCode },
  }) as const;

export const allFiltersCleared = () =>
  ({
    type: SubscriptionUsers_AllFilters_Cleared,
  }) as const;

export const nameListingFilterUpdated = (name: string) =>
  ({
    type: SubscriptionUsers_NameFilter_Updated,
    payload: { name },
  }) as const;

export const projectFilterUpdated = (projectId: Uuid) =>
  ({
    type: SubscriptionUsers_ProjectFilter_Updated,
    payload: { projectId },
  }) as const;

export const environmentFilterUpdated = (environmentId: Uuid) =>
  ({
    type: SubscriptionUsers_EnvironmentFilter_Updated,
    payload: { environmentId },
  }) as const;

export const roleFilterUpdated = (roleIds: ReadonlySet<Uuid>) =>
  ({
    type: SubscriptionUsers_RoleFilter_Updated,
    payload: { roleIds },
  }) as const;

export const roleFilterRemoved = (roleId: Uuid) =>
  ({
    type: SubscriptionUsers_RoleFilter_Removed,
    payload: { roleId },
  }) as const;

export const collectionFilterRemoved = (collectionId: Uuid) =>
  ({
    type: SubscriptionUsers_CollectionFilter_Removed,
    payload: { collectionId },
  }) as const;

export const languageFilterRemoved = (languageId: Uuid) =>
  ({
    type: SubscriptionUsers_LanguageFilter_Removed,
    payload: { languageId },
  }) as const;

export const collectionFilterUpdated = (collectionIds: ReadonlySet<Uuid>) =>
  ({
    type: SubscriptionUsers_CollectionFilter_Updated,
    payload: { collectionIds },
  }) as const;

export const languageFilterUpdated = (languageIds: ReadonlySet<Uuid>) =>
  ({
    type: SubscriptionUsers_LanguageFilter_Updated,
    payload: { languageIds },
  }) as const;

export const statusFilterUpdated = (status: ByStatus) =>
  ({
    type: SubscriptionUsers_StatusFilter_Updated,
    payload: { status },
  }) as const;
