import { Action } from '../../../../@types/Action.type.ts';
import {
  SmartLink_HandleSuccessfulRedirect,
  SmartLink_HandleSuccessfulRedirectToContentItem,
  SmartLink_HandleUnsuccessfulRedirect,
} from '../../constants/smartLinkActionTypes.ts';

export const progress = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case SmartLink_HandleSuccessfulRedirectToContentItem:
    case SmartLink_HandleSuccessfulRedirect:
      return true;

    case SmartLink_HandleUnsuccessfulRedirect:
      return false;

    default:
      return state;
  }
};
