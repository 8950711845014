import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ApiStatus } from '../../../../../data/reducers/apiKeys/ApiKeyStatus.type.ts';
import { IProjectContainerRepository } from '../../../../../repositories/interfaces/IProjectContainerRepository.type.ts';
import { ApiKeys_ApiStatus_Loaded } from '../../constants/apiKeysActionTypes.ts';

interface IDeps {
  readonly projectContainerRepository: Pick<IProjectContainerRepository, 'getApiStatus'>;
}

const apiStatusLoaded = (apiKeysStatus: ReadonlyMap<Uuid, ApiStatus>) =>
  ({
    type: ApiKeys_ApiStatus_Loaded,
    payload: {
      apiKeysStatus,
    },
  }) as const;

export type LoadApiStatusActionsType = ReturnType<typeof apiStatusLoaded>;

export const loadApiStatusCreator =
  (dependencies: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch): Promise<void> => {
    const response = await dependencies.projectContainerRepository.getApiStatus(abortSignal);
    const apiStatusPerEnvironment = new Map(
      response.map((model): [Uuid, ApiStatus] => [
        model.projectEnvironmentId,
        {
          isSecuredDeliveryApiEnabled: model.secureApiEnabled,
          isManagementApiEnabled: model.managementApiEnabled,
        },
      ]),
    );

    dispatch(apiStatusLoaded(apiStatusPerEnvironment));
  };
