import { Action } from '../../../../@types/Action.type.ts';
import {
  Webhooks_CancelEditing,
  Webhooks_CancelEditingInitial,
  Webhooks_CreateEmpty,
  Webhooks_InitEdit,
  Webhooks_ListingClose_Finished,
  Webhooks_ListingInit_Started,
  Webhooks_Webhook_ArchiveStarted,
  Webhooks_Webhook_DisableCompleted,
  Webhooks_Webhook_EnableCompleted,
  Webhooks_Webhook_InsertCompleted,
  Webhooks_Webhook_ResetCompleted,
  Webhooks_Webhook_UpdateCompleted,
} from '../../constants/webhooksActionTypes.ts';

export const editedWebhookId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Webhooks_CreateEmpty:
      return action.payload.webhook.id;
    case Webhooks_InitEdit:
      return action.payload.id;

    case Webhooks_Webhook_DisableCompleted:
    case Webhooks_Webhook_EnableCompleted:
    case Webhooks_Webhook_InsertCompleted:
    case Webhooks_Webhook_ResetCompleted:
    case Webhooks_Webhook_UpdateCompleted:
    case Webhooks_CancelEditing:
    case Webhooks_CancelEditingInitial:
    case Webhooks_Webhook_ArchiveStarted:
    case Webhooks_ListingInit_Started:
    case Webhooks_ListingClose_Finished:
      return null;

    default:
      return state;
  }
};
