import { Button } from '@kontent-ai/component-library/Button';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import React from 'react';

interface IPossiblyIncorrectPlacementWarningFooterProps {
  readonly isWarningDismissed: boolean;
  readonly onConfirm: () => void;
  readonly onWarningDismissToggle: (value: boolean | null) => void;
}

export const PossiblyIncorrectPlacementWarningFooter: React.FC<
  IPossiblyIncorrectPlacementWarningFooterProps
> = ({ isWarningDismissed, onConfirm, onWarningDismissToggle }) => (
  <>
    <Checkbox
      checkboxState="default"
      checked={isWarningDismissed}
      className="possibly-incorrect-placement-warning__checkbox"
      onToggle={onWarningDismissToggle}
    >
      Don’t show this again
    </Checkbox>
    <Button buttonStyle="primary" onClick={onConfirm} size="medium">
      Got it
    </Button>
  </>
);

PossiblyIncorrectPlacementWarningFooter.displayName = 'PossiblyIncorrectPlacementWarningFooter';
