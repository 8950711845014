import { Dispatch, ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { notSpecifiedContentListingOrderBy } from '../../../../reducers/listingUi/reducers/orderBy.ts';
import { UpdateListingOrderBy } from './updateListingOrderBy.ts';

interface IDeps {
  readonly loadListingContentItems: () => ThunkPromise;
  readonly updateListingOrderBy: UpdateListingOrderBy;
}

export const resetFullTextSortingActionCreator =
  (deps: IDeps) =>
  (saveToLocalStorage: boolean): ThunkPromise =>
  async (dispatch: Dispatch) => {
    dispatch(deps.updateListingOrderBy(notSpecifiedContentListingOrderBy, saveToLocalStorage));
    await dispatch(deps.loadListingContentItems());
  };
