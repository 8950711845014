import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { environmentNameUpdated } from '../actions/generalEnvironmentSettingsActions.ts';
import { EnvironmentNameCard as EnvironmentNameCardComponent } from '../components/EnvironmentNameCard.tsx';

export const EnvironmentNameCard: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();
  const currentEnvironmentName = useSelector((s) => getCurrentProject(s).environmentName);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(environmentNameUpdated(currentEnvironmentName));
      setIsInitialized(true);
    }
  }, [currentEnvironmentName, isInitialized]);

  if (!isInitialized) {
    return <Loader />;
  }

  return <EnvironmentNameCardComponent />;
};

EnvironmentNameCard.displayName = 'EnvironmentNameCard';
