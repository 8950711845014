import { isElementHorizontallyOverflowing, isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import { ReactNode, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';

type Props = {
  readonly appendToParent?: boolean;
  readonly children: ReactNode;
  readonly className?: string;
  readonly customCondition?: (isOverflowing: boolean) => boolean;
  readonly placement: Placement;
  readonly text?: string;
};

export const OptionalTooltip = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const onResize = useDebouncedCallback(
    () =>
      setIsOverflowing(
        isElementVerticallyOverflowing(ref.current) ||
          isElementHorizontallyOverflowing(ref.current),
      ),
    200,
  );

  useResizeObserver({
    ref,
    onResize,
  });

  const showText = props.customCondition?.(isOverflowing) ?? isOverflowing;
  const tooltipText = showText && props.text ? props.text : '';

  return (
    <Tooltip
      placement={props.placement}
      tippyOptions={props.appendToParent ? { appendTo: 'parent' } : undefined}
      tooltipText={tooltipText}
    >
      <div ref={ref} className={props.className}>
        {props.children}
      </div>
    </Tooltip>
  );
};
