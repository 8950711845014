import { assert, areShallowEqual } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import { ClipboardEvent, forwardRef, useCallback } from 'react';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { AiFollowingAction } from '../../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentProjectId } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ITextTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { setRichTextClipboard } from '../../../../../../richText/actions/thunkRichTextActions.ts';
import {
  SimpleMultilineTextInput as SimpleMultilineTextInputComponent,
  SimpleMultilineTextInputProps,
} from '../../../../../../richText/editors/simpleText/SimpleMultilineTextInput.tsx';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  IPastedContent,
  importFromPastedText,
} from '../../../../../../richText/plugins/clipboard/api/editorClipboardUtils.ts';
import { PasteContentToRichTextParams } from '../../../../../../richText/plugins/clipboard/thunks/pasteContentToRichText.ts';
import { EmptyMetadata } from '../../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { OnAddComment } from '../../../../../../richText/plugins/comments/CommentsPlugin.tsx';
import { IApprovedSuggestion } from '../../../../../../richText/plugins/comments/api/editorSuggestionUtils.ts';
import { createSimpleTextValueContent } from '../../../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { ObjectDataType } from '../../../../../../richText/utils/export/html/elements/objects.ts';
import { EmptyContentComponents } from '../../../../../models/contentItem/ContentComponent.ts';
import {
  getCommentThreadIdByExternalSegmentIdMappingForElement,
  getFocusedCommentThreadIdForElement,
  getTextElementCommentThreadsByExternalSegmentId,
} from '../../../../../selectors/elementCommentThreads.ts';
import { ITextWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/ITextItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/Warnings.ts';
import { getValidationSelectorId } from '../../../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { focusedCommentThreadChanged } from '../../../actions/contentItemEditingActions.ts';
import {
  addCommentToRichTextElement,
  blurCommentThread,
  markSuggestionAsApproved,
} from '../../../actions/thunkContentItemEditingActions.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../models/ReadonlyEmptyElementPlaceholder.ts';
import { getErrorMessages } from '../../../utils/itemValidationUtils.ts';
import { useItemElementReference } from '../../hooks/useItemElementReference.ts';

const defaultValidationResult: ITextWarningResult = {
  ...emptyItemElementWarningResult,
  isMaxWordsLimitMet: true,
  isMaxCharsLimitMet: true,
  isRegexValidationMet: true,
  regexValidationMessage: '',
};

type SimpleMultilineTextInputOwnProps = Omit<
  SimpleMultilineTextInputProps,
  | 'aiGuidelinesIds'
  | 'allowCreateCommentThread'
  | 'approvedSuggestions'
  | 'commentThreadIdMapping'
  | 'commentThreads'
  | 'element'
  | 'elementLimitations'
  | 'errorMessages'
  | 'focusedCommentThreadId'
  | 'onBlurCommentThread'
  | 'onAddComment'
  | 'onFocusCommentThread'
  | 'onSuggestionApplied'
  | 'focusableRef'
  | 'setRichTextClipboard'
  | 'validationResult'
>;

interface ISimpleMultilineTextInputContainerProps extends SimpleMultilineTextInputOwnProps {
  readonly typeElement: ITextTypeElement;
}

export const SimpleMultilineTextInput = forwardRef<
  IFocusable,
  ISimpleMultilineTextInputContainerProps
>(({ placeholder, typeElement, ...otherProps }, ref) => {
  const { elementId } = typeElement;
  const element = useItemElementReference(typeElement);
  const contentComponentId = element?.contentComponentId;
  const validationResultSelectorId = getValidationSelectorId(
    elementId,
    contentComponentId ?? undefined,
  );

  const allowCreateCommentThread = useSelector((s) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.ViewContent, s),
  );
  const commentThreads = useSelector((s) =>
    getTextElementCommentThreadsByExternalSegmentId(
      s.contentApp.editedContentItemVariantComments.commentThreads,
      elementId,
      contentComponentId ?? null,
    ),
  );
  const commentThreadIdMapping = useSelector((s) =>
    getCommentThreadIdByExternalSegmentIdMappingForElement(
      s.contentApp.editedContentItemVariantComments.commentThreads,
      elementId,
      contentComponentId ?? null,
    ),
  );
  const focusedCommentThreadId = useSelector((s) =>
    getFocusedCommentThreadIdForElement(
      s.contentApp.editedContentItemVariantComments.commentThreads,
      elementId,
      contentComponentId ?? null,
      s.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
    ),
  );
  const approvedSuggestions = useSelector((s) => s.contentApp.editorUi.approvedSuggestions);
  const validationResult = useSelector(
    (s) =>
      (s.contentApp.itemValidationWarnings.get(validationResultSelectorId) as ITextWarningResult) ??
      defaultValidationResult,
    (left, right) => {
      // limitationMessages won’t ever be shallow equal because they’re arrays, we need to compare them separately
      const areShallowEqualWithoutLimitationMessages = areShallowEqual(left, right, [
        'limitationMessages',
      ]);
      const areLimitationMessagesEqual = areShallowEqual(
        left.limitationMessages,
        right.limitationMessages,
      );
      return areShallowEqualWithoutLimitationMessages && areLimitationMessagesEqual;
    },
  );
  const errorMessages = useSelector(
    (s) =>
      getErrorMessages(
        s.contentApp.itemValidationErrors,
        s.contentApp.itemValidationWarnings,
        validationResultSelectorId,
        s.contentApp.showIncompleteItemWarningsBeforePublish,
      ),
    areShallowEqual,
  );
  const currentProjectId = useSelector(getCurrentProjectId);

  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );
  const onAddComment: OnAddComment = useCallback(
    (editorState, type, api) => {
      assert(element, () => 'Missing edited element reference');
      return dispatch(addCommentToRichTextElement(element, editorState, type, api));
    },
    [element],
  );
  const onBlurCommentThread = useCallback(() => dispatch(blurCommentThread()), []);
  const onFocusCommentThread = useCallback(
    (threadId: Uuid) => dispatch(focusedCommentThreadChanged(threadId)),
    [],
  );
  const onSuggestionApplied = useCallback(
    (suggestion: IApprovedSuggestion) =>
      dispatch(markSuggestionAsApproved(suggestion.commentThreadId, suggestion.suggestionId)),
    [],
  );

  const elementType = element?.elementType;
  const onPasteContent = useCallback(
    (editorState: EditorState, params: PasteContentToRichTextParams): Promise<EditorState> => {
      const pastedContent = importFromPastedText(
        params.pastedHtmlString,
        params.isPlainText,
        currentProjectId,
      );

      const aiSessionId = pastedContent.pastedMetadata?.get(ObjectDataType.AiSession);
      if (aiSessionId && elementId && elementType) {
        dispatch(
          trackUserEventWithData(TrackedEvent.AiAction, {
            action: AiFollowingAction.SuggestionPasted,
            aiSessionId,
            elementId,
            elementType,
          }),
        );
      }

      const plainTextContent: IPastedContent = {
        content: createSimpleTextValueContent(params.pastedPlainText),
        contentComponents: new Map(),
        pastedMetadata: new Map(),
      };

      return Promise.resolve(params.pasteContent(editorState, params.selection, plainTextContent));
    },
    [currentProjectId, elementId, elementType],
  );

  if (!element) {
    return null;
  }

  return (
    <SimpleMultilineTextInputComponent
      {...otherProps}
      aiGuidelinesIds={typeElement.aiGuidelinesIds}
      allowCreateCommentThread={allowCreateCommentThread}
      approvedSuggestions={approvedSuggestions}
      commentThreadIdMapping={commentThreadIdMapping}
      commentThreads={commentThreads}
      errorMessages={errorMessages}
      element={element}
      focusedCommentThreadId={focusedCommentThreadId}
      elementLimitations={typeElement}
      defaultValue={typeElement.defaultValue}
      onBlurCommentThread={onBlurCommentThread}
      onAddComment={onAddComment}
      onFocusCommentThread={onFocusCommentThread}
      onPasteContent={onPasteContent}
      onSuggestionApplied={onSuggestionApplied}
      focusableRef={ref}
      setRichTextClipboard={onSetRichTextClipboard}
      validationResult={validationResult}
      placeholder={
        placeholder ??
        (otherProps.disabled
          ? ReadonlyEmptyElementPlaceholder.StringElement
          : 'Type your text here...')
      }
    />
  );
});

SimpleMultilineTextInput.displayName = 'SimpleMultilineTextInputContainer';
