import { isArray } from '@kontent-ai/utils';
import { EmptyObject, FieldError, FieldErrors, FieldValues, Path, Resolver } from 'react-hook-form';

export type ValidationFunction<Value, FormShape, Props> = (
  value: Value,
  allValues: FormShape,
  props: Props,
) => string | undefined;

/**
 * Configuration object with subset of keys of FormShape, each containing array of validation functions.
 */
export type FormValidatorConfig<FormShape, Props extends AnyObject = EmptyObject> = {
  [F in keyof FormShape]?: OneOrMore<ValidationFunction<FormShape[F], FormShape, Props>>;
};

/**
 * Constructs validation resolver for react-hook-form out of simple config object
 * @param {FormValidatorConfig<FormShape>} config Configuration object with keys as subset of FormShape and values as array of validation functions
 * @param {Props} props Props passed to the validation functions
 * @returns {(values: FormShape, context: undefined, options: ResolverOptions<FormShape>) => ResolverResult<FormShape>} Form validator
 */
export const createFormValidationResolver =
  <FormShape extends FieldValues, Props extends AnyObject = EmptyObject>(
    config: FormValidatorConfig<FormShape, Props>,
    props: Props,
  ): Resolver<FormShape> =>
  (values, _ctx, options) => {
    const errors = {} as FieldErrors<FormShape>;

    for (const field in options.fields) {
      if (!Object.hasOwn(config, field)) {
        continue;
      }

      const validations:
        | OneOrMore<ValidationFunction<FormShape[Path<FormShape>], FormShape, Props>>
        | undefined = config[field];

      if (!validations) {
        continue;
      }

      const formValues = values;
      const value = values[field] as FormShape[Path<FormShape>];
      const fieldValidationResult = isArray(validations)
        ? validations.reduce(
            (result, validationFunc) => result || validationFunc(value, formValues, props),
            undefined,
          )
        : validations(value, formValues, props);

      if (fieldValidationResult) {
        const error: FieldError = {
          message: fieldValidationResult,
          type: 'validate',
        };

        errors[field as Path<FormShape>] = error as any;
      }
    }

    const hasErrors = Object.values(errors).some((x) => !!x);

    return hasErrors ? { errors, values: {} } : { errors: {}, values };
  };
