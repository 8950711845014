import styled from 'styled-components';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { gridUnit } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';

export const StyledLabel = styled.div<{ readonly $color: BaseColor }>`
  min-width: ${px(3 * gridUnit)};
  ${Typography.LabelLarge};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none; // Avoids Safari tooltip when truncated.
  ${(props) => props.$color && `color: ${props.$color}`};
`;
