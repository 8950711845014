import { useAttachRef } from '@kontent-ai/hooks';
import { PropsWithChildren, RefObject, forwardRef, useCallback } from 'react';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../_shared/hooks/useAutoScroll.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  areElementContentLimitationsMet,
  isElementReadyForPublish,
} from '../../../utils/getItemElementValidationResult.ts';
import { getIsFocused, getIsHighlighted } from '../../../utils/itemHighlightingUtils.ts';
import { focusFirstIncompleteItemElement } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import {
  IItemElementOwnProps,
  ItemElement,
} from '../../ContentItemEditing/components/elements/ItemElement.tsx';
import { ItemElementScrollOptions } from '../../ContentItemEditing/constants/uiConstants.ts';
import {
  getErrorMessages,
  getErrorValidationResult,
  getFriendlyWarningMessages,
  getIncompleteElementIdPaths,
  getWarningValidationResult,
} from '../../ContentItemEditing/utils/itemValidationUtils.ts';

type ExpandedItemElementContainerProps = Omit<IItemElementOwnProps, 'isDisplayOnly'> & {
  readonly hideValidationStatus?: boolean;
};

const getIsRequiredStateLoaded = (s: IStore): boolean =>
  !!s.contentApp.editedContentItem &&
  s.contentApp.loadedContentItemTypes.has(s.contentApp.editedContentItem.editedContentItemTypeId);

const getIsExpandedItemHighlighted = (
  s: IStore,
  elementId: Uuid,
  hideValidationStatus?: boolean,
): boolean => {
  const { itemValidationErrors, itemValidationWarnings, showIncomplete } = s.contentApp;
  const incompleteElementIds = getIncompleteElementIdPaths(
    itemValidationErrors,
    itemValidationWarnings,
  );
  return (
    getIsHighlighted(elementId, incompleteElementIds) && showIncomplete && !hideValidationStatus
  );
};

const getExpandedItemErrorMessages = (s: IStore, elementId: Uuid): ReadonlyArray<string> => {
  const { itemValidationErrors, itemValidationWarnings, showIncompleteItemWarningsBeforePublish } =
    s.contentApp;
  return getErrorMessages(
    itemValidationErrors,
    itemValidationWarnings,
    elementId,
    showIncompleteItemWarningsBeforePublish,
  );
};

const getExpandedItemFriendlyWarningMessages = (
  s: IStore,
  elementId: Uuid,
): ReadonlyArray<string> => {
  const { itemValidationFriendlyWarnings, showFriendlyWarnings } = s.contentApp;
  return getFriendlyWarningMessages(
    itemValidationFriendlyWarnings,
    elementId,
    showFriendlyWarnings,
  );
};

const getValidationPassed = (s: IStore, elementId: Uuid): boolean => {
  const errorValidationResult = getErrorValidationResult(
    s.contentApp.itemValidationErrors,
    elementId,
  );
  const warningValidationResult = getWarningValidationResult(
    s.contentApp.itemValidationWarnings,
    elementId,
  );
  return !errorValidationResult && isElementReadyForPublish(warningValidationResult ?? undefined);
};

const getShowLimitationWarning = (
  s: IStore,
  elementId: Uuid,
  hideValidationStatus?: boolean,
): boolean => {
  const warningValidationResult = getWarningValidationResult(
    s.contentApp.itemValidationWarnings,
    elementId,
  );
  return (
    !!warningValidationResult &&
    !areElementContentLimitationsMet(warningValidationResult) &&
    !hideValidationStatus
  );
};

const useAutoScrollExpandedItemElement = (
  typeElement: TypeElement,
  scrollTargetRef: RefObject<HTMLDivElement>,
): void => {
  const scrollId = useSelector((s) => {
    const editedContentItem = getEditedContentItem(s);
    return CreateAutoScrollId.forElement(typeElement.codename ?? '', editedContentItem.id);
  });
  useAutoScroll({ scrollTargetRef, scrollId, scrollOptions: ItemElementScrollOptions });
};

export const ExpandedItemElement = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ExpandedItemElementContainerProps>
>((props, forwardedRef) => {
  const areContentItemElementsHighlighting = useSelector(
    (s) => s.contentApp.areContentItemElementsHighlighting,
  );
  const canUpdateContent = useSelector((s) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s),
  );
  const errorMessages = useSelector((s) =>
    getExpandedItemErrorMessages(s, props.typeElement.elementId),
  );
  const friendlyWarningMessages = useSelector((s) =>
    getExpandedItemFriendlyWarningMessages(s, props.typeElement.elementId),
  );
  const isFocused = useSelector((s) =>
    getIsFocused(props.typeElement.elementId, s.contentApp.firstIncompleteElementId),
  );
  const isHighLighted = useSelector((s) =>
    getIsExpandedItemHighlighted(s, props.typeElement.elementId, props.hideValidationStatus),
  );
  const isRequiredStateLoaded = useSelector(getIsRequiredStateLoaded);
  const showLimitationWarning = useSelector((s) =>
    getShowLimitationWarning(s, props.typeElement.elementId, props.hideValidationStatus),
  );
  const validationPassed = useSelector((s) => getValidationPassed(s, props.typeElement.elementId));

  const dispatch = useDispatch();
  const focusItemElement = useCallback(() => dispatch(focusFirstIncompleteItemElement()), []);

  const { refObject: scrollTargetRef, refToForward } = useAttachRef(forwardedRef);
  useAutoScrollExpandedItemElement(props.typeElement, scrollTargetRef);

  return isRequiredStateLoaded ? (
    <ItemElement
      ref={refToForward}
      areContentItemElementsHighlighting={areContentItemElementsHighlighting}
      canUpdateContent={canUpdateContent}
      className={props.className}
      commentThreadId={null}
      contentComponentId={props.contentComponentId}
      defaultTabIndex={props.defaultTabIndex}
      disabled={props.disabled}
      elementSupportsComments={false}
      errorMessages={errorMessages}
      escapeHandledByEditor={props.escapeHandledByEditor}
      focusEditor={props.focusEditor}
      focusItemElement={focusItemElement}
      friendlyWarningMessages={friendlyWarningMessages}
      hasFocusedComment={false}
      headerClassName={props.headerClassName}
      hideErrorMessages={props.disabled}
      isCompact={props.isCompact}
      isDisplayOnly
      isFocused={isFocused}
      isHighlighted={isHighLighted}
      isLocked={false}
      lockedByUser={null}
      onContentClick={props.onContentClick}
      onHeaderClick={props.onHeaderClick}
      showElementStatus={props.typeElement.isRequired && !props.hideValidationStatus}
      showLimitationWarning={showLimitationWarning}
      title={props.title}
      typeElement={props.typeElement}
      validationPassed={validationPassed}
    >
      {props.children}
    </ItemElement>
  ) : null;
});

ExpandedItemElement.displayName = 'ExpandedItemElementContainer';
