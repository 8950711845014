import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { DataDraftJsAttributes } from '../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { AssetThumbnailBottomClassname } from '../../../contentInventory/assets/components/AssetTile/AssetTile.tsx';
import { AssetThumbnailActionsPaneClassname } from '../../../contentInventory/assets/components/AssetTile/AssetTileActions.tsx';
import {
  EmptyTextBlockClassName,
  TextBlockClassName,
  getBlockCssSelector,
} from '../../editorCore/utils/editorComponentUtils.ts';
import {
  ContentComponentCssClass,
  ContentComponentHeaderCssClass,
} from '../../plugins/contentComponents/utils/contentComponentRenderingUtils.ts';
import { HighlightedBlockKeys } from '../../plugins/visuals/utils/editorHighlightUtils.ts';

export const DraftJSTextNodeSelector = `span[${DataDraftJsAttributes.Text}]`;
export const DraftJSLeafSelector = `span[${DataDraftJsAttributes.OffsetKey}]`;
export const DraftJSBlockContentSelector = `div[${DataDraftJsAttributes.OffsetKey}]`;

const LinkedItemHeaderCssClass = 'bar-item__bar';
const LinkedItemBodyCssClass = 'bar-item__pane';

const highlightedCssProperty = '--highlighted';
const highlightedStyleFlag = `${highlightedCssProperty}: 1;`;

export function isHighlighted(element: HTMLElement): boolean {
  return !!Number.parseInt(
    window.getComputedStyle(element).getPropertyValue(highlightedCssProperty),
    2,
  );
}

export type HighlightStyle = {
  // Various highlights may have different colors via usage of different set of color variables
  readonly colorSuffix: string;
  // We use various CSS properties to be able to combine several highlight colors on top of each other
  // NOTE:
  // There is also box-shadow style used for CODE inline style that may also combine with these visually
  // We prefer using box-shadow background only on text, as block elements may have their own shadow that might conflict with it
  readonly cssProperty: // DOM selection highlight
  // NOTE: Also used by (Focused)CommentThreadHighlighter but on different level of elements, so it is not conflicting
    | 'background-color'
    // AI selection highlight for locked editor state
    // Highlight for AI generated content
    | 'background-image';
  readonly cssSelectionSelector: string;
};

const defaultHighlightStyle: HighlightStyle = {
  colorSuffix: '',
  cssProperty: 'background-color',
  cssSelectionSelector: ' *::selection',
};

const getHighlightStyle = (
  style: HighlightStyle,
  type: 'block' | 'text' | 'element' | 'none',
): string => {
  const color = `var(--${type}-selection-bg-color${style.colorSuffix})`;

  switch (style.cssProperty) {
    case 'background-color':
      return `background-color: ${color};`;
    case 'background-image':
      return `background-image: linear-gradient(${color}, ${color});`;
  }
};

function getBlockEdgeStyleString(
  reachingBlockEnd: string | null,
  reachingBlockStart: string | null,
  editorId: string,
): string {
  if (!reachingBlockEnd && !reachingBlockStart) {
    return '';
  }

  // The styles for pseudo-elements are already present, we just activate them by setting the content
  const endStyle = reachingBlockEnd
    ? `${getBlockCssSelector(
        editorId,
        reachingBlockEnd,
      )} > ${DraftJSBlockContentSelector}:after { content: " "; }`
    : '';

  const startStyle = reachingBlockStart
    ? `${getBlockCssSelector(
        editorId,
        reachingBlockStart,
      )} > ${DraftJSBlockContentSelector}:before { content: " "; }`
    : '';

  return `
${endStyle}
${startStyle}
  `;
}

function getBlocksStyleString(
  blockKeys: ReadonlySet<string>,
  editorId: string,
  style: HighlightStyle,
): string {
  if (!blockKeys.size) {
    return '';
  }

  const selectors = Collection.getValues(blockKeys)
    .map(
      (blockKey) => `${getBlockCssSelector(editorId, blockKey)} .${ContentComponentCssClass},
     ${getBlockCssSelector(editorId, blockKey)} .${ContentComponentHeaderCssClass},
     ${getBlockCssSelector(editorId, blockKey)} .${LinkedItemBodyCssClass},
     ${getBlockCssSelector(editorId, blockKey)} .${LinkedItemHeaderCssClass}`,
    )
    .join(', ');

  const background = getHighlightStyle(style, 'block') + highlightedStyleFlag;

  const styleString = `
${selectors} { ${background} }
  `;
  return styleString;
}

function getTextBlocksStyleString(
  textBlockKeys: ReadonlySet<string>,
  editorId: string,
  style: HighlightStyle,
): string {
  if (!textBlockKeys.size) {
    return '';
  }

  const blockKeys = Collection.getValues(textBlockKeys);
  const selectors = blockKeys
    .map(
      (blockKey) => `${getBlockCssSelector(editorId, blockKey)} > ${DraftJSBlockContentSelector}`,
    )
    .join(', ');

  // Prevent the default text highlight to make sure two selection highlights do not overlap, as that would produce darker selection colorr
  const textSelectors = blockKeys
    .map(
      (blockKey) =>
        `${getBlockCssSelector(editorId, blockKey)} ${DraftJSLeafSelector}${
          style.cssSelectionSelector
        }`,
    )
    .join(', ');

  const styleString = `
${selectors} { ${getHighlightStyle(
    style,
    'text',
  )} animation: force-hide-nested-selection 1ms; ${highlightedStyleFlag} }
${textSelectors} { ${getHighlightStyle(style, 'none')} ${highlightedStyleFlag} }
  `;
  return styleString;
}

function getTableCellsStyleString(
  cellBlockKeys: ReadonlySet<string>,
  editorId: string,
  style: HighlightStyle,
): string {
  if (!cellBlockKeys.size) {
    return '';
  }

  const blockKeys = Collection.getValues(cellBlockKeys);
  const selectors = blockKeys.map((blockKey) => getBlockCssSelector(editorId, blockKey)).join(', ');

  // Prevent the default text highlight to make sure two selection highlights do not overlap, as that would produce darker selection color
  const nestedTextBlocksSelectors = blockKeys
    .map(
      (blockKey) =>
        `${getBlockCssSelector(editorId, blockKey)} .${TextBlockClassName} ${DraftJSLeafSelector}${
          style.cssSelectionSelector
        }`,
    )
    .join(', ');

  // Prevent the nested blocks highlight to make sure two selection highlights do not overlap, as that would produce darker selection color
  const nestedEmptyTextBlocksSelectors = blockKeys
    .map(
      (blockKey) =>
        `${getBlockCssSelector(
          editorId,
          blockKey,
        )} .${EmptyTextBlockClassName} > ${DraftJSBlockContentSelector}`,
    )
    .join(', ');

  const styleString = `
${selectors} { ${getHighlightStyle(
    style,
    'text',
  )} animation: force-hide-nested-selection 1ms; ${highlightedStyleFlag} }
${nestedTextBlocksSelectors} { ${getHighlightStyle(style, 'none')} ${highlightedStyleFlag} }
${nestedEmptyTextBlocksSelectors} { ${getHighlightStyle(style, 'none')} ${highlightedStyleFlag} }
  `;
  return styleString;
}

function getImagesStyleString(
  imageBlockKeys: ReadonlySet<string>,
  editorId: string,
  style: HighlightStyle,
): string {
  if (!imageBlockKeys.size) {
    return '';
  }

  const selectors = Collection.getValues(imageBlockKeys)
    .map((blockKey) => {
      const blockSelector = getBlockCssSelector(editorId, blockKey);
      return `${blockSelector} .${AssetThumbnailActionsPaneClassname}, ${blockSelector} .${AssetThumbnailBottomClassname}`;
    })
    .join(', ');

  const styleString = `${selectors} { ${getHighlightStyle(
    style,
    'element',
  )} ${highlightedStyleFlag} }`;
  return styleString;
}

type RichTextHighlighterProps = HighlightedBlockKeys & {
  readonly editorId: string;
  readonly style?: HighlightStyle;
};

export const RichTextHighlighter: React.FC<RichTextHighlighterProps> = ({
  editorId,
  blockKeys,
  style = defaultHighlightStyle,
  imageBlockKeys,
  tableCellBlockKeys,
  textBlockKeys,
  reachingBlockEnd,
  reachingBlockStart,
}) => {
  const blocksStyleString = getBlocksStyleString(blockKeys, editorId, style);
  const textBlocksStyleString = getTextBlocksStyleString(textBlockKeys, editorId, style);
  const edgeStyleString = getBlockEdgeStyleString(reachingBlockEnd, reachingBlockStart, editorId);
  const imagesStyleString = getImagesStyleString(imageBlockKeys, editorId, style);
  const tableCellsStyleString = getTableCellsStyleString(tableCellBlockKeys, editorId, style);

  if (
    !blocksStyleString &&
    !textBlocksStyleString &&
    !edgeStyleString &&
    !imagesStyleString &&
    !tableCellsStyleString
  ) {
    return null;
  }

  const inlineStyleString = `
${blocksStyleString}
${textBlocksStyleString}
${edgeStyleString}
${imagesStyleString}
${tableCellsStyleString}
  `;

  return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
};

RichTextHighlighter.displayName = 'RichTextHighlighter';
