import { Action } from '../../../../../@types/Action.type.ts';
import {
  AssetType_Editor_InitFinished,
  AssetType_Editor_SavingFinished,
} from '../../../assetTypes/constants/assetTypeActionTypes.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Creator_InitStarted,
  ContentType_Editor_ChangeCodename,
  ContentType_Editor_ChangeContentTypeName,
  ContentType_Editor_ChangeMultipleChoiceOptionCodename,
  ContentType_Editor_ChangeTypeElementCodename,
  ContentType_Editor_ContentGroupCodenameRenamed,
  ContentType_Editor_ContentGroupCreated,
  ContentType_Editor_ContentGroupDeleted,
  ContentType_Editor_ContentGroupMoved,
  ContentType_Editor_ContentGroupRenamed,
  ContentType_Editor_InitFinished,
  ContentType_Editor_MultipleChoiceCreateOption,
  ContentType_Editor_MultipleChoiceDeleteOption,
  ContentType_Editor_MultipleChoiceMoveOption,
  ContentType_Editor_MultipleChoiceUpdateOption,
  ContentType_Editor_SavingFinished,
  ContentType_Editor_SelectedTypeElementsSentToContentGroup,
  ContentType_Editor_SelectedTypeElementsSentToNewContentGroup,
} from '../../../contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentModels_TypeEditor_RemoveTypeElement,
  ContentModels_TypeEditor_TypeElementCreated,
  ContentModels_TypeEditor_TypeElementMoved,
  ContentModels_TypeEditor_TypeElementUpdated,
} from '../../constants/sharedContentModelsActionTypes.ts';

export function editedTypeIsModified(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentModels_TypeEditor_RemoveTypeElement:
    case ContentModels_TypeEditor_TypeElementMoved:
    case ContentModels_TypeEditor_TypeElementCreated:
    case ContentModels_TypeEditor_TypeElementUpdated:
    case ContentType_Editor_ContentGroupCodenameRenamed:
    case ContentType_Editor_ContentGroupCreated:
    case ContentType_Editor_ContentGroupDeleted:
    case ContentType_Editor_ContentGroupMoved:
    case ContentType_Editor_ContentGroupRenamed:
    case ContentType_Editor_MultipleChoiceCreateOption:
    case ContentType_Editor_MultipleChoiceUpdateOption:
    case ContentType_Editor_MultipleChoiceDeleteOption:
    case ContentType_Editor_MultipleChoiceMoveOption:
    case ContentType_Editor_SelectedTypeElementsSentToContentGroup:
    case ContentType_Editor_SelectedTypeElementsSentToNewContentGroup:
    case ContentType_Editor_ChangeContentTypeName:
    case ContentType_Editor_ChangeCodename:
    case ContentType_Editor_ChangeTypeElementCodename:
    case ContentType_Editor_ChangeMultipleChoiceOptionCodename: {
      return true;
    }

    case ContentType_Creator_InitStarted:
    case ContentType_Creator_CreationFinished:
    case ContentType_Editor_InitFinished:
    case ContentType_Editor_SavingFinished:
    case AssetType_Editor_InitFinished:
    case AssetType_Editor_SavingFinished: {
      return false;
    }

    default:
      return state;
  }
}
