interface IEmptyInventoryProps {
  readonly isFilterEmpty: boolean;
  readonly isSearchPhraseEmpty: boolean;
}

export const getEmptyInventoryInfoMessage = (emptyInventoryProps: IEmptyInventoryProps): string => {
  const { isFilterEmpty, isSearchPhraseEmpty } = emptyInventoryProps;

  if (!isFilterEmpty && !isSearchPhraseEmpty) {
    return 'Try rephrasing your search phrase or using different filters.';
  }

  if (!isSearchPhraseEmpty) {
    return 'Try rephrasing your search phrase.';
  }

  if (!isFilterEmpty) {
    return 'Try using different filters.';
  }

  return '';
};
