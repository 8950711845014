import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { CollectionSection } from '../../constants/collectionSection.ts';
import { ContentTypeSection } from '../../constants/contentTypeSection.ts';
import { NewContentItem_Initialized } from '../../constants/newContentItemActionTypes.ts';
import { NewContentItemOrigin } from '../../constants/newContentItemOrigin.ts';
import {
  ICollectionConfig,
  IContentTypeConfig,
  INewContentItemDialogProperties,
  INewContentItemForm,
} from '../../reducers/INewContentItemStoreState.type.ts';
import { getInitialSelectedCollectionData } from '../../selectors/getInitialSelectedCollectionData.ts';
import { getInitialSelectedContentTypeData } from '../../selectors/getInitialSelectedContentTypeData.ts';
import { getInitialSelectedWorkflowId } from '../../selectors/getInitialSelectedWorkflowId.ts';
import { newContentItemDialogPropertiesSet } from '../newContentItemActions.ts';

export type InitialSelectedCollectionData = {
  readonly collectionId: Uuid | null;
  readonly collectionSection: CollectionSection;
};

export type InitialSelectedContentTypeData = {
  readonly contentTypeId: Uuid | null;
  readonly contentTypeSection: ContentTypeSection;
};

interface IDeps {
  readonly dialogProperties: INewContentItemDialogProperties;
  readonly getCollectionSelectorConfig: (state: IStore) => ICollectionConfig;
  readonly getContentTypeSelectorConfig: (
    state: IStore,
    allowedContentTypeIds: Immutable.Set<Uuid> | null,
  ) => IContentTypeConfig;
  readonly getInitialCollectionData?: (
    state: IStore,
    origin: NewContentItemOrigin,
    languageId: Uuid,
  ) => InitialSelectedCollectionData;
  readonly getInitialLanguageId?: (state: IStore) => Uuid;
  readonly loadCollections: () => ThunkPromise;
  readonly loadCollectionsPreference?: () => ThunkPromise;
  readonly loadContentTypes: () => ThunkPromise;
  readonly loadTypesPreference?: () => ThunkPromise;
}

const initialize = (
  initialSelectedValues: INewContentItemForm,
  contentTypeSelectorConfig: IContentTypeConfig,
  collectionSelectorConfig: ICollectionConfig,
) =>
  ({
    type: NewContentItem_Initialized,
    payload: {
      collectionSelectorConfig,
      contentTypeSelectorConfig,
      initialSelectedValues,
    },
  }) as const;

export type PrepareNewContentItemDialogActionType = ReturnType<typeof initialize>;

type Params = {
  readonly allowedContentTypeIds: Immutable.Set<Uuid> | null;
};

export const createPrepareNewContentItemDialogAction =
  (deps: IDeps) =>
  ({ allowedContentTypeIds }: Params): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(newContentItemDialogPropertiesSet(deps.dialogProperties));

    const { data } = getState();

    if (data.contentTypes.loadingStatus !== LoadingStatus.Loaded) {
      await dispatch(deps.loadContentTypes());
    }
    if (data.collections.loadingStatus !== LoadingStatus.Loaded) {
      await dispatch(deps.loadCollections());
    }

    if (deps.loadTypesPreference) {
      await dispatch(deps.loadTypesPreference());
    }

    if (deps.loadCollectionsPreference) {
      await dispatch(deps.loadCollectionsPreference());
    }

    const state = getState();
    const contentTypeSelectorConfig = deps.getContentTypeSelectorConfig(
      state,
      allowedContentTypeIds,
    );
    const initialSelectorValues = getInitialSelectedValues(state, deps, contentTypeSelectorConfig);
    const collectionSelectorConfig = deps.getCollectionSelectorConfig(state);

    dispatch(
      initialize(initialSelectorValues, contentTypeSelectorConfig, collectionSelectorConfig),
    );
  };

const getInitialSelectedValues = (
  state: IStore,
  deps: IDeps,
  contentTypeConfig: IContentTypeConfig,
): INewContentItemForm => {
  const {
    getInitialCollectionData = getInitialSelectedCollectionData,
    getInitialLanguageId = getSelectedLanguageIdOrThrow,
    dialogProperties: { origin },
  } = deps;

  const languageId = getInitialLanguageId(state);
  const { collectionId, collectionSection } = getInitialCollectionData(state, origin, languageId);
  const { contentTypeId, contentTypeSection } = getInitialSelectedContentTypeData(
    state,
    origin,
    languageId,
    collectionId,
    contentTypeConfig,
  );
  const workflowId = getInitialSelectedWorkflowId(state, languageId, collectionId, contentTypeId);

  return {
    collectionId,
    collectionSection,
    contentTypeId,
    contentTypeSection,
    languageId,
    name: '',
    workflowId,
  };
};
