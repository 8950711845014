import React from 'react';
import { IconWrapper } from '../../Icons/IconWrapper.tsx';

const width = 8;
const height = 2;

export const IndeterminateIcon: React.FC = () => (
  <IconWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{
        width,
        height,
      }}
    >
      <rect
        {...{
          width,
          height,
        }}
        fill="currentcolor"
        fillRule="evenodd"
        rx="1"
      />
    </svg>
  </IconWrapper>
);

IndeterminateIcon.displayName = 'IndeterminateIcon';
