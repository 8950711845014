import { buildAssetListingRoutePath } from '../../../applications/contentInventory/assets/utils/assetsPathUtils.ts';
import {
  BreadcrumbsOriginItem,
  IAssetFolderBreadcrumbsOriginItem,
  IBreadcrumbsOrigin,
} from '../../../applications/contentInventory/shared/reducers/IContentInventoryStoreState.type.ts';

export const getPreviouslyOpenAssetFolderPath = (
  breadcrumbsOrigin: IBreadcrumbsOrigin,
  projectId: Uuid,
  fallbackFolderId: Uuid,
): string => {
  const folderOrigin = breadcrumbsOrigin.items.find(isAssetFolderBreadcrumbsOrigin);

  return (
    folderOrigin?.path ??
    buildAssetListingRoutePath({
      assetFolderId: fallbackFolderId,
      projectId,
    })
  );
};

const isAssetFolderBreadcrumbsOrigin = (
  origin: BreadcrumbsOriginItem,
): origin is IAssetFolderBreadcrumbsOriginItem =>
  (origin as IAssetFolderBreadcrumbsOriginItem).type === 'AssetFolder';
