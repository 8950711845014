import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';

const doesInvitationsContainContributor =
  (invitations: Immutable.List<Invitation>) =>
  (contributor: IProjectContributor): boolean =>
    invitations.some(
      (i: Invitation) => contributor.email.toLocaleLowerCase() === i.email.toLocaleLowerCase(),
    );

export const findCollidingContributorEmails = memoize.maxOne(
  (
    allContributors: ReadonlyMap<Uuid, IProjectContributor>,
    invitations: Immutable.List<Invitation>,
  ): ReadonlySet<string> =>
    new Set(
      Collection.getValues(allContributors)
        .filter((user) => !user.isVirtual)
        .filter(doesInvitationsContainContributor(invitations))
        .map((c: IProjectContributor) => c.email.trim().toLocaleLowerCase()),
    ),
);
