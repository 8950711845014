import React, { ReactNode } from 'react';

interface INoUsageProps {
  readonly children: ReactNode;
}

export const NoUsage: React.FC<INoUsageProps> = ({ children }) => (
  <div className="sidebar__section-content">{children}</div>
);

NoUsage.displayName = 'NoUsage';
