import { Box } from '@kontent-ai/component-library/Box';
import { colorBackground, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { useMemo } from 'react';
import styled from 'styled-components';
import { WebSpotlightZIndex } from '../../constants/WebSpotlightZIndex.ts';
import { WebSpotlightPreviewContainerOffset } from '../../constants/uiConstants.ts';
import { useWebSpotlight } from '../../context/WebSpotlightContext.tsx';
import { useSharedContentItemPreviewRouteState } from '../../hooks/useSharedContentItemPreviewRouteState.ts';
import { useWebSpotlightMessaging } from '../../hooks/useWebSpotlightMessaging.ts';
import { useWebSpotlightPreviewInitialization } from '../../hooks/useWebSpotlightPreviewInitialization.ts';
import { WebSpotlightPreviewResolutionType } from '../../models/webSpotlightPreviewResolutionType.ts';
import { WebSpotlightPreviewResizeHandles } from './WebSpotlightPreviewResizeHandles.tsx';

interface IStyledIFrameProps {
  readonly fitScreen: boolean;
  readonly height: number;
  readonly scale: number;
  readonly width: number;
}

const StyledIFrame = styled.iframe.attrs((props: IStyledIFrameProps) => ({
  style: {
    width: props.fitScreen ? '100%' : px(props.width),
    height: props.fitScreen ? '100%' : px(props.height),
    transform: `scale(${props.fitScreen ? 1 : props.scale})`,
  },
}))<IStyledIFrameProps>`
  background-color: ${colorBackground};
  transform-origin: ${(props) => (props.scale < 1 ? '0 0' : 'center top')};
  z-index: ${WebSpotlightZIndex.PreviewIFrame}
`;

export const WebSpotlightPreviewSandbox = () => {
  const {
    isSmartLinkSdkInitialized,
    previewIFrameKey,
    setPreviewIFrameRef,
    previewIFrameResolutionType,
    previewIFrameResolution,
    previewUrl,
  } = useWebSpotlight();

  const containerWidth = useMemo(
    () =>
      previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen &&
      previewIFrameResolution.scale < 1
        ? previewIFrameResolution.width * previewIFrameResolution.scale
        : 'auto',
    [previewIFrameResolutionType, previewIFrameResolution.scale, previewIFrameResolution.width],
  );

  useWebSpotlightMessaging();
  useWebSpotlightPreviewInitialization();

  const [sharedPreviewState] = useSharedContentItemPreviewRouteState();

  return (
    <Box
      width={containerWidth}
      height="100%"
      marginX="auto"
      marginY={0}
      paddingRight={WebSpotlightPreviewContainerOffset}
      paddingBottom={WebSpotlightPreviewContainerOffset}
    >
      <WebSpotlightPreviewResizeHandles>
        <StyledIFrame
          key={previewIFrameKey}
          title="Preview"
          ref={setPreviewIFrameRef}
          src={sharedPreviewState?.url ?? previewUrl}
          fitScreen={previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen}
          width={previewIFrameResolution.width}
          height={previewIFrameResolution.height}
          scale={previewIFrameResolution.scale}
          sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation"
          className={classNames(
            'web-spotlight-preview__sandbox',
            'web-spotlight__layout-guard-content',
            {
              'web-spotlight-preview__sandbox--is-initialized': isSmartLinkSdkInitialized,
            },
          )}
        />
      </WebSpotlightPreviewResizeHandles>
    </Box>
  );
};
