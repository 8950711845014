import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { isArchivedWorkflowStep } from '../../../../../_shared/utils/contentItemUtils.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';

const getFirstStepWithTransitionFromCurrent = (
  currentStep: IWorkflowStep,
  steps: readonly IWorkflowStep[],
): IWorkflowStep | undefined =>
  steps.find((step) => !!step && currentStep.transitionsTo.has(step.id));

const getNextStepWithTransitionFromCurrent = (
  currentStep: IWorkflowStep,
  workflowSteps: ReadonlyMap<Uuid, IWorkflowStep>,
): IWorkflowStep | undefined => {
  const steps = Collection.getValues(workflowSteps);
  const index = steps.findIndex((step) => !!step && currentStep.id === step.id);
  const nextSteps = steps.slice(index);
  return getFirstStepWithTransitionFromCurrent(currentStep, nextSteps);
};

export const getNextWorkflowStepForVariantWithoutPublishedVersion = memoize.maxOne(
  (
    currentStep: IWorkflowStep,
    workflowSteps: ReadonlyMap<Uuid, IWorkflowStep>,
  ): IWorkflowStep | undefined => {
    const nextStepAfterCurrent = getNextStepWithTransitionFromCurrent(currentStep, workflowSteps);

    return (
      nextStepAfterCurrent ??
      getFirstStepWithTransitionFromCurrent(currentStep, Collection.getValues(workflowSteps))
    );
  },
);

export const getNextWorkflowStepForVariantWithPublishedVersion = memoize.maxOne(
  (
    currentStep: IWorkflowStep,
    workflowSteps: ReadonlyMap<Uuid, IWorkflowStep>,
  ): IWorkflowStep | undefined => {
    const nextStepAfterCurrent = getNextStepWithTransitionFromCurrent(currentStep, workflowSteps);

    if (!nextStepAfterCurrent || isArchivedWorkflowStep(nextStepAfterCurrent)) {
      return getFirstStepWithTransitionFromCurrent(
        currentStep,
        Collection.getValues(workflowSteps),
      );
    }

    return nextStepAfterCurrent;
  },
);
