import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { IBaseTypeElementData } from '../../../../../contentModels/shared/models/elements/types/TypeElementData.ts';
import { getEditedContentItemType } from '../../../../selectors/getEditedContentItemType.ts';

export const getEditedContentItemTypeElementData = (
  state: IStore,
  elementId: Uuid,
): IBaseTypeElementData | undefined =>
  getEditedContentItemType(state)?.contentElements?.find(
    (typeElement) => typeElement.elementId === elementId,
  );
