import { Column, Row } from '@kontent-ai/component-library/Row';
import { Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { FallbacksPopover } from './FallbacksPopover.tsx';

const FallbacksStatusText = styled.div`
  ${Typography.BodyMedium};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FallbacksStatus: React.FC<{ readonly itemId: Uuid }> = ({ itemId }) => (
  <Row noWrap alignY="center" css="min-width: 0">
    <Column>
      <FallbacksStatusText>Fallbacks</FallbacksStatusText>
    </Column>
    <Column>
      <FallbacksPopover itemId={itemId} />
    </Column>
  </Row>
);

FallbacksStatus.displayName = 'FallbacksStatus';
