import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { SelectionAfter } from '../../../utils/editorStateUtils.ts';
import { DragDropPlugin } from '../DragDropPlugin.tsx';
import { shiftCustomBlock as shiftCustomBlockInContent } from './editorDragDropUtils.ts';

export const editorDragDropApi: EditorApiImplementation<DragDropPlugin> = {
  shiftCustomBlock(api, editorState, fromKey, toKey, allowUndo) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => shiftCustomBlockInContent(input, fromKey, toKey),
      'change-block-data',
      allowUndo,
      SelectionAfter.Original,
    );
  },
};
