import { Action } from '../../../@types/Action.type.ts';
import { Shared_UserProperties_LoadingFinished } from '../../constants/sharedActionTypes.ts';

export const areUserPropertiesLoaded = (state = false, action: Action): boolean => {
  switch (action.type) {
    case Shared_UserProperties_LoadingFinished: {
      return true;
    }

    default:
      return state;
  }
};
