import { Button } from '@kontent-ai/component-library/Button';
import { FC, useState } from 'react';

type Props = {
  readonly onRequestPasswordChange: () => void;
};

export const ChangePassword: FC<Props> = ({ onRequestPasswordChange }) => {
  const [isPasswordChangeRequested, setIsPasswordChangeRequested] = useState(false);

  const requestPasswordChange = (): void => {
    onRequestPasswordChange();
    setIsPasswordChangeRequested(true);
  };

  return (
    <section className="card__pane">
      <div className="card__content-section">
        <h2 className="user-profile__section-title">Change password</h2>
        <div className="user-profile__form-group">
          If you need to change your password, click the button below and we’ll send you an email to
          set a new one.
        </div>
      </div>
      <div className="card__bottom-section">
        {isPasswordChangeRequested ? (
          'The email is on its way - check your inbox.'
        ) : (
          <Button
            buttonStyle="primary"
            disabled={isPasswordChangeRequested}
            onClick={requestPasswordChange}
          >
            Set new password
          </Button>
        )}
      </div>
    </section>
  );
};

ChangePassword.displayName = 'ChangePassword';
