import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { loadListingContentItemsByIds } from '../../../../../data/actions/thunkDataActions.ts';
import { assetService } from '../../../../contentInventory/content/features/Asset/services/assetService.ts';
import { createGetAssetDetails } from './customElementActions/createGetAssetDetails.ts';
import { createGetItemDetails } from './customElementActions/createItemDetails.ts';

const { contentTypeRepository } = repositoryCollection;

export const getAssetDetails = createGetAssetDetails({
  assetService,
});

export const getItemDetails = createGetItemDetails({
  contentTypeRepository,
  loadListingContentItemsByIds,
});
