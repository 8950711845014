import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DropDownOptionsGroup } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import { EditingAction } from '../../../models/EditingAction.ts';
import { ActionsMenuItemToActionMapper } from './ActionsMenuItemToActionMapper.tsx';

type ActionsMenuGroupProps = {
  readonly allGroupActions: readonly EditingAction[];
  readonly availableActions: readonly EditingAction[];
  readonly isSlim?: boolean;
  readonly title?: string;
};

export const ActionsMenuGroup: React.FC<ActionsMenuGroupProps> = (props) => {
  const actionsToRender = props.allGroupActions.filter((action) =>
    props.availableActions.includes(action),
  );

  if (!actionsToRender.length) {
    return null;
  }

  return (
    <DropDownOptionsGroup title={props.title}>
      {actionsToRender.map((action) => (
        <ActionsMenuItemToActionMapper
          action={action}
          actionOrigin={ContentItemEditingEventOrigins.MoreActions}
          isSlim={props.isSlim}
          key={action}
        />
      ))}
    </DropDownOptionsGroup>
  );
};

ActionsMenuGroup.displayName = 'ActionsMenuGroup';
