import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import { IApiTokenDetailServerModel } from '../../../../../repositories/serverModels/IApiTokenDetailServerModel.type.ts';
import {
  ApiKeyDetail_Creating_Failed,
  ApiKeyDetail_Creating_Finished,
  ApiKeyDetail_Creating_Started,
} from '../../constants/apiKeysActionTypes.ts';
import {
  ApiKey,
  convertToCreateServerModel,
  createApiKeyFromServerModel,
} from '../../models/ApiKey.ts';

const started = () =>
  ({
    type: ApiKeyDetail_Creating_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_Creating_Failed,
  }) as const;

const success = (apiKey: ApiKey) =>
  ({
    type: ApiKeyDetail_Creating_Finished,
    payload: { apiKey },
  }) as const;

interface ICreateApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'createApiKey'>;
}

export type CreateApiKeyActionsType = ReturnType<typeof started | typeof failed | typeof success>;

export const createApiKeyCreator =
  ({ apiKeysRepository }: ICreateApiKeyDependencies) =>
  (apiKey: ApiKey): ThunkPromise<IApiTokenDetailServerModel> =>
  async (dispatch) => {
    try {
      dispatch(started());

      const apiKeyServerModel = convertToCreateServerModel(apiKey);

      const serverModel = await apiKeysRepository.createApiKey(apiKeyServerModel);

      dispatch(success(createApiKeyFromServerModel(serverModel)));

      return serverModel;
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
