import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';
import { AiError, getAiErrorMessage } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type AiSuggestionErrorProps = {
  readonly error: AiError;
};

export const AiSuggestionError: React.FC<AiSuggestionErrorProps> = ({ error }) => {
  return (
    <Callout calloutType="warning" {...getDataUiElementAttribute(DataUiElement.CalloutWarning)}>
      {getAiErrorMessage(error, baseErrorMessageByErrorCode)}
    </Callout>
  );
};

AiSuggestionError.displayName = 'AiSuggestionError';
