import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  getCmApiIsEnabledFromServerModel,
  getSecuredDeliveryApiIsEnabledFromServerModel,
} from '../../../../../data/models/apiKeys/apiKeyModelUtils.ts';
import { ApiStatus } from '../../../../../data/reducers/apiKeys/ApiKeyStatus.type.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { Environment_ApiStatus_Loaded } from '../../constants/generalSettingsActionTypes.ts';

interface IDeps {
  readonly projectRepository: Pick<
    IProjectRepository,
    'getContentManagementApiStatus' | 'getSecuredDeliveryApiStatus'
  >;
}

const environmentApiStatusLoaded = (environmentId: Uuid, apiStatus: ApiStatus) =>
  ({
    type: Environment_ApiStatus_Loaded,
    payload: { environmentId, apiStatus },
  }) as const;

export type LoadEnvironmentApiStatusActionsType = ReturnType<typeof environmentApiStatusLoaded>;

export const loadEnvironmentApiStatusCreator =
  (dependencies: IDeps) =>
  (environmentId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const [secureDeliveryApiStatus, contentManagementApiStatus] = await Promise.all([
      dependencies.projectRepository.getSecuredDeliveryApiStatus(abortSignal),
      dependencies.projectRepository.getContentManagementApiStatus(abortSignal),
    ]);

    dispatch(
      environmentApiStatusLoaded(environmentId, {
        isSecuredDeliveryApiEnabled:
          getSecuredDeliveryApiIsEnabledFromServerModel(secureDeliveryApiStatus),
        isManagementApiEnabled: getCmApiIsEnabledFromServerModel(contentManagementApiStatus),
      }),
    );
  };
