import { Action } from '../../../../../@types/Action.type.ts';
import {
  Environment_RenameEnvironment_Failed,
  Environment_RenameEnvironment_Finished,
  Environment_RenameEnvironment_Started,
} from '../../constants/generalSettingsActionTypes.ts';

export const renamingEnvironmentFailed = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Environment_RenameEnvironment_Failed:
      return true;

    case Environment_RenameEnvironment_Finished:
    case Environment_RenameEnvironment_Started:
      return false;

    default:
      return state;
  }
};
