import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ContentState, EditorState } from 'draft-js';
import { forwardRef, useMemo } from 'react';
import { ActionMenuPositioner } from '../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createCopyToClipboardAiAction,
  createDiscardAiActionFromResult,
  createRejectSuggestionAiSection,
  createReplaceSelectionAiAction,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { AiResult } from '../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import {
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { Tone } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.changeTone.ts';
import { AiSuggestionError } from '../../components/AiSuggestionError.tsx';
import { RichAiSuggestionViewer } from '../../containers/viewers/RichAiSuggestionViewer.tsx';

const changeToneReasons = createFeedbackReasons([
  SpecificFeedbackReason.ToneWrong,
  SpecificFeedbackReason.ContentChangedTooMuch,
  SpecificFeedbackReason.ContentTooSimilar,
  SpecificFeedbackReason.ResultSlow,
  SpecificFeedbackReason.ContentChangedMeaning,
]);

type ChangeToneActionProps = {
  readonly onCopyToClipboard?: () => void;
  readonly onDiscard: () => void;
  readonly onReplaceSelection?: () => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
  readonly result: ActionResult<ContentState>;
  readonly resultWidth: number;
  readonly tone: Tone;
};

export const ChangeToneAction = forwardRef<HTMLElement, ChangeToneActionProps>(
  (
    {
      onCopyToClipboard,
      onDiscard,
      onReplaceSelection,
      onTryAgain,
      preferMenuOnTop,
      result,
      resultWidth,
      tone,
    },
    ref,
  ) => {
    const menuOptions = [
      createUseSuggestionAiSection(
        [
          createReplaceSelectionAiAction(onReplaceSelection),
          createCopyToClipboardAiAction(onCopyToClipboard),
        ],
        result,
      ),
      createRejectSuggestionAiSection([
        createTryAgainAiAction(result, onTryAgain),
        createDiscardAiActionFromResult(onDiscard, result),
      ]),
    ];

    const resultEditorState = useMemo(
      () => (result.content ? EditorState.createWithContent(result.content) : null),
      [result.content],
    );
    const hasResult = !!result.error || !!resultEditorState;

    return (
      <ActionMenuPositioner
        preferMenuOnTop={preferMenuOnTop}
        ref={ref}
        renderMenu={() => <AiActionMenu options={menuOptions} />}
        renderResult={(actionResult) => (
          <AiResult
            actionName={AiActionName.ChangeTone}
            actionTitle={`Change tone to ${tone.toLocaleLowerCase()}`}
            feedbackReasons={changeToneReasons}
            isLoading={!result.isFinished}
            ref={actionResult}
            renderResult={() =>
              hasResult && (
                <Stack spacing={Spacing.L}>
                  {result.error && <AiSuggestionError error={result.error} />}
                  {resultEditorState && <RichAiSuggestionViewer editorState={resultEditorState} />}
                </Stack>
              )
            }
            width={resultWidth}
          />
        )}
      />
    );
  },
);

ChangeToneAction.displayName = 'ChangeToneAction';
