import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { IInviteRepository } from '../../../../../repositories/interfaces/IInviteRepository.type.ts';
import {
  IResendInvitationResponseServerModel,
  IResendInvitationServerModel,
} from '../../../../../repositories/serverModels/InviteUserServerModel.type.ts';
import {
  SubscriptionUserDetail_ResendInviteToProjects_Failed,
  SubscriptionUserDetail_ResendInviteToProjects_Finished,
  SubscriptionUserDetail_ResendInviteToProjects_Started,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly inviteRepository: Pick<IInviteRepository, 'resendInviteToProject'>;
}

const started = (userId: UserId, projectIds: ReadonlyArray<Uuid>) =>
  ({
    type: SubscriptionUserDetail_ResendInviteToProjects_Started,
    payload: {
      userId,
      projectIds,
    },
  }) as const;

const finished = (userId: UserId, projectIds: ReadonlyArray<Uuid>) =>
  ({
    type: SubscriptionUserDetail_ResendInviteToProjects_Finished,
    payload: {
      userId,
      projectIds,
    },
  }) as const;

const failed = (userId: UserId, projectIds: ReadonlyArray<Uuid>) =>
  ({
    type: SubscriptionUserDetail_ResendInviteToProjects_Failed,
    payload: {
      userId,
      projectIds,
    },
  }) as const;

export type ResendInviteToProjectsActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createResendInviteToProjects =
  ({ inviteRepository }: IDeps) =>
  (userId: UserId, email: string, projectIds: ReadonlyArray<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(userId, projectIds));

    try {
      const invitation: IResendInvitationServerModel = {
        invitedEmail: email,
      };

      const sentRequests = projectIds.reduce(
        (requests: Array<Promise<IResendInvitationResponseServerModel>>, projectId: Uuid) => {
          const request = inviteRepository.resendInviteToProject(invitation, projectId);
          requests.push(request);
          return requests;
        },
        new Array<Promise<IResendInvitationResponseServerModel>>(),
      );

      await Promise.all(sentRequests);

      dispatch(finished(userId, projectIds));
    } catch (e) {
      logError(e);
      dispatch(failed(userId, projectIds));
    }
  };
