import React from 'react';
import {
  DataUiWorkflowAction,
  ItemDetailSection,
  getDataUiObjectNameAttribute,
  getDataUiWorkflowActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithoutCount } from '../../../../../../_shared/utils/stringUtils.ts';

type IncompleteItemElementsSidebarNotificationProps = {
  readonly onClick: () => void;
  readonly incompleteElementsCount: number;
};

export const IncompleteItemElementsSidebarNotification: React.FC<
  IncompleteItemElementsSidebarNotificationProps
> = ({ onClick, incompleteElementsCount }) => (
  <div
    className="sidebar__section sidebar__section--no-title"
    {...getDataUiObjectNameAttribute(ItemDetailSection.IncompleteItemElementsNotification)}
  >
    <span
      className="content-item-toolbar__incomplete-notification"
      data-incomplete-elements-count={incompleteElementsCount}
    >
      <a
        className="sidebar__section-item-link"
        onClick={onClick}
        {...getDataUiWorkflowActionAttribute(DataUiWorkflowAction.ScrollToIncompleteElements)}
      >
        {pluralizeWithoutCount('Element', incompleteElementsCount)} left to complete ↓
      </a>
    </span>
  </div>
);

IncompleteItemElementsSidebarNotification.displayName = 'IncompleteItemElementsSidebarNotification';
