import { ContentBlock } from 'draft-js';
import React, { memo } from 'react';
import { DeferAutoScrollCssClass } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import { OriginalItemElementsContext } from '../../../../../itemEditor/features/ContentComponent/context/ItemElementsContext.tsx';
import { ContentOverlay } from '../../../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import {
  getContentOverlayClass,
  getContentOverlayId,
} from '../../../../editorCore/utils/editorComponentUtils.ts';
import { useContentComponent } from '../../../../hooks/useContentComponent.ts';
import { ExpandedBlockStyles } from '../../../customBlocks/components/ExpandedBlockStyles.tsx';
import { getContentComponentId } from '../../api/editorContentComponentUtils.ts';
import { ExpandedContentComponentElements } from '../../containers/expanded/elements/ExpandedContentComponentElements.tsx';

interface IExpandedContentComponentBlockProps {
  readonly block: ContentBlock;
  readonly editorId: string;
  readonly isHighlighted: boolean;
}

const ExpandedContentComponentBlock: React.FC<IExpandedContentComponentBlockProps> = ({
  block,
  editorId,
  isHighlighted,
}) => {
  const blockKey = block.getKey();
  const contentComponentId = getContentComponentId(block);
  const contentComponent = useContentComponent(contentComponentId);
  const originalContentComponent = useContentComponent(
    contentComponentId,
    OriginalItemElementsContext,
  );

  if (!contentComponent) {
    return null;
  }

  return (
    <ContentOverlay
      className={getContentOverlayClass(editorId)}
      overlayId={getContentOverlayId(editorId, blockKey)}
      pendingClassName={DeferAutoScrollCssClass}
      renderContent={() => (
        <>
          <ExpandedContentComponentElements
            contentComponent={contentComponent}
            isHighlighted={isHighlighted}
            originalContentComponent={originalContentComponent ?? undefined}
          />
          <ExpandedBlockStyles editorId={editorId} blockKey={blockKey} />
        </>
      )}
    />
  );
};

ExpandedContentComponentBlock.displayName = 'ExpandedContentComponentBlock';

const ExpandedContentComponentBlockMemo = memo(ExpandedContentComponentBlock);
export { ExpandedContentComponentBlockMemo as ExpandedContentComponentBlock };
