import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { lockedElementsChanged } from '../contentItemEditingActions.ts';

export const createReleaseExpiredElementLocks = () => (): ThunkFunction => (dispatch, getState) => {
  const dateNow = Date.now();
  const lockedElements = getState().contentApp.editorUi.lockedElements;

  const validLockedElement = lockedElements.filter(
    (lockedElement) => !!lockedElement && dateNow < Date.parse(lockedElement.validUntil),
  );

  dispatch(lockedElementsChanged(validLockedElement));
};
