import { Collection } from '@kontent-ai/utils';

const isEqual = <TKey, TValue>(
  a: ReadonlyMap<TKey, TValue>,
  b: ReadonlyMap<TKey, TValue>,
  valueComparator: (valueA: TValue, valueB: TValue) => boolean,
): boolean => {
  return (
    a.size === b.size &&
    Collection.getEntries(a).every(([keyA, valueA]) => {
      if (!b.has(keyA)) {
        return false;
      }

      return valueComparator(valueA, b.get(keyA) as TValue);
    })
  );
};

export const MapUtils = {
  isEqual,
} as const;
