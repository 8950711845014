import {
  UrlSlugCompiledTypeElementServerModel,
  UrlSlugModeServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { UrlSlugMode } from '../../../../itemEditor/constants/urlSlugMode.ts';
import { ElementType } from '../ContentItemElementType.ts';
import { UnitOption } from '../textLengthOptions.ts';
import {
  EmptyBaseTypeElement,
  IBaseTypeElement,
  ITypeElementWithValidationRegex,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface IUrlSlugTypeElement extends IBaseTypeElement, ITypeElementWithValidationRegex {
  readonly maxChars: string | null;
  readonly mode: UrlSlugMode;
  readonly dependentTextElementId: Uuid | null;
  readonly _lengthUnitOption: UnitOption;
  readonly type: ElementType.UrlSlug;
}

export const EmptyUrlSlugTypeElement: IUrlSlugTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Required,
  _lengthUnitOption: UnitOption.Words,
  dependentTextElementId: null,
  maxChars: null,
  mode: UrlSlugMode.Auto,
  type: ElementType.UrlSlug,
  validationRegex: null,
};

export function getUrlSlugTypeElementFromServerModel(
  serverModel: UrlSlugCompiledTypeElementServerModel,
): IUrlSlugTypeElement {
  const mode = serverModel.mode
    ? serverModel.mode === UrlSlugModeServerModel.Custom
      ? UrlSlugMode.Custom
      : UrlSlugMode.Auto
    : UrlSlugMode.Auto;

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyUrlSlugTypeElement._configurability,
    _lengthUnitOption: serverModel.maxChars ? UnitOption.Characters : UnitOption.Words,
    dependentTextElementId: serverModel.dependentStringTypeElementId,
    maxChars: serverModel.maxChars ? serverModel.maxChars.toString() : null,
    mode,
    type: ElementType.UrlSlug,
    validationRegex: serverModel.validationRegex,
  };
}

export const newUrlSlugTypeElement = (
  params: Partial<IUrlSlugTypeElement>,
): IUrlSlugTypeElement => ({
  ...EmptyUrlSlugTypeElement,
  ...params,
});
