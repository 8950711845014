import { useCallback, useMemo, useState } from 'react';
import { IDialogFooterProps } from '../components/DialogFooter.tsx';
import { IPopoverDialogProps, PopoverDialog } from './components/PopoverDialog.tsx';
import { IPopoverConfig, usePopover } from './usePopover.tsx';

export interface IPopoverDialogConfig
  extends Omit<IPopoverConfig, 'isOpen'>,
    Omit<IDialogFooterProps, 'isDismissable' | 'onClose'>,
    Pick<IPopoverDialogProps, 'headline'> {}

export const usePopoverDialog = (config: IPopoverDialogConfig) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeDialog = useCallback(() => setIsOpen(false), []);
  const openDialog = useCallback(() => setIsOpen(true), []);
  const {
    cancelAction,
    headline,
    primaryAction,
    extraAction,
    renderNotificationBar,
    dialogState,
    onClose,
    ...popoverConfig
  } = config;

  const preparedOnClose = useCallback(() => {
    closeDialog();
    onClose?.();
  }, [closeDialog, onClose]);

  const { cancelActionRef, dialogTitleProps, popoverProps, targetProps } = usePopover({
    ...popoverConfig,
    onClose: preparedOnClose,
    isOpen,
  });

  const preparedCancelAction = useMemo(() => {
    return {
      ...cancelAction,
      ref: cancelActionRef,
    };
  }, [cancelAction, cancelActionRef]);

  const popoverDialogProps: IPopoverDialogProps = {
    dialogTitleProps,
    headline,
    dialogState,
    ...popoverProps,
    cancelAction: preparedCancelAction,
    onClose: preparedOnClose,
    primaryAction,
    extraAction,
    renderNotificationBar,
  };

  return {
    PopoverDialog,
    popoverDialogProps,
    targetProps,
    openDialog,
    closeDialog,
    isOpen,
  };
};
