import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import {
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createNewTaxonomyGroup } from '../../actions/thunkTaxonomyActions.ts';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';

export const TaxonomyCreatorToolbarActions = () => {
  const codename = useSelector((s) => s.taxonomyApp.editedTaxonomyGroup.codename);
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const isBeingSaved = useSelector(
    (s) => s.taxonomyApp.status === TaxonomyEditorStatus.IsBeingSaved,
  );
  const isLoading = useSelector((s) => s.taxonomyApp.status === TaxonomyEditorStatus.Loading);

  const history = useHistory();
  const dispatch = useDispatch();
  const onSave = useCallback(async (): Promise<void> => {
    try {
      const createdTaxonomyGroup = await dispatch(createNewTaxonomyGroup());
      history.push(
        buildPath<EditTaxonomyGroupRouteParams>(EditTaxonomyGroupRoute, {
          projectId,
          taxonomyGroupId: createdTaxonomyGroup.id,
        }),
      );
    } catch {
      return;
    }
  }, [history, projectId]);

  return (
    <StatusBarActions
      codename={codename}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      onSave={onSave}
    />
  );
};
