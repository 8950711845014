import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

export const getRecentlyUsedContentTypeIdsForListing = (
  state: IStore,
): ReadonlyArray<Uuid> | null =>
  state.data.contentTypes.byId.size > 5
    ? state.sharedApp.uiPreferences.contentTypesRecentlyUsedInListing
    : null;

export const getRecentlyUsedContentTypeIdsForEditing = (
  state: IStore,
  allowedContentTypesIds: Immutable.Set<string>,
): ReadonlyArray<Uuid> | null =>
  allowedContentTypesIds.size > 0
    ? null
    : state.sharedApp.uiPreferences.contentTypesRecentlyUsedInEditing;
