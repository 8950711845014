import { Card } from '@kontent-ai/component-library/Card';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { SpacesListing } from '../containers/SpacesListing.tsx';
import { SpacesQuickTipSection } from './SpacesQuickTipSection.tsx';

type Props = {
  readonly initiallyExpandedSpaceId: Uuid | null;
  readonly previewUrlsAppRoute: string;
  readonly showCollections: boolean;
};

export const SpacesApp: React.FC<Props> = (props: Props) => (
  <div {...getDataUiAppNameAttribute(DataUiAppName.Spaces)}>
    <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.Spaces} />
    <Stack spacing={Spacing.XL}>
      <PageTitle>{EnvironmentSettingsAppNames.Spaces}</PageTitle>
      <Row spacing={Spacing.L}>
        <Column flexFactor={5} flexBasis={600} maxWidth={1000 + Spacing.L}>
          <Card component="section">
            <Card.Body>
              <SpacesListing initiallyExpandedSpaceId={props.initiallyExpandedSpaceId} />
            </Card.Body>
          </Card>
        </Column>
        <Column flexFactor={1} flexBasis={250}>
          <SpacesQuickTipSection
            previewUrlsAppRoute={props.previewUrlsAppRoute}
            showCollections={props.showCollections}
          />
        </Column>
      </Row>
    </Stack>
  </div>
);

SpacesApp.displayName = 'SpacesApp';
