import Immutable from 'immutable';
import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_ExpandNode_Failed,
  WebSpotlight_ExpandNode_Finished,
  WebSpotlight_ExpandNode_Started,
  WebSpotlight_ReloadNode_Failed,
  WebSpotlight_ReloadNode_Finished,
  WebSpotlight_ReloadNode_Started,
} from '../constants/webSpotlightActionTypes.ts';
import { NodeId } from '../types/nodeId.type.ts';

const initialState = Immutable.Set<NodeId>();

export const nodesBeingLoaded = (state = initialState, action: Action): Immutable.Set<NodeId> => {
  switch (action.type) {
    case WebSpotlight_ReloadNode_Started:
    case WebSpotlight_ExpandNode_Started:
      return state.add(action.payload.nodeId);

    case WebSpotlight_ReloadNode_Failed:
    case WebSpotlight_ReloadNode_Finished:
    case WebSpotlight_ExpandNode_Failed:
    case WebSpotlight_ExpandNode_Finished:
      return state.remove(action.payload.nodeId);

    default:
      return state;
  }
};
