import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentItemEditing_CommentThread_AiInlineThreadAdded,
  ContentItemEditing_CommentThread_Focused,
  ContentItemEditing_CommentThread_HideAllComments,
  ContentItemEditing_CommentThread_InlineThreadAdded,
  ContentItemEditing_CommentThread_ItemEditingStarted,
  ContentItemEditing_CommentThread_ShowAllComments,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = true;

export function isCommentsPaneVisible(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_CommentThread_HideAllComments: {
      return false;
    }

    case ContentItemEditing_CommentThread_AiInlineThreadAdded:
    case ContentItemEditing_CommentThread_InlineThreadAdded:
    case ContentItemEditing_CommentThread_ItemEditingStarted:
    case ContentItemEditing_CommentThread_ShowAllComments:
    case ContentItemEditing_CommentThread_Focused: {
      return true;
    }

    default: {
      return state;
    }
  }
}
