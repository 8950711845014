import { ByStatus } from '../../../../_shared/constants/userListingFilter.ts';

export interface ISubscriptionUsersListingFilter {
  readonly byCollection: ReadonlySet<Uuid>;
  readonly byEnvironment: Uuid;
  readonly byLanguage: ReadonlySet<Uuid>;
  readonly byName: string;
  readonly byProject: Uuid;
  readonly byRole: ReadonlySet<Uuid>;
  readonly byStatus: ByStatus;
}

export const emptySubscriptionListingUserFilter: ISubscriptionUsersListingFilter = {
  byCollection: new Set(),
  byEnvironment: '',
  byLanguage: new Set(),
  byName: '',
  byProject: '',
  byRole: new Set(),
  byStatus: ByStatus.Active,
};

export const isFilterSet = (filter: ISubscriptionUsersListingFilter): boolean =>
  !areFiltersEqual(filter, emptySubscriptionListingUserFilter);

const areFiltersEqual = (
  a: ISubscriptionUsersListingFilter,
  b: ISubscriptionUsersListingFilter,
): boolean =>
  a.byName.trim() === b.byName.trim() &&
  a.byProject === b.byProject &&
  a.byEnvironment === b.byEnvironment &&
  a.byCollection.size === b.byCollection.size &&
  a.byRole.size === b.byRole.size &&
  a.byLanguage.size === b.byLanguage.size &&
  a.byStatus === b.byStatus;
