import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { createUpdatedAssignmentForUpdateWorkflowStep } from '../../../../../../_shared/features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalUtils.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { contentItemEditingSidebarSectionCleanedUp } from '../contentItemEditingActions.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

export const createUpdateWorkflowStepAction =
  (deps: IDeps) =>
  (history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { changeWorkflowStepModalData } = state.contentApp;

    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin: getModalDialogActionOrigin(state),
        history,
        getUpdatedAssignment: () =>
          createUpdatedAssignmentForUpdateWorkflowStep(changeWorkflowStepModalData),
        submittingSection: AssignmentSections.WorkflowStep,
      }),
    );

    dispatch(contentItemEditingSidebarSectionCleanedUp());
  };
