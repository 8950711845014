import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_ResendInvitation_Finished } from '../constants/actionTypes.ts';

export const adminsReinvitedIds = (
  state = Immutable.Set<UserId>(),
  action: Action,
): Immutable.Set<UserId> => {
  switch (action.type) {
    case Subscription_ResendInvitation_Finished:
      return state.add(action.payload.userId);

    default:
      return state;
  }
};
