import React from 'react';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { BaseAvatarComponent, IBaseAvatarProps } from './components/BaseAvatarComponent.tsx';
import { StyledAvatar } from './components/StyledAvatar.tsx';

export interface IAvatarProps extends IBaseAvatarProps {
  readonly children?: never;
}

export const Avatar = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IAvatarProps>>(
  (
    {
      label,
      boxShadow,
      size,
      backgroundGradient,
      image,
      initials,
      tooltipPlacement,
      children, // Just to omit them from otherProps
      ...otherProps
    },
    forwardedRef,
  ) => {
    return (
      <BaseAvatarComponent
        renderContainer={(injectedProps) => (
          <StyledAvatar
            aria-label={label}
            boxShadow={boxShadow}
            ref={forwardedRef}
            $size={size}
            {...otherProps}
            {...injectedProps}
          />
        )}
        {...{
          backgroundGradient,
          boxShadow,
          image,
          initials,
          label,
          size,
          tooltipPlacement,
          tooltipText: label,
        }}
        {...getDataUiComponentAttribute(Avatar)}
      />
    );
  },
);

Avatar.displayName = 'Avatar';
