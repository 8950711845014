import { isTimeInPast } from '../dateTime/timeUtils.ts';

export const isVariantScheduledActionInProgress = (
  scheduledTime: string | null | undefined,
): boolean => {
  if (!scheduledTime) {
    return false;
  }
  return isTimeInPast(new Date(), scheduledTime);
};
