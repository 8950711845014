import React, { useRef } from 'react';
import { IPopoverTargetProps } from '../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { IDialogAction } from '../../../../../../../component-library/components/Dialogs/components/DialogFooterAction.tsx';
import { HandleClickOutside } from '../../../../../../_shared/components/HandleClickOutside.tsx';
import { FriendlyWarningPopover } from '../../../../../../_shared/components/infos/FriendlyWarningPopover.tsx';
import {
  DataUiAction,
  Popovers,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IContentComponentDeleteConfirmationDialogOwnProps {
  readonly isOpen: boolean;
  readonly renderTarget: (targetProps: IPopoverTargetProps) => React.ReactElement;
  readonly onDelete: () => void;
  readonly onCancel: () => void;
}

export const ContentComponentDeleteConfirmationDialog: React.FC<
  IContentComponentDeleteConfirmationDialogOwnProps
> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <HandleClickOutside observedRefs={ref} onClickOutside={props.onCancel}>
      <FriendlyWarningPopover
        popoverRef={ref}
        renderTarget={props.renderTarget}
        headline="Delete component?"
        isOpen={props.isOpen}
        onClose={props.onCancel}
        primaryAction={{
          text: 'Delete',
          destructive: true,
          onClick: props.onDelete,
          ...getDataUiActionAttribute(DataUiAction.Delete),
        }}
        cancelAction={{ ...getDataUiActionAttribute(DataUiAction.Cancel) } as IDialogAction}
        placement="top-end"
        {...getDataUiObjectNameAttribute(Popovers.ContentComponentDeleteConfirmationDialog)}
      >
        <p>This will delete the component and all its content.</p>
        <p>Do you want to delete the component?</p>
      </FriendlyWarningPopover>
    </HandleClickOutside>
  );
};

ContentComponentDeleteConfirmationDialog.displayName = 'ContentComponentDeleteConfirmationDialog';
