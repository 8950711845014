import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { forwardRef, useMemo } from 'react';
import { ActionMenuPositioner } from '../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createCopyToClipboardAiAction,
  createDiscardAiActionFromResult,
  createRejectSuggestionAiSection,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { AiResult } from '../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import {
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createSimpleTextValueEditorState } from '../../../../utils/editorSimpleTextValueUtils.ts';
import { AiSuggestionError } from '../../components/AiSuggestionError.tsx';
import { StringAiSuggestionViewer } from '../../containers/viewers/StringAiSuggestionViewer.tsx';

const feedbackReasons = createFeedbackReasons([
  SpecificFeedbackReason.ResultTooShort,
  SpecificFeedbackReason.ResultTooLong,
  SpecificFeedbackReason.ResultSlow,
  SpecificFeedbackReason.ContentChangedMeaning,
]);

type SummarizeActionProps = {
  readonly onCopyToClipboard?: () => void;
  readonly onDiscard: () => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
  readonly result: ActionResult<string>;
  readonly resultWidth: number;
};

export const SummarizeAction = forwardRef<HTMLElement, SummarizeActionProps>(
  ({ onCopyToClipboard, onDiscard, onTryAgain, preferMenuOnTop, result, resultWidth }, ref) => {
    const menuOptions = [
      createUseSuggestionAiSection([createCopyToClipboardAiAction(onCopyToClipboard)], result),
      createRejectSuggestionAiSection([
        createTryAgainAiAction(result, onTryAgain),
        createDiscardAiActionFromResult(onDiscard, result),
      ]),
    ];

    const resultEditorState = useMemo(
      () => (result.content ? createSimpleTextValueEditorState(result.content) : null),
      [result.content],
    );

    const hasResult = !!result.error || !!result.content;

    return (
      <ActionMenuPositioner
        preferMenuOnTop={preferMenuOnTop}
        ref={ref}
        renderMenu={() => <AiActionMenu options={menuOptions} />}
        renderResult={(resultRef) => (
          <AiResult
            ref={resultRef}
            actionName={AiActionName.Summarize}
            actionTitle="Summarize"
            feedbackReasons={feedbackReasons}
            isLoading={!result.isFinished}
            renderResult={() =>
              hasResult && (
                <Stack spacing={Spacing.L}>
                  {result.error && <AiSuggestionError error={result.error} />}
                  {resultEditorState && (
                    <StringAiSuggestionViewer editorState={resultEditorState} />
                  )}
                </Stack>
              )
            }
            width={resultWidth}
          />
        )}
      />
    );
  },
);

SummarizeAction.displayName = 'SummarizeAction';
