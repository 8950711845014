export enum DraftJSInlineStyle {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Code = 'CODE',
  Subscript = 'SUB',
  Superscript = 'SUP',
  AiHighlight = 'AI',
  SelectionHighlight = 'SELECTION-HIGHLIGHT',
}

export const CustomStyles = {
  [DraftJSInlineStyle.Bold]: {
    className: 'rte__bold',
  },
  [DraftJSInlineStyle.Code]: {
    className: 'rte__code',
  },
  [DraftJSInlineStyle.Italic]: {
    className: 'rte__italic',
  },
  [DraftJSInlineStyle.Subscript]: {
    className: 'rte__subscript',
  },
  [DraftJSInlineStyle.Superscript]: {
    className: 'rte__superscript',
  },
} as const;

export const MutuallyExclusiveStyles = {
  [DraftJSInlineStyle.Subscript]: [DraftJSInlineStyle.Superscript],
  [DraftJSInlineStyle.Superscript]: [DraftJSInlineStyle.Subscript],
} as const;

export const isMutuallyExclusiveStyleKey = (
  key: DraftJSInlineStyle,
): key is keyof typeof MutuallyExclusiveStyles =>
  (Object.keys(MutuallyExclusiveStyles) as ReadonlyArray<DraftJSInlineStyle>).includes(key);
