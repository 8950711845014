import { Action } from '../../../../@types/Action.type.ts';
import { AuditLog_Listing_Left } from '../../../../applications/environmentSettings/auditLog/constants/auditLogActionTypes.ts';
import { Data_AuditEvents_Started } from '../../../constants/dataActionTypes.ts';

const initialState = '';

export const cancellationToken = (state = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Data_AuditEvents_Started:
      return action.payload.cancellationToken;

    case AuditLog_Listing_Left:
      return initialState;

    default:
      return state;
  }
};
