import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { Languages } from '../../../../data/models/languages/Language.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IRichTextTypeElement } from '../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import { getImageAssetReferences } from '../../../richText/plugins/images/api/editorImageUtils.ts';
import { getModularContentItemIds } from '../../../richText/plugins/linkedItems/api/editorModularUtils.ts';
import {
  getAllContentLinkIds,
  getLinkedAssetIds,
} from '../../../richText/plugins/links/api/editorLinkUtils.ts';
import { IGetReferencesFromContentState } from '../../../richText/utils/general/editorContentUtils.ts';
import { AssetReference } from '../../models/contentItemElements/AssetItemElement.ts';
import { IRichTextItemElement } from '../../models/contentItemElements/RichTextItemElement.ts';
import { IGetItemElementFriendlyWarnings } from '../getItemElementFriendlyWarnings.ts';
import {
  IFriendlyWarning,
  ItemElementFriendlyWarningResult,
  MissingAssetDescriptionFriendlyWarningForRichText,
} from './types/FriendlyWarnings.ts';
import {
  IGetBrokenAssetWarningsWithDetails,
  IGetBrokenLinkWarningsWithDetails,
  IGetBrokenModularWarningsWithDetails,
  getBrokenAssetWarningsWithDetails,
  getBrokenLinkWarningsWithDetails,
  getBrokenModularWarningsWithDetails,
} from './utils/contentLinkUtils.ts';
import { IAssetsHaveRequiredDescriptions } from './utils/internalUtilsCreators/assetsHaveRequiredDescriptions.ts';
import { assetsHaveRequiredDescriptions } from './utils/publicUtils.ts';

export interface IRichTextItemElementFriendlyWarningCheckerParams {
  readonly typeElement: IRichTextTypeElement;
  readonly itemElement: IRichTextItemElement;
  readonly loadedEntries: Immutable.Map<Uuid, IListingContentItem>;
  readonly loadedAssets: Immutable.Map<Uuid, IAsset>;
  readonly currentLanguageId: Uuid;
  readonly languages: Languages;
}

export interface IRichTextItemElementFriendlyWarningCheckerUtils {
  readonly getImageAssetReferences: IGetReferencesFromContentState<AssetReference>;
  readonly assetsHaveRequiredDescriptions: IAssetsHaveRequiredDescriptions;
  readonly getLinkedAssetIds: IGetReferencesFromContentState;
  readonly getAllContentLinkIds: IGetReferencesFromContentState;
  readonly getModularContentItemIds: IGetReferencesFromContentState;
  readonly getBrokenLinkWarningsWithDetails: IGetBrokenLinkWarningsWithDetails;
  readonly getBrokenModularWarningsWithDetails: IGetBrokenModularWarningsWithDetails;
  readonly getBrokenAssetWarningsWithDetails: IGetBrokenAssetWarningsWithDetails;
}

const getMemoizedResult = memoize.weak(
  (
    brokenModularsWarnings: ReadonlyArray<IFriendlyWarning>,
    brokenAssetWarnings: ReadonlyArray<IFriendlyWarning>,
    brokenLinksWarnings: ReadonlyArray<IFriendlyWarning>,
    hasDescription: boolean,
  ): ItemElementFriendlyWarningResult => ({
    warningMessages: [
      ...brokenModularsWarnings,
      ...brokenAssetWarnings,
      ...brokenLinksWarnings,
      ...(hasDescription ? [] : [MissingAssetDescriptionFriendlyWarningForRichText]),
    ],
  }),
);

// exported just for unit tests
export const richTextItemElementFriendlyWarningCheckerFactory =
  (
    utils: IRichTextItemElementFriendlyWarningCheckerUtils,
  ): IGetItemElementFriendlyWarnings<
    IRichTextItemElement,
    IRichTextItemElementFriendlyWarningCheckerParams
  > =>
  (params: IRichTextItemElementFriendlyWarningCheckerParams): ItemElementFriendlyWarningResult => {
    const { loadedEntries, loadedAssets, itemElement, currentLanguageId, languages } = params;

    const content = itemElement._editorState.getCurrentContent();
    const modularContentItemIds = utils.getModularContentItemIds(content);
    const assetReferences = utils.getImageAssetReferences(content);
    const contentLinkIds = utils.getAllContentLinkIds(content);
    const assetLinkIds = utils.getLinkedAssetIds(content);

    const brokenModularWarnings = utils.getBrokenModularWarningsWithDetails(
      modularContentItemIds,
      loadedEntries,
    );

    const brokenAssetWarnings = utils.getBrokenAssetWarningsWithDetails(
      assetReferences,
      loadedAssets,
    );

    const brokenLinkWarnings = utils.getBrokenLinkWarningsWithDetails(
      contentLinkIds,
      loadedEntries,
      assetLinkIds,
      loadedAssets,
    );

    const assets = assetReferences
      .map((assetReference) => loadedAssets.get(assetReference.id) ?? null)
      .filter(notNull);
    const areAllAssetsWithRequiredDescriptions = utils.assetsHaveRequiredDescriptions(
      assets,
      currentLanguageId,
      languages,
    );

    return getMemoizedResult(
      brokenModularWarnings,
      brokenAssetWarnings,
      brokenLinkWarnings,
      areAllAssetsWithRequiredDescriptions,
    );
  };

export const getRichTextItemElementValidationFriendlyWarning =
  richTextItemElementFriendlyWarningCheckerFactory({
    getImageAssetReferences,
    getLinkedAssetIds,
    assetsHaveRequiredDescriptions,
    getAllContentLinkIds,
    getModularContentItemIds,
    getBrokenLinkWarningsWithDetails,
    getBrokenModularWarningsWithDetails,
    getBrokenAssetWarningsWithDetails,
  });
