import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ISampleContentItemActionData,
  SampleContentItemEventTypes,
} from '../../../../../../_shared/models/TrackUserEventData.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { HotjarTag, tagRecording } from '../../../../../../_shared/utils/hotjarUtils.ts';
import { isSampleContentItem } from '../../../../../../_shared/utils/sampleItemsUtils.ts';
import { isAccountLessThan30DaysOld } from '../../../../../../_shared/utils/trialUtils.ts';
import { isProjectTrial } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

interface ITrackSampleContentItemEventDependencies {
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const isSampleItemInTrialPeriod = (state: IStore): boolean => {
  const {
    data: { user },
    contentApp: { editedContentItem },
    sharedApp: { currentProjectId },
  } = state;

  return (
    !!editedContentItem &&
    isSampleContentItem(editedContentItem.id) &&
    (isProjectTrial(state, currentProjectId) || isAccountLessThan30DaysOld(user.info))
  );
};

export const createTrackSampleContentItemEventAction =
  (deps: ITrackSampleContentItemEventDependencies) =>
  (eventData: ISampleContentItemActionData): ThunkFunction =>
  (dispatch, getState) => {
    if (isSampleItemInTrialPeriod(getState())) {
      dispatch(deps.trackUserEvent(TrackedEvent.SampleContentItemAction, eventData));
    }
  };

export const createTrackSampleContentItemOpened =
  (deps: ITrackSampleContentItemEventDependencies) => (): ThunkFunction => (dispatch, getState) => {
    if (isSampleItemInTrialPeriod(getState())) {
      dispatch(
        deps.trackUserEvent(TrackedEvent.SampleContentItemAction, {
          action: SampleContentItemEventTypes.SampleItemOpened,
        }),
      );
      tagRecording(HotjarTag.SampleContentItemOpened);
    }
  };
