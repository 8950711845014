import { Action } from '../../../@types/Action.type.ts';
import { SubscriptionPropertyServerModel } from '../../../repositories/serverModels/SubscriptionPropertyServerModel.type.ts';
import { Shared_LoadSubscriptionProperties_Finished } from '../../constants/sharedActionTypes.ts';
import {
  SubscriptionPropertiesModel,
  SubscriptionPropertiesStateKeysByServerKeys,
} from '../../models/SubscriptionPropertiesModel.ts';

const initialState: SubscriptionPropertiesModel = {
  AssetCollectionMandatory: null,
  LegacyWebhooks: null,
  SendFinalReport: null,
};

const mapPropertyKeyAndValueToState = (
  state: SubscriptionPropertiesModel,
  propertyKey: string,
  propertyValue: string | null,
): SubscriptionPropertiesModel => {
  const propName: string | undefined = SubscriptionPropertiesStateKeysByServerKeys[propertyKey];
  if (!propName) {
    return state;
  }

  return {
    ...state,
    [propName]: propertyValue,
  };
};

const mapPropertyToState = (
  state: SubscriptionPropertiesModel,
  property: SubscriptionPropertyServerModel,
): SubscriptionPropertiesModel =>
  mapPropertyKeyAndValueToState(state, property.key, property.value);

export const subscriptionProperties = (
  state = initialState,
  action: Action,
): SubscriptionPropertiesModel => {
  switch (action.type) {
    case Shared_LoadSubscriptionProperties_Finished: {
      return action.payload.properties.reduce(mapPropertyToState, initialState);
    }

    default:
      return state;
  }
};
