import { InvariantException } from '@kontent-ai/errors';
import { assert, notNull, notUndefined } from '@kontent-ai/utils';
import {
  ConditionActionServerModel,
  ConditionTriggerTypeServerModel,
  MultipleChoiceConditionOperatorServerModel,
  TypeElementConditionServerModel,
} from '../../../repositories/serverModels/TypeElementConditionServerModel.type.ts';

export type TypeElementCondition = {
  readonly id: Uuid;
  readonly action: ConditionAction;
  readonly isActive: boolean;
  readonly trigger: TypeElementConditionTrigger;
};

export type TypeElementConditionTrigger = {
  readonly elementId: Uuid | null;
  readonly operator: MultipleChoiceConditionOperator;
  readonly optionIds: UuidArray;
};

export enum ConditionAction {
  Hide = 'hide',
  Show = 'show',
}

export enum MultipleChoiceConditionOperator {
  All = 'all',
  Any = 'any',
}

export const emptyTypeElementCondition: TypeElementCondition = {
  action: ConditionAction.Show,
  id: '00000000-0000-0000-0000-000000000000',
  isActive: false,
  trigger: {
    elementId: null,
    operator: MultipleChoiceConditionOperator.Any,
    optionIds: [],
  },
};

export function convertConditionFromServerModel(
  conditions: ReadonlyArray<TypeElementConditionServerModel>,
): TypeElementCondition | null {
  if (conditions === null || conditions.length === 0) {
    return null;
  }

  const condition = conditions[0];

  assert(
    conditions.length === 1 && notNull(condition) && notUndefined(condition),
    () =>
      `${__filename}: Error converting condition from server model. Max one condition is supported.`,
  );

  if (condition.triggers.length === 0) {
    return {
      id: condition.id,
      isActive: condition.isActive,
      action: convertConditionActionFromServerModel(condition.action),
      trigger: emptyTypeElementCondition.trigger,
    };
  }

  const trigger = condition.triggers[0];

  assert(
    condition.triggers.length === 1 && notNull(trigger) && notUndefined(trigger),
    () =>
      `${__filename}: Error converting condition trigger from server model. Max one trigger is supported.`,
  );

  assert(
    trigger.triggerType === ConditionTriggerTypeServerModel.MultipleChoiceTypeElement,
    () =>
      `${__filename}: Error converting condition trigger from server model. Only multiple choice trigger type is supported.`,
  );

  return {
    id: condition.id,
    isActive: condition.isActive,
    action: convertConditionActionFromServerModel(condition.action),
    trigger: {
      elementId: trigger.elementId,
      operator: convertMultipleChoiceOperatorFromServerModel(trigger.operator),
      optionIds: trigger.values,
    },
  };
}

function convertConditionActionFromServerModel(
  actionServerModel: ConditionActionServerModel,
): ConditionAction {
  switch (actionServerModel) {
    case ConditionActionServerModel.Hide:
      return ConditionAction.Hide;
    case ConditionActionServerModel.Show:
      return ConditionAction.Show;
    default:
      throw InvariantException(
        `${__filename}: Error converting condition from server model. Unknown condition action '${actionServerModel}'.`,
      );
  }
}

function convertMultipleChoiceOperatorFromServerModel(
  operatorServerModel: MultipleChoiceConditionOperatorServerModel,
): MultipleChoiceConditionOperator {
  switch (operatorServerModel) {
    case MultipleChoiceConditionOperatorServerModel.All:
      return MultipleChoiceConditionOperator.All;
    case MultipleChoiceConditionOperatorServerModel.Any:
      return MultipleChoiceConditionOperator.Any;
    default:
      throw InvariantException(
        `${__filename}: Error converting condition trigger from server model. Unknown condition trigger operator '${operatorServerModel}'.`,
      );
  }
}
