import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import {
  VerticalMenuItem,
  VerticalMenuSection,
  useVerticalMenu,
} from '@kontent-ai/component-library/VerticalMenu';
import { notNull } from '@kontent-ai/utils';
import { IconName } from '../../../constants/iconEnumGenerated.ts';
import { Icon } from '../../../uiComponents/Icon/Icon.tsx';
import { ActionResults } from '../helpers/transformAiResult.ts';

export type AiMenuItem = VerticalMenuItem<AiMenuItem> & {
  readonly id: string;
  readonly iconName: IconName;
  readonly onClick?: () => void;
};

type AiMenuSection = VerticalMenuSection<AiMenuItem>;

export const createUseSuggestionAiSection = <T extends ActionResults>(
  menuItems: ReadonlyArray<AiMenuItem | null>,
  result: T,
): AiMenuSection | null =>
  result.error && !result.content
    ? null
    : {
        id: 'use-suggestion-ai-section',
        type: 'section',
        items: menuItems.filter(notNull),
      };

export const createRejectSuggestionAiSection = (
  menuItems: ReadonlyArray<AiMenuItem | null>,
): AiMenuSection => ({
  id: 'reject-suggestion-ai-section',
  type: 'section',
  items: menuItems.filter(notNull),
});

export const createReplaceSelectionAiAction = (onClick?: () => void): AiMenuItem | null =>
  onClick
    ? {
        id: 'replace-selection',
        label: 'Replace selection',
        iconName: IconName.Check,
        onClick,
      }
    : null;

export const createCopyToClipboardAiAction = (onClick?: () => void): AiMenuItem | null =>
  onClick
    ? {
        id: 'copy-to-clipboard',
        label: 'Copy to Clipboard',
        iconName: IconName.DocCopy,
        onClick,
      }
    : null;

export const createTryAgainAiAction = <T extends ActionResults>(
  result: T,
  onClick?: () => void,
): AiMenuItem | null =>
  result.error === null && onClick
    ? {
        id: 'try-again',
        label: 'Try again',
        iconName: IconName.RotateDoubleRight,
        onClick,
      }
    : null;

export const createDiscardAiActionFromResult = <T extends ActionResults>(
  onClick: () => void,
  result: T,
): AiMenuItem => {
  return createDiscardAiAction(onClick, result.isFinished, !!result.error);
};

export const createDiscardAiAction = (
  onClick: () => void,
  isActionFinished: boolean,
  isActionFailed: boolean,
): AiMenuItem => {
  const itemBase: Pick<AiMenuItem, 'id' | 'onClick'> = {
    id: 'discard',
    onClick,
  };

  if (!isActionFinished) {
    return {
      ...itemBase,
      label: 'Cancel',
      iconName: IconName.Cancel,
    };
  }
  if (isActionFailed) {
    return {
      ...itemBase,
      label: 'Dismiss',
      iconName: IconName.Cancel,
    };
  }
  return {
    ...itemBase,
    label: 'Discard',
    iconName: IconName.Bin,
  };
};

export const createEditInputsAiAction = (label: string, onClick?: () => void): AiMenuItem | null =>
  onClick
    ? {
        id: 'edit-inputs',
        label,
        iconName: IconName.Edit,
        onClick,
      }
    : null;

type AiActionMenuProps = {
  readonly options: ReadonlyArray<AiMenuSection | null>;
};

export const AiActionMenu = ({ options }: AiActionMenuProps) => {
  const menuOptions = options.filter(notNull).filter((section) => section.items.length > 0);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } =
    useVerticalMenu<AiMenuItem>(menuOptions);

  return (
    <VerticalMenu
      {...verticalMenuProps}
      aria-label="AI action menu"
      contain={false}
      renderItem={({ item }) =>
        item.value && (
          <MenuItemWithMouseHover
            menuItemState="default"
            text={item.value.label}
            leadingElement={<Icon iconName={item.value.iconName} />}
            onPress={item.value.onClick}
          />
        )
      }
      state={verticalMenuState}
    />
  );
};
