import { combineReducers } from '@reduxjs/toolkit';
import { editedPreviewConfiguration } from './internalReducers/editedPreviewConfiguration.ts';
import { previewConfiguration } from './internalReducers/previewConfiguration.ts';
import { previewUrlsSectionErrors } from './internalReducers/previewUrlsSectionErrors.ts';
import { status } from './internalReducers/status.ts';

export const previewConfigurationAppReducer = combineReducers({
  status,
  editedPreviewConfiguration,
  previewConfiguration,
  previewUrlsSectionErrors,
});
