import { combineReducers } from '@reduxjs/toolkit';
import { editedRole } from './editorUi/editedRole.ts';
import { originalEditedRole } from './editorUi/originalEditedRole.ts';
import { status } from './editorUi/status.ts';
import { loadingStatus as listingLoadingStatus } from './listingUi/loadingStatus.ts';
import { operationStatus } from './listingUi/operationStatus.ts';

export const rolesAppReducer = combineReducers({
  editorUi: combineReducers({
    editedRole,
    originalEditedRole,
    status,
  }),
  listingUi: combineReducers({
    loadingStatus: listingLoadingStatus,
    operationStatus,
  }),
});
