import { IRule } from '../../../../data/models/roles/IRoleSettings.ts';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import { NoSelectedTypeErrorMessage } from '../constants/UIConstants.ts';

const areTypesNonEmpty = (rule: IRule): boolean => rule.types.length > 0;

export const getCannotRuleTypesValidationError = (rule: IRule): string | undefined => {
  if (!areTypesNonEmpty(rule)) {
    return NoSelectedTypeErrorMessage;
  }

  return undefined;
};

export const isRoleValid = (role: IRoleWithSettings): boolean =>
  role.settings.cannotRules.every((rule: IRule) => !getCannotRuleTypesValidationError(rule));
