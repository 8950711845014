import { connect } from 'react-redux';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { getCannotViewItemMessage } from '../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { getLinkedContentItemPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getTypeName } from '../../../../../../data/reducers/contentTypes/selectors/getTypeName.ts';
import { CannotCreateNewVariantForItemError } from '../../../../../itemEditor/constants/errorMessages.ts';
import { getItemSmartLink } from '../../../../../smartLink/utils/smartLinkUtils.ts';
import {
  ContentLinkDetail as ContentLinkDetailComponent,
  IContentLinkDetailOwnProps,
  IContentLinkDetailStateProps,
} from '../components/ContentLinkDetail.tsx';

interface IContentLinkDetailContainerProps extends IContentLinkDetailOwnProps {
  readonly currentPath: string;
  readonly entityKey: string;
  readonly itemId: Uuid;
}

const mapStateToProps = (
  state: IStore,
  ownProps: IContentLinkDetailContainerProps,
): IContentLinkDetailStateProps => {
  const contentItem = state.data.listingContentItems.byId.get(ownProps.itemId);
  const contentTypeName = (contentItem && getTypeName(contentItem.item.typeId, state)) ?? null;
  const contentItemPath = ownProps.isViewOnly
    ? getItemSmartLink(ownProps.currentPath, ownProps.itemId)
    : getLinkedContentItemPath(ownProps.currentPath, ownProps.itemId);

  const canCreateContent = contentItem
    ? hasActiveVariantCapability(ActiveCapabilityType.CreateContent, contentItem)
    : false;
  const canViewContent = canUserViewAnyActiveLanguage(state);

  return {
    canCreateContent,
    cannotViewDisabledMessage: contentItem ? getCannotViewItemMessage(contentItem) : undefined,
    cannotCreateDisabledMessage: canCreateContent ? undefined : CannotCreateNewVariantForItemError,
    canViewContent,
    contentItem,
    contentItemPath,
    contentTypeName,
  };
};

export const ContentLinkDetail = connect(mapStateToProps, undefined, undefined, {
  forwardRef: true,
})(ContentLinkDetailComponent);
