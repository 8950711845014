import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { spacingCard } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { LinkLike } from '../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { IntercomUtils } from '../../../../_shared/utils/intercomUtils.ts';
import { WorkflowOverviewState } from '../models/WorkflowOverviewState.ts';
import { IWorkflowOverview } from '../utils/createWorkflowOverview.ts';
import { ItemsWithoutDueDateWarning } from './ItemsWithoutDueDateWarning.tsx';
import { PlanningStatus } from './PlanningStatus.tsx';
import { WorkflowsOverview } from './WorkflowsOverview.tsx';

const planningStatusTitle = 'Planning status';

type Props = {
  readonly delayedItemsCount: number;
  readonly itemsOnTrackCount: number;
  readonly itemsWithoutDueDateCount: number;
  readonly scheduledToPublishItemsCount: number;
  readonly workflowOverviewState: WorkflowOverviewState;
  readonly workflows: readonly IWorkflowOverview[];
};
export const ProjectOverview: React.FC<Props> = ({
  itemsWithoutDueDateCount,
  delayedItemsCount,
  scheduledToPublishItemsCount,
  itemsOnTrackCount,
  workflowOverviewState,
  workflows,
}) => (
  <div className="row">
    <div className="col-xs-24 col-lg-14">
      <Stack spacing={spacingCard}>
        <Card component="section">
          <Card.Headline>{planningStatusTitle}</Card.Headline>
          <Card.Body>
            <PlanningStatus
              delayedItemsCount={delayedItemsCount}
              itemsOnTrackCount={itemsOnTrackCount}
              scheduledToPublishItemsCount={scheduledToPublishItemsCount}
              isLoading={workflowOverviewState === WorkflowOverviewState.Loading}
            />
          </Card.Body>
        </Card>
        <WorkflowsOverview workflowOverviewState={workflowOverviewState} workflows={workflows} />
      </Stack>
    </div>
    <div className="col-md-24 col-lg-10">
      {!!itemsWithoutDueDateCount && (
        <ItemsWithoutDueDateWarning itemsWithoutDueDateCount={itemsWithoutDueDateCount} />
      )}
      <QuickTip hideTitle>
        <p>
          This overview shows only items edited within the past 90 days.&nbsp;
          <LinkLike onClick={() => IntercomUtils.showMessage()}>
            Let us know if you need more.
          </LinkLike>
        </p>
      </QuickTip>
    </div>
  </div>
);

ProjectOverview.displayName = 'ProjectOverview';
