import { Action } from '../../../../../@types/Action.type.ts';
import { LocalizationEditor_LoadItemVariantCount_Finished } from '../../constants/localizationActionTypes.ts';

const initialState: number = 0;

export function activeLanguagesItemVariantCount(state: number = initialState, action: Action) {
  switch (action.type) {
    case LocalizationEditor_LoadItemVariantCount_Finished: {
      return action.payload.itemVariantCount.activeLanguagesItemVariantCount;
    }

    default:
      return state;
  }
}
