import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { DataTableCell, IDataTableCellProps, wrapPlainContentItems } from './DataTableCell.tsx';

interface ILinkDataTableCellProps extends IDataTableCellProps {
  readonly linkPath: string | undefined | null;
  readonly openInNewTab?: boolean;
  /** Aria label for the table cell link and make it focusable — there should be only one focusable table cell per row. */
  readonly focusableRowLinkAriaLabel?: string;
}

export const LinkDataTableCell: React.FC<React.PropsWithChildren<ILinkDataTableCellProps>> = ({
  disabled,
  isMultiline,
  linkPath,
  openInNewTab,
  children,
  className,
  focusableRowLinkAriaLabel,
  ...rest
}) => {
  if (linkPath && !disabled) {
    const shouldFocusRow = !!focusableRowLinkAriaLabel;
    return (
      <DataTableCell
        {...rest}
        className={classNames(className, {
          'data-table__row-select': shouldFocusRow,
        })}
        isMultiline={isMultiline}
        hasLink
      >
        <Link
          aria-label={focusableRowLinkAriaLabel}
          className={classNames('data-table__link', {
            'data-table__link--multiline': isMultiline,
            'data-table__link--outward': openInNewTab,
            'data-table__row-select-link': shouldFocusRow,
          })}
          to={linkPath}
          // Setting tabIndex to -1 when is not provided row focus link, so its not focusable
          tabIndex={shouldFocusRow ? 0 : -1}
          target={openInNewTab ? '_blank' : undefined}
        >
          {wrapPlainContentItems(children)}
        </Link>
      </DataTableCell>
    );
  }

  return (
    <DataTableCell {...rest} disabled={disabled} className={className} isMultiline={isMultiline}>
      {children}
    </DataTableCell>
  );
};
