import { useHotkeys } from '@kontent-ai/component-library/hooks';
import React from 'react';

/**
 * @deprecated Use useHotkeys with excludedElementRefs instead.
 */
export const EnterHotkeyDisabler: React.FC<React.PropsWithChildren> = ({ children }) => {
  const hotkeyProps = useHotkeys({
    enter: (keyboardEvent) => {
      keyboardEvent.stopPropagation();
    },
  });

  return <div {...hotkeyProps}>{children}</div>;
};
