import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadAdministratedSubscriptions } from '../../../../data/actions/thunkDataActions.ts';
import {
  loadSubscriptionUsage,
  loadSubscriptionUsers,
} from '../../shared/actions/thunkSubscriptionManagementActions.ts';
import { failInvitingNewAdmin } from './adminsActions.ts';
import { inviteNewAdminCreator } from './thunks/createAdmin.ts';
import { createDeactivateUsersOnSubscriptionProjectsAction } from './thunks/deactivateUsersOnSubcriptionProjects.ts';
import { deleteAdminsCreator } from './thunks/deleteAdmins.ts';
import { promoteUsersCreator } from './thunks/promoteUsers.ts';
import { resendInvitationToAdminCreator } from './thunks/resendInvitationToAdmin.ts';
import { revokeAdminCreator } from './thunks/revokeAdmin.ts';

const { peopleRepository, subscriptionRepository } = repositoryCollection;

export const inviteNewAdmin = inviteNewAdminCreator({
  subscriptionRepository,
  failInvitingNewAdmin,
  loadSubscriptionUsage,
});

export const promoteUsers = promoteUsersCreator({
  subscriptionRepository,
  loadAdministratedSubscriptions,
});

const deactivateUsersOnSubscriptionProjects = createDeactivateUsersOnSubscriptionProjectsAction({
  peopleRepository,
  loadSubscriptionUsers,
  loadSubscriptionUsage,
});

export const deleteAdmins = deleteAdminsCreator({
  subscriptionRepository,
  deactivateUsersOnSubscriptionProjects,
  loadSubscriptionUsers,
});

export const revokeAdmin = revokeAdminCreator({
  subscriptionRepository,
  loadSubscriptionUsage,
  loadSubscriptionUsers,
});

export const resendInvitationToAdmin = resendInvitationToAdminCreator({
  subscriptionRepository,
});
