import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../../../_shared/models/SidebarNames.ts';
import { getSelectedSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { cancelPromotingUsers } from '../../actions/adminsActions.ts';
import { promoteUsers } from '../../actions/thunkAdminsActions.ts';
import { PromoteUsersModal as Component } from '../../components/promoteUsersModal/PromoteUsersModal.tsx';

export const PromoteUsersModal = () => {
  const selectedSubscriptionId = useSelector(
    (s) => getSelectedSubscription(s)?.subscriptionId ?? '',
  );

  const showSidebar = useSelector((s) => {
    const { isVisible, sidebarName } = s.sharedApp.sidebarStatus;
    return isVisible && sidebarName === SidebarNames.PromoteUsersModal;
  });

  const selectedSubscriptionUsers = useSelector(
    (s) => s.subscriptionApp.admins.selectedSubscriptionUsers,
  );
  const promoteUsersErrorMessage = useSelector(
    (s) => s.subscriptionApp.admins.promoteUsersErrorMessage,
  );

  const promotingSubscriptionAdmin = useSelector((s) => {
    const { promotingSubscriptionAdmins, sendingSubscriptionAdminInvitation } =
      s.subscriptionApp.admins;
    return promotingSubscriptionAdmins || sendingSubscriptionAdminInvitation;
  });

  const dispatch = useDispatch();

  return (
    <Component
      showSidebar={showSidebar}
      selectedSubscriptionUsers={selectedSubscriptionUsers}
      subscriptionId={selectedSubscriptionId}
      promotingSubscriptionAdmin={promotingSubscriptionAdmin}
      errorMessage={promoteUsersErrorMessage}
      closeDialog={() => dispatch(cancelPromotingUsers())}
      promoteUsers={(subscriptionId: Uuid, userIds: ReadonlySet<UserId>) =>
        dispatch(promoteUsers(subscriptionId, userIds))
      }
    />
  );
};

PromoteUsersModal.displayName = 'PromoteUsersModal';
