import { Action } from '../../../../../@types/Action.type.ts';
import {
  AssetLibrary_Assets_CreateAssetsFromEditing,
  AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers,
  AssetLibrary_Assets_UploadFailed,
  AssetLibrary_Assets_UploadFinished,
} from '../../../../contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { ContentItemEditing_Changes_Discarded } from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlyArray<Uuid> = [];

export function editedContentItemVariantUploadingAssets(
  state = initialState,
  action: Action,
): ReadonlyArray<Uuid> {
  switch (action.type) {
    case AssetLibrary_Assets_CreateAssetsFromEditing: {
      return [...state, ...action.payload.files];
    }

    case AssetLibrary_Assets_RemoveTemporaryAssetIdentifiers: {
      return state.filter((tempId) => !action.payload.oldAssetIds.find((id) => id === tempId));
    }

    case AssetLibrary_Assets_UploadFinished:
    case AssetLibrary_Assets_UploadFailed: {
      return state.filter((tempId) => tempId !== action.payload.oldAssetId);
    }

    case ContentItemEditing_Changes_Discarded:
      return initialState;

    default:
      return state;
  }
}
