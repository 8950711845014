import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  IPopoverTexts,
  IUserActiveStatus,
  UserActiveStatusToggleWithConfirmationDialog,
} from '../../../../_shared/components/UserActiveStatusToggleWithConfirmationDialog.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { SubscriptionPlanMaxUserLimitReachedWarning } from '../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IntercomUtils } from '../../../../_shared/utils/intercomUtils.ts';
import {
  getNumberOfUsersInActivation,
  getSubscriptionUsage,
  hasAvailableUserSlots,
  hasToUsePaidUserSlot,
  isUserCountedInUsage,
} from '../../../../_shared/utils/subscriptionUsageUtils.ts';
import { emptySubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { redirectToPlanSelection } from '../../shared/utils/subscriptionManagementPathUtils.ts';
import { triggerUserActiveStateOnAllSubscription } from '../actions/thunkSubscriptionUsersActions.ts';
import { ISubscriptionUserViewModel } from '../components/SubscriptionUsersScrollTableRow.tsx';

interface ISubscriptionUserStatusCellContentOwnProps {
  readonly subscriptionUserViewModel: ISubscriptionUserViewModel;
}

const getSubscription = (state: IStore) => getSelectedSubscription(state) ?? emptySubscription;

const getPopoverTexts = (isActiveGlobal: boolean): IPopoverTexts => {
  const toggleAction = isActiveGlobal ? 'Deactivate' : 'Activate';

  return {
    headlineText: `${toggleAction} user in all projects?`,
    primaryActionText: `${toggleAction} in all projects`,
  };
};

const getIsUserMaxLimitReached = (s: IStore, subscriptionId: Uuid, userId: Uuid) => {
  const subscriptionUsages = s.data.subscriptions.subscriptionUsages;
  const subscriptionUsage = getSubscriptionUsage(subscriptionUsages, subscriptionId);
  const plans = s.data.plans.byId;
  const numberOfUsersInActivation = getNumberOfUsersInActivation(s);

  return (
    !isUserCountedInUsage(s, userId) &&
    !hasAvailableUserSlots(subscriptionUsage, plans, numberOfUsersInActivation)
  );
};

const getIsUserIncludedUnitsReached = (s: IStore, userId: Uuid, email: string) =>
  !isUserCountedInUsage(s, userId) && hasToUsePaidUserSlot(s, [email]);

export const SubscriptionUserStatusCellContent: React.FC<
  ISubscriptionUserStatusCellContentOwnProps
> = ({ subscriptionUserViewModel }) => {
  const isRequiredStateLoaded = useSelector((s) => !!getSelectedSubscription(s));

  const {
    email,
    fullName,
    isActiveInAllProjects,
    isActivating,
    isDeactivating,
    isInvitationPending,
    isEmployee,
    isSubscriptionAdmin,
    userId,
  } = subscriptionUserViewModel;

  const dispatch = useDispatch();
  const history = useHistory();

  const subscriptionId = useSelector(getSubscription).subscriptionId;
  const isCustomPlan = useSelector((s) => getSubscriptionPlan(s, subscriptionId).isCustom);
  const isUserIncludedUnitsReached = useSelector((s) =>
    getIsUserIncludedUnitsReached(s, userId, email),
  );
  const isUserMaxLimitReached = useSelector((s) =>
    getIsUserMaxLimitReached(s, subscriptionId, userId),
  );

  const disabled = isSubscriptionAdmin || isActivating || isDeactivating || isInvitationPending;

  const popoverTexts = getPopoverTexts(isActiveInAllProjects);

  const userActiveStatus: IUserActiveStatus = {
    isActive: isActiveInAllProjects,
    isActivating,
    isDeactivating,
    isInvitationPending,
  };

  const activatingUserOverMaxLimit = !isEmployee && !isActiveInAllProjects && isUserMaxLimitReached;
  const activatingUserOverIncludedUnitsLimit =
    !isEmployee && !isActiveInAllProjects && isUserIncludedUnitsReached;

  const onSelectPlan = useCallback(
    () =>
      redirectToPlanSelection({
        history,
        subscriptionId,
      }),
    [history, subscriptionId],
  );

  const onShowIntercom = useCallback(() => {
    IntercomUtils.showIntercom();
    IntercomUtils.showMessage();
  }, []);

  const onToggleUserActiveState = () =>
    dispatch(
      triggerUserActiveStateOnAllSubscription(userId, subscriptionId, !isActiveInAllProjects, true),
    );

  return isRequiredStateLoaded ? (
    <UserActiveStatusToggleWithConfirmationDialog
      activatingUserOverMaxLimit={activatingUserOverMaxLimit}
      disabled={disabled}
      isCurrentUserAdmin
      isCustomPlan={isCustomPlan}
      popoverTexts={popoverTexts}
      showConfirmationDialog
      userActiveStatus={userActiveStatus}
      onSelectPlan={onSelectPlan}
      onShowIntercom={onShowIntercom}
      onToggleUserActiveState={onToggleUserActiveState}
      renderPopoverDialogContent={() => {
        return activatingUserOverMaxLimit ? (
          <SubscriptionPlanMaxUserLimitReachedWarning hideButton />
        ) : (
          <Stack spacing={Spacing.M} css="word-break: break-word">
            <p>
              Are you sure you want to {isActiveInAllProjects ? 'deactivate' : 'activate'} user{' '}
              <strong>{fullName}</strong> in all projects?
            </p>
            {activatingUserOverIncludedUnitsLimit && (
              <SubscriptionPlanIncludedUserLimitReachedWarning
                newEmails={[email]}
                isInActivationMode
              />
            )}
          </Stack>
        );
      }}
    />
  ) : null;
};

SubscriptionUserStatusCellContent.displayName = 'SubscriptionUserStatusCellContent';
