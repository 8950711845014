import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { Link } from 'react-router-dom';

type OpenItemInANewTabLinkProps = {
  readonly hasViewPermission: boolean;
  readonly linkPath: string;
};

const ContentItemVariantLinkButton: React.FC<
  Pick<OpenItemInANewTabLinkProps, 'hasViewPermission'>
> = ({ hasViewPermission }) => (
  <QuinaryButton
    onClick={noOperation}
    tooltipText={
      hasViewPermission ? 'Open in new tab' : 'You don’t have permissions to view this variant'
    }
    disabled={!hasViewPermission}
  >
    <QuinaryButton.Icon icon={Icons.ArrowRightTopSquare} screenReaderText="Open in new tab" />
  </QuinaryButton>
);

ContentItemVariantLinkButton.displayName = 'ContentItemVariantLinkButton';

export const OpenItemInANewTabLink: React.FC<OpenItemInANewTabLinkProps> = ({
  hasViewPermission,
  linkPath,
}) =>
  hasViewPermission ? (
    <Link to={linkPath} target="_blank">
      <ContentItemVariantLinkButton hasViewPermission />
    </Link>
  ) : (
    <ContentItemVariantLinkButton hasViewPermission={false} />
  );

OpenItemInANewTabLink.displayName = 'OpenItemInANewTabLink';
