import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { getProjectDetail } from '../../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { CopyType } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import { ProjectStatusPollingInterval } from '../../constants/copyState.ts';
import { ICopyProjectDataInfo } from '../../models/ICopyProjectDataInfo.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import {
  ICanProjectBeCopied,
  IGetCopyProjectValidationData,
} from '../../utils/copyProjectUtils.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  failCreatingNewProject,
  finishCreatingTemplateProject,
  setProjectDataInfo,
  startCreatingNewProject,
} from '../createProjectActions.ts';
import { projectCreationInitialized } from '../projectsActions.ts';
import { startPollingCloningState } from './cloneProject.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
  readonly getCopyProjectValidationData: IGetCopyProjectValidationData;
  readonly canProjectBeCopied: ICanProjectBeCopied;
}

const isCopyProjectDataInfoValid = async (
  deps: IDeps,
  sourceProjectId: Uuid,
  targetSubscriptionId: Uuid,
  dispatch: Dispatch,
  getState: GetState,
): Promise<boolean> => {
  const copyProjectDataInfo: ICopyProjectDataInfo =
    await deps.projectRepository.getProjectDataInfo(sourceProjectId);
  dispatch(setProjectDataInfo(copyProjectDataInfo));

  const templateValidationResult = deps.getCopyProjectValidationData(
    getState(),
    sourceProjectId,
    targetSubscriptionId,
  );

  if (!deps.canProjectBeCopied(templateValidationResult)) {
    const templateContentLimitExceededData = {
      'project-template-id': sourceProjectId,
      'has-custom-type-elements': copyProjectDataInfo.containsCustomElements,
    };
    dispatch(
      deps.trackUserEventWithData(
        TrackedEvent.TemplateContentLimitExceeded,
        templateContentLimitExceededData,
      ),
    );

    return false;
  }

  return true;
};

const getSourceProject = (
  dispatch: Dispatch,
  state: IStore,
  project: INewProjectFormShape,
): IProjectDetails | null => {
  if (!project.projectTemplateId) {
    dispatch(failCreatingNewProject('Project template is not defined.'));
    return null;
  }

  const sourceProject = getProjectDetail(state, project.projectTemplateId);
  if (!sourceProject) {
    dispatch(failCreatingNewProject('Source project template is not loaded.'));
    return null;
  }

  return sourceProject;
};

export const createCreateProjectFromTemplateAction =
  (deps: IDeps) =>
  (project: INewProjectFormShape): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const sourceProject = getSourceProject(dispatch, getState(), project);
    if (!sourceProject) {
      return;
    }

    try {
      dispatch(startCreatingNewProject());

      if (
        !(await isCopyProjectDataInfoValid(
          deps,
          sourceProject.projectId,
          project.subscriptionId,
          dispatch,
          getState,
        ))
      ) {
        dispatch(failCreatingNewProject());
        return;
      }

      const createdProject = await deps.projectRepository.copyProject(project.projectTemplateId, {
        projectName: project.projectName,
        destinationLocationId: project.projectLocationId,
        destinationSubscriptionId: project.subscriptionId,
        includeContent: project.includeContent,
        copyType: CopyType.Template,
      });
      await waitUntilProjectIsActive(createdProject.projectGuid, deps.projectRepository);
      dispatch(projectCreationInitialized(createdProject.projectGuid));
      await dispatch(deps.loadProjects());
      await dispatch(deps.loadUserProjectsInfo());
      dispatch(
        startPollingCloningState(
          deps.projectRepository,
          createdProject.projectGuid,
          ProjectStatusPollingInterval,
          deps.loadProjects,
        ),
      );
      dispatch(finishCreatingTemplateProject(createdProject.projectGuid));

      // if you are going to change the code below, please, consider whether you should make the same changes in createEmptyProject.ts
      dispatch(
        deps.trackUserEventWithData(TrackedEvent.ProjectCreated, {
          'project-template-id': project.projectTemplateId,
        }),
      );
    } catch (error) {
      dispatch(
        failCreatingNewProject('The project wasn’t created as we’re unable to reach the servers.'),
      );
      throw error;
    }
  };
