import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IListingContentItems } from '../IListingContentItems.type.ts';

export const isItemsInitialLoadFinished = (
  listingContentItems: Pick<IListingContentItems, 'allIds' | 'loadingStatus'>,
): boolean => {
  return (
    listingContentItems.loadingStatus !== LoadingStatus.InitialEmpty && !!listingContentItems.allIds
  );
};
