import { useEffect, useRef } from 'react';

export function useEventListener<TEventName extends keyof HTMLElementEventMap>(
  eventName: TEventName,
  handler: (event: HTMLElementEventMap[TEventName]) => void,
  element: Element | HTMLElement | Window | Document | MediaQueryList | null | undefined,
  options?: boolean | AddEventListenerOptions,
): void;
export function useEventListener<TEvent extends Event>(
  eventName: keyof HTMLElementEventMap,
  handler: (event: TEvent) => void,
  element: Element | HTMLElement | Window | Document | MediaQueryList | null | undefined,
  options?: boolean | AddEventListenerOptions,
): void {
  const savedHandler = useRef<(event: TEvent) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!element || !element.addEventListener) {
      return;
    }

    const eventListener = (event: TEvent) => savedHandler.current?.(event);

    element.addEventListener(eventName, eventListener, options);

    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, element, options]);
}
