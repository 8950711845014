import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { isProjectManagerInAnyEnvironment } from '../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { isPatApiKey } from './tokenTypeSelectors.ts';

export const canRevokeApiKey = (state: IStore): boolean =>
  isProjectManagerInAnyEnvironment(state) || canRevokePat(state);

const canRevokePat = (state: IStore): boolean =>
  isPatApiKey(state) && !isEmptyOrWhitespace(state.data.apiKeys.keyDetail.apiKey);
