import { Capability } from '../../../../_shared/utils/permissions/capability.ts';

export const contentCanRuleCapabilityOptions: ReadonlyArray<ReadonlyArray<Capability>> = [
  [Capability.ViewContent],
  [Capability.ViewContent, Capability.UpdateContent],
  [Capability.ViewContent, Capability.UpdateContent, Capability.CreateContent],
  [Capability.ViewContent, Capability.UpdateContent, Capability.DeleteContent],
  [
    Capability.ViewContent,
    Capability.UpdateContent,
    Capability.CreateContent,
    Capability.DeleteContent,
  ],
];

export const assetCanRuleCapabilityOptions: ReadonlyArray<ReadonlyArray<Capability>> = [
  [Capability.ViewAssets],
  [Capability.ViewAssets, Capability.UpdateAssets],
  [Capability.ViewAssets, Capability.UpdateAssets, Capability.CreateAssets],
  [Capability.ViewAssets, Capability.UpdateAssets, Capability.DeleteAssets],
  [
    Capability.ViewAssets,
    Capability.UpdateAssets,
    Capability.CreateAssets,
    Capability.DeleteAssets,
  ],
];

export const cannotRuleCapabilityOptions: ReadonlyArray<ReadonlyArray<Capability>> = [
  [Capability.CreateContent],
  [Capability.DeleteContent],
  [Capability.DeleteContent, Capability.CreateContent],
  [Capability.DeleteContent, Capability.CreateContent, Capability.UpdateContent],
  [
    Capability.DeleteContent,
    Capability.CreateContent,
    Capability.UpdateContent,
    Capability.ViewContent,
  ],
];
