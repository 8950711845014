import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorBackground,
  colorPrimary,
  colorTextHint,
  colorToggleIcon,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Toggle } from '../../../../_shared/components/Toggle.tsx';

type RatingRadioButtonProps = React.PropsWithChildren &
  Readonly<{
    description?: string;
    isChecked: boolean;
    name: string;
    value: string;
    onChange: (value: string) => void;
  }>;

export const RatingRadioButton: React.FC<RatingRadioButtonProps> = ({
  children,
  description,
  isChecked,
  name,
  value,
  onChange,
}) => {
  const onToggle = () => {
    onChange(value);
  };

  return (
    <Box className="rating-radio-button">
      <ToggleRatingRadioButton
        name={name}
        label={children}
        value={value}
        checked={isChecked}
        onToggle={onToggle}
      />

      {description ? (
        <Box
          className="rating-radio-button__description"
          typography={Typography.LabelMedium}
          color={colorTextHint}
          textAlign="center"
          onClick={onToggle}
        >
          {description}
        </Box>
      ) : null}
    </Box>
  );
};

type ToggleRatingRadioButtonProps = Readonly<{
  checked: boolean;
  label: React.ReactNode;
  name: string;
  value: string;
  onToggle: () => void;
}>;

const ToggleRatingRadioButton: React.FC<ToggleRatingRadioButtonProps> = ({
  checked,
  label,
  name,
  value,
  onToggle,
}) => {
  const labelWithRadioIcon = (
    <>
      <Box marginBottom={Spacing.S}>
        <RadioButtonIcon checked={checked} />
      </Box>
      {label}
    </>
  );

  return (
    <Toggle
      labelClassName="rating-radio-button__label"
      spanClassName="rating-radio-button__label__text"
      id={`${name}-${value}`}
      name={name}
      label={labelWithRadioIcon}
      type="radio"
      checked={checked}
      onToggle={onToggle}
    />
  );
};

type RadioButtonIconProps = Readonly<{
  checked: boolean;
}>;
const RadioButtonIcon: React.FC<RadioButtonIconProps> = ({ checked }) => {
  const checkboxIcon = checked ? (
    <Icons.RbCheck color={colorPrimary} />
  ) : (
    <Icons.RbUncheck color={colorToggleIcon} />
  );

  return (
    <Box borderRadius={BorderRadius.Pill} backgroundColor={colorBackground}>
      {checkboxIcon}
    </Box>
  );
};
