export enum StatusMessageTypes {
  Deleting = 'Deleting',
  Invalid = 'Invalid',
  Offline = 'Offline',
  Saved = 'Saved',
  Saving = 'Saving',
  ServerError = 'ServerError',
  Unsaved = 'Unsaved',
  UploadingAsset = 'UploadingAsset',
}
