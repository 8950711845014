import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Caption } from '../../../../../../../_shared/components/DateTimeWithTimeZonePicker/Caption.tsx';
import { TimeZoneSelect } from '../../../../../../../_shared/components/TimeZoneSelect/TimeZoneSelect.tsx';
import {
  DateTimePickerInElement,
  DateTimePickerInElementProps,
} from './DateTimePickerInElement.tsx';

type DateTimeInputsProps = DateTimePickerInElementProps;

export const DateTimeInputs: React.FC<DateTimeInputsProps> = (props) => (
  <Stack spacing={Spacing.S}>
    <Inline spacingX={Spacing.S}>
      <DateTimePickerInElement
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        onChange={props.onChange}
        elementData={props.elementData}
        datetimePickerRef={props.datetimePickerRef}
      />
      <TimeZoneSelect
        selectedTimeZoneId={props.elementData.displayTimeZone}
        isDisabled={props.disabled}
        onChange={(tz) => props.onChange(props.elementData._dateTime, tz)}
      />
    </Inline>
    <Caption
      renderStyledText={(text) => <Label size={LabelSize.M}>{text}</Label>}
      date={props.elementData.value}
      timeZone={props.elementData.displayTimeZone}
    />
  </Stack>
);

DateTimeInputs.displayName = 'DateTimeInputs';
