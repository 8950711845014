import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ExpandedAssetElement } from '../components/ExpandedAssetElement.tsx';
import { ExpandedDateTimeElement } from '../components/ExpandedDateTimeElement.tsx';
import { ExpandedGuidelinesElement } from '../components/ExpandedGuidelinesElement.tsx';
import { ExpandedItemRichTextElement } from '../components/ExpandedItemRichTextElement.tsx';
import { ExpandedLinkedItemsElement } from '../components/ExpandedLinkedItemsElement.tsx';
import { ExpandedMultipleChoiceElement } from '../components/ExpandedMultipleChoiceElement.tsx';
import { ExpandedNumberElement } from '../components/ExpandedNumberElement.tsx';
import { ExpandedTaxonomyElement } from '../components/ExpandedTaxonomyElement.tsx';
import { ExpandedTextElement } from '../components/ExpandedTextElement.tsx';
import { expandedItemElementComponentLocator } from './expandedItemElementComponentLocator.ts';

export const bootstrapExpandedElementComponents = () => {
  expandedItemElementComponentLocator
    .setGuidelines(ExpandedGuidelinesElement)
    .setComponent(ElementType.Asset, ExpandedAssetElement)
    .setComponent(ElementType.Subpages, ExpandedLinkedItemsElement)
    .setComponent(ElementType.DateTime, ExpandedDateTimeElement)
    .setComponent(ElementType.LinkedItems, ExpandedLinkedItemsElement)
    .setComponent(ElementType.MultipleChoice, ExpandedMultipleChoiceElement)
    .setComponent(ElementType.Number, ExpandedNumberElement)
    .setComponent(ElementType.RichText, ExpandedItemRichTextElement)
    // Custom element is handled directly in ExpandedItemElements.tsx
    .setComponent(ElementType.Text, ExpandedTextElement)
    .setComponent(ElementType.Taxonomy, ExpandedTaxonomyElement)
    .setComponent(ElementType.UrlSlug, ExpandedTextElement);
};
