import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getEmptyInventoryInfoMessage } from '../../../../contentInventory/shared/utils/getEmptyInventoryInfoMessage.ts';

type Props = {
  readonly canCreate: boolean;
  readonly inventoryIsEmpty: boolean;
  readonly isFilterEmpty: boolean;
  readonly isMultilingualProject: boolean;
  // KCL-12266 Remove isSpaceAndCollectionMismatched during April
  readonly isSpaceAndCollectionMismatched: boolean;
  readonly noFilteredResults: boolean;
  readonly onClearFilter: () => void;
  readonly onClearSearchPhrase: () => void;
  readonly onCreateNewItem: () => void;
  readonly searchPhrase: string;
  readonly selectedLanguageName: string;
};

const _getScrollTableInfoMessage = (props: Props) => {
  if (props.noFilteredResults || props.isSpaceAndCollectionMismatched) {
    const buttonText = props.searchPhrase ? 'Clear your search phrase' : 'Clear filters';

    const handleButtonClick = props.searchPhrase ? props.onClearSearchPhrase : props.onClearFilter;
    const description = props.isSpaceAndCollectionMismatched
      ? 'The selected combination of spaces and collections doesn’t share any content. Try a different combination of filters.'
      : getEmptyInventoryInfoMessage({
          isFilterEmpty: props.isFilterEmpty,
          isSearchPhraseEmpty: !props.searchPhrase,
        });

    return (
      <EmptyState>
        <EmptyState.Title>We couldn’t find any match.</EmptyState.Title>
        <EmptyState.Content>{description}</EmptyState.Content>
        <EmptyState.Footer>
          <Button
            buttonStyle="primary"
            onClick={handleButtonClick}
            {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
          >
            {buttonText}
          </Button>
        </EmptyState.Footer>
      </EmptyState>
    );
  }
  if (props.inventoryIsEmpty) {
    if (props.canCreate) {
      return (
        <EmptyState>
          <EmptyState.Title>It’s kind of lonely here.</EmptyState.Title>
          <EmptyState.Content>
            Bring your project to life, start creating eye-catching content.
          </EmptyState.Content>
          <EmptyState.Footer>
            <Button
              buttonStyle="primary"
              onClick={props.onCreateNewItem}
              {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
            >
              Create new content item
            </Button>
          </EmptyState.Footer>
        </EmptyState>
      );
    }

    const text = props.isMultilingualProject
      ? `You have no content to view in the ${props.selectedLanguageName} language variant.`
      : 'You have no content to view';

    return (
      <EmptyState>
        <EmptyState.Title>Everything’s done!</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>{text}</p>
            <p>Sit back and wait for your content contributors to send you content.</p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
      </EmptyState>
    );
  }

  return null;
};

export const ContentStatusScrollTableEmptyState: React.FC<Props> = (props) => (
  <div {...getDataUiCollectionAttribute(DataUiCollection.ContentItems)}>
    {_getScrollTableInfoMessage(props)}
  </div>
);

ContentStatusScrollTableEmptyState.displayName = 'ContentStatusScrollTableEmptyState';
