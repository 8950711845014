/**
 * Creates an object composed of the picked object properties.
 * @param obj The source object
 * @param keys The property paths to pick
 */
export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> =>
  ({
    ...keys.reduce(
      (result, key) => ({
        ...result,
        [key]: obj[key],
      }),
      {},
    ),
  }) as Pick<T, K>;
