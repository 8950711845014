import { IMultipleChoiceTypeElement } from '../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { validElementWarningResult } from '../../constants/validElementWarningResults.ts';
import { MultipleChoiceIsRequiredWarning } from '../../constants/warningMessageTemplates.ts';
import { removeNonexistingMultipleChoiceOptions } from '../../features/ContentItemEditing/utils/multipleChoiceOptionsUtils.ts';
import { IMultipleChoiceItemElement } from '../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { ItemElementWarningResult, emptyItemElementWarningResult } from './types/Warnings.ts';

const isRequiredLimitValid = (
  typeElement: IMultipleChoiceTypeElement,
  itemElement: IMultipleChoiceItemElement,
): boolean => {
  if (typeElement.isRequired) {
    const { options } = removeNonexistingMultipleChoiceOptions(typeElement, itemElement);
    return !!options && options.length > 0;
  }

  return true;
};

interface IParams {
  readonly typeElement: IMultipleChoiceTypeElement;
  readonly itemElement: IMultipleChoiceItemElement;
}

const invalidElementResult: ItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  requiredMessage: MultipleChoiceIsRequiredWarning,
};

export const getMultipleChoiceItemElementValidationWarning = ({
  typeElement,
  itemElement,
}: IParams): ItemElementWarningResult => {
  const isRequiredLimitMet = isRequiredLimitValid(typeElement, itemElement);

  return isRequiredLimitMet ? validElementWarningResult : invalidElementResult;
};
