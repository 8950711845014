import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  Taxonomy_App_Initialized,
  Taxonomy_Groups_SelectionChanged,
} from '../../constants/taxonomyActionTypes.ts';

const defaultState = Immutable.Set<Uuid>();

export function selectedTaxonomyGroupIds(
  state = defaultState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case Taxonomy_App_Initialized:
      return defaultState;

    case Taxonomy_Groups_SelectionChanged:
      return action.payload.selectedTaxonomyGroupIds;

    default:
      return state;
  }
}
