import { ITaxonomyTermServerModel } from '../serverModels/contentModels/TaxonomyGroupServerModel.type.ts';

export enum TaxonomyPatchOperationType {
  Add = 'add',
  Move = 'move',
  Remove = 'remove',
  Replace = 'replace',
}

export interface ITaxonomyPatchOperationValue {
  readonly name?: string;
  readonly codename?: string;
  readonly archived?: boolean;
  readonly nodes?: ITaxonomyTermServerModel[];
  readonly node?: ITaxonomyTermServerModel;
}

export interface ITaxonomyPatchOperation {
  readonly after?: Uuid;
  readonly before?: Uuid;
  readonly operation: TaxonomyPatchOperationType;
  readonly reference?: Uuid | null;
  readonly source?: Uuid;
  readonly value?: ITaxonomyPatchOperationValue;
}

export type TaxonomyPatchDocument = {
  readonly operations: readonly ITaxonomyPatchOperation[];
};

export const createTaxonomyPatchDocument = (
  operation: ITaxonomyPatchOperation,
): TaxonomyPatchDocument => {
  return {
    operations: [operation],
  };
};
