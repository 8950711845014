import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  DuplicationOptions as DuplicationOptionsComponent,
  DuplicationOptionsOwnProps,
} from '../../../components/editingActions/leafActionForms/DuplicationOptions.tsx';
import { getAvailableContentItemEditingActions } from '../../../utils/editingActions/getAvailableContentItemEditingActions.ts';

export const DuplicationOptions: React.FC<DuplicationOptionsOwnProps> = ({ actionOrigin }) => {
  const location = useLocation();

  const availableActions = useSelector((s) =>
    getAvailableContentItemEditingActions(s, location.pathname),
  );

  return (
    <DuplicationOptionsComponent actionOrigin={actionOrigin} availableActions={availableActions} />
  );
};

DuplicationOptions.displayName = 'DuplicationOptions';
