export const EntityWebhooks_EnableWebhook_Finished = 'EntityWebhooks_EnableWebhook_Finished';

export const EntityWebhooks_DisableWebhook_Finished = 'EntityWebhooks_DisableWebhook_Finished';

export const EntityWebhooks_ResetWebhook_Finished = 'EntityWebhooks_ResetWebhook_Finished';

export const EntityWebhooks_EntityWebhook_InsertCompleted =
  'EntityWebhooks_EntityWebhook_InsertCompleted';
export const EntityWebhooks_EntityWebhook_UpdateCompleted =
  'EntityWebhooks_EntityWebhook_UpdateCompleted';

export const EntityWebhooks_InitEdit_Completed = 'EntityWebhooks_InitEdit_Completed';
export const EntityWebhooks_InitEdit_Started = 'EntityWebhooks_InitEdit_Started';
export const EntityWebhooks_InitCreate_Completed = 'EntityWebhooks_InitCreate_Completed';
export const EntityWebhooks_InitCreate_Started = 'EntityWebhooks_InitCreate_Started';
export const EntityWebhooks_Editing_NameChanged = 'EntityWebhooks_Editing_NameChanged';
