import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import {
  areContentCollectionsEnabledForCurrentProjectPlan,
  areContentCollectionsPermissionsEnabledForCurrentProjectPlan,
  areSpacesInCollectionsEnabled,
} from '../../applications/environmentSettings/selectors/allowedFeaturesUtils.ts';
import { CollectionsMap, ICollection } from '../../data/models/collections/Collection.ts';
import { getCurrentProjectId } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { Capability } from '../utils/permissions/capability.ts';
import { filterCollectionsByCapability } from '../utils/permissions/filterCollectionsByCapability.ts';

export const getCollections = memoize.maxOne((collectionsMap: CollectionsMap) =>
  Collection.getValues(collectionsMap),
);

export const areCollectionPermissionsEnabledForCurrentProject = (state: IStore): boolean =>
  areContentCollectionsEnabledForCurrentProjectPlan(state) &&
  areContentCollectionsPermissionsEnabledForCurrentProjectPlan(state);

export const areCollectionsVisibleForCurrentUser = (state: IStore): boolean => {
  const {
    data: { collections, user },
  } = state;

  const projectId = getCurrentProjectId(state);
  const collectionsWithViewCapability = filterCollectionsByCapability(
    Capability.ViewContent,
    collections.byId,
    projectId,
    user,
  );

  return collectionsWithViewCapability.length > 1;
};

export const isCollectionsConfigurationVisible = (
  state: IStore,
  collections: ReadonlyArray<ICollection>,
): boolean => areContentCollectionsEnabledForCurrentProjectPlan(state) || collections.length > 1;

export const areCollectionsVisibleForAssets = (
  state: IStore,
  collections: ReadonlyArray<ICollection>,
): boolean => isCollectionsConfigurationVisible(state, collections);

export const areCollectionsVisibleForSpaces = (
  state: IStore,
  collections: ReadonlyArray<ICollection>,
): boolean =>
  isCollectionsConfigurationVisible(state, collections) && areSpacesInCollectionsEnabled(state);
