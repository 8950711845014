import { useEffect, useState } from 'react';
import { upsertUserProperty } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IRelativeInsertPosition } from '../../../../../_shared/models/SmartLinkCommand.ts';
import { PossiblyIncorrectPlacementWarningDismissedServerKey } from '../../../../../_shared/models/UserPropertiesServerKeys.ts';
import {
  ModalDialog,
  ModalDialogSize,
} from '../../../../itemEditor/features/ContentItemEditing/components/modalDialog/ModalDialog.tsx';
import { PossiblyIncorrectPlacementWarningFooter } from '../components/PossiblyIncorrectPlacementWarningFooter.tsx';

export enum PossiblyIncorrectPlacementWarningType {
  ContentComponent = 'component',
  LinkedItem = 'item',
}

type Props = {
  readonly insertPosition: IRelativeInsertPosition;
  readonly onClose: () => void;
  readonly warningType: PossiblyIncorrectPlacementWarningType;
};

export const PossiblyIncorrectPlacementWarning = ({
  insertPosition: { targetId, placement },
  onClose,
  warningType,
}: Props) => {
  const [dismissed, setDismissed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const targetItemName = useSelector(
    (s) => s.data.listingContentItems.byId.get(targetId)?.item.name,
  );
  const type =
    warningType === PossiblyIncorrectPlacementWarningType.ContentComponent
      ? 'new component'
      : 'item';

  useEffect(() => {
    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPossiblyIncorrectPlacementWarningShown));
  }, []);

  const handleConfirm = () => {
    dispatch(
      upsertUserProperty(PossiblyIncorrectPlacementWarningDismissedServerKey, dismissed.toString()),
    );
    onClose();
  };

  const handleDismiss = (value: boolean | null) => {
    setDismissed(!!value);
  };

  return (
    <ModalDialog
      noDivider
      onCancel={onClose}
      size={ModalDialogSize.Narrow}
      buttons={
        <PossiblyIncorrectPlacementWarningFooter
          isWarningDismissed={dismissed}
          onConfirm={handleConfirm}
          onWarningDismissToggle={handleDismiss}
        />
      }
      titleBarText={`Position of the inserted ${warningType} may not be correct`}
    >
      <section className="possibly-incorrect-placement-warning__content">
        <p className="possibly-incorrect-placement-warning__paragraph">
          We can’t tell exactly where you wanted to place the {type} because you’re adding it{' '}
          {placement} {targetItemName} which is here more than once. We’ve put it {placement} its
          last instance, please review the position and adjust it if needed.
        </p>
      </section>
    </ModalDialog>
  );
};
