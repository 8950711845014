import React from 'react';
import { Redirect } from 'react-router';
import { DataTable } from '../../../_shared/components/DataTable/DataTable.tsx';
import { EmptyState } from '../../../_shared/components/EmptyState/EmptyState.tsx';
import { Loader } from '../../../_shared/components/Loader.tsx';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import {
  ContentAssignedToYouRoute,
  ContentRecentlyEditedByYouRoute,
  EnvironmentRouteParams,
  YourTasksRoute,
} from '../../../_shared/constants/routePaths.ts';
import { YourContentTabName } from '../../../_shared/constants/yourContentTabName.ts';
import { DataUiCollection } from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';

export interface IYourContentNonEmptyRedirectStateProps {
  readonly projectId: Uuid;
  readonly loadingFinished: boolean;
  readonly selectedTab: YourContentTabName;
  readonly assignedToYouItemsCount: number;
  readonly recentlyEditedByYouItemsCount: number;
}

const getFirstNonEmptyTabRoute = (props: IYourContentNonEmptyRedirectStateProps): string => {
  const { assignedToYouItemsCount, recentlyEditedByYouItemsCount, selectedTab, projectId } = props;

  const assignedToYouRoute = buildPath<EnvironmentRouteParams>(ContentAssignedToYouRoute, {
    projectId,
  });

  const editedRoute = buildPath<EnvironmentRouteParams>(ContentRecentlyEditedByYouRoute, {
    projectId,
  });

  const yourTasksRoute = buildPath<EnvironmentRouteParams>(YourTasksRoute, {
    projectId,
  });

  switch (selectedTab) {
    case YourContentTabName.AssignedToYou:
      return assignedToYouRoute;

    case YourContentTabName.RecentlyEditedByYou:
      return editedRoute;

    case YourContentTabName.YourTasks:
      return yourTasksRoute;

    default:
      return recentlyEditedByYouItemsCount <= 0 && assignedToYouItemsCount > 0
        ? assignedToYouRoute
        : editedRoute;
  }
};

export const YourContentNonEmptyRedirect: React.FC<IYourContentNonEmptyRedirectStateProps> = (
  props,
) => {
  const { loadingFinished } = props;
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  if (!loadingFinished) {
    return (
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          infoMessage={
            <EmptyState>
              <Loader />
            </EmptyState>
          }
        />
      </section>
    );
  }

  const redirectToRoute = getFirstNonEmptyTabRoute(props);
  return <Redirect {...getRedirectPropsWithSameSearch({ to: redirectToRoute })} />;
};

YourContentNonEmptyRedirect.displayName = 'YourContentNonEmptyRedirect';
