import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { showElementsAreInaccessibleNotification } from '../../actions/contentItemEditingActions.ts';
import {
  highlightIncompleteContentElements,
  showIncompleteElementsInContentGroup,
} from '../../actions/thunkContentItemEditingActions.ts';
import {
  IIncompleteItemElementsNotificationDispatchProps,
  IIncompleteItemElementsNotificationOwnProps,
  IIncompleteItemElementsNotificationStateProps,
  IncompleteItemElementsNotification as IncompleteItemElementsNotificationComponent,
} from '../../components/details/IncompleteItemElementsNotification.tsx';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import {
  getIncompleteElementIdPaths,
  isAnyIncompleteElementAccessible,
} from '../../utils/itemValidationUtils.ts';

const mapStateToProps = (state: IStore): IIncompleteItemElementsNotificationStateProps => {
  const {
    contentApp: { itemValidationErrors, itemValidationWarnings },
  } = state;
  return {
    incompleteElementsCount: getIncompleteElementIdPaths(
      itemValidationErrors,
      itemValidationWarnings,
    ).size,
    isAnyIncompleteElementAccessible: isAnyIncompleteElementAccessible(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
): IIncompleteItemElementsNotificationDispatchProps => ({
  onIncompleteNotificationClick: () => dispatch(showIncompleteElementsInContentGroup()),
  onAfterIncompleteNotificationClicked: () => dispatch(highlightIncompleteContentElements()),
  onShowInaccessibleElementsNotification: () =>
    dispatch(
      showElementsAreInaccessibleNotification(ContentGroupSelectionReason.FocusIncompleteElement),
    ),
});

export const IncompleteItemElementsNotification: React.ComponentType<
  React.PropsWithChildren<IIncompleteItemElementsNotificationOwnProps>
> = connect(mapStateToProps, mapDispatchToProps)(IncompleteItemElementsNotificationComponent);
