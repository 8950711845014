import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { UserSubscriptionSettings } from '../../containers/UserDetail/UserSubscriptionSettings.tsx';
import { UserDetailFilter } from './UserDetailFilter.tsx';
import { UserDetailInfo } from './UserDetailInfo.tsx';

export interface IUserDetailModalBodyProps {
  readonly user: IUserInfo;
  readonly onNavigateBack: () => void;
}

export const UserDetailModalBody: React.FC<IUserDetailModalBodyProps> = ({
  user,
  onNavigateBack,
}) => (
  <Box maxWidth={px(960)}>
    <Stack spacing={Spacing.L}>
      <UserDetailInfo user={user} />
      <Stack spacing={Spacing.XXXL}>
        <UserDetailFilter userId={user.userId} />
        <UserSubscriptionSettings userId={user.userId} onNavigateBack={onNavigateBack} />
      </Stack>
    </Stack>
  </Box>
);

UserDetailModalBody.displayName = 'UserDetailModalBody';
