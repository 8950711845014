import { AriaButtonProps } from '@react-aria/button';
import { ComponentProps, MouseEvent, PropsWithChildren, forwardRef } from 'react';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { ButtonBadge } from './ButtonBadge.tsx';
import { ButtonIcon } from './ButtonIcon.tsx';
import { ButtonLabel } from './ButtonLabel.tsx';
import { IBaseButtonProps } from './components/BaseButton.ts';
import { BaseButtonComponent } from './components/BaseButtonComponent.tsx';
import { ButtonProgressIcon } from './components/ButtonProgressIcon.tsx';
import { StyledButton } from './components/StyledButton.tsx';

type ButtonType = ComponentProps<typeof StyledButton>['type'];

export interface IButtonProps
  extends TooltipPropsExtension,
    IBaseButtonProps,
    Pick<AriaButtonProps, 'aria-label' | 'aria-describedby'> {
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly disabled?: boolean;
  readonly type?: ButtonType;
}

const ButtonWithRef = forwardRef<HTMLButtonElement, PropsWithChildren<IButtonProps>>(
  (
    {
      buttonDisplay = 'inline',
      size = 'medium',
      buttonStyle,
      disabled,
      disableTabulator,
      children,
      destructive,
      destructiveIcon,
      onClick,
      tooltipShortcuts,
      tooltipMaxGridUnitsWidth,
      tooltipPlacement = 'top',
      tooltipText,
      activated,
      ...props
    },
    forwardedRef,
  ) => (
    <BaseButtonComponent
      buttonStyle={buttonStyle}
      buttonDisplay={buttonDisplay}
      disabled={disabled}
      disableTabulator={disableTabulator}
      destructive={destructive}
      destructiveIcon={destructiveIcon}
      size={size}
      activated={activated}
      tooltipShortcuts={tooltipShortcuts}
      tooltipMaxGridUnitsWidth={tooltipMaxGridUnitsWidth}
      tooltipPlacement={tooltipPlacement}
      tooltipText={tooltipText}
      renderButtonComponent={(injectedProps) => (
        <StyledButton
          autoFocus={props.autoFocus}
          name={props.name}
          disabled={disabled}
          onClick={onClick}
          ref={forwardedRef}
          title={props.title}
          type={props.type}
          {...props}
          {...injectedProps}
          {...getDataUiComponentAttribute(Button)}
        />
      )}
    >
      {children}
    </BaseButtonComponent>
  ),
);

ButtonWithRef.displayName = 'Button';

const buttonComposition = {
  Badge: ButtonBadge,
  Icon: ButtonIcon,
  Label: ButtonLabel,
  ProgressIcon: ButtonProgressIcon,
} as const;

export const Button = Object.assign(ButtonWithRef, buttonComposition);
