import { Chevron } from '@kontent-ai/component-library/Chevron';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { MouseEventHandler, TransitionEventHandler } from 'react';
import { SelectGroupRenderMode } from '../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.ts';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';
import {
  StatusBarDropdownTrigger,
  StatusBarDropdownTriggerEllipsis,
} from './components/StatusBarDropdownTrigger.tsx';
import { StyledStatusBarTriggerWrapper } from './components/StyledStatusBarTriggerWrapper.tsx';

// eslint-disable-next-line
import { useFocusRing } from '@react-aria/focus';

type Props = IForwardedRefProps<HTMLButtonElement> & {
  readonly children: string;
  readonly dataUiAttributes: ObjectWithDataAttribute;
  readonly isExpanded: boolean;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly renderMode: SelectGroupRenderMode;
  readonly tag?: React.ReactNode;
  readonly onChevronTransitionEnd?: TransitionEventHandler<HTMLSpanElement>;
};

const StatusBarDropDownSelected: React.FC<Props> = ({
  children,
  dataUiAttributes,
  forwardedRef,
  isExpanded,
  onClick,
  renderMode,
  onChevronTransitionEnd: onTransitionEnd,
  tag,
}) => {
  const Component =
    renderMode === 'InEllipsisMenu' ? StatusBarDropdownTriggerEllipsis : StatusBarDropdownTrigger;

  const { focusProps, isFocusVisible } = useFocusRing({ within: true });

  return (
    <OptionalTooltip
      text={children}
      placement="right"
      customRenderText={(refForTruncatedElement) => (
        <StyledStatusBarTriggerWrapper
          data-hj-suppress=""
          onClick={onClick}
          ref={forwardedRef}
          {...focusProps}
          isFocusVisible={isFocusVisible}
        >
          <Component {...dataUiAttributes}>
            <Row spacingX={Spacing.S} alignY="center" noWrap css="height: 100%">
              <Column>
                <div className="status-bar__dropdown-text" ref={refForTruncatedElement}>
                  {children}
                </div>
              </Column>
              {tag && <Column width="fit-content">{tag}</Column>}
              <Column width="content">
                <Chevron onTransitionEnd={onTransitionEnd} direction={isExpanded ? 'up' : 'down'} />
              </Column>
            </Row>
          </Component>
        </StyledStatusBarTriggerWrapper>
      )}
    />
  );
};

StatusBarDropDownSelected.displayName = 'StatusBarDropDownSelected';

const StatusBarDropDownSelectedRFC = forwardRef(StatusBarDropDownSelected);
export { StatusBarDropDownSelectedRFC as StatusBarDropDownSelected };
