import React from 'react';
import { INumberItemElement } from '../../../../../models/contentItemElements/NumberItemElement.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../../hooks/useRenderPlaceholderText.ts';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';
import { SimpleValueDiff } from '../../SimpleValueDiff.tsx';

interface INumberDiffProps extends IRevisionDiffElementProps<INumberItemElement> {}

export const NumberDiff: React.FC<INumberDiffProps> = (props) => {
  const { elementData, originalElementData, typeElement } = props;
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.NumberElement,
    originalElementData,
    typeElement,
  );

  return (
    <SimpleValueDiff
      value={elementData.value || ''}
      originalValue={originalElementData && (originalElementData.value || '')}
      placeholderText={placeholderText ?? undefined}
    />
  );
};

NumberDiff.displayName = 'NumberDiff';
