import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { OrderBy } from '../../../../../_shared/models/OrderBy.ts';
import { SubscriptionUserListingConfig } from '../../../../../localStorages/subscriptionUserListingConfigStorage.ts';
import {
  SubscriptionUsers_Filter_Initialized,
  SubscriptionUsers_SortBy_Initialized,
} from '../../constants/actionTypes.ts';
import { ISubscriptionUsersListingFilter } from '../../models/SubscriptionUserListingFilter.ts';
import { SubscriptionUserListingColumnCode } from '../../reducers/subscriptionUsersListingUi/sortBy.ts';

interface IDeps {
  readonly loadSubscriptionUserListingFilterDeps: (
    environmentId: Uuid,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

const filterInitialized = (filter: ISubscriptionUsersListingFilter) =>
  ({
    type: SubscriptionUsers_Filter_Initialized,
    payload: { filter },
  }) as const;

const sortByInitialized = (orderBy: OrderBy<SubscriptionUserListingColumnCode>) =>
  ({
    type: SubscriptionUsers_SortBy_Initialized,
    payload: { orderBy },
  }) as const;

export type InitializeFilterActionsType = ReturnType<
  typeof filterInitialized | typeof sortByInitialized
>;

export const createLoadSubscriptionUserListingConfigAction =
  ({ loadSubscriptionUserListingFilterDeps }: IDeps) =>
  (config: SubscriptionUserListingConfig, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(filterInitialized(config));
    dispatch(
      sortByInitialized({
        columnCode: config.columnCode,
        direction: config.direction,
      }),
    );
    await dispatch(loadSubscriptionUserListingFilterDeps(config.byEnvironment, abortSignal));
  };
