import React from 'react';
import { StatusMessageTypes } from '../../../../../_shared/constants/statusMessageType.ts';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import { OperationStatus } from '../../../../../_shared/models/OperationStatus.ts';
import {
  DefaultStatusMessage,
  StatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { EnvironmentOperation } from '../../constants/environmentOperation.ts';
import { IEnvironmentSavingStatus } from '../../models/IEnvironmentSavingStatus.type.ts';

const getDeleteStatusMessage = (savingStatus: IEnvironmentSavingStatus): StatusMessage => {
  switch (savingStatus.status) {
    case OperationStatus.Failed: {
      return {
        messageType: StatusMessageTypes.ServerError,
      };
    }
    case OperationStatus.Success: {
      return {
        messageType: StatusMessageTypes.Saved,
      };
    }
    case OperationStatus.Pending: {
      return {
        messageType: StatusMessageTypes.Deleting,
      };
    }
    default:
      return DefaultStatusMessage;
  }
};

const getSwapStatusMessage = (savingStatus: IEnvironmentSavingStatus): StatusMessage => {
  switch (savingStatus.status) {
    case OperationStatus.Failed: {
      return {
        messageType: StatusMessageTypes.ServerError,
      };
    }
    case OperationStatus.Success: {
      return {
        messageType: StatusMessageTypes.Saved,
      };
    }
    case OperationStatus.Pending: {
      return {
        messageType: StatusMessageTypes.Saving,
      };
    }
    default:
      return DefaultStatusMessage;
  }
};

const getEnvironmentSavingStatusMessage = ({
  environmentsApp: { savingStatus },
}: IStore): StatusMessage => {
  switch (savingStatus.actionType) {
    case EnvironmentOperation.Delete: {
      return getDeleteStatusMessage(savingStatus);
    }
    case EnvironmentOperation.Swap: {
      return getSwapStatusMessage(savingStatus);
    }
    default:
      return DefaultStatusMessage;
  }
};

export const EnvironmentSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getEnvironmentSavingStatusMessage,
);
