import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Data_AdministratedSubscriptions_Success,
  Data_PlansForSelection_Success,
  Data_UserProjectsInfo_Failed,
  Data_UserProjectsInfo_Success,
} from '../../../constants/dataActionTypes.ts';
import { IPlan, createPlanFromServerModel } from '../../../models/plans/Plan.ts';

const initialState: Immutable.Map<Uuid, IPlan> = Immutable.Map();

export function byId(
  state: Immutable.Map<Uuid, IPlan> = initialState,
  action: Action,
): Immutable.Map<Uuid, IPlan> {
  switch (action.type) {
    case Data_UserProjectsInfo_Failed:
      return initialState;

    case Data_UserProjectsInfo_Success:
    case Data_AdministratedSubscriptions_Success: {
      const { plans } = action.payload.data;
      return (plans || []).reduce((map, planServerModel) => {
        const plan = createPlanFromServerModel(planServerModel);
        return map.set(plan.planId, plan);
      }, state);
    }

    case Data_PlansForSelection_Success: {
      return action.payload.data.reduce((map, planServerModel) => {
        const plan = createPlanFromServerModel(planServerModel);
        return map.set(plan.planId, plan);
      }, state);
    }

    default:
      return state;
  }
}
