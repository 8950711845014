import { EditorState } from 'draft-js';
import { GuidelinesCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { exportGuidelinesToServerHtml } from '../../../../richText/utils/export/html/exportGuidelinesToServerHtml.ts';
import {
  emptyEditorState,
  emptyJsonValue,
} from '../../../../richText/utils/general/editorEmptyValues.ts';
import { importRichTextFromHtml } from '../../../../richText/utils/import/importRichTextFromHtml.ts';
import { createRichTextDomainModel } from '../../../../richText/utils/serverModel/editorServerModelUtils.ts';
import { ElementType } from '../ContentItemElementType.ts';
import {
  EmptyBaseTypeElement,
  IBaseTypeElement,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface IGuidelinesTypeElement extends IBaseTypeElement {
  readonly _editorState: EditorState;
  readonly guidelinesHtml: string | null;
  readonly guidelinesJson: string | null;
  readonly type: ElementType.Guidelines;
}

export const EmptyGuidelinesTypeElement: IGuidelinesTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.None,
  _editorState: emptyEditorState,
  guidelinesHtml: null,
  guidelinesJson: emptyJsonValue,
  name: 'Guidelines',
  type: ElementType.Guidelines,
};

export function getGuidelinesTypeElementFromServerModel(
  serverModel: GuidelinesCompiledTypeElementServerModel,
): IGuidelinesTypeElement {
  const { content, updatedServerValues } = createRichTextDomainModel(
    {
      value: serverModel.guidelinesHtml,
      jsonValue: serverModel.guidelinesJson,
    },
    {
      importFromValue: importRichTextFromHtml,
      getValue: exportGuidelinesToServerHtml,
    },
  );

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyGuidelinesTypeElement._configurability,
    type: ElementType.Guidelines,
    _editorState: EditorState.createWithContent(content),
    guidelinesHtml:
      updatedServerValues?.value ??
      serverModel.guidelinesHtml ??
      EmptyGuidelinesTypeElement.guidelinesHtml,
    guidelinesJson:
      updatedServerValues?.jsonValue ??
      serverModel.guidelinesJson ??
      EmptyGuidelinesTypeElement.guidelinesJson,
  };
}
