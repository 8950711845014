import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WidgetListingState } from '../types/WidgetListingState.type.ts';
import { WidgetDetailTableStateContent } from './WidgetDetailTableStateContent.tsx';

type TaskListWidgetDetailProps = React.PropsWithChildren<{
  readonly state: WidgetListingState;
}>;

export const TaskWidgetDetailTable: React.FC<TaskListWidgetDetailProps> = ({ children, state }) => {
  return (
    <WidgetDetailTableStateContent state={state}>
      <DataTable
        dataUiCollectionName={DataUiCollection.ContentTasks}
        header={<DataTableHeadRow columns={widgetTableHeadColumns} />}
      >
        {children}
      </DataTable>
    </WidgetDetailTableStateContent>
  );
};

TaskWidgetDetailTable.displayName = 'TaskWidgetDetailTable';

const widgetTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Task',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-7',
  },
  {
    columnName: 'Due date',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: 'Assigned to',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: 'Content item name',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
]);
