import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { createAssetFolder } from '../../actions/thunkAssetLibraryActions.ts';
import {
  AssetCreateNewFolder as AssetCreateNewFolderComponent,
  IAssetCreateNewFolderDispatchProps,
} from '../../components/AssetFolders/AssetCreateNewFolder.tsx';

const mapDispatchToProps = (dispatch: Dispatch): IAssetCreateNewFolderDispatchProps => {
  return {
    onCreate: (name: string) => dispatch(createAssetFolder(name)),
  };
};

export const AssetCreateNewFolder: React.ComponentType = connect(
  null,
  mapDispatchToProps,
)(AssetCreateNewFolderComponent);
