import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

export const LanguageProductivityTip: React.FC = () => (
  <div>
    <QuickTip subtitle="Create content in any language">
      <p>
        Set up languages your way to&nbsp;
        <OutwardLink href={documentationLinks.contentLocalization}>
          create localized content
        </OutwardLink>
        .
      </p>
      <p>
        Serving localized content is crucial to build better relationship with your regional
        audience.
      </p>
    </QuickTip>
  </div>
);

LanguageProductivityTip.displayName = 'LanguageProductivityTip';
