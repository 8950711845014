import { ICurrentUserInfo } from '../../../../../data/models/user/CurrentUserInfo.ts';
import { IAssignmentCreationServerModel } from '../../../../../repositories/serverModels/IAssignmentServerModel.type.ts';

export function createEmptyAssignment(
  assignmentId: Uuid,
  userInfo: ICurrentUserInfo,
  workflowId: Uuid,
): IAssignmentCreationServerModel {
  return {
    id: assignmentId,
    assignedBy: null,
    assignees: [
      {
        userId: userInfo.userId,
      },
    ],
    workflowStatus: { workflowId },
    due: null,
    createdAt: null,
    note: '',
    noteAddedAt: null,
    noteAddedBy: null,
    publishScheduleTime: null,
    publishScheduleDisplayTimeZone: null,
    unpublishScheduleTime: null,
    unpublishScheduleDisplayTimeZone: null,
  };
}
