import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import styled from 'styled-components';
import { StructuredFeedbackButtons } from '../../../../../../../_shared/features/StructuredFeedback/containers/StructuredFeedbackButtons.tsx';
import { getAiFeedbackFeature } from '../../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { TaxonomyTermAssignedSourceType } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { itemElementCompactWidth } from '../ItemElement.tsx';
import {
  TaxonomyAiAssistant as TaxonomyAiAssistantComponent,
  TaxonomyAiAssistantProps,
} from './TaxonomyAiAssistant.tsx';
import { feedbackReasons } from './TaxonomyAiAssistantResult.tsx';

type SelectorParams = {
  readonly areAiTaxonomiesEnabled: boolean;
  readonly disabled: boolean;
  readonly isAiAssistantActive: boolean;
  readonly onTermSelectionChanged?: (termIds: ReadonlySet<Uuid>) => void;
};

export type TaxonomyAiAssistantWithFeedbackProps = Omit<
  TaxonomyAiAssistantProps,
  'onTermSelectionChanged' | 'isActive' | 'setIsActive'
> & {
  readonly areAiTaxonomiesEnabled: boolean;
  readonly disabled: boolean;
  readonly onTermSelectionChanged?: (
    termIds: ReadonlySet<Uuid>,
    source: TaxonomyTermAssignedSourceType,
  ) => void;
  readonly renderSelector: (params: SelectorParams) => React.ReactNode;
};

const CompactColumn = styled(Column)`
  width: ${px(itemElementCompactWidth)};
`;

export const TaxonomyAiAssistantWithFeedback: React.FC<TaxonomyAiAssistantWithFeedbackProps> = ({
  areAiTaxonomiesEnabled,
  disabled,
  onTermSelectionChanged,
  renderSelector,
  ...otherProps
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const selectorParams: SelectorParams = {
    areAiTaxonomiesEnabled,
    isAiAssistantActive: isActive,
    disabled: disabled || isActive,
    onTermSelectionChanged: (termIds) =>
      onTermSelectionChanged?.(termIds, TaxonomyTermAssignedSourceType.ManualSelection),
  };

  return (
    <Stack spacing={Spacing.M} align="start">
      {areAiTaxonomiesEnabled && !disabled && onTermSelectionChanged && (
        <TaxonomyAiAssistantComponent
          isActive={isActive}
          onTermSelectionChanged={(termIds) => {
            setShowFeedback(true);
            onTermSelectionChanged(termIds, TaxonomyTermAssignedSourceType.AiAssistant);
          }}
          setIsActive={(newValue) => {
            if (newValue) {
              setShowFeedback(false);
            }
            setIsActive(newValue);
          }}
          {...otherProps}
        />
      )}
      <Row alignY="center" spacing={Spacing.M}>
        <CompactColumn>{renderSelector(selectorParams)}</CompactColumn>
        {showFeedback && areAiTaxonomiesEnabled && !disabled && (
          <Column>
            <StructuredFeedbackButtons
              feature={getAiFeedbackFeature(AiActionName.CategorizeItem)}
              feedbackReasons={feedbackReasons}
            />
          </Column>
        )}
      </Row>
    </Stack>
  );
};
