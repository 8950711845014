import { Action } from '../../../@types/Action.type.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Editor_DuplicatingFinished,
  ContentType_Editor_InitFinished,
} from '../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import { ContentModels_TypeEditor_Left } from '../../../applications/contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentTypeSnippet_Creator_CreationFinished,
  ContentTypeSnippet_Editor_InitFinished,
} from '../../../applications/contentModels/snippets/constants/snippetActionTypes.ts';
import {
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Group_CreationFinished,
} from '../../../applications/contentModels/taxonomy/constants/taxonomyActionTypes.ts';
import { ContentItemEditing_Init_Ready } from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  NewContentItem_ContentItemCreatedInLinkedItems,
  NewContentItem_ContentItemCreatedInListing,
  NewContentItem_ContentItemCreatedInRichText,
} from '../../../applications/itemEditor/features/NewContentItem/constants/newContentItemActionTypes.ts';
import { Shared_Editor_Left } from '../../constants/sharedActionTypes.ts';

const defaultValue: boolean | null = null;

export function isObjectNewlyCreated(state = defaultValue, action: Action): boolean | null {
  switch (action.type) {
    case ContentType_Creator_CreationFinished:
    case ContentType_Editor_DuplicatingFinished:
    case ContentTypeSnippet_Creator_CreationFinished:
    case NewContentItem_ContentItemCreatedInLinkedItems:
    case NewContentItem_ContentItemCreatedInRichText:
    case NewContentItem_ContentItemCreatedInListing:
    case Taxonomy_Group_CreationFinished:
      return true;

    case ContentItemEditing_Init_Ready:
    case ContentTypeSnippet_Editor_InitFinished:
    case ContentType_Editor_InitFinished:
    case Taxonomy_GroupEdit_Initialized:
      return state || false;

    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left:
      return null;

    default:
      return state;
  }
}
