import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  Workflow_Restore_Failed,
  Workflow_Restore_Finished,
  Workflow_Restore_Started,
} from '../../constants/workflowActionTypes.ts';
import { fireRestoreWorkflowEvents } from './fireWorkflowIntercomEvents.ts';

interface IDeps {
  readonly workflowRepository: {
    readonly restore: (workflowId: Uuid) => Promise<void>;
  };
  readonly loadWorkflows: () => ThunkPromise;
}

const started = () =>
  ({
    type: Workflow_Restore_Started,
  }) as const;

const finished = () =>
  ({
    type: Workflow_Restore_Finished,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Workflow_Restore_Failed,
    payload: { errorMessage },
  }) as const;

export type RestoreWorkflowActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createRestoreWorkflowAction =
  ({ workflowRepository, loadWorkflows }: IDeps) =>
  (workflowId: Uuid): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());

      await workflowRepository.restore(workflowId);
      await dispatch(loadWorkflows());

      dispatch(finished());
      dispatch(fireRestoreWorkflowEvents(workflowId));
    } catch (error) {
      dispatch(failed('Failed to restore workflow.'));
      throw error;
    }
  };
