import React from 'react';
import { modalOpened } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const DeleteItemAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const performAction = compose(
    dispatch,
    trackContentItemEditingAction(
      modalOpened(ModalDialogType.DeleteItemConfirmationDialog),
      ContentItemEditingEventTypes.Delete,
    ),
  );

  return (
    <EditingActionDynamicDetailProvider {...props} performAction={performAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
