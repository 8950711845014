import { ItemEditorOperationId } from '../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';

export enum FailureStatus {
  CodenameIsNotUnique = 'CodenameIsNotUnique',
  ContentTooLarge = 'ContentTooLarge',
  ContentWasModified = 'ContentWasModified',
  ElementHasConcurrentConflict = 'ElementHasConcurrentConflict',
  ElementTooLarge = 'ElementTooLarge',
  MissingCapability = 'MissingCapability',
  ModelValidationFailed = 'ModelValidationFailed',
  NonLocalizableContentTooLarge = 'NonLocalizableContentTooLarge',
  UnknownFailure = 'UnknownFailure',
}

export interface IEditedContentItemCommonFailure {
  readonly status: Exclude<FailureStatus, FailureStatus.NonLocalizableContentTooLarge>;
}

export interface IEditedContentItemVariantIdsFailure {
  readonly status: FailureStatus.NonLocalizableContentTooLarge;
  readonly variantIds: ReadonlyArray<Uuid>;
}

export type EditedContentItemFailure =
  | IEditedContentItemCommonFailure
  | IEditedContentItemVariantIdsFailure;

export interface IEditedContentItemStatus {
  readonly saving: ReadonlySet<ItemEditorOperationId>;
  readonly failures: ReadonlyMap<ItemEditorOperationId, EditedContentItemFailure>;
}

export const InitialEditedContentItemStatus: IEditedContentItemStatus = {
  saving: new Set(),
  failures: new Map(),
};
