import { useEffect, useMemo, useState } from 'react';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { getSortedRolesMemoized } from '../../../environmentSettings/roles/selectors/getSortedRolesList.ts';
import {
  changeSourceEnvironmentSelector,
  closeCreateEnvironmentDialog,
} from '../actions/environmentsActions.ts';
import { createEnvironment, loadEnvironmentRoles } from '../actions/thunkEnvironmentsActions.ts';
import { CreateEnvironmentDialog as CreateEnvironmentDialogComponent } from '../components/CreateEnvironmentDialog.tsx';
import { IBaseEnvironment } from '../models/IBaseEnvironment.type.ts';
import { getEnvironmentsForCloning } from '../selectors/getEnvironmentsForCloning.ts';
import { CopyDataOption } from '../types/copyDataOption.ts';

export interface IRoleOption {
  readonly id: Uuid;
  readonly codename: string | null;
  readonly label: string;
}

const getRoleOptions = (roles: ReadonlyArray<IRole>): ReadonlyArray<IRoleOption> =>
  roles.map((role) => ({
    id: role.id,
    codename: role.codename,
    label: role.name,
  }));

const EmptyRoles: ReadonlySet<Uuid> = new Set();

type Props = {
  readonly isCustomAssetDomainSet?: boolean;
};

export const CreateEnvironmentDialog = ({ isCustomAssetDomainSet }: Props) => {
  const dispatch = useDispatch();
  const showDialog = useSelector((s) => s.environmentsApp.showDialogs.createDialog);
  const { cloneFromId, isNewEnvironment, loadingRolesStatus } = useSelector(
    (s) => s.environmentsApp.createEnvironmentDialogSettings,
  );
  const clonedEnvironment =
    useSelector((s) => (cloneFromId ? s.data.projects.byId.get(cloneFromId) : null)) ?? null;
  const environments = useSelector((s) =>
    clonedEnvironment
      ? getEnvironmentsForCloning(s, clonedEnvironment.masterEnvironmentId)
      : Immutable.List.of<IBaseEnvironment>(),
  );
  const creatingEnvironment = useSelector((s) => s.environmentsApp.creatingEnvironment);
  const creatingEnvironmentFailed = useSelector((s) => s.environmentsApp.creatingEnvironmentFailed);
  const roles = useSelector((s) => getSortedRolesMemoized(s.environmentsApp.environmentRoles));
  const roleOptions = useMemo(() => getRoleOptions(roles), [roles]);

  const [selectedRoleIds, setSelectedRoleIds] = useState<ReadonlySet<Uuid>>(EmptyRoles);

  useThunkPromise(loadEnvironmentRolesForProjectToCloneFrom, cloneFromId, {
    canRun: !!cloneFromId && showDialog,
  });

  useEffect(() => {
    if (cloneFromId && showDialog) {
      setSelectedRoleIds(EmptyRoles);
    }
  }, [cloneFromId, showDialog]);

  const onCreateEnvironment = (
    environmentId: Uuid,
    name: string,
    rolesToActivate: ReadonlyArray<Uuid>,
    copyDataOptions: ReadonlyArray<CopyDataOption>,
  ) => dispatch(createEnvironment(environmentId, name, rolesToActivate, copyDataOptions));
  const onCloseCreateEnvironmentDialog = () => dispatch(closeCreateEnvironmentDialog());
  const onSourceEnvironmentChanged = (environmentId: Uuid) =>
    dispatch(changeSourceEnvironmentSelector(environmentId));

  return cloneFromId ? (
    <CreateEnvironmentDialogComponent
      cloneFromId={cloneFromId}
      cloneFromName={clonedEnvironment?.environmentName || ''}
      creatingEnvironment={creatingEnvironment}
      creatingEnvironmentFailed={creatingEnvironmentFailed}
      environments={environments}
      isCustomAssetDomainSet={!!isCustomAssetDomainSet}
      isNewEnvironment={isNewEnvironment}
      loadingRolesStatus={loadingRolesStatus}
      onCloseCreateEnvironmentDialog={onCloseCreateEnvironmentDialog}
      onCreateEnvironment={onCreateEnvironment}
      onSelectRoles={setSelectedRoleIds}
      onSourceEnvironmentChanged={onSourceEnvironmentChanged}
      roleOptions={roleOptions}
      selectedRoleIds={selectedRoleIds}
      showDialog={showDialog}
    />
  ) : null;
};

const loadEnvironmentRolesForProjectToCloneFrom =
  (cloneFromId: Uuid | null, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    if (cloneFromId) {
      await dispatch(loadEnvironmentRoles(cloneFromId, abortSignal));
    }
  };
