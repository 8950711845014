import React, { useContext } from 'react';
import { EditorPaperContext } from '../../../../../../_shared/contexts/EditorPaperContext.tsx';
import { EditorScrollContext } from '../../../../../../_shared/contexts/EditorScrollContext.tsx';
import { presetOrderedQuickActions } from '../../constants/editingActions/presetOrderedQuickActions.ts';
import { EditingAction } from '../../models/EditingAction.ts';
import { splitContentItemEditingActions } from '../../utils/editingActions/splitContentItemEditingActions.ts';
import { EditingActionsMenu } from './EditingActionsMenu.tsx';
import { EditingQuickActions } from './EditingQuickActions.tsx';

type EditingActionsProps = {
  readonly activatedAction: EditingAction;
  readonly availableActions: readonly EditingAction[];
  readonly isInPublishingOrArchivingStep: boolean;
  readonly onDeactivateAction: () => void;
  readonly onShowActionsMenu: () => void;
};

export const EditingActions: React.FC<EditingActionsProps> = (props) => {
  const paperContext = useContext(EditorPaperContext);
  const scrollContext = useContext(EditorScrollContext);
  if (!scrollContext.isCandid) {
    // prevents quick actions animating on item load (before paper width is set to the context for the first time)
    return null;
  }

  const viewContext = {
    ...scrollContext,
    paperThresholds: paperContext.thresholds,
  };
  const { menuActions, quickActions } = splitContentItemEditingActions(
    props.availableActions,
    presetOrderedQuickActions,
    viewContext,
    !props.isInPublishingOrArchivingStep,
  );

  const menuActivatedAction = quickActions.includes(props.activatedAction)
    ? EditingAction.none
    : props.activatedAction;

  const hasQuickActions = !!quickActions.length;

  return (
    <div className="content-editing-actions content-item-pane__actions-pane">
      <div className="content-editing-actions__wrapper">
        <div className="content-editing-actions__quick-actions">
          <EditingQuickActions
            activatedAction={props.activatedAction}
            actions={quickActions}
            onDeactivateAction={props.onDeactivateAction}
          />
        </div>
        <div className="content-editing-actions__menu">
          <EditingActionsMenu
            activatedAction={menuActivatedAction}
            actions={menuActions}
            areAllActionsInMenu={!hasQuickActions}
            onShowMenu={props.onShowActionsMenu}
            onHideMenu={props.onDeactivateAction}
            key={scrollContext.isPaperScrolledOut ? 'scrolled-out' : 'on-top'}
          />
        </div>
      </div>
    </div>
  );
};

EditingActions.displayName = 'EditingActions';
