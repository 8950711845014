import { Action } from '../../../../@types/Action.type.ts';
import { AssetType_Editor_InitFinished } from '../../assetTypes/constants/assetTypeActionTypes.ts';
import {
  ContentType_Editor_InitFinished,
  ContentType_Listing_RouteEntered,
} from '../../contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentTypeSnippet_Editor_InitFinished,
  ContentTypeSnippet_Listing_RouteEntered,
} from '../../snippets/constants/snippetActionTypes.ts';
import { ContentModelsTabName } from '../types/contentModelsTabName.ts';

export function selectedTab(
  state: ContentModelsTabName = ContentModelsTabName.Types,
  action: Action,
): ContentModelsTabName {
  switch (action.type) {
    case ContentType_Listing_RouteEntered:
    case ContentType_Editor_InitFinished:
      return ContentModelsTabName.Types;

    case ContentTypeSnippet_Listing_RouteEntered:
    case ContentTypeSnippet_Editor_InitFinished:
      return ContentModelsTabName.Snippets;

    case AssetType_Editor_InitFinished:
      return ContentModelsTabName.AssetType;

    default:
      return state;
  }
}
