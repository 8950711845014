import { Collection } from '@kontent-ai/utils';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { forgetSmartLinkCommand } from '../../../../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { getCurrentUserRoleForCollectionForLanguageOrThrow } from '../../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { shallowEqual } from '../../../../../../../_shared/utils/shallowEqual.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILinkedItemsTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { useSpacesIds } from '../../../../../../environmentSettings/spaces/hooks/useSpaceIds.ts';
import {
  prepareNewContentItemDialogForLinkedItems,
  prepareNewContentItemVariantInitFromNewContentItemForm,
} from '../../../../NewContentItem/actions/thunkNewContentItemActions.ts';
import { CollectionSection } from '../../../../NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../../NewContentItem/constants/contentTypeSection.ts';
import { getAvailableCollectionsForContentItem } from '../../../../NewContentItem/utils/getAvailableCollectionsForContentItem.ts';
import { createNewItemFromLinkedItems } from '../../../actions/thunkContentItemEditingActions.ts';
import { redirectToLinkedItem } from '../../../actions/thunks/redirectToLinkedItem.ts';
import { LinkedItemsActionsPane as LinkedItemsActionsPaneComponent } from '../../../components/elements/linkedItems/LinkedItemsActionsPane.tsx';
import { getUsableContentTypes } from '../../../utils/modularContentUtils.ts';

type Props = {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly alreadyLinkedItemIds: Immutable.List<Uuid>;
  readonly autoFocus?: boolean;
  readonly collectionId?: Uuid;
  readonly elementId: Uuid;
  readonly modalDialogTitle: string;
  readonly onAddExistingItems: (itemsIds: ReadonlyArray<Uuid>, insertAtIndex?: number) => void;
  readonly onCreateNewItem: (
    itemId: Uuid,
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
    insertAtIndex: number | null,
  ) => void;
  readonly typeElement: ILinkedItemsTypeElement;
};

const getTypesForCollection = (
  allowedContentTypes: Immutable.Set<IContentType>,
  collectionId: Uuid,
  state: IStore,
): Immutable.Set<IContentType> => {
  const { settings } = getCurrentUserRoleForCollectionForLanguageOrThrow(
    state,
    collectionId,
    getSelectedLanguageIdOrThrow(state),
  );

  return getUsableContentTypes(allowedContentTypes, state.data.contentTypes.byId, settings);
};

export const LinkedItemsActionsPane = ({
  autoFocus,
  alreadyLinkedItemIds,
  collectionId,
  elementId,
  typeElement,
  modalDialogTitle,
  onCreateNewItem,
  onAddExistingItems,
  allowedContentTypes,
}: Props) => {
  const currentPath = useLocation().pathname;
  const history = useHistory();
  const dispatch = useDispatch();
  const smartLinkCommand = useSelector((s) => s.sharedApp.smartLinkCommand);
  const editedContentItemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);
  const collections = useSelector((s) => {
    const selectedLanguageId = s.sharedApp.selectedLanguage.id;

    return selectedLanguageId ? getAvailableCollectionsForContentItem(selectedLanguageId, s) : [];
  }, shallowEqual);

  const contentTypes = useSelector((s) => {
    const singleCollectionId = collections.length === 1 && Collection.getFirst(collections)?.id;

    return singleCollectionId
      ? getTypesForCollection(allowedContentTypes, singleCollectionId, s)
      : s.data.contentTypes.byId;
  });

  const spaceIds = useSpacesIds(collectionId);

  const onCreateNewItemCallback = useCallback(
    async (insertAtIndex: number | null): Promise<void> => {
      if (editedContentItemId) {
        const createdItemId = await dispatch(
          createNewItemFromLinkedItems(
            editedContentItemId,
            elementId,
            (itemId, contentTypeSection, collectionSection) =>
              onCreateNewItem(itemId, contentTypeSection, collectionSection, insertAtIndex),
          ),
        );

        dispatch(redirectToLinkedItem(currentPath, createdItemId.item.id, history));
      }
    },
    [elementId, onCreateNewItem, editedContentItemId, currentPath, history],
  );

  const onSmartLinkCommandExecuted = useCallback(() => dispatch(forgetSmartLinkCommand()), []);

  const canCreateNewItem = collections.length > 0 && !contentTypes.isEmpty();

  const prepareNewContentItemCreation = useCallback(
    () => dispatch(prepareNewContentItemVariantInitFromNewContentItemForm()),
    [],
  );

  const prepareNewContentItemDialog = useCallback(
    () =>
      dispatch(
        prepareNewContentItemDialogForLinkedItems({
          allowedContentTypeIds: Immutable.Set(typeElement.allowedTypes),
        }),
      ),
    [typeElement.allowedTypes],
  );

  const allowedContentTypeIds = useMemo(
    () => allowedContentTypes.map((type) => type.id).toArray(),
    [allowedContentTypes],
  );

  return (
    <LinkedItemsActionsPaneComponent
      allowedContentTypeIds={allowedContentTypeIds}
      alreadyLinkedItemIds={alreadyLinkedItemIds}
      autoFocus={autoFocus}
      canCreateNewItem={canCreateNewItem}
      collectionId={collectionId}
      modalDialogTitle={modalDialogTitle}
      onAddExistingItems={onAddExistingItems}
      onCreateNewItem={onCreateNewItemCallback}
      onOpenNewContentItemDialog={prepareNewContentItemDialog}
      onPrepareNewContentItem={prepareNewContentItemCreation}
      onSmartLinkCommandExecuted={onSmartLinkCommandExecuted}
      showCreateNewItemButton={!!onCreateNewItem}
      smartLinkCommand={smartLinkCommand}
      spaceIds={spaceIds}
      typeElement={typeElement}
    />
  );
};
