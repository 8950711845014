import { ICancellablePromise } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IContentTypeSnippet } from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { TypeListOperationResultServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  ContentTypeSnippet_Listing_ResetOperationState,
  ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted,
} from '../../constants/snippetActionTypes.ts';
import {
  ContentTypeSnippetListingOperationStatus,
  ContentTypeSnippetListingOperationType,
} from '../../utils/contentTypeSnippetListingOperationStatus.ts';

interface IDeps {
  readonly contentTypeSnippetRepository: {
    readonly restoreContentTypeSnippets: (
      typeId: UuidArray,
    ) => Promise<TypeListOperationResultServerModel>;
  };
  readonly loadSnippetsData: () => ThunkPromise;
  readonly delay: (duration: number) => ICancellablePromise;
}

const restoreContentTypeSnippetsStarted = (
  affectedContentTypeSnippets: Immutable.Set<IContentTypeSnippet>,
) =>
  ({
    type: ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted,
    payload: { affectedContentTypeSnippets },
  }) as const;

const restoreContentTypeSnippetsFinished = (
  affectedContentTypeSnippetIds: Immutable.Set<Uuid>,
  successfulIds: Immutable.Set<Uuid>,
) =>
  ({
    type: ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished,
    payload: {
      affectedContentTypeSnippetIds,
      successfulIds,
    },
  }) as const;

const resetContentTypeSnippetListingOperationState = (
  operationToReset: ContentTypeSnippetListingOperationStatus,
) =>
  ({
    type: ContentTypeSnippet_Listing_ResetOperationState,
    payload: {
      operationToReset,
    },
  }) as const;

export type UndoContentTypeSnippetListingOperationActionsType = ReturnType<
  | typeof restoreContentTypeSnippetsStarted
  | typeof restoreContentTypeSnippetsFinished
  | typeof resetContentTypeSnippetListingOperationState
>;

export const createUndoContentTypeSnippetListingOperationAction =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { listingOperationStatus } = getState().contentModelsApp.snippets.listing;
    if (listingOperationStatus.operationType !== ContentTypeSnippetListingOperationType.Archived) {
      return;
    }

    const snippetsToBeRestored = listingOperationStatus.restorableContentTypeSnippets;
    dispatch(restoreContentTypeSnippetsStarted(snippetsToBeRestored));
    const restorableTypeIds = snippetsToBeRestored
      .map((snippet: IContentTypeSnippet) => snippet.id)
      .toSet();

    const operationResult = await deps.contentTypeSnippetRepository.restoreContentTypeSnippets(
      restorableTypeIds.toArray(),
    );
    dispatch(
      restoreContentTypeSnippetsFinished(
        restorableTypeIds,
        Immutable.Set<Uuid>(operationResult.modifiedTypeIds),
      ),
    );
    const prevListingOperationStatus =
      getState().contentModelsApp.snippets.listing.listingOperationStatus;

    await dispatch(deps.loadSnippetsData());

    await deps.delay(5000);
    dispatch(resetContentTypeSnippetListingOperationState(prevListingOperationStatus));
  };
