import { Direction } from '@kontent-ai/types';
import { assert, Collection, notNull } from '@kontent-ai/utils';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  Modifier,
  RawDraftContentState,
  SelectionState,
} from 'draft-js';
import Immutable from 'immutable';
import { isNumeric } from '../../../../_shared/utils/validation/isNumeric.ts';
import { removeEntities, splitEntity } from '../../plugins/entityApi/api/editorEntityUtils.ts';
import { DraftJSInlineStyle } from '../../plugins/inlineStyles/api/inlineStyles.ts';
import { updateText } from '../../plugins/textApi/api/editorTextUtils.ts';
import {
  BaseBlockType,
  BlockType,
  ObjectBlockType,
  changeBaseBlockType,
  getNestedBlockType,
  isMutableBlockType,
  parseBlockType,
} from '../blocks/blockType.ts';
import {
  CustomBlockSleevePosition,
  findParentBlock,
  findSiblingBlock,
  getCustomBlockSleevePosition,
  getNestingLevel,
  getParentBlockTypes,
  hasMutableBlockType,
  isCustomBlockSleeve,
  isEmptyParagraph,
  isEmptyTextBlock,
  isHeading,
  isInTable,
  isListItem,
  isNewBlockPlaceholder,
  isObjectBlock,
  isStyledTextBlock,
  isTableCell,
  isTextBlock,
  isUnstyledBlock,
} from '../blocks/blockTypeUtils.ts';
import {
  getBaseBlockType,
  getBlockLength,
  getFullBlockType,
} from '../blocks/editorBlockGetters.ts';
import {
  BlockEdgeStatus,
  IFilteredBlocksResult,
  IRawBlock,
  RawEntityMap,
  canMergeBlocks,
  changeBlockType,
  createContent,
  createContentFromRawBlocks,
  createEmptyRawParagraph,
  createRawBlock,
  filterBlocks,
  findBlockIndex,
  getUnstyledBlock,
  isAtBlockEdge,
  isAtBlockEnd,
  setBlockKey,
  setBlockText,
  setBlockType,
  updateBlockDepth,
} from '../blocks/editorBlockUtils.ts';
import {
  ensureContentConsistency,
  getValidSelection,
} from '../consistency/editorConsistencyUtils.ts';
import {
  createSelection,
  getBlocksAtSelection,
  getFullySelectedBlocks,
  getSelectionEdgeStatus,
  isSelectionWithinOneBlock,
  moveCaretToSelectionEnd,
  setContentSelection,
  updateBlockKeyInSelection,
} from '../editorSelectionUtils.ts';
import { getBlocks } from './editorContentGetters.ts';

export type IGetReferencesFromContentState<TReference = Uuid> = (
  content: ContentState,
) => ReadonlyArray<TReference>;

export interface IContentChangeInput {
  readonly content: ContentState;
  readonly selection: SelectionState;
}

export interface IContentChangeResult {
  readonly wasModified?: boolean;
  readonly isUnhandled?: boolean;
  readonly content: ContentState;
  readonly selection: SelectionState;
}

export function getEmptyRawContentState(): RawDraftContentState {
  return {
    entityMap: {},
    blocks: [createEmptyRawParagraph()],
  };
}

function changeToUnstyledBlock(resultBlock: ContentBlock): ContentBlock {
  return resultBlock.merge({
    type: changeBaseBlockType(getFullBlockType(resultBlock), BlockType.Unstyled),
    depth: 0,
  }) as ContentBlock;
}

function extractContentFromBlock(block: ContentBlock, start: number, end: number): ContentBlock {
  return setBlockText(block, {
    text: block.getText().substring(start, end),
    characterList: block.getCharacterList().slice(start, end).toList(),
  });
}

function extractContentFromBlocks(
  blocks: ReadonlyArray<ContentBlock>,
  startInFirst: number,
  endInLast: number,
): ReadonlyArray<ContentBlock> {
  const firstBlock = blocks[0];
  const lastBlock = Collection.getLast(blocks);
  assert(
    firstBlock,
    () => `${__filename}.extractContentFromBlocks: Item at first index is not a content block.`,
  );
  assert(
    lastBlock,
    () => `${__filename}.extractContentFromBlocks: Item at last index is not a content block.`,
  );

  const firstResultBlock = extractContentFromBlock(
    firstBlock,
    startInFirst,
    firstBlock.getLength(),
  );
  const lastResultBlock = extractContentFromBlock(lastBlock, 0, endInLast);

  const resultBlocks = [firstResultBlock, ...blocks.slice(1, blocks.length - 1), lastResultBlock];
  return resultBlocks;
}

function removeSpareParentMetadata(
  blocks: ReadonlyArray<ContentBlock>,
): ReadonlyArray<ContentBlock> {
  const minNestingLevel = Math.min(...blocks.map((block) => getNestingLevel(block)));
  if (minNestingLevel <= 0) {
    return blocks;
  }

  const withoutSpareParentMetadata = blocks.map((block) => {
    const normalizedBlockType = getNestedBlockType(
      getParentBlockTypes(block).slice(minNestingLevel),
      getBaseBlockType(block),
    );
    return setBlockType(block, normalizedBlockType);
  });
  return withoutSpareParentMetadata;
}

function includeMissingParents(
  extractedBlocks: ReadonlyArray<ContentBlock>,
  originalContent: ContentState,
): ReadonlyArray<ContentBlock> {
  const originalBlocks = getBlocks(originalContent);
  const includeParents: Array<ContentBlock> = [];

  let firstExtractedBlock = extractedBlocks[0];
  assert(
    firstExtractedBlock,
    () => `${__filename}.includeMissingParents: Item at first index is not a content block.`,
  );
  let firstExtractedBlockIndex = findBlockIndex(originalBlocks, firstExtractedBlock.getKey());

  const minNestingLevel = Math.min(...extractedBlocks.map((block) => getNestingLevel(block)));

  while (getNestingLevel(firstExtractedBlock) > minNestingLevel) {
    const parent = findParentBlock(firstExtractedBlockIndex, originalBlocks);
    if (!parent) {
      break;
    }
    includeParents.unshift(parent.block);
    firstExtractedBlock = parent.block;
    firstExtractedBlockIndex = parent.index;
  }
  if (includeParents.length === 0) {
    return extractedBlocks;
  }

  const withParents = [...includeParents, ...extractedBlocks];
  return withParents;
}

export function extractSelectedContent(
  content: ContentState,
  selection: SelectionState,
): ContentState {
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();

  if (isSelectionWithinOneBlock(selection)) {
    const block = content.getBlockForKey(selection.getAnchorKey());
    const extractedBlock = extractContentFromBlock(block, startOffset, endOffset);

    // Part of text block is treated as unstyled as it doesn't make sense to apply block type to such subset of content
    const extractAsUnstyled = startOffset > 0 || endOffset < block.getLength();
    const resultBlock = extractAsUnstyled ? changeToUnstyledBlock(extractedBlock) : extractedBlock;

    return createContent(removeSpareParentMetadata([resultBlock]));
  }

  const selectedBlocks = getBlocksAtSelection(content, selection);
  const extractedBlocks = extractContentFromBlocks(selectedBlocks, startOffset, endOffset);

  const blocksWithParents = includeMissingParents(extractedBlocks, content);
  const withoutSpareParentMetadata = removeSpareParentMetadata(blocksWithParents);

  return createContent(withoutSpareParentMetadata);
}

function getFallbackSelectionBefore(
  content: ContentState,
  startKey: string,
  newContent: ContentState,
): SelectionState | null {
  let blockBefore = content.getBlockBefore(startKey);
  while (blockBefore && !newContent.getBlockForKey(blockBefore.getKey())) {
    blockBefore = content.getBlockBefore(blockBefore.getKey());
  }
  const selectionBefore =
    blockBefore && createSelection(blockBefore.getKey(), blockBefore.getLength());
  return selectionBefore ?? null;
}

function getFallbackSelectionAfter(
  content: ContentState,
  endKey: string,
  newContent: ContentState,
): SelectionState | null {
  let blockAfter = content.getBlockAfter(endKey);
  while (blockAfter && !newContent.getBlockForKey(blockAfter.getKey())) {
    blockAfter = content.getBlockAfter(blockAfter.getKey());
  }
  const selectionAfter = blockAfter && createSelection(blockAfter.getKey(), 0);
  return selectionAfter ?? null;
}

function moveSelectionInsideTable(
  content: ContentState,
  selection: SelectionState,
): SelectionState {
  const blocks = getBlocks(content);

  let startKey = selection.getStartKey();
  let startOffset = selection.getStartOffset();

  const startIndex = findBlockIndex(blocks, startKey);
  if (
    getCustomBlockSleevePosition(startIndex, blocks, BlockType.TableCell) ===
    CustomBlockSleevePosition.BeforeOwner
  ) {
    const tableContentStartBlock = blocks[startIndex + 2];
    if (tableContentStartBlock) {
      startKey = tableContentStartBlock.getKey();
      startOffset = 0;
    }
  }

  let endKey = selection.getEndKey();
  let endOffset = selection.getEndOffset();

  const endIndex = findBlockIndex(blocks, startKey);
  if (
    getCustomBlockSleevePosition(startIndex, blocks, BlockType.TableCell) ===
    CustomBlockSleevePosition.AfterOwner
  ) {
    const tableContentEndBlock = blocks[endIndex - 1];
    if (tableContentEndBlock) {
      endKey = tableContentEndBlock.getKey();
      endOffset = 0;
    }
  }

  return selection.getIsBackward()
    ? createSelection(endKey, endOffset, startKey, startOffset, Direction.Backward)
    : createSelection(startKey, startOffset, endKey, endOffset);
}

function flipDirection(direction: Direction): Direction {
  return direction === Direction.Forward ? Direction.Backward : Direction.Forward;
}

function getSelectionAfterRemove(
  content: ContentState,
  selection: SelectionState,
  newContent: ContentState,
  direction: Direction,
): SelectionState {
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();

  // If the block where selection started before the removal exists even after the removal,
  // this is the ideal selection regardless of the removal direction
  // We want to keep the start offset as the text in that block might have been just partially selected
  const idealSelectionBefore = newContent.getBlockForKey(startKey)
    ? createSelection(startKey, startOffset)
    : null;
  if (idealSelectionBefore) {
    return idealSelectionBefore;
  }

  const endKey = selection.getEndKey();
  const endBlock = newContent.getBlockForKey(endKey);

  const idealSelectionAfter = endBlock ? createSelection(endKey, 0) : null;

  // If the selection after removal ended up at the beginning of a non-empty block, it means that it's content was just partially selected
  // Similar to start selection, we just want to keep this selection as the best one for any direction
  if (idealSelectionAfter && endBlock.getLength()) {
    return idealSelectionAfter;
  }

  // In case either the block at the selection start or selection end was deleted,
  // we want to do a fallback to a next meaningful block in the given direction
  const selectionBefore = getFallbackSelectionBefore(content, startKey, newContent);
  const selectionAfter = getFallbackSelectionAfter(content, endKey, newContent);

  const bestAvailableSelection =
    direction === Direction.Backward
      ? (selectionBefore ?? idealSelectionAfter ?? selectionAfter)
      : (idealSelectionAfter ?? selectionAfter ?? selectionBefore);

  const newSelection =
    bestAvailableSelection ?? createSelection(newContent.getFirstBlock().getKey());
  const consistentSelection = getValidSelection(
    newContent,
    newSelection,
    // Selection in a table cell block is not valid. This may happen in case the deleted range was at the edge of the table cell content.
    // The validity check will fix it for us, we just need to give it the correct direction back to the table cell (the opposite of the delete direction)
    isTableCell(newContent.getBlockForKey(newSelection.getStartKey()))
      ? flipDirection(direction)
      : direction,
  );

  return consistentSelection;
}

function getSleeveBeforeTable(
  blocks: ReadonlyArray<ContentBlock>,
  startIndexInTable: number,
  stopAtIndex: number,
): ContentBlock | null {
  for (let i = startIndexInTable - 1; i > stopAtIndex; i--) {
    if (
      getCustomBlockSleevePosition(i, blocks, BlockType.TableCell) ===
      CustomBlockSleevePosition.BeforeOwner
    ) {
      return blocks[i] ?? null;
    }
  }
  return null;
}

function getSleeveAfterTable(
  blocks: ReadonlyArray<ContentBlock>,
  startIndexInTable: number,
  stopAtIndex: number,
): ContentBlock | null {
  for (let i = startIndexInTable + 1; i < stopAtIndex; i++) {
    if (
      getCustomBlockSleevePosition(i, blocks, BlockType.TableCell) ===
      CustomBlockSleevePosition.AfterOwner
    ) {
      return blocks[i] ?? null;
    }
  }
  return null;
}

function areSemanticallyReplaceable(block1: ContentBlock, block2: ContentBlock): boolean {
  const type1 = parseBlockType(getFullBlockType(block1));
  const type2 = parseBlockType(getFullBlockType(block2));

  const nestingLevelMatches = type1.length === type2.length;
  if (!nestingLevelMatches) {
    return false;
  }

  for (let i = 0; i < type1.length - 2; i++) {
    const parentMatches = type1[i] === type2[i];
    if (!parentMatches) {
      return false;
    }
  }

  const baseType1 = type1[type1.length - 1];
  const baseType2 = type2[type2.length - 1];
  assert(baseType1, () => `${__filename}.areSemanticallyReplaceable: Base type 1 is falsy.`);
  assert(baseType2, () => `${__filename}.areSemanticallyReplaceable: Base type 2 is falsy.`);

  const bothAreMutable = isMutableBlockType(baseType1) && isMutableBlockType(baseType2);
  if (bothAreMutable) {
    return true;
  }

  const areEquivalentTableCells =
    baseType1 === BlockType.TableCell &&
    baseType2 === BlockType.TableCell &&
    block1.getDepth() === block2.getDepth();
  return areEquivalentTableCells;
}

function restoreOriginalBlocks(
  originalContent: ContentState,
  removedIndices: ReadonlyArray<number>,
  consistentContent: ContentState,
): ContentState {
  const originalBlocks = getBlocks(originalContent);
  const originalBlockKeys = originalBlocks.map((block) => block.getKey());

  const consistentBlocks = getBlocks(consistentContent);

  let changed = false;
  let lastUsedIndexInOriginal = -1;

  const newBlocks = consistentBlocks.map((consistentBlock, indexInConsistent) => {
    const consistentBlockKey = consistentBlock.getKey();

    let indexInOriginal = lastUsedIndexInOriginal + 1;
    while (indexInOriginal < originalBlocks.length) {
      const originalBlockKey = originalBlockKeys[indexInOriginal];
      if (originalBlockKey === consistentBlockKey) {
        lastUsedIndexInOriginal = indexInOriginal;
        break;
      }
      indexInOriginal++;
    }

    const wasCreatedByConsistencyCheck = lastUsedIndexInOriginal !== indexInOriginal;
    if (wasCreatedByConsistencyCheck) {
      const firstCandidateIndex = removedIndices.findIndex(
        (removedIndex) => removedIndex === lastUsedIndexInOriginal + 1,
      );
      if (firstCandidateIndex >= 0) {
        const indexNotANumberMessage = () =>
          `${__filename}.restoreOriginalBlocks: Candidate index in original is not a number.`;
        const blockNotAContentBlockMessage = () =>
          `${__filename}.restoreOriginalBlocks: Candidate block is not a content block.`;

        for (
          let candidateIndex = firstCandidateIndex;
          candidateIndex < removedIndices.length;
          candidateIndex++
        ) {
          const candidateIndexInOriginal = removedIndices[candidateIndex];
          assert(isNumeric(candidateIndexInOriginal), indexNotANumberMessage);
          const candidateBlock = originalBlocks[candidateIndexInOriginal];
          assert(candidateBlock, blockNotAContentBlockMessage);

          const isCandidateMatching =
            areSemanticallyReplaceable(candidateBlock, consistentBlock) &&
            isCustomBlockSleeve(candidateIndexInOriginal, originalBlocks) ===
              isCustomBlockSleeve(indexInConsistent, consistentBlocks);

          if (isCandidateMatching) {
            // Candidate found, use its original data to the output and adjust index in original blocks to make sure the order is preserved and candidate is used only once
            // Use only text from the new block as all text is expected to be removed at this point
            // Keep the new block type
            const restoredBlock = setBlockText(
              setBlockType(candidateBlock, getFullBlockType(consistentBlock)),
              {
                text: consistentBlock.getText(),
                characterList: consistentBlock.getCharacterList(),
              },
            );
            lastUsedIndexInOriginal = candidateIndexInOriginal;
            changed = true;
            return restoredBlock;
          }
        }
      }
    }

    // Not created by consistency check or matching candidate not found - Just use the current block
    return consistentBlock;
  });

  if (!changed) {
    return consistentContent;
  }
  const newContent = createContent(newBlocks);
  return newContent;
}

function removeRangeFromSingleBlock(input: IContentChangeInput): IContentChangeResult {
  const { content, selection } = input;

  if (!isSelectionWithinOneBlock(selection)) {
    return input;
  }

  const newContent = Modifier.removeRange(content, selection, 'forward');
  const consistentContent = ensureContentConsistency(newContent);

  return {
    wasModified: true,
    content: consistentContent,
    selection: consistentContent.getSelectionAfter(),
  };
}

function removePartiallySelectedTextFromBlocks(
  blocks: ReadonlyArray<ContentBlock>,
  selection: SelectionState,
  allowMergingBlocks: boolean,
): ReadonlyArray<ContentBlock> {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  // Remove the remaining parts of the selection (the text parts)
  const withTextDeletedAndMerged = blocks.map((block, index) => {
    const blockKey = block.getKey();
    if (blockKey === startKey) {
      const nextBlock = blocks[index + 1];
      const startOffset = selection.getStartOffset();
      const mergeEndBlockToStartBlock =
        nextBlock &&
        nextBlock.getKey() === endKey &&
        allowMergingBlocks &&
        canMergeBlocks(block, nextBlock);

      if (mergeEndBlockToStartBlock) {
        const endOffset = selection.getEndOffset();
        return setBlockText(block, {
          text:
            block.getText().substring(0, startOffset) + nextBlock.getText().substring(endOffset),
          characterList: block
            .getCharacterList()
            .slice(0, startOffset)
            .concat(nextBlock.getCharacterList().slice(endOffset))
            .toList(),
        });
      }

      return setBlockText(block, {
        text: block.getText().substring(0, startOffset),
        characterList: block.getCharacterList().slice(0, startOffset).toList(),
      });
    }

    if (blockKey === endKey) {
      const previousBlock = blocks[index - 1];
      const endBlockMergedToStartBlock =
        previousBlock &&
        previousBlock.getKey() === startKey &&
        allowMergingBlocks &&
        canMergeBlocks(previousBlock, block);

      if (endBlockMergedToStartBlock) {
        return null;
      }
      const endOffset = selection.getEndOffset();
      return setBlockText(block, {
        text: block.getText().substring(endOffset),
        characterList: block.getCharacterList().slice(endOffset).toList(),
      });
    }

    return block;
  });

  return withTextDeletedAndMerged.filter(notNull);
}

const isSleeveAfterTable = (blocks: ReadonlyArray<ContentBlock>, startKey: string): boolean =>
  getCustomBlockSleevePosition(findBlockIndex(blocks, startKey), blocks, BlockType.TableCell) ===
  CustomBlockSleevePosition.AfterOwner;

const isSleeveBeforeTable = (blocks: ReadonlyArray<ContentBlock>, endKey: string): boolean =>
  getCustomBlockSleevePosition(findBlockIndex(blocks, endKey), blocks, BlockType.TableCell) ===
  CustomBlockSleevePosition.BeforeOwner;

function isSelectionFromOneTableToAnother(
  content: ContentState,
  selection: SelectionState,
): boolean {
  const blocksAtSelection = getBlocksAtSelection(content, selection);

  const blocks = getBlocks(content);
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  const isStartAfterTable = isSleeveAfterTable(blocks, startKey);
  const isStartInOrAfterTable = isInTable(blocksAtSelection[0] ?? null) || isStartAfterTable;

  const isEndBeforeTable = isSleeveBeforeTable(blocks, endKey);
  const isEndInOrBeforeTable = isInTable(Collection.getLast(blocksAtSelection)) || isEndBeforeTable;

  const containsBlocksOutsideTable = !blocksAtSelection.every(isInTable);

  return isStartInOrAfterTable && isEndInOrBeforeTable && containsBlocksOutsideTable;
}

function restoreSleevesBetweenTables(
  blockRemovalResult: IFilteredBlocksResult,
  originalInput: IContentChangeInput,
): ReadonlyArray<ContentBlock> {
  const { content, selection } = originalInput;

  // Restore extra empty paragraphs between tables
  const isSelectionBetweenTables = isSelectionFromOneTableToAnother(content, selection);

  const { blocks: withRemovedBlocks, removedIndices } = blockRemovalResult;

  const someBlocksRemoved = removedIndices.length > 0;
  if (!isSelectionBetweenTables || !someBlocksRemoved) {
    return withRemovedBlocks;
  }

  const firstRemovedBlockIndex = removedIndices[0];
  assert(isNumeric(firstRemovedBlockIndex), () => 'First removed block is undefined');

  const lastRemovedBlockIndex = Collection.getLast(removedIndices);
  assert(isNumeric(lastRemovedBlockIndex), () => 'Last removed block is undefined');

  const originalBlocks = getBlocks(content);

  const withRestoredTableSleeves = [
    ...withRemovedBlocks.slice(0, firstRemovedBlockIndex),
    getSleeveAfterTable(originalBlocks, firstRemovedBlockIndex, lastRemovedBlockIndex) ??
      getUnstyledBlock([]),
    getSleeveBeforeTable(originalBlocks, lastRemovedBlockIndex, firstRemovedBlockIndex) ??
      getUnstyledBlock([]),
    ...withRemovedBlocks.slice(firstRemovedBlockIndex),
  ];

  return withRestoredTableSleeves;
}

function removeFullySelectedBlocks(
  input: IContentChangeInput,
  includeParent: boolean,
  allowEmptyResult?: boolean,
): IFilteredBlocksResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);

  // When inserting new content, we don't remove fully selected parent to allow inserting that new content into a table cell with its content fully selected
  const fullySelectedBlocks = getFullySelectedBlocks(content, selection, includeParent);
  const fullySelectedBlockKeys = Immutable.Set(fullySelectedBlocks.map((block) => block.getKey()));

  const withFullySelectedBlocksRemoved = filterBlocks(
    blocks,
    (block) => !fullySelectedBlockKeys.contains(block.getKey()),
    allowEmptyResult,
  );
  return withFullySelectedBlocksRemoved;
}

function restoreFirstRemovedBlockAsEmptyParagraph(
  blockRemovalResult: IFilteredBlocksResult,
  originalContent: ContentState,
): IFilteredBlocksResult {
  const { blocks, removedIndices } = blockRemovalResult;

  const firstRemovedBlockIndex = removedIndices[0];
  if (!isNumeric(firstRemovedBlockIndex)) {
    return blockRemovalResult;
  }

  const originalBlocks = getBlocks(originalContent);
  const firstRemovedBlock = originalBlocks[firstRemovedBlockIndex];
  if (!firstRemovedBlock) {
    return blockRemovalResult;
  }

  const restoredBlock = setBlockText(changeBlockType(firstRemovedBlock, BlockType.Unstyled), {
    text: '',
    characterList: Immutable.List(),
  });

  const newBlocks = [
    ...blocks.slice(0, firstRemovedBlockIndex),
    restoredBlock,
    ...blocks.slice(firstRemovedBlockIndex),
  ];

  return {
    blocks: newBlocks,
    removedIndices: removedIndices.slice(1),
  };
}

export function removeRange(
  input: IContentChangeInput,
  direction: Direction = Direction.Backward,
  isInsertingNewContent: boolean = false,
  allowMergingBlocks: boolean = !isInsertingNewContent,
): IContentChangeResult {
  const { content, selection } = input;

  if (selection.isCollapsed()) {
    return input;
  }

  const blocks = getBlocks(content);

  if (isSelectionWithinOneBlock(selection)) {
    const blockKey = selection.getStartKey();
    const block = content.getBlockForKey(blockKey);
    if (!block) {
      return input;
    }

    // If only partially selected, we just need to remove the range directly
    const isFullySelected =
      selection.getStartOffset() === 0 && selection.getEndOffset() === block.getLength();
    if (!isFullySelected) {
      return removeRangeFromSingleBlock(input);
    }

    // If it has siblings, it means its potential parent is not fully selected, and we can just perform a simple removal while keeping the block
    const blockIndex = findBlockIndex(blocks, blockKey);
    const hasSiblings =
      !!findSiblingBlock(blockIndex, blocks, Direction.Backward)?.block ||
      !!findSiblingBlock(blockIndex, blocks, Direction.Forward)?.block;
    if (hasSiblings) {
      return removeRangeFromSingleBlock(input);
    }
  }

  const withFullySelectedBlocksRemoved = removeFullySelectedBlocks(
    input,
    !isInsertingNewContent,
    true,
  );

  const selectionEdgeStatus = getSelectionEdgeStatus(content, selection);
  const areOnlyFullySelectedBlocksInSelection =
    [BlockEdgeStatus.StartEdge, BlockEdgeStatus.EmptyBlockEdge].includes(
      selectionEdgeStatus.start,
    ) &&
    [BlockEdgeStatus.EndEdge, BlockEdgeStatus.EmptyBlockEdge].includes(selectionEdgeStatus.end);

  // When we are removing whole blocks the selection jumps by default to the end of the preceding block.
  // In case we also insert new content, this content would be inserted to this block adopting its formatting instead of replacing the selection.
  // Therefore, we reintroduce the first block at selection as empty to provide proper target for the new content.
  // We reintroduce it as empty paragraph so that it prefers the formatting of the new content (e.g. in case of paste)
  const withTargetBlockRestored =
    isInsertingNewContent && areOnlyFullySelectedBlocksInSelection
      ? restoreFirstRemovedBlockAsEmptyParagraph(withFullySelectedBlocksRemoved, content)
      : withFullySelectedBlocksRemoved;

  const withRestoredTableBoundary = restoreSleevesBetweenTables(withTargetBlockRestored, input);

  const withTextDeletedAndMerged = areOnlyFullySelectedBlocksInSelection
    ? withRestoredTableBoundary
    : removePartiallySelectedTextFromBlocks(
        withRestoredTableBoundary,
        selection,
        allowMergingBlocks,
      );

  const nonEmpty = withTextDeletedAndMerged.length
    ? withTextDeletedAndMerged
    : [getUnstyledBlock([])];

  // Some required blocks may be recreated by the consistency check
  // As some block metadata could be lost through this change, we restore their original variants to keep the changes at minimum
  // This will be especially important for collaborative editing where we need to preserve as much metadata as possible
  const withRemovedRange = createContent(nonEmpty);
  const consistentContent = ensureContentConsistency(withRemovedRange);
  const newContent =
    consistentContent !== withRemovedRange
      ? restoreOriginalBlocks(
          content,
          withFullySelectedBlocksRemoved.removedIndices,
          consistentContent,
        )
      : withRemovedRange;

  // Default delete behavior may differ in case the selection is just next to a table or at the start/end of the first/last content in table
  // By moving such edge selection inside the table we ensure that the user experience is consistent when tables content is modified
  // When the selection starts just after the table we expect the result to stay there because the table before the selection shouldn't be influenced at all
  const isStartAfterTable = isSleeveAfterTable(blocks, selection.getStartKey());
  const initialRemoveSelection = isStartAfterTable
    ? selection
    : moveSelectionInsideTable(content, selection);

  const newSelection = getSelectionAfterRemove(
    content,
    initialRemoveSelection,
    newContent,
    direction,
  );
  const newContentWithSelection = setContentSelection(
    newContent,
    initialRemoveSelection,
    newSelection,
  );

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

function getNewDefaultSelection(
  newBlocks: ReadonlyArray<ContentBlock>,
  direction: Direction,
): SelectionState {
  if (direction === Direction.Backward) {
    const firstNewBlock = newBlocks[0];
    assert(
      firstNewBlock,
      () => `${__filename}.getNewDefaultSelection: Item at the first index is not a content block.`,
    );
    return createSelection(firstNewBlock.getKey());
  }

  const lastNewBlock = Collection.getLast(newBlocks);
  assert(
    lastNewBlock,
    () => `${__filename}.getNewDefaultSelection: Item at the last index is not a content block.`,
  );
  return createSelection(lastNewBlock.getKey(), lastNewBlock.getLength());
}

export function deleteBlocks(
  input: IContentChangeInput,
  blockKeys: Immutable.Set<string>,
  direction: Direction,
): IContentChangeResult {
  if (blockKeys.isEmpty()) {
    return input;
  }

  const { content, selection } = input;
  const blocks = getBlocks(content);

  let changed = false;
  let newSelection: SelectionState | null = null;

  const newBlocks: Array<ContentBlock> = [];
  blocks.forEach((block, index) => {
    const deleteBlock = blockKeys.contains(block.getKey());
    if (deleteBlock) {
      changed = true;
      if (direction === Direction.Backward) {
        // Backward deletion places selection just before the first deleted block
        if (!newSelection) {
          const previousBlock = newBlocks[newBlocks.length - 1];
          newSelection = previousBlock
            ? createSelection(previousBlock.getKey(), previousBlock.getLength())
            : null;
        }
      } else {
        // Forward deletion places selection just after the last deleted block
        const nextBlock = blocks[index + 1];
        newSelection = nextBlock ? createSelection(nextBlock.getKey(), 0) : null;
      }
    } else {
      newBlocks.push(block);
    }
  });

  if (!changed) {
    return input;
  }

  // Make sure at least one block remains
  if (!newBlocks.length) {
    newBlocks.push(getUnstyledBlock([]));
  }

  // Default selection to start / end of the content if there were no blocks to receive the selection
  const defaultNewSelection = getNewDefaultSelection(newBlocks, direction);
  const validNewSelection = newSelection || defaultNewSelection;

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, validNewSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: validNewSelection,
  };
}

function getBlockKeysForBlockTypeChange(
  content: ContentState,
  selection: SelectionState,
): Immutable.Set<string> {
  const blocks = getBlocks(content);
  const blocksWithinSelection = getBlocksAtSelection(
    content,
    selection,
    (block, index) => !isCustomBlockSleeve(index, blocks) && hasMutableBlockType(block),
  );

  return Immutable.Set.of(...blocksWithinSelection.map((block) => block.getKey()));
}

export function changeBlock(
  input: IContentChangeInput,
  updater: (block: ContentBlock, blockIndex: number) => ContentBlock,
): IContentChangeResult {
  const content = input.content;
  let newSelection = input.selection;

  const newContent = changeBlocksInContent(content, (block, blockIndex) => {
    const newBlock = updater(block, blockIndex);
    if (newBlock !== block) {
      newSelection = updateBlockKeyInSelection(newSelection, block.getKey(), newBlock.getKey());
    }

    return newBlock;
  });

  if (newContent === content) {
    return input;
  }

  const newContentWithSelection =
    input.selection === newSelection
      ? newContent
      : setContentSelection(newContent, content.getSelectionBefore(), newSelection);

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
}

export function changeBlocksType(
  input: IContentChangeInput,
  getNewBlockType: (block: ContentBlock) => BaseBlockType,
  predicate?: (block: ContentBlock) => boolean,
): IContentChangeResult {
  const { content, selection } = input;
  const blockKeysToChange = getBlockKeysForBlockTypeChange(content, selection);
  const withChangedBlocks = changeBlock(input, (block) => {
    const allowChange =
      blockKeysToChange.contains(block.getKey()) && (!predicate || predicate(block));
    return allowChange ? changeBlockType(block, getNewBlockType(block)) : block;
  });

  return withChangedBlocks;
}

export const MaxListDepth = 20;

function adjustListItemDepth(block: ContentBlock, offset: number): ContentBlock {
  if (!isListItem(block)) {
    return block;
  }

  return updateBlockDepth(block, (depth) => Math.max(0, Math.min(depth + offset, MaxListDepth)));
}

export function adjustBlocksDepth(
  input: IContentChangeInput,
  offset: number,
): IContentChangeResult {
  const { content, selection } = input;
  const selectedBlockKeys = new Set(
    getBlocksAtSelection(content, selection).map((block) => block.getKey()),
  );

  const changed = changeBlock(input, (block) =>
    selectedBlockKeys.has(block.getKey()) ? adjustListItemDepth(block, offset) : block,
  );

  return changed;
}

export function deleteAtCaret(
  input: IContentChangeInput,
  direction: Direction,
): IContentChangeResult {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const blockKey = selection.getAnchorKey();
  const block = content.getBlockForKey(blockKey);
  if (direction === Direction.Backward) {
    const isAtBlockStart = selection.getAnchorOffset() === 0;
    if (isAtBlockStart) {
      // Start of the block, try to delete whatever is before the block
      return deleteBefore(input, blockKey);
    }
  } else {
    const _isAtBlockEnd = selection.getAnchorOffset() >= block.getLength();
    if (_isAtBlockEnd) {
      // End of the block, try to delete whatever is after the block
      return deleteAfter(input, blockKey);
    }
  }

  // No special behavior in the remaining cases
  return {
    ...input,
    isUnhandled: true,
  };
}

export function deleteAtSelection(
  input: IContentChangeInput,
  direction: Direction,
): IContentChangeResult {
  if (input.selection.isCollapsed()) {
    return deleteAtCaret(input, direction);
  }

  return removeRange(input, direction);
}

export function getClosestSelectionAfter(
  blocks: ReadonlyArray<ContentBlock>,
  blockKey: string,
): SelectionState | null {
  const blockIndex = findBlockIndex(blocks, blockKey);
  if (blockIndex < 0) {
    return null;
  }

  const block = blocks[blockIndex];
  if (!block) {
    return null;
  }

  if (isTextBlock(block)) {
    // If selection can be placed into desired block index, it is placed to its end
    return createSelection(block.getKey(), block.getLength());
  }

  // Otherwise it is placed to the start of the next block valid for selection
  for (let nextBlockIndex = blockIndex + 1; nextBlockIndex < blocks.length; nextBlockIndex++) {
    const nextBlock = blocks[nextBlockIndex];
    if (nextBlock && isTextBlock(nextBlock)) {
      return createSelection(nextBlock.getKey());
    }
  }
  return null;
}

export function replaceBlock(
  input: IContentChangeInput,
  blockKey: string,
  withBlocks: ReadonlyArray<IRawBlock>,
  entityMap?: RawEntityMap,
): IContentChangeResult {
  const blocks = getBlocks(input.content);
  const index = findBlockIndex(blocks, blockKey);
  if (index < 0) {
    return input;
  }

  const targetBlock = input.content.getBlockForKey(blockKey);
  if (!targetBlock) {
    return input;
  }

  const parentBlockTypes = getParentBlockTypes(targetBlock);
  const replacementBlocks = getBlocks(
    createContentFromRawBlocks(parentBlockTypes, withBlocks, entityMap),
  );

  const newBlocks = [...blocks.slice(0, index), ...replacementBlocks, ...blocks.slice(index + 1)];

  const newContent = createContent(newBlocks);

  // Place the selection at the end of the last inserted block if possible, otherwise just keep it
  // This may happen in case of replacement of object block only
  const lastNewBlock = newBlocks[index + withBlocks.length - 1];
  assert(
    lastNewBlock,
    () => `${__filename}.replaceBlock: Item at the last new block index is not a content block.`,
  );
  const newSelection =
    getClosestSelectionAfter(newBlocks, lastNewBlock.getKey()) ?? input.selection;

  const newContentWithSelection = setContentSelection(newContent, input.selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

export function insertBlocksAtSelection(
  input: IContentChangeInput,
  blocks: ReadonlyArray<IRawBlock>,
  entityMap?: RawEntityMap,
): IContentChangeResult {
  if (!blocks.length) {
    return input;
  }

  // Make sure the content is ready to get new blocks
  const withSelectionRemoved = removeRange(input, Direction.Backward, true);

  const targetBlockKey = withSelectionRemoved.selection.getStartKey();
  const targetOffset = withSelectionRemoved.selection.getStartOffset();

  // splitBlock() creates an empty block when selection is placed at the edge of the block. This will avoid it
  const targetBlockToSplit = withSelectionRemoved.content.getBlockForKey(targetBlockKey);
  const mustSplitTarget = !isAtBlockEdge(targetBlockToSplit, targetOffset);

  const withSplitBlock = mustSplitTarget
    ? splitBlock(splitEntity(withSelectionRemoved))
    : withSelectionRemoved;

  const targetBlocks = getBlocks(withSplitBlock.content);

  const targetBlockIndex = findBlockIndex(targetBlocks, targetBlockKey);
  const targetBlock = targetBlocks[targetBlockIndex];
  if (!targetBlock) {
    return input;
  }

  const insertingAtObjectBoundary = isCustomBlockSleeve(targetBlockIndex, targetBlocks);
  const blockAfterTarget = targetBlocks[targetBlockIndex + 1] ?? null;
  const insertingBeforeObject = insertingAtObjectBoundary && isObjectBlock(blockAfterTarget);
  const insertingAtStartOfNonEmptyTextBlock = targetOffset === 0 && getBlockLength(targetBlock) > 0;

  // When the new blocks are inserted into regular empty paragraph, the paragraph is replaced by them
  const replaceTargetBlock =
    isEmptyParagraph(targetBlock) &&
    !insertingAtObjectBoundary &&
    !insertingAtStartOfNonEmptyTextBlock;

  const newContent = replaceTargetBlock
    ? replaceBlock(withSplitBlock, targetBlockKey, blocks, entityMap)
    : insertBlocks(
        withSplitBlock,
        blocks,
        targetBlockKey,
        insertingBeforeObject || insertingAtStartOfNonEmptyTextBlock,
        entityMap,
      );

  const newContentWithSelection = setContentSelection(
    newContent.content,
    input.selection,
    newContent.selection,
  );

  return {
    content: newContentWithSelection,
    selection: newContent.selection,
    wasModified: true,
  };
}

function insertBlocks(
  input: IContentChangeInput,
  insertedBlocks: ReadonlyArray<IRawBlock>,
  blockKey: string,
  insertBefore: boolean,
  entityMap?: RawEntityMap,
): IContentChangeResult {
  if (!insertedBlocks.length) {
    return input;
  }

  const blocks = getBlocks(input.content).slice();
  const index = findBlockIndex(blocks, blockKey);
  if (index < 0) {
    return input;
  }

  const targetBlock = input.content.getBlockForKey(blockKey);
  if (!targetBlock) {
    return input;
  }

  const parentBlockTypes = getParentBlockTypes(targetBlock);
  const blocksToInsert = getBlocks(
    createContentFromRawBlocks(parentBlockTypes, insertedBlocks, entityMap),
  );

  const targetIndex = insertBefore ? index : index + 1;
  const newBlocks = [
    ...blocks.slice(0, targetIndex),
    ...blocksToInsert,
    ...blocks.slice(targetIndex),
  ];

  const newContent = createContent(newBlocks);

  // Place the selection at the end of the last inserted block if possible, otherwise just keep it
  // That would be upon inserting object block only, but that should be done with extra empty paragraphs by the caller
  const lastNewBlock = newBlocks[index + insertedBlocks.length - 1];
  assert(
    lastNewBlock,
    () => `${__filename}.insertBlocks: Item at the last new block index is not a content block.`,
  );
  const newSelection =
    getClosestSelectionAfter(newBlocks, lastNewBlock.getKey()) ?? input.selection;

  const newContentWithSelection = setContentSelection(newContent, input.selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

export function deleteObjectBlock(
  input: IContentChangeInput,
  blockKey: string,
  direction: Direction,
): IContentChangeResult {
  const { content, selection } = input;

  const keyBlock = content.getBlockForKey(blockKey);
  if (!isObjectBlock(keyBlock)) {
    return input;
  }

  const blocks = getBlocks(content);
  const filterResult = filterBlocks(blocks, (block) => block.getKey() !== blockKey);
  const newBlocks = filterResult.blocks;

  const removedIndices = filterResult.removedIndices;
  const firstRemovedBlockIndex = removedIndices[0];
  assert(
    isNumeric(firstRemovedBlockIndex),
    () => `${__filename}.deleteObjectBlock: First item in removed indices is not a number.`,
  );
  const firstRemovedBlock = blocks[firstRemovedBlockIndex];
  assert(
    firstRemovedBlock,
    () =>
      `${__filename}.deleteObjectBlock: Item at the first removed index is not a content block.`,
  );
  const lastRemovedBlockIndex = Collection.getLast(removedIndices);
  assert(
    isNumeric(lastRemovedBlockIndex),
    () => `${__filename}.deleteObjectBlock: Last item in removed indices is not a number.`,
  );
  const lastRemovedBlock = blocks[lastRemovedBlockIndex];
  assert(
    lastRemovedBlock,
    () => `${__filename}.deleteObjectBlock: Item at the last removed index is not a content block.`,
  );
  const removedSelection = createSelection(
    firstRemovedBlock.getKey(),
    0,
    lastRemovedBlock.getKey(),
    lastRemovedBlock.getLength(),
  );

  const newContent = createContent(newBlocks);
  const newSelection = getSelectionAfterRemove(content, removedSelection, newContent, direction);
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

function moveCaretToEndOfPreviousSibling(
  input: IContentChangeInput,
  blockKey: string,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const blockIndex = findBlockIndex(blocks, blockKey);
  const before = findSiblingBlock(blockIndex, blocks, Direction.Backward).block;
  if (before) {
    const newSelection = createSelection(before.getKey(), before.getLength());
    const newContent = setContentSelection(content, selection, newSelection);

    return {
      wasModified: true,
      content: newContent,
      selection: newSelection,
    };
  }

  return input;
}

function deleteBefore(input: IContentChangeInput, blockKey: string): IContentChangeResult {
  const { content } = input;
  const blocks = getBlocks(content);

  const currentBlockIndex = findBlockIndex(blocks, blockKey);
  if (currentBlockIndex < 0) {
    return input;
  }

  const customBlockSleevePosition = getCustomBlockSleevePosition(currentBlockIndex, blocks);
  const siblingBefore = findSiblingBlock(currentBlockIndex, blocks, Direction.Backward);

  switch (customBlockSleevePosition) {
    case CustomBlockSleevePosition.BeforeOwner: {
      // In case there is empty text block before, delete it and keep the caret at position
      if (
        siblingBefore.block &&
        isEmptyTextBlock(siblingBefore.block) &&
        !isCustomBlockSleeve(siblingBefore.index, blocks)
      ) {
        return deleteBlocks(
          input,
          Immutable.Set.of(siblingBefore.block.getKey()),
          Direction.Forward,
        );
      }
      return moveCaretToEndOfPreviousSibling(input, blockKey);
    }

    case CustomBlockSleevePosition.AfterOwner: {
      // Delete the owner block
      if (siblingBefore.block) {
        // In case we are after the table, just stay there
        if (isTableCell(siblingBefore.block)) {
          return input;
        }
        // Otherwise it is an object block and delete it
        return deleteObjectBlock(input, siblingBefore.block.getKey(), Direction.Backward);
      }
      break;
    }

    default: {
      const currentBlock = blocks[currentBlockIndex];
      assert(
        currentBlock,
        () => `${__filename}.deleteBefore: Current block is not a content block.`,
      );

      const _customBlockSleevePosition = getCustomBlockSleevePosition(
        currentBlockIndex - 1,
        blocks,
      );

      // In case of list item without anything before it to which the block could merge, lower the depth or convert it to paragraph
      if (
        isListItem(currentBlock) &&
        (!siblingBefore.block || _customBlockSleevePosition !== CustomBlockSleevePosition.None)
      ) {
        if (currentBlock.getDepth() > 0) {
          return adjustBlocksDepth(input, -1);
        }
        return changeBlocksType(input, () => BlockType.Unstyled);
      }

      // Ensure last empty text block is always converted to a paragraph
      if (containsSingleEmptyTextBlock(content)) {
        return changeBlocksType(input, () => BlockType.Unstyled);
      }

      // When at the start of the given nested context (start of table cell, start of editor), do not delete anything
      if (!siblingBefore.block) {
        return input;
      }

      // In case of empty text block, delete it unless the very first block
      if (isEmptyTextBlock(currentBlock)) {
        return deleteBlocks(input, Immutable.Set.of(blockKey), Direction.Backward);
      }

      // When we are in the content following the custom block, jump to its extra empty paragraph after
      if (_customBlockSleevePosition === CustomBlockSleevePosition.AfterOwner) {
        return moveCaretToEndOfPreviousSibling(input, blockKey);
      }
    }
  }

  // No special behavior in the remaining cases
  return {
    ...input,
    isUnhandled: true,
  };
}

function moveCaretToStartOfNextSibling(
  input: IContentChangeInput,
  blockKey: string,
): IContentChangeResult {
  const { content, selection } = input;

  const blocks = getBlocks(content);
  const blockIndex = findBlockIndex(blocks, blockKey);
  const after = findSiblingBlock(blockIndex, blocks, Direction.Forward).block;
  if (after) {
    const newSelection = createSelection(after.getKey());
    const newContent = setContentSelection(content, selection, newSelection);

    return {
      wasModified: true,
      content: newContent,
      selection: newSelection,
    };
  }

  return input;
}

function deleteAfter(input: IContentChangeInput, blockKey: string): IContentChangeResult {
  const { content } = input;
  const blocks = getBlocks(content);

  const currentBlockIndex = findBlockIndex(blocks, blockKey);
  if (currentBlockIndex < 0) {
    return input;
  }

  const customBlockSleevePosition = getCustomBlockSleevePosition(currentBlockIndex, blocks);
  const siblingAfter = findSiblingBlock(currentBlockIndex, blocks, Direction.Forward);

  switch (customBlockSleevePosition) {
    case CustomBlockSleevePosition.BeforeOwner: {
      if (siblingAfter.block) {
        // In case we are before the table, just stay there
        if (isTableCell(siblingAfter.block)) {
          return input;
        }
        // Otherwise it is an object block and delete it
        return deleteObjectBlock(input, siblingAfter.block.getKey(), Direction.Forward);
      }
      break;
    }

    case CustomBlockSleevePosition.AfterOwner: {
      // In case there is empty text block after, delete it and keep the caret at position
      if (
        siblingAfter.block &&
        isEmptyTextBlock(siblingAfter.block) &&
        !isCustomBlockSleeve(siblingAfter.index, blocks)
      ) {
        return deleteBlocks(
          input,
          Immutable.Set.of(siblingAfter.block.getKey()),
          Direction.Backward,
        );
      }
      return moveCaretToStartOfNextSibling(input, blockKey);
    }

    default: {
      // Ensure last empty text block is always converted to a paragraph
      if (containsSingleEmptyTextBlock(content)) {
        return changeBlocksType(input, () => BlockType.Unstyled);
      }

      // When at the end of the given nested context (end of table cell, end of editor), do not delete anything
      if (!siblingAfter.block) {
        return input;
      }

      // In case of normal empty text block, delete it unless it is the last block in the given context
      const currentBlock = blocks[currentBlockIndex];
      if (currentBlock && isEmptyTextBlock(currentBlock)) {
        return deleteBlocks(input, Immutable.Set.of(blockKey), Direction.Forward);
      }

      // When we are in the content preceding the custom block, jump to its extra empty paragraph before
      if (
        getCustomBlockSleevePosition(currentBlockIndex + 1, blocks) ===
        CustomBlockSleevePosition.BeforeOwner
      ) {
        return moveCaretToStartOfNextSibling(input, blockKey);
      }
    }
  }

  // No special behavior in the remaining cases
  return {
    ...input,
    isUnhandled: true,
  };
}

export function insertNewChars(
  input: IContentChangeInput,
  chars: string,
  forceVisualStyle?: Immutable.OrderedSet<string>,
): IContentChangeResult {
  // If selection is over more blocks, we need to remove content first because updateText can operate only within a single block
  // On the other hand we don't want to interfere with updateText within single block selection because the operation may need some metadata from the content being replaced
  const withSingleBlockSelection = isSelectionWithinOneBlock(input.selection)
    ? input
    : removeRange(input, Direction.Forward, true, true);
  const withUpdatedText = updateText(withSingleBlockSelection, chars, forceVisualStyle);
  const result = moveCaretToSelectionEnd(withUpdatedText);

  return {
    wasModified: result.content !== input.content,
    content: result.content,
    selection: result.selection,
  };
}

export function splitBlock(
  input: IContentChangeInput,
  isParagraphAllowed: boolean = true,
): IContentChangeResult {
  if (isParagraphAllowed && shouldTurnBlockToParagraphAfterEmptyList(input)) {
    return changeBlocksType(input, () => BlockType.Unstyled);
  }

  const selectionRemoved = removeRange(input);
  const entitySplit = splitEntity(selectionRemoved);

  const blockSplit = Modifier.splitBlock(entitySplit.content, entitySplit.selection);
  const withBlockSplit = {
    wasModified: true,
    content: blockSplit,
    selection: blockSplit.getSelectionAfter(),
  };

  if (isParagraphAllowed && shouldTurnBlockToParagraphAfterHeading(input)) {
    return changeBlocksType(withBlockSplit, () => BlockType.Unstyled);
  }

  return withBlockSplit;
}

function shouldTurnBlockToParagraphAfterEmptyList(input: IContentChangeInput): boolean {
  const { content, selection } = input;
  const currentFocusBlock = content.getBlockForKey(selection.getFocusKey());

  return selection.isCollapsed() && isListItem(currentFocusBlock) && !currentFocusBlock.getText();
}

function shouldTurnBlockToParagraphAfterHeading(input: IContentChangeInput): boolean {
  const { content, selection } = input;
  const currentStartBlock = content.getBlockForKey(selection.getStartKey());
  const currentEndBlock = content.getBlockForKey(selection.getEndKey());

  return isHeading(currentStartBlock) && isAtBlockEnd(currentEndBlock, selection.getEndOffset());
}

export function getNewBlockPlaceholderType(block: ContentBlock): BaseBlockType | null {
  if (!isNewBlockPlaceholder(block)) {
    return null;
  }
  return block.getData().get('type');
}

export function createNewBlockPlaceholder(
  input: IContentChangeInput,
  blockType: ObjectBlockType,
  id: Uuid,
): IContentChangeResult {
  const blockData = Immutable.Map({
    type: blockType,
    id,
  });
  const blocks: ReadonlyArray<IRawBlock> = [
    createEmptyRawParagraph(),
    createRawBlock({
      type: BlockType.NewBlockPlaceholder,
      data: blockData,
    }),
    createEmptyRawParagraph(),
  ];

  const withNewBlockPlaceholder = insertBlocksAtSelection(input, blocks);
  return withNewBlockPlaceholder;
}

export function findNewBlockPlaceholder(content: ContentState, id: Uuid): ContentBlock | undefined {
  return getBlocks(content).find(
    (block) => isNewBlockPlaceholder(block) && block.getData().get('id') === id,
  );
}

export function removeCustomBlocks(
  content: ContentState,
  predicate: (block: ContentBlock) => boolean,
): ContentState | null {
  const blocks = getBlocks(content);
  const newBlocks = blocks.filter((block, index) => {
    if (predicate(block)) {
      return false;
    }
    const customBlockSleevePosition = getCustomBlockSleevePosition(index, blocks);
    if (customBlockSleevePosition === CustomBlockSleevePosition.BeforeOwner) {
      const nextBlock = blocks[index + 1];
      if (nextBlock && predicate(nextBlock)) {
        return false;
      }
    }
    if (customBlockSleevePosition === CustomBlockSleevePosition.AfterOwner) {
      const previousBlock = blocks[index - 1];
      if (previousBlock && predicate(previousBlock)) {
        return false;
      }
    }
    return true;
  });

  if (blocks.length !== newBlocks.length) {
    const newContent = newBlocks.length > 0 ? createContent(newBlocks) : null;

    return newContent;
  }

  return content;
}

export function containsNewBlockPlaceholder(
  content: ContentState,
  placeholderBlockKey: string,
): boolean {
  return getBlocks(content).some(
    (block) => isNewBlockPlaceholder(block) && block.getKey() === placeholderBlockKey,
  );
}

function removeInlineStyles(input: IContentChangeInput): IContentChangeResult {
  const { content, selection } = input;

  const clearedStyles = Object.values(DraftJSInlineStyle);
  const newContent = clearedStyles.reduce((state, style) => {
    return Modifier.removeInlineStyle(state, selection, style);
  }, content);

  return {
    wasModified: true,
    content: newContent,
    selection: newContent.getSelectionAfter(),
  };
}

export function clearSelectionFormatting(input: IContentChangeInput): IContentChangeResult {
  const { selection } = input;

  if (selection.isCollapsed()) {
    return input;
  }

  const withEntitiesSplit = splitEntity(input);
  const withoutStyles = removeInlineStyles(withEntitiesSplit);
  const withoutEntities = removeEntities(withoutStyles);

  const selectedBlockKeys = Immutable.Set(
    getBlocksAtSelection(input.content, selection).map((block) => block.getKey()),
  );
  const withClearedFormatting = changeBlocksType(
    withoutEntities,
    () => BlockType.Unstyled,
    (block) => selectedBlockKeys.contains(block.getKey()) && isStyledTextBlock(block),
  );

  return withClearedFormatting;
}

export function contentIsPlainText(content: ContentState): boolean {
  const blockMap = content.getBlockMap();
  if (blockMap.size > 1) {
    return false;
  }

  const firstBlock = blockMap.first();
  return (
    !!firstBlock &&
    isUnstyledBlock(firstBlock) &&
    firstBlock
      .getCharacterList()
      .every((ch: CharacterMetadata) => !ch.getEntity() && ch.getStyle().isEmpty())
  );
}

export function setBlockData(
  input: IContentChangeInput,
  blockKey: string,
  blockData: Immutable.Map<string, any>,
): IContentChangeResult {
  const contentWithUpdatedBlocks = Modifier.setBlockData(
    input.content,
    createSelection(blockKey),
    blockData,
  );

  const newSelection = contentWithUpdatedBlocks.getSelectionAfter();
  const newContent = setContentSelection(contentWithUpdatedBlocks, input.selection, newSelection);

  return {
    wasModified: true,
    content: newContent,
    selection: newSelection,
  };
}

export function isContentEmpty(content: ContentState): boolean {
  const blockCount = content.getBlockMap().count();
  return blockCount === 0 || (blockCount === 1 && isEmptyParagraph(content.getBlockMap().first()));
}

export function containsSingleEmptyTextBlock(content: ContentState): boolean {
  const blockCount = content.getBlockMap().count();
  return blockCount === 1 && isEmptyTextBlock(content.getBlockMap().first());
}

export const containsSingleTextBlock = (content: ContentState): boolean => {
  const blocks = getBlocks(content);
  return blocks.length === 1 && !!blocks[0] && isTextBlock(blocks[0]);
};

export const containsText = (content: ContentState): boolean =>
  getBlocks(content).some((block) => block.getLength());

export function modifyBlocks(
  content: ContentState,
  modifier: (block: ContentBlock) => ContentBlock,
): ContentState {
  const blocks = getBlocks(content);

  const newBlocks = blocks.map(modifier);
  const hasChanged = blocks.some((block, index) => block !== newBlocks[index]);
  if (!hasChanged) {
    return content;
  }
  return createContent(newBlocks);
}

const areBlockTypesCompatible = (first: ContentBlock, second: ContentBlock): boolean =>
  first.getType() === second.getType() || (isTextBlock(first) && isTextBlock(second));

export const preserveBlockKeys = (
  input: IContentChangeInput,
  originalContent: ContentState,
): IContentChangeResult => {
  const { content } = input;

  const originalBlocks = getBlocks(originalContent);

  return changeBlock(input, (block, blockIndex) =>
    updateBlockWithOriginalKey(block, originalBlocks[blockIndex], content),
  );
};

export const preserveContentBlockKeys = (
  content: ContentState,
  originalContent: ContentState,
): ContentState => {
  const originalBlocks = getBlocks(originalContent);

  return changeBlocksInContent(content, (block, blockIndex) =>
    updateBlockWithOriginalKey(block, originalBlocks[blockIndex], content),
  );
};

const updateBlockWithOriginalKey = (
  block: ContentBlock,
  originalBlock: ContentBlock | undefined,
  content: ContentState,
) => {
  const blockKey = block.getKey();

  if (!originalBlock) {
    return block;
  }

  const originalBlockKey = originalBlock.getKey();
  if (!originalBlockKey) {
    return block;
  }

  if (
    blockKey !== originalBlockKey &&
    !content.getBlockForKey(originalBlockKey) &&
    areBlockTypesCompatible(block, originalBlock)
  ) {
    return setBlockKey(block, originalBlockKey);
  }

  return block;
};

const changeBlocksInContent = (
  content: ContentState,
  updater: (block: ContentBlock, blockIndex: number) => ContentBlock,
): ContentState => {
  const blocks = getBlocks(content);

  let changed = false;
  const newBlocks = blocks.map((block, blockIndex) => {
    const newBlock = updater(block, blockIndex);
    if (newBlock !== block) {
      changed = true;
    }

    return newBlock;
  });

  return changed ? createContent(newBlocks) : content;
};
