import { memoize } from '@kontent-ai/memoization';
import { alphabetically } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { getProjectContainerEnvironments } from '../../../../applications/projectSettings/environments/selectors/getEnvironments.ts';
import { projectIdStorage } from '../../../../localStorages/projectIdStorage.ts';
import { IPlan } from '../../../models/plans/Plan.ts';
import {
  INormalizedRolesWithSettings,
  defaultNormalizedRolesWithSettings,
} from '../../../models/roles/INormalizedRolesWithSettings.ts';
import { ISubscription } from '../../../models/subscriptions/Subscription.ts';
import { IUserProjectInfo, emptyUserProjectInfo } from '../../../models/user/UserProjectInfo.ts';
import {
  getSubscription,
  getSubscriptionPlan,
  isSubscriptionCanceled,
  isSubscriptionExpired,
  isSubscriptionSuspended,
} from '../../subscriptions/selectors/subscriptionSelectors.ts';
import { IUser } from '../IUser.type.ts';

const nullUserProjectInfo: IUserProjectInfo = emptyUserProjectInfo;

export function getProjectInfo(state: IStore, projectId: Uuid): IUserProjectInfo {
  return getProjectInfoForUser(state.data.user, projectId);
}

export function getProjectInfoForUser(userState: IUser, projectId: Uuid): IUserProjectInfo {
  return userState.projectsInfoById.get(projectId) || nullUserProjectInfo;
}

export function getProjectSubscription(state: IStore, projectId: Uuid): ISubscription {
  const project = getProjectInfo(state, projectId);
  return getSubscription(state, project.subscriptionId);
}

export function getProjectPlan(state: IStore, projectId: Uuid): IPlan {
  const projectSubscription = getProjectSubscription(state, projectId);
  return getSubscriptionPlan(state, projectSubscription.subscriptionId);
}

export function getCurrentProject(state: IStore): IUserProjectInfo {
  const {
    sharedApp: { currentProjectId },
  } = state;
  return getProjectInfo(state, currentProjectId);
}

export function getCurrentProjectId(state: IStore): Uuid {
  return getCurrentProject(state).projectId;
}

export function getCurrentSubscriptionId(state: IStore): Uuid {
  return getCurrentProject(state).subscriptionId;
}

export const getCurrentProjectName = compose(
  (currentProject) => currentProject.projectName,
  getCurrentProject,
);

export function getCurrentProjectContainer(state: IStore): IUserProjectInfo {
  const currentProjectContainerId = getCurrentProjectContainerId(state);

  return getProjectContainerInfoForUser(state.data.user, currentProjectContainerId);
}

export function getAllProjectContainerEnvironmentsUserProjectInfo(
  state: IStore,
): ReadonlyArray<IUserProjectInfo> {
  const currentProjectContainerId = getCurrentProjectContainerId(state);
  const projectEnvironmentDetails = getProjectContainerEnvironments(
    state,
    currentProjectContainerId,
    false,
  );
  const projectEnvironmentIds = projectEnvironmentDetails.map(
    (environmentDetail) => environmentDetail.projectId,
  );

  return projectEnvironmentIds.map((environmentId) =>
    getProjectInfoForUser(state.data.user, environmentId),
  );
}

export const getCurrentEnvironmentIdForProjectContainer = (
  userState: IUser,
  projectContainerId: Uuid | null,
): Uuid | null => {
  if (projectContainerId === null) {
    return null;
  }

  const lastOpenedEnvironmentId = projectIdStorage.load() || '';
  const hasAccessToLastOpenedEnvironment =
    lastOpenedEnvironmentId &&
    userState.projectsInfoById.keySeq().contains(lastOpenedEnvironmentId);
  const accessibleEnvironmentsFromContainer = userState.projectsInfoById.filter(
    (project) => project?.projectContainerId === projectContainerId,
  );
  const isLastOpenedEnvironmentFromContainer = accessibleEnvironmentsFromContainer
    .keySeq()
    .contains(lastOpenedEnvironmentId);

  return hasAccessToLastOpenedEnvironment && isLastOpenedEnvironmentFromContainer
    ? lastOpenedEnvironmentId
    : accessibleEnvironmentsFromContainer.first()?.projectId || null;
};

const getProjectContainerInfoForUser = (
  userState: IUser,
  projectContainerId: Uuid,
): IUserProjectInfo => {
  const environmentId = getCurrentEnvironmentIdForProjectContainer(userState, projectContainerId);

  if (!environmentId) {
    return nullUserProjectInfo;
  }

  return getProjectInfoForUser(userState, environmentId);
};

export function getCurrentProjectContainerId(state: IStore): Uuid {
  const {
    sharedApp: { currentProjectContainerId },
  } = state;
  return currentProjectContainerId;
}

export function getNormalizedRolesWithSettings(state: IStore): INormalizedRolesWithSettings {
  return getNormalizedRolesWithSettingsForUser(state.data.user, getCurrentProject(state).projectId);
}

export function getNormalizedRolesWithSettingsForUser(
  userState: IUser,
  projectId: Uuid,
): INormalizedRolesWithSettings {
  return (
    userState.normalizedRolesWithSettingsPerProject.get(projectId) ||
    defaultNormalizedRolesWithSettings
  );
}

export function getCurrentProjectContainerSubscription(state: IStore): ISubscription {
  const currentProject = getCurrentProjectContainer(state);
  return getProjectSubscription(state, currentProject.projectId);
}

export function getCurrentProjectSubscription(state: IStore): ISubscription {
  const {
    sharedApp: { currentProjectId },
  } = state;
  return getProjectSubscription(state, currentProjectId);
}

export function getCurrentProjectPlan(state: IStore): IPlan {
  const {
    sharedApp: { currentProjectId },
  } = state;
  return getProjectPlan(state, currentProjectId);
}

export function getCurrentProjectContainerPlan(state: IStore): IPlan {
  const subscription = getCurrentProjectContainerSubscription(state);
  return getSubscriptionPlan(state, subscription.subscriptionId);
}

export const getSortedUserProjects = memoize.maxOne(
  (userProjectsInfo: Immutable.Map<Uuid, IUserProjectInfo>): Immutable.List<IUserProjectInfo> => {
    return userProjectsInfo
      .sort((a: IUserProjectInfo, b: IUserProjectInfo) =>
        alphabetically(a.environmentName, b.environmentName),
      )
      .toList();
  },
);

export function isProjectPlanExpired(state: IStore, projectId: Uuid): boolean {
  const subscription = getProjectSubscription(state, projectId);
  return isSubscriptionExpired(subscription);
}

export function isProjectPlanSuspended(state: IStore, projectId: Uuid): boolean {
  const subscription = getProjectSubscription(state, projectId);
  return isSubscriptionSuspended(subscription);
}

export function isProjectTrial(state: IStore, projectId: Uuid): boolean {
  const plan = getProjectPlan(state, projectId);
  return plan.isTrial && !isProjectPlanExpired(state, projectId);
}

export function isProjectPlanCanceled(state: IStore, projectId: Uuid): boolean {
  const subscription = getProjectSubscription(state, projectId);
  return isSubscriptionCanceled(subscription);
}

export function hasUserSeenLatestProjectUpdates(state: IStore): boolean {
  const {
    data: { latestProductUpdateInfo },
    sharedApp: {
      userProperties: { productUpdateOpenedAt },
    },
  } = state;

  return (
    !!latestProductUpdateInfo &&
    (!productUpdateOpenedAt ||
      new Date(productUpdateOpenedAt) < new Date(latestProductUpdateInfo.publishedAt))
  );
}

export function getSubscriptionIdFromCurrentProject(state: IStore): Uuid {
  const currentProject = getCurrentProject(state);
  return currentProject.subscriptionId;
}
