import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IListingOrderByStorage } from '../../../../../localStorages/listingOrderByStorage.ts';
import { AssetLibrary_Initialized } from '../../constants/assetLibraryActionTypes.ts';
import { AssetQueryOrigin } from '../../utils/assetLibraryQueryUtils.ts';

interface IDeps {
  readonly ensureAssetLibraryRelatedData: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadAndFixAssetQuery: (
    origin: AssetQueryOrigin,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly listingOrderByStorage: IListingOrderByStorage;
}

const assetLibraryInitialized = { type: AssetLibrary_Initialized } as const;

export type InitializeAssetLibraryActionsType = typeof assetLibraryInitialized;

export const createInitializeAssetLibraryAction =
  (deps: IDeps) =>
  (origin: AssetQueryOrigin, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await dispatch(deps.ensureAssetLibraryRelatedData(abortSignal));
    await dispatch(deps.loadAndFixAssetQuery(origin, abortSignal));

    dispatch(assetLibraryInitialized);
  };
