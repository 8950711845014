import { ISelectSection } from '@kontent-ai/component-library/Selects';
import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { DefaultWorkflowId } from '../../../_shared/constants/variantIdValues.ts';
import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import {
  IAssignmentWorkflowStep,
  RegularWorkflowStep,
} from '../../../data/models/workflow/WorkflowStep.ts';
import { IWorkflowStepTrigger } from '../models/IWorkflowStepTrigger.type.ts';

type GetTooltip = (step: IAssignmentWorkflowStep) => string | undefined;

const createMapStepToTrigger = (getTooltip: GetTooltip) => (step: IAssignmentWorkflowStep) => ({
  color: step.color,
  id: step.id,
  label: step.name,
  name: step.name,
  tooltip: getTooltip(step),
});

const convertRegularStepToAssignmentStep = (workflow: Workflow) => (step: RegularWorkflowStep) => ({
  ...step,
  workflowId: workflow.id,
});

export const getWorkflowStepTriggers = memoize.weak(
  (
    workflowsMap: ReadonlyMap<Uuid, Workflow>,
    getTooltip: GetTooltip,
  ): ReadonlyArray<ISelectSection<IWorkflowStepTrigger>> => {
    const workflows = Collection.getValues(workflowsMap);
    const defaultWorkflow = workflows.find((workFlow) => workFlow.id === DefaultWorkflowId);

    const mapStepToTrigger = createMapStepToTrigger(getTooltip);

    return workflows
      .map((workflow): ISelectSection<IWorkflowStepTrigger> => {
        return {
          type: 'section',
          id: workflow.id,
          label: workflow.name,
          items: workflow.steps
            .map(convertRegularStepToAssignmentStep(workflow))
            .map(mapStepToTrigger),
        };
      })
      .concat(
        defaultWorkflow
          ? [
              {
                type: 'section',
                id: 'all-workflows',
                label: 'All workflows',
                items: [
                  defaultWorkflow.archivedStep,
                  defaultWorkflow.publishedStep,
                  defaultWorkflow.scheduledStep,
                ]
                  .map(convertRegularStepToAssignmentStep(defaultWorkflow))
                  .map(mapStepToTrigger),
              },
            ]
          : [],
      );
  },
);
