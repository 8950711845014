import { Action } from '../../../../../@types/Action.type.ts';
import {
  Calendar_LoadItemsWithVariantActiveInPeriod_Started,
  Calendar_Page_Left,
} from '../../constants/calendarActionTypes.ts';

const initialState: Uuid = '';

export const cancellationToken = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Calendar_LoadItemsWithVariantActiveInPeriod_Started: {
      return action.payload.cancellationToken;
    }

    case Calendar_Page_Left: {
      return initialState;
    }

    default:
      return state;
  }
};
