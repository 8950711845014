import { InputState } from '@kontent-ai/component-library/Input';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ErrorTag } from '@kontent-ai/component-library/Selects';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { joinWithOxfordComma } from '../../../../../_shared/utils/stringUtils.ts';
import {
  MapiKeyDisabledEnvironment,
  MapiKeyDisabledEnvironments,
  MapiKeyLimitationsTooltip,
} from '../../constants/ApiKeyDetailTemplates.ts';
import { ApiKey } from '../../models/ApiKey.ts';
import { EnvironmentOption } from '../../selectors/getSelectedEnvironmentOptions.ts';

type ReadonlyMapiEnvironmentsSelectorProps = {
  readonly allEnvironmentOptions: ReadonlyArray<EnvironmentOption>;
  readonly apiKey: ApiKey;
};

export const ReadonlyMapiEnvironmentsSelector: React.FC<ReadonlyMapiEnvironmentsSelectorProps> = ({
  allEnvironmentOptions,
  apiKey,
}) => {
  const disabledEnvironmentNames = allEnvironmentOptions
    .filter(
      (environment) => apiKey.environments.includes(environment.id) && !environment.isApiEnabled,
    )
    .map((environment) => environment.label);

  return (
    <Stack spacing={Spacing.XL}>
      <Label size={LabelSize.L}>The API key has access only to the following environments:</Label>
      <MultiSelect<EnvironmentOption>
        caption={getCaption(disabledEnvironmentNames)}
        label="Environments"
        items={allEnvironmentOptions}
        selectedItemIds={apiKey.environments}
        inputState={InputState.Disabled}
        tooltipPlacement="bottom"
        tooltipText={MapiKeyLimitationsTooltip(apiKey.name)}
        renderSelectedOption={renderSelectedEnvironmentOption}
        inputDataAttributes={getDataUiInputAttribute(DataUiInput.Environments)}
      />
    </Stack>
  );
};

ReadonlyMapiEnvironmentsSelector.displayName = 'ReadonlyMapiEnvironmentsSelector';

const getCaption = (disabledEnvironmentNames: ReadonlyArray<string>): string =>
  disabledEnvironmentNames.length === 0
    ? ''
    : disabledEnvironmentNames.length === 1
      ? MapiKeyDisabledEnvironment(disabledEnvironmentNames)
      : MapiKeyDisabledEnvironments(joinWithOxfordComma(disabledEnvironmentNames));

const renderSelectedEnvironmentOption = (
  _id: string,
  environmentOption: EnvironmentOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return environmentOption.isApiEnabled ? (
    <DefaultTag {...defaultTagProps} label={environmentOption.label} />
  ) : (
    <ErrorTag
      {...defaultTagProps}
      label={environmentOption.label}
      customTooltipText={MapiKeyDisabledEnvironment(environmentOption.label)}
      disabled
    />
  );
};
