import { UnreachableCaseException } from '@kontent-ai/errors';
import React from 'react';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import {
  colorAlertBackground,
  colorAlertIcon,
  colorInfoBackground,
  colorInfoIcon,
  colorWarningBackground,
  colorWarningIcon,
} from '../../tokens/decision/colors.ts';
import { IconSize } from '../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { Typography } from '../../tokens/quarks/typography.ts';
import { Icons } from '../Icons/components/icons.ts';
import { StyledCallout } from './components/StyledCallout.tsx';
import { StyledSubheadline } from './components/StyledSubheadline.tsx';
import { CalloutType } from './types.ts';

export interface ICalloutProps {
  readonly calloutType: CalloutType;
  readonly children: React.ReactNode;
  readonly headline?: string;
  readonly hideSubheadline?: boolean;
  readonly maxWidth?: number;
  readonly renderActions?: () => React.ReactNode;
}

const getIcon = (calloutType: CalloutType) => {
  const commonProps = {
    size: IconSize.S,
  };

  switch (calloutType) {
    case 'warning':
      return <Icons.ExclamationTriangle color={colorAlertIcon} {...commonProps} />;
    case 'friendlyWarning':
      return <Icons.ExclamationTriangle color={colorWarningIcon} {...commonProps} />;
    case 'quickTip':
      return <Icons.ICircle color={colorInfoIcon} {...commonProps} />;
    default:
      throw UnreachableCaseException(calloutType);
  }
};

const getSubheadlineText = (calloutType: CalloutType) => {
  switch (calloutType) {
    case 'warning':
      return 'Warning';
    case 'friendlyWarning':
      return 'Friendly warning';
    case 'quickTip':
      return 'Quick tip';
    default:
      throw UnreachableCaseException(calloutType);
  }
};

const getBackgroundColor = (calloutType: CalloutType) => {
  switch (calloutType) {
    case 'quickTip':
      return colorInfoBackground;
    case 'friendlyWarning':
      return colorWarningBackground;
    case 'warning':
      return colorAlertBackground;
    default:
      throw UnreachableCaseException(calloutType);
  }
};

const getBorderColor = (calloutType: CalloutType) => {
  switch (calloutType) {
    case 'warning':
      return colorAlertIcon;
    case 'friendlyWarning':
      return colorWarningIcon;
    case 'quickTip':
      return colorInfoIcon;
    default:
      throw UnreachableCaseException(calloutType);
  }
};

export const Callout = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ICalloutProps>>(
  (
    { calloutType, children, headline, hideSubheadline, renderActions, maxWidth, ...otherProps },
    forwardedRef,
  ) => (
    <StyledCallout
      backgroundColor={getBackgroundColor(calloutType)}
      borderColor={getBorderColor(calloutType)}
      maxWidth={maxWidth}
      ref={forwardedRef}
      {...otherProps}
    >
      <Stack spacing={Spacing.L}>
        {!hideSubheadline && (
          <Row noWrap spacing={Spacing.S}>
            <Column width="content">{getIcon(calloutType)}</Column>
            <Column>
              <StyledSubheadline calloutType={calloutType}>
                {getSubheadlineText(calloutType)}
              </StyledSubheadline>
            </Column>
          </Row>
        )}
        {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
        {headline && <h1 css={`${Typography.HeadlineMedium};`}>{headline}</h1>}
        <div>{children}</div>
        {renderActions?.()}
      </Stack>
    </StyledCallout>
  ),
);

Callout.displayName = 'Callout';
