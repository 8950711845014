import { ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { ItemColumnCode } from '../../../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../../../../_shared/models/OrderBy.ts';
import { Content_ContentItemListingFilter_Clear } from '../../../../constants/contentActionTypes.ts';

const cleared = (
  previousOrderBy: OrderBy<ItemColumnCode> | null,
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined,
) =>
  ({
    type: Content_ContentItemListingFilter_Clear,
    payload: {
      previousOrderBy,
      forcedContentTypeIds,
    },
  }) as const;

export type ClearContentItemListingFilterActionsType = ReturnType<typeof cleared>;

export const createClearContentItemListingFilterAction =
  () =>
  (forcedContentTypeIds?: ReadonlySet<Uuid>): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: {
        listingUi: { fullTextSearchContext },
      },
    } = getState();

    dispatch(cleared(fullTextSearchContext?.previousOrderBy ?? null, forcedContentTypeIds));
  };
