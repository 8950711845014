import { noOperation } from '@kontent-ai/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentStatusLanguagesFilterStorage } from '../../../../localStorages/contentStatusLanguagesFilterStorage.ts';

export const LanguageFilterContext = React.createContext<{
  selectedLanguageIds: ReadonlySet<Uuid>;
  setSelectedLanguageIds: (ids: ReadonlySet<Uuid>) => void;
}>({
  selectedLanguageIds: new Set(),
  setSelectedLanguageIds: noOperation,
});

export const LanguageFilterContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [selectedLanguageIds, setSelectedLanguageIds] = useState<ReadonlySet<Uuid>>(new Set());
  const languagesWithViewCapability = useSelector((state) => {
    const { projectId } = getCurrentProject(state);
    return getAllActiveLanguagesForCurrentUserInAnyCollection(
      state.data.user,
      projectId,
      state.data.languages,
      Capability.ViewContent,
    );
  });
  const memoizedLanguagesWithViewCapability = useMemo(
    () => languagesWithViewCapability.toArray(),
    [languagesWithViewCapability],
  );
  const currentProjectId = useSelector(getCurrentProjectId);

  useEffect(() => {
    const maybeInconsistentLanguageIds = ContentStatusLanguagesFilterStorage.load(currentProjectId);
    const preselectedLanguageIds = memoizedLanguagesWithViewCapability
      .filter(({ id: languageId }) => maybeInconsistentLanguageIds.has(languageId))
      .map(({ id }) => id);
    setSelectedLanguageIds(new Set(preselectedLanguageIds));
  }, [memoizedLanguagesWithViewCapability, currentProjectId]);

  useEffect(() => {
    ContentStatusLanguagesFilterStorage.save(selectedLanguageIds, currentProjectId);
  }, [selectedLanguageIds, currentProjectId]);

  const value = useMemo(
    () => ({
      selectedLanguageIds,
      setSelectedLanguageIds: (ids: ReadonlySet<Uuid>) => setSelectedLanguageIds(ids),
    }),
    [selectedLanguageIds],
  );

  return <LanguageFilterContext.Provider value={value}>{children}</LanguageFilterContext.Provider>;
};
