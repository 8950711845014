import Immutable from 'immutable';
import { IBillingPeriodReportServerModel } from '../../../../repositories/serverModels/BillingPeriodReportServerModel.type.ts';
import { IFupMetricSummary, getFupMetricSummaryFromServerModel } from './FupMetricSummary.ts';
import { IPackageSummary, getPackageSummaryFromServerModel } from './PackageSummary.ts';
import { IPaidMetricSummary, getPaidMetricSummaryFromServerModel } from './PaidMetricSummary.ts';

export interface IBillingPeriodReport {
  readonly createdAt: DateTimeStamp;
  readonly extraCosts: number;
  readonly fupMetricSummaries: Immutable.List<IFupMetricSummary>;
  readonly monthlyFee: number;
  readonly packagesCosts: number;
  readonly packageSummaries: Immutable.List<IPackageSummary>;
  readonly paidMetricSummaries: Immutable.List<IPaidMetricSummary>;
  readonly periodEnd: DateTimeStamp;
  readonly periodStart: DateTimeStamp;
  readonly planId: Uuid;
  readonly reportId: Uuid;
  readonly reportType: string;
  readonly subscriptionId: Uuid;
  readonly totalCosts: number;
}

export const createBillingPeriodReportDomainModel = (
  serverModel: IBillingPeriodReportServerModel,
): IBillingPeriodReport => {
  if (typeof serverModel !== 'object') {
    throw new Error('Billing period report is not an object');
  }

  const fupMetricSummaries = (serverModel.fupMetricSummaries || []).map(
    getFupMetricSummaryFromServerModel,
  );
  const packageSummaries = (serverModel.packageSummaries || []).map(
    getPackageSummaryFromServerModel,
  );
  const paidMetricSummaries = (serverModel.paidMetricSummaries || []).map(
    getPaidMetricSummaryFromServerModel,
  );

  return {
    createdAt: serverModel.createdAt ?? '',
    extraCosts: serverModel.extraCosts ?? 0,
    fupMetricSummaries: Immutable.List(fupMetricSummaries) ?? Immutable.List<IFupMetricSummary>(),
    monthlyFee: serverModel.monthlyFee ?? 0,
    packagesCosts: serverModel.packagesCosts ?? 0,
    packageSummaries: Immutable.List(packageSummaries) ?? Immutable.List<IPackageSummary>(),
    paidMetricSummaries:
      Immutable.List(paidMetricSummaries) ?? Immutable.List<IPaidMetricSummary>(),
    periodEnd: serverModel.periodEnd ?? '',
    periodStart: serverModel.periodStart ?? '',
    planId: serverModel.planId ?? '',
    reportId: serverModel.reportId ?? '',
    reportType: serverModel.reportType ?? '',
    subscriptionId: serverModel.subscriptionId ?? '',
    totalCosts: serverModel.totalCosts ?? 0,
  };
};
