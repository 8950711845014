import React from 'react';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Chevron } from '../../Chevron/Chevron.tsx';

export const CollapsibleVerticalMenuItemIcon: React.FC<{
  readonly hasChildren: boolean;
  readonly isExpanded: boolean;
}> = ({ isExpanded, hasChildren }) => {
  if (hasChildren) {
    return <Chevron size={IconSize.S} direction={isExpanded ? 'down' : 'right'} />;
  }

  return <Chevron direction="up" css="visibility: hidden" />;
};

CollapsibleVerticalMenuItemIcon.displayName = 'CollapsibleVerticalMenuItemIcon';
