export const getComposedElementCodename = (
  elementCodename: string | null,
  snippetCodename?: string | null,
): string | null => {
  if (snippetCodename && elementCodename) {
    return `${snippetCodename}__${elementCodename}`;
  }
  return elementCodename;
};

export const getSnippetCodenameElementPrefix = (snippetCodename: string): string | null =>
  `${snippetCodename}__`;
