import { Action } from '../../../../../@types/Action.type.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import {
  Taxonomy_Groups_Archived,
  Taxonomy_Groups_LoadingStarted,
  Taxonomy_Groups_RestorationInfoFaded,
  Taxonomy_Groups_Restored,
  Taxonomy_Groups_SelectionChanged,
} from '../../constants/taxonomyActionTypes.ts';
import { getTaxonomyGroupListStatusInfoMessage } from '../../utils/getTaxonomyGroupListStatusInfoMessage.ts';

const initialState: IStatusInfoMessage = getTaxonomyGroupListStatusInfoMessage({});

export const taxonomyGroupListStatusInfoMessage = (
  state: IStatusInfoMessage = initialState,
  action: Action,
): IStatusInfoMessage => {
  switch (action.type) {
    case Taxonomy_Groups_LoadingStarted: {
      return initialState;
    }

    case Taxonomy_Groups_SelectionChanged: {
      const { selectedTaxonomyGroupIds } = action.payload;
      return getTaxonomyGroupListStatusInfoMessage({
        selectedTaxonomyGroupIds,
      });
    }

    case Taxonomy_Groups_Restored: {
      const { groupIds } = action.payload;
      return getTaxonomyGroupListStatusInfoMessage({
        restoredTaxonomyGroups: groupIds,
      });
    }

    case Taxonomy_Groups_RestorationInfoFaded: {
      const { originalStatusInfoMessage } = action.payload;
      return state === originalStatusInfoMessage ? initialState : state;
    }

    case Taxonomy_Groups_Archived: {
      const { groupIds } = action.payload;
      return getTaxonomyGroupListStatusInfoMessage({
        deletedTaxonomyGroups: groupIds,
      });
    }

    default:
      return state;
  }
};
