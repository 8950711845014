import { combineReducers } from '@reduxjs/toolkit';
import { filter } from './internalReducers/filter.ts';
import { listingLoadingStatus } from './internalReducers/listingLoadingStatus.ts';
import { projectContributors } from './internalReducers/projectContributors.ts';
import { sortBy } from './internalReducers/sortBy.ts';
import { userDetail } from './internalReducers/userDetail.ts';
import { userInvitation } from './internalReducers/userInvitation.ts';
import { usersCountedInUsage } from './internalReducers/usersCountedInUsage.ts';

export const usersReducer = combineReducers({
  filter,
  listingLoadingStatus,
  projectContributors,
  sortBy,
  userDetail,
  userInvitation,
  usersCountedInUsage,
});
