import { Action } from '../../../../@types/Action.type.ts';
import { DefaultDisplayTimeZoneId } from '../../../utils/dateTime/timeZoneUtils.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduledToPublish_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

export function scheduledPublishDisplayTimeZone(state = DefaultDisplayTimeZoneId, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.scheduledPublishDisplayTimeZone;

    case ChangeWorkflowStep_ScheduledToPublish_Changed:
      return action.payload.timeZoneId;

    default:
      return state;
  }
}
