import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { openAssetFolder } from '../../actions/thunkAssetLibraryActions.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { AssetFoldersBreadcrumbs } from './AssetFoldersBreadcrumbs.tsx';

type Props = {
  readonly clearAssetSelection: () => void;
  readonly orderBy: AssetsOrderBy;
};

export const AssetFoldersBreadcrumbsInAssetSelector: React.FC<Props> = ({
  clearAssetSelection,
  orderBy,
}) => {
  const dispatch = useDispatch();

  const openFolder = async (assetFolderId: Uuid): Promise<void> => {
    clearAssetSelection();

    const { folderOpened } = await dispatch(openAssetFolder({ assetFolderId, orderBy }));
    if (!folderOpened) {
      await dispatch(openAssetFolder({ assetFolderId: RootFolderId, orderBy }));
    }
  };

  return (
    <AssetFoldersBreadcrumbs
      onFolderOpen={openFolder}
      onFolderReset={() => openFolder(RootFolderId)}
    />
  );
};
