import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isAnyItemEditorMutationActive } from '../../safeRedirect/selectors/isAnyItemEditorMutationActive.ts';
import { usePendingDialog } from '../hooks/usePendingDialog.ts';
import { IPendingChangesDialogProps } from '../types/IPendingChangesDialogProps.type.ts';
import { PendingStatus } from './PendingStatus.tsx';
import { PendingStatusAlign } from './PendingStatusAlign.tsx';

export const UnsavedChangesDialog: React.FC<IPendingChangesDialogProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { isPending, showDialog, hasError } = usePendingDialog(
    useSelector(isAnyItemEditorMutationActive),
    onConfirm,
  );

  if (!showDialog) {
    return null;
  }

  const body = (
    <Stack spacing={Spacing.XL}>
      <PendingStatusAlign>
        <PendingStatus
          errorLabel="Saving changes failed. You need to return to the content item"
          finishedLabel="Saved"
          hasError={hasError}
          isPending={isPending}
          pendingLabel="Saving"
        />
      </PendingStatusAlign>
      <Box component="p">
        Please wait until all changes finish saving.
        <br />
        If you leave before saving is finished, your changes may be lost.
      </Box>
    </Stack>
  );

  const disabledButtonTooltip = hasError
    ? 'Saving changes has failed, you need to return to the content item.'
    : 'The changes are saved, you need to return to the content item.';

  const isLeaveAvailable = isPending;
  const footerLeft = (
    <Button
      buttonStyle="secondary"
      destructive
      destructiveIcon={Icons.Bin}
      disabled={!isLeaveAvailable}
      tooltipText={isLeaveAvailable ? undefined : disabledButtonTooltip}
      onClick={onConfirm}
      {...getDataUiActionAttribute(DataUiAction.DiscardAndNavigate)}
    >
      Leave and discard changes
    </Button>
  );

  const footerRight = (
    <Button autoFocus buttonStyle="primary" onClick={onCancel}>
      Return to the content item
    </Button>
  );

  return (
    <ModalDialog
      dataUiElement={DataUiElement.UnsavedChangesDialog}
      headerContent="Saving changes"
      bodyContent={body}
      footerContentLeft={footerLeft}
      footerContentRight={footerRight}
      onClose={onCancel}
    />
  );
};

UnsavedChangesDialog.displayName = 'UnsavedChangesDialog';
