export enum TimeInterval {
  CurrentWeek = 'CurrentWeek',
  CurrentMonth = 'CurrentMonth',
  PastThirtyDays = 'PastThirtyDays',
}

const timeIntervalToLabelMap = {
  [TimeInterval.CurrentMonth]: 'Current month',
  [TimeInterval.CurrentWeek]: 'Current week',
  [TimeInterval.PastThirtyDays]: 'Past 30 days',
} as const;

export const timeIntervals = Object.entries(timeIntervalToLabelMap).map(([id, label]) => ({
  id,
  label,
}));
