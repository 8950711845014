import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import React, { ChangeEventHandler, useCallback, useState } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getInvalidInputReason, isValidInput } from './imageEditorInputUtils.ts';

type ImageEditorInputProps = {
  readonly dataUiInput: DataUiInput;
  readonly initialValue: number;
  readonly name: string;
  readonly originalDimension: number;
  readonly onChange: (newValue: string, isValid: boolean) => void;
};

export const ImageEditorInput: React.FC<ImageEditorInputProps> = ({
  dataUiInput,
  initialValue,
  name,
  onChange,
  originalDimension,
}) => {
  const [value, setValue] = useState<string | null>(initialValue.toString());

  const onInputChange = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (e) => {
      const inputValue = e.target.value;
      setValue(inputValue);

      const isChangedInputValid = isValidInput(inputValue, originalDimension, name);

      onChange(inputValue, isChangedInputValid);
    },
    [originalDimension, name, onChange],
  );

  const invalidInputReason = getInvalidInputReason(value, originalDimension, name);

  return (
    <Input
      inputState={invalidInputReason ? InputState.Alert : InputState.Default}
      caption={invalidInputReason}
      type={InputType.Text}
      name={name}
      label={name}
      min={1}
      value={value ?? ''}
      onChange={onInputChange}
      auxiliaryElements={
        <Label size={LabelSize.L} color={BaseColor.Black}>
          px
        </Label>
      }
      {...getDataUiInputAttribute(dataUiInput)}
    />
  );
};

ImageEditorInput.displayName = 'ImageEditorInput';
