import { IMultipleChoiceTypeElement } from '../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import {
  EmptyMultipleChoiceItemElement,
  IMultipleChoiceItemElement,
} from '../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createMultipleChoiceItemElement: ItemElementCreator<
  IMultipleChoiceTypeElement,
  IMultipleChoiceItemElement
> = (multipleChoiceTypeElement, omitDefaultValue): IMultipleChoiceItemElement => ({
  ...EmptyMultipleChoiceItemElement,
  elementId: multipleChoiceTypeElement.elementId,
  options: omitDefaultValue
    ? EmptyMultipleChoiceItemElement.options
    : multipleChoiceTypeElement.defaultValue,
});
