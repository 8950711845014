import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  ICancellablePromise,
  makeCancellablePromise,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect, useRef, useState } from 'react';
import { getLandingPageLinks } from '../../../../_shared/constants/landingPageLinks.ts';

enum RequestStatus {
  None = 'None',
  IsWaiting = 'IsWaiting',
  HasFailed = 'HasFailed',
}

interface IAcceptSubscriptionTermsOfServiceProps {
  readonly invalidationReason: string | null;
  readonly onAcceptTermsOfService: () => Promise<boolean>;
  readonly subscriptionName: string;
}

export const AcceptSubscriptionTermsOfService: React.FC<IAcceptSubscriptionTermsOfServiceProps> = ({
  invalidationReason,
  onAcceptTermsOfService,
  subscriptionName,
}) => {
  const [requestStatus, setRequestStatus] = useState(RequestStatus.None);
  const acceptPromiseRef = useRef<ICancellablePromise | null>(null);

  useEffect(() => {
    return () => acceptPromiseRef.current?.cancel();
  }, []);

  const acceptTermsOfService = () => {
    setRequestStatus(RequestStatus.IsWaiting);

    acceptPromiseRef.current = makeCancellablePromise(onAcceptTermsOfService)
      .then((result) => {
        if (!result) {
          setRequestStatus(RequestStatus.HasFailed);
        }
      })
      .catch(swallowCancelledPromiseError);
  };

  const isGdprForgotten = invalidationReason === 'gdprforgotten';
  const isWaiting = requestStatus === RequestStatus.IsWaiting;
  const hasFailed = requestStatus === RequestStatus.HasFailed;
  const headerText = isGdprForgotten
    ? 'We need you to agree with our Terms of Service'
    : 'Introducing Kontent.ai + updated Terms of Service';

  return (
    <Box overflowY="auto">
      <div className="subscription-accept-terms-of-service">
        <Stack spacing={Spacing.XL}>
          <Stack align="center" spacing={Spacing.S}>
            <h2 className="subscription-accept-terms-of-service__header">{headerText}</h2>
            <div className="subscription-accept-terms-of-service__message">
              <Stack spacing={Spacing.L}>
                {isGdprForgotten ? (
                  <>
                    <p>
                      At Kontent.ai, we take data privacy, including GDPR compliance, seriously.
                      That’s why we’ve removed data about someone on your subscription. That means
                      we don’t have any agreement from your subscription to our current Terms of
                      Service.
                    </p>
                    <p>
                      Please take a moment to review the current&nbsp;
                      <OutwardLink href={getLandingPageLinks().termsOfService}>
                        Terms of Service
                      </OutwardLink>
                      &nbsp;for your subscription with the name: <strong>{subscriptionName}</strong>
                      .
                    </p>
                    <p>
                      If you have further questions, don’t hesitate to contact our Support team.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      For the last 7 years, we’ve provided services to you and hundreds of other
                      businesses around the world, operating as a business unit of Kentico Software.
                      We’re excited to announce that we’re now operating as a standalone company
                      called Kontent.ai.
                    </p>
                    <p>
                      To support the growth of our newly independent company, we’ve raised $40
                      million in growth capital from Expedition Growth Capital. With these funds,
                      we’ll expand our engineering and commercial teams, as well as expand our
                      global footprint.
                    </p>
                    <p>
                      From a business perspective, the platform you know and love stays the same.
                      But since we’re changing legal entities, we’ve updated our Terms of Service to
                      reflect that. You can&nbsp;
                      <OutwardLink href={getLandingPageLinks().termsOfService}>
                        review the new Terms of Service
                      </OutwardLink>
                      &nbsp;and accept them by clicking the button below.
                    </p>
                    <p>
                      For more information about our new brand,&nbsp;
                      <OutwardLink href={getLandingPageLinks().newBrandAnnouncement}>
                        read our announcement
                      </OutwardLink>
                      .
                    </p>
                    <p>If you have any questions, don’t hesitate to contact our Support team.</p>
                    <p>
                      We sincerely thank you for choosing Kontent.ai for your content needs, and
                      we’re looking forward to an exciting road ahead!
                    </p>
                  </>
                )}
              </Stack>
            </div>
          </Stack>
          <Stack align="center" spacing={Spacing.M}>
            <Button buttonStyle="primary" onClick={acceptTermsOfService} size="medium">
              {isWaiting && <Button.ProgressIcon />}
              <Button.Label>I accept the Terms of Service</Button.Label>
            </Button>
            {hasFailed && (
              <div className="subscription-accept-terms-of-service__error-message">
                Signing has failed.
              </div>
            )}
          </Stack>
        </Stack>
      </div>
    </Box>
  );
};

AcceptSubscriptionTermsOfService.displayName = 'AcceptSubscriptionTermsOfService';
