import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IElementPathItem } from '../../../../../richText/plugins/contentComponents/api/traverseComponentUtils.ts';
import { getContentGroupForElement } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import { CreateContentGroupTabsId } from '../../utils/contentGroupTabsId.ts';
import { ISelectContentGroupAction } from './selectContentGroup.ts';

export type ISelectContentGroupsByElementPath = (
  reason: ContentGroupSelectionReason,
  elementPath: Immutable.List<IElementPathItem>,
) => ThunkFunction;

interface IDeps {
  readonly selectContentGroup: ISelectContentGroupAction;
}

export const createSelectContentGroupsByElementPathAction =
  (deps: IDeps): ISelectContentGroupsByElementPath =>
  (reason, elementPath): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: { loadedContentItemTypes },
    } = getState();

    elementPath.forEach((pathItem: IElementPathItem) => {
      const type = loadedContentItemTypes.get(pathItem.typeId);
      const groupId = getContentGroupForElement(type?.contentElements ?? [], pathItem.elementId);
      const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(pathItem.itemId);

      dispatch(
        deps.selectContentGroup({
          contentGroupTabsId,
          contentGroupId: groupId,
          reason,
        }),
      );
    });
  };
