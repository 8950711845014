import React, { useCallback } from 'react';
import { StatusBarActions } from '../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { StatusMessageTypes } from '../../../_shared/constants/statusMessageType.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { updatePreviewConfigurationFromToolbar } from '../actions/thunkPreviewConfigurationActions.ts';

export const PreviewConfigurationToolbarActions: React.FC = () => {
  const dispatch = useDispatch();
  const isBeingSaved = useSelector(
    (s) => s.previewConfigurationApp.status.messageType === StatusMessageTypes.Saving,
  );
  const isLoading = useSelector((s) => s.previewConfigurationApp.previewConfiguration === null);

  const save = useCallback(() => dispatch(updatePreviewConfigurationFromToolbar()), []);

  return <StatusBarActions isBeingSaved={isBeingSaved} isLoading={isLoading} onSave={save} />;
};

PreviewConfigurationToolbarActions.displayName = 'PreviewConfigurationToolbarActions';
