import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { ILanguageRepository } from '../../../../../repositories/interfaces/ILanguageRepository.type.ts';
import { ILanguagesServerModel } from '../../../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';
import { LocalizationEditor_LanguagesLoading_Success } from '../../constants/localizationActionTypes.ts';

interface ILoadProjectLanguagesDeps {
  readonly languageRepository: Pick<ILanguageRepository, 'getLanguages'>;
  readonly contentVariantIdStorage: {
    readonly load: () => Uuid | null;
    readonly save: (variantId: Uuid) => void;
  };
}

const languagesLoadSuccess = (projectLanguages: ILanguagesServerModel) =>
  ({
    type: LocalizationEditor_LanguagesLoading_Success,
    payload: {
      projectLanguages,
    },
  }) as const;

export type LanguagesLoadSuccess = ReturnType<typeof languagesLoadSuccess>;

export const createLoadLanguagesForLocalizationEditorAction =
  (deps: ILoadProjectLanguagesDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const projectLanguages = await deps.languageRepository.getLanguages(abortSignal);
    const savedVariantId = deps.contentVariantIdStorage.load();
    const savedVariantExists = projectLanguages.languages.some(
      (lang) => lang.id === savedVariantId && !lang.inactive,
    );

    if (!savedVariantExists) {
      deps.contentVariantIdStorage.save(DefaultVariantId);
    }

    dispatch(languagesLoadSuccess(projectLanguages));
  };
