import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Webhooks_ListingInit_Finished,
  Webhooks_ListingInit_Started,
} from '../../../../applications/webhooks/constants/webhooksActionTypes.ts';

export function byIdLoadingStatus(
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case Webhooks_ListingInit_Started:
      return LoadingStatus.Loading;

    case Webhooks_ListingInit_Finished:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
}
