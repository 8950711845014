import { assert } from '@kontent-ai/utils';
import { ContentBlock } from 'draft-js';
import { getNestingLevel } from './blockTypeUtils.ts';

export interface IBlockHierarchyNode {
  readonly block: ContentBlock;
  readonly childNodes: ReadonlyArray<IBlockHierarchyNode>;
}

export function getBlockHierarchy(
  blocks: ReadonlyArray<ContentBlock>,
  startIndex: number = 0,
): ReadonlyArray<IBlockHierarchyNode> {
  const result: IBlockHierarchyNode[] = [];
  const startBlock = blocks[startIndex];
  assert(startBlock, () => `${__filename}: Block at start index "${startIndex}" is falsy.`);
  const targetLevel = getNestingLevel(startBlock);
  const blockIsFalsyMessage = (index: number) => () =>
    `${__filename}: Block at index "${index}" is falsy.`;

  for (let i = startIndex; i < blocks.length; i++) {
    const block = blocks[i];
    assert(block, blockIsFalsyMessage(i));
    const blockLevel = getNestingLevel(block);
    if (blockLevel < targetLevel) {
      break;
    }

    const nextBlock = blocks[i + 1];
    const nextBlockLevel = (nextBlock && getNestingLevel(nextBlock)) || targetLevel;
    const hasNestedBlocks = nextBlockLevel > targetLevel;

    if (hasNestedBlocks) {
      const outputBlock = {
        block,
        childNodes: getBlockHierarchy(blocks, i + 1),
      };
      result.push(outputBlock);
      i += outputBlock.childNodes.length;
    } else {
      const outputBlock = {
        block,
        childNodes: [],
      };
      result.push(outputBlock);
    }
  }

  return result;
}

export function flattenBlockHierarchy(
  nodes: ReadonlyArray<IBlockHierarchyNode>,
): ReadonlyArray<ContentBlock> {
  const result: ContentBlock[] = [];
  nodes.forEach((node) => {
    result.push(node.block);
    result.push(...flattenBlockHierarchy(node.childNodes));
  });
  return result;
}
