import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { EnvironmentEventType } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { ISwapEnvironmentServerModel } from '../../../../../repositories/serverModels/EnvironmentServerModels.type.ts';
import {
  Environment_SwapEnvironment_Failed,
  Environment_SwapEnvironment_Finished,
  Environment_SwapEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';

interface ISwapEnvironmentsCreatorDependencies {
  readonly loadProjects: () => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

const swapEnvironmentsStarted = (environmentId: Uuid) =>
  ({
    type: Environment_SwapEnvironment_Started,
    payload: {
      environmentId,
    },
  }) as const;

const swapEnvironmentsFinished = (environmentId: Uuid) =>
  ({
    type: Environment_SwapEnvironment_Finished,
    payload: {
      environmentId,
    },
  }) as const;

const swapEnvironmentsFailed = (environmentId: Uuid) =>
  ({
    type: Environment_SwapEnvironment_Failed,
    payload: {
      environmentId,
    },
  }) as const;

export type SwapEnvironmentsActionsType = ReturnType<
  typeof swapEnvironmentsStarted | typeof swapEnvironmentsFinished | typeof swapEnvironmentsFailed
>;

export const swapEnvironmentsCreator =
  (deps: ISwapEnvironmentsCreatorDependencies) =>
  (environmentId: Uuid, oldProductionId: Uuid, enableWebhooks: boolean): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const environment = getState().data.projects.byId.get(environmentId);
    if (!environment) {
      throw InvariantException(`No environment with id ${environmentId} was found.`);
    }

    dispatch(swapEnvironmentsStarted(environmentId));

    try {
      const requestModel: ISwapEnvironmentServerModel = {
        enableWebhooks,
      };
      await deps.projectRepository.swapEnvironments(environmentId, oldProductionId, requestModel);
      await Promise.all([dispatch(deps.loadUserProjectsInfo()), dispatch(deps.loadProjects())]);
      dispatch(swapEnvironmentsFinished(environmentId));
      dispatch(
        deps.trackUserEventWithData(TrackedEvent.Environment, {
          type: EnvironmentEventType.Swap,
          environmentId,
          oldProductionId,
        }),
      );
    } catch (error) {
      dispatch(swapEnvironmentsFailed(environmentId));
      throw InvariantException(
        `Could not swap the environment because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
