import { ThunkFunction, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  closeNotificationBar,
  showErrorNotification,
} from '../../../../_shared/actions/sharedActions.ts';
import { PreviewConfigurationNotValidErrorMessage } from '../../constants/previewConfigurationUiConstants.ts';

type Deps = {
  readonly updatePreviewConfiguration: () => ThunkPromise;
  readonly validatePreviewConfiguration: () => ThunkFunction;
};

export const createUpdatePreviewConfigurationFromToolbarAction =
  (deps: Deps) =>
  (): ThunkFunction =>
  (dispatch, getState): void => {
    dispatch(deps.validatePreviewConfiguration());

    const {
      previewConfigurationApp: { previewUrlsSectionErrors },
    } = getState();

    if (previewUrlsSectionErrors.size) {
      dispatch(showErrorNotification(PreviewConfigurationNotValidErrorMessage));
    } else {
      dispatch(deps.updatePreviewConfiguration());
      dispatch(closeNotificationBar());
    }
  };
