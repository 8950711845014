import { Stack } from '@kontent-ai/component-library/Stack';
import {
  SliceDirection,
  SliceFrom,
  useSliceOverflowingItems,
} from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isSameMonth, isToday } from 'date-fns';
import React, { useRef } from 'react';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { DateCellWrapper } from '../../containers/Calendar/DateCellWrapper.tsx';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { getFormattedDate } from '../../utils/calendarUtils.ts';
import { AgendaItem } from '../AgendaItem.tsx';
import { StyledAgendaItemWrapper } from './StyledAgendaItemWrapper.tsx';
import { StyledCalendarCell } from './StyledCalendarCell.tsx';
import { StyledCellTitle } from './StyledCellTitle.tsx';
import { StyledShowMoreButton } from './StyledShowMoreButton.tsx';

type CalendarCellProps = Readonly<{
  date: Date;
  events: readonly ICalendarEvent[];
  isCurrentMonthLoaded: boolean;
  onDrillDownDateSelected: (date: DateTimeStamp) => void;
  onItemDetailSelected: (id: Uuid, date: DateTimeStamp) => void;
  startOfCurrentMonth: Date;
}>;

export const CalendarCell: React.FC<CalendarCellProps> = ({
  date,
  events,
  isCurrentMonthLoaded,
  onDrillDownDateSelected,
  onItemDetailSelected,
  startOfCurrentMonth,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isDateToday = isToday(date);
  const isOutsideMonthRange = !isSameMonth(date, startOfCurrentMonth);
  const { attachVisibleItemRef, visibleItems, hiddenItems } = useSliceOverflowingItems(
    events,
    containerRef,
    SliceFrom.End,
    SliceDirection.Vertical,
  );

  const showMoreButton = (
    <StyledShowMoreButton
      onClick={() => onDrillDownDateSelected(date.toISOString())}
      {...getDataUiActionAttribute(DataUiAction.Show)}
    >
      {`+${hiddenItems.length} more`}
    </StyledShowMoreButton>
  );

  return (
    <DateCellWrapper value={date}>
      <StyledCalendarCell
        isLoaded={isCurrentMonthLoaded}
        isOutsideMonthRange={isOutsideMonthRange}
        isToday={isDateToday}
        ref={containerRef}
        {...getDataUiElementAttribute(
          isOutsideMonthRange
            ? DataUiElement.CalendarDateCellOutOfRange
            : DataUiElement.CalendarDateCell,
        )}
      >
        <Stack spacing={Spacing.XS}>
          <StyledCellTitle
            isLoaded={isCurrentMonthLoaded}
            isToday={isDateToday}
            isOutsideMonthRange={isOutsideMonthRange}
            {...getDataUiElementAttribute(
              isDateToday
                ? DataUiElement.CalendarDateCellTitleToday
                : DataUiElement.CalendarDateCellTitle,
            )}
          >
            {getFormattedDate(date, startOfCurrentMonth)}
          </StyledCellTitle>
          {visibleItems.map((event, index) => (
            <StyledAgendaItemWrapper
              key={index}
              onClick={() => onItemDetailSelected(event.itemId, event.date)}
              ref={attachVisibleItemRef(index)}
            >
              <AgendaItem event={event} />
            </StyledAgendaItemWrapper>
          ))}
          {hiddenItems.length > 0 && showMoreButton}
        </Stack>
      </StyledCalendarCell>
    </DateCellWrapper>
  );
};

CalendarCell.displayName = 'CalendarCell';
