import classNames from 'classnames';
import React from 'react';
import { ITaxonomyTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { ITaxonomyItemElement } from '../../../../../models/contentItemElements/TaxonomyItemElement.ts';
import { TaxonomySelectorView } from '../../../../ContentItemEditing/components/elements/taxonomy/TaxonomySelector.tsx';
import { IGetTaxonomySelectorNodeClassNameInput } from '../../../../ContentItemEditing/components/elements/taxonomy/TaxonomySelectorNode.tsx';
import { TaxonomySelector } from '../../../../ContentItemEditing/containers/elements/taxonomy/TaxonomySelector.tsx';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';

const getDiffedTaxonomySelectorNodeClassNameCreator =
  (originalElementData: ITaxonomyItemElement) =>
  (input: IGetTaxonomySelectorNodeClassNameInput) => {
    const { term, isSelected } = input;

    const isAdded = isSelected && !originalElementData.value.has(term.id);
    const isRemoved = !isSelected && originalElementData.value.has(term.id);

    return classNames({
      'tree-node__item--is-added': isAdded,
      'tree-node__item--is-removed': isRemoved,
    });
  };

interface ITaxonomyDiffProps extends IRevisionDiffElementProps<ITaxonomyItemElement> {
  readonly typeElement: ITaxonomyTypeElement;
}

const TaxonomyDiff: React.FC<ITaxonomyDiffProps> = (props) => {
  const { elementData, originalElementData, typeElement } = props;

  const getNodeClassName = originalElementData
    ? getDiffedTaxonomySelectorNodeClassNameCreator(originalElementData)
    : undefined;

  return (
    <TaxonomySelector
      taxonomyGroupId={typeElement.taxonomyGroupId}
      selectedTermIds={elementData.value}
      getNodeClassName={getNodeClassName}
      disabled
      defaultValue={typeElement.defaultValue}
      maxItems={typeElement.maxItems}
      minItems={typeElement.minItems}
      isInRevisions
      view={originalElementData ? TaxonomySelectorView.Tree : TaxonomySelectorView.Auto} // Don't use compact view for diff
    />
  );
};

TaxonomyDiff.displayName = 'TaxonomyDiff';

export { TaxonomyDiff };
