import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { requestChangePassword } from '../../../../_shared/utils/authorization/authorization.ts';

export const createRequestChangePasswordAction =
  () =>
  (): ThunkPromise =>
  async (_dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      data: { user },
    } = getState();

    await requestChangePassword(user.info.email);
  };
