export enum ContentTypeSelectorState {
  ShowSuggested = 'ShowSuggested',
  ShowRestricted = 'ShowRestricted',
  ShowAllOnly = 'ShowAllOnly',
}

type Params = {
  readonly allowedContentTypesCount: number;
  readonly suggestedContentTypesCount: number;
};

export const getContentTypeSelectorState = ({
  allowedContentTypesCount,
  suggestedContentTypesCount,
}: Params): ContentTypeSelectorState => {
  if (allowedContentTypesCount > 0) {
    return ContentTypeSelectorState.ShowRestricted;
  }

  if (suggestedContentTypesCount > 0) {
    return ContentTypeSelectorState.ShowSuggested;
  }

  return ContentTypeSelectorState.ShowAllOnly;
};
