import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { assignTaxonomyTermsToAssets } from '../actions/thunkAssetLibraryActions.ts';

type Args = {
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

type SelectedTermsPerElement = ReadonlyMap<Uuid, ReadonlySet<Uuid>>;

export const useAssignTaxonomyTermsToAssets = ({ deselectAssets, selectedAssets }: Args) => {
  const dispatch = useDispatch();

  const assignTerms = useCallback(
    async (selectedTermsPerElement: SelectedTermsPerElement): Promise<void> => {
      const assignedAssetIds = await dispatch(
        assignTaxonomyTermsToAssets({
          selectedAssets,
          selectedTermsPerElement,
        }),
      );

      deselectAssets(assignedAssetIds);
    },
    [deselectAssets, selectedAssets],
  );

  return assignTerms;
};
