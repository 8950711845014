import styled, { css } from 'styled-components';
import { Size } from '../../../tokens/decision/spacing.ts';
import { gridUnit } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';

type WrapperProps = {
  readonly isCentered: boolean;
};

export const StyledWrapper = styled.div<WrapperProps>`
  position: absolute;
  
  ${({ isCentered }) =>
    isCentered
      ? css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `
      : css`
    bottom: ${px(Size.L)};
    left: ${px(14 * gridUnit)};
  `};
`;
