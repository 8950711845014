import { assert } from '@kontent-ai/utils';
import { IProjectContainerActiveUserSeverModel } from '../../../repositories/serverModels/IProjectContainerActiveUserSeverModel.type.ts';

export interface IProjectContainerActiveUser {
  readonly userId: UserId;
  readonly firstName: string;
  readonly lastName: string;
  readonly isProjectManager: boolean;
}

export function createProjectContainerActiveUserDomainModel(
  serverModel: IProjectContainerActiveUserSeverModel,
): IProjectContainerActiveUser {
  assert(
    typeof serverModel === 'object',
    () => `${__filename}: ProjectContainer active user is not an object`,
  );

  return {
    userId: serverModel.userId,
    firstName: serverModel.firstName,
    lastName: serverModel.lastName,
    isProjectManager: serverModel.isProjectManager,
  };
}
