import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_AuditEvents_Success } from '../../../constants/dataActionTypes.ts';
import { createAuditEventsFromServerModel } from '../../../models/auditLog/AuditEvent.ts';

const initialState: boolean = false;

export function hasAnyResults(state: boolean = initialState, action: Action): boolean {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_AuditEvents_Success: {
      return state || !createAuditEventsFromServerModel(action.payload).auditEvents.isEmpty();
    }

    default:
      return state;
  }
}
