import { assert, alphabetically } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';

export interface UsageItemReference {
  primary: IContentItemVariantReference;
  secondary: IContentItemVariantReference | null;
}

const createUsageItemReference = (
  refs: Immutable.Iterable<number, IContentItemVariantReference>,
): UsageItemReference => {
  const currentVersion = refs
    .filter((ref: IContentItemVariantReference) => !ref.isPublishedVersion)
    .first();
  const publishedVersion = refs
    .filter((ref: IContentItemVariantReference) => ref.isPublishedVersion)
    .first();

  const primaryVersion = currentVersion || publishedVersion;
  assert(primaryVersion, () => 'There must be at least some reference in UsageItemReference');

  return currentVersion && publishedVersion
    ? {
        primary: currentVersion,
        secondary: publishedVersion,
      }
    : {
        primary: primaryVersion,
        secondary: null,
      };
};

export const createUsages = (
  references: IContentItemVariantReference[],
): Immutable.Set<UsageItemReference> => {
  return Immutable.List(references)
    .groupBy((ref: IContentItemVariantReference) => `${ref.id.itemId};${ref.id.variantId}`)
    .map(createUsageItemReference)
    .toSet();
};

export const compareUsages = (a: UsageItemReference, b: UsageItemReference): number =>
  alphabetically(a.primary.name, b.primary.name);
