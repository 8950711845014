import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { AssetFolderDeleteDialog as AssetFolderDeleteDialogComponent } from '../../components/AssetFolders/AssetFolderDeleteDialog.tsx';
import { getOpenedAssetFolder } from '../../selectors/getOpenedAssetFolder.ts';

type Props = {
  readonly closeDialog: () => void;
  readonly onDelete: () => void;
};

export const AssetFolderDeleteDialog: React.FC<Props> = ({ closeDialog, onDelete }) => {
  const openedFolderName = useSelector((s) => getOpenedAssetFolder(s).name);

  return (
    <AssetFolderDeleteDialogComponent
      closeDialog={closeDialog}
      deleteFolder={onDelete}
      folderName={openedFolderName}
    />
  );
};

AssetFolderDeleteDialog.displayName = 'AssetFolderDeleteDialog';
