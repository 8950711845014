import React from 'react';
import { DropTarget } from '../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isWithinTargetInset20 } from '../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';

type Props = {
  readonly collections: ReadonlyArray<ICollection>;
  readonly editedCollectionId: Uuid | null;
  readonly onMove: (sourceId: Uuid, targetId: Uuid) => void;
  readonly renderCollection: (collection: ICollection) => React.ReactElement;
};

export const DroppableCollections: React.FC<Props> = ({
  collections,
  onMove,
  renderCollection,
  editedCollectionId,
}) => {
  return (
    <>
      {collections.map((collection) => (
        <DropTarget<HTMLLIElement>
          key={collection.id}
          accept={DndTypes.Collection_Move}
          canDrop={editedCollectionId !== collection.id}
          hoveringCollisionStrategy={isWithinTargetInset20}
          onMove={({ sourceId, targetId }) => onMove(sourceId, targetId)}
          parentId=""
          renderDroppable={(ref) => (
            <li ref={ref} {...getDataUiElementAttribute(DataUiElement.BarItemNode)}>
              {renderCollection(collection)}
            </li>
          )}
          targetId={collection.id}
        />
      ))}
    </>
  );
};

DroppableCollections.displayName = 'DroppableCollections';
