import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IPreviewConfigurationRepository } from '../../../../../../repositories/interfaces/IPreviewConfigurationRepository.type.ts';
import { IPreviewConfiguration } from '../../../../../previewConfiguration/models/PreviewConfiguration.type.ts';
import { getPreviewConfigurationFromServerModel } from '../../../../../previewConfiguration/utils/getPreviewConfigurationFromServerModel.ts';
import {
  ContentItemEditing_LoadPreviewConfiguration_Finished,
  ContentItemEditing_LoadPreviewConfiguration_Started,
} from '../../constants/contentItemEditingActionTypes.ts';

interface IDeps {
  readonly previewConfigurationRepository: IPreviewConfigurationRepository;
}

export const started = () =>
  ({
    type: ContentItemEditing_LoadPreviewConfiguration_Started,
  }) as const;

export const finished = (previewConfiguration: IPreviewConfiguration) =>
  ({
    type: ContentItemEditing_LoadPreviewConfiguration_Finished,
    payload: {
      previewConfiguration,
    },
  }) as const;

export type LoadPreviewConfigurationActionTypes = ReturnType<typeof started | typeof finished>;

export const createLoadPreviewConfiguration =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    const previewConfigurationServerModel = await deps.previewConfigurationRepository.get(
      currentProjectId,
      abortSignal,
    );
    const previewConfiguration = getPreviewConfigurationFromServerModel(
      previewConfigurationServerModel,
      null,
      false,
    );

    dispatch(finished(previewConfiguration));
  };
