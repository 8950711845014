import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { focusCommentThread } from '../actions/thunkContentItemEditingActions.ts';
import {
  ContentGroupTab as ContentGroupTabComponent,
  IContentGroupTabOwnProps,
} from '../components/ContentGroupTab.tsx';
import { getOrderedInlineComments } from '../selectors/inlineCommentSelectors.ts';
import { isUnresolvedSavedInlineCommentThread } from '../utils/inlineCommentUtils.ts';

export const ContentGroupTab: React.FC<IContentGroupTabOwnProps> = (ownProps) => {
  const dispatch = useDispatch();

  const commentsState = useSelector((state) => {
    const { editedContentItem } = state.contentApp;
    if (!editedContentItem) {
      return null;
    }

    const unresolvedInlineCommentThreads = getOrderedInlineComments(
      state,
      ownProps.contentGroup.id,
      isUnresolvedSavedInlineCommentThread,
    );
    return {
      commentCount: unresolvedInlineCommentThreads.length,
      firstCommentThreadId: unresolvedInlineCommentThreads[0]?.commentThread.id,
    };
  }, shallowEqual);

  if (!commentsState) {
    return null;
  }

  const onCommentsClick = () => {
    if (commentsState.firstCommentThreadId) {
      dispatch(focusCommentThread(commentsState.firstCommentThreadId));
    }
  };

  return (
    <ContentGroupTabComponent
      {...ownProps}
      commentCount={commentsState.commentCount}
      onCommentsClick={onCommentsClick}
    />
  );
};

ContentGroupTab.displayName = 'ContentGroupTab';
