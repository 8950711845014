import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { YourContentNonEmptyRedirect as YourContentNonEmptyRedirectComponent } from '../components/YourContentNonEmptyRedirect.tsx';

export const YourContentNonEmptyRedirect: React.FC = () => {
  const { projectId } = useSelector(getCurrentProject);

  const selectedTab = useSelector((s) => s.contentApp.listingUi.yourContentUi.selectedTab);
  const loadingFinished = useSelector(
    (s) =>
      s.data.assignedContentItems.loadingStatus === LoadingStatus.Loaded &&
      s.data.recentlyEditedContentItems.loadingStatus === LoadingStatus.Loaded,
  );

  const assignedToYouItemsCount = useSelector((s) => s.data.assignedContentItems.items.count());
  const recentlyEditedByYouItemsCount = useSelector((s) =>
    s.data.recentlyEditedContentItems.items.count(),
  );

  return (
    <YourContentNonEmptyRedirectComponent
      projectId={projectId}
      loadingFinished={loadingFinished}
      selectedTab={selectedTab}
      assignedToYouItemsCount={assignedToYouItemsCount}
      recentlyEditedByYouItemsCount={recentlyEditedByYouItemsCount}
    />
  );
};

YourContentNonEmptyRedirect.displayName = 'YourContentNonEmptyRedirect';
