import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_CommentThread_AiInlineThreadAdded,
  ContentItemEditing_CommentThread_InlineThreadAdded,
  ContentItemEditing_CommentThread_ReplyStarted,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { CommentThreadItemType } from '../../../models/comments/CommentThreadItem.ts';

const initialState = Immutable.Map<string, CommentThreadItemType>();

// We don't want to remove mapping of comments ids to types,
// because pending animation keeps component rendered even after comment thread is removed from state
export function newCommentThreadItemTypeMappings(
  state: Immutable.Map<string, CommentThreadItemType> = initialState,
  action: Action,
): Immutable.Map<string, CommentThreadItemType> {
  switch (action.type) {
    case ContentItemEditing_CommentThread_ReplyStarted: {
      return state.set(action.payload.threadId, action.payload.type);
    }

    case ContentItemEditing_CommentThread_InlineThreadAdded: {
      return state.set(action.payload.inlineThread.id, action.payload.type);
    }

    case ContentItemEditing_CommentThread_AiInlineThreadAdded: {
      return state.set(action.payload.createdThread.id, CommentThreadItemType.Comment);
    }

    default:
      return state;
  }
}
