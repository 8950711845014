import { Action } from '../../../../../../../@types/Action.type.ts';
import { buildPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUrlFactory } from '../../../../../../../_shared/utils/urlFactory.ts';
import { Calendar_Initialization_Finished } from '../../../../../../contentPlanning/calendar/constants/calendarActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  FilterRoute,
  FilterRouteParams,
} from '../../../../../../smartLink/constants/routePaths.ts';
import {
  Content_ContentItemListingFilter_Clear,
  Content_Filter_Clear,
} from '../../../../constants/contentActionTypes.ts';
import {
  ContentListing_Filter_SearchPhraseChanged,
  ContentListing_Init_Finished,
} from '../../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  ContentItemFilter_SetUp,
  ListingFilter_CollectionFilterChanged,
  ListingFilter_ContentItemStatusChanged,
  ListingFilter_ContentTypeFilterChanged,
  ListingFilter_ContributorsFilterChanged,
  ListingFilter_CreateLinkedFilter_Failed,
  ListingFilter_CreateLinkedFilter_Finished,
  ListingFilter_CreateLinkedFilter_Started,
  ListingFilter_PublishingStatusFilterChanged,
  ListingFilter_SavedFilterSelected,
  ListingFilter_SearchScopeChanged,
  ListingFilter_SitemapFilterChanged,
  ListingFilter_TaxonomyGroupsFilterChanged,
  ListingFilter_WorkflowStatusesFilterChanged,
} from '../../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialState: string | null = null;

export function currentFilterLink(state = initialState, action: Action) {
  switch (action.type) {
    case ContentItemFilter_SetUp:
    case Content_ContentItemListingFilter_Clear:
    case Content_Filter_Clear:
    case Calendar_Initialization_Finished:
    case ContentListing_Init_Finished:
    case ContentListing_Filter_SearchPhraseChanged:
    case ListingFilter_CollectionFilterChanged:
    case ListingFilter_ContentTypeFilterChanged:
    case ListingFilter_ContributorsFilterChanged:
    case ListingFilter_PublishingStatusFilterChanged:
    case ListingFilter_ContentItemStatusChanged:
    case ListingFilter_SearchScopeChanged:
    case ListingFilter_SitemapFilterChanged:
    case ListingFilter_TaxonomyGroupsFilterChanged:
    case ListingFilter_WorkflowStatusesFilterChanged:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ListingFilter_CreateLinkedFilter_Started:
    case ListingFilter_CreateLinkedFilter_Failed:
    case ListingFilter_SavedFilterSelected:
      return null;

    case ListingFilter_CreateLinkedFilter_Finished:
      return `${getUrlFactory().getDraftUiRootUrl()}${buildPath<FilterRouteParams>(FilterRoute, {
        projectId: action.payload.projectId,
        filterId: action.payload.filter.id,
      })}`;

    default:
      return state;
  }
}
