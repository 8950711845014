import {
  IContentType,
  emptyContentType,
} from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  convertContentTypeBaseToServerModel,
  getContentTypeBaseFromServerModel,
} from '../../../../data/models/contentModelsApp/sharedContentTypeModels.ts';
import { AssetTypeServerModel } from '../../../../repositories/serverModels/contentModels/AssetTypeServerModel.type.ts';
import { convertTypeElementsToServerModel } from '../../shared/utils/typeElementDataConverter.ts';

export const getAssetTypeFromServerModel = (serverModel: AssetTypeServerModel): IContentType => ({
  ...getContentTypeBaseFromServerModel(serverModel, { subpagesElementEnabled: false }),
  contentGroups: emptyContentType.contentGroups,
});

export const convertAssetTypeToServerModel = (assetType: IContentType): AssetTypeServerModel => ({
  ...convertContentTypeBaseToServerModel(assetType),
  contentElements: convertTypeElementsToServerModel(assetType.typeElements),
});
