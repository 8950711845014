import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  DataUiRevisionAction,
  getDataUiRevisionActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IRevisionClickWrapperDataProps {
  readonly to: string;
}

export const RevisionClickWrapper: React.FC<
  React.PropsWithChildren<IRevisionClickWrapperDataProps>
> = ({ to, children }): JSX.Element => {
  return (
    <NavLink
      activeClassName="revision__wrapper--is-selected"
      className="revision__wrapper"
      to={to}
      {...getDataUiRevisionActionAttribute(DataUiRevisionAction.SelectRevision)}
    >
      <div className="revision__outer">
        <div className="revision__separator" />
      </div>
      <div className="revision__inner">
        {children}
        <div className="revision-item__action-pane">
          <div className="revision-item__action" />
        </div>
      </div>
    </NavLink>
  );
};
RevisionClickWrapper.displayName = 'RevisionClickWrapper';
