import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface TreeItemExpandButtonProps {
  readonly hasSubpages: boolean;
  readonly isExpanded: boolean;
  readonly isVariantTranslated: boolean;
  readonly onClick: () => void;
}

export const TreeItemExpandButton: React.FC<TreeItemExpandButtonProps> = ({
  hasSubpages,
  isExpanded,
  isVariantTranslated,
  onClick,
}) => {
  const text = isExpanded ? 'Collapse' : 'Expand';
  const handleClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    onClick();
  };

  const isVisible = hasSubpages && isVariantTranslated;

  return (
    <Box
      aria-hidden={!isVisible}
      visibility={isVisible ? 'visible' : 'hidden'}
      paddingRight={Spacing.XS}
    >
      <QuinaryButton
        onClick={handleClick}
        tooltipText={text}
        {...getDataUiActionAttribute(isExpanded ? DataUiAction.Collapse : DataUiAction.Expand)}
      >
        <QuinaryButton.Icon
          icon={isExpanded ? Icons.ChevronDown : Icons.ChevronRight}
          screenReaderText={text}
        />
      </QuinaryButton>
    </Box>
  );
};

TreeItemExpandButton.displayName = 'TreeItemExpandButton';
