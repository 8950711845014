import { combineReducers } from '@reduxjs/toolkit';
import { editedEnvironmentName } from './internalReducers/editedEnvironmentName.ts';
import { renamingEnvironment } from './internalReducers/renamingEnvironment.ts';
import { renamingEnvironmentFailed } from './internalReducers/renamingEnvironmentFailed.ts';

export const generalSettingsAppReducer = combineReducers({
  editedEnvironmentName,
  renamingEnvironment,
  renamingEnvironmentFailed,
});
