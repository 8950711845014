export const ItemListingFilterDebounceTime = 700; // ms

export const RestoreSavedFilterButtonNotificationTime = 30000; // ms

export enum FilterAction {
  SaveFilter = 'SaveFilter',
  ShareFilter = 'ShareFilter',
  MainMenu = 'MainMenu',
}

export const uncategorizedItemFilterTaxonomyTermId: Uuid = 'f3443ae7-f1ed-45de-a851-b62eaf68e068';

export const filterHeadlineIdentifier = 'filterHeading';
