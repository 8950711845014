import { IPaidMetricSummaryServerModel } from '../../../../repositories/serverModels/PaidMetricSummaryServerModel.type.ts';

export interface IPaidMetricSummary {
  readonly name: string;
  readonly usage: number;
  readonly includedInPlan: number;
  readonly overPlan: number;
  readonly extraUnits: number;
  readonly extraUnitsCosts: number;
}

export function createPaidMetricsSummary(props?: Partial<IPaidMetricSummary>): IPaidMetricSummary {
  return {
    name: '',
    usage: 0,
    includedInPlan: 0,
    overPlan: 0,
    extraUnits: 0,
    extraUnitsCosts: 0,
    ...props,
  };
}

export function getPaidMetricSummaryFromServerModel(
  serverModel: IPaidMetricSummaryServerModel,
): IPaidMetricSummary {
  if (typeof serverModel !== 'object') {
    throw new Error('Paid metric summary is not an object');
  }

  const emptyObject = createPaidMetricsSummary();

  return {
    name: serverModel.name ?? emptyObject.name,
    usage: serverModel.usage ?? emptyObject.usage,
    includedInPlan: serverModel.includedInPlan ?? emptyObject.includedInPlan,
    overPlan: serverModel.overPlan ?? emptyObject.overPlan,
    extraUnits: serverModel.extraUnits ?? emptyObject.extraUnits,
    extraUnitsCosts: serverModel.extraUnitsCosts ?? emptyObject.extraUnitsCosts,
  };
}
