import { validateWithRegex } from '../../../../../_shared/utils/validation/limitations/baseTextLimitValidator.ts';
import { ValidationRegex } from '../../../../contentModels/shared/models/elements/types/TypeElementData.ts';
import { RegexValidationWarning } from '../../../constants/warningMessageTemplates.ts';

export const getValidationMessageValue = (validationMessage: string | null | undefined) =>
  validationMessage ? validationMessage : RegexValidationWarning;

export const getIsRegexValidationMet = (
  validationRegex: ValidationRegex | null,
  itemText: string,
) =>
  validationRegex === null ||
  !validationRegex?.isActive ||
  validateWithRegex(validationRegex?.regex, validationRegex?.flags, itemText);
