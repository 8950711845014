import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import {
  IEntityWebhookSetting,
  createEntityWebhookSettingDomainModel,
  createEntityWebhookSettingServerModel,
  emptyEntityWebhook,
} from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookServerModel } from '../../../../repositories/serverModels/IEntityWebhookServerModel.type.ts';
import { EntityWebhooks_EntityWebhook_InsertCompleted } from '../../constants/entityWebhooksActionTypes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { createEntityWebhookSettingFromFormValues } from '../../utils/createEntityWebhookSettingFromFormValues.ts';

interface IDeps {
  readonly entityWebhookRepository: {
    readonly insert: (serverModel: IEntityWebhookServerModel) => Promise<IEntityWebhookServerModel>;
  };
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const completed = (webhook: IEntityWebhookSetting) =>
  ({
    type: EntityWebhooks_EntityWebhook_InsertCompleted,
    payload: {
      webhook,
    },
  }) as const;

export type InsertEntityWebhookActionTypes = ReturnType<typeof completed>;

export const createInsertEntityWebhookAction =
  (deps: IDeps) =>
  (formValues: IEntityWebhookFormShape, newWebhookId: Uuid): ThunkPromise =>
  async (dispatch) => {
    const entityWebhook = createEntityWebhookSettingFromFormValues(formValues, emptyEntityWebhook);
    const modifiedEntityWebhook: IEntityWebhookSetting = {
      ...entityWebhook,
      id: newWebhookId,
    };

    const serverModel = await deps.entityWebhookRepository.insert(
      createEntityWebhookSettingServerModel(modifiedEntityWebhook),
    );
    const domainModel = createEntityWebhookSettingDomainModel(serverModel);
    dispatch(deps.trackUserEvent(TrackedEvent.EntityWebhookSaved, { url: domainModel.url }));
    dispatch(completed(domainModel));
  };
