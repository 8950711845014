import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  AssignmentComparisonDifference,
  Notifications,
} from '../../../../_shared/services/signalR/signalRClient.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { getAssignmentFromServerModel } from '../../models/contentItem/Assignment.ts';
import { ContentItemChangeReason } from '../../models/contentItem/ContentItemChangeReason.type.ts';
import {
  createNewContentItemOverwrite,
  getItemOverwrittenWorkflowDifferences,
  itemOverwriteCanAutoRefresh,
} from '../../utils/itemOverwriteUtils.ts';
import { currentItemOverwritten } from '../contentActions.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly getItemWithVariant: (
      itemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
}

export const createHandleCurrentItemOverwrittenAction =
  (deps: IDeps) =>
  (params: Notifications['contentChange']['payload']): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemOverwritten, editedContentItemVariant },
    } = getState();

    assert(editedContentItemVariant, () => '"editedContentItemVariant" is not loaded.');

    const oldOverwrite = editedContentItemOverwritten;
    const contentItemId = editedContentItemVariant.id;

    let newOverwrite = createNewContentItemOverwrite(params);

    if (params.changeReason !== ContentItemChangeReason.Archive) {
      const newEditedContentItem = await deps.contentItemRepository.getItemWithVariant(
        contentItemId.itemId,
        contentItemId.variantId,
      );
      const assignment = getAssignmentFromServerModel(newEditedContentItem.variant.assignment);

      newOverwrite = {
        ...newOverwrite,
        difference: {
          ...getItemOverwrittenWorkflowDifferences(
            assignment,
            params.difference.assignmentDifferences,
            newEditedContentItem,
          ),
          assignees: params.difference.assignmentDifferences.includes(
            AssignmentComparisonDifference.Assignee,
          )
            ? assignment.assignees
            : undefined,
          codename: params.difference.codenameChanged
            ? newEditedContentItem.item.codeName
            : undefined,
          collection: params.difference.collectionChanged
            ? newEditedContentItem.item.collectionId
            : undefined,
          dueDate: params.difference.assignmentDifferences.includes(
            AssignmentComparisonDifference.DueDate,
          )
            ? assignment.due
            : undefined,
          name: params.difference.nameChanged ? newEditedContentItem.item.name : undefined,
          note: params.difference.assignmentDifferences.includes(
            AssignmentComparisonDifference.Note,
          )
            ? assignment.note
            : undefined,
          sitemap: params.difference.sitemapChanged
            ? Immutable.Set.of(...newEditedContentItem.item.sitemapLocation)
            : undefined,
        },
      };
    }
    // Do not replace (dispatch) previous overwrite that needs user to refresh the item
    // with an incoming overwrite that could partially update the item automatically.
    if (
      !oldOverwrite ||
      itemOverwriteCanAutoRefresh(oldOverwrite) ||
      !itemOverwriteCanAutoRefresh(newOverwrite)
    ) {
      dispatch(currentItemOverwritten(contentItemId.itemId, newOverwrite));
    }
  };
