import classNames from 'classnames';
import React from 'react';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

interface INoElementsElementProps {
  readonly isDisabled: boolean;
}

export const NoElementsElement: React.FC<INoElementsElementProps> = ({ isDisabled }) => (
  <div
    className={classNames('content-item-element__wrapper', {
      'content-item-element__wrapper--is-disabled': isDisabled,
    })}
  >
    <div className="content-item-element content-item-element--is-disabled">
      <div className="content-item-element__info">There are no elements to fill in.</div>
    </div>
  </div>
);
NoElementsElement.displayName = 'NoElementsElement';

interface INoElementsEmptyStateProps {
  readonly canEdit: boolean;
}

export const NoElementsEmptyState: React.FC<INoElementsEmptyStateProps> = ({ canEdit }) => {
  const isDisabled = !canEdit;

  return (
    <ContentItemPaneElementsGroup
      isDisabled={isDisabled}
      isGuideline
      hasTopRoundedCorners
      hasBottomRoundedCorners
    >
      <NoElementsElement isDisabled={isDisabled} />
    </ContentItemPaneElementsGroup>
  );
};

NoElementsEmptyState.displayName = 'NoElementsEmptyState';
