import React from 'react';
import {
  LimitedSpaceInfo,
  SpaceNamesMetadata,
  defaultAllSpacesPlaceholder,
  shouldCollapseSpaces,
} from '../../../applications/environmentSettings/spaces/utils/spacesUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { JoinItemsWithOxfordComma } from '../utilityComponents/JoinItemsWithOxfordComma.tsx';

type RenderSpaceName = (space: LimitedSpaceInfo) => React.ReactNode;

type Props = {
  readonly spaceData: SpaceNamesMetadata;
  readonly renderSpaceName: RenderSpaceName;
  readonly allSpacesPlaceholderText?: string;
};

const SharedSpacesMessage: React.FC<Props> = ({
  spaceData,
  renderSpaceName,
  allSpacesPlaceholderText,
}) => {
  if (spaceData.usedSpaces.length === 0) {
    return <>This collection is not assigned to any space.</>;
  }

  const [firstSpace] = spaceData.usedSpaces;
  if (spaceData.usedSpaces.length === 1 && firstSpace) {
    return (
      <>
        This collection is assigned to {renderSpaceName(firstSpace)} and not shared anywhere else.
      </>
    );
  }

  if (shouldCollapseSpaces(spaceData)) {
    return <>This collection is shared across {allSpacesPlaceholderText}.</>;
  }

  return (
    <>
      This collection is shared across multiple spaces:{' '}
      <JoinItemsWithOxfordComma items={spaceData.usedSpaces} renderItem={renderSpaceName} />.
    </>
  );
};

export const AssignedSpacesMessage: React.FC<Props> = ({
  spaceData,
  renderSpaceName,
  allSpacesPlaceholderText = defaultAllSpacesPlaceholder,
}) => {
  return (
    <span {...getDataUiElementAttribute(DataUiElement.AssignedSpacesMessage)}>
      <SharedSpacesMessage
        spaceData={spaceData}
        renderSpaceName={renderSpaceName}
        allSpacesPlaceholderText={allSpacesPlaceholderText}
      />
    </span>
  );
};

AssignedSpacesMessage.displayName = 'AssignedSpacesMessage';
