import { CommonUser, formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import {
  atomicInstructionChar,
  instructionTriggerSequence,
} from '../../../plugins/inlineAi/constants/aiConstants.ts';
import {
  AiInstructionEntity,
  isFinishedAiInstruction,
} from '../../../plugins/inlineAi/utils/InstructionEntity.ts';
import { FinishedMentionEntity } from '../../../plugins/mentions/api/MentionEntity.ts';

export type GetUser = (userId: UserId) => CommonUser | null;

export const getMentionText = (entity: FinishedMentionEntity, getUser: GetUser): string => {
  const userId = entity.getData().userId;
  if (!userId) {
    return '';
  }

  const user = getUser(userId);
  if (!user) {
    return '';
  }

  return `@${formatUserName(user)}`;
};

export const getInstructionText = (entity: AiInstructionEntity, entityText: string): string => {
  if (!entityText.startsWith(atomicInstructionChar)) {
    return '';
  }

  const instruction = isFinishedAiInstruction(entity)
    ? entity.getData().instruction
    : entityText.substring(atomicInstructionChar.length);

  return `${instructionTriggerSequence}${instruction}`;
};
