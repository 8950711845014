import { isAbortError } from '@kontent-ai/errors';
import { notNull } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DapiKeyTypes } from '../../../../../_shared/models/ApiKeyType.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  DapiKeyListing_Loading_Failed,
  DapiKeyListing_Loading_Finished,
  DapiKeyListing_Loading_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { IApiKeyFilter, createApiKeyFilterServerModel } from '../../models/ApiKeyFilter.ts';
import {
  IApiKeyListingData,
  createApiKeyListingDataFromServerModel,
} from '../../models/ApiKeyListingData.ts';

const started = () =>
  ({
    type: DapiKeyListing_Loading_Started,
  }) as const;

const failed = () =>
  ({
    type: DapiKeyListing_Loading_Failed,
  }) as const;

const success = (apiKeyListingData: ReadonlyArray<IApiKeyListingData>) =>
  ({
    type: DapiKeyListing_Loading_Finished,
    payload: { apiKeyListingData },
  }) as const;

interface ILoadDapiKeyListingDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'getApiKeyListing'>;
}

export type LoadDapiKeyListingActionsType = ReturnType<
  typeof started | typeof failed | typeof success
>;

export const loadDapiKeyListingCreator =
  (dependencies: ILoadDapiKeyListingDependencies) =>
  (filter: IApiKeyFilter, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());
      const filterModel = createApiKeyFilterServerModel(filter, DapiKeyTypes);
      const serverModel = await dependencies.apiKeysRepository.getApiKeyListing(
        filterModel,
        abortSignal,
      );

      const apiKeyListingData = serverModel
        .map(createApiKeyListingDataFromServerModel)
        .filter(notNull);

      dispatch(success(apiKeyListingData));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
