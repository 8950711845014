import { AiErrorCode, AiErrorCodeMap } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';

export const matchWritingStyleErrorMessageByErrorCode: AiErrorCodeMap = {
  ...baseErrorMessageByErrorCode,
  [AiErrorCode.InvalidData]:
    'The AI didn’t find enough textual content in the referenced content item to match the voice and tone with.',
  [AiErrorCode.InputTooLong]:
    'The selected text or the text in the referenced content item is too long.',
  [AiErrorCode.InputTooShort]:
    'The selected text or the text in the referenced content item is not long enough.',
  [AiErrorCode.Unable]:
    'The selected text or the referenced content item doesn’t contain enough information for the AI to provide a meaningful result. Select more text, a different part of your text, or try a different content item as reference.',
};
