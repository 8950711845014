import { InvariantException } from '@kontent-ai/errors';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ITaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { RootFolderId } from '../constants/assetFolderConstants.ts';

export const getOpenedAssetFolder = (state: IStore): ITaxonomyTerm => {
  const {
    assetLibraryApp: { openedFolderId },
    data: { assetFolders },
  } = state;

  if (openedFolderId === RootFolderId) {
    throw InvariantException('No asset folder is opened');
  }

  const openedFolder = assetFolders.taxonomyGroup.terms.get(openedFolderId);

  if (!openedFolder) {
    throw InvariantException(`Asset folder with id: ${openedFolderId} was not found`);
  }

  return openedFolder;
};

export const getSubFolderIdsOfOpenedAssetFolder = (state: IStore): ReadonlyArray<Uuid> => {
  const {
    assetLibraryApp: { openedFolderId },
    data: { assetFolders },
  } = state;

  if (openedFolderId === RootFolderId) {
    return assetFolders.taxonomyGroup.childIds;
  }

  const openedFolder = assetFolders.taxonomyGroup.terms.get(openedFolderId);

  return openedFolder?.childIds ?? [];
};
