import { Action } from '../../../../../../@types/Action.type.ts';
import {
  activationFailed,
  deactivationFailed,
  revokeFailed,
  saveFailed,
} from '../../../constants/errorMessages.ts';
import {
  UserDetail_Closed,
  UserDetail_NotificationBar_Hidden,
  UserDetail_UpdateCollectionGroups_Failed,
  UserDetail_UpdateCollectionGroups_Finished,
  UserDetail_UpdateCollectionGroups_Started,
  User_RevokeInvite_Failed,
  User_RevokeInvite_Finished,
  User_ToggleActiveState_Failed,
  User_ToggleActiveState_Finished,
} from '../../../constants/usersActionTypes.ts';

const emptyState = null;

export const savingError = (state: string | null = emptyState, action: Action): string | null => {
  switch (action.type) {
    case UserDetail_UpdateCollectionGroups_Failed:
      return saveFailed;
    case User_RevokeInvite_Failed:
      return revokeFailed;

    case User_ToggleActiveState_Failed: {
      if (!action.payload.triggeredFromUsersOverview) {
        return action.payload.activate ? activationFailed : deactivationFailed;
      }

      return state;
    }

    case UserDetail_Closed:
    case User_RevokeInvite_Finished:
    case User_ToggleActiveState_Finished:
    case UserDetail_UpdateCollectionGroups_Finished:
    case UserDetail_UpdateCollectionGroups_Started:
    case UserDetail_NotificationBar_Hidden:
      return emptyState;
    default:
      return state;
  }
};
