import { ILinkedItemsTypeElement } from '../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import {
  EmptyLinkedItemElement,
  ILinkedItemsElement,
} from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createModularContentItemElement: ItemElementCreator<
  ILinkedItemsTypeElement,
  ILinkedItemsElement
> = (modularContentTypeElement, omitDefaultValue): ILinkedItemsElement => ({
  ...EmptyLinkedItemElement,
  elementId: modularContentTypeElement.elementId,
  value: omitDefaultValue
    ? EmptyLinkedItemElement.value
    : Immutable.List(modularContentTypeElement.defaultValue),
});
