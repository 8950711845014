import { IconButton, IconButtonState } from '@kontent-ai/component-library/Button';
import React, { forwardRef } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IApiKeyRegenerationButtonDataProps {
  readonly requireConfirmation: boolean;
  readonly buttonState?: IconButtonState;
  readonly tooltipText?: string;
}

interface IApiKeyRegenerationButtonCallbackProps {
  readonly onRegenerate: () => void;
  readonly onConfirm: () => void;
}

interface IApiKeyRegenerationButtonProps
  extends IApiKeyRegenerationButtonDataProps,
    IApiKeyRegenerationButtonCallbackProps {}

export const ApiKeyRegenerationButton: React.FC<
  React.PropsWithChildren<IApiKeyRegenerationButtonProps>
> = forwardRef<HTMLButtonElement, React.PropsWithChildren<IApiKeyRegenerationButtonProps>>(
  (
    { requireConfirmation, onConfirm, onRegenerate, tooltipText, children, ...otherProps },
    forwardedRef,
  ) => (
    <IconButton
      buttonStyle="tertiary"
      iconName="RotateDoubleRight"
      onClick={requireConfirmation ? onConfirm : onRegenerate}
      ref={forwardedRef}
      size="medium"
      tooltipPlacement="top-start"
      tooltipText={tooltipText ?? 'Generate new API key'}
      {...getDataUiActionAttribute(DataUiAction.Regenerate)}
      {...otherProps}
    />
  ),
);

ApiKeyRegenerationButton.displayName = 'ApiKeyRegenerationButton';
