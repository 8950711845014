import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  Roles_ListingInit_Finished,
  Roles_ListingInit_Started,
} from '../../constants/rolesActionTypes.ts';

interface IDeps {
  readonly loadRoles: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
}

const initRoleListingStarted = () => ({ type: Roles_ListingInit_Started }) as const;
const initRoleListingFinished = () => ({ type: Roles_ListingInit_Finished }) as const;

export type InitRoleListingActionsType = ReturnType<
  typeof initRoleListingStarted | typeof initRoleListingFinished
>;

export const makeInitRoleListingAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(initRoleListingStarted());

    await Promise.all([
      dispatch(deps.loadRoles(abortSignal)),
      dispatch(deps.loadUsers(abortSignal)),
    ]);

    dispatch(initRoleListingFinished());
  };
