import { Icons } from '@kontent-ai/component-library/Icons';
import { NotificationBarBlank } from '@kontent-ai/component-library/NotificationBar';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { IconSize, Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type TranslationNotificationBar = {
  readonly onClose: () => void;
  readonly statusComponent: React.ReactNode | null;
};

export const TranslationNotificationBar: React.FC<TranslationNotificationBar> = ({
  onClose,
  statusComponent,
}) => (
  <NotificationBarBlank
    onDismiss={onClose}
    {...getDataUiElementAttribute(DataUiElement.NotificationBarInfo)}
  >
    <Row spacing={Spacing.M} noWrap>
      <Column>
        <Icons.KopilotAi size={IconSize.L} spacing={Spacing.L} />
      </Column>
      <Column width="fit-content">{statusComponent}</Column>
    </Row>
  </NotificationBarBlank>
);
