import { assert } from '@kontent-ai/utils';
import { ISubscriptionUserWithSettingsServerModel } from '../../../../repositories/serverModels/SubscriptionUsersUsageServerModel.type.ts';
import {
  IUserProjectSettings,
  getUserProjectSettingsFromServerModel,
} from './UserProjectSettings.ts';

export interface ISubscriptionUserWithSettings {
  readonly email: string;
  readonly firstName: string;
  readonly isActivating: boolean;
  readonly isDeactivating: boolean;
  readonly isInvitationPending: boolean;
  readonly isEmployee: boolean;
  readonly isSubscriptionAdmin: boolean;
  readonly lastName: string;
  readonly userId: UserId;
  readonly userProjectsSettings: ReadonlyArray<IUserProjectSettings>;
}

const emptySubscriptionUserWithSettings: ISubscriptionUserWithSettings = {
  email: '',
  firstName: '',
  isActivating: false,
  isDeactivating: false,
  isInvitationPending: false,
  isEmployee: false,
  isSubscriptionAdmin: false,
  lastName: '',
  userId: '',
  userProjectsSettings: [],
};

export const createSubscriptionUserWithSettings = (
  params?: Partial<ISubscriptionUserWithSettings>,
): ISubscriptionUserWithSettings => ({
  ...emptySubscriptionUserWithSettings,
  ...params,
});

export function getSubscriptionUserWithSettingsFromServerModel(
  serverModel: ISubscriptionUserWithSettingsServerModel,
): ISubscriptionUserWithSettings {
  assert(!!serverModel, () => 'SubscriptionUserWithSettings server model is falsy.');

  return createSubscriptionUserWithSettings({
    userId: serverModel.userId,
    firstName: serverModel.firstName,
    lastName: serverModel.lastName,
    email: serverModel.email,
    isSubscriptionAdmin: serverModel.isSubscriptionAdmin,
    isEmployee: serverModel.isKenticoUser,
    isInvitationPending: serverModel.isInvitationPending,
    userProjectsSettings: serverModel.userProjectsSettings.map(
      getUserProjectSettingsFromServerModel,
    ),
  });
}
