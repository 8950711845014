import { Button } from '@kontent-ai/component-library/Button';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { FC } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRuleWithScope } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { emptyContentCanRule } from '../../../../constants/emptyRules.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { ContentCapabilityRulePicker } from './ContentCapabilityRulePicker.tsx';

type Props = {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly contentCanRules: ReadonlyArray<IRuleWithScope>;
  readonly isDisabled: boolean;
  readonly onChange: (newRules: ReadonlyArray<IRuleWithScope>) => void;
  readonly status: RoleEditorStatus;
};

export const ContentProductionCanRules: FC<Props> = ({
  contentCanRules,
  allTypes,
  onChange,
  isDisabled,
  status,
}) => (
  <>
    <p className="capability-rules__title">Users with this role can:</p>
    {contentCanRules.map((rule: IRuleWithScope, index: number) => (
      <ContentCapabilityRulePicker
        key={index}
        index={index}
        isDisabled={isDisabled}
        onRemove={() => onChange(contentCanRules.filter((_, i) => i !== index))}
        allTypes={allTypes}
        capabilityRule={rule}
        onChange={(newRule) => onChange(Collection.replace(contentCanRules, index, newRule))}
        status={status}
      />
    ))}
    {!isDisabled && (
      <Button
        disabled={isDisabled}
        buttonStyle="tertiary"
        onClick={() => onChange([...contentCanRules, emptyContentCanRule])}
        css={`
          max-width: fit-content;
          margin-bottom: ${px(Spacing.XXL)};
        `}
        {...getDataUiActionAttribute(DataUiAction.Add)}
      >
        Add rule
      </Button>
    )}
  </>
);

ContentProductionCanRules.displayName = 'ContentProductionCanRules';
