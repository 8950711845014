import { useContext } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  IItemElementsContextValue,
  ItemElementsContext,
} from '../../itemEditor/features/ContentComponent/context/ItemElementsContext.tsx';
import { RichTextItemElementContext } from '../../itemEditor/features/ContentItemEditing/context/RichTextItemElementContext.tsx';
import { getContentComponents } from '../../itemEditor/features/ContentItemEditing/utils/contentComponentUtils.ts';
import { IContentComponent } from '../../itemEditor/models/contentItem/ContentComponent.ts';

// NOTE: Content components can be both in top level item and in expanded items
// That is why we use the closest context to lookup for them
export const useContentComponents = (
  sourceContext?: React.Context<IItemElementsContextValue>,
): ReadonlyMap<Uuid, IContentComponent> | null => {
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);
  const { elements: contextElements } = useContext(sourceContext ?? ItemElementsContext);
  const fallbackToEditedItem = !sourceContext;
  const contentComponents = useSelector((state) => {
    if (!rootRichTextElementId) {
      return null;
    }
    const sourceElements =
      contextElements ??
      (fallbackToEditedItem ? state.contentApp.editedContentItemVariantElements : null);

    return sourceElements ? getContentComponents(sourceElements, rootRichTextElementId) : null;
  });

  return contentComponents;
};
