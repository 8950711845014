import { useObserveElementPresence } from '@kontent-ai/hooks';
import { noOperation } from '@kontent-ai/utils';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { StatusBarRightPaneId } from '../../../../_shared/components/StatusBar/StatusBarContainer.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { EnvironmentSettingsApp } from '../../root/components/EnvironmentSettingsApp.tsx';
import { initWorkflowEditing, saveWorkflowToServer } from '../actions/thunkWorkflowActions.ts';
import { leaveWorkflowEditor } from '../actions/workflowActions.ts';
import { WorkflowEditor as WorkflowEditorComponent } from '../components/WorkflowEditor.tsx';
import { WorkflowSettingsPage } from '../components/WorkflowSettingsPage.tsx';
import {
  IWorkflowListingOperationStatus,
  initialWorkflowListingOperationStatus,
} from '../model/WorkflowListingOperation.ts';
import { hasWorkflowEditorUnsavedChanges } from '../utils/hasWorkflowEditorUnsavedChanges.ts';
import { WorkflowToolbarActions } from './WorkflowToolbarActions.tsx';

type Props = {
  readonly workflowId: Uuid;
  readonly setOperationStatus: Dispatch<SetStateAction<IWorkflowListingOperationStatus>>;
};

export const WorkflowEditor = (props: Props) => {
  const [isInitThunkDone] = useThunkPromise(initWorkflowEditing, props.workflowId);

  useEffect(() => {
    props.setOperationStatus(initialWorkflowListingOperationStatus);
  }, [props.setOperationStatus]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(leaveWorkflowEditor());
    };
  }, []);

  const workflowName = useSelector((s) => s.workflowsApp.editorUi.editedWorkflowName);
  const hasUnsavedChanges = useSelector(hasWorkflowEditorUnsavedChanges);
  const isBeingSaved = useSelector(
    (s) => s.projectsApp.savingStatus.workflow.status === OperationStatus.Pending,
  );
  const allSet = useSelector(
    (s) =>
      isInitThunkDone && s.workflowsApp.editorUi.workflowLoadingStatus === LoadingStatus.Loaded,
  );
  const isAdvancedEditorEnabled = useSelector(isMultipleWorkflowsConfigurationVisible);

  const statusBarRightPaneRef = useObserveElementPresence(StatusBarRightPaneId);

  const Layout = isAdvancedEditorEnabled ? WorkflowSettingsPage : EnvironmentSettingsApp;

  return (
    <Layout>
      {allSet ? (
        <>
          {statusBarRightPaneRef.current &&
            createPortal(
              <WorkflowToolbarActions setOperationStatus={props.setOperationStatus} />,
              statusBarRightPaneRef.current,
            )}
          <WorkflowEditorComponent
            hasUnsavedChanges={hasUnsavedChanges}
            isAdvancedEditorEnabled={isAdvancedEditorEnabled}
            isBeingSaved={isBeingSaved}
            isCentered={isAdvancedEditorEnabled}
            pageTitle={isAdvancedEditorEnabled ? workflowName : undefined}
            onSaveWorkflow={() => {
              dispatch(saveWorkflowToServer()).catch(noOperation);
            }}
            onUnsavedNavigation={async (onSuccess, onFail) => {
              try {
                await dispatch(saveWorkflowToServer());
                onSuccess();
              } catch {
                onFail();
              }
            }}
          />
        </>
      ) : (
        <Loader />
      )}
    </Layout>
  );
};
