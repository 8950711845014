import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { CopyState } from '../../../applications/projects/constants/copyState.ts';
import { IBaseProject } from '../../../applications/projects/models/IBaseProject.type.ts';
import {
  IProjectListingUser,
  mapProjectListingUserFromServerModel,
} from '../../../applications/projects/models/ProjectListingUser.ts';
import { IProjectDetailsServerModel } from '../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';

export interface IProjectDetails extends IBaseProject {
  readonly activeLanguagesCount: number;
  readonly activeProjectContainerUsersCount: number;
  readonly copyState: CopyState;
  readonly copyType: string;
  readonly customRolesCount: number;
  readonly inactive: boolean;
  readonly isEnvironmentObsolete: boolean;
  readonly planId: Uuid;
  readonly planIsExpired: boolean;
  readonly planIsSuspended: boolean;
  readonly productionFrom: DateTimeStamp;
  readonly productionTo: DateTimeStamp;
  readonly projectActivatedAt: DateTimeStamp;
  readonly projectContainerId: Uuid;
  readonly projectCreatedAt: DateTimeStamp;
  readonly projectLocationId: string;
  readonly projectManagers: Immutable.List<IProjectListingUser>;
  readonly roles: ReadonlyArray<IProjectDetailsUserRole>;
  readonly subscriptionAdmins: Immutable.List<UserId>;
  readonly subscriptionId: Uuid;
  readonly subscriptionName: string;
}

const emptyProjectDetails: IProjectDetails = {
  activeLanguagesCount: 0,
  activeProjectContainerUsersCount: 0,
  copyState: CopyState.NotCloned,
  copyType: '',
  customRolesCount: 0,
  environmentName: '',
  inactive: false,
  isEnvironmentObsolete: false,
  masterEnvironmentId: '',
  planId: '',
  planIsExpired: false,
  planIsSuspended: false,
  productionFrom: '',
  productionTo: '',
  projectActivatedAt: '',
  projectContainerId: '',
  projectCreatedAt: '',
  projectId: '',
  projectLocationId: '',
  projectManagers: Immutable.List(),
  projectName: '',
  roles: [],
  sourceEnvironmentId: '',
  sourceEnvironmentName: '',
  subscriptionAdmins: Immutable.List(),
  subscriptionId: '',
  subscriptionName: '',
};

export const createProjectDetails = (params?: Partial<IProjectDetails>): IProjectDetails => ({
  ...emptyProjectDetails,
  ...params,
});

export interface IProjectDetailsUserRole {
  readonly roleName: string;
  readonly languageNames: ReadonlyArray<string>;
}

export function createProjectDetailsDomainModel(
  serverModel: IProjectDetailsServerModel,
): IProjectDetails {
  assert(typeof serverModel === 'object', () => 'Project detail is not an object');

  return {
    activeLanguagesCount: serverModel.activeLanguagesCount ?? 0,
    activeProjectContainerUsersCount: serverModel.activeProjectContainerUsersCount ?? 0,
    copyState: serverModel.copyState ?? CopyState.NotCloned,
    copyType: serverModel.copyType ?? '',
    customRolesCount: serverModel.customRolesCount ?? 0,
    inactive: serverModel.inactive ?? true,
    masterEnvironmentId: serverModel.masterProjectId ?? '',
    planId: serverModel.planId ?? '',
    planIsExpired: serverModel.planIsExpired ?? false,
    planIsSuspended: serverModel.planIsSuspended ?? false,
    productionFrom: serverModel.productionFrom ?? '',
    productionTo: serverModel.productionTo ?? '',
    projectActivatedAt: serverModel.projectActivatedAt ?? '',
    projectCreatedAt: serverModel.projectCreatedAt ?? '',
    projectContainerId: serverModel.projectContainerId ?? '',
    projectName: serverModel.projectContainerName ?? '',
    projectId: serverModel.projectId ?? '',
    projectLocationId: serverModel.projectLocationId ?? '',
    projectManagers: Immutable.List(
      (serverModel.projectManagers ?? []).map(mapProjectListingUserFromServerModel),
    ),
    environmentName: serverModel.projectName ?? '',
    roles: serverModel.roles ?? [],
    sourceEnvironmentId: serverModel.sourceProjectId ?? '',
    sourceEnvironmentName: serverModel.sourceProjectName ?? '',
    subscriptionAdmins: Immutable.List(serverModel.subscriptionAdmins ?? []),
    subscriptionId: serverModel.subscriptionId ?? '',
    subscriptionName: serverModel.subscriptionName ?? '',
    isEnvironmentObsolete: serverModel.isProjectObsolete ?? false,
  };
}
