import { Collection, numerically } from '@kontent-ai/utils';
import { ContentState } from 'draft-js';
import { convertServerModelToRawContent } from '../../../../applications/richText/utils/serverModel/editorServerModel.ts';
import { createRichTextContentDomainModelFromRaw } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { AiMessageErrorCode } from '../../../../repositories/serverModels/ai/AiMessageErrorCode.ts';
import {
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../../../../repositories/serverModels/ai/AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  CumulatedIdsResult,
  CumulatedStringResult,
  isCumulatedDraftJsOperationResponseMessage,
  isCumulatedIdsOperationResponseMessage,
  isCumulatedStringOperationResponseMessage,
} from '../../../../repositories/serverModels/ai/AiServerModels.result.ts';
import { FinishedActionParams } from '../hooks/useAiActionTracking.ts';
import { ResultSelector } from '../types/ResultSelector.type.ts';
import { AiError, AiErrorCode, messageErrorCodeToErrorCodeMap } from '../types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../utils/baseErrorMessageByErrorCode.ts';

const sortBySequenceNumberAscending = <T extends { readonly sequenceNumber: number }>(a: T, b: T) =>
  numerically(a.sequenceNumber, b.sequenceNumber);

export const transformCumulatedStringResults = (
  results: ReadonlyArray<CumulatedStringResult>,
): string | null => {
  const sortedResults = results.toSorted(sortBySequenceNumberAscending);
  return Collection.getLast(sortedResults)?.value ?? null;
};

export const transformCumulatedDraftJsResults = (
  results: ReadonlyArray<CumulatedDraftJsResult>,
): ContentState | null => {
  const sortedResults = results.toSorted(sortBySequenceNumberAscending);
  const lastValue = Collection.getLast(sortedResults)?.value;
  return lastValue
    ? createRichTextContentDomainModelFromRaw(convertServerModelToRawContent(lastValue))
    : null;
};

export const transformCumulatedIdsResults = (
  results: ReadonlyArray<CumulatedIdsResult>,
): ReadonlyArray<Uuid> | null => {
  const sortedResults = results.toSorted(sortBySequenceNumberAscending);
  return Collection.getLast(sortedResults)?.value ?? null;
};

export type ActionResult<T> = {
  readonly content: T | null;
  readonly error: AiError | null;
  readonly isFinished: boolean;
  readonly trackingParams: FinishedActionParams | null;
};

export type ActionResults = ReturnType<
  typeof getContentStateActionResult | typeof getStringActionResult | typeof getIdsActionResult
>;

const createTrackingParams = (
  messages: ReadonlyArray<OperationResponseMessage>,
  hasResult: boolean,
  errorCode: AiMessageErrorCode | AiErrorCode.InactivityTimeout | undefined,
): FinishedActionParams | null => {
  const operationId = messages.at(0)?.operationId;
  if (!operationId) {
    return null;
  }

  return {
    operationId,
    hasResult,
    errorCode,
  };
};

const getAiError = (errorMessage: ErrorOperationResponseMessage): AiError => {
  const aiErrorCode =
    messageErrorCodeToErrorCodeMap[errorMessage.error.code] ?? AiErrorCode.General;

  return aiErrorCode === AiErrorCode.UnableWithGeneratedReason
    ? {
        code: aiErrorCode,
        message: errorMessage.error.message ?? baseErrorMessageByErrorCode[AiErrorCode.General],
      }
    : { code: aiErrorCode };
};

export const getContentStateActionResult: ResultSelector<ActionResult<ContentState>> = (
  messages,
  context,
) => {
  const filteredMessages = messages.filter(isCumulatedDraftJsOperationResponseMessage);

  const content = transformCumulatedDraftJsResults(
    filteredMessages.map((message) => message.result),
  );

  if (context.hasTimedOut) {
    return {
      content,
      error: { code: AiErrorCode.InactivityTimeout },
      isFinished: true,
      trackingParams: createTrackingParams(messages, !!content, AiErrorCode.InactivityTimeout),
    } satisfies ActionResult<ContentState>;
  }

  const errorMessage = messages.find(isErrorOperationMessage);
  if (errorMessage) {
    const error = getAiError(errorMessage);

    return {
      content: error.code === AiErrorCode.OutputIncomplete ? content : null,
      error,
      isFinished: true,
      trackingParams: createTrackingParams(messages, !!content, errorMessage.error.code),
    } satisfies ActionResult<ContentState>;
  }

  return {
    content,
    error: null,
    isFinished: filteredMessages.some((message) => message.result.isFinished),
    trackingParams: createTrackingParams(messages, !!content, undefined),
  } satisfies ActionResult<ContentState>;
};

export const getStringActionResult: ResultSelector<ActionResult<string>> = (messages, context) => {
  const filteredMessages = messages.filter(isCumulatedStringOperationResponseMessage);

  const content = transformCumulatedStringResults(
    filteredMessages.map((message) => message.result),
  );

  if (context.hasTimedOut) {
    return {
      content,
      error: { code: AiErrorCode.InactivityTimeout },
      isFinished: true,
      trackingParams: createTrackingParams(messages, !!content, AiErrorCode.InactivityTimeout),
    };
  }

  const errorMessage = messages.find(isErrorOperationMessage);
  if (errorMessage) {
    const error = getAiError(errorMessage);

    return {
      content: error.code === AiErrorCode.OutputIncomplete ? content : null,
      error,
      isFinished: true,
      trackingParams: createTrackingParams(messages, !!content, errorMessage.error.code),
    } satisfies ActionResult<string>;
  }

  return {
    content,
    error: null,
    isFinished: filteredMessages.some((message) => message.result.isFinished),
    trackingParams: createTrackingParams(messages, !!content, undefined),
  } satisfies ActionResult<string>;
};

export const getIdsActionResult: ResultSelector<ActionResult<ReadonlyArray<Uuid>>> = (
  messages,
  context,
) => {
  const filteredMessages = messages.filter(isCumulatedIdsOperationResponseMessage);

  const content = transformCumulatedIdsResults(filteredMessages.map((message) => message.result));

  if (context.hasTimedOut) {
    return {
      content,
      error: { code: AiErrorCode.InactivityTimeout },
      isFinished: true,
      trackingParams: createTrackingParams(messages, !!content, AiErrorCode.InactivityTimeout),
    };
  }

  const errorMessage = messages.find(isErrorOperationMessage);
  if (errorMessage) {
    const error = getAiError(errorMessage);

    return {
      content: error.code === AiErrorCode.OutputIncomplete ? content : null,
      error,
      isFinished: true,
      trackingParams: createTrackingParams(messages, !!content, errorMessage.error.code),
    } satisfies ActionResult<ReadonlyArray<Uuid>>;
  }

  return {
    content,
    error: null,
    isFinished: filteredMessages.some((message) => message.result.isFinished),
    trackingParams: createTrackingParams(messages, !!content, undefined),
  } satisfies ActionResult<ReadonlyArray<Uuid>>;
};
