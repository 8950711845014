import React from 'react';
import { useLocation } from 'react-router';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCannotViewItemMessage } from '../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { createBuildLinkForVariantReference } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getTypeName } from '../../../../../data/reducers/contentTypes/selectors/getTypeName.ts';
import { openSingleAssetUsage } from '../../actions/thunkAssetLibraryActions.ts';
import {
  ISingleAssetUsageOwnProps,
  SingleAssetUsage as SingleAssetUsageComponent,
} from '../../components/AssetEditing/SingleAssetUsage.tsx';

interface IContainerProps extends ISingleAssetUsageOwnProps {
  readonly assetId: Uuid;
  readonly isInDialog: boolean;
  readonly projectId: string;
}

export const SingleAssetUsage: React.FC<IContainerProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const typeName = useSelector((s) => getTypeName(props.usage.primary.typeId, s));

  return (
    <SingleAssetUsageComponent
      assetScrollId={CreateAutoScrollId.forAsset(props.assetId)}
      disabledMessage={getCannotViewItemMessage(props.usage.primary)}
      onUsageClick={props.isInDialog ? undefined : () => dispatch(openSingleAssetUsage())}
      typeName={typeName}
      usage={props.usage}
      variantReferenceBuildLink={createBuildLinkForVariantReference(
        props.projectId,
        location.pathname,
      )}
    />
  );
};

SingleAssetUsage.displayName = 'SingleAssetUsage';
