import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';

interface IItemElementHeaderSubsectionProps {
  readonly isFirst?: boolean;
}

export const ItemElementHeaderSubsection: React.FC<
  React.PropsWithChildren<IItemElementHeaderSubsectionProps>
> = ({ children, isFirst }) => (
  <Box
    display="flex"
    alignItems="center"
    flexGrow={isFirst ? 9999 : 1}
    justifyContent={isFirst ? 'flex-start' : 'flex-end'}
    paddingY={isFirst ? Spacing.S : Spacing.None}
  >
    {children}
  </Box>
);

ItemElementHeaderSubsection.displayName = 'ItemElementHeaderSubsection';
