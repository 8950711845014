import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  EntityWebhooks_InitCreate_Completed,
  EntityWebhooks_InitCreate_Started,
  EntityWebhooks_InitEdit_Completed,
  EntityWebhooks_InitEdit_Started,
} from '../../../../applications/entityWebhooks/constants/entityWebhooksActionTypes.ts';

const initialState = LoadingStatus.InitialEmpty;

export function loadingStatus(state = initialState, action: Action): LoadingStatus {
  switch (action.type) {
    case EntityWebhooks_InitCreate_Started:
    case EntityWebhooks_InitEdit_Started:
      return LoadingStatus.Loading;

    case EntityWebhooks_InitCreate_Completed:
    case EntityWebhooks_InitEdit_Completed:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
}
