export type WebhookMessageFilterOption = {
  readonly id: WebhookMessageFilterOptionId;
  readonly name: string;
};

export enum WebhookMessageFilterOptionId {
  ShowFailedAndDelivered = 'ShowFailedAndDelivered',
  ShowAllFailed = 'ShowAllFailed',
  ShowLastResponseFailed = 'ShowLastResponseFailed',
}

export const WebhookMessageFilterOptions: ReadonlyArray<WebhookMessageFilterOption> = [
  {
    id: WebhookMessageFilterOptionId.ShowFailedAndDelivered,
    name: 'Show failed and delivered',
  },
  {
    id: WebhookMessageFilterOptionId.ShowAllFailed,
    name: 'Show all failed',
  },
  {
    id: WebhookMessageFilterOptionId.ShowLastResponseFailed,
    name: 'Show last response failed',
  },
];
