import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  INewContentItemDialogProperties,
  INewContentItemForm,
} from '../reducers/INewContentItemStoreState.type.ts';

export const getNewContentItemForm = (state: IStore): INewContentItemForm =>
  state.contentApp.newContentItem.form;

export const getNewContentItemDialogProperties = (state: IStore): INewContentItemDialogProperties =>
  state.contentApp.newContentItem.dialogProperties;
