import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { anyContentTypeContentType } from '../constants/anyContentTypeContentType.ts';

export const handleAnyContentTypeContentTypeOnContentTypesChangedInFormInput = <
  TContentType extends IContentType,
>(
  formValues: ReadonlyArray<TContentType>,
  previousValues: ReadonlyArray<TContentType>,
): ReadonlyArray<TContentType> => {
  const oldValuesContainAnyContentType = previousValues.some(
    (contentType) => contentType.id === anyContentTypeContentType.id,
  );
  const newValuesContainAnyContentType = formValues.some(
    (contentType) => contentType.id === anyContentTypeContentType.id,
  );
  const newValuesContainNormalContentType = formValues.some(
    (contentType) => contentType.id !== anyContentTypeContentType.id,
  );
  return (oldValuesContainAnyContentType && newValuesContainNormalContentType) ||
    !newValuesContainAnyContentType
    ? formValues.filter((contentType) => contentType.id !== anyContentTypeContentType.id)
    : formValues.filter((contentType) => contentType.id === anyContentTypeContentType.id);
};
