import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackUserEventWithDataAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_CancelScheduledPublish_Failed,
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_CancelScheduledPublish_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { BulkCancelScheduledPublishErrorMessage } from '../../../constants/uiConstants.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface ICancelScheduledPublishSelectedContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly trackUserEvent: TrackUserEventWithDataAction;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_CancelScheduledPublish_Started>(
  ContentListing_CancelScheduledPublish_Started,
);
const actionFinished = createBulkActionFinished<
  typeof ContentListing_CancelScheduledPublish_Finished
>(ContentListing_CancelScheduledPublish_Finished);
const actionFailed = createBulkActionFailed<typeof ContentListing_CancelScheduledPublish_Failed>(
  ContentListing_CancelScheduledPublish_Failed,
);

export type CancelScheduledPublishSelectedContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished | typeof actionFailed
>;

export const cancelScheduledPublishSelectedContentItemsActionCreator =
  (deps: ICancelScheduledPublishSelectedContentItemsActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<any> => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());

    const query = getItemListingOperationQueryFromFilter(getState());

    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.cancelScheduledPublishOfVariants(
        selectedLanguageId,
        query,
      );

      dispatch(actionFinished(createBulkActionResult(operationResult, selectedLanguageId)));
    } catch (error) {
      logError('Error while bulk cancelling scheduled publish', error);
      dispatch(actionFailed(BulkCancelScheduledPublishErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
