import { combineReducers } from '@reduxjs/toolkit';
import { calendarView } from './internalReducers/calendarView.ts';
import { cancellationToken } from './internalReducers/cancellationToken.ts';
import { drillDownDate } from './internalReducers/drillDownDate.ts';
import { itemDetails } from './internalReducers/itemDetails.ts';
import { itemsLoadingStatus } from './internalReducers/itemsLoadingStatus.ts';
import { orderedItemIds } from './internalReducers/orderedItemIds.ts';
import { selectedArrangement } from './internalReducers/selectedArrangement.ts';
import { selectedPeriod } from './internalReducers/selectedPeriod.ts';
import { wasDataCountLimitExceeded } from './internalReducers/wasDataCountLimitExceeded.ts';

export const calendarAppReducer = combineReducers({
  calendarView,
  cancellationToken,
  drillDownDate,
  itemDetails,
  itemsLoadingStatus,
  orderedItemIds,
  selectedArrangement,
  selectedPeriod,
  wasDataCountLimitExceeded,
});
