import { MapiKeyCapability } from '../models/MapiKeyCapability.type.ts';
import { Permissions } from '../validation/mapiKeyValidationConfig.ts';

export const getMapiKeyCapabilitiesFromPermissions = (
  permissions: Permissions,
): ReadonlyArray<MapiKeyCapability> => [
  ...(permissions.canReadContent ? [MapiKeyCapability.ReadContent] : []),
  ...(permissions.canManageContent ? [MapiKeyCapability.ManageContent] : []),
  ...(permissions.canReadAssets ? [MapiKeyCapability.ReadAssets] : []),
  ...(permissions.canManageAssets ? [MapiKeyCapability.ManageAssets] : []),
  ...(permissions.canManageContentModel ? [MapiKeyCapability.ManageContentModel] : []),
  ...(permissions.canManageEnvironmentSettings
    ? [MapiKeyCapability.ManageEnvironmentSettings]
    : []),
  ...(permissions.canManageEnvironments ? [MapiKeyCapability.ManageEnvironments] : []),
  ...(permissions.canManageCustomApps ? [MapiKeyCapability.ManageCustomApps] : []),
];
