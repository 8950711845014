import { createGuid } from '@kontent-ai/utils';
import { logError } from '../../../../../_shared/utils/logError.ts';
import type { DiffWorker } from '../workers/@types/diff.worker.d.ts';
import { IChange } from './IDiffWordsWithSpace.type.ts';

type Resolvers = {
  readonly resolve: (result: ReadonlyArray<IChange>) => void;
  readonly reject: (reason?: any) => void;
};

type DiffWorkerFactoryResult = {
  readonly worker: Omit<DiffWorker, 'terminate'>;
  readonly terminate: () => void;
};

const callerIdToResolvers = new Map<Uuid, Resolvers>();

const getDiffWorker = (function () {
  let worker: DiffWorker | null = null;

  return (): DiffWorkerFactoryResult => {
    worker = worker ?? new Worker(new URL('../workers/diff.worker.ts', import.meta.url));

    return {
      worker,
      terminate: () => {
        worker?.terminate();
        worker = null;
      },
    };
  };
})();

export async function diffWordsWithSpaceParallel(
  oldTextParts: ReadonlyArray<string>,
  newTextParts: ReadonlyArray<string>,
): Promise<ReadonlyArray<IChange>> {
  const { worker, terminate } = getDiffWorker();

  worker.onerror = ({ message }) => logError(message);

  worker.onmessage = ({ data: { callerId, result } }) => {
    const callerResolvers = callerIdToResolvers.get(callerId);
    callerIdToResolvers.delete(callerId);

    if (callerResolvers) {
      callerResolvers.resolve(result);

      if (!callerIdToResolvers.size) {
        terminate();
      }
    } else {
      logError(`Missing resolver for callerId: '${callerId}'`);
    }
  };

  return new Promise<ReadonlyArray<IChange>>((resolve, reject) => {
    const callerId = createGuid();
    const callerResolvers = {
      resolve,
      reject,
    };
    callerIdToResolvers.set(callerId, callerResolvers);

    worker.postMessage({
      oldTextParts,
      newTextParts,
      callerId,
    });
  });
}
