import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import { IUserToInviteResponseServerModel } from '../../../../../repositories/serverModels/InviteUserServerModel.type.ts';
import { Invite_ExistingUsers_Loaded } from '../../constants/usersActionTypes.ts';

interface ILoadExistingUsersDependencies {
  readonly peopleRepository: Pick<IPeopleRepository, 'getPeopleForInvitation'>;
}

const existingUsersLoaded = (existingUsers: ReadonlyArray<IUserToInviteResponseServerModel>) =>
  ({
    type: Invite_ExistingUsers_Loaded,
    payload: { existingUsers },
  }) as const;

export type LoadExistingUsers = ReturnType<typeof existingUsersLoaded>;

export const loadExistingUsersCreator =
  (dependencies: ILoadExistingUsersDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const usersToInviteServerModel =
      await dependencies.peopleRepository.getPeopleForInvitation(abortSignal);

    dispatch(existingUsersLoaded(usersToInviteServerModel));
  };
