import { combineReducers } from '@reduxjs/toolkit';
import Immutable from 'immutable';
import { delayedItemsIds } from './aggregations/delayedItemsIds.ts';
import { itemsByWorkflowByStep } from './aggregations/itemsByWorkflowByStep.ts';
import { itemsOnTrackIds } from './aggregations/itemsOnTrackIds.ts';
import { itemsWithoutDueDatesIds } from './aggregations/itemsWithoutDueDatesIds.ts';
import { scheduledToPublishItemsIds } from './aggregations/scheduledToPublishItemsIds.ts';

export interface AggregationsState {
  readonly itemsWithoutDueDatesIds: Immutable.Set<Uuid>;
  readonly itemsOnTrackIds: Immutable.Set<Uuid>;
  readonly delayedItemsIds: Immutable.Set<Uuid>;
  readonly scheduledToPublishItemsIds: Immutable.Set<Uuid>;
  readonly itemsByWorkflowByStep: ReadonlyMap<Uuid, ReadonlyMap<Uuid, ReadonlySet<Uuid>>>;
}

export const aggregations = combineReducers({
  itemsWithoutDueDatesIds,
  itemsOnTrackIds,
  delayedItemsIds,
  scheduledToPublishItemsIds,
  itemsByWorkflowByStep,
});
