import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../_shared/selectors/contentCollections.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { areSpacesInCollectionsAvailable } from './areSpacesInCollectionsAvailable.ts';

export const getContentItemListingHiddenColumns = (state: IStore) => {
  const {
    contentApp: {
      listingUi: { hiddenColumns: storedHiddenColumns },
    },
  } = state;
  const hiddenColumns = new Set<ItemColumnCode>(storedHiddenColumns.toArray());
  const areCollectionsAvailable = areCollectionsVisibleForCurrentUser(state);
  const areSpacesAvailable = areSpacesInCollectionsAvailable(state);

  if (!areCollectionsAvailable) {
    hiddenColumns.add(ItemColumnCode.Collection);
  }

  if (!areSpacesAvailable) {
    hiddenColumns.add(ItemColumnCode.Space);
  }

  return hiddenColumns;
};
