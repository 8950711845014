import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { isTypeElementValid } from '../../../utils/typeValidationUtils.ts';

interface IDeps {
  readonly setFirstInvalidElement: (element: IBaseTypeElementData) => Action;
}

export const createFocusFirstIncompleteElementAction =
  (deps: IDeps) => (): ThunkFunction => (dispatch, getState) => {
    const {
      editedType: { typeElements },
      validationResults,
    } = getState().contentModelsApp.typeEditor;

    const firstInvalidElement = typeElements.find((element) => {
      const result = validationResults.get(element.elementId);
      return !isTypeElementValid(result);
    });

    if (firstInvalidElement) {
      dispatch(deps.setFirstInvalidElement(firstInvalidElement));
    }
  };
