import React, { useMemo } from 'react';

interface IContentComponentContextState {
  readonly contentComponentId: Uuid | null;
}

const defaultContext: IContentComponentContextState = {
  contentComponentId: null,
};

export const ContentComponentContext =
  React.createContext<IContentComponentContextState>(defaultContext);

interface IContentComponentContextProviderProps {
  readonly contentComponentId: Uuid;
}

export const ContentComponentContextProvider: React.FC<
  React.PropsWithChildren<IContentComponentContextProviderProps>
> = ({ contentComponentId, children }) => {
  const value: IContentComponentContextState = useMemo(
    () => ({ contentComponentId }),
    [contentComponentId],
  );

  return (
    <ContentComponentContext.Provider value={value}>{children}</ContentComponentContext.Provider>
  );
};
ContentComponentContextProvider.displayName = 'ContentComponentContextProvider';
