import React from 'react';
import { getConsistentSelectedTermIds } from '../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { ITaxonomyItemElement } from '../../../models/contentItemElements/TaxonomyItemElement.ts';
import { TaxonomySelector } from '../../ContentItemEditing/containers/elements/taxonomy/TaxonomySelector.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

export interface IContentComponentTaxonomyElementStateProps {
  readonly taxonomyGroup?: ITaxonomyGroup;
  readonly isNumberOfTermsLimitMet?: boolean;
}

export interface IContentComponentTaxonomyElementOwnProps
  extends IContentComponentItemElementOwnProps<ITaxonomyItemElement, ITaxonomyTypeElement> {}

interface ITaxonomyElementProps
  extends IContentComponentTaxonomyElementStateProps,
    IContentComponentTaxonomyElementOwnProps {}

export class ContentComponentTaxonomyElement extends React.PureComponent<ITaxonomyElementProps> {
  static displayName = 'ContentComponentTaxonomyElement';

  private readonly onTermSelectionChanged = (selectedTerms: ReadonlySet<Uuid>): void => {
    const { elementData, onUpdate, taxonomyGroup, typeElement } = this.props;

    if (!taxonomyGroup) {
      return;
    }

    onUpdate({
      ...elementData,
      groupId: typeElement.taxonomyGroupId,
      value: selectedTerms,
    });
  };

  render() {
    const {
      taxonomyGroup,
      elementData,
      typeElement,
      disabled,
      validationResultSelectorId,
      contentComponentId,
      isNumberOfTermsLimitMet,
    } = this.props;

    return (
      <ContentComponentItemElement
        typeElement={typeElement}
        validationResultSelectorId={validationResultSelectorId}
        disabled={disabled}
        contentComponentId={contentComponentId}
        isCompact
      >
        <TaxonomySelector
          disabled={disabled}
          taxonomyGroupId={typeElement.taxonomyGroupId}
          selectedTermIds={getConsistentSelectedTermIds(
            elementData.groupId,
            elementData.value,
            taxonomyGroup,
          )}
          onTermSelectionChanged={disabled ? undefined : this.onTermSelectionChanged}
          defaultValue={typeElement.defaultValue}
          isNumberOfTermsLimitMet={isNumberOfTermsLimitMet}
          maxItems={typeElement.maxItems}
          minItems={typeElement.minItems}
        />
      </ContentComponentItemElement>
    );
  }
}
