export const Relations_CollapseAllNodes = 'Relations_CollapseAllNodes';
export const Relations_CollapseNode = 'Relations_CollapseNode';

export const Relations_ExpandNode_Failed = 'Relations_ExpandNode_Failed';
export const Relations_ExpandNode_Finished = 'Relations_ExpandNode_Finished';
export const Relations_ExpandNode_Started = 'Relations_ExpandNode_Started';

export const Relations_HideRootSelector = 'Relations_HideRootSelector';
export const Relations_ShowRootSelector = 'Relations_ShowRootSelector';

export const Relations_InitFailed = 'Relations_InitFailed';
export const Relations_InitFinished = 'Relations_InitFinished';
export const Relations_InitStarted = 'Relations_InitStarted';

export const Relations_LoadDefaultRoot_Failed = 'Relations_LoadDefaultRoot_Failed';
export const Relations_LoadDefaultRoot_Finished = 'Relations_LoadDefaultRoot_Finished';
export const Relations_LoadDefaultRoot_Started = 'Relations_LoadDefaultRoot_Started';

export const Relations_SetDefaultRoot_Failed = 'Relations_SetDefaultRoot_Failed';
export const Relations_SetDefaultRoot_Finished = 'Relations_SetDefaultRoot_Finished';
export const Relations_SetDefaultRoot_Started = 'Relations_SetDefaultRoot_Started';

export const Relations_SetRoot_Failed = 'Relations_SetRoot_Failed';
export const Relations_SetRoot_Finished = 'Relations_SetRoot_Finished';
export const Relations_SetRoot_Started = 'Relations_SetRoot_Started';
