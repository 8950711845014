import React from 'react';
import { DataTableCell } from '../../../../../_shared/components/DataTable/DataTableCell.tsx';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { SearchPhraseHighlighter } from '../../../../../_shared/components/Highlighting/SearchPhraseHighlighter.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Tag } from '../../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  formatUserFirstNameForUsersListing,
  formatUserLastNameForUsersListing,
} from '../../../../../_shared/utils/usersUtils.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { UserStatusCellContent } from '../../containers/userListing/UserStatusCellContent.tsx';
import { UserRolesCell } from './UserRolesCell.tsx';

type Props = {
  readonly contributor: IProjectContributor;
  readonly pathToUserDetail: string;
};

export const UserListingRow: React.FC<Props> = (props) => {
  const { contributor, pathToUserDetail } = props;

  const searchPhrase = useSelector((state) => state.usersApp.filter.byName || '');
  const isLinkDisabled = contributor.isInviteRevoking;

  return (
    <DataTableRow
      id={contributor.userId}
      key={contributor.userId}
      dataUiObjectName={contributor.email}
    >
      <LinkDataTableCell
        disabled={isLinkDisabled}
        linkPath={pathToUserDetail}
        focusableRowLinkAriaLabel={
          isLinkDisabled
            ? undefined
            : `visit user — ${formatUserFirstNameForUsersListing(contributor)}`
        }
      >
        <span data-hj-suppress="">
          <SearchPhraseHighlighter
            searchPhrase={searchPhrase}
            text={formatUserFirstNameForUsersListing(contributor)}
          />
        </span>
      </LinkDataTableCell>
      <LinkDataTableCell disabled={isLinkDisabled} linkPath={pathToUserDetail}>
        <span data-hj-suppress="">
          <SearchPhraseHighlighter
            searchPhrase={searchPhrase}
            text={formatUserLastNameForUsersListing(contributor)}
          />
        </span>
      </LinkDataTableCell>
      <LinkDataTableCell disabled={isLinkDisabled} linkPath={pathToUserDetail}>
        <SearchPhraseHighlighter searchPhrase={searchPhrase} text={contributor.email} />
      </LinkDataTableCell>
      <LinkDataTableCell disabled={isLinkDisabled} linkPath={pathToUserDetail}>
        {contributor.isAdminOnProject && (
          <Tag color={TagColor.Gray} customClass="data-table__tag--admin">
            Admin
          </Tag>
        )}
        <UserRolesCell user={contributor} />
      </LinkDataTableCell>
      <DataTableCell>
        <UserStatusCellContent contributor={contributor} />
      </DataTableCell>
    </DataTableRow>
  );
};

UserListingRow.displayName = 'UserListingRow';
