import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_ChangePlan_Failed,
  Subscription_ChangePlan_Finished,
  Subscription_ChangePlan_Requested,
} from '../constants/actionTypes.ts';

export const changeInProgress = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_ChangePlan_Requested:
      return true;

    case Subscription_ChangePlan_Finished:
    case Subscription_ChangePlan_Failed:
      return false;

    default:
      return state;
  }
};
