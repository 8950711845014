import React from 'react';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getMemoizedContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { getListingContentItem } from '../../../../../_shared/selectors/getListingContentItem.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { hasCapabilityInLanguage } from '../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getEditedContentItemType } from '../../../selectors/getEditedContentItemType.ts';
import { isDefaultVariantRequiredForPublish } from '../../ContentItemEditing/utils/itemValidationUtils.ts';
import { DefaultVariantRequiredTooltip } from '../constants/uiConstants.ts';
import { LazyChildRow } from './ChildRow.tsx';

const shouldParentItemDefaultVariantBeVisible = (state: IStore): boolean => {
  const selectedLanguageId = getSelectedLanguageId(state);
  const editingDefaultVariant = selectedLanguageId === DefaultVariantId;

  if (editingDefaultVariant) {
    return false;
  }

  const canViewDefaultVariant = hasCapabilityInLanguage(
    state,
    Capability.ViewContent,
    DefaultVariantId,
  );
  if (!canViewDefaultVariant) {
    return false;
  }

  const itemType = getEditedContentItemType(state);
  return itemType?.contentElements.some((element) => element.isNonLocalizable) ?? false;
};

const getParentItemDefaultVariantRequiredMessage = (
  state: IStore,
  contentItemId: ContentItemId,
): string | null => {
  const itemType = getEditedContentItemType(state);

  if (!itemType) {
    return null;
  }

  const item = getListingContentItem(state, contentItemId);
  const canViewDefaultVariant = hasActiveVariantCapability(ActiveCapabilityType.ViewContent, item);
  const selectedLanguageName = getSelectedLanguageNameOrPlaceholder(state);

  const isRequired = isDefaultVariantRequiredForPublish(
    itemType,
    false,
    canViewDefaultVariant,
    item,
  );
  return isRequired ? DefaultVariantRequiredTooltip(selectedLanguageName) : null;
};

export const ParentItemDefaultVariantRow: React.FC = () => {
  const editedContentItem = useSelector(getEditedContentItem);
  const contentItemId = getMemoizedContentItemId(editedContentItem.id, DefaultVariantId);
  const shouldBeVisible = useSelector(shouldParentItemDefaultVariantBeVisible);
  const requiredMessage = useSelector((s) =>
    getParentItemDefaultVariantRequiredMessage(s, contentItemId),
  );

  if (!shouldBeVisible) {
    return null;
  }

  return (
    <LazyChildRow
      contentItemId={contentItemId}
      depth={1}
      disabledMessage={requiredMessage ?? undefined}
      hideOutsideViewport={false}
    />
  );
};

ParentItemDefaultVariantRow.displayName = 'ParentItemDefaultVariantRow';
