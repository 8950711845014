export const ContentType_Creator_CreationFinished = 'ContentType_Creator_CreationFinished';
export const ContentType_Creator_CreationStarted = 'ContentType_Creator_CreationStarted';
export const ContentType_Creator_InitFinished = 'ContentType_Creator_InitFinished';
export const ContentType_Creator_InitStarted = 'ContentType_Creator_InitStarted';
export const ContentType_Editor_AnyInvalidElement_ShowWarning =
  'ContentType_Editor_AnyInvalidElement_ShowWarning';
export const ContentType_Editor_ChangeCodename = 'ContentType_Editor_ChangeCodename';
export const ContentType_Editor_ChangeContentTypeName = 'ContentType_Editor_ChangeContentTypeName';
export const ContentType_Editor_ChangeMultipleChoiceOptionCodename =
  'ContentType_Editor_ChangeMultipleChoiceOptionCodename';
export const ContentType_Editor_ChangeTypeElementCodename =
  'ContentType_Editor_ChangeTypeElementCodename';
export const ContentType_Editor_ContentGroupCodenameRenamed =
  'ContentType_Editor_ContentGroupCodenameRenamed';
export const ContentType_Editor_ContentGroupCreated = 'ContentType_Editor_ContentGroupCreated';
export const ContentType_Editor_ContentGroupDeleted = 'ContentType_Editor_ContentGroupDeleted';
export const ContentType_Editor_ContentGroupMoveFinished =
  'ContentType_Editor_ContentGroupMoveFinished';
export const ContentType_Editor_ContentGroupMoveStarted =
  'ContentType_Editor_ContentGroupMoveStarted';
export const ContentType_Editor_ContentGroupMoved = 'ContentType_Editor_ContentGroupMoved';
export const ContentType_Editor_ContentGroupRenamed = 'ContentType_Editor_ContentGroupRenamed';
export const ContentType_Editor_DuplicatingFailed = 'ContentType_Editor_DuplicatingFailed';
export const ContentType_Editor_DuplicatingFinished = 'ContentType_Editor_DuplicatingFinished';
export const ContentType_Editor_DuplicatingStarted = 'ContentType_Editor_DuplicatingStarted';
export const ContentType_Editor_HideConfiguration = 'ContentType_Editor_HideConfiguration';
export const ContentType_Editor_InitFinished = 'ContentType_Editor_InitFinished';
export const ContentType_Editor_MultipleChoiceCreateOption =
  'ContentType_Editor_MultipleChoiceCreateOption';
export const ContentType_Editor_MultipleChoiceDeleteOption =
  'ContentType_Editor_MultipleChoiceDeleteOption';
export const ContentType_Editor_MultipleChoiceMoveOption =
  'ContentType_Editor_MultipleChoiceMoveOption';
export const ContentType_Editor_MultipleChoiceOptionDeselected =
  'ContentType_Editor_MultipleChoiceOptionDeselected';
export const ContentType_Editor_MultipleChoiceOptionDropped =
  'ContentType_Editor_MultipleChoiceOptionDropped';
export const ContentType_Editor_MultipleChoiceOptionLabelChanged =
  'ContentType_Editor_MultipleChoiceOptionLabelChanged';
export const ContentType_Editor_MultipleChoiceOptionPickedUp =
  'ContentType_Editor_MultipleChoiceOptionPickedUp';
export const ContentType_Editor_MultipleChoiceOptionSelected =
  'ContentType_Editor_MultipleChoiceOptionSelected';
export const ContentType_Editor_MultipleChoiceUpdateOption =
  'ContentType_Editor_MultipleChoiceUpdateOption';
export const ContentType_Editor_SavingFinished = 'ContentType_Editor_SavingFinished';
export const ContentType_Editor_SelectedContentGroupChanged =
  'ContentType_Editor_SelectedContentGroupChanged';
export const ContentType_Editor_SelectedTypeElementsSentToContentGroup =
  'ContentType_Editor_SelectedTypeElementsSentToContentGroup';
export const ContentType_Editor_SelectedTypeElementsSentToNewContentGroup =
  'ContentType_Editor_SelectedTypeElementsSentToNewContentGroup';
export const ContentType_Editor_ShowConfiguration = 'ContentType_Editor_ShowConfiguration';
export const ContentType_Editor_TypeElementSelectionToggled =
  'ContentType_Editor_TypeElementSelectionToggled';
export const ContentType_Listing_ArchiveContentTypesFinished =
  'ContentType_Listing_ArchiveContentTypesFinished';
export const ContentType_Listing_ArchiveContentTypesStarted =
  'ContentType_Listing_ArchiveContentTypesStarted';
export const ContentType_Listing_FilterSearchPhraseChanged =
  'ContentType_Listing_FilterSearchPhraseChanged';
export const ContentType_Listing_InitFinished = 'ContentType_Listing_InitFinished';
export const ContentType_Listing_InitStarted = 'ContentType_Listing_InitStarted';
export const ContentType_Listing_ResetOperationState = 'ContentType_Listing_ResetOperationState';
export const ContentType_Listing_RestoreContentTypesFinished =
  'ContentType_Listing_RestoreContentTypesFinished';
export const ContentType_Listing_RestoreContentTypesStarted =
  'ContentType_Listing_RestoreContentTypesStarted';
export const ContentType_Listing_RouteEntered = 'ContentType_Listing_RouteEntered';
export const ContentType_Listing_SelectedTypesUpdated = 'ContentType_Listing_SelectedTypesUpdated';
export const ContentType_LoadComponentUsage_Dismissed = 'ContentType_LoadComponentUsage_Dismissed';
export const ContentType_LoadComponentUsage_LoadUsageCount =
  'ContentType_LoadComponentUsage_LoadUsageCount';
