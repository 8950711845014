import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { WorkflowOverviewState } from '../models/WorkflowOverviewState.ts';
import { IWorkflowStepOverview } from '../utils/createWorkflowOverview.ts';
import { StepOverview } from './StepOverview.tsx';

interface IWorkflowOverviewProps {
  readonly name: string;
  readonly maxStepCount: number;
  readonly steps: readonly IWorkflowStepOverview[];
  readonly onViewWorkflowStep: (stepId: Uuid) => void;
  readonly workflowOverviewState: WorkflowOverviewState;
}

export const WorkflowOverview: React.FC<IWorkflowOverviewProps> = ({
  name,
  maxStepCount,
  steps,
  onViewWorkflowStep,
  workflowOverviewState,
}) => (
  <Stack spacing={Spacing.L}>
    <Box
      typography={Typography.HeadlineSmall}
      overflow="hidden"
      css="text-overflow: ellipsis; white-space: nowrap;"
    >
      <span title={name}>{name}</span>
    </Box>
    {workflowOverviewState === WorkflowOverviewState.FilteredByWorkflow ||
    steps.some((step) => step.count > 0) ? (
      <Stack spacing={Spacing.XS}>
        {steps.map((step) => (
          <StepOverview
            key={step.id}
            step={step}
            maxStepCount={maxStepCount}
            onViewWorkflowStep={onViewWorkflowStep}
          />
        ))}
      </Stack>
    ) : (
      <Box typography={Typography.Subheadline}>
        {workflowOverviewState === WorkflowOverviewState.FilteredByOthers
          ? 'There’s no content item in this workflow that was edited in the last 90 days and matches the current filters. Try using different filters.'
          : 'No content item in this workflow was edited in last 90 days.'}
      </Box>
    )}
  </Stack>
);
WorkflowOverview.displayName = 'WorkflowOverview';
