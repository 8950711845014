import { Collection } from '@kontent-ai/utils';
import { IDataStore } from '../../../../data/IDataStore.type.ts';
import {
  getConsistentCollectionFilter,
  getConsistentTaxonomyFilter,
} from '../../content/features/ListingFilter/utils/consistencyUtils.ts';
import { uncategorizedAssetFilterTaxonomyTermId } from '../constants/taxonomyConstants.ts';
import { IAssetLibraryQuery } from '../models/IAssetLibraryQuery.ts';
import { getAssetTaxonomiesForFilter } from './assetTaxonomyUtils.ts';

export enum AssetQueryOrigin {
  // We now recognize only single origin but to keep the life cycle similar to content inventory
  // and expecting more complex scenarios when there is a workflow for assets in future (and therefore a need for project overview)
  // we keep the origin in the game despite having only one.
  AssetLibrary = 'asset-library',
}

export const getConsistentAssetLibraryQuery = (
  dataStore: IDataStore,
  queryFromStorage: IAssetLibraryQuery,
): IAssetLibraryQuery => {
  return {
    ...queryFromStorage,
    selectedTaxonomyNodes: getConsistentTaxonomyFilter(
      getAssetTaxonomiesForFilter(
        dataStore.assetTypes.defaultAssetType,
        dataStore.taxonomyGroups.byId,
      ).toArray(),
      queryFromStorage.selectedTaxonomyNodes,
      [uncategorizedAssetFilterTaxonomyTermId],
    ),
    selectedCollections: getConsistentCollectionFilter(
      Collection.getKeys(dataStore.collections.byId),
      queryFromStorage.selectedCollections,
      [null],
    ),
  };
};
