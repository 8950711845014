import { IConvertedContentState } from '../../clipboard/thunks/createConvertRichTextToClipboardHtml.ts';

export function copyContentToClipboard(content: IConvertedContentState) {
  // Various browsers have various hiccups when populating clipboard content, here is what we know so far
  // Chrome
  // - Supports clipboard API but filters out the object tags when used
  // - When using copy command on contenteditable, includes global document styles to the clipboard
  // Firefox
  // - Doesn't support clipboard API by default (can only be enabled in browser settings)
  // Safari
  // - Supports clipboard API, but only triggered synchronously from user event
  // https://stackoverflow.com/questions/62327358/javascript-clipboard-api-safari-ios-notallowederror-message

  // Try a combination of copy command with capturing the copy event of editable DIV
  const copySuccess = tryCopyWithClipboardEvent(content);

  // If the approach via capturing the copy event didn't succeed, we can still try clipboard API
  // This works in Safari, but has to be called synchronously
  if (!copySuccess && typeof ClipboardItem !== 'undefined') {
    tryCopyWithClipboardAPI(content);
  }
}

const tryCopyWithClipboardEvent = (content: IConvertedContentState): boolean => {
  const editableDiv = setupEditableDiv(content);

  let copySuccess = false;

  try {
    document.body.appendChild(editableDiv);

    const range = document.createRange();
    range.selectNodeContents(editableDiv);
    const selection = self.getSelection();
    if (!selection) {
      return false;
    }
    selection.removeAllRanges();
    selection.addRange(range);
    editableDiv.focus();

    // We populate the clipboard in the triggered event to get behavior consistent with standard copy action initiated by the user.
    // This works in Chrome and Firefox
    editableDiv.addEventListener(
      'copy',
      (e) => {
        const clipBoardData = e.clipboardData;
        if (clipBoardData) {
          clipBoardData.setData('text/plain', content.plainText);
          clipBoardData.setData('text/html', content.html);
          copySuccess = true;
          e.stopPropagation();
          e.preventDefault();
        }
      },
      true,
    );

    document.execCommand('copy');
  } finally {
    document.body.removeChild(editableDiv);
  }

  return copySuccess;
};

const setupEditableDiv = (content: IConvertedContentState): HTMLDivElement => {
  const editableDiv = document.createElement('div');
  editableDiv.contentEditable = 'true';
  editableDiv.style.position = 'fixed';
  editableDiv.style.opacity = '0';

  // We still populate the DIV with the HTML to make sure the clipboard contains the HTML in some way in case the copy event wouldn't fire
  // This works as the last resort, but it may spam the clipboard with unnecessary inline styles from global styles
  editableDiv.innerHTML = content.html;

  return editableDiv;
};

const tryCopyWithClipboardAPI = (content: IConvertedContentState): void => {
  try {
    navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([content.plainText], { type: 'text/plain' }),
        'text/html': new Blob([content.html], { type: 'text/html' }),
      }),
    ]);
  } catch {
    // In case even clipboard API is not available, the clipboard contains the content copied with execCommand,
    // which is just a bit spammed with inline styles, but we can live with it...
  }
};
