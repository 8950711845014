import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { memo } from 'react';
import {
  FeatureNameMap,
  RichTextFeature,
} from '../../../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';

interface IRichTextFormatStatusMessageProps {
  readonly disallowedLimitations: ReadonlySet<RichTextFeature>;
  readonly failedLimitations: ReadonlySet<RichTextFeature> | undefined;
  readonly isTableFormatting?: boolean;
}

const RichTextFormatLimitStatusMessage: React.FC<IRichTextFormatStatusMessageProps> = ({
  disallowedLimitations,
  failedLimitations,
  isTableFormatting,
}: IRichTextFormatStatusMessageProps): JSX.Element | null => {
  if (!disallowedLimitations.size) {
    return null;
  }

  return (
    <span className="content-item-element__status-segment">
      <span>{isTableFormatting ? 'Table text' : 'Text'} formatting not allowed: </span>
      {Collection.getValues(disallowedLimitations).map((limitation) => {
        if (!limitation) {
          return null;
        }

        const isInvalid = failedLimitations?.has(limitation);

        return (
          <span
            key={limitation}
            className={classNames('content-item-element__status-item', {
              'content-item-element__status-item--is-invalid': isInvalid,
            })}
          >
            {FeatureNameMap[limitation]}
          </span>
        );
      })}
    </span>
  );
};

RichTextFormatLimitStatusMessage.displayName = 'RichTextFormatLimitStatusMessage';

const RichTextFormatLimitStatusMessageMemo = memo(RichTextFormatLimitStatusMessage);
export { RichTextFormatLimitStatusMessageMemo as RichTextFormatLimitStatusMessage };
