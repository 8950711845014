import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusAlign,
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { UnreachableCaseException } from '@kontent-ai/errors';
import { usePrevious } from '@kontent-ai/hooks';
import { createGuid, identity } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { StatusMessageTypes } from '../../constants/statusMessageType.ts';
import { StatusMessage as StatusMessageType } from '../../models/StatusMessage.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IStatusMessageProps {
  readonly keepVisible?: boolean;
  readonly renderStatus?: (status: JSX.Element) => JSX.Element;
  readonly statusMessage: StatusMessageType;
}

interface IPreset {
  readonly description: string;
  readonly iconName: IconName;
}

const getStatusMessage = (messageType: StatusMessageTypes): IPreset | null => {
  switch (messageType) {
    case StatusMessageTypes.Offline:
      return {
        description:
          'Connection lost — to avoid losing your content, save a copy, and paste it back in once online.',
        iconName: IconName.ExclamationTriangle,
      };

    case StatusMessageTypes.ServerError:
      return {
        description: 'Unsaved changes — we’ve hit a snag',
        iconName: IconName.TimesCircle,
      };

    case StatusMessageTypes.Invalid:
      return {
        description: 'Unsaved changes — correct errors first',
        iconName: IconName.TimesCircle,
      };

    case StatusMessageTypes.Saving:
      return {
        description: 'Saving...',
        iconName: IconName.Spinner,
      };

    case StatusMessageTypes.Saved:
      return {
        description: 'Saved',
        iconName: IconName.CheckCircle,
      };

    case StatusMessageTypes.Deleting:
      return {
        description: 'Deleting...',
        iconName: IconName.Spinner,
      };

    case StatusMessageTypes.UploadingAsset:
      return {
        description: 'Uploading asset',
        iconName: IconName.Spinner,
      };

    case StatusMessageTypes.Unsaved:
      return null;

    default:
      throw UnreachableCaseException(messageType);
  }
};

interface ISimpleStatusProps {
  readonly messageType: StatusMessageTypes;
  readonly messageId: Uuid;
}

export const SimpleStatus: React.FC<ISimpleStatusProps> = ({ messageType, messageId }) => {
  switch (messageType) {
    case StatusMessageTypes.Offline:
      return (
        <SimpleStatusError
          label="Connection lost – Your recent changes weren’t saved. Refresh the page to load the latest saved version."
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneError)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.ServerError:
      return (
        <SimpleStatusError
          label="Unsaved changes — we’ve hit a snag"
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneError)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.Invalid:
      return (
        <SimpleStatusError
          label="Unsaved changes — correct errors first"
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneError)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.Saving:
      return (
        <SimpleStatusDefault
          label="Saving"
          icon={AnimatedProgressIcon}
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneInProgress)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.Saved:
      return (
        <SimpleStatusSuccess
          icon={Icons.CbCheckPreview}
          label="Saved"
          iconAlign={SimpleStatusAlign.Left}
          disabledFocus
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneSuccess)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.Deleting:
      return (
        <SimpleStatusDefault
          label="Deleting"
          icon={AnimatedProgressIcon}
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneInProgress)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.UploadingAsset:
      return (
        <SimpleStatusDefault
          label="Uploading asset"
          icon={AnimatedProgressIcon}
          simpleStatusStyle="inverse"
          {...getDataUiElementAttribute(DataUiElement.StatusPaneInProgress)}
          {...getDataUiObjectNameAttribute(messageId)}
        />
      );

    case StatusMessageTypes.Unsaved:
      return null;

    default:
      throw UnreachableCaseException(messageType);
  }
};

SimpleStatus.displayName = 'SimpleStatus';

export const StatusMessage: React.FC<IStatusMessageProps> = ({
  keepVisible,
  renderStatus = identity,
  statusMessage: { messageType },
}) => {
  const preset = getStatusMessage(messageType);
  const [visible, setVisible] = useState(!!preset && messageType !== StatusMessageTypes.Saved);

  // Every new message has its own guid to be able to detect the change in UI tests
  const [messageId, setMessageId] = useState(createGuid());

  const previousMessageType = usePrevious(messageType);
  useEffect(() => {
    if (previousMessageType !== messageType) {
      setVisible(messageType !== StatusMessageTypes.Unsaved);
      setMessageId(createGuid());
    }
  }, [previousMessageType, messageType]);

  const debouncedHideMessage = useDebouncedCallback(() => setVisible(false), 30_000);

  useEffect(() => {
    if (!keepVisible && messageType === StatusMessageTypes.Saved) {
      debouncedHideMessage();
    }
    return debouncedHideMessage.cancel;
  }, [keepVisible, messageType, debouncedHideMessage]);

  if (!visible) {
    return null;
  }

  return renderStatus(<SimpleStatus messageType={messageType} messageId={messageId} />);
};

StatusMessage.displayName = 'StatusMessage';
