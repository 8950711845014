import { Button } from '@kontent-ai/component-library/Button';
import { FC, ReactNode, RefObject } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { MainLayoutGrid } from './MainLayoutGrid.tsx';
import { StatusBarContainer } from './StatusBar/StatusBarContainer.tsx';

export interface IEditableAppBarOwnProps {
  readonly renderLeftContainer: () => ReactNode;
  readonly renderCentralContainer: (ref: RefObject<HTMLDivElement>) => ReactNode;
  readonly renderRightContainer: () => ReactNode;
}

export interface IEditableAppBarStateProps {
  readonly createButtonText?: string;
  readonly canCreateNewObject?: boolean;
  readonly createNewItemTooltip?: string;
  readonly isDisabled?: boolean;
}

export interface IEditableAppBarDispatchProps {
  readonly onCreate?: () => void;
}

type EditableAppBarProps = IEditableAppBarOwnProps &
  IEditableAppBarDispatchProps &
  IEditableAppBarStateProps;

export const EditableAppBar: FC<EditableAppBarProps> = ({
  canCreateNewObject,
  createButtonText,
  createNewItemTooltip,
  isDisabled,
  onCreate,
  renderCentralContainer,
  renderLeftContainer,
  renderRightContainer,
}) => {
  const createButton = onCreate && canCreateNewObject && (
    <Button
      tooltipText={createNewItemTooltip}
      tooltipPlacement="left"
      buttonStyle="secondary"
      onClick={onCreate}
      disabled={isDisabled}
      {...getDataUiActionAttribute(DataUiAction.CreateNew)}
    >
      {createButtonText || 'Create'}
    </Button>
  );

  return (
    <MainLayoutGrid.StatusBar>
      <StatusBarContainer
        renderLeftContainer={renderLeftContainer}
        renderCentralContainer={renderCentralContainer}
        renderRightContainer={() => (
          <>
            {renderRightContainer()}
            {createButton}
          </>
        )}
      />
    </MainLayoutGrid.StatusBar>
  );
};

EditableAppBar.displayName = 'EditableAppBar';
