import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { forwardRef } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly disabledTooltipText: string | null;
  readonly isActive: boolean;
  readonly isDisabled: boolean;
  readonly onClick?: () => void;
};

export const TaxonomyAiAssistantButton = forwardRef<HTMLButtonElement, Props>(
  ({ disabledTooltipText, isActive, isDisabled, onClick }, ref) => (
    <Button
      activated={isActive}
      buttonStyle="tertiary"
      disabled={isDisabled || !onClick}
      onClick={isActive ? undefined : onClick}
      ref={ref}
      size="small"
      tooltipPlacement="right"
      tooltipText={
        disabledTooltipText ?? 'Let AI suggest taxonomy terms based on this item’s textual content.'
      }
      type="button"
      {...getDataUiActionAttribute(DataUiAction.SuggestWithAi)}
    >
      <Button.Icon icon={Icons.KopilotAi} />
      <Button.Label>Suggest with AI</Button.Label>
    </Button>
  ),
);

TaxonomyAiAssistantButton.displayName = 'TaxonomyAiAssistantButton';
