import { WorkflowsCurrentUserCanCreateVariantInHint } from '../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import { WorkflowsCurrentUserCanMoveEditedVariantToHint } from '../selectors/workflows/getWorkflowsCurrentUserCanMoveEditedVariantTo.ts';

const rolePreventsYouFromWorkingWithTheFirstStepsOfOtherWorkflows =
  'Your role prevents you from working with the first steps of other workflows.';
const itemsOfThisTypeCanBeMovedOnlyToThisWorkflow =
  'Content items of this type can be moved only to this workflow.';
const itemsOfThisTypeCanGoOnlyThroughThisWorkflow =
  'Content items of this content type can go only through this workflow.';

export const getTooltipForWorkflowsCurrentUserCanMoveEditedVariantTo = (
  hint: WorkflowsCurrentUserCanMoveEditedVariantToHint,
): string | undefined => {
  switch (hint) {
    case WorkflowsCurrentUserCanMoveEditedVariantToHint.RoleCannotMoveVariantToAnyOtherWorkflows:
      return rolePreventsYouFromWorkingWithTheFirstStepsOfOtherWorkflows;
    case WorkflowsCurrentUserCanMoveEditedVariantToHint.VariantCanBeMovedToOnlyOneOtherWorkflow:
      return itemsOfThisTypeCanBeMovedOnlyToThisWorkflow;
    case WorkflowsCurrentUserCanMoveEditedVariantToHint.VariantCannotBeMovedToAnyOtherWorkflow:
      return itemsOfThisTypeCanGoOnlyThroughThisWorkflow;
    default:
      return undefined;
  }
};

export const getTooltipForWorkflowsSidebarSection = (
  hint: WorkflowsCurrentUserCanMoveEditedVariantToHint,
): string | undefined => {
  switch (hint) {
    case WorkflowsCurrentUserCanMoveEditedVariantToHint.RoleCannotMoveVariantToAnyOtherWorkflows:
      return rolePreventsYouFromWorkingWithTheFirstStepsOfOtherWorkflows;
    case WorkflowsCurrentUserCanMoveEditedVariantToHint.VariantCannotBeMovedToAnyOtherWorkflow:
      return itemsOfThisTypeCanGoOnlyThroughThisWorkflow;
    default:
      return undefined;
  }
};

export const getTooltipForWorkflowsCurrentUserCanCreateVariantIn = (
  hint: WorkflowsCurrentUserCanCreateVariantInHint,
): string | undefined => {
  switch (hint) {
    case WorkflowsCurrentUserCanCreateVariantInHint.RoleCannotCreateVariantInOtherWorkflows:
      return rolePreventsYouFromWorkingWithTheFirstStepsOfOtherWorkflows;
    case WorkflowsCurrentUserCanCreateVariantInHint.VariantCanBeCreatedOnlyInOneWorkflow:
      return itemsOfThisTypeCanGoOnlyThroughThisWorkflow;
    default:
      return undefined;
  }
};
