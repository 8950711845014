import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { Task } from '../../../../_shared/models/Task.ts';
import { IUserInfo } from '../../../../_shared/models/UserInfo.ts';
import { TableCellDueDate } from '../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ScrollTableItemContributors } from './ScrollTableItemContributors.tsx';

type ContentStatusTasksTableRowProps = Readonly<{
  assignees: ReadonlyArray<IUserInfo>;
  linkPath: string;
  task: Task;
}>;

export const ContentStatusTasksTableRow: React.FC<ContentStatusTasksTableRowProps> = ({
  assignees,
  linkPath,
  task,
}) => (
  <DataTableRow dataUiObjectName={task.id} id={task.id}>
    <LinkDataTableCell linkPath={linkPath}>{task.description}</LinkDataTableCell>
    <LinkDataTableCell linkPath={linkPath}>
      <TableCellDueDate dueDate={task.dueDate} />
    </LinkDataTableCell>
    <LinkDataTableCell linkPath={linkPath}>
      <ScrollTableItemContributors contributors={assignees} />
    </LinkDataTableCell>
  </DataTableRow>
);

ContentStatusTasksTableRow.displayName = 'ContentStatusTasksTableRow';
