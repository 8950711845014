import { ReactElement } from 'react';
import { EmptyObject, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { ProjectLocationSelector } from '../../containers/projects/ProjectLocationSelector.tsx';

type Props<TFormShape extends FieldValues> = {
  readonly destinationSubscriptionId: Uuid;
  readonly disabled?: boolean;
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, string>;
};

export const ProjectLocationSelectorWrapper = <TFormShape extends FieldValues = EmptyObject>({
  destinationSubscriptionId,
  disabled,
  formProps,
  name,
}: Props<TFormShape>): ReactElement => {
  const { field } = useController<TFormShape>({
    control: formProps.control,
    name,
  });

  return (
    <LabelFor
      target={(id: Uuid) => (
        <ProjectLocationSelector
          ref={field.ref}
          destinationSubscriptionId={destinationSubscriptionId}
          disabled={disabled}
          dropdownId={id}
          onSelectionChanged={field.onChange}
          selectedProjectLocationId={field.value as string}
        />
      )}
    >
      Project data center
    </LabelFor>
  );
};
