import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { activateContentItemEditingAction } from '../../../../actions/contentItemEditingActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { EditingAction } from '../../../../models/EditingAction.ts';

export const SetupPreviewAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      performAction={() =>
        dispatch(activateContentItemEditingAction(EditingAction.setupItemPreview))
      }
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
