import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { SubscriptionUserDetailProjectFilter } from '../../containers/UserDetail/SubscriptionUserDetailProjectFilter.tsx';
import { SubscriptionUserDetailSearchPhraseFilter } from '../../containers/UserDetail/SubscriptionUserDetailSearchPhraseFilter.tsx';

type UserDetailFilterProps = {
  readonly userId: Uuid;
};

export const UserDetailFilter: React.FC<UserDetailFilterProps> = ({ userId }) => (
  <Row spacing={Spacing.S}>
    <Column width="1/4">
      <SubscriptionUserDetailProjectFilter userId={userId} />
    </Column>
    <Column width="3/4">
      <SubscriptionUserDetailSearchPhraseFilter />
    </Column>
  </Row>
);

UserDetailFilter.displayName = 'UserDetailFilter';
