import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../_shared/components/NotificationBar.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { closeConvertContentComponentToItemNotificationBar } from '../actions/contentItemEditingActions.ts';

export const ContentComponentConvertedToItemNotificationBar = () => {
  const dispatch = useDispatch();

  const itemId = useSelector((s) => s.contentApp.editedContentItem?.id);
  const message = useSelector((s) =>
    itemId && s.contentApp.editorUi.convertedItemIds.contains(itemId)
      ? 'Now you can reuse your content. Don’t forget to name the item!'
      : null,
  );

  return itemId ? (
    <NotificationBar
      type={NotificationBarType.InfoLight}
      message={message}
      uiElement={DataUiElement.ContentComponentConvertedToItemInfo}
      onClosePanel={() => dispatch(closeConvertContentComponentToItemNotificationBar(itemId))}
    />
  ) : null;
};
