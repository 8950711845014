export const Spaces_Create_Started = 'Spaces_Create_Started';
export const Spaces_Create_Succeeded = 'Spaces_Create_Succeeded';
export const Spaces_Create_Failed = 'Spaces_Create_Failed';

export const Spaces_Update_Started = 'Spaces_Update_Started';
export const Spaces_Update_Succeeded = 'Spaces_Update_Succeeded';
export const Spaces_Update_Failed = 'Spaces_Update_Failed';

export const Spaces_Delete_Started = 'Spaces_Delete_Started';
export const Spaces_Delete_Succeeded = 'Spaces_Delete_Succeeded';
export const Spaces_Delete_Failed = 'Spaces_Delete_Failed';

export const Spaces_Restore_Started = 'Spaces_Restore_Started';
export const Spaces_Restore_Succeeded = 'Spaces_Restore_Succeeded';
export const Spaces_Restore_Failed = 'Spaces_Restore_Failed';

export const Spaces_Listing_Left = 'Spaces_Listing_Left';
