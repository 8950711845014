import { useContext } from 'react';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { EnsureInventoryRelatedData } from '../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { CategoryItemsListingModal } from '../containers/CategoryItemsListingModal.tsx';
import { ProjectOverviewPageContextProvider } from '../containers/ProjectOverviewPageContext.tsx';
import {
  ProjectOverviewPageContent,
  ProjectOverviewPageType,
} from './ProjectOverviewPageContent.tsx';
import { ProjectOverviewPageContext } from './ProjectOverviewPageContext.tsx';

const ProjectOverviewModal = () => {
  const { isVisible, dismissCategory, viewedCategory, listingSelectedWorkflowStep } = useContext(
    ProjectOverviewPageContext,
  );

  return (
    <CategoryItemsListingModal
      isOpen={isVisible}
      listingSelectedWorkflowStep={listingSelectedWorkflowStep}
      onCancel={dismissCategory}
      viewedCategory={viewedCategory}
    />
  );
};

type Props = {
  readonly listingItemsLoadingStatus: LoadingStatus;
  readonly onClearFilter: () => void;
  readonly onFilterChange: () => void;
  readonly onOpenCreateNewItemDialog: () => void;
  readonly projectOverviewPageType: ProjectOverviewPageType;
};

export const ProjectOverviewPage = (props: Props) => {
  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.ProjectOverview)}>
      <div className="canvas__workspace">
        <HtmlPageTitle appName={AppNames.ProjectOverview} />
        <EnsureInventoryRelatedData>
          <ContentItemFilter
            clearFilter={props.onClearFilter}
            listingItemsLoadingStatus={props.listingItemsLoadingStatus}
            onFilterChange={props.onFilterChange}
            origin={ContentItemFilterOrigin.ProjectOverview}
          />
          <ProjectOverviewPageContextProvider>
            <div className="canvas__content">
              <div className="canvas__notifications">
                <NotificationBar />
              </div>
              <div className="canvas__content-pane">
                <ProjectOverviewPageContent
                  onOpenCreateNewItemDialog={props.onOpenCreateNewItemDialog}
                  projectOverviewPageContentType={props.projectOverviewPageType}
                />
              </div>
            </div>
            <ProjectOverviewModal />
          </ProjectOverviewPageContextProvider>
        </EnsureInventoryRelatedData>
      </div>
    </div>
  );
};
