import Immutable from 'immutable';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export const getAvailableContentTypesForWebSpotlightRootItem = (
  state: IStore,
): Immutable.List<IContentType> => {
  const rootTypeId = state.webSpotlightApp.configuration?.rootTypeId;
  const rootType = rootTypeId && state.data.contentTypes.byId.get(rootTypeId);

  return rootType ? Immutable.List.of<IContentType>(rootType) : Immutable.List();
};
