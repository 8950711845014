import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  FontSize,
  LineHeight,
  Spacing,
  colorWarningIcon,
  px,
} from '@kontent-ai/component-library/tokens';
import { Placement } from '@kontent-ai/component-library/types';
import React from 'react';
import { IconName } from '../../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../../../_shared/uiComponents/Icon/Icon.tsx';

interface IUnfinishedStatusIconProps {
  readonly tooltipPlacement?: Placement;
}

export const UnfinishedStatusIcon: React.FC<IUnfinishedStatusIconProps> = ({
  tooltipPlacement = 'right',
}) => (
  <Tooltip tooltipText="Unfinished elements" placement={tooltipPlacement}>
    <Box
      alignItems="center"
      color={colorWarningIcon}
      display="flex"
      marginRight={Spacing.S}
      css={`
        font-size: ${px(FontSize.S)};
        line-height: ${px(LineHeight.S)};
      `}
    >
      <Icon iconName={IconName.SmallDotCentered} screenReaderText="Unfinished elements" />
    </Box>
  </Tooltip>
);

UnfinishedStatusIcon.displayName = 'UnfinishedStatusIcon';
