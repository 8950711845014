import { Input } from '@kontent-ai/component-library/Input';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';

import { Box } from '@kontent-ai/component-library/Box';
import { useController } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { MissionControlFeedbackFormShape } from './FeedbackForm.tsx';
import { FeedbackInputLabel } from './FeedbackInputLabel.tsx';

type FeedbackCommentProps = Readonly<{
  formProps: HookFormProps<MissionControlFeedbackFormShape>;
}>;

export const FeedbackComment: React.FC<FeedbackCommentProps> = ({ formProps: { control } }) => {
  const { field } = useController({ control, name: 'comment' });

  return (
    <Box>
      <FeedbackInputLabel isOptional marginBottom={Spacing.L}>
        Do you have any additional feedback?
      </FeedbackInputLabel>
      <Input
        ref={field.ref}
        onChange={field.onChange}
        value={field.value}
        placeholder="I’m rating this way because..."
      />
    </Box>
  );
};
