import { CannotViewItemMessage } from '../../../applications/contentInventory/content/constants/cannotViewMessages.ts';
import { ActiveCapabilityType } from '../../models/activeCapability.type.ts';
import {
  HasVariantActiveCapabilityParams,
  hasActiveVariantCapability,
} from './activeCapabilities.ts';

export const getCannotViewItemMessage = (
  params: HasVariantActiveCapabilityParams,
): string | undefined =>
  hasActiveVariantCapability(ActiveCapabilityType.ViewContent, params)
    ? undefined
    : CannotViewItemMessage;
