import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IProjectsEmptyStateProps {
  readonly hadTrial: boolean;
  readonly onCreateFreeTrialClick: () => void;
  readonly onRedirectToSubscriptions: () => void;
  readonly showActiveProjectsEmptyState: boolean;
  readonly showArchivedProjectsEmptyState: boolean;
}

const activeProjectsTitleText = 'You have the opportunity to start from scratch.';

export const ProjectsEmptyState: React.FC<IProjectsEmptyStateProps> = ({
  showActiveProjectsEmptyState,
  onCreateFreeTrialClick,
  showArchivedProjectsEmptyState,
  onRedirectToSubscriptions,
  hadTrial,
}) => (
  <>
    {showActiveProjectsEmptyState &&
      (hadTrial ? (
        <EmptyState>
          <EmptyState.Title>{activeProjectsTitleText}</EmptyState.Title>
          <EmptyState.Content>You have no active subscriptions.</EmptyState.Content>
          <EmptyState.Footer>
            <Button
              buttonStyle="primary"
              onClick={onRedirectToSubscriptions}
              {...getDataUiActionAttribute(DataUiAction.CreateSubscription)}
            >
              Create subscription
            </Button>
          </EmptyState.Footer>
        </EmptyState>
      ) : (
        <EmptyState>
          <EmptyState.Title>{activeProjectsTitleText}</EmptyState.Title>
          <EmptyState.Content>
            If you’d like to create projects, start a free trial subscription and try it out. No
            credit card required.
          </EmptyState.Content>
          <EmptyState.Footer>
            <Button
              buttonStyle="primary"
              onClick={onCreateFreeTrialClick}
              {...getDataUiActionAttribute(DataUiAction.StartTrial)}
            >
              Start my free 30-day trial
            </Button>
          </EmptyState.Footer>
        </EmptyState>
      ))}
    {showArchivedProjectsEmptyState && (
      <EmptyState>
        <EmptyState.Title>There are no archived projects.</EmptyState.Title>
        <EmptyState.Content>Projects that you archive will appear here.</EmptyState.Content>
      </EmptyState>
    )}
  </>
);

ProjectsEmptyState.displayName = 'ProjectsEmptyState';
