import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { redirectToSharedPreview } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { SharedWebSpotlightPreviewParams } from '../constants/routePaths.ts';

enum SharedPreviewSmartLinkQueryParam {
  URL = 'url',
  Width = 'width',
  Height = 'height',
}

export const WebSpotlightSharedPreviewSmartLink = () => {
  const {
    itemGuid: itemId,
    variantGuid: variantId,
    spaceId,
    projectId,
  } = useParams<SharedWebSpotlightPreviewParams>();
  const { search } = useLocation();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      itemId,
      variantId,
      spaceId,
      projectId,
      url: searchParams.get(SharedPreviewSmartLinkQueryParam.URL),
      width:
        Number.parseInt(searchParams.get(SharedPreviewSmartLinkQueryParam.Width) || '', 10) || null,
      height:
        Number.parseInt(searchParams.get(SharedPreviewSmartLinkQueryParam.Height) || '', 10) ||
        null,
    };
  }, [itemId, variantId, spaceId, projectId, search]);

  useThunkPromise(redirectToSharedPreview, params);

  return <SmartLink />;
};
