import { ReactNode } from 'react';
import { tryEnsuringSelectedLanguageId } from '../actions/thunkSharedActions.ts';
import { Loader } from '../components/Loader.tsx';
import { useSelector } from '../hooks/useSelector.ts';
import { useThunkPromise } from '../hooks/useThunkPromise.ts';
import { isSelectedLanguageIdInitialized } from '../selectors/getSelectedLanguageId.ts';

type Props = {
  readonly children: ReactNode;
};

export const TryEnsuringSelectedVariantId = ({ children }: Props) => {
  const isLanguageInitialized = useSelector(isSelectedLanguageIdInitialized);

  useThunkPromise(tryEnsuringSelectedLanguageId, {
    canRun: !isLanguageInitialized,
  });

  return isLanguageInitialized ? <>{children}</> : <Loader />;
};
