import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  AssetLibrary_Query_Changed,
  AssetLibrary_Query_FilterCleared,
  AssetLibrary_Query_Loaded,
} from '../../constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import { IAssetLibraryQuery, emptyAssetLibraryQuery } from '../../models/IAssetLibraryQuery.ts';

const initialState = emptyAssetLibraryQuery;

export function query(
  state: IAssetLibraryQuery = initialState,
  action: Action,
): IAssetLibraryQuery {
  switch (action.type) {
    case AssetLibrary_Query_Loaded:
    case AssetSelector_Opened:
      return action.payload.query;

    case Shared_CurrentProjectId_Updated:
      return initialState;

    case AssetLibrary_Query_Changed:
      return {
        ...state,
        ...action.payload.partialQuery,
      };

    case AssetLibrary_Query_FilterCleared:
      return {
        ...initialState,
        searchPhrase: state.searchPhrase,
        mimeType: state.mimeType,
      };

    default:
      return state;
  }
}
