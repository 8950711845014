import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject, useCallback, useContext, useRef, useState } from 'react';
import {
  useLayoutSynchronizationReadTick,
  useLayoutSynchronizationUpdateTick,
} from '../../../../_shared/contexts/LayoutSynchronizationContext.tsx';
import { ContentNestingContext } from '../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';

const caretWidthPx = 1;

export interface IEditorSizeHandlerProps {
  readonly contentOverlayClassName?: string;
  readonly editorRef: RefObject<HTMLElement>;
  readonly onSizeChanged?: () => void;
}

export const EditorSizeHandler: React.FC<IEditorSizeHandlerProps> = ({
  contentOverlayClassName,
  editorRef,
  onSizeChanged,
}) => {
  const { isTopLevel } = useContext(ContentNestingContext);

  const [contentOverlayWidth, setContentOverlayWidth] = useState<number | null>(null);
  const pendingContentOverlayWidth = useRef<number | null>(null);

  const width = useRef<number | null>(null);
  const height = useRef<number | null>(null);

  const readLayout = useCallback(() => {
    const newWidth = editorRef.current?.offsetWidth ?? null;
    const newHeight = editorRef.current?.offsetHeight ?? null;

    if (width.current !== newWidth || height.current !== newHeight) {
      if (width.current && height.current) {
        onSizeChanged?.();
      }
      width.current = newWidth;
      height.current = newHeight;

      if (contentOverlayClassName) {
        if (newWidth) {
          pendingContentOverlayWidth.current = isTopLevel
            ? newWidth - caretWidthPx
            : // Linked items and content components in deeper levels reach to the edge of the editor to preserve the space
              // this corresponds to the respective styles for .content-component
              newWidth + Spacing.XXL - caretWidthPx;
        }
      }
    }
  }, [contentOverlayClassName, editorRef, onSizeChanged, isTopLevel]);

  const updateLayout = useCallback(() => {
    if (pendingContentOverlayWidth.current) {
      setContentOverlayWidth(pendingContentOverlayWidth.current);
      pendingContentOverlayWidth.current = null;
    }
  }, []);

  useLayoutSynchronizationReadTick(readLayout);
  useLayoutSynchronizationUpdateTick(updateLayout);

  const inlineStyleString = contentOverlayWidth
    ? `.${contentOverlayClassName} { width: ${contentOverlayWidth}px; }`
    : null;

  return inlineStyleString ? (
    <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />
  ) : null;
};
EditorSizeHandler.displayName = 'EditorSizeHandler';
