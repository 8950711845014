import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentTypeSnippet_Editor_InitStarted,
  ContentTypeSnippet_Editor_TypeElementCreated,
} from '../../constants/snippetActionTypes.ts';

export function lastAddedElementId(
  state: Uuid | undefined | null = null,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case ContentTypeSnippet_Editor_TypeElementCreated: {
      return action.payload.typeElementData.elementId;
    }

    case ContentTypeSnippet_Editor_InitStarted: {
      return null;
    }

    default:
      return state;
  }
}
