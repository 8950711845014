import { useMenuSection } from '@react-aria/menu';
import { mergeProps } from '@react-aria/utils';
import { PropsWithChildren } from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { colorTextDefault } from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { MenuDivider } from './MenuDivider.tsx';

type MenuSectionProps = Readonly<{
  label: string;
  isFirst?: boolean;
}>;

export const MenuSection = ({
  children,
  label,
  isFirst,
  ...otherProps
}: PropsWithChildren<MenuSectionProps>) => {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: label,
    'aria-label': label,
  });
  return (
    <>
      {!isFirst && <MenuDivider />}
      <Box paddingX={Spacing.L} paddingY={Spacing.S} {...mergeProps(itemProps, otherProps)}>
        <Box
          component="span"
          color={colorTextDefault}
          typography={Typography.HeadlineMedium}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          {...headingProps}
          {...getDataUiComponentAttribute(MenuSection)}
        >
          {label}
        </Box>
      </Box>
      <Box {...groupProps}>{children}</Box>
    </>
  );
};
