import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import { ICommentThreadServerModel } from '../../../../../../repositories/serverModels/CommentThreadServerModel.type.ts';
import {
  createCommentContentServerModel,
  isCommentContent,
} from '../../../../models/comments/Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import { isSuggestionContent } from '../../../../models/comments/Suggestion.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import {
  ContentItemEditing_CommentThread_UpdateItemFinished,
  ContentItemEditing_CommentThread_UpdateItemStarted,
} from '../../constants/contentItemEditingActionTypes.ts';

const updateCommentThreadItemFinished = (payload: {
  threadId: Uuid;
  updatedThread: ICommentThread;
}) =>
  ({
    type: ContentItemEditing_CommentThread_UpdateItemFinished,
    payload,
  }) as const;

const updateCommentThreadItemStarted = (payload: {
  threadId: Uuid;
  commentId: Uuid;
}) =>
  ({
    type: ContentItemEditing_CommentThread_UpdateItemStarted,
    payload,
  }) as const;

export type UpdateCommentThreadItemActionsType = ReturnType<
  typeof updateCommentThreadItemFinished | typeof updateCommentThreadItemStarted
>;

interface IUpdateCommentThreadItemDependencies {
  readonly commentRepository: ICommentRepository;
}

const updateThreadItem = (
  commentRepository: ICommentRepository,
  editedContentItemVariant: EditedContentItemVariant,
  threadId: Uuid,
  commentThreadItemId: Uuid,
  content: ICommentThreadItemContentModel,
): Promise<ICommentThreadServerModel> => {
  const { itemId, variantId } = editedContentItemVariant.id;

  if (isCommentContent(content)) {
    return commentRepository.editComment(itemId, variantId, threadId, commentThreadItemId, {
      type: CommentThreadItemType.Comment,
      content: createCommentContentServerModel(content.content),
    });
  }
  if (isSuggestionContent(content)) {
    return commentRepository.editSuggestion(itemId, variantId, threadId, commentThreadItemId, {
      type: CommentThreadItemType.Suggestion,
      suggestedText: content.suggestedText,
    });
  }

  throw InvariantException('Content is neither comment nor suggestion.');
};

export const createUpdateCommentThreadItemAction =
  (deps: IUpdateCommentThreadItemDependencies) =>
  (
    threadId: Uuid,
    commentThreadItemId: Uuid,
    content: ICommentThreadItemContentModel,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { editedContentItemVariant, editedContentItemVariantComments } = getState().contentApp;
    const thread = editedContentItemVariantComments.commentThreads.find(
      (t: ICommentThread) => t.id === threadId,
    );

    const isContentValid =
      (isCommentContent(content) && content.content.hasText()) ||
      (isSuggestionContent(content) && !!content.suggestedText);

    if (!thread || !isContentValid || !editedContentItemVariant) {
      return;
    }

    const threadItem = thread.threadItems.find(
      (item: ICommentThreadItem) => item.id === commentThreadItemId,
    );
    if (!threadItem) {
      return;
    }

    dispatch(
      updateCommentThreadItemStarted({
        threadId,
        commentId: commentThreadItemId,
      }),
    );

    const updatedThreadServerModel = await updateThreadItem(
      deps.commentRepository,
      editedContentItemVariant,
      threadId,
      commentThreadItemId,
      content,
    );

    const updatedThread = createCommentThreadDomainModel(updatedThreadServerModel);

    dispatch(
      updateCommentThreadItemFinished({
        threadId,
        updatedThread,
      }),
    );
  };
