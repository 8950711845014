import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ListingFilter_UpdateSavedFilter_Failed,
  ListingFilter_UpdateSavedFilter_Finished,
  ListingFilter_UpdateSavedFilter_Started,
} from '../../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialState: ReadonlySet<Uuid> = new Set<Uuid>();

export function filtersBeingUpdated(state = initialState, action: Action) {
  switch (action.type) {
    case ListingFilter_UpdateSavedFilter_Started: {
      return Collection.add(state, action.payload.filterId);
    }

    case ListingFilter_UpdateSavedFilter_Finished: {
      return Collection.remove(state, action.payload.filter.id);
    }

    case ListingFilter_UpdateSavedFilter_Failed: {
      return Collection.remove(state, action.payload.filterId);
    }

    default:
      return state;
  }
}
