import { ICustomItemElement } from '../../models/contentItemElements/CustomItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export function areCustomItemElementsTheSame(
  itemElement1: ICustomItemElement,
  itemElement2: ICustomItemElement,
): boolean {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }

  const valuesAreTheSame = itemElement1.value === itemElement2.value;
  const searchableValuesAreTheSame = itemElement1.searchableValue === itemElement2.searchableValue;

  return searchableValuesAreTheSame && valuesAreTheSame;
}
