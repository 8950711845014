import { assert } from '@kontent-ai/utils';
import React from 'react';
import { ICompiledContentItemElementData } from '../../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  AssetElementType,
  CompiledTypeElementType,
  ElementType,
  isAssetElementType,
} from '../../../../content/models/ContentItemElementType.ts';
import { TypeElement } from '../../../../content/models/contentTypeElements/TypeElement.type.ts';
import { IAssetElementProps } from './AssetElementProps.type.ts';
import { TaxonomyAssetElement } from './TaxonomyAssetElement.tsx';

type AssetElement = React.ComponentType<
  React.PropsWithChildren<IAssetElementProps<ICompiledContentItemElementData, TypeElement>>
>;

const componentMap: ReadonlyRecord<AssetElementType, AssetElement> = {
  [ElementType.Taxonomy]: TaxonomyAssetElement,
};

export const getAssetElementEditComponent = (type: CompiledTypeElementType): AssetElement => {
  const component = isAssetElementType(type) ? componentMap[type] : null;
  assert(component, () => `Cannot find asset editing component for element type '${type}'`);
  return component;
};
