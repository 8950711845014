import { useHover } from '@react-aria/interactions';
import { forwardRef } from 'react';
import { MenuItem, MenuItemProps } from './MenuItem.tsx';

const emptyObject = {};

interface MenuItemWithMouseHoverProps extends Omit<MenuItemProps, 'isHovered'> {}

export const MenuItemWithMouseHover = forwardRef<HTMLDivElement, MenuItemWithMouseHoverProps>(
  (props, forwardedRef) => {
    const { hoverProps, isHovered } = useHover(emptyObject);

    return <MenuItem ref={forwardedRef} {...props} isHovered={isHovered} {...hoverProps} />;
  },
);

MenuItemWithMouseHover.displayName = 'MenuItemWithMouseHover';
