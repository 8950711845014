import { assert } from '@kontent-ai/utils';
import React, { RefObject } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { newContentItemSelectedCollectionChanged } from '../actions/newContentItemActions.ts';
import { validateSelectedValues } from '../actions/thunkNewContentItemActions.ts';
import { CollectionSelector } from '../components/CollectionSelector.tsx';
import { CollectionSection } from '../constants/collectionSection.ts';
import { disabledCollectionSelectorTooltip } from '../constants/uiConstants.ts';
import { getCollectionOptions } from '../selectors/getCollectionOptions.ts';
import { getNewContentItemForm } from '../selectors/newContentItemDialog.ts';

type Props = {
  readonly disabledTooltip: string | undefined;
  readonly index: number;
  readonly inputRef?: RefObject<HTMLInputElement>;
};

const CollectionSelectorContainer: React.FC<Props> = ({ disabledTooltip, index, inputRef }) => {
  const selectedCollectionSection = useSelector((s) => getNewContentItemForm(s).collectionSection);
  const collectionOptions = useSelector(getCollectionOptions);
  const onlyOneCollectionAvailable =
    collectionOptions.find((options) => options.id === CollectionSection.AllCollections)?.items
      .length === 1;
  const disabledTooltipText =
    disabledTooltip || (onlyOneCollectionAvailable ? disabledCollectionSelectorTooltip : undefined);
  const isSelectedValueShown = !disabledTooltipText || onlyOneCollectionAvailable;
  const selectedCollectionId = useSelector((s) =>
    isSelectedValueShown ? getNewContentItemForm(s).collectionId : null,
  );

  const dispatch = useDispatch();
  const onCollectionSelected = (collectionId: Uuid) => {
    const section = collectionOptions.find((s) =>
      s.items.some((item) => item.id.endsWith(collectionId)),
    );
    assert(!!section, () => `${__filename}: Section with collection id ${collectionId} not found`);
    dispatch(
      newContentItemSelectedCollectionChanged(collectionId, section.id as CollectionSection),
    );
    dispatch(validateSelectedValues());
  };

  return (
    <CollectionSelector
      collectionOptions={collectionOptions}
      disabledTooltip={disabledTooltip}
      index={index}
      onCollectionSelected={onCollectionSelected}
      selectedCollectionId={selectedCollectionId}
      selectedCollectionSection={selectedCollectionSection}
      inputRef={inputRef}
    />
  );
};

CollectionSelectorContainer.displayName = 'CollectionSelectorContainer';

export { CollectionSelectorContainer as CollectionSelector };
