import { BaseColor } from '@kontent-ai/component-library/tokens';
import { ComponentProps } from 'react';
import { DefaultTag } from '../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../component-library/components/Tag/Tag.tsx';

export const renderFilterOption =
  (anyId: string) =>
  (id: string, _object: object, defaultTagProps: ComponentProps<typeof DefaultTag>) =>
    id === anyId ? (
      <Tag {...defaultTagProps} background={BaseColor.Gray30} />
    ) : (
      <DefaultTag {...defaultTagProps} />
    );
