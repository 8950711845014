import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getSelectedLanguageId,
  isSelectedLanguageIdEnsured,
} from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getListingLanguageSwitcherOptionsCreator } from '../../../../_shared/utils/languageSwitchers/getListingLanguageSwitcherOptions.ts';
import { getPathWithReplacedVariantIdForRelationsRoute } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentListingLanguageSwitcher } from '../../content/features/ContentItemInventory/components/ContentListingLanguageSwitcher/ContentListingLanguageSwitcher.tsx';

const getRelationsLanguageSwitcherOptions = getListingLanguageSwitcherOptionsCreator(
  getPathWithReplacedVariantIdForRelationsRoute,
);

export const RelationsLanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const isSelectedLanguageEnsured = useSelector(isSelectedLanguageIdEnsured);
  const languageOptions = useSelector((store) =>
    getRelationsLanguageSwitcherOptions(
      getCurrentProjectId(store),
      store.data.user,
      store.data.languages,
      pathname,
    ),
  );

  return (
    <ContentListingLanguageSwitcher
      languages={languageOptions}
      selectedLanguageId={selectedLanguageId}
      isSelectedLanguageEnsured={isSelectedLanguageEnsured}
    />
  );
};
