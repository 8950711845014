import { Collection, notNullNorUndefined, notUndefined } from '@kontent-ai/utils';
import { ISpace, SpacesMap } from '../../../../data/models/space/space.ts';
import {
  IPreviewConfiguration,
  ISpaceDomain,
} from '../../../previewConfiguration/models/PreviewConfiguration.type.ts';

export type LimitedSpaceInfo = Pick<ISpace, 'codeName' | 'name' | 'id'>;

export type SpaceNamesMetadata = {
  readonly allSpacesUsed: boolean;
  readonly usedSpaces: readonly LimitedSpaceInfo[];
};

export const EmptySpaceNamesMetadata: SpaceNamesMetadata = {
  allSpacesUsed: false,
  usedSpaces: [],
};

export const defaultAllSpacesPlaceholder = 'All spaces';

export const spacesByCollectionId = (spaces: SpacesMap, collectionId: Uuid): readonly ISpace[] =>
  [...spaces.values()].filter((space) => space.collectionIds.includes(collectionId));

export const spacesByCollectionIds = (
  spaces: SpacesMap,
  collectionIds: readonly Uuid[],
): ReadonlyMap<Uuid, readonly ISpace[]> =>
  new Map(
    collectionIds.map((collectionId) => [collectionId, spacesByCollectionId(spaces, collectionId)]),
  );

export const spacesContainCollection = (
  spaces: ReadonlyArray<ISpace>,
  collectionIds: ReadonlyArray<Uuid>,
): ReadonlyArray<ISpace> => {
  const indexedCollectionIds = new Set(collectionIds);

  return spaces.filter((space) =>
    space.collectionIds.some((collectionId) => indexedCollectionIds.has(collectionId)),
  );
};

export const anyCollectionInMultipleSpaces = (
  spaces: SpacesMap,
  collectionIds: readonly Uuid[],
): boolean =>
  collectionIds.some((collectionId) => spacesByCollectionId(spaces, collectionId).length > 1);

export const allSpacesIncluded = (spaces: SpacesMap, usedSpaceIds: readonly Uuid[]): boolean => {
  const providedSpaceIds = new Set(usedSpaceIds);
  if (spaces.size !== providedSpaceIds.size) {
    return false;
  }

  const spaceIds = [...spaces.keys()];
  return spaceIds.length === Collection.intersect(spaceIds, providedSpaceIds).length;
};

export const spaceNames = (
  spaces: SpacesMap,
  usedSpaceIds: readonly Uuid[],
): SpaceNamesMetadata => {
  const usedSpaces = usedSpaceIds.map((spaceId) => spaces.get(spaceId)).filter(notNullNorUndefined);

  if (usedSpaces.length === 0) {
    return EmptySpaceNamesMetadata;
  }
  const allSpacesUsed = allSpacesIncluded(spaces, usedSpaceIds);

  return {
    allSpacesUsed,
    usedSpaces,
  };
};

export const shouldCollapseSpaces = (spaceData: SpaceNamesMetadata) =>
  spaceData.allSpacesUsed && spaceData.usedSpaces.length > 1;

export const getCollapsedSpaceNames = (
  spaceData: SpaceNamesMetadata,
  allSpacesTextPlaceholder: string = defaultAllSpacesPlaceholder,
) => {
  return shouldCollapseSpaces(spaceData)
    ? [allSpacesTextPlaceholder]
    : spaceData.usedSpaces.map(({ name }) => name);
};

export const getUniqueCollectionIdsFromSpaceIds = (
  spacesById: SpacesMap,
  spaceIds: ReadonlyArray<Uuid> | ReadonlySet<Uuid>,
): ReadonlySet<Uuid> => {
  const normalizedSpaceIds = Array.isArray(spaceIds)
    ? (spaceIds as ReadonlyArray<Uuid>)
    : Collection.getValues(spaceIds as ReadonlySet<Uuid>);

  const collections = normalizedSpaceIds
    .map((spaceId) => spacesById.get(spaceId))
    .filter(notUndefined)
    .flatMap((space) => space.collectionIds);

  return new Set(collections);
};
export const getDefaultSpaceDomain = (
  previewConfiguration: IPreviewConfiguration,
): ISpaceDomain | null =>
  Collection.getFirst(
    previewConfiguration.spaceDomains.filter((spaceDomain) => !!spaceDomain.domain),
  );
