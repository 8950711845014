import {
  ProvideRegexWithValidLengthLimit,
  ProvideValidRegexPattern,
  ProvideValidationMessageWithValidLengthLimit,
} from '../../../constants/errorMessageTemplates.ts';
import { ValidationRegex } from '../../../models/elements/types/TypeElementData.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { TypeElementRegexValidationResult } from '../../../utils/typeElementValidators/types/TypeElementRegexValidationResult.type.ts';

export function getTypeElementRegexValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  validationRegex: ValidationRegex | null,
): TypeElementRegexValidationResult {
  const { isRegexPatternValid, isRegexLengthValid, isRegexValidationMessageLengthValid } =
    typeElementValidationUtils;

  const isValidationMessageLengthValid =
    validationRegex === null ||
    isRegexValidationMessageLengthValid(validationRegex.validationMessage);
  const isValidationRegexLengthValid =
    validationRegex === null || isRegexLengthValid(validationRegex.regex);
  const isValidationRegexValid =
    validationRegex === null || isRegexPatternValid(validationRegex.regex);
  const errors = [];

  if (!isValidationRegexLengthValid) {
    errors.push(ProvideRegexWithValidLengthLimit);
  }

  if (!isValidationRegexValid) {
    errors.push(ProvideValidRegexPattern);
  }

  if (!isValidationMessageLengthValid) {
    errors.push(ProvideValidationMessageWithValidLengthLimit);
  }

  return {
    isRegexPatternValid: isValidationRegexValid,
    isRegexLengthValid: isValidationRegexLengthValid,
    isRegexValidationMessageLengthValid: isValidationMessageLengthValid,
    errorMessages: errors,
  };
}
