import { InvariantException } from '@kontent-ai/errors';
import { IPlanFupMetricServerModel } from '../../../repositories/serverModels/PlanServerModel.type.ts';

export interface IFupMetric {
  readonly name: string;
  readonly includedUnits: number;
  readonly includedMultiplierMetricName: string;
}

export function createFupMetric(props?: Partial<IFupMetric>): IFupMetric {
  return {
    name: '',
    includedUnits: 0,
    includedMultiplierMetricName: '',
    ...props,
  };
}

export function createFupMetricFromServerModel(serverModel: IPlanFupMetricServerModel): IFupMetric {
  if (typeof serverModel !== 'object') {
    throw InvariantException('Fup metric is not an object');
  }

  const emptyObject = createFupMetric();

  return {
    name: serverModel.name ?? emptyObject.name,
    includedUnits: serverModel.includedUnits ?? emptyObject.includedUnits,
    includedMultiplierMetricName:
      serverModel.includedMultiplierMetricName ?? emptyObject.includedMultiplierMetricName,
  };
}
