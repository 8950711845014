import { NumberTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  INumberTypeElementData,
  getNumberTypeElementDataFromServerModel,
} from '../../models/elements/NumberTypeElementData.ts';
import { hasNumberDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';

function convertTypeElementRecordToServerModel(
  typeElement: INumberTypeElementData,
): NumberTypeElementServerModel {
  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    default: hasNumberDefaultValue(typeElement)
      ? { global: { value: +typeElement.defaultValue.value } }
      : null,
    type: ElementType.Number,
  };
}

export function createNumberTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: NumberTypeElementServerModel) =>
      getNumberTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: INumberTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
