import { memoize } from '@kontent-ai/memoization';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';

export const getSubscriptionProjects = memoize.weak(
  (
    projects: ReadonlyArray<IProjectDetails>,
    subscriptionId: Uuid,
  ): ReadonlyArray<IProjectDetails> =>
    projects.filter((project: IProjectDetails) => project.subscriptionId === subscriptionId),
);

export const getSubscriptionMasterEnvironments = memoize.weak(
  (
    projects: ReadonlyArray<IProjectDetails>,
    subscriptionId: Uuid,
  ): ReadonlyArray<IProjectDetails> =>
    projects.filter(
      (project: IProjectDetails) =>
        project.subscriptionId === subscriptionId &&
        project.projectId === project.masterEnvironmentId &&
        !project.inactive,
    ),
);
