import { Card } from '@kontent-ai/component-library/Card';
import { PaperLevel } from '@kontent-ai/component-library/Paper';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { DialogState } from '../../DialogStateEnum.ts';
import { DialogFooter, IDialogFooterProps } from '../../components/DialogFooter.tsx';
import { IPopoverFrameProps, PopoverFrame } from './PopoverFrame.tsx';

interface IPopoverDialogSimpleProps {
  readonly headline: string;
  readonly minWidth?: string;
  readonly minHeight?: string;
  readonly dialogState?: DialogState;
}

export interface IPopoverDialogProps
  extends IPopoverDialogSimpleProps,
    IPopoverFrameProps,
    Omit<IDialogFooterProps, 'dialogState' | 'isDismissable'> {
  readonly dialogTitleProps: Omit<HTMLAttributes<HTMLElement>, 'children'>;
}

export const PopoverDialog = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<IPopoverDialogProps>
>(
  (
    {
      cancelAction,
      children,
      primaryAction,
      dialogTitleProps,
      extraAction,
      headline,
      minHeight,
      minWidth,
      onClose,
      renderNotificationBar,
      dialogState = DialogState.Default,
      ...popoverFrameProps
    },
    forwardedRef,
  ) => {
    return (
      <PopoverFrame {...popoverFrameProps} ref={forwardedRef}>
        <Card
          component="section"
          cardLabel="Card label"
          level={PaperLevel.Popout}
          css={{
            minWidth,
            minHeight,
          }}
        >
          {onClose && <Card.CloseButton onClose={onClose} />}
          <Card.Headline {...dialogTitleProps}>{headline}</Card.Headline>
          <Card.Body>{children}</Card.Body>
          <DialogFooter
            dialogState={dialogState}
            isDismissable
            {...{
              cancelAction,
              primaryAction,
              extraAction,
              onClose,
              renderNotificationBar,
            }}
          />
        </Card>
      </PopoverFrame>
    );
  },
);

PopoverDialog.displayName = 'PopoverDialog';
