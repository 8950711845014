import { EntityInstance } from 'draft-js';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import { Entity, EntityType, getEntityType } from '../../entityApi/api/Entity.ts';

type UserEntityData = {
  readonly userId: string;
};

export type MentionEntity = Entity<Partial<UserEntityData>>;

export type FinishedMentionEntity = Entity<UserEntityData>;

export const isMention = (entity: EntityInstance | undefined): entity is MentionEntity =>
  !!entity && getEntityType(entity) === EntityType.Mention;

export const isNewMention = (entity: EntityInstance | undefined): entity is MentionEntity =>
  isMention(entity) && entity.getData() && !entity.getData().userId;

export const isFinishedMention = (
  entity: EntityInstance | undefined,
): entity is FinishedMentionEntity =>
  isMention(entity) && entity.getData() && isString(entity.getData().userId);
