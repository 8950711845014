import { UnreachableCaseException } from '@kontent-ai/errors';
import {
  colorAlertText,
  colorTextDefault,
  colorTextDisabled,
} from '../../../../tokens/decision/colors.ts';
import { RadioGroupState } from '../../types.ts';

export const getRadioButtonLabelColor = (radioButtonState: RadioGroupState) => {
  switch (radioButtonState) {
    case 'default':
      return colorTextDefault;
    case 'disabled':
      return colorTextDisabled;
    case 'alert':
      return colorAlertText;
    default:
      throw UnreachableCaseException(radioButtonState);
  }
};
