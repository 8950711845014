import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IStore } from '../../../stores/IStore.type.ts';

export const getFirstWorkflowIdOfItemSelection = (state: IStore): Uuid | undefined => {
  const { selectAll, selectedItemIds, unselectedItemIds } =
    state.contentApp.listingUi.itemsSelection;
  const { allIds, byId } = state.data.listingContentItems;
  const firstRelevantItem: IListingContentItem | undefined = selectAll
    ? // "allIds" contains the identifiers of currently shown items in inventory
      allIds
        ?.filter((id: Uuid) => !unselectedItemIds.has(id))
        .map((id: Uuid) => byId.get(id))
        .first()
    : byId.get(selectedItemIds.first() ?? '');

  return firstRelevantItem?.variant?.assignment.workflowStatus.workflowId;
};
