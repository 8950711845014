export type VariantTranslationTaskParams = {
  readonly aiSessionId: Uuid;
  readonly allElementIds: ReadonlyArray<Uuid>;
  readonly createdBy: string;
  readonly completedAt: DateTimeStamp;
  readonly failedElementIds: ReadonlyArray<Uuid>;
  readonly itemId: Uuid;
  readonly projectEnvironmentId: Uuid;
  readonly skippedElementIds: ReadonlyArray<Uuid>;
  readonly sourceLanguageId: Uuid;
  readonly status: DurableTaskStatus;
  readonly successfulElementIds: ReadonlyArray<Uuid>;
  readonly variantId: Uuid;
};

export enum DurableTaskStatus {
  New = 'new',
  Running = 'running',
  Terminated = 'terminated',
  Completed = 'completed',
}
