import { RichTextElementUpdateData } from '@kontent-ai/smart-link';
import { notNullNorUndefined } from '@kontent-ai/utils';
import { GetState } from '../../../../@types/Dispatcher.type.ts';
import { getLanguageCodename } from '../../../../_shared/utils/languageUtils.ts';
import { isEditableElement } from '../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getDeliveryContentComponentCodename } from '../../../itemEditor/features/ContentComponent/utils/deliveryContentComponentUtils.ts';
import { IContentComponent } from '../../../itemEditor/models/contentItem/ContentComponent.ts';
import { ModularContentPreviewDataContext } from '../../types/ElementPreviewDataResolvers.type.ts';

type ContentComponentUpdateData = RichTextElementUpdateData['data']['linkedItems'][0];

export const getContentComponentDataForPreview = (
  contentComponent: IContentComponent,
  getState: GetState,
  context: ModularContentPreviewDataContext,
): ContentComponentUpdateData | null => {
  const {
    data: {
      collections: { byId: collectionsById },
      languages: { defaultLanguage, byId: languagesById },
    },
    contentApp: { editedContentItem, loadedContentItemTypes },
    sharedApp: {
      selectedLanguage: { id: selectedLanguageId },
    },
  } = getState();

  const { deliveryContentComponentIds, rootRichTextElement, elementResolvers } = context;

  if (!editedContentItem || !selectedLanguageId) {
    return null;
  }

  const type = loadedContentItemTypes.get(contentComponent.contentTypeId);
  if (!type?.codeName) {
    return null;
  }

  const deliveryId = deliveryContentComponentIds.get(contentComponent.id);
  if (!deliveryId) {
    return null;
  }

  const languageCodename = getLanguageCodename(selectedLanguageId, defaultLanguage, languagesById);
  const collectionCodename = collectionsById.get(editedContentItem.collectionId)?.codeName ?? '';
  const deliveryCodename = getDeliveryContentComponentCodename(deliveryId);

  const system: ContentComponentUpdateData['system'] = {
    codename: deliveryCodename,
    collection: collectionCodename,
    id: deliveryId,
    language: languageCodename,
    lastModified: rootRichTextElement.lastModifiedAt ?? new Date().toISOString(),
    name: deliveryId,
    sitemapLocations: [],
    type: type.codeName,
    workflow: null,
    workflowStep: null,
  };

  const elementsWithCodename = contentComponent.elements
    .map((el) => {
      const typeElement = type.contentElements.find((i) => i.elementId === el.elementId);
      if (!typeElement || !isEditableElement(typeElement) || !typeElement.codename) {
        return null;
      }

      const getValue = elementResolvers[el.type];
      if (!getValue) {
        return null;
      }

      const value = getValue(el, typeElement, getState, context);
      if (!value) {
        return null;
      }

      const data = {
        name: typeElement.name,
        type: value.type,
        ...value.data,
      };

      return [typeElement.codename, data];
    })
    .filter(notNullNorUndefined);

  return {
    system,
    elements: Object.fromEntries(elementsWithCodename),
  };
};
