import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  RelationsEntryRouteParams,
  RelationsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventData } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IRelationsConfigStorage } from '../../../../../localStorages/relationsConfigStorage.ts';
import {
  Relations_InitFailed,
  Relations_InitFinished,
  Relations_InitStarted,
} from '../../constants/relationsActionTypes.ts';
import { UnableToLoadErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadDefaultRelationsRoot: (
    abortSignal?: AbortSignal,
  ) => ThunkPromise<{ rootId: Uuid | null }>;
  readonly relationsConfigStorage: IProjectSpecificStorage<IRelationsConfigStorage>;
  readonly setRelationsRoot: (
    params: { rootId: Uuid | null },
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventData;
}

const started = (relationsPath: string) =>
  ({
    type: Relations_InitStarted,
    payload: {
      relationsPath,
    },
  }) as const;

const finished = () =>
  ({
    type: Relations_InitFinished,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Relations_InitFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type InitRelationsActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const initRelationsActionCreator =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const relationsPath = buildPath<RelationsEntryRouteParams>(RelationsRoute, {
      projectId: currentProjectId,
      variantId: selectedLanguageId,
    });

    dispatch(started(relationsPath));

    try {
      const { rootId: defaultRootId } = await dispatch(deps.loadDefaultRelationsRoot(abortSignal));
      const storage = deps.relationsConfigStorage.load(currentProjectId);
      const rootId = storage?.rootId || defaultRootId || null;

      await Promise.all([
        dispatch(deps.loadContentTypes(abortSignal)),
        dispatch(deps.loadCollections(abortSignal)),
        dispatch(
          deps.setRelationsRoot(
            {
              rootId,
            },
            abortSignal,
          ),
        ),
      ]);

      dispatch(finished());
      dispatch(deps.trackUserEvent(TrackedEvent.RelationsTabOpened));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(UnableToLoadErrorMessage));
      }

      throw error;
    }
  };
