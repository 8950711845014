import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  Sitemap_AddCollapsedNode,
  Sitemap_RemoveCollapsedNode,
} from '../../constants/sitemapActionTypes.ts';

export const collapsedNodeIds = (
  state = Immutable.List<Uuid>(),
  action: Action,
): Immutable.List<Uuid> => {
  switch (action.type) {
    case Sitemap_RemoveCollapsedNode:
      return state.delete(state.indexOf(action.payload.nodeId));

    case Sitemap_AddCollapsedNode:
      return state.push(action.payload.nodeId);

    default:
      return state;
  }
};
