import classNames from 'classnames';
import { isValid } from 'date-fns';
import React, { FocusEvent, KeyboardEvent } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { formatTimeToReadable, parseTime } from './datetimeUtils.ts';

interface ITimeInputProps {
  readonly defaultTime?: string;
  readonly isValid: boolean;
  readonly onBlur: (event: FocusEvent<HTMLInputElement>) => void;
  readonly onChange: (event: React.ChangeEvent<HTMLElement>) => void;
  readonly onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  readonly value?: string;
}

export const TimeInput: React.FC<ITimeInputProps> = (props) => {
  const selectAllText = (event: FocusEvent<HTMLInputElement>): void => {
    event.currentTarget.select();
  };
  const { defaultTime = '' } = props;
  const parsedTime = parseTime(defaultTime);
  const formattedDefaultTime = formatTimeToReadable(
    parsedTime && isValid(parsedTime) ? parsedTime : new Date(),
  );

  return (
    <div className="datetime-picker__time">
      <input
        autoComplete="off"
        className={classNames('datetime-picker__time-input form__text-field', {
          'datetime-picker__time-input--invalid': !props.isValid,
        })}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onFocus={selectAllText}
        onKeyDown={props.onKeyDown}
        placeholder={formattedDefaultTime || '12:00 am'}
        type="text"
        value={props.value || ''}
        {...getDataUiInputAttribute(DataUiInput.Time)}
      />
    </div>
  );
};

TimeInput.displayName = 'TimeInput';
