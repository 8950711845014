import {
  variantTranslationFinished,
  variantTranslationStarted,
} from '../../../applications/itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { VariantTranslationTask } from './VariantTranslationTask.type.ts';
import { VariantTranslationStatus } from './variantTranslationStatus.ts';

export const getTranslationStatusCallback = (task: VariantTranslationTask) => {
  const { itemId, projectEnvironmentId, variantId } = task.params;

  switch (task.overallProgress) {
    case VariantTranslationStatus.InProgress:
      return variantTranslationStarted(projectEnvironmentId, itemId, variantId);

    case VariantTranslationStatus.PartiallyFailed:
    case VariantTranslationStatus.Failed:
    case VariantTranslationStatus.Completed:
    case VariantTranslationStatus.Cancelled:
      return variantTranslationFinished(projectEnvironmentId, itemId, variantId);
  }
};
