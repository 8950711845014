import { InvariantException } from '@kontent-ai/errors';
import {
  DapiKeyDetailRoute,
  MapiKeyDetailRoute,
  PersonalMapiKeyDetailRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';

export const getApiKeyRedirectRoute = (apiKeyType: ApiKeyType): string => {
  switch (apiKeyType) {
    case ApiKeyType.MAPIPat:
      return PersonalMapiKeyDetailRoute;
    case ApiKeyType.MAPI:
      return MapiKeyDetailRoute;
    case ApiKeyType.DAPI:
      return DapiKeyDetailRoute;
    default:
      throw InvariantException(`Unsupported API key type ${apiKeyType}.`);
  }
};
