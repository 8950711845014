import { CodenameTargetAge } from '../models/TrackUserEventData.ts';
import { selectEnabledAiFeatureSets } from '../selectors/aiSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isUuid } from './validation/typeValidators.ts';

type TrackUserEventDataSelector = (state: IStore) => AnyObject | false | null | undefined;

const trackCurrentAiFeatureSets: TrackUserEventDataSelector = (state) =>
  areUserAndProjectPropertiesLoaded(state) && {
    aiFeatureSets: selectEnabledAiFeatureSets(state),
  };

const trackCurrentObjectAge: TrackUserEventDataSelector = ({
  sharedApp: { isObjectNewlyCreated },
}) =>
  isObjectNewlyCreated !== null && {
    age: isObjectNewlyCreated ? CodenameTargetAge.New : CodenameTargetAge.Existing,
  };

const trackCurrentProjectId: TrackUserEventDataSelector = ({ sharedApp: { currentProjectId } }) =>
  isUuid(currentProjectId) && {
    project: currentProjectId,
  };

const trackCurrentlyEditedItemVariantId: TrackUserEventDataSelector = ({
  contentApp: { editedContentItemVariant },
}) =>
  editedContentItemVariant && {
    ...editedContentItemVariant.id,
  };

const areUserAndProjectPropertiesLoaded = (state: IStore): boolean =>
  !!state.sharedApp.projectProperties && !!state.sharedApp.userProperties;

const contextualTrackers: TrackUserEventDataSelector[] = [
  trackCurrentProjectId,
  trackCurrentlyEditedItemVariantId,
  trackCurrentObjectAge,
  trackCurrentAiFeatureSets,
];

const emptyData = {};

export const getContextuallyTrackedData = (state: IStore) =>
  contextualTrackers
    .map((selector) => selector(state))
    .filter((data) => !!data)
    .reduce(
      (previousPayload, data) => ({
        ...previousPayload,
        ...data,
      }),
      emptyData,
    );
