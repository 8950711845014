import { Action } from '../../../../../@types/Action.type.ts';
import {
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangCreator_Show,
  LocalizationEditor_Language_Add,
  LocalizationEditor_LanguagesLoading_Success,
} from '../../constants/localizationActionTypes.ts';

export const isEditedLanguageNew = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case LocalizationEditor_LangCreator_Show:
      return true;

    case LocalizationEditor_LangCreator_Hide:
    case LocalizationEditor_Language_Add:
    case LocalizationEditor_LanguagesLoading_Success:
      return false;

    default:
      return state;
  }
};
