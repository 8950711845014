import { logError } from '../../../../../_shared/utils/logError.ts';
import { DiffType } from './DiffType.ts';
import { FakeCharType, IDiffInputBlock, IInputChar } from './diffDataUtils.ts';

export function validateCustomBlockInputCharacter(
  oldChar: IInputChar,
  diffType: DiffType,
  expectedFakeCharType: FakeCharType,
): void {
  if (oldChar.fakeCharType !== expectedFakeCharType) {
    logError(
      `Found unexpected fake character '${oldChar.char} (${oldChar.fakeCharType})' but expected a fake character of type ${expectedFakeCharType}. Diff type: ${diffType}`,
    );
  }
}

export function validateUnchangedCustomBlockInput(
  expectedFakeCharType: FakeCharType,
  oldBlock: IDiffInputBlock,
  newBlock: IDiffInputBlock,
  oldChar: IInputChar,
  newChar: IInputChar,
): void {
  validateCustomBlockInputCharacter(oldChar, DiffType.None, expectedFakeCharType);
  validateCustomBlockInputCharacter(newChar, DiffType.None, expectedFakeCharType);
  if (newBlock.type !== oldBlock.type) {
    logError(
      `Unchanged custom block, but old and new block type does not match. Old block type: "${oldBlock.type}". New block type: "${newBlock.type}".`,
    );
  }
}

export function validateUnchangedContentInput(oldChar: IInputChar, newChar: IInputChar): void {
  if (oldChar.fakeCharType !== newChar.fakeCharType) {
    logError(
      `Unchanged char must be of the same type in both old and new, otherwise the result may be inconsistent. Old char: "${oldChar.char} (${oldChar.fakeCharType})" New char: "${newChar.char} (${newChar.fakeCharType})".`,
    );
  }
}

export function validateUnchangedCharInputChars(
  newChar: IInputChar | undefined,
  oldChar: IInputChar | undefined,
  diffChar: string,
): void {
  if (!newChar) {
    logError(`Char stays but not found in corresponding new block: ${diffChar}`);
  }
  if (!oldChar) {
    logError(`Char stays but not found in corresponding old block: ${diffChar}`);
  }
}

export function validateUnchangedCharInputBlocks(
  newBlock: IDiffInputBlock | undefined,
  oldBlock: IDiffInputBlock | undefined,
  diffChar: string,
): void {
  if (!newBlock) {
    logError(`Char stays, but corresponding new block is not available: ${diffChar}`);
  }
  if (!oldBlock) {
    logError(`Char stays, but corresponding old block is not available: ${diffChar}`);
  }
}

export function validateAllContentProcessed(
  oldBlock: IDiffInputBlock | undefined,
  charsProcessedInOldBlock: number,
  newBlock: IDiffInputBlock | undefined,
  charsProcessedInNewBlock: number,
): void {
  if (oldBlock && oldBlock.characters.length > charsProcessedInOldBlock) {
    logError(
      `${
        oldBlock.characters.length - charsProcessedInOldBlock
      } remaining character(s) found in old block (${
        oldBlock.type
      }), diff may lack some content in output.`,
    );
  }
  if (newBlock && newBlock.characters.length > charsProcessedInNewBlock) {
    logError(
      `${
        newBlock.characters.length - charsProcessedInNewBlock
      } remaining character(s) found in new block (${
        newBlock.type
      }), diff may lack some content in output.`,
    );
  }
}
