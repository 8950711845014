import { Action } from '../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { isAiImageTaggingEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { tryParseApiError } from '../../../../../repositories/serverModels/ServerApiError.ts';
import {
  GetAssets,
  IItemElementServerModelConverterDataDependencies,
} from '../../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import { IAssetLibraryQuery } from '../../models/IAssetLibraryQuery.ts';
import { saveEditedAssetFail, saveEditedAssetStart } from '../assetLibraryActions.ts';

interface IDeps {
  readonly assetService: IAssetService;
  readonly saveEditedAssetFinish: (
    asset: IAsset,
    filterQuery: IAssetLibraryQuery,
    originalAsset: IAsset | undefined,
  ) => Action;
}

export const createUpdateAssetAction =
  (deps: IDeps) =>
  (asset: IAsset): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      assetLibraryApp: { query },
      data: {
        assets: { byId },
      },
    } = getState();

    try {
      dispatch(saveEditedAssetStart());

      const getAssets: GetAssets = () => getState().data.assets.byId;
      const elementDependencies: IItemElementServerModelConverterDataDependencies = { getAssets };
      const updatedAsset = await deps.assetService.updateAsset(asset, elementDependencies);

      dispatch(deps.saveEditedAssetFinish(updatedAsset, query, byId.get(asset.id)));
    } catch (error) {
      const apiError = tryParseApiError(error);
      if (apiError) {
        const isAiImageRecognitionEnabled = isAiImageTaggingEnabled(getState());
        dispatch(saveEditedAssetFail(apiError.code, apiError.message, isAiImageRecognitionEnabled));
      }
      throw error;
    }
  };
