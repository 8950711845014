import { Action } from '../../../../@types/Action.type.ts';
import { YourContent_InitYourTasks_Finished } from '../../constants/yourContentActionTypes.ts';

export const hasMore = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case YourContent_InitYourTasks_Finished:
      return action.payload.hasMore;

    default:
      return state;
  }
};
