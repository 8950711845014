import { TextToParagraphs } from '../../../../../../../../_shared/components/StringRenderers.tsx';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { changeNote } from '../../../../actions/thunkContentItemEditingActions.ts';
import { NoteAuthor } from '../../../../components/details/Note/NoteAuthor.tsx';
import { ClickableSection } from '../../../../components/sidebar/sectionTypes/ClickableSection.tsx';
import { getNoteState } from '../../../selectors/getNoteState.ts';

export const ClickableNoteSection = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeNote(ContentItemEditingEventOrigins.ItemDetails, ModalDialogType.ContentItemNoteDialog),
    );

  const { note } = useSelector(getNoteState);
  return (
    <>
      <ClickableSection
        dataUiActionAttribute={DataUiWorkflowAction.EditNote}
        dataUiNameAttribute={ItemEditingForms.Note}
        onEditClick={onClick}
      >
        {note && <TextToParagraphs text={note.text} />}
      </ClickableSection>
      <NoteAuthor note={note} />
    </>
  );
};
