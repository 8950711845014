import React from 'react';
import { ArchivedVariantAssignment as ArchivedVariantAssignmentComponent } from '../../../../../../../_shared/components/VariantAssignments/ArchivedVariantAssignment.tsx';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';

export const ArchivedVariantAssignment: React.FC = () => {
  const date = useSelector(
    (s) => s.contentApp.editedContentItemVariant?.assignment.createdAt || null,
  );

  return <ArchivedVariantAssignmentComponent date={date} />;
};

ArchivedVariantAssignment.displayName = 'ArchivedVariantAssignment';
