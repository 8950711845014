import styled from 'styled-components';

type Props = {
  readonly left: number;
  readonly top: number;
};
export const InlineToolbarAnchor = styled.div.attrs<Props>(({ left, top }) => ({
  style: {
    top,
    left,
    position: 'relative',
  },
}))<Props>`
  width: 0;
  height: 0;
`;
