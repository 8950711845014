import { combineReducers } from '@reduxjs/toolkit';
import { aggregations } from './internalReducers/aggregations.ts';
import { cancellationToken } from './internalReducers/cancellationToken.ts';
import { itemsLimitExceeded } from './internalReducers/itemsLimitExceeded.ts';
import { loadingStatus } from './internalReducers/loadingStatus.ts';

export const projectOverviewAppReducer = combineReducers({
  aggregations,
  cancellationToken,
  itemsLimitExceeded,
  loadingStatus,
});
