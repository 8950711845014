import React from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ActionType } from '../../../constants/editingActions/staticDetails.ts';
import { EditingAction as EditingActionEnum } from '../../../models/EditingAction.ts';
import { EditingAction } from '../actions/EditingAction.tsx';
import { ActionsMenuItem } from './ActionsMenuItem.tsx';

type ActionsMenuItemToActionMapperProps = {
  readonly action: EditingActionEnum;
  readonly actionOrigin: ContentItemEditingEventOrigins;
  readonly isSlim?: boolean;
};

export const ActionsMenuItemToActionMapper: React.FC<ActionsMenuItemToActionMapperProps> = (
  props,
) => (
  <EditingAction action={props.action}>
    {(details) => (
      <ActionsMenuItem
        badgeColor={details.badgeColor}
        badgeValue={details.badgeValue}
        dataAttributes={details.dataUiAttribute}
        disabled={details.isDisabled}
        iconName={details.iconName}
        isDestructive={details.actionType === ActionType.Destructive}
        isSlim={props.isSlim}
        onClick={() => details.performAction(props.actionOrigin)}
        outwardLinkUrl={details.outwardUrl}
        text={details.caption}
        tooltip={details.tooltip}
        withSubmenu={details.isSubmenu}
      />
    )}
  </EditingAction>
);

ActionsMenuItemToActionMapper.displayName = 'ActionsMenuItemToActionMapper';
