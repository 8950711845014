import { Direction } from '@kontent-ai/types';
import { assert } from '@kontent-ai/utils';
import { ContentBlock } from 'draft-js';
import {
  BaseBlockType,
  BlockType,
  BlockTypeWithSleeves,
  getParentBlockTypes as getParentBlockTypesForType,
  isBlockTypeNestedIn,
  isBlockTypeWithSleeves,
  isHeadingBlockType,
  isListBlockType,
  isMutableBlockType,
  isObjectBlockType,
  isTableContentBlockType,
  isTextBlockType,
  isTopLevelBlockType,
  parseBlockType,
} from './blockType.ts';
import { getBaseBlockType, getBlockLength, getFullBlockType } from './editorBlockGetters.ts';

export function isBlockNestedIn(block: ContentBlock, parentBlock: ContentBlock): boolean {
  return isBlockTypeNestedIn(getFullBlockType(block), getFullBlockType(parentBlock));
}

export function getNestingLevel(block: ContentBlock): number {
  return parseBlockType(getFullBlockType(block)).length - 1;
}

export function getParentBlockTypes(block: ContentBlock): ReadonlyArray<BaseBlockType> {
  return getParentBlockTypesForType(getFullBlockType(block));
}

// Block type check is separated here in order to avoid cyclic dependency because it is used by various methods
export function isTextBlock(block: ContentBlock): boolean {
  return isTextBlockType(getBaseBlockType(block));
}

export function isListItem(block: ContentBlock): boolean {
  return isListBlockType(getBaseBlockType(block));
}

export function isOrderedListItem(block: ContentBlock): boolean {
  return getBaseBlockType(block) === BlockType.OrderedListItem;
}

export function isUnorderedListItem(block: ContentBlock): boolean {
  return getBaseBlockType(block) === BlockType.UnorderedListItem;
}

export function isTopLevelBlock(block: ContentBlock): boolean {
  return !!block && isTopLevelBlockType(getFullBlockType(block));
}

export function isTableCell(block: ContentBlock | null | undefined): boolean {
  return !!block && getBaseBlockType(block) === BlockType.TableCell;
}

export function isTableCellContent(block: ContentBlock | null | undefined): boolean {
  const blockType = block && getFullBlockType(block);
  return !!blockType && isTableContentBlockType(blockType);
}

export function isInTable(block: ContentBlock | null | undefined): boolean {
  return isTableCell(block) || isTableCellContent(block);
}

export function isEmptyTextBlock(block: ContentBlock | null | undefined): boolean {
  return !!block && isTextBlock(block) && getBlockLength(block) === 0;
}

export function isNotEmptyTextBlock(block: ContentBlock | null | undefined): boolean {
  return !!block && isTextBlock(block) && getBlockLength(block) > 0;
}

export function isUnstyledBlock(block: ContentBlock): boolean {
  return getBaseBlockType(block) === BlockType.Unstyled;
}

export function isStyledTextBlock(block: ContentBlock): boolean {
  return isTextBlock(block) && !isUnstyledBlock(block);
}

export function isEmptyParagraph(block: ContentBlock | null | undefined): boolean {
  return !!block && isUnstyledBlock(block) && !getBlockLength(block);
}

export function isNewBlockPlaceholder(block: ContentBlock | null | undefined): boolean {
  return !!block && getBaseBlockType(block) === BlockType.NewBlockPlaceholder;
}

export function isImage(block: ContentBlock | null | undefined): boolean {
  return !!block && getBaseBlockType(block) === BlockType.Image;
}

export function isContentModule(block: ContentBlock | null | undefined): boolean {
  return !!block && getBaseBlockType(block) === BlockType.ContentModule;
}

export function isContentComponent(block: ContentBlock | null | undefined): boolean {
  return !!block && getBaseBlockType(block) === BlockType.ContentComponent;
}

export function isObjectBlock(block: ContentBlock | null | undefined): boolean {
  return !!block && isObjectBlockType(getBaseBlockType(block));
}

export function isHeading(block: ContentBlock | null | undefined): boolean {
  return !!block && isHeadingBlockType(getBaseBlockType(block));
}

interface IParentSearchResult {
  readonly block: ContentBlock;
  readonly index: number;
}

export function findParentBlock(
  blockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
): IParentSearchResult | null {
  const block = blocks[blockIndex];
  if (!block) {
    return null;
  }
  const blockLevel = getNestingLevel(block);
  if (blockLevel <= 0) {
    return null;
  }

  const desiredParentLevel = blockLevel - 1;
  let index = blockIndex - 1;

  while (blocks[index]) {
    const parentCandidate = blocks[index];
    if (!parentCandidate) {
      return null;
    }
    const candidateLevel = getNestingLevel(parentCandidate);
    if (candidateLevel < desiredParentLevel) {
      // Unrelated parent in higher level - no more chance of finding the parent
      return null;
    }
    if (candidateLevel === desiredParentLevel) {
      if (isBlockNestedIn(block, parentCandidate)) {
        // Found matching parent
        return {
          block: parentCandidate,
          index,
        };
      }
      // Possible parent but types didn't match - no more chance of finding the parent
      return null;
    }
    index -= 1;
  }
  // Reached the start of the content but parent not found
  return null;
}

interface ISiblingSearchResult {
  readonly block: ContentBlock | null;
  readonly index: number;
}

export function findSiblingBlock(
  blockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
  direction: Direction,
): ISiblingSearchResult {
  const block = blocks[blockIndex];
  assert(block, () => `${__filename}: Item at index "${blockIndex}" is not a content block.`);
  const blockLevel = getNestingLevel(block);
  const step = direction === Direction.Forward ? 1 : -1;
  const blockIsNotAContentBlockMessage = (index: number) => () =>
    `${__filename}: Sibling candidate block at index "${index}" is not a content block.`;

  let index = blockIndex + step;
  while (blocks[index]) {
    const siblingCandidate = blocks[index];
    assert(siblingCandidate, blockIsNotAContentBlockMessage(index));
    const candidateLevel = getNestingLevel(siblingCandidate);
    if (candidateLevel === blockLevel) {
      // Found sibling at the same level
      return {
        block: siblingCandidate,
        index,
      };
    }
    if (candidateLevel < blockLevel) {
      // Reached the end of the level but sibling not found
      return {
        block: null,
        index,
      };
    }
    index += step;
  }
  // Reached the end of the content but sibling not found
  return {
    block: null,
    index,
  };
}

export enum CustomBlockSleevePosition {
  BeforeOwner = 'before',
  AfterOwner = 'after',
  None = 'none',
}

export function hasMutableBlockType(block: ContentBlock | null): boolean {
  return !!block && isMutableBlockType(getBaseBlockType(block));
}

export function isCustomBlockSleeve(
  blockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
  requiredOwnerBlockType?: BlockTypeWithSleeves,
): boolean {
  const direction = getCustomBlockSleevePosition(blockIndex, blocks, requiredOwnerBlockType);
  return direction !== CustomBlockSleevePosition.None;
}

export function getCustomBlockSleevePosition(
  blockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
  requiredOwnerBlockType?: BlockTypeWithSleeves,
): CustomBlockSleevePosition {
  const block = blocks[blockIndex] ?? null;
  if (!isEmptyParagraph(block)) {
    return CustomBlockSleevePosition.None;
  }

  const previous = findSiblingBlock(blockIndex, blocks, Direction.Backward);
  const previousBlockType = previous.block && getBaseBlockType(previous.block);
  if (isBlockTypeWithSleeves(previousBlockType)) {
    const matchesOwnerTypeRequirement =
      !requiredOwnerBlockType || requiredOwnerBlockType === previousBlockType;
    if (matchesOwnerTypeRequirement) {
      return CustomBlockSleevePosition.AfterOwner;
    }
  }

  const next = findSiblingBlock(blockIndex, blocks, Direction.Forward);
  const nextBlockType = next.block && getBaseBlockType(next.block);
  if (isBlockTypeWithSleeves(nextBlockType)) {
    const matchesOwnerTypeRequirement =
      !requiredOwnerBlockType || requiredOwnerBlockType === nextBlockType;
    if (matchesOwnerTypeRequirement) {
      return CustomBlockSleevePosition.BeforeOwner;
    }
  }
  return CustomBlockSleevePosition.None;
}

export const HeadingBlockTypeSequence: ReadonlyArray<BaseBlockType> = [
  BlockType.Unstyled,
  BlockType.HeadingOne,
  BlockType.HeadingTwo,
  BlockType.HeadingThree,
  BlockType.HeadingFour,
  BlockType.HeadingFive,
  BlockType.HeadingSix,
];

export const TextBlockTypeSequence: ReadonlyArray<BaseBlockType> = [
  ...HeadingBlockTypeSequence,
  BlockType.OrderedListItem,
  BlockType.UnorderedListItem,
];

export const getNextBlockTypeInSequence = (
  sequence: ReadonlyArray<BaseBlockType>,
  blockType: BaseBlockType | null,
  allowCycle: boolean = true,
  backwards: boolean = false,
): BaseBlockType | null => {
  const index = sequence.findIndex((type) => type === blockType);
  if (index < 0) {
    if (!allowCycle) {
      return null;
    }
    return BlockType.Unstyled;
  }

  const offset = backwards ? -1 : 1;
  const nextIndex = index + offset;
  if (nextIndex < 0) {
    if (!allowCycle) {
      return null;
    }
    return sequence[sequence.length - 1] ?? null;
  }
  if (nextIndex >= sequence.length) {
    if (!allowCycle) {
      return null;
    }
    return sequence[0] ?? null;
  }
  return sequence[nextIndex] ?? null;
};
