import { modalOpened } from '../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { ModalDialogType } from '../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  UseThunkPromiseStatus,
  useThunkPromise,
} from '../../../../_shared/hooks/useThunkPromise.ts';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { isAdminOnSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { emptyApiKey } from '../../../projectSettings/apiKeys/models/ApiKey.ts';
import { getEditedSubscriptionId } from '../../shared/selectors/getEditedSubscriptionId.ts';
import { initSubscriptionApiKey } from '../actions/thunkSubscriptionApiActions.ts';
import { SubscriptionApiKeyTile as SubscriptionApiKeyTileComponent } from '../components/SubscriptionApiKeyTile.tsx';
import { isSapiAvailable } from '../selectors/sapiKeySelectors.tsx';

export const SubscriptionApiKeyTile = () => {
  const dispatch = useDispatch();

  const apiKeyRegenerationStatus = useSelector(
    (state) => state.subscriptionApp.apiKeys.regenerationStatus,
  );
  const apiKey = useSelector((state) => state.data.apiKeys.keyDetail);
  const subscriptionId = useSelector(getEditedSubscriptionId) ?? '';
  const isApiAvailable = useSelector(isSapiAvailable(subscriptionId));
  const isSubscriptionAdmin = useSelector((state) => isAdminOnSubscription(state, subscriptionId));

  const openSubscriptionKeyGenerationDialog = () =>
    dispatch(modalOpened(ModalDialogType.SapiKeyGenerationDialog));

  const [isInitThunkDone, , loadingStatus] = useThunkPromise(
    initSubscriptionApiKey,
    subscriptionId,
    isApiAvailable,
    isSubscriptionAdmin,
  );

  const acceptableApiKeyTypes = [ApiKeyType.SAPI, emptyApiKey.type];
  if (!isInitThunkDone || !acceptableApiKeyTypes.includes(apiKey.type)) {
    return <Loader />;
  }

  return (
    <SubscriptionApiKeyTileComponent
      apiKey={apiKey}
      isApiAvailable={isApiAvailable}
      isLoadingFailed={loadingStatus === UseThunkPromiseStatus.Failed}
      regenerationStatus={apiKeyRegenerationStatus}
      subscriptionId={subscriptionId}
      onRegenerate={openSubscriptionKeyGenerationDialog}
    />
  );
};
