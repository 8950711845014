import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import {
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed,
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Finished,
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Started,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadSubscriptionUsersUsage: () => ThunkPromise;
  readonly peopleRepository: Pick<
    IPeopleRepository,
    'activateContributorOnProject' | 'deactivateContributorOnProject'
  >;
}

const started = (userId: UserId, projectIds: ReadonlyArray<Uuid>, activate: boolean) =>
  ({
    type: SubscriptionUserDetail_ToggleActiveStateOnProjects_Started,
    payload: {
      userId,
      projectIds,
      activate,
    },
  }) as const;

const finished = () =>
  ({
    type: SubscriptionUserDetail_ToggleActiveStateOnProjects_Finished,
  }) as const;

const failed = (userId: UserId, projectIds: ReadonlyArray<Uuid>, activate: boolean) =>
  ({
    type: SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed,
    payload: {
      userId,
      projectIds,
      activate,
    },
  }) as const;

export type ToggleUserActiveStateOnProjectsActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createToggleUserActiveStateOnProjects =
  (deps: IDeps) =>
  (
    userId: UserId,
    subscriptionId: Uuid,
    projectIds: ReadonlyArray<Uuid>,
    activate: boolean,
  ): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(userId, projectIds, activate));

    try {
      let sentRequests: ReadonlyArray<Promise<void>> | null = null;
      if (activate) {
        sentRequests = projectIds.reduce(
          (requests: ReadonlyArray<Promise<void>>, projectId: Uuid) => {
            const request = deps.peopleRepository.activateContributorOnProject(
              userId,
              subscriptionId,
              projectId,
            );
            return [...requests, request];
          },
          new Array<Promise<void>>(),
        );
      } else {
        sentRequests = projectIds.reduce((requests: Array<Promise<void>>, projectId: Uuid) => {
          const request = deps.peopleRepository.deactivateContributorOnProject(
            userId,
            subscriptionId,
            projectId,
          );
          requests.push(request);
          return requests;
        }, new Array<Promise<void>>());
      }

      await Promise.all(sentRequests);
      dispatch(finished());
    } catch (e) {
      logError(e);
      dispatch(failed(userId, projectIds, activate));
    } finally {
      dispatch(deps.loadSubscriptionUsage(subscriptionId));
      dispatch(deps.loadSubscriptionUsersUsage());
    }
  };
