import React from 'react';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { Asset, EmptyAsset } from '../../../../../../data/models/assets/Asset.ts';
import { AssetLink as AssetLinkComponent, IAssetLinkOwnProps } from '../components/AssetLink.tsx';

export const AssetLink: React.FC<React.PropsWithChildren<IAssetLinkOwnProps>> = ({
  children,
  ...props
}) => {
  const asset = useSelector((state) => state.data.assets.byId.get(props.assetId) ?? EmptyAsset);
  // Ability to scroll to it by global asset id (from asset usage)
  const scrollId = CreateAutoScrollId.forAsset(props.assetId || '');

  return (
    <AssetLinkComponent
      isBrokenLink={!Asset.exists(asset) || asset.archived || !!asset._failed}
      scrollId={scrollId}
      {...props}
    >
      {children}
    </AssetLinkComponent>
  );
};

AssetLink.displayName = 'AssetLinkContainer';
