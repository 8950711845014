import { InvariantException } from '@kontent-ai/errors';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';

export function getEditedTypeElements(state: IStore): ReadonlyArray<TypeElement> {
  const {
    contentApp: { editedContentItem, loadedContentItemTypes },
  } = state;

  if (!editedContentItem) {
    throw InvariantException(
      'ContentItemElements.tsx container: "editedContentItem" is not loaded',
    );
  }

  const editedContentItemType = loadedContentItemTypes.get(
    editedContentItem.editedContentItemTypeId,
  );

  if (!editedContentItemType) {
    throw InvariantException(
      'ContentItemElements.tsx container: "editedContentItemType" is not loaded',
    );
  }

  return editedContentItemType.contentElements;
}
