import React from 'react';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { untitledContentItem } from '../../../../../contentInventory/content/constants/uiConstants.ts';
import { getAreAnyContentGroupsVisible } from '../../../../selectors/getAreAnyContentGroupsVisible.ts';
import { getEditedContentItemName } from '../../../CascadePublish/selectors/getEditedContentItemName.ts';
import { ContentItemPaneElementsGroup } from '../../components/ContentItemPaneElementsGroup.tsx';
import { ItemNameElement } from '../../components/elements/itemNameElement/ItemNameElement.tsx';
import { ItemNameGuidelineStatus } from '../../utils/itemEditingUtils.ts';

export const RevisionItemHeader: React.FC = () => {
  const name = useSelector(getEditedContentItemName);
  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);

  return (
    <ContentItemPaneElementsGroup
      isDisabled
      hasTopRoundedCorners
      hasBottomRoundedCorners={areAnyContentGroupsVisible}
    >
      <ItemNameElement
        dataUiInputName={DataUiInput.EntityName}
        isLoading={name === undefined}
        maxLength={ValidationConstants.ContentItemNameMaxLength}
        placeholder={untitledContentItem}
        guidelinesStatus={ItemNameGuidelineStatus.Hidden}
        title="Content item name"
        value={name ?? ''}
        readOnly
      />
    </ContentItemPaneElementsGroup>
  );
};

RevisionItemHeader.displayName = 'RevisionItemHeader';
