import { TaskStatus } from '../Task.ts';

export enum TasksEventActions {
  NavigatedFromYourTasks = 'navigated-from-your-tasks',
  TaskArchived = 'task-archived',
  TaskCompleted = 'task-completed',
  TaskCreated = 'task-created',
  TaskListExpanded = 'task-list-expanded',
  TaskReopened = 'task-reopened',
  TaskUpdated = 'task-updated',
}

type TasksEventWithWorkflowStep = Exclude<
  TasksEventActions,
  TasksEventActions.NavigatedFromYourTasks | TasksEventActions.TaskListExpanded
>;

export type TasksUserEventData =
  | {
      readonly action: TasksEventActions.NavigatedFromYourTasks;
      readonly itemId: Uuid;
      readonly taskId: Uuid;
      readonly variantId: Uuid;
    }
  | {
      readonly action: TasksEventWithWorkflowStep;
      readonly workflowStepId: string;
      readonly hasDueDate: boolean;
      readonly status?: TaskStatus;
    }
  | {
      readonly action: TasksEventActions.TaskListExpanded;
    };
