import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { Subscription_SubscriptionUsageLoaded } from '../../constants/subscriptionManagementActionTypes.ts';
import {
  ISubscriptionUsage,
  createSubscriptionUsageDomainModel,
} from '../../models/SubscriptionUsage.ts';
import { ILoadSubscriptionUsage } from '../../types/ILoadSubscriptionUsage.type.ts';

interface ILoadSubscriptionUsagesDependencies {
  readonly subscriptionRepository: ISubscriptionRepository;
}

const subscriptionUsageLoaded = (subscriptionUsage: ISubscriptionUsage, subscriptionId: Uuid) =>
  ({
    type: Subscription_SubscriptionUsageLoaded,
    payload: {
      subscriptionId,
      subscriptionUsage,
    },
  }) as const;

export type LoadSubscriptionUsagesActionsType = ReturnType<typeof subscriptionUsageLoaded>;

export const loadSubscriptionUsageCreator =
  (dependencies: ILoadSubscriptionUsagesDependencies): ILoadSubscriptionUsage =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const subscriptionUsageServerModel =
      await dependencies.subscriptionRepository.getSubscriptionUsage(subscriptionId, abortSignal);
    const usage = createSubscriptionUsageDomainModel(subscriptionUsageServerModel);
    dispatch(subscriptionUsageLoaded(usage, subscriptionId));
  };
