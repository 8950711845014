import { AriaMenuOptions } from '@react-aria/menu';
import { RootMenuTriggerState } from '@react-stately/menu';
import { AriaButtonProps } from '@react-types/button';
import React, { useMemo, PropsWithChildren, RefObject } from 'react';

type MenuContextState = Readonly<{
  menuTriggerProps: AriaButtonProps;
  menuTriggerRef: RefObject<HTMLElement>;
  menuTriggerState: RootMenuTriggerState;
  menuListProps: AriaMenuOptions<any>;
  menuListRef: RefObject<HTMLDivElement>;
}>;

export const MenuContext = React.createContext<MenuContextState | null>(null);

type MenuContextProviderProps = MenuContextState;

export const MenuContextProvider: React.FC<PropsWithChildren<MenuContextProviderProps>> = ({
  children,
  menuTriggerProps,
  menuTriggerRef,
  menuTriggerState,
  menuListProps,
  menuListRef,
}) => {
  const contextState = useMemo<MenuContextState>(
    () => ({
      menuTriggerProps,
      menuTriggerRef,
      menuTriggerState,
      menuListProps,
      menuListRef,
    }),
    [menuTriggerProps, menuTriggerRef, menuTriggerState, menuListProps, menuListRef],
  );

  return <MenuContext.Provider value={contextState}>{children}</MenuContext.Provider>;
};
