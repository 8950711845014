import { isString } from '../../../../_shared/utils/stringUtils.ts';

// All element types
export enum ElementType {
  Asset = 'CONTENTELEMENT_ASSET',
  ContentTypeSnippet = 'CONTENTELEMENT_SNIPPET',
  Custom = 'CONTENTELEMENT_CUSTOM',
  DateTime = 'CONTENTELEMENT_DATETIME',
  Guidelines = 'CONTENTELEMENT_GUIDELINES',
  LinkedItems = 'CONTENTELEMENT_RELATEDCONTENT',
  MultipleChoice = 'CONTENTELEMENT_MULTIPLECHOICE',
  Number = 'CONTENTELEMENT_NUMBER',
  RichText = 'CONTENTELEMENT_RICHSTRING',
  Subpages = 'CONTENTELEMENT_SUBPAGES',
  Taxonomy = 'CONTENTELEMENT_TAXONOMY',
  Text = 'CONTENTELEMENT_STRING',
  UrlSlug = 'CONTENTELEMENT_URLSLUG',
}

export const elementTypes: ReadonlyArray<ElementType> = Object.values(ElementType);

export const isElementType = (type: unknown): type is ElementType =>
  isString(type) && (elementTypes as ReadonlyArray<string>).includes(type);

type ClientOnlyElementType = ElementType.Subpages;

// All type elements
export type TypeElementType = ElementType;
export const TypeElementTypes: ReadonlyArray<ElementType> = Object.values(ElementType);

export type TypeElementTypeServerModel = Exclude<TypeElementType, ClientOnlyElementType>;

// Type elements when compiled (flattened) for usage in items
export type CompiledTypeElementType = Exclude<TypeElementType, ElementType.ContentTypeSnippet>;

const compiledElementTypesMap: ReadonlyRecord<CompiledTypeElementType, null> = {
  [ElementType.Asset]: null,
  [ElementType.Custom]: null,
  [ElementType.DateTime]: null,
  [ElementType.Guidelines]: null,
  [ElementType.LinkedItems]: null,
  [ElementType.MultipleChoice]: null,
  [ElementType.Number]: null,
  [ElementType.RichText]: null,
  [ElementType.Subpages]: null,
  [ElementType.Taxonomy]: null,
  [ElementType.Text]: null,
  [ElementType.UrlSlug]: null,
};
export const CompiledTypeElementTypes: ReadonlyArray<CompiledTypeElementType> =
  Object.keys(compiledElementTypesMap);

export type CompiledTypeElementTypeServerModel = Exclude<
  CompiledTypeElementType,
  ClientOnlyElementType
>;

export type ContentTypeSnippetElementType = Exclude<
  TypeElementType,
  ElementType.ContentTypeSnippet | ElementType.UrlSlug
>;

// Element types present in content item
export type NonEditableElementType = ElementType.Guidelines;
export type EditableElementType = Exclude<CompiledTypeElementType, NonEditableElementType>;

export type ItemElementType = Exclude<EditableElementType, ClientOnlyElementType>;

const itemElementTypesMap: ReadonlyRecord<ItemElementType, null> = {
  [ElementType.Asset]: null,
  [ElementType.Custom]: null,
  [ElementType.DateTime]: null,
  [ElementType.LinkedItems]: null,
  [ElementType.MultipleChoice]: null,
  [ElementType.Number]: null,
  [ElementType.RichText]: null,
  [ElementType.Taxonomy]: null,
  [ElementType.Text]: null,
  [ElementType.UrlSlug]: null,
};
export const ItemElementTypes: ReadonlyArray<ItemElementType> = Object.keys(itemElementTypesMap);

// Element types present in assets
export type AssetElementType = ElementType.Taxonomy;

const assetElementTypesMap: ReadonlyRecord<AssetElementType, null> = {
  [ElementType.Taxonomy]: null,
};
export const assetElementTypes: ReadonlyArray<AssetElementType> = Object.keys(assetElementTypesMap);

export const isAssetElementType = (type: ElementType): type is AssetElementType =>
  (assetElementTypes as ReadonlyArray<ElementType>).includes(type);
