import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { loadItemVariantTimelineActionCreator } from './thunks/loadItemVariantTimeline.ts';
import { loadMoreItemsVariantTimelineActionCreator as loadMoreVariantTimelineItemsActionCreator } from './thunks/loadMoreVariantTimelineItems.ts';
import { loadTimelineActionCreator } from './thunks/loadTimeline.ts';

const { contentItemRepository } = repositoryCollection;

export const loadItemVariantTimeline = loadItemVariantTimelineActionCreator({
  contentItemRepository,
});

export const loadTimeline = loadTimelineActionCreator({ loadItemVariantTimeline });

export const loadMoreVariantTimelineItems = loadMoreVariantTimelineItemsActionCreator({
  contentItemRepository,
});

export const loadMoreTimelineItems = loadTimelineActionCreator({
  loadItemVariantTimeline: loadMoreVariantTimelineItems,
});
