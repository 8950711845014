import { Collection, areShallowEqual } from '@kontent-ai/utils';
import { areTheSame } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import {
  ICollectionGroupRoles,
  IProjectContributorRole,
} from '../../../../data/models/users/ProjectContributor.ts';
import { PredefinedRoles } from '../../roles/constants/predefinedRoles.ts';
import {
  roleBuilderAnyCollectionOptionId,
  roleBuilderAnyLanguageOptionId,
} from '../constants/roleBuilder.ts';

export const isCollectionGroupRolesValid = (
  collectionGroupRoles: readonly ICollectionGroupRoles[],
  allRoles: IRoleWithSettings[],
): boolean => {
  const projectManagerIds = allRoles
    .filter((role: IRoleWithSettings) => role.codename === PredefinedRoles.ProjectManager)
    .map((role: IRoleWithSettings) => role.id);
  const containsProjectManagerRole = collectionGroupRoles.some((group: ICollectionGroupRoles) =>
    group.roles.some((role: IProjectContributorRole) =>
      projectManagerIds.some((pmId) => pmId === role.roleId),
    ),
  );

  if (!containsProjectManagerRole) {
    return true;
  }

  const firstGroupRole = Collection.getFirst(collectionGroupRoles);
  return (
    collectionGroupRoles.length === 1 &&
    !!firstGroupRole &&
    areTheSame(
      firstGroupRole.collectionIds,
      [roleBuilderAnyCollectionOptionId],
      (a, b) => a === b,
    ) &&
    firstGroupRole.roles.length === 1 &&
    areShallowEqual(firstGroupRole.roles[0]?.languageIds ?? [], [roleBuilderAnyLanguageOptionId])
  );
};
