import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState } from '../../../../../../../@types/Dispatcher.type.ts';
import { getSavedFilterUpdateServerModelFromListingFilter } from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { IFilterRepository } from '../../../../../../../repositories/interfaces/IFilterRepository.type.ts';
import { IFilterServerModel } from '../../../../../../../repositories/serverModels/FilterServerModels.type.ts';
import { isListingFilterEmpty } from '../../../../models/filter/IListingFilter.ts';
import {
  ListingFilter_CreateLinkedFilter_Failed,
  ListingFilter_CreateLinkedFilter_Finished,
  ListingFilter_CreateLinkedFilter_Started,
} from '../../constants/listingFilterActionTypes.ts';
import { UnableToCreateLinkErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly filterRepository: IFilterRepository;
}

const failed = (errorMessage: string) =>
  ({
    type: ListingFilter_CreateLinkedFilter_Failed,
    payload: { errorMessage },
  }) as const;

const finished = (projectId: Uuid, filter: IFilterServerModel) =>
  ({
    type: ListingFilter_CreateLinkedFilter_Finished,
    payload: {
      projectId,
      filter,
    },
  }) as const;

const started = () =>
  ({
    type: ListingFilter_CreateLinkedFilter_Started,
  }) as const;

export type CreateLinkedFilter = ReturnType<typeof started | typeof finished | typeof failed>;

export const createCreateLinkedFilterAction =
  (deps: IDeps) =>
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        listingUi: { filter },
      },
      sharedApp: { currentProjectId },
    } = getState();

    if (isListingFilterEmpty(filter)) {
      throw InvariantException('createLinkedFilter.ts: listing filter is empty');
    }

    dispatch(started());

    try {
      const createFilterServerModel = getSavedFilterUpdateServerModelFromListingFilter(filter);
      const serverModel = await deps.filterRepository.createLinkedFilter(createFilterServerModel);
      dispatch(finished(currentProjectId, serverModel));
    } catch (e) {
      dispatch(failed(UnableToCreateLinkErrorMessage));
      throw e;
    }
  };
