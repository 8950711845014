import { PropsWithChildren, RefObject } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside.ts';

type Props<TRef> = {
  readonly observedRefs: OneOrMore<RefObject<TRef>>;
  readonly onClickOutside: (event: MouseEvent) => void;
};

export const HandleClickOutside = <TRef extends HTMLElement>({
  children,
  observedRefs,
  onClickOutside,
}: PropsWithChildren<Props<TRef>>) => {
  useOnClickOutside<TRef>(observedRefs, onClickOutside);

  return <>{children}</>;
};

HandleClickOutside.displayName = 'HandleClickOutside';
