import React from 'react';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  EditContentTypeRoute,
  EditContentTypeRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { buildPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TypeSection as TypeSectionComponent } from '../../components/details/TypeSection.tsx';

export const TypeSection: React.FC = () => {
  const dispatch = useDispatch();
  const isClickable = useSelector((state: IStore): boolean => {
    return currentUserHasCapabilities(state, Capability.ConfigureContentTypes);
  });
  const typeLink = useSelector((state: IStore): string | null => {
    const {
      contentApp: { editedContentItem, loadedContentItemTypes },
    } = state;

    const itemType = loadedContentItemTypes.get(editedContentItem?.editedContentItemTypeId ?? '');

    if (!itemType) {
      return null;
    }

    return buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
      contentTypeId: itemType.id,
      projectId: state.sharedApp.currentProjectId,
    });
  });
  const typeName = useSelector((state: IStore): string => {
    const {
      contentApp: { editedContentItem, loadedContentItemTypes },
    } = state;

    const itemType = loadedContentItemTypes.get(editedContentItem?.editedContentItemTypeId ?? '');

    return itemType?.name ?? '';
  });

  if (!typeLink || !typeName) {
    return null;
  }

  return (
    <TypeSectionComponent
      isClickable={isClickable}
      onClick={() =>
        dispatch(
          trackUserEventWithData(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingEventTypes.ContentType,
            origin: ContentItemEditingEventOrigins.ItemDetails,
          }),
        )
      }
      typeLink={typeLink}
      typeName={typeName}
    />
  );
};

TypeSection.displayName = 'TypeSectionContainer';
