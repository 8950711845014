import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { BarItemExpandedNoActions } from '../../../../_shared/components/BarItems/BarItemExpandedNoActions.tsx';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  renderDateUtcString,
  renderDatetimeString,
  renderTimeUtcString,
} from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { jsonTryParse } from '../../../../_shared/utils/jsonUtils.ts';
import { isString, replaceEmptyStringWithDash } from '../../../../_shared/utils/stringUtils.ts';
import { IAuditEvent } from '../../../../data/models/auditLog/AuditEvent.ts';

interface IEventProperty {
  readonly eventPropertyTitle: string;
  readonly eventPropertyValue: string;
  readonly eventPropertyOldValue: string;
  readonly eventPropertyTooltipLabel: string;
  readonly eventPropertyTooltipText: string;
}

export interface IAuditLogEventDetailProps {
  readonly auditLogEvent: IAuditEvent;
}

interface IAuditEventObjectDescription {
  readonly name: string;
  readonly codename: string;
}

interface IAuditEventActionDescription {
  readonly original: IAuditEventObjectDescription;
  readonly updated: IAuditEventObjectDescription;
}

const renderPropertyValue = (property: Partial<IEventProperty>) => {
  return (
    <>
      {isString(property.eventPropertyValue)
        ? replaceEmptyStringWithDash(property.eventPropertyValue)
        : null}
      {property.eventPropertyOldValue &&
        property.eventPropertyOldValue !== property.eventPropertyValue && (
          <span className="audit-log__event__property-old-value">
            {`(Before: ${property.eventPropertyOldValue})`}
          </span>
        )}
    </>
  );
};

const renderProperties = (title: string, properties: ReadonlyArray<Partial<IEventProperty>>) => {
  return (
    <table className="audit-log__event-properties__table">
      <thead className="audit-log__event-properties__table-head">
        <tr>
          <th className="audit-log__event-properties__title" colSpan={2}>
            {title}
          </th>
        </tr>
      </thead>
      <tbody>
        {properties.map((property) => (
          <tr className="audit-log-event-properties__row" key={property.eventPropertyTitle}>
            <td className="audit-log__event__property-title">{property.eventPropertyTitle}</td>
            <td className="audit-log__event__property-value">
              {property.eventPropertyTooltipText ? (
                <>
                  {property.eventPropertyValue}{' '}
                  <QuinaryButton
                    tooltipText={property.eventPropertyTooltipText}
                    tooltipPlacement="top-start"
                    onClick={() => noOperation()}
                  >
                    {property.eventPropertyTooltipLabel}
                    <QuinaryButton.Icon
                      icon={Icons.QuestionCircle}
                      screenReaderText={property.eventPropertyTooltipText}
                    />
                  </QuinaryButton>
                </>
              ) : (
                renderPropertyValue(property)
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const parseActionDescription = (
  auditLogEvent: IAuditEvent,
): IAuditEventActionDescription | null => {
  const actionDescription = jsonTryParse(auditLogEvent.actionDescription);
  return actionDescription as IAuditEventActionDescription | null;
};

const parseObjectDescription = (
  auditLogEvent: IAuditEvent,
): IAuditEventObjectDescription | null => {
  const objectDescription = jsonTryParse(auditLogEvent.objectDescription);
  return objectDescription as IAuditEventObjectDescription | null;
};

export const AuditLogEventDetails: React.FC<IAuditLogEventDetailProps> = ({ auditLogEvent }) => {
  const eventActionDescription = parseActionDescription(auditLogEvent);
  const eventObjectDescription = parseObjectDescription(auditLogEvent);
  const eventObjectProperties = [
    {
      eventPropertyTitle: 'Type',
      eventPropertyValue: auditLogEvent.objectType,
    },
    {
      eventPropertyTitle: 'Object ID',
      eventPropertyValue: auditLogEvent.objectId,
    },
    {
      eventPropertyTitle: 'Name',
      eventPropertyValue: auditLogEvent.objectDisplayName,
      eventPropertyOldValue: eventActionDescription?.original.name,
    },
    {
      eventPropertyTitle: 'Codename',
      eventPropertyValue: eventObjectDescription?.codename,
      eventPropertyOldValue: eventActionDescription?.original.codename,
    },
  ];

  const eventOtherProperties = [
    {
      eventPropertyTitle: 'E-mail',
      eventPropertyValue: auditLogEvent.userEmail,
    },
    {
      eventPropertyTitle: 'Name',
      eventPropertyValue: auditLogEvent.userFullName,
    },
    {
      eventPropertyTitle: 'Source',
      eventPropertyValue: auditLogEvent.source,
    },
    {
      eventPropertyTitle: 'Date',
      eventPropertyValue: `${renderDateUtcString(auditLogEvent.timestamp)}, ${renderTimeUtcString(
        auditLogEvent.timestamp,
        true,
      )}`,
      eventPropertyTooltipText: `Shown in UTC (Coordinated Universal Time,
also known as GMT).
     
This is ${renderDatetimeString(auditLogEvent.timestamp, true)} your
local time.`,
      eventPropertyTooltipLabel: 'UTC',
    },
  ];

  return (
    <BarItemExpandedNoActions dataUiElement={DataUiElement.AuditLogEventDetails}>
      {renderProperties('What changed', eventObjectProperties)}
      {renderProperties('Who changed it and when', eventOtherProperties)}
    </BarItemExpandedNoActions>
  );
};

AuditLogEventDetails.displayName = 'AuditLogEventDetails';
