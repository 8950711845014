import { Box } from '@kontent-ai/component-library/Box';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Paper } from '@kontent-ai/component-library/Paper';
import {
  Spacing,
  Typography,
  colorTextDefault,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';

const WidgetTitle: React.FC<
  Readonly<{
    explanatoryLabel?: string;
    text: string;
  }>
> = ({ explanatoryLabel, text }) => (
  <Box
    alignItems="center"
    css="grid-area: title;"
    display="flex"
    gap={Spacing.XS}
    typography={Typography.HeadlineSmall}
    component="h2"
    margin={0}
  >
    {text}
    {explanatoryLabel && <Hint tooltipText={explanatoryLabel} />}
  </Box>
);

WidgetTitle.displayName = 'WidgetTitle';

const WidgetAux: React.FC<
  React.PropsWithChildren & {
    readonly maxWidth?: number;
  }
> = ({ children, maxWidth = 22 * gridUnit }) => (
  <Box css="grid-area: aux" maxWidth={maxWidth}>
    {children}
  </Box>
);

WidgetAux.displayName = 'WidgetAux';

const WidgetBody: React.FC<React.PropsWithChildren> = ({ children, ...props }) => (
  <div css={`grid-area: body; padding-top: ${px(Spacing.S)};`} {...props}>
    {children}
  </div>
);

WidgetBody.displayName = 'WidgetBody';

const WidgetFooter: React.FC<React.PropsWithChildren> = ({ children, ...props }) => (
  <div
    className="widget__footer"
    css={`grid-area: footer; padding: ${px(Spacing.L)} 0;`}
    {...props}
  >
    {children}
  </div>
);

WidgetFooter.displayName = 'WidgetFooter';

const WidgetSubtitle: React.FC<React.PropsWithChildren> = ({ children, ...props }) => (
  <div css={`grid-area: subtitle; padding-top: ${px(Spacing.S)};`} {...props}>
    {children}
  </div>
);

WidgetSubtitle.displayName = 'WidgetSubtitle';

const WidgetCenterContent: React.FC<React.PropsWithChildren> = ({ children, ...props }) => (
  <div
    css={`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `}
    {...props}
  >
    {children}
  </div>
);

WidgetCenterContent.displayName = 'WidgetCenterContent';

const WidgetWrapper: React.FC<React.PropsWithChildren> = ({ children, ...props }) => (
  <Paper
    padding={Spacing.XL}
    height="100%"
    {...props}
    className="widget"
    css={`
      height: 100%;
      display: grid;
      grid-template:
        "title aux" auto
        "subtitle aux" auto
        "body body" 1fr
        "footer footer" auto
        / 1fr auto;
      align-items: start;
      color: ${colorTextDefault};

      &:has(.widget__footer) {
        padding-bottom: 0;
      }
    `}
  >
    {children}
  </Paper>
);

WidgetWrapper.displayName = 'Widget';

export const Widget = Object.assign(WidgetWrapper, {
  AuxElement: WidgetAux,
  Body: WidgetBody,
  Footer: WidgetFooter,
  Subtitle: WidgetSubtitle,
  Title: WidgetTitle,
  CenterContent: WidgetCenterContent,
});
