import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import {
  EditableAppBar,
  IEditableAppBarDispatchProps,
  IEditableAppBarOwnProps,
  IEditableAppBarStateProps,
} from '../../../_shared/components/EditableAppBar.tsx';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  areAllSubscriptionsCanceled,
  areAllSubscriptionsExpired,
  getAdministratedSubscriptions,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { showCreateProjectModal } from '../actions/projectsActions.ts';
import { IsCreatingANewProject, IsLoadingProjectLocations } from '../constants/errorMessages.ts';

function getTooltipText(isCreating: boolean, isLoading: boolean): string | undefined {
  if (isCreating) {
    return IsCreatingANewProject;
  }

  if (isLoading) {
    return IsLoadingProjectLocations;
  }

  return undefined;
}

const mapStateToProps = (state: IStore): IEditableAppBarStateProps => {
  const {
    data: { subscriptions },
    projectsApp: { creatingProject },
    sharedApp: { availableProjectLocations },
  } = state;

  const administratedSubscriptions = getAdministratedSubscriptions(
    subscriptions.administratedIds,
    subscriptions.byId,
  );
  const isLoading = !availableProjectLocations.length;
  const tooltipText = getTooltipText(creatingProject, isLoading);

  return {
    createButtonText: 'Create new project',
    canCreateNewObject:
      administratedSubscriptions.length > 0 &&
      !areAllSubscriptionsExpired(administratedSubscriptions) &&
      !areAllSubscriptionsCanceled(administratedSubscriptions),
    isDisabled: creatingProject || isLoading,
    createNewItemTooltip: tooltipText,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IEditableAppBarDispatchProps => ({
  onCreate: () => dispatch(showCreateProjectModal()),
});

export const ProjectEditableAppBar: React.ComponentType<IEditableAppBarOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditableAppBar);
