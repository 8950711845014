import { DateTimeTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  IDateTimeTypeElementData,
  getDateTimeTypeElementDataFromServerModel,
} from '../../models/elements/DateTimeTypeElementData.ts';
import { hasDateTimeDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';

function convertTypeElementRecordToServerModel(
  typeElement: IDateTimeTypeElementData,
): DateTimeTypeElementServerModel {
  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    default: hasDateTimeDefaultValue(typeElement)
      ? { global: { value: typeElement.defaultValue.value } }
      : null,
    type: ElementType.DateTime,
  };
}

export function createDateTimeTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: DateTimeTypeElementServerModel) =>
      getDateTimeTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IDateTimeTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
