import { memoize } from '@kontent-ai/memoization';
import {
  AssignmentComparisonDifference,
  Notifications,
} from '../../../_shared/services/signalR/signalRClient.type.ts';
import {
  IAssignmentWorkflowStep,
  WorkflowStepAction,
} from '../../../data/models/workflow/WorkflowStep.ts';
import { IContentItemWithVariantServerModel } from '../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  IContentItemOverwritten,
  IContentItemOverwrittenDifference,
  IVariantWorkflowStepOverwritten,
} from '../../contentInventory/content/stores/IContentAppStoreState.ts';
import { IAssignment } from '../models/contentItem/Assignment.ts';
import { ContentItemChangeReason } from '../models/contentItem/ContentItemChangeReason.type.ts';

const differencesSupportingAutoRefresh: ReadonlyArray<keyof IContentItemOverwrittenDifference> = [
  'assignees',
  'codename',
  'dueDate',
  'name',
  'note',
  'publishScheduleTime',
  'sitemap',
  'unpublishScheduleTime',
  'workflowStep',
];

const changeReasonsSupportingAutoRefresh: ReadonlyArray<string> = [
  ContentItemChangeReason.DiscardVersion,
  ContentItemChangeReason.NewVersion,
  ContentItemChangeReason.Publish,
  ContentItemChangeReason.RestoreRevision,
  ContentItemChangeReason.Unpublish,
  ContentItemChangeReason.Update,
];

export const getKeysOfDetectedDifferences = memoize.weak(
  (
    differences: IContentItemOverwrittenDifference,
  ): ReadonlyArray<keyof IContentItemOverwrittenDifference> =>
    Object.keys(differences).filter(
      (
        key: keyof IContentItemOverwrittenDifference,
      ): key is keyof IContentItemOverwrittenDifference => differences[key] !== undefined,
    ),
);

export const itemOverwriteCanAutoRefresh = (itemOverwrite: IContentItemOverwritten): boolean => {
  if (!changeReasonsSupportingAutoRefresh.includes(itemOverwrite.changeReason)) {
    return false;
  }

  // RestoreRevision contains changes only in elements' values and is being applied automatically by adding changed elements to isOutdated state
  if (itemOverwrite.changeReason === ContentItemChangeReason.RestoreRevision) {
    return true;
  }

  const diffKeys = getKeysOfDetectedDifferences(itemOverwrite.difference);
  const containsOnlyAutoRefreshDifferences =
    diffKeys.length > 0 && diffKeys.every((key) => differencesSupportingAutoRefresh.includes(key));
  return containsOnlyAutoRefreshDifferences;
};

export const isOverwrittenToRegularStep = (
  workflowStep: IVariantWorkflowStepOverwritten,
): boolean => {
  return (
    workflowStep.workflowStatus.action === WorkflowStepAction.NoAction &&
    workflowStep.workflowStatus.name !== 'Archived'
  );
};

export const createNewContentItemOverwrite = (
  params: Notifications['contentChange']['payload'],
): IContentItemOverwritten => {
  return {
    changeBy: params.changeBy,
    changeByManageApi: params.changeByManageApi,
    changeReason: params.changeReason,
    difference: {},
  };
};

export const workflowStepOverwrittenDifference = (
  contentItemWithVariant: IContentItemWithVariantServerModel,
  workflowStatus: IAssignmentWorkflowStep,
): IVariantWorkflowStepOverwritten => {
  return {
    createdAt: contentItemWithVariant.variant.assignment.createdAt,
    lastPublishedAt: contentItemWithVariant.variant.lastPublishedAt,
    lastPublishedBy: contentItemWithVariant.variant.lastPublishedBy,
    publishingState: contentItemWithVariant.variant.publishingState,
    variantCapabilities: contentItemWithVariant.activeCapabilities.variantCapabilities,
    workflowStatus,
  };
};

export const getItemOverwrittenWorkflowDifferences = (
  assignment: IAssignment,
  assignmentDifferences: ReadonlyArray<AssignmentComparisonDifference>,
  contentItemWithVariant: IContentItemWithVariantServerModel,
): IContentItemOverwrittenDifference => ({
  publishScheduleTime: assignmentDifferences.includes(
    AssignmentComparisonDifference.PublishScheduleTime,
  )
    ? assignment.scheduledToPublishAt
    : undefined,
  unpublishScheduleTime: assignmentDifferences.includes(
    AssignmentComparisonDifference.UnpublishScheduleTime,
  )
    ? assignment.scheduledToUnpublishAt
    : undefined,
  workflowStep: assignmentDifferences.includes(AssignmentComparisonDifference.WorkflowStep)
    ? workflowStepOverwrittenDifference(contentItemWithVariant, assignment.workflowStatus)
    : undefined,
});
