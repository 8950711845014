import classNames from 'classnames';
import { useState } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import {
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
} from '../../../../../models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { CommentThreadItemContent } from './CommentThreadItemContent.tsx';
import { CommentThreadItemEditor } from './CommentThreadItemEditor.tsx';
import { CommentThreadItemHeader } from './CommentThreadItemHeader.tsx';
import { CommentThreadItemSegmentInfo } from './CommentThreadItemSegmentInfo.tsx';

type CommentThreadItemProps = {
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly allowUnresolve: boolean;
  readonly approveInfo: string;
  readonly canApproveSuggestion: boolean;
  readonly className: string;
  readonly commentThread: ICommentThread;
  readonly commentThreadItem: ICommentThreadItem;
  readonly commentThreadType: CommentThreadType;
  readonly createdBy: IProjectContributor | null;
  readonly elementName: string | null;
  readonly elementSegment: string | null;
  readonly isInlineThreadWithRemovedContent?: boolean;
  readonly isLastApprovedSuggestion?: boolean;
  readonly onApproveSuggestion: () => void;
  readonly onCancelEdit: () => void;
  readonly onDomReferenceCreated?: (ref: HTMLElement) => void;
  readonly onResolve: () => void;
  readonly onResolveThreadAfterSuggestionWasApproved: () => void;
  readonly onSubmitEdit: (content: ICommentThreadItemContentModel) => Promise<void>;
  readonly shouldDisplaySegmentInfo: boolean;
  readonly suggestionApprovedBy: IProjectContributor | null;
};

export const CommentThreadItem = ({
  onDomReferenceCreated,
  shouldDisplaySegmentInfo,
  allowEdit,
  allowResolve,
  allowUnresolve,
  approveInfo,
  canApproveSuggestion,
  commentThread,
  commentThreadItem,
  createdBy,
  elementName,
  elementSegment,
  commentThreadType,
  isLastApprovedSuggestion,
  suggestionApprovedBy,
  onResolve,
  onApproveSuggestion,
  onResolveThreadAfterSuggestionWasApproved,
  onCancelEdit,
  onSubmitEdit,
  isInlineThreadWithRemovedContent,
  className,
}: CommentThreadItemProps) => {
  const [isApproveAnimating, setIsApproveAnimating] = useState(false);

  const commentElementReferenceCreated = (element: HTMLElement | null) => {
    if (onDomReferenceCreated && element) {
      onDomReferenceCreated(element);
    }
  };

  const approveSuggestion = () => {
    setIsApproveAnimating(true);
    onApproveSuggestion();
  };

  const approveAnimationFinished = () => {
    setIsApproveAnimating(false);
    onResolveThreadAfterSuggestionWasApproved();
  };

  const itemIsSuggestion = isSuggestion(commentThreadItem);

  return (
    <div
      className={classNames('comment', className, {
        'comment--is-suggestion': itemIsSuggestion,
        'comment--is-editing': commentThreadItem.isEditing,
      })}
      ref={commentElementReferenceCreated}
      {...getDataUiElementAttribute(DataUiElement.CommentThreadItem)}
    >
      <CommentThreadItemHeader
        commentThread={commentThread}
        commentThreadItem={commentThreadItem}
        allowEdit={allowEdit}
        allowResolve={allowResolve}
        allowUnresolve={allowUnresolve}
        createdBy={createdBy}
        isLastApprovedSuggestion={isLastApprovedSuggestion}
        approveInfo={approveInfo}
        onApproveSuggestion={canApproveSuggestion ? approveSuggestion : undefined}
        onResolve={onResolve}
      />
      {shouldDisplaySegmentInfo && (
        <CommentThreadItemSegmentInfo
          elementName={elementName}
          commentThreadType={commentThreadType}
          elementSegment={elementSegment}
          isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
        />
      )}
      {commentThreadItem.isEditing ? ( // show edit form when editing comment message
        <CommentThreadItemEditor
          submitButtonText={commentThreadItem.isSubmitting ? 'Sending…' : 'Save'}
          commentThreadItem={commentThreadItem}
          isEditing={commentThreadItem.isEditing}
          isInputDisabled={false}
          isButtonDisabled={commentThreadItem.isSubmitting}
          onSubmit={onSubmitEdit}
          onCancel={onCancelEdit}
          areButtonsDisplayed
        />
      ) : (
        <CommentThreadItemContent
          commentThreadItem={commentThreadItem}
          commentThread={commentThread}
          isLastApprovedSuggestion={isLastApprovedSuggestion}
          suggestionApprovedBy={suggestionApprovedBy}
          isApproveAnimating={isApproveAnimating}
          onApproveAnimationFinished={approveAnimationFinished}
        />
      )}
    </div>
  );
};

CommentThreadItem.displayName = 'CommentThreadItem';
