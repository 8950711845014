import React, { createContext, useContext } from 'react';

interface IFlexingTileContextProps {
  readonly tileClassName: string | null;
}

const FlexingTileContext = createContext<string | null>(null);

export const FlexingTileContextProvider: React.FC<
  React.PropsWithChildren<IFlexingTileContextProps>
> = ({ tileClassName, children }) => {
  return (
    <FlexingTileContext.Provider value={tileClassName}>{children}</FlexingTileContext.Provider>
  );
};

FlexingTileContextProvider.displayName = 'AssetLibraryViewContextProvider';

export const useFlexingTileClassName = (): string | null => useContext(FlexingTileContext) ?? null;
