import { Action } from '../../../../../@types/Action.type.ts';
import {
  Project_CloneProjectValidation_Failed,
  Project_CloneProject_Failed,
  Project_CloneProject_Finished,
  Project_CloneProject_Started,
  Project_Clone_CloseModal,
} from '../../../constants/projectActionTypes.ts';

export const errorMessage = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Project_CloneProject_Started:
    case Project_CloneProject_Finished:
    case Project_Clone_CloseModal:
    case Project_CloneProjectValidation_Failed:
      return '';

    case Project_CloneProject_Failed:
      return action.payload.message;

    default:
      return state;
  }
};
