import classNames from 'classnames';
import React from 'react';
import { AgendaItemsListing } from '../../containers/AgendaItemsListing.tsx';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { CalendarPopupType } from '../Popups/CalendarPopupType.ts';

export interface IGroupedEvent {
  readonly date: string;
  readonly events: ReadonlyArray<ICalendarEvent>;
}

export interface IAgendaGroupOwnProps {
  readonly groupedEvent: IGroupedEvent;
}

export interface IAgendaGroupDispatchProps {
  readonly onDismiss: () => void;
}

export interface IAgendaGroupStateProps {
  readonly popupType: CalendarPopupType;
  readonly isToday: boolean;
}

type IAgendaGroupProps = IAgendaGroupStateProps & IAgendaGroupDispatchProps & IAgendaGroupOwnProps;

export const AgendaGroup: React.FC<IAgendaGroupProps> = ({
  groupedEvent,
  isToday,
  onDismiss,
  popupType,
}) => (
  <div
    className={classNames('agenda-group', {
      'agenda-group--today': isToday,
    })}
    key={groupedEvent.date}
  >
    <div className="agenda-group__title">
      {groupedEvent.date}
      {isToday && <span className="agenda-group__title--today">Today</span>}
    </div>
    <div>
      <AgendaItemsListing items={groupedEvent.events} onDismiss={onDismiss} popupType={popupType} />
    </div>
  </div>
);

AgendaGroup.displayName = 'AgendaGroup';
