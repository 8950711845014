import { isElementHorizontallyOverflowing, isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import { useAttachRef } from '@kontent-ai/hooks';
import { ComponentProps, Ref, RefCallback, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { Tooltip } from './Tooltip.tsx';

const getCombinedTooltipText = (
  text: string,
  tooltipText: TooltipPropsExtension['tooltipText'],
  isOverflowing: boolean,
): string => {
  if (isOverflowing) {
    if (tooltipText?.length) {
      return `${tooltipText}\n${text}`;
    }
    return text;
  }

  return tooltipText ?? '';
};

export type UseOptionalTooltipResult<TElement extends HTMLElement = HTMLElement> = {
  /** Spread this on a regular Tooltip component. */
  readonly tooltipProps: Pick<ComponentProps<typeof Tooltip>, 'tooltipText'>;
  /** You **must** pass this to the truncated text element. */
  readonly truncatedElementRef: RefCallback<TElement>;
};

export const useOptionalTooltip = <TElement extends HTMLElement = HTMLElement>(
  truncatedElementRef: Ref<TElement>,
  text: string,
  tooltipText?: TooltipPropsExtension['tooltipText'],
): UseOptionalTooltipResult<TElement> => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { refObject, refToForward } = useAttachRef(truncatedElementRef);

  useResizeObserver({
    ref: refObject,
    onResize: () =>
      setIsOverflowing(
        isElementHorizontallyOverflowing(refObject.current) ||
          isElementVerticallyOverflowing(refObject.current),
      ),
  });

  const combinedTooltipText = getCombinedTooltipText(text, tooltipText, isOverflowing);

  const tooltipProps = {
    tooltipText: combinedTooltipText,
  };

  return {
    tooltipProps,
    truncatedElementRef: refToForward,
  };
};
