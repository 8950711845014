import { Icons } from '@kontent-ai/component-library/Icons';
import { useEffect } from 'react';
import { modalOpened } from '../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../_shared/constants/modalDialogType.ts';
import {
  ContentAssignedToYouRoute,
  ContentRecentlyEditedByYouRoute,
  EnvironmentRouteParams,
  YourTasksRoute,
} from '../../../_shared/constants/routePaths.ts';
import { YourContentTabName } from '../../../_shared/constants/yourContentTabName.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiNavAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { prepareNewContentItemDialogForYourContent } from '../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import {
  initAssignedContentItemsToMe,
  initContentRecentlyEditedByYou,
  initYourTasks,
} from '../actions/thunkYourContentActions.ts';
import { yourContentPageLeft } from '../actions/yourContentActions.ts';
import { YourContent as YourContentComponent } from '../components/YourContent.tsx';
import { getYourTasksCount } from '../selectors/getYourTasks.ts';

const renderYourTasksCount = (yourTasksCount: number | undefined, hasMore: boolean): string => {
  if (yourTasksCount === undefined) {
    return '';
  }

  return `(${yourTasksCount}${hasMore ? '+' : ''})`;
};

const tabs = [
  {
    id: YourContentTabName.RecentlyEditedByYou,
    label: 'Recently edited by you',
    leadingElement: <Icons.Clock />,
    trailingElement: null,
    ...getDataUiNavAttribute(DataUiAppName.YourContentRecentlyEditedByYou),
    ...getDataUiElementAttribute(DataUiElement.NavigationTab),
  },
  {
    id: YourContentTabName.AssignedToYou,
    label: 'Assigned to you',
    leadingElement: <Icons.User />,
    trailingElement: null,
    ...getDataUiNavAttribute(DataUiAppName.YourContentAssignedToYou),
    ...getDataUiElementAttribute(DataUiElement.NavigationTab),
  },
  {
    id: YourContentTabName.YourTasks,
    label: 'Your tasks',
    leadingElement: <Icons.CbCheckPreview />,
    trailingElement: null,
    ...getDataUiNavAttribute(DataUiAppName.YourContentYourTasks),
    ...getDataUiElementAttribute(DataUiElement.NavigationTab),
  },
];

export const YourContent = () => {
  const dispatch = useDispatch();
  const { projectId } = useSelector(getCurrentProject);

  const assignedItemsCount = useSelector((s) =>
    s.data.assignedContentItems.loadingStatus === LoadingStatus.Loaded
      ? s.data.assignedContentItems.items.count()
      : undefined,
  );
  const yourTasksCount = useSelector(getYourTasksCount);
  const hasMoreTasks = useSelector((s) => s.yourContentApp.yourTasks.hasMore);

  const recentlyEditedRoute = buildPath<EnvironmentRouteParams>(ContentRecentlyEditedByYouRoute, {
    projectId,
  });
  const assignedRoute = buildPath<EnvironmentRouteParams>(ContentAssignedToYouRoute, {
    projectId,
  });
  const yourTasksRoute = buildPath<EnvironmentRouteParams>(YourTasksRoute, { projectId });

  const tabIdToRouteMap: Record<YourContentTabName, string> = {
    [YourContentTabName.RecentlyEditedByYou]: recentlyEditedRoute,
    [YourContentTabName.AssignedToYou]: assignedRoute,
    [YourContentTabName.YourTasks]: yourTasksRoute,
  };

  const getTabLabelSuffix = (id: YourContentTabName): string => {
    switch (id) {
      case YourContentTabName.AssignedToYou:
        return assignedItemsCount ? ` (${assignedItemsCount})` : '';
      case YourContentTabName.YourTasks:
        return ` ${renderYourTasksCount(yourTasksCount, hasMoreTasks)}`;
      default:
        return '';
    }
  };

  const enhancedTabs = tabs.map(({ id, ...tab }) => ({
    ...tab,
    id: tabIdToRouteMap[id],
    label: `${tab.label}${getTabLabelSuffix(id)}`,
    to: tabIdToRouteMap[id],
  }));

  const canCreate = useSelector(
    (s) =>
      !getAllActiveLanguagesForCurrentUserInAnyCollection(
        s.data.user,
        projectId,
        s.data.languages,
        Capability.CreateContent,
      ).isEmpty(),
  );

  const onCreateNew = () =>
    dispatch(() => {
      dispatch(
        prepareNewContentItemDialogForYourContent({
          allowedContentTypeIds: null,
        }),
      );
      dispatch(modalOpened(ModalDialogType.NewContentItemDialog));
    });

  useThunkPromise(initContentRecentlyEditedByYou);
  useThunkPromise(initAssignedContentItemsToMe);
  useThunkPromise(initYourTasks);

  useEffect(() => {
    return () => {
      dispatch(yourContentPageLeft());
    };
  }, []);

  return (
    <YourContentComponent canCreate={canCreate} onCreateNew={onCreateNew} tabs={enhancedTabs} />
  );
};
