import React from 'react';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { NotificationBar } from '../../../_shared/containers/NotificationBar.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAccountCapabilities } from '../../../data/models/user/CurrentUserInfo.ts';
import { UserEmailPreferences } from '../containers/UserEmailPreferences.tsx';
import { UserProfileForm } from '../containers/UserProfileForm.tsx';
import { ChangePassword } from './ChangePassword.tsx';

type IUserProfileEditorProps = {
  readonly accountCapabilities: IAccountCapabilities;
  readonly onRequestPasswordChange: () => void;
};

export const UserProfileEditor: React.FC<IUserProfileEditorProps> = ({
  accountCapabilities,
  onRequestPasswordChange,
}) => {
  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.UserProfile)}>
      <HtmlPageTitle appName={AppNames.UserProfile} />
      <div className="canvas__workspace">
        <section className="canvas__content">
          <div className="canvas__notifications">
            <NotificationBar />
          </div>
          <div className="canvas__content-pane canvas__content-pane--is-not-project-specific">
            <div className="flex-grid__pane">
              <div className="flex-grid__item">
                <UserProfileForm />
              </div>
              {accountCapabilities.canChangePassword && (
                <div className="flex-grid__item">
                  <ChangePassword onRequestPasswordChange={onRequestPasswordChange} />
                </div>
              )}
              <div className="flex-grid__item">
                <UserEmailPreferences />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

UserProfileEditor.displayName = 'UserProfileEditor';
