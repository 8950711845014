import {
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
} from '../../applications/richText/plugins/apiLimitations/api/editorLimitationUtils.ts';

export const areItemLinkTypesEnabled = (
  allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature>,
  allowedTextFormatting: ReadonlySet<TextFormattingFeature>,
  allowedTableTextFormatting: ReadonlySet<TextFormattingFeature>,
): boolean => {
  const itemLinksAllowedInText =
    allowedBlocks.has(TopLevelBlockCategoryFeature.Text) &&
    allowedTextFormatting.has(TextFormattingFeature.Link);
  const itemLinksAllowedInTables =
    allowedBlocks.has(TopLevelBlockCategoryFeature.Tables) &&
    allowedTableTextFormatting.has(TextFormattingFeature.Link);

  return itemLinksAllowedInText || itemLinksAllowedInTables;
};
