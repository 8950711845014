export const selectedAssetsMaxCount = 99;
export const tooManyAssetsSelectedMessage = `Select less than ${
  selectedAssetsMaxCount + 1
} assets.`;
export const cannotDeleteSelectedAssets =
  'Some of the selected assets are in collections where you can’t delete assets.';
export const cannotEditSelectedAssets =
  'Some of the selected assets are in collections where you can’t edit assets.';
export const cannotCreateAssetsInCollection = 'Your role can’t create assets in this collection.';
export const cannotEditAssetsInCollection = 'Your role can’t edit assets in this collection.';
export const loadingAssetUsage = 'Loading usages...';
export const assetHasNoUsage = 'This asset is not used in any content item.';
export const MaxAssetFullTextSearchPhraseLength = 200;
export const UnknownFolderNamePlaceholder = 'Unknown folder';
export const assetDropzoneText = 'Drop assets here.';
export const assetDropzoneNoPermissionText = 'You don’t have permission to upload assets here.';
