import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  CommentEventTypes,
  ICommentsIntercomData,
} from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import {
  createCommentContentServerModel,
  isCommentContent,
} from '../../../../models/comments/Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import { isSuggestionContent } from '../../../../models/comments/Suggestion.ts';
import {
  ContentItemEditing_CommentThread_InlineSubmittingFinished,
  ContentItemEditing_CommentThread_InlineSubmittingStarted,
} from '../../constants/contentItemEditingActionTypes.ts';
import { getCommentThreadTopLevelElementId } from '../../utils/getCommentThreadTopLevelElementId.ts';

const commentThreadInlineSubmittingStarted = (threadId: Uuid) =>
  ({
    type: ContentItemEditing_CommentThread_InlineSubmittingStarted,
    payload: { threadId },
  }) as const;

const commentThreadInlineSubmittingFinished = (threadId: Uuid, updatedThread: ICommentThread) =>
  ({
    type: ContentItemEditing_CommentThread_InlineSubmittingFinished,
    payload: {
      threadId,
      updatedThread,
    },
  }) as const;

export type SaveNewInlineCommentThreadToServerActionsType = ReturnType<
  typeof commentThreadInlineSubmittingStarted | typeof commentThreadInlineSubmittingFinished
>;

interface ISaveNewInlineCommentThreadToServerDependencies {
  readonly commentRepository: ICommentRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

function getCommentIntercomEventPayload(
  commentThread: ICommentThread,
  commentType: CommentThreadItemType,
  contentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
): ICommentsIntercomData {
  const contentItemTypeElements = contentItemTypes
    .toArray()
    .flatMap((type) => type.contentElements);
  const typeElement = contentItemTypeElements.find(
    (element) => element.elementId === commentThread.elementId,
  );

  return {
    action:
      commentType === CommentThreadItemType.Suggestion
        ? CommentEventTypes.SuggestionCreated
        : CommentEventTypes.CommentCreated,
    'element-type': typeElement?.type,
  };
}

export const createSaveNewInlineCommentThreadToServerAction =
  (deps: ISaveNewInlineCommentThreadToServerDependencies) =>
  (
    unsavedThreadId: Uuid,
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        editedContentItemVariantComments,
        loadedContentItemTypes,
        editedContentItemVariantElements,
      },
    } = getState();

    const thread = editedContentItemVariantComments.commentThreads.find(
      (t: ICommentThread) => t.id === unsavedThreadId,
    );

    const isContentValid =
      (isCommentContent(content) && content.content.hasText()) ||
      (isSuggestionContent(content) && !!content.suggestedText);

    if (!thread || !editedContentItem || !editedContentItemVariant || !isContentValid) {
      return;
    }

    dispatch(commentThreadInlineSubmittingStarted(unsavedThreadId));

    const { itemId, variantId } = editedContentItemVariant.id;

    const contentToSave = isCommentContent(content)
      ? createCommentContentServerModel(content.content)
      : null;
    const suggestedText = isSuggestionContent(content) ? content.suggestedText : null;
    const threadItems =
      content.type === CommentThreadItemType.Comment
        ? [
            {
              type: content.type,
              content: contentToSave,
            },
          ]
        : [
            {
              type: content.type,
              suggestedText,
            },
          ];

    const topLevelElementId = getCommentThreadTopLevelElementId(
      editedContentItem,
      editedContentItemVariantElements,
      thread,
    );

    const newCommentThreadServerModel = await deps.commentRepository.createCommentThread(
      itemId,
      variantId,
      {
        id: thread.id,
        contentComponentId: thread.contentComponentId,
        elementId: thread.elementId,
        elementSegment: thread.elementSegment,
        externalSegmentId: thread.externalSegmentId,
        threadType: thread.threadType,
        threadItems,
        topLevelElementId,
      },
    );
    const newCommentThread = createCommentThreadDomainModel(newCommentThreadServerModel);

    const eventPayload = getCommentIntercomEventPayload(
      newCommentThread,
      type,
      loadedContentItemTypes,
    );
    dispatch(deps.trackUserEventWithData(TrackedEvent.Comments, eventPayload));

    dispatch(commentThreadInlineSubmittingFinished(unsavedThreadId, newCommentThread));
  };
