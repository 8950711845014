import { useState } from 'react';

export const useUserFeedbackInput = () => {
  const [ease, setEase] = useState('');
  const [requirements, setRequirements] = useState('');
  const [optionalComment, setOptionalComment] = useState('');

  return {
    ease,
    onEaseChange: setEase,
    onOptionalCommentChange: setOptionalComment,
    onRequirementsChange: setRequirements,
    optionalComment,
    requirements,
  };
};
