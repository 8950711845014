import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithData } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IAssetLimitsIntercomData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetFileTypeOption } from '../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { IAssetTypeElementData } from '../../models/elements/AssetTypeElementData.ts';
import { IRichTextTypeElementData } from '../../models/elements/RichTextTypeElementData.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { isAssetTypeElement, isRichTextTypeElement } from '../../types/typeElementTypeGuards.ts';

const convertToAssetLimitsIntercomData = (
  elementData: IAssetTypeElementData | IRichTextTypeElementData,
): IAssetLimitsIntercomData => {
  return {
    'element-id': elementData.elementId,
    'max-width': elementData.maxWidth,
    'min-width': elementData.minWidth,
    'max-height': elementData.maxHeight,
    'min-height': elementData.minHeight,
    'file-size': elementData.fileSize,
    'file-size-unit-option': elementData._fileSizeUnitOption,
    'file-type': elementData.fileType,
  } as IAssetLimitsIntercomData;
};

const wasAnyImageLimitFilled = (
  newElement: IAssetTypeElementData | IRichTextTypeElementData,
): boolean => {
  return (
    newElement.fileSize !== null ||
    newElement.fileType === AssetFileTypeOption.ResponsiveImages ||
    newElement.maxHeight !== null ||
    newElement.minHeight !== null ||
    newElement.maxWidth !== null ||
    newElement.minWidth !== null
  );
};

const wasAnyImageLimitModified = (
  newElement: IAssetTypeElementData | IRichTextTypeElementData,
  originalElement: IAssetTypeElementData | IRichTextTypeElementData,
): boolean => {
  return (
    originalElement.fileSize !== newElement.fileSize ||
    (newElement.fileSize !== null &&
      originalElement._fileSizeUnitOption !== newElement._fileSizeUnitOption) ||
    originalElement.fileType !== newElement.fileType ||
    originalElement.maxHeight !== newElement.maxHeight ||
    originalElement.minHeight !== newElement.minHeight ||
    originalElement.maxWidth !== newElement.maxWidth ||
    originalElement.minWidth !== newElement.minWidth
  );
};

const fireTrackingAssetLimitationChangedEvent = (
  trackUserEvent: TrackUserEventWithData,
  newElement: IAssetTypeElementData | IRichTextTypeElementData,
  originalElement: IAssetTypeElementData | IRichTextTypeElementData,
): void => {
  if (!newElement || (originalElement && originalElement.type !== newElement.type)) {
    return;
  }
  let limitsChanged = false;

  // content type element was created -> check if any limit related to images was filled
  if (originalElement) {
    limitsChanged = wasAnyImageLimitModified(newElement, originalElement);
  } else {
    limitsChanged = wasAnyImageLimitFilled(newElement);
  }

  if (newElement.type === ElementType.Asset && limitsChanged) {
    trackUserEvent(
      TrackedEvent.AssetLimitedInAssetElement,
      convertToAssetLimitsIntercomData(newElement),
    );
  } else if (newElement.type === ElementType.RichText && limitsChanged) {
    trackUserEvent(
      TrackedEvent.AssetLimitedInRichText,
      convertToAssetLimitsIntercomData(newElement),
    );
  }
};

export type ITrackAssetLimitConfigurationChanged = (
  trackEvent: TrackUserEventWithData,
  newElements: ReadonlyArray<IBaseTypeElementData>,
  originalElements?: ReadonlyArray<IBaseTypeElementData>,
) => void;

export const trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged = (
  trackEvent: TrackUserEventWithData,
  newElements: ReadonlyArray<IBaseTypeElementData>,
  originalElements: ReadonlyArray<IBaseTypeElementData> = [],
): void => {
  newElements.map((element) => {
    if (!element || (element.type !== ElementType.Asset && element.type !== ElementType.RichText)) {
      return;
    }
    const originalElement = originalElements.find(
      (original) => !!original && original.elementId === element.elementId,
    );

    if (isAssetTypeElement(element) && isAssetTypeElement(originalElement)) {
      fireTrackingAssetLimitationChangedEvent(trackEvent, element, originalElement);
    } else if (isRichTextTypeElement(element) && isAssetTypeElement(originalElement)) {
      fireTrackingAssetLimitationChangedEvent(trackEvent, element, originalElement);
    }
  });
};
