import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';

export type IEnsureDefaultVariantsAction = (
  listingItems: ReadonlyArray<IListingContentItem>,
  contentTypes: ReadonlyArray<ICompiledContentType>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface IEnsureDefaultVariantsActionDependencies {
  readonly loadDefaultListingItems: ILoadListingItemsAction;
}

export const createEnsureDefaultVariantsAction =
  ({
    loadDefaultListingItems,
  }: IEnsureDefaultVariantsActionDependencies): IEnsureDefaultVariantsAction =>
  (listingItems, contentTypes, abortSignal) =>
  async (dispatch, getState) => {
    const canViewDefaultVariantLanguage = hasCapabilityInLanguage(
      getState(),
      Capability.ViewContent,
      DefaultVariantId,
    );

    if (canViewDefaultVariantLanguage) {
      const typeIdsWithNonLocalizableElements = new Set(
        contentTypes
          .filter((type) => type.contentElements.some((element) => element.isNonLocalizable))
          .map((type) => type.id),
      );

      const itemIdsWithNonLocalizableElements = listingItems
        .filter((item) => typeIdsWithNonLocalizableElements.has(item.item.typeId))
        .map((item) => item.item.id);

      await dispatch(loadDefaultListingItems(itemIdsWithNonLocalizableElements, abortSignal));
    }
  };
