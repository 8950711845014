import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { IStore } from '../../../../stores/IStore.type.ts';
import {
  isRestoringFromArchivedStep,
  isUnpublishing,
} from '../../utils/changeWorkflowStepModalActionUtils.ts';
import { isWorkflowStepModalOpenedInItemEditing } from '../../utils/changeWorkflowStepModalUtils.ts';

export const getFromWorkflowStep = (state: IStore): IWorkflowStep | null => {
  const {
    contentApp: { changeWorkflowStepModalData },
    workflowsApp: {
      editorUi: { archivedWorkflowStep, publishedWorkflowStep },
    },
  } = state;

  if (isUnpublishing(changeWorkflowStepModalData.workflowStepAction)) {
    return publishedWorkflowStep;
  }
  if (isRestoringFromArchivedStep(changeWorkflowStepModalData.workflowStepAction)) {
    return archivedWorkflowStep;
  }

  return isWorkflowStepModalOpenedInItemEditing(changeWorkflowStepModalData)
    ? getFromWorkflowStepForItemEditing(state)
    : null;
};

const getFromWorkflowStepForItemEditing = (state: IStore): IWorkflowStep | null => {
  const {
    contentApp: { editedContentItemVariant },
  } = state;

  return editedContentItemVariant?.assignment.workflowStatus ?? null;
};
