import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import React, { useMemo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { notInCollectionSelectItem } from '../../../assets/constants/assetNullCollectionConstants.ts';

interface IProps {
  readonly collections: ReadonlyArray<ICollection>;
  readonly selectedCollections: ReadonlyArray<Uuid | null>;
  readonly onFilterChanged: (selectedCollections: ReadonlyArray<Uuid | null>) => void;
}

const toSelectOption = (collection: ICollection): IBaseSelectItem => ({
  id: collection.id,
  label: collection.name,
});

export const ConfigurableCollectionMultipleOptionSelect: React.FC<IProps> = ({
  collections,
  selectedCollections,
  onFilterChanged,
}) => {
  const items = useMemo(
    () => [notInCollectionSelectItem, ...collections.map(toSelectOption)],
    [collections],
  );

  const selectedCollectionIds = useMemo(
    () =>
      selectedCollections
        .filter((id) => id === null || collections.some((collection) => collection.id === id))
        .map((id) => id ?? notInCollectionSelectItem.id),
    [collections, selectedCollections],
  );

  return (
    <MultiSelect
      placeholder="Select a collection"
      items={items}
      selectedItemIds={selectedCollectionIds}
      onSelectionChange={(selectedIds) =>
        onFilterChanged(
          Array.from(selectedIds).map((id) => (id !== notInCollectionSelectItem.id ? id : null)),
        )
      }
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
    />
  );
};

ConfigurableCollectionMultipleOptionSelect.displayName =
  'ConfigurableCollectionMultipleOptionSelect';
