import { UnreachableCaseException } from '@kontent-ai/errors';
import {
  colorAlertHover,
  colorAlertIcon,
  colorBackgroundDisabled,
  colorBorderDefault,
  colorIconDisabled,
  colorPrimary,
  colorPrimaryHover,
} from '../../../../tokens/decision/colors.ts';
import { BaseColor, Color } from '../../../../tokens/quarks/colors.ts';
import { RadioGroupState } from '../../types.ts';

export type RadioButtonIconColors = {
  readonly backgroundColor: Color;
  readonly fillColor: Color;
  readonly fillColorHover: Color;
  readonly selectedStrokeColor: Color;
  readonly selectedStrokeColorHover: Color;
  readonly strokeColor: Color;
};

const defaultColors: RadioButtonIconColors = {
  backgroundColor: BaseColor.White,
  fillColor: colorPrimary,
  fillColorHover: colorPrimaryHover,
  selectedStrokeColor: colorPrimary,
  selectedStrokeColorHover: colorPrimaryHover,
  strokeColor: colorBorderDefault,
};

const disabledColors: RadioButtonIconColors = {
  backgroundColor: colorBackgroundDisabled,
  fillColor: colorIconDisabled,
  fillColorHover: colorBorderDefault,
  selectedStrokeColor: colorBorderDefault,
  selectedStrokeColorHover: colorBorderDefault,
  strokeColor: colorBorderDefault,
};

const alertColors: RadioButtonIconColors = {
  backgroundColor: BaseColor.White,
  fillColor: colorAlertIcon,
  fillColorHover: colorAlertHover,
  selectedStrokeColor: colorAlertIcon,
  selectedStrokeColorHover: colorAlertHover,
  strokeColor: colorAlertIcon,
};

export const getRadioButtonIconColors = (state: RadioGroupState) => {
  switch (state) {
    case 'default':
      return defaultColors;
    case 'disabled':
      return disabledColors;
    case 'alert':
      return alertColors;
    default:
      throw UnreachableCaseException(state);
  }
};
