import React from 'react';
import { StyledTextSkeleton } from './components/StyledTextSkeleton.tsx';
import { SkeletonTypeToSkeletonBorderRadiusMap } from './types.ts';

type TextSkeletonProps = Readonly<{
  width: number | string;
  children?: never;
}>;

export const TextSkeleton = React.forwardRef<HTMLElement, TextSkeletonProps>((props, ref) => (
  <StyledTextSkeleton
    $width={props.width}
    borderRadius={SkeletonTypeToSkeletonBorderRadiusMap.text}
    ref={ref}
  />
));

TextSkeleton.displayName = 'TextSkeleton';
