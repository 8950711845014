import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

export interface IWorkflowListingOperationStatus {
  readonly message: string;
  readonly messageStyle: StatusMessageStyle;
  readonly operationType: WorkflowListingOperationType;
  readonly affectedWorkflowId: Uuid | null;
}

export enum WorkflowListingOperationType {
  NoOperation = 'nooperation',
  Deleting = 'deleting',
  Deleted = 'deleted',
  Restoring = 'restoring',
  Restored = 'restored',
}

export const initialWorkflowListingOperationStatus: IWorkflowListingOperationStatus = {
  message: 'Workflows',
  messageStyle: StatusMessageStyle.Headline,
  operationType: WorkflowListingOperationType.NoOperation,
  affectedWorkflowId: null,
};
