import { Action } from '../../../../@types/Action.type.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduleToPublishMethod_Changed,
  ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed,
  ChangeWorkflowStep_WorkflowStep_Selected,
} from '../constants/changeWorkflowStepActionTypes.ts';
import { ScheduleMethod } from '../constants/scheduleMethods.ts';
import { isScheduling } from '../utils/changeWorkflowStepModalActionUtils.ts';

const defaultState = ScheduleMethod.Now;

export function scheduleMethod(state = defaultState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_WorkflowStep_Selected:
      return isScheduling(action.payload.workflowStepAction)
        ? ScheduleMethod.Schedule
        : ScheduleMethod.Now;

    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.scheduleMethod;

    case ChangeWorkflowStep_ScheduleToPublishMethod_Changed:
    case ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed:
      return action.payload.method;

    default:
      return state;
  }
}
