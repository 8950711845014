import { Action } from '../../../../../@types/Action.type.ts';
import {
  Sitemap_HideCreateNewForm,
  Sitemap_InitSitemap,
  Sitemap_NodeCreated,
  Sitemap_ShowCreateNewForm,
} from '../../constants/sitemapActionTypes.ts';

export const isCreateNewFormDisplayed = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Sitemap_ShowCreateNewForm:
      return true;

    case Sitemap_HideCreateNewForm:
    case Sitemap_NodeCreated:
    case Sitemap_InitSitemap:
      return false;

    default:
      return state;
  }
};
