import { combineReducers } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { Task } from '../../../_shared/models/Task.ts';
import { byId } from './reducers/byId.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';

export type TasksData = {
  readonly byId: ReadonlyMap<Uuid, Task>;
  readonly loadingStatus: LoadingStatus;
};

export const tasks = combineReducers({
  byId,
  loadingStatus,
});
