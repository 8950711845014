import { VerticalMenuItem } from '@kontent-ai/component-library/VerticalMenu';
import { useAttachRef } from '@kontent-ai/hooks';
import React, { RefAttributes } from 'react';
import { useLocation } from 'react-router';
import useResizeObserver from 'use-resize-observer';
import { HorizontalTabsCell } from './HorizontalTabsCell.tsx';
import { CompactHorizontalTabs } from './components/CompactHorizontalTabs.tsx';
import { ExpandedHorizontalTabs } from './components/ExpandedHorizontalTabs.tsx';

type ResponsiveHorizontalTabsProps = {
  readonly children?: never;
  readonly containerRef: React.RefObject<HTMLElement>;
  readonly renderCompactTabs: (ref: React.Ref<HTMLDivElement>) => React.ReactElement;
  readonly renderExpandedTabs: (ref: React.Ref<HTMLDivElement>) => React.ReactElement;
};

const ResponsiveHorizontalTabs = React.forwardRef<HTMLDivElement, ResponsiveHorizontalTabsProps>(
  ({ containerRef, renderCompactTabs, renderExpandedTabs }, ref) => {
    const { width: containerWidth = Number.MAX_VALUE } = useResizeObserver({ ref: containerRef });
    const [shouldRenderCompactTabs, setShouldRenderCompactTabs] = React.useState(false);
    const lastKnownHorizontalTabsWidth = React.useRef<number>(0);
    const { refObject: horizontalTabsRef, refToForward } = useAttachRef(ref);

    React.useLayoutEffect(() => {
      const horizontalTabsWidth = horizontalTabsRef.current?.scrollWidth ?? 0;

      if (!shouldRenderCompactTabs) {
        lastKnownHorizontalTabsWidth.current = horizontalTabsWidth;
      }

      setShouldRenderCompactTabs(lastKnownHorizontalTabsWidth.current > containerWidth);
    }, [horizontalTabsRef, containerWidth, shouldRenderCompactTabs]);

    if (shouldRenderCompactTabs) {
      return renderCompactTabs(refToForward);
    }

    return renderExpandedTabs(refToForward);
  },
);

ResponsiveHorizontalTabs.displayName = 'ResponsiveHorizontalTabs';

type CompactHorizontalTabsWithRouterProps<
  HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>,
> = {
  readonly children?: never;
  readonly getItemProps: (item: HorizontalTabItem) => {
    readonly label: string;
    readonly pathname: string;
  };
  readonly items: readonly HorizontalTabItem[];
};

const CompactHorizontalTabsWithRouter = React.forwardRef(
  <HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>>(
    { getItemProps, items, ...restProps }: CompactHorizontalTabsWithRouterProps<HorizontalTabItem>,
    ref: React.Ref<HTMLDivElement>,
  ): ReturnType<React.FC<CompactHorizontalTabsWithRouterProps<HorizontalTabItem>>> => {
    const location = useLocation();
    const selectedTab = items.find((item) =>
      location.pathname.startsWith(getItemProps(item).pathname),
    );

    return selectedTab ? (
      <CompactHorizontalTabs
        getItemProps={getItemProps}
        items={items}
        ref={ref}
        selectedItem={selectedTab}
        {...restProps}
      />
    ) : null;
  },
);

CompactHorizontalTabsWithRouter.displayName = 'CompactHorizontalTabsWithRouter';

type HorizontalTabsProps<HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>> = {
  readonly children?: never;
  readonly containerRef: React.RefObject<HTMLElement>;
  readonly getItemProps: (item: HorizontalTabItem) => {
    readonly label: string;
    readonly pathname: string;
  };
  readonly items: readonly HorizontalTabItem[];
};

const HorizontalTabsComponent = React.forwardRef(
  <HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>>(
    {
      children,
      containerRef,
      getItemProps,
      items,
      ...restProps
    }: HorizontalTabsProps<HorizontalTabItem>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ): ReturnType<React.FC<HorizontalTabsProps<HorizontalTabItem>>> =>
    items.length ? (
      <ResponsiveHorizontalTabs
        ref={forwardedRef}
        containerRef={containerRef}
        renderCompactTabs={(ref) => (
          <CompactHorizontalTabsWithRouter
            ref={ref}
            getItemProps={getItemProps}
            items={items}
            {...restProps}
          />
        )}
        renderExpandedTabs={(ref) => (
          <ExpandedHorizontalTabs
            ref={ref}
            items={items}
            renderItem={(tab) => {
              const { label, pathname, ...otherProps } = getItemProps(tab);
              return <HorizontalTabsCell label={label} to={pathname} {...otherProps} />;
            }}
            {...restProps}
          />
        )}
      />
    ) : null,
);

HorizontalTabsComponent.displayName = 'HorizontalTabs';

export const HorizontalTabs = HorizontalTabsComponent as (<
  HorizontalTabItem extends VerticalMenuItem<HorizontalTabItem>,
>(
  props: HorizontalTabsProps<HorizontalTabItem> & RefAttributes<HTMLElement>,
) => ReturnType<React.FC<HorizontalTabsProps<HorizontalTabItem>>>) &
  React.FC;
