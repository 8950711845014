import Immutable from 'immutable';
import {
  FailedItemServerModel,
  ItemListOperationResultServerModel,
} from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { ServerApiErrorCode } from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { BulkActionFinishedPayload } from '../actions/contentItemInventoryActions.ts';

export const createBulkActionResult = (
  operationResult: ItemListOperationResultServerModel,
  variantId: Uuid,
  unmodifiedItemsErrorCode?: ServerApiErrorCode,
): BulkActionFinishedPayload => {
  const unmodifiedItemsFilter = unmodifiedItemsErrorCode
    ? (failedItem: FailedItemServerModel) => failedItem.code === unmodifiedItemsErrorCode
    : () => false;

  return {
    successfulIds: Immutable.Set(operationResult.modifiedItemIds),
    failedIds: Immutable.Set(
      operationResult.failedItems.filter((i) => !unmodifiedItemsFilter(i)).map((i) => i.id),
    ),
    unmodifiedIds: Immutable.Set(
      operationResult.failedItems.filter(unmodifiedItemsFilter).map((i) => i.id),
    ),
    variantId,
  };
};
