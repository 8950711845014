import { ScrollAlignment, getParent, isElement, scrollElementsToView } from '@kontent-ai/DOM';
import { useAttachRef, usePrevious } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { useContext, useEffect, useLayoutEffect, useRef, forwardRef, memo } from 'react';
import useResizeObserver from 'use-resize-observer';
import { ScrollOptionsContext } from '../../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { useOnClickOutside } from '../../../../../../_shared/hooks/useOnClickOutside.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataAttributes,
  getDataAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../../../../../_shared/utils/usersUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { ElementAttributes } from '../../../../constants/elementAttributes.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { ISuggestion, isSuggestion } from '../../../../models/comments/Suggestion.ts';
import { dateComparer } from '../../../../utils/commentUtils.ts';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';
import { CommentThreadItem } from '../../containers/comments/CommentThreadItem.tsx';
import { Reply } from './Reply.tsx';
import { ResolveUndoDialog } from './ResolveUndoDialog.tsx';
import { TipsFromKontentId } from './threadItem/CommentThreadItemHeader.tsx';
import { NewCommentThreadItem } from './threadItem/NewCommentThreadItem.tsx';

export function getThreadHeight(
  threadRef: React.RefObject<HTMLDivElement> | undefined,
): number | null {
  if (!threadRef) {
    return null;
  }

  const threadElement = threadRef.current;
  if (threadElement instanceof HTMLDivElement) {
    const threadElementRect = threadElement.getBoundingClientRect();
    const threadHeight = Math.round(threadElementRect.height);
    return threadHeight;
  }

  return null;
}

type CommentThreadProps = {
  readonly canSuggest: boolean;
  readonly className: string;
  readonly commentThread: ICommentThread;
  readonly isFocused: boolean;
  readonly isInlineThreadWithRemovedContent?: boolean;
  readonly isResolved: boolean;
  readonly onBlur: () => void;
  readonly onBlurNewItemText: (isCommentPending: boolean) => void;
  readonly onCancelNewItem: () => void;
  readonly onFocus: () => void;
  readonly onFocused?: () => void;
  readonly onResized?: (threadId: Uuid, oldHeight: number, newHeight: number) => void;
  readonly onResolveUndo: () => void;
  readonly onResolveUndoCancel: () => void;
  readonly onStartNewItem: (type: CommentThreadItemType) => void;
  readonly onSubmitNewItem: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly resolvedBy: IProjectContributor | null;
  readonly showReferenceForInlineThreads: boolean;
  readonly type: CommentThreadItemType | undefined;
};

const getUserName = (
  resolvedById: string | null,
  resolvedBy: IProjectContributor | null,
): string => {
  if (resolvedById === TipsFromKontentId) {
    return 'Kentico';
  }

  return formatUserName(resolvedBy);
};

export const CommentThread = memo(
  forwardRef<HTMLDivElement, CommentThreadProps>(
    (
      {
        canSuggest,
        className,
        commentThread,
        isFocused,
        isInlineThreadWithRemovedContent,
        isResolved,
        onBlur,
        onBlurNewItemText,
        onCancelNewItem,
        onFocus,
        onFocused,
        onResized,
        onResolveUndo,
        onResolveUndoCancel,
        onStartNewItem,
        onSubmitNewItem,
        resolvedBy,
        showReferenceForInlineThreads,
        type,
      },
      ref,
    ) => {
      const newCommentThreadItemRef = useRef<NewCommentThreadItem>(null);
      const { refObject: commentThreadRef, refToForward } = useAttachRef(ref);

      useEffect(() => {
        if (isFocused) {
          onFocused?.();
        }
      }, [isFocused, onFocused]);

      const { scrollOptions } = useContext(ScrollOptionsContext);

      useEffect(() => {
        if (commentThread.isReplying) {
          // Focus needs to be initiated first because change in focus may discard scrolling
          newCommentThreadItemRef.current?.focus();

          if (commentThreadRef.current) {
            scrollElementsToView([commentThreadRef.current], {
              ...scrollOptions,
              // We prefer bottom alignment in case of reply to prevent it hiding behind the fold in case the comment thread is long
              alignment: ScrollAlignment.Bottom,
            });
          }
        }
      }, [commentThread.isReplying, commentThreadRef, scrollOptions]);

      const { height = 0 } = useResizeObserver({ ref: commentThreadRef });
      const previousHeight = usePrevious(height);

      useLayoutEffect(() => {
        if (height !== previousHeight) {
          onResized?.(commentThread.id, previousHeight, height);
        }
      }, [commentThread.id, height, previousHeight, onResized]);

      const handleClickOutside = (event: MouseEvent): void => {
        if (!isFocused || !isElement(event.target)) {
          return;
        }

        const deepestElementWithCommentBlurModifier = event.target.hasAttribute(
          ElementAttributes.BlurCommentThreadOnClick,
        )
          ? event.target
          : getParent(event.target, (element) =>
              element.hasAttribute(ElementAttributes.BlurCommentThreadOnClick),
            );

        const isBlurHandlingDisabled =
          deepestElementWithCommentBlurModifier?.getAttribute(
            ElementAttributes.BlurCommentThreadOnClick,
          ) === 'false';

        if (!isBlurHandlingDisabled) {
          onBlur();
        }
      };

      useOnClickOutside(commentThreadRef, handleClickOutside);

      const firstThreadItem = commentThread.threadItems.first();
      if (!firstThreadItem) {
        return null;
      }

      if (commentThread.isInUndoResolvedState) {
        return (
          <ResolveUndoDialog
            ref={refToForward}
            isSuggestionThread={isSuggestion(firstThreadItem)}
            onResolveUndo={onResolveUndo}
            onResolveUndoCancel={onResolveUndoCancel}
          />
        );
      }

      const onClick = (): void => {
        if (!isFocused) {
          onFocus();
        }
      };

      const lastApprovedSuggestion = commentThread.threadItems
        .filter(
          (comment: ICommentThreadItem) => isSuggestion(comment) && !!comment.suggestionApprovedAt,
        )
        .max((a: ISuggestion, b: ISuggestion) =>
          dateComparer(a.suggestionApprovedAt, b.suggestionApprovedAt),
        );

      const itemsBeingEdited = commentThread.threadItems.filter(
        (comment: ICommentThreadItem) => comment.isEditing,
      );
      const shouldRenderNewCommentThreadItem =
        itemsBeingEdited.isEmpty() &&
        !isResolved &&
        (isFocused || commentThread.isReplying || commentThread.isSubmitting);

      return (
        <div
          id={commentThread.id}
          className={classNames(
            CommentThreadCssClass,
            {
              'comment-thread--is-resolved': commentThread.resolvedAt,
              'comment-thread--has-focus': isFocused,
            },
            className,
          )}
          onClick={onClick}
          ref={refToForward}
          {...getDataUiCollectionAttribute(DataUiCollection.CommentThreadItems)}
          {...getDataUiElementAttribute(DataUiElement.CommentThread)}
          {...getDataAttribute(DataAttributes.CommentThreadId, commentThread.id)}
        >
          {isResolved && (
            <div className="comment-thread__comment-status comment-status">
              <Icon
                iconName={IconName.CheckCircle}
                className="comment-status__icon"
                dataAttribute={getDataUiElementAttribute(DataUiElement.CommentStatusIsResolved)}
              />
              <div className="comment-status__label">
                Resolved by {getUserName(commentThread.resolvedBy, resolvedBy)}
              </div>
            </div>
          )}
          {commentThread.threadItems.toArray().map((comment, index) => (
            <CommentThreadItem
              key={comment.id}
              className="comment-thread__comment"
              commentThreadItem={comment}
              commentThread={commentThread}
              isThreadRoot={index === 0}
              showReferenceForInlineThreads={showReferenceForInlineThreads && index === 0}
              isLastApprovedSuggestion={comment === lastApprovedSuggestion}
              isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
            />
          ))}
          {shouldRenderNewCommentThreadItem && (
            <Reply
              ref={newCommentThreadItemRef}
              canSuggest={canSuggest}
              commentThread={commentThread}
              isResolved={isResolved}
              onBlurNewItemText={onBlurNewItemText}
              onCancelNewItem={onCancelNewItem}
              onStartNewItem={onStartNewItem}
              onSubmitNewItem={onSubmitNewItem}
              type={type}
            />
          )}
        </div>
      );
    },
  ),
);

CommentThread.displayName = 'CommentThread';
