import { useCallback, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver, { ObservedSize } from 'use-resize-observer';

const breakpointsUpdateIntervalMs = 100;

export const useContainerBreakpoints = (breakpoints: ReadonlyArray<number>) => {
  const containerRef = useRef(null);
  const [reachedBreakpoints, setReachedBreakpoints] = useState<ReadonlyArray<number>>([]);

  const onResize = useCallback(
    ({ width }: ObservedSize) => {
      if (width) {
        const newReachedBreakpoints = breakpoints.filter((breakpoint) => width >= breakpoint);
        if (newReachedBreakpoints.length !== reachedBreakpoints?.length) {
          setReachedBreakpoints(newReachedBreakpoints);
        }
      } else if (reachedBreakpoints.length !== 0) {
        setReachedBreakpoints([]);
      }
    },
    [reachedBreakpoints, breakpoints],
  );

  const throttledOnResize = useThrottledCallback(onResize, breakpointsUpdateIntervalMs);

  useResizeObserver({
    ref: containerRef,
    onResize: throttledOnResize,
  });

  return {
    containerRef,
    reachedBreakpoints,
  };
};
