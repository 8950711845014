import { ICollection } from '../../../../data/models/collections/Collection.ts';
import {
  CollapsedSpaceBarAllCollectionsText,
  CollapsedSpaceBarCollectionPluralPrefix,
  CollapsedSpaceBarCollectionSingularPrefix,
} from '../constants/spacesUiConstants.ts';

export const getCollectionsText = (
  collections: ReadonlyArray<ICollection>,
  areAllCollectionSelected: boolean,
  includePrefix?: boolean,
) => {
  if (collections.length === 0) {
    return '';
  }

  const prefix =
    collections.length === 1
      ? CollapsedSpaceBarCollectionSingularPrefix
      : CollapsedSpaceBarCollectionPluralPrefix;
  const text =
    areAllCollectionSelected && collections.length > 1
      ? CollapsedSpaceBarAllCollectionsText
      : collections.map((c) => c.name).join(', ');

  return includePrefix ? `${prefix}${text}` : text;
};
