import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isEmail } from '../../../../_shared/utils/validation/isEmail.ts';
import { areCollectionGroupsTheSame } from '../utils/areCollectionGroupsTheSame.ts';
import { getInitialInvitationCollectionGroups } from './getInitialInvitationCollectionGroups.ts';

export const hasInviteAtLeastOneUser = (state: IStore): boolean => {
  const {
    usersApp: { userInvitation },
  } = state;

  return !userInvitation.invitations.isEmpty() || isEmail(userInvitation.searchText.trim());
};

export const hasInviteUnsavedChanges = (state: IStore): boolean => {
  const {
    data: {
      roles: { rolesById },
    },
    usersApp: { userInvitation },
  } = state;

  const originalCollectionGroups = getInitialInvitationCollectionGroups(rolesById);
  const hasUnsavedCollectionGroups = !areCollectionGroupsTheSame(
    userInvitation.collectionGroups,
    originalCollectionGroups,
  );

  return (
    !!userInvitation.searchText.length ||
    hasInviteAtLeastOneUser(state) ||
    hasUnsavedCollectionGroups
  );
};
