import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentItemEditing_CloseIncompleteItemNotificationBar,
  ContentItemEditing_FocusValidationWarningsBeforePublish,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function showIncompleteItemNotificationBar(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_FocusValidationWarningsBeforePublish: {
      return true;
    }

    case ContentItemEditing_CloseIncompleteItemNotificationBar: {
      return initialState;
    }

    default:
      return state;
  }
}
