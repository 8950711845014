import { useCallback, useEffect, useRef } from 'react';
import { forgetSmartLinkCommand } from '../../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { SmartLinkCommand } from '../../../../../_shared/models/SmartLinkCommand.ts';
import { isSmartLinkCommandRelevantToElement } from '../../../../../_shared/models/utils/smartLinkCommandUtils.ts';
import { useDeliveryContentComponentId } from '../../../../itemEditor/features/ContentComponent/hooks/useDeliveryContentComponentId.tsx';
import { ElementReference } from '../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { isSmartLinkCommandRelevantToRTEs } from '../api/editorSmartLinkUtils.ts';

interface ISmartLinkCommandConnectorProps {
  readonly element: ElementReference;
  readonly onCommandReceived: () => void;
  readonly onExecuteCommand: (command: SmartLinkCommand) => Promise<void>;
}

export const SmartLinkCommandConnector: React.FC<ISmartLinkCommandConnectorProps> = ({
  element,
  onCommandReceived,
  onExecuteCommand,
}) => {
  const deliveryContentComponentId = useDeliveryContentComponentId();
  const command = useSelector((state) => state.sharedApp.smartLinkCommand);
  const prevCommand = useRef<SmartLinkCommand | null>(null);
  const lastCommandPromise = useRef<Promise<void>>(Promise.resolve());
  const dispatch = useDispatch();

  const onSmartLinkCommandExecuted = useCallback(() => dispatch(forgetSmartLinkCommand()), []);

  useEffect(() => {
    if (command) {
      // Command may already be initiated when editor is loaded. That is why we should check
      // and process stored commands directly on mount.
      const isNewCommand = command !== prevCommand.current;

      prevCommand.current = command;

      if (isNewCommand) {
        // Chain the incoming commands as their processing is asynchronous and may take some time
        lastCommandPromise.current = lastCommandPromise.current.then(async () => {
          onCommandReceived();

          const { elementCodename, itemId, rootRichTextElementId } = element;

          if (
            !itemId ||
            !rootRichTextElementId ||
            !isSmartLinkCommandRelevantToRTEs(command) ||
            !isSmartLinkCommandRelevantToElement(
              command,
              itemId.itemId,
              deliveryContentComponentId,
              elementCodename,
            )
          ) {
            return;
          }

          await onExecuteCommand(command);
          onSmartLinkCommandExecuted();
        });
      }
    }
  }, [
    element,
    command,
    onCommandReceived,
    onExecuteCommand,
    onSmartLinkCommandExecuted,
    deliveryContentComponentId,
  ]);

  return null;
};

SmartLinkCommandConnector.displayName = 'SmartLinkCommandConnectorProps';
