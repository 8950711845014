import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { getNewContentItemForm } from '../../../NewContentItem/selectors/newContentItemDialog.ts';

interface IDeps {
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createTrackContentComponentCreatedAction =
  (deps: IDeps) => (): ThunkFunction => (dispatch, getState) => {
    const { contentTypeSection } = getNewContentItemForm(getState());

    dispatch(
      deps.trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.CreateComponent,
        origin: ContentItemEditingEventOrigins.Paper,
        contentElementType: ElementType.RichText,
        contentTypeSection,
      }),
    );
  };
