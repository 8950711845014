import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  SimpleStatusAlign,
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { IconSize, Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AiOperationState } from '../../../../../../_shared/features/AI/types/AiOperationState.ts';
import { AiError } from '../../../../../../_shared/features/AI/types/aiErrors.ts';

enum AiOperationStatusValue {
  None = 'None',
  Pending = 'Pending',
  Error = 'Error',
  Finished = 'Finished',
}

export const getAiOperationStatus = (
  aiOperationState: AiOperationState,
  error: AiError | null,
): AiOperationStatusValue => {
  if (aiOperationState === AiOperationState.Pending) {
    return AiOperationStatusValue.Pending;
  }

  if (error) {
    return AiOperationStatusValue.Error;
  }

  if (aiOperationState === AiOperationState.NotExecuted) {
    return AiOperationStatusValue.None;
  }

  return AiOperationStatusValue.Finished;
};

export type Props = {
  readonly label: string;
  readonly status: AiOperationStatusValue;
};

export const AiActionStatus = ({ label, status }: Props) => {
  switch (status) {
    case AiOperationStatusValue.None:
      return null;

    case AiOperationStatusValue.Pending:
      return <PendingStatus label={label} />;

    case AiOperationStatusValue.Error:
      return <ErrorStatus label={label} />;

    case AiOperationStatusValue.Finished:
      return <FinishedStatus label={label} />;
  }
};

const PendingStatus = ({ label }: Pick<Props, 'label'>) => {
  const [showIsDelayed, setShowIsDelayed] = useState(false);
  const debouncedShowIsDelayed = useDebouncedCallback(() => setShowIsDelayed(true), 15000);

  useEffect(() => {
    debouncedShowIsDelayed();
    return debouncedShowIsDelayed.cancel;
  }, [debouncedShowIsDelayed]);

  return (
    <AiStatusWrapper>
      <SimpleStatusDefault
        icon={AnimatedProgressIcon}
        iconAlign={SimpleStatusAlign.Right}
        label={`${label}…${showIsDelayed ? ' this may take a while.' : ''}`}
      />
    </AiStatusWrapper>
  );
};

const FinishedStatus = ({ label }: Pick<Props, 'label'>) => (
  <AiStatusWrapper>
    <SimpleStatusSuccess
      icon={Icons.CbCheckPreview}
      iconAlign={SimpleStatusAlign.Right}
      label={label}
    />
  </AiStatusWrapper>
);

const ErrorStatus = ({ label }: Pick<Props, 'label'>) => (
  <AiStatusWrapper>
    <SimpleStatusError
      icon={Icons.ExclamationTriangleInverted}
      iconAlign={SimpleStatusAlign.Right}
      label={label}
    />
  </AiStatusWrapper>
);

const AiStatusWrapper: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Inline align="center" noWrap spacingX={Spacing.XS}>
    <Icons.KopilotAi size={IconSize.S} />
    {children}
  </Inline>
);
