import { InvariantException } from '@kontent-ai/errors';
import { IAssignmentNormalizedServerModel } from '../../../../repositories/serverModels/IAssignmentServerModel.type.ts';
import { INote } from './Assignment.ts';

export interface IAssignmentNormalized {
  readonly id: Uuid;
  readonly createdAt: DateTimeStamp | null;
  readonly note: INote | null;
  readonly assignees: ReadonlySet<UserId>;
  readonly assignedBy: UserId | null;
  readonly due: DateTimeStamp | null;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledPublishDisplayTimeZone: string | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly scheduledUnpublishDisplayTimeZone: string | null;
  readonly workflowStepId: Uuid | null;
  readonly workflowId: Uuid | null;
}

export function getAssignmentNormalizedFromServerModel(
  assignment: IAssignmentNormalizedServerModel,
): IAssignmentNormalized {
  if (!assignment.workflowStatus || !assignment.workflowStatus._id) {
    throw InvariantException('Assignment server model: workflow status is falsy');
  }

  const noteExists = assignment.note && assignment.note.length > 0;
  const assignedBy = assignment.assignedBy?.userId ?? null;
  const createdAt = assignment.createdAt;
  const note: INote | null = noteExists
    ? {
        noteText: assignment.note || '',
        addedBy: assignment.noteAddedBy ? assignment.noteAddedBy : assignedBy,
        addedAt: assignment.noteAddedAt ? assignment.noteAddedAt : createdAt,
      }
    : null;

  const assignees = assignment.assignees
    ? new Set<UserId>(assignment.assignees.map((a) => a.userId))
    : new Set<UserId>();

  return {
    id: assignment.id,
    createdAt,
    note,
    scheduledToPublishAt: assignment.publishScheduleTime,
    scheduledPublishDisplayTimeZone: assignment.publishScheduleDisplayTimeZone,
    scheduledToUnpublishAt: assignment.unpublishScheduleTime,
    scheduledUnpublishDisplayTimeZone: assignment.unpublishScheduleDisplayTimeZone,
    assignees,
    assignedBy,
    due: assignment.due,
    workflowStepId: assignment.workflowStatus._id,
    workflowId: assignment.workflowStatus.workflowId,
  };
}
