import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ElementValue_Changed,
  ContentItemEditing_Init_Ready,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState = Immutable.Set<Uuid>();

export function refreshedElementIds(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case ContentItemEditing_Init_Ready: {
      return initialState;
    }

    case ContentItemEditing_ElementRefresh_Finished: {
      return state.add(action.payload.elementData.elementId);
    }

    case ContentItemEditing_ElementValue_Changed: {
      return state.remove(action.payload.elementData.elementId);
    }

    default:
      return state;
  }
}
