import { useHistory, useParams } from 'react-router';
import { RoleEditorRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { createRoleEditingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  roleValidationFailed,
  roleValidationFailedNoSelectedCapabilities,
} from '../actions/rolesActions.ts';
import { createRole } from '../actions/thunkRolesActions.ts';
import { RoleEditorToolbarActions } from '../components/RoleEditorToolbarActions.tsx';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { isRoleUsedByAnyUser } from '../selectors/isRoleUsed.ts';
import { isRoleUsedByWorkflowStep as isRoleUsedByWorkflowStepSelector } from '../selectors/isRoleUsedByWorkflowStep.ts';
import { canCreateNewCustomRole } from '../utils/customRolesUtils.ts';
import { getUpdateServerModelFromEditedRole } from '../utils/getUpdateServerModelFromEditedRole.ts';
import { isRoleValid } from '../utils/validationUtils.ts';

export const RoleCreatorToolbarActions = () => {
  const { projectId } = useParams<RoleEditorRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const editedRole = useSelector((s) => s.rolesApp.editorUi.editedRole);
  const isRoleUsedByUser = useSelector((s) =>
    isRoleUsedByAnyUser(s.rolesApp.editorUi.editedRole, s.data.users.usersById),
  );
  const isRoleUsedByWorkflowStep = useSelector((s) =>
    isRoleUsedByWorkflowStepSelector(s.rolesApp.editorUi.editedRole, s.data.workflows.byId),
  );
  const readOnly = useSelector((s) => !canCreateNewCustomRole(getCustomRolesLimitInfo(s)));
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);

  const handleSave = async (): Promise<void> => {
    const roleToCreate = getUpdateServerModelFromEditedRole(editedRole, contentTypesById);
    if (!isRoleValid(editedRole)) {
      dispatch(roleValidationFailed());
      return;
    }

    if (!roleToCreate.capabilities.length) {
      dispatch(roleValidationFailedNoSelectedCapabilities());
      return;
    }

    const createdRole = await dispatch(createRole(roleToCreate, contentTypesById));
    history.push(
      createRoleEditingLink({
        projectId,
        roleId: createdRole.id,
      }),
    );
  };

  return (
    <RoleEditorToolbarActions
      status={status}
      isEditedRoleReadOnly={editedRole.isReadonly}
      isRoleUsedByUser={isRoleUsedByUser}
      isRoleUsedByWorkflowStep={isRoleUsedByWorkflowStep}
      isRoleUsedByCustomApp={false}
      readOnly={readOnly}
      onSave={handleSave}
    />
  );
};
