import React from 'react';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { IconWrapper } from '../../Icons/IconWrapper.tsx';

export const CheckedIcon: React.FC = () => (
  <IconWrapper $size={IconSize.S}>
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12">
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M5 10a.997.997 0 01-.707-.292l-2-2a.999.999 0 111.414-1.414l1.138 1.138 3.323-4.986a.999.999 0 111.664 1.11l-4 6A1 1 0 015 10"
      />
    </svg>
  </IconWrapper>
);

CheckedIcon.displayName = 'CheckedIcon';
