import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_TypeElementDropped,
  ContentModels_TypeEditor_TypeElementPickedUp,
} from '../../constants/sharedContentModelsActionTypes.ts';

const initialState = '';

export const NewElementId = '00000000-0000-0000-0000-000000000000';

export function draggedElementId(state = initialState, action: Action): Uuid {
  switch (action.type) {
    case ContentModels_TypeEditor_TypeElementPickedUp: {
      return action.payload.typeElementId;
    }

    case ContentModels_TypeEditor_TypeElementDropped: {
      return initialState;
    }

    default:
      return state;
  }
}
