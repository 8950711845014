import { parseError } from '@kontent-ai/errors';
import { trackExceptionToAppInsights } from './applicationInsights.ts';

const logConsoleError: typeof console.error = (...params) => {
  // Do not spam console in production.
  if (self._clientConfig.logErrorsToConsole) {
    console.error(...params);
  }
};

export function trackError(exception: unknown, error?: unknown): void {
  if (self._envConfig.appInsightsInstrumentationKey) {
    trackExceptionToAppInsights({ exception: parseError(exception, error) });
  }
}

export function logError(exception: unknown, error?: unknown): void {
  logConsoleError(exception, error);
  trackError(exception, error);
}
