import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

const LogoImgWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

LogoImgWrapper.displayName = 'LogoImgWrapper';

const LogoImg = styled.img`
  height: ${px(gridUnit * 2)};
`;

LogoImg.displayName = 'LogoImg';

export const StatusBarLogo = () => (
  <LogoImgWrapper>
    <LogoImg alt="Kontent.ai logo" src="/images/kontent-logo-no-trademark_RGB.svg" />
  </LogoImgWrapper>
);
