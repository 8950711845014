import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';

type Params = {
  readonly editedItemId: Uuid;
  readonly item?: IListingContentItem;
  readonly itemVariantId: Uuid;
  readonly parentItemId: Uuid;
  readonly parentVariantId: Uuid;
};

export const shouldChildItemBeRendered = ({
  editedItemId,
  item,
  itemVariantId,
  parentItemId,
  parentVariantId,
}: Params): boolean =>
  !!item &&
  !item.item.archived &&
  item.item.id !== editedItemId &&
  (item.item.id !== parentItemId ||
    (item.variant?.id.variantId ?? itemVariantId) !== parentVariantId);
