import { Collection } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { FieldPathByValue, useController } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { filterOutDuplicates } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { EntityWebhookEntityType } from '../../../../data/models/webhooks/EntityWebhookEntityType.ts';
import { EntityWebhookAction } from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { EntityWebhookEventTriggerBar as EntityWebhookEventTriggerBarComponent } from '../../components/settings/EntityWebhookEventTriggerBar.tsx';
import { IEventTrigger } from '../../components/settings/EntityWebhookEventTriggerBarListing.tsx';
import { EntityWebhookEventTriggerDetail as EntityWebhookEventTriggerDetailComponent } from '../../components/settings/EntityWebhookEventTriggerDetail.tsx';
import { allCheckBoxValue } from '../../constants/entityWebhookConstants.ts';
import {
  IEntityWebhookFormShape,
  IEntityWebhookTrigger,
} from '../../models/IEntityWebhookFormShape.type.ts';
import { anyCurrentlyVisibleEventIsSelected } from '../../utils/entityTriggersChecks.ts';

const isEntityWebhookAction = (value: string): value is EntityWebhookAction =>
  Object.values(EntityWebhookAction).includes(value as EntityWebhookAction);

const getSelectedEvents = (
  entityWebhookActionProps: ReadonlyArray<string>,
  allActionOptions: ReadonlyArray<EntityWebhookAction>,
): ReadonlyArray<string> =>
  Collection.isSubset(entityWebhookActionProps, allActionOptions)
    ? [...entityWebhookActionProps, allCheckBoxValue]
    : entityWebhookActionProps;

type EntityWebhookEventTriggerBarProps = {
  readonly enabled: boolean;
  readonly eventTriggerName: string;
  readonly eventTriggerType: EntityWebhookEntityType;
  readonly eventTriggers: ReadonlyArray<IEventTrigger>;
  readonly fieldNameTriggers: FieldPathByValue<IEntityWebhookFormShape, IEntityWebhookTrigger>;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
  readonly hasEmptyFilters: boolean;
};

export const EntityWebhookEventTriggerBar = ({
  enabled,
  eventTriggerName,
  eventTriggerType,
  eventTriggers,
  fieldNameTriggers,
  formProps,
  hasEmptyFilters,
}: EntityWebhookEventTriggerBarProps) => {
  const { control } = formProps;

  const { field } = useController({
    control,
    name: fieldNameTriggers,
  });

  const allActionOptions = eventTriggers.map((option) => option.action);

  const [selectedEvents, setSelectedEvents] = useState<ReadonlyArray<string>>(() =>
    getSelectedEvents(field.value.actions, allActionOptions),
  );
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setSelectedEvents(
      getSelectedEvents(
        field.value.actions,
        eventTriggers.map((option) => option.action),
      ),
    );
  }, [field.value.actions, eventTriggers]);

  const setSelectedEventsValues = (selectedEventsValue: ReadonlyArray<string>): void => {
    setSelectedEvents(selectedEventsValue);
    field.onChange({
      ...field.value,
      actions: selectedEventsValue.filter(isEntityWebhookAction),
    });
  };

  return (
    <EntityWebhookEventTriggerBarComponent
      enabled={enabled}
      eventTriggerName={eventTriggerName}
      hasAlert={
        field.value.checked &&
        (!anyCurrentlyVisibleEventIsSelected(field.value.actions, allActionOptions) ||
          hasEmptyFilters)
      }
      checkedBarItem={field.value.checked}
      isExpanded={isExpanded}
      onSetCheckedBarItem={(checked: boolean): void => {
        field.onChange({
          ...field.value,
          checked,
        });
      }}
      onSetIsExpanded={setIsExpanded}
    >
      <EntityWebhookEventTriggerDetailComponent
        enabled={enabled}
        eventTriggerName={eventTriggerName}
        eventTriggerType={eventTriggerType}
        eventTriggers={eventTriggers}
        formProps={formProps}
        checkedBarItem={field.value.checked}
        onEventTriggerChange={(selectedEventsCallbackValue: ReadonlyArray<string>) => {
          const allCheckBoxValueSelected = selectedEventsCallbackValue.includes(allCheckBoxValue);
          const allEventsSelected = Collection.isSubset(
            selectedEventsCallbackValue,
            allActionOptions,
          );
          const originallyAllCheckBoxValueSelected = selectedEvents.includes(allCheckBoxValue);

          if (!allCheckBoxValueSelected && originallyAllCheckBoxValueSelected) {
            setSelectedEventsValues([]);
          } else if (
            (!allCheckBoxValueSelected && allEventsSelected) ||
            (allCheckBoxValueSelected && !originallyAllCheckBoxValueSelected)
          ) {
            setSelectedEventsValues(
              filterOutDuplicates([...selectedEvents, ...allActionOptions, allCheckBoxValue]),
            );
          } else if (allCheckBoxValueSelected && !allEventsSelected) {
            setSelectedEventsValues(
              selectedEventsCallbackValue.filter((x) => x !== allCheckBoxValue),
            );
          } else {
            setSelectedEventsValues(selectedEventsCallbackValue);
          }
        }}
        selectedEvents={selectedEvents}
      />
    </EntityWebhookEventTriggerBarComponent>
  );
};

EntityWebhookEventTriggerBar.displayName = 'EntityWebhookEventTriggerBarContainer';
