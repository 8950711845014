import { ITextItemElement } from '../../models/contentItemElements/TextItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';
import { IEqualityCheckerDependencies } from './types/IEqualityCheckerDependencies.type.ts';
import { IItemElementsEqualityChecker } from './types/IItemElementsEqualityChecker.type.ts';

export const createTextItemElementsEqualityChecker =
  ({
    areTextEditorStatesTheSame,
    getItemElementEqualityChecker,
  }: IEqualityCheckerDependencies): IItemElementsEqualityChecker =>
  (itemElement1: ITextItemElement, itemElement2: ITextItemElement): boolean => {
    if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
      return false;
    }

    return areTextEditorStatesTheSame(
      itemElement1._editorState,
      itemElement2._editorState,
      getItemElementEqualityChecker,
    );
  };
