import { ExtendedContentChange } from '../components/contentItemEditorNotifications/overwriteNotification/OverwriteNotificationBar.tsx';

export const emptyContentChange: ExtendedContentChange = {
  changeBy: undefined,
  changeByManageApi: false,
  changeBySelf: false,
  changeReason: '',
  difference: {
    assignees: undefined,
    collection: undefined,
    codename: undefined,
    dueDate: undefined,
    name: undefined,
    note: undefined,
    publishScheduleTime: undefined,
    sitemap: undefined,
    unpublishScheduleTime: undefined,
    workflowStep: undefined,
  },
};
