import React, { useMemo } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../_shared/utils/permissions/activeCapabilities.ts';

type CommentsContextState = {
  readonly allowNewComments: boolean;
};

const defaultContext: CommentsContextState = {
  allowNewComments: false,
};

export const CommentsContext = React.createContext<CommentsContextState>(defaultContext);

type CommentsContextProviderProps = {
  readonly children: React.ReactNode;
  readonly elementCanReceiveComments: boolean;
};

export const CommentsContextProvider: React.FC<CommentsContextProviderProps> = ({
  children,
  elementCanReceiveComments,
}) => {
  const canViewItem = useSelector((state) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.ViewContent, state),
  );

  const contextState = useMemo<CommentsContextState>(
    () => ({ allowNewComments: elementCanReceiveComments && canViewItem }),
    [elementCanReceiveComments, canViewItem],
  );

  return <CommentsContext.Provider value={contextState}>{children}</CommentsContext.Provider>;
};

CommentsContextProvider.displayName = 'CommentsContextProvider';
