import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { BarItemExpandCollapseAction } from '../../../../_shared/components/BarItems/Actions/BarItemExpandCollapseAction.tsx';
import { BarItemActions } from '../../../../_shared/components/BarItems/BarItemActions.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  renderDateString,
  renderTimeString,
} from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { IAuditEvent } from '../../../../data/models/auditLog/AuditEvent.ts';
import { getActionTypePastTenseString } from '../constants/ActionType.ts';
import { AuditLogEventDetails } from './AuditLogEventDetails.tsx';

export interface IAuditLogEventProps {
  readonly auditLogEvent: IAuditEvent;
}

const renderEventDescription = (event: IAuditEvent): JSX.Element => (
  <>
    <span>{event.objectType} “</span>
    <span className="audit-log__event-title__description-truncated-part">
      {event.objectDisplayName}
    </span>
    <span className="audit-log__event-title__description-truncated-part">
      ” was <strong>{getActionTypePastTenseString(event.actionType)}</strong> by{' '}
      {event.userFullName}
    </span>
  </>
);

export const AuditLogEvent: React.FC<IAuditLogEventProps> = ({ auditLogEvent }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <div className="bar-item__node" {...getDataUiElementAttribute(DataUiElement.AuditLogEvent)}>
      <div className="bar-item__wrapper">
        <div
          className={classNames('bar-item__pane', { 'bar-item__pane--is-expanded': !isCollapsed })}
        >
          <div
            className={classNames('bar-item__bar', 'bar-item__bar--is-clickable', {
              'bar-item__bar--is-expanded': !isCollapsed,
            })}
          >
            <BarItemActions>
              <BarItemExpandCollapseAction
                isCollapsed={isCollapsed}
                onCollapse={toggleCollapse}
                onExpand={toggleCollapse}
              />
            </BarItemActions>
            <div className="bar-item__title-pane" onClick={toggleCollapse}>
              <div className={classNames('bar-item__title', 'audit-log__event-title__timestamp')}>
                {renderDateString(auditLogEvent.timestamp)},{' '}
                {renderTimeString(auditLogEvent.timestamp)}
              </div>
              <div
                className={classNames('bar-item__title', 'audit-log__event-title__description')}
                {...getDataUiElementAttribute(DataUiElement.AuditLogEventDescription)}
              >
                {renderEventDescription(auditLogEvent)}
              </div>
            </div>
          </div>
          {!isCollapsed && <AuditLogEventDetails auditLogEvent={auditLogEvent} />}
        </div>
      </div>
    </div>
  );
};

AuditLogEvent.displayName = 'AuditLogEvent';
