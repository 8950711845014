export enum WebhookHealthStatus {
  Unknown = 'Unknown',
  Working = 'Working',
  Failing = 'Failing',
  Dead = 'Dead',
}

export const WebhookDisabledDisplayName = 'Disabled';

export const WebhookHealthStatusDisplayNames: ReadonlyRecord<WebhookHealthStatus, string> = {
  [WebhookHealthStatus.Unknown]: 'Ready for message',
  [WebhookHealthStatus.Working]: 'Working',
  [WebhookHealthStatus.Failing]: 'Failing',
  [WebhookHealthStatus.Dead]: 'Dead',
};

export const mapWebhookHealthStatusFromServerValue = (
  healthStatusString: string,
): WebhookHealthStatus => {
  return Object.values(WebhookHealthStatus).includes(healthStatusString as WebhookHealthStatus)
    ? (healthStatusString as WebhookHealthStatus)
    : WebhookHealthStatus.Unknown;
};
