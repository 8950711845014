import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { memoize } from '@kontent-ai/memoization';
import React, { useMemo } from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  getCollectionsFilterOptions,
  getSelectedCollectionsFilterOptions,
  getSelectedLanguagesFilterOptions,
  getSelectedRolesFilterOptions,
} from '../../../../../_shared/utils/userListingFilterUtils.ts';
import { IProjectDetails } from '../../../../../data/models/projects/ProjectDetails.ts';
import {
  collectionFilterRemoved,
  environmentFilterUpdated,
  languageFilterRemoved,
  projectFilterUpdated,
  roleFilterRemoved,
  statusFilterUpdated,
} from '../../actions/subscriptionUserListingActions.ts';
import { SubscriptionUserListingStatusFilterTag } from '../../components/ListingFilter/SubscriptionUserListingStatusFilterTag.tsx';
import {
  ISubscriptionUsersListingFilter,
  emptySubscriptionListingUserFilter,
} from '../../models/SubscriptionUserListingFilter.ts';
import { getSubscriptionProjectsAndEnvironments } from '../../selectors/getSubscriptionProjectsAndEnvironments.ts';
import { getLanguageFilterOptions } from '../../utils/getFilterOptions.ts';

interface ISubscriptionUsersFilterTagsProps {
  readonly filter: ISubscriptionUsersListingFilter;
  readonly onTagClick: () => void;
}

const getProjectTagTextMemoized = memoize.weak(
  (projects: ReadonlyArray<IProjectDetails>, projectFilterId: Uuid) =>
    projects.find((p) => p.projectId === projectFilterId)?.projectName,
);

const getEnvironmentTagTextMemoized = memoize.weak(
  (environments: ReadonlyArray<IProjectDetails>, environmentFilterId: Uuid) =>
    environments.find((p) => p.projectId === environmentFilterId)?.environmentName,
);

export const SubscriptionUsersFilterTags: React.FC<ISubscriptionUsersFilterTagsProps> = ({
  filter,
  onTagClick,
}) => {
  const dispatch = useDispatch();
  const projectsAndEnvironments = useSelector(getSubscriptionProjectsAndEnvironments);
  const loadedRoles = useSelector((state) => state.subscriptionApp.users.listingUi.roles);
  const loadedCollections = useSelector(
    (state) => state.subscriptionApp.users.listingUi.collections,
  );
  const collectionOptions = getCollectionsFilterOptions(loadedCollections);
  const loadedLanguages = useSelector((state) => state.subscriptionApp.users.listingUi.languages);
  const languageOptions = getLanguageFilterOptions(loadedLanguages);

  const roleOptions = useMemo(
    () =>
      loadedRoles.map((role) => ({
        ...role,
        label: role.name,
      })),
    [loadedRoles],
  );
  const selectedRoles = getSelectedRolesFilterOptions(roleOptions, filter.byRole);
  const selectedCollections = getSelectedCollectionsFilterOptions(
    collectionOptions,
    filter.byCollection,
  );
  const selectedLanguages = getSelectedLanguagesFilterOptions(languageOptions, filter.byLanguage);
  const projectTagText =
    projectsAndEnvironments &&
    getProjectTagTextMemoized(projectsAndEnvironments.projects, filter.byProject);
  const environmentTagText =
    projectsAndEnvironments &&
    getEnvironmentTagTextMemoized(projectsAndEnvironments.environments, filter.byEnvironment);

  const resetStatusFilter = () =>
    dispatch(statusFilterUpdated(emptySubscriptionListingUserFilter.byStatus));
  const removeProjectFilter = () =>
    dispatch(projectFilterUpdated(emptySubscriptionListingUserFilter.byProject));
  const removeEnvironmentFilter = () =>
    dispatch(environmentFilterUpdated(emptySubscriptionListingUserFilter.byEnvironment));

  return (
    <Row spacing={Spacing.S} alignY="center" alignX="start">
      <Column width="fit-content">
        <Label size={LabelSize.L} color={colorTextDefault}>
          Applied filters:
        </Label>
      </Column>
      <Column width="fit-content">
        <SubscriptionUserListingStatusFilterTag
          status={filter.byStatus}
          onLabelClick={onTagClick}
          onRemoveClick={resetStatusFilter}
        />
      </Column>
      {projectTagText && (
        <Column width="fit-content">
          <DefaultTag
            label={projectTagText}
            onLabelClick={onTagClick}
            onRemoveClick={removeProjectFilter}
          />
        </Column>
      )}
      {environmentTagText && (
        <Column width="fit-content">
          <DefaultTag
            label={environmentTagText}
            onLabelClick={onTagClick}
            onRemoveClick={removeEnvironmentFilter}
          />
        </Column>
      )}
      {selectedRoles.map((role) => (
        <Column key={`role ${role.id}`} width="fit-content">
          <DefaultTag
            label={role.label}
            onLabelClick={onTagClick}
            onRemoveClick={() => dispatch(roleFilterRemoved(role.id))}
          />
        </Column>
      ))}
      {selectedCollections.map((collection) => (
        <Column key={`collection ${collection.id}`} width="fit-content">
          <DefaultTag
            label={collection.label}
            onLabelClick={onTagClick}
            onRemoveClick={() => dispatch(collectionFilterRemoved(collection.id))}
          />
        </Column>
      ))}
      {selectedLanguages.map((language) => (
        <Column key={`language ${language.id}`} width="fit-content">
          <DefaultTag
            label={language.label}
            onLabelClick={onTagClick}
            onRemoveClick={() => dispatch(languageFilterRemoved(language.id))}
          />
        </Column>
      ))}
    </Row>
  );
};
