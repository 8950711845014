import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IContentItemServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ICreateContentItemVariant } from '../../../../itemEditor/features/ContentItemEditing/actions/thunks/createContentItemVariant.ts';
import { ICreateContentItemAction } from '../../../../itemEditor/features/NewContentItem/actions/thunks/createContentItem.ts';
import { getNewContentItemForm } from '../../../../itemEditor/features/NewContentItem/selectors/newContentItemDialog.ts';

type Deps = {
  readonly createContentItem: ICreateContentItemAction;
  readonly createContentItemVariant: ICreateContentItemVariant;
};

export const createEnsureRootItemCreatedAction =
  (deps: Deps) =>
  (): ThunkPromise<IContentItemServerModel> =>
  async (dispatch, getState): Promise<IContentItemServerModel> => {
    const state = getState();
    const newContentItemForm = getNewContentItemForm(state);

    assert(
      newContentItemForm.contentTypeId,
      () => `${__filename}: Content type id was not set for root item creation.`,
    );
    assert(
      newContentItemForm.collectionId,
      () => `${__filename}: Collection id was not set for root item creation.`,
    );
    assert(
      newContentItemForm.workflowId,
      () => `${__filename}: Workflow id was not set for root item creation.`,
    );

    const rootItem = await dispatch(
      deps.createContentItem({
        collectionId: newContentItemForm.collectionId,
        name: newContentItemForm.name,
        typeId: newContentItemForm.contentTypeId,
      }),
    );

    const rootType = state.contentApp.loadedContentItemTypes.get(newContentItemForm.contentTypeId);

    assert(rootType, () => `${__filename}: Web Spotlight root type was not found.`);

    await dispatch(
      deps.createContentItemVariant(
        { id: rootItem.item.id, collectionId: newContentItemForm.collectionId },
        newContentItemForm.languageId,
        rootType,
        newContentItemForm.workflowId,
      ),
    );

    return rootItem.item;
  };
