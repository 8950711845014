export const RootFolderId: Uuid = '00000000-0000-0000-0000-000000000000';

export const editedAssetFolderCopy = Object.freeze({
  cancelTooltip: 'Cancel folder rename',
  cancelText: 'Cancel',
  confirmTooltip: 'Confirm folder rename',
  confirmText: 'Rename folder',
  emptyNameConfirmTooltip: 'Provide a folder name',
  equalNamesConfirmTooltip: 'Provide a different folder name',
  header: 'Rename folder',
  placeholder: 'Write updated folder name',
});

export const newAssetFolderCopy = Object.freeze({
  cancelTooltip: 'Cancel folder creation',
  cancelText: 'Cancel',
  confirmTooltip: 'Confirm folder creation',
  confirmText: 'Create folder',
  emptyNameConfirmTooltip: 'Provide a folder name',
  equalNamesConfirmTooltip: 'Provide a folder name',
  header: 'New folder',
  placeholder: 'Write new folder name',
});
