import {
  BaseColor,
  BorderRadius,
  Spacing,
  colorBackgroundHoverInverse,
  colorTextDefaultInverse,
  px,
  shadowFocusStyles,
  sizeHorizontalTabHeight,
  transition250,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

type StyledSelectCellProps = {
  readonly isFocusVisible?: boolean;
  readonly isActive?: boolean;
};

const cellHeight = px(sizeHorizontalTabHeight);

export const StyledSelectCell = styled.button<StyledSelectCellProps>`
  /* Sizes and positioning */
  padding: 0 ${px(Spacing.XL)};
  height: ${cellHeight};

  /* Text styles */
  line-height: ${cellHeight};
  text-align: center;
  white-space: nowrap;
  color: ${colorTextDefaultInverse};

  /* Others */
  overflow: hidden;
  border: none;
  border-radius: ${px(BorderRadius.M)};
  background-color: ${BaseColor.Transparent};
  transition: background-color ${transition250}, color ${transition250};

  ${({ isFocusVisible }) =>
    isFocusVisible &&
    css`
    ${shadowFocusStyles};
    z-index: 1;
  `};

  ${(props) =>
    props.isActive
      ? `
    background-color: ${colorBackgroundHoverInverse};
  `
      : `
    &:hover {
      background-color: ${colorBackgroundHoverInverse};
    }
  `};
`;
StyledSelectCell.displayName = 'StyledSelectCell';
