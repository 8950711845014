import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import {
  DataUiCollection,
  DataUiRteAction,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RichTextInputCommand } from '../../../keyboardShortcuts/api/EditorCommand.ts';
import { RTEToolbarButtonWithMenu } from '../../../toolbars/components/buttons/RTEToolbarButtonWithMenu.tsx';
import {
  EditorToolbarMenu,
  EditorToolbarMenuItem,
} from '../../../toolbars/components/menu/EditorToolbarMenu.tsx';
import { getToolbarButtonTooltipText } from '../../../toolbars/utils/toolbarUtils.ts';

export type CreateLinkOption = {
  readonly name: string;
  readonly onClick: () => void;
  readonly isDisabled?: boolean;
  readonly shortcuts?: string;
  readonly tooltipText?: string;
  readonly uiAction: DataUiRteAction;
};

type Props = {
  readonly isAddLinkAllowed: boolean;
  readonly options: ReadonlyArray<CreateLinkOption>;
};

export const CreateLinkButton: React.FC<Props> = ({ isAddLinkAllowed, options }) => {
  const menuItems = options.map(
    (option) =>
      ({
        disabled: !!option.isDisabled,
        id: option.name,
        isActive: false,
        isViolated: false,
        label: option.name,
        onClick: option.onClick,
        shortcuts: option.shortcuts,
        tooltipText: option.tooltipText,
        uiAction: option.uiAction,
      }) satisfies EditorToolbarMenuItem,
  );

  const tooltipText = isAddLinkAllowed
    ? 'Insert link'
    : (getToolbarButtonTooltipText(RichTextInputCommand.InsertLink, !isAddLinkAllowed) ?? '');

  return (
    <RTEToolbarButtonWithMenu
      disabled={!isAddLinkAllowed}
      icon={Icons.Chain}
      menuUiCollection={DataUiCollection.RteLinkMenuOptions}
      toolbarPlacement="bottom-start"
      tooltipText={tooltipText}
      uiAction={DataUiRteAction.Link}
    >
      <EditorToolbarMenu items={menuItems} />
    </RTEToolbarButtonWithMenu>
  );
};
