import Immutable from 'immutable';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { IRoleServerModel } from '../../../repositories/serverModels/IRoleServerModel.type.ts';

export interface IRole {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string | null;
  readonly isReadonly: boolean;
  readonly capabilities: Immutable.Set<Capability>;
}

export function getRoleFromServerModel(serverModel: IRoleServerModel): IRole {
  return {
    id: serverModel.id,
    name: serverModel.name || '',
    codename: serverModel.codename || null,
    isReadonly: !!serverModel.codename, // if codename is present, it is a default role -> cannot be edited
    capabilities: Immutable.Set(serverModel.capabilities),
  };
}
