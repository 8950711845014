import { useCallback, useEffect } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { loadPlansForSelection } from '../../../../data/actions/thunkDataActions.ts';
import { CommonPlanName } from '../../../../data/constants/CommonPlanName.ts';
import { IPlan, IPlanWithDetails, emptyPlan } from '../../../../data/models/plans/Plan.ts';
import { emptySubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { IPlans } from '../../../../data/reducers/plans/IPlans.type.ts';
import {
  getSelectedSubscription,
  getSelectedSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { loadSubscriptionUsage } from '../../shared/actions/thunkSubscriptionManagementActions.ts';
import {
  clearConfirmationMessage,
  showChangePlanDialog as showChangePlanDialogAction,
} from '../actions/planSelectionActions.ts';
import { PlanSelection as PlanSelectionComponent } from '../components/PlanSelection.tsx';

function getPlan(plans: IPlans, planName: CommonPlanName, currentPlanId: Uuid): IPlanWithDetails {
  const filteredPlans = plans.byId.filter((plan: IPlan) => plan.name.includes(planName));

  const foundPlan =
    filteredPlans.find((plan: IPlan) => plan.planId === currentPlanId) ||
    filteredPlans.find((plan: IPlan) => plans.idsForSelection.contains(plan.planId));

  const isPlanVersionObsolete = !!foundPlan && !plans.idsForSelection.contains(foundPlan.planId);

  return {
    plan: foundPlan ?? null,
    isPlanVersionObsolete,
  };
}

export const PlanSelection = () => {
  const currentSubscription = useSelector(
    (state) => getSelectedSubscription(state) ?? emptySubscription,
  );
  const currentPlan = useSelector((state) => getSelectedSubscriptionPlan(state) ?? emptyPlan);

  const subscriptionUsage = useSelector((s) =>
    s.data.subscriptions.subscriptionUsages.get(currentSubscription.subscriptionId),
  );
  const showChangePlanSuccessMessage = useSelector(
    (s) => s.subscriptionApp.plans.showChangePlanSuccessMessage,
  );
  const developerPlan = useSelector(
    (s) => getPlan(s.data.plans, CommonPlanName.Developer, currentPlan.planId)?.plan,
  );
  const isTrial = currentPlan.isTrial;
  const planDisplayName = currentPlan.displayName;

  useThunkPromise(loadSubscriptionUsage, currentSubscription.subscriptionId);
  useThunkPromise(loadPlansForSelection);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearConfirmationMessage());
    };
  }, []);

  const showChangePlanDialog = useCallback((planId: string): void => {
    dispatch(showChangePlanDialogAction(planId));
  }, []);

  return (
    <PlanSelectionComponent
      isCurrentSubscriptionExpired={!currentSubscription.isActive}
      isTrial={isTrial}
      showChangePlanSuccessMessage={showChangePlanSuccessMessage}
      showChangePlanDialog={showChangePlanDialog}
      developerPlan={developerPlan}
      planDisplayName={planDisplayName}
      subscriptionUsage={subscriptionUsage}
    />
  );
};
