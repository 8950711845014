import { createDateTime } from '../../../../_shared/models/DateTime.ts';
import {
  DefaultDisplayTimeZoneId,
  UtcTimeZoneId,
} from '../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { IDateTimeItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  DateTimeItemElement,
  EmptyDateTimeItemElement,
} from '../../models/contentItemElements/DateTimeItemElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export const ensureTimeZoneId = (
  displayTimeZone: string | null,
  dateTimeValue: string | null,
): string => {
  if (displayTimeZone) {
    return displayTimeZone;
  }

  return dateTimeValue ? UtcTimeZoneId : DefaultDisplayTimeZoneId;
};

export const dateTimeElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
  IDateTimeItemElementServerModel,
  DateTimeItemElement
> = (serverElement) => {
  const value = serverElement.value || '';

  const element: DateTimeItemElement = {
    ...EmptyDateTimeItemElement,
    elementId: serverElement.elementId,
    value,
    _dateTime: createDateTime({
      value,
    }),
    displayTimeZone: ensureTimeZoneId(serverElement.displayTimeZone, serverElement.value),
    lastModifiedBy: serverElement.lastModifiedBy ? serverElement.lastModifiedBy : null,
    lastModifiedAt: serverElement.lastModifiedAt ? serverElement.lastModifiedAt : null,
  };
  return element;
};

export const dateTimeElementServerModelConverter: ISimpleItemElementServerModelConverter<
  DateTimeItemElement,
  IDateTimeItemElementServerModel
> = (element) => {
  const serverElement: IDateTimeItemElementServerModel = {
    elementId: element.elementId,
    type: ElementType.DateTime,
    value: element.value,
    displayTimeZone: element.displayTimeZone,
    lastModifiedBy: element.lastModifiedBy,
    lastModifiedAt: element.lastModifiedAt,
  };

  return serverElement;
};
