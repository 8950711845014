import { EditorState } from 'draft-js';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { SelectionAfter } from '../../../utils/editorStateUtils.ts';
import { applyAutomaticMarkdownInlineConversion as applyAutomaticMarkdownInlineConversionInContent } from '../../inlineStyles/api/editorInlineMarkdownUtils.ts';
import {
  EditorCommandStatus,
  TextFormattingCommandFeatureMap,
  TextStyleFeatureStyleMap,
  applyAllowedInlineStyleForSelectedChars,
  canCommandExecute,
  isFeatureActive,
  isTextStyleFeature,
  removeViolatedInlineStyleForSelectedChars,
} from '../../keyboardShortcuts/api/editorCommandUtils.ts';
import { InlineStylesPlugin } from '../InlineStylesPlugin.tsx';
import { modifyStyle, removeInlineStyleForSelectedChars } from './editorStyleUtils.ts';
import { MutuallyExclusiveStyles, isMutuallyExclusiveStyleKey } from './inlineStyles.ts';

export const editorInlineStyleApi: EditorApiImplementation<InlineStylesPlugin> = {
  applyAutomaticMarkdownInlineConversion(api, editorState, onMarkdownConversion) {
    const withMarkDownApplied = api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => applyAutomaticMarkdownInlineConversionInContent(input, onMarkdownConversion),
      'insert-fragment',
    );

    // Inline markdown conversion may change style for the next typed character(s)
    // we force the original style to keep typing after the converted text with the original style
    const withRestoredInlineStyle =
      withMarkDownApplied !== editorState
        ? EditorState.setInlineStyleOverride(
            withMarkDownApplied,
            api.getCurrentVisualStyle(editorState),
          )
        : withMarkDownApplied;

    return withRestoredInlineStyle;
  },

  applyInlineStyle(api, editorState, inlineStyle) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => applyAllowedInlineStyleForSelectedChars(input, inlineStyle, api.getLimitations()),
      'change-inline-style',
      true,
    );
  },

  executeTextStyleCommand(api, editorState, command) {
    const status = api.getCommandStatus(editorState, command);
    if (!canCommandExecute(status)) {
      return editorState;
    }

    const feature = TextFormattingCommandFeatureMap[command];
    if (!feature) {
      return editorState;
    }

    if (!isTextStyleFeature(feature)) {
      return editorState;
    }

    const style = TextStyleFeatureStyleMap[feature];
    const isActive = isFeatureActive(status);

    // If the selection is collapsed, toggle the specified style on or off and
    // set the result as the new inline style override. This will then be
    // used as the inline style for the next character to be inserted.
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      const currentVisualStyle = api.getCurrentVisualStyle(editorState);

      return EditorState.setInlineStyleOverride(
        editorState,
        isActive
          ? currentVisualStyle.remove(style)
          : currentVisualStyle
              .subtract(isMutuallyExclusiveStyleKey(style) ? MutuallyExclusiveStyles[style] : [])
              .add(style),
      );
    }

    if (status === EditorCommandStatus.ActiveAllowed) {
      return api.removeInlineStyle(editorState, style);
    }
    if (status === EditorCommandStatus.ActiveNotAllowed) {
      return api.removeViolatedInlineStyle(editorState, style);
    }
    return api.applyInlineStyle(editorState, style);
  },

  modifyStyle(api, editorState, filterCallback, stylesToRemove, stylesToAdd) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => modifyStyle(input, filterCallback, stylesToRemove, stylesToAdd),
      'change-inline-style',
      false,
      SelectionAfter.Original,
    );
  },

  removeInlineStyle(api, editorState, inlineStyle) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => removeInlineStyleForSelectedChars(input, inlineStyle),
      'change-inline-style',
      true,
    );
  },

  removeViolatedInlineStyle(api, editorState, inlineStyle) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) =>
        removeViolatedInlineStyleForSelectedChars(input, inlineStyle, api.getLimitations()),
      'change-inline-style',
      true,
    );
  },
};
