import { ActiveCapabilityType } from '../../_shared/models/activeCapability.type.ts';
import { TaxonomiesByGroupIdServerModel } from './TaxonomyFilterServerModel.type.ts';
import { IContentItemElementServerModel } from './elementServerModels.type.ts';

interface IAssetServerModelBase {
  readonly _id: Uuid;
  readonly archived: boolean;
  readonly codename: string;
  readonly created: DateTimeStamp | null;
  readonly createdBy: string | null;
  readonly deliveryLink?: string | null;
  readonly descriptions: ReadonlyRecord<string, string> | null;
  readonly downloadLink?: string | null;
  readonly filename: string | null;
  readonly folderId: Uuid;
  readonly image_height: number | null;
  readonly image_width: number | null;
  readonly isAnimatedGif: boolean;
  readonly lastModified: DateTimeStamp | null;
  readonly lastModifiedBy: string | null;
  readonly link?: string | null;
  readonly nonLocalizableElements: ReadonlyArray<IContentItemElementServerModel>;
  readonly size: number;
  readonly tags: ReadonlyArray<string> | null;
  readonly thumbnailLink?: string | null;
  readonly title: string | null;
  readonly type: string | null;
  readonly eTag: string | null;
}

export interface IAssetRequestServerModel extends IAssetServerModelBase {
  readonly collection: {
    readonly id: Uuid | null;
  };
}

export interface IAssetResponseServerModel extends IAssetServerModelBase {
  readonly collectionId: Uuid | null;
  readonly capabilities: {
    readonly activeCapabilities: ReadonlyArray<ActiveCapabilityType>;
  };
}

export interface IAssetsArchiveResultServerModel {
  readonly archivedAssetsIds: UuidArray;
  readonly usedAssetsIds: UuidArray;
}

export interface IAssetsRestoreResponseServerModel {
  readonly assets: ReadonlyArray<IAssetResponseServerModel>;
  readonly assetsLinks: ReadonlyArray<IAssetLinksServerModel>;
}

export interface IMoveAssetItemToFolderServerModel {
  readonly assetId: Uuid;
  readonly targetFolderId: Uuid;
}

export interface IMoveAssetsToFolderRequestServerModel {
  readonly items: ReadonlyArray<IMoveAssetItemToFolderServerModel>;
}

export interface IMoveAssetsToFolderResultServerModel {
  readonly movedAssetsIds: UuidArray;
}

export interface IMoveAssetItemToCollectionServerModel {
  readonly assetId: Uuid;
  readonly targetCollectionId: Uuid | null;
}

export interface IMoveAssetsToCollectionRequestServerModel {
  readonly items: ReadonlyArray<IMoveAssetItemToCollectionServerModel>;
}

export interface IMoveAssetsToCollectionResultServerModel {
  readonly movedAssetsIds: UuidArray;
}

export interface IAssetListingServerModel {
  readonly data: ReadonlyArray<IAssetResponseServerModel>;
  readonly continuationToken: string | null;
}

export interface IAssetByIdsQueryServerModel {
  readonly assetIds: UuidArray;
}

export interface IAssetsByIdsResponseServerModel {
  readonly data: ReadonlyArray<IAssetResponseServerModel>;
}

export enum AssetOrderingServerCode {
  CreatedAt = 'createdAt',
  LastModifiedAt = 'lastModifiedAt',
  FileSize = 'fileSize',
  Name = 'name',
}

export type OrderByServerModel = {
  readonly columnName: AssetOrderingServerCode;
  readonly direction: 'asc' | 'desc';
};

export interface IAssetQueryServerModel {
  // user-defined fields, all nullable
  readonly searchPhrase: string | null;
  readonly collectionIds: ReadonlyArray<Uuid | null> | null;
  readonly taxonomies: TaxonomiesByGroupIdServerModel | null;
  readonly orderBy: OrderByServerModel | null;

  // system-defined fields
  readonly mimeType: string | null;
  readonly includeArchived?: boolean;
  readonly continuationToken: string | null;
  readonly maxAssetsCount: number;
  readonly folderId: Uuid;
  readonly includeSubFolders: boolean;
}

export interface IAssetLinksServerModel {
  readonly assetId: Uuid;
  readonly link: string | null;
  readonly downloadLink: string | null;
  readonly thumbnailLink: string | null;
  readonly deliveryLink: string | null;
}
export interface IAssetInsertMetadataServerModel {
  readonly folderId: Uuid;
  readonly collection: {
    readonly id: Uuid | null;
  };
}

export type RequiredAssetCreationMetadata = {
  readonly collectionId: Uuid | null;
  readonly folderId: Uuid;
};
