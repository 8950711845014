import { Action } from '../../../@types/Action.type.ts';
import { Data_LatestProductUpdate_Success } from '../../constants/dataActionTypes.ts';
import { ILatestProductUpdateInfo } from './ILatestProductUpdateInfo.type.ts';

export function latestProductUpdateInfo(
  state: ILatestProductUpdateInfo | null = null,
  action: Action,
): ILatestProductUpdateInfo | null {
  switch (action.type) {
    case Data_LatestProductUpdate_Success:
      return action.payload.data;

    default:
      return state;
  }
}
