import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type EntityWebhookSettingsProps = {
  readonly children: JSX.Element;
};

export const EntityWebhookSettings = ({ children }: EntityWebhookSettingsProps) => {
  return (
    <Card cardLabel="Settings" component="section">
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.FormSection)}>
        Settings
      </Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>{children}</Stack>
      </Card.Body>
    </Card>
  );
};

EntityWebhookSettings.displayName = 'EntityWebhookSettings';
