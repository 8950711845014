import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { clearListingFilter } from '../../../itemEditor/actions/contentActions.ts';
import {
  INoItemsFoundEmptyStateProps,
  NoItemsFoundEmptyState as NoItemsFoundEmptyStateComponent,
} from '../components/NoItemsFoundEmptyState.tsx';

const mapDispatchToProps = (dispatch: Dispatch): INoItemsFoundEmptyStateProps => ({
  onClearFilter: () => dispatch(clearListingFilter()),
});

export const NoItemsFoundEmptyState: React.ComponentType = connect(
  null,
  mapDispatchToProps,
)(NoItemsFoundEmptyStateComponent);
