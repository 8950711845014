import React from 'react';
import { ContentProductionCapabilitiesConfigurationCard } from '../../containers/ContentProductionCapabilitiesConfigurationCard.tsx';
import { ContentModelsCapabilitiesConfigurationCard } from './cards/ContentModelsCapabilitiesConfigurationCard.tsx';
import { ProjectCapabilitiesConfigurationCard } from './cards/ProjectCapabilitiesConfigurationCard.tsx';

interface IRoleCapabilitiesConfiguratorProps {
  readonly areCollectionsEnabled: boolean;
  readonly areCustomAppsEnabled: boolean;
  readonly areSpacesEnabled: boolean;
  readonly isAssetTypeEnabled: boolean;
  readonly isAuditLogEnabled: boolean;
  readonly isSitemapEnabled: boolean;
  readonly projectId: Uuid;
}

export const RoleCapabilitiesConfigurator: React.FC<IRoleCapabilitiesConfiguratorProps> = ({
  areCollectionsEnabled,
  areCustomAppsEnabled,
  areSpacesEnabled,
  isAssetTypeEnabled,
  isAuditLogEnabled,
  isSitemapEnabled,
  projectId,
}) => (
  <div className="role-editor__cards-pane">
    <ContentProductionCapabilitiesConfigurationCard projectId={projectId} />
    <ContentModelsCapabilitiesConfigurationCard
      isAssetTypeEnabled={isAssetTypeEnabled}
      isSitemapEnabled={isSitemapEnabled}
    />
    <ProjectCapabilitiesConfigurationCard
      areCollectionsEnabled={areCollectionsEnabled}
      areCustomAppsEnabled={areCustomAppsEnabled}
      areSpacesEnabled={areSpacesEnabled}
      isAuditLogEnabled={isAuditLogEnabled}
    />
  </div>
);

RoleCapabilitiesConfigurator.displayName = 'RoleCapabilitiesConfigurator';
