import React from 'react';
import {
  HighlightedIndexes,
  findMatchingIndexes as findMatchingIndexesDefault,
} from '../../utils/filter/nameFilterUtils.ts';
import { SearchPhraseHighlighterElement } from './SearchPhraseHighlighterElement.tsx';

type Props = {
  readonly className?: string;
  readonly findMatchingIndexes?: (words: string[], text: string) => HighlightedIndexes[];
  readonly searchPhrase: string;
  readonly text: string;
};

export const SearchPhraseHighlighter: React.FC<Props> = ({
  className,
  findMatchingIndexes = findMatchingIndexesDefault,
  searchPhrase,
  text,
}) => {
  return (
    <span className={className} title={text}>
      <SearchPhraseHighlighterElement
        searchPhrase={searchPhrase}
        text={text}
        findMatchingIndexes={findMatchingIndexes}
      />
    </span>
  );
};

SearchPhraseHighlighter.displayName = 'SearchPhraseHighlighter';
