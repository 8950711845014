import { combineReducers } from '@reduxjs/toolkit';
import { filterSearchPhrase } from './listing/filterSearchPhrase.ts';
import { listingOperationStatus } from './listing/listingOperationStatus.ts';
import { loadingStatus } from './listing/loadingStatus.ts';
import { selectedContentTypeSnippets } from './listing/selectedContentTypeSnippets.ts';

export const listingReducer = combineReducers({
  filterSearchPhrase,
  listingOperationStatus,
  loadingStatus,
  selectedContentTypeSnippets,
});
