import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { isSelectionCollapsed } from '../../../../../../_shared/utils/selectionUtils.ts';
import { EmailLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/EmailLinkProperties.type.ts';
import { LinkDetail } from '../../../../../itemEditor/utils/itemElementDataConverters/utils/LinkDetail.tsx';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { EmailLinkDialog } from './EmailLinkDialog.tsx';
import { EmailLinkInfo } from './EmailLinkInfo.tsx';

interface IEmailLinkProps {
  readonly children: ReadonlyArray<React.ReactNode>;
  readonly disabled?: boolean;
  readonly emailAddress: string;
  readonly emailSubject: string;
  readonly onCancel?: () => void;
  readonly onEdit?: () => Promise<void>;
  readonly onUnlink?: () => void;
  readonly onUpdate?: (values: EmailLinkProperties) => void;
  readonly status?: EditorLinkStatus;
  readonly text: string;
}

enum DialogType {
  None = 'none',
  Info = 'info',
  Edit = 'edit',
}

export const EmailLink: React.FC<IEmailLinkProps> = (props) => {
  const [dialog, setDialog] = useState(DialogType.None);

  const startEdit = (): void => {
    if (props.onEdit) {
      props.onEdit().then(() => setDialog(DialogType.Edit));
    }
  };

  const cancelEdit = (): void => {
    if (props.onCancel) {
      props.onCancel();
      setDialog(DialogType.None);
    }
  };

  const updateLink = (values: EmailLinkProperties): void => {
    if (props.onUpdate) {
      props.onUpdate(values);
      setDialog(DialogType.None);
    }
  };

  const toggleActive = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (dialog !== DialogType.Edit) {
      const selectionCollapsed = isSelectionCollapsed();
      setDialog(
        dialog === DialogType.None && selectionCollapsed ? DialogType.Info : DialogType.None,
      );
    }
    e.preventDefault();
  };

  const handleClose = (): void => {
    if (dialog === DialogType.Info) {
      setDialog(DialogType.None);
    }
  };

  const renderPopover = (): ReactNode => {
    const { disabled, emailAddress, emailSubject, status, onUnlink } = props;

    if (dialog === DialogType.Info) {
      return (
        <LinkDetail disabled={disabled} onEdit={startEdit} onUnlink={onUnlink} status={status}>
          <EmailLinkInfo
            disabled={disabled}
            emailAddress={emailAddress}
            emailSubject={emailSubject}
          />
        </LinkDetail>
      );
    }

    if (dialog === DialogType.Edit) {
      return (
        <EmailLinkDialog
          emailAddress={emailAddress}
          emailSubject={emailSubject}
          linkText={props.text}
          onCancel={cancelEdit}
          onUnlink={onUnlink}
          onUpsert={updateLink}
        />
      );
    }

    return null;
  };

  const { emailAddress, emailSubject } = props;

  return (
    <InlineContentWithDialog
      isInfo={dialog === DialogType.Info}
      renderContent={(ref) => (
        <a
          ref={ref}
          className={classNames('rte__link', 'rte__link--is-clickable', {
            'rte__link--is-edited': dialog === DialogType.Edit,
          })}
          onClick={toggleActive}
          data-email-address={emailAddress}
          data-email-subject={emailSubject}
        >
          {props.children}
        </a>
      )}
      renderDialog={renderPopover}
      onClose={handleClose}
    />
  );
};

EmailLink.displayName = 'EmailLink';
