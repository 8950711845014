import { compressJsonString } from '../../../../../_shared/utils/jsonUtils.ts';
import { CustomTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  ICustomTypeElementData,
  getCustomTypeElementDataFromServerModel,
} from '../../models/elements/CustomTypeElementData.tsx';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';

function convertTypeElementRecordToServerModel(
  typeElement: ICustomTypeElementData,
): CustomTypeElementServerModel {
  const configForServerModel = typeElement.config ? compressJsonString(typeElement.config) : null;

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    allowedElements: typeElement.allowedElements,
    config: configForServerModel,
    sourceUrl: typeElement.sourceUrl,
    type: ElementType.Custom,
  };
}

export function createCustomTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: CustomTypeElementServerModel) =>
      getCustomTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: ICustomTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
