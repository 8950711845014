import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { ILanguage, Languages } from '../../../../../../data/models/languages/Language.ts';
import { ILanguagesData } from '../../../../../../data/reducers/languages/ILanguagesData.type.ts';
import { getAllActiveLanguagesForCurrentUserForCollection } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { IUser } from '../../../../../../data/reducers/user/IUser.type.ts';
import { IContentItemVariantReference } from '../../../../models/contentItem/ContentItemVariantReference.ts';

const getLanguageOptionsArray = memoize.maxOne(
  (options: ILanguage[]): ReadonlyArray<IBaseSelectItem> =>
    options.map(
      ({ id, name }): IBaseSelectItem => ({
        id,
        label: name,
      }),
    ),
);

const getAvailableVariantLanguages = (
  languages: Languages,
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
  currentLanguageId: Uuid | null,
): ILanguage[] => {
  return languages
    .valueSeq()
    .toArray()
    .filter((language) => {
      const variant = contentItemVariants.get(language.id);
      const doesVariantExist = !!variant && !variant.isArchived;
      return (
        language.id !== currentLanguageId &&
        doesVariantExist &&
        hasActiveVariantCapability(
          ActiveCapabilityType.ViewContent,
          contentItemVariants.get(language.id),
        )
      );
    });
};

export const getSourceLanguagesForVariantTranslation = (
  languages: ILanguagesData,
  selectedLanguageId: Uuid | null,
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
  user: IUser,
  projectId: Uuid,
  collectionId: Uuid | null,
): ReadonlyArray<IBaseSelectItem> => {
  const activeLanguagesForUser = getAllActiveLanguagesForCurrentUserForCollection(
    user,
    projectId,
    collectionId,
    languages,
    Capability.ViewContent,
  );
  const activeVariantLanguagesForUser = getAvailableVariantLanguages(
    activeLanguagesForUser,
    contentItemVariants,
    selectedLanguageId,
  );
  return getLanguageOptionsArray(activeVariantLanguagesForUser);
};
