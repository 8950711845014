import { Action } from '../../../../@types/Action.type.ts';
import { Data_ItemsAssignedToCurrentUser_Started } from '../../../../data/constants/dataActionTypes.ts';
import { YourContent_PageLeft } from '../../constants/yourContentActionTypes.ts';

const initialState: Uuid = '';

export const cancellationToken = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Data_ItemsAssignedToCurrentUser_Started: {
      return action.payload.cancellationToken;
    }

    case YourContent_PageLeft: {
      return initialState;
    }

    default:
      return state;
  }
};
