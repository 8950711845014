import { createGuid, notNullNorUndefined } from '@kontent-ai/utils';
import { ContentState, EditorState } from 'draft-js';
import { useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { ElementReference } from '../../../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { emptyComment } from '../../../../../applications/itemEditor/models/comments/Comment.ts';
import {
  CommentThreadType,
  IInlineCommentThread,
  emptyCommentThread,
} from '../../../../../applications/itemEditor/models/comments/CommentThreads.ts';
import { emptySuggestion } from '../../../../../applications/itemEditor/models/comments/Suggestion.ts';
import { useEditorStateCallbacks } from '../../../../../applications/richText/editorCore/hooks/useEditorStateCallbacks.ts';
import { Apply } from '../../../../../applications/richText/editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../../../applications/richText/editorCore/types/EditorChangeReason.ts';
import { CommentsPlugin } from '../../../../../applications/richText/plugins/comments/CommentsPlugin.tsx';
import {
  getSelectedText,
  getSelectionOfAllContent,
} from '../../../../../applications/richText/utils/editorSelectionUtils.ts';
import { saveNewAiInlineCommentThreadToServer } from '../../../contentItemEditing/actions/thunkAiReviewEditingActions.ts';
import { minimizeCommentSelections } from './utils/minimizeCommentSelections.ts';
import { ReviewComment, getCommentSelection } from './utils/reviewCommentUtils.ts';

export const useCreateReviewComments = (
  element: ElementReference,
): {
  readonly createComments: (
    newComments: ReadonlyArray<ReviewComment>,
  ) => Promise<IInlineCommentThread[]>;
  readonly decorateWithCreateCommentsCallbacks: Apply<CommentsPlugin>;
} => {
  const dispatch = useDispatch();

  const {
    decorateWithEditorStateCallbacks: decorateWithCreateCommentsCallbacks,
    executeChange,
    getApi,
    propagatePendingContentChanges,
  } = useEditorStateCallbacks<CommentsPlugin>();

  // Gets review comments generated by AI and filters out comments whose comment selection is not found in the current editor state.
  // Returns comment threads that were actually added to the editor state.
  const createComments = useCallback(
    async (newComments: ReadonlyArray<ReviewComment>) => {
      if (!newComments.length) {
        return [];
      }

      const addedCommentThreads: Array<IInlineCommentThread> = [];
      let originalEditorState: EditorState | null = null;

      const newEditorState = await executeChange((editorState) => {
        originalEditorState = editorState;
        const content = editorState.getCurrentContent();

        return newComments.reduce((editorStateWithAddedComments, comment) => {
          const externalSegmentId = createGuid();
          const commentSelection = getCommentSelection(content, comment);
          if (!commentSelection) {
            return editorStateWithAddedComments;
          }

          const minimizedSelections = minimizeCommentSelections(
            content,
            commentSelection,
            comment.replacement,
            getSelectionOfAllContent(comment.replacement),
          );

          if (!minimizedSelections) {
            return editorStateWithAddedComments;
          }

          const {
            originalSelection: minimumCommentSelection,
            replacementSelection: minimumReplacementSelection,
          } = minimizedSelections;

          const editorStateWithAddedComment = getApi().addCommentToText(
            editorStateWithAddedComments,
            minimumCommentSelection,
            externalSegmentId,
          );

          if (element.itemId && editorStateWithAddedComments !== editorStateWithAddedComment) {
            const suggestedText = getSelectedText(comment.replacement, minimumReplacementSelection);

            addedCommentThreads.push({
              ...emptyCommentThread,
              contentComponentId: element.contentComponentId,
              contentItemId: element.itemId.itemId,
              elementId: element.elementId,
              elementSegment: getSelectedText(content, minimumCommentSelection),
              externalSegmentId,
              id: createGuid(),
              threadItems: Immutable.List(
                [
                  {
                    ...emptyComment,
                    content: ContentState.createFromText(comment.comment),
                  },
                  isEmptyOrWhitespace(suggestedText)
                    ? null
                    : {
                        ...emptySuggestion,
                        suggestedText,
                      },
                ].filter(notNullNorUndefined),
              ),
              threadType: CommentThreadType.RichText,
              variantId: element.itemId.variantId,
            });
          }

          return editorStateWithAddedComment;
        }, editorState);
      }, EditorChangeReason.Comment);

      if (newEditorState !== originalEditorState) {
        await propagatePendingContentChanges();
        addedCommentThreads.forEach((thread) => {
          dispatch(saveNewAiInlineCommentThreadToServer(thread));
        });
        return addedCommentThreads;
      }

      return [];
    },
    [executeChange, element, getApi, propagatePendingContentChanges],
  );

  return {
    createComments,
    decorateWithCreateCommentsCallbacks,
  };
};
