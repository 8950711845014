import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { trimAndRemoveMultipleSpaces } from '../../../../_shared/utils/stringUtils.ts';
import { closeDeleteEnvironmentDialog } from '../actions/environmentsActions.ts';
import { deleteEnvironment } from '../actions/thunkEnvironmentsActions.ts';
import { DeleteEnvironmentDialog as DeleteEnvironmentDialogComponent } from '../components/DeleteEnvironmentDialog.tsx';

export const DeleteEnvironmentDialog: React.FC = () => {
  const showDialog = useSelector((s) => s.environmentsApp.showDialogs.deleteDialog);
  const deletedEnvironmentId = useSelector((s) => s.environmentsApp.editedEnvironmentId);
  const deletingEnvironment = useSelector((s) => s.environmentsApp.deletingEnvironment);
  const deletingEnvironmentFailed = useSelector((s) => s.environmentsApp.deletingEnvironmentFailed);
  const environmentName = useSelector((s) =>
    trimAndRemoveMultipleSpaces(
      s.data.projects.byId.get(deletedEnvironmentId)?.environmentName || '',
    ),
  );

  const dispatch = useDispatch();

  return (
    <DeleteEnvironmentDialogComponent
      deletedEnvironmentId={deletedEnvironmentId}
      deletingEnvironment={deletingEnvironment}
      deletingEnvironmentFailed={deletingEnvironmentFailed}
      environmentName={environmentName}
      showDialog={showDialog}
      onClose={() => dispatch(closeDeleteEnvironmentDialog())}
      onDelete={(environmentId: Uuid) => dispatch(deleteEnvironment(environmentId))}
    />
  );
};

DeleteEnvironmentDialog.displayName = 'DeleteEnvironmentDialog';
