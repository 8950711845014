import React from 'react';
import { OnboardingNotificationsSelector as Component } from '../../components/OnboardingNotifications/OnboardingNotificationsSelector.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { isTechnicalRole } from '../../models/utils/quickstartUtils.ts';

export const OnboardingNotificationsSelector: React.FC = () => {
  const businessRole = useSelector((r) => r.sharedApp.userProperties.businessRole);
  const currentOnboardingNotification = useSelector(
    (r) => r.data.user.currentOnboardingNotification,
  );

  return (
    <Component
      onboardingNotification={currentOnboardingNotification?.notification ?? null}
      additionalData={currentOnboardingNotification?.additionalData ?? null}
      isTechnicalRole={isTechnicalRole(businessRole)}
    />
  );
};
