import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { IUiPreferenceRepository } from '../../../../../../repositories/interfaces/IUiPreferenceRepository.type.ts';
import { UiPreference } from '../../../../../../repositories/uiPreferenceRepository.ts';

type Deps = {
  readonly getCurrentPreference: (state: IStore) => ReadonlyArray<Uuid> | null;
  readonly uiPreference: UiPreference;
  readonly uiPreferenceRepository: IUiPreferenceRepository;
  readonly updateNewPreference: (preference: ReadonlyArray<Uuid>) => Action;
};

export const createLoadContentItemUiPreferenceAction =
  ({ getCurrentPreference, uiPreference, uiPreferenceRepository, updateNewPreference }: Deps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const currentPreference = getCurrentPreference(getState());

    if (currentPreference) {
      return;
    }

    const preference = await uiPreferenceRepository.getOne<ReadonlyArray<Uuid>>(
      uiPreference,
      abortSignal,
    );

    dispatch(updateNewPreference(preference ?? []));
  };
