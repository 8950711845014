import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { RedactedItemName } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

type Props = {
  readonly areCollectionsVisible: boolean;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly collectionName: string | undefined;
  readonly contentItemName: string;
  readonly contentTypeName: string;
  readonly hideDescription?: boolean;
  readonly isCompact?: boolean;
  readonly isInvalid: boolean | undefined;
  readonly isUnfinished?: boolean;
  readonly onClick?: (() => void) | undefined;
  readonly renderExpandToggle?: () => ReactNode;
  readonly renderWorkflowStep?: () => ReactNode;
};

export const LoadedLinkedItemContent: React.FC<Props> = ({
  areCollectionsVisible,
  cannotViewDisabledMessage,
  collectionName,
  contentItemName,
  contentTypeName,
  hideDescription,
  isCompact,
  isInvalid,
  isUnfinished,
  onClick,
  renderExpandToggle,
  renderWorkflowStep,
}) => {
  const isRedacted = !!cannotViewDisabledMessage;

  return (
    <div
      className="bar-item__title-pane"
      onClick={onClick}
      {...getDataUiElementAttribute(DataUiElement.BarItem)}
    >
      <div
        className={classNames('bar-item__title bar-item__title--with-flex', {
          'bar-item__title--size-xs': isCompact,
        })}
      >
        {renderExpandToggle?.()}
        <div
          className={classNames('bar-item__title-name', {
            'bar-item__title-name--is-redacted': isRedacted,
            'bar-item__title-name--is-unfinished': isUnfinished,
          })}
        >
          <Tooltip
            tooltipText={isRedacted ? cannotViewDisabledMessage : contentItemName}
            placement="top-start"
          >
            <IgnoreByGrammarly className="bar-item__title-name-text">
              {isRedacted ? RedactedItemName : contentItemName}
            </IgnoreByGrammarly>
          </Tooltip>
        </div>
      </div>
      {!hideDescription && (
        <>
          <div
            className={classNames('bar-item__title-description', {
              'bar-item__title-description--is-invalid': isInvalid,
            })}
          >
            <Tooltip tooltipText={`Content type: ${contentTypeName}`} placement="top-start">
              <IgnoreByGrammarly>{contentTypeName}</IgnoreByGrammarly>
            </Tooltip>
          </div>
          {areCollectionsVisible && (
            <div className={classNames('bar-item__title-description')}>
              {collectionName && (
                <Tooltip tooltipText={`Collection: ${collectionName}`} placement="top-start">
                  <IgnoreByGrammarly>{collectionName}</IgnoreByGrammarly>
                </Tooltip>
              )}
            </div>
          )}
        </>
      )}
      {renderWorkflowStep?.()}
    </div>
  );
};

LoadedLinkedItemContent.displayName = 'LoadedLinkedItemContent';
