import classNames from 'classnames';
import React from 'react';

type Props = {
  readonly originalValue?: string | null;
  readonly placeholderText?: string;
  readonly value: string;
};

export const SimpleValueDiff: React.FC<Props> = ({ originalValue, placeholderText, value }) => {
  const valueIsEmpty = value === '';
  const originalValueIsEmpty = originalValue === '';

  const isAdded = originalValueIsEmpty && !valueIsEmpty;
  const isRemoved = valueIsEmpty && !originalValueIsEmpty;
  const isChanged = !isAdded && !isRemoved && value !== originalValue;

  const isCompare = originalValue !== null && originalValue !== undefined;

  return (
    <div
      className={classNames('diff__simple-value', {
        'diff__simple-value--is-compare': isCompare,
        'diff__simple-value--is-empty': valueIsEmpty,
      })}
    >
      {isCompare ? (
        <>
          <span
            className={classNames('diff__chunk', {
              'diff__chunk--is-added': !!value && (isAdded || isChanged),
            })}
          >
            {value}
          </span>
          {originalValue && (isRemoved || isChanged) && (
            <span className="diff__chunk diff__chunk--is-removed">{originalValue}</span>
          )}
        </>
      ) : (
        <ValueContent value={value} placeholderText={placeholderText} />
      )}
    </div>
  );
};

SimpleValueDiff.displayName = 'SimpleValueDiff';

const ValueContent: React.FC<{
  readonly placeholderText?: string;
  readonly value: string;
}> = ({ placeholderText, value }) =>
  value === '' ? (
    <div className="content-item-element__placeholder">{placeholderText}</div>
  ) : (
    <span>{value}</span>
  );

ValueContent.displayName = 'ValueContent';
