import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { FC } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ClearFilterButtonProps {
  readonly onClick: () => void;
}

export const AuditLogClearFilterButton: FC<ClearFilterButtonProps> = ({ onClick }) => (
  <Button
    buttonStyle="tertiary"
    size="medium"
    onClick={onClick}
    {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
  >
    <Button.Icon icon={Icons.Times} />
    <Button.Label>Clear</Button.Label>
  </Button>
);

AuditLogClearFilterButton.displayName = 'AuditLogClearFilterButton';
