import { InputState } from '@kontent-ai/component-library/Input';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { anyEnvironmentOption } from '../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { EnvironmentFilterOption } from '../../../../_shared/models/UserListingFilterOption.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getEnvironments } from '../../../projectSettings/environments/selectors/getEnvironments.ts';
import { getProjectsSortedByEnvironmentName } from '../../../projects/selectors/projectSelectors.ts';
import { environmentFilterUpdated } from '../actions/subscriptionUserListingActions.ts';
import { loadSubscriptionUserListingFilterDeps } from '../actions/thunkSubscriptionUsersActions.ts';
import { getEnvironmentFilterOptions } from '../utils/getFilterOptions.ts';

const getEnvironmentOptions = (s: IStore): ReadonlyArray<EnvironmentFilterOption> => {
  const selectedProjectId = s.subscriptionApp.users.listingUi.filter.byProject;
  const environments = getEnvironments(s, selectedProjectId, false);
  const sortedEnvironments = getProjectsSortedByEnvironmentName(environments);
  const withAnyEnvironment = getEnvironmentFilterOptions(sortedEnvironments, true);
  return withAnyEnvironment;
};

const getEnvironmentOption = memoize.weak(
  (
    environments: ReadonlyArray<EnvironmentFilterOption>,
    environmentId: Uuid,
    isProjectSelected: boolean,
  ) =>
    environments.find((option: EnvironmentFilterOption) => environmentId === option.id) ||
    getAnyEnvironmentOption(isProjectSelected),
);

const getSelectedEnvironmentOption = (
  s: IStore,
  environments: ReadonlyArray<EnvironmentFilterOption>,
  isProjectSelected: boolean,
): EnvironmentFilterOption => {
  const environmentId = s.subscriptionApp.users.listingUi.filter.byEnvironment;
  return getEnvironmentOption(environments, environmentId, isProjectSelected);
};

const getAnyEnvironmentOption = (isProjectSelected: boolean): EnvironmentFilterOption =>
  isProjectSelected
    ? anyEnvironmentOption
    : {
        ...anyEnvironmentOption,
        label: 'Select project first',
      };

export const SubscriptionUsersEnvironmentFilter: React.FC = () => {
  const isProjectSelected = useSelector(
    (s) => !!s.subscriptionApp.users.listingUi.filter.byProject,
  );
  const environments = useSelector(getEnvironmentOptions);
  const selectedEnvironmentOption = useSelector((s) =>
    getSelectedEnvironmentOption(s, environments, isProjectSelected),
  );

  const dispatch = useDispatch();
  const onUpdateFilter = async (updatedEnvironmentOptionId: EnvironmentFilterOption['id']) => {
    const updatedEnvironmentId =
      updatedEnvironmentOptionId === anyEnvironmentOption.id ? '' : updatedEnvironmentOptionId;
    if (updatedEnvironmentId !== selectedEnvironmentOption.id) {
      dispatch(environmentFilterUpdated(updatedEnvironmentId));

      if (updatedEnvironmentId) {
        await dispatch(loadSubscriptionUserListingFilterDeps(updatedEnvironmentId));
      }
    }
  };

  return (
    <SingleSelect<EnvironmentFilterOption>
      inputState={isProjectSelected ? InputState.Default : InputState.Disabled}
      label="Environments"
      onSelectionChange={onUpdateFilter}
      items={environments}
      selectedItemId={selectedEnvironmentOption.id}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Environments)}
      {...getDataUiCollectionAttribute(DataUiCollection.Environments)}
    />
  );
};

SubscriptionUsersEnvironmentFilter.displayName = 'SubscriptionUsersEnvironmentFilter';
