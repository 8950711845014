import { IScrollState } from '../../../../_shared/utils/scrollGridUtils.ts';
import {
  AuditLog_Listing_Left,
  AuditLog_ScrollState_Changed,
} from '../constants/auditLogActionTypes.ts';

export const leaveAuditLog = () =>
  ({
    type: AuditLog_Listing_Left,
  }) as const;

export const auditLogScrollStateChanged = (state: IScrollState) =>
  ({
    type: AuditLog_ScrollState_Changed,
    payload: { ...state },
  }) as const;
