import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IPlan } from '../../../data/models/plans/Plan.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  ISubscription,
  emptySubscription,
} from '../../../data/models/subscriptions/Subscription.ts';
import {
  ISubscriptionWarningLinkData,
  SuspendedSubscriptionsWarning as SuspendedSubscriptionsWarningComponent,
} from '../components/SuspendedSubscriptionsWarning.tsx';

const getSubscriptionLinks = (
  subscriptions: Immutable.Map<Uuid, ISubscription>,
  plans: Immutable.Map<Uuid, IPlan>,
  projects: Immutable.List<IProjectDetails>,
): ReadonlyArray<ISubscriptionWarningLinkData> =>
  projects
    .groupBy((p: IProjectDetails) => p.subscriptionId)
    .toArray()
    .map((g: Immutable.Map<number, IProjectDetails>) => {
      const firstDetail = g.first();

      if (!firstDetail) {
        return null;
      }

      const subscription = subscriptions.get(firstDetail.subscriptionId) || emptySubscription;
      const isPrepaid = !!plans.get(subscription.currentPlan.planId)?.isPrepaid;

      return (
        firstDetail && {
          isPrepaid,
          statusReason: subscription.statusReason,
          subscriptionId: firstDetail.subscriptionId,
          subscriptionName: firstDetail.subscriptionName,
        }
      );
    })
    .filter(notNull);

export const SuspendedSubscriptionsWarning: React.FC = () => {
  const projects = useSelector((state) => state.data.projects.byId);
  const administratedSubscriptionsIds = useSelector(
    (state) => state.data.subscriptions.administratedIds,
  );
  const subscriptions = useSelector((state) => state.data.subscriptions.byId);
  const plans = useSelector((state) => state.data.plans.byId);

  const suspendedProjects = projects.filter((project: IProjectDetails) => project.planIsSuspended);
  const administratedSuspendedProjects = suspendedProjects
    .filter((p: IProjectDetails) => administratedSubscriptionsIds.contains(p.subscriptionId))
    .toList();
  const administratedSubscriptionLinks = getSubscriptionLinks(
    subscriptions,
    plans,
    administratedSuspendedProjects,
  );
  const notAdministratedProjects = suspendedProjects
    .filter((p: IProjectDetails) => !administratedSubscriptionsIds.contains(p.subscriptionId))
    .toList();
  const notAdministratedSubscriptionLinks = getSubscriptionLinks(
    subscriptions,
    plans,
    notAdministratedProjects,
  );

  if (suspendedProjects.isEmpty()) {
    return null;
  }

  return (
    <SuspendedSubscriptionsWarningComponent
      administratedSubscriptionLinks={administratedSubscriptionLinks}
      notAdministratedSubscriptionLinks={notAdministratedSubscriptionLinks}
    />
  );
};

SuspendedSubscriptionsWarning.displayName = 'SuspendedSubscriptionsWarningContainer';
