import React from 'react';
import { useHistory, useParams } from 'react-router';
import { AssetListingRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { navigatingToFolder } from '../../actions/assetLibraryActions.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { redirectToAssetFolder } from '../../utils/assetsPathUtils.ts';
import { AssetFoldersBreadcrumbs } from './AssetFoldersBreadcrumbs.tsx';

type Props = {
  readonly clearAssetSelection: () => void;
};

export const AssetFoldersBreadcrumbsInLibrary: React.FC<Props> = ({ clearAssetSelection }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<AssetListingRouteParams>();

  const openAssetFolder = (assetFolderId: Uuid): void => {
    clearAssetSelection();
    dispatch(navigatingToFolder());
    redirectToAssetFolder({
      assetFolderId,
      history,
      projectId,
    });
  };

  return (
    <AssetFoldersBreadcrumbs
      onFolderOpen={openAssetFolder}
      onFolderReset={() => openAssetFolder(RootFolderId)}
    />
  );
};

AssetFoldersBreadcrumbsInLibrary.displayName = 'AssetFoldersBreadcrumbsInLibrary';
