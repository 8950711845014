import { useRef, useState } from 'react';
import { useEventListener } from './useEventListener.ts';

/**
 * A hook wrapper for MatchMedia browser API.
 * @param {string} query - Match media query (see https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries).
 * @returns {boolean}
 */
export const useMatchMedia = (query: string): boolean => {
  const mediaQueryListRef = useRef(self.matchMedia(query));
  const [matches, setMatches] = useState(mediaQueryListRef.current.matches);
  useEventListener(
    'change',
    () => setMatches(mediaQueryListRef.current.matches),
    mediaQueryListRef.current,
  );
  return matches;
};
