import { Collection, delay } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { getLanguageCodename } from '../../../../_shared/utils/languageUtils.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import {
  WebSpotlight_PreviewApiPolling_Aborted,
  WebSpotlight_PreviewApiPolling_ChangeReady,
  WebSpotlight_PreviewApiPolling_Failed,
  WebSpotlight_PreviewApiPolling_Finished,
  WebSpotlight_PreviewApiPolling_Started,
} from '../../constants/webSpotlightActionTypes.ts';

interface IDeps {
  readonly webSpotlightRepository: Pick<
    IWebSpotlightRepository,
    'getItemLastModificationDateFromPreviewApi'
  >;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

const PreviewApiChangesPollingIntervalMs = 2000;

const started = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Started,
  }) as const;

const aborted = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Aborted,
  }) as const;

const changeReady = (itemCodename: string) =>
  ({
    type: WebSpotlight_PreviewApiPolling_ChangeReady,
    payload: {
      itemCodename,
    },
  }) as const;

const finished = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Finished,
  }) as const;

const failed = () =>
  ({
    type: WebSpotlight_PreviewApiPolling_Failed,
  }) as const;

export type StartPreviewApiChangesPollingActionsType = ReturnType<
  typeof started | typeof changeReady | typeof failed | typeof aborted | typeof finished
>;

export const createStartPreviewApiChangesPollingAction =
  (deps: IDeps) =>
  (onChangeReady: (itemCodename: string) => void, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      const {
        data: { languages },
        sharedApp: { selectedLanguage },
        webSpotlightApp: { lastModifiedPreviewItems },
      } = getState();

      const languageCodename = getLanguageCodename(
        selectedLanguage.id ?? '',
        languages.defaultLanguage,
        languages.byId,
      );

      dispatch(started());

      const lastModificationDateByItemCodename = new Map(lastModifiedPreviewItems);

      while (!abortSignal.aborted && lastModificationDateByItemCodename.size > 0) {
        await Promise.all(
          Collection.getEntries(lastModificationDateByItemCodename).map(
            async ([itemCodename, lastModifiedDate]) => {
              const response =
                await deps.webSpotlightRepository.getItemLastModificationDateFromPreviewApi(
                  languageCodename,
                  itemCodename,
                  abortSignal,
                );
              const updatedLastModifiedDateMs = new Date(response.lastModified).getTime();
              if (!abortSignal.aborted && updatedLastModifiedDateMs >= lastModifiedDate.getTime()) {
                dispatch(
                  deps.trackUserEventWithData(
                    TrackedEvent.WebSpotlightUserChangesReadyOnPreviewApi,
                    {
                      msSinceChangeSaved: Date.now() - updatedLastModifiedDateMs,
                    },
                  ),
                );

                onChangeReady(itemCodename);
                dispatch(changeReady(itemCodename));
                lastModificationDateByItemCodename.delete(itemCodename);
              }
            },
          ),
        );

        if (lastModificationDateByItemCodename.size === 0) {
          dispatch(finished());
          return;
        }

        await delay(PreviewApiChangesPollingIntervalMs);
      }

      dispatch(aborted());
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
