import { identity } from '@kontent-ai/utils';
import { animated, useTransition } from '@react-spring/web';
import React, { ReactNode, forwardRef, memo, useEffect, useState } from 'react';
import { useGradualSequence } from '../../../../../_shared/hooks/useGradualSequence.ts';
import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCheckboxCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCheckboxCell.tsx';
import { ScrollTableDefaultRowSkelet } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableDefaultRowSkelet.tsx';
import { ScrollTableExpandToggleCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableExpandToggleCell.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ItemNameCellContent,
  ItemTypeCellContent,
  OpenInNewTabCell,
  WorkflowStatusWithFallbackBadgesCell,
} from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { PlaceholderForCheckboxCell } from './PlaceholderForCheckboxCell.tsx';
import { RedactedChildRow } from './RedactedChildRow.tsx';
import { ResponsiveCascadeCellsGroup } from './ResponsiveCascadeCellsGroup.tsx';

type Props = Omit<ContentProps, 'item'> &
  ChildItemsProps & {
    readonly cannotViewMessage: string | undefined;
    readonly item: IListingContentItem | null;
  };

export const ChildRow = forwardRef<HTMLDivElement, Props>(
  ({ cannotViewMessage, childContentItemIds, item, renderChildRow, ...contentProps }, ref) => {
    const { depth, isExpanded } = contentProps;

    // We use one animated div for all items to reduce overhead in case there are many child items
    // we also keep the list of items to render to keep them rendered upon collapse animation
    const [renderChildItemIds, setRenderChildItemIds] = useState<
      ReadonlyArray<MemoizedContentItemId>
    >([]);
    useEffect(() => {
      if (isExpanded) {
        setRenderChildItemIds(childContentItemIds);
      }
    }, [isExpanded, childContentItemIds]);

    const transitions = useTransition(isExpanded, {
      // The animation here is used so that the user realizes something collapsed, if it was without animation,
      // the actual change could be just contents of the cells changing text which may not be noticeable enough by the user
      // As we don't want the user to wait much, we make the animation faster than default, but still noticeable enough
      config: {
        tension: 350,
        clamp: true,
      },
      from: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
      },
      leave: {
        opacity: 0,
      },
      onRest: () => {
        if (!isExpanded) {
          setRenderChildItemIds([]);
        }
      },
    });

    if (!item) {
      return <ScrollTableDefaultRowSkelet />;
    }

    if (cannotViewMessage) {
      return <RedactedChildRow depth={depth} item={item} tooltip={cannotViewMessage} />;
    }

    return (
      <>
        <ChildRowContent ref={ref} item={item} {...contentProps} />
        {!!renderChildItemIds.length &&
          transitions(
            (style, renderExpanded) =>
              renderExpanded && (
                <animated.div style={style}>
                  <ChildItems
                    childContentItemIds={renderChildItemIds}
                    renderChildRow={renderChildRow}
                  />
                </animated.div>
              ),
          )}
      </>
    );
  },
);

ChildRow.displayName = 'ChildRow';

type ContentProps = {
  readonly cannotOpenMessage: string | undefined;
  readonly contentType: IContentType | null;
  readonly depth: number;
  readonly disabledMessage: string | undefined;
  readonly hasAnyChildNodes: boolean;
  readonly isExpanded: boolean;
  readonly isLoadingChildNodes: boolean;
  readonly isSelected: boolean;
  readonly item: IListingContentItem;
  readonly itemPath: string;
  readonly onItemCollapsed: () => void;
  readonly onItemDeselected: () => void;
  readonly onItemExpanded: () => void;
  readonly onItemSelected: () => void;
  readonly variantId: string;
};

const ChildRowContent = memo(
  forwardRef<HTMLDivElement, ContentProps>(
    (
      {
        cannotOpenMessage,
        contentType,
        depth,
        disabledMessage,
        hasAnyChildNodes,
        isExpanded,
        item,
        itemPath,
        onItemCollapsed,
        onItemDeselected,
        onItemExpanded,
        onItemSelected,
        isLoadingChildNodes,
        isSelected,
        variantId,
      },
      ref,
    ) => {
      const onToggleSelect = isSelected ? onItemDeselected : onItemSelected;

      const onToggleExpand = isExpanded ? onItemCollapsed : onItemExpanded;

      const clickableProps = {
        isClickable: hasAnyChildNodes,
        disabledTooltip: hasAnyChildNodes ? undefined : disabledMessage,
      };

      return (
        <ScrollTableRow
          elementName={DataUiElement.CascadePublishChildItem}
          isLoading={isLoadingChildNodes}
          isSelected={isSelected}
          objectName={item.item.name}
          ref={ref}
        >
          <ResponsiveCascadeCellsGroup>
            {Array(depth)
              .fill(null)
              .map((_val, index) => (
                <PlaceholderForCheckboxCell
                  key={index}
                  disabledMessage={clickableProps.disabledTooltip}
                />
              ))}

            <ScrollTableCheckboxCell
              disabledMessage={disabledMessage}
              id={item.item.id}
              isItemChecked={isSelected}
              name="Select item"
              onCheckboxClick={onToggleSelect}
            />

            <ScrollTableExpandToggleCell
              isExpanded={isExpanded}
              isHidden={!hasAnyChildNodes}
              onToggle={onToggleExpand}
              disabledMessage={clickableProps.disabledTooltip}
            />

            <ScrollTableCell
              size={10}
              title={item.item.name}
              onClick={onToggleExpand}
              {...clickableProps}
            >
              <ItemNameCellContent
                item={item}
                variantId={variantId}
                disabled={!!clickableProps.disabledTooltip}
              />
            </ScrollTableCell>

            <OpenInNewTabCell path={itemPath} disabledMessage={cannotOpenMessage} />
          </ResponsiveCascadeCellsGroup>

          <WorkflowStatusWithFallbackBadgesCell
            variant={item.variant}
            itemId={item.item.id}
            onToggleExpand={onToggleExpand}
            {...clickableProps}
          />

          <ScrollTableCell
            size={4}
            isGrowing
            title={contentType?.name}
            onClick={onToggleExpand}
            {...clickableProps}
          >
            <ItemTypeCellContent itemType={contentType} />
          </ScrollTableCell>
        </ScrollTableRow>
      );
    },
  ),
);

ChildRowContent.displayName = 'ChildRowContent';

type ChildItemsProps = {
  readonly renderChildRow: (contentItemId: MemoizedContentItemId) => ReactNode;
  readonly childContentItemIds: ReadonlyArray<MemoizedContentItemId>;
};

const ChildItems: React.FC<ChildItemsProps> = memo(({ childContentItemIds, renderChildRow }) => {
  // When we are dealing with more than 1000 items, mount them in chunks to avoid browser getting stuck on too many new components
  const { renderItems } = useGradualSequence(childContentItemIds, identity, { chunkSize: 1000 });

  return <>{renderItems.map((contentItemId) => renderChildRow(contentItemId))}</>;
});

ChildItems.displayName = 'ChildItems';
