import { combineReducers } from '@reduxjs/toolkit';
import { currentOnboardingNotification } from './reducers/currentOnboardingNotification.ts';
import { info } from './reducers/info.ts';
import { normalizedRolesWithSettingsPerProject } from './reducers/normalizedRolesWithSettingsPerProject.ts';
import { projectsInfoById } from './reducers/projectsInfoById.ts';

export const user = combineReducers({
  currentOnboardingNotification,
  info,
  normalizedRolesWithSettingsPerProject,
  projectsInfoById,
});
