import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';

interface ISimpleTitleTabProps {
  readonly additionalIconClass?: string;
  readonly hideIconOnSmallScreen?: boolean;
  readonly iconName?: IconName;
  readonly imageClass?: string;
  readonly title: string;
  readonly modifier?: SimpleTabTitleModifier;
}

export enum SimpleTabTitleModifier {
  IsInYourContent = 'tabbed-navigation__tab-title--in-your-content',
  IsInContentGroup = 'tabbed-navigation__tab-title--in-content-group',
}

export const SimpleTabTitle: React.FC<ISimpleTitleTabProps> = (props) => (
  <span className={classNames('tabbed-navigation__tab-title', props.modifier)}>
    {props.iconName && (
      <Icon
        iconName={props.iconName}
        className={classNames(props.additionalIconClass, 'tabbed-navigation__tab-title-icon', {
          'tabbed-navigation__tab-title-icon--hidden-on-small-screen': props.hideIconOnSmallScreen,
        })}
      />
    )}
    {props.imageClass && (
      <i
        className={classNames(props.imageClass, 'tabbed-navigation__tab-title-icon', {
          'tabbed-navigation__tab-title-icon--hidden-on-small-screen': props.hideIconOnSmallScreen,
        })}
      />
    )}
    {props.title}
  </span>
);

SimpleTabTitle.displayName = 'SimpleTabTitle';
