import { roleBuilderAnyLanguageOptionId } from '../constants/roleBuilder.ts';

type Params = {
  readonly activeLanguageIds: ReadonlySet<Uuid>;
  readonly selectedLanguageOptionIds: ReadonlySet<Uuid>;
  readonly userRoleLanguageIds: ReadonlySet<Uuid>;
};

export const getRemainingLanguagesCount = (params: Params): number => {
  const { activeLanguageIds, selectedLanguageOptionIds, userRoleLanguageIds } = params;

  const selectedLanguageIdsWithoutAnyInOtherRoles = [...selectedLanguageOptionIds].filter(
    (id) =>
      id !== roleBuilderAnyLanguageOptionId &&
      activeLanguageIds.has(id) &&
      !userRoleLanguageIds.has(id),
  );

  return activeLanguageIds.size - selectedLanguageIdsWithoutAnyInOtherRoles.length;
};
