import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCheckboxCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCheckboxCell.tsx';
import { ScrollTableExpandToggleCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableExpandToggleCell.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ItemNameCellContent,
  ItemTypeCellContent,
  OpenInNewTabCell,
  WorkflowStatusWithFallbacksCellContent,
} from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { ResponsiveCascadeCellsGroup } from './ResponsiveCascadeCellsGroup.tsx';

interface IParentItemRowDataProps {
  readonly item: IListingContentItem;
  readonly itemPath: string;
  readonly itemType: IContentType;
  readonly selectedLanguageId?: Uuid;
}

export const ParentItemRow: React.FC<IParentItemRowDataProps> = (props) => (
  <ScrollTableRow objectName={props.item.item.name} isSelected>
    <ResponsiveCascadeCellsGroup>
      <ScrollTableCheckboxCell
        disabledMessage="Edited item must be published"
        id={props.item.item.id}
        isItemChecked
        name="Select item"
        onCheckboxClick={noOperation}
      />

      <ScrollTableExpandToggleCell
        disabledMessage="Edited item is always expanded"
        isExpanded
        onToggle={noOperation}
      />

      <ScrollTableCell size={10} title={props.item.item.name}>
        <ItemNameCellContent item={props.item} variantId={props.selectedLanguageId} />
      </ScrollTableCell>

      <OpenInNewTabCell path={props.itemPath} />
    </ResponsiveCascadeCellsGroup>

    <ScrollTableCell size={4} isGrowing>
      <WorkflowStatusWithFallbacksCellContent
        variant={props.item.variant}
        itemId={props.item.item.id}
      />
    </ScrollTableCell>

    <ScrollTableCell size={4} isGrowing title={props.itemType.name}>
      <ItemTypeCellContent itemType={props.itemType} />
    </ScrollTableCell>
  </ScrollTableRow>
);

ParentItemRow.displayName = 'ParentItemRow';
