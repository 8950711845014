import { Box } from '@kontent-ai/component-library/Box';
import { Input } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchPhraseHighlighterElement } from '../../../_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import { LabelFor } from '../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { sortSpacesByName } from '../../../_shared/utils/spaces/sortSpacesByName.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { ISpaceDomain } from '../models/PreviewConfiguration.type.ts';
import { PreviewConfigurationEmptyState } from './PreviewConfigurationEmptyState.tsx';
import { SearchInput } from './SearchInput.tsx';

type Props = {
  readonly onChange: (spaceDomains: ReadonlyArray<ISpaceDomain>) => void;
  readonly spaces: SpacesMap;
  readonly spacesDomains: ReadonlyArray<ISpaceDomain>;
  readonly spacesAppRoute: string;
};

export const SpaceDomains: React.FC<Props> = ({
  onChange,
  spaces,
  spacesAppRoute,
  spacesDomains,
}) => {
  const [searchPhrase, setSearchPhrase] = useState('');

  if (!spaces.size) {
    return (
      <p className="card__content">
        Create a <Link to={spacesAppRoute}>space</Link> first to use the {'{Space}'} macro when
        setting multiple previews.
      </p>
    );
  }

  const filteredAndSortedSpaceDomains = sortSpacesByName(spaces.values()).filter((space) =>
    space.name.toLowerCase().includes(searchPhrase.toLowerCase()),
  );

  return (
    <Stack spacing={Spacing.L}>
      <Box paddingBottom={Spacing.L}>
        <SearchInput
          onChange={setSearchPhrase}
          onClear={() => setSearchPhrase('')}
          placeholderText="Filter by space name"
          searchPhrase={searchPhrase}
        />
      </Box>
      {filteredAndSortedSpaceDomains.length === 0 && searchPhrase.length > 0 ? (
        <PreviewConfigurationEmptyState
          title="We couldn’t find any match."
          text={
            <>
              Looks like a space named “{searchPhrase}” doesn’t exist. Go to{' '}
              <Link to={spacesAppRoute}>spaces</Link> to create one.
            </>
          }
          onCallToAction={() => setSearchPhrase('')}
          callToActionText="Clear search phrase"
        />
      ) : (
        <>
          {filteredAndSortedSpaceDomains.reduce<ReadonlyArray<ReactNode>>(
            (reducedElements, space) => {
              const spaceDomain = spacesDomains.find(({ spaceId }) => spaceId === space.id);
              const index = spacesDomains.findIndex(({ spaceId }) => spaceId === space.id);

              if (!spaceDomain) {
                return reducedElements;
              }

              const element: ReactNode = (
                <div
                  key={space.id}
                  {...getDataUiElementAttribute(DataUiElement.SpaceDomain)}
                  {...getDataUiObjectNameAttribute(space.name)}
                >
                  <LabelFor
                    target={(inputId) => (
                      <Input
                        id={inputId}
                        maxLength={1024}
                        placeholder="Specify a space domain, for example, www.mysite.com"
                        value={spaceDomain.domain}
                        onChange={(e) => {
                          const updatedSpaceDomain: ISpaceDomain = {
                            spaceId: space.id,
                            domain: e.currentTarget.value,
                          };

                          onChange(Collection.replace(spacesDomains, index, updatedSpaceDomain));
                        }}
                        {...getDataUiInputAttribute(DataUiInput.SpaceDomain)}
                      />
                    )}
                  >
                    <SearchPhraseHighlighterElement searchPhrase={searchPhrase} text={space.name} />
                  </LabelFor>
                </div>
              );

              return [...reducedElements, element];
            },
            [],
          )}
        </>
      )}
    </Stack>
  );
};

SpaceDomains.displayName = 'SpaceDomains';
