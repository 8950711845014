import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { DataUiElement } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  CommentThreadIsInaccessible,
  IncompleteElementsAreInaccessible,
} from '../../../../constants/errorMessages.ts';
import { hideElementsAreInaccessibleNotification } from '../../actions/contentItemEditingActions.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';

interface IElementsAreInaccessibleNotificationBarProps {
  readonly trigger: ContentGroupSelectionReason;
}

const getMessage = (trigger: ContentGroupSelectionReason): string | null => {
  switch (trigger) {
    case ContentGroupSelectionReason.FocusCommentThread:
      return CommentThreadIsInaccessible;
    case ContentGroupSelectionReason.FocusIncompleteElement:
      return IncompleteElementsAreInaccessible;
    default:
      return null;
  }
};

export const ElementsAreInaccessibleNotificationBar: React.FC<
  IElementsAreInaccessibleNotificationBarProps
> = ({ trigger }) => {
  const dispatch = useDispatch();
  const message = getMessage(trigger);

  if (!message) {
    return null;
  }

  return (
    <NotificationBar
      type={NotificationBarType.Error}
      uiElement={DataUiElement.NotificationMessageError}
      message={message}
      onClosePanel={() => dispatch(hideElementsAreInaccessibleNotification())}
    />
  );
};

ElementsAreInaccessibleNotificationBar.displayName = 'ElementsAreInaccessibleNotificationBar';
