import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { IPreviewConfiguration } from '../../../previewConfiguration/models/PreviewConfiguration.type.ts';
import { WebSpotlight_LoadWebSpotlightTree_Started } from '../../../webSpotlight/constants/webSpotlightActionTypes.ts';
import { ContentItemEditing_LoadPreviewConfiguration_Finished } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: IPreviewConfiguration | null = null;

export function previewConfiguration(
  state: IPreviewConfiguration | null = initialState,
  action: Action,
): IPreviewConfiguration | null {
  switch (action.type) {
    case ContentItemEditing_LoadPreviewConfiguration_Finished: {
      return action.payload.previewConfiguration;
    }

    case WebSpotlight_LoadWebSpotlightTree_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
