import { Collection } from '@kontent-ai/utils';
import { IContentComponent } from '../../models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IRichTextItemElement } from '../../models/contentItemElements/RichTextItemElement.ts';
import { getElementById } from '../../stores/utils/contentItemElementsUtils.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';
import {
  GetItemEqualityCheckerType,
  IEqualityCheckerDependencies,
} from './types/IEqualityCheckerDependencies.type.ts';
import { IItemElementsEqualityChecker } from './types/IItemElementsEqualityChecker.type.ts';

const areContentComponentInstancesTheSame = (
  contentComponent1: IContentComponent,
  contentComponent2: IContentComponent,
  getItemElementEqualityChecker: GetItemEqualityCheckerType,
): boolean => {
  if (contentComponent1 === contentComponent2) {
    return true;
  }

  if (contentComponent1.id !== contentComponent2.id) {
    return false;
  }

  if (contentComponent1.contentTypeId !== contentComponent2.contentTypeId) {
    return false;
  }

  const elements1 = contentComponent1.elements;
  const elements2 = contentComponent2.elements;

  if (elements1.length !== elements2.length) {
    return false;
  }

  const elementsAreTheSame = elements1.every((element1: ICompiledContentItemElementData) => {
    const element2 = getElementById(element1.elementId, elements2);
    if (!element2) {
      return false;
    }
    const isElementValueTheSame = getItemElementEqualityChecker(element1.type);
    return isElementValueTheSame(element1, element2);
  });
  return elementsAreTheSame;
};

const areContentComponentsTheSame = (
  contentComponents1: ReadonlyMap<Uuid, IContentComponent>,
  contentComponents2: ReadonlyMap<Uuid, IContentComponent>,
  getItemElementEqualityChecker: GetItemEqualityCheckerType,
): boolean => {
  if (contentComponents1 === contentComponents2) {
    return true;
  }

  if (contentComponents1.size !== contentComponents2.size) {
    return false;
  }

  return Collection.getValues(contentComponents1).every((contentComponent1) => {
    const contentComponent2 = contentComponents2.get(contentComponent1.id);
    return (
      !!contentComponent2 &&
      areContentComponentInstancesTheSame(
        contentComponent1,
        contentComponent2,
        getItemElementEqualityChecker,
      )
    );
  });
};

export const createRichTextItemElementsEqualityChecker =
  ({
    areTextEditorStatesTheSame,
    getItemElementEqualityChecker,
  }: IEqualityCheckerDependencies): IItemElementsEqualityChecker =>
  (itemElement1: IRichTextItemElement, itemElement2: IRichTextItemElement): boolean => {
    if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
      return false;
    }

    return (
      areTextEditorStatesTheSame(
        itemElement1._editorState,
        itemElement2._editorState,
        getItemElementEqualityChecker,
      ) &&
      areContentComponentsTheSame(
        itemElement1.contentComponents,
        itemElement2.contentComponents,
        getItemElementEqualityChecker,
      )
    );
  };
