import { memoize } from '@kontent-ai/memoization';
import { notNullNorUndefined } from '@kontent-ai/utils';
import { ContentBlock, ContentState, EntityInstance, Modifier, SelectionState } from 'draft-js';
import Immutable from 'immutable';
import {
  createSelection,
  getAllEntitiesAtSelection,
  getBlocksAtSelection,
  getMetadataAtSelection,
  isSelectionWithinOneBlock,
  moveCaretToSelectionEnd,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { EntityMap, getBlocks, getEntityMap } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  IGetReferencesFromContentState,
} from '../../../utils/general/editorContentUtils.ts';
import { Entity, EntityMutability, EntityType } from '../../entityApi/api/Entity.ts';
import {
  convertToBoolean,
  findEntities,
  removeEntities,
} from '../../entityApi/api/editorEntityUtils.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';
import { AssetLinkData, ContentLinkData, LinkData } from './LinkData.type.ts';
import {
  getLinkTypeFromData,
  isAssetLink,
  isContentLink,
  isLink,
  isLinkPlaceholder,
  isNewLink,
} from './LinkEntity.ts';
import { LinkType } from './LinkType.ts';
import { NewLinkType } from './NewLinkType.ts';

export function isSingleLinkPlaceholderAtSelection(
  content: ContentState,
  selection: SelectionState,
): boolean {
  const entities = getAllEntitiesAtSelection(content, selection);
  return entities.length === 1 && entities.every(isLinkPlaceholder);
}

export function isLinkAtSelection(content: ContentState, selection: SelectionState): boolean {
  return getAllEntitiesAtSelection(content, selection).some(isLink);
}

export function isNewLinkAllowedAtSelection(
  content: ContentState,
  selection: SelectionState,
): boolean {
  return (
    isSelectionWithinOneBlock(selection) && !getAllEntitiesAtSelection(content, selection).length
  );
}

export function unlink(
  input: IContentChangeInput,
  predicate: (entityKey: string) => boolean,
): IContentChangeResult {
  const { content, selection } = input;

  // Remove any link that is even slightly contained in the selection
  const metadata = getMetadataAtSelection(content, selection);
  if (!metadata) {
    return input;
  }

  const topLevelEntities = metadata.entityKeyAtAnyTopLevelChars ?? Immutable.Set<string>();
  const tableEntities = metadata.entityKeyAtAnyTableChars ?? Immutable.Set<string>();
  const entityKeys = topLevelEntities.union(tableEntities);

  const linkEntityKeys = new Set<string>(
    entityKeys
      .filter(
        (entityKey) => !!entityKey && isLink(content.getEntity(entityKey)) && predicate(entityKey),
      )
      .filter(notNullNorUndefined)
      .toArray(),
  );

  const blocks = getBlocksAtSelection(content, selection);
  const unlinked = blocks.reduce((result: IContentChangeInput, block: ContentBlock) => {
    let newResult = result;
    block.findEntityRanges(
      (charMetadata) => linkEntityKeys.has(charMetadata.getEntity() ?? ''),
      (start, end) => {
        const blockKey = block.getKey();
        const linkSelection = createSelection(blockKey, start, blockKey, end);
        const removeInput = {
          content: newResult.content,
          selection: linkSelection,
        };

        newResult = removeEntities(removeInput);
      },
    );
    return newResult;
  }, input);

  if (unlinked === input) {
    return input;
  }

  const newContentWithSelection = setContentSelection(unlinked.content, selection, selection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection,
  };
}

const getIdsFromIdentifiedEntitiesInBlock = memoize.weak(
  <TData extends AnyObject, TValue extends TData[keyof TData]>(
    block: ContentBlock,
    entityProcessor: IEntityProcessor<TData, TValue>,
    entityMap: EntityMap,
  ): UuidArray => {
    const ids = Array.of<Uuid>();

    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (!entityKey) {
          return false;
        }
        const entity = entityMap.__get(entityKey);
        return entityProcessor.identifier(entity);
      },
      (start) => {
        const entityKey = block.getEntityAt(start);
        if (entityKey) {
          const entity = entityMap.__get(entityKey);
          const id = entityProcessor.retriever(entity);
          if (id) {
            ids.push(id);
          }
        }
      },
    );

    return ids;
  },
);

const getInfoFromIdentifiedEntitiesInContentOutput = memoize.allForever(
  (...idsInBlocks: ReadonlyArray<ReadonlyArray<Uuid>>): ReadonlyArray<Uuid> => {
    const allIds: Array<Uuid> = [];
    idsInBlocks.forEach((ids) => allIds.push(...ids));

    return allIds;
  },
);

// We memoize this method, because it is used from multiple places within the same React life cycle, it helps speed things up
const getInfoFromIdentifiedEntitiesInContent = memoize.weak(
  <TData extends AnyObject, TValue extends TData[keyof TData]>(
    content: ContentState,
    entityProcessor: IEntityProcessor<TData, TValue>,
  ): ReadonlyArray<Uuid> => {
    const entityMap = getEntityMap(content);
    const idsInBlocks = getBlocks(content)
      .map((block: ContentBlock) =>
        getIdsFromIdentifiedEntitiesInBlock(block, entityProcessor, entityMap),
      )
      .filter((ids) => !!ids.length);

    const result = getInfoFromIdentifiedEntitiesInContentOutput(...idsInBlocks);
    return result;
  },
);

const getInfoFromIdentifiedEntitiesCreator = <
  TData extends AnyObject,
  TValue extends TData[keyof TData],
>(
  entityProcessor: IEntityProcessor<TData, TValue>,
): IGetReferencesFromContentState => {
  return (content: ContentState) =>
    getInfoFromIdentifiedEntitiesInContent(content, entityProcessor);
};

export function isUploadingLink(entity: EntityInstance): boolean {
  return isLink(entity) && entity.getData()?.uploading;
}

const blockContainsNewLinks = memoize.weak((block: ContentBlock, entityMap: EntityMap): boolean => {
  let found = false;
  findEntities(block, entityMap, isNewLink, () => {
    found = true;
  });
  return found;
});

export const containsNewLinks = (content: ContentState): boolean => {
  const entityMap = getEntityMap(content);
  return getBlocks(content).some((block: ContentBlock) => blockContainsNewLinks(block, entityMap));
};

export interface IEntityProcessor<TData extends AnyObject, TValue extends TData[keyof TData]> {
  readonly identifier: (entity: EntityInstance) => boolean;
  readonly retriever: (entity: Entity<TData>) => TValue;
}

const contentLinkProcessor: IEntityProcessor<ContentLinkData, Uuid> = {
  identifier: isContentLink,
  retriever: (entity: EntityInstance) => entity.getData().itemId,
};

const assetLinkProcessor: IEntityProcessor<AssetLinkData, Uuid> = {
  identifier: isAssetLink,
  retriever: (entity: EntityInstance) => entity.getData().assetId,
};

export const getAllContentLinkIds = getInfoFromIdentifiedEntitiesCreator(contentLinkProcessor);

export const getLinkedAssetIds = getInfoFromIdentifiedEntitiesCreator(assetLinkProcessor);

export function createLink(input: IContentChangeInput, linkData: LinkData): IContentChangeResult {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    const contentStateWithLink = content.createEntity(
      EntityType.Link,
      EntityMutability.Mutable,
      linkData,
    );
    const entityKey = contentStateWithLink.getLastCreatedEntityKey();
    const newContent = Modifier.applyEntity(contentStateWithLink, selection, entityKey);
    const newContentWithSelection = setContentSelection(newContent, selection, selection);

    return {
      wasModified: true,
      content: newContentWithSelection,
      selection,
    };
  }

  return input;
}

export function createLinkWithText(
  input: IContentChangeInput,
  text: string,
  linkData: LinkData,
  keepSelected: boolean,
): IContentChangeResult {
  const withUpdatedText = text !== '' ? updateText(input, text) : input;
  const withLinkCreated = createLink(withUpdatedText, linkData);
  const withUpdatedSelection = keepSelected
    ? withLinkCreated
    : moveCaretToSelectionEnd(withLinkCreated);
  const newContent = setContentSelection(
    withUpdatedSelection.content,
    input.selection,
    withUpdatedSelection.selection,
  );

  return {
    wasModified: withUpdatedSelection.content !== input.content,
    content: newContent,
    selection: withUpdatedSelection.selection,
  };
}

export function createLinkPlaceholder(
  input: IContentChangeInput,
  linkType: NewLinkType,
): IContentChangeResult {
  const updated = createLinkWithText(
    input,
    ' ',
    {
      isPlaceholder: true,
      type: linkType,
    },
    false,
  );

  return updated;
}

export function findLinks(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (!entityKey) {
      return false;
    }
    const entity = contentState.getEntity(entityKey);
    return isLink(entity);
  }, callback);
}

// This is here to ensure backwards compatibility with string openInNewWindow
export function convertOpenInNewWindow(data: boolean | string | undefined): boolean | undefined {
  return data !== undefined ? convertToBoolean(data.toString()) : undefined;
}

export function getLinkType(link: EntityInstance): LinkType | NewLinkType | null {
  if (!isLink(link)) {
    return null;
  }

  const data = link.getData();

  return data ? getLinkTypeFromData(data) : null;
}

export const createValueLinkWithText = (
  input: IContentChangeInput,
  text: string,
  linkData: LinkData,
  keepSelected: boolean,
  isAtPlaceholder: boolean,
): IContentChangeResult => {
  const withLink = createLinkWithText(input, text, linkData, keepSelected);
  if (!withLink.wasModified) {
    return input;
  }
  const newSelection = withLink.selection;

  // If link is created at placeholder, update selection before in order to revert selection properly to collapsed after undo
  const newContent = isAtPlaceholder
    ? setContentSelection(
        withLink.content,
        createSelection(input.selection.getStartKey(), input.selection.getStartOffset()),
        newSelection,
      )
    : withLink.content;

  return {
    wasModified: true,
    content: newContent,
    selection: newSelection,
  };
};
