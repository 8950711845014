import { Button } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithoutCount } from '../../../../../../_shared/utils/stringUtils.ts';

type Props = {
  readonly areAllButtonsDisabled: boolean;
  readonly deleteActionTooltip: string | undefined;
  readonly editActionTooltip: string | undefined;
  readonly folderActionTooltip: string | undefined;
  readonly onAssignTerms: (() => void) | null;
  readonly onDeleteAssetFolder: (() => void) | null;
  readonly onDeleteAssets: (() => void) | null;
  readonly onMoveAssetsToCollection: (() => void) | null;
  readonly onMoveAssetsToFolder: (() => void) | null;
  readonly onMoveFolder: (() => void) | null;
  readonly selectedAssetsCount: number;
};

export const AssetListingActionPaneButtons = ({
  areAllButtonsDisabled,
  deleteActionTooltip,
  editActionTooltip,
  folderActionTooltip,
  onAssignTerms,
  onDeleteAssetFolder,
  onDeleteAssets,
  onMoveAssetsToCollection,
  onMoveAssetsToFolder,
  onMoveFolder,
  selectedAssetsCount,
}: Props) => (
  <Column>
    <Row alignX="end" alignY="center" spacing={Spacing.S}>
      {onAssignTerms && (
        <Column width="fit-content">
          <Button
            buttonStyle="tertiary"
            disabled={areAllButtonsDisabled || !!editActionTooltip}
            onClick={onAssignTerms}
            size="small"
            tooltipPlacement="left"
            tooltipText={editActionTooltip}
            {...getDataUiActionAttribute(DataUiAction.AssignTerms)}
          >
            Assign taxonomy terms
          </Button>
        </Column>
      )}

      {onMoveAssetsToFolder && (
        <Column width="fit-content">
          <Button
            buttonStyle="tertiary"
            disabled={areAllButtonsDisabled || !!editActionTooltip}
            onClick={onMoveAssetsToFolder}
            size="small"
            tooltipPlacement="left"
            tooltipText={editActionTooltip}
            {...getDataUiActionAttribute(DataUiAction.MoveAssetsToFolder)}
          >
            Move {pluralizeWithoutCount('asset', selectedAssetsCount)} to folder
          </Button>
        </Column>
      )}

      {onMoveAssetsToCollection && (
        <Column width="fit-content">
          <Button
            buttonStyle="tertiary"
            disabled={areAllButtonsDisabled || !!editActionTooltip}
            onClick={onMoveAssetsToCollection}
            size="small"
            tooltipPlacement="left"
            tooltipText={editActionTooltip}
            {...getDataUiActionAttribute(DataUiAction.MoveAssetsToCollection)}
          >
            Move {pluralizeWithoutCount('asset', selectedAssetsCount)} to collection
          </Button>
        </Column>
      )}

      {onDeleteAssets && (
        <Column width="fit-content">
          <Button
            buttonStyle="secondary"
            destructive
            disabled={areAllButtonsDisabled || !!deleteActionTooltip}
            onClick={onDeleteAssets}
            size="small"
            tooltipPlacement="left"
            tooltipText={deleteActionTooltip}
            {...getDataUiActionAttribute(DataUiAction.Delete)}
          >
            Delete {pluralizeWithoutCount('asset', selectedAssetsCount)}
          </Button>
        </Column>
      )}

      {onMoveFolder && (
        <Column width="fit-content">
          <Button
            buttonStyle="tertiary"
            disabled={areAllButtonsDisabled || !!folderActionTooltip}
            onClick={onMoveFolder}
            size="small"
            tooltipPlacement="left"
            tooltipText={folderActionTooltip}
            {...getDataUiActionAttribute(DataUiAction.MoveAssetFolder)}
          >
            Move folder
          </Button>
        </Column>
      )}

      {onDeleteAssetFolder && (
        <Column width="fit-content">
          <Button
            buttonStyle="secondary"
            destructive
            disabled={areAllButtonsDisabled || !!folderActionTooltip}
            onClick={onDeleteAssetFolder}
            size="small"
            tooltipPlacement="left"
            tooltipText={folderActionTooltip}
            {...getDataUiActionAttribute(DataUiAction.DeleteAssetFolder)}
          >
            Delete folder
          </Button>
        </Column>
      )}
    </Row>
  </Column>
);
