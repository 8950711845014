import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import { useForm } from 'react-hook-form';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { MissionControlUserFeedbackDialogData } from '../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  FormValidatorConfig,
  createFormValidationResolver,
} from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IntercomMessageServerModel } from '../../../../repositories/serverModels/IntercomMessageServerModel.type.ts';
import { FeedbackForm, MissionControlFeedbackFormShape } from '../components/FeedbackForm.tsx';

const { userRepository } = repositoryCollection;

type TrackUserActions = MissionControlUserFeedbackDialogData['action'];

export const useFeedbackDialog = () => {
  const dispatch = useDispatch();

  const formProps = useForm<MissionControlFeedbackFormShape>({
    defaultValues: {
      rating: '',
      comment: '',
    },
    resolver: createFormValidationResolver(missionControlFeedbackValidationConfig, {}),
    mode: 'onChange',
  });

  const trackFeedbackDialog = (action: TrackUserActions) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.MissionControlUserFeedbackDialog, {
        action,
      }),
    );

  const sendFeedback = (feedback: MissionControlFeedbackFormShape) => {
    userRepository.sendIntercomMessage(createFeedbackMessage(feedback));
    dispatch(
      trackUserEventWithData(TrackedEvent.MissionControlUserFeedbackDialogSent, {
        rating: feedback.rating,
        comment: feedback.comment,
      }),
    );
  };

  const onSubmitDialog = async () => {
    const isValid = await formProps.trigger();
    if (!isValid) {
      return;
    }

    sendFeedback(formProps.getValues());
    formProps.reset();
    closeDialog();
  };

  const onCloseDialog = () => {
    formProps.reset();
    trackFeedbackDialog('closed');
  };

  const {
    PopoverDialog,
    openDialog: originalOpenDialog,
    closeDialog,
    isOpen,
    popoverDialogProps,
    targetProps,
  } = usePopoverDialog({
    headline: 'Help make us better!',
    dialogState: DialogState.Default,
    shouldCloseOnBlur: false,
    primaryAction: { text: 'Send', onClick: onSubmitDialog },
    onClose: onCloseDialog,
    placement: 'bottom-end',
  });

  const openDialog = () => {
    originalOpenDialog();
    trackFeedbackDialog('opened');
  };

  const FeedbackDialog = () => (
    <PopoverDialog {...popoverDialogProps} minWidth={px(90 * gridUnit)}>
      <FeedbackForm formProps={formProps} />
    </PopoverDialog>
  );

  return {
    FeedbackDialog,
    targetProps,
    isOpen,
    openDialog,
    closeDialog,
  };
};

const createFeedbackMessage = (
  feedback: MissionControlFeedbackFormShape,
): IntercomMessageServerModel => {
  return {
    text: `This message was automatically generated by Kontent.ai.
    \nFeedback on: Mission Control 
    \nRating: ${feedback.rating}
    \nComment: ${feedback.comment}`,
  };
};

const ratingIsRequired = (value: string) =>
  Number.isNaN(Number.parseInt(value, 10)) ? 'Your rating is required.' : undefined;

const missionControlFeedbackValidationConfig: FormValidatorConfig<MissionControlFeedbackFormShape> =
  {
    rating: [ratingIsRequired],
  };
