import { Action } from '../../../../@types/Action.type.ts';
import {
  LocalizationEditor_Init_Finished,
  LocalizationEditor_Language_DraggingStarted,
  LocalizationEditor_Saving_Started,
} from '../constants/localizationActionTypes.ts';

const initialState = null;

export const draggedLanguageId = (state = initialState, action: Action): Uuid | null => {
  switch (action.type) {
    case LocalizationEditor_Language_DraggingStarted:
      return action.payload.languageId;

    case LocalizationEditor_Saving_Started:
    case LocalizationEditor_Init_Finished:
      return initialState;

    default:
      return state;
  }
};
