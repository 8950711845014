export const NewContentItem_ContentItemCreatedInLinkedItems =
  'NewContentItem_ContentItemCreatedInLinkedItems';
export const NewContentItem_ContentItemCreatedInListing =
  'NewContentItem_ContentItemCreatedInListing';
export const NewContentItem_ContentItemCreatedInRichText =
  'NewContentItem_ContentItemCreatedInRichText';
export const NewContentItem_CreatingContentItemInListing =
  'NewContentItem_CreatingContentItemInListing';
export const NewContentItem_DialogPropertiesSet = 'NewContentItem_DialogPropertiesSet';
export const NewContentItem_Initialized = 'NewContentItem_Initialized';
export const NewContentItem_NameChanged = 'NewContentItem_NameChanged';
export const NewContentItem_SelectedCollectionChanged = 'NewContentItem_SelectedCollectionChanged';
export const NewContentItem_SelectedContentTypeChanged =
  'NewContentItem_SelectedContentTypeChanged';
export const NewContentItem_SelectedLanguageChanged = 'NewContentItem_SelectedLanguageChanged';
export const NewContentItem_SelectedWorkflowChanged = 'NewContentItem_SelectedWorkflowChanged';
export const RecentlyUsedCollectionsForEditing_Loaded = 'RecentlyUsedCollectionsForEditing_Loaded';
export const RecentlyUsedCollectionsForListing_Loaded = 'RecentlyUsedCollectionsForListing_Loaded';
export const RecentlyUsedContentTypesForEditing_Loaded = 'RecentlyUsedContentTypes_Loaded';
export const RecentlyUsedContentTypesForListing_Loaded =
  'RecentlyUsedContentTypesForListing_Loaded';
