import { History } from 'history';
import { ContentItemsAppRouteSegment } from '../../../../_shared/constants/routePaths.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { redirectToLatestPublishedRevision } from '../actions/thunks/redirectToLatestPublishedRevision.ts';

type Props = {
  readonly projectId: Uuid;
  readonly app: ContentItemsAppRouteSegment;
  readonly history: History;
  readonly variantId: Uuid;
  readonly spaceId: Uuid | undefined;
  readonly contentItemId: Uuid;
};

export const RedirectToLatestPublishedRevision = (props: Props) => {
  useThunkPromise(
    redirectToLatestPublishedRevision,
    props.history,
    props.projectId,
    props.app,
    props.variantId,
    props.spaceId,
    props.contentItemId,
  );

  return null;
};
