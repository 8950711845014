import { RefObject, forwardRef, useRef } from 'react';
import { DialogState } from '../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { canUserCreateContentTypes } from '../../../../contentInventory/content/features/ContentItemInventory/utils/canUserCreateContentTypes.ts';
import { ContentTypesLoadingIndicator } from '../components/ContentTypesLoadingIndicator.tsx';
import { EmptyContentTypePlaceholder } from '../components/EmptyContentTypePlaceholder.tsx';
import { NewContentItemForm } from '../components/NewContentItemForm.tsx';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { disabledCreateButtonTooltip } from '../constants/uiConstants.ts';
import { getNewContentItemFormSelectedValues } from '../selectors/getNewContentItemFormSelectedValues.ts';
import { hasNewContentItemFormAvailableContentTypes } from '../selectors/hasNewContentItemFormAvailableContentTypes.ts';
import { getNewContentItemDialogProperties } from '../selectors/newContentItemDialog.ts';
import { getNewContentItemVisibleSelectors } from '../utils/getNewContentItemVisibleSelectors.ts';

interface IProps {
  readonly isOpen: boolean;
  readonly onSubmit: () => void;
  readonly onClose: () => void;
}

const NewContentItemDialogContainer = forwardRef<HTMLDivElement, IProps>(
  ({ onClose, isOpen, onSubmit }, ref) => {
    const selectedValues = useSelector(getNewContentItemFormSelectedValues);
    const visibleSelectors = useSelector(getNewContentItemVisibleSelectors);
    const projectId = useSelector((s) => s.sharedApp.currentProjectId);
    const canCreateContentTypes = useSelector(canUserCreateContentTypes);
    const hasAvailableContentTypes = useSelector(hasNewContentItemFormAvailableContentTypes);
    const isSubmitButtonShown = useSelector(hasNewContentItemFormAvailableContentTypes);
    const disabledButtonTooltip = useSelector(getDisabledButtonTooltip);
    const headline = useSelector(getHeadline);

    const isFormReady = useSelector((s) => {
      const areTypesLoaded = s.data.contentTypes.loadingStatus === LoadingStatus.Loaded;
      const areCollectionsLoaded = s.data.collections.loadingStatus === LoadingStatus.Loaded;
      const isConfigLoaded = s.contentApp.newContentItem.formConfig.isLoaded;

      return areTypesLoaded && areCollectionsLoaded && isConfigLoaded;
    });

    const inputRef = useRef<HTMLInputElement | HTMLAnchorElement>(null);
    const loaderRef = useRef<HTMLDivElement>(null);

    return (
      <ModalDialog
        headline={headline}
        isDismissable
        isOpen={isOpen}
        primaryAction={
          isSubmitButtonShown
            ? {
                disabled: !!disabledButtonTooltip,
                onClick: onSubmit,
                text: 'Create',
                tooltipText: disabledButtonTooltip,
                ...getDataUiActionAttribute(DataUiAction.Confirm),
              }
            : undefined
        }
        onClose={onClose}
        ref={ref}
        state={isFormReady ? DialogState.Default : DialogState.InProgress}
        autoFocusRef={isFormReady ? inputRef : loaderRef}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
      >
        {!isFormReady && <ContentTypesLoadingIndicator ref={loaderRef} />}
        {isFormReady && hasAvailableContentTypes && (
          <NewContentItemForm
            selectedValues={selectedValues}
            visibleSelectors={visibleSelectors}
            firstInputRef={inputRef as RefObject<HTMLInputElement>}
          />
        )}
        {isFormReady && !hasAvailableContentTypes && (
          <EmptyContentTypePlaceholder
            canCreateContentTypes={canCreateContentTypes}
            onCreateNewTypeClick={onClose}
            projectId={projectId}
            actionRef={inputRef as RefObject<HTMLAnchorElement>}
          />
        )}
      </ModalDialog>
    );
  },
);

NewContentItemDialogContainer.displayName = 'NewContentItemDialogContainer';

export { NewContentItemDialogContainer as NewContentItemDialog };

const getHeadline = (state: IStore): string =>
  getNewContentItemDialogProperties(state).origin === NewContentItemOrigin.ContentComponent
    ? 'Create a component'
    : 'Create a content item';

const getDisabledButtonTooltip = (state: IStore): string | undefined => {
  const selectedValues = getNewContentItemFormSelectedValues(state);
  const visibleSelectors = getNewContentItemVisibleSelectors(state);
  const doAllVisibleSelectorsHaveSelectedValue = Array.from(visibleSelectors.values()).every(
    (selector) => selectedValues[selector],
  );

  return doAllVisibleSelectorsHaveSelectedValue ? undefined : disabledCreateButtonTooltip;
};
