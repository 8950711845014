import { assert } from '@kontent-ai/utils';
import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { ContentItemEditingEventOrigins } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { newContentItemVariantInitPrepared } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { CreateNewItemFromType } from '../../ContentItemEditing/actions/thunks/createNewItemFromType.ts';

interface IDeps {
  readonly createNewItemFromType: CreateNewItemFromType;
  readonly trackContentItemEditingCreateNewAction: (
    actionOrigin: ContentItemEditingEventOrigins,
  ) => ThunkFunction;
}

export const createDuplicateItemWithoutContentAction =
  (deps: IDeps) =>
  (
    actionOrigin: ContentItemEditingEventOrigins,
    destinationCollectionId?: Uuid,
  ): ThunkPromise<{ readonly duplicateItemId: Uuid }> =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();

    assert(
      editedContentItemVariant,
      () => `${__filename}: Edited content item variant was not found.`,
    );

    dispatch(
      newContentItemVariantInitPrepared({
        workflowId: editedContentItemVariant.assignment.workflowStatus.workflowId,
      }),
    );

    const duplicateItem = await dispatch(deps.createNewItemFromType(destinationCollectionId));

    dispatch(modalDismissed());
    dispatch(deps.trackContentItemEditingCreateNewAction(actionOrigin));

    return { duplicateItemId: duplicateItem.createdItemId };
  };
