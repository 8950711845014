import { Action } from '../../../../../@types/Action.type.ts';
import { NewContentItem_DialogPropertiesSet } from '../constants/newContentItemActionTypes.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { INewContentItemDialogProperties } from './INewContentItemStoreState.type.ts';

const initialState: INewContentItemDialogProperties = {
  origin: NewContentItemOrigin.None,
  selectors: new Set(),
};

export const dialogProperties = (
  state = initialState,
  action: Action,
): INewContentItemDialogProperties => {
  switch (action.type) {
    case NewContentItem_DialogPropertiesSet:
      return action.payload;

    default:
      return state;
  }
};
