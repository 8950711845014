import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  AssetLibrary_Asset_RenditionCreated,
  AssetLibrary_Asset_RenditionUpdated,
} from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { Data_Assets_Loaded } from '../../../constants/dataActionTypes.ts';
import { IAssetRendition } from '../../../models/assetRenditions/AssetRendition.ts';

const initialState: ReadonlyMap<Uuid, IAssetRendition> = new Map<Uuid, IAssetRendition>();

export function byId(state = initialState, action: Action): ReadonlyMap<Uuid, IAssetRendition> {
  switch (action.type) {
    case Data_Assets_Loaded: {
      const renditions = action.payload.renditions;
      return Collection.addMany(
        state,
        renditions.map((rendition) => [rendition.renditionId, rendition]),
      );
    }

    case AssetLibrary_Asset_RenditionCreated:
    case AssetLibrary_Asset_RenditionUpdated: {
      const rendition = action.payload.rendition;
      return Collection.add(state, [rendition.renditionId, rendition]);
    }

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
