import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { loadContentTypes } from '../../../../../data/actions/thunkDataActions.ts';
import { loadListingItems } from '../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';

export const loadWidgetListing =
  (contentItemIds: ReadonlyArray<Uuid>, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(loadListingItems(contentItemIds, abortSignal)),
      dispatch(loadContentTypes(abortSignal)),
    ]);
  };
