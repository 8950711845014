import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import {
  IFeatures,
  createFeatures,
  createFeaturesFromServerModel,
} from '../../../_shared/models/Features.ts';
import { IPlanServerModel } from '../../../repositories/serverModels/PlanServerModel.type.ts';
import { IFupMetric, createFupMetricFromServerModel } from './FupMetric.ts';
import { IPaidMetric, createPaidMetricFromServerModel } from './PaidMetric.ts';

export interface IPlan {
  readonly displayName: string;
  readonly features: IFeatures;
  readonly fupMetrics: Immutable.List<IFupMetric>;
  readonly isCustom: boolean;
  readonly isPrepaid: boolean;
  readonly isTrial: boolean;
  readonly monthlyFee: number;
  readonly name: string;
  readonly paidMetrics: Immutable.List<IPaidMetric>;
  readonly planId: Uuid;
}

export const emptyPlan: IPlan = {
  displayName: '',
  features: createFeatures(),
  fupMetrics: Immutable.List(),
  isCustom: false,
  isPrepaid: false,
  isTrial: false,
  monthlyFee: 0,
  name: '',
  paidMetrics: Immutable.List(),
  planId: '',
};

export interface IPlanWithDetails {
  readonly plan: IPlan | null;
  readonly isPlanVersionObsolete: boolean;
}

export function createPlanFromServerModel(serverModel: IPlanServerModel): IPlan {
  if (typeof serverModel !== 'object') {
    throw InvariantException('Subscription plan is not an object');
  }

  return {
    displayName: serverModel.displayName ?? emptyPlan.displayName,
    features: serverModel.features
      ? createFeaturesFromServerModel(serverModel.features)
      : emptyPlan.features,
    fupMetrics: serverModel.fupMetrics
      ? Immutable.List(serverModel.fupMetrics.map(createFupMetricFromServerModel))
      : emptyPlan.fupMetrics,
    isCustom: serverModel.isCustom ?? emptyPlan.isCustom,
    isPrepaid: serverModel.isPrepaid ?? emptyPlan.isPrepaid,
    isTrial: serverModel.isTrial ?? emptyPlan.isTrial,
    monthlyFee: Number.isInteger(serverModel.monthlyFee)
      ? serverModel.monthlyFee
      : emptyPlan.monthlyFee,
    name: serverModel.name ?? emptyPlan.name,
    paidMetrics: serverModel.paidMetrics
      ? Immutable.List(serverModel.paidMetrics.map(createPaidMetricFromServerModel))
      : emptyPlan.paidMetrics,
    planId: serverModel.planId ?? emptyPlan.planId,
  };
}
