import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Warning } from '../../../_shared/components/infos/Warning.tsx';
import {
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
} from '../../../_shared/constants/routePaths.ts';
import { AvailableSubscriptionAdminsWithoutCurrentProject } from '../../../_shared/containers/infos/AvailableSubscriptionAdminsWithoutCurrentProject.tsx';
import { LinkLike } from '../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { TrialActivation } from '../containers/TrialActivation.tsx';

interface IExpiredSubscriptionsWarningProps {
  readonly administratedSubscriptionLinks: ReadonlyArray<ISubscriptionWarningLinkData>;
  readonly notAdministratedSubscriptionLinks: ReadonlyArray<ISubscriptionWarningLinkData>;
}

export interface ISubscriptionWarningLinkData {
  readonly subscriptionId: Uuid;
  readonly subscriptionName: string;
}

export const ExpiredSubscriptionsWarning: React.FC<IExpiredSubscriptionsWarningProps> = ({
  administratedSubscriptionLinks,
  notAdministratedSubscriptionLinks,
}) => {
  const [showAdminsDialog, setShowAdminsDialog] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState<Uuid | null>(null);

  const closeSubscriptionAdminDialog = () => {
    setShowAdminsDialog(false);
    setSubscriptionId(null);
  };

  const openSubscriptionAdminDialog = (openedSubscriptionId: Uuid): void => {
    setShowAdminsDialog(true);
    setSubscriptionId(openedSubscriptionId);
  };

  const expiredSubscriptionCount =
    administratedSubscriptionLinks.length + notAdministratedSubscriptionLinks.length;
  const warningText = getWarningTexts(expiredSubscriptionCount);

  return (
    <div className="row">
      <div className="col-sm-12">
        <Warning subtitle={warningText.title}>
          <div className="callout__text-block">
            <p>
              {warningText.explanation} This means you <br />
              currently cannot make any changes to the affected projects.
            </p>
            <p>
              Now you can: <br />
            </p>
            <ul>
              {administratedSubscriptionLinks.map((s: ISubscriptionWarningLinkData, index) => (
                <li key={index}>
                  <Link
                    to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
                      subscriptionId: s.subscriptionId,
                    })}
                  >
                    Renew
                  </Link>{' '}
                  your “{s.subscriptionName}” subscription.
                </li>
              ))}
              {notAdministratedSubscriptionLinks.map((s: IProjectDetails, index) => (
                <li key={index}>
                  <p>
                    <LinkLike onClick={() => openSubscriptionAdminDialog(s.subscriptionId)}>
                      Contact an admin
                    </LinkLike>
                    {` of the “${s.subscriptionName}” subscription to renew it.`}
                  </p>
                  {showAdminsDialog && subscriptionId === s.subscriptionId && (
                    <AvailableSubscriptionAdminsWithoutCurrentProject
                      onClose={closeSubscriptionAdminDialog}
                      subscriptionId={s.subscriptionId}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="callout__text-block">
            <TrialActivation text="You can also start trial and create your own project. No credit card required." />
          </div>
        </Warning>
      </div>
    </div>
  );
};

ExpiredSubscriptionsWarning.displayName = 'ExpiredSubscriptionsWarning';

const getWarningTexts = (subscriptionCount: number) =>
  subscriptionCount > 1
    ? {
        title: 'Expired subscriptions',
        explanation: 'Several subscriptions of projects you worked on have expired.',
      }
    : {
        title: 'Expired subscription',
        explanation: 'A subscription of projects you worked on has expired.',
      };
