export type TypeElementConditionServerModel = {
  readonly id: Uuid;
  readonly action: ConditionActionServerModel;
  readonly isActive: boolean;
  readonly triggers: ReadonlyArray<TypeElementConditionTriggerServerModel>;
};

export type TypeElementConditionTriggerServerModel = {
  readonly elementId: Uuid | null;
  readonly triggerType: ConditionTriggerTypeServerModel.MultipleChoiceTypeElement;
  readonly operator: MultipleChoiceConditionOperatorServerModel;
  readonly values: UuidArray;
};

export enum ConditionActionServerModel {
  Hide = 'hide',
  Show = 'show',
}

export enum MultipleChoiceConditionOperatorServerModel {
  All = 'all',
  Any = 'any',
}

export enum ConditionTriggerTypeServerModel {
  MultipleChoiceTypeElement = 'multipleChoiceTypeElement',
}
