import { IListingContentItemServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  IActiveCapabilitiesForVariant,
  createActiveCapabilitiesForVariantFromServerModel,
} from '../activeCapabilities.ts';
import { IListingItemData, emptyItemData, getItemDataFromJS } from './IListingItemData.ts';
import { IListingVariantData, getListingVariantDataFromJS } from './IListingVariantData.ts';

export interface IListingContentItem {
  readonly activeCapabilities: IActiveCapabilitiesForVariant;
  readonly highlightedName: string | null;
  readonly isPublishedVersion: boolean;
  readonly item: IListingItemData;
  readonly variant: IListingVariantData | null;
  readonly translatedLanguageIds: UuidArray;
}

export const emptyListingContentItem: IListingContentItem = {
  activeCapabilities: {
    variantCapabilities: [],
  },
  highlightedName: null,
  isPublishedVersion: false,
  item: emptyItemData,
  variant: null,
  translatedLanguageIds: [],
};

export const getListingContentItemFromJS = (
  serverModel: IListingContentItemServerModel,
): IListingContentItem => ({
  activeCapabilities: createActiveCapabilitiesForVariantFromServerModel(
    serverModel.activeCapabilities,
  ),
  highlightedName: serverModel.highlightedName || null,
  isPublishedVersion: serverModel.isPublishedVersion || false,
  item: getItemDataFromJS(serverModel.item),
  variant: serverModel.variant ? getListingVariantDataFromJS(serverModel.variant) : null,
  translatedLanguageIds: serverModel.translatedLanguages || [],
});
