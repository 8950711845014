import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

interface WebSpotlightPreviewEmptyState {
  readonly hideDocumentationLink?: boolean;
}

export const WebSpotlightPreviewEmptyState: React.FC<
  React.PropsWithChildren<WebSpotlightPreviewEmptyState>
> = ({ children, hideDocumentationLink }) => {
  return (
    <EmptyState size="fullOffset">
      <EmptyState.Title>Preview is not available for this item</EmptyState.Title>
      <EmptyState.Content>
        <p>
          {children && (
            <>
              {children}
              <br />
            </>
          )}
          {!hideDocumentationLink && (
            <>
              Learn{' '}
              <OutwardLink href={documentationLinks.configuringPreview}>
                how to set up preview
              </OutwardLink>{' '}
              for your content items.
            </>
          )}
        </p>
      </EmptyState.Content>
    </EmptyState>
  );
};

WebSpotlightPreviewEmptyState.displayName = 'WebSpotlightPreviewEmptyState';
