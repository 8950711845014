import { Action } from '../../../../../@types/Action.type.ts';
import { Content_Editing_AssignmentSubmittingFinished } from '../../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_Init_Ready,
  ContentItemEditing_NotificationBar_Close,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentRevisions_RevisionRestoreFinished } from '../../../features/Revisions/constants/revisionActionTypes.ts';

export function restoredVersionId(state: Uuid | null = null, action: Action): Uuid | null {
  switch (action.type) {
    case ContentRevisions_RevisionRestoreFinished: {
      return action.payload.itemId;
    }

    case Content_Editing_AssignmentSubmittingFinished:
    case ContentItemEditing_NotificationBar_Close: {
      return null;
    }

    case ContentItemEditing_Init_Ready: {
      const itemId = action.payload.editedContentItem.id;
      if (state !== itemId) {
        return null;
      }
      return state;
    }

    default:
      return state;
  }
}
