import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { Collection, alphabetically, createCompare } from '@kontent-ai/utils';
import React, { useMemo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  onTaxonomySelectionChange,
  toSelectSection,
} from '../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { createTaxonomyFilterItemId } from '../../utils/taxonomyFilterItemIdUtils.ts';

interface IProps {
  readonly taxonomyGroups: ReadonlyArray<ITaxonomyGroup>;
  readonly selectedTaxonomies: ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
  readonly onFilterChanged: (selectedTermsByGroup: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void;
}

const getNonEmptyTaxonomyGroups = (
  taxonomyGroups: ReadonlyArray<ITaxonomyGroup>,
): ReadonlyArray<ITaxonomyGroup> =>
  taxonomyGroups.filter((taxonomyGroup) => !!taxonomyGroup.childIds.length);

export const ConfigurableTaxonomyMultipleOptionSelect: React.FC<IProps> = ({
  taxonomyGroups,
  selectedTaxonomies,
  onFilterChanged,
}) => {
  const items = useMemo(
    () =>
      getNonEmptyTaxonomyGroups(taxonomyGroups)
        .map(toSelectSection)
        .sort(
          createCompare({
            compare: alphabetically,
            select: (item) => item.label,
          }),
        ),
    [taxonomyGroups],
  );

  const selectedTaxonomyIds = useMemo(() => {
    return Collection.getEntries(selectedTaxonomies).flatMap(([groupId, termIds]) =>
      [...termIds].map((termId) => createTaxonomyFilterItemId(groupId, termId)),
    );
  }, [selectedTaxonomies]);

  return (
    <MultiSelect
      placeholder="Select a taxonomy term"
      items={items}
      selectedItemIds={selectedTaxonomyIds}
      onSelectionChange={(selectedIds) => onTaxonomySelectionChange(selectedIds, onFilterChanged)}
      {...getDataUiCollectionAttribute(DataUiCollection.Taxonomies)}
    />
  );
};

ConfigurableTaxonomyMultipleOptionSelect.displayName = 'ConfigurableTaxonomyMultipleOptionSelect';
