import { Action } from '../../../../../@types/Action.type.ts';
import {
  PublishedStep,
  getPublishedWorkflowStepFromServerModel,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { publishedStepConstantValues } from '../../constants/publishedStepValues.ts';
import {
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
  Workflow_InitializeEditing_Started,
  Workflow_PublishedStepEditor_Save,
} from '../../constants/workflowActionTypes.ts';

const publishedInitialState: PublishedStep = {
  ...publishedStepConstantValues,
  id: '',
  roleIdsForCreateNew: new Set(),
  roleIdsForUnpublish: new Set(),
};

export const publishedWorkflowStep = (
  state: PublishedStep = publishedInitialState,
  action: Action,
): PublishedStep => {
  switch (action.type) {
    case Workflow_InitializeEditing_Started: {
      return publishedInitialState;
    }

    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished: {
      return action.payload.workflow.publishedStep;
    }

    case Workflow_Editing_WorkflowSaveFinished: {
      return getPublishedWorkflowStepFromServerModel(action.payload.workflow.publishedStatus);
    }

    case Workflow_PublishedStepEditor_Save: {
      const { roleIdsForCreateNew, roleIdsForUnpublish } = action.payload;

      return {
        ...state,
        roleIdsForUnpublish,
        roleIdsForCreateNew,
      };
    }

    default: {
      return state;
    }
  }
};
