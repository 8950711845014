import { Action } from '../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../_shared/constants/sharedActionTypes.ts';
import { WebSpotlight_Space_Selected } from '../constants/webSpotlightActionTypes.ts';

export const rootItemId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case WebSpotlight_Space_Selected:
      return action.payload.rootItemId;

    case Shared_CurrentProjectId_Updated:
      return null;

    default:
      return state;
  }
};
