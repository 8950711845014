import {
  colorBackground,
  colorBorderDefault,
  colorPrimaryHoverInverse,
} from '@kontent-ai/component-library/tokens';
import { ComponentProps } from 'react';
import { Tag } from './Tag.tsx';
import { TagCountStatus } from './components/TagCountStatus.tsx';
import { TagIcon } from './components/TagIcon.tsx';
import { TagLabel } from './components/TagLabel.tsx';

const DefaultTagComponent = ({
  ...otherProps
}: Omit<ComponentProps<typeof Tag>, 'backgroundHover' | 'background' | 'borderColor'>) => {
  return (
    <Tag
      borderColor={colorBorderDefault}
      background={colorBackground}
      backgroundHover={colorPrimaryHoverInverse}
      {...otherProps}
    />
  );
};

DefaultTagComponent.displayName = 'DefaultTag';

const tagComposition = {
  CountStatus: TagCountStatus,
  Label: TagLabel,
  Icon: TagIcon,
} as const;

export const DefaultTag = Object.assign(DefaultTagComponent, tagComposition);
