import { History } from 'history';
import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import {
  EditItemVariantCodenameRoute,
  EditItemVariantCodenameRouteParams,
} from '../../../smartLink/constants/routePaths.ts';
import { getFloatingEditorSmartLinkTarget } from '../../../smartLink/utils/smartLinkUtils.ts';
import { IEditButtonContentItemClickedMessageData } from '../../types/SmartLinkSdkApi.ts';

export const navigateToClickedContentItem =
  (messageData: IEditButtonContentItemClickedMessageData, history: History): ThunkFunction =>
  (_, getState) => {
    const {
      data: { languages },
      sharedApp: { currentProjectId },
    } = getState();

    // Use smart link for redirection to different project.
    // It should properly handle the permissions and Web Spotlight when needed, and also includes navigation to a particular element
    if (messageData.projectId && messageData.projectId !== currentProjectId) {
      const smartLinkPath = buildPath<EditItemVariantCodenameRouteParams>(
        EditItemVariantCodenameRoute,
        {
          projectId: messageData.projectId,
          itemGuid: messageData.itemId,
          variantCodename: messageData.languageCodename,
        },
      );
      history.push(smartLinkPath);
    }

    // Otherwise translate parameters to IDs and redirect locally within Web Spotlight
    const variantId = messageData.languageCodename
      ? (languages.byId.find(
          (language: ILanguage) => language.codename === messageData.languageCodename,
        )?.id ?? DefaultVariantId)
      : undefined;

    const currentPath = history.location.pathname;

    const target = getFloatingEditorSmartLinkTarget(
      messageData.projectId,
      variantId,
      messageData.itemId,
      currentPath,
    );

    if (target.path !== currentPath) {
      // Make a physical redirect
      history.push(target.path);
    }
  };
