import React from 'react';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { ItemNameElement } from '../../../../itemEditor/features/ContentItemEditing/components/elements/itemNameElement/ItemNameElement.tsx';
import { ItemNameGuidelineStatus } from '../../../../itemEditor/features/ContentItemEditing/utils/itemEditingUtils.ts';
import { assetTitleChanged } from '../../actions/assetLibraryActions.ts';

type Props = {
  readonly editedAsset: IAsset;
  readonly readOnly: boolean;
};

export const AssetTitleEditorElement: React.FC<Props> = ({ editedAsset, readOnly }) => {
  const dispatch = useDispatch();

  const filename = useSelector((s) => s.data.assets.byId.get(editedAsset.id)?.filename ?? '');

  return (
    <ItemNameElement
      dataUiInputName={DataUiInput.EntityName}
      isLoading={false}
      maxLength={ValidationConstants.AssetTitleMaxLength}
      placeholder={filename}
      guidelinesStatus={ItemNameGuidelineStatus.Hidden}
      title="Asset title"
      value={editedAsset.title ?? ''}
      readOnly={readOnly}
      onChange={(name: string) => dispatch(assetTitleChanged(name, filename))}
    />
  );
};

AssetTitleEditorElement.displayName = 'AssetTitleEditorElement';
