import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { ApiStatisticsPlugin } from '../ApiStatisticsPlugin.tsx';
import { IEditorStatistics } from './editorStatistics.ts';

export const createEditorStatisticsApi = (
  statistics: IEditorStatistics | null,
): EditorApiImplementation<ApiStatisticsPlugin> => ({
  getStatistics() {
    return statistics;
  },
});
