import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { NotTranslatedBadge } from '../Workflow/NotTranslatedBadge.tsx';
import { FallbacksPopover } from './FallbacksPopover.tsx';

export const FallbacksStatusCompact: React.FC<{ readonly itemId: Uuid }> = ({ itemId }) => (
  <Row
    alignY="center"
    // We need to quickfix margin to match with other compact WF steps with the icon
    css={`margin-right: ${px(-Spacing.XS)}`}
  >
    <Column>
      <NotTranslatedBadge />
    </Column>
    <Column>
      <FallbacksPopover itemId={itemId} />
    </Column>
  </Row>
);

FallbacksStatusCompact.displayName = 'FallbacksStatusCompact';
