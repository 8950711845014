import React from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { colorTextHint } from '../../../tokens/decision/colors.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { getDataUiComponentAttribute } from '../../../utils/dataAttributes/DataUiAttributes.ts';

export const TabViewEmptyState: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Box
    color={colorTextHint}
    typography={Typography.SubheadlineLarge}
    {...getDataUiComponentAttribute(TabViewEmptyState)}
  >
    {children}
  </Box>
);

TabViewEmptyState.displayName = 'TabViewEmptyState';
