import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ILoadListingItemsAction } from './loadListingItems.ts';

export type IEnsureLoadedListingItemsAction = (
  contentItemIds: Immutable.List<Uuid>,
) => ThunkPromise;

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
}

export const createEnsureLoadedListingItemsAction =
  (dependencies: IDeps): IEnsureLoadedListingItemsAction =>
  (ids: Immutable.List<Uuid>): ThunkPromise =>
  async (dispatch, getState) => {
    const uniqueIds = ids.toSet();
    const alreadyLoaded = getState().data.listingContentItems.byId;
    const alreadyLoadedIds = alreadyLoaded
      .map((contentItem: IListingContentItem) => contentItem.item.id)
      .toList();
    const loadIds = uniqueIds.subtract(alreadyLoadedIds).toArray();

    await dispatch(dependencies.loadListingItems(loadIds));
  };
