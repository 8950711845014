import { Location } from 'history';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import {
  IBreadcrumbResolverDependencies,
  IBreadcrumbResolverInfo,
  createBreadcrumbResolvers,
} from '../../utils/breadcrumbs/breadcrumbResolvers.ts';
import { breadcrumbsChanged } from '../sharedActions.ts';

interface ILoadBreadcrumbsActionDeps extends IBreadcrumbResolverDependencies {
  readonly location: Location;
}

export const createLoadBreadcrumbsAction =
  () =>
  (deps: ILoadBreadcrumbsActionDeps, abortSignal: AbortSignal): ThunkPromise => {
    const { location, ...restOfDeps } = deps;

    return async (dispatch) => {
      let breadcrumbResolvers: ReadonlyArray<IBreadcrumbResolverInfo> | undefined =
        createBreadcrumbResolvers(restOfDeps);
      const promises = location.pathname
        ? location.pathname
            .split('/')
            .filter((x) => !!x)
            .map((segment) => {
              const foundResolverInfo = breadcrumbResolvers?.find((resolverInfo) =>
                resolverInfo.pattern.exec(segment),
              );
              breadcrumbResolvers = foundResolverInfo?.childResolvers;
              return foundResolverInfo;
            })
            .map((resolverInfo) => resolverInfo?.resolver?.(location, abortSignal))
            .filter((x) => !!x)
        : [];

      const segments = await Promise.all(promises);
      const breadcrumbs = segments.flatMap((segment) => segment.toArray());

      dispatch(breadcrumbsChanged(breadcrumbs));
    };
  };
