import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC, forwardRef } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { ScrollTableHeadColumn } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import { SimpleScrollTable } from '../../../../../_shared/uiComponents/ScrollTable/SimpleScrollTable.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FailedItemRow } from './FailedItemRow.tsx';

interface Props extends IAnimatedModalDialogProps {
  readonly modalTitle: string;
  readonly dataUiElement: DataUiElement;
  readonly failedItemIds: ReadonlyArray<ContentItemId>;
  readonly renderScrollTableTitle: () => JSX.Element;
  readonly renderWarning?: () => JSX.Element;
  readonly onClose: () => void;
  readonly showCollectionColumn: boolean;
}

const ModalBody: FC<Props> = (props) => (
  <Stack spacing={Spacing.L}>
    {props.renderWarning?.()}

    <SimpleScrollTable
      collectionName={DataUiCollection.ContentItems}
      fillAvailableSpace
      noShadow
      renderTitle={props.renderScrollTableTitle}
      renderHead={(showScrollBar: boolean) => (
        <ScrollTableHeadRow collectionName={DataUiCollection.ColumnHeaderCollection}>
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.Name)}
            size={10}
            isGrowing
          />
          <ScrollTableHeadColumn name="" size={1} />
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
            size={4}
            isGrowing
          />
          <ScrollTableHeadColumn
            name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
            size={4}
            isGrowing
          />
          {props.showCollectionColumn && (
            <ScrollTableHeadColumn
              name={translateColumnCodeToTitle(ItemColumnCode.Collection)}
              size={4}
              isGrowing
            />
          )}
          {showScrollBar && <div className="scroll-table__head-scroll" />}
        </ScrollTableHeadRow>
      )}
    >
      {props.failedItemIds.map((contentItemId) => (
        <FailedItemRow
          key={stringifyContentItemId(contentItemId)}
          contentItemId={contentItemId}
          showCollectionColumn={props.showCollectionColumn}
        />
      ))}
    </SimpleScrollTable>
  </Stack>
);

export const FailedItemsModal = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ModalDialog
    headline={props.modalTitle}
    isDismissable
    isOpen={props.isOpen}
    onClose={props.onClose}
    primaryAction={{
      text: 'Dismiss',
      onClick: props.onClose,
      ...getDataUiActionAttribute(DataUiAction.CloseDialog),
    }}
    ref={ref}
    withDividers
    {...getDataUiElementAttribute(props.dataUiElement)}
  >
    <ModalBody {...props} />
  </ModalDialog>
));

FailedItemsModal.displayName = 'FailedItemsModal';
