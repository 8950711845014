import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { LocalizationEditor_LoadItemVariantCount_Finished } from '../../constants/localizationActionTypes.ts';
import {
  ItemVariantCount,
  getItemVariantCountFromServerModel,
} from '../../models/ItemVariantCount.ts';

interface IDeps {
  readonly projectRepository: Pick<IProjectRepository, 'getItemVariantCount'>;
}

const itemVariantCountLoaded = (itemVariantCount: ItemVariantCount) =>
  ({
    type: LocalizationEditor_LoadItemVariantCount_Finished,
    payload: { itemVariantCount },
  }) as const;

export type LoadItemVariantCountActionsType = ReturnType<typeof itemVariantCountLoaded>;

export const createLoadItemVariantCount =
  ({ projectRepository }: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const itemVariantCountServerModel = await projectRepository.getItemVariantCount(
      projectId,
      abortSignal,
    );
    const itemVariantCount = getItemVariantCountFromServerModel(itemVariantCountServerModel);

    dispatch(itemVariantCountLoaded(itemVariantCount));
  };
