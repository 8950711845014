import { createGuid } from '@kontent-ai/utils';

const IsNumberRegex = /^\d+$/;

export function generateCodename(text: string): string {
  return text.replace(/\s/g, '_');
}

function getSuffix() {
  return `_${createGuid().substr(0, 7)}`;
}

function ensureValidCodename(codename: string): string {
  const isNumber = IsNumberRegex.test(codename.charAt(0));
  return isNumber ? `n${codename}` : codename;
}

export function generateUniqueCodename(
  text: string,
  usedCodenames: ReadonlyArray<string | null>,
): string {
  const codename = generateCodename(text);
  const validCodename = ensureValidCodename(codename);
  const isUsed = usedCodenames.includes(validCodename);
  return isUsed ? `${validCodename}${getSuffix()}` : validCodename;
}
