import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  MissionControl_Init_Finished,
  MissionControl_Init_Started,
} from '../../constants/missionControlActionTypes.ts';

interface IDeps {
  readonly loadUsers: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = () =>
  ({
    type: MissionControl_Init_Started,
  }) as const;

const finished = () =>
  ({
    type: MissionControl_Init_Finished,
  }) as const;

export type InitMissionControlActionsType = ReturnType<typeof started | typeof finished>;

export const initMissionControlActionCreator =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    await Promise.all([
      dispatch(deps.loadUsers(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadWorkflows(abortSignal)),
    ]);

    dispatch(finished());
  };
