import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { checkboxGroupSpacingBetweenOptions } from '@kontent-ai/component-library/CheckboxGroup';
import { Stack } from '@kontent-ai/component-library/Stack';
import { noOperation } from '@kontent-ai/utils';
import React, { RefObject, useState } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
  translateColumnCodeToUiAttribute,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { ColumnSettingsSource } from '../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { ConfigurationButton } from '../../../../../_shared/uiComponents/ConfigurationButton/ConfigurationButton.tsx';
import { DropDownOptionsGroup } from '../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import { StatefulDropDown } from '../../../../../_shared/uiComponents/DropDown/StatefulDropDown.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IColumnVisibility } from '../../../../contentInventory/content/features/ContentItemInventory/types/ColumnVisibility.type.ts';

export interface IColumnSettingsDispatchProps {
  readonly updateColumnFilter: (columnVisibility: IColumnVisibility) => void;
}

export interface IColumnSettingsStateProps {
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly selectableColumns: ReadonlyArray<ItemColumnCode>;
}

export interface IColumnSettingsOwnProps {
  readonly source: ColumnSettingsSource;
}

type ColumnSettingsProps = IColumnSettingsStateProps &
  IColumnSettingsDispatchProps &
  IColumnSettingsOwnProps;

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  appendTo: 'parent',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left'],
        },
      },
    ],
  },
};

export const ColumnSettings: React.FC<React.PropsWithChildren<ColumnSettingsProps>> = ({
  hiddenColumns,
  selectableColumns,
  updateColumnFilter,
}) => {
  const [lastTabIndex, setLastTabIndex] = useState(0);

  return (
    <div className="column-settings">
      <StatefulDropDown
        longer
        optionListDataUiAttributes={getDataUiActionAttribute(DataUiAction.ConfigureColumnSettings)}
        renderContent={() => (
          <DropDownOptionsGroup
            dataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.ColumnSettings)}
          >
            <Stack spacing={checkboxGroupSpacingBetweenOptions}>
              <Checkbox
                checkboxState="disabled"
                checked
                onToggle={noOperation}
                tooltipPlacement="left"
                tooltipText="Names can’t be hidden."
              >
                Name
              </Checkbox>
              {selectableColumns.map((column: ItemColumnCode, index: number) => {
                const uiElementAttribute = translateColumnCodeToUiAttribute(column);

                return (
                  <Checkbox
                    autoFocus={index === lastTabIndex}
                    checkboxState="default"
                    checked={!hiddenColumns.has(column)}
                    key={column}
                    onFocus={() => setLastTabIndex(index)}
                    onToggle={(isChecked) =>
                      updateColumnFilter({
                        columnCode: column,
                        visible: isChecked,
                      })
                    }
                    {...(uiElementAttribute && getDataUiElementAttribute(uiElementAttribute))}
                  >
                    {translateColumnCodeToTitle(column)}
                  </Checkbox>
                );
              })}
            </Stack>
          </DropDownOptionsGroup>
        )}
        renderSelectedOption={(
          ref: RefObject<HTMLElement>,
          onClick: () => void,
          isOptionListVisible: boolean,
        ) => (
          <ConfigurationButton
            dataUiAction={DataUiAction.ConfigureColumnSettings}
            iconName={IconName.Cogwheel}
            tooltipPlacement="left"
            tooltipText="Select visible columns"
            isActive={isOptionListVisible}
            onToggle={onClick}
            ref={ref}
          />
        )}
        tippyOptions={tippyOptions}
      />
    </div>
  );
};

ColumnSettings.displayName = 'ColumnSettings';
