import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_CreateSubscription_Failed,
  Subscription_CreateSubscription_Finished,
  Subscription_CreateSubscription_Started,
} from '../contants/actionTypes.ts';

export const creatingNewSubscription = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_CreateSubscription_Started:
      return true;

    case Subscription_CreateSubscription_Failed:
    case Subscription_CreateSubscription_Finished:
      return false;

    default:
      return state;
  }
};
