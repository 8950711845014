import { EditorState } from 'draft-js';
import { useCallback, useMemo, useState } from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { getContentStateActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { AiActionProps } from '../../../../../../_shared/features/AI/types/AiActionProps.type.ts';
import {
  AiActionSource,
  AiFollowingAction,
  TrackingAiActionName,
} from '../../../../../../_shared/models/events/AiActionEventData.type.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createImproveContentByBriefParams } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.improveContentByBrief.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None, Optional } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../editorCore/utils/decorable.ts';
import { extractSelectedContent } from '../../../../utils/general/editorContentUtils.ts';
import { LockEditorPlugin } from '../../../behavior/LockEditorPlugin.tsx';
import { ClipboardPlugin } from '../../../clipboard/ClipboardPlugin.tsx';
import { AiMenuItem, AiMenuPlugin, AiMenuSection, GetAiMenuItems } from '../../AiMenuPlugin.tsx';
import { AiMenuActionItem } from '../../components/menu/AiMenuActionItem.tsx';
import { createAiActionResultSelector } from '../../helpers/createAiActionResultSelector.ts';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard.ts';
import { useReplaceSelection } from '../../hooks/useReplaceSelection.ts';
import { ResultPositioner, useResultPositioner } from '../../hooks/useResultPositioner.tsx';
import { useResultWithPreservedBlockKeys } from '../../hooks/useResultWithPreservedBlockKeys.ts';
import { ImproveContentAction } from './ImproveContentAction.tsx';

export type ImproveContentPlugin = EditorPlugin<
  None,
  AiActionProps,
  None,
  None,
  [ClipboardPlugin, LockEditorPlugin, Optional<AiMenuPlugin>]
>;

export const ImproveContentPlugin: PluginComponent<ImproveContentPlugin> = (props) => {
  const [actionEditorState, setActionEditorState] = useState<EditorState | null>(null);
  const [contentInstructions, setContentInstructions] = useState('');

  const {
    aiSessionId,
    elementOperationTrackingData,
    resetAiSessionId,
    trackFinishedAction,
    trackFollowingAction,
    trackStartingAction,
  } = useAiActionTrackingWithSession(props.element);

  const { decorateWithEditorStateCallbacks, getEditorState, lockEditor, unlockEditor } =
    useEditorStateCallbacks<ImproveContentPlugin>();

  const { cancel, run, result } = useAiTask(
    AiActionName.ImproveContentByBrief,
    createAiActionResultSelector(props.element.elementType, getContentStateActionResult),
  );

  useOnFinishedAiActionTask(
    result.isFinished,
    () => result.trackingParams && trackFinishedAction(result.trackingParams),
  );

  const startImproveContentAction = useCallback(
    (instructions: string, actionSource: AiActionSource) => {
      if (!actionEditorState) {
        return;
      }

      const contentState = extractSelectedContent(
        actionEditorState.getCurrentContent(),
        actionEditorState.getSelection(),
      );

      setContentInstructions(instructions);

      run(
        createImproveContentByBriefParams(contentState, instructions, elementOperationTrackingData),
      );

      trackStartingAction({
        action: AiActionName.ImproveContentByBrief,
        source: actionSource,
      });
    },
    [trackStartingAction, actionEditorState, run, elementOperationTrackingData],
  );

  const start = useCallback(async () => {
    const editorState = getEditorState();
    await lockEditor(editorState);
    setActionEditorState(editorState);
    trackStartingAction({
      action: TrackingAiActionName.ImproveContent,
      source: AiActionSource.InlineToolbar,
    });
  }, [trackStartingAction, lockEditor, getEditorState]);

  const reset = useCallback(() => {
    if (!result.isFinished) {
      cancel();
    }
    resetAiSessionId();
    setActionEditorState(null);
    setContentInstructions('');
    unlockEditor();
  }, [unlockEditor, cancel, result.isFinished, resetAiSessionId]);

  const tryAgain = useMemo(
    () =>
      result.isFinished
        ? () => {
            if (actionEditorState) {
              trackFollowingAction({ action: AiFollowingAction.TryAgain });
              startImproveContentAction(contentInstructions, AiActionSource.ActionMenu);
            }
          }
        : undefined,
    [
      startImproveContentAction,
      actionEditorState,
      contentInstructions,
      result.isFinished,
      trackFollowingAction,
    ],
  );

  const { decorateWithReplaceCallbacks, replaceSelection } = useReplaceSelection(actionEditorState);

  const onReplaceSelection = useMemo(() => {
    const content = result.content;

    return result.isFinished && content
      ? () => {
          trackFollowingAction({ action: AiFollowingAction.ReplaceSelection });
          reset();
          replaceSelection(content);
        }
      : undefined;
  }, [replaceSelection, reset, result.content, result.isFinished, trackFollowingAction]);

  const { copyToClipboard } = useCopyToClipboard();

  const onCopyToClipboard = useMemo(() => {
    const content = result.content;

    return result.isFinished && content
      ? () => {
          trackFollowingAction({ action: AiFollowingAction.CopyToClipboard });
          reset();
          copyToClipboard(content, aiSessionId);
        }
      : undefined;
  }, [
    copyToClipboard,
    reset,
    result.content,
    result.isFinished,
    trackFollowingAction,
    aiSessionId,
  ]);

  const { decorateWithPositionerCallbacks, resultPositionerProps } = useResultPositioner(
    !!actionEditorState,
  );
  const resultWithPreservedBlockKeys = useResultWithPreservedBlockKeys(result);
  const renderOverlays: Decorator<Render<ImproveContentPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => (
      <>
        {baseRenderOverlays(state)}
        {actionEditorState && (
          <ResultPositioner
            {...resultPositionerProps}
            renderResult={(isPositionedAboveContent, resultWidth, resultRef) => (
              <ImproveContentAction
                initialInstructions={contentInstructions}
                onClosePropsDialog={() => {
                  trackFollowingAction({ action: AiFollowingAction.Cancel });
                  reset();
                }}
                onDiscard={() => {
                  trackFollowingAction({ action: AiFollowingAction.Discard });
                  reset();
                }}
                onCopyToClipboard={onCopyToClipboard}
                onInputsEdited={() =>
                  trackFollowingAction({ action: AiFollowingAction.EditInputs })
                }
                onReplaceSelection={onReplaceSelection}
                onSubmit={(instructions) =>
                  startImproveContentAction(instructions, AiActionSource.ActionDialog)
                }
                onTryAgain={tryAgain}
                preferMenuOnTop={isPositionedAboveContent}
                ref={resultRef}
                result={resultWithPreservedBlockKeys}
                resultWidth={resultWidth}
              />
            )}
          />
        )}
      </>
    ),
    [
      actionEditorState,
      contentInstructions,
      reset,
      onCopyToClipboard,
      onReplaceSelection,
      startImproveContentAction,
      resultWithPreservedBlockKeys,
      resultPositionerProps,
      tryAgain,
      trackFollowingAction,
    ],
  );

  const getAiMenuItems: Decorator<GetAiMenuItems> = useCallback(
    (baseGetAiMenuItems) => (editorState) => {
      return [
        ...baseGetAiMenuItems(editorState),
        ...(isActionAvailable(editorState)
          ? [
              {
                id: 'improve-content',
                label: 'Improve content',
                renderIntoMenu: (item, onActionStarted) => (
                  <AiMenuActionItem
                    iconName={IconName.Diamond}
                    label={item.label}
                    onPress={() => {
                      onActionStarted();
                      start();
                    }}
                    {...getDataUiActionAttribute(DataUiRteAction.ImproveContent)}
                  />
                ),
                section: AiMenuSection.EditOrReviewSelection,
                type: 'item',
              } satisfies AiMenuItem,
            ]
          : []),
      ];
    },
    [start],
  );

  const apply: Apply<ImproveContentPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      decorateWithReplaceCallbacks(state);
      decorateWithPositionerCallbacks(state);
      state.getInlineToolbarAiMenuItems?.decorate(getAiMenuItems);
      state.renderOverlays.decorate(renderOverlays);
      return {};
    },
    [
      decorateWithEditorStateCallbacks,
      decorateWithReplaceCallbacks,
      decorateWithPositionerCallbacks,
      getAiMenuItems,
      renderOverlays,
    ],
  );

  return useEditorWithPlugin(props, { apply });
};

const isActionAvailable = (_: EditorState) => true;
