import { Route, Switch } from 'react-router';
import { RedirectIf } from '../../../_shared/components/routing/RedirectIf.tsx';
import {
  ContentItemAccessDeniedRoute,
  ContentItemAccessDeniedRouteParams,
  ContentItemPreviewWithEditorRoute,
  ContentItemRoute,
  RootRoute,
} from '../../../_shared/constants/routePaths.ts';
import { IRouteContext, RouteContext } from '../../../_shared/containers/routing/RouteContext.tsx';
import { AccessDeniedToContentItemAction } from '../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { AccessDeniedToContentItem } from '../../errorHandling/containers/AccessDeniedToContentItem.tsx';
import { ContentItemEditing } from '../../itemEditor/components/ContentItemEditing.tsx';
import { WebSpotlightTree } from '../containers/WebSpotlightTree.tsx';
import { WebSpotlightContextProvider } from '../context/WebSpotlightContext.tsx';
import { WebSpotlightEditorView } from './WebSpotlightEditorView.tsx';
import { WebSpotlightGrid } from './WebSpotlightGrid.tsx';
import { WebSpotlightLoaderView } from './WebSpotlightLoaderView.tsx';
import { WebSpotlightPreviewView } from './WebSpotlightPreviewView.tsx';
import { WebSpotlightRevisionView } from './WebSpotlightRevisionView.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';

type Props = {
  readonly rootItemId: Uuid;
};

export const InitializedWebSpotlight = ({ rootItemId }: Props) => {
  return (
    <WebSpotlightContextProvider>
      <WebSpotlightGrid>
        <WebSpotlightTree rootItemId={rootItemId} />
        <Switch>
          <Route path={ContentItemAccessDeniedRoute}>
            <RouteContext>
              {({ match }: IRouteContext<ContentItemAccessDeniedRouteParams<string>>) => (
                <RedirectIf
                  redirectTo={RootRoute}
                  doRedirect={
                    !Object.values(AccessDeniedToContentItemAction).includes(
                      match.params.requestedAction,
                    )
                  }
                >
                  <WebSpotlightWorkspace>
                    <AccessDeniedToContentItem requestedAction={match.params.requestedAction} />
                  </WebSpotlightWorkspace>
                </RedirectIf>
              )}
            </RouteContext>
          </Route>
          <Route strict path={ContentItemRoute}>
            <ContentItemEditing
              renderEditor={() => <WebSpotlightEditorView />}
              renderRevision={(timelineItemId) => (
                <WebSpotlightRevisionView timelineItemId={timelineItemId} />
              )}
              renderPreview={() => <WebSpotlightPreviewView />}
              renderNotInitialized={() => <WebSpotlightLoaderView />}
            />
          </Route>
          <Route strict path={ContentItemPreviewWithEditorRoute}>
            {/* In case the route is preview route, we always render preview as it has its own loader logic independent of the edited item state */}
            <ContentItemEditing
              renderEditor={() => <WebSpotlightEditorView />}
              renderRevision={(timelineItemId) => (
                <WebSpotlightRevisionView timelineItemId={timelineItemId} />
              )}
              renderPreview={() => <WebSpotlightPreviewView />}
              renderNotInitialized={() => <WebSpotlightPreviewView />}
            />
          </Route>
        </Switch>
      </WebSpotlightGrid>
    </WebSpotlightContextProvider>
  );
};
