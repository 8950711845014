import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { EntityWebhookSecret as EntityWebhookSecretComponent } from '../../components/settings/EntityWebhookSecret.tsx';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';

const { entityWebhookRepository } = repositoryCollection;

type EntityWebhookSecretProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookSecret = ({ enabled, formProps }: EntityWebhookSecretProps) => {
  const { control } = formProps;
  const { field, fieldState } = useController({
    control,
    name: 'secret',
  });

  const [informationMessage, setInformationMessage] = useState<string>('');
  const [secretGenerationError, setSecretGenerationError] = useState<string>('');
  const fieldErrorMessage = fieldState.error?.message;
  const showMessageDelayRef = useRef<ICancellablePromise>();

  const showInformationMessage = useCallback((message: string): void => {
    setInformationMessage(message);
    showMessageDelayRef.current?.cancel();
    showMessageDelayRef.current = delay(1000)
      .then(() => {
        setInformationMessage('');
      })
      .catch(swallowCancelledPromiseError);
  }, []);

  const onGenerateNewSecret = () => {
    field.onChange('');
  };

  useEffect(() => {
    if (!field.value) {
      setInformationMessage('Generating secret...');
      setSecretGenerationError('');
      entityWebhookRepository
        .generateSecret()
        .then(({ secret }) => {
          field.onChange(secret);
          showInformationMessage('New secret generated!');
        })
        .catch((e) => {
          logError(`${__filename}: Failed to generate webhook secret.`, e);
          setSecretGenerationError(
            'I wasn’t able to generate new secret for you. Please contact us for help.',
          );
          setInformationMessage('');
        });
    }
  }, [field, field.value, showInformationMessage]);

  return (
    <EntityWebhookSecretComponent
      enabled={enabled}
      errorMessage={fieldErrorMessage || secretGenerationError || ''}
      formProps={formProps}
      informationMessage={informationMessage}
      onGenerateNewSecret={onGenerateNewSecret}
      secretValue={field.value}
    />
  );
};

EntityWebhookSecret.displayName = 'EntityWebhookSecretContainer';
