import { useCallback } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IntercomUtils } from '../../../../_shared/utils/intercomUtils.ts';
import {
  getCurrentProjectId,
  getProjectInfo,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

export const useEnableFeatureIntercomMessage = (featureDisplayName: string): (() => void) => {
  const environmentId = useSelector(getCurrentProjectId);
  const environmentInfo = useSelector((state) => getProjectInfo(state, environmentId));

  return useCallback(() => {
    IntercomUtils.showIntercom();
    IntercomUtils.showMessage(
      `Dear Kontent.ai support, I would like to enable ${featureDisplayName} on` +
        ` project environment "${environmentInfo.projectName} – ${environmentInfo.environmentName}"` +
        ` (ID ${environmentInfo.projectId}).`,
    );
  }, [environmentInfo, featureDisplayName]);
};
