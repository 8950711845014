import { fromUnixTime, isValid } from 'date-fns';

const parseAuthTokenJson = (accessToken: AuthToken) => {
  const base64content = accessToken.split('.')[1] ?? '';
  return JSON.parse(atob(base64content));
};

const parseCustomProperty = (accessToken: AuthToken, propertyName: string) => {
  try {
    const json = parseAuthTokenJson(accessToken);
    const propertyValue = json[`${self._envConfig.auth0ClaimNamespace}/${propertyName}`];
    return propertyValue;
  } catch {
    return undefined;
  }
};

export const parseEmail = (accessToken: AuthToken): string | null => {
  const email = parseCustomProperty(accessToken, 'email');
  return email ?? null;
};

export const parseEmailVerified = (accessToken: AuthToken): boolean => {
  const emailVerified = parseCustomProperty(accessToken, 'email_verified');
  return emailVerified ?? false;
};

export function parseJwtExpiresAt(accessToken: AuthToken): Date {
  try {
    const json = parseAuthTokenJson(accessToken);
    const expiresAt = fromUnixTime(json.exp);

    if (isValid(expiresAt)) {
      return expiresAt;
    }
  } catch {
    // in case of an error, return the start of "unix epoch" time — accessToken is automatically expired
  }
  return new Date(0);
}
