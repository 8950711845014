import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import { TippyProps } from '@tippyjs/react';
import { Modifier } from 'react-popper';

const featuresInfluencingModifiers: ReadonlyMap<string, ReadonlyArray<string>> = new Map([
  // Influences => Influenced
  ['preventOverflow', ['flip']],
  ['arrow', ['flip']],
  ['arrow', ['inlinePositioning']],
  ['offset', ['inlinePositioning']],
]);

const getFeature = (modifier: Modifier<any>): string => modifier.name;

export function getEnabledFeatures(
  modifiers: ReadonlyArray<Modifier<any>> | undefined,
): ReadonlyArray<string> {
  return modifiers?.filter((modifier) => modifier.enabled !== false).map(getFeature) ?? [];
}

const loggedErrors = new Set<string>();

function logErrorOnce(error: string) {
  if (!loggedErrors.has(error)) {
    ComponentLibraryGlobals.logError(error);
    loggedErrors.add(error);
  }
}

export function assertFeaturesAddedInOrder(
  tippyOptions: TippyProps,
  ...newFeatures: ReadonlyArray<string>
) {
  const alreadyPresentFeatures = getEnabledFeatures(tippyOptions.popperOptions?.modifiers);
  if (alreadyPresentFeatures.length === 0) {
    return;
  }

  newFeatures
    .flatMap((feature) =>
      (featuresInfluencingModifiers.get(feature) ?? [])
        .filter((influencedModifierName) => alreadyPresentFeatures.includes(influencedModifierName))
        .map(
          (violatedModifierName) =>
            `Feature '${violatedModifierName}' must be applied after '${feature}' as its properties depend on the latter.`,
        ),
    )
    .forEach(logErrorOnce);
}
