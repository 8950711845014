import React from 'react';
import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CollectionsTitle } from '../../../constants/uiConstants.ts';
import { ClickableCollectionSection } from '../../../containers/details/Collection/SectionTypes/ClickableCollectionSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

export const CollectionSection: React.FC = () => (
  <Section
    ClickableSectionComponent={ClickableCollectionSection}
    NonClickableSectionComponent={() => null}
    ReadonlySectionComponent={() => null}
    title={CollectionsTitle}
    type={SectionType.Clickable}
    uiObjectNameAttribute={ItemDetailSection.ContentCollectionsSection}
  />
);

CollectionSection.displayName = 'CollectionSection';
