import React, { useCallback } from 'react';
import { StatusBarActions } from '../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { saveEditedCollections } from '../actions/collectionsThunkActions.ts';

export const CollectionsToolbarActions: React.FC = () => {
  const dispatch = useDispatch();
  const isBeingSaved = useSelector((s) => s.collectionsApp.isBeingSaved);
  const isInitialized = useSelector((s) => s.collectionsApp.isInitialized);

  const save = useCallback(() => dispatch(saveEditedCollections()), []);

  return <StatusBarActions isBeingSaved={isBeingSaved} isLoading={!isInitialized} onSave={save} />;
};

CollectionsToolbarActions.displayName = 'CollectionsToolbarActions';
