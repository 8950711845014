import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_FocusElementMovedToPreviousPosition,
  ContentModels_TypeEditor_TypeElementCreated,
  ContentModels_TypeEditor_TypeElementDropped,
  ContentModels_TypeEditor_TypeElementMoved,
} from '../../constants/sharedContentModelsActionTypes.ts';

export function lastMovedElementId(
  state: Uuid | undefined | null = null,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case ContentModels_TypeEditor_FocusElementMovedToPreviousPosition: {
      return action.payload.typeElementId;
    }

    case ContentModels_TypeEditor_TypeElementCreated:
    case ContentModels_TypeEditor_TypeElementDropped:
    case ContentModels_TypeEditor_TypeElementMoved:
      return null;

    default:
      return state;
  }
}
