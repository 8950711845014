import { Chevron } from '@kontent-ai/component-library/Chevron';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { IconSize, Spacing, colorIconDefaultInverse } from '@kontent-ai/component-library/tokens';
import React, { MouseEventHandler } from 'react';
import { StyledSelectCell } from './components/StyledSelectCell.tsx';

export interface ISelectCellProps {
  readonly isActive?: boolean;
  readonly label: string;
  readonly onClick?: MouseEventHandler<HTMLElement>;
}

export const SelectCell = React.forwardRef<HTMLButtonElement, ISelectCellProps>(
  ({ isActive, label, onClick, ...otherProps }, forwardedRef) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    return (
      <OptionalTooltip
        placement="bottom-start"
        text={label}
        customRenderText={(ref) => (
          <StyledSelectCell
            isActive={isActive}
            isFocusVisible={isFocusVisible}
            onClick={onClick}
            ref={forwardedRef}
            {...focusProps}
            {...otherProps}
          >
            <Row alignY="center" spacing={Spacing.S} component="span" noWrap>
              <Column component="span">
                <Label size={LabelSize.L} ref={ref}>
                  {label}
                </Label>
              </Column>
              <Column component="span" width="content">
                <Chevron
                  direction={isActive ? 'up' : 'down'}
                  size={IconSize.S}
                  color={colorIconDefaultInverse}
                />
              </Column>
            </Row>
          </StyledSelectCell>
        )}
      />
    );
  },
);

SelectCell.displayName = 'SelectCell';
