import { Action } from '../../../../../@types/Action.type.ts';
import { YourContentTabName } from '../../../../../_shared/constants/yourContentTabName.ts';
import { Content_YourContent_TabOpened } from '../../../../contentInventory/content/constants/contentActionTypes.ts';

export const selectedTab = (
  state: YourContentTabName = YourContentTabName.RecentlyEditedByYou,
  action: Action,
): YourContentTabName => {
  switch (action.type) {
    case Content_YourContent_TabOpened: {
      const { tabName } = action.payload;
      return tabName;
    }

    default: {
      return state;
    }
  }
};
