import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { dismissUiBlockingMessage } from '../../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isErrorStatus } from '../../../../../_shared/models/StatusMessage.ts';
import { getItemSavingStatus } from '../../ContentItemEditing/containers/ContentItemSavingStatus.tsx';

interface IUsePendingDialogResult {
  readonly isPending: boolean;
  readonly showDialog: boolean;
  readonly hasError: boolean;
}

const minimumPendingTimeInMs = 1000;

export function usePendingDialog(
  isPending: boolean,
  onConfirm: () => void,
): IUsePendingDialogResult {
  // UI blocking message prevents navigation, but doesn't render the pending changes dialog
  // and automatically continues with the navigation after it is dismissed
  const isUiBlockingMessageDisplayed = useSelector((state) => !!state.sharedApp.uiBlockingMessage);
  const shouldDismissUiBlockingMessageOnNavigation = useSelector(
    (state) => !!state.sharedApp.uiBlockingMessage?.dismissOnNavigation,
  );
  const mountedWithUIBlockingMessage = useRef(isUiBlockingMessageDisplayed).current;

  const [displayAsPending, setDisplayAsPending] = useState(!mountedWithUIBlockingMessage);

  const debouncedSetDisplayAsPending = useDebouncedCallback(
    setDisplayAsPending,
    minimumPendingTimeInMs,
  );

  useEffect(() => {
    if (!mountedWithUIBlockingMessage) {
      debouncedSetDisplayAsPending(isPending);
    }
    return debouncedSetDisplayAsPending.cancel;
  }, [mountedWithUIBlockingMessage, isPending, debouncedSetDisplayAsPending]);

  const dispatch = useDispatch();

  // We don't display the dialog when there is UI blocking message
  // instead, we dismiss it automatically and continue once all pending operations are done
  useEffect(() => {
    if (
      mountedWithUIBlockingMessage &&
      (!isUiBlockingMessageDisplayed || shouldDismissUiBlockingMessageOnNavigation) &&
      !isPending
    ) {
      if (shouldDismissUiBlockingMessageOnNavigation) {
        dispatch(dismissUiBlockingMessage());
      }
      onConfirm();
    }
  }, [
    mountedWithUIBlockingMessage,
    isUiBlockingMessageDisplayed,
    shouldDismissUiBlockingMessageOnNavigation,
    isPending,
    onConfirm,
  ]);

  const savingStatus = useSelector(getItemSavingStatus);

  return {
    isPending: displayAsPending,
    showDialog: !mountedWithUIBlockingMessage,
    hasError: isErrorStatus(savingStatus.messageType),
  };
}
