import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypesUsage: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createLoadContentTypesDataAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadContentTypesUsage(abortSignal)),
    ]);
  };
