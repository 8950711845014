import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  UserListing_Init_Finished,
  UserListing_Init_Started,
  UserListing_Left,
} from '../../constants/usersActionTypes.ts';

export function listingLoadingStatus(
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case UserListing_Left:
    case Shared_CurrentProjectId_Updated: {
      return LoadingStatus.InitialEmpty;
    }

    case UserListing_Init_Started: {
      return LoadingStatus.Loading;
    }

    case UserListing_Init_Finished: {
      return LoadingStatus.Loaded;
    }

    default:
      return state;
  }
}
