import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectContainer } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { projectNameUpdated } from '../actions/generalProjectSettingsActions.ts';
import { ProjectNameCard as ProjectNameCardComponent } from '../components/ProjectNameCard.tsx';

export const ProjectNameCard: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useDispatch();
  const currentProjectName = useSelector((s) => getCurrentProjectContainer(s).projectName);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(projectNameUpdated(currentProjectName));
      setIsInitialized(true);
    }
  }, [currentProjectName, isInitialized]);

  if (!isInitialized) {
    return <Loader />;
  }

  return <ProjectNameCardComponent />;
};

ProjectNameCard.displayName = 'ProjectNameCard';
