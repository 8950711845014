import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';

export const isAssetLibraryLoading = (state: IStore): boolean => {
  const {
    data: {
      assetFolders: { loadingStatus: foldersLoadingStatus },
      assets: {
        listingAssets: { listingIds },
        loadingStatus: assetLoadingStatus,
      },
    },
  } = state;

  return (
    assetLoadingStatus === LoadingStatus.InitialEmpty ||
    foldersLoadingStatus === LoadingStatus.InitialEmpty ||
    (assetLoadingStatus === LoadingStatus.Loading && listingIds.isEmpty()) ||
    foldersLoadingStatus === LoadingStatus.Loading
  );
};
