import { useEffect } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { handleUnsuccessfulRedirect } from '../actions/smartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { LinkIsInvalidMessage } from '../constants/uiConstants.ts';

export const OtherRouteSmartLink = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));
  }, []);

  return <SmartLink />;
};
