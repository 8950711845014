import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  getSelectedSubscriptionIdOrThrow,
  getSubscriptionPlan,
} from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';

interface IDeps {
  readonly loadEnvironmentRoles: (projectId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadEnvironmentCollections: (projectId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadEnvironmentLanguages: (projectId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
}

export const createLoadSubscriptionUserListingFilterDepsAction =
  ({ loadEnvironmentCollections, loadEnvironmentLanguages, loadEnvironmentRoles }: IDeps) =>
  (environmentId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const subscriptionId = getSelectedSubscriptionIdOrThrow(getState());
    const subscriptionPlan = getSubscriptionPlan(getState(), subscriptionId);

    const shouldLoadEnvironmentCollections =
      subscriptionPlan.features.areContentCollectionsEnabled &&
      subscriptionPlan.features.areContentCollectionsPermissionsEnabled;

    await Promise.all([
      dispatch(loadEnvironmentRoles(environmentId, abortSignal)),
      shouldLoadEnvironmentCollections &&
        dispatch(loadEnvironmentCollections(environmentId, abortSignal)),
      subscriptionPlan.features.areLanguageRolesEnabled &&
        dispatch(loadEnvironmentLanguages(environmentId, abortSignal)),
    ]);
  };
