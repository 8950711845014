import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { Data_Projects_Success } from '../../../constants/dataActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Data_Projects_Success:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
};
