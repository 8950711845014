import React, { RefObject, useRef } from 'react';
import { TaxonomyTermAssignedSourceType } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { getConsistentSelectedTermIds } from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { ITaxonomyItemElement } from '../../../../../models/contentItemElements/TaxonomyItemElement.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { TaxonomyAiAssistant } from '../../../containers/elements/taxonomy/TaxonomyAiAssistant.tsx';
import { TaxonomySelector } from '../../../containers/elements/taxonomy/TaxonomySelector.tsx';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';
import { TaxonomySelectorView } from './TaxonomySelector.tsx';

export type TaxonomyExtraProps = {
  readonly isNumberOfTermsLimitMet?: boolean;
  readonly onTermSelectionChanged: (
    termIds: ReadonlySet<Uuid>,
    source: TaxonomyTermAssignedSourceType,
  ) => void;
  readonly taxonomyGroup?: ITaxonomyGroup;
};

export type TaxonomyProps = IItemElementComponentOwnProps<
  ITaxonomyItemElement,
  ITaxonomyTypeElement
> &
  TaxonomyExtraProps;

const TaxonomyWrapper: React.FC<TaxonomyProps> = (props) => {
  const taxonomyElementRef = useRef<HTMLDivElement>(null);

  return (
    <ItemElement
      disabled={props.disabled}
      ref={taxonomyElementRef}
      title={props.typeElement.name}
      typeElement={props.typeElement}
    >
      <ItemElementRefresher
        elementData={props.elementData}
        renderInput={(refresherItemElement: ITaxonomyItemElement) => (
          <TaxonomyElementContent
            refresherItemElement={refresherItemElement}
            taxonomyElementRef={taxonomyElementRef}
            {...props}
          />
        )}
      />
    </ItemElement>
  );
};

export type TaxonomyElementContentProps = TaxonomyProps & {
  readonly refresherItemElement: ITaxonomyItemElement;
  readonly taxonomyElementRef: RefObject<HTMLDivElement>;
};

const TaxonomyElementContent: React.FC<TaxonomyElementContentProps> = ({
  autoFocus,
  isNumberOfTermsLimitMet,
  refresherItemElement,
  taxonomyElementRef,
  taxonomyGroup,
  typeElement,
  ...otherProps
}) => {
  const selectedTermIds = getConsistentSelectedTermIds(
    refresherItemElement.groupId,
    refresherItemElement.value,
    taxonomyGroup,
  );

  return (
    <TaxonomyAiAssistant
      currentlySelectedTerms={selectedTermIds}
      disabled={otherProps.disabled}
      onTermSelectionChanged={otherProps.onTermSelectionChanged}
      taxonomyElementRef={taxonomyElementRef}
      typeElement={typeElement}
      renderSelector={({
        areAiTaxonomiesEnabled,
        disabled,
        isAiAssistantActive,
        onTermSelectionChanged,
      }) => (
        <TaxonomySelector
          autoFocus={autoFocus}
          defaultValue={typeElement.defaultValue}
          disabled={disabled}
          isNumberOfTermsLimitMet={isNumberOfTermsLimitMet}
          maxItems={typeElement.maxItems}
          minItems={typeElement.minItems}
          onTermSelectionChanged={onTermSelectionChanged}
          selectedTermIds={selectedTermIds}
          taxonomyGroupId={typeElement.taxonomyGroupId}
          tooltipText={
            isAiAssistantActive
              ? 'Finish or discard the AI selection to pick the terms manually.'
              : undefined
          }
          view={areAiTaxonomiesEnabled ? TaxonomySelectorView.Compact : TaxonomySelectorView.Auto}
        />
      )}
    />
  );
};

TaxonomyElementContent.displayName = 'Taxonomy';
export { TaxonomyWrapper as Taxonomy };
