import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ISubscriptionAdmin } from '../../shared/models/SubscriptionAdmin.ts';
import { resendInvitationToAdmin, revokeAdmin } from '../actions/thunkAdminsActions.ts';
import {
  ISubscriptionAdminInvitationStatusDispatchProps,
  ISubscriptionAdminInvitationStatusStateProps,
  SubscriptionAdminInvitationStatus as SubscriptionAdminInvitationStatusComponent,
} from '../components/SubscriptionAdminInvitationStatus.tsx';

interface IContainerProps {
  readonly subscriptionAdmin: ISubscriptionAdmin;
}

function mapStateToProps(
  state: IStore,
  ownProps: IContainerProps,
): ISubscriptionAdminInvitationStatusStateProps {
  const { subscriptionApp } = state;
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId ?? '';

  return {
    adminsBeingReinvitedIds: subscriptionApp.admins.adminsBeingReinvitedIds,
    adminsBeingRevokedIds: subscriptionApp.admins.adminsBeingRevokedIds,
    adminsReinvitedIds: subscriptionApp.admins.adminsReinvitedIds,
    subscriptionAdmin: ownProps.subscriptionAdmin,
    subscriptionId,
  };
}

function mapDispatchToProps(dispatch: Dispatch): ISubscriptionAdminInvitationStatusDispatchProps {
  return {
    resendInvitationToAdmin: compose(dispatch, resendInvitationToAdmin),
    revokeAdmin: compose(dispatch, revokeAdmin),
  };
}

export const SubscriptionAdminInvitationStatus: React.ComponentType<IContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionAdminInvitationStatusComponent);
