import {
  getSelectableWorkflowStepsForBulk,
  getSelectableWorkflowStepsForEditedVariant,
} from '../../../../applications/contentInventory/content/features/ContentItemInventory/selectors/getSelectableWorkflowSteps.ts';
import { IWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { getWorkflow } from '../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import { ChangeWorkflowStepModalAction } from '../types/workflowStepModalActionTypes.ts';
import { isUnpublishingOrRestoringFromArchivedStep } from '../utils/changeWorkflowStepModalActionUtils.ts';
import { isWorkflowStepModalOpenedInBulk } from '../utils/changeWorkflowStepModalUtils.ts';

export const getSelectableWorkflowSteps = (state: IStore): readonly IWorkflowStep[] => {
  const {
    contentApp: {
      changeWorkflowStepModalData,
      editedContentItemVariant,
      listingUi: { bulkActionCheckResult },
    },
  } = state;

  if (cannotSelectTargetWorkflowStep(changeWorkflowStepModalData.workflowStepAction)) {
    return [];
  }

  const { workflowId } = changeWorkflowStepModalData.workflowStep;
  const currentWorkflow = getWorkflow(state, workflowId);

  if (isWorkflowStepModalOpenedInBulk(changeWorkflowStepModalData)) {
    return getSelectableWorkflowStepsForBulk(bulkActionCheckResult, currentWorkflow);
  }

  return getSelectableWorkflowStepsForEditedVariant(
    editedContentItemVariant,
    currentWorkflow ?? null,
  );
};

const cannotSelectTargetWorkflowStep = (
  workflowStepAction: ChangeWorkflowStepModalAction | null,
): boolean => {
  return isUnpublishingOrRestoringFromArchivedStep(workflowStepAction);
};
