import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_SubscriptionAdminInvitation_Canceled,
  Subscription_SubscriptionAdminInvitation_Failed,
  Subscription_SubscriptionAdminInvitation_Finished,
} from '../constants/actionTypes.ts';

export const createNewAdminErrorMessage = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Subscription_SubscriptionAdminInvitation_Canceled:
    case Subscription_SubscriptionAdminInvitation_Finished:
      return '';

    case Subscription_SubscriptionAdminInvitation_Failed:
      return action.payload.errorMessage;

    default:
      return state;
  }
};
