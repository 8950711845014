import React from 'react';
import { IMultipleChoiceTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { IMultipleChoiceItemElement } from '../../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { MultipleChoiceDiff } from './subelements/MultipleChoiceDiff.tsx';

type MultipleChoiceProps = IRevisionItemElementProps<
  IMultipleChoiceItemElement,
  IMultipleChoiceTypeElement
>;

export const MultipleChoice: React.FC<MultipleChoiceProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => (
  <ItemElement
    disabled
    isCompact
    isDisplayOnly
    hideValidationStatus={hideValidationStatus}
    typeElement={typeElement}
    revisionChangedBy={revisionElementModifiedBy}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: IMultipleChoiceItemElement) => (
        <MultipleChoiceDiff
          elementData={refresherElementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      )}
    />
  </ItemElement>
);

MultipleChoice.displayName = 'MultipleChoice (Revisions)';
