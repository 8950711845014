import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  DataUiAppName,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useWebSpotlight } from '../context/WebSpotlightContext.tsx';
import { useIsPreviewUrlOutdated } from '../hooks/useIsPreviewUrlOutdated.ts';
import { useWebSpotlightTabRoutes } from '../hooks/useWebSpotlightTabRoutes.ts';
import { isPreviewAutoRefreshFeatureAvailable } from '../selectors/webSpotlightSelectors.ts';
import { WebSpotlightTabName } from '../types/webSpotlightTabName.ts';
import { WebSpotlightPreviewApiLastChangesStatus } from './WebSpotlightPreviewApiLastChangesStatus.tsx';
import { WebSpotlightPreviewControls } from './preview/WebSpotlightPreviewControls.tsx';

export const WebSpotlightTabs = () => {
  const { previewPath, editorPath, previewMatchedParams } = useWebSpotlightTabRoutes();

  const { refreshPreview } = useWebSpotlight();

  const isAutoRefreshAvailable = useSelector(isPreviewAutoRefreshFeatureAvailable);
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const onManualRefreshPreview = () =>
    refreshPreview({
      isManualRefresh: true,
      isPreviewUrlOutdated,
    });

  return (
    <Box css="grid-area: tabs;">
      <Box marginLeft={px(-1 * borderWidthDefault)}>
        <TabView
          selectedKey={
            previewMatchedParams ? WebSpotlightTabName.Preview : WebSpotlightTabName.Editor
          }
          items={[
            {
              label: WebSpotlightTabName.Preview,
              id: WebSpotlightTabName.Preview,
              to: previewPath,
              ...getDataUiObjectNameAttribute(DataUiAppName.WebSpotlightPreview),
            },
            {
              label: WebSpotlightTabName.Editor,
              id: WebSpotlightTabName.Editor,
              to: editorPath,
              ...getDataUiObjectNameAttribute(DataUiAppName.WebSpotlightEditor),
            },
          ]}
        >
          <TabView.TabGroup
            renderAuxElement={() =>
              isAutoRefreshAvailable && (
                <WebSpotlightPreviewApiLastChangesStatus
                  onRefreshPreview={onManualRefreshPreview}
                />
              )
            }
          />
          <TabView.TabPanel
            css={`
              padding: ${px(Spacing.S)} ${px(Spacing.L)};
              border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
            `}
          >
            {(activeTabId) =>
              activeTabId === WebSpotlightTabName.Preview ? <WebSpotlightPreviewControls /> : null
            }
          </TabView.TabPanel>
        </TabView>
      </Box>
    </Box>
  );
};
