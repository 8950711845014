import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React, { useMemo } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';

type SelectWorkflowWhenCreatingItemDialogProps = {
  readonly isDismissable: boolean;
  readonly isSavingDisabled: boolean;
  readonly workflows: ReadonlyArray<Workflow>;
  readonly onCancel?: () => void;
  readonly onSave: () => void;
  readonly onSelectWorkflow: (workflowId: Uuid) => void;
};

export const NewVariantWorkflowSelectionDialog: React.FC<
  SelectWorkflowWhenCreatingItemDialogProps
> = ({ isDismissable, isSavingDisabled, workflows, onCancel, onSave, onSelectWorkflow }) => {
  const singleSelectItems = useMemo(
    () =>
      workflows.map(({ id, name }) => ({
        id,
        label: name,
      })),
    [workflows],
  );

  return (
    <ModalDialog
      headline="Create a new language variant"
      isDismissable={isDismissable}
      isOpen
      onClose={onCancel}
      shouldCloseOnInteractOutside={() => false}
      primaryAction={{
        disabled: isSavingDisabled,
        onClick: onSave,
        text: 'Create variant',
        tooltipText: isSavingDisabled ? 'Select a workflow' : undefined,
        ...getDataUiActionAttribute(DataUiAction.Confirm),
      }}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <SingleSelect
        ariaLabel="Workflow"
        items={singleSelectItems}
        onSelectionChange={onSelectWorkflow}
        placeholder="Select a workflow"
        inputDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Workflows)}
      />
    </ModalDialog>
  );
};

NewVariantWorkflowSelectionDialog.displayName = 'NewVariantWorkflowSelectionDialog';
