import React from 'react';
import { IForwardedRefProps, forwardRef } from '../utils/forwardedRefProps.tsx';

interface IIgnoreByGrammarly extends IForwardedRefProps<HTMLSpanElement> {
  readonly text?: string;
  readonly className?: string;
}

const IgnoreByGrammarlyComponent: React.FC<React.PropsWithChildren<IIgnoreByGrammarly>> = ({
  children,
  className,
  text,
  forwardedRef,
}) => {
  if (!children && !text) {
    return null;
  }

  return (
    <span className={className} ref={forwardedRef} data-enable-grammarly="false">
      {children ?? text}
    </span>
  );
};

IgnoreByGrammarlyComponent.displayName = 'IgnoreByGrammarly';

export const IgnoreByGrammarly = forwardRef(IgnoreByGrammarlyComponent);
