import { ItemElementValidationResult } from '../../ItemElementValidationResult.type.ts';

export type ItemElementWarningResult = {
  readonly limitationMessages: ReadonlyArray<string>;
  readonly requiredMessage: string | null;
} & ItemElementValidationResult;

export const emptyItemElementWarningResult: ItemElementWarningResult = {
  limitationMessages: [],
  requiredMessage: null,
};

export type ItemElementWarnings = ReadonlyMap<UuidPath, ItemElementWarningResult>;
