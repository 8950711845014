import Immutable from 'immutable';
import React from 'react';
import { TreeList } from '../../../../../_shared/components/TreeList/TreeList.tsx';
import {
  ISitemap,
  ISitemapNode,
} from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { SitemapSelectorNode } from './SitemapSelectorNode.tsx';

export interface ISitemapSelectorDataProps {
  readonly collapsedNodes: Immutable.Set<Uuid>;
  readonly isDisabled?: boolean;
  readonly selectedLocations: Immutable.Set<Uuid>;
  readonly sitemap: ISitemap;
}

export interface ISitemapSelectorCallbacksProps {
  readonly onToggleNodeCollapsed: (toggledNodeId: Uuid) => void;
  readonly onToggleSitemapLocation: (selectedNodeId: Uuid) => void;
}

type SiteMapSelectorProps = ISitemapSelectorDataProps & ISitemapSelectorCallbacksProps;

export class SitemapSelector extends React.PureComponent<SiteMapSelectorProps> {
  static displayName = 'SitemapSelector';

  private readonly onToggleSitemapNodeLocation = (selectedNodeId: Uuid): void => {
    this.props.onToggleSitemapLocation(selectedNodeId);
  };

  render(): JSX.Element {
    const { sitemap, collapsedNodes, isDisabled, selectedLocations, onToggleNodeCollapsed } =
      this.props;

    const firstLevelNodes = sitemap.childIds
      .map((childId: Uuid) => sitemap.nodes.get(childId))
      .map((childNode: ISitemapNode) => (
        <SitemapSelectorNode
          key={childNode.id}
          allNodes={sitemap.nodes}
          collapsedNodes={collapsedNodes}
          currentNode={childNode}
          isDisabled={isDisabled}
          onNodeCollapseToggle={onToggleNodeCollapsed}
          onToggleSitemapLocation={this.onToggleSitemapNodeLocation}
          selectedLocations={selectedLocations}
        />
      ));

    return <TreeList>{firstLevelNodes}</TreeList>;
  }
}
