import { memoize } from '@kontent-ai/memoization';
import { ContentState, EditorState, SelectionState } from 'draft-js';
import {
  isContentComponent,
  isContentModule,
  isCustomBlockSleeve,
  isEmptyTextBlock,
  isImage,
  isTableCell,
} from '../../../utils/blocks/blockTypeUtils.ts';
import {
  BlockEdgeStatus,
  findBlockIndex,
  getBlockEdgeStatus,
} from '../../../utils/blocks/editorBlockUtils.ts';
import {
  SelectionEdgeStatus,
  getFullySelectedBlocks,
  getSelectionEdgeStatus,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';

export type HighlightedBlockKeys = {
  readonly blockKeys: ReadonlySet<string>;
  readonly imageBlockKeys: ReadonlySet<string>;
  readonly tableCellBlockKeys: ReadonlySet<string>;
  readonly textBlockKeys: ReadonlySet<string>;
  readonly reachingBlockEnd: string | null;
  readonly reachingBlockStart: string | null;
};

export type HighlightState = {
  readonly highlighted: HighlightedBlockKeys;
  readonly selection: SelectionState;
  readonly selectionEdgeStatus: SelectionEdgeStatus;
};

const NoHighlightedBlockKeys: HighlightedBlockKeys = {
  blockKeys: new Set(),
  imageBlockKeys: new Set(),
  tableCellBlockKeys: new Set(),
  textBlockKeys: new Set(),
  reachingBlockEnd: null,
  reachingBlockStart: null,
};

export function getInitialHighlightState(editorState: EditorState): HighlightState {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();

  return {
    highlighted: NoHighlightedBlockKeys,
    selection,
    selectionEdgeStatus: getSelectionEdgeStatus(content, selection),
  };
}

export function shouldUpdateHighlightedBlockKeys(
  lastSelection: SelectionState,
  selectionEdgeStatus: SelectionEdgeStatus,
  newSelection: SelectionState,
  newSelectionEdgeStatus: SelectionEdgeStatus,
): boolean {
  return (
    (lastSelection !== newSelection && selectionEdgeStatus !== newSelectionEdgeStatus) ||
    lastSelection.getStartKey() !== newSelection.getStartKey() ||
    lastSelection.getEndKey() !== newSelection.getEndKey() ||
    lastSelection.getHasFocus() !== newSelection.getHasFocus()
  );
}

const getMemoizedBlockKeys = memoize.maxN(
  (...blockKeys: ReadonlyArray<string>): ReadonlySet<string> => new Set(blockKeys),
  // One highlighted state uses up to 4 sets of highlighted blocks. Only one editor is highlighted (has focus) at a time
  // Just for the case of effective focus switching between two editors we allow 10 of such sets at a time
  10,
);

export function getHighlightedBlockKeys(
  content: ContentState,
  selection: SelectionState,
): HighlightedBlockKeys {
  if (selection.isCollapsed()) {
    return NoHighlightedBlockKeys;
  }

  const blocks = getBlocks(content);
  const fullySelectedBlocks = getFullySelectedBlocks(content, selection);

  const blockKeys = getMemoizedBlockKeys(
    ...fullySelectedBlocks
      .filter((block) => isTableCell(block) || isContentModule(block) || isContentComponent(block))
      .map((block) => block.getKey()),
  );
  const imageBlockKeys = getMemoizedBlockKeys(
    ...fullySelectedBlocks.filter(isImage).map((block) => block.getKey()),
  );
  const tableCellBlockKeys = getMemoizedBlockKeys(
    ...fullySelectedBlocks.filter(isTableCell).map((block) => block.getKey()),
  );
  const textBlockKeys = getMemoizedBlockKeys(
    ...fullySelectedBlocks
      .filter((block) => {
        if (isEmptyTextBlock(block)) {
          const blockIndex = findBlockIndex(blocks, block.getKey());
          return !isCustomBlockSleeve(blockIndex, blocks);
        }
        return false;
      })
      .map((block) => block.getKey()),
  );

  const startKey = selection.getStartKey();
  const startEdgeStatus = getBlockEdgeStatus(
    content.getBlockForKey(startKey),
    selection.getStartOffset(),
  );
  const isValidReachedBlockEnd =
    startEdgeStatus === BlockEdgeStatus.EndEdge ||
    isCustomBlockSleeve(findBlockIndex(blocks, startKey), blocks);
  const reachingBlockEnd = isValidReachedBlockEnd ? startKey : null;

  const endKey = selection.getEndKey();
  const endEdgeStatus = getBlockEdgeStatus(
    content.getBlockForKey(endKey),
    selection.getEndOffset(),
  );
  const isValidReachedBlockStart =
    endEdgeStatus === BlockEdgeStatus.StartEdge ||
    isCustomBlockSleeve(findBlockIndex(blocks, endKey), blocks);
  const reachingBlockStart = isValidReachedBlockStart ? endKey : null;

  return {
    blockKeys,
    imageBlockKeys,
    tableCellBlockKeys,
    textBlockKeys,
    reachingBlockEnd,
    reachingBlockStart,
  };
}
