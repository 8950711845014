import { Button } from '@kontent-ai/component-library/Button';
import React, { MouseEventHandler, useState } from 'react';
import { BarItemAction } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { EnterHotkeyDisabler } from '../../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import {
  ContentChangesWarningModal,
  ContentChangesWarningSubtitle,
} from '../../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ILanguageEditorPrimaryButtonProps {
  readonly isLanguageActive: boolean;
  readonly isLanguageLimitReached: boolean;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
  readonly onActivate: (() => void) | null;
  readonly onDismissLongProcessingChangesWarning: () => void;
  readonly isCodenameOrFallbackModified: boolean;
  readonly showLargeProjectSaveWarning: boolean;
}

export const LanguageEditorPrimaryButton: React.FC<ILanguageEditorPrimaryButtonProps> = ({
  isLanguageActive,
  isLanguageLimitReached,
  onSubmit,
  onActivate,
  onCancel,
  onDismissLongProcessingChangesWarning,
  isCodenameOrFallbackModified,
  showLargeProjectSaveWarning,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onSave = () => {
    if (showLargeProjectSaveWarning && isCodenameOrFallbackModified) {
      setShowModal(true);
    } else {
      onSubmit();
    }
  };

  const onActivateLanguage = () => {
    if (showLargeProjectSaveWarning) {
      setShowModal(true);
    } else {
      onActivate?.();
    }
  };

  const onDiscardChanges = () => {
    setShowModal(false);
    onCancel();
  };

  const onConfirm = () => {
    setShowModal(false);

    if (isLanguageActive) {
      onSubmit();
    } else {
      onActivate?.();
    }
  };

  const primaryAction: BarItemAction = isLanguageActive
    ? {
        text: 'Save',
        handler: onSave,
        dataUIActionName: DataUiAction.Save,
        shortcut: ShortcutSymbols.Enter,
      }
    : {
        text: 'Activate',
        handler: onActivateLanguage,
        isDisabled: isLanguageLimitReached,
        tooltipText: isLanguageLimitReached ? 'You have too many active languages.' : undefined,
        dataUIActionName: DataUiAction.Activate,
      };

  const primaryButtonOnClickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (primaryAction?.handler) {
      event.preventDefault();
      primaryAction.handler();
    }
  };

  return (
    <>
      <EnterHotkeyDisabler>
        <Button
          buttonStyle="primary"
          disabled={primaryAction.isDisabled}
          onClick={primaryButtonOnClickHandler}
          size="small"
          tooltipPlacement="bottom-end"
          tooltipShortcuts={primaryAction.isDisabled ? undefined : primaryAction.shortcut}
          tooltipText={primaryAction.tooltipText || primaryAction.text}
          type={primaryAction.handler ? 'button' : 'submit'}
          {...getDataUiActionAttribute(primaryAction.dataUIActionName)}
        >
          {primaryAction.text}
        </Button>
      </EnterHotkeyDisabler>
      <ContentChangesWarningModal
        onCancel={() => setShowModal(false)}
        onConfirm={onConfirm}
        onDiscardChanges={onDiscardChanges}
        onDismissWarning={onDismissLongProcessingChangesWarning}
        isOpen={showModal}
        warningSubtitle={ContentChangesWarningSubtitle.LanguageSettingsEditing}
      />
    </>
  );
};

LanguageEditorPrimaryButton.displayName = 'LanguageEditorPrimaryButton';
