import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

const appetizeIframeOrigin = 'https://appetize.io';
const heartbeatsToSendBeforeTimeout = 14; // will timeout in 14 * 15000(heartbeat interval) + 30000(default timeout) = 4 min
const heartbeatInterval = 15000;

type Props = {
  readonly sampleMobileAppUrl: string;
  readonly isCurrentUserEmployee: boolean;

  readonly onMobileAppOpened: (linkId: string, target: string) => void;
};

export const MobileSampleAppSection: React.FC<Props> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { onMobileAppOpened } = props;
  useEffect(() => {
    let heartbeatTimeoutId: number | null = null;

    const startHeartbeat = (beatsLeft: number) =>
      beatsLeft > 0
        ? window.setTimeout(() => {
            iframeRef.current?.contentWindow?.postMessage('heartbeat', appetizeIframeOrigin);
            heartbeatTimeoutId = startHeartbeat(beatsLeft - 1);
          }, heartbeatInterval)
        : null;

    const handleMessage = (e: MessageEvent): void => {
      if (e.origin !== appetizeIframeOrigin) {
        return;
      }

      switch (e.data) {
        case 'sessionRequested': {
          setIsExpanded(true);

          onMobileAppOpened('open-sample-mobile-app', props.sampleMobileAppUrl);

          if (props.isCurrentUserEmployee) {
            clearTimeout(heartbeatTimeoutId ?? undefined);
            heartbeatTimeoutId = startHeartbeat(heartbeatsToSendBeforeTimeout);
          }
          return;
        }
        case 'userInteractionReceived': {
          if (!props.isCurrentUserEmployee) {
            return;
          }
          clearTimeout(heartbeatTimeoutId ?? undefined);
          heartbeatTimeoutId = startHeartbeat(heartbeatsToSendBeforeTimeout);
          return;
        }
        default:
          return;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      clearTimeout(heartbeatTimeoutId ?? undefined);
    };
  }, [onMobileAppOpened, props.sampleMobileAppUrl, props.isCurrentUserEmployee]);

  return (
    <Stack spacing={Spacing.L}>
      <Stack spacing={Spacing.L}>
        <h4 className="quickstart__section-subtitle">Sample native mobile app</h4>
        <Stack spacing={Spacing.S}>
          <p>Want to see how the content of this project looks on an Android?</p>
          <p className="u-no-margin">
            Change some content in this Sample project, publish it, and see the changes right here.
          </p>
        </Stack>
      </Stack>
      <div className="sample-mobile-app">
        <iframe
          ref={iframeRef}
          title="Mobile sample application"
          className={classNames('sample-mobile-app__iframe', {
            'sample-mobile-app__iframe--expanded': isExpanded,
            'sample-mobile-app__iframe--collapsed': !isExpanded,
          })}
          src={props.sampleMobileAppUrl}
          frameBorder="0"
          scrolling="no"
          sandbox="allow-scripts allow-same-origin"
        />
      </div>
    </Stack>
  );
};

MobileSampleAppSection.displayName = 'MobileSampleAppSection';
