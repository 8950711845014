import Immutable from 'immutable';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { LinkIsInvalidMessage } from '../../constants/uiConstants.ts';
import { variantService } from '../../services/variantService.ts';
import { handleUnsuccessfulRedirect } from '../smartLinkActions.ts';
import { editItemVariant } from '../thunkSmartLinkActions.ts';
import { IItemElement } from './editItemVariant.ts';

type Params = {
  readonly projectId: Uuid;
  readonly variantCodename: string;
  readonly itemId: Uuid;
};

export const editItemVariantCodenameByItemId =
  ({ projectId, variantCodename, itemId }: Params, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const variantId = await variantService.getVariantIdFromCodename(
      variantCodename,
      projectId,
      abortSignal,
    );

    if (variantId) {
      await dispatch(
        editItemVariant(
          {
            projectId,
            variantId,
            path: Immutable.List<IItemElement>([{ itemId }]),
          },
          abortSignal,
        ),
      );
    } else {
      dispatch(handleUnsuccessfulRedirect(null, LinkIsInvalidMessage, null));
    }
  };
