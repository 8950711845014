import { Box } from '@kontent-ai/component-library/Box';
import { spacingMainLayoutLeft } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ProjectOverviewPage } from '../../../contentPlanning/projectOverview/containers/ProjectOverviewPage.tsx';

export const ProjectOverview: React.FC = () => {
  return (
    <Box flexGrow={1} overflowY="auto" paddingLeft={spacingMainLayoutLeft}>
      <ProjectOverviewPage />
    </Box>
  );
};
