import React from 'react';
import { StyledSkeleton } from './components/StyledSkeleton.tsx';
import { CustomSkeletonBorderRadius, SkeletonTypeToSkeletonBorderRadiusMap } from './types.ts';

type RectangleSkeletonProps = Readonly<{
  height: number | string;
  width: number | string;
  borderRadius?: CustomSkeletonBorderRadius;
  children?: never;
}>;

export const RectangleSkeleton = React.forwardRef<HTMLElement, RectangleSkeletonProps>(
  ({ width, height, borderRadius = SkeletonTypeToSkeletonBorderRadiusMap.rectangle }, ref) => (
    <StyledSkeleton $width={width} $height={height} borderRadius={borderRadius} ref={ref} />
  ),
);

RectangleSkeleton.displayName = 'RectangleSkeleton';
