import { Toggle } from '@kontent-ai/component-library/Toggle';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import Immutable from 'immutable';
import React, { useState, ReactNode } from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  DataTableCell,
  IDataTableCellProps,
} from '../../../../../_shared/components/DataTable/DataTableCell.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { HtmlSubscriptionManagementPageTitle } from '../../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAppName,
  DataUiCollection,
  DataUiSwitchAction,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { DeactivationConfirmationDialog } from '../../../../projects/components/projects/DeactivationConfirmationDialog.tsx';
import {
  CreatingProjectTooltipMessage,
  CurrentSubscriptionProjectLimitReachedTooltipMessage,
} from '../../../../projects/constants/UIConstants.ts';
import { StartTrialQuickTip } from '../../../../projects/containers/StartTrialQuickTip.ts';
import { TrialActivationModal } from '../../../../projects/containers/TrialActivationModal.tsx';
import { CreateProjectModal } from '../../../../projects/containers/projects/CreateProjectModal.tsx';
import { ListingProjectPlaceholder } from '../../../../projects/containers/projects/ListingProjectPlaceholder.tsx';
import {
  IProjectViewModel,
  IUserListItem,
} from '../../../../projects/selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../../../projects/types/projectUiTransitionState.ts';
import { SubscriptionTabName } from '../../constants/subscriptionTabName.ts';

const getStatus = (project: IProjectViewModel): string => {
  let statusText = 'Active';
  if (project.uiTransitionState === ProjectUiTransitionState.Activating) {
    statusText = 'Activating';
  } else if (project.uiTransitionState === ProjectUiTransitionState.Deactivating) {
    statusText = 'Archiving';
  } else if (project.inactive) {
    statusText = 'Archived';
  }

  return statusText;
};

type SubscriptionProjectsProps = {
  readonly allSubscriptionsExpired: boolean;
  readonly isCreatingProject: boolean;
  readonly isCurrentUserAdmin: boolean;
  readonly isEnvironmentsColumnVisible: boolean;
  readonly isProjectsLimitReached: boolean;
  readonly onShowCreateProjectModal: () => void;
  readonly onToggleProjectActiveState: (
    projectId: Uuid,
    activate: ProjectUiTransitionState,
  ) => void;
  readonly projectsLimitCount: number;
  readonly projectsList: ReadonlyArray<IProjectViewModel>;
  readonly projectsLoaded: boolean;
  readonly selectedSubscriptionIsActive: boolean;
};

export const SubscriptionProjects: React.FC<SubscriptionProjectsProps> = ({
  allSubscriptionsExpired,
  isCreatingProject,
  isCurrentUserAdmin,
  isEnvironmentsColumnVisible,
  isProjectsLimitReached,
  onShowCreateProjectModal,
  onToggleProjectActiveState,
  projectsLimitCount,
  projectsList,
  projectsLoaded,
  selectedSubscriptionIsActive,
}) => {
  const [showDeactivationDialogForProjectId, setShowDeactivationDialogForProjectId] =
    useState<Uuid | null>(null);

  const handleActivationButtonToggle = (project: IProjectViewModel): (() => void) => {
    const inactive = project.inactive;

    if (!inactive) {
      return () => setShowDeactivationDialogForProjectId(project.projectId);
    }
    if (inactive) {
      return () => activateProject(project);
    }
    return () => deactivateProject(project);
  };

  const renderActivationButton = (project: IProjectViewModel): JSX.Element => {
    const inactive = project.inactive;
    const activating = project.uiTransitionState === ProjectUiTransitionState.Activating;
    const deactivating = project.uiTransitionState === ProjectUiTransitionState.Deactivating;
    const actionInProgress = activating || deactivating;
    const isActivationDisabled = isProjectsLimitReached && inactive;

    const switchButton = (
      <Tooltip
        tooltipText={
          isActivationDisabled
            ? CurrentSubscriptionProjectLimitReachedTooltipMessage(projectsLimitCount)
            : undefined
        }
        placement="left"
      >
        <Toggle
          status={(inactive || deactivating) && !activating ? 'off' : 'on'}
          disabled={actionInProgress || isActivationDisabled}
          onChange={handleActivationButtonToggle(project)}
          labelText={getStatus(project)}
          {...getDataUiActionAttribute(DataUiSwitchAction.SwitchProjectStatus)}
        />
      </Tooltip>
    );

    if (project.projectId !== showDeactivationDialogForProjectId) {
      return switchButton;
    }

    return (
      <>
        {switchButton}
        <DeactivationConfirmationDialog
          deactivateProject={() => deactivateProject(project)}
          closeDeactivationConfirmationDialog={closeDeactivationConfirmationDialog}
          projectName={project.projectName}
          environmentName={project.environmentName}
        />
      </>
    );
  };

  const closeDeactivationConfirmationDialog = (): void =>
    setShowDeactivationDialogForProjectId(null);

  const deactivateProject = (project: IProjectViewModel): void => {
    closeDeactivationConfirmationDialog();
    onToggleProjectActiveState(project.projectId, ProjectUiTransitionState.Deactivating);
  };

  const activateProject = (project: IProjectViewModel): void => {
    closeDeactivationConfirmationDialog();
    onToggleProjectActiveState(project.projectId, ProjectUiTransitionState.Activating);
  };

  const getProjectStatus = (project: IProjectViewModel): JSX.Element | string => {
    return isProjectSettingUp(project) ? 'Creating project' : renderActivationButton(project);
  };

  const renderStatusTag = (project: IProjectViewModel): React.ReactNode => {
    if (project.planIsExpired) {
      return <Tag color={TagColor.Red}>Expired</Tag>;
    }
    if (project.planIsSuspended) {
      return <Tag color={TagColor.Pink}>Suspended</Tag>;
    }
    return null;
  };

  const renderProjectList = (): React.ReactNode => {
    if (!projectsList) {
      return;
    }

    return projectsList.map((project) => {
      const projectSettingUp = isProjectSettingUp(project);

      const managerLength = project.projectManagers.length;
      const joinedManagers = project.projectManagers.map(
        (manager: IUserListItem, index: number) => {
          const separator = managerLength === index + 1 ? null : ', ';
          const isMe = manager.isMe;
          return (
            <span
              key={index}
              className={classNames({
                'project-data-table-item__manager-you': isMe,
              })}
            >
              {manager.name}
              {manager.suffix && (
                <span className="project-data-table-item__manager-suffix">
                  &nbsp;{manager.suffix}
                </span>
              )}
              {separator && (
                <span className="project-data-table-item__manager-separator">{separator}</span>
              )}
            </span>
          );
        },
      );

      const disabled = project.inactive || projectSettingUp;

      return (
        <DataTableRow
          key={project.projectId}
          id={project.projectId}
          className="project-data-table-item"
          disabled={disabled}
          dataUiObjectName={project.projectName}
        >
          <ProjectTableCell project={project}>
            <span className="project-data-table-item__name" title={project.projectName}>
              {project.projectName}
            </span>
            {renderStatusTag(project)}
          </ProjectTableCell>
          <ProjectTableCell project={project} isExpandable>
            <span className="project-data-table-item__managers">{joinedManagers}</span>
          </ProjectTableCell>
          <ProjectTableCell project={project}>{project.projectLocationName}</ProjectTableCell>
          <ProjectTableCell project={project}>{project.activeLanguagesCount}</ProjectTableCell>
          {isCurrentUserAdmin && (
            <>
              <ProjectTableCell project={project}>
                {project.activeProjectContainerUsersCount}
              </ProjectTableCell>
              {isEnvironmentsColumnVisible && (
                <ProjectTableCell project={project}>{project.environmentsCount}</ProjectTableCell>
              )}
              <DataTableCell>{getProjectStatus(project)}</DataTableCell>
            </>
          )}
        </DataTableRow>
      );
    });
  };

  const createProjectListingColumns = (): Immutable.List<Column> => {
    const columns: Array<Column> = [
      {
        columnName: 'Name',
        className: 'data-table__column--7',
        orderBy: OrderByDirection.None,
      },
      {
        columnName: 'Project managers',
        orderBy: OrderByDirection.None,
      },
    ];

    columns.push({
      columnName: 'Data center',
      orderBy: OrderByDirection.None,
    });

    columns.push({
      columnName: 'Active languages',
      orderBy: OrderByDirection.None,
    });

    if (isCurrentUserAdmin) {
      columns.push({
        columnName: 'Active users',
        orderBy: OrderByDirection.None,
      });
      if (isEnvironmentsColumnVisible) {
        columns.push({
          columnName: 'Environments',
          orderBy: OrderByDirection.None,
        });
      }
      columns.push({
        columnName: 'Status',
        className: 'data-table__column--4',
        orderBy: OrderByDirection.None,
      });
    }

    return Immutable.List(columns);
  };

  const getDisabledCreateButtonTooltip = (): string => {
    if (isProjectsLimitReached) {
      return CurrentSubscriptionProjectLimitReachedTooltipMessage(projectsLimitCount);
    }
    if (isCreatingProject) {
      return CreatingProjectTooltipMessage;
    }
    return '';
  };

  const ProjectListingColumns = createProjectListingColumns();
  const showCreateProjectModal =
    isCurrentUserAdmin && !allSubscriptionsExpired && selectedSubscriptionIsActive
      ? onShowCreateProjectModal
      : undefined;

  const isCreateNewProjectDisabled = isCreatingProject || isProjectsLimitReached;

  if (!projectsLoaded) {
    return <Loader />;
  }

  return (
    <div className="canvas">
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Projects} />
      <div className="canvas__workspace">
        <section
          className="canvas__content"
          {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionProjects)}
        >
          <div
            className="canvas__content-pane canvas__content-pane--is-not-project-specific project-management-pane"
            data-hj-suppress=""
          >
            <StartTrialQuickTip />
            <TrialActivationModal />
            <div className="row">
              <div className="col-sm-24">
                <DataTable
                  onCreateNewItem={showCreateProjectModal}
                  dataUiCollectionName={DataUiCollection.ProjectsList}
                  isCreateNewDisabled={isCreateNewProjectDisabled}
                  createNewItemTooltip={getDisabledCreateButtonTooltip()}
                  title={
                    <ListingMessage
                      statusInfoMessage={{ text: 'Projects', style: StatusMessageStyle.Headline }}
                    />
                  }
                  header={<DataTableHeadRow columns={ProjectListingColumns} />}
                  infoMessage={
                    <ListingProjectPlaceholder onCreateButtonClick={showCreateProjectModal} />
                  }
                >
                  {renderProjectList()}
                </DataTable>
              </div>
            </div>
          </div>
        </section>
        <CreateProjectModal />
      </div>
    </div>
  );
};

type ProjectTableCellProps = IDataTableCellProps & {
  readonly children: ReactNode;
  readonly project: IProjectViewModel;
};

const ProjectTableCell = ({ project, ...props }: ProjectTableCellProps) => (
  <LinkDataTableCell
    {...props}
    linkPath={project.inactive || isProjectSettingUp(project) ? null : project.url}
    focusableRowLinkAriaLabel={`visit project — ${project.projectName}`}
  >
    {props.children}
  </LinkDataTableCell>
);

const isProjectSettingUp = (project: IProjectViewModel): boolean =>
  project.uiTransitionState === ProjectUiTransitionState.SettingUp;
