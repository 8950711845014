import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getEditedContentItemVariantId } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { IAssetItemElement } from '../../../../../models/contentItemElements/AssetItemElement.ts';
import { isAssetElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { assetElementValueChanged } from '../../../actions/thunkContentItemEditingActions.ts';
import { Asset as AssetComponent, AssetProps } from '../../../components/elements/asset/Asset.tsx';

type Props = Omit<AssetProps, 'elementData' | 'onChange'>;

export const Asset: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { typeElement } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(typeElement.elementId, state.contentApp.editedContentItemVariantElements),
  );

  const editedContentItemId = useSelector(getEditedContentItemVariantId);

  const onChange = useCallback(
    (itemElement: IAssetItemElement) => {
      if (editedContentItemId) {
        dispatch(assetElementValueChanged(editedContentItemId, itemElement, typeElement));
      }
    },
    [editedContentItemId, typeElement],
  );

  if (!isAssetElement(elementData)) {
    return null;
  }

  return <AssetComponent {...props} elementData={elementData} onChange={onChange} />;
};

Asset.displayName = 'AssetContainer';
