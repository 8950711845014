import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  Data_ListingContentItems_FullTextSearchTurnedOff,
  Data_ListingContentItems_FullTextSearchTurnedOn,
} from '../../../../../data/constants/dataActionTypes.ts';
import {
  Content_ContentItemListingFilter_Clear,
  Content_Filter_Clear,
} from '../../../../contentInventory/content/constants/contentActionTypes.ts';
import { LocalizedRouteLeft } from '../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { Calendar_Page_Left } from '../../../../contentPlanning/calendar/constants/calendarActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { Data_ContentStatusContentItems_Success } from '../../constants/contentStatusActionTypes.ts';

const initialState: string | null = null;

export function nextContinuationToken(
  state: string | null = initialState,
  action: Action,
): string | null {
  switch (action.type) {
    case Data_ContentStatusContentItems_Success:
      return action.payload.continuationToken || null;

    case Shared_CurrentProjectId_Updated:
    case LocalizedRouteLeft:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case Calendar_Page_Left:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
    case Content_Filter_Clear:
    case Content_ContentItemListingFilter_Clear:
    case Data_ListingContentItems_FullTextSearchTurnedOff:
    case Data_ListingContentItems_FullTextSearchTurnedOn:
      return initialState;

    default:
      return state;
  }
}
