import { Dispatch, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IDisplayWarningsOnPublishResult } from '../../../../../applications/itemEditor/features/ContentItemEditing/actions/thunks/displayWarningsOnPublishSelected.ts';
import { trackUserEventWithData } from '../../../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../models/events/ContentItemEditingEventData.type.ts';
import { OpenWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import { IOpenChangeWorkflowStepModal } from './openChangeWorkflowStepModal.ts';

interface IDeps {
  readonly displayWarningsOnPublishSelected: () => ThunkFunction<IDisplayWarningsOnPublishResult>;
  readonly openChangeWorkflowStepModal: IOpenChangeWorkflowStepModal;
}

export const createOpenPublishModalAction =
  (deps: IDeps) =>
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch: Dispatch) => {
    const result = dispatch(deps.displayWarningsOnPublishSelected());

    if (!result.hasElementsWithWarnings && !result.defaultVariantBlocksPublishing) {
      dispatch(deps.openChangeWorkflowStepModal(OpenWorkflowStepModalAction.Publish, actionOrigin));
      dispatch(
        trackUserEventWithData(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingEventTypes.PublishSelected,
          origin: actionOrigin,
        }),
      );
    }
  };
