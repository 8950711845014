import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  Capability,
  getUserCapability,
} from '../../../../../_shared/utils/permissions/capability.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IEnsureLoadedContentTypesAction } from '../../../../itemEditor/features/LoadedItems/actions/thunks/ensureLoadedContentTypes.ts';
import { isWebSpotlightEnabledAndInitializedForCurrentProject } from '../../../../webSpotlight/selectors/webSpotlightSelectors.ts';

type Deps = {
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
};

export const createInitializeSpacesAppAction =
  (deps: Deps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      webSpotlightApp: { configuration },
    } = state;

    const project = getCurrentProject(state);
    const canUserViewContent = getUserCapability(project).can(Capability.ViewContent);

    if (!canUserViewContent || !isWebSpotlightEnabledAndInitializedForCurrentProject(state)) {
      await dispatch(deps.loadSpaces(abortSignal));
      return;
    }

    assert(
      configuration?.rootTypeId,
      () => `${__filename}: Web spotlight configuration was not loaded yet.`,
    );

    await Promise.all([
      dispatch(deps.loadSpaces(abortSignal)),
      dispatch(deps.ensureLoadedContentTypes(new Set([configuration.rootTypeId]), abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
    ]);
  };
