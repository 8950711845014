import { combineReducers } from '@reduxjs/toolkit';
import { assetUiEditor } from './assetUiEditor.ts';
import { assetListingScrollGridState } from './internalReducers/assetListingScrollGridState.ts';
import { isAssetSelectorOpened } from './internalReducers/isAssetSelectorOpened.ts';
import { openedFolderId } from './internalReducers/openedFolderId.ts';
import { potentiallyUnsortedBy } from './internalReducers/potentiallyUnsortedBy.ts';
import { query } from './internalReducers/query.ts';
import { queryLoadingStatus } from './internalReducers/queryLoadingStatus.ts';
import { usage } from './internalReducers/usage.ts';
import { lastAction } from './lastAction.ts';

export const assetLibraryReducer = combineReducers({
  assetListingScrollGridState,
  assetUiEditor,
  isAssetSelectorOpened,
  lastAction,
  openedFolderId,
  potentiallyUnsortedBy,
  query,
  queryLoadingStatus,
  usage,
});
