import { Action } from '../../../../../@types/Action.type.ts';
import { Dispatch, GetState, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IMultipleChoiceOptionData } from '../../../../../_shared/models/MultipleChoiceOption.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IEditedMultipleChoiceElement } from '../../../contentTypes/reducers/IContentTypesAppStoreState.type.ts';
import { IEditedMultipleChoiceSnippetElement } from '../../../snippets/reducers/IContentTypeSnippetsAppStoreState.type.ts';
import { untitledMultipleChoiceOptionName } from '../../constants/uiConstants.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { isMultipleChoiceTypeElement } from '../../types/typeElementTypeGuards.ts';

type Deps = {
  readonly deleteOption: (optionId: Uuid, typeElementId: Uuid) => Action;
  readonly deselectOption: () => Action;
  readonly getEditedMultipleChoiceElement: (
    state: IStore,
  ) => IEditedMultipleChoiceElement | IEditedMultipleChoiceSnippetElement;
  readonly getTypeElements: (state: IStore) => ReadonlyArray<IBaseTypeElementData>;
  readonly updateOption: (newOption: IMultipleChoiceOptionData, typeElementId: Uuid) => Action;
};

export const createSaveEditedMultipleChoiceOptionAction =
  (deps: Deps) =>
  (): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState): void => {
    const state = getState();
    const { elementId, optionId, optionLabel } = deps.getEditedMultipleChoiceElement(state);

    if (!optionId) {
      return;
    }

    dispatch(deps.deselectOption());

    const typeElement = deps
      .getTypeElements(state)
      .find((element) => element.elementId === elementId);

    if (!isMultipleChoiceTypeElement(typeElement)) {
      return;
    }

    const option: IMultipleChoiceOptionData | undefined = typeElement.options[optionId];

    if (!option) {
      return;
    }

    dispatch(
      deps.updateOption(
        {
          ...option,
          label: optionLabel || untitledMultipleChoiceOptionName,
        },
        elementId,
      ),
    );
  };
