import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IScrollTableHeadRowProps {
  readonly collectionName: DataUiCollection;
  readonly children: React.ReactNode | React.ReactNodeArray;
}

export const ScrollTableHeadRow: React.FC<IScrollTableHeadRowProps> = ({
  collectionName,
  children,
}) => (
  <div className="scroll-table__head" {...getDataUiCollectionAttribute(collectionName)} role="row">
    {children}
  </div>
);

ScrollTableHeadRow.displayName = 'ScrollTableHeadRow';
