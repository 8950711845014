import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { listingFilterChanged } from '../contentItemInventoryActions.ts';

interface IDeps {
  readonly loadListingContentItems: () => ThunkPromise;
}

export const createFilterContentItemsInDialogAction =
  (deps: IDeps) => (): ThunkPromise => async (dispatch) => {
    dispatch(listingFilterChanged());

    await dispatch(deps.loadListingContentItems());
  };
