import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { IAssignedItemViewModel } from '../../../../yourContent/components/AssignedToYou/ContentAssignedToYou.tsx';
import { NameWithLanguage } from '../../../../yourContent/components/NameWithLanguage.tsx';

type ItemsAssignedToYouWidgetDetailRowProps = {
  readonly onItemClick?: () => void;
  readonly item: IAssignedItemViewModel;
  readonly rowFocusLinkAriaLabel: string;
};

export const ItemsAssignedToYouWidgetDetailRow: React.FC<
  ItemsAssignedToYouWidgetDetailRowProps
> = ({ onItemClick, item, rowFocusLinkAriaLabel }) => {
  const { pathname } = useLocation();

  const linkProps = {
    linkPath: getContentItemPath(pathname, item.id.itemId, item.id.variantId),
    onClick: onItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <DataTableRow
      id={stringifyContentItemId(item.id)}
      key={stringifyContentItemId(item.id)}
      dataUiObjectName={item.name}
    >
      <LinkDataTableCell {...linkProps} focusableRowLinkAriaLabel={rowFocusLinkAriaLabel}>
        <NameWithLanguage
          name={item.name}
          language={item.language}
          variantCompletionStatus={item.variantCompletionStatus}
        />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps} title={item.contentType}>
        {item.contentType}
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <WorkflowStatusTagForVariant
          publishingState={item.publishingState}
          workflowStatus={item.workflowStatus}
          scheduledToPublishAt={item.scheduledToPublishAt}
          scheduledToUnpublishAt={item.scheduledToUnpublishAt}
        />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <TableCellDueDate dueDate={item.due} />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <LastUpdatedAt time={item.lastUpdatedAt} />
      </LinkDataTableCell>
    </DataTableRow>
  );
};
