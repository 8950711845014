import React from 'react';
import { EnvironmentRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
  getCurrentProjectSubscription,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentModelsTabs as ContentModelsTabsComponent } from '../../shared/components/ContentModelsTabs.tsx';
import { getContentModelsTabsStructure } from '../../shared/utils/contentModelsTabsStructure.ts';
import { isSitemapEnabled } from '../../sitemap/utils/sitemapUtils.ts';

export const ContentModelsTabs: React.FC = () => {
  const projectId = useSelector(getCurrentProjectId);
  const capability = useSelector(compose(getUserCapability, getCurrentProject));
  const contentModelsTabs = useSelector(
    compose(getContentModelsTabsStructure, isSitemapEnabled, getCurrentProjectSubscription),
  );

  if (!projectId) {
    return null;
  }

  const tabs = contentModelsTabs
    .filter((tab) => capability.can(tab.requiredCapability))
    .map(({ linkRoute, requiredCapability, tabName, tabText, ...rest }) => {
      const pathname = buildPath<EnvironmentRouteParams>(linkRoute, { projectId });
      return {
        id: pathname,
        label: tabText,
        pathname,
        ...rest,
      };
    });

  return <ContentModelsTabsComponent contentModelsTabs={tabs} />;
};

ContentModelsTabs.displayName = 'ContentModelsTabs';
