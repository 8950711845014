import { Box } from '@kontent-ai/component-library/Box';
import {
  Spacing,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
} from '@kontent-ai/component-library/tokens';
import React from 'react';

export const MissionControlHeaderLayout: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => (
  <Box
    paddingLeft={spacingMainLayoutLeft}
    paddingRight={spacingMainLayoutRight}
    paddingBottom={Spacing.XXL}
  >
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {children}
    </Box>
  </Box>
);
