import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadCollections,
  loadContentTypes,
  loadSpaces,
} from '../../../../data/actions/thunkDataActions.ts';
import { createInitCollectionsListing } from './thunkActions/initCollectionsListing.ts';
import { createSaveEditedCollections } from './thunkActions/saveEditedCollections.ts';

const { collectionsRepository } = repositoryCollection;

export const saveEditedCollections = createSaveEditedCollections({
  collectionsRepository,
  trackUserEvent: trackUserEventWithData,
});

export const initCollectionsListing = createInitCollectionsListing({
  loadCollections,
  loadContentTypes,
  loadSpaces,
});
