import { notNullNorUndefined } from '@kontent-ai/utils';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  Capability,
  ICapability,
  getUserCapability,
} from '../../../_shared/utils/permissions/capability.ts';
import {
  getCurrentProject,
  getProjectInfo,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isEnvironmentGeneralSettingsTabVisible } from '../selectors/environmentSettingsMenuCustomVisibilities.ts';
import {
  EnvironmentSettingsMenuStructureGroupItem,
  EnvironmentSettingsMenuStructureGroupItemWithCapability,
  environmentSettingsMenuStructure,
} from './environmentSettingsMenuStructure.tsx';

const isItemWithCapabilityVisible = (
  item: EnvironmentSettingsMenuStructureGroupItemWithCapability,
  capability: ICapability,
): boolean => capability.canOneOf([item.requiredCapability]);

const isItemVisible = (
  item: EnvironmentSettingsMenuStructureGroupItem,
  capability: ICapability,
  state: IStore,
): boolean =>
  (!isItemWithCapabilities(item) || isItemWithCapabilityVisible(item, capability)) &&
  (item.isFeatureEnabled?.(state) ?? true);

export const getFilteredOutMenuStructure = (
  state: IStore,
  menuStructure: ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem>,
): ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem> => {
  const currentEnvironment = getCurrentProject(state);
  const environmentCapability = getUserCapability(currentEnvironment);

  return menuStructure.filter((item) => isItemVisible(item, environmentCapability, state));
};

const isItemWithCapabilities = (
  item: EnvironmentSettingsMenuStructureGroupItem,
): item is EnvironmentSettingsMenuStructureGroupItemWithCapability => 'requiredCapability' in item;

export const environmentSettingsRequiredCapabilities: () => ReadonlyArray<Capability> = () =>
  environmentSettingsMenuStructure()
    .filter(isItemWithCapabilities)
    .map((item) => item.requiredCapability)
    .filter(notNullNorUndefined);

export const canAccessEnvironmentSettings = (state: IStore, environmentId: Uuid): boolean => {
  const projectInfo = getProjectInfo(state, environmentId);
  return (
    getUserCapability(projectInfo).canOneOf(environmentSettingsRequiredCapabilities()) ||
    isEnvironmentGeneralSettingsTabVisible(state, projectInfo.projectId)
  );
};
