import React, { useEffect, useState } from 'react';

interface IRichTextItemElementContextState {
  readonly rootRichTextElementId: Uuid | null;
}

const defaultContext: IRichTextItemElementContextState = {
  rootRichTextElementId: null,
};

export const RichTextItemElementContext =
  React.createContext<IRichTextItemElementContextState>(defaultContext);

interface IRichTextItemElementContextProviderProps {
  readonly children: React.ReactNode | React.ReactNodeArray;
  readonly rootRichTextElementId: Uuid | null;
}

export const RichTextItemElementContextProvider: React.FC<
  IRichTextItemElementContextProviderProps
> = ({ rootRichTextElementId, children }) => {
  const [value, setValue] = useState<IRichTextItemElementContextState>({ rootRichTextElementId });
  useEffect(() => setValue({ rootRichTextElementId }), [rootRichTextElementId]);

  return (
    <RichTextItemElementContext.Provider value={value}>
      {children}
    </RichTextItemElementContext.Provider>
  );
};

RichTextItemElementContextProvider.displayName = 'RichTextItemElementContextProvider';
