import styled, { css } from 'styled-components';
import {
  colorBackgroundDisabled,
  colorIconDefaultInverse,
  colorPrimary,
  colorPrimaryDisabled,
  colorPrimaryHover,
  colorPrimaryHoverInverse,
} from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing, gridUnit } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { ToggleStatus } from '../types.ts';
import { StyledToggleLabel } from './StyledToggleLabel.tsx';

export type StyledToggleProps = Readonly<{
  status: ToggleStatus;
  disabled?: boolean;
  isFocused?: boolean;
}>;

const getToggleBackgroundStyles = ({
  status,
  disabled,
}: Pick<StyledToggleProps, 'status' | 'disabled'>) => {
  if (status === 'off') {
    return css`
      background-color: ${disabled ? colorBackgroundDisabled : BaseColor.Gray70};
    `;
  }

  if (disabled) {
    return css`
      background-color: ${colorPrimaryDisabled};
      ${StyledToggleLabel}:hover && {
        background-color: ${colorPrimaryDisabled};
      }
    `;
  }

  return css`
    background-color: ${colorPrimary};
    ${StyledToggleLabel}:hover && {
      background-color: ${colorPrimaryHover};
    }
  `;
};

const getToggleTriggerBallColor = ({
  status,
  disabled,
}: Pick<StyledToggleProps, 'status' | 'disabled'>) => css`
  &:before {
    background-color: ${status === 'off' && disabled ? BaseColor.Gray60 : colorIconDefaultInverse};
  }

  ${
    !disabled &&
    css`
    ${StyledToggleLabel}:hover &&:before {
      background-color: ${status === 'on' ? colorPrimaryHoverInverse : BaseColor.Gray10};
    }
  `
  }
`;

const getTriggerSizeStyles = ({ status }: Pick<StyledToggleProps, 'status'>) => css`
  width: ${px(status === 'on' ? 2 * gridUnit : 1.75 * gridUnit)};
  height: ${px(status === 'on' ? 2 * gridUnit : 1.75 * gridUnit)};
`;

const getToggleTriggerPositionStyles = ({ status }: Pick<StyledToggleProps, 'status'>) => css`
  transform: translateX(${px(status === 'on' ? -2 : -2.25 * gridUnit)});
`;

export const StyledToggle = styled.span<StyledToggleProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: ${px(Spacing.S)};
  vertical-align: middle;
  border-radius: ${px(BorderRadius.Pill)};
  transition: background-color ${transition250};
  width: ${px(4.5 * gridUnit)};
  height: ${px(2.5 * gridUnit)};

  ${getToggleBackgroundStyles};
  ${getToggleTriggerBallColor};

  &:before {
    position: absolute;
    border-radius: ${px(BorderRadius.Pill)};
    content: "";
    transition: background-color ${transition250};
    transition-property: transform, height, width;
    right: 0;
    ${getToggleTriggerPositionStyles};
    ${getTriggerSizeStyles};
  }

  ${({ isFocused }) => isFocused && css`${shadowFocusStyles}`};
`;
