import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { DataUiElement } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ItemElement } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/ItemElement.tsx';
import { TypeElement } from '../../../../content/models/contentTypeElements/TypeElement.type.ts';

interface IAssetElementWrapperProps {
  readonly className?: string;
  readonly dataUiElement?: DataUiElement;
  readonly disabled: boolean;
  readonly title?: string;
  readonly titleId?: string;
  readonly typeElement: TypeElement;
}

export const AssetElementWrapper: React.FC<React.PropsWithChildren<IAssetElementWrapperProps>> = ({
  children,
  className,
  dataUiElement,
  disabled,
  title,
  titleId,
  typeElement,
}) => {
  return (
    <ItemElement
      areContentItemElementsHighlighting={false}
      canUpdateContent={!disabled}
      className={className}
      commentThreadId={null}
      customDataUiElement={dataUiElement}
      disabled={disabled}
      elementSupportsComments={false}
      focusItemElement={noOperation}
      hasFocusedComment={false}
      hideErrorMessages={disabled}
      isFocused={false}
      isHighlighted={false}
      isLocked={false}
      lockedByUser={null}
      showElementStatus={false}
      title={title}
      titleId={titleId}
      typeElement={typeElement}
      validationPassed
      hideNonLocalizableElementInfo
    >
      {children}
    </ItemElement>
  );
};

AssetElementWrapper.displayName = 'AssetElementWrapper';
