import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentType_Listing_RouteEntered,
  ContentType_Listing_SelectedTypesUpdated,
} from '../../constants/contentTypesActionTypes.ts';

const defaultState = Immutable.Set<Uuid>();

export function selectedContentTypes(state = defaultState, action: Action): Immutable.Set<Uuid> {
  switch (action.type) {
    case ContentType_Listing_SelectedTypesUpdated:
      return action.payload.selectedContentTypeIds;

    case ContentType_Listing_RouteEntered:
      return defaultState;

    default:
      return state;
  }
}
