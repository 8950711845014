import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ITextTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { setRichTextClipboard } from '../../../../richText/actions/thunkRichTextActions.ts';
import { SimpleMultilineTextDiff } from '../../../../richText/editors/simpleText/SimpleMultilineTextDiff.tsx';
import { EmptyMetadata } from '../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { EmptyContentComponents } from '../../../models/contentItem/ContentComponent.ts';
import { ITextItemElement } from '../../../models/contentItemElements/TextItemElement.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../Revisions/hooks/useRenderPlaceholderText.ts';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { NestableExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedTextElementProps = NestableExpandedItemElementDataProps<
  ITextTypeElement,
  ITextItemElement
>;

export const ExpandedTextElement: React.FC<ExpandedTextElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => {
  const value = elementData._editorState;
  const originalValue = originalElementData?._editorState.getCurrentContent();
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.StringElement,
    originalElementData,
    typeElement,
  );

  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      typeElement={typeElement}
    >
      <SimpleMultilineTextDiff
        className="rte--in-content-item-element"
        editorState={value}
        originalContent={originalValue}
        placeholder={placeholderText ?? undefined}
        setRichTextClipboard={onSetRichTextClipboard}
        spellCheck
      />
    </ExpandedItemElement>
  );
};

ExpandedTextElement.displayName = 'ExpandedTextElement';
