import { Action } from '../../../../@types/Action.type.ts';
import {
  SmartLink_HandleSuccessfulRedirect,
  SmartLink_HandleSuccessfulRedirectToContentItem,
  SmartLink_HandleUnsuccessfulRedirect,
} from '../../constants/smartLinkActionTypes.ts';

export const linkMessage = (state: string = 'Loading', action: Action): string => {
  switch (action.type) {
    case SmartLink_HandleUnsuccessfulRedirect:
      return action.payload.linkMessage || state;

    case SmartLink_HandleSuccessfulRedirectToContentItem:
    case SmartLink_HandleSuccessfulRedirect:
      return 'Redirecting';

    default:
      return state;
  }
};
