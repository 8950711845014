import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { isUrlSlugTypeElement } from '../../../contentModels/shared/types/typeElementTypeGuards.ts';
import { IRichTextItemElement } from '../../../itemEditor/models/contentItemElements/RichTextItemElement.ts';
import { isRichTextElement } from '../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getAllContentLinkIds } from '../../../richText/plugins/links/api/editorLinkUtils.ts';

interface IDeps {
  readonly loadDefaultListingItems: (
    itemIds: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ) => ThunkPromise<ReadonlyArray<IListingContentItem> | null>;
}

const getRichTextContentLinkIdsIncludingNested = (
  rootRichTextElement: IRichTextItemElement,
): ReadonlySet<Uuid> => {
  const nestedRichTexts = Array.from(rootRichTextElement.contentComponents.values()).flatMap(
    (component) => component.elements.filter(isRichTextElement),
  );

  return new Set(
    [rootRichTextElement, ...nestedRichTexts].flatMap((element) =>
      getAllContentLinkIds(element._editorState.getCurrentContent()),
    ),
  );
};

export const ensureContentLinksDefaultVariantsLoadedActionCreator =
  (deps: IDeps) =>
  (rootRichTextElement: IRichTextItemElement): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: {
        listingContentItems: { byId: listingItemsById, defaultById: defaultListingItemsById },
        contentTypes: { byId: contentTypesById },
      },
      sharedApp: {
        selectedLanguage: { id: selectedLanguageId },
      },
    } = getState();

    if (selectedLanguageId === DefaultVariantId) {
      return; // default listing items are only used in non-default languages
    }

    const allLinkIds = getRichTextContentLinkIdsIncludingNested(rootRichTextElement);

    const itemsWithNonLocalizableSlug = Array.from(allLinkIds).filter((itemId) => {
      const item = listingItemsById.get(itemId);
      if (!item) {
        return false;
      }

      const type = contentTypesById.get(item.item.typeId);
      if (!type) {
        return false;
      }

      return type.typeElements.some((element) => {
        return isUrlSlugTypeElement(element) && element.isNonLocalizable;
      });
    });

    if (!itemsWithNonLocalizableSlug.length) {
      return; // we only need default variants to get non-localizable URL slug value
    }

    const alreadyLoadedDefaultIds = Immutable.Set(defaultListingItemsById.keys());
    const defaultVariantIdsToLoad = itemsWithNonLocalizableSlug.filter(
      (id) => !alreadyLoadedDefaultIds.has(id),
    );

    if (defaultVariantIdsToLoad.length > 0) {
      await dispatch(deps.loadDefaultListingItems(defaultVariantIdsToLoad));
    }
  };
