import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { ModalDialog } from '../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type WebhookDisableConfirmationModalProps = {
  readonly webhookName: string;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const WebhookDisableConfirmationModal: FC<WebhookDisableConfirmationModalProps> = ({
  webhookName,
  onCancel,
  onConfirm,
}) => (
  <ModalDialog
    headerContent="Disable webhook"
    bodyContent={
      <div>
        <Warning>
          <p>
            When you disable this webhook, two things will happen immediately:
            <br />
            <ul>
              <li>The webhook stops sending notifications to your app.</li>
              <li>Any unsent notifications will be deleted.</li>
            </ul>
          </p>
          <p>
            Do you want to disable the <b>{webhookName}</b> webhook?
          </p>
        </Warning>
      </div>
    }
    onClose={onCancel}
    footerContentRight={
      <div {...getDataUiCollectionAttribute(DataUiCollection.WarningMessageActions)}>
        <Button
          buttonStyle="secondary"
          onClick={onCancel}
          {...getDataUiActionAttribute(DataUiAction.Cancel)}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primary"
          onClick={onConfirm}
          {...getDataUiActionAttribute(DataUiAction.Disable)}
        >
          Disable
        </Button>
      </div>
    }
  />
);

WebhookDisableConfirmationModal.displayName = 'WebhookDisableConfirmationModal';
