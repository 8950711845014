import { connect } from 'react-redux';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsCanceled,
  getAdministratedSubscriptions,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { logoutAction } from '../../actions/thunks/logout.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import {
  IUserMenuDataProps,
  IUserMenuDispatchProps,
  UserMenu as UserMenuComponent,
} from '../../components/MainMenu/UserMenu.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { IStore } from '../../stores/IStore.type.ts';

function mapStateToProps(state: IStore): IUserMenuDataProps {
  const {
    data: { user, subscriptions, projects },
  } = state;
  const projectSubscriptions = getAdministratedSubscriptions(
    projects.byId.map((project: IProjectDetails) => project.subscriptionId).toSet(),
    subscriptions.byId,
  );
  const allSubscriptionsCanceled = areAllSubscriptionsCanceled(projectSubscriptions);
  return {
    showSubscriptionLink:
      getAdministratedSubscriptions(subscriptions.administratedIds, subscriptions.byId).length >
        0 ||
      (allSubscriptionsCanceled && user.info.hadTrial),
    currentUser: user.info,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IUserMenuDispatchProps {
  return {
    trackMenuOpened: () => dispatch(trackUserEvent(TrackedEvent.UserMenuOpened)),
    onSignOutClick: () => dispatch(logoutAction),
  };
}

export const UserMenu = connect(mapStateToProps, mapDispatchToProps)(UserMenuComponent);
