import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  ITaxonomyPatchOperation,
  TaxonomyPatchOperationType,
} from '../../../../../repositories/utils/createTaxonomyPatchDocument.ts';
import { AssetLibrary_Folders_Updated } from '../../constants/assetLibraryActionTypes.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly patch: (operation: ITaxonomyPatchOperation) => Promise<ITaxonomyGroupServerModel>;
  };
}

const assetFolderUpdated = (taxonomyGroup: ITaxonomyGroupServerModel) =>
  ({
    type: AssetLibrary_Folders_Updated,
    payload: {
      taxonomyGroup,
    },
  }) as const;

export type UpdateAssetFolderActionsType = ReturnType<typeof assetFolderUpdated>;

export const createUpdateAssetFolderAction =
  (deps: IDeps) => (assetFolder: ITaxonomyTerm) => async (dispatch: Dispatch) => {
    const operation: ITaxonomyPatchOperation = {
      operation: TaxonomyPatchOperationType.Replace,
      reference: assetFolder.id,
      value: {
        name: assetFolder.name,
      },
    };

    const taxonomyGroup = await deps.assetFolderRepository.patch(operation);

    dispatch(assetFolderUpdated(taxonomyGroup));
  };
