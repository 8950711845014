import React from 'react';
import { INote } from '../../../../../applications/itemEditor/models/contentItem/Assignment.ts';
import { IFriendlyWarning } from '../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { DateTime } from '../../../../models/DateTime.ts';
import { ContributorsSection } from '../../containers/Elements/ContributorsSection.tsx';
import { DueDateSection } from '../Elements/DueDateSection.tsx';
import { NoteSection } from '../Elements/NoteSection.tsx';
import { UpdatingWorkflowFriendlyWarningSection } from '../Elements/UpdatingWorkflowFriendlyWarningSection.tsx';

export interface IAssignmentSectionProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly dueDate: DateTimeStamp;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
  readonly note: INote | null;
  readonly onDueDateChange: (dateTime: DateTime) => void;
  readonly onNoteChanged: (newNote: string) => void;
  readonly workflowStep: IWorkflowStep;
}

export const AssignmentSection: React.FC<IAssignmentSectionProps> = (
  props: IAssignmentSectionProps,
) => (
  <>
    <UpdatingWorkflowFriendlyWarningSection
      activeUsersNames={props.activeUsersNames}
      friendlyWarningReasons={props.friendlyWarningReasons}
      selectedWorkflowStep={props.workflowStep}
    />
    <DueDateSection dueDate={props.dueDate} onDueDateChanged={props.onDueDateChange} />
    <ContributorsSection />
    <NoteSection note={props.note} onNoteChanged={props.onNoteChanged} />
  </>
);

AssignmentSection.displayName = 'AssignmentSection';
