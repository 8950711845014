import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { CalendarView } from '../models/CalendarView.ts';

export interface ICalendarEmptyStateOwnProps {
  readonly type: CalendarView;
}

export interface ICalendarEmptyStateDispatchProps {
  readonly onClearFilter: () => void;
}

type ICalendarEmptyStateProps = ICalendarEmptyStateOwnProps & ICalendarEmptyStateDispatchProps;

export const CalendarEmptyState: React.FC<ICalendarEmptyStateProps> = ({ onClearFilter, type }) => {
  switch (type) {
    case CalendarView.EmptyInventory: {
      return (
        <EmptyState>
          <EmptyState.Title>No content planned this month.</EmptyState.Title>
          <EmptyState.Content>
            <p>
              Need help? Read about{' '}
              <OutwardLink href={documentationLinks.dueDates}>setting due dates</OutwardLink>.
            </p>
          </EmptyState.Content>
        </EmptyState>
      );
    }
    case CalendarView.EmptySearch: {
      return (
        <EmptyState>
          <EmptyState.Title>We couldn’t find any matches.</EmptyState.Title>
          <EmptyState.Content>Try using different filters.</EmptyState.Content>
          <EmptyState.Footer>
            <Button buttonStyle="primary" onClick={onClearFilter}>
              Clear the filters
            </Button>
          </EmptyState.Footer>
        </EmptyState>
      );
    }
    default:
      return null;
  }
};

CalendarEmptyState.displayName = 'CalendarEmptyState';
