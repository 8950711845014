import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRuleWithScope } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import {
  canRuleGroupAdded,
  canRuleGroupRemoved,
  canRuleTypeAdded,
  canRuleTypeRemoved,
} from '../../../../actions/rolesActions.ts';
import { contentCanRuleCapabilityOptions } from '../../../../constants/capabilityActionOptions.ts';
import { CapabilityScope } from '../../../../models/CapabilityScope.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import {
  contentScopeOptions,
  getOptionName,
  getScopeName,
} from '../../../../utils/capabilityRulePickerUtils.ts';
import {
  AllTypesPlaceholder,
  CapabilityTypesAndGroupsSelector,
} from './CapabilityTypesAndGroupsSelector.tsx';
import { RuleDeleteButton } from './RuleDeleteButton.tsx';

const ActionDropDown = getDropDown<ReadonlyArray<Capability>>();
const ScopeDropDown = getDropDown<CapabilityScope>();

interface ICapabilityRulePickerProps {
  readonly isDisabled: boolean;
  readonly index: number;
  readonly onRemove: () => void;
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly capabilityRule: IRuleWithScope;
  readonly onChange: (newRule: IRuleWithScope) => void;
  readonly status: RoleEditorStatus;
}

export const ContentCapabilityRulePicker: React.FC<ICapabilityRulePickerProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <div
      className="capability-rules__rule capability-rules__rule--expanded"
      {...getDataUiElementAttribute(DataUiElement.CapabilityRule)}
    >
      <div
        className={classnames('capability-rules__rule-part', {
          'capability-rules__rule-part--is-disabled': props.isDisabled,
        })}
      >
        <ActionDropDown
          dataUiCollectionName={DataUiCollection.RuleCapabilities}
          getOptionId={(o) => o.join(',')}
          onSelect={(action) =>
            props.onChange({
              ...props.capabilityRule,
              capabilities: action,
            })
          }
          options={contentCanRuleCapabilityOptions}
          renderOptionName={getOptionName}
          selectedOption={props.capabilityRule.capabilities}
          className="combo-box"
          disabled={props.isDisabled}
        />
      </div>
      <div
        className={classnames('capability-rules__rule-part', {
          'capability-rules__rule-part--is-disabled': props.isDisabled,
        })}
      >
        <ScopeDropDown
          disabled={props.isDisabled}
          dataUiCollectionName={DataUiCollection.RuleCapabilityScopes}
          getOptionId={(i) => i}
          onSelect={(scope) =>
            props.onChange({
              ...props.capabilityRule,
              scope,
            })
          }
          options={contentScopeOptions}
          renderOptionName={getScopeName}
          selectedOption={props.capabilityRule.scope}
          className="combo-box"
        />
      </div>
      <div
        className={classnames('capability-rules__rule-part', {
          'capability-rules__rule-part--is-disabled': props.isDisabled,
        })}
      >
        <CapabilityTypesAndGroupsSelector
          allTypes={props.allTypes}
          capabilityRule={props.capabilityRule}
          isDisabled={props.isDisabled}
          placeholder={AllTypesPlaceholder.label}
          placeholderType="tag"
          onTypeAdded={({ typeId }) =>
            dispatch(canRuleTypeAdded(props.allTypes, props.index, typeId))
          }
          onTypeRemoved={({ typeId }) =>
            dispatch(canRuleTypeRemoved(props.allTypes, props.index, typeId))
          }
          onGroupAdded={({ typeId, groupId }) =>
            dispatch(canRuleGroupAdded(props.allTypes, props.index, typeId, groupId))
          }
          onGroupRemoved={({ typeId, groupId }) =>
            dispatch(canRuleGroupRemoved(props.allTypes, props.index, typeId, groupId))
          }
        />
      </div>
      {!props.isDisabled && (
        <RuleDeleteButton disabled={props.isDisabled} onRemove={props.onRemove} />
      )}
    </div>
  );
};

ContentCapabilityRulePicker.displayName = 'ContentCapabilityRulePicker';
