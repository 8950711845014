import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { isProjectTrial } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { selectPreviewLinks } from './selectPreviewLinks.ts';

export const canShowNotSetupPreview = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  const isPreviewNotSetUp = previewLinks.every(({ url, error }) => !url && !error);

  return isPreviewNotSetUp && isProjectTrial(state, state.sharedApp.currentProjectId);
};
