import {
  BaseColor,
  Spacing,
  colorBackgroundHighlighted,
  colorBackgroundLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

type CellProps = {
  readonly isLoaded: boolean;
  readonly isOutsideMonthRange: boolean;
  readonly isToday: boolean;
};

export const StyledCalendarCell = styled.div<CellProps>`
  height: 100%;
  padding: ${px(Spacing.S)};
  background-color: ${BaseColor.White};

  ${({ isOutsideMonthRange }) =>
    isOutsideMonthRange &&
    css`
    background-color: ${colorBackgroundLowEmphasis};
  `};
  
  ${({ isToday }) =>
    isToday &&
    css`
    background-color: ${colorBackgroundHighlighted};
  `};

  ${({ isLoaded }) =>
    !isLoaded &&
    css`
    background-color: transparent;
  `};
`;
