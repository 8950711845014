import { UnreachableCaseException } from '@kontent-ai/errors';
import { useAttachRef } from '@kontent-ai/hooks';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import ClipboardJS from 'clipboard';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import { ShortcutsConfig, useHotkeys } from '../../../hooks/useHotkeys.tsx';
import { copyToClipboardDelayMs } from '../../../tokens/decision/transitions.ts';
import {
  DataUiAttributes,
  getDataAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { IBaseInputProps } from '../../Input/components/BaseInputComponent.tsx';
import { InputState } from '../../Input/inputStateEnum.ts';
import { InputType } from '../../Input/inputType.ts';
import { SimpleStatusError, SimpleStatusSuccess } from '../../SimpleStatus/SimpleStatus.tsx';
import { SimpleStatusAlign } from '../../SimpleStatus/types/simpleStatusAlign.ts';
import { ClipboardButtonInjectedProps } from './ClipboardButton.tsx';

enum ClipboardStatus {
  Default = 'default',
  Success = 'success',
  Error = 'error',
}

const successSuffix = (
  <SimpleStatusSuccess
    icon={Icons.CbCheckPreview}
    iconAlign={SimpleStatusAlign.Left}
    label="Copied"
    disabledFocus
  />
);

const errorSuffix = (
  <SimpleStatusError
    icon={Icons.ExclamationTriangleInverted}
    iconAlign={SimpleStatusAlign.Left}
    label="Error"
    disabledFocus
    tooltipText="Copying isn’t supported in your browser."
  />
);

const getStatusComponent = (clipboardStatus: ClipboardStatus): React.ReactNode => {
  switch (clipboardStatus) {
    case ClipboardStatus.Success:
      return successSuffix;
    case ClipboardStatus.Error:
      return errorSuffix;
    case ClipboardStatus.Default:
      return null;
    default:
      throw UnreachableCaseException(clipboardStatus);
  }
};

type ClipboardCallbackProps = {
  readonly onCopyCompleted?: () => void;
  readonly onError?: () => void;
  readonly onInputBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onInputChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onInputFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  readonly onSuccess?: () => void;
};

type InjectedProps = Omit<IBaseInputProps, 'dataUiInput'>;

type ClipboardType = InputType.Text | InputType.Password;

export type CommonClipboardProps = ClipboardCallbackProps & {
  readonly auxiliaryElement?: ReactNode;
  readonly buttonRef?: RefObject<HTMLButtonElement>;
  readonly caption?: string;
  readonly containerRef?: RefObject<HTMLElement>;
  readonly id?: string;
  readonly label?: string;
  readonly overlayMessage?: string;
  readonly type?: ClipboardType;
  readonly value: string;
};

type BaseClipboardComponentProps = CommonClipboardProps & {
  readonly dataUiComponent: string | undefined;
  readonly inputState?: InputState;
  readonly renderClipboardButton: (ref: ClipboardButtonInjectedProps) => JSX.Element;
  readonly renderControlComponent: (props: InjectedProps) => JSX.Element;
};

export const BaseClipboardComponent: React.FC<BaseClipboardComponentProps> = ({
  auxiliaryElement,
  buttonRef,
  caption,
  containerRef,
  dataUiComponent,
  inputState,
  label,
  onCopyCompleted,
  onError,
  onInputBlur,
  onInputChange,
  onInputFocus,
  onSuccess,
  overlayMessage,
  value,
  renderClipboardButton,
  renderControlComponent,
  ...otherProps
}) => {
  const [delayedAction, setDelayedAction] = React.useState<ICancellablePromise>();
  const [clipboardStatus, setClipboardStatus] = React.useState<ClipboardStatus>(
    ClipboardStatus.Default,
  );
  const { refToForward: clipboardButtonRefToForward, refObject: clipboardButtonRefObject } =
    useAttachRef<HTMLButtonElement>(buttonRef);

  const { ref: wrapperRef } = useHotkeys({
    [ShortcutsConfig.ControlC]: () => clipboardButtonRefObject?.current?.click(),
  });

  const planDelayedAction = useCallback(() => {
    setDelayedAction(
      delay(copyToClipboardDelayMs)
        .then(() => {
          onCopyCompleted?.();
          setClipboardStatus(ClipboardStatus.Default);
        })
        .catch(swallowCancelledPromiseError),
    );
  }, [onCopyCompleted]);

  const handleSuccess = useCallback(() => {
    onSuccess?.();
    planDelayedAction();
    setClipboardStatus(ClipboardStatus.Success);
  }, [onSuccess, planDelayedAction]);

  const handleError = useCallback(() => {
    onError?.();
    planDelayedAction();
    setClipboardStatus(ClipboardStatus.Error);
  }, [onError, planDelayedAction]);

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    event.target?.select();
    onInputFocus?.(event);
  };

  useEffect(() => {
    if (!clipboardButtonRefObject.current) {
      return;
    }

    const container = containerRef?.current ?? undefined;

    const clipboardJS = new ClipboardJS(clipboardButtonRefObject.current, {
      text: () => value,
      container,
    });

    clipboardJS.on('success', (e) => {
      e.clearSelection();
      handleSuccess();
    });

    clipboardJS.on('error', () => {
      handleError();
    });

    return () => {
      clipboardJS.destroy();
      delayedAction?.cancel();
    };
  }, [containerRef, delayedAction, handleError, handleSuccess, value, clipboardButtonRefObject]);

  const clipboardInputState = overlayMessage?.length ? InputState.Disabled : inputState;
  const hasOverlayMessage =
    typeof overlayMessage === 'string' ? !!overlayMessage.length : !!overlayMessage;

  return (
    <div ref={wrapperRef} {...getDataAttribute(DataUiAttributes.Component, dataUiComponent)}>
      {renderControlComponent({
        auxiliaryElements: [
          auxiliaryElement,
          renderClipboardButton({
            ref: clipboardButtonRefToForward,
            isDisabled: clipboardInputState === InputState.Alert,
          }),
        ],
        caption,
        label,
        inputState: clipboardInputState,
        value: value ?? '',
        onBlur: onInputBlur,
        onChange: inputState === InputState.ReadOnly ? undefined : onInputChange,
        onFocus: handleInputFocus,
        overlayMessage: hasOverlayMessage ? overlayMessage : getStatusComponent(clipboardStatus),
        ...otherProps,
      })}
    </div>
  );
};

BaseClipboardComponent.displayName = 'BaseClipboardComponent';
