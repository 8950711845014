import React from 'react';
import { useHistory } from 'react-router';
import { SubscriptionsRoute } from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsCanceled,
  getAdministratedSubscriptions,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { showTrialActivationModal } from '../actions/projectsActions.ts';
import { ProjectsEmptyState as ProjectsEmptyStateComponent } from '../components/ProjectsEmptyState.tsx';
import { CopyState } from '../constants/copyState.ts';

interface IMyProjectsEmptyStateOwnProps {
  readonly activeProjectsShown: boolean;
}

export const MyProjectsEmptyState: React.FC<IMyProjectsEmptyStateOwnProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const hadTrial = useSelector((s) => s.data.user.info.hadTrial);
  const showActiveProjectsEmptyState = useSelector(
    (s) =>
      props.activeProjectsShown &&
      s.data.projects.byId.isEmpty() &&
      areAllSubscriptionsCanceled(
        getAdministratedSubscriptions(
          s.data.subscriptions.administratedIds,
          s.data.subscriptions.byId,
        ),
      ),
  );
  const showArchivedProjectsEmptyState = useSelector(
    (s) =>
      !props.activeProjectsShown &&
      s.data.projects.byId
        .filter((project: IProjectDetails) => project.inactive)
        .filterNot(
          (project: IProjectDetails) => project.inactive && project.copyState === CopyState.Failed,
        )
        .isEmpty(),
  );

  const onRedirectToSubscriptions = () => history.push(SubscriptionsRoute);
  const onCreateFreeTrialClick = () => dispatch(showTrialActivationModal());

  return (
    <div>
      <ProjectsEmptyStateComponent
        showActiveProjectsEmptyState={showActiveProjectsEmptyState}
        showArchivedProjectsEmptyState={showArchivedProjectsEmptyState}
        onCreateFreeTrialClick={onCreateFreeTrialClick}
        hadTrial={hadTrial}
        onRedirectToSubscriptions={onRedirectToSubscriptions}
      />
    </div>
  );
};

MyProjectsEmptyState.displayName = 'MyProjectsEmptyState';
