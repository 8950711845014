import React from 'react';

interface IBarItemErrorProps {
  readonly showError: boolean;
  readonly error?: string;
}

export const BarItemError: React.FC<IBarItemErrorProps> = ({ showError, error }) => {
  if (!showError || !error) {
    return <noscript />;
  }

  return (
    <div className="bar-item__alert-pane">
      <div className="bar-item__alert-message">{error}</div>
    </div>
  );
};

BarItemError.displayName = 'BarItemError';
