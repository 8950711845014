import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { notUndefined } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useScrollOnDragEvents } from '../../../../_shared/hooks/useScrollOnDragEvents.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { areSpacesInCollectionsEnabled } from '../../selectors/allowedFeaturesUtils.ts';
import {
  cancelCreator,
  editedCollectionNameChanged,
  moveCollection,
  openEditor,
  submitCreator,
} from '../actions/baseActions.ts';
import { areActiveElementsDisabled } from '../selectors/areActiveElementsDisabled.ts';
import { getLimitedApplicableWorkflowsCount } from '../utils/getCollectionApplicableWorkflows.ts';
import { CollectionExpandedForm } from './BarItems/CollectionExpandedForm.tsx';
import { DraggableCollectionBar } from './BarItems/DraggableCollectionBar.tsx';
import { DroppableCollections } from './BarItems/DroppableCollections.tsx';
import { ExpandedCollection } from './BarItems/ExpandedCollection.tsx';
import { CollectionsEmptyMessage } from './CollectionsEmptyMessage.tsx';
import { CollectionsHeader } from './CollectionsHeader.tsx';

interface ICollectionsListingProps {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly collectionsMap: CollectionsMap;
}

const collectionWorkflowLimitationRowHeightPx = 24;

export const CollectionsListing: React.FC<ICollectionsListingProps> = ({
  collectionIds,
  collectionsMap,
}) => {
  const dispatch = useDispatch();

  const editedCollectionName = useSelector((s) => s.collectionsApp.editedCollectionName);
  const editedCollectionId = useSelector((s) => s.collectionsApp.editedCollectionId);
  const isDragging = useSelector((s) => !!s.collectionsApp.draggedCollectionId);
  const isCreatorOpen = useSelector((s) => s.collectionsApp.isCreatorOpen);
  const allowCollectionEdit = !useSelector(areActiveElementsDisabled);
  const workflows = useSelector((s) => s.data.workflows.byId);
  const showSpaces = useSelector(
    (s) => areSpacesInCollectionsEnabled(s) && s.data.spaces.byId.size > 0,
  );

  const dndScrollAreaRef = useRef<HTMLUListElement | null>(null);
  useScrollOnDragEvents(dndScrollAreaRef);

  const onCreatorSubmitted = compose(dispatch, submitCreator);
  const onCreatorCanceled = compose(dispatch, cancelCreator);
  const onNameChange = compose(dispatch, editedCollectionNameChanged);

  const filterSearchPhrase = useSelector((s) => s.collectionsApp.filterSearchPhrase);
  const recentlyCreatedCollectionIds = useSelector(
    (s) => s.collectionsApp.recentlyCreatedCollectionIds,
  );

  const filteredCollectionIds = isEmptyOrWhitespace(filterSearchPhrase)
    ? collectionIds
    : collectionIds.filter(
        (collectionId) =>
          collectionsMap
            .get(collectionId)
            ?.name.toLocaleLowerCase()
            .includes(filterSearchPhrase.toLocaleLowerCase()) ||
          recentlyCreatedCollectionIds.includes(collectionId),
      );

  const filteredCollections = filteredCollectionIds
    .map((collectionId) => collectionsMap.get(collectionId))
    .filter(notUndefined);

  return (
    <Stack spacing={Spacing.XL}>
      <CollectionsHeader filteredCollectionsLength={filteredCollections.length} />
      {!filteredCollections.length && !isCreatorOpen && <CollectionsEmptyMessage />}
      <Stack
        component="ul"
        className={classNames('bar-item__list', {
          'bar-item__list--is-dragging': isDragging,
        })}
        ref={dndScrollAreaRef}
        spacing={Spacing.M}
        {...getDataUiElementAttribute(DataUiElement.BarItemList)}
      >
        <BarItemAnimation
          estimatedMaxHeightWhenExpanded={234}
          renderCollapsed={() => null}
          renderExpanded={() => (
            <li {...getDataUiElementAttribute(DataUiElement.BarItemNode)}>
              <CollectionExpandedForm
                collectionId={undefined}
                name={editedCollectionName}
                onNameChange={onNameChange}
                onSubmit={onCreatorSubmitted}
                onCancel={onCreatorCanceled}
                showSpaces={showSpaces}
              />
            </li>
          )}
          shouldBeExpanded={isCreatorOpen}
        />
        <DroppableCollections
          collections={filteredCollections}
          editedCollectionId={editedCollectionId}
          onMove={(sourceId, targetId) => dispatch(moveCollection(sourceId, targetId))}
          renderCollection={(collection) => (
            <BarItemAnimation
              estimatedMaxHeightWhenExpanded={
                340 +
                collectionWorkflowLimitationRowHeightPx *
                  getLimitedApplicableWorkflowsCount(workflows, collection.id)
              }
              renderCollapsed={() => (
                <DraggableCollectionBar
                  key={collection.id}
                  collection={collection}
                  isEditable={allowCollectionEdit}
                  onEdit={
                    allowCollectionEdit
                      ? () => dispatch(openEditor(collection.id, collection.name))
                      : undefined
                  }
                  showSpaces={showSpaces}
                />
              )}
              renderExpanded={() => (
                <ExpandedCollection collectionId={collection.id} showSpaces={showSpaces} />
              )}
              shouldBeExpanded={editedCollectionId === collection.id}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};
