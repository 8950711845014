import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';

export interface IRunWebsiteLocallySectionProps {
  readonly trackLinkClick: (linkId: string, target: string) => void;
}

export const RunWebsiteLocallySection: React.FC<IRunWebsiteLocallySectionProps> = () => {
  return (
    <Stack spacing={Spacing.L}>
      <Stack spacing={Spacing.L}>
        <h4 className="quickstart__section-subtitle">Sample website</h4>
        <Stack spacing={Spacing.S}>
          <p>Want to see how the content of this project looks on a website?</p>
          <p className="u-no-margin">Check out the sample apps linked above.</p>
        </Stack>
      </Stack>
      <div className="sample-app-section__sample-site" />
    </Stack>
  );
};

RunWebsiteLocallySection.displayName = 'RunWebsiteLocallySection';
