import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { isAiTranslationsEnabled } from '../../../../../../../_shared/selectors/aiSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { EditedContentItemVariant } from '../../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { VariantTranslationCondition } from '../../../constants/VariantTranslationCondition.ts';
import { getFailedTranslationCondition } from '../getFailedTranslationCondition.ts';

enum TranslationActionState {
  Enabled = 0,
  Disabled = 1,
  Hidden = 2,
}

export const isVariantTranslationEnabled = (
  state: IStore,
  variant: EditedContentItemVariant,
): boolean => getVariantTranslationActionState(state, variant) === TranslationActionState.Enabled;

export const isVariantTranslationDisabled = (
  state: IStore,
  variant: EditedContentItemVariant,
): boolean => getVariantTranslationActionState(state, variant) === TranslationActionState.Disabled;

const getVariantTranslationActionState = (
  state: IStore,
  variant: EditedContentItemVariant,
): TranslationActionState => {
  if (shouldNotDisplayEitherOfAiTranslateActions(state, variant)) {
    return TranslationActionState.Hidden;
  }

  if (getFailedTranslationCondition(state) === VariantTranslationCondition.None) {
    return TranslationActionState.Enabled;
  }

  return TranslationActionState.Disabled;
};

const shouldNotDisplayEitherOfAiTranslateActions = (
  state: IStore,
  variant: EditedContentItemVariant,
) =>
  isPublishingStepSelected(variant.assignment) ||
  isArchivedWorkflowStepSelected(variant.assignment) ||
  !isAiTranslationsEnabled(state) ||
  !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state);
