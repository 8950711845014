import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { Link } from 'react-router-dom';
import { ItemNameGuidelineStatus } from '../../../utils/itemEditingUtils.ts';
import { Guidelines } from '../subComponents/Guidelines.tsx';

type Props = {
  readonly defaultLanguageName?: string;
  readonly defaultLanguagePath?: string;
  readonly guidelinesStatus: ItemNameGuidelineStatus;
  readonly onLanguageSwitch?: () => void;
};

export const ItemElementNameGuidelines: React.FC<Props> = ({
  defaultLanguageName,
  defaultLanguagePath,
  guidelinesStatus,
  onLanguageSwitch,
}) => {
  if (guidelinesStatus === ItemNameGuidelineStatus.Hidden) {
    return null;
  }

  if (guidelinesStatus === ItemNameGuidelineStatus.RedirectToDefaultLanguage) {
    return (
      <Guidelines>
        <p>
          To edit the content item name, switch to your default language
          {defaultLanguagePath && defaultLanguageName && (
            <>
              {' — '}
              <Link to={defaultLanguagePath} onClick={onLanguageSwitch}>
                {defaultLanguageName}
              </Link>
            </>
          )}
          .
        </p>
      </Guidelines>
    );
  }

  return (
    <Guidelines>
      <p>{getGuidelinesText(guidelinesStatus, defaultLanguageName ?? '')}</p>
    </Guidelines>
  );
};

ItemElementNameGuidelines.displayName = 'ItemElementNameGuidelines';

const getGuidelinesText = (
  status: ItemNameGuidelineStatus,
  defaultLanguageName: string,
): string => {
  switch (status) {
    case ItemNameGuidelineStatus.UpdatingNameWillAffectOtherVariants:
      return 'This name identifies the item elsewhere in your project. If you edit the name, it will change for all languages.';
    case ItemNameGuidelineStatus.NoUpdatePermissionForDefaultLanguage:
      return `If you need to edit the content item name, ask a colleague with enough permissions to edit it in ${defaultLanguageName}.`;
    case ItemNameGuidelineStatus.NoRoleForDefaultLanguage:
      return 'If you need to edit the content item name, ask a colleague with enough permissions to edit it in the default language.';
    default:
      throw InvariantException(`Invalid guidelinesStatus value: ${status}.`);
  }
};
