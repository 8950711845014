import { parse } from 'cookie';

function expireCookie(name: string, now: string): void {
  document.cookie = `${name}=;expires=${now}`;
}

export function getExistingCookies(documentCookie: string): ReadonlyRecord<string, string> {
  const cookiePairs = parse(documentCookie);
  return Object.fromEntries(
    Object.entries(cookiePairs).filter(([, v]) => v !== undefined) as [string, string][],
  );
}

export function expireCookies(cookieNames: ReadonlyArray<string>): void {
  const now = new Date().toUTCString();
  if (cookieNames) {
    cookieNames.forEach((name) => expireCookie(name, now));
  }
}
