import Immutable from 'immutable';
import { useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getViewableContentGroups } from '../../../../../_shared/utils/contentItemUtils.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  PendingAiChangesGuard,
  TryLeaveCallback,
} from '../../PendingChangesDialog/containers/PendingAiChangesGuard.tsx';
import { selectContentGroup } from '../actions/thunkContentItemEditingActions.ts';
import {
  ContentGroupTabs as ContentGroupTabsComponent,
  IContentGroupTabsOwnProps,
} from '../components/ContentGroupTabs.tsx';
import { ContentGroupSelectionReason } from '../constants/ContentGroupSelectionReason.ts';
import { getContentGroupBadgeType } from '../selectors/getContentGroupBadgeType.ts';
import { ContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import {
  getIncompleteElementIdPaths,
  getIncompleteElementsPerGroup,
} from '../utils/itemValidationUtils.ts';
import { ContentGroupTab } from './ContentGroupTab.tsx';

type Props = IContentGroupTabsOwnProps & {
  readonly canEditContent: boolean;
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentType: ICompiledContentType;
  readonly hideValidationStatus?: boolean;
};

export const ContentGroupTabs = (props: Props) => {
  const { canEditContent, contentGroupTabsId, contentType, hideValidationStatus } = props;

  const contentTypeGroups = useSelector((state) =>
    getViewableContentGroups(contentType, state.contentApp.editorUi),
  );
  const selectedContentGroupId = useSelector((state) =>
    getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state),
  );

  const badgeType = useSelector((state) =>
    getContentGroupBadgeType(
      state.contentApp.showIncompleteItemWarningsBeforePublish,
      hideValidationStatus,
    ),
  );

  const incompleteElementsPerGroup = useSelector((state) => {
    if (hideValidationStatus) {
      return Immutable.Map<Uuid, number>();
    }

    const { itemValidationErrors, itemValidationWarnings, editedContentItemVariantElements } =
      state.contentApp;

    const incompleteElementIds = getIncompleteElementIdPaths(
      itemValidationErrors,
      itemValidationWarnings,
    );
    const contentTypeElements = contentType.contentElements;

    return getIncompleteElementsPerGroup(
      contentTypeGroups,
      editedContentItemVariantElements,
      contentTypeElements,
      incompleteElementIds,
      undefined,
    );
  });

  const dispatch = useDispatch();
  const onSelect = useCallback(
    (contentGroupId: Uuid) => {
      dispatch(
        selectContentGroup({
          contentGroupTabsId,
          contentGroupId,
          reason: ContentGroupSelectionReason.UserClick,
        }),
      );
    },
    [contentGroupTabsId],
  );

  const tryLeaveIfGroupChanged = useCallback(
    (tryLeave: TryLeaveCallback, groupId: Uuid): void => {
      if (groupId !== selectedContentGroupId) {
        tryLeave(() => onSelect(groupId));
      }
    },
    [onSelect, selectedContentGroupId],
  );

  return (
    <PendingAiChangesGuard
      renderContent={(tryLeave) => (
        <ContentGroupTabsComponent
          {...props}
          badgeType={badgeType}
          canEditContent={canEditContent}
          contentTypeGroups={contentTypeGroups}
          incompleteElementsPerGroup={incompleteElementsPerGroup}
          selectedContentGroupId={selectedContentGroupId}
          tabItemComponent={ContentGroupTab}
          onSelect={(groupId) => tryLeaveIfGroupChanged(tryLeave, groupId)}
        />
      )}
    />
  );
};
