import React from 'react';
import { DropDownCaptionOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownCaptionOption.tsx';
import { DropDownOptionsGroup } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';

export type ActionsMenuSubMenuOwnProps = {
  readonly caption: string;
  readonly children: React.ReactNode;
};

export type ActionsMenuSubMenuDispatchProps = {
  readonly onNavigateBack: () => void;
};

type ActionsMenuSubMenuProps = ActionsMenuSubMenuOwnProps & ActionsMenuSubMenuDispatchProps;

export const ActionsMenuSubMenu: React.FC<React.PropsWithChildren<ActionsMenuSubMenuProps>> = (
  props,
) => (
  <>
    <DropDownOptionsGroup>
      <DropDownCaptionOption name={props.caption} onClick={props.onNavigateBack} />
    </DropDownOptionsGroup>
    <DropDownOptionsGroup>{props.children}</DropDownOptionsGroup>
  </>
);

ActionsMenuSubMenu.displayName = 'ActionsMenuSubMenu';
