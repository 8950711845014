import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_ExpandNode_Failed,
  Relations_ExpandNode_Finished,
  Relations_ExpandNode_Started,
} from '../constants/relationsActionTypes.ts';

const initialState = Immutable.Set<string>();

export function nodesBeingProcessed(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_ExpandNode_Started:
      return state.add(action.payload.nodeId);

    case Relations_ExpandNode_Finished:
    case Relations_ExpandNode_Failed:
      return state.remove(action.payload.nodeId);

    default:
      return state;
  }
}
