import { Action } from '../../../../../@types/Action.type.ts';
import { isMultipleChoiceTypeElement } from '../../../shared/types/typeElementTypeGuards.ts';
import {
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_InitStarted,
  ContentTypeSnippet_Editor_MultipleChoiceOptionDeselected,
  ContentTypeSnippet_Editor_MultipleChoiceOptionLabelChanged,
  ContentTypeSnippet_Editor_MultipleChoiceOptionSelected,
  ContentTypeSnippet_Editor_TypeElementCreated,
} from '../../constants/snippetActionTypes.ts';
import { IEditedMultipleChoiceSnippetElement } from '../IContentTypeSnippetsAppStoreState.type.ts';

const initialState: IEditedMultipleChoiceSnippetElement = {
  elementId: '',
  optionId: '',
  optionLabel: '',
};

export const editedMultipleChoiceElement = (
  state = initialState,
  action: Action,
): IEditedMultipleChoiceSnippetElement => {
  switch (action.type) {
    case ContentTypeSnippet_Editor_TypeElementCreated: {
      const { typeElementData } = action.payload;

      const firstOption =
        isMultipleChoiceTypeElement(typeElementData) &&
        Object.values(typeElementData.options).at(0);
      return firstOption
        ? {
            elementId: typeElementData.elementId,
            optionId: firstOption.id,
            optionLabel: '',
          }
        : state;
    }

    case ContentTypeSnippet_Editor_MultipleChoiceOptionSelected:
      return {
        elementId: action.payload.elementId,
        optionId: action.payload.option.id,
        optionLabel: action.payload.option.label,
      };

    case ContentTypeSnippet_Editor_MultipleChoiceOptionLabelChanged:
      return { ...state, optionLabel: action.payload.label };

    case ContentTypeSnippet_Creator_InitStarted:
    case ContentTypeSnippet_Editor_InitStarted:
    case ContentTypeSnippet_Editor_MultipleChoiceOptionDeselected:
      return initialState;

    default:
      return state;
  }
};
