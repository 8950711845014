import { ContentTypeUsageServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';

export interface IContentTypeUsage {
  readonly id: Uuid;
  readonly itemCount: number;
  readonly contentComponentCount: number;
}

export const EmptyContentTypeUsage: IContentTypeUsage = {
  id: '',
  itemCount: 0,
  contentComponentCount: 0,
};

export const isContentTypeUsed = (typeUsage?: IContentTypeUsage): boolean =>
  !!typeUsage && (typeUsage.itemCount > 0 || typeUsage.contentComponentCount > 0);

export const getContentTypeUsageFromServerModel = (
  serverModel: Partial<ContentTypeUsageServerModel>,
): IContentTypeUsage => {
  return {
    id: serverModel.typeId || '',
    itemCount: serverModel.itemsCount || 0,
    contentComponentCount: serverModel.contentComponentsCount || 0,
  };
};
