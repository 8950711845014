import React from 'react';
import { useParams } from 'react-router';
import {
  EnvironmentRouteParams,
  HomeEntryRouteParams,
  QuickstartRoute,
  YourContentRoute,
} from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { projectHasQuickstart } from '../../../_shared/utils/trialUtils.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { Home as HomeComponent } from '../components/Home.tsx';

export const Home: React.FC = () => {
  const { projectId } = useParams<HomeEntryRouteParams>();
  const shouldShowQuickstart = useSelector((s) => projectHasQuickstart(s, getCurrentProject(s)));

  return (
    <HomeComponent
      shouldShowQuickstart={shouldShowQuickstart}
      defaultRoute={
        shouldShowQuickstart
          ? buildPath<EnvironmentRouteParams>(QuickstartRoute, {
              projectId,
            })
          : buildPath<EnvironmentRouteParams>(YourContentRoute, {
              projectId,
            })
      }
    />
  );
};

Home.displayName = 'Home';
