import { GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DoesCurrentStateStillMatchArguments,
  ILoadListingItemsParams,
} from '../../../../../data/actions/thunks/listingContentItems/loadListingContentItems.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getContentItemScrollTableRowHeightPx } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { ensureContentTypesLoadedForAllContentItems } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/ensureContentTypesLoadedForAllContentItems.ts';

type LoadContentStatusContentItemsParams = ILoadListingItemsParams &
  Readonly<{
    variantIds: ReadonlySet<Uuid>;
  }>;
interface IDeps {
  readonly loadContentStatusContentItems: (
    params: LoadContentStatusContentItemsParams,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

const doesCurrentStateStillMatchArgumentsIncludingLanguage: DoesCurrentStateStillMatchArguments = (
  route,
  args,
) => route.projectId === args.projectId;

type Params = {
  variantIds?: ReadonlySet<Uuid>;
  scrollPositionChanged?: boolean;
};

export const createLoadContentStatusContentItemsForInventoryAction =
  (deps: IDeps) =>
  (
    { variantIds = new Set(), scrollPositionChanged }: Params,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      listingUi: { filter, orderBy },
    } = state.contentApp;

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const currentProjectId = getCurrentProjectId(state);

    await dispatch(
      deps.loadContentStatusContentItems(
        {
          doesCurrentStateStillMatchArguments: doesCurrentStateStillMatchArgumentsIncludingLanguage,
          filter,
          getScrollTableState: (getFreshState: GetState) =>
            getFreshState().contentApp.listingUi.contentItemListingScrollTableState,
          itemHeightPx: getContentItemScrollTableRowHeightPx(),
          orderBy,
          projectId: currentProjectId,
          scrollPositionChanged: !!scrollPositionChanged,
          languageId: selectedLanguageId,
          variantIds,
        },
        abortSignal,
      ),
    );

    await dispatch(ensureContentTypesLoadedForAllContentItems(abortSignal));
  };
