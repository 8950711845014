import React from 'react';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCheckboxCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCheckboxCell.tsx';
import { ScrollTableHeadColumn } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import {
  DataUiCollection,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type RecommendedLinkedItemsScrollTableHeadDataProps = {
  readonly contentListingOrderBy: OrderBy<ItemColumnCode> | null;
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly isSelectAllOn?: boolean;
  readonly renderCheckboxPlaceholder?: boolean;
  readonly renderOpenItemInNewTab?: boolean;
  readonly selectAllDisabledMessage?: string;
  readonly showScoreColumn: boolean;
};

type RecommendedLinkedItemsScrollTableHeadCallbackProps = {
  readonly onCheckboxClick?: () => void;
};

export type RecommendedLinkedItemsScrollTableHeadProps =
  RecommendedLinkedItemsScrollTableHeadDataProps &
    RecommendedLinkedItemsScrollTableHeadCallbackProps;

export const RecommendedLinkedItemsScrollTableHead: React.FC<
  RecommendedLinkedItemsScrollTableHeadProps
> = ({
  hiddenColumns,
  isSelectAllOn,
  onCheckboxClick,
  renderCheckboxPlaceholder,
  renderOpenItemInNewTab,
  selectAllDisabledMessage,
  showScoreColumn,
}) => (
  <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
    {renderCheckboxPlaceholder && <ScrollTableCell size={1} />}
    {!renderCheckboxPlaceholder && onCheckboxClick && (
      <ScrollTableCheckboxCell
        id="select-all"
        name="Select all"
        isItemChecked={!!isSelectAllOn}
        onCheckboxClick={onCheckboxClick}
        disabledMessage={selectAllDisabledMessage}
      />
    )}
    <ScrollTableHeadColumn
      dataUiElement={DataUiElement.ColumnHeaderName}
      name={translateColumnCodeToTitle(ItemColumnCode.Name)}
      size={10}
      isGrowing
    />
    {renderOpenItemInNewTab && (
      <div className="scroll-table__column-head scroll-table__column--1" />
    )}
    {!hiddenColumns.has(ItemColumnCode.WorkflowStep) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderWorkflowStep}
        name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
        size={4}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.ContentType) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderContentType}
        name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Collection) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderCollection}
        name={translateColumnCodeToTitle(ItemColumnCode.Collection)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Space) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderSpace}
        name={translateColumnCodeToTitle(ItemColumnCode.Space)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.LastPublishedAt) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderPublished}
        name={translateColumnCodeToTitle(ItemColumnCode.LastPublishedAt)}
        size={4}
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.LastModifiedAt) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderLastModified}
        name={translateColumnCodeToTitle(ItemColumnCode.LastModifiedAt)}
        size={4}
      />
    )}
    {showScoreColumn && (
      <ScrollTableHeadColumn
        name="Score"
        orderByDirection={OrderByDirection.Descending}
        size={4}
        isGrowing
      />
    )}
  </ScrollTableHeadRow>
);
