import Immutable from 'immutable';
import { splitToGroups } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { BaseBlockType, BlockType } from '../../../../richText/utils/blocks/blockType.ts';
import { DiffType } from './DiffType.ts';
import { convertToPrivateUseArea } from './diffUnicodeUtils.ts';

export const EmptyBlockPlaceholder = ' ';

export function checkBlockTypeChanged(
  oldBlock: IDiffInputBlock | undefined,
  newBlock: IDiffInputBlock | undefined,
): boolean {
  if (!oldBlock || !newBlock) {
    return false;
  }

  return oldBlock.type !== newBlock.type;
}

export function mergeDiffType(diffType: DiffType | null, newDiffType: DiffType): DiffType {
  if (!diffType) {
    return newDiffType;
  }

  if (newDiffType && diffType !== newDiffType) {
    return DiffType.Mixed;
  }

  return diffType;
}

export enum FakeCharType {
  BlockContainer = 'container',
  Block = 'block',
  Text = 'text',
}

export interface IInputChar {
  char: string;
  inlineStyles: Immutable.OrderedSet<string>;
  entityKey: string | null;
  fakeCharType?: FakeCharType;
  fakedOriginalChars?: ReadonlyArray<IInputChar>;
}

export const DiffBlockTypeTable = 'table';
type DiffBlockType = BaseBlockType | typeof DiffBlockTypeTable;

export interface IDiffInputBlock {
  readonly type: DiffBlockType;
  readonly key: string;
  readonly depth: number;
  readonly characters: ReadonlyArray<IInputChar>;
  readonly blocks?: ReadonlyArray<IDiffInputBlock>;
  readonly data?: Immutable.Map<any, any>;
}

export function isImageOrModularBlock(block: IDiffInputBlock) {
  return block.type === BlockType.Image || block.type === BlockType.ContentModule;
}

export function isContentComponentBlock(block: IDiffInputBlock) {
  return block.type === BlockType.ContentComponent;
}

export const isDiffTableBlock = (block: IDiffInputBlock) => block.type === DiffBlockTypeTable;

export interface IOutputChar {
  readonly char: string;
  readonly inlineStyles: Immutable.OrderedSet<string>;
  readonly entityKey: string | null;
  readonly diffType?: DiffType;
}

export interface IDiffResultBlock {
  readonly characters: ReadonlyArray<IOutputChar>;
  readonly blocks?: ReadonlyArray<IDiffResultBlock>;
  readonly depth: number;
  readonly type: DiffBlockType;
  readonly data?: Immutable.Map<any, any>;
  readonly diffType: DiffType | null;
}

export interface IRawOutputBlock {
  readonly characters: IOutputChar[];
  // This tracks the overall change for a block to find out if the whole block needs to be marked with a single change
  diffType: DiffType | null;
  sourceBlock: IDiffInputBlock | null;
  sourceBlockIsOld: boolean;
}

export function createOutputCharFromInputChar(ch: IInputChar, diffType: DiffType): IOutputChar {
  return {
    char: ch.char,
    inlineStyles: ch.inlineStyles,
    entityKey: ch.entityKey,
    diffType,
  };
}

export function createOutputBlock(
  openBlock: IRawOutputBlock,
  sourceBlock: IDiffInputBlock | undefined,
): IDiffResultBlock {
  const type = sourceBlock ? sourceBlock.type : BlockType.Unstyled;
  const depth = sourceBlock ? sourceBlock.depth : 0;

  const outputBlock = {
    characters: openBlock.characters,
    type,
    depth,
    diffType: openBlock.diffType,
    data: sourceBlock ? sourceBlock.data : undefined,
  };

  return outputBlock;
}

export function createAtomicOutputBlock(
  sourceBlock: IDiffInputBlock,
  blockDiffType: DiffType,
): IDiffResultBlock {
  return {
    characters: [],
    type: sourceBlock.type,
    depth: sourceBlock.depth,
    data: sourceBlock.data,
    diffType: blockDiffType,
  };
}

export function createRawOutputBlock(): IRawOutputBlock {
  return {
    characters: [],
    diffType: null,
    sourceBlock: null,
    sourceBlockIsOld: false,
  };
}

export function getTextPartsFromInputBlock(block: IDiffInputBlock): ReadonlyArray<string> {
  // We split the text by entities to make sure that two neighbouring entities or entity + text forming a word do not merge in word diff
  // as we use fake words instead of entities
  const splitByEntity = splitToGroups(
    block.characters,
    (current, previous) => current.entityKey !== previous.entityKey,
  );
  const textParts = splitByEntity.map((chunk) =>
    chunk
      .map(
        // Fake chars need to be converted to private use area Unicode range to prevent overlap in comparison with real physical chars
        (c: IInputChar) => (c.fakeCharType ? convertToPrivateUseArea(c.char) : c.char),
      )
      .join(''),
  );

  return textParts;
}

export interface ITextDiffPart {
  value: string;
  added?: boolean;
  removed?: boolean;
}

export const BlockBoundaryChar: IInputChar = {
  char: '¶',
  entityKey: null,
  inlineStyles: Immutable.OrderedSet<string>(),
  fakeCharType: FakeCharType.Block,
};

export const ZeroWidthSpaceChar: IInputChar = {
  char: '\u200b',
  entityKey: null,
  inlineStyles: Immutable.OrderedSet<string>(),
  fakeCharType: FakeCharType.Block,
};

const isBlockCharacter = (ch: IInputChar): boolean => ch.fakeCharType === FakeCharType.Block;

export function isAtBlockEdge(block: IDiffInputBlock, index: number): boolean {
  return (
    block.characters.slice(0, index).every(isBlockCharacter) ||
    block.characters.slice(index).every(isBlockCharacter)
  );
}
