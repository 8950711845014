import { combineReducers } from '@reduxjs/toolkit';
import { draggedLanguageId } from './draggedLanguageId.ts';
import { activeLanguagesItemVariantCount } from './internalReducers/activeLanguagesItemVariantCount.ts';
import { defaultLanguage } from './internalReducers/defaultLanguage.ts';
import { editedLanguageId } from './internalReducers/editedLanguageId.ts';
import { isDefaultLanguageEdited } from './internalReducers/isDefaultLanguageEdited.ts';
import { isEditedLanguageNew } from './internalReducers/isEditedLanguageNew.ts';
import { languageItemsOrder } from './internalReducers/languageItemsOrder.ts';
import { languages } from './internalReducers/languages.ts';
import { languagesHavePendingChanges } from './internalReducers/languagesHavePendingChanges.ts';
import { loadingStatus } from './internalReducers/loadingStatus.ts';

export const localizationReducer = combineReducers({
  activeLanguagesItemVariantCount,
  defaultLanguage,
  draggedLanguageId,
  editedLanguageId,
  isEditedLanguageNew,
  isDefaultLanguageEdited,
  languageItemsOrder,
  languages,
  loadingStatus,
  languagesHavePendingChanges,
});
