import { combineReducers } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { ICompiledContentType } from '../../../applications/contentInventory/content/models/CompiledContentType.ts';
import { defaultAssetType } from './reducers/defaultAssetType.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';

export interface IAssetTypes {
  readonly defaultAssetType: ICompiledContentType | null;
  readonly loadingStatus: LoadingStatus;
}

export const assetTypes = combineReducers({
  defaultAssetType,
  loadingStatus,
});
