import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getUserInviteLink } from '../../selectors/getUserInviteLink.ts';

export const UserListingDefaultState: React.FC = () => {
  const inviteLink = useSelector(getUserInviteLink);

  return (
    <EmptyState>
      <EmptyState.Title>Where is everybody?</EmptyState.Title>
      <EmptyState.Content>Invite your team to help you kickstart the project!</EmptyState.Content>
      {inviteLink && (
        <EmptyState.Footer>
          <RouterLinkButton
            buttonStyle="primary"
            to={inviteLink}
            {...getDataUiActionAttribute(DataUiAction.CreateNew)}
          >
            Invite
          </RouterLinkButton>
        </EmptyState.Footer>
      )}
    </EmptyState>
  );
};

UserListingDefaultState.displayName = 'UserListingDefaultState';
