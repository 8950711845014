import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  renderDateUtcString,
  renderShortenedDateUtcString,
} from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { IBillingPeriodReport } from '../models/BillingPeriodReport.ts';

interface IPlanBar {
  readonly subscription: ISubscription;
  readonly report: IBillingPeriodReport;
  readonly plan: IPlan;
  readonly canChangePlan: boolean;
  readonly getPlansSelectionPath: () => string;
}

export const PlanBar: FC<IPlanBar> = ({
  subscription,
  report,
  plan,
  canChangePlan,
  getPlansSelectionPath,
}) => (
  <section className="current-usage__section">
    <div className="current-usage__plan-overview-box">
      <div className="current-usage__plan-overview-container">
        <div className="current-usage__plan-overview-inner-box">
          <div className="current-usage__plan-overview-title">Your plan</div>
          <div className="current-usage__plan-overview-content">
            {plan.displayName}
            <span className="current-usage__plan-overview-price">${report.monthlyFee}</span>
          </div>
          {!plan.isPrepaid && canChangePlan && (
            <div className="current-usage__plan-overview-content-action">
              <Link className="u-no-text-decoration" to={getPlansSelectionPath()}>
                <Button
                  buttonStyle="tertiary"
                  size="medium"
                  {...getDataUiActionAttribute(DataUiAction.ChangePlan)}
                >
                  Change plan
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="current-usage__plan-overview-inner-box">
          <div className="current-usage__plan-overview-title">Additional charges</div>
          <div className="current-usage__plan-overview-content">
            Extra usage
            <span className="current-usage__plan-overview-price">${report.extraCosts}</span>
          </div>
          {plan.isPrepaid && (
            <div className="current-usage__plan-overview-content">
              Services
              <span className="current-usage__plan-overview-price">${report.packagesCosts}</span>
            </div>
          )}
        </div>
        {renderBillingPeriodBox(subscription, report, false)}
      </div>
    </div>
    <div className="current-usage__total-price-box">
      <div className="current-usage__section-content">
        <div className="current-usage__total-price-label">Total price</div>
        <div className="current-usage__total-price-value">${report.totalCosts}</div>
      </div>
    </div>
  </section>
);

PlanBar.displayName = 'PlanBar';

const getEndPeriod = (date: DateTimeStamp) => {
  const periodEndDate = new Date(date);
  periodEndDate.setMonth(periodEndDate.getMonth() + 1);

  return periodEndDate.toISOString();
};

const renderBillingPeriodBox = (
  subscription: ISubscription,
  report: IBillingPeriodReport | null,
  showPrepaidPeriod: boolean,
): JSX.Element => {
  return (
    <div className="current-usage__plan-overview-inner-box">
      <div className="current-usage__plan-overview-title">Billing period</div>
      <div className="current-usage__plan-overview-content">
        {showPrepaidPeriod
          ? `${renderDateUtcString(
              subscription.currentPlan.startAt,
            )} - ${renderShortenedDateUtcString(subscription.currentPlan.endAt)}`
          : report?.reportId &&
            `${renderDateUtcString(report.periodStart)} - ${renderShortenedDateUtcString(
              getEndPeriod(report.periodStart),
            )}`}
      </div>
    </div>
  );
};
