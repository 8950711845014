import { InputState } from '@kontent-ai/component-library/Input';
import { CollapsibleOption, ISelectItem } from '@kontent-ai/component-library/Selects';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import { ComponentProps, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { capitalizeFirstLetter } from '../../../../../_shared/utils/stringUtils.ts';
import { getAllWorkflowSteps } from '../../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { getWorkflowTooltip } from '../../../../../_shared/utils/workflow/getWorkflowTooltip.ts';
import { EntityWebhookAction } from '../../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { anyWorkflowStepWorkflowStep } from '../../../constants/anyWorkflowStepWorkflowStep.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { handleAnyWorkflowStepWorkflowStepOnWorkflowStepsChangedInFormInput } from '../../../utils/anyWorkflowStepWorkflowStepUtils.ts';
import { EntityWebhookValidationError } from '../EntityWebhookValidationError.tsx';
import { IStepSelectItem } from './EntityWebhookWorkflowStepFilterSelector.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export interface IWorkflowOptionSelectItem extends ISelectItem<IWorkflowOptionSelectItem> {
  readonly tooltip?: string;
}

const combineIds = (workflowId: Uuid, stepId: Uuid) => `${workflowId}/${stepId}`;

const toWorkflowStepSelectItem = (
  workflowStep: IWorkflowStep,
  workflow: Workflow,
): IWorkflowOptionSelectItem => ({
  id: combineIds(workflow.id, workflowStep.id),
  label: workflowStep.name,
  tooltip: capitalizeFirstLetter(getWorkflowTooltip(workflow.name)),
});

const toWorkflowSelectItem = (workflow: Workflow): IWorkflowOptionSelectItem => ({
  id: workflow.id,
  label: workflow.name,
  items: getAllWorkflowSteps(workflow).map((step) => toWorkflowStepSelectItem(step, workflow)),
});

const renderWorkflowStepSelectedOption = (
  id: string,
  workflowStep: IWorkflowOptionSelectItem,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) => {
  return id === anyWorkflowStepWorkflowStep.id ? (
    <Tag
      {...defaultTagProps}
      customTooltipText={workflowStep.tooltip}
      background={BaseColor.Gray30}
    />
  ) : (
    <DefaultTag {...defaultTagProps} customTooltipText={workflowStep.tooltip} />
  );
};

export const EntityWebhookExtendedWorkflowStepFilterSelector = (props: Props) => {
  const workflowsById = useSelector((state) => state.data.workflows.byId);
  const workflows = useMemo(() => Collection.getValues(workflowsById), [workflowsById]);

  const { control } = props.formProps;
  const { field } = useController({ control, name: 'triggers.contentItemTrigger' });

  const items = useMemo(
    () => [anyWorkflowStepWorkflowStep, ...workflows.map(toWorkflowSelectItem)],
    [workflows],
  );
  const [expandedWorkflowIds, setExpandedWorkflowIds] = useState<ReadonlySet<Uuid>>(
    () => new Set(items.map((item) => item.id)),
  );

  const inputState =
    field.value.checked && field.value.actions.includes(EntityWebhookAction.WorkflowStepChanged)
      ? InputState.Default
      : InputState.Disabled;

  const isEmptyWorkflowFilter = field.value.workflowSteps.length < 1;

  const normalize = <TWorkflowStep extends IStepSelectItem & IWorkflowOptionSelectItem>(
    formValues: ReadonlyArray<TWorkflowStep>,
    previousValues: ReadonlyArray<TWorkflowStep>,
  ): ReadonlyArray<IWorkflowOptionSelectItem> => {
    const values = handleAnyWorkflowStepWorkflowStepOnWorkflowStepsChangedInFormInput(
      formValues,
      previousValues,
    );
    const selectedWorkflows = values.filter((item) =>
      workflows.some((workflow) => workflow.id === item.id),
    );
    const selectedWorkflowsToAdd = selectedWorkflows.filter(
      (workflow) => !workflow.items?.every((item) => values.some((step) => step.id === item.id)),
    );
    const itemsToRemove = selectedWorkflows
      .filter((workflow) =>
        workflow.items?.every((item) => values.some((step) => step.id === item.id)),
      )
      .flatMap((workflow) => workflow.items ?? []);
    const newValues = [
      ...values.filter((item) => !selectedWorkflows.includes(item)),
      ...items
        .filter((item) => selectedWorkflowsToAdd.some((workflow) => item.id === workflow.id))
        .flatMap((item) => item.items ?? []),
    ].filter((item) => !itemsToRemove.includes(item));
    field.onChange({
      ...field.value,
      workflowSteps: newValues.map((option) => option.id),
    });
    return newValues;
  };

  return (
    <>
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, IWorkflowOptionSelectItem>
        expandedKeys={expandedWorkflowIds}
        formProps={props.formProps}
        inputState={inputState}
        items={items}
        name="triggers.contentItemTrigger.workflowSteps"
        normalize={normalize}
        onExpandedChange={setExpandedWorkflowIds}
        placeholder="Select workflow steps"
        renderMenuOption={(optionProps) => <CollapsibleOption {...optionProps} />}
        renderSelectedOption={renderWorkflowStepSelectedOption}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookWorkflowStep)}
      />
      {isEmptyWorkflowFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one language." />
      )}
    </>
  );
};

EntityWebhookExtendedWorkflowStepFilterSelector.displayName =
  'EntityWebhookExtendedWorkflowStepFilterSelector';
