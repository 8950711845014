import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { CommentThreadsOnRemovedContentOnboardingShownAtServerKey } from '../../../../../../_shared/models/UserPropertiesServerKeys.ts';
import { scrollToCommentsOnRemovedContentSection } from '../../utils/commentThreadsOnRemovedContentScrollingUtils.ts';
import { discussionSidebarOpened } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly upsertUserProperty: (propertyKey: string, propertyValue: string) => ThunkPromise;
}

export const createShowCommentThreadsOnRemovedContentOnboarding =
  (deps: IDeps) => (): ThunkPromise => async (dispatch) => {
    dispatch(discussionSidebarOpened());
    scrollToCommentsOnRemovedContentSection();

    const shownAt = new Date().toUTCString();
    await dispatch(
      deps.upsertUserProperty(CommentThreadsOnRemovedContentOnboardingShownAtServerKey, shownAt),
    );
  };
