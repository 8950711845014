import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Size,
  SkeletonContentGradient,
  Spacing,
  Typography,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';

interface IWorkflowOverviewSkeletonProps {
  readonly name: string;
  readonly stepsCount: number;
}

const StatusSkeletonWidth = 112;

const StyledStatusSkeleton = styled.div`
  width: ${px(StatusSkeletonWidth)};
  height: ${px(Size.S)};
  margin-right: ${px(Spacing.S)};
  background: ${SkeletonContentGradient.toString()};
  border-radius: ${px(BorderRadius.Pill)};
`;

const StyledCountSkeleton = styled.div`
  width: ${px(Size.S)};
  height: ${px(Size.S)};
  background: ${SkeletonContentGradient.toString()};
  border-radius: ${px(BorderRadius.Pill)};
`;

const StyledOverviewBarSkeleton = styled.div`
  width: 50%;
  height: ${px(Size.S)};
  margin-left: ${px(Spacing.S)};
  background: ${SkeletonContentGradient.toString()};
  border-radius: ${px(BorderRadius.Pill)};
`;

export const WorkflowOverviewSkeleton: React.FC<IWorkflowOverviewSkeletonProps> = ({
  stepsCount,
  name,
}) => (
  <Stack spacing={Spacing.L}>
    <Box typography={Typography.HeadlineSmall}>{name}</Box>
    <Stack spacing={Spacing.XS}>
      {Array.from(new Array(stepsCount).keys()).map((key) => (
        <Row key={key}>
          <Column flexFactor={1}>
            <Box display="flex" justifyContent="space-between">
              <StyledStatusSkeleton />
              <StyledCountSkeleton />
            </Box>
          </Column>
          <Column flexFactor={3}>
            <StyledOverviewBarSkeleton />
          </Column>
        </Row>
      ))}
    </Stack>
  </Stack>
);

WorkflowOverviewSkeleton.displayName = 'WorkflowOverviewSkeleton';
