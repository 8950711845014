import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { RootFolderId } from '../constants/assetFolderConstants.ts';

export const assetFolderExists = (assetFolderId: Uuid, state: IStore): boolean => {
  const {
    data: { assetFolders },
  } = state;

  return assetFolderId === RootFolderId || assetFolders.taxonomyGroup.terms.has(assetFolderId);
};
