import { combineReducers } from '@reduxjs/toolkit';
import { collections } from './subscriptionUsersListingUi/collections.ts';
import { filter } from './subscriptionUsersListingUi/filter.ts';
import { languages } from './subscriptionUsersListingUi/languages.ts';
import { roles } from './subscriptionUsersListingUi/roles.ts';
import { sortBy } from './subscriptionUsersListingUi/sortBy.ts';

export const subscriptionUsersListingUi = combineReducers({
  collections,
  filter,
  languages,
  roles,
  sortBy,
});
