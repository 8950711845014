import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { ICollection } from '../../../../data/models/collections/Collection.ts';
import { notInCollectionSelectItem } from '../constants/assetNullCollectionConstants.ts';

export const getCollectionItems = (
  collections: ReadonlyArray<ICollection>,
  includeNotInCollection: boolean,
): ReadonlyArray<IBaseSelectItem> => {
  const collectionItems = collections.map(
    (collection): IBaseSelectItem => ({
      id: collection.id,
      label: collection.name,
    }),
  );

  return includeNotInCollection ? [notInCollectionSelectItem, ...collectionItems] : collectionItems;
};
