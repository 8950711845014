import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { ILoadAssetsAction } from '../../../../../data/actions/thunks/assets/loadAssets.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { isAssetTypeElement } from '../../types/typeElementTypeGuards.ts';

export type ILoadAssetDefaultValueReferencesAction = (
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

type Deps = {
  readonly loadAssets: ILoadAssetsAction;
};

export const createLoadAssetDefaultValueReferencesAction =
  ({ loadAssets }: Deps): ILoadAssetDefaultValueReferencesAction =>
  (typeElements: ReadonlyArray<IBaseTypeElementData>, abortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const canViewAssets = currentUserHasCapabilities(state, Capability.ViewContent);

    if (!canViewAssets) {
      return;
    }

    const defaultValueAssetReferences = typeElements
      .filter(isAssetTypeElement)
      .flatMap((asset) => asset.defaultValue);

    await dispatch(loadAssets(defaultValueAssetReferences, abortSignal));
  };
