import Immutable from 'immutable';

export interface IAuditLogFilter {
  readonly selectedFromDate: DateTimeStamp;
  readonly selectedToDate: DateTimeStamp;
  readonly selectedObjectTypes: Immutable.Set<string>;
  readonly selectedActionTypes: Immutable.Set<string>;

  readonly searchPhrase: string;

  readonly isFromCalendarCleared: boolean;
  readonly isToCalendarCleared: boolean;

  readonly isFilterValid: boolean;
}

export const emptyAuditLogFilter: IAuditLogFilter = {
  selectedFromDate: '',
  selectedToDate: '',

  selectedObjectTypes: Immutable.Set(),
  selectedActionTypes: Immutable.Set(),

  searchPhrase: '',

  isFromCalendarCleared: true,
  isToCalendarCleared: true,

  isFilterValid: true,
};

export const areFiltersEqual = (x: IAuditLogFilter, y: IAuditLogFilter): boolean =>
  x.searchPhrase.trim() === y.searchPhrase.trim() &&
  x.selectedFromDate === y.selectedFromDate &&
  x.selectedToDate === y.selectedToDate &&
  Immutable.is(x.selectedActionTypes, y.selectedActionTypes) &&
  Immutable.is(x.selectedObjectTypes, y.selectedObjectTypes) &&
  x.isFromCalendarCleared === y.isFromCalendarCleared &&
  x.isToCalendarCleared === y.isToCalendarCleared &&
  x.isFilterValid === y.isFilterValid;

export const isFilterSet = (filterState: IAuditLogFilter): boolean => {
  return !areFiltersEqual(filterState, emptyAuditLogFilter);
};
