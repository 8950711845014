import { Action } from '../../../../../@types/Action.type.ts';
import {
  Taxonomy_Terms_TermDropped,
  Taxonomy_Terms_TermMoved,
  Taxonomy_Terms_TermPickedUp,
} from '../../constants/taxonomyActionTypes.ts';
import { IDraggedTermDiff } from '../../stores/ITaxonomyAppStoreState.type.ts';

const initialState: IDraggedTermDiff = {
  currentLeftSibling: null,
  currentParent: '',
  currentRightSibling: null,
  originalLeftSibling: null,
  originalParent: '',
};

export const draggedTermDiff = (
  state: IDraggedTermDiff = initialState,
  action: Action,
): IDraggedTermDiff => {
  switch (action.type) {
    case Taxonomy_Terms_TermPickedUp: {
      const { originalLeftSibling, originalParent } = action.payload;

      return {
        ...state,
        originalLeftSibling,
        originalParent,
      };
    }

    case Taxonomy_Terms_TermMoved: {
      const { currentLeftSibling, currentParent, currentRightSibling } = action.payload;

      return {
        ...state,
        currentLeftSibling,
        currentParent,
        currentRightSibling,
      };
    }

    case Taxonomy_Terms_TermDropped:
      return initialState;

    default:
      return state;
  }
};
