import { InvariantException } from '@kontent-ai/errors';
import { IPlanPaidMetricServerModel } from '../../../repositories/serverModels/PlanServerModel.type.ts';

export interface IPaidMetric {
  readonly name: string;
  readonly includedUnits: number;
  readonly extraPackagePrice: number;
  readonly extraPackageUnits: number;
}

export function createPaidMetric(props?: Partial<IPaidMetric>): IPaidMetric {
  return {
    name: '',
    includedUnits: 0,
    extraPackagePrice: 0,
    extraPackageUnits: 0,
    ...props,
  };
}

export enum MetricNames {
  SubscriptionMaxUsers = 'SubscriptionMaxUsers',
  SubscriptionActiveUsers = 'SubscriptionActiveUsers',
  ProjectCustomRolesCount = 'ProjectCustomRolesCount',
  ProjectContentItemsCount = 'ProjectContentItemsCount',
  ProjectContentVariantsCount = 'ProjectContentVariantsCount',
}

export function createPaidMetricFromServerModel(
  serverModel: IPlanPaidMetricServerModel,
): IPaidMetric {
  if (typeof serverModel !== 'object') {
    throw InvariantException('Paid metric is not an object');
  }

  const emptyObject = createPaidMetric();

  return {
    name: serverModel.name ?? emptyObject.name,
    includedUnits: serverModel.includedUnits ?? emptyObject.includedUnits,
    extraPackagePrice: serverModel.extraPackagePrice ?? emptyObject.extraPackagePrice,
    extraPackageUnits: serverModel.extraPackageUnits ?? emptyObject.extraPackageUnits,
  };
}
