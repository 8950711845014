import React, { createContext, useCallback, useMemo, useState } from 'react';
import { ListingCategory } from '../models/ProjectOverviewListingCategory.ts';

export type SelectedWorkflowStep = {
  readonly workflowId: Uuid;
  readonly stepId: Uuid;
};

interface ProjectOverviewPageContextProvidedValues {
  readonly isVisible: boolean;
  readonly viewedCategory: ListingCategory;
  readonly listingSelectedWorkflowStep: SelectedWorkflowStep | null;

  readonly changeCategory: (newCategory: ListingCategory) => void;
  readonly dismissCategory: () => void;
  readonly viewWorkflowStep: (workflowId: Uuid, stepId: Uuid) => void;
}

const defaultValue = {
  isVisible: false,
  viewedCategory: ListingCategory.None,
  listingSelectedWorkflowStep: null,
  changeCategory: () => undefined,
  viewWorkflowStep: () => undefined,
  dismissCategory: () => undefined,
};

export const ProjectOverviewPageContext =
  createContext<ProjectOverviewPageContextProvidedValues>(defaultValue);

export type ProjectOverviewPageContextProviderDispatchProps = {
  readonly onChangeCategory: (newView: string) => void;
};

export const ProjectOverviewPageContextProvider: React.FC<
  React.PropsWithChildren<ProjectOverviewPageContextProviderDispatchProps>
> = ({ onChangeCategory, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [viewedCategory, setViewedCategory] = useState(ListingCategory.None);
  const [listingSelectedWorkflowStep, setListingSelectedWorkflowStep] =
    useState<SelectedWorkflowStep | null>(null);

  const changeCategory = useCallback(
    (newCategory: ListingCategory) => {
      onChangeCategory(newCategory);
      setViewedCategory(newCategory);
      setListingSelectedWorkflowStep(null);
      setIsVisible(true);
    },
    [onChangeCategory],
  );

  const viewWorkflowStep = useCallback(
    (workflowId: Uuid, stepId: Uuid) => {
      onChangeCategory(ListingCategory.ByWorkflowStep);
      setViewedCategory(ListingCategory.ByWorkflowStep);
      setListingSelectedWorkflowStep({ workflowId, stepId });
      setIsVisible(true);
    },
    [onChangeCategory],
  );

  const dismissCategory = useCallback(() => setIsVisible(false), []);

  const context = useMemo(
    () => ({
      isVisible,
      listingSelectedWorkflowStep,
      viewedCategory,
      viewWorkflowStep,
      changeCategory,
      dismissCategory,
    }),
    [
      isVisible,
      listingSelectedWorkflowStep,
      viewedCategory,
      viewWorkflowStep,
      changeCategory,
      dismissCategory,
    ],
  );

  return (
    <ProjectOverviewPageContext.Provider value={context}>
      {children}
    </ProjectOverviewPageContext.Provider>
  );
};

ProjectOverviewPageContextProvider.displayName = 'ProjectOverviewPageContextProvider';
