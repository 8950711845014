import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import {
  Shared_TaxonomyTermsCollapsion_GroupCollapsed,
  Shared_TaxonomyTermsCollapsion_GroupExpanded,
  Shared_TaxonomyTermsCollapsion_TermToggled,
} from '../../constants/sharedActionTypes.ts';

export const collapsedTaxonomyTermIds = (
  state: ReadonlyMap<Uuid, ReadonlySet<Uuid>> = new Map(),
  action: Action,
): ReadonlyMap<Uuid, ReadonlySet<Uuid>> => {
  switch (action.type) {
    case Shared_TaxonomyTermsCollapsion_GroupCollapsed: {
      const { taxonomyGroup } = action.payload;
      return Collection.add(state, [taxonomyGroup.id, new Set(taxonomyGroup.childIds)]);
    }

    case Shared_TaxonomyTermsCollapsion_GroupExpanded: {
      const { taxonomyGroupId } = action.payload;
      return Collection.remove(state, taxonomyGroupId);
    }

    case Shared_TaxonomyTermsCollapsion_TermToggled: {
      const { taxonomyGroupId, termId } = action.payload;
      return Collection.add(state, [
        taxonomyGroupId,
        Collection.togglePresence(state.get(taxonomyGroupId) ?? new Set(), termId),
      ]);
    }

    default:
      return state;
  }
};
