import { ThunkFunction } from '../../../@types/Dispatcher.type.ts';
import { browserIsOnline } from '../../../_shared/actions/sharedActions.ts';

export const createHandleServerResponseAction =
  (request: XMLHttpRequest): ThunkFunction<any> =>
  (dispatch, getState) => {
    if (!getState().sharedApp.isOnline) {
      dispatch(browserIsOnline());
    }

    return request;
  };
