import {
  Duration,
  add,
  getHours,
  getMinutes,
  isMatch,
  isValid,
  parse,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';
import { format, fromZonedTime } from 'date-fns-tz';
import { millisecondsInMinute } from 'date-fns/constants';
import { cartesianMultiply, toArray } from '../../../app/_shared/utils/arrayUtils/arrayUtils.ts';
import { isEmptyOrWhitespace } from '../../../app/_shared/utils/stringUtils.ts';

export const isValidDateTime = isValid;

export interface IProcessedInput {
  readonly datetime: Date | null;
  readonly isValid: boolean;
}

export const DateTimeDefaultFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const DateReadableFormat = 'PP';
export const TimeReadableFormat = 'h:mm aaa';
const dateFormats = [
  'yyyy-MM-dd',
  'd.M.yyyy',
  'dd.MM.yyyy',
  'dd.MMM.yyyy',
  'MMM d, yyyy,',
  'P',
  'PP',
];
const timeFormats = [
  'h:mm aaaaa',
  'h:mm aaaa',
  'h:mm aaa',
  'h:mm aa',
  'h:mm a',
  'hh:mm aaaaa',
  'hh:mm aaaa',
  'hh:mm aaa',
  'hh:mm aa',
  'hh:mm a',
  'hh:mm',
  'HH:mm',
];
const datetimeFormats = cartesianMultiply(dateFormats, timeFormats)
  .map(([dateFormat, timeFormat]) => {
    return `${dateFormat} ${timeFormat}`;
  })
  .concat(dateFormats)
  .concat(timeFormats);

export const dateHasTimeSet = (datetime: Date | null): boolean =>
  !!datetime && (datetime.getHours() > 0 || datetime.getMinutes() > 0);

export const makeDate = (
  returnToUTC: boolean | undefined,
  input?: Date | string | number,
  formats: string | string[] = datetimeFormats,
): Date => {
  let date = new Date();
  const formatArray = toArray(formats);
  if (input) {
    if (typeof input === 'string') {
      if (input !== '') {
        date = new Date(input);
        // first we have to try our formats mainly because of "d.M.yyyy" format because
        // dates strings like "2.3.2000" going through new Date() takes first number as Month
        let foundFormatMatch = false;
        for (const dateFormat of formatArray) {
          if (isMatch(input, dateFormat)) {
            date = parse(input, dateFormat, new Date());
            foundFormatMatch = true;
            break;
          }
        }
        if (!foundFormatMatch && Date.parse(input)) {
          date = new Date(input);
        }
      }
    } else {
      date = new Date(input);
    }
  }

  if (returnToUTC) {
    return fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
  return date;
};

export const parseDatetime = (datetimeString: string, utc: boolean | undefined): Date => {
  if (datetimeString === '') {
    throw new Error('datetimeUtils.ts: Empty string cannot be parsed as a Date object');
  }

  return makeDate(utc, datetimeString, datetimeFormats);
};

export const parseTime = (timeString: string, utc: boolean | undefined): Date => {
  let time = timeString;
  // sanitize `nnn` input to '0nnn'
  if (/^\s*\d{3}([^\d]|$)/.test(timeString)) {
    time = `0${timeString}`;
  }

  return makeDate(utc, time, TimeReadableFormat);
};

export const getTodayWithStartTime = (utc: boolean | undefined): Date => {
  return startOfDay(makeDate(utc));
};

export const getTodayWithDefaultTime = (
  utc: boolean | undefined,
  defaultTime: string | undefined,
): Date | null => {
  const today = getTodayWithStartTime(utc);
  const datetime = setTimeInDatetimeObject(today, utc, '', defaultTime);

  return datetime;
};

export const formatDatetimeToDefault = (datetime: Date | null): string => {
  if (!datetime || !isValidDateTime(datetime)) {
    return '';
  }

  return format(datetime, DateTimeDefaultFormat);
};

export const formatDatetimeToReadable = (
  datetime: Date | null,
  withTime: boolean | undefined,
): string | null => {
  if (!datetime || !isValidDateTime(datetime)) {
    return null;
  }

  const formatStr = `${DateReadableFormat}${withTime ? `, ${TimeReadableFormat}` : ''}`;

  return format(datetime, formatStr);
};

export const setTimeInDatetimeObject = (
  datetime: Date | null,
  utc: boolean | undefined,
  timeInformation: string | null,
  defaultTime: string | undefined,
): Date | null => {
  if (datetime) {
    if (timeInformation) {
      const time = parseDatetime(timeInformation, utc);
      if (isValidDateTime(time)) {
        return setMinutes(setHours(datetime, getHours(time)), getMinutes(time));
      }
    }
    if (defaultTime) {
      const time = parseTime(defaultTime, utc);
      if (isValidDateTime(time)) {
        return setMinutes(setHours(datetime, getHours(time)), getMinutes(time));
      }
    }
  }

  return datetime;
};

export const processTimeInput = (
  timeInputString: string,
  value: string,
  defaultTime: string | undefined,
  utc: boolean | undefined,
): IProcessedInput => {
  const datetime = parseDatetime(value, utc);

  if (isValidDateTime(datetime)) {
    const adjustedDateTime = setTimeInDatetimeObject(datetime, utc, timeInputString, defaultTime);
    return {
      datetime: adjustedDateTime,
      isValid: isValidDateTime(adjustedDateTime),
    };
  }

  if (isEmptyOrWhitespace(timeInputString)) {
    return {
      datetime: getTodayWithStartTime(utc),
      isValid: true,
    };
  }

  return {
    datetime,
    isValid: isValidDateTime(datetime),
  };
};

export const convertToLocalizedDate = (date: Date, isUtc: boolean | undefined): Date =>
  isUtc ? new Date(date.getTime() + date.getTimezoneOffset() * millisecondsInMinute) : date;

export const isDatetimeWithinBounds = (
  value: Date | null,
  minValue: Date | undefined,
  maxValue: Date | undefined,
): boolean => {
  if (!value) {
    return false;
  }
  if (!!minValue && value < minValue) {
    return false;
  }
  if (!!maxValue && value > maxValue) {
    return false;
  }

  return true;
};

export const formatTimeToReadable = (datetime: Date | null): string | null => {
  if (!datetime || !isValidDateTime(datetime)) {
    return null;
  }

  return format(datetime, TimeReadableFormat);
};

export const incrementTime = (
  timeInputValue: string,
  increment: Duration,
  currentValue: string,
  utc: boolean | undefined,
): Date => {
  const date = parseDatetime(currentValue, utc);
  const time = parseTime(timeInputValue, true);
  const adjustedTime = add(time, increment);

  const datetime = setMinutes(setHours(date, getHours(adjustedTime)), getMinutes(adjustedTime));

  return datetime;
};

export const IncrementUpMinutes: Duration = {
  minutes: 5,
};

export const IncrementUpHours: Duration = {
  hours: 1,
};

export const IncrementDownMinutes: Duration = {
  minutes: -5,
};

export const IncrementDownHours: Duration = {
  hours: -1,
};
