import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import React from 'react';
import { documentationLinks } from '../../../../../../../_shared/constants/documentationLinks.ts';
import { AiErrorCode } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import { GenericDescribeWithAiError } from '../../../../utils/imageDescriptionAiErrors.ts';

interface IProps {
  readonly dismissError: () => void;
  readonly errorCode: AiErrorCode;
}

const InvalidDataError = () => (
  <>
    The AI couldn’t describe the image due to its format or size. Use an{' '}
    <OutwardLink className="asset-ai-error" href={documentationLinks.aiImageAnalysisRequirements}>
      AI-compatible version of the image
    </OutwardLink>{' '}
    or describe the image manually.
  </>
);

export const DescribeWithAiError: React.FC<IProps> = ({ dismissError, errorCode }) => (
  <NotificationBarAlert onDismiss={dismissError}>
    {errorCode === AiErrorCode.InvalidData ? <InvalidDataError /> : GenericDescribeWithAiError}
  </NotificationBarAlert>
);

DescribeWithAiError.displayName = 'DescribeWithAiError';
