import { getScrollParent } from '@kontent-ai/DOM';
import { getDomSelection } from '../../../../../_shared/utils/selectionUtils.ts';

const getCaretClientTop = (): number | null => {
  const selection = getDomSelection();
  if (!selection) {
    return null;
  }

  const { anchorOffset, anchorNode, focusOffset, focusNode } = selection;

  if (!anchorNode || !focusNode) {
    return null;
  }

  const rect = selection.range?.getBoundingClientRect();
  if (!rect) {
    return null;
  }

  const range = document.createRange();
  range.setStart(anchorNode, anchorOffset);
  range.setEnd(focusNode, focusOffset);

  const isReversed = range.collapsed;

  return isReversed ? rect.top : rect.bottom;
};

export const keepCaretPositionInViewport = async <TResult>(
  elementRef: React.RefObject<HTMLDivElement>,
  action: () => Promise<TResult>,
) => {
  const caretTopBefore = getCaretClientTop();

  await action();

  const caretTopAfter = getCaretClientTop();
  if (caretTopBefore === null || caretTopAfter === null) {
    return;
  }

  const scrollParent = getScrollParent(elementRef.current);
  const deltaToOriginalPosition = caretTopAfter - caretTopBefore;

  scrollParent.scrollTop += deltaToOriginalPosition;
};
