import { Box } from '@kontent-ai/component-library/Box';
import { spacingMainLayoutLeft } from '@kontent-ai/component-library/tokens';
import React, { useEffect, useRef, useState } from 'react';
import { FullScreenModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { stringifyContentItemId } from '../../../../_shared/models/utils/contentItemIdUtils.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getItemListForScrollTable } from '../../../../data/reducers/listingContentItems/selectors/getItemListForScrollTable.ts';
import { isItemsInitialLoadFinished } from '../../../../data/reducers/listingContentItems/selectors/isItemsInitialLoadFinished.ts';
import { clearContentItemListingFilter } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { EnsureInventoryRelatedData } from '../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import {
  initializeContentStatus,
  loadListingContentItemsForContentStatus,
} from '../actions/thunkContentStatusActions.ts';
import { ContentStatusCommentsTable } from '../containers/ContentStatusCommentsTable.tsx';
import { ContentStatusScrollTableEmptyState } from '../containers/ContentStatusScrollTable/ContentStatusScrollTableEmptyState.tsx';
import { ContentStatusScrollTableHead } from '../containers/ContentStatusScrollTable/ContentStatusScrollTableHead.tsx';
import { ContentStatusScrollTableRow } from '../containers/ContentStatusScrollTable/ContentStatusScrollTableRow.tsx';
import { ContentStatusTasksTable } from '../containers/ContentStatusTasksTable.tsx';
import { LanguageFilterContextProvider } from '../contexts/LanguageFilter.tsx';
import { ContentStatusFilter } from './ContentStatusFilter/ContentStatusFilter.tsx';

type DetailType = 'tasks' | 'comments';

type OpenDetail = Readonly<{
  type: DetailType;
  contentItemId: ContentItemId;
  contentItemName: string;
}>;

const getItemListSelector = (state: IStore) =>
  getItemListForScrollTable(
    state.data.contentStatusContentItems,
    getNumberOfItemsInViewport(state.contentApp.listingUi.contentItemListingScrollTableState),
  );

export const ContentStatusTabContent: React.FC = () => {
  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState<OpenDetail | null>(null);
  const contentPaneRef = useRef<HTMLDivElement>(null);
  const items = useSelector(getItemListSelector);
  const shouldRenderScrollTable = useSelector((s) =>
    isItemsInitialLoadFinished(s.data.contentStatusContentItems),
  );
  const listingItemsLoadingStatus = useSelector(
    (s) => s.data.contentStatusContentItems.loadingStatus,
  );

  const openDetailModal = (type: DetailType, item: IListingContentItem) => {
    if (!item.variant?.id) {
      return;
    }

    setOpenDetail({
      type,
      contentItemId: item.variant?.id,
      contentItemName: item.item.name,
    });
  };

  const closeDialog = () => {
    setOpenDetail(null);
  };

  useEffect(() => {
    dispatch(initializeContentStatus());
    dispatch(trackUserEvent(TrackedEvent.ContentStatusOpened));
  }, []);

  return (
    <>
      <Box className="canvas" paddingLeft={spacingMainLayoutLeft}>
        <div className="canvas__workspace">
          <EnsureInventoryRelatedData>
            <LanguageFilterContextProvider>
              <ContentStatusFilter
                clearFilter={() => dispatch(clearContentItemListingFilter())}
                listingItemsLoadingStatus={listingItemsLoadingStatus}
              />
              <div className="canvas__content">
                <div
                  ref={contentPaneRef}
                  className="canvas__content-pane canvas__content-pane--no-bottom-offset canvas__content-pane--without-vertical-scrollbar"
                  {...getDataUiAppNameAttribute(DataUiAppName.ContentStatus)}
                >
                  <HtmlPageTitle appName={AppNames.ContentStatus} />
                  {shouldRenderScrollTable ? (
                    <ContentItemScrollTable
                      ariaLabel="Content status"
                      items={items}
                      onLoadContentItems={loadListingContentItemsForContentStatus({
                        scrollPositionChanged: true,
                      })}
                      parentContainerRef={contentPaneRef}
                      renderEmptyState={() => (
                        <ContentStatusScrollTableEmptyState isInDialog={false} />
                      )}
                      renderRowItem={(params) => {
                        const contentItemId: ContentItemId = params.item.variant?.id ?? {
                          itemId: params.item.item.id,
                          variantId: DefaultVariantId,
                        };
                        return (
                          <ContentStatusScrollTableRow
                            key={stringifyContentItemId(contentItemId)}
                            index={params.index}
                            item={params.item}
                            onTaskButtonClick={() => openDetailModal('tasks', params.item)}
                            onCommentButtonClick={() => openDetailModal('comments', params.item)}
                          />
                        );
                      }}
                      renderTableHead={() => <ContentStatusScrollTableHead />}
                      renderTableTitle={() => null}
                      withColumnSettings
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </LanguageFilterContextProvider>
          </EnsureInventoryRelatedData>
        </div>
      </Box>

      <FullScreenModalDialog
        headline={`${openDetail?.type === 'tasks' ? 'Tasks' : 'Comments'} in ${openDetail?.contentItemName}`}
        isDismissable
        isOpen={!!openDetail}
        onClose={closeDialog}
      >
        {openDetail?.type === 'tasks' && (
          <ContentStatusTasksTable
            itemId={openDetail.contentItemId.itemId}
            variantId={openDetail.contentItemId.variantId}
          />
        )}

        {openDetail?.type === 'comments' && (
          <ContentStatusCommentsTable
            itemId={openDetail.contentItemId.itemId}
            variantId={openDetail.contentItemId.variantId}
          />
        )}
      </FullScreenModalDialog>
    </>
  );
};

ContentStatusTabContent.displayName = 'ContentStatusTabContent';
