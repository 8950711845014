/* eslint-disable @typescript-eslint/no-base-to-string */
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

export const EmptyStateContent = ({ children }: Props) => {
  return (
    <Stack
      align="center"
      spacing={Spacing.L}
      css={`
        ${Typography.UIParagraph}
        text-align: center;
      `}
    >
      {children}
    </Stack>
  );
};
