import { PredefinedRoles } from '../../../applications/environmentSettings/roles/constants/predefinedRoles.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { DefaultCollectionId, DefaultVariantId } from '../../constants/variantIdValues.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from './getContributorRole.ts';

export const isProjectManager = (state: IStore): boolean =>
  isUserProjectManager(state.data.user, getCurrentProject(state).projectId);

export const isUserProjectManager = (user: IUser, projectId: Uuid): boolean =>
  getCurrentUserRoleForCollectionForLanguageForUser(
    user,
    projectId,
    DefaultCollectionId,
    DefaultVariantId,
  )?.codename === PredefinedRoles.ProjectManager;
