import { filterContentTypesByCapability } from '../../../applications/contentModels/shared/utils/typeUtils.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { getNormalizedRolesWithSettingsForUser } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { Capability } from './capability.ts';
import { getAllApplicableRolesForLanguage } from './getContributorRole.ts';

export const getContentTypesWithViewCapability = (
  projectId: Uuid,
  languageId: Uuid,
  user: IUser,
  allContentTypes: ReadonlyArray<IContentType>,
): ReadonlyArray<IContentType> => {
  const userRoles = getNormalizedRolesWithSettingsForUser(user, projectId);
  const rolesForLanguage = getAllApplicableRolesForLanguage(userRoles.collectionGroups, languageId);

  const typesWithCapability = rolesForLanguage.flatMap((role) => {
    return filterContentTypesByCapability(allContentTypes, role.settings, Capability.ViewContent);
  });

  return [...new Set(typesWithCapability)];
};
