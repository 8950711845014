import React, { memo } from 'react';
import {
  EditorProps,
  useEditor,
  usePluginComposition,
} from '../../../../editorCore/Editor.composition.tsx';
import { useComposition as useViewerComposition } from '../../../../editors/richText/RichTextViewer.tsx';
import { UnlimitedEditorLimitations } from '../../../apiLimitations/api/EditorFeatureLimitations.ts';
import { textKeyCommandMap } from '../../../keyboardShortcuts/api/editorKeyboardUtils.ts';
import { BorderlessPlugin } from '../../../visuals/BorderlessPlugin.tsx';

export const useComposition = () => usePluginComposition(useViewerComposition(), BorderlessPlugin);

export type RichAiSuggestionViewerProps = EditorProps<typeof useComposition>;

export const RichAiSuggestionViewer: React.FC<RichAiSuggestionViewerProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    keyCommandMap: textKeyCommandMap,
    limitations: UnlimitedEditorLimitations,
    ...props,
  });
});

RichAiSuggestionViewer.displayName = 'RichAiSuggestionViewer';
