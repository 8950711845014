import { Callout } from '@kontent-ai/component-library/Callout';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { RectangleSkeleton } from '@kontent-ai/component-library/Skeleton';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ILanguage } from '../../../data/models/languages/Language.ts';
import { IListingVariantData } from '../../../data/models/listingContentItems/IListingVariantData.ts';
import { isVariantPublished } from '../../utils/contentItemVariantUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithoutCount } from '../../utils/stringUtils.ts';

const SummarySkeleton: React.FC = () => (
  <Stack spacing={Spacing.S} {...getDataUiElementAttribute(DataUiElement.Skeleton)}>
    <RectangleSkeleton width="100%" height={14} />
    <RectangleSkeleton width="100%" height={14} />
    <RectangleSkeleton width="60%" height={14} />
  </Stack>
);

SummarySkeleton.displayName = 'SummarySkeleton';

const SummaryCallout: React.FC<{
  readonly renderContent: () => React.ReactElement;
}> = ({ renderContent }) => (
  <Callout
    calloutType="quickTip"
    hideSubheadline
    {...getDataUiElementAttribute(DataUiElement.LinkedContentFallbackSummary)}
  >
    {renderContent()}
  </Callout>
);

SummaryCallout.displayName = 'SummaryCallout';

const labelSize = LabelSize.L;

export type LinkedContentFallbackSummaryProps = {
  readonly itemName: string;
  readonly isSelectedLanguageTranslated: boolean;
  readonly activeLanguages: ReadonlyArray<ILanguage>;
  readonly translatedVariants: ReadonlyArray<IListingVariantData> | null;
  readonly fallbackLanguageId: Uuid | null;
  readonly selectedLanguageId: Uuid;
};

export const LinkedContentFallbackSummary: React.FC<LinkedContentFallbackSummaryProps> = ({
  itemName,
  isSelectedLanguageTranslated,
  activeLanguages,
  translatedVariants,
  fallbackLanguageId,
  selectedLanguageId,
}) => {
  if (translatedVariants === null) {
    return <SummaryCallout renderContent={() => <SummarySkeleton />} />;
  }

  if (!translatedVariants.length) {
    return (
      <SummaryCallout
        renderContent={() => (
          <Label size={labelSize}>
            The item <strong>{itemName}</strong> isn’t translated to any of the currently active
            languages. Someone might have deleted the item or deactivated a language. Refresh to
            view the latest content.
          </Label>
        )}
      />
    );
  }

  const fallbackLanguageName =
    activeLanguages.find((l) => l.id === fallbackLanguageId)?.name ?? null;
  const selectedLanguageName = activeLanguages.find((l) => l.id === selectedLanguageId)?.name;

  if (!fallbackLanguageName) {
    return (
      <SummaryCallout
        renderContent={() => (
          <Label size={labelSize}>
            The item <strong>{itemName}</strong> isn’t translated to {selectedLanguageName}
            &nbsp;and doesn’t fall back to any language due to recent changes. To view the item’s
            current fallback languages, refresh the page.
          </Label>
        )}
      />
    );
  }

  if (isSelectedLanguageTranslated) {
    return (
      <SummaryCallout
        renderContent={() => (
          <Label size={labelSize}>
            Someone modified the item <strong>{itemName}</strong>. To view the item’s language
            fallbacks, refresh the page.
          </Label>
        )}
      />
    );
  }

  const publishedTranslatedVariants = translatedVariants.filter(isVariantPublished);
  const isFallbackVariantPublished = publishedTranslatedVariants.find(
    (v) => v.id.variantId === fallbackLanguageId,
  );

  if (isFallbackVariantPublished) {
    return (
      <SummaryCallout
        renderContent={() => (
          <Label size={labelSize}>
            The item <strong>{itemName}</strong> isn’t translated to {selectedLanguageName}, but is
            published in {publishedTranslatedVariants.length} other{' '}
            {pluralizeWithoutCount('language', publishedTranslatedVariants.length)}. Based on your
            localization settings, the item’s language falls back to {fallbackLanguageName}.
          </Label>
        )}
      />
    );
  }

  return (
    <SummaryCallout
      renderContent={() => (
        <Label size={labelSize}>
          The item <strong>{itemName}</strong> isn’t translated to {selectedLanguageName}, but it’s
          available in {translatedVariants.length} other{' '}
          {pluralizeWithoutCount('language', translatedVariants.length)}. Based on your localization
          settings, the item’s language falls back to {fallbackLanguageName}. To display the item on
          the web, make sure to publish the {fallbackLanguageName} variant.
        </Label>
      )}
    />
  );
};

LinkedContentFallbackSummary.displayName = 'LinkedContentFallbackSummary';
