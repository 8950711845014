import { Box } from '@kontent-ai/component-library/Box';
import { AnimatedProgressIcon } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps, PropsWithChildren } from 'react';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';

type FeatureActivationDialogProps = {
  readonly isActivating: boolean;
  readonly onActivate?: () => void;
} & Omit<ComponentProps<typeof ModalDialog>, 'isDismissable'>;

export const FeatureActivationDialog: React.FC<PropsWithChildren<FeatureActivationDialogProps>> = ({
  children,
  isActivating,
  onActivate,
  primaryAction,
  ...restProps
}) => {
  return (
    <ModalDialog
      primaryAction={
        primaryAction ?? {
          text: isActivating ? 'Activating' : 'Agree and Activate',
          disabled: isActivating,
          onClick: onActivate,
          iconBefore: isActivating ? AnimatedProgressIcon : undefined,
        }
      }
      isDismissable={!isActivating}
      {...restProps}
    >
      <Box maxWidth={800}>{children}</Box>
    </ModalDialog>
  );
};
