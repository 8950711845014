import { getNodesClosestParentElement, getSelfOrParent } from '@kontent-ai/DOM';
import classNames from 'classnames';
import { DraftEditorLeafs, DraftInlineStyle } from 'draft-js';
import Immutable from 'immutable';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getDataAttribute } from '../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { DataDraftJsAttributes } from '../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { getNativeDomSelection } from '../../../../../_shared/utils/selectionUtils.ts';
import { DraftJSTextNodeSelector } from '../../../components/utility/RichTextHighlighter.tsx';
import { isAtomicEntity } from '../../textApi/api/editorTextUtils.ts';
import { EntityDecoratorProps } from '../api/editorEntityUtils.ts';

let lastInCaretPointBefore: HTMLElement | null = null;
let lastInCaretPointAfter: HTMLElement | null = null;

const caretPointClassname = 'caret-point';
const caretPointBeforeClassname = 'caret-point-before';
const caretPointAfterClassname = 'caret-point-after';

export function moveToEndOf(element: Element): boolean {
  const textElement = element.querySelector<HTMLSpanElement>(DraftJSTextNodeSelector);
  if (!textElement) {
    return false;
  }

  const textNode = textElement.lastChild;
  if (!textNode || textNode.nodeType !== document.TEXT_NODE) {
    return false;
  }

  const selection = getNativeDomSelection();
  if (selection) {
    selection.removeAllRanges();
    const range = document.createRange();
    range.setStart(textNode, (textNode.textContent || '').length);
    selection.addRange(range);

    return true;
  }

  return false;
}

export function moveToStartOf(subtreeRoot: Element): boolean {
  const textElement = subtreeRoot.querySelector<HTMLSpanElement>(DraftJSTextNodeSelector);
  if (!textElement) {
    return false;
  }

  const textNode = textElement.lastChild;
  if (!textNode || textNode.nodeType !== document.TEXT_NODE) {
    return false;
  }

  const selection = getNativeDomSelection();
  if (selection) {
    selection.removeAllRanges();
    const range = document.createRange();
    range.setStart(textNode, 0);
    selection.addRange(range);

    return true;
  }

  return false;
}

const handleCaretMovement = () => {
  const selection = getNativeDomSelection();
  const closestParentElement = getNodesClosestParentElement(selection?.anchorNode ?? null);
  const caretPoint =
    closestParentElement &&
    getSelfOrParent(closestParentElement, (element) =>
      element.classList.contains(caretPointClassname),
    );
  if (!caretPoint) {
    return;
  }
  if (caretPoint.classList.contains(caretPointBeforeClassname)) {
    if (selection?.anchorOffset !== 0) {
      if (lastInCaretPointBefore === caretPoint) {
        lastInCaretPointBefore = null;
        // Selection moved from caret point before to the right - Move the caret after the entity
        const afterEntity = caretPoint.nextElementSibling?.nextElementSibling;
        if (afterEntity) {
          if (afterEntity.classList.contains(caretPointAfterClassname)) {
            // Move to the caret point after
            moveToEndOf(afterEntity);
          } else {
            // Move to the standard content after
            moveToStartOf(afterEntity);
          }
          return;
        }
      }

      // When the caret is before the entity, it must be kept at the start so that new characters are typed before the entity
      moveToStartOf(caretPoint);
      lastInCaretPointBefore = caretPoint;
    } else {
      lastInCaretPointBefore = caretPoint;
    }
    return;
  }

  lastInCaretPointBefore = null;

  if (caretPoint.classList.contains(caretPointAfterClassname)) {
    if (selection?.anchorOffset === 0) {
      if (lastInCaretPointAfter === caretPoint) {
        lastInCaretPointAfter = null;
        // Selection moved from caret point before to the left - Move the caret before the entity
        const beforeEntity = caretPoint.previousElementSibling?.previousElementSibling;
        if (beforeEntity) {
          if (beforeEntity.classList.contains(caretPointBeforeClassname)) {
            // Move to the caret point before
            moveToStartOf(beforeEntity);
          } else {
            // Move to the standard content before
            moveToEndOf(beforeEntity);
          }
          return;
        }
      }

      // When the caretPoint is after the entity, it must be kept at the end so that new characters are typed after the entity
      moveToEndOf(caretPoint);
      lastInCaretPointAfter = caretPoint;
    } else {
      lastInCaretPointAfter = caretPoint;
    }
    return;
  }

  lastInCaretPointAfter = null;
};

let mountedInstances = 0;

function mounted() {
  if (mountedInstances === 0) {
    mountedInstances = 1;

    // Observe DOM selection, and correct placement of cursor
    document.addEventListener('selectionchange', handleCaretMovement);
  } else {
    mountedInstances++;
  }
}

function unmounted() {
  mountedInstances--;
  if (mountedInstances <= 0) {
    // Cleanup after last
    document.removeEventListener('selectionchange', handleCaretMovement);
  }
}

// We assume that the children given to this method are children given to the decorator. Which should be an array of DraftEditorLeaf
// It could use some more robust type check / review if there aren't edge cases that could violate it
// We clone the children adjusting their props, because DraftEditorLeaf has built in support to update DOM selection after mount/change
// and in case this isn't included, the caret position doesn't update properly after changes to the editor
export const updateLeafNodes = (
  children: DraftEditorLeafs,
  getUpdatedProps: (props: AnyObject) => AnyObject,
): DraftEditorLeafs => {
  return React.Children.map(children, (child) => {
    return React.cloneElement(child, { ...child.props, ...getUpdatedProps(child.props) });
  });
};

// We convert the child content (which should be a single character) to zero-width non-joiner ( &zwnj; ) to hide the original presentation
// but keep the character for caret placement in caret point
const zeroWidthText = '\u200C';

// In caret point after, we use hair space ( &hairsp; ) because we need character with some actual width
// otherwise upon placing caret by clicking the mouse lands always at the start of the node (as zero width character start and end overlap)
// but we need it to land at the end to prevent jumping the caret before the entity
const hairSpaceText = '\u200A';

const CaretPoint = styled.span<{
  readonly isBetweenEntities?: boolean;
}>`
  position: relative;
  padding-left: ${(props) => (props.isBetweenEntities ? '4px' : '0')};

  &::before {
    position: absolute;
    left: -2px;
    top: 0;
    bottom: 0;
    right: -2px;
    content: "";
    cursor: text;
    z-index: 1;
  }
`;

type AtomicEntityProps = EntityDecoratorProps & {
  readonly renderContent: (content: DraftEditorLeafs) => React.ReactNode;
};

const noStyles: DraftInlineStyle = Immutable.OrderedSet();

// This is a wrapper for atomic (single char) entities
// It automatically ensures caret handling around them, as the browser caret support doesn't work great when there is non-editable element
// at the start or the end of the block.
// The content rendered with renderContent should have contenteditable=false and data-offset-key rendered in the top element
//
// We don't use immutable entities provided by DraftJS for several reasons
// 1) Our API doesn't handle them as immutable (neither do default DraftJS modifiers)
// 2) Presentation of the entities would have to include their original text (and maybe nothing more), which is too limiting
// 3) They might not act atomic enough in complex processes in the future, such as collaborative editing
export const AtomicEntity: React.FC<AtomicEntityProps> = ({
  blockKey,
  children,
  contentState,
  end,
  offsetKey,
  renderContent,
  start,
}) => {
  const block = contentState.getBlockForKey(blockKey);
  const isFirst = start === 0;
  const isLast = end === block.getLength();

  const entityKeyAfter = isLast ? undefined : block.getEntityAt(end);
  const isAtomicEntityAfter =
    !!entityKeyAfter && isAtomicEntity(contentState.getEntity(entityKeyAfter));

  useEffect(() => {
    mounted();
    return unmounted;
  }, []);

  // When the entity is at the start of the block
  // we need to provide a proper place for caret before it as the entity content is expected to be non-editable
  // We replicate the content with ZWNJ and make sure that caret always stays at the start of it for proper typing behavior before the entity
  const caretLeafsBefore =
    isFirst &&
    updateLeafNodes(children, ({ selection, forceSelection }) => {
      const useSelection = !!selection?.hasEdgeWithin(blockKey, start, start);

      return {
        styleSet: noStyles,
        text: zeroWidthText,
        // DOM selection is handled by this caret point only when at the exact point at the start of the entity
        // See _setSelection method of DraftEditorLeaf
        selection: useSelection ? selection : undefined,
        forceSelection: useSelection && !!forceSelection,
      };
    });
  const before = caretLeafsBefore && (
    <CaretPoint
      className={classNames(caretPointClassname, caretPointBeforeClassname)}
      {...getDataAttribute(DataDraftJsAttributes.OffsetKey, offsetKey)}
    >
      {caretLeafsBefore}
    </CaretPoint>
  );

  // When the entity is at the end of the block or just before another atomic entity
  // we need to provide a proper place for caret after it as the entity content is expected to be non-editable
  // We replicate the content with ZWNJ and make sure that caret always stays at the end of it for proper typing behavior after the entity
  const caretLeafsAfter =
    (isLast || isAtomicEntityAfter) &&
    updateLeafNodes(children, ({ selection, forceSelection }) => {
      const useSelection = !!selection?.hasEdgeWithin(blockKey, end, end);

      return {
        styleSet: noStyles,
        text: hairSpaceText,
        // DOM selection is handled by this caret point only when at the exact point at the end of the entity
        // See _setSelection method of DraftEditorLeaf
        selection: useSelection ? selection : undefined,
        forceSelection: useSelection && !!forceSelection,
      };
    });
  const after = caretLeafsAfter && (
    <CaretPoint
      className={classNames(caretPointClassname, caretPointAfterClassname)}
      isBetweenEntities={isAtomicEntityAfter}
      {...getDataAttribute(DataDraftJsAttributes.OffsetKey, offsetKey)}
    >
      {caretLeafsAfter}
    </CaretPoint>
  );

  return (
    <>
      {before}
      {renderContent(
        updateLeafNodes(children, () => ({
          styleSet: noStyles,
          text: zeroWidthText,
          // This content is non-editable, so it never updates the DOM selection. It is updated either by caret points, or the standard content around
          // See _setSelection method of DraftEditorLeaf
          selection: null,
          forceSelection: false,
        })),
      )}
      {after}
    </>
  );
};

AtomicEntity.displayName = 'AtomicEntity';
