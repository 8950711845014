import { Action } from '../../../../../@types/Action.type.ts';
import { Sitemap_FinishSaving, Sitemap_StartSaving } from '../../constants/sitemapActionTypes.ts';

export const savingSitemap = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Sitemap_StartSaving:
      return true;

    case Sitemap_FinishSaving:
      return false;

    default:
      return state;
  }
};
