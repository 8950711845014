import { Column, Row } from '@kontent-ai/component-library/Row';
import React, { ComponentProps, ReactElement } from 'react';
import styled from 'styled-components';
import { BreadcrumbsContextProvider } from './BreadcrumbsContext.tsx';
import { BreadcrumbsCell } from './components/BreadcrumbsCell.tsx';
import { BreadcrumbsItems } from './components/BreadcrumbsItems.tsx';

export interface IBreadcrumbsProps {
  readonly children:
    | ReactElement<ComponentProps<typeof Column>>
    | Array<ReactElement<ComponentProps<typeof Column>>>;
}

const StyledBreadcrumbItemsWrapper = styled.div`
  display: inline-flex;
  min-width: 0;
`;

const StyledRow = styled(Row)`
  flex: 1 1 auto;
  min-width: 0;
`;

export const BreadcrumbsComponent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IBreadcrumbsProps>
>(({ children, ...otherProps }, forwardedRef) => (
  <BreadcrumbsContextProvider>
    <StyledBreadcrumbItemsWrapper ref={forwardedRef} {...otherProps}>
      <StyledRow alignY="center" noWrap>
        {children}
      </StyledRow>
    </StyledBreadcrumbItemsWrapper>
  </BreadcrumbsContextProvider>
));

BreadcrumbsComponent.displayName = 'Breadcrumbs';

const breadcrumbsComposition = {
  Items: BreadcrumbsItems,
  Cell: BreadcrumbsCell,
} as const;

export const Breadcrumbs = Object.assign(BreadcrumbsComponent, breadcrumbsComposition);
