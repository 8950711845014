import { InputState } from '@kontent-ai/component-library/Input';
import { ItemId } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { forwardRef, useCallback } from 'react';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { LanguageFallbackOption } from '../../models/LanguageFallbackOption.type.ts';

type Props = {
  readonly disabled: boolean;
  readonly fallbackLanguageOptions: ReadonlyArray<LanguageFallbackOption>;
  readonly onChange: (value: Uuid | null) => void;
  readonly value: Uuid | null;
};

export const FallbackLanguageDropdown = forwardRef<HTMLDivElement, Props>(
  ({ disabled, fallbackLanguageOptions, onChange, value }, ref) => {
    const propagateOnChange = useCallback(
      (id: ItemId | null): void => {
        onChange(id === DefaultVariantId ? null : id);
      },
      [onChange],
    );

    return (
      <SingleSelect
        ref={ref}
        items={fallbackLanguageOptions}
        label="Falls back to"
        inputState={disabled ? InputState.Disabled : InputState.Default}
        onSelectionChange={propagateOnChange}
        selectedItemId={value ?? DefaultVariantId}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(
          DataUiCollection.LanguageFallbackOptions,
        )}
      />
    );
  },
);

FallbackLanguageDropdown.displayName = 'FallbackLanguageDropdown';
