import {
  loadAssetFolders,
  loadListingAssets,
} from '../../../../../../data/actions/thunkDataActions.ts';
import { createInitializeAssetDialogAction } from './thunks/initializeAssetDialog.ts';

export const initializeAssetDialog = createInitializeAssetDialogAction({
  loadAssetFolders,
  loadListingAssets,
});
