import { EditedContentItemVariant } from '../../applications/itemEditor/models/contentItem/edited/EditedContentItemVariant.ts';
import { PublishingState } from '../../data/constants/PublishingState.ts';
import { IWorkflowStep } from '../../data/models/workflow/WorkflowStep.ts';
import { isPublishedWorkflowStep, isPublishedWorkflowStepSelected } from './contentItemUtils.ts';

export enum VariantCompletionStatus {
  notTranslated = 'NonTranslated',
  unfinished = 'Unfinished',
  ready = 'Ready',
  allDone = 'AllDone',
}

export type ContentItemVariantStateData = Pick<
  EditedContentItemVariant,
  'assignment' | 'isComplete' | 'isArchived'
>;

export type ContentItemVariantPublishingData = {
  readonly publishingState: PublishingState;
  readonly workflowStatus: IWorkflowStep;
};

export const isNewVersion = (data: ContentItemVariantPublishingData): boolean =>
  isVariantPublished(data) && !isPublishedWorkflowStep(data.workflowStatus);

export function isVariantPublished(
  variant: Pick<EditedContentItemVariant, 'publishingState'> | null,
): boolean {
  if (!variant) {
    return false;
  }
  return variant.publishingState === PublishingState.Published;
}

export function isVariantArchived(
  variant: Pick<EditedContentItemVariant, 'isArchived'> | null,
): boolean {
  if (!variant) {
    return false;
  }
  return variant.isArchived.valueOf();
}

export function getContentVariantState(
  variant?: ContentItemVariantStateData | null,
): VariantCompletionStatus {
  if (!variant) {
    return VariantCompletionStatus.notTranslated;
  }

  if (variant.isArchived) {
    return VariantCompletionStatus.notTranslated;
  }

  if (!variant.isComplete) {
    return VariantCompletionStatus.unfinished;
  }

  return isPublishedWorkflowStepSelected(variant.assignment)
    ? VariantCompletionStatus.allDone
    : VariantCompletionStatus.ready;
}
