import React from 'react';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  DialogPosition,
  ToggleableWebhookMessageDialog,
} from './ToggleableWebhookMessageDialog.tsx';
import { WebhookMessageDialogTrigger } from './WebhookMessageDialogTrigger.tsx';

interface IWebhookMessageContentButtonProps {
  readonly content: string;
  readonly isLoading?: boolean;
  readonly onClick: () => void;
}

export const WebhookMessageContentButton: React.FC<IWebhookMessageContentButtonProps> = ({
  content,
  isLoading,
  onClick,
}) => (
  <ToggleableWebhookMessageDialog
    content={content}
    dataUiAction={DataUiAction.ShowWebhookMessageContent}
    dialogPosition={DialogPosition.Left}
    isLoading={isLoading}
    labelText="Webhook notification content"
    trigger={(triggerProps) => <WebhookMessageDialogTrigger {...triggerProps} onClick={onClick} />}
  />
);

WebhookMessageContentButton.displayName = 'WebhookMessageContentButton';
