import { notNull } from '@kontent-ai/utils';
import { MultipleChoiceMode } from '../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { IMultipleChoiceOptionData } from '../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  MultipleChoiceModeServerModel,
  MultipleChoiceTypeElementServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  IMultipleChoiceTypeElementData,
  getMultipleChoiceTypeElementDataFromServerModel,
} from '../../models/elements/MultipleChoiceTypeElementData.ts';
import { hasMultipleChoiceDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';

function convertTypeElementRecordToServerModel(
  typeElement: IMultipleChoiceTypeElementData,
): MultipleChoiceTypeElementServerModel {
  const options = typeElement.optionsOrder
    .map((optionId: Uuid) => typeElement.options[optionId] ?? null)
    .filter(notNull)
    .map((option: IMultipleChoiceOptionData) => ({
      id: option.id,
      name: option.label,
      codeName: option.codename,
    }));

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    default: hasMultipleChoiceDefaultValue(typeElement)
      ? { global: { value: typeElement.defaultValue } }
      : null,
    mode:
      typeElement.mode === MultipleChoiceMode.Single
        ? MultipleChoiceModeServerModel.Single
        : MultipleChoiceModeServerModel.Multiple,
    options,
    type: ElementType.MultipleChoice,
  };
}

export function createMultipleChoiceTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (
      serverTypeElement: MultipleChoiceTypeElementServerModel,
    ) => getMultipleChoiceTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IMultipleChoiceTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
