import { useCallback } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedLanguageCodename } from '../../../_shared/selectors/getSelectedLanguageCodename.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { startPreviewApiChangesPolling } from '../actions/thunkWebSpotlightActions.ts';
import { useWebSpotlight } from '../context/WebSpotlightContext.tsx';
import {
  isPreviewAutoRefreshFeatureAvailable,
  isPreviewAutoRefreshFeatureEnabledByUser,
} from '../selectors/webSpotlightSelectors.ts';
import { useIsPreviewUrlOutdated } from './useIsPreviewUrlOutdated.ts';

export function usePreviewApiPolling(): void {
  const currentProjectId = useSelector(getCurrentProjectId);
  const selectedLanguageCodename = useSelector(getSelectedLanguageCodename);
  const isAutoRefreshFeatureEnabled = useSelector(isPreviewAutoRefreshFeatureEnabledByUser);

  const { refreshPreview, supportedSmartLinkFeatures } = useWebSpotlight();

  const canPoll = useSelector(
    (s) =>
      isPreviewAutoRefreshFeatureAvailable(s) &&
      !!supportedSmartLinkFeatures?.refreshHandler &&
      !!s.webSpotlightApp.lastModifiedPreviewItems.size,
  );
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const onChangeReady = useCallback(
    (itemCodename: string) => {
      if (isAutoRefreshFeatureEnabled) {
        refreshPreview({
          isManualRefresh: false,
          data: {
            projectId: currentProjectId,
            languageCodename: selectedLanguageCodename,
            updatedItemCodename: itemCodename,
          },
          isPreviewUrlOutdated,
        });
      }
    },
    [
      refreshPreview,
      currentProjectId,
      selectedLanguageCodename,
      isAutoRefreshFeatureEnabled,
      isPreviewUrlOutdated,
    ],
  );

  useThunkPromise(startPreviewApiChangesPolling, onChangeReady, { canRun: canPoll });
}
