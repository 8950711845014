import { isValidJsonObject } from '../../../../../../_shared/utils/jsonUtils.ts';
import { isValidUrl, urlStartsWithHttps } from '../../../../../../_shared/utils/urlUtils.ts';
import { isUuid } from '../../../../../../_shared/utils/validation/typeValidators.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { ITypeElementValidationUtils } from '../types/ITypeElementValidationUtils.type.ts';
import { ITypeElementValidationUtilsDependencies } from '../types/ITypeElementValidationUtilsDependencies.type.ts';
import { isInputADecimalNumber } from './isInputADecimalNumber.ts';
import { isInputAReasonableInteger } from './isInputAReasonableInteger.ts';
import { GuidelinesValidationResult } from './types/GuidelinesValidationResult.type.ts';
import { NameValidationResult } from './types/NameValidationResult.type.ts';

function getNameValidationResult(
  { ValidationConstants }: ITypeElementValidationUtilsDependencies,
  typeElement: IBaseTypeElementData,
): NameValidationResult {
  const value = typeElement.name;

  if (value.length > ValidationConstants.ElementNameMaxLength) {
    return {
      isValid: false,
      errorMessage: `Shorten the label to squeeze in ${ValidationConstants.ElementNameMaxLength} characters tops.`,
    };
  }

  return {
    isValid: true,
    errorMessage: null,
  };
}

function getGuidelinesValidationResult(
  { ValidationConstants }: ITypeElementValidationUtilsDependencies,
  typeElement: IBaseTypeElementData,
): GuidelinesValidationResult {
  const value = typeElement.guidelines;

  if (value && value.length > ValidationConstants.ElementGuidelinesMaxLength) {
    return {
      isValid: false,
      errorMessage: `Provide more concise guidelines and try to fit into the limit of ${ValidationConstants.ElementGuidelinesMaxLength} characters. Now they are ${value.length} characters long.`,
    };
  }

  return {
    isValid: true,
    errorMessage: null,
  };
}

const isLimitValueValid = (limitValue: string | null): boolean =>
  !limitValue || (isInputAReasonableInteger(limitValue) && Number.parseInt(limitValue, 10) > 0);

const isFileSizeValid = (fileSizeValue: string | null): boolean =>
  !fileSizeValue ||
  (isInputADecimalNumber(fileSizeValue, 3) && Number.parseFloat(fileSizeValue) > 0);

function isRegexPatternValid(regex: string): boolean {
  try {
    new RegExp(regex);
  } catch {
    return false;
  }
  return true;
}

const isRegexLengthValid = (
  { ValidationConstants }: ITypeElementValidationUtilsDependencies,
  regex: string,
): boolean => regex.length <= ValidationConstants.RegexPatternMaxLength;

const isRegexValidationMessageLengthValid = (
  { ValidationConstants }: ITypeElementValidationUtilsDependencies,
  validationMessage: string | null,
): boolean =>
  validationMessage === null ||
  validationMessage.length <= ValidationConstants.RegexCustomValidationMessageMaxLength;

export function createTypeElementValidationUtils(
  utils: ITypeElementValidationUtilsDependencies,
): ITypeElementValidationUtils {
  return {
    getGuidelinesValidationResult: (typeElement: IBaseTypeElementData) =>
      getGuidelinesValidationResult(utils, typeElement),
    getNameValidationResult: (typeElement: IBaseTypeElementData) =>
      getNameValidationResult(utils, typeElement),
    isFileSizeValid,
    isLimitValueValid,
    isUuid,
    isValidJsonObject,
    isValidUrl,
    isRegexLengthValid: (regex: string) => isRegexLengthValid(utils, regex),
    isRegexPatternValid,
    isRegexValidationMessageLengthValid: (validationMessage: string | null) =>
      isRegexValidationMessageLengthValid(utils, validationMessage),
    urlStartsWithHttps,
  };
}
