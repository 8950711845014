import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { format, isWithinInterval } from 'date-fns';
import { ReactNode, forwardRef, useState } from 'react';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { DatetimePicker } from '../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { DateTime, createDateTime } from '../../../../_shared/models/DateTime.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { LocalTimeZoneId } from '../../../../_shared/utils/dateTime/timeZoneUtils.ts';

const MIN_MODAL_WIDTH = '500px';
const MAX_MODAL_INFO_WIDTH = 640;

const isDateInRange = (expirationDate: string, minDate: Date, maxDate: Date): boolean =>
  isWithinInterval(new Date(expirationDate), {
    start: minDate,
    end: maxDate,
  });

const getDefaultDateTime = (defaultExpirationDate: string): DateTime =>
  createDateTime({
    value: defaultExpirationDate,
    isValid: true,
  });

const getDefaultTime = (defaultExpirationDate: string): string =>
  format(new Date(defaultExpirationDate), 'p');

export interface ApiKeyRegenerationModalProps {
  readonly getDefaultExpirationDate: () => string;
  readonly getInvalidDateError?: (minDate: Date, maxDate: Date) => string;
  readonly getMaxExpirationDate: () => Date;
  readonly getMinExpirationDate: () => Date;
  readonly headline: string;
  readonly infoChildElement?: ReactNode;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirm: (dateTime: DateTime) => void;
}

export const ApiKeyModalWithExpirationDate = forwardRef<
  HTMLDivElement,
  ApiKeyRegenerationModalProps
>(
  (
    {
      getDefaultExpirationDate,
      getInvalidDateError,
      getMaxExpirationDate,
      getMinExpirationDate,
      headline,
      infoChildElement,
      isOpen,
      onClose,
      onConfirm,
    },
    ref,
  ) => {
    const defaultDate = getDefaultExpirationDate();
    const minDate = getMinExpirationDate();
    const maxDate = getMaxExpirationDate();
    const errorMessage = getInvalidDateError?.(minDate, maxDate);
    const [expirationDate, setExpirationDate] = useState(getDefaultDateTime(defaultDate));

    const onChange = (newExpirationDate: DateTime): void => {
      const isDateValid =
        newExpirationDate.isValid && isDateInRange(newExpirationDate.value, minDate, maxDate);

      setExpirationDate(
        createDateTime({
          value: newExpirationDate.value,
          isValid: isDateValid,
        }),
      );
    };

    return (
      <ModalDialog
        cancelAction={{
          onClick: onClose,
          ...getDataUiActionAttribute(DataUiAction.Cancel),
        }}
        headline={headline}
        isDismissable
        isOpen={isOpen}
        minWidth={MIN_MODAL_WIDTH}
        onClose={onClose}
        primaryAction={{
          disabled: !expirationDate.isValid,
          onClick: () => onConfirm(expirationDate),
          text: 'Generate new key',
          ...getDataUiActionAttribute(DataUiAction.Save),
        }}
        ref={ref}
        shouldCloseOnInteractOutside={() => true}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
      >
        <Box maxWidth={MAX_MODAL_INFO_WIDTH} width="100%">
          {infoChildElement && (
            <Box
              paddingBottom={Spacing.XL}
              {...getDataUiElementAttribute(DataUiElement.ApiKeyModalInfo)}
            >
              {infoChildElement}
            </Box>
          )}
          <DatetimePicker
            defaultDate={defaultDate}
            defaultTime={getDefaultTime(defaultDate)}
            errorMessage={expirationDate.isValid ? '' : errorMessage}
            isFullWidth
            maxValue={maxDate}
            minValue={minDate}
            onChange={onChange}
            timeZoneId={LocalTimeZoneId}
            title="Set expiration date"
            value={expirationDate.value}
          />
        </Box>
      </ModalDialog>
    );
  },
);

ApiKeyModalWithExpirationDate.displayName = 'ApiKeyModalWithExpirationDate';
