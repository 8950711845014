import {
  IContentComponent,
  createContentComponent,
} from '../../../models/contentItem/ContentComponent.ts';
import { IContentComponentServerModel } from '../../../models/contentItem/ContentComponentServerModel.type.ts';
import {
  IGetItemElementDomainModelConverter,
  IGetItemElementServerModelConverter,
} from '../../../utils/getItemElementDataConverters.ts';
import {
  IItemElementDomainModelConverterDataDependencies,
  IItemElementServerModelConverterDataDependencies,
} from '../../../utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';

export type ContentComponentType = IContentComponent | IContentComponentServerModel;

export interface IContentComponentDomainModelConversionDependencies {
  readonly getItemElementDomainModelConverter: IGetItemElementDomainModelConverter;
  readonly dataDependencies: IItemElementDomainModelConverterDataDependencies;
}

export interface IContentComponentServerModelConversionDependencies {
  readonly getItemElementServerModelConverter: IGetItemElementServerModelConverter;
  readonly dataDependencies: IItemElementServerModelConverterDataDependencies;
}

export const contentComponentConversionForServer = (
  contentComponent: IContentComponent,
  dependencies: IContentComponentServerModelConversionDependencies,
): IContentComponentServerModel => {
  const elements = contentComponent.elements.map((element) =>
    dependencies.getItemElementServerModelConverter(element.type)(
      element,
      dependencies.dataDependencies,
    ),
  );

  return {
    id: contentComponent.id,
    type: {
      _id: contentComponent.contentTypeId,
    },
    elements,
  };
};

export const contentComponentConversionToDomainModel = (
  contentComponent: IContentComponentServerModel,
  dependencies: IContentComponentDomainModelConversionDependencies,
): IContentComponent => {
  const elements = contentComponent.elements.map((element) =>
    dependencies.getItemElementDomainModelConverter(element.type)(
      element,
      dependencies.dataDependencies,
    ),
  );

  return createContentComponent({
    id: contentComponent.id,
    contentTypeId: contentComponent.type._id,
    elements,
  });
};
