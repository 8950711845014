import { Button } from '@kontent-ai/component-library/Button';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { FC } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRule } from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { emptyCannotRule } from '../../../../constants/emptyRules.ts';
import { RoleEditorStatus } from '../../../../models/RoleEditorStatus.ts';
import { CapabilityRuleExceptionPicker } from './CapabilityRuleExceptionPicker.tsx';

type Props = {
  readonly allTypes: Immutable.Map<Uuid, IContentType>;
  readonly isDisabled: boolean;
  readonly onChange: (newRules: ReadonlyArray<IRule>) => void;
  readonly contentCannotRules: ReadonlyArray<IRule>;
  readonly status: RoleEditorStatus;
};

export const ContentProductionCannotRules: FC<Props> = ({
  contentCannotRules,
  allTypes,
  onChange,
  isDisabled,
  status,
}) => (
  <>
    {(contentCannotRules.length > 0 || !isDisabled) && (
      <p className="capability-rules__title">
        Users with this role <b>cannot</b>:
      </p>
    )}
    {contentCannotRules.length === 0 && !isDisabled && (
      <div className="capability-rules__empty-message">Add exceptions to the rules above.</div>
    )}
    {contentCannotRules.map((rule: IRule, index: number) => (
      <CapabilityRuleExceptionPicker
        key={index}
        index={index}
        isDisabled={isDisabled}
        onRemove={() => onChange(contentCannotRules.filter((_, i) => i !== index))}
        allTypes={allTypes}
        capabilityRule={rule}
        onChange={(newRule) => onChange(Collection.replace(contentCannotRules, index, newRule))}
        status={status}
      />
    ))}
    {!isDisabled && (
      <Button
        disabled={isDisabled}
        buttonStyle="tertiary"
        onClick={() => onChange([...contentCannotRules, emptyCannotRule])}
        css={`
          max-width: fit-content;
          margin-bottom: ${px(Spacing.XXL)};
        `}
        {...getDataUiActionAttribute(DataUiAction.AddException)}
      >
        Add exception
      </Button>
    )}
  </>
);

ContentProductionCannotRules.displayName = 'ContentProductionCannotRules';
