import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_Editing_StepDraggingFinished,
  Workflow_Editing_StepDraggingStarted,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
} from '../../constants/workflowActionTypes.ts';

const initialState = null;

export const draggedWorkflowStepId = (state = initialState, action: Action): Uuid | null => {
  switch (action.type) {
    case Workflow_Editing_StepDraggingStarted:
      return action.payload.workflowStepId;

    case Workflow_Editing_StepDraggingFinished:
    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished:
      return initialState;

    default:
      return state;
  }
};
