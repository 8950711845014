import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

export const CopyProjectDocumentationLinksExtended: React.FC = () => (
  <div className="create-project-form__learn-more-links create-project-form__learn-more-links--with-options-above">
    <span>Learn more about </span>
    <OutwardLink href={documentationLinks.cloneProjectWhatGetsCopied}>what gets copied</OutwardLink>
    <span> and how to select </span>
    <OutwardLink href={documentationLinks.selectDataCenter}>data centers</OutwardLink>.
  </div>
);
