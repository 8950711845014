import { memoize } from '@kontent-ai/memoization';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { IGroupedEvent } from '../../components/Calendar/AgendaGroup.tsx';
import { AgendaView as AgendaViewComponent } from '../../components/Calendar/AgendaView.tsx';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { CalendarView } from '../../models/CalendarView.ts';
import { compareEventsByDate, isDateInRange } from '../../utils/calendarUtils.ts';

type Props = {
  readonly events: readonly ICalendarEvent[];
};

interface IWritableGroupedEvent {
  readonly date: string;
  readonly events: ICalendarEvent[];
}

const getGroupedEvents = (
  selectedPeriod: DateRange,
  ...events: ICalendarEvent[]
): ReadonlyArray<IGroupedEvent> =>
  events
    .sort(compareEventsByDate)
    .reduce((byDay: IWritableGroupedEvent[], event: ICalendarEvent) => {
      if (isDateInRange(event.date, selectedPeriod.from, selectedPeriod.to)) {
        const date = format(new Date(event.date), 'dd EEEE');
        const dayIndex = byDay.findIndex((dayGroup) => dayGroup.date === date);

        if (dayIndex < 0) {
          byDay.push({
            date,
            events: [event],
          });
        } else {
          byDay[dayIndex]?.events.push(event);
        }
      }

      return byDay;
    }, []);

const getGroupedEventsMemoized = memoize.maxOne(getGroupedEvents);

export const AgendaView: React.FC<Props> = ({ events }) => {
  const groupedEvents = useSelector((s) =>
    getGroupedEventsMemoized(s.calendarApp.selectedPeriod, ...events),
  );
  const shouldRenderView = useSelector(
    (s) =>
      s.calendarApp.calendarView === CalendarView.Items &&
      s.calendarApp.itemsLoadingStatus === LoadingStatus.Loaded,
  );

  if (!shouldRenderView) {
    return null;
  }

  return <AgendaViewComponent groupedEvents={groupedEvents} />;
};

AgendaView.displayName = 'AgendaView';
