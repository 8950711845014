import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISpacesRepository } from '../../../../../repositories/interfaces/ISpacesRepository.type.ts';
import { IContentItemServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  ICreateSpaceRequestServerModel,
  ISpaceServerResponseModel,
} from '../../../../../repositories/serverModels/SpacesServerModel.type.ts';
import {
  Spaces_Create_Failed,
  Spaces_Create_Started,
  Spaces_Create_Succeeded,
} from '../../constants/spacesActionTypes.ts';
import { SpacesActionFailedMessage } from '../../constants/spacesUiConstants.ts';

type Deps = {
  readonly ensureRootItemCreated: () => ThunkPromise<IContentItemServerModel>;
  readonly spacesRepository: Pick<ISpacesRepository, 'create'>;
};

const started = () =>
  ({
    type: Spaces_Create_Started,
  }) as const;

const created = (space: ISpaceServerResponseModel) =>
  ({
    type: Spaces_Create_Succeeded,
    payload: {
      space,
    },
  }) as const;

const failed = () =>
  ({
    type: Spaces_Create_Failed,
    payload: {
      errorMessage: SpacesActionFailedMessage`create`,
    },
  }) as const;

export type CreateSpaceActionType = ReturnType<typeof started | typeof created | typeof failed>;

export const createCreateSpaceAction =
  (deps: Deps) =>
  (
    name: string,
    collectionIds: ReadonlyArray<Uuid>,
    rootItemId: Uuid | null,
    shouldCreateNewRootItem: boolean,
  ): ThunkPromise<ISpaceServerResponseModel> =>
  async (dispatch) => {
    dispatch(started());

    try {
      let webSpotlightRootItemId = rootItemId;

      if (!rootItemId && shouldCreateNewRootItem) {
        const rootItem = await dispatch(deps.ensureRootItemCreated());

        webSpotlightRootItemId = rootItem.id;
      }

      const requestModel: ICreateSpaceRequestServerModel = {
        name,
        collections: collectionIds,
        webSpotlightRootItemId,
      };

      const response = await deps.spacesRepository.create(requestModel);
      dispatch(created(response));
      return response;
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
