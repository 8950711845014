import { Pathname } from 'history';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IContentItemPatchServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { tryParseApiError } from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { JsonPatchOperation } from '../../../../../../repositories/utils/jsonPatchConstants.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { getNotEmptyItemName } from '../../../../stores/utils/contentItemHelperMethods.ts';
import { getContentItemSavingOriginFromRoute } from '../../utils/itemEditingUtils.ts';
import {
  contentItemSavingFailed,
  contentItemSavingFinished,
  contentItemSavingStarted,
} from '../contentItemEditingActions.ts';
import { AutoGenerateAllUrlSlugsAction } from './autoGenerateAllUrlSlugs.ts';

type SaveContentItemNameToServerDependencies = Readonly<{
  contentItemRepository: IContentItemRepository;
  regenerateAllUrlSlugs: AutoGenerateAllUrlSlugsAction;
}>;

export const getPatchData = (
  name: string,
  isCodenameAutogenerated: boolean,
): IContentItemPatchServerModel[] => {
  const namePatchData = {
    op: JsonPatchOperation.Replace,
    path: itemEditorOperationIds.name,
    value: name,
  };

  if (!isCodenameAutogenerated) {
    return [namePatchData];
  }

  const codenamePatchData = {
    op: JsonPatchOperation.Replace,
    path: itemEditorOperationIds.codename,
  };

  return [namePatchData, codenamePatchData];
};

export const createSaveContentItemNameToServerAction =
  (deps: SaveContentItemNameToServerDependencies) =>
  (pathname: Pathname) =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      contentApp: {
        editedContentItem,
        listingUi: { filter, orderBy },
        editorUi: {
          codenameEditor: { isCodenameAutogenerated },
        },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (editedContentItem === null) {
      return Promise.reject('Missing edited item');
    }

    try {
      dispatch(contentItemSavingStarted(itemEditorOperationIds.name));

      const newName = getNotEmptyItemName(editedContentItem.name);
      const patchData = getPatchData(newName, isCodenameAutogenerated);

      const origin = getContentItemSavingOriginFromRoute(window.location.pathname);
      const itemServerModel = await deps.contentItemRepository.patchItem(
        editedContentItem.id,
        patchData,
      );

      dispatch(
        contentItemSavingFinished({
          filter,
          orderBy,
          itemPreviouslyUpdatedAt: editedContentItem.lastModifiedAt,
          itemServerModel,
          operationId: itemEditorOperationIds.name,
          origin,
          usedSearchMethod,
        }),
      );
      return dispatch(deps.regenerateAllUrlSlugs({ overwriteExisting: true, pathname }));
    } catch (error) {
      const apiError = tryParseApiError(error);
      dispatch(
        contentItemSavingFailed({
          operationId: itemEditorOperationIds.name,
          apiError,
        }),
      );
      logError('Saving content item name failed.', error);
      throw error;
    }
  };
