import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_SelectedCollectionChanged,
} from '../../constants/newContentItemActionTypes.ts';

export function collectionId(state: Uuid | null = null, action: Action): Uuid | null {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.collectionId;

    case NewContentItem_SelectedCollectionChanged:
      return action.payload.collectionId;

    default:
      return state;
  }
}
