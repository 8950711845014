import { EditorState } from 'draft-js';
import { GuidelinesTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { exportGuidelinesToServerHtml } from '../../../../richText/utils/export/html/exportGuidelinesToServerHtml.ts';
import {
  emptyEditorState,
  emptyJsonValue,
} from '../../../../richText/utils/general/editorEmptyValues.ts';
import { importRichTextFromHtml } from '../../../../richText/utils/import/importRichTextFromHtml.ts';
import { createRichTextDomainModel } from '../../../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IGuidelinesTypeElementData extends IBaseTypeElementData {
  readonly guidelinesJson: string | null;
  readonly guidelinesHtml: string | null;
  readonly _editorState: EditorState;
}

export const EmptyGuidelinesTypeElementData: IGuidelinesTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.None,
  _editorState: emptyEditorState,
  guidelinesHtml: null,
  guidelinesJson: emptyJsonValue,
  name: 'Guidelines',
  type: ElementType.Guidelines,
};

export function getGuidelinesTypeElementDataFromServerModel(
  serverModel: GuidelinesTypeElementServerModel,
): IGuidelinesTypeElementData {
  const { content, updatedServerValues } = createRichTextDomainModel(
    {
      value: serverModel.guidelinesHtml,
      jsonValue: serverModel.guidelinesJson,
    },
    {
      importFromValue: importRichTextFromHtml,
      getValue: exportGuidelinesToServerHtml,
    },
  );

  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyGuidelinesTypeElementData._configurability,
    _editorState: EditorState.createWithContent(content),
    guidelinesHtml:
      updatedServerValues?.value ??
      serverModel.guidelinesHtml ??
      EmptyGuidelinesTypeElementData.guidelinesHtml,
    guidelinesJson:
      updatedServerValues?.jsonValue ??
      serverModel.guidelinesJson ??
      EmptyGuidelinesTypeElementData.guidelinesJson,
    isRequired: EmptyGuidelinesTypeElementData.isRequired, // always false as it does not make sense for this element
    name: serverModel.name ?? EmptyGuidelinesTypeElementData.name,
    type: EmptyGuidelinesTypeElementData.type,
  };
}
