import Immutable from 'immutable';
import {
  ItemsRequestTrigger,
  shouldUseLastContinuationToken,
} from '../../../../_shared/utils/scrollTableUtils.ts';
import { IListingContentItem } from '../../../models/listingContentItems/IListingContentItem.ts';

type Params = {
  readonly currentByIds: Immutable.Map<Uuid, IListingContentItem>;
  readonly currentAllIds: Immutable.OrderedSet<Uuid>;
  readonly requestTrigger: ItemsRequestTrigger;
};

export const filterOutPublishedVersionsOfLoadedInventoryItems =
  ({ currentAllIds, currentByIds, requestTrigger }: Params) =>
  (newItemsBatch: ReadonlyArray<IListingContentItem>): ReadonlyArray<IListingContentItem> => {
    const willNewItemsBeAppendedToAllIds = shouldUseLastContinuationToken(requestTrigger);
    const itemsAddedByPreviousBatches = willNewItemsBeAppendedToAllIds
      ? currentAllIds
      : Immutable.OrderedSet<Uuid>();

    return newItemsBatch.filter((currentItem: IListingContentItem): boolean => {
      if (!currentItem.isPublishedVersion) {
        // always prefer fresh version
        return true;
      }

      const isNonPublishedVersionAlreadyInTheInventory = () => {
        if (!itemsAddedByPreviousBatches.has(currentItem.item.id)) {
          return false;
        }

        const item = currentByIds.get(currentItem.item.id);
        return item && !item.isPublishedVersion;
      };

      const isNonPublishedVersionInThisBatch = () =>
        newItemsBatch.some(
          (item) => item.item.id === currentItem.item.id && !item.isPublishedVersion,
        );

      return !isNonPublishedVersionAlreadyInTheInventory() && !isNonPublishedVersionInThisBatch();
    });
  };
