import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../../_shared/components/AppBar/BackNavigation.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getGoBackLinkByPath } from '../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';

export const TaxonomyBackNavigation: React.FC = () => {
  const location = useLocation();
  const to = getGoBackLinkByPath(location.pathname);
  const isDisabled = useSelector(
    (state) => state.taxonomyApp.status === TaxonomyEditorStatus.IsBeingSaved,
  );

  return <BackNavigation to={to} isDisabled={isDisabled} />;
};

TaxonomyBackNavigation.displayName = 'TaxonomyBackNavigation';
