import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Stack } from '@kontent-ai/component-library/Stack';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  Spacing,
  colorTextLowEmphasis,
  px,
  spacingSimpleStatusProtectiveArea,
} from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, MouseEventHandler, useId } from 'react';
import { modalWithPropertiesOpened } from '../../../../../../../_shared/actions/sharedActions.ts';
import { CreateAutoScrollId } from '../../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DueDate } from '../../../../../../../_shared/components/DueDate/DueDate.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { ModalDialogType } from '../../../../../../../_shared/constants/modalDialogType.ts';
import { useAutoScroll } from '../../../../../../../_shared/hooks/useAutoScroll.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { Task as TaskModel, TaskStatus } from '../../../../../../../_shared/models/Task.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { startTaskEditing, taskHighlighted } from '../../../actions/contentItemEditingActions.ts';
import { completeTask, reopenTask } from '../../../actions/thunkContentItemEditingActions.ts';
import { TaskUserMention } from './TaskUserMention.tsx';

export interface ITaskProps {
  readonly task: TaskModel;
}

type PersistentHighlight = {
  clearHighlight: () => void;
  keepHighlightedUntilUnmount: boolean;
};

const usePersistentHighlighting = (
  isHighlighted: boolean,
  onHighlighted: () => void,
): PersistentHighlight => {
  const [keepHighlightedUntilUnmount, setKeepHighlightedUntilUnmount] =
    React.useState(isHighlighted);
  const clearHighlight = useCallback(() => {
    setKeepHighlightedUntilUnmount(false);
  }, []);

  useEffect(() => {
    if (isHighlighted) {
      onHighlighted();
      setKeepHighlightedUntilUnmount(true);
    }
  }, [isHighlighted, onHighlighted]);

  return {
    clearHighlight,
    keepHighlightedUntilUnmount,
  };
};

export const Task: React.FC<ITaskProps> = ({ task }) => {
  const isHighlighted = useSelector((s) =>
    s.contentApp.editorUi.tasks.highlightedTaskIds.includes(task.id),
  );

  const assignee = useSelector((s) => {
    const assignees = Collection.filter(s.data.users.usersById, (user: IProjectContributor) =>
      task.assignees.has(user.userId),
    );
    return Collection.getFirst(Collection.getValues(assignees));
  });

  const isTaskCompleted = task.status === TaskStatus.Completed;
  const dispatch = useDispatch();

  const { clearHighlight, keepHighlightedUntilUnmount } = usePersistentHighlighting(
    isHighlighted,
    () => dispatch(taskHighlighted(task.id)),
  );

  const onTaskCompletionToggle = () => {
    clearHighlight();
    if (isTaskCompleted) {
      dispatch(reopenTask(task.id));
    } else {
      dispatch(completeTask(task.id));
    }
  };

  const handleTaskClick: MouseEventHandler<HTMLDivElement> = () => {
    clearHighlight();
    dispatch(startTaskEditing(task.id));
    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.ContentItemEditTaskDialog,
        actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  const taskRef = useRef<HTMLDivElement>(null);
  useAutoScroll({
    scrollId: CreateAutoScrollId.forTask(task.id),
    scrollTargetRef: taskRef,
  });

  const labelId = useId();

  return (
    <>
      <div
        className={classNames('content-item-sidebar__task', {
          'content-item-sidebar__task--highlighted': keepHighlightedUntilUnmount,
        })}
        ref={taskRef}
        onClick={handleTaskClick}
      >
        <Box paddingLeft={Spacing.XL}>
          <Checkbox
            className="content-item-sidebar__task-checkbox"
            ariaLabelledBy={labelId}
            checkboxState="default"
            id={task.id}
            checked={isTaskCompleted}
            onToggle={onTaskCompletionToggle}
          />
        </Box>
        <div
          className={classNames('content-item-sidebar__task-description', {
            'content-item-sidebar__task-description--completed': isTaskCompleted,
          })}
        >
          <Stack
            id={labelId}
            // The due-date has a 'protective area'. Sort of a bumper. When it's absent we need to add a little space.
            spacing={task.dueDate ? Spacing.None : Spacing.XXS}
          >
            <SrOnly>Assignee:</SrOnly>
            <TaskUserMention userId={assignee?.userId} key={task.id} wasPinged />

            {task.dueDate && (
              <Box marginLeft={px(spacingSimpleStatusProtectiveArea * -1)}>
                <SrOnly>Due date:</SrOnly>
                <DueDate
                  dueDate={task.dueDate}
                  format={isTaskCompleted ? 'basic' : 'auto'}
                  css={
                    isTaskCompleted ? `text-decoration: line-through ${colorTextLowEmphasis}` : ''
                  }
                />
              </Box>
            )}

            <SrOnly>Task description: </SrOnly>
            <span
              className={classNames('content-item-sidebar__task-description-text', {
                'content-item-sidebar__task-description-text--completed': isTaskCompleted,
              })}
            >
              {task.description}
            </span>
          </Stack>
          <Icon
            iconName={IconName.Edit}
            className={classNames('content-item-sidebar__icon-edit')}
          />
        </div>
      </div>
    </>
  );
};

Task.displayName = 'Task';
