import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../constants/documentationLinks.ts';
import { ContentChangesWarningSubtitle } from './ContentChangesWarningModal.tsx';

interface ILongProcessingChangesWarningProps {
  readonly hideLongProcessingChangesWarning?: boolean;
  readonly isLongProcessingWarningDismissed: boolean;
  readonly onLongProcessingWarningDismiss: () => void;
  readonly warningSubtitle: ContentChangesWarningSubtitle;
}

export const LongProcessingChangesWarning: React.FC<ILongProcessingChangesWarningProps> = ({
  hideLongProcessingChangesWarning,
  isLongProcessingWarningDismissed,
  onLongProcessingWarningDismiss,
  warningSubtitle,
}) => {
  if (hideLongProcessingChangesWarning) {
    return null;
  }

  return (
    <Callout calloutType="friendlyWarning" headline={warningSubtitle}>
      <p>
        New changes won’t be available in the Delivery API until the API finishes{' '}
        <OutwardLink href={documentationLinks.changeProcessingTime}>
          processing your latest changes
        </OutwardLink>
        .
      </p>
      <p>
        The Delivery API might return inconsistent data during the processing, which can break your
        app. To prevent any issues, make sure your apps accept both the old and new data while the
        changes are processed.
      </p>
      <Box marginTop={Spacing.S} css={`margin-left: ${-Spacing.S}px`}>
        <Checkbox
          checked={isLongProcessingWarningDismissed}
          checkboxState="default"
          onToggle={onLongProcessingWarningDismiss}
        >
          Don’t show this message again
        </Checkbox>
      </Box>
    </Callout>
  );
};

LongProcessingChangesWarning.displayName = 'LongProcessingChangesWarning';
