import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { DefaultVariantId } from '../../../_shared/constants/variantIdValues.ts';
import {
  IValidationResult,
  emptyValidationResult,
} from '../../../_shared/utils/validation/ValidationResult.ts';
import { IAssetRendition } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ILanguage, Languages } from '../../../data/models/languages/Language.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';
import { EditableTypeElement } from '../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { IContentComponent } from '../models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { isRichTextElement } from '../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { ItemElementValidationResult } from './ItemElementValidationResult.type.ts';
import { accumulateContentComponentValidationResults } from './accumulateContentComponentValidationResults.ts';
import { ItemElementErrorResult } from './elementErrorCheckers/types/Errors.ts';
import { getElementErrors } from './getElementErrors.ts';
import { getItemElementFriendlyWarnings } from './getItemElementFriendlyWarnings.ts';
import { getItemElementValueForErrorValidation } from './getItemElementValueForErrorValidation.ts';
import { getItemElementWarnings } from './getItemElementWarnings.ts';
import { createItemElementWithInitialValue } from './itemElementCreator.ts';
import { ItemElementFriendlyWarningResult } from './itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ItemElementWarningResult } from './itemElementWarningCheckers/types/Warnings.ts';
import { getValidationSelectorId } from './itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { mapElementErrorResultToItemElementErrorResult } from './mapElementErrorResultToItemElementErrorResult.ts';

export const isElementRequiredLimitMet = (warningResult: ItemElementWarningResult): boolean => {
  return !warningResult.requiredMessage;
};

export const areElementContentLimitationsMet = (
  warningResult: ItemElementWarningResult,
): boolean => {
  return !warningResult.limitationMessages.length;
};

export const isElementReadyForPublish = (
  warningResult: ItemElementWarningResult | undefined,
): boolean =>
  !warningResult ||
  (isElementRequiredLimitMet(warningResult) && areElementContentLimitationsMet(warningResult));

export const isElementValidIfEmpty = (typeElement: EditableTypeElement): boolean => {
  const emptyElementData = createItemElementWithInitialValue(typeElement);
  const validationResult = getItemElementValidationResult(
    typeElement,
    emptyElementData,
    Immutable.Map<Uuid, ICompiledContentType>(),
    Immutable.Map<Uuid, IAsset>(),
    new Map<Uuid, IAssetRendition>(),
    Immutable.Map<Uuid, ITaxonomyGroup>(),
    Immutable.Map<Uuid, IListingContentItem>(),
    DefaultVariantId,
    Immutable.OrderedMap<Uuid, ILanguage>(),
    true,
  );
  return isElementReadyForPublish(Collection.getValues(validationResult.warnings)[0]);
};

const mergeValidationResult = memoize.weak(
  <TItemElementValidationResult extends ItemElementValidationResult>(
    results: ReadonlyMap<UuidPath, TItemElementValidationResult>,
    result: TItemElementValidationResult,
    validationSelectorId: string,
  ) => Collection.add(results, [validationSelectorId, result]),
);

export const createValidationResult = memoize.weak(
  (
    errorResult: ItemElementErrorResult,
    warningResult: ItemElementWarningResult,
    friendlyWarningResult: ItemElementFriendlyWarningResult,
    elementId: Uuid,
  ): IValidationResult => {
    return {
      errors: errorResult.errorMessages.length
        ? new Map<UuidPath, ItemElementErrorResult>([[elementId, errorResult]])
        : emptyValidationResult.errors,
      warnings: isElementReadyForPublish(warningResult)
        ? emptyValidationResult.warnings
        : new Map<UuidPath, ItemElementWarningResult>([[elementId, warningResult]]),
      friendlyWarnings: friendlyWarningResult.warningMessages.length
        ? new Map<UuidPath, ItemElementFriendlyWarningResult>([[elementId, friendlyWarningResult]])
        : emptyValidationResult.friendlyWarnings,
    };
  },
);

const emptyContentComponentTypeIds: ReadonlyMap<Uuid, Uuid> = new Map();

const getContentComponentTypeIds = memoize.maxNWithTransformedArgs(
  (contentComponents: ReadonlyArray<IContentComponent>): ReadonlyMap<Uuid, Uuid> =>
    new Map<Uuid, Uuid>(
      contentComponents.map((contentComponent) => [
        contentComponent.id,
        contentComponent.contentTypeId,
      ]),
    ),
  (args) =>
    args[0].flatMap((contentComponent) => [contentComponent.id, contentComponent.contentTypeId]),
  100,
);

export const getItemElementValidationResult = memoize.weak(
  <TItemElement extends ICompiledContentItemElementData = ICompiledContentItemElementData>(
    typeElement: EditableTypeElement,
    itemElement: TItemElement,
    loadedContentTypes: Immutable.Map<Uuid, ICompiledContentType>,
    loadedAssets: Immutable.Map<Uuid, IAsset>,
    loadedAssetRenditions: ReadonlyMap<Uuid, IAssetRendition>,
    taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
    loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
    currentLanguageId: Uuid,
    languages: Languages,
    areAssetRenditionsEnabled: boolean,
    contentComponentId?: Uuid,
    contentComponentTypeIds?: ReadonlyMap<Uuid, Uuid>,
  ): IValidationResult => {
    const useContentComponentTypeIds =
      isRichTextElement(itemElement) && !contentComponentTypeIds
        ? // Only collect component type IDs via top level element where they are physically stored
          getContentComponentTypeIds(Collection.getValues(itemElement.contentComponents))
        : (contentComponentTypeIds ?? emptyContentComponentTypeIds);

    const componentsResults: IValidationResult =
      isRichTextElement(itemElement) && !contentComponentId
        ? // Only validate content components via top level element where they are physically stored
          // We do validation of all components at top level to not harm memoization by sending all content components down the pipeline
          accumulateContentComponentValidationResults(
            getItemElementValidationResult,
            itemElement,
            useContentComponentTypeIds,
            loadedContentTypes,
            loadedAssets,
            loadedAssetRenditions,
            taxonomyGroups,
            loadedEntries,
            currentLanguageId,
            languages,
            areAssetRenditionsEnabled,
          )
        : emptyValidationResult;

    const warning: ItemElementWarningResult = getItemElementWarnings({
      areAssetRenditionsEnabled,
      contentComponentTypeIds: useContentComponentTypeIds,
      itemElement,
      loadedAssetRenditions,
      loadedAssets,
      loadedContentTypes,
      loadedEntries,
      taxonomyGroups,
      typeElement,
    });

    const friendlyWarning: ItemElementFriendlyWarningResult = getItemElementFriendlyWarnings({
      itemElement,
      currentLanguageId,
      languages,
      loadedAssets,
      loadedContentTypes,
      loadedEntries,
      taxonomyGroups,
      typeElement,
    });

    const validationResults = getElementErrors({
      value: getItemElementValueForErrorValidation(itemElement),
      typeElement,
      loadedContentTypes,
    });

    const error = mapElementErrorResultToItemElementErrorResult(
      validationResults,
      typeElement.type,
    );

    const validationSelectorId = getValidationSelectorId(typeElement.elementId, contentComponentId);
    const warnings = mergeValidationResult(
      componentsResults.warnings,
      warning,
      validationSelectorId,
    );
    const friendlyWarnings = mergeValidationResult(
      componentsResults.friendlyWarnings,
      friendlyWarning,
      validationSelectorId,
    );
    const errors = mergeValidationResult(componentsResults.errors, error, validationSelectorId);

    return {
      warnings,
      friendlyWarnings,
      errors,
    };
  },
);

export type IGetItemElementValidationResults = typeof getItemElementValidationResult;
