import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { Notifications } from '../../../../_shared/services/signalR/signalRClient.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { getAssignmentFromServerModel } from '../../models/contentItem/Assignment.ts';
import { ContentItemChangeReason } from '../../models/contentItem/ContentItemChangeReason.type.ts';
import {
  createNewContentItemOverwrite,
  getItemOverwrittenWorkflowDifferences,
} from '../../utils/itemOverwriteUtils.ts';
import { itemDefaultVariantOverwritten } from '../contentActions.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly getItemWithVariant: (
      itemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
}

export const createHandleDefaultVariantOverwrittenAction =
  (deps: IDeps) =>
  (params: Notifications['contentChange']['payload']): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();

    assert(editedContentItemVariant, () => '"editedContentItemVariant" is not loaded.');

    const contentItemId = editedContentItemVariant.id;
    let newOverwrite = createNewContentItemOverwrite(params);

    if (params.changeReason !== ContentItemChangeReason.Archive) {
      const editedContentItemWithDefaultVariant =
        await deps.contentItemRepository.getItemWithVariant(contentItemId.itemId, DefaultVariantId);
      const assignment = getAssignmentFromServerModel(
        editedContentItemWithDefaultVariant.variant.assignment,
      );

      newOverwrite = {
        ...newOverwrite,
        difference: getItemOverwrittenWorkflowDifferences(
          assignment,
          params.difference.assignmentDifferences,
          editedContentItemWithDefaultVariant,
        ),
      };
    }

    dispatch(itemDefaultVariantOverwritten(contentItemId.itemId, newOverwrite));
  };
