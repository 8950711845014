import { Action } from '../../../../../@types/Action.type.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import {
  UserListing_Init_Finished,
  UserListing_SortByFirstName_Selected,
  UserListing_SortByLastName_Selected,
  UserListing_SortByStatus_Selected,
  UserListing_SortBy_DirectionToggled,
} from '../../constants/usersActionTypes.ts';
import { UserListingColumnCode } from '../IUsersAppState.type.ts';

const initialState: OrderBy<UserListingColumnCode> = {
  columnCode: UserListingColumnCode.LastName,
  direction: OrderByDirection.Ascending,
};

export const sortBy = (
  state: OrderBy<UserListingColumnCode> = initialState,
  action: Action,
): OrderBy<UserListingColumnCode> => {
  switch (action.type) {
    case UserListing_Init_Finished: {
      const { userListingConfig } = action.payload;

      return {
        columnCode: userListingConfig.sortByField,
        direction: userListingConfig.sortByDirection,
      };
    }

    case UserListing_SortBy_DirectionToggled:
      return {
        ...state,
        direction:
          state.direction === OrderByDirection.Ascending
            ? OrderByDirection.Descending
            : OrderByDirection.Ascending,
      };

    case UserListing_SortByLastName_Selected:
      return {
        columnCode: UserListingColumnCode.LastName,
        direction: OrderByDirection.Ascending,
      };

    case UserListing_SortByFirstName_Selected:
      return {
        columnCode: UserListingColumnCode.FirstName,
        direction: OrderByDirection.Ascending,
      };

    case UserListing_SortByStatus_Selected:
      return {
        columnCode: UserListingColumnCode.Status,
        direction: OrderByDirection.Ascending,
      };

    default:
      return state;
  }
};
