import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IPaidMetric } from '../../../../../data/models/plans/PaidMetric.ts';
import { IPlan } from '../../../../../data/models/plans/Plan.ts';
import { IRoleWithSettings } from '../../../../../data/models/roles/IRoleWithSettings.ts';
import { PredefinedRoles } from '../../constants/predefinedRoles.ts';
import { ICustomRolesLimitInfo } from '../../selectors/customRoleLimit.ts';
import {
  areCustomRolesOverLimit,
  canCreateNewCustomRole,
  getNumberOfCustomRolesOverLimit,
} from '../../utils/customRolesUtils.ts';

interface IFriendlyWarningStatus {
  readonly messageTitle?: string;
  readonly activeMessages: Array<ReactNode>;
}

const getRoleCostMessage = (
  limitInfo: ICustomRolesLimitInfo,
  extraPackagePrice: number,
): string => {
  const { customRoleCount, customRoleLimit } = limitInfo;

  if (customRoleLimit === null) {
    return `You can add as many roles as you like for $${extraPackagePrice} each.`;
  }

  const extraAllowedRolesNumber = customRoleLimit - customRoleCount;

  if (extraAllowedRolesNumber === 1) {
    return `You can add 1 more role for $${extraPackagePrice}.`;
  }

  return `You can add ${extraAllowedRolesNumber} more roles for $${extraPackagePrice} each.`;
};

interface IRoleConfiguratorMessageProps {
  readonly currentPlan: IPlan;
  readonly customRolesLimitInfo: ICustomRolesLimitInfo;
  readonly customRolesPaidMetric: IPaidMetric | undefined;
  readonly isRoleBeingCreated: boolean;
  readonly isSubscriptionAdmin: boolean;
  readonly newRoleLink: string;
  readonly role: IRoleWithSettings;
}

export const RoleConfiguratorMessage: React.FC<IRoleConfiguratorMessageProps> = ({
  currentPlan,
  customRolesLimitInfo,
  customRolesPaidMetric,
  isRoleBeingCreated,
  isSubscriptionAdmin,
  newRoleLink,
  role,
}) => {
  const getIfYouWantToModifyMessage = (): ReactNode => {
    const numberOfOverLimit = getNumberOfCustomRolesOverLimit(customRolesLimitInfo);

    return isSubscriptionAdmin ? (
      <p key="modify-admin">
        If you want to modify a custom role to have specific capabilities, delete{' '}
        {pluralizeWithCount('custom role', numberOfOverLimit)} to fit within your subscription plan
        limit or upgrade your plan.{' '}
      </p>
    ) : (
      <p key="modify-not-admin">
        If you want to modify a custom role to have specific capabilities, delete{' '}
        {pluralizeWithCount('custom role', numberOfOverLimit)} to fit within your subscription plan
        limit or ask your subscription admin to upgrade your plan.{' '}
      </p>
    );
  };

  const getIfYouWantToCreateMessage = (): ReactNode =>
    isSubscriptionAdmin ? (
      <p key="create-admin">
        If you want {currentPlan.features.maxCustomRoles === 0 ? 'a' : 'another'} role with specific
        capabilities, upgrade your subscription plan to add new custom roles.{' '}
      </p>
    ) : (
      <p key="create-not-admin">
        If you want {currentPlan.features.maxCustomRoles === 0 ? 'a' : 'another'} role with specific
        capabilities, ask your subscription admin to upgrade your plan.{' '}
      </p>
    );

  const getCreatingRoleOverIncludedFriendlyWarning = (
    extraRolePrice: number,
  ): IFriendlyWarningStatus => {
    const roleCostMessage = getRoleCostMessage(customRolesLimitInfo, extraRolePrice);

    return {
      messageTitle: 'Creating extra custom roles',
      activeMessages: [
        <p key="extra-role-info">You’ve reached the custom roles included in your plan.</p>,
        <p key="extra-role-cost">{roleCostMessage}</p>,
      ],
    };
  };

  const getFriendlyWarning = (): IFriendlyWarningStatus => {
    if (
      isRoleBeingCreated &&
      customRolesPaidMetric &&
      customRolesLimitInfo.customRoleCount >= customRolesPaidMetric.includedUnits
    ) {
      return getCreatingRoleOverIncludedFriendlyWarning(customRolesPaidMetric.extraPackagePrice);
    }

    const activeMessages: Array<ReactNode> = [];

    const canCreateNewRole = canCreateNewCustomRole(customRolesLimitInfo);
    const areRolesOverLimit = areCustomRolesOverLimit(customRolesLimitInfo);

    if (role.isReadonly) {
      activeMessages.push(
        <p key="readonly">
          {role.name} is a predefined role with all capabilities. This role cannot be changed
          {role.codename !== PredefinedRoles.LocalProjectManager && ' or deleted'}.
        </p>,
      );

      if (canCreateNewRole) {
        activeMessages.push(
          <p key="readonly-add">
            If you want {currentPlan.features.maxCustomRoles === 1 ? 'a' : 'another'} role with
            specific capabilities, <Link to={newRoleLink}>add a new custom role</Link>.
          </p>,
        );
      }
    }

    // Can neither add nor edit
    if (areRolesOverLimit) {
      if (currentPlan.features.maxCustomRoles === 0) {
        activeMessages.push(
          <p key="plan-exceeded-1">
            Your {currentPlan.displayName} plan comes with the predefined Project manager role and
            no custom roles. You can keep using the legacy roles you have.
          </p>,
        );
      } else {
        activeMessages.push(
          <p key="plan-exceeded-2">
            Your {currentPlan.displayName} plan comes with the predefined Project manager role and{' '}
            {pluralizeWithCount('custom role', currentPlan.features.maxCustomRoles)}. You can keep
            using the legacy roles you have.
          </p>,
        );
        activeMessages.push(getIfYouWantToModifyMessage());
      }
    }
    // Cannot add
    else if (!canCreateNewRole && !!currentPlan.features.maxCustomRoles) {
      activeMessages.push(
        <p key="plan-met">
          You’ve met your plan limit of{' '}
          {pluralizeWithCount('custom role', currentPlan.features.maxCustomRoles)} for the project.
        </p>,
      );

      activeMessages.push(getIfYouWantToCreateMessage());
    }

    return {
      activeMessages,
    };
  };

  const friendlyWarning = getFriendlyWarning();

  if (friendlyWarning.activeMessages.length <= 0) {
    return null;
  }

  return (
    <div className="role-editor__message">
      <Warning subtitle={friendlyWarning.messageTitle}>{friendlyWarning.activeMessages}</Warning>
    </div>
  );
};

RoleConfiguratorMessage.displayName = 'RoleConfiguratorMessage';
