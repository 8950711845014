import { Direction } from '@kontent-ai/types';
import { assert, isArray } from '@kontent-ai/utils';
import Immutable from 'immutable';

export type CompareFunction<T> = (a: T, b: T) => number;

export function splitToGroups<T>(
  items: ReadonlyArray<T>,
  splitCondition: (current: T, previous: T) => boolean,
): ReadonlyArray<ReadonlyArray<T>> {
  const result: Array<T[]> = [];
  let currentGroup: T[] = [];
  let previous: T | null = null;
  for (let i = 0; i < items.length; i++) {
    const current = items[i] as T;
    if (previous) {
      if (splitCondition(current, previous)) {
        result.push(currentGroup);
        currentGroup = [];
      }
    }
    currentGroup.push(current);
    previous = current;
  }
  if (currentGroup.length) {
    result.push(currentGroup);
  }

  return result;
}

export const toArray = <T>(arg: T | ReadonlyArray<T>): ReadonlyArray<T> =>
  isArray(arg) ? arg : [arg];

type Evaluator<T> = (arg: T) => number;

export const getMax = <T>(array: ReadonlyArray<T>, evaluate: Evaluator<T>): T => {
  assert(array.length !== 0, () => 'Cannot get max of an empty array.');
  const firstItem = array[0] as T;
  return array.reduce(
    (maxResult, item) => {
      const value = evaluate(item);
      if (value > maxResult.value) {
        return {
          item,
          value,
        };
      }
      return maxResult;
    },
    {
      item: firstItem,
      value: evaluate(firstItem),
    },
  ).item;
};

export const getMin = <T>(array: ReadonlyArray<T>, evaluate: Evaluator<T>): T => {
  assert(array.length !== 0, () => 'Cannot get min of an empty array.');
  const firstItem = array[0] as T;
  return array.reduce(
    (minResult, item) => {
      const value = evaluate(item);
      if (value < minResult.value) {
        return {
          item,
          value,
        };
      }
      return minResult;
    },
    {
      item: firstItem,
      value: evaluate(firstItem),
    },
  ).item;
};

/** @deprecated Use .filter(notNull). */
export const filterOutNullish = <T>(
  array: ReadonlyArray<T | null | undefined>,
): ReadonlyArray<T> => {
  return array.filter((item) => item !== null && typeof item !== 'undefined') as ReadonlyArray<T>;
};

export function filterOutNullishOrEmpty<TItem>(
  array: ReadonlyArray<TItem | null | undefined>,
): ReadonlyArray<TItem> {
  return array.filter((x) => !!x) as ReadonlyArray<TItem>;
}

export const distinctFilter = <T>(value: T, index: number, self: ReadonlyArray<T>) =>
  self.indexOf(value) === index;

const getDistinctFilterWithComparer = <T>(compare: CompareFunction<T>) => {
  return (value: T, index: number, self: ReadonlyArray<T>) =>
    self.findIndex((item) => compare(item, value) === 0) === index;
};

export const filterOutDuplicates = <T>(
  array: ReadonlyArray<T>,
  compare?: CompareFunction<T>,
): ReadonlyArray<T> =>
  array.filter(compare ? getDistinctFilterWithComparer(compare) : distinctFilter);

export const replace = <T>(array: ReadonlyArray<T>, toBeReplaced: T, replacement: T) =>
  array.map((item) => (item === toBeReplaced ? replacement : item));

export const areTheSame = <T>(
  array: ReadonlyArray<T>,
  otherArray: ReadonlyArray<T>,
  areElementsTheSame: (element: T, otherElement: T) => boolean,
): boolean => {
  return (
    array === otherArray ||
    (array.length === otherArray.length &&
      array.every((element, index) => areElementsTheSame(element, otherArray[index] as T)))
  );
};

export const getItemsDistance = <TItem, TKey>(
  items: ReadonlyArray<TItem> | Immutable.List<TItem>,
  fromKey: TKey,
  toKey: TKey,
  getKey: (item: TItem) => TKey,
): number | null => {
  const fromIndex = items.findIndex((item: TItem) => getKey(item) === fromKey);
  const toIndex = items.findIndex((item: TItem) => getKey(item) === toKey);

  if (fromIndex < 0 || toIndex < 0) {
    return null;
  }
  return toIndex - fromIndex;
};

export const getItemsDirection = <TItem, TKey>(
  items: Immutable.List<TItem> | ReadonlyArray<TItem>,
  fromKey: TKey,
  toKey: TKey,
  getKey: (item: TItem) => TKey,
): Direction | null => {
  const itemsDistance = getItemsDistance(items, fromKey, toKey, getKey);
  if (!itemsDistance) {
    return null;
  }
  return itemsDistance > 0 ? Direction.Forward : Direction.Backward;
};

export const cartesianMultiply = <A, B>(
  a: ReadonlyArray<A>,
  b: ReadonlyArray<B>,
): ReadonlyArray<Pair<A, B>> => {
  const bLength = b.length;
  const result: Array<Pair<A, B>> = Array(a.length * bLength);

  a.forEach((itemA, indexA) => {
    b.forEach((itemB, indexB) => {
      result[indexA * bLength + indexB] = [itemA, itemB];
    });
  });

  return result;
};

function* chunks<TElement>(
  array: readonly TElement[],
  maxChunkSize: number,
): IterableIterator<readonly TElement[]> {
  for (let i = 0; i < array.length; i += maxChunkSize) {
    yield array.slice(i, i + maxChunkSize);
  }
}

export const splitToChunks = <TElement>(
  array: readonly TElement[],
  maxChunkSize: number,
): readonly (readonly TElement[])[] => {
  assert(
    Number.isSafeInteger(maxChunkSize) && maxChunkSize > 0,
    () => `maxChunkSize value ${maxChunkSize} is not a positive safe integer.`,
  );
  return Array.from(chunks(array, maxChunkSize));
};

export const insertAtIndex = <TItem>(
  input: ReadonlyArray<TItem>,
  startIndex: number,
  ...itemsToInsert: [TItem, ...ReadonlyArray<TItem>]
): ReadonlyArray<TItem> => {
  const array = [...input];
  array.splice(startIndex, 0, ...itemsToInsert);
  return array;
};

export const moveToIndex = <TItem>(
  input: ReadonlyArray<TItem>,
  itemToMove: TItem,
  targetIndex: number,
): ReadonlyArray<TItem> => {
  const array = [...input.filter((item) => item !== itemToMove)];
  array.splice(targetIndex, 0, itemToMove);
  return array;
};
