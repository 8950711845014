import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';

const InfoRow: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Row spacingX={Spacing.XS} noWrap>
    {children}
  </Row>
);
InfoRow.displayName = 'InfoRow';

const LabelColumn = styled(Column).attrs(() => ({ width: 'fit-content' }))`
  ${Typography.BodyLargeEmphasis};
  color: ${colorTextDefault};
`;

const ValueColumn = styled(Column)`
  ${Typography.BodyLarge};
  color: ${colorTextDefault};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 0;
`;

const infoRowComposition = {
  Label: LabelColumn,
  Value: ValueColumn,
} as const;

export const LinkInfo = Object.assign(InfoRow, infoRowComposition);
