import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { Content_HideValidationResults } from '../../../../../itemEditor/constants/editorActionTypes.ts';
import {
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_CheckPublishWarnings_Finished,
  ContentListing_DeleteItems_Finished,
  ContentListing_Init_Started,
  ContentListing_MoveToCollection_Finished,
  ContentListing_PublishItems_Finished,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

const initial = Immutable.Set<Uuid>();

export function highlightedContentItems(state = initial, action: Action): Immutable.Set<Uuid> {
  switch (action.type) {
    case ContentListing_CheckPublishWarnings_Finished: {
      const warnings = action.payload.publishWarnings;
      return warnings.itemsWithBrokenLinks.union(warnings.itemsWithBrokenModulars);
    }

    case ContentListing_MoveToCollection_Finished:
    case ContentListing_PublishItems_Finished:
    case ContentListing_ScheduledPublishItems_Finished:
    case ContentListing_UnpublishItems_Finished:
    case ContentListing_ScheduledUnpublishItems_Finished:
    case ContentListing_CancelScheduledPublish_Finished:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentListing_DeleteItems_Finished:
    case ContentListing_UndoArchiveItems_Finished:
      return action.payload.failedIds;

    case Content_HideValidationResults:
    case ContentListing_Init_Started:
      return initial;

    default:
      return state;
  }
}
