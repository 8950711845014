import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import Immutable from 'immutable';
import React from 'react';
import { MultipleOptionSelect } from '../../../../../_shared/components/MultipleOptionSelect/MultipleOptionSelect.tsx';
import { MultipleOptionSelectValidationMessageBehavior } from '../../../../../_shared/components/MultipleOptionSelect/MultipleOptionSelectValidationMessageBehavior.ts';
import { OptionType } from '../../../../../_shared/components/MultipleOptionSelect/MultipleSelectDropDownOption.tsx';
import { MultipleSelectDropDownUserOption } from '../../../../../_shared/components/MultipleOptionSelect/MultipleSelectDropDownUserOption.tsx';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { LabelFor } from '../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { doesEntitySatisfyFilterPhrase } from '../../../../../_shared/utils/filter/nameFilterUtils.ts';
import { joinWithLinebreaks } from '../../../../../_shared/utils/renderUtils.tsx';
import { formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { isEmail } from '../../../../../_shared/utils/validation/isEmail.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import { IExistingUserToInvite } from '../../../../../data/models/users/ExistingUserToInvite.ts';
import { invitationInProgress } from '../../constants/errorMessages.ts';
import { Invitation } from '../../reducers/IUsersAppState.type.ts';
import { createIsNewInvitation } from '../../selectors/createIsNewInvitation.ts';
import { findDuplicateInvitations } from '../../utils/findInvitationDuplicates.ts';
import { countInvitationTokensWithoutLeadingSpaces } from '../../utils/parseInvitations.ts';

interface IInvitationEmailProps {
  readonly autoFocus: boolean;
  readonly changeInvitations: (newSelected: Immutable.List<Invitation>) => void;
  readonly changeSearchText: (newEmail: string) => void;
  readonly collidingContributorEmails: ReadonlySet<string>;
  readonly createUserDetailLink: (userId: UserId) => string;
  readonly emailInputValidationMessages: ReadonlyArray<string>;
  readonly emailsCountedInSubscriptionLimits: ReadonlyArray<string>;
  readonly existingUsersByEmail: Immutable.Map<string, IExistingUserToInvite>;
  readonly invitations: Immutable.List<Invitation>;
  readonly isInviting: boolean;
  readonly searchText: string;
  readonly shouldHighlightNewUsers: boolean;
}

const categoryOption: Invitation = {
  email: 'Users from subscription',
};

const createGetInvitationColor =
  (
    collidingContributorEmails: ReadonlySet<string>,
    highlightNewUsers: boolean,
    duplicateInvitations: ReadonlySet<Invitation>,
    emailsCountedInSubscriptionLimits: ReadonlyArray<string>,
  ) =>
  (i: Invitation): TagColor => {
    if (
      !isEmail(i.email) ||
      collidingContributorEmails.has(i.email.toLocaleLowerCase()) ||
      duplicateInvitations.has(i)
    ) {
      return TagColor.Red;
    }

    return highlightNewUsers && emailsCountedInSubscriptionLimits.includes(i.email)
      ? TagColor.Warning
      : TagColor.Product;
  };

const userWithoutName = ({ email }: Invitation): IUserInfo => ({
  email,
  userId: email,
  lastName: '',
  firstName: '',
});

export const InvitationEmailInput: React.FC<IInvitationEmailProps> = (props) => {
  const searchText = props.searchText;
  const trimmedSearchText = searchText.trim();

  const isNewInvitation = createIsNewInvitation(props.existingUsersByEmail);
  const duplicates = findDuplicateInvitations(props.invitations);
  const filteredExistingUsers = props.existingUsersByEmail.filter((u: IExistingUserToInvite) =>
    doesEntitySatisfyFilterPhrase(trimmedSearchText, u, [formatUserName]),
  );
  const shouldMarkTextRed =
    countInvitationTokensWithoutLeadingSpaces(searchText) > 1 && filteredExistingUsers.isEmpty();
  const existingInvitations: Immutable.List<Invitation> = props.existingUsersByEmail
    .valueSeq()
    .toList();
  const getOptionColor = createGetInvitationColor(
    props.collidingContributorEmails,
    props.shouldHighlightNewUsers,
    duplicates,
    props.emailsCountedInSubscriptionLimits,
  );

  return (
    <LabelFor
      target={() => (
        <Tooltip placement="top" tooltipText={props.isInviting ? invitationInProgress : undefined}>
          <div className="enable-tooltip__disabled-dropdown">
            <MultipleOptionSelect<Invitation>
              autofocus={props.autoFocus}
              collectionName={DataUiCollection.UsersList}
              customSearchTextClassName={
                shouldMarkTextRed ? 'invite-modal__invalid-search-text' : undefined
              }
              delayAutofocus={300}
              getOptionColor={getOptionColor}
              getOptionId={(i) =>
                duplicates.has(i) ? `${i.email}${props.invitations.indexOf(i)}` : i.email
              }
              getOptionName={(i) =>
                isNewInvitation(i)
                  ? i.email
                  : formatUserName(props.existingUsersByEmail.get(i.email))
              }
              getOptionType={(u) =>
                u.email === categoryOption.email ? OptionType.Category : OptionType.Item
              }
              isDisabled={props.isInviting}
              onChange={props.changeInvitations}
              onSearchFieldBlur={() =>
                isEmail(trimmedSearchText) && props.changeSearchText(`${searchText} `)
              }
              onSearchTextChange={props.changeSearchText}
              options={
                filteredExistingUsers.isEmpty()
                  ? existingInvitations
                  : existingInvitations.unshift(categoryOption)
              }
              placeholder="Type an email or select from existing users"
              renderDropdownOption={(optionProps) => (
                <MultipleSelectDropDownUserOption
                  {...optionProps}
                  key={optionProps.getOptionId(optionProps.option)}
                  option={
                    props.existingUsersByEmail.get(optionProps.option.email) ||
                    userWithoutName(optionProps.option)
                  }
                />
              )}
              searchText={searchText}
              selectedOptions={props.invitations}
              validationMessage={
                props.emailInputValidationMessages.length === 0
                  ? undefined
                  : joinWithLinebreaks(props.emailInputValidationMessages)
              }
              validationMessageBehavior={
                MultipleOptionSelectValidationMessageBehavior.ShowWheneverDefined
              }
            />
          </div>
        </Tooltip>
      )}
    >
      Emails
    </LabelFor>
  );
};

InvitationEmailInput.displayName = 'InvitationEmailInput';
