import { InputState } from '@kontent-ai/component-library/Input';
import { propagateInstanceToParent } from '@kontent-ai/hooks';
import React, { ComponentProps } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { FormSection } from '../../../../_shared/components/input/FormSection.tsx';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IWebhookFormShape } from '../../models/IWebhookFormShape.type.ts';
import { SecretField } from './SecretInputField.tsx';
import { Triggers } from './Triggers.tsx';

type Props = {
  readonly workflowStepsInWorkflows: ComponentProps<typeof Triggers>['workflowStepsInWorkflows'];
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IWebhookFormShape>;
  readonly onGenerateNewSecret: (onNewSecret: (secret: string | undefined) => void) => () => void;
  readonly textareaRef: React.MutableRefObject<HTMLElement | null>;
};

export const WebhookFormFields = (props: Props) => {
  const { control } = props.formProps;
  const {
    errors: { triggers: triggersError },
  } = useFormState({
    control,
    name: 'triggers',
  });

  return (
    <>
      <FormSection title="General">
        <FormGroup>
          <ValidatedInput<IWebhookFormShape>
            inputState={props.enabled ? InputState.Default : InputState.Disabled}
            name="name"
            label="Webhook name"
            maxLength={200}
            placeholder="Provide a webhook name"
            autoFocus={props.enabled}
            dataUiInputName={DataUiInput.WebhookName}
            formProps={props.formProps}
          />
        </FormGroup>
        <FormGroup>
          <ValidatedInput<IWebhookFormShape>
            inputState={props.enabled ? InputState.Default : InputState.Disabled}
            name="url"
            label="URL address"
            maxLength={ValidationConstants.WebhookUrlMaxLength}
            placeholder="Enter a URL address"
            formProps={props.formProps}
          />
        </FormGroup>
        <FormGroup>
          <LabelFor
            target={(id) => (
              <Controller
                control={control}
                name="secret"
                render={({ field, fieldState }) => (
                  <SecretField
                    ref={(instance) => {
                      propagateInstanceToParent(props.textareaRef, instance);
                      propagateInstanceToParent(field.ref, instance);
                    }}
                    errorMessage={fieldState.error?.message}
                    inputId={id}
                    onChange={field.onChange}
                    onGenerateNewSecret={props.onGenerateNewSecret}
                    readonly={!props.enabled}
                    value={field.value}
                  />
                )}
              />
            )}
          >
            Secret
          </LabelFor>
        </FormGroup>
      </FormSection>
      <FormSection
        title="Triggers"
        message="Select at least one event that triggers this webhook. The events are evaluated separately. We’ll send a notification for each selected event (if it occurs)."
        errorMessage={triggersError?.message}
      >
        <Controller
          control={control}
          name="triggers"
          render={({ field }) => (
            <Triggers
              workflowStepsInWorkflows={props.workflowStepsInWorkflows}
              disabled={!props.enabled}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
      </FormSection>
    </>
  );
};
