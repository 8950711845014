import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Init_FinishedNoVariant,
  ContentItemEditing_Init_Ready,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { IContentItemVariantReference } from '../../models/contentItem/ContentItemVariantReference.ts';

const initialState: Immutable.Map<Uuid, IContentItemVariantReference> = Immutable.Map();

export function contentItemVariants(
  state: Immutable.Map<Uuid, IContentItemVariantReference> = initialState,
  action: Action,
): Immutable.Map<Uuid, IContentItemVariantReference> {
  switch (action.type) {
    case ContentItemEditing_Init_FinishedNoVariant:
    case ContentItemEditing_Init_Ready: {
      return action.payload.contentItemVariants;
    }

    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeModal_PublishingFinished: {
      const { itemVariantData, defaultVariantReference } = action.payload;
      return state
        .concat({
          [itemVariantData.editedContentItemVariant.id.variantId]:
            itemVariantData.editedContentItemVariantReference,
          ...(defaultVariantReference && { [DefaultVariantId]: defaultVariantReference }),
        })
        .toMap();
    }

    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentItemEditing_CreateNewVersion_Finished: {
      const { itemVariantData } = action.payload;
      return state.set(
        itemVariantData.editedContentItemVariant.id.variantId,
        itemVariantData.editedContentItemVariantReference,
      );
    }

    default:
      return state;
  }
}
