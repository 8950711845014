import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

export const createUpdateDueDate =
  (deps: IDeps) =>
  (
    history: History,
    dueDate: DateTimeStamp,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch) => {
    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin,
        history,
        getUpdatedAssignment: () => ({
          due: dueDate,
        }),
        submittingSection: AssignmentSections.DueDate,
      }),
    );
  };
