import React from 'react';
import { usePopover } from '../../../../../client/component-library/components/Dialogs/Popover/usePopover.tsx';
import { Box } from '../../../layout/Box/Box.tsx';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { Button } from '../../Button/Button.tsx';
import { Card } from '../../Card/Card.tsx';

export type StepCounterProps = Readonly<{
  currentStep: number;
  totalSteps: number;
}>;

type PrimaryButtonProps =
  | Readonly<{
      showPrimaryButton: true;
      onPrimaryButtonClick: () => void;
    }>
  | Readonly<{
      showPrimaryButton?: false;
      onPrimaryButtonClick?: never;
    }>;

type InversePopoverProps = AllOrNoneProps<StepCounterProps> &
  PrimaryButtonProps &
  Readonly<{
    dialogTitleProps: ReturnType<typeof usePopover>['dialogTitleProps'];
    headlineText: string;
    onCloseActionClick: () => void;
  }>;

export const InversePopover = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<InversePopoverProps>
>(
  (
    {
      children,
      currentStep = 0,
      dialogTitleProps,
      headlineText,
      onCloseActionClick,
      onPrimaryButtonClick,
      showPrimaryButton,
      totalSteps = 0,
    },
    forwardedRef,
  ) => {
    const isFinalStep = currentStep >= totalSteps;

    const shouldShowStepCounter = currentStep > 0 && totalSteps > 0;

    return (
      <Box maxWidth={600}>
        <Card cardLabel={headlineText} component="article" ref={forwardedRef} variant="inverse">
          <Card.CloseButton onClose={onCloseActionClick} />
          {shouldShowStepCounter && (
            <Card.Overline>
              <Box typography={Typography.UIParagraph}>
                {currentStep} / {totalSteps}
              </Box>
            </Card.Overline>
          )}
          <Card.Headline {...dialogTitleProps}>{headlineText}</Card.Headline>
          <Card.Body>{children}</Card.Body>
          {showPrimaryButton && (
            <Card.Footer>
              <Box display="flex" justifyContent="end">
                <Button buttonStyle="primary-inverse" onClick={onPrimaryButtonClick} size="small">
                  {isFinalStep ? 'Got it' : 'Next tip'}
                </Button>
              </Box>
            </Card.Footer>
          )}
        </Card>
      </Box>
    );
  },
);

InversePopover.displayName = 'InvertedPopover';
