import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { getGoBackLinkByPath } from '../../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { ContentItemRevisionToolbarActions as ContentItemRevisionToolbarActionsComponent } from '../../components/statusBar/ContentItemRevisionToolbarActions.tsx';

export const ContentItemRevisionToolbarActions: React.FC = () => {
  const location = useLocation();
  const goBackLink = useMemo(() => getGoBackLinkByPath(location.pathname), [location.pathname]);

  return <ContentItemRevisionToolbarActionsComponent to={goBackLink} />;
};

ContentItemRevisionToolbarActions.displayName = 'ContentItemRevisionToolbarActions';
