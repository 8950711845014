import React from 'react';

/**
 * This component should be used only once at the "root" of the app!
 *
 * Default element for appending elements to that we want rendered outside of the DOM hierarchy of the parent component.
 * This is usually done using React portal or Tippy — e.g. modal dialog, popovers, tooltips, etc.
 * We don’t want to append these to the end of the body element for performance reasons (this is Tippy’s default).
 */
export const RootPortalContainer = React.forwardRef<HTMLDivElement>((props, forwardedRef) => (
  <div id="root-portal-container" ref={forwardedRef} {...props} />
));

RootPortalContainer.displayName = 'RootPortalContainer';
