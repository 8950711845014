import { IAssetItemElementWarningResult } from '../utils/itemElementWarningCheckers/types/IAssetItemElementWarningResult.type.ts';
import { IModularContentItemElementWarningResult } from '../utils/itemElementWarningCheckers/types/IModularContentItemElementWarningResult.type.ts';
import { ITaxonomyItemElementWarningResult } from '../utils/itemElementWarningCheckers/types/ITaxonomyItemElementWarningResult.type.ts';
import { ITextWarningResult } from '../utils/itemElementWarningCheckers/types/ITextItemElementWarningResult.type.ts';
import { IUrlSlugWarningResult } from '../utils/itemElementWarningCheckers/types/IUrlSlugWarningResult.type.ts';
import { ItemElementWarningResult } from '../utils/itemElementWarningCheckers/types/Warnings.ts';

export const validElementWarningResult: ItemElementWarningResult = {
  limitationMessages: [],
  requiredMessage: null,
};

export const validTextWarningResultWithRegexValidationMessage = (
  regexValidationMessage: string,
): ITextWarningResult => ({
  ...validElementWarningResult,
  isMaxCharsLimitMet: true,
  isMaxWordsLimitMet: true,
  isRegexValidationMet: true,
  regexValidationMessage,
});

export const validUrlSlugWarningResultWithRegexValidationMessage = (
  regexValidationMessage: string,
): IUrlSlugWarningResult => ({
  ...validElementWarningResult,
  isRegexValidationMet: true,
  regexValidationMessage,
});

export const validAssetItemWarningResult: IAssetItemElementWarningResult = {
  ...validElementWarningResult,
  isFileSizeLimitMet: true,
  isHeightLimitMet: true,
  isNumberOfItemsLimitMet: true,
  isResponsiveImageTypeLimitMet: true,
  isWidthLimitMet: true,
};

export const validModularContentItemWarningResult: IModularContentItemElementWarningResult = {
  ...validElementWarningResult,
  containsOnlyAllowedContentTypes: true,
  isNumberOfItemsLimitMet: true,
};

export const validTaxonomyItemWarningResult: ITaxonomyItemElementWarningResult = {
  ...validElementWarningResult,
  isNumberOfTermsLimitMet: true,
};
