import { InvariantException } from '@kontent-ai/errors';
import {
  IWorkflowStep,
  createAssignmentWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import {
  IChangeWorkflowStepModalData,
  createChangeWorkflowStepModalData,
} from '../reducers/IChangeWorkflowStepModalData.ts';
import {
  ChangeWorkflowStepModalAction,
  OpenWorkflowStepModalAction,
} from '../types/workflowStepModalActionTypes.ts';
import { getWorkflowActionForUpdateStep } from '../utils/changeWorkflowStepModalActionUtils.ts';
import { getFirstWorkflowIdOfItemSelection } from './getFirstWorkflowIdOfItemSelection.ts';
import {
  getPreselectedStepForBulkRestoreFromArchivedStep,
  getPreselectedStepForBulkUpdateStep,
  getPreselectedStepForPublish,
  getPreselectedStepForUnpublish,
} from './getPreselectedWorkflowStep.ts';

export const createInitialChangeWorkflowStepModalDataForBulk = (
  state: IStore,
  workflowAction: OpenWorkflowStepModalAction,
): IChangeWorkflowStepModalData | null => {
  switch (workflowAction) {
    case OpenWorkflowStepModalAction.Publish:
      return createInitialModalDataForBulkPublish(state);
    case OpenWorkflowStepModalAction.RestoreFromArchivedStep:
      return createInitialModalDataForBulkRestore(state);
    case OpenWorkflowStepModalAction.UnpublishAndArchive:
      return createInitialModalDataForBulkUnpublish(state);
    case OpenWorkflowStepModalAction.UpdateStep:
      return createInitialModalDataForBulkUpdateStep(state);
    default:
      throw InvariantException(
        'createInitialChangeWorkflowStepModalDataForBulk.ts: Unknown modal workflow action.',
      );
  }
};

const createInitialModalDataForBulkPublish = (
  state: IStore,
): IChangeWorkflowStepModalData | null => {
  const workflowId = getFirstWorkflowIdOfItemSelection(state);

  if (workflowId === undefined) {
    return null;
  }

  return createChangeWorkflowStepModalDataForBulk(
    getPreselectedStepForPublish(state, workflowId)?.step,
    ChangeWorkflowStepModalAction.Publish,
    workflowId,
  );
};

const createInitialModalDataForBulkUnpublish = (
  state: IStore,
): IChangeWorkflowStepModalData | null => {
  const workflowId = getFirstWorkflowIdOfItemSelection(state);

  if (workflowId === undefined) {
    return null;
  }

  return createChangeWorkflowStepModalDataForBulk(
    getPreselectedStepForUnpublish(state, workflowId)?.step,
    ChangeWorkflowStepModalAction.UnpublishAndArchive,
    workflowId,
  );
};

const createInitialModalDataForBulkRestore = (
  state: IStore,
): IChangeWorkflowStepModalData | null => {
  const workflowId = getFirstWorkflowIdOfItemSelection(state);

  if (workflowId === undefined) {
    return null;
  }

  return createChangeWorkflowStepModalDataForBulk(
    getPreselectedStepForBulkRestoreFromArchivedStep(state, workflowId)?.step,
    ChangeWorkflowStepModalAction.RestoreFromArchivedStep,
    workflowId,
  );
};

const createInitialModalDataForBulkUpdateStep = (
  state: IStore,
): IChangeWorkflowStepModalData | null => {
  const workflowId = getFirstWorkflowIdOfItemSelection(state);

  if (workflowId === undefined) {
    return null;
  }

  const selectedWorkflowStep = getPreselectedStepForBulkUpdateStep(state, workflowId);
  const workflowAction = getWorkflowActionForUpdateStep(selectedWorkflowStep);

  return createChangeWorkflowStepModalDataForBulk(selectedWorkflowStep, workflowAction, workflowId);
};

const createChangeWorkflowStepModalDataForBulk = (
  selectedWorkflowStep: IWorkflowStep | undefined,
  workflowStepAction: ChangeWorkflowStepModalAction,
  workflowId: Uuid,
): IChangeWorkflowStepModalData => {
  return createChangeWorkflowStepModalData({
    isInBulk: true,
    workflowStepAction,
    ...(selectedWorkflowStep
      ? {
          workflowStep: createAssignmentWorkflowStep(selectedWorkflowStep, workflowId),
        }
      : {}),
  });
};
