import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { emailValidation } from '../../../../_shared/utils/validation/isEmail.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { uniquenessValidationBuilder } from '../../../../_shared/utils/validation/isPropertyUnique.ts';
import { ISubscriptionAdmin } from '../../shared/models/SubscriptionAdmin.ts';
import { ISubscriptionAdminInvitationFormShape } from '../components/invitationModal/SubscriptionAdminInvitationForm.tsx';

const emailRequired = nonEmptyValidationBuilder('email');
const firstNameRequired = nonEmptyValidationBuilder('first name');
const lastNameRequired = nonEmptyValidationBuilder('last name');

type Props = {
  readonly existingAdmins: ReadonlyArray<ISubscriptionAdmin>;
};

const uniqueAndCaseInsensitive = uniquenessValidationBuilder<Props, ISubscriptionAdmin>(
  'email',
  (props) => props.existingAdmins,
  (admin) => admin.email.toLowerCase(),
);

const emailUnique = (
  givenEmail: string,
  _: ISubscriptionAdminInvitationFormShape,
  props: Props,
): string | undefined => uniqueAndCaseInsensitive(givenEmail.toLowerCase(), _, props);

export const newAdminInvitationValidationConfig: FormValidatorConfig<
  ISubscriptionAdminInvitationFormShape,
  Props
> = {
  email: [emailRequired, emailValidation, emailUnique],
  firstName: [firstNameRequired],
  lastName: [lastNameRequired],
};

export type { Props as NewAdminInvitationFormValidationResolverProps };
