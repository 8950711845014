import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_SelectSubscription_Clear } from '../../shared/constants/subscriptionManagementActionTypes.ts';
import {
  Subscription_SubscriptionAdminInvitation_Failed,
  Subscription_SubscriptionAdminInvitation_Finished,
  Subscription_SubscriptionAdminInvitation_Started,
} from '../constants/actionTypes.ts';

export const sendingSubscriptionAdminInvitation = (
  state: boolean = false,
  action: Action,
): boolean => {
  switch (action.type) {
    case Subscription_SubscriptionAdminInvitation_Started:
      return true;

    case Subscription_SubscriptionAdminInvitation_Failed:
    case Subscription_SubscriptionAdminInvitation_Finished:
    case Subscription_SelectSubscription_Clear:
      return false;

    default:
      return state;
  }
};
