import { assert } from '@kontent-ai/utils';
import { IPlanFeaturesServerModel } from '../../repositories/serverModels/PlanServerModel.type.ts';

export interface IFeatures {
  readonly areContentCollectionsEnabled: boolean;
  readonly areContentCollectionsPermissionsEnabled: boolean;
  readonly areCustomElementsEnabled: boolean;
  readonly areLanguageRolesEnabled: boolean;
  readonly areMultipleWorkflowsEnabled: boolean;
  readonly auditLogEnabled: boolean;
  readonly isEnhancedAssetManagementEnabled: boolean;
  readonly isSecuredDeliveryApiAvailable: boolean;
  readonly isSubscriptionApiEnabled: boolean;
  readonly webSpotlightEnabled: boolean;
  readonly maxActiveLanguages: number | null;
  readonly maxActiveUsers: number | null;
  readonly maxCustomRoles: number | null;
  readonly maxProjectEnvironments: number | null;
  readonly maxProjectSpaces: number | null;
  readonly maxSubscriptionProjects: number | null;
}

export function createFeatures(props?: Partial<IFeatures>): IFeatures {
  return {
    areContentCollectionsEnabled: false,
    areContentCollectionsPermissionsEnabled: false,
    areCustomElementsEnabled: false,
    areLanguageRolesEnabled: false,
    areMultipleWorkflowsEnabled: false,
    auditLogEnabled: false,
    isSubscriptionApiEnabled: false,
    isEnhancedAssetManagementEnabled: false,
    isSecuredDeliveryApiAvailable: false,
    webSpotlightEnabled: false,
    maxActiveLanguages: 0,
    maxActiveUsers: 0,
    maxCustomRoles: 0,
    maxProjectEnvironments: 0,
    maxProjectSpaces: 0,
    maxSubscriptionProjects: 0,
    ...props,
  };
}

const getMaxLimit = (serverValue: number | null, defaultValue: number | null) => {
  return serverValue === null || Number.isInteger(serverValue) ? serverValue : defaultValue;
};

export function createFeaturesFromServerModel(serverModel: IPlanFeaturesServerModel): IFeatures {
  assert(typeof serverModel === 'object', () => 'Features is not an object');

  const emptyObject = createFeatures();

  return {
    areContentCollectionsEnabled:
      serverModel.areContentCollectionsEnabled ?? emptyObject.areContentCollectionsEnabled,
    areContentCollectionsPermissionsEnabled:
      serverModel.areContentCollectionsPermissionsEnabled ??
      emptyObject.areContentCollectionsPermissionsEnabled,
    areCustomElementsEnabled:
      serverModel.areCustomElementsEnabled ?? emptyObject.areCustomElementsEnabled,
    areLanguageRolesEnabled:
      serverModel.areLanguageRolesEnabled ?? emptyObject.areLanguageRolesEnabled,
    areMultipleWorkflowsEnabled:
      serverModel.multipleWorkflowsEnabled ?? emptyObject.areMultipleWorkflowsEnabled,
    auditLogEnabled: serverModel.auditLogEnabled ?? emptyObject.auditLogEnabled,
    isEnhancedAssetManagementEnabled:
      serverModel.isEnhancedAssetManagementEnabled ?? emptyObject.isEnhancedAssetManagementEnabled,
    isSecuredDeliveryApiAvailable:
      serverModel.isSecuredDeliveryApiEnabled ?? emptyObject.isSecuredDeliveryApiAvailable,
    isSubscriptionApiEnabled:
      serverModel.isSubscriptionApiEnabled ?? emptyObject.isSubscriptionApiEnabled,
    webSpotlightEnabled: serverModel.webSpotlightEnabled ?? emptyObject.webSpotlightEnabled,
    maxActiveLanguages: getMaxLimit(
      serverModel.maxProjectLanguages,
      emptyObject.maxActiveLanguages,
    ),
    maxActiveUsers: getMaxLimit(serverModel.maxUsers, emptyObject.maxActiveUsers),
    maxCustomRoles: getMaxLimit(serverModel.maxCustomRoles, emptyObject.maxCustomRoles),
    maxProjectEnvironments: getMaxLimit(
      serverModel.maxProjectEnvironments,
      emptyObject.maxProjectEnvironments,
    ),
    maxProjectSpaces: getMaxLimit(serverModel.maxProjectSpaces, emptyObject.maxProjectSpaces),
    maxSubscriptionProjects: getMaxLimit(
      serverModel.maxSubscriptionProjects,
      emptyObject.maxSubscriptionProjects,
    ),
  };
}
