import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';

type FeedbackCategoryLabelPros = React.PropsWithChildren &
  Readonly<{
    isOptional?: boolean;
    marginBottom?: Spacing | string;
  }>;

export const FeedbackInputLabel: React.FC<FeedbackCategoryLabelPros> = ({
  children,
  isOptional = false,
  marginBottom,
}) => {
  const optionalLabel = (
    <Box typography={Typography.LabelMedium} display="contents">
      Optional
    </Box>
  );

  return (
    <Box marginBottom={marginBottom}>
      <Row spacing={Spacing.XS}>
        <Column width="content">
          <Box typography={Typography.HeadlineMedium}>{children}</Box>
        </Column>
        {isOptional ? <Column width="content">{optionalLabel}</Column> : null}
      </Row>
    </Box>
  );
};
