import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemEditing_CommentThread_Blurred } from '../../constants/contentItemEditingActionTypes.ts';

const commentThreadBlurred = () =>
  ({
    type: ContentItemEditing_CommentThread_Blurred,
  }) as const;

export type BlurCommentThreadActionsType = ReturnType<typeof commentThreadBlurred>;

export const createBlurCommentThread = () => (): ThunkFunction => (dispatch, getState) => {
  const focusedThreadId =
    getState().contentApp.editedContentItemVariantComments.focusedCommentThreadId;

  // Avoid unnecessary blur action dispatching
  if (focusedThreadId) {
    dispatch(commentThreadBlurred());
  }
};
