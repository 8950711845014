import { useRef } from 'react';
import { ContentItemSidebarPlaceholder } from '../../itemEditor/features/ContentItemEditing/components/sidebar/ContentItemSidebarPlaceholder.tsx';
import { ContentItemEditor } from '../../itemEditor/features/ContentItemEditing/containers/ContentItemEditor.tsx';
import { ContentItemEditorContextualHelp } from '../../itemEditor/features/ContentItemEditing/containers/ContentItemEditorContextualHelp.tsx';
import { WaitForEditedItemInitialized } from '../../itemEditor/features/ContentItemEditing/containers/WaitForEditedItemInitialized.tsx';
import { ContentItemSidebar } from '../../itemEditor/features/ContentItemEditing/containers/sidebar/ContentItemSidebar.tsx';
import { WebSpotlightTabs } from './WebSpotlightTabs.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';

export const WebSpotlightEditorView = () => {
  const workspaceRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <WebSpotlightTabs />
      <WebSpotlightWorkspace withItemEditor workspaceRef={workspaceRef}>
        <ContentItemEditor shouldRenderSidebar={false} />
        <ContentItemEditorContextualHelp />
      </WebSpotlightWorkspace>
      <WaitForEditedItemInitialized>
        <ContentItemSidebar hasPlaceholder outsideClickElementRefs={workspaceRef} />
        <ContentItemSidebarPlaceholder />
      </WaitForEditedItemInitialized>
    </>
  );
};
