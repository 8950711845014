import { EditorState } from 'draft-js';
import { ClipboardEvent } from 'react';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ISetRichTextClipboardAction } from '../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { isRichTextElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { ElementReference } from '../../containers/hooks/useItemElementReference.ts';

type ISetRichTextElementClipboardAction = (
  e: ClipboardEvent,
  editorState: EditorState,
  element: ElementReference,
) => ThunkFunction;

interface IDeps {
  readonly setRichTextClipboard: ISetRichTextClipboardAction;
}

export const createSetRichTextElementClipboardAction =
  (deps: IDeps): ISetRichTextElementClipboardAction =>
  (e, editorState, element) =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariantElements },
    } = getState();

    const rootRichTextElement = getElementById(
      element.rootRichTextElementId ?? element.elementId,
      editedContentItemVariantElements,
    );
    if (!isRichTextElement(rootRichTextElement)) {
      return;
    }

    dispatch(
      deps.setRichTextClipboard(e, editorState, rootRichTextElement.contentComponents, new Map()),
    );
  };
