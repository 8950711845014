import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';

export const InnovationLabEmptyState: React.FC = () => (
  <EmptyState>
    <EmptyState.Title>We will introduce new features soon.</EmptyState.Title>
    <EmptyState.Content>
      It begins with a little action. Go ahead and activate a feature.
    </EmptyState.Content>
  </EmptyState>
);
