import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { IWebhookSetting } from '../../../data/models/webhooks/WebhookSetting.ts';
import { IWorkflowStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { getAllWorkflowStepsById } from '../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import {
  ContentTriggerOperation,
  WebhookTriggerType,
} from '../../../repositories/serverModels/WebhookServerModel.ts';
import { ITrigger } from '../models/IWebhookFormShape.type.ts';
import { isWebhookNew } from './isWebhookNew.ts';

export const ItemContentTriggers: ReadonlyArray<ITrigger> = [
  {
    id: ContentTriggerOperation.Publish,
    name: 'Publish',
    label: 'Publish',
  },
  {
    id: ContentTriggerOperation.Unpublish,
    name: 'Unpublish',
    label: 'Unpublish',
  },
];

export const PreviewItemContentTriggers: ReadonlyArray<ITrigger> = [
  {
    id: ContentTriggerOperation.Upsert,
    name: 'Create or Update',
    label: 'Create or Update',
  },
  {
    id: ContentTriggerOperation.Archive,
    name: 'Delete',
    label: 'Delete',
  },
];

export const PreviewTaxonomyContentTriggers: ReadonlyArray<ITrigger> = [
  {
    id: ContentTriggerOperation.Archive,
    name: 'Delete',
    label: 'Delete',
  },
  {
    id: ContentTriggerOperation.Restore,
    name: 'Restore',
    label: 'Restore',
  },
  {
    id: ContentTriggerOperation.Upsert,
    name: 'Create or Update',
    label: 'Create or Update',
  },
];

export const TaxonomyContentTriggers: ReadonlyArray<ITrigger> = [
  {
    id: ContentTriggerOperation.Archive,
    name: 'Delete',
    label: 'Delete',
  },
  {
    id: ContentTriggerOperation.Restore,
    name: 'Restore',
    label: 'Restore',
  },
  {
    id: ContentTriggerOperation.Upsert,
    name: 'Create or Update',
    label: 'Create or Update',
  },
];

export const ManagementApiContentTriggers: ReadonlyArray<ITrigger> = [
  {
    id: ContentTriggerOperation.Create,
    name: 'Create',
    label: 'Create',
  },
  {
    id: ContentTriggerOperation.Archive,
    name: 'Delete',
    label: 'Delete',
  },
  {
    id: ContentTriggerOperation.Restore,
    name: 'Restore',
    label: 'Restore',
  },
];

export const hasValidWebhookTriggerConfiguration = (state: IStore, webhookId: Uuid): boolean => {
  const {
    data: {
      webhooks: { byId },
    },
  } = state;

  const allWorkflowStepsByIds = getAllWorkflowStepsById(state.data.workflows.byId);
  const webhooksWithNoTrigger = getWebhooksWithNoTrigger(
    byId,
    Immutable.OrderedMap(allWorkflowStepsByIds),
  );
  const webhooksWithNoTriggerIds = webhooksWithNoTrigger.map(
    (webhook: IWebhookSetting) => webhook.id,
  );

  return !webhooksWithNoTriggerIds.contains(webhookId);
};

const hasValidWorkflowStepsTrigger = (
  stepsByIds: Immutable.OrderedMap<Uuid, IWorkflowStep>,
  workflowStepsTriggerIds: ReadonlySet<Uuid>,
): boolean => {
  const existingStepIds = stepsByIds.keySeq().toList();
  return (
    !Collection.isEmpty(workflowStepsTriggerIds) &&
    Array.from(workflowStepsTriggerIds).some((id: Uuid) => existingStepIds.contains(id))
  );
};

export const getItemWorkflowStepTriggerIds = memoize.maxOne(
  (workflowChanges: Immutable.Map<WebhookTriggerType, ReadonlyArray<Uuid>>): ReadonlySet<Uuid> => {
    const itemWorkflowChanges = workflowChanges.get(WebhookTriggerType.ContentItemVariant) ?? [];
    return new Set(itemWorkflowChanges);
  },
);

export const getWebhooksWithNoTrigger = memoize.maxOne(
  (
    webhooks: Immutable.Map<Uuid, IWebhookSetting>,
    stepsByIds: Immutable.OrderedMap<Uuid, IWorkflowStep>,
  ): Immutable.List<IWebhookSetting> =>
    webhooks
      .sort((a, b) => a.order - b.order)
      .filter(
        (webhook: IWebhookSetting) =>
          !isWebhookNew(webhook) &&
          !hasValidWorkflowStepsTrigger(
            stepsByIds,
            getItemWorkflowStepTriggerIds(webhook.workflowStepChangeTriggers),
          ) &&
          webhook.deliveryApiContentChangeTriggers.isEmpty() &&
          webhook.managementApiContentChangeTriggers.isEmpty() &&
          webhook.previewDeliveryApiContentChangeTriggers.isEmpty(),
      )
      .toList(),
);

export const memoizedWebhooks = memoize.maxOne((webhooks: Immutable.Map<Uuid, IWebhookSetting>) => {
  const sortedWebhooks = webhooks.sort((a, b) => a.order - b.order);
  return {
    newWebhook: sortedWebhooks.filter(isWebhookNew).first(),
    webhooks: sortedWebhooks.filterNot(isWebhookNew).toList(),
  };
});
