import { getScrollParent } from '@kontent-ai/DOM';
import { RefObject, useEffect, useRef } from 'react';

/**
 * Retrieves a scroll parent ref for the given element ref
 * See getScrollParent function to see what a scroll parent means
 */
export const useScrollParent = (
  ref: RefObject<Element | null | undefined>,
): { readonly current: Element } => {
  // We need to get the scroll parent after rendering to prevent forced reflow
  const scrollParentRef = useRef<Element>(document.body);
  useEffect(() => {
    const newScrollParent = ref.current && getScrollParent(ref.current);
    scrollParentRef.current = newScrollParent ?? document.body;
  }, [ref]);

  return scrollParentRef;
};
