import Immutable from 'immutable';
import { removeNonExistingTaxonomyTerms } from '../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { validateNumberOfItems } from '../../../../../../_shared/utils/validation/limitations/validateNumberOfItems.ts';
import { ITaxonomyGroup } from '../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTypeElementData } from '../../../models/elements/TaxonomyTypeElementData.ts';
import { getIntegerFromStringOrNull } from '../../typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { hasTaxonomyDefaultValue } from '../../typeElementDefaultValueUtils.ts';

export const areLimitationsInTaxonomyDefaultValueMet = (
  typeElement: ITaxonomyTypeElementData,
  taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
): boolean => {
  if (!typeElement.taxonomyGroupId || !hasTaxonomyDefaultValue(typeElement)) {
    return true;
  }

  const taxonomyGroup = taxonomyGroups.get(typeElement.taxonomyGroupId);
  if (!taxonomyGroup) {
    return true;
  }

  const termsCount = removeNonExistingTaxonomyTerms(
    new Set(typeElement.defaultValue),
    taxonomyGroup,
  ).size;
  const minItems = getIntegerFromStringOrNull(typeElement.minItems);
  const maxItems = getIntegerFromStringOrNull(typeElement.maxItems);

  const isNumberOfTermsLimitMet = validateNumberOfItems(minItems, maxItems, termsCount);

  return isNumberOfTermsLimitMet;
};
