import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';

type Props = {
  readonly featureName: string;
};

export const InnovationLabGenericDeactivationWarning: React.FC<Props> = ({ featureName }) => (
  <Stack spacing={Spacing.M}>
    <Callout calloutType="friendlyWarning" hideSubheadline>
      <p>
        <b>Deactivating this Innovation Lab feature cannot be undone</b>
      </p>
      <p>
        You can deactivate the feature at any time, but be aware that it will not be possible to
        activate it again due to technical limitations.
      </p>
      <p>Currently, the feature is active for you and other users in this project.</p>
    </Callout>
    <p>Are you sure you want to deactivate the {featureName}?</p>
  </Stack>
);
