import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Data_Sitemap_Failed,
  Data_Sitemap_Started,
  Data_Sitemap_Success,
} from '../../../constants/dataActionTypes.ts';

const initialState = LoadingStatus.InitialEmpty;

export function loadingStatus(state = initialState, action: Action): LoadingStatus {
  switch (action.type) {
    case Data_Sitemap_Started:
      return LoadingStatus.Loading;

    case Data_Sitemap_Success:
      return LoadingStatus.Loaded;

    case Data_Sitemap_Failed:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
