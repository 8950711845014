import { useCallback, useEffect, useRef, useState } from 'react';
import { getStringActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { usePendingAiActionWithSession } from '../../../../../_shared/features/AI/hooks/usePendingAiActionWithSession.ts';
import { AiActionProps } from '../../../../../_shared/features/AI/types/AiActionProps.type.ts';
import { AiError } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { AiActionSource } from '../../../../../_shared/models/events/AiActionEventData.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { useEditorStateCallbacks } from '../../../../../applications/richText/editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../../applications/richText/editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../../../applications/richText/editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../../applications/richText/editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  Render,
} from '../../../../../applications/richText/editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../../applications/richText/editorCore/utils/decorable.ts';
import { CommentsPlugin } from '../../../../../applications/richText/plugins/comments/CommentsPlugin.tsx';
import { StylesPlugin } from '../../../../../applications/richText/plugins/visuals/StylesPlugin.tsx';
import { isContentEmpty } from '../../../../../applications/richText/utils/general/editorContentUtils.ts';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createReviewByGuidelinesRequest } from '../../../repositories/serverModels/AiServerModels.reviewByGuidelines.ts';
import { AiReviewAction } from './containers/AiReviewAction.tsx';
import { useCreateReviewComments } from './useCreateReviewComments.tsx';
import { ReviewCommentServerModel, parseReviewComments } from './utils/reviewCommentServerModel.ts';
import { getNewComments, getReviewComments } from './utils/reviewCommentUtils.ts';

type ReviewByGuidelinesPluginProps = AiActionProps & {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
};

export type ReviewByGuidelinesPlugin = EditorPlugin<
  None,
  ReviewByGuidelinesPluginProps,
  None,
  None,
  [StylesPlugin, CommentsPlugin]
>;

const getGuidelines = (state: IStore, aiGuidelinesIds: ReadonlyArray<Uuid>): string => {
  const selectedGuidelineId = aiGuidelinesIds[0];
  return (
    (selectedGuidelineId && state.data.aiGuidelines.byId.get(selectedGuidelineId)?.guidelines) ?? ''
  );
};

export const ReviewByGuidelinesPlugin: PluginComponent<ReviewByGuidelinesPlugin> = (props) => {
  const { element, aiGuidelinesIds } = props;

  const guidelines = useSelector((state) => getGuidelines(state, aiGuidelinesIds));
  const hasGuidelines = !isEmptyOrWhitespace(guidelines);

  const [anyAiReviewCommentsAdded, setAnyAiReviewCommentsAdded] = useState<boolean>(false);
  const [lastError, setLastError] = useState<AiError | null>(null);

  const { aiSessionId, elementOperationTrackingData, trackFinishedAction, trackStartingAction } =
    useAiActionTrackingWithSession(props.element);

  const { decorateWithEditorStateCallbacks, getEditorState } =
    useEditorStateCallbacks<ReviewByGuidelinesPlugin>();

  const { aiOperationState, startPendingAiAction, finishPendingAiAction } =
    usePendingAiActionWithSession(aiSessionId);

  const { run, result } = useAiTask(AiActionName.ReviewByGuidelines, getStringActionResult);

  const { createComments, decorateWithCreateCommentsCallbacks } = useCreateReviewComments(element);

  // We keep only server model at this level for more effective detection of new comments without having to compare content states
  const comments = parseReviewComments(result);
  const processedComments = useRef<ReadonlyArray<ReviewCommentServerModel>>([]);

  useEffect(() => {
    if (comments?.length) {
      const newParsedComments = getNewComments(comments, processedComments.current);
      if (!newParsedComments.length) {
        return;
      }

      const newComments = getReviewComments(newParsedComments);
      if (!newComments.length) {
        return;
      }

      createComments(newComments).then((addedThreads) => {
        if (addedThreads.length > 0) {
          setAnyAiReviewCommentsAdded(true);
        }
      });

      processedComments.current = [...processedComments.current, ...newParsedComments];
    }
  }, [comments, createComments]);

  useOnFinishedAiActionTask(result.isFinished, () => {
    if (result.trackingParams) {
      trackFinishedAction(result.trackingParams);
    }
    setLastError(result.error);
    finishPendingAiAction();
  });

  const startReviewByGuidelinesAction = useCallback(() => {
    processedComments.current = [];
    setAnyAiReviewCommentsAdded(false);
    startPendingAiAction();

    const content = getEditorState().getCurrentContent();

    run(createReviewByGuidelinesRequest(content, guidelines, elementOperationTrackingData), {
      element,
    });

    trackStartingAction({
      action: AiActionName.ReviewByGuidelines,
      source: AiActionSource.Element,
    });
  }, [
    element,
    elementOperationTrackingData,
    getEditorState,
    guidelines,
    run,
    startPendingAiAction,
    trackStartingAction,
  ]);

  const renderOverlays: Decorator<Render<ReviewByGuidelinesPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => (
      <>
        {baseRenderOverlays(state)}
        <AiReviewAction
          aiOperationState={aiOperationState}
          anyAiReviewCommentsAdded={anyAiReviewCommentsAdded}
          element={element}
          error={lastError}
          hasGuidelines={hasGuidelines}
          isEmpty={isContentEmpty(state.editorState.getCurrentContent())}
          perform={startReviewByGuidelinesAction}
        />
      </>
    ),
    [
      aiOperationState,
      anyAiReviewCommentsAdded,
      element,

      hasGuidelines,
      lastError,
      startReviewByGuidelinesAction,
    ],
  );

  const apply: Apply<ReviewByGuidelinesPlugin> = useCallback(
    (state) => {
      decorateWithCreateCommentsCallbacks(state);
      decorateWithEditorStateCallbacks(state);
      state.renderOverlays.decorate(renderOverlays);

      return {};
    },
    [decorateWithCreateCommentsCallbacks, decorateWithEditorStateCallbacks, renderOverlays],
  );

  return useEditorWithPlugin(props, { apply });
};
