import { withAutoDispatcher } from '../../../../../_shared/components/AutoDispatcher.tsx';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { loadListingAssets } from '../../../../../data/actions/thunkDataActions.ts';
import { IScrollGridState } from '../../stores/IAssetLibraryState.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IAssetScrollGridObservedState {
  readonly orderBy: AssetsOrderBy;
  readonly scrollGridState: IScrollGridState;
}

const shouldDispatch = (oldState: IStore, newState: IStore): boolean => {
  const oldScroll = oldState.assetLibraryApp.assetListingScrollGridState;
  const newScroll = newState.assetLibraryApp.assetListingScrollGridState;

  return (
    oldScroll.scrollPositionPx !== newScroll.scrollPositionPx ||
    oldScroll.availableHeightPx !== newScroll.availableHeightPx
  );
};

const mapObservedState = (state: IStore, ownProps: Props): IAssetScrollGridObservedState => ({
  orderBy: ownProps.orderBy,
  scrollGridState: state.assetLibraryApp.assetListingScrollGridState,
});

type Props = {
  readonly orderBy: AssetsOrderBy;
};

// do not rewrite to VFC+useEffect unless you handle chaining of promises (see testing notes from KCL-7305 or commit description)
export const AssetScrollStateObserver = withAutoDispatcher<Props, IAssetScrollGridObservedState>(
  mapObservedState,
  ({ orderBy }) => loadListingAssets({ requestTrigger: AssetsRequestTrigger.UserScroll, orderBy }),
  100,
  shouldDispatch,
)(() => null);
