import { getElementOffset } from '@kontent-ai/DOM';
import { memoize } from '@kontent-ai/memoization';
import { ContentBlock, ContentState } from 'draft-js';
import Immutable from 'immutable';
import { ICompiledContentType } from '../../../contentInventory/content/models/CompiledContentType.ts';
import { EditableTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getBlockCommentThreadReferences } from '../../../richText/plugins/comments/api/editorCommentUtils.ts';
import {
  IComponentPathItem,
  getContentComponentId,
} from '../../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { isContentComponent } from '../../../richText/utils/blocks/blockTypeUtils.ts';
import { getBlocks } from '../../../richText/utils/general/editorContentGetters.ts';
import { CommentThreadType, IInlineCommentThread } from '../../models/comments/CommentThreads.ts';
import { IContentComponent } from '../../models/contentItem/ContentComponent.ts';
import { IBaseTextItemElement } from '../../models/contentItemElements/IBaseTextItemElement.type.ts';
import { IRichTextItemElement } from '../../models/contentItemElements/RichTextItemElement.ts';
import { isRichTextElement } from '../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import {
  EmptyThreadReferences,
  ICommentThreadWithLocation,
  getCommentedSegmentSelector,
  getCommentedTextSelector,
} from '../commentUtils.ts';
import { IItemElementCommentManagerDependencies } from '../getItemElementCommentManager.ts';
import { createItemElementWithInitialValue } from '../itemElementCreator.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

export function getBaseTextItemElementCommentManager(
  deps: IItemElementCommentManagerDependencies,
): IItemElementCommentManager<IBaseTextItemElement> {
  return {
    getCommentThreadReferences: (
      element,
      threads,
      loadedContentItemTypes,
      _,
      rootRichTextElement,
    ) => {
      const content = element._editorState.getCurrentContent();
      if (isRichTextElement(element)) {
        return getCommentThreadReferencesFromContent(
          content,
          rootRichTextElement ?? element,
          threads,
          loadedContentItemTypes,
          deps,
        );
      }
      return getCommentThreadReferencesFromContent(
        content,
        null,
        threads,
        loadedContentItemTypes,
        deps,
      );
    },
    getCommentThreadOffset,
  };
}

export function getCommentThreadOffset(commentThread: IInlineCommentThread): number | null {
  const selector =
    commentThread.threadType === CommentThreadType.RichText
      ? getCommentedTextSelector
      : getCommentedSegmentSelector;
  const cssSelector = selector(commentThread);

  return cssSelector ? getElementOffset(cssSelector) : null;
}

function getContentComponentElementCommentReferences(
  contentComponent: IContentComponent,
  element: EditableTypeElement,
  rootRichTextElement: IRichTextItemElement,
  threads: ReadonlyArray<IInlineCommentThread>,
  loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
  deps: IItemElementCommentManagerDependencies,
): ReadonlyArray<ICommentThreadWithLocation> {
  const compiledElement =
    contentComponent.elements.find(
      (e) => e.elementId === element.elementId && e.type === element.type,
    ) ?? createItemElementWithInitialValue(element);

  if (compiledElement) {
    const commentManager = deps.getItemElementCommentManager(compiledElement.type);
    if (commentManager?.getCommentThreadReferences) {
      return commentManager.getCommentThreadReferences(
        compiledElement,
        threads,
        loadedContentItemTypes,
        contentComponent.id,
        rootRichTextElement,
      );
    }
  }
  return EmptyThreadReferences;
}

const getContentComponentCommentReferences = memoize.weak(
  (
    contentComponent: IContentComponent,
    rootRichTextElement: IRichTextItemElement,
    threads: ReadonlyArray<IInlineCommentThread>,
    loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
    deps: IItemElementCommentManagerDependencies,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    const contentType = loadedContentItemTypes.get(contentComponent.contentTypeId);
    const threadReferences = (contentType?.contentElements || [])
      .filter(isEditableElement)
      .flatMap((contentTypeElement) => {
        const contentComponentThreads = getContentComponentElementCommentReferences(
          contentComponent,
          contentTypeElement,
          rootRichTextElement,
          threads,
          loadedContentItemTypes,
          deps,
        );

        return contentComponentThreads.map((threadReference: ICommentThreadWithLocation) => {
          const pathItem: IComponentPathItem = {
            contentComponentId: contentComponent.id,
            contentGroupId: contentTypeElement.contentGroupId,
          };

          return {
            commentThread: threadReference.commentThread,
            componentPath: threadReference.componentPath
              ? [pathItem, ...threadReference.componentPath]
              : [pathItem],
          };
        });
      });

    return threadReferences;
  },
);

// This memoization is for blocks that remain the same while some block is edited
const getCommentThreadReferencesFromBlock = memoize.weak(
  (
    block: ContentBlock,
    rootRichTextElement: IRichTextItemElement | null,
    threads: ReadonlyArray<IInlineCommentThread>,
    loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
    deps: IItemElementCommentManagerDependencies,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    if (isContentComponent(block)) {
      const contentComponentId = getContentComponentId(block);
      if (!contentComponentId) {
        return [];
      }

      const contentComponent = rootRichTextElement?.contentComponents.get(contentComponentId);
      return contentComponent && rootRichTextElement
        ? getContentComponentCommentReferences(
            contentComponent,
            rootRichTextElement,
            threads,
            loadedContentItemTypes,
            deps,
          )
        : [];
    }

    const commentThreadSegments = getBlockCommentThreadReferences(block);

    const commentThreadReferences = commentThreadSegments.flatMap((commentThreadSegment) =>
      threads
        .filter(
          (commentThread: IInlineCommentThread) =>
            commentThread.externalSegmentId === commentThreadSegment.segmentId,
        )
        .map((commentThread: IInlineCommentThread) => ({
          commentThread,
          componentPath: null,
        })),
    );

    return commentThreadReferences;
  },
);

const getCommentThreadReferencesFromContentOutput = memoize.allForever(
  (
    ...threadReferencesPerBlock: ReadonlyArray<ReadonlyArray<ICommentThreadWithLocation>>
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    const existingThreadIds = new Set<Uuid>();
    const allCommentThreadReferences = threadReferencesPerBlock.flatMap((threadReferences) =>
      threadReferences.flatMap((threadReference) => {
        if (existingThreadIds.has(threadReference.commentThread.id)) {
          return [];
        }
        existingThreadIds.add(threadReference.commentThread.id);
        return [threadReference];
      }),
    );

    return allCommentThreadReferences;
  },
);

function getCommentThreadReferencesFromContent(
  content: ContentState,
  rootRichTextElement: IRichTextItemElement | null,
  threads: ReadonlyArray<IInlineCommentThread>,
  loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
  deps: IItemElementCommentManagerDependencies,
): ReadonlyArray<ICommentThreadWithLocation> {
  if (!threads.length) {
    return EmptyThreadReferences;
  }

  const threadsPerBlock = getBlocks(content)
    .map((block) =>
      getCommentThreadReferencesFromBlock(
        block,
        rootRichTextElement,
        threads,
        loadedContentItemTypes,
        deps,
      ),
    )
    .filter((references) => references.length);

  if (!threadsPerBlock.length) {
    return EmptyThreadReferences;
  }

  const allCommentThreads = getCommentThreadReferencesFromContentOutput(...threadsPerBlock);
  return allCommentThreads;
}
