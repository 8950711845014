import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Environment_CreateEnvironment_Initialized } from '../../../projectSettings/environments/constants/environmentActionTypes.ts';
import {
  ProjectListing_ActivateProject_Finished,
  ProjectListing_ActivateProject_Started,
  ProjectListing_DeactivateProject_Finished,
  ProjectListing_DeactivateProject_Started,
  ProjectListing_ProjectCloning_Initialized,
  ProjectListing_ProjectCreation_Initialized,
  Project_CopyState_Finished,
  Project_CreateSampleProject_Failed,
  Project_CreateSampleProject_Finished,
  Project_NewProject_Finished,
} from '../../constants/projectActionTypes.ts';
import { ProjectUiTransitionState } from '../../types/projectUiTransitionState.ts';

export function projectsUiTransitionState(
  state: Immutable.Map<Uuid, ProjectUiTransitionState> = Immutable.Map(),
  action: Action,
) {
  switch (action.type) {
    case ProjectListing_ActivateProject_Started: {
      const { projectId }: { projectId: Uuid } = action.payload;
      return state.set(projectId, ProjectUiTransitionState.Activating);
    }

    case ProjectListing_DeactivateProject_Started: {
      const { projectId }: { projectId: Uuid } = action.payload;
      return state.set(projectId, ProjectUiTransitionState.Deactivating);
    }

    case ProjectListing_ProjectCreation_Initialized:
    case ProjectListing_ProjectCloning_Initialized:
    case Environment_CreateEnvironment_Initialized: {
      const { projectId }: { projectId: Uuid } = action.payload;
      return state.set(projectId, ProjectUiTransitionState.SettingUp);
    }

    case ProjectListing_ActivateProject_Finished:
    case ProjectListing_DeactivateProject_Finished: {
      const { projectId }: { projectId: Uuid } = action.payload;
      return state.delete(projectId);
    }

    case Project_CreateSampleProject_Failed: {
      return state.filter((s) => s !== ProjectUiTransitionState.SettingUp).toMap();
    }

    case Project_CreateSampleProject_Finished: {
      return state.delete(action.payload.projectId);
    }

    case Project_NewProject_Finished:
    case Project_CopyState_Finished: {
      const { projectId } = action.payload.data;
      return state.delete(projectId);
    }

    default:
      return state;
  }
}
