import { InputState } from '@kontent-ai/component-library/Input';
import { IBaseSelectItem, ISelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React, { useMemo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getShortenedTextWithEllipsis } from '../../../../_shared/utils/stringUtils.ts';
import { IBaseEnvironment } from '../models/IBaseEnvironment.type.ts';

export interface ISourceEnvironmentStateProps {
  readonly caption: string;
  readonly disabled: boolean;
  readonly label: string;
  readonly onSelect: (selectedOption: Uuid) => void;
  readonly options: readonly IBaseEnvironment[];
  readonly selectedEnvironmentId: Uuid;
}

export const OriginEnvironmentSelector: React.FC<ISourceEnvironmentStateProps> = ({
  disabled,
  onSelect,
  options,
  selectedEnvironmentId,
  label,
  caption,
}) => {
  const displayedNameLength = 80;

  const singleSelectItems: ISelectItem<IBaseSelectItem>[] = useMemo(
    () =>
      options.map(({ environmentId, environmentName }) => ({
        id: environmentId,
        label: getShortenedTextWithEllipsis(environmentName, displayedNameLength),
      })),
    [options],
  );

  return (
    <SingleSelect
      inputState={disabled ? InputState.Disabled : InputState.Default}
      items={singleSelectItems}
      onSelectionChange={(environmentId: string) => onSelect(environmentId)}
      label={label}
      caption={caption}
      selectedItemId={selectedEnvironmentId}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Environments)}
    />
  );
};
