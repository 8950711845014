import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { VariantTranslationCondition } from '../../../../constants/VariantTranslationCondition.ts';
import { getFailedTranslationCondition } from '../../../../utils/editingActions/getFailedTranslationCondition.ts';

const noAction = () => undefined;

export const DisabledTranslateVariantAction = ({
  children,
  ...props
}: EditingActionDynamicDetailProviderOwnProps) => {
  const failedCondition = useSelector((state) => getFailedTranslationCondition(state));
  const tooltip = getTooltip(failedCondition);

  return (
    <EditingActionDynamicDetailProvider {...props} tooltip={tooltip} performAction={noAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};

const getTooltip = (condition: VariantTranslationCondition): string => {
  switch (condition) {
    case VariantTranslationCondition.NoOtherVariantExists:
      return 'Nothing to translate. Provide content in another language first.';
    case VariantTranslationCondition.NoTranslatableElements:
      return 'There are no elements in this content item that AI could translate. Add text or rich text elements to the content type first.';
    case VariantTranslationCondition.TranslationIsRunning:
      return 'Content item is already being translated. Please wait until translation is finished.';
    case VariantTranslationCondition.None:
      return '';
  }
};
