import React from 'react';
import { useLocation } from 'react-router';
import { highlightOpenTasks } from '../../../../../../applications/itemEditor/features/ContentItemEditing/actions/thunks/highlightOpenTasks.ts';
import { IFriendlyWarning } from '../../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { modalDismissed } from '../../../../../actions/sharedActions.ts';
import { useDispatch } from '../../../../../hooks/useDispatch.ts';
import { FriendlyWarningMessageForEditing as FriendlyWarningMessageForEditingComponent } from '../../../components/Elements/PublishingFriendlyWarningSection/FriendlyWarningMessageForEditing.tsx';

interface IFriendlyWarningMessageForEditingProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarnings: ReadonlyArray<IFriendlyWarning>;
}

export const FriendlyWarningMessageForEditing: React.FC<IFriendlyWarningMessageForEditingProps> = ({
  activeUsersNames,
  friendlyWarnings,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const onUnfinishedTasksWarningClick = () => {
    dispatch(modalDismissed());
    dispatch(highlightOpenTasks(location.pathname));
  };

  return (
    <FriendlyWarningMessageForEditingComponent
      activeUsersNames={activeUsersNames}
      friendlyWarnings={friendlyWarnings}
      onUnfinishedTasksWarningClick={onUnfinishedTasksWarningClick}
    />
  );
};

FriendlyWarningMessageForEditing.displayName = 'FriendlyWarningMessageForEditing';
