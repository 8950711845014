import { AnchorButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { FC } from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FastSpringWidget } from '../containers/FastSpringWidget.tsx';

export interface ICreditCardInputStateProps {
  readonly baseUrl: string;
  readonly isConnected: boolean;
}

interface ICreditCardInputProps extends ICreditCardInputStateProps {}

const CreditCardInput: FC<ICreditCardInputProps> = ({ isConnected, baseUrl }) => {
  const quickTipAdditionalMessage = isConnected
    ? 'The button to manage your payment method will take you to FastSpring where you can edit your payment information.'
    : 'The button will take you to FastSpring where you can enter your payment information.';

  return (
    <div className="credit-card">
      <section className="card__pane">
        <div className="card__content-section">
          <div className="card__title-section">
            <h1 className="card__title">Payment details</h1>
          </div>
          {isConnected ? (
            <div className="card__content">
              <p className="credit-card__message">
                Your subscription is currently linked with the selected payment method.
              </p>
              <div className="credit-card__quick-tip">
                <QuickTip>
                  We use FastSpring for processing payments. {quickTipAdditionalMessage}
                </QuickTip>
              </div>
              <AnchorButton
                buttonStyle="primary"
                buttonDisplay="block"
                href={`https://${baseUrl}/account`}
                {...getDataUiActionAttribute(DataUiAction.ManageCreditCard)}
              >
                Manage your FastSpring account
                <AnchorButton.Icon icon={Icons.ArrowRightTopSquare} />
              </AnchorButton>
              <FastSpringWidget
                buttonText="Update your payment method"
                customButtonClassName="payment-method__update-button"
                dataUiAction={DataUiAction.SelectCreditCard}
              />
            </div>
          ) : (
            <div className="card__content">
              <p className="credit-card__message">
                Your subscription is not linked with any payment method.
              </p>
              <FastSpringWidget
                buttonText="Select a payment method"
                buttonIsPrimary
                dataUiAction={DataUiAction.SelectCreditCard}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

CreditCardInput.displayName = 'CreditCardInput';

export { CreditCardInput };
