import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentItemEditing_ArchiveTemporaryContentItem_Finished,
  ContentItemEditing_ArchiveTemporaryContentItem_Started,
} from '../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  Data_ItemsAssignedToCurrentUser_Started,
  Data_ItemsAssignedToCurrentUser_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IAssignedListingContentItem,
  createAssignedContentItem,
} from '../../../models/listingContentItems/IAssignedListingContentItem.ts';

const initialState: Immutable.List<IAssignedListingContentItem> = Immutable.List();

export function items(
  state = initialState,
  action: Action,
): Immutable.List<IAssignedListingContentItem> {
  switch (action.type) {
    case Data_ItemsAssignedToCurrentUser_Success: {
      const { contentItems } = action.payload.data;
      const result = contentItems.map(createAssignedContentItem);

      return Immutable.List(result);
    }

    case Shared_CurrentProjectId_Updated:
    case Data_ItemsAssignedToCurrentUser_Started:
      return initialState;

    case ContentItemEditing_ArchiveTemporaryContentItem_Started:
    case ContentItemEditing_ArchiveTemporaryContentItem_Finished:
      return state
        .filter(
          (listItem: IAssignedListingContentItem) => listItem.id.itemId !== action.payload.itemId,
        )
        .toList();

    default:
      return state;
  }
}
