import { Action } from '../../@types/Action.type.ts';
import { GetState } from '../../@types/Dispatcher.type.ts';
import { IStore } from '../stores/IStore.type.ts';

interface IRequestTokenSetup<TActionCreator> {
  readonly tokenizedActionCreator: TActionCreator;
  readonly isCurrentTokenValid: Predicate;
}

export type RequestTokenFactory<TActionCreator = () => Action> = (
  getState: GetState,
) => IRequestTokenSetup<TActionCreator>;

export const initializeRequestTokenFactory =
  <TActionCreator = () => Action>(
    stateSelector: (state: IStore) => Uuid,
    tokenizedActionCreatorBuilder: (token: Uuid) => TActionCreator,
    tokenGenerator: () => Uuid,
  ): RequestTokenFactory<TActionCreator> =>
  (getState) => {
    const currentToken = tokenGenerator();
    const tokenizedActionCreator = tokenizedActionCreatorBuilder(currentToken);
    const isCurrentTokenValid = () => currentToken === stateSelector(getState());

    return {
      tokenizedActionCreator,
      isCurrentTokenValid,
    };
  };
