import { template } from '../../../../_shared/utils/stringUtils.ts';

export const DisabledCreationButtonTooltipText =
  'Finish editing your space before creating a new one.';
export const DisabledEditingButtonTooltipText =
  'Finish editing one space first before editing another.';
export const DisabledCreationButtonLimitReachedTooltipText = template`You have reached the maximum number of spaces per project in your current subscription plan (${0}).`;

export const DeletedSpaceText = template`${1} deleted`;

export const MissingSpaceNameMessage = 'Space name is required.';
export const DisabledActionWhenInProgressMessage = 'Wait for the action to finish.';

export const SpacesActionFailedMessage = template`We couldn’t ${0} your space. Please try again. If this keeps happening, let us know.`;

export const WebSpotlightActiveForSpaceText = 'Web Spotlight is active';
export const MissingPermissionToCreateWebSpotlightRootItemMessage =
  'You don’t have permission to assign the root item. Ask your project manager to give you “create content” permission to do this.';

export const ExpandedSpaceBarItemHeight = 464;
export const ExpandedSpaceBarActiveWebSpotlightText =
  'To enable Web Spotlight for this space, you need to select its root item.';
export const ExpandedSpaceBarInactiveWebSpotlightText =
  'Web Spotlight is currently disabled in this environment, but a root item has been previously assigned. To enable Web Spotlight and restore functionality, activate it again. Alternatively, if you no longer wish to use Web Spotlight, you can unlink the assigned root item.';
export const ExpandedSpaceBaseActiveWebSpotlightTooltipText =
  'Root item is the item that’s at the top of the Web Spotlight tree hierarchy. It’s also useful for generating a sitemap, for example.';

export const CollapsedSpaceBarCollectionSingularPrefix = 'Collection: ';
export const CollapsedSpaceBarCollectionPluralPrefix = 'Collections: ';
export const CollapsedSpaceBarAllCollectionsText = 'All collections';
