import { assert } from '@kontent-ai/utils';
import { IEditedContentItem } from '../../../applications/itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { IProjectContributor } from '../../../data/models/users/ProjectContributor.ts';
import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import { getWorkflow } from '../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { isWorkflowStepModalOpenedInItemEditing } from '../../features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalUtils.ts';
import { getSelectedLanguageIdOrThrow } from '../../selectors/getSelectedLanguageId.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getUsersInfo } from '../usersUtils.ts';
import {
  getActiveAssignedContributors,
  getAllActiveContributors,
} from './getAvailableContributors.ts';
import {
  getAllApplicableContributorRoleIdsForLanguage,
  getApplicableContributorRoleId,
} from './getContributorRole.ts';
import { canRoleDoSomethingInStep } from './roleInWorkflowStepUtils.ts';

export const getHiddenAssignedContributors = (
  state: IStore,
): ReadonlyArray<IProjectContributor> => {
  const {
    data: { users },
    contentApp: { changeWorkflowStepModalData, editedContentItem },
  } = state;
  const { contributors, workflowStep } = changeWorkflowStepModalData;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  const contributorsInfo = getUsersInfo(contributors, users.usersById);
  const activeAssignedContributors = getActiveAssignedContributors(
    contributorsInfo,
    getAllActiveContributors(users.usersById),
  );
  const currentWorkflow = getWorkflow(state, workflowStep.workflowId);

  if (!currentWorkflow) {
    return [];
  }

  return isWorkflowStepModalOpenedInItemEditing(changeWorkflowStepModalData)
    ? getHiddenAssignedContributorsForEditing(
        activeAssignedContributors,
        editedContentItem,
        selectedLanguageId,
        currentWorkflow,
        workflowStep.id,
      )
    : getHiddenAssignedContributorsForListing(
        activeAssignedContributors,
        selectedLanguageId,
        currentWorkflow,
        workflowStep.id,
      );
};

const getHiddenAssignedContributorsForEditing = (
  activeAssignedContributors: ReadonlyArray<IProjectContributor>,
  editedContentItem: IEditedContentItem | null,
  selectedLanguageId: Uuid,
  workflow: Workflow,
  workflowStepId: Uuid,
): ReadonlyArray<IProjectContributor> => {
  assert(editedContentItem, () => 'selectContributors.ts: editedContentItem is undefined.');

  return activeAssignedContributors.filter((contributor: IProjectContributor) => {
    const roleId = getApplicableContributorRoleId(
      contributor,
      selectedLanguageId,
      editedContentItem.collectionId,
    );
    return !roleId || !canRoleDoSomethingInStep(roleId, workflowStepId, workflow);
  });
};

const getHiddenAssignedContributorsForListing = (
  activeAssignedContributors: ReadonlyArray<IProjectContributor>,
  selectedLanguageId: Uuid,
  workflow: Workflow,
  workflowStepId: Uuid,
): ReadonlyArray<IProjectContributor> =>
  activeAssignedContributors.filter((contributor: IProjectContributor) => {
    const contributorLanguageRoleIds = getAllApplicableContributorRoleIdsForLanguage(
      contributor.collectionGroups,
      selectedLanguageId,
    );
    return !contributorLanguageRoleIds.some((roleId) =>
      canRoleDoSomethingInStep(roleId, workflowStepId, workflow),
    );
  });
