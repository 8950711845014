import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  BaseColor,
  Spacing,
  Typography,
  colorDividerDefault,
  colorTextHint,
  px,
} from '@kontent-ai/component-library/tokens';
import { isSameDay } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { dividerBorderWidth } from '../../../../../../component-library/components/Dividers/decisionTokens.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { compareEventsByDate } from '../../utils/calendarUtils.ts';
import { DayOfWeek, useCalendarMatrix } from '../../utils/useCalendarMatrix.ts';
import { CalendarCell } from './CalendarCell.tsx';
import { StyledMonthWrapper } from './StyledMonthWrapper.tsx';

const StyledWeekDayHeaderColumn = styled(Column)`
  &:not(:last-child) {
    border-right: ${px(dividerBorderWidth)} solid ${colorDividerDefault};
  }
`;

const StyledCalendarRow = styled(Row)`
  min-height: 120px;
  height: 100%;

  &:not(:last-child) {
    border-bottom: ${px(dividerBorderWidth)} solid ${colorDividerDefault};
  }
`;

const StyledCalendarColumn = styled(Column)`
  &:not(:last-child) {
    border-right: ${px(dividerBorderWidth)} solid ${colorDividerDefault};
  }
`;

const StyledWeekHeader = styled(Row)`
  border-bottom: ${px(dividerBorderWidth)} solid ${colorDividerDefault};
`;

const getSortedWeekdayNames = (startOfWeek: DayOfWeek): string[] => {
  const unsortedDaysOfWeek = Object.keys(DayOfWeek).filter((dayOfWeek) =>
    Number.isNaN(Number(dayOfWeek)),
  );

  return unsortedDaysOfWeek
    .slice(startOfWeek, unsortedDaysOfWeek.length)
    .concat(unsortedDaysOfWeek.slice(0, startOfWeek));
};

const getWeekHeader = (startOfWeek: DayOfWeek, isCurrentMonthLoaded: boolean) => (
  <StyledWeekHeader {...getDataUiElementAttribute(DataUiElement.CalendarWeekdayHeader)}>
    {getSortedWeekdayNames(startOfWeek).map((dayOfWeek, weekDayIndex) => (
      <StyledWeekDayHeaderColumn key={weekDayIndex} width="1/7">
        <Box
          overflow="hidden"
          color={isCurrentMonthLoaded ? colorTextHint : BaseColor.Gray30}
          whiteSpace="nowrap"
          padding={Spacing.S}
          textAlign="left"
          typography={Typography.LabelLarge}
          css="text-overflow: ellipsis"
        >
          {dayOfWeek}
        </Box>
      </StyledWeekDayHeaderColumn>
    ))}
  </StyledWeekHeader>
);

interface MonthViewProps {
  readonly events: readonly ICalendarEvent[];
  readonly isCurrentMonthLoaded: boolean;
  readonly onDrillDownDateSelected: (date: DateTimeStamp) => void;
  readonly onItemDetailSelected: (id: Uuid, date: DateTimeStamp) => void;
  readonly startOfCurrentMonth: Date;
  readonly startOfWeek: DayOfWeek;
}

export const MonthView: React.FC<MonthViewProps> = ({
  events,
  isCurrentMonthLoaded,
  onDrillDownDateSelected,
  onItemDetailSelected,
  startOfCurrentMonth,
  startOfWeek,
}) => {
  const calendarMatrix = useCalendarMatrix(startOfCurrentMonth, startOfWeek);
  return (
    <StyledMonthWrapper
      className="canvas"
      {...getDataUiElementAttribute(DataUiElement.CalendarMonthView)}
    >
      {getWeekHeader(startOfWeek, isCurrentMonthLoaded)}
      {calendarMatrix.map((week, weekIndex) => (
        <StyledCalendarRow
          key={weekIndex}
          noWrap
          alignX="center"
          {...getDataUiElementAttribute(DataUiElement.CalendarMonthRow)}
        >
          {week.map((day, dayIndex) => (
            <StyledCalendarColumn key={dayIndex} width="1/7">
              <CalendarCell
                date={day}
                events={events
                  .filter((event) => isSameDay(new Date(event.date), day))
                  .sort(compareEventsByDate)}
                isCurrentMonthLoaded={isCurrentMonthLoaded}
                onDrillDownDateSelected={onDrillDownDateSelected}
                onItemDetailSelected={onItemDetailSelected}
                startOfCurrentMonth={startOfCurrentMonth}
              />
            </StyledCalendarColumn>
          ))}
        </StyledCalendarRow>
      ))}
    </StyledMonthWrapper>
  );
};
