import { isElement } from '@kontent-ai/DOM';
import { usePrevious } from '@kontent-ai/hooks';
import { Direction } from '@kontent-ai/types';
import { delay } from '@kontent-ai/utils';
import { XYCoord } from 'dnd-core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectDropTarget, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useThrottledCallback } from 'use-debounce';
import { FileWithThumbnail } from '../../../applications/contentInventory/assets/models/FileWithThumbnail.type.ts';
import {
  crossedHalfTargetHeight,
  dragMoveThrottleIntervalMs,
} from '../../utils/dragDrop/dragDropUtils.ts';
import {
  DraggedFile,
  DraggedFiles,
  getDraggedFiles,
  getDroppedFiles,
  getFileTypes,
  isDraggedFiles,
} from '../../utils/fileDragDropUtils.ts';
import { DraggedFilesContextProvider } from './DraggedFilesContext.tsx';

const areTargetsEquivalent = (first: DropFilesTarget | null, second: DropFilesTarget): boolean =>
  first?.id === second.id && first?.element === second.element;

export type DropFilesTarget = {
  readonly id: string;
  readonly element: HTMLElement;
};

export type DropFilesParams = {
  readonly direction: Direction;
  readonly files: ReadonlyArray<FileWithThumbnail>;
  readonly target: DropFilesTarget;
};

export type DropFilesHandler = (params: DropFilesParams) => void;

export type IRenderFilesPreview = (
  contentRef: React.RefObject<HTMLElement>,
  files: ReadonlyArray<DraggedFile>,
  target: DropFilesTarget,
  direction: Direction,
) => React.ReactElement;

type IFindTarget = (
  contentElement: HTMLElement,
  clientOffset: XYCoord,
  lastTarget: DropFilesTarget | null,
) => DropFilesTarget | null;

type CollectedProps = {
  readonly isDraggingFiles: boolean;
};

type FileDropHandlerProps = {
  readonly canDrop: boolean;
  readonly contentRef: React.RefObject<HTMLElement>;
  readonly findTarget: IFindTarget;
  readonly onDropFiles: DropFilesHandler;
  readonly onInit: (connectDropTarget: ConnectDropTarget) => void;
  readonly onUpdate: (isDraggingFiles: boolean) => void;
  readonly renderPreview: IRenderFilesPreview;
};

const FileDropHandler: React.FC<FileDropHandlerProps> = ({
  contentRef,
  canDrop,
  findTarget,
  onDropFiles,
  onInit,
  onUpdate,
  renderPreview,
}) => {
  const [draggedFiles, setDraggedFiles] = useState<ReadonlyArray<DraggedFile>>([]);
  const [currentTarget, setCurrentTarget] = useState<DropFilesTarget | null>(null);
  const currentTargetRef = useRef<DropFilesTarget | null>(null);
  const [currentDirection, setCurrentDirection] = useState<Direction>(Direction.Backward);

  const updateTarget = useCallback(
    (contentElement: HTMLElement, clientOffset: XYCoord) => {
      const target = findTarget(contentElement, clientOffset, currentTargetRef.current);
      if (target) {
        const targetBoundingRect = target.element.getBoundingClientRect();
        const direction = crossedHalfTargetHeight(
          targetBoundingRect,
          clientOffset,
          Direction.Forward,
        )
          ? Direction.Forward
          : Direction.Backward;
        setCurrentTarget((prevState) =>
          areTargetsEquivalent(prevState, target) ? prevState : target,
        );
        currentTargetRef.current = target;
        setCurrentDirection(direction);
      } else {
        setCurrentTarget(null);
        currentTargetRef.current = null;
      }
    },
    [findTarget],
  );
  const throttledUpdateTarget = useThrottledCallback(updateTarget, dragMoveThrottleIntervalMs);
  const reset = () => {
    setCurrentTarget(null);
    setDraggedFiles([]);
    currentTargetRef.current = null;
  };

  const [{ isDraggingFiles }, connectDropTarget] = useDrop<DraggedFiles, void, CollectedProps>({
    accept: NativeTypes.FILE,
    canDrop: () => {
      return canDrop;
    },
    collect: (monitor) => {
      const item = monitor.getItem();
      if (isDraggedFiles(item)) {
        const fileTypes = getFileTypes(item);
        // Only update dragged files when some items are present as not all drag events will receive the items due to React DnD bug and browser limitations
        // https://github.com/react-dnd/react-dnd/issues/584#issuecomment-883603556
        if (fileTypes.length > 0) {
          const newDraggedFiles = getDraggedFiles(...fileTypes);
          setDraggedFiles(newDraggedFiles);
        }
      }

      return {
        isDraggingFiles:
          monitor.isOver() && monitor.canDrop() && monitor.getItemType() === NativeTypes.FILE,
      };
    },
    drop: (dragObject) => {
      if (isDraggedFiles(dragObject) && dragObject.files.length > 0 && currentTarget) {
        const filesWithThumbnail = getDroppedFiles(dragObject.files);
        onDropFiles({
          direction: currentDirection,
          files: filesWithThumbnail,
          target: currentTarget,
        });
        // Reset the state so it doesn't influence the next drag.
        // This needs to be done in a deferred way as the drop event still executes collect in the end.
        delay(0).then(reset);
      }
    },
    hover: (dragObject, monitor) => {
      const contentElement = contentRef.current;

      if (isDraggedFiles(dragObject)) {
        const clientOffset = monitor.getClientOffset();
        if (isElement(contentElement) && clientOffset) {
          throttledUpdateTarget(contentElement, clientOffset);
        }
      }
    },
  });

  useEffect(() => {
    onInit(connectDropTarget);
  }, [onInit, connectDropTarget]);

  const previousIsDraggingFiles = usePrevious(isDraggingFiles);
  useEffect(() => {
    if (previousIsDraggingFiles !== isDraggingFiles) {
      onUpdate(isDraggingFiles);
    }
  }, [isDraggingFiles, previousIsDraggingFiles, onUpdate]);

  return isDraggingFiles && currentTarget
    ? renderPreview(contentRef, draggedFiles, currentTarget, currentDirection)
    : null;
};
FileDropHandler.displayName = 'FileDropHandler';

type FileDropContainerProps = {
  readonly canDrop: boolean;
  readonly contentRef: React.RefObject<HTMLElement>;
  readonly findTarget: IFindTarget;
  readonly onDropFiles: DropFilesHandler;
  readonly renderContent: (
    connectDropTarget: ConnectDropTarget,
    isDraggingFiles: boolean,
  ) => React.ReactNode;
  readonly renderPreview: IRenderFilesPreview;
};

export const FileDropContainer: React.FC<FileDropContainerProps> = ({
  canDrop,
  contentRef,
  findTarget,
  onDropFiles,
  renderContent,
  renderPreview,
}) => {
  const [isDraggingFiles, setIsDraggingFiles] = useState(false);
  const [connectDropTarget, setConnectDropTarget] = useState<ConnectDropTarget | null>(null);

  const onInit = useCallback(
    (connect: ConnectDropTarget) => setConnectDropTarget(() => connect),
    [],
  );

  // We defer setting dragging files flag to cut the drag / drop event to smaller pieces and give the browser some breathing time
  const deferredSetIsDraggingFiles = useCallback(async (newIsDraggingFiles: boolean) => {
    await delay(0);
    setIsDraggingFiles(newIsDraggingFiles);
  }, []);

  return (
    <DraggedFilesContextProvider isDraggingFiles={isDraggingFiles}>
      {connectDropTarget && renderContent(connectDropTarget, isDraggingFiles)}
      <FileDropHandler
        canDrop={canDrop}
        contentRef={contentRef}
        findTarget={findTarget}
        onDropFiles={onDropFiles}
        onInit={onInit}
        onUpdate={deferredSetIsDraggingFiles}
        renderPreview={renderPreview}
      />
    </DraggedFilesContextProvider>
  );
};
FileDropContainer.displayName = 'FileDropContainer';
