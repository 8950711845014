import { EditorState } from 'draft-js';
import React, { useCallback, useRef } from 'react';
import { ITextTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { IFocusable } from '../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { ITextItemElement } from '../../../models/contentItemElements/TextItemElement.ts';
import { SimpleMultilineTextInput } from '../../ContentItemEditing/containers/elements/stringElement/SimpleMultilineTextInput.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

interface IContentComponentStringElementProps
  extends IContentComponentItemElementOwnProps<ITextItemElement, ITextTypeElement> {}

export const ContentComponentStringElement: React.FC<IContentComponentStringElementProps> = (
  props,
) => {
  const {
    autoFocus,
    contentComponentId,
    disabled,
    elementData,
    onUpdate,
    typeElement,
    validationResultSelectorId,
  } = props;

  const editorRef = useRef<IFocusable>(null);

  const onContentChange = useCallback(
    (newState: EditorState): Promise<void> => {
      onUpdate({
        ...elementData,
        _editorState: newState,
      });
      return Promise.resolve();
    },
    [elementData, onUpdate],
  );

  const focusEditorAtTheStart = useCallback(() => {
    editorRef.current?.focusAtTheStart();
  }, []);

  const focusEditorAtTheEnd = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.focusAtTheEnd();
    }
  }, []);

  return (
    <ContentComponentItemElement
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
      disabled={disabled}
      contentComponentId={contentComponentId}
      onHeaderClick={focusEditorAtTheStart}
      onContentClick={focusEditorAtTheEnd}
    >
      <SimpleMultilineTextInput
        autoFocus={autoFocus}
        className="rte--in-content-item-element"
        disabled={disabled}
        editorState={elementData._editorState}
        onContentChange={onContentChange}
        ref={editorRef}
        typeElement={typeElement}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentStringElement.displayName = 'ContentComponentStringElement';
