import { ReactNode } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getIsEditedItemInitialized } from '../selectors/isEditedItemInitialized.ts';

type Props = {
  readonly children: ReactNode;
  readonly renderWhileWaiting?: () => ReactNode;
};

export const WaitForEditedItemInitialized = ({ children, renderWhileWaiting }: Props) => {
  const location = useLocation();

  const isEditedItemInitialized = useSelector((s) =>
    getIsEditedItemInitialized(s, location.pathname),
  );

  return isEditedItemInitialized ? children : renderWhileWaiting?.();
};
