import { CollectionsFilterStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { ICollectionsAppStoreStateType } from '../applications/environmentSettings/collections/reducers/ICollectionsAppStoreState.type.ts';
import { initialFilterSearchPhrase } from '../applications/environmentSettings/collections/reducers/internalReducers/filterSearchPhrase.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

type filterSearchPhraseType = ICollectionsAppStoreStateType['filterSearchPhrase'];

const getFilterSearchPhraseFromJS = (
  rawFilterSearchPhrase: filterSearchPhraseType,
): filterSearchPhraseType => {
  return rawFilterSearchPhrase ?? initialFilterSearchPhrase;
};

const collectionsFilterStorageParams: StorageParams<filterSearchPhraseType> = {
  key: CollectionsFilterStorageKey,
  initialState: initialFilterSearchPhrase,
  fromJS: getFilterSearchPhraseFromJS,
};

export const CollectionsFilterStorage = getProjectSpecificStorage<filterSearchPhraseType>(
  collectionsFilterStorageParams,
);
