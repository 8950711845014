import React from 'react';
import { PhoneLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/PhoneLinkProperties.type.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { PhoneLinkDialog } from './PhoneLinkDialog.tsx';

interface INewPhoneLinkProps {
  readonly children: ReadonlyArray<React.ReactNode>;
  readonly isPlaceholder: boolean;
  readonly linkText: string;
  readonly onCancel: () => void;
  readonly onInsert: (values: PhoneLinkProperties) => void;
}

export const NewPhoneLink: React.FC<INewPhoneLinkProps> = ({
  children,
  isPlaceholder,
  linkText,
  onCancel,
  onInsert,
}) => {
  return (
    <InlineContentWithDialog
      renderContent={(ref) => (
        <a className="rte__link rte__link--is-edited" href="#" ref={ref}>
          {children}
        </a>
      )}
      renderDialog={() => (
        <PhoneLinkDialog
          linkText={isPlaceholder ? '' : linkText}
          onCancel={onCancel}
          onUpsert={onInsert}
        />
      )}
    />
  );
};

NewPhoneLink.displayName = 'NewPhoneLink';
