import { isArray } from '@kontent-ai/utils';
import { jsonrepair } from 'jsonrepair';
import { ActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { isEmptyOrWhitespace, isString } from '../../../../../../_shared/utils/stringUtils.ts';

export type ReviewCommentServerModel = {
  readonly comment: string;
  readonly userHtml: string;
  readonly replacementHtml: string;
};

const isReviewComment = (obj: any): obj is ReviewCommentServerModel =>
  typeof obj === 'object' &&
  isString(obj.comment) &&
  isString(obj.userHtml) &&
  isString(obj.replacementHtml);

const isValidReviewComment = (comment: ReviewCommentServerModel) =>
  !isEmptyOrWhitespace(comment.comment) &&
  !isEmptyOrWhitespace(comment.userHtml) &&
  comment.replacementHtml !== comment.userHtml;

export const parseReviewComments = (
  result: ActionResult<string>,
): ReadonlyArray<ReviewCommentServerModel> | null => {
  if (result.error || !result.content) {
    return null;
  }

  try {
    const validJson = jsonrepair(result.content);
    const parsedJson = JSON.parse(validJson);

    if (!isArray(parsedJson)) {
      return null;
    }

    // If result is not yet finished, the last item property value may be incomplete,
    const completeItems = result.isFinished ? parsedJson : parsedJson.slice(0, -1);

    return completeItems.filter(isReviewComment).filter(isValidReviewComment);
  } catch {
    // We don't log the error as jsonrepair package contains a bug that fires often in our case
    // when we get incomplete strings containing delimiters (e.g. commas) via streaming
    // https://github.com/josdejong/jsonrepair/issues/130
    return null;
  }
};
