import classNames from 'classnames';
import React from 'react';
import {
  FriendlyWarning as FriendlyWarningComponent,
  IFriendlyWarningOwnProps,
} from '../components/elements/subComponents/FriendlyWarning.tsx';

export const FriendlyWarning: React.FC<IFriendlyWarningOwnProps> = (props) => (
  <FriendlyWarningComponent
    {...props}
    messageBarClassName={classNames(
      'content-item-element__alert-message',
      'content-item-element__alert-message--is-warning',
    )}
    messageClassName={classNames(
      'content-item-element__alert-inner-message',
      'content-item-element__alert-inner-message--is-warning',
    )}
  />
);
