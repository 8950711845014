import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import {
  getCurrentProject,
  getNormalizedRolesWithSettingsForUser,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { Capability } from './capability.ts';
import { getAllApplicableRolesForLanguage } from './getContributorRole.ts';

export const hasCapabilityInLanguage = (
  state: IStore,
  capability: Capability,
  languageId: Uuid,
): boolean =>
  hasCapabilityInLanguageForUser(
    state.data.user,
    getCurrentProject(state).projectId,
    capability,
    languageId,
  );

export const hasCapabilityInLanguageForUser = (
  userState: IUser,
  projectId: Uuid,
  capability: Capability,
  languageId: Uuid,
): boolean =>
  getAllApplicableRolesForLanguage(
    getNormalizedRolesWithSettingsForUser(userState, projectId).collectionGroups,
    languageId,
  ).some((r) => r.capabilities.contains(capability));
