import { forwardRef } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { filterOutNullishOrEmpty } from '../../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { getContributorsAvailableAsAssigneesInTasks } from '../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { cancelTaskEditing } from '../../../actions/contentItemEditingActions.ts';
import { createTask } from '../../../actions/thunkContentItemEditingActions.ts';
import { NewTaskId } from '../../../constants/taskConstants.ts';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { isTaskSubmitting as isTaskSubmittingSelector } from '../../../selectors/isTaskSubmitting.ts';
import { TaskForm } from './TaskForm.tsx';

const defaultValues: ITaskFormShape = {
  assignedContributorId: null,
  taskDescription: '',
  taskDueDate: {
    value: '',
    isValid: true,
  },
};

export const AddTaskFormDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const allActiveContributors = useSelector(
      (s) => getContributorsAvailableAsAssigneesInTasks(s).allActive,
    );
    const isTaskSubmitting = useSelector((s) => isTaskSubmittingSelector(s, NewTaskId));

    const dispatch = useDispatch();

    const onConfirm = (values: ITaskFormShape) =>
      dispatch(
        createTask(
          values.taskDescription,
          filterOutNullishOrEmpty([values.assignedContributorId]),
          values.taskDueDate,
        ),
      );
    const onCancel = () => dispatch(cancelTaskEditing(NewTaskId));

    return (
      <TaskForm
        isOpen={isOpen}
        ref={ref}
        modalHeadline="Add task"
        allActiveContributors={allActiveContributors}
        defaultValues={defaultValues}
        isTaskSubmitting={isTaskSubmitting}
        onCancel={onCancel}
        onConfirm={onConfirm}
        submitButtonName="Add"
        submitButtonText={isTaskSubmitting ? 'Adding…' : 'Add'}
      />
    );
  },
);

AddTaskFormDialog.displayName = 'AddTaskFormDialog';
