import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  IProjectTemplateSelectorStateProps,
  ProjectTemplateSelector,
} from '../../components/projects/ProjectTemplateSelector.tsx';
import { IBaseProject } from '../../models/IBaseProject.type.ts';

interface IProjectTemplateSelectorContainerProps {
  readonly dropdownId?: string;
  readonly selectedProjectTemplateId?: string | null;
  readonly className?: string;
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly disabled: boolean;
  readonly onSelectionChanged: (projectTemplateId: string) => void;
}

function mapStateToProps(
  _state: IStore,
  ownProps: IProjectTemplateSelectorContainerProps,
): IProjectTemplateSelectorStateProps {
  const defaultOption: IBaseProject = {
    projectId: '',
    projectName: 'Empty Project',
    masterEnvironmentId: '',
    environmentName: '',
    sourceEnvironmentId: '',
    sourceEnvironmentName: '',
  };

  const options = [defaultOption, ...ownProps.availableProjectTemplates];

  return {
    availableProjectTemplates: options,
  };
}

const ProjectTemplateSelectorContainer: React.ComponentType<
  IProjectTemplateSelectorContainerProps & React.ComponentPropsWithRef<React.ComponentType>
> = connect(mapStateToProps, null, null, { forwardRef: true })(ProjectTemplateSelector);

export { ProjectTemplateSelectorContainer as ProjectTemplateSelector };
