import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import { FeatureFlagsServerModel } from '../../../repositories/serverModels/FeatureFlagsServerModel.type.ts';
import { Shared_LoadProjectFeatureFlags_Finished } from '../../constants/sharedActionTypes.ts';

type Dependencies = {
  readonly projectRepository: Pick<IProjectRepository, 'getFeatureFlags'>;
};

const finished = (featureFlags: FeatureFlagsServerModel) =>
  ({
    type: Shared_LoadProjectFeatureFlags_Finished,
    payload: {
      featureFlags,
    },
  }) as const;

export type LoadProjectFeatureFlagsActionsType = ReturnType<typeof finished>;

export const createLoadProjectFeatureFlags =
  (deps: Dependencies) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const featureFlags = await deps.projectRepository.getFeatureFlags(projectId, abortSignal);
    dispatch(finished(featureFlags));
  };
