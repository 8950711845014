import { memoize } from '@kontent-ai/memoization';
import { NumberValue } from '../../../../_shared/models/NumberValue.ts';
import { isNumeric } from '../../../../_shared/utils/validation/isNumeric.ts';
import { ElementError, ElementErrorResult } from './types/Errors.ts';
import { isNumberTooLarge } from './utils/isNumberTooLarge.ts';

interface IParams {
  readonly value: NumberValue;
}

const getMemoizedResult = memoize.allForever(
  (isValid: boolean, isTooLarge: boolean): ElementErrorResult => {
    const errors: Array<ElementError> = [];

    if (!isValid) {
      errors.push(ElementError.InvalidNumber);
    } else if (isTooLarge) {
      errors.push(ElementError.NumberTooLarge);
    }

    return {
      errors,
    };
  },
);

export const getNumberElementErrors = ({ value }: IParams): ElementErrorResult => {
  const isValid =
    value.isValid &&
    (!value.value || (!value.value.toLowerCase().includes('e') && isNumeric(value.value)));
  const isTooLarge = !!value.value && isNumberTooLarge(value.value);

  return getMemoizedResult(isValid, isTooLarge);
};
