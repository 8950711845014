import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import React from 'react';
import { DefaultTag } from '../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../component-library/components/Tag/Tag.tsx';
import {
  allCollectionsOption,
  anyCollectionOption,
} from '../../../../_shared/constants/userListingFilter.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { CollectionFilterOption } from '../../../../_shared/models/UserListingFilterOption.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getCollectionsFilterOptions } from '../../../../_shared/utils/userListingFilterUtils.ts';
import { getFilterLanguageTagColor } from '../../../../_shared/utils/userListingUtils.ts';
import { collectionFilterUpdated } from '../actions/subscriptionUserListingActions.ts';

export const SubscriptionUsersCollectionFilter: React.FC = () => {
  const loadedCollections = useSelector((s) => s.subscriptionApp.users.listingUi.collections);
  const collectionOptions = getCollectionsFilterOptions(loadedCollections);
  const isEnvironmentSelected = useSelector(
    (s) => !!s.subscriptionApp.users.listingUi.filter.byEnvironment,
  );
  const selectedCollectionOptionIds = useSelector(
    (s) => s.subscriptionApp.users.listingUi.filter.byCollection,
  );

  const dispatch = useDispatch();

  const onUpdateFilter = (updatedCollectionIds: ReadonlySet<CollectionFilterOption['id']>) => {
    const allCollectionsOptionWasAdded =
      updatedCollectionIds.has(allCollectionsOption.id) &&
      !selectedCollectionOptionIds.has(allCollectionsOption.id);
    const resolvedCollectionIds = new Set(
      allCollectionsOptionWasAdded
        ? [allCollectionsOption.id]
        : [...updatedCollectionIds].filter(
            (collectionId) => collectionId !== allCollectionsOption.id,
          ),
    );

    dispatch(collectionFilterUpdated(resolvedCollectionIds));
  };

  const placeholderProps = isEnvironmentSelected
    ? {
        placeholder: anyCollectionOption.label,
        placeholderType: 'tag' as const,
      }
    : {
        placeholder: 'Select environment first',
      };

  return (
    <MultiSelect<CollectionFilterOption>
      inputState={
        !isEnvironmentSelected || !loadedCollections.length
          ? InputState.Disabled
          : InputState.Default
      }
      label="Collection"
      onSelectionChange={onUpdateFilter}
      items={collectionOptions}
      renderSelectedOption={(_id, selectedCollection, defaultTagProps) => {
        const background = getFilterLanguageTagColor(selectedCollection);
        if (background) {
          return <Tag {...defaultTagProps} background={background} />;
        }
        return <DefaultTag {...defaultTagProps} />;
      }}
      selectedItemIds={selectedCollectionOptionIds}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      {...placeholderProps}
    />
  );
};

SubscriptionUsersCollectionFilter.displayName = 'SubscriptionUsersCollectionFilter';
