import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { modalWithPropertiesOpened } from '../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';

export const moveToArchivedStep =
  (workflowId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.MoveItemToArchivedStepConfirmationDialog,
        actionOrigin: getModalDialogActionOrigin(getState()),
        workflowId,
      }),
    );
  };
