import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isModularContentElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { ILinkedItemsElement } from '../../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { onLinkedItemsElementValueChange } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  LinkedItems as LinkedItemsComponent,
  LinkedItemsProps,
} from '../../../components/elements/linkedItems/LinkedItems.tsx';

type Props = Omit<LinkedItemsProps, 'elementData' | 'onValueChanged'>;

export const LinkedItems: React.FC<Props> = ({ typeElement, ...otherProps }) => {
  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(typeElement.elementId, state.contentApp.editedContentItemVariantElements),
  );

  const onValueChanged = useCallback(
    (itemElement: ILinkedItemsElement) =>
      dispatch(onLinkedItemsElementValueChange(itemElement, typeElement)),
    [typeElement],
  );

  if (!isModularContentElement(elementData)) {
    return null;
  }

  return (
    <LinkedItemsComponent
      {...otherProps}
      elementData={elementData}
      onValueChanged={onValueChanged}
      typeElement={typeElement}
    />
  );
};

LinkedItems.displayName = 'LinkedItemsContainer';
