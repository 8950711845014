import { ContentState } from 'draft-js';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { DraftJsOperationInput, StringOperationInput } from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type ImproveContentByBriefParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: 'ImproveContentByBrief' | 'ImproveWriting';
    readonly inputs: {
      readonly content: DraftJsOperationInput;
      readonly contentBrief: StringOperationInput;
    };
  };

export const createImproveContentByBriefParams = (
  contentState: ContentState,
  contentInstructions: string,
  trackingData: ElementOperationTrackingData,
): ImproveContentByBriefParams => ({
  actionName: 'ImproveContentByBrief',
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
    contentBrief: {
      type: 'string',
      value: contentInstructions,
    },
  },
  trackingData,
});

export type ImproveContentByBriefResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isImproveContentByBriefMessage = (
  message: OperationResponseMessage,
): message is ImproveContentByBriefResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
