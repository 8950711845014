import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import React, { useRef } from 'react';
import { DialogState } from '../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type InviteButtonProps = {
  readonly disabledTooltip: string | undefined;
  readonly emailsCountedInSubscriptionLimits: ReadonlyArray<string>;
  readonly isInvitationInProgress: boolean;
  readonly onInvite: () => void;
  readonly shouldConfirm: boolean;
};

export const InviteButton: React.FC<InviteButtonProps> = ({
  disabledTooltip,
  emailsCountedInSubscriptionLimits,
  isInvitationInProgress,
  onInvite,
  shouldConfirm,
}) => {
  const targetRef = useRef<HTMLElement>(null);

  const { closeDialog, isOpen, openDialog, PopoverDialog, popoverDialogProps, targetProps } =
    usePopoverDialog({
      dialogState: DialogState.Default,
      headline: 'Inviting extra users',
      onClose: () => closeDialog(),
      placement: 'top-end',
      primaryAction: {
        onClick: () => {
          closeDialog();
          onInvite();
        },
        text: 'Invite',
        ...getDataUiActionAttribute(DataUiAction.Save),
      },
      shouldCloseOnInteractOutside: (element) => !targetRef.current?.contains(element),
      targetRef,
    });

  const onClick = (): void => {
    if (isOpen) {
      return;
    }

    if (shouldConfirm) {
      openDialog();
    } else {
      onInvite();
    }
  };

  return (
    <>
      <Button
        {...targetProps}
        buttonStyle="primary"
        disabled={!!disabledTooltip}
        onClick={onClick}
        tooltipPlacement="top"
        tooltipText={disabledTooltip}
        {...getDataUiActionAttribute(
          shouldConfirm ? DataUiAction.ShowConfirmation : DataUiAction.Save,
        )}
      >
        {isInvitationInProgress && <Button.ProgressIcon />}
        <Button.Label>Invite</Button.Label>
      </Button>
      <PopoverDialog {...popoverDialogProps}>
        <Box marginBottom={Spacing.XL} maxWidth={gridUnit * 80}>
          <SubscriptionPlanIncludedUserLimitReachedWarning
            isHeadlineHidden
            newEmails={emailsCountedInSubscriptionLimits}
          />
        </Box>
      </PopoverDialog>
    </>
  );
};

InviteButton.displayName = 'InviteButton';
