import React from 'react';
import { BaseModalDialogComponent, IBaseModalDialogProps } from './BaseModalDialogComponent.tsx';

export interface IFullScreenModalDialogProps extends IBaseModalDialogProps {}

export const FullScreenModalDialog = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IFullScreenModalDialogProps>
>(({ children, ...props }, forwardedRef) => (
  <BaseModalDialogComponent isFullScreen withDividers ref={forwardedRef} {...props}>
    {children}
  </BaseModalDialogComponent>
));

FullScreenModalDialog.displayName = 'FullScreenModalDialog';
