import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { TabView } from '@kontent-ai/component-library/TabView';
import { Spacing, canvasInnerSectionRestrictedWidth } from '@kontent-ai/component-library/tokens';
import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { useGetLastRouteSegment } from '../../../../_shared/hooks/useGetLastRouteSegment.ts';
import { ProjectSettingsAppNames } from '../../root/constants/ProjectSettingsAppNames.ts';
import { ApiKeyTab } from '../constants/ApiKeyTab.ts';
import { TabItemWithLink } from '../constants/ApiKeyTabWithLink.tsx';

const getTabMapByPath = (tabs: ReadonlyArray<TabItemWithLink>) =>
  tabs.reduce<Dictionary<TabItemWithLink>>(
    (resultingMap, itemToAdd) => ({
      ...resultingMap,
      [itemToAdd.to]: itemToAdd,
    }),
    {},
  );

export interface IApiKeyListProps {
  readonly renderTabsContent: () => JSX.Element;
  readonly tabs: ReadonlyArray<TabItemWithLink>;
}

export const ApiKeyList: React.FC<IApiKeyListProps> = ({ renderTabsContent, tabs }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const selectedSectionPath = useGetLastRouteSegment();

  const tabMapByPath = getTabMapByPath(tabs);
  const selectedTab = tabMapByPath[selectedSectionPath];
  const defaultUrl = `${match.url}/${tabs[0]?.to}`;

  useEffect(() => {
    if (!selectedTab) {
      history.push(defaultUrl);
    }
  }, [defaultUrl, history, selectedTab]);

  if (!selectedTab) {
    return null;
  }

  return (
    <Box>
      <HtmlSettingsPageTitle settingsAppName={ProjectSettingsAppNames.ApiKeyListing} />
      <Box maxWidth={canvasInnerSectionRestrictedWidth}>
        <Stack spacing={Spacing.XL}>
          <PageTitle>{ProjectSettingsAppNames.ApiKeyListing}</PageTitle>
          <TabView selectedKey={selectedTab.id as ApiKeyTab} items={tabs}>
            <TabView.TabGroup />
            <TabView.TabPanel>{renderTabsContent}</TabView.TabPanel>
          </TabView>
        </Stack>
      </Box>
    </Box>
  );
};

ApiKeyList.displayName = 'ApiKeyList';
