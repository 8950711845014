import {
  BaseColor,
  BorderRadius,
  Spacing,
  colorBorderDefault,
  colorBorderHover,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import { useHover } from '@react-aria/interactions';
import React from 'react';
import styled from 'styled-components';
import { WebSpotlightZIndex } from '../../../constants/WebSpotlightZIndex.ts';
import { WebSpotlightPreviewResizingDirection } from '../../../models/WebSpotlightPreviewResizingDirection.tsx';

const ElementWrapper = styled.div<{ readonly isHovered: boolean }>`
  width: ${px(Spacing.L)};
  height: 100%;
  position: absolute;
  top: 0;
  right: ${px(-Spacing.L)};
  border-left: 2px solid;
  cursor: e-resize;
  user-select: none;

  ${(props) =>
    props.isHovered
      ? `
    border-left-color: ${colorBorderHover};
    z-index: ${WebSpotlightZIndex.PreviewIFrame};
  `
      : `
    border-left-color: ${colorBorderDefault};
    z-index: ${WebSpotlightZIndex.InactiveResizeHandle};
  `};
`;

const ResizeButton = styled.button<{ readonly isHovered: boolean }>`
  width: ${px(gridUnit)};
  height: ${px(gridUnit * 10)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: 0 transparent;
  border-top-right-radius: ${px(BorderRadius.L)};
  border-bottom-right-radius: ${px(BorderRadius.L)};
  cursor: e-resize;

  ${(props) =>
    props.isHovered
      ? `
    background-color: ${colorBorderHover};
    border-radius: ${px(BorderRadius.L)};
    color: ${BaseColor.Gray20};
    left: -50%;
    width: ${px(gridUnit * 2)};
  `
      : `
    background-color: ${colorBorderDefault};
    color: ${BaseColor.Gray80};
  `};
`;

interface IWebSpotlightPreviewHorizontalResizeBarProps {
  readonly isBorderHovered: boolean;
  readonly onResizeStart: (event: React.MouseEvent<HTMLElement>) => void;
  readonly resizingDirection: WebSpotlightPreviewResizingDirection;
}

export const WebSpotlightPreviewHorizontalResizeBar: React.FC<
  IWebSpotlightPreviewHorizontalResizeBarProps
> = ({ isBorderHovered, onResizeStart, resizingDirection }) => {
  const { isHovered, hoverProps } = useHover({});

  const isResizing = resizingDirection !== WebSpotlightPreviewResizingDirection.None;

  const isWrapperHovered = isResizing
    ? [
        WebSpotlightPreviewResizingDirection.Horizontal,
        WebSpotlightPreviewResizingDirection.Diagonal,
      ].includes(resizingDirection)
    : isHovered || isBorderHovered;

  const isButtonHovered = isResizing
    ? resizingDirection === WebSpotlightPreviewResizingDirection.Horizontal
    : isHovered;

  return (
    <ElementWrapper isHovered={isWrapperHovered} onMouseDown={onResizeStart} {...hoverProps}>
      <ResizeButton isHovered={isButtonHovered}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="33"
          viewBox="0 0 5 33"
          fill="none"
        >
          <line x1="0.595215" y1="0.660156" x2="0.595215" y2="32.6602" stroke="currentcolor" />
          <line x1="3.59521" y1="0.660156" x2="3.59521" y2="32.6602" stroke="currentcolor" />
        </svg>
      </ResizeButton>
    </ElementWrapper>
  );
};

WebSpotlightPreviewHorizontalResizeBar.displayName = 'WebSpotlightPreviewHorizontalResizeBar';
