import { variantCountWarningLimit } from '../../../../_shared/constants/limitConstants.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';

export const shouldLongProcessingChangesWarningBeHidden = (s: IStore): boolean => {
  const editedTypeUsage = s.data.contentTypeUsages.byId.get(
    s.contentModelsApp.typeEditor.editedType.id,
  );

  return (
    !editedTypeUsage ||
    editedTypeUsage.itemCount < variantCountWarningLimit ||
    s.sharedApp.userProperties.longProcessingChangesWarningDismissed ||
    !s.contentModelsApp.typeEditor.editedTypeIsModified
  );
};
