import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscriptionAdmin } from '../../shared/models/SubscriptionAdmin.ts';

export interface ISubscriptionAdminInvitationStatusStateProps {
  readonly adminsBeingReinvitedIds: Immutable.Set<UserId>;
  readonly adminsBeingRevokedIds: Immutable.Set<UserId>;
  readonly adminsReinvitedIds: Immutable.Set<UserId>;
  readonly subscriptionAdmin: ISubscriptionAdmin;
  readonly subscriptionId: Uuid | null;
}

export interface ISubscriptionAdminInvitationStatusDispatchProps {
  readonly resendInvitationToAdmin: (subscriptionId: Uuid, userId: UserId) => void;
  readonly revokeAdmin: (subscriptionId: Uuid, userId: UserId) => void;
}

type Props = ISubscriptionAdminInvitationStatusStateProps &
  ISubscriptionAdminInvitationStatusDispatchProps;

const ResendInviteElement = ({
  adminsBeingReinvitedIds,
  adminsBeingRevokedIds,
  adminsReinvitedIds,
  resendInvitationToAdmin,
  subscriptionAdmin,
  subscriptionId,
}: Props): JSX.Element => {
  if (adminsBeingReinvitedIds.has(subscriptionAdmin.userId)) {
    return (
      <Button buttonStyle="secondary" disabled size="small">
        <Button.ProgressIcon />
        <Button.Label>Sending</Button.Label>
      </Button>
    );
  }

  if (adminsReinvitedIds.has(subscriptionAdmin.userId)) {
    return (
      <Button buttonStyle="secondary" disabled size="small">
        Sent
      </Button>
    );
  }

  return (
    <Button
      buttonStyle="secondary"
      disabled={adminsBeingRevokedIds.has(subscriptionAdmin.userId)}
      onClick={() => {
        if (subscriptionId) {
          resendInvitationToAdmin(subscriptionId, subscriptionAdmin.userId);
        }
      }}
      size="small"
      {...getDataUiActionAttribute(DataUiAction.Resend)}
    >
      Resend
    </Button>
  );
};

ResendInviteElement.displayName = 'ResendInviteElement';

const RevokeInviteElement = ({
  adminsBeingRevokedIds,
  adminsBeingReinvitedIds,
  revokeAdmin,
  subscriptionAdmin,
  subscriptionId,
}: Props): JSX.Element => {
  if (adminsBeingRevokedIds.has(subscriptionAdmin.userId)) {
    return (
      <Button buttonStyle="secondary" disabled size="small">
        <Button.ProgressIcon />
        <Button.Label>Revoking</Button.Label>
      </Button>
    );
  }

  return (
    <Button
      buttonStyle="secondary"
      disabled={adminsBeingReinvitedIds.has(subscriptionAdmin.userId)}
      onClick={() => {
        if (subscriptionId) {
          revokeAdmin(subscriptionId, subscriptionAdmin.userId);
        }
      }}
      size="small"
      {...getDataUiActionAttribute(DataUiAction.Revoke)}
    >
      Revoke
    </Button>
  );
};

RevokeInviteElement.displayName = 'RevokeInviteElement';

export const SubscriptionAdminInvitationStatus = (props: Props): JSX.Element => {
  const { subscriptionAdmin } = props;

  if (!subscriptionAdmin.isInvitationPending) {
    return <span>active</span>;
  }

  return (
    <div className="action-link__pane-wrapper">
      <div className="action-link__pane-info">pending invitation</div>
      <div className="action-link__pane">
        <Inline spacing={Spacing.S}>
          <ResendInviteElement {...props} />
          <RevokeInviteElement {...props} />
        </Inline>
      </div>
    </div>
  );
};

SubscriptionAdminInvitationStatus.displayName = 'SubscriptionAdminInvitationStatus';
