import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CollectionsLimitations } from '../../containers/limitations/CollectionsLimitations.tsx';
import { ContentTypesLimitations } from '../../containers/limitations/ContentTypesLimitations.tsx';

export interface ScopeLimitationsProps {
  readonly onWorkflowScopeDeleted?: (scopeId: Uuid) => void;
  readonly renderLabels?: boolean;
  readonly scopeId: string | undefined;
}

export const ScopeLimitations: React.FC<ScopeLimitationsProps> = ({
  onWorkflowScopeDeleted,
  renderLabels,
  scopeId,
}) => (
  <Row spacing={Spacing.M} alignY="center">
    <Column flexFactor={1}>
      <ContentTypesLimitations
        label={renderLabels ? 'Content type' : undefined}
        scopeId={scopeId}
      />
    </Column>

    <Column width="content">
      <Box paddingTop={renderLabels ? Spacing.XL : undefined}>in</Box>
    </Column>

    <Column flexFactor={1}>
      <CollectionsLimitations label={renderLabels ? 'Collection' : undefined} scopeId={scopeId} />
    </Column>

    <Column width="content">
      <Box paddingTop={renderLabels ? Spacing.XL : undefined}>
        <IconButton
          buttonState={onWorkflowScopeDeleted ? 'default' : 'disabled'}
          onClick={() => scopeId && onWorkflowScopeDeleted?.(scopeId)}
          buttonStyle="tertiary"
          iconName="Bin"
          size="medium"
          tooltipPlacement="bottom"
          tooltipText={
            onWorkflowScopeDeleted ? 'Delete rule' : 'Every workflow needs at least one rule'
          }
          {...getDataUiActionAttribute(DataUiAction.DeleteScope)}
        />
      </Box>
    </Column>
  </Row>
);

ScopeLimitations.displayName = 'Limitations';
