import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  WebSpotlight_LoadWebSpotlightTree_Started,
  WebSpotlight_LoadWebSpotlightTree_Succeeded,
} from '../../../../webSpotlight/constants/webSpotlightActionTypes.ts';
import { AssetLibrary_Folders_Opened } from '../../../assets/constants/assetLibraryActionTypes.ts';
import { Relations_InitStarted } from '../../../relations/constants/relationsActionTypes.ts';
import { BreadcrumbsOriginChanged } from '../../constants/contentInventoryActionTypes.ts';
import { IBreadcrumbsOrigin } from '../IContentInventoryStoreState.type.ts';

const initialState: IBreadcrumbsOrigin = {
  items: Immutable.List(),
};

export const breadcrumbsOrigin = (
  state: IBreadcrumbsOrigin = initialState,
  action: Action,
): IBreadcrumbsOrigin => {
  switch (action.type) {
    case BreadcrumbsOriginChanged:
      return {
        items: Immutable.List(action.payload.items),
      };

    case Relations_InitStarted:
      return {
        items: Immutable.List([
          {
            path: action.payload.relationsPath,
            title: AppNames.Relations,
          },
        ]),
      };

    case WebSpotlight_LoadWebSpotlightTree_Started:
      return {
        items: Immutable.List([
          {
            path: action.payload.rootPath,
            title: AppNames.WebSpotlight,
          },
        ]),
      };

    case WebSpotlight_LoadWebSpotlightTree_Succeeded:
      return {
        items: Immutable.List([
          {
            path: action.payload.rootItemPath,
            title: AppNames.WebSpotlight,
          },
        ]),
      };

    case AssetLibrary_Folders_Opened:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
};
