import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  ProjectListing_ProjectCloning_Initialized,
  Project_CopyState_Finished,
  Project_CopyState_Populate,
  Project_CopyState_Start,
  Project_CopyState_Stop,
} from '../../constants/projectActionTypes.ts';

const initialState = Immutable.Map<Uuid, boolean>();
export function cloningProjectsIds(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, boolean> {
  switch (action.type) {
    case ProjectListing_ProjectCloning_Initialized:
    case Project_CopyState_Populate: {
      if (!!action.payload.projectId && !state.has(action.payload.projectId)) {
        return state.set(action.payload.projectId, false);
      }
      return state;
    }
    case Project_CopyState_Finished: {
      return state.delete(action.payload.data.projectId);
    }
    case Project_CopyState_Start: {
      return state.set(action.payload.projectId, true);
    }
    case Project_CopyState_Stop: {
      return initialState;
    }

    default:
      return state;
  }
}
