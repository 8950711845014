import Immutable from 'immutable';
import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { moveSelectedAssetsToFolder } from '../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../types/orderBy.type.ts';

type Args = {
  readonly assetsById: Immutable.Map<string, IAsset>;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

export const useMoveSelectedAssetsToFolder = ({
  assetsById,
  deselectAssets,
  orderBy,
  selectedAssets,
}: Args) => {
  const dispatch = useDispatch();

  const moveSelectedAssets = useCallback(
    async (targetFolderId: Uuid): Promise<void> => {
      const movedAssetsIds = await dispatch(
        moveSelectedAssetsToFolder({
          assetsById,
          targetFolderId,
          orderBy,
          selectedAssets,
        }),
      );

      deselectAssets(movedAssetsIds);
    },
    [assetsById, deselectAssets, orderBy, selectedAssets],
  );

  return moveSelectedAssets;
};
