import { Box } from '@kontent-ai/component-library/Box';
import { spacingMainLayoutLeft } from '@kontent-ai/component-library/tokens';
import { Calendar as ContentPlanningCalendar } from '../../../contentPlanning/calendar/containers/Calendar/Calendar.tsx';

export const Calendar = () => {
  return (
    <Box flexGrow={1} overflowY="auto" paddingLeft={spacingMainLayoutLeft}>
      <ContentPlanningCalendar />
    </Box>
  );
};
