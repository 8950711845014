import React from 'react';
import { Controller } from 'react-hook-form';
import { DatetimePicker } from '../../../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { HookFormProps } from '../../../../../../../_shared/types/hookFormProps.type.ts';
import { getTodayReadableDate } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { showFieldError } from '../../../../../../../_shared/utils/validation/showFieldError.ts';
import { IDueDateFormShape } from '../../../models/IDueDateFormShape.type.ts';
import { FormPane } from '../FormPane.tsx';

type Props = {
  readonly datePickerId?: string;
  readonly formProps: HookFormProps<IDueDateFormShape>;
  readonly isSectionSubmitting: boolean;
  readonly onReset: () => void;
  readonly onSubmit: () => void;
  readonly inline?: boolean;
};

export const DueDateFormBase = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>(
  (props, ref) => (
    <FormPane onSubmit={props.onSubmit} onReset={props.onReset} ref={ref}>
      <Controller
        control={props.formProps.control}
        name="date"
        render={({ field, fieldState, formState }) => (
          <DatetimePicker
            ref={field.ref}
            autoFocus
            datePickerId={props.datePickerId}
            defaultTime="16:00"
            defaultDate={getTodayReadableDate()}
            disabled={props.isSectionSubmitting}
            hideValidationError={!showFieldError(formState, fieldState.error)}
            onChange={field.onChange}
            popperClassName="sidebar__date-input"
            value={field.value.value}
            inline={props.inline}
            //Todo: Adjust this after ACP-84 is done
            tippyOptions={{
              placement: 'right',
            }}
            isFullWidth
            title="Due date"
          />
        )}
      />
    </FormPane>
  ),
);

DueDateFormBase.displayName = 'DueDateFormBase';
