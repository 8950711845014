import { DragSource } from '../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { IStepBarDispatchProps, IStepBarStateProps, StepBar } from './StepBar.tsx';

export interface IDraggableStepBarDispatchProps extends IStepBarDispatchProps {
  readonly onStepDropped: () => void;
  readonly onStepPickedUp: () => void;
}

type IDraggableStepBarProps = IStepBarStateProps & IDraggableStepBarDispatchProps;

export const DraggableStepBar = (props: IDraggableStepBarProps) => {
  const { onStepDropped, onStepPickedUp, ...stepBarProps } = props;

  return (
    <DragSource
      parentId=""
      sourceId={stepBarProps.id}
      onDragStart={onStepPickedUp}
      onDragEnd={onStepDropped}
      renderDraggable={(connectDragSource, isDragging) => (
        <StepBar {...stepBarProps} connectDragSource={connectDragSource} isDragging={isDragging} />
      )}
      renderPreview={() => <StepBar {...stepBarProps} isDragging={false} />}
      type={DndTypes.WorkflowStep_Bar_Move}
    />
  );
};
