import React from 'react';
import { ICustomTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICustomItemElement } from '../../../../models/contentItemElements/CustomItemElement.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { ElementValue } from '../../../../types/CustomElementApi.ts';
import { ItemElement } from '../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../containers/elements/ItemElementRefresher.tsx';
import { CustomElementSandbox } from '../../containers/elements/customElement/CustomElementSandbox.tsx';
import { IItemElementComponentOwnProps } from './IItemElementOwnProps.type.ts';

export type CustomElementExtraProps = {
  readonly elements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly getElementValue: (elementCodename: string) => ElementValue | undefined;
  readonly onValueChange: (value: string | null, searchableValue: string | null) => void;
  readonly typeElements: ReadonlyArray<TypeElement>;
  readonly validationErrors?: ReadonlyArray<string>;
};

export type CustomElementProps = IItemElementComponentOwnProps<
  ICustomItemElement,
  ICustomTypeElement
> &
  CustomElementExtraProps;

export class CustomElement extends React.PureComponent<CustomElementProps> {
  static displayName = 'CustomElement';

  private readonly onChange = (value: string | null, searchableValue: string | null): void => {
    this.props.onValueChange(value, searchableValue);
  };

  render() {
    const {
      elementData,
      typeElement,
      disabled,
      autoFocus,
      typeElements,
      elements,
      getElementValue,
    } = this.props;

    return (
      <ItemElement typeElement={typeElement} disabled={disabled}>
        <ItemElementRefresher
          elementData={elementData}
          renderInput={(refresherItemElement: ICustomItemElement) => (
            <CustomElementSandbox
              typeElement={typeElement}
              value={refresherItemElement.value}
              onChange={this.onChange}
              typeElements={typeElements}
              elements={elements}
              getElementValue={getElementValue}
              autoFocus={autoFocus}
              className="custom-element__sandbox"
              disabled={disabled}
            />
          )}
        />
      </ItemElement>
    );
  }
}
