import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { Task, createTaskFromServerModel } from '../../../../../../_shared/models/Task.ts';
import { getCurrentUser } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import { ITaskRepository } from '../../../../../../repositories/interfaces/ITaskRepository.type.ts';
import {
  ContentItemEditing_LoadTasksForItemVariant_Finished,
  ContentItemEditing_LoadTasksForItemVariant_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { orderTasks } from '../../utils/orderTasks.ts';

const started = {
  type: ContentItemEditing_LoadTasksForItemVariant_Started,
} as const;

const finished = (orderedTasks: ReadonlyArray<Task>) =>
  ({
    type: ContentItemEditing_LoadTasksForItemVariant_Finished,
    payload: { orderedTasks },
  }) as const;

export type LoadTasksForItemVariantActionsType = ReturnType<typeof finished> | typeof started;

interface IDeps {
  readonly taskRepository: Pick<ITaskRepository, 'getTasksForItemVariant'>;
}

export type ILoadTasksForItemVariantAction = (
  itemId: Uuid,
  variantId: Uuid,
  abortSignal?: AbortSignal,
) => ThunkPromise;

export const loadTasksForItemVariantCreator =
  (deps: IDeps): ILoadTasksForItemVariantAction =>
  (itemId, variantId, abortSignal) =>
  async (dispatch, getState) => {
    dispatch(started);

    const serverModelTasks = await deps.taskRepository.getTasksForItemVariant(
      itemId,
      variantId,
      false,
      abortSignal,
    );
    const tasks = serverModelTasks.map(createTaskFromServerModel);
    const orderedTasks = orderTasks(tasks, getCurrentUser(getState()).userId);

    dispatch(finished(orderedTasks));
  };
