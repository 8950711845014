import { isElement } from '@kontent-ai/DOM';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import {
  CustomAppRouteParams,
  CustomAppsRoute,
  SubscriptionEnvironmentCustomAppsRoute,
  SubscriptionEnvironmentCustomAppsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  CustomApp,
  createCustomAppUpdateServerModel,
} from '../../../../data/models/customApps/CustomApp.ts';
import { RoleOption } from '../../../../data/models/roles/RoleOption.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { customAppNameChanged } from '../actions/customAppActions.ts';
import { CustomAppEditorToolbarActions } from '../components/toolbar/CustomAppEditorToolbarActions.tsx';
import { CustomAppSavingStatus } from '../components/toolbar/CustomAppSavingStatus.tsx';
import { CustomAppSavingStatusPlaceholderElementId } from '../components/toolbar/CustomAppSavingStatusPlaceholder.tsx';
import { CustomAppToolbarActionsPlaceholderElementId } from '../components/toolbar/CustomAppToolbarActionsPlaceholder.tsx';
import { CustomAppFormShape } from '../models/CustomAppFormShape.type.ts';
import { createCustomAppFromFormValues } from '../utils/createCustomAppFromFormValues.ts';
import { getDefaultRolesForFormInput } from '../utils/roleUtils.ts';
import { customAppEditorFormValidationConfig } from '../validation/customAppValidation.ts';
import { CustomAppEditorForm as CustomAppEditorFormComponent } from './../components/CustomAppEditorForm.tsx';

type CustomAppEditorFormProps = {
  readonly customApp: CustomApp;
  readonly roles: ReadonlyArray<RoleOption>;
  readonly subscriptionId?: Uuid;
};

const { customAppRepository } = repositoryCollection;

export const CustomAppEditorForm = ({
  customApp,
  roles,
  subscriptionId,
}: CustomAppEditorFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);

  const [customAppActionIsInProgress, setCustomAppActionIsInProgress] = useState<boolean>(false);
  const [displayNotificationBar, setDisplayNotificationBar] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>(customApp.name);

  const customAppFormDefaultValues: CustomAppFormShape = {
    sourceUrl: customApp.sourceUrl,
    config: customApp.config,
    name: customApp.name,
    allowedRoleIds: getDefaultRolesForFormInput(customApp.allowedRoles, roles),
  };
  const formProps = useForm<CustomAppFormShape>({
    defaultValues: customAppFormDefaultValues,
    mode: 'onTouched',
    resolver: createFormValidationResolver(customAppEditorFormValidationConfig, {}),
    shouldFocusError: false,
  });
  const { formState, handleSubmit, reset, setValue, getValues, trigger, watch } = formProps;

  useEffect(() => {
    const subscription = watch(async () => {
      const isActualFormValid = await trigger();
      if (displayNotificationBar && isActualFormValid) {
        setDisplayNotificationBar(false);
      }

      setIsValid(isActualFormValid);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [displayNotificationBar, trigger, watch]);

  useEffect(() => {
    const nameSubscription = watch((formValues: CustomAppFormShape, { name }) => {
      if (name === 'name') {
        dispatch(customAppNameChanged(formValues.name));
        setCurrentName(formValues.name);
      }
    });

    return () => {
      nameSubscription.unsubscribe();
    };
  }, [watch]);

  const updateCustomApp = async (formValues: CustomAppFormShape): Promise<void> => {
    setCustomAppActionIsInProgress(true);

    const modifiedCustomApp = createCustomAppFromFormValues(formValues, customApp);
    const serverModel = createCustomAppUpdateServerModel(modifiedCustomApp);
    await customAppRepository.update(serverModel);

    setCustomAppActionIsInProgress(false);
  };

  const submitForm = handleSubmit(
    async (formValues) => {
      await updateCustomApp(formValues);
      reset(formValues, { keepValues: true, keepIsValid: true });
    },
    () => {
      const values = getValues();
      if (values.sourceUrl === '') {
        setValue('sourceUrl', '', { shouldTouch: true });
      }
      setDisplayNotificationBar(true);
    },
  );

  const handleUnsavedChanges = async (onSuccess: () => void, onFail: () => void) => {
    try {
      const values = getValues();
      await updateCustomApp(values);

      if (isValid) {
        onSuccess();
      } else {
        onFail();
      }
    } catch {
      onFail();
    }
  };

  const handleDelete = async () => {
    await customAppRepository.archive(customApp.id);

    if (subscriptionId == null) {
      history.push(
        buildPath<CustomAppRouteParams>(CustomAppsRoute, {
          projectId,
        }),
      );
    } else {
      history.push(
        buildPath<SubscriptionEnvironmentCustomAppsRouteParams>(
          SubscriptionEnvironmentCustomAppsRoute,
          {
            projectId,
            subscriptionId,
          },
        ),
      );
    }
  };

  const { current: customAppToolbarContainerElement } = useObserveElementPresence(
    CustomAppToolbarActionsPlaceholderElementId,
  );

  const { current: customAppSavingStatusContainerElement } = useObserveElementPresence(
    CustomAppSavingStatusPlaceholderElementId,
  );

  return (
    <>
      <HtmlSettingsPageTitle
        customName={currentName}
        settingsAppName={EnvironmentSettingsAppNames.CustomApps}
      />
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={formState.isDirty}
        isBeingSaved={customAppActionIsInProgress}
        onSaveChanges={handleUnsavedChanges}
      />
      <CustomAppEditorFormComponent
        formProps={formProps}
        hasError={displayNotificationBar}
        onCloseNotificationBar={() => setDisplayNotificationBar(false)}
        onSubmit={submitForm}
        roles={roles}
      />
      {isElement(customAppSavingStatusContainerElement) &&
        createPortal(
          <CustomAppSavingStatus customAppActionIsInProgress={customAppActionIsInProgress} />,
          customAppSavingStatusContainerElement,
        )}
      {isElement(customAppToolbarContainerElement) &&
        createPortal(
          <CustomAppEditorToolbarActions
            customAppActionIsInProgress={customAppActionIsInProgress}
            customAppCodename={customApp.codename}
            customAppName={customApp.name}
            onSubmit={submitForm}
            onDelete={handleDelete}
          />,
          customAppToolbarContainerElement,
        )}
    </>
  );
};
