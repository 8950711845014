import { isXMLHttpRequest } from '@kontent-ai/errors';
import { CancelledPromiseError } from '@kontent-ai/utils';
import {
  BrowserOptions,
  Event,
  StackFrame,
  browserTracingIntegration,
  feedbackSyncIntegration,
  init,
  replayIntegration,
} from '@sentry/react';
import { detect } from 'detect-browser';

let feedbackIntegration: ReturnType<typeof feedbackSyncIntegration> | null = null;

export const SentryReportABugWidget = {
  enableWidget: (elementToAttachTo: Element) => {
    feedbackIntegration?.attachTo(elementToAttachTo, {});
  },
  disableWidget: () => {
    feedbackIntegration?.remove();
  },
};

export const initializeSentry = (): void => {
  if (!self._envConfig.isSentryEnabled) {
    return;
  }

  const isDebuggingEnabled = self._envConfig.isSentryDebuggingEnabled;
  const isProductionEnvironment = process.env.NODE_ENV === 'production';

  feedbackIntegration = feedbackSyncIntegration({
    autoInject: false,
    showBranding: false,
    showEmail: false,
    showName: false,
  });

  init({
    ...createIgnoreOptions(),
    debug: !isProductionEnvironment && isDebuggingEnabled,
    dsn: 'https://432dba6789127884fb07d753b8679857@o4506230339207168.ingest.sentry.io/4506257606574080',
    environment: process.env.NODE_ENV,
    integrations: [browserTracingIntegration(), replayIntegration(), feedbackIntegration],
    release: self._clientConfig.clientVersion || 'NotSet',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: isProductionEnvironment || isDebuggingEnabled ? 1 : 0,
    tracesSampleRate: 0.02, // https://kontent-ai.atlassian.net/browse/KCL-11943
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/app\.devkontentmasters\.com\/api/,
      /^https:\/\/app\.kontent\.ai\/api/,
    ],
  });
};

const createIgnoreOptions = (): Pick<
  BrowserOptions,
  'beforeSend' | 'denyUrls' | 'ignoreErrors'
> => {
  const browser = detect();

  return {
    beforeSend: (event, hint) => {
      if (browser?.name === 'safari' && Number.parseFloat(browser.version) < 15.6) {
        return null; // Ignore all errors caused by unsupported Safari versions.
      }

      const error = hint.originalException;
      if (isXMLHttpRequest(error)) {
        return null;
      }

      if (
        hint.mechanism?.type === 'onunhandledrejection' &&
        error instanceof CancelledPromiseError
      ) {
        return null; // Not an application error, the promise was cancelled by us on purpose.
      }

      if (isExceptionInInjectedThirdPartyCode(event)) {
        return null;
      }

      return event;
    },
    denyUrls: [
      // Chrome extensions, see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    ignoreErrors: [
      // Generic errors, see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      'top.GLOBALS', // Random plugins/extensions
      'originalCreateNotification', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'fb_xd_fragment', // Facebook borked
      // Our custom stuff:
      'Server returned handshake error', // signalr handshake error, see https://kontent-ai.atlassian.net/browse/KCL-12006
      'Expected a handshake response from the server.', // signalr handshake error, see https://kontent-ai.atlassian.net/browse/KCL-12023
      /^ResizeObserver loop completed with undelivered notifications.$/, // Firefox ResizeObserver
      /^ResizeObserver loop limit exceeded$/, // Chrome ResizeObserver
      /Object Not Found Matching Id:[0-9]+/, // Most likely Outlook browser extension safe scanning a smart link, see https://github.com/getsentry/sentry-javascript/issues/3440
      'does not correspond with provided preview URL origin', // Origin of the iframe message received by Web Spotlight is different from preview URL
      /^Object captured as promise rejection with keys: code, description, error$/, // User got signed out and will be redirected to login page
      /^Non-Error promise rejection captured with value: Not implemented on this platform$/, // Caused by LastPass extension in Safari, see https://kontent-ai.atlassian.net/browse/KCL-12063
      /^Incoming message origin .* does not correspond with provided custom element’s url\.$/, // https://kontent-ai.atlassian.net/browse/KCL-12081
      'NetworkError when attempting to fetch resource.', // Firefox specific error on reload during fetching data https://kontent-ai.atlassian.net/browse/KCL-13019
      'toscaAlertAlerter is not defined', // A problem with 3rd party browser extensions: https://stackoverflow.com/questions/78729166/referenceerror-anonymous-ongoing-level-error-unhandled-shadowdomunlocker-i
      'TricentisShadowDomUnlocker is not defined', // A problem with 3rd party browser extensions
      'TricentisAlertAlerter is not defined', // A problem with 3rd party browser extensions
    ],
  };
};

const isExceptionInInjectedThirdPartyCode = (event: Event): boolean => {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  return !!frames?.some((frame) => isCodeInjectedFromGtm(frame) || isIntercomWidgetCode(frame));
};

const isCodeInjectedFromGtm = (frame: StackFrame): boolean => {
  if (typeof frame.filename !== 'string') {
    return false;
  }

  return (
    frame.filename.startsWith('https://www.googletagmanager.com') ||
    frame.filename.startsWith('https://tags.srv.stackadapt.com')
  );
};

const isIntercomWidgetCode = (frame: StackFrame): boolean => {
  if (typeof frame.filename !== 'string') {
    return false;
  }

  return frame.filename.startsWith('https://js.intercomcdn.com');
};
