import React from 'react';
import { useLocation } from 'react-router';
import { PublishedVariantAssignment as Component } from '../../../../../../../_shared/components/VariantAssignments/PublishedVariantAssignment.tsx';
import {
  ContentItemTimelineLatestPublishedRoute,
  ContentItemTimelineLatestPublishedRouteParams,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IVariantStatusVariant } from '../../../../../../../_shared/models/VariantModels.type.ts';
import {
  buildPath,
  matchPath,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';

type PublishedStatusProps = {
  readonly variant: IVariantStatusVariant;
};

export const PublishedVariantAssignment: React.FC<PublishedStatusProps> = ({ variant }) => {
  const location = useLocation();

  const pathToLastPublishedRevision = useSelector((s) => {
    const matchParams = matchPath<ContentItemsRouteParams>(location.pathname, ContentItemsRoute);

    return buildPath<ContentItemTimelineLatestPublishedRouteParams<UuidArray>>(
      ContentItemTimelineLatestPublishedRoute,
      {
        app: matchParams?.app ?? ContentItemsAppRouteSegment.Content,
        projectId: s.sharedApp.currentProjectId,
        variantId: variant.id.variantId,
        spaceId: matchParams?.spaceId,
        contentItemIds: [variant.id.itemId],
      },
    );
  });

  return (
    <Component
      pathToLastPublishedRevision={pathToLastPublishedRevision}
      publishedAt={variant.lastPublishedAt}
    />
  );
};

PublishedVariantAssignment.displayName = 'PublishedVariantAssignment';
