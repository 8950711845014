import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IContentTypeSnippetTypeElementData } from '../../../models/elements/ContentTypeSnippetTypeElementData.ts';
import { IUrlSlugTypeElementData } from '../../../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { isTypeValid } from '../../../utils/typeValidationUtils.ts';
import {
  getUrlSlugGeneratedByContentTypeSnippetElement,
  getUrlSlugGeneratedByTextElement,
} from '../../../utils/urlSlugUtils.ts';
import {
  clearTypeEditorErrorMessage,
  removeTypeElement,
} from '../../sharedContentModelsActions.ts';

interface IDeps {
  readonly updateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createRemoveTypeElementWithDependenciesAction =
  (deps: IDeps) =>
  (typeElementId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const typeElements = state.contentModelsApp.typeEditor.editedType.typeElements;
    const removedTypeElement = typeElements.find((element) => element.elementId === typeElementId);
    if (!removedTypeElement) {
      return;
    }

    let urlSlug: IUrlSlugTypeElementData | null = null;
    if (removedTypeElement.type === ElementType.Text) {
      urlSlug =
        getUrlSlugGeneratedByTextElement(typeElements, removedTypeElement.elementId) ?? null;
    } else if (removedTypeElement.type === ElementType.ContentTypeSnippet) {
      const snippetId = (removedTypeElement as IContentTypeSnippetTypeElementData).snippetId;
      urlSlug =
        getUrlSlugGeneratedByContentTypeSnippetElement(
          typeElements,
          state.data.snippets.byId,
          snippetId,
        ) ?? null;
    }

    dispatch(removeTypeElement(typeElementId));

    if (isTypeValid(getState().contentModelsApp.typeEditor.validationResults)) {
      dispatch(clearTypeEditorErrorMessage());
    }

    if (urlSlug) {
      const updatedUrlSlug: IUrlSlugTypeElementData = {
        ...urlSlug,
        dependedOnTextElementId: null,
      };
      dispatch(deps.updateTypeElement(updatedUrlSlug));
    }
  };
