import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import { createUpdatedAssignmentForUpdateWorkflowStep } from '../../../../../../_shared/features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalUtils.ts';
import {
  Content_Editing_RestoreFromArchivedStepFinished,
  Content_Editing_RestoreFromArchivedStepStarted,
} from '../../../../constants/editorActionTypes.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { contentItemEditingSidebarSectionCleanedUp } from '../contentItemEditingActions.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

const start = () => ({ type: Content_Editing_RestoreFromArchivedStepStarted }) as const;
const success = () => ({ type: Content_Editing_RestoreFromArchivedStepFinished }) as const;

export type RestoreFromArchivedStepType = ReturnType<typeof start | typeof success>;

export const createRestoreFromArchivedStep =
  (deps: IDeps) =>
  (history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const changeWorkflowStepModalData = state.contentApp.changeWorkflowStepModalData;

    dispatch(start());

    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin: getModalDialogActionOrigin(state),
        history,
        getUpdatedAssignment: () =>
          createUpdatedAssignmentForUpdateWorkflowStep(changeWorkflowStepModalData),
        submittingSection: AssignmentSections.WorkflowStep,
      }),
    );

    dispatch(success());
    dispatch(contentItemEditingSidebarSectionCleanedUp());
    dispatch(modalDismissed());
  };
