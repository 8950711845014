import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import {
  NewContentItem_ContentItemCreatedInLinkedItems,
  NewContentItem_ContentItemCreatedInListing,
  NewContentItem_ContentItemCreatedInRichText,
  NewContentItem_CreatingContentItemInListing,
} from '../../features/NewContentItem/constants/newContentItemActionTypes.ts';

const initialState: string = '';

export function progressMessage(state = initialState, action: Action): string {
  switch (action.type) {
    case NewContentItem_CreatingContentItemInListing: {
      return 'Creating content item';
    }

    case NewContentItem_ContentItemCreatedInRichText:
    case NewContentItem_ContentItemCreatedInLinkedItems:
    case NewContentItem_ContentItemCreatedInListing:
    case ContentListing_Init_Started: {
      return initialState;
    }

    default:
      return state;
  }
}
