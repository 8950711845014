import { InvariantException } from '@kontent-ai/errors';
import { isUuid } from '../../../../../_shared/utils/validation/typeValidators.ts';
import { CommentThreadType } from '../../../models/comments/CommentThreads.ts';

const simpleCommentThreadTypes: ReadonlyArray<CommentThreadType> = [
  CommentThreadType.CustomElement,
  CommentThreadType.DateTime,
  CommentThreadType.MultipleChoice,
  CommentThreadType.Number,
  CommentThreadType.Taxonomy,
];

const getElementType = (commentThreadType: CommentThreadType): string => {
  switch (commentThreadType) {
    case CommentThreadType.RichText:
      return 'rich text';
    case CommentThreadType.RichTextLinkedItem:
      return 'linked item in rich text';
    case CommentThreadType.InlineImage:
      return 'asset in rich text';
    case CommentThreadType.Asset:
      return 'asset';
    case CommentThreadType.ElementLinkedItem:
      return '';
    case CommentThreadType.Number:
      return 'number';
    case CommentThreadType.Taxonomy:
      return 'taxonomy';
    case CommentThreadType.MultipleChoice:
      return 'multiple choice';
    case CommentThreadType.DateTime:
      return 'date & time';
    case CommentThreadType.CustomElement:
      return 'custom';
    default:
      throw InvariantException(`Comment thread type ${commentThreadType} doesn’t exists.`);
  }
};

const getRemovedElementMessage = (commentThreadType: CommentThreadType): string =>
  `Commented on removed ${getElementType(commentThreadType)} element:`;

export const isSimpleCommentThreadType = (commentThreadType: CommentThreadType): boolean =>
  simpleCommentThreadTypes.includes(commentThreadType);

export const simpleCommentThreadTypeMessage = (
  commentThreadType: CommentThreadType,
  elementName: string | null,
): string => {
  if (!elementName) {
    return getRemovedElementMessage(commentThreadType);
  }

  return `Commented on “${elementName}”:`;
};

const commentThreadTypesWithoutTextSegment: ReadonlyArray<CommentThreadType> = [
  CommentThreadType.Asset,
  CommentThreadType.ElementLinkedItem,
  CommentThreadType.InlineImage,
  CommentThreadType.RichTextLinkedItem,
];

export const isCommentThreadTypeWithoutTextSegment = (
  commentThreadType: CommentThreadType,
): boolean => commentThreadTypesWithoutTextSegment.includes(commentThreadType);

export const commentThreadTypeWithoutTextSegmentMessage = (
  commentThreadType: CommentThreadType,
  elementName: string | null,
  elementSegment: string | null,
  isInlineThreadWithRemovedContent: boolean | undefined,
): string => {
  if (!elementName) {
    return getRemovedElementMessage(commentThreadType);
  }

  const isAssetType =
    commentThreadType === CommentThreadType.InlineImage ||
    commentThreadType === CommentThreadType.Asset;
  const hasElementSegment = elementSegment && !isUuid(elementSegment); // in old data, assets / item ids (uuid) can be found in element segment property
  const elementType = isAssetType ? 'asset' : '';
  const segmentName = hasElementSegment ? `${elementType} “${elementSegment}”` : elementType;

  // You can't use ternary expression, because moreover character appears before segment name in Safari tests
  if (isInlineThreadWithRemovedContent) {
    return `Commented on removed ${segmentName} in “${elementName}”:`;
  }

  return `Commented on ${segmentName} in “${elementName}”:`;
};

export const isCommentThreadTypeWithTextSegment = (commentThreadType: CommentThreadType): boolean =>
  commentThreadType === CommentThreadType.RichText;

export const commentThreadTypeWithTextSegmentMessage = (
  commentThreadType: CommentThreadType,
  elementName: string | null,
  isInlineThreadWithRemovedContent: boolean | undefined,
): string => {
  if (!elementName) {
    return getRemovedElementMessage(commentThreadType);
  }

  return `Commented on ${
    isInlineThreadWithRemovedContent ? 'removed text in' : 'selected text in'
  } “${elementName}”:`;
};
