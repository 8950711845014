import { ElementType } from '../../applications/contentInventory/content/models/ContentItemElementType.ts';
import {
  IContentItemElementServerModel,
  ITaxonomyItemElementServerModel,
} from './elementServerModels.type.ts';

export const isTaxonomyItemElementServerModel = (
  element: IContentItemElementServerModel,
): element is ITaxonomyItemElementServerModel => {
  return element.type === ElementType.Taxonomy;
};
