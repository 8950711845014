import { assert } from '@kontent-ai/utils';
import { IPackageSummaryServerModel } from '../../../../repositories/serverModels/PackageSummaryServerModel.type.ts';

export interface IPackageSummary {
  readonly displayName: string;
  readonly name: string;
  readonly price: number;
}

export function getPackageSummaryFromServerModel(
  serverModel: IPackageSummaryServerModel,
): IPackageSummary {
  assert(!!serverModel, () => 'Package summary server model is falsy');

  return {
    displayName: serverModel.displayName,
    name: serverModel.name,
    price: serverModel.price,
  };
}
