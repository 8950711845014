import { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isTimeInPast } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { revokeApiKeyAndRedirect } from '../../actions/thunkApiKeysActions.ts';
import { RevokeApiKeyDialog as RevokeApiKeyDialogComponent } from '../../components/ApiKeyDetail/RevokeApiKeyDialog.tsx';
import {
  ExistingApiKeyWillBeInvalid,
  ExpiredApiKeyUnrecoverable,
} from '../../constants/warningMessageTemplates.ts';
import { getTokenSeedId } from '../../selectors/getTokenSeedId.ts';

export const RevokeApiKeyDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenSeedId = useSelector(getTokenSeedId);
    const isApiKeyExpired = useSelector(
      (state) =>
        !!state.data.apiKeys.keyDetail.expiresAt &&
        isTimeInPast(new Date(), state.data.apiKeys.keyDetail.expiresAt),
    );

    const onClose = useCallback(() => dispatch(modalDismissed()), []);
    const onConfirm = useCallback(() => {
      dispatch(modalDismissed());
      dispatch(revokeApiKeyAndRedirect(history, tokenSeedId));
    }, [tokenSeedId, history]);

    const warningMessage = isApiKeyExpired
      ? ExpiredApiKeyUnrecoverable
      : ExistingApiKeyWillBeInvalid;

    return (
      <RevokeApiKeyDialogComponent
        actionName="Revoke"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        ref={ref}
        warningMessage={warningMessage}
      />
    );
  },
);

RevokeApiKeyDialog.displayName = 'RevokeApiKeyDialogContainer';
