import { memoize } from '@kontent-ai/memoization';
import { DraftCSSProperties, DraftInlineStyle } from 'draft-js';

export const AiStyleIdPrefix = 'AI-';

export const isAiIdStyle = (style: string): boolean => style.startsWith(AiStyleIdPrefix);

export const getAiSessionId = (style: string): Uuid => style.substring(AiStyleIdPrefix.length);

export const getAiInlineStyle = (aiSessionId: Uuid): string => `${AiStyleIdPrefix}${aiSessionId}`;

export const getAiClassName = (aiSessionId: Uuid): string => `rte__ai--${aiSessionId}`;

export const getAiStyle = memoize.weak((styles: DraftInlineStyle): DraftInlineStyle => {
  return styles.filter(isAiIdStyle).toOrderedSet();
});

const EmptyCSSProperties: DraftCSSProperties = {};

export const getInlineStyleWithAiIds = memoize.weak(
  (style: DraftInlineStyle): DraftCSSProperties => {
    if (style.isEmpty()) {
      return EmptyCSSProperties;
    }

    const aiSessionIds = getAiStyle(style).map(getAiSessionId);
    if (aiSessionIds.isEmpty()) {
      return EmptyCSSProperties;
    }

    const className = [
      // While there is AI metadata at specific content, we highlight it
      // We currently use this only while streaming, in future we will use handling of the highlight similar to one used for comments
      'rte__ai',
      ...aiSessionIds.map(getAiClassName).toArray(),
    ].join(' ');

    return { className };
  },
);
