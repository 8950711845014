import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { TypeElementValidationResult } from './typeElementValidators/types/TypeElementValidationResult.type.ts';

export const NonLocalizableTypeElements: ReadonlyArray<ElementType> = [
  ElementType.Asset,
  ElementType.Custom,
  ElementType.DateTime,
  ElementType.LinkedItems,
  ElementType.MultipleChoice,
  ElementType.Number,
  ElementType.Subpages,
  ElementType.Text,
  ElementType.UrlSlug,
  ElementType.Taxonomy,
];

export const isTypeElementValid = (validationResult?: TypeElementValidationResult): boolean =>
  !!validationResult && validationResult.errorMessages.length === 0;

export const isTypeValid = (
  validationResults: ReadonlyMap<Uuid, TypeElementValidationResult>,
): boolean =>
  Array.from(validationResults.values()).every((validationResult) =>
    isTypeElementValid(validationResult),
  );

export const canTypeElementBeNonLocalizable = (typeElement: IBaseTypeElementData): boolean =>
  NonLocalizableTypeElements.includes(typeElement.type);
