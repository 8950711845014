import {
  AssetUploadFinishedEventForGlobalState,
  AssetUploadFinishedEventForLocalState,
} from '../assets/AssetUploadFinishedEvent.ts';

export const CustomEventName = {
  clientConfigChanged: 'clientConfigChanged',
  assetUploadFinishedForLocalState: 'assetUploadFinishedForLocalState',
  assetUploadFinishedForGlobalState: 'assetUploadFinishedForGlobalState',
} as const;

export type KontentEventMap = {
  readonly [CustomEventName.clientConfigChanged]: Event;
  readonly [CustomEventName.assetUploadFinishedForLocalState]: AssetUploadFinishedEventForLocalState;
  readonly [CustomEventName.assetUploadFinishedForGlobalState]: AssetUploadFinishedEventForGlobalState;
};
