import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { ContentTypeConversionOptions } from '../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import { CompiledContentTypeServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { UntitledContentTypeName } from '../../../contentModels/contentTypes/constants/contentTypeConstants.ts';
import { getCompiledTypeElementFromServerModel } from '../utils/getCompiledTypeElementFromServerModel.ts';
import { TypeElement } from './contentTypeElements/TypeElement.type.ts';
import {
  IContentGroup,
  getContentGroupFromServerModel,
} from './contentTypeElements/types/ContentGroup.ts';

export interface ICompiledContentType {
  readonly codeName: string | null;
  readonly contentElements: ReadonlyArray<TypeElement>;
  readonly contentGroups: Immutable.List<IContentGroup>;
  readonly id: Uuid;
  readonly lastModified: DateTimeStamp | null;
  readonly name: string;
}

export const EmptyCompiledContentType: ICompiledContentType = {
  codeName: '',
  contentElements: [],
  contentGroups: Immutable.List<IContentGroup>(),
  id: '',
  lastModified: null,
  name: '',
};

export function getCompiledContentTypeFromServerModel(
  serverModel: CompiledContentTypeServerModel,
  conversionOptions: ContentTypeConversionOptions,
): ICompiledContentType {
  assert(!!serverModel, () => 'Compiled content type server model is falsy.');

  return {
    codeName:
      serverModel.codeName !== undefined ? serverModel.codeName : EmptyCompiledContentType.codeName,
    contentElements: serverModel.contentElements?.map((element) =>
      getCompiledTypeElementFromServerModel(element, conversionOptions),
    ),
    contentGroups: Immutable.List(
      serverModel.contentGroups?.map((group) => getContentGroupFromServerModel(group)) ?? [],
    ),
    id: serverModel._id ?? EmptyCompiledContentType.id,
    lastModified: serverModel.lastModified,
    name: isEmptyOrWhitespace(serverModel.name) ? UntitledContentTypeName : serverModel.name,
  };
}
