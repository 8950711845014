import { Collection } from '@kontent-ai/utils';
import { ITaxonomyGroup } from '../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import {
  ITaxonomyTerm,
  emptyTaxonomyTerm,
} from '../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { uncategorizedItemFilterTaxonomyTermId } from '../constants/listingFilterConstants.ts';

function addUncategorizedFilterTerm(taxonomyGroup: ITaxonomyGroup): ITaxonomyGroup {
  const uncategorizedTerm: ITaxonomyTerm = {
    ...emptyTaxonomyTerm,
    id: uncategorizedItemFilterTaxonomyTermId,
    name: `∅ Not in ${taxonomyGroup.name}`,
  };

  return {
    ...taxonomyGroup,
    childIds: [uncategorizedItemFilterTaxonomyTermId, ...taxonomyGroup.childIds],
    terms: Collection.add(taxonomyGroup.terms, [
      uncategorizedItemFilterTaxonomyTermId,
      uncategorizedTerm,
    ]),
  };
}

export const getTaxonomiesForItemFilterSelector = (
  taxonomyGroups: ReadonlyArray<ITaxonomyGroup>,
): ReadonlyArray<ITaxonomyGroup> =>
  taxonomyGroups
    .filter((taxonomyGroup) => !!taxonomyGroup.childIds.length)
    .map(addUncategorizedFilterTerm);
