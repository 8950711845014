import {
  Subscription_CreateSubscription_CloseModal,
  Subscription_CreateSubscription_ShowModal,
  Subscription_SubscriptionListing_Leave,
} from '../contants/actionTypes.ts';

export const showCreateSubscriptionModal = () =>
  ({
    type: Subscription_CreateSubscription_ShowModal,
  }) as const;

export const closeCreateSubscriptionModal = () =>
  ({
    type: Subscription_CreateSubscription_CloseModal,
  }) as const;

export const leaveSubscriptionListing = () =>
  ({
    type: Subscription_SubscriptionListing_Leave,
  }) as const;
