import React from 'react';
import { IMultipleChoiceTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { IMultipleChoiceItemElement } from '../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { MultipleChoiceDiff } from '../../Revisions/components/elements/subelements/MultipleChoiceDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedMultipleChoiceElementProps = ExpandedItemElementDataProps<
  IMultipleChoiceTypeElement,
  IMultipleChoiceItemElement
>;

export const ExpandedMultipleChoiceElement: React.FC<ExpandedMultipleChoiceElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => {
  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      isCompact
      typeElement={typeElement}
    >
      <MultipleChoiceDiff
        elementData={elementData}
        originalElementData={originalElementData}
        typeElement={typeElement}
      />
    </ExpandedItemElement>
  );
};

ExpandedMultipleChoiceElement.displayName = 'ExpandedMultipleChoiceElement';
