import { memoize } from '@kontent-ai/memoization';
import { assert } from '@kontent-ai/utils';
import {
  ArchivedStep,
  IWorkflowStep,
  PublishedStep,
  RegularWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import { IWorkflowEditorStoreState } from '../reducers/IWorkflowAppState.type.ts';

const getOrderedStepsMemoized = memoize.maxOne(
  (
    workflowSteps: readonly RegularWorkflowStep[],
    workflowStepsOrder: UuidArray,
  ): ReadonlyArray<IWorkflowStep> => {
    return workflowStepsOrder.map((id: Uuid): IWorkflowStep => {
      const workflowStep = workflowSteps.find((step) => step.id === id);

      assert(
        !!workflowStep,
        () => `getOrderedStepsMemoized: workflowStep with id ${id} not found.`,
      );

      return workflowStep;
    });
  },
);

export const getOrderedRegularSteps = (
  workflowSteps: readonly RegularWorkflowStep[],
  workflowStepsOrder: UuidArray,
): ReadonlyArray<IWorkflowStep> => {
  return getOrderedStepsMemoized(workflowSteps, workflowStepsOrder);
};

const getOrderedAllStepsMemoized = memoize.maxOne(
  (
    workflowSteps: readonly RegularWorkflowStep[],
    workflowStepsOrder: UuidArray,
    publishedWorkflowStep: PublishedStep,
    archivedWorkflowStep: ArchivedStep,
  ): ReadonlyArray<IWorkflowStep> => {
    return getOrderedStepsMemoized(workflowSteps, workflowStepsOrder).concat([
      publishedWorkflowStep,
      archivedWorkflowStep,
    ]);
  },
);

export const getOrderedAllSteps = (
  workflowEditorApp: IWorkflowEditorStoreState,
): ReadonlyArray<IWorkflowStep> =>
  getOrderedAllStepsMemoized(
    workflowEditorApp.regularWorkflowSteps,
    workflowEditorApp.regularWorkflowStepsOrder,
    workflowEditorApp.publishedWorkflowStep,
    workflowEditorApp.archivedWorkflowStep,
  );
