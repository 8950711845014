import { EditingQuickAction } from '../../models/EditingQuickAction.ts';

export const presetOrderedQuickActions = [
  EditingQuickAction.previewItem,
  EditingQuickAction.addTask,
  EditingQuickAction.changeWorkflowStep,
  EditingQuickAction.publishItem,
  EditingQuickAction.changeDueDate,
  EditingQuickAction.assignPeople,
  EditingQuickAction.changeNote,
];
