import React from 'react';
import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SpacesTitle } from '../../../constants/uiConstants.ts';
import { NonClickableSpacesSection } from '../../../containers/details/Spaces/SectionTypes/NonClickableSpacesSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

export const SpacesSection: React.FC = () => (
  <Section
    ClickableSectionComponent={() => null}
    NonClickableSectionComponent={NonClickableSpacesSection}
    ReadonlySectionComponent={() => null}
    title={SpacesTitle}
    type={SectionType.NonClickable}
    uiObjectNameAttribute={ItemDetailSection.SpacesSection}
  />
);

SpacesSection.displayName = 'SpacesSection';
