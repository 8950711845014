import { ITaxonomyTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import {
  EmptyTaxonomyItemElement,
  ITaxonomyItemElement,
} from '../../models/contentItemElements/TaxonomyItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createTaxonomyItemElement: ItemElementCreator<
  ITaxonomyTypeElement,
  ITaxonomyItemElement
> = (taxonomyTypeElement, omitDefaultValue): ITaxonomyItemElement => ({
  ...EmptyTaxonomyItemElement,
  elementId: taxonomyTypeElement.elementId,
  groupId: taxonomyTypeElement.taxonomyGroupId,
  value: omitDefaultValue
    ? EmptyTaxonomyItemElement.value
    : new Set(taxonomyTypeElement.defaultValue),
});
