import { INumberTypeElement } from '../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import { validElementWarningResult } from '../../constants/validElementWarningResults.ts';
import { IsRequiredWarning } from '../../constants/warningMessageTemplates.ts';
import { INumberItemElement } from '../../models/contentItemElements/NumberItemElement.ts';
import { ItemElementWarningResult, emptyItemElementWarningResult } from './types/Warnings.ts';

const isRequiredLimitValid = (
  typeElement: INumberTypeElement,
  itemElement: INumberItemElement,
): boolean => {
  if (typeElement.isRequired) {
    const value = itemElement.value;
    return !!value;
  }

  return true;
};

interface IParams {
  readonly typeElement: INumberTypeElement;
  readonly itemElement: INumberItemElement;
}

const invalidElementResult: ItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  requiredMessage: IsRequiredWarning,
};

export const getNumberItemElementValidationWarning = ({
  typeElement,
  itemElement,
}: IParams): ItemElementWarningResult => {
  const isRequiredLimitMet = isRequiredLimitValid(typeElement, itemElement);

  return isRequiredLimitMet ? validElementWarningResult : invalidElementResult;
};
