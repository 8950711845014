import {
  colorAlertBackground,
  colorAlertBackgroundInverse,
  colorAlertText,
  colorBackgroundDisabled,
  colorBorderAlert,
  colorBorderDefault,
  colorBorderDisabled,
  colorIconDisabled,
  colorPrimary,
  colorPrimaryHover,
  colorPrimaryHoverInverse,
  colorTextDefault,
  colorTextDisabled,
  colorTextHint,
} from '../../../tokens/decision/colors.ts';
import { BaseColor, Color } from '../../../tokens/quarks/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { CheckboxProps } from '../Checkbox.tsx';
import { CheckboxStatus } from '../types.ts';

export const checkboxPadding = Spacing.S;
export const checkboxSymbolSize = Spacing.L;
export const captionTextColor = colorTextHint;

export type CheckboxStylingProps = Pick<CheckboxProps, 'checkboxState'> & {
  readonly checkboxStatus: CheckboxStatus;
};

export type CheckboxTokens = Readonly<{
  backgroundColor: Color;
  backgroundColorHover: Color;
  captionTextColor: BaseColor;
  iconBackgroundColor: Color;
  iconBackgroundColorHover: Color;
  iconBorderColor: Color;
  iconBorderColorHover: Color;
  iconColor: Color;
  iconColorHover: Color;
  labelTextColor: BaseColor;
}>;

const defaultCheckboxTokens: CheckboxTokens = {
  backgroundColor: BaseColor.Transparent,
  backgroundColorHover: colorPrimaryHoverInverse,
  captionTextColor: colorTextHint,
  iconBackgroundColor: BaseColor.White,
  iconBackgroundColorHover: BaseColor.White,
  iconBorderColor: colorBorderDefault,
  iconBorderColorHover: colorPrimaryHover,
  iconColor: BaseColor.White,
  iconColorHover: BaseColor.White,
  labelTextColor: colorTextDefault,
};

const alertCheckboxTokens = {
  backgroundColorHover: colorAlertBackground,
  iconBorderColor: colorBorderAlert,
  iconBorderColorHover: colorBorderAlert,
  labelTextColor: colorAlertText,
} satisfies Partial<CheckboxTokens>;

export const getCheckboxTokens = (props: CheckboxStylingProps): CheckboxTokens => {
  const { checkboxState, checkboxStatus } = props;

  if (checkboxState === 'disabled') {
    return {
      ...defaultCheckboxTokens,
      backgroundColorHover: defaultCheckboxTokens.backgroundColor,
      captionTextColor: colorTextDisabled,
      iconBackgroundColor: colorBackgroundDisabled,
      iconBackgroundColorHover: colorBackgroundDisabled,
      iconBorderColor: colorBorderDisabled,
      iconBorderColorHover: colorBorderDisabled,
      iconColor: colorIconDisabled,
      iconColorHover: colorIconDisabled,
      labelTextColor: colorTextDisabled,
    };
  }

  if (checkboxStatus !== 'unchecked') {
    if (checkboxState === 'alert') {
      return {
        ...defaultCheckboxTokens,
        ...alertCheckboxTokens,
        iconBackgroundColor: colorAlertBackgroundInverse,
        iconBackgroundColorHover: colorAlertBackgroundInverse,
      };
    }

    return {
      ...defaultCheckboxTokens,
      iconBackgroundColor: colorPrimary,
      iconBackgroundColorHover: colorPrimaryHover,
      iconBorderColor: colorPrimary,
      iconBorderColorHover: colorPrimaryHover,
    };
  }

  if (checkboxState === 'alert') {
    return {
      ...defaultCheckboxTokens,
      ...alertCheckboxTokens,
    };
  }

  return defaultCheckboxTokens;
};
