import { ScrollAlignment, ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { gridUnit } from '../../../../../_shared/constants/styleConstants.ts';
import { DefaultScrollOffset } from '../../../../../_shared/hooks/useAutoScroll.ts';
import { joinWithOxfordComma } from '../../../../../_shared/utils/stringUtils.ts';

export const CancelItemScheduledPublishErrorMessage =
  'There was an error while canceling the scheduled publishing for this content item. If this keeps happening, please let us know.';
export const CancelItemScheduledUnpublishErrorMessage =
  'There was an error while removing the unpublishing date for this content item. If this keeps happening, please let us know.';
export const CodenameIsAutoGeneratedMessage = 'Autogenerated from content item name.';
export const CodenameChangeMayBreakYourAppMessage = 'Changing the codename could break your app.';
export const CodenameIsNotUniqueErrorMessage =
  'The codename was not saved. You are trying to use a codename that is taken by another content item.';
export const CodenameSavingFailedErrorMessage = 'Something went wrong. The codename was not saved.';
export const CodenameEditingPossibleOnlyInDefaultLanguage =
  'You can edit the codename only in the default language version.';
export const CollectionsTitle = 'Collection';
export const SpacesTitle = 'Space';
export const CreateNewVersionErrorMessage =
  'Creating new version failed. Please refresh the page and try again.';
export const DiscardNewVersionErrorMessage =
  'There was an error while discarding version. If this keeps happening, please let us know.';
export const MentionedUsersCannotSeeMentionErrorMessage = (userNames: ReadonlyArray<string>) =>
  `${joinWithOxfordComma(
    userNames,
  )} will not be able to see this comment as they do not have access to this content item or content group.`;
export const UnableToSeeTaskErrorMessage = (userNames: ReadonlyArray<string>) =>
  `${joinWithOxfordComma(
    userNames,
  )} will not be able to see this task as they do not have access to this content item.`;
export const UpdateItemCollectionErrorMessage = (collectionName: string) =>
  `We couldn’t move this item to ${collectionName}. Please try again later.`;
export const UserRoleCannotCreateNewItem = 'Your role cannot create items from the allowed types';

// Keep in sync with content-item-pane.less
export const SpaceTakenByActions = 12 * gridUnit;
export const SpaceTakenByActionButtons = 58;
export const ContentItemPaneCommentListWidth = gridUnit * 42;
export const ContentItemPaneCommentThreadLeftMargin = gridUnit * 3;

export const CommentThreadCssClass = 'comment-thread';

export const ItemEditorScrollOptions: ScrollElementsToViewOptions = {
  topOffset: SpaceTakenByActions,
  bottomOffset: DefaultScrollOffset,
};

export const FloatingEditorScrollOptions: ScrollElementsToViewOptions = {
  topOffset: DefaultScrollOffset,
  bottomOffset: DefaultScrollOffset,
  alignment: ScrollAlignment.Top,
};

export const ItemElementScrollOptions: ScrollElementsToViewOptions = {
  alignment: ScrollAlignment.Top,
};

export const NonLocalizableElementDefaultVariantTooltip = (defaultLanguageName: string) =>
  `In other languages, this element uses the published content from ${defaultLanguageName}. Publishing a new version in ${defaultLanguageName} updates this element’s content across all languages, including their published content.`;

export const NonLocalizableElementRevisionsTooltip = (defaultLanguageName: string) =>
  `You see here this element’s latest ${defaultLanguageName} content. To see its versions, go to the ${defaultLanguageName} variant of this item.`;

export const NonLocalizableElementCannotViewDefaultVariantTooltip = (defaultLanguageName: string) =>
  `The current language uses the published ${defaultLanguageName} content for this element. Ask a colleague with enough permissions to edit it.`;

export const NonLocalizableElementPublishedContentUsedTooltip = (defaultLanguageName: string) =>
  `This element uses the published ${defaultLanguageName} content.`;

export const NonLocalizableElementLatestContentUsedTooltip = (defaultLanguageName: string) =>
  `Your app uses the published ${defaultLanguageName} content for this element. In Kontent.ai, you see the element’s latest content even before it’s published.`;

export const NonLocalizableElementRequiredTooltip = (defaultLanguageName: string) =>
  `This required element uses the ${defaultLanguageName} content which needs to be published before you can publish this language.`;

export const NonLocalizableElementStaysEmptyTooltip = (defaultLanguageName: string) =>
  `This element uses the ${defaultLanguageName} content. Until that’s published, the element stays empty in your app across all languages.`;

export const DefaultVariantRequiredButIncompleteMessage = (
  selectedLanguageName: string,
  defaultLanguageName: string,
  canPublishDefaultVariant: boolean,
  isPublishingStepAvailableForDefaultVariant: boolean,
): string =>
  `You can’t publish this item in ${selectedLanguageName} before it’s ${
    canPublishDefaultVariant ? 'completed' : 'published'
  } in ${defaultLanguageName}.${
    isPublishingStepAvailableForDefaultVariant
      ? ''
      : ` This item’s ${defaultLanguageName} variant can’t transition to the Published step.`
  }`;

export const MinimumOptionCountForCompactSelector = 7;

export const TaskPopoverDialogWidth = gridUnit * 51;
export const TaskPopoverPaddingSize = Spacing.XL;
export const TaskPopoverFriendlyWarningMaxWidth =
  TaskPopoverDialogWidth - 2 * TaskPopoverPaddingSize;
