import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { closeSwapEnvironmentDialog } from '../actions/environmentsActions.ts';
import { swapEnvironments } from '../actions/thunkEnvironmentsActions.ts';
import { SwapEnvironmentDialog as SwapEnvironmentDialogComponent } from '../components/SwapEnvironmentDialog.tsx';

export const SwapEnvironmentDialog: React.FC<{ readonly isCustomAssetDomainSet?: boolean }> = ({
  isCustomAssetDomainSet,
}) => {
  const showDialog = useSelector((s) => s.environmentsApp.showDialogs.swapDialog);
  const swappedEnvironmentId = useSelector((s) => s.environmentsApp.editedEnvironmentId);
  const swappingEnvironment = useSelector((s) => s.environmentsApp.swappingEnvironment);
  const swappingFailed = useSelector((s) => s.environmentsApp.swappingEnvironmentFailed);

  const environment = useSelector((s) => s.data.projects.byId.get(swappedEnvironmentId));
  const dispatch = useDispatch();

  return (
    <SwapEnvironmentDialogComponent
      environmentId={swappedEnvironmentId}
      environmentName={environment?.environmentName ?? ''}
      oldProductionId={environment?.masterEnvironmentId ?? ''}
      isCustomAssetDomainSet={!!isCustomAssetDomainSet}
      isObsolete={environment?.isEnvironmentObsolete ?? false}
      onClose={() => dispatch(closeSwapEnvironmentDialog())}
      onSwap={(environmentId, oldProductionId, enableWebhooks) =>
        dispatch(swapEnvironments(environmentId, oldProductionId, enableWebhooks))
      }
      showDialog={showDialog}
      swappingEnvironment={swappingEnvironment}
      swappingFailed={swappingFailed}
    />
  );
};
