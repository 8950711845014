import classNames from 'classnames';
import { useRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import useResizeObserver from 'use-resize-observer';
import { BarItemDragAction } from '../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemError } from '../../../../../_shared/components/BarItems/BarItemError.tsx';
import { CodenameBarItemButton } from '../../../../../_shared/components/Codename/CodenameBarItemButton.tsx';
import { ScreenExtraLargeMinimalWidth } from '../../../../../_shared/constants/uiConstants.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { getDataUiObjectNameAttribute } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import {
  getWorkflowTagsForTransitionsTo,
  numberOfTagsSizeL,
  numberOfTagsSizeM,
} from '../../utils/getWorkflowTagsForTransitionTo.tsx';
import { AddNextStepButton } from './AddNextStepButton.tsx';
import { PlaceholderOuterAction } from './PlaceholderOuterAction.tsx';
import { StepColorPin } from './StepColor/StepColorPin.tsx';

export interface IStepBarOwnProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
}

export interface IStepBarStateProps {
  readonly allRenderedSteps: ReadonlyArray<IWorkflowStep>;
  readonly canAddNewStep: boolean;
  readonly codename: string;
  readonly color: WorkflowStepColor;
  readonly errorMessage?: string;
  readonly id: Uuid;
  readonly isDraggable: boolean;
  readonly isReadonly?: boolean;
  readonly isRegularStep: boolean;
  readonly name: string;
  readonly relatedCodenames?: ReadonlySet<string>;
}

export interface IStepBarDispatchProps {
  readonly onAddNextStep?: (stepId: Uuid) => void;
  readonly onCodenameSave?: (codename: string) => void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onStatusEditingInitiated: () => void;
}

type Props = IStepBarOwnProps & IStepBarStateProps & IStepBarDispatchProps;

export const StepBar = ({
  relatedCodenames,
  allRenderedSteps,
  canAddNewStep,
  codename,
  color,
  connectDragSource,
  errorMessage,
  id,
  isDraggable,
  isDragging,
  isReadonly,
  isRegularStep,
  name,
  onAddNextStep,
  onCodenameSave,
  onCodenameCopy,
  onCodenameEdit,
  onStatusEditingInitiated,
}: Props) => {
  const onStatusEditingInit = isReadonly ? undefined : onStatusEditingInitiated;
  const numberOfTags = useNumberOfRenderedTags();

  return (
    <div className="bar-item__wrapper bar-item__wrapper--is-flexible">
      <div
        className={classNames('bar-item__pane', {
          'bar-item__pane--is-dragging': isDragging,
        })}
        {...getDataUiObjectNameAttribute(name)}
      >
        <div
          className={classNames('bar-item__bar', {
            'bar-item__bar--is-clickable': !isReadonly,
          })}
        >
          {isDraggable && (
            <BarItemActions>
              <BarItemDragAction connectDragSource={connectDragSource} disabled={isReadonly} />
            </BarItemActions>
          )}
          <StepColorPin color={color} onClick={onStatusEditingInit} />
          <div className="bar-item__title-pane" onClick={onStatusEditingInit}>
            <div className="bar-item__title" data-hj-suppress={isReadonly ? '' : undefined}>
              <div className="bar-item__title-name">
                <div className="bar-item__title-name-text">{name}</div>
              </div>
            </div>
            <div className="bar-item__tags-pane">
              {getWorkflowTagsForTransitionsTo(id, allRenderedSteps, numberOfTags)}
            </div>
          </div>
          <BarItemActions>
            <CodenameBarItemButton
              codename={codename}
              maxLength={ValidationConstants.WorkflowStatusCodenameMaxLength}
              onCodenameSave={isRegularStep ? onCodenameSave : undefined}
              onCodenameCopy={onCodenameCopy}
              onCodenameEdit={onCodenameEdit}
              relatedCodenames={relatedCodenames}
            />
            <BarItemEditAction onClick={onStatusEditingInit} disabled={isReadonly} />
          </BarItemActions>
        </div>
        <BarItemError showError={!!errorMessage} error={errorMessage} />
      </div>
      <div className="bar-item__outer-actions-pane">
        {!isDragging && canAddNewStep ? (
          <AddNextStepButton
            onClick={isReadonly ? undefined : () => onAddNextStep?.(id)}
            disabled={isReadonly}
          />
        ) : (
          <PlaceholderOuterAction />
        )}
      </div>
    </div>
  );
};

const useNumberOfRenderedTags = (): number => {
  const [numberOfTags, setNumberOfTags] = useState(numberOfTagsSizeL);
  const documentBodyRef = useRef(self.document.body);

  useResizeObserver({
    ref: documentBodyRef,
    onResize: ({ width }) => {
      if (width) {
        setNumberOfTags(
          width > ScreenExtraLargeMinimalWidth ? numberOfTagsSizeL : numberOfTagsSizeM,
        );
      }
    },
  });

  return numberOfTags;
};
