import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isLanguageActive } from '../../../../../../../_shared/models/utils/isLanguageActive.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { hasCapabilityInLanguageForUser } from '../../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { getCurrentProject } from '../../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  ContentItemStatusWithTranslationOptions,
  ContentItemStatusWithoutTranslationOptions,
  IContentItemStatus,
  convertContentItemStatusToQueryParams,
  getContentItemStatusFromIds,
} from '../../../../models/filter/IContentItemStatus.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly onContentItemStatusSelectionChanged: (itemStatus: IContentItemStatus) => void;
  readonly selectedContentItemStatus: IContentItemStatus;
  readonly selectedLanguageId: Uuid;
  readonly user: IUser;
};

export const ContentItemStatusSelector = (props: Props) => {
  const { projectId } = useSelector(getCurrentProject);
  const isFilterCategoryVisible = useSelector(
    (state) => !Collection.isEmpty(state.data.users.usersById),
  );
  const languagesById = useSelector((state) => state.data.languages.byId);
  const customLanguages = useMemo(() => languagesById.valueSeq().toArray(), [languagesById]);
  const label = 'Select a status';

  const showTranslationOptionsInStatusCategory =
    customLanguages.some(isLanguageActive) &&
    hasCapabilityInLanguageForUser(
      props.user,
      projectId,
      Capability.CreateContent,
      props.selectedLanguageId,
    );

  const publishStateOptions = showTranslationOptionsInStatusCategory
    ? ContentItemStatusWithTranslationOptions
    : ContentItemStatusWithoutTranslationOptions;

  const selectedContentItemStatusIds = convertContentItemStatusToQueryParams(
    props.selectedContentItemStatus,
  );
  const selectedOptionIds = publishStateOptions
    .filter((option) => selectedContentItemStatusIds.includes(option.id))
    .map((option) => option.id);

  return isFilterCategoryVisible ? (
    <ListingFilterCategorySelector
      options={publishStateOptions}
      collection={DataUiCollection.Statuses}
      placeholder={label}
      ariaLabel={label}
      title="Status"
      onChange={(selectedIds) => {
        const selectedContentItemStatusRecord = getContentItemStatusFromIds(new Set(selectedIds));
        props.onContentItemStatusSelectionChanged(selectedContentItemStatusRecord);
      }}
      selectedOptionIds={selectedOptionIds}
    />
  ) : null;
};
