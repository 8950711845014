import { Action } from '../../../../../@types/Action.type.ts';
import { Calendar_LoadItemsWithVariantActiveInPeriod_Finished } from '../../constants/calendarActionTypes.ts';

export const wasDataCountLimitExceeded = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Calendar_LoadItemsWithVariantActiveInPeriod_Finished: {
      return action.payload.wasDataCountLimitExceeded;
    }

    default:
      return state;
  }
};
