import { Box } from '@kontent-ai/component-library/Box';
import { TextSkeleton } from '@kontent-ai/component-library/Skeleton';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  Color,
  Spacing,
  Typography,
  colorTextDefault,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IHorizontalStackedBarData } from '../../../types/IHorizontalStackedBarData.type.ts';
import { ChartSection } from './ChartSection.tsx';

interface IHorizontalStackedBarChartProps {
  readonly chartLabel: string;
  readonly data: ReadonlyArray<IHorizontalStackedBarData>;
  readonly itemTypeName: string;
  readonly loading: boolean;
}

const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${px(gridUnit * 1.25)};
  padding: ${px(Spacing.S)} 0 ${px(Spacing.L)} 0;
  gap: 2px;
  box-sizing: content-box;
`;

const ChartLegend = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: ${px(Spacing.S)};
  column-gap: ${px(Spacing.S)};
  justify-content: space-between;
`;

const ChartLegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const ChartLegendColorCircle = styled.div<{
  readonly color: Color;
}>`
  width: ${px(gridUnit)};
  height: ${px(gridUnit)};
  background-color: ${(props) => props.color};
  border-radius: 100%;
  margin-right: ${px(Spacing.S)};
`;

export const HorizontalStackedBarChart: React.FC<IHorizontalStackedBarChartProps> = ({
  data,
  loading,
  itemTypeName,
  chartLabel,
}) => {
  const total = useMemo(() => data.reduce((sum, section) => sum + section.value, 0), [data]);

  if (loading) {
    return (
      <>
        <ChartContainer>
          <TextSkeleton width="100%" />
        </ChartContainer>
        <ChartLegend>
          {data.map((section) => (
            <ChartLegendItem key={section.id}>
              <TextSkeleton width={100} />
            </ChartLegendItem>
          ))}
        </ChartLegend>
        <SrOnly>Loading data for the content progress widget.</SrOnly>
      </>
    );
  }

  if (total === 0) {
    return (
      <>
        <Box paddingY={Spacing.S} typography={Typography.HeadlineLarge} color={colorTextDefault}>
          &nbsp;
        </Box>
        <Box typography={Typography.Subheadline} color={colorTextDefault}>
          No insights yet
        </Box>
      </>
    );
  }

  return (
    <>
      <ChartContainer role="img" aria-label={chartLabel}>
        {data.map((section) => {
          const itemCountInText = pluralizeWithCount(itemTypeName, section.value);
          return section.value > 0 ? (
            <ChartSection
              key={section.id}
              item={section}
              tooltipText={itemCountInText}
              total={total}
            />
          ) : null;
        })}
      </ChartContainer>
      <ChartLegend>
        {data.map((section) => (
          <ChartLegendItem key={section.id}>
            <ChartLegendColorCircle color={section.color} />
            <Box typography={Typography.Subheadline} color={colorTextDefault}>
              {section.label}
            </Box>
          </ChartLegendItem>
        ))}
      </ChartLegend>
    </>
  );
};

HorizontalStackedBarChart.displayName = 'HorizontalStackedBarChart';
