import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { userNotFound } from '../../actions/subscriptionUserDetailActions.ts';

interface IUserDetailDataEnsurerOwnProps {
  readonly userId: string;
  readonly userListingLink: string;
  readonly children: (user: IUserInfo, onNavigateBack: () => void) => React.ReactElement;
}

export const UserDetailDataEnsurer: React.FC<IUserDetailDataEnsurerOwnProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.data.subscriptions.subscriptionUsersUsage?.subscriptionUsers?.find(
      ({ userId }) => userId === props.userId,
    );
  });

  const onUserNotFound = useCallback(() => {
    history.push(props.userListingLink);
  }, [history, props.userListingLink]);

  const onNavigateBack = useCallback(
    () => history.push(props.userListingLink),
    [history, props.userListingLink],
  );

  if (!user) {
    dispatch(userNotFound());
    onUserNotFound();
    return null;
  }

  return props.children(user, onNavigateBack);
};
