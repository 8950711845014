import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';

export const NoSuggestionsEmptyState: React.FC = () => (
  <EmptyState>
    <EmptyState.Title>No suggestions available</EmptyState.Title>
    <EmptyState.Content>We couldn’t find any relevant content items to suggest.</EmptyState.Content>
  </EmptyState>
);

NoSuggestionsEmptyState.displayName = 'NoSuggestionsEmptyState';
