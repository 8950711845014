import { Collection } from '@kontent-ai/utils';
import { IRole } from '../../../data/models/roles/IRole.ts';
import { getDeletedRole } from '../constants/getDeletedRole.ts';

export const getDeletedRoles = (
  allRoles: ReadonlyArray<IRole>,
  roleIds: ReadonlySet<Uuid>,
): ReadonlyArray<IRole> =>
  Collection.getValues(roleIds)
    .filter((id) => !allRoles.some((role) => !!role && role.id === id))
    .map(getDeletedRole);
