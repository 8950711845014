import React from 'react';
import { ILinkedItemsTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { ILinkedItemsElement } from '../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { LinkedItemsDiff } from '../../Revisions/components/elements/subelements/LinkedItemsDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedLinkedItemsElementProps = ExpandedItemElementDataProps<
  ILinkedItemsTypeElement,
  ILinkedItemsElement
>;

export const ExpandedLinkedItemsElement: React.FC<ExpandedLinkedItemsElementProps> = ({
  className,
  elementData,
  originalElementData,
  typeElement,
}) => {
  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      typeElement={typeElement}
    >
      <div className="modular-content__items">
        <LinkedItemsDiff
          elementData={elementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      </div>
    </ExpandedItemElement>
  );
};

ExpandedLinkedItemsElement.displayName = 'ExpandedLinkedItemsElement';
