import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { isScheduledToPublishWorkflowStep } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_Succeeded,
  ProjectOverview_Page_Left,
} from '../../../constants/projectOverviewActionTypes.ts';

const initialState: Immutable.Set<Uuid> = Immutable.Set();

const isScheduledToPublish = ({ variant }: IListingContentItem): boolean =>
  !!variant && isScheduledToPublishWorkflowStep(variant.assignment.workflowStatus);

export const scheduledToPublishItemsIds = (
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> => {
  switch (action.type) {
    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      return Immutable.Set(
        action.payload.contentItems
          .filter(isScheduledToPublish)
          .map((itemVariant) => itemVariant.item.id),
      );
    }

    case ProjectOverview_Page_Left: {
      return initialState;
    }

    default:
      return state;
  }
};
