import { Box } from '@kontent-ai/component-library/Box';
import { TabItem, TabView } from '@kontent-ai/component-library/TabView';
import {
  Spacing,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { trackUserEvent } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { PageTitle } from '../../../_shared/components/PageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useGetLastRouteSegment } from '../../../_shared/hooks/useGetLastRouteSegment.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { initMissionControl } from '../actions/thunkMissionControlActions.ts';
import { ContentPace } from '../contentPace/containers/ContentPace.tsx';
import { ContentStatusTabContent } from '../contentStatus/components/ContentStatusTabContent.tsx';
import { MissionControlDemoContextProvider } from '../contexts/MissionControlDemoContext.tsx';
import { DashboardTabContent } from '../dashboard/container/DashboardTabContent.tsx';
import { MissionControlDemoModeToggle } from './MissionControlDemoModeToggle.tsx';
import { MissionControlTabPanel } from './MissionControlTabPanel.tsx';

type MissionControlTabItem = TabItem & {
  readonly id: string;
  readonly label: AppNames;
  readonly to: string;
};

const tabs = [
  {
    id: 'dashboard',
    label: AppNames.Dashboard,
    leadingElement: null,
    to: 'dashboard',
    trailingElement: null,
    ...getDataUiObjectNameAttribute('dashboard'),
  },
  {
    id: 'content-status',
    label: AppNames.ContentStatus,
    leadingElement: null,
    to: 'content-status',
    trailingElement: null,
    ...getDataUiObjectNameAttribute('content-status'),
  },
  {
    id: 'content-pace',
    label: AppNames.ContentPace,
    leadingElement: null,
    to: 'content-pace',
    trailingElement: null,
    ...getDataUiObjectNameAttribute('content-pace'),
  },
] as const satisfies readonly MissionControlTabItem[];

export const MissionControlPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const selectedSectionPath = useGetLastRouteSegment();
  const dispatch = useDispatch();

  const tabMapByPath = tabs.reduce<Dictionary<MissionControlTabItem>>(
    (resultingMap, itemToAdd) => ({
      ...resultingMap,
      [itemToAdd.to]: itemToAdd,
    }),
    {},
  );

  const selectedTab = tabMapByPath[selectedSectionPath];
  const defaultUrl = `${match.url}/${tabs[0].to}`;

  useEffect(() => {
    if (!selectedTab) {
      history.push(defaultUrl);
    }
  }, [defaultUrl, history, selectedTab]);

  useThunkPromise(initMissionControl);

  useEffect(() => {
    dispatch(trackUserEvent(TrackedEvent.MissionControlOpened));
  }, []);

  if (!selectedTab) {
    return null;
  }

  return (
    <MissionControlDemoContextProvider>
      <Box display="flex" flexDirection="column" height="100%" paddingTop={spacingMainLayoutTop}>
        <Box
          paddingLeft={spacingMainLayoutLeft}
          paddingRight={spacingMainLayoutRight}
          paddingBottom={Spacing.XXL}
        >
          <PageTitle>{AppNames.MissionControl}</PageTitle>
        </Box>

        <Box minHeight={0}>
          <TabView selectedKey={selectedTab.id} items={tabs}>
            <Box paddingLeft={spacingMainLayoutLeft} paddingRight={spacingMainLayoutRight}>
              <TabView.TabGroup
                {...getDataUiCollectionAttribute(DataUiCollection.MissionControlNavigationMenu)}
                renderAuxElement={() => <MissionControlDemoModeToggle />}
              />
            </Box>
            <MissionControlTabPanel>
              {() => {
                switch (selectedTab.id) {
                  case 'content-status':
                    return <ContentStatusTabContent />;
                  case 'dashboard':
                    return <DashboardTabContent />;
                  case 'content-pace':
                    return <ContentPace />;
                  default:
                    return (
                      <>
                        <HtmlPageTitle appName={selectedTab.label} />
                        <PageTitle>!!! {selectedTab.label} !!!</PageTitle>
                      </>
                    );
                }
              }}
            </MissionControlTabPanel>
          </TabView>
        </Box>
      </Box>
    </MissionControlDemoContextProvider>
  );
};
