export const Subscription_SubscriptionUsers_Loaded = 'Subscription_SubscriptionUsers_Loaded';
export const Subscription_SubscriptionUsers_Changed = 'Subscription_SubscriptionUsers_Changed';
export const Subscription_SubscriptionUsageLoaded = 'Subscription_SubscriptionUsageLoaded';
// Subscription edit actions
export const Subscription_SelectSubscription = 'Subscription_SelectSubscription';
export const Subscription_SelectSubscription_Clear = 'Subscription_SelectSubscription_Clear';

export const SubscriptionManagement_RouteEntered = 'SubscriptionManagement_RouteEntered';
export const SubscriptionManagement_RouteLeft = 'SubscriptionManagement_RouteLeft';

export const SubscriptionApiKey_Creation_Failed = 'SubscriptionApiKey_Creation_Failed';
export const SubscriptionApiKey_Creation_Finished = 'SubscriptionApiKey_Creation_Finished';
export const SubscriptionApiKey_Creation_Started = 'SubscriptionApiKey_Creation_Started';
export const SubscriptionApiKey_GenerationStatus_Reset =
  'SubscriptionApiKey_GenerationStatus_Reset';
export const SubscriptionApiKey_Loading_Finished = 'SubscriptionApiKey_Loading_Finished';
export const SubscriptionApiKey_Loading_Started = 'SubscriptionApiKey_Loading_Started';
export const SubscriptionApiKey_Regeneration_Failed = 'SubscriptionApiKey_Regeneration_Failed';
export const SubscriptionApiKey_Regeneration_Finished = 'SubscriptionApiKey_Regeneration_Finished';
export const SubscriptionApiKey_Regeneration_Started = 'SubscriptionApiKey_Regeneration_Started';
