import indefiniteArticles from 'articles';
import { isEmptyOrWhitespace, template } from '../stringUtils.ts';

const errorMessageWithFeatureTemplate = template`Provide ${1} ${0}.`;
const errorMessageWithoutFeatureTemplate = template`Provide ${0}.`;

const createErrorMessageWithFeature = (fieldName: string, featureName: string): string =>
  errorMessageWithFeatureTemplate(fieldName, indefiniteArticles.articlize(featureName));

const createErrorMessageWithoutFeature = (fieldName: string): string =>
  errorMessageWithoutFeatureTemplate(indefiniteArticles.articlize(fieldName));

/**
 * Returns undefined for non-empty string and error message otherwise
 * @param {string} fieldName name of the required field in singular noun lowercase form
 * @param {string} featureName optional name of the feature in adjective lowercase form
 * @example
 * // returns 'Provide webhook name'
 * nonEmptyValidationBuilder('name', 'webhook')
 * @example
 * // returns 'Provide name'
 * nonEmptyValidationBuilder('name')
 */
export const nonEmptyValidationBuilder = (
  fieldName: string,
  featureName: string | undefined = undefined,
): ((value: string) => string | undefined) => {
  const errorMessage = featureName
    ? createErrorMessageWithFeature(fieldName, featureName)
    : createErrorMessageWithoutFeature(fieldName);

  return (value: string) => (isEmptyOrWhitespace(value) ? errorMessage : undefined);
};
