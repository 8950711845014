type IComposeType = Compose2 & Compose3 & Compose4 & Compose5 & Compose6;

/**
 * Returns a function that calls given functions in a chain from the last to the first one.
 * Return value (if any) of a function is passed to the next one as an argument.
 * It works just like flowRight from loadash, see https://lodash.com/docs/4.17.15#flowRight.
 * @params functions to be chained together, supports up to 6 parameters
 * @returns function which calls all the given functions in a chain
 *
 * @example const sum = (a: number, b: number) => a + b;
 * const printResult = (result: number) => console.log(`Hey, look what we got: ${result}.`);
 * const sumAndPrint: (a: number, b: number) => void = compose(printResult, sum);
 *
 * @example const onClick = compose(dispatch, selectUser);
 * @example const onClick = compose(dispatch, saveUser, prepareUser);
 */
export const compose: IComposeType =
  (a: any, ...bs: any[]) =>
  (...i: any[]) =>
    bs.length > 0
      ? [...bs.slice(0, bs.length - 1).reverse(), a].reduce(
          (result, fnc) => {
            return fnc(result);
          },
          bs[bs.length - 1](...i),
        )
      : a(...i);

type IsNotUnknown<T> = unknown extends T ? IsFalse : IsTrue;

type MatchingReturnTypeFirst<T, TArgs extends any[]> = T extends {
  (...args: TArgs): infer R;
  (...args: any[]): any;
  (...args: any[]): any;
  (...args: any[]): any;
}
  ? R
  : T extends { (...args: TArgs): infer R; (...args: any[]): any; (...args: any[]): any }
    ? R
    : T extends { (...args: TArgs): infer R; (...args: any[]): any }
      ? R
      : T extends (...args: TArgs) => infer R
        ? R
        : never;

type MatchingReturnTypeSecond<T, TArgs extends any[]> = T extends {
  (...args: any[]): any;
  (...args: TArgs): infer R;
  (...args: any[]): any;
  (...args: any[]): any;
}
  ? R
  : T extends { (...args: any[]): any; (...args: TArgs): infer R; (...args: any[]): any }
    ? R
    : T extends { (...args: any[]): any; (...args: TArgs): infer R }
      ? R
      : never;

type MatchingReturnTypeThird<T, TArgs extends any[]> = T extends {
  (...args: any[]): any;
  (...args: any[]): any;
  (...args: TArgs): infer R;
  (...args: any[]): any;
}
  ? R
  : T extends { (...args: any[]): any; (...args: any[]): any; (...args: TArgs): infer R }
    ? R
    : never;

type MatchingReturnTypeFourth<T, TArgs extends any[]> = T extends {
  (...args: any[]): any;
  (...args: any[]): any;
  (...args: any[]): any;
  (...args: TArgs): infer R;
}
  ? R
  : never;

type MatchingOverloadedReturnType<T, TArgs extends any[]> = IsNotUnknown<
  MatchingReturnTypeFirst<T, TArgs>
> extends IsTrue
  ? MatchingReturnTypeFirst<T, TArgs>
  : IsNotUnknown<MatchingReturnTypeSecond<T, TArgs>> extends IsTrue
    ? MatchingReturnTypeSecond<T, TArgs>
    : IsNotUnknown<MatchingReturnTypeThird<T, TArgs>> extends IsTrue
      ? MatchingReturnTypeThird<T, TArgs>
      : IsNotUnknown<MatchingReturnTypeFourth<T, TArgs>> extends IsTrue
        ? MatchingReturnTypeFourth<T, TArgs>
        : never;

type IsTrue = 'This is a special type meaning the type operation is truthful';
type IsFalse = 'This is a special type meaning the type operation is falsy';
type ExtendsOneOf<T, TOne, TTwo> = T extends TOne ? IsTrue : T extends TTwo ? IsTrue : IsFalse;
type ReplaceParamsWithExpected<T, TExpected> = T extends (...args: any[]) => infer TReturn
  ? TExpected extends (...args: infer TArgs) => any
    ? (...args: TArgs) => TReturn
    : never
  : never;
type OR<T, TOne, TTwo> = ExtendsOneOf<T, TOne, TTwo> extends IsTrue
  ? T
  : ReplaceParamsWithExpected<T, TOne>;

type TestType<T, TExpected extends (...a: any[]) => any> = OR<
  T,
  TExpected,
  (i: unknown) => ReturnType<TExpected>
>;

type Compose2 = <TFirst extends (...args: any[]) => any, TSecond extends (...args: any[]) => any>(
  a: TestType<
    TSecond,
    (
      arg: MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>,
    ) => MatchingOverloadedReturnType<
      TSecond,
      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
    >
  >,
  b: TestType<
    TFirst,
    (...args: Parameters<TFirst>) => MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>
  >,
) => (
  ...i: Parameters<TFirst>
) => MatchingOverloadedReturnType<
  TSecond,
  [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
>;
type Compose3 = <
  TFirst extends (...args: any[]) => any,
  TSecond extends (...args: any[]) => any,
  TThird extends (...args: any[]) => any,
>(
  a: TestType<
    TThird,
    (
      arg: MatchingOverloadedReturnType<
        TSecond,
        [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
      >,
    ) => MatchingOverloadedReturnType<
      TThird,
      [
        MatchingOverloadedReturnType<
          TSecond,
          [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
        >,
      ]
    >
  >,
  b: TestType<
    TSecond,
    (
      arg: MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>,
    ) => MatchingOverloadedReturnType<
      TSecond,
      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
    >
  >,
  c: TestType<
    TFirst,
    (...args: Parameters<TFirst>) => MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>
  >,
) => (
  ...i: Parameters<TFirst>
) => MatchingOverloadedReturnType<
  TThird,
  [
    MatchingOverloadedReturnType<
      TSecond,
      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
    >,
  ]
>;
type Compose4 = <
  TFirst extends (...args: any[]) => any,
  TSecond extends (...args: any[]) => any,
  TThird extends (...args: any[]) => any,
  TFourth extends (...args: any[]) => any,
>(
  a: TestType<
    TFourth,
    (
      arg: MatchingOverloadedReturnType<
        TThird,
        [
          MatchingOverloadedReturnType<
            TSecond,
            [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
          >,
        ]
      >,
    ) => MatchingOverloadedReturnType<
      TFourth,
      [
        MatchingOverloadedReturnType<
          TThird,
          [
            MatchingOverloadedReturnType<
              TSecond,
              [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
            >,
          ]
        >,
      ]
    >
  >,
  b: TestType<
    TThird,
    (
      arg: MatchingOverloadedReturnType<
        TSecond,
        [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
      >,
    ) => MatchingOverloadedReturnType<
      TThird,
      [
        MatchingOverloadedReturnType<
          TSecond,
          [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
        >,
      ]
    >
  >,
  c: TestType<
    TSecond,
    (
      arg: MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>,
    ) => MatchingOverloadedReturnType<
      TSecond,
      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
    >
  >,
  d: TestType<
    TFirst,
    (...args: Parameters<TFirst>) => MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>
  >,
) => (
  ...i: Parameters<TFirst>
) => MatchingOverloadedReturnType<
  TFourth,
  [
    MatchingOverloadedReturnType<
      TThird,
      [
        MatchingOverloadedReturnType<
          TSecond,
          [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
        >,
      ]
    >,
  ]
>;
type Compose5 = <
  TFirst extends (...args: any[]) => any,
  TSecond extends (...args: any[]) => any,
  TThird extends (...args: any[]) => any,
  TFourth extends (...args: any[]) => any,
  TFifth extends (...args: any[]) => any,
>(
  a: TestType<
    TFifth,
    (
      arg: MatchingOverloadedReturnType<
        TFourth,
        [
          MatchingOverloadedReturnType<
            TThird,
            [
              MatchingOverloadedReturnType<
                TSecond,
                [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
              >,
            ]
          >,
        ]
      >,
    ) => MatchingOverloadedReturnType<
      TFifth,
      [
        MatchingOverloadedReturnType<
          TFourth,
          [
            MatchingOverloadedReturnType<
              TThird,
              [
                MatchingOverloadedReturnType<
                  TSecond,
                  [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
                >,
              ]
            >,
          ]
        >,
      ]
    >
  >,
  b: TestType<
    TFourth,
    (
      arg: MatchingOverloadedReturnType<
        TThird,
        [
          MatchingOverloadedReturnType<
            TSecond,
            [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
          >,
        ]
      >,
    ) => MatchingOverloadedReturnType<
      TFourth,
      [
        MatchingOverloadedReturnType<
          TThird,
          [
            MatchingOverloadedReturnType<
              TSecond,
              [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
            >,
          ]
        >,
      ]
    >
  >,
  c: TestType<
    TThird,
    (
      arg: MatchingOverloadedReturnType<
        TSecond,
        [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
      >,
    ) => MatchingOverloadedReturnType<
      TThird,
      [
        MatchingOverloadedReturnType<
          TSecond,
          [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
        >,
      ]
    >
  >,
  d: TestType<
    TSecond,
    (
      arg: MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>,
    ) => MatchingOverloadedReturnType<
      TSecond,
      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
    >
  >,
  e: TestType<
    TFirst,
    (...args: Parameters<TFirst>) => MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>
  >,
) => (
  ...i: Parameters<TFirst>
) => MatchingOverloadedReturnType<
  TFifth,
  [
    MatchingOverloadedReturnType<
      TFourth,
      [
        MatchingOverloadedReturnType<
          TThird,
          [
            MatchingOverloadedReturnType<
              TSecond,
              [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
            >,
          ]
        >,
      ]
    >,
  ]
>;
type Compose6 = <
  TFirst extends (...args: any[]) => any,
  TSecond extends (...args: any[]) => any,
  TThird extends (...args: any[]) => any,
  TFourth extends (...args: any[]) => any,
  TFifth extends (...args: any[]) => any,
  TSixth extends (...args: any[]) => any,
>(
  a: TestType<
    TSixth,
    (
      arg: MatchingOverloadedReturnType<
        TFifth,
        [
          MatchingOverloadedReturnType<
            TFourth,
            [
              MatchingOverloadedReturnType<
                TThird,
                [
                  MatchingOverloadedReturnType<
                    TSecond,
                    [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
                  >,
                ]
              >,
            ]
          >,
        ]
      >,
    ) => MatchingOverloadedReturnType<
      TSixth,
      [
        MatchingOverloadedReturnType<
          TFifth,
          [
            MatchingOverloadedReturnType<
              TFourth,
              [
                MatchingOverloadedReturnType<
                  TThird,
                  [
                    MatchingOverloadedReturnType<
                      TSecond,
                      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
                    >,
                  ]
                >,
              ]
            >,
          ]
        >,
      ]
    >
  >,
  b: TestType<
    TFifth,
    (
      arg: MatchingOverloadedReturnType<
        TFourth,
        [
          MatchingOverloadedReturnType<
            TThird,
            [
              MatchingOverloadedReturnType<
                TSecond,
                [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
              >,
            ]
          >,
        ]
      >,
    ) => MatchingOverloadedReturnType<
      TFifth,
      [
        MatchingOverloadedReturnType<
          TFourth,
          [
            MatchingOverloadedReturnType<
              TThird,
              [
                MatchingOverloadedReturnType<
                  TSecond,
                  [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
                >,
              ]
            >,
          ]
        >,
      ]
    >
  >,
  c: TestType<
    TFourth,
    (
      arg: MatchingOverloadedReturnType<
        TThird,
        [
          MatchingOverloadedReturnType<
            TSecond,
            [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
          >,
        ]
      >,
    ) => MatchingOverloadedReturnType<
      TFourth,
      [
        MatchingOverloadedReturnType<
          TThird,
          [
            MatchingOverloadedReturnType<
              TSecond,
              [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
            >,
          ]
        >,
      ]
    >
  >,
  d: TestType<
    TThird,
    (
      arg: MatchingOverloadedReturnType<
        TSecond,
        [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
      >,
    ) => MatchingOverloadedReturnType<
      TThird,
      [
        MatchingOverloadedReturnType<
          TSecond,
          [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
        >,
      ]
    >
  >,
  e: TestType<
    TSecond,
    (
      arg: MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>,
    ) => MatchingOverloadedReturnType<
      TSecond,
      [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
    >
  >,
  f: TestType<
    TFirst,
    (...args: Parameters<TFirst>) => MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>
  >,
) => (
  ...i: Parameters<TFirst>
) => MatchingOverloadedReturnType<
  TSixth,
  [
    MatchingOverloadedReturnType<
      TFifth,
      [
        MatchingOverloadedReturnType<
          TFourth,
          [
            MatchingOverloadedReturnType<
              TThird,
              [
                MatchingOverloadedReturnType<
                  TSecond,
                  [MatchingOverloadedReturnType<TFirst, Parameters<TFirst>>]
                >,
              ]
            >,
          ]
        >,
      ]
    >,
  ]
>;
