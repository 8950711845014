import { ICustomTypeElement } from '../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import {
  EmptyCustomItemElement,
  ICustomItemElement,
} from '../../models/contentItemElements/CustomItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createCustomItemElement: ItemElementCreator<ICustomTypeElement, ICustomItemElement> = (
  customTypeElement,
): ICustomItemElement => ({
  ...EmptyCustomItemElement,
  elementId: customTypeElement.elementId,
});
