import Immutable from 'immutable';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  IRule,
  IRuleWithScope,
  ITypeRuleSetting,
} from '../../../../data/models/roles/IRoleSettings.ts';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import {
  IContentGroupRuleSettingsServerModel,
  IRoleUpdateServerModel,
  IRuleServerModel,
  IRuleWithScopeServerModel,
  ITypeRuleSettingServerModel,
} from '../../../../repositories/serverModels/IRoleServerModel.type.ts';
import { IContentGroup } from '../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { UntitledRoleSurrogateName } from '../constants/UIConstants.ts';

export const computedCapabilities: Immutable.Set<Capability> = Immutable.Set([
  Capability.ViewContent,
  Capability.UpdateContent,
  Capability.DeleteContent,
  Capability.CreateContent,
  Capability.ViewAssets,
  Capability.UpdateAssets,
  Capability.DeleteAssets,
  Capability.CreateAssets,
  Capability.ManageAssets,
]);

const computeContentProductionCapabilitiesFromRules = (
  editedRole: IRoleWithSettings,
): Immutable.Set<Capability> =>
  editedRole.settings.canRules.reduce(
    (agg: Immutable.Set<Capability>, canRule: IRuleWithScope) =>
      agg.union(Immutable.List(canRule.capabilities)),
    Immutable.Set<Capability>(),
  );

const decorateWithManageAssets = (
  staticCapabilities: Immutable.Set<Capability>,
): Immutable.Set<Capability> =>
  staticCapabilities.includes(Capability.UpdateContent)
    ? staticCapabilities.add(Capability.ManageAssets)
    : staticCapabilities;

const computeRoleCapabilities = (editedRole: IRoleWithSettings): Immutable.Set<Capability> => {
  const roleCapabilities = editedRole.capabilities
    .subtract(computedCapabilities)
    .union(computeContentProductionCapabilitiesFromRules(editedRole));

  return decorateWithManageAssets(roleCapabilities);
};

const getSelectedGroupsForServer = (
  typeSetting: ITypeRuleSetting,
  allTypes: Immutable.Map<Uuid, IContentType>,
): ReadonlyArray<IContentGroupRuleSettingsServerModel> => {
  const type = allTypes.get(typeSetting.typeId);

  if (type && !typeSetting.contentGroupIds.isEmpty()) {
    const allTypeGroupIds = type.contentGroups.map((g: IContentGroup) => g.id).toSet();
    const settingContainsAllGroups = allTypeGroupIds
      .subtract(typeSetting.contentGroupIds)
      .isEmpty();
    const settingDoesNotContainDeletedGroup = typeSetting.contentGroupIds
      .subtract(allTypeGroupIds)
      .isEmpty();
    if (settingContainsAllGroups && settingDoesNotContainDeletedGroup) {
      return [];
    }
  }

  return typeSetting.contentGroupIds.map((contentGroupId: Uuid) => ({ contentGroupId })).toArray();
};

export function getUpdateServerModelFromEditedRole(
  role: IRoleWithSettings,
  allTypes: Immutable.Map<Uuid, IContentType>,
): IRoleUpdateServerModel {
  return {
    name: isEmptyOrWhitespace(role.name) ? UntitledRoleSurrogateName : role.name,
    capabilities: computeRoleCapabilities(role).toArray(),
    settings: {
      canRules: role.settings.canRules.map(
        (rule: IRuleWithScope): IRuleWithScopeServerModel => ({
          scope: rule.scope,
          capabilities: rule.capabilities,
          types: rule.types.map(
            (typeRuleSetting: ITypeRuleSetting): ITypeRuleSettingServerModel => ({
              typeId: typeRuleSetting.typeId,
              contentGroups: getSelectedGroupsForServer(typeRuleSetting, allTypes),
            }),
          ),
        }),
      ),
      cannotRules: role.settings.cannotRules.map(
        (rule: IRule): IRuleServerModel => ({
          capabilities: rule.capabilities,
          types: rule.types.map(
            (typeRuleSetting: ITypeRuleSetting): ITypeRuleSettingServerModel => ({
              typeId: typeRuleSetting.typeId,
              contentGroups: getSelectedGroupsForServer(typeRuleSetting, allTypes),
            }),
          ),
        }),
      ),
    },
  };
}
