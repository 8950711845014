import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { initializeUserDetail } from '../../actions/thunkUsersActions.ts';
import { closeUserDetail } from '../../actions/usersActions.ts';

type Props = {
  readonly userId: string;
  readonly userListingLink: string;
  readonly children: (user: IProjectContributor, onNavigateBack: () => void) => React.ReactElement;
};

export const UserDetailDataEnsurer = ({ userListingLink, userId, children }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.usersApp.projectContributors.get(userId));
  const isInitialized = useSelector((state) => state.usersApp.userDetail.isInitialized);
  const onNavigateBack = useCallback(
    () => history.push(userListingLink),
    [history, userListingLink],
  );

  const onUserNotFound = useCallback(() => {
    history.push(userListingLink);
  }, [history, userListingLink]);

  useEffect(() => {
    dispatch(initializeUserDetail(userId, onUserNotFound));

    return () => {
      dispatch(closeUserDetail());
    };
  }, [userId, onUserNotFound]);

  if (!isInitialized || !user) {
    return null;
  }

  return children(user, onNavigateBack);
};
