export const UntitledContentTypeName = 'Untitled content type';

export const editedContentGroupCopy = Object.freeze({
  cancelTooltip: 'Cancel renaming new content group',
  cancelText: 'Cancel',
  confirmTooltip: 'Confirm content group rename',
  confirmText: 'Rename content group',
  emptyNameConfirmTooltip: 'Provide a content group name',
  equalNamesConfirmTooltip: 'Provide a different content group name',
  header: 'Rename content group',
  placeholder: 'Write updated content group name',
});

export const newContentGroupCopy = Object.freeze({
  cancelTooltip: 'Cancel content group creation',
  cancelText: 'Cancel',
  confirmTooltip: 'Confirm content group creation',
  confirmText: 'Create content group',
  emptyNameConfirmTooltip: 'Provide a content group name',
  equalNamesConfirmTooltip: 'Provide a content group name',
  header: 'Create new content group',
  placeholder: 'Write new content group name',
});
