export const OpenWorkflowStepModalAction = {
  Publish: 'Publish',
  UnpublishAndArchive: 'UnpublishAndArchive',
  RestoreFromArchivedStep: 'RestoreFromArchivedStep',
  UpdateStep: 'UpdateStep',
} as const;
export type OpenWorkflowStepModalAction =
  (typeof OpenWorkflowStepModalAction)[keyof typeof OpenWorkflowStepModalAction];

export const ChangeWorkflowStepModalAction = {
  ...OpenWorkflowStepModalAction,
  ScheduleToPublish: 'ScheduleToPublish',
  ScheduleToUnpublishAndArchive: 'ScheduleToUnpublishAndArchive',
  MoveToArchivedStep: 'MoveToArchivedStep',
} as const;
export type ChangeWorkflowStepModalAction =
  (typeof ChangeWorkflowStepModalAction)[keyof typeof ChangeWorkflowStepModalAction];
