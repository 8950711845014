import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../../../_shared/components/AppBar/BackNavigation.tsx';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getBreadcrumbsOrigin } from '../../../../../../_shared/selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getEditedContentItemStatus } from '../../../../../../_shared/selectors/getEditedContentItemStatus.ts';
import { getGoBackLinkByPath } from '../../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';

export const ContentItemBackNavigation: React.FC = () => {
  const { pathname } = useLocation();
  const lastBreadcrumbsOriginItem = useSelector(
    compose((breadcrumbsOrigin) => breadcrumbsOrigin.items.last(), getBreadcrumbsOrigin),
  );
  const isSaving = useSelector(
    compose(
      (editedContentItemStatus) => !Collection.isEmpty(editedContentItemStatus.saving),
      getEditedContentItemStatus,
    ),
  );

  const goBackLink = getGoBackLinkByPath(pathname, lastBreadcrumbsOriginItem?.path);

  return <BackNavigation to={goBackLink} isDisabled={isSaving} />;
};
