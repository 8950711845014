import React, { RefObject } from 'react';
import { AiSignalRFailMessage } from '../../../../../../../_shared/constants/uiConstants.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { TaxonomyTermAssignedSourceType } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { isAiItemCategorizationEnabled } from '../../../../../../../_shared/selectors/aiSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { getTaxonomyTermByIdOrThrow } from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import {
  isRichTextElement,
  isTextElement,
} from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import {
  TaxonomyAiAssistantWithFeedback,
  TaxonomyAiAssistantWithFeedbackProps,
} from '../../../components/elements/taxonomy/TaxonomyAiAssistantWithFeedback.tsx';
import { TaxonomyTermTag } from '../../../models/TaxonomyTermTag.type.ts';
import {
  EmptyElementReference,
  useItemElementReference,
} from '../../hooks/useItemElementReference.ts';

type Props = Pick<TaxonomyAiAssistantWithFeedbackProps, 'renderSelector'> & {
  readonly currentlySelectedTerms: ReadonlySet<Uuid>;
  readonly disabled: boolean;
  readonly onTermSelectionChanged?: (
    termIds: ReadonlySet<Uuid>,
    source: TaxonomyTermAssignedSourceType,
  ) => void;
  readonly taxonomyElementRef: RefObject<HTMLDivElement>;
  readonly typeElement: ITaxonomyTypeElement;
};

type TaxonomyTermTagWithChildren = TaxonomyTermTag & {
  readonly childTerms: TaxonomyTermTagWithChildren[];
};

const emptyPath: ReadonlyArray<string> = [];

const toTaxonomyTerm = (
  taxonomyGroup: ITaxonomyGroup,
  termId: Uuid,
  parentPath: ReadonlyArray<string>,
): TaxonomyTermTagWithChildren => {
  const term = getTaxonomyTermByIdOrThrow(taxonomyGroup, termId);
  const path = [...parentPath, term.name];
  return {
    id: term.id,
    label: term.name,
    path,
    childTerms: term.childIds.map((id) => toTaxonomyTerm(taxonomyGroup, id, path)),
  };
};

const flattenTaxonomyTerms = (terms: TaxonomyTermTagWithChildren[]): TaxonomyTermTag[] => {
  return terms.reduce((accumulatedResult: TaxonomyTermTag[], term: TaxonomyTermTagWithChildren) => {
    const flatChildTerms = flattenTaxonomyTerms(term.childTerms);
    return [...accumulatedResult, term, ...flatChildTerms];
  }, []);
};

const getTaxonomyTerms = (taxonomyGroup?: ITaxonomyGroup): TaxonomyTermTag[] => {
  const taxonomyTermsWithChildren =
    taxonomyGroup?.childIds.map((id) => toTaxonomyTerm(taxonomyGroup, id, emptyPath)) ?? [];
  return flattenTaxonomyTerms(taxonomyTermsWithChildren);
};

const areSomeTextElementsPresent = (state: IStore): boolean =>
  state.contentApp.editedContentItemVariantElements?.some(
    (element) => isRichTextElement(element) || isTextElement(element),
  ) ?? false;

export const TaxonomyAiAssistant: React.FC<Props> = ({
  onTermSelectionChanged,
  typeElement,
  ...otherProps
}) => {
  const areAiTaxonomiesEnabled = useSelector(isAiItemCategorizationEnabled);

  const itemElementReference = useItemElementReference(typeElement) ?? EmptyElementReference;

  const areTextElementsPresent = useSelector(areSomeTextElementsPresent);
  const editedVariantId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);

  const taxonomyGroupId = typeElement.taxonomyGroupId;
  const taxonomyGroup = useSelector((s) => s.data.taxonomyGroups.byId.get(taxonomyGroupId ?? ''));
  const taxonomyGroupName = taxonomyGroup?.name;
  const taxonomyTerms = getTaxonomyTerms(taxonomyGroup);
  const isTaxonomyEmpty = !taxonomyGroup || taxonomyTerms.length === 0;

  const disabledTooltipText = useSelector((state) =>
    getDisabledTooltip(state, areTextElementsPresent, isTaxonomyEmpty, taxonomyGroupName),
  );

  if (!editedVariantId || !taxonomyGroupId || !taxonomyGroup || !onTermSelectionChanged) {
    return null;
  }

  return (
    <TaxonomyAiAssistantWithFeedback
      areAiTaxonomiesEnabled={areAiTaxonomiesEnabled}
      disabledTooltipText={disabledTooltipText}
      editedVariantId={editedVariantId}
      onTermSelectionChanged={onTermSelectionChanged}
      taxonomyElementInfo={itemElementReference}
      taxonomyGroup={taxonomyGroup}
      taxonomyTerms={taxonomyTerms}
      {...otherProps}
    />
  );
};

TaxonomyAiAssistant.displayName = 'TaxonomyAiAssistant';

const getDisabledTooltip = (
  state: IStore,
  areTextElementsPresent: boolean,
  isTaxonomyEmpty: boolean,
  taxonomyGroupName: string | undefined,
): string | null => {
  const signalRFail = state.sharedApp.signalRConnection.lastConnectionFailReason;

  if (signalRFail) {
    return AiSignalRFailMessage;
  }

  if (isTaxonomyEmpty) {
    return `The AI can’t suggest any terms because the ${taxonomyGroupName} taxonomy group is empty.`;
  }

  if (!areTextElementsPresent) {
    return 'The AI didn’t find any text to analyze and make meaningful suggestions.';
  }

  return null;
};
