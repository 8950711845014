import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IContentTypeSnippet } from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { TypeListOperationResultServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted,
} from '../../constants/snippetActionTypes.ts';

interface IDeps {
  readonly contentTypeSnippetRepository: {
    readonly archiveContentTypeSnippets: (
      typeIds: UuidArray,
    ) => Promise<TypeListOperationResultServerModel>;
  };
  readonly loadSnippetsData: () => ThunkPromise;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const archiveContentTypeSnippetsStarted = (affectedContentTypeSnippetIds: Immutable.Set<Uuid>) =>
  ({
    type: ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted,
    payload: { affectedContentTypeSnippetIds },
  }) as const;

const archiveContentTypeSnippetsFinished = (
  affectedContentTypeSnippets: Immutable.Set<IContentTypeSnippet>,
  successfulIds: Immutable.Set<Uuid>,
) =>
  ({
    type: ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished,
    payload: {
      affectedContentTypeSnippets,
      successfulIds,
    },
  }) as const;

export type ArchiveContentTypeSnippetsActionsType = ReturnType<
  typeof archiveContentTypeSnippetsStarted | typeof archiveContentTypeSnippetsFinished
>;

export const createArchiveContentTypeSnippetsAction =
  (deps: IDeps) =>
  (contentTypeSnippetIds: Immutable.Set<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const affectedSnippets = getState()
      .data.snippets.byId.filter((snippet: IContentTypeSnippet) =>
        contentTypeSnippetIds.contains(snippet.id),
      )
      .toSet();
    dispatch(archiveContentTypeSnippetsStarted(contentTypeSnippetIds));

    const operationResult = await deps.contentTypeSnippetRepository.archiveContentTypeSnippets(
      contentTypeSnippetIds.toArray(),
    );
    dispatch(
      archiveContentTypeSnippetsFinished(
        affectedSnippets,
        Immutable.Set<Uuid>(operationResult.modifiedTypeIds),
      ),
    );

    dispatch(
      deps.trackUserEvent(TrackedEvent.ContentTypeSnippetDeleted, {
        'content-type-snippet-count': operationResult.modifiedTypeIds.length,
      }),
    );
    await dispatch(deps.loadSnippetsData());
  };
