import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_WorkflowsUsage_Success } from '../../../constants/dataActionTypes.ts';

const initialState: ReadonlyMap<Uuid, number> = new Map();

export function usage(
  state: ReadonlyMap<Uuid, number> = initialState,
  action: Action,
): ReadonlyMap<Uuid, number> {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_WorkflowsUsage_Success: {
      return Collection.addMany(state, Object.entries(action.payload.data));
    }

    default:
      return state;
  }
}
