import { usePrevious } from '@kontent-ai/hooks';
import { createGuid } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { modalOpened } from '../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { isDefined } from '../../../_shared/utils/filter/isDefined.ts';
import { compose } from '../../../_shared/utils/func/compose.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { validatePreviewConfiguration } from '../actions/thunkPreviewConfigurationActions.ts';
import { PreviewUrlsSection as PreviewUrlsSectionComponent } from '../components/PreviewUrlsSection.tsx';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import {
  ContentTypeId,
  IPreviewUrlPatternError,
  SpaceRowId,
} from '../stores/IPreviewConfigurationAppStoreState.type.ts';
import { anySpacesOptionId } from '../utils/getSpacesOptions.ts';

type Props = {
  readonly contentTypes: Immutable.List<IContentType>;
  readonly errors: ReadonlyMap<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>>;
  readonly onChange: (
    previewUrlPatterns: ReadonlyMap<Uuid, ReadonlyArray<IPreviewUrlPattern>>,
  ) => void;
  readonly previewUrlPatterns: ReadonlyMap<Uuid, ReadonlyArray<IPreviewUrlPattern>>;
  readonly spaces: SpacesMap;
};

export const PreviewUrlsSection: React.FC<Props> = ({
  contentTypes,
  errors,
  onChange,
  previewUrlPatterns,
  spaces,
}) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [editedData, setEditedData] = useState<EditedData | null>(null);
  const configuredPatterns = contentTypes
    .toArray()
    .map((contentType) => {
      const patterns = previewUrlPatterns?.get(contentType.id);
      return patterns
        ? {
            contentTypeId: contentType.id,
            contentTypeName: contentType.name,
            patterns,
          }
        : undefined;
    })
    .filter(isDefined)
    .filter(({ contentTypeName }) =>
      contentTypeName.toLowerCase().includes(searchPhrase.toLowerCase()),
    );

  const previousPreviewUrlPatterns = usePrevious(previewUrlPatterns);
  useEffect(() => {
    // content type was added, open bar item for editing
    if (previewUrlPatterns.size === previousPreviewUrlPatterns.size + 1) {
      const previousContentTypeIds = new Set(Array.from(previousPreviewUrlPatterns.keys()));
      const addedContentTypeId =
        Array.from(previewUrlPatterns.keys()).find(
          (contentTypeId) => !previousContentTypeIds.has(contentTypeId),
        ) ?? '';

      setEditedData({
        contentTypeId: addedContentTypeId,
        initialPreviewUrlPatterns: null,
      });
    }
  }, [previewUrlPatterns, previousPreviewUrlPatterns]);

  const dispatch = useDispatch();
  const cancelEditing = () => setEditedData(null);
  const configureContentType = () =>
    dispatch(modalOpened(ModalDialogType.ConfigureContentTypePreviewUrls));
  const validateForm = () => dispatch(validatePreviewConfiguration());

  const deleteContentType = () => {
    if (editedData) {
      const newPatterns = new Map(previewUrlPatterns);

      newPatterns.delete(editedData.contentTypeId);
      onChange(newPatterns);
    }
  };

  const resetContentType = () => {
    if (editedData) {
      const newPatterns = new Map(previewUrlPatterns);

      if (editedData.initialPreviewUrlPatterns) {
        newPatterns.set(editedData.contentTypeId, editedData.initialPreviewUrlPatterns);
      } else {
        newPatterns.delete(editedData.contentTypeId);
      }

      onChange(newPatterns);
    }
  };

  const addNewContentTypeUrlPattern = () => {
    if (!editedData) {
      return;
    }

    const newPatterns = new Map(previewUrlPatterns);
    const urlPatterns = newPatterns.get(editedData.contentTypeId);

    if (!urlPatterns) {
      return;
    }

    const hasAnyOption = urlPatterns.some((previewUrlPattern) =>
      previewUrlPattern.spaces.has(anySpacesOptionId),
    );
    const newPatternSpaces = hasAnyOption ? new Set<Uuid>() : new Set([anySpacesOptionId]);

    newPatterns.set(
      editedData.contentTypeId,
      urlPatterns.concat([
        { rowId: createGuid(), urlPattern: '', spaces: newPatternSpaces, enabled: true },
      ]),
    );
    onChange(newPatterns);
  };

  const deleteContentTypeUrlPattern = (spaceRowId: Uuid) => {
    if (!editedData) {
      return;
    }

    const newPatterns = new Map(previewUrlPatterns);
    const urlPatterns = newPatterns.get(editedData.contentTypeId);

    if (!urlPatterns) {
      return;
    }

    newPatterns.set(
      editedData.contentTypeId,
      urlPatterns.filter((previewUrlPattern) => previewUrlPattern.rowId !== spaceRowId),
    );
    onChange(newPatterns);
  };

  const changePreviewUrlPatterns = (
    updatedPreviewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>,
  ) => {
    if (editedData) {
      const newPatterns = new Map(previewUrlPatterns);

      newPatterns.set(editedData.contentTypeId, updatedPreviewUrlPatterns);
      onChange(newPatterns);
    }
  };

  const disabledConfigureTooltipMessage = getDisabledConfigureTooltipMessage(!!editedData);

  return (
    <PreviewUrlsSectionComponent
      configuredPatterns={configuredPatterns}
      disabledConfigureTooltipMessage={disabledConfigureTooltipMessage}
      editedContentTypeId={editedData?.contentTypeId}
      errors={errors}
      isEditingEnabled={!editedData}
      onAddNewPattern={addNewContentTypeUrlPattern}
      onCancel={compose(cancelEditing, resetContentType)}
      onConfigureContentType={configureContentType}
      onConfirm={compose(cancelEditing, validateForm)}
      onDelete={compose(cancelEditing, deleteContentType)}
      onDeletePattern={deleteContentTypeUrlPattern}
      onEdit={(contentTypeId, initialPatterns) =>
        setEditedData({
          contentTypeId,
          initialPreviewUrlPatterns: initialPatterns,
        })
      }
      onChange={changePreviewUrlPatterns}
      onSearchPhraseChange={setSearchPhrase}
      searchPhrase={searchPhrase}
      spaces={spaces}
    />
  );
};

PreviewUrlsSection.displayName = 'PreviewUrlsSectionContainer';

const getDisabledConfigureTooltipMessage = (
  isContentTypeBeingEdited: boolean,
): string | undefined => {
  if (isContentTypeBeingEdited) {
    return 'Finish configuration of your content type to configure a new one.';
  }

  return undefined;
};

type EditedData = {
  readonly contentTypeId: Uuid;
  readonly initialPreviewUrlPatterns: ReadonlyArray<IPreviewUrlPattern> | null;
};
