import { IAsset } from './Asset.ts';

type AssetWithoutImageData = Omit<IAsset, 'width' | 'height' | '_deliveryLink' | '_downloadLink'>;

export interface IImage extends AssetWithoutImageData {
  readonly width: number;
  readonly height: number;
  readonly _deliveryLink: string;
  readonly _downloadLink: string;
}

export const isImage = (asset: IAsset): asset is IImage =>
  !!asset.width && !!asset.height && !!asset._deliveryLink && !!asset._downloadLink;
