import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  ContentTriggerOperation,
  WebhookTriggerType,
} from '../../../repositories/serverModels/WebhookServerModel.ts';
import { ITrigger } from '../models/IWebhookFormShape.type.ts';

export const getTriggerOperationIds = (
  triggers: Immutable.Map<WebhookTriggerType, ReadonlyArray<ContentTriggerOperation>>,
  type: WebhookTriggerType,
  allTriggers: ReadonlyArray<ITrigger>,
): ReadonlySet<ITrigger['id']> => {
  const contentTriggerOperations = triggers.get(type);
  const allExistingTriggerOperations = allTriggers.map((trigger) => trigger.id);
  const validTriggerOperations = Collection.intersect(
    contentTriggerOperations ?? [],
    allExistingTriggerOperations,
  );

  return new Set(validTriggerOperations);
};
