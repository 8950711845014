import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  contentItemDuplicatingFailed,
  contentItemDuplicatingFinished,
  contentItemDuplicatingStarted,
} from '../contentItemEditingActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
}

export const createDuplicateContentItemAction =
  (deps: IDeps) =>
  (
    contentItemId: Uuid,
    destinationCollectionId: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise<IContentItemWithVariantServerModel> =>
  async (dispatch, getState) => {
    const {
      contentApp: { listingUi },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());

    dispatch(contentItemDuplicatingStarted());
    try {
      const duplicatedItem = await deps.contentItemRepository.duplicateItem(
        contentItemId,
        {
          destinationCollectionId,
        },
        abortSignal,
      );
      const duplicatedItemWithVariant = await deps.contentItemRepository.getItemWithVariant(
        duplicatedItem.item.id,
        selectedLanguageId,
        abortSignal,
      );

      dispatch(
        contentItemDuplicatingFinished({
          itemWithVariant: duplicatedItemWithVariant,
          filter: listingUi.filter,
          usedSearchMethod,
        }),
      );

      return duplicatedItemWithVariant;
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(contentItemDuplicatingFailed());
      }

      throw error;
    }
  };
