import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React, { useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { splitToGroups } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  getBlockFromBlockElement,
  isCellBoundary,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { isTableCell } from '../../../utils/blocks/blockTypeUtils.ts';
import { getCoordinatesFromDepth } from '../api/depthCoordinatesConversion.ts';

export type RowCells = Immutable.Iterable<number, ReadonlyArray<React.ReactElement<any>>>;

interface ITableProps {
  readonly onResize: () => void;
  readonly renderRow: (cells: RowCells, rowIndex: number) => JSX.Element;
}

export const Table: React.FC<React.PropsWithChildren<ITableProps>> = ({
  children,
  onResize,
  renderRow,
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  useResizeObserver({ ref: tableRef, onResize });

  const childrenArray = React.Children.toArray(children) as ReadonlyArray<React.ReactElement<any>>;
  const groupedChildren = Immutable.List.of<ReadonlyArray<React.ReactElement<any>>>(
    ...splitToGroups(childrenArray, isCellBoundary),
  );

  const rows = groupedChildren.groupBy((cellElements: ReadonlyArray<React.ReactElement<any>>) => {
    const cellBlocks = cellElements.map(getBlockFromBlockElement);
    const cellBlock = cellBlocks.find(isTableCell) || cellBlocks[0];
    assert(
      cellBlock,
      () =>
        `${__filename}: Cell block is not a content block. Value: ${JSON.stringify(cellBlock)}.`,
    );
    return getCoordinatesFromDepth(cellBlock.getDepth()).y;
  });

  return (
    <div ref={tableRef} className="rte__table-table">
      {rows.map(renderRow).toArray()}
    </div>
  );
};

Table.displayName = 'Table';
