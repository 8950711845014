import { GetState } from '../../../../../../../@types/Dispatcher.type.ts';
import { ConditionValidationData } from './getConditionValidationResult.ts';

export const getConditionValidationDataForTypeElement = (
  getState: GetState,
): ConditionValidationData => {
  const {
    contentModelsApp: { activeConditionsBeforeLastSave, typeEditor },
  } = getState();

  return {
    activeConditionsBeforeLastSave,
    typeElements: typeEditor.editedType.typeElements,
  };
};

export const getConditionValidationDataForSnippetElement = (
  getState: GetState,
): ConditionValidationData => {
  const {
    contentModelsApp: { activeConditionsBeforeLastSave, snippets },
  } = getState();

  return {
    activeConditionsBeforeLastSave,
    typeElements: snippets.editor.editedContentTypeSnippet.typeElements,
  };
};
