import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Data_ContentTypesUsage_Failed,
  Data_ContentTypesUsage_Started,
  Data_ContentTypesUsage_Success,
} from '../../../constants/dataActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Data_ContentTypesUsage_Started:
      return LoadingStatus.Loading;

    case Data_ContentTypesUsage_Success:
      return LoadingStatus.Loaded;

    case Data_ContentTypesUsage_Failed:
      return LoadingStatus.InitialEmpty;

    default:
      return state;
  }
};
