import { memoize } from '@kontent-ai/memoization';
import { notNullNorUndefined } from '@kontent-ai/utils';
import { CharacterMetadata, ContentBlock, DraftInlineStyle, EntityInstance } from 'draft-js';
import Immutable from 'immutable';
import { isInTable } from '../blocks/blockTypeUtils.ts';
import { EntityMap } from '../general/editorContentGetters.ts';

export interface IAggregatedMetadata {
  readonly styleAtAllTopLevelChars: DraftInlineStyle | null;
  readonly styleAtAnyTopLevelChars: DraftInlineStyle | null;
  readonly entityKeyAtAllTopLevelChars: string | null;
  readonly entityKeyAtAnyTopLevelChars: Immutable.OrderedSet<string> | null;
  readonly styleAtAllTableChars: DraftInlineStyle | null;
  readonly styleAtAnyTableChars: DraftInlineStyle | null;
  readonly entityKeyAtAllTableChars: string | null;
  readonly entityKeyAtAnyTableChars: Immutable.OrderedSet<string> | null;
}

const emptyMetadata = {
  styleAtAllTopLevelChars: null,
  styleAtAnyTopLevelChars: null,
  entityKeyAtAllTopLevelChars: null,
  entityKeyAtAnyTopLevelChars: null,
  styleAtAllTableChars: null,
  styleAtAnyTableChars: null,
  entityKeyAtAllTableChars: null,
  entityKeyAtAnyTableChars: null,
};

export const aggregateAllChars = (
  result: Immutable.OrderedSet<string> | null,
  next: Immutable.OrderedSet<string> | null,
): Immutable.OrderedSet<string> | null => {
  if (result && next) {
    return result.intersect(next);
  }
  return result ?? next;
};

export const aggregateAnyChars = (
  result: Immutable.OrderedSet<string> | null,
  next: Immutable.OrderedSet<string> | null,
): Immutable.OrderedSet<string> | null => {
  if (result && next) {
    return result.union(next);
  }
  return result ?? next;
};

const addCharacterToAggregation = memoize.weak(
  (
    result: IAggregatedMetadata,
    char: CharacterMetadata,
    isInTableBlock?: boolean,
  ): IAggregatedMetadata => {
    const charStyle = char.getStyle();
    const charEntityKey = char.getEntity();

    if (isInTableBlock) {
      return {
        ...result,
        styleAtAllTableChars: aggregateAllChars(result.styleAtAllTableChars, charStyle),
        styleAtAnyTableChars: aggregateAnyChars(result.styleAtAnyTableChars, charStyle),
        entityKeyAtAllTableChars:
          result.entityKeyAtAllTableChars === charEntityKey ? charEntityKey : null,
        entityKeyAtAnyTableChars:
          charEntityKey && result.entityKeyAtAnyTableChars
            ? result.entityKeyAtAnyTableChars.add(charEntityKey)
            : result.entityKeyAtAnyTableChars,
      };
    }

    return {
      ...result,
      styleAtAllTopLevelChars: aggregateAllChars(result.styleAtAllTopLevelChars, charStyle),
      styleAtAnyTopLevelChars: aggregateAnyChars(result.styleAtAnyTopLevelChars, charStyle),
      entityKeyAtAllTopLevelChars:
        result.entityKeyAtAllTopLevelChars === charEntityKey ? charEntityKey : null,
      entityKeyAtAnyTopLevelChars:
        charEntityKey && result.entityKeyAtAnyTopLevelChars
          ? result.entityKeyAtAnyTopLevelChars.add(charEntityKey)
          : result.entityKeyAtAnyTopLevelChars,
    };
  },
);

const getSingleCharacterMetadata = memoize.weak(
  (char: CharacterMetadata, isInTableBlock?: boolean): IAggregatedMetadata => {
    const style = char.getStyle();
    const entityKey = char.getEntity() || null;

    if (isInTableBlock) {
      return {
        ...emptyMetadata,
        styleAtAllTableChars: style,
        styleAtAnyTableChars: style,
        entityKeyAtAllTableChars: entityKey,
        entityKeyAtAnyTableChars: entityKey
          ? Immutable.OrderedSet.of(entityKey)
          : Immutable.OrderedSet(),
      };
    }

    return {
      ...emptyMetadata,
      styleAtAllTopLevelChars: style,
      styleAtAnyTopLevelChars: style,
      entityKeyAtAllTopLevelChars: entityKey,
      entityKeyAtAnyTopLevelChars: entityKey
        ? Immutable.OrderedSet.of(entityKey)
        : Immutable.OrderedSet(),
    };
  },
);

function getMetadataForChars(
  chars: ReadonlyArray<CharacterMetadata>,
  areInTableBlock?: boolean,
): IAggregatedMetadata | null {
  const firstChar = chars[0];
  if (!firstChar) {
    return null;
  }

  const firstCharMetadata = getSingleCharacterMetadata(firstChar, areInTableBlock);

  let lastProcessedChar = firstChar;
  const aggregated = chars.reduce((result: IAggregatedMetadata, char: CharacterMetadata) => {
    // Optimize for repeating metadata sets (which are quite common as formatted text appears in chunks of chars) to avoid unnecessary intersections
    // Character metadata is pooled, so we can optimize based that to avoid even getStyle / getEntity calls for the best performance
    if (char === lastProcessedChar) {
      return result;
    }
    lastProcessedChar = char;

    const newResult = addCharacterToAggregation(result, char, areInTableBlock);
    return newResult;
  }, firstCharMetadata);

  return aggregated;
}

export const getMetadataForBlockChars = memoize.weak(
  (block: ContentBlock, start: number, end: number): IAggregatedMetadata | null => {
    const startWithinBounds = Math.max(start, 0);
    const endWithinBounds = Math.min(end, block.getLength());

    if (startWithinBounds >= endWithinBounds) {
      return null;
    }

    const chars =
      startWithinBounds > 0 || endWithinBounds < block.getLength()
        ? block
            .getCharacterList()
            .skip(startWithinBounds)
            .take(endWithinBounds - startWithinBounds)
            .toArray()
        : block.getCharacterList().toArray();

    const aggregated = getMetadataForChars(chars, isInTable(block));
    return aggregated;
  },
);

function addMetadataToAggregation(
  result: IAggregatedMetadata,
  next: IAggregatedMetadata,
): IAggregatedMetadata {
  return {
    styleAtAllTopLevelChars: aggregateAllChars(
      result.styleAtAllTopLevelChars,
      next.styleAtAllTopLevelChars,
    ),
    styleAtAnyTopLevelChars: aggregateAnyChars(
      result.styleAtAnyTopLevelChars,
      next.styleAtAnyTopLevelChars,
    ),
    entityKeyAtAllTopLevelChars:
      result.entityKeyAtAllTopLevelChars === next.entityKeyAtAllTopLevelChars
        ? result.entityKeyAtAllTopLevelChars
        : null,
    entityKeyAtAnyTopLevelChars: aggregateAnyChars(
      result.entityKeyAtAnyTopLevelChars,
      next.entityKeyAtAnyTopLevelChars,
    ),
    styleAtAllTableChars: aggregateAllChars(result.styleAtAllTableChars, next.styleAtAllTableChars),
    styleAtAnyTableChars: aggregateAnyChars(result.styleAtAnyTableChars, next.styleAtAnyTableChars),
    entityKeyAtAllTableChars:
      result.entityKeyAtAllTableChars === next.entityKeyAtAllTableChars
        ? result.entityKeyAtAllTableChars
        : null,
    entityKeyAtAnyTableChars: aggregateAnyChars(
      result.entityKeyAtAnyTableChars,
      next.entityKeyAtAnyTableChars,
    ),
  };
}

export function aggregateMetadata(
  allMetadata: ReadonlyArray<IAggregatedMetadata | null>,
): IAggregatedMetadata | null {
  const aggregated = allMetadata.reduce(
    (result: IAggregatedMetadata | null, metadataItem: IAggregatedMetadata | null) => {
      if (!result) {
        return metadataItem;
      }
      if (!metadataItem) {
        return result;
      }
      const newResult = addMetadataToAggregation(result, metadataItem);
      return newResult;
    },
    null,
  );

  return aggregated;
}

export function getMetadataAtCaretPosition(
  block: ContentBlock,
  caretPosition: number,
): IAggregatedMetadata | null {
  if (caretPosition > block.getLength()) {
    return null;
  }

  const offset = Math.max(caretPosition - 1, 0);
  const style = block.getInlineStyleAt(offset);
  const entityKey = block.getEntityAt(offset) || null;

  if (isInTable(block)) {
    return {
      ...emptyMetadata,
      styleAtAllTableChars: style,
      styleAtAnyTableChars: style,
      entityKeyAtAllTableChars: entityKey,
      entityKeyAtAnyTableChars: entityKey
        ? Immutable.OrderedSet.of(entityKey)
        : Immutable.OrderedSet(),
    };
  }

  return {
    ...emptyMetadata,
    styleAtAllTopLevelChars: style,
    styleAtAnyTopLevelChars: style,
    entityKeyAtAllTopLevelChars: entityKey,
    entityKeyAtAnyTopLevelChars: entityKey
      ? Immutable.OrderedSet.of(entityKey)
      : Immutable.OrderedSet(),
  };
}

const getMemoizedInlineStyles = memoize.maxN(
  (...inlineStyles: ReadonlyArray<string>): Immutable.OrderedSet<string> =>
    Immutable.OrderedSet.of(...inlineStyles),
  // We generally don't know how many combinations there will be as the styles include comment styles with dynamic values
  // that is why we use rather higher limit to make sure memoization is ensured
  // If we included only visual styles, we could use limit around 100
  1_000,
);

const getMemoizedAggregatedMetadataResult = memoize.maxN(
  (
    styleAtAllTopLevelChars: DraftInlineStyle | null,
    styleAtAnyTopLevelChars: DraftInlineStyle | null,
    entityKeyAtAllTopLevelChars: string | null,
    entityKeyAtAnyTopLevelChars: Immutable.OrderedSet<string> | null,
    styleAtAllTableChars: DraftInlineStyle | null,
    styleAtAnyTableChars: DraftInlineStyle | null,
    entityKeyAtAllTableChars: string | null,
    entityKeyAtAnyTableChars: Immutable.OrderedSet<string> | null,
  ): IAggregatedMetadata => {
    return {
      styleAtAllTopLevelChars,
      styleAtAnyTopLevelChars,
      entityKeyAtAllTopLevelChars,
      entityKeyAtAnyTopLevelChars,
      styleAtAllTableChars,
      styleAtAnyTableChars,
      entityKeyAtAllTableChars,
      entityKeyAtAnyTableChars,
    };
  },
  // Editor metadata is typically used for toolbars which display only when editor is focused,
  // but it evaluates even when the editor is not focused, so we need to keep the cached value for every editor on the page
  // the limit of 1000 seems to cover enough editor instances for now, but maybe could even be reduced
  1_000,
);

export const getMemoizedAggregatedMetadata = (aggregated: IAggregatedMetadata | null) =>
  aggregated &&
  getMemoizedAggregatedMetadataResult(
    aggregated.styleAtAllTopLevelChars
      ? getMemoizedInlineStyles(...aggregated.styleAtAllTopLevelChars.toArray())
      : null,
    aggregated.styleAtAnyTopLevelChars
      ? getMemoizedInlineStyles(...aggregated.styleAtAnyTopLevelChars.toArray())
      : null,
    aggregated.entityKeyAtAllTopLevelChars ?? null,
    aggregated.entityKeyAtAnyTopLevelChars
      ? getMemoizedInlineStyles(...aggregated.entityKeyAtAnyTopLevelChars.toArray())
      : null,
    aggregated.styleAtAllTableChars
      ? getMemoizedInlineStyles(...aggregated.styleAtAllTableChars.toArray())
      : null,
    aggregated.styleAtAnyTableChars
      ? getMemoizedInlineStyles(...aggregated.styleAtAnyTableChars.toArray())
      : null,
    aggregated.entityKeyAtAllTableChars ?? null,
    aggregated.entityKeyAtAnyTableChars
      ? getMemoizedInlineStyles(...aggregated.entityKeyAtAnyTableChars.toArray())
      : null,
  );

export const getEntities = memoize.weak(
  (entityMap: EntityMap, entityKeys: Immutable.Set<string>): ReadonlyArray<EntityInstance> =>
    entityKeys.map(entityMap.__get).toArray().filter(notNullNorUndefined),
);
