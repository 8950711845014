import { EditorState } from 'draft-js';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isTextElement } from '../../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { onStringElementValueChange } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  StringElement as StringElementComponent,
  StringElementProps,
} from '../../../components/elements/stringElement/StringElement.tsx';

type Props = Omit<StringElementProps, 'elementData' | 'onChange'>;

export const StringElement: React.FC<Props> = (props) => {
  const {
    typeElement: { elementId },
  } = props;

  const dispatch = useDispatch();
  const elementData = useSelector((state) =>
    getElementById(elementId, state.contentApp.editedContentItemVariantElements),
  );

  const onChange = useCallback(
    (newEditorState: EditorState) =>
      dispatch(onStringElementValueChange(elementId, newEditorState)),
    [elementId],
  );

  if (!isTextElement(elementData)) {
    return null;
  }

  return <StringElementComponent {...props} elementData={elementData} onChange={onChange} />;
};

StringElement.displayName = 'StringElementContainer';
