import Immutable from 'immutable';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { IItemsSelection } from '../../../../stores/IContentAppStoreState.ts';

export enum ItemSelectionState {
  AllSelected = 'allSelected',
  SomeSelected = 'someSelected',
  ExactNumberSelected = 'exactNumberSelected',
}

export const isItemSelected = (itemsSelection: IItemsSelection, itemId: Uuid): boolean =>
  itemsSelection.selectedItemIds.has(itemId) ||
  (itemsSelection.selectAll && !itemsSelection.unselectedItemIds.has(itemId));

/* If not all items are loaded, the result might be missing some selected items not yet loaded.
   Mind you, we're executing bulk operations on BE using a query, therefore selected items here might not be items selected for a bulk operation.
 */
export const getSelectedItems = (state: IStore): Immutable.Set<Uuid> => {
  const {
    contentApp: { listingUi },
    data: { listingContentItems },
  } = state;

  if (listingUi.itemsSelection.selectAll) {
    if (!listingContentItems.allIds) {
      return Immutable.Set();
    }

    const actualSelectedItems = listingContentItems.allIds.filter(
      (id: Uuid) => !listingUi.itemsSelection.unselectedItemIds.includes(id),
    );

    return actualSelectedItems.toSet();
  }

  return listingUi.itemsSelection.selectedItemIds.toSet();
};

// if not all items are loaded, the value should be treated as an estimate of minimum number of selected items
export const totalNumberOfSelectedItems = (state: IStore): number => {
  return getSelectedItems(state).count();
};

export const isSomethingSelected = (state: IStore): boolean => {
  const {
    contentApp: { listingUi },
    data: { listingContentItems },
  } = state;

  return (
    (listingUi.itemsSelection.selectAll && !!listingContentItems.nextContinuationToken) ||
    totalNumberOfSelectedItems(state) > 0
  );
};

export const getItemSelectionState = (state: IStore): ItemSelectionState => {
  const { contentApp, data } = state;

  const isOnlyEstimate =
    contentApp.listingUi.itemsSelection.selectAll &&
    data.listingContentItems.nextContinuationToken !== null;
  const anyUnselected = !contentApp.listingUi.itemsSelection.unselectedItemIds.isEmpty();

  if (isOnlyEstimate && !anyUnselected) {
    return ItemSelectionState.AllSelected;
  }

  if (isOnlyEstimate && anyUnselected) {
    return ItemSelectionState.SomeSelected;
  }

  return ItemSelectionState.ExactNumberSelected;
};
