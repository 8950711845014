import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import React, { RefAttributes, useRef, useState } from 'react';
import { HandleClickOutside } from '../../../../_shared/components/HandleClickOutside.tsx';
import { ArrowPosition } from '../../../../_shared/uiComponents/Popover/Popover.tsx';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebhookMessageDialogPopover } from './WebhookMessageDialogPopover.tsx';
import { IWebhookMessageDialogSharedProps } from './WebhookMessageDialogTrigger.tsx';

export enum DialogPosition {
  Left = 'webhook-message-listing__dialog--position-left',
  Right = 'webhook-message-listing__dialog--position-right',
}

export interface IToggleableWebhookMessageDialogProps {
  readonly content: string;
  readonly dataUiAction: DataUiAction;
  readonly dialogPosition: DialogPosition;
  readonly isLoading?: boolean;
  readonly labelText: string;
  readonly trigger: (
    injectedProps: IWebhookMessageDialogSharedProps & RefAttributes<HTMLDivElement>,
  ) => JSX.Element;
}

export const ToggleableWebhookMessageDialog: React.FC<IToggleableWebhookMessageDialogProps> = ({
  content,
  dataUiAction,
  dialogPosition,
  isLoading,
  labelText,
  trigger,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => setIsActive(!isActive);
  const hidePopover = () => setIsActive(false);

  const transitions = useTransition(isActive, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const animatedPopover = transitions(
    (style, item) =>
      item && (
        <animated.div
          style={style}
          className={classNames('webhook-message-listing__dialog', dialogPosition)}
          onClick={(e) => e.stopPropagation()}
        >
          <WebhookMessageDialogPopover
            content={content}
            isLoading={isLoading}
            onAfterCopied={hidePopover}
            labelText={labelText}
            verticalArrowPosition={
              dialogPosition === DialogPosition.Right ? ArrowPosition.Start : ArrowPosition.End
            }
          />
        </animated.div>
      ),
  );

  return (
    <HandleClickOutside observedRefs={innerRef} onClickOutside={hidePopover}>
      {trigger({
        dataUiAction,
        isActive,
        labelText,
        onToggle: toggleActive,
        popover: animatedPopover,
        ref: innerRef,
      })}
    </HandleClickOutside>
  );
};

ToggleableWebhookMessageDialog.displayName = 'ToggleableWebhookMessageDialog';
