import { EditorState } from 'draft-js';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { getSelectionForEntity as getSelectionForEntityInContent } from '../../../utils/editorSelectionUtils.ts';
import { EntityApiPlugin } from '../EntityApiPlugin.tsx';
import { removeMatchingEntities } from './editorEntityUtils.ts';

export const editorEntityApi: EditorApiImplementation<EntityApiPlugin> = {
  getSelectionForEntity(_, editorState, entityKey) {
    const content = editorState.getCurrentContent();
    return getSelectionForEntityInContent(content, entityKey);
  },

  forceSelectionToEntity(api, editorState, entityKey) {
    const newSelection = api.getSelectionForEntity(editorState, entityKey);
    if (newSelection) {
      return EditorState.forceSelection(editorState, newSelection);
    }

    return editorState;
  },

  removeEntities(api, editorState, predicate, getReplacementText, allowUndo = true) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => removeMatchingEntities(input, predicate, getReplacementText),
      'change-block-data',
      allowUndo,
    );
  },
};
