import React from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../../../_shared/models/LoadingStatusEnum.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../../../_shared/selectors/contentCollections.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { CollectionSection } from '../../../components/details/Collection/CollectionSection.tsx';
import { getCurrentCollectionName } from '../../selectors/getCurrentCollectionName.ts';

const shouldShowCollectionSection = (state: IStore): boolean =>
  areCollectionsVisibleForCurrentUser(state) &&
  state.data.collections.loadingStatus === LoadingStatus.Loaded;

const CollectionSectionContainer: React.FC = () => {
  const currentCollectionName = useSelector(getCurrentCollectionName);
  const showCollectionSection = useSelector(shouldShowCollectionSection);

  return showCollectionSection && currentCollectionName ? <CollectionSection /> : null;
};

CollectionSectionContainer.displayName = 'CollectionSectionContainer';
export { CollectionSectionContainer as CollectionSection };
