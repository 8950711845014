import { AiErrorCode, AiErrorCodeMap } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';

export const editorInlineErrorMessageByErrorCode: AiErrorCodeMap = {
  ...baseErrorMessageByErrorCode,
  [AiErrorCode.InputContentFilter]:
    'The instructions or the texts in this item don’t meet our content policy.',
  [AiErrorCode.InputTooLong]:
    'The instructions are too long, or there’s too much text in the item. Shorten the instructions or remove some content from the item to try again.',
  [AiErrorCode.InputTooShort]: 'Provide longer instructions to get useful results from the AI.',
  [AiErrorCode.Unable]:
    'The AI didn’t provide a meaningful response. Try again later. If this keeps happening, let us know via chat.',
};
