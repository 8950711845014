import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';

interface Deps {
  readonly CalendarFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadItemsMatchingFilterWithVariantActiveInPeriod: (period: DateRange) => ThunkPromise;
}

export const createFilterCalendarAction =
  (deps: Deps) => (): ThunkPromise => async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        listingUi: { filter },
      },
      calendarApp: { selectedPeriod },
    } = state;
    const { projectId } = getCurrentProject(state);
    deps.CalendarFilterStorage?.save(filter, projectId);
    await dispatch(deps.loadItemsMatchingFilterWithVariantActiveInPeriod(selectedPeriod));
  };
