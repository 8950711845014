import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isCloningFailed, isCloningInProgress } from '../../../projects/utils/copyStateUtils.ts';
import { IEnvironmentViewModel } from '../selectors/getEnvironmentsForListing.ts';
import { EnvironmentActionFailedButton } from './ButtonActions/EnvironmentActionFailedButton.tsx';
import { EnvironmentTransitionButton } from './ButtonActions/EnvironmentTransitionButton.tsx';

type Props = {
  readonly environment: IEnvironmentViewModel;
  readonly hasManageEnvironmentCapability: boolean;
  readonly onDeleteEnvironment: (environmentId: Uuid) => void;
  readonly onGoToEnvironment: (environmentId: Uuid) => void;
};

export const EnvironmentMainButton: React.FC<Props> = ({
  environment,
  hasManageEnvironmentCapability,
  onDeleteEnvironment,
  onGoToEnvironment,
}) => {
  if (
    environment.isBeingDeleted ||
    environment.isBeingRenamed ||
    environment.isBeingSwapped ||
    isCloningInProgress(environment.copyState)
  ) {
    return <EnvironmentTransitionButton environment={environment} isStatusChanging={false} />;
  }

  if (isCloningFailed(environment.copyState) && hasManageEnvironmentCapability) {
    return (
      <EnvironmentActionFailedButton
        onDelete={() => onDeleteEnvironment(environment.environmentId)}
      />
    );
  }

  return (
    <Button
      buttonStyle="primary"
      size="medium"
      onClick={() => onGoToEnvironment(environment.environmentId)}
      {...getDataUiActionAttribute(DataUiAction.GoToEnvironment)}
    >
      Go to environment
    </Button>
  );
};

EnvironmentMainButton.displayName = 'EnvironmentMainButton';
