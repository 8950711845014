import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { insertAtIndex } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { isTimeInPast } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import {
  ISitemap,
  ISitemapNode,
} from '../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { DirectionItemEnum } from '../constants/DirectionItemEnum.ts';
import { SitemapDeprecatedAt } from '../constants/uiConstants.ts';

function getNode(sitemap: ISitemap, nodeId: Uuid): ISitemapNode | null {
  return sitemap.id === nodeId ? sitemap : (sitemap.nodes.get(nodeId) ?? null);
}

function updateNode(sitemap: ISitemap, node: ISitemapNode): ISitemap {
  return {
    ...sitemap,
    nodes: new Map(sitemap.nodes).set(node.id, node),
  };
}

function getParentNode(sitemap: ISitemap, nodeId: Uuid): ISitemapNode | null {
  return sitemap.childIds.includes(nodeId)
    ? sitemap
    : Collection.getValues(sitemap.nodes).find((node) => node.childIds.includes(nodeId)) || null;
}

function getNodeIndex(childIds: ReadonlyArray<Uuid>, nodeId: Uuid): number {
  return childIds.findIndex((childId: Uuid) => childId === nodeId);
}

function findPrecedentSiblingId(sitemap: ISitemap, targetNodeId: Uuid): Uuid | null {
  const parentNode = getParentNode(sitemap, targetNodeId);
  if (parentNode) {
    const targetIndex = getNodeIndex(parentNode.childIds, targetNodeId);
    // If the target is second or more... (First node has no precedent sibling...)
    if (targetIndex > 0) {
      const precedentSiblingId = parentNode.childIds[targetIndex - 1];
      if (precedentSiblingId) {
        return precedentSiblingId;
      }
    }
  }

  return null;
}

function detachNode(sitemap: ISitemap, nodeId: Uuid): ISitemap {
  const parent = getParentNode(sitemap, nodeId);
  const sitemapNodes = parent
    ? new Map(sitemap.nodes).set(parent.id, {
        ...parent,
        childIds: parent.childIds.filter((id) => id !== nodeId),
      })
    : sitemap.nodes;

  return {
    ...sitemap,
    childIds: sitemap.childIds.filter((id) => id !== nodeId),
    nodes: sitemapNodes,
  };
}

function placeNodeAsSibling(sitemap: ISitemap, targetNodeId: Uuid, nodeId: Uuid): ISitemap {
  const parentNode = getParentNode(sitemap, targetNodeId);

  if (parentNode && parentNode.id === sitemap.id) {
    const childIds = sitemap.childIds;
    const index = getNodeIndex(childIds, targetNodeId);
    const updatedChildIds = insertAtIndex(childIds, index + 1, nodeId);

    return {
      ...sitemap,
      childIds: updatedChildIds,
    };
  }
  if (parentNode) {
    const index = getNodeIndex(parentNode.childIds, targetNodeId);
    const updatedParentNode: ISitemapNode = {
      ...parentNode,
      childIds: insertAtIndex(parentNode.childIds, index + 1, nodeId),
    };

    return updateNode(sitemap, updatedParentNode);
  }

  return sitemap;
}

function placeNodeAsLastChild(sitemap: ISitemap, targetId: Uuid, nodeId: Uuid): ISitemap {
  if (sitemap.id === targetId) {
    return {
      ...sitemap,
      childIds: [...sitemap.childIds, nodeId],
    };
  }

  const parentNode = getNode(sitemap, targetId);

  if (parentNode) {
    return updateNode(sitemap, {
      ...parentNode,
      childIds: [...parentNode.childIds, nodeId],
    });
  }

  return sitemap;
}

function moveNodeRight(
  sitemap: ISitemap,
  collapsedNodeIds: ReadonlyArray<Uuid>,
  nodeId: Uuid,
): ISitemap {
  const futureParentId = findPrecedentSiblingId(sitemap, nodeId);
  if (futureParentId) {
    const sitemapWithSourceNodeDetached = detachNode(sitemap, nodeId);
    if (collapsedNodeIds.includes(futureParentId)) {
      return placeNodeAsSibling(sitemapWithSourceNodeDetached, futureParentId, nodeId);
    }
    return placeNodeAsLastChild(sitemapWithSourceNodeDetached, futureParentId, nodeId);
  }

  return sitemap;
}

function insertNodeAsAChild(
  sitemap: ISitemap,
  targetNode: ISitemapNode,
  targetIndex: number,
  sourceNodeId: Uuid,
): ISitemap {
  if (sitemap.id === targetNode.id) {
    return {
      ...sitemap,
      childIds: insertAtIndex(sitemap.childIds, targetIndex, sourceNodeId),
    };
  }

  return updateNode(sitemap, {
    ...targetNode,
    childIds: insertAtIndex(targetNode.childIds, targetIndex, sourceNodeId),
  });
}

function moveNodeInSitemap(
  sitemap: ISitemap,
  draggedNodeId: Uuid,
  targetNodeId: Uuid,
  before = false,
): ISitemap {
  const treeWithDraggedNodeDetached = detachNode(sitemap, draggedNodeId);

  const targetNodeParent = getParentNode(treeWithDraggedNodeDetached, targetNodeId);
  if (targetNodeParent && targetNodeParent.id !== draggedNodeId) {
    const children = targetNodeParent.childIds;
    let targetNodeIndex = getNodeIndex(children, targetNodeId);

    if (targetNodeIndex >= 0) {
      if (!before) {
        targetNodeIndex++;
      }

      return insertNodeAsAChild(
        treeWithDraggedNodeDetached,
        targetNodeParent,
        targetNodeIndex,
        draggedNodeId,
      );
    }
  }

  return sitemap;
}

function isAncestor(sitemap: ISitemap, supposedChildId: Uuid, supposedAncestorId: Uuid): boolean {
  if (supposedAncestorId === sitemap.id) {
    return false;
  }
  const parentNodeOfSupposedChild = getParentNode(sitemap, supposedChildId);
  if (parentNodeOfSupposedChild) {
    if (parentNodeOfSupposedChild.id === supposedAncestorId) {
      return true;
    }
    return isAncestor(sitemap, parentNodeOfSupposedChild.id, supposedAncestorId);
  }
  return false;
}

export function removeNode(sitemap: ISitemap, nodeId: Uuid): ISitemap {
  const sitemapWithFilteredChildIds = detachNode(sitemap, nodeId);
  const nodes = new Map(sitemapWithFilteredChildIds.nodes);
  nodes.delete(nodeId);

  return {
    ...sitemapWithFilteredChildIds,
    nodes,
  };
}

export function moveSitemapNode(
  sitemap: ISitemap,
  collapsedNodeIds: ReadonlyArray<Uuid>,
  draggedNodeId: Uuid,
  targetNodeId: Uuid,
  directionSet: ReadonlySet<DirectionItemEnum>,
): ISitemap {
  // if SourceNode does not exist => no movement
  const sourceNode = getNode(sitemap, draggedNodeId);
  if (sourceNode === null) {
    return sitemap;
  }

  // This is for moving the node right
  if (draggedNodeId === targetNodeId || isAncestor(sitemap, targetNodeId, draggedNodeId)) {
    if (directionSet.has(DirectionItemEnum.Right)) {
      const updatedSitemap = moveNodeRight(sitemap, collapsedNodeIds, draggedNodeId);
      return updatedSitemap;
    }
    return sitemap;
  }

  if (
    directionSet.has(DirectionItemEnum.Up) ||
    directionSet.has(DirectionItemEnum.Down) ||
    !directionSet.has(DirectionItemEnum.Right)
  ) {
    const updatedSitemap = moveNodeInSitemap(
      sitemap,
      draggedNodeId,
      targetNodeId,
      directionSet.has(DirectionItemEnum.Up) && !directionSet.has(DirectionItemEnum.Down),
    );
    return updatedSitemap;
  }

  return sitemap;
}

export function isSitemapEnabled(subscription: ISubscription): boolean {
  if (!subscription.subscriptionId) {
    return false;
  }

  const subscriptionCreatedBeforeDeprecation = isTimeInPast(
    SitemapDeprecatedAt,
    subscription.createdAt,
  );
  return subscriptionCreatedBeforeDeprecation;
}

export const getNodeCodenamesWithoutEditedNodeCodename = memoize.maxOne(
  (
    sitemapNodes: ReadonlyMap<Uuid, ISitemapNode>,
    editedNodeId?: Uuid,
  ): ReadonlyArray<string | null> => {
    return Collection.getValues(sitemapNodes)
      .filter((node) => node.id === editedNodeId)
      .map((node) => node.codename);
  },
);
