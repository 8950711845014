import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  IWebhookSetting,
  createWebhookSettingDomainModel,
} from '../../../../data/models/webhooks/WebhookSetting.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';
import { IWebhookServerModel } from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  Webhooks_Webhook_RestoreCompleted,
  Webhooks_Webhook_RestoreFailed,
  Webhooks_Webhook_RestoreStarted,
} from '../../constants/webhooksActionTypes.ts';

interface IRestoreWebhookDependencies {
  readonly webhookRepository: IWebhookRepository;
}

const started = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_RestoreStarted,
    payload: {
      webhook,
    },
  }) as const;

const completed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_RestoreCompleted,
    payload: {
      webhook,
    },
  }) as const;

const failed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_RestoreFailed,
    payload: {
      webhook,
    },
  }) as const;

export type RestoreWebhookActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

export const createRestoreWebhookAction =
  (deps: IRestoreWebhookDependencies) =>
  (webhook: IWebhookSetting): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(started(webhook));

      const restoredWebhook: IWebhookServerModel = await deps.webhookRepository.restore(webhook.id);

      dispatch(completed(createWebhookSettingDomainModel(restoredWebhook)));
    } catch {
      dispatch(failed(webhook));
    }
  };
