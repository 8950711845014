import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_ElementRefresh_Failed,
  ContentItemEditing_ElementRefresh_Fetched,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';

const initialState: Immutable.Map<Uuid, ICompiledContentItemElementData | null> = Immutable.Map();

export function pendingRefreshElements(
  state: Immutable.Map<Uuid, ICompiledContentItemElementData | null> = initialState,
  action: Action,
): Immutable.Map<Uuid, ICompiledContentItemElementData | null> {
  switch (action.type) {
    case ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted:
    case ContentItemEditing_ElementRefresh_Failed: {
      return initialState;
    }

    case ContentItemEditing_ElementRefresh_Fetched: {
      const { refreshedElements } = action.payload;

      return refreshedElements.reduce(
        (
          reduced: Immutable.Map<Uuid, ICompiledContentItemElementData | null>,
          elementData: ICompiledContentItemElementData,
        ) => {
          return reduced.set(elementData.elementId, elementData);
        },
        state,
      );
    }

    case ContentItemEditing_ElementRefresh_Finished: {
      return state.remove(action.payload.elementData.elementId);
    }

    default:
      return state;
  }
}
