import { Minute } from '../constants/onboardingConstants.ts';

export const getRoundedUpTimeInMinutes = (milliseconds: number): string => {
  return `${Math.floor((milliseconds + Minute) / Minute)}`;
};

export const getAgeOfProject = (projectActivatedAt: DateTimeStamp, nowDate: Date): number => {
  const projectCreatedAt = new Date(projectActivatedAt).valueOf();
  const now = nowDate.valueOf();

  return now - projectCreatedAt;
};
