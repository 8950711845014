import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ProjectUiTransitionState } from '../../../projects/types/projectUiTransitionState.ts';
import { isProjectInSettingUpUiTransitionState } from '../../../projects/utils/projectUtils.ts';

const getEnvironmentsMemoized = memoize.weak(
  (
    byId: Immutable.Map<Uuid, IProjectDetails>,
    projectsUiTransitionState: Immutable.Map<Uuid, ProjectUiTransitionState>,
    masterEnvironmentId: Uuid,
    includeIncomplete: boolean = true,
  ) =>
    byId
      .toArray()
      .filter(
        (project: IProjectDetails) =>
          project.masterEnvironmentId === masterEnvironmentId &&
          (includeIncomplete ||
            !isProjectInSettingUpUiTransitionState(project.projectId, projectsUiTransitionState)),
      ),
);

const getProjectContainerEnvironmentsMemoized = memoize.weak(
  (
    byId: Immutable.Map<Uuid, IProjectDetails>,
    projectsUiTransitionState: Immutable.Map<Uuid, ProjectUiTransitionState>,
    projectContainerId: Uuid,
    includeIncomplete: boolean = true,
  ): ReadonlyArray<IProjectDetails> =>
    byId
      .toArray()
      .filter(
        (project: IProjectDetails) =>
          project.projectContainerId === projectContainerId &&
          (includeIncomplete ||
            !isProjectInSettingUpUiTransitionState(project.projectId, projectsUiTransitionState)),
      ),
);

export const getEnvironments = (
  state: IStore,
  masterEnvironmentId: Uuid,
  includeIncomplete: boolean = true,
): ReadonlyArray<IProjectDetails> => {
  const {
    data: {
      projects: { byId },
    },
    projectsApp: { projectsUiTransitionState },
  } = state;

  return getEnvironmentsMemoized(
    byId,
    projectsUiTransitionState,
    masterEnvironmentId,
    includeIncomplete,
  );
};

export const getProjectContainerEnvironments = (
  state: IStore,
  projectContainerId: Uuid,
  includeIncomplete: boolean = true,
): ReadonlyArray<IProjectDetails> => {
  const {
    data: {
      projects: { byId },
    },
    projectsApp: { projectsUiTransitionState },
  } = state;

  return getProjectContainerEnvironmentsMemoized(
    byId,
    projectsUiTransitionState,
    projectContainerId,
    includeIncomplete,
  );
};
