import { buildQueryString } from './queryParamsUtils.ts';

/**
 * Redirects to sing-in page
 * @param includeRedirectUri uri of current page will be passed to sing-in page
 */
export function redirectToSignInPage(includeRedirectUri?: boolean): void {
  const relativeUri = getSignInPageUrl(includeRedirectUri);
  window.location.assign(`${window.location.protocol}//${window.location.host}/${relativeUri}`);
}

/**
 * Generates relative url to sing-in page
 * @param includeRedirectUri uri of current page will be added to sing-in page url
 */
export function getSignInPageUrl(includeRedirectUri?: boolean): string {
  let loginPageUrl = 'sign-in';
  if (includeRedirectUri) {
    loginPageUrl += buildQueryString({ redirect_uri: window.location.href });
  }
  return loginPageUrl;
}
