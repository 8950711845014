import { ContentState } from 'draft-js';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  DraftJsOperationInput,
  StringOperationInput,
} from '../../../../repositories/serverModels/ai/AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../../../../repositories/serverModels/ai/AiServerModels.response.ts';
import {
  CumulatedStringResult,
  isCumulatedStringOperationResponseMessage,
} from '../../../../repositories/serverModels/ai/AiServerModels.result.ts';

export type ReviewByGuidelinesParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: AiActionName.ReviewByGuidelines;
    readonly inputs: {
      readonly content: DraftJsOperationInput;
      readonly guidelines: StringOperationInput;
    };
  };

export const createReviewByGuidelinesRequest = (
  contentState: ContentState,
  guidelines: string,
  trackingData: ElementOperationTrackingData,
): ReviewByGuidelinesParams => ({
  actionName: AiActionName.ReviewByGuidelines,
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
    guidelines: {
      type: 'string',
      value: guidelines,
    },
  },
  trackingData,
});

export type ReviewByGuidelinesResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedStringResult>;

export const isReviewByGuidelinesMessage = (
  message: OperationResponseMessage,
): message is ReviewByGuidelinesResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedStringOperationResponseMessage(message) || isErrorOperationMessage(message);
