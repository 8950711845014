import { Action } from '../../../../../../@types/Action.type.ts';
import {
  Invite_Closed,
  Invite_Initialize,
  Invite_SearchText_Changed,
  Invite_SubmitInvitations_Finished,
} from '../../../constants/usersActionTypes.ts';
import { findTextWithoutInvitations } from '../../../utils/parseInvitations.ts';

const emptyState = '';

export const searchText = (state = emptyState, action: Action): string => {
  switch (action.type) {
    case Invite_SearchText_Changed:
      return findTextWithoutInvitations(action.payload.searchText);

    case Invite_Initialize:
      return action.payload.initialEmail || emptyState;

    case Invite_SubmitInvitations_Finished:
    case Invite_Closed:
      return emptyState;

    default:
      return state;
  }
};
