import { createCompare, numerically } from '@kontent-ai/utils';
import { OrderByDirection } from '../../../_shared/models/OrderBy.ts';

type BaseItem = Readonly<{
  id: string;
  value: number;
}>;

type Tuple = readonly [id: string, percentage: number];

/**
 * Returns rounded percentages using the "largest remainder method" so that their sum always adds up to 100.
 * @param items
 */
export const getRoundedPercentages = <TItem extends BaseItem>(
  items: ReadonlyArray<TItem>,
): ReadonlyMap<string, number> => {
  const total = items.reduce((sum, item) => sum + item.value, 0);

  const percentagesWithId = items.map<Tuple>(({ value, id }) => [id, (value / total) * 100]);

  const diffToTotalPercentage =
    100 -
    percentagesWithId.reduce(
      (accumulator, [, percentage]) => accumulator + Math.floor(percentage),
      0,
    );

  const percentageSortedByRemainder = percentagesWithId.sort(
    createCompare({
      select: ([, percentage]) => percentage - Math.floor(percentage),
      compare: numerically,
      direction: OrderByDirection.Descending,
    }),
  );

  const result = percentageSortedByRemainder.map<Tuple>(([id, percentage], index) => [
    id,
    Math.floor(index < diffToTotalPercentage ? percentage + 1 : percentage),
  ]);

  return new Map(result);
};
