import { linearGradient } from '../../utils/gradient.ts';
import { BaseColor } from '../quarks/colors.ts';
import { Gradient, GradientAngle } from '../quarks/gradients.ts';

export const DeskGradient = linearGradient(
  GradientAngle.ToBottomRight,
  BaseColor.Gray10,
  BaseColor.OceanBlue20,
);
export const EmptyDeskGradient = linearGradient(
  GradientAngle.ToBottomRight,
  BaseColor.Gray10,
  BaseColor.OceanBlue30,
);
export const SkeletonContentGradient = Gradient.GrayVeryLight(GradientAngle.ToBottomRight);

const composeGuardGradient = (angle: GradientAngle) =>
  linearGradient(angle, BaseColor.OceanBlue10, BaseColor.Transparent);

/**
 * Get gradient based on guard's location (not direction).
 */
export const GuardGradient = {
  left: composeGuardGradient(GradientAngle.ToRight),
  top: composeGuardGradient(GradientAngle.ToBottom),
  right: composeGuardGradient(GradientAngle.ToLeft),
  bottom: composeGuardGradient(GradientAngle.ToTop),
} as const;
