import { ContentBlock, EditorChangeType } from 'draft-js';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import { TablesPlugin } from '../TablesPlugin.tsx';
import { TableActionType } from './EditorTableApi.type.ts';
import {
  insertColumnLeft as insertColumnLeftInContent,
  insertColumnRight as insertColumnRightInContent,
  insertRowAbove as insertRowAboveInContent,
  insertRowBelow as insertRowBelowInContent,
  insertTable as insertTableToContent,
  removeColumn as removeColumnInContent,
  removeRow as removeRowInContent,
  removeTable as removeTableFromContent,
} from './editorTableUtils.ts';

export const editorTableApi: EditorApiImplementation<TablesPlugin> = {
  insertTable(api, editorState, selection, cols, rows) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => insertTableToContent(input, cols, rows),
      'insert-fragment',
    );
  },

  executeTableAction(api, editorState, action, starterCellBlock) {
    const [change, changeType] = tableActionsMap[action];

    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => change(input, starterCellBlock),
      changeType,
    );
  },
};

const tableActionsMap: ReadonlyRecord<
  TableActionType,
  [
    (input: IContentChangeInput, starterCellBlock: ContentBlock) => IContentChangeResult,
    EditorChangeType,
  ]
> = {
  'insert-row-above': [insertRowAboveInContent, 'insert-fragment'],
  'insert-row-below': [insertRowBelowInContent, 'insert-fragment'],
  'remove-row': [removeRowInContent, 'remove-range'],
  'insert-column-left': [insertColumnLeftInContent, 'insert-fragment'],
  'insert-column-right': [insertColumnRightInContent, 'insert-fragment'],
  'remove-column': [removeColumnInContent, 'remove-range'],
  'remove-table': [removeTableFromContent, 'remove-range'],
};
