import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadSubscriptionUsersUsage: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createInitSubscriptionUsers =
  ({ loadSubscriptionUsersUsage, loadProjects }: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(loadSubscriptionUsersUsage(abortSignal)),
      dispatch(loadProjects(abortSignal)),
    ]);
  };
