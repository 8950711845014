import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { ICompiledContentType } from '../../contentInventory/content/models/CompiledContentType.ts';

export const getEditedContentItemType = (state: IStore): ICompiledContentType | null => {
  const { editedContentItem, loadedContentItemTypes } = state.contentApp;

  if (!editedContentItem?.editedContentItemTypeId) {
    return null;
  }

  return loadedContentItemTypes.get(editedContentItem.editedContentItemTypeId) ?? null;
};
