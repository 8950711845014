import { mergeProps } from '@react-aria/utils';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { Tooltip } from './Tooltip.tsx';
import { UseOptionalTooltipResult, useOptionalTooltip } from './useOptionalTooltip.ts';

type RenderProp = (
  truncatedElementRef: UseOptionalTooltipResult['truncatedElementRef'],
) => React.ReactElement;

export type OptionalTooltipProps = {
  readonly customRenderText?: RenderProp;
  readonly placement: TooltipPropsExtension['tooltipPlacement'];
  /**
   * Tooltip text and text merge into tooltip when text gets truncated.
   */
  readonly text: string;
  /**
   * tooltipText is shown always. If truncation occurs, text is appended.
   */
  readonly tooltipText?: TooltipPropsExtension['tooltipText'];
  readonly visible?: boolean;
};

const StyledOptionalTooltipBody = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const OptionalTooltip: React.FC<React.PropsWithChildren<OptionalTooltipProps>> = ({
  children,
  customRenderText,
  placement = 'bottom',
  text,
  tooltipText,
  visible,
  ...otherProps
}) => {
  const textElementRef = useRef<HTMLElement>(null);

  const { tooltipProps, truncatedElementRef } = useOptionalTooltip(
    textElementRef,
    text,
    tooltipText,
  );

  return (
    <Tooltip placement={placement} visible={visible} {...mergeProps(tooltipProps, otherProps)}>
      {customRenderText ? (
        customRenderText(truncatedElementRef)
      ) : (
        <StyledOptionalTooltipBody ref={truncatedElementRef}>{children}</StyledOptionalTooltipBody>
      )}
    </Tooltip>
  );
};

OptionalTooltip.displayName = 'OptionalTooltip';
