import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { DateTime, createDateTime } from '../../../../../../_shared/models/DateTime.ts';
import { isDateTimeTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { DateTimeItemElement } from '../../../../models/contentItemElements/DateTimeItemElement.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { getDateTimeElementErrors } from '../../../../utils/elementErrorCheckers/dateTimeElementErrorChecker.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getItemElementValueForErrorValidation } from '../../../../utils/getItemElementValueForErrorValidation.ts';
import { emptyItemElementFriendlyWarningResult } from '../../../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { getDateTimeItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/dateTimeItemElementWarningChecker.ts';
import { mapElementErrorResultToItemElementErrorResult } from '../../../../utils/mapElementErrorResultToItemElementErrorResult.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

export const createDateTimeElementValueChangedAction =
  () =>
  (elementId: Uuid, dateTime: DateTime, displayTimeZone: string): ThunkFunction =>
  (dispatch, getState) => {
    const { editedContentItem, editedContentItemVariantElements, loadedContentItemTypes } =
      getState().contentApp;

    if (!editedContentItem) {
      throw InvariantException(
        'Cannot update dateTime element value. "editedContentItem" is not loaded',
      );
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    if (!editedContentItemType) {
      throw InvariantException(
        'Cannot update dateTime element value. "editedContentItemType" is not loaded',
      );
    }

    const dateTimeElement = getElementByIdOrThrow<DateTimeItemElement>(
      elementId,
      editedContentItemVariantElements,
    );
    const updatedDateTimeElement = {
      ...dateTimeElement,
      value: dateTime.value,
      displayTimeZone,
      _dateTime: createDateTime(dateTime),
    };

    const errorResult = getDateTimeElementErrors({
      value: getItemElementValueForErrorValidation(updatedDateTimeElement),
    });
    const typeElement = editedContentItemType.contentElements.find(
      (elType) => elType.elementId === elementId,
    );
    if (!isDateTimeTypeElement(typeElement)) {
      return;
    }

    const warningResult = getDateTimeItemElementValidationWarning({
      typeElement,
      itemElement: updatedDateTimeElement,
    });

    const validationResult = createValidationResult(
      mapElementErrorResultToItemElementErrorResult(errorResult, typeElement.type),
      warningResult,
      emptyItemElementFriendlyWarningResult,
      elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: updatedDateTimeElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
