import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { ICollectionGroupRoles } from '../../../../../data/models/users/ProjectContributor.ts';
import {
  UserDetail_Initialized,
  UserDetail_UserNotFound,
} from '../../constants/usersActionTypes.ts';

const userDetailInitialized = (collectionGroups: readonly ICollectionGroupRoles[]) =>
  ({
    type: UserDetail_Initialized,
    payload: { collectionGroups },
  }) as const;

const userNotFound = () =>
  ({
    type: UserDetail_UserNotFound,
  }) as const;

export type InitializeUserDetailActionsType = ReturnType<
  typeof userDetailInitialized | typeof userNotFound
>;

export const createInitializeUserDetailAction =
  () =>
  (userId: Uuid, onUserNotFound: () => void): ThunkFunction =>
  (dispatch, getState): void => {
    const {
      usersApp: { projectContributors },
    } = getState();

    const user = projectContributors.get(userId);

    if (user) {
      dispatch(userDetailInitialized(user.collectionGroups));
    } else {
      dispatch(userNotFound());
      onUserNotFound();
    }
  };
