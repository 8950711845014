import { format } from 'date-fns-tz';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';

export const getOffsetFormat = (
  dateTimeString: string,
  browserCompatibleTimeZone: string,
): string => {
  const offset = format(new Date(dateTimeString), 'XXX', { timeZone: browserCompatibleTimeZone });

  return offset === 'Z' ? '+00:00' : offset;
};

export const getSelectedDateAndTimeZoneCaption = (
  dateTimeString: string,
  browserCompatibleTimeZone: string,
): string =>
  `You’ve selected UTC ${getOffsetFormat(
    dateTimeString,
    browserCompatibleTimeZone,
  )} – this means ${renderDatetimeString(dateTimeString)} in your local time.`;
