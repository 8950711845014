import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { showDefaultLanguageEditor } from '../../actions/localizationActions.ts';
import {
  DefaultLanguageBar as DefaultLanguageBarComponent,
  IDefaultLanguageBarCallbacksProps,
  IDefaultLanguageBarDataProps,
} from '../../components/languageBarItems/DefaultLanguageBar.tsx';

const mapStateToDefaultLanguageBarProps = (state: IStore): IDefaultLanguageBarDataProps => {
  const {
    localizationApp: { defaultLanguage, editedLanguageId, isDefaultLanguageEdited },
  } = state;
  return {
    canBeEdited: !editedLanguageId && !isDefaultLanguageEdited,
    defaultLanguage,
  };
};

const mapDispatchToDefaultLanguageBarProps = (
  dispatch: Dispatch,
): IDefaultLanguageBarCallbacksProps => ({
  onEdit: () => dispatch(showDefaultLanguageEditor()),
});

export const DefaultLanguageBar: React.ComponentType = connect(
  mapStateToDefaultLanguageBarProps,
  mapDispatchToDefaultLanguageBarProps,
)(DefaultLanguageBarComponent);
