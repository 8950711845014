import { Box } from '@kontent-ai/component-library/Box';
import { RadioButton, RadioGroup } from '@kontent-ai/component-library/RadioGroup';
import { Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import { useController } from 'react-hook-form';
import { OptionType } from '../../../../_shared/components/Options/createOptionsListComponent.tsx';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiEntityWebhookAction,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EntityWebhookDeliverySlot } from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';

const options: ReadonlyArray<OptionType<DataUiEntityWebhookAction>> = [
  {
    dataUiAttribute: DataUiEntityWebhookAction.WebhookPublishedSlotTrigger,
    id: EntityWebhookDeliverySlot.Published,
    label: 'Published data',
  },
  {
    dataUiAttribute: DataUiEntityWebhookAction.WebhookPreviewSlotTrigger,
    id: EntityWebhookDeliverySlot.Preview,
    label: 'Preview data',
  },
];

type EntityWebhookSlotTriggerProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

const groupTitle = 'Published or preview';

export const EntityWebhookSlotTrigger = ({ enabled, formProps }: EntityWebhookSlotTriggerProps) => {
  const { control } = formProps;
  const { field } = useController({ control, name: 'triggers.slot' });

  return (
    <Stack spacing={Spacing.L}>
      <Box typography={Typography.HeadlineMedium}>{groupTitle}</Box>
      <RadioGroup
        ariaLabel={groupTitle}
        groupSubLabel="Select whether you’re interested in content changes in preview or published data."
        name="SlotTrigger"
        onChange={field.onChange}
        radioGroupState={enabled ? 'default' : 'disabled'}
        value={field.value}
        {...getDataUiCollectionAttribute(DataUiCollection.ScheduleMethodSelector)}
      >
        {options.map((option) => (
          <Row key={option.id}>
            <RadioButton
              value={option.id}
              radioButtonState={option.disabled ? 'disabled' : 'default'}
              tooltipText={option.tooltipText}
              {...getDataUiObjectNameAttribute(option.dataUiAttribute ?? '')}
            >
              {option.label}
            </RadioButton>
          </Row>
        ))}
      </RadioGroup>
    </Stack>
  );
};

EntityWebhookSlotTrigger.displayName = 'EntityWebhookSlotTrigger';
