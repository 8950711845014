import { Action } from '../../../../../@types/Action.type.ts';
import { SubscriptionUsers_UsersUsage_Loaded } from '../../../../subscriptionManagement/SubscriptionUsers/constants/actionTypes.ts';
import { Users_UsersCountedInUsage_Loaded } from '../../constants/usersActionTypes.ts';

const initialState: readonly string[] = [];

export const usersCountedInUsage = (
  state: ReadonlyArray<string> = initialState,
  action: Action,
): ReadonlyArray<string> => {
  switch (action.type) {
    case Users_UsersCountedInUsage_Loaded:
      return action.payload.usersCountedInUsage;

    case SubscriptionUsers_UsersUsage_Loaded: {
      const { subscriptionUsers } = action.payload.data;

      return subscriptionUsers
        .filter((su) => !su.isEmployee && su.userProjectsSettings.some((ups) => !ups.inactive))
        .map((su) => su.userId);
    }

    default:
      return state;
  }
};
