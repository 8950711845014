import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../../_shared/models/SidebarNames.ts';
import { SubscriptionFormContextProvider } from '../../shared/components/SubscriptionFormContext.tsx';
import { closeCreateSubscriptionModal } from '../actions/subscriptionListingActions.ts';
import { createNewSubscription } from '../actions/thunkSubscriptionListingActions.ts';
import { CreateNewSubscriptionModal } from '../components/CreateNewSubscriptionModal.tsx';
import { INewSubscriptionFormShape } from '../components/NewSubscriptionForm.tsx';

const CreateNewSubscriptionModalContainer: React.FC = () => {
  const creatingInProgress = useSelector(
    (s) => s.subscriptionApp.listingUi.creatingNewSubscription,
  );
  const errorMessage = useSelector(
    (s) => s.subscriptionApp.listingUi.creatingNewSubscriptionErrorMessage,
  );
  const showSidebar = useSelector((s) => {
    const { isVisible, sidebarName } = s.sharedApp.sidebarStatus;
    return isVisible && sidebarName === SidebarNames.CreateNewSubscriptionModal;
  });

  const dispatch = useDispatch();
  const closeDialog = () => dispatch(closeCreateSubscriptionModal());
  const onFormSubmit = useCallback(
    (values: INewSubscriptionFormShape) => dispatch(createNewSubscription(values)),
    [],
  );

  return (
    <SubscriptionFormContextProvider>
      <CreateNewSubscriptionModal
        closeDialog={closeDialog}
        creatingInProgress={creatingInProgress}
        errorMessage={errorMessage}
        onFormSubmit={onFormSubmit}
        showSidebar={showSidebar}
      />
    </SubscriptionFormContextProvider>
  );
};

CreateNewSubscriptionModalContainer.displayName = 'CreateNewSubscriptionModalContainer';

export { CreateNewSubscriptionModalContainer as CreateNewSubscriptionModal };
