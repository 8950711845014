import { RefObject, useEffect, useRef, useState } from 'react';

/**
 * Observes DOM element presence based on its id.
 * The hook reacts when the element is found or lost and return a ref object containing such element.
 * Useful when ensuring a portal container exists before some content is rendered into portal.
 * @param elementId
 */
export const useObserveElementPresence = (elementId: string): RefObject<HTMLElement | null> => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setElement(document.getElementById(elementId));

    const observer = new MutationObserver(() => {
      setElement(document.getElementById(elementId));
    });

    observer.observe(document, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, [elementId]);

  const elementRef = useRef(element);
  elementRef.current = element;

  return elementRef;
};
