import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { RequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import { getOrderBy } from '../../../../applications/contentInventory/content/models/filter/contentItemFilterUtils.ts';
import { AssignedContentItemsOrderBy } from '../../../../applications/itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IListingContentItemServerModel } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  Data_ItemsAssignedToCurrentUser_Failed,
  Data_ItemsAssignedToCurrentUser_Started,
  Data_ItemsAssignedToCurrentUser_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: Pick<IContentItemRepository, 'getItemsAssignedToCurrentUser'>;
  readonly requestTokenFactory: RequestTokenFactory;
}

type ContentItemsAssignedToCurrentUserLoadedActionPayload = {
  readonly contentItems: ReadonlyArray<IListingContentItemServerModel>;
};

export const createTokenizedAssignedItemsLoadingStarted = (cancellationToken: Uuid) => () =>
  ({
    type: Data_ItemsAssignedToCurrentUser_Started,
    payload: {
      cancellationToken,
    },
  }) as const;

const success = (data: ContentItemsAssignedToCurrentUserLoadedActionPayload) =>
  ({
    type: Data_ItemsAssignedToCurrentUser_Success,
    payload: { data },
  }) as const;

const failure = () => ({ type: Data_ItemsAssignedToCurrentUser_Failed }) as const;

export type LoadContentItemsAssignedToCurrentUserActionsType = ReturnType<
  ReturnType<typeof createTokenizedAssignedItemsLoadingStarted> | typeof success | typeof failure
>;

export const createContentItemsAssignedToCurrentUserAction =
  (deps: IDeps) =>
  (orderBy: OrderBy<AssignedContentItemsOrderBy>, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const { isCurrentTokenValid, tokenizedActionCreator: assignedItemsLoadingStarted } =
      deps.requestTokenFactory(getState);
    dispatch(assignedItemsLoadingStarted());

    try {
      const contentItems = await deps.contentItemRepository.getItemsAssignedToCurrentUser(
        getOrderBy(orderBy),
        { maxItemsCount: 100 },
        abortSignal,
        isCurrentTokenValid,
      );

      if (isCurrentTokenValid()) {
        dispatch(
          success({
            contentItems: contentItems.data,
          }),
        );
      }
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
