import { Icons } from '@kontent-ai/component-library/Icons';
import { MenuItemWithMouseHover } from '@kontent-ai/component-library/MenuItem';
import {
  VerticalMenuItem,
  VerticalMenuSection,
  useVerticalMenu,
} from '@kontent-ai/component-library/VerticalMenu';
import { IconSize } from '@kontent-ai/component-library/tokens';
import React, { useRef, useState } from 'react';
import { DropDownMenuControlled } from '../../../../../../../component-library/components/DropDownMenu/DropDownMenuControlled.tsx';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';

export type AiSubMenuItem = VerticalMenuItem<AiSubMenuItem> & {
  readonly renderIntoMenu: (item: AiSubMenuItem) => React.ReactNode;
};

export type AiSubMenuSection = VerticalMenuSection<AiSubMenuItem> & {};

type AiMenuItemWithSubMenuProps = {
  readonly label: string;
  readonly iconName: IconName;
  readonly items: ReadonlyArray<AiSubMenuItem | AiSubMenuSection>;
};

export const AiMenuItemWithSubMenu: React.FC<AiMenuItemWithSubMenuProps> = ({
  label,
  iconName,
  items,
  ...otherProps
}) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { verticalMenuState, VerticalMenu, verticalMenuProps } = useVerticalMenu(items);

  const toggleMenuIfMounted = (isOpen: boolean) => {
    if (menuItemRef.current) {
      setIsExpanded(isOpen);
    }
  };

  return (
    <DropDownMenuControlled
      onDropDownVisibilityChange={toggleMenuIfMounted}
      tippyOptions={{
        placement: 'right-start',
        appendTo: 'parent',
      }}
      isDropDownVisible={isExpanded}
      triggerRef={menuItemRef}
      renderTrigger={(triggerProps) => (
        <MenuItemWithMouseHover
          ref={triggerProps.ref}
          isExpanded={isExpanded}
          menuItemState="default"
          text={label}
          onPress={() => toggleMenuIfMounted(!isExpanded)}
          trailingElements={<Icons.ChevronRight size={IconSize.M} />}
          leadingElement={<Icon iconName={iconName} />}
          {...otherProps}
        />
      )}
      renderDropDown={() => (
        <VerticalMenu
          {...verticalMenuProps}
          aria-label={label}
          renderItem={({ item }) => item.value?.renderIntoMenu(item.value)}
          state={verticalMenuState}
          contain={false}
        />
      )}
    />
  );
};

AiMenuItemWithSubMenu.displayName = 'MenuItemWithSubMenu';
