import Immutable from 'immutable';
import { Action } from '../../../@types/Action.type.ts';
import { isSubpagesTypeElement } from '../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import {
  ContentItemEditing_ElementValue_Changed,
  ContentItemEditing_Init_Ready,
} from '../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { isModularContentElement } from '../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import {
  WebSpotlight_LocalizedRoute_Left,
  WebSpotlight_Subpages_Loaded,
} from '../constants/webSpotlightActionTypes.ts';
import { createSubpagesByIdMap } from '../utils/webSpotlightUtils.ts';

const initialState = Immutable.Map<Uuid, UuidArray>();

export const subpagesById = (
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, UuidArray> => {
  switch (action.type) {
    case WebSpotlight_Subpages_Loaded:
      return state.merge(createSubpagesByIdMap(action.payload.itemSubpagesById));

    case ContentItemEditing_ElementValue_Changed: {
      // Sync the subpages in the tree with the editor
      // but only if subpages for this item were previously loaded (can be displayed in the UI)
      const { itemId, typeElement, elementData } = action.payload;

      if (!state.get(itemId)) {
        return state;
      }

      if (!isSubpagesTypeElement(typeElement) || !isModularContentElement(elementData)) {
        return state;
      }

      return state.set(itemId, elementData.value.toArray());
    }

    case ContentItemEditing_Init_Ready: {
      // When edited item is loaded, update its subpages in the tree to make sure the tree is initially synced with the editor
      // but only if subpages for this item were previously loaded (can be displayed in the UI)
      const { editedContentItem, editedContentItemType, editedContentItemVariantElements } =
        action.payload;

      if (!state.get(editedContentItem.id)) {
        return state;
      }

      const subpagesElement = editedContentItemType.contentElements.find(isSubpagesTypeElement);
      if (!subpagesElement) {
        return state;
      }

      const elementData = getElementById(
        subpagesElement.elementId,
        editedContentItemVariantElements,
      );
      if (!isModularContentElement(elementData)) {
        return state;
      }

      return state.set(editedContentItem.id, elementData.value.toArray());
    }

    case WebSpotlight_LocalizedRoute_Left:
      return initialState;

    default:
      return state;
  }
};
