import React from 'react';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { ContentProgressWidget } from '../components/ContentProgressWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const ContentProgressWidgetLiveContainer: React.FC = () => {
  return (
    <ContentProgressWidget contentProgressFetcher={missionControlRepository.getContentProgress} />
  );
};

ContentProgressWidgetLiveContainer.displayName = 'ContentProgressWidgetLiveContainer';
