import { Action } from '../../../@types/Action.type.ts';
import {
  ProjectApp_RouteLeft,
  Project_ProjectContext_Left,
} from '../../../applications/projects/constants/projectActionTypes.ts';
import { SmartLink_RouteLeft } from '../../../applications/smartLink/constants/smartLinkActionTypes.ts';
import { SubscriptionManagement_RouteLeft } from '../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';
import { UserProfile_RouteLeft } from '../../../applications/userProfile/constants/userProfileActionTypes.ts';
import { Shared_CurrentProjectId_Updated } from '../../constants/sharedActionTypes.ts';

const initialState: Uuid = '';

export function currentProjectId(state: Uuid = initialState, action: Action): Uuid {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated: {
      return action.payload.currentProjectId;
    }
    case ProjectApp_RouteLeft:
    case SubscriptionManagement_RouteLeft:
    case UserProfile_RouteLeft:
    case SmartLink_RouteLeft:
    case Project_ProjectContext_Left: {
      return initialState;
    }

    default:
      return state;
  }
}
