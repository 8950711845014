import { ISelectItem } from './types.ts';

const emptySelectionItemId = 'EMPTY_SELECTION_ITEM';
const emptySelectionItemLabel = '(None)';

export interface IEmptySelectionItem extends ISelectItem<IEmptySelectionItem> {
  readonly id: typeof emptySelectionItemId;
  readonly label: typeof emptySelectionItemLabel;
}

export const emptySelectionItem: IEmptySelectionItem = {
  id: emptySelectionItemId,
  label: emptySelectionItemLabel,
};
