import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_Preferences_Loaded,
  WebSpotlight_PreviewAutoRefresh_Changed,
} from '../constants/webSpotlightActionTypes.ts';

export const isPreviewAutoRefreshEnabled = (state = true, action: Action): boolean => {
  switch (action.type) {
    case WebSpotlight_Preferences_Loaded:
    case WebSpotlight_PreviewAutoRefresh_Changed:
      return action.payload.isPreviewAutoRefreshEnabled;

    default:
      return state;
  }
};
