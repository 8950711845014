import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IElementStatusProps {
  readonly option: ElementStatusOption;
  readonly showIncompleteItemWarningsBeforePublish?: boolean;
}

export enum ElementStatusOption {
  Empty = 'empty',
  FilledCorrectly = 'filled-correctly',
  FilledIncorrectly = 'filled-incorrectly',
}

interface IElementStatus {
  readonly className: string;
  readonly text: string;
  readonly title: string;
}

const getElementStatusDetails = ({
  option,
  showIncompleteItemWarningsBeforePublish,
}: IElementStatusProps): IElementStatus => {
  switch (option) {
    case ElementStatusOption.FilledCorrectly:
      return {
        className: 'status--filled-correctly',
        text: 'Required',
        title: 'This element’s value must be provided.',
      };
    case ElementStatusOption.FilledIncorrectly:
      return {
        className: classNames('status--filled-incorrectly', {
          'status--validation-failed': showIncompleteItemWarningsBeforePublish,
        }),
        text: 'Not filled in correctly',
        title: 'Fill in the element based on its limitations.',
      };
    case ElementStatusOption.Empty:
      return {
        className: classNames({
          'status--validation-failed': showIncompleteItemWarningsBeforePublish,
        }),
        text: 'Required',
        title: 'Fill in the element.',
      };
    default:
      throw Error(`[ElementStatus.tsx] Unknown element status option: "${option}"`);
  }
};

export const ElementStatus: React.FC<IElementStatusProps> = (props) => {
  const elementStatusDetails = getElementStatusDetails(props);

  return (
    <span
      className={classNames('status', elementStatusDetails.className)}
      title={elementStatusDetails.title}
      {...getDataUiElementAttribute(DataUiElement.ContentElementRequired)}
    >
      {elementStatusDetails.text}
    </span>
  );
};

ElementStatus.displayName = 'ElementStatus';
