import { useEffect } from 'react';
import { getDefaultSpaceDomain } from '../../../applications/environmentSettings/spaces/utils/spacesUtils.ts';
import { getContentItemPreviewUrlInfo } from '../../../applications/itemEditor/utils/previewUtils.ts';
import { onboardingNotificationClosed } from '../../actions/sharedActions.ts';
import { trackUserEventWithData } from '../../actions/thunks/trackUserEvent.ts';
import { OnboardingNotificationBar } from '../../components/OnboardingNotifications/OnboardingNotificationBar.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';

const Message = () => (
  <>🚀 Awesome! Your content is live. 🚀 Want to preview how it could look on a basic website?</>
);

export const ContentPublishingOnboardingBusinessUserNotification = () => {
  const previewUrl = useSelector((s) => {
    const { languages, collections } = s.data;
    const {
      editedContentItem,
      editedContentItemVariantElements,
      editedContentItemVariant,
      previewConfiguration,
    } = s.contentApp;

    return getContentItemPreviewUrlInfo(
      editedContentItem?.id,
      editedContentItem?.codename,
      editedContentItem?.editedContentItemTypeId,
      editedContentItem?.collectionId,
      editedContentItemVariant?.id.variantId,
      editedContentItemVariantElements,
      previewConfiguration,
      languages.defaultLanguage,
      languages.byId,
      collections.byId,
      previewConfiguration ? (getDefaultSpaceDomain(previewConfiguration)?.spaceId ?? null) : null,
    ).url;
  });

  const dispatch = useDispatch();
  const close = () => dispatch(onboardingNotificationClosed());
  const buttonClick = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--cta',
        info: 'content-publishing--business',
      }),
    );
    dispatch(onboardingNotificationClosed());
    if (previewUrl) {
      window.open(previewUrl, '_blank');
    }
  };

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.FeatureUsed, {
        feature: 'onboarding-notification--displayed',
        info: 'content-publishing--business',
      }),
    );
  }, []);

  return (
    <OnboardingNotificationBar
      buttonMessage="See your content"
      message={<Message />}
      onButtonClick={buttonClick}
      onClose={close}
    />
  );
};
