import { Action } from '../../../@types/Action.type.ts';
import {
  AssetLibrary_Asset_ArchiveCompleted,
  AssetLibrary_Asset_ArchiveFailed,
  AssetLibrary_Asset_ReplaceFailed,
  AssetLibrary_Asset_ReplaceFinished,
  AssetLibrary_Asset_ReplaceStarted,
  AssetLibrary_Assets_ArchiveCompleted,
  AssetLibrary_Assets_ArchiveStarted,
  AssetLibrary_Assets_AssignTermsCompleted,
  AssetLibrary_Assets_AssignTermsStarted,
  AssetLibrary_Assets_MoveBackToCollectionCompleted,
  AssetLibrary_Assets_MoveBackToCollectionStarted,
  AssetLibrary_Assets_MoveBackToFolderCompleted,
  AssetLibrary_Assets_MoveBackToFolderStarted,
  AssetLibrary_Assets_MoveToCollectionCompleted,
  AssetLibrary_Assets_MoveToCollectionStarted,
  AssetLibrary_Assets_MoveToFolderCompleted,
  AssetLibrary_Assets_MoveToFolderStarted,
  AssetLibrary_Assets_ReportUploadProgress,
  AssetLibrary_Assets_RestoreCompleted,
  AssetLibrary_Assets_RestoreStarted,
  AssetLibrary_Folders_ArchiveCompleted,
  AssetLibrary_Folders_ArchiveStarted,
  AssetLibrary_Folders_MovingBackCompleted,
  AssetLibrary_Folders_MovingBackFailed,
  AssetLibrary_Folders_MovingBackStarted,
  AssetLibrary_Folders_MovingCompleted,
  AssetLibrary_Folders_MovingFailed,
  AssetLibrary_Folders_MovingStarted,
  AssetLibrary_Folders_RestoreCompleted,
  AssetLibrary_Folders_RestoreStarted,
} from '../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import {
  ContentListing_BulkAction_ExecutionTakingTooLong,
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_CancelScheduledPublish_Started,
  ContentListing_DeleteItems_Finished,
  ContentListing_DeleteItems_Started,
  ContentListing_MoveToArchivedStep_Started,
  ContentListing_MoveToCollection_Failed,
  ContentListing_MoveToCollection_Finished,
  ContentListing_MoveToCollection_Started,
  ContentListing_PublishItems_Failed,
  ContentListing_PublishItems_Finished,
  ContentListing_PublishItems_Started,
  ContentListing_RestoreFromArchivedStep_Started,
  ContentListing_ScheduledPublishItems_Failed,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledPublishItems_Started,
  ContentListing_ScheduledUnpublishItems_Failed,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Started,
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UndoArchiveItems_Started,
  ContentListing_UndoPublishItems_Finished,
  ContentListing_UndoPublishItems_Started,
  ContentListing_UndoScheduledPublish_Finished,
  ContentListing_UndoScheduledPublish_Started,
  ContentListing_UnpublishItems_Failed,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UnpublishItems_Started,
  ContentListing_UpdateStandardStep_Started,
  ContentListing_UpdateWorkflowStep_Failed,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { LastCascadeAction } from '../../../applications/contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentType_Editor_DuplicatingFailed,
  ContentType_Editor_DuplicatingFinished,
  ContentType_Editor_DuplicatingStarted,
  ContentType_Listing_ArchiveContentTypesFinished,
  ContentType_Listing_ArchiveContentTypesStarted,
  ContentType_Listing_ResetOperationState,
  ContentType_Listing_RestoreContentTypesFinished,
  ContentType_Listing_RestoreContentTypesStarted,
} from '../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted,
  ContentTypeSnippet_Listing_ResetOperationState,
  ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished,
  ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted,
} from '../../../applications/contentModels/snippets/constants/snippetActionTypes.ts';
import {
  Taxonomy_Groups_Archived,
  Taxonomy_Groups_Archiving,
  Taxonomy_Groups_Restored,
  Taxonomy_Groups_Restoring,
} from '../../../applications/contentModels/taxonomy/constants/taxonomyActionTypes.ts';
import {
  Role_Delete_Failed,
  Role_Delete_Finished,
  Role_Delete_Started,
  Role_Restore_Finished,
  Role_Restore_Started,
} from '../../../applications/environmentSettings/roles/constants/rolesActionTypes.ts';
import {
  Spaces_Restore_Failed,
  Spaces_Restore_Started,
  Spaces_Restore_Succeeded,
} from '../../../applications/environmentSettings/spaces/constants/spacesActionTypes.ts';
import {
  Workflow_Delete_Failed,
  Workflow_Delete_Finished,
  Workflow_Delete_Started,
  Workflow_Restore_Finished,
  Workflow_Restore_Started,
} from '../../../applications/environmentSettings/workflow/constants/workflowActionTypes.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_AssignmentSubmittingStarted,
  Content_Editing_CancelScheduledPublishingFailed,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledPublishingStarted,
  Content_Editing_CancelScheduledUnpublishingFailed,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_CancelScheduledUnpublishingStarted,
  Content_Editing_DiscardNewVersionFailed,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_DiscardNewVersionStarted,
  Content_Editing_PublishContentItemVariantFailed,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_RestoreFromArchivedStepFinished,
  Content_Editing_RestoreFromArchivedStepStarted,
  Content_Editing_ScheduledPublishingFailed,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledPublishingStarted,
  Content_Editing_ScheduledUnpublishingFailed,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_ScheduledUnpublishingStarted,
  Content_Editing_UnpublishContentItemVariantFailed,
  Content_Editing_UnpublishContentItemVariantFinished,
  Content_Editing_UnpublishContentItemVariantStarted,
} from '../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeAction_UndoStarted,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { AssignmentSections } from '../../../applications/itemEditor/features/ContentItemEditing/constants/AssignmentSections.ts';
import {
  ContentItemEditing_Archiving_Failed,
  ContentItemEditing_Archiving_Finished,
  ContentItemEditing_Archiving_Started,
  ContentItemEditing_ConvertingContentComponent_Failed,
  ContentItemEditing_ConvertingContentComponent_Started,
  ContentItemEditing_CopyContentFromVariant_Failed,
  ContentItemEditing_CopyContentFromVariant_Finished,
  ContentItemEditing_CopyContentFromVariant_Started,
  ContentItemEditing_CreateNewVersion_Failed,
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_CreateNewVersion_Started,
  ContentItemEditing_CreatingLinkedItem,
  ContentItemEditing_Duplicating_Failed,
  ContentItemEditing_Duplicating_Finished,
  ContentItemEditing_Duplicating_Started,
  ContentItemEditing_ElementRefresh_Failed,
  ContentItemEditing_Refresh_Failed,
  ContentItemEditing_Refresh_Finished,
  ContentItemEditing_Refresh_Started,
} from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ContentEditing_MoveItemToCollection_Finished,
  ContentEditing_MoveItemToCollection_Started,
} from '../../../applications/itemEditor/features/MoveToCollectionDialog/constants/moveToCollectionDialogActionTypes.ts';
import {
  ContentItemEditing_NewVariant_Failed,
  ContentItemEditing_NewVariant_Finished,
  ContentItemEditing_NewVariant_Started,
} from '../../../applications/itemEditor/features/NewVariantWorkflowSelectionDialog/constants/newVariantActionTypes.ts';
import { getNotEmptyItemName } from '../../../applications/itemEditor/stores/utils/contentItemHelperMethods.ts';
import {
  ApiKeyDetail_Creating_Failed,
  ApiKeyDetail_Creating_Finished,
  ApiKeyDetail_Creating_Started,
  ApiKeyDetail_OldDapiRegeneration_Failed,
  ApiKeyDetail_OldDapiRegeneration_Finished,
  ApiKeyDetail_OldDapiRegeneration_Started,
  ApiKeyDetail_Regeneration_Failed,
  ApiKeyDetail_Regeneration_Finished,
  ApiKeyDetail_Regeneration_Started,
  ApiKeyDetail_RevokeAndRedirect_Finished,
  ApiKeyDetail_RevokeAndRedirect_Started,
  ApiKeyDetail_Revoke_Failed,
  ApiKeyDetail_Updating_Failed,
  ApiKeyDetail_Updating_Finished,
  ApiKeyDetail_Updating_Started,
} from '../../../applications/projectSettings/apiKeys/constants/apiKeysActionTypes.ts';
import {
  WebSpotlight_CreatePageItem_Started,
  WebSpotlight_LinkPageItems_Failed,
  WebSpotlight_LinkPageItems_Finished,
  WebSpotlight_LinkPageItems_Started,
} from '../../../applications/webSpotlight/constants/webSpotlightActionTypes.ts';
import {
  Archiving,
  ChangingWorkflow,
  ChangingWorkflowStep,
  Deleting,
  Discarding,
  Moving,
  Publishing,
  Removing,
  Restoring,
  SavingChanges,
  Scheduling,
  Unpublishing,
  Unscheduling,
} from '../../constants/itemActions.ts';
import { Shared_UiBlockingMessage_Dismissed } from '../../constants/sharedActionTypes.ts';
import { capitalizeFirstLetter, pluralizeWithCount } from '../../utils/stringUtils.ts';
import { UiBlockingMessage } from '../ISharedState.type.ts';

const LinkingContentLines = { textLines: ['Linking content items'] };
const ReplacingAssetTextLines = ['Replacing asset...'];

const initialState: UiBlockingMessage | null = null;

export function uiBlockingMessage(state = initialState, action: Action): UiBlockingMessage | null {
  switch (action.type) {
    case ContentListing_BulkAction_ExecutionTakingTooLong:
      return {
        textLines: [
          'That’s a lot of content.',
          'We’re still working on that,',
          'but it might take a while.',
        ],
      };
    case ContentListing_PublishItems_Started:
      return { textLines: [Publishing] };
    case Content_Editing_RestoreFromArchivedStepStarted:
      return { textLines: [Restoring] };
    case ContentListing_ScheduledPublishItems_Started:
      return { textLines: [Scheduling] };
    case ContentListing_ScheduledUnpublishItems_Started:
      return { textLines: [SavingChanges] };
    case ContentListing_UndoPublishItems_Started:
    case ContentListing_UnpublishItems_Started:
      return { textLines: [Unpublishing] };
    case ContentListing_UndoScheduledPublish_Started:
    case ContentListing_CancelScheduledPublish_Started:
      return { textLines: [Unscheduling] };
    case ContentListing_UpdateStandardStep_Started:
      return { textLines: [ChangingWorkflowStep] };
    case ContentListing_MoveToArchivedStep_Started:
      return { textLines: [Archiving] };
    case ContentEditing_MoveItemToCollection_Started:
    case ContentListing_MoveToCollection_Started:
      return { textLines: [Moving] };
    case ContentListing_MoveToCollection_Failed:
      return { textLines: [action.payload.errorMessage] };
    case ContentListing_RestoreFromArchivedStep_Started:
      return { textLines: [Restoring] };
    case ContentItemEditing_Archiving_Failed:
      return { textLines: ['Deleting failed. Refresh the page and try again.'] };
    case ContentItemEditing_ConvertingContentComponent_Started:
      return {
        dismissOnNavigation: true,
        textLines: ['Converting to content item… Remember to name it.'],
      };
    case ContentItemEditing_ConvertingContentComponent_Failed:
      return {
        textLines: ['Converting content component failed. Refresh the page and try again.'],
      };
    case ContentItemEditing_CopyContentFromVariant_Started:
      return { textLines: ['Copying content'] };
    case ContentItemEditing_CopyContentFromVariant_Failed:
      return { textLines: ['Copying content failed. Refresh the page and try again.'] };
    case ContentItemEditing_CreateNewVersion_Started:
      return { textLines: ['Preparing a draft of your content item'] };
    case ContentItemEditing_CreatingLinkedItem:
    case WebSpotlight_CreatePageItem_Started:
      return { dismissOnNavigation: true, textLines: ['Creating content item'] };
    case ContentItemEditing_Duplicating_Started:
      return { textLines: ['Duplicating content item'] };
    case ContentType_Editor_DuplicatingStarted:
      return { textLines: ['Duplicating content type'] };
    case ContentItemEditing_Duplicating_Failed:
    case ContentType_Editor_DuplicatingFailed:
      return { textLines: ['Duplicating failed. Refresh the page and try again.'] };
    case ContentItemEditing_NewVariant_Started:
      return { textLines: ['Creating new variant'] };
    case ContentItemEditing_Refresh_Started:
      return { textLines: ['Refreshing content'] };
    case ContentItemEditing_ElementRefresh_Failed:
      return {
        textLines: [
          'Refreshing the element failed. Reload the page to get the latest item version.',
        ],
      };

    case Content_Editing_UnpublishContentItemVariantStarted:
      return { textLines: [Unpublishing] };
    case Content_Editing_CancelScheduledPublishingStarted:
      return { textLines: [Unscheduling] };
    case Content_Editing_CancelScheduledUnpublishingStarted:
      return { textLines: [Removing] };
    case Content_Editing_PublishContentItemVariantStarted:
      return { textLines: [Publishing] };
    case Content_Editing_ScheduledPublishingStarted:
      return { textLines: [Scheduling] };
    case Content_Editing_ScheduledUnpublishingStarted:
      return { textLines: [SavingChanges] };
    case Content_Editing_DiscardNewVersionStarted:
      return { textLines: [Discarding] };
    case Content_Editing_AssignmentSubmittingStarted: {
      if (state?.textLines) {
        return state;
      }
      if (action.payload.submittingSection === AssignmentSections.WorkflowStep) {
        return { textLines: [ChangingWorkflowStep] };
      }
      if (action.payload.submittingSection === AssignmentSections.Workflow) {
        return { textLines: [ChangingWorkflow] };
      }

      return state;
    }

    case ContentEditing_CascadeModal_PublishingStarted: {
      const selectedItemsCount = action.payload.totalNumberOfItemsToBePublished - 1;

      return {
        textLines: [
          `Publishing ${getNotEmptyItemName(
            action.payload.editedItemName,
          )} and ${pluralizeWithCount('item', selectedItemsCount)}`,
        ],
        subtextLines: ['Please do not close this window until publishing is finished.'],
      };
    }
    case ContentEditing_CascadeAction_UndoStarted: {
      const selectedItemsCount = action.payload.totalNumberOfAffectedItems - 1;
      const actionName =
        action.payload.action === LastCascadeAction.Publish ? 'unpublishing' : 'unscheduling';

      return {
        textLines: [
          `${capitalizeFirstLetter(actionName)} of ${getNotEmptyItemName(
            action.payload.editedItemName,
          )} and ${pluralizeWithCount('item', selectedItemsCount)}`,
        ],
        subtextLines: [`Please do not close this window until ${actionName} is finished.`],
      };
    }

    case ContentEditing_CascadeModal_SchedulingStarted: {
      const selectedItemsCount = action.payload.totalNumberOfItemsToBeScheduled - 1;

      return {
        textLines: [
          `Scheduling ${getNotEmptyItemName(
            action.payload.editedItemName,
          )} and ${pluralizeWithCount('item', selectedItemsCount)}`,
        ],
        subtextLines: ['Please do not close this window until scheduling is finished.'],
      };
    }

    case ContentType_Listing_ArchiveContentTypesStarted:
    case ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsStarted:
    case ContentItemEditing_Archiving_Started:
    case AssetLibrary_Folders_ArchiveStarted:
    case ContentListing_DeleteItems_Started:
    case Role_Delete_Started:
    case Workflow_Delete_Started:
    case AssetLibrary_Assets_ArchiveStarted:
    case Taxonomy_Groups_Archiving:
      return { textLines: [Deleting] };

    case AssetLibrary_Asset_ReplaceStarted:
      return { textLines: ReplacingAssetTextLines, subtextLines: ['0 %'] };

    case AssetLibrary_Assets_ReportUploadProgress:
      // UploadProgress is triggered for both replace and addition of an asset, UI message is desired for the former only
      return state?.textLines === ReplacingAssetTextLines
        ? { ...state, subtextLines: [`${action.payload.percentUploaded} %`] }
        : state;

    case AssetLibrary_Assets_MoveToCollectionStarted:
    case AssetLibrary_Assets_MoveToFolderStarted:
    case AssetLibrary_Folders_MovingStarted:
      return { textLines: ['Moving'] };

    case AssetLibrary_Folders_MovingFailed:
      return { textLines: ['Moving failed. Refresh the page and try again.'] };

    case AssetLibrary_Folders_MovingBackStarted:
    case AssetLibrary_Assets_MoveBackToCollectionStarted:
    case AssetLibrary_Assets_MoveBackToFolderStarted:
      return { textLines: ['Moving back'] };

    case AssetLibrary_Folders_MovingBackFailed:
      return { textLines: ['Moving back failed. Refresh the page and try again.'] };

    case AssetLibrary_Assets_AssignTermsStarted:
      return { textLines: ['Assigning terms'] };

    case AssetLibrary_Assets_RestoreStarted:
    case ContentType_Listing_RestoreContentTypesStarted:
    case ContentListing_UndoArchiveItems_Started:
    case ContentTypeSnippet_Listing_RestoreContentTypeSnippetsStarted:
    case AssetLibrary_Folders_RestoreStarted:
    case Role_Restore_Started:
    case Workflow_Restore_Started:
    case Taxonomy_Groups_Restoring:
    case Spaces_Restore_Started:
      return { textLines: [Restoring] };

    case ApiKeyDetail_Regeneration_Started:
    case ApiKeyDetail_OldDapiRegeneration_Started:
      return { textLines: ['Generating new API key'] };

    case ApiKeyDetail_Creating_Started:
    case ApiKeyDetail_Updating_Started:
      return { textLines: [SavingChanges] };

    case ApiKeyDetail_RevokeAndRedirect_Started:
      return { textLines: ['Revoking'] };

    case ApiKeyDetail_Creating_Finished:
    case ApiKeyDetail_Creating_Failed:
    case ApiKeyDetail_Updating_Failed:
    case ApiKeyDetail_Updating_Finished:
    case ApiKeyDetail_Regeneration_Failed:
    case ApiKeyDetail_Regeneration_Finished:
    case ApiKeyDetail_OldDapiRegeneration_Failed:
    case ApiKeyDetail_OldDapiRegeneration_Finished:
    case ApiKeyDetail_Revoke_Failed:
    case ApiKeyDetail_RevokeAndRedirect_Finished:
    case AssetLibrary_Asset_ArchiveCompleted:
    case AssetLibrary_Asset_ArchiveFailed:
    case AssetLibrary_Asset_ReplaceFailed:
    case AssetLibrary_Asset_ReplaceFinished:
    case AssetLibrary_Assets_ArchiveCompleted:
    case AssetLibrary_Assets_AssignTermsCompleted:
    case AssetLibrary_Assets_MoveBackToCollectionCompleted:
    case AssetLibrary_Assets_MoveToCollectionCompleted:
    case AssetLibrary_Assets_MoveBackToFolderCompleted:
    case AssetLibrary_Assets_MoveToFolderCompleted:
    case AssetLibrary_Assets_RestoreCompleted:
    case AssetLibrary_Folders_ArchiveCompleted:
    case AssetLibrary_Folders_MovingBackCompleted:
    case AssetLibrary_Folders_MovingCompleted:
    case AssetLibrary_Folders_RestoreCompleted:
    case Content_Editing_AssignmentSubmittingFailed:
    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFailed:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFailed:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFailed:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFailed:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_RestoreFromArchivedStepFinished:
    case Content_Editing_ScheduledPublishingFailed:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFailed:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFailed:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFailed:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_MoveItemToCollection_Finished:
    case ContentItemEditing_Archiving_Finished:
    case ContentItemEditing_CopyContentFromVariant_Finished:
    case ContentItemEditing_CreateNewVersion_Failed:
    case ContentItemEditing_CreateNewVersion_Finished:
    case ContentItemEditing_Duplicating_Finished:
    case ContentItemEditing_Refresh_Finished:
    case ContentItemEditing_Refresh_Failed:
    case ContentItemEditing_NewVariant_Finished:
    case ContentItemEditing_NewVariant_Failed:
    case ContentListing_DeleteItems_Finished:
    case ContentListing_CancelScheduledPublish_Finished:
    case ContentListing_MoveToCollection_Finished:
    case ContentListing_PublishItems_Failed:
    case ContentListing_PublishItems_Finished:
    case ContentListing_ScheduledPublishItems_Failed:
    case ContentListing_ScheduledPublishItems_Finished:
    case ContentListing_ScheduledUnpublishItems_Failed:
    case ContentListing_ScheduledUnpublishItems_Finished:
    case ContentListing_UndoArchiveItems_Finished:
    case ContentListing_UndoPublishItems_Finished:
    case ContentListing_UndoScheduledPublish_Finished:
    case ContentListing_UnpublishItems_Failed:
    case ContentListing_UnpublishItems_Finished:
    case ContentListing_UpdateWorkflowStep_Failed:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentType_Editor_DuplicatingFinished:
    case ContentType_Listing_ArchiveContentTypesFinished:
    case ContentType_Listing_ResetOperationState:
    case ContentType_Listing_RestoreContentTypesFinished:
    case ContentTypeSnippet_Listing_ArchiveContentTypeSnippetsFinished:
    case ContentTypeSnippet_Listing_ResetOperationState:
    case ContentTypeSnippet_Listing_RestoreContentTypeSnippetsFinished:
    case Role_Delete_Failed:
    case Role_Delete_Finished:
    case Role_Restore_Finished:
    case Workflow_Delete_Failed:
    case Workflow_Delete_Finished:
    case Workflow_Restore_Finished:
    case Taxonomy_Groups_Archived:
    case Taxonomy_Groups_Restored:
    case Spaces_Restore_Succeeded:
    case Spaces_Restore_Failed:
    case Shared_UiBlockingMessage_Dismissed:
      return initialState;

    case WebSpotlight_LinkPageItems_Started:
      // Only set the message if the is no existing message in progress. It may happen when creating content item which linking is part of
      return state === initialState ? LinkingContentLines : state;

    case WebSpotlight_LinkPageItems_Finished:
    case WebSpotlight_LinkPageItems_Failed:
      // Only reset the messsage if the current message was initiated by linking action
      return state === LinkingContentLines ? initialState : state;

    default:
      return state;
  }
}
