import { Action } from '../../../../@types/Action.type.ts';
import { emptyNote } from '../../../../applications/itemEditor/models/contentItem/Assignment.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_Note_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

export function note(state = null, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.note;

    case ChangeWorkflowStep_Note_Changed: {
      const currentNote = state || emptyNote;
      return {
        ...currentNote,
        noteText: action.payload.newNote,
      };
    }

    default:
      return state;
  }
}
