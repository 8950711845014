import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { AssetLibraryEmptyState } from '../models/AssetLibraryEmptyStateEnum.ts';
import { isAssetLibraryVisibleFilterEmpty } from '../models/IAssetLibraryQuery.ts';

export const getAssetLibraryEmptyState = (state: IStore): AssetLibraryEmptyState => {
  const {
    assetLibraryApp: { query },
    data: {
      assetFolders: { loadingStatus: folderLoadingStatus, taxonomyGroup },
      assets: {
        listingAssets: { listingIds },
        loadingStatus: assetLoadingStatus,
      },
    },
  } = state;

  if (!listingIds.isEmpty()) {
    return AssetLibraryEmptyState.None;
  }

  if (query.searchPhrase && !isAssetLibraryVisibleFilterEmpty(query)) {
    return AssetLibraryEmptyState.NoAssetsFound_FilledSearchPhraseAndFilter;
  }

  if (query.searchPhrase) {
    return AssetLibraryEmptyState.NoAssetsFound_FilledSearchPhrase;
  }

  if (!isAssetLibraryVisibleFilterEmpty(query)) {
    return AssetLibraryEmptyState.NoAssetsFound_FilledFilter;
  }

  const isAssetLibraryLoaded =
    assetLoadingStatus === LoadingStatus.Loaded && folderLoadingStatus === LoadingStatus.Loaded;
  const noFoldersExist = !taxonomyGroup.terms.size;

  if (isAssetLibraryLoaded && noFoldersExist) {
    return AssetLibraryEmptyState.EmptyLibrary;
  }

  return AssetLibraryEmptyState.None;
};
