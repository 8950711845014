import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Toggle, ToggleStatus } from '@kontent-ai/component-library/Toggle';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import { ApiStatus } from '../../../../_shared/models/ApiStatus.ts';
import { getApiSwitchLabelText } from '../utils/getApiSwitchLabelText.ts';

export interface IApiStatusSwitchProps {
  readonly header: string;
  readonly isAvailable: boolean;
  readonly status: ApiStatus;
  readonly tooltipText?: string;
  readonly onChange: (newStatus: ApiStatus) => void;
  readonly renderFooter: () => ReactNode;
}

export const ApiStatusSwitch: React.FC<IApiStatusSwitchProps> = ({
  header,
  isAvailable,
  status,
  tooltipText,
  onChange,
  renderFooter,
}) => {
  const onChangeSwitchStatus = (newStatus: ToggleStatus) => {
    const newApiStatus = newStatus === 'on' ? ApiStatus.Enabled : ApiStatus.Disabled;
    onChange(newApiStatus);
  };

  return (
    <Stack spacing={Spacing.XL}>
      <Box typography={Typography.HeadlineMedium}>{header}</Box>
      <Toggle
        status={status === ApiStatus.Enabled ? 'on' : 'off'}
        onChange={(newStatus: ToggleStatus) => onChangeSwitchStatus(newStatus)}
        disabled={!isAvailable}
        labelText={getApiSwitchLabelText(status)}
        tooltipText={tooltipText}
        tooltipPlacement="bottom"
      />
      {renderFooter()}
    </Stack>
  );
};

ApiStatusSwitch.displayName = 'ApiStatusSwitch';
