import { ISelectItem, ISelectSection } from '../types.ts';

/**
 * Creates a new array populated with the results of calling a provided  callback function on every item in the calling array — including nested items.
 * @param items
 * @param callbackFn
 */
export const mapItems = <TItem extends ISelectItem<TItem>>(
  items: ReadonlyArray<ISelectSection<TItem> | TItem>,
  callbackFn: (item: ISelectSection<TItem> | TItem) => ISelectSection<TItem> | TItem,
): ReadonlyArray<ISelectSection<TItem> | TItem> => {
  return items.reduce<ReadonlyArray<ISelectSection<TItem> | TItem>>((result, item) => {
    const itemsToAppend = item.items?.length ? mapItems(item.items, callbackFn) : undefined;
    return [
      ...result,
      {
        ...callbackFn(item),
        ...(itemsToAppend?.length ? { items: itemsToAppend } : {}),
      } as ISelectSection<TItem> | TItem,
    ];
  }, []);
};
