import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { getViewableContentGroups } from '../../../_shared/utils/contentItemUtils.ts';
import { getEditedContentItemType } from './getEditedContentItemType.ts';

export function getAreAnyContentGroupsVisible(state: IStore): boolean {
  const editedContentItemType = getEditedContentItemType(state);
  return (
    !!editedContentItemType &&
    !getViewableContentGroups(editedContentItemType, state.contentApp.editorUi).isEmpty()
  );
}
