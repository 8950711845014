import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ContentType_Listing_InitFinished,
  ContentType_Listing_InitStarted,
} from '../../constants/contentTypesActionTypes.ts';
import { contentTypeListingRouteEntered } from '../contentTypesActions.ts';

interface IDeps {
  readonly loadContentTypesData: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWebSpotlightConfiguration: (
    projectId: Uuid,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

export const started = () => ({ type: ContentType_Listing_InitStarted }) as const;
export const finished = () => ({ type: ContentType_Listing_InitFinished }) as const;
export type InitContentTypeListingActionTypes =
  | ReturnType<typeof started>
  | ReturnType<typeof finished>;

export const createInitContentTypeListingAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(started());

    dispatch(contentTypeListingRouteEntered());
    await Promise.all([
      dispatch(deps.loadContentTypesData(abortSignal)),
      dispatch(
        deps.loadWebSpotlightConfiguration(getState().sharedApp.currentProjectId, abortSignal),
      ),
    ]);

    dispatch(finished());
  };
