import React from 'react';
import { EmailLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/EmailLinkProperties.type.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { EmailLinkDialog } from './EmailLinkDialog.tsx';

interface IEmailLinkProps {
  readonly children: ReadonlyArray<React.ReactNode>;
  readonly isPlaceholder: boolean;
  readonly linkText: string;
  readonly onCancel: () => void;
  readonly onInsert: (values: EmailLinkProperties) => void;
}

export const NewEmailLink: React.FC<IEmailLinkProps> = (props) => {
  const { isPlaceholder, linkText, onCancel, onInsert } = props;

  return (
    <InlineContentWithDialog
      renderContent={(ref) => (
        <a className="rte__link rte__link--is-edited" href="#" ref={ref}>
          {props.children}
        </a>
      )}
      renderDialog={() => (
        <EmailLinkDialog
          linkText={isPlaceholder ? '' : linkText}
          onCancel={onCancel}
          onUpsert={onInsert}
        />
      )}
    />
  );
};

NewEmailLink.displayName = 'NewEmailLink';
