import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { SpacesFilterStorage } from '../../../../localStorages/spacesFilterStorage.ts';

export const useSpacesFilter = (projectId: Uuid, onFilter: (searchPhrase: string) => void) => {
  const [inputSearchPhrase, setInputSearchPhrase] = useState('');
  const [filterSearchPhrase, setFilterSearchPhrase] = useState('');

  const onFilterRef = useRef(onFilter);
  onFilterRef.current = onFilter;

  useEffect(() => {
    const { searchPhrase } = SpacesFilterStorage.load(projectId);
    setInputSearchPhrase(searchPhrase);
    setFilterSearchPhrase(searchPhrase);
    onFilterRef.current(searchPhrase);
  }, [projectId]);

  const setFilterSearchPhraseDebounced = useDebouncedCallback(setFilterSearchPhrase, 100);

  const searchSpaces = useCallback(
    (searchPhrase: string) => {
      setInputSearchPhrase(searchPhrase);
      setFilterSearchPhraseDebounced(searchPhrase);
      onFilterRef.current(searchPhrase);

      const filter = SpacesFilterStorage.load(projectId);
      SpacesFilterStorage.save({ ...filter, searchPhrase }, projectId);
    },
    [projectId, setFilterSearchPhraseDebounced],
  );

  return {
    inputSearchPhrase,
    filterSearchPhrase,
    searchSpaces,
  };
};
