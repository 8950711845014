import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getEditedContentItemVariantId } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { itemRefreshedAfterLinkedEntityChange } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
}

export const createRefreshItemAfterLinkedEntityChange =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const editedContentItemId = getEditedContentItemVariantId(state);

    if (!editedContentItemId) {
      return;
    }

    const { itemId, variantId } = editedContentItemId;
    const filter = state.contentApp.listingUi.filter;
    const usedSearchMethod = state.data.listingContentItems.usedSearchMethod;

    const itemWithVariant = await deps.contentItemRepository.getItemWithVariant(
      itemId,
      variantId,
      abortSignal,
    );

    dispatch(
      itemRefreshedAfterLinkedEntityChange({
        itemWithVariant,
        filter,
        usedSearchMethod,
      }),
    );
  };
