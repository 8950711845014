import { endsWithWhitespace, startsWithWhitespace } from '../stringUtils.ts';

const TrimStartErrorMessage = 'Remove extra space at the start of the text.';
const TrimEndErrorMessage = 'Remove extra space at the end of the text.';

export const startsOrEndsWithWhitespaceValidation = (value: string): string | undefined => {
  if (startsWithWhitespace(value)) {
    return TrimStartErrorMessage;
  }
  return endsWithWhitespace(value) ? TrimEndErrorMessage : undefined;
};
