import { usePrevious } from '@kontent-ai/hooks';
import { ContentState } from 'draft-js';
import { useRef } from 'react';
import { ActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { preserveContentBlockKeys } from '../../../utils/general/editorContentUtils.ts';

export const useResultWithPreservedBlockKeys = (
  result: ActionResult<ContentState>,
): ActionResult<ContentState> => {
  const content = result.content;
  const usePrevResult = usePrevious(content);
  const contentWithPreservedBlockKeys = useRef<ContentState | null>(null);

  if (content !== usePrevResult) {
    contentWithPreservedBlockKeys.current =
      content && contentWithPreservedBlockKeys.current
        ? preserveContentBlockKeys(content, contentWithPreservedBlockKeys.current)
        : content;
  }

  return {
    ...result,
    content: contentWithPreservedBlockKeys.current,
  };
};
