import { Button } from '@kontent-ai/component-library/Button';
import { useContext } from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { Status } from '../constants/filters.ts';
import { InnovationLabAppFilterContext } from './InnovationLabAppFilterContext.tsx';

const StatusToMessage: ReadonlyRecord<Status, string> = {
  [Status.All]: '',
  [Status.Active]: 'It seems no features are active.',
  [Status.Inactive]: 'It seems all features are already active.',
};

export const InnovationLabFiltersEmptyState = () => {
  const { status, clearFilter } = useContext(InnovationLabAppFilterContext);

  return (
    <EmptyState>
      <EmptyState.Title>We couldn’t find anything here.</EmptyState.Title>
      <EmptyState.Content>
        {StatusToMessage[status]} Tweak your filters to fill this space.
      </EmptyState.Content>
      <EmptyState.Footer>
        <Button buttonStyle="primary" onClick={clearFilter}>
          Clear filters
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};
