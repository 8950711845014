import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { AiActionName } from '../AiActionName.type.ts';
import { ItemVariantIdOperationInput, StringOperationInput } from '../AiServerModels.input.type.ts';
import {
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  isCumulatedDraftJsOperationResponseMessage,
  isCumulatedStringOperationResponseMessage,
} from '../AiServerModels.result.ts';
import { InlinePlainTextInstructionResponseMessage } from './AiServerModels.inlinePlainTextInstruction.ts';
import { InlineRichTextInstructionResponseMessage } from './AiServerModels.inlineRichTextInstruction.ts';

export type InlineInstructionActionName =
  | AiActionName.PlainTextInlineInstruction
  | AiActionName.RichTextInlineInstruction;

export type InlineInstructionResponseMessage =
  | InlinePlainTextInstructionResponseMessage
  | InlineRichTextInstructionResponseMessage;

export const isInlineInstructionMessage = (
  message: OperationResponseMessage,
): message is
  | InlinePlainTextInstructionResponseMessage
  | InlineRichTextInstructionResponseMessage
  | ErrorOperationResponseMessage =>
  isCumulatedStringOperationResponseMessage(message) ||
  isCumulatedDraftJsOperationResponseMessage(message) ||
  isErrorOperationMessage(message);

export type InlineInstructionBaseInputs = {
  readonly elementId: StringOperationInput;
  readonly elementName: StringOperationInput | null;
  readonly instruction: StringOperationInput;
  readonly itemName: StringOperationInput | null;
  readonly itemVariantId: ItemVariantIdOperationInput;
  readonly languageCodename: StringOperationInput | null;
  readonly languageName: StringOperationInput | null;
};

export type InlineInstructionBaseInputParams = {
  readonly elementId: Uuid;
  readonly elementName: string | null;
  readonly instruction: string;
  readonly itemName: string | null;
  readonly itemVariantId: ContentItemId;
  readonly languageCodename: string | null;
  readonly languageName: string | null;
};

export const getInlineInstructionBaseInputs = ({
  elementId,
  elementName,
  instruction,
  itemName,
  itemVariantId,
  languageCodename,
  languageName,
}: InlineInstructionBaseInputParams): InlineInstructionBaseInputs => ({
  elementId: {
    type: 'string',
    value: elementId,
  },
  elementName: {
    type: 'string',
    value: elementName ?? '',
  },
  instruction: {
    type: 'string',
    value: instruction,
  },
  itemName: {
    type: 'string',
    value: itemName ?? '',
  },
  itemVariantId: {
    type: 'item-variant-id',
    value: {
      itemId: itemVariantId.itemId,
      variantId: itemVariantId.variantId,
    },
  },
  languageCodename: {
    type: 'string',
    value: languageCodename ?? '',
  },
  languageName: {
    type: 'string',
    value: languageName ?? '',
  },
});
