import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { IAssetTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import { IAssetItemElement } from '../../../../models/contentItemElements/AssetItemElement.ts';
import { isAssetElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { areIdsEquivalent } from '../../utils/itemEditingUtils.ts';
import { IAssetElementValueChangedAction } from './assetElementValueChanged.ts';

interface IChangeAssetElementValueActionDependencies {
  readonly assetElementValueChanged: IAssetElementValueChangedAction;
}

export const changeAssetElementValueActionCreator =
  (deps: IChangeAssetElementValueActionDependencies) =>
  (
    contentItemId: ContentItemId,
    typeElement: IAssetTypeElement,
    modifier: (elementData: IAssetItemElement) => IAssetItemElement,
  ): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: { editedContentItemVariant, editedContentItemVariantElements },
    } = state;

    if (
      !editedContentItemVariant ||
      !areIdsEquivalent(contentItemId, editedContentItemVariant.id)
    ) {
      return;
    }

    const elementData = getElementById(typeElement.elementId, editedContentItemVariantElements);
    if (!isAssetElement(elementData)) {
      return;
    }

    const newElementData = modifier(elementData);
    dispatch(deps.assetElementValueChanged(contentItemId, newElementData, typeElement));
  };
