import { Action } from '../../../../../@types/Action.type.ts';
import { ContentModels_TypeEditor_FirstInvalidElement_Focused } from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import { ContentTypeSnippet_Editor_FocusFirstInvalidElement } from '../../constants/snippetActionTypes.ts';

export function firstInvalidElementId(
  state: Uuid | undefined | null = null,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case ContentTypeSnippet_Editor_FocusFirstInvalidElement: {
      return action.payload.elementId;
    }

    case ContentModels_TypeEditor_FirstInvalidElement_Focused: {
      return null;
    }

    default:
      return state;
  }
}
