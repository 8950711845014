import { combineReducers } from '@reduxjs/toolkit';
import { uiBlockingMessage } from './internalReducers/UIBlockingMessage.ts';
import { appInstanceId } from './internalReducers/appInstanceId.ts';
import { areUserPropertiesLoaded } from './internalReducers/areUserPropertiesLoaded.ts';
import { availableProjectLocations } from './internalReducers/availableProjectLocations.ts';
import { breadcrumbs } from './internalReducers/breadcrumbs.ts';
import { collapsedTaxonomyTermIds } from './internalReducers/collapsedTaxonomyTermIds.ts';
import { currentMasterEnvironmentId } from './internalReducers/currentMasterEnvironmentId.ts';
import { currentProjectContainerId } from './internalReducers/currentProjectContainerId.ts';
import { currentProjectId } from './internalReducers/currentProjectId.ts';
import { fallbacksForLinkedContentStatus } from './internalReducers/fallbacksForLinkedContentStatus.ts';
import { generalProperties } from './internalReducers/generalProperties.ts';
import { isLoggedIn } from './internalReducers/isLoggedIn.ts';
import { isObjectNewlyCreated } from './internalReducers/isObjectNewlyCreated.ts';
import { isOnline } from './internalReducers/isOnline.ts';
import { modalDialog } from './internalReducers/modalDialog.ts';
import { projectFeatureFlags } from './internalReducers/projectFeatureFlags.ts';
import { projectProperties } from './internalReducers/projectProperties.ts';
import { returnAutoScrollTargetByPath } from './internalReducers/returnAutoScrollTargetByPath.ts';
import { scrollIdByPath } from './internalReducers/scrollIdByPath.ts';
import { selectedLanguage } from './internalReducers/selectedLanguage.ts';
import { sidebarStatus } from './internalReducers/sidebarStatus.ts';
import { signalRConnection } from './internalReducers/signalRConnection.ts';
import { smartLinkCommand } from './internalReducers/smartLinkCommand.ts';
import { subscriptionProperties } from './internalReducers/subscriptionProperties.ts';
import { uiPreferences } from './internalReducers/uiPreferences.ts';
import { userProperties } from './internalReducers/userProperties.ts';
import { notificationBar } from './notificationBarReducer.ts';

export const sharedStateReducer = combineReducers({
  appInstanceId,
  areUserPropertiesLoaded,
  availableProjectLocations,
  breadcrumbs,
  collapsedTaxonomyTermIds,
  currentMasterEnvironmentId,
  currentProjectContainerId,
  currentProjectId,
  fallbacksForLinkedContentStatus,
  generalProperties,
  isLoggedIn,
  isObjectNewlyCreated,
  isOnline,
  modalDialog,
  notificationBar,
  projectProperties,
  projectFeatureFlags,
  subscriptionProperties,
  returnAutoScrollTargetByPath,
  scrollIdByPath,
  selectedLanguage,
  sidebarStatus,
  signalRConnection,
  smartLinkCommand,
  uiBlockingMessage,
  uiPreferences,
  userProperties,
});
