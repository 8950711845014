import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { IForwardedRefProps, forwardRef } from '../utils/forwardedRefProps.tsx';

export enum FeatureState {
  Beta = 'Beta',
  New = 'New',
}

type Props = IForwardedRefProps<HTMLDivElement> & {
  readonly featureState: FeatureState;
  readonly tooltipText?: string;
};

const FeatureStateTag: React.FC<Props> = ({ featureState, forwardedRef, tooltipText }) => (
  <Tooltip placement="top-start" tooltipText={tooltipText}>
    <span className="beta-tag" ref={forwardedRef}>
      {featureState}
    </span>
  </Tooltip>
);

FeatureStateTag.displayName = 'FeatureStateTag';

const FeatureStateTagRFC = forwardRef(FeatureStateTag);
export { FeatureStateTagRFC as FeatureStateTag };
