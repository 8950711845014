import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  DeletingStatusMessage,
  SavedStatusMessage,
  SavingStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

const getAssetSavingStatusMessage = (state: IStore): StatusMessage => {
  const { editedAsset, isEditedAssetBeingSaved, isEditedAssetModified } =
    state.assetLibraryApp.assetUiEditor;

  if (isEditedAssetModified) {
    return UnsavedStatusMessage;
  }

  if (!!editedAsset && editedAsset._isBeingDeleted) {
    return DeletingStatusMessage;
  }

  return isEditedAssetBeingSaved ? SavingStatusMessage : SavedStatusMessage;
};

export const AssetSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getAssetSavingStatusMessage,
);
