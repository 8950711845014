import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_Succeeded,
  ProjectOverview_Page_Left,
} from '../../../constants/projectOverviewActionTypes.ts';

const initialState: Immutable.Set<Uuid> = Immutable.Set();

const isDelayed = ({ variant }: IListingContentItem, now: Date): boolean =>
  !!variant && !!variant.assignment.due && !(new Date(variant.assignment.due) > now);

export const delayedItemsIds = (
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> => {
  switch (action.type) {
    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      return Immutable.Set(
        action.payload.contentItems
          .filter((item) => isDelayed(item, action.payload.now))
          .map((itemVariant) => itemVariant.item.id),
      );
    }

    case ProjectOverview_Page_Left: {
      return initialState;
    }

    default:
      return state;
  }
};
