import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { Subscription_Balance_Loaded } from '../../constants/actionTypes.ts';

interface ILoadSubscriptionBalanceDependencies {
  readonly subscriptionRepository: Pick<ISubscriptionRepository, 'getSubscriptionBalance'>;
}

export const subscriptionBalanceLoaded = (balance: number) =>
  ({
    type: Subscription_Balance_Loaded,
    payload: {
      balance,
    },
  }) as const;

export type LoadSubscriptionBalanceActionsType = ReturnType<typeof subscriptionBalanceLoaded>;

export const loadSubscriptionBalanceCreator =
  (dependencies: ILoadSubscriptionBalanceDependencies) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const balance = await dependencies.subscriptionRepository.getSubscriptionBalance(
      subscriptionId,
      abortSignal,
    );
    dispatch(subscriptionBalanceLoaded(balance));
  };
