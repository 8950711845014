import {
  ICustomAppCreateRequestServerModel,
  ICustomAppResponseServerModel,
  ICustomAppUpdateRequestServerModel,
} from '../../../repositories/serverModels/ICustomAppRepository.type.ts';

const DefaultCustomAppId: Uuid = '00000000-0000-0000-0000-000000000000';

export type CustomApp = {
  readonly id: Uuid;
  readonly name: string;
  readonly codename: string;
  readonly sourceUrl: string;
  readonly allowedRoles: UuidArray;
  readonly config: string;
};

export const createCustomAppDomainModel = (
  rawCustomApp: ICustomAppResponseServerModel,
): CustomApp => ({
  id: rawCustomApp.id,
  name: rawCustomApp.name,
  codename: rawCustomApp.codeName,
  sourceUrl: rawCustomApp.sourceUrl,
  allowedRoles: rawCustomApp.allowedRoles ?? [],
  config: rawCustomApp.config,
});

export const createCustomAppCreateServerModel = (
  customApp: CustomApp,
): ICustomAppCreateRequestServerModel => ({
  name: customApp.name,
  sourceUrl: customApp.sourceUrl,
  allowedRoles: customApp.allowedRoles ?? [],
  config: customApp.config,
});

export const createCustomAppUpdateServerModel = (
  customApp: CustomApp,
): ICustomAppUpdateRequestServerModel => ({
  id: customApp.id,
  name: customApp.name,
  codeName: customApp.codename,
  sourceUrl: customApp.sourceUrl,
  allowedRoles: customApp.allowedRoles ?? [],
  config: customApp.config,
});

export const emptyCustomApp: CustomApp = {
  id: DefaultCustomAppId,
  name: '',
  codename: '',
  sourceUrl: '',
  allowedRoles: [],
  config: '',
};
