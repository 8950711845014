import { ICustomItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICustomItemElement } from '../../models/contentItemElements/CustomItemElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export const customElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
  ICustomItemElementServerModel,
  ICustomItemElement
> = (serverElement) => {
  const { elementId, value, searchableValue, lastModifiedBy, lastModifiedAt } = serverElement;

  return {
    elementId,
    type: ElementType.Custom,
    value,
    searchableValue,
    lastModifiedBy: lastModifiedBy ? lastModifiedBy : null,
    lastModifiedAt: lastModifiedAt ? lastModifiedAt : null,
  };
};

export const customElementServerModelConverter: ISimpleItemElementServerModelConverter<
  ICustomItemElement,
  ICustomItemElementServerModel
> = (element) => {
  const { elementId, value, searchableValue, lastModifiedBy, lastModifiedAt } = element;

  return {
    elementId,
    type: ElementType.Custom,
    value,
    searchableValue,
    lastModifiedBy,
    lastModifiedAt,
  };
};
