import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';

interface ITrialPlanBar {
  readonly subscription: ISubscription;
  readonly getDaysToDate: (dateTime: DateTimeStamp) => number;
  readonly getPlansSelectionPath: () => string;
}

export const TrialPlanBar: FC<ITrialPlanBar> = ({
  subscription: { currentPlan, isActive },
  getDaysToDate,
  getPlansSelectionPath,
}) => {
  const daysLeft = getDaysToDate(currentPlan.endAt);

  const activeTrial = (
    <>
      {daysLeft > 1 ? (
        <h2 className="current-usage__section-title">{daysLeft} days left in your free trial</h2>
      ) : (
        <h2 className="current-usage__section-title">Your trial expires today</h2>
      )}
      <p className="current-usage__section-paragraph">
        During your free trial, we won’t charge you for anything.
      </p>
    </>
  );

  const expiredTrial = (
    <>
      <h2 className="current-usage__section-title">Your trial has come to an end</h2>
      <p className="current-usage__section-paragraph">
        Below is your usage during your free trial.
      </p>
    </>
  );

  const changePlanButton = (
    <Link className="u-no-text-decoration" to={getPlansSelectionPath()}>
      <Button
        buttonStyle="primary"
        size="medium"
        {...getDataUiActionAttribute(DataUiAction.ChangePlan)}
      >
        {isActive ? 'Select plan now' : 'Select your plan'}
      </Button>
    </Link>
  );

  return (
    <section className="current-usage__section">
      <div className="current-usage__plan-overview-box">
        {isActive ? activeTrial : expiredTrial}
        <div className="current-usage__section-redirect">{changePlanButton}</div>
      </div>
    </section>
  );
};
