import { useContext } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  IItemElementsContextValue,
  ItemElementsContext,
} from '../../itemEditor/features/ContentComponent/context/ItemElementsContext.tsx';
import { RichTextItemElementContext } from '../../itemEditor/features/ContentItemEditing/context/RichTextItemElementContext.tsx';
import { getContentComponent } from '../../itemEditor/features/ContentItemEditing/utils/contentComponentUtils.ts';
import { IContentComponent } from '../../itemEditor/models/contentItem/ContentComponent.ts';

// NOTE: Content components can be both in top level item and in expanded items
// That is why we use the closest context to lookup for them
export const useContentComponent = (
  contentComponentId: Uuid | null,
  sourceContext?: React.Context<IItemElementsContextValue>,
): IContentComponent | null => {
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);
  const { elements: contextElements } = useContext(sourceContext ?? ItemElementsContext);
  const fallbackToEditedItem = !sourceContext;
  const contentComponent = useSelector((state) => {
    if (!rootRichTextElementId || !contentComponentId) {
      return null;
    }
    const sourceElements =
      contextElements ??
      (fallbackToEditedItem ? state.contentApp.editedContentItemVariantElements : null);

    return sourceElements
      ? getContentComponent(sourceElements, rootRichTextElementId, contentComponentId)
      : null;
  });

  return contentComponent;
};
