import { EditorState } from 'draft-js';
import { IValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { isString } from '../../../../_shared/utils/stringUtils.ts';
import { getCharacterCount } from '../../../richText/plugins/textApi/api/editorTextUtils.ts';
import { ElementError, ElementErrorResult, emptyElementErrorResult } from './types/Errors.ts';

interface IParams {
  readonly value: EditorState | string;
}

const invalidElementResult: ElementErrorResult = {
  errors: [ElementError.TextTooLong],
};

export const createTextElementErrorChecker =
  (validationConstants: IValidationConstants) =>
  ({ value }: IParams): ElementErrorResult => {
    const characterCount = isString(value)
      ? value.length
      : getCharacterCount(value.getCurrentContent());
    const isValid = characterCount <= validationConstants.SimpleTextElementValueMaxLength;

    return isValid ? emptyElementErrorResult : invalidElementResult;
  };
