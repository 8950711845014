import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_SubscriptionUsageLoaded } from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';
import { ISubscriptionUsage } from '../../../../applications/subscriptionManagement/shared/models/SubscriptionUsage.ts';

const initialState: Immutable.Map<Uuid, ISubscriptionUsage> = Immutable.Map();

export const subscriptionUsages = (
  state: Immutable.Map<Uuid, ISubscriptionUsage> = initialState,
  action: Action,
): Immutable.Map<Uuid, ISubscriptionUsage> => {
  switch (action.type) {
    case Subscription_SubscriptionUsageLoaded: {
      const subscriptionId = action.payload.subscriptionId;
      const subscriptionUsage = action.payload.subscriptionUsage;
      return state.set(subscriptionId, subscriptionUsage);
    }

    default:
      return state;
  }
};
