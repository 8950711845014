import { ISelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { pluralizeWithoutCount } from '../../../../../../_shared/utils/stringUtils.ts';
import { ICollection } from '../../../../../../data/models/collections/Collection.ts';
import { ISpace } from '../../../../../../data/models/space/space.ts';

export interface ICollectionSelectItem extends ISelectItem<ICollectionSelectItem> {}
type SelectSectionBase = Pick<ISelectSection<ICollectionSelectItem>, 'id' | 'label'>;

export const suggestedCollectionsSectionBase = (countOfCollections: number): SelectSectionBase => ({
  id: 'suggested-collection',
  label: pluralizeWithoutCount('Suggested collection', countOfCollections),
});
export const allCollectionsSectionBase: SelectSectionBase = {
  id: 'all-collections',
  label: 'All collections',
};

const toSelectItem = (collection: ICollection): ICollectionSelectItem => ({
  id: collection.id,
  label: collection.name,
  type: 'item',
});

const toSelectSection = (
  sectionBase: SelectSectionBase,
  collections: ReadonlyArray<ICollection>,
): ISelectSection<ICollectionSelectItem> => ({
  ...sectionBase,
  type: 'section',
  items: collections.map(toSelectItem),
});

export const getUniqueCollectionIdsFromSpaces = (
  spaces: ReadonlyArray<ISpace>,
): ReadonlySet<Uuid> => new Set(spaces.flatMap((space) => space.collectionIds));

export const getCollectionOptions = (
  spaces: ReadonlyArray<ISpace>,
  collections: ReadonlyArray<ICollection>,
  areSpacesInCollectionsEnabled: boolean,
): ReadonlyArray<ISelectSection<ICollectionSelectItem> | ICollectionSelectItem> => {
  const collectionIdsFromSpaces = getUniqueCollectionIdsFromSpaces(spaces);

  if (
    !areSpacesInCollectionsEnabled ||
    collectionIdsFromSpaces.size === 0 ||
    collectionIdsFromSpaces.size >= collections.length
  ) {
    return collections.map(toSelectItem);
  }

  const suggestedCollections = collections.filter((collection) =>
    collectionIdsFromSpaces.has(collection.id),
  );
  const allCollections = collections.filter(
    (collection) => !collectionIdsFromSpaces.has(collection.id),
  );

  return [
    toSelectSection(
      suggestedCollectionsSectionBase(suggestedCollections.length),
      suggestedCollections,
    ),
    toSelectSection(allCollectionsSectionBase, allCollections),
  ];
};
