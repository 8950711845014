import { IconName } from '@kontent-ai/component-library/Icons';
import { shareIconName } from '../../../../../../_shared/constants/iconConventions.ts';
import { Color } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionBadge.tsx';
import {
  DataUiAction,
  DataUiWorkflowAction,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiWorkflowActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingAction } from '../../models/EditingAction.ts';

export enum ActionType {
  Destructive = 'Destructive',
  Important = 'Important',
  Normal = 'Normal',
}

export type StaticActionDetail = {
  readonly actionType: ActionType;
  readonly badgeColor?: Color;
  readonly caption: string;
  readonly dataUiAttribute?: ObjectWithDataAttribute;
  readonly iconName: IconName;
  readonly isDisabled?: boolean;
  readonly isSubmenu?: boolean;
  readonly subMenuCaption?: string;
  readonly tooltip?: string;
};

type StaticActionDetails = { readonly [key in keyof typeof EditingAction]: StaticActionDetail };

const isSubmenu = true;
const isDisabled = true;

const previewDetail: StaticActionDetail = {
  actionType: ActionType.Important,
  caption: 'Preview',
  dataUiAttribute: getDataUiActionAttribute(DataUiAction.OpenPreview),
  iconName: 'Eye',
};

const changeCollectionDetail: StaticActionDetail = {
  actionType: ActionType.Normal,
  caption: 'Change collection',
  dataUiAttribute: getDataUiActionAttribute(DataUiAction.OpenCollectionSelection),
  iconName: 'Drawers',
};

const deleteDetail: StaticActionDetail = {
  actionType: ActionType.Destructive,
  caption: 'Delete',
  dataUiAttribute: getDataUiActionAttribute(DataUiAction.Delete),
  iconName: 'Bin',
};

const noDetail: StaticActionDetail = {
  actionType: ActionType.Normal,
  caption: 'never',
  iconName: 'Bin',
};

export const staticActionDetails: StaticActionDetails = {
  [EditingAction.addTask]: {
    actionType: ActionType.Normal,
    caption: 'Add task',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.AddTask),
    iconName: 'CbCheck',
  },
  [EditingAction.assignPeople]: {
    actionType: ActionType.Normal,
    caption: 'Assign contributors',
    dataUiAttribute: getDataUiWorkflowActionAttribute(
      DataUiWorkflowAction.OpenContributorsSelection,
    ),
    iconName: 'Users',
  },
  [EditingAction.changeCollection]: changeCollectionDetail,
  [EditingAction.changeDueDate]: {
    actionType: ActionType.Normal,
    caption: 'Change due date',
    dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.EditDueDate),
    iconName: 'Calendar',
  },
  [EditingAction.changeNote]: {
    actionType: ActionType.Normal,
    caption: 'Change note',
    dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.EditNote),
    iconName: 'RectangleParagraph',
  },
  [EditingAction.changeStep]: {
    actionType: ActionType.Normal,
    caption: 'Change workflow step',
    iconName: 'ArrowDoubleRight',
  },
  [EditingAction.changeWorkflow]: {
    actionType: ActionType.Normal,
    caption: 'Change workflow',
    dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.ChangeWorkflow),
    iconName: 'SchemePathCircles',
  },
  [EditingAction.closeComponents]: {
    actionType: ActionType.Normal,
    caption: 'Collapse components',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.CollapseAllComponents),
    iconName: 'Collapse',
  },
  [EditingAction.closeInlineComments]: {
    actionType: ActionType.Normal,
    badgeColor: Color.Red,
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.HideComments),
    caption: 'Hide comments',
    iconName: 'BubbleHide',
  },
  [EditingAction.compareVersions]: {
    actionType: ActionType.Normal,
    caption: 'Version history',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.CompareVersions),
    iconName: 'Clock',
  },
  [EditingAction.copyFromLanguage]: {
    actionType: ActionType.Normal,
    caption: 'Copy from language',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.CopyFromLanguage),
    iconName: 'Translate',
    isSubmenu,
  },
  [EditingAction.deleteItem]: deleteDetail,
  [EditingAction.demonstrateDisabledPreviewItem]: {
    ...previewDetail,
    isDisabled,
  },
  [EditingAction.disabledTranslateVariant]: {
    actionType: ActionType.Normal,
    caption: 'Translate with AI',
    isDisabled,
    iconName: 'KopilotAi',
  },
  [EditingAction.demonstratePublishPreventsDeletingItem]: {
    ...deleteDetail,
    tooltip: 'This item has a published version.',
    isDisabled,
  },
  [EditingAction.demonstrateWebSpotlightPreventsDeletingItem]: {
    ...deleteDetail,
    tooltip: 'This item is used by Web Spotlight and cannot be deleted.',
    isDisabled,
  },
  [EditingAction.discardNewVersion]: {
    actionType: ActionType.Destructive,
    caption: 'Discard version',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.Discard),
    iconName: 'TwoRectanglesStackedTimes',
  },
  [EditingAction.duplicateItemWithContent]: {
    actionType: ActionType.Normal,
    caption: 'Including content',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.Duplicate),
    iconName: 'DocCopy',
  },
  [EditingAction.duplicateItemWithoutContent]: {
    actionType: ActionType.Normal,
    caption: 'Empty',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.CreateNew),
    iconName: 'DocPlus',
  },
  [EditingAction.none]: noDetail,
  [EditingAction.openComponents]: {
    actionType: ActionType.Normal,
    caption: 'Expand components',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.ExpandAllComponents),
    iconName: 'Expand',
  },
  [EditingAction.openDiscussions]: {
    actionType: ActionType.Normal,
    badgeColor: Color.Red,
    caption: 'Show discussion',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.ShowDiscussion),
    iconName: 'Bubbles',
  },
  [EditingAction.openInlineComments]: {
    actionType: ActionType.Normal,
    badgeColor: Color.Red,
    caption: 'Show comments',
    iconName: 'BubbleShow',
  },
  [EditingAction.openResolvedComments]: {
    actionType: ActionType.Normal,
    caption: 'Show resolved',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.ShowResolvedComments),
    iconName: 'BubbleWithLines',
  },
  [EditingAction.previewItem]: previewDetail,
  [EditingAction.previewItemInMultipleSpaces]: {
    ...previewDetail,
    isSubmenu,
  },
  [EditingAction.previewSampleAppItem]: {
    ...previewDetail,
    isSubmenu,
  },
  [EditingAction.publishItem]: {
    actionType: ActionType.Normal,
    caption: 'Publish…',
    iconName: 'PaperPlane',
  },
  [EditingAction.setDueDate]: {
    actionType: ActionType.Normal,
    caption: 'Set due date',
    dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.EditDueDate),
    iconName: 'Calendar',
  },
  [EditingAction.setNote]: {
    actionType: ActionType.Normal,
    caption: 'Add note',
    dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.EditNote),
    iconName: 'RectangleParagraph',
  },
  [EditingAction.setupItemPreview]: previewDetail,
  [EditingAction.shareLink]: {
    actionType: ActionType.Normal,
    caption: 'Share',
    iconName: shareIconName,
    isSubmenu,
    subMenuCaption: 'Share item',
  },
  [EditingAction.showDuplicationOptions]: {
    actionType: ActionType.Normal,
    caption: 'Duplicate',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.OpenDuplicateMenu),
    iconName: 'DocCopy',
    isSubmenu,
    subMenuCaption: 'Duplicate...',
  },
  [EditingAction.showMainMenu]: noDetail,
  [EditingAction.translateVariant]: {
    actionType: ActionType.Normal,
    caption: 'Translate with AI',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.TranslateVariantMenu),
    iconName: 'KopilotAi',
    isSubmenu,
    subMenuCaption: 'Translate from language',
  },
  [EditingAction.viewCodename]: {
    actionType: ActionType.Normal,
    caption: 'Codename',
    dataUiAttribute: getDataUiActionAttribute(DataUiAction.GetCodename),
    iconName: 'BracesOctothorpe',
    isSubmenu,
  },
};
