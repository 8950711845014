import { Action } from '../../../../../../@types/Action.type.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_Succeeded,
  ProjectOverview_Page_Left,
} from '../../../constants/projectOverviewActionTypes.ts';

const initialState: ReadonlyMap<Uuid, ReadonlyMap<Uuid, ReadonlySet<Uuid>>> = new Map();

const groupByWorkflowByStep = (
  workflowsMap: ReadonlyMap<Uuid, ReadonlyMap<Uuid, Set<Uuid>>>,
  itemVariant: IListingContentItem,
): ReadonlyMap<Uuid, ReadonlyMap<Uuid, Set<Uuid>>> => {
  if (!itemVariant.variant) {
    return workflowsMap;
  }

  const workflowId = itemVariant.variant.assignment.workflowStatus.workflowId;
  const stepId = itemVariant.variant.assignment.workflowStatus.id;
  const stepsMap = workflowsMap.get(workflowId) ?? new Map<Uuid, Set<Uuid>>();
  const itemIdsSet = stepsMap.get(stepId) ?? new Set<Uuid>();

  itemIdsSet.add(itemVariant.item.id);
  return new Map(workflowsMap).set(workflowId, new Map(stepsMap).set(stepId, itemIdsSet));
};

export const itemsByWorkflowByStep = (
  state: ReadonlyMap<Uuid, ReadonlyMap<Uuid, ReadonlySet<Uuid>>> = initialState,
  action: Action,
): ReadonlyMap<Uuid, ReadonlyMap<Uuid, ReadonlySet<Uuid>>> => {
  switch (action.type) {
    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      return action.payload.contentItems.reduce(groupByWorkflowByStep, new Map());
    }

    case ProjectOverview_Page_Left: {
      return initialState;
    }

    default:
      return state;
  }
};
