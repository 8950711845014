import { Action } from '../../../../@types/Action.type.ts';
import { Shared_ModalDismissed } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ContentType_LoadComponentUsage_Dismissed } from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import { ContentItemEditing_NewVariant_Started } from '../../../../applications/itemEditor/features/NewVariantWorkflowSelectionDialog/constants/newVariantActionTypes.ts';
import { Data_ContentTypeComponentUsage_Started } from '../../../constants/dataActionTypes.ts';

const initialState: Uuid = '';

export const cancellationToken = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Data_ContentTypeComponentUsage_Started: {
      return action.payload.cancellationToken;
    }

    case ContentType_LoadComponentUsage_Dismissed:
    case ContentItemEditing_NewVariant_Started:
    case Shared_ModalDismissed: {
      return initialState;
    }

    default:
      return state;
  }
};
