import { memoize } from '@kontent-ai/memoization';
import { ItemElementValidationResult } from '../../ItemElementValidationResult.type.ts';

export enum FriendlyWarningReason {
  BrokenAssets = 'BrokenAssets',
  BrokenLinks = 'BrokenLinks',
  BrokenLinksInListing = 'BrokenLinksInListing',
  BrokenModulars = 'BrokenModulars',
  BrokenModularsInListing = 'BrokenModularsInListing',
  BrokenTaxonomies = 'BrokenTaxonomies',
  IncompleteTasks = 'IncompleteTasks',
  ItemIsEditedByMultipleUsers = 'ItemIsEditedByMultipleUsers',
  MissingAssetDescription = 'MissingAssetDescription',
}

export enum FriendlyWarningReasonDetail {
  // Broken links
  DeletedAsset = 'DeletedAsset',
  DeletedItem = 'DeletedItem',
  DeletedTaxonomyTerm = 'DeletedTaxonomyTerm',
  NonExistingAsset = 'NonExistingAsset',
  NonExistingItem = 'NonExistingItem',
  UnpublishedItem = 'UnpublishedItem',
}

export interface IFriendlyWarning {
  reason: FriendlyWarningReason;
  message?: string;
  reasonDetails?: ReadonlyArray<FriendlyWarningReasonDetail>;
}

export type ItemElementFriendlyWarningResult = {
  readonly warningMessages: ReadonlyArray<IFriendlyWarning>;
} & ItemElementValidationResult;

export const emptyItemElementFriendlyWarningResult: ItemElementFriendlyWarningResult = {
  warningMessages: [],
};

export type ItemElementFriendlyWarnings = ReadonlyMap<UuidPath, ItemElementFriendlyWarningResult>;

export const MissingAssetDescriptionFriendlyWarningForRichText: IFriendlyWarning = {
  reason: FriendlyWarningReason.MissingAssetDescription,
  message:
    'There are assets without a description (alt-text). This may impact accessibility and SEO.',
};

export const MissingAssetDescriptionFriendlyWarningForAsset: IFriendlyWarning = {
  reason: FriendlyWarningReason.MissingAssetDescription,
  message:
    'There are assets without a description (alt-text). This may impact accessibility and SEO.',
};

export const BrokenLinksInListing: IFriendlyWarning = {
  reason: FriendlyWarningReason.BrokenLinksInListing,
};

export const BrokenModularsInListing: IFriendlyWarning = {
  reason: FriendlyWarningReason.BrokenModularsInListing,
};

const getFriendlyWarningMessage = (
  reason: FriendlyWarningReason,
  reasonDetail: FriendlyWarningReasonDetail,
): string | null => {
  switch (reasonDetail) {
    case FriendlyWarningReasonDetail.UnpublishedItem:
      return reason === FriendlyWarningReason.BrokenLinks
        ? 'content items that are not published yet.'
        : 'content items that are not published yet. You can publish them together with this item.';
    case FriendlyWarningReasonDetail.DeletedItem:
      return 'content items that have been deleted.';
    case FriendlyWarningReasonDetail.DeletedAsset:
      return 'assets that have been deleted.';
    case FriendlyWarningReasonDetail.DeletedTaxonomyTerm:
      return 'taxonomy terms that have been deleted.';
    case FriendlyWarningReasonDetail.NonExistingItem:
      return 'content items that don’t exist.';
    case FriendlyWarningReasonDetail.NonExistingAsset:
      return 'assets that don’t exist.';
    default:
      return null;
  }
};

const generateFriendlyWarnings = (
  reason: FriendlyWarningReason,
  messagePrefix: string,
  reasonDetails: ReadonlyArray<FriendlyWarningReasonDetail>,
): ReadonlyArray<IFriendlyWarning> => {
  const result: Array<IFriendlyWarning> = [];

  new Set(reasonDetails).forEach((reasonDetail: FriendlyWarningReasonDetail) => {
    const message = getFriendlyWarningMessage(reason, reasonDetail);
    if (message !== null) {
      result.push({
        reason,
        message: `${messagePrefix} ${message}`,
        reasonDetails: [reasonDetail],
      });
    }
  });

  return result;
};

export const createBrokenLinkWarningsWithDetails = memoize.weak(
  (reasonDetails: ReadonlyArray<FriendlyWarningReasonDetail>): ReadonlyArray<IFriendlyWarning> =>
    generateFriendlyWarnings(
      FriendlyWarningReason.BrokenLinks,
      'There are links to',
      reasonDetails,
    ),
);

export const createBrokenModularWarningsWithDetails = memoize.weak(
  (reasonDetails: ReadonlyArray<FriendlyWarningReasonDetail>): ReadonlyArray<IFriendlyWarning> =>
    generateFriendlyWarnings(FriendlyWarningReason.BrokenModulars, 'There are', reasonDetails),
);

export const createBrokenAssetWarningsWithDetails = memoize.weak(
  (reasonDetails: ReadonlyArray<FriendlyWarningReasonDetail>): ReadonlyArray<IFriendlyWarning> =>
    generateFriendlyWarnings(FriendlyWarningReason.BrokenAssets, 'There are', reasonDetails),
);

export const createBrokenTaxonomyWarningsWithDetails = memoize.weak(
  (reasonDetails: ReadonlyArray<FriendlyWarningReasonDetail>): ReadonlyArray<IFriendlyWarning> =>
    generateFriendlyWarnings(FriendlyWarningReason.BrokenTaxonomies, 'There are', reasonDetails),
);
