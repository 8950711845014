import { TypeElementType } from '../../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { RichTextInputCommand } from '../../../applications/richText/plugins/keyboardShortcuts/api/EditorCommand.ts';
import { EditorCommandStatus } from '../../../applications/richText/plugins/keyboardShortcuts/api/editorCommandUtils.ts';

export enum RTECommandSource {
  BlockToolbar = 'block-toolbar',
  InlineToolbar = 'inline-toolbar',
  KeyCommand = 'key-command',
  ShorthandConversion = 'shorthand-conversion',
  SmartLinkCommand = 'smart-link-command',
}

export type RTECommandInfo = {
  readonly canExecuteCommand: boolean;
  readonly canUpdateContent: boolean;
  readonly command: RichTextInputCommand;
  readonly commandStatus: EditorCommandStatus;
  readonly source: RTECommandSource;
};

export type RTECommandEventData = RTECommandInfo & {
  readonly elementId: Uuid;
  readonly elementName: string;
  readonly elementType: TypeElementType;
};
