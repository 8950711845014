import { TaxonomyTypeElementValidationResult } from '../types/TaxonomyTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const TaxonomyInitialValidationResult: TaxonomyTypeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  isDefaultValueValid: true,
  isGuidelinesValid: true,
  isNameValid: true,
  isNumberOfTermsValid: true,
  isSelectedTaxonomyValid: true,
  errorMessages: [],
  warningMessages: [],
};
