import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  spacingMainLayoutBottom,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';

type ProjectSettingsLayoutProps = {
  readonly renderContent: () => ReactNode;
};

const StyledRow = styled(Row)`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const ScrollableColumn = styled(Column)`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ProjectSettingsLayout: React.FC<ProjectSettingsLayoutProps> = ({ renderContent }) => (
  <>
    <HtmlPageTitle appName={AppNames.ProjectSettings} />
    <Box minHeight={0} display="flex" className="canvas">
      <StyledRow noWrap>
        <ScrollableColumn>
          <Box marginLeft={spacingMainLayoutLeft} marginRight={spacingMainLayoutRight}>
            <Box
              maxWidth={1000}
              marginTop={spacingMainLayoutTop}
              marginBottom={spacingMainLayoutBottom}
              marginX="auto"
            >
              {renderContent()}
            </Box>
          </Box>
        </ScrollableColumn>
      </StyledRow>
    </Box>
  </>
);

ProjectSettingsLayout.displayName = 'ProjectSettingsLayout';
