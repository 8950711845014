import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonStyle } from '../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IAddNextStepButtonProps {
  readonly onClick?: () => void;
  readonly disabled?: boolean;
}

export const AddNextStepButton: React.FC<IAddNextStepButtonProps> = ({ onClick, disabled }) => (
  <div className="bar-item__outer-action">
    <SquareButton
      style={SquareButtonStyle.Tertiary}
      onClick={onClick}
      tooltipText="Add next step"
      tooltipPlacement="left"
      disabled={disabled}
      iconName={IconName.Plus}
      {...getDataUiActionAttribute(DataUiAction.CreateNew)}
    />
  </div>
);

AddNextStepButton.displayName = 'AddNextStepButton';
