import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { StatusMessageStyle } from '../../../content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';

type LocalState = {
  readonly selectedAssetsCount: number;
};

export const getStatusMessageStyle = (
  state: IStore,
  { selectedAssetsCount }: LocalState,
): StatusMessageStyle => {
  const {
    assetLibraryApp: { lastAction },
  } = state;

  switch (lastAction.type) {
    case StatusMessageAction.Archiving:
    case StatusMessageAction.ArchivingFolder:
    case StatusMessageAction.Moving:
    case StatusMessageAction.MovingFolder:
    case StatusMessageAction.Restoring:
    case StatusMessageAction.RestoringFolder:
    case StatusMessageAction.MovingAssetsBackToCollection:
    case StatusMessageAction.MovingAssetsBackToFolder:
    case StatusMessageAction.MovingFolderBack:
    case StatusMessageAction.AssigningTerms:
      return StatusMessageStyle.Progress;

    case StatusMessageAction.ArchivedAssets:
    case StatusMessageAction.ArchivedFolder:
      return StatusMessageStyle.Deleted;

    case StatusMessageAction.MovedFolder:
    case StatusMessageAction.MovedFolderBack:
    case StatusMessageAction.MovedAssetsToCollection:
    case StatusMessageAction.MovedAssetsBackToCollection:
    case StatusMessageAction.MovedAssetsToFolder:
    case StatusMessageAction.MovedAssetsBackToFolder:
    case StatusMessageAction.RestoredAssets:
    case StatusMessageAction.RestoredFolder:
      return StatusMessageStyle.Updated;

    case StatusMessageAction.AssignedTerms:
      return lastAction.failedIds.size > 0 ? StatusMessageStyle.Failed : StatusMessageStyle.Updated;

    case StatusMessageAction.Selected: {
      if (selectedAssetsCount > 0) {
        return StatusMessageStyle.Selected;
      }
      break;
    }

    default:
      break;
  }

  return StatusMessageStyle.Normal;
};
