import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { Capability, getUserCapability } from '../../../_shared/utils/permissions/capability.ts';
import { isProjectManager } from '../../../_shared/utils/permissions/isProjectManager.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import {
  getCurrentProjectId,
  getProjectInfo,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isWebSpotlightEnabled } from '../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { isWebSpotlightIncludedInPlan } from './isWebSpotlightIncludedInPlan.ts';

export const isEnvironmentGeneralSettingsTabVisible = (
  state: IStore,
  environmentId?: Uuid,
): boolean => {
  const environment = getProjectInfo(state, environmentId ?? getCurrentProjectId(state));
  return (
    canManageWebSpotlight(state) ||
    canManageEnvironment(environment) ||
    canManageApiKey(environment) ||
    canActivateApiStatus(state)
  );
};

export const canManageEnvironment = (projectInfo: IUserProjectInfo): boolean =>
  getUserCapability(projectInfo).can(Capability.ManageEnvironments);

export const canManageApiKey = (projectInfo: IUserProjectInfo): boolean =>
  getUserCapability(projectInfo).can(Capability.ManagePersonalApiKey);

export const canManageWebSpotlight = (state: IStore): boolean =>
  (isWebSpotlightIncludedInPlan(state) || isWebSpotlightEnabled(state)) && isProjectManager(state);

export const canActivateApiStatus = (state: IStore): boolean => isProjectManager(state);
