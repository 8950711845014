import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { RedactedItemName } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

interface IDeletedLinkedItemContentProps {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly isCompact?: boolean;
  readonly name: string;
  readonly showExpandActionPlaceholder?: boolean;
}

export const DeletedLinkedItemContent: React.FC<IDeletedLinkedItemContentProps> = ({
  cannotViewDisabledMessage,
  isCompact,
  name,
  showExpandActionPlaceholder,
}) => {
  const isRedacted = !!cannotViewDisabledMessage;

  return (
    <Tooltip tooltipText={cannotViewDisabledMessage} placement="bottom-start">
      <div className="bar-item__title-pane">
        {showExpandActionPlaceholder && (
          <div
            className={classNames('bar-item__action', 'bar-item__action--is-hidden', {
              'bar-item__action--size-xs': isCompact,
            })}
          />
        )}
        <div
          title={isRedacted ? undefined : name}
          className={classNames('bar-item__title-text', 'bar-item__title-text--deleted', {
            'bar-item__title-text--is-redacted': isRedacted,
            'bar-item__title-text--no-left-offset': showExpandActionPlaceholder,
          })}
        >
          <IgnoreByGrammarly>{isRedacted ? RedactedItemName : name}</IgnoreByGrammarly>
        </div>
        <div className="bar-item__title-label">
          <IgnoreByGrammarly>(Deleted)</IgnoreByGrammarly>
        </div>
      </div>
    </Tooltip>
  );
};

DeletedLinkedItemContent.displayName = 'DeletedLinkedItemContent';
