import React from 'react';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { getCommentedTextSelector } from '../../../../utils/commentUtils.ts';

export interface IFocusedCommentThreadHighlighterProps {
  readonly focusedThread: ICommentThread | null;
  readonly focusedThreadHasSuggestions: boolean;
}

export const FocusedCommentThreadHighlighter: React.FC<IFocusedCommentThreadHighlighterProps> = ({
  focusedThread,
  focusedThreadHasSuggestions,
}): JSX.Element | null => {
  if (!focusedThread) {
    return null;
  }

  const focusedCommentCssClassSelector = getCommentedTextSelector(focusedThread);
  if (!focusedCommentCssClassSelector) {
    return null;
  }

  // Important styles needed to overwrite default comment highlight in the inline style
  const inlineStyleString = `
${focusedCommentCssClassSelector} {
  z-index: var(--z-200) !important;
  background-color: var(${
    focusedThreadHasSuggestions
      ? '--suggestion-highlighted-bg-color'
      : '--comment-highlighted-bg-color'
  }) !important;
}`;

  return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
};

FocusedCommentThreadHighlighter.displayName = 'FocusedCommentThreadHighlighter';
