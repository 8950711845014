import React from 'react';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { TasksDoneOnTimeWidget } from '../components/TasksDoneOnTimeWidget.tsx';

const { missionControlRepository } = repositoryCollection;

export const TasksDoneOnTimeWidgetLiveContainer: React.FC = () => {
  return (
    <TasksDoneOnTimeWidget tasksDoneOnTimeFetcher={missionControlRepository.getTasksDoneOnTime} />
  );
};

TasksDoneOnTimeWidgetLiveContainer.displayName = 'TasksDoneOnTimeWidgetLiveContainer';
