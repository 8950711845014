import { IFupMetricSummaryServerModel } from '../../../../repositories/serverModels/FupMetricSummaryServerModel.type.ts';

export interface IFupMetricSummary {
  readonly name: string;
  readonly usage: number;
  readonly includedInPlan: number;
  readonly overPlan: number;
}

export function createFupMetricSummary(): IFupMetricSummary {
  return {
    name: '',
    usage: 0,
    includedInPlan: 0,
    overPlan: 0,
  };
}

export function getFupMetricSummaryFromServerModel(
  serverModel: IFupMetricSummaryServerModel,
): IFupMetricSummary {
  if (typeof serverModel !== 'object') {
    throw new Error('Fup metric summary is not an object');
  }

  const emptyObject = createFupMetricSummary();

  return {
    name: serverModel.name ?? emptyObject.name,
    usage: serverModel.usage ?? emptyObject.usage,
    includedInPlan: serverModel.includedInPlan ?? emptyObject.includedInPlan,
    overPlan: serverModel.overPlan ?? emptyObject.overPlan,
  };
}
