import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';

export enum ApiKeyActionStatus {
  None = 'none',
  LoadingFailed = 'loadingFailed',
  RegenerationFailed = 'regenerationFailed',
  RevokingFailed = 'revokingFailed',
  SavingFailed = 'savingFailed',
  SavingInterrupted = 'savingInterrupted',
}

export interface IApiKeysAppStoreState {
  readonly apiKeysSettingsLoadingStatus: LoadingStatus;
  readonly deliveryKeysLoadingStatus: LoadingStatus;
  readonly detailLoadingStatus: LoadingStatus;
  readonly detailActionStatus: ApiKeyActionStatus;
  readonly hasAnyDapiKeys: boolean;
  readonly hasAnyMapiKeys: boolean;
  readonly managementKeysLoadingStatus: LoadingStatus;
}
