import React from 'react';
import { QuickTip } from '../../../../components/infos/QuickTip.tsx';
import { AppNames } from '../../../../constants/applicationNames.ts';
import { ModalSection } from '../ModalSection.tsx';

export const ArchivedStepInfoSection: React.FC = () => (
  <ModalSection>
    <QuickTip>
      <p>Use the Archived step for retiring your content.</p>
      <p>If you need to work with this item again, it can be restored later.</p>
      <p>Archived items can be found in {AppNames.Content} alongside other items.</p>
    </QuickTip>
  </ModalSection>
);

ArchivedStepInfoSection.displayName = 'ArchiveInfoSection';
