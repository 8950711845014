import { ContentBlock, ContentState, Modifier, SelectionState } from 'draft-js';
import { AiActionSource } from '../../../../../_shared/models/events/AiActionEventData.type.ts';
import {
  createSelection,
  getMetadataAtSelection,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import { markAiContent } from '../../ai/utils/editorAiUtils.ts';
import { EntityMutability, EntityType } from '../../entityApi/api/Entity.ts';
import { EntityPredicate } from '../../entityApi/api/editorEntityUtils.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';
import { atomicInstructionChar, instructionTriggerSequence } from '../constants/aiConstants.ts';
import {
  FinishedAiInstructionData,
  NewAiInstructionData,
  isAiInstruction,
  isFinishedAiInstruction,
  isNewAiInstruction,
} from './InstructionEntity.ts';

export function findFinishedInstructions(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (!entityKey) {
      return false;
    }
    const entity = contentState.getEntity(entityKey);
    return isFinishedAiInstruction(entity);
  }, callback);
}

export function findInstructionStarts(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      if (!entityKey) {
        return false;
      }
      const entity = contentState.getEntity(entityKey);
      return isNewAiInstruction(entity);
    },
    (start) => callback(start, start + atomicInstructionChar.length),
  );
}

export function findInstructionContents(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      if (!entityKey) {
        return false;
      }
      const entity = contentState.getEntity(entityKey);
      return isNewAiInstruction(entity);
    },
    (start, end) => {
      const contentStart = start + atomicInstructionChar.length;
      if (end > contentStart) {
        callback(contentStart, end);
      }
    },
  );
}

export function createNewInstruction(
  input: IContentChangeInput,
  data: NewAiInstructionData,
): IContentChangeResult {
  const { instruction, ...newInstructionData } = data;

  const withAtomicChar = updateText(input, atomicInstructionChar + (data.instruction ?? ''));

  const { content, selection } = withAtomicChar;

  const contentStateWithInstruction = content.createEntity(
    EntityType.AiInstruction,
    EntityMutability.Mutable,
    newInstructionData,
  );
  const entityKey = contentStateWithInstruction.getLastCreatedEntityKey();

  const newContent = Modifier.applyEntity(content, selection, entityKey);
  const newSelection = createSelection(selection.getEndKey(), selection.getEndOffset());
  const newContentWithSelection = setContentSelection(newContent, input.selection, newSelection);

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
}

function isEntityAtBlockChars(block: ContentBlock, start: number, end: number): boolean {
  for (let offset = start; offset < end; offset++) {
    if (block.getEntityAt(offset)) {
      return true;
    }
  }

  return false;
}

export function applyNewInstruction(
  input: IContentChangeInput,
  data: NewAiInstructionData,
): IContentChangeResult {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const caretBlockKey = selection.getStartKey();
  const block = content.getBlockForKey(caretBlockKey);

  if (!block) {
    return input;
  }

  const caretOffset = selection.getStartOffset();

  const potentialInstructionStartOffset = caretOffset - instructionTriggerSequence.length;
  if (potentialInstructionStartOffset < 0) {
    return input;
  }

  const blockText = block.getText();
  const potentialInstructionChars = blockText.substring(
    potentialInstructionStartOffset,
    caretOffset,
  );
  if (potentialInstructionChars !== instructionTriggerSequence) {
    return input;
  }

  // If some entity is already present at any of the trigger chars, do not allow new instruction as we don't want to break the existing entity
  if (isEntityAtBlockChars(block, potentialInstructionStartOffset, caretOffset)) {
    return input;
  }

  // If there is a non-whitespace char before the trigger characters, we don't want to initiate the new Instruction
  // as it is probably part of something continuous, e.g. URL
  // If the force flag is true (e.g. in case the instruction is triggered via button), this pre-condition is ignored
  if (data.source === AiActionSource.TextShortcut && potentialInstructionStartOffset > 0) {
    const precedingCharOffset = potentialInstructionStartOffset - 1;
    const precedingChar = blockText[precedingCharOffset];
    const isPrecedingCharWhitespace = /\s/.test(precedingChar ?? '');
    if (!isPrecedingCharWhitespace) {
      // Except for the preceding char being an instruction, because instructions are atomic and two instructions next to each other are OK
      const precedingCharEntityKey = block.getEntityAt(precedingCharOffset);
      const precedingCharEntity = precedingCharEntityKey
        ? content.getEntity(precedingCharEntityKey)
        : undefined;
      const isPrecedingCharInstruction =
        !!precedingCharEntity && isFinishedAiInstruction(precedingCharEntity);
      if (!isPrecedingCharInstruction) {
        return input;
      }
    }
  }

  return createNewInstruction(
    {
      content: input.content,
      selection: createSelection(
        caretBlockKey,
        potentialInstructionStartOffset,
        caretBlockKey,
        potentialInstructionStartOffset + instructionTriggerSequence.length,
      ),
    },
    data,
  );
}

export function createFinishedInstruction(
  input: IContentChangeInput,
  data: FinishedAiInstructionData,
): IContentChangeResult {
  const withAtomicText = updateText(input, atomicInstructionChar);
  const withAiMetadata = markAiContent(withAtomicText, data.aiSessionId);

  const { content, selection } = withAiMetadata;

  const contentStateWithInstruction = content.createEntity(
    EntityType.AiInstruction,
    EntityMutability.Immutable,
    data,
  );
  const entityKey = contentStateWithInstruction.getLastCreatedEntityKey();

  const newContent = Modifier.applyEntity(content, selection, entityKey);
  const newSelection = createSelection(selection.getEndKey(), selection.getEndOffset());
  const newContentWithSelection = setContentSelection(newContent, input.selection, newSelection);

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
}

export const getActiveInstructionEntityKey = (
  content: ContentState,
  selection: SelectionState,
  predicate?: EntityPredicate,
): string | null => {
  if (!selection.getHasFocus()) {
    return null;
  }

  const metadataAtSelection = getMetadataAtSelection(content, selection);
  if (!metadataAtSelection) {
    return null;
  }

  const isCaretAtBlockStart = selection.isCollapsed() && !selection.getStartOffset();
  if (isCaretAtBlockStart) {
    return null;
  }

  const { entityKeyAtAllTableChars, entityKeyAtAllTopLevelChars } = metadataAtSelection;

  if (entityKeyAtAllTopLevelChars && entityKeyAtAllTableChars) {
    return null;
  }

  const entityKey = entityKeyAtAllTopLevelChars ?? entityKeyAtAllTableChars;
  if (!entityKey) {
    return null;
  }

  const entity = content.getEntity(entityKey);
  if (!entity || predicate?.(entity, entityKey) === false) {
    return null;
  }

  return isAiInstruction(entity) ? entityKey : null;
};
