import classNames from 'classnames';
import React from 'react';
import { useFlexingTileClassName } from './FlexingTileContext.tsx';

interface IFlexingTileProps {
  readonly className?: string;
  readonly children: React.ReactNode;
}

export const FlexingTile: React.FC<IFlexingTileProps> = ({ className, children }) => {
  const tileClassName = useFlexingTileClassName();

  return (
    <div className={classNames(className, tileClassName ?? 'col-sm-8 col-md-6 col-lg-4')}>
      {children}
    </div>
  );
};

FlexingTile.displayName = 'FlexingTile';
