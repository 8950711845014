import { EditingAction } from '../../../../../../itemEditor/features/ContentItemEditing/models/EditingAction.ts';

export const floatingEditorMenuStructure = [
  {
    actions: [
      EditingAction.addTask,
      EditingAction.changeStep,
      EditingAction.publishItem,
      EditingAction.setDueDate,
      EditingAction.changeDueDate,
      EditingAction.assignPeople,
    ],
    key: 'Main actions',
    title: undefined,
  },
];
