import { Action } from '../../../../@types/Action.type.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { IWebhookSetting } from '../../../../data/models/webhooks/WebhookSetting.ts';
import {
  Webhooks_CancelEditing,
  Webhooks_CancelEditingInitial,
  Webhooks_ListingInit_Finished,
  Webhooks_Webhook_ArchiveCompleted,
  Webhooks_Webhook_ArchiveFailed,
  Webhooks_Webhook_ArchiveStarted,
  Webhooks_Webhook_DisableCompleted,
  Webhooks_Webhook_DisableFailed,
  Webhooks_Webhook_EnableCompleted,
  Webhooks_Webhook_EnableFailed,
  Webhooks_Webhook_InsertCompleted,
  Webhooks_Webhook_RestoreCompleted,
  Webhooks_Webhook_RestoreFailed,
  Webhooks_Webhook_RestoreStarted,
  Webhooks_Webhook_SaveFailed,
  Webhooks_Webhook_SaveStarted,
  Webhooks_Webhook_UpdateCompleted,
} from '../../constants/webhooksActionTypes.ts';
import { IWebhookOperation, OperationType } from '../../models/webhookOperation.ts';

const createOperation = (
  type: OperationType,
  status: OperationStatus,
  webhook?: IWebhookSetting,
): IWebhookOperation => ({
  type,
  status,
  webhook,
});

export const lastOperation = (
  state: IWebhookOperation | null = null,
  action: Action,
): IWebhookOperation | null => {
  switch (action.type) {
    case Webhooks_Webhook_ArchiveStarted:
      return createOperation(
        OperationType.Archive,
        OperationStatus.Pending,
        action.payload.webhook,
      );

    case Webhooks_Webhook_ArchiveCompleted:
      return createOperation(
        OperationType.Archive,
        OperationStatus.Success,
        action.payload.webhook,
      );

    case Webhooks_Webhook_ArchiveFailed:
      return createOperation(OperationType.Archive, OperationStatus.Failed, action.payload.webhook);

    case Webhooks_Webhook_RestoreStarted:
      return createOperation(
        OperationType.Restore,
        OperationStatus.Pending,
        action.payload.webhook,
      );

    case Webhooks_Webhook_RestoreCompleted:
      return createOperation(
        OperationType.Restore,
        OperationStatus.Success,
        action.payload.webhook,
      );

    case Webhooks_Webhook_RestoreFailed:
      return createOperation(OperationType.Restore, OperationStatus.Failed, action.payload.webhook);

    case Webhooks_Webhook_SaveStarted:
      return createOperation(OperationType.Save, OperationStatus.Pending, action.payload.webhook);

    case Webhooks_Webhook_UpdateCompleted:
    case Webhooks_Webhook_InsertCompleted:
      return createOperation(OperationType.Save, OperationStatus.Success, action.payload.webhook);

    case Webhooks_Webhook_SaveFailed:
      return createOperation(OperationType.Save, OperationStatus.Failed, action.payload.webhook);

    case Webhooks_Webhook_EnableFailed:
    case Webhooks_Webhook_DisableFailed:
      return createOperation(OperationType.Save, OperationStatus.Failed);

    case Webhooks_Webhook_EnableCompleted:
    case Webhooks_Webhook_DisableCompleted:
      return createOperation(OperationType.Save, OperationStatus.Success);

    case Webhooks_CancelEditingInitial:
    case Webhooks_CancelEditing:
      return createOperation(OperationType.Discard, OperationStatus.Initialized);

    case Webhooks_ListingInit_Finished:
      return null;

    default:
      return state;
  }
};
