import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { clearTypeEditorErrorMessage } from '../../../shared/actions/sharedContentModelsActions.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import { removeTypeElement } from '../../../snippets/actions/snippetsActions.ts';

export const createRemoveSnippetElement =
  () =>
  (typeElementId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    dispatch(removeTypeElement(typeElementId));

    if (isTypeValid(getState().contentModelsApp.snippets.editor.validationResults)) {
      dispatch(clearTypeEditorErrorMessage());
    }
  };
