import { Item } from '@react-stately/collections';
import { useTabListState } from '@react-stately/tabs';
import { TabListProps } from '@react-types/tabs';
import React from 'react';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { TabWithState } from './components/Tab.tsx';
import { TabGroupWithState } from './components/TabGroup.tsx';
import { TabViewEmptyState } from './components/TabViewEmptyState.tsx';
import { TabViewPanelWithState } from './components/TabViewPanel.tsx';
import { TabItem, TabViewState, TabViewStateContext } from './utils.ts';

type CustomTabViewProps<T extends TabItem> = {
  readonly fullWidthTabs?: boolean;
  readonly renderTab?: (tab: T) => React.ReactNode;
};

type TabViewProps<T extends TabItem> = Omit<TabListProps<T>, 'children'> &
  React.PropsWithChildren<CustomTabViewProps<T>>;

const renderDefaultTab = <T extends TabItem>(tab: T) => <TabWithState item={tab} />;

export const TabViewWrapper = React.forwardRef<HTMLDivElement, Required<React.PropsWithChildren>>(
  ({ children }, ref) => (
    <Stack
      ref={ref}
      css="height: 100%"
      spacing={Spacing.None}
      {...getDataUiComponentAttribute(TabView)}
    >
      {children}
    </Stack>
  ),
);

TabViewWrapper.displayName = 'TabViewWrapper';

const TabViewComponent = React.forwardRef(
  <T extends TabItem>(
    {
      children,
      defaultSelectedKey,
      disabledKeys,
      fullWidthTabs,
      isDisabled,
      items,
      onSelectionChange,
      renderTab = renderDefaultTab,
      selectedKey,
    }: TabViewProps<T>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const tabListProps: TabListProps<T> = React.useMemo(
      () => ({
        children: (item: T) => <Item key={item.id}>{renderTab(item)}</Item>,
        defaultSelectedKey,
        disabledKeys,
        isDisabled,
        items,
        onSelectionChange,
        selectedKey,
      }),
      [
        defaultSelectedKey,
        disabledKeys,
        isDisabled,
        items,
        onSelectionChange,
        renderTab,
        selectedKey,
      ],
    );

    const tabListState = useTabListState<TabItem>(tabListProps);

    const tabViewState = React.useMemo<TabViewState>(
      () => ({
        tabListState,
        tabListProps,
        fullWidthTabs: !!fullWidthTabs,
      }),
      [tabListState, tabListProps, fullWidthTabs],
    );

    return (
      <TabViewWrapper ref={ref}>
        <TabViewStateContext.Provider value={tabViewState}>{children}</TabViewStateContext.Provider>
      </TabViewWrapper>
    );
  },
) as (<T extends TabItem>(
  props: TabViewProps<T> & React.RefAttributes<HTMLElement>,
) => ReturnType<React.FC>) &
  React.FC;

TabViewComponent.displayName = 'TabView';

export const TabView = Object.assign(TabViewComponent, {
  Tab: TabWithState,
  TabGroup: TabGroupWithState,
  TabPanel: TabViewPanelWithState,
  EmptyState: TabViewEmptyState,
});
