import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_Editor_Left } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { ContentModels_TypeEditor_Left } from '../../../../../contentModels/shared/constants/sharedContentModelsActionTypes.ts';

import {
  ContentItemEditing_ContentDetailsButton_Toggled,
  ContentItemEditing_Sidebar_Closed,
  ContentItemEditing_Sidebar_Opened,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

export function openedByUser(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_ContentDetailsButton_Toggled:
      return true;
    case ContentItemEditing_Sidebar_Opened:
      return action.payload.params?.type === 'click';
    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left:
    case ContentItemEditing_Sidebar_Closed:
      return false;

    default:
      return state;
  }
}
