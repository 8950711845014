import { Action } from '../../../../@types/Action.type.ts';
import { shouldUseLastContinuationToken } from '../../../../_shared/utils/scrollTableUtils.ts';
import { ContentType_LoadComponentUsage_Dismissed } from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import {
  Data_ContentTypeComponentUsage_Finished,
  Data_ContentTypeComponentUsage_Started,
} from '../../../constants/dataActionTypes.ts';
import { IListingContentItem } from '../../../models/listingContentItems/IListingContentItem.ts';

const initialState: ReadonlyArray<IListingContentItem> | null = null;

const areItemVariantsEqual = (itemA: IListingContentItem, itemB: IListingContentItem) =>
  itemA.item.id === itemB.item.id && itemA.variant?.id.variantId === itemB.variant?.id.variantId;

const isItemAlreadyIncluded = (
  item: IListingContentItem,
  storedItems: ReadonlyArray<IListingContentItem>,
) => storedItems.some((stateItem) => areItemVariantsEqual(stateItem, item));

export const items = (
  state: ReadonlyArray<IListingContentItem> | null = initialState,
  action: Action,
): ReadonlyArray<IListingContentItem> | null => {
  switch (action.type) {
    case Data_ContentTypeComponentUsage_Started: {
      return shouldUseLastContinuationToken(action.payload.requestTrigger) ? state : initialState;
    }

    case Data_ContentTypeComponentUsage_Finished: {
      if (state === null) {
        return action.payload.contentItems;
      }

      const newItems = action.payload.contentItems.filter(
        (item) => !isItemAlreadyIncluded(item, state),
      );
      return shouldUseLastContinuationToken(action.payload.requestTrigger)
        ? state.concat(newItems)
        : newItems;
    }

    case ContentType_LoadComponentUsage_Dismissed: {
      return initialState;
    }

    default:
      return state;
  }
};
