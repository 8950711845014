import Immutable from 'immutable';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';
import {
  ItemVariantTimeline_Load_Initial_Finished,
  ItemVariantTimeline_Load_Initial_Started,
  ItemVariantTimeline_Load_MoreItems_Finished,
  ItemVariantTimeline_Load_MoreItems_Started,
} from '../constants/timelineActionTypes.ts';

export const itemVariantTimelineLoadMoreItemsStarted = () =>
  ({ type: ItemVariantTimeline_Load_MoreItems_Started }) as const;
export const itemVariantTimelineLoadMoreItemsFinished = (
  entryTimeline: Immutable.List<TimelineItem>,
  timelineContinuationToken: string | null,
) =>
  ({
    type: ItemVariantTimeline_Load_MoreItems_Finished,
    payload: {
      entryTimeline,
      timelineContinuationToken,
    },
  }) as const;
export const itemVariantTimelineLoadInitialStarted = () =>
  ({ type: ItemVariantTimeline_Load_Initial_Started }) as const;
export const itemVariantTimelineLoadInitialFinished = (
  entryTimeline: Immutable.List<TimelineItem>,
  timelineContinuationToken: string | null,
) =>
  ({
    type: ItemVariantTimeline_Load_Initial_Finished,
    payload: {
      entryTimeline,
      timelineContinuationToken,
    },
  }) as const;

export type LoadTimelineActionsType = ReturnType<
  | typeof itemVariantTimelineLoadMoreItemsFinished
  | typeof itemVariantTimelineLoadMoreItemsStarted
  | typeof itemVariantTimelineLoadInitialStarted
  | typeof itemVariantTimelineLoadInitialFinished
>;
