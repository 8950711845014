import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IListingFilter } from '../../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { ContentType_LoadComponentUsage_LoadUsageCount } from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ContentItemFilterServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';

const usageCountLoaded = (contentTypeId: Uuid, usagesCount: number) =>
  ({
    type: ContentType_LoadComponentUsage_LoadUsageCount,
    payload: {
      contentTypeId,
      visibleItemsToUserCount: usagesCount,
    },
  }) as const;

export type LoadContentTypeAsComponentUsageCountActionsType = ReturnType<typeof usageCountLoaded>;

interface ILoadContentTypeAsComponentUsageCountDependencies {
  readonly buildFilterForListingContentItems: (
    params: IListingFilter,
  ) => Readonly<ContentItemFilterServerModel>;
  readonly contentItemRepository: IContentItemRepository;
}

export const createLoadContentTypeAsComponentUsageCountAction =
  (deps: ILoadContentTypeAsComponentUsageCountDependencies) =>
  (contentTypeId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      listingUi: { filter },
    } = state.contentApp;

    const serverFilter = deps.buildFilterForListingContentItems(filter);

    const usagesCount = await deps.contentItemRepository.getContentItemsCountByComponentsTypeId(
      contentTypeId,
      serverFilter,
      abortSignal,
    );

    dispatch(usageCountLoaded(usagesCount.contentTypeId, usagesCount.numberOfItems));
  };
