export enum AiFeatureSet {
  ChangeTone = 'ChangeTone',
  CreateTitle = 'CreateTitle',
  ImageDescription = 'ImageDescription',
  ImageTagging = 'ImageTagging',
  ImproveContent = 'ImproveContent',
  Inline = 'Inline',
  ItemVariantTranslations = 'ItemVariantTranslations',
  LinkedItems = 'LinkedItems',
  LinkedItemsScore = 'LinkedItemsScore',
  MakeShorter = 'MakeShorter',
  MatchWritingStyle = 'MatchWritingStyle',
  Review = 'Review',
  SemanticSearch = 'SemanticSearch',
  Summarize = 'Summarize',
  Taxonomies = 'Taxonomies',
  Translations = 'Translations',
}
