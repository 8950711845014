import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';

export const hasProjectMultipleActiveLanguagesForCurrentUser = (state: IStore): boolean =>
  getAllActiveLanguagesForCurrentUserInAnyCollection(
    state.data.user,
    state.sharedApp.currentProjectId,
    state.data.languages,
    Capability.ViewContent,
  ).size > 1;
