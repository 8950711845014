import { memoize } from '@kontent-ai/memoization';
import { areShallowEqual } from '@kontent-ai/utils';
import { logError } from '../../../_shared/utils/logError.ts';
import {
  ICollectionServerModel,
  ICollectionsServerResponseModel,
  IUpdateCollectionsServerRequestModel,
} from '../../../repositories/serverModels/CollectionsServerModel.type.ts';

export interface ICollection {
  readonly name: string;
  readonly codeName: string;
  readonly id: Uuid;
}

export interface ICollections {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly collections: ReadonlyArray<ICollection>;
  readonly lastModified: DateTimeStamp;
  readonly lastModifiedBy: UserId;
  readonly eTag: string;
}

export type CollectionsMap = ReadonlyMap<Uuid, ICollection>;

export const emptyCollection: ICollection = {
  name: '',
  codeName: '',
  id: '00000000-0000-0000-0000-000000000000',
};

export function createCollection(props?: Partial<ICollection>): ICollection {
  return {
    ...emptyCollection,
    ...props,
  };
}

export const emptyCollections: ICollections = {
  collectionIds: [],
  collections: [],
  lastModified: '',
  lastModifiedBy: '',
  eTag: '',
};

export function createCollectionFromServerModel(serverModel: ICollectionServerModel): ICollection {
  return {
    name: serverModel.name ?? emptyCollection.name,
    codeName: serverModel.codeName ?? emptyCollection.codeName,
    id: serverModel.id ?? emptyCollection.id,
  };
}

export function createCollectionsFromServerModel(
  serverModel: ICollectionsServerResponseModel,
): ICollections {
  return {
    collectionIds: (serverModel.collections ?? []).map((c) => c.id),
    collections: (serverModel.collections ?? []).map(createCollectionFromServerModel),
    lastModified: serverModel.lastModified ?? emptyCollections.lastModified,
    lastModifiedBy: serverModel.lastModifiedBy ?? emptyCollections.lastModifiedBy,
    eTag: serverModel.eTag ?? emptyCollections.eTag,
  };
}

export const createCollectionsServerUpdateModel = (
  collectionIds: ReadonlyArray<Uuid>,
  collections: ReadonlyArray<ICollection>,
  eTag: string,
): IUpdateCollectionsServerRequestModel => {
  const serverCollections = collectionIds.reduce<readonly ICollectionServerModel[]>(
    (result, collectionId) => {
      const collection = collections.find((c) => c.id === collectionId);

      if (!collection) {
        logError(`${__filename}: Collection with id “${collectionId}” was not found.`);

        return result;
      }

      return [...result, collection];
    },
    [],
  );

  return {
    collections: serverCollections,
    eTag,
  };
};

export const areCollectionsMapsSame = memoize.weak(
  (collections1: CollectionsMap, collections2: CollectionsMap): boolean => {
    const collectionArray2 = Array.from(collections2.values());
    const sameSize = collections1.size === collections2.size;
    return (
      sameSize &&
      Array.from(collections1.values()).every((collection1Item, index) => {
        const collection2Item = collectionArray2[index];
        return collection2Item && areShallowEqual(collection1Item, collection2Item);
      })
    );
  },
);
