import { Box } from '@kontent-ai/component-library/Box';
import { VerticalTabs } from '@kontent-ai/component-library/VerticalTabs';
import React from 'react';
import {
  AdministratorsRoute,
  BillingHistoryRoute,
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
  ProjectsInSubscriptionRoute,
  SubscriptionApiKeysRoute,
  SubscriptionGeneralRoute,
  SubscriptionOverviewRoute,
  SubscriptionUsersRoute,
  TransactionsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAppName,
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath, matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  SubscriptionStatus,
  emptySubscription,
} from '../../../../../data/models/subscriptions/Subscription.ts';
import { getSelectedSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { SubscriptionTabName } from '../../constants/subscriptionTabName.ts';
import { isStandardPlan } from '../../utils/planUtils.ts';
import { PlanPrepaidStatus, getPlanPrepaidStatus } from '../../utils/subscriptionPlanUtils.ts';

export const SubscriptionManagementTabs: React.FC = () => {
  const subscription = useSelector((state) => getSelectedSubscription(state) ?? emptySubscription);
  const plan = useSelector((state) => state.data.plans.byId.get(subscription.currentPlan.planId));
  const shouldDisplayTransactions = useSelector(
    (state) => state.sharedApp.subscriptionProperties.SendFinalReport === 'Enabled',
  );

  if (!plan) {
    return null;
  }

  const {
    subscriptionDetails: { hadPrepaidPlan },
    subscriptionId,
  } = subscription;

  const isMarketplaceSubscription = !!subscription.azureMarketplaceSubscriptionId;
  const prepaidStatus = getPlanPrepaidStatus(plan, hadPrepaidPlan);

  const isBillingHistoryVisible = !plan.isTrial && !isMarketplaceSubscription;

  const isBillingInformationVisible =
    prepaidStatus !== PlanPrepaidStatus.IsPrepaid ||
    subscription.status === SubscriptionStatus.Canceled ||
    subscription.isFsSubscriptionConnected;

  const isPlansTabVisible =
    (subscription.status === SubscriptionStatus.Suspended ||
      subscription.status === SubscriptionStatus.Expired ||
      (subscription.status === SubscriptionStatus.Active &&
        (plan.isTrial || isStandardPlan(plan.name)))) &&
    !isMarketplaceSubscription;

  const isTransactionsTabVisible =
    prepaidStatus !== PlanPrepaidStatus.None &&
    !isMarketplaceSubscription &&
    shouldDisplayTransactions;

  const isApiKeysTabVisible =
    subscription.status === SubscriptionStatus.Active ||
    subscription.status === SubscriptionStatus.Suspended;

  const usageUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionOverviewRoute, {
    subscriptionId,
  });
  const generalUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionGeneralRoute, {
    subscriptionId,
  });
  const billingHistoryUrl = buildPath<EnsuredSubscriptionRouteParams>(BillingHistoryRoute, {
    subscriptionId,
  });
  const billingInformationUrl = buildPath<EnsuredSubscriptionRouteParams>(BillingInformationRoute, {
    subscriptionId,
  });
  const plansUrl = buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
    subscriptionId,
  });
  const transactionsUrl = buildPath<EnsuredSubscriptionRouteParams>(TransactionsRoute, {
    subscriptionId,
  });
  const adminsUrl = buildPath<EnsuredSubscriptionRouteParams>(AdministratorsRoute, {
    subscriptionId,
  });
  const usersUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionUsersRoute, {
    subscriptionId,
  });
  const projectsUrl = buildPath<EnsuredSubscriptionRouteParams>(ProjectsInSubscriptionRoute, {
    subscriptionId,
  });
  const apiKeysUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionApiKeysRoute, {
    subscriptionId,
  });

  const activeItemId = getActiveItemId(location.pathname, subscriptionId);

  return (
    <Box
      overflowX="hidden"
      overflowY="auto"
      flexGrow={0}
      flexShrink={0}
      flexBasis="auto"
      display="flex"
    >
      <VerticalTabs {...getDataUiCollectionAttribute(DataUiCollection.SubscriptionTabs)}>
        <VerticalTabs.Item
          isActive={activeItemId === usageUrl}
          label={SubscriptionTabName.Usage}
          url={usageUrl}
          {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionUsage)}
          {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
        />
        <VerticalTabs.Item
          isActive={activeItemId === generalUrl}
          label={SubscriptionTabName.General}
          url={generalUrl}
          {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionGeneral)}
          {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
        />
        {isBillingHistoryVisible && (
          <VerticalTabs.Item
            isActive={activeItemId === billingHistoryUrl}
            label={SubscriptionTabName.BillingHistory}
            url={billingHistoryUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionBillingHistory)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {isBillingInformationVisible && (
          <VerticalTabs.Item
            isActive={activeItemId === billingInformationUrl}
            label={SubscriptionTabName.BillingInfo}
            url={billingInformationUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionBillingInfo)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {isPlansTabVisible && (
          <VerticalTabs.Item
            isActive={activeItemId === plansUrl}
            label={SubscriptionTabName.Plans}
            url={plansUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionPlans)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {isTransactionsTabVisible && (
          <VerticalTabs.Item
            isActive={activeItemId === transactionsUrl}
            label={SubscriptionTabName.Transactions}
            url={transactionsUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionTransactions)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {subscription.status !== SubscriptionStatus.Canceled && (
          <VerticalTabs.Item
            isActive={activeItemId === adminsUrl}
            label={SubscriptionTabName.Admins}
            url={adminsUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionAdmins)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {subscription.status !== SubscriptionStatus.Canceled && (
          <VerticalTabs.Item
            isActive={activeItemId === usersUrl}
            label={SubscriptionTabName.Users}
            url={usersUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionUsers)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {subscription.status !== SubscriptionStatus.Canceled && (
          <VerticalTabs.Item
            isActive={activeItemId === projectsUrl}
            label={SubscriptionTabName.Projects}
            url={projectsUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionProjects)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
        {isApiKeysTabVisible && (
          <VerticalTabs.Item
            isActive={activeItemId === apiKeysUrl}
            label={SubscriptionTabName.ApiKeys}
            url={apiKeysUrl}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionApiKeys)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        )}
      </VerticalTabs>
    </Box>
  );
};

SubscriptionManagementTabs.displayName = 'SubscriptionManagementTabs';

const getActiveItemId = (pathname: string, subscriptionId: string) => {
  if (matchPath(pathname, SubscriptionUsersRoute)) {
    return buildPath<EnsuredSubscriptionRouteParams>(SubscriptionUsersRoute, { subscriptionId });
  }

  return pathname;
};
