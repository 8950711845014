import { Inline } from '@kontent-ai/component-library/Inline';
import { animated, useTrail } from '@react-spring/web';
import { forwardRef } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStepColor } from '../../../../../../data/constants/WorkflowStepColor.ts';
import { StepColorPickerItem } from './StepColorPickerItem.tsx';

type Props = {
  readonly onChange: (color: WorkflowStepColor) => void;
  readonly selectedColor: WorkflowStepColor;
};

const colors = Object.values(WorkflowStepColor);

export const StepColorPickerList = forwardRef<HTMLUListElement, Props>(
  ({ onChange, selectedColor }, ref) => {
    const trail = useTrail(colors.length, {
      from: { opacity: 0 },
      opacity: 1,
      config: {
        mass: 1,
        tension: 550,
        friction: 30,
        clamp: true,
      },
    });

    return (
      <ul
        ref={ref}
        className="workflow-step__color-picker-list"
        {...getDataUiCollectionAttribute(DataUiCollection.WorkflowStepColors)}
      >
        <Inline>
          {trail.map((style, index) => {
            const color = colors[index] ?? WorkflowStepColor.Red;
            const isSelected = color === selectedColor;

            return (
              <animated.div key={color} style={style}>
                <StepColorPickerItem
                  color={color}
                  isSelected={isSelected}
                  onClick={() => onChange(color)}
                />
              </animated.div>
            );
          })}
        </Inline>
      </ul>
    );
  },
);

StepColorPickerList.displayName = 'StepColorPickerList';
