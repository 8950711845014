import { UnreachableCaseException } from '@kontent-ai/errors';
import { DefaultSampleProjectName } from '../../../_shared/constants/defaultNames.ts';
import { getDefaultProjectLocationId } from '../../../_shared/constants/projectLocationIds.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import { IProjectServerModel } from '../../../repositories/serverModels/IProjectServerModel.type.ts';
import { SampleProjectType } from '../constants/sampleProjectType.ts';
import { waitUntilProjectIsActive } from './projectUtils.ts';

interface ICreateSampleProjectDependencies {
  readonly projectRepository: IProjectRepository;
  readonly onEmptyProjectCreated?: (project: IProjectServerModel) => void;
}

export const createSampleProjectCreator =
  (deps: ICreateSampleProjectDependencies) =>
  async (
    subscriptionId: Uuid,
    type: SampleProjectType,
    projectName?: string,
  ): Promise<IProjectServerModel> => {
    try {
      const createdProject = await deps.projectRepository.createProject({
        projectName: projectName || DefaultSampleProjectName,
        subscriptionId,
        projectLocationId: getDefaultProjectLocationId(),
      });

      await waitUntilProjectIsActive(createdProject.projectGuid, deps.projectRepository);
      deps.onEmptyProjectCreated?.(createdProject);

      switch (type) {
        case SampleProjectType.HealthTech:
          return deps.projectRepository.copyHealthTechSampleProjectData(createdProject.projectGuid);
        case SampleProjectType.DancingGoat:
          return deps.projectRepository.copyDancingGoatSampleProjectData(
            createdProject.projectGuid,
          );
        case SampleProjectType.GettingStarted:
          return deps.projectRepository.copyGettingStartedProjectData(createdProject.projectGuid);
        default:
          throw UnreachableCaseException(type);
      }
    } catch (error) {
      throw new Error(`Could not create a Sample Project because of the following error: ${error}`);
    }
  };
