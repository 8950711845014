import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableHeadRow } from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentPaceDetailTableRow } from '../containers/ContentPaceDetailTableRow.tsx';
import { ContentPaceDetailContentItem } from '../getDetailContentItemsFromServerModel.ts';

const headColumns = Immutable.List([
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-7',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Language),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Contributors),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: 'Time',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--growing-4',
  },
]);

type Props = Readonly<{
  areAllItemsLoaded: boolean;
  averageTimeInSeconds: number;
  detailContentItems: ReadonlyArray<ContentPaceDetailContentItem> | null;
  isListPartial: boolean;
  isLoadingMoreItems: boolean;
  onLoadMoreItems?: () => Promise<void>;
}>;

type ContentPaceDetailEmptyStateProps = React.PropsWithChildren<{
  readonly title: string;
}>;

const ContentPaceDetailEmptyState: React.FC<ContentPaceDetailEmptyStateProps> = ({
  title,
  children,
}) => (
  <EmptyState size="full">
    <EmptyState.Title>{title}</EmptyState.Title>
    <EmptyState.Content>{children}</EmptyState.Content>
  </EmptyState>
);

export const ContentPaceDetail: React.FC<Props> = ({
  areAllItemsLoaded,
  averageTimeInSeconds,
  detailContentItems,
  isListPartial,
  isLoadingMoreItems,
  onLoadMoreItems,
}) => {
  if (detailContentItems === null) {
    return <Loader />;
  }

  if (!detailContentItems.length) {
    if (isListPartial) {
      return (
        <ContentPaceDetailEmptyState title="You don’t have permission to view these content items.">
          Due to limited permissions, you can’t view items in this workflow step.
        </ContentPaceDetailEmptyState>
      );
    }

    return (
      <ContentPaceDetailEmptyState title="No insights yet.">
        <p>Insights for this workflow step are not available yet for the set time frame.</p>

        <p>Check back later after you make progress with your content items.</p>
      </ContentPaceDetailEmptyState>
    );
  }

  const averageTimeInHours = Math.floor(averageTimeInSeconds / 60 / 60);

  return (
    <Stack spacing={Spacing.L}>
      <Box color={colorTextDefault} typography={Typography.LabelLargeEmphasis}>
        Average time: {averageTimeInHours} hours
      </Box>

      <DataTable
        dataUiCollectionName={DataUiCollection.ContentPaceDetailListing}
        header={<DataTableHeadRow columns={headColumns} />}
      >
        {detailContentItems.map((contentItem) => {
          return (
            <ContentPaceDetailTableRow
              data={contentItem}
              key={contentItem.itemWithVariant.item.id}
            />
          );
        })}
      </DataTable>
      {!areAllItemsLoaded && (
        <Box display="flex" justifyContent="center">
          <Button buttonStyle="tertiary" disabled={isLoadingMoreItems} onClick={onLoadMoreItems}>
            {isLoadingMoreItems && <Button.ProgressIcon />}
            Load more
          </Button>
        </Box>
      )}
      {isListPartial && (
        <Callout
          calloutType="quickTip"
          hideSubheadline
          headline="Visibility is limited to select items"
        >
          You don’t have permission to view all content items in this workflow step.
        </Callout>
      )}
    </Stack>
  );
};

ContentPaceDetail.displayName = 'ContentPaceDetail';
