import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorBackgroundHover,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import { WorkflowStatus } from '../../../../../component-library/components/WorkflowStatus/WorkflowStatus.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderWorkflowStepColor } from '../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { IWorkflowStepOverview } from '../utils/createWorkflowOverview.ts';
import { WorkflowStepOverviewBar } from './WorkflowStepOverviewBar.tsx';

interface IStepOverviewProps {
  readonly maxStepCount: number;
  readonly step: IWorkflowStepOverview;
  readonly onViewWorkflowStep: (stepId: Uuid) => void;
}

type StyledWorkflowStepOverviewItemProps = {
  readonly $disabled: boolean;
};

const StyledWorkflowStepOverviewItem = styled.div<StyledWorkflowStepOverviewItemProps>`
  display: flex;
  padding: ${px(Spacing.XS)};
  align-items: center;
  border-radius: ${px(BorderRadius.Pill)};
  cursor: pointer;
  justify-content: space-between;

  &:hover {
    background-color: ${colorBackgroundHover};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  `};
`;

export const StepOverview: React.FC<IStepOverviewProps> = ({
  step,
  onViewWorkflowStep,
  maxStepCount,
}) => {
  const isDisabled = step.count === 0;

  const openWorkflowStepModal = isDisabled ? undefined : () => onViewWorkflowStep(step.id);

  return (
    <Row>
      <Column flexFactor={1}>
        <Tooltip
          tooltipText={
            isDisabled
              ? 'No content items in this workflow step were edited in the last 90 days.'
              : undefined
          }
          placement="top"
        >
          <StyledWorkflowStepOverviewItem
            onClick={openWorkflowStepModal}
            {...getDataUiActionAttribute(DataUiAction.WorkflowStepOverviewItem)}
            $disabled={isDisabled}
          >
            <Box overflow="hidden" css="text-overflow: ellipsis; white-space: nowrap;">
              <WorkflowStatus
                primary={{
                  name: step.name,
                  background: renderWorkflowStepColor(step.color),
                }}
                {...getDataUiElementAttribute(DataUiElement.WorkflowOverviewItemName)}
              />
            </Box>
            <Box typography={Typography.TitleMedium} paddingX={Spacing.S}>
              <div
                css="width: max-content"
                {...getDataUiElementAttribute(DataUiElement.WorkflowStepOverviewItemCount)}
              >
                {step.count}
              </div>
            </Box>
          </StyledWorkflowStepOverviewItem>
        </Tooltip>
      </Column>
      <Column flexFactor={3}>
        <WorkflowStepOverviewBar step={step} total={maxStepCount} onClick={openWorkflowStepModal} />
      </Column>
    </Row>
  );
};
StepOverview.displayName = 'StepOverview';
