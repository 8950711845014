import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_SubscriptionRename_Failed,
  Subscription_SubscriptionRename_Finished,
  Subscription_SubscriptionRename_Started,
} from '../constants/actionTypes.ts';

export const serverError = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Subscription_SubscriptionRename_Finished:
    case Subscription_SubscriptionRename_Started:
      return '';

    case Subscription_SubscriptionRename_Failed:
      return action.payload.serverError;

    default:
      return state;
  }
};
