import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ListingFilter_RestoreSavedFilter_Failed,
  ListingFilter_RestoreSavedFilter_Finished,
  ListingFilter_RestoreSavedFilter_Started,
} from '../../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialState: boolean = false;

export function restoreActionInProgress(state = initialState, action: Action) {
  switch (action.type) {
    case ListingFilter_RestoreSavedFilter_Started:
      return true;
    case ListingFilter_RestoreSavedFilter_Finished:
    case ListingFilter_RestoreSavedFilter_Failed:
      return false;
    default:
      return state;
  }
}
