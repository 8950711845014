import { IStore } from '../stores/IStore.type.ts';
import { getLanguageCodename } from '../utils/languageUtils.ts';

export const getSelectedLanguageCodename = (state: IStore): string => {
  const {
    data: { languages },
    sharedApp: { selectedLanguage },
  } = state;

  return getLanguageCodename(selectedLanguage.id ?? '', languages.defaultLanguage, languages.byId);
};
