import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export const getSuggestedContentTypeIds = (
  availableContentTypes: Immutable.List<IContentType>,
  suggestedContentTypeIds: ReadonlyArray<Uuid>,
  recentlyUsedContentTypeIds: null | ReadonlyArray<Uuid>,
) => {
  const suggestedIds =
    suggestedContentTypeIds.length > 0
      ? suggestedContentTypeIds
      : (recentlyUsedContentTypeIds ?? []);

  return suggestedIds.filter((id) =>
    availableContentTypes.find((contentType) => contentType?.id === id),
  );
};
