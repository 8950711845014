import { InvariantException } from '@kontent-ai/errors';
import { Action } from '../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { RequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import {
  ItemsRequestTrigger,
  getMaxItemsForLoading,
  shouldRequestNewItems,
  shouldUseLastContinuationToken,
} from '../../../../_shared/utils/scrollTableUtils.ts';
import { BuildFilterForListingContentItemsParams } from '../../../../applications/contentInventory/content/utils/buildFilterWithContinuationForListingContentItems.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ContentItemFilterWithContinuationServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import {
  Data_ContentTypeComponentUsage_Failed,
  Data_ContentTypeComponentUsage_Finished,
  Data_ContentTypeComponentUsage_Started,
} from '../../../constants/dataActionTypes.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../models/listingContentItems/IListingContentItem.ts';
import { getFreshTableState } from '../listingContentItems/loadListingContentItems.ts';

export const createTokenizedContentTypeAsComponentUsageLoadingStarted =
  (cancellationToken: Uuid) =>
  (requestTrigger: ItemsRequestTrigger.UserScroll | ItemsRequestTrigger.Other) =>
    ({
      type: Data_ContentTypeComponentUsage_Started,
      payload: {
        cancellationToken,
        requestTrigger,
      },
    }) as const;

const success = (
  contentItems: ReadonlyArray<IListingContentItem>,
  requestTrigger: ItemsRequestTrigger,
  token: ContinuationToken,
) =>
  ({
    type: Data_ContentTypeComponentUsage_Finished,
    payload: {
      contentItems,
      requestTrigger,
      token,
    },
  }) as const;

const failure = () => ({ type: Data_ContentTypeComponentUsage_Failed }) as const;

interface IDeps {
  readonly buildFilterForListingContentItems: (
    params: BuildFilterForListingContentItemsParams,
  ) => Readonly<ContentItemFilterWithContinuationServerModel>;
  readonly contentItemRepository: IContentItemRepository;
  readonly requestTokenFactory: RequestTokenFactory<
    (requestTrigger: ItemsRequestTrigger) => Action
  >;
}

export type LoadContentTypeAsComponentUsageActionsType = ReturnType<
  | ReturnType<typeof createTokenizedContentTypeAsComponentUsageLoadingStarted>
  | typeof success
  | typeof failure
>;

export type LoadContentTypeAsComponentUsageParams = {
  readonly scrollPositionChanged: boolean;
  readonly contentTypeId: Uuid;
};

export const createLoadContentTypeAsComponentUsageAction =
  (deps: IDeps) =>
  (params: LoadContentTypeAsComponentUsageParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      listingUi: { filter, orderBy },
    } = state.contentApp;

    let requestTrigger = params.scrollPositionChanged
      ? ItemsRequestTrigger.UserScroll
      : ItemsRequestTrigger.Other;

    if (!params.contentTypeId) {
      throw InvariantException('Content type id is null');
    }

    while (
      shouldRequestNewItems({
        continuationToken: getState().data.contentTypeComponentsUsage.continuationToken,
        loadingStatus: getState().data.contentTypeComponentsUsage.loadingStatus,
        numberOfLoadedItems: getState().data.contentTypeComponentsUsage.items?.length ?? 0,
        requestTrigger,
        tableState: getFreshTableState(getState()),
      })
    ) {
      const serverFilter = deps.buildFilterForListingContentItems({
        continuationToken: shouldUseLastContinuationToken(requestTrigger)
          ? getState().data.contentTypeComponentsUsage.continuationToken
          : null,
        filter,
        includePublishedVersions: true,
        maxItemsCount: getMaxItemsForLoading(getFreshTableState(getState())),
        orderBy,
        useBackUpSearchMethod: false,
      });

      const { tokenizedActionCreator: start, isCurrentTokenValid } =
        deps.requestTokenFactory(getState);
      dispatch(start(requestTrigger));

      const items = await deps.contentItemRepository.getContentItemsByComponentsTypeId(
        params.contentTypeId,
        serverFilter,
        abortSignal,
      );

      if (isCurrentTokenValid()) {
        const contentItems = items.data.map(getListingContentItemFromJS);

        dispatch(success(contentItems, requestTrigger, items.continuationToken));
      }

      requestTrigger = ItemsRequestTrigger.TooFewItemsLoaded;
    }
  };
