import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_Started,
  ProjectOverview_LoadItemsMatchingFilter_Succeeded,
  ProjectOverview_Page_Left,
} from '../../constants/projectOverviewActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case ProjectOverview_LoadItemsMatchingFilter_Started: {
      return LoadingStatus.Loading;
    }

    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      return LoadingStatus.Loaded;
    }

    case ProjectOverview_Page_Left: {
      return LoadingStatus.InitialEmpty;
    }

    default:
      return state;
  }
};
