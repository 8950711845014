import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentType_Creator_CreationFinished,
  ContentType_Editor_DuplicatingFinished,
  ContentType_Editor_SavingFinished,
} from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import { ContentModels_TypeEditor_TypeLoaded } from '../../../../applications/contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import { Data_ContentTypes_Success } from '../../../constants/dataActionTypes.ts';
import { IContentType } from '../../../models/contentModelsApp/contentTypes/ContentType.ts';

const initialState: Immutable.Map<Uuid, IContentType> = Immutable.Map();

export function byId(
  state: Immutable.Map<Uuid, IContentType> = initialState,
  action: Action,
): Immutable.Map<Uuid, IContentType> {
  switch (action.type) {
    case Data_ContentTypes_Success: {
      const contentTypes = action.payload.data.reduce(
        (reducedMap: Immutable.Map<Uuid, IContentType>, item: IContentType) =>
          reducedMap.set(item.id, item),
        Immutable.Map<Uuid, IContentType>(),
      );
      return contentTypes;
    }

    case ContentType_Creator_CreationFinished:
    case ContentModels_TypeEditor_TypeLoaded:
      return state.set(action.payload.editedContentType.id, action.payload.editedContentType);

    case ContentType_Editor_DuplicatingFinished:
    case ContentType_Editor_SavingFinished:
      return state.set(action.payload.contentType.id, action.payload.contentType);

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
