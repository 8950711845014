import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import {
  BorderRadius,
  Spacing,
  borderWidthDefault,
  colorBorderAlert,
  px,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { BarItemExpandCollapseAction } from '../../../../_shared/components/BarItems/Actions/BarItemExpandCollapseAction.tsx';
import { BarItemActions } from '../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { BarItemExpandedNoActions } from '../../../../_shared/components/BarItems/BarItemExpandedNoActions.tsx';
import { BarItemTitle } from '../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const Container = styled.div<{
  readonly $hasAlert: boolean;
}>`
  ${({ $hasAlert }) =>
    $hasAlert &&
    css`
      border-radius: ${px(BorderRadius.M)};
      box-shadow: 0 0 0 ${px(borderWidthDefault)} ${colorBorderAlert};
    `};
`;

type Props = {
  readonly enabled: boolean;
  readonly eventTriggerName: string;
  readonly checkedBarItem: boolean;
  readonly onSetCheckedBarItem: (checkedBarItem: boolean) => void;
  readonly children: ReactNode;
  readonly hasAlert: boolean;
  readonly isExpanded: boolean;
  readonly onSetIsExpanded: (isExpanded: boolean) => void;
};

export const EntityWebhookEventTriggerBar = (props: Props) => {
  const checkboxState = props.enabled ? 'default' : 'disabled';

  const renderBarItems = (isExpanded: boolean) => (
    <Container $hasAlert={props.hasAlert}>
      <div className="bar-item__wrapper bar-item__wrapper--is-flexible">
        <div
          className={classNames('bar-item__pane', {
            'bar-item__pane--is-expanded': isExpanded,
            'bar-item__pane--has-border-alert': props.hasAlert,
          })}
        >
          <div
            className={classNames('bar-item__bar', 'bar-item__bar--is-clickable', {
              'bar-item__bar--is-expanded': isExpanded,
            })}
            css={`padding: 0 ${px(Spacing.L)};`}
          >
            <BarItemActions>
              <Checkbox
                checkboxState={checkboxState}
                onToggle={props.onSetCheckedBarItem}
                checked={props.checkedBarItem}
              />
            </BarItemActions>
            <BarItemTitle
              className="bar-item__title--with-flex"
              dataUiObjectName={props.eventTriggerName}
              onClick={() => props.onSetIsExpanded(!isExpanded)}
            >
              {props.eventTriggerName}
            </BarItemTitle>
            <BarItemActions>
              <BarItemExpandCollapseAction
                isCollapsed={!isExpanded}
                onCollapse={() => props.onSetIsExpanded(false)}
                onExpand={() => props.onSetIsExpanded(true)}
              />
            </BarItemActions>
          </div>
          {isExpanded && (
            <BarItemExpandedNoActions
              dataUiElement={DataUiElement.EntityWebhookEventTriggerDetails}
            >
              {props.children}
            </BarItemExpandedNoActions>
          )}
        </div>
      </div>
    </Container>
  );

  return (
    <Box component="li" className="bar-item__node">
      <BarItemAnimation
        renderCollapsed={() => renderBarItems(false)}
        renderExpanded={() => renderBarItems(true)}
        estimatedMaxHeightWhenExpanded={300}
        shouldBeExpanded={props.isExpanded}
      />
    </Box>
  );
};

EntityWebhookEventTriggerBar.displayName = 'EntityWebhookEventTriggerBar';
