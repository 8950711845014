import { EditorState } from 'draft-js';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  IContentItemElementServerModel,
  IUrlSlugItemElementServerModel,
} from '../../../../../../repositories/serverModels/elementServerModels.type.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IUrlSlugItemElement } from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { isUrlSlugElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { urlSlugElementDomainModelConverter } from '../../../../utils/getItemElementDataConverters.ts';
import { areUrlSlugsItemElementsTheSame } from '../../../../utils/itemElementsEqualityCheckers/urlSlugItemElementsEqualityChecker.ts';

interface IDeps {
  readonly updateAutogeneratedUrlSlugElements: (
    patchedElements: ReadonlyArray<ICompiledContentItemElementData>,
  ) => Action;
  readonly onUrlSlugElementValueChange: (
    elementId: Uuid,
    newEditorState: EditorState,
  ) => ThunkFunction;
}

const isAutoGeneratedUrlSlug = (
  element: IContentItemElementServerModel,
): element is IUrlSlugItemElementServerModel => {
  return isUrlSlugElement(element) && element.mode === UrlSlugMode.Auto;
};

function getUpdatedUrlSlugElements(
  patchedElements: ReadonlyArray<IContentItemElementServerModel>,
  originalElements: ReadonlyArray<ICompiledContentItemElementData>,
): ReadonlyArray<IUrlSlugItemElement> {
  const patchedUrlSlugElements = patchedElements
    .filter(isAutoGeneratedUrlSlug)
    .map(urlSlugElementDomainModelConverter)
    .filter((element) => {
      const originalElement = getElementById(element.elementId, originalElements);

      return (
        !isUrlSlugElement(originalElement) ||
        !areUrlSlugsItemElementsTheSame(element, originalElement)
      );
    });

  return patchedUrlSlugElements;
}

export const createUpdateAutogeneratedUrlSlugAfterDataPatch =
  (deps: IDeps) =>
  (
    patchedElements: ReadonlyArray<IContentItemElementServerModel>,
    originalElements: ReadonlyArray<ICompiledContentItemElementData>,
  ): ThunkFunction =>
  (dispatch) => {
    const updatedUrlSlugElements = getUpdatedUrlSlugElements(patchedElements, originalElements);
    if (updatedUrlSlugElements.length > 0) {
      dispatch(deps.updateAutogeneratedUrlSlugElements(updatedUrlSlugElements));
      const slugElements = updatedUrlSlugElements.map((element) => element);
      for (const element of slugElements) {
        dispatch(deps.onUrlSlugElementValueChange(element.elementId, element._editorState));
      }
    }
  };
