import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { IProjectServerModel } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import {
  Project_CreateSampleProject_Failed,
  Project_CreateSampleProject_Finished,
  Project_CreateSampleProject_Started,
} from '../../constants/projectActionTypes.ts';
import { SampleProjectType } from '../../constants/sampleProjectType.ts';
import { createSampleProjectCreator as _createSampleProjectCreator } from '../../utils/createSampleProjectUtils.ts';
import { projectCreationInitialized } from '../projectsActions.ts';

interface ICreateSampleProjectCreatorDependencies {
  readonly loadProjects: () => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

const start = () =>
  ({
    type: Project_CreateSampleProject_Started,
  }) as const;

const fail = () =>
  ({
    type: Project_CreateSampleProject_Failed,
  }) as const;

const finish = (projectId: Uuid) =>
  ({
    type: Project_CreateSampleProject_Finished,
    payload: {
      projectId,
    },
  }) as const;

export type CreateSampleProjectActionsType = ReturnType<typeof start | typeof fail | typeof finish>;

export const createSampleProjectCreator =
  (deps: ICreateSampleProjectCreatorDependencies) =>
  (subscriptionId: Uuid, type: SampleProjectType, projectName: string): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());
    try {
      const onEmptyProjectCreated = (project: IProjectServerModel) =>
        dispatch(projectCreationInitialized(project.projectGuid));

      const createdProject = await _createSampleProjectCreator({ ...deps, onEmptyProjectCreated })(
        subscriptionId,
        type,
        projectName,
      );

      await dispatch(deps.loadUserProjectsInfo());
      await dispatch(deps.loadProjects());

      const feature =
        type === SampleProjectType.DancingGoat
          ? 'create-sample-project-modal--submitted'
          : 'create-new-sample-project-modal--submitted';
      dispatch(deps.trackUserEventWithData(TrackedEvent.FeatureUsed, { feature }));

      dispatch(finish(createdProject.projectGuid));
    } catch (error) {
      dispatch(fail());

      throw InvariantException(
        `Could not create a sample project because of the following error: ${error}`,
      );
    }
  };
