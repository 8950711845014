import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { IconSize, Spacing, colorTextHint } from '@kontent-ai/component-library/tokens';
import React from 'react';

interface IProps {
  readonly message: string;
}

export const AssetDescriptionAiLoader: React.FC<IProps> = ({ message }) => (
  <Inline align="center" noWrap spacingX={Spacing.S}>
    <Icons.KopilotAi size={IconSize.S} />
    <Label size={LabelSize.L} color={colorTextHint}>
      {message}
    </Label>
    <AnimatedProgressIcon />
  </Inline>
);

AssetDescriptionAiLoader.displayName = 'AssetDescriptionAiLoader';
