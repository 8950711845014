import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import {
  ISavedFilter,
  getSavedFilterUpdateServerModel,
} from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { IFilterRepository } from '../../../../../../../repositories/interfaces/IFilterRepository.type.ts';
import { IFilterServerModel } from '../../../../../../../repositories/serverModels/FilterServerModels.type.ts';
import {
  ListingFilter_UpdateSavedFilter_Failed,
  ListingFilter_UpdateSavedFilter_Finished,
  ListingFilter_UpdateSavedFilter_Started,
} from '../../constants/listingFilterActionTypes.ts';
import { UnableToRenameErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly filterRepository: IFilterRepository;
}

interface IParams {
  readonly filter: ISavedFilter;
}

const started = (filterId: Uuid) =>
  ({
    type: ListingFilter_UpdateSavedFilter_Started,
    payload: {
      filterId,
    },
  }) as const;

const finished = (filter: IFilterServerModel) =>
  ({
    type: ListingFilter_UpdateSavedFilter_Finished,
    payload: { filter },
  }) as const;

const failed = (filterId: Uuid, errorMessage: string) =>
  ({
    type: ListingFilter_UpdateSavedFilter_Failed,
    payload: {
      errorMessage,
      filterId,
    },
  }) as const;

export type UpdateSavedFilterActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createUpdateSavedFilterAction =
  (deps: IDeps) =>
  ({ filter }: IParams): ThunkPromise =>
  async (dispatch) => {
    dispatch(started(filter.id));

    try {
      const filterServerModel = getSavedFilterUpdateServerModel(filter);
      const savedFilter = await deps.filterRepository.updateFilter(filter.id, filterServerModel);
      dispatch(finished(savedFilter));
    } catch (e) {
      dispatch(failed(filter.id, UnableToRenameErrorMessage));
      throw e;
    }
  };
