import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ICopyingDraftDataActionButtonProps {
  readonly text: string;
}

export const CopyingDraftDataActionButton: FC<ICopyingDraftDataActionButtonProps> = ({ text }) => (
  <div className="my-project__button-wrapper">
    <Button
      buttonStyle="secondary"
      buttonDisplay="block"
      disabled
      {...getDataUiElementAttribute(DataUiElement.ProjectCopyStatus)}
    >
      <Button.ProgressIcon
        {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
      />
      <Button.Label>{text}</Button.Label>
    </Button>
  </div>
);

CopyingDraftDataActionButton.displayName = 'CopyingDraftDataActionButton';
