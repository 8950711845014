import { CalendarArrangementStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';
import {
  CalendarArrangement,
  isCalendarArrangement,
} from '../applications/contentPlanning/calendar/models/CalendarArrangement.ts';

let _arrangement: CalendarArrangement | null = null;

export const calendarArrangementStorage = {
  save(arrangement: CalendarArrangement): void {
    localStorage.set(CalendarArrangementStorageKey, arrangement);
    _arrangement = arrangement;
  },

  forget(): void {
    localStorage.remove(CalendarArrangementStorageKey);
    _arrangement = null;
  },

  load(): CalendarArrangement {
    if (!_arrangement) {
      const value = localStorage.get(CalendarArrangementStorageKey);
      if (isCalendarArrangement(value)) {
        _arrangement = value;
      }

      if (!_arrangement) {
        return CalendarArrangement.Month;
      }
    }

    return _arrangement;
  },
};
