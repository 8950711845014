import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ItemColumnCode } from '../../../../../../_shared/constants/itemColumnCode.ts';
import {
  ContentListing_Columns_HideColumn,
  ContentListing_Columns_Init,
  ContentListing_Columns_ShowColumn,
} from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

export function hiddenColumns(
  state: Immutable.Set<ItemColumnCode> = Immutable.Set(),
  action: Action,
): Immutable.Set<ItemColumnCode> {
  switch (action.type) {
    case ContentListing_Columns_Init:
      return action.payload.hiddenColumns;

    case ContentListing_Columns_ShowColumn:
      return state.filterNot((column: ItemColumnCode) => column === action.payload.column).toSet();

    case ContentListing_Columns_HideColumn:
      return state.add(action.payload.column);

    default:
      return state;
  }
}
