import { ReactNode } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { unmountEditor } from '../../../_shared/actions/thunks/unmountEditor.ts';
import { useRedirectPropsWithSameSearch } from '../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  ContentItemEditorRouteParams,
  ContentItemEditorRoutes,
  ContentItemOpenCommentRoute,
  ContentItemOpenCommentRouteParams,
  ContentItemOpenTaskRoute,
  ContentItemOpenTaskRouteParams,
  ContentItemPreviewRoute,
  ContentItemPreviewWithEditorAccessDeniedRoute,
  ContentItemPreviewWithEditorAccessDeniedRouteParams,
  ContentItemPreviewWithEditorRouteParams,
  ContentItemRevisionViewerRoute,
  ContentItemRevisionViewerRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemTimelineItemRoute,
  ContentItemTimelineLatestPublishedRoute,
  ContentItemTimelineLatestPublishedRouteParams,
} from '../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../_shared/containers/routing/AuthorizedSection.tsx';
import { IRouteContext, RouteContext } from '../../../_shared/containers/routing/RouteContext.tsx';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import {
  buildPath,
  getEditedContentItemId,
  matchPath,
  parseContentItemIds,
} from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { EnsureFocusedCommentThread } from '../../contentInventory/shared/containers/EnsureFocusedCommentThread.tsx';
import { EnsureHighlightedTask } from '../../contentInventory/shared/containers/EnsureHighlightedTask.tsx';
import { RedirectToLatestPublishedRevision } from '../../contentInventory/shared/containers/RedirectToLatestPublishedRevision.tsx';
import { contentRequiredCapabilities } from '../../contentInventory/shared/utils/contentInventoryRequiredCapabilities.ts';
import { ItemLiveUserPresenceMonitor } from '../features/ContentItemEditing/components/ItemLiveUserPresenceMonitor.tsx';
import { EnsureEditedItemInitialized } from '../features/ContentItemEditing/containers/EnsureEditedItemInitialized.tsx';
import { EnsureEntryTimeline } from '../features/ContentItemEditing/containers/EnsureEntryTimeline.tsx';
import { EnsureTemporaryItemDeletion } from '../features/ContentItemEditing/containers/EnsureTemporaryItemDeletion.tsx';
import { HandlePendingNavigation } from '../features/ContentItemEditing/containers/HandlePendingNavigation.tsx';
import { WaitForEditedItemInitialized } from '../features/ContentItemEditing/containers/WaitForEditedItemInitialized.tsx';
import { IsJustCreatedItemCloneProvider } from '../features/ContentItemEditing/context/IsJustCreatedItemCloneContext.tsx';

function getEditedItemKey(
  params: ContentItemEditorRouteParams<string> | ContentItemPreviewWithEditorRouteParams<string>,
  isExact: boolean,
  pathname: string,
): string {
  const contentItemIds = parseContentItemIds(params.contentItemIds);
  const lastContentItemId = contentItemIds[contentItemIds.length - 1];

  if (!isExact) {
    const accessDeniedMatch = matchPath<
      ContentItemPreviewWithEditorAccessDeniedRouteParams<string>
    >(pathname, ContentItemPreviewWithEditorAccessDeniedRoute);

    if (accessDeniedMatch) {
      return `${accessDeniedMatch.projectId}_${accessDeniedMatch.variantId}_${accessDeniedMatch.editedItemId}_${accessDeniedMatch.requestedAction}`;
    }
  }

  return `${params.projectId}_${params.variantId}_${params.editedItemId ?? lastContentItemId}`;
}

type Props = {
  readonly renderEditor: () => ReactNode;
  readonly renderNotInitialized?: () => ReactNode;
  readonly renderRevision: (timelineItemId: Uuid) => ReactNode;
  readonly renderPreview?: () => ReactNode;
};

export const ContentItemEditing = ({
  renderEditor,
  renderNotInitialized,
  renderPreview,
  renderRevision,
}: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  const contentItemEditorMatch = useRouteMatch<
    ContentItemEditorRouteParams<string> | ContentItemPreviewWithEditorRouteParams<string>
  >(ContentItemEditorRoutes);

  if (!contentItemEditorMatch) return null;

  const contentItemEditorKey = getEditedItemKey(
    contentItemEditorMatch.params,
    contentItemEditorMatch.isExact,
    location.pathname,
  );

  return (
    <IsJustCreatedItemCloneProvider>
      <ItemLiveUserPresenceMonitor />
      <>
        <EnsureTemporaryItemDeletion key={contentItemEditorKey} />
        <Switch>
          <Route path={ContentItemTimelineItemRoute}>
            <AuthorizedSection
              appName={AppNames.ContentItemRevisionViewer}
              requiresOneOfCapabilities={contentRequiredCapabilities}
            >
              <Switch>
                <Route path={ContentItemRevisionViewerRoute}>
                  <RouteContext>
                    {({
                      match: {
                        params: { timelineItemId },
                      },
                    }: IRouteContext<ContentItemRevisionViewerRouteParams<string>>) => (
                      <>
                        <EnsureEditedItemInitialized key={contentItemEditorKey}>
                          <HandlePendingNavigation />
                        </EnsureEditedItemInitialized>
                        <WaitForEditedItemInitialized renderWhileWaiting={renderNotInitialized}>
                          <EnsureEntryTimeline key={contentItemEditorKey}>
                            {renderRevision(timelineItemId)}
                          </EnsureEntryTimeline>
                        </WaitForEditedItemInitialized>
                      </>
                    )}
                  </RouteContext>
                </Route>
                <Route exact path={ContentItemTimelineLatestPublishedRoute}>
                  <RouteContext>
                    {({
                      history,
                    }: IRouteContext<ContentItemTimelineLatestPublishedRouteParams<string>>) => (
                      <RedirectToLatestPublishedRevision
                        history={history}
                        app={contentItemEditorMatch.params.app}
                        projectId={contentItemEditorMatch.params.projectId}
                        variantId={contentItemEditorMatch.params.variantId}
                        spaceId={contentItemEditorMatch.params.spaceId}
                        contentItemId={getEditedContentItemId(contentItemEditorMatch.params)}
                      />
                    )}
                  </RouteContext>
                </Route>
                <Route>
                  <Redirect
                    to={buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
                      app: contentItemEditorMatch.params.app,
                      projectId: contentItemEditorMatch.params.projectId,
                      variantId: contentItemEditorMatch.params.variantId,
                      spaceId: contentItemEditorMatch.params.spaceId,
                      contentItemIds: parseContentItemIds(
                        contentItemEditorMatch.params.contentItemIds,
                      ),
                    })}
                  />
                </Route>
              </Switch>
            </AuthorizedSection>
          </Route>
          <Route path={ContentItemEditorRoutes}>
            <RouteContext>
              {(routeProps: IRouteContext<ContentItemEditorRouteParams<string>>) => (
                // Preview route does not depend on edited item being loaded, so we separate the initializer and the waiting component
                // Still, preview uses edited item initialization to get preview URL patterns and initialize floating editor
                <AuthorizedSection
                  appName={AppNames.ContentItemEditor}
                  requiresOneOfCapabilities={contentRequiredCapabilities}
                >
                  <EnsureEditedItemInitialized
                    key={getEditedItemKey(
                      routeProps.match.params,
                      routeProps.match.isExact,
                      location.pathname,
                    )}
                    onRouteLeft={() => {
                      dispatch(unmountEditor());
                    }}
                  >
                    <HandlePendingNavigation />
                  </EnsureEditedItemInitialized>
                  <Switch>
                    <Route exact path={ContentItemOpenCommentRoute}>
                      <RouteContext>
                        {(
                          routeContext: IRouteContext<ContentItemOpenCommentRouteParams<string>>,
                        ) => {
                          const redirectPath = buildPath<ContentItemRouteParams<UuidArray>>(
                            ContentItemRoute,
                            {
                              app: routeContext.match.params.app,
                              projectId: routeContext.match.params.projectId,
                              variantId: routeContext.match.params.variantId,
                              spaceId: routeContext.match.params.spaceId,
                              contentItemIds: parseContentItemIds(
                                routeContext.match.params.contentItemIds,
                              ),
                            },
                          );
                          return (
                            <WaitForEditedItemInitialized renderWhileWaiting={renderNotInitialized}>
                              <EnsureFocusedCommentThread
                                redirectPath={redirectPath}
                                commentThreadId={routeContext.match.params.commentThreadId}
                              />
                              <Redirect to={redirectPath} />
                            </WaitForEditedItemInitialized>
                          );
                        }}
                      </RouteContext>
                    </Route>
                    <Route exact path={ContentItemOpenTaskRoute}>
                      <RouteContext>
                        {(routeContext: IRouteContext<ContentItemOpenTaskRouteParams<string>>) => {
                          const redirectPath = buildPath<ContentItemRouteParams<UuidArray>>(
                            ContentItemRoute,
                            {
                              app: routeContext.match.params.app,
                              projectId: routeContext.match.params.projectId,
                              variantId: routeContext.match.params.variantId,
                              spaceId: routeContext.match.params.spaceId,
                              contentItemIds: parseContentItemIds(
                                routeContext.match.params.contentItemIds,
                              ),
                            },
                          );
                          return (
                            <WaitForEditedItemInitialized renderWhileWaiting={renderNotInitialized}>
                              <EnsureHighlightedTask taskId={routeContext.match.params.taskId} />
                              <Redirect {...getRedirectPropsWithSameSearch({ to: redirectPath })} />
                            </WaitForEditedItemInitialized>
                          );
                        }}
                      </RouteContext>
                    </Route>
                    {renderPreview && (
                      // Preview is not depending on edited item being loaded, so it is not waiting for it
                      <Route path={ContentItemPreviewRoute}>{renderPreview()}</Route>
                    )}
                    <Route>
                      <WaitForEditedItemInitialized renderWhileWaiting={renderNotInitialized}>
                        {renderEditor()}
                      </WaitForEditedItemInitialized>
                    </Route>
                  </Switch>
                </AuthorizedSection>
              )}
            </RouteContext>
          </Route>
        </Switch>
      </>
    </IsJustCreatedItemCloneProvider>
  );
};
