import React from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getProjectType } from '../../../_shared/models/utils/quickstartUtils.ts';
import { compose } from '../../../_shared/utils/func/compose.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { Quickstart as QuickstartComponent } from '../components/Quickstart.tsx';

export const Quickstart: React.FC = () => {
  const projectType = useSelector(compose(getProjectType, getCurrentProject));

  return <QuickstartComponent projectType={projectType} />;
};

Quickstart.displayName = 'Quickstart';
