// This file is generated upon a change in icons. Do not change it manually.
export enum IconName {
  ALowercase = 'icon-a-lowercase',
  Accordion = 'icon-accordion',
  Android = 'icon-android',
  Apple = 'icon-apple',
  ArrowCrookedLeft = 'icon-arrow-crooked-left',
  ArrowCrookedRight = 'icon-arrow-crooked-right',
  ArrowCurvedLeft = 'icon-arrow-curved-left',
  ArrowCurvedRight = 'icon-arrow-curved-right',
  ArrowDoubleLeft = 'icon-arrow-double-left',
  ArrowDoubleRight = 'icon-arrow-double-right',
  ArrowDown = 'icon-arrow-down',
  ArrowDownCircle = 'icon-arrow-down-circle',
  ArrowDownLine = 'icon-arrow-down-line',
  ArrowEnterSquare = 'icon-arrow-enter-square',
  ArrowLeaveSquare = 'icon-arrow-leave-square',
  ArrowLeft = 'icon-arrow-left',
  ArrowLeftCircle = 'icon-arrow-left-circle',
  ArrowLeftRect = 'icon-arrow-left-rect',
  ArrowRight = 'icon-arrow-right',
  ArrowRightCircle = 'icon-arrow-right-circle',
  ArrowRightRect = 'icon-arrow-right-rect',
  ArrowRightTopSquare = 'icon-arrow-right-top-square',
  ArrowULeft = 'icon-arrow-u-left',
  ArrowURight = 'icon-arrow-u-right',
  ArrowUp = 'icon-arrow-up',
  ArrowUpCircle = 'icon-arrow-up-circle',
  ArrowUpLine = 'icon-arrow-up-line',
  Arrows = 'icon-arrows',
  ArrowsCrooked = 'icon-arrows-crooked',
  ArrowsH = 'icon-arrows-h',
  ArrowsV = 'icon-arrows-v',
  B = 'icon-b',
  Badge = 'icon-badge',
  BanSign = 'icon-ban-sign',
  Bell = 'icon-bell',
  BezierScheme = 'icon-bezier-scheme',
  Bin = 'icon-bin',
  Binder = 'icon-binder',
  BookOpened = 'icon-book-opened',
  Box = 'icon-box',
  BoxCart = 'icon-box-cart',
  BoxClock = 'icon-box-clock',
  BoxDisc = 'icon-box-disc',
  Boxes = 'icon-boxes',
  Braces = 'icon-braces',
  BracesOctothorpe = 'icon-braces-octothorpe',
  BrandBing = 'icon-brand-bing',
  BrandFacebook = 'icon-brand-facebook',
  BrandGoogle = 'icon-brand-google',
  BrandGooglePlus = 'icon-brand-google-plus',
  BrandInstagram = 'icon-brand-instagram',
  BrandLinkedin = 'icon-brand-linkedin',
  BrandMstranslator = 'icon-brand-mstranslator',
  BrandOpenId = 'icon-brand-open-id',
  BrandPinterest = 'icon-brand-pinterest',
  BrandSharepoint = 'icon-brand-sharepoint',
  BrandTwitter = 'icon-brand-twitter',
  BrandYoutube = 'icon-brand-youtube',
  Breadcrumb = 'icon-breadcrumb',
  Briefcase = 'icon-briefcase',
  Broom = 'icon-broom',
  Brush = 'icon-brush',
  BrushSlashed = 'icon-brush-slashed',
  Bubble = 'icon-bubble',
  BubbleCensored = 'icon-bubble-censored',
  BubbleCheck = 'icon-bubble-check',
  BubbleHeart = 'icon-bubble-heart',
  BubbleHide = 'icon-bubble-hide',
  BubbleInverted = 'icon-bubble-inverted',
  BubblePencil = 'icon-bubble-pencil',
  BubbleShow = 'icon-bubble-show',
  BubbleTimes = 'icon-bubble-times',
  BubbleWithLines = 'icon-bubble-with-lines',
  Bubbles = 'icon-bubbles',
  BucketShovel = 'icon-bucket-shovel',
  Bug = 'icon-bug',
  Building = 'icon-building',
  BuildingBlock = 'icon-building-block',
  Bullseye = 'icon-bullseye',
  CSharp = 'icon-c-sharp',
  Calendar = 'icon-calendar',
  CalendarNumber = 'icon-calendar-number',
  Camera = 'icon-camera',
  Cancel = 'icon-cancel',
  CaretDown = 'icon-caret-down',
  CaretLeft = 'icon-caret-left',
  CaretLeftAlignedRight = 'icon-caret-left-aligned-right',
  CaretRight = 'icon-caret-right',
  CaretRightAlignedLeft = 'icon-caret-right-aligned-left',
  CaretRightDown = 'icon-caret-right-down',
  CaretUp = 'icon-caret-up',
  Carousel = 'icon-carousel',
  CbCheck = 'icon-cb-check',
  CbCheckDisabled = 'icon-cb-check-disabled',
  CbCheckInverted = 'icon-cb-check-inverted',
  CbCheckMinus = 'icon-cb-check-minus',
  CbCheckPreview = 'icon-cb-check-preview',
  CbCheckSign = 'icon-cb-check-sign',
  CbUncheck = 'icon-cb-uncheck',
  Chain = 'icon-chain',
  ChainBroken = 'icon-chain-broken',
  ChainSlash = 'icon-chain-slash',
  Check = 'icon-check',
  CheckCircle = 'icon-check-circle',
  CheckCircleInverted = 'icon-check-circle-inverted',
  Checklist = 'icon-checklist',
  ChevronDoubleDown = 'icon-chevron-double-down',
  ChevronDoubleLeft = 'icon-chevron-double-left',
  ChevronDoubleRight = 'icon-chevron-double-right',
  ChevronDoubleUp = 'icon-chevron-double-up',
  ChevronDown = 'icon-chevron-down',
  ChevronDownCircle = 'icon-chevron-down-circle',
  ChevronDownLine = 'icon-chevron-down-line',
  ChevronDownSquare = 'icon-chevron-down-square',
  ChevronLeft = 'icon-chevron-left',
  ChevronLeftCircle = 'icon-chevron-left-circle',
  ChevronLeftLine = 'icon-chevron-left-line',
  ChevronLeftSquare = 'icon-chevron-left-square',
  ChevronRight = 'icon-chevron-right',
  ChevronRightCircle = 'icon-chevron-right-circle',
  ChevronRightLine = 'icon-chevron-right-line',
  ChevronRightSquare = 'icon-chevron-right-square',
  ChevronUp = 'icon-chevron-up',
  ChevronUpCircle = 'icon-chevron-up-circle',
  ChevronUpLine = 'icon-chevron-up-line',
  ChevronUpSquare = 'icon-chevron-up-square',
  ChoiceMultiScheme = 'icon-choice-multi-scheme',
  ChoiceSingleScheme = 'icon-choice-single-scheme',
  ChoiceUserScheme = 'icon-choice-user-scheme',
  Chrome = 'icon-chrome',
  Circle = 'icon-circle',
  CircleDashed = 'icon-circle-dashed',
  CircleInverted = 'icon-circle-inverted',
  CircleSlashed = 'icon-circle-slashed',
  CircleSquare = 'icon-circle-square',
  Clapperboard = 'icon-clapperboard',
  ClipboardChecklist = 'icon-clipboard-checklist',
  ClipboardList = 'icon-clipboard-list',
  Clock = 'icon-clock',
  Cloud = 'icon-cloud',
  Cogwheel = 'icon-cogwheel',
  CogwheelSquare = 'icon-cogwheel-square',
  Cogwheels = 'icon-cogwheels',
  Collapse = 'icon-collapse',
  CollapseScheme = 'icon-collapse-scheme',
  Compass = 'icon-compass',
  Convert = 'icon-convert',
  Cookie = 'icon-cookie',
  Crop = 'icon-crop',
  CrosshairF = 'icon-crosshair-f',
  CrosshairO = 'icon-crosshair-o',
  Cup = 'icon-cup',
  CustomElement = 'icon-custom-element',
  Database = 'icon-database',
  Desktop = 'icon-desktop',
  DialogWindow = 'icon-dialog-window',
  DialogWindowCogwheel = 'icon-dialog-window-cogwheel',
  Diamond = 'icon-diamond',
  Doc = 'icon-doc',
  DocArrows = 'icon-doc-arrows',
  DocBanSign = 'icon-doc-ban-sign',
  DocCopy = 'icon-doc-copy',
  DocInverted = 'icon-doc-inverted',
  DocMove = 'icon-doc-move',
  DocPaste = 'icon-doc-paste',
  DocPlus = 'icon-doc-plus',
  DocTorn = 'icon-doc-torn',
  DocUser = 'icon-doc-user',
  DollarSign = 'icon-dollar-sign',
  DotsVertical = 'icon-dots-vertical',
  DownDir = 'icon-down-dir',
  Drawers = 'icon-drawers',
  EBook = 'icon-e-book',
  Earth = 'icon-earth',
  Edge = 'icon-edge',
  Edit = 'icon-edit',
  EkgLine = 'icon-ekg-line',
  Ellipsis = 'icon-ellipsis',
  EngageUsers = 'icon-engage-users',
  ExclamationTriangle = 'icon-exclamation-triangle',
  ExclamationTriangleInverted = 'icon-exclamation-triangle-inverted',
  Expand = 'icon-expand',
  Eye = 'icon-eye',
  EyeSlash = 'icon-eye-slash',
  F = 'icon-f',
  Factory = 'icon-factory',
  File = 'icon-file',
  FilePdf = 'icon-file-pdf',
  Filter1 = 'icon-filter-1',
  Firefox = 'icon-firefox',
  Flag = 'icon-flag',
  Folder = 'icon-folder',
  FolderBelt = 'icon-folder-belt',
  FolderClock = 'icon-folder-clock',
  FolderInverted = 'icon-folder-inverted',
  FolderOpened = 'icon-folder-opened',
  Form = 'icon-form',
  Funnel = 'icon-funnel',
  Gauge = 'icon-gauge',
  Gift = 'icon-gift',
  GraduateCap = 'icon-graduate-cap',
  Graph = 'icon-graph',
  H1 = 'icon-h-1',
  H2 = 'icon-h-2',
  H3 = 'icon-h-3',
  H4 = 'icon-h-4',
  H5 = 'icon-h-5',
  H6 = 'icon-h-6',
  HalfArrowsRightLeft = 'icon-half-arrows-right-left',
  HatMoustache = 'icon-hat-moustache',
  Heart = 'icon-heart',
  Heartshake = 'icon-heartshake',
  Highlighter = 'icon-highlighter',
  Home = 'icon-home',
  I = 'icon-i',
  ICircle = 'icon-i-circle',
  IdCard = 'icon-id-card',
  IdCards = 'icon-id-cards',
  Indent = 'icon-indent',
  IntegrationScheme = 'icon-integration-scheme',
  Intercom = 'icon-intercom',
  InternetExplorer = 'icon-internet-explorer',
  Invoice = 'icon-invoice',
  Kentico = 'icon-kentico',
  Key = 'icon-key',
  LArticleMap = 'icon-l-article-map',
  LCalendarNumberArticle = 'icon-l-calendar-number-article',
  LCols2 = 'icon-l-cols-2',
  LCols206020 = 'icon-l-cols-20-60-20',
  LCols2080 = 'icon-l-cols-20-80',
  LCols255025 = 'icon-l-cols-25-50-25',
  LCols3 = 'icon-l-cols-3',
  LCols3070 = 'icon-l-cols-30-70',
  LCols4 = 'icon-l-cols-4',
  LCols7030 = 'icon-l-cols-70-30',
  LCols8020 = 'icon-l-cols-80-20',
  LForms2 = 'icon-l-forms-2',
  LGrid22 = 'icon-l-grid-2-2',
  LGrid32 = 'icon-l-grid-3-2',
  LHeaderCols2Footer = 'icon-l-header-cols-2-footer',
  LHeaderCols3Footer = 'icon-l-header-cols-3-footer',
  LHeaderListImg = 'icon-l-header-list-img',
  LHeaderMenuText = 'icon-l-header-menu-text',
  LHeaderText = 'icon-l-header-text',
  LImg2Cols3 = 'icon-l-img-2-cols-3',
  LImg3Cols3 = 'icon-l-img-3-cols-3',
  LLightbox = 'icon-l-lightbox',
  LListArticle = 'icon-l-list-article',
  LListArticleCol = 'icon-l-list-article-col',
  LListImgArticle = 'icon-l-list-img-article',
  LListTitle = 'icon-l-list-title',
  LLogotypeMenuColFooter = 'icon-l-logotype-menu-col-footer',
  LLogotypeMenuHCol = 'icon-l-logotype-menu-h-col',
  LLogotypeMenuVCol = 'icon-l-logotype-menu-v-col',
  LMenuCols2 = 'icon-l-menu-cols-2',
  LMenuCols3 = 'icon-l-menu-cols-3',
  LMenuList = 'icon-l-menu-list',
  LMenuListImg = 'icon-l-menu-list-img',
  LMenuListImgCol = 'icon-l-menu-list-img-col',
  LMenuText = 'icon-l-menu-text',
  LMenuTextCol = 'icon-l-menu-text-col',
  LMenuTextColBottom = 'icon-l-menu-text-col-bottom',
  LRows2 = 'icon-l-rows-2',
  LRows3 = 'icon-l-rows-3',
  LRows4 = 'icon-l-rows-4',
  LText = 'icon-l-text',
  LTextCol = 'icon-l-text-col',
  Laptop = 'icon-laptop',
  Layout = 'icon-layout',
  Layouts = 'icon-layouts',
  LifeBelt = 'icon-life-belt',
  LightBulb = 'icon-light-bulb',
  Linux = 'icon-linux',
  List = 'icon-list',
  ListBullets = 'icon-list-bullets',
  ListNumbers = 'icon-list-numbers',
  Lock = 'icon-lock',
  LockUnlocked = 'icon-lock-unlocked',
  Loop = 'icon-loop',
  Magnifier = 'icon-magnifier',
  MagnifierMinus = 'icon-magnifier-minus',
  MagnifierPlus = 'icon-magnifier-plus',
  Map = 'icon-map',
  MapMarker = 'icon-map-marker',
  Market = 'icon-market',
  Mask = 'icon-mask',
  MediaPlayer = 'icon-media-player',
  Menu = 'icon-menu',
  Merge = 'icon-merge',
  Message = 'icon-message',
  Messages = 'icon-messages',
  Microphone = 'icon-microphone',
  Minus = 'icon-minus',
  MinusCircle = 'icon-minus-circle',
  MinusSquare = 'icon-minus-square',
  Mobile = 'icon-mobile',
  ModalClose = 'icon-modal-close',
  ModalMaximize = 'icon-modal-maximize',
  ModalMinimize = 'icon-modal-minimize',
  ModalQuestion = 'icon-modal-question',
  MoneyBill = 'icon-money-bill',
  Monitor = 'icon-monitor',
  MonitorBroken = 'icon-monitor-broken',
  MonitorSmartphone = 'icon-monitor-smartphone',
  Mug = 'icon-mug',
  Newspaper = 'icon-newspaper',
  Note = 'icon-note',
  Notebook = 'icon-notebook',
  Octothorpe = 'icon-octothorpe',
  One = 'icon-one',
  Opera = 'icon-opera',
  OrganisationalScheme = 'icon-organisational-scheme',
  Outdent = 'icon-outdent',
  Palette = 'icon-palette',
  Pants = 'icon-pants',
  PaperPlane = 'icon-paper-plane',
  Paperclip = 'icon-paperclip',
  Paragraph = 'icon-paragraph',
  ParagraphCenter = 'icon-paragraph-center',
  ParagraphShort = 'icon-paragraph-short',
  ParentChildScheme = 'icon-parent-child-scheme',
  ParentChildSchemeInverted = 'icon-parent-child-scheme-inverted',
  ParentChildrenScheme2 = 'icon-parent-children-scheme-2',
  ParentChildrenScheme3 = 'icon-parent-children-scheme-3',
  Pause = 'icon-pause',
  Pc = 'icon-pc',
  Pda = 'icon-pda',
  PercentSign = 'icon-percent-sign',
  Perfume = 'icon-perfume',
  PermissionList = 'icon-permission-list',
  Personalisation = 'icon-personalisation',
  PersonalisationVariants = 'icon-personalisation-variants',
  Picture = 'icon-picture',
  Pictures = 'icon-pictures',
  Piechart = 'icon-piechart',
  PiechartLines = 'icon-piechart-lines',
  Pilcrow = 'icon-pilcrow',
  Pin = 'icon-pin',
  PinInverted = 'icon-pin-inverted',
  Placeholder = 'icon-placeholder',
  Plus = 'icon-plus',
  PlusCircle = 'icon-plus-circle',
  PlusSquare = 'icon-plus-square',
  Printer = 'icon-printer',
  ProcessScheme = 'icon-process-scheme',
  ProjectScheme = 'icon-project-scheme',
  Puzzle = 'icon-puzzle',
  PuzzlePlus = 'icon-puzzle-plus',
  QrCode = 'icon-qr-code',
  QuestionCircle = 'icon-question-circle',
  RbCheck = 'icon-rb-check',
  RbCheckSign = 'icon-rb-check-sign',
  RbUncheck = 'icon-rb-uncheck',
  Recaptcha = 'icon-recaptcha',
  RectangleA = 'icon-rectangle-a',
  RectangleAInverted = 'icon-rectangle-a-inverted',
  RectangleH = 'icon-rectangle-h',
  RectangleLines = 'icon-rectangle-lines',
  RectangleParagraph = 'icon-rectangle-paragraph',
  RectangleV = 'icon-rectangle-v',
  RestrictionList = 'icon-restriction-list',
  Ribbon = 'icon-ribbon',
  RightDoubleQuotationMark = 'icon-right-double-quotation-mark',
  RotateDoubleRight = 'icon-rotate-double-right',
  RotateLeft = 'icon-rotate-left',
  RotateRight = 'icon-rotate-right',
  Rss = 'icon-rss',
  S = 'icon-s',
  Safari = 'icon-safari',
  SchemeCirclesTriangle = 'icon-scheme-circles-triangle',
  SchemeConnectedCircles = 'icon-scheme-connected-circles',
  SchemePathCircles = 'icon-scheme-path-circles',
  SchemePathCirclesFlipped = 'icon-scheme-path-circles-flipped',
  Scissors = 'icon-scissors',
  Separate = 'icon-separate',
  ShareApple = 'icon-share-apple',
  ShareGoogle = 'icon-share-google',
  ShoeWomen = 'icon-shoe-women',
  ShoppingCart = 'icon-shopping-cart',
  SmallDotCentered = 'icon-small-dot-centered',
  Smartphone = 'icon-smartphone',
  Spinner = 'icon-spinner',
  Square = 'icon-square',
  SquareDashed = 'icon-square-dashed',
  SquareDashedLine = 'icon-square-dashed-line',
  StagingScheme = 'icon-staging-scheme',
  StarEmpty = 'icon-star-empty',
  StarFull = 'icon-star-full',
  StarSemi = 'icon-star-semi',
  Subscript = 'icon-subscript',
  Superscript = 'icon-superscript',
  T = 'icon-t',
  TF = 'icon-t-f',
  TShirt = 'icon-t-shirt',
  TWithCross = 'icon-t-with-cross',
  Tab = 'icon-tab',
  TabVertical = 'icon-tab-vertical',
  Table = 'icon-table',
  Tablet = 'icon-tablet',
  Tag = 'icon-tag',
  Tags = 'icon-tags',
  ThreeDotsVertical = 'icon-three-dots-vertical',
  Times = 'icon-times',
  TimesCircle = 'icon-times-circle',
  Translate = 'icon-translate',
  TreeStructure = 'icon-tree-structure',
  TriangleRight = 'icon-triangle-right',
  Trophy = 'icon-trophy',
  Truck = 'icon-truck',
  TwoRectanglesH = 'icon-two-rectangles-h',
  TwoRectanglesStacked = 'icon-two-rectangles-stacked',
  TwoRectanglesStackedTimes = 'icon-two-rectangles-stacked-times',
  TwoRectanglesV = 'icon-two-rectangles-v',
  TwoSquaresLine = 'icon-two-squares-line',
  TwoSquaresWithLines = 'icon-two-squares-with-lines',
  U = 'icon-u',
  UpDir = 'icon-up-dir',
  User = 'icon-user',
  UserCheckbox = 'icon-user-checkbox',
  UserFrame = 'icon-user-frame',
  Users = 'icon-users',
  Vb = 'icon-vb',
  Watch = 'icon-watch',
  Windows = 'icon-windows',
  WizardStick = 'icon-wizard-stick',
  X = 'icon-x',
  XmlTag = 'icon-xml-tag',
}
