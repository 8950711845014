import { createGuid } from '@kontent-ai/utils';
import { ContentState, EditorState } from 'draft-js';
import { exportToServerPlainText } from './export/plainText/exportToPlainText.ts';

const neverSplitDelimiter = createGuid();

export const createSimpleTextValueContent = (text: string): ContentState =>
  ContentState.createFromText(text, neverSplitDelimiter);

export const createSimpleTextValueEditorState = (text: string): EditorState =>
  EditorState.createWithContent(createSimpleTextValueContent(text));

export const getSimpleTextValue = (editorState: EditorState): string =>
  editorState.getCurrentContent().getFirstBlock().getText();

export const createSimpleTextFromContentState = (contentState: ContentState): ContentState =>
  createSimpleTextValueContent(exportToServerPlainText(contentState));
