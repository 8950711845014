import React, { ReactElement, useMemo } from 'react';
import {
  CardinalLocalTimeZoneId,
  ensureBrowserCompatibleTimeZone,
} from '../../utils/dateTime/timeZoneUtils.ts';
import { getSelectedDateAndTimeZoneCaption } from './dateTimeWithTimeZonePickerUtils.ts';

export const Caption: React.FC<{
  readonly renderStyledText: (text: string) => ReactElement;
  readonly date: string | null;
  readonly timeZone: string | null;
}> = ({ date, timeZone, renderStyledText }) => {
  const ensuredBrowserCompatibleTimeZone = useMemo(
    () => timeZone && ensureBrowserCompatibleTimeZone(timeZone),
    [timeZone],
  );
  const text =
    date && ensuredBrowserCompatibleTimeZone && timeZone !== CardinalLocalTimeZoneId
      ? getSelectedDateAndTimeZoneCaption(date, ensuredBrowserCompatibleTimeZone)
      : null;

  return text ? renderStyledText(text) : null;
};

Caption.displayName = 'Caption';
