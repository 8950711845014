import { getFileSizeObject } from '../../../../../_shared/utils/fileSizeUtils.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { isUuid } from '../../../../../_shared/utils/validation/typeValidators.ts';
import { AssetTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { IAssetReferenceServerModel } from '../../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { defaultAssetFileTypeOption } from '../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../../../contentInventory/content/models/fileSizeOptions.ts';
import { QuantityOption } from '../../../../contentInventory/content/models/quantityOptions.ts';
import { AssetReference } from '../../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import { getQuantityUnitOption } from '../../utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  ITypeElementDataWithAssetDimensions,
  ITypeElementDataWithAssetTypeLimitation,
  ITypeElementDataWithFileSizeLimit,
  ITypeElementDataWithNumberOfItemsLimit,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IAssetTypeElementData
  extends IBaseTypeElementData,
    ITypeElementDataWithAssetDimensions,
    ITypeElementDataWithAssetTypeLimitation,
    ITypeElementDataWithFileSizeLimit,
    ITypeElementDataWithNumberOfItemsLimit,
    IAssetTypeElementDataWithDefaultValue {}

interface IAssetTypeElementDataWithDefaultValue {
  readonly defaultValue: ReadonlyArray<AssetReference>;
}

export const EmptyAssetTypeElementData: IAssetTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  _fileSizeUnitOption: FileSizeUnit.Kilobyte,
  _heightOption: QuantityOption.Exactly,
  _quantityUnitOption: QuantityOption.Minimum,
  _widthOption: QuantityOption.Exactly,
  defaultValue: [],
  fileSize: null,
  fileType: defaultAssetFileTypeOption,
  maxHeight: null,
  maxItems: null,
  maxWidth: null,
  minHeight: null,
  minItems: null,
  minWidth: null,
  type: ElementType.Asset,
};

export const newAssetTypeElementData = (
  diff?: Partial<IAssetTypeElementData>,
): IAssetTypeElementData => ({
  ...EmptyAssetTypeElementData,
  ...diff,
});

export const getAssetReferenceDomainModel = (
  serverModel: ReadonlyArray<IAssetReferenceServerModel> | undefined,
): ReadonlyArray<AssetReference> =>
  serverModel
    ?.filter((model) => !!model && !isEmptyOrWhitespace(model._id) && isUuid(model._id))
    .map((model) => ({ id: model._id, renditions: [] })) ?? [];

export function getAssetTypeElementDataFromServerModel(
  serverModel: AssetTypeElementServerModel,
): IAssetTypeElementData {
  const quantityUnitOption = getQuantityUnitOption(serverModel.minItems, serverModel.maxItems);
  const fileSizeObject = serverModel.fileSize ? getFileSizeObject(serverModel.fileSize) : null;
  const widthOption = getQuantityUnitOption(serverModel.minWidth, serverModel.maxWidth);
  const heightOption = getQuantityUnitOption(serverModel.minHeight, serverModel.maxHeight);
  const defaultValue = getAssetReferenceDomainModel(serverModel.default?.global.value);

  return newAssetTypeElementData({
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyAssetTypeElementData._configurability,
    _fileSizeUnitOption: fileSizeObject
      ? fileSizeObject.unit
      : EmptyAssetTypeElementData._fileSizeUnitOption,
    _heightOption: heightOption,
    _quantityUnitOption: quantityUnitOption,
    _widthOption: widthOption,
    defaultValue,
    fileSize: fileSizeObject?.fileSize ?? null,
    fileType: serverModel.assetFileType || EmptyAssetTypeElementData.fileType,
    maxHeight: serverModel.maxHeight
      ? serverModel.maxHeight.toString()
      : EmptyAssetTypeElementData.maxHeight,
    maxItems: serverModel.maxItems
      ? serverModel.maxItems.toString()
      : EmptyAssetTypeElementData.maxItems,
    maxWidth: serverModel.maxWidth
      ? serverModel.maxWidth.toString()
      : EmptyAssetTypeElementData.maxWidth,
    minHeight: serverModel.minHeight
      ? serverModel.minHeight.toString()
      : EmptyAssetTypeElementData.minHeight,
    minItems: serverModel.minItems
      ? serverModel.minItems.toString()
      : EmptyAssetTypeElementData.minItems,
    minWidth: serverModel.minWidth
      ? serverModel.minWidth.toString()
      : EmptyAssetTypeElementData.minWidth,
    type: ElementType.Asset,
  });
}
