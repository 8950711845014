import { notNull } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { createLanguageServerModel } from '../../../../../data/models/languages/Language.ts';
import {
  ILanguageServerModel,
  ILanguagesServerModel,
} from '../../../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';
import {
  ServerApiErrorCode,
  processApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import {
  LocalizationEditor_Saving_Failed,
  LocalizationEditor_Saving_Started,
  LocalizationEditor_Saving_Success,
} from '../../constants/localizationActionTypes.ts';

const COUNT_OF_RETRY_ATTEMPTS = 3;

interface ISaveProjectLanguagesToServerDependencies {
  readonly loadLanguagesForEditor: () => ThunkPromise;
  readonly loadLanguageUsages: () => ThunkPromise;
  readonly languageRepository: {
    readonly updateLanguages: (languages: ILanguagesServerModel) => Promise<ILanguagesServerModel>;
  };
}

interface ICreateRetrySavingDependencies {
  readonly tryUpdateProjectLanguages: () => Promise<void>;
  readonly handleUnknownError: () => Promise<void>;
  readonly handleCannotDeleteUsedLanguageApiError: () => Promise<void>;
  readonly handleCannotAddNewLanguageApiError: () => Promise<void>;
}

const success = () =>
  ({
    type: LocalizationEditor_Saving_Success,
  }) as const;

const failed = () =>
  ({
    type: LocalizationEditor_Saving_Failed,
  }) as const;

const started = () =>
  ({
    type: LocalizationEditor_Saving_Started,
  }) as const;

export type SaveLanguagesToServerActionsType = ReturnType<
  typeof success | typeof failed | typeof started
>;

type RetryFunction = (remainingAttempts: number) => Promise<void>;

const createRetrySaving = (deps: ICreateRetrySavingDependencies): RetryFunction => {
  const performRetry = (remainingAttempts: number, retrySavingImplementation: RetryFunction) =>
    remainingAttempts > 1
      ? retrySavingImplementation(remainingAttempts - 1)
      : deps.handleUnknownError();

  const retrySaving = async (remainingAttempts: number) =>
    await deps
      .tryUpdateProjectLanguages()
      .catch(
        processApiError(
          ServerApiErrorCode.CannotDeleteUsedLanguage,
          deps.handleCannotDeleteUsedLanguageApiError,
        ),
      )
      .catch(
        processApiError(
          ServerApiErrorCode.CannotAddNewLanguage,
          deps.handleCannotAddNewLanguageApiError,
        ),
      )
      .catch(() => performRetry(remainingAttempts, retrySaving));

  return retrySaving;
};

export const createSaveLanguagesToServerAction =
  (deps: ISaveProjectLanguagesToServerDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(started());
    const {
      localizationApp: { languageItemsOrder, languages, defaultLanguage },
    } = getState();

    const languagesServerModel: Array<ILanguageServerModel> = languageItemsOrder
      .toArray()
      .map((languageId: Uuid) => languages.get(languageId) ?? null)
      .filter(notNull)
      .map(createLanguageServerModel);

    const projectLanguagesServerModel: ILanguagesServerModel = {
      defaultLanguage: createLanguageServerModel(defaultLanguage),
      languages: languagesServerModel,
    };

    const handleTerminatingApiError = async (): Promise<void> => {
      await Promise.all([
        dispatch(failed()),
        dispatch(deps.loadLanguagesForEditor()),
        dispatch(deps.loadLanguageUsages()),
      ]).then(() => Promise.resolve());
    };

    const tryUpdateProjectLanguages = async (): Promise<void> => {
      await deps.languageRepository.updateLanguages(projectLanguagesServerModel);
      dispatch(success());
    };

    const handleUnknownError = async (): Promise<void> => {
      dispatch(failed());
      await dispatch(deps.loadLanguagesForEditor());
    };

    const retrySaving = createRetrySaving({
      tryUpdateProjectLanguages,
      handleUnknownError,
      handleCannotDeleteUsedLanguageApiError: handleTerminatingApiError,
      handleCannotAddNewLanguageApiError: handleTerminatingApiError,
    });

    return retrySaving(COUNT_OF_RETRY_ATTEMPTS);
  };
