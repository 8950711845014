import { Box } from '@kontent-ai/component-library/Box';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Input } from '@kontent-ai/component-library/Input';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  CollectionsRoute,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ICollection } from '../../../../data/models/collections/Collection.ts';
import {
  ExpandedSpaceBarActiveWebSpotlightText,
  ExpandedSpaceBarInactiveWebSpotlightText,
  ExpandedSpaceBaseActiveWebSpotlightTooltipText,
} from '../constants/spacesUiConstants.ts';
import { RootItemBar } from '../containers/RootItemBar.tsx';
import { CollectionMultiselect } from './CollectionMultiselect.tsx';
import { RootItemEmptyState } from './RootItemEmptyState.tsx';

type Props = {
  readonly allCollections: ReadonlyArray<ICollection>;
  readonly anySharedCollection: boolean;
  readonly canUserCreateContent: boolean;
  readonly hasExistingRootItem: boolean;
  readonly hasSpaceLinkedRootItem: boolean;
  readonly isRootItemLoading: boolean;
  readonly isWebSpotlightActive: boolean;
  readonly name: string;
  readonly onNameChange: (name: string) => void;
  readonly onOpenCreateNewItemDialog: () => void;
  readonly onOpenLinkExistingItemDialog: () => void;
  readonly onRootItemUnlink: () => void;
  readonly onSelectedCollectionIdsChange: (collectionIds: ReadonlyArray<Uuid>) => void;
  readonly projectId: Uuid;
  readonly rootItemId: Uuid | null;
  readonly selectedCollectionIds: ReadonlyArray<Uuid>;
  readonly shouldShowRootItem: boolean;
  readonly showCollections: boolean;
};

export const ExpandedSpaceBarContent: React.FC<Props> = ({
  allCollections,
  anySharedCollection,
  canUserCreateContent,
  hasExistingRootItem,
  hasSpaceLinkedRootItem,
  isRootItemLoading,
  isWebSpotlightActive,
  name,
  onNameChange,
  onOpenCreateNewItemDialog,
  onOpenLinkExistingItemDialog,
  onRootItemUnlink,
  onSelectedCollectionIdsChange,
  projectId,
  rootItemId,
  selectedCollectionIds,
  shouldShowRootItem,
  showCollections,
}) => {
  return (
    <>
      <Box typography={Typography.HeadlineMedium} marginBottom={Spacing.S}>
        General
      </Box>
      <Input
        autoFocus
        label="Space name"
        maxLength={ValidationConstants.SpaceNameMaxLength}
        onChange={(e) => onNameChange(e.target.value)}
        value={name}
        {...getDataUiElementAttribute(DataUiElement.SpaceNameInput)}
        {...getDataUiInputAttribute(DataUiInput.Text)}
      />

      {showCollections && (
        <>
          <Box
            typography={Typography.HeadlineMedium}
            marginTop={Spacing.XXL}
            marginBottom={Spacing.S}
          >
            Consists of content from
          </Box>
          <Box typography={Typography.LabelLarge} marginTop={Spacing.L} marginBottom={Spacing.S}>
            Collections
          </Box>
          <CollectionMultiselect
            collections={allCollections}
            selectedCollectionIds={selectedCollectionIds}
            onSelectedIdsChanged={onSelectedCollectionIdsChange}
          />
          {anySharedCollection && (
            <Box typography={Typography.Subheadline} marginTop={Spacing.S}>
              Some of the selected{' '}
              <Link to={buildPath<EnvironmentRouteParams>(CollectionsRoute, { projectId })}>
                collections
              </Link>{' '}
              are shared across multiple spaces.
            </Box>
          )}
        </>
      )}

      {(isWebSpotlightActive || hasExistingRootItem) && (
        <>
          <Box
            typography={Typography.HeadlineMedium}
            marginTop={Spacing.XXL}
            marginBottom={Spacing.S}
          >
            Web Spotlight
          </Box>
          <Box typography={Typography.Subheadline}>
            {isWebSpotlightActive
              ? !hasSpaceLinkedRootItem && (
                  <>
                    {ExpandedSpaceBarActiveWebSpotlightText}
                    <Box marginLeft={Spacing.XS} component="span">
                      <Hint
                        tooltipText={ExpandedSpaceBaseActiveWebSpotlightTooltipText}
                        tooltipPlacement="right"
                      />
                    </Box>
                  </>
                )
              : ExpandedSpaceBarInactiveWebSpotlightText}
          </Box>
          <Box typography={Typography.HeadlineSmall} marginTop={Spacing.L} marginBottom={Spacing.S}>
            Root item
          </Box>
          {shouldShowRootItem ? (
            <RootItemBar
              isLoading={isRootItemLoading}
              itemId={rootItemId}
              onUnlink={onRootItemUnlink}
            />
          ) : (
            <Box marginBottom={Spacing.XL}>
              <RootItemEmptyState
                onOpenLinkExistingItemDialog={onOpenLinkExistingItemDialog}
                canUserCreateContent={canUserCreateContent}
                onOpenCreateNewItemDialog={onOpenCreateNewItemDialog}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

ExpandedSpaceBarContent.displayName = 'ExpandedSpaceBarContent';
