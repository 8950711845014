import React from 'react';
import { ScrollContainerContextProvider } from '../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { DatetimePicker } from '../../../../components/DatetimePicker/DatetimePicker.tsx';
import { DateTime } from '../../../../models/DateTime.ts';
import { ModalSection } from '../ModalSection.tsx';

interface IDueDateSectionProps {
  readonly dueDate: DateTimeStamp | null;
  readonly onDueDateChanged: (dateTime: DateTime) => void;
  readonly autoFocus?: boolean;
}

export const DueDateSection: React.FC<IDueDateSectionProps> = (props) => (
  <ScrollContainerContextProvider tippyBoundaryRef={null}>
    <ModalSection key="dueSection">
      <DatetimePicker
        title="Due date"
        defaultTime="16:00"
        onChange={props.onDueDateChanged}
        value={props.dueDate}
      />
    </ModalSection>
  </ScrollContainerContextProvider>
);

DueDateSection.displayName = 'DueDateSection';
