import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  getCurrentProjectContainer,
  getProjectPlan,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  showCreateEnvironmentDialog,
  showDeleteEnvironmentDialog,
  showSwapEnvironmentDialog,
} from '../actions/environmentsActions.ts';
import { initEnvironmentListing } from '../actions/thunkEnvironmentsActions.ts';
import { EnvironmentListing as EnvironmentListingComponent } from '../components/EnvironmentListing.tsx';
import { getEnvironmentsForCloning } from '../selectors/getEnvironmentsForCloning.ts';
import { getSplitEnvironmentsForListing } from '../selectors/getEnvironmentsForListing.ts';
import {
  getDefaultEnvironmentToCloneFromId,
  getEnvironmentsCount,
  isEnvironmentsLimitReached,
} from '../utils/environmentsUtils.ts';

type Props = {
  readonly withSubscriptionIdInRoute?: Uuid;
};

export const EnvironmentListing = (props: Props) => {
  const [isInitThunkDone] = useThunkPromise(initEnvironmentListing);
  const allSet = useSelector(
    (s) => isInitThunkDone && s.environmentsApp.loadingStatus === LoadingStatus.Loaded,
  );

  const currentProject = useSelector((s) => getCurrentProjectContainer(s));
  const environments = useSelector((s) =>
    getSplitEnvironmentsForListing(s, currentProject.masterEnvironmentId),
  );
  const environmentsCount = useSelector((s) => getEnvironmentsCount(s, currentProject.projectId));
  const environmentsForCloning = useSelector((s) =>
    getEnvironmentsForCloning(s, currentProject.masterEnvironmentId),
  );
  const defaultCloneFromId = getDefaultEnvironmentToCloneFromId(
    environmentsForCloning,
    currentProject.masterEnvironmentId,
  );
  const hasAnyEnvironmentToCloneFrom = environmentsForCloning.count() > 0;
  const maxProjectEnvironments = useSelector(
    (s) => getProjectPlan(s, currentProject.projectId).features.maxProjectEnvironments,
  );

  const dispatch = useDispatch();
  const onCreateEnvironment = (cloneFromId: Uuid, isNewEnvironment: boolean) =>
    dispatch(showCreateEnvironmentDialog(cloneFromId, isNewEnvironment));
  const onDeleteEnvironment = (environmentId: Uuid) =>
    dispatch(showDeleteEnvironmentDialog(environmentId));
  const onSwapEnvironment = (environmentId: Uuid) =>
    dispatch(showSwapEnvironmentDialog(environmentId));

  return allSet ? (
    <EnvironmentListingComponent
      defaultCloneFromId={defaultCloneFromId}
      environments={environments}
      hasAnyEnvironmentToCloneFrom={hasAnyEnvironmentToCloneFrom}
      isEnvironmentsFeatureInPlan={maxProjectEnvironments !== 1}
      masterEnvironmentId={currentProject.masterEnvironmentId}
      onCreateEnvironment={onCreateEnvironment}
      onDeleteEnvironment={onDeleteEnvironment}
      onSwapEnvironment={onSwapEnvironment}
      planLimitReached={isEnvironmentsLimitReached(maxProjectEnvironments, environmentsCount)}
      subscriptionId={props.withSubscriptionIdInRoute}
    />
  ) : (
    <Loader />
  );
};
