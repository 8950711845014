import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownOptionSubmenuMark } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionSubmenuMark.tsx';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { ObjectWithDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly dataAttributes?: ObjectWithDataAttribute;
  readonly disabled?: boolean;
  readonly disabledTooltip?: string;
  readonly iconName?: IconName;
  readonly onClick: () => void;
  readonly text: string;
  readonly withSubmenu?: boolean;
};

export const FilterActionsMenuItem = ({
  dataAttributes,
  disabled,
  disabledTooltip,
  iconName,
  onClick,
  text,
  withSubmenu,
}: Props) => (
  <DropDownOption
    isDisabled={disabled}
    onClick={onClick}
    tooltipText={disabled ? disabledTooltip : undefined}
    dataUiAttributes={dataAttributes}
  >
    <div className="filter-actions-menu-item">
      {iconName && <Icon iconName={iconName} className="filter-actions-menu-item__icon" />}
      <DropDownOptionName text={text} />
    </div>
    {withSubmenu && <DropDownOptionSubmenuMark />}
  </DropDownOption>
);
