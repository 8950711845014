import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

export interface IModalSectionProps {
  readonly className?: string;
  readonly dataUiElementName?: DataUiElement;
  readonly title?: string;
}

export const ModalSection: React.FC<React.PropsWithChildren<IModalSectionProps>> = (props) => (
  <div
    className={classNames('modal__section', props.className)}
    {...(props.dataUiElementName && getDataUiElementAttribute(props.dataUiElementName))}
  >
    {props.title && <h2 className="modal__section-title">{props.title}</h2>}
    {props.children}
  </div>
);

ModalSection.displayName = 'ModalSection';
