import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Data_ListingContentItems_FullTextSearchBecameUnavailable } from '../../../../data/constants/dataActionTypes.ts';
import { FullTextSearchStatus_Loaded } from '../constants/contentInventoryActionTypes.ts';
import { FullTextSearchStatus } from './IContentInventoryStoreState.type.ts';

const initialState: FullTextSearchStatus = FullTextSearchStatus.Initial;

export const fullTextSearchStatus = (
  state: FullTextSearchStatus = initialState,
  action: Action,
): FullTextSearchStatus => {
  switch (action.type) {
    case FullTextSearchStatus_Loaded: {
      if (state === FullTextSearchStatus.Unavailable) {
        return state;
      }

      return action.payload.isFullTextSearchEnabled
        ? FullTextSearchStatus.Enabled
        : FullTextSearchStatus.Disabled;
    }

    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_ListingContentItems_FullTextSearchBecameUnavailable:
      return FullTextSearchStatus.Unavailable;

    default:
      return state;
  }
};
