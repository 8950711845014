import { Action } from '../../../../../@types/Action.type.ts';
import { ContentGroupSelectionReason } from '../../../features/ContentItemEditing/constants/ContentGroupSelectionReason.ts';
import {
  ContentItemEditing_HideElementsAreInaccessibleNotification,
  ContentItemEditing_Init_Started,
  ContentItemEditing_ShowElementsAreInaccessibleNotification,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ContentGroupSelectionReason | null = null;

export function elementsAreInaccessibleNotificationBarTrigger(
  state = initialState,
  action: Action,
): ContentGroupSelectionReason | null {
  switch (action.type) {
    case ContentItemEditing_ShowElementsAreInaccessibleNotification:
      return action.payload.trigger;

    case ContentItemEditing_HideElementsAreInaccessibleNotification:
    case ContentItemEditing_Init_Started:
      return initialState;

    default:
      return state;
  }
}
