import { delay } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import {
  TrackUserEventData,
  TrackUserEventWithDataAction,
} from '../../models/TrackUserEvent.type.ts';
import { eventTrackingService } from '../../services/eventTrackingService.ts';
import { getContextuallyTrackedData } from '../../utils/trackingUtils.ts';

const createTrackUserEvent =
  (eventName: TrackedEvent, eventPayload: any): ThunkPromise =>
  async (_dispatch, getState) => {
    const state = getState();

    const contextuallyTrackedData = getContextuallyTrackedData(state);

    // event payload can overwrite any contextual data, provided tracked event decided to do so
    const completePayload = {
      ...contextuallyTrackedData,
      ...eventPayload,
    };

    // Defer executing the actual tracking as analytics could trigger style calculation when reading some contextual data
    await delay(0);
    eventTrackingService.trackUserEvent(eventName, completePayload);
  };

export const trackUserEvent: TrackUserEventData = (eventName) =>
  createTrackUserEvent(eventName, undefined);

export const trackUserEventWithData: TrackUserEventWithDataAction = (eventName, eventPayload) =>
  createTrackUserEvent(eventName, eventPayload);
