import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  Typography,
  colorTextDefault,
  colorTextHint,
} from '@kontent-ai/component-library/tokens';
import { useIdWithPrefix } from '@kontent-ai/hooks';
import { useFocusWithin } from '@react-aria/interactions';
import classNames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AssignedSpacesMessage } from '../../../../../../_shared/components/Spaces/AssignedSpacesMessage.tsx';
import {
  ElementStatus,
  ElementStatusOption,
} from '../../../../../../_shared/uiComponents/ElementStatus/ElementStatus.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SpaceNamesMetadata } from '../../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { ItemElementHeaderSection } from './subComponents/ItemElementHeaderSection.tsx';
import { ItemElementHeaderSubsection } from './subComponents/ItemElementHeaderSubsection.tsx';
import { ItemElementLabel } from './subComponents/ItemElementLabel.tsx';

type Props = {
  readonly collectionName: string;
  readonly onChange: () => void;
  readonly isSpaceDefined: boolean;
  readonly spaceData: SpaceNamesMetadata;
};

const CollectionName = styled.span`
  ${Typography.BodyLargeEmphasis};
`;

export const ContentItemCollection: React.FC<Props> = ({
  collectionName,
  onChange,
  isSpaceDefined,
  spaceData,
}) => {
  const [isCaretFocused, setIsCaretFocused] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setIsCaretFocused(isFocusWithin),
  });

  const collectionSelectorId = useIdWithPrefix('content-item-collection-id');

  return (
    <Box
      className="content-item-element__wrapper"
      {...focusWithinProps}
      {...getDataUiElementAttribute(DataUiElement.ContentElementCollection)}
    >
      <Box
        className={classNames('content-item-element', {
          'content-item-element--is-caret-focused': isCaretFocused,
        })}
      >
        <Box className="content-item-element__header">
          <ItemElementHeaderSection>
            <ItemElementHeaderSubsection isFirst>
              <Row alignX="start" alignY="center" spacingX={Spacing.S} noWrap>
                <Column>
                  <ItemElementLabel id={collectionSelectorId}>Collection</ItemElementLabel>
                </Column>
                <Column>
                  <ElementStatus option={ElementStatusOption.FilledCorrectly} />
                </Column>
              </Row>
            </ItemElementHeaderSubsection>
          </ItemElementHeaderSection>
        </Box>
        <Box className="content-item-element__content">
          {isSpaceDefined && (
            <Box color={colorTextHint} marginBottom={Spacing.S} typography={Typography.BodySmall}>
              <AssignedSpacesMessage
                spaceData={spaceData}
                renderSpaceName={({ name }) => (
                  <Box
                    component="span"
                    color={colorTextDefault}
                    typography={Typography.BodySmallEmphasis}
                  >
                    {name}
                  </Box>
                )}
              />
            </Box>
          )}
          <Row alignY="center" spacingX={Spacing.L}>
            <Column width="fit-content">
              <Box typography={Typography.BodyLarge}>
                This item is in “
                <CollectionName {...getDataUiElementAttribute(DataUiElement.SelectedCollection)}>
                  {collectionName}
                </CollectionName>
                ” collection.
              </Box>
            </Column>
            <Column>
              <Button buttonStyle="tertiary" onClick={onChange} size="medium">
                Change collection
              </Button>
            </Column>
          </Row>
          {spaceData.usedSpaces.length > 1 && (
            <Box
              color={colorTextHint}
              marginTop={Spacing.S}
              typography={Typography.BodySmall}
              {...getDataUiElementAttribute(
                DataUiElement.ContentElementCollectionSharedSpaceMessage,
              )}
            >
              Shared collection means that this content item can be displayed in multiple spaces.
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ContentItemCollection.displayName = 'ContentItemCollection';
