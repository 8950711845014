import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { IStartTrialQuickTipProps, StartTrialQuickTip } from '../components/StartTrialQuickTip.tsx';

function mapStateToProps(state: IStore): IStartTrialQuickTipProps {
  const {
    data: { user, projects },
    sharedApp: { userProperties },
  } = state;

  const hadTrial = user.info.hadTrial;
  const hasAnyProject = !projects.byId.isEmpty();
  const noExpiredOrSuspendedProjects = projects.byId.every(
    (p: IProjectDetails) => !p.planIsExpired && !p.planIsSuspended,
  );

  return {
    isVisible:
      !hadTrial &&
      hasAnyProject &&
      noExpiredOrSuspendedProjects &&
      !userProperties.isAzureMarketplaceUser,
  };
}

const StartTrialQuickTipContainer: React.ComponentType =
  connect(mapStateToProps)(StartTrialQuickTip);
export { StartTrialQuickTipContainer as StartTrialQuickTip };
