import { DefaultLanguageId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { isAiReviewEnabled } from '../../../../../../_shared/selectors/aiSelectors.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { TypeElement } from '../../../../../../applications/contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isRichTextTypeElement,
  isTextTypeElement,
} from '../../../../../../applications/contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';

export const shouldShowAiReviewButton = (
  store: IStore,
  typeElement: TypeElement,
  contentComponentId: string | undefined,
): boolean => {
  const isReviewEnabled = isAiReviewEnabled(store);
  const isElementReviewable = isRichTextTypeElement(typeElement) || isTextTypeElement(typeElement);
  const isDefaultLanguage = getSelectedLanguageId(store) === DefaultLanguageId;
  const shouldShowReviewButtonInTopLevelElement =
    // We do not display the review button for nonLocalizable elements in non default language,
    // because their status states that you should modify their value in default language.
    !typeElement.isNonLocalizable || isDefaultLanguage;

  return (
    isReviewEnabled &&
    isElementReviewable &&
    // We always display review button in content components.
    (!!contentComponentId || shouldShowReviewButtonInTopLevelElement)
  );
};
