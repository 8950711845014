import React from 'react';
import { getConnectedStatusMessage } from '../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';

const getRoleSavingStatusMessage = (state: IStore): StatusMessage => {
  const {
    rolesApp: {
      editorUi: { status },
    },
  } = state;

  if (status === RoleEditorStatus.Modified || status === RoleEditorStatus.ValidationError) {
    return UnsavedStatusMessage;
  }

  return status === RoleEditorStatus.IsBeingSaved ? SavingStatusMessage : SavedStatusMessage;
};

export const RoleSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getRoleSavingStatusMessage,
);
