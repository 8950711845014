import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { FC, forwardRef, useRef } from 'react';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IFriendlyWarning } from '../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ContentItemUsages } from '../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { Loader, LoaderType } from '../../../components/Loader.tsx';
import { ModalDialogTwoColumnBodyLayout } from '../../../components/ModalDialog/ModalDialogTwoColumnBodyLayout.tsx';
import { DateTime } from '../../../models/DateTime.ts';
import {
  DataUiAction,
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowStatusSelectorSection } from '../containers/WorkflowStatusSelectorSection.tsx';
import { IChangeWorkflowStepModalData } from '../reducers/IChangeWorkflowStepModalData.ts';
import { ChangeAssignmentSection } from './ChangeAssignmentSection.tsx';

export type WorkflowSubmitButtonProps = {
  readonly dataUiAttribute: ObjectWithDataAttribute;
  readonly destructive?: boolean;
  readonly disabled: boolean;
  readonly iconName?: IconName;
  readonly onSubmit: (() => void) | undefined;
  readonly text: string;
  readonly tooltipText?: string;
  readonly isLoading?: boolean;
};

type ChangeWorkflowStepModalProps = {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly changeWorkflowStepModalData: IChangeWorkflowStepModalData;
  readonly contentItemUsages: ContentItemUsages;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
  readonly hasPendingUpdates?: boolean;
  readonly isLoading?: boolean;
  readonly itemIds: ReadonlySet<Uuid>;
  readonly modalTitle: string;
  readonly onCloseModal: () => void;
  readonly onDueDateChange: (dateTime: DateTime) => void;
  readonly onHideNotificationBar: () => void;
  readonly onNoteChanged: (newNote: string) => void;
  readonly onSelectorValueChanged: (optionId: Uuid) => void;
  readonly updateSubmitProps: WorkflowSubmitButtonProps;
  readonly isOpen: boolean;
};

const LeftColumnElement: React.FC<ChangeWorkflowStepModalProps> = (props) =>
  props.isLoading ? (
    <Loader type={LoaderType.Sidebar} />
  ) : (
    <ChangeAssignmentSection
      activeUsersNames={props.activeUsersNames}
      changeWorkflowStepModalData={props.changeWorkflowStepModalData}
      friendlyWarningReasons={props.friendlyWarningReasons}
      onDueDateChange={props.onDueDateChange}
      onNoteChanged={props.onNoteChanged}
      itemIds={props.itemIds}
      contentItemUsages={props.contentItemUsages}
    />
  );

const ModalBody: FC<ChangeWorkflowStepModalProps> = (props) => (
  <ModalDialogTwoColumnBodyLayout
    leftColumnElement={<LeftColumnElement {...props} />}
    rightColumnElement={null}
    topElement={
      <WorkflowStatusSelectorSection onSelectorValueChanged={props.onSelectorValueChanged} />
    }
  />
);

export const ChangeWorkflowStepModal = forwardRef<HTMLDivElement, ChangeWorkflowStepModalProps>(
  (props, ref) => {
    const {
      isOpen,
      updateSubmitProps: {
        onSubmit,
        destructive,
        disabled,
        iconName,
        text,
        tooltipText,
        dataUiAttribute,
      },
      isLoading,
    } = props;
    const loaderRef = useRef(null);

    return (
      <ModalDialog
        autoFocusRef={isLoading ? loaderRef : undefined}
        cancelAction={{
          onClick: props.onCloseModal,
          ...getDataUiActionAttribute(DataUiAction.CloseDialog),
        }}
        headline={props.modalTitle}
        isDismissable
        isOpen={isOpen}
        onClose={props.onCloseModal}
        primaryAction={{
          onClick: onSubmit,
          destructive,
          disabled,
          iconBefore: iconName ? Icons[iconName] : undefined,
          text,
          tooltipText,
          ...dataUiAttribute,
        }}
        ref={ref}
        renderNotificationBar={() =>
          props.changeWorkflowStepModalData.savingError && (
            <NotificationBarAlert onDismiss={props.onHideNotificationBar}>
              {props.changeWorkflowStepModalData.savingError}
            </NotificationBarAlert>
          )
        }
        shouldCloseOnInteractOutside={() => false}
        state={isLoading || props.hasPendingUpdates ? DialogState.InProgress : undefined}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
      >
        {isLoading ? (
          <Loader type={LoaderType.Sidebar} ref={loaderRef} />
        ) : (
          <ModalBody {...props} />
        )}
      </ModalDialog>
    );
  },
);

ChangeWorkflowStepModal.displayName = 'ChangeWorkflowStepModal';
