import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  LocalizationEditor_Init_Finished,
  LocalizationEditor_Init_Started,
} from '../../constants/localizationActionTypes.ts';

export function loadingStatus(
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated: {
      return LoadingStatus.InitialEmpty;
    }

    case LocalizationEditor_Init_Started: {
      return LoadingStatus.Loading;
    }

    case LocalizationEditor_Init_Finished: {
      return LoadingStatus.Loaded;
    }

    default:
      return state;
  }
}
