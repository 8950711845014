import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { BarItemAction } from '../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IWebhookBarItemResetAction {
  readonly onClick: () => void;
}

const WebhookBarItemResetAction: React.FC<IWebhookBarItemResetAction> = ({ onClick }) => (
  <Tooltip placement="top" tooltipText="Reset">
    <BarItemAction
      dataUiActionName={DataUiAction.Reset}
      iconName={IconName.RotateLeft}
      onClick={onClick}
      screenReaderText="Reset"
    />
  </Tooltip>
);

WebhookBarItemResetAction.displayName = 'WebhookBarItemResetAction';

export { WebhookBarItemResetAction };
