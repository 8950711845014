import { RichTextTypeElementValidationResult } from '../types/RichTextTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const RichTextInitialValidationResult: RichTextTypeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  isGuidelinesValid: true,
  isNameValid: true,
  isMaxCharsValid: true,
  isMaxWordsValid: true,
  isWidthValid: true,
  isHeightValid: true,
  isImageSizeValid: true,
  areAllowedBlocksValid: true,
  areAllowedTextBlocksValid: true,
  areAllowedTableTextBlocksValid: true,
  errorMessages: [],
};
