import { Collection } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { RequiredAssetCreationMetadata } from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import { AssetLibrary_Assets_CreateAssetsFromEditing } from '../../constants/assetLibraryActionTypes.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { IAssetUploadResult, ICreateAssets, IOnAssetFinished } from './createAssets.ts';

interface IDeps {
  readonly createAssets: ICreateAssets;
}

const uploadingAssetsFromEditing = (files: ReadonlyArray<Uuid>) =>
  ({
    type: AssetLibrary_Assets_CreateAssetsFromEditing,
    payload: {
      files,
    },
  }) as const;

export type UploadAssetsFromEditingActionsType = ReturnType<typeof uploadingAssetsFromEditing>;

export const createUploadAssetsFromEditingAction =
  (deps: IDeps) =>
  (
    files: Map<Uuid, FileWithThumbnail>,
    metadata: RequiredAssetCreationMetadata,
    onAssetFinished?: IOnAssetFinished,
  ): ThunkPromise<ReadonlyArray<IAssetUploadResult>> =>
  (dispatch: Dispatch): Promise<ReadonlyArray<IAssetUploadResult>> => {
    dispatch(uploadingAssetsFromEditing(Collection.getKeys(files)));
    return dispatch(deps.createAssets(files, metadata, onAssetFinished));
  };
