import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  AssetLibrary_EditedAsset_DeletionFinished,
  AssetLibrary_EditedAsset_DeletionStarted,
} from '../../constants/assetLibraryActionTypes.ts';

interface IDeps {
  readonly archiveAsset: (asset: IAsset) => ThunkPromise;
}

const editedAssetDeletionStarted = () =>
  ({
    type: AssetLibrary_EditedAsset_DeletionStarted,
  }) as const;

const editedAssetDeletionFinished = () =>
  ({
    type: AssetLibrary_EditedAsset_DeletionFinished,
  }) as const;

export type DeleteEditedAssetActionsType = ReturnType<
  typeof editedAssetDeletionStarted | typeof editedAssetDeletionFinished
>;

export const createDeleteEditedAssetAction =
  ({ archiveAsset }: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { editedAsset } = getState().assetLibraryApp.assetUiEditor;

    if (!editedAsset) {
      throw InvariantException('editedAsset is falsy.');
    }

    dispatch(editedAssetDeletionStarted());
    await dispatch(archiveAsset(editedAsset));
    dispatch(editedAssetDeletionFinished());
  };
