import { MatchAiTasks } from '../../../../../_shared/features/AI/hooks/aiTasks/matchAiTask.type.ts';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  ElementReference,
  areReferencesPointingToSameElement,
  isElementReference,
} from '../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';

export const matchElementAiTasks =
  (element: ElementReference, ...actionNames: ReadonlyArray<AiActionName>): MatchAiTasks =>
  (aiTasks) =>
    aiTasks.filter(
      (aiTask) =>
        actionNames.includes(aiTask.action.name) &&
        isElementReference(aiTask.context.element) &&
        areReferencesPointingToSameElement(aiTask.context.element, element),
    );
