import React, { useState } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { getContentStateActionResult } from '../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useOnFinishedAiActionTask } from '../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiSessionId } from '../../../../../_shared/features/AI/hooks/useAiSessionId.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createAssetOperationTrackingData } from '../../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { createDescribeImageParams } from '../../../../../repositories/serverModels/ai/actions/AiServerModels.describeImage.ts';
import { ITextTypeElement } from '../../../content/models/contentTypeElements/TextTypeElement.ts';
import { matchAnyTranslateDescriptionAiTasksInProgress } from '../../utils/matchAnyTranslateDescriptionAiTasksInProgress.tsx';
import { AssetDescription as AssetDescriptionComponent } from './Description/AssetDescription.tsx';
import { AssetDescriptionAiLoader } from './Description/DescribeWithAi/AssetDescriptionAiLoader.tsx';
import { DescribeWithAiButton } from './Description/DescribeWithAi/DescribeWithAiButton.tsx';
import { DescribeWithAiError } from './Description/DescribeWithAi/DescribeWithAiError.tsx';

export interface IAssetDescriptionWithAiGenerateActionProps {
  readonly assetId: Uuid;
  readonly copyFromOtherLanguageButton: JSX.Element;
  readonly descriptionLabel: string;
  readonly isEditable: boolean;
  readonly language: ILanguage;
  readonly languageDescription: string;
  readonly onChangeDescription: (
    variantId: Uuid,
    newDescription: string,
    isAiGenerated: boolean,
  ) => void;
  readonly placeholder: string;
  readonly typeElement: ITextTypeElement;
}

export const AssetDescriptionWithAiGenerateAction: React.FC<
  IAssetDescriptionWithAiGenerateActionProps
> = ({
  assetId,
  copyFromOtherLanguageButton,
  descriptionLabel,
  isEditable,
  language,
  languageDescription,
  onChangeDescription,
  placeholder,
  typeElement,
}) => {
  const dispatch = useDispatch();
  const [isErrorDismissed, setIsErrorDismissed] = useState(false);
  const [isDescriptionBeingGenerated, setIsDescriptionBeingGenerated] = useState(false);

  const { run, result } = useAiTask(AiActionName.DescribeImage, getContentStateActionResult);
  const translateDescriptionAiTaskInProgress = useAiTaskProgress(
    matchAnyTranslateDescriptionAiTasksInProgress(assetId),
  );
  const { aiSessionId, resetAiSessionId } = useAiSessionId();
  const assetTrackingData = createAssetOperationTrackingData(assetId, aiSessionId);

  useOnFinishedAiActionTask(result.isFinished, () => {
    if (!result.error) {
      const imageDescription = result.content?.getPlainText() ?? '';
      onChangeDescription(language.id, imageDescription, true);
    }
    setIsDescriptionBeingGenerated(false);
  });

  const onGenerateDescription = () => {
    run(createDescribeImageParams(assetId, language.name, language.codename, assetTrackingData), {
      assetId,
    });
    dispatch(trackUserEvent(TrackedEvent.AiImageDescription));
    setIsDescriptionBeingGenerated(true);
    setIsErrorDismissed(false);
    resetAiSessionId();
  };

  const buttons = (
    <>
      <DescribeWithAiButton
        isDisabled={
          isDescriptionBeingGenerated ||
          translateDescriptionAiTaskInProgress === AiTaskProgress.Running
        }
        onGenerateDescription={onGenerateDescription}
      />
      {copyFromOtherLanguageButton}
    </>
  );

  return (
    <AssetDescriptionComponent
      buttons={buttons}
      descriptionLabel={descriptionLabel}
      errorNotificationBar={
        result.error && !isErrorDismissed ? (
          <DescribeWithAiError
            dismissError={() => setIsErrorDismissed(true)}
            errorCode={result.error.code}
          />
        ) : undefined
      }
      isEditable={isEditable}
      language={language}
      languageDescription={languageDescription}
      loader={
        isDescriptionBeingGenerated ? (
          <AssetDescriptionAiLoader message="Describing the image..." />
        ) : undefined
      }
      onChangeDescription={(variantId, newDescription) => {
        onChangeDescription(variantId, newDescription, false);
        if (!isErrorDismissed) {
          setIsErrorDismissed(true);
        }
      }}
      placeholder={placeholder}
      typeElement={typeElement}
    />
  );
};

AssetDescriptionWithAiGenerateAction.displayName = 'AssetDescriptionWithAiGenerateAction';
