import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { RoleEditorStatus } from '../models/RoleEditorStatus.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { areCustomRolesOverLimit } from './customRolesUtils.ts';

const editorDisablingStatuses = Immutable.Set([
  RoleEditorStatus.InitialEmpty,
  RoleEditorStatus.Loading,
]);

export const isRoleEditingDisabled = (state: IStore): boolean => {
  const {
    rolesApp: {
      editorUi: { status, editedRole },
    },
  } = state;

  const customRolesLimitInfo = getCustomRolesLimitInfo(state);
  const areRolesOverLimit = areCustomRolesOverLimit(customRolesLimitInfo);
  const isOverLimitOrIsLoading = areRolesOverLimit || editorDisablingStatuses.contains(status);

  return isOverLimitOrIsLoading || editedRole.isReadonly;
};

export const hasUnsavedChanges = (status: RoleEditorStatus): boolean =>
  status === RoleEditorStatus.Modified ||
  status === RoleEditorStatus.IsBeingSaved ||
  status === RoleEditorStatus.ValidationError;

export const isLoaded = (status: RoleEditorStatus): boolean =>
  status !== RoleEditorStatus.Loading && status !== RoleEditorStatus.InitialEmpty;
