import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadAdministratedSubscriptions,
  loadProjects,
  loadUserProjectsInfo,
} from '../../../../data/actions/thunkDataActions.ts';
import { createFastSpringAccountConnectionCompleted } from './thunk/fastSpringAccountConnectionCompleted.ts';
import { loadSubscriptionBillingInformationCreator } from './thunk/loadSubscriptionBillingInformation.ts';

const { subscriptionRepository } = repositoryCollection;

export const loadSubscriptionBillingInformation = loadSubscriptionBillingInformationCreator({
  subscriptionRepository,
});

export const fastSpringAccountConnectionCompleted = createFastSpringAccountConnectionCompleted({
  loadAdministratedSubscriptions,
  loadProjects,
  loadSubscriptionBillingInformation,
  loadUserProjectsInfo,
});
