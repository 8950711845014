import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import { HtmlSettingsPageTitle } from '../../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../../_shared/components/PageTitle.tsx';
import { ProjectSettingsAppNames } from '../../../root/constants/ProjectSettingsAppNames.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';

type ApiKeyActionStatusProps = {
  readonly apiKeyActionStatus: ApiKeyActionStatus;
  readonly onAlertDismiss: () => void;
};

const actionErrorMessageMap: ReadonlyRecord<
  Exclude<ApiKeyActionStatus, ApiKeyActionStatus.None | ApiKeyActionStatus.LoadingFailed>,
  string
> = {
  [ApiKeyActionStatus.RegenerationFailed]:
    'Couldn’t generate a new API key. Please try it again or contact us for help.',
  [ApiKeyActionStatus.RevokingFailed]:
    'Couldn’t revoke the API key. Please try it again or contact us for help.',
  [ApiKeyActionStatus.SavingFailed]:
    'Couldn’t save the API key. Please try it again or contact us for help.',
  [ApiKeyActionStatus.SavingInterrupted]:
    'Couldn’t save the API key. Please fill all the required fields.',
};

const ActionStatus: React.FC<ApiKeyActionStatusProps> = ({
  apiKeyActionStatus,
  onAlertDismiss,
}) => {
  if (
    apiKeyActionStatus === ApiKeyActionStatus.None ||
    apiKeyActionStatus === ApiKeyActionStatus.LoadingFailed
  ) {
    return null;
  }

  return (
    <NotificationBarAlert onDismiss={onAlertDismiss}>
      {actionErrorMessageMap[apiKeyActionStatus]}
    </NotificationBarAlert>
  );
};

type ApiKeyDetailProps = ApiKeyActionStatusProps & {
  readonly apiKeyName: string;
  readonly children: ReactNode;
};

export const ApiKeyDetailLayout: React.FC<ApiKeyDetailProps> = ({
  apiKeyActionStatus,
  apiKeyName,
  children,
  onAlertDismiss,
}) => (
  <Stack spacing={Spacing.XL}>
    <HtmlSettingsPageTitle
      settingsAppName={ProjectSettingsAppNames.ApiKeyListing}
      customName={apiKeyName}
    />
    <PageTitle>{apiKeyName}</PageTitle>
    <Stack spacing={Spacing.XXL}>
      <ActionStatus apiKeyActionStatus={apiKeyActionStatus} onAlertDismiss={onAlertDismiss} />
      {children}
    </Stack>
  </Stack>
);

ApiKeyDetailLayout.displayName = 'ApiKeyDetailLayout';
