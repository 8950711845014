import { Input, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { HotkeysHandler } from '../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { PopoverMessage } from '../../../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PhoneLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/PhoneLinkProperties.type.ts';
import { LinkDialogActionsBar } from '../../components/dialog/LinkDialogActionsBar.tsx';

interface IPhoneLinkDialogProps {
  readonly linkText: string;
  readonly onCancel: () => void;
  readonly onUnlink?: () => void;
  readonly onUpsert: (values: PhoneLinkProperties) => void;
  readonly phoneNumber?: string;
}

export const PhoneLinkDialog: React.FC<IPhoneLinkDialogProps> = (props) => {
  const [linkText, setLinkText] = useState(props.linkText);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber || '');

  const isSubmittingDisabled = !linkText || !phoneNumber;

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | KeyboardEvent): void => {
    e.preventDefault();
    if (!isSubmittingDisabled) {
      props.onUpsert({
        linkText,
        phoneNumber,
      });
    }
  };

  const submitButtonText = props.onUnlink ? 'Update' : 'Insert';
  const disabledSubmitButtonTooltipText = `Fill in the link text and phone number before ${
    props.onUnlink ? 'updating' : 'insertion'
  }`;

  return (
    <ReactFocusLock>
      <HotkeysHandler
        handlers={{
          onEscape: props.onCancel,
        }}
        {...getDataUiElementAttribute(DataUiElement.RteLinkDialog)}
      >
        <form onSubmit={onSubmit}>
          <PopoverMessage>
            <Stack spacing={Spacing.XL}>
              <Input
                label="Link text"
                autoComplete="off"
                type={InputType.Text}
                value={linkText}
                onChange={(e) => setLinkText(e.target.value)}
                {...getDataUiInputAttribute(DataUiInput.Text)}
              />
              <Input
                autoFocus
                label="Phone number"
                autoComplete="off"
                type={InputType.Text}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                {...getDataUiInputAttribute(DataUiInput.PhoneNumber)}
              />
            </Stack>
          </PopoverMessage>
          <LinkDialogActionsBar
            disabledSubmitButtonTooltipText={disabledSubmitButtonTooltipText}
            isSubmittingDisabled={isSubmittingDisabled}
            onCancel={props.onCancel}
            onUnlink={props.onUnlink}
            submitButtonText={submitButtonText}
          />
        </form>
      </HotkeysHandler>
    </ReactFocusLock>
  );
};

PhoneLinkDialog.displayName = 'PhoneLinkDialog';
