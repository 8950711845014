import { Collection } from '@kontent-ai/utils';
import { IProjectContributor } from '../../data/models/users/ProjectContributor.ts';
import { toFuzzyDateDifference, toLocalTime } from './dateTime/timeUtils.ts';
import { CommonUser, formatCurrentUserName } from './usersUtils.ts';

type Props = {
  readonly currentUserId: string;
  readonly projectContributors: ReadonlyMap<Uuid, CommonUser>;
  readonly entityChangedAt: DateTimeStamp | null;
  readonly entityChangedBy: string | null;
};

export const getTimeAndAuthorOfEntityChange = ({
  currentUserId,
  projectContributors,
  entityChangedAt,
  entityChangedBy,
}: Props): string => {
  const entityChangeAtLocalTime = toLocalTime(entityChangedAt);
  const entityChangedFuzzyTime = entityChangeAtLocalTime
    ? toFuzzyDateDifference(new Date(), entityChangeAtLocalTime, true)
    : 'Unknown';

  const author =
    Collection.getValues(projectContributors).find(
      (person: IProjectContributor) => person.userId === entityChangedBy,
    ) ?? null;

  return `${entityChangedFuzzyTime} by ${formatCurrentUserName(currentUserId, false)(author)}`;
};
