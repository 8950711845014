import classNames from 'classnames';
import React, { useContext } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ListingCategory } from '../models/ProjectOverviewListingCategory.ts';
import {
  IPlanningStatusTileDetails,
  PlanningStatusTile,
  PlanningStatusTileStatus,
} from './PlanningStatusTile.tsx';
import { ProjectOverviewPageContext } from './ProjectOverviewPageContext.tsx';

interface IPlanningStatusProps {
  readonly itemsOnTrackCount: number;
  readonly delayedItemsCount: number;
  readonly scheduledToPublishItemsCount: number;
  readonly isLoading: boolean;
}

const delayedTileDetails: IPlanningStatusTileDetails = {
  caption: 'Delayed',
  disabledTooltip: 'No delayed items',
  enabledTooltip: 'View delayed items',
};

const onTrackTileDetails: IPlanningStatusTileDetails = {
  caption: 'On track',
  disabledTooltip: 'No items on track',
  enabledTooltip: 'View items on track',
};

const scheduledToPublishTileDetails: IPlanningStatusTileDetails = {
  caption: 'Scheduled to publish',
  disabledTooltip: 'No items scheduled to publish',
  enabledTooltip: 'View items scheduled to publish',
};

const getTileStatus = (isLoading: boolean, count: number): PlanningStatusTileStatus => {
  if (isLoading) {
    return PlanningStatusTileStatus.Loading;
  }

  return count > 0 ? PlanningStatusTileStatus.Enabled : PlanningStatusTileStatus.Disabled;
};

export const PlanningStatus: React.FC<IPlanningStatusProps> = ({
  isLoading,
  delayedItemsCount,
  itemsOnTrackCount,
  scheduledToPublishItemsCount,
}) => {
  const delayedTileStatus = getTileStatus(isLoading, delayedItemsCount);
  const onTrackTileStatus = getTileStatus(isLoading, itemsOnTrackCount);
  const scheduledToPublishTileStatus = getTileStatus(isLoading, scheduledToPublishItemsCount);
  const { changeCategory } = useContext(ProjectOverviewPageContext);

  return (
    <div
      className={classNames('planning-status', {
        'planning-status--is-loading': isLoading,
      })}
      {...getDataUiElementAttribute(DataUiElement.PlanningStatus)}
    >
      <ul className="planning-status__tiles">
        <li className="planning-status__tile">
          <PlanningStatusTile
            count={itemsOnTrackCount}
            details={onTrackTileDetails}
            key={onTrackTileDetails.caption}
            onClick={() => changeCategory(ListingCategory.OnTrack)}
            status={onTrackTileStatus}
          />
        </li>
        <li className="planning-status__tile">
          <PlanningStatusTile
            count={delayedItemsCount}
            details={delayedTileDetails}
            key={delayedTileDetails.caption}
            onClick={() => changeCategory(ListingCategory.Delayed)}
            status={delayedTileStatus}
          />
        </li>
        <li className="planning-status__tile">
          <PlanningStatusTile
            count={scheduledToPublishItemsCount}
            details={scheduledToPublishTileDetails}
            key={scheduledToPublishTileDetails.caption}
            onClick={() => changeCategory(ListingCategory.ScheduledToPublish)}
            status={scheduledToPublishTileStatus}
          />
        </li>
      </ul>
    </div>
  );
};

PlanningStatus.displayName = 'PlanningStatus';
