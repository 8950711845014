import classNames from 'classnames';
import React from 'react';

export interface IContentItemPaneElementsGroupDataProps {
  readonly areHeaderContentGroups?: boolean;
  readonly className?: string;
  readonly isDisabled: boolean;
  readonly isGuideline?: boolean;
  readonly hasTopRoundedCorners?: boolean;
  readonly hasBottomRoundedCorners?: boolean;
}

export const ContentItemPaneElementsGroup: React.FC<
  React.PropsWithChildren<IContentItemPaneElementsGroupDataProps>
> = ({
  areHeaderContentGroups,
  children,
  className,
  isDisabled,
  isGuideline,
  hasTopRoundedCorners,
  hasBottomRoundedCorners,
}) => {
  return (
    <div
      className={classNames(className, 'content-item-pane__elements-group', {
        'content-item-pane__elements-group--is-disabled': isDisabled,
        'content-item-pane__elements-group--is-guideline': isGuideline,
        'content-item-pane__elements-group--has-top-rounded-corners': hasTopRoundedCorners,
        'content-item-pane__elements-group--has-bottom-rounded-corners': hasBottomRoundedCorners,
        'content-item-pane__elements-group--are-header-content-group-tabs': areHeaderContentGroups,
      })}
    >
      {children}
    </div>
  );
};

ContentItemPaneElementsGroup.displayName = 'ContentItemPaneElementsGroup';
