import React from 'react';
import { Redirect } from 'react-router';
import {
  AccessDeniedRoute,
  AccessDeniedRouteParams,
  EnvironmentAccessDeniedRoute,
  EnvironmentAccessDeniedRouteParams,
  ProjectAccessDeniedRoute,
  ProjectAccessDeniedRouteParams,
} from '../../constants/routePaths.ts';
import { shouldRedirectToDefault } from '../../utils/routing/queryParamsUtils.ts';
import { buildDefaultRoute } from '../../utils/routing/redirectToDefaultRoute.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

export interface IAuthorizedSectionDataProps {
  readonly appName: string;
  readonly isAuthorized: boolean;
  readonly projectContainerId?: Uuid;
  readonly projectId: Uuid;
}

export const AuthorizedSection: React.FC<React.PropsWithChildren<IAuthorizedSectionDataProps>> = ({
  isAuthorized,
  projectId,
  projectContainerId,
  appName,
  children,
}) => {
  if (!isAuthorized) {
    if (shouldRedirectToDefault(location)) {
      return <Redirect to={buildDefaultRoute(projectId)} />;
    }

    if (projectContainerId && isUuid(projectContainerId)) {
      return (
        <Redirect
          to={buildPath<ProjectAccessDeniedRouteParams>(ProjectAccessDeniedRoute, {
            requestedAppName: appName,
            projectContainerId,
          })}
        />
      );
    }

    if (isUuid(projectId)) {
      return (
        <Redirect
          to={buildPath<EnvironmentAccessDeniedRouteParams>(EnvironmentAccessDeniedRoute, {
            requestedAppName: appName,
            projectId,
          })}
        />
      );
    }

    return (
      <Redirect
        to={buildPath<AccessDeniedRouteParams>(AccessDeniedRoute, { requestedAppName: appName })}
      />
    );
  }

  return <>{children}</>;
};

AuthorizedSection.displayName = 'AuthorizedSection';
