import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { initEditItemVariantSmartLink } from '../actions/thunks/initEditItemSmartLink.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemRouteParams } from '../constants/routePaths.ts';

export const EditItemSmartLink = () => {
  const location = useLocation();
  const { itemGuid: itemId, variantGuid: variantId, projectId } = useParams<EditItemRouteParams>();

  const params = useMemo(
    () => ({
      projectId,
      itemId,
      variantId,
      location,
    }),
    [projectId, itemId, variantId, location],
  );

  useThunkPromise(initEditItemVariantSmartLink, params);

  return <SmartLink />;
};
