import { ContentState, SelectionState } from 'draft-js';
import { isWordChar } from '../../../../../../applications/itemEditor/features/Revisions/utils/diffUnicodeUtils.ts';
import {
  createSelection,
  getSelectedText,
  isSelectionWithinOneBlock,
} from '../../../../../../applications/richText/utils/editorSelectionUtils.ts';

// We don't want to have comments on single spaces, so we use minimum of 2 chars
// to jump at least over the next word (when available)
const minimumCommentedTextLength = 2;

export const minimizeCommentSelections = (
  originalContent: ContentState,
  originalSelection: SelectionState,
  replacementContent: ContentState,
  replacementSelection: SelectionState,
): {
  readonly originalSelection: SelectionState;
  readonly replacementSelection: SelectionState;
} | null => {
  const unchangedSelections = {
    originalSelection,
    replacementSelection,
  };

  if (
    !isSelectionWithinOneBlock(originalSelection) ||
    !isSelectionWithinOneBlock(replacementSelection)
  ) {
    return unchangedSelections;
  }

  const originalText = getSelectedText(originalContent, originalSelection);
  const replacementText = getSelectedText(replacementContent, replacementSelection);

  if (originalText === replacementText) {
    return null;
  }

  const prefixLength = getCommonPrefixLength(
    originalText,
    replacementText,
    minimumCommentedTextLength,
  );
  const suffixLength = getCommonSuffixLength(
    originalText.slice(prefixLength),
    replacementText.slice(prefixLength),
    minimumCommentedTextLength,
  );

  if (!prefixLength && !suffixLength) {
    return unchangedSelections;
  }

  const minimumOriginalSelection = createSelection(
    originalSelection.getStartKey(),
    originalSelection.getStartOffset() + prefixLength,
    originalSelection.getEndKey(),
    originalSelection.getEndOffset() - suffixLength,
  );

  const minimumReplacementSelection = createSelection(
    replacementSelection.getStartKey(),
    replacementSelection.getStartOffset() + prefixLength,
    replacementSelection.getEndKey(),
    replacementSelection.getEndOffset() - suffixLength,
  );

  return {
    originalSelection: minimumOriginalSelection,
    replacementSelection: minimumReplacementSelection,
  };
};

const isWordBoundary = (text: string, index: number): boolean => !isWordChar(text[index]);

const getCommonPrefixLength = (
  original: string,
  replacement: string,
  minimumCharsLeftInOriginal: number,
): number => {
  const maxPrefixLength = Math.min(
    original.length - minimumCharsLeftInOriginal,
    replacement.length,
  );

  let lastCommonPrefixLengthAtWordBoundary = 0;
  for (let commonPrefixLength = 1; commonPrefixLength < maxPrefixLength; commonPrefixLength++) {
    if (original[commonPrefixLength - 1] !== replacement[commonPrefixLength - 1]) {
      return lastCommonPrefixLengthAtWordBoundary;
    }
    if (
      isWordBoundary(original, commonPrefixLength - 1) &&
      isWordBoundary(replacement, commonPrefixLength - 1)
    ) {
      lastCommonPrefixLengthAtWordBoundary = commonPrefixLength;
    }
  }
  return lastCommonPrefixLengthAtWordBoundary;
};

const getCommonSuffixLength = (
  original: string,
  replacement: string,
  minimumCharsLeftInOriginal: number,
): number => {
  const maxSuffixLength = Math.min(
    original.length - minimumCharsLeftInOriginal,
    replacement.length,
  );

  let lastCommonSuffixLengthAtWordBoundary = 0;
  for (let commonSuffixLength = 1; commonSuffixLength < maxSuffixLength; commonSuffixLength++) {
    if (
      original[original.length - commonSuffixLength] !==
      replacement[replacement.length - commonSuffixLength]
    ) {
      return lastCommonSuffixLengthAtWordBoundary;
    }
    if (
      isWordBoundary(original, original.length - commonSuffixLength) &&
      isWordBoundary(replacement, replacement.length - commonSuffixLength)
    ) {
      lastCommonSuffixLengthAtWordBoundary = commonSuffixLength;
    }
  }
  return lastCommonSuffixLengthAtWordBoundary;
};
