import { ElementReference } from '../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { CustomEventName } from '../events/KontentEventMap.ts';

export type AssetUploadFinishedEventDetail = {
  readonly oldAssetId: Uuid;
  readonly newAssetId: Uuid | null;
  readonly element: ElementReference | null;
};

export class AssetUploadFinishedEventForLocalState extends CustomEvent<AssetUploadFinishedEventDetail> {
  #isPropagationStopped: boolean = false;

  constructor(detail: AssetUploadFinishedEventDetail) {
    super(CustomEventName.assetUploadFinishedForLocalState, { detail });
  }

  public stopPropagation = () => {
    this.#isPropagationStopped = true;
    super.stopPropagation();
  };

  public stopImmediatePropagation = () => {
    this.#isPropagationStopped = true;
    super.stopImmediatePropagation();
  };

  public isPropagationStopped = (): boolean => this.#isPropagationStopped;
}

export class AssetUploadFinishedEventForGlobalState extends CustomEvent<AssetUploadFinishedEventDetail> {
  constructor(detail: AssetUploadFinishedEventDetail) {
    super(CustomEventName.assetUploadFinishedForGlobalState, { detail });
  }
}
