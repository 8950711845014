import { Reducer } from '@reduxjs/toolkit';
import { Path } from 'history';
import { Action } from '../../../../@types/Action.type.ts';
import { SharedContentItemPreviewRouteState } from '../../../webSpotlight/hooks/useSharedContentItemPreviewRouteState.ts';
import {
  SmartLink_HandleSuccessfulRedirect,
  SmartLink_HandleSuccessfulRedirectToContentItem,
  SmartLink_HandleUnsuccessfulRedirect,
} from '../../constants/smartLinkActionTypes.ts';

export type KnownLocationState = SharedContentItemPreviewRouteState;

export type HistoryEntry = {
  readonly path: Path;
  readonly state?: KnownLocationState;
};

export const targetHistoryEntry: Reducer<HistoryEntry | null, Action> = (state = null, action) => {
  switch (action.type) {
    case SmartLink_HandleSuccessfulRedirectToContentItem:
      return { path: action.payload.targetPath };

    case SmartLink_HandleSuccessfulRedirect:
      return action.payload;

    case SmartLink_HandleUnsuccessfulRedirect:
      return null;

    default:
      return state;
  }
};
