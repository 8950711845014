import { ContentState } from 'draft-js';
import { exportToServerPlainText } from '../../../../applications/richText/utils/export/plainText/exportToPlainText.ts';
import { AiActionName } from '../AiActionName.type.ts';
import { StringOperationInput } from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import { CumulatedOperationResponseMessageTemplate } from '../AiServerModels.response.ts';
import { CumulatedStringResult } from '../AiServerModels.result.ts';
import {
  InlineInstructionBaseInputParams,
  InlineInstructionBaseInputs,
  getInlineInstructionBaseInputs,
} from './AiServerModels.inlineInstruction.ts';

export type InlinePlainTextInstructionParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: AiActionName.PlainTextInlineInstruction;
    readonly inputs: InlineInstructionBaseInputs & {
      readonly content: StringOperationInput;
    };
  };

type InlinePlainTextInstructionInputParams = InlineInstructionBaseInputParams & {
  readonly contentState: ContentState;
};

export const createInlinePlainTextInstructionParams = (
  { contentState, ...baseParams }: InlinePlainTextInstructionInputParams,
  trackingData: ElementOperationTrackingData,
): InlinePlainTextInstructionParams => ({
  actionName: AiActionName.PlainTextInlineInstruction,
  type: 'multiple-inputs-request-v1',
  inputs: {
    ...getInlineInstructionBaseInputs(baseParams),
    content: {
      type: 'string',
      value: exportToServerPlainText(contentState),
    },
  },
  trackingData,
});

export type InlinePlainTextInstructionResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedStringResult>;
