import { ItemColumnCode } from '../_shared/constants/itemColumnCode.ts';
import { GuidelinesVisibilityStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';
import { IGuidelinesVisibilityType } from '../applications/contentInventory/content/features/ContentItemInventory/types/GuidelinesVisibility.type.ts';

interface IGuidelinesVisibilityData {
  readonly collapsedGuidelines: ReadonlyArray<Uuid>;
}

interface IGuidelinesVisibilityStorage {
  readonly load: (projectId: Uuid, userId: Uuid) => ReadonlyArray<Uuid>;
  readonly save: (
    guidelinesVisibility: IGuidelinesVisibilityType,
    projectId: Uuid,
    userId: Uuid,
  ) => void;
}

const getStorageKey = (projectId: Uuid, userId: Uuid): string =>
  `${GuidelinesVisibilityStorageKey}_${projectId}_${userId}`;

const toGuidelinesVisibilityValue = (guidelinesVisibilityData: IGuidelinesVisibilityData): string =>
  JSON.stringify(guidelinesVisibilityData);

export const guidelinesVisibilityStorage: IGuidelinesVisibilityStorage = {
  load(projectId: Uuid, userId: Uuid): ReadonlyArray<Uuid> {
    try {
      const data = localStorage.get(getStorageKey(projectId, userId)) ?? '';
      const parsedData = JSON.parse(data) as IGuidelinesVisibilityData;
      return parsedData?.collapsedGuidelines ?? [];
    } catch {
      return [];
    }
  },

  save(guidelinesVisibility: IGuidelinesVisibilityType, projectId: Uuid, userId: Uuid): void {
    const collapsedGuidelines = guidelinesVisibilityStorage.load(projectId, userId);
    if (
      guidelinesVisibility.collapsed &&
      !collapsedGuidelines.includes(guidelinesVisibility.guidelinesElementId)
    ) {
      const updatedGuidelines = [...collapsedGuidelines];
      updatedGuidelines.push(guidelinesVisibility.guidelinesElementId);
      localStorage.set(
        getStorageKey(projectId, userId),
        toGuidelinesVisibilityValue({ collapsedGuidelines: updatedGuidelines }),
      );
    }
    if (!guidelinesVisibility.collapsed) {
      const updatedGuidelines = collapsedGuidelines.filter(
        (column: ItemColumnCode) => column !== guidelinesVisibility.guidelinesElementId,
      );
      localStorage.set(
        getStorageKey(projectId, userId),
        toGuidelinesVisibilityValue({ collapsedGuidelines: updatedGuidelines }),
      );
    }
  },
};
