import { AssetPickerRecentlyUsedFolderStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { RootFolderId } from '../applications/contentInventory/assets/constants/assetFolderConstants.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

const getFolderIdFromJS = (rawFolderId: Uuid): Uuid => {
  return rawFolderId ?? RootFolderId;
};

const assetPickerRecentlyUsedFolderStorageParams: StorageParams<Uuid> = {
  key: AssetPickerRecentlyUsedFolderStorageKey,
  initialState: RootFolderId,
  fromJS: getFolderIdFromJS,
};

export const AssetPickerRecentlyUsedFolderStorage = getProjectSpecificStorage<Uuid>(
  assetPickerRecentlyUsedFolderStorageParams,
);
