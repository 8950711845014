import {
  getCharacterWithoutLineBreaksCount,
  getWordCount,
} from '../../../../../../_shared/utils/textUtils.ts';
import {
  validateMaxChars,
  validateMaxWords,
} from '../../../../../../_shared/utils/validation/limitations/baseTextLimitValidator.ts';
import { getIsRegexValidationMet } from '../../../../../itemEditor/utils/itemElementWarningCheckers/utils/validationRegexWarningHelper.ts';
import { ITextTypeElementData } from '../../../models/elements/TextTypeElementData.ts';
import { getIntegerFromStringOrNull } from '../../typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { hasTextDefaultValue } from '../../typeElementDefaultValueUtils.ts';

export const areLimitationsInTextDefaultValueMet = (typeElement: ITextTypeElementData): boolean => {
  if (!hasTextDefaultValue(typeElement)) {
    return true;
  }

  const defaultValue = typeElement.defaultValue;
  const maxChars = getIntegerFromStringOrNull(typeElement.maxChars);
  const maxWords = getIntegerFromStringOrNull(typeElement.maxWords);
  const charsCount = getCharacterWithoutLineBreaksCount(defaultValue);
  const wordsCount = getWordCount(defaultValue);

  const isMaxCharsLimitMet = validateMaxChars(maxChars, charsCount);
  const isMaxWordsLimitMet = validateMaxWords(maxWords, wordsCount);
  const isRegexValidationMet = getIsRegexValidationMet(typeElement.validationRegex, defaultValue);

  return isMaxCharsLimitMet && isMaxWordsLimitMet && isRegexValidationMet;
};
