import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface INewCommentThreadItemReplyOptionsProps {
  readonly canSuggest: boolean;
  readonly onNewSuggestion: () => void;
  readonly onNewComment: () => void;
}

export const NewCommentThreadItemReplyOptions: FC<INewCommentThreadItemReplyOptionsProps> = (
  props,
) => {
  return (
    <div className="comment-editor__options">
      <div className="comment-editor__actions">
        {props.canSuggest && (
          <Button
            buttonStyle="secondary"
            size="small"
            onClick={props.onNewSuggestion}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.Suggest)}
          >
            Suggest changes
          </Button>
        )}
        <Button
          buttonStyle="primary"
          size="small"
          onClick={props.onNewComment}
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.Reply)}
        >
          Reply
        </Button>
      </div>
    </div>
  );
};

NewCommentThreadItemReplyOptions.displayName = 'NewCommentThreadItemReplyOptions';
