import { ComponentProps, useEffect } from 'react';
import { Sidebar as SidebarComponent } from '../../components/Sidebar/Sidebar.tsx';
import { Shared_AnySidebar_Unmounted } from '../../constants/sharedActionTypes.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';

const createAnySidebarUnmountedAction = () => ({ type: Shared_AnySidebar_Unmounted }) as const;

export type SidebarActionsType = ReturnType<typeof createAnySidebarUnmountedAction>;

export const Sidebar = ({ children, ...props }: ComponentProps<typeof SidebarComponent>) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(createAnySidebarUnmountedAction());
    };
  }, []);

  return <SidebarComponent {...props}>{children}</SidebarComponent>;
};
