import React, { useCallback, useContext } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../stores/utils/contentItemElementsUtils.ts';
import { selectContentGroup } from '../actions/thunkContentItemEditingActions.ts';
import { ContentGroupTab } from '../components/ContentGroupTab.tsx';
import { ContentGroupTabs } from '../components/ContentGroupTabs.tsx';
import { ContentGroupSelectionReason } from '../constants/ContentGroupSelectionReason.ts';
import { RichTextItemElementContext } from '../context/RichTextItemElementContext.tsx';
import { getContentGroupBadgeType } from '../selectors/getContentGroupBadgeType.ts';
import { getContentComponent } from '../utils/contentComponentUtils.ts';
import { CreateContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import {
  getIncompleteElementIdPaths,
  getIncompleteElementsPerGroup,
} from '../utils/itemValidationUtils.ts';

interface IContentGroupTabsInComponentProps {
  readonly canEditContent: boolean;
  readonly contentComponentId: Uuid;
  readonly contentType: ICompiledContentType;
  readonly hideValidationStatus?: boolean;
}

export const ContentGroupTabsInComponent: React.FC<IContentGroupTabsInComponentProps> = (props) => {
  const { canEditContent, contentComponentId, contentType, hideValidationStatus } = props;

  const { rootRichTextElementId } = useContext(RichTextItemElementContext);
  const contentGroupTabsId = CreateContentGroupTabsId.forContentComponent(contentComponentId);

  const contentTypeElements = contentType.contentElements;
  const contentTypeGroups = contentType.contentGroups;
  const selectedContentGroupId = useSelector((state) =>
    getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, contentTypeGroups, state),
  );

  const badgeType = useSelector((state) =>
    getContentGroupBadgeType(
      state.contentApp.showIncompleteItemWarningsBeforePublish,
      hideValidationStatus,
    ),
  );

  const incompleteElementsPerGroup = useSelector((state) => {
    const { itemValidationErrors, itemValidationWarnings, editedContentItemVariantElements } =
      state.contentApp;
    if (!rootRichTextElementId || !editedContentItemVariantElements) {
      return Immutable.Map<Uuid, number>();
    }

    const incompleteElementIds = getIncompleteElementIdPaths(
      itemValidationErrors,
      itemValidationWarnings,
    );
    const elements = getContentComponent(
      editedContentItemVariantElements,
      rootRichTextElementId,
      contentComponentId,
    )?.elements;
    if (!elements) {
      return Immutable.Map<Uuid, number>();
    }

    return getIncompleteElementsPerGroup(
      contentTypeGroups,
      elements,
      contentTypeElements,
      incompleteElementIds,
      contentComponentId,
    );
  });

  const dispatch = useDispatch();
  const onSelect = useCallback(
    (contentGroupId: Uuid) => {
      dispatch(
        selectContentGroup({
          contentGroupTabsId,
          contentGroupId,
          reason: ContentGroupSelectionReason.UserClick,
        }),
      );
    },
    [contentGroupTabsId],
  );

  return (
    <ContentGroupTabs
      {...props}
      badgeType={badgeType}
      canEditContent={canEditContent}
      contentTypeGroups={contentTypeGroups}
      incompleteElementsPerGroup={incompleteElementsPerGroup}
      selectedContentGroupId={selectedContentGroupId}
      tabItemComponent={ContentGroupTab}
      onSelect={onSelect}
    />
  );
};

ContentGroupTabsInComponent.displayName = 'ContentGroupTabsInComponent';
