import classNames from 'classnames';
import React, { forwardRef } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export enum LoaderType {
  Narrow = 'loader__wrapper--narrow',
  MinimumHeight = 'loader__wrapper--minimum',
  Sidebar = 'loader__wrapper--sidebar',
}

export interface ILoaderProps {
  readonly className?: string;
  readonly type?: LoaderType;
  readonly renderContent?: () => React.ReactNode;
  readonly text?: string;
}

export const Loader = forwardRef<HTMLDivElement, ILoaderProps>(
  ({ className, type, renderContent, text }, forwardedRef) => (
    <div
      className={classNames('loader__wrapper', type, className)}
      ref={forwardedRef}
      tabIndex={-1}
      aria-label={text}
      {...getDataUiElementAttribute(DataUiElement.Loader)}
    >
      {text && <div className="loader__text">{text}</div>}
      {renderContent !== undefined ? renderContent() : null}
      <div className="loader">
        <div className="loader__bubble" />
      </div>
    </div>
  ),
);

Loader.displayName = 'Loader';
