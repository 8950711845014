import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Spacing, Typography, px } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import { FC, useCallback, useMemo } from 'react';
import { Warning } from '../../../../../../../_shared/components/infos/Warning.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IRoleSettings,
  IRuleWithScope,
} from '../../../../../../../data/models/roles/IRoleSettings.ts';
import { emptyAssetCanRule } from '../../../../constants/emptyRules.ts';
import { isAssetScopeRule, isContentScopeRule } from '../../../../utils/ruleScopeUtils.ts';
import { AssetCapabilityRulePicker } from './AssetCapabilityRulePicker.tsx';

export type AssetsCapabilitiesSectionProps = {
  readonly isDisabled: boolean;
  readonly onSettingsChanged: (settings: IRoleSettings) => void;
  readonly roleSettings: IRoleSettings;
};

export const AssetsCapabilitiesSection: FC<AssetsCapabilitiesSectionProps> = ({
  isDisabled,
  onSettingsChanged,
  roleSettings,
}) => {
  const contentCanRules = useMemo(
    () => roleSettings.canRules.filter(isContentScopeRule),
    [roleSettings],
  );
  const assetCanRules = useMemo(
    () => roleSettings.canRules.filter(isAssetScopeRule),
    [roleSettings],
  );
  const hasAnyContentRules = contentCanRules.length > 0;
  const hasAnyAssetRules = assetCanRules.length > 0;
  const onChange = useCallback(
    (newAssetCanRules: ReadonlyArray<IRuleWithScope>) =>
      onSettingsChanged({
        ...roleSettings,
        canRules: [...contentCanRules, ...newAssetCanRules],
      }),
    [contentCanRules, onSettingsChanged, roleSettings],
  );
  return (
    <>
      <Box typography={Typography.HeadlineMedium} marginTop={Spacing.XXL} marginBottom={Spacing.L}>
        Assets
      </Box>
      <div className="capability-rules__pane capability-rules__pane--expanded">
        <div
          {...getDataUiCollectionAttribute(DataUiCollection.CapabilityRules)}
          className="capability-rules__section"
        >
          {hasAnyAssetRules ? (
            <>
              <p className="capability-rules__title">Users with this role can:</p>
              {assetCanRules.map((rule: IRuleWithScope, index: number) => (
                <AssetCapabilityRulePicker
                  key={index}
                  capabilityRule={rule}
                  isDisabled={isDisabled}
                  onChange={(newRule) =>
                    onChange(Collection.replace(assetCanRules, index, newRule))
                  }
                  onRemove={() => onChange(assetCanRules.filter((_, i) => i !== index))}
                />
              ))}
            </>
          ) : (
            <Box marginBottom={px(Spacing.XXL)} maxWidth="fit-content">
              <Button
                buttonStyle="tertiary"
                onClick={() =>
                  onSettingsChanged({
                    ...roleSettings,
                    canRules: [...roleSettings.canRules, emptyAssetCanRule],
                  })
                }
                disabled={isDisabled}
                {...getDataUiActionAttribute(DataUiAction.Add)}
              >
                Add rule
              </Button>
            </Box>
          )}
          {hasAnyContentRules && !hasAnyAssetRules && (
            <Warning subtitle="You didn’t set any asset permissions for this role.">
              <p>
                Permissions for assets are set here separately. They can be different from
                permissions for content items.
              </p>
            </Warning>
          )}
        </div>
      </div>
    </>
  );
};

AssetsCapabilitiesSection.displayName = 'AssetsCapabilitiesSection';
