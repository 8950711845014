import {
  SimpleStatusDefault,
  SimpleStatusError,
  SimpleStatusWarning,
} from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';
import { isEmptyOrWhitespace } from '../../utils/stringUtils.ts';
import { getFormattedDueDate } from './utils/getFormattedDueDate.ts';

type DueDateFormat = 'auto' | 'basic';

interface DueDateProps {
  /** Set to 'basic' if you want the basic format always. Useful for items that are already done. */
  readonly format?: DueDateFormat;
  readonly dueDate?: string | null;
}

export const DueDate: React.FC<DueDateProps> = ({ dueDate, format = 'auto', ...rest }) => {
  if (!dueDate || isEmptyOrWhitespace(dueDate)) {
    return null;
  }

  const { almostDue, dueDateFuzzy, dueDateReadable, overdue } = getFormattedDueDate(dueDate);

  const basicFormat = (
    <SimpleStatusDefault
      label={dueDateFuzzy}
      tooltipText={dueDateReadable || undefined}
      {...rest}
    />
  );

  if (format === 'basic') {
    return basicFormat;
  }

  if (overdue) {
    return (
      <SimpleStatusError
        label={dueDateFuzzy}
        tooltipText={dueDateReadable || undefined}
        {...rest}
      />
    );
  }

  if (almostDue) {
    return (
      <SimpleStatusWarning
        label={dueDateFuzzy}
        tooltipText={dueDateReadable || undefined}
        {...rest}
      />
    );
  }

  return basicFormat;
};

DueDate.displayName = 'DueDate';
