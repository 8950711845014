import { forwardRef } from 'react';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { cancelContentItemVariantsScheduledUnpublish } from '../../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { CancelScheduleUnpublishConfirmationDialog as Component } from '../../components/cancelConfirmation/CancelScheduleUnpublishConfirmationDialog.tsx';

export const CancelScheduleUnpublishConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();
  const onCancel = compose(dispatch, modalDismissed);
  const onConfirm = compose(dispatch, cancelContentItemVariantsScheduledUnpublish);

  return <Component onCancel={onCancel} onConfirm={onConfirm} ref={ref} {...props} />;
});

CancelScheduleUnpublishConfirmationDialog.displayName =
  'CancelScheduleUnpublishConfirmationDialogContainer';
