import { delay } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentComponentActionType } from '../../../../../../_shared/models/TrackUserEventData.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';

interface IDeps {
  readonly deactivateContentItemEditingAction: () => Action;
  readonly contentComponentsExpanded: (contentComponentIds: Immutable.Set<Uuid>) => Action;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createExpandAllContentComponentsAction =
  (deps: IDeps) => (): ThunkPromise => async (dispatch, getState) => {
    const {
      editedContentItemVariant,
      editorUi: { collapsedContentComponents },
    } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw new Error('This is available only in edited content item');
    }
    dispatch(deps.deactivateContentItemEditingAction());

    // Let the menu hide first and defer expanding action a bit to give React a bit of breathing space
    // This prevents menu to trigger style recalculation while in the middle of mounting and positioning a lot of content components which is expensive
    await delay(0);
    dispatch(deps.contentComponentsExpanded(collapsedContentComponents));
    dispatch(
      deps.trackUserEventWithData(TrackedEvent.ContentComponentCollapse, {
        action: ContentComponentActionType.ExpandAll,
        'item-id': editedContentItemVariant.id.itemId,
        'variant-id': editedContentItemVariant.id.variantId,
      }),
    );

    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.ExpandComponents,
        origin: ContentItemEditingEventOrigins.MoreActions,
      }),
    );
  };
