import React from 'react';
import { PlayableImage } from '../../../../../_shared/uiComponents/PlayableImage/PlayableImage.tsx';
import { isImageMimeType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';

export interface IAssetPreviewProps {
  readonly asset: IAsset;
}

export const AssetPreview: React.FC<IAssetPreviewProps> = ({ asset }) => {
  const isImage = isImageMimeType(asset.type);
  const hasPreview = isImage || !!asset._thumbnailLink;

  return hasPreview ? (
    <div className="asset-library-pane__editor-preview">
      <PlayableImage
        previewUrl={asset._thumbnailLink || ''}
        playUrl={asset.isAnimatedGif ? (asset._downloadLink ?? undefined) : undefined}
      />
    </div>
  ) : null;
};

AssetPreview.displayName = 'AssetPreview';
