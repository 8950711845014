import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.type.ts';

export type AssetRenditionReference = {
  readonly id: Uuid;
};

export type AssetReference = {
  readonly id: Uuid;
  readonly renditions: ReadonlyArray<AssetRenditionReference>;
};

export const createAssetReferenceMemoized = memoize.maxN(
  (assetId: Uuid, renditions?: ReadonlyArray<AssetRenditionReference>): AssetReference => ({
    id: assetId,
    renditions: renditions ?? [],
  }),
  1_000,
);

interface IAssetItemElementValue {
  readonly value: Immutable.OrderedMap<Uuid, AssetReference>;
}

export interface IAssetItemElement
  extends IAssetItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyAssetItemElementValue: IAssetItemElementValue = {
  value: Immutable.OrderedMap(),
};

export const EmptyAssetItemElement: IAssetItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Asset,
  ...EmptyAssetItemElementValue,
};

export const newAssetItemElement = (params: Partial<IAssetItemElement>): IAssetItemElement => ({
  ...EmptyAssetItemElement,
  ...params,
});
