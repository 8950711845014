import { Collection } from '@kontent-ai/utils';
import {
  FeatureNameMap,
  RichTextFeature,
  TopLevelBlockCategoryFeature,
} from '../../../../plugins/apiLimitations/api/editorLimitationUtils.ts';

export const getViolatedFeaturesWarning = (
  violatedFeatures: ReadonlySet<RichTextFeature>,
  areTableFeatures: boolean = false,
): string => {
  if (!violatedFeatures.size) {
    return '';
  }

  const blockNames = Collection.getValues(violatedFeatures).map(
    (block: TopLevelBlockCategoryFeature) => FeatureNameMap[block],
  );
  return `The following ${
    areTableFeatures ? 'table' : 'text'
  } limitations have been set: ${blockNames.join(', ')}. Remove them before publishing.`;
};
