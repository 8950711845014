import { memoize } from '@kontent-ai/memoization';
import { getDefaultProjectLocationId } from '../../../../_shared/constants/projectLocationIds.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ISubscription } from '../../../models/subscriptions/Subscription.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
  getSelectedSubscription,
  getSubscriptionsSortedByName,
} from './subscriptionSelectors.ts';

export const getSampleProjectAllowedSubscriptions = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> => {
    return subscriptions.filter(
      (s) =>
        // no explicitly allowed locations, all default locations are available
        s.subscriptionDetails.allowedProjectLocationIds.length === 0 ||
        // only explicit locations available && able to create Sample Project
        s.subscriptionDetails.allowedProjectLocationIds.some(
          (locationId) => !!locationId && getDefaultProjectLocationId() === locationId,
        ),
    );
  },
);

export const getDefaultSubscriptionId = (
  state: IStore,
  sortedSubscriptions: ReadonlyArray<ISubscription>,
): Uuid => {
  const selectedSubscription = getSelectedSubscription(state);
  const subscriptionSelectorDefaultValue = sortedSubscriptions[0]?.subscriptionId ?? '';

  return selectedSubscription
    ? selectedSubscription?.subscriptionId
    : subscriptionSelectorDefaultValue;
};

export const getSortedSubscriptions = (state: IStore): ReadonlyArray<ISubscription> => {
  const { administratedIds, byId } = state.data.subscriptions;
  const administratedSubscriptions = getAdministratedSubscriptions(administratedIds, byId);
  const activeSubscriptions = getActiveSubscriptions(administratedSubscriptions);
  const sampleProjectAllowedSubscriptions =
    getSampleProjectAllowedSubscriptions(activeSubscriptions);

  return getSubscriptionsSortedByName(sampleProjectAllowedSubscriptions);
};
