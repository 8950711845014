import React, { useCallback, useRef } from 'react';
import { IUrlSlugTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/UrlSlugTypeElement.ts';
import { IFocusable } from '../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { EditorChangeCallback } from '../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { IUrlSlugItemElement } from '../../../models/contentItemElements/UrlSlugItemElement.ts';
import { UrlSlugInput } from '../../ContentItemEditing/containers/elements/urlSlug/UrlSlugInput.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

export interface IContentComponentUrlSlugElementProps
  extends IContentComponentItemElementOwnProps<IUrlSlugItemElement, IUrlSlugTypeElement> {}

export const ContentComponentUrlSlugElement: React.FC<IContentComponentUrlSlugElementProps> = (
  props,
) => {
  const {
    autoFocus,
    contentComponentId,
    disabled,
    elementData,
    onUpdate,
    typeElement,
    validationResultSelectorId,
  } = props;

  const editorRef = useRef<IFocusable>(null);

  const onContentChange: EditorChangeCallback = useCallback(
    (editorState) => {
      onUpdate({
        ...elementData,
        _editorState: editorState,
      });
      return Promise.resolve();
    },
    [elementData, onUpdate],
  );

  const focusEditorAtTheStart = useCallback(() => editorRef.current?.focusAtTheStart(), []);
  const focusEditorAtTheEnd = useCallback(() => editorRef.current?.focusAtTheEnd(), []);

  return (
    <ContentComponentItemElement
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
      disabled={disabled}
      contentComponentId={contentComponentId}
      onHeaderClick={focusEditorAtTheStart}
      onContentClick={focusEditorAtTheEnd}
    >
      <UrlSlugInput
        ref={editorRef}
        disabled={disabled}
        editorState={elementData._editorState}
        className="rte--in-content-item-element"
        onContentChange={onContentChange}
        autoFocus={autoFocus}
        customMode // url slug in component is always in the custom mode
        regenerateDisabled // there is no way to generate url slug in component
        typeElement={typeElement}
      />
    </ContentComponentItemElement>
  );
};

ContentComponentUrlSlugElement.displayName = 'ContentComponentUrlSlugElement';
