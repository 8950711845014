import { isElement, scrollToView } from '@kontent-ai/DOM';
import { DataAttributes } from '../../../../../_shared/utils/dataAttributes/DataAttributes.ts';

export const scrollToCommentsOnRemovedContentSection = (): void => {
  window.setTimeout(() => {
    const title = document.querySelector(`[${DataAttributes.CommentsOnRemovedContentHeader}]`);
    if (!isElement(title)) {
      return;
    }

    scrollToView(title, 'start');
  }, 1000);
};

export const scrollToCommentThreadOnRemovedContent = (threadId: Uuid) => {
  window.setTimeout(() => {
    const title = document.querySelector(`[${DataAttributes.CommentThreadId}='${threadId}']`);
    if (!isElement(title)) {
      return;
    }

    scrollToView(title, 'start');
  }, 1000);
};
