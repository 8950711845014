import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { areCollectionsMapsSame } from '../../../../data/models/collections/Collection.ts';

export const haveCollectionsUnsavedChanges = (state: IStore): boolean => {
  const {
    collectionsApp: { editedCollectionId, editedCollectionName, editedCollections, isCreatorOpen },
    data: { collections },
  } = state;

  return (
    isCreatorOpen ||
    (editedCollectionId !== null &&
      editedCollectionName !== collections.byId.get(editedCollectionId)?.name) ||
    !areCollectionsMapsSame(editedCollections, collections.byId)
  );
};
