import { cardBorderRadius } from '@kontent-ai/component-library/Card';
import { Placement } from '@kontent-ai/component-library/types';
import { noOperation } from '@kontent-ai/utils';
import { Modifier as CoreModifier } from '@popperjs/core';
import { Placement as PopperPlacement } from '@popperjs/core/lib/enums';
import { Options as ArrowOptions } from '@popperjs/core/lib/modifiers/arrow';
import { Options as FlipOptions } from '@popperjs/core/lib/modifiers/flip';
import { Options as PreventOverflowOptions } from '@popperjs/core/lib/modifiers/preventOverflow';
import { TippyProps } from '@tippyjs/react';
import { Modifier } from 'react-popper';
import { inlinePositioning } from 'tippy.js';
import { IPopoverProps } from '../types.type.ts';
import { IAdjustTippyOptions } from '../usePopover.tsx';
import { InlineArrowModifier } from './inlineArrow.ts';
import { getOffsetToInlineTarget } from './inlinePlacementUtils.ts';
import { GetPopperOffset, getFallbackPlacements } from './placementUtils.ts';
import { assertFeaturesAddedInOrder, getEnabledFeatures } from './tippyOptionsConsistencyUtils.ts';

export const defaultPlacement: Placement = 'bottom';

export const createRemoveModifiers =
  (modifierNames: ReadonlyArray<string>) =>
  (tippyOptions: TippyProps): TippyProps => {
    return {
      ...tippyOptions,
      popperOptions: {
        ...tippyOptions.popperOptions,
        modifiers: [
          ...(tippyOptions.popperOptions?.modifiers?.filter(
            ({ name }) => !modifierNames.includes(name),
          ) ?? []),
        ],
      },
    };
  };

function upsertModifiers(
  tippyOptions: TippyProps,
  ...modifiers: ReadonlyArray<Modifier<any>>
): TippyProps {
  const modifierNames = modifiers.map((modifier) => modifier.name);
  const withoutModifiers = createRemoveModifiers(modifierNames)(tippyOptions);

  assertFeaturesAddedInOrder(tippyOptions, ...getEnabledFeatures(modifiers));

  return {
    ...withoutModifiers,
    popperOptions: {
      ...withoutModifiers.popperOptions,
      modifiers: [...(withoutModifiers.popperOptions?.modifiers ?? []), ...modifiers],
    },
  };
}

export const createUpsertModifiers =
  (...modifiers: ReadonlyArray<Modifier<any>>) =>
  (tippyOptions: TippyProps): TippyProps => {
    return upsertModifiers(tippyOptions, ...modifiers);
  };

export const createAddArrow = (padding: ArrowOptions['padding']) =>
  createUpsertModifiers({
    name: 'arrow',
    options: {
      padding,
    },
  });

const dummyInlinePositioningModifier: CoreModifier<'inlinePositioning', Record<string, never>> = {
  name: 'inlinePositioning',
  enabled: false,
  phase: 'main',
  fn: noOperation,
};

// TippyJS's default inlinePositioning plugin is buggy for complex scenarios in combination with flip as it hacks the Popper life cycle
// https://github.com/atomiks/tippyjs/issues/977
// We leverage the native functionality and combine extra skidding with customized arrow modifier to achieve the desired behavior but in a cleaner way
export const createAddInlinePositioning =
  (targetRef: React.RefObject<HTMLElement>): IAdjustTippyOptions =>
  (tippyProps) => {
    // Adjust offset so that the popper element aligns properly to an actual part of the inline target
    const getOffset: GetPopperOffset = (params) => {
      const defaultOffset =
        typeof tippyProps.offset === 'function' ? tippyProps.offset(params) : tippyProps.offset;
      return getOffsetToInlineTarget(defaultOffset, params.placement, targetRef);
    };

    // When arrow is used, replace it with a modified arrow with inline support
    // as otherwise the standard arrow would try to move towards to the target based on bounding rectangle of the target
    const arrowModifier = tippyProps.popperOptions?.modifiers?.find((m) => m.name === 'arrow');
    const newModifiers = [
      ...(arrowModifier || tippyProps.arrow
        ? [
            {
              ...InlineArrowModifier,
              options: arrowModifier?.options,
            },
          ]
        : []),
      // We use this extra noOp modifier to properly detect the feature and assert order with other features
      dummyInlinePositioningModifier,
    ];

    return upsertModifiers(addOffset(tippyProps, getOffset), ...newModifiers);
  };

function addOffset(tippyOptions: TippyProps, offset: TippyProps['offset']): TippyProps {
  assertFeaturesAddedInOrder(tippyOptions, 'offset');

  return {
    ...tippyOptions,
    offset,
  };
}

export const createAddOffset =
  (offset: TippyProps['offset']) =>
  (tippyOptions: TippyProps): TippyProps =>
    addOffset(tippyOptions, offset);

export const createAddPreventOverflow = (options: Partial<PreventOverflowOptions>) => {
  const preventOverflowModifiers = [
    {
      name: 'computeStyles',
      options: {
        // We need to disable the adaptive mode, as the combination of flip and preventOverflow modifiers may misplace the popper
        // See https://github.com/popperjs/react-popper/issues/383#issuecomment-723996981 for more details
        adaptive: false,
      },
    },
    {
      name: 'preventOverflow',
      options,
    },
  ];

  return createUpsertModifiers(...preventOverflowModifiers);
};

export const addPreventOverflow = createAddPreventOverflow({});

type ReadonlyFlipOptions = Omit<FlipOptions, 'fallbackPlacements' | 'allowedAutoPlacements'> & {
  readonly fallbackPlacements: ReadonlyArray<PopperPlacement>;
  readonly allowedAutoPlacements: ReadonlyArray<PopperPlacement>;
};

export const createAddFlipping =
  (options: Partial<ReadonlyFlipOptions>) =>
  (tippyOptions: TippyProps): TippyProps => {
    const isInlinePositioningPluginEnabled =
      !!tippyOptions.inlinePositioning && !!tippyOptions.plugins?.includes(inlinePositioning);
    const placement = tippyOptions.placement ?? defaultPlacement;

    const existingModifiers = tippyOptions.popperOptions?.modifiers;

    const arrowModifier = existingModifiers?.find((m) => m.name === 'arrow');
    const arrowPadding = arrowModifier?.options?.padding ?? 0;

    const preventOverflowModifier = existingModifiers?.find((m) => m.name === 'preventOverflow');
    const preventOverflowPadding = preventOverflowModifier?.options?.padding ?? 0;

    const flippingModifiers = [
      {
        name: 'flip',
        options: {
          fallbackPlacements:
            options.fallbackPlacements ??
            getFallbackPlacements(placement, isInlinePositioningPluginEnabled),
          // If prevent overflow is defined with a non-default boundary, we need to use the same boundary for flipping so it flips soon enough
          // Same with padding, we need to consider the arrow and prevent overflow padding to give flip a properly adjusted boundary
          boundary: options.boundary ?? preventOverflowModifier?.options?.boundary ?? 'viewport',
          padding: options.padding ?? arrowPadding + preventOverflowPadding,
        },
      },
    ];

    return upsertModifiers(tippyOptions, ...flippingModifiers);
  };

export const addFlipping = createAddFlipping({});

// Tippy and Popper have a load of default modifiers.
// The defaults can be seen here:
// https://github.com/floating-ui/floating-ui/blob/v2.x/src/popper.js#L16
// https://github.com/atomiks/tippyjs/blob/master/src/createTippy.ts#L634
// In order to override some of them and avoid unwanted default behavior, the following modifiers are necessary.
export const defaultPopperModifiers = [
  {
    name: 'flip',
    enabled: false,
  },
  {
    name: 'preventOverflow',
    enabled: false,
  },
];

export const getDefaultPopoverTippyOptions = (placement?: IPopoverProps['placement']) => {
  return {
    placement: placement ?? defaultPlacement,
    popperOptions: {
      modifiers: [
        {
          name: 'arrow',
          options: {
            padding: cardBorderRadius, // prevent arrow from reaching the very edge of the popover — because of the rounded corners
          },
        },
        ...defaultPopperModifiers,
      ],
    },
  };
};

export const addNonInteractive: IAdjustTippyOptions = (tippyOptions) => ({
  ...tippyOptions,
  interactive: false,
});
