import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { AssignedContentItemsOrderBy } from '../../../itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';
import { changeOrderByColumn } from '../yourContentActions.ts';

interface IDependencies {
  readonly loadContentItemsAssignedToCurrentUser: (
    orderBy: OrderBy<AssignedContentItemsOrderBy>,
  ) => ThunkPromise;
}

export const createOrderAssignedContentItemsAction =
  (deps: IDependencies) =>
  (orderBy: OrderBy<AssignedContentItemsOrderBy>): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(changeOrderByColumn(orderBy));
      await dispatch(deps.loadContentItemsAssignedToCurrentUser(orderBy));
    } catch (e) {
      logError('Ordering of assigned content items failed.', e);
    }
  };
