import {
  SavedStatusMessage,
  SavingStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { StatusMessage } from '../../../../../_shared/uiComponents/StatusMessage/StatusMessage.tsx';

type CustomAppSavingStatusProps = {
  readonly customAppActionIsInProgress: boolean;
};

export const CustomAppSavingStatus = ({
  customAppActionIsInProgress,
}: CustomAppSavingStatusProps) => {
  return customAppActionIsInProgress ? (
    <StatusMessage statusMessage={SavingStatusMessage} />
  ) : (
    <StatusMessage statusMessage={SavedStatusMessage} />
  );
};
