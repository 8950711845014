import React from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonStyle } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IRuleDeleteButtonProps {
  readonly onRemove: () => void;
  readonly disabled?: boolean;
}

export const RuleDeleteButton: React.FC<IRuleDeleteButtonProps> = ({ disabled, onRemove }) => (
  <SquareButton
    className="capability-rules__delete-button"
    disabled={disabled}
    iconName={IconName.Bin}
    onClick={disabled ? undefined : onRemove}
    ariaLabel="Delete rule"
    style={SquareButtonStyle.Tertiary}
    {...getDataUiActionAttribute(DataUiAction.Delete)}
  />
);

RuleDeleteButton.displayName = 'RuleDeleteButton';
