import { OverlayContainer } from '@react-aria/overlays';
import React, { useContext } from 'react';
import { PortalContainerContext } from '../_contexts/PortalContainerContext.tsx';

export const OverlayPortalContainer: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const { overlayPortalContainerRef } = useContext(PortalContainerContext);

  return (
    <OverlayContainer portalContainer={overlayPortalContainerRef.current ?? undefined}>
      {children}
    </OverlayContainer>
  );
};
