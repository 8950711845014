import React from 'react';
import { ContentItemUsagesLinks } from '../../components/ContentItemUsagesLinks/ContentItemUsagesLinks.tsx';
import { useSelector } from '../../hooks/useSelector.ts';

export type ContentItemUsagesLinksContainerProps = {
  readonly contentItemId: Uuid;
};

const ContentItemUsagesLinksContainer: React.FC<ContentItemUsagesLinksContainerProps> = (props) => {
  const contentItemUsages = useSelector((s) => s.data.listingContentItems.contentItemUsages);

  return <ContentItemUsagesLinks {...props} contentItemUsages={contentItemUsages} />;
};

ContentItemUsagesLinksContainer.displayName = 'ContentItemUsagesLinksContainer';
export { ContentItemUsagesLinksContainer as ContentItemUsagesLinks };
