import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import {
  IExistingUserToInvite,
  mapExistingUserToInviteFromServerModel,
} from '../../../../../../data/models/users/ExistingUserToInvite.ts';
import {
  Invite_ExistingUsers_Loaded,
  Invite_SubmitInvitations_Finished,
} from '../../../constants/usersActionTypes.ts';

const emptyState = Immutable.Map<string, IExistingUserToInvite>();

export const existingUsersByEmail = (
  state: Immutable.Map<string, IExistingUserToInvite> = emptyState,
  action: Action,
): Immutable.Map<string, IExistingUserToInvite> => {
  switch (action.type) {
    case Invite_ExistingUsers_Loaded:
      return action.payload.existingUsers
        .map(mapExistingUserToInviteFromServerModel)
        .reduce(
          (result, user) => result.set(user.email, user),
          Immutable.Map<string, IExistingUserToInvite>(),
        );

    case Invite_SubmitInvitations_Finished: {
      const invitedUserIds = action.payload.users.map((u) => u.userId);
      return state.filter((u: IExistingUserToInvite) => !invitedUserIds.includes(u.userId)).toMap();
    }
    default:
      return state;
  }
};
