import { ContentBlock, ContentState } from 'draft-js';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { getDeliveryContentComponentId } from '../../../itemEditor/features/ContentComponent/utils/deliveryContentComponentUtils.ts';
import { getContentComponentId } from '../../plugins/contentComponents/api/editorContentComponentUtils.ts';
import { getModularContentItemId } from '../../plugins/linkedItems/api/editorModularUtils.ts';
import { getBlocks } from '../general/editorContentGetters.ts';
import { isContentComponent, isContentModule } from './blockTypeUtils.ts';
import { getBlockHierarchy } from './editorHierarchyUtils.ts';

export const getBlockByTargetId = async (
  content: ContentState,
  targetId: string,
  contentItemIdentifier: ContentItemId,
  rootRichTextElementId: Uuid,
): Promise<ContentBlock | null> => {
  const blocks = getBlocks(content);
  const hierarchy = getBlockHierarchy(blocks);

  // We go through the array in reverse order because linked items may have duplicates in the RTE
  // and we are interested in the last matched element.
  for (let i = hierarchy.length - 2; i > 0; i--) {
    const currentBlock = hierarchy[i]?.block;

    if (currentBlock) {
      if (isContentComponent(currentBlock)) {
        const contentComponentId = getContentComponentId(currentBlock);
        if (contentComponentId) {
          const deliveryId = await getDeliveryContentComponentId(
            contentItemIdentifier.itemId,
            contentItemIdentifier.variantId,
            rootRichTextElementId,
            contentComponentId,
          );

          if (deliveryId === targetId) {
            return currentBlock;
          }
        }
      }

      if (isContentModule(currentBlock)) {
        const itemId = getModularContentItemId(currentBlock);

        if (itemId === targetId) {
          return currentBlock;
        }
      }
    }
  }

  return null;
};
