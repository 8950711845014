import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { calendarPageLeft } from '../calendarActions.ts';

interface IDeps {
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createLeaveCalendarAction = (deps: IDeps) => (): ThunkFunction => (dispatch) => {
  dispatch(calendarPageLeft());

  dispatch(
    deps.trackUserEventWithData(TrackedEvent.Calendar, { action: CalendarEventTypes.CalendarLeft }),
  );
};
