import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { roleNameChanged } from '../actions/rolesActions.ts';
import { UntitledRoleSurrogateName } from '../constants/UIConstants.ts';

type Props = {
  readonly readOnly: boolean;
};

export const RoleNameEditorElement = (props: Props) => {
  const dispatch = useDispatch();
  const name = useSelector((s) => s.rolesApp.editorUi.editedRole.name);

  return (
    <NameEditorElement
      autoFocus={!name}
      dataUiInputName={DataUiInput.EntityName}
      maxLength={ValidationConstants.RoleNameMaxLength}
      placeholder={UntitledRoleSurrogateName}
      value={name === UntitledRoleSurrogateName ? '' : name}
      onChange={(newName: string) => dispatch(roleNameChanged(newName))}
      readOnly={props.readOnly}
      title="Role name"
    />
  );
};
