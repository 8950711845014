import { Action } from '../../../../../@types/Action.type.ts';
import { ProjectListing_Init } from '../../../../projects/constants/projectActionTypes.ts';
import { Project_EditedProjectName_Updated } from '../../constants/generalSettingsActionTypes.ts';

const defaultState = '';

export const editedProjectName = (state: string = defaultState, action: Action): string => {
  switch (action.type) {
    case Project_EditedProjectName_Updated: {
      return action.payload.name;
    }

    case ProjectListing_Init: {
      return defaultState;
    }

    default:
      return state;
  }
};
