let htmlDocument: Document | null = null;

function ensureShadowDoc(): Document {
  htmlDocument ??= document.implementation.createHTMLDocument('foo');
  return htmlDocument;
}

export function createShadowElement(tagName: string): HTMLElement {
  const shadowDoc = ensureShadowDoc();
  if (shadowDoc) {
    return shadowDoc.createElement(tagName);
  }

  throw new Error('Unable to create shadow document element');
}

function makeAbsoluteUrlRelative(url: string): string {
  const currentRootUrl = `${window.location.protocol}//${window.location.host}/`;
  const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  if (url.startsWith(`${currentUrl}?`) || url.startsWith(`${currentUrl}#`)) {
    return url.substring(currentUrl.length);
  }
  if (url.startsWith(currentRootUrl)) {
    return `/${url.substring(currentRootUrl.length)}`;
  }
  return url;
}

function makeAbsoluteLinksRelative(document: Document): void {
  const linkElements = Array.from(document.getElementsByTagName('a'));
  linkElements.map((link) => {
    const href = link.getAttribute('href');
    if (href) {
      const updatedHref = makeAbsoluteUrlRelative(href);
      if (href !== updatedHref) {
        link.setAttribute('href', updatedHref);
      }
    }
  });
}

export const createGetNodesFromHtmlString =
  (getDocument: () => Document) =>
  (htmlDocString: string): Array<Node> => {
    const shadowDoc = getDocument();
    if (shadowDoc?.documentElement) {
      shadowDoc.documentElement.innerHTML = htmlDocString;

      // As shadow DOM lies outside of current page URL context, we need to simulate default browser behavior that converts absolute links to relative
      makeAbsoluteLinksRelative(shadowDoc);

      const bodyElem = shadowDoc.getElementsByTagName('body')[0];

      if (bodyElem) {
        return Array.from(bodyElem.childNodes);
      }
    }

    throw new Error(`Can’t parse HTML from the following string '${htmlDocString}'.`);
  };

export const getNodesFromHtmlString = createGetNodesFromHtmlString(ensureShadowDoc);
