import { InvariantException } from '@kontent-ai/errors';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';

export const hasChildItemAnyPublishingDependencies = (
  listingItem: IListingContentItem | null,
  contentType: ICompiledContentType | null,
  rootItemId: Uuid,
  canViewDefaultVariant: boolean,
): boolean => {
  if (listingItem && contentType && listingItem.item.typeId !== contentType.id) {
    throw InvariantException(
      `Content item ${listingItem.item.id} is of type ${listingItem.item.typeId}, but invalid content type ${contentType.id} was passed to this method`,
    );
  }

  const hasAnyChildrenExceptRootItem = !!listingItem?.variant?.modularItemIds.filter(
    (linkedItemId) => linkedItemId !== rootItemId,
  ).length;

  if (hasAnyChildrenExceptRootItem) {
    return true;
  }

  const isDefaultVariant = listingItem?.variant?.id.variantId === DefaultVariantId;
  return (
    !isDefaultVariant &&
    canViewDefaultVariant &&
    Boolean(contentType?.contentElements.some((element) => element.isNonLocalizable))
  );
};
