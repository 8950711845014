import { assert } from '@kontent-ai/utils';

type DecorableBase<TFunc extends (...args: any[]) => any> = (
  ...args: Parameters<TFunc>
) => ReturnType<TFunc>;

export type Decorator<TFunc extends (...args: any[]) => any> = (base: TFunc) => TFunc;

type DecoratorCall<TFunc extends (...args: any[]) => any> = (decorator: Decorator<TFunc>) => void;

type Decorate<TFunc extends (...args: any[]) => any> = {
  readonly decorate: DecoratorCall<(...args: Parameters<TFunc>) => ReturnType<TFunc>>;
};

const sealProperty = 'sealInternal';

type Sealable = {
  readonly [sealProperty]: () => void;
};

const isSealable = (x: unknown): x is Sealable => typeof (x as any)?.[sealProperty] === 'function';

export type DecorableFunction<TFunc extends (...args: any[]) => any> = DecorableBase<TFunc> &
  Decorate<TFunc>;

export const decorable = <TFunc extends (...args: any[]) => any>(
  initialImplementation: TFunc,
): DecorableFunction<TFunc> => {
  let implementation: TFunc = initialImplementation;
  let sealed = false;

  const result = (...args: Parameters<TFunc>) => {
    assert(
      sealed,
      () =>
        'Decorable function cannot be called before it is sealed, as there may still be some decorations pending. Call the function later in the life cycle.',
    );
    const baseCalled = false;
    const functionResult = implementation(...args);
    assert(
      !baseCalled,
      () =>
        'Decorable function cannot be called before it is sealed, as there may still be some decorations pending. Call the function later in the life cycle.',
    );
    return functionResult;
  };

  const decorate: DecoratorCall<TFunc> = (decorator: Decorator<TFunc>) => {
    assert(
      !sealed,
      () =>
        'Decorable function is already sealed and cannot be further decorated. Call the decorate earlier in the life cycle.',
    );
    implementation = decorator(implementation);
  };

  const sealable: DecorableFunction<TFunc> & Sealable = Object.assign(result, {
    decorate,
    [sealProperty]: () => {
      sealed = true;
    },
  });

  return sealable;
};

export type Decorable<TState extends ReadonlyRecord<string, unknown>> = {
  readonly [TKey in keyof TState]: TState[TKey] extends (...args: any[]) => any
    ? DecorableFunction<TState[TKey]>
    : TState[TKey];
};

export type DeepSealed<TPossibleDecorable> = TPossibleDecorable extends DecorableFunction<
  infer TFunc
>
  ? TFunc
  : TPossibleDecorable extends ReadonlyRecord<string, unknown>
    ? { readonly [P in keyof TPossibleDecorable]: DeepSealed<TPossibleDecorable[P]> }
    : TPossibleDecorable;

export const seal = <T extends AnyObject | DecorableFunction<(...args: any[]) => any>>(
  decorableValue: T,
): DeepSealed<T> => {
  if (decorableValue) {
    if (isSealable(decorableValue)) {
      decorableValue[sealProperty]();
    } else if (typeof decorableValue === 'object') {
      for (const key of Object.keys(decorableValue)) {
        if (Object.hasOwn(decorableValue, key)) {
          seal(decorableValue[key]);
        }
      }
    }
  }
  return decorableValue as DeepSealed<T>;
};
