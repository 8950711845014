import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextDefault,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { EmptyStateContent } from './components/EmptyStateContent.tsx';
import { EmptyStateContentGroup } from './components/EmptyStateContentGroup.tsx';
import { EmptyStateFooter } from './components/EmptyStateFooter.tsx';
import { EmptyStateImage } from './components/EmptyStateImage.tsx';
import { EmptyStateTitle } from './components/EmptyStateTitle.tsx';

const emptyStateSizes = ['fullOffset', 'full', 'shrink'] as const;

type EmptyStateSize = (typeof emptyStateSizes)[number];

type Props = {
  readonly dataUiElement?: DataUiElement;
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly size?: EmptyStateSize;
};

type StyledContainerProps = {
  readonly $isShrunk: boolean;
};

const StyledContainer = styled(Box)<StyledContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({ $isShrunk }) =>
    !$isShrunk &&
    css`
    height: 100%;
    min-height: 50vh;
  `};
`;

const EmptyStateComponent: React.FC<Props> = ({
  dataUiElement = DataUiElement.EmptyState,
  children,
  size = 'full',
}) => {
  const fullSizePaddingOffset = px(20 * gridUnit);

  return (
    <StyledContainer
      $isShrunk={size === 'shrink'}
      color={colorTextDefault}
      typography={Typography.BodyLarge}
      {...getDataUiElementAttribute(dataUiElement)}
    >
      <Stack
        align="center"
        spacing={Spacing.XL}
        css={`
          width: 100%;
          padding-bottom: ${size === 'fullOffset' ? fullSizePaddingOffset : ''}
        `}
      >
        {children}
      </Stack>
    </StyledContainer>
  );
};

EmptyStateComponent.displayName = 'EmptyState';

export const EmptyState = Object.assign(EmptyStateComponent, {
  Content: EmptyStateContent,
  ContentGroup: EmptyStateContentGroup,
  Footer: EmptyStateFooter,
  Image: EmptyStateImage,
  Title: EmptyStateTitle,
});
