import { feedbackModalSubmitted, modalDismissed } from '../../actions/sharedActions.ts';
import { dismissUserFeedback } from '../../actions/thunks/dismissUserFeedback.ts';
import { submitUserFeedback } from '../../actions/thunks/submitUserFeedback.ts';
import { UmuxFeedbackModal } from '../../components/UmuxFeedback/UmuxFeedbackModal.tsx';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { AiFeedbackTimestamps } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';

export const AiFeedbackDialog = () => {
  const dispatch = useDispatch();

  const feedbackTimestamps = useSelector((s) => s.sharedApp.userProperties.aiFeedbackTimestamps);

  const close = () => {
    dispatch(modalDismissed());
    dispatch(dismissUserFeedback(UserFeedbackSource.Ai, AiFeedbackTimestamps, feedbackTimestamps));
  };

  const submit = async (ease: string, requirements: string, comment: string): Promise<void> => {
    await dispatch(
      submitUserFeedback({
        source: UserFeedbackSource.Ai,
        propertyServerKey: AiFeedbackTimestamps,
        feedbackTimestamps,
        ease,
        requirements,
        comment,
      }),
    );
    dispatch(feedbackModalSubmitted());
  };

  return (
    <UmuxFeedbackModal
      dismissFeedbackButtonText="I changed my mind"
      easeTitle="The current AI feature improves the efficiency of my content creation process."
      headerContent="How helpful is AI when you create content?"
      onClose={close}
      onSubmit={submit}
      overAllOtherElements
      requirementsTitle="The AI feature meets my content creation needs."
      subtitle="Think about your experience with using the AI assistant in Kontent.ai."
    />
  );
};
