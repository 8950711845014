import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Content_LoadedContentItemTypes_TypesLoaded } from '../../../contentInventory/content/constants/contentActionTypes.ts';
import { ICompiledContentType } from '../../../contentInventory/content/models/CompiledContentType.ts';
import {
  ContentItemEditing_Init_FinishedNoVariant,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_Init_Started,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: Immutable.Map<Uuid, ICompiledContentType> = Immutable.Map();

// There are several components in the current content item editor implementation that trigger the loading of content types. Because
// of that, if a content type is used several times in the same editor, multiple requests will be sent to fetch it. This results in
// unnecessary re-renders of the editor (which worsens the performance when the number of components used in RTE is huge).
// The following condition is needed to prevent that from happening and only update the content type in Redux if it has been
// modified since the last fetch.
const shouldUpsertContentType = (
  state: Immutable.Map<Uuid, ICompiledContentType>,
  contentType: ICompiledContentType,
): boolean => {
  const contentTypeFromState = state.get(contentType.id);

  const hasContentTypeChanges =
    !!contentType?.lastModified &&
    !!contentTypeFromState?.lastModified &&
    Date.parse(contentType.lastModified) > Date.parse(contentTypeFromState.lastModified);

  return !contentTypeFromState || hasContentTypeChanges;
};

export function loadedContentItemTypes(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, ICompiledContentType> {
  switch (action.type) {
    case ContentItemEditing_Init_FinishedNoVariant:
    case ContentItemEditing_Init_Ready: {
      return state.set(
        action.payload.editedContentItem.editedContentItemTypeId,
        action.payload.editedContentItemType,
      );
    }

    case Content_LoadedContentItemTypes_TypesLoaded: {
      const { contentTypes } = action.payload;

      return contentTypes.reduce(
        (reducedState, contentType) =>
          shouldUpsertContentType(reducedState, contentType)
            ? reducedState.set(contentType.id, contentType)
            : reducedState,
        state,
      );
    }

    case ContentItemEditing_Init_Started: {
      return initialState;
    }

    default:
      return state;
  }
}
