import { ContentItemId } from '../models/ContentItemId.ts';
import { getEditedContentItemIdFromPath } from '../utils/routing/routeTransitionUtils.ts';
import { SignalRClient } from './signalR/signalRClient.ts';
import {
  ElementLockStatus,
  ISignalRConnectionCallbacks,
  Notifications,
  ServerMethod,
} from './signalR/signalRClient.type.ts';

let client: SignalRClient | null = null;

function connectToProjectHub(
  signalRClient: SignalRClient,
  getAccessToken: () => Promise<AuthToken>,
  isSignalRAlternativeTransportEnabled: boolean,
  projectId: Uuid,
  connectionCallbacks: ISignalRConnectionCallbacks,
): void {
  client = signalRClient;
  client?.connect(
    getAccessToken,
    isSignalRAlternativeTransportEnabled,
    projectId,
    connectionCallbacks,
  );
}

function disconnectFromProjectHub(): void {
  client?.disconnect();
  client = null;
}

function ensureClientStateForUser(
  editedContentItemId: ContentItemId | undefined,
  lockedElements: Notifications['lockedElementsChange']['payload']['lockedElementSessions'],
  currentUserId: UserId,
): void {
  if (editedContentItemId) {
    const lockedElement = lockedElements.find(
      (element) => element && element.userId === currentUserId,
    );
    client?.send<ServerMethod.EnsureClientState>({
      methodName: ServerMethod.EnsureClientState,
      payload: { ...editedContentItemId, elementId: lockedElement?.elementId ?? null },
    });
  }
}

function onItemEntered(itemVariantId: ContentItemId): void {
  client?.send<ServerMethod.OnItemEntered>({
    methodName: ServerMethod.OnItemEntered,
    payload: itemVariantId,
  });
}

function onItemLeft(itemVariantId: ContentItemId): void {
  client?.send<ServerMethod.OnItemLeft>({
    methodName: ServerMethod.OnItemLeft,
    payload: itemVariantId,
  });
}

async function requestElementLock(
  itemVariantId: ContentItemId,
  elementId: Uuid,
): Promise<ElementLockStatus | null> {
  return (
    (await client?.forceInvoke<ServerMethod.OnElementLockRequested>({
      methodName: ServerMethod.OnElementLockRequested,
      payload: { ...itemVariantId, elementId },
    })) ?? null
  );
}

function releaseElementLockForUser(
  lockedElements: Notifications['lockedElementsChange']['payload']['lockedElementSessions'],
  currentUserId: UserId,
): void {
  const currentContentItemId = getEditedContentItemIdFromPath(self.location.pathname);
  const lockedElement = lockedElements.find(
    (element) => element && element.userId === currentUserId,
  );

  if (currentContentItemId && lockedElement) {
    client?.send<ServerMethod.OnElementLockReleased>({
      methodName: ServerMethod.OnElementLockReleased,
      payload: { ...currentContentItemId, elementId: lockedElement.elementId },
    });
  }
}

export const projectNotificationService = {
  connectToProjectHub,
  disconnectFromProjectHub,
  ensureClientStateForUser,
  onItemEntered,
  onItemLeft,
  releaseElementLockForUser,
  requestElementLock,
};
