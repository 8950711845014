import React from 'react';
import { getDropDown } from '../../../_shared/components/DropDown.tsx';
import { DataUiCollection } from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  WebhookMessageFilterOption,
  WebhookMessageFilterOptionId,
  WebhookMessageFilterOptions,
} from '../constants/webhookMessageFilterOptions.ts';

const DropDown = getDropDown<WebhookMessageFilterOption>();

type Props = {
  readonly onSelectionChanged: (optionId: WebhookMessageFilterOptionId) => void;
  readonly selectedOptionId: string;
};

export const WebhookMessageListingFilter: React.FC<Props> = ({
  onSelectionChanged,
  selectedOptionId,
}) => {
  return (
    <div className="filter webhook-message-listing__filter">
      <div className="webhook-message-listing__dropdown-wrapper">
        <DropDown
          className="combo-box"
          onSelect={(selectedOption: WebhookMessageFilterOption) =>
            onSelectionChanged(selectedOption.id)
          }
          renderOptionName={(selectedOption: WebhookMessageFilterOption) => selectedOption?.name}
          getOptionId={(selectedOption: WebhookMessageFilterOption) => selectedOption.id}
          options={WebhookMessageFilterOptions}
          selectedOption={WebhookMessageFilterOptions.find(
            (option) => option.id === selectedOptionId,
          )}
          dataUiCollectionName={DataUiCollection.WebhookMessagesFilterOptions}
        />
      </div>
    </div>
  );
};

WebhookMessageListingFilter.displayName = 'WebhookMessageListingFilter';
