import { InvariantException } from '@kontent-ai/errors';

export enum QuantityOption {
  Minimum = 'minimum',
  Exactly = 'exactly',
  Maximum = 'maximum',
}

export const QuantityOptions: ReadonlyArray<QuantityOption> = [
  QuantityOption.Minimum,
  QuantityOption.Exactly,
  QuantityOption.Maximum,
];

export function getQuantityOptionName(value: QuantityOption): string {
  switch (value) {
    case QuantityOption.Minimum:
      return 'At least';
    case QuantityOption.Exactly:
      return 'Exactly';
    case QuantityOption.Maximum:
      return 'At most';
    default:
      throw InvariantException('Invalid quantityOption value');
  }
}
