import { Card } from '@kontent-ai/component-library/Card';
import { InputState } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';

type EntityWebhookGeneralSettingsProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookGeneralSettings = ({
  enabled,
  formProps,
}: EntityWebhookGeneralSettingsProps) => {
  const inputState = enabled ? InputState.Default : InputState.Disabled;

  return (
    <Card cardLabel="General" component="section">
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.FormSection)}>
        General
      </Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <ValidatedInput<IEntityWebhookFormShape>
            autoFocus={enabled}
            name="name"
            label="Webhook name"
            dataUiInputName={DataUiInput.EntityName}
            inputState={inputState}
            maxLength={200}
            formProps={formProps}
            placeholder="Provide a webhook name"
          />
          <ValidatedInput<IEntityWebhookFormShape>
            name="url"
            label="Webhook URL"
            dataUiInputName={DataUiInput.Url}
            inputState={inputState}
            maxLength={ValidationConstants.WebhookUrlMaxLength}
            formProps={formProps}
            placeholder="Enter an absolute URL address"
          />
        </Stack>
      </Card.Body>
    </Card>
  );
};

EntityWebhookGeneralSettings.displayName = 'EntityWebhookGeneralSettings';
