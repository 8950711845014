import { getRelevantCollections } from '../../../../_shared/selectors/getRelevantCollections.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { isAssetCollectionMandatoryForCurrentSubscriptionOrProject } from '../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { cannotCreateAssetsInCollection } from '../constants/uiConstants.ts';
import { getCollectionItems } from '../utils/getCollectionItems.ts';

export const getTheOnlyAvailableCollectionId = (s: IStore): Uuid | null => {
  const isAssetCollectionMandatory = isAssetCollectionMandatoryForCurrentSubscriptionOrProject(s);

  const availableCollections = getCollectionItems(
    getRelevantCollections(s, Capability.CreateAssets),
    !isAssetCollectionMandatory,
  );

  const disabledCollections = getCollectionItems(
    getRelevantCollections(s, Capability.ViewAssets),
    false,
  )
    .filter((item) => !availableCollections.some(({ id }) => item.id === id))
    .map((item) => ({ ...item, disabledTooltipText: cannotCreateAssetsInCollection }));

  const isOnlyOneCollectionAvailable =
    isAssetCollectionMandatory && availableCollections.length === 1 && !disabledCollections.length;

  return isOnlyOneCollectionAvailable ? (availableCollections[0]?.id ?? null) : null;
};
