import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentTypeSnippet_Creator_CreationFinished,
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_ChangeCodename,
  ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename,
  ContentTypeSnippet_Editor_ChangeName,
  ContentTypeSnippet_Editor_ChangeTypeElementCodename,
  ContentTypeSnippet_Editor_InitStarted,
  ContentTypeSnippet_Editor_MoveTypeElement,
  ContentTypeSnippet_Editor_MultipleChoiceCreateOption,
  ContentTypeSnippet_Editor_MultipleChoiceDeleteOption,
  ContentTypeSnippet_Editor_MultipleChoiceMoveOption,
  ContentTypeSnippet_Editor_MultipleChoiceUpdateOption,
  ContentTypeSnippet_Editor_RemoveTypeElement,
  ContentTypeSnippet_Editor_SavingFinished,
  ContentTypeSnippet_Editor_TypeElementCreated,
  ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd,
  ContentTypeSnippet_Editor_TypeElementUpdated,
} from '../../constants/snippetActionTypes.ts';

export function editedContentTypeSnippetIsModified(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentTypeSnippet_Editor_MultipleChoiceCreateOption:
    case ContentTypeSnippet_Editor_MultipleChoiceUpdateOption:
    case ContentTypeSnippet_Editor_MultipleChoiceDeleteOption:
    case ContentTypeSnippet_Editor_MultipleChoiceMoveOption:
    case ContentTypeSnippet_Editor_RemoveTypeElement:
    case ContentTypeSnippet_Editor_MoveTypeElement:
    case ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd:
    case ContentTypeSnippet_Editor_TypeElementCreated:
    case ContentTypeSnippet_Editor_TypeElementUpdated:
    case ContentTypeSnippet_Editor_ChangeCodename:
    case ContentTypeSnippet_Editor_ChangeTypeElementCodename:
    case ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename:
    case ContentTypeSnippet_Editor_ChangeName: {
      return true;
    }

    case ContentTypeSnippet_Creator_InitStarted:
    case ContentTypeSnippet_Creator_CreationFinished:
    case ContentTypeSnippet_Editor_InitStarted:
    case ContentTypeSnippet_Editor_SavingFinished: {
      return false;
    }

    default:
      return state;
  }
}
