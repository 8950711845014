import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_NameChanged,
} from '../../constants/newContentItemActionTypes.ts';

export function name(state = '', action: Action): string {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.name;

    case NewContentItem_NameChanged:
      return action.payload.name;

    default:
      return state;
  }
}
