import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { clearAuditLogFilter } from '../actions/auditLogFilterActions.ts';

export const AuditLogEmptyState: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <EmptyState>
      <EmptyState.Title>We couldn’t find any match</EmptyState.Title>
      <EmptyState.Content>
        Try rephrasing your search phrase or using less restrictive filters.
      </EmptyState.Content>
      <EmptyState.Footer>
        <Button buttonStyle="primary" onClick={() => dispatch(clearAuditLogFilter())}>
          Clear all filters
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};

AuditLogEmptyState.displayName = 'AuditLogEmptyState';
