import { areShallowEqual } from '@kontent-ai/utils';
import { RefCallback, RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export enum SliceFrom {
  Beginning = 'Beginning',
  End = 'End',
}

export enum SliceDirection {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

type VisibleItemId = number | string;
type VisibleItemsById = Map<VisibleItemId, HTMLElement>;

type IGetElementSize = (element: Element) => number;

const getElementWidth: IGetElementSize = (element) => element.scrollWidth;
const getElementHeight: IGetElementSize = (element) => element.scrollHeight;

export const sliceItems = <TItem>(
  allItems: ReadonlyArray<TItem>,
  lastVisibleIndex: number | null,
  sliceFrom: SliceFrom,
): [visibleItems: ReadonlyArray<TItem>, hiddenItems: ReadonlyArray<TItem>] => {
  if (lastVisibleIndex === null) {
    return [[], allItems];
  }

  if (sliceFrom === SliceFrom.Beginning) {
    return [
      allItems.slice(-1 * (lastVisibleIndex + 1)),
      allItems.slice(0, -1 * (lastVisibleIndex + 1)),
    ];
  }

  return [allItems.slice(0, lastVisibleIndex + 1), allItems.slice(lastVisibleIndex + 1)];
};

export function getLastVisibleItemIndex(
  availableSize: number,
  boundaries: ReadonlyArray<number>,
): number | null {
  const firstHiddenItemIndex = boundaries.findIndex((boundary) => boundary > availableSize);
  if (firstHiddenItemIndex === 0) {
    return null;
  }

  return firstHiddenItemIndex === -1 ? boundaries.length - 1 : firstHiddenItemIndex - 1;
}

const sortVisibleItems = (
  visibleItemsById: VisibleItemsById,
  direction: SliceFrom,
): ReadonlyArray<HTMLElement> => {
  const visibleItems = Array.from(visibleItemsById.values());
  return direction === SliceFrom.End ? visibleItems : visibleItems.reverse();
};

const getItemBoundaries = (
  visibleItemsById: VisibleItemsById,
  sliceFrom: SliceFrom,
  getElementSize: IGetElementSize,
): ReadonlyArray<number> => {
  const visibleItems = sortVisibleItems(visibleItemsById, sliceFrom);
  const itemsSizes = visibleItems.filter(Boolean).map((item) => getElementSize(item));

  return itemsSizes.reduce((boundaries, nextItemSize) => {
    const lastBoundary = boundaries[boundaries.length - 1] ?? 0;
    return [...boundaries, lastBoundary + nextItemSize];
  }, []);
};

export type AttachVisibleItemRefCallback = (itemId: VisibleItemId) => RefCallback<HTMLElement>;

interface OverflowingItemsInfo<TItem> {
  readonly attachVisibleItemRef: AttachVisibleItemRefCallback;
  readonly visibleItems: ReadonlyArray<TItem>;
  readonly hiddenItems: ReadonlyArray<TItem>;
}

const noItems: ReadonlyArray<any> = [];

export const useSliceOverflowingItems = <TItem>(
  allItems: ReadonlyArray<TItem>,
  containerRef: RefObject<HTMLElement>,
  sliceFrom: SliceFrom,
  direction: SliceDirection,
): OverflowingItemsInfo<TItem> => {
  // We always start by rendering all items to figure out all necessary dimensions for hiding items
  const [visibleItems, setVisibleItems] = useState<ReadonlyArray<TItem>>(allItems);
  const [hiddenItems, setHiddenItems] = useState<ReadonlyArray<TItem>>(noItems);

  const reset = useCallback(() => {
    setVisibleItems(allItems);
    setHiddenItems(noItems);
  }, [allItems]);

  useLayoutEffect(() => {
    reset();
  }, [reset]);

  const [availableContainerSize, setAvailableContainerSize] = useState(Number.MAX_VALUE);

  useResizeObserver({
    onResize: ({ width = Number.MAX_VALUE, height = Number.MAX_VALUE }) => {
      setAvailableContainerSize(direction === SliceDirection.Horizontal ? width : height);
      // Whenever container size changes, we reset the state to initial to recalculate
      reset();
    },
    ref: containerRef,
  });

  const visibleItemsRef = useRef<VisibleItemsById>(new Map());
  const getElementSize: IGetElementSize =
    direction === SliceDirection.Horizontal ? getElementWidth : getElementHeight;

  const update = useCallback(() => {
    const itemBoundaries = getItemBoundaries(visibleItemsRef.current, sliceFrom, getElementSize);
    const hideableContentSize = itemBoundaries[itemBoundaries.length - 1] ?? 0;
    const totalContentSize = Array.from(containerRef.current?.children ?? []).reduce(
      (totalSize, child) => {
        return totalSize + getElementSize(child);
      },
      0,
    );
    const nonHidingContentSize =
      totalContentSize - hideableContentSize < 0 ? 0 : totalContentSize - hideableContentSize;
    const boundariesWithNonHidingOffset = itemBoundaries.map((size) => size + nonHidingContentSize);
    const shouldSlice =
      availableContainerSize <
      (boundariesWithNonHidingOffset[boundariesWithNonHidingOffset.length - 1] ?? 0);

    if (shouldSlice) {
      const lastVisibleItemIndex = getLastVisibleItemIndex(
        availableContainerSize,
        boundariesWithNonHidingOffset,
      );
      const [newVisibleItems, newHiddenItems] = sliceItems(
        allItems,
        lastVisibleItemIndex,
        sliceFrom,
      );

      if (!areShallowEqual(visibleItems, newVisibleItems)) {
        setVisibleItems(newVisibleItems);
      }
      if (!areShallowEqual(hiddenItems, newHiddenItems)) {
        setHiddenItems(newHiddenItems);
      }
    }
  }, [
    allItems,
    containerRef,
    getElementSize,
    hiddenItems,
    sliceFrom,
    availableContainerSize,
    visibleItems,
  ]);

  useLayoutEffect(() => {
    update();
  }, [update]);

  const attachVisibleItemRef: AttachVisibleItemRefCallback = useCallback(
    (itemId) => (instance) => {
      if (instance instanceof HTMLElement) {
        visibleItemsRef.current.set(itemId, instance);
      } else {
        visibleItemsRef.current.delete(itemId);
      }
    },
    [],
  );

  return {
    attachVisibleItemRef,
    visibleItems,
    hiddenItems,
  };
};
