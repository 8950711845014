import { getHighestPlan } from '../../data/reducers/plans/selectors/planSelectors.ts';
import { Amplitude as AmplitudeComponent } from '../components/Amplitude.tsx';
import { useSelector } from '../hooks/useSelector.ts';

export const Amplitude = () => {
  const businessRole = useSelector((s) => s.sharedApp.userProperties.businessRole);
  const businessType = useSelector((s) => s.sharedApp.userProperties.businessType);
  const companyName = useSelector((s) => s.sharedApp.userProperties.companyName);
  const filledBusinessRole = useSelector((s) => s.sharedApp.userProperties.filledBusinessRole);
  const highestPlan = useSelector(
    (s) => getHighestPlan(s.data.plans.byId, s.data.subscriptions.byId) ?? undefined,
  );
  const userInfo = useSelector((s) => s.data.user.info);

  return (
    <AmplitudeComponent
      businessRole={businessRole}
      businessType={businessType}
      companyName={companyName}
      highestPlan={highestPlan}
      otherBusinessRole={filledBusinessRole}
      userInfo={userInfo}
    />
  );
};
