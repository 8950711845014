import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import {
  roleBuilderAnyCollectionOptionId,
  roleBuilderAnyLanguageOptionId,
} from '../constants/roleBuilder.ts';

export const filterOutAnyLanguageId = (
  userRoles: readonly IProjectContributorRole[],
): readonly IProjectContributorRole[] =>
  userRoles.map(
    (userRole: IProjectContributorRole): IProjectContributorRole => ({
      ...userRole,
      languageIds: userRole.languageIds.filter((id: Uuid) => id !== roleBuilderAnyLanguageOptionId),
    }),
  );

export const filterOutAnyCollectionId = (collectionIds: UuidArray): UuidArray =>
  collectionIds.filter((id) => id !== roleBuilderAnyCollectionOptionId);
