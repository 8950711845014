import Immutable from 'immutable';

export type RegexMatch = { matchedString: string; index: number; groups: Immutable.List<string> };

const getGlobalizedRegexCopy = (regex: RegExp): RegExp => {
  const flags = regex.flags.includes('g') ? regex.flags : regex.flags.concat('g');
  return new RegExp(regex, flags);
};

export const getAllMatches = (regex: RegExp, text: string): Immutable.List<RegexMatch> => {
  const result: RegexMatch[] = [];
  const globalizedRegex = getGlobalizedRegexCopy(regex);

  let match: RegExpExecArray | null = null;

  do {
    match = globalizedRegex.exec(text);

    if (match) {
      result.push({
        matchedString: match[0] ?? '',
        index: match.index,
        groups: Immutable.List(match.slice(1)),
      });
    }
  } while (match !== null);

  return Immutable.List(result);
};
