import React from 'react';
import { FormattedMessage } from '../../../../../../../_shared/components/FormattedMessage.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IFriendlyWarningOwnProps {
  readonly friendlyWarnings?: ReadonlyArray<string>;
  readonly hideWarning?: boolean;
}

interface IFriendlyWarningProps extends IFriendlyWarningOwnProps {
  readonly messageBarClassName: string;
  readonly messageClassName: string;
}

export class FriendlyWarning extends React.PureComponent<IFriendlyWarningProps> {
  static displayName = 'FriendlyWarning';

  render() {
    const { friendlyWarnings, hideWarning, messageBarClassName, messageClassName } = this.props;

    if (hideWarning || !friendlyWarnings || !friendlyWarnings.length) {
      return null;
    }

    return (
      <div
        className={messageBarClassName}
        {...getDataUiElementAttribute(DataUiElement.NotificationMessageWarning)}
      >
        {friendlyWarnings.map((friendlyWarning: string, index) => (
          <FormattedMessage
            key={`friendlyWarningMessage${index}`}
            className={messageClassName}
            messageWithFormattingTags={friendlyWarning}
            {...getDataUiElementAttribute(DataUiElement.ElementWarningText)}
          />
        ))}
      </div>
    );
  }
}
