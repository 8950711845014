import React, { memo } from 'react';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { AiStylesPlugin } from '../../plugins/ai/AiStylesPlugin.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { ExternalUpdatePlugin } from '../../plugins/behavior/ExternalUpdatePlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { ReadonlyPlugin } from '../../plugins/behavior/ReadonlyPlugin.tsx';
import { SpellCheckPlugin } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { EntityApiPlugin } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { DisplayInlineAiPlugin } from '../../plugins/inlineAi/DisplayInlineAiPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { TextApiPlugin } from '../../plugins/textApi/TextApiPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';

export const useComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    FocusPlugin,
    ApiLimitationsPlugin,
    KeyboardShortcutsPlugin,
    TextApiPlugin,
    ClipboardPlugin,
    StylesPlugin,
    AiStylesPlugin,
    EntityApiPlugin,
    DisplayInlineAiPlugin,
    SpellCheckPlugin,
    ExternalUpdatePlugin,
    ReadonlyPlugin,
  );

export type SimpleMultilineTextViewerProps = EditorProps<typeof useComposition>;

export const SimpleMultilineTextViewer: React.FC<SimpleMultilineTextViewerProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    ...props,
    dataUiInput: props.dataUiInput ?? DataUiInput.ItemTextElement,
    keyCommandMap: textKeyCommandMap,
    limitations: SimpleTextEditorLimitations,
  });
});

SimpleMultilineTextViewer.displayName = 'SimpleMultilineTextViewer';
