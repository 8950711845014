import { selectorContainsContentComponentId } from './itemElementWarningCheckers/utils/getValidationSelectorId.ts';

export const getIsHighlighted = (
  elementId: Uuid,
  highlightedItemElementIds: ReadonlySet<string>,
): boolean => {
  return !!highlightedItemElementIds && highlightedItemElementIds.has(elementId);
};

export const getIsFocused = (elementId: Uuid, firstIncompleteElementId: Uuid): boolean => {
  return !!firstIncompleteElementId && firstIncompleteElementId === elementId;
};

export const isAnyElementInContentComponentIncomplete = (
  contentComponentId: Uuid,
  highlightedItemElementIds: ReadonlySet<string>,
): boolean => {
  return (
    !!highlightedItemElementIds &&
    [...highlightedItemElementIds].some((highlightedItemElementId) =>
      selectorContainsContentComponentId(highlightedItemElementId, contentComponentId),
    )
  );
};
