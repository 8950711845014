import { template } from '../stringUtils.ts';

const buildErrorMessage = template`Provide a value shorter than ${0} characters.`;

export const isShorterThan = (value: string | null | undefined, maxLength: number): boolean =>
  !value || value.length <= maxLength;

export const isShorterThanValidationBuilder = (maxLength: number) => {
  const errorMessage = buildErrorMessage(maxLength);

  return (value: string) => (isShorterThan(value, maxLength) ? undefined : errorMessage);
};
