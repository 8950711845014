import {
  BaseColor,
  BorderRadius,
  Spacing,
  Typography,
  colorTextDefaultInverse,
  px,
  transition250,
} from '@kontent-ai/component-library/tokens';
import { overlayColors, rgba } from '@kontent-ai/component-library/utils';
import styled from 'styled-components';
import { SelectWrapper } from '../../../../../component-library/components/StatusBar/Selects/components/SelectWrapper.tsx';

export const StatusBarDropDown = styled.div`
  ${Typography.LabelLarge}
  width: 100%;
  padding: 0 ${px(Spacing.S)};
  color: ${colorTextDefaultInverse};
  background-color: ${BaseColor.Black};
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
  transition: border ${transition250};
  transition-property: background-color, background, border;
  appearance: none;
  user-select: none;
  
  &:hover {
    background-color: ${overlayColors(rgba(BaseColor.White, 0.2), BaseColor.Black)};
  }
`;

export const StatusBarDropdownTrigger = styled(StatusBarDropDown)`
  border-radius: ${px(BorderRadius.M)};
`;

export const StatusBarDropdownTriggerEllipsis = styled(StatusBarDropDown)`
  ${SelectWrapper}:first-child && {
    border-bottom-left-radius: ${px(BorderRadius.M)};
    border-top-left-radius: ${px(BorderRadius.M)};
    padding-left: ${px(Spacing.L)};
  }

  ${SelectWrapper}:last-child && {
    border-bottom-right-radius: ${px(BorderRadius.M)};
    border-top-right-radius: ${px(BorderRadius.M)};
    padding-right: ${px(Spacing.L)};
  }
`;
