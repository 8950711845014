import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';
import { ContentPaceBody } from '../components/ContentPaceBody.tsx';
import { getDemoContentPaceTimeSpentByWorkflowData } from '../constants/contentPaceDemoData.ts';
import { ContentPaceBodyContainerProps } from './ContentPaceBodyLiveContainer.tsx';

export const ContentPaceBodyDemoContainer: React.FC<ContentPaceBodyContainerProps> = ({
  timeInterval,
  contentTypeId,
  onChartItemClick,
}) => {
  const workflows = useSelector((s) => getWorkflowsSortedByName(s.data.workflows.byId));

  const timeSpentInWorkflowStepByWorkflow = getDemoContentPaceTimeSpentByWorkflowData(
    workflows,
    timeInterval,
    contentTypeId,
  );

  return (
    <ContentPaceBody
      timeSpentInWorkflowStepByWorkflow={timeSpentInWorkflowStepByWorkflow}
      workflows={workflows}
      onChartItemClick={onChartItemClick}
    />
  );
};

ContentPaceBodyDemoContainer.displayName = 'ContentPaceBodyDemoContainer';
