import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isContentItemVariantTranslated } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { ItemLinkLimitations } from '../../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  ContentLink as ContentLinkComponent,
  IContentLinkOwnProps,
} from '../components/ContentLink.tsx';

interface IContentLinkContainerProps extends IContentLinkOwnProps {
  readonly elementId?: Uuid;
  readonly text: string;
  readonly limitations?: ItemLinkLimitations;
}

export const ContentLink: React.FC<React.PropsWithChildren<IContentLinkContainerProps>> = ({
  text,
  children,
  elementId,
  ...props
}) => {
  // Ability to scroll to it by global item id (from usage)
  const alternativeScrollIds = useMemo(
    () => [CreateAutoScrollId.forContentItem(props.itemId)],
    [props.itemId],
  );
  // Entity key is not persistent, so we need to build other pseudo-unique ID from what we have
  const scrollId = elementId
    ? CreateAutoScrollId.forContentLink(elementId, props.itemId, text)
    : undefined;

  const contentItem = useSelector((state) => state.data.listingContentItems.byId.get(props.itemId));
  const isBrokenLink =
    !isContentItemVariantTranslated(contentItem) ||
    !isVariantPublished(contentItem?.variant ?? null);
  const areItemLinkTypesLimited =
    !!props.limitations && props.limitations.allowedItemLinkTypes.length > 0;
  const isLinkTypeAllowed =
    !areItemLinkTypesLimited ||
    props.limitations?.allowedItemLinkTypes.includes(contentItem?.item.typeId ?? '');
  const location = useLocation();

  return (
    <ContentLinkComponent
      alternativeScrollIds={alternativeScrollIds}
      currentPath={location.pathname}
      isBrokenLink={isBrokenLink}
      isInvalidTypeLink={!isLinkTypeAllowed}
      scrollId={scrollId}
      {...props}
    >
      {children}
    </ContentLinkComponent>
  );
};

ContentLink.displayName = 'ContentLinkContainer';
