import { Action } from '../../../../../@types/Action.type.ts';
import { ISubscriptionUserDetailFilter } from '../../../shared/stores/ISubscriptionStoreState.type.ts';
import {
  SubscriptionUserDetail_Filters_Cleared,
  SubscriptionUserDetail_ProjectFilter_Init,
  SubscriptionUserDetail_ProjectFilter_Updated,
  SubscriptionUserDetail_SearchPhraseFilter_Updated,
} from '../../constants/actionTypes.ts';

export const defaultState: ISubscriptionUserDetailFilter = {
  byProject: new Set(),
  searchPhrase: '',
};

export const filter = (
  state: ISubscriptionUserDetailFilter = defaultState,
  action: Action,
): ISubscriptionUserDetailFilter => {
  switch (action.type) {
    case SubscriptionUserDetail_ProjectFilter_Init:
    case SubscriptionUserDetail_Filters_Cleared:
      return defaultState;

    case SubscriptionUserDetail_ProjectFilter_Updated:
      return {
        ...state,
        byProject: action.payload.projectIds,
      };

    case SubscriptionUserDetail_SearchPhraseFilter_Updated:
      return {
        ...state,
        searchPhrase: action.payload.searchPhrase,
      };

    default:
      return state;
  }
};
