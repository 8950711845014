import { InvariantException } from '@kontent-ai/errors';
import { ISubscriptionDetailsServerModel } from '../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import {
  EmptyServiceAgreementSignature,
  IServiceAgreementSignature,
  createServiceAgreementSignatureDomainModel,
} from './ServiceAgreementSignature.ts';

export interface ISubscriptionDetails {
  readonly hadPrepaidPlan: boolean;
  readonly serviceAgreementSignature: IServiceAgreementSignature;
  readonly wasCreditCardNotificationSent: boolean;
  readonly allowedProjectLocationIds: ReadonlyArray<string>;
}

export const EmptySubscriptionDetails: ISubscriptionDetails = {
  hadPrepaidPlan: false,
  serviceAgreementSignature: EmptyServiceAgreementSignature,
  wasCreditCardNotificationSent: false,
  allowedProjectLocationIds: [],
};

export function createSubscriptionDetailsDomainModel(
  serverModel: Partial<ISubscriptionDetailsServerModel>,
): ISubscriptionDetails {
  if (typeof serverModel !== 'object') {
    throw InvariantException('Subscription details are not an object');
  }

  const serviceAgreementSignature = createServiceAgreementSignatureDomainModel(
    serverModel.serviceAgreementSignature,
  );

  return {
    hadPrepaidPlan: serverModel.hadPrepaidPlan || false,
    serviceAgreementSignature,
    wasCreditCardNotificationSent: serverModel.wasCreditCardNotificationSent || false,
    allowedProjectLocationIds: serverModel.allowedProjectLocationIds || [],
  };
}
