import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import { ProjectPropertyServerModel } from '../../../repositories/serverModels/ProjectPropertyServerModel.type.ts';
import { Shared_LoadProjectProperties_Finished } from '../../constants/sharedActionTypes.ts';

interface IDependencies {
  readonly projectRepository: IProjectRepository;
}

const finished = (properties: ReadonlyArray<ProjectPropertyServerModel>) =>
  ({
    type: Shared_LoadProjectProperties_Finished,
    payload: {
      properties,
    },
  }) as const;

export type LoadProjectPropertiesActionsType = ReturnType<typeof finished>;

export const createLoadProjectProperties =
  (deps: IDependencies) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const properties = await deps.projectRepository.getProperties(projectId, abortSignal);
    dispatch(finished(properties));
  };
