import { Action } from '../../../../../@types/Action.type.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { EmptyAsset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  AssetLibrary_AssetEditorDialog_InitFinished,
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitFinished,
  AssetLibrary_AssetEditor_InitStarted,
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_Asset_CodenameChanged,
  AssetLibrary_Asset_Edit,
  AssetLibrary_Asset_ReplaceFailed,
  AssetLibrary_Asset_ReplaceFinished,
  AssetLibrary_Asset_ReplaceStarted,
  AssetLibrary_Asset_TitleChanged,
  AssetLibrary_Assets_ReportUploadProgress,
  AssetLibrary_EditedAsset_DeletionFinished,
  AssetLibrary_EditedAsset_DeletionStarted,
  AssetLibrary_Folders_Opened,
} from '../../constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';

const defaultState = EmptyAsset;

export function editedAsset(state: IAsset = defaultState, action: Action): IAsset | null {
  switch (action.type) {
    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditorDialog_InitStarted:
    case AssetSelector_Opened:
    case AssetLibrary_Folders_Opened: {
      return defaultState;
    }

    case AssetLibrary_AssetEditor_InitFinished:
    case AssetLibrary_AssetEditorDialog_InitFinished:
    case AssetLibrary_Asset_Edit: {
      return action.payload.asset;
    }

    case AssetLibrary_Asset_CodenameChanged: {
      const codename = action.payload.codename;
      return {
        ...state,
        codename,
      };
    }

    case AssetLibrary_Asset_TitleChanged: {
      const title = action.payload.title;
      if (isEmptyOrWhitespace(title)) {
        return {
          ...state,
          title: defaultState.title,
        };
      }
      const titleWithEnsuredLength = title.substring(0, ValidationConstants.AssetTitleMaxLength);
      return {
        ...state,
        title: titleWithEnsuredLength,
      };
    }

    case AssetLibrary_EditedAsset_DeletionStarted: {
      return (
        state && {
          ...state,
          _isBeingDeleted: true,
        }
      );
    }

    case AssetLibrary_EditedAsset_DeletionFinished: {
      return (
        state && {
          ...state,
          _isBeingDeleted: false,
        }
      );
    }

    case AssetLibrary_Assets_ReportUploadProgress: {
      if (!state || state.id !== action.payload.assetId) {
        return state;
      }

      return {
        ...state,
        _uploaded: action.payload.percentUploaded,
        _sizeUploaded: action.payload.sizeUploaded,
      };
    }

    case AssetLibrary_Asset_ReplaceStarted: {
      if (!state || state.id !== action.payload.assetId) {
        return state;
      }

      return {
        ...state,
        _uploading: true,
        _failed: false,
        _uploaded: 0,
      };
    }

    case AssetLibrary_Asset_ReplaceFinished: {
      if (!state || state.id !== action.payload.oldAssetId) {
        return state;
      }

      return {
        ...state,
        ...action.payload.newAsset,
        _uploading: false,
        _failed: false,
        _uploaded: 100,
      };
    }

    case AssetLibrary_Asset_ReplaceFailed: {
      if (!state || state.id !== action.payload.oldAssetId) {
        return state;
      }

      return {
        ...state,
        _uploading: false,
        _failed: true,
      };
    }

    case AssetLibrary_AssetEditor_SaveFinished: {
      if (!state || state.id !== action.payload.asset.id) {
        return state;
      }

      return {
        ...state,
        eTag: action.payload.asset.eTag,
      };
    }

    default:
      return state;
  }
}
