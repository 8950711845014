import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { EnvironmentSettings } from '../../applications/environmentSettings/root/containers/EnvironmentSettings.tsx';
import { Home } from '../../applications/home/containers/Home.tsx';
import { MissionControlPage as EapMissionControlPage } from '../../applications/missionControl/components/MissionControlPage.tsx';
import { EnforceServiceAgreement } from '../../applications/subscriptionManagement/shared/containers/EnforceServiceAgreement.tsx';
import { MissionControlPage } from '../../applications/unifiedMissionControl/components/MissionControlPage.tsx';
import { RedirectHomeRoutesToMissionControl } from '../../applications/unifiedMissionControl/components/RedirectHomeRoutesToMissionControl.tsx';
import { ensureSelectedVariantForWebSpotlight } from '../../applications/webSpotlight/actions/thunkWebSpotlightActions.ts';
import { localizedRouteLeft } from '../../applications/webSpotlight/actions/webSpotlightActions.ts';
import { WebSpotlight } from '../../applications/webSpotlight/components/WebSpotlight.tsx';
import { EnsureWebSpotlight } from '../../applications/webSpotlight/containers/EnsureWebSpotlight.tsx';
import { AppNames } from '../constants/applicationNames.ts';
import {
  EnvironmentRouteParams,
  HomeRootRoute,
  WebSpotlightEntryRouteParams,
} from '../constants/routePaths.ts';
import { ShowAiFeedbackModalAutomatically } from '../containers/AiSurvey/ShowAiFeedbackModalAutomatically.tsx';
import { EnsureSelectedVariantIdInRoute } from '../containers/EnsureSelectedVariantIdInRoute.tsx';
import { ShowHelpUsImproveSurveyDialogAutomatically } from '../containers/HelpUsImproveSurvey/ShowHelpUsImproveSurveyDialogAutomatically.tsx';
import { TryEnsuringSelectedVariantId } from '../containers/TryEnsuringSelectedVariantId.tsx';
import { AuthorizedSection } from '../containers/routing/AuthorizedSection.tsx';
import { IRouteContext, RouteContext } from '../containers/routing/RouteContext.tsx';
import { getSelectedLanguageId } from '../selectors/getSelectedLanguageId.ts';
import { Capability, neverAllowed } from '../utils/permissions/capability.ts';
import { HtmlPageTitle, SpecialAppNames } from './HtmlPageTitle.tsx';
import { Loader } from './Loader.tsx';
import { useRedirectPropsWithSameSearch } from './routing/useRedirectPropsWithSameSearch.tsx';

const ContentInventory = lazy(
  () =>
    import(
      '../../applications/contentInventory/shared/containers/ContentInventory.tsx' /* webpackChunkName: "contentInventory" */
    ),
);
const ContentModels = lazy(
  () =>
    import(
      '../../applications/contentModels/shared/containers/ContentModels.tsx' /* webpackChunkName: "contentModels" */
    ),
);

export type ProjectRoutes = {
  readonly content: RouteConfig;
  readonly contentModels: RouteConfig;
  readonly environmentSettings: RouteConfig;
  readonly home: RouteConfig;
  readonly missionControl?: RouteConfig;
  readonly unifiedMissionControl?: RouteConfig;
  readonly webSpotlight?: RouteConfig;
};

type RouteConfig = {
  readonly path: string;
  readonly requiredCapabilities: ReadonlyArray<Capability>;
};

type Props = {
  readonly firstAccessibleRoute: string;
  readonly projectName: string;
  readonly routes: ProjectRoutes;
};

export const Project: React.FC<Props> = ({ firstAccessibleRoute, projectName, routes }) => {
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();

  return (
    <>
      <HtmlPageTitle appName={SpecialAppNames.Project} customName={projectName} />
      <EnforceServiceAgreement>
        <TryEnsuringSelectedVariantId>
          <Switch>
            {routes.unifiedMissionControl ? (
              <Route path={HomeRootRoute}>
                <RedirectHomeRoutesToMissionControl />
              </Route>
            ) : (
              <Route path={routes.home.path}>
                <AuthorizedSection
                  appName={AppNames.Home}
                  requiresOneOfCapabilities={routes.home.requiredCapabilities}
                >
                  <Home />
                </AuthorizedSection>
              </Route>
            )}
            {routes.unifiedMissionControl && (
              <Route path={routes.unifiedMissionControl.path}>
                <AuthorizedSection
                  appName={AppNames.MissionControl}
                  requiresOneOfCapabilities={routes.unifiedMissionControl.requiredCapabilities}
                >
                  <MissionControlPage />
                </AuthorizedSection>
              </Route>
            )}
            {!routes.unifiedMissionControl && routes.missionControl && (
              <Route path={routes.missionControl.path}>
                <AuthorizedSection
                  appName={AppNames.MissionControl}
                  requiresOneOfCapabilities={routes.missionControl.requiredCapabilities}
                >
                  <EapMissionControlPage />
                </AuthorizedSection>
              </Route>
            )}
            {routes.webSpotlight && (
              <Route path={routes.webSpotlight.path}>
                <RouteContext>
                  {(props: IRouteContext<WebSpotlightEntryRouteParams>) => (
                    <AuthorizedSection
                      appName={AppNames.WebSpotlight}
                      requiresOneOfCapabilities={
                        routes.webSpotlight?.requiredCapabilities ?? neverAllowed
                      }
                    >
                      <EnsureSelectedVariantIdInRoute
                        key={`${props.match.params.projectId}${props.match.params.variantId}`}
                        currentRouteVariantId={props.match.params.variantId}
                        ensureSelectedVariant={ensureSelectedVariantForWebSpotlight}
                        getSelectedLanguageIdFromStore={getSelectedLanguageId}
                        localizedRouteLeft={localizedRouteLeft}
                      >
                        <EnsureWebSpotlight>
                          <WebSpotlight />
                        </EnsureWebSpotlight>
                      </EnsureSelectedVariantIdInRoute>
                    </AuthorizedSection>
                  )}
                </RouteContext>
              </Route>
            )}
            <Route path={routes.content.path}>
              <AuthorizedSection
                appName={AppNames.Content}
                requiresOneOfCapabilities={routes.content.requiredCapabilities}
              >
                <Suspense fallback={<Loader />}>
                  <ContentInventory />
                </Suspense>
              </AuthorizedSection>
            </Route>
            <Route path={routes.contentModels.path}>
              <RouteContext>
                {({ match }: IRouteContext<EnvironmentRouteParams>) => (
                  <AuthorizedSection
                    appName={AppNames.ContentModels}
                    requiresOneOfCapabilities={routes.contentModels.requiredCapabilities}
                  >
                    <Suspense fallback={<Loader />}>
                      <ContentModels projectId={match.params.projectId} />
                    </Suspense>
                  </AuthorizedSection>
                )}
              </RouteContext>
            </Route>
            <Route path={routes.environmentSettings.path}>
              <AuthorizedSection
                appName={AppNames.EnvironmentSettings}
                requiresOneOfCapabilities={routes.environmentSettings.requiredCapabilities}
              >
                <EnvironmentSettings />
              </AuthorizedSection>
            </Route>
            <Route>
              <Redirect {...getRedirectPropsWithSameSearch({ to: firstAccessibleRoute })} />
            </Route>
          </Switch>
        </TryEnsuringSelectedVariantId>
        <ShowHelpUsImproveSurveyDialogAutomatically />
        <ShowAiFeedbackModalAutomatically />
      </EnforceServiceAgreement>
    </>
  );
};

Project.displayName = 'Project';
