import {
  Assertiveness,
  announce,
  clearAnnouncer,
  destroyAnnouncer,
} from '@react-aria/live-announcer';
import { useEffect } from 'react';

export const useLiveAnnouncer = (message: string, assertiveness: Assertiveness) => {
  useEffect(() => {
    announce(message, assertiveness);
    return () => clearAnnouncer(assertiveness);
  }, [message, assertiveness]);

  useEffect(() => {
    return destroyAnnouncer;
  }, []);
};
