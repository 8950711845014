import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { newContentItemVariantInitPrepared } from '../../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { getNewContentItemForm } from '../../selectors/newContentItemDialog.ts';

export const createPrepareNewContentItemVariantInitFromNewContentItemFormAction =
  () =>
  (): ThunkFunction =>
  (dispatch, getState): void => {
    const { workflowId } = getNewContentItemForm(getState());

    dispatch(
      newContentItemVariantInitPrepared({
        workflowId,
      }),
    );
  };
