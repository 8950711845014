import { assert } from '@kontent-ai/utils';
import { MiddlewareAPI } from '@reduxjs/toolkit';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { ICompiledContentType } from '../../../applications/contentInventory/content/models/CompiledContentType.ts';
import {
  isAssetTypeElement,
  isEditableElement,
  isRichTextTypeElement,
} from '../../../applications/contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import {
  changeAssetElementValue,
  changeRichStringElementValue,
} from '../../../applications/itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { replaceAssetInElementData } from '../../../applications/itemEditor/features/ContentItemEditing/utils/assetUtils.ts';
import { updateContentComponentElement } from '../../../applications/itemEditor/features/ContentItemEditing/utils/contentComponentUtils.ts';
import { areIdsEquivalent } from '../../../applications/itemEditor/features/ContentItemEditing/utils/itemEditingUtils.ts';
import { IAssetItemElement } from '../../../applications/itemEditor/models/contentItemElements/AssetItemElement.ts';
import { ICompiledContentItemElementData } from '../../../applications/itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IRichTextItemElement } from '../../../applications/itemEditor/models/contentItemElements/RichTextItemElement.ts';
import {
  isAssetElement,
  isRichTextElement,
} from '../../../applications/itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { createItemElementWithInitialValue } from '../../../applications/itemEditor/utils/itemElementCreator.ts';
import { baseEditorApi } from '../../../applications/richText/editorCore/api/baseEditorApi.ts';
import { bindApiMethods } from '../../../applications/richText/editorCore/hooks/bindApiMethods.ts';
import { PluginApi } from '../../../applications/richText/editorCore/types/Editor.api.type.ts';
import { UnlimitedEditorLimitations } from '../../../applications/richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { createEditorLimitationsApi } from '../../../applications/richText/plugins/apiLimitations/api/editorLimitationsApi.ts';
import { editorCustomBlocksApi } from '../../../applications/richText/plugins/customBlocks/api/editorCustomBlocksApi.ts';
import { editorDragDropApi } from '../../../applications/richText/plugins/dragDrop/api/editorDragDropApi.ts';
import { editorEntityApi } from '../../../applications/richText/plugins/entityApi/api/editorEntityApi.ts';
import { editorImageApi } from '../../../applications/richText/plugins/images/api/editorImageApi.ts';
import { editorCommandApi } from '../../../applications/richText/plugins/keyboardShortcuts/api/editorCommandApi.ts';
import { editorLinkApi } from '../../../applications/richText/plugins/links/api/editorLinkApi.ts';
import { editorTextApi } from '../../../applications/richText/plugins/textApi/api/editorTextApi.ts';
import { editorUndoRedoApi } from '../../../applications/richText/plugins/undoRedo/api/editorUndoRedoApi.ts';
import { UploadFilesPlugin } from '../../../applications/richText/plugins/uploadFiles/UploadFilesPlugin.tsx';
import { editorUploadFilesApi } from '../../../applications/richText/plugins/uploadFiles/api/editorUploadFilesApi.ts';
import { AssetUploadFinishedEventForGlobalState } from '../../utils/assets/AssetUploadFinishedEvent.ts';
import { IStore } from '../IStore.type.ts';

const API: PluginApi<UploadFilesPlugin> = bindApiMethods({
  ...baseEditorApi,
  ...createEditorLimitationsApi(UnlimitedEditorLimitations),
  ...editorCommandApi,
  ...editorTextApi,
  ...editorEntityApi,
  ...editorUndoRedoApi,
  ...editorCustomBlocksApi,
  ...editorDragDropApi,
  ...editorImageApi,
  ...editorLinkApi,
  ...editorUploadFilesApi,
});

function replaceAssetInRichTextElement(
  elementData: IRichTextItemElement,
  oldAssetId: Uuid,
  newAssetId: Uuid,
): IRichTextItemElement {
  return {
    ...elementData,
    _editorState: API.replaceAsset(elementData._editorState, oldAssetId, newAssetId),
  };
}

const replaceAssetInElement = (
  elementData: ICompiledContentItemElementData,
  oldAssetId: Uuid,
  newAssetId: Uuid,
): IRichTextItemElement | IAssetItemElement | ICompiledContentItemElementData => {
  if (isAssetElement(elementData)) {
    return replaceAssetInElementData(elementData, oldAssetId, newAssetId);
  }
  if (isRichTextElement(elementData)) {
    return replaceAssetInRichTextElement(elementData, oldAssetId, newAssetId);
  }
  return elementData;
};

function createDefaultContentComponentElement(
  loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
  contentComponentTypeId: string,
  elementId: string,
) {
  const type = loadedContentItemTypes.get(contentComponentTypeId);
  assert(type, () => `Content component type ID ${contentComponentTypeId} not found`);

  const typeElement = type.contentElements.find((element) => element.elementId === elementId);
  assert(
    isEditableElement(typeElement),
    () => `Editable type element ID ${elementId} not found in type ID ${contentComponentTypeId}`,
  );

  return createItemElementWithInitialValue(typeElement);
}

export const assetUploadMiddleware = ({ getState, dispatch }: MiddlewareAPI<Dispatch, IStore>) => {
  const onAssetUploadFinished = (event: AssetUploadFinishedEventForGlobalState) => {
    const state = getState();
    const {
      contentApp: { editedContentItem, editedContentItemVariant, loadedContentItemTypes },
    } = state;

    const { oldAssetId, newAssetId, element } = event.detail;

    if (!element) {
      return;
    }

    const { itemId, rootRichTextElementId, contentComponentId, elementId } = element;

    if (
      !newAssetId ||
      !itemId ||
      !editedContentItem ||
      !editedContentItemVariant ||
      !areIdsEquivalent(editedContentItemVariant.id, itemId)
    ) {
      return;
    }

    const topLevelElementId = rootRichTextElementId ?? elementId;
    const topLevelContentType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    if (!topLevelContentType) {
      return;
    }
    const topLevelTypeElement = topLevelContentType.contentElements.find(
      (elType) => elType.elementId === topLevelElementId,
    );

    if (isRichTextTypeElement(topLevelTypeElement)) {
      if (contentComponentId) {
        dispatch(
          changeRichStringElementValue(itemId, topLevelElementId, (elementData) =>
            updateContentComponentElement(
              elementData,
              contentComponentId,
              elementId,
              (componentElementData) =>
                replaceAssetInElement(componentElementData, oldAssetId, newAssetId),
              (contentComponentTypeId) =>
                createDefaultContentComponentElement(
                  loadedContentItemTypes,
                  contentComponentTypeId,
                  elementId,
                ),
            ),
          ),
        );
      } else {
        dispatch(
          changeRichStringElementValue(itemId, topLevelElementId, (elementData) =>
            replaceAssetInRichTextElement(elementData, oldAssetId, newAssetId),
          ),
        );
      }
    } else if (isAssetTypeElement(topLevelTypeElement)) {
      dispatch(
        changeAssetElementValue(itemId, topLevelTypeElement, (elementData) =>
          replaceAssetInElementData(elementData, oldAssetId, newAssetId),
        ),
      );
    }
  };

  self.addEventListener('assetUploadFinishedForGlobalState', onAssetUploadFinished);

  return (next: Dispatch) => next;
};
