import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { spacingCard } from '../../../tokens/decision/spacing.ts';
import { typographyCardHeadline } from '../../../tokens/decision/typography.ts';
import { QuinaryButton } from '../../Button/QuinaryButton/QuinaryButton.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { CardContext } from '../CardContext.tsx';
import { CardContainer } from './CardContainer.tsx';
import { CardOverline } from './CardOverline.tsx';

const CardCloseButtonContainer = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})`
  grid-area: close;
  
  ${CardContainer}:has(${CardOverline}) & {
    align-items: flex-start;
  }
`;

type Props = {
  readonly onClose: () => void;
};

export const CardCloseButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ onClose, ...otherProps }, forwardedRef) => {
    const { variant: variantFromCtx } = useContext(CardContext);

    const mappedVariantFromCtx = variantFromCtx === 'disabled' ? 'default' : variantFromCtx;

    return (
      <CardCloseButtonContainer
        height={typographyCardHeadline.lineHeight}
        paddingLeft={spacingCard}
      >
        <QuinaryButton
          buttonStyle={mappedVariantFromCtx}
          onClick={onClose}
          ref={forwardedRef}
          tooltipPlacement="left"
          tooltipText="Close"
          tooltipShortcuts="Esc"
          {...otherProps}
        >
          <QuinaryButton.Icon icon={Icons.ModalClose} screenReaderText="Close" />
        </QuinaryButton>
      </CardCloseButtonContainer>
    );
  },
);

CardCloseButton.displayName = 'CardCloseButton';
