import { Action } from '../../../../../@types/Action.type.ts';
import {
  Environment_RenameEnvironment_Failed,
  Environment_RenameEnvironment_Finished,
  Environment_RenameEnvironment_Started,
} from '../../../../environmentSettings/general/constants/generalSettingsActionTypes.ts';
import {
  Project_Delete_Failed,
  Project_Delete_Started,
} from '../../../../projects/constants/projectActionTypes.ts';
import {
  Environment_DeleteEnvironment_Failed,
  Environment_DeleteEnvironment_Finished,
  Environment_DeleteEnvironment_Started,
  Environment_SwapEnvironment_Failed,
  Environment_SwapEnvironment_Finished,
  Environment_SwapEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';
import { EnvironmentUiState } from '../../types/EnvironmentUiState.ts';

const initialState: ReadonlyMap<Uuid, EnvironmentUiState> = new Map();

export function environmentUiStates(
  state: ReadonlyMap<Uuid, EnvironmentUiState> = initialState,
  action: Action,
): ReadonlyMap<Uuid, EnvironmentUiState> {
  switch (action.type) {
    case Project_Delete_Started: {
      const newState = new Map<Uuid, EnvironmentUiState>(state);
      newState.set(action.payload.projectId, EnvironmentUiState.Deleting);
      return newState;
    }

    case Project_Delete_Failed: {
      const newState = new Map<Uuid, EnvironmentUiState>(state);
      newState.delete(action.payload.projectId);
      return newState;
    }

    case Environment_DeleteEnvironment_Started: {
      const newState = new Map<Uuid, EnvironmentUiState>(state);
      newState.set(action.payload.environmentId, EnvironmentUiState.Deleting);
      return newState;
    }

    case Environment_RenameEnvironment_Started: {
      const newState = new Map<Uuid, EnvironmentUiState>(state);
      newState.set(action.payload.environmentId, EnvironmentUiState.Renaming);
      return newState;
    }

    case Environment_SwapEnvironment_Started: {
      const newState = new Map<Uuid, EnvironmentUiState>(state);
      newState.set(action.payload.environmentId, EnvironmentUiState.Swapping);
      return newState;
    }

    case Environment_DeleteEnvironment_Finished:
    case Environment_DeleteEnvironment_Failed:
    case Environment_RenameEnvironment_Finished:
    case Environment_RenameEnvironment_Failed:
    case Environment_SwapEnvironment_Finished:
    case Environment_SwapEnvironment_Failed: {
      const newState = new Map<Uuid, EnvironmentUiState>(state);
      newState.delete(action.payload.environmentId);
      return newState;
    }

    default:
      return state;
  }
}
