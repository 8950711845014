import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IUserInfoServerModel } from '../../../../repositories/serverModels/UserServerModel.type.ts';
import {
  Data_UserInfo_Failed,
  Data_UserInfo_Started,
  Data_UserInfo_Success,
} from '../../../constants/dataActionTypes.ts';
import { ICurrentUserInfo } from '../../../models/user/CurrentUserInfo.ts';

interface IDeps {
  readonly userRepository: {
    readonly getUserInfo: (abortSignal?: AbortSignal) => Promise<IUserInfoServerModel>;
  };
}

const start = () => ({ type: Data_UserInfo_Started }) as const;
const success = (data: IUserInfoServerModel) =>
  ({
    type: Data_UserInfo_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_UserInfo_Failed }) as const;

export type LoadUserInfoActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadUserInfoAction =
  ({ userRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise<ICurrentUserInfo> =>
  async (dispatch, getState) => {
    dispatch(start());

    try {
      const userInfo = await userRepository.getUserInfo(abortSignal);
      dispatch(success(userInfo));
      return getState().data.user.info;
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
