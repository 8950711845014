import React, { RefObject } from 'react';
import { HorizontalTabs } from '../../../../component-library/components/StatusBar/HorizontalTabs/HorizontalTabs.tsx';
import {
  DataUiCollection,
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type HomeTabsStructure = {
  readonly id: string;
  readonly label: string;
  readonly path: string;
  readonly dataUiAttributes: ObjectWithDataAttribute;
};

type HomeTabsProps = {
  readonly containerRef: RefObject<HTMLElement>;
  readonly tabsStructure: readonly HomeTabsStructure[];
};

export const HomeTabs: React.FC<HomeTabsProps> = (props) => (
  <HorizontalTabs
    containerRef={props.containerRef}
    getItemProps={(item) => ({
      label: item.label,
      pathname: item.path,
      ...getDataUiElementAttribute(DataUiElement.NavigationTab),
      ...item.dataUiAttributes,
    })}
    items={props.tabsStructure}
    {...getDataUiCollectionAttribute(DataUiCollection.TabItems)}
  />
);

HomeTabs.displayName = 'HomeTabs';
