import { logError } from '../../../../../_shared/utils/logError.ts';
import { getListingContentItemFromJS } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingContentItemServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { isListingContentItemWithRequiredVariant } from '../../shared/utils/isListingContentItemWithRequiredVariant.ts';
import { ListingContentItemWithRequiredVariant } from '../../unchangedUnpublishedContent/types/UnchangedUnpublishedItem.type.ts';

export const createUnchangedPublishedItemFromServerModel = (
  model: IListingContentItemServerModel,
): ListingContentItemWithRequiredVariant | undefined => {
  const itemWithVariant = getListingContentItemFromJS(model);
  if (!isListingContentItemWithRequiredVariant(itemWithVariant)) {
    logError('Unchanged published item doesn’t have defined item variant.');
    return undefined;
  }

  return itemWithVariant;
};
