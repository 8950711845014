import { memoize } from '@kontent-ai/memoization';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js';
import Immutable from 'immutable';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import {
  getCharacterWithoutLineBreaksCount as getCharacterWithoutLineBreaksCountUtil,
  getCharacterWithoutWhitespacesCount as getCharacterWithoutWhitespacesCountUtil,
  getWordCount as getWordCountUtil,
} from '../../../../../_shared/utils/textUtils.ts';
import { ItemElementLimitType } from '../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/limitInfoMessages/ItemLimitStatusMessage.tsx';
import { getBlockKey } from '../../../utils/blocks/editorBlockGetters.ts';
import { isAtBlockEdge, isAtEntityEdge } from '../../../utils/blocks/editorBlockUtils.ts';
import {
  createSelection,
  isSelectionWithinOneBlock,
  moveCaretToSelectionEnd,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import { BaseTextLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  ConversionResult,
  evaluateDashConversion,
} from '../../customInputHandling/api/dashUtils.ts';
import {
  isFinishedAiInstruction,
  isNewAiInstruction,
} from '../../inlineAi/utils/InstructionEntity.ts';
import {
  getCommentStyleForNewContent,
  getVisualStyleForNewContent,
} from '../../inlineStyles/api/editorStyleUtils.ts';
import { isFinishedMention, isNewMention } from '../../mentions/api/MentionEntity.ts';

const getCount = memoize.weak(
  (content: ContentState, getFromBlock: (block: ContentBlock) => number): number => {
    const count = getBlocks(content).reduce(
      (total: number, block: ContentBlock) => total + getFromBlock(block),
      0,
    );
    return count;
  },
);

const getCharacterWithoutWhitespacesCountFromBlock = memoize.weak((block: ContentBlock): number =>
  getCharacterWithoutWhitespacesCountUtil(block.getText()),
);

export const getCharacterWithoutWhitespacesCount = (content: ContentState): number =>
  getCount(content, getCharacterWithoutWhitespacesCountFromBlock);

const getCharacterCountFromBlock = memoize.weak((block: ContentBlock): number =>
  getCharacterWithoutLineBreaksCountUtil(block.getText()),
);

export const getCharacterCount = (content: ContentState): number =>
  getCount(content, getCharacterCountFromBlock);

export function hasNonWhitespaceChar(content: ContentState): boolean {
  const plainText = content.getPlainText('');
  const regex = /\S/; // Any character except white space.
  return regex.test(plainText);
}

const getWordCountFromBlock = memoize.weak((block: ContentBlock): number =>
  getWordCountUtil(block.getText()),
);

export const getWordCount = (content: ContentState): number =>
  getCount(content, getWordCountFromBlock);

export type TextLimitStatus = {
  readonly isLimitValueMet: boolean;
  readonly limitType: ItemElementLimitType;
  readonly limitValue: number | null;
  readonly textSizeCurrentValue: number;
};

// There is only one actively changing editor at a time, so memoizing the last value is enough to optimize performance
const getTextLimitStatusResult = memoize.maxOne(
  (
    isLimitValueMet: boolean,
    limitType: ItemElementLimitType,
    limitValue: number | null,
    textSizeCurrentValue: number,
  ): TextLimitStatus => ({
    isLimitValueMet,
    limitValue,
    textSizeCurrentValue,
    limitType,
  }),
);

export const getTextSizeTooltip = memoize.weak((content: ContentState): string => {
  const wordCount = getWordCount(content);
  const charCount = getCharacterCount(content);
  const charCountWithoutSpaces = getCharacterWithoutWhitespacesCount(content);
  const textSizeTooltip = `${pluralizeWithCount('word', wordCount)}, ${pluralizeWithCount(
    'character',
    charCount,
  )}, ${pluralizeWithCount('character', charCountWithoutSpaces)} without spaces`;

  return textSizeTooltip;
});

export const getTextLimitStatus = memoize.weak(
  (limitations: BaseTextLimitations, content: ContentState): TextLimitStatus => {
    const maxWords = limitations.maxWords;
    const limitType =
      maxWords === null ? ItemElementLimitType.CharacterCount : ItemElementLimitType.WordCount;
    const limitValue = maxWords === null ? limitations.maxChars : limitations.maxWords;
    const textSizeCurrentValue =
      maxWords === null ? getCharacterCount(content) : getWordCount(content);

    const isLimitValueMet = limitValue === null || textSizeCurrentValue <= limitValue;

    return getTextLimitStatusResult(isLimitValueMet, limitType, limitValue, textSizeCurrentValue);
  },
);

const extendingEntityTypeGuards: ReadonlyArray<(entity: EntityInstance) => boolean> = [
  isNewMention,
  isNewAiInstruction,
];

export const isAtomicEntity = (entity: EntityInstance): boolean =>
  isFinishedMention(entity) || isFinishedAiInstruction(entity);

export function getEntityKeyForNewContent(
  content: ContentState,
  selection: SelectionState,
): string | null {
  if (!isSelectionWithinOneBlock(selection)) {
    return null;
  }

  const block = content.getBlockForKey(selection.getStartKey());
  if (!block) {
    return null;
  }

  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  // If all replaced chars have the same entity, keep the entity
  if (start < end) {
    const characterList = block.getCharacterList().slice(start, end).toArray();
    const commonEntityKey = characterList[0]?.getEntity() || null;

    // We don't preserve atomic entities, as they have fixed text (single character), and any attempt to update their text should replace them completely
    if (commonEntityKey && isAtomicEntity(content.getEntity(commonEntityKey))) {
      return null;
    }

    if (!characterList.every((ch: CharacterMetadata) => ch.getEntity() === commonEntityKey)) {
      return null;
    }
    return commonEntityKey;
  }

  // Preserve entity if we are inside it, discontinue when we are at the edge to allow writing around link without expanding it
  const isInsideEntity = !isAtBlockEdge(block, start) && !isAtEntityEdge(block, start);

  // Preserve entity when typing text at the end of the entity for extendable entities (such as when writing a new mention or a new AI instruction)
  const previousCharEntityKey = start ? block.getEntityAt(start - 1) : undefined;
  const previousCharEntity = previousCharEntityKey
    ? content.getEntity(previousCharEntityKey)
    : undefined;
  const isExtendingEntity =
    previousCharEntity && extendingEntityTypeGuards.some((guard) => guard(previousCharEntity));

  const preserveEntity = isExtendingEntity || isInsideEntity;

  return preserveEntity ? previousCharEntityKey || block.getEntityAt(end) || null : null;
}

// If content is updated, the resulting selection is over the updated text
export function updateText(
  input: IContentChangeInput,
  newText: string,
  forceVisualStyle?: Immutable.OrderedSet<string>,
): IContentChangeResult {
  const { content, selection } = input;

  if (!isSelectionWithinOneBlock(selection)) {
    return input;
  }

  const visualStyle = getVisualStyleForNewContent(content, selection, forceVisualStyle);
  const commentStyle = getCommentStyleForNewContent(content, selection);
  const style = visualStyle.union(commentStyle);

  const entityKey = getEntityKeyForNewContent(content, selection);

  const newEndOffset = selection.getStartOffset() + newText.length;
  const newSelection = createSelection(
    selection.getStartKey(),
    selection.getStartOffset(),
    selection.getEndKey(),
    newEndOffset,
  );

  const newContent = Modifier.replaceText(
    content,
    selection,
    newText,
    style,
    entityKey ?? undefined,
  );
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
}

export const applyDashShorthand = (input: IContentChangeInput): IContentChangeResult => {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const currentBlockKey = selection.getStartKey();
  const positionAfterShorthand = selection.getStartOffset();
  const currentBlock = content.getBlockForKey(currentBlockKey);

  const previousCharacters = currentBlock.getText().substr(0, positionAfterShorthand);
  const conversion = evaluateDashConversion(previousCharacters);
  if (conversion.result === ConversionResult.NoConversion) {
    return input;
  }

  const positionBeforeShorthand = positionAfterShorthand - conversion.convertedFrom.length;
  const selectionOverShorthand = createSelection(
    currentBlockKey,
    positionBeforeShorthand,
    currentBlockKey,
    positionAfterShorthand,
  );
  const textWithConvertedDash = updateText(
    {
      content: input.content,
      selection: selectionOverShorthand,
    },
    conversion.convertedTo,
  );

  const result = moveCaretToSelectionEnd(textWithConvertedDash);

  return result;
};

const getTextSelectionsInBlock = (
  block: ContentBlock,
  text: string,
): ReadonlyArray<SelectionState> => {
  if (!text) return [];

  const blockText = block.getText();
  const blockKey = getBlockKey(block);

  const selections: SelectionState[] = [];
  let lastTextEndIndex = 0;

  do {
    const textStartIndex = blockText.indexOf(text, lastTextEndIndex);
    if (textStartIndex < 0) break;

    lastTextEndIndex = textStartIndex + text.length;
    selections.push(createSelection(blockKey, textStartIndex, blockKey, lastTextEndIndex));
  } while (lastTextEndIndex < blockText.length);

  return selections;
};

export const getTextSelections = (
  content: ContentState,
  text: string,
): ReadonlyArray<SelectionState> => {
  if (!text) return [];

  const blocks = getBlocks(content);
  return blocks.flatMap((block) => getTextSelectionsInBlock(block, text));
};
