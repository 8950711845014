import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { isNewVersion } from '../../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

export const isDiscardNewVersionAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
): boolean => {
  const editedContentItemVariant = state.contentApp.editedContentItemVariant;

  return (
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DiscardNewVersion, state) &&
    !!editedContentItemVariant &&
    isNewVersion({
      workflowStatus: editedContentItemVariant.assignment.workflowStatus,
      publishingState: editedContentItemVariant.publishingState,
    })
  );
};
