import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IPreviewConfigurationEmptyStateContent {
  readonly title: string;
  readonly text: React.ReactNode;
  readonly callToActionText: string;
  readonly onCallToAction: () => void;
  readonly dataUiActionAttribute?: DataUiAction;
}

const Heading = styled.h2`
  color: ${colorTextDefault};
  ${Typography.HeadlineLarge};
`;

const Paragraph = styled.p`
  color: ${colorTextDefault};
  ${Typography.BodyLarge};
`;

export const PreviewConfigurationEmptyState: React.FC<IPreviewConfigurationEmptyStateContent> = ({
  title,
  text,
  callToActionText,
  onCallToAction,
  dataUiActionAttribute = DataUiAction.CreateFirst,
}) => {
  return (
    <Stack spacing={Spacing.XL} align="center">
      <Stack spacing={Spacing.S} align="center">
        <Heading>{title}</Heading>
        <Paragraph>{text}</Paragraph>
      </Stack>
      <Button
        onClick={onCallToAction}
        buttonStyle="primary"
        {...getDataUiActionAttribute(dataUiActionAttribute)}
      >
        {callToActionText}
      </Button>
    </Stack>
  );
};

PreviewConfigurationEmptyState.displayName = 'PreviewConfigurationEmptyState';
