import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import { buildUrlQueryString } from '../buildUrlQueryString.ts';

interface IQueryParams {
  redirect_uri: string;
  redirect_to_default: string;
}

type ParseQueryParamsResult<T> = {
  [P in keyof T]?: string;
};

export function parseQueryParams<T>(locationSearch: string): ParseQueryParamsResult<T> {
  if (!locationSearch || locationSearch.length <= 1) {
    return {};
  }
  return Object.fromEntries(
    locationSearch
      .substr(1)
      .split('&')
      .map((pair) => pair.split('='))
      .map((pair) =>
        pair[0] && pair[1] ? ([pair[0], decodeURIComponent(pair[1])] as const) : null,
      )
      .filter(notNull),
  ) as ParseQueryParamsResult<T>;
}

export function buildQueryString(params: Partial<IQueryParams>) {
  return buildUrlQueryString(params);
}

const getRedirectUriMemoized = memoize.allForever((locationSearch: string): string | undefined => {
  return parseQueryParams<{ redirect_uri: string }>(locationSearch).redirect_uri;
});

export const getRedirectUri = (location: Location) => getRedirectUriMemoized(location.search);

const shouldRedirectToDefaultMemoized = memoize.allForever((locationSearch: string): boolean => {
  return (
    parseQueryParams<{ redirect_to_default: string }>(locationSearch).redirect_to_default === 'true'
  );
});

export const shouldRedirectToDefault = (location: Location) =>
  shouldRedirectToDefaultMemoized(location.search);
