import {
  Home_LocalizedRoute_Entered,
  Home_LocalizedRoute_Left,
} from '../constants/homeActionTypes.ts';

export const localizedRouteLeft = () => ({ type: Home_LocalizedRoute_Left }) as const;

export const localizedRouteEntered = (selectedLanguageId: Uuid) =>
  ({
    type: Home_LocalizedRoute_Entered,
    payload: {
      selectedLanguageId,
    },
  }) as const;
