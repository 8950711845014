import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICommentThread } from '../../../itemEditor/models/comments/CommentThreads.ts';
import { ContentStatusCommentsTableRow } from '../containers/ContentStatusCommentsTableRow.tsx';

type ContentStatusCommentsTableProps = Readonly<{
  commentThreads: ReadonlyArray<ICommentThread>;
  isLoading: boolean;
  itemId: Uuid;
  variantId: Uuid;
}>;

export const ContentStatusCommentsTable: React.FC<ContentStatusCommentsTableProps> = ({
  commentThreads,
  isLoading,
  itemId,
  variantId,
}) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <DataTable
      dataUiCollectionName={DataUiCollection.CommentThreads}
      header={<DataTableHeadRow columns={commentsTableHeadColumns} />}
    >
      {commentThreads.map((thread) => (
        <ContentStatusCommentsTableRow
          key={thread.id}
          commentThread={thread}
          itemId={itemId}
          variantId={variantId}
        />
      ))}
    </DataTable>
  );
};

ContentStatusCommentsTable.displayName = 'ContentStatusCommentsTable';

const commentsTableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Comment',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-13',
  },
  {
    columnName: 'Replies',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
  {
    columnName: 'Created',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-3',
  },
  {
    columnName: 'Author',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--growing-2',
  },
]);
