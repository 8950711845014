import { px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { modalDismissed } from '../../actions/sharedActions.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { compose } from '../../utils/func/compose.ts';

export const ThanksForFeedbackModal: React.FC = () => {
  const dispatch = useDispatch();
  const onDismiss = compose(dispatch, modalDismissed);

  return (
    <ModalDialog
      headline="Thank you for your feedback!"
      primaryAction={{
        text: 'Close',
        onClick: onDismiss,
      }}
      onClose={onDismiss}
      isOpen
      isDismissable={false}
      minWidth={px(760)}
    >
      <p>We appreciate you took the time to help us improve Kontent.ai</p>
    </ModalDialog>
  );
};

ThanksForFeedbackModal.displayName = 'ThanksForFeedbackModal';
