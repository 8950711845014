import { combineReducers } from '@reduxjs/toolkit';
import { collection } from './formConfig/collection.ts';
import { contentType } from './formConfig/contentType.ts';
import { isLoaded } from './formConfig/isLoaded.ts';

export const formConfig = combineReducers({
  collection,
  contentType,
  isLoaded,
});
