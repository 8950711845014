import { AnchorButton } from '@kontent-ai/component-library/Button';
import { BorderRadius, Spacing, ZIndex, gridUnit, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const mainContentId = 'mainContent';

const SkipToMainContentWrapper = styled.div`
    position: absolute;
    top: ${px(-gridUnit * 100)};
    left: 0;
    z-index: ${ZIndex.OneHundred};

    &:focus-within {
      top: ${px(Spacing.XXXL)};
      left: ${px(Spacing.M)};
    }
  `;

const StyledAnchorButton = styled(AnchorButton)`
    padding: ${px(Spacing.S)} ${px(Spacing.M)};
    z-index: ${ZIndex.OneHundred};

    &:focus-visible {
      border-radius: ${px(BorderRadius.Pill)};
      outline: none;
    }
  `;

export const SkipToMainContent = () => (
  <SkipToMainContentWrapper>
    <StyledAnchorButton buttonStyle="primary" size="small" href={`#${mainContentId}`}>
      Skip to main content
    </StyledAnchorButton>
  </SkipToMainContentWrapper>
);
