import { Action } from '../../../../@types/Action.type.ts';
import {
  SubscriptionUserDetail_ResendInviteToProjects_Failed,
  SubscriptionUserDetail_ResendInviteToProjects_Finished,
  SubscriptionUserDetail_ResendInviteToProjects_Started,
  SubscriptionUserDetail_RevokeInviteToProjects_Failed,
  SubscriptionUserDetail_RevokeInviteToProjects_Started,
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed,
  SubscriptionUserDetail_ToggleActiveStateOnProjects_Started,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Started,
  SubscriptionUsers_UsersUsage_Loaded,
} from '../../../../applications/subscriptionManagement/SubscriptionUsers/constants/actionTypes.ts';
import { ISubscriptionUserWithSettings } from '../../../../applications/subscriptionManagement/SubscriptionUsers/models/SubscriptionUserWithSettings.ts';
import { ISubscriptionUsersUsage } from '../../../../applications/subscriptionManagement/SubscriptionUsers/models/SubscriptionUsersUsage.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SelectSubscription_Clear,
} from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';

const setUpSubscriptionUserActivatingStatus = (
  subscriptionUser: ISubscriptionUserWithSettings,
  userId: UserId,
  isActivating: boolean,
  isDeactivating: boolean,
): ISubscriptionUserWithSettings => {
  if (subscriptionUser.userId === userId) {
    return {
      ...subscriptionUser,
      isActivating,
      isDeactivating,
    };
  }
  return subscriptionUser;
};

const setUpUserProjectSettingsActivatingStatus = (
  subscriptionUser: ISubscriptionUserWithSettings,
  userId: UserId,
  projectIds: ReadonlyArray<Uuid>,
  isActivating: boolean,
  isDeactivating: boolean,
): ISubscriptionUserWithSettings => {
  if (subscriptionUser.userId === userId) {
    return {
      ...subscriptionUser,
      userProjectsSettings: subscriptionUser.userProjectsSettings.map((userProjectSettings) => {
        if (projectIds.some((projectId) => userProjectSettings.projectId === projectId)) {
          return {
            ...userProjectSettings,
            isActivating,
            isDeactivating,
          };
        }
        return userProjectSettings;
      }),
    };
  }

  return subscriptionUser;
};

const setUpUserInviteStatus = (
  subscriptionUser: ISubscriptionUserWithSettings,
  userId: UserId,
  projectIds: ReadonlyArray<Uuid>,
  isInviteRevoking: boolean,
  isInviteSending: boolean,
  isInviteSent: boolean,
): ISubscriptionUserWithSettings => {
  if (subscriptionUser.userId === userId) {
    return {
      ...subscriptionUser,
      userProjectsSettings: subscriptionUser.userProjectsSettings.map((userProjectSettings) => {
        if (projectIds.some((projectId) => userProjectSettings.projectId === projectId)) {
          return {
            ...userProjectSettings,
            isInviteRevoking,
            isInviteSending,
            isInviteSent,
          };
        }
        return userProjectSettings;
      }),
    };
  }
  return subscriptionUser;
};

export const subscriptionUsersUsage = (
  state: ISubscriptionUsersUsage | null = null,
  action: Action,
): ISubscriptionUsersUsage | null => {
  switch (action.type) {
    case SubscriptionUsers_UsersUsage_Loaded:
      return action.payload.data;

    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Started: {
      const { userId, subscriptionId, activate } = action.payload;

      if (!state || state.subscriptionId !== subscriptionId) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpSubscriptionUserActivatingStatus(subscriptionUser, userId, activate, !activate),
        ),
      };
    }

    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed: {
      const { userId, subscriptionId } = action.payload;

      if (!state || state.subscriptionId !== subscriptionId) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpSubscriptionUserActivatingStatus(subscriptionUser, userId, false, false),
        ),
      };
    }

    case SubscriptionUserDetail_ToggleActiveStateOnProjects_Started: {
      const { userId, projectIds, activate } = action.payload;

      if (!state) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpUserProjectSettingsActivatingStatus(
            subscriptionUser,
            userId,
            projectIds,
            activate,
            !activate,
          ),
        ),
      };
    }

    case SubscriptionUserDetail_ToggleActiveStateOnProjects_Failed: {
      const { userId, projectIds } = action.payload;

      if (!state) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpUserProjectSettingsActivatingStatus(
            subscriptionUser,
            userId,
            projectIds,
            false,
            false,
          ),
        ),
      };
    }

    case SubscriptionUserDetail_ResendInviteToProjects_Started: {
      const { userId, projectIds } = action.payload;

      if (!state) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpUserInviteStatus(subscriptionUser, userId, projectIds, false, true, false),
        ),
      };
    }

    case SubscriptionUserDetail_ResendInviteToProjects_Finished: {
      const { userId, projectIds } = action.payload;

      if (!state) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpUserInviteStatus(subscriptionUser, userId, projectIds, false, false, true),
        ),
      };
    }

    case SubscriptionUserDetail_RevokeInviteToProjects_Started: {
      const { userId, projectIds } = action.payload;

      if (!state) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpUserInviteStatus(subscriptionUser, userId, projectIds, true, false, false),
        ),
      };
    }

    case SubscriptionUserDetail_ResendInviteToProjects_Failed:
    case SubscriptionUserDetail_RevokeInviteToProjects_Failed: {
      const { userId, projectIds } = action.payload;

      if (!state) {
        return state;
      }

      return {
        ...state,
        subscriptionUsers: state.subscriptionUsers.map((subscriptionUser) =>
          setUpUserInviteStatus(subscriptionUser, userId, projectIds, false, false, false),
        ),
      };
    }

    case Subscription_SelectSubscription_Clear:
    case Subscription_SelectSubscription:
      return null;

    default:
      return state;
  }
};
