import { useCallback } from 'react';
import { DefaultValueStatus } from '../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/limitInfoMessages/DefaultValueStatus.tsx';
import { hasDefaultValue } from '../../../../itemEditor/features/ContentItemEditing/utils/defaultValueUtils.ts';
import { ITextWarningResult } from '../../../../itemEditor/utils/itemElementWarningCheckers/types/ITextItemElementWarningResult.type.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { TextLimitations } from '../../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { StylesPlugin } from '../../../plugins/visuals/StylesPlugin.tsx';
import { TextElementStatus } from './components/TextElementStatus.tsx';

type SimpleMultilineTextInputStatusPluginProps = {
  readonly defaultValue?: string;
  readonly errorMessages: ReadonlyArray<string>;
  readonly elementLimitations: TextLimitations;
  readonly validationResult: ITextWarningResult;
};

export type SimpleMultilineTextInputStatusPlugin = EditorPlugin<
  None,
  SimpleMultilineTextInputStatusPluginProps,
  None,
  None,
  [StylesPlugin]
>;

export const SimpleMultilineTextInputStatusPlugin: PluginComponent<
  SimpleMultilineTextInputStatusPlugin
> = (props) => {
  const { validationResult, errorMessages, defaultValue, elementLimitations, disabled } = props;

  const renderStatus: Decorator<Render<SimpleMultilineTextInputStatusPlugin>> = useCallback(
    (baseRenderStatus) => (state) => {
      const { editorState } = state;

      const content = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const hasFocus = selection.getHasFocus();

      const isCurrentValueDefault = !!defaultValue && content.getPlainText() === defaultValue;

      return (
        <>
          {baseRenderStatus(state)}
          <DefaultValueStatus
            isStatusRendered={hasDefaultValue(defaultValue) && !disabled}
            isValueDefault={isCurrentValueDefault}
          />
          <TextElementStatus
            content={content}
            errorMessages={errorMessages}
            hasFocus={hasFocus}
            limitations={elementLimitations}
            validationResult={validationResult}
          />
        </>
      );
    },
    [elementLimitations, validationResult, errorMessages, defaultValue, disabled],
  );

  const apply: Apply<SimpleMultilineTextInputStatusPlugin> = useCallback(
    (state) => {
      state.renderStatus.decorate(renderStatus);
      return {};
    },
    [renderStatus],
  );

  return useEditorWithPlugin(props, { apply });
};
