import { EditorState } from 'draft-js';
import { ICompiledContentItemElementData } from '../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IRichTextItemElement } from '../../../../itemEditor/models/contentItemElements/RichTextItemElement.ts';
import { isRichTextElement } from '../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getItemElementDomainModelConverter } from '../../../../itemEditor/utils/getItemElementDataConverters.ts';
import { importRichTextFromHtml } from '../../../../richText/utils/import/importRichTextFromHtml.ts';
import { createRichTextContentDomainModel } from '../../../../richText/utils/serverModel/editorServerModelUtils.ts';

export function ensureEditorStateInRichTextElements(
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): ReadonlyArray<ICompiledContentItemElementData> {
  const preparedElements = elements.map((element) => {
    return isRichTextElement(element) ? ensureEditorState(element) : element;
  });
  return preparedElements;
}

function ensureEditorState(element: IRichTextItemElement): IRichTextItemElement {
  if (element._editorState) {
    return element;
  }

  const content = createRichTextContentDomainModel(
    {
      value: element.value,
      jsonValue: element.jsonValue,
    },
    {
      importFromValue: importRichTextFromHtml,
      contentComponentConversionDependencies: {
        getItemElementDomainModelConverter,
        dataDependencies: {},
      },
    },
  );

  return {
    ...element,
    _editorState: EditorState.createWithContent(content),
  };
}
