import React from 'react';
import { connect } from 'react-redux';
import {
  IUiBlockingMessageDataProps,
  UiBlockingMessage as UiBlockingMessageComponent,
} from '../components/UiBlockingMessage.tsx';
import { IStore } from '../stores/IStore.type.ts';

function mapStateToProps(state: IStore): IUiBlockingMessageDataProps {
  return {
    uiBlockingMessage: state.sharedApp.uiBlockingMessage,
  };
}

export const UiBlockingMessage: React.ComponentType = connect(mapStateToProps)(
  UiBlockingMessageComponent,
);
