import { Collection } from '@kontent-ai/utils';
import { AssetLibraryFilterStorageKey } from '../_shared/constants/localStorageKeys.ts';
import {
  IAssetLibraryQuery,
  emptyAssetLibraryQuery,
} from '../applications/contentInventory/assets/models/IAssetLibraryQuery.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

export type AssetLibraryQueryWithoutMimeType = Omit<IAssetLibraryQuery, 'mimeType'>;

export type IAssetLibraryQueryStorage = {
  readonly mimeType?: never; // for now, we do not want to load mimeType from local storage even if it set
  readonly searchPhrase?: string;
  readonly selectedTaxonomyNodes?: Record<Uuid, ReadonlyArray<Uuid>>;
  readonly selectedCollections?: ReadonlyArray<Uuid | null>;
};

export const emptyAssetLibraryQueryStorage: AssetLibraryQueryWithoutMimeType =
  emptyAssetLibraryQuery;

export const getAssetQueryFromJS = (
  rawQuery: IAssetLibraryQueryStorage,
): AssetLibraryQueryWithoutMimeType => {
  const rawTaxonomyNodes = rawQuery.selectedTaxonomyNodes ?? {};

  return {
    searchPhrase: rawQuery.searchPhrase ?? '',
    selectedTaxonomyNodes: new Map(
      Object.entries(rawTaxonomyNodes).map(([groupId, termIds]) => [groupId, new Set(termIds)]),
    ),
    selectedCollections: rawQuery.selectedCollections ?? [],
  };
};

/** Cannot use JSON.stringify() on ES6 Map or Set - transform query to records and arrays. */
export const serializeAssetQueryToJS = (
  query: AssetLibraryQueryWithoutMimeType,
): IAssetLibraryQueryStorage => ({
  ...query,
  selectedTaxonomyNodes: Object.fromEntries(
    Collection.getEntries(query.selectedTaxonomyNodes).map(([groupId, termIds]) => [
      groupId,
      Collection.getValues(termIds),
    ]),
  ),
});

const assetStorageParams: StorageParams<AssetLibraryQueryWithoutMimeType> = {
  key: AssetLibraryFilterStorageKey,
  initialState: emptyAssetLibraryQueryStorage,
  fromJS: getAssetQueryFromJS,
  toJS: serializeAssetQueryToJS,
};

export const AssetLibraryQueryStorage =
  getProjectSpecificStorage<AssetLibraryQueryWithoutMimeType>(assetStorageParams);
