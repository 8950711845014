import { assert } from '@kontent-ai/utils';
import { ContentState, RawDraftContentState } from 'draft-js';
import { ICommentServerModel } from '../../../../repositories/serverModels/CommentThreadServerModel.type.ts';
import { emptyContentState } from '../../../richText/utils/general/editorEmptyValues.ts';
import {
  RichTextServerModel,
  convertServerModelToRawContent,
} from '../../../richText/utils/serverModel/editorServerModel.ts';
import {
  convertContentToServerModel,
  createRichTextContentDomainModelFromRaw,
} from '../../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemBaseContentModel,
  ICommentThreadItemContentModel,
} from './CommentThreadItem.ts';
import { ISuggestion, ISuggestionContentModel } from './Suggestion.ts';

export interface IComment extends ICommentThreadItem {
  readonly content: ContentState;
}

export interface ICommentContentModel extends ICommentThreadItemBaseContentModel {
  readonly content: ContentState;
}

export const emptyComment: IComment = {
  id: '',
  type: CommentThreadItemType.Comment,
  content: emptyContentState,
  createdAt: '',
  updatedAt: '',
  createdBy: '',
  isSubmitting: false,
  isEditing: false,
};

export function isComment(arg: ISuggestion | IComment | ICommentThreadItem): arg is IComment {
  return arg.type === CommentThreadItemType.Comment;
}

export function isCommentContent(
  arg: ICommentThreadItemContentModel | ICommentContentModel | ISuggestionContentModel,
): arg is ICommentContentModel {
  return arg.type === CommentThreadItemType.Comment;
}

export function createCommentContentServerModel(content: ContentState): RichTextServerModel {
  return convertContentToServerModel(content);
}

export function createCommentContentDomainModel(
  content: RawDraftContentState | undefined,
  text: string,
): ContentState {
  return content
    ? createRichTextContentDomainModelFromRaw(content)
    : ContentState.createFromText(text);
}

export function createCommentDomainModel(rawComment: ICommentServerModel): IComment {
  assert(!!rawComment, () => 'Comment server model is falsy.');

  return {
    ...emptyComment,
    id: rawComment._id ?? emptyComment.id,
    content: createCommentContentDomainModel(
      convertServerModelToRawContent(rawComment.content),
      rawComment.text,
    ),
    createdAt: rawComment.createdAt ?? emptyComment.createdAt,
    updatedAt: rawComment.updatedAt ?? emptyComment.updatedAt,
    createdBy: rawComment.createdBy ?? emptyComment.createdBy,
  };
}
