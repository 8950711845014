import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_SelectSubscription_Clear } from '../../shared/constants/subscriptionManagementActionTypes.ts';
import {
  Subscription_PromoteAdmin_Failed,
  Subscription_PromoteAdmin_Finished,
  Subscription_PromoteAdmin_Started,
} from '../constants/actionTypes.ts';

export const promotingSubscriptionAdmins = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_PromoteAdmin_Started:
      return true;

    case Subscription_PromoteAdmin_Failed:
    case Subscription_PromoteAdmin_Finished:
    case Subscription_SelectSubscription_Clear:
      return false;

    default:
      return state;
  }
};
