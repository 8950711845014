import { Action } from '../../../../@types/Action.type.ts';
import {
  Project_NewProject_Failed,
  Project_NewProject_Finished,
  Project_NewProject_Started,
  Project_Template_Finished,
} from '../../constants/projectActionTypes.ts';

export const creatingProject = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Project_NewProject_Started:
      return true;

    case Project_Template_Finished:
    case Project_NewProject_Failed:
    case Project_NewProject_Finished:
      return false;

    default:
      return state;
  }
};
