import { useAttachRef } from '@kontent-ai/hooks';
import { forwardRef, useMemo } from 'react';
import { CreateAutoScrollId } from '../../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../../../_shared/hooks/useAutoScroll.ts';
import { ElementAssetTile, IElementAssetTileProps } from './ElementAssetTile.tsx';

export const AssetTileWithAutoScroll = forwardRef<HTMLDivElement, IElementAssetTileProps>(
  (props, ref) => {
    const scrollId = CreateAutoScrollId.forAsset(props.assetId);
    const alternativeScrollIds = useMemo(
      () => [CreateAutoScrollId.forAssetTile(props.assetId)],
      [props.assetId],
    );

    const { refObject: scrollTargetRef, refToForward } = useAttachRef(ref);
    useAutoScroll({ alternativeScrollIds, scrollId, scrollTargetRef });

    return <ElementAssetTile ref={refToForward} {...props} />;
  },
);

AssetTileWithAutoScroll.displayName = 'AssetTileWithAutoScroll';
