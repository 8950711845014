import { forwardRef } from 'react';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { CascadeFailedItemsModal } from '../components/CascadeFailedItemsModal.tsx';
import { getStatusInfoMessageForFailedDialog } from '../utils/getStatusInfoMessage.ts';
import { sortChildContentItemItemIds } from '../utils/sortChildContentItemItemIds.ts';

export const CascadeScheduleFailedItemsModal = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();
  const failedItemIds = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    return selectedLanguageId
      ? sortChildContentItemItemIds(
          s.contentApp.editorUi.cascadePublish.cascadeResult.failedItemIds,
          s.data.listingContentItems.byId,
          s.data.listingContentItems.defaultById,
          selectedLanguageId,
        )
      : null;
  });
  const statusInfoMessage = useSelector((s) =>
    getStatusInfoMessageForFailedDialog(s.contentApp.editorUi.cascadePublish.cascadeResult),
  );

  if (!failedItemIds) {
    return null;
  }

  return (
    <CascadeFailedItemsModal
      failedItemIds={failedItemIds}
      modalTitle="Some items failed to schedule"
      onClose={() => dispatch(modalDismissed())}
      ref={ref}
      statusInfoMessage={statusInfoMessage}
      {...props}
    />
  );
});
