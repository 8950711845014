export enum SelectorType {
  Name = 'name',
  Collection = 'collection',
  Language = 'language',
  Workflow = 'workflow',
  ContentType = 'contentType',
}

export const orderedSelectors: ReadonlyArray<SelectorType> = [
  SelectorType.Name,
  SelectorType.Language,
  SelectorType.Collection,
  SelectorType.ContentType,
  SelectorType.Workflow,
];
