import Immutable from 'immutable';
import React, { ReactNode, RefAttributes, useCallback } from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';
import {
  IMultipleOptionSelectDropDownOptionProps,
  OptionExpansionState,
  OptionType,
} from './MultipleSelectDropDownOption.tsx';

interface IMultipleOptionSelectDropDownDataProps<T> {
  readonly collectionName?: DataUiCollection;
  readonly highlightedOption?: T;
  readonly highlightedPattern: string;
  readonly id?: string;
  readonly options: Immutable.List<T>;
  readonly showOptionWithWorkflowStepColorIndicator?: boolean;
  readonly maxWidth?: number;
}

interface IMultipleOptionSelectDropDownCallbackProps<T> {
  readonly renderDropdownOption: (props: IMultipleOptionSelectDropDownOptionProps<T>) => ReactNode;
  readonly getIsOptionSelected: (option: T) => boolean;
  readonly getOptionExpansionState?: (option: T) => OptionExpansionState;
  readonly getOptionId: (option: T) => string;
  readonly getOptionColor?: (option: T) => TagColor;
  readonly getOptionIndent?: (option: T) => number;
  readonly getOptionName: (option: T) => string;
  readonly getOptionTooltip?: (option: T) => string | undefined;
  readonly getOptionType?: (option: T) => OptionType;
  readonly isOptionDisabled?: (option: T) => boolean;
  readonly onOptionCollapse?: (option: T) => void;
  readonly onOptionConfirmed: (option: T) => void;
  readonly onOptionExpand?: (option: T) => void;
  readonly onOptionHighlighted: (option: T) => void;
  readonly onOptionRemoved?: (option: T) => void;
  readonly shouldSearchedOptionBeHighlighted?: (searchPhrase: string, option: T) => boolean;
}

type MultipleOptionSelectDropDownProps<T> = IMultipleOptionSelectDropDownDataProps<T> &
  IMultipleOptionSelectDropDownCallbackProps<T> &
  IForwardedRefProps<HTMLDivElement>;

const minimalDropdownWidth = 512;

const MultipleOptionSelectDropDown = <T = never>(
  props: MultipleOptionSelectDropDownProps<T>,
): React.ReactElement => {
  const {
    collectionName,
    renderDropdownOption,
    forwardedRef,
    getIsOptionSelected,
    getOptionExpansionState,
    getOptionId,
    getOptionIndent,
    getOptionColor,
    getOptionName,
    getOptionTooltip,
    getOptionType,
    highlightedOption,
    highlightedPattern,
    id,
    isOptionDisabled,
    onOptionCollapse,
    onOptionConfirmed,
    onOptionExpand,
    onOptionHighlighted,
    onOptionRemoved,
    options,
    maxWidth,
    shouldSearchedOptionBeHighlighted,
    showOptionWithWorkflowStepColorIndicator,
  } = props;

  const onClick: (option: T) => void = useCallback(
    (option) => {
      if (getIsOptionSelected(option)) {
        onOptionRemoved?.(option);
      } else {
        onOptionConfirmed(option);
      }
    },
    [getIsOptionSelected, onOptionRemoved, onOptionConfirmed],
  );

  return (
    <div
      id={id}
      className="multi-select__dropdown"
      data-hj-suppress=""
      ref={forwardedRef}
      {...(collectionName ? getDataUiCollectionAttribute(collectionName) : {})}
      {...getDataUiElementAttribute(DataUiElement.MultiSelectDropdown)}
      style={{
        maxWidth: !maxWidth || maxWidth < minimalDropdownWidth ? minimalDropdownWidth : maxWidth,
      }}
      onMouseDown={(e) => {
        //  Otherwise the search field loses focus and triggers the close action while holding a mouse-click
        e.preventDefault();
      }}
    >
      <div className="multi-select__dropdown-options">
        {options.toArray().map((option) =>
          renderDropdownOption({
            getOptionColor,
            getOptionIndent,
            getOptionName,
            getOptionTooltip,
            getOptionType,
            highlightedPattern,
            isHighlighted:
              !!highlightedOption && getOptionId(option) === getOptionId(highlightedOption),
            isOptionDisabled,
            isSelected: getIsOptionSelected(option),
            getOptionId,
            onClick,
            onHover: onOptionHighlighted,
            onOptionCollapse,
            onOptionExpand,
            option,
            optionExpansionState: getOptionExpansionState?.(option) ?? OptionExpansionState.None,
            showOptionWithWorkflowStepColorIndicator,
            shouldSearchedOptionBeHighlighted,
          }),
        )}
      </div>
    </div>
  );
};

MultipleOptionSelectDropDown.displayName = 'MultipleOptionSelectDropDown';

const MultipleOptionSelectDropDownFRC = forwardRef(MultipleOptionSelectDropDown) as <T = never>(
  props: MultipleOptionSelectDropDownProps<T> & RefAttributes<HTMLDivElement>,
) => React.ReactElement;
export { MultipleOptionSelectDropDownFRC as MultipleOptionSelectDropDown };
