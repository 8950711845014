import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, Typography, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rememberScrollId } from '../../../../../../_shared/actions/sharedActions.ts';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { NotTranslatedTagForVariant } from '../../../../../../_shared/components/Workflow/NotTranslatedTagForVariant.tsx';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { WorkflowStatusTagForVariant } from '../../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import {
  buildPath,
  getContentItemPath,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getDefaultLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProjectId } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { EditableTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { matchElementAiTasks } from '../../../../../richText/plugins/ai/utils/matchElementAiTasks.ts';
import {
  EditItemElementVariantCodenameRoute,
  EditItemElementVariantCodenameRouteParams,
} from '../../../../../smartLink/constants/routePaths.ts';
import { useItemVariantId } from '../../../ContentComponent/context/ContentItemContext.tsx';
import { LanguageContext } from '../../../ContentComponent/context/LanguageContext.tsx';
import { RevisionsContext } from '../../../Revisions/context/RevisionsContext.tsx';
import { NonLocalizableElementDefaultVariantTooltip } from '../../constants/uiConstants.ts';
import {
  ElementReference,
  useItemElementReference,
} from '../../containers/hooks/useItemElementReference.ts';
import { getNonLocalizableElementInfoTooltip } from '../../selectors/getNonLocalizableElementInfoTooltip.ts';

const InfoColumn = styled(Column).attrs(() => ({ width: 'fit-content' }))`
  ${Typography.BodySmall}
  color: ${colorTextLowEmphasis};
`;

export const DefaultVariantInfo = (props: { readonly element: ElementReference }) => {
  const tooltipText = useSelector((s) =>
    NonLocalizableElementDefaultVariantTooltip(getDefaultLanguage(s).name),
  );

  const aiActionProgress = useAiTaskProgress(
    matchElementAiTasks(
      props.element,
      AiActionName.TranslateContent,
      AiActionName.ReviewByGuidelines,
    ),
  );
  if (aiActionProgress !== AiTaskProgress.NotStarted) {
    return null;
  }

  return (
    <Row alignX="end" alignY="center" spacingX={Spacing.XS} spacingY={Spacing.S} noWrap>
      <InfoColumn>Published content in this element applies to all languages.</InfoColumn>
      <Column width="content">
        <QuinaryButton onClick={noOperation} tooltipText={tooltipText} tooltipPlacement="top">
          <QuinaryButton.Icon icon={Icons.QuestionCircle} />
        </QuinaryButton>
      </Column>
    </Row>
  );
};

export const NonDefaultVariantInfo = (props: { readonly typeElement: EditableTypeElement }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { itemId } = useItemVariantId();
  const isInRevisions = useContext(RevisionsContext);

  const projectId = useSelector(getCurrentProjectId);
  const defaultLanguage = useSelector(getDefaultLanguage);
  const defaultListingItem = useSelector((state) =>
    state.data.listingContentItems.defaultById.get(itemId),
  );
  const canViewDefaultLanguage =
    defaultListingItem &&
    hasActiveVariantCapability(ActiveCapabilityType.ViewContent, defaultListingItem);
  const tooltipText = useSelector((s) =>
    getNonLocalizableElementInfoTooltip(s, isInRevisions, itemId, props.typeElement),
  );

  const localDefaultLanguagePath = getContentItemPath(
    history.location.pathname,
    itemId,
    DefaultVariantId,
  );
  const elementCodename = props.typeElement.codename;

  // Link href navigates to item editor in case they open the link in a new window
  const linkDefaultLanguagePath = elementCodename
    ? buildPath<EditItemElementVariantCodenameRouteParams>(EditItemElementVariantCodenameRoute, {
        projectId,
        itemGuid: itemId,
        elementCodename,
        variantCodename: defaultLanguage.codename,
      })
    : localDefaultLanguagePath;

  const redirectToDefaultLanguage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (elementCodename) {
      // Autoscroll to the respective element in default language to simplify user navigation
      dispatch(
        rememberScrollId({
          path: localDefaultLanguagePath,
          scrollId: CreateAutoScrollId.forElement(elementCodename, itemId),
        }),
      );
    }
    history.push(localDefaultLanguagePath);
  };

  const workflowTag = (
    <Box display="inline-flex">
      {defaultListingItem?.variant && !defaultListingItem.variant.isArchived ? (
        <WorkflowStatusTagForVariant
          workflowStatus={defaultListingItem.variant.assignment.workflowStatus}
          publishingState={defaultListingItem.variant.publishingState}
          scheduledToPublishAt={defaultListingItem.variant.assignment.scheduledToPublishAt}
          scheduledToUnpublishAt={defaultListingItem.variant.assignment.scheduledToUnpublishAt}
        />
      ) : (
        <NotTranslatedTagForVariant />
      )}
    </Box>
  );

  const defaultLanguageLink = (
    <Tooltip placement="bottom" tooltipText={`Edit element in ${defaultLanguage.name}`}>
      <Link to={linkDefaultLanguagePath} onClick={redirectToDefaultLanguage}>
        {defaultLanguage.name}
      </Link>
    </Tooltip>
  );

  return (
    <Row alignX="end" alignY="center" spacingY={Spacing.S} spacingX={Spacing.XS} noWrap>
      {canViewDefaultLanguage ? (
        <InfoColumn>
          The element’s content in {defaultLanguageLink} {workflowTag} applies to all languages.
        </InfoColumn>
      ) : (
        <InfoColumn css="white-space: normal">
          This non-localizable element can be edited only in {defaultLanguage.name}.
        </InfoColumn>
      )}
      <Column width="content">
        <QuinaryButton onClick={noOperation} tooltipText={tooltipText} tooltipPlacement="top">
          <QuinaryButton.Icon icon={Icons.QuestionCircle} />
        </QuinaryButton>
      </Column>
    </Row>
  );
};

export const NonLocalizableItemElementInfo = (props: {
  readonly typeElement: EditableTypeElement;
}) => {
  const { languageId } = useContext(LanguageContext);
  const isDefaultVariant = languageId === DefaultVariantId;

  const element = useItemElementReference(props.typeElement);
  if (!element) {
    return null;
  }

  return isDefaultVariant ? (
    <DefaultVariantInfo element={element} />
  ) : (
    <NonDefaultVariantInfo {...props} />
  );
};

NonLocalizableItemElementInfo.displayName = 'NonLocalizableItemElementInfo';
