import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DateTime } from '../../../../../_shared/models/DateTime.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKeyDetail_Regeneration_Failed,
  ApiKeyDetail_Regeneration_Finished,
  ApiKeyDetail_Regeneration_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { ApiKey, createApiKeyFromServerModel } from '../../models/ApiKey.ts';

const started = () =>
  ({
    type: ApiKeyDetail_Regeneration_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_Regeneration_Failed,
  }) as const;

const success = (apiKey: ApiKey) =>
  ({
    type: ApiKeyDetail_Regeneration_Finished,
    payload: { apiKey },
  }) as const;

interface IRegenerateApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'regenerateApiKey'>;
}

export type RegenerateApiKeyActionsType = ReturnType<
  typeof started | typeof failed | typeof success
>;

export const regenerateApiKeyCreator =
  ({ apiKeysRepository }: IRegenerateApiKeyDependencies) =>
  (tokenSeedId: Uuid, expirationDate: DateTime): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(started());
      const serverModel = await apiKeysRepository.regenerateApiKey(tokenSeedId, {
        expires_at: expirationDate.value,
      });

      dispatch(success(createApiKeyFromServerModel(serverModel)));
    } catch (error) {
      dispatch(failed());
      throw InvariantException(
        `Could not regenerate api key because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
