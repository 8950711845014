import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { LinkLike } from '../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IntercomUtils } from '../../../_shared/utils/intercomUtils.ts';

interface ITrialActivationProps {
  readonly isVisible: boolean;
  readonly text: string;
  readonly onClick: () => void;
}

const contactUsClick = (): void => {
  IntercomUtils.showIntercom();
  IntercomUtils.showMessage();
};

export const TrialActivation: FC<ITrialActivationProps> = (props) => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <div className="trial-activation">
      <p>{props.text}</p>
      <Button
        buttonStyle="primary"
        onClick={props.onClick}
        {...getDataUiActionAttribute(DataUiAction.Activate)}
      >
        Start my free 30-day trial
      </Button>
      <div className="trial-activation__contact-us">
        Need help? <LinkLike onClick={contactUsClick}>Contact us</LinkLike>
      </div>
    </div>
  );
};

TrialActivation.displayName = 'TrialActivation';
