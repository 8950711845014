import { useCallback } from 'react';
import { useIsAiWorkingOnImageDescription } from '../../../../../_shared/hooks/Assets/useIsAiWorkingOnImageDescription.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { isAiImageTaggingEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { Asset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  assetEditorModalClosed,
  setEditedAsset,
} from '../../../../contentInventory/assets/actions/assetLibraryActions.ts';
import {
  initializeAssetEditDialog,
  trackUserActionOnAssetUpdate,
  updateAsset,
  validateAssetsInEditedItem,
} from '../../../../contentInventory/assets/actions/thunkAssetLibraryActions.ts';
import { aiIsWorkingOnDescription } from '../../../../contentInventory/assets/constants/aiConstants.ts';
import { getAssetAlreadyModifiedSaveButtonTooltip } from '../../../../contentInventory/assets/utils/getAssetAlreadyModifiedMessageUtils.ts';
import { AssetEditorDialog } from '../components/AssetEditorDialog.tsx';

interface IAssetEditorDialogContainerProps {
  readonly assetId: Uuid;
  readonly onAssetEditingFinished: () => void;
}

export const ModalAssetEditor = ({
  assetId,
  onAssetEditingFinished,
}: IAssetEditorDialogContainerProps) => {
  const originalAsset = useSelector((state) => state.data.assets.byId.get(assetId));

  const {
    assetEditorLoadingStatus,
    editedAsset,
    hasConflict,
    isEditedAssetBeingSaved,
    isEditedAssetModified,
  } = useSelector((state) => state.assetLibraryApp.assetUiEditor);

  const taxonomyGroups = useSelector((state) => state.data.taxonomyGroups.byId);

  const isLoading = assetEditorLoadingStatus !== LoadingStatus.Loaded;
  const canUpdateAssets = originalAsset
    ? Asset.hasCapability(originalAsset, ActiveCapabilityType.UpdateAssets)
    : false;

  useThunkPromise(initializeAssetEditDialog, assetId);

  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    onAssetEditingFinished();
    dispatch(assetEditorModalClosed());
  }, [onAssetEditingFinished]);

  const onChange = useCallback(
    (updatedAsset: IAsset) => dispatch(setEditedAsset(updatedAsset)),
    [],
  );

  const onSaveAsset = useCallback(
    async (asset: IAsset) => {
      dispatch(trackUserActionOnAssetUpdate(asset));
      await dispatch(updateAsset(asset));
      dispatch(validateAssetsInEditedItem());
      onAssetEditingFinished();
      dispatch(assetEditorModalClosed());
    },
    [onAssetEditingFinished],
  );

  const isAiWorkingOnImageDescription = useIsAiWorkingOnImageDescription(assetId);
  const isAiImageRecognitionEnabled = useSelector(isAiImageTaggingEnabled);

  const isSaveButtonDisabled =
    isLoading ||
    isEditedAssetBeingSaved ||
    hasConflict ||
    !isEditedAssetModified ||
    isAiWorkingOnImageDescription;

  return originalAsset ? (
    <AssetEditorDialog
      assetTitle={editedAsset?.title ?? null}
      assetTitlePlaceholder={originalAsset?.filename}
      canUpdateAssets={canUpdateAssets}
      disabledSaveButtonTooltipText={getDisabledSaveButtonTooltip({
        hasConflict,
        isAiImageRecognitionEnabled,
        isAiWorkingOnImageDescription,
      })}
      editedAsset={editedAsset}
      isBeingSaved={isEditedAssetBeingSaved}
      isLoading={isLoading}
      isSaveButtonDisabled={isSaveButtonDisabled}
      onCancel={onCancel}
      onChange={onChange}
      onSaveAsset={onSaveAsset}
      taxonomyGroups={taxonomyGroups}
    />
  ) : null;
};

const getDisabledSaveButtonTooltip = ({
  hasConflict,
  isAiImageRecognitionEnabled,
  isAiWorkingOnImageDescription,
}: {
  readonly hasConflict: boolean;
  readonly isAiImageRecognitionEnabled: boolean;
  readonly isAiWorkingOnImageDescription: boolean;
}): string | undefined => {
  if (isAiWorkingOnImageDescription) {
    return aiIsWorkingOnDescription;
  }

  if (hasConflict) {
    return getAssetAlreadyModifiedSaveButtonTooltip(isAiImageRecognitionEnabled);
  }

  return undefined;
};
