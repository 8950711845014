import Immutable from 'immutable';
import React from 'react';
import { FullScreenModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { CategoryItemsListingModalView, ListingView } from './ListingViews/ListingView.tsx';

export type ProjectOverviewItem = {
  readonly id: Uuid;
  readonly contentType: string;
  readonly due: DateTimeStamp | null;
  readonly lastUpdatedAt: DateTimeStamp | null;
  readonly name: string;
  readonly workflowStatus: IAssignmentWorkflowStep;
  readonly publishingState: PublishingState;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly linkTo: string;
  readonly isComplete: boolean;
};

type Props = {
  readonly contentItems: Immutable.List<ProjectOverviewItem>;
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly title: string;
  readonly view: CategoryItemsListingModalView;
};

export const CategoryItemsListingModal: React.FC<Props> = ({
  contentItems,
  isOpen,
  onCancel,
  title,
  view,
}) => (
  <FullScreenModalDialog
    headline={title}
    isDismissable
    isOpen={isOpen}
    onClose={onCancel}
    cancelAction={{
      onClick: onCancel,
      tooltipText: 'Cancel',
      tooltipShortcuts: ShortcutSymbols.Escape,
      ...getDataUiActionAttribute(DataUiAction.Cancel),
    }}
    {...getDataUiElementAttribute(DataUiElement.ModalDialog)}
  >
    <ListingView viewType={view} contentItems={contentItems} />
  </FullScreenModalDialog>
);

CategoryItemsListingModal.displayName = 'CategoryItemsListingModal';
