import { InvariantException } from '@kontent-ai/errors';
import React, { useState } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { AssetCreateNewFolder } from '../../containers/AssetFolders/AssetCreateNewFolder.tsx';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { AssetFolderNameDialog, AssetFolderState } from './AssetFolderNameDialog.tsx';
import { AssetFolders } from './AssetFolders.tsx';

export type AssetLibraryFoldersProps = {
  readonly canManageFolders: boolean;
  readonly clearAssetSelection: () => void;
  readonly folders: ReadonlyArray<ITaxonomyTerm>;
  readonly isAssetSelectorOpened: boolean;
  readonly onFolderUpdate: (folder: ITaxonomyTerm) => void;
  readonly orderBy: AssetsOrderBy;
};

export const AssetLibraryFolders: React.FC<AssetLibraryFoldersProps> = ({
  canManageFolders,
  clearAssetSelection,
  folders,
  isAssetSelectorOpened,
  onFolderUpdate,
  orderBy,
}) => {
  const [folderToRename, setFolderToRename] = useState<ITaxonomyTerm | null>(null);

  const closeRenameDialog = (): void => setFolderToRename(null);

  const updateName = (name: string): void => {
    if (!folderToRename) {
      throw InvariantException('Unknown folder to rename');
    }

    const updatedFolder = {
      ...folderToRename,
      name,
    };

    onFolderUpdate(updatedFolder);
    closeRenameDialog();
  };

  return (
    <section className="asset-library__section">
      <h3 className="asset-library__section-title">Folders</h3>
      <div
        className="asset-library__section-tiles row"
        {...getDataUiCollectionAttribute(DataUiCollection.AssetFolders)}
      >
        {canManageFolders && <AssetCreateNewFolder />}
        <AssetFolders
          clearAssetSelection={clearAssetSelection}
          folders={folders}
          isAssetSelectorOpen={isAssetSelectorOpened}
          onRename={canManageFolders ? setFolderToRename : null}
          orderBy={orderBy}
        />
      </div>
      {canManageFolders && folderToRename && (
        <AssetFolderNameDialog
          initialValue={folderToRename.name}
          onCancel={closeRenameDialog}
          onConfirm={updateName}
          state={AssetFolderState.Edited}
        />
      )}
    </section>
  );
};

AssetLibraryFolders.displayName = 'AssetLibraryFolders';
