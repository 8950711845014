import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentType_Editor_ContentGroupCreated,
  ContentType_Editor_ContentGroupDeleted,
  ContentType_Editor_SelectedContentGroupChanged,
} from '../../../contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_TypeElementCreated,
} from '../../constants/sharedContentModelsActionTypes.ts';

export function lastAddedElementId(
  state: Uuid | undefined | null = null,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case ContentModels_TypeEditor_TypeElementCreated: {
      return action.payload.typeElementData.elementId;
    }

    case ContentModels_TypeEditor_InitStarted:
    case ContentType_Editor_SelectedContentGroupChanged:
    case ContentType_Editor_ContentGroupCreated:
    case ContentType_Editor_ContentGroupDeleted: {
      return null;
    }

    default:
      return state;
  }
}
