import {
  Environment_CreateEnvironment_CloseDialog,
  Environment_CreateEnvironment_ShowDialog,
  Environment_CreateEnvironment_SourceEnvironmentChanged,
  Environment_DeleteEnvironment_CloseDialog,
  Environment_DeleteEnvironment_ShowDialog,
  Environment_SwapEnvironment_CloseDialog,
  Environment_SwapEnvironment_ShowDialog,
} from '../constants/environmentActionTypes.ts';

export const closeCreateEnvironmentDialog = () =>
  ({
    type: Environment_CreateEnvironment_CloseDialog,
  }) as const;

export const showCreateEnvironmentDialog = (cloneFromId: Uuid, isNewEnvironment: boolean) =>
  ({
    type: Environment_CreateEnvironment_ShowDialog,
    payload: {
      cloneFromId,
      isNewEnvironment,
    },
  }) as const;

export const closeDeleteEnvironmentDialog = () =>
  ({
    type: Environment_DeleteEnvironment_CloseDialog,
  }) as const;

export const showDeleteEnvironmentDialog = (environmentId: Uuid) =>
  ({
    type: Environment_DeleteEnvironment_ShowDialog,
    payload: {
      environmentId,
    },
  }) as const;

export const changeSourceEnvironmentSelector = (environmentId: Uuid) =>
  ({
    type: Environment_CreateEnvironment_SourceEnvironmentChanged,
    payload: {
      environmentId,
    },
  }) as const;

export const closeSwapEnvironmentDialog = () =>
  ({
    type: Environment_SwapEnvironment_CloseDialog,
  }) as const;

export const showSwapEnvironmentDialog = (environmentId: Uuid) =>
  ({
    type: Environment_SwapEnvironment_ShowDialog,
    payload: {
      environmentId,
    },
  }) as const;
