import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ICollectionsRepository } from '../../../../../repositories/interfaces/ICollectionsRepository.type.ts';
import { ICollectionServerModel } from '../../../../../repositories/serverModels/CollectionsServerModel.type.ts';
import {
  SubscriptionUsers_CollectionFilter_LoadCollections_Failed,
  SubscriptionUsers_CollectionFilter_LoadCollections_Started,
  SubscriptionUsers_CollectionFilter_LoadCollections_Succeeded,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly collectionsRepository: Pick<ICollectionsRepository, 'getProjectCollections'>;
}

const start = () =>
  ({
    type: SubscriptionUsers_CollectionFilter_LoadCollections_Started,
  }) as const;

const success = (data: ReadonlyArray<ICollectionServerModel>) =>
  ({
    type: SubscriptionUsers_CollectionFilter_LoadCollections_Succeeded,
    payload: { data },
  }) as const;

const failure = () =>
  ({
    type: SubscriptionUsers_CollectionFilter_LoadCollections_Failed,
  }) as const;

export type LoadEnvironmentCollectionsActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const loadEnvironmentCollectionsCreator =
  ({ collectionsRepository }: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const collectionsServerModel = await collectionsRepository.getProjectCollections(
        projectId,
        abortSignal,
      );
      dispatch(success(collectionsServerModel.collections));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
