import React from 'react';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { EditorToolbarDivider } from '../../toolbars/components/buttons/EditorToolbarDivider.tsx';
import {
  ToolbarButtonInputProps,
  getToolbarButtonProps,
} from '../../toolbars/utils/toolbarUtils.ts';
import { BoldButton } from './buttons/BoldButton.tsx';
import { CodeButton } from './buttons/CodeButton.tsx';
import { ItalicButton } from './buttons/ItalicButton.tsx';
import { SubscriptButton } from './buttons/SubscriptButton.tsx';
import { SuperscriptButton } from './buttons/SuperscriptButton.tsx';

export type InlineStylesToolbarButtonsProps = Omit<
  ToolbarButtonInputProps,
  'selectionContainsText'
> & {
  readonly areButtonsBefore: boolean;
  readonly onCommand: (command: RichTextInputCommand) => void;
};

export const InlineStylesToolbarButtons: React.FC<InlineStylesToolbarButtonsProps> = ({
  areButtonsBefore,
  currentVisualStyle,
  entityMap,
  fullBlockTypesAtSelection,
  hidesDisallowedFeatures,
  limitations,
  metadataAtSelection,
  onCommand,
}) => {
  const buttonProps: ToolbarButtonInputProps = {
    fullBlockTypesAtSelection,
    currentVisualStyle,
    entityMap,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection,
    onCommand,
    selectionContainsText: null,
  };

  const boldProps = getToolbarButtonProps(RichTextInputCommand.Bold, buttonProps);
  const italicProps = getToolbarButtonProps(RichTextInputCommand.Italic, buttonProps);
  const superscriptProps = getToolbarButtonProps(RichTextInputCommand.Superscript, buttonProps);
  const subscriptProps = getToolbarButtonProps(RichTextInputCommand.Subscript, buttonProps);
  const codeProps = getToolbarButtonProps(RichTextInputCommand.Code, buttonProps);

  return (
    <>
      {areButtonsBefore &&
        (boldProps || italicProps || superscriptProps || subscriptProps || codeProps) && (
          <EditorToolbarDivider />
        )}
      {boldProps && <BoldButton {...boldProps} />}
      {italicProps && <ItalicButton {...italicProps} />}
      {(boldProps || italicProps) && (superscriptProps || subscriptProps || codeProps) && (
        <EditorToolbarDivider />
      )}
      {superscriptProps && <SuperscriptButton {...superscriptProps} />}
      {subscriptProps && <SubscriptButton {...subscriptProps} />}
      {codeProps && <CodeButton {...codeProps} />}
    </>
  );
};

InlineStylesToolbarButtons.displayName = 'InlineStylesToolbarButtons';
