import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Environment_CreateEnvironment_LoadRoles_Started,
  Environment_CreateEnvironment_LoadRoles_Success,
  Environment_CreateEnvironment_ShowDialog,
  Environment_CreateEnvironment_SourceEnvironmentChanged,
} from '../../constants/environmentActionTypes.ts';
import { ICreateEnvironmentDialogSettings } from '../IEnvironmentsAppStoreState.type.ts';

const initialState: ICreateEnvironmentDialogSettings = {
  cloneFromId: null,
  isNewEnvironment: true,
  loadingRolesStatus: LoadingStatus.InitialEmpty,
};

export const createEnvironmentDialogSettings = (
  state: ICreateEnvironmentDialogSettings = initialState,
  action: Action,
): ICreateEnvironmentDialogSettings => {
  switch (action.type) {
    case Environment_CreateEnvironment_ShowDialog:
      return {
        ...state,
        cloneFromId: action.payload.cloneFromId,
        isNewEnvironment: action.payload.isNewEnvironment,
      };

    case Environment_CreateEnvironment_SourceEnvironmentChanged:
      return {
        ...state,
        cloneFromId: action.payload.environmentId,
      };

    case Environment_CreateEnvironment_LoadRoles_Started:
      return {
        ...state,
        loadingRolesStatus: LoadingStatus.Loading,
      };

    case Environment_CreateEnvironment_LoadRoles_Success:
      return {
        ...state,
        loadingRolesStatus: LoadingStatus.Loaded,
      };

    default:
      return state;
  }
};
