import React from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { DefaultLanguageBar } from '../containers/languageBarItems/DefaultLanguageBar.tsx';
import { DefaultLanguageItemEditor } from '../containers/languageEditors/DefaultLanguageEditor.tsx';

type Props = {
  readonly isEdited: boolean;
};

export const DefaultLanguageItem: React.FC<Props> = ({ isEdited }) => (
  <li className="bar-item__node">
    <BarItemAnimation
      estimatedMaxHeightWhenExpanded={400}
      renderCollapsed={() => <DefaultLanguageBar />}
      renderExpanded={() => <DefaultLanguageItemEditor />}
      shouldBeExpanded={isEdited}
    />
  </li>
);

DefaultLanguageItem.displayName = 'DefaultLanguageItem';
