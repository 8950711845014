import Immutable from 'immutable';
import { ElementError, ElementErrorResult, emptyElementErrorResult } from './types/Errors.ts';

interface IParams {
  readonly value: Immutable.List<Uuid>;
}

const invalidElementResult: ElementErrorResult = {
  errors: [ElementError.InvalidLinkedItems],
};

export const getLinkedItemsElementErrors = ({ value }: IParams): ElementErrorResult => {
  const isValid = Immutable.List.isList(value);

  return isValid ? emptyElementErrorResult : invalidElementResult;
};
