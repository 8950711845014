import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WidgetDetailTableStateContent } from '../../shared/components/WidgetDetailTableStateContent.tsx';
import { entityListDetailDisplayCount } from '../../shared/constants/widgetConstants.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { AssignedContentItemsOrderByColumnCode } from '../constants/assignedContentItemsOrderByColumnCode.ts';
import { createNewOrderBy, getDirection } from '../utils/orderByColumn.ts';

type ItemsAssignedToYouWidgetDetailProps = React.PropsWithChildren<{
  readonly ariaLabelledBy: string;
  readonly onChangeOrderBy: (
    orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    numberOfItemsToFetch: number,
  ) => void;
  readonly orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>;
  readonly state: WidgetListingState;
}>;

export const ItemsAssignedToYouWidgetDetail: React.FC<ItemsAssignedToYouWidgetDetailProps> = ({
  ariaLabelledBy,
  onChangeOrderBy,
  orderBy,
  state,
  children,
}) => {
  return (
    <WidgetDetailTableStateContent state={state}>
      <DataTable
        ariaLabelledBy={ariaLabelledBy}
        dataUiCollectionName={DataUiCollection.ContentItems}
        header={<DataTableHeadRow columns={getTableHeadColumns(orderBy, onChangeOrderBy)} />}
      >
        {children}
      </DataTable>
    </WidgetDetailTableStateContent>
  );
};

const getTableHeadColumns = (
  orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
  onChangeOrderBy: (
    orderBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
    numberOfItemsToFetch: number,
  ) => void,
) => {
  return Immutable.List.of<Column>(
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
      orderBy: OrderByDirection.None,
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
      orderBy: OrderByDirection.None,
      className: 'data-table__column--4',
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
      orderBy: OrderByDirection.None,
      className: 'data-table__column--5',
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
      orderBy: getDirection(orderBy, AssignedContentItemsOrderByColumnCode.Due),
      className: 'data-table__column--4',
      onClick: () =>
        onChangeOrderBy(
          createNewOrderBy(orderBy, AssignedContentItemsOrderByColumnCode.Due),
          entityListDetailDisplayCount,
        ),
    },
    {
      columnName: translateColumnCodeToTitle(ItemColumnCode.LastModifiedAt),
      orderBy: getDirection(orderBy, AssignedContentItemsOrderByColumnCode.LastModified),
      className: 'data-table__column--4',
      onClick: () =>
        onChangeOrderBy(
          createNewOrderBy(orderBy, AssignedContentItemsOrderByColumnCode.LastModified),
          entityListDetailDisplayCount,
        ),
    },
  );
};
