import { Action } from '../../../../../@types/Action.type.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import {
  SubscriptionUsers_SortBy_Changed,
  SubscriptionUsers_SortBy_Initialized,
} from '../../constants/actionTypes.ts';

export enum SubscriptionUserListingColumnCode {
  Name = 'name',
  LastLogin = 'lastLogin',
  Status = 'status',
  None = '',
}

export const defaultOrderBy: OrderBy<SubscriptionUserListingColumnCode> = {
  columnCode: SubscriptionUserListingColumnCode.Name,
  direction: OrderByDirection.Ascending,
};

const toggleOrderDirection = (direction: OrderByDirection) => {
  switch (direction) {
    case OrderByDirection.Ascending:
      return OrderByDirection.Descending;
    case OrderByDirection.Descending:
      return OrderByDirection.Ascending;
    default:
      return direction;
  }
};

export const sortBy = (
  state: OrderBy<SubscriptionUserListingColumnCode> = defaultOrderBy,
  action: Action,
): OrderBy<SubscriptionUserListingColumnCode> => {
  switch (action.type) {
    case SubscriptionUsers_SortBy_Changed: {
      const direction =
        state.columnCode === action.payload.columnCode
          ? toggleOrderDirection(state.direction)
          : defaultOrderBy.direction;
      return {
        columnCode: action.payload.columnCode,
        direction,
      };
    }

    case SubscriptionUsers_SortBy_Initialized: {
      return {
        ...action.payload.orderBy,
      };
    }

    default:
      return state;
  }
};
