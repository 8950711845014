export enum ActiveCapabilityType {
  CancelSchedule = 'cancel-scheduled-publish',
  ConfigureCodenames = 'configure-codenames',
  CreateContent = 'create',
  CreateNewVersion = 'create-new-version',
  DeleteContent = 'delete',
  DiscardNewVersion = 'discard-new-version',
  Unpublish = 'unpublish',
  UpdateAssignment = 'update-assignment',
  UpdateContent = 'update',
  ViewContent = 'view',
  ViewAssets = 'view-assets',
  UpdateAssets = 'update-assets',
  CreateAssets = 'create-assets',
  DeleteAssets = 'delete-assets',
}
