import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IAssetTypeRepository } from '../../../../repositories/interfaces/IAssetTypeRepository.type.ts';
import { CompiledContentTypeServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  Data_AssetType_Failed,
  Data_AssetType_Started,
  Data_AssetType_Success,
} from '../../../constants/dataActionTypes.ts';
import { defaultAssetTypeId } from '../../../constants/defaultAssetType.ts';

interface IDeps {
  readonly assetTypeRepository: Pick<IAssetTypeRepository, 'getCompiledAssetType'>;
}

const start = () => ({ type: Data_AssetType_Started }) as const;
const success = (data: CompiledContentTypeServerModel) =>
  ({
    type: Data_AssetType_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_AssetType_Failed }) as const;

export type LoadAssetTypeActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadAssetTypeAction =
  ({ assetTypeRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const assetTypeServerModel = await assetTypeRepository.getCompiledAssetType(
        defaultAssetTypeId,
        abortSignal,
      );
      dispatch(success(assetTypeServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
