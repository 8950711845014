import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useThrottledCallback } from 'use-debounce';
import { TextFilter } from '../../../../../_shared/components/TextFilter.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  capitalizeFirstLetter,
  isEmptyOrWhitespace,
} from '../../../../../_shared/utils/stringUtils.ts';
import { searchPhraseFilterUpdated } from '../../actions/subscriptionUserDetailActions.ts';
import { SubscriptionCapabilitiesContext } from '../../context/SubscriptionCapabilitiesContext.tsx';

export const StyledFilterAreaFilterLabel = styled(Label).attrs({
  size: LabelSize.L,
  $color: colorTextDefault,
})`
  display: flex;
  user-select: none;
`;

export const SubscriptionUserDetailSearchPhraseFilter = () => {
  const dispatch = useDispatch();
  const storedFilterValue = useSelector(
    (s) => s.subscriptionApp.users.userDetailUi.filter.searchPhrase,
  );
  const [filterValue, setFilterValue] = useState<string>(storedFilterValue);

  const storeFilter = useThrottledCallback(
    (searchPhrase: string) => dispatch(searchPhraseFilterUpdated(searchPhrase)),
    500,
  );

  useEffect(() => {
    if (isEmptyOrWhitespace(storedFilterValue)) {
      setFilterValue('');
    }
  }, [storedFilterValue]);

  const onUpdateFilter = (searchPhrase: string) => {
    setFilterValue(searchPhrase);
    storeFilter(searchPhrase);
  };

  const capabilities = useContext(SubscriptionCapabilitiesContext);
  const filterName = capabilities.areLanguageRolesEnabled
    ? 'environment, role or language'
    : 'environment or role';

  return (
    <Stack spacing={Spacing.S}>
      <StyledFilterAreaFilterLabel>{capitalizeFirstLetter(filterName)}</StyledFilterAreaFilterLabel>
      <TextFilter
        onChange={onUpdateFilter}
        placeholder={`Filter by ${filterName}`}
        text={filterValue}
        ariaLabel="Search subscription user detail"
      />
    </Stack>
  );
};
