import { Action } from '../../../../@types/Action.type.ts';
import { IUserIdentifier } from '../../../models/UserIdentifier.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_SelectedContributors_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

const emptyState: ReadonlySet<IUserIdentifier> = new Set();

export function contributors(state = emptyState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.contributors;

    case ChangeWorkflowStep_SelectedContributors_Changed:
      return action.payload.contributors;

    default:
      return state;
  }
}
