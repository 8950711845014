import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import { getSortedUserProjects } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

export const getAccessibleEnvironmentIds = (state: IStore): ReadonlyArray<Uuid> =>
  getAccessibleEnvironmentIdsMemoized(
    state.data.user.projectsInfoById,
    state.sharedApp.currentProjectContainerId,
  );

const getAccessibleEnvironmentIdsMemoized = memoize.maxOne(
  (
    projectsById: Immutable.Map<Uuid, IUserProjectInfo>,
    projectContainerId: Uuid,
  ): ReadonlyArray<Uuid> =>
    getSortedUserProjects(projectsById)
      .filter((projectInfo) => projectInfo.projectContainerId === projectContainerId)
      .map((projectInfo) => projectInfo.projectId)
      .toArray(),
);
