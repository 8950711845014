import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps } from 'react';

type ActivateFeatureButtonProps = {
  readonly canBeDeactivated?: boolean;
  readonly children?: never;
  readonly deactivationUnavailableExplanation?: string;
  readonly featureName: string;
  readonly isDeactivating: boolean;
} & Omit<
  ComponentProps<typeof Button>,
  'disabled' | 'size' | 'tooltipText' | 'buttonStyle' | 'destructive' | 'destructiveIcon'
>;
export const DeactivateFeatureButton: React.FC<ActivateFeatureButtonProps> = ({
  canBeDeactivated = true,
  deactivationUnavailableExplanation = 'This feature can’t be turned off.',
  featureName,
  isDeactivating,
  ...restProps
}) => {
  return (
    <Button
      buttonStyle="tertiary"
      destructive
      destructiveIcon={Icons.Drawers}
      disabled={!canBeDeactivated || isDeactivating}
      size="small"
      tooltipText={canBeDeactivated ? null : deactivationUnavailableExplanation}
      {...restProps}
    >
      {isDeactivating && <Button.ProgressIcon />}
      <Button.Label>
        {isDeactivating ? 'Deactivating' : 'Deactivate'} {featureName}
      </Button.Label>
    </Button>
  );
};
