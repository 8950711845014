import { useAttachRef } from '@kontent-ai/hooks';
import { ComponentProps, forwardRef } from 'react';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { CommentThread } from './CommentThread.tsx';

// Comment thread in discussion is using auto-scroll to handle scrolling for comment link from notification e-mail.
export const CommentSectionThread = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof CommentThread>
>((props, ref) => {
  const scrollId = CreateAutoScrollId.forCommentThread(props.commentThread.id);
  const { refObject: scrollTargetRef, refToForward } = useAttachRef(ref);
  useAutoScroll({ scrollTargetRef, scrollId });

  return <CommentThread ref={refToForward} {...props} />;
});

CommentSectionThread.displayName = 'CommentSectionThread';
