import { ITaskServerModel } from '../../repositories/serverModels/ITaskServerModel.type.ts';
import { ContentItemId } from './ContentItemId.ts';

export enum TaskStatus {
  Completed = 'Completed',
  Open = 'Open',
}

export type Task = {
  readonly assignees: ReadonlySet<string>;
  readonly contentItemId: ContentItemId;
  readonly description: string;
  readonly dueDate: string | null;
  readonly id: Uuid;
  readonly lastModifiedAt: DateTimeStamp;
  readonly lastModifiedBy: string;
  readonly owner: string;
  readonly status: TaskStatus;
};

export const createTaskFromServerModel = (task: ITaskServerModel): Task => ({
  assignees: new Set(task.assignees),
  contentItemId: {
    variantId: task.itemVariantId.variantId,
    itemId: task.itemVariantId.itemId,
  },
  description: task.description,
  dueDate: task.due,
  id: task.id,
  lastModifiedAt: task.lastModifiedAt,
  lastModifiedBy: task.lastModifiedBy,
  owner: task.owner,
  status: task.status,
});
