import { TaxonomyTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  ITaxonomyTypeElementData,
  getTaxonomyTypeElementDataFromServerModel,
} from '../../models/elements/TaxonomyTypeElementData.ts';
import { hasTaxonomyDefaultValue } from '../typeElementDefaultValueUtils.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';
import { getIntegerFromString } from './utils/typeElementDataConvertersUtils.ts';

function convertTypeElementRecordToServerModel(
  typeElement: ITaxonomyTypeElementData,
): TaxonomyTypeElementServerModel {
  const { taxonomyGroupId, maxItems, minItems } = typeElement;

  const maxItemsCount = maxItems && getIntegerFromString(maxItems);
  const minItemsCount = minItems && getIntegerFromString(minItems);

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    default: hasTaxonomyDefaultValue(typeElement)
      ? { global: { value: typeElement.defaultValue } }
      : null,
    maxItems: maxItemsCount && maxItemsCount > 0 ? maxItemsCount : null,
    minItems: minItemsCount && minItemsCount > 0 ? minItemsCount : null,
    taxonomyGroupId,
    type: ElementType.Taxonomy,
  };
}

export function createTaxonomyTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: TaxonomyTypeElementServerModel) =>
      getTaxonomyTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: ITaxonomyTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
