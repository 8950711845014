import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { getContributorsValidationErrorMessage } from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/workflowValidationMessages.ts';
import { IStore } from '../../../../stores/IStore.type.ts';
import { getContributorsAvailableAsAssigneesInChangeWorkflowStepModal } from '../../../../utils/permissions/getAvailableContributors.ts';
import {
  showAllContributors,
  showAvailableContributors,
} from '../../actions/changeWorkflowStepModalActions.ts';
import { changeSelectedContributors } from '../../actions/thunks/changeSelectedContributors.ts';
import {
  ContributorsSection as ContributorsSectionComponent,
  IContributorsSectionDispatchProps,
  IContributorsSectionOwnProps,
  IContributorsSectionStateProps,
} from '../../components/Elements/ContributorsSection.tsx';

const mapStateToProps = (state: IStore): IContributorsSectionStateProps => {
  const {
    contentApp: { changeWorkflowStepModalData },
    data: { user },
  } = state;

  const contributors = getContributorsAvailableAsAssigneesInChangeWorkflowStepModal(state);

  const availableContributors = changeWorkflowStepModalData.showAllContributors
    ? contributors.allActive
    : contributors.available;

  const assignedContributors = (
    changeWorkflowStepModalData.showAllContributors
      ? contributors.assigned
      : contributors.availableAssigned
  ).map((contributor) => contributor.userId);

  const hasAssignedAvailableContributor = !!contributors.availableAssigned.length;
  const errorMessage = hasAssignedAvailableContributor
    ? undefined
    : getContributorsValidationErrorMessage(changeWorkflowStepModalData.workflowStep.name);

  return {
    assignedContributors,
    availableContributors,
    currentUserId: user.info.userId,
    errorMessage,
    showAllContributors: changeWorkflowStepModalData.showAllContributors,
    workflowStepName: changeWorkflowStepModalData.workflowStep.name,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IContributorsSectionDispatchProps => ({
  onContributorListChanged: (contributors) => dispatch(changeSelectedContributors(contributors)),
  onShowAllContributors: () => dispatch(showAllContributors()),
  onShowAvailableContributors: () => dispatch(showAvailableContributors()),
});

export const ContributorsSection: React.ComponentType<
  React.PropsWithChildren<IContributorsSectionOwnProps>
> = connect(mapStateToProps, mapDispatchToProps)(ContributorsSectionComponent);
