import { memoize } from '@kontent-ai/memoization';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import {
  roleBuilderAnyLanguageOptionId,
  roleBuilderAnyLanguageOptionName,
} from '../constants/roleBuilder.ts';
import { getUsedLanguageIds } from '../utils/getUsedLanguageIds.ts';

export const getBasicRoleBuilderErrorMessage = memoize.maxOne(
  (
    userRoles: readonly IProjectContributorRole[],
    areLanguageRolesEnabled: boolean,
  ): string | undefined => {
    const usedLanguageIds = getUsedLanguageIds(userRoles);

    if (
      !areLanguageRolesEnabled &&
      [...usedLanguageIds].filter((id) => id !== roleBuilderAnyLanguageOptionId).length > 0
    ) {
      return `Assign a single role to “${roleBuilderAnyLanguageOptionName}” to save your changes.`;
    }

    const allRowsHaveLanguages = userRoles.every((userRole) => !!userRole.languageIds.length);
    if (!allRowsHaveLanguages) {
      return 'Please assign languages to all roles.';
    }

    return undefined;
  },
);
