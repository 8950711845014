export interface IProjectServerModel {
  readonly projectGuid: Uuid;
  readonly projectName: string;
  readonly subscriptionId: Uuid;
  readonly deactivatedAt?: DateTimeStamp;
  readonly activatedAt?: DateTimeStamp;
}

export interface INewProjectServerModel {
  readonly projectName: string;
  readonly subscriptionId: Uuid;
  readonly projectLocationId: string;
}

export enum CopyType {
  Clone = 'Clone',
  Template = 'Template',
}

export interface ICopyProjectServerModel {
  readonly projectName: string;
  readonly destinationSubscriptionId: Uuid;
  readonly destinationLocationId: string;
  readonly includeContent: boolean;
  readonly copyType: CopyType;
}
