import { Collection } from '@kontent-ai/utils';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { InitialSelectedCollectionData } from '../actions/thunks/prepareNewContentItemDialog.ts';
import { CollectionSection } from '../constants/collectionSection.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { getAvailableCollections } from '../utils/getAvailableCollections.ts';

const _getInitialSelectedCollectionData = (
  state: IStore,
  origin: NewContentItemOrigin,
  languageId: Uuid,
  defaultCollectionId: Uuid | null,
): InitialSelectedCollectionData => {
  const collections = getAvailableCollections(origin, state, languageId);
  const firstCollectionId = collections[0]?.id;

  if (collections.length === 1 && firstCollectionId) {
    return {
      collectionId: firstCollectionId,
      collectionSection: CollectionSection.AllCollections,
    };
  }

  const collectionId = collections.find((collection) => collection.id === defaultCollectionId)
    ? defaultCollectionId
    : null;

  return {
    collectionId,
    collectionSection: collectionId ? CollectionSection.AllCollections : CollectionSection.None,
  };
};

export const getInitialSelectedCollectionData = (
  state: IStore,
  origin: NewContentItemOrigin,
  languageId: Uuid,
): InitialSelectedCollectionData =>
  _getInitialSelectedCollectionData(state, origin, languageId, null);

export const getInitialSelectedCollectionDataForListing = (
  state: IStore,
  origin: NewContentItemOrigin,
  languageId: Uuid,
): InitialSelectedCollectionData => {
  const { selectedCollectionsNodes } = state.contentApp.listingUi.filter;

  const defaultId =
    areCollectionsVisibleForCurrentUser(state) && selectedCollectionsNodes.size === 1
      ? (Collection.getValues(selectedCollectionsNodes)[0] ?? null)
      : null;

  return _getInitialSelectedCollectionData(state, origin, languageId, defaultId);
};

export const getInitialSelectedCollectionDataForEditing = (
  state: IStore,
  origin: NewContentItemOrigin,
  languageId: Uuid,
): InitialSelectedCollectionData => {
  const defaultId = state.contentApp.editedContentItem?.collectionId || null;

  return _getInitialSelectedCollectionData(state, origin, languageId, defaultId);
};
