import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Callout, CalloutType } from './Callout.tsx';

type WarningProps = {
  readonly hideTitle?: boolean;
  readonly subtitle?: string;
};

export const Warning: React.FC<React.PropsWithChildren<WarningProps>> = ({
  children,
  hideTitle,
  subtitle,
}) => (
  <Callout
    type={CalloutType.Warning}
    iconName={IconName.ExclamationTriangle}
    subtitle={subtitle}
    title={hideTitle ? undefined : 'Friendly warning'}
    uiElement={DataUiElement.CalloutWarning}
  >
    {children}
  </Callout>
);

Warning.displayName = 'Warning';
