import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Data_PlansForSelection_Success } from '../../../constants/dataActionTypes.ts';

const initialState: Immutable.Set<Uuid> = Immutable.Set();

export function idsForSelection(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case Data_PlansForSelection_Success: {
      return Immutable.Set(action.payload.data.map((plan) => plan.planId));
    }

    default:
      return state;
  }
}
