import { isElement } from '@kontent-ai/DOM';
import { Spacing, spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { usePrevious } from '@kontent-ai/hooks';
import { Direction } from '@kontent-ai/types';
import { TippyProps } from '@tippyjs/react';
import React, { useCallback, useEffect, useState } from 'react';
import { arrowSize } from '../../../../../../component-library/components/Dialogs/Popover/tokens.ts';
import { IAdjustTippyOptions } from '../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import {
  CustomBlockSleeveAfterClassname,
  CustomBlockSleeveBeforeClassname,
  EmptyTextBlockClassName,
  FirstBlockClassName,
  LastBlockClassName,
  ListItemClassName,
  TopLevelBlockClassName,
  UnstyledClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';

export const targetLineWidth = 3;

// If there is no padding of the content / editor itself
// we use extra space to place the target a bit outside of the editor for better look and feel
const extraOffsetAtContentEdge = Spacing.M / 2;
const defaultBlockSpacing = Spacing.XL;
const spacingBetweenListItems = Spacing.L;

function getTargetOffsetBefore(targetBlockElement: HTMLElement): number {
  const offsetToTopOfTargetBlock = targetBlockElement.getBoundingClientRect().height / 2;

  const isFirstBlock = targetBlockElement.classList.contains(FirstBlockClassName);
  if (isFirstBlock) {
    const paddingTop = Number.parseInt(getComputedStyle(targetBlockElement).paddingTop, 10);
    const offsetToEdgeOfContent = offsetToTopOfTargetBlock + extraOffsetAtContentEdge - paddingTop;
    return offsetToEdgeOfContent;
  }

  const isCustomBlockSleeveAfter = targetBlockElement.classList.contains(
    CustomBlockSleeveAfterClassname,
  );
  if (isCustomBlockSleeveAfter) {
    // Custom block sleeve after doesn't have any target place before, we always position it after it
    return -getTargetOffsetAfter(targetBlockElement);
  }

  const isCustomBlockSleeveBefore = targetBlockElement.classList.contains(
    CustomBlockSleeveBeforeClassname,
  );
  if (isCustomBlockSleeveBefore) {
    const offsetToMiddleOfCustomBlockSleeve = 0;
    return offsetToMiddleOfCustomBlockSleeve;
  }

  const previousBlockElement = targetBlockElement.previousSibling;
  if (
    isElement(previousBlockElement) &&
    previousBlockElement.classList.contains(CustomBlockSleeveBeforeClassname)
  ) {
    const halfHeightOfSleeveBefore = previousBlockElement.getBoundingClientRect().height / 2;
    const offsetToMiddleOfPrecedingSleeveBefore =
      offsetToTopOfTargetBlock + halfHeightOfSleeveBefore;
    return offsetToMiddleOfPrecedingSleeveBefore + getTargetOffsetBefore(previousBlockElement);
  }

  const isSpaceBetweenListItems =
    targetBlockElement.classList.contains(ListItemClassName) &&
    isElement(previousBlockElement) &&
    previousBlockElement.classList.contains(ListItemClassName);
  const spaceBetweenBlocks = isSpaceBetweenListItems
    ? spacingBetweenListItems
    : defaultBlockSpacing;

  // The block spacing is already included in the block padding, we need to subtract it
  const offsetToMiddleOfSpaceBetweenBlocks = offsetToTopOfTargetBlock - spaceBetweenBlocks / 2;
  return offsetToMiddleOfSpaceBetweenBlocks;
}

function getTargetOffsetAfter(targetBlockElement: HTMLElement): number {
  const offsetToBottomOfTargetBlock = targetBlockElement.getBoundingClientRect().height / 2;

  const isLastBlock = targetBlockElement.classList.contains(LastBlockClassName);
  if (isLastBlock) {
    const paddingBottom = Number.parseInt(getComputedStyle(targetBlockElement).paddingBottom, 10);
    const offsetToEdgeOfContent =
      offsetToBottomOfTargetBlock + extraOffsetAtContentEdge - paddingBottom;
    return offsetToEdgeOfContent;
  }

  const isCustomBlockSleeveBefore = targetBlockElement.classList.contains(
    CustomBlockSleeveBeforeClassname,
  );
  if (isCustomBlockSleeveBefore) {
    const offsetToMiddleOfCustomBlockSleeve = 0;
    return offsetToMiddleOfCustomBlockSleeve;
  }

  const nextBlockElement = targetBlockElement.nextSibling;
  if (
    isElement(nextBlockElement) &&
    nextBlockElement.classList.contains(CustomBlockSleeveBeforeClassname)
  ) {
    const offsetToMiddleOfAdjacentSleeveBefore =
      offsetToBottomOfTargetBlock + nextBlockElement.getBoundingClientRect().height / 2;
    return offsetToMiddleOfAdjacentSleeveBefore;
  }

  if (
    isElement(nextBlockElement) &&
    nextBlockElement.classList.contains(CustomBlockSleeveAfterClassname)
  ) {
    const halfHeightOfNextBlock = nextBlockElement.getBoundingClientRect().height / 2;
    const offsetToMiddleOfSleeveAfter = offsetToBottomOfTargetBlock - halfHeightOfNextBlock;
    return offsetToMiddleOfSleeveAfter + getTargetOffsetAfter(nextBlockElement);
  }

  const isSpaceBetweenListItems =
    targetBlockElement.classList.contains(ListItemClassName) &&
    isElement(nextBlockElement) &&
    nextBlockElement.classList.contains(ListItemClassName);
  const spaceBetweenBlocks = isSpaceBetweenListItems
    ? spacingBetweenListItems
    : defaultBlockSpacing;

  // The block spacing belongs to the padding of the next block, we need to add it
  const offsetToMiddleOfSpaceBetweenBlocks = offsetToBottomOfTargetBlock + spaceBetweenBlocks / 2;
  return offsetToMiddleOfSpaceBetweenBlocks;
}

type IPreviewAdjustments = {
  readonly adjustTippyOptions: IAdjustTippyOptions;
  readonly containerWidth: number;
  readonly offset: TippyProps['offset'];
  readonly popoverHorizontalDisplacement: number;
  readonly targetHeight: number;
};

export function usePreviewAdjustments(
  contentRef: React.RefObject<HTMLElement>,
  targetBlockElement: HTMLElement,
  popoverRef: React.RefObject<HTMLDivElement>,
  direction: Direction,
): IPreviewAdjustments {
  const [isPositioned, setIsPositioned] = useState(false);
  const [popoverVerticalOffset, setPopoverVerticalOffset] = useState(0);
  const [popoverHorizontalOffset, setPopoverHorizontalOffset] = useState(0);
  const [popoverHorizontalDisplacement, setPopoverHorizontalDisplacement] = useState(0);
  const [targetHeight, setTargetHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const previousDirection = usePrevious(direction);
  const previousTargetBlockElement = usePrevious(targetBlockElement);

  useEffect(() => {
    if (previousDirection !== direction || previousTargetBlockElement !== targetBlockElement) {
      setIsPositioned(false);
    }
  }, [previousDirection, direction, previousTargetBlockElement, targetBlockElement]);

  const adjustPreview = useCallback(() => {
    const contentElement = contentRef.current;
    const popoverElement = popoverRef.current;

    if (!targetBlockElement || !contentElement) {
      return;
    }

    const contentRect = contentElement.getBoundingClientRect();
    const middleOfContent = contentRect.top + contentRect.height / 2;

    const targetRect = targetBlockElement.getBoundingClientRect();
    const middleOfTarget = targetRect.top + targetRect.height / 2;

    const offsetToMiddleOfTarget = middleOfTarget - middleOfContent;

    // We start in the middle of the target block which is fine for the extra drop targets
    const isTargetEmptyContent =
      targetBlockElement.classList.contains(EmptyTextBlockClassName) &&
      targetBlockElement.classList.contains(FirstBlockClassName) &&
      targetBlockElement.classList.contains(LastBlockClassName) &&
      targetBlockElement.classList.contains(UnstyledClassName);

    const targetBlockHeight = targetRect.height;
    const halfTargetBlockHeight = targetBlockHeight / 2;

    if (isTargetEmptyContent) {
      const isTargetTopLevelBlock = targetBlockElement.classList.contains(TopLevelBlockClassName);
      if (isTargetTopLevelBlock) {
        // In an empty editor, place it at the start (just before the placeholder)
        setPopoverVerticalOffset(offsetToMiddleOfTarget - halfTargetBlockHeight);
        setTargetHeight(0);
      } else {
        // In an empty table cell, position the target to the middle of it (do not adjust the position)
        // and make the target covering the whole table cell
        setPopoverVerticalOffset(offsetToMiddleOfTarget);
        setTargetHeight(targetBlockHeight);
      }
    } else {
      // In the other cases place it at the side of the block / between blocks
      if (direction === Direction.Backward) {
        const targetOffsetBefore = getTargetOffsetBefore(targetBlockElement);
        setPopoverVerticalOffset(offsetToMiddleOfTarget - targetOffsetBefore);
      } else {
        const targetOffsetAfter = getTargetOffsetAfter(targetBlockElement);
        setPopoverVerticalOffset(offsetToMiddleOfTarget + targetOffsetAfter);
      }
      setTargetHeight(0);
    }

    const blockListElement = targetBlockElement.parentElement;
    if (!blockListElement) {
      return;
    }

    const blockListRect = blockListElement.getBoundingClientRect();

    setPopoverHorizontalOffset(contentRect.left - blockListRect.left);
    setContainerWidth(blockListRect.width);

    if (popoverElement) {
      const popoverPointingTo =
        popoverElement.getBoundingClientRect().right + arrowSize / 2 + spacingPopupDistance;
      const desiredPointingTo = blockListRect.left;
      setPopoverHorizontalDisplacement(Math.max(0, popoverPointingTo - desiredPointingTo));
    }
  }, [direction, targetBlockElement, contentRef, popoverRef]);

  const previousIsPositioned = usePrevious(isPositioned);
  useEffect(() => {
    if (previousIsPositioned !== isPositioned) {
      adjustPreview();
    }
  }, [adjustPreview, previousIsPositioned, isPositioned]);

  const addDetectIsPositioned: IAdjustTippyOptions = useCallback(
    (tippyProps) => ({
      ...tippyProps,
      onMount: (instance) =>
        instance.setProps({
          onAfterUpdate: () => setIsPositioned(true),
        }),
    }),
    [],
  );

  return {
    adjustTippyOptions: addDetectIsPositioned,
    containerWidth,
    offset: [popoverVerticalOffset, popoverHorizontalOffset + spacingPopupDistance + arrowSize / 2],
    popoverHorizontalDisplacement,
    targetHeight,
  };
}
