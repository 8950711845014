import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import {
  colorBackground,
  colorBackgroundHoverInverse,
  colorBackgroundInverse,
  colorBorderLowEmphasis,
  colorIconDefaultInverse,
  colorTextDefault,
  colorTextDefaultInverse,
} from '../../../tokens/decision/colors.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { IconWrapper } from '../../Icons/IconWrapper.tsx';
import { FakeInput } from '../../Input/components/FakeInput.tsx';
import { InputControl } from '../../Input/components/InputControl.tsx';
import { InputField } from '../../Input/components/InputField.tsx';

export const StyledStatusBarLanguageSelectorWrapper = styled(Box).attrs({
  width: 260,
  minWidth: 0,
})`
  & ${InputField} {
    background-color: ${colorBackgroundInverse};
    transition: background-color ${transition250};
    transition-property: background-color, background;
  }
  
  & ${InputField}:hover {
    background-color: ${colorBackgroundHoverInverse};
  }

  & ${FakeInput},
  & ${InputField}:hover ${FakeInput} {
    border-color: ${colorBorderLowEmphasis};
    transition: border-color ${transition250};
  }

  & ${InputField}:focus-within {
    background-color: ${colorBackground};
  }
  
  & ${InputField}:not(:focus-within) ${IconWrapper} {
    color: ${colorIconDefaultInverse};
    transition: color ${transition250};
  }
  
  & ${InputControl} {
    color: ${colorTextDefaultInverse};
    transition: color ${transition250};
  }
  
  & ${InputControl}:focus-within {
    color: ${colorTextDefault};
  }
`;
