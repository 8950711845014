import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  AssetLibrary_Query_Changed,
  AssetLibrary_Query_FilterCleared,
  AssetLibrary_Reloading_Started,
  AssetLibrary_Route_Left,
} from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import {
  AssetSelector_Closed,
  AssetSelector_Opened,
} from '../../../../applications/contentInventory/assets/features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import {
  Data_AssetListing_Started,
  Data_AssetListing_Success,
} from '../../../constants/dataActionTypes.ts';

export const loadingStatus = (
  state = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case AssetLibrary_Reloading_Started:
    case AssetSelector_Opened:
    case Shared_CurrentProjectId_Updated:
    case AssetSelector_Closed:
    case AssetLibrary_Route_Left:
      return LoadingStatus.InitialEmpty;

    case Data_AssetListing_Success:
      return LoadingStatus.Loaded;

    case Data_AssetListing_Started:
    case AssetLibrary_Query_Changed:
    case AssetLibrary_Query_FilterCleared:
      return LoadingStatus.Loading;

    default:
      return state;
  }
};
