import React, { useEffect } from 'react';
import { Redirect, RedirectProps, Route, Switch } from 'react-router';
import { AppNames } from '../../_shared/constants/applicationNames.ts';
import {
  ActiveProjectsRoute,
  ArchivedProjectsRoute,
  ProjectsRoute,
} from '../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../_shared/hooks/useDispatch.ts';
import { projectAppRouteEntered, projectAppRouteLeft } from './actions/projectsActions.ts';
import { MyProjects } from './containers/MyProjects.tsx';

const ProjectsApp: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(projectAppRouteEntered());

    return () => {
      dispatch(projectAppRouteLeft());
    };
  }, []);

  return <>{children}</>;
};

export const getProjectsRoutes = (
  getRedirectPropsWithSameSearch: (redirectProps: RedirectProps) => RedirectProps,
) => {
  return (
    <Route key={AppNames.ProjectSettings} path={ProjectsRoute}>
      <ProjectsApp>
        <Switch>
          <Route exact path={ActiveProjectsRoute}>
            <MyProjects projectsToShow="active" />
          </Route>
          <Route exact path={ArchivedProjectsRoute}>
            <MyProjects projectsToShow="archived" />
          </Route>
          <Route>
            <Redirect {...getRedirectPropsWithSameSearch({ to: ActiveProjectsRoute })} />
          </Route>
        </Switch>
      </ProjectsApp>
    </Route>
  );
};
