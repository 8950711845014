import { ToolbarButtonProps } from '../../toolbars/utils/toolbarUtils.ts';

export const getTextBlockTypeButtonTooltip = (
  textAllowed: boolean,
  selectedItemProps: ToolbarButtonProps | null,
) => {
  if (!textAllowed) {
    return 'Text is not allowed here';
  }

  return selectedItemProps?.isViolated || selectedItemProps?.disabled
    ? (selectedItemProps.tooltipText ?? 'Adjust text formatting')
    : 'Adjust text formatting';
};
