import React, { useMemo } from 'react';

type ActiveInstructionContextState = {
  readonly activeInstructionEntityKey: string | null;
};

const defaultContext: ActiveInstructionContextState = {
  activeInstructionEntityKey: null,
};

export const ActiveInstructionContext =
  React.createContext<ActiveInstructionContextState>(defaultContext);

type ActiveInstructionContextProviderProps = {
  readonly children: React.ReactNode;
  readonly activeInstructionEntityKey: string | null;
};

export const ActiveInstructionContextProvider: React.FC<ActiveInstructionContextProviderProps> = ({
  children,
  activeInstructionEntityKey,
}) => {
  const contextState = useMemo<ActiveInstructionContextState>(
    () => ({ activeInstructionEntityKey }),
    [activeInstructionEntityKey],
  );

  return (
    <ActiveInstructionContext.Provider value={contextState}>
      {children}
    </ActiveInstructionContext.Provider>
  );
};

ActiveInstructionContextProvider.displayName = 'ActiveInstructionContextProvider';
