import { Action } from '../../../../../../@types/Action.type.ts';
import { Shared_ModalDismissed } from '../../../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingStarted,
} from '../../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeModal_InitFinished,
  ContentEditing_CascadeModal_InitStarted,
  ContentEditing_CascadeModal_PublishingStarted,
  ContentEditing_CascadeModal_SchedulingStarted,
} from '../../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { ContentItemEditing_NewVariant_Started } from '../../../../features/NewVariantWorkflowSelectionDialog/constants/newVariantActionTypes.ts';

const initialState = LoadingStatus.InitialEmpty;

export const loadingStatus = (
  state = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case ContentEditing_CascadeModal_InitStarted:
      return LoadingStatus.Loading;

    case ContentEditing_CascadeModal_InitFinished:
      return LoadingStatus.Loaded;

    case Shared_ModalDismissed:
    case ContentItemEditing_NewVariant_Started:
    case Content_Editing_PublishContentItemVariantStarted:
    case Content_Editing_ScheduledPublishingStarted:
    case ContentEditing_CascadeModal_PublishingStarted:
    case ContentEditing_CascadeModal_SchedulingStarted:
      return initialState;

    default:
      return state;
  }
};
