import {
  BaseColor,
  Spacing,
  Typography,
  colorTextDefault,
  colorTextHint,
  px,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

type CellTileProps = {
  readonly isLoaded: boolean;
  readonly isToday: boolean;
  readonly isOutsideMonthRange: boolean;
};

export const StyledCellTitle = styled.span<CellTileProps>`
  ${Typography.HeadlineMedium};
  display: block;
  color: ${colorTextDefault};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ isOutsideMonthRange }) =>
    isOutsideMonthRange &&
    css`
      color: ${colorTextHint};
  `};
  
  ${({ isLoaded }) =>
    !isLoaded &&
    css`
    color: ${BaseColor.Gray30};
  `};
  
  ${({ isToday, isLoaded }) =>
    isToday &&
    css`
    &:after {
      color: ${isLoaded ? BaseColor.Gray50 : BaseColor.Gray30};
      margin-left: ${px(Spacing.S)};
      content: "Today";
    }
  `};
`;
