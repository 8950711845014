export const activationFailed =
  'We couldn’t activate the user. Please try again. If this keeps happening, let us know.';
export const deactivationFailed =
  'We couldn’t deactivate the user. Please try again. If this keeps happening, let us know.';
export const inviteFailed =
  'We couldn’t send the invitation. Please try again. If this keeps happening, let us know.';
export const revokeFailed =
  'We couldn’t revoke the invitation. Please try again. If this keeps happening, let us know.';
export const subscriptionUsersUserDetailFailed =
  'The URL you used to access this user’s settings is invalid. Check for typos and try again.';
