import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing, Typography, colorAlertIcon } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Widget } from './Widget.tsx';

type WidgetErrorStateProps = Readonly<{
  isInline?: boolean;
}>;

export const WidgetErrorState: React.FC<WidgetErrorStateProps> = ({ isInline }) => (
  <Widget.Body>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={isInline ? 'start' : 'center'}
      alignItems="center"
      minHeight={isInline ? undefined : 200}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={isInline ? 'row' : 'column'}
        gap={Spacing.L}
      >
        <Icons.TimesCircle height={48} width={48} color={colorAlertIcon} />
        <Box typography={Typography.HeadlineLarge}>Oops, something went wrong</Box>
      </Box>
    </Box>
  </Widget.Body>
);

WidgetErrorState.displayName = 'WidgetErrorState';
