import { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLayoutSynchronizationUpdateTick } from '../contexts/LayoutSynchronizationContext.tsx';
import { IntersectionObserverRootContext } from './IntersectionObserverRootContext.tsx';

type IsIntersectingCallback = (entry: IntersectionObserverEntry | undefined) => boolean;

type UseIntersectionObserverParams<TInstance extends HTMLElement> = {
  readonly targetRef: RefObject<TInstance>;
  readonly isIntersectingCallback: IsIntersectingCallback;
  readonly options?: IntersectionObserverInit;
};

const defaultOptions: IntersectionObserverInit = {
  delay: 50,
};

enum State {
  InSync = 0,
  SetIntersecting = 1,
  SetNotIntersecting = 2,
}

/**
 * Use this hook in situations when you need IntersectionObserver API for observing changes in the intersection of a target element with an ancestor.
 * The hook tries to work with IntersectionObserver v2 API and fallbacks to v1 if unsupported in browser.
 * Receives:
 * - Ref of a target you want to observe.
 * - [Optional] Callback to determine if target element is intersecting with ancestor, or isEntryIntersectingDefaultCallback is used.
 * - [Optional] Options
 * -- rootRef - Root ref of the ancestor. Defaults to the browser viewport if not specified or if null.
 * -- rootMargin - Margin around the root.
 * -- threshold - Either a single number or an array of numbers which indicate a percentage of the target's visibility. Default is 0.
 * -- trackVisibility - v2 API param indicating whether the observer will track changes in a target's visibility. Default is true.
 * -- delay - v2 API param indicating minimum delay in milliseconds between notifications from the observer. Default is 100.
 * Returns:
 * - Boolean - result of the intersection callback.
 */
export const useIntersectionObserver = <TInstance extends HTMLElement>({
  targetRef,
  isIntersectingCallback,
  options,
}: UseIntersectionObserverParams<TInstance>): boolean => {
  const { root: contextRoot } = useContext(IntersectionObserverRootContext);

  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  // We keep ref to the current state as well to be able to query it in update scheduling and do not have to recreate the observer
  const isIntersectingRef = useRef(false);

  const updateIsIntersecting = useCallback((newIsIntersecting: boolean) => {
    setIsIntersecting(newIsIntersecting);
    isIntersectingRef.current = newIsIntersecting;
    pendingState.current = State.InSync;
  }, []);

  const pendingState = useRef(State.InSync);
  const update = useCallback(() => {
    switch (pendingState.current) {
      case State.SetIntersecting: {
        updateIsIntersecting(true);
        break;
      }

      case State.SetNotIntersecting: {
        updateIsIntersecting(false);
        break;
      }

      default:
        break;
    }
  }, [updateIsIntersecting]);
  useLayoutSynchronizationUpdateTick(update);

  const observer = useRef<IntersectionObserver | null>(null);

  // We need to spread the options to prevent recreating the observer upon every render
  const { delay: delayProp, root, rootMargin, threshold, trackVisibility } = options ?? {};

  useEffect(() => {
    const initOptions: IntersectionObserverInit = {
      delay: delayProp ?? defaultOptions.delay,
      root: root ?? contextRoot ?? defaultOptions.root,
      rootMargin: rootMargin ?? defaultOptions.rootMargin,
      threshold: threshold ?? defaultOptions.threshold,
      trackVisibility: trackVisibility ?? defaultOptions.trackVisibility,
    };

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry?.rootBounds) {
        // When dragging, all sizes on the first call on elements which changed order are zero, if we wouldn't ignore this
        // the intersection would return false and the content got hidden until the intersection observer fires again (crossing the root boundary)
        if (entry.rootBounds.width === 0 && entry.rootBounds.height === 0) {
          return;
        }
        const newIsIntersecting = isIntersectingCallback(entry);
        if (newIsIntersecting === isIntersectingRef.current) {
          pendingState.current = State.InSync;
        } else {
          pendingState.current = newIsIntersecting
            ? State.SetIntersecting
            : State.SetNotIntersecting;
        }
      }
    }, initOptions);

    const { current: currentObserver } = observer;
    if (targetRef.current) {
      currentObserver.observe(targetRef.current);
    }

    return () => currentObserver.disconnect();
  }, [
    contextRoot,
    delayProp,
    isIntersectingCallback,
    root,
    rootMargin,
    targetRef,
    threshold,
    trackVisibility,
  ]);

  return isIntersecting;
};

export const isEntryFullyVisibleCallback: IsIntersectingCallback = (
  entry: IntersectionObserverEntry,
): boolean => {
  if (typeof entry.isVisible === 'boolean') {
    return entry.isIntersecting && entry.isVisible;
  }

  // The browser doesn't support Intersection Observer v2, falling back to v1 behavior.
  return entry.isIntersecting;
};

export const isEntryIntersectingCallback: IsIntersectingCallback = (
  entry: IntersectionObserverEntry,
): boolean => entry.isIntersecting;
