import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ICollectionsRepository } from '../../../../repositories/interfaces/ICollectionsRepository.type.ts';
import { ICollectionsServerResponseModel } from '../../../../repositories/serverModels/CollectionsServerModel.type.ts';
import {
  Data_Collections_Failed,
  Data_Collections_Started,
  Data_Collections_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly collectionsRepository: Pick<ICollectionsRepository, 'get'>;
}

const start = () => ({ type: Data_Collections_Started }) as const;
const success = (collections: ICollectionsServerResponseModel) =>
  ({
    type: Data_Collections_Success,
    payload: collections,
  }) as const;
const failure = () => ({ type: Data_Collections_Failed }) as const;

export type LoadCollectionsActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadCollectionsAction =
  ({ collectionsRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const collectionsServerModel = await collectionsRepository.get(abortSignal);
      dispatch(success(collectionsServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
