import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../_shared/components/NotificationBar.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

const subscriptionColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--7',
  },
  {
    columnName: 'Admins',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Plan',
    className: 'data-table__column--5',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Projects',
    className: 'data-table__column--2',
    orderBy: OrderByDirection.None,
  },
]);

export type SubscriptionListingItem = {
  readonly adminNames: ReadonlyArray<string>;
  readonly id: Uuid;
  readonly isCanceled: boolean;
  readonly isExpired: boolean;
  readonly isSuspended: boolean;
  readonly name: string;
  readonly numberOfProjects: number;
  readonly path: string;
  readonly planDisplayName: string;
};

type SubscriptionListingProps = {
  readonly areAllProjectSubscriptionsCanceled: boolean;
  readonly listingSubscriptions: ReadonlyArray<SubscriptionListingItem>;
  readonly onShowCreateSubscriptionModal: () => void;
  readonly showCreateNewSubscriptionSuccessMessage: boolean;
};

export const SubscriptionListing: React.FC<SubscriptionListingProps> = ({
  areAllProjectSubscriptionsCanceled,
  listingSubscriptions,
  onShowCreateSubscriptionModal,
  showCreateNewSubscriptionSuccessMessage,
}) => {
  if (listingSubscriptions.length === 0 && !areAllProjectSubscriptionsCanceled) {
    return <Loader />;
  }

  const subscriptionsTable = (
    <DataTable
      dataUiCollectionName={DataUiCollection.Subscriptions}
      onCreateNewItem={onShowCreateSubscriptionModal}
      title={
        <ListingMessage
          statusInfoMessage={{
            text: AppNames.Subscriptions,
            style: StatusMessageStyle.Headline,
          }}
        />
      }
      header={<DataTableHeadRow columns={subscriptionColumns} />}
    >
      {listingSubscriptions.map((subscription: SubscriptionListingItem) => (
        <DataTableRow
          id={subscription.id}
          key={subscription.id}
          dataUiObjectName={subscription.name}
        >
          <LinkDataTableCell
            linkPath={subscription.path}
            focusableRowLinkAriaLabel={`visit subscription — ${subscription.name}`}
          >
            <span className="subscription-data-table-item__name">{subscription.name}</span>
            {subscription.isCanceled && <Tag color={TagColor.Gray}>Canceled</Tag>}
            {subscription.isExpired && <Tag color={TagColor.Red}>Expired</Tag>}
            {subscription.isSuspended && <Tag color={TagColor.Pink}>Suspended</Tag>}
          </LinkDataTableCell>
          <LinkDataTableCell isExpandable linkPath={subscription.path}>
            {subscription.adminNames.join(', ')}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={subscription.path}>
            {subscription.planDisplayName}
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={subscription.path}>
            {subscription.numberOfProjects}
          </LinkDataTableCell>
        </DataTableRow>
      ))}
    </DataTable>
  );

  return (
    <>
      {showCreateNewSubscriptionSuccessMessage && (
        <div className="canvas__notifications">
          <NotificationBar
            message="Your new subscription has been created successfully."
            type={NotificationBarType.Success}
          />
        </div>
      )}
      <div className="subscription-listing">
        <div
          className="canvas__content-pane canvas__content-pane--is-not-project-specific subscription-pane"
          data-hj-suppress=""
        >
          <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
            {subscriptionsTable}
          </div>
        </div>
      </div>
    </>
  );
};

SubscriptionListing.displayName = 'SubscriptionListing';
