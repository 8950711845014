import React, { ReactNode } from 'react';
import { Box } from '../../layout/Box/Box.tsx';
import { gridUnit } from '../../tokens/quarks/spacing.ts';
import { VerticalTabsItem } from './components/VerticalTabsItem.tsx';
import { VerticalTabsList } from './components/VerticalTabsList.tsx';
import { VerticalTabsSidebar } from './components/VerticalTabsSidebar.tsx';

type Props = {
  readonly children: ReactNode;
};

const VerticalTabsWithRef = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...otherProps }, forwardedRef) => (
    <Box ref={forwardedRef} width={19 * gridUnit} display="flex">
      <VerticalTabsSidebar {...otherProps}>
        <VerticalTabsList>{children}</VerticalTabsList>
      </VerticalTabsSidebar>
    </Box>
  ),
);

VerticalTabsWithRef.displayName = 'VerticalTabs';

const verticalTabsComposition = {
  Item: VerticalTabsItem,
} as const;

export const VerticalTabs = Object.assign(VerticalTabsWithRef, verticalTabsComposition);
