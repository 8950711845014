import { Collection, alphabetically } from '@kontent-ai/utils';
import { useEffect, useMemo } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  createNewRoleLink,
  createRoleEditingLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { onRoleListingLeft } from '../actions/rolesActions.ts';
import { initRoleListing, restoreDeletedRole } from '../actions/thunkRolesActions.ts';
import { RoleListing as RoleListingComponent } from '../components/RoleListing.tsx';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { hasRole } from '../selectors/isRoleUsed.ts';
import { canCreateNewCustomRole } from '../utils/customRolesUtils.ts';

type Props = {
  readonly projectId: Uuid;
  readonly withSubscriptionIdInRoute?: Uuid;
};

export const RoleListing = (props: Props) => {
  const [isInitThunkDone] = useThunkPromise(initRoleListing);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(onRoleListingLeft());
    };
  }, []);

  const rolesById = useSelector((s) => s.data.roles.rolesById);
  const usersById = useSelector((s) => s.data.users.usersById);

  const roleItems = useMemo(
    () =>
      [...rolesById.values()]
        .map((role) => ({
          id: role.id,
          name: role.name,
          isDefaultRole: role.isReadonly,
          userCount: Collection.filter(usersById, hasRole(role.id)).size,
          roleEditorPath: createRoleEditingLink({
            projectId: props.projectId,
            subscriptionId: props.withSubscriptionIdInRoute,
            roleId: role.id,
          }),
        }))
        .sort((a, b) => alphabetically(a.name, b.name)),
    [props.projectId, props.withSubscriptionIdInRoute, rolesById, usersById],
  );

  const canCreateNewRole = useSelector((s) => {
    const limitInfo = getCustomRolesLimitInfo(s);
    return canCreateNewCustomRole(limitInfo);
  });

  const createNewRolePath = createNewRoleLink({
    projectId: props.projectId,
    subscriptionId: props.withSubscriptionIdInRoute,
  });

  const operationStatus = useSelector((s) => s.rolesApp.listingUi.operationStatus);

  const allSet = useSelector(
    (s) => isInitThunkDone && s.rolesApp.listingUi.loadingStatus === LoadingStatus.Loaded,
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <RoleListingComponent
      onRenewDeletedRole={() => dispatch(restoreDeletedRole())}
      roleItems={roleItems}
      createNewRolePath={createNewRolePath}
      canCreateNewRole={canCreateNewRole}
      operationStatus={operationStatus}
    />
  );
};
