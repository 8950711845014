import { Action } from '../../../@types/Action.type.ts';
import {
  Shared_Browser_IsOffline,
  Shared_Browser_IsOnline,
} from '../../constants/sharedActionTypes.ts';

export function isOnline(state: boolean = true, action: Action): boolean {
  switch (action.type) {
    case Shared_Browser_IsOffline:
      return false;

    case Shared_Browser_IsOnline:
      return true;

    default:
      return state;
  }
}
