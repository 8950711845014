import { cardBorderRadius } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { TabView } from '@kontent-ai/component-library/TabView';
import {
  Size,
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  gridUnit,
  px,
  spacingCard,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled, { css } from 'styled-components';
import { IResourceItem, resources } from '../../data/resources.ts';

export interface IQuickstartSdkSectionProps {
  readonly renderContent: (item: IResourceItem) => JSX.Element;
  readonly title: string;
}

const Logo = styled.i`
  height: ${px(gridUnit * 2)};
  max-height: ${px(Size.S)};
`;

const tabPanelStyle = css`
  padding: ${px(spacingCard)};
  border: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
  border-top: none;
  border-radius: 0 0 ${px(cardBorderRadius)} ${px(cardBorderRadius)};
`;

export const QuickstartSdkSection: React.FC<IQuickstartSdkSectionProps> = ({
  renderContent,
  title,
}) => {
  if (!resources.length) {
    return null;
  }

  return (
    <Stack spacing={Spacing.L}>
      <div className="quickstart__section-subtitle">{title}</div>
      <TabView
        items={resources.map((resource) => ({
          label: resource.tabName,
          id: resource.resourceType,
          leadingElement: <Logo className={resource.logoClass} />,
        }))}
      >
        <TabView.TabGroup />
        <TabView.TabPanel css={`${tabPanelStyle}`}>
          {(activeTabId) => {
            const selectedResource = resources.find((item) => item.resourceType === activeTabId);
            return selectedResource && renderContent(selectedResource);
          }}
        </TabView.TabPanel>
      </TabView>
    </Stack>
  );
};

QuickstartSdkSection.displayName = 'QuickstartSdkSection';
