import { ReactNode } from 'react';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AuthorizedSection as AuthorizedSectionComponent } from '../../components/routing/AuthorizedSection.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { Capability, getUserCapability } from '../../utils/permissions/capability.ts';

type Props = {
  readonly appName: string;
  readonly requiresOneOfCapabilities: ReadonlyArray<Capability>;
  readonly children: ReactNode;
};

export const AuthorizedSection = (props: Props) => {
  const { requiresOneOfCapabilities, ...restProps } = props;

  const currentProject = useSelector(getCurrentProject);
  const isAuthorized = getUserCapability(currentProject).canOneOf(requiresOneOfCapabilities);

  return (
    <AuthorizedSectionComponent
      projectId={currentProject.projectId}
      isAuthorized={isAuthorized}
      {...restProps}
    />
  );
};
