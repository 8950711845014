import { scrollToView } from '@kontent-ai/DOM';
import { RefObject, useLayoutEffect, useRef } from 'react';

export const useScrollIntoViewOnMount = <T extends HTMLElement>(): RefObject<T> => {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      scrollToView(ref.current);
    }
  }, []);

  return ref;
};
