import React from 'react';
import { SampleAppContent } from '../../containers/SampleAppContent.tsx';
import { IResourceItem } from '../../data/resources.ts';
import { QuickstartSdkSection } from '../shared/QuickstartSdkSection.tsx';

export const SampleProjectSdkSection: React.FC = () => (
  <QuickstartSdkSection
    renderContent={(item: IResourceItem) => <SampleAppContent item={item} />}
    title="Build with your stack using our SDKs"
  />
);

SampleProjectSdkSection.displayName = 'SampleProjectSdkSection';
