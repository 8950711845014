import {
  AnyApiImplementation,
  AnyApiMethods,
  WithoutApiParameter,
} from '../types/Editor.api.type.ts';

export const bindApiMethods = <TApi extends AnyApiImplementation>(
  api: TApi,
): WithoutApiParameter<TApi> => {
  const sealedApi: Mutable<AnyApiMethods> = {};
  for (const methodName in api) {
    if (Object.hasOwn(api, methodName)) {
      const method = api[methodName];
      if (typeof method === 'function') {
        sealedApi[methodName] = method.bind(undefined, sealedApi);
      }
    }
  }

  return sealedApi as WithoutApiParameter<TApi>;
};
