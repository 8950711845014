import { InvariantException } from '@kontent-ai/errors';
import { ContentTypeSnippetTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  IContentTypeSnippetTypeElementData,
  getContentTypeSnippetTypeElementDataFromServerModel,
} from '../../models/elements/ContentTypeSnippetTypeElementData.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';

function convertTypeElementRecordToServerModel(
  typeElement: IContentTypeSnippetTypeElementData,
): ContentTypeSnippetTypeElementServerModel {
  if (typeElement.snippetId === null) {
    throw InvariantException('Content type snippet element should have snippetId property.');
  }
  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    isRequired: false, // always false as it does not make sense for this element
    snippetId: typeElement.snippetId,
    type: ElementType.ContentTypeSnippet,
  };
}

export function createContentTypeSnippetTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (
      serverTypeElement: ContentTypeSnippetTypeElementServerModel,
    ) => getContentTypeSnippetTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IContentTypeSnippetTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
