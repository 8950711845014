export enum WebSpotlightTreeOperationsCheckResult {
  ItemIsPublished = 'ItemIsPublished',
  ItemIsScheduledToPublish = 'ItemIsScheduledToPublish',
  ItemNotTranslated = 'ItemNotTranslated',
  Loading = 'Loading',
  Ok = 'Ok',
  PermissionMissing = 'PermissionMissing',
  Unknown = 'Unknown',
}

export interface IWebSpotlightTreeOperationsCheckServerModel {
  readonly createNewItem: WebSpotlightTreeOperationsCheckResult;
  readonly linkExistingItem: WebSpotlightTreeOperationsCheckResult;
}
