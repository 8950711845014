import React from 'react';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CreateNewSubscriptionModal } from '../../SubscriptionListing/containers/CreateNewSubscriptionModal.tsx';
import { SubscriptionListing } from '../../SubscriptionListing/containers/SubscriptionListing.tsx';

export const SubscriptionManagement: React.FC = (): JSX.Element => (
  <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionListing)}>
    <div className="canvas__workspace">
      <section className="canvas__content">
        <SubscriptionListing />
        <CreateNewSubscriptionModal />
      </section>
    </div>
  </div>
);

SubscriptionManagement.displayName = 'SubscriptionManagement';
