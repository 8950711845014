import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import { activateContentItemEditingAction } from '../../../../actions/contentItemEditingActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { EditingAction } from '../../../../models/EditingAction.ts';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const CopyFromLanguageAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const performAction = compose(
    dispatch,
    trackContentItemEditingAction(
      activateContentItemEditingAction(EditingAction.copyFromLanguage),
      ContentItemEditingEventTypes.CopyFromLanguageSelected,
    ),
  );

  return (
    <EditingActionDynamicDetailProvider {...props} performAction={performAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
