import { Action } from '../../../@types/Action.type.ts';
import { GeneralPropertyServerModel } from '../../../repositories/serverModels/GeneralPropertyServerModel.type.ts';
import { Shared_GeneralProperties_LoadingFinished } from '../../constants/sharedActionTypes.ts';
import {
  GeneralPropertiesModel,
  GeneralPropertiesStateKeysByServerKeys,
  GeneralPropertyServerKey,
} from '../../models/GeneralPropertiesModel.ts';
import { resolvePropertyValue } from '../../utils/generalPropertiesUtils.ts';

const initialState: GeneralPropertiesModel = {
  enableUmuxSurvey: null,
  enableSpacesInCollections: false,
  enableCustomApplications: false,
};

const mapPropertyKeyAndValueToState = (
  state: GeneralPropertiesModel,
  propertyKey: GeneralPropertyServerKey,
  propertyValue: string | null,
): GeneralPropertiesModel => {
  const propName = GeneralPropertiesStateKeysByServerKeys[propertyKey];
  if (!propName) {
    return state;
  }

  return {
    ...state,
    [propName]: resolvePropertyValue(propName, propertyValue),
  };
};

const mapPropertyToState = (
  state: GeneralPropertiesModel,
  property: GeneralPropertyServerModel,
): GeneralPropertiesModel => mapPropertyKeyAndValueToState(state, property.key, property.value);

export const generalProperties = (state = initialState, action: Action): GeneralPropertiesModel => {
  switch (action.type) {
    case Shared_GeneralProperties_LoadingFinished: {
      return action.payload.properties.reduce(mapPropertyToState, initialState);
    }

    default:
      return state;
  }
};
