import classnames from 'classnames';
import React from 'react';
import { getDataUiObjectNameAttribute } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface Props {
  readonly dataUiObjectName: string;
  readonly hasFullWidth?: boolean;
  readonly title: string;
}

export const CapabilityConfigurationCard: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
  dataUiObjectName,
  hasFullWidth,
}) => (
  <div
    className={classnames('role-editor__card', {
      'role-editor__card--has-full-width': hasFullWidth,
    })}
    {...getDataUiObjectNameAttribute(dataUiObjectName)}
  >
    <div className="card__pane">
      <div className="card__content-section">
        <div className="card__title-section">
          <h3 className="card__title">{title}</h3>
        </div>
        {children}
      </div>
    </div>
  </div>
);

CapabilityConfigurationCard.displayName = 'CapabilityConfigurationCard';
