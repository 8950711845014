import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { anyTaxonomyTaxonomy } from '../constants/anyTaxonomyTaxonomy.ts';

export const handleAnyTaxonomyTaxonomyOnTaxonomiesChangedInFormInput = <
  TTaxonomy extends ITaxonomyGroup,
>(
  formValues: ReadonlyArray<TTaxonomy>,
  previousValues: ReadonlyArray<TTaxonomy>,
): ReadonlyArray<TTaxonomy> => {
  const oldValuesContainAnyTaxonomy = previousValues.some(
    (taxonomy) => taxonomy.id === anyTaxonomyTaxonomy.id,
  );
  const newValuesContainAnyTaxonomy = formValues.some(
    (taxonomy) => taxonomy.id === anyTaxonomyTaxonomy.id,
  );
  const newValuesContainNormalTaxonomy = formValues.some(
    (taxonomy) => taxonomy.id !== anyTaxonomyTaxonomy.id,
  );
  return (oldValuesContainAnyTaxonomy && newValuesContainNormalTaxonomy) ||
    !newValuesContainAnyTaxonomy
    ? formValues.filter((taxonomy) => taxonomy.id !== anyTaxonomyTaxonomy.id)
    : formValues.filter((taxonomy) => taxonomy.id === anyTaxonomyTaxonomy.id);
};
