import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ApiKey } from '../../../projectSettings/apiKeys/models/ApiKey.ts';
import { ApiKeyRegenerationFailed } from '../constants/errorMessageTemplates.ts';
import { KeyRegenerationStatus } from '../models/KeyRegenerationStatus.ts';
import { SubscriptionApiKeyInput } from './SubscriptionApiKeyInput.tsx';

type Props = {
  readonly apiKey: ApiKey;
  readonly isLoadingFailed: boolean;
  readonly regenerationStatus: KeyRegenerationStatus;
  readonly onRegenerate: () => void;
};

export const SubscriptionApiKeySection: React.FC<Props> = ({
  apiKey,
  isLoadingFailed,
  regenerationStatus,
  onRegenerate,
}) => {
  if (apiKey.type !== ApiKeyType.SAPI && !isLoadingFailed) {
    return (
      <>
        <Button
          buttonStyle="primary"
          size="small"
          onClick={onRegenerate}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create key
        </Button>
        {regenerationStatus === KeyRegenerationStatus.Failed && (
          <Box marginTop={Spacing.M}>
            <SimpleStatusError label={ApiKeyRegenerationFailed} />
          </Box>
        )}
      </>
    );
  }

  return (
    <SubscriptionApiKeyInput
      apiKey={apiKey}
      isLoadingFailed={isLoadingFailed}
      regenerationStatus={regenerationStatus}
      onRegenerate={onRegenerate}
    />
  );
};

SubscriptionApiKeySection.displayName = 'SubscriptionApiKeySection';
