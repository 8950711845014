import { ReactNode, useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { AccessibleLoader } from '../../_shared/components/AccessibleLoader.tsx';
import { loadCollections, loadLanguages } from '../../data/actions/thunkDataActions.ts';
import {
  getActiveLanguageIds,
  getAllLanguageIds,
} from '../../data/reducers/languages/selectors/getLanguages.ts';
import { getNormalizedRolesWithSettings } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AppNames } from '../constants/applicationNames.ts';
import {
  AccessDeniedRoute,
  AccessDeniedRouteParams,
  AccessLostDueToDeactivatedLanguageRoute,
  EnvironmentAccessDeniedRouteParams,
  EnvironmentRouteParams,
} from '../constants/routePaths.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { useThunkPromise } from '../hooks/useThunkPromise.ts';
import { Capability } from '../utils/permissions/capability.ts';
import { getLanguageIdsWithAllowedCapabilityInAnyCollection } from '../utils/permissions/getLanguageIdsWithAllowedCapability.ts';
import { buildPath } from '../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';

type Props = {
  readonly appName: AppNames;
  readonly children: ReactNode;
};

export const EnsureContentProductionCapabilities = ({ appName, children }: Props) => {
  const [isLoadLanguagesThunkDone] = useThunkPromise(loadLanguages);
  const [isLoadCollectionsThunkDone] = useThunkPromise(loadCollections);
  const isEnsured = isLoadLanguagesThunkDone && isLoadCollectionsThunkDone;

  const { projectId } = useParams<EnvironmentRouteParams>();

  const normalizedRoles = useSelector(getNormalizedRolesWithSettings);
  const allLanguageIds = useSelector((s) => getAllLanguageIds(s.data.languages));
  const collectionsMap = useSelector((s) => s.data.collections.byId);

  const languageIdsWithAllowedView = useMemo(
    () =>
      getLanguageIdsWithAllowedCapabilityInAnyCollection(
        normalizedRoles,
        allLanguageIds,
        Capability.ViewContent,
        collectionsMap,
      ),
    [normalizedRoles, allLanguageIds, collectionsMap],
  );

  const hasFallbackLanguage = useSelector((s) => {
    const activeLanguageIds = getActiveLanguageIds(s.data.languages);
    return activeLanguageIds.some((id: Uuid) => languageIdsWithAllowedView.has(id));
  });

  if (!isEnsured) {
    return <AccessibleLoader screenReaderText={`Loading ${appName}`} />;
  }

  if (languageIdsWithAllowedView.isEmpty()) {
    return (
      <Redirect
        to={
          isUuid(projectId)
            ? buildPath<EnvironmentAccessDeniedRouteParams>(AccessDeniedRoute, {
                requestedAppName: appName,
                projectId,
              })
            : buildPath<AccessDeniedRouteParams>(AccessDeniedRoute, { requestedAppName: appName })
        }
      />
    );
  }

  if (!hasFallbackLanguage) {
    return (
      <Redirect
        to={buildPath<EnvironmentRouteParams>(AccessLostDueToDeactivatedLanguageRoute, {
          projectId,
        })}
      />
    );
  }

  return <>{children}</>;
};
