import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { Notifications } from '../../../../../_shared/services/signalR/signalRClient.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getMin } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { releaseExpiredElementLocks } from '../actions/thunkContentItemEditingActions.ts';
import {
  ElementLocksObserver as ElementLocksObserverComponent,
  IElementLocksObserverDispatchProps,
  IElementLocksObserverStateProps,
} from '../components/ElementLocksObserver.ts';

function getReleaseLockTimeout(
  lockedElements: Notifications['lockedElementsChange']['payload']['lockedElementSessions'],
): number {
  if (!lockedElements.length) {
    return 0;
  }

  const lockWithEarliestExpiration = getMin(lockedElements, (lockedElement) => {
    return Date.parse(lockedElement.validUntil);
  });
  const releaseLockTimeout = Date.parse(lockWithEarliestExpiration.validUntil) - Date.now();

  return releaseLockTimeout > 0 ? releaseLockTimeout : 0;
}

function mapStateToProps(state: IStore): IElementLocksObserverStateProps {
  const {
    contentApp: {
      editorUi: { lockedElements },
    },
  } = state;

  const releaseLockTimeout = getReleaseLockTimeout(lockedElements);

  return {
    hasLockedElements: !!lockedElements.length,
    releaseLockTimeout,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IElementLocksObserverDispatchProps {
  return {
    releaseExpiredElementLocks: () => dispatch(releaseExpiredElementLocks()),
  };
}

export const ElementLocksObserver: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ElementLocksObserverComponent);
