import { memoize } from '@kontent-ai/memoization';
import { Direction } from '@kontent-ai/types';
import { Collection, notNullNorUndefined } from '@kontent-ai/utils';
import { ContentBlock, ContentState } from 'draft-js';
import Immutable from 'immutable';
import {
  BaseBlockType,
  BlockType,
  TextBlockTypes,
  isNestedBlockType,
  isTableContentBlockType,
  isTextBlockType,
  parseBlockType,
} from '../../../utils/blocks/blockType.ts';
import {
  findSiblingBlock,
  isContentComponent,
  isContentModule,
  isCustomBlockSleeve,
  isImage,
  isInTable,
  isOrderedListItem,
  isTableCell,
  isTableCellContent,
  isTopLevelBlock,
  isUnorderedListItem,
  isUnstyledBlock,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../../../utils/blocks/editorBlockGetters.ts';
import { createContent, filterBlocks } from '../../../utils/blocks/editorBlockUtils.ts';
import { setContentSelection } from '../../../utils/editorSelectionUtils.ts';
import { EntityMap, getBlocks, getEntityMap } from '../../../utils/general/editorContentGetters.ts';
import { isContentEmpty } from '../../../utils/general/editorContentUtils.ts';
import {
  getEntities,
  getMetadataForBlockChars,
} from '../../../utils/metadata/editorMetadataUtils.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DraftJSInlineStyle } from '../../inlineStyles/api/inlineStyles.ts';
import { isLink } from '../../links/api/LinkEntity.ts';
import { EditorFeatureLimitations } from './EditorFeatureLimitations.ts';
import { EditorLinkStatus } from './EditorLinkStatus.ts';

// Do not re-order this object alphabetically => it is used to generate sorted messages
export const TopLevelBlockCategoryFeature = {
  Text: 'text',
  Tables: 'tables',
  Images: 'images',
  ComponentsAndItems: 'components-and-items',
} as const;
export type TopLevelBlockCategoryFeature =
  (typeof TopLevelBlockCategoryFeature)[keyof typeof TopLevelBlockCategoryFeature];

export const TableBlockCategoryFeature = {
  Text: TopLevelBlockCategoryFeature.Text,
  Images: TopLevelBlockCategoryFeature.Images,
} as const;
export type TableBlockCategoryFeature =
  (typeof TableBlockCategoryFeature)[keyof typeof TableBlockCategoryFeature];

// Do not re-order this object alphabetically => it is used to generate sorted messages
export const TextBlockTypeFeature = {
  Paragraph: 'paragraph',
  HeadingOne: 'heading-one',
  HeadingTwo: 'heading-two',
  HeadingThree: 'heading-three',
  HeadingFour: 'heading-four',
  HeadingFive: 'heading-five',
  HeadingSix: 'heading-six',
  OrderedList: 'ordered-list',
  UnorderedList: 'unordered-list',
} as const;
export type TextBlockTypeFeature = (typeof TextBlockTypeFeature)[keyof typeof TextBlockTypeFeature];

// Do not re-order these objects alphabetically => it is used to generate sorted messages
export const TextStyleFeature = {
  Bold: 'bold',
  Italic: 'italic',
  Superscript: 'superscript',
  Subscript: 'subscript',
  Code: 'code',
} as const;
export type TextStyleFeature = (typeof TextStyleFeature)[keyof typeof TextStyleFeature];

export const BaseTextFormattingFeature = {
  ...TextStyleFeature,
  Link: 'link',
} as const;
export type BaseTextFormattingFeature =
  (typeof BaseTextFormattingFeature)[keyof typeof BaseTextFormattingFeature];

export const TextFormattingFeature = {
  ...BaseTextFormattingFeature,
  Unstyled: 'unstyled',
} as const;

export type TextFormattingFeature =
  (typeof TextFormattingFeature)[keyof typeof TextFormattingFeature];

export type RichTextCommandFeature =
  | TextBlockTypeFeature
  | BaseTextFormattingFeature
  | TopLevelBlockCategoryFeature;

export type RichTextFeature =
  | TopLevelBlockCategoryFeature
  | TableBlockCategoryFeature
  | RichTextCommandFeature;

export const allTopLevelBlockCategoryFeatures: ReadonlySet<TopLevelBlockCategoryFeature> = new Set(
  Object.values(TopLevelBlockCategoryFeature),
);
export const allTableBlockCategoryFeatures: ReadonlySet<TableBlockCategoryFeature> = new Set(
  Object.values(TableBlockCategoryFeature),
);
export const allTextBlockTypeFeatures: ReadonlySet<TextBlockTypeFeature> = new Set(
  Object.values(TextBlockTypeFeature),
);

// All allowed values (including 'unstyled' special value)
export const allTextFormattingFeatures: ReadonlySet<TextFormattingFeature> = new Set(
  Object.values(TextFormattingFeature),
);
// All styles that can be limited (excluding 'unstyled' special value)
export const allBaseTextFormattingFeatures: ReadonlySet<BaseTextFormattingFeature> = new Set(
  Object.values(BaseTextFormattingFeature),
);

type BlockFeatureNameMap = ReadonlyRecord<TopLevelBlockCategoryFeature, string>;

export const BlockFeatureNameMap: BlockFeatureNameMap = {
  [TopLevelBlockCategoryFeature.Text]: 'Text and Empty lines',
  [TopLevelBlockCategoryFeature.Tables]: 'Tables',
  [TopLevelBlockCategoryFeature.Images]: 'Images',
  [TopLevelBlockCategoryFeature.ComponentsAndItems]: 'Components and Items',
};

type TableBlockFeatureNameMap = ReadonlyRecord<TableBlockCategoryFeature, string>;

export const TableBlockFeatureNameMap: TableBlockFeatureNameMap = {
  [TableBlockCategoryFeature.Text]: 'Text and Empty lines in tables',
  [TableBlockCategoryFeature.Images]: 'Images in tables',
};

type TextBlockFeatureNameMap = ReadonlyRecord<TextBlockTypeFeature, string>;

export const TextBlockFeatureNameMap: TextBlockFeatureNameMap = {
  [TextBlockTypeFeature.Paragraph]: 'Paragraph',
  [TextBlockTypeFeature.HeadingOne]: 'Heading 1',
  [TextBlockTypeFeature.HeadingTwo]: 'Heading 2',
  [TextBlockTypeFeature.HeadingThree]: 'Heading 3',
  [TextBlockTypeFeature.HeadingFour]: 'Heading 4',
  [TextBlockTypeFeature.HeadingFive]: 'Heading 5',
  [TextBlockTypeFeature.HeadingSix]: 'Heading 6',
  [TextBlockTypeFeature.OrderedList]: 'Ordered list',
  [TextBlockTypeFeature.UnorderedList]: 'Unordered list',
};

type TextFormattingFeatureNameMap = ReadonlyRecord<BaseTextFormattingFeature, string>;

export const TextFormattingFeatureNameMap: TextFormattingFeatureNameMap = {
  [TextFormattingFeature.Bold]: 'Bold',
  [TextFormattingFeature.Italic]: 'Italic',
  [TextFormattingFeature.Superscript]: 'Superscript',
  [TextFormattingFeature.Subscript]: 'Subscript',
  [TextFormattingFeature.Code]: 'Code',
  [TextFormattingFeature.Link]: 'Link',
};

export const FeatureNameMap = {
  ...BlockFeatureNameMap,
  ...TextBlockFeatureNameMap,
  ...TextFormattingFeatureNameMap,
};

export const isKnownFeatureNameMapKey = (key: string): key is keyof typeof FeatureNameMap =>
  (Object.keys(FeatureNameMap) as ReadonlyArray<string>).includes(key);

const getDisallowedTextBlocksAndFormattingFeatures = (
  allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>,
  allowedTextFormatting: ReadonlySet<TextFormattingFeature>,
): ReadonlySet<RichTextFeature> => {
  const disallowedTextBlocks = Collection.removeMany(
    allTextBlockTypeFeatures,
    Collection.getValues(allowedTextBlocks),
  );

  // Remove 'unstyled' special value since we dont want to validate it
  const allowedBaseTextFormatting = Collection.getValues(allowedTextFormatting).filter(
    (f) => f !== TextFormattingFeature.Unstyled,
  );

  const disallowedTextFormatting = Collection.removeMany(
    allBaseTextFormattingFeatures,
    allowedBaseTextFormatting,
  );

  return Collection.addMany(disallowedTextBlocks, Collection.getValues(disallowedTextFormatting));
};

const getDisallowedBlockFeatures = memoize.weak(
  (
    allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature | TableBlockCategoryFeature>,
    allBlockCategoryFeatures: ReadonlySet<TopLevelBlockCategoryFeature | TableBlockCategoryFeature>,
  ): ReadonlySet<TopLevelBlockCategoryFeature | TableBlockCategoryFeature> => {
    return Collection.removeMany(allBlockCategoryFeatures, Collection.getValues(allowedBlocks));
  },
);

export const getDisallowedTopLevelTextBlocksAndFormattingFeatures = memoize.weak(
  (limitations: EditorFeatureLimitations): ReadonlySet<RichTextFeature> => {
    return getDisallowedTextBlocksAndFormattingFeatures(
      limitations.allowedTextBlocks,
      limitations.allowedTextFormatting,
    );
  },
);

export const getDisallowedTableTextBlocksAndFormattingFeatures = memoize.weak(
  (limitations: EditorFeatureLimitations): ReadonlySet<RichTextFeature> => {
    return getDisallowedTextBlocksAndFormattingFeatures(
      limitations.allowedTableTextBlocks,
      limitations.allowedTableTextFormatting,
    );
  },
);

export const getDisallowedFeatures = memoize.allForever(
  <T extends TopLevelBlockCategoryFeature | TableBlockCategoryFeature>(
    allowedBlocks: ReadonlySet<T>,
    allBlockCategoryFeatures: ReadonlySet<T>,
    allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>,
    allowedTextFormatting: ReadonlySet<TextFormattingFeature>,
  ): ReadonlySet<RichTextFeature> => {
    const disallowedBlocks = getDisallowedBlockFeatures(allowedBlocks, allBlockCategoryFeatures);

    return Collection.addMany(
      disallowedBlocks,
      Collection.getValues(
        getDisallowedTextBlocksAndFormattingFeatures(allowedTextBlocks, allowedTextFormatting),
      ),
    );
  },
);

export const getAllDisallowedTopLevelFeatures = (
  limitations: EditorFeatureLimitations,
): ReadonlySet<RichTextFeature> => {
  const { allowedBlocks, allowedTextBlocks, allowedTextFormatting } = limitations;
  const isTextAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  if (!isTextAllowed) {
    // Do not check text blocks & styles when text is not allowed
    return getDisallowedBlockFeatures(allowedBlocks, allTopLevelBlockCategoryFeatures);
  }

  return getDisallowedFeatures(
    allowedBlocks,
    allTopLevelBlockCategoryFeatures,
    allowedTextBlocks,
    allowedTextFormatting,
  );
};

export const getAllDisallowedTableFeatures = (
  limitations: EditorFeatureLimitations,
): ReadonlySet<RichTextFeature> => {
  const { allowedBlocks, allowedTableBlocks, allowedTableTextBlocks, allowedTableTextFormatting } =
    limitations;
  const isTableAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
  if (!isTableAllowed) {
    // Do not check text blocks & styles when table is not allowed
    return new Set();
  }

  return getDisallowedFeatures(
    allowedTableBlocks,
    allTableBlockCategoryFeatures,
    allowedTableTextBlocks,
    allowedTableTextFormatting,
  );
};

interface IDisallowedFeatures {
  readonly disallowedTopLevelFeatures: ReadonlySet<RichTextFeature>;
  readonly disallowedTableFeatures: ReadonlySet<RichTextFeature>;
}

export const getAllDisallowedFeatures = memoize.weak(
  (limitations: EditorFeatureLimitations): IDisallowedFeatures => ({
    disallowedTopLevelFeatures: getAllDisallowedTopLevelFeatures(limitations),
    disallowedTableFeatures: getAllDisallowedTableFeatures(limitations),
  }),
);

const isTextBlock = (
  block: ContentBlock,
  index: number,
  contentBlocks: ReadonlyArray<ContentBlock>,
): boolean => {
  return isTextBlockType(getBaseBlockType(block)) && !isCustomBlockSleeve(index, contentBlocks);
};

const isTextParagraph = (
  block: ContentBlock,
  index: number,
  contentBlocks: ReadonlyArray<ContentBlock>,
): boolean => {
  return isUnstyledBlock(block) && !isCustomBlockSleeve(index, contentBlocks);
};

const isNonEmptyParagraphOrWithSibling = (
  block: ContentBlock,
  index: number,
  contentBlocks: ReadonlyArray<ContentBlock>,
): boolean => {
  if (!isTextParagraph(block, index, contentBlocks)) {
    return false;
  }

  const isEmpty = !block.getLength();
  if (!isEmpty) {
    return true;
  }

  return (
    !!findSiblingBlock(index, contentBlocks, Direction.Forward).block ||
    !!findSiblingBlock(index, contentBlocks, Direction.Backward).block
  );
};

const isContentModuleOrContentComponentBlock = (block: ContentBlock | null): boolean => {
  return isContentModule(block) || isContentComponent(block);
};

export const areAllTextBlocksAllowed = memoize.weak(
  (
    fullBlockTypesAtSelection: ReadonlySet<BlockType>,
    limitations: EditorFeatureLimitations,
  ): boolean => {
    const disallowedFeatures = getAllDisallowedTopLevelFeatures(limitations);

    const textNotAllowed = disallowedFeatures.has(TopLevelBlockCategoryFeature.Text);
    if (textNotAllowed) {
      // Only track top level text block types here - match to full block types is intentional here
      const areTextBlocksAtSelection = !!Collection.intersect(
        TextBlockTypes,
        fullBlockTypesAtSelection,
      ).length;
      if (areTextBlocksAtSelection) {
        return false;
      }
    }

    const tablesNotAllowed = disallowedFeatures.has(TopLevelBlockCategoryFeature.Tables);
    if (tablesNotAllowed) {
      // Detect any content placed inside the table, even normal blocks nested in table cells
      const isTableContentAtSelection = Collection.getValues(fullBlockTypesAtSelection)
        .flatMap(parseBlockType)
        .includes(BlockType.TableCell);
      if (isTableContentAtSelection) {
        return false;
      }
    }

    return true;
  },
);

export const areAllTextBlocksDisallowed = memoize.weak(
  (
    fullBlockTypesAtSelection: ReadonlySet<BlockType>,
    limitations: EditorFeatureLimitations,
  ): boolean => {
    const textAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
    if (textAllowed) {
      // Only track top level text block types here - match to full block types is intentional here
      const textBlocksAtSelection = !!Collection.intersect(
        TextBlockTypes,
        fullBlockTypesAtSelection,
      ).length;
      if (textBlocksAtSelection) {
        return false;
      }
    }

    const tablesAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
    if (tablesAllowed) {
      // Detect any content placed inside the table, even normal blocks nested in table cells
      const isTableContentAtSelection = Collection.getValues(fullBlockTypesAtSelection)
        .flatMap(parseBlockType)
        .includes(BlockType.TableCell);
      if (isTableContentAtSelection) {
        return false;
      }
    }

    return true;
  },
);

export const isTextFeatureAllowed = (
  feature: RichTextFeature,
  fullBlockTypesAtSelection: ReadonlySet<BlockType>,
  limitations: EditorFeatureLimitations,
): boolean => {
  if (!areAllTextBlocksAllowed(fullBlockTypesAtSelection, limitations)) {
    return false;
  }

  const { disallowedTopLevelFeatures, disallowedTableFeatures } =
    getAllDisallowedFeatures(limitations);

  const isAllowedInTopLevel = !disallowedTopLevelFeatures.has(feature);
  const isAllowedInTable = !disallowedTableFeatures.has(feature);

  const topLevelBlockTypesAtSelection = Collection.getValues(fullBlockTypesAtSelection).filter(
    (b) => !!b && !isNestedBlockType(b) && b !== BlockType.TableCell,
  );
  const tableBlockTypesAtSelection = Collection.getValues(fullBlockTypesAtSelection).filter(
    (b) => !!b && isTableContentBlockType(b),
  );

  const containsTopLevelBlocks = !!topLevelBlockTypesAtSelection.length;
  const containsTableBlocks = !!tableBlockTypesAtSelection.length;

  return (
    (!containsTopLevelBlocks || isAllowedInTopLevel) && (!containsTableBlocks || isAllowedInTable)
  );
};

const blockContainsLink = memoize.weak((block: ContentBlock, entityMap: EntityMap): boolean => {
  const metadata = getMetadataForBlockChars(block, 0, block.getLength());
  if (!metadata) {
    return false;
  }
  const topLevelEntities = metadata.entityKeyAtAnyTopLevelChars ?? Immutable.Set<string>();
  const tableEntities = metadata.entityKeyAtAnyTableChars ?? Immutable.Set<string>();
  const entityKeys = topLevelEntities.union(tableEntities);

  const entities = getEntities(entityMap, entityKeys);
  return entities.some(isLink);
});

const contentContainsLink: FeatureContentChecker = (
  content: ContentState,
  predicate: FeatureBlockPredicate,
) => {
  const entityMap = getEntityMap(content);
  return getBlocks(content)
    .filter(predicate)
    .some((block) => blockContainsLink(block, entityMap));
};

const blockContainsStyle = (block: ContentBlock, style: DraftJSInlineStyle): boolean => {
  const metadata = getMetadataForBlockChars(block, 0, block.getLength());
  return (
    (metadata?.styleAtAnyTopLevelChars?.has(style) || metadata?.styleAtAnyTableChars?.has(style)) ??
    false
  );
};

type FeatureBlockChecker = (
  block: ContentBlock,
  index: number,
  contentBlocks: ReadonlyArray<ContentBlock>,
) => boolean;
type FeatureBlockPredicate = (block: ContentBlock) => boolean;
type FeatureBlockCheckerMap = ReadonlyRecord<
  TopLevelBlockCategoryFeature | TextBlockTypeFeature,
  FeatureBlockChecker
>;

const createFeatureBlockChecker =
  (blockChecker: FeatureBlockChecker, predicate: FeatureBlockPredicate) =>
  (block: ContentBlock, index: number, contentBlocks: ReadonlyArray<ContentBlock>) => {
    return predicate(block) && blockChecker(block, index, contentBlocks);
  };

const createBlockTypeChecker = (blockType: BaseBlockType) => (block: ContentBlock) =>
  getBaseBlockType(block) === blockType;

const createFeatureBlockCheckerMap = (predicate: FeatureBlockPredicate) => ({
  [TopLevelBlockCategoryFeature.Text]: createFeatureBlockChecker(isTextBlock, predicate),
  [TopLevelBlockCategoryFeature.Tables]: createFeatureBlockChecker(isTableCell, predicate),
  [TopLevelBlockCategoryFeature.Images]: createFeatureBlockChecker(isImage, predicate),
  [TopLevelBlockCategoryFeature.ComponentsAndItems]: createFeatureBlockChecker(
    isContentModuleOrContentComponentBlock,
    predicate,
  ),

  [TextBlockTypeFeature.Paragraph]: createFeatureBlockChecker(
    isNonEmptyParagraphOrWithSibling,
    predicate,
  ),
  [TextBlockTypeFeature.HeadingOne]: createFeatureBlockChecker(
    createBlockTypeChecker(BlockType.HeadingOne),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingTwo]: createFeatureBlockChecker(
    createBlockTypeChecker(BlockType.HeadingTwo),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingThree]: createFeatureBlockChecker(
    createBlockTypeChecker(BlockType.HeadingThree),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingFour]: createFeatureBlockChecker(
    createBlockTypeChecker(BlockType.HeadingFour),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingFive]: createFeatureBlockChecker(
    createBlockTypeChecker(BlockType.HeadingFive),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingSix]: createFeatureBlockChecker(
    createBlockTypeChecker(BlockType.HeadingSix),
    predicate,
  ),
  [TextBlockTypeFeature.OrderedList]: createFeatureBlockChecker(isOrderedListItem, predicate),
  [TextBlockTypeFeature.UnorderedList]: createFeatureBlockChecker(isUnorderedListItem, predicate),
});

const topLevelFeatureBlockCheckerMap: FeatureBlockCheckerMap =
  createFeatureBlockCheckerMap(isTopLevelBlock);
const tableFeatureBlockCheckerMap: FeatureBlockCheckerMap =
  createFeatureBlockCheckerMap(isTableCellContent);

type FeatureContentChecker = (content: ContentState, predicate?: FeatureBlockPredicate) => boolean;
type FeatureContentCheckerMap = ReadonlyRecord<RichTextFeature, FeatureContentChecker>;

const createFeatureContentChecker =
  (blockChecker: FeatureBlockChecker, predicate?: FeatureBlockPredicate) =>
  (content: ContentState) =>
    getBlocks(content)
      .filter((block) => !predicate || predicate(block))
      .some(blockChecker);

const createFeatureContentCheckerMap = (predicate: FeatureBlockPredicate) => ({
  [TopLevelBlockCategoryFeature.Text]: createFeatureContentChecker(
    createFeatureBlockChecker(isTextBlock, predicate),
  ),
  [TopLevelBlockCategoryFeature.Tables]: createFeatureContentChecker(isTableCell, predicate),
  [TopLevelBlockCategoryFeature.Images]: createFeatureContentChecker(isImage, predicate),
  [TopLevelBlockCategoryFeature.ComponentsAndItems]: createFeatureContentChecker(
    isContentModuleOrContentComponentBlock,
    predicate,
  ),

  [TextBlockTypeFeature.Paragraph]: createFeatureContentChecker(
    createFeatureBlockChecker(isNonEmptyParagraphOrWithSibling, predicate),
  ),
  [TextBlockTypeFeature.HeadingOne]: createFeatureContentChecker(
    createBlockTypeChecker(BlockType.HeadingOne),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingTwo]: createFeatureContentChecker(
    createBlockTypeChecker(BlockType.HeadingTwo),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingThree]: createFeatureContentChecker(
    createBlockTypeChecker(BlockType.HeadingThree),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingFour]: createFeatureContentChecker(
    createBlockTypeChecker(BlockType.HeadingFour),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingFive]: createFeatureContentChecker(
    createBlockTypeChecker(BlockType.HeadingFive),
    predicate,
  ),
  [TextBlockTypeFeature.HeadingSix]: createFeatureContentChecker(
    createBlockTypeChecker(BlockType.HeadingSix),
    predicate,
  ),
  [TextBlockTypeFeature.OrderedList]: createFeatureContentChecker(isOrderedListItem, predicate),
  [TextBlockTypeFeature.UnorderedList]: createFeatureContentChecker(isUnorderedListItem, predicate),

  [TextFormattingFeature.Bold]: createFeatureContentChecker(
    (b) => blockContainsStyle(b, DraftJSInlineStyle.Bold),
    predicate,
  ),
  [TextFormattingFeature.Code]: createFeatureContentChecker(
    (b) => blockContainsStyle(b, DraftJSInlineStyle.Code),
    predicate,
  ),
  [TextFormattingFeature.Italic]: createFeatureContentChecker(
    (b) => blockContainsStyle(b, DraftJSInlineStyle.Italic),
    predicate,
  ),
  [TextFormattingFeature.Subscript]: createFeatureContentChecker(
    (b) => blockContainsStyle(b, DraftJSInlineStyle.Subscript),
    predicate,
  ),
  [TextFormattingFeature.Superscript]: createFeatureContentChecker(
    (b) => blockContainsStyle(b, DraftJSInlineStyle.Superscript),
    predicate,
  ),

  // Links need to be checked for the whole ContentState because they need to read from entityMap
  [TextFormattingFeature.Link]: (content: ContentState) => contentContainsLink(content, predicate),
});

const topLevelFeatureContentCheckerMap: FeatureContentCheckerMap =
  createFeatureContentCheckerMap(isTopLevelBlock);
const tableFeatureContentCheckerMap: FeatureContentCheckerMap =
  createFeatureContentCheckerMap(isTableCellContent);

export type GetViolatedFeaturesInContentState = (
  content: ContentState,
  limitations: EditorFeatureLimitations,
) => IViolatedFeaturesResult;

export interface IViolatedFeaturesResult {
  readonly violatedTopLevelFeatures: ReadonlySet<RichTextFeature>;
  readonly violatedTableFeatures: ReadonlySet<RichTextFeature>;
}

export const emptyViolatedFeaturesResult: IViolatedFeaturesResult = {
  violatedTopLevelFeatures: new Set<RichTextFeature>(),
  violatedTableFeatures: new Set<RichTextFeature>(),
};

const getMemoizedViolatedFeatures = memoize.maxN(
  (...features: ReadonlyArray<RichTextFeature>): ReadonlySet<RichTextFeature> => new Set(features),
  // There will be just few active combinations of violated features at a time, so the limit of 100 should be safe enough for any normal usage
  100,
);

const getViolatedFeaturesResult = memoize.maxN(
  (
    violatedTopLevelFeatures: ReadonlySet<RichTextFeature>,
    violatedTableFeatures: ReadonlySet<RichTextFeature>,
  ): IViolatedFeaturesResult => ({
    violatedTopLevelFeatures,
    violatedTableFeatures,
  }),
  // One editor uses one result, and we don't expect too many active editors at a time that would need recalculation, so 100 limit is safe enough
  100,
);

export const getViolatedFeaturesInContentState: GetViolatedFeaturesInContentState = (
  content: ContentState,
  limitations: EditorFeatureLimitations,
): IViolatedFeaturesResult => {
  if (isContentEmpty(content)) {
    return getViolatedFeaturesResult(new Set(), new Set());
  }

  const disallowedTopLevelFeatures = getAllDisallowedTopLevelFeatures(limitations);
  const disallowedTableFeatures = getAllDisallowedTableFeatures(limitations);

  const violatedTopLevelFeatures = getMemoizedViolatedFeatures(
    ...Collection.getValues(disallowedTopLevelFeatures).filter(
      (disallowedFeature: RichTextFeature) => {
        const checker = topLevelFeatureContentCheckerMap[disallowedFeature];
        return checker?.(content);
      },
    ),
  );
  const violatedTableFeatures = getMemoizedViolatedFeatures(
    ...Collection.getValues(disallowedTableFeatures).filter(
      (disallowedFeature: RichTextFeature) => {
        const checker = tableFeatureContentCheckerMap[disallowedFeature];
        return checker?.(content);
      },
    ),
  );

  return getViolatedFeaturesResult(violatedTopLevelFeatures, violatedTableFeatures);
};

export function removeInvalidBlocks(
  content: ContentState,
  limitations: EditorFeatureLimitations,
): ContentState {
  const disallowedTopLevelFeatures = getAllDisallowedTopLevelFeatures(limitations);
  const disallowedTableFeatures = getAllDisallowedTableFeatures(limitations);

  if (isContentEmpty(content) || !disallowedTopLevelFeatures.size) {
    return content;
  }

  const invalidTopLevelBlockCheckers = Collection.getValues(disallowedTopLevelFeatures)
    .map(
      (disallowedFeature: TopLevelBlockCategoryFeature) =>
        topLevelFeatureBlockCheckerMap[disallowedFeature],
    )
    .filter(notNullNorUndefined);

  const invalidTableBlockCheckers = Collection.getValues(disallowedTableFeatures)
    .map(
      (disallowedFeature: TableBlockCategoryFeature) =>
        tableFeatureBlockCheckerMap[disallowedFeature],
    )
    .filter(notNullNorUndefined);

  if (!invalidTopLevelBlockCheckers.length && !invalidTableBlockCheckers.length) {
    return content;
  }

  const invalidBlockCheckers = [...invalidTopLevelBlockCheckers, ...invalidTableBlockCheckers];

  const blocks = getBlocks(content);
  const newBlocks = filterBlocks(blocks, (block, index) =>
    invalidBlockCheckers.every((checker) => !checker(block, index, blocks)),
  ).blocks;

  if (newBlocks === blocks) {
    return content;
  }

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(
    newContent,
    content.getSelectionBefore(),
    content.getSelectionAfter(),
  );

  return newContentWithSelection;
}

export const getLinkStatus = (
  linkProps: Pick<EntityDecoratorProps, 'blockKey' | 'contentState'>,
  limitations: EditorFeatureLimitations,
): EditorLinkStatus => {
  const { allowedBlocks, allowedTextFormatting, allowedTableTextFormatting } = limitations;
  const block = linkProps.contentState.getBlockForKey(linkProps.blockKey);
  const isLinkInTable = isInTable(block);
  const isInNotAllowedText = isLinkInTable
    ? !allowedBlocks.has(TopLevelBlockCategoryFeature.Tables)
    : !allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isNotAllowed = isLinkInTable
    ? !allowedTableTextFormatting.has(TextFormattingFeature.Link)
    : !allowedTextFormatting.has(TextFormattingFeature.Link);

  if (isInNotAllowedText) {
    return EditorLinkStatus.InNotAllowedText;
  }
  if (isNotAllowed) {
    return EditorLinkStatus.NotAllowed;
  }
  return EditorLinkStatus.Allowed;
};
