import { FilterAction } from './listingFilterConstants.ts';

export const FilterActionsMenuText = {
  [FilterAction.SaveFilter]: 'Save',
  [FilterAction.ShareFilter]: 'Share',
} as const;

export const FilterActionsMenuLeafNames = {
  [FilterAction.SaveFilter]: 'Save filter',
  [FilterAction.ShareFilter]: 'Share filter',
} as const;

export const isFilterActionsMenuLeafNamesKey = (
  key: FilterAction,
): key is keyof typeof FilterActionsMenuLeafNames =>
  (Object.keys(FilterActionsMenuLeafNames) as ReadonlyArray<FilterAction>).includes(key);
