import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { selectedContentTypeSnippetsUpdated } from '../snippetsActions.ts';

export const createSelectContentTypeSnippetsAction =
  () =>
  (selectedTypeSnippetIds: Immutable.Set<Uuid>): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const updatedSelectedSnippetIds =
      state.contentModelsApp.snippets.listing.selectedContentTypeSnippets.merge(
        selectedTypeSnippetIds,
      );

    dispatch(selectedContentTypeSnippetsUpdated(updatedSelectedSnippetIds));
  };
