import { Action } from '../../../../../@types/Action.type.ts';
import {
  IRoleWithSettings,
  defaultRoleWithSettings,
} from '../../../../../data/models/roles/IRoleWithSettings.ts';
import {
  Role_CreatorInit_Started,
  Role_EditorInit_Finished,
  Role_EditorInit_Started,
  Role_Update_Finished,
  Roles_ListingInit_Started,
} from '../../constants/rolesActionTypes.ts';
import { getEditedRoleApplicationModel } from '../../utils/editedRoleUtils.ts';

export const originalEditedRole = (
  state: IRoleWithSettings = defaultRoleWithSettings,
  action: Action,
): IRoleWithSettings => {
  switch (action.type) {
    case Role_CreatorInit_Started:
    case Role_EditorInit_Started:
    case Roles_ListingInit_Started:
      return defaultRoleWithSettings;

    case Role_EditorInit_Finished:
    case Role_Update_Finished: {
      const { role, allTypes } = action.payload;

      return getEditedRoleApplicationModel(role, allTypes);
    }

    default:
      return state;
  }
};
