import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_Editing_Left,
  Workflow_InitializeEditing_Started,
} from '../../constants/workflowActionTypes.ts';

const initialState = '';

export const cancellationToken = (state = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Workflow_InitializeEditing_Started:
      return action.payload.cancellationToken;

    case Workflow_Editing_Left:
      return initialState;

    default:
      return state;
  }
};
