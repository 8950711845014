import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getListingContentItemFromJS } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  IContentItemWithVariantsServerModel,
  RequiredItemCreationParams,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { listingItemsLoaded } from '../../../../actions/contentActions.ts';
import { ContentItemEditing_CreatingLinkedItem } from '../../../ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { NewContentItem_ContentItemCreatedInLinkedItems } from '../../constants/newContentItemActionTypes.ts';
import { ICreateContentItemAction } from './createContentItem.ts';

interface IDeps {
  readonly createContentItem: ICreateContentItemAction;
  readonly updateCollectionsPreference: (collectionId: Uuid) => ThunkPromise;
  readonly updateTypesPreference: (typeId: Uuid) => ThunkPromise;
}

const linkedItemIsBeingCreated = () =>
  ({
    type: ContentItemEditing_CreatingLinkedItem,
  }) as const;

const newContentItemCreated = () =>
  ({
    type: NewContentItem_ContentItemCreatedInLinkedItems,
  }) as const;

export type CreateNewLinkedItemActionsType = ReturnType<
  typeof linkedItemIsBeingCreated | typeof newContentItemCreated
>;

export const createNewLinkedItemActionCreator =
  ({ createContentItem, updateCollectionsPreference, updateTypesPreference }: IDeps) =>
  (
    createItemParams: RequiredItemCreationParams,
  ): ThunkPromise<IContentItemWithVariantsServerModel> =>
  async (dispatch): Promise<IContentItemWithVariantsServerModel> => {
    dispatch(linkedItemIsBeingCreated());

    const createdItem = await dispatch(createContentItem(createItemParams));

    const createdListingItem = getListingContentItemFromJS({
      activeCapabilities: {
        variantCapabilities: [],
      },
      highlightedName: null,
      isPublishedVersion: false,
      item: createdItem.item,
      variant: null,
      translatedLanguages: [],
    });

    dispatch(listingItemsLoaded([createdListingItem]));
    dispatch(newContentItemCreated());
    await Promise.all([
      dispatch(updateTypesPreference(createItemParams.typeId)),
      dispatch(updateCollectionsPreference(createItemParams.collectionId)),
    ]);

    return createdItem;
  };
