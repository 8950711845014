import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { GuidelinesEventTypes } from '../../../../../../_shared/models/TrackUserEventData.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  BaseTypeElementEditableData,
  IBaseTypeElementData,
} from '../../../models/elements/types/TypeElementData.ts';
import { getClosestPreviousElement } from '../../../utils/typeUtils.ts';
import { newTypeElementCreated } from '../../sharedContentModelsActions.ts';

interface IDeps {
  readonly createGuid: () => Uuid;
  readonly createDefaultTypeElementData: (elementType: TypeElementType) => IBaseTypeElementData;
}

type NewTypeElementInitialData = Partial<BaseTypeElementEditableData>;

const prepareNewTypeElement = (
  { createGuid, createDefaultTypeElementData }: IDeps,
  elementType: TypeElementType,
  initialElementData: NewTypeElementInitialData,
): IBaseTypeElementData => {
  const defaultTypeElement = createDefaultTypeElementData(elementType);
  return {
    ...defaultTypeElement,
    ...initialElementData,
    elementId: createGuid(),
  };
};

export const createAddNewTypeElementAction =
  (deps: IDeps) =>
  (elementType: TypeElementType, initialElementData?: NewTypeElementInitialData): ThunkFunction =>
  (dispatch, getState) => {
    const {
      typeEditor: { editedType },
      contentTypes: {
        editor: { selectedContentGroupId },
      },
    } = getState().contentModelsApp;

    if (elementType === ElementType.Guidelines) {
      dispatch(
        trackUserEventWithData(TrackedEvent.Guidelines, { action: GuidelinesEventTypes.Created }),
      );
    }

    const elementData = { ...initialElementData, contentGroupId: selectedContentGroupId };
    const newTypeElement = prepareNewTypeElement(deps, elementType, elementData);
    const insertAfterElement = getClosestPreviousElement(
      editedType.typeElements,
      editedType.contentGroups,
      selectedContentGroupId,
    );
    const insertAtIndex = insertAfterElement
      ? editedType.typeElements.indexOf(insertAfterElement) + 1
      : 0;

    dispatch(newTypeElementCreated(newTypeElement, insertAtIndex));
  };

export const createInsertNewTypeElementBeforeAction =
  (deps: IDeps) =>
  (
    elementType: TypeElementType,
    insertBeforeElement: IBaseTypeElementData,
    initialElementData?: NewTypeElementInitialData,
  ): ThunkFunction =>
  (dispatch, getState) => {
    const { typeElements } = getState().contentModelsApp.typeEditor.editedType;

    if (elementType === ElementType.Guidelines) {
      dispatch(
        trackUserEventWithData(TrackedEvent.Guidelines, { action: GuidelinesEventTypes.Created }),
      );
    }

    const elementData = {
      ...initialElementData,
      contentGroupId: insertBeforeElement.contentGroupId,
    };
    const newTypeElement = prepareNewTypeElement(deps, elementType, elementData);
    const insertAtIndex = typeElements.indexOf(insertBeforeElement);

    dispatch(newTypeElementCreated(newTypeElement, insertAtIndex));
  };
