import React from 'react';

export const RevisionsContext = React.createContext<boolean>(false);

export const RevisionsContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  // When the context is used, it always gives true
  // We need to pass the true value to the component as a variable otherwise ESlint complains about typing of the value property of the context
  const isInRevisions = true;

  return <RevisionsContext.Provider value={isInRevisions}>{children}</RevisionsContext.Provider>;
};
