import React, { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightAssetTrackedEvent,
  TrackedEvent,
} from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { loadListingAssets } from '../../../../../data/actions/thunkDataActions.ts';
import {
  IAssetLibraryQuery,
  areAssetLibraryQueriesEqual,
  areSearchPhrasesEqual,
  areSelectedCollectionsEqual,
  areSelectedTaxonomiesEqual,
} from '../../models/IAssetLibraryQuery.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IAssetQueryObserverProps {
  readonly onQueryChange?: (query: IAssetLibraryQuery) => void;
  readonly orderBy: AssetsOrderBy;
}

const useReloadAssets = (previousQueryRef: React.MutableRefObject<IAssetLibraryQuery>) => {
  const dispatch = useDispatch();

  return useDebouncedCallback(
    (
      query: IAssetLibraryQuery,
      orderBy: AssetsOrderBy,
      onQueryChange?: (query: IAssetLibraryQuery) => void,
    ) => {
      if (!areAssetLibraryQueriesEqual(query, previousQueryRef.current)) {
        if (!areSearchPhrasesEqual(previousQueryRef.current, query)) {
          dispatch(
            trackUserEventWithData(TrackedEvent.FindRightAsset, {
              name: FindRightAssetTrackedEvent.AssetLibraryFilteredByName,
            }),
          );
        }
        if (!areSelectedTaxonomiesEqual(previousQueryRef.current, query)) {
          dispatch(
            trackUserEventWithData(TrackedEvent.FindRightAsset, {
              name: FindRightAssetTrackedEvent.AssetLibraryFilteredByTaxonomy,
            }),
          );
        }
        if (!areSelectedCollectionsEqual(previousQueryRef.current, query)) {
          dispatch(
            trackUserEventWithData(TrackedEvent.FindRightAsset, {
              name: FindRightAssetTrackedEvent.AssetLibraryFilteredByCollection,
            }),
          );
        }

        previousQueryRef.current = query;
        onQueryChange?.(query);
        dispatch(loadListingAssets({ requestTrigger: AssetsRequestTrigger.Search, orderBy }));
      }
    },
    300,
  );
};

export const AssetQueryObserver: React.FC<IAssetQueryObserverProps> = ({
  onQueryChange,
  orderBy,
}) => {
  const query = useSelector((s) => s.assetLibraryApp.query);
  const previousQueryRef = useRef(query);

  const reloadAssetsDebounced = useReloadAssets(previousQueryRef);

  useEffect(() => {
    reloadAssetsDebounced(query, orderBy, onQueryChange);
    return reloadAssetsDebounced.cancel;
  }, [query, onQueryChange, reloadAssetsDebounced, orderBy]);

  return null;
};

AssetQueryObserver.displayName = 'AssetQueryObserver';
