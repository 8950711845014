import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { CalendarEventTypes } from '../../../../_shared/models/TrackUserEventData.ts';
import { calendarItemDetailsSelected } from '../actions/calendarActions.ts';
import {
  AgendaItemsListing as AgendaItemsListingComponent,
  AgendaItemsListingDispatchProps,
  AgendaItemsListingOwnProps,
} from '../components/AgendaItemsListing.tsx';

export const mapDispatchToProps = (dispatch: Dispatch): AgendaItemsListingDispatchProps => ({
  onAgendaItemClicked: (detailItemId: Uuid, date: DateTimeStamp) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.Calendar, {
        action: CalendarEventTypes.ItemDetailsSelected,
      }),
    );
    dispatch(calendarItemDetailsSelected(detailItemId, date));
  },
});

export const AgendaItemsListing: React.ComponentType<
  React.PropsWithChildren<AgendaItemsListingOwnProps>
> = connect(null, mapDispatchToProps)(AgendaItemsListingComponent);
