import { Action } from '../../../../../@types/Action.type.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_Started,
  ProjectOverview_Page_Left,
} from '../../constants/projectOverviewActionTypes.ts';

const initialState: Uuid = '';

export const cancellationToken = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case ProjectOverview_LoadItemsMatchingFilter_Started: {
      return action.payload.cancellationToken;
    }

    case ProjectOverview_Page_Left: {
      return initialState;
    }

    default:
      return state;
  }
};
