import { Box } from '@kontent-ai/component-library/Box';
import { OnboardingPopover } from '@kontent-ai/component-library/OnboardingBubble';
import React, { ReactNode } from 'react';
import { upsertUserProperty } from '../../../../_shared/actions/thunkSharedActions.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DismissedNewWebhooksOnboardingServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { webhooksOnboardingStepUpdated } from '../../../webhooks/actions/webhooksActions.ts';

const useNewWebhooksOnboarding = () => {
  const shouldShow = useSelector(
    (state) => !state.sharedApp.userProperties.dismissedNewWebhooksOnboarding,
  );

  const currentStep = useSelector((state) => state.webhooksApp.newWebhooksOnboardingStep);

  const dispatch = useDispatch();
  const onDismiss = () => {
    dispatch(upsertUserProperty(DismissedNewWebhooksOnboardingServerKey, 'true'));
  };

  const updateCurrentStep = (newStep: number) => {
    dispatch(webhooksOnboardingStepUpdated(newStep));
  };

  return {
    shouldShow,
    currentStep,
    onDismiss,
    updateCurrentStep,
  };
};

type NewWebhooksOnboardingStepContent = {
  readonly headline: string;
  readonly text: string;
};

const newWebhooksOnboardingStepContent: ReadonlyArray<NewWebhooksOnboardingStepContent> = [
  {
    headline: 'Improved webhooks experience!',
    text: 'Track changes in assets, content types, languages, taxonomies, and specific content items based on filters.',
  },
  {
    headline: 'Your existing webhooks still work',
    text: 'They’re just called legacy webhooks. Give the improved webhooks a try!',
  },
];

export const NewWebhooksOnboarding: React.FC<{
  readonly step: number;
  readonly element: ReactNode;
}> = ({ step, element }) => {
  const { shouldShow, currentStep, onDismiss, updateCurrentStep } = useNewWebhooksOnboarding();

  const currentContent = newWebhooksOnboardingStepContent[currentStep - 1];

  return (
    <OnboardingPopover
      headlineText={currentContent?.headline ?? ''}
      isVisible={shouldShow && currentStep === step}
      onDismiss={onDismiss}
      placement="right"
      currentStep={currentStep}
      totalSteps={newWebhooksOnboardingStepContent.length}
      onNext={() => updateCurrentStep(currentStep + 1)}
      showPrimaryButton
      renderTarget={(targetProps) => <div {...targetProps}>{element}</div>}
    >
      <Box>
        <p>{currentContent?.text}</p>
      </Box>
    </OnboardingPopover>
  );
};

NewWebhooksOnboarding.displayName = 'NewWebhooksOnboarding';
