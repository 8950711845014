import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { AutoScrollTarget } from '../../actions/sharedActions.ts';
import {
  Shared_ReturnScrollId_Apply,
  Shared_ReturnScrollId_Remember,
  Shared_ScrollId_Remember,
} from '../../constants/sharedActionTypes.ts';

const initialState: ReadonlyMap<string, AutoScrollTarget> = new Map<string, AutoScrollTarget>();

export function returnAutoScrollTargetByPath(
  state = initialState,
  action: Action,
): ReadonlyMap<string, AutoScrollTarget> {
  switch (action.type) {
    // Reset remembered scrolling upon displaying content inventory to avoid initial scrolling within the first entry to content item
    case ContentListing_Init_Started:
      return initialState;

    case Shared_ScrollId_Remember: {
      const { target, returnTarget } = action.payload;
      return returnTarget ? Collection.add(state, [target.path, returnTarget]) : state;
    }

    case Shared_ReturnScrollId_Remember: {
      const { targetPath, returnTarget } = action.payload;
      return Collection.add(state, [targetPath, returnTarget]);
    }

    case Shared_ReturnScrollId_Apply: {
      const { targetPath } = action.payload;
      return Collection.remove(state, targetPath);
    }

    default:
      return state;
  }
}
