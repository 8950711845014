import { EntityInstance } from 'draft-js';
import { AiActionSource } from '../../../../../_shared/models/events/AiActionEventData.type.ts';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import { EntityType, getEntityType } from '../../entityApi/api/Entity.ts';

export type AiInstructionData = {
  readonly aiSessionId: Uuid;
  readonly instruction?: string;
};

export interface AiInstructionEntity extends EntityInstance {
  getData(): AiInstructionData;
}

export type NewAiInstructionData = AiInstructionData & {
  readonly source: AiActionSource;
};

interface NewAiInstructionEntity extends EntityInstance {
  getData(): NewAiInstructionData;
}

export type FinishedAiInstructionData = AiInstructionData & {
  readonly error?: string;
  readonly instruction: string;
  readonly isFinished?: boolean;
  readonly lastModifiedAt: DateTimeStamp;
};

interface FinishedAiInstructionEntity extends EntityInstance {
  getData(): FinishedAiInstructionData;
}

export const isAiInstruction = (entity: EntityInstance): entity is AiInstructionEntity =>
  !!entity && getEntityType(entity) === EntityType.AiInstruction;

export const isNewAiInstruction = (entity: EntityInstance): entity is NewAiInstructionEntity =>
  isAiInstruction(entity) && entity.getData() && entity.getData().instruction === undefined;

export const isFinishedAiInstruction = (
  entity: EntityInstance,
): entity is FinishedAiInstructionEntity =>
  isAiInstruction(entity) && entity.getData() && isString(entity.getData().instruction);
