import React, { useMemo } from 'react';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isAiImageDescriptionEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { canUserViewContentInDefaultLanguage } from '../../../../../_shared/utils/permissions/canUserViewContentInDefaultLanguage.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import {
  EmptyTextTypeElement,
  ITextTypeElement,
} from '../../../content/models/contentTypeElements/TextTypeElement.ts';
import { AssetDescriptionWithAiGenerateAction } from '../../components/AssetEditing/AssetDescriptionWithAiGenerateAction.tsx';
import { AssetDescriptionWithAiTranslateAction } from '../../components/AssetEditing/AssetDescriptionWithAiTranslateAction.tsx';
import { AssetDescription as AssetDescriptionComponent } from '../../components/AssetEditing/Description/AssetDescription.tsx';
import { CopyFromLanguageButton } from '../../components/AssetEditing/Description/CopyFromLanguageButton.tsx';
import { aiIsWorkingOnDescription } from '../../constants/aiConstants.ts';
import { matchAnyTranslateDescriptionAiTasksInProgress } from '../../utils/matchAnyTranslateDescriptionAiTasksInProgress.tsx';
import { matchDescribeImageAiTasks } from '../../utils/matchDescribeImageAiTasks.tsx';

interface IProps {
  readonly assetId: Uuid;
  readonly descriptionLabel: string;
  readonly isEditable: boolean;
  readonly isImage: boolean;
  readonly language: ILanguage;
  readonly languageDescription: string;
  readonly onChangeDescription: (
    variantId: Uuid,
    newDescription: string,
    isAiGenerated: boolean,
  ) => void;
  readonly onCopyDescription: (sourceVariantId: Uuid, targetVariantId: Uuid) => void;
  readonly otherNonEmptyLanguages: ReadonlyArray<ILanguage>;
}

export const AssetDescription: React.FC<IProps> = ({
  assetId,
  descriptionLabel,
  isEditable,
  isImage,
  language,
  languageDescription,
  onChangeDescription,
  onCopyDescription,
  otherNonEmptyLanguages,
}) => {
  const defaultLanguage = useSelector((s) => s.data.languages.defaultLanguage);
  const canDescriptionBeGeneratedByAi = useSelector(
    (s) =>
      s.data.languages.defaultLanguage.id === language.id &&
      isAiImageDescriptionEnabled(s) &&
      isImage,
  );
  const canDescriptionBeTranslatedByAi = useSelector(
    (s) =>
      s.data.languages.defaultLanguage.id !== language.id &&
      isAiImageDescriptionEnabled(s) &&
      canUserViewContentInDefaultLanguage(s) &&
      isImage,
  );
  const defaultLanguageDescription = useSelector((s) =>
    s.assetLibraryApp.assetUiEditor.editedAsset?.descriptions.get(
      s.data.languages.defaultLanguage.id,
    ),
  );

  const basePlaceholder = isImage
    ? 'Describe what the image shows'
    : 'Write a description of the asset';
  const copyPlaceholder =
    otherNonEmptyLanguages.length > 0 ? ' or copy it from another language.' : '.';
  const placeholder = basePlaceholder + copyPlaceholder;

  const typeElement: ITextTypeElement = useMemo(
    () =>
      ({
        ...EmptyTextTypeElement,
        codename: language.id,
        elementId: language.id,
        guidelines: isImage ? 'This description will be used as an Alt text.' : '',
        name: language.name,
      }) as const,
    [language, isImage],
  );

  const describeImageProgress = useAiTaskProgress(matchDescribeImageAiTasks(assetId));
  const translateDescriptionProgress = useAiTaskProgress(
    matchAnyTranslateDescriptionAiTasksInProgress(assetId),
  );
  const isAiWorkingOnDescription =
    describeImageProgress === AiTaskProgress.Running ||
    translateDescriptionProgress === AiTaskProgress.Running;

  const copyButton = (
    <CopyFromLanguageButton
      disabledTooltipText={isAiWorkingOnDescription ? aiIsWorkingOnDescription : undefined}
      languages={otherNonEmptyLanguages}
      onCopyFrom={(variantId) => onCopyDescription(variantId, language.id)}
    />
  );

  if (canDescriptionBeGeneratedByAi) {
    return (
      <AssetDescriptionWithAiGenerateAction
        assetId={assetId}
        descriptionLabel={descriptionLabel}
        isEditable={isEditable}
        language={language}
        languageDescription={languageDescription}
        onChangeDescription={onChangeDescription}
        placeholder={placeholder}
        typeElement={typeElement}
        copyFromOtherLanguageButton={copyButton}
      />
    );
  }

  if (canDescriptionBeTranslatedByAi && defaultLanguageDescription) {
    return (
      <AssetDescriptionWithAiTranslateAction
        assetId={assetId}
        descriptionLabel={descriptionLabel}
        isEditable={isEditable}
        defaultDescription={defaultLanguageDescription}
        defaultLanguage={defaultLanguage}
        language={language}
        languageDescription={languageDescription}
        onChangeDescription={onChangeDescription}
        placeholder={placeholder}
        typeElement={typeElement}
        copyFromOtherLanguageButton={copyButton}
      />
    );
  }

  return (
    <AssetDescriptionComponent
      buttons={copyButton}
      descriptionLabel={descriptionLabel}
      isEditable={isEditable}
      language={language}
      languageDescription={languageDescription}
      onChangeDescription={(variantId, newDescription) =>
        onChangeDescription(variantId, newDescription, false)
      }
      placeholder={placeholder}
      typeElement={typeElement}
    />
  );
};

AssetDescription.displayName = 'AssetDescription';
