import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_Creator_Canceled,
  Collections_Creator_Opened,
  Collections_Creator_Submitted,
  Collections_Listing_Initialized,
} from '../../constants/actionTypes.ts';

export const isCreatorOpen = (state = false, action: Action): boolean => {
  switch (action.type) {
    case Collections_Creator_Opened:
      return true;

    case Collections_Creator_Canceled:
    case Collections_Creator_Submitted:
    case Collections_Listing_Initialized:
      return false;

    default:
      return state;
  }
};
