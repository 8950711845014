import { Identifier } from 'dnd-core';

export enum DndTypes {
  Asset_Item_Element_Dnd_Identifier = 'Asset_Item_Element_Dnd_Identifier',
  Asset_Type_Element_Dnd_Identifier = 'Asset_Type_Element_Dnd_Identifier',
  Collection_Move = 'Collection_Move',
  Content_Element_Add = 'Content_Element_Add',
  Content_Element_Move = 'Content_Element_Move',
  Content_Group_Move = 'Content_Group_Move',
  Language_Bar_Move = 'Language_Bar_Move',
  Multiple_Choice_Option_Move = 'Multiple_Choice_Option_Move',
  Related_Content_Item_Dnd_Identifier = 'Related_Content_Item_Dnd_Identifier',
  Rich_Text_ContentComponent = 'Rich_Text_ContentComponent',
  Rich_Text_Image = 'Rich_Text_Image',
  Rich_Text_ModularContent = 'Rich_Text_ModularContent',
  Taxonomy_Node_Move = 'Taxonomy_Node_Move',
  Sitemap_Node_Move = 'Sitemap_Node_Move',
  WorkflowStep_Bar_Move = 'WorkflowStep_Bar_Move',
}

export const AllRichTextObjectBlocks: ReadonlyArray<Identifier> = [
  DndTypes.Rich_Text_ContentComponent,
  DndTypes.Rich_Text_Image,
  DndTypes.Rich_Text_ModularContent,
];
