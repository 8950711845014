import { SubscriptionUserListingConfigStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { ByStatus } from '../_shared/constants/userListingFilter.ts';
import { OrderBy, OrderByDirection } from '../_shared/models/OrderBy.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';
import {
  ISubscriptionUsersListingFilter,
  emptySubscriptionListingUserFilter,
} from '../applications/subscriptionManagement/SubscriptionUsers/models/SubscriptionUserListingFilter.ts';
import {
  SubscriptionUserListingColumnCode,
  defaultOrderBy,
} from '../applications/subscriptionManagement/SubscriptionUsers/reducers/subscriptionUsersListingUi/sortBy.ts';

export type SubscriptionUserListingConfig = ISubscriptionUsersListingFilter &
  OrderBy<SubscriptionUserListingColumnCode>;

export const subscriptionUserListingConfigStorage = {
  save: (subscriptionId: Uuid, config: SubscriptionUserListingConfig) => {
    localStorage.set(
      getStorageKey(subscriptionId),
      JSON.stringify({
        ...config,
        byCollection: [...config.byCollection],
        byRole: [...config.byRole],
        byLanguage: [...config.byLanguage],
      }),
    );
  },
  load: (subscriptionId: Uuid): SubscriptionUserListingConfig => {
    const data = parseConfig(subscriptionId);

    if (!data) {
      return {
        ...emptySubscriptionListingUserFilter,
        ...defaultOrderBy,
      };
    }

    return {
      byCollection: data.byCollection
        ? new Set(data.byCollection)
        : emptySubscriptionListingUserFilter.byCollection,
      byEnvironment: data.byEnvironment ?? emptySubscriptionListingUserFilter.byEnvironment,
      byLanguage: data.byLanguage
        ? new Set(data.byLanguage)
        : emptySubscriptionListingUserFilter.byLanguage,
      byName: data.byName ?? emptySubscriptionListingUserFilter.byName,
      byProject: data.byProject ?? emptySubscriptionListingUserFilter.byProject,
      byRole: data.byRole ? new Set(data.byRole) : emptySubscriptionListingUserFilter.byRole,
      byStatus: getEnumValueOrDefault(
        ByStatus,
        data.byStatus,
        emptySubscriptionListingUserFilter.byStatus,
      ),
      columnCode: getEnumValueOrDefault(
        SubscriptionUserListingColumnCode,
        data.columnCode,
        defaultOrderBy.columnCode,
      ),
      direction: getEnumValueOrDefault(OrderByDirection, data.direction, defaultOrderBy.direction),
    };
  },
  remove: (subscriptionId: Uuid) => localStorage.remove(getStorageKey(subscriptionId)),
};

const getEnumValueOrDefault = <T>(enumObject: Record<string, T>, value: T, defaultValue: T): T =>
  Object.values(enumObject).includes(value) ? value : defaultValue;

const parseConfig = (subscriptionId: Uuid) => {
  try {
    const value = localStorage.get(getStorageKey(subscriptionId)) ?? '';
    return JSON.parse(value);
  } catch {
    return null;
  }
};

const getStorageKey = (subscriptionId: Uuid) =>
  `${SubscriptionUserListingConfigStorageKey}-${subscriptionId}`;
