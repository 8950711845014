import { Dispatch, ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { tryParseApiError } from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { JsonPatchOperation } from '../../../../../../repositories/utils/jsonPatchConstants.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { getContentItemSavingOriginFromRoute } from '../../utils/itemEditingUtils.ts';
import {
  contentItemSavingFailed,
  contentItemSavingFinished,
  contentItemSavingStarted,
} from '../contentItemEditingActions.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly patchItemProperty: (
      contentItemId: Uuid,
      operation: string,
      path: string,
      value: any,
    ) => Promise<any>;
  };
  readonly trackUserEvent: (eventName: TrackedEvent) => ThunkFunction;
}

export const createSaveContentItemSitemapToServerAction =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<any> => {
    const {
      contentApp: {
        editedContentItem,
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (!editedContentItem) {
      return Promise.reject('Missing edited content item');
    }

    try {
      dispatch(contentItemSavingStarted(itemEditorOperationIds.sitemapLocation));

      const origin = getContentItemSavingOriginFromRoute(window.location.pathname);
      const itemServerModel = await deps.contentItemRepository.patchItemProperty(
        editedContentItem.id,
        JsonPatchOperation.Replace,
        itemEditorOperationIds.sitemapLocation,
        editedContentItem.sitemapLocation.toArray(),
      );

      dispatch(
        contentItemSavingFinished({
          filter,
          orderBy,
          itemPreviouslyUpdatedAt: editedContentItem.lastModifiedAt,
          itemServerModel,
          operationId: itemEditorOperationIds.sitemapLocation,
          origin,
          usedSearchMethod,
        }),
      );
    } catch (error) {
      const apiError = tryParseApiError(error);

      dispatch(
        contentItemSavingFailed({
          operationId: itemEditorOperationIds.sitemapLocation,
          apiError,
        }),
      );

      logError('Saving sitemap location failed.', error);

      throw error;
    }
  };
