import { AssetLibrary_Assets_ToggleAssetSelection } from '../../constants/assetLibraryActionTypes.ts';

export const assetSelectionToggled = (assetId: Uuid) =>
  ({
    type: AssetLibrary_Assets_ToggleAssetSelection,
    payload: {
      assetId,
    },
  }) as const;

export type ToggleAssetSelectionActionsType = ReturnType<typeof assetSelectionToggled>;
