import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IRoleRepository } from '../../../../repositories/interfaces/IRoleRepository.type.ts';
import { INormalizedProjectUserCollectionGroupsServerModel } from '../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  Data_UserNormalizedRoleWithSettings_Failed,
  Data_UserNormalizedRoleWithSettings_Started,
  Data_UserNormalizedRoleWithSettings_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'getNormalizedRoleWithSettings'>;
}

export interface INormalizedRoleWithSettingsPayload {
  readonly projectId: Uuid;
  readonly roles: INormalizedProjectUserCollectionGroupsServerModel;
}

const start = () => ({ type: Data_UserNormalizedRoleWithSettings_Started }) as const;
const success = (data: INormalizedRoleWithSettingsPayload) =>
  ({
    type: Data_UserNormalizedRoleWithSettings_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_UserNormalizedRoleWithSettings_Failed }) as const;

export type LoadNormalizedRoleWithSettingsActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadNormalizedRoleWithSettingsAction =
  ({ roleRepository }: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const roles = await roleRepository.getNormalizedRoleWithSettings(projectId, abortSignal);
      dispatch(
        success({
          projectId,
          roles,
        }),
      );
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
