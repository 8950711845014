import classNames from 'classnames';
import React, { useContext, useRef } from 'react';
import { useGradualSequence } from '../../../../../_shared/hooks/useGradualSequence.ts';
import { useScrollOnDragEvents } from '../../../../../_shared/hooks/useScrollOnDragEvents.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { DeferAutoScrollCssClass } from '../../../../../_shared/utils/autoScrollUtils.ts';
import {
  DataUiCollection,
  getDataUiActiveCapabilitiesDataAttributes,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { FloatingEditorContext } from '../../../../webSpotlight/components/preview/FloatingEditor/FloatingEditorContext.tsx';
import { CommentThreadHighlighter } from '../containers/comments/CommentThreadHighlighter.tsx';
import { FocusedCommentThreadHighlighter } from '../containers/comments/FocusedCommentThreadHighlighter.tsx';
import { FocusedCommentThreadScroller } from '../containers/comments/FocusedCommentThreadScroller.tsx';
import { ContentItemElementsAutoDispatcher } from '../containers/elements/ContentItemElementsAutoDispatcher.tsx';
import { ContentItemElementGroup } from './ContentItemElementGroup.tsx';
import { NoElementsEmptyState } from './NoElementsEmptyState.tsx';

export interface IContentItemElementsProps {
  readonly activeCapabilities: ReadonlyArray<ActiveCapabilityType>;
  readonly areAnyContentGroupsVisible: boolean;
  readonly canUpdateContent: boolean;
  readonly contentType: ICompiledContentType;
  readonly disabled: boolean;
  readonly isCollectionInMainPaneEnabled: boolean;
  readonly selectedLanguageIsoCodename: string | null;
  readonly typeElementsToRender: ReadonlyArray<TypeElement>;
  readonly workflowStepId: Uuid;
}

export const ContentItemElements: React.FC<IContentItemElementsProps> = ({
  activeCapabilities,
  areAnyContentGroupsVisible,
  canUpdateContent,
  disabled,
  isCollectionInMainPaneEnabled,
  selectedLanguageIsoCodename,
  workflowStepId,
  typeElementsToRender,
}) => {
  const canEdit = !disabled && canUpdateContent;

  const isFloatingEditor = useContext(FloatingEditorContext);
  const { renderItems, isComplete } = useGradualSequence(
    typeElementsToRender,
    (typeElement) => typeElement.elementId,
    isFloatingEditor ? undefined : { chunkSize: Number.MAX_VALUE },
  );

  const elementsRef = useRef<HTMLDivElement | null>(null);
  useScrollOnDragEvents(elementsRef);

  return (
    <React.Fragment key={workflowStepId}>
      <CommentThreadHighlighter />
      <ContentItemElementsAutoDispatcher />
      <div
        className={classNames('content-item-pane__elements-list', {
          'content-item-pane__elements-list--is-disabled': !canEdit,
          'content-item-pane__elements-list--is-compact': isFloatingEditor,
          'content-item-pane__elements-list--top-spacing':
            !areAnyContentGroupsVisible && isCollectionInMainPaneEnabled,
          [DeferAutoScrollCssClass]: !isComplete,
        })}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentElements)}
        {...getDataUiActiveCapabilitiesDataAttributes(activeCapabilities)}
        lang={selectedLanguageIsoCodename || undefined}
        ref={elementsRef}
      >
        {typeElementsToRender.length === 0 ? (
          <NoElementsEmptyState canEdit={canEdit} />
        ) : (
          renderItems.length > 0 && (
            <ContentItemElementGroup
              canEdit={canEdit}
              typeElements={renderItems}
              hasTopRoundedCorners={areAnyContentGroupsVisible || isCollectionInMainPaneEnabled}
              hasBottomRoundedCorners
            />
          )
        )}
      </div>
      <FocusedCommentThreadHighlighter />
      {/* After elements due to auto-scroll - KCL-1453 */}
      <FocusedCommentThreadScroller />
    </React.Fragment>
  );
};

ContentItemElements.displayName = 'ContentItemElements';
