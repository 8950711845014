export enum WebhookTriggerType {
  Asset = 'asset',
  ContentItemVariant = 'content_item_variant',
  ContentType = 'content_type',
  Taxonomy = 'taxonomy',
}

export interface IContentTriggerServerModel {
  readonly operations: ReadonlyArray<ContentTriggerOperation>;
  readonly type: WebhookTriggerType;
}

export interface IWorkflowTriggerServerModel {
  readonly transitionsTo: UuidArray;
  readonly type: WebhookTriggerType;
}

export enum ContentTriggerOperation {
  Archive = 'archive',
  Publish = 'publish',
  Restore = 'restore',
  Unpublish = 'unpublish',
  Upsert = 'upsert',
  Create = 'create',
}

export interface IWebhookTriggerServerModel {
  readonly deliveryApiContentChanges: ReadonlyArray<IContentTriggerServerModel>;
  readonly previewDeliveryApiContentChanges: ReadonlyArray<IContentTriggerServerModel>;
  readonly workflowStepChanges: ReadonlyArray<IWorkflowTriggerServerModel>;
  readonly managementApiContentChanges: ReadonlyArray<IContentTriggerServerModel>;
}

export interface IWebhookServerModel {
  readonly healthStatus: string;
  readonly id: Uuid;
  readonly name: string;
  readonly order: number;
  readonly secret: string;
  readonly enabled: boolean;
  readonly triggers: IWebhookTriggerServerModel;
  readonly url: string;
}
