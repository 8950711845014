import { isElement } from '@kontent-ai/DOM';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useLayoutSynchronizationReadTick,
  useLayoutSynchronizationUpdateTick,
} from '../../../../../_shared/contexts/LayoutSynchronizationContext.tsx';
import {
  IMouseMainButtonStatusChangeNotificationPayload,
  MouseMainButtonStatusObserver,
} from '../../../../richText/components/utility/MouseMainButtonStatusObserver.tsx';

const getPlaceholderId = (overlayId: string) => `${overlayId}_placeholder`;
const getViewerId = (overlayId: string) => `${overlayId}_viewer`;

type ContentOverlayPlaceholderProps = {
  readonly overlayId: string;
};

const Viewer = styled.div`
  position: absolute;
  z-index: 1;
  height: 0;
  opacity: 1;
  transform: translateX(0) translateY(0);
  // Transition from pending to normal view is delayed and smooth to prevent flicker before position of nested ContentOverlay stabilizes
  transition: opacity 100ms;
  transition-delay: 100ms;
`;

const Placeholder = styled.div``;

export const ContentOverlayPlaceholder: React.FC<ContentOverlayPlaceholderProps> = ({
  overlayId,
}) => <Placeholder id={getPlaceholderId(overlayId)} />;

ContentOverlayPlaceholder.displayName = 'ContentOverlayPlaceholder';

const syncThresholdPx = 2;

// Maximum level of nested components is 6, but there may be also expanded modular with content component, which makes it 13 + 2 for initial sync
const maxSyncAttempts = 15;

type Coords = {
  readonly top: number;
  readonly left: number;
};

type Delta = Coords & {
  readonly height: number;
};

const getDelta = (
  offset: Coords | null,
  newOffset: Coords,
  height: number | null,
  newHeight: number,
): Delta => ({
  top: Math.abs(offset ? offset.top - newOffset.top : newOffset.top),
  left: Math.abs(offset ? offset.left - newOffset.left : newOffset.left),
  height: Math.abs(height ? height - newHeight : newHeight),
});

const isDeltaTheSame = (delta: Delta | null, newDelta: Delta) =>
  delta &&
  delta.top === newDelta.top &&
  delta.left === newDelta.left &&
  delta.height === newDelta.height;

const isDeltaLargeEnough = (newDelta: Delta) =>
  newDelta.top >= syncThresholdPx ||
  newDelta.left >= syncThresholdPx ||
  newDelta.height >= syncThresholdPx;

enum Phase {
  GetContentHeight = 'GetContentHeight',
  UpdateHeight = 'UpdateHeight',
  GetOffset = 'GetOffset',
  UpdateOffset = 'UpdateOffset',
  CheckValues = 'CheckValues',
  UpdateValues = 'UpdateValues',
}

const getContentHeight = (viewerElement: HTMLElement | null): number | null => {
  if (!viewerElement) {
    return null;
  }

  const contentElement = viewerElement.childNodes[0];
  const contentHeight = isElement(contentElement)
    ? contentElement.getBoundingClientRect().height
    : null;

  return contentHeight;
};

const getTargetOffset = (
  placeholder: HTMLElement | null,
  viewerElement: HTMLElement | null,
): Coords | null => {
  if (!viewerElement || !placeholder) {
    return null;
  }

  const parent = viewerElement.parentElement;
  if (!parent) {
    return null;
  }

  const placeholderRect = placeholder.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  const targetOffset = {
    top: placeholderRect.top - parentRect.top,
    left: placeholderRect.left - parentRect.left,
  };

  return targetOffset;
};

const useIsExternalSelectionInProgress = (viewerRef: React.RefObject<HTMLElement>) => {
  const mouseClickOriginIsOutside = useCallback(
    (origin: EventTarget | null): boolean => {
      if (origin && origin instanceof Node) {
        const viewerElement = viewerRef.current;
        if (viewerElement) {
          return !viewerElement.contains(origin);
        }
      }
      return false;
    },
    [viewerRef],
  );

  const [isExternalSelectionInProgress, setIsExternalSelectionInProgress] = useState(false);

  // When there is a selection in progress, but is external to the overlay content, we need to make the overlay invisible to the mouse events
  // Otherwise the DOM selection might jump to where the overlay is placed physically (typically before the rich text editor), which is unwanted
  const mouseStatusChanged = useCallback(
    (changeInfo: IMouseMainButtonStatusChangeNotificationPayload): void => {
      if (!changeInfo.isMouseDown) {
        setIsExternalSelectionInProgress(false);
        return;
      }

      // Do not observe selection which originated from this component, it is not external selection
      if (mouseClickOriginIsOutside(changeInfo.target)) {
        setIsExternalSelectionInProgress(true);
      }
    },
    [mouseClickOriginIsOutside],
  );

  return {
    isExternalSelectionInProgress,
    mouseStatusChanged,
  };
};

interface IContentOverlayPositionerProps {
  readonly ignoreMouseEvents?: boolean;
  readonly overlayId: string;
  readonly pendingClassName?: string;
  readonly viewerRef: React.RefObject<HTMLElement>;
}

const ContentOverlayPositioner: React.FC<IContentOverlayPositionerProps> = ({
  ignoreMouseEvents,
  overlayId,
  pendingClassName,
  viewerRef,
}) => {
  // We need to keep the data related to fixPosition as normal variables because
  // re-rendering based on state change from the
  // interrupts async scrolling in Firefox
  const lastDelta = useRef<Delta | null>(null);
  const syncAttempts = useRef(0);

  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [offset, setOffset] = useState<Coords | null>(null);

  const pendingHeight = useRef<number | null>(null);
  const pendingOffset = useRef<Coords | null>(null);

  const phase = useRef<Phase>(Phase.GetContentHeight);

  const getPlaceholder = useCallback((): HTMLElement | null => {
    return document.getElementById(getPlaceholderId(overlayId));
  }, [overlayId]);

  const reset = useCallback(() => {
    setOffset(null);
    setContentHeight(null);
    phase.current = Phase.GetContentHeight;
  }, []);

  const trackChanges = useCallback((): void => {
    const placeholder = getPlaceholder();
    if (!placeholder) {
      reset();
      return;
    }

    const viewerElement = viewerRef.current;
    if (!viewerElement) {
      return;
    }

    const newOffset = getTargetOffset(placeholder, viewerElement);
    const newContentHeight = getContentHeight(viewerElement);

    if (!newOffset || newContentHeight === null) {
      return;
    }

    const newDelta = getDelta(offset, newOffset, contentHeight, newContentHeight);

    if (isDeltaLargeEnough(newDelta)) {
      // Stop repositioning if several sync attempts do not succeed otherwise we may end up in an infinite loop of re-rendering
      // It may happen in case bounding boxes of placeholder and viewer are not in sync, e.g. when there is inconsistent margin
      const isSameDelta = isDeltaTheSame(lastDelta.current, newDelta);

      if (!isSameDelta || syncAttempts.current < maxSyncAttempts) {
        lastDelta.current = newDelta;
        syncAttempts.current = isSameDelta ? syncAttempts.current + 1 : 1;

        // Schedule update of values for the update tick
        pendingOffset.current = newOffset;
        pendingHeight.current = newContentHeight;
        phase.current = Phase.UpdateValues;
      }
    }
  }, [offset, contentHeight, getPlaceholder, reset, viewerRef]);

  const readLayout = useCallback(() => {
    const placeholder = getPlaceholder();
    if (!placeholder) {
      reset();
    }
    switch (phase.current) {
      case Phase.GetContentHeight: {
        const newHeight = getContentHeight(viewerRef.current);
        if (newHeight !== null) {
          pendingHeight.current = newHeight;
          phase.current = Phase.UpdateHeight;
        }
        break;
      }

      case Phase.GetOffset: {
        const newOffset = getTargetOffset(placeholder, viewerRef.current);
        if (newOffset) {
          pendingOffset.current = newOffset;
          phase.current = Phase.UpdateOffset;
        }
        break;
      }

      case Phase.CheckValues: {
        trackChanges();
        break;
      }

      default:
        break;
    }
  }, [getPlaceholder, reset, trackChanges, viewerRef]);

  const updateLayout = useCallback(() => {
    const placeholder = getPlaceholder();
    if (!placeholder) {
      reset();
    }
    switch (phase.current) {
      case Phase.UpdateHeight: {
        setContentHeight(pendingHeight.current);
        phase.current = Phase.GetOffset;
        break;
      }

      case Phase.UpdateOffset: {
        setOffset(pendingOffset.current);
        phase.current = Phase.CheckValues;
        break;
      }

      case Phase.UpdateValues: {
        setContentHeight(pendingHeight.current);
        setOffset(pendingOffset.current);
        phase.current = Phase.CheckValues;
        break;
      }

      default:
        break;
    }
  }, [getPlaceholder, reset]);

  useLayoutSynchronizationReadTick(readLayout);
  useLayoutSynchronizationUpdateTick(updateLayout);

  const { isExternalSelectionInProgress, mouseStatusChanged } =
    useIsExternalSelectionInProgress(viewerRef);

  const offsetStyle = offset
    ? // We need to use margins for offset positioning because:
      // 1) top, left don't guarantee proper offset from the original position
      // 2) transform is tampering with underlying sticky positioning https://github.com/w3c/csswg-drafts/issues/3186
      `${Viewer}#${getViewerId(overlayId)} { margin-left: ${offset.left}px; margin-top: ${
        offset.top
      }px; }`
    : // Pending
      `${Viewer}#${getViewerId(
        overlayId,
      )} { top: 0; left: 0; pointer-events: none; opacity: 0; transition: opacity 0ms; transition-delay: 0ms; }`;

  // Sync height back to the placeholder to make enough space for the viewer
  const heightStyle = contentHeight
    ? `${Placeholder}#${getPlaceholderId(overlayId)} { height: ${contentHeight}px; }`
    : '';

  const ignoreMouseStyle =
    ignoreMouseEvents || isExternalSelectionInProgress
      ? `${Viewer}#${getViewerId(overlayId)} { pointer-events: none; }`
      : '';

  const inlineStyleString = `${heightStyle}${offsetStyle}${ignoreMouseStyle}`;
  const isPending = !offset;

  return (
    <>
      <MouseMainButtonStatusObserver statusChanged={mouseStatusChanged} />
      {((isPending && pendingClassName) || inlineStyleString) && (
        <style
          className={isPending ? pendingClassName : undefined}
          dangerouslySetInnerHTML={{ __html: inlineStyleString }}
        />
      )}
    </>
  );
};

ContentOverlayPositioner.displayName = 'ContentOverlayPositioner';

interface IContentOverlayProps {
  readonly className?: string;
  readonly ignoreMouseEvents?: boolean;
  readonly overlayId: string;
  readonly pendingClassName?: string;
  readonly renderContent: () => JSX.Element;
}

// ContentOverlay allows virtual (visual) nesting of a component in another component while the nested component is placed aside (not inside) in DOM
// We use in to nest complex content in rich text editor / viewer
// Known problems that it solves
// 1) When selecting rich text editor content with keyboard, the caret doesn't jump over an expanded content item / content component
//    as the next selection point is inside it
// 2) DraftJS had a problem with selection consistency throwing exceptions when selection landed in a nested editor
//    (block key that wasn't known to the current editor). This should now be fixed (error suppressed)
export const ContentOverlay: React.FC<IContentOverlayProps> = ({
  className,
  ignoreMouseEvents,
  overlayId,
  pendingClassName,
  renderContent,
}) => {
  const viewerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Viewer id={getViewerId(overlayId)} className={className} ref={viewerRef}>
      {renderContent()}
      <ContentOverlayPositioner
        ignoreMouseEvents={ignoreMouseEvents}
        overlayId={overlayId}
        pendingClassName={pendingClassName}
        viewerRef={viewerRef}
      />
    </Viewer>
  );
};

ContentOverlay.displayName = 'ContentOverlay';
