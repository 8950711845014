import React, { useEffect, useState } from 'react';
import { IItemChangedDetails } from '../../../../../types/CustomElementApi.ts';
import { IItemObservedData } from './CustomElementSandbox.tsx';

interface IItemChangeObserverProps {
  readonly onItemDetailsChange: (itemDetails: IItemChangedDetails) => void;
  readonly itemDataToObserve: IItemObservedData;
}

export const ItemChangeObserver: React.FC<IItemChangeObserverProps> = ({
  itemDataToObserve,
  onItemDetailsChange,
}) => {
  const [previousState, setPreviousState] = useState<IItemObservedData>(itemDataToObserve);

  useEffect(() => {
    if (itemDataToObserve !== previousState) {
      setPreviousState(itemDataToObserve);

      // Defer the notification after React cycle finishes to not intercept with the React life cycle with a callback that could modify something
      window.setTimeout(() => onItemDetailsChange(itemDataToObserve), 0);
    }
  }, [itemDataToObserve, onItemDetailsChange, previousState]);

  return null;
};

ItemChangeObserver.displayName = 'ItemChangeObserver';
