import { isWithinInterval } from 'date-fns';
import {
  getMaximalExpiration,
  getMinimalExpiration,
} from '../../../../_shared/constants/apiKeyExpirations.ts';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { getExpirationDateErrorMessage } from '../../../../_shared/utils/apiKeys/getApiKeyErrorMessage.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';

const isDateInRange = (expirationDate: string, minDate: Date, maxDate: Date): boolean =>
  isWithinInterval(new Date(expirationDate), {
    start: minDate,
    end: maxDate,
  });

const isExpirationDateValid = (
  expirationDate: DateTime,
  minExpirationDate: Date,
  maxExpirationDate: Date,
) =>
  !isEmptyOrWhitespace(expirationDate.value) &&
  expirationDate.isValid &&
  isDateInRange(expirationDate.value, minExpirationDate, maxExpirationDate);

export const expirationDateIsValidBuilder = (
  apiKeyType: ApiKeyType.DAPI | ApiKeyType.MAPIPat | ApiKeyType.MAPI,
): ((expirationDate: DateTime) => string | undefined) => {
  return (expirationDate: DateTime) => {
    const minExpirationDate = getMinimalExpiration(apiKeyType);
    const maxExpirationDate = getMaximalExpiration(apiKeyType);

    const errorMessage = getExpirationDateErrorMessage(minExpirationDate, maxExpirationDate);

    return isExpirationDateValid(expirationDate, minExpirationDate, maxExpirationDate)
      ? undefined
      : errorMessage;
  };
};
