enum Punctuation {
  Hyphen = '-',
  EnDash = '–',
  EmDash = '—',
}

const wrapWithSpaces = (text: string): string => {
  const space = ' ';
  return `${space}${text}${space}`;
};

const enDashShorthand = wrapWithSpaces(`${Punctuation.Hyphen}${Punctuation.Hyphen}`);
const emDashShorthand = wrapWithSpaces(
  `${Punctuation.Hyphen}${Punctuation.Hyphen}${Punctuation.Hyphen}`,
);

export enum ConversionResult {
  NoConversion = 'NoConversion',
  ConvertToEnDash = 'ConvertToEnDash',
  ConvertToEmDash = 'ConvertToEmDash',
}

type Conversion =
  | { readonly result: ConversionResult.NoConversion }
  | {
      readonly result: ConversionResult;
      readonly convertedFrom: string;
      readonly convertedTo: string;
    };

const getSubstringFromEnd = (text: string, length: number): string =>
  text.substr(text.length - length, text.length);

export const evaluateDashConversion = (evaluatedChars: string): Conversion => {
  if (getSubstringFromEnd(evaluatedChars, emDashShorthand.length) === emDashShorthand) {
    return {
      result: ConversionResult.ConvertToEmDash,
      convertedFrom: getSubstringFromEnd(evaluatedChars, emDashShorthand.length),
      convertedTo: wrapWithSpaces(Punctuation.EmDash),
    };
  }

  if (getSubstringFromEnd(evaluatedChars, enDashShorthand.length) === enDashShorthand) {
    return {
      result: ConversionResult.ConvertToEnDash,
      convertedFrom: getSubstringFromEnd(evaluatedChars, enDashShorthand.length),
      convertedTo: wrapWithSpaces(Punctuation.EnDash),
    };
  }

  return { result: ConversionResult.NoConversion };
};
