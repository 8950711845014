import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { pluralizeWithoutCount } from '../../../../../_shared/utils/stringUtils.ts';
import { SpaceNamesMetadata, getCollapsedSpaceNames } from '../../../spaces/utils/spacesUtils.ts';

type Props = {
  readonly spaceData: SpaceNamesMetadata;
};

const StyledContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colorTextLowEmphasis};
`;

export const CollectionBarSpaces: React.FC<Props> = ({ spaceData }) => {
  const hasAnySpace = spaceData.usedSpaces.length > 0;
  const displayNames = getCollapsedSpaceNames(spaceData);

  const displayText = hasAnySpace ? displayNames.join(', ') : 'Without space';
  const tooltipText = `${pluralizeWithoutCount(
    'Space',
    spaceData.usedSpaces.length,
  )}: ${spaceData.usedSpaces.map(({ name }) => name).join(', ')}`;

  return (
    <StyledContainer>
      <Tooltip placement="bottom-start" tooltipText={hasAnySpace ? tooltipText : undefined}>
        <span>{displayText}</span>
      </Tooltip>
    </StyledContainer>
  );
};

CollectionBarSpaces.displayName = 'CollectionBarSpaces';
