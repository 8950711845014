import { Action } from '../../../@types/Action.type.ts';
import {
  Shared_SignalR_Connected,
  Shared_SignalR_ConnectingFailed,
} from '../../constants/sharedActionTypes.ts';
import { SignalRConnection } from '../../models/signalRConnection.type.ts';

const initialValue: SignalRConnection = {
  lastConnectionFailReason: null,
};

export function signalRConnection(
  state: SignalRConnection = initialValue,
  action: Action,
): SignalRConnection {
  switch (action.type) {
    case Shared_SignalR_Connected:
      return initialValue;

    case Shared_SignalR_ConnectingFailed: {
      return action.payload;
    }

    default:
      return state;
  }
}
