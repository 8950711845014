import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ContentState, EditorState } from 'draft-js';
import { forwardRef, useMemo } from 'react';
import { ActionMenuPositioner } from '../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createCopyToClipboardAiAction,
  createDiscardAiActionFromResult,
  createRejectSuggestionAiSection,
  createReplaceSelectionAiAction,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { AiResult } from '../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import {
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { AiSuggestionError } from '../../components/AiSuggestionError.tsx';
import { RichAiSuggestionViewer } from '../../containers/viewers/RichAiSuggestionViewer.tsx';

const feedbackReasons = createFeedbackReasons([
  SpecificFeedbackReason.ResultTooShort,
  SpecificFeedbackReason.ResultTooLong,
  SpecificFeedbackReason.ResultSlow,
  SpecificFeedbackReason.ContentChangedMeaning,
]);

type MakeShorterActionProps = {
  readonly onCopyToClipboard?: () => void;
  readonly onDiscard: () => void;
  readonly onReplaceSelection?: () => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
  readonly result: ActionResult<ContentState>;
  readonly resultWidth: number;
};

export const MakeShorterAction = forwardRef<HTMLElement, MakeShorterActionProps>(
  (
    {
      onCopyToClipboard,
      onDiscard,
      onReplaceSelection,
      onTryAgain,
      preferMenuOnTop,
      result,
      resultWidth,
    },
    ref,
  ) => {
    const menuOptions = [
      createUseSuggestionAiSection(
        [
          createReplaceSelectionAiAction(onReplaceSelection),
          createCopyToClipboardAiAction(onCopyToClipboard),
        ],
        result,
      ),
      createRejectSuggestionAiSection([
        createTryAgainAiAction(result, onTryAgain),
        createDiscardAiActionFromResult(onDiscard, result),
      ]),
    ];

    const resultEditorState = useMemo(
      () => (result.content ? EditorState.createWithContent(result.content) : null),
      [result.content],
    );
    const hasResult = !!result.error || !!resultEditorState;

    return (
      <ActionMenuPositioner
        preferMenuOnTop={preferMenuOnTop}
        ref={ref}
        renderMenu={() => <AiActionMenu options={menuOptions} />}
        renderResult={(resultRef) => (
          <AiResult
            actionName={AiActionName.MakeShorter}
            actionTitle="Make shorter"
            feedbackReasons={feedbackReasons}
            isLoading={!result.isFinished}
            ref={resultRef}
            renderResult={() =>
              hasResult && (
                <Stack spacing={Spacing.L}>
                  {result.error && <AiSuggestionError error={result.error} />}
                  {resultEditorState && <RichAiSuggestionViewer editorState={resultEditorState} />}
                </Stack>
              )
            }
            width={resultWidth}
          />
        )}
      />
    );
  },
);

MakeShorterAction.displayName = 'MakeShorterAction';
