import React from 'react';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { ColumnSettingsSource } from '../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { ScrollTableHeadColumn } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import {
  DataUiCollection,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ColumnSettings } from '../../containers/ContentStatusScrollTable/ColumnSettings.tsx';

export type ContentItemScrollTableHeadProps = {
  readonly hiddenColumns: ReadonlySet<ItemColumnCode>;
  readonly source: ColumnSettingsSource;
};

export const ContentStatusScrollTableHead: React.FC<ContentItemScrollTableHeadProps> = ({
  hiddenColumns,
  source,
}) => (
  <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
    <ScrollTableHeadColumn
      dataUiElement={DataUiElement.ColumnHeaderName}
      name={translateColumnCodeToTitle(ItemColumnCode.Name)}
      size={10}
      isGrowing
    />
    {!hiddenColumns.has(ItemColumnCode.ContentType) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderContentType}
        name={translateColumnCodeToTitle(ItemColumnCode.ContentType)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Collection) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderCollection}
        name={translateColumnCodeToTitle(ItemColumnCode.Collection)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Space) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderSpace}
        name={translateColumnCodeToTitle(ItemColumnCode.Space)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Language) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderLanguage}
        name={translateColumnCodeToTitle(ItemColumnCode.Language)}
        size={7}
        isGrowing
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Contributors) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderContributors}
        name={translateColumnCodeToTitle(ItemColumnCode.Contributors)}
        size={4}
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.DueDate) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderDueDate}
        name={translateColumnCodeToTitle(ItemColumnCode.DueDate)}
        size={10}
        isGrowing
        orderByDirection={OrderByDirection.Ascending}
      />
    )}

    {!hiddenColumns.has(ItemColumnCode.WorkflowStep) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderWorkflowStep}
        name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
        size={4.5}
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Comments) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderComments}
        name={translateColumnCodeToTitle(ItemColumnCode.Comments)}
        size={3}
      />
    )}
    {!hiddenColumns.has(ItemColumnCode.Tasks) && (
      <ScrollTableHeadColumn
        dataUiElement={DataUiElement.ColumnHeaderTasks}
        name={translateColumnCodeToTitle(ItemColumnCode.Tasks)}
        size={2}
      />
    )}
    <ColumnSettings source={source} />
  </ScrollTableHeadRow>
);

ContentStatusScrollTableHead.displayName = 'ContentItemScrollTableHead';
