import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  getSelectedLanguageId,
  isSelectedLanguageIdEnsured,
} from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { getListingLanguageSwitcherOptionsCreator } from '../../../_shared/utils/languageSwitchers/getListingLanguageSwitcherOptions.ts';
import { getPathWithReplacedVariantIdForMissionControlLanguageDependentRoutes } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentListingLanguageSwitcher } from '../../contentInventory/content/features/ContentItemInventory/components/ContentListingLanguageSwitcher/ContentListingLanguageSwitcher.tsx';

const getMissionControlLanguageSwitcherOptions = getListingLanguageSwitcherOptionsCreator(
  getPathWithReplacedVariantIdForMissionControlLanguageDependentRoutes,
);

export const MissionControlLanguageSwitcher: React.FC = () => {
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const isSelectedLanguageEnsured = useSelector(isSelectedLanguageIdEnsured);

  const { pathname } = useLocation();
  const languages = useSelector((s) =>
    getMissionControlLanguageSwitcherOptions(
      getCurrentProjectId(s),
      s.data.user,
      s.data.languages,
      pathname,
    ),
  );

  return (
    <ContentListingLanguageSwitcher
      isSelectedLanguageEnsured={isSelectedLanguageEnsured}
      languages={languages}
      selectedLanguageId={selectedLanguageId}
    />
  );
};
