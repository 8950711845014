import { Button } from '@kontent-ai/component-library/Button';
import Immutable from 'immutable';
import { FC, useRef, useState } from 'react';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { ShortcutSymbols } from '../../../../_shared/constants/shortcutSymbols.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentItemsSearchArea } from '../../../contentInventory/content/components/ContentItemsSearchArea.tsx';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { ContentItemScrollTableEmptyState } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';
import { ContentItemScrollTableTitle } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTableTitle.tsx';
import { ContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { EnsureInventoryRelatedData } from '../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { PreselectedFilterIds } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { EnsureFullTextSearchStatus } from '../../../contentInventory/shared/containers/EnsureFullTextSearchStatus.tsx';
import { ModalDialog } from '../../../itemEditor/features/ContentItemEditing/components/modalDialog/ModalDialog.tsx';
import { MultipleContentItemsSelectorScrollTableHead } from '../containers/MultipleContentItemsSelectorScrollTable/MultipleContentItemsSelectorScrollTableHead.tsx';
import { MultipleContentItemsSelectorScrollTableRow } from '../containers/MultipleContentItemsSelectorScrollTable/MultipleContentItemsSelectorScrollTableRow.tsx';

type Props = {
  readonly filterContentItems: () => void;
  readonly hideVariantSpecificInfo?: boolean;
  readonly isFilterInitialized: boolean;
  readonly isInitRetrieving: boolean;
  readonly itemIsAlreadyLinkedMsg?: string;
  readonly items: ReadonlyArray<IListingContentItem | null>;
  readonly listingItemsLoadingStatus: LoadingStatus;
  readonly loadContentItems: ThunkPromise;
  readonly onAssignSelectedEntries: () => void;
  readonly onClearFilter: () => void;
  readonly onCloseSelector: () => void;
  readonly onItemDoubleClick?: (contentItemId: Uuid) => void;
  readonly preselectedFilterIds?: PreselectedFilterIds;
  readonly renderScrollTable: boolean;
  readonly selectedEntries: Immutable.Set<Uuid>;
  readonly submitButtonText?: string;
  readonly titleBarText: string;
};

export const ModalMultipleContentItemsSelector: FC<Props> = (props) => {
  const contentPaneRef = useRef<HTMLDivElement>(null);
  const [isAssigning, setIsAssigning] = useState(false);

  const addRelatedEntries = (): void => {
    if (!isAssigning && !props.selectedEntries.isEmpty()) {
      props.onAssignSelectedEntries();
      setIsAssigning(true);
    }
  };

  const isAssignDisabled = isAssigning || props.selectedEntries.isEmpty();
  const submitButtonText = props.submitButtonText || 'Insert';
  const disabledSubmitButtonTooltip = props.selectedEntries.isEmpty()
    ? 'Select at least one content item'
    : undefined;

  return (
    <ModalDialog
      buttons={
        <>
          <Button
            tooltipText="Cancel"
            tooltipPlacement="top"
            tooltipShortcuts={ShortcutSymbols.Escape}
            buttonStyle="secondary"
            type="button"
            onClick={props.onCloseSelector}
            {...getDataUiActionAttribute(DataUiAction.Cancel)}
          >
            Cancel
          </Button>
          <Button
            tooltipText={isAssignDisabled ? disabledSubmitButtonTooltip : submitButtonText}
            tooltipPlacement="top"
            tooltipShortcuts={isAssignDisabled ? undefined : ShortcutSymbols.Enter}
            buttonStyle="primary"
            type="button"
            onClick={isAssignDisabled ? undefined : addRelatedEntries}
            disabled={isAssignDisabled}
            {...getDataUiActionAttribute(DataUiAction.Insert)}
          >
            {submitButtonText}
          </Button>
        </>
      }
      onCancel={props.onCloseSelector}
      onSubmit={isAssignDisabled ? undefined : addRelatedEntries}
      titleBarText={props.titleBarText}
    >
      <div className="canvas__workspace canvas__workspace--in-modal">
        <EnsureInventoryRelatedData>
          <ContentItemFilter
            clearFilter={props.onClearFilter}
            hideVariantSpecificInfo={props.hideVariantSpecificInfo}
            listingItemsLoadingStatus={props.listingItemsLoadingStatus}
            onFilterChange={props.filterContentItems}
            origin={ContentItemFilterOrigin.ModalMultipleContentItemsSelector}
            preselectedFilterIds={props.preselectedFilterIds}
          />
          <section className="canvas__content">
            <div className="canvas__notifications">
              <NotificationBar />
            </div>
            <div
              ref={contentPaneRef}
              className="canvas__content-pane
                           canvas__content-pane--no-bottom-offset
                           canvas__content-pane--without-vertical-scrollbar
                           canvas__content-pane--in-modal"
            >
              <div className="row">
                <div className="col-sm-24">
                  <EnsureFullTextSearchStatus>
                    <ContentItemsSearchArea
                      isFullTextSearchForceDisabled={props.hideVariantSpecificInfo}
                      isInDialog
                    />
                    {props.renderScrollTable ? (
                      <ContentItemScrollTable
                        ariaLabel="content item"
                        items={props.items}
                        onLoadContentItems={props.loadContentItems}
                        onItemDoubleClick={props.onItemDoubleClick}
                        parentContainerRef={contentPaneRef}
                        renderEmptyState={() => <ContentItemScrollTableEmptyState isInDialog />}
                        renderRowItem={(params) => (
                          <MultipleContentItemsSelectorScrollTableRow
                            hideVariantSpecificInfo={props.hideVariantSpecificInfo}
                            index={params.index}
                            item={params.item}
                            itemIsAlreadyLinkedMsg={props.itemIsAlreadyLinkedMsg}
                            key={params.item.item.id}
                            onItemDoubleClick={params.onItemDoubleClick}
                          />
                        )}
                        renderTableHead={() => (
                          <MultipleContentItemsSelectorScrollTableHead
                            hideVariantSpecificInfo={props.hideVariantSpecificInfo}
                          />
                        )}
                        renderTableTitle={() => <ContentItemScrollTableTitle />}
                        withColumnSettings={!props.hideVariantSpecificInfo}
                      />
                    ) : (
                      <Loader />
                    )}
                  </EnsureFullTextSearchStatus>
                </div>
              </div>
            </div>
          </section>
        </EnsureInventoryRelatedData>
      </div>
    </ModalDialog>
  );
};
