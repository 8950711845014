import { IRichTextTypeElement } from '../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import {
  IRichTextItemElement,
  newRichTextItemElement,
} from '../../models/contentItemElements/RichTextItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createRichTextItemElement: ItemElementCreator<
  IRichTextTypeElement,
  IRichTextItemElement
> = (richTextTypeElement): IRichTextItemElement =>
  newRichTextItemElement({
    elementId: richTextTypeElement.elementId,
  });
