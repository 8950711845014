import { isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import { useAttachRef } from '@kontent-ai/hooks';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { Box } from '../../../layout/Box/Box.tsx';
import { px } from '../../../tokens/utils/utils.ts';
import { verticalMenuScrollablePartPadding } from '../decisionTokens.ts';

type StyledScrollableContainerProps = {
  readonly scrollbarWidth: number;
};

const StyledScrollableContainer = styled(Box).attrs({
  overflowX: 'hidden',
  overflowY: 'auto',
})<StyledScrollableContainerProps>`
  padding-right: ${({ scrollbarWidth }) => px(scrollbarWidth)};

  @supports (scrollbar-gutter: stable) {
    & {
      // we can’t use padding in Chrome, because elements grow with their scrollbar in there (unlike in Safari or Firefox) — padding results in extra spacing
      padding-right: 0;
      scrollbar-gutter: ${({ scrollbarWidth }) => (scrollbarWidth ? 'stable' : undefined)};
    }
  }
`;

const StyledScrollableInnerContainer = styled(Box).attrs({
  paddingY: verticalMenuScrollablePartPadding,
})<StyledScrollableContainerProps>`
  width: ${({ scrollbarWidth }) =>
    scrollbarWidth ? `calc(100% + ${scrollbarWidth}px)` : undefined};

  @supports (scrollbar-gutter: stable) {
    & {
      width: auto;
    }
  }
`;

export const ScrollableContainer = React.forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, forwardedRef) => {
    const [scrollbarWidth, setScrollbarWidth] = React.useState<number>(0);
    const { refToForward, refObject } = useAttachRef<HTMLDivElement>(forwardedRef);

    useResizeObserver({
      ref: refObject,
      onResize: () => {
        const scrollableContainerElement: HTMLDivElement | null = refObject.current;

        if (scrollableContainerElement) {
          const isOverflowing = isElementVerticallyOverflowing(scrollableContainerElement);
          setScrollbarWidth(
            isOverflowing
              ? scrollableContainerElement.offsetWidth - scrollableContainerElement.clientWidth
              : 0,
          );
        }
      },
    });

    return (
      <StyledScrollableContainer ref={refToForward} scrollbarWidth={scrollbarWidth}>
        <StyledScrollableInnerContainer scrollbarWidth={scrollbarWidth}>
          {children}
        </StyledScrollableInnerContainer>
      </StyledScrollableContainer>
    );
  },
);

ScrollableContainer.displayName = 'ScrollableContainer';
