import { History } from 'history';
import { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Action } from '../../@types/Action.type.ts';
import { ThunkPromise } from '../../@types/Dispatcher.type.ts';
import { Loader } from '../components/Loader.tsx';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { useThunkPromise } from '../hooks/useThunkPromise.ts';
import { IStore } from '../stores/IStore.type.ts';

type Props = {
  readonly children: ReactNode;
  readonly currentRouteVariantId: Uuid | undefined;
  readonly getSelectedLanguageIdFromStore: (state: IStore) => Uuid | undefined | null;
  readonly ensureSelectedVariant: (
    currentRouteVariantId: Uuid | undefined,
    history: History,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly localizedRouteLeft: () => Action;
};

export const EnsureSelectedVariantIdInRoute = ({
  children,
  currentRouteVariantId,
  getSelectedLanguageIdFromStore,
  ensureSelectedVariant,
  localizedRouteLeft,
}: Props) => {
  const history = useHistory();
  const [isThunkDone] = useThunkPromise(ensureSelectedVariant, currentRouteVariantId, history);
  const isEnsured = useSelector(
    (s) =>
      isThunkDone &&
      currentRouteVariantId &&
      getSelectedLanguageIdFromStore(s) === currentRouteVariantId,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(localizedRouteLeft());
    };
  }, [localizedRouteLeft]);

  return isEnsured ? children : <Loader />;
};
