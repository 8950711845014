import { Action } from '../../../../../@types/Action.type.ts';
import {
  Taxonomy_GroupCreate_Initialized,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Terms_HideCreateNewForm,
  Taxonomy_Terms_ShowCreateNewForm,
  Taxonomy_Terms_TermCreated,
} from '../../constants/taxonomyActionTypes.ts';

export const isCreateNewFormDisplayed = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Taxonomy_Terms_ShowCreateNewForm:
      return true;

    case Taxonomy_GroupEdit_Initialized:
    case Taxonomy_GroupCreate_Initialized:
    case Taxonomy_Terms_TermCreated:
    case Taxonomy_Terms_HideCreateNewForm:
      return false;

    default:
      return state;
  }
};
