import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { createInviteLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  getCurrentProject,
  isProjectPlanExpired,
} from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

export const getUserInviteLink = (state: IStore, nameOrEmail?: string): string | undefined => {
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId;
  const projectId = getCurrentProject(state).projectId;
  const isProjectExpired = isProjectPlanExpired(state, projectId);

  if (isProjectExpired) {
    return undefined;
  }

  const createInviteLinkWithEmail = (rawEmail?: string) =>
    createInviteLink({
      rawEmail,
      projectId,
      subscriptionId,
    });

  const inviteLink = nameOrEmail
    ? createInviteLinkWithEmail(nameOrEmail)
    : createInviteLinkWithEmail();

  return inviteLink;
};
