import { filterItems, findItem, mapItems } from '@kontent-ai/component-library/Selects';
import { isSection } from '@kontent-ai/component-library/VerticalMenu';
import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { moveOpenedAssetFolder } from '../../actions/thunkAssetLibraryActions.ts';
import {
  IAssetFolderOption,
  IMoveToAssetFolderDialogDisabledProps,
  IMoveToAssetFolderDialogOwnProps,
  MoveToAssetFolderDialog,
} from '../../components/AssetListing/MoveToAssetFolderDialog.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { createMoveToFolderSelectorOptions } from '../../selectors/createMoveToFolderSelectorOptions.ts';
import { getOpenedAssetFolder } from '../../selectors/getOpenedAssetFolder.ts';
import { getSubFolderIds } from '../../utils/getSubFolderIds.ts';

const getOpenedFolderSubFolderIds = memoize.maxOne(
  (taxonomyGroup: ITaxonomyGroup, openedFolder: ITaxonomyTerm): UuidArray => {
    return getSubFolderIds(taxonomyGroup, openedFolder.childIds);
  },
);

const findParentFolderId = memoize.maxOne((taxonomyGroup: ITaxonomyGroup, folderId: Uuid): Uuid => {
  if (taxonomyGroup.childIds.includes(folderId)) {
    return RootFolderId;
  }

  const parentFolder = Collection.getValues(taxonomyGroup.terms).find((folder: ITaxonomyTerm) =>
    folder.childIds.includes(folderId),
  );

  if (!parentFolder) {
    throw InvariantException(`Folder with id ${folderId} has no parent folder`);
  }

  return parentFolder.id;
});

const disableOptionFactory =
  (openedFolderId: Uuid) =>
  (option: IAssetFolderOption): IAssetFolderOption => {
    if (option.id === openedFolderId) {
      return {
        ...option,
        disabledTooltipText: 'Cannot move the folder onto itself',
      };
    }

    return option;
  };

const disabledProps: IMoveToAssetFolderDialogDisabledProps = {
  tooltip: 'Select a different folder than it is currently in',
};

export const AssetFolderMoveDialog: React.FC<IMoveToAssetFolderDialogOwnProps> = (props) => {
  const dispatch = useDispatch();

  const openedFolderId = useSelector((s) => s.assetLibraryApp.openedFolderId);

  if (openedFolderId === RootFolderId) {
    throw InvariantException('Can not move root folder');
  }

  const openedFolder = useSelector(getOpenedAssetFolder);
  const disabledOption = disableOptionFactory(openedFolderId);
  const disabledOptionIds = [openedFolderId];

  const folderOptions = useSelector((s) => {
    const assetFolders = s.data.assetFolders;

    const subFolderIds = getOpenedFolderSubFolderIds(assetFolders.taxonomyGroup, openedFolder);
    const options = createMoveToFolderSelectorOptions(assetFolders.taxonomyGroup);
    const filteredOptions = filterItems<IAssetFolderOption>(
      options,
      (o: IAssetFolderOption) => !subFolderIds.includes(o.id),
      true,
    );
    const preparedOptions = mapItems<IAssetFolderOption>(
      filteredOptions,
      disabledOption,
    ) as ReadonlyArray<IAssetFolderOption>;

    return preparedOptions;
  });

  const preselectedOption = useSelector((s) => {
    const assetFolders = s.data.assetFolders;

    const parentFolderId = findParentFolderId(assetFolders.taxonomyGroup, openedFolderId);
    const preselected = findItem(
      folderOptions,
      (option: IAssetFolderOption) => option.id === parentFolderId,
    );

    if (!preselected || isSection(preselected)) {
      throw InvariantException(`Folder option with id ${parentFolderId} was not found`);
    }

    return preselected;
  });

  const getDisabledProps = (
    selectedOption: IAssetFolderOption,
  ): IMoveToAssetFolderDialogDisabledProps | null =>
    selectedOption === preselectedOption ? disabledProps : null;

  return (
    <MoveToAssetFolderDialog
      closeDialog={props.closeDialog}
      dataUiAction={DataUiAction.MoveAssetFolder}
      disabledOptionIds={disabledOptionIds}
      folderOptions={folderOptions}
      getDisabledProps={getDisabledProps}
      label={props.label}
      onMoveToFolder={(folderId: Uuid) => dispatch(moveOpenedAssetFolder(folderId))}
      preselectedOption={preselectedOption}
      primaryActionText={props.primaryActionText}
    />
  );
};
