import {
  FocusVisibleResult,
  useFocusVisible,
  useInteractionModality,
} from '@react-aria/interactions';
import React, { useMemo } from 'react';

export const FocusVisibilityContext = React.createContext<FocusVisibleResult>({
  isFocusVisible: false,
});

export const FocusVisibilityInfoProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const { isFocusVisible: isKeyboardFocusVisible } = useFocusVisible();
  const modality = useInteractionModality();
  const isFocusVisible = isKeyboardFocusVisible && modality === 'keyboard';
  const value = useMemo(() => ({ isFocusVisible }), [isFocusVisible]);

  return (
    <FocusVisibilityContext.Provider value={value}>{children}</FocusVisibilityContext.Provider>
  );
};
