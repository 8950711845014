import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IAssetRepository } from '../../../../../repositories/interfaces/IAssetRepository.type.ts';
import { IContentItemVariantReferenceServerModel } from '../../../../../repositories/serverModels/ContentItemUsageModel.type.ts';
import { AssetLibrary_Asset_UsageLoaded } from '../../constants/assetLibraryActionTypes.ts';

export interface IAssetUsageLoadedPayload {
  readonly assetId: Uuid;
  readonly usage: ReadonlyArray<IContentItemVariantReferenceServerModel>;
}

const assetUsageLoaded = (payload: IAssetUsageLoadedPayload) =>
  ({
    type: AssetLibrary_Asset_UsageLoaded,
    payload,
  }) as const;

export type LoadAssetUsageActionsType = ReturnType<typeof assetUsageLoaded>;

interface ILoadAssetUsageDependencies {
  readonly assetRepository: IAssetRepository;
}

export const createLoadAssetUsageAction =
  (deps: ILoadAssetUsageDependencies) =>
  (assetId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const usage = await deps.assetRepository.getUsedIn(assetId, abortSignal);

    dispatch(
      assetUsageLoaded({
        assetId,
        usage,
      }),
    );
  };
