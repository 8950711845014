import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { FullTextSearchStatus } from '../reducers/IContentInventoryStoreState.type.ts';

export const isFullTextSearchUnavailableOrDisabled = (state: IStore): boolean =>
  [FullTextSearchStatus.Unavailable, FullTextSearchStatus.Disabled].includes(
    state.contentInventory.fullTextSearchStatus,
  );

export const isFullTextSearchUnavailable = (state: IStore): boolean =>
  state.contentInventory.fullTextSearchStatus === FullTextSearchStatus.Unavailable;
