import { Action } from '../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Webhooks_WebhookMessageContent_LoadFinished,
  Webhooks_WebhookMessageContent_LoadStarted,
} from '../../constants/webhooksActionTypes.ts';

export function webhookMessageContentLoadingStatus(
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case Webhooks_WebhookMessageContent_LoadStarted:
      return LoadingStatus.Loading;

    case Webhooks_WebhookMessageContent_LoadFinished:
      return LoadingStatus.Loaded;

    default:
      return state;
  }
}
