import { Callout } from '@kontent-ai/component-library/Callout';
import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { BorderRadius, Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ActionMenuPositioner } from '../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import { InstructionMenu } from './InstructionMenu.tsx';

type InstructionErrorProps = {
  readonly disabled: boolean;
  readonly error: string;
  readonly isActionFinished: boolean;
  readonly onDiscard?: () => void;
  readonly onEditInstruction?: () => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
};

export const InstructionError: React.FC<InstructionErrorProps> = ({
  disabled,
  error,
  isActionFinished,
  onDiscard,
  onEditInstruction,
  onTryAgain,
  preferMenuOnTop,
}) => {
  return (
    <ActionMenuPositioner
      preferMenuOnTop={preferMenuOnTop}
      renderMenu={() =>
        !disabled && (
          <InstructionMenu
            isActionFailed={!!error}
            isActionFinished={isActionFinished}
            onDiscard={onDiscard}
            onEditInstruction={onEditInstruction}
            onTryAgain={onTryAgain}
          />
        )
      }
      renderResult={(ref) => (
        <Paper
          borderRadius={BorderRadius.M}
          level={PaperLevel.Popout}
          maxWidth={600}
          minWidth={300}
          padding={Spacing.L}
          ref={ref}
        >
          <Callout calloutType="warning">{error}</Callout>
        </Paper>
      )}
    />
  );
};

InstructionError.displayName = 'InstructionError';
