import DiffMatchPatch from 'diff-match-patch';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import { IChange, IDiffWordsWithSpace } from './IDiffWordsWithSpace.type.ts';
import { isWordChar } from './diffUnicodeUtils.ts';

function diffToChange(diff: DiffMatchPatch.Diff): IChange {
  const type = diff[0];
  const text = diff[1];

  if (type === DiffMatchPatch.DIFF_DELETE) {
    return {
      count: text.length,
      value: text,
      removed: true,
    };
  }
  if (type === DiffMatchPatch.DIFF_INSERT) {
    return {
      count: text.length,
      value: text,
      added: true,
    };
  }
  return {
    count: text.length,
    value: text,
  };
}

/* Inspired by
   https://github.com/rars/diff-match-patch-ts/blob/master/src/diff-match-patch.class.ts (source code for wordsToChars)
   and
   https://github.com/google/diff-match-patch/wiki/Line-or-Word-Diffs (handling lines vs. words)
*/

// Non-word chars (e.g. whitespaces) are processed one-by-one, while words are being processed as atomic
const getNextBoundary = (text: string, start: number): number => {
  const textStartChar = text[start];
  const lastIsWordChar = isString(textStartChar) && isWordChar(textStartChar);
  if (!lastIsWordChar) {
    return start;
  }
  for (let i = start + 1; i < text.length; i++) {
    const currentTextChar = text[i];
    const currentIsWordChar = isString(currentTextChar) && isWordChar(currentTextChar);
    if (!currentIsWordChar) {
      return i - 1;
    }
  }
  return text.length - 1;
};

type WordHashMap = Record<string, number>;

function wordsToCharsMunge(
  textParts: ReadonlyArray<string>,
  wordArray: string[],
  wordHash: WordHashMap,
): string {
  let chars = '';
  for (let partIndex = 0; partIndex < textParts.length; partIndex++) {
    const textPart = textParts[partIndex];

    // Walk the text, pulling out a substring for each line.
    // text.split('\n') would would temporarily double our memory footprint.
    // Modifying text would create many large strings to garbage collect.
    let wordStart = 0;
    let wordEnd = -1;
    // Keeping our own length constiable is faster than looking it up.
    let wordArrayLength = wordArray.length;

    if (!isString(textPart)) {
      continue;
    }

    while (wordEnd < textPart.length - 1) {
      wordEnd = getNextBoundary(textPart, wordStart);

      const word = textPart.substring(wordStart, wordEnd + 1);
      wordStart = wordEnd + 1;

      const hash = wordHash[word];
      const hashExists = !!hash;
      if (hashExists) {
        chars += String.fromCharCode(hash);
      } else {
        chars += String.fromCharCode(wordArrayLength);
        wordHash[word] = wordArrayLength;
        wordArray[wordArrayLength++] = word;
      }
    }
  }
  return chars;
}

function wordsToChars(
  textParts1: ReadonlyArray<string>,
  textParts2: ReadonlyArray<string>,
): {
  readonly chars1: string;
  readonly chars2: string;
  readonly wordArray: string[];
} {
  const wordArray: string[] = []; // e.g. lineArray[4] == 'Hello\n'
  const wordHash: WordHashMap = {}; // e.g. lineHash['Hello\n'] == 4

  // '\x00' is a valid character, but constious debuggers don't like it.
  // So we'll insert a junk entry to avoid generating a null character.
  wordArray[0] = '';

  const chars1 = wordsToCharsMunge(textParts1, wordArray, wordHash);
  const chars2 = wordsToCharsMunge(textParts2, wordArray, wordHash);

  return {
    chars1,
    chars2,
    wordArray,
  };
}

function charsToWords(diffs: DiffMatchPatch.Diff[], wordArray: string[]): void {
  const dmp = new DiffMatchPatch.diff_match_patch();
  dmp.diff_charsToLines_(diffs, wordArray);
}

const DefaultDiffTimeoutInSeconds = 1;

export const diffWordsWithSpace: IDiffWordsWithSpace = (
  oldTextParts: ReadonlyArray<string>,
  newTextParts: ReadonlyArray<string>,
  deadline?: number,
): ReadonlyArray<IChange> => {
  const useDeadline = deadline ?? new Date().getTime() + DefaultDiffTimeoutInSeconds * 1000;

  const dmp = new DiffMatchPatch.diff_match_patch();
  const mappedWords = wordsToChars(oldTextParts, newTextParts);
  const lineText1 = mappedWords.chars1;
  const lineText2 = mappedWords.chars2;
  const wordArray = mappedWords.wordArray;
  const diffs = dmp.diff_main(lineText1, lineText2, false, useDeadline);
  charsToWords(diffs, wordArray);

  const result = diffs.map(diffToChange);
  return result;
};
