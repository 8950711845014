import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ITaxonomyGroupServerModel } from '../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Data_TaxonomyGroups_Started,
  Data_TaxonomyGroups_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  ITaxonomyGroup,
  createTaxonomyGroupDomainModel,
} from '../../../models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';

const initialState: Immutable.Map<Uuid, ITaxonomyGroup> = Immutable.Map();

export function byId(
  state: Immutable.Map<Uuid, ITaxonomyGroup> = initialState,
  action: Action,
): Immutable.Map<Uuid, ITaxonomyGroup> {
  switch (action.type) {
    case Data_TaxonomyGroups_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_TaxonomyGroups_Success: {
      const taxonomyGroups = action.payload.data.reduce(
        (reduced: Immutable.Map<Uuid, ITaxonomyGroup>, item: ITaxonomyGroupServerModel) =>
          reduced.set(item.id, createTaxonomyGroupDomainModel(item)),
        initialState,
      );
      return taxonomyGroups;
    }

    default:
      return state;
  }
}
