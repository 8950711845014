import { ModularContentTypeElementValidationResult } from '../types/ModularContentTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const ModularContentInitialValidationResult: ModularContentTypeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  isGuidelinesValid: true,
  isNameValid: true,
  isNumberOfItemsValid: true,
  errorMessages: [],
  warningMessages: [],
};
