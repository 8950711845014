import { ContentItemEditingEventOrigins } from '../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  ContentItemEditingModalProperties,
  ModalProperties,
} from '../../../_shared/types/ModalDialogProperties.type.ts';

const isModalWithActionOrigin = (
  modalProperties: ModalProperties | null,
): modalProperties is ContentItemEditingModalProperties =>
  !!modalProperties && !!(modalProperties as ContentItemEditingModalProperties).actionOrigin;

export const getModalDialogActionOrigin = ({
  sharedApp: { modalDialog },
}: IStore): ContentItemEditingEventOrigins =>
  isModalWithActionOrigin(modalDialog.properties)
    ? modalDialog.properties.actionOrigin
    : ContentItemEditingEventOrigins.Undefined;
