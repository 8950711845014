import Immutable from 'immutable';
import {
  IFixedInsertPosition,
  IRelativeInsertPosition,
  InsertPosition,
  InsertPositionPlacement,
  SmartLinkCommand,
  SmartLinkCommandType,
} from '../../../../../_shared/models/SmartLinkCommand.ts';
import { isFixedInsertPosition } from '../../../../../_shared/models/utils/smartLinkCommandUtils.ts';

export function isSmartLinkCommandRelevantToLIEs(command: SmartLinkCommand): boolean {
  return [SmartLinkCommandType.CreateLinkedItem, SmartLinkCommandType.InsertLinkedItem].includes(
    command.type,
  );
}

export function getSmartLinkInsertIndex(
  insertPosition: InsertPosition,
  alreadyLinkedItemIds: Immutable.List<Uuid>,
): number {
  const insertIndex = isFixedInsertPosition(insertPosition)
    ? getFixedSmartLinkInsertIndex(insertPosition, alreadyLinkedItemIds)
    : getRelativeSmartLinkInsertIndex(insertPosition, alreadyLinkedItemIds);

  if (insertIndex < 0) {
    return 0;
  }

  if (insertIndex > alreadyLinkedItemIds.size) {
    return alreadyLinkedItemIds.size;
  }

  return insertIndex;
}

function getFixedSmartLinkInsertIndex(
  insertPosition: IFixedInsertPosition,
  alreadyLinkedItemIds: Immutable.List<Uuid>,
): number {
  return insertPosition.placement === InsertPositionPlacement.Start ? 0 : alreadyLinkedItemIds.size;
}

function getRelativeSmartLinkInsertIndex(
  insertPosition: IRelativeInsertPosition,
  alreadyLinkedItemIds: Immutable.List<Uuid>,
): number {
  const targetIndex = alreadyLinkedItemIds.indexOf(insertPosition.targetId);
  return insertPosition.placement === InsertPositionPlacement.After ? targetIndex + 1 : targetIndex;
}
