import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IWorkflowRepository } from '../../../../../repositories/interfaces/IWorkflowRepository.type.ts';
import { WorkflowStepsUsageServerModel } from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';
import { Workflow_Editing_StepsUsageLoaded } from '../../constants/workflowActionTypes.ts';

interface IDeps {
  readonly workflowRepository: IWorkflowRepository;
}

const usagesLoaded = (usages: WorkflowStepsUsageServerModel) =>
  ({
    type: Workflow_Editing_StepsUsageLoaded,
    payload: { usages },
  }) as const;

export type LoadWorkflowStepsUsageActionsType = ReturnType<typeof usagesLoaded>;

export const createLoadWorkflowStepsUsageAction =
  ({ workflowRepository }: IDeps) =>
  (workflowId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const workflowStepsUsageServerModel = await workflowRepository.getStepsUsage(
      workflowId,
      abortSignal,
    );

    dispatch(usagesLoaded(workflowStepsUsageServerModel));
  };
