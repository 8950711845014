import { Dispatch, GetState, ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IItemListingOperationCheckServerModel } from '../../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  IItemListingBulkActionsCheck,
  getListingOperationsCheckFromServerModel,
} from '../../../../models/ItemListingBulkActionStatus.ts';
import {
  ItemListingBulkAction,
  allItemListingBulkActions,
} from '../../../../models/ItemListingBulkActions.ts';
import { ContentItemListingOperationQueryModel } from '../../../../models/filter/ContentItemListOperationQueryModel.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_BulkActionCheck_Loaded,
  ContentListing_BulkActionCheck_Loading,
} from '../../constants/contentItemInventoryActionTypes.ts';

interface ILoadOperationsStatusesDependencies {
  readonly contentItemRepository: {
    checkListOperations: (
      variantId: Uuid,
      operations: ItemListingBulkAction[],
      query: ContentItemListingOperationQueryModel,
    ) => Promise<IItemListingOperationCheckServerModel>;
  };
}

const operationStatusesLoading = () =>
  ({
    type: ContentListing_BulkActionCheck_Loading,
  }) as const;

const operationStatusesLoaded = (operationStatuses: IItemListingBulkActionsCheck) =>
  ({
    type: ContentListing_BulkActionCheck_Loaded,
    payload: { operationStatuses },
  }) as const;

export type LoadOperationsStatusesActionsType = ReturnType<
  typeof operationStatusesLoading | typeof operationStatusesLoaded
>;

export const createLoadOperationsStatuses =
  (deps: ILoadOperationsStatusesDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    dispatch(operationStatusesLoading());

    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const query = getItemListingOperationQueryFromFilter(getState());
    const serverModel = await deps.contentItemRepository.checkListOperations(
      selectedLanguageId,
      allItemListingBulkActions,
      query,
    );

    dispatch(operationStatusesLoaded(getListingOperationsCheckFromServerModel(serverModel)));
  };
