import React from 'react';
import { SubscriptionTabName } from '../../applications/subscriptionManagement/shared/constants/subscriptionTabName.ts';
import { AppNames } from '../constants/applicationNames.ts';
import { HtmlPageTitle } from './HtmlPageTitle.tsx';

type Props = {
  readonly tabName: SubscriptionTabName;
};

export const HtmlSubscriptionManagementPageTitle: React.FC<Props> = ({ tabName }) => (
  <HtmlPageTitle
    appName={AppNames.SubscriptionManagement}
    customName={`${tabName} | Subscription Management`}
  />
);

HtmlSubscriptionManagementPageTitle.displayName = 'HtmlSubscriptionManagementPageTitle';
