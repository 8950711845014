import { forwardRef } from 'react';
import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { taxonomyTermRemoved } from '../actions/taxonomyActions.ts';
import { getNode } from '../utils/taxonomyTreeUtils.ts';

export const TermRemoveConfirmationDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  (props, ref) => {
    const termId = useSelector((s) => s.taxonomyApp.editedTaxonomyTermId);
    const termName = useSelector(
      (s) => getNode(s.taxonomyApp.editedTaxonomyGroup, termId)?.name || '',
    );
    const dispatch = useDispatch();

    const onClose = compose(dispatch, modalDismissed);
    const onConfirm = () => dispatch(taxonomyTermRemoved(termId));

    return (
      <ConfirmationDialog
        alert
        closeImmediatelyAfterConfirm
        confirmButtonText="Remove taxonomy terms"
        headerContent="Remove taxonomy term"
        onClose={onClose}
        onConfirm={onConfirm}
        ref={ref}
        {...props}
      >
        <p>
          This will also remove <strong>all nested taxonomy terms.</strong>
        </p>
        <p>
          Do you want to remove <strong>{termName}</strong> and all its nested terms?
        </p>
      </ConfirmationDialog>
    );
  },
);

TermRemoveConfirmationDialog.displayName = 'TermRemoveConfirmationDialog';
