import { Icons } from '@kontent-ai/component-library/Icons';
import { SimpleStatusWarning } from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export const AssetUncategorizedLabel: React.FC = () => (
  <SimpleStatusWarning
    icon={Icons.Drawers}
    tooltipText="No taxonomy terms assigned"
    tooltipPlacement="right"
    {...getDataUiElementAttribute(DataUiElement.UncategorizedLabel)}
  />
);

AssetUncategorizedLabel.displayName = 'AssetUncategorizedLabel';
