import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { modalWithPropertiesOpened } from '../../../../actions/sharedActions.ts';
import { ModalDialogType } from '../../../../constants/modalDialogType.ts';
import { ContentItemEditingEventOrigins } from '../../../../models/events/ContentItemEditingEventData.type.ts';
import { createInitialChangeWorkflowStepModalDataForItemEditing } from '../../selectors/createInitialChangeWorkflowStepModalDataForItemEditing.ts';
import { OpenWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import { setInitialChangeWorkflowStepModalData } from '../changeWorkflowStepModalActions.ts';

export type IOpenChangeWorkflowStepModal = (
  workflowAction: OpenWorkflowStepModalAction,
  actionOrigin: ContentItemEditingEventOrigins,
) => ThunkFunction;

export const openChangeWorkflowStepModal =
  (
    workflowAction: OpenWorkflowStepModalAction,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const initialModalData = createInitialChangeWorkflowStepModalDataForItemEditing(
      getState(),
      workflowAction,
    );

    assert(
      initialModalData,
      () =>
        'openChangeWorkflowStepModal.ts: Failed to create initial change workflow step modal data.',
    );

    dispatch(setInitialChangeWorkflowStepModalData(initialModalData));
    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.ChangeWorkflowStepDialog,
        actionOrigin,
      }),
    );
  };
