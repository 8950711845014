import { BaseBlockType, BlockType, TextBlockType } from '../../../utils/blocks/blockType.ts';
import { isTableCellContent } from '../../../utils/blocks/blockTypeUtils.ts';
import { createSelection, setContentSelection } from '../../../utils/editorSelectionUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  changeBlocksType,
} from '../../../utils/general/editorContentUtils.ts';
import { EditorFeatureLimitations } from '../../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  getAllDisallowedTableFeatures,
  getAllDisallowedTopLevelFeatures,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import { BlockTypeCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { getFeatureForBlockType } from '../../keyboardShortcuts/api/editorCommandUtils.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';

export const MarkdownBlockTypeCommandMap: ReadonlyRecord<TextBlockType, BlockTypeCommand> = {
  [BlockType.Unstyled]: BlockTypeCommand.Unstyled,
  [BlockType.HeadingOne]: BlockTypeCommand.HeadingOne,
  [BlockType.HeadingTwo]: BlockTypeCommand.HeadingTwo,
  [BlockType.HeadingThree]: BlockTypeCommand.HeadingThree,
  [BlockType.HeadingFour]: BlockTypeCommand.HeadingFour,
  [BlockType.HeadingFive]: BlockTypeCommand.HeadingFive,
  [BlockType.HeadingSix]: BlockTypeCommand.HeadingSix,
  [BlockType.OrderedListItem]: BlockTypeCommand.OrderedList,
  [BlockType.UnorderedListItem]: BlockTypeCommand.UnorderedList,
};

export const isMarkdownShorthandBlockType = (
  type: string,
): type is keyof typeof MarkdownBlockTypeCommandMap =>
  (Object.keys(MarkdownBlockTypeCommandMap) as readonly string[]).includes(type);

export enum MarkdownBlockShorthand {
  HeadingOne = '# ',
  HeadingTwo = '## ',
  HeadingThree = '### ',
  HeadingFour = '#### ',
  HeadingFive = '##### ',
  HeadingSix = '###### ',
  OrderedListItemWithDot = '1. ',
  OrderedListItemWithParenthesis = '1) ',
  UnorderedListItemWithAsterisk = '* ',
  UnorderedListItemWithDash = '- ',
}

export const MarkdownShorthandBlockTypeMap: ReadonlyRecord<MarkdownBlockShorthand, TextBlockType> =
  {
    [MarkdownBlockShorthand.HeadingOne]: BlockType.HeadingOne,
    [MarkdownBlockShorthand.HeadingTwo]: BlockType.HeadingTwo,
    [MarkdownBlockShorthand.HeadingThree]: BlockType.HeadingThree,
    [MarkdownBlockShorthand.HeadingFour]: BlockType.HeadingFour,
    [MarkdownBlockShorthand.HeadingFive]: BlockType.HeadingFive,
    [MarkdownBlockShorthand.HeadingSix]: BlockType.HeadingSix,
    [MarkdownBlockShorthand.OrderedListItemWithDot]: BlockType.OrderedListItem,
    [MarkdownBlockShorthand.OrderedListItemWithParenthesis]: BlockType.OrderedListItem,
    [MarkdownBlockShorthand.UnorderedListItemWithAsterisk]: BlockType.UnorderedListItem,
    [MarkdownBlockShorthand.UnorderedListItemWithDash]: BlockType.UnorderedListItem,
  } as const;

export const isMarkdownBlockShorthand = (
  shorthandCandidate: string,
): shorthandCandidate is keyof typeof MarkdownShorthandBlockTypeMap =>
  (Object.keys(MarkdownShorthandBlockTypeMap) as readonly string[]).includes(shorthandCandidate);

export type MarkdownBlockConversionResult = {
  readonly markdownShorthand: MarkdownBlockShorthand;
  readonly blockType: BaseBlockType;
};

export function getAutomaticMarkdownBlockConversionResult(
  shorthandCandidate: string,
): MarkdownBlockConversionResult | null {
  if (isMarkdownBlockShorthand(shorthandCandidate)) {
    return {
      markdownShorthand: shorthandCandidate,
      blockType: MarkdownShorthandBlockTypeMap[shorthandCandidate],
    };
  }

  return null;
}

export function applyAutomaticMarkdownBlockConversion(
  input: IContentChangeInput,
  limitations: EditorFeatureLimitations,
  onMarkdownConversion?: (conversionResult: MarkdownBlockConversionResult) => void,
): IContentChangeResult {
  const { content, selection } = input;

  if (!selection.isCollapsed()) {
    return input;
  }

  const selectionStartKey = selection.getStartKey();
  const block = content.getBlockForKey(selectionStartKey);

  if (!block) {
    return input;
  }

  const blockText = block.getText();
  const caretPosition = selection.getStartOffset();
  const textBeforeCaret = blockText.substring(0, caretPosition);
  const markdownConversionResult = getAutomaticMarkdownBlockConversionResult(textBeforeCaret);

  if (!markdownConversionResult) {
    return input;
  }

  const { markdownShorthand, blockType } = markdownConversionResult;

  const disallowedFeatures = isTableCellContent(block)
    ? getAllDisallowedTableFeatures(limitations)
    : getAllDisallowedTopLevelFeatures(limitations);
  const feature = getFeatureForBlockType(blockType);
  const newBlockType = feature && !disallowedFeatures.has(feature) ? blockType : null;

  if (!newBlockType) {
    return input;
  }

  const withChangedBlockType = changeBlocksType(input, () => newBlockType);

  const removeSelection = createSelection(
    selectionStartKey,
    0,
    selectionStartKey,
    markdownShorthand.length,
  );
  const withRemovedSelection = updateText(
    {
      content: withChangedBlockType.content,
      selection: removeSelection,
    },
    '',
  );

  const newSelection = withRemovedSelection.content.getSelectionAfter();
  const modifiedContentStateWithSelection = setContentSelection(
    withRemovedSelection.content,
    selection,
    newSelection,
  );

  if (onMarkdownConversion) {
    onMarkdownConversion(markdownConversionResult);
  }

  return {
    wasModified: true,
    content: modifiedContentStateWithSelection,
    selection: newSelection,
  };
}
