import { Action } from '../../../../../@types/Action.type.ts';
import { Workflow_Editing_WorkflowStepUnsavedChangesUpdated } from '../../constants/workflowActionTypes.ts';

export const hasEditedWorkflowStepUnsavedChanges = (state = false, action: Action): boolean => {
  switch (action.type) {
    case Workflow_Editing_WorkflowStepUnsavedChangesUpdated:
      return action.payload.hasUnsavedChanges;

    default:
      return state;
  }
};
