import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { DropDownFrame } from '../../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { SquareButton } from '../../../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  activateContentItemEditingAction,
  deactivateContentItemEditingAction,
} from '../../../../../../itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { ActionsLeafMenu } from '../../../../../../itemEditor/features/ContentItemEditing/components/editingActions/actionsMenu/ActionsLeafMenu.tsx';
import { EditingAction } from '../../../../../../itemEditor/features/ContentItemEditing/models/EditingAction.ts';
import { getAvailableContentItemEditingActions } from '../../../../../../itemEditor/features/ContentItemEditing/utils/editingActions/getAvailableContentItemEditingActions.ts';
import { useHandleClickOutsideInActionsMenu } from '../../../../../../itemEditor/features/ContentItemEditing/utils/editingActions/useHandleClickOutsideInActionsMenu.ts';
import { MoreActionsMainMenu } from './MoreActionsMainMenu.tsx';

export const MoreActions: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const availableActions = useSelector((state: IStore) =>
    getAvailableContentItemEditingActions(state, location.pathname),
  );
  const activatedAction = useSelector(
    (state: IStore) => state.contentApp.editorUi.editingActions.activatedAction,
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // We need to remember the last active action in case the action becomes none to allow the menu to fade away in its last state when hiding
  const [lastActiveAction, setLastActiveAction] = useState(activatedAction);

  useEffect(() => {
    if (activatedAction === EditingAction.none) {
      setIsMenuOpen(false);
    } else {
      setLastActiveAction(activatedAction);
      setIsMenuOpen(true);
    }
  }, [activatedAction]);

  const onHideMenu = useCallback(() => {
    dispatch(deactivateContentItemEditingAction());
  }, []);

  const onShowMenu = useCallback(() => {
    dispatch(activateContentItemEditingAction(EditingAction.showMainMenu));
  }, []);

  const [handleClickOutside, shouldCloseOnClickOutside, useCustomHandleClickOutside] =
    useHandleClickOutsideInActionsMenu(onHideMenu, activatedAction);

  return (
    <DropDownFrame
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      isOptionListVisible={isMenuOpen}
      longer
      onClickOutside={isMenuOpen ? handleClickOutside : undefined}
      renderContent={() =>
        lastActiveAction === EditingAction.showMainMenu ? (
          <MoreActionsMainMenu actions={availableActions} />
        ) : (
          <ActionsLeafMenu
            key={lastActiveAction}
            activatedAction={lastActiveAction}
            useCustomHandleClickOutside={useCustomHandleClickOutside}
          />
        )
      }
      renderSelector={(ref: RefObject<HTMLDivElement>) => (
        <SquareButton
          customButtonClassName={isMenuOpen ? 'active' : ''}
          ref={ref}
          iconClass="u-rotate-90"
          iconName={IconName.Ellipsis}
          onClick={isMenuOpen ? onHideMenu : onShowMenu}
          style={SquareButtonStyle.Quinary}
          size={SquareButtonSize.Quinary}
          tooltipText="More actions"
        />
      )}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
    />
  );
};

MoreActions.displayName = 'MoreActions';
