import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { getDropDown } from '../../../../../_shared/components/DropDown.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IRoleOption {
  readonly disabledTooltip: string | undefined;
  readonly id: Uuid;
  readonly name: string;
  readonly isProjectManagerRole: boolean;
}

export type RoleSelectorProps = {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly disabledTooltip: string | undefined;
  readonly onChange: (role: IRoleOption) => void;
  readonly roles: ReadonlyArray<IRoleOption>;
  readonly selectedRole: IRoleOption | undefined;
};

const DropDown = getDropDown<IRoleOption>();

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'bottom-start',
};

export const RoleSelector: React.FC<RoleSelectorProps> = ({
  className,
  disabledTooltip,
  onChange,
  roles,
  selectedRole,
}) => (
  <Tooltip placement="top" tooltipText={disabledTooltip}>
    <div className={className}>
      <DropDown
        className={className}
        dataUiCollectionName={DataUiCollection.UserRoles}
        disabled={!!disabledTooltip}
        getOptionDisabledMessage={(role) => role.disabledTooltip}
        getOptionId={(role) => role.id}
        onSelect={onChange}
        options={roles}
        renderOptionName={(role) => role.name}
        selectedOption={selectedRole}
        tippyOptions={tippyOptions}
      />
    </div>
  </Tooltip>
);

RoleSelector.displayName = 'RoleSelector';
