import React, { useMemo } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { DataUiCollection } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { hasAnySelectableOptions } from '../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ConfigurableTaxonomyMultipleOptionSelect } from '../../../../shared/components/filter/ConfigurableTaxonomyMultipleOptionSelect.tsx';
import { ListingFilterCategory } from '../../../../shared/components/filter/ListingFilterCategory.tsx';
import { queryChanged } from '../../../actions/assetLibraryActions.ts';
import { getAssetTaxonomiesForFilter } from '../../../utils/assetTaxonomyUtils.ts';

export const AssetsTaxonomyFilterSelector: React.FC = () => {
  const dispatch = useDispatch();
  const defaultAssetType = useSelector((s) => s.data.assetTypes.defaultAssetType);
  const taxonomyGroups = useSelector((s) => s.data.taxonomyGroups.byId);
  const taxonomies = useMemo(
    () => getAssetTaxonomiesForFilter(defaultAssetType, taxonomyGroups).toArray(),
    [defaultAssetType, taxonomyGroups],
  );
  const selectedTaxonomies = useSelector((s) => s.assetLibraryApp.query.selectedTaxonomyNodes);
  const shouldBeVisible = hasAnySelectableOptions(taxonomies);

  return shouldBeVisible ? (
    <ListingFilterCategory
      title="Taxonomy"
      collectionName={DataUiCollection.Taxonomies}
      noHorizontalPadding
    >
      <ConfigurableTaxonomyMultipleOptionSelect
        selectedTaxonomies={selectedTaxonomies}
        taxonomyGroups={taxonomies}
        onFilterChanged={(selectedTaxonomyNodes) =>
          dispatch(queryChanged({ selectedTaxonomyNodes }))
        }
      />
    </ListingFilterCategory>
  ) : null;
};

AssetsTaxonomyFilterSelector.displayName = 'AssetsTaxonomyFilterSelector';
