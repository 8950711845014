import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { BrowseButton } from '../../../../../../../_shared/components/BrowseButton.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FileWithThumbnail } from '../../../../../../contentInventory/assets/models/FileWithThumbnail.type.ts';

export interface IAssetPickerDataProps {
  readonly children: string;
  readonly pickerDisabledTooltipMessage?: string;
}

export interface IAssetPickerCallbackProps {
  readonly onPick: () => void;
  readonly onUpload: ((files: ReadonlyArray<FileWithThumbnail>) => void) | null;
}

type IAssetPickerProps = IAssetPickerDataProps & IAssetPickerCallbackProps;

const AssetPickerButton: React.FC<IAssetPickerProps> = (props) => (
  <Button
    buttonDisplay="inline"
    buttonStyle="tertiary"
    disabled={!!props.pickerDisabledTooltipMessage}
    onClick={props.onPick}
    size="small"
    tooltipText={props.pickerDisabledTooltipMessage}
    {...getDataUiActionAttribute(DataUiAction.PickAsset)}
  >
    {props.children}
  </Button>
);

export const AssetPicker: React.FC<IAssetPickerProps> = (props) => (
  <div className="asset-picker">
    <span>
      <AssetPickerButton {...props} />
    </span>
    {props.onUpload && (
      <span className="asset-picker__info-message">
        <BrowseButton onUpload={props.onUpload} linkName="Browse" />
        <span> or drop assets here to upload.</span>
      </span>
    )}
  </div>
);

AssetPicker.displayName = 'AssetPicker';
