import { createGuid } from '@kontent-ai/utils';
import { Languages, createLanguage } from '../../../../data/models/languages/Language.ts';
import {
  LocalizationEditor_DefaultLangEditor_Hide,
  LocalizationEditor_DefaultLangEditor_Saved,
  LocalizationEditor_DefaultLangEditor_Show,
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangCreator_Show,
  LocalizationEditor_LangEditor_Hide,
  LocalizationEditor_LangEditor_Show,
  LocalizationEditor_LanguageBar_Move,
  LocalizationEditor_Language_Activate,
  LocalizationEditor_Language_Deactivate,
  LocalizationEditor_Language_DraggingStarted,
  LocalizationEditor_Language_Update,
} from '../constants/localizationActionTypes.ts';
import { DefaultLanguageFormShape, LanguageFormShape } from '../models/languageFormShape.type.ts';

export const saveEditedLanguage = (languageId: Uuid, formValues: LanguageFormShape) =>
  ({
    type: LocalizationEditor_Language_Update,
    payload: {
      languageId,
      formValues,
    },
  }) as const;

export const showLanguageEditor = (id: Uuid) =>
  ({
    type: LocalizationEditor_LangEditor_Show,
    payload: { id },
  }) as const;

export const showLanguageCreateForm = () =>
  ({
    type: LocalizationEditor_LangCreator_Show,
    payload: {
      language: createLanguage({
        id: createGuid(),
      }),
    },
  }) as const;

export const hideLanguageCreateForm = (languageId: Uuid) =>
  ({
    type: LocalizationEditor_LangCreator_Hide,
    payload: {
      languageId,
    },
  }) as const;

export const hideLanguageEditor = (id: Uuid) =>
  ({
    type: LocalizationEditor_LangEditor_Hide,
    payload: { id },
  }) as const;

export const showDefaultLanguageEditor = () =>
  ({
    type: LocalizationEditor_DefaultLangEditor_Show,
  }) as const;

export const hideDefaultLanguageEditor = () =>
  ({
    type: LocalizationEditor_DefaultLangEditor_Hide,
  }) as const;

export const saveEditedDefaultLanguage = (formValues: DefaultLanguageFormShape) =>
  ({
    type: LocalizationEditor_DefaultLangEditor_Saved,
    payload: { formValues },
  }) as const;

export const deactivateLanguage = (id: Uuid) =>
  ({
    type: LocalizationEditor_Language_Deactivate,
    payload: { id },
  }) as const;

export const activateLanguage = (id: Uuid, languages: Languages) =>
  ({
    type: LocalizationEditor_Language_Activate,
    payload: {
      id,
      languages,
    },
  }) as const;

export const onLanguageMove = (movedItemId: Uuid, targetItemId: Uuid) =>
  ({
    type: LocalizationEditor_LanguageBar_Move,
    payload: {
      movedItemId,
      targetItemId,
    },
  }) as const;

export const startLanguageDragging = (languageId: Uuid) =>
  ({
    type: LocalizationEditor_Language_DraggingStarted,
    payload: { languageId },
  }) as const;
