import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_Balance_Loaded } from '../../../../applications/subscriptionManagement/Transactions/constants/actionTypes.ts';

export const subscriptionBalance = (state: number = 0, action: Action): number => {
  switch (action.type) {
    case Subscription_Balance_Loaded:
      return action.payload.balance;

    default:
      return state;
  }
};
