import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { BarItemSkelet } from '../../../../_shared/components/BarItems/BarItemSkelet.tsx';
import { getScrollGridComponent } from '../../../../_shared/components/ScrollGrid/ScrollGrid.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IScrollState } from '../../../../_shared/utils/scrollGridUtils.ts';
import { loadAuditEvents } from '../../../../data/actions/thunkDataActions.ts';
import { IAuditEvent } from '../../../../data/models/auditLog/AuditEvent.ts';
import { auditLogScrollStateChanged } from '../actions/auditLogBaseActions.ts';
import { AuditLogEmptyState } from '../components/AuditLogEmptyState.tsx';
import { LoadingMoreEventsDebounceTime, MaxLoadedEvents } from '../constants/auditLogProperties.ts';
import { AuditLogEventTile } from './AuditLogEventTile.tsx';

const getEventsForScrollGrid = memoize.allForever(
  (
    events: Immutable.List<IAuditEvent>,
    areEventsLoaded: boolean,
  ): Immutable.List<IAuditEvent | null> => {
    return areEventsLoaded
      ? events.toList()
      : events.concat(Immutable.Repeat(null, MaxLoadedEvents)).toList();
  },
);

const ScrollGridComponent = getScrollGridComponent<IAuditEvent, never>({
  TileComponent: AuditLogEventTile,
  TileSkeletComponent: BarItemSkelet,
  EmptyStateComponent: AuditLogEmptyState,
});

export const AuditLogListing = () => {
  const dispatch = useDispatch();
  const events = useSelector((state: IStore) => state.data.auditLog.events);
  const scrollState = useSelector((state) => state.auditLogApp.auditLogScrollState);
  const areEventsLoaded = useSelector(
    (state: IStore) => state.data.auditLog.loadingStatus === LoadingStatus.Loaded,
  );

  const onScrollGridChange = useCallback((newScrollState: IScrollState) => {
    dispatch(auditLogScrollStateChanged(newScrollState));
  }, []);

  const loadAuditEventsCallback = () => dispatch(loadAuditEvents(true));
  const loadEventsDebounced = useDebouncedCallback(
    loadAuditEventsCallback,
    LoadingMoreEventsDebounceTime,
  );

  useEffect(() => {
    if (scrollState.scrollPositionPx !== 0) {
      loadEventsDebounced();
    }
    return loadEventsDebounced.cancel;
  }, [loadEventsDebounced, scrollState]);

  return (
    <div className="audit-log__list">
      <ScrollGridComponent
        items={getEventsForScrollGrid(events, areEventsLoaded)}
        scrollGridState={scrollState}
        onChange={onScrollGridChange}
      />
    </div>
  );
};
