import { delay } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';

interface IDependencies {
  readonly highlightIncompleteItemElements: () => Action;
  readonly resetIsHighlighting: () => Action;
}

export const createShowHighlightingOfIncompleteElementsAction =
  (deps: IDependencies) => (): ThunkFunction => (dispatch) => {
    dispatch(deps.highlightIncompleteItemElements());

    delay(500).then(() => dispatch(deps.resetIsHighlighting()));
  };
