import React from 'react';
import styled, { css } from 'styled-components';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { px } from '../../tokens/utils/utils.ts';
import { XAlignment, YAlignment } from '../../types/alignments.ts';

type StyledRowProps = Pick<RowProps, 'alignX' | 'alignY' | 'noWrap'> &
  Readonly<{
    $spacingX: Spacing;
    $spacingY: Spacing;
  }>;

const StyledRow = styled.div<StyledRowProps>`
  --column-spacing-X: ${(props) => px(props.$spacingX)};
  display: flex;
  ${({ noWrap }) => !noWrap && css`flex-wrap: wrap`};
  margin-left: calc(var(--column-spacing-X) * -1);
  row-gap: ${(props) => px(props.$spacingY)};
  ${({ alignX }) => alignX && css`justify-content: ${alignX};`};
  ${({ alignY }) => alignY && css`align-items: ${alignY};`};
`;

export type RowProps = Readonly<{
  alignX?: XAlignment;
  alignY?: YAlignment;
  noWrap?: boolean;
  spacing?: Spacing;
  spacingX?: Spacing;
  spacingY?: Spacing;
}>;

export const Row = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      alignX = 'normal',
      alignY = 'normal',
      children,
      component,
      noWrap,
      spacing = Spacing.None,
      spacingX = spacing,
      spacingY = spacing,
      ...otherProps
    }: React.PropsWithChildren<RowProps> & PolymorphicComponentProps<TElement>,
    forwardedRef: PolymorphicRef<TElement>,
  ) => (
    <StyledRow
      alignX={alignX}
      alignY={alignY}
      as={component ?? 'div'}
      ref={forwardedRef}
      noWrap={noWrap}
      $spacingX={spacingX}
      $spacingY={spacingY}
      {...otherProps}
    >
      {children}
    </StyledRow>
  ),
) as PolymorphicComponent<RowProps>;

Row.displayName = 'Row';
