import { InvariantException } from '@kontent-ai/errors';
import {
  CompiledTypeElementType,
  ElementType,
  ItemElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import {
  createRichTextDomainModel,
  createRichTextServerModel,
} from '../../richText/utils/serverModel/editorServerModelUtils.ts';
import {
  createAssetElementDomainModelConverter,
  createAssetElementServerModelConverter,
} from './itemElementDataConverters/assetItemElementDataConverter.ts';
import {
  customElementDomainModelConverter,
  customElementServerModelConverter,
} from './itemElementDataConverters/customItemElementDataConverter.ts';
import {
  dateTimeElementDomainModelConverter,
  dateTimeElementServerModelConverter,
} from './itemElementDataConverters/dateTimeItemElementDataConverter.ts';
import {
  linkedItemsElementDomainModelConverter,
  linkedItemsElementServerModelConverter,
} from './itemElementDataConverters/linkedItemsElementConverter.ts';
import {
  multipleChoiceElementDomainModelConverter,
  multipleChoiceElementServerModelConverter,
} from './itemElementDataConverters/multipleChoiceElementConverter.ts';
import {
  numberElementDomainModelConverter,
  numberElementServerModelConverter,
} from './itemElementDataConverters/numberItemElementDataConverter.ts';
import {
  createRichTextElementDomainModelConverter,
  createRichTextElementServerModelConverter,
} from './itemElementDataConverters/richTextItemElementDataConverter.ts';
import {
  taxonomyElementDomainModelConverter,
  taxonomyElementServerModelConverter,
} from './itemElementDataConverters/taxonomyItemElementDataConverter.ts';
import {
  createTextElementDomainModelConverter,
  createTextElementServerModelConverter,
} from './itemElementDataConverters/textItemElementDataConverter.ts';
import {
  IItemElementDomainModelConverter,
  IItemElementServerModelConverter,
} from './itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import {
  createUrlSlugElementDomainModelConverter,
  createUrlSlugElementServerModelConverter,
} from './itemElementDataConverters/urlSlugItemElementDataConverter.ts';

export type IGetItemElementDomainModelConverter = (
  type: CompiledTypeElementType,
) => IItemElementDomainModelConverter;

export type IGetItemElementServerModelConverter = (
  type: CompiledTypeElementType,
) => IItemElementServerModelConverter;

const richTextElementDomainModelConverter = createRichTextElementDomainModelConverter({
  createRichTextDomainModel,
  contentComponentConversionDependencies: {
    getItemElementDomainModelConverter: (type) => getItemElementDomainModelConverter(type),
  },
});

const richTextElementServerModelConverter = createRichTextElementServerModelConverter({
  createRichTextServerModel,
  contentComponentConversionDependencies: {
    getItemElementServerModelConverter: (type) => getItemElementServerModelConverter(type),
  },
});

const richTextItemElementDomainModelConverterForImport = createRichTextElementDomainModelConverter({
  createRichTextDomainModel,
  contentComponentConversionDependencies: {
    getItemElementDomainModelConverter: getItemElementDomainModelConverterForImport,
  },
});

const textElementDomainModelConverter = createTextElementDomainModelConverter({
  createRichTextDomainModel,
});

const textElementServerModelConverter = createTextElementServerModelConverter({
  createRichTextServerModel,
});

const assetElementDomainModelConverter = createAssetElementDomainModelConverter();

const assetElementServerModelConverter = createAssetElementServerModelConverter();

export const urlSlugElementDomainModelConverter = createUrlSlugElementDomainModelConverter({
  createRichTextDomainModel,
});

export const urlSlugElementServerModelConverter = createUrlSlugElementServerModelConverter({
  createRichTextServerModel,
});

type DomainModelConverterMap = ReadonlyRecord<ItemElementType, IItemElementDomainModelConverter>;

const domainModelConverterMap: DomainModelConverterMap = {
  [ElementType.Asset]: assetElementDomainModelConverter,
  [ElementType.DateTime]: dateTimeElementDomainModelConverter,
  [ElementType.LinkedItems]: linkedItemsElementDomainModelConverter,
  [ElementType.MultipleChoice]: multipleChoiceElementDomainModelConverter,
  [ElementType.Number]: numberElementDomainModelConverter,
  [ElementType.RichText]: richTextElementDomainModelConverter,
  [ElementType.Custom]: customElementDomainModelConverter,
  [ElementType.Text]: textElementDomainModelConverter,
  [ElementType.Taxonomy]: taxonomyElementDomainModelConverter,
  [ElementType.UrlSlug]: urlSlugElementDomainModelConverter,
};

const isKnownDomainModelConverterKey = (
  key: CompiledTypeElementType,
): key is keyof typeof domainModelConverterMap =>
  (Object.keys(domainModelConverterMap) as ReadonlyArray<CompiledTypeElementType>).includes(key);

type ServerModelConverterMap = ReadonlyRecord<ItemElementType, IItemElementServerModelConverter>;

const serverModelConverterMap: ServerModelConverterMap = {
  [ElementType.Asset]: assetElementServerModelConverter,
  [ElementType.DateTime]: dateTimeElementServerModelConverter,
  [ElementType.LinkedItems]: linkedItemsElementServerModelConverter,
  [ElementType.MultipleChoice]: multipleChoiceElementServerModelConverter,
  [ElementType.Number]: numberElementServerModelConverter,
  [ElementType.RichText]: richTextElementServerModelConverter,
  [ElementType.Custom]: customElementServerModelConverter,
  [ElementType.Text]: textElementServerModelConverter,
  [ElementType.Taxonomy]: taxonomyElementServerModelConverter,
  [ElementType.UrlSlug]: urlSlugElementServerModelConverter,
};

const isKnownServerModelConverterKey = (
  key: CompiledTypeElementType,
): key is keyof typeof serverModelConverterMap =>
  (Object.keys(serverModelConverterMap) as ReadonlyArray<CompiledTypeElementType>).includes(key);

export const getItemElementDomainModelConverter: IGetItemElementDomainModelConverter = (
  type: CompiledTypeElementType,
): IItemElementDomainModelConverter => {
  if (isKnownDomainModelConverterKey(type)) {
    return domainModelConverterMap[type];
  }

  throw InvariantException(`Given key "${type}" is not known.`);
};

export const getItemElementServerModelConverter: IGetItemElementServerModelConverter = (
  type: CompiledTypeElementType,
): IItemElementServerModelConverter => {
  if (isKnownServerModelConverterKey(type)) {
    return serverModelConverterMap[type];
  }

  throw InvariantException(`Given key "${type}" is not known.`);
};

const importConverterMap: DomainModelConverterMap = {
  ...domainModelConverterMap,
  [ElementType.RichText]: richTextItemElementDomainModelConverterForImport,
};

const isKnownImportConverterKey = (
  key: CompiledTypeElementType,
): key is keyof typeof importConverterMap =>
  (Object.keys(importConverterMap) as ReadonlyArray<CompiledTypeElementType>).includes(key);

export function getItemElementDomainModelConverterForImport(
  type: CompiledTypeElementType,
): IItemElementDomainModelConverter {
  if (isKnownImportConverterKey(type)) {
    return importConverterMap[type];
  }

  throw InvariantException(`Given key "${type}" is not known.`);
}
