import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import { shouldPreserveScrollPosition } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { Data_AssetListing_Started } from '../../../../../data/constants/dataActionTypes.ts';
import {
  AssetLibrary_Folders_Opened,
  AssetLibrary_Reloading_Started,
  AssetLibrary_Route_Left,
  AssetLibrary_ScrollGridState_Changed,
} from '../../constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import { IScrollGridState } from '../../stores/IAssetLibraryState.ts';

const initialState = {
  availableHeightPx: 0,
  scrollPositionPx: 0,
  scrollHeightPx: 0,
};

export const assetListingScrollGridState = (
  state: IScrollGridState = initialState,
  action: Action,
): IScrollGridState => {
  switch (action.type) {
    case AssetLibrary_ScrollGridState_Changed: {
      return {
        availableHeightPx: action.payload.availableHeightPx,
        scrollPositionPx: action.payload.scrollPositionPx,
        scrollHeightPx: action.payload.scrollHeightPx,
      };
    }

    case Data_AssetListing_Started: {
      if (shouldPreserveScrollPosition(action.payload.requestTrigger)) {
        return state;
      }

      return {
        ...state,
        scrollPositionPx: 0,
      };
    }

    case AssetLibrary_Folders_Opened:
    case AssetLibrary_Reloading_Started:
    case AssetLibrary_Route_Left:
    case AssetSelector_Opened:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
};
