import React from 'react';
import { RestoreRevisionButton } from '../../../containers/StatusBar/revisions/RestoreRevisionButton.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../utils/dateTime/timeUtils.ts';
import { makeSpacesUnbreakable } from '../../../utils/stringUtils.ts';

type ContentItemRevisionTitleProps = {
  readonly canRestoreRevision: boolean;
  readonly date: DateTimeStamp | null;
  readonly fullName: string | null;
  readonly isCurrent: boolean;
};

const getRevisionDetails = (date: DateTimeStamp | null, fullName: string | null): string => {
  const revisionDate = makeSpacesUnbreakable(renderDatetimeString(date));
  const authorName = makeSpacesUnbreakable(fullName);

  if (revisionDate && authorName) {
    return `(${revisionDate} by ${authorName})`;
  }

  if (revisionDate) {
    return `(${revisionDate})`;
  }

  return '';
};

export const ContentItemRevisionTitle: React.FC<ContentItemRevisionTitleProps> = ({
  canRestoreRevision,
  date,
  fullName,
  isCurrent,
}) => (
  <>
    <div className="status-bar__title-pane">
      <h3 className="status-bar__title" {...getDataUiElementAttribute(DataUiElement.RevisionTitle)}>
        {isCurrent ? 'Viewing current version' : 'Viewing selected version'}&nbsp;
        {getRevisionDetails(date, fullName)}
      </h3>
    </div>
    {canRestoreRevision && <RestoreRevisionButton>Restore this version</RestoreRevisionButton>}
  </>
);

ContentItemRevisionTitle.displayName = 'ContentItemRevisionTitle';
