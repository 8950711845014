import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isTechnicalRole } from '../../../../_shared/models/utils/quickstartUtils.ts';
import { getCurrentUser } from '../../../../_shared/selectors/getCurrentUser.ts';
import { GettingStartedQuickstart as GettingStartedQuickstartComponent } from '../../components/GettingStartedQuickstart/GettingStartedQuickstart.tsx';

export const GettingStartedQuickstart: React.FC = () => {
  const userName = useSelector((s) => getCurrentUser(s).firstName);
  const shouldShowSdkSection = useSelector((s) =>
    isTechnicalRole(s.sharedApp.userProperties.businessRole),
  );

  return (
    <GettingStartedQuickstartComponent showSdkSection={shouldShowSdkSection} userName={userName} />
  );
};

GettingStartedQuickstart.displayName = 'GettingStartedQuickstart';
