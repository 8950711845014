import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { AssetLibrary_Reloading_Started } from '../../constants/assetLibraryActionTypes.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { IOpenAssetFolderAction } from './openAssetFolder.ts';

interface IDeps {
  readonly openAssetFolder: IOpenAssetFolderAction;
}

interface IResult {
  readonly folderExists: boolean;
}

const reloadStarted = () =>
  ({
    type: AssetLibrary_Reloading_Started,
  }) as const;

export type LoadAssetFolderActionsType = ReturnType<typeof reloadStarted>;

export const createEnsureLoadedAssetFolderAction =
  (deps: IDeps) =>
  (assetFolderId: Uuid, orderBy: AssetsOrderBy, abortSignal: AbortSignal): ThunkPromise<IResult> =>
  async (dispatch, getState) => {
    const {
      assetLibraryApp: { openedFolderId },
      data: { assets },
    } = getState();

    if (assetFolderId === openedFolderId && assets.loadingStatus !== LoadingStatus.InitialEmpty) {
      // Do not reload assets when navigating to already initialized asset library
      return { folderExists: true };
    }

    dispatch(reloadStarted());

    const { folderOpened } = await dispatch(
      deps.openAssetFolder({ assetFolderId, orderBy }, abortSignal),
    );

    return { folderExists: folderOpened };
  };
