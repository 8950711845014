import { BREAK } from './symbols.ts';

export const encodeText = (text: string): string => {
  if (!text) {
    return '';
  }

  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\xA0/g, '&nbsp;')
    .replace(/\n/g, `${BREAK}\n`);
};

export const encodeAttr = (text: string): string => {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
};
