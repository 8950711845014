import { IRule, IRuleWithScope } from '../../../../data/models/roles/IRoleSettings.ts';
import { CapabilityScope } from '../models/CapabilityScope.ts';
import {
  assetCanRuleCapabilityOptions,
  cannotRuleCapabilityOptions,
  contentCanRuleCapabilityOptions,
} from './capabilityActionOptions.ts';

export const emptyCannotRule: IRule = {
  types: [],
  capabilities: cannotRuleCapabilityOptions[0] ?? [],
};

export const emptyContentCanRule: IRuleWithScope = {
  types: [],
  capabilities: contentCanRuleCapabilityOptions[0] ?? [],
  scope: CapabilityScope.AllContent,
};

export const emptyAssetCanRule: IRuleWithScope = {
  types: [],
  capabilities: assetCanRuleCapabilityOptions[0] ?? [],
  scope: CapabilityScope.AllAssets,
};
