import { MatchAiTasks } from '../../../../_shared/features/AI/hooks/aiTasks/matchAiTask.type.ts';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';

export const matchDescribeImageAiTasks =
  (assetId: Uuid): MatchAiTasks =>
  (aiTasks) =>
    aiTasks.filter(
      ({ action, context }) =>
        action.name === AiActionName.DescribeImage &&
        'assetId' in context &&
        context.assetId === assetId,
    );
