import { History } from 'history';
import { EnvironmentRoute, EnvironmentRouteParams } from '../../constants/routePaths.ts';
import { logError } from '../logError.ts';
import { buildPath } from './routeTransitionUtils.ts';

interface IParams {
  readonly history: History;
  readonly currentProjectId: Uuid;
  readonly error?: any;
}

export const buildDefaultRoute = (projectId: Uuid) => {
  return buildPath<EnvironmentRouteParams>(EnvironmentRoute, { projectId });
};

export const redirectToDefaultRoute = ({ history, currentProjectId, error }: IParams) => {
  history.push(buildDefaultRoute(currentProjectId));

  if (error) {
    logError(error);
  }
};
