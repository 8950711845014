import { isArchivedWorkflowStep } from '../../../../_shared/utils/contentItemUtils.ts';
import {
  IWorkflowStep,
  WorkflowStepAction,
} from '../../../../data/models/workflow/WorkflowStep.ts';

export const getWorkflowStepsWithEmptyTransitionsTo = (
  steps: readonly IWorkflowStep[],
): readonly IWorkflowStep[] =>
  steps
    .filter((step) => !!step && step.action === WorkflowStepAction.NoAction)
    .filter((step) => isArchivedWorkflowStep(step))
    .filter((step) => !!step && step.transitionsTo.size === 0);
