import { combineReducers } from '@reduxjs/toolkit';
import { editedWebhookId } from './internalReducers/editedWebhookId.ts';
import { isResettingWebhookInProgress } from './internalReducers/isResettingWebhookInProgress.ts';
import { isSettingWebhookStateInProgress } from './internalReducers/isSettingWebhookStateInProgress.ts';
import { lastOperation } from './internalReducers/lastOperation.ts';
import { newWebhooksOnboardingStep } from './internalReducers/newWebhooksOnboardingStep.ts';
import { webhookMessageContent } from './internalReducers/webhookMessageContent.ts';
import { webhookMessageContentLoadingStatus } from './internalReducers/webhookMessageContentLoadingStatus.ts';
import { webhookMessages } from './internalReducers/webhookMessages.ts';
import { webhookMessagesListingFilter } from './internalReducers/webhookMessagesListingFilter.ts';
import { webhookMessagesLoadingStatus } from './internalReducers/webhookMessagesLoadingStatus.ts';

export const webhooksAppReducer = combineReducers({
  editedWebhookId,
  isResettingWebhookInProgress,
  isSettingWebhookStateInProgress,
  lastOperation,
  newWebhooksOnboardingStep,
  webhookMessages,
  webhookMessagesLoadingStatus,
  webhookMessagesListingFilter,
  webhookMessageContent,
  webhookMessageContentLoadingStatus,
});
