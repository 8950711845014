import React from 'react';
import styled from 'styled-components';
import { ColorCircle } from '../../../containers/ColorCircle/ColorCircle.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import { colorDividerDefault } from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { DarkGradient, GradientAngle, GradientFunction } from '../../../tokens/quarks/gradients.ts';
import { BoxShadow } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Placement } from '../../../types/placement.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { AvatarSize } from '../avatarSize.ts';
import { Initials } from './Initials.tsx';

export interface IBaseAvatarProps {
  readonly backgroundGradient: GradientFunction;
  readonly boxShadow?: BoxShadow;
  /** If not provided, initials are rendered */
  readonly image?: string;
  readonly initials: string;
  readonly label: string;
  readonly size: AvatarSize;
  readonly tooltipPlacement?: Placement;
}

interface IInjectedProps {
  readonly children: JSX.Element;
  readonly label: string;
}

export interface IBaseAvatarComponentProps extends IBaseAvatarProps {
  readonly renderContainer: (props: IInjectedProps) => JSX.Element;
  readonly tooltipText?: string;
}

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border: ${px(borderWidthDefault)} solid ${colorDividerDefault};
  border-radius: ${px(BorderRadius.Pill)};
  background: ${BaseColor.Gray10};
  overflow: hidden;
`;

export const BaseAvatarComponent: React.FC<IBaseAvatarComponentProps> = ({
  backgroundGradient,
  image,
  initials,
  label,
  renderContainer,
  size,
  tooltipText,
  tooltipPlacement = 'bottom',
}) => {
  const hasImage = !!image;

  return (
    <Tooltip tooltipText={tooltipText} placement={tooltipPlacement}>
      {renderContainer({
        children: hasImage ? (
          <Image src={image} alt={label} />
        ) : (
          <ColorCircle
            color={
              backgroundGradient(GradientAngle.ToBottom) ??
              DarkGradient.GrayDark(GradientAngle.ToBottom)
            }
            size={size}
          >
            <Initials $size={size} aria-hidden>
              {size === AvatarSize.XS ? initials[0] : initials}
            </Initials>
            <SrOnly>{label}</SrOnly>
          </ColorCircle>
        ),
        label,
      })}
    </Tooltip>
  );
};

BaseAvatarComponent.displayName = 'BaseAvatarComponent';
