import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, Typography, spacingCard } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';

type Props = {
  readonly id?: string;
  readonly text: string;
};

const StyledCardPageTitle = styled.h1`
  word-break: break-word;
  ${Typography.PageTitle};
`;

/**
 * @deprecated This is a temporary solution until the page titles consistency is solved in KCL-8561.
 * Please, do not add more of these, we don't want to have page titles on the card anymore.
 */
export const CardPageTitle = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ children, id, text, ...otherProps }, forwardedRef) => (
    <Box
      component="header"
      ref={forwardedRef}
      paddingBottom={spacingCard}
      css="grid-area: headline"
    >
      <Row spacing={Spacing.XL} noWrap>
        <Column width="fit-content">
          <StyledCardPageTitle id={id} {...otherProps}>
            {text}
          </StyledCardPageTitle>
        </Column>
        {children && (
          <Column width="content">
            <Row
              alignY="center"
              css={`
              height: ${Typography.PageTitle.lineHeight}
            `}
              noWrap
            >
              <Column width="fit-content">{children}</Column>
            </Row>
          </Column>
        )}
      </Row>
    </Box>
  ),
);

CardPageTitle.displayName = 'CardPageTitle';
