import { Location, LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { RedirectProps, useLocation } from 'react-router';

const getTargetLocationDescriptor = (
  location: Location,
  to: LocationDescriptor,
): LocationDescriptor => {
  if (typeof to === 'string') {
    return {
      pathname: to,
      search: location.search,
    };
  }

  return {
    ...to,
    search: location.search,
  };
};

export const useRedirectPropsWithSameSearch = (): ((
  redirectProps: RedirectProps,
) => RedirectProps) => {
  const location = useLocation();

  return useCallback(
    (redirectProps: RedirectProps): RedirectProps => ({
      ...redirectProps,
      to: getTargetLocationDescriptor(location, redirectProps.to),
    }),
    [location],
  );
};
