import { memoize } from '@kontent-ai/memoization';
import escapeHtml from 'escape-html';
import {
  validateMaxChars,
  validateMaxWords,
} from '../../../../_shared/utils/validation/limitations/baseTextLimitValidator.ts';
import { ITextTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import {
  getCharacterCount,
  getCharacterWithoutWhitespacesCount,
  getWordCount,
} from '../../../richText/plugins/textApi/api/editorTextUtils.ts';
import { validTextWarningResultWithRegexValidationMessage } from '../../constants/validElementWarningResults.ts';
import {
  TextIsRequiredWarning,
  TextTooLongWarning,
} from '../../constants/warningMessageTemplates.ts';
import { ITextItemElement } from '../../models/contentItemElements/TextItemElement.ts';
import { ITextWarningResult } from './types/ITextItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from './types/Warnings.ts';
import {
  getIsRegexValidationMet,
  getValidationMessageValue,
} from './utils/validationRegexWarningHelper.ts';

const validateRequiredLimit = (isRequired: boolean | undefined, charsCount: number): boolean =>
  isRequired ? charsCount > 0 : true;

interface IParams {
  readonly typeElement: ITextTypeElement;
  readonly itemElement: ITextItemElement;
}

const getMemoizedResult = memoize.allForever(
  (
    maxChars: number | null,
    maxWords: number | null,
    isMaxCharsLimitMet: boolean,
    isMaxWordsLimitMet: boolean,
    isRequiredLimitMet: boolean,
    isRegexValidationMet: boolean,
    regexValidationMessage: string,
  ): ITextWarningResult => {
    const warningMessages: Array<string> = [];

    if (!isMaxCharsLimitMet) {
      warningMessages.push(TextTooLongWarning(maxChars, 'characters'));
    }

    if (!isMaxWordsLimitMet) {
      warningMessages.push(TextTooLongWarning(maxWords, 'words'));
    }

    if (isRequiredLimitMet && !isRegexValidationMet) {
      warningMessages.push(escapeHtml(regexValidationMessage));
    }

    return {
      ...emptyItemElementWarningResult,
      isMaxCharsLimitMet,
      isMaxWordsLimitMet,
      isRegexValidationMet,
      limitationMessages: warningMessages,
      regexValidationMessage: isRegexValidationMet ? null : regexValidationMessage,
      requiredMessage: isRequiredLimitMet ? null : TextIsRequiredWarning,
    };
  },
);

const areConditionsToSkipValidationMet = (
  typeElement: ITextTypeElement,
  isEmpty: boolean,
): boolean => !typeElement.isRequired && isEmpty;

export const getTextItemElementValidationWarning = ({
  typeElement,
  itemElement,
}: IParams): ITextWarningResult => {
  const editorState = itemElement._editorState;
  const isRequired = typeElement.isRequired;
  const maxChars = typeElement.maxChars;
  const maxWords = typeElement.maxWords;
  const editorContent = editorState.getCurrentContent();
  const wordsCount = getWordCount(editorContent);
  const charsCount = getCharacterCount(editorContent);
  const charsWithoutWhitespaceCount = getCharacterWithoutWhitespacesCount(editorContent);
  const isEmpty = !editorContent.hasText();
  const regexValidationMessage = getValidationMessageValue(
    typeElement.validationRegex?.validationMessage,
  );

  if (areConditionsToSkipValidationMet(typeElement, isEmpty)) {
    return validTextWarningResultWithRegexValidationMessage(regexValidationMessage);
  }

  const isMaxCharsLimitMet = validateMaxChars(maxChars, charsCount);
  const isMaxWordsLimitMet = validateMaxWords(maxWords, wordsCount);
  const isRequiredLimitMet = validateRequiredLimit(isRequired, charsWithoutWhitespaceCount);
  const isRegexValidationMet = getIsRegexValidationMet(
    typeElement.validationRegex,
    editorContent.getPlainText(),
  );

  return getMemoizedResult(
    maxChars,
    maxWords,
    isMaxCharsLimitMet,
    isMaxWordsLimitMet,
    isRequiredLimitMet,
    isRegexValidationMet,
    regexValidationMessage,
  );
};
