import { Action } from '../../../../@types/Action.type.ts';
import { KeyRegenerationStatus } from '../../ApiKeys/models/KeyRegenerationStatus.ts';
import {
  SubscriptionApiKey_Creation_Failed,
  SubscriptionApiKey_Creation_Finished,
  SubscriptionApiKey_Creation_Started,
  SubscriptionApiKey_GenerationStatus_Reset,
  SubscriptionApiKey_Loading_Started,
  SubscriptionApiKey_Regeneration_Failed,
  SubscriptionApiKey_Regeneration_Finished,
  SubscriptionApiKey_Regeneration_Started,
} from '../constants/subscriptionManagementActionTypes.ts';

const initialState: KeyRegenerationStatus = KeyRegenerationStatus.None;

export const regenerationStatus = (
  state: KeyRegenerationStatus = initialState,
  action: Action,
): KeyRegenerationStatus => {
  switch (action.type) {
    case SubscriptionApiKey_Creation_Started:
    case SubscriptionApiKey_Regeneration_Started:
      return KeyRegenerationStatus.Started;

    case SubscriptionApiKey_Creation_Finished:
    case SubscriptionApiKey_Regeneration_Finished:
      return KeyRegenerationStatus.Finished;

    case SubscriptionApiKey_Creation_Failed:
    case SubscriptionApiKey_Regeneration_Failed:
      return KeyRegenerationStatus.Failed;

    case SubscriptionApiKey_Loading_Started:
    case SubscriptionApiKey_GenerationStatus_Reset:
      return initialState;

    default:
      return state;
  }
};
