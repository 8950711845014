import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { createSelection } from '../../../utils/editorSelectionUtils.ts';
import { ContentComponentsPlugin } from '../ContentComponentsPlugin.tsx';
import {
  convertContentComponentToModularItem,
  insertContentComponent as insertContentComponentToContent,
} from './editorContentComponentUtils.ts';

export const editorContentComponentApi: EditorApiImplementation<ContentComponentsPlugin> = {
  convertContentComponent(api, editorState, blockKey, itemIds) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => convertContentComponentToModularItem(input, blockKey, itemIds),
      'insert-fragment',
    );
  },

  insertContentComponent(
    api,
    editorState,
    placeholderBlockKey,
    contentComponentId,
    allowUndo = true,
  ) {
    const selection = allowUndo
      ? createSelection(placeholderBlockKey)
      : editorState.getCurrentContent().getSelectionBefore();

    return api.executeContentChange(
      editorState,
      selection,
      (input) => insertContentComponentToContent(input, placeholderBlockKey, contentComponentId),
      'insert-fragment',
      allowUndo,
    );
  },
};
