export enum ModalDialogType {
  AiFeedbackDialog = 'AiFeedbackDialog',
  ApiKeyRegenerationDialog = 'ApiKeyRegenerationDialog',
  BulkItemsDeleteConfirmationDialog = 'BulkItemsDeleteConfirmationDialog',
  CancelItemScheduledUnpublishConfirmationDialog = 'CancelItemScheduledUnpublishConfirmationDialog',
  CascadePublishDialog = 'CascadePublishDialog',
  CascadePublishFailedItemsDialog = 'CascadePublishFailedItemsDialog',
  CascadeScheduleDialog = 'CascadeScheduleDialog',
  CascadeScheduleFailedItemsDialog = 'CascadeScheduleFailedItemsDialog',
  CascadeUnpublishConfirmationDialog = 'CascadeUnpublishConfirmationDialog',
  ConfigureContentTypePreviewUrls = 'ConfigureContentTypePreviewUrls',
  ChangeContentItemCollectionDialog = 'ChangeContentItemCollectionDialog',
  ChangeDefaultRootConfirmationDialog = 'ChangeDefaultRootConfirmationDialog',
  ChangeWorkflowDialog = 'ChangeWorkflowDialog',
  ChangeWorkflowStepDialog = 'ChangeWorkflowStepDialog',
  ChangeWorkflowStepListingDialog = 'ChangeWorkflowStepListingDialog',
  ContentItemAddTaskDialog = 'ContentItemAddTaskDialog',
  ContentItemEditTaskDialog = 'ContentItemEditTaskDialog',
  ContentItemNoteDialog = 'ContentItemNoteDialog',
  ContentItemAssignContributorsDialog = 'ContentItemAssignContributorsDialog',
  ContentItemDueDateDialog = 'ContentItemDueDateDialog',
  ContentTypeAsComponentUsageDialog = 'ContentTypeAsComponentUsageDialog',
  ContentModelingSatisfactionSurveyDialog = 'ContentModelingSatisfactionSurveyDialog',
  DeleteItemConfirmationDialog = 'DeleteItemConfirmationDialog',
  DiscardNewVersionConfirmationDialog = 'DiscardNewVersionConfirmationDialog',
  DuplicateItemWithContent = 'DuplicateItemWithContent',
  DuplicateItemWithoutContent = 'DuplicateItemWithoutContent',
  HelpUsImproveSurveyDialog = 'HelpUsImproveSurveyDialog',
  SapiKeyGenerationDialog = 'SapiKeyGenerationDialog',
  MoveItemToArchivedStepConfirmationDialog = 'ArchiveItemConfirmationDialog',
  MoveItemsToArchivedStepConfirmationDialog = 'ArchiveItemsConfirmationDialog',
  NewContentItemDialog = 'NewContentItemDialog',
  NewContentItemDialogForWebSpotlight = 'NewContentItemDialogForWebSpotlight',
  NewVariantWorkflowSelectionDialog = 'NewVariantWorkflowSelectionDialog',
  None = 'none',
  RevokeApiKeyDialog = 'RevokeApiKeyDialog',
  RemoveTaxonomyTermConfirmationDialog = 'RemoveTaxonomyTermConfirmationDialog',
  RestoreRevisionConfirmationDialog = 'RestoreRevisionConfirmationDialog',
  SendYourFeedbackDialog = 'SendYourFeedbackDialog',
  ThanksForFeedbackDialog = 'ThanksForFeedbackDialog',
  UnsupportedAssetFileType = 'UnsupportedAssetFileType',
}
