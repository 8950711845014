import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ContentState, EditorState } from 'draft-js';
import { forwardRef, useMemo, useState } from 'react';
import { ActionMenuPositioner } from '../../../../../../_shared/features/AI/components/ActionMenuPositioner.tsx';
import {
  AiActionMenu,
  createCopyToClipboardAiAction,
  createDiscardAiActionFromResult,
  createEditInputsAiAction,
  createRejectSuggestionAiSection,
  createReplaceSelectionAiAction,
  createTryAgainAiAction,
  createUseSuggestionAiSection,
} from '../../../../../../_shared/features/AI/components/AiActionMenu.tsx';
import { AiResult } from '../../../../../../_shared/features/AI/components/AiResult.tsx';
import { ActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import {
  SpecificFeedbackReason,
  createFeedbackReasons,
} from '../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { AiSuggestionError } from '../../components/AiSuggestionError.tsx';
import { RichAiSuggestionViewer } from '../../containers/viewers/RichAiSuggestionViewer.tsx';
import { ImproveContentDialog } from './ImproveContentDialog.tsx';

const feedbackReasons = createFeedbackReasons([
  SpecificFeedbackReason.ResultNotByInstructions,
  SpecificFeedbackReason.ResultSlow,
]);

type ImproveContentActionProps = {
  readonly initialInstructions: string;
  readonly onClosePropsDialog: () => void;
  readonly onCopyToClipboard?: () => void;
  readonly onDiscard: () => void;
  readonly onInputsEdited: () => void;
  readonly onReplaceSelection?: () => void;
  readonly onSubmit: (contentInstructions: string) => void;
  readonly onTryAgain?: () => void;
  readonly preferMenuOnTop: boolean;
  readonly result: ActionResult<ContentState>;
  readonly resultWidth: number;
};

export const ImproveContentAction = forwardRef<HTMLElement, ImproveContentActionProps>(
  (
    {
      initialInstructions,
      onClosePropsDialog,
      onCopyToClipboard,
      onDiscard,
      onInputsEdited,
      onReplaceSelection,
      onSubmit,
      onTryAgain,
      preferMenuOnTop,
      result,
      resultWidth,
    },
    ref,
  ) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const editContentInstructionsAction = useMemo(
      () =>
        result.isFinished
          ? () => {
              setIsDialogOpen(true);
              onInputsEdited();
            }
          : undefined,
      [result.isFinished, onInputsEdited],
    );

    const improveContentMenuOptions = [
      createUseSuggestionAiSection(
        [
          createReplaceSelectionAiAction(onReplaceSelection),
          createCopyToClipboardAiAction(onCopyToClipboard),
        ],
        result,
      ),
      createRejectSuggestionAiSection([
        createEditInputsAiAction('Edit Content instructions', editContentInstructionsAction),
        createTryAgainAiAction(result, onTryAgain),
        createDiscardAiActionFromResult(onDiscard, result),
      ]),
    ];

    const resultEditorState = useMemo(
      () => (result.content ? EditorState.createWithContent(result.content) : null),
      [result.content],
    );
    const hasResult = !!result.error || !!resultEditorState;

    const handleSubmit = (instructions: string): void => {
      setIsDialogOpen(false);
      onSubmit(instructions);
    };

    const handleClose = (): void => {
      setIsDialogOpen(false);
      onClosePropsDialog();
    };

    return (
      <>
        <ImproveContentDialog
          initialInstructions={initialInstructions}
          isDialogOpen={isDialogOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
        {!isDialogOpen && (
          <ActionMenuPositioner
            preferMenuOnTop={preferMenuOnTop}
            ref={ref}
            renderMenu={() => <AiActionMenu options={improveContentMenuOptions} />}
            renderResult={(resultRef) => (
              <AiResult
                ref={resultRef}
                actionName={AiActionName.ImproveContentByBrief}
                actionTitle="Improve content"
                feedbackReasons={feedbackReasons}
                isLoading={!result.isFinished}
                renderResult={() =>
                  hasResult && (
                    <Stack spacing={Spacing.L}>
                      {result.error && <AiSuggestionError error={result.error} />}
                      {resultEditorState && (
                        <RichAiSuggestionViewer editorState={resultEditorState} />
                      )}
                    </Stack>
                  )
                }
                width={resultWidth}
              />
            )}
          />
        )}
      </>
    );
  },
);

ImproveContentAction.displayName = 'ImproveContentAction';
