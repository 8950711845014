import { memoize } from '@kontent-ai/memoization';
import escapeHtml from 'escape-html';
import { IUrlSlugTypeElement } from '../../../contentInventory/content/models/contentTypeElements/UrlSlugTypeElement.ts';
import { getBlocks } from '../../../richText/utils/general/editorContentGetters.ts';
import { validUrlSlugWarningResultWithRegexValidationMessage } from '../../constants/validElementWarningResults.ts';
import { IsRequiredWarning } from '../../constants/warningMessageTemplates.ts';
import { IUrlSlugItemElement } from '../../models/contentItemElements/UrlSlugItemElement.ts';
import { IUrlSlugWarningResult } from './types/IUrlSlugWarningResult.type.ts';
import { emptyItemElementWarningResult } from './types/Warnings.ts';
import {
  getIsRegexValidationMet,
  getValidationMessageValue,
} from './utils/validationRegexWarningHelper.ts';

const getNumberOfChars = (itemElement: IUrlSlugItemElement) => {
  const content = itemElement._editorState.getCurrentContent();
  const blocks = getBlocks(content);
  const characterCount = blocks.reduce((length, block) => length + block.getLength(), 0);
  return characterCount;
};

const isRequiredLimitValid = (
  typeElement: IUrlSlugTypeElement,
  itemElement: IUrlSlugItemElement,
): boolean => {
  if (typeElement.isRequired) {
    const charCount = getNumberOfChars(itemElement);
    return charCount > 0;
  }

  return true;
};

interface IParams {
  readonly typeElement: IUrlSlugTypeElement;
  readonly itemElement: IUrlSlugItemElement;
}

const getMemoizedResult = memoize.allForever(
  (
    isRequiredLimitMet: boolean,
    isRegexValidationMet: boolean,
    regexValidationMessage: string,
  ): IUrlSlugWarningResult => {
    const warningMessages: Array<string> = [];

    if (isRequiredLimitMet && !isRegexValidationMet) {
      warningMessages.push(escapeHtml(regexValidationMessage));
    }

    return {
      ...emptyItemElementWarningResult,
      isRegexValidationMet,
      regexValidationMessage,
      limitationMessages: [...warningMessages],
      requiredMessage: isRequiredLimitMet ? null : IsRequiredWarning,
    };
  },
);

const areConditionsToSkipValidationMet = (
  typeElement: IUrlSlugTypeElement,
  isEmpty: boolean,
): boolean => !typeElement.isRequired && isEmpty;

export const getUrlSlugItemElementValidationWarning = ({
  typeElement,
  itemElement,
}: IParams): IUrlSlugWarningResult => {
  const editorState = itemElement._editorState;
  const editorContent = editorState.getCurrentContent();
  const isRequiredLimitMet = isRequiredLimitValid(typeElement, itemElement);
  const isEmpty = getNumberOfChars(itemElement) === 0;
  const regexValidationMessage = getValidationMessageValue(
    typeElement.validationRegex?.validationMessage,
  );

  if (areConditionsToSkipValidationMet(typeElement, isEmpty)) {
    return validUrlSlugWarningResultWithRegexValidationMessage(regexValidationMessage);
  }

  const isRegexValidationMet = getIsRegexValidationMet(
    typeElement.validationRegex,
    editorContent.getPlainText(),
  );
  return getMemoizedResult(isRequiredLimitMet, isRegexValidationMet, regexValidationMessage);
};
