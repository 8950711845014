import { createFormattedJsonString } from '../../../../../_shared/utils/jsonUtils.ts';
import { CustomTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface ICustomTypeElementData extends IBaseTypeElementData {
  readonly sourceUrl: string;
  readonly config: string | null;
  readonly allowedElements: ReadonlyArray<Uuid>;
}

export const EmptyCustomTypeElementData: ICustomTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Required,
  allowedElements: [],
  config: null,
  sourceUrl: '',
  type: ElementType.Custom,
};

export function getCustomTypeElementDataFromServerModel(
  serverModel: CustomTypeElementServerModel,
): ICustomTypeElementData {
  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyCustomTypeElementData._configurability,
    allowedElements: serverModel.allowedElements ?? EmptyCustomTypeElementData.allowedElements,
    config: serverModel.config
      ? createFormattedJsonString(serverModel.config)
      : EmptyCustomTypeElementData.config,
    sourceUrl: serverModel.sourceUrl ?? EmptyCustomTypeElementData.sourceUrl,
    type: EmptyCustomTypeElementData.type,
  };
}
