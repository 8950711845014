import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React, { RefObject } from 'react';
import styled from 'styled-components';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../../_shared/utils/forwardedRefProps.tsx';

type EditingActionsLinkWrapperProps = IForwardedRefProps<HTMLElement> & {
  readonly children: React.ReactElement;
  readonly disabled: boolean | undefined;
  readonly onClick: () => void;
  readonly outwardLinkUrl: string | undefined;
};

const EditingActionsOutwardLink = styled(OutwardLink)`
  text-decoration: none;
  :hover, :visited, :focus {
    text-decoration: none;
  }
`;

const EditingActionsLinkWrapper: React.FC<EditingActionsLinkWrapperProps> = (props) => {
  if (props.outwardLinkUrl && !props.disabled) {
    return (
      <EditingActionsOutwardLink
        ref={props.forwardedRef as RefObject<HTMLAnchorElement>}
        href={props.outwardLinkUrl}
        onClick={props.onClick}
      >
        {props.children}
      </EditingActionsOutwardLink>
    );
  }

  if (props.forwardedRef) {
    return <div ref={props.forwardedRef as RefObject<HTMLDivElement>}>{props.children}</div>;
  }

  return props.children;
};

EditingActionsLinkWrapper.displayName = 'EditingActionsLinkWrapper';

const EditingActionsLinkWrapperFRC = forwardRef(EditingActionsLinkWrapper);

export { EditingActionsLinkWrapperFRC as EditingActionsLinkWrapper };
