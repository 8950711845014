import { ContentTypeConversionOptions } from '../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import { ContentTypeElementServerModel } from '../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import {
  ElementType,
  TypeElementType,
  TypeElementTypeServerModel,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { createRichTextServerModel } from '../../../richText/utils/serverModel/editorServerModelUtils.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { fixConditionsOperatorsInTypeElements } from './fixConditionsOperatorsInTypeElements.ts';
import { createUrlSlugTypeElementDataConverter } from './typeElementDataConverters/UrlSlugTypeElementDataConverter.ts';
import { createAssetTypeElementDataConverter } from './typeElementDataConverters/assetTypeElementDataConverter.ts';
import { createContentTypeSnippetTypeElementDataConverter } from './typeElementDataConverters/contentTypeSnippetTypeElementDataConverter.ts';
import { createCustomTypeElementDataConverter } from './typeElementDataConverters/customTypeElementDataConverter.ts';
import { createDateTimeTypeElementDataConverter } from './typeElementDataConverters/dateTimeTypeElementDataConverter.ts';
import { createGuidelinesTypeElementDataConverter } from './typeElementDataConverters/guidelinesTypeElementDataConverter.ts';
import { createModularContentTypeElementDataConverter } from './typeElementDataConverters/modularContentTypeElementDataConverter.ts';
import { createMultipleChoiceTypeElementDataConverter } from './typeElementDataConverters/multipleChoiceTypeElementDataConverter.ts';
import { createNumberTypeElementDataConverter } from './typeElementDataConverters/numberTypeElementDataConverter.ts';
import { createRichTextTypeElementDataConverter } from './typeElementDataConverters/richTextTypeElementDataConverter.ts';
import { createTaxonomyTypeElementDataConverter } from './typeElementDataConverters/taxonomyTypeElementDataConverter.ts';
import { createTextTypeElementDataConverter } from './typeElementDataConverters/textTypeElementDataConverter.ts';
import { ITypeElementDataConverters } from './typeElementDataConverters/types/ITypeElementDataConverters.type.ts';
import { withDefaultNameIfNotSet } from './typeElementsUtils.ts';

const assetTypeElementDataConverter = createAssetTypeElementDataConverter();

const dateTimeTypeElementDataConverter = createDateTimeTypeElementDataConverter();

const guidelinesTypeElementDataConverter = createGuidelinesTypeElementDataConverter({
  createRichTextServerModel,
});

const modularContentTypeDataConverter = createModularContentTypeElementDataConverter();

const multipleChoiceTypeElementDataConverter = createMultipleChoiceTypeElementDataConverter();

const numberTypeElementDataConverter = createNumberTypeElementDataConverter();

const customTypeElementDataConverter = createCustomTypeElementDataConverter();

const richTextTypeElementDataConverter = createRichTextTypeElementDataConverter();

const textTypeElementDataConverter = createTextTypeElementDataConverter();

const taxonomyTypeElementDataConverter = createTaxonomyTypeElementDataConverter();

const urlSlugTypeElementDataConverter = createUrlSlugTypeElementDataConverter();

const contentTypeSnippetTypeElementDataConverter =
  createContentTypeSnippetTypeElementDataConverter();

const serverModelToRecordMap: ReadonlyRecord<
  TypeElementTypeServerModel,
  ITypeElementDataConverters
> = {
  [ElementType.Asset]: assetTypeElementDataConverter,
  [ElementType.ContentTypeSnippet]: contentTypeSnippetTypeElementDataConverter,
  [ElementType.Custom]: customTypeElementDataConverter,
  [ElementType.DateTime]: dateTimeTypeElementDataConverter,
  [ElementType.Guidelines]: guidelinesTypeElementDataConverter,
  [ElementType.LinkedItems]: modularContentTypeDataConverter,
  [ElementType.MultipleChoice]: multipleChoiceTypeElementDataConverter,
  [ElementType.Number]: numberTypeElementDataConverter,
  [ElementType.RichText]: richTextTypeElementDataConverter,
  [ElementType.Taxonomy]: taxonomyTypeElementDataConverter,
  [ElementType.Text]: textTypeElementDataConverter,
  [ElementType.UrlSlug]: urlSlugTypeElementDataConverter,
};

const recordToServerModelMap: ReadonlyRecord<TypeElementType, ITypeElementDataConverters> = {
  [ElementType.Asset]: assetTypeElementDataConverter,
  [ElementType.ContentTypeSnippet]: contentTypeSnippetTypeElementDataConverter,
  [ElementType.Custom]: customTypeElementDataConverter,
  [ElementType.DateTime]: dateTimeTypeElementDataConverter,
  [ElementType.Guidelines]: guidelinesTypeElementDataConverter,
  [ElementType.LinkedItems]: modularContentTypeDataConverter,
  [ElementType.MultipleChoice]: multipleChoiceTypeElementDataConverter,
  [ElementType.Number]: numberTypeElementDataConverter,
  [ElementType.RichText]: richTextTypeElementDataConverter,
  [ElementType.Subpages]: modularContentTypeDataConverter,
  [ElementType.Taxonomy]: taxonomyTypeElementDataConverter,
  [ElementType.Text]: textTypeElementDataConverter,
  [ElementType.UrlSlug]: urlSlugTypeElementDataConverter,
};

export function getTypeElementRecord(
  serverTypeElement: ContentTypeElementServerModel,
  conversionOptions: ContentTypeConversionOptions,
): IBaseTypeElementData {
  return serverModelToRecordMap[serverTypeElement.type].convertTypeElementServerModelToRecord(
    serverTypeElement,
    conversionOptions,
  );
}

export function convertTypeElementsToServerModel(
  typeElements: ReadonlyArray<IBaseTypeElementData>,
): Array<ContentTypeElementServerModel> {
  return fixConditionsOperatorsInTypeElements(typeElements)
    .map(withDefaultNameIfNotSet)
    .map((typeElementRecord) =>
      recordToServerModelMap[typeElementRecord.type].convertTypeElementRecordToServerModel(
        typeElementRecord,
      ),
    );
}
