import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_LoadWebSpotlightTree_Failed,
  WebSpotlight_LoadWebSpotlightTree_Started,
  WebSpotlight_LocalizedRoute_Left,
} from '../../../applications/webSpotlight/constants/webSpotlightActionTypes.ts';
import {
  Shared_SmartLinkCommand_Forget,
  Shared_SmartLinkCommand_Remember,
} from '../../constants/sharedActionTypes.ts';
import { SmartLinkCommand } from '../../models/SmartLinkCommand.ts';

const initialState: SmartLinkCommand | null = null;

export const smartLinkCommand = (state = initialState, action: Action) => {
  switch (action.type) {
    case WebSpotlight_LoadWebSpotlightTree_Started:
    case WebSpotlight_LoadWebSpotlightTree_Failed:
    case WebSpotlight_LocalizedRoute_Left:
    case Shared_SmartLinkCommand_Forget:
      return initialState;

    case Shared_SmartLinkCommand_Remember:
      return action.payload.command;

    default:
      return state;
  }
};
