import { Action } from '../../../../@types/Action.type.ts';
import { Shared_Editor_Left } from '../../../../_shared/constants/sharedActionTypes.ts';
import { AssetLibrary_Asset_AssetsInEditedItemValidationFinished } from '../../../contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentModels_TypeEditor_Left } from '../../../contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ElementValue_Changed,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ItemElementFriendlyWarnings } from '../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import {
  updateStateForNonRichTextElement,
  updateStateForRichTextElement,
} from '../utils/validationResultUtils.ts';

const initialState: ItemElementFriendlyWarnings = new Map();

export function itemValidationFriendlyWarnings(
  state = initialState,
  action: Action,
): ItemElementFriendlyWarnings {
  switch (action.type) {
    case ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished:
    case AssetLibrary_Asset_AssetsInEditedItemValidationFinished: {
      return action.payload.friendlyWarnings;
    }

    case ContentItemEditing_ElementValue_Changed:
    case ContentItemEditing_ElementRefresh_Finished: {
      const elementData = action.payload.elementData;
      const validationResult = action.payload.validationResult;

      return elementData.type === ElementType.RichText
        ? updateStateForRichTextElement(
            state,
            validationResult.friendlyWarnings,
            elementData.elementId,
          )
        : updateStateForNonRichTextElement(
            state,
            validationResult.friendlyWarnings,
            elementData.elementId,
          );
    }

    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
