import { Redirect, Route, Switch } from 'react-router';
import { EnvironmentErrorHandlingRoutes } from '../../applications/errorHandling/EnvironmentErrorHandlingRoutes.tsx';
import { ProjectErrorHandlingRoutes } from '../../applications/errorHandling/ProjectErrorHandlingRoutes.tsx';
import { getErrorHandlingRoutes } from '../../applications/errorHandling/errorHandlingRoutes.tsx';
import { EnsureApiKeysSettingsInitialized } from '../../applications/projectSettings/apiKeys/containers/EnsureApiKeysSettingsInitialized.tsx';
import { ProjectSettings } from '../../applications/projectSettings/root/containers/ProjectSettings.tsx';
import { getProjectsRoutes } from '../../applications/projects/projectsRoutes.tsx';
import { getSmartLinkRoutes } from '../../applications/smartLink/smartLinkRoutes.tsx';
import { getSubscriptionManagementRoutes } from '../../applications/subscriptionManagement/shared/subscriptionManagementRoutes.tsx';
import { UserProfileEditor } from '../../applications/userProfile/containers/UserProfileEditor.tsx';
import { HtmlPageTitle, SpecialAppNames } from '../components/HtmlPageTitle.tsx';
import { useRedirectPropsWithSameSearch } from '../components/routing/useRedirectPropsWithSameSearch.tsx';
import { AppNames } from '../constants/applicationNames.ts';
import {
  EnvironmentRoute,
  EnvironmentWarningRoute,
  ProjectSettingsRoute,
  ProjectWarningRoute,
  UserProfileRoute,
} from '../constants/routePaths.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { useSetCurrentProjectIdToSentry } from '../hooks/useSetCurrentProjectIdToSentry.ts';
import { getPathToRedirectToFromRoot } from '../utils/getPathToRedirectToFromRoot.ts';
import { AppWrapper } from './AppWrapper.tsx';
import { EnsureCurrentProject } from './EnsureCurrentProject/EnsureCurrentProject.tsx';
import { EnsureCurrentProjectContainer } from './EnsureCurrentProjectContainer/EnsureCurrentProjectContainer.tsx';
import { Project } from './Project.tsx';

export const App = () => {
  const pathToRedirectFromRoot = useSelector(getPathToRedirectToFromRoot);
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();
  useSetCurrentProjectIdToSentry();

  return (
    <AppWrapper>
      <HtmlPageTitle appName={SpecialAppNames.None} />
      <Switch>
        {getSmartLinkRoutes()}
        {getSubscriptionManagementRoutes(getRedirectPropsWithSameSearch)}
        <Route key={AppNames.UserProfile} path={UserProfileRoute}>
          <UserProfileEditor />
        </Route>
        <Route path={[ProjectSettingsRoute, ProjectWarningRoute]}>
          <EnsureCurrentProjectContainer>
            <Switch>
              <Route path={ProjectWarningRoute}>
                <ProjectErrorHandlingRoutes />
              </Route>
              <Route path={ProjectSettingsRoute}>
                <EnsureApiKeysSettingsInitialized>
                  <ProjectSettings />
                </EnsureApiKeysSettingsInitialized>
              </Route>
            </Switch>
          </EnsureCurrentProjectContainer>
        </Route>
        {getProjectsRoutes(getRedirectPropsWithSameSearch)}
        {getErrorHandlingRoutes()}
        <Route path={EnvironmentRoute}>
          <EnsureCurrentProject>
            <Switch>
              <Route path={EnvironmentWarningRoute}>
                <EnvironmentErrorHandlingRoutes />
              </Route>
              <Route>
                <Project />
              </Route>
            </Switch>
          </EnsureCurrentProject>
        </Route>
        <Route>
          <Redirect {...getRedirectPropsWithSameSearch({ to: pathToRedirectFromRoot })} />
        </Route>
      </Switch>
    </AppWrapper>
  );
};
