import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  pluralizeWithCount,
  pluralizeWithoutCount,
} from '../../../../../_shared/utils/stringUtils.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import {
  ArchiveAssetsAction,
  ArchiveFolderAction,
  AssignTaxonomyTermsToAssetsAction,
  MoveAssetsBackToCollectionAction,
  MoveAssetsBackToFolderAction,
  MoveAssetsToCollectionAction,
  MoveAssetsToFolderAction,
  RestoreAssetsAction,
  RestoreFolderAction,
} from '../../types/lastAssetsAction.ts';
import { getListingAssetsCount } from './getListingAssetsCount.ts';

const getAssetsWithCountMessage = (count: number, total: number) =>
  `${count} of ${pluralizeWithCount('asset', total)} deleted`;
const getAssetsInUseMessage = (count: number) =>
  `, ${pluralizeWithCount('asset', count)} ${count > 1 ? 'are' : 'is'} still in use`;

const constructArchiveMessage = (lastAction: ArchiveAssetsAction): string => {
  const numberOfSuccessfulItems = lastAction.successfulIds.size;
  const numberOfFailedItems = lastAction.failedIds.size;
  if (numberOfFailedItems === 0) {
    return `${pluralizeWithCount('asset', numberOfSuccessfulItems)} deleted.`;
  }
  const totalCount = numberOfFailedItems + numberOfSuccessfulItems;

  return `${getAssetsWithCountMessage(numberOfSuccessfulItems, totalCount)}${getAssetsInUseMessage(
    numberOfFailedItems,
  )}.`;
};

const constructArchiveFolderMessage = (lastAction: ArchiveFolderAction): string => {
  const numberOfSuccessfulItems = lastAction.successfulAssetsIds.size;
  const numberOfFailedItems = lastAction.failedAssetsIds.size;
  const numberOfUsedItems = lastAction.usedAssetsIds.size;
  const numberOfSuccessfulFolders = lastAction.successfulFoldersIds.size;
  const numberOfNotDeletedItems = numberOfFailedItems + numberOfUsedItems;
  const totalCount = numberOfNotDeletedItems + numberOfSuccessfulItems;

  if (numberOfNotDeletedItems === 0) {
    if (numberOfSuccessfulItems === 0) {
      return `${pluralizeWithCount('folder', numberOfSuccessfulFolders)} deleted.`;
    }

    return `${pluralizeWithCount(
      'folder',
      numberOfSuccessfulFolders,
    )} deleted, ${pluralizeWithCount('asset', numberOfSuccessfulItems)} deleted.`;
  }

  return `${getAssetsWithCountMessage(numberOfSuccessfulItems, totalCount)}${getAssetsInUseMessage(
    numberOfNotDeletedItems,
  )} or in collections where you can’t delete assets. Some folders haven’t been deleted.`;
};

const constructMoveMessage = (
  lastAction: MoveAssetsToFolderAction | MoveAssetsToCollectionAction,
): string => {
  const numberOfItems = lastAction.successfulIds.size;

  return `${pluralizeWithCount('asset', numberOfItems)} moved.`;
};

const constructRestoreMessage = (lastAction: RestoreAssetsAction): string => {
  const numberOfSuccessfulItems = lastAction.successfulIds.size;
  return `${pluralizeWithCount('asset', numberOfSuccessfulItems)} restored.`;
};

const constructRestoreFolderMessage = (lastAction: RestoreFolderAction): string => {
  const numberOfFolders = lastAction.successfulFoldersIds.size;
  const numberOfItems = lastAction.successfulAssetsIds.size;
  const areFoldersRestored = numberOfFolders > 0;

  if (areFoldersRestored) {
    if (numberOfItems > 0) {
      return `${pluralizeWithCount('folder', numberOfFolders)} restored, ${pluralizeWithCount(
        'asset',
        numberOfItems,
      )} restored.`;
    }

    return `${pluralizeWithCount('folder', numberOfFolders)} restored.`;
  }

  return `${pluralizeWithCount('asset', numberOfItems)} restored.`;
};

const constructMovedBackMessage = (
  lastAction: MoveAssetsBackToFolderAction | MoveAssetsBackToCollectionAction,
): string => {
  const numberOfItems = lastAction.successfulIds.size;
  return `${pluralizeWithCount('asset', numberOfItems)} moved back.`;
};

const constructAssignTermsMessage = (lastAction: AssignTaxonomyTermsToAssetsAction): string => {
  const numberOfItems = lastAction.successfulIds.size;
  const numberOfFailedItems = lastAction.failedIds.size;

  if (numberOfItems > 0 && numberOfFailedItems > 0) {
    return `Assigned terms to ${pluralizeWithCount(
      'asset',
      numberOfItems,
    )}, failed to assign terms to ${pluralizeWithCount('asset', numberOfFailedItems)}.`;
  }
  if (numberOfFailedItems > 0) {
    return `Failed to assign terms to ${pluralizeWithCount('asset', numberOfFailedItems)}.`;
  }
  return `Assigned terms to ${pluralizeWithCount('asset', numberOfItems)}.`;
};

type LocalState = {
  readonly selectedAssetsCount: number;
};

// Note that the order is super-important for message priority
export const getStatusMessageText = (
  state: IStore,
  { selectedAssetsCount }: LocalState,
): string => {
  const {
    assetLibraryApp: { lastAction },
    data,
  } = state;

  // First display progress message
  switch (lastAction.type) {
    case StatusMessageAction.Archiving:
    case StatusMessageAction.ArchivingFolder:
      return 'Deleting';
    case StatusMessageAction.Moving:
    case StatusMessageAction.MovingFolder:
      return 'Moving';
    case StatusMessageAction.MovingAssetsBackToCollection:
    case StatusMessageAction.MovingAssetsBackToFolder:
    case StatusMessageAction.MovingFolderBack:
      return 'Moving back';
    case StatusMessageAction.Restoring:
    case StatusMessageAction.RestoringFolder:
      return 'Restoring';
    case StatusMessageAction.AssigningTerms:
      return 'Assigning terms';
    default:
      break;
  }

  // Then the result of an action
  switch (lastAction.type) {
    case StatusMessageAction.ArchivedAssets:
      return constructArchiveMessage(lastAction);

    case StatusMessageAction.ArchivedFolder:
      return constructArchiveFolderMessage(lastAction);

    case StatusMessageAction.MovedAssetsToCollection:
    case StatusMessageAction.MovedAssetsToFolder:
      return constructMoveMessage(lastAction);

    case StatusMessageAction.MovedFolder:
      return 'Folder successfully moved';

    case StatusMessageAction.RestoredAssets:
      return constructRestoreMessage(lastAction);

    case StatusMessageAction.RestoredFolder:
      return constructRestoreFolderMessage(lastAction);

    case StatusMessageAction.MovedAssetsBackToCollection:
    case StatusMessageAction.MovedAssetsBackToFolder:
      return constructMovedBackMessage(lastAction);

    case StatusMessageAction.MovedFolderBack:
      return 'Folder successfully moved back';

    case StatusMessageAction.AssignedTerms:
      return constructAssignTermsMessage(lastAction);

    default: {
      if (selectedAssetsCount > 0) {
        return `${pluralizeWithCount('asset', selectedAssetsCount)} selected`;
      }

      const { listingAssets, nextContinuationToken } = data.assets;
      const loadedAssetsCount = getListingAssetsCount(listingAssets);
      const countMessage = `${loadedAssetsCount}${nextContinuationToken ? '+' : ''}`;

      return `${countMessage} ${pluralizeWithoutCount('asset', loadedAssetsCount)}`;
    }
  }
};
