import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { joinWithOxfordComma } from '../../../../../../_shared/utils/stringUtils.ts';
import { getVariantIdLanguage } from '../../../../selectors/variantLanguage.ts';

interface INonLocalizableSavingFailedNotificationContentProps {
  readonly elementName: string | null;
  readonly failedVariantIds: ReadonlyArray<Uuid>;
}

export const NonLocalizableSavingFailedNotificationContent: React.FC<
  INonLocalizableSavingFailedNotificationContentProps
> = ({ failedVariantIds, elementName }) => {
  const languagesData = useSelector((s) => s.data.languages);
  const failedVariantNames = failedVariantIds.map(
    (id) => getVariantIdLanguage(id, languagesData).name,
  );
  const selectedLanguageId = useSelector(getSelectedLanguageId) ?? '';

  const isSelectedVariantFailedToSave = failedVariantIds.includes(selectedLanguageId);
  const isAnyOtherVariantFailedToSave = failedVariantIds.some((id) => id !== selectedLanguageId);
  const elementDescription = elementName ? `${elementName} element` : 'one of the elements';

  return (
    <Box>
      We couldn’t save the item in&nbsp;
      {isSelectedVariantFailedToSave && !isAnyOtherVariantFailedToSave
        ? 'this language'
        : 'some languages'}
      &nbsp;
      {isAnyOtherVariantFailedToSave && (
        <Box display="inline-flex" style={{ verticalAlign: 'middle' }}>
          <QuinaryButton
            tooltipText={`Content in ${elementDescription} couldn’t be saved in ${joinWithOxfordComma(
              failedVariantNames,
            )}. The item’s content wasn’t changed.`}
            buttonStyle="inverse"
            tooltipPlacement="top"
          >
            <QuinaryButton.Icon icon={Icons.QuestionCircle} />
          </QuinaryButton>
          &nbsp;
        </Box>
      )}
      because content in {elementDescription} is too long. Try to shorten it and tell us why you
      need this.
    </Box>
  );
};

NonLocalizableSavingFailedNotificationContent.displayName =
  'NonLocalizableSavingFailedNotificationContent';
