export const Content_HideValidationResults = 'Content_HideValidationResults';
export const Content_ShowFriendlyWarnings = 'Content_ShowFriendlyWarnings';
export const Content_Editing_AssignmentSubmittingFailed =
  'Content_Editing_AssignmentSubmittingFailed';
export const Content_Editing_AssignmentSubmittingFinished =
  'Content_Editing_AssignmentSubmittingFinished';
export const Content_Editing_AssignmentSubmittingStarted =
  'Content_Editing_AssignmentSubmittingStarted';
export const Content_Editing_CancelScheduledPublishingFailed =
  'Content_Editing_CancelScheduledPublishingFailed';
export const Content_Editing_CancelScheduledPublishingFinished =
  'Content_Editing_CancelScheduledPublishingFinished';
export const Content_Editing_CancelScheduledPublishingStarted =
  'Content_Editing_CancelScheduledPublishingStarted';
export const Content_Editing_CancelScheduledUnpublishingFailed =
  'Content_Editing_CancelScheduledUnpublishingFailed';
export const Content_Editing_CancelScheduledUnpublishingFinished =
  'Content_Editing_CancelScheduledUnpublishingFinished';
export const Content_Editing_CancelScheduledUnpublishingStarted =
  'Content_Editing_CancelScheduledUnpublishingStarted';
export const Content_Editing_CurrentItemOverwritten = 'Content_Editing_CurrentItemOverwritten';
export const Content_Editing_DefaultVariantOverwritten =
  'Content_Editing_DefaultVariantOverwritten';
export const Content_Editing_CurrentItemOverwrittenNotificationClosed =
  'Content_Editing_CurrentItemOverwrittenNotificationClosed';
export const Content_Editing_DefaultVariantOverwrittenNotificationClosed =
  'Content_Editing_DefaultVariantOverwrittenNotificationClosed';
export const Content_Editing_DiscardNewVersionFailed = 'Content_Editing_DiscardNewVersionFailed';
export const Content_Editing_DiscardNewVersionFinished =
  'Content_Editing_DiscardNewVersionFinished';
export const Content_Editing_DiscardNewVersionStarted = 'Content_Editing_DiscardNewVersionStarted';
export const Content_Editing_ItemElementsOverwritten = 'Content_Editing_ItemElementsOverwritten';
export const Content_Editing_PublishContentItemVariantFailed =
  'Content_Editing_PublishContentItemVariantFailed';
export const Content_Editing_PublishContentItemVariantFinished =
  'Content_Editing_PublishContentItemVariantFinished';
export const Content_Editing_PublishContentItemVariantStarted =
  'Content_Editing_PublishContentItemVariantStarted';
export const Content_Editing_RestoreFromArchivedStepFinished =
  'Content_Editing_RestoreFromArchivedStepFinished';
export const Content_Editing_RestoreFromArchivedStepStarted =
  'Content_Editing_RestoreFromArchivedStepStarted';
export const Content_Editing_ScheduledPublishingFailed =
  'Content_Editing_ScheduledPublishingFailed';
export const Content_Editing_ScheduledPublishingFinished =
  'Content_Editing_ScheduledPublishingFinished';
export const Content_Editing_ScheduledPublishingStarted =
  'Content_Editing_ScheduledPublishingStarted';
export const Content_Editing_ScheduledUnpublishingFailed =
  'Content_Editing_ScheduledUnpublishingFailed';
export const Content_Editing_ScheduledUnpublishingFinished =
  'Content_Editing_ScheduledUnpublishingFinished';
export const Content_Editing_ScheduledUnpublishingStarted =
  'Content_Editing_ScheduledUnpublishingStarted';
export const Content_Editing_UnpublishContentItemVariantFailed =
  'Content_Editing_UnpublishContentItemVariantFailed';
export const Content_Editing_UnpublishContentItemVariantFinished =
  'Content_Editing_UnpublishContentItemVariantFinished';
export const Content_Editing_UnpublishContentItemVariantStarted =
  'Content_Editing_UnpublishContentItemVariantStarted';
