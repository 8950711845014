import { Ref, RefObject, useCallback, useRef } from 'react';
import { RefCallback, isRefCallback, isRefObject } from '../utils/refUtils.ts';

type UseAttachRefReturn<TInstance> = {
  readonly refObject: RefObject<TInstance>;
  readonly refToForward: RefCallback<TInstance>;
};

/**
 * Use this hook in situations where:
 * - You need to attach to received Ref.
 * - You need to work with RefObject, but you don't want to limit a ref property to be RefObject only.
 * Receives:
 * - A Ref (RefObject or RefCallback) to attach to.
 * Returns an object with properties:
 * - 'refObject' Parsed refObject that can be attached to domain logic. This ref is not the source of truth and should not be attached to an element.
 * - 'refToForward' Decorated ref from the argument converted to RefCallback. You must use this ref for further forwarding.
 */
export function useAttachRef<TInstance extends HTMLElement>(
  ref: Ref<TInstance> | undefined,
): UseAttachRefReturn<TInstance> {
  const _refObject = useRef<TInstance | null>(null);

  const _refCallbackWrapper: (instance: TInstance | null) => void = useCallback(
    (instance) => {
      _refObject.current = instance;
      propagateInstanceToParent(ref, instance);
    },
    [ref],
  );

  return {
    refObject: _refObject,
    refToForward: _refCallbackWrapper,
  };
}

export function propagateInstanceToParent<TInstance>(
  ref: Ref<TInstance> | undefined,
  instance: TInstance | null,
): void {
  if (isRefCallback(ref)) {
    ref(instance);
  }

  if (isRefObject(ref)) {
    (ref as Mutable<RefObject<TInstance>>).current = instance;
  }
}
