import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IItemElementStatusProps {
  readonly dataUiElement?: DataUiElement;
  readonly isInvalid?: boolean;
  readonly message?: string;
}

export class StatusMessage extends React.PureComponent<IItemElementStatusProps> {
  static displayName = 'StatusMessage';

  render() {
    const { dataUiElement, isInvalid, message } = this.props;

    if (!message) {
      return null;
    }

    return (
      <span
        className={classNames('content-item-element__status-item', {
          'content-item-element__status-item--is-invalid': isInvalid,
        })}
        {...(dataUiElement && getDataUiElementAttribute(dataUiElement))}
      >
        {message}
      </span>
    );
  }
}
