export const getIsoLanguageCodename = (
  languageName: string,
  languageCodename: string,
): string | null => {
  const languagesToCodenameMap = getCachedLanguagesMap();

  return (
    languagesToCodenameMap[disregardBcp47Subtags(languageCodename.toLocaleLowerCase())] ||
    languagesToCodenameMap[disregardBcp47Subtags(languageName.toLocaleLowerCase())] ||
    null
  );
};

const disregardBcp47Subtags = (language: string): string => language.split('-')[0] || '';

let languagesToCode: ReadonlyRecord<string, string> | null = null;

const getCachedLanguagesMap = (): ReadonlyRecord<string, string> => {
  languagesToCode ??= createLanguagesToCodeMap();
  return languagesToCode;
};

const createLanguagesToCodeMap = (): ReadonlyRecord<string, string> => {
  const langNames = new Intl.DisplayNames(['en'], { type: 'language' });

  return Object.fromEntries(
    allSmallChars
      .flatMap((char) => allSmallChars.map((sndChar) => char + sndChar))
      .flatMap((code) => {
        const enName = langNames.of(code);
        if (!enName || enName === code) {
          return [];
        }

        const nativeName = new Intl.DisplayNames([code], { type: 'language' }).of(code);

        return [
          [code, code] as const,
          [enName.toLocaleLowerCase(), code] as const,
          ...(nativeName ? [[nativeName?.toLocaleLowerCase(), code] as const] : []),
        ] as const;
      }),
  );
};

const allSmallChars: ReadonlyArray<string> = Array(26)
  .fill(97)
  .map((x, y) => String.fromCharCode(x + y));
