import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../@types/Action.type.ts';
import { AssetLibrary_AssetEditor_SaveFailed } from '../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import {
  ListingFilter_CreateLinkedFilter_Failed,
  ListingFilter_DeleteSavedFilter_Failed,
  ListingFilter_SaveFilter_Failed,
  ListingFilter_UpdateSavedFilter_Failed,
} from '../../../applications/contentInventory/content/features/ListingFilter/constants/listingFilterActionTypes.ts';
import {
  Relations_ExpandNode_Failed,
  Relations_InitFailed,
  Relations_SetDefaultRoot_Failed,
  Relations_SetRoot_Failed,
} from '../../../applications/contentInventory/relations/constants/relationsActionTypes.ts';
import { AssetType_Editor_SavingFailed } from '../../../applications/contentModels/assetTypes/constants/assetTypeActionTypes.ts';
import { ContentType_Editor_AnyInvalidElement_ShowWarning } from '../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';
import {
  Sitemap_Deleted,
  Sitemap_SavingFailed,
} from '../../../applications/contentModels/sitemap/constants/sitemapActionTypes.ts';
import { ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning } from '../../../applications/contentModels/snippets/constants/snippetActionTypes.ts';
import {
  Taxonomy_Group_CreationFailed,
  Taxonomy_Group_SavingFailed,
} from '../../../applications/contentModels/taxonomy/constants/taxonomyActionTypes.ts';
import { Collections_Save_Failed } from '../../../applications/environmentSettings/collections/constants/actionTypes.ts';
import {
  Role_Create_Failed,
  Role_Delete_Failed,
  Role_Update_Failed,
  Role_Validation_Failed,
  Role_Validation_FailedNoSelectedCapabilities,
} from '../../../applications/environmentSettings/roles/constants/rolesActionTypes.ts';
import {
  Spaces_Create_Failed,
  Spaces_Delete_Failed,
  Spaces_Restore_Failed,
  Spaces_Update_Failed,
} from '../../../applications/environmentSettings/spaces/constants/spacesActionTypes.ts';
import {
  Invite_SubmitInvitations_Finished,
  UserDetail_UserNotFound,
  User_ToggleActiveState_Failed,
} from '../../../applications/environmentSettings/users/constants/usersActionTypes.ts';
import {
  Workflow_Delete_Failed,
  Workflow_Editing_EmptyTransitionsSaveFailed,
  Workflow_Editing_WorkflowSaveFailed,
  Workflow_ServerValidation_ReceivedGlobalErrors,
  Workflow_ServerValidation_ReceivedStepErrors,
} from '../../../applications/environmentSettings/workflow/constants/workflowActionTypes.ts';
import { WorkflowStepValidationError } from '../../../applications/environmentSettings/workflow/model/WorkflowValidationErrors.ts';
import {
  Content_Editing_CancelScheduledPublishingFailed,
  Content_Editing_CancelScheduledUnpublishingFailed,
  Content_Editing_DiscardNewVersionFailed,
} from '../../../applications/itemEditor/constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_SchedulingFailed,
} from '../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Failed,
  ContentItemEditing_FocusValidationWarningsBeforePublish,
} from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { RichText_Paste_Failed } from '../../../applications/richText/constants/richTextActionTypes.ts';
import {
  SubscriptionUserDetail_UserNotFound,
  SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed,
} from '../../../applications/subscriptionManagement/SubscriptionUsers/constants/actionTypes.ts';
import { ServerApiErrorCode } from '../../../repositories/serverModels/ServerApiError.ts';
import { NotificationBarType } from '../../components/NotificationBar.tsx';
import {
  Shared_NotificationBar_ShowError,
  Shared_NotificationBar_ShowSuccess,
} from '../../constants/sharedActionTypes.ts';

const initialState = NotificationBarType.InfoLight;

export const type = (
  state: NotificationBarType = initialState,
  action: Action,
): NotificationBarType => {
  switch (action.type) {
    case Shared_NotificationBar_ShowSuccess:
      return NotificationBarType.Success;

    // "Item deleted" notification should be red
    case Sitemap_Deleted:
      return NotificationBarType.Error;

    case AssetType_Editor_SavingFailed:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
    case ContentItemEditing_FocusValidationWarningsBeforePublish:
    case ContentTypeSnippet_Editor_AnyInvalidElement_ShowWarning:
    case ContentType_Editor_AnyInvalidElement_ShowWarning:
    case Content_Editing_CancelScheduledPublishingFailed:
    case Content_Editing_CancelScheduledUnpublishingFailed:
    case Content_Editing_DiscardNewVersionFailed:
    case ListingFilter_CreateLinkedFilter_Failed:
    case ListingFilter_DeleteSavedFilter_Failed:
    case ListingFilter_SaveFilter_Failed:
    case ListingFilter_UpdateSavedFilter_Failed:
    case Relations_ExpandNode_Failed:
    case Relations_InitFailed:
    case Relations_SetDefaultRoot_Failed:
    case Relations_SetRoot_Failed:
    case RichText_Paste_Failed:
    case Role_Create_Failed:
    case Role_Delete_Failed:
    case Role_Update_Failed:
    case Role_Validation_Failed:
    case Role_Validation_FailedNoSelectedCapabilities:
    case Shared_NotificationBar_ShowError:
    case Sitemap_SavingFailed:
    case Taxonomy_Group_CreationFailed:
    case Taxonomy_Group_SavingFailed:
    case UserDetail_UserNotFound:
    case Workflow_Editing_EmptyTransitionsSaveFailed:
    case Workflow_Editing_WorkflowSaveFailed:
    case Workflow_Delete_Failed:
    case Collections_Save_Failed:
    case SubscriptionUserDetail_UserNotFound:
    case User_ToggleActiveState_Failed:
    case SubscriptionUser_TriggerActiveStateOnAllSubscriptionProjects_Failed:
    case Spaces_Create_Failed:
    case Spaces_Delete_Failed:
    case Spaces_Restore_Failed:
    case Spaces_Update_Failed:
      return NotificationBarType.Error;

    case Workflow_ServerValidation_ReceivedGlobalErrors: {
      if (action.payload.errors && action.payload.errors.size > 0) {
        return NotificationBarType.Error;
      }
      return state;
    }

    case Workflow_ServerValidation_ReceivedStepErrors: {
      const errorValues = Collection.getValues(action.payload.errors);
      if (
        errorValues.some(
          (list) =>
            (!!list && list.has(WorkflowStepValidationError.NoPathToPublished)) ||
            list.has(WorkflowStepValidationError.NoTransitions),
        )
      ) {
        return NotificationBarType.Error;
      }
      return state;
    }

    case Invite_SubmitInvitations_Finished: {
      const { failedInvitations } = action.payload;

      if (failedInvitations.length > 0) {
        return NotificationBarType.Error;
      }
      return state;
    }

    case AssetLibrary_AssetEditor_SaveFailed:
    case ContentItemEditing_CreateNewVersion_Failed: {
      if (
        action.payload.apiError === ServerApiErrorCode.ContentWasModified ||
        action.payload.apiError === ServerApiErrorCode.CodenameIsNotUnique
      ) {
        return NotificationBarType.Error;
      }
      return state;
    }

    default:
      return state;
  }
};
