import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_SelectSubscription } from '../../shared/constants/subscriptionManagementActionTypes.ts';
import {
  Subscription_CreateSubscription_Finished,
  Subscription_SubscriptionListing_Leave,
} from '../contants/actionTypes.ts';

export const showCreateNewSubscriptionSuccessMessage = (
  state: boolean = false,
  action: Action,
): boolean => {
  switch (action.type) {
    case Subscription_CreateSubscription_Finished:
      return true;

    case Subscription_SelectSubscription:
    case Subscription_SubscriptionListing_Leave:
      return false;

    default:
      return state;
  }
};
