import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ContentTypeSnippet_Creator_InitFinished,
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_InitFinished,
  ContentTypeSnippet_Editor_InitStarted,
} from '../../constants/snippetActionTypes.ts';

export function contentTypeSnippetEditingLoadingStatus(
  state = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case ContentTypeSnippet_Creator_InitStarted:
    case ContentTypeSnippet_Editor_InitStarted: {
      return LoadingStatus.Loading;
    }

    case ContentTypeSnippet_Creator_InitFinished:
    case ContentTypeSnippet_Editor_InitFinished: {
      return LoadingStatus.Loaded;
    }

    default:
      return state;
  }
}
