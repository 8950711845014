import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { DefaultCollectionId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { DataUiAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { ICollectionUsageServerResponseModel } from '../../../../../repositories/serverModels/CollectionsServerModel.type.ts';
import { areSpacesInCollectionsEnabled as areSpacesInCollectionsEnabledSelector } from '../../../selectors/allowedFeaturesUtils.ts';
import {
  cancelEditor,
  deleteCollection,
  editedCollectionNameChanged,
  submitEditor,
} from '../../actions/baseActions.ts';
import {
  defaultCollectionCannotBeDeleted,
  loadingCollectionUsage,
  unableToLoadCollectionUsage,
  usedCollectionCannotBeDeleted,
  usedCollectionCannotBeDeletedSpacesInCollectionsVersion,
} from '../../constants/UIConstants.tsx';
import { CollectionExpandedForm } from './CollectionExpandedForm.tsx';

interface IExpandedCollectionProps {
  readonly collectionId: Uuid;
  readonly showSpaces: boolean;
}

const getUsageDisabledMessage = (
  usage: ICollectionUsageServerResponseModel,
  areSpacesInCollectionsEnabled: boolean,
) => {
  if (
    areSpacesInCollectionsEnabled &&
    (!!usage.numberOfItems || !!usage.numberOfAssets || !!usage.numberOfSpaces)
  ) {
    return usedCollectionCannotBeDeletedSpacesInCollectionsVersion;
  }

  if (!!usage.numberOfItems || !!usage.numberOfAssets) {
    return usedCollectionCannotBeDeleted;
  }

  return undefined;
};

const useCollectionDeleteDisabledMessage = (collectionId: Uuid): string | undefined => {
  const [disabledMessage, setDisabledMessage] = useState<string | undefined>(
    loadingCollectionUsage,
  );

  const areSpacesInCollectionsEnabled = useSelector(areSpacesInCollectionsEnabledSelector);

  useEffect(() => {
    if (collectionId === DefaultCollectionId) {
      setDisabledMessage(defaultCollectionCannotBeDeleted);
      return;
    }

    setDisabledMessage(loadingCollectionUsage);

    const { cancel } = makeCancellablePromise(() =>
      repositoryCollection.collectionsRepository.getUsage(collectionId),
    )
      .then((usage) => {
        const message = getUsageDisabledMessage(usage, areSpacesInCollectionsEnabled);

        setDisabledMessage(message);
      })
      .catch(swallowCancelledPromiseError)
      .catch((e) => {
        setDisabledMessage(unableToLoadCollectionUsage);
        logError(`${__filename}: ${e}`);
      });

    return cancel;
  }, [areSpacesInCollectionsEnabled, collectionId]);

  return disabledMessage;
};

export const ExpandedCollection: React.FC<IExpandedCollectionProps> = ({
  collectionId,
  showSpaces,
}) => {
  const name = useSelector((state) => state.collectionsApp.editedCollectionName);

  const dispatch = useDispatch();

  const deleteDisabledMessage = useCollectionDeleteDisabledMessage(collectionId);
  const onDelete = () => dispatch(deleteCollection(collectionId));
  const onNameChange = (newName: string) => dispatch(editedCollectionNameChanged(newName));

  return (
    <CollectionExpandedForm
      collectionId={collectionId}
      destructiveAction={{
        text: 'Delete',
        handler: onDelete,
        dataUIActionName: DataUiAction.Delete,
        isDisabled: !!deleteDisabledMessage,
        tooltipText: deleteDisabledMessage,
        icon: 'Bin',
      }}
      name={name}
      onCancel={() => dispatch(cancelEditor())}
      onNameChange={onNameChange}
      onSubmit={(newName) => dispatch(submitEditor(collectionId, newName))}
      showSpaces={showSpaces}
    />
  );
};

ExpandedCollection.displayName = 'ExpandedCollection';
