import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { selectedContentTypeSnippetsUpdated } from '../snippetsActions.ts';

export const createUnselectContentTypeSnippetsAction =
  () =>
  (unselectedTypeIds: Immutable.Set<Uuid>): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const updatedSelectedTypeIds =
      state.contentModelsApp.snippets.listing.selectedContentTypeSnippets.subtract(
        unselectedTypeIds,
      );

    dispatch(selectedContentTypeSnippetsUpdated(updatedSelectedTypeIds));
  };
