import { memoize } from '@kontent-ai/memoization';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import { ICollectionGroupRoles } from '../../../../data/models/users/ProjectContributor.ts';
import { roleBuilderAnyCollectionOptionId } from '../constants/roleBuilder.ts';
import { getInitialUserRoles } from './getInitialUserRoles.ts';

export const getInitialInvitationCollectionGroups = memoize.maxOne(
  (roles: ReadonlyMap<Uuid, IRoleWithSettings>): ReadonlyArray<ICollectionGroupRoles> => {
    return [
      {
        collectionIds: [roleBuilderAnyCollectionOptionId],
        roles: getInitialUserRoles(roles),
      },
    ];
  },
);
