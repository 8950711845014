import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ILoadListingItemsAction } from '../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { UnableToLoadNodeErrorMessage } from '../../constants/uiConstants.ts';
import {
  WebSpotlight_ReloadNode_Failed,
  WebSpotlight_ReloadNode_Finished,
  WebSpotlight_ReloadNode_Started,
} from '../../constants/webSpotlightActionTypes.ts';
import { IExpandedNodesData } from '../../reducers/expandedNodesData.ts';
import { NodeId } from '../../types/nodeId.type.ts';

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadSubpagesIds: (contentItemIds: UuidArray) => ThunkPromise;
}

export interface INodeWithSubpagesParams {
  readonly itemId: Uuid;
  readonly newSubpagesIds: UuidArray;
  readonly nodeId: NodeId;
}

const started = (nodeId: NodeId) =>
  ({
    type: WebSpotlight_ReloadNode_Started,
    payload: {
      nodeId,
    },
  }) as const;

const finished = (nodeId: NodeId, nodeData: IExpandedNodesData) =>
  ({
    type: WebSpotlight_ReloadNode_Finished,
    payload: {
      nodeId,
      nodeData,
    },
  }) as const;

const failed = (nodeId: NodeId, errorMessage: string) =>
  ({
    type: WebSpotlight_ReloadNode_Failed,
    payload: {
      nodeId,
      errorMessage,
    },
  }) as const;

export type ReloadWebSpotlightNodeActionsType = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const reloadWebSpotlightNodeActionCreator =
  (deps: IDeps) =>
  ({ itemId, newSubpagesIds, nodeId }: INodeWithSubpagesParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch(started(nodeId));

      const itemIdsToReload = newSubpagesIds.concat(itemId);
      await Promise.all([
        dispatch(deps.loadSubpagesIds(itemIdsToReload)),
        dispatch(deps.loadListingItems(itemIdsToReload)),
      ]);

      const subpagesIds = getState().webSpotlightApp.subpagesById.get(itemId) || [];
      dispatch(
        finished(nodeId, {
          itemId,
          subpagesIds,
        }),
      );
    } catch {
      dispatch(failed(nodeId, UnableToLoadNodeErrorMessage));
    }
  };
