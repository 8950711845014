import { Action } from '../../../../../@types/Action.type.ts';
import { Calendar_ArrangementSelected } from '../../constants/calendarActionTypes.ts';
import { CalendarArrangement } from '../../models/CalendarArrangement.ts';

export const selectedArrangement = (
  state: CalendarArrangement = CalendarArrangement.Month,
  action: Action,
): CalendarArrangement => {
  switch (action.type) {
    case Calendar_ArrangementSelected: {
      return action.payload.arrangement;
    }

    default:
      return state;
  }
};
