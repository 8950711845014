import { Action } from '../../../../@types/Action.type.ts';
import { IContentItemOverwritten } from '../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_DefaultVariantOverwritten,
  Content_Editing_DefaultVariantOverwrittenNotificationClosed,
} from '../../constants/editorActionTypes.ts';
import { ContentItemEditing_Init_Ready } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: IContentItemOverwritten | null = null;

export function defaultVariantOverwritten(
  state = initialState,
  action: Action,
): IContentItemOverwritten | null {
  switch (action.type) {
    case Content_Editing_DefaultVariantOverwritten: {
      return action.payload.params;
    }

    case ContentItemEditing_Init_Ready:
    case Content_Editing_DefaultVariantOverwrittenNotificationClosed:
      return initialState;

    default:
      return state;
  }
}
