import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getEditedContentItemIdFromPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

export function getIsEditedItemInitialized(state: IStore, currentPath: string): boolean {
  const contentItemId = getEditedContentItemIdFromPath(currentPath);
  const { editedContentItemVariant, editedContentItem } = state.contentApp;

  return (
    !!editedContentItemVariant &&
    !!editedContentItem &&
    editedContentItem.id === editedContentItemVariant.id.itemId &&
    editedContentItemVariant.id.itemId === contentItemId?.itemId &&
    editedContentItemVariant.id.variantId === contentItemId.variantId
  );
}
