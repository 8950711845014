import {
  SubscriptionUserDetail_Filters_Cleared,
  SubscriptionUserDetail_Initialized,
  SubscriptionUserDetail_Left,
  SubscriptionUserDetail_NotificationBar_Hidden,
  SubscriptionUserDetail_ProjectFilter_Init,
  SubscriptionUserDetail_ProjectFilter_Updated,
  SubscriptionUserDetail_SearchPhraseFilter_Updated,
  SubscriptionUserDetail_UserNotFound,
} from '../constants/actionTypes.ts';

export const projectFilterInit = () =>
  ({
    type: SubscriptionUserDetail_ProjectFilter_Init,
  }) as const;

export const projectFilterUpdated = (projectIds: ReadonlySet<Uuid>) =>
  ({
    type: SubscriptionUserDetail_ProjectFilter_Updated,
    payload: { projectIds },
  }) as const;

export const searchPhraseFilterUpdated = (searchPhrase: string) =>
  ({
    type: SubscriptionUserDetail_SearchPhraseFilter_Updated,
    payload: { searchPhrase },
  }) as const;

export const projectFiltersCleared = () =>
  ({
    type: SubscriptionUserDetail_Filters_Cleared,
  }) as const;

export const userNotFound = () =>
  ({
    type: SubscriptionUserDetail_UserNotFound,
  }) as const;

export const userDetailInitialized = () =>
  ({
    type: SubscriptionUserDetail_Initialized,
  }) as const;

export const hideNotificationBarInUserDetail = () =>
  ({
    type: SubscriptionUserDetail_NotificationBar_Hidden,
  }) as const;

export const userDetailLeft = () =>
  ({
    type: SubscriptionUserDetail_Left,
  }) as const;
