import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { loadSpaces } from '../../../data/actions/thunkDataActions.ts';
import { createLoadContentTypesAction } from '../../../data/actions/thunks/models/loadContentTypes.ts';
import { initializePreviewConfigurationActionCreator } from './thunks/initializePreviewConfiguration.ts';
import { createLoadPreviewConfigurationAction } from './thunks/loadPreviewConfiguration.ts';
import { updatePreviewConfigurationActionCreator } from './thunks/updatePreviewConfiguration.ts';
import { createUpdatePreviewConfigurationFromToolbarAction } from './thunks/updatePreviewConfigurationFromToolbar.ts';
import { createValidatePreviewConfigurationAction } from './thunks/validatePreviewConfiguration.ts';

const { contentTypeRepository, previewConfigurationRepository } = repositoryCollection;

const loadContentTypes = createLoadContentTypesAction({ contentTypeRepository });
const loadPreviewConfiguration = createLoadPreviewConfigurationAction({
  previewConfigurationRepository,
});

export const validatePreviewConfiguration = createValidatePreviewConfigurationAction();

export const initializePreviewConfiguration = initializePreviewConfigurationActionCreator({
  loadContentTypes,
  loadPreviewConfiguration,
  loadSpaces,
  validatePreviewConfiguration,
});

export const updatePreviewConfiguration = updatePreviewConfigurationActionCreator({
  previewConfigurationRepository,
});

export const updatePreviewConfigurationFromToolbar =
  createUpdatePreviewConfigurationFromToolbarAction({
    updatePreviewConfiguration,
    validatePreviewConfiguration,
  });
