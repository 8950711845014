import { DefaultWorkflowId } from '../../../_shared/constants/variantIdValues.ts';
import {
  EntityWebhookAction,
  EntityWebhookDeliverySlot,
  IEntityWebhookSetting,
  IWorkflowStatusReference,
} from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { anyCollectionCollection } from '../constants/anyCollectionCollection.ts';
import { anyContentTypeContentType } from '../constants/anyContentTypeContentType.ts';
import { anyLanguageLanguage } from '../constants/anyLanguageLanguage.ts';
import { anyTaxonomyTaxonomy } from '../constants/anyTaxonomyTaxonomy.ts';
import { anyWorkflowStepWorkflowStep } from '../constants/anyWorkflowStepWorkflowStep.ts';
import { IEntityWebhookFormShape } from '../models/IEntityWebhookFormShape.type.ts';

export const createEntityWebhookSettingFromFormValues = (
  formValues: IEntityWebhookFormShape,
  originalWebhookSetting: IEntityWebhookSetting,
): IEntityWebhookSetting => {
  return {
    id: originalWebhookSetting.id,
    url: formValues.url || '',
    name: formValues.name,
    enabled: originalWebhookSetting.enabled,
    secret: formValues.secret || '',
    headers: Array.from(formValues.headers),
    healthStatus: originalWebhookSetting.healthStatus,
    triggers: {
      assetTrigger: {
        enabled: formValues.triggers.assetTrigger.checked,
        actions: Array.from(formValues.triggers.assetTrigger.actions),
      },
      contentItemTrigger: {
        enabled: formValues.triggers.contentItemTrigger.checked,
        actions: getContentItemActionsForSlot(
          formValues.triggers.contentItemTrigger.actions,
          formValues.triggers.slot,
        ),
        collectionFilters: Array.from(
          formValues.triggers.contentItemTrigger.collectionIds?.filter(
            (c) => c !== anyCollectionCollection.id,
          ) ?? [],
        ),
        contentTypeFilters: Array.from(
          formValues.triggers.contentItemTrigger.contentTypeIds?.filter(
            (ct) => ct !== anyContentTypeContentType.id,
          ) ?? [],
        ),
        languageFilters: Array.from(
          formValues.triggers.contentItemTrigger.languageIds?.filter(
            (l) => l !== anyLanguageLanguage.id,
          ) ?? [],
        ),
        workflowSteps: getWorkflowStatusReferences(
          formValues.triggers.contentItemTrigger.workflowSteps,
        ),
      },
      contentTypeTrigger: {
        enabled: formValues.triggers.contentTypeTrigger.checked,
        actions: Array.from(formValues.triggers.contentTypeTrigger.actions),
        contentTypeFilters: Array.from(
          formValues.triggers.contentTypeTrigger.contentTypeIds?.filter(
            (ct) => ct !== anyContentTypeContentType.id,
          ) ?? [],
        ),
      },
      languageTrigger: {
        enabled: formValues.triggers.languageTrigger.checked,
        actions: Array.from(formValues.triggers.languageTrigger.actions),
        languageFilters: Array.from(
          formValues.triggers.languageTrigger.languageIds?.filter(
            (l) => l !== anyLanguageLanguage.id,
          ) ?? [],
        ),
      },
      taxonomyTrigger: {
        enabled: formValues.triggers.taxonomyTrigger.checked,
        actions: Array.from(formValues.triggers.taxonomyTrigger.actions),
        taxonomyFilters: Array.from(
          formValues.triggers.taxonomyTrigger.taxonomyIds?.filter(
            (t) => t !== anyTaxonomyTaxonomy.id,
          ) ?? [],
        ),
      },
      events: formValues.triggers.events,
      slot: formValues.triggers.slot,
    },
  };
};

function getContentItemActionsForSlot(
  actions: ReadonlyArray<EntityWebhookAction>,
  slot: EntityWebhookDeliverySlot,
): EntityWebhookAction[] {
  return slot === EntityWebhookDeliverySlot.Preview
    ? actions.filter((a) =>
        [
          EntityWebhookAction.MetadataChanged,
          EntityWebhookAction.Created,
          EntityWebhookAction.Changed,
          EntityWebhookAction.Deleted,
          EntityWebhookAction.WorkflowStepChanged,
        ].includes(a),
      )
    : actions.filter((a) =>
        [
          EntityWebhookAction.MetadataChanged,
          EntityWebhookAction.Published,
          EntityWebhookAction.Unpublished,
        ].includes(a),
      );
}

function getWorkflowStatusReferences(steps: ReadonlyArray<string>): IWorkflowStatusReference[] {
  return (steps?.filter((ws) => ws !== anyWorkflowStepWorkflowStep.id) ?? []).flatMap((ws) => {
    const parts = ws.split('/');

    if (parts.length === 2 && parts[0] !== undefined && parts[1] !== undefined) {
      return [
        {
          workflowId: parts[0],
          stepId: parts[1],
        },
      ];
    }

    if (parts.length === 1 && parts[0] !== undefined) {
      return [
        {
          workflowId: DefaultWorkflowId,
          stepId: parts[0],
        },
      ];
    }

    return [];
  });
}
