import React from 'react';
import { Guidelines } from '../../../../../_shared/containers/Guidelines.tsx';
import { IGuidelinesTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';

type ExpandedGuidelinesElementProps = {
  readonly typeElement: IGuidelinesTypeElement;
};

export const ExpandedGuidelinesElement: React.FC<ExpandedGuidelinesElementProps> = ({
  typeElement,
}) => (
  <ExpandedItemElement disabled typeElement={typeElement} hideValidationStatus>
    <Guidelines typeElement={typeElement} />
  </ExpandedItemElement>
);

ExpandedGuidelinesElement.displayName = 'ExpandedGuidelinesElement';
