import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';

export const areItemsEqual = (
  originalItems: ReadonlyArray<IBaseSelectItem> | undefined,
  newItems: ReadonlyArray<IBaseSelectItem> | undefined,
): boolean => {
  if (originalItems === undefined && newItems === undefined) {
    return true;
  }

  if (originalItems === undefined || newItems === undefined) {
    return false;
  }

  return (
    originalItems.length === newItems.length &&
    originalItems.every((term, index) => isSameItem(term, newItems[index]))
  );
};

const isSameItem = (originalItem: IBaseSelectItem, newItem: IBaseSelectItem | undefined): boolean =>
  !!newItem && originalItem.id === newItem.id && areItemsEqual(originalItem.items, newItem.items);
