import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  DialogPosition,
  ToggleableWebhookMessageDialog,
} from './ToggleableWebhookMessageDialog.tsx';
import { WebhookMessageDialogTrigger } from './WebhookMessageDialogTrigger.tsx';

interface IWebhookMessageLastResponseButtonProps {
  readonly content: string | null;
}

export const WebhookMessageLastResponseButton: React.FC<IWebhookMessageLastResponseButtonProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  return (
    <ToggleableWebhookMessageDialog
      content={content}
      dataUiAction={DataUiAction.ShowWebhookMessageResponse}
      dialogPosition={DialogPosition.Right}
      labelText="Last failure response"
      trigger={(triggerProps) => (
        <WebhookMessageDialogTrigger {...triggerProps} onClick={noOperation} />
      )}
    />
  );
};
