import Immutable from 'immutable';
import {
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  GeneralEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { IBreadcrumbResolverInfo } from '../../../../_shared/utils/breadcrumbs/breadcrumbResolvers.ts';
import { buildPath, matchPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { InnovationLabApplicationName } from './uiConstants.ts';

export const innovationLabRouteSegment = 'innovation-lab';
export const InnovationLabSettingsRoute = `${EnvironmentSettingsRoute}/${innovationLabRouteSegment}`;
export const SubscriptionInnovationLabSettingsRoute = `${SubscriptionEnvironmentSettingsRoute}/${innovationLabRouteSegment}`;
export const ObsoleteEarlyAccessProgramSettingsRoute = `${GeneralEnvironmentSettingsRoute}/early-access-program`;

export const InnovationLabBreadcrumbResolverInfo: IBreadcrumbResolverInfo = {
  pattern: new RegExp(`^${innovationLabRouteSegment}$`),
  resolver: (location) => {
    const match =
      matchPath<EnvironmentRouteParams>(location.pathname, InnovationLabSettingsRoute) ?? {};
    const breadcrumb = {
      title: InnovationLabApplicationName,
      path: buildPath(InnovationLabSettingsRoute, match),
      useProjectIdPrefix: false,
    };
    const breadcrumbs = Immutable.List([breadcrumb]);

    return Promise.resolve(breadcrumbs);
  },
  childResolvers: [],
};
