const parseStringToBoolean = (value: string): boolean => {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    default:
      return false;
  }
};

export const parseBooleanFromValue = (value: string | null): boolean => {
  switch (typeof value) {
    case 'boolean':
    case 'bigint':
    case 'number':
      return !!value;
    case 'string':
      return parseStringToBoolean(value);
    default:
      return false;
  }
};
