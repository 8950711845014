import { memoize } from '@kontent-ai/memoization';
import { CapabilityScope } from '../../../applications/environmentSettings/roles/models/CapabilityScope.ts';
import { CannotCreateNewVariantForItemError } from '../../../applications/itemEditor/constants/errorMessages.ts';
import { IAssignment } from '../../../applications/itemEditor/models/contentItem/Assignment.ts';
import {
  IActiveCapabilities,
  IActiveCapabilitiesForVariant,
} from '../../../data/models/activeCapabilities.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  IRoleSettings,
  IRule,
  IRuleWithScope,
  ITypeRuleSetting,
} from '../../../data/models/roles/IRoleSettings.ts';
import { IRoleWithSettings } from '../../../data/models/roles/IRoleWithSettings.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { UserAssignment } from '../../models/UserAssignment.ts';
import { IUserIdentifier } from '../../models/UserIdentifier.ts';
import { ActiveCapabilityType } from '../../models/activeCapability.type.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { Capability } from './capability.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from './getContributorRole.ts';

const isApplicableCapability = (capability: Capability) => (rule?: IRule) =>
  !!rule && rule.capabilities.includes(capability);

const isApplicableScope = (isUserAssigned: boolean) => (rule?: IRuleWithScope) =>
  !!rule &&
  (rule.scope === CapabilityScope.AllContent ||
    (rule.scope === CapabilityScope.AssignedContent && isUserAssigned));

const isApplicableType =
  (typeId: Uuid, additionalPredicate: (t: ITypeRuleSetting) => boolean) => (rule?: IRule) =>
    !!rule &&
    (rule.types === null ||
      rule.types.length === 0 ||
      rule.types.some((t) => t.typeId === typeId && additionalPredicate(t)));

const isWholeTypeSelected = (t: ITypeRuleSetting): boolean => t.contentGroupIds.isEmpty();
const groupsDontMatter = (): boolean => true;
const createIsGroupSelected = (groupId: Uuid) => (t: ITypeRuleSetting) =>
  t.contentGroupIds.isEmpty() || t.contentGroupIds.contains(groupId);

export const isAllowedTypeForCapability = memoize.maxOne(
  (
    settings: IRoleSettings | null | undefined,
    capability: Capability,
    assignment: UserAssignment = UserAssignment.Any,
  ): ((typeId: Uuid) => boolean) => {
    if (!settings) {
      return () => false;
    }

    const isAllowedByCanRules = (typeId: Uuid) =>
      settings.canRules
        .filter(isApplicableCapability(capability))
        .filter(
          (rule) =>
            assignment === UserAssignment.Any ||
            isApplicableScope(assignment === UserAssignment.IsAssigned)(rule),
        )
        .some(isApplicableType(typeId, groupsDontMatter));

    const isForbiddenByCannotRules = (typeId: Uuid) =>
      settings.cannotRules
        .filter(isApplicableCapability(capability))
        .some(isApplicableType(typeId, isWholeTypeSelected));

    const isTypeAllowed = (typeId: Uuid) =>
      isAllowedByCanRules(typeId) && !isForbiddenByCannotRules(typeId);

    return isTypeAllowed;
  },
);

export const canCreateItemVariant = (
  typeId: Uuid,
  collectionId: Uuid,
  languageId: Uuid,
  state: IStore,
): boolean => {
  const userState = state.data.user;
  const projectId = getCurrentProjectId(state);

  return canCreateItemVariantForUser(typeId, collectionId, languageId, userState, projectId);
};

export const canCreateItemVariantForUser = (
  typeId: Uuid,
  collectionId: Uuid,
  languageId: Uuid,
  userState: IUser,
  projectId: Uuid,
): boolean =>
  isAllowedTypeForCapability(
    getCurrentUserRoleForCollectionForLanguageForUser(
      userState,
      projectId,
      collectionId,
      languageId,
    )?.settings,
    Capability.CreateContent,
  )(typeId);

export const canMoveItemVariant = (
  typeId: Uuid,
  collectionId: Uuid,
  languageId: Uuid,
  assignment: IAssignment,
  state: IStore,
): boolean => {
  const userState = state.data.user;
  const projectId = getCurrentProjectId(state);
  const userAssignment = getUserAssignment(assignment, userState);

  return canMoveItemVariantForUser(
    typeId,
    collectionId,
    languageId,
    userAssignment,
    userState,
    projectId,
  );
};

const getUserAssignment = (assignment: IAssignment, user: IUser): UserAssignment =>
  Array.from(assignment.assignees).some(
    (assignee: IUserIdentifier) => assignee.userId === user.info.userId,
  )
    ? UserAssignment.IsAssigned
    : UserAssignment.NotAssigned;

const canMoveItemVariantForUser = (
  typeId: Uuid,
  collectionId: Uuid,
  languageId: Uuid,
  userAssignment: UserAssignment,
  userState: IUser,
  projectId: Uuid,
): boolean => {
  const applicableRole = getCurrentUserRoleForCollectionForLanguageForUser(
    userState,
    projectId,
    collectionId,
    languageId,
  );

  return isAllowedTypeForCapability(
    applicableRole?.settings,
    Capability.UpdateContent,
    userAssignment,
  )(typeId);
};

export const getCannotCreateMessageForItem = (item: IListingContentItem): string | undefined => {
  const isTranslated = !!item.variant && !item.variant.isArchived;
  const canCreate = hasActiveVariantCapability(ActiveCapabilityType.CreateContent, item);

  return isTranslated || canCreate ? undefined : CannotCreateNewVariantForItemError;
};

export type HasVariantActiveCapabilityParams = {
  readonly activeCapabilities: IActiveCapabilitiesForVariant;
};

export type HasActiveCapabilityParams = {
  readonly activeCapabilities: IActiveCapabilities;
};

export const hasActiveVariantCapability = (
  activeCapability: ActiveCapabilityType,
  withVariantActiveCapabilities: HasVariantActiveCapabilityParams | null | undefined,
): boolean =>
  withVariantActiveCapabilities?.activeCapabilities.variantCapabilities.includes(
    activeCapability,
  ) || false;

export const hasActiveVariantCapabilityForEditedItem = (
  activeCapability: ActiveCapabilityType,
  state: IStore,
): boolean => hasActiveVariantCapability(activeCapability, state.contentApp.editorUi);

export const canDuplicateItem = (state: IStore, languageId: Uuid, typeId: Uuid): boolean => {
  const projectId = getCurrentProjectId(state);
  const {
    data: {
      collections: { byId },
      user,
    },
  } = state;

  return [...byId.values()].some((collection) =>
    isAllowedTypeForCapability(
      getCurrentUserRoleForCollectionForLanguageForUser(user, projectId, collection.id, languageId)
        ?.settings,
      Capability.CreateContent,
    )(typeId),
  );
};

// elements do not have to be part of any group
export const canViewContentGroup = (
  groupId: Uuid | null | undefined,
  withActiveCapabilities: HasActiveCapabilityParams,
): boolean =>
  groupId
    ? withActiveCapabilities.activeCapabilities.contentGroupsForViewCapability.includes(groupId)
    : hasActiveVariantCapability(ActiveCapabilityType.ViewContent, withActiveCapabilities);

// elements do not have to be part of any group
export const canUpdateContentGroup = (
  groupId: Uuid | null | undefined,
  withActiveCapabilities: HasActiveCapabilityParams,
): boolean =>
  groupId
    ? withActiveCapabilities.activeCapabilities.contentGroupsForUpdateCapability.includes(groupId)
    : hasActiveVariantCapability(ActiveCapabilityType.UpdateContent, withActiveCapabilities);

export const doesRoleAllowViewContentGroup = (
  groupId: Uuid | null | undefined,
  typeId: Uuid,
  role: IRoleWithSettings | null | undefined,
  isUserAssigned: boolean,
): boolean => {
  if (!role) {
    return false;
  }

  const isApplicableCanRule = role.settings.canRules
    .filter(isApplicableCapability(Capability.ViewContent))
    .filter(isApplicableScope(isUserAssigned))
    .some(isApplicableType(typeId, groupId ? createIsGroupSelected(groupId) : groupsDontMatter));

  const isApplicableCannotRule = role.settings.cannotRules
    .filter(isApplicableCapability(Capability.ViewContent))
    .some(isApplicableType(typeId, groupId ? createIsGroupSelected(groupId) : isWholeTypeSelected));

  return isApplicableCanRule && !isApplicableCannotRule;
};

export const canChangeWorkflowForEditedItem = (state: IStore) =>
  hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateAssignment, state);
