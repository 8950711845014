export const LocalizationEditor_DefaultLangEditor_Hide =
  'LocalizationEditor_DefaultLangEditor_Hide';
export const LocalizationEditor_DefaultLangEditor_Saved =
  'LocalizationEditor_DefaultLangEditor_Saved';
export const LocalizationEditor_DefaultLangEditor_Show =
  'LocalizationEditor_DefaultLangEditor_Show';
export const LocalizationEditor_Init_Finished = 'LocalizationEditor_Init_Finished';
export const LocalizationEditor_Init_Started = 'LocalizationEditor_Init_Started';
export const LocalizationEditor_LangCreator_Hide = 'LocalizationEditor_LangCreator_Hide';
export const LocalizationEditor_LangCreator_Show = 'LocalizationEditor_LangCreator_Show';
export const LocalizationEditor_LangEditor_Hide = 'LocalizationEditor_LangEditor_Hide';
export const LocalizationEditor_LangEditor_Show = 'LocalizationEditor_LangEditor_Show';
export const LocalizationEditor_Language_Activate = 'LocalizationEditor_Language_Activate';
export const LocalizationEditor_Language_Add = 'LocalizationEditor_Language_Add';
export const LocalizationEditor_Language_Deactivate = 'LocalizationEditor_Language_Deactivate';
export const LocalizationEditor_Language_DraggingStarted =
  'LocalizationEditor_Language_DraggingStarted';
export const LocalizationEditor_Language_Update = 'LocalizationEditor_Language_Update';
export const LocalizationEditor_LanguageBar_Move = 'LocalizationEditor_LanguageBar_Move';
export const LocalizationEditor_LanguagesLoading_Success =
  'LocalizationEditor_LanguagesLoading_Success';
export const LocalizationEditor_LoadItemVariantCount_Finished =
  'LocalizationEditor_LoadItemVariantCount_Finished';
export const LocalizationEditor_Saving_Failed = 'LocalizationEditor_Saving_Failed';
export const LocalizationEditor_Saving_Started = 'LocalizationEditor_Saving_Started';
export const LocalizationEditor_Saving_Success = 'LocalizationEditor_Saving_Success';
