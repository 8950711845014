import { Collection } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { IPlan } from '../../../data/models/plans/Plan.ts';
import { ISubscription } from '../../../data/models/subscriptions/Subscription.ts';
import { MainLayoutGrid } from '../MainLayoutGrid.tsx';
import { SubscriptionWarning } from './SubscriptionWarning.tsx';
import { BillingInformationAction } from './actions/BillingInformationAction.tsx';
import { ExpiredSubscriptionWarningAction } from './actions/ExpiredSubscriptionWarningAction.tsx';
import { SuspendedSubscriptionWarningAction } from './actions/SuspendedSubscriptionWarningAction.tsx';

export enum HeaderNotifications {
  CanceledSubscriptionNotification = 'CanceledSubscriptionNotification',
  ExpiredSubscriptionNotification = 'ExpiredSubscriptionNotification',
  SuspendedSubscriptionNotification = 'SuspendedSubscriptionNotification',
  LimitsExceededNotification = 'LimitsExceededNotification',
}

interface IHeaderNotificationBarProps {
  readonly currentUserHasMoreSubscriptions: boolean;
  readonly isSubscriptionAdmin: boolean;
  readonly subscription: ISubscription;
  readonly subscriptionPlan: IPlan;
  readonly visibleNotifications: ReadonlySet<HeaderNotifications>;
}

export const HeaderNotificationBar: React.FC<IHeaderNotificationBarProps> = ({
  currentUserHasMoreSubscriptions,
  isSubscriptionAdmin,
  subscription,
  subscriptionPlan,
  visibleNotifications,
}) => {
  const { subscriptionId, statusReason } = subscription;

  const [hiddenNotifications, setHiddenNotifications] = useState<ReadonlySet<HeaderNotifications>>(
    new Set(),
  );

  const isNotificationVisible = (notification: HeaderNotifications): boolean =>
    visibleNotifications.has(notification) && !hiddenNotifications.has(notification);

  const closeNotification = (type: HeaderNotifications): void => {
    setHiddenNotifications((prevNotifications) => Collection.remove(prevNotifications, type));
  };

  return (
    <MainLayoutGrid.WarningBar>
      {isNotificationVisible(HeaderNotifications.CanceledSubscriptionNotification) && (
        <SubscriptionWarning
          description={`This subscription has been canceled. To keep using Kontent.ai, ${
            currentUserHasMoreSubscriptions ? 'switch to another subscription or ' : ''
          }create a new subscription.`}
          isSubscriptionAdmin={isSubscriptionAdmin}
          onClose={() => closeNotification(HeaderNotifications.CanceledSubscriptionNotification)}
        />
      )}
      {isNotificationVisible(HeaderNotifications.ExpiredSubscriptionNotification) && (
        <SubscriptionWarning
          action={<ExpiredSubscriptionWarningAction subscriptionId={subscriptionId} />}
          description={`This subscription has expired. To keep using Kontent.ai, ${
            isSubscriptionAdmin ? 'select a subscription plan' : 'contact a subscription admin'
          }.`}
          isSubscriptionAdmin={isSubscriptionAdmin}
          onClose={() => closeNotification(HeaderNotifications.ExpiredSubscriptionNotification)}
        />
      )}
      {isNotificationVisible(HeaderNotifications.SuspendedSubscriptionNotification) && (
        <SubscriptionWarning
          action={
            <SuspendedSubscriptionWarningAction
              isPlanPrepaid={subscriptionPlan.isPrepaid}
              subscriptionId={subscriptionId}
              subscriptionStatusReason={statusReason}
            />
          }
          description={`This subscription has been suspended.${
            isSubscriptionAdmin ? '' : ' Contact a subscription admin to renew the subscription.'
          }`}
          isSubscriptionAdmin={isSubscriptionAdmin}
          onClose={() => closeNotification(HeaderNotifications.SuspendedSubscriptionNotification)}
        />
      )}
      {isNotificationVisible(HeaderNotifications.LimitsExceededNotification) && (
        <SubscriptionWarning
          action={<BillingInformationAction subscriptionId={subscriptionId} />}
          description={`The subscription “${subscription.subscriptionName}” exceeds its limits`}
          isSubscriptionAdmin={isSubscriptionAdmin}
          onClose={() => closeNotification(HeaderNotifications.LimitsExceededNotification)}
        />
      )}
    </MainLayoutGrid.WarningBar>
  );
};

HeaderNotificationBar.displayName = 'HeaderNotificationBar';
