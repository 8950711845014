import { createGuid } from '@kontent-ai/utils';
import { ContentState, convertFromRaw } from 'draft-js';
import {
  IContentComponentDomainModelConversionDependencies,
  contentComponentConversionToDomainModel,
} from '../../../../itemEditor/features/ContentComponent/utils/contentComponentConversionUtils.ts';
import { IContentComponent } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { IContentComponentServerModel } from '../../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import { getItemElementDomainModelConverterForImport } from '../../../../itemEditor/utils/getItemElementDataConverters.ts';
import { IStoreContentComponent } from '../../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import { ObjectDataType } from '../../../utils/export/html/elements/objects.ts';
import { IImportedRawState, convertClipboardToRawState } from './convertClipboardToRawState.ts';
import { IRawStateBuilderDependencies } from './rawStateBuilder/RawStateBuilder.ts';

export function importFromPastedTextRaw(
  pastedString: string,
  isPlainText: boolean,
  currentProjectId: Uuid,
  dependencies: IRawStateBuilderDependencies,
): IImportedRawState {
  return convertClipboardToRawState(pastedString, isPlainText, currentProjectId, dependencies);
}

export interface IPastedEditorContent {
  readonly content: ContentState;
  readonly pastedMetadata: ReadonlyMap<ObjectDataType, Uuid>;
}

export interface IPastedContent extends IPastedEditorContent {
  readonly contentComponents: ReadonlyMap<Uuid, IContentComponent>;
}

export type IImportFromPastedText = (
  pastedString: string,
  isPlainText: boolean,
  projectId: Uuid,
) => IPastedContent;

export const importFromPastedText: IImportFromPastedText = (
  pastedString,
  isPlainText,
  projectId,
): IPastedContent => {
  const contentComponents = new Map<Uuid, IContentComponent>();
  const localStoreContentComponent: IStoreContentComponent = (contentComponent) => {
    // Pasted content component has a new ID to prevent collision with an existing component ID
    const contentComponentId = createGuid();
    const withNewId = {
      ...contentComponent,
      id: contentComponentId,
    };
    contentComponents.set(contentComponentId, withNewId);
    return withNewId;
  };

  const storeContentComponent = (
    contentComponentServerModel: IContentComponentServerModel,
  ): Uuid => {
    const dependencies: IContentComponentDomainModelConversionDependencies = {
      getItemElementDomainModelConverter: getItemElementDomainModelConverterForImport,
      dataDependencies: {
        storeContentComponent: localStoreContentComponent,
      },
    };
    const contentComponent = contentComponentConversionToDomainModel(
      contentComponentServerModel,
      dependencies,
    );
    return localStoreContentComponent(contentComponent).id;
  };

  const rawState = importFromPastedTextRaw(pastedString, isPlainText, projectId, {
    storeContentComponent,
  });
  const content = convertFromRaw(rawState);

  return {
    content,
    pastedMetadata: rawState.importedMetadata,
    contentComponents,
  };
};
