import React, { memo } from 'react';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { areItemLinkTypesEnabled } from '../../../../../../../../_shared/selectors/itemLinkTypes.ts';
import { isAssetLimited } from '../../../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { EditorLimitations } from '../../../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  BlockFeatureNameMap,
  TableBlockCategoryFeature,
  TableBlockFeatureNameMap,
  TopLevelBlockCategoryFeature,
  getAllDisallowedTableFeatures,
  getAllDisallowedTopLevelFeatures,
  getDisallowedTableTextBlocksAndFormattingFeatures,
  getDisallowedTopLevelTextBlocksAndFormattingFeatures,
} from '../../../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { IRichTextWarningResult } from '../../../../../../utils/itemElementWarningCheckers/types/IRichTextItemElementWarningResult.type.ts';
import {
  getAllowedItemLinkTypesMessage,
  getAllowedTypes,
  getAllowedTypesMessage,
} from '../../../../utils/modularContentUtils.ts';
import { AssetLimitStatusMessage } from './AssetLimitStatusMessage.tsx';
import { ContentTypeLimitStatusMessage } from './ContentTypeLimitStatusMessage.tsx';
import { RichTextBlockLimitStatusMessage } from './RichTextBlockLimitStatusMessage.tsx';
import { RichTextFormatLimitStatusMessage } from './RichTextFormatLimitStatusMessage.tsx';

interface IRichTextBlockLimitStatusPane {
  readonly limitations: EditorLimitations;
  readonly validationResult?: IRichTextWarningResult | null;
}

const RichTextBlockLimitStatusPane: React.FC<IRichTextBlockLimitStatusPane> = (
  props: IRichTextBlockLimitStatusPane,
): JSX.Element | null => {
  const contentTypes = useSelector((state) => state.data.contentTypes);

  const { validationResult, limitations } = props;
  const violatedTopLevelFeatures = validationResult?.violatedFeatures.violatedTopLevelFeatures;
  const violatedTableFeatures = validationResult?.violatedFeatures.violatedTableFeatures;

  const textBlockAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const tableBlockAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
  const imageBlockAllowed = limitations.allowedBlocks.has(TopLevelBlockCategoryFeature.Images);
  const componentBlockAllowed = limitations.allowedBlocks.has(
    TopLevelBlockCategoryFeature.ComponentsAndItems,
  );

  const itemLinksEnabled = areItemLinkTypesEnabled(
    limitations.allowedBlocks,
    limitations.allowedTextFormatting,
    limitations.allowedTableTextFormatting,
  );

  const imageTableBlockAllowed = limitations.allowedTableBlocks.has(
    TableBlockCategoryFeature.Images,
  );

  const allowedContentTypes = getAllowedTypes(limitations.allowedTypes, contentTypes.byId, true);
  const allowedItemLinkTypes = getAllowedTypes(
    limitations.allowedItemLinkTypes,
    contentTypes.byId,
    true,
  );

  const disallowedTopLevelFeatures = getAllDisallowedTopLevelFeatures(limitations);
  const disallowedTableFeatures = getAllDisallowedTableFeatures(limitations);

  const disallowedTopLevelTextBlocksAndFormattingFeatures =
    getDisallowedTopLevelTextBlocksAndFormattingFeatures(limitations);
  const disallowedTableTextBlocksAndFormattingFeatures =
    getDisallowedTableTextBlocksAndFormattingFeatures(limitations);

  const featureLimited = !!disallowedTopLevelFeatures.size || !!disallowedTableFeatures.size;
  const assetLimited = isAssetLimited(limitations);
  const typeLimited = !allowedContentTypes.isEmpty();
  const itemLinkTypesLimited = !allowedItemLinkTypes.isEmpty();

  const isEditorLimited = featureLimited || assetLimited || typeLimited || itemLinkTypesLimited;

  if (!isEditorLimited) {
    return null;
  }

  return (
    <div className="content-item-element__status-pane">
      <span>Element parameters: </span>
      <RichTextBlockLimitStatusMessage
        blockName={BlockFeatureNameMap[TopLevelBlockCategoryFeature.Text]}
        isAllowed={textBlockAllowed}
        isValid={!violatedTopLevelFeatures?.has(TopLevelBlockCategoryFeature.Text)}
      >
        <RichTextFormatLimitStatusMessage
          disallowedLimitations={disallowedTopLevelTextBlocksAndFormattingFeatures}
          failedLimitations={violatedTopLevelFeatures}
        />
      </RichTextBlockLimitStatusMessage>
      <RichTextBlockLimitStatusMessage
        blockName={BlockFeatureNameMap[TopLevelBlockCategoryFeature.Tables]}
        isAllowed={tableBlockAllowed}
        isValid={!violatedTopLevelFeatures?.has(TopLevelBlockCategoryFeature.Tables)}
      >
        <RichTextFormatLimitStatusMessage
          disallowedLimitations={disallowedTableTextBlocksAndFormattingFeatures}
          failedLimitations={validationResult?.violatedFeatures.violatedTableFeatures}
          isTableFormatting
        />
      </RichTextBlockLimitStatusMessage>
      <RichTextBlockLimitStatusMessage
        blockName={BlockFeatureNameMap[TopLevelBlockCategoryFeature.Images]}
        isAllowed={imageBlockAllowed}
        isValid={!violatedTopLevelFeatures?.has(TopLevelBlockCategoryFeature.Images)}
      >
        <AssetLimitStatusMessage
          limitations={limitations}
          validationResult={validationResult}
          prefix="Images"
        />
      </RichTextBlockLimitStatusMessage>
      <RichTextBlockLimitStatusMessage
        blockName={TableBlockFeatureNameMap[TableBlockCategoryFeature.Images]}
        isAllowed={imageTableBlockAllowed}
        isValid={!violatedTableFeatures?.has(TableBlockCategoryFeature.Images)}
      />
      <RichTextBlockLimitStatusMessage
        blockName={BlockFeatureNameMap[TopLevelBlockCategoryFeature.ComponentsAndItems]}
        isAllowed={componentBlockAllowed}
        isValid={!violatedTopLevelFeatures?.has(TopLevelBlockCategoryFeature.ComponentsAndItems)}
      >
        <ContentTypeLimitStatusMessage
          allowedTypesMessage={getAllowedTypesMessage(allowedContentTypes.toArray())}
          isValid={!validationResult || validationResult.containsOnlyAllowedContentTypes}
        />
      </RichTextBlockLimitStatusMessage>
      {itemLinksEnabled && (
        <ContentTypeLimitStatusMessage
          allowedTypesMessage={getAllowedItemLinkTypesMessage(allowedItemLinkTypes.toArray())}
          isValid={!validationResult || validationResult.containsOnlyAllowedItemLinkTypes}
        />
      )}
    </div>
  );
};

RichTextBlockLimitStatusPane.displayName = 'RichTextBlockLimitStatusPane';

const RichTextBlockLimitStatusPaneMemo = memo(RichTextBlockLimitStatusPane);
export { RichTextBlockLimitStatusPaneMemo as RichTextBlockLimitStatusPane };
