import React from 'react';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { IBaseProject } from '../../models/IBaseProject.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { CopyProjectDocumentationLinks } from './CopyProjectDocumentationLinks.tsx';
import { CopyProjectDocumentationLinksExtended } from './CopyProjectDocumentationLinksExtended.tsx';
import { IncludeContentSelector } from './IncludeContentSelector.tsx';
import { ProjectLocationSelectorWrapper } from './ProjectLocationSelectorWrapper.tsx';
import { ProjectTemplateSelectorWrapper } from './ProjectTemplateSelectorWrapper.tsx';
import { SubscriptionSelectorWrapper } from './SubscriptionSelectorWrapper.tsx';

type Props = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly availableProjectLocations: ReadonlyArray<IProjectLocation>;
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly destinationSubscriptionId: Uuid;
  readonly disableSubscriptionSelector: boolean;
  readonly formProps: HookFormProps<INewProjectFormShape>;
  readonly hasTemplateErrors: boolean;
  readonly isProjectTemplateSelected: boolean;
  readonly onSubmit: (() => void) | undefined;
};

export const CreateProjectForm: React.FC<Props> = ({
  activeSubscriptions,
  availableProjectTemplates,
  destinationSubscriptionId,
  disableSubscriptionSelector,
  formProps,
  hasTemplateErrors,
  isProjectTemplateSelected,
  onSubmit,
}) => {
  return (
    <form className="form create-project-form" onSubmit={onSubmit}>
      <FormGroup>
        <ValidatedInput<INewProjectFormShape>
          name="projectName"
          label="Project name"
          maxLength={ValidationConstants.ProjectNameMaxLength}
          dataUiInputName={DataUiInput.ProjectName}
          autoFocus
          inputSuppressedInHotjar
          formProps={formProps}
        />
      </FormGroup>

      <FormGroup>
        <SubscriptionSelectorWrapper<INewProjectFormShape>
          activeSubscriptions={activeSubscriptions}
          disabled={disableSubscriptionSelector}
          formProps={formProps}
          name="subscriptionId"
        />
      </FormGroup>

      <FormGroup>
        <ProjectLocationSelectorWrapper<INewProjectFormShape>
          destinationSubscriptionId={destinationSubscriptionId}
          formProps={formProps}
          name="projectLocationId"
        />
      </FormGroup>

      <FormGroup>
        <ProjectTemplateSelectorWrapper
          availableProjectTemplates={availableProjectTemplates}
          formProps={formProps}
          hasTemplateErrors={hasTemplateErrors}
        />
      </FormGroup>
      <FormGroup isCompact>
        {isProjectTemplateSelected ? (
          <>
            <IncludeContentSelector<INewProjectFormShape>
              formProps={formProps}
              name="includeContent"
            />
            <CopyProjectDocumentationLinksExtended />
          </>
        ) : (
          <CopyProjectDocumentationLinks />
        )}
      </FormGroup>
    </form>
  );
};

CreateProjectForm.displayName = 'CreateProjectForm';
