import { ScrollElementsToViewOptions } from '@kontent-ai/DOM';
import React, { useCallback } from 'react';
import { ScrollExecutedCallback } from '../../../../../../../_shared/hooks/useAutoScroll.ts';
import { ILinkedItemsTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { ILinkedItemsElement } from '../../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { deferredScrollToLinkedItemElementTarget } from '../../../../../utils/customScrollTargetUtils.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { LinkedItemsComponent } from '../../../containers/elements/modularContent/LinkedItemsComponent.tsx';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';

export type LinkedItemsProps = IItemElementComponentOwnProps<
  ILinkedItemsElement,
  ILinkedItemsTypeElement
> & {
  readonly onValueChanged: (
    itemElement: ILinkedItemsElement,
    addedReferencesCount?: number,
  ) => void;
};

export const LinkedItems: React.FC<LinkedItemsProps> = ({
  autoFocus,
  disabled,
  elementData,
  onValueChanged,
  typeElement,
}) => {
  const onCustomScrollToTarget = useCallback(
    (
      targetId: string,
      scrollOptions: ScrollElementsToViewOptions,
      onScrollExecuted: ScrollExecutedCallback,
    ) => {
      return deferredScrollToLinkedItemElementTarget(
        targetId,
        elementData.elementId,
        scrollOptions,
        undefined,
        onScrollExecuted,
      );
    },
    [elementData],
  );

  return (
    <ItemElement
      disabled={disabled}
      onCustomScrollToTarget={onCustomScrollToTarget}
      typeElement={typeElement}
    >
      <ItemElementRefresher
        elementData={elementData}
        renderInput={(refresherItemElement: ILinkedItemsElement) => (
          <LinkedItemsComponent
            autoFocus={autoFocus}
            elementData={refresherItemElement}
            isDisabled={disabled}
            onValueChanged={onValueChanged}
            typeElement={typeElement}
            validationResultSelectorId={refresherItemElement.elementId}
          />
        )}
      />
    </ItemElement>
  );
};

LinkedItems.displayName = 'LinkedItems';
