import { getElementOffset } from '@kontent-ai/DOM';
import { memoize } from '@kontent-ai/memoization';
import { ICommentThread } from '../../models/comments/CommentThreads.ts';
import {
  EmptyThreadReferences,
  ICommentThreadWithLocation,
  getCommentedElementSelector,
  isThreadInline,
} from '../commentUtils.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

const getSimpleElementCommentThreadReferences = memoize.allForever(
  (
    element: { elementId: Uuid },
    contentComponentId: Uuid | null,
    commentThreads: ReadonlyArray<ICommentThread>,
  ): ReadonlyArray<ICommentThreadWithLocation> => {
    if (commentThreads.length === 0) {
      return EmptyThreadReferences;
    }

    const elementThreads = commentThreads
      .filter(isThreadInline)
      .filter(
        (commentThread) =>
          commentThread.elementId === element.elementId &&
          commentThread.contentComponentId === contentComponentId,
      )
      .map((commentThread) => ({
        commentThread,
        componentPath: null,
      }));

    return elementThreads;
  },
);

export function getSimpleItemElementCommentManager(): IItemElementCommentManager {
  return {
    getCommentThreadReferences: (element, threads, _loadedContentItemTypes, contentComponentId) =>
      getSimpleElementCommentThreadReferences(element, contentComponentId, threads),
    getCommentThreadOffset,
  };
}

function getCommentThreadOffset(commentThread: ICommentThread): number | null {
  return getElementOffset(getCommentedElementSelector(commentThread));
}
