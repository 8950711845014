import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionServerModel } from '../../../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import { INewSubscriptionFormShape } from '../../components/NewSubscriptionForm.tsx';
import {
  Subscription_CreateSubscription_Failed,
  Subscription_CreateSubscription_Finished,
  Subscription_CreateSubscription_Started,
} from '../../contants/actionTypes.ts';

interface ICreateNewSubscriptionDependencies {
  readonly subscriptionRepository: {
    readonly createNewSubscription: (
      subscriptionName: string,
      acceptTerms: boolean,
    ) => Promise<ISubscriptionServerModel>;
  };
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
}

const started = () => ({ type: Subscription_CreateSubscription_Started }) as const;

const failed = (error: string) =>
  ({
    type: Subscription_CreateSubscription_Failed,
    payload: { error },
  }) as const;

const finished = (subscription: ISubscriptionServerModel) =>
  ({
    type: Subscription_CreateSubscription_Finished,
    payload: { subscription },
  }) as const;

export type CreateNewSubscriptionActionsType = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const newSubscriptionCreator =
  (dependencies: ICreateNewSubscriptionDependencies) =>
  (values: INewSubscriptionFormShape): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(started());

      const subscription = await dependencies.subscriptionRepository.createNewSubscription(
        values.subscriptionName,
        values.acceptTerms,
      );
      await dispatch(dependencies.loadAdministratedSubscriptions());

      dispatch(finished(subscription));
    } catch (e) {
      dispatch(failed(`Failed to create new subscription: ${e}`));
    }
  };
