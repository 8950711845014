import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_ItemElementsOverwritten,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_Init_Ready,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState = Immutable.Set<Uuid>();

export function outdatedElementIds(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case ContentItemEditing_Init_Ready:
    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentItemEditing_CreateNewVersion_Finished: {
      return initialState;
    }

    case Content_Editing_ItemElementsOverwritten: {
      const { changedElements } = action.payload;

      return state.merge(Immutable.Set.of(...changedElements));
    }

    case ContentItemEditing_ElementRefresh_Finished: {
      return state.remove(action.payload.elementData.elementId);
    }

    default:
      return state;
  }
}
