import { isXMLHttpRequest } from '@kontent-ai/errors';
import { isArrayOf } from '@kontent-ai/utils';
import { jsonTryParse } from '../../_shared/utils/jsonUtils.ts';
import { isString } from '../../_shared/utils/stringUtils.ts';
import { isUuid } from '../../_shared/utils/validation/typeValidators.ts';

export interface IServerApiError {
  readonly error_id: string;
  readonly code: ServerApiErrorCode;
  readonly message: string;
  readonly description: string;
  readonly validation_errors?: ReadonlyArray<IApiValidationError>;
  readonly variant_ids?: ReadonlyArray<Uuid>;
}

export enum ServerApiErrorCode {
  CannotAddNewLanguage = 'CR400.3',
  CannotDeleteUsedLanguage = 'CR400.2',
  UserRegistrationWasAlreadyCompleted = 'CR400.20',
  CannotPublishIncompleteVariant = 'SL400.27',
  CannotPublishVariantWithNonLocalizableElements = 'SL400.31',
  CannotScheduleIncompleteVariant = 'SL400.28',
  CannotScheduleVariantWithNonLocalizableElements = 'SL400.32',
  CollectionsConfigurationNotValid = 'SL400.18',
  CodenameIsNotUnique = 'SL400.15',
  NonLocalizableContentTooLarge = 'SL400.8',
  ContentTooLarge = 'SL400.7',
  ContentWasModified = 'DB400.1',
  ItemHasOtherVariant = 'CR400.9',
  MissingActivePermission = 'SL403.7',
  MissingPaidFeature = 'SL403.8',
  PermissionDenied = 'SL403.1',
  UnsupportedAssetFileType = 'SL415.1',
  ValidationFailed = 'CR400.6',
  WorkflowConfigurationIsNotValid = 'SL400.3',
}

export interface IApiValidationError {
  readonly code?: ApiValidationErrorCode;
  readonly message: string;
}

export enum ApiValidationErrorCode {
  ElementTooLarge = 'CR400.6.1',
}

export const isApiValidationError = (arg: unknown): arg is IApiValidationError => {
  if (!arg || typeof arg !== 'object') return false;

  const hasMessageProperty = 'message' in arg && typeof arg.message === 'string';

  const meetsCodeProperty =
    !('code' in arg) ||
    (typeof arg.code === 'string' &&
      Object.values<string>(ApiValidationErrorCode).includes(arg.code));

  return hasMessageProperty && meetsCodeProperty;
};

export const isServerApiError = (arg: unknown): arg is IServerApiError => {
  if (!arg || typeof arg !== 'object') return false;

  const hasErrorId = 'error_id' in arg && isString(arg.error_id);

  const hasCode =
    'code' in arg &&
    typeof arg.code === 'string' &&
    Object.values<string>(ServerApiErrorCode).includes(arg.code);

  const hasMessage = 'message' in arg && isString(arg.message);

  const hasDescription = 'description' in arg && isString(arg.description);

  const meetsValidationErrors =
    !('validation_errors' in arg) || isArrayOf(arg.validation_errors, isApiValidationError);

  const meetsVariantIds = !('variant_ids' in arg) || isArrayOf(arg.variant_ids, isUuid);

  return (
    hasErrorId &&
    hasCode &&
    hasMessage &&
    hasDescription &&
    meetsValidationErrors &&
    meetsVariantIds
  );
};

export const tryParseApiError = (originalError: unknown): IServerApiError | null => {
  if (!originalError || typeof originalError !== 'object') return null;

  const error =
    ('responseText' in originalError &&
      isString(originalError.responseText) &&
      jsonTryParse(originalError.responseText)) ||
    originalError;

  return isServerApiError(error) ? error : null;
};

export const processApiError =
  <TResult>(code: ServerApiErrorCode, process: (apiError: IServerApiError) => Promise<TResult>) =>
  async (error: unknown): Promise<TResult> => {
    const apiError = tryParseApiError(error);

    if (apiError?.code === code) {
      return await process(apiError);
    }

    if (apiError) throw new Error(JSON.stringify(apiError, undefined, 2));
    if (isXMLHttpRequest(error)) {
      throw new Error(`Status: ${error.status};\nResponse text: ${error.responseText}`);
    }

    throw new Error('Unknown error.');
  };
