import { Collection } from '@kontent-ai/utils';
import { useCallback, useRef } from 'react';
import { usePendingAiAction } from './usePendingAiAction.ts';

type Cancel = () => Promise<void>;

// Use together with useAiActionWithCallback for multiple AI actions that may run simultaneously from a single component
// Note there is usePendingAiActionWithSession with smaller boilerplate available for simple scenarios
export const usePendingAiActionWithCallback = (): {
  readonly startPendingAiAction: (aiSessionId: Uuid, cancel: Cancel) => void;
  readonly finishPendingAiAction: (aiSessionId: Uuid) => void;
  readonly cancelPendingAiAction: (aiSessionId: Uuid) => void;
} => {
  const { startPendingAiAction: start, finishPendingAiAction: finish } = usePendingAiAction();

  const cancelByAiSessionId = useRef<ReadonlyMap<Uuid, Cancel>>(new Map());

  const startPendingAiAction = useCallback(
    (aiSessionId: Uuid, cancel: Cancel) => {
      start(aiSessionId);
      cancelByAiSessionId.current = Collection.add(cancelByAiSessionId.current, [
        aiSessionId,
        cancel,
      ]);
    },
    [start],
  );

  const finishPendingAiAction = useCallback(
    (aiSessionId: Uuid) => {
      finish(aiSessionId);
      cancelByAiSessionId.current = Collection.remove(cancelByAiSessionId.current, aiSessionId);
    },
    [finish],
  );

  const cancelPendingAiAction = useCallback(
    (aiSessionId: Uuid) => {
      cancelByAiSessionId.current.get(aiSessionId)?.();
      finishPendingAiAction(aiSessionId);
    },
    [finishPendingAiAction],
  );

  return {
    cancelPendingAiAction,
    finishPendingAiAction,
    startPendingAiAction,
  };
};
