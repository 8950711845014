import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IRelationRepository } from '../../../../../repositories/interfaces/IRelationRepository.type.ts';
import {
  Relations_LoadDefaultRoot_Failed,
  Relations_LoadDefaultRoot_Finished,
  Relations_LoadDefaultRoot_Started,
} from '../../constants/relationsActionTypes.ts';

interface IDeps {
  readonly relationRepository: IRelationRepository;
}

const started = () =>
  ({
    type: Relations_LoadDefaultRoot_Started,
  }) as const;

const finished = (itemId: Uuid | null) =>
  ({
    type: Relations_LoadDefaultRoot_Finished,
    payload: {
      itemId,
    },
  }) as const;

const failed = () =>
  ({
    type: Relations_LoadDefaultRoot_Failed,
  }) as const;

export type LoadDefaultRelationsRootActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const loadDefaultRelationsRootActionCreator =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise<{ readonly rootId: Uuid | null }> =>
  async (dispatch) => {
    dispatch(started());

    try {
      const response = await deps.relationRepository.getDefaultRootItemId(abortSignal);

      dispatch(finished(response.itemId));

      return {
        rootId: response.itemId,
      };
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
