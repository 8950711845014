import { Textarea } from '@kontent-ai/component-library/Input';
import React from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { SingleEaseQuestion } from './SingleEaseQuestion.tsx';

interface IUmuxFeedbackQuestionnaireProps {
  readonly ease: string;
  readonly easeTitle: string;
  readonly onEaseChange: (requirements: string) => void;
  readonly onOptionalCommentChange: (requirements: string) => void;
  readonly onRequirementsChange: (requirements: string) => void;
  readonly optionalComment: string;
  readonly requirements: string;
  readonly requirementsTitle: string;
}

export const UmuxFeedbackQuestionnaire: React.FC<IUmuxFeedbackQuestionnaireProps> = (props) => (
  <div className="feedback-modal">
    <div className="feedback-modal__feedback-section">
      <SingleEaseQuestion
        title={props.requirementsTitle}
        onChange={props.onRequirementsChange}
        name="requirements"
        value={props.requirements}
      />
    </div>
    <div className="feedback-modal__feedback-section">
      <SingleEaseQuestion
        title={props.easeTitle}
        onChange={props.onEaseChange}
        name="ease"
        value={props.ease}
      />
    </div>
    <div className="feedback-modal__feedback-section">
      <div className="feedback-modal__question">
        Why did you rate this way? <span className="feedback-modal__supplemental">Optional</span>
      </div>
      <Textarea
        css="max-height: 20vh"
        autoFocus
        onChange={(e) => props.onOptionalCommentChange(e.target.value)}
        placeholder="I’m rating this way because…"
        value={props.optionalComment}
        {...getDataUiInputAttribute(DataUiInput.Text)}
      />
    </div>
  </div>
);

UmuxFeedbackQuestionnaire.displayName = 'UmuxFeedbackQuestionnaire';
