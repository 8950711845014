import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Link } from 'react-router-dom';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { SpaceMacro } from '../../../previewConfiguration/constants/macros.ts';

type Props = {
  readonly previewUrlsAppRoute: string;
  readonly showCollections: boolean;
};

export const SpacesQuickTipSection: React.FC<Props> = (props: Props) => (
  <Callout calloutType="quickTip" headline="Manage multiple previews with spaces!">
    <Stack spacing={Spacing.XL}>
      <ul>
        <li>
          Each <OutwardLink href={documentationLinks.spaces}>space</OutwardLink> can represent a
          different brand, channel, or other content division of your choice.
        </li>
        {props.showCollections && (
          <li>
            Assign <OutwardLink href={documentationLinks.collections}>collections</OutwardLink> to
            spaces to level up your content governance with a more granular content access
            permission model.
          </li>
        )}
        <li>
          Set up Web Spotlight for each space separately by using a different root item per space.
        </li>
        <li>
          Define <Link to={props.previewUrlsAppRoute}>preview URLs</Link> for each space using the
          macro <strong>{SpaceMacro}</strong>.
        </li>
      </ul>
    </Stack>
  </Callout>
);
