import useLocalStorageState from 'use-local-storage-state';
import { ContentPaceContentTypeStorageKey } from '../../../../_shared/constants/localStorageKeys.ts';

const getStorageKey = (projectId: Uuid, userId: Uuid): string =>
  `${ContentPaceContentTypeStorageKey}_${projectId}_${userId}`;

type UseContentPaceContentTypeResult = Readonly<{
  contentTypeId: Uuid | null;
  setContentTypeId: (value: Uuid | null) => void;
}>;

export const useContentPaceContentType = (
  userId: Uuid,
  projectId: Uuid,
): UseContentPaceContentTypeResult => {
  const storageKey = getStorageKey(projectId, userId);

  const [contentTypeId, setContentTypeId] = useLocalStorageState<Uuid | null>(storageKey, {
    defaultValue: null,
  });

  return {
    contentTypeId,
    setContentTypeId,
  };
};
