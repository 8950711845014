import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { TimeInterval } from '../../constants/timeIntervals.ts';
import { WorkflowStage } from '../../constants/workflowStage.ts';
import { ItemIdsWithTimeSpentInWorkflowStep } from '../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { ContentPaceDetail as ContentPaceDetailComponent } from '../components/ContentPaceDetail.tsx';
import {
  ContentPaceDetailContentItem,
  ContentPaceDetailData,
  getDetailContentItemsFromServerModel,
} from '../getDetailContentItemsFromServerModel.ts';
import { getContentPaceRequestModel } from '../utils/getContentPaceRequestModel.ts';

const { missionControlRepository, contentItemRepository } = repositoryCollection;

const _contentPaceDetailChunkSize = 200;

type State = Readonly<{
  isListPartial: boolean;
  loadedItems: ReadonlyArray<ContentPaceDetailContentItem> | null;
  unloadedItemIds: ItemIdsWithTimeSpentInWorkflowStep;
}>;

const initialState: State = {
  isListPartial: false,
  loadedItems: null,
  unloadedItemIds: [],
};

const InitStarted = 'InitStarted';
const LoadChunkSuccessful = 'LoadChunkSuccessful';

type Action = Readonly<
  | {
      type: typeof LoadChunkSuccessful;
      payload: Readonly<{
        chunkData: ContentPaceDetailData;
        newUnloadedItemIds: ItemIdsWithTimeSpentInWorkflowStep;
      }>;
    }
  | {
      type: typeof InitStarted;
    }
>;

const createLoadChunkSuccessfulAction = (
  chunkData: ContentPaceDetailData,
  newUnloadedItemIds: ItemIdsWithTimeSpentInWorkflowStep,
): Action => {
  return {
    type: LoadChunkSuccessful,
    payload: {
      chunkData,
      newUnloadedItemIds,
    },
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case LoadChunkSuccessful: {
      return {
        isListPartial: state.isListPartial || action.payload.chunkData.isListPartial,
        loadedItems: [
          ...(state.loadedItems || []),
          ...(action.payload.chunkData.itemsWithDuration || []),
        ],
        unloadedItemIds: action.payload.newUnloadedItemIds,
      };
    }
    case InitStarted:
      return initialState;
  }
};

export type ContentPaceDetailProps = Readonly<{
  averageTimeInSeconds: number;
  contentTypeId: Uuid | null;
  timeInterval: TimeInterval;
  workflowId: Uuid;
  workflowStage: WorkflowStage;
  workflowStepId: Uuid;
}>;

export const ContentPaceDetailLiveContainer: React.FC<ContentPaceDetailProps> = ({
  averageTimeInSeconds,
  contentTypeId,
  timeInterval,
  workflowId,
  workflowStepId,
  workflowStage,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [{ isListPartial, loadedItems, unloadedItemIds }, localDispatch] = useReducer(
    reducer,
    initialState,
  );

  const loadItemsChunk = useCallback(
    async (itemIdsWithTimeSpent: ItemIdsWithTimeSpentInWorkflowStep) => {
      const itemIdsToLoad = itemIdsWithTimeSpent.map((item) => item.contentItemId);

      const loadedItemsData =
        await contentItemRepository.getAllListingItemsDataWithVariantsByContentItemIds(
          itemIdsToLoad,
        );

      return getDetailContentItemsFromServerModel(
        loadedItemsData?.data,
        itemIdsWithTimeSpent,
        loadedItemsData.deletedItemIds.length,
      );
    },
    [],
  );

  const onLoadMoreItems = useCallback(async () => {
    setIsLoading(true);

    try {
      const chunkData = await loadItemsChunk(unloadedItemIds.slice(0, _contentPaceDetailChunkSize));
      localDispatch(
        createLoadChunkSuccessfulAction(
          chunkData,
          unloadedItemIds.slice(_contentPaceDetailChunkSize),
        ),
      );
    } catch (e) {
      logError(`${__filename}: Failed to fetch. `, e);
    } finally {
      setIsLoading(false);
    }
  }, [unloadedItemIds, loadItemsChunk]);

  useEffect(() => {
    localDispatch({ type: InitStarted });
    const { cancel } = makeCancellablePromise(async () => {
      const itemIdsToLoad = await missionControlRepository.getItemIdsWithTimeSpentInWorkflowStep(
        workflowId,
        workflowStepId,
        getContentPaceRequestModel(timeInterval, workflowStage, contentTypeId),
      );

      const chunkData = await loadItemsChunk(itemIdsToLoad.slice(0, _contentPaceDetailChunkSize));

      return {
        chunkData,
        newUnloadedItemIds: itemIdsToLoad.slice(_contentPaceDetailChunkSize),
      };
    })
      .then(({ chunkData, newUnloadedItemIds }) =>
        localDispatch(createLoadChunkSuccessfulAction(chunkData, newUnloadedItemIds)),
      )
      .catch(swallowCancelledPromiseError)
      .catch((error) => {
        logError(`${__filename}: Failed to fetch. `, error);
      })
      .finally(() => setIsLoading(false));

    return cancel;
  }, [workflowId, workflowStepId, timeInterval, workflowStage, contentTypeId, loadItemsChunk]);

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentPaceWorkflowDetailOpened, {
        contentTypeId,
        timeInterval,
        workflowId,
        workflowStage,
        workflowStepId,
      }),
    );
  }, [workflowId, workflowStepId, timeInterval, contentTypeId, workflowStage]);

  return (
    <ContentPaceDetailComponent
      areAllItemsLoaded={unloadedItemIds.length === 0}
      averageTimeInSeconds={averageTimeInSeconds}
      detailContentItems={loadedItems}
      isListPartial={isListPartial}
      isLoadingMoreItems={isLoading}
      onLoadMoreItems={onLoadMoreItems}
    />
  );
};

ContentPaceDetailLiveContainer.displayName = 'ContentPaceDetailLiveContainer';
