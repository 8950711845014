import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import {
  Invite_Initialize,
  Invite_Invitations_Changed,
  Invite_SearchText_Changed,
  Invite_SubmitInvitations_Finished,
} from '../../../constants/usersActionTypes.ts';
import { parseInvitations } from '../../../utils/parseInvitations.ts';
import { Invitation } from '../../IUsersAppState.type.ts';

export const invitations = (
  state: Immutable.List<Invitation> = Immutable.List(),
  action: Action,
): Immutable.List<Invitation> => {
  switch (action.type) {
    case Invite_SearchText_Changed:
      return state.concat(parseInvitations(action.payload.searchText)).toList();

    case Invite_Invitations_Changed:
      return action.payload.newInvitations;

    case Invite_Initialize:
    case Invite_SubmitInvitations_Finished:
      return Immutable.List();

    default:
      return state;
  }
};
