import { updateCurrentProject } from '../../../../_shared/actions/thunkSharedActions.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadAdministratedSubscriptions,
  loadProjects,
  loadUserProjectsInfo,
} from '../../../../data/actions/thunkDataActions.ts';
import { acceptTermsOfServiceCreator } from './thunks/acceptTermsOfService.ts';
import { createEnsureSelectedSubscriptionAction } from './thunks/ensureSelectedSubscription.ts';
import { createEnsureSubscriptionCurrentProjectAction } from './thunks/ensureSubcriptionCurrentProject.ts';
import { loadSubscriptionUsageCreator } from './thunks/loadSubscriptionUsages.ts';
import { loadSubscriptionUsersCreator } from './thunks/loadSubscriptionUsers.ts';

const { subscriptionRepository } = repositoryCollection;

export const loadSubscriptionUsage = loadSubscriptionUsageCreator({
  subscriptionRepository,
});

export const loadSubscriptionUsers = loadSubscriptionUsersCreator({
  subscriptionRepository,
});

export const ensureSelectedSubscription = createEnsureSelectedSubscriptionAction({
  loadAdministratedSubscriptions,
  loadSubscriptionUsage,
});

export const ensureSubscriptionCurrentProject = createEnsureSubscriptionCurrentProjectAction({
  updateCurrentProject,
});

export const acceptTermsOfService = acceptTermsOfServiceCreator({
  subscriptionRepository,
  loadProjects,
  loadUserProjectsInfo,
  loadAdministratedSubscriptions,
});
