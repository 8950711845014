import { Direction } from '@kontent-ai/types';
import { assert } from '@kontent-ai/utils';
import { ContentBlock, ContentState, Modifier, SelectionState, genKey } from 'draft-js';
import { splitEntity } from '../../plugins/entityApi/api/editorEntityUtils.ts';
import { updateText } from '../../plugins/textApi/api/editorTextUtils.ts';
import {
  BlockType,
  MaxNestingLevel,
  getNestedBlockType,
  isBlockTypeAllowedInTableCell,
} from '../blocks/blockType.ts';
import { getParentBlockTypes, isEmptyParagraph, isInTable } from '../blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../blocks/editorBlockGetters.ts';
import {
  BlockEdgeStatus,
  canMergeBlocks,
  createContent,
  filterBlocks,
  findBlockIndex,
  setBlockKey,
  setBlockText,
  setBlockType,
} from '../blocks/editorBlockUtils.ts';
import { ensureCustomBlockSleevesInContent } from '../consistency/editorConsistencyUtils.ts';
import {
  createSelection,
  getSelectionEdgeStatus,
  isSelectionWithinOneBlock,
  moveCaretToSelectionEnd,
  setContentSelection,
} from '../editorSelectionUtils.ts';
import { getBlocks } from '../general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  changeBlocksType,
  containsSingleTextBlock,
  contentIsPlainText,
  removeRange,
} from '../general/editorContentUtils.ts';

function prepareContentForRoot(content: ContentState): ContentState {
  const blocks = getBlocks(content);
  const allowedBlocks = filterBlocks(
    blocks,
    (block) =>
      !getParentBlockTypes(block).includes(BlockType.TableCell) ||
      isBlockTypeAllowedInTableCell(getBaseBlockType(block)),
  ).blocks;
  const rebasedAllowedBlocks = allowedBlocks.map((block) =>
    setBlockType(
      block,
      getNestedBlockType(
        getParentBlockTypes(block).slice(0, MaxNestingLevel),
        getBaseBlockType(block),
      ),
    ),
  );
  return createContent(rebasedAllowedBlocks);
}

function prepareContentForTableCell(content: ContentState): ContentState {
  const blocks = getBlocks(content);
  const allowedBlocks = filterBlocks(blocks, (block) =>
    isBlockTypeAllowedInTableCell(getBaseBlockType(block)),
  ).blocks;
  const rebasedAllowedBlocks = allowedBlocks.map((block) =>
    setBlockType(block, getNestedBlockType([BlockType.TableCell], getBaseBlockType(block))),
  );
  return createContent(rebasedAllowedBlocks);
}

export function pasteContent(
  input: IContentChangeInput,
  pastedContent: ContentState,
): IContentChangeResult {
  const { selection } = input;

  // When pasted content is just unstyled text without metadata, paste it as if it were a normal input to gain proper input styles
  if (isSelectionWithinOneBlock(selection) && contentIsPlainText(pastedContent)) {
    const text = pastedContent.getFirstBlock().getText();
    const withPastedText = updateText(input, text);

    return moveCaretToSelectionEnd(withPastedText);
  }

  // When the pasted content contains only single text block, we can merge partially selected text blocks
  // as the pasted text can be placed in the middle of the merged blocks
  const allowMergingBlocks = containsSingleTextBlock(pastedContent);

  const withSelectionRemoved = removeRange(input, Direction.Backward, true, allowMergingBlocks);
  const withSplitEntity = splitEntity(withSelectionRemoved);

  // If the selection wasn't collapsed, and both original blocks still exist, it means that they were partially selected but not merged
  // in this case the expected resulting selection is the split selection at the edge between those two blocks.
  const startBlock = withSplitEntity.content.getBlockForKey(selection.getStartKey());
  const endBlock = withSplitEntity.content.getBlockForKey(selection.getEndKey());
  const areOriginalBlocksPreserved =
    startBlock &&
    endBlock &&
    withSplitEntity.content.getBlockAfter(startBlock.getKey()) === endBlock;

  const pasteTarget =
    !selection.isCollapsed() && !allowMergingBlocks && areOriginalBlocksPreserved
      ? {
          content: withSplitEntity.content,
          selection: createSelection(
            startBlock.getKey(),
            startBlock.getLength(),
            endBlock.getKey(),
            0,
          ),
        }
      : withSplitEntity;

  return insertContent(pasteTarget, pastedContent);
}

const prepareContentToInsert = (
  input: IContentChangeInput,
  insertedContent: ContentState,
): ContentState => {
  const targetBlockKey = input.selection.getStartKey();
  const targetBlock = input.content.getBlockForKey(targetBlockKey);

  // Prepare content to paste
  const pastingToTable = isInTable(targetBlock);
  const preparedPastedContent = pastingToTable
    ? prepareContentForTableCell(insertedContent)
    : prepareContentForRoot(insertedContent);

  return ensureCustomBlockSleevesInContent(preparedPastedContent);
};

const insertContent = (
  input: IContentChangeInput,
  insertedContent: ContentState,
): IContentChangeResult => {
  const preparedInsertedContent = prepareContentToInsert(input, insertedContent);

  if (input.selection.isCollapsed()) {
    return insertContentToCollapsedSelection(input, preparedInsertedContent);
  }

  // When both start and end of the selection had partially selected blocks, the selection is split to the adjacent edges of such blocks.
  // We need to use our own code to merge the content of the inserted blocks with the existing content as Modifier.replaceWithFragment
  // can only handle inserting content into a collapsed selection because it internally calls removeRangeFromContentState which makes the selection collapsed.
  return insertContentToSplitSelection(input, preparedInsertedContent);
};

const insertContentToCollapsedSelection = (
  input: IContentChangeInput,
  insertedContent: ContentState,
): IContentChangeResult => {
  const { content, selection } = input;

  const targetBlockKey = selection.getStartKey();
  const targetBlock = content.getBlockForKey(targetBlockKey);

  const insertingToTable = isInTable(targetBlock);

  const firstInsertedBlock = insertedContent.getFirstBlock();

  // Normally, when DraftJS inserts fragment to an empty paragraph, it changes it to inserted block type
  // but when the target is in a table, it's block type is nested, so DraftJS doesn't detect it properly
  // We need to update the target block explicitly in this case
  const changeTargetBlockType =
    insertingToTable && !!firstInsertedBlock && isEmptyParagraph(targetBlock);

  const withUpdatedBlockType = changeTargetBlockType
    ? changeBlocksType(
        input,
        () => getBaseBlockType(firstInsertedBlock),
        (block) => block.getKey() === targetBlockKey,
      )
    : input;

  const newContent = Modifier.replaceWithFragment(
    withUpdatedBlockType.content,
    withUpdatedBlockType.selection,
    insertedContent.getBlockMap(),
  );
  const newSelection = newContent.getSelectionAfter();
  const newContentWithSelection = setContentSelection(newContent, input.selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
};

const isSelectionEmptyOrBetweenAdjacentBlockEdges = (
  content: ContentState,
  selection: SelectionState,
): boolean => {
  // The selection must be either collapsed or start and end must be at adjacent edges of the blocks
  // This is guaranteed immediately after removeRange
  if (!selection.isCollapsed()) {
    const areSelectionEdgesInAdjacentBlocks =
      content.getKeyAfter(selection.getStartKey()) === selection.getEndKey();
    if (!areSelectionEdgesInAdjacentBlocks) {
      return false;
    }

    const edgeStatus = getSelectionEdgeStatus(content, selection);
    const areSelectionEdgesAdjacent =
      [BlockEdgeStatus.EmptyBlockEdge, BlockEdgeStatus.EndEdge].includes(edgeStatus.start) &&
      [BlockEdgeStatus.EmptyBlockEdge, BlockEdgeStatus.StartEdge].includes(edgeStatus.end);

    if (!areSelectionEdgesAdjacent) {
      return false;
    }
  }

  return true;
};

const insertContentToSplitSelection = (
  input: IContentChangeInput,
  insertedContent: ContentState,
): IContentChangeResult => {
  const { content, selection } = input;
  if (!isSelectionEmptyOrBetweenAdjacentBlockEdges(content, selection)) {
    return input;
  }

  const blocks = getBlocks(content);

  const startKey = selection.getStartKey();
  const firstBlockInSelectionIndex = findBlockIndex(blocks, startKey);
  const firstBlockInSelectionToMerge = blocks[firstBlockInSelectionIndex];
  assert(firstBlockInSelectionToMerge, () => 'The first block in selection is undefined');
  const remainingBlocksBeforeSelection = firstBlockInSelectionIndex
    ? blocks.slice(0, firstBlockInSelectionIndex)
    : [];

  const endKey = selection.getEndKey();
  const lastBlockInSelectionIndex = findBlockIndex(blocks, endKey);
  const lastBlockInSelectionToMerge =
    firstBlockInSelectionIndex === lastBlockInSelectionIndex
      ? undefined
      : blocks[lastBlockInSelectionIndex];
  const remainingBlocksAfterSelection = blocks.slice(lastBlockInSelectionIndex + 1);

  const insertedBlocks = getBlocks(insertedContent)
    // Ensure unique block keys to avoid potential collision with the original ones
    .map((block) => setBlockKey(block, genKey()));

  const firstInsertedBlockToMerge = insertedBlocks[0];
  const lastInsertedBlockToMerge =
    insertedBlocks.length > 1 ? insertedBlocks[insertedBlocks.length - 1] : undefined;
  const remainingInsertedBlocks = insertedBlocks.filter(
    (block) => block !== firstInsertedBlockToMerge && block !== lastInsertedBlockToMerge,
  );

  const mergedBlocksAtSelectionStart = tryMergeBlocks(
    firstBlockInSelectionToMerge,
    firstInsertedBlockToMerge,
    firstBlockInSelectionToMerge.getKey(),
  );
  const mergedBlocksAtSelectionEnd = tryMergeBlocks(
    lastInsertedBlockToMerge,
    lastBlockInSelectionToMerge,
    lastBlockInSelectionToMerge?.getKey(),
  );

  const newBlocks = [
    ...remainingBlocksBeforeSelection,
    ...mergedBlocksAtSelectionStart,
    ...remainingInsertedBlocks,
    ...mergedBlocksAtSelectionEnd,
    ...remainingBlocksAfterSelection,
  ];

  const updatedBlocks = [
    mergedBlocksAtSelectionStart[mergedBlocksAtSelectionStart.length - 1],
    ...remainingInsertedBlocks,
    mergedBlocksAtSelectionEnd[0],
  ];

  const lastUpdatedBlock = updatedBlocks[updatedBlocks.length - 1];
  assert(lastUpdatedBlock, () => 'No blocks were updated');

  // The resulting selection is collapsed selection at the end of the inserted content (in last updated block)
  const newSelection = createSelection(
    lastUpdatedBlock.getKey(),
    lastUpdatedBlock.getLength() - (lastBlockInSelectionToMerge?.getLength() ?? 0),
  );

  const newContent = createContent(newBlocks);
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
};

const tryMergeBlocks = (
  first: ContentBlock | undefined,
  second: ContentBlock | undefined,
  mergedBlockKey?: string,
): ReadonlyArray<ContentBlock> => {
  if (!first) {
    return second ? [second] : [];
  }

  if (!second) {
    return [first];
  }

  if (!canMergeBlocks(first, second)) {
    return [first, second];
  }

  // When merging to an empty paragraph, the style of the second block has priority, so it can apply its formatting to it
  const useTypeAndMetadataFromBlock = isEmptyParagraph(first) ? second : first;

  const mergedBlock = setBlockText(
    mergedBlockKey
      ? setBlockKey(useTypeAndMetadataFromBlock, mergedBlockKey)
      : useTypeAndMetadataFromBlock,
    {
      text: first.getText() + second.getText(),
      characterList: first.getCharacterList().concat(second.getCharacterList()).toList(),
    },
  );

  return [mergedBlock];
};
