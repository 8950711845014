import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  canvasInnerSectionRestrictedWidth,
  spacingMainLayoutBottom,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ApiKey } from '../../../projectSettings/apiKeys/models/ApiKey.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { KeyRegenerationStatus } from '../models/KeyRegenerationStatus.ts';
import { SubscriptionApiKeyCard } from './SubscriptionApiKeyCard.tsx';
import { SubscriptionIdCard } from './SubscriptionIdCard.tsx';

export type SubscriptionApiKeyTileProps = {
  readonly apiKey: ApiKey;
  readonly isApiAvailable: boolean;
  readonly isLoadingFailed: boolean;
  readonly regenerationStatus: KeyRegenerationStatus;
  readonly subscriptionId: Uuid;
  readonly onRegenerate: () => void;
};

export const SubscriptionApiKeyTile: React.FC<SubscriptionApiKeyTileProps> = ({
  apiKey,
  isApiAvailable,
  isLoadingFailed,
  regenerationStatus,
  subscriptionId,
  onRegenerate,
}) => (
  <Box
    paddingTop={spacingMainLayoutTop}
    paddingRight={spacingMainLayoutRight}
    paddingBottom={spacingMainLayoutBottom}
    paddingLeft={spacingMainLayoutLeft}
    overflowX="hidden"
    overflowY="auto"
    {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionApiKeys)}
  >
    <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.ApiKeys} />
    <Box css="width: 100%" maxWidth={canvasInnerSectionRestrictedWidth}>
      <Stack spacing={Spacing.XL}>
        <PageTitle>{SubscriptionTabName.ApiKeys}</PageTitle>
        <SubscriptionIdCard subscriptionId={subscriptionId} />
        <Box maxWidth={canvasInnerSectionRestrictedWidth / 2}>
          <SubscriptionApiKeyCard
            apiKey={apiKey}
            isApiAvailable={isApiAvailable}
            isLoadingFailed={isLoadingFailed}
            regenerationStatus={regenerationStatus}
            subscriptionId={subscriptionId}
            onRegenerate={onRegenerate}
          />
        </Box>
      </Stack>
    </Box>
  </Box>
);

SubscriptionApiKeyTile.displayName = 'SubscriptionApiKeyTile';
