import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { CommentThreadItemType } from '../../../../models/comments/CommentThreadItem.ts';
import { getFocusedCommentThread } from '../../../../selectors/commentThreads.ts';
import { hasCommentThreadSuggestions } from '../../../../utils/commentUtils.ts';
import {
  FocusedCommentThreadHighlighter as FocusedCommentThreadHighlighterComponent,
  IFocusedCommentThreadHighlighterProps,
} from '../../components/comments/FocusedCommentThreadHighlighter.tsx';

function mapStateToProps(state: IStore): IFocusedCommentThreadHighlighterProps {
  const {
    editorUi: { newCommentThreadItemTypeMappings },
  } = state.contentApp;

  const focusedThread = getFocusedCommentThread(state);
  const focusedNewThread = focusedThread && newCommentThreadItemTypeMappings.get(focusedThread.id);

  const focusedThreadHasSuggestions =
    !!focusedThread &&
    (((focusedThread.isUnsaved || focusedThread.isReplying) &&
      focusedNewThread === CommentThreadItemType.Suggestion) ||
      hasCommentThreadSuggestions(focusedThread));

  return {
    focusedThread,
    focusedThreadHasSuggestions,
  };
}

export const FocusedCommentThreadHighlighter: React.ComponentType = connect(mapStateToProps)(
  FocusedCommentThreadHighlighterComponent,
);
