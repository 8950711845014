import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { isCustomTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ICustomItemElement } from '../../../../models/contentItemElements/CustomItemElement.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { getCustomElementErrors } from '../../../../utils/elementErrorCheckers/customElementErrorChecker.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getItemElementValueForErrorValidation } from '../../../../utils/getItemElementValueForErrorValidation.ts';
import { emptyItemElementFriendlyWarningResult } from '../../../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { getCustomItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/customItemElementWarningChecker.ts';
import { mapElementErrorResultToItemElementErrorResult } from '../../../../utils/mapElementErrorResultToItemElementErrorResult.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

export const createCustomElementValueChangedAction =
  () =>
  (elementId: Uuid, value: string | null, searchableValue: string | null): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItem, editedContentItemVariantElements, loadedContentItemTypes },
    } = getState();

    if (!editedContentItem) {
      throw InvariantException('customElementValueChanged: "editedContentItem" is not loaded');
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    if (!editedContentItemType) {
      throw InvariantException('customElementValueChanged: "editedContentItemType" is not loaded');
    }

    const element = getElementByIdOrThrow(elementId, editedContentItemVariantElements);
    const updatedElement: ICustomItemElement = {
      ...element,
      value,
      searchableValue,
    };

    const errorResult = getCustomElementErrors({
      value: getItemElementValueForErrorValidation(updatedElement),
    });

    const typeElement = editedContentItemType.contentElements.find(
      (elType) => elType.elementId === elementId,
    );
    if (!isCustomTypeElement(typeElement)) {
      return;
    }

    const warningResult = getCustomItemElementValidationWarning({
      typeElement,
      itemElement: updatedElement,
    });

    const validationResult = createValidationResult(
      mapElementErrorResultToItemElementErrorResult(errorResult, typeElement.type),
      warningResult,
      emptyItemElementFriendlyWarningResult,
      elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: updatedElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
