import React from 'react';
import { ILinkedItemsTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { ILinkedItemsElement } from '../../../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';
import { LinkedItemsDiff } from './subelements/LinkedItemsDiff.tsx';

type LinkedItemsProps = IRevisionItemElementProps<ILinkedItemsElement, ILinkedItemsTypeElement>;

export const LinkedItems: React.FC<LinkedItemsProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  typeElement,
  revisionElementModifiedBy,
}) => (
  <ItemElement
    disabled
    hideValidationStatus={hideValidationStatus}
    isDisplayOnly
    typeElement={typeElement}
    revisionChangedBy={revisionElementModifiedBy}
  >
    <ItemElementRefresher
      elementData={elementData}
      renderInput={(refresherElementData: ILinkedItemsElement) => (
        <LinkedItemsDiff
          elementData={refresherElementData}
          originalElementData={originalElementData}
          typeElement={typeElement}
        />
      )}
    />
  </ItemElement>
);

LinkedItems.displayName = 'LinkedItems (Revisions)';
