import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Project_CopyState_Updated } from '../../../../applications/projects/constants/projectActionTypes.ts';
import { IProjectDetailsServerModel } from '../../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';
import { Data_Projects_Success } from '../../../constants/dataActionTypes.ts';
import {
  IProjectDetails,
  createProjectDetailsDomainModel,
} from '../../../models/projects/ProjectDetails.ts';

const initialState: Immutable.Map<Uuid, IProjectDetails> = Immutable.Map();

export const byId = (
  state: Immutable.Map<Uuid, IProjectDetails> = initialState,
  action: Action,
): Immutable.Map<Uuid, IProjectDetails> => {
  switch (action.type) {
    case Data_Projects_Success: {
      const projectDetails = action.payload.data.reduce(
        (reducedMap: Immutable.Map<Uuid, IProjectDetails>, project: IProjectDetailsServerModel) =>
          reducedMap.set(project.projectId, createProjectDetailsDomainModel(project)),
        initialState,
      );

      return projectDetails;
    }

    case Project_CopyState_Updated: {
      const oldProject = state.get(action.payload.data.projectId);
      if (!oldProject) {
        return state;
      }
      const modifiedProject: IProjectDetails = {
        ...oldProject,
        copyState: action.payload.data.copyState,
      };

      return state.set(action.payload.data.projectId, modifiedProject);
    }

    default:
      return state;
  }
};
