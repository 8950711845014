import { Action } from '../../../../@types/Action.type.ts';
import { ContentType_Creator_InitStarted } from '../../contentTypes/constants/contentTypesActionTypes.ts';
import {
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_InitStarted,
} from '../../snippets/constants/snippetActionTypes.ts';
import {
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_SaveAttempted,
} from '../constants/sharedContentModelsActionTypes.ts';
import { ConditionElementId } from '../types/ConditionElementId.type.ts';

const initialState: ReadonlyArray<ConditionElementId> = [];

export const activeConditionsBeforeLastSave = (
  state = initialState,
  action: Action,
): ReadonlyArray<ConditionElementId> => {
  switch (action.type) {
    case ContentType_Creator_InitStarted:
    case ContentTypeSnippet_Editor_InitStarted:
    case ContentTypeSnippet_Creator_InitStarted:
    case ContentModels_TypeEditor_InitStarted:
      return initialState;
    case ContentModels_TypeEditor_SaveAttempted:
      return action.payload.activeConditionIds;
    default:
      return state;
  }
};
