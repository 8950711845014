import {
  ISelectItem,
  ItemId,
  Option,
  RenderSelectMenuOptionProps,
} from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React, { useState } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';

export interface IAssetFolderOption extends ISelectItem<IAssetFolderOption> {
  readonly tooltip: string | undefined;
}

export interface IMoveToAssetFolderDialogDisabledProps {
  readonly tooltip: string;
}

export interface IMoveToAssetFolderDialogOwnProps {
  readonly closeDialog: () => void;
  readonly dataUiAction: DataUiAction;
  readonly label: string;
  readonly primaryActionText: string;
}

type Props = IMoveToAssetFolderDialogOwnProps & {
  readonly disabledOptionIds?: ReadonlyArray<ItemId>;
  readonly folderOptions: ReadonlyArray<IAssetFolderOption>;
  readonly getDisabledProps: (
    selectedFolder: IAssetFolderOption,
  ) => IMoveToAssetFolderDialogDisabledProps | null;
  readonly onMoveToFolder: (folderId: Uuid) => void;
  readonly preselectedOption: IAssetFolderOption;
};

const renderLeadingElement = (itemId: ItemId) => {
  const isFolder = itemId !== RootFolderId;

  return <Icon iconName={isFolder ? IconName.Folder : IconName.Home} />;
};

const renderOption = (
  optionProps: RenderSelectMenuOptionProps<IAssetFolderOption>,
): React.ReactNode => {
  const {
    item: { value: option },
  } = optionProps;

  return (
    option && (
      <Option
        leadingElement={renderLeadingElement(option.id)}
        {...optionProps}
        {...getDataUiObjectNameAttribute(option.id)}
      />
    )
  );
};

export const MoveToAssetFolderDialog: React.FC<Props> = ({
  closeDialog,
  dataUiAction,
  disabledOptionIds,
  folderOptions,
  getDisabledProps,
  label,
  onMoveToFolder,
  preselectedOption,
  primaryActionText,
}) => {
  const [selectedOption, setSelectedOption] = useState<IAssetFolderOption>(preselectedOption);

  const moveToFolder = (): void => {
    onMoveToFolder(selectedOption.id);
    closeDialog();
  };

  const disabledProps = getDisabledProps(selectedOption);

  return (
    <ModalDialog
      isOpen
      isDismissable
      onClose={closeDialog}
      headline="Move to a folder"
      primaryAction={{
        disabled: !!disabledProps,
        onClick: moveToFolder,
        text: primaryActionText,
        tooltipText: disabledProps?.tooltip,
        ...getDataUiActionAttribute(dataUiAction),
      }}
      cancelAction={{
        onClick: closeDialog,
      }}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <div className="move-to-asset-folder-dialog">
        <SingleSelect<IAssetFolderOption>
          autoFocus
          delayAutoFocus={250}
          disabledItemIds={disabledOptionIds}
          items={folderOptions}
          label={label}
          onSelectionChange={(_itemId, item) => {
            setSelectedOption(item ?? preselectedOption);
          }}
          selectedItemId={selectedOption.id}
          renderPrefix={renderLeadingElement}
          renderMenuOption={renderOption}
          tooltipText={selectedOption.tooltip}
          tooltipPlacement="top-start"
          verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.AssetFolders)}
          inputDataAttributes={{
            ...getDataUiActionAttribute(DataUiAction.OpenAssetFoldersSelector),
            ...getDataUiInputAttribute(DataUiInput.Text),
          }}
        />
      </div>
    </ModalDialog>
  );
};

MoveToAssetFolderDialog.displayName = 'MoveToAssetFolderDialog';
