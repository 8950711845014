import { getCurrentUser } from '../../../../_shared/selectors/getCurrentUser.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { ApiKeyTab } from '../constants/ApiKeyTab.ts';
import { getEnvironmentsDetails } from './getEnvironmentsDetails.ts';
import { hasCapabilityInAnyEnvironment } from './hasCapabilityInAnyEnvironment.ts';

const isProjectManagerInAnyEnvironment = (state: IStore): boolean => {
  const environmentDetails = getEnvironmentsDetails(state);
  const { userId } = getCurrentUser(state);

  return !!environmentDetails.find((environmentDetail) =>
    environmentDetail.projectManagers.find((projectManager) => projectManager?.userId === userId),
  );
};

const canSeeMapiKeys = (state: IStore) =>
  hasCapabilityInAnyEnvironment(state, Capability.ManagePersonalApiKey) ||
  isProjectManagerInAnyEnvironment(state) ||
  state.apiKeysApp.hasAnyMapiKeys;

const canSeeDapiKeys = (state: IStore) =>
  isProjectManagerInAnyEnvironment(state) || state.apiKeysApp.hasAnyDapiKeys;

export const getVisibleApiKeyTabs = (state: IStore): ReadonlyArray<ApiKeyTab> => [
  ...(canSeeMapiKeys(state) ? [ApiKeyTab.MapiKeys] : []),
  ...(canSeeDapiKeys(state) ? [ApiKeyTab.DapiKeys] : []),
];
