import Immutable from 'immutable';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import {
  IListItemsByIdsQueryServerModel,
  ItemCompletionServerStatus,
  OrderByServerModel,
} from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { TaxonomyGroupFilterServerModel } from '../../../../../repositories/serverModels/TaxonomyFilterServerModel.type.ts';
import { AssignedContentItemsOrderBy } from '../../../../itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';
import { getTaxonomyGroupFilterServerModel } from '../../../shared/utils/getTaxonomyGroupFilterServerModel.ts';
import { uncategorizedItemFilterTaxonomyTermId } from '../../features/ListingFilter/constants/listingFilterConstants.ts';
import { IContentItemStatus } from './IContentItemStatus.ts';
import { IPublishingState } from './IPublishingState.ts';

export function getPublishingState(
  publishingState: IPublishingState,
): PublishingState[] | undefined {
  const result: PublishingState[] = [];

  if (publishingState.published) {
    result.push(PublishingState.Published);
  }
  if (publishingState.none) {
    result.push(PublishingState.None);
  }
  if (publishingState.unpublished) {
    result.push(PublishingState.Unpublished);
  }

  return result.length > 0 ? result : undefined;
}

export function getItemCompletionStatus(
  requiredElements: IContentItemStatus,
): ItemCompletionServerStatus[] | undefined {
  const result: ItemCompletionServerStatus[] = [];

  if (requiredElements.allDone) {
    result.push('allDone');
  }
  if (requiredElements.ready) {
    result.push('completed');
  }
  if (requiredElements.notTranslated) {
    result.push('notTranslated');
  }
  if (requiredElements.unfinished) {
    result.push('unfinished');
  }

  return result.length > 0 ? result : undefined;
}

export function createContentItemFilterServerModelFromIds(
  ids: Immutable.Set<Uuid>,
): IListItemsByIdsQueryServerModel {
  return {
    itemIds: ids.toJS(),
  };
}

export function getOrderBy(
  orderBy: OrderBy<ItemColumnCode> | OrderBy<AssignedContentItemsOrderBy>,
): OrderByServerModel {
  return {
    columnName: orderBy.columnCode,
    direction: orderBy.direction === OrderByDirection.Descending ? 'desc' : 'asc',
  };
}

export const getTaxonomyGroupItemFilterServerModel = (
  termIds: ReadonlyArray<Uuid>,
): TaxonomyGroupFilterServerModel =>
  getTaxonomyGroupFilterServerModel(termIds, uncategorizedItemFilterTaxonomyTermId);
