import {
  colorBackgroundDisabled,
  colorBackgroundDisabledComplementary,
  colorPrimary,
  colorPrimaryHover,
} from '../../tokens/decision/colors.ts';
import { Size } from '../../tokens/decision/spacing.ts';
import { BaseColor } from '../../tokens/quarks/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';

export const sliderTrackHeight = Size.S;
export const sliderTrackLineHeight = Spacing.XS;
export const sliderTrackLineBackground = BaseColor.Gray30;
export const sliderTrackLineBackgroundDisabled = colorBackgroundDisabled;
export const sliderTrackLineProgressBackground = colorPrimary;
export const sliderTrackLineProgressBackgroundDisabled = colorBackgroundDisabledComplementary;

export const sliderThumbSize = Spacing.M;
export const sliderThumbSizeActive = Size.XS;
export const sliderThumbBackground = colorPrimary;
export const sliderThumbBackgroundDisabled = colorBackgroundDisabledComplementary;
export const sliderThumbBackgroundHover = colorPrimaryHover;
