import styled from 'styled-components';

export const StyledSelectGroupFlexibleWrapper = styled.div`
  flex: 1 1;
  min-width: 0;
  max-width: 100%;
  align-self: stretch;
  display: flex;
  align-items: center;
`;
