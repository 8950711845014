import React from 'react';
import { DataUiElement } from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { StatusMessage } from './StatusMessage.tsx';

interface IContentTypeLimitStatusMessageProps {
  readonly allowedTypesMessage: string | undefined;
  readonly isValid: boolean;
}

export const ContentTypeLimitStatusMessage: React.FC<IContentTypeLimitStatusMessageProps> = ({
  allowedTypesMessage,
  isValid,
}): JSX.Element | null => {
  if (!allowedTypesMessage) {
    return null;
  }

  return (
    <span className="content-item-element__status-segment">
      <StatusMessage
        dataUiElement={DataUiElement.ElementLimitations}
        isInvalid={!isValid}
        message={allowedTypesMessage}
      />
    </span>
  );
};

ContentTypeLimitStatusMessage.displayName = 'ContentTypeLimitStatusMessage';
