import { Collection } from '@kontent-ai/utils';
import { ContentState } from 'draft-js';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import {
  IContentComponentServerModelConversionDependencies,
  contentComponentConversionForServer,
} from '../../../../itemEditor/features/ContentComponent/utils/contentComponentConversionUtils.ts';
import { IContentComponent } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { IContentComponentServerModel } from '../../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import { IGetItemElementServerModelConverter } from '../../../../itemEditor/utils/getItemElementDataConverters.ts';
import { GetAssets } from '../../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import { ObjectDataType } from '../../../utils/export/html/elements/objects.ts';
import {
  GetUsers,
  exportToClipboardPlainText,
} from '../../../utils/export/plainText/exportToPlainText.ts';
import { getContentStateForServer } from '../../../utils/serverModel/editorServerModelUtils.ts';
import { exportToClipboardHtml } from '../api/exportToClipboardHtml.ts';

export interface IConvertedContentState {
  readonly html: string;
  readonly plainText: string;
}

export type IConvertRichTextToClipboardHtml = (
  content: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
  metadata: ReadonlyMap<ObjectDataType, Uuid>,
) => ThunkFunction<IConvertedContentState>;

interface IDeps {
  readonly getItemElementServerModelConverter: IGetItemElementServerModelConverter;
}

export const createConvertRichTextToClipboardHtml =
  (deps: IDeps): IConvertRichTextToClipboardHtml =>
  (content, contentComponents, metadata) =>
  (_dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    const forServer = getContentStateForServer(content);

    const getAssets: GetAssets = () => getState().data.assets.byId;
    const contentComponentConversionDeps: IContentComponentServerModelConversionDependencies = {
      getItemElementServerModelConverter: deps.getItemElementServerModelConverter,
      dataDependencies: {
        getAssets,
        getContentComponent: (contentComponentId) => contentComponents.get(contentComponentId),
      },
    };
    const contentComponentsForServer = new Map<Uuid, IContentComponentServerModel>(
      Collection.getEntries(contentComponents).map(([contentComponentId, contentComponent]) => [
        contentComponentId,
        contentComponentConversionForServer(contentComponent, contentComponentConversionDeps),
      ]),
    );

    const getUsers: GetUsers = () => getState().data.users.usersById;

    const html = exportToClipboardHtml(
      forServer,
      contentComponentsForServer,
      new Map(metadata).set(ObjectDataType.Project, currentProjectId),
      { getUsers },
    );

    const plainText = exportToClipboardPlainText(content, { getUsers });

    return {
      html,
      plainText,
    };
  };
