import { InvariantException } from '@kontent-ai/errors';

export enum FileSizeUnit {
  Byte = 'byte',
  Kilobyte = 'kilobyte',
  Megabyte = 'megabyte',
  Gigabyte = 'gigabyte',
}

export const FileSizeOptions: ReadonlyArray<FileSizeUnit> = [
  FileSizeUnit.Kilobyte,
  FileSizeUnit.Megabyte,
  FileSizeUnit.Gigabyte,
];

export function getFileSizeOptionName(value: FileSizeUnit): string {
  switch (value) {
    case FileSizeUnit.Byte:
      return 'B';
    case FileSizeUnit.Kilobyte:
      return 'kB';
    case FileSizeUnit.Megabyte:
      return 'MB';
    case FileSizeUnit.Gigabyte:
      return 'GB';
    default:
      throw InvariantException('Invalid fileSize value');
  }
}
