import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IContentItemRepository } from '../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IServerContentItemUsages } from '../../../repositories/serverModels/ContentItemUsageModel.type.ts';
import { Data_ListingContentItems_LoadUsages } from '../../constants/dataActionTypes.ts';

const usagesLoaded = (itemId: Uuid, usages: IServerContentItemUsages) =>
  ({
    type: Data_ListingContentItems_LoadUsages,
    payload: {
      itemId,
      usages,
    },
  }) as const;

export type LoadContentItemUsagesActionsType = ReturnType<typeof usagesLoaded>;

interface ILoadContentItemUsagesDependencies {
  readonly contentItemRepository: IContentItemRepository;
}

export type ILoadContentItemUsagesAction = (
  contentItemId: Uuid,
  variantId: Uuid,
  abortSignal?: AbortSignal,
) => ThunkPromise;

export const createLoadContentItemUsagesAction =
  (deps: ILoadContentItemUsagesDependencies): ILoadContentItemUsagesAction =>
  (contentItemId, variantId, abortSignal) =>
  async (dispatch) => {
    const usages = await deps.contentItemRepository.getContentItemUsage(
      contentItemId,
      variantId,
      abortSignal,
    );

    dispatch(usagesLoaded(contentItemId, usages));
  };
