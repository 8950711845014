import { useState } from 'react';
import {
  ContentChangesWarningModal,
  ContentChangesWarningSubtitle,
} from '../../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import { SaveElementsWithAffectedLocalizationWarning } from '../../../../../_shared/components/ModalDialog/SaveElementsWithAffectedLocalizationWarning.tsx';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';

type Props = {
  readonly codename?: string | null;
  readonly disabledDeleteTooltip?: string;
  readonly elementsWithAffectedLocalization: ReadonlyArray<{
    readonly elementId: Uuid;
    readonly name: string;
  }>;
  readonly hideLongProcessingChangesWarning?: boolean;
  readonly isBeingSaved: boolean;
  readonly isCodenameEditable?: boolean;
  readonly isDeleteDisabled?: boolean;
  readonly isDuplicateDisabled?: boolean;
  readonly isLoading: boolean;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onCodenameSave?: (codename: string) => void;
  readonly onDelete?: () => void;
  readonly onDismissWarning: () => void;
  readonly onDuplicate?: () => void;
  readonly onSave: () => void;
  readonly relatedCodenames?: ReadonlySet<string>;
};

export const ContentTypeOrSnippetEditorToolbarActions = ({
  codename,
  elementsWithAffectedLocalization,
  isBeingSaved,
  isCodenameEditable,
  relatedCodenames,
  isDuplicateDisabled,
  isLoading,
  hideLongProcessingChangesWarning,
  onSave,
  onCodenameSave,
  onCodenameCopy,
  onCodenameEdit,
  onDuplicate,
  onDismissWarning,
}: Props) => {
  const [showDialog, setShowDialog] = useState(false);

  const onSaveChanges = () => {
    if (!hideLongProcessingChangesWarning || elementsWithAffectedLocalization.length > 0) {
      setShowDialog(true);
    } else {
      onSave();
    }
  };

  const onConfirmDialog = () => {
    setShowDialog(false);
    onSave();
  };

  const onCancelDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <StatusBarActions
        codename={codename}
        isBeingSaved={isBeingSaved}
        isCodenameEditable={isCodenameEditable}
        relatedCodenames={relatedCodenames}
        isDuplicateDisabled={isDuplicateDisabled}
        isLoading={isLoading}
        onSave={onSaveChanges}
        onCodenameSave={onCodenameSave}
        onCodenameCopy={onCodenameCopy}
        onCodenameEdit={onCodenameEdit}
        onDuplicate={onDuplicate}
      />
      <ContentChangesWarningModal
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
        onDismissWarning={onDismissWarning}
        isOpen={showDialog}
        warningSubtitle={ContentChangesWarningSubtitle.ContentTypeEditing}
        hideLongProcessingChangesWarning={hideLongProcessingChangesWarning}
        renderAdditionalModalContent={() => (
          <SaveElementsWithAffectedLocalizationWarning
            elementsWithAffectedLocalization={elementsWithAffectedLocalization}
          />
        )}
      />
    </>
  );
};
