import { Loader } from './Loader.tsx';

export const InitializerLoader = () => {
  return (
    <Loader
      renderContent={() => (
        <img
          alt="Loading..."
          src="/images/logotypes/kontent-ai-black.svg"
          className="loader__image"
        />
      )}
    />
  );
};
