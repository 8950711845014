import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { emptyCollection } from '../../../../../../data/models/collections/Collection.ts';

export const getCurrentCollectionName = (state: IStore): string | null => {
  const { editedContentItem } = state.contentApp;

  if (!editedContentItem) {
    return null;
  }

  const currentCollection = state.data.collections.byId.get(editedContentItem.collectionId);

  return currentCollection ? currentCollection.name : emptyCollection.name;
};
