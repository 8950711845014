import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_CreateTask_Failed,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_CreateTask_Started,
  ContentItemEditing_Init_Started,
  ContentItemEditing_UpdateTask_Failed,
  ContentItemEditing_UpdateTask_Finished,
  ContentItemEditing_UpdateTask_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { NewTaskId } from '../../../../features/ContentItemEditing/constants/taskConstants.ts';

const initialState: ReadonlySet<Uuid> = new Set();

export function submittingTasks(state = initialState, action: Action): ReadonlySet<Uuid> {
  switch (action.type) {
    case ContentItemEditing_Init_Started:
      return initialState;

    case ContentItemEditing_CreateTask_Started:
      return Collection.add(state, NewTaskId);

    case ContentItemEditing_CreateTask_Failed:
    case ContentItemEditing_CreateTask_Finished:
      return Collection.remove(state, NewTaskId);

    case ContentItemEditing_UpdateTask_Started:
      return Collection.add(state, action.payload.taskId);

    case ContentItemEditing_UpdateTask_Failed:
      return Collection.remove(state, action.payload.taskId);

    case ContentItemEditing_UpdateTask_Finished:
      return Collection.remove(state, action.payload.task.id);

    default:
      return state;
  }
}
