import { Action } from '../../../../@types/Action.type.ts';
import { Shared_Editor_Left } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentModels_TypeEditor_Left } from '../../../contentModels/shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentItemEditing_AssetAndRichTextElementsRevalidated,
  ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ElementValue_Changed,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ItemElementWarnings } from '../../utils/itemElementWarningCheckers/types/Warnings.ts';
import {
  updateStateForNonRichTextElement,
  updateStateForRichTextElement,
} from '../utils/validationResultUtils.ts';

const initialState: ItemElementWarnings = new Map();

export function itemValidationWarnings(state = initialState, action: Action): ItemElementWarnings {
  switch (action.type) {
    case ContentItemEditing_BulkContentItemVariantElementsRevalidationFinished: {
      return action.payload.warnings;
    }

    case ContentItemEditing_ElementValue_Changed:
    case ContentItemEditing_ElementRefresh_Finished: {
      const elementData = action.payload.elementData;
      const validationResult = action.payload.validationResult;

      return elementData.type === ElementType.RichText
        ? updateStateForRichTextElement(state, validationResult.warnings, elementData.elementId)
        : updateStateForNonRichTextElement(state, validationResult.warnings, elementData.elementId);
    }

    case ContentItemEditing_AssetAndRichTextElementsRevalidated: {
      return [...action.payload.validationResults].reduce(
        (previousState, [elementData, validationResult]) =>
          elementData.type === ElementType.RichText
            ? updateStateForRichTextElement(
                previousState,
                validationResult.warnings,
                elementData.elementId,
              )
            : updateStateForNonRichTextElement(
                previousState,
                validationResult.warnings,
                elementData.elementId,
              ),
        state,
      );
    }

    case ContentModels_TypeEditor_Left:
    case Shared_Editor_Left: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
