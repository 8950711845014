import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadUserProjectsInfo } from '../../../../data/actions/thunkDataActions.ts';
import { createSaveProjectNameToServerAction } from './thunks/saveProjectNameToServer.ts';

const { projectRepository } = repositoryCollection;

export const saveProjectNameToServer = createSaveProjectNameToServerAction({
  projectRepository,
  loadUserProjectsInfo,
});
