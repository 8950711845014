import { nameof } from '@kontent-ai/utils';
import { ListingOrderByStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { OrderBy, OrderByDirection } from '../_shared/models/OrderBy.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

export enum ListingContext {
  ContentItemInventory = 'contentiteminventory-v2',
  AssetListing = 'assetlisting',
}

function getStorageKey(listingContext: ListingContext, projectId: Uuid): string {
  return `${ListingOrderByStorageKey}-${listingContext}-${projectId}`;
}

export interface IListingOrderByStorage {
  readonly save: <TOrderBy>(
    orderByState: TOrderBy,
    listingContext: ListingContext,
    projectId: Uuid,
  ) => void;
  readonly forget: (listingContext: ListingContext, projectId: Uuid) => void;
  readonly load: <TOrderBy>(
    listingContext: ListingContext,
    projectId: Uuid,
    parseRawData: (rawData: string | undefined | null) => TOrderBy | null,
  ) => TOrderBy | null;
}

export const listingOrderByStorage: IListingOrderByStorage = {
  save<TOrderBy>(orderByState: TOrderBy, listingContext: ListingContext, projectId: Uuid): void {
    localStorage.set(getStorageKey(listingContext, projectId), JSON.stringify(orderByState));
  },

  forget(listingContext: ListingContext, projectId: Uuid): void {
    localStorage.remove(getStorageKey(listingContext, projectId));
  },

  load<TOrderBy>(
    listingContext: ListingContext,
    projectId: Uuid,
    parseRawData: (rawData: string | undefined | null) => TOrderBy | null,
  ): TOrderBy | null {
    const stringValue = localStorage.get(getStorageKey(listingContext, projectId));
    return parseRawData(stringValue);
  },
};

export const parseLocalStorageDataToOrderBy =
  <TColumnCode>(columnCodes: Record<string, TColumnCode>) =>
  (rawData: string | undefined): OrderBy<TColumnCode> | null => {
    if (!rawData) {
      return null;
    }

    const data = JSON.parse(rawData);

    const columnCode = data[nameof<OrderBy<TColumnCode>>('columnCode')];
    const direction = data[nameof<OrderBy<TColumnCode>>('direction')];

    if (
      !Object.values(columnCodes).includes(columnCode) ||
      !Object.values(OrderByDirection).includes(direction)
    ) {
      return null;
    }

    return {
      columnCode,
      direction,
    };
  };
