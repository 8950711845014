import { Action } from '../../../../@types/Action.type.ts';
import { DapiKeyListing_Loading_Finished } from '../../../../applications/projectSettings/apiKeys/constants/apiKeysActionTypes.ts';
import { IApiKeyListingData } from '../../../../applications/projectSettings/apiKeys/models/ApiKeyListingData.ts';

const initialState: ReadonlyArray<IApiKeyListingData> = [];

export const dapiListingData = (
  state: ReadonlyArray<IApiKeyListingData> = initialState,
  action: Action,
): ReadonlyArray<IApiKeyListingData> => {
  switch (action.type) {
    case DapiKeyListing_Loading_Finished: {
      return action.payload.apiKeyListingData;
    }

    default:
      return state;
  }
};
