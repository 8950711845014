import Immutable from 'immutable';
import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { moveSelectedAssetsToCollection } from '../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../types/orderBy.type.ts';

type Args = {
  readonly assetsById: Immutable.Map<Uuid, IAsset>;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

export const useMoveSelectedAssetsToCollection = ({
  assetsById,
  orderBy,
  selectedAssets,
}: Args) => {
  const dispatch = useDispatch();

  const moveSelectedAssets = useCallback(
    (targetCollectionId: Uuid | null) =>
      dispatch(
        moveSelectedAssetsToCollection({
          assetsById,
          orderBy,
          selectedAssets,
          targetCollectionId,
        }),
      ),
    [assetsById, orderBy, selectedAssets],
  );

  return moveSelectedAssets;
};
