import { createEnsureSelectedVariantInRouteAction } from '../../../_shared/actions/thunks/ensureSelectedVariantInRoute.ts';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { getPathWithReplacedVariantIdForHomeRoute } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadCollections, loadLanguages } from '../../../data/actions/thunkDataActions.ts';
import { contentVariantIdStorage } from '../../../localStorages/contentVariantStorage.ts';
import { localizedRouteEntered } from './homeActions.ts';

export const ensureSelectedVariantForHome = createEnsureSelectedVariantInRouteAction({
  appName: AppNames.Home,
  loadCollections,
  loadLanguages,
  contentVariantIdStorage,
  getPathWithReplacedVariantId: getPathWithReplacedVariantIdForHomeRoute,
  localizedRouteEntered,
});
