import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadAdministratedSubscriptions,
  loadPlansForSelection,
  loadProjects,
  loadUserProjectsInfo,
} from '../../../../data/actions/thunkDataActions.ts';
import { loadSubscriptionUsage } from '../../shared/actions/thunkSubscriptionManagementActions.ts';
import { changeSubscriptionPlanCreator } from './thunks/changeSubscriptionPlan.ts';

const { subscriptionRepository } = repositoryCollection;

export const changeSubscriptionPlan = changeSubscriptionPlanCreator({
  loadAdministratedSubscriptions,
  loadProjects,
  loadSubscriptionUsage,
  loadUserProjectsInfo,
  subscriptionRepository,
  loadPlansForSelection,
});
