import React from 'react';
import { useContentComponentType } from '../../../../hooks/useContentComponentType.ts';
import {
  ExpandedContentComponent as ExpandedContentComponentComponent,
  IExpandedContentComponentOwnProps,
} from '../../components/expanded/ExpandedContentComponent.tsx';

interface IExpandedContentComponentContainerProps extends IExpandedContentComponentOwnProps {
  readonly contentComponentId: Uuid;
  readonly additionalClassName?: string;
}

export const ExpandedContentComponent: React.FC<IExpandedContentComponentContainerProps> = (
  props,
) => {
  const contentType = useContentComponentType(props.contentComponentId);
  if (!contentType) {
    return null;
  }

  return <ExpandedContentComponentComponent {...props} contentType={contentType} />;
};

ExpandedContentComponent.displayName = 'ExpandedContentComponent';
