import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_SubscriptionRename_Failed,
  Subscription_SubscriptionRename_Finished,
  Subscription_SubscriptionRename_Started,
} from '../constants/actionTypes.ts';

export const subscriptionIsBeingUpdated = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_SubscriptionRename_Started:
      return true;

    case Subscription_SubscriptionRename_Failed:
    case Subscription_SubscriptionRename_Finished:
      return false;

    default:
      return state;
  }
};
