import { Box } from '@kontent-ai/component-library/Box';
import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import { ModalDialog } from '../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export interface IUnsavedChangesDialogProps {
  readonly disabledTooltip?: string | undefined;
  readonly isVisible: boolean;
  readonly isBeingSaved: boolean;
  readonly onClose: () => void;
  readonly onDiscard: () => void;
  readonly onSaveChanges: () => void;
  readonly renderAdditionalContent?: () => ReactNode;
}

export const UnsavedChangesDialog: React.FC<IUnsavedChangesDialogProps> = ({
  disabledTooltip,
  isVisible,
  isBeingSaved,
  onClose,
  onDiscard,
  onSaveChanges,
  renderAdditionalContent,
}) => {
  const additionalContent = renderAdditionalContent?.();

  return (
    <ModalDialog
      headline="Unsaved changes"
      isOpen={isVisible}
      isDismissable
      primaryAction={{
        onClick: onSaveChanges,
        text: isBeingSaved ? 'Saving' : 'Save changes',
        iconBefore: isBeingSaved ? AnimatedProgressIcon : undefined,
        disabled: isBeingSaved || !!disabledTooltip,
        tooltipPlacement: 'top-end',
        tooltipText: disabledTooltip,
        ...getDataUiActionAttribute(DataUiAction.SaveAndNavigate),
      }}
      onClose={onClose}
      cancelAction={{
        onClick: onClose,
        autoFocus: true,
      }}
      extraAction={{
        destructive: true,
        disabled: isBeingSaved,
        destructiveIcon: Icons.Bin,
        onClick: onDiscard,
        text: 'Discard',
        ...getDataUiActionAttribute(DataUiAction.DiscardAndNavigate),
      }}
      shouldCloseOnInteractOutside={() => false}
      {...getDataUiElementAttribute(DataUiElement.UnsavedChangesDialog)}
    >
      <Box width={70 * gridUnit} paddingBottom={Spacing.S}>
        <Stack spacing={additionalContent ? Spacing.XL : Spacing.None}>
          <p>Looks like you’ve changed a few things.</p>
          {additionalContent}
          <p>Should we save the changes or discard them?</p>
        </Stack>
      </Box>
    </ModalDialog>
  );
};

UnsavedChangesDialog.displayName = 'UnsavedChangesDialog';
