import React, { memo } from 'react';
import {
  DataUiElement,
  DataUiInput,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EditorProps,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { UnlimitedEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { DiffPlugin } from '../../plugins/diff/DiffPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { useComposition as useViewerComposition } from './RichTextViewer.tsx';

const useComposition = () => usePluginComposition(useViewerComposition(), DiffPlugin);

export type RichTextDiffProps = EditorProps<typeof useComposition>;

export const RichTextDiff: React.FC<RichTextDiffProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    ...props,
    dataUiInput: props.dataUiInput ?? DataUiInput.ItemTextElement,
    dataUiElement: props.dataUiElement ?? DataUiElement.RichTextViewer,
    keyCommandMap: textKeyCommandMap,
    limitations: UnlimitedEditorLimitations,
    tabIndex: props.tabIndex ?? -1,
  });
});

RichTextDiff.displayName = 'RichTextDiff';
