import { memoize } from '@kontent-ai/memoization';
import { digestToBuffer } from '../../../../../_shared/utils/crypto/digest.ts';
import { startsWithDigit } from '../../../../../_shared/utils/stringUtils.ts';

const byteToHex = [...new Array(256).keys()].map((_, i) => (i + 0x100).toString(16).substr(1));

function hexFromBytes(data: ReadonlyArray<number>): string {
  return data.map((byte) => byteToHex[byte]).join('');
}

function dotNetUuidFromBytes(data: Uint8Array): Uuid {
  const dataArray = [...data];
  const segments = [
    // Microsoft historically uses Guid v2 so we need to convert the first three segments to little-endian by reversing them
    // See https://en.wikipedia.org/wiki/Universally_unique_identifier
    dataArray
      .slice(0, 4)
      .reverse(),
    dataArray.slice(4, 6).reverse(),
    dataArray.slice(6, 8).reverse(),
    dataArray.slice(8, 10),
    dataArray.slice(10),
  ];
  return segments.map(hexFromBytes).join('-');
}

// Logic replicated from method GetComponentUniqueId in
// https://kenticocloud.visualstudio.com/Kontent/_git/draft-backend?path=%2FService.ConsistencyCheck%2VFChecks%2VFContentComponentItemsCheck.cs
export const getDeliveryContentComponentId = memoize.maxN(
  async (
    contentItemId: Uuid,
    variantId: Uuid,
    rootRichTextElementId: Uuid,
    contentComponentId: Uuid,
  ): Promise<Uuid> => {
    const input = `${contentItemId}${variantId}${rootRichTextElementId}${contentComponentId}`;
    const hashBuffer = await digestToBuffer('SHA-1', input);
    const data = new Uint8Array(hashBuffer.slice(0, 16));
    data[7] = 0x01;

    return dotNetUuidFromBytes(data);
  },
  1_000,
);

export const getDeliveryContentComponentCodename = (deliveryContentComponentId: Uuid): string => {
  const codename = deliveryContentComponentId.replace(/-/g, '_');
  return startsWithDigit(codename) ? `n${codename}` : codename;
};
