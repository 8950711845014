import { Collection } from '@kontent-ai/utils';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapabilityInLanguage } from '../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';

export const getAvailableCollectionsForContentItem = (
  selectedLanguageId: string,
  state: IStore,
): ReadonlyArray<ICollection> =>
  filterCollectionsByCapabilityInLanguage(
    Collection.getValues(state.data.collections.byId),
    selectedLanguageId,
    Capability.CreateContent,
    state.sharedApp.currentProjectId,
    state.data.user,
  );
