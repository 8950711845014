import { Button } from '@kontent-ai/component-library/Button';
import { FC, useState } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FlexingTile } from '../AssetTile/FlexingTile.tsx';
import { AssetFolderNameDialog, AssetFolderState } from './AssetFolderNameDialog.tsx';

export interface IAssetCreateNewFolderDispatchProps {
  readonly onCreate: (name: string) => void;
}

type Props = IAssetCreateNewFolderDispatchProps;

export const AssetCreateNewFolder: FC<Props> = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = (): void => setShowDialog(true);
  const closeDialog = (): void => setShowDialog(false);

  const createFolder = (name: string): void => {
    props.onCreate(name);
    closeDialog();
  };

  return (
    <>
      <FlexingTile>
        <Button
          buttonStyle="tertiary"
          buttonDisplay="block"
          onClick={openDialog}
          {...getDataUiActionAttribute(DataUiAction.CreateFolder)}
        >
          <Icon iconName={IconName.Plus} className="btn__icon" />
          Create new folder
        </Button>
      </FlexingTile>
      {showDialog && (
        <AssetFolderNameDialog
          onConfirm={createFolder}
          onCancel={closeDialog}
          state={AssetFolderState.New}
        />
      )}
    </>
  );
};

AssetCreateNewFolder.displayName = 'AssetCreateNewFolder';
