import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../../_shared/components/NotificationBar.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import {
  IContentItemOverwritten,
  IContentItemOverwrittenDifference,
} from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ContentItemChangeReason } from '../../../../../models/contentItem/ContentItemChangeReason.type.ts';
import {
  getKeysOfDetectedDifferences,
  itemOverwriteCanAutoRefresh,
} from '../../../../../utils/itemOverwriteUtils.ts';
import { OverwriteNotificationBarMessage } from './OverwriteNotificationBarMessage.tsx';

const defaultIcon = IconName.Edit;

const changeReasonIcons: ReadonlyRecord<ContentItemChangeReason, IconName> = {
  [ContentItemChangeReason.Archive]: IconName.Bin,
  [ContentItemChangeReason.DiscardVersion]: IconName.Bin,
  [ContentItemChangeReason.NewVersion]: IconName.Doc,
  [ContentItemChangeReason.Publish]: IconName.PaperPlane,
  [ContentItemChangeReason.RestoreRevision]: IconName.Clock,
  [ContentItemChangeReason.Unpublish]: IconName.ArrowULeft,
  [ContentItemChangeReason.Update]: IconName.Edit,
};

const isKnownChangeReason = (
  changeReason: string,
): changeReason is keyof typeof changeReasonIcons =>
  (Object.keys(changeReasonIcons) as ReadonlyArray<string>).includes(changeReason);

const updateReasonIcons: Record<keyof IContentItemOverwrittenDifference, IconName> = {
  assignees: IconName.Users,
  collection: IconName.Edit,
  codename: IconName.BracesOctothorpe,
  dueDate: IconName.Calendar,
  name: IconName.Edit,
  note: IconName.Edit,
  publishScheduleTime: IconName.CalendarNumber,
  sitemap: IconName.ParentChildrenScheme3,
  unpublishScheduleTime: IconName.CalendarNumber,
  workflowStep: IconName.ArrowDoubleRight,
};

const getIconName = (changeContent: ExtendedContentChange): IconName => {
  if (changeContent.changeReason === ContentItemChangeReason.Update) {
    const diffKeys = getKeysOfDetectedDifferences(changeContent.difference);
    const firstKey = Collection.getFirst(diffKeys);
    if (diffKeys.length !== 1 || firstKey === null) {
      return defaultIcon;
    }
    return updateReasonIcons[firstKey] || defaultIcon;
  }

  return isKnownChangeReason(changeContent.changeReason)
    ? changeReasonIcons[changeContent.changeReason]
    : defaultIcon;
};

export type ExtendedContentChange = IContentItemOverwritten & {
  readonly changeBySelf: boolean;
};

export interface IOverwriteNotificationBarStateProps {
  readonly changeByUser?: IProjectContributor;
  readonly contentChange: ExtendedContentChange;
}

interface IOverwriteNotificationBarDispatchProps {
  readonly onClose: () => void;
  readonly onDisplay: (changeContent: ExtendedContentChange) => void;
  readonly onRefresh: (changeContent: ExtendedContentChange) => void;
}

interface IOverwriteNotificationBarProps
  extends IOverwriteNotificationBarStateProps,
    IOverwriteNotificationBarDispatchProps {
  readonly overwrittenLanguageName?: string;
}

const RefreshButton: React.FC<IOverwriteNotificationBarProps> = ({ contentChange, onRefresh }) => (
  <Button
    size="small"
    buttonStyle="primary"
    onClick={() => onRefresh(contentChange)}
    {...getDataUiActionAttribute(DataUiAction.Refresh)}
  >
    <Button.Icon icon={Icons.RotateDoubleRight} />
    Refresh item
  </Button>
);

export const OverwriteNotificationBar: React.FC<IOverwriteNotificationBarProps> = (props) => {
  const { onClose, onDisplay, contentChange, overwrittenLanguageName } = props;
  React.useEffect(() => {
    onDisplay(contentChange);
  }, [onDisplay, contentChange]);

  const iconName = getIconName(contentChange);
  const canAutoRefresh = itemOverwriteCanAutoRefresh(contentChange);
  const isItemDeleted = contentChange.changeReason === ContentItemChangeReason.Archive;
  const showRefreshButton = !canAutoRefresh && !isItemDeleted;

  return (
    <NotificationBar
      iconName={iconName}
      onClosePanel={canAutoRefresh ? onClose : undefined}
      type={NotificationBarType.InfoLight}
      message={
        <OverwriteNotificationBarMessage
          {...props}
          {...getDataUiElementAttribute(DataUiElement.NotificationText)}
          overwrittenLanguageName={overwrittenLanguageName}
          showRefreshButtonPrefix={showRefreshButton}
        />
      }
      uiElement={DataUiElement.NotificationBarItemOverwriteInfo}
      tools={showRefreshButton && <RefreshButton {...props} />}
    />
  );
};

OverwriteNotificationBar.displayName = 'OverwriteNotificationBar';
