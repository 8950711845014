import React from 'react';
import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ClickableWorkflowSection } from '../../../containers/details/Workflow/SectionTypes/ClickableWorkflowSection.tsx';
import { ReadonlyWorkflowSection } from '../../../containers/details/Workflow/SectionTypes/ReadonlyWorkflowSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

export interface IWorkflowSectionProps {
  readonly sectionType: SectionType;
}

export const WorkflowSection: React.FC<IWorkflowSectionProps> = ({ sectionType }) => (
  <Section
    ClickableSectionComponent={ClickableWorkflowSection}
    NonClickableSectionComponent={() => null}
    ReadonlySectionComponent={ReadonlyWorkflowSection}
    title="Workflow"
    type={sectionType}
    uiObjectNameAttribute={ItemDetailSection.WorkflowSection}
  />
);

WorkflowSection.displayName = 'WorkflowSection';
