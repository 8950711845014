import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { filterOutNullishOrEmpty } from '../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { isEmptyOrWhitespace } from '../../../../../../_shared/utils/stringUtils.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { getCommentedTextSelector } from '../../../../utils/commentUtils.ts';

const CommentHighlightStyles = createGlobalStyle<{ readonly styles: string }>`
  ${(props) => props.styles}
`;

const getStyles = (commentThreads: ReadonlyArray<ICommentThread>, color: string): string => {
  if (commentThreads.length === 0) {
    return '';
  }

  const commentsCssSelectors = filterOutNullishOrEmpty(
    commentThreads.map(getCommentedTextSelector),
  );

  return `${commentsCssSelectors.join(',')} {
    z-index: var(--z-200);
    background-color: ${color};
  }`;
};

export interface ICommentThreadHighlighterProps {
  readonly commentThreads: ReadonlyArray<ICommentThread>;
  readonly suggestionThreads: ReadonlyArray<ICommentThread>;
}

export const CommentThreadHighlighter: React.FC<ICommentThreadHighlighterProps> = ({
  commentThreads,
  suggestionThreads,
}) => {
  const styles = `
${getStyles(commentThreads, 'var(--comment-bg-color)')}
${getStyles(suggestionThreads, 'var(--suggestion-bg-color)')}
`;

  if (isEmptyOrWhitespace(styles)) {
    return null;
  }

  return <CommentHighlightStyles styles={styles} />;
};

CommentThreadHighlighter.displayName = 'CommentThreadHighlighter';
