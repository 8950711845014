import { EditorState } from 'draft-js';
import { ClipboardEvent } from 'react';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IContentComponent } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { ensureContentConsistency } from '../../../utils/consistency/editorConsistencyUtils.ts';
import { ObjectDataType } from '../../../utils/export/html/elements/objects.ts';
import { extractSelectedContent } from '../../../utils/general/editorContentUtils.ts';
import { IConvertRichTextToClipboardHtml } from './createConvertRichTextToClipboardHtml.ts';

export const EmptyMetadata = new Map<ObjectDataType, Uuid>();

export type ISetRichTextClipboardAction = (
  e: ClipboardEvent,
  editorState: EditorState,
  contentComponents: ReadonlyMap<Uuid, IContentComponent>,
  metadata: ReadonlyMap<ObjectDataType, Uuid>,
) => ThunkFunction;

interface IDeps {
  readonly convertRichTextToClipboardHtml: IConvertRichTextToClipboardHtml;
}

export const createSetRichTextClipboardAction =
  (deps: IDeps): ISetRichTextClipboardAction =>
  (e, editorState, contentComponents, metadata) =>
  (dispatch): void => {
    const selection = editorState.getSelection();

    if (selection.getHasFocus() && !selection.isCollapsed()) {
      const clipBoardData = e.clipboardData;
      if (clipBoardData) {
        const content = editorState.getCurrentContent();

        const selectedContent = extractSelectedContent(content, selection);
        const consistentContent = ensureContentConsistency(selectedContent);

        const convertedContentState = dispatch(
          deps.convertRichTextToClipboardHtml(consistentContent, contentComponents, metadata),
        );

        clipBoardData.setData('text/plain', convertedContentState.plainText);
        clipBoardData.setData('text/html', convertedContentState.html);

        // This is necessary to prevent the current document selection from
        // being written to the clipboard.
        e.preventDefault();
      }
    }
  };
