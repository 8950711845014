import { Box } from '@kontent-ai/component-library/Box';
import { SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  colorTextDefault,
  colorTextLowEmphasis,
  colorWarningText,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { getFormattedDueDate } from '../../../../../../_shared/components/DueDate/utils/getFormattedDueDate.ts';

interface ITableCellDueDateProps {
  readonly dueDate: DateTimeStamp | null;
}

// TODO: This component should be removed after finishing KCL-11582. Read the task description for more info
export const TableCellDueDate: React.FC<ITableCellDueDateProps> = ({ dueDate }) => {
  const { almostDue, dueDateFuzzy, dueDateReadable, overdue } = getFormattedDueDate(dueDate);
  const dueDateColorByDueDateType = almostDue
    ? colorWarningText
    : dueDateReadable === null
      ? colorTextLowEmphasis
      : colorTextDefault;

  return overdue ? (
    <SimpleStatusError label={dueDateFuzzy} tooltipText={dueDateReadable || undefined} />
  ) : (
    <Tooltip tooltipText={dueDateReadable || undefined} placement="top">
      <Box color={dueDateColorByDueDateType}>{dueDateFuzzy}</Box>
    </Tooltip>
  );
};

TableCellDueDate.displayName = 'TableCellDueDate';
