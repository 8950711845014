import Immutable from 'immutable';
import {
  getExistingAssignedAssetsWithRenditions,
  isEveryImageResponsive,
  validateFileSizeForAssets,
  validateHeightForAssets,
  validateWidthForAssets,
} from '../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { convertFileSizeToBytes } from '../../../../../../_shared/utils/fileSizeUtils.ts';
import { validateNumberOfItems } from '../../../../../../_shared/utils/validation/limitations/validateNumberOfItems.ts';
import { IAssetRendition } from '../../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { defaultAssetFileTypeOption } from '../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { IAssetTypeElementData } from '../../../models/elements/AssetTypeElementData.ts';
import {
  getFloatFromStringOrNull,
  getIntegerFromStringOrNull,
} from '../../typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { hasAssetDefaultValue } from '../../typeElementDefaultValueUtils.ts';

export const areLimitationsInAssetDefaultValueMet = (
  typeElement: IAssetTypeElementData,
  loadedAssets: Immutable.Map<Uuid, IAsset>,
  loadedAssetRenditions: ReadonlyMap<Uuid, IAssetRendition>,
): boolean => {
  if (!hasAssetDefaultValue(typeElement)) {
    return true;
  }

  const minItems = getIntegerFromStringOrNull(typeElement.minItems);
  const maxItems = getIntegerFromStringOrNull(typeElement.maxItems);
  const fileSize = getFloatFromStringOrNull(typeElement.fileSize);
  const fileSizeUnit = typeElement._fileSizeUnitOption;
  const fileSizeInBytes =
    fileSize && fileSize > 0 && fileSizeUnit
      ? convertFileSizeToBytes(fileSize, fileSizeUnit)
      : null;
  const assignedAssets = getExistingAssignedAssetsWithRenditions(
    typeElement.defaultValue,
    loadedAssets,
    loadedAssetRenditions,
    false,
  );
  const minWidth = getIntegerFromStringOrNull(typeElement.minWidth);
  const maxWidth = getIntegerFromStringOrNull(typeElement.maxWidth);
  const minHeight = getIntegerFromStringOrNull(typeElement.minHeight);
  const maxHeight = getIntegerFromStringOrNull(typeElement.maxHeight);

  const isNumberOfItemsLimitMet = validateNumberOfItems(minItems, maxItems, assignedAssets.length);
  const isFileSizeLimitMet = validateFileSizeForAssets(assignedAssets, fileSizeInBytes);
  const isResponsiveImageTypeLimitMet =
    typeElement.fileType === defaultAssetFileTypeOption || isEveryImageResponsive(assignedAssets);
  const isWidthLimitMet = validateWidthForAssets(assignedAssets, minWidth, maxWidth);
  const isHeightLimitMet = validateHeightForAssets(assignedAssets, minHeight, maxHeight);

  return (
    isNumberOfItemsLimitMet &&
    isFileSizeLimitMet &&
    isResponsiveImageTypeLimitMet &&
    isWidthLimitMet &&
    isHeightLimitMet
  );
};
