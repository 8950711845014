import { assert } from '@kontent-ai/utils';

const separator = '|';

export type TaxonomyFilterItemId = `${Uuid}${typeof separator}${Uuid}`;

export function createTaxonomyFilterItemId(groupId: Uuid, termId: Uuid): TaxonomyFilterItemId {
  return `${groupId}${separator}${termId}`;
}

export function getGroupId(taxonomyFilterId: TaxonomyFilterItemId): Uuid {
  const [groupId] = splitTaxonomyFilterItemId(taxonomyFilterId);
  return groupId;
}

export function getTermId(taxonomyFilterId: TaxonomyFilterItemId): Uuid {
  const [, termId] = splitTaxonomyFilterItemId(taxonomyFilterId);
  return termId;
}

export function splitTaxonomyFilterItemId(
  taxonomyFilterId: TaxonomyFilterItemId,
): readonly [groupId: Uuid, termId: Uuid] {
  const [groupId, termId] = taxonomyFilterId.split(separator);
  assert(groupId && termId, () => `${__filename}: Invalid taxonomyFilterId encountered`);
  return [groupId, termId];
}

export function isTaxonomyFilterItemId(id: string): id is TaxonomyFilterItemId {
  return id.includes(separator);
}
