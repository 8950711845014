import React, { RefObject, useCallback } from 'react';
import { useClickOutsideCallbackState } from '../../../../../../../_shared/hooks/useClickOutsideCallbackState.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { EditingAction } from '../../../models/EditingAction.ts';
import { doesActionRepresentMenuWithMultipleOptions } from '../../../utils/editingActions/doesActionRepresentMenuWithMultipleOptions.ts';
import { EditingLeafActionForm } from '../EditingLeafActionForm.tsx';
import { QuickActionsButtonToActionMapper } from './QuickActionsButtonToActionMapper.tsx';
import { QuickActionsDropDownMenu } from './QuickActionsDropDownMenu.tsx';
import { QuickActionsLeafMenuWrapper } from './QuickActionsLeafMenuWrapper.tsx';

type QuickActionProps = {
  readonly activatedAction: EditingAction;
  readonly action: EditingAction;
  readonly onClickOutside: () => void;
};

export const QuickAction: React.FC<QuickActionProps> = (props) => {
  const [handleClickOutside, shouldCloseOnClickOutside, useCustomHandleClickOutside] =
    useClickOutsideCallbackState({ initialOnClickOutside: props.onClickOutside });

  const leafForm = (
    <EditingLeafActionForm
      actionOrigin={ContentItemEditingEventOrigins.QuickActions}
      activatedAction={props.activatedAction}
      useCustomHandleClickOutside={useCustomHandleClickOutside}
    />
  );

  const shouldRenderLeafFormInPopover = leafForm && props.activatedAction === props.action;

  const renderQuickActionsButton = useCallback(
    (ref: RefObject<HTMLButtonElement> | undefined): JSX.Element => (
      <QuickActionsButtonToActionMapper
        action={props.action}
        actionOrigin={ContentItemEditingEventOrigins.QuickActions}
        ref={ref}
        isActive={shouldRenderLeafFormInPopover}
      />
    ),
    [props.action, shouldRenderLeafFormInPopover],
  );

  if (!shouldRenderLeafFormInPopover) {
    return renderQuickActionsButton(undefined);
  }

  if (doesActionRepresentMenuWithMultipleOptions(props.activatedAction)) {
    return (
      <QuickActionsDropDownMenu
        activatedAction={props.activatedAction}
        onClickOutside={handleClickOutside}
        renderButton={renderQuickActionsButton}
      >
        {leafForm}
      </QuickActionsDropDownMenu>
    );
  }

  return (
    <QuickActionsLeafMenuWrapper
      onClickOutside={handleClickOutside}
      renderButton={renderQuickActionsButton}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
    >
      {leafForm}
    </QuickActionsLeafMenuWrapper>
  );
};

QuickAction.displayName = 'QuickAction';
