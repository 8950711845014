import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ListingMessage } from '../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { UserProjectSettings } from '../../containers/UserDetail/UserProjectSettings.tsx';
import { ProjectSettingsSearchResult } from '../../utils/userProjectSettingsFilterUtils.ts';

type UserSubscriptionSettingsProps = {
  readonly userId: Uuid;
  readonly searchResults: ReadonlyArray<ProjectSettingsSearchResult>;

  readonly onNavigateBack: () => void;
};

export const UserSubscriptionSettings: React.FC<UserSubscriptionSettingsProps> = ({
  searchResults,
  userId,
  onNavigateBack,
}) => {
  const isUserInSingleProject = searchResults.length === 1;
  return (
    <Stack spacing={Spacing.L}>
      <ListingMessage
        statusInfoMessage={{
          style: StatusMessageStyle.Normal,
          text: `Roles in ${pluralizeWithCount('project', searchResults.length)}`,
        }}
      />
      <Stack>
        {searchResults.map((searchResult) => (
          <UserProjectSettings
            key={searchResult.project.projectId}
            searchResult={searchResult}
            userId={userId}
            isSingleProject={isUserInSingleProject}
            onNavigateBack={onNavigateBack}
          />
        ))}
      </Stack>
    </Stack>
  );
};

UserSubscriptionSettings.displayName = 'UserSubscriptionSettings';
