import React from 'react';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ColumnSettingsSource } from '../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { orderContentItems } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTableHead } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/ContentItemScrollTableHead.tsx';
import { getContentItemListingOrderBy } from '../../../../contentInventory/content/reducers/listingUi/selectors/getContentItemListingOrderBy.ts';
import { getContentItemListingHiddenColumns } from '../../../../contentInventory/content/selectors/getContentItemListingHiddenColumns.ts';

type Props = {
  readonly onItemOrderChanged: () => void;
};

export const ContentItemSelectorScrollTableHead: React.FC<Props> = ({ onItemOrderChanged }) => {
  const hiddenColumns = useSelector(getContentItemListingHiddenColumns);
  const contentItemListingOrderBy = useSelector(getContentItemListingOrderBy);
  const dispatch = useDispatch();

  const onColumnHeadClicked = (columnCode: ItemColumnCode) => {
    dispatch(orderContentItems(columnCode, false));
    onItemOrderChanged();
  };

  return (
    <ContentItemScrollTableHead
      contentListingOrderBy={contentItemListingOrderBy}
      hiddenColumns={hiddenColumns}
      onColumnHeadClicked={onColumnHeadClicked}
      renderOpenItemInNewTab
      source={ColumnSettingsSource.ModalItemSelector}
    />
  );
};
