import { ComponentProps } from 'react';
import { DefaultLanguageId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { AiReviewAction as AiReviewActionComponent } from '../components/AiReviewAction.tsx';

type Props = Omit<ComponentProps<typeof AiReviewActionComponent>, 'isDefaultLanguage'>;

export const AiReviewAction = (props: Props) => {
  const isDefaultLanguage = useSelector(
    (state) => getSelectedLanguageId(state) === DefaultLanguageId,
  );

  return (
    <AiReviewActionComponent
      {...props}
      perform={props.perform}
      isDefaultLanguage={isDefaultLanguage}
    />
  );
};
