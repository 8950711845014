import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  Data_AiGuidelines_Failed,
  Data_AiGuidelines_Success,
} from '../../../../../data/constants/dataActionTypes.ts';
import {
  AiGuidelines_Creating_Finished,
  AiGuidelines_Updating_Finished,
} from '../../../contentModels/aiGuidelines/constants/aiGuidelinesActionTypes.ts';
import { AiGuidelines, createAiGuidelinesFromServerModel } from '../../models/aiGuidelines.ts';

type AiGuidelinesMap = ReadonlyMap<Uuid, AiGuidelines>;
const initialState: AiGuidelinesMap = new Map<Uuid, AiGuidelines>();

export const byId = (state = initialState, action: Action): AiGuidelinesMap => {
  switch (action.type) {
    case Data_AiGuidelines_Success: {
      const aiGuidelinesMap = action.payload.data.map((aiGuidelinesServerModel) => {
        const aiGuidelines = createAiGuidelinesFromServerModel(aiGuidelinesServerModel);
        return [aiGuidelines.id, aiGuidelines] as const;
      });

      return new Map(aiGuidelinesMap);
    }

    case AiGuidelines_Creating_Finished: {
      const aiGuidelines = createAiGuidelinesFromServerModel(action.payload.aiGuidelines);

      return Collection.add(state, [aiGuidelines.id, aiGuidelines]);
    }

    case AiGuidelines_Updating_Finished: {
      const aiGuidelines = createAiGuidelinesFromServerModel(action.payload.aiGuidelines);

      return Collection.replaceWith(state, aiGuidelines.id, () => aiGuidelines);
    }

    case Shared_CurrentProjectId_Updated:
    case Data_AiGuidelines_Failed:
      return initialState;

    default:
      return state;
  }
};
