import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isAnyItemEditorMutationActive } from '../../safeRedirect/selectors/isAnyItemEditorMutationActive.ts';
import { useAssetUploadProgress } from '../hooks/useAssetUploadProgress.ts';
import { usePendingDialog } from '../hooks/usePendingDialog.ts';
import { IPendingChangesDialogProps } from '../types/IPendingChangesDialogProps.type.ts';
import { PendingStatus } from './PendingStatus.tsx';
import { PendingStatusAlign } from './PendingStatusAlign.tsx';

export const AssetsUploadingDialog: React.FC<IPendingChangesDialogProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { isPending, showDialog, hasError } = usePendingDialog(
    useSelector(isAnyItemEditorMutationActive),
    onConfirm,
  );

  const { progress, someAssetFailed } = useAssetUploadProgress();

  const displayProgress = Math.min(
    maximumDisplayedProgressPercent,
    Math.round(progress ?? maximumDisplayedProgressPercent),
  );

  if (!showDialog) {
    return null;
  }

  const uploadHasError = hasError || someAssetFailed;

  const body = (
    <Stack spacing={Spacing.XL}>
      <PendingStatusAlign>
        <PendingStatus
          errorLabel="Upload failed. You need to return to the content item"
          finishedLabel="Upload finished"
          hasError={uploadHasError}
          isPending={isPending}
          pendingLabel={`Upload in progress (${displayProgress}%)`}
        />
      </PendingStatusAlign>
      Please wait until your assets finish uploading.
      <br />
      If you leave before uploading is finished, some of the assets won’t be linked to this content
      item.
    </Stack>
  );

  const disabledButtonTooltip = uploadHasError
    ? 'Upload has failed, you need to return to the content item.'
    : 'The assets have finished uploading, you need to return to the content item.';

  const isLeaveAvailable = isPending && !uploadHasError;
  const footerLeft = (
    <Button
      buttonStyle="secondary"
      destructive
      destructiveIcon={Icons.Bin}
      disabled={!isLeaveAvailable}
      tooltipText={isLeaveAvailable ? undefined : disabledButtonTooltip}
      onClick={onConfirm}
      {...getDataUiActionAttribute(DataUiAction.DiscardAndNavigate)}
    >
      Leave and unlink assets
    </Button>
  );

  const footerRight = (
    <Button autoFocus buttonStyle="primary" onClick={onCancel}>
      Return to the content item
    </Button>
  );

  return (
    <ModalDialog
      dataUiElement={DataUiElement.UnsavedChangesDialog}
      headerContent="Uploading assets"
      bodyContent={body}
      footerContentLeft={footerLeft}
      footerContentRight={footerRight}
      onClose={onCancel}
    />
  );
};

AssetsUploadingDialog.displayName = 'AssetsUploadingDialog';

const maximumDisplayedProgressPercent = 99;
