import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventData } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  AuditLog_Search_Finished,
  AuditLog_Search_Started,
} from '../../constants/auditLogActionTypes.ts';

interface IDeps {
  readonly loadAuditEvents: (
    scrollPositionChanged?: boolean,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventData;
}

const started = () =>
  ({
    type: AuditLog_Search_Started,
  }) as const;

const finished = () =>
  ({
    type: AuditLog_Search_Finished,
  }) as const;

export type SearchEventsActionsType = ReturnType<typeof started | typeof finished>;

export const createSearchAuditEventsAction =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    await dispatch(deps.loadAuditEvents(false, abortSignal));

    dispatch(deps.trackUserEvent(TrackedEvent.AuditLogSearched));
    dispatch(finished());
  };
