import {
  Dispatch,
  GetState,
  ThunkFunction,
} from '../../../../../../../../@types/Dispatcher.type.ts';
import { Data_ListingContentItems_FullTextSearchTurnedOn } from '../../../../../../../../data/constants/dataActionTypes.ts';
import { IFullTextSearchContextStorage } from '../../../../../../../../localStorages/fullTextSearchContextStorage.ts';
import { ListingContext } from '../../../../../../../../localStorages/listingOrderByStorage.ts';
import { notSpecifiedContentListingOrderBy } from '../../../../../reducers/listingUi/reducers/orderBy.ts';
import { IFullTextSearchContext } from '../../../../../stores/IContentAppStoreState.ts';
import { UpdateListingOrderBy } from '../updateListingOrderBy.ts';

interface IFullTextSearchTurnedOnPayload {
  readonly fullTextSearchContext: IFullTextSearchContext;
}

const fullTextSearchTurnedOn = (payload: IFullTextSearchTurnedOnPayload) =>
  ({
    type: Data_ListingContentItems_FullTextSearchTurnedOn,
    payload,
  }) as const;

export type HandleFullTextSearchTurnedOnActionsType = ReturnType<typeof fullTextSearchTurnedOn>;

interface IDeps {
  readonly fullTextSearchContextStorage: IFullTextSearchContextStorage;
  readonly updateListingOrderBy: UpdateListingOrderBy;
}

export const createHandleFullTextSearchTurnedOnAction =
  (deps: IDeps) =>
  (isInDialog: boolean): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const {
      contentApp: {
        listingUi: { orderBy },
      },
      sharedApp: { currentProjectId },
    } = getState();

    const updatedFullTextSearchContext = { previousOrderBy: orderBy };

    dispatch(
      fullTextSearchTurnedOn({
        fullTextSearchContext: updatedFullTextSearchContext,
      }),
    );

    dispatch(deps.updateListingOrderBy(notSpecifiedContentListingOrderBy, !isInDialog));

    if (isInDialog) {
      return;
    }

    deps.fullTextSearchContextStorage.save(
      updatedFullTextSearchContext,
      ListingContext.ContentItemInventory,
      currentProjectId,
    );
  };
