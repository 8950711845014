import { Action } from '../../../../../../@types/Action.type.ts';
import { NewContentItem_Initialized } from '../../constants/newContentItemActionTypes.ts';
import { IContentTypeConfig } from '../INewContentItemStoreState.type.ts';

const initialState: IContentTypeConfig = {
  allowedContentTypeIds: null,
  recentlyUsedContentTypeIds: null,
  suggestedContentTypeIds: [],
};

export const contentType = (state = initialState, action: Action): IContentTypeConfig => {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.contentTypeSelectorConfig;

    default:
      return state;
  }
};
