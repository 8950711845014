import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_PromoteAdmin_Finished,
} from '../../../../applications/subscriptionManagement/Admins/constants/actionTypes.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SubscriptionUsers_Loaded,
} from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';
import { ISubscriptionAdmin } from '../../../../applications/subscriptionManagement/shared/models/SubscriptionAdmin.ts';
import { ISubscriptionUser } from '../../../../applications/subscriptionManagement/shared/models/SubscriptionUserInfo.ts';
import { createProjectContributor } from '../../../models/users/ProjectContributor.ts';

export const subscriptionUsers = (
  state = Immutable.List<ISubscriptionUser>(),
  action: Action,
): Immutable.List<ISubscriptionUser> => {
  switch (action.type) {
    case Subscription_SubscriptionUsers_Loaded:
      return action.payload.subscriptionUsers;

    case Subscription_PromoteAdmin_Finished: {
      const promotedUserIds = action.payload.promotedUsers.map(
        (user: ISubscriptionAdmin) => user.userId,
      );
      return state
        .filterNot((user: ISubscriptionUser) => promotedUserIds.includes(user.userId))
        .toList();
    }

    case Subscription_AdminListing_DeleteAdminsFinished: {
      const deletedAdmins = action.payload.deletedAdmins;
      const usersToPromote = deletedAdmins
        .filter((a) => !!a && !a.isInvitationPending)
        .map((admin) => createProjectContributor(admin));
      return state.concat(usersToPromote).toList();
    }

    case Subscription_SelectSubscription:
      return Immutable.List();

    default:
      return state;
  }
};
