import React, { useMemo } from 'react';

type ContextState = {
  readonly root: Element | null;
};

const defaultContext: ContextState = {
  root: null,
};

export const IntersectionObserverRootContext = React.createContext<ContextState>(defaultContext);

interface ContextProps {
  readonly rootRef: React.RefObject<HTMLElement>;
}

export const IntersectionObserverRootContextProvider: React.FC<
  React.PropsWithChildren<ContextProps>
> = ({ children, rootRef }) => {
  const rootElement = rootRef.current;

  const contextState = useMemo<ContextState>(() => ({ root: rootElement }), [rootElement]);

  return (
    <IntersectionObserverRootContext.Provider value={contextState}>
      {children}
    </IntersectionObserverRootContext.Provider>
  );
};

IntersectionObserverRootContextProvider.displayName = 'IntersectionObserverRootContextProvider';
