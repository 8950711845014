import classNames from 'classnames';
import { memo, useContext, useEffect, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import { ScrollContainerContextProvider } from '../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { ScrollOptionsContextProvider } from '../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import {
  EditorPaperContext,
  EditorPaperContextProvider,
  useUpdatePaperContext,
} from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import {
  EditorScrollContextDefaultGracePeriodMs,
  EditorScrollContextProvider,
} from '../../../../../_shared/contexts/EditorScrollContext.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import { useFixAutoCompleteOnScroll } from '../../../../../_shared/hooks/useFixAutoCompleteOnScroll.ts';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getReachedPaperThresholds } from '../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { untitledContentItem } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { EditedContentItemContextProvider } from '../../ContentComponent/context/ContentItemContext.tsx';
import { ItemEditorScrollOptions } from '../constants/uiConstants.ts';
import { ContentItemElements } from '../containers/ContentItemElements.tsx';
import { ContentItemHeader } from '../containers/ContentItemHeader.tsx';
import { ContentItemNotifications } from '../containers/ContentItemNotifications.tsx';
import { ElementLocksObserver } from '../containers/ElementLocksObserver.tsx';
import { DiscussionsSidebar } from '../containers/comments/DiscussionsSidebar.tsx';
import { InlineCommentPane } from '../containers/comments/InlineCommentPane.tsx';
import { ResolvedCommentsSidebar } from '../containers/comments/ResolvedCommentsSidebar.tsx';
import { EditingActions } from '../containers/editingActions/EditingActions.tsx';
import { ContentItemSidebar } from '../containers/sidebar/ContentItemSidebar.tsx';
import { ContentItemChangeIntercomTracker } from './ContentItemChangeIntercomTracker.tsx';
import { ContentItemSidebarPlaceholder } from './sidebar/ContentItemSidebarPlaceholder.tsx';

type ContentItemEditorPaneProps = {
  readonly disabled: boolean;
  readonly onInit: () => void;
  readonly onReposition: (pane: HTMLDivElement) => void;
  readonly onScroll: (scrollTop: number) => void;
  readonly shouldRenderSidebar: boolean;
};

const ContentItemEditorPane = ({
  disabled,
  onInit,
  onReposition,
  onScroll,
  shouldRenderSidebar,
}: ContentItemEditorPaneProps) => {
  const contentItemPaneRef = useRef<HTMLDivElement>(null);
  const contentItemPaperRef = useRef<HTMLDivElement>(null);
  const contentItemCanvasRef = useRef<HTMLDivElement>(null);

  const isContentItemCanvasScrollable = contentItemCanvasRef.current
    ? contentItemCanvasRef.current.offsetWidth > contentItemCanvasRef.current.clientWidth // this works only if the canvas has no borders — KCL-8777
    : false;

  useFixAutoCompleteOnScroll(contentItemCanvasRef);

  // Do not render item content until paper context is updated because anything using it would rerender after it initializes
  // this mainly causes performance problems in case of many linked items
  const { isEditorPaperSet: canRenderPaperContent } = useUpdatePaperContext(contentItemPaperRef);

  const throttledOnScroll = useThrottledCallback(
    () => contentItemCanvasRef.current && onScroll(contentItemCanvasRef.current?.scrollTop),
    EditorScrollContextDefaultGracePeriodMs,
  );
  const memoizedRepositionHandler = useThrottledCallback(() => {
    if (onReposition && contentItemPaneRef.current) {
      onReposition(contentItemPaneRef.current);
    }
  }, EditorScrollContextDefaultGracePeriodMs);

  useResizeObserver({
    ref: contentItemCanvasRef,
    onResize: memoizedRepositionHandler,
  });

  useEffect(() => {
    onInit();
  }, [onInit]);

  const paperContext = useContext(EditorPaperContext);
  const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

  return (
    <IntersectionObserverRootContextProvider rootRef={contentItemCanvasRef}>
      <ScrollContainerContextProvider
        scrollContainerRef={contentItemCanvasRef}
        tippyBoundaryRef={contentItemCanvasRef}
      >
        <div
          className="content-item-pane__canvas"
          onScroll={throttledOnScroll}
          ref={contentItemCanvasRef}
        >
          <div
            className={classNames('content-item-pane', {
              'content-item-pane--is-disabled': disabled,
              'content-item-pane--is-small': reachedThresholds.sizeXS || reachedThresholds.sizeS,
              'content-item-pane--is-canvas-scrollable': isContentItemCanvasScrollable,
            })}
            data-hj-suppress=""
            ref={contentItemPaneRef}
          >
            <ScrollOptionsContextProvider scrollOptions={ItemEditorScrollOptions}>
              <EditingActions />
              <div className="content-item-pane__main-pane" ref={contentItemPaperRef}>
                {canRenderPaperContent && (
                  <>
                    <ContentItemHeader />
                    <ContentItemElements />
                    <ElementLocksObserver />
                  </>
                )}
              </div>
              <InlineCommentPane />
            </ScrollOptionsContextProvider>
          </div>
        </div>
        {shouldRenderSidebar && (
          <>
            <ContentItemSidebar hasPlaceholder outsideClickElementRefs={contentItemCanvasRef} />
            <ContentItemSidebarPlaceholder />
          </>
        )}
      </ScrollContainerContextProvider>
    </IntersectionObserverRootContextProvider>
  );
};

const ContentItemEditorPaneMemo = memo(
  ContentItemEditorPane,
  (prevProps, nextProps) => prevProps.disabled === nextProps.disabled,
);

interface Props {
  readonly contentItemName: string | null;
  readonly disabled: boolean;
  readonly shouldRenderSidebar: boolean;
}

export const ContentItemEditor = ({ contentItemName, disabled, shouldRenderSidebar }: Props) => {
  return (
    <div
      className="canvas"
      {...getDataUiAppNameAttribute(DataUiAppName.ContentItemEditing)}
      data-hj-suppress=""
    >
      <HtmlPageTitle
        appName={AppNames.ContentItemEditor}
        customName={contentItemName || untitledContentItem}
      />
      <EditedContentItemContextProvider>
        <div className="canvas__workspace">
          <section
            className="canvas__content content-item-editor"
            {...getDataUiElementAttribute(DataUiElement.ContentItemEditor)}
          >
            <EditorPaperContextProvider>
              <EditorScrollContextProvider>
                {(updateScrollContext) => (
                  <>
                    <ContentItemNotifications />
                    <ContentItemEditorPaneMemo
                      disabled={disabled}
                      shouldRenderSidebar={shouldRenderSidebar}
                      onInit={() =>
                        updateScrollContext(() => ({
                          isCandid: true,
                        }))
                      }
                      onScroll={(scrollTopPx) => {
                        updateScrollContext(() => ({ scrollTopPx }));
                      }}
                      onReposition={(pane) =>
                        updateScrollContext(() => ({
                          offsetTopPx: pane.offsetTop,
                        }))
                      }
                    />
                  </>
                )}
              </EditorScrollContextProvider>
            </EditorPaperContextProvider>
          </section>
          <ResolvedCommentsSidebar />
          <DiscussionsSidebar />
        </div>
      </EditedContentItemContextProvider>
      <ContentItemChangeIntercomTracker />
    </div>
  );
};
