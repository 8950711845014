import { ScrollTableState } from '../../applications/contentInventory/content/models/ScrollTableState.type.ts';
import { MinimumLimitPerRequest } from '../constants/scrollTableConstants.ts';
import { LoadingStatus } from '../models/LoadingStatusEnum.ts';
import { IStore } from '../stores/IStore.type.ts';

const TopPadding = 1;
const OverallPadding = 2 * TopPadding;

export enum ItemsRequestTrigger {
  TooFewItemsLoaded = 'TooFewItemsLoaded',
  UserScroll = 'UserScroll',
  Other = 'Other',
}

/**
 * Constructs maximum items to be loaded.
 */
export const getMaxItemsForLoading = (tableState: IScrollTableConvertedState): number => {
  return Math.max(MinimumLimitPerRequest, tableState.numberOfItemsInViewport * 2);
};

export type IScrollTableConvertedState = {
  readonly numberOfItemsInViewport: number;
  readonly indexOfFirstItemInViewport: number;
};

type ConvertArgs = {
  readonly stats: ScrollTableState;
  readonly itemHeight: number;
  readonly totalNumberOfItems: number | null;
};

export const areScrollTableStatsTheSame = (
  a: ScrollTableState | null,
  b: ScrollTableState | null,
): boolean =>
  (!a && !b) ||
  (!!a &&
    !!b &&
    a.scrollPositionPx === b.scrollPositionPx &&
    a.availableHeightPx === b.availableHeightPx);

export const getMaxAdmissibleValue = (
  value: number,
  minimum: number | null,
  maximum: number | null,
): number => {
  if (maximum !== null && value > maximum) {
    return maximum;
  }
  if (minimum !== null && value < minimum) {
    return minimum;
  }

  return value;
};

export const convertFromPixelsToItems = ({
  stats,
  itemHeight,
  totalNumberOfItems,
}: ConvertArgs): IScrollTableConvertedState => {
  const numberOfVisibleItems = Math.ceil(stats.availableHeightPx / itemHeight) + OverallPadding;
  const numberOfItemsToDisplay = getMaxAdmissibleValue(numberOfVisibleItems, 1, totalNumberOfItems);
  const indexOfFirstItem = Math.floor(stats.scrollPositionPx / itemHeight) - TopPadding;
  const minimumIndexOfFirstItem = totalNumberOfItems
    ? Math.max(0, totalNumberOfItems - numberOfItemsToDisplay)
    : null;

  return {
    numberOfItemsInViewport: numberOfItemsToDisplay,
    indexOfFirstItemInViewport: getMaxAdmissibleValue(indexOfFirstItem, 0, minimumIndexOfFirstItem),
  };
};

/**
 * Returns the bottom threshold that specifies when to reload data.
 */
export const getThresholdsForReload = (total: number, numberOfItemsInViewport: number): number => {
  const halfOfViewportHeight = Math.ceil(numberOfItemsInViewport / 2);
  return total - halfOfViewportHeight;
};

export const shouldDispatchOnScrollStateChange = (oldState: IStore, newState: IStore): boolean => {
  const oldScroll = oldState.contentApp.listingUi.contentItemListingScrollTableState;
  const newScroll = newState.contentApp.listingUi.contentItemListingScrollTableState;

  // prevents duplicated first request (first load is performed by init actions)
  // possible scrolling up does not matter as we do not reload in these scenarios anyway
  const isFirstChange = newScroll.scrollPositionPx === 0;
  const scrollStateChanged =
    oldScroll.availableHeightPx !== newScroll.availableHeightPx ||
    oldScroll.scrollPositionPx !== newScroll.scrollPositionPx;

  return !isFirstChange && scrollStateChanged;
};

const isNumberOfLoadedItemsOverTheReloadThreshold = (
  tableState: IScrollTableConvertedState,
  numberOfLoadedItems: number,
) => {
  const indexOfLastItemInViewport =
    tableState.indexOfFirstItemInViewport + tableState.numberOfItemsInViewport + OverallPadding;

  const reloadThreshold = getThresholdsForReload(
    numberOfLoadedItems,
    tableState.numberOfItemsInViewport,
  );

  return reloadThreshold < indexOfLastItemInViewport;
};

export const shouldUseLastContinuationToken = (requestTrigger: ItemsRequestTrigger): boolean =>
  [ItemsRequestTrigger.UserScroll, ItemsRequestTrigger.TooFewItemsLoaded].includes(requestTrigger);

export interface IRequestForNewItems {
  continuationToken: string | null;
  loadingStatus: LoadingStatus;
  numberOfLoadedItems: number;
  requestTrigger: ItemsRequestTrigger;
  tableState: IScrollTableConvertedState;
}

export const shouldRequestNewItems = ({
  continuationToken,
  loadingStatus,
  numberOfLoadedItems,
  requestTrigger,
  tableState,
}: IRequestForNewItems): boolean => {
  if (loadingStatus === LoadingStatus.InitialEmpty) {
    return true;
  }

  if (!shouldUseLastContinuationToken(requestTrigger)) {
    return true;
  }

  if (continuationToken === null) {
    return false;
  }

  return isNumberOfLoadedItemsOverTheReloadThreshold(tableState, numberOfLoadedItems);
};
