import classNames from 'classnames';
import React, { useState } from 'react';
import { DataAttributes, getDataAttribute } from '../../utils/dataAttributes/DataAttributes.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Toggle } from '../Toggle.tsx';

export enum Rate {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
}

interface ISingleEaseQuestion {
  readonly title?: string;
  readonly name: string;
  readonly onChange: (value: string) => void;
  readonly value: string;
  readonly getExplanation?: (value: Rate) => string | null;
}

const getExplanation = (rate: string): string | null => {
  switch (rate) {
    case Rate.One:
      return 'Strongly disagree';
    case Rate.Seven:
      return 'Strongly agree';
    default:
      return null;
  }
};

type Event =
  | React.MouseEvent<HTMLElement>
  | React.KeyboardEvent<HTMLInputElement>
  | React.FocusEvent<HTMLInputElement>;

const getRate = (event: Event) =>
  event.currentTarget.parentElement?.getAttribute('data-rate') ?? '';

const handleEvent = (event: Event, handler: (rate: string) => void): void => {
  const rate = getRate(event);

  if (rate) {
    handler(rate);
  }
};

export const SingleEaseQuestion: React.FC<ISingleEaseQuestion> = (props) => {
  const [focusedRate, setFocusedRate] = useState<string | null>(null);

  return (
    <>
      <div className="single-ease-question__title">{props.title}</div>
      <div
        className="single-ease-question__scale"
        {...getDataUiElementAttribute(DataUiElement.SingleEaseQuestion)}
      >
        {Object.values(Rate).map((rate) => (
          <div
            className="single-ease-question__item"
            key={rate}
            {...getDataAttribute(DataAttributes.Rate, rate)}
          >
            <Toggle
              id={rate + props.name}
              type="radio"
              label={rate}
              labelClassName={classNames('single-ease-question__label', {
                'single-ease-question__label--isChecked': props.value === rate,
                'single-ease-question__label--isFocused': focusedRate === rate,
              })}
              name={rate + props.name}
              onToggle={(event) => handleEvent(event, props.onChange)}
              onFocus={(event) => handleEvent(event, setFocusedRate)}
              onBlur={() => rate === focusedRate && setFocusedRate(null)}
            />

            <div className="single-ease-question__explanation">
              {(props.getExplanation ?? getExplanation)(rate)}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

SingleEaseQuestion.displayName = 'SingleEaseQuestion';
