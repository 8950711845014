import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { removeNonExistingTaxonomyTerms } from '../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { validateNumberOfItems } from '../../../../_shared/utils/validation/limitations/validateNumberOfItems.ts';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { validTaxonomyItemWarningResult } from '../../constants/validElementWarningResults.ts';
import {
  TaxonomyExactlyWarning,
  TaxonomyIsRequiredWarning,
  TaxonomyMaximumWarning,
  TaxonomyMinimumWarning,
} from '../../constants/warningMessageTemplates.ts';
import { ITaxonomyItemElement } from '../../models/contentItemElements/TaxonomyItemElement.ts';
import { ITaxonomyItemElementWarningResult } from './types/ITaxonomyItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from './types/Warnings.ts';

const isElementEmpty = (
  typeElement: ITaxonomyTypeElement,
  itemElement: ITaxonomyItemElement,
  taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
): boolean => {
  if (!typeElement.taxonomyGroupId) {
    throw InvariantException(
      'Argument typeElement of type ITaxonomyElementType does not have taxonomyGroupId set',
    );
  }
  const taxonomyGroup = taxonomyGroups.get(typeElement.taxonomyGroupId);
  if (!taxonomyGroup) {
    return true;
  }

  return !removeNonExistingTaxonomyTerms(itemElement.value, taxonomyGroup).size;
};

const isRequiredLimitValid = (typeElement: ITaxonomyTypeElement, isEmpty: boolean): boolean =>
  !(typeElement.isRequired && isEmpty);

const areConditionsToSkipValidationMet = (
  typeElement: ITaxonomyTypeElement,
  isEmpty: boolean,
): boolean => !typeElement.isRequired && isEmpty;

interface IParams {
  readonly typeElement: ITaxonomyTypeElement;
  readonly itemElement: ITaxonomyItemElement;
  readonly taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>;
}

const getInvalidNumberOfItemsErrorMessage = (
  minItems: number | null,
  maxItems: number | null,
): string => {
  if (minItems && maxItems && minItems === maxItems) {
    return TaxonomyExactlyWarning(minItems);
  }
  if (minItems) {
    return TaxonomyMinimumWarning(minItems);
  }
  if (maxItems) {
    return TaxonomyMaximumWarning(maxItems);
  }

  return '';
};

const getExistingTermsCount = (
  taxonomyGroupId: Uuid | null,
  allTaxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>,
  selectedTerms: ReadonlySet<string>,
): number => {
  if (!taxonomyGroupId) {
    return 0;
  }

  const taxonomyGroup = allTaxonomyGroups.get(taxonomyGroupId);
  return taxonomyGroup ? removeNonExistingTaxonomyTerms(selectedTerms, taxonomyGroup).size : 0;
};

export const getTaxonomyItemElementValidationWarning = ({
  typeElement,
  itemElement,
  taxonomyGroups,
}: IParams): ITaxonomyItemElementWarningResult => {
  const warningMessages: Array<string> = [];

  const minItems = typeElement.minItems;
  const maxItems = typeElement.maxItems;
  const isEmpty = isElementEmpty(typeElement, itemElement, taxonomyGroups);

  if (areConditionsToSkipValidationMet(typeElement, isEmpty)) {
    return validTaxonomyItemWarningResult;
  }

  const existingTermsCount = getExistingTermsCount(
    typeElement.taxonomyGroupId,
    taxonomyGroups,
    itemElement.value,
  );

  const isRequiredLimitMet = isRequiredLimitValid(typeElement, isEmpty);
  const isNumberOfTermsLimitMet = validateNumberOfItems(minItems, maxItems, existingTermsCount);

  if (isRequiredLimitMet && !isNumberOfTermsLimitMet) {
    warningMessages.push(getInvalidNumberOfItemsErrorMessage(minItems, maxItems));
  }

  return {
    ...emptyItemElementWarningResult,
    isNumberOfTermsLimitMet,
    limitationMessages: [...warningMessages],
    requiredMessage: isRequiredLimitMet ? null : TaxonomyIsRequiredWarning,
  };
};
