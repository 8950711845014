import { memoize } from '@kontent-ai/memoization';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getAllProjectsMemoized } from '../../../projects/selectors/projectSelectors.ts';
import { getSubscriptionMasterEnvironments } from '../../shared/utils/subscriptionProjectUtils.ts';
import { IUserProjectSettingActiveStatus } from '../containers/UserDetail/UserProjectSettingsStatus.tsx';
import { ISubscriptionUserWithSettings } from '../models/SubscriptionUserWithSettings.ts';
import { IUserProjectSettings } from '../models/UserProjectSettings.ts';

export const getAllMasterEnvironments = (s: IStore): ReadonlyArray<IProjectDetails> | undefined => {
  const selectedSubscription = getSelectedSubscription(s);
  if (!selectedSubscription) {
    return undefined;
  }

  const projects = getAllProjectsMemoized(s.data.projects.byId);
  return getSubscriptionMasterEnvironments(projects, selectedSubscription.subscriptionId);
};

export const getUserMasterEnvironments = (
  s: IStore,
  userId: Uuid,
): ReadonlyArray<IProjectDetails> | undefined => getUserEnvironmentsInternal(s, userId, true);

export const getUserEnvironments = (
  s: IStore,
  userId: Uuid,
): ReadonlyArray<IProjectDetails> | undefined => getUserEnvironmentsInternal(s, userId, false);

const getUserEnvironmentsInternal = (
  s: IStore,
  userId: Uuid,
  onlyMasterEnvironments: boolean,
): ReadonlyArray<IProjectDetails> | undefined => {
  const allEnvironments = getAllProjectsMemoized(s.data.projects.byId);
  const subscriptionUsers = s.data.subscriptions.subscriptionUsersUsage?.subscriptionUsers;
  if (!allEnvironments || !subscriptionUsers) {
    return undefined;
  }

  return getEnvironmentsForUser(allEnvironments, subscriptionUsers, userId, onlyMasterEnvironments);
};

const getEnvironmentsForUser = memoize.weak(
  (
    allEnvironments: ReadonlyArray<IProjectDetails>,
    subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>,
    userId: Uuid,
    onlyMasterEnvironments: boolean,
  ): ReadonlyArray<IProjectDetails> => {
    const userUsage = getSubscriptionUserUsage(subscriptionUsers, userId);
    const userProjectIds =
      userUsage?.userProjectsSettings.map((settings) =>
        onlyMasterEnvironments ? settings.masterEnvironmentId : settings.projectId,
      ) || [];
    const userProjects = allEnvironments.filter((environment) =>
      userProjectIds.includes(environment.projectId),
    );
    return userProjects;
  },
);

export const getSubscriptionUserUsage = memoize.weak(
  (
    subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>,
    userId: Uuid,
  ): ISubscriptionUserWithSettings | undefined =>
    subscriptionUsers.find((user) => user.userId === userId),
);

export const getUserProjectSettings = memoize.weak(
  (
    subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>,
    userId: Uuid,
    masterProjectId: Uuid,
  ): ReadonlyArray<IUserProjectSettings> | undefined => {
    const userUsage = getSubscriptionUserUsage(subscriptionUsers, userId);
    if (!userUsage) {
      return undefined;
    }

    const userProjectSettings = userUsage.userProjectsSettings.filter(
      (settings) => settings.masterEnvironmentId === masterProjectId,
    );
    return userProjectSettings;
  },
);

export const getProjectIdsFromSettings = memoize.weak(
  (userProjectSettings: ReadonlyArray<IUserProjectSettings>): ReadonlyArray<Uuid> =>
    userProjectSettings.map((settings) => settings.projectId),
);

export const getUserProjectSettingsActiveStatus = (
  subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>,
  userId: Uuid,
  userProjectSettings: ReadonlyArray<IUserProjectSettings>,
): IUserProjectSettingActiveStatus => {
  const subscriptionUser = getSubscriptionUserUsage(subscriptionUsers, userId);

  const projectWithProjectManagerRole = userProjectSettings.find(
    (ups) =>
      ups.collectionGroups.length === 1 &&
      (ups.collectionGroups[0]?.roles ?? []).some((role) => role.isProjectManagerRole),
  );

  const isActiveGlobal = userProjectSettings.some((ups) => !ups.inactive);
  const isActivating =
    subscriptionUser?.isActivating || userProjectSettings.some((ups) => ups.isActivating);
  const isDeactivating =
    subscriptionUser?.isDeactivating || userProjectSettings.some((ups) => ups.isDeactivating);
  const isInvitationPending = !!subscriptionUser?.isInvitationPending;
  const isInviteRevoking = userProjectSettings.some((ups) => ups.isInviteRevoking);
  const isInviteSending = userProjectSettings.some((ups) => ups.isInviteSending);
  const isInviteSent = userProjectSettings.some((ups) => ups.isInviteSent);

  //PM can be active only if it is PM and active on all environments
  //If PM is inactive, after activation it will be set up as PM and activated on all environments
  const isProjectManagerActiveStatusTheSameAsGlobalStatus = (
    settings: IUserProjectSettings,
  ): boolean => {
    return settings.inactive !== isActiveGlobal;
  };

  let projectIds: ReadonlyArray<Uuid> | null = null;
  let isProjectManagerRole: boolean | null = null;
  let hasNonProjectManagerRole: boolean | null = null;
  if (
    !!projectWithProjectManagerRole &&
    (isInvitationPending ||
      isProjectManagerActiveStatusTheSameAsGlobalStatus(projectWithProjectManagerRole))
  ) {
    projectIds = [projectWithProjectManagerRole.projectId];
    isProjectManagerRole = true;
    hasNonProjectManagerRole = userProjectSettings.some(
      (ups) =>
        ups.collectionGroups.length !== 1 ||
        (ups.collectionGroups[0]?.roles ?? []).some((role) => !role.isProjectManagerRole),
    );
  } else if (isInvitationPending) {
    projectIds = userProjectSettings.map((ups) => ups.projectId);
    isProjectManagerRole = false;
    hasNonProjectManagerRole = true;
  } else {
    projectIds = userProjectSettings
      .filter((ups) => ups.inactive !== isActiveGlobal)
      .map((ups) => ups.projectId);
    isProjectManagerRole = false;
    hasNonProjectManagerRole = true;
  }

  return {
    hasNonProjectManagerRole,
    isActiveGlobal,
    isActivating,
    isDeactivating,
    isInvitationPending,
    isInviteRevoking,
    isInviteSending,
    isInviteSent,
    isProjectManagerRole,
    projectIds,
  };
};
