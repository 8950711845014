import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  IWorkflowStep,
  RegularWorkflowStep,
  WorkflowStepAction,
  emptyWorkflowStep,
  getWorkflowStepFromServerModel,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import {
  Workflow_ArchivedStepEditor_Save,
  Workflow_Editing_StepCodenameChanged,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
  Workflow_InitializeEditing_Started,
  Workflow_PublishedStepEditor_Save,
  Workflow_StepCreator_Cancel,
  Workflow_StepCreator_Initiated,
  Workflow_StepEditor_Delete,
  Workflow_StepEditor_Save,
} from '../../constants/workflowActionTypes.ts';

const getNewTransitionsToForStep = (
  step: IWorkflowStep,
  transitionToId: Uuid,
  shouldExist: boolean,
): ReadonlySet<Uuid> => {
  if (step.action !== WorkflowStepAction.NoAction) {
    // transitionsTo of special steps cannot be influenced
    return step.transitionsTo;
  }

  return shouldExist
    ? Collection.add(step.transitionsTo, transitionToId)
    : Collection.remove(step.transitionsTo, transitionToId);
};

export const regularWorkflowSteps = (
  state: readonly RegularWorkflowStep[] = [],
  action: Action,
): readonly RegularWorkflowStep[] => {
  switch (action.type) {
    case Workflow_InitializeEditing_Started: {
      return [];
    }

    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished: {
      return action.payload.workflow.steps;
    }

    case Workflow_Editing_WorkflowSaveFinished: {
      return action.payload.workflow.statuses.map(getWorkflowStepFromServerModel);
    }

    case Workflow_StepEditor_Save: {
      const { stepId, name, color, transitionsTo, transitionsFrom, roleIds } = action.payload;

      const savedStep = state.find(({ id }) => id === stepId) ?? emptyWorkflowStep;

      const stepUpdate: RegularWorkflowStep = {
        id: stepId,
        name,
        color,
        codename: savedStep.codename,
        action: WorkflowStepAction.NoAction,
        transitionsTo: new Set(Collection.getValues(transitionsTo).map(({ id }) => id)),
        roleIds,
      };

      const updatedStepIndex = state.findIndex(({ id }) => id === stepId);

      return Collection.replace(
        state.map((step) => ({
          ...step,
          transitionsTo: getNewTransitionsToForStep(
            step,
            stepId,
            Collection.getValues(transitionsFrom)
              .map((s: IWorkflowStep) => s.id)
              .includes(step.id),
          ),
        })),
        updatedStepIndex,
        stepUpdate,
      );
    }

    case Workflow_Editing_StepCodenameChanged: {
      const { stepId, stepCodename } = action.payload;
      const updatedStepIndex = state.findIndex((step) => step.id === stepId);
      const updatedStep = state[updatedStepIndex];

      if (!updatedStep) {
        return state;
      }

      return Collection.replace(state, updatedStepIndex, {
        ...updatedStep,
        codename: stepCodename,
      });
    }

    case Workflow_ArchivedStepEditor_Save:
    case Workflow_PublishedStepEditor_Save: {
      const { stepId, transitionsFrom } = action.payload;

      return state.map(
        (step: RegularWorkflowStep): RegularWorkflowStep => ({
          ...step,
          transitionsTo: getNewTransitionsToForStep(
            step,
            stepId,
            Collection.getValues(transitionsFrom)
              .map((s: IWorkflowStep) => s.id)
              .includes(step.id),
          ),
        }),
      );
    }

    case Workflow_StepCreator_Cancel:
    case Workflow_StepEditor_Delete: {
      const { stepId } = action.payload;
      return state
        .map((step) => ({
          ...step,
          transitionsTo: getNewTransitionsToForStep(step, stepId, false),
        }))
        .filter((step) => step.id !== stepId);
    }

    case Workflow_StepCreator_Initiated: {
      const { workflowStep } = action.payload;

      return [...state, workflowStep];
    }

    default: {
      return state;
    }
  }
};
