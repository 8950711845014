import { InvariantException } from '@kontent-ai/errors';
import { List } from 'immutable';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TimelineItem } from '../../../../models/revisions/TimeLineItem.ts';

interface IDeps {
  readonly loadItemVariantTimeline: (
    contentItemId: Uuid,
    variantId: Uuid,
    abortSignal?: AbortSignal,
  ) => ThunkPromise<List<TimelineItem>>;
}

export const loadTimelineActionCreator =
  ({ loadItemVariantTimeline }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const { editedContentItemVariant } = getState().contentApp;
    if (!editedContentItemVariant) {
      throw InvariantException('Cannot load timeline before variant is loaded.');
    }

    await dispatch(
      loadItemVariantTimeline(
        editedContentItemVariant.id.itemId,
        editedContentItemVariant.id.variantId,
        abortSignal,
      ),
    );
  };
