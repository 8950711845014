import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SidebarSection } from '../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButtonWithErrorMessage } from '../../../../_shared/components/Sidebar/SidebarSubmitButtonWithErrorMessage.tsx';
import { DefaultSampleProjectName } from '../../../../_shared/constants/defaultNames.ts';
import { Sidebar } from '../../../../_shared/containers/Sidebar/SidebarContainer.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../../_shared/models/SidebarNames.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getDefaultSubscriptionId,
  getSortedSubscriptions,
} from '../../../../data/reducers/subscriptions/selectors/sampleProjectSubscriptionSelectors.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
  getSubscriptionActiveProjectsCount,
  getSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { closeCreateSampleProjectModal } from '../../actions/projectsActions.ts';
import { createSampleProject } from '../../actions/thunkProjectsActions.ts';
import {
  CreateSampleProjectForm,
  ICreateSampleProjectFormShape,
} from '../../components/projects/CreateSampleProjectForm.tsx';
import { SelectedSubscriptionProjectLimitReachedTooltipMessage } from '../../constants/UIConstants.ts';
import { SampleProjectType } from '../../constants/sampleProjectType.ts';

type Props = {
  readonly type: SampleProjectType;
};

export const CreateSampleProjectModal: React.FC<Props> = (props) => {
  const creatingSampleProject = useSelector((state) => state.projectsApp.creatingSampleProject);
  const errorMessage = useSelector((state) => state.projectsApp.createProjectErrorMessage);
  const showSidebar = useSelector(getShowSidebar);
  const sortedSubscriptions = useSelector(getSortedSubscriptions);
  const defaultSubscriptionId = useSelector((state) =>
    getDefaultSubscriptionId(state, sortedSubscriptions),
  );

  const dispatch = useDispatch();

  const formProps = useForm<ICreateSampleProjectFormShape>({
    defaultValues: {
      subscriptionId: defaultSubscriptionId,
      name: DefaultSampleProjectName,
    },
  });

  const { handleSubmit, watch, reset } = formProps;

  useEffect(() => {
    if (!creatingSampleProject) {
      reset();
    }
  }, [creatingSampleProject, reset]);

  const onCloseDialog = () => dispatch(closeCreateSampleProjectModal());
  const submitForm = handleSubmit((values) => {
    dispatch(createSampleProject(values.subscriptionId, props.type, values.name));
  });
  const subscriptionId = watch('subscriptionId');

  const maxSubscriptionProjectsCount = useSelector((state) => {
    const selectedSubscriptionPlan = subscriptionId
      ? getSubscriptionPlan(state, subscriptionId)
      : undefined;

    return selectedSubscriptionPlan
      ? selectedSubscriptionPlan.features.maxSubscriptionProjects || 0
      : 0;
  });

  const subscriptionProjectsLimitReached = useSelector((state) => {
    const currentSubscriptionProjectsCount = subscriptionId
      ? getSubscriptionActiveProjectsCount(state, subscriptionId)
      : 0;

    return (
      maxSubscriptionProjectsCount > 0 &&
      maxSubscriptionProjectsCount <= currentSubscriptionProjectsCount
    );
  });

  const submitButton = (
    <SidebarSubmitButtonWithErrorMessage
      onSubmit={submitForm}
      buttonLabel={creatingSampleProject ? 'Creating project' : 'Create project'}
      dataUiAttribute={getDataUiActionAttribute(DataUiAction.CreateNew)}
      disabled={
        creatingSampleProject || !sortedSubscriptions.length || subscriptionProjectsLimitReached
      }
      errorMessage={errorMessage}
      isBeingSubmitted={creatingSampleProject}
      tooltipText={
        subscriptionProjectsLimitReached && !creatingSampleProject
          ? SelectedSubscriptionProjectLimitReachedTooltipMessage(maxSubscriptionProjectsCount)
          : undefined
      }
    />
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={onCloseDialog}
      titleElement="Create Sample Project"
      submitElement={submitButton}
      onSubmit={submitForm}
    >
      <SidebarSection>
        <CreateSampleProjectForm
          areSubscriptionsAvailable={sortedSubscriptions.length > 0}
          isNameFieldDisabled={props.type !== SampleProjectType.HealthTech}
          formProps={formProps}
          onSubmit={creatingSampleProject ? undefined : submitForm}
          subscriptions={sortedSubscriptions}
          isCreationInProgress={creatingSampleProject}
        />
      </SidebarSection>
    </Sidebar>
  );
};

const getShowSidebar = (state: IStore): boolean => {
  const {
    data: { subscriptions },
    sharedApp: { sidebarStatus },
  } = state;

  const hasAnyActiveSubscription =
    getActiveSubscriptions(
      getAdministratedSubscriptions(subscriptions.administratedIds, subscriptions.byId),
    ).length > 0;

  const showSidebar =
    sidebarStatus.isVisible &&
    sidebarStatus.sidebarName === SidebarNames.CreateSampleProjectModal &&
    hasAnyActiveSubscription;

  return showSidebar;
};
