import {
  Dispatch,
  GetState,
  ThunkPromise,
} from '../../../../../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IPublishWarningsServerModel } from '../../../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  IPublishWarnings,
  getPublishWarningsFromServerModel,
} from '../../../../../../../itemEditor/models/PublishWarnings.ts';
import { ContentItemListingOperationQueryModel } from '../../../../../models/filter/ContentItemListOperationQueryModel.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import { ContentListing_CheckPublishWarnings_Finished } from '../../../constants/contentItemInventoryActionTypes.ts';

interface ICheckPublishWarningDependencies {
  readonly contentItemRepository: {
    readonly checkPublishWarnings: (
      variantId: Uuid,
      query: ContentItemListingOperationQueryModel,
    ) => Promise<IPublishWarningsServerModel>;
  };
}

const checkPublishWarningsFinished = (publishWarnings: IPublishWarnings) =>
  ({
    type: ContentListing_CheckPublishWarnings_Finished,
    payload: {
      publishWarnings,
    },
  }) as const;

export type CheckPublishWarningsActionsType = ReturnType<typeof checkPublishWarningsFinished>;

export const createCheckPublishWarningsAction =
  (deps: ICheckPublishWarningDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const query = getItemListingOperationQueryFromFilter(getState());
    const publishWarningsServerModel = await deps.contentItemRepository.checkPublishWarnings(
      selectedLanguageId,
      query,
    );
    const publishWarnings = getPublishWarningsFromServerModel(publishWarningsServerModel);

    dispatch(checkPublishWarningsFinished(publishWarnings));
  };
