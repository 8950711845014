import { Action } from '../../../../../@types/Action.type.ts';
import {
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitStarted,
  AssetLibrary_AssetEditor_SaveFailed,
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_AssetEditor_SaveStarted,
} from '../../constants/assetLibraryActionTypes.ts';

export function isEditedAssetBeingSaved(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case AssetLibrary_AssetEditor_SaveStarted:
      return true;

    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditorDialog_InitStarted:
    case AssetLibrary_AssetEditor_SaveFinished:
    case AssetLibrary_AssetEditor_SaveFailed:
      return false;

    default:
      return state;
  }
}
