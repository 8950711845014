import { FC } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContributorsSection as ContributorsSectionComponent } from '../../../components/details/Contributors/ContributorsSection.tsx';
import { SectionType } from '../../../components/sidebar/sectionTypes/SectionType.tsx';
import { getContributorState } from '../../selectors/getContributorState.ts';

export const ContributorsSection: FC = () => {
  const { isHidden } = useSelector(getContributorState);

  return isHidden ? null : <ContributorsSectionComponent sectionType={SectionType.Clickable} />;
};
