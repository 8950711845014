import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { getBreadcrumbsOrigin } from '../../../../../_shared/selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getPreviouslyOpenAssetFolderPath } from '../../../../../_shared/utils/breadcrumbs/getPreviouslyOpenAssetFolderPath.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../../shared/actions/contentInventoryActions.ts';
import { buildAssetRoutePath } from '../../utils/assetsPathUtils.ts';

export const createOpenSingleAssetUsageAction = () => (): ThunkFunction => (dispatch, getState) => {
  const state = getState();
  const {
    assetLibraryApp: {
      assetUiEditor: { editedAsset },
    },
    sharedApp: { currentProjectId },
  } = state;

  if (!editedAsset) {
    throw InvariantException('No asset is being edited');
  }

  const breadcrumbsOrigin = getBreadcrumbsOrigin(state);
  const assetsPath = getPreviouslyOpenAssetFolderPath(
    breadcrumbsOrigin,
    currentProjectId,
    editedAsset.folderId,
  );
  const assetPath = buildAssetRoutePath({
    assetId: editedAsset.id,
    projectId: currentProjectId,
  });

  dispatch(
    contentItemsBreadcrumbsOriginChanged(
      {
        path: assetsPath,
        title: AppNames.Assets,
        type: 'AssetFolder',
      },
      {
        path: assetPath,
        title: editedAsset.title || editedAsset.filename || '',
      },
    ),
  );
};
