import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  CompiledTypeElementType,
  ElementType,
} from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  getAllUsedContentComponents,
  getContentComponentIds,
} from '../../../../richText/plugins/contentComponents/api/editorContentComponentUtils.ts';
import { IContentComponent } from '../../../models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IRichTextItemElement } from '../../../models/contentItemElements/RichTextItemElement.ts';
import { isRichTextElement } from '../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import {
  IElementModifier,
  getElementById,
  modifyElement,
} from '../../../stores/utils/contentItemElementsUtils.ts';

interface IElementProps {
  readonly contentComponentId?: string;
  readonly elementData: {
    readonly elementId: string;
  };
  readonly typeElement: {
    readonly type: CompiledTypeElementType;
  };
}

export const determineTopmostContentElementType = (props: IElementProps) => {
  const {
    contentComponentId,
    elementData: { elementId },
  } = props;

  const isElementInContentComponent = !!contentComponentId && contentComponentId !== elementId;

  return isElementInContentComponent ? ElementType.RichText : props.typeElement.type;
};

export const getTopLevelContentComponentIdsFromElements = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): Immutable.Set<Uuid> =>
  Immutable.Set.of<Uuid>(
    ...elements
      .filter(isRichTextElement)
      .flatMap((element) => getContentComponentIds(element._editorState.getCurrentContent())),
  );

export const getAllContentComponentIdsFromElements = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): Immutable.Set<Uuid> =>
  Immutable.Set.of<Uuid>(
    ...elements
      .filter(isRichTextElement)
      .flatMap((element) =>
        getAllUsedContentComponents(
          element._editorState.getCurrentContent(),
          element.contentComponents,
        ).map((contentComponent) => contentComponent.id),
      ),
  );

export const getChildContentComponentIdsFromElements = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  contentComponentId: Uuid,
): Immutable.Set<Uuid> =>
  Immutable.Set.of<Uuid>(
    ...elements.filter(isRichTextElement).flatMap((element) => {
      const contentComponent = element.contentComponents.get(contentComponentId);
      if (!contentComponent) {
        return [];
      }
      const childContentComponentIds = getTopLevelContentComponentIdsFromElements(
        contentComponent.elements,
      );
      return [
        contentComponent.id,
        ...childContentComponentIds
          .flatMap((childContentComponentId: Uuid) =>
            getChildContentComponentIdsFromElements([element], childContentComponentId),
          )
          .toArray(),
      ];
    }),
  );

export const getParentContentComponentIdsFromElements = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  contentComponentId: Uuid,
): ReadonlySet<Uuid> => {
  const element = elements
    .filter(isRichTextElement)
    .find(({ contentComponents }) => contentComponents.has(contentComponentId));
  if (!element) {
    return new Set();
  }

  const componentToItsParentId = new Map<Uuid, Uuid>(
    Collection.getValues(element.contentComponents).flatMap((parentContentComponent) =>
      getTopLevelContentComponentIdsFromElements(parentContentComponent.elements)
        .toArray()
        .map((childContentComponentId) => [childContentComponentId, parentContentComponent.id]),
    ),
  );

  const gatherParentComponentIds = (componentId: Uuid): ReadonlyArray<Uuid> => {
    const parentId = componentToItsParentId.get(componentId);
    return parentId ? [parentId, ...gatherParentComponentIds(parentId)] : [];
  };

  const parentComponentIds = gatherParentComponentIds(contentComponentId);

  return new Set(parentComponentIds);
};

export const updateContentComponentElement = (
  rootRichTextElement: IRichTextItemElement,
  contentComponentId: Uuid,
  elementId: Uuid,
  modifier: IElementModifier,
  createMissingElement: (contentComponentTypeId: Uuid) => ICompiledContentItemElementData,
): IRichTextItemElement => {
  const contentComponent = rootRichTextElement.contentComponents.get(contentComponentId);
  if (!contentComponent) {
    return rootRichTextElement;
  }

  const newElements = modifyElement(contentComponent.elements, elementId, modifier, () =>
    createMissingElement(contentComponent.contentTypeId),
  );
  const newContentComponent: IContentComponent = {
    ...contentComponent,
    elements: newElements,
  };

  const newContentComponents = Collection.add(rootRichTextElement.contentComponents, [
    contentComponentId,
    newContentComponent,
  ]);

  return {
    ...rootRichTextElement,
    contentComponents: newContentComponents,
  };
};

export const getContentComponents = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  rootRichTextElementId: Uuid,
): ReadonlyMap<Uuid, IContentComponent> | null => {
  const element = getElementById(rootRichTextElementId, elements);
  if (!isRichTextElement(element)) {
    return null;
  }
  return element.contentComponents;
};

export const getContentComponent = (
  elements: ReadonlyArray<ICompiledContentItemElementData>,
  rootRichTextElementId: Uuid,
  contentComponentId: Uuid,
): IContentComponent | null =>
  getContentComponents(elements, rootRichTextElementId)?.get(contentComponentId) ?? null;
