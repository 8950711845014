import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useEffect, useState } from 'react';
import { DropDownFrame } from '../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { IDropdownTippyOptions } from '../../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingAction } from '../../models/EditingAction.ts';
import { useHandleClickOutsideInActionsMenu } from '../../utils/editingActions/useHandleClickOutsideInActionsMenu.ts';
import { UseCustomHandleClickOutside } from './EditingLeafActionForm.tsx';
import { EditingActionsButton } from './actions/EditingActionsButton.tsx';
import { ActionsLeafMenu } from './actionsMenu/ActionsLeafMenu.tsx';
import { ActionsMainMenu } from './actionsMenu/ActionsMainMenu.tsx';

type EditingActionsMenuProps = {
  readonly actions: readonly EditingAction[];
  readonly activatedAction: EditingAction;
  readonly areAllActionsInMenu: boolean;
  readonly onHideMenu: () => void;
  readonly onShowMenu: () => void;
};

type MenuContentProps = {
  readonly actions: readonly EditingAction[];
  readonly activatedAction: EditingAction;
  readonly useCustomHandleClickOutside: UseCustomHandleClickOutside;
};

const MenuContent: React.FC<MenuContentProps> = React.memo((props) =>
  props.activatedAction === EditingAction.showMainMenu ? (
    <ActionsMainMenu actions={props.actions} key="main-menu" />
  ) : (
    <ActionsLeafMenu
      activatedAction={props.activatedAction}
      key={props.activatedAction}
      useCustomHandleClickOutside={props.useCustomHandleClickOutside}
    />
  ),
);

const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-end',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-start', 'left-start', 'right', 'left'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: Spacing.S,
        },
      },
    ],
  },
};

type MenuButtonProps = {
  readonly forwardedRef: React.RefObject<HTMLButtonElement>;
  readonly isActive?: boolean;
  readonly onClick: React.MouseEventHandler<HTMLElement>;
  readonly text: string;
};

const MenuButton: React.FC<MenuButtonProps> = React.memo((props) => (
  <EditingActionsButton
    buttonStyle="tertiary"
    dataUiAction={DataUiAction.MoreActions}
    ref={props.forwardedRef}
    iconName="Ellipsis"
    isActive={props.isActive}
    onClick={props.onClick}
    text={props.text}
  />
));

MenuButton.displayName = 'MenuButton';

export const EditingActionsMenu: React.FC<EditingActionsMenuProps> = ({
  actions,
  activatedAction,
  areAllActionsInMenu,
  onHideMenu,
  onShowMenu,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // We need to remember the last active action in case the action becomes none to allow the menu to fade away in its last state when hiding
  const [lastActiveAction, setLastActiveAction] = useState(activatedAction);

  useEffect(() => {
    if (activatedAction === EditingAction.none) {
      setIsMenuOpen(false);
    } else {
      setLastActiveAction(activatedAction);
      setIsMenuOpen(true);
    }
  }, [activatedAction]);

  const [handleClickOutside, shouldCloseOnClickOutside, useCustomHandleClickOutside] =
    useHandleClickOutsideInActionsMenu(onHideMenu, lastActiveAction);

  const menuButtonText = areAllActionsInMenu ? 'Actions' : 'More actions';

  const onButtonClick = isMenuOpen ? onHideMenu : onShowMenu;

  return (
    <DropDownFrame
      allowAnimation={false}
      isOptionListVisible={isMenuOpen}
      longer
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      onClickOutside={isMenuOpen ? handleClickOutside : undefined}
      renderContent={() => (
        <MenuContent
          actions={actions}
          activatedAction={lastActiveAction}
          useCustomHandleClickOutside={useCustomHandleClickOutside}
        />
      )}
      renderSelector={(ref: React.RefObject<HTMLButtonElement>) => (
        <MenuButton
          onClick={onButtonClick}
          forwardedRef={ref}
          isActive={isMenuOpen}
          text={menuButtonText}
        />
      )}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
      tippyOptions={tippyOptions}
    />
  );
};

EditingActionsMenu.displayName = 'EditingActionsMenu';
