import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IExistingUserToInvite } from '../../../../data/models/users/ExistingUserToInvite.ts';
import { Invitation } from '../reducers/IUsersAppState.type.ts';

export const createIsNewInvitation = memoize.maxOne(
  (existingUsersByEmail: Immutable.Map<string, IExistingUserToInvite>) =>
    (invitation: Invitation) =>
      !existingUsersByEmail.has(invitation.email),
);

export const createIsNewInvitationSelector = (state: IStore) =>
  createIsNewInvitation(state.usersApp.userInvitation.existingUsersByEmail);
