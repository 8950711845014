import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKeyDetail_Updating_Failed,
  ApiKeyDetail_Updating_Finished,
  ApiKeyDetail_Updating_Started,
} from '../../constants/apiKeysActionTypes.ts';
import {
  ApiKey,
  convertToUpdateServerModel,
  createApiKeyFromServerModel,
} from '../../models/ApiKey.ts';

const started = () =>
  ({
    type: ApiKeyDetail_Updating_Started,
  }) as const;

const failed = () =>
  ({
    type: ApiKeyDetail_Updating_Failed,
  }) as const;

const success = (apiKey: ApiKey) =>
  ({
    type: ApiKeyDetail_Updating_Finished,
    payload: { apiKey },
  }) as const;

interface ISaveApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'updateApiKey'>;
}

export type UpdateApiKeyActionsType = ReturnType<typeof started | typeof failed | typeof success>;

export const updateApiKeyCreator =
  ({ apiKeysRepository }: ISaveApiKeyDependencies) =>
  (apiKey: ApiKey): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());

      const updatedKeyDetail = convertToUpdateServerModel(apiKey);

      const serverModel = await apiKeysRepository.updateApiKey(
        apiKey.tokenSeedId,
        updatedKeyDetail,
      );

      dispatch(success(createApiKeyFromServerModel(serverModel)));
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
