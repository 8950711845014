import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IMultipleChoiceTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { isMultipleChoiceElement } from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { emptyItemElementErrorResult } from '../../../../utils/elementErrorCheckers/types/Errors.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { emptyItemElementFriendlyWarningResult } from '../../../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { getMultipleChoiceItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/multipleChoiceItemElementWarningChecker.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

type Deps = {
  revalidateEditedContentItemVariantElements: () => ThunkFunction;
  processDependentElements: (
    triggerElementNewValue: ICompiledContentItemElementData,
    triggerElementPreviousValue: ICompiledContentItemElementData,
  ) => ThunkFunction;
};

export const createOnMultipleChoiceOptionsChangeAction =
  ({ revalidateEditedContentItemVariantElements, processDependentElements }: Deps) =>
  (selectedOptions: readonly Uuid[], typeElement: IMultipleChoiceTypeElement): ThunkFunction =>
  (dispatch, getState) => {
    const { elementId } = typeElement;
    const {
      contentApp: { editedContentItem, editedContentItemVariantElements: elements },
    } = getState();

    if (!editedContentItem) {
      throw InvariantException(
        `multipleChoiceOptionsChanged.ts: "editedContentItem" is ${editedContentItem}`,
      );
    }

    const element = getElementByIdOrThrow(elementId, elements);
    if (!isMultipleChoiceElement(element)) {
      return;
    }

    const updatedElement = {
      ...element,
      options: selectedOptions,
    };

    const warningResult = getMultipleChoiceItemElementValidationWarning({
      typeElement,
      itemElement: updatedElement,
    });

    const validationResult = createValidationResult(
      emptyItemElementErrorResult,
      warningResult,
      emptyItemElementFriendlyWarningResult,
      elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: updatedElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );

    dispatch(processDependentElements(updatedElement, element));

    // revalidate conditionally-dependent elements
    dispatch(revalidateEditedContentItemVariantElements());
  };
