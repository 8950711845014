import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentPaceDetail as ContentPaceDetailComponent } from '../components/ContentPaceDetail.tsx';
import { getDemoContentPaceDetailContentItems } from '../constants/contentPaceDemoData.ts';
import { ContentPaceDetailProps } from './ContentPaceDetailLiveContainer.tsx';

export const ContentPaceDetailDemoContainer: React.FC<ContentPaceDetailProps> = ({
  averageTimeInSeconds,
  contentTypeId,
}) => {
  const demoContentTypeId = useSelector((s) => s.data.contentTypes.byId.keySeq().first() ?? '');
  const demoCurrentUserId = useSelector((s) => s.data.user.info.userId);

  const detailContentItems = getDemoContentPaceDetailContentItems(
    demoCurrentUserId,
    contentTypeId ?? demoContentTypeId,
    averageTimeInSeconds,
  );

  return (
    <ContentPaceDetailComponent
      areAllItemsLoaded
      averageTimeInSeconds={averageTimeInSeconds}
      detailContentItems={detailContentItems}
      isListPartial={false}
      isLoadingMoreItems={false}
    />
  );
};

ContentPaceDetailDemoContainer.displayName = 'ContentPaceDetailDemoContainer';
