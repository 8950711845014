import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { selectWorkflowStep } from '../../../../../../_shared/features/ChangeWorkflowStepModal/actions/changeWorkflowStepModalActions.ts';
import { ScheduleMethod } from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/scheduleMethods.ts';
import { isPublishingAvailable } from '../../../../../../_shared/features/ChangeWorkflowStepModal/selectors/isPublishingAvailable.ts';
import { getWorkflowActionForUpdateStep } from '../../../../../../_shared/features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalActionUtils.ts';
import { isPublishedWorkflowStep } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { createAssignmentWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import {
  getWorkflow,
  getWorkflowStep,
} from '../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { hideValidationResults } from '../../../../actions/contentActions.ts';

interface IDeps {
  readonly displayWarningsOnPublishSelected: () => ThunkFunction;
}

export const createSelectWorkflowStepForEditedItemAction =
  ({ displayWarningsOnPublishSelected }: IDeps) =>
  (selectedStepId: Uuid): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      contentApp: {
        editedContentItemVariant,
        changeWorkflowStepModalData: { scheduleMethod, workflowStep },
      },
    } = state;

    assert(editedContentItemVariant, () => 'Edited content item variant was not found');

    const { workflowId } = editedContentItemVariant.assignment.workflowStatus;
    const currentWorkflow = getWorkflow(state, workflowId);

    assert(
      currentWorkflow,
      () => `selectWorkflowStepForEditedItem.ts: Did not find workflow with id ${workflowId}.`,
    );

    const newWorkflowStep = currentWorkflow && getWorkflowStep(currentWorkflow, selectedStepId);

    assert(
      newWorkflowStep,
      () =>
        `selectWorkflowStepForEditedItem.ts: Did not find workflow step with id ${selectedStepId}, workflow id ${workflowId}.`,
    );

    const selectedWorkflowStep = createAssignmentWorkflowStep(newWorkflowStep, workflowId);
    const selectedScheduleMethod = isPublishingAvailable(state)
      ? scheduleMethod
      : ScheduleMethod.Schedule;
    const workflowAction = getWorkflowActionForUpdateStep(
      selectedWorkflowStep,
      selectedScheduleMethod,
    );

    dispatch(
      selectWorkflowStep(workflowAction, {
        ...selectedWorkflowStep,
        workflowId: currentWorkflow.id,
      }),
    );

    if (workflowStep.id !== selectedStepId) {
      dispatch(hideValidationResults());
    }

    if (isPublishedWorkflowStep(selectedWorkflowStep)) {
      dispatch(displayWarningsOnPublishSelected());
    }
  };
