import { memoize } from '@kontent-ai/memoization';
import { Identifier } from 'dnd-core';
import { FileWithThumbnail } from '../../applications/contentInventory/assets/models/FileWithThumbnail.type.ts';

export type DraggedFiles = {
  readonly files: ReadonlyArray<File>;
  readonly items: DataTransferItemList;
  readonly type: Identifier;
};

export const isDraggedFiles = (draggedObject: any): draggedObject is DraggedFiles =>
  Array.isArray(draggedObject?.files);

export type DraggedFile = {
  readonly type: string;
};

export function getFileTypes({ files, items }: DraggedFiles): ReadonlyArray<string> {
  if (files && files.length > 0) {
    return files.map((file) => file.type);
  }
  if (items && items.length > 0) {
    return Array.from(items)
      .filter((item) => item.kind === 'file')
      .map((item) => item.type);
  }

  return [];
}

export const getDraggedFiles = memoize.maxOne((...fileTypes: ReadonlyArray<string>) =>
  fileTypes.map((fileType) => ({ type: fileType })),
);

export function getDroppedFiles(files: ReadonlyArray<File>): ReadonlyArray<FileWithThumbnail> {
  const filesWithThumbnail: ReadonlyArray<FileWithThumbnail> = files.map((file) => {
    const fileWithThumbnail = file as FileWithThumbnail;
    fileWithThumbnail.thumbnailLink = URL.createObjectURL(file);
    return fileWithThumbnail;
  });
  return filesWithThumbnail;
}
