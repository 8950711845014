import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export interface IStringContentItemElement extends ICompiledContentItemElementData {
  readonly value: string;
}

export function areStringItemElementsTheSame(
  itemElement1: IStringContentItemElement,
  itemElement2: IStringContentItemElement,
): boolean {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }

  return itemElement1.value === itemElement2.value;
}
