import { assert } from '@kontent-ai/utils';
import { IUserToInviteResponseServerModel } from '../../../repositories/serverModels/InviteUserServerModel.type.ts';

export interface IExistingUserToInvite {
  readonly userId: UserId;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
}

export const mapExistingUserToInviteFromServerModel = (
  serverModel: IUserToInviteResponseServerModel,
): IExistingUserToInvite => {
  assert(
    typeof serverModel === 'object',
    () => 'mapExistingUserToInviteFromServerModel: serverModel is not an object',
  );

  return {
    userId: serverModel.userId || '',
    email: serverModel.email || '',
    firstName: serverModel.firstName || '',
    lastName: serverModel.lastName || '',
  };
};
