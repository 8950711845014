import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemPreviewRoute,
  ContentItemPreviewRouteParams,
  ContentItemsAppRouteSegment,
  WebSpotlightEntryRoute,
  WebSpotlightEntryRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { BuildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { containsWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { isAbsoluteWebUrl } from '../../../../_shared/utils/urlUtils.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { SharedContentItemPreviewRouteState } from '../../../webSpotlight/hooks/useSharedContentItemPreviewRouteState.ts';
import {
  NoAccessToProjectMessage,
  SharedLinkContentDoesNotExistMessage,
  SharedLinkHasInvalidVariantIdMessage,
  SharedLinkHasNoUrlQueryParametersMessage,
  SharedLinkUserDoesNotHavePermissionToItemVariantMessage,
  SharedPreviewLinkHasInvalidSpaceIdMessage,
  SharedPreviewLinkUnsuccessfulRedirectText,
} from '../../constants/uiConstants.ts';
import { handleSuccessfulRedirect, handleUnsuccessfulRedirect } from '../smartLinkActions.ts';

interface IDeps {
  readonly buildPath: BuildPath;
  readonly contentItemRepository: IContentItemRepository;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly updateCurrentProject: (
    project: IUserProjectInfo,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

interface IParams {
  readonly height: number | null;
  readonly itemId: Uuid;
  readonly projectId: Uuid;
  readonly url: string | null;
  readonly variantId: Uuid;
  readonly width: number | null;
  readonly spaceId: Uuid;
}

export const redirectToSharedPreviewCreator =
  (deps: IDeps) =>
  (params: IParams, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const { itemId, variantId, spaceId, projectId, url, width, height } = params;

    dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewShareLinkUsed));

    const decodedSharedPreviewUrl = url && decodeURIComponent(url);
    if (
      !decodedSharedPreviewUrl ||
      containsWhitespace(decodedSharedPreviewUrl) ||
      !isAbsoluteWebUrl(decodedSharedPreviewUrl)
    ) {
      dispatch(
        handleUnsuccessfulRedirect(
          SharedPreviewLinkUnsuccessfulRedirectText,
          SharedLinkHasNoUrlQueryParametersMessage,
          deps.buildPath<WebSpotlightEntryRouteParams>(WebSpotlightEntryRoute, { projectId }),
        ),
      );
      return;
    }

    const {
      data: { user },
    } = getState();
    const project = user.projectsInfoById.get(projectId);

    if (!project) {
      dispatch(handleUnsuccessfulRedirect(null, NoAccessToProjectMessage, null));
      return;
    }

    try {
      await dispatch(deps.updateCurrentProject(project, abortSignal));
      await dispatch(deps.loadSpaces(abortSignal));
      const item = await deps.contentItemRepository.getItemWithAllVariants(itemId, abortSignal);

      const {
        data: { spaces },
      } = getState();
      const rootItemId = spaces.byId.get(spaceId)?.webSpotlightRootItemId;

      if (!rootItemId) {
        dispatch(
          handleUnsuccessfulRedirect(
            SharedPreviewLinkUnsuccessfulRedirectText,
            SharedPreviewLinkHasInvalidSpaceIdMessage,
            deps.buildPath<WebSpotlightEntryRouteParams>(WebSpotlightEntryRoute, { projectId }),
          ),
        );
      } else if (item.variants.some((v) => v._id.variantId === variantId)) {
        dispatch(
          handleSuccessfulRedirect(
            deps.buildPath<ContentItemPreviewRouteParams<UuidArray>>(ContentItemPreviewRoute, {
              app: ContentItemsAppRouteSegment.WebSpotlight,
              projectId,
              variantId,
              spaceId,
              contentItemIds: [rootItemId, itemId],
            }),
            {
              url: decodedSharedPreviewUrl,
              width,
              height,
            } satisfies SharedContentItemPreviewRouteState,
          ),
        );
      } else {
        dispatch(
          handleUnsuccessfulRedirect(
            SharedPreviewLinkUnsuccessfulRedirectText,
            SharedLinkHasInvalidVariantIdMessage,
            deps.buildPath<WebSpotlightEntryRouteParams>(WebSpotlightEntryRoute, { projectId }),
          ),
        );
      }
    } catch (error) {
      if (!isAbortError(error) && isXMLHttpRequest(error)) {
        if (error.status === 403) {
          dispatch(
            handleUnsuccessfulRedirect(
              SharedPreviewLinkUnsuccessfulRedirectText,
              SharedLinkUserDoesNotHavePermissionToItemVariantMessage,
              deps.buildPath<WebSpotlightEntryRouteParams>(WebSpotlightEntryRoute, { projectId }),
            ),
          );
          return;
        }

        if (error.status === 404) {
          dispatch(
            handleUnsuccessfulRedirect(
              SharedPreviewLinkUnsuccessfulRedirectText,
              SharedLinkContentDoesNotExistMessage,
              deps.buildPath<WebSpotlightEntryRouteParams>(WebSpotlightEntryRoute, { projectId }),
            ),
          );
          return;
        }
      }

      throw error;
    }
  };
