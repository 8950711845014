import { TabView } from '@kontent-ai/component-library/TabView';
import { ComponentProps } from 'react';

/**
 * UX came up with this design that goes completely against the grain of what has been designed before. I don't want to break the hierarchy
 * of the components that is required for TabView, hence I am adding these styles here. Reserve your judgement of me. Judge the source of
 * my misery and weep with me.
 *
 * The goal here was also to shove the unholy component into its separate file, give it an obvious name indicating its purpose and supposed
 * usage, and to have enough space to provide this lore. All three with the same aim to discourage the re-use of this component.
 * !!! DO NOT RE-USE THIS COMPONENT !!! It's an abomination.
 */
export const MissionControlTabPanel = (props: ComponentProps<typeof TabView.TabPanel>) => {
  return (
    <TabView.TabPanel
      css={`
        display: flex;
        flex-direction: column;
        // Fully stretch the panel for Content status. It only takes up the available height.
        height: 100%;
        min-height: 0;
        padding: 0;
      `}
      {...props}
    />
  );
};

MissionControlTabPanel.displayName = 'MissionControlTabPanel';
