import { History } from 'history';
import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import {
  AutoScrollId,
  CreateAutoScrollId,
} from '../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { isRelativeInsertPosition } from '../../../../_shared/models/utils/smartLinkCommandUtils.ts';
import { IAddButtonInitialMessageData } from '../../types/SmartLinkSdkApi.ts';
import { navigateToElementBase } from './navigateToElementBase.ts';

const getAddButtonTargetScrollId = (messageData: IAddButtonInitialMessageData): AutoScrollId => {
  const targetId = isRelativeInsertPosition(messageData.insertPosition)
    ? messageData.insertPosition.targetId
    : null;

  return messageData.contentComponentId
    ? CreateAutoScrollId.forComponentElement(
        messageData.elementCodename,
        messageData.contentComponentId,
        messageData.itemId,
        targetId,
      )
    : CreateAutoScrollId.forElement(messageData.elementCodename, messageData.itemId, targetId);
};

export const navigateToAddButtonTarget =
  (messageData: IAddButtonInitialMessageData, history: History): ThunkFunction =>
  (dispatch) => {
    const targetScrollId = getAddButtonTargetScrollId(messageData);
    dispatch(navigateToElementBase(messageData, history, targetScrollId));
  };
