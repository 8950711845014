import { IMultipleChoiceTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { IMultipleChoiceItemElement } from '../../../models/contentItemElements/MultipleChoiceItemElement.ts';

export const removeNonexistingMultipleChoiceOptions = (
  typeElement: IMultipleChoiceTypeElement,
  itemElement: IMultipleChoiceItemElement,
): IMultipleChoiceItemElement => {
  const currentOptions = itemElement.options;
  if (currentOptions && currentOptions.length > 0) {
    const availableOptions = typeElement.options;
    if (!availableOptions) {
      return {
        ...itemElement,
        options: [],
      };
    }
    const clearedOptions = currentOptions.filter((selected) => !!availableOptions[selected]);
    return {
      ...itemElement,
      options: clearedOptions,
    };
  }
  return itemElement;
};

const updateSelectedRadioOption = (
  currentOptions: readonly string[],
  optionId: string,
): readonly string[] => {
  if (currentOptions.some((id) => id === optionId)) {
    return [];
  }

  return [optionId];
};

const updateToggledCheckboxOption = (
  currentOptions: readonly string[],
  toggledOptionId: string,
): readonly string[] => {
  if (currentOptions.some((id) => id === toggledOptionId)) {
    return currentOptions.filter((id) => id !== toggledOptionId);
  }

  return currentOptions.concat([toggledOptionId]);
};

export const updateMultipleChoiceOptionsSelection = (
  typeElement: IMultipleChoiceTypeElement,
  itemElement: IMultipleChoiceItemElement,
  optionId: Uuid,
): IMultipleChoiceItemElement => {
  const updatedOptions =
    typeElement.mode === 'single'
      ? updateSelectedRadioOption(itemElement.options, optionId)
      : updateToggledCheckboxOption(itemElement.options, optionId);

  const updatedElement: IMultipleChoiceItemElement = {
    ...itemElement,
    options: updatedOptions,
  };

  return removeNonexistingMultipleChoiceOptions(typeElement, updatedElement);
};
