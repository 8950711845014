import { getConnectedStatusMessage } from '../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  StatusMessage,
} from '../../../_shared/models/StatusMessage.ts';
import { getCurrentUser } from '../../../_shared/selectors/getCurrentUser.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';

export const getUserProfileSavingStatusMessage = (state: IStore): StatusMessage => {
  const {
    sharedApp: {
      userProperties: { emailNotificationsBeingSaved },
    },
  } = state;

  return getCurrentUser(state).isBeingSaved || emailNotificationsBeingSaved
    ? SavingStatusMessage
    : SavedStatusMessage;
};

export const UserProfileSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getUserProfileSavingStatusMessage,
);
