import React from 'react';
import { useController } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { SubscriptionsRoute } from '../../../../_shared/constants/routePaths.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import {
  SampleProjectLocationsLimited,
  SampleProjectLocationsUnavailable,
} from '../../constants/errorMessages.ts';
import { SubscriptionSelectorWrapper } from './SubscriptionSelectorWrapper.tsx';

export interface ICreateSampleProjectFormShape {
  readonly name: string;
  readonly subscriptionId: string;
}

type ComponentProps = {
  readonly areSubscriptionsAvailable: boolean;
  readonly isNameFieldDisabled: boolean;
  readonly formProps: HookFormProps<ICreateSampleProjectFormShape>;
  readonly onSubmit: (() => void) | undefined;
  readonly subscriptions: ReadonlyArray<ISubscription>;
  readonly isCreationInProgress: boolean;
};

const createNewSubscriptionInfo = (
  <p>
    <Link to={buildPath(SubscriptionsRoute, {})}>Create a new subscription</Link>
    &nbsp;for the sample project.
  </p>
);

const SampleProjectLocationsLimitedWarning: React.FC = () => (
  <Warning subtitle="Limited choice of subscriptions">
    <p>{SampleProjectLocationsLimited}</p>
  </Warning>
);

const SampleProjectLocationsUnavailableWarning: React.FC = () => (
  <Warning subtitle="No subscriptions available">
    <p>{SampleProjectLocationsUnavailable}</p>
    {createNewSubscriptionInfo}
  </Warning>
);

export const CreateSampleProjectForm = (props: ComponentProps) => {
  const { field: nameField } = useController({ control: props.formProps.control, name: 'name' });

  return (
    <form className="form" onSubmit={props.onSubmit}>
      <FormGroup>
        <LabelFor
          target={(id: Uuid) => (
            <input
              className="form__text-field"
              value={nameField.value}
              onChange={nameField.onChange}
              disabled={props.isNameFieldDisabled || props.isCreationInProgress}
              id={id}
              type="text"
              {...getDataUiInputAttribute(DataUiInput.ProjectName)}
            />
          )}
        >
          Project name
        </LabelFor>
      </FormGroup>
      <FormGroup>
        {props.areSubscriptionsAvailable && (
          <SubscriptionSelectorWrapper<ICreateSampleProjectFormShape>
            name="subscriptionId"
            formProps={props.formProps}
            activeSubscriptions={props.subscriptions}
            disabled={props.isCreationInProgress}
          />
        )}
        <div className="create-sample-project-form__warning-wrapper">
          {props.areSubscriptionsAvailable ? (
            <SampleProjectLocationsLimitedWarning />
          ) : (
            <SampleProjectLocationsUnavailableWarning />
          )}
        </div>
      </FormGroup>
    </form>
  );
};

CreateSampleProjectForm.displayName = 'CreateSampleProjectForm';
