import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { getBlockCssSelector } from '../../../editorCore/utils/editorComponentUtils.ts';
import { BaseBlockType } from '../../../utils/blocks/blockType.ts';

interface IExpandedBlockStylesProps {
  readonly blockKey: string;
  readonly editorId: string;
}

const barItemBlockTypes: ReadonlyArray<BaseBlockType> = [
  BaseBlockType.ContentComponent,
  BaseBlockType.ContentModule,
];

export const ExpandedBlockStyles: React.FC<IExpandedBlockStylesProps> = ({
  blockKey,
  editorId,
}) => {
  const customBlockSleeveAfterCssSelector = `${getBlockCssSelector(
    editorId,
    blockKey,
  )} + .rte__custom-block-sleeve--is-after`;

  const spacingStyleString = barItemBlockTypes
    .map(
      (blockType) =>
        `${customBlockSleeveAfterCssSelector} + .rte__next--is-${blockType} {
      padding-top: ${Spacing.XL}px;
    }`,
    )
    .join('\n');

  const hoverStyleString = `${customBlockSleeveAfterCssSelector}:before {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  
    ${customBlockSleeveAfterCssSelector} div:before {
      border-top-right-radius: 0;
    }`;

  const inlineStyleString = `${spacingStyleString}

    ${hoverStyleString}`;

  return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
};

ExpandedBlockStyles.displayName = 'EnsureExpandedBlockSpacing';
