import { Collection, createGuid } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import {
  ITaxonomyGroup,
  createTaxonomyGroupDomainModel,
  emptyTaxonomyGroup,
} from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { emptyTaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Taxonomy_GroupCreate_Initialized,
  Taxonomy_GroupEdit_Cleared,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Group_CodenameChanged,
  Taxonomy_Group_CreationFinished,
  Taxonomy_Group_NameChanged,
  Taxonomy_Group_SavingFinished,
  Taxonomy_Groups_LoadingStarted,
  Taxonomy_Terms_TermCodenameChanged,
  Taxonomy_Terms_TermCreated,
  Taxonomy_Terms_TermMoved,
  Taxonomy_Terms_TermNameChanged,
  Taxonomy_Terms_TermRemoved,
} from '../../constants/taxonomyActionTypes.ts';
import { removeTerm } from '../../utils/taxonomyTreeUtils.ts';

const initialState = emptyTaxonomyGroup;

export const editedTaxonomyGroup = (
  state: ITaxonomyGroup = initialState,
  action: Action,
): ITaxonomyGroup => {
  switch (action.type) {
    case Taxonomy_GroupEdit_Cleared:
    case Taxonomy_Groups_LoadingStarted: {
      return initialState;
    }

    case Taxonomy_Terms_TermCreated: {
      const newTerm = {
        ...emptyTaxonomyTerm,
        id: createGuid(),
        name: action.payload.termName,
      };

      return {
        ...state,
        terms: Collection.add(state.terms, [newTerm.id, newTerm]),
        childIds: [...state.childIds, newTerm.id],
      };
    }
    case Taxonomy_Terms_TermCodenameChanged: {
      const { termId, termCodename } = action.payload;

      return {
        ...state,
        terms: Collection.replaceWith(state.terms, termId, (term) => ({
          ...term,
          codename: termCodename,
        })),
      };
    }

    case Taxonomy_Terms_TermNameChanged: {
      const { termId, termName } = action.payload;

      return {
        ...state,
        terms: Collection.replaceWith(state.terms, termId, (term) => ({
          ...term,
          name: termName,
        })),
      };
    }

    case Taxonomy_Terms_TermRemoved: {
      const termId = action.payload.termId;

      return removeTerm(state, termId);
    }

    case Taxonomy_GroupCreate_Initialized: {
      return {
        ...state,
        id: createGuid(),
      };
    }

    case Taxonomy_GroupEdit_Initialized:
    case Taxonomy_Terms_TermMoved: {
      return action.payload.editedTaxonomyGroup;
    }

    case Taxonomy_Group_CreationFinished:
    case Taxonomy_Group_SavingFinished: {
      const serverTaxonomyGroup: ITaxonomyGroupServerModel = action.payload.editedTaxonomyGroup;
      return createTaxonomyGroupDomainModel(serverTaxonomyGroup);
    }

    case Taxonomy_Group_NameChanged: {
      const { name } = action.payload;
      const newName = isEmptyOrWhitespace(name) ? '' : name;
      return {
        ...state,
        name: newName,
      };
    }

    case Taxonomy_Group_CodenameChanged: {
      const { codename } = action.payload;
      return {
        ...state,
        codename,
      };
    }

    default:
      return state;
  }
};
