import { ICollection } from '../../../../../data/models/collections/Collection.ts';

export enum CollectionSelectorState {
  ShowSuggested = 'ShowSuggested',
  ShowAllOnly = 'ShowAllOnly',
}

export const getCollectionSelectorState = (
  suggestedCollections: ReadonlyArray<ICollection>,
): CollectionSelectorState => {
  if (suggestedCollections.length > 0) {
    return CollectionSelectorState.ShowSuggested;
  }

  return CollectionSelectorState.ShowAllOnly;
};
