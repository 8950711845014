import React from 'react';
import { LinkDetail } from '../../../../../itemEditor/utils/itemElementDataConverters/utils/LinkDetail.tsx';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { NoPermissionsAssetLink } from './AssetLinkDetailVariants.tsx';
import { SpecificAssetLink } from './SpecificAssetLink.tsx';

export interface IAssetLinkDetailOwnProps {
  readonly disabled?: boolean;
  readonly onAssetClick: () => void;
  readonly onEdit?: () => void;
  readonly onUnlink?: () => void;
  readonly status?: EditorLinkStatus;
}

export interface IAssetLinkDetailStateProps {
  readonly assetArchived: boolean;
  readonly assetName: string;
  readonly assetPath?: string;
  readonly canViewContent: boolean;
  readonly exists: boolean;
  readonly isUploading: boolean;
  readonly uploadedSoFar: number | null;
}

type AssetLinkDetailProps = IAssetLinkDetailOwnProps & IAssetLinkDetailStateProps;

export const AssetLinkDetail: React.FC<AssetLinkDetailProps> = ({
  assetArchived,
  assetName,
  assetPath,
  canViewContent,
  disabled,
  exists,
  isUploading,
  onAssetClick,
  onEdit,
  onUnlink,
  status,
  uploadedSoFar,
}) => {
  return (
    <LinkDetail
      disabled={disabled || isUploading}
      isUploading={isUploading}
      onEdit={canViewContent && !isUploading ? onEdit : undefined}
      onUnlink={onUnlink}
      status={status}
      uploadedSoFar={uploadedSoFar ?? undefined}
    >
      {canViewContent ? (
        <SpecificAssetLink
          archived={assetArchived}
          assetName={assetName}
          assetPath={isUploading ? undefined : assetPath}
          disabled={disabled || isUploading}
          exists={exists}
          onAssetClick={onAssetClick}
        />
      ) : (
        <NoPermissionsAssetLink />
      )}
    </LinkDetail>
  );
};

AssetLinkDetail.displayName = 'AssetLinkDetail';
