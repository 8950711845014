import { roleBuilderAnyLanguageOptionName } from '../constants/roleBuilder.ts';
import { getRemainingLanguagesCount } from './getRemainingLanguagesCount.ts';

type Params = {
  readonly activeLanguageIds: ReadonlySet<Uuid>;
  readonly hasOnlySingleUserRole: boolean;
  readonly selectedLanguageOptionIds: ReadonlySet<Uuid>;
  readonly userRoleLanguageIds: ReadonlySet<Uuid>;
};

export const getAnyLanguageOptionName = (params: Params) => {
  const {
    activeLanguageIds,
    hasOnlySingleUserRole,
    userRoleLanguageIds,
    selectedLanguageOptionIds,
  } = params;

  if (hasOnlySingleUserRole) {
    return roleBuilderAnyLanguageOptionName;
  }

  const anyLanguagesCount = getRemainingLanguagesCount({
    activeLanguageIds,
    selectedLanguageOptionIds,
    userRoleLanguageIds,
  });

  return `Any remaining languages (${anyLanguagesCount})`;
};
