import { useMemo } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { useEditorWithPlugin } from './hooks/useEditorWithPlugin.tsx';
import { BaseEditor, BaseEditorProps } from './types/Editor.base.type.ts';
import { PluginComponent } from './types/Editor.composition.type.ts';
import {
  Api,
  Contract,
  InternalProps,
  None,
  Props,
  RequiredApi,
  RequiredProps,
  RequiredState,
  State,
} from './types/Editor.contract.type.ts';

type PluginError = {
  error: string;
  requiredState: unknown;
  requiredProps: unknown;
};

type ChainWithError = ReadonlyArray<Contract | PluginError>;

type ValidatedPluginChain<
  Chain extends ReadonlyArray<PluginComponent>,
  Base extends BaseEditor,
> = PluginChain<
  PluginsFromComponents<Chain>,
  State<Base>,
  Props<Base>,
  Api<Base>
> extends infer ChainHasError extends { chainWithError: ChainWithError }
  ? // Some plugin has chaining error (missing dependencies)
    ParamsFromErrorChain<ChainHasError['chainWithError']>
  : // Correctly composed editor
    Chain;

type ParamsFromErrorChain<Plugins extends ChainWithError> = {
  [Index in keyof Plugins]: Plugins[Index] extends infer ParamHasError extends PluginError
    ? PluginComponent & ParamHasError
    : Plugins[Index] extends Contract
      ? PluginComponent<Plugins[Index]>
      : PluginComponent;
};

type IsOptional<TPlugin extends Contract> = {
  readonly optional?: () => TPlugin;
};

type PluginFromComponent<Component extends PluginComponent> = Component extends IsOptional<
  infer OPlugin
>
  ? OptionalPlugin<OPlugin>
  : Component extends PluginComponent<infer Plugin>
    ? Plugin
    : never;

type PluginsFromComponents<Components extends ReadonlyArray<PluginComponent>> = {
  [Index in keyof Components]: PluginFromComponent<Components[Index]>;
};

type NonOptionalKeys<T> = {
  [K in keyof T]-?: None extends Pick<T, K> ? never : K;
}[keyof T];

type PluginChain<
  TPlugins extends ReadonlyArray<Contract>,
  TAllState = None,
  TAllProps = None,
  TAllApi = None,
  TValidPluginChain extends any[] = [],
> = TPlugins extends readonly [
  [
    infer TState,
    infer TProps,
    infer TApi,
    infer TRequiredState,
    infer TRequiredProps,
    infer TRequiredApi,
    infer TInternalProps,
  ],
  ...infer TRemainingPlugins extends ReadonlyArray<Contract>,
]
  ? [TAllState, TAllProps, TAllApi] extends [TRequiredState, TRequiredProps, TRequiredApi]
    ? PluginChain<
        TRemainingPlugins,
        TAllState & TState,
        TAllProps & TProps,
        TAllApi & TApi,
        [
          ...TValidPluginChain,
          [
            state: TState,
            props: TProps,
            api: TApi,
            requiredState: TRequiredState,
            requiredProps: TRequiredProps,
            requiredApi: TRequiredApi,
            internalProps: TInternalProps,
          ],
        ]
      >
    : {
        chainWithError: [
          ...TValidPluginChain,
          {
            error: 'This plugin has some missing dependencies that must be applied before it';
            // We use keyof as a helper to get subtracted aliased types in the error output
            // with Omit, it outputs all involved types in a long chain in an expanded Omit expression which is not very readable
            requiredState: keyof Omit<TRequiredState, NonOptionalKeys<TAllState>>;
            requiredProps: keyof Omit<TRequiredProps, NonOptionalKeys<TAllProps>>;
            requiredApi: keyof Omit<TRequiredApi, NonOptionalKeys<TAllApi>>;
          },
          ...TRemainingPlugins,
        ];
      }
  : TValidPluginChain;

type CombineMany<
  Plugins extends ReadonlyArray<Contract>,
  Base extends Contract = BaseEditor,
> = Plugins extends readonly [
  [
    infer MoreProvidedState,
    infer MoreProvidedProps,
    infer MoreProvidedApi,
    any,
    any,
    any,
    infer MoreProvidedInternalProps,
  ],
  ...infer Rest extends ReadonlyArray<Contract>,
]
  ? CombineMany<
      Rest,
      [
        Base[0] & MoreProvidedState,
        Base[1] & MoreProvidedProps,
        Base[2] & MoreProvidedApi,
        None,
        None,
        None,
        Base[6] & MoreProvidedInternalProps,
      ]
    >
  : Base;

type PluginComposition<TContract extends Contract> = {
  readonly plugins: ReadonlyArray<PluginComponent>;
  readonly contract?: () => TContract;
};

export const emptyPluginComposition: PluginComposition<BaseEditor> = {
  plugins: [],
};

/**
 * This hook verifies the plugin types and their order and returns a complete plugin composition
 * that can be passed to useEditor hook to render the editor
 *
 * Wrap in a factory such as
 *   const useComposition = () => usePluginComposition(...)
 * and use
 *   EditorProps<typeof useComposition>
 * to infer the props of the built editor component
 *
 * @params list of plugins to use in the editor
 */
export const usePluginComposition = <
  TBaseEditor extends BaseEditor,
  PluginComponents extends ReadonlyArray<PluginComponent>,
  FinalContract extends BaseEditor = CombineMany<
    PluginsFromComponents<PluginComponents>,
    TBaseEditor
  >,
>(
  base: PluginComposition<TBaseEditor>,
  ...plugins: ValidatedPluginChain<PluginComponents, TBaseEditor>
): PluginComposition<FinalContract> =>
  // biome-ignore lint/correctness/useExhaustiveDependencies(plugins.filter): We need to memoize the created component to prevent remounting upon rerender
  useMemo(
    () => ({ plugins: [...base.plugins, ...plugins.filter((plugin) => plugin !== NoPlugin)] }),
    [...plugins, base.plugins],
  );

// We still require all inputs as the plugin may be present, just may not expose state and API
type OptionalPlugin<TPlugin extends Contract> = [
  Partial<State<TPlugin>>,
  Props<TPlugin>,
  Partial<Api<TPlugin>>,
  RequiredState<TPlugin>,
  RequiredProps<TPlugin>,
  RequiredApi<TPlugin>,
  InternalProps<TPlugin>,
];

// We use this as a null value component that represents a not used optional plugin
// We filter it out in plugin composition, so it should never be rendered
const NoPlugin: PluginComponent = (_) => {
  throw new Error('Disabled plugin should be filtered out by the editor framework');
};

/**
 * This hook conditionally applies plugin in the usePluginComposition
 * The optional plugin still requires all its props (as it may be present)
 * but exposes all its state values and callbacks as optional (as it may not be present)
 *
 * @param isEnabledSelector - selector that evaluates whether the plugin is enabled
 * @param plugin - optional plugin
 */
export const useOptionalPlugin = <Component extends PluginComponent>(
  isEnabledSelector: (state: IStore) => boolean,
  plugin: Component,
): Component & IsOptional<PluginFromComponent<Component>> =>
  useSelector(isEnabledSelector) ? plugin : (NoPlugin as Component);

export type EditorProps<
  TPluginCompositionFactory extends (...args: any[]) => PluginComposition<any>,
> = BaseEditorProps & TPluginCompositionFactory extends (
  ...args: any[]
) => PluginComposition<infer TContract>
  ? Omit<BaseEditorProps & Props<TContract>, 'plugins' | 'hooks'>
  : never;

type PropsFromComposition<TComposition extends PluginComposition<any>> = BaseEditorProps &
  TComposition extends PluginComposition<infer TContract>
  ? Omit<Props<TContract> & InternalProps<TContract>, 'plugins' | 'hooks'>
  : never;

/**
 * This hook renders the editor using the given plugin composition
 *
 * @param composition - composition of plugins returned from usePluginComposition
 * @param props - properties required by the base editor and plugins
 */
export const useEditor = <
  TComposition extends PluginComposition<any>,
  TProps extends BaseEditorProps & PropsFromComposition<TComposition>,
>(
  composition: TComposition,
  props: TProps,
) => useEditorWithPlugin({ ...props, plugins: composition.plugins }, {});
