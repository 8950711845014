import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_Editing_Left,
  Workflow_Editing_NameChanged,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
} from '../../constants/workflowActionTypes.ts';

export const editedWorkflowName = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished:
      return action.payload.workflow.name;

    case Workflow_Editing_NameChanged:
      return action.payload.name;

    case Workflow_Editing_Left:
      return '';

    default:
      return state;
  }
};
