import {
  FriendlyWarningReason,
  IFriendlyWarning,
} from '../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { Warning } from '../../../../components/infos/Warning.tsx';
import { ModalSection } from '../ModalSection.tsx';
import { FriendlyWarningMessageForMultipleUsersEditing } from './FriendlyWarningMessageForMultipleUsersEditing.tsx';

type Props = {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning> | undefined;
};

export const ArchivingFriendlyWarningSection = ({
  activeUsersNames,
  friendlyWarningReasons,
}: Props) => {
  const filteredReasons =
    !!friendlyWarningReasons &&
    friendlyWarningReasons.filter(
      (w) => !!w && !!w.reason && w.reason === FriendlyWarningReason.ItemIsEditedByMultipleUsers,
    );

  if (!filteredReasons || !filteredReasons.length) {
    return null;
  }

  const infoMessage = `If you archive the item, ${
    activeUsersNames.length > 1 ? 'these users' : 'this user'
  } won’t be able to make further content changes.`;

  return (
    <ModalSection>
      <Warning subtitle="Archive content item?">
        <FriendlyWarningMessageForMultipleUsersEditing
          activeUsersNames={activeUsersNames}
          infoMessage={infoMessage}
        />
      </Warning>
    </ModalSection>
  );
};
