import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  Workflow_Delete_Finished,
  Workflow_Editing_WorkflowSaveFinished,
} from '../../../../applications/environmentSettings/workflow/constants/workflowActionTypes.ts';
import { Data_Workflow_Success } from '../../../constants/dataActionTypes.ts';
import { Workflow, getWorkflowFromServerModel } from '../../../models/workflow/Workflow.ts';

const initialState: ReadonlyMap<Uuid, Workflow> = new Map();

export function byId(
  state: ReadonlyMap<Uuid, Workflow> = initialState,
  action: Action,
): ReadonlyMap<Uuid, Workflow> {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Workflow_Delete_Finished: {
      const { workflowId } = action.payload;

      return Collection.remove(state, workflowId);
    }

    case Workflow_Editing_WorkflowSaveFinished: {
      const { workflow } = action.payload;

      return Collection.add(state, [workflow.id, getWorkflowFromServerModel(workflow)]);
    }

    case Data_Workflow_Success: {
      return action.payload.data.reduce(
        (map, workflowServerModel) =>
          map.set(workflowServerModel.id, getWorkflowFromServerModel(workflowServerModel)),
        new Map<Uuid, Workflow>(),
      );
    }

    default:
      return state;
  }
}
