import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Data_ItemsRecentlyEditedByCurrentUser_Failed,
  Data_ItemsRecentlyEditedByCurrentUser_Started,
  Data_ItemsRecentlyEditedByCurrentUser_Success,
} from '../../../constants/dataActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
    case Data_ItemsRecentlyEditedByCurrentUser_Failed:
      return LoadingStatus.InitialEmpty;

    case Data_ItemsRecentlyEditedByCurrentUser_Success:
      return LoadingStatus.Loaded;

    case Data_ItemsRecentlyEditedByCurrentUser_Started:
      return LoadingStatus.Loading;

    default:
      return state;
  }
};
