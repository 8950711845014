import { InvariantException, isAbortError, isError } from '@kontent-ai/errors';
import { ThunkFunction, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IContentItemRepository } from '../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { JsonPatchOperation } from '../../../../repositories/utils/jsonPatchConstants.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ILinkedItemsTypeElement } from '../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { isSubpagesTypeElement } from '../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { IParseContentItemVariant } from '../../../itemEditor/features/ContentItemEditing/utils/parseContentItem.ts';
import { ILinkedItemsElement } from '../../../itemEditor/models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { getElementById } from '../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import {
  convertElementToServerModel,
  createItemElementPatchPath,
} from '../../../itemEditor/stores/utils/contentItemHelperMethods.ts';
import { GetAssets } from '../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import {
  WebSpotlight_LinkPageItems_Failed,
  WebSpotlight_LinkPageItems_Finished,
  WebSpotlight_LinkPageItems_Started,
} from '../../constants/webSpotlightActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
  readonly parseContentItemVariant: IParseContentItemVariant;
  readonly onLinkedItemsElementValueChange: (
    itemElement: ILinkedItemsElement,
    typeElement: ILinkedItemsTypeElement,
  ) => ThunkFunction;
}

const started = () =>
  ({
    type: WebSpotlight_LinkPageItems_Started,
  }) as const;

const finished = () =>
  ({
    type: WebSpotlight_LinkPageItems_Finished,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: WebSpotlight_LinkPageItems_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type LinkPageItemsActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export type ILinkPageItemsAction = (
  contentItemId: Uuid,
  variantId: Uuid,
  elementId: Uuid,
  contentItemIds: UuidArray,
) => ThunkPromise;

export const linkPageItemsActionCreator =
  (deps: IDeps): ILinkPageItemsAction =>
  (
    itemId: Uuid,
    variantId: Uuid,
    elementId: Uuid,
    contentItemIds: UuidArray,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItem, loadedContentItemTypes },
    } = getState();

    try {
      dispatch(started());

      const variant = await deps.contentItemRepository.getItemWithVariant(
        itemId,
        variantId,
        abortSignal,
      );
      const parsedItemVariant = deps.parseContentItemVariant(variant);

      const elementData = getElementById<ILinkedItemsElement>(
        elementId,
        parsedItemVariant.editedContentItemVariantElements,
      );
      if (!elementData) {
        dispatch(failed('Unable to link pages, the target element was not found.'));
        return;
      }

      if (elementData.type !== ElementType.LinkedItems) {
        throw InvariantException(
          `linkPageItems.ts: Target element has invalid type ${elementData.type}.`,
        );
      }

      const updatedElementData: ILinkedItemsElement = {
        ...elementData,
        value: elementData.value.concat(contentItemIds).toList(),
      };

      const getAssets: GetAssets = () => getState().data.assets.byId;
      const elementServerModel = convertElementToServerModel(updatedElementData, { getAssets });

      const patchData = {
        op: JsonPatchOperation.Replace,
        path: createItemElementPatchPath(elementId),
        value: elementServerModel,
      };

      await deps.contentItemRepository.patchItemVariant(
        itemId,
        variantId,
        [patchData],
        abortSignal,
      );

      if (editedContentItem && editedContentItem.id === itemId) {
        const editedContentItemType = loadedContentItemTypes.get(
          editedContentItem.editedContentItemTypeId,
        );
        if (!editedContentItemType) {
          throw InvariantException(
            `linkPageItems.ts: Edited content item type id ${editedContentItem.editedContentItemTypeId} not found within loadedContentItemTypes.`,
          );
        }

        const typeElement = editedContentItemType.contentElements.find(
          (element) => element.elementId === elementId,
        );
        if (!typeElement) {
          throw InvariantException('linkPageItems.ts: Target type element was not found.');
        }
        if (!isSubpagesTypeElement(typeElement)) {
          throw InvariantException(
            `linkPageItems.ts: Target type element has invalid type ${elementData.type}.`,
          );
        }

        dispatch(deps.onLinkedItemsElementValueChange(updatedElementData, typeElement));
      }

      dispatch(finished());
    } catch (error) {
      if (!isAbortError(error) && isError(error)) {
        dispatch(failed(error.message));
      }

      throw error;
    }
  };
