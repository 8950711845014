import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { AssetLibrary_Folders_Opened } from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../../../applications/contentInventory/assets/features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import { Data_AssetListing_Success } from '../../../constants/dataActionTypes.ts';

const initialState: string | null = null;

export function nextContinuationToken(state = initialState, action: Action): string | null {
  switch (action.type) {
    case Data_AssetListing_Success: {
      const payload = action.payload;
      return payload.continuationToken || null;
    }

    case AssetSelector_Opened:
    case AssetLibrary_Folders_Opened:
    case Shared_CurrentProjectId_Updated: {
      return initialState;
    }

    default:
      return state;
  }
}
