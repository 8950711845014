import { combineReducers } from '@reduxjs/toolkit';
import { createEnvironmentDialogSettings } from './internalReducers/createEnvironmentDialogSettings.ts';
import { creatingEnvironment } from './internalReducers/creatingEnvironment.ts';
import { creatingEnvironmentFailed } from './internalReducers/creatingEnvironmentFailed.ts';
import { deletingEnvironment } from './internalReducers/deletingEnvironment.ts';
import { deletingEnvironmentFailed } from './internalReducers/deletingEnvironmentFailed.ts';
import { editedEnvironmentId } from './internalReducers/editedEnvironmentId.ts';
import { environmentRoles } from './internalReducers/environmentRoles.ts';
import { environmentUiStates } from './internalReducers/environmentUiStates.ts';
import { loadingStatus } from './internalReducers/loadingStatus.ts';
import { savingStatus } from './internalReducers/savingStatus.ts';
import { showDialogs } from './internalReducers/showDialogs.ts';
import { swappingEnvironment } from './internalReducers/swappingEnvironment.ts';
import { swappingEnvironmentFailed } from './internalReducers/swappingEnvironmentFailed.ts';

export const environmentsAppReducer = combineReducers({
  createEnvironmentDialogSettings,
  creatingEnvironment,
  creatingEnvironmentFailed,
  deletingEnvironment,
  deletingEnvironmentFailed,
  editedEnvironmentId,
  environmentRoles,
  environmentUiStates,
  loadingStatus,
  savingStatus,
  showDialogs,
  swappingEnvironment,
  swappingEnvironmentFailed,
});
