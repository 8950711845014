import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_Creator_Submitted,
  Collections_FilterSearchPhrase_Changed,
  Collections_Listing_Initialized,
} from '../../constants/actionTypes.ts';

const emptyState: ReadonlyArray<Uuid> = [];

export const recentlyCreatedCollectionIds = (
  state = emptyState,
  action: Action,
): ReadonlyArray<Uuid> => {
  switch (action.type) {
    case Collections_Creator_Submitted: {
      return [action.payload.collectionId, ...state];
    }

    case Collections_FilterSearchPhrase_Changed:
    case Collections_Listing_Initialized: {
      return emptyState;
    }

    default:
      return state;
  }
};
