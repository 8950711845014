import { Action } from '../../../../@types/Action.type.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduleToPublishMethod_Changed,
  ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed,
  ChangeWorkflowStep_WorkflowStep_Selected,
} from '../constants/changeWorkflowStepActionTypes.ts';
import { ScheduleMethod } from '../constants/scheduleMethods.ts';
import { ChangeWorkflowStepModalAction } from '../types/workflowStepModalActionTypes.ts';

const defaultState = null;

export function workflowStepAction(state = defaultState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.workflowStepAction;

    case ChangeWorkflowStep_WorkflowStep_Selected:
      return action.payload.workflowStepAction;

    case ChangeWorkflowStep_ScheduleToPublishMethod_Changed:
      return action.payload.method === ScheduleMethod.Now
        ? ChangeWorkflowStepModalAction.Publish
        : ChangeWorkflowStepModalAction.ScheduleToPublish;

    case ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed:
      return action.payload.method === ScheduleMethod.Now
        ? ChangeWorkflowStepModalAction.UnpublishAndArchive
        : ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive;

    default:
      return state;
  }
}
