import {
  BorderRadius,
  Color,
  Spacing,
  borderWidthDefault,
  px,
  shadowFocusStyles,
  transition250,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';
import { tagHeight } from '../tokens.ts';

interface IBaseButtonProps {
  readonly isFocused?: boolean;
  readonly disabled?: boolean;
  readonly interactive?: boolean;
  readonly backgroundHover: Color;
  readonly borderColor: Color;
}

interface IStyledLabelButtonWrapperProps {
  readonly isRemovable?: boolean;
}

interface ILabelButtonProps extends IBaseButtonProps, IStyledLabelButtonWrapperProps {}

interface IRemoveButtonProps extends IBaseButtonProps {}

const getBaseButtonStyles = ({ disabled, interactive, backgroundHover }: IBaseButtonProps) => css`
  background: transparent;
  transition: background ${transition250};

  &:hover {
    ${!disabled && interactive && css`background: ${backgroundHover};`};
  }
`;

const getLabelButtonRightPadding = (props: ILabelButtonProps) =>
  px(props.isRemovable ? Spacing.M : Spacing.L - borderWidthDefault);

const getLabelButtonBorderRadius = (props: IStyledLabelButtonWrapperProps) =>
  props.isRemovable
    ? `${px(BorderRadius.Pill)} 0 0 ${px(BorderRadius.Pill)}`
    : px(BorderRadius.Pill);

const getLabelButtonBorderWidthStyles = ({ isRemovable }: ILabelButtonProps) => css`
  border-width: ${px(borderWidthDefault)};
  ${isRemovable ? css`border-right-width: 0` : ''}
`;

const getLabelCursor = (props: ILabelButtonProps) => {
  if (props.disabled) {
    return css`cursor: not-allowed`;
  }
  return css`cursor: ${props.interactive ? 'pointer' : 'default'}`;
};

export const StyledLabelButtonWrapper = styled.div<IStyledLabelButtonWrapperProps>`
  border-radius: ${getLabelButtonBorderRadius};
  min-width: 0;
`;

export const StyledLabelButton = styled.div<ILabelButtonProps>`
  ${getBaseButtonStyles};
  ${getLabelButtonBorderWidthStyles};
  border-color: ${({ borderColor }) => borderColor};
  border-style: solid;
  display: flex;
  align-items: center;
  gap: ${px(Spacing.S)};
  max-width: 100%;
  height: ${px(tagHeight)};
  padding: 0 ${getLabelButtonRightPadding} 0 ${px(Spacing.L - borderWidthDefault)};
  border-radius: inherit;

  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    inset: ${px(borderWidthDefault * -1)};
    right:0;
    border-radius: inherit;
    ${({ isFocused }) => isFocused && css`${shadowFocusStyles};`};
  }
  
  // Increase specificity to override normalize.css styles
  && {
    ${getLabelCursor};
  }
`;

export const StyledRemoveButtonWrapper = styled.div`
  border-radius: 0 ${px(BorderRadius.Pill)} ${px(BorderRadius.Pill)} 0;
`;

export const StyledRemoveButton = styled.button<IRemoveButtonProps>`
  ${getBaseButtonStyles};
  border-width: ${px(borderWidthDefault)};
  border-left-width: 0;
  border-color: ${({ borderColor }) => borderColor};
  border-style: solid;
  display: flex;
  align-items: center;
  height: ${px(tagHeight)};
  padding: 0 ${px(Spacing.M - borderWidthDefault)} 0 ${px(Spacing.S)};
  border-radius: inherit;
  
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    inset: ${px(borderWidthDefault * -1)};
    left:0;
    border-radius: inherit;
    ${({ isFocused }) => isFocused && css`${shadowFocusStyles};`};
  }
  
  // Increase specificity to override normalize.css styles
  && {
    ${({ disabled }) => disabled && css`cursor: not-allowed`};
  }
`;
