import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  ContentModels_TypeEditor_ErrorMessageCleared,
  ContentModels_TypeEditor_FirstInvalidElement_Focused,
  ContentModels_TypeEditor_FirstInvalidElement_Set,
  ContentModels_TypeEditor_FocusElementMovedToPreviousPosition,
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_Left,
  ContentModels_TypeEditor_RemoveTypeElement,
  ContentModels_TypeEditor_SaveAttempted,
  ContentModels_TypeEditor_SavingStarted,
  ContentModels_TypeEditor_TypeElementCreated,
  ContentModels_TypeEditor_TypeElementDropped,
  ContentModels_TypeEditor_TypeElementMoved,
  ContentModels_TypeEditor_TypeElementPickedUp,
  ContentModels_TypeEditor_TypeElementUpdated,
  ContentModels_TypeEditor_TypeElementValidated,
  ContentModels_TypeEditor_TypeLoaded,
} from '../constants/sharedContentModelsActionTypes.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { ConditionElementId } from '../types/ConditionElementId.type.ts';
import { TypeElementValidationResult } from '../utils/typeElementValidators/types/TypeElementValidationResult.type.ts';

export const editorLeft = () =>
  ({
    type: ContentModels_TypeEditor_Left,
  }) as const;

export const firstInvalidElementFocused = () =>
  ({
    type: ContentModels_TypeEditor_FirstInvalidElement_Focused,
  }) as const;

export const setFirstInvalidElement = (element: IBaseTypeElementData) =>
  ({
    type: ContentModels_TypeEditor_FirstInvalidElement_Set,
    payload: {
      element,
    },
  }) as const;

export const clearTypeEditorErrorMessage = () =>
  ({
    type: ContentModels_TypeEditor_ErrorMessageCleared,
  }) as const;

export const typeElementPickedUp = (typeElementId: Uuid) =>
  ({
    type: ContentModels_TypeEditor_TypeElementPickedUp,
    payload: { typeElementId },
  }) as const;

export const typeElementDropped = () =>
  ({
    type: ContentModels_TypeEditor_TypeElementDropped,
  }) as const;

export const moveTypeElement = (sourceId: Uuid, targetId: Uuid) =>
  ({
    type: ContentModels_TypeEditor_TypeElementMoved,
    payload: {
      sourceId,
      targetId,
    },
  }) as const;

export const removeTypeElement = (typeElementId: Uuid) =>
  ({
    type: ContentModels_TypeEditor_RemoveTypeElement,
    payload: { typeElementId },
  }) as const;

export const focusElementMovedToPreviousPosition = (typeElementId: Uuid) =>
  ({
    type: ContentModels_TypeEditor_FocusElementMovedToPreviousPosition,
    payload: { typeElementId },
  }) as const;

export const newTypeElementCreated = (typeElementData: IBaseTypeElementData, position: number) =>
  ({
    type: ContentModels_TypeEditor_TypeElementCreated,
    payload: {
      typeElementData,
      position,
    },
  }) as const;

export const typeElementValidated = (
  elementId: Uuid,
  validationResult: TypeElementValidationResult,
) =>
  ({
    type: ContentModels_TypeEditor_TypeElementValidated,
    payload: {
      elementId,
      validationResult,
    },
  }) as const;

export const typeElementUpdated = (updatedTypeElement: IBaseTypeElementData) =>
  ({
    type: ContentModels_TypeEditor_TypeElementUpdated,
    payload: { updatedTypeElement },
  }) as const;

export const saveEditedTypeStarted = () =>
  ({
    type: ContentModels_TypeEditor_SavingStarted,
  }) as const;

export const initTypeEditorStarted = () =>
  ({
    type: ContentModels_TypeEditor_InitStarted,
  }) as const;

export const typeEditorLoaded = (editedContentType: IContentType) =>
  ({
    type: ContentModels_TypeEditor_TypeLoaded,
    payload: {
      editedContentType,
    },
  }) as const;

export const typeEditorSaveAttempted = (activeConditionIds: ReadonlyArray<ConditionElementId>) =>
  ({
    type: ContentModels_TypeEditor_SaveAttempted,
    payload: {
      activeConditionIds,
    },
  }) as const;
