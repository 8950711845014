import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IDragActionProps {
  readonly className?: string;
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled?: boolean;
}

export const DragAction = React.forwardRef<HTMLDivElement, IDragActionProps>(
  ({ className, connectDragSource, disabled }, forwardedRef) => {
    const { refToForward } = useAttachRef(forwardedRef);

    const refWithDragSource = useCallback(
      (instance: HTMLDivElement | null) => {
        refToForward(instance);
        connectDragSource?.(instance);
      },
      [refToForward, connectDragSource],
    );

    return (
      /* Firefox doesn't allow dragging by the button and only responds to drag on its children (icon without the hover area around) */
      /* Therefore we cannot use button tag directly, instead we use DIV with button styles for the drag action */
      <div
        ref={disabled ? forwardedRef : refWithDragSource}
        className={classNames(
          className,
          'btn',
          'btn--quinary',
          'btn--square',
          'btn--square-quinary',
          'drag-action',
          { disabled },
        )}
        {...getDataUiActionAttribute(DataUiAction.Drag)}
      >
        <Icon iconName={IconName.DotsVertical} />
        <span className="sr-only">Drag</span>
      </div>
    );
  },
);

DragAction.displayName = 'DragAction';
