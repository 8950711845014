import {
  loadContentTypes,
  loadUsers,
  loadWorkflows,
} from '../../../data/actions/thunkDataActions.ts';
import { initMissionControlActionCreator } from '../../unifiedMissionControl/actions/thunks/initMissionControl.ts';

export const initMissionControl = initMissionControlActionCreator({
  loadUsers,
  loadContentTypes,
  loadWorkflows,
});
