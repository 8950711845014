import { Callout } from '@kontent-ai/component-library/Callout';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Spacing,
  colorBackgroundLowEmphasis,
  gridUnit,
} from '@kontent-ai/component-library/tokens';
import { useMemo, useRef } from 'react';
import { Route } from 'react-router';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  SubscriptionUserDetailRoute,
  SubscriptionUserDetailRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { ByStatus } from '../../../../_shared/constants/userListingFilter.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import {
  IRouteContext,
  RouteContext,
} from '../../../../_shared/containers/routing/RouteContext.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createSubscriptionUserListingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import {
  SubscriptionHasNoProjectsHeading,
  SubscriptionHasNoProjectsText,
} from '../../shared/constants/UIConstants.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { SubscriptionOverviewBar } from '../containers/SubscriptionOverviewBar.tsx';
import { SubscriptionUsersScrollTable } from '../containers/SubscriptionUsersScrollTable.tsx';
import { ISubscriptionUsersListingFilter } from '../models/SubscriptionUserListingFilter.ts';
import { ISubscriptionUsersUsage } from '../models/SubscriptionUsersUsage.ts';
import { SubscriptionProjectsAndEnvironments } from '../selectors/getSubscriptionProjectsAndEnvironments.ts';
import { SubscriptionUserListingFilter } from './ListingFilter/SubscriptionUserListingFilter.tsx';
import { StyledSubscriptionUsersListingMessage } from './StyledSubscriptionUsersListingMessage.tsx';
import { UserDetail } from './UserDetail/UserDetail.tsx';
import { UserDetailDataEnsurer } from './UserDetail/UserDetailDataEnsurer.tsx';

type Props = {
  readonly filter: ISubscriptionUsersListingFilter;
  readonly onClearFilter: () => void;
  readonly subscriptionId: Uuid;
  readonly subscriptionPlan: IPlan;
  readonly subscriptionProjectsAndEnvironments?: SubscriptionProjectsAndEnvironments;
  readonly subscriptionUsersUsage: ISubscriptionUsersUsage | null;
};

export const SubscriptionUsers = ({
  filter,
  onClearFilter,
  subscriptionId,
  subscriptionPlan,
  subscriptionProjectsAndEnvironments,
  subscriptionUsersUsage,
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const userListingLink = useMemo(
    () =>
      createSubscriptionUserListingLink({
        subscriptionId,
      }),
    [subscriptionId],
  );

  if (!subscriptionUsersUsage || !subscriptionProjectsAndEnvironments) {
    return <Loader />;
  }

  if (!subscriptionProjectsAndEnvironments.byMasterEnvironmentId.size) {
    return (
      <div
        className="canvas__content"
        {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionUsers)}
      >
        <div
          className="canvas__content-pane canvas__content-pane--is-not-project-specific project-management-pane"
          data-hj-suppress=""
        >
          <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Users} />
          <Stack spacing={Spacing.XL}>
            <PageTitle>Subscription users</PageTitle>
            <EmptyState>
              <EmptyState.Title>{SubscriptionHasNoProjectsHeading}</EmptyState.Title>
              <EmptyState.Content>{SubscriptionHasNoProjectsText}</EmptyState.Content>
            </EmptyState>
          </Stack>
        </div>
      </div>
    );
  }

  const areDeactivatedUsersShown =
    filter.byStatus === ByStatus.Deactivated || filter.byStatus === ByStatus.ActiveAndDeactivated;

  return (
    <div
      className="canvas__content"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionUsers)}
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Users} />
      <div className="canvas__notifications">
        <NotificationBar />
      </div>
      <IntersectionObserverRootContextProvider rootRef={scrollContainerRef}>
        <div
          className="canvas__content-pane canvas__content-pane--is-not-project-specific project-management-pane"
          data-hj-suppress=""
          ref={scrollContainerRef}
        >
          <StyledSubscriptionUsersListingMessage>
            <ListingMessage
              statusInfoMessage={{
                text: 'Subscription users',
                style: StatusMessageStyle.Headline,
              }}
            />
          </StyledSubscriptionUsersListingMessage>
          <SubscriptionOverviewBar subscriptionPlan={subscriptionPlan} />
          <Stack spacing={Spacing.XL}>
            <Paper
              padding={Spacing.L}
              borderRadius={BorderRadius.M}
              backgroundColor={colorBackgroundLowEmphasis}
              {...getDataUiElementAttribute(DataUiElement.Filter)}
            >
              <SubscriptionUserListingFilter filter={filter} onClearFilter={onClearFilter} />
            </Paper>
            {areDeactivatedUsersShown && (
              <Callout
                calloutType="quickTip"
                hideSubheadline
                maxWidth={109 * gridUnit}
                headline="Do I pay for deactivated users?"
              >
                <p>
                  In short, no. Users that are deactivated in all projects don’t count towards the
                  user quota in your subscription plan.
                </p>
                <p>
                  You can deactivate a user by toggling the switch in their subscription status.
                </p>
              </Callout>
            )}

            <SubscriptionUsersScrollTable
              subscriptionId={subscriptionId}
              subscriptionProjectsAndEnvironments={subscriptionProjectsAndEnvironments}
              subscriptionUsers={subscriptionUsersUsage.subscriptionUsers}
            />
          </Stack>

          <Route path={SubscriptionUserDetailRoute}>
            <RouteContext>
              {({ match }: IRouteContext<SubscriptionUserDetailRouteParams>) => (
                <UserDetailDataEnsurer
                  userId={match.params.userId}
                  userListingLink={userListingLink}
                >
                  {(user, onNavigateBack) => (
                    <UserDetail user={user} onNavigateBack={onNavigateBack} />
                  )}
                </UserDetailDataEnsurer>
              )}
            </RouteContext>
          </Route>
        </div>
      </IntersectionObserverRootContextProvider>
    </div>
  );
};
