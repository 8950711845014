import { assert } from '@kontent-ai/utils';
import { IUserInfo } from '../../../_shared/models/UserInfo.ts';
import {
  filterOutAnyCollectionId,
  filterOutAnyLanguageId,
} from '../../../applications/environmentSettings/users/utils/projectContributorRoleUtils.ts';
import {
  ICollectionGroupServerModel,
  IProjectContributorRoleServerModel,
  IProjectContributorServerModel,
} from '../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';

export interface IProjectContributor extends IUserInfo {
  readonly inactive: boolean;
  readonly isActivating: boolean;
  readonly isAdminOnProject: boolean;
  readonly isDeactivating: boolean;
  readonly isGdprForgotten: boolean;
  readonly isInvitationPending: boolean;
  readonly isInviteRevoking: boolean;
  readonly isInviteSending: boolean;
  readonly isInviteSent: boolean;
  readonly isVirtual: boolean;
  readonly collectionGroups: ReadonlyArray<ICollectionGroupRoles>;
}

export interface ICollectionGroupRoles {
  readonly collectionIds: UuidArray;
  readonly roles: ReadonlyArray<IProjectContributorRole>;
}

export interface IProjectContributorRole {
  readonly languageIds: ReadonlyArray<Uuid>;
  readonly projectUserRoleId?: Uuid;
  readonly roleId: Uuid;
}

export const emptyProjectContributorInfo: IProjectContributor = {
  email: '',
  firstName: '',
  inactive: true,
  isActivating: false,
  isAdminOnProject: false,
  isDeactivating: false,
  isGdprForgotten: false,
  isInvitationPending: false,
  isInviteRevoking: false,
  isInviteSending: false,
  isInviteSent: false,
  isVirtual: false,
  lastName: '',
  collectionGroups: [],
  userId: '',
};

export function createProjectContributor(
  params?: Partial<IProjectContributor>,
): IProjectContributor {
  return {
    ...emptyProjectContributorInfo,
    ...params,
  };
}

const createCollectionGroupRolesFromServerModel = (
  collectionGroups: ReadonlyArray<ICollectionGroupServerModel> | undefined,
): ReadonlyArray<ICollectionGroupRoles> =>
  (collectionGroups || []).map(
    (collectionGroup: ICollectionGroupServerModel): ICollectionGroupRoles => ({
      collectionIds: collectionGroup.collectionIds,
      roles: createContributorRolesFromServerModel(collectionGroup.roles),
    }),
  );

const createContributorRolesFromServerModel = (
  roles: ReadonlyArray<IProjectContributorRoleServerModel> | undefined,
): ReadonlyArray<IProjectContributorRole> =>
  (roles || []).map(
    (role: IProjectContributorRoleServerModel): IProjectContributorRole => ({
      languageIds: role.languageIds,
      projectUserRoleId: role.projectUserRoleId,
      roleId: role.roleId,
    }),
  );

export const createCollectionGroupRolesServerModel = (
  collectionGroups: readonly ICollectionGroupRoles[],
): readonly ICollectionGroupServerModel[] =>
  collectionGroups.map(
    (group): ICollectionGroupServerModel => ({
      collectionIds: filterOutAnyCollectionId(group.collectionIds),
      roles: filterOutAnyLanguageId(group.roles).map(createContributorRoleServerModel),
    }),
  );

const createContributorRoleServerModel = (
  userRole: IProjectContributorRole,
): IProjectContributorRoleServerModel => ({
  languageIds: userRole.languageIds,
  projectUserRoleId: userRole.projectUserRoleId,
  roleId: userRole.roleId,
});

export function createProjectContributorFromServerModel(
  serverModel: IProjectContributorServerModel,
): IProjectContributor {
  assert(typeof serverModel === 'object', () => 'Project contributor is not an object');

  const {
    userId,
    email,
    firstName,
    lastName,
    inactive,
    isGdprForgotten,
    isInvitationPending,
    isSubscriptionAdmin,
    isVirtual,
    collectionGroups,
  } = serverModel;

  return createProjectContributor({
    email,
    firstName,
    inactive,
    isActivating: false,
    isAdminOnProject: isSubscriptionAdmin,
    isDeactivating: false,
    isGdprForgotten,
    isInvitationPending,
    isVirtual,
    lastName,
    collectionGroups: createCollectionGroupRolesFromServerModel(collectionGroups),
    userId,
  });
}

export const getRoleIdsInAllCollectionsAndLanguages = (
  user: IProjectContributor,
): ReadonlyArray<Uuid> => getRolesInAllCollectionsAndLanguages(user).map((r) => r.roleId);

export const getRolesInAllCollectionsAndLanguages = (
  user: IProjectContributor,
): ReadonlyArray<IProjectContributorRole> => user.collectionGroups.flatMap((c) => c.roles);
