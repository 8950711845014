import { InvariantException } from '@kontent-ai/errors';

export enum CannotPublishReason {
  WorkflowStep = 'WorkflowStep',
  MissingPermission = 'MissingPermission',
  ItemIncomplete = 'ItemIncomplete',
  ItemIsPublished = 'ItemIsPublished',
  ItemIsScheduled = 'ItemIsScheduled',
  ItemIsScheduledToPublishForEarlier = 'ItemIsScheduledToPublishForEarlier',
  ItemIsNotTranslatedButHasFallback = 'ItemIsNotTranslatedButHasFallback',
  ItemIsNotTranslated = 'ItemIsNotTranslated',
  ItemNotFound = 'ItemNotFound',
  MustPublishNonLocalizableElements = 'MustPublishNonLocalizableElements',
  None = 'None',
}

export const getMessageForCannotPublishReason = (
  reason: CannotPublishReason,
  defaultLanguageName: string,
  currentLanguageName: string,
): string | undefined => {
  switch (reason) {
    case CannotPublishReason.WorkflowStep:
      return 'This item’s workflow step cannot transition to the Published step.';
    case CannotPublishReason.ItemIncomplete:
      return 'This item is not complete.';
    case CannotPublishReason.MissingPermission:
      return 'You don’t have permission to publish this item.';
    case CannotPublishReason.ItemIsPublished:
      return 'This item is already published.';
    case CannotPublishReason.ItemIsScheduled:
      return 'This item is scheduled to publish. To publish it now, first open the item and cancel scheduling.';
    case CannotPublishReason.ItemIsScheduledToPublishForEarlier:
      return 'This item is already scheduled for earlier.';
    case CannotPublishReason.ItemIsNotTranslatedButHasFallback:
      return `This item isn’t translated to ${currentLanguageName}. The item falls back to a published version in another language.`;
    case CannotPublishReason.ItemIsNotTranslated:
      return 'This item is not translated.';
    case CannotPublishReason.ItemNotFound:
      return 'This item was not found.';
    case CannotPublishReason.MustPublishNonLocalizableElements:
      return `This item contains required non-localizable elements. Publish this item in ${defaultLanguageName} first or together with it.`;
    case CannotPublishReason.None:
      return undefined;
    default:
      throw InvariantException(`Unknown reason '${reason}.'`);
  }
};
