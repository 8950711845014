import { Direction } from '@kontent-ai/types';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { useEventListener } from './useEventListener.ts';
import { useScrollParent } from './useScrollParent.ts';

const scrollStepInPixels = 20;

const portionOfScrollElementConsideredAsEdge = 0.2;
const maxScrollEdgeOffsetInPixels = 100;

function getScrollDirection(scrollParent: RefObject<Element>, event: DragEvent): Direction | null {
  const scrollContainer = scrollParent.current;
  if (!scrollContainer || !event.target || !scrollContainer.contains(event.target as Node)) {
    return null;
  }

  const offset = scrollContainer.getBoundingClientRect();
  const scrollOffset = Math.min(
    maxScrollEdgeOffsetInPixels,
    offset.height * portionOfScrollElementConsideredAsEdge,
  );

  const isAtTopEdge = offset.top + scrollOffset >= event.clientY;
  if (isAtTopEdge) {
    return Direction.Backward;
  }

  const isAtBottomEdge = offset.top + offset.height - scrollOffset <= event.clientY;
  if (isAtBottomEdge) {
    return Direction.Forward;
  }

  return null;
}

/**
 * Ensures automatic scrolling when dragging an element out of visible part of an element.
 * Received ref should point to the element that defines the area of where the scrolling is possible.
 */
export const useScrollOnDragEvents = <TElement extends HTMLElement>(
  scrollingAreaRef: RefObject<TElement>,
): void => {
  const scrollParent = useScrollParent(scrollingAreaRef);
  const [scrollDirection, setScrollDirection] = useState<Direction | null>(null);

  useEffect(() => {
    const scrollContainer = scrollParent.current;
    if (!scrollContainer || !scrollDirection) {
      return;
    }

    let lastScrollTop: number | null = null;

    const intervalId = self.setInterval(() => {
      if (lastScrollTop !== null) {
        // Stop scrolling when user scrolled against the automatic direction
        const scrolledAgainstDirection =
          scrollDirection === Direction.Backward
            ? scrollContainer.scrollTop > lastScrollTop
            : scrollContainer.scrollTop < lastScrollTop;

        if (scrolledAgainstDirection) {
          setScrollDirection(null);
          return;
        }
      }

      const originalScrollTop = scrollContainer.scrollTop;
      // This is intentional in order to scroll the parent.
      scrollContainer.scrollTop +=
        scrollDirection === Direction.Backward ? -scrollStepInPixels : scrollStepInPixels;

      // Stop when automatic scrolling no longer has effect (reached the end of scroll container)
      if (scrollContainer.scrollTop === originalScrollTop) {
        setScrollDirection(null);
      }
      lastScrollTop = scrollContainer.scrollTop;
    }, 50);

    return () => self.clearInterval(intervalId);
  }, [scrollParent, scrollDirection]);

  const startScrollingAtEdge = useCallback(
    (event: DragEvent): void => {
      setScrollDirection(getScrollDirection(scrollParent, event));
    },
    [scrollParent],
  );

  useEventListener('dragover', startScrollingAtEdge, self);

  const stopScrolling = () => setScrollDirection(null);

  useEventListener('dragleave', stopScrolling, scrollParent.current);
  useEventListener('dragend', stopScrolling, self);
  useEventListener('drop', stopScrolling, self);
};
