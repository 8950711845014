import { Reducer } from '@reduxjs/toolkit';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Collections_Save_Finished } from '../../../../applications/environmentSettings/collections/constants/actionTypes.ts';
import { Data_Collections_Success } from '../../../constants/dataActionTypes.ts';
import {
  CollectionsMap,
  createCollectionsFromServerModel,
} from '../../../models/collections/Collection.ts';

const initialState: CollectionsMap = new Map();

export const byId: Reducer<CollectionsMap, Action> = (state = initialState, action) => {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_Collections_Success:
    case Collections_Save_Finished:
      return new Map(
        createCollectionsFromServerModel(action.payload).collections.map((c) => [c.id, c]),
      );

    default:
      return state;
  }
};
