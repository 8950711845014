import React from 'react';
import { modalOpened } from '../../../actions/sharedActions.ts';
import { RestoreRevisionButton as RestoreRevisionButtonComponent } from '../../../components/StatusBar/revisions/RestoreRevisionButton.tsx';
import { ModalDialogType } from '../../../constants/modalDialogType.ts';
import { useRestoreRevision } from '../../../hooks/StatusBar/revisions/useRestoreRevision.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { useSelector } from '../../../hooks/useSelector.ts';
import { getRestoreConfirmation } from '../../../selectors/StatusBar/revisions/revisionSelectors.ts';
import { RestoreConfirmation } from '../../../types/RestoreConfirmation.type.ts';

type Props = {
  readonly children: string;
};

export const RestoreRevisionButton: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const confirmation = useSelector(getRestoreConfirmation);
  const dispatch = useDispatch();
  const restoreRevision = useRestoreRevision();

  const onClick = () => {
    if (confirmation === RestoreConfirmation.NoConfirmation) {
      restoreRevision();
    } else {
      dispatch(modalOpened(ModalDialogType.RestoreRevisionConfirmationDialog));
    }
  };

  return (
    <RestoreRevisionButtonComponent onClick={onClick}>{children}</RestoreRevisionButtonComponent>
  );
};
