import React from 'react';
import { Redirect } from 'react-router';
import { RootRoute } from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AccessDenied } from '../components/AccessDenied.tsx';

export const AccessDeniedNoPermissionsInActiveLanguages: React.FC = () => {
  const currentProject = useSelector(getCurrentProject);

  return currentProject ? (
    <AccessDenied
      title={`Looks like you don’t have access to “${currentProject.projectName}” anymore.`}
    />
  ) : (
    <Redirect to={RootRoute} />
  );
};
