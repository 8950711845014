import React, { useCallback, useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { objectTypeChanged } from '../actions/auditLogFilterActions.ts';
import { AuditLogFilterSelector } from '../components/AuditLogFilterSelector.tsx';
import { ObjectType, ObjectTypeList } from '../constants/ObjectType.ts';

export const ObjectTypeFilterSelector: React.FC = () => {
  const auditLogFilter = useSelector((state: IStore) => state.auditLogApp.auditLogFilter);
  const dispatch = useDispatch();

  const selectedObjectTypesMemoized = useMemo(() => {
    return auditLogFilter.selectedObjectTypes.toArray();
  }, [auditLogFilter]);

  const onChange = useCallback((selectedObjectTypes: ReadonlyArray<ObjectType>) => {
    dispatch(objectTypeChanged(Immutable.Set(selectedObjectTypes)));
  }, []);

  return (
    <AuditLogFilterSelector
      title="What changed"
      collection={DataUiCollection.AuditLogObjectTypes}
      options={ObjectTypeList}
      selectedOptions={selectedObjectTypesMemoized}
      placeholder="Select a type"
      onFilterChanged={onChange}
    />
  );
};

ObjectTypeFilterSelector.displayName = 'ObjectTypeFilterSelector';
