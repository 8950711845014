export enum WorkflowGlobalValidationError {
  StatusesEmpty = 'statuses_empty',
  NumberOfPublishedSteps = 'number_of_published_steps',
  NumberOfScheduledToPublishSteps = 'number_of_scheduled_to_publish_steps',
  DuplicateWorkflowCodeNames = 'duplicate_workflow_codenames',
  ReusedStepCodeNames = 'reused_step_codenames',
}

export enum WorkflowStepValidationError {
  TransitionToNonExistingStep = 'transition_to_non_existing_step',
  NoTransitions = 'no_transitions',
  TransitionsToScheduled = 'transitions_to_scheduled',
  NoPathToPublished = 'no_path_to_published',
}
