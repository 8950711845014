import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { showFriendlyWarnings } from '../../../../actions/contentActions.ts';
import {
  getElementsWithWarnings,
  isPublishingBlockedByDefaultVariant,
} from '../../../../selectors/itemValidations.ts';
import {
  focusValidationWarningsBeforePublish,
  showIncompleteDefaultVariantNotification,
} from '../contentItemEditingActions.ts';

interface IDependencies {
  readonly showIncompleteElementsInContentGroup: () => ThunkFunction;
}

export interface IDisplayWarningsOnPublishResult {
  readonly hasElementsWithWarnings: boolean;
  readonly defaultVariantBlocksPublishing: boolean;
}

export const createDisplayWarningsOnPublishSelectedAction =
  ({ showIncompleteElementsInContentGroup }: IDependencies) =>
  (): ThunkFunction<IDisplayWarningsOnPublishResult> =>
  (dispatch, getState) => {
    const state = getState();
    const highlightedElementIds = getElementsWithWarnings(state);
    const hasElementsWithWarnings = !!highlightedElementIds.length;

    const defaultVariantBlocksPublishing = isPublishingBlockedByDefaultVariant(state);

    if (hasElementsWithWarnings) {
      dispatch(showIncompleteElementsInContentGroup());
      dispatch(focusValidationWarningsBeforePublish(highlightedElementIds));
    }

    if (defaultVariantBlocksPublishing) {
      dispatch(showIncompleteDefaultVariantNotification());
    }

    dispatch(showFriendlyWarnings());

    return {
      hasElementsWithWarnings,
      defaultVariantBlocksPublishing,
    };
  };
