import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { InnovationLabActivationState } from '../components/InnovationLabFeatureStatus.tsx';

export const useGetFeatureActivationState = (
  isFeatureEnabledSelector: (store: IStore) => boolean,
): InnovationLabActivationState => {
  const isFeatureEnabled = useSelector(isFeatureEnabledSelector);

  return isFeatureEnabled ? 'active' : 'inactive';
};
