import { OrderBy, OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { AssignedContentItemsOrderByColumnCode } from '../constants/assignedContentItemsOrderByColumnCode.ts';

export const getDirection = (
  orderedBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
  columnCode: AssignedContentItemsOrderByColumnCode,
): OrderByDirection =>
  orderedBy.columnCode === columnCode ? orderedBy.direction : OrderByDirection.None;

export const createNewOrderBy = (
  orderedBy: OrderBy<AssignedContentItemsOrderByColumnCode>,
  columnCode: AssignedContentItemsOrderByColumnCode,
): OrderBy<AssignedContentItemsOrderByColumnCode> => {
  const isOrderedByColumn = orderedBy.columnCode === columnCode;

  const direction = isOrderedByColumn
    ? getOppositeDirection(orderedBy.direction)
    : orderedBy.direction;

  return {
    columnCode,
    direction,
  };
};

const getOppositeDirection = (direction: OrderByDirection): OrderByDirection => {
  return direction === OrderByDirection.Descending
    ? OrderByDirection.Ascending
    : OrderByDirection.Descending;
};
