import React, { MouseEventHandler, RefObject, forwardRef } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { CommonDropDownProps } from './DropDownFrame.tsx';
import { DropDownOptionsGroup } from './DropDownOptionsGroup.tsx';
import { StatefulDropDown } from './StatefulDropDown.tsx';

type DropDownOptionActions = {
  readonly name: string;
  readonly onClick?: MouseEventHandler<HTMLElement>;
  readonly onMouseDown?: MouseEventHandler<HTMLElement>;
  readonly dataAttributes?: ObjectWithDataAttribute;
  readonly role?: 'separator';
  readonly linkPath?: string;
  readonly openLinkInNewTab?: boolean;
  readonly isOutsideLink?: boolean;
  readonly withBadge?: boolean;
};

type DropDownProps<TOption> = CommonDropDownProps & {
  // When isOpen is set, the open state is controlled by the parent component, otherwise the component uses the internal isOpen state
  readonly isOpen?: boolean;
  readonly longer?: boolean;
  readonly onClose?: () => void;
  readonly onOpen?: () => void;
  readonly options: ReadonlyArray<TOption>;
  readonly renderOption: (hideMenu: () => void, option: TOption) => React.ReactNode;
  readonly renderOptionsHeadline?: (onClick: () => void) => React.ReactNode;
  readonly renderSelectedOption: (
    ref: RefObject<HTMLElement>,
    onClick: () => void,
    isOpen: boolean,
  ) => React.ReactNode;
};

export const createDropDown = <
  TOption extends AnyObject | string | number = DropDownOptionActions,
>() => {
  const DropdownWithOptions = forwardRef<HTMLElement, DropDownProps<TOption>>(
    ({ options, renderOptionsHeadline, renderOption, renderSelectedOption, ...rest }, ref) => (
      <StatefulDropDown
        ref={ref}
        {...rest}
        renderContent={(hideMenu) => {
          return (
            <DropDownOptionsGroup title={renderOptionsHeadline?.(hideMenu)}>
              {options.map((option) => renderOption(hideMenu, option))}
            </DropDownOptionsGroup>
          );
        }}
        renderSelectedOption={renderSelectedOption}
      />
    ),
  );

  DropdownWithOptions.displayName = 'DropdownWithOptions';

  return DropdownWithOptions;
};
