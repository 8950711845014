import { assert, Collection, areShallowEqual } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  WorkflowRoleLimitation,
  WorkflowScopeChange,
  WorkflowStepChange,
} from '../../../../../_shared/models/events/WorkflowsEventData.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  Workflow,
  WorkflowScope,
  getWorkflowFromServerModel,
} from '../../../../../data/models/workflow/Workflow.ts';
import {
  ArchivedStep,
  PublishedStep,
  RegularWorkflowStep,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { IWorkflowServerModel } from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';

type WorkflowParams = {
  readonly archived: ArchivedStep;
  readonly published: PublishedStep;
  readonly regularSteps: ReadonlyMap<Uuid, RegularWorkflowStep>;
  readonly scopes: ReadonlyArray<WorkflowScope>;
};

type FireWorkflowRolesIntercomEventsParams = {
  readonly ids: {
    readonly added: Immutable.Set<Uuid>;
    readonly deleted: Immutable.Set<Uuid>;
    readonly retained: Immutable.Set<Uuid>;
  };
  readonly newWorkflow: WorkflowParams;
  readonly oldWorkflow: WorkflowParams;
};

const self = 1;
const publishedStepCount = 1; // includes both Published and Scheduled
const archivedStepCount = 1;

// Finds out whether WF is a 'complete' graph. Apart from the fact, that loops (transitions to self) are not allowed
// Publish step is not part of this collection
const isCompleteGraph = (workflow: ReadonlyMap<Uuid, RegularWorkflowStep>) => {
  const maxAvailableTransitions = workflow.size - self + publishedStepCount + archivedStepCount;

  return Collection.getValues(workflow).every(
    (w: RegularWorkflowStep) => w.transitionsTo.size === maxAvailableTransitions,
  );
};

const hasWorkflowStepChanged = (
  oldStep: RegularWorkflowStep | undefined,
  newStep: RegularWorkflowStep | undefined,
): boolean =>
  !areShallowEqual(newStep, oldStep, ['roleIds', 'transitionsTo']) ||
  !areShallowEqual(oldStep?.transitionsTo, newStep?.transitionsTo) ||
  !areShallowEqual(oldStep?.roleIds, newStep?.roleIds);

const hasWorkflowStepsChanged = ({
  ids,
  oldWorkflow,
  newWorkflow,
}: FireWorkflowRolesIntercomEventsParams): boolean =>
  ids.retained.some((stepId: Uuid) =>
    hasWorkflowStepChanged(
      oldWorkflow.regularSteps.get(stepId),
      newWorkflow.regularSteps.get(stepId),
    ),
  );

const hasWorkflowStepTransitionsChanged = ({
  ids,
  oldWorkflow,
  newWorkflow,
}: FireWorkflowRolesIntercomEventsParams): boolean => {
  // new or deleted step means at least one new/removed transition inevitably
  if (!ids.added.isEmpty() || !ids.deleted.isEmpty()) {
    return true;
  }

  // when no steps were added/removed (checked above) and graph was complete, completeness check is sufficient to detect a transition change
  if (isCompleteGraph(oldWorkflow.regularSteps)) {
    return !isCompleteGraph(newWorkflow.regularSteps);
  }

  return ids.retained.some((stepId: Uuid) => {
    const newTransitions = Array.from(newWorkflow.regularSteps.get(stepId)?.transitionsTo || []);
    const oldTransitionsWithoutDeleted = Array.from(
      oldWorkflow.regularSteps.get(stepId)?.transitionsTo || [],
    ).filter((id) => !ids.deleted.has(id));

    return !areShallowEqual(newTransitions, oldTransitionsWithoutDeleted);
  });
};

const hasPublishedStepRoleLimitationsBeenRemoved = ({
  oldWorkflow,
  newWorkflow,
}: FireWorkflowRolesIntercomEventsParams): boolean =>
  (!areShallowEqual(
    oldWorkflow.published.roleIdsForUnpublish,
    newWorkflow.published.roleIdsForUnpublish,
  ) &&
    newWorkflow.published.roleIdsForUnpublish.size === 0) ||
  (!areShallowEqual(
    oldWorkflow.published.roleIdsForCreateNew,
    newWorkflow.published.roleIdsForCreateNew,
  ) &&
    newWorkflow.published.roleIdsForCreateNew.size === 0);

const hasPublishedStepRolesConfigurationChanged = ({
  oldWorkflow,
  newWorkflow,
}: FireWorkflowRolesIntercomEventsParams): boolean =>
  !areShallowEqual(
    oldWorkflow.published.roleIdsForUnpublish,
    newWorkflow.published.roleIdsForUnpublish,
  ) ||
  !areShallowEqual(
    oldWorkflow.published.roleIdsForCreateNew,
    newWorkflow.published.roleIdsForCreateNew,
  );

const hasArchivedStepRolesConfigurationChanged = ({
  oldWorkflow,
  newWorkflow,
}: FireWorkflowRolesIntercomEventsParams): boolean =>
  !areShallowEqual(oldWorkflow.archived.roleIds, newWorkflow.archived.roleIds);

const isConfigurationForPublishAndCreateNewSame = ({
  newWorkflow,
}: FireWorkflowRolesIntercomEventsParams): boolean =>
  areShallowEqual(
    newWorkflow.published.roleIdsForUnpublish,
    newWorkflow.published.roleIdsForCreateNew,
  );

const getNewStepIdsWithRoleSet = (
  params: FireWorkflowRolesIntercomEventsParams,
): Immutable.Set<Uuid> =>
  params.ids.added
    .filter((stepId: Uuid) => !!params.newWorkflow.regularSteps.get(stepId)?.roleIds.size)
    .toSet();

const getRetainedStepIdsWithRolesSet = (
  params: FireWorkflowRolesIntercomEventsParams,
): Immutable.Set<Uuid> =>
  params.ids.retained
    .filter((id: Uuid) => {
      const newRoles = params.newWorkflow.regularSteps.get(id)?.roleIds;
      const oldRoles = params.oldWorkflow.regularSteps.get(id)?.roleIds;
      return !!newRoles?.size && !areShallowEqual(newRoles, oldRoles);
    })
    .toSet();

const getRetainedStepIdsWithRolesRemoved = (
  params: FireWorkflowRolesIntercomEventsParams,
): Immutable.Set<Uuid> =>
  params.ids.retained
    .filter((id: Uuid) => {
      const newRoles = params.newWorkflow.regularSteps.get(id)?.roleIds;
      const oldRoles = params.oldWorkflow.regularSteps.get(id)?.roleIds;
      return newRoles?.size === 0 && !!oldRoles?.size;
    })
    .toSet();

const getStepIdsTransitioningToPublish = (
  params: FireWorkflowRolesIntercomEventsParams,
): ReadonlySet<Uuid> =>
  new Set(
    Collection.getValues(params.newWorkflow.regularSteps)
      .filter((s: RegularWorkflowStep) => s.transitionsTo.has(params.newWorkflow.published.id))
      .map((s: RegularWorkflowStep) => s.id),
  );

function* getChangedWorkflowRoleLimitations(
  params: FireWorkflowRolesIntercomEventsParams,
): Generator<WorkflowRoleLimitation> {
  const newWorkflowStepIdsWithRoleSet = getNewStepIdsWithRoleSet(params);
  const retainedWorkflowStepIdsWithRolesSet = getRetainedStepIdsWithRolesSet(params);
  const retainedWorkflowStepIdsWithRolesRemoved = getRetainedStepIdsWithRolesRemoved(params);

  if (!retainedWorkflowStepIdsWithRolesSet.isEmpty() || !newWorkflowStepIdsWithRoleSet.isEmpty()) {
    yield WorkflowRoleLimitation.RoleLimitationsSet;
  }

  if (
    !retainedWorkflowStepIdsWithRolesRemoved.isEmpty() ||
    hasPublishedStepRoleLimitationsBeenRemoved(params)
  ) {
    yield WorkflowRoleLimitation.RoleLimitationsRemoved;
  }

  const stepIdsWithTransitionToPublish = getStepIdsTransitioningToPublish(params);
  const stepIdsWithRolesNewlySet = newWorkflowStepIdsWithRoleSet.union(
    retainedWorkflowStepIdsWithRolesSet,
  );
  const werePublishingRolesSet = Collection.getValues(stepIdsWithTransitionToPublish).some(
    (value) => stepIdsWithRolesNewlySet.contains(value),
  );

  if (werePublishingRolesSet) {
    yield WorkflowRoleLimitation.RoleLimitationsForPublish;
  }

  if (hasPublishedStepRolesConfigurationChanged(params)) {
    yield isConfigurationForPublishAndCreateNewSame(params)
      ? WorkflowRoleLimitation.SameRolesForUnpublishAndCreateNew
      : WorkflowRoleLimitation.DifferentRolesForUnpublishAndCreateNew;
  }
}

function* getWorkflowStepChanges(
  params: FireWorkflowRolesIntercomEventsParams,
): Generator<WorkflowStepChange> {
  if (!params.ids.added.isEmpty()) {
    yield WorkflowStepChange.RegularStepAdded;
  }

  if (!params.ids.deleted.isEmpty()) {
    yield WorkflowStepChange.RegularStepRemoved;
  }

  if (hasWorkflowStepsChanged(params)) {
    yield WorkflowStepChange.RegularStepChanged;
  }

  if (hasPublishedStepRolesConfigurationChanged(params)) {
    yield WorkflowStepChange.PublishStepChanged;
  }

  if (hasArchivedStepRolesConfigurationChanged(params)) {
    yield WorkflowStepChange.ArchivedStepChanged;
  }

  if (hasWorkflowStepTransitionsChanged(params)) {
    yield WorkflowStepChange.HasAnyRegularTransitionChanged;
  }
}

function* getWorkflowScopeChangesForNewWorkflow({
  scopes,
}: IWorkflowServerModel): Generator<WorkflowScopeChange> {
  const addedTypes = scopes.flatMap((scope) => scope.contentTypes);
  const addedCollections = scopes.flatMap((scope) => scope.collections);

  if (addedTypes.length) {
    yield WorkflowScopeChange.ContentTypesAdded;
  }

  if (addedCollections.length) {
    yield WorkflowScopeChange.CollectionsAdded;
  }
}

function* getWorkflowScopeChangesForExistingWorkflow({
  newWorkflow,
  oldWorkflow,
}: FireWorkflowRolesIntercomEventsParams): Generator<WorkflowScopeChange> {
  yield* getScopeRestrictionChanges(
    oldWorkflow.scopes,
    newWorkflow.scopes,
    (scope) => scope.contentTypes,
    WorkflowScopeChange.ContentTypesAdded,
    WorkflowScopeChange.ContentTypesRemoved,
  );

  yield* getScopeRestrictionChanges(
    oldWorkflow.scopes,
    newWorkflow.scopes,
    (scope) => scope.collections,
    WorkflowScopeChange.CollectionsAdded,
    WorkflowScopeChange.CollectionsRemoved,
  );
}

function* getScopeRestrictionChanges(
  initialScopes: ReadonlyArray<WorkflowScope>,
  updatedScopes: ReadonlyArray<WorkflowScope>,
  restrictionSelector: (scope: WorkflowScope) => ReadonlySet<Uuid>,
  restrictionAdded: WorkflowScopeChange,
  restrictionRemoved: WorkflowScopeChange,
): Generator<WorkflowScopeChange> {
  const oldRestrictions = new Set(
    initialScopes.flatMap((scope) => Array.from(restrictionSelector(scope))),
  );
  const newRestrictions = new Set(
    updatedScopes.flatMap((scope) => Array.from(restrictionSelector(scope))),
  );

  if (Collection.getValues(newRestrictions).some((id) => !oldRestrictions.has(id))) {
    yield restrictionAdded;
  }
  if (Collection.getValues(oldRestrictions).some((id) => !newRestrictions.has(id))) {
    yield restrictionRemoved;
  }
}

const createWorkflowParams = ({
  archivedStep,
  publishedStep,
  scopes,
  steps: stepsArray,
}: Workflow): WorkflowParams => {
  const regularSteps = stepsArray.reduce(
    (reduced, step) => reduced.set(step.id, step),
    new Map<Uuid, RegularWorkflowStep>(),
  );

  return {
    archived: archivedStep,
    published: publishedStep,
    regularSteps,
    scopes,
  };
};

const createRolesAndTransitionEventsParams = (
  newWorkflowServerModel: IWorkflowServerModel,
  oldWorkflowModel: Workflow,
): FireWorkflowRolesIntercomEventsParams => {
  const newWorkflow = createWorkflowParams(getWorkflowFromServerModel(newWorkflowServerModel));
  const oldWorkflow = createWorkflowParams(oldWorkflowModel);

  const newWorkflowIds = Immutable.Set.fromKeys(newWorkflow.regularSteps);
  const oldWorkflowIds = Immutable.Set.fromKeys(oldWorkflow.regularSteps);

  const added = newWorkflowIds.subtract(oldWorkflowIds);
  const retained = newWorkflowIds.subtract(added);
  const deleted = oldWorkflowIds.subtract(newWorkflowIds);

  return {
    ids: {
      added,
      deleted,
      retained,
    },
    newWorkflow,
    oldWorkflow,
  };
};

const selectWorkflowsCount = ({ data: { workflows } }: IStore): number => workflows.byId.size;

const fireNewWorkflowEvents =
  (newWorkflow: IWorkflowServerModel): ThunkFunction =>
  (dispatch, getState) => {
    const {
      id: workflowId,
      statuses: { length: regularStepsCount },
      scopes: { length: scopesCount },
    } = newWorkflow;
    const workflowsCount = selectWorkflowsCount(getState());
    const scopeChanges = [...getWorkflowScopeChangesForNewWorkflow(newWorkflow)];

    dispatch(
      trackUserEventWithData(TrackedEvent.WorkflowCreated, {
        workflowId,
        'regular-steps-count': regularStepsCount,
        'workflows-count': workflowsCount,
        'scopes-count': scopesCount,
        'scopes-changes': scopeChanges,
      }),
    );
  };

const fireExistingWorkflowEvents =
  (newWorkflow: IWorkflowServerModel, oldWorkflow: Workflow): ThunkFunction =>
  (dispatch, getState) => {
    const {
      id: workflowId,
      scopes: { length: newScopesCount },
      statuses: { length: newStepsCount },
    } = newWorkflow;
    const {
      scopes: { length: oldScopesCount },
      steps: { length: oldStepsCount },
    } = oldWorkflow;
    const workflowsCount = selectWorkflowsCount(getState());

    const params = createRolesAndTransitionEventsParams(newWorkflow, oldWorkflow);

    const limitations = [...getChangedWorkflowRoleLimitations(params)];
    const stepChanges = [...getWorkflowStepChanges(params)];
    const scopesChanges = [...getWorkflowScopeChangesForExistingWorkflow(params)];
    dispatch(
      trackUserEventWithData(TrackedEvent.WorkflowUpdated, {
        workflowId,
        limitations,
        'regular-steps-count': newStepsCount,
        'regular-steps-delta': newStepsCount - oldStepsCount,
        'scopes-changes': scopesChanges,
        'scopes-count': newScopesCount,
        'scopes-delta': newScopesCount - oldScopesCount,
        'step-changes': stepChanges,
        'workflows-count': workflowsCount,
      }),
    );
  };

export const fireChangeWorkflowEvents = (
  newWorkflow: IWorkflowServerModel,
  oldWorkflow: Workflow | undefined,
): ThunkFunction =>
  oldWorkflow
    ? fireExistingWorkflowEvents(newWorkflow, oldWorkflow)
    : fireNewWorkflowEvents(newWorkflow);

export const fireDeleteWorkflowEvents =
  (removedWorkflow: Workflow): ThunkFunction =>
  (dispatch, getState) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.WorkflowDeleted, {
        workflowId: removedWorkflow.id,
        'regular-steps-count': removedWorkflow.steps.length,
        'workflows-count': selectWorkflowsCount(getState()),
      }),
    );

export const fireRestoreWorkflowEvents =
  (workflowId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const restoredWorkflow = getWorkflow(state, workflowId);
    assert(restoredWorkflow, () => `${__filename}: Restored workflow "${workflowId}" not found`);

    dispatch(
      trackUserEventWithData(TrackedEvent.WorkflowRestored, {
        workflowId,
        'regular-steps-count': restoredWorkflow.steps.length,
        'workflows-count': selectWorkflowsCount(state),
      }),
    );
  };
