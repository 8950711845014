import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadContentItemsRecentlyEditedByCurrentUser: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSpaces: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createInitContentRecentlyEditedByYouAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    await Promise.all([
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadContentItemsRecentlyEditedByCurrentUser(abortSignal)),
      dispatch(deps.loadSpaces(abortSignal)),
      dispatch(deps.loadWorkflows(abortSignal)),
    ]);
  };
