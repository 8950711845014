import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { Workflow } from '../../../../../../data/models/workflow/Workflow.ts';
import { RegularWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { getWorkflow } from '../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { IAssignment } from '../../../../models/contentItem/Assignment.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { contentItemEditingSidebarSectionCleanedUp } from '../contentItemEditingActions.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly loadFirstWorkflowStepCurrentRoleCanWorkWith: (
    languageId: Uuid,
    collectionId: Uuid,
    workflowId: Uuid,
  ) => ThunkPromise;
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

const getNewStepId = (
  originalStepId: Uuid,
  originalWorkflow: Workflow,
  newWorkflow: Workflow,
): Uuid => {
  if (originalStepId === originalWorkflow.publishedStep.id) {
    return newWorkflow.publishedStep.id;
  }

  if (originalStepId === originalWorkflow.scheduledStep.id) {
    return newWorkflow.scheduledStep.id;
  }

  if (originalStepId === originalWorkflow.archivedStep.id) {
    return newWorkflow.archivedStep.id;
  }

  const firstStep: RegularWorkflowStep | undefined = newWorkflow.steps[0];
  assert(
    firstStep,
    () => `${__filename}: Workflow ${newWorkflow.id} does not have first regular step.`,
  );

  return firstStep.id;
};

const createUpdatedAssignmentForChangeWorkflow = (
  state: IStore,
  newWorkflowId: Uuid,
): Partial<IAssignment> => {
  const {
    contentApp: { editedContentItemVariant },
  } = state;
  assert(
    editedContentItemVariant,
    () => `${__filename}: Edited content item variant was not found`,
  );

  const { assignment: originalAssignment } = editedContentItemVariant;
  const originalWorkflow = getWorkflow(state, originalAssignment.workflowStatus.workflowId);
  assert(
    originalWorkflow,
    () =>
      `${__filename}: Did not find workflow with id ${originalAssignment.workflowStatus.workflowId}`,
  );

  const newWorkflow = getWorkflow(state, newWorkflowId);
  assert(newWorkflow, () => `${__filename}: Did not find workflow with id ${newWorkflowId}`);
  const id = getNewStepId(originalAssignment.workflowStatus.id, originalWorkflow, newWorkflow);

  return {
    workflowStatus: {
      ...originalAssignment.workflowStatus,
      id,
      workflowId: newWorkflowId,
    },
  };
};

export const createChangeWorkflowAction =
  (deps: IDeps) =>
  (workflowId: Uuid, history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      contentApp: { editedContentItem, editedContentItemVariant },
    } = state;

    assert(editedContentItem, () => `${__filename}: Did not find edited content item.`);
    assert(
      editedContentItemVariant,
      () => `${__filename}: Did not find edited content item variant.`,
    );

    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin: getModalDialogActionOrigin(state),
        history,
        getUpdatedAssignment: () => createUpdatedAssignmentForChangeWorkflow(state, workflowId),
        submittingSection: AssignmentSections.Workflow,
      }),
    );

    await dispatch(
      deps.loadFirstWorkflowStepCurrentRoleCanWorkWith(
        editedContentItemVariant.id.variantId,
        editedContentItem.collectionId,
        workflowId,
      ),
    );

    dispatch(contentItemEditingSidebarSectionCleanedUp());
  };
