import { Action } from '../../../../@types/Action.type.ts';
import {
  Relations_LoadDefaultRoot_Failed,
  Relations_LoadDefaultRoot_Finished,
  Relations_LoadDefaultRoot_Started,
  Relations_SetDefaultRoot_Finished,
} from '../constants/relationsActionTypes.ts';

const initialState: Uuid | null = null;

export function defaultRootId(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_LoadDefaultRoot_Started:
    case Relations_LoadDefaultRoot_Failed:
      return initialState;

    case Relations_SetDefaultRoot_Finished:
    case Relations_LoadDefaultRoot_Finished:
      return action.payload.itemId;

    default:
      return state;
  }
}
