import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { ReactNode, RefCallback, useRef } from 'react';
import {
  DropDownMenuPositioner,
  DropdownTippyOptions,
} from '../../../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';

type Props = Readonly<{
  isResultVisible: boolean;
  renderButton: (ref: RefCallback<HTMLElement>) => ReactNode;
  renderResult: (preferMenuOnTop: boolean) => ReactNode;
}>;

export const TaxonomyAiAssistantPositioner = ({
  isResultVisible,
  renderButton,
  renderResult,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { preventOverflowModifier, boundaryProps } = usePreventOverflowFromScrollContainer();

  const tippyOptions: DropdownTippyOptions = {
    offset: [0, spacingPopupDistance],
    placement: 'top-start',
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          options: {
            ...boundaryProps,
            fallbackPlacements: ['bottom-start'],
          },
        },
        preventOverflowModifier,
      ],
    },
  };

  return (
    <DropDownMenuPositioner
      isDropDownVisible={isResultVisible}
      renderDropDown={(_triggerWidth, _triggerRefObject, { placement }) => {
        const preferMenuOnTop = placement?.startsWith('top') ?? true;
        return renderResult(preferMenuOnTop);
      }}
      renderTrigger={(triggerProps) => renderButton(triggerProps.ref)}
      tippyOptions={tippyOptions}
      triggerRef={buttonRef}
    />
  );
};
