import { InvariantException } from '@kontent-ai/errors';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IContentItemRepository } from '../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { TimelineItem, getTimelineItemFromJS } from '../../../models/revisions/TimeLineItem.ts';
import { timelineItemsFetchedPerRequest } from '../constants/timelineRequestOptions.ts';

type TimelineWithContinuationToken = {
  timeline: Immutable.List<TimelineItem>;
  continuationToken: string | null;
};

export const fetchTimelineUntilAllRestoredFromReferencesResolved = async (
  contentItemRepository: Pick<IContentItemRepository, 'requestTimelineItemsChunk'>,
  contentItemId: Uuid,
  variantId: Uuid,
  timelineContinuationToken: string | null,
  abortSignal?: AbortSignal,
): Promise<TimelineWithContinuationToken> => {
  let timeline: TimelineItem[] = [];
  let continuationToken = timelineContinuationToken;
  let unresolvedRestoredFromReferences = new Set<Uuid>();

  do {
    const timelineItemServerModel = await contentItemRepository.requestTimelineItemsChunk(
      contentItemId,
      {
        continuationToken,
        maxItemsCount: timelineItemsFetchedPerRequest,
      },
      variantId,
      abortSignal,
    );

    const timelineChunk: TimelineItem[] = timelineItemServerModel.data.map(getTimelineItemFromJS);
    continuationToken = timelineItemServerModel.continuationToken;

    timeline = timeline.concat(timelineChunk);

    const restoredFromReferencesInChunk = timelineChunk
      .map((item) => item.restoredFrom)
      .filter(notNull);

    unresolvedRestoredFromReferences = new Set(
      [...unresolvedRestoredFromReferences, ...restoredFromReferencesInChunk].filter(
        (reference) => !timelineChunk.some((item) => item.revisionId === reference),
      ),
    );
  } while (continuationToken && unresolvedRestoredFromReferences.size !== 0);

  if (unresolvedRestoredFromReferences.size !== 0) {
    throw InvariantException(
      `Failed to resolve all restoredFrom references. Unresolved references: ${Array.from(
        unresolvedRestoredFromReferences,
      ).join(', ')}`,
    );
  }

  return { timeline: Immutable.List(timeline), continuationToken };
};
