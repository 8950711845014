import { isElement } from '@kontent-ai/DOM';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React from 'react';
import { createPortal } from 'react-dom';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useCancelAiTasks } from '../../../../../../_shared/features/AI/hooks/aiTasks/useCancelAiTasks.ts';
import { AiOperationState } from '../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  AiError,
  getAiErrorMessage,
} from '../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ElementReference } from '../../../../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import {
  AiActionStatus,
  getAiOperationStatus,
} from '../../../../../../applications/richText/plugins/ai/components/status/AiActionStatus.tsx';
import { getAiElementStatusPlaceholderId } from '../../../../../../applications/richText/plugins/ai/components/status/AiElementStatusPlaceholder.tsx';
import { matchElementAiTasks } from '../../../../../../applications/richText/plugins/ai/utils/matchElementAiTasks.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { reviewByGuidelinesErrorMessageByErrorCode } from '../reviewByGuidelinesAiErrors.ts';
import { getUnresolvedCommentThreadsByAiAssistant } from '../selectors/getUnresolvedCommentThreadsByAiAssistant.ts';
import { getAiReviewButtonPlaceholderId } from './AiReviewButtonPlaceholder.tsx';

type ActionStatusProps = {
  readonly anyAiReviewCommentsAdded: boolean;
  readonly aiOperationState: AiOperationState;
  readonly otherTasksProgress: AiTaskProgress;
  readonly isDefaultLanguage: boolean;
  readonly hasGuidelines: boolean;
  readonly isEmpty: boolean;
};

type Props = Omit<ActionStatusProps, 'otherTasksProgress'> & {
  readonly element: ElementReference;
  readonly error: AiError | null;
  readonly perform: () => void;
};

export const AiReviewAction: React.FC<Props> = (props) => {
  const { aiOperationState, anyAiReviewCommentsAdded, element, error, perform } = props;

  const matchOtherTasks = matchElementAiTasks(element, AiActionName.TranslateContent);
  const otherTasksProgress = useAiTaskProgress(matchOtherTasks);
  const cancelOtherActions = useCancelAiTasks(matchOtherTasks);

  const disabledTooltipText = getDisabledTooltipText({ ...props, otherTasksProgress });

  const { current: buttonPlaceholder } = useObserveElementPresence(
    getAiReviewButtonPlaceholderId(element.elementId, element.contentComponentId),
  );

  const { current: statusPlaceholder } = useObserveElementPresence(
    getAiElementStatusPlaceholderId(element.elementId, element.contentComponentId),
  );

  const anyExistingAiReviewComments = useSelector(
    (s) => !!getUnresolvedCommentThreadsByAiAssistant(s, element.elementId).length,
  );

  return (
    <>
      {otherTasksProgress === AiTaskProgress.NotStarted &&
        isElement(statusPlaceholder) &&
        createPortal(
          <AiActionStatus
            label={getStatusLabel(
              aiOperationState,
              anyExistingAiReviewComments || anyAiReviewCommentsAdded,
              error,
            )}
            status={getAiOperationStatus(aiOperationState, error)}
          />,
          statusPlaceholder,
        )}
      {isElement(buttonPlaceholder) &&
        createPortal(
          <QuinaryButton
            tooltipText={disabledTooltipText ?? 'Review content with AI'}
            tooltipPlacement="bottom"
            disabled={!!disabledTooltipText}
            onClick={async () => {
              await cancelOtherActions();
              perform();
            }}
            {...getDataUiElementAttribute(DataUiElement.AiReviewButton)}
          >
            <QuinaryButton.Icon icon={Icons.UserCheckbox} />
          </QuinaryButton>,
          buttonPlaceholder,
        )}
    </>
  );
};

const getStatusLabel = (
  aiOperationState: AiOperationState,
  anyUnresolvedAiReviewComments: boolean,
  error: AiError | null,
): string => {
  if (error) {
    return getAiErrorMessage(error, reviewByGuidelinesErrorMessageByErrorCode);
  }

  if (aiOperationState === AiOperationState.Pending) {
    return 'Reviewing';
  }

  if (anyUnresolvedAiReviewComments) {
    return 'Review finished';
  }

  return 'OK, no revisions needed';
};

const getDisabledTooltipText = ({
  aiOperationState,
  hasGuidelines,
  otherTasksProgress,
  isDefaultLanguage,
  isEmpty,
}: ActionStatusProps): string | null => {
  if (otherTasksProgress === AiTaskProgress.Running) {
    return 'Another action is running.';
  }

  if (aiOperationState === AiOperationState.Pending) {
    return 'The AI is reviewing your content based on the guidelines specified for this element.';
  }

  if (!isDefaultLanguage) {
    return 'You can review content with AI only in the default language.';
  }

  if (!hasGuidelines) {
    return 'This element is missing content review guidelines. Ask your project manager to add them.';
  }

  if (isEmpty) {
    return 'When you provide content, you can use AI to review it based on your guidelines.';
  }

  return null;
};
