import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import {
  ContentItemPreviewRoute,
  ContentItemPreviewRouteParams,
} from '../../../_shared/constants/routePaths.ts';

export type SharedContentItemPreviewRouteState = {
  readonly width: number | null;
  readonly height: number | null;
  readonly url: string;
};

export const useSharedContentItemPreviewRouteState = (): readonly [
  state: SharedContentItemPreviewRouteState | null,
  clearState: () => void,
] => {
  const match = useRouteMatch<ContentItemPreviewRouteParams<string>>(ContentItemPreviewRoute);
  const location = useLocation();
  const state = useMemo(
    () => (match && isSharedContentItemPreviewRouteState(location.state) ? location.state : null),
    [match, location.state],
  );

  const history = useHistory();
  const clearState = useCallback(() => {
    history.replace({
      pathname: history.location.pathname,
      state: null,
    });
  }, [history]);

  return [state, clearState];
};

const isSharedContentItemPreviewRouteState = (
  input: unknown,
): input is SharedContentItemPreviewRouteState => {
  return (
    !!input &&
    typeof input === 'object' &&
    'url' in input &&
    typeof input.url === 'string' &&
    'width' in input &&
    typeof input.width === 'number' &&
    'height' in input &&
    typeof input.height === 'number'
  );
};
