import { Action } from '../../../../@types/Action.type.ts';
import {
  AssetLibrary_Folders_ArchiveCompleted,
  AssetLibrary_Folders_Created,
  AssetLibrary_Folders_MovingBackCompleted,
  AssetLibrary_Folders_MovingCompleted,
  AssetLibrary_Folders_RestoreCompleted,
  AssetLibrary_Folders_Updated,
} from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import { Data_AssetFolders_Success } from '../../../constants/dataActionTypes.ts';
import {
  ITaxonomyGroup,
  createTaxonomyGroupDomainModel,
  emptyTaxonomyGroup,
} from '../../../models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';

const initialState: ITaxonomyGroup = emptyTaxonomyGroup;

export const taxonomyGroup = (
  state: ITaxonomyGroup = initialState,
  action: Action,
): ITaxonomyGroup => {
  switch (action.type) {
    case AssetLibrary_Folders_Created:
    case AssetLibrary_Folders_Updated:
    case AssetLibrary_Folders_ArchiveCompleted:
    case AssetLibrary_Folders_MovingCompleted:
    case AssetLibrary_Folders_MovingBackCompleted:
    case AssetLibrary_Folders_RestoreCompleted: {
      const serverModel = action.payload.taxonomyGroup;

      return serverModel ? createTaxonomyGroupDomainModel(serverModel) : state;
    }

    case Data_AssetFolders_Success: {
      const serverModel = action.payload.data;
      return createTaxonomyGroupDomainModel(serverModel);
    }

    default:
      return state;
  }
};
