import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_AssetTypeLoaded,
  ContentModels_TypeEditor_InitStarted,
  ContentModels_TypeEditor_TypeLoaded,
} from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentType_Creator_InitStarted,
  ContentType_Editor_ContentGroupCreated,
  ContentType_Editor_ContentGroupDeleted,
  ContentType_Editor_SelectedContentGroupChanged,
  ContentType_Editor_SelectedTypeElementsSentToContentGroup,
  ContentType_Editor_SelectedTypeElementsSentToNewContentGroup,
  ContentType_Editor_TypeElementSelectionToggled,
} from '../../constants/contentTypesActionTypes.ts';

export const selectedTypeElementIds = (state: UuidArray = [], action: Action): UuidArray => {
  switch (action.type) {
    case ContentType_Editor_TypeElementSelectionToggled: {
      const { isSelected, elementId } = action.payload;
      if (isSelected && !state.includes(elementId)) {
        return [...state, elementId];
      }

      if (!isSelected) {
        const workingState = [...state];
        for (
          let index = workingState.indexOf(elementId);
          index >= 0;
          index = workingState.indexOf(elementId)
        ) {
          workingState.splice(index, 1);
        }
        return workingState;
      }

      return state;
    }

    case ContentType_Creator_InitStarted:
    case ContentType_Editor_ContentGroupCreated:
    case ContentType_Editor_ContentGroupDeleted:
    case ContentModels_TypeEditor_InitStarted:
    case ContentType_Editor_SelectedContentGroupChanged:
    case ContentType_Editor_SelectedTypeElementsSentToContentGroup:
    case ContentType_Editor_SelectedTypeElementsSentToNewContentGroup:
    case ContentModels_TypeEditor_AssetTypeLoaded:
    case ContentModels_TypeEditor_TypeLoaded:
      return [];

    default:
      return state;
  }
};
