import { Button } from '@kontent-ai/component-library/Button';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { MouseEventHandler, RefObject } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { EnterHotkeyDisabler } from '../Hotkeys/EnterHotkeyDisabler.tsx';

export type BarItemAction = {
  readonly autoFocus?: boolean;
  readonly dataUIActionName: DataUiAction;
  readonly handler?: () => void;
  readonly isDisabled?: boolean;
  readonly isInProgress?: boolean;
  readonly shortcut?: string;
  readonly renderModalComponent?: () => React.ReactNode;
  readonly text: string;
  readonly tooltipText?: string;
};

export type DestructiveBarItemAction = BarItemAction & {
  readonly icon: IconName;
};

interface IBarItemActionButtonsProps {
  readonly primaryAction?: BarItemAction;
  readonly secondaryAction: BarItemAction;
  readonly destructiveAction?: DestructiveBarItemAction;
}

type DestructiveButtonProps = {
  readonly action: DestructiveBarItemAction;
  readonly forwardedRef?: RefObject<HTMLButtonElement>;
};

const DestructiveButton: React.FC<DestructiveButtonProps> = ({ action, forwardedRef }) => {
  const Icon = Icons[action.icon];

  return (
    <EnterHotkeyDisabler>
      <Button
        autoFocus={action.autoFocus}
        buttonStyle="secondary"
        destructive
        destructiveIcon={Icon}
        disabled={action.isDisabled}
        onClick={action.handler}
        ref={forwardedRef}
        size="small"
        tooltipPlacement="bottom-start"
        tooltipText={action.tooltipText}
        {...getDataUiActionAttribute(action.dataUIActionName)}
      >
        {action.isInProgress && <Button.ProgressIcon />}
        <Button.Label>{action.text}</Button.Label>
      </Button>
    </EnterHotkeyDisabler>
  );
};

DestructiveButton.displayName = 'DestructiveButton';

export const BarItemActionButtons: React.FC<IBarItemActionButtonsProps> = (props) => {
  const { primaryAction, secondaryAction, destructiveAction } = props;

  const destructiveButton = destructiveAction ? (
    <>
      <DestructiveButton action={destructiveAction} />
      {destructiveAction?.renderModalComponent?.()}
    </>
  ) : null;

  const secondaryButton = (
    <>
      <EnterHotkeyDisabler>
        <Button
          autoFocus={secondaryAction.autoFocus}
          buttonStyle="secondary"
          disabled={secondaryAction.isDisabled}
          onClick={secondaryAction.handler}
          size="small"
          tooltipPlacement="bottom-end"
          tooltipShortcuts={secondaryAction.isDisabled ? undefined : secondaryAction.shortcut}
          tooltipText={secondaryAction.tooltipText || secondaryAction.text}
          type="button"
          {...getDataUiActionAttribute(secondaryAction.dataUIActionName)}
        >
          {secondaryAction.isInProgress && <Button.ProgressIcon />}
          <Button.Label>{secondaryAction.text}</Button.Label>
        </Button>
      </EnterHotkeyDisabler>
      {secondaryAction.renderModalComponent?.()}
    </>
  );

  const primaryButtonOnClickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (primaryAction?.handler) {
      event.preventDefault();
      primaryAction.handler();
    }
  };

  const primaryButton = primaryAction && (
    <>
      <EnterHotkeyDisabler>
        <Button
          autoFocus={primaryAction.autoFocus}
          buttonStyle="primary"
          disabled={primaryAction.isDisabled}
          onClick={primaryButtonOnClickHandler}
          size="small"
          tooltipPlacement="bottom-end"
          tooltipShortcuts={primaryAction.isDisabled ? undefined : primaryAction.shortcut}
          tooltipText={primaryAction.tooltipText || primaryAction.text}
          type={primaryAction.handler ? 'button' : 'submit'}
          {...getDataUiActionAttribute(primaryAction.dataUIActionName)}
        >
          {primaryAction.isInProgress && <Button.ProgressIcon />}
          <Button.Label>{primaryAction.text}</Button.Label>
        </Button>
      </EnterHotkeyDisabler>
      {primaryAction.renderModalComponent?.()}
    </>
  );

  return (
    <div className="bar-item__expansion-actions-bar">
      <Row spacing={Spacing.S}>
        <Column>{destructiveButton}</Column>
        <Column width="fit-content">
          <Inline spacing={Spacing.S}>
            {secondaryButton}
            {primaryButton}
          </Inline>
        </Column>
      </Row>
    </div>
  );
};

BarItemActionButtons.displayName = 'BarItemActionButtons';
