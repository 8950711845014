import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_ArchivedStepEditor_Save,
  Workflow_Delete_Finished,
  Workflow_Editing_Left,
  Workflow_InitializeCreating_Started,
  Workflow_InitializeEditing_Started,
  Workflow_PublishedStepEditor_Save,
  Workflow_StepCreator_Cancel,
  Workflow_StepCreator_Initiated,
  Workflow_StepEditor_Cancel,
  Workflow_StepEditor_Delete,
  Workflow_StepEditor_Initiated,
  Workflow_StepEditor_Save,
} from '../../constants/workflowActionTypes.ts';

export const editedWorkflowStepId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Workflow_StepEditor_Initiated:
      return action.payload.stepId;

    case Workflow_StepCreator_Initiated:
      return action.payload.workflowStep.id;

    case Workflow_StepCreator_Cancel:
    case Workflow_Editing_Left:
    case Workflow_InitializeCreating_Started:
    case Workflow_InitializeEditing_Started:
    case Workflow_StepEditor_Cancel:
    case Workflow_StepEditor_Delete:
    case Workflow_StepEditor_Save:
    case Workflow_PublishedStepEditor_Save:
    case Workflow_ArchivedStepEditor_Save:
    case Workflow_Delete_Finished:
      return null;

    default:
      return state;
  }
};
