import { assert } from '@kontent-ai/utils';
import { ISubscriptionUsersUsageServerModel } from '../../../../repositories/serverModels/SubscriptionUsersUsageServerModel.type.ts';
import {
  ISubscriptionUserWithSettings,
  getSubscriptionUserWithSettingsFromServerModel,
} from './SubscriptionUserWithSettings.ts';

export interface ISubscriptionUsersUsage {
  readonly subscriptionId: Uuid;
  readonly subscriptionUsers: ReadonlyArray<ISubscriptionUserWithSettings>;
}

export function getSubscriptionUsersUsageFromServerModel(
  serverModel: ISubscriptionUsersUsageServerModel,
): ISubscriptionUsersUsage {
  assert(!!serverModel, () => 'SubscriptionUsersUsage server model is falsy.');

  return {
    subscriptionId: serverModel.subscriptionId,
    subscriptionUsers: serverModel.subscriptionUsers.map(
      getSubscriptionUserWithSettingsFromServerModel,
    ),
  };
}
