import { assert } from '@kontent-ai/utils';
import { css } from 'styled-components';

const roundTo2Digits = (num: number) => Math.round(num * 100) / 100;

export const px = <T extends number>(num: T): T extends 0 ? '0' : `${T}px` => {
  assert(
    Number.isFinite(num) && Math.abs(num) <= Number.MAX_SAFE_INTEGER,
    () => `${num} is not a valid value for conversion.`,
  );
  const roundedNumber = roundTo2Digits(num);
  return roundedNumber === 0 ? '0' : (`${roundedNumber}px` as any);
};

export const pxOrString = (val: number | string) => (typeof val === 'string' ? val : px(val));

export interface IBoxModelAttrs {
  readonly short?: number | string;
  readonly shortX?: number | string;
  readonly shortY?: number | string;
  readonly top?: number | string;
  readonly right?: number | string;
  readonly bottom?: number | string;
  readonly left?: number | string;
}

const boxModelAttributePrefixMap = {
  margin: 'margin-',
  padding: 'padding-',
  inset: '',
} as const;

export const addBoxModelAttrs = (
  attrName: keyof typeof boxModelAttributePrefixMap,
  attrs: IBoxModelAttrs,
) => {
  const {
    short,
    shortX = short,
    shortY = short,
    top = shortY,
    right = shortX,
    bottom = shortY,
    left = shortX,
  } = attrs;

  if ((top ?? bottom ?? left ?? right) !== undefined) {
    const prefix = boxModelAttributePrefixMap[attrName];
    return css`
      ${top !== undefined && css`${prefix}top: ${pxOrString(top)};`}
      ${right !== undefined && css`${prefix}right: ${pxOrString(right)};`}
      ${bottom !== undefined && css`${prefix}bottom: ${pxOrString(bottom)};`}
      ${left !== undefined && css`${prefix}left: ${pxOrString(left)};`}
    `;
  }
  return undefined;
};
