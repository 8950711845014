import { useEffect } from 'react';
import { modalOpened } from '../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ModalDialogType } from '../../../../_shared/constants/modalDialogType.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ProjectOverviewEventTypes } from '../../../../_shared/models/TrackUserEventData.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { areListingFilterAndSearchEmpty } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { clearListingFilter } from '../../../itemEditor/actions/contentActions.ts';
import { prepareNewContentItemDialogForProjectOverview } from '../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { projectOverviewLeft } from '../actions/projectOverviewActions.ts';
import { filterProjectOverview } from '../actions/thunkContentPlanningActions.ts';
import { ProjectOverviewPage as ProjectOverviewPageComponent } from '../components/ProjectOverviewPage.tsx';
import { ProjectOverviewPageType } from '../components/ProjectOverviewPageContent.tsx';
import { AggregationsState } from '../reducers/internalReducers/aggregations.ts';

export const ProjectOverviewPage = () => {
  const projectOverviewPageType = useSelector(getProjectOverviewPageType);
  const projectOverviewLoadingStatus = useSelector((s) => s.projectOverviewApp.loadingStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ProjectOverview, {
        action: ProjectOverviewEventTypes.PageEntered,
      }),
    );

    return () => {
      dispatch(projectOverviewLeft());
      dispatch(
        trackUserEventWithData(TrackedEvent.ProjectOverview, {
          action: ProjectOverviewEventTypes.PageLeft,
        }),
      );
    };
  }, []);

  const openCreateNewItemDialog = () => {
    dispatch(
      prepareNewContentItemDialogForProjectOverview({
        allowedContentTypeIds: null,
      }),
    );
    dispatch(modalOpened(ModalDialogType.NewContentItemDialog));
  };

  const onFilterChange = () => dispatch(filterProjectOverview());

  const onClearFilter = () => dispatch(clearListingFilter());

  return (
    <ProjectOverviewPageComponent
      listingItemsLoadingStatus={projectOverviewLoadingStatus}
      onClearFilter={onClearFilter}
      onFilterChange={onFilterChange}
      onOpenCreateNewItemDialog={openCreateNewItemDialog}
      projectOverviewPageType={projectOverviewPageType}
    />
  );
};

const noRelevantItemsForProjectOverviewFound = (
  aggregations: AggregationsState,
  loadingStatus: LoadingStatus,
): boolean => {
  const planningStatusTilesTotalCount =
    aggregations.itemsOnTrackIds.size +
    aggregations.delayedItemsIds.size +
    aggregations.scheduledToPublishItemsIds.size;
  const isLoading = loadingStatus !== LoadingStatus.Loaded;
  return !aggregations.itemsByWorkflowByStep.size && !planningStatusTilesTotalCount && !isLoading;
};

const getProjectOverviewPageType = (state: IStore): ProjectOverviewPageType => {
  const { aggregations, loadingStatus, itemsLimitExceeded } = state.projectOverviewApp;
  const {
    listingUi: { filter },
  } = state.contentApp;

  if (itemsLimitExceeded) {
    return ProjectOverviewPageType.LimitExceeded;
  }

  if (noRelevantItemsForProjectOverviewFound(aggregations, loadingStatus)) {
    if (areListingFilterAndSearchEmpty(filter)) {
      return ProjectOverviewPageType.EmptyProject;
    }

    return ProjectOverviewPageType.NoItemsFound;
  }

  return ProjectOverviewPageType.ProjectOverview;
};
