import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { AnimatedProgressIcon, Icon } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps, RefObject } from 'react';
import { DialogState } from '../DialogStateEnum.ts';

type ButtonProps = ComponentProps<typeof Button>;

export interface IDialogAction extends Omit<ButtonProps, 'buttonDisplay' | 'buttonStyle' | 'size'> {
  readonly ref?: RefObject<HTMLButtonElement>;
}

export interface IDialogActionWithMoreOptions extends IDialogAction {
  readonly text: string;
  readonly iconBefore?: Icon;
  readonly iconAfter?: Icon;
}

interface IDialogFooterActionProps extends IDialogActionWithMoreOptions {
  readonly dialogState: DialogState;
  readonly buttonStyle: ButtonStyle;
}

export const DialogFooterAction = React.forwardRef<HTMLButtonElement, IDialogFooterActionProps>(
  (
    { buttonStyle, dialogState, disabled, iconBefore, iconAfter, text, ...otherProps },
    forwardedRef,
  ) => {
    const isInProgress = buttonStyle === 'primary' && dialogState === DialogState.InProgress;

    return (
      <Button
        disabled={isInProgress || disabled}
        buttonStyle={buttonStyle}
        ref={forwardedRef}
        size="medium"
        {...otherProps}
      >
        {iconBefore && <Button.Icon icon={isInProgress ? AnimatedProgressIcon : iconBefore} />}
        <Button.Label>{text}</Button.Label>
        {iconAfter && <Button.Icon icon={isInProgress ? AnimatedProgressIcon : iconAfter} />}
      </Button>
    );
  },
);
