import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { IAssetFolderOption } from '../components/AssetListing/MoveToAssetFolderDialog.tsx';
import { RootFolderId } from '../constants/assetFolderConstants.ts';

const noFolderOption: IAssetFolderOption = {
  id: RootFolderId,
  label: 'Assets (no folder)',
  tooltip: undefined,
};

const isNestedFolder = (path: readonly string[]): boolean => path.length >= 2;

const createOption = (folder: ITaxonomyTerm, path: readonly string[]): IAssetFolderOption => {
  const isNested = isNestedFolder(path);

  const tooltip = isNested ? path.join(' > ') : undefined;

  return {
    id: folder.id,
    label: folder.name,
    tooltip,
  };
};

const getSubFolderOptions = (
  taxonomyGroup: ITaxonomyGroup,
  folder: ITaxonomyTerm | null = null,
  path: readonly string[] = [],
): readonly IAssetFolderOption[] => {
  const result: IAssetFolderOption[] = [];

  if (!folder) {
    result.push(noFolderOption);
  }

  const { childIds } = folder || taxonomyGroup;

  const childFolders = childIds.map((childId: Uuid) => {
    const childFolder = taxonomyGroup.terms.get(childId);

    if (!childFolder) {
      throw InvariantException(`Folder with id ${childId} does not exist`);
    }

    return childFolder;
  });

  const option = folder ? createOption(folder, path) : undefined;
  const optionItems: IAssetFolderOption[] = [];

  childFolders
    .sort(
      createCompare({
        select: (childFolder) => childFolder.name,
        compare: alphabetically,
      }),
    )
    .forEach((childFolder: ITaxonomyTerm) => {
      const newPath = [...path, childFolder.name];
      const newResults = getSubFolderOptions(taxonomyGroup, childFolder, newPath);

      if (folder) {
        optionItems.push(...newResults);
      } else {
        result.push(...newResults);
      }
    });

  if (option) {
    result.push({
      ...option,
      ...(optionItems.length
        ? {
            items: optionItems,
          }
        : {}),
    });
  }

  return result;
};

export const createMoveToFolderSelectorOptions = memoize.maxOne(getSubFolderOptions);
