import { useAttachRef } from '@kontent-ai/hooks';
import { RefCallback } from '@kontent-ai/utils';
import { Ref, RefObject, useCallback, useState } from 'react';

/**
 * usePopper hook needs to be recalled on ref change but does not support refCallback yet.
 * This hook fulfills refCallback API but forces rerender when ref to instance changes.
 */
export const usePopperRef = <TInstance extends HTMLElement>(
  ref?: Ref<TInstance>,
): [setRefCallback: RefCallback<TInstance>, refToElement: RefObject<TInstance>] => {
  // eslint-disable-next-line react/hook-use-state
  const [, _setInstance] = useState<TInstance | null>(null);
  const { refToForward, refObject } = useAttachRef<TInstance>(ref);

  const forwardRef = useCallback(
    (instance: TInstance | null) => {
      refToForward(instance);
      _setInstance(instance);
    },
    [refToForward],
  );

  return [forwardRef, refObject];
};
