import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../_shared/selectors/contentCollections.ts';
import { areSpacesInCollectionsAvailable } from '../../../contentInventory/content/selectors/areSpacesInCollectionsAvailable.ts';
import { hasProjectMultipleActiveLanguagesForCurrentUser } from '../selectors/hasProjectMultipleActiveLanguagesForCurrentUser.ts';
import { useContentStatusColumnSettings } from './useContentStatusColumnSettings.ts';

export const useHiddenColumns = (): ReadonlySet<ItemColumnCode> => {
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);
  const hasUserPermissionToMultipleCollections = useSelector(areCollectionsVisibleForCurrentUser);
  const hasUserPermissionToMultipleLanguages = useSelector(
    hasProjectMultipleActiveLanguagesForCurrentUser,
  );
  const isAvailableSpacesColumn = useSelector(areSpacesInCollectionsAvailable);
  const userId = useSelector((s) => s.data.user.info.userId);
  const { hiddenColumns } = useContentStatusColumnSettings(userId, currentProjectId);

  const columns: Set<ItemColumnCode> = new Set(hiddenColumns);
  if (!hasUserPermissionToMultipleCollections) {
    columns.add(ItemColumnCode.Collection);
  }

  if (!isAvailableSpacesColumn) {
    columns.add(ItemColumnCode.Space);
  }

  if (!hasUserPermissionToMultipleLanguages) {
    columns.add(ItemColumnCode.Language);
  }

  return columns;
};
