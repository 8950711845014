import React, { useEffect, useMemo } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { useIsDemoModeEnabled } from '../../contexts/MissionControlDemoContext.tsx';
import { DashboardTabContent } from '../components/DashboardTabContent.tsx';
import { getAvailableWidgets } from '../utils/widgetProvider.ts';

const DashboardTabContentContainer: React.FC = () => {
  const isDemoMode = useIsDemoModeEnabled();
  const projectId = useSelector(getCurrentProjectId);
  const user = useSelector((s) => s.data.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackUserEvent(TrackedEvent.DashboardOpened));
  }, []);

  const availableWidgets = useMemo(
    () => getAvailableWidgets(isDemoMode, { projectId, user }),
    [isDemoMode, projectId, user],
  );

  return <DashboardTabContent widgets={availableWidgets} />;
};

DashboardTabContent.displayName = 'DashboardTabContentContainer';
export { DashboardTabContentContainer as DashboardTabContent };
