import { ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getCommentsOnRemovedContent } from '../../selectors/inlineCommentSelectors.ts';
import { scrollToCommentThreadOnRemovedContent } from '../../utils/commentThreadsOnRemovedContentScrollingUtils.ts';
import { discussionSidebarOpened } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly unresolveCommentThread: (threadId: Uuid) => ThunkPromise;
}

export const createReopenCommentThread =
  (deps: IDeps) =>
  (commentThreadId: Uuid): ThunkFunction =>
  async (dispatch, getState) => {
    await dispatch(deps.unresolveCommentThread(commentThreadId));

    const isCommentThreadOnRemovedContent = getCommentsOnRemovedContent(getState()).find(
      (thread) => thread.id === commentThreadId,
    );

    if (isCommentThreadOnRemovedContent) {
      dispatch(discussionSidebarOpened());
      scrollToCommentThreadOnRemovedContent(commentThreadId);
    }
  };
