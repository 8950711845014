import { repositoryCollection } from '../../../../../../_shared/repositories/repositories.ts';
import { loadFilters } from '../../../../../../data/actions/thunkDataActions.ts';
import { createCreateLinkedFilterAction } from './thunks/createLinkedFilter.ts';
import { createDeleteSavedFilterAction } from './thunks/deleteSavedFilter.ts';
import { createInitializeSavedFiltersAction } from './thunks/initializeSavedFilters.ts';
import { createRestoreFilterAction } from './thunks/restoreSavedFilter.ts';
import { createSaveFilterAction } from './thunks/saveFilter.ts';
import { createUpdateSavedFilterAction } from './thunks/updateSavedFilter.ts';

const { filterRepository } = repositoryCollection;

export const createLinkedFilter = createCreateLinkedFilterAction({
  filterRepository,
});

export const deleteSavedFilter = createDeleteSavedFilterAction({
  filterRepository,
});

export const initSavedFiltersUi = createInitializeSavedFiltersAction({
  loadFilters,
});

export const saveFilter = createSaveFilterAction({
  filterRepository,
});

export const restoreSavedFilter = createRestoreFilterAction({
  filterRepository,
});

export const updateSavedFilter = createUpdateSavedFilterAction({
  filterRepository,
});
