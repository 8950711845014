import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_ResendInvitation_Failed,
  Subscription_ResendInvitation_Finished,
  Subscription_ResendInvitation_Started,
} from '../constants/actionTypes.ts';

export const adminsBeingReinvitedIds = (
  state = Immutable.Set<UserId>(),
  action: Action,
): Immutable.Set<UserId> => {
  switch (action.type) {
    case Subscription_ResendInvitation_Started:
      return state.add(action.payload.userId);

    case Subscription_ResendInvitation_Finished:
    case Subscription_ResendInvitation_Failed:
      return state.remove(action.payload.userId);

    default:
      return state;
  }
};
