import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { emptyNote } from '../../../../models/contentItem/Assignment.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

export const createUpdateNote =
  (deps: IDeps) =>
  (history: History, note: string, actionOrigin: ContentItemEditingEventOrigins): ThunkPromise =>
  async (dispatch, getState) => {
    const { editedContentItemVariant } = getState().contentApp;
    await dispatch(
      deps.updateAssignmentSection({
        actionOrigin,
        history,
        getUpdatedAssignment: () => ({
          note: {
            ...(editedContentItemVariant?.assignment.note || emptyNote),
            noteText: note,
          },
        }),
        submittingSection: AssignmentSections.Note,
      }),
    );
  };
