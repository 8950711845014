import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { ICollection } from '../../../../data/models/collections/Collection.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ICollectionGroup, IUserProjectSettings } from './UserProjectSettings.ts';
import { CollectionsByProjectMap } from './collectionsByProjectMap.type.ts';

export interface IUserProjectSettingsViewModel {
  readonly environmentSettings: ReadonlyArray<IUserEnvironmentSettingsViewModel>;
}

export interface IUserEnvironmentSettingsViewModel {
  readonly collectionGroupSettings: ReadonlyArray<IUserCollectionGroupSettingsViewModel>;
  readonly environment: IProjectDetails;
}

export interface IUserCollectionGroupSettingsViewModel {
  readonly collections: ReadonlyArray<ICollection>;
  readonly roleName: string;
  readonly isAssignedToAnyLanguage: boolean;
  readonly languageNames: ReadonlyArray<string>;
}

export const getUserProjectSettingsViewModel = memoize.weak(
  (
    userProjectSettings: ReadonlyArray<IUserProjectSettings>,
    allProjects: ReadonlyArray<IProjectDetails>,
    collectionsByProject: CollectionsByProjectMap,
  ): IUserProjectSettingsViewModel => {
    return {
      environmentSettings: userProjectSettings.map((settings) => {
        const projectId = settings.projectId;
        const collections = collectionsByProject.get(settings.projectId);
        if (!collections) {
          throw InvariantException(
            `UseProjectSettingsViewModel.ts: Cannot find loaded collections for project id ${projectId}`,
          );
        }

        return getEnvironmentSettingsViewModel(settings, allProjects, collections);
      }),
    };
  },
);

const getCollectionGroupSettingsViewModel = (
  collectionGroup: ICollectionGroup,
  allCollections: ReadonlyArray<ICollection>,
): ReadonlyArray<IUserCollectionGroupSettingsViewModel> =>
  collectionGroup.roles.map((role) => ({
    collections: allCollections.filter((collection) =>
      collectionGroup.collectionIds.includes(collection.id),
    ),
    isAssignedToAnyLanguage: role.isAssignedToAnyLanguage,
    languageNames: role.languages.map((language) => language.name),
    roleName: role.name,
  }));

const getEnvironmentSettingsViewModel = (
  userProjectSettings: IUserProjectSettings,
  allProjects: ReadonlyArray<IProjectDetails>,
  allCollections: ReadonlyArray<ICollection>,
): IUserEnvironmentSettingsViewModel => {
  const environment = allProjects.find(
    (project) => project.projectId === userProjectSettings.projectId,
  );
  if (!environment) {
    throw InvariantException(
      `UseProjectSettingsViewModel.ts: Cannot find project info id ${userProjectSettings.projectId}`,
    );
  }

  return {
    environment,
    collectionGroupSettings: userProjectSettings.collectionGroups.flatMap((collectionGroup) =>
      getCollectionGroupSettingsViewModel(collectionGroup, allCollections),
    ),
  };
};
