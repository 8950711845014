import { isSection } from '../../VerticalMenu/utils/utils.tsx';
import { ISelectItem, ISelectSection } from '../types.ts';

/**
 * Returns a new array with all items/sections that satisfy the provided predicate.
 * Filters out "empty" sections — sections with empty 'items' array.
 * If 'shouldAlwaysFilterNestedItems' is true, nested items are filtered with the provided predicate as well. By default, they are always
 * included if their parent passes the predicate.
 * @param items
 * @param predicate
 * @param shouldAlwaysFilterNestedItems
 */
export const filterItems = <TItem extends ISelectItem<TItem>>(
  items: ReadonlyArray<ISelectSection<TItem> | TItem>,
  predicate: (item: ISelectSection<TItem> | TItem) => boolean,
  shouldAlwaysFilterNestedItems: boolean = false,
): ReadonlyArray<ISelectSection<TItem> | TItem> => {
  return items.reduce<ReadonlyArray<ISelectSection<TItem> | TItem>>((result, item) => {
    if (isSection(item) && !item.items?.length) {
      return result;
    }

    if (predicate(item)) {
      const { items: itemItems, ...itemRest } = item;
      const filteredItems =
        item.items && shouldAlwaysFilterNestedItems
          ? filterItems(item.items, predicate, shouldAlwaysFilterNestedItems)
          : item.items;
      return [
        ...result,
        {
          ...itemRest,
          ...(filteredItems?.length ? { items: filteredItems } : {}),
        },
      ] as ReadonlyArray<ISelectSection<TItem> | TItem>;
    }

    const filteredItems = item.items
      ? filterItems(item.items, predicate, shouldAlwaysFilterNestedItems)
      : undefined;
    if (filteredItems?.length) {
      return [
        ...result,
        {
          ...item,
          items: filteredItems,
        },
      ] as ReadonlyArray<ISelectSection<TItem> | TItem>;
    }

    return result;
  }, []);
};
