import { Dispatch, GetState, ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightAssetTrackedEvent,
  TrackedEvent,
} from '../../../../../../../_shared/constants/trackedEvent.ts';
import { AiImageTaggingAction } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { isAiImageTaggingEnabled } from '../../../../../../../_shared/selectors/aiSelectors.ts';
import { wasAssetMatchedOnlyByTag } from '../../../../utils/wasAssetMatchedOnlyByTag.ts';
import { assetSelectorClosed } from '../modalAssetSelectorActions.ts';

export const createCloseAssetSelectorOnCancel =
  () =>
  (name: FindRightAssetTrackedEvent): ThunkFunction =>
  (dispatch: Dispatch): void => {
    dispatch(assetSelectorClosed());

    dispatch(trackUserEventWithData(TrackedEvent.FindRightAsset, { name }));
  };

export const createCloseAssetSelectorOnInsert =
  () =>
  (assetIds: UuidArray): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState): void => {
    const state = getState();
    const {
      assetLibraryApp: { openedFolderId },
    } = getState();

    dispatch(assetSelectorClosed());

    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightAsset, {
        name: FindRightAssetTrackedEvent.InsertedAssetsFromModalSelector,
        'asset-ids': JSON.stringify(assetIds),
        'opened-folder-id': openedFolderId,
      }),
    );

    if (isAiImageTaggingEnabled(state)) {
      const {
        assetLibraryApp: {
          query: { searchPhrase },
        },
        data: {
          assets: { byId: assetsById },
        },
      } = state;

      const selectedAssets = assetsById.filter((asset) => assetIds.includes(asset?.id)).toArray();
      const hasAnyTaggedAssetBeenSelected = !!selectedAssets.find((asset) => asset.tags.length > 0);
      if (hasAnyTaggedAssetBeenSelected) {
        dispatch(
          trackUserEventWithData(TrackedEvent.AiImageTagging, {
            action: AiImageTaggingAction.TaggedAssetInserted,
            matchedByTag: !!selectedAssets.find((asset) =>
              wasAssetMatchedOnlyByTag(asset, searchPhrase),
            ),
          }),
        );
      }
    }
  };
