import React, { useContext } from 'react';

const IsUnifiedMissionControlEnabledContext = React.createContext<boolean>(false);

export const IsUnifiedMissionControlEnabledContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isEnabled = true;

  return (
    <IsUnifiedMissionControlEnabledContext.Provider value={isEnabled}>
      {children}
    </IsUnifiedMissionControlEnabledContext.Provider>
  );
};

export const useIsUnifiedMissionControlEnabled = (): boolean =>
  useContext(IsUnifiedMissionControlEnabledContext);
