import React from 'react';
import { SpaceRoute, SpaceRouteParams } from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../_shared/utils/permissions/capabilityUtils.ts';
import { createSpaceListingLink } from '../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { InitializedWebSpotlight } from '../components/InitializedWebSpotlight.tsx';
import { WebSpotlightEmptyState } from '../components/WebSpotlightEmptyState.tsx';

export const WebSpotlightContent: React.FC = () => {
  const spaceId = useSelector((s) => s.webSpotlightApp.spaceId);
  const rootItemId = useSelector((s) => s.webSpotlightApp.rootItemId);

  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const space = useSelector((s) => (spaceId ? s.data.spaces.byId.get(spaceId) : null));
  const canConnectRootItemToSpace = useSelector((s) =>
    currentUserHasCapabilities(s, Capability.ManageSpaces, Capability.CreateContent),
  );

  if (rootItemId) {
    return <InitializedWebSpotlight rootItemId={rootItemId} />;
  }

  if (space) {
    const expandedSpacePath = buildPath<SpaceRouteParams>(SpaceRoute, {
      projectId,
      spaceId: space.id,
    });

    return (
      <WebSpotlightEmptyState
        actionPath={expandedSpacePath}
        actionText={`Set root item for ${space.name}`}
        isActionAvailable={canConnectRootItemToSpace}
      />
    );
  }

  return (
    <WebSpotlightEmptyState
      actionPath={createSpaceListingLink({ projectId })}
      actionText="Go to spaces"
      isActionAvailable={canConnectRootItemToSpace}
    />
  );
};

WebSpotlightContent.displayName = 'WebSpotlightContent';
