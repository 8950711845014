import { ErrorTag } from '@kontent-ai/component-library/Selects';
import { colorBackgroundDisabledComplementary } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { DefaultTag } from '../../../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import { hiddenEnvironmentsOptionId } from '../../selectors/getHiddenEnvironmentOption.ts';
import {
  AllEnvironmentsTagId,
  EnvironmentOption,
} from '../../selectors/getSelectedEnvironmentOptions.ts';

type ApiKeyEnvironmentOptionProps = {
  readonly canUpdateApiKey: boolean;
  readonly defaultTagProps: ComponentProps<typeof DefaultTag>;
  readonly environmentOption: EnvironmentOption;
  readonly hasError: boolean;
  readonly tooltipText: string;
};

export const ApiKeyEnvironmentOption: React.FC<ApiKeyEnvironmentOptionProps> = ({
  canUpdateApiKey,
  defaultTagProps,
  environmentOption,
  hasError,
  tooltipText,
}) => {
  const { id, label } = environmentOption;

  if (id === AllEnvironmentsTagId) {
    return (
      <Tag {...defaultTagProps} label={label} background={colorBackgroundDisabledComplementary} />
    );
  }

  if (id === hiddenEnvironmentsOptionId) {
    return (
      <Tag
        {...defaultTagProps}
        label={label}
        background={colorBackgroundDisabledComplementary}
        customTooltipText="Your role is limited so you don’t have access to these environments."
      />
    );
  }

  if (hasError) {
    return (
      <ErrorTag
        {...defaultTagProps}
        label={label}
        customTooltipText={tooltipText}
        disabled={!canUpdateApiKey}
      />
    );
  }

  return <DefaultTag {...defaultTagProps} label={label} />;
};

ApiKeyEnvironmentOption.displayName = 'ApiKeyEnvironmentOption';
