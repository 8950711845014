import { Action } from '../../../../@types/Action.type.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import {
  Collections_Save_Failed,
  Collections_Save_Finished,
  Collections_Save_Started,
} from '../../../environmentSettings/collections/constants/actionTypes.ts';
import {
  Environment_RenameEnvironment_Failed,
  Environment_RenameEnvironment_Finished,
  Environment_RenameEnvironment_Started,
} from '../../../environmentSettings/general/constants/generalSettingsActionTypes.ts';
import {
  LocalizationEditor_DefaultLangEditor_Hide,
  LocalizationEditor_Init_Started,
  LocalizationEditor_LangCreator_Hide,
  LocalizationEditor_LangEditor_Hide,
  LocalizationEditor_Language_Add,
  LocalizationEditor_Language_Update,
  LocalizationEditor_Saving_Failed,
  LocalizationEditor_Saving_Started,
  LocalizationEditor_Saving_Success,
} from '../../../environmentSettings/localization/constants/localizationActionTypes.ts';
import {
  Spaces_Create_Failed,
  Spaces_Create_Started,
  Spaces_Create_Succeeded,
  Spaces_Delete_Failed,
  Spaces_Delete_Started,
  Spaces_Delete_Succeeded,
  Spaces_Update_Failed,
  Spaces_Update_Started,
  Spaces_Update_Succeeded,
} from '../../../environmentSettings/spaces/constants/spacesActionTypes.ts';
import {
  UserDetail_UpdateCollectionGroups_Failed,
  UserDetail_UpdateCollectionGroups_Finished,
  UserDetail_UpdateCollectionGroups_Started,
  Users_ProjectContributorsLoaded,
} from '../../../environmentSettings/users/constants/usersActionTypes.ts';
import {
  Workflow_Editing_WorkflowSaveFailed,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_Editing_WorkflowSaveStarted,
  Workflow_InitializeEditing_Started,
  Workflow_ServerValidation_ReceivedGlobalErrors,
  Workflow_ServerValidation_ReceivedStepErrors,
} from '../../../environmentSettings/workflow/constants/workflowActionTypes.ts';
import {
  Project_RenameProjectOnServer_Failed,
  Project_RenameProjectOnServer_Finished,
  Project_RenameProjectOnServer_Started,
} from '../../../projectSettings/general/constants/generalSettingsActionTypes.ts';
import {
  Project_Delete_Failed,
  Project_Delete_Finished,
  Project_Delete_Started,
} from '../../constants/projectActionTypes.ts';
import { IProjectSavingStatus } from '../../models/IProjectSavingStatus.type.ts';

const initialStatus: IProjectSavingStatus = {
  languages: {
    status: OperationStatus.Success,
  },
  projectDelete: {
    status: OperationStatus.Success,
    deletedProjectName: undefined,
  },
  projectName: {
    status: OperationStatus.Success,
  },
  environmentName: {
    status: OperationStatus.Success,
  },
  users: {
    status: OperationStatus.Success,
  },
  workflow: {
    status: OperationStatus.Success,
  },
  collections: {
    status: OperationStatus.Success,
  },
  spaces: {
    status: OperationStatus.Success,
  },
};

export const savingStatus = (state = initialStatus, action: Action): IProjectSavingStatus => {
  switch (action.type) {
    case Project_RenameProjectOnServer_Started: {
      return {
        ...state,
        projectName: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Project_RenameProjectOnServer_Finished: {
      return {
        ...state,
        projectName: {
          status: OperationStatus.Success,
        },
      };
    }

    case Project_RenameProjectOnServer_Failed: {
      return {
        ...state,
        projectName: {
          status: OperationStatus.Failed,
        },
      };
    }

    case Project_Delete_Started: {
      return {
        ...state,
        projectDelete: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Project_Delete_Failed: {
      return {
        ...state,
        projectDelete: {
          status: OperationStatus.Failed,
          deletedProjectName: action.payload.projectName,
        },
      };
    }

    case Project_Delete_Finished: {
      return {
        ...state,
        projectDelete: {
          status: OperationStatus.Success,
          deletedProjectName: action.payload.projectName,
        },
      };
    }

    case Environment_RenameEnvironment_Started: {
      return {
        ...state,
        environmentName: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Environment_RenameEnvironment_Failed: {
      return {
        ...state,
        environmentName: {
          status: OperationStatus.Failed,
        },
      };
    }

    case Environment_RenameEnvironment_Finished: {
      return {
        ...state,
        environmentName: {
          status: OperationStatus.Success,
        },
      };
    }

    case LocalizationEditor_Language_Add:
    case LocalizationEditor_Language_Update:
    case LocalizationEditor_Saving_Started: {
      return {
        ...state,
        languages: {
          status: OperationStatus.Pending,
        },
      };
    }

    case LocalizationEditor_Init_Started:
    case LocalizationEditor_DefaultLangEditor_Hide:
    case LocalizationEditor_LangEditor_Hide:
    case LocalizationEditor_LangCreator_Hide: {
      return {
        ...state,
        languages: {
          status: OperationStatus.Initialized,
        },
      };
    }

    case LocalizationEditor_Saving_Success: {
      return {
        ...state,
        languages: {
          status: OperationStatus.Success,
        },
      };
    }

    case LocalizationEditor_Saving_Failed: {
      return {
        ...state,
        languages: {
          status: OperationStatus.Failed,
        },
      };
    }

    case UserDetail_UpdateCollectionGroups_Started: {
      return {
        ...state,
        users: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Users_ProjectContributorsLoaded:
    case UserDetail_UpdateCollectionGroups_Finished: {
      return {
        ...state,
        users: {
          status: OperationStatus.Success,
        },
      };
    }

    case UserDetail_UpdateCollectionGroups_Failed: {
      return {
        ...state,
        users: {
          status: OperationStatus.Failed,
        },
      };
    }

    case Workflow_Editing_WorkflowSaveStarted: {
      return {
        ...state,
        workflow: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Workflow_InitializeEditing_Started:
    case Workflow_Editing_WorkflowSaveFinished: {
      return {
        ...state,
        workflow: {
          status: OperationStatus.Success,
        },
      };
    }

    case Workflow_ServerValidation_ReceivedStepErrors:
    case Workflow_ServerValidation_ReceivedGlobalErrors:
    case Workflow_Editing_WorkflowSaveFailed: {
      return {
        ...state,
        workflow: {
          status: OperationStatus.Failed,
        },
      };
    }

    case Collections_Save_Started: {
      return {
        ...state,
        collections: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Collections_Save_Finished: {
      return {
        ...state,
        collections: {
          status: OperationStatus.Success,
        },
      };
    }

    case Collections_Save_Failed: {
      return {
        ...state,
        collections: {
          status: OperationStatus.Failed,
        },
      };
    }

    case Spaces_Create_Started:
    case Spaces_Delete_Started:
    case Spaces_Update_Started: {
      return {
        ...state,
        spaces: {
          status: OperationStatus.Pending,
        },
      };
    }

    case Spaces_Create_Succeeded:
    case Spaces_Delete_Succeeded:
    case Spaces_Update_Succeeded: {
      return {
        ...state,
        spaces: {
          status: OperationStatus.Success,
        },
      };
    }

    case Spaces_Create_Failed:
    case Spaces_Delete_Failed:
    case Spaces_Update_Failed: {
      return {
        ...state,
        spaces: {
          status: OperationStatus.Failed,
        },
      };
    }

    default:
      return state;
  }
};
