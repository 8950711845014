import { IRole } from '../../../../data/models/roles/IRole.ts';
import {
  IRoleWithSettings,
  getRoleWithSettingsFromServerModel,
} from '../../../../data/models/roles/IRoleWithSettings.ts';
import { RoleOption } from '../../../../data/models/roles/RoleOption.ts';
import { IRoleServerModel } from '../../../../repositories/serverModels/IRoleServerModel.type.ts';
import { getSortedRoleOptionsListWithAllRolesFirst } from '../../selectors/getSortedRoleOptionsListWithAllRolesFirst.ts';
import {
  getDefaultRolesForFormInput as generalGetDefaultRolesForFormInput,
  handleAllRolesRoleOnSubmit as generalHandleAllRolesRoleOnSubmit,
} from '../../utils/allRolesRoleBehaviorUtils.ts';

export const handleAllRolesRoleOnSubmit = (roleIds: ReadonlyArray<Uuid>): ReadonlyArray<Uuid> =>
  Array.from(generalHandleAllRolesRoleOnSubmit(roleIds));

export const getSortedRoleOptionsListFromServerModelWithAllRolesFirst = (
  rolesServerModel: ReadonlyArray<IRoleServerModel>,
): ReadonlyArray<RoleOption> =>
  getSortedRoleOptionsListWithAllRolesFirst(
    rolesServerModel.reduce(
      (reduced, roleServerModel) =>
        reduced.set(roleServerModel.id, getRoleWithSettingsFromServerModel(roleServerModel)),
      new Map<Uuid, IRoleWithSettings>(),
    ),
  );

export const getDefaultRolesForFormInput = (
  roleIds: ReadonlyArray<Uuid>,
  allRoles: ReadonlyArray<IRole>,
): ReadonlyArray<Uuid> =>
  generalGetDefaultRolesForFormInput(new Set<Uuid>(roleIds), allRoles, false).map(
    (role) => role.id,
  );
