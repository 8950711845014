import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import {
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { buildPath, matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

export const redirectToItem = (history: History, itemId: Uuid): void => {
  const matchParams = matchPath<ContentItemsRouteParams>(
    history.location.pathname,
    ContentItemsRoute,
  );
  if (!matchParams) {
    throw InvariantException('Current route does not belong to ContentItemsRoute routes');
  }
  const contentItemPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
    app: matchParams.app,
    projectId: matchParams.projectId,
    variantId: matchParams.variantId,
    spaceId: matchParams.spaceId,
    contentItemIds: [itemId],
  });
  history.push(contentItemPath);
};
