import { InvariantException } from '@kontent-ai/errors';
import {
  ConditionAction,
  MultipleChoiceConditionOperator,
  TypeElementCondition,
  emptyTypeElementCondition,
} from '../../../../../_shared/models/utils/TypeElementCondition.ts';
import {
  ConditionActionServerModel,
  ConditionTriggerTypeServerModel,
  MultipleChoiceConditionOperatorServerModel,
  TypeElementConditionServerModel,
} from '../../../../../repositories/serverModels/TypeElementConditionServerModel.type.ts';
import { ContentTypeElementBaseServerModel } from '../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';

export function convertBaseTypeElementDataToServerModel(
  typeElement: IBaseTypeElementData,
): ContentTypeElementBaseServerModel {
  return {
    codeName: typeElement.codename || null,
    conditions: convertConditionToServerModel(typeElement.condition),
    contentGroupId: typeElement.contentGroupId,
    elementId: typeElement.elementId,
    guidelines: typeElement.guidelines || null,
    isNonLocalizable: typeElement.isNonLocalizable,
    isRequired: typeElement.isRequired,
    name: typeElement.name,
  };
}

export function convertConditionToServerModel(
  condition: TypeElementCondition | null,
): ReadonlyArray<TypeElementConditionServerModel> {
  if (!condition || isEmpty(condition)) {
    return [];
  }

  const conditionServerModel: TypeElementConditionServerModel = {
    id: condition.id,
    isActive: condition.isActive,
    action: convertConditionActionToServerModel(condition.action),
    triggers: isTriggerSelected(condition)
      ? [
          {
            elementId: condition.trigger.elementId,
            triggerType: ConditionTriggerTypeServerModel.MultipleChoiceTypeElement,
            operator: convertMultipleChoiceOperatorToServerModel(condition.trigger.operator),
            values: condition.trigger.optionIds,
          },
        ]
      : [],
  };

  return [conditionServerModel];
}

const isEmpty = (condition: TypeElementCondition): boolean =>
  !condition.isActive &&
  condition.action === emptyTypeElementCondition.action &&
  condition.trigger.elementId === emptyTypeElementCondition.trigger.elementId;

const isTriggerSelected = (condition: TypeElementCondition): boolean =>
  !!condition.trigger.elementId;

function convertConditionActionToServerModel(action: ConditionAction): ConditionActionServerModel {
  switch (action) {
    case ConditionAction.Hide:
      return ConditionActionServerModel.Hide;
    case ConditionAction.Show:
      return ConditionActionServerModel.Show;
    default:
      throw InvariantException(
        `${__filename}: Error converting condition to server model. Unknown condition action '${action}'.`,
      );
  }
}

function convertMultipleChoiceOperatorToServerModel(
  operator: MultipleChoiceConditionOperator,
): MultipleChoiceConditionOperatorServerModel {
  switch (operator) {
    case MultipleChoiceConditionOperator.All:
      return MultipleChoiceConditionOperatorServerModel.All;
    case MultipleChoiceConditionOperator.Any:
      return MultipleChoiceConditionOperatorServerModel.Any;
    default:
      throw InvariantException(
        `${__filename}: Error converting condition trigger to server model. Unknown condition trigger operator '${operator}'.`,
      );
  }
}
