const ColorPalette = {
  Gray10: '#F3F3F3',
  Gray20: '#DFDFDF',
  Gray30: '#C6C6C6',
  Gray40: '#A8A8A8',
  Gray50: '#8C8C8C',
  Gray60: '#6F6F6F',
  Gray70: '#525252',
  Gray80: '#393939',
  Gray90: '#262626',
  Gray100: '#151515',
  NeonGreen10: '#D9FFC0',
  NeonGreen20: '#9BF800',
  NeonGreen30: '#89DC00',
  NeonGreen40: '#74BB00',
  NeonGreen50: '#609D00',
  NeonGreen60: '#4B7C00',
  NeonGreen70: '#365C00',
  NeonGreen80: '#254000',
  NeonGreen90: '#172B00',
  NeonGreen100: '#0B1900',
  PersianGreen10: '#D0FFE4',
  PersianGreen20: '#92F4C0',
  PersianGreen30: '#00E293',
  PersianGreen40: '#00C07C',
  PersianGreen50: '#00A168',
  PersianGreen60: '#007F51',
  PersianGreen70: '#005F3B',
  PersianGreen80: '#004228',
  PersianGreen90: '#002D1A',
  PersianGreen100: '#001B0D',
  SkyBlue10: '#E9F6FF',
  SkyBlue20: '#BEE6FF',
  SkyBlue30: '#72D1FF',
  SkyBlue40: '#00B6EA',
  SkyBlue50: '#0098C5',
  SkyBlue60: '#00789C',
  SkyBlue70: '#005975',
  SkyBlue80: '#003E53',
  SkyBlue90: '#002A39',
  SkyBlue100: '#001923',
  OceanBlue10: '#F1F4FF',
  OceanBlue20: '#D6E0FF',
  OceanBlue30: '#B1C5FF',
  OceanBlue40: '#81A6FF',
  OceanBlue50: '#438AFF',
  OceanBlue60: '#006CDC',
  OceanBlue70: '#0050A6',
  OceanBlue80: '#003777',
  OceanBlue90: '#002554',
  OceanBlue100: '#001535',
  DeepPurple10: '#F3F3FE',
  DeepPurple20: '#DEDDFD',
  DeepPurple30: '#C3C0FC',
  DeepPurple40: '#A29EFA',
  DeepPurple50: '#847EF9',
  DeepPurple60: '#5B4FF5',
  DeepPurple70: '#3B23F1',
  DeepPurple80: '#2711B3',
  DeepPurple90: '#190980',
  DeepPurple100: '#0D0454',
  Purple10: '#F8F2FF',
  Purple20: '#EBD9FF',
  Purple30: '#DAB8FF',
  Purple40: '#C98FFF',
  Purple50: '#BC62FF',
  Purple60: '#AD00FB',
  Purple70: '#8200BE',
  Purple80: '#5C0089',
  Purple90: '#400061',
  Purple100: '#28003E',
  Rose10: '#FFF1F4',
  Rose20: '#FFD6E0',
  Rose30: '#FFB0C6',
  Rose40: '#FF7FA8',
  Rose50: '#FF3E8D',
  Rose60: '#D9006F',
  Rose70: '#A30052',
  Rose80: '#750039',
  Rose90: '#520026',
  Rose100: '#340016',
  Red10: '#FFEBEB',
  Red20: '#FEC7C7',
  Red30: '#FE9A9A',
  Red40: '#FD6262',
  Red50: '#FD3030',
  Red60: '#DB0000',
  Red70: '#A90000',
  Red80: '#790000',
  Red90: '#550000',
  Red100: '#360000',
  Orange10: '#FFF1EF',
  Orange20: '#FFD7D2',
  Orange30: '#FFB3A8',
  Orange40: '#FF856C',
  Orange50: '#F85600',
  Orange60: '#C54300',
  Orange70: '#943000',
  Orange80: '#6A2000',
  Orange90: '#4A1400',
  Orange100: '#2F0900',
  Yellow10: '#FFF2E8',
  Yellow20: '#FFD9BA',
  Yellow30: '#FFB865',
  Yellow40: '#E59800',
  Yellow50: '#C17F00',
  Yellow60: '#996400',
  Yellow70: '#724A00',
  Yellow80: '#513300',
  Yellow90: '#382200',
  Yellow100: '#221300',
} as const;

export const UncategorizedColor = {
  Black: '#000000',
  White: '#FFFFFF',
  Transparent: 'transparent',
  AzureBlue: '#0093FF',
  BrickOrange: '#FF3D00',
  CheeseYellow: '#F9A600',
} as const;

export const BaseColor = {
  ...ColorPalette,
  ...UncategorizedColor,
} as const;

const TransparentColorDisambiguationSymbol: unique symbol = Symbol(
  'TransparentColorDisambiguationSymbol',
);

export type TransparentColor = string & {
  colorDisambiguationSymbol: typeof TransparentColorDisambiguationSymbol;
};
export type BaseColor = (typeof BaseColor)[keyof typeof BaseColor];
export type UncategorizedColor = (typeof UncategorizedColor)[keyof typeof UncategorizedColor];
export type Color = BaseColor | TransparentColor;
