import React from 'react';
import {
  IForwardedRefProps,
  forwardRef,
} from '../../../../../../_shared/utils/forwardedRefProps.tsx';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { NewCommentThreadItem } from './threadItem/NewCommentThreadItem.tsx';
import { NewCommentThreadItemReplyOptions } from './threadItem/NewCommentThreadItemReplyOptions.tsx';

type ReplyProps = IForwardedRefProps<NewCommentThreadItem> & {
  readonly canSuggest: boolean;
  readonly commentThread: ICommentThread;
  readonly isResolved: boolean;
  readonly onBlurNewItemText: (isCommentPending: boolean) => void;
  readonly onCancelNewItem: () => void;
  readonly onStartNewItem: (type: CommentThreadItemType) => void;
  readonly onSubmitNewItem: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly type: CommentThreadItemType | undefined;
};

const isNewCommentThreadItem = (
  commentThread: ICommentThread,
  type?: CommentThreadItemType,
): type is CommentThreadItemType =>
  !!type && (commentThread.isReplying || commentThread.isSubmitting);

const _Reply: React.FC<ReplyProps> = (props) => {
  if (isNewCommentThreadItem(props.commentThread, props.type)) {
    return (
      <div className="comment-editor__reply">
        <NewCommentThreadItem
          ref={props.forwardedRef}
          elementSegment={props.commentThread.elementSegment}
          inputValue=""
          isEditing={props.commentThread.isReplying}
          isSubmitting={props.commentThread.isSubmitting}
          onBlur={props.onBlurNewItemText}
          onCancel={props.onCancelNewItem}
          onSubmit={props.onSubmitNewItem}
          type={props.type}
        />
      </div>
    );
  }

  return (
    <NewCommentThreadItemReplyOptions
      canSuggest={props.canSuggest && !props.isResolved}
      onNewSuggestion={() => props.onStartNewItem(CommentThreadItemType.Suggestion)}
      onNewComment={() => props.onStartNewItem(CommentThreadItemType.Comment)}
    />
  );
};

_Reply.displayName = 'Reply';

export const Reply = forwardRef(_Reply);
