import Immutable from 'immutable';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

const areValuesTheSame = (
  modularItems1: Immutable.List<Uuid>,
  modularItems2: Immutable.List<Uuid>,
): boolean =>
  modularItems1.size === modularItems2.size && Immutable.is(modularItems1, modularItems2);

export const areModularContentsItemElementsTheSame = (
  itemElement1: ILinkedItemsElement,
  itemElement2: ILinkedItemsElement,
): boolean => {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }
  return areValuesTheSame(itemElement1.value, itemElement2.value);
};
