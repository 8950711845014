import { Hint } from '@kontent-ai/component-library/Hint';
import { InputType } from '@kontent-ai/component-library/Input';
import React from 'react';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { DisabledSearchHelpMessage, ExactMatchHelpMessage } from '../constants/fullTextSearch.ts';
import { MaxFullTextSearchPhraseLength } from '../constants/uiConstants.ts';
import { validateFullTextSearchPhrase } from '../utils/validateFullTextSearchPhrase.ts';
import { ContentItemsSearchSort } from './ContentItemsSearchSort.tsx';

type Props = {
  readonly autofocus?: boolean;
  readonly fullTextSearchStatus: FullTextSearchStatus;
  readonly onSortReset: () => void;
  readonly onUpdateSearchPhrase: (text: string) => void;
  readonly orderByColumnIsCustom: boolean;
  readonly searchPhrase: string;
};

export const ContentItemsTextFilter: React.FC<Props> = ({
  autofocus,
  fullTextSearchStatus,
  onSortReset,
  onUpdateSearchPhrase,
  orderByColumnIsCustom,
  searchPhrase,
}) => {
  const isFullTextSearchEnabled = fullTextSearchStatus === FullTextSearchStatus.Enabled;

  const helpMessage = isFullTextSearchEnabled ? ExactMatchHelpMessage : DisabledSearchHelpMessage;

  const errorMessage = validateFullTextSearchPhrase({
    fullTextSearchStatus,
    searchPhrase,
  });

  return (
    <TextFilter
      autofocus={autofocus}
      errorMessage={errorMessage}
      extraIconsAfterReset={[
        <Hint
          key="full-text-search-help"
          tooltipPlacement="bottom"
          tooltipText={helpMessage}
          ariaLabel="Show more information"
        />,
      ]}
      extraIconsBeforeReset={[
        <ContentItemsSearchSort
          isVisible={isFullTextSearchEnabled && !!searchPhrase}
          key="full-text-search-sort"
          onSortReset={onSortReset}
          orderByColumnIsCustom={orderByColumnIsCustom}
        />,
      ]}
      maxLength={
        isFullTextSearchEnabled
          ? MaxFullTextSearchPhraseLength
          : ValidationConstants.ContentItemNameMaxLength
      }
      onChange={onUpdateSearchPhrase}
      placeholder={
        isFullTextSearchEnabled
          ? 'Search for items by their name or content'
          : 'Search for items by their name'
      }
      text={searchPhrase}
      ariaLabel="Search content items"
      type={InputType.Search}
    />
  );
};

ContentItemsTextFilter.displayName = 'ContentItemsTextFilter';
