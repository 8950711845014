import React from 'react';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { DeletedSpaceText } from '../constants/spacesUiConstants.ts';
import { SpaceDeletionData } from '../types/spaceDeletion.type.ts';

type Props = {
  readonly deletedSpace: SpaceDeletionData;
  readonly onRestore: (spaceId: Uuid) => void;
};

export const DeletedSpaceListingMessage: React.FC<Props> = ({ deletedSpace, onRestore }) => (
  <ListingMessage
    statusInfoMessage={{
      text: DeletedSpaceText`${deletedSpace.name}`,
      style: StatusMessageStyle.Deleted,
    }}
    onUndo={() => onRestore(deletedSpace.id)}
  />
);

DeletedSpaceListingMessage.displayName = 'SpacesListingMessage';
