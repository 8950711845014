import { UnreachableCaseException } from '@kontent-ai/errors';
import { Property } from 'csstype';
import styled from 'styled-components';
import { colorBackgroundDisabled, colorInputBackground } from '../../../tokens/decision/colors.ts';
import { spacingInputLarge } from '../../../tokens/decision/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { InputState } from '../inputStateEnum.ts';
import { InputStyleProps } from './inputStyleProps.type.ts';
import { getInputCursor } from './stylingUtils.ts';
import { inputBorderRadius } from './tokens.ts';

const getInputFieldBackground = ({ $inputState }: InputStyleProps) => {
  switch ($inputState) {
    case InputState.Disabled:
    case InputState.ReadOnly:
      return colorBackgroundDisabled;
    case InputState.Default:
    case InputState.Alert:
      return colorInputBackground;
    default:
      throw UnreachableCaseException($inputState);
  }
};

export const InputField = styled.span<
  InputStyleProps & {
    readonly overflow?: Property.Overflow;
  }
>`
  position: relative;
  
  display: block;
  padding: 0 ${px(spacingInputLarge)};
  border-radius: ${inputBorderRadius};
  
  cursor: ${getInputCursor};
  background: ${getInputFieldBackground};

  ${(props) => (props.overflow ? `overflow:${props.overflow}` : '')};
`;
InputField.displayName = 'InputField';
