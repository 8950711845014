import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import { forwardRef, useState } from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { ModalSection } from '../../../../../_shared/features/ChangeWorkflowStepModal/components/ModalSection.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { CollectionSingleSelect } from '../../../../contentInventory/shared/components/CollectionSingleSelect.tsx';
import {
  duplicateToCollectionHeader,
  duplicateToCollectionWarning,
} from '../constants/duplicateToCollectionUiConstants.ts';

interface IDuplicateToCollectionDialogProps extends IAnimatedModalDialogProps {
  readonly collectionName: string;
  readonly collectionOptions: ReadonlyArray<ICollection>;
  readonly itemName: string;
  readonly onClose: () => void;
  readonly onDuplicate: (collectionId: Uuid) => void;
}

export const DuplicateToCollectionDialog = forwardRef<
  HTMLDivElement,
  IDuplicateToCollectionDialogProps
>(({ collectionName, collectionOptions, isOpen, itemName, onClose, onDuplicate }, ref) => {
  const [collectionId, setCollectionId] = useState<string | null>(null);

  return (
    <ModalDialog
      cancelAction={{
        onClick: onClose,
        ...getDataUiActionAttribute(DataUiAction.CloseDialog),
      }}
      headline={duplicateToCollectionHeader(itemName)}
      isDismissable
      isOpen={isOpen}
      maxWidth={px(90 * gridUnit)}
      minWidth={px(80 * gridUnit)}
      onClose={onClose}
      primaryAction={{
        text: 'Duplicate',
        disabled: !collectionId,
        onClick: collectionId ? () => onDuplicate(collectionId) : undefined,
        ...getDataUiActionAttribute(DataUiAction.Duplicate),
        tooltipText: collectionId ? undefined : 'Select a collection',
        tooltipPlacement: 'top-end',
      }}
      ref={ref}
    >
      <>
        <Warning hideTitle>{duplicateToCollectionWarning(collectionName)}</Warning>
        <div className="duplicate-to-collection-modal__section">
          <ModalSection title="Collection">
            <CollectionSingleSelect
              currentCollectionId={collectionId}
              onChange={setCollectionId}
              collectionOptions={collectionOptions}
              shouldHighlightAsError={false}
            />
          </ModalSection>
        </div>
      </>
    </ModalDialog>
  );
});

DuplicateToCollectionDialog.displayName = 'DuplicateToCollectionDialog';
