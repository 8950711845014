import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_Editing_Left,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
} from '../../constants/workflowActionTypes.ts';

export const editedWorkflowId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Workflow_InitializeCreating_Finished:
      return null;

    case Workflow_InitializeEditing_Finished:
      return action.payload.workflow.id;

    case Workflow_Editing_Left:
      return null;

    default:
      return state;
  }
};
