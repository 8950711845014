import { assert } from '@kontent-ai/utils';
import { IProjectListingUserServerModel } from '../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';

export interface IProjectListingUser {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly userId: UserId;
}

export const emptyProjectListingUser: IProjectListingUser = {
  email: '',
  firstName: '',
  lastName: '',
  userId: '',
};

export const mapProjectListingUserFromServerModel = (
  rawProjectUser: IProjectListingUserServerModel,
): IProjectListingUser => {
  assert(!!rawProjectUser, () => 'ProjectListingUser server model is falsy.');

  return {
    email: rawProjectUser.email ?? emptyProjectListingUser.email,
    firstName: rawProjectUser.firstName ?? emptyProjectListingUser.firstName,
    lastName: rawProjectUser.lastName ?? emptyProjectListingUser.lastName,
    userId: rawProjectUser.userId ?? emptyProjectListingUser.userId,
  };
};
