// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSelector as _useSelector } from 'react-redux';
import { IStore } from '../stores/IStore.type.ts';

export function useSelector<TReturn>(
  selector: (state: IStore) => TReturn,
  equalityFn?: (a: TReturn, b: TReturn) => boolean,
): TReturn {
  return _useSelector(selector, { equalityFn, devModeChecks: { stabilityCheck: 'never' } });
}
