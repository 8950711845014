import { ThunkFunction } from '../../../@types/Dispatcher.type.ts';
import { lockedElementsChanged } from '../../../applications/itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { Notifications } from '../../services/signalR/signalRClient.type.ts';
import { getEditedContentItemIdFromPath } from '../../utils/routing/routeTransitionUtils.ts';

export const onLockedElementsChange =
  (params: Notifications['lockedElementsChange']['payload']): ThunkFunction =>
  (dispatch) => {
    const currentContentItemId = getEditedContentItemIdFromPath(self.location.pathname);

    if (
      currentContentItemId &&
      params.itemId === currentContentItemId.itemId &&
      params.variantId === currentContentItemId.variantId
    ) {
      dispatch(lockedElementsChanged(params.lockedElementSessions));
    }
  };
