import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { IValidationResult } from '../../../../_shared/utils/validation/ValidationResult.ts';
import { ContentItemEditing_LinkedItemVariantElementsRevalidationFinished } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ItemElementErrorResult } from '../../utils/elementErrorCheckers/types/Errors.ts';

const isErrorResultValid = (errorResult: ItemElementErrorResult | undefined) =>
  !!errorResult && !!errorResult.errorMessages.length;

export type LoadedContentIemValidations = ReadonlyMap<Uuid, ReadonlyMap<Uuid, IValidationResult>>;
const initialState: LoadedContentIemValidations = new Map();

export function loadedContentItemValidations(
  state = initialState,
  action: Action,
): LoadedContentIemValidations {
  switch (action.type) {
    case ContentItemEditing_LinkedItemVariantElementsRevalidationFinished: {
      const { contentItem, itemValidationResult } = action.payload;

      if (!contentItem.variant) {
        return state;
      }

      const { itemId, variantId } = contentItem.variant.id;

      const { errors, friendlyWarnings, warnings } = itemValidationResult;

      const contentItemValidation = state.get(itemId) ?? new Map();
      const contentItemVariantValidation = Collection.add(contentItemValidation, [
        variantId,
        {
          errors: Collection.filter(errors, isErrorResultValid),
          friendlyWarnings,
          warnings,
        },
      ]);

      return Collection.add(state, [itemId, contentItemVariantValidation]);
    }

    default:
      return state;
  }
}
