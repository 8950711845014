import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../../../_shared/components/AppBar/BackNavigation.tsx';
import { getGoBackLinkByPath } from '../../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';

export const ContentItemRevisionBackNavigation: React.FC = () => {
  const location = useLocation();
  const goBackLink = useMemo(() => getGoBackLinkByPath(location.pathname), [location.pathname]);

  return <BackNavigation isDisabled={false} to={goBackLink} />;
};

ContentItemRevisionBackNavigation.displayName = 'ContentItemRevisionBackNavigation';
