import { Action } from '../../../../../@types/Action.type.ts';
import {
  LocalizationEditor_DefaultLangEditor_Hide,
  LocalizationEditor_DefaultLangEditor_Saved,
  LocalizationEditor_DefaultLangEditor_Show,
  LocalizationEditor_Init_Started,
} from '../../constants/localizationActionTypes.ts';

export const isDefaultLanguageEdited = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case LocalizationEditor_DefaultLangEditor_Show:
      return true;

    case LocalizationEditor_Init_Started:
    case LocalizationEditor_DefaultLangEditor_Saved:
    case LocalizationEditor_DefaultLangEditor_Hide:
      return false;

    default:
      return state;
  }
};
