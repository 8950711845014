import { EditorChangeType, EditorState } from 'draft-js';

export type IResetRedoStack = (editorState: EditorState) => EditorState;

export const resetRedoStack: IResetRedoStack = (editorState: EditorState): EditorState => {
  return EditorState.set(editorState, {
    redoStack: editorState.getRedoStack().clear(),
  });
};

export type IApplyUndoRedoStacks = (
  previousEditorState: EditorState,
  currentEditorState: EditorState,
) => EditorState;

export const applyUndoRedoStacks: IApplyUndoRedoStacks = (
  sourceEditorState: EditorState,
  targetEditorState: EditorState,
): EditorState => {
  return EditorState.set(targetEditorState, {
    undoStack: sourceEditorState.getUndoStack(),
    redoStack: sourceEditorState.getRedoStack(),
  });
};

export const disableUndo = (editorState: EditorState): EditorState =>
  EditorState.set(editorState, { allowUndo: false });

export function updateWithUndoFlag(
  editorState: EditorState,
  updater: (withUndoFlag: EditorState) => EditorState,
  allowUndo: boolean,
): EditorState {
  const editorAllowsUndo = editorState.getAllowUndo();
  const withUndoFlag = EditorState.set(editorState, { allowUndo: editorAllowsUndo && allowUndo });

  const updated = updater(withUndoFlag);

  const withOriginalUndoFlag = EditorState.set(updated, { allowUndo: editorAllowsUndo });

  return withOriginalUndoFlag;
}

// Detection of undo boundaries for text writing
// Logic taken from DraftJS source code
// https://github.com/facebook/draft-js/blob/master/src/model/immutable/EditorState.js#L683
export function mustBecomeBoundary(
  lastChangeType: EditorChangeType,
  changeType: EditorChangeType,
): boolean {
  return (
    changeType !== lastChangeType ||
    (changeType !== 'insert-characters' &&
      changeType !== 'backspace-character' &&
      changeType !== 'delete-character')
  );
}
