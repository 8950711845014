import { getScrollParent, isElementVisible } from '@kontent-ai/DOM';
import { px } from '@kontent-ai/component-library/tokens';
import { areShallowEqual } from '@kontent-ai/utils';
import { ContentBlock } from 'draft-js';
import React, { CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import { HandleClickOutside } from '../../../../../_shared/components/HandleClickOutside.tsx';
import { DropDownOptionList } from '../../../../../_shared/uiComponents/DropDown/DropDownOptionList.tsx';
import { DropDownOptionsGroup } from '../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SpaceTakenByActionButtons } from '../../../../itemEditor/features/ContentItemEditing/constants/uiConstants.ts';
import { getBlockIdClassName } from '../../../editorCore/utils/editorComponentUtils.ts';
import { ToolbarOffsetFromContainer } from '../../toolbars/utils/inlineToolbarPositioningUtils.ts';
import { TableActionType } from '../api/EditorTableApi.type.ts';

interface IPosition {
  readonly top: number;
  readonly left: number;
}

const VerticalMenuOffset = 8;
const HorizontalMenuOffset = 8;

const getMenuPosition = (
  element: HTMLElement,
  menu: HTMLElement,
  editor: HTMLElement,
): IPosition => {
  const scrollParent = getScrollParent(element) ?? document.body;

  const containerRect = scrollParent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const menuRect = menu.getBoundingClientRect();
  const editorRect = editor.getBoundingClientRect();

  const maxTop = containerRect.bottom - menuRect.height - ToolbarOffsetFromContainer;
  const maxLeft = editorRect.right - menuRect.width;

  // Make sure the menu fits into the viewport
  const desiredTop = elementRect.top - VerticalMenuOffset;
  const minTop = containerRect.top + SpaceTakenByActionButtons + ToolbarOffsetFromContainer;

  const top = Math.min(Math.max(desiredTop, minTop), maxTop);

  const desiredLeft = elementRect.left + elementRect.width - HorizontalMenuOffset;
  const adjustedLeft = Math.min(desiredLeft, maxLeft);

  // If the menu covers the whole cell width (narrow cell at the right of the window), place the menu to the left
  const coversEntireCellWidth = adjustedLeft < elementRect.left;
  const left = coversEntireCellWidth
    ? Math.max(elementRect.left - menuRect.width, SpaceTakenByActionButtons)
    : adjustedLeft;

  return {
    top,
    left,
  };
};

export interface ITableAction {
  readonly id: TableActionType | 'insert-delete-separator';
  readonly isSeparator?: boolean;
  readonly text: string;
}

interface ITableContextMenuDataProps {
  readonly actions: ReadonlyArray<ITableAction>;
  readonly visible: boolean;
  readonly cellRef: React.RefObject<HTMLElement> | null;
  readonly cellBlock: ContentBlock | null;
  readonly editorRef: React.RefObject<HTMLElement>;
}

interface ITableContextMenuCallbackProps {
  readonly onAction: (action: string, cellBlock: ContentBlock) => void;
  readonly onHide: () => void;
}

type TableContextMenuProps = ITableContextMenuDataProps & ITableContextMenuCallbackProps;

interface ITableContextMenuState {
  readonly position: IPosition | null;
}

export class TableContextMenu extends React.PureComponent<
  TableContextMenuProps,
  ITableContextMenuState
> {
  static displayName = 'TableContextMenu';

  private readonly _menuRef = React.createRef<HTMLUListElement>();

  constructor(props: TableContextMenuProps) {
    super(props);

    this.state = { position: null };
  }

  componentDidMount(): void {
    document.addEventListener('scroll', this._updatePosition, true);
    document.addEventListener('resize', this._updatePosition);
  }

  componentDidUpdate(prevProps: TableContextMenuProps): void {
    if (this.props.visible) {
      if (!areShallowEqual(this.props, prevProps)) {
        this._updatePosition();
      }
    } else if (prevProps.visible) {
      this.setState(() => ({ position: null }));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._updatePosition, true);
    document.removeEventListener('resize', this._updatePosition);
  }

  private readonly _updatePosition = (): void => {
    if (!this.props.visible) {
      return;
    }

    const cellElement = this.props.cellRef?.current;
    const menuElement = this._menuRef?.current;
    const editorElement = this.props.editorRef.current;

    if (
      cellElement &&
      menuElement &&
      editorElement &&
      isElementVisible(cellElement, SpaceTakenByActionButtons, ToolbarOffsetFromContainer)
    ) {
      const position = getMenuPosition(cellElement, menuElement, editorElement);

      this.setState(() => ({ position }));
    } else {
      this.setState(() => ({ position: null }));
    }
  };

  private readonly _handleAction = (action: string) => {
    if (this.props.cellBlock) {
      this.props.onAction(action, this.props.cellBlock);
    }
    this.props.onHide();
  };

  private readonly _renderItems = () => {
    const renderedItems = this.props.actions.map((action) => {
      if (action.isSeparator) {
        return <li key={action.id} className="dropdown-option-separator" role="separator" />;
      }

      return (
        <li
          className="dropdown-option"
          key={action.id}
          onClick={() => this._handleAction(action.id)}
        >
          <span className="dropdown-option__pane">
            <span className="dropdown-option__name">{action.text}</span>
          </span>
        </li>
      );
    });

    return renderedItems;
  };

  private readonly _renderHighlight = () => {
    const blockKey = this.props.cellBlock?.getKey();
    if (!blockKey) {
      return null;
    }
    const inlineStyleString = `.rte__table-cell.${getBlockIdClassName(blockKey)} {
      background-color: var(--element-selection-bg-color);
    }`;

    return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
  };

  private readonly _renderMenu = () => {
    const { onHide } = this.props;
    const { position } = this.state;

    const style: CSSProperties | undefined = position
      ? {
          top: px(position.top),
          left: px(position.left),
        }
      : undefined;

    return createPortal(
      <HandleClickOutside observedRefs={this._menuRef} onClickOutside={onHide}>
        <DropDownOptionList
          className="rte__table-menu"
          ref={this._menuRef}
          longer
          optionListDataUiAttributes={getDataUiCollectionAttribute(
            DataUiCollection.RteTableMenuOptions,
          )}
          style={style}
        >
          <DropDownOptionsGroup>{this._renderItems()}</DropDownOptionsGroup>
        </DropDownOptionList>
      </HandleClickOutside>,
      document.body,
    );
  };

  render() {
    if (!this.props.visible) {
      return null;
    }

    return (
      <>
        {this._renderMenu()}
        {this._renderHighlight()}
      </>
    );
  }
}

export const deleteTableAction: ITableAction = {
  text: 'Delete table',
  id: 'remove-table',
};

export const tableActions: ReadonlyArray<ITableAction> = [
  {
    text: 'Insert row above',
    id: 'insert-row-above',
  },
  {
    text: 'Insert row below',
    id: 'insert-row-below',
  },
  {
    text: 'Insert column left',
    id: 'insert-column-left',
  },
  {
    text: 'Insert column right',
    id: 'insert-column-right',
  },
  {
    isSeparator: true,
    id: 'insert-delete-separator',
    text: '',
  },
  {
    text: 'Delete row',
    id: 'remove-row',
  },
  {
    text: 'Delete column',
    id: 'remove-column',
  },
  deleteTableAction,
];

export const deleteOnlyTableActions: ReadonlyArray<ITableAction> = [deleteTableAction];
