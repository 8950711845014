import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import Immutable from 'immutable';
import React from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';

export interface ICapabilityOptionDataProps {
  readonly checked: boolean;
  readonly children?: string;
  readonly disabled: boolean;
  readonly option: Immutable.Set<Capability>;
  readonly optionName: string;
}

export interface ICapabilityOptionCallbackProps {
  readonly onChecked: () => void;
  readonly onUnchecked: () => void;
}

type IProps = ICapabilityOptionDataProps & ICapabilityOptionCallbackProps;

const getOptionId = (option: Immutable.Set<Capability>) => option.join(',');

export const CapabilityOption: React.FC<IProps> = (props: IProps) => (
  <Checkbox
    caption={props.children}
    checked={props.checked}
    checkboxState={props.disabled ? 'disabled' : 'default'}
    name={getOptionId(props.option)}
    onToggle={(isChecked) => (isChecked ? props.onChecked() : props.onUnchecked())}
    {...getDataUiInputAttribute(DataUiInput.CapabilityOption)}
    {...getDataUiObjectNameAttribute(getOptionId(props.option))}
  >
    {props.optionName}
  </Checkbox>
);

CapabilityOption.displayName = 'CapabilityOption';
