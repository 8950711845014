import React from 'react';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { changeDueDate } from '../../../../actions/thunkContentItemEditingActions.ts';
import { SectionLink } from '../../../../components/details/SectionLink.tsx';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';

export const NonClickableDueDateSection: React.FC = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeDueDate(
        ContentItemEditingEventOrigins.ItemDetails,
        ModalDialogType.ContentItemDueDateDialog,
      ),
    );

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.DueDate}>
      <SectionLink
        dataUiAttribute={DataUiWorkflowAction.EditDueDate}
        onEditClick={onClick}
        text="Set due date"
      />
    </NonClickableSection>
  );
};

NonClickableDueDateSection.displayName = 'NonClickableDueDateSection';
