import { ItemEditorOperationId } from '../../../../applications/contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  ContentItemEditing_ItemAiAction_Finished,
  ContentItemEditing_ItemAiAction_Started,
} from '../constants/aiActionTypes.ts';

export const contentItemAiActionStarted = (operationId: ItemEditorOperationId) =>
  ({
    type: ContentItemEditing_ItemAiAction_Started,
    payload: { operationId },
  }) as const;

export const contentItemAiActionFinished = (operationId: ItemEditorOperationId) =>
  ({
    type: ContentItemEditing_ItemAiAction_Finished,
    payload: { operationId },
  }) as const;
