import { ProjectContainerIdStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

let _projectContainerId: Uuid | null = null;

export interface IProjectContainerIdStorage {
  readonly save: (projectContainerId: Uuid) => void;
  readonly forget: () => void;
  readonly load: () => Uuid | null;
}

export const projectContainerIdStorage: IProjectContainerIdStorage = {
  save(projectContainerId: Uuid) {
    localStorage.set(ProjectContainerIdStorageKey, projectContainerId);
    _projectContainerId = projectContainerId;
  },

  forget(): void {
    localStorage.remove(ProjectContainerIdStorageKey);
    _projectContainerId = null;
  },

  load(): Uuid | null {
    if (_projectContainerId === null) {
      _projectContainerId = localStorage.get(ProjectContainerIdStorageKey);
    }

    return _projectContainerId;
  },
};
