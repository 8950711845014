import { RegexException } from '@kontent-ai/errors';

function validateMaxLimit(maxLimit: number, currentCount: number): boolean {
  return currentCount <= maxLimit;
}

export function validateMaxChars(maxChars: number | null, charsCount: number): boolean {
  return maxChars !== null ? validateMaxLimit(maxChars, charsCount) : true;
}

export function validateMaxWords(maxWords: number | null, wordsCount: number): boolean {
  return maxWords !== null ? validateMaxLimit(maxWords, wordsCount) : true;
}

export function validateWithRegex(
  regex: string | undefined,
  flags: string | null | undefined,
  itemText: string,
): boolean {
  if (!regex) {
    return true;
  }
  try {
    const pattern = flags === null ? new RegExp(regex) : new RegExp(regex, flags);
    return pattern.test(itemText);
  } catch {
    throw RegexException(regex, flags);
  }
}
