import classnames from 'classnames';
import React from 'react';
import { Loader } from '../../../../../../../_shared/components/Loader.tsx';
import { ICompiledContentType } from '../../../../../../contentInventory/content/models/CompiledContentType.ts';
import { NoElementsElement } from '../../../../../../itemEditor/features/ContentItemEditing/components/NoElementsEmptyState.tsx';
import { ContentGroupTabsInComponent } from '../../../../../../itemEditor/features/ContentItemEditing/containers/ContentGroupTabsInComponent.tsx';
import { ContentNestingContextProvider } from '../../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { IContentComponent } from '../../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { getTypeElementsInContentGroup } from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { ExpandedContentComponentElement } from './ExpandedContentComponentElement.tsx';

type ExpandedContentComponentElementsProps = {
  readonly contentComponent: IContentComponent;
  readonly contentType: ICompiledContentType | null;
  readonly isHighlighted: boolean;
  readonly originalContentComponent?: IContentComponent;
  readonly selectedContentGroupId: Uuid | null;
};

export const ExpandedContentComponentElements: React.FC<ExpandedContentComponentElementsProps> = ({
  contentComponent,
  contentType,
  isHighlighted,
  originalContentComponent,
  selectedContentGroupId,
}) => {
  if (!contentType) {
    return <Loader />;
  }

  const contentTypeElements = contentType.contentElements;

  const elementsInGroup = getTypeElementsInContentGroup(
    contentTypeElements,
    selectedContentGroupId,
  );

  return (
    <div
      className={classnames('content-component__content', {
        'content-component__content--is-highlighted': isHighlighted,
      })}
    >
      {!contentType.contentGroups.isEmpty() && (
        <ContentGroupTabsInComponent
          canEditContent={false}
          contentComponentId={contentComponent.id}
          contentType={contentType}
          hideValidationStatus
        />
      )}
      <div className="expanded-content-component">
        {elementsInGroup.length === 0 ? (
          <NoElementsElement isDisabled />
        ) : (
          <ContentNestingContextProvider isCurrentLevelContentComponent>
            <ContentNestingContextProvider isCurrentLevelContentComponent>
              {elementsInGroup.map((currentTypeElement) => (
                <ExpandedContentComponentElement
                  key={currentTypeElement.elementId}
                  contentComponent={contentComponent}
                  currentTypeElement={currentTypeElement}
                  originalContentComponent={originalContentComponent}
                  typeElements={contentTypeElements}
                />
              ))}
            </ContentNestingContextProvider>
          </ContentNestingContextProvider>
        )}
      </div>
    </div>
  );
};

ExpandedContentComponentElements.displayName = 'ExpandedContentComponentElements';
