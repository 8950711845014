import {
  Collection,
  alphabetically,
  makeCancellablePromise,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import stringify from 'fast-safe-stringify';
import { useEffect, useState } from 'react';
import { digestToString } from '../../../../../../_shared/utils/crypto/digest.ts';
import {
  ISavedFilter,
  getSavedFilterFromListingFilter,
} from '../../../../../../data/models/filters/ISavedFilter.ts';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';

async function hashSavedFilter({ id, name, ...propsToHash }: ISavedFilter): Promise<string> {
  const json = stringify.stableStringify(propsToHash, (_key, value) =>
    Array.isArray(value) ? value.sort(alphabetically) : value,
  );
  return await digestToString('SHA-256', json);
}

async function hashSavedFilters(
  filters: ReadonlyMap<Uuid, ISavedFilter>,
): Promise<ReadonlyMap<string, Uuid>> {
  const promises = Collection.getEntries(filters).map(async ([filterId, filter]) => {
    const hash = await hashSavedFilter(filter);
    return [hash, filterId] as const;
  });
  const resultEntries = await Promise.all(promises);
  return new Map(resultEntries);
}

type UseSelectedFilterId = (
  currentFilterState: IListingFilter,
  savedFilters: ReadonlyMap<Uuid, ISavedFilter>,
) => Uuid | null;

export const useSelectedFilterId: UseSelectedFilterId = (currentFilterState, savedFilters) => {
  const [selectedFilterId, setSelectedFilterId] = useState<Uuid | null>(null);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(() =>
      Promise.all([
        hashSavedFilter(getSavedFilterFromListingFilter(currentFilterState)),
        hashSavedFilters(savedFilters),
      ]),
    )
      .then(([selectedFilterHash, filterIdByHash]) =>
        setSelectedFilterId(filterIdByHash.get(selectedFilterHash) ?? null),
      )
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [currentFilterState, savedFilters]);

  return selectedFilterId;
};
