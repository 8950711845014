import { DateRange } from '../../../../_shared/models/DateRange.type.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  IListingFilter,
  areListingFilterAndSearchEmpty,
} from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import {
  Calendar_ArrangementSelected,
  Calendar_DrillDownDateSelected,
  Calendar_Initialization_Finished,
  Calendar_Initialization_Started,
  Calendar_ItemDetailsSelected,
  Calendar_ItemDetails_NavigateBack,
  Calendar_LoadItemsWithVariantActiveInPeriod_Finished,
  Calendar_LoadItemsWithVariantActiveInPeriod_Started,
  Calendar_Page_Left,
  Calendar_PopupClosed,
} from '../constants/calendarActionTypes.ts';
import { CalendarArrangement } from '../models/CalendarArrangement.ts';

export const calendarArrangementSelected = (arrangement: CalendarArrangement) =>
  ({
    type: Calendar_ArrangementSelected,
    payload: { arrangement },
  }) as const;

export const calendarInitializationFinished = (filter: IListingFilter) =>
  ({
    type: Calendar_Initialization_Finished,
    payload: { filter },
  }) as const;

export const calendarDrillDownDateSelected = (drillDownDate: DateTimeStamp) =>
  ({
    type: Calendar_DrillDownDateSelected,
    payload: { drillDownDate },
  }) as const;

export const calendarItemDetailsSelected = (itemDetailId: Uuid, date: DateTimeStamp) =>
  ({
    type: Calendar_ItemDetailsSelected,
    payload: {
      id: itemDetailId,
      date,
    },
  }) as const;

export const loadCalendarItemsWithVariantActiveInPeriodStarted = (
  period: DateRange,
  cancellationToken: Uuid,
) =>
  ({
    type: Calendar_LoadItemsWithVariantActiveInPeriod_Started,
    payload: {
      period,
      cancellationToken,
    },
  }) as const;

export const createTokenizedLoadItemsWithVariantActiveInPeriodStarted =
  (cancellationToken: Uuid) => (period: DateRange) =>
    loadCalendarItemsWithVariantActiveInPeriodStarted(period, cancellationToken);

export const loadCalendarItemsWithVariantActiveInPeriodFinished = (
  contentItems: ReadonlyArray<IListingContentItem>,
  period: DateRange,
  filter: IListingFilter,
  wasItemsLimitExceeded: boolean,
) =>
  ({
    type: Calendar_LoadItemsWithVariantActiveInPeriod_Finished,
    payload: {
      contentItems,
      period,
      isFilterSet: !areListingFilterAndSearchEmpty(filter),
      wasDataCountLimitExceeded: wasItemsLimitExceeded,
    },
  }) as const;

export const calendarPopupClosed = () =>
  ({
    type: Calendar_PopupClosed,
  }) as const;

export const navigateBackFromCalendarItemDetails = () =>
  ({
    type: Calendar_ItemDetails_NavigateBack,
  }) as const;

export const calendarPageLeft = () =>
  ({
    type: Calendar_Page_Left,
  }) as const;

export const calendarInitializationStarted = () =>
  ({
    type: Calendar_Initialization_Started,
  }) as const;
