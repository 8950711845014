import { InputState } from '@kontent-ai/component-library/Input';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { VerticalMenuItem } from '@kontent-ai/component-library/VerticalMenu';
import { createCompare, createGuid, naturally } from '@kontent-ai/utils';
import { forwardRef, useMemo, useState } from 'react';
import { ModalDialog } from '../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { modalDismissed } from '../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { previewConfigurationFormChanged } from '../actions/previewConfigurationActions.ts';
import { anySpacesOptionId } from '../utils/getSpacesOptions.ts';

export const ConfigureContentTypePreviewUrlsDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>(({ isOpen }, ref) => {
  const [selectedContentTypeId, setSelectedContentTypeId] = useState<Uuid | null>(null);
  const editedPreviewConfiguration = useSelector(
    (state) => state.previewConfigurationApp.editedPreviewConfiguration,
  );

  const disabledContentTypeIds = Array.from(
    editedPreviewConfiguration?.previewUrlPatterns.entries() ?? [],
  )
    .filter(([, patterns]) => patterns.some((p) => p.enabled))
    .map(([key]) => key);

  const dispatch = useDispatch();
  const onClose = () => dispatch(modalDismissed());

  const onSubmit = () => {
    if (selectedContentTypeId && editedPreviewConfiguration) {
      const previewUrlPatterns = editedPreviewConfiguration.previewUrlPatterns;
      // Try to get legacy disabled pattern to use as default value
      const disabledPattern = previewUrlPatterns
        .get(selectedContentTypeId)
        ?.find((p) => !p.enabled);

      const newPatterns = new Map(previewUrlPatterns).set(selectedContentTypeId, [
        {
          rowId: createGuid(),
          urlPattern: disabledPattern?.urlPattern ?? '',
          enabled: true,
          spaces: new Set([anySpacesOptionId]),
        },
      ]);

      dispatch(
        previewConfigurationFormChanged({
          ...editedPreviewConfiguration,
          previewUrlPatterns: newPatterns,
        }),
      );

      onClose();
    }
  };

  const contentTypes = useSelector((state) => state.data.contentTypes.byId);
  const contentTypeItems = useMemo(
    () =>
      contentTypes
        .toArray()
        .map(
          (contentType): ContentTypeSingleSelectItem => ({
            id: contentType.id,
            label: contentType.name,
          }),
        )
        .sort(
          createCompare({
            select: (item) => item.label,
            compare: naturally,
          }),
        ),
    [contentTypes],
  );

  return (
    <ModalDialog
      headline="Select a content type"
      isDismissable
      isOpen={isOpen}
      primaryAction={{
        disabled: !selectedContentTypeId,
        onClick: onSubmit,
        text: 'Select',
        tooltipText: '',
        ...getDataUiActionAttribute(DataUiAction.Confirm),
      }}
      onClose={onClose}
      ref={ref}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <SingleSelect
        disabledItemIds={disabledContentTypeIds}
        inputState={InputState.Default}
        items={contentTypeItems}
        placeholder="Select a content type"
        onSelectionChange={(contentTypeId) => setSelectedContentTypeId(contentTypeId)}
        selectedItemId={selectedContentTypeId}
      />
    </ModalDialog>
  );
});

ConfigureContentTypePreviewUrlsDialog.displayName = 'ConfigureContentTypePreviewUrlsDialog';

type ContentTypeSingleSelectItem = VerticalMenuItem<ContentTypeSingleSelectItem>;
