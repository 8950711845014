import { memoize } from '@kontent-ai/memoization';
import { ContentBlock, ContentState, EditorState } from 'draft-js';
import { isContentComponent } from '../blocks/blockTypeUtils.ts';

const isEditorState = (obj: any): obj is EditorState => {
  return obj?.getCurrentContent && obj.getSelection;
};

const memoizeSanitization = <F extends AnyFunction>(func: F) => memoize.maxN(func, 1_000);

const sanitize = memoizeSanitization(<T>(obj: T): T => {
  if (!obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return sanitizeArray(obj) as unknown as T;
  }

  if (isObject(obj)) {
    return sanitizeObject(obj);
  }

  return obj;
});

const sanitizeBlock = memoizeSanitization((block: ContentBlock): ContentBlock => {
  if (isContentComponent(block)) {
    return block.set('data', sanitize(block.getData())) as ContentBlock;
  }

  return block;
});

const sanitizeContentState = memoizeSanitization((content: ContentState): ContentState => {
  let blockMap = content.getBlockMap();
  let changed = false;

  const sanitizedBlocks: Record<string, ContentBlock> = {};

  blockMap.forEach((block: ContentBlock) => {
    const sanitizedBlock = sanitizeBlock(block);
    if (sanitizedBlock !== block) {
      sanitizedBlocks[block.getKey()] = sanitizedBlock;
      changed = true;
    }
  });

  if (!changed) {
    return content;
  }

  for (const key in sanitizedBlocks) {
    if (Object.hasOwn(sanitizedBlocks, key)) {
      const block = sanitizedBlocks[key];
      if (block) {
        blockMap = blockMap.set(key, block);
      }
    }
  }

  return content.set('blockMap', blockMap) as ContentState;
});

const sanitizeEditorState = memoizeSanitization((editorState: EditorState): EditorState => {
  // Editor state should not include undo / redo stack because it consumes too much memory
  // Do not crawl inside to prevent consuming too much CPU as the structure is heavy
  return EditorState.set(editorState, {
    currentContent: sanitizeContentState(editorState.getCurrentContent()),
    undoStack: undefined,
    redoStack: undefined,
  });
});

const sanitizeObject = memoizeSanitization(<T extends AnyObject>(obj: T): T => {
  const sanitized: any = {};
  let changed = false;

  if (isEditorState(obj)) {
    return sanitizeEditorState(obj) as any as T;
  }

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key];
      const sanitizedObj = sanitize(value);
      if (sanitizedObj !== value) {
        sanitized[key] = sanitizedObj;
        changed = true;
      }
    }
  }

  if (!changed) {
    return obj;
  }

  return { ...obj, ...sanitized };
});

const sanitizeArray = memoizeSanitization(<T>(arr: T[]): T[] => {
  const sanitized: Record<number, any> = {};
  let changed = false;

  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];
    const sanitizedObj = sanitize(value);
    if (sanitizedObj !== value) {
      sanitized[i] = sanitizedObj;
      changed = true;
    }
  }

  if (!changed) {
    return arr;
  }

  const result = [...arr];
  for (const key in sanitized) {
    if (Object.hasOwn(sanitized, key)) {
      const value = sanitized[key];
      result[key] = value;
    }
  }

  return result;
});

const isObject = (obj: any): obj is AnyObject => typeof obj === 'object';

export const sanitizeReduxActionForDevTools = sanitize;
export const sanitizeReduxStateForDevTools = sanitize;
