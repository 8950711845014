export const documentationLinks = {
  advancedAssetManagement: 'https://kontent.ai/learn/docs/assets/advanced-asset-management',
  aiImageAnalysis: 'https://kontent.ai/learn/docs/assets/ai-accelerated-image-operations',
  aiImageAnalysisRequirements:
    'https://kontent.ai/learn/docs/assets/ai-accelerated-image-operations#a-considerations',
  aiImageAnalysisGenerateDescriptions:
    'https://kontent.ai/learn/docs/assets/ai-accelerated-image-operations#a-generate-localized-image-descriptions',
  aiPoweredTranslation: 'https://kontent.ai/learn/docs/languages/translate-with-ai',
  aiSuggestedLinkedItems:
    'https://kontent.ai/learn/docs/content-items/linking#a-link-content-together',
  aiSuggestedTaxonomyTerms:
    'https://kontent.ai/learn/docs/content-items/tagging#a-let-ai-suggest-the-right-taxonomy-terms',
  apiContentManagement: 'https://kontent.ai/learn/docs/apis/openapi/management-api-v2',
  apiDelivery: 'https://kontent.ai/learn/docs/apis/openapi/delivery-api',
  apiSubscription: 'https://kontent.ai/learn/docs/apis/openapi/subscription-api',
  apiSync: 'https://kontent.ai/learn/docs/apis/openapi/sync-api/',
  assignContributors: 'https://kontent.ai//learn/docs/task-management#a-assign-contributors',
  buildAppJs: 'https://kontent.ai/learn/develop/build-apps/build-your-first-app/javascript',
  buildAppNet: 'https://kontent.ai/learn/develop/build-apps/build-your-first-app/net',
  buildAppRuby: 'https://kontent.ai/learn/develop/build-apps/build-your-first-app/ruby',
  buildingNavigation:
    'https://kontent.ai/learn/develop/decide-navigation-and-urls/choose-navigation-approach',
  changeProcessingTime:
    'https://kontent.ai/learn/docs/apis/openapi/delivery-api/#section/Change-processing-time',
  cloneProjectWhatGetsCopied: 'https://kontent.ai/learn/docs/projects#a-what-gets-copied',
  codenameLimitations:
    'https://kontent.ai/learn/docs/content-model/content-types#a-rules-for-codenames',
  collections: 'https://kontent.ai/learn/set-up/your-first-project/content-organization',
  collectionsAndSpacesConnection:
    'https://kontent.ai/learn/docs/spaces#a-connect-spaces-to-collections',
  collectionsPermissions:
    'https://kontent.ai/learn/docs/collections#a-assign-user-role-permissions-for-collections',
  components: 'https://kontent.ai/learn/create/add-structure-to-your-content',
  configuringPreview: 'https://kontent.ai/learn/docs/preview/preview-configuration',
  contentItems: 'https://kontent.ai/learn/create/content-creation-in-30-minutes',
  contentLocalization: 'https://kontent.ai/learn/plan/plan-localization',
  contentStatus: 'https://kontent.ai/learn/docs/content-status',
  customElements: 'https://kontent.ai/learn/docs/custom-elements',
  customHeaders: 'https://kontent.ai/learn/docs/webhooks/webhooks/javascript#a-custom-headers',
  dueDates: 'https://kontent.ai/learn/docs/task-management#a-set-a-due-date',
  environments: 'https://kontent.ai/learn/docs/environments',
  environmentsSwap:
    'https://kontent.ai/learn/docs/environments#a-environment-swap-mark-environment-as-production',
  getStarted: 'https://kontent.ai/learn/plan/get-started',
  keyboardShortcuts: 'https://kontent.ai/learn/docs/keyboard-shortcuts',
  landingPage: 'https://kontent.ai/learn/',
  languageFallbacks: 'https://kontent.ai/learn/docs/languages/languages#a-language-fallbacks',
  manageWorkflows: 'https://kontent.ai/learn/docs/workflows-publishing/workflows',
  mapiDeprecation: 'https://kontent.ai/learn/mapi-v2-deprecated-key',
  mapiExpiration: 'https://kontent.ai/learn/mapi-v2-key-expiration',
  migrateFromLegacyWebhooks: 'https://kontent.ai/learn/docs/webhooks/migrate-from-legacy-webhooks',
  missionControl: 'http://kontent.ai/learn/docs/mission-control',
  newWebhooks: 'https://kontent.ai/learn/docs/webhooks/webhooks/',
  nonLocalizableElements:
    'https://kontent.ai/learn/docs/content-model/content-types#a-non-localizable-elements',
  permissions: 'https://kontent.ai/learn/docs/users-roles/permissions',
  regexLimitations:
    'https://kontent.ai/learn/docs/content-model/element-validation#a-which-elements-can-have-limitations-',
  reservedHeaders: 'https://kontent.ai/learn/docs/webhooks/webhooks/javascript#a-reserved-headers',
  sapiKeyPermissions: 'https://kontent.ai/learn/sapi-key-permissions',
  secureAccess: 'https://kontent.ai/learn/docs/security/secure-access',
  selectDataCenter: 'https://kontent.ai/learn/docs/architecture/data-centers',
  setUpWebSpotlight: 'https://kontent.ai/learn/docs/web-spotlight',
  spaces: 'https://kontent.ai/learn/set-up/your-first-project/what-about-channels',
  subscriptionPlans: 'https://kontent.ai/pricing/',
  validateReceivedNotifications:
    'https://kontent.ai/learn/docs/webhooks/webhooks/#a-validate-received-notifications',
  tagAssets: 'https://kontent.ai/learn/docs/assets/assets-in-content-items#a-tag-assets',
  webSpotlight: 'https://kontent.ai/learn/set-up/website-management',
  webhooks: 'https://kontent.ai/learn/docs/webhooks',
  webhookTriggersAndEvents:
    'https://kontent.ai/learn/docs/webhooks/webhooks/#a-webhook-triggers-and-events',
} as const satisfies Dictionary<string>;
