import { Collection } from '@kontent-ai/utils';
import { ITriggers } from '../models/IWebhookFormShape.type.ts';

export const areAllTriggersEmpty = (triggers: ITriggers): boolean =>
  [
    triggers.deliveryApiItemContentTriggerIds,
    triggers.deliveryApiTaxonomyContentTriggerIds,
    triggers.previewDeliveryApiItemContentTriggerIds,
    triggers.previewDeliveryApiTaxonomyContentTriggerIds,
    triggers.itemWorkflowStepIds,
    triggers.managementApiItemContentTriggerIds,
  ].every(Collection.isEmpty);
