import { useAttachRef } from '@kontent-ai/hooks';
import { useCheckbox } from '@react-aria/checkbox';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { useToggleState } from '@react-stately/toggle';
import React, { FocusEventHandler, ReactNode, useId, useRef } from 'react';
import { useOurFocusRing } from '../../hooks/useOurFocusRing.ts';
import { Box } from '../../layout/Box/Box.tsx';
import { Inline } from '../../layout/Inline/Inline.tsx';
import { Column } from '../../layout/Row/Column.tsx';
import { Row } from '../../layout/Row/Row.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Tooltip } from '../Tooltip/Tooltip.tsx';
import { TooltipPropsExtension } from '../_utils/propPrefabs.ts';
import { CheckboxCaption } from './components/CheckboxCaption.tsx';
import { CheckboxSymbol } from './components/CheckboxSymbol.tsx';
import { StyledCheckboxInput } from './components/StyledCheckboxInput.tsx';
import { StyledCheckboxLabel, labelOffset } from './components/StyledCheckboxLabel.tsx';
import { StyledCheckboxWrapper } from './components/StyledCheckboxWrapper.tsx';
import { CheckboxState, KeyboardModifiers } from './types.ts';
import { getCheckboxStatus } from './utils/getCheckboxStatus.ts';
import { CheckboxStylingProps, checkboxPadding } from './utils/stylingUtils.ts';

export type CheckboxProps = Pick<TooltipPropsExtension, 'tooltipPlacement' | 'tooltipText'> & {
  readonly ariaLabel?: string;
  readonly ariaLabelledBy?: string;
  readonly autoFocus?: boolean;
  readonly caption?: ReactNode | null;
  readonly checkboxState: CheckboxState;
  readonly checked?: boolean;
  readonly children?: ReactNode;
  readonly className?: string;
  readonly id?: string;
  readonly indeterminate?: boolean;
  readonly name?: string;
  readonly onBlur?: FocusEventHandler<HTMLInputElement>;
  readonly onFocus?: FocusEventHandler<HTMLInputElement>;
  readonly onToggle: (value: boolean, keyboardModifiers: KeyboardModifiers) => void;
  readonly tabIndex?: number;
};

export const Checkbox = React.forwardRef<HTMLInputElement, React.PropsWithChildren<CheckboxProps>>(
  (
    {
      ariaLabel,
      ariaLabelledBy,
      autoFocus,
      caption,
      checked = false,
      checkboxState,
      children,
      id,
      indeterminate = false,
      onBlur,
      onFocus,
      onToggle,
      tooltipText = '',
      tooltipPlacement = 'top',
      className,
      tabIndex,
      name,
      ...restProps
    },
    forwardedRef,
  ) => {
    const labelRef = useRef<HTMLLabelElement>(null);
    const checkboxStatus = getCheckboxStatus(checked, indeterminate);
    const pressEventKeyboardModifiersRef = useRef<KeyboardModifiers>({
      shiftKey: false,
      ctrlKey: false,
      metaKey: false,
      altKey: false,
    });

    const { isFocusVisible, focusProps } = useOurFocusRing();

    const { isHovered, hoverProps } = useHover({});

    const { refToForward, refObject } = useAttachRef(forwardedRef);

    const captionId = useId();

    const toggleState = useToggleState({
      isDisabled: checkboxState === 'disabled',
      isSelected: checked,
      onChange: (isSelected) => onToggle(isSelected, pressEventKeyboardModifiersRef.current),
    });

    const { inputProps } = useCheckbox(
      {
        'aria-describedby': caption ? captionId : undefined,
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledBy,
        autoFocus,
        id,
        isIndeterminate: indeterminate,
        isDisabled: checkboxState === 'disabled',
        onFocus,
        onBlur,
        children,
        isSelected: checked,
      },
      toggleState,
      refObject,
    );

    // Save keyboard modifiers to pass to `onToggle` callback
    const { pressProps: inputPressProps } = usePress({
      ref: refObject,
      isDisabled: checkboxState === 'disabled',
      allowTextSelectionOnPress: false,
      onPress: ({ shiftKey, ctrlKey, metaKey, altKey }) => {
        pressEventKeyboardModifiersRef.current = {
          shiftKey,
          ctrlKey,
          metaKey,
          altKey,
        };
      },
    });

    const checkboxStylingProps: CheckboxStylingProps = {
      checkboxState,
      checkboxStatus,
    };

    return (
      <Stack align="start" {...getDataUiComponentAttribute(Checkbox)} {...restProps}>
        <Inline>
          <Tooltip
            placement={tooltipPlacement}
            tooltipText={tooltipText}
            visible={isFocusVisible || isHovered}
          >
            <StyledCheckboxWrapper
              ref={labelRef}
              isFocusVisible={isFocusVisible}
              className={className}
              {...checkboxStylingProps}
              {...mergeProps(hoverProps)}
            >
              <StyledCheckboxInput
                tabIndex={tabIndex}
                ref={refToForward}
                name={name}
                {...checkboxStylingProps}
                {...mergeProps(inputPressProps, inputProps, focusProps)}
              />
              <Row component="span" alignY="start" noWrap>
                <Column component="span" width="content">
                  <CheckboxSymbol {...checkboxStylingProps} />
                </Column>
                {!!children && (
                  <Column width="fit-content">
                    <Box
                      paddingRight={checkboxPadding}
                      css={`
                        padding-top: ${labelOffset};
                        padding-bottom: ${labelOffset};
                      `}
                    >
                      <StyledCheckboxLabel {...checkboxStylingProps}>
                        {children}
                      </StyledCheckboxLabel>
                    </Box>
                  </Column>
                )}
              </Row>
            </StyledCheckboxWrapper>
          </Tooltip>
        </Inline>
        {!!caption && <CheckboxCaption captionId={captionId} caption={caption} />}
      </Stack>
    );
  },
);

Checkbox.displayName = 'Checkbox';
