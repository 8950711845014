import { getUrlRelativeOrWithProtocol } from '../../../../../_shared/utils/urlUtils.ts';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import {
  getMetadataAtSelection,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { TopLevelBlockCategoryFeature } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { LinksPlugin } from '../LinksPlugin.type.ts';
import { EmailLinkData, PhoneLinkData, WebLinkData } from './LinkData.type.ts';
import { NewLinkType } from './NewLinkType.ts';
import { applyAutomaticLinkConversion as applyAutomaticLinkConversionInContent } from './automation/linkConversionUtils.ts';
import {
  createLinkPlaceholder as createLinkPlaceholderInContent,
  createLinkWithText,
  createValueLinkWithText,
  isSingleLinkPlaceholderAtSelection,
  unlink as unlinkInContent,
} from './editorLinkUtils.ts';

export const editorLinkApi: EditorApiImplementation<LinksPlugin> = {
  applyAutomaticLinkConversion(api, editorState, lastTypedChars, selection) {
    // Explicit selection must be collapsed
    if (selection && !selection.isCollapsed()) {
      return editorState;
    }

    const withConvertedLink = api.executeContentChange(
      editorState,
      selection ?? editorState.getSelection(),
      (input) => {
        const linkConverted = applyAutomaticLinkConversionInContent(
          input,
          lastTypedChars?.[lastTypedChars.length - 1] ?? null,
        );
        if (!selection || linkConverted === input) {
          return linkConverted;
        }

        // When converting at explicit selection, restore selection to the original
        return {
          content: setContentSelection(
            linkConverted.content,
            linkConverted.content.getSelectionBefore(),
            editorState.getSelection(),
          ),
          selection: editorState.getSelection(),
          wasModified: true,
        };
      },
      'apply-entity',
    );

    return withConvertedLink;
  },

  createLinkPlaceholder(api, editorState, selection, linkType) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => createLinkPlaceholderInContent(input, linkType),
      'apply-entity',
    );
  },

  createNewContentLink(api, editorState, selection, text = '') {
    return api.executeContentChange(
      editorState,
      selection,
      (input) =>
        createLinkWithText(
          input,
          text,
          { type: NewLinkType.ContentLink },
          !selection.isCollapsed(),
        ),
      'apply-entity',
    );
  },

  createContentLink(api, editorState, selection, contentItemId, text = '') {
    return api.executeContentChange(
      editorState,
      selection,
      (input) =>
        createLinkWithText(input, text, { itemId: contentItemId }, !selection.isCollapsed()),
      'apply-entity',
    );
  },

  createNewAssetLink(api, editorState, selection, text = '') {
    return api.executeContentChange(
      editorState,
      selection,
      (input) =>
        createLinkWithText(input, text, { type: NewLinkType.AssetLink }, !selection.isCollapsed()),
      'apply-entity',
    );
  },

  createAssetLink(api, editorState, selection, assetId, text = '') {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => createLinkWithText(input, text, { assetId }, !selection.isCollapsed()),
      'apply-entity',
    );
  },

  createValueLink(api, editorState, selection, text, linkData, allowUndo = true) {
    const content = editorState.getCurrentContent();
    const linkIsCreatedAtPlaceholder = isSingleLinkPlaceholderAtSelection(content, selection);
    // If there is a placeholder, we don't want to record undo operation as it was already recorded by the operation that created the placeholder
    // If we allowed it here, it would be possible to undo to a link creation state, which would make the UI inconsistent due to locking
    const finalAllowUndo = allowUndo && !linkIsCreatedAtPlaceholder;
    const keepSelected = !selection.isCollapsed() && !linkIsCreatedAtPlaceholder;

    return api.executeContentChange(
      editorState,
      selection,
      (input) =>
        createValueLinkWithText(input, text, linkData, keepSelected, linkIsCreatedAtPlaceholder),
      'apply-entity',
      finalAllowUndo,
    );
  },

  createNewEmailLink(api, editorState, selection, allowUndo = true) {
    return api.createValueLink(
      editorState,
      selection,
      '',
      { type: NewLinkType.EmailLink },
      allowUndo,
    );
  },

  createEmailLink(api, editorState, selection, properties, allowUndo = true) {
    const data: EmailLinkData = {
      emailAddress: properties.emailAddress,
      emailSubject: properties.emailSubject,
    };

    return api.createValueLink(editorState, selection, properties.linkText, data, allowUndo);
  },

  createNewPhoneLink(api, editorState, selection, allowUndo = true) {
    return api.createValueLink(
      editorState,
      selection,
      '',
      { type: NewLinkType.PhoneLink },
      allowUndo,
    );
  },

  createPhoneLink(api, editorState, selection, properties, allowUndo = true) {
    const data: PhoneLinkData = { phoneNumber: properties.phoneNumber };

    return api.createValueLink(editorState, selection, properties.linkText, data, allowUndo);
  },

  createNewWebLink(api, editorState, selection, allowUndo = true) {
    return api.createValueLink(
      editorState,
      selection,
      '',
      { type: NewLinkType.WebLink },
      allowUndo,
    );
  },

  createWebLink(api, editorState, selection, properties, allowUndo = true) {
    const url = (properties.linkUrl && getUrlRelativeOrWithProtocol(properties.linkUrl)) ?? '';

    const text = (properties.linkText || url) ?? '';
    const data: WebLinkData = {
      url,
      title: properties.linkTitle,
      openInNewWindow: properties.openInNewWindow,
    };

    return api.createValueLink(editorState, selection, text, data, allowUndo);
  },

  unlink(api, editorState, selection) {
    const { allowedBlocks } = api.getLimitations();
    const isTextAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
    const isTableAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
    const metadata = getMetadataAtSelection(editorState.getCurrentContent(), selection);

    if (!metadata) {
      return editorState;
    }

    return api.executeContentChange(
      editorState,
      selection,
      (input) =>
        unlinkInContent(input, (entityKey) => {
          return (
            (isTextAllowed && !!metadata.entityKeyAtAnyTopLevelChars?.contains(entityKey)) ||
            (isTableAllowed && !!metadata.entityKeyAtAnyTableChars?.contains(entityKey))
          );
        }),
      'apply-entity',
    );
  },
};
