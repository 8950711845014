import React, { useCallback } from 'react';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { TrackUserEventWithData } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiRteAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { ModalAssetSelector } from '../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalAssetSelector.tsx';
import { CannotViewAssetsMessage } from '../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import {
  Apply,
  EditorPlugin,
  PluginProps,
  PluginState,
  Render,
} from '../../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { getLinkStatus } from '../../apiLimitations/api/editorLimitationUtils.ts';
import { EditAssetPlugin } from '../../assets/EditAssetPlugin.tsx';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { GetLinkEntityComponent, GetLinkOptions, LinksPlugin } from '../LinksPlugin.type.ts';
import { isAssetLink, isNewLinkOfType } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { NewLinkType } from '../api/NewLinkType.ts';
import { isNewLinkAllowedAtSelection } from '../api/editorLinkUtils.ts';
import { ContentLinksPlugin } from '../content/ContentLinksPlugin.tsx';
import { AssetLink } from './containers/AssetLink.tsx';

type AssetLinksPluginProps = {
  readonly editedEntityName: string;
  readonly trackUserEventWithData: TrackUserEventWithData;
};

export type AssetLinksPlugin = EditorPlugin<
  None,
  AssetLinksPluginProps,
  None,
  None,
  [LinksPlugin, EditAssetPlugin]
>;

type CustomLinkProps = Pick<
  PluginState<AssetLinksPlugin>,
  'editAsset' | 'editLink' | 'cancelNewLink' | 'linkEditingCancelled' | 'unlink' | 'getApi'
> &
  Pick<PluginProps<ContentLinksPlugin>, 'disabled'>;

const NewAssetLinkEntity = ({
  cancelNewLink,
  children,
  contentState,
  entityKey,
}: React.PropsWithChildren<EntityDecoratorProps<CustomLinkProps>>) => {
  const entity = contentState.getEntity(entityKey);
  if (!isNewLinkOfType(entity, NewLinkType.AssetLink)) {
    return children;
  }

  const isPlaceholder = !!entity.getData().isPlaceholder;

  // New link should be always under a dialog, but just for case if anyone sees it, click reverts it back to no link
  return (
    <a
      href="client/app/applications/richText/components/editorCore/plugins/links/AssetLinksPlugin#"
      className="rte__link rte__link--is-edited"
      onClick={() => cancelNewLink(entityKey, isPlaceholder)}
    >
      {children}
    </a>
  );
};

const AssetLinkEntity = ({
  blockKey,
  children,
  contentState,
  disabled,
  editAsset,
  editLink,
  entityKey,
  getApi,
  unlink,
}: React.PropsWithChildren<EntityDecoratorProps<CustomLinkProps>>) => {
  const entity = contentState.getEntity(entityKey);
  if (!isAssetLink(entity)) {
    return children;
  }

  const { assetId } = entity.getData();
  const status = getLinkStatus({ blockKey, contentState }, getApi().getLimitations());

  return (
    <AssetLink
      assetId={assetId}
      disabled={disabled}
      entityKey={entityKey}
      key={entityKey}
      onAssetClick={() => editAsset(assetId)}
      onEdit={() => editLink(entityKey)}
      onUnlink={() => unlink(entityKey)}
      status={status}
    >
      {children}
    </AssetLink>
  );
};

export const AssetLinksPlugin: PluginComponent<AssetLinksPlugin> = (props) => {
  const { disabled, editedEntityName, trackUserEventWithData } = props;

  const canViewContent = useSelector(canUserViewAnyActiveLanguage);

  const apply: Apply<AssetLinksPlugin> = useCallback(
    (state) => {
      const getLinkEntityComponent: Decorator<GetLinkEntityComponent<CustomLinkProps>> =
        (baseGetLinkEntityComponent) => (linkType) => {
          const customLinkProps: CustomLinkProps = {
            cancelNewLink: state.cancelNewLink,
            disabled,
            editAsset: state.editAsset,
            editLink: state.editLink,
            getApi: state.getApi,
            linkEditingCancelled: state.linkEditingCancelled,
            unlink: state.unlink,
          };

          switch (linkType) {
            case LinkType.Asset:
              return {
                component: AssetLinkEntity,
                props: customLinkProps,
              };

            case NewLinkType.AssetLink:
              return {
                component: NewAssetLinkEntity,
                props: customLinkProps,
              };

            default:
              return baseGetLinkEntityComponent(linkType);
          }
        };

      state.getLinkEntityComponent.decorate(getLinkEntityComponent);

      const updateAssetLink = (entityKey: string, assetId: Uuid): void => {
        if (!state.canUpdateContent(EditorChangeReason.Internal)) {
          return;
        }

        state.executeChange((editorState) => {
          const selection = state.getApi().getSelectionForEntity(editorState, entityKey);
          if (selection) {
            const newEditorState = state.getApi().createAssetLink(editorState, selection, assetId);
            if (newEditorState !== editorState) {
              trackUserEventWithData(TrackedEvent.ContentItemEditing, {
                action: ContentItemEditingEventTypes.AssignAsset,
                origin: ContentItemEditingEventOrigins.Paper,
                contentElementType: ElementType.RichText,
                selectedItemsCount: 1,
              });
            }

            state.linkEditingFinished();
            return newEditorState;
          }
          return editorState;
        }, EditorChangeReason.Internal);
      };

      const renderModal: Decorator<Render<AssetLinksPlugin>> = (baseRenderModal) => (baseState) => {
        const { editedLinkEntityKey, editorState, cancelNewLink, focus, linkEditingCancelled } =
          baseState;

        if (editedLinkEntityKey) {
          const content = editorState.getCurrentContent();
          const entity = content.getEntity(editedLinkEntityKey);

          const isExisting = isAssetLink(entity);
          const isNew = isNewLinkOfType(entity, NewLinkType.AssetLink);
          const isPlaceholder = isNew && !!entity.getData().isPlaceholder;

          if (isExisting || isNew) {
            return (
              <ModalAssetSelector
                isOpen
                onClose={() => {
                  if (isExisting) {
                    linkEditingCancelled(editedLinkEntityKey);
                  } else {
                    cancelNewLink(editedLinkEntityKey, isPlaceholder);
                  }
                  focus();
                }}
                onSelect={(assetId) => {
                  updateAssetLink(editedLinkEntityKey, assetId);
                  focus();
                }}
                titleBarText={`Select an asset to link to ${editedEntityName}`}
              />
            );
          }
        }

        return baseRenderModal(baseState);
      };

      state.renderModal.decorate(renderModal);

      const newAssetLink = (): void => {
        if (!state.canUpdateContent()) {
          return;
        }

        state.executeChange((editorState) => {
          const selection = editorState.getSelection();
          const content = editorState.getCurrentContent();
          if (isNewLinkAllowedAtSelection(content, selection)) {
            const newEditorState = selection.isCollapsed()
              ? state.getApi().createLinkPlaceholder(editorState, selection, NewLinkType.AssetLink)
              : state.getApi().createNewAssetLink(editorState, selection);
            if (editorState !== newEditorState) {
              const entityKey = newEditorState.getCurrentContent().getLastCreatedEntityKey();
              state.setEditedLinkEntityKey(entityKey);
              return newEditorState;
            }
          }
          return editorState;
        }, EditorChangeReason.Internal);
      };

      const getLinkOptions: Decorator<GetLinkOptions> = (baseGetLinkOptions) => () => [
        ...baseGetLinkOptions(),
        {
          isDisabled: !canViewContent,
          name: 'Asset link',
          onClick: newAssetLink,
          tooltipText: canViewContent ? undefined : CannotViewAssetsMessage,
          uiAction: DataUiRteAction.AddAssetLink,
        },
      ];

      state.getLinkOptions.decorate(getLinkOptions);

      return {};
    },
    [canViewContent, disabled, trackUserEventWithData, editedEntityName],
  );

  return useEditorWithPlugin(props, { apply });
};
