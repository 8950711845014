import { isAbortError } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  ContentItemPreviewRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  WebSpotlightContentRoute,
  WebSpotlightRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCannotViewItemMessage } from '../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ILoadListingItemsAction } from '../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { UnableToLoadErrorMessage } from '../../constants/uiConstants.ts';
import {
  WebSpotlight_LoadWebSpotlightTree_Failed,
  WebSpotlight_LoadWebSpotlightTree_Started,
  WebSpotlight_LoadWebSpotlightTree_Succeeded,
} from '../../constants/webSpotlightActionTypes.ts';
import { IExpandedNodesData } from '../../reducers/expandedNodesData.ts';
import { getRootNodeId } from '../../utils/webSpotlightUtils.ts';
import { INodeParams } from './expandWebSpotlightNode.ts';

interface IDeps {
  readonly expandSelectedWebSpotlightNodeAncestors: (
    currentPath: string,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly expandWebSpotlightNode: (params: INodeParams, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadSubpagesIds: (contentItemIds: UuidArray, abortSignal?: AbortSignal) => ThunkPromise;
}

const started = (rootPath: string) =>
  ({
    type: WebSpotlight_LoadWebSpotlightTree_Started,
    payload: {
      rootPath,
    },
  }) as const;

const succeeded = (rootItemPath: string) =>
  ({
    type: WebSpotlight_LoadWebSpotlightTree_Succeeded,
    payload: {
      rootItemPath,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: WebSpotlight_LoadWebSpotlightTree_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type LoadWebSpotlightTreeActionsType = ReturnType<
  typeof started | typeof succeeded | typeof failed
>;

export const createLoadWebSpotlightTreeAction =
  (deps: IDeps) =>
  (
    rootItemId: Uuid,
    spaceId: Uuid | undefined,
    path: string,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        sharedApp: { currentProjectId },
        webSpotlightApp: { expandedNodesData },
      } = state;

      const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
      const rootPath = buildPath<WebSpotlightRouteParams>(WebSpotlightContentRoute, {
        projectId: currentProjectId,
        variantId: selectedLanguageId,
        spaceId,
      });

      dispatch(started(rootPath));

      const itemsToLoad: ReadonlyArray<Uuid> = [
        ...Immutable.List(expandedNodesData.values())
          .flatMap<number, string>((data: IExpandedNodesData) => [data.itemId, ...data.subpagesIds])
          .toArray(),
        rootItemId,
      ];

      const [listingItems] = await Promise.all([
        dispatch(deps.loadListingItems(itemsToLoad, abortSignal)),
        dispatch(deps.loadSubpagesIds(itemsToLoad, abortSignal)),
      ]);

      const rootItem = listingItems?.find((i) => i.item.id === rootItemId);
      assertRootItem(
        rootItem,
        rootItemId,
        itemsToLoad,
        listingItems,
        getState().data.listingContentItems.byId,
      );

      const cannotViewMessage = getCannotViewItemMessage(rootItem);
      if (!cannotViewMessage) {
        const nodeId = getRootNodeId(rootItemId);
        await dispatch(
          deps.expandWebSpotlightNode(
            {
              itemId: rootItemId,
              nodeId,
            },
            abortSignal,
          ),
        );
      }

      const rootItemPath = buildPath<ContentItemRouteParams<UuidArray>>(ContentItemPreviewRoute, {
        app: ContentItemsAppRouteSegment.WebSpotlight,
        projectId: currentProjectId,
        variantId: selectedLanguageId,
        spaceId,
        contentItemIds: [rootItemId],
      });

      await dispatch(deps.expandSelectedWebSpotlightNodeAncestors(path, abortSignal));
      dispatch(succeeded(rootItemPath));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(UnableToLoadErrorMessage));
      }

      throw error;
    }
  };

function assertRootItem(
  rootItem: IListingContentItem | undefined,
  rootItemId: string,
  itemIdsToLoad: readonly Uuid[],
  responseListingContentItems: readonly IListingContentItem[] | null,
  contentItemsByIdsStore: Immutable.Map<Uuid, IListingContentItem>,
): asserts rootItem {
  assert(rootItem, () => {
    const responseContentItemIds = responseListingContentItems?.map((i) => i.item.id) ?? [];
    const responseContentItemIdsNotFoundInStore = responseContentItemIds.filter(
      (id) => !contentItemsByIdsStore.has(id),
    );

    const responseContentItemIdsSet = new Set(responseContentItemIds);
    const contentItemIdsNotFoundInResponse = itemIdsToLoad.filter(
      (id) => !responseContentItemIdsSet.has(id),
    );

    return `${__filename}: Root item not found.
Requested root item id: ${rootItemId},
Count of requested content items: ${itemIdsToLoad.length}
Count of returned content items: ${responseContentItemIds.length}
Requested content item ids not found in response: [${contentItemIdsNotFoundInResponse}],
Returned content item ids not found in store: [${responseContentItemIdsNotFoundInStore}]`;
  });
}
