import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { getImageAssetReferences } from '../../../../../richText/plugins/images/api/editorImageUtils.ts';
import { getBlocks } from '../../../../../richText/utils/general/editorContentGetters.ts';
import { DeletedAssetsInGuidelines } from '../../../constants/errorMessageTemplates.ts';
import { IGuidelinesTypeElementData } from '../../../models/elements/GuidelinesTypeElementData.ts';
import { GuidelinesTypeElementValidationResult } from '../../../utils/typeElementValidators/types/GuidelinesTypeElementValidationResult.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';

function getGuidelinesTypeElementValidationResult(
  { ValidationConstants }: ITypeElementValidatorDependencies,
  typeElement: IGuidelinesTypeElementData,
  assetsById: Immutable.Map<Uuid, IAsset>,
): GuidelinesTypeElementValidationResult {
  const content = typeElement._editorState.getCurrentContent();
  const contentLength = getBlocks(content).reduce((length, block) => length + block.getLength(), 0);
  const isMaxCharsValid = contentLength <= ValidationConstants.GuidelinesElementTextMaxLength;

  const errors: Array<string> = [];
  if (!isMaxCharsValid) {
    errors.push(
      `Provide guidelines shorter than ${ValidationConstants.GuidelinesElementTextMaxLength} characters. You currently have ${contentLength} characters.`,
    );
  }

  const assetReferences = getImageAssetReferences(content);
  const containsDeletedAsset = assetReferences.some((asset) => assetsById.get(asset.id)?.archived);
  if (containsDeletedAsset) {
    errors.push(DeletedAssetsInGuidelines);
  }

  return {
    isMaxCharsValid,
    errorMessages: errors,
  };
}

export const createValidateGuidelinesTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: IGuidelinesTypeElementData): ThunkFunction<GuidelinesTypeElementValidationResult> =>
  (dispatch, getState) => {
    const assetsById = getState().data.assets.byId;
    const validationResult = getGuidelinesTypeElementValidationResult(
      deps,
      typeElement,
      assetsById,
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
