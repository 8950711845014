import { updateCurrentProject } from '../../../_shared/actions/thunkSharedActions.ts';
import { ContentItemFilterOrigin } from '../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { getSignInPageUrl } from '../../../_shared/utils/routing/redirectToSignInPage.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { loadSpaces } from '../../../data/actions/thunkDataActions.ts';
import { getFilterStorage } from '../../../localStorages/getFilterStorage.ts';
import { editItemVariantCreator } from './thunks/editItemVariant.ts';
import { openInventoryWithFilterCreator } from './thunks/openInventoryWithFilter.ts';
import { openProjectCreator } from './thunks/openProject.ts';
import { redirectToSharedPreviewCreator } from './thunks/redirectToSharedPreview.ts';

const { contentItemRepository, filterRepository } = repositoryCollection;

export const openInventoryWithFilter = openInventoryWithFilterCreator({
  buildPath,
  contentItemFilterStorage: getFilterStorage(ContentItemFilterOrigin.ContentInventory),
  filterRepository,
  getSignInPageUrl,
});

export const openProject = openProjectCreator({
  updateCurrentProject,
  buildPath,
  getSignInPageUrl,
});

export const editItemVariant = editItemVariantCreator({
  contentItemRepository,
  buildPath,
  getSignInPageUrl,
});

export const redirectToSharedPreview = redirectToSharedPreviewCreator({
  buildPath,
  contentItemRepository,
  loadSpaces,
  updateCurrentProject,
});
