import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { ILanguage } from '../../../data/models/languages/Language.ts';

export type LanguageOption = ILanguage & ISelectItem<LanguageOption>;

export const mapLanguageToOption = (language: ILanguage): LanguageOption => {
  return {
    ...language,
    label: language.name,
  };
};
