import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IFilterRepository } from '../../../../repositories/interfaces/IFilterRepository.type.ts';
import { IFiltersServerModel } from '../../../../repositories/serverModels/FilterServerModels.type.ts';
import {
  Data_Filters_Failed,
  Data_Filters_Started,
  Data_Filters_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly filterRepository: IFilterRepository;
}

const start = () => ({ type: Data_Filters_Started }) as const;
const failure = () => ({ type: Data_Filters_Failed }) as const;
const success = (data: IFiltersServerModel) =>
  ({
    type: Data_Filters_Success,
    payload: { data },
  }) as const;

export type LoadFiltersActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadFiltersAction =
  ({ filterRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const filterServerModels = await filterRepository.getFilters(abortSignal);
      dispatch(success(filterServerModels));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
