import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentItemEditing_ExpandModularContentItem_Finished,
  ContentItemEditing_Init_Ready,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { IContentItemWithVariant } from '../../models/contentItem/ContentItemWithVariant.ts';

const initialState: Immutable.Map<Uuid, IContentItemWithVariant> = Immutable.Map();

export function loadedContentItems(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, IContentItemWithVariant> {
  switch (action.type) {
    case ContentItemEditing_ExpandModularContentItem_Finished: {
      const contentItem = action.payload.contentItem;
      return state.set(contentItem.item.id, contentItem);
    }

    case ContentItemEditing_Init_Ready:
    case Shared_CurrentProjectId_Updated: {
      return initialState;
    }

    default:
      return state;
  }
}
