import { InnovationLabFeaturesServerModel } from '../../../../repositories/serverModels/InnovationLabFeaturesServerModel.type.ts';
import {
  InnovationLabFeatureCodename,
  innovationLabFeatureCodenames,
} from '../types/InnovationLabFeatureCodenames.ts';
import { InnovationLabFeatureSet } from '../types/InnovationLabFeatureSet.type.ts';
import { InnovationLabFeatureStatus } from '../types/InnovationLabFeatureStatus.ts';

const isKnownFeatureCodename = (
  codeName?: string | null,
): codeName is InnovationLabFeatureCodename =>
  !!codeName && (innovationLabFeatureCodenames as ReadonlyArray<string>).includes(codeName);
const isKnownFeatureStatus = (status?: string | null): status is InnovationLabFeatureStatus =>
  !!status && (Object.values(InnovationLabFeatureStatus) as ReadonlyArray<string>).includes(status);

export const createDomainModelFromServerModel = (
  serverModel: InnovationLabFeaturesServerModel,
): InnovationLabFeatureSet => {
  const featureSet = Object.entries(serverModel).reduce<InnovationLabFeatureSet>(
    (result, [featureCodename, featureInfo]) => {
      const featureStatus = featureInfo?.status?.toLowerCase();
      if (isKnownFeatureCodename(featureCodename) && isKnownFeatureStatus(featureStatus)) {
        result[featureCodename] = {
          status: featureStatus,
        };
      }

      return result;
    },
    {},
  );

  return featureSet;
};
