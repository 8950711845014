import { Dispatch, GetState, ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { IFilterRepository } from '../../../../../../../repositories/interfaces/IFilterRepository.type.ts';
import { IFilterServerModel } from '../../../../../../../repositories/serverModels/FilterServerModels.type.ts';
import {
  ListingFilter_RestoreSavedFilter_Failed,
  ListingFilter_RestoreSavedFilter_Finished,
  ListingFilter_RestoreSavedFilter_Started,
} from '../../constants/listingFilterActionTypes.ts';
import { UnableToRestoreErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly filterRepository: IFilterRepository;
}

const started = () =>
  ({
    type: ListingFilter_RestoreSavedFilter_Started,
  }) as const;

const finished = (filter: IFilterServerModel) =>
  ({
    type: ListingFilter_RestoreSavedFilter_Finished,
    payload: {
      filter,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: ListingFilter_RestoreSavedFilter_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type RestoreSavedFilterActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createRestoreFilterAction =
  (deps: IDeps) =>
  (): ThunkFunction =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        listingUi: {
          savedFiltersUi: { lastDeletedFilterId },
        },
      },
    } = getState();

    dispatch(started());
    try {
      const serverModel = await deps.filterRepository.restoreFilter(lastDeletedFilterId);
      dispatch(finished(serverModel));
    } catch (e) {
      dispatch(failed(UnableToRestoreErrorMessage));
      throw e;
    }
  };
