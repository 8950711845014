import { useLayoutEffect } from 'react';
import { boot, shutdown } from './../utils/churnZeroUtils.ts';

export interface IChurnZeroComponentDataProps {
  readonly email: string;
  readonly subscriptionId?: Uuid;
}

export const ChurnZeroComponent = (props: IChurnZeroComponentDataProps) => {
  const { subscriptionId, email } = props;

  useLayoutEffect(() => {
    if (subscriptionId) {
      boot({
        subscriptionId,
        email,
      });
    }

    return () => {
      shutdown();
    };
  }, [subscriptionId, email]);

  return <noscript />;
};
