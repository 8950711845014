import React, { useMemo } from 'react';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';

type ILanguageContextState = {
  readonly languageId: Uuid;
};

const defaultContext: ILanguageContextState = {
  languageId: DefaultVariantId,
};

export const LanguageContext = React.createContext<ILanguageContextState>(defaultContext);

interface ILanguageContextProviderProps {
  readonly children: React.ReactNode | React.ReactNodeArray;
  readonly languageId: Uuid;
}

export const LanguageContextProvider: React.FC<ILanguageContextProviderProps> = ({
  children,
  languageId,
}) => {
  const contextState = useMemo<ILanguageContextState>(() => ({ languageId }), [languageId]);

  return <LanguageContext.Provider value={contextState}>{children}</LanguageContext.Provider>;
};

LanguageContextProvider.displayName = 'LanguageContextProvider';
