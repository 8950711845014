import { InputState } from '@kontent-ai/component-library/Input';
import { IBaseSelectItem, Option } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { SelectorHeader } from './SelectorHeader.tsx';

interface IWorkflowSelectorProps {
  readonly disabledTooltip: string | undefined;
  readonly index: number;
  readonly inputRef?: RefObject<HTMLInputElement>;
  readonly onSelect: (id: Uuid) => void;
  readonly selectedWorkflowId: Uuid | null;
  readonly workflows: ReadonlyArray<IBaseSelectItem>;
}

export const WorkflowSelector: React.FC<IWorkflowSelectorProps> = ({
  disabledTooltip,
  index,
  inputRef,
  onSelect,
  selectedWorkflowId,
  workflows,
}) => (
  <Stack spacing={Spacing.L}>
    <SelectorHeader index={index} type={SelectorType.Workflow} />
    <SingleSelect
      inputState={disabledTooltip ? InputState.Disabled : undefined}
      items={workflows}
      onSelectionChange={onSelect}
      placeholder="Select a workflow"
      renderMenuOption={(optionProps) =>
        optionProps.item.value && (
          <Option {...optionProps} {...getDataUiObjectNameAttribute(optionProps.item.value.id)} />
        )
      }
      selectedItemId={selectedWorkflowId}
      tooltipText={disabledTooltip}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Workflows)}
      inputRef={inputRef}
      {...getDataUiCollectionAttribute(DataUiCollection.Workflows)}
    />
  </Stack>
);

WorkflowSelector.displayName = 'WorkflowSelector';
