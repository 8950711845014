import React from 'react';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableColumnSize } from '../../../../../_shared/uiComponents/ScrollTable/types/scrollTableTypes.ts';

export const PlaceholderForCheckboxCell: React.FC<{
  readonly disabledMessage?: string;
  readonly size?: ScrollTableColumnSize;
}> = ({ disabledMessage, size }) => (
  <ScrollTableCell size={size || 1} disabledTooltip={disabledMessage}>
    <div />
  </ScrollTableCell>
);

PlaceholderForCheckboxCell.displayName = 'PlaceholderForCheckboxCell';
