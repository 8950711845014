import { validateNumberOfItems } from '../../../../../../_shared/utils/validation/limitations/validateNumberOfItems.ts';
import { ILinkedItemsTypeElementData } from '../../../models/elements/LinkedItemsTypeElementData.ts';
import { IDefaultValueLinkedItem } from '../../../reducers/IContentModelsAppStoreState.type.ts';
import { getIntegerFromStringOrNull } from '../../typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { hasLinkedItemsDefaultValue } from '../../typeElementDefaultValueUtils.ts';

export const areLimitationsInLinkedItemsDefaultValueMet = (
  typeElement: ILinkedItemsTypeElementData,
  allReferencedLinkedItems: ReadonlyArray<IDefaultValueLinkedItem>,
): boolean => {
  if (!hasLinkedItemsDefaultValue(typeElement)) {
    return true;
  }

  const minItems = getIntegerFromStringOrNull(typeElement.minItems);
  const maxItems = getIntegerFromStringOrNull(typeElement.maxItems);

  const existingReferencedItems = allReferencedLinkedItems.filter(
    (item) => typeElement.defaultValue.includes(item.itemId) && !item.isItemArchived,
  );
  const numberOfItems = existingReferencedItems.length;

  const isNumberOfItemsLimitMet = validateNumberOfItems(minItems, maxItems, numberOfItems);
  const containsOnlyAllowedContentTypes = hasOnlyAllowedContentTypes(
    typeElement.allowedTypes,
    existingReferencedItems,
  );

  return isNumberOfItemsLimitMet && containsOnlyAllowedContentTypes;
};

const hasOnlyAllowedContentTypes = (
  allowedTypesIds: ReadonlyArray<Uuid>,
  referencedItems: ReadonlyArray<IDefaultValueLinkedItem>,
): boolean => {
  if (!allowedTypesIds || !allowedTypesIds.length) {
    return true;
  }

  return referencedItems.every((item: IDefaultValueLinkedItem) => {
    return allowedTypesIds.includes(item.typeId);
  });
};
