import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isSampleProject } from '../../../../_shared/utils/sampleItemsUtils.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsExpired,
  getAdministratedSubscriptions,
  getSelectedSubscription,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  IListingProjectPlaceholderProps,
  ListingProjectPlaceholder as ListingProjectPlaceholderComponent,
} from '../../../subscriptionManagement/shared/components/ListingProjectPlaceholder.tsx';
import { getSubscriptionProjects } from '../../../subscriptionManagement/shared/utils/subscriptionProjectUtils.ts';

interface IOwnProps {
  readonly onCreateButtonClick?: () => void;
}

function mapStateToProps(state: IStore, ownProps: IOwnProps): IListingProjectPlaceholderProps {
  const {
    data: { subscriptions, projects },
    projectsApp: { creatingProject },
  } = state;

  const listingProjectPlaceholderProps: IListingProjectPlaceholderProps = {
    creatingProject,
  };

  const selectedSubscription = getSelectedSubscription(state);

  if (selectedSubscription) {
    const subscriptionProjects = getSubscriptionProjects(
      projects.byId.toArray(),
      selectedSubscription.subscriptionId,
    );
    const numberOfProjectsDisplayed = subscriptionProjects.length;

    return {
      ...listingProjectPlaceholderProps,
      onCreateButtonClick:
        numberOfProjectsDisplayed === 0 ? ownProps.onCreateButtonClick : undefined,
    };
  }

  const administratedSubscriptions = getAdministratedSubscriptions(
    subscriptions.administratedIds,
    subscriptions.byId,
  );
  const canCreateProject = administratedSubscriptions.length > 0;
  const allSubscriptionAreExpired = areAllSubscriptionsExpired(administratedSubscriptions);

  if (!canCreateProject || allSubscriptionAreExpired) {
    return {
      ...listingProjectPlaceholderProps,
      onCreateButtonClick: undefined,
    };
  }

  const administratedSubscriptionIds = administratedSubscriptions.map((p) => p?.subscriptionId);
  const containsOtherThanSampleProject = projects.byId
    .filter((project) => administratedSubscriptionIds.includes(project.subscriptionId))
    .some((project: IProjectDetails) => !isSampleProject(project));

  return {
    ...listingProjectPlaceholderProps,
    onCreateButtonClick: containsOtherThanSampleProject ? undefined : ownProps.onCreateButtonClick,
  };
}

export const ListingProjectPlaceholder: React.ComponentType<IOwnProps> = connect(mapStateToProps)(
  ListingProjectPlaceholderComponent,
);
