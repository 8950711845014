import { useCallback } from 'react';
import { ModalViewer } from '../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../_shared/components/Modal/ModalViewerPosition.ts';
import { useEditorWithPlugin } from '../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../editorCore/types/Editor.composition.type.ts';
import { None } from '../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, Decorator, decorable } from '../editorCore/utils/decorable.ts';
import { FocusPlugin } from './behavior/FocusPlugin.tsx';
import { KeyboardShortcutsPlugin, OnEscape } from './keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from './keyboardShortcuts/api/EditorCommand.ts';
import { StylesPlugin } from './visuals/StylesPlugin.tsx';

export type OnCloseModal = () => boolean;

type ModalsPluginState = {
  readonly onCloseModal: DecorableFunction<OnCloseModal>;
  readonly renderModal: DecorableFunction<Render<ModalsPlugin>>;
  readonly renderModalToViewer: DecorableFunction<Render<ModalsPlugin>>;
};

export type ModalsPlugin = EditorPlugin<
  ModalsPluginState,
  None,
  None,
  None,
  [StylesPlugin, FocusPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>]
>;

const renderNull: Render<ModalsPlugin> = () => null;

const renderOverlays: Decorator<Render<ModalsPlugin>> = (baseRenderOverlays) => (state) => {
  const { onCloseModal, renderModal, renderModalToViewer } = state;

  const modal = renderModal(state);
  const viewerModal = modal ? null : renderModalToViewer(state);

  return (
    <>
      {baseRenderOverlays(state)}
      <ModalViewer
        dialogClassName="dialog"
        isDialogVisible={!!viewerModal}
        onClose={onCloseModal}
        position={ModalViewerPosition.Center}
      >
        {viewerModal}
      </ModalViewer>
      {modal}
    </>
  );
};

export const ModalsPlugin: PluginComponent<ModalsPlugin> = (props) => {
  const apply: Apply<ModalsPlugin> = useCallback((state) => {
    state.renderOverlays.decorate(renderOverlays);

    const onCloseModal = decorable<OnCloseModal>(() => false);

    const onEscape: Decorator<OnEscape> = (baseOnEscape) => () => {
      if (!onCloseModal()) {
        baseOnEscape();
      }
    };

    state.onEscape.decorate(onEscape);

    return {
      onCloseModal,
      renderModal: decorable(renderNull),
      renderModalToViewer: decorable(renderNull),
    };
  }, []);

  return useEditorWithPlugin(props, { apply });
};
