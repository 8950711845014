import { getCurrentProject } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { EnsuringStatus } from '../../reducers/internalReducers/selectedLanguage.ts';
import { getSelectedLanguageId } from '../../selectors/getSelectedLanguageId.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { Capability, getUserCapability } from './capability.ts';

export const canUserViewAnyActiveLanguage = (state: IStore): boolean =>
  getUserCapability(getCurrentProject(state)).canOneOf([Capability.ViewContent]) &&
  state.sharedApp.selectedLanguage.ensuringStatus !== EnsuringStatus.EnsuredButEmpty &&
  !!getSelectedLanguageId(state);
