import { Collection } from '@kontent-ai/utils';
import { AnyAiMessagePayload } from '../../services/signalR/signalRClient.type.ts';

type Listener = (messages: ReadonlyArray<AnyAiMessagePayload>) => void;
type Unsubscribe = () => void;

export type AiMessageBuffer = {
  readonly dropMessages: (
    operationId: Uuid,
    condition: (message: AnyAiMessagePayload) => boolean,
  ) => void;
  readonly getAllMessages: () => ReadonlyMap<Uuid, ReadonlyArray<AnyAiMessagePayload>>;
  readonly getMessagesFor: (operationId: Uuid) => ReadonlyArray<AnyAiMessagePayload> | null;
  readonly ignoreMessages: (operationId: Uuid) => void;
  readonly keepMessages: (operationId: Uuid) => void;
  readonly messageReceived: (message: AnyAiMessagePayload) => void;
  readonly subscribe: (operationId: Uuid, listener: Listener) => Unsubscribe;
};

export const createAiMessageBuffer = (): AiMessageBuffer => {
  let buffer: ReadonlyMap<Uuid, ReadonlyArray<AnyAiMessagePayload>> = new Map();
  let listeners: ReadonlyMap<Uuid, ReadonlyArray<Listener>> = new Map();

  const dropMessages = (
    operationId: Uuid,
    dropCondition: (message: AnyAiMessagePayload) => boolean,
  ): void => {
    buffer = Collection.replaceWith(buffer, operationId, (existingMessages) =>
      existingMessages.filter((message) => !dropCondition(message)),
    );
  };

  const keepMessages = (operationId: Uuid): void => {
    buffer = Collection.add(buffer, [operationId, []]);
    listeners = Collection.add(listeners, [operationId, []]);
  };

  const messageReceived = (message: AnyAiMessagePayload): void => {
    buffer = Collection.replaceWith(buffer, message.operationId, (existingMessages) => [
      ...existingMessages,
      message,
    ]);
    notifyListeners(message.operationId);
  };

  const ignoreMessages = (operationId: Uuid): void => {
    buffer = Collection.remove(buffer, operationId);
    listeners = Collection.remove(listeners, operationId);
  };

  const getAllMessages = (): ReadonlyMap<Uuid, ReadonlyArray<AnyAiMessagePayload>> => buffer;

  const getMessagesFor = (operationId: Uuid): ReadonlyArray<AnyAiMessagePayload> | null =>
    buffer.get(operationId) ?? null;

  const subscribe = (operationId: Uuid, listener: Listener): Unsubscribe => {
    listeners = Collection.replaceWith(listeners, operationId, (actionListeners) => [
      ...actionListeners,
      listener,
    ]);

    return () => {
      listeners = Collection.replaceWith(listeners, operationId, (actionListeners) =>
        actionListeners.filter((actionListener) => actionListener !== listener),
      );
    };
  };

  const notifyListeners = (operationId: Uuid): void => {
    const messages = buffer.get(operationId) ?? [];
    listeners.get(operationId)?.forEach((listener) => listener(messages));
  };

  return {
    dropMessages,
    getAllMessages,
    getMessagesFor,
    ignoreMessages,
    keepMessages,
    messageReceived,
    subscribe,
  };
};
