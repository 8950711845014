import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { guidelinesVisibilityStorage } from '../../../../../../localStorages/guidelinesVisibilityStorage.ts';
import {
  ContentItemEditing_GuidelinesElement_Collapsed,
  ContentItemEditing_GuidelinesElement_Expanded,
} from '../../constants/contentItemEditingActionTypes.ts';

const guidelinesElementCollapsed = (guidelinesElementId: Uuid) =>
  ({
    type: ContentItemEditing_GuidelinesElement_Collapsed,
    payload: { guidelinesElementId },
  }) as const;

const guidelinesElementExpanded = (guidelinesElementId: Uuid) =>
  ({
    type: ContentItemEditing_GuidelinesElement_Expanded,
    payload: { guidelinesElementId },
  }) as const;

export type GuidelinesElementActionsType = ReturnType<
  typeof guidelinesElementCollapsed | typeof guidelinesElementExpanded
>;

export const createChangeGuidelinesElementVisibilityAction =
  () =>
  (guidelinesElementId: Uuid, isCollapsed: boolean): ThunkFunction =>
  (dispatch, getState) => {
    const {
      data: {
        user: {
          info: { userId },
        },
      },
      sharedApp: { currentProjectId },
    } = getState();

    if (isCollapsed) {
      dispatch(guidelinesElementCollapsed(guidelinesElementId));
    } else {
      dispatch(guidelinesElementExpanded(guidelinesElementId));
    }

    const newGuidelinesVisibility = { guidelinesElementId, collapsed: isCollapsed };
    guidelinesVisibilityStorage.save(newGuidelinesVisibility, currentProjectId, userId);
  };
