export const ContentModels_TypeEditor_AssetTypeLoaded = 'ContentModels_TypeEditor_AssetTypeLoaded';
export const ContentModels_TypeEditor_ErrorMessageCleared =
  'ContentModels_TypeEditor_ErrorMessageCleared';
export const ContentModels_TypeEditor_FirstInvalidElement_Focused =
  'ContentModels_TypeEditor_FirstInvalidElement_Focused';
export const ContentModels_TypeEditor_FirstInvalidElement_Set =
  'ContentModels_TypeEditor_FirstInvalidElement_Set';
export const ContentModels_TypeEditor_FocusElementMovedToPreviousPosition =
  'ContentModels_TypeEditor_FocusElementMovedToPreviousPosition';
export const ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loading =
  'ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loading';
export const ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loaded =
  'ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loaded';
export const ContentModels_TypeEditor_InitStarted = 'ContentModels_TypeEditor_InitStarted';
export const ContentModels_TypeEditor_Left = 'ContentModels_TypeEditor_Left';
export const ContentModels_TypeEditor_LinkedItemsDefaultValueReferences_Added =
  'ContentModels_TypeEditor_LinkedItemsDefaultValueReferences_Added';
export const ContentModels_TypeEditor_RemoveTypeElement =
  'ContentModels_TypeEditor_RemoveTypeElement';
export const ContentModels_TypeEditor_SaveAttempted = 'ContentModels_TypeEditor_SaveAttempted';
export const ContentModels_TypeEditor_SavingStarted = 'ContentModels_TypeEditor_SavingStarted';
export const ContentModels_TypeEditor_TypeElementCreated =
  'ContentModels_TypeEditor_TypeElementCreated';
export const ContentModels_TypeEditor_TypeElementDropped =
  'ContentModels_TypeEditor_TypeElementDropped';
export const ContentModels_TypeEditor_TypeElementMoved =
  'ContentModels_TypeEditor_TypeElementMoved';
export const ContentModels_TypeEditor_TypeElementPickedUp =
  'ContentModels_TypeEditor_TypeElementPickedUp';
export const ContentModels_TypeEditor_TypeElementUpdated =
  'ContentModels_TypeEditor_TypeElementUpdated';
export const ContentModels_TypeEditor_TypeElementValidated =
  'ContentModels_TypeEditor_TypeElementValidated';
export const ContentModels_TypeEditor_TypeLoaded = 'ContentModels_TypeEditor_TypeLoaded';
