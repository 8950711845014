import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ILanguageRepository } from '../../../../../repositories/interfaces/ILanguageRepository.type.ts';
import { ILanguagesServerModel } from '../../../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';
import {
  SubscriptionUsers_LanguageFilter_LoadLanguages_Failed,
  SubscriptionUsers_LanguageFilter_LoadLanguages_Started,
  SubscriptionUsers_LanguageFilter_LoadLanguages_Succeeded,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly languageRepository: Pick<ILanguageRepository, 'getLanguagesForProject'>;
}

const start = () =>
  ({
    type: SubscriptionUsers_LanguageFilter_LoadLanguages_Started,
  }) as const;

const success = (data: ILanguagesServerModel) =>
  ({
    type: SubscriptionUsers_LanguageFilter_LoadLanguages_Succeeded,
    payload: { data },
  }) as const;

const failure = () =>
  ({
    type: SubscriptionUsers_LanguageFilter_LoadLanguages_Failed,
  }) as const;

export type LoadEnvironmentLanguagesActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const loadEnvironmentLanguagesCreator =
  ({ languageRepository }: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const languageServerModel = await languageRepository.getLanguagesForProject(
        projectId,
        abortSignal,
      );
      dispatch(success(languageServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
