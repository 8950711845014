export enum ApiKeyType {
  DAPI = 'delivery-api',
  MAPIPat = 'management-api-pat',
  MAPI = 'management-api',
  SAPI = 'subscription-api',
  Unknown = 'unknown',
  // Users will not be able to create more of these tokens, however tokens of such types will remain valid
  DAPIPrimary = 'delivery-api-primary',
  DAPISecondary = 'delivery-api-secondary',
  PAPIPrimary = 'preview-delivery-api-primary',
  PAPISecondary = 'preview-delivery-api-secondary',
}

export const OldDapiKeyTypes = [
  ApiKeyType.DAPISecondary,
  ApiKeyType.DAPIPrimary,
  ApiKeyType.PAPISecondary,
  ApiKeyType.PAPIPrimary,
];

export const DapiKeyTypes = [...OldDapiKeyTypes, ApiKeyType.DAPI];
