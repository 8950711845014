import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  ITaskUserMentionOwnProps,
  ITaskUserMentionStateProps,
  TaskUserMention as TaskUserMentionComponent,
} from '../../../components/details/Tasks/TaskUserMention.tsx';

interface IUserMentionContainerProps extends ITaskUserMentionOwnProps {
  readonly userId?: UserId;
}

function mapStateToProps(
  state: IStore,
  { userId }: IUserMentionContainerProps,
): ITaskUserMentionStateProps {
  const {
    data: {
      user,
      users: { usersById },
    },
  } = state;

  const mentionedUser = userId ? usersById.get(userId) : undefined;
  const isCurrentUser = mentionedUser?.userId === user.info.userId;

  return {
    user: mentionedUser,
    isCurrentUser,
  };
}

export const TaskUserMention: React.ComponentType<IUserMentionContainerProps> =
  connect(mapStateToProps)(TaskUserMentionComponent);
