import { memoize } from '@kontent-ai/memoization';
import { createCompare, naturally } from '@kontent-ai/utils';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';

export const getWorkflowsSortedByName = memoize.maxOne(
  (workflows: ReadonlyMap<Uuid, Workflow>): readonly Workflow[] =>
    Array.from(workflows.values()).sort(
      createCompare({
        compare: naturally,
        select: (t) => t.name,
      }),
    ),
);
