import React from 'react';
import { Tag } from '../../../../../../component-library/components/Tag/Tag.tsx';
import { RemovalState } from '../../../../../../component-library/components/Tag/removalStateEnum.ts';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import {
  IAssignmentWorkflowStep,
  IWorkflowStep,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { ConfigureWorkflowLink } from '../../containers/Elements/ConfigureWorkflowLink.tsx';
import { WorkflowStepSelector } from '../../containers/Elements/WorkflowStepSelector.tsx';

interface IWorkflowStepTagProps {
  readonly color: WorkflowStepColor;
  readonly title: string;
}

const WorkflowStepTag: React.FC<IWorkflowStepTagProps> = (props) => (
  <div
    css={`
      display: inline-block;
      min-width: 0;
    `}
  >
    <Tag
      label={props.title}
      background={renderWorkflowStepColor(props.color)}
      removalState={RemovalState.NoRemoval}
    />
  </div>
);

export interface IWorkflowStatusSelectorSectionOwnProps {
  readonly onSelectorValueChanged: (optionId: Uuid) => void;
}

export interface IWorkflowStatusSelectorSectionStateProps {
  readonly fromWorkflowStep: IWorkflowStep | null;
  readonly selectableSteps: ReadonlyArray<IWorkflowStep>;
  readonly selectedWorkflowStep: IAssignmentWorkflowStep;
  readonly showConfigureWorkflowLink: boolean;
}

type IWorkflowStatusSelectorSectionProps = IWorkflowStatusSelectorSectionStateProps &
  IWorkflowStatusSelectorSectionOwnProps;

const FromWorkflowStep: React.FC<IWorkflowStatusSelectorSectionProps> = ({ fromWorkflowStep }) =>
  fromWorkflowStep ? (
    <>
      <span>From&nbsp;</span>
      <WorkflowStepTag color={fromWorkflowStep.color} title={fromWorkflowStep.name} />
    </>
  ) : (
    <span>Change workflow step</span>
  );

const SelectableSteps: React.FC<IWorkflowStatusSelectorSectionProps> = (props) => {
  switch (props.selectableSteps.length) {
    case 0:
      return (
        <WorkflowStepTag
          color={props.selectedWorkflowStep.color}
          title={props.selectedWorkflowStep.name}
        />
      );

    case 1: {
      const firstStep = props.selectableSteps[0];
      return (
        <WorkflowStepTag
          color={firstStep?.color || WorkflowStepColor.Gray}
          title={firstStep?.name || ''}
        />
      );
    }

    default:
      return (
        <WorkflowStepSelector
          options={props.selectableSteps}
          onSelectorValueChanged={props.onSelectorValueChanged}
        />
      );
  }
};

export const WorkflowStatusSelectorSection: React.FC<IWorkflowStatusSelectorSectionProps> = (
  props,
) => (
  <div className="change-workflow-step-modal__workflow-status-selector-section">
    <div
      className="change-workflow-step-modal__change-workflow-step-section"
      {...getDataUiElementAttribute(DataUiElement.ChangeWorkflowStepSection)}
    >
      <FromWorkflowStep {...props} />
      <span>&nbsp;to&nbsp;</span>
      <SelectableSteps {...props} />
    </div>
    {props.showConfigureWorkflowLink && (
      <ConfigureWorkflowLink workflowId={props.selectedWorkflowStep.workflowId} />
    )}
  </div>
);

WorkflowStatusSelectorSection.displayName = 'WorkflowStatusSelectorSection';
