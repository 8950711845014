import { Button, ButtonStyle } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { PortalContainerContextProvider } from '@kontent-ai/component-library/context';
import { spacingBetweenButtonsHorizontal } from '@kontent-ai/component-library/tokens';
import { FC, useRef, useState } from 'react';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export enum UmuxFeedbackButtonOrder {
  SubmitFirst = 'submit-first',
  CancelFirst = 'cancel-first',
}

interface IUmuxFeedbackQuestionnaireFooterProps {
  readonly buttonOrder: UmuxFeedbackButtonOrder;
  readonly cancelButtonStyle: ButtonStyle;
  readonly dismissFeedbackButtonText: string;
  readonly isDisabled: boolean;
  readonly onSubmit: () => void;
  readonly onClose: () => void;
  readonly overAllOtherElements?: boolean;
  readonly sendFeedbackButtonText: string;
  readonly submitButtonStyle: ButtonStyle;
}

export const UmuxFeedbackQuestionnaireFooter: FC<IUmuxFeedbackQuestionnaireFooterProps> = ({
  buttonOrder,
  cancelButtonStyle,
  dismissFeedbackButtonText,
  isDisabled,
  sendFeedbackButtonText,
  submitButtonStyle,
  onSubmit,
  onClose,
}) => {
  const [isDismissInProgress, setIsDismissInProgress] = useState(false);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const portalContainerRef = useRef<HTMLDivElement>(null);

  const dismissFeedback = (): void => {
    setIsDismissInProgress(true);
    onClose();
  };

  const submitFeedback = (): void => {
    setIsSubmitInProgress(true);
    onSubmit();
  };

  const actionInProgress = isDismissInProgress || isSubmitInProgress;

  const submitButton = (
    <Button
      tooltipText={isDisabled ? 'Please fill the survey before sending.' : undefined}
      tooltipPlacement="bottom"
      onClick={submitFeedback}
      buttonStyle={submitButtonStyle}
      disabled={isDisabled || actionInProgress}
      {...getDataUiActionAttribute(DataUiAction.SendUserFeedback)}
    >
      {isSubmitInProgress && (
        <Button.ProgressIcon
          {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
        />
      )}
      <Button.Label>{sendFeedbackButtonText}</Button.Label>
    </Button>
  );

  const cancelButton = (
    <Button
      buttonStyle={cancelButtonStyle}
      onClick={dismissFeedback}
      disabled={actionInProgress}
      {...getDataUiActionAttribute(DataUiAction.DismissUserFeedback)}
    >
      {isDismissInProgress && (
        <Button.ProgressIcon
          {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
        />
      )}
      <Button.Label>{dismissFeedbackButtonText}</Button.Label>
    </Button>
  );

  const buttons =
    buttonOrder === UmuxFeedbackButtonOrder.SubmitFirst ? (
      <>
        {submitButton}
        {cancelButton}
      </>
    ) : (
      <>
        {cancelButton}
        {submitButton}
      </>
    );

  return (
    <PortalContainerContextProvider portalContainerRef={portalContainerRef}>
      <Inline ref={portalContainerRef} spacingX={spacingBetweenButtonsHorizontal}>
        {buttons}
      </Inline>
    </PortalContainerContextProvider>
  );
};

UmuxFeedbackQuestionnaireFooter.displayName = 'UmuxFeedbackQuestionnaireFooter';
