import { History } from 'history';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { rememberReturnScrollId } from '../../../../../../_shared/actions/sharedActions.ts';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { getLinkedContentItemPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';

export const redirectToLinkedItem =
  (basePath: string, itemId: Uuid, history: History): ThunkFunction =>
  (dispatch) => {
    const currentPath = location.pathname;
    const targetPath = getLinkedContentItemPath(basePath, itemId);

    history.push(targetPath);

    const returnScrollId = CreateAutoScrollId.forContentItem(itemId);
    dispatch(rememberReturnScrollId(targetPath, { path: currentPath, scrollId: returnScrollId }));
  };
