import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { alphabetically } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { filterOutNullish } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { ContentTypeConversionOptions } from '../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import {
  IContentType,
  createDeletedContentType,
} from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ISnippets } from '../../../../data/reducers/snippets/ISnippets.type.ts';
import {
  EmptyTextTypeElementData,
  ITextTypeElementData,
} from '../models/elements/TextTypeElementData.ts';
import { IUrlSlugTypeElementData } from '../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { isSnippetTypeElement, isTextTypeElement } from '../types/typeElementTypeGuards.ts';
import { withDefaultNameIfNotSet } from '../utils/typeElementsUtils.ts';

const getAllTextTypeElements = memoize.maxOne(
  (
    typeElements: ReadonlyArray<IBaseTypeElementData>,
    snippets: ISnippets,
  ): ReadonlyArray<ITextTypeElementData> => {
    const contentTextElements = typeElements.filter(isTextTypeElement);
    const snippetIds = filterOutNullish(
      typeElements.filter(isSnippetTypeElement).map((snippet) => snippet.snippetId),
    );
    const currentSnippets = filterOutNullish(snippetIds.map((id) => snippets.byId.get(id)));
    const snippetTextTypeElements = currentSnippets
      .flatMap((snippet) => snippet.typeElements)
      .filter(isTextTypeElement);

    return snippetTextTypeElements.concat(contentTextElements);
  },
);

export const getSelectedTextElement = memoize.maxOne(
  (
    typeElements: ReadonlyArray<IBaseTypeElementData>,
    snippets: ISnippets,
    dependedOnTextElementId: Uuid | null,
  ): ITextTypeElementData | undefined => {
    if (!dependedOnTextElementId) {
      return undefined;
    }

    const allTextElements = getAllTextTypeElements(typeElements, snippets);
    return allTextElements.find(
      (element: ITextTypeElementData) => element.elementId === dependedOnTextElementId,
    );
  },
);

export const getAllTextElementsSortedByName = memoize.maxOne(
  (
    typeElements: ReadonlyArray<IBaseTypeElementData>,
    snippets: ISnippets,
  ): ReadonlyArray<IBaseTypeElementData> => {
    const allTextElements = getAllTextTypeElements(typeElements, snippets);
    return allTextElements
      .map(withDefaultNameIfNotSet)
      .sort((a, b) => alphabetically(a.name, b.name));
  },
);

const defaultAutoGenerateFromOption: ITextTypeElementData = {
  ...EmptyTextTypeElementData,
  elementId: '',
  name: 'Content item name (deprecated)',
};

export const getAutogenerateFromOptions = memoize.maxOne(
  (
    textElementOptions: ReadonlyArray<IBaseTypeElementData>,
    showDefaultOption: boolean,
  ): ReadonlyArray<IBaseTypeElementData> =>
    showDefaultOption ? [defaultAutoGenerateFromOption, ...textElementOptions] : textElementOptions,
);

export const getUrlSlugSelectedOption = (
  urlSlugElementData: IUrlSlugTypeElementData,
  typeElements: any,
  snippets: ISnippets,
): ITextTypeElementData | undefined => {
  const showDefaultOption = urlSlugElementData._canBeGeneratedFromDeprecatedItemName;
  const selectedTextElement = getSelectedTextElement(
    typeElements,
    snippets,
    urlSlugElementData.dependedOnTextElementId,
  );

  return selectedTextElement || (showDefaultOption && defaultAutoGenerateFromOption) || undefined;
};

export const getAllowedContentTypesSelectedOptions = memoize.weak(
  (
    types: Immutable.Map<Uuid, IContentType>,
    allowedTypes: ReadonlyArray<Uuid>,
  ): ReadonlySet<IContentType> => {
    const findTypeOrCreateDeletedType = (typeId: Uuid): IContentType =>
      types.find((type) => type?.id === typeId) ?? createDeletedContentType(typeId);

    return new Set(allowedTypes.map(findTypeOrCreateDeletedType));
  },
);

// todo could be somehow autopopulated based on the redux state
export const getContentTypeConversionOptions = (state: IStore): ContentTypeConversionOptions => {
  const {
    webSpotlightApp: { configuration },
  } = state;

  if (!configuration) {
    throw InvariantException(
      'WebSpotlight is not initialized yet! Content types cannot be properly converted!',
    );
  }

  return {
    subpagesElementEnabled: configuration.isEnabled,
  };
};
