import React, { ReactElement } from 'react';
import { EmptyObject, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { SubscriptionSelector } from './SubscriptionSelector.tsx';

type Props<TFormShape extends FieldValues> = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly disabled?: boolean;
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, string | null | undefined>;
};

export const SubscriptionSelectorWrapper = <TFormShape extends FieldValues = EmptyObject>({
  activeSubscriptions,
  disabled,
  formProps,
  name,
}: Props<TFormShape>): ReactElement => {
  const { field } = useController({
    control: formProps.control,
    name,
  });

  return (
    <LabelFor
      target={(id: Uuid) => (
        <SubscriptionSelector
          ref={field.ref}
          activeSubscriptions={activeSubscriptions}
          disabled={disabled}
          dropdownId={id}
          onSelectionChanged={field.onChange}
          selectedSubscriptionId={field.value as string | null | undefined}
        />
      )}
    >
      Subscription
    </LabelFor>
  );
};

(SubscriptionSelectorWrapper as React.FC).displayName = 'SubscriptionSelectorWrapper';
