import React from 'react';
import {
  ItemEditingForms,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ISectionProps {
  readonly dataUiNameAttribute: ItemEditingForms;
}

export const NonClickableSection: React.FC<React.PropsWithChildren<ISectionProps>> = ({
  children,
  dataUiNameAttribute,
}) => (
  <div
    className="sidebar__section-item"
    {...getDataUiObjectNameAttribute(dataUiNameAttribute || '')}
  >
    {children}
  </div>
);

NonClickableSection.displayName = 'NonClickableSection';
