import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import {
  CompiledTypeElementType,
  EditableElementType,
  ElementType,
} from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { Asset } from '../components/elements/Asset.tsx';
import { CustomElement } from '../components/elements/CustomElement.tsx';
import { DateTime } from '../components/elements/DateTime.tsx';
import { LinkedItems } from '../components/elements/LinkedItems.tsx';
import { MultipleChoice } from '../components/elements/MultipleChoice.tsx';
import { NumberElement } from '../components/elements/NumberElement.tsx';
import { RichString } from '../components/elements/RichString.tsx';
import { StringElement } from '../components/elements/StringElement.tsx';
import { Taxonomy } from '../components/elements/Taxonomy.tsx';
import { UrlSlug } from '../components/elements/UrlSlug.tsx';

export interface IRevisionItemElementProps<
  TItemElement extends ICompiledContentItemElementData,
  TElementType extends TypeElement,
> {
  readonly elementData: TItemElement;
  readonly hideValidationStatus?: boolean;
  readonly originalElementData: TItemElement | null;
  readonly typeElement: TElementType;
  readonly revisionElementModifiedBy?: string;
}

type RevisionComponent = React.ComponentType<IRevisionItemElementProps<any, any>>;

const componentMap: ReadonlyRecord<EditableElementType, RevisionComponent> = {
  [ElementType.Asset]: Asset,
  [ElementType.Custom]: CustomElement,
  [ElementType.DateTime]: DateTime,
  [ElementType.LinkedItems]: LinkedItems,
  [ElementType.MultipleChoice]: MultipleChoice,
  [ElementType.Number]: NumberElement,
  [ElementType.RichText]: RichString,
  [ElementType.Subpages]: LinkedItems,
  [ElementType.Taxonomy]: Taxonomy,
  [ElementType.Text]: StringElement,
  [ElementType.UrlSlug]: UrlSlug,
};

const isKnownKey = (key: CompiledTypeElementType): key is keyof typeof componentMap =>
  (Object.keys(componentMap) as ReadonlyArray<CompiledTypeElementType>).includes(key);

export const getItemElementRevisionComponent = (
  type: CompiledTypeElementType,
): RevisionComponent => {
  if (isKnownKey(type)) {
    return componentMap[type];
  }

  throw InvariantException(`No component found for type "${type}".`);
};
