import React from 'react';
import { IFriendlyWarning } from '../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ArchivedStepInfoSection } from '../Elements/ArchivedStepInfoSection.tsx';
import { ArchivingFriendlyWarningSection } from '../Elements/ArchivingFriendlyWarningSection.tsx';

export interface IMoveToArchivedStepProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning>;
}

export const MoveToArchivedStepSection: React.FC<IMoveToArchivedStepProps> = (props) => (
  <>
    <ArchivedStepInfoSection />
    <ArchivingFriendlyWarningSection
      activeUsersNames={props.activeUsersNames}
      friendlyWarningReasons={props.friendlyWarningReasons}
    />
  </>
);

MoveToArchivedStepSection.displayName = 'MoveToArchivedStepSection';
