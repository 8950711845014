import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadCollections,
  loadLanguages,
  loadProjects,
  loadRoles,
} from '../../../../data/actions/thunkDataActions.ts';
import { UserListingConfigStorage } from '../../../../localStorages/userListingConfigStorage.ts';
import { loadSubscriptionUsage } from '../../../subscriptionManagement/shared/actions/thunkSubscriptionManagementActions.ts';
import { createClearFilterAction } from './thunks/clearFilter.ts';
import { createInitializeInviteModalAction } from './thunks/initializeInviteModal.ts';
import { initializePeopleListingForProjectCreator } from './thunks/initializePeopleListingForProject.ts';
import { createInitializeUserDetailAction } from './thunks/initializeUserDetail.ts';
import { loadExistingUsersCreator } from './thunks/loadExistingUsers.ts';
import { createLoadProjectContributorsAction } from './thunks/loadProjectContributors.ts';
import { loadUsersCountedInUsageCreator } from './thunks/loadUsersCountedInUsage.ts';
import { createResendInviteAction } from './thunks/resendInvite.ts';
import { createRevokeInviteAction } from './thunks/revokeInvite.ts';
import { createSearchAllUsersByNameAction } from './thunks/searchAllUsersByName.ts';
import { createSubmitInvitations } from './thunks/submitInvitations.ts';
import { createToggleUserActiveStateAction } from './thunks/toggleUserActiveState.ts';
import { createTrackUserListingFilterChange } from './thunks/trackUserListingFilterChange.ts';
import { createUpdateCollectionGroups } from './thunks/updateCollectionGroups.ts';
import { createUpdateSavedConfigAction } from './thunks/updateSavedConfig.ts';

const { peopleRepository, inviteRepository } = repositoryCollection;

const loadProjectContributors = createLoadProjectContributorsAction({ peopleRepository });

export const loadExistingUsers = loadExistingUsersCreator({
  peopleRepository,
});

export const loadUsersCountedInUsage = loadUsersCountedInUsageCreator({
  peopleRepository,
});

export const initializePeopleListingForProject = initializePeopleListingForProjectCreator({
  loadCollections,
  loadExistingUsers,
  loadLanguages,
  loadProjectContributors,
  loadProjects,
  loadRoles,
  loadSubscriptionUsage,
  loadUsersCountedInUsage,
  UserListingConfigStorage,
});

export const submitInvitations = createSubmitInvitations({
  inviteRepository,
  loadSubscriptionUsage,
});

export const toggleUserActiveState = createToggleUserActiveStateAction({
  peopleRepository,
  trackUserEvent: trackUserEventWithData,
  loadSubscriptionUsage,
  loadProjectContributors,
  loadUsersCountedInUsage,
});

export const revokeInvite = createRevokeInviteAction({
  loadExistingUsers,
  loadProjectContributors,
  loadSubscriptionUsage,
  peopleRepository,
});

export const resendInvite = createResendInviteAction({ inviteRepository });

export const updateCollectionGroups = createUpdateCollectionGroups({
  peopleRepository,
  trackUserEvent: trackUserEventWithData,
  loadProjectContributors,
});

export const updateSavedConfig = createUpdateSavedConfigAction({
  userListingConfigStorage: UserListingConfigStorage,
});

export const clearFilter = createClearFilterAction({
  updateSavedConfig,
});

export const trackUserListingFilterChange = createTrackUserListingFilterChange({
  trackUserEventWithData,
});

export const initializeInviteModal = createInitializeInviteModalAction();

export const initializeUserDetail = createInitializeUserDetailAction();

export const searchAllUsersByName = createSearchAllUsersByNameAction({
  updateSavedConfig,
});
