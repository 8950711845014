import { Collection } from '@kontent-ai/utils';
import { missingSpaceDomain } from '../constants/errorMessageTemplates.ts';
import { SpaceMacro } from '../constants/macros.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import { ISpaceDomain } from '../models/PreviewConfiguration.type.ts';
import { anySpacesOptionId } from '../utils/getSpacesOptions.ts';

export type ContentTypeSpacesValidatorResult = {
  readonly message: string;
  readonly invalidSpaceIds: ReadonlyArray<string>;
};

export const contentTypeSpacesValidator = (
  previewUrlPattern: IPreviewUrlPattern,
  spaceDomains: ReadonlyArray<ISpaceDomain>,
  otherUrlPatterns: ReadonlyArray<IPreviewUrlPattern>,
): ContentTypeSpacesValidatorResult | undefined => {
  const hasSpaceMacro = new RegExp(SpaceMacro, 'ig').test(previewUrlPattern.urlPattern);

  if (!previewUrlPattern.spaces.size) {
    return {
      message: 'Select some space.',
      invalidSpaceIds: [],
    };
  }

  if (!hasSpaceMacro) {
    return undefined;
  }

  if (previewUrlPattern.spaces.has(anySpacesOptionId)) {
    const allSpaceIds = spaceDomains.map((domain) => domain.spaceId);
    const remainingSpaceIds = allSpaceIds.filter(
      (id) => !otherUrlPatterns.some((pattern) => pattern.spaces.has(id)),
    );
    const invalidRemainingSpaceIds = remainingSpaceIds.filter(
      (spaceId) =>
        !spaceDomains.some(
          (spaceDomain) => spaceDomain.spaceId === spaceId && !!spaceDomain.domain,
        ),
    );

    if (invalidRemainingSpaceIds.length) {
      return {
        message: missingSpaceDomain,
        invalidSpaceIds: [...invalidRemainingSpaceIds, anySpacesOptionId],
      };
    }
  } else {
    const invalidSpaceIds = Collection.getValues(previewUrlPattern.spaces).filter(
      (spaceId) =>
        !spaceDomains.some(
          (spaceDomain) => spaceDomain.spaceId === spaceId && !!spaceDomain.domain,
        ),
    );

    if (invalidSpaceIds.length) {
      return {
        message: missingSpaceDomain,
        invalidSpaceIds,
      };
    }
  }

  return undefined;
};
