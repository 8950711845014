import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ICompiledContentType,
  getCompiledContentTypeFromServerModel,
} from '../../../../applications/contentInventory/content/models/CompiledContentType.ts';
import { Data_AssetType_Success } from '../../../constants/dataActionTypes.ts';

const initialState: ICompiledContentType | null = null;

export function defaultAssetType(
  state: ICompiledContentType | null = initialState,
  action: Action,
): ICompiledContentType | null {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_AssetType_Success: {
      return getCompiledContentTypeFromServerModel(action.payload.data, {
        subpagesElementEnabled: false,
      });
    }

    default:
      return state;
  }
}
