import { EntityInstance } from 'draft-js';
import { Entity, EntityType, getEntityType } from '../../entityApi/api/Entity.ts';
import {
  AssetLinkData,
  ContentLinkData,
  EmailLinkData,
  NewLinkData,
  PhoneLinkData,
  WebLinkData,
} from './LinkData.type.ts';
import { LinkType } from './LinkType.ts';
import { NewLinkType } from './NewLinkType.ts';

export function isLink(entity: EntityInstance | undefined): boolean {
  return !!entity && getEntityType(entity) === EntityType.Link;
}

type NewLinkEntity = Entity<NewLinkData>;

export const newLinkTypes: ReadonlyArray<NewLinkType> = Object.values(NewLinkType);

export function isNewLinkOfType<TType extends NewLinkType = NewLinkType>(
  entity: EntityInstance | undefined,
  type: TType,
): entity is Entity<
  Omit<NewLinkData, 'type'> & {
    readonly type: TType;
  }
> {
  if (!isLink(entity)) {
    return false;
  }
  const data = entity?.getData();
  return data.type === type;
}

export function isNewLink(entity: EntityInstance | undefined): entity is Entity<NewLinkData> {
  if (!isLink(entity)) {
    return false;
  }
  const data = entity?.getData();
  return newLinkTypes.includes(data.type);
}

export function isLinkPlaceholder(entity: EntityInstance | undefined): entity is NewLinkEntity {
  return isNewLink(entity) && !!entity.getData().isPlaceholder;
}

type ContentLinkEntity = Entity<ContentLinkData>;

export const isContentLink = (entity: EntityInstance | undefined): entity is ContentLinkEntity =>
  isLink(entity) && entity?.getData().itemId;

type AssetLinkEntity = Entity<AssetLinkData>;

export const isAssetLink = (entity: EntityInstance | undefined): entity is AssetLinkEntity =>
  isLink(entity) && entity?.getData().assetId;

type EmailLinkEntity = Entity<EmailLinkData>;

export const isEmailLink = (entity: EntityInstance | undefined): entity is EmailLinkEntity =>
  isLink(entity) && entity?.getData().emailAddress;

type WebLinkEntity = Entity<WebLinkData>;

export const isWebLink = (entity: EntityInstance | undefined): entity is WebLinkEntity =>
  isLink(entity) && entity?.getData().url;

type PhoneLinkEntity = Entity<PhoneLinkData>;

export const isPhoneLink = (entity: EntityInstance | undefined): entity is PhoneLinkEntity =>
  isLink(entity) && entity?.getData().phoneNumber;

export function getLinkTypeFromData(data: AnyObject): LinkType | NewLinkType | null {
  if (Object.hasOwn(data, 'itemId')) {
    return LinkType.Content;
  }
  if (Object.hasOwn(data, 'assetId')) {
    return LinkType.Asset;
  }
  if (Object.hasOwn(data, 'url')) {
    return LinkType.Web;
  }
  if (Object.hasOwn(data, 'emailAddress')) {
    return LinkType.Email;
  }
  if (Object.hasOwn(data, 'phoneNumber')) {
    return LinkType.Phone;
  }
  if (Object.hasOwn(data, 'type')) {
    if (Object.values(NewLinkType).includes(data.type)) {
      return data.type;
    }
  }
  return null;
}

type ExistingLinkData =
  | AssetLinkData
  | ContentLinkData
  | EmailLinkData
  | PhoneLinkData
  | WebLinkData;

export function isExistingLink(
  entity: EntityInstance | undefined,
): entity is Entity<ExistingLinkData> {
  return isLink(entity) && !isNewLink(entity);
}
