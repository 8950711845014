import { IconButton } from '@kontent-ai/component-library/Button';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState, InputType } from '@kontent-ai/component-library/Input';
import {
  ICancellablePromise,
  delay,
  noOperation,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly readonly?: boolean;
  readonly errorMessage: string | undefined;
  readonly inputId: Uuid;
  readonly onChange: (value: string) => void;
  readonly onGenerateNewSecret: (onNewSecret: (secret: string | undefined) => void) => () => void;
  readonly value: string | undefined;
};

export const SecretField = forwardRef<HTMLInputElement, Props>(
  ({ readonly, errorMessage, inputId, onChange, onGenerateNewSecret, value }, ref) => {
    const showMessageDelayRef = useRef<ICancellablePromise>();

    const [currentMessage, setCurrentMessage] = useState<string | null>(null);
    const [secretGenerationError, setSecretGenerationError] = useState<string | null>(null);

    const onShowMessage = useCallback((message: string, hideAfter: number = 1000): void => {
      setCurrentMessage(message);

      showMessageDelayRef.current?.cancel();
      showMessageDelayRef.current = delay(hideAfter)
        .then(() => {
          setCurrentMessage(null);
        })
        .catch(swallowCancelledPromiseError);
    }, []);

    const handleErrorDuringGeneration = useCallback(() => {
      setSecretGenerationError(
        'I wasn’t able to generate new secret for you. Please contact us for help.',
      );
      setCurrentMessage(null);
    }, []);

    const generateNewSecret = useCallback((): (() => void) => {
      setCurrentMessage('Generating secret...');
      setSecretGenerationError('');

      try {
        const cancel = onGenerateNewSecret((secret) => {
          if (secret) {
            onChange(secret);
            onShowMessage('New secret generated!');
          } else {
            handleErrorDuringGeneration();
          }
        });

        return cancel;
      } catch {
        handleErrorDuringGeneration();
        return noOperation;
      }
    }, [handleErrorDuringGeneration, onGenerateNewSecret, onShowMessage, onChange]);

    useEffect(() => {
      if (value) return;
      return generateNewSecret();
    }, [generateNewSecret, value]);

    useEffect(() => {
      return showMessageDelayRef.current?.cancel;
    }, []);

    return (
      <EnterHotkeyDisabler>
        <Clipboard
          auxiliaryElement={
            <IconButton
              buttonState={readonly ? 'disabled' : 'default'}
              buttonStyle="tertiary"
              iconName="RotateDoubleRight"
              onClick={generateNewSecret}
              size="medium"
              tooltipPlacement="top-start"
              tooltipText="Generate new secret"
              {...getDataUiActionAttribute(DataUiAction.Regenerate)}
            />
          }
          caption={errorMessage || secretGenerationError || ''}
          id={inputId}
          inputState={
            errorMessage || secretGenerationError ? InputState.Alert : InputState.ReadOnly
          }
          overlayMessage={currentMessage || ''}
          ref={ref}
          type={InputType.Password}
          value={value || ''}
          {...getDataUiInputAttribute(DataUiInput.Secret)}
        />
      </EnterHotkeyDisabler>
    );
  },
);
