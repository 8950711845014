import React, { memo, useContext } from 'react';
import { ContentNestingContext } from '../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { CreateContentGroupTabsId } from '../../../../../itemEditor/features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { ContentOverlay } from '../../../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import { ExpandedItemElements } from '../../../../../itemEditor/features/LinkedItems/containers/ExpandedItemElements.tsx';
import {
  getContentOverlayClass,
  getContentOverlayId,
} from '../../../../editorCore/utils/editorComponentUtils.ts';
import { ExpandedBlockStyles } from '../../../customBlocks/components/ExpandedBlockStyles.tsx';

interface IExpandedModularContentProps {
  readonly blockKey: string;
  readonly contentItemId: Uuid;
  readonly editorId: string;
  readonly isDragging: boolean;
  readonly isHighlighted: boolean;
}

export const ExpandedModularContent: React.FC<IExpandedModularContentProps> = memo(
  ({ blockKey, contentItemId, editorId, isDragging, isHighlighted }) => {
    const { nestedLevel } = useContext(ContentNestingContext);
    const contentGroupTabsId = CreateContentGroupTabsId.forRichTextLinkedItem(
      blockKey,
      contentItemId,
      nestedLevel,
    );

    return (
      <ContentOverlay
        className={getContentOverlayClass(editorId)}
        // We need to disable mouse interaction of ContentOverlay in order to prevent incorrect evaluation of mouse position
        // due to different DOM placement. This is needed for dragging and making selection with a mouse
        ignoreMouseEvents={isDragging}
        key={blockKey}
        overlayId={getContentOverlayId(editorId, blockKey)}
        renderContent={() => (
          <>
            <ExpandedItemElements
              contentGroupTabsId={contentGroupTabsId}
              contentItemId={contentItemId}
              isHighlighted={isHighlighted}
            />
            <ExpandedBlockStyles editorId={editorId} blockKey={blockKey} />
          </>
        )}
      />
    );
  },
);
ExpandedModularContent.displayName = 'ExpandedModularContent';
