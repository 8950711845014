import { Callout } from '@kontent-ai/component-library/Callout';
import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export const UnsupportedAssetFileTypeModal: React.FC = () => {
  const dispatch = useDispatch();
  const closeDialog = () => dispatch(modalDismissed());

  return (
    <ModalDialog
      isOpen
      isDismissable
      onClose={closeDialog}
      headline="File type not permitted"
      minWidth={px(50 * gridUnit)}
      cancelAction={{
        title: 'Close',
        onClick: closeDialog,
        ...getDataUiActionAttribute(DataUiAction.CloseDialog),
      }}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <Callout
        calloutType="warning"
        headline="Prohibited file type"
        hideSubheadline
        {...getDataUiElementAttribute(DataUiElement.CalloutWarning)}
      >
        Free plans can only upload DOCX, GIF, JPG, JSON, MP4, PNG, WebP, XSLX, or ZIP files to
        Kontent.ai.
      </Callout>
    </ModalDialog>
  );
};

UnsupportedAssetFileTypeModal.displayName = 'UnsupportedAssetFileTypeModal';
