import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { loadCollections, loadContentTypes } from '../../../../../data/actions/thunkDataActions.ts';
import { UiPreference } from '../../../../../repositories/uiPreferenceRepository.ts';
import {
  newContentItemDialogPropertiesForContentComponent,
  newContentItemDialogPropertiesForContentStatus,
  newContentItemDialogPropertiesForLinkedItems,
  newContentItemDialogPropertiesForListing,
  newContentItemDialogPropertiesForProjectOverview,
  newContentItemDialogPropertiesForSpaces,
  newContentItemDialogPropertiesForWebSpotlight,
  newContentItemDialogPropertiesForYourContent,
} from '../constants/newContentItemDialogProperties.ts';
import {
  getCollectionSelectorConfigForContentComponent,
  getCollectionSelectorConfigForLinkedItems,
  getCollectionSelectorConfigForListing,
  getCollectionSelectorConfigForSpaces,
  getCollectionSelectorConfigForWebSpotlight,
} from '../selectors/getCollectionSelectorConfig.ts';
import {
  getContentTypeSelectorConfigForEditing,
  getContentTypeSelectorConfigForListing,
  getContentTypeSelectorConfigForSpaces,
  getContentTypeSelectorConfigForWebSpotlight,
} from '../selectors/getContentTypeSelectorConfig.ts';
import {
  getInitialSelectedCollectionDataForEditing,
  getInitialSelectedCollectionDataForListing,
} from '../selectors/getInitialSelectedCollectionData.ts';
import { getInitialSelectedLanguageIdForYourContent } from '../selectors/getInitialSelectedLanguageId.ts';
import {
  recentlyUsedCollectionsForEditingLoaded,
  recentlyUsedCollectionsForListingLoaded,
  recentlyUsedContentTypesForEditingLoaded,
  recentlyUsedContentTypesForListingLoaded,
} from './newContentItemActions.ts';
import { createContentItemActionCreator } from './thunks/createContentItem.ts';
import { createNewContentItemActionCreator } from './thunks/createNewContentItem.ts';
import { createNewLinkedItemActionCreator } from './thunks/createNewLinkedItem.ts';
import { createLoadContentItemUiPreferenceAction } from './thunks/loadContentItemUiPreference.ts';
import { createPrepareNewContentItemDialogAction } from './thunks/prepareNewContentItemDialog.ts';
import { createPrepareNewContentItemVariantInitFromNewContentItemFormAction } from './thunks/prepareNewContentItemVariantInitFromNewContentItemForm.ts';
import { createUpdateNewContentItemUiPreferenceAction } from './thunks/updateNewContentItemUiPreference.ts';
import { createValidateSelectedValuesAction } from './thunks/validateSelectedValues.ts';

const { contentItemRepository, uiPreferenceRepository } = repositoryCollection;

const loadCollectionsPreferenceForEditing = createLoadContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.collectionsRecentlyUsedInEditing,
  uiPreference: UiPreference.collectionsRecentlyUsedInEditing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedCollectionsForEditingLoaded,
});

const loadCollectionsPreferenceForListing = createLoadContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.collectionsRecentlyUsedInListing,
  uiPreference: UiPreference.collectionsRecentlyUsedInListing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedCollectionsForListingLoaded,
});

const loadTypesPreferenceForEditing = createLoadContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.contentTypesRecentlyUsedInEditing,
  uiPreference: UiPreference.contentTypesRecentlyUsedInEditing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedContentTypesForEditingLoaded,
});

const loadTypesPreferenceForListing = createLoadContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.contentTypesRecentlyUsedInListing,
  uiPreference: UiPreference.contentTypesRecentlyUsedInListing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedContentTypesForListingLoaded,
});

export const updateCollectionsPreferenceForEditing = createUpdateNewContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.collectionsRecentlyUsedInEditing,
  uiPreference: UiPreference.collectionsRecentlyUsedInEditing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedCollectionsForEditingLoaded,
});

export const updateCollectionsPreferenceForListing = createUpdateNewContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.collectionsRecentlyUsedInListing,
  uiPreference: UiPreference.collectionsRecentlyUsedInListing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedCollectionsForListingLoaded,
});

export const updateTypesPreferenceForEditing = createUpdateNewContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.contentTypesRecentlyUsedInEditing,
  uiPreference: UiPreference.contentTypesRecentlyUsedInEditing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedContentTypesForEditingLoaded,
});

export const updateTypesPreferenceForListing = createUpdateNewContentItemUiPreferenceAction({
  getCurrentPreference: (state) => state.sharedApp.uiPreferences.contentTypesRecentlyUsedInListing,
  uiPreference: UiPreference.contentTypesRecentlyUsedInListing,
  uiPreferenceRepository,
  updateNewPreference: recentlyUsedContentTypesForListingLoaded,
});

export const prepareNewContentItemDialogForLinkedItems = createPrepareNewContentItemDialogAction({
  dialogProperties: newContentItemDialogPropertiesForLinkedItems,
  getCollectionSelectorConfig: getCollectionSelectorConfigForLinkedItems,
  getContentTypeSelectorConfig: getContentTypeSelectorConfigForEditing,
  getInitialCollectionData: getInitialSelectedCollectionDataForEditing,
  loadCollections,
  loadCollectionsPreference: loadCollectionsPreferenceForEditing,
  loadContentTypes,
  loadTypesPreference: loadTypesPreferenceForEditing,
});

export const prepareNewContentItemDialogForContentComponent =
  createPrepareNewContentItemDialogAction({
    dialogProperties: newContentItemDialogPropertiesForContentComponent,
    getCollectionSelectorConfig: getCollectionSelectorConfigForContentComponent,
    getContentTypeSelectorConfig: getContentTypeSelectorConfigForEditing,
    getInitialCollectionData: getInitialSelectedCollectionDataForEditing,
    loadCollections,
    loadContentTypes,
    loadTypesPreference: loadTypesPreferenceForEditing,
  });

export const prepareNewContentItemDialogForWebSpotlight = createPrepareNewContentItemDialogAction({
  dialogProperties: newContentItemDialogPropertiesForWebSpotlight,
  getCollectionSelectorConfig: getCollectionSelectorConfigForWebSpotlight,
  getContentTypeSelectorConfig: getContentTypeSelectorConfigForWebSpotlight,
  loadCollections,
  loadContentTypes,
});

export const prepareNewContentItemDialogForSpaces = createPrepareNewContentItemDialogAction({
  dialogProperties: newContentItemDialogPropertiesForSpaces,
  getCollectionSelectorConfig: getCollectionSelectorConfigForSpaces,
  getContentTypeSelectorConfig: getContentTypeSelectorConfigForSpaces,
  loadCollections,
  loadContentTypes,
});

export const prepareNewContentItemDialogForListing = createPrepareNewContentItemDialogAction({
  dialogProperties: newContentItemDialogPropertiesForListing,
  getCollectionSelectorConfig: getCollectionSelectorConfigForListing,
  getContentTypeSelectorConfig: getContentTypeSelectorConfigForListing,
  getInitialCollectionData: getInitialSelectedCollectionDataForListing,
  loadCollections,
  loadCollectionsPreference: loadCollectionsPreferenceForListing,
  loadContentTypes,
  loadTypesPreference: loadTypesPreferenceForListing,
});

export const prepareNewContentItemDialogForContentStatus = createPrepareNewContentItemDialogAction({
  dialogProperties: newContentItemDialogPropertiesForContentStatus,
  getCollectionSelectorConfig: getCollectionSelectorConfigForListing,
  getContentTypeSelectorConfig: getContentTypeSelectorConfigForListing,
  getInitialLanguageId: getInitialSelectedLanguageIdForYourContent,
  loadCollections,
  loadCollectionsPreference: loadCollectionsPreferenceForListing,
  loadContentTypes,
  loadTypesPreference: loadTypesPreferenceForListing,
});

export const prepareNewContentItemDialogForYourContent = createPrepareNewContentItemDialogAction({
  dialogProperties: newContentItemDialogPropertiesForYourContent,
  getCollectionSelectorConfig: getCollectionSelectorConfigForListing,
  getContentTypeSelectorConfig: getContentTypeSelectorConfigForListing,
  getInitialLanguageId: getInitialSelectedLanguageIdForYourContent,
  loadCollections,
  loadCollectionsPreference: loadCollectionsPreferenceForListing,
  loadContentTypes,
  loadTypesPreference: loadTypesPreferenceForListing,
});

export const prepareNewContentItemDialogForProjectOverview =
  createPrepareNewContentItemDialogAction({
    dialogProperties: newContentItemDialogPropertiesForProjectOverview,
    getCollectionSelectorConfig: getCollectionSelectorConfigForListing,
    getContentTypeSelectorConfig: getContentTypeSelectorConfigForListing,
    getInitialCollectionData: getInitialSelectedCollectionDataForListing,
    loadCollections,
    loadCollectionsPreference: loadCollectionsPreferenceForListing,
    loadContentTypes,
    loadTypesPreference: loadTypesPreferenceForListing,
  });

const createContentItemDependencies = {
  contentItemRepository,
};
export const createContentItem = createContentItemActionCreator(createContentItemDependencies);

export const createNewLinkedItem = createNewLinkedItemActionCreator({
  createContentItem,
  updateCollectionsPreference: updateCollectionsPreferenceForEditing,
  updateTypesPreference: updateTypesPreferenceForEditing,
});

export const createNewContentItem = createNewContentItemActionCreator({
  createContentItem,
  updateCollectionsPreference: updateCollectionsPreferenceForListing,
  updateTypesPreference: updateTypesPreferenceForListing,
});

export const validateSelectedValues = createValidateSelectedValuesAction();

export const prepareNewContentItemVariantInitFromNewContentItemForm =
  createPrepareNewContentItemVariantInitFromNewContentItemFormAction();
