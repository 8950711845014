import styled from 'styled-components';
import { colorOverlayBackground } from '../../../tokens/decision/colors.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { ZIndex } from '../../../tokens/quarks/zIndex.ts';

interface IStyledOverlay {
  readonly rect: DOMRectReadOnly;
}

const getPolygonPath = (rect: DOMRectReadOnly) => {
  const top = rect.top - Spacing.S;
  const left = rect.left - Spacing.S;
  const bottom = rect.bottom + Spacing.S;
  const right = rect.right + Spacing.S;

  return `polygon(0% 0%, 0% 100%, ${left}px 100%, ${left}px ${top}px, ${right}px ${top}px, ${right}px ${bottom}px, ${left}px ${bottom}px, ${left}px 100%, 100% 100%, 100% 0%)`;
};

export const StyledClippedOverlay = styled.div.attrs((props: IStyledOverlay) => ({
  style: {
    clipPath: getPolygonPath(props.rect),
  },
}))<IStyledOverlay>`
  background-color: ${colorOverlayBackground};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.TwoThousandFourHundred};
  display: block;
`;
