import classNames from 'classnames';
import React from 'react';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';

export interface IDefaultLanguageBarDataProps {
  readonly defaultLanguage: ILanguage;
  readonly canBeEdited: boolean;
}

export interface IDefaultLanguageBarCallbacksProps {
  readonly onEdit: () => void;
}

export const DefaultLanguageBar: React.FC<
  IDefaultLanguageBarDataProps & IDefaultLanguageBarCallbacksProps
> = ({ defaultLanguage, canBeEdited, onEdit }) => (
  <div className="bar-item__wrapper">
    <div className="bar-item__pane">
      <div
        className={classNames('bar-item__bar', {
          'bar-item__bar--is-clickable': canBeEdited,
        })}
      >
        <BarItemActions>
          <div className={classNames('bar-item__action', 'bar-item__action--is-disabled')} />
        </BarItemActions>
        <div className="bar-item__title-pane" onClick={canBeEdited ? onEdit : undefined}>
          <div className="bar-item__title">{defaultLanguage.name}</div>
          <div className="bar-item__title">
            <div className="language-settings__subtitle">Default language</div>
          </div>
        </div>
        <BarItemActions>
          <BarItemEditAction onClick={onEdit} disabled={!canBeEdited} />
        </BarItemActions>
      </div>
    </div>
  </div>
);

DefaultLanguageBar.displayName = 'DefaultLanguageBar';
