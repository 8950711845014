import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ContentTypeSnippet_Listing_InitFinished,
  ContentTypeSnippet_Listing_InitStarted,
} from '../../constants/snippetActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case ContentTypeSnippet_Listing_InitStarted:
      return LoadingStatus.Loading;
    case ContentTypeSnippet_Listing_InitFinished:
      return LoadingStatus.Loaded;
    default:
      return state;
  }
};
