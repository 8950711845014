import classNames from 'classnames';
import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isImageMimeType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { Languages } from '../../../../../data/models/languages/Language.ts';
import { LanguageContextProvider } from '../../../../itemEditor/features/ContentComponent/context/LanguageContext.tsx';
import { ICompiledContentItemElementData } from '../../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import { AssetCollection } from '../../containers/AssetCollection.tsx';
import { AssetPreview } from './AssetPreview.tsx';
import { AssetTitle } from './AssetTitle.tsx';
import { AssetDescriptions } from './Description/AssetDescriptions.tsx';
import { AssetElements } from './Elements/AssetElements.tsx';

const updateElementData = (
  asset: IAsset,
  updatedElement: ICompiledContentItemElementData,
): IAsset => ({
  ...asset,
  nonLocalizableElements: [
    ...asset.nonLocalizableElements.filter((e) => e.elementId !== updatedElement.elementId),
    updatedElement,
  ],
});

type AssetEditorProps = {
  readonly allLanguages: Languages;
  readonly asset: IAsset;
  readonly canUpdateAssets: boolean;
  readonly isAssetCollectionVisible: boolean;
  readonly isLanguageDescriptionEditableMap: Immutable.Map<Uuid, boolean>;
  readonly isModalAssetEditor: boolean;
  readonly languagesToShow: Languages;
  readonly onChange: (updatedAsset: IAsset) => void;
};

export const AssetEditor: React.FC<AssetEditorProps> = ({
  allLanguages,
  asset,
  canUpdateAssets,
  isAssetCollectionVisible,
  isLanguageDescriptionEditableMap,
  isModalAssetEditor,
  languagesToShow,
  onChange,
}) => {
  const onChangeDescription = useCallback(
    (variantId: Uuid, newDescription: string, isAiGenerated: boolean): void => {
      const updatedDescriptions = asset.descriptions.set(variantId, newDescription);
      const updatedAsset: IAsset = {
        ...asset,
        descriptions: updatedDescriptions,
        ...(isAiGenerated ? { _aiDescription: newDescription } : undefined),
      };

      onChange(updatedAsset);
    },
    [asset, onChange],
  );

  const onChangeElement = useCallback(
    (updatedElement: ICompiledContentItemElementData): void => {
      onChange(updateElementData(asset, updatedElement));
    },
    [asset, onChange],
  );

  const isImage = isImageMimeType(asset.type);

  return (
    <LanguageContextProvider languageId={DefaultVariantId}>
      <div
        className={classNames('asset-library-pane__editor-content', {
          'asset-library-pane__editor-content--is-disabled': !canUpdateAssets,
        })}
        {...getDataUiCollectionAttribute(DataUiCollection.AssetDescriptions)}
      >
        <AssetTitle
          asset={asset}
          canUpdateAssets={canUpdateAssets}
          isInModal={isModalAssetEditor}
          onChange={onChange}
        />
        <AssetPreview asset={asset} />
        {isAssetCollectionVisible && <AssetCollection isDisabled={!canUpdateAssets} />}
        <AssetDescriptions
          assetId={asset.id}
          allLanguages={allLanguages}
          canUpdateAssets={canUpdateAssets}
          descriptions={asset.descriptions}
          isImage={isImage}
          isLanguageDescriptionEditableMap={isLanguageDescriptionEditableMap}
          languagesToShow={languagesToShow}
          onChange={onChangeDescription}
        />
        <AssetElements
          disabled={!canUpdateAssets}
          elements={asset.nonLocalizableElements}
          onChange={onChangeElement}
        />
      </div>
    </LanguageContextProvider>
  );
};
