import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';

type IconProps = IForwardedRefProps<HTMLElement> & {
  readonly iconName: IconName;
  readonly className?: string;
  readonly screenReaderText?: string;
  readonly dataAttribute?: ObjectWithDataAttribute;
  readonly onClick?: React.MouseEventHandler<HTMLElement>;
};

const IconComponent: React.FC<IconProps> = (props) => (
  <>
    <i
      ref={props.forwardedRef}
      aria-hidden
      className={classNames(props.className, props.iconName)}
      onClick={props.onClick}
      {...props.dataAttribute}
    />
    {!!props.screenReaderText && <div className="sr-only">{props.screenReaderText}</div>}
  </>
);

IconComponent.displayName = 'Icon';

export const Icon = forwardRef(IconComponent);
