import { StatusMessageTypes } from '../constants/statusMessageType.ts';

export type StatusMessage = {
  readonly messageType: StatusMessageTypes;
};

export const DefaultStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Saved };
export const DeletingStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Deleting };
export const InvalidStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Invalid };
export const OfflineStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Offline };
export const SavedStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Saved };
export const SavingStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Saving };
export const ServerErrorStatusMessage: StatusMessage = {
  messageType: StatusMessageTypes.ServerError,
};
export const UnsavedStatusMessage: StatusMessage = { messageType: StatusMessageTypes.Unsaved };
export const UploadingAssetStatusMessage: StatusMessage = {
  messageType: StatusMessageTypes.UploadingAsset,
};

const errorStatuses: ReadonlySet<StatusMessageTypes> = new Set([
  StatusMessageTypes.Invalid,
  StatusMessageTypes.Offline,
  StatusMessageTypes.ServerError,
]);

export const isErrorStatus = (status: StatusMessageTypes): boolean => errorStatuses.has(status);
