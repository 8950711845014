import { memoize } from '@kontent-ai/memoization';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { OrderBy, OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { UserListingColumnCode } from '../reducers/IUsersAppState.type.ts';
import { filterUsers } from '../selectors/filterUsers.ts';
import { sortUsers } from '../utils/sortUsers.ts';

const getDisplayedUsers = memoize.maxOne(
  (
    filteredUsers: ReadonlyArray<IProjectContributor>,
    sortBy: OrderBy<UserListingColumnCode>,
  ): ReadonlyArray<IProjectContributor> => {
    const sortedUsers = sortUsers(filteredUsers, sortBy.columnCode);

    return sortBy.direction === OrderByDirection.Ascending
      ? sortedUsers
      : [...sortedUsers].reverse();
  },
);

const getNonVirtualProjectContributors = (
  projectContributors: ReadonlyMap<Uuid, IProjectContributor>,
) =>
  new Map<Uuid, IProjectContributor>(
    Array.from(projectContributors).filter(
      ([, projectContributor]) => !projectContributor.isVirtual,
    ),
  );

export const useUserListingDisplayedUsers = (): ReadonlyArray<IProjectContributor> => {
  return useSelector((s) =>
    getDisplayedUsers(
      filterUsers(
        s.data.languages,
        s.data.collections.byId,
        getNonVirtualProjectContributors(s.usersApp.projectContributors),
        s.usersApp.filter,
      ),
      s.usersApp.sortBy,
    ),
  );
};
