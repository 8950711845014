import { useLocation } from 'react-router';
import { selectSpace } from '../../applications/webSpotlight/actions/thunkWebSpotlightActions.ts';
import { ISpace } from '../../data/models/space/space.ts';
import { IUserProjectInfo, emptyUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import {
  getCurrentProject,
  getCurrentProjectContainer,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { trackUserEventWithData } from '../actions/thunks/trackUserEvent.ts';
import { ProjectMenu as ProjectMenuComponent } from '../components/ProjectMenu/ProjectMenu.tsx';
import {
  ContentItemRoute,
  ContentItemRouteParams,
  WebSpotlightRootRoute,
} from '../constants/routePaths.ts';
import { TrackedEvent } from '../constants/trackedEvent.ts';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { LoadingStatus } from '../models/LoadingStatusEnum.ts';
import { getSelectedLanguageId } from '../selectors/getSelectedLanguageId.ts';
import { getEnvironmentItems, getEnvironments } from '../utils/environments/environmentUtils.ts';
import { Capability } from '../utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../utils/permissions/capabilityUtils.ts';
import { getProjectItems, getProjects } from '../utils/projects/projectUtils.ts';
import {
  shouldHideEnvironmentMenu,
  shouldHideProjectMenu,
} from '../utils/routing/projectMenuUtils.ts';
import { matchPath } from '../utils/routing/routeTransitionUtils.ts';
import { getSpacesItems, manageSpacesItemId } from '../utils/spaces/spaceSelectorUtils.ts';

export const ProjectMenu = () => {
  const location = useLocation();
  const currentUserProjectInfo = useSelector((s) =>
    getCurrentProject(s) === emptyUserProjectInfo
      ? getCurrentProjectContainer(s)
      : getCurrentProject(s),
  );

  if (
    shouldHideProjectMenu(location.pathname) ||
    !currentUserProjectInfo.projectId ||
    !currentUserProjectInfo.projectContainerId
  ) {
    return null;
  }

  return <VisibleProjectMenu currentUserProjectInfo={currentUserProjectInfo} />;
};

type Props = {
  readonly currentUserProjectInfo: IUserProjectInfo;
};

const VisibleProjectMenu = (props: Props) => {
  const location = useLocation();
  const environments = useSelector((s) => getEnvironments(s, location.pathname));
  const projects = useSelector((s) => getProjects(s, location.pathname));

  const spaces = useSelector((s) =>
    s.data.spaces.loadingStatus !== LoadingStatus.InitialEmpty ? s.data.spaces.byId : null,
  );

  const variantId = useSelector((s) => getSelectedLanguageId(s) ?? '');
  const currentSpaceId = useSelector((s) => s.webSpotlightApp.spaceId);
  const canManageSpaces = useSelector((s) =>
    currentUserHasCapabilities(s, Capability.ManageSpaces),
  );

  const dispatch = useDispatch();

  const { projectId: currentEnvironmentId, projectContainerId: currentProjectContainerId } =
    props.currentUserProjectInfo;

  const projectItems = getProjectItems(projects, currentProjectContainerId);

  const shouldShowEnvironmentSelect = !shouldHideEnvironmentMenu(location.pathname);
  const environmentItems = shouldShowEnvironmentSelect
    ? getEnvironmentItems(environments, currentEnvironmentId, currentProjectContainerId)
    : [];

  const isAtValidRouteForSpaceSelector = isValidWebSpotlightRoute(location.pathname);

  const onSpaceClick = async (space: ISpace, path: string) => {
    dispatch(
      trackUserEventWithData(TrackedEvent.SpaceWebSpotlightSwitchSpace, { 'space-id': space.id }),
    );
    await dispatch(selectSpace(space.id, space.webSpotlightRootItemId, path));
  };

  const spacesItems =
    isAtValidRouteForSpaceSelector && currentSpaceId && spaces && currentEnvironmentId
      ? getSpacesItems(
          spaces,
          currentSpaceId,
          currentEnvironmentId,
          variantId,
          canManageSpaces,
          onSpaceClick,
        )
      : null;

  const selectedSpaceName =
    spacesItems?.find((space) => space.id === currentSpaceId)?.label ?? null;
  const shouldShowSpaceSelect =
    !!selectedSpaceName &&
    (spacesItems?.filter((spaceItem) => spaceItem.id !== manageSpacesItemId)?.length ?? 0) >= 2;

  return (
    <ProjectMenuComponent
      environmentId={currentEnvironmentId}
      environmentItems={environmentItems}
      environmentName={props.currentUserProjectInfo.environmentName}
      productionId={props.currentUserProjectInfo.masterEnvironmentId}
      projectItems={projectItems}
      projectName={props.currentUserProjectInfo.projectName}
      shouldShowEnvironmentSelect={shouldShowEnvironmentSelect}
      shouldShowSpaceSelect={shouldShowSpaceSelect}
      spaceItems={spacesItems}
      spaceName={selectedSpaceName}
    />
  );
};

const isValidWebSpotlightRoute = (locationPath: string): boolean => {
  if (matchPath(locationPath, WebSpotlightRootRoute)) {
    return true;
  }

  const itemRouteMatch = matchPath<ContentItemRouteParams<string>>(locationPath, ContentItemRoute);

  return itemRouteMatch?.app === 'web-spotlight';
};
