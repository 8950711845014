import React from 'react';
import { INumberTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/NumberTypeElement.ts';
import { INumberItemElement } from '../../../models/contentItemElements/NumberItemElement.ts';
import { NumberDiff } from '../../Revisions/components/elements/subelements/NumberDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedNumberElementProps = ExpandedItemElementDataProps<
  INumberTypeElement,
  INumberItemElement
>;

export const ExpandedNumberElement: React.FC<ExpandedNumberElementProps> = ({
  elementData,
  originalElementData,
  typeElement,
  className,
}) => (
  <ExpandedItemElement
    className={className}
    disabled
    hideValidationStatus
    typeElement={typeElement}
  >
    <NumberDiff
      elementData={elementData}
      originalElementData={originalElementData}
      typeElement={typeElement}
    />
  </ExpandedItemElement>
);

ExpandedNumberElement.displayName = 'ExpandedNumberElement';
