import { format } from 'date-fns';
import { joinWithOxfordComma, pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { ISubscriptionUserViewModel } from '../components/SubscriptionUsersScrollTableRow.tsx';

export const dateWeStartedTrackingLoginsTimestamp = '2021-08-04T00:00:00.000Z';
const userAssignmentMaxEntityCountInTooltip = 10;

const lastLoginIsMoreThanYearAgo = (latestLastLoginDateTimeString: string): boolean =>
  (Date.now() - new Date(latestLastLoginDateTimeString).getTime()) / (1000 * 3600 * 24) > 365;

export const getLastActivityFieldText = (
  latestLastLoginDateTimeString: string | null,
  isInvitationPending: boolean,
  showTime = false,
): string => {
  if (isInvitationPending) {
    return 'Not joined yet';
  }

  if (!latestLastLoginDateTimeString || lastLoginIsMoreThanYearAgo(latestLastLoginDateTimeString)) {
    return 'Over a year ago';
  }

  return format(
    new Date(latestLastLoginDateTimeString),
    `MMM d, yyyy${showTime ? "' at' hh:mm" : ''}`,
  );
};

export const getUserAssignmentCellText = (
  entityNames: ReadonlyArray<string>,
  entityType: string,
): string =>
  entityNames.length === 1
    ? (entityNames[0] ?? '')
    : pluralizeWithCount(entityType, entityNames.length);

export const getUserAssignmentCellTooltip = (entityNames: ReadonlyArray<string>): string =>
  entityNames.length <= userAssignmentMaxEntityCountInTooltip
    ? joinWithOxfordComma(entityNames)
    : `${entityNames.slice(0, userAssignmentMaxEntityCountInTooltip).join(', ')} and ${
        entityNames.length - userAssignmentMaxEntityCountInTooltip
      } more`;

export const getSwitchUserStatusTooltip = (user: ISubscriptionUserViewModel): string => {
  if (user.isInvitationPending) {
    return 'This user has not completed their account registration yet. Open their user detail to manage their status across projects.';
  }

  if (user.isSubscriptionAdmin) {
    return 'This user is a subscription admin. If you need to deactivate them, go to Admins and remove them from the list of admins.';
  }

  return `${user.isActiveInAllProjects ? 'Deactivate' : 'Activate'} user in all projects.`;
};
