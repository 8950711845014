import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';

export type TaxonomyOption = ITaxonomyGroup & ISelectItem<TaxonomyOption>;

export const mapTaxonomyToOption = (taxonomy: ITaxonomyGroup): TaxonomyOption => {
  return {
    ...taxonomy,
    label: taxonomy.name,
  };
};
