import {
  Subscription_AddNewSubscriptionAdmin_ShowModal,
  Subscription_AdminListing_Select,
  Subscription_AdminListing_Unselect,
  Subscription_PromoteAdmin_Canceled,
  Subscription_PromoteExistingUsers_ShowModal,
  Subscription_SubscriptionAdminInvitation_Canceled,
  Subscription_SubscriptionAdminInvitation_Failed,
} from '../constants/actionTypes.ts';

export const showAddNewSubscriptionAdminModal = () =>
  ({
    type: Subscription_AddNewSubscriptionAdmin_ShowModal,
  }) as const;

export const showPromoteUsersModal = () =>
  ({
    type: Subscription_PromoteExistingUsers_ShowModal,
  }) as const;

export const cancelInvitingNewAdmin = () =>
  ({
    type: Subscription_SubscriptionAdminInvitation_Canceled,
  }) as const;

export const failInvitingNewAdmin = (errorMessage: string) =>
  ({
    type: Subscription_SubscriptionAdminInvitation_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export const cancelPromotingUsers = () =>
  ({
    type: Subscription_PromoteAdmin_Canceled,
  }) as const;

export const selectAdmin = (userId: string) =>
  ({
    type: Subscription_AdminListing_Select,
    payload: {
      userId,
    },
  }) as const;

export const unselectAdmin = (userId: string) =>
  ({
    type: Subscription_AdminListing_Unselect,
    payload: {
      userId,
    },
  }) as const;
