import React from 'react';
import { IAssetTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import { IAssetItemElement } from '../../../models/contentItemElements/AssetItemElement.ts';
import { AssetComponent } from '../../ContentItemEditing/containers/elements/asset/AssetComponent.tsx';
import { IContentComponentItemElementOwnProps } from '../IContentComponentItemElementOwnProps.type.ts';
import { ContentComponentItemElement } from '../containers/ContentComponentItemElement.tsx';

interface IContentComponentAssetElement
  extends IContentComponentItemElementOwnProps<IAssetItemElement, IAssetTypeElement> {}

export const ContentComponentAssetElement: React.FC<IContentComponentAssetElement> = ({
  typeElement,
  disabled,
  elementData,
  validationResultSelectorId,
  contentComponentId,
  onUpdate,
}) => {
  const renderWrapperElement = (innerComponent: JSX.Element) => (
    <ContentComponentItemElement
      contentComponentId={contentComponentId}
      disabled={disabled}
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
    >
      {innerComponent}
    </ContentComponentItemElement>
  );

  return (
    <AssetComponent
      contentComponentId={contentComponentId}
      disabled={disabled}
      elementData={elementData}
      onChange={onUpdate}
      renderWrapper={renderWrapperElement}
      typeElement={typeElement}
      validationResultSelectorId={validationResultSelectorId}
    />
  );
};

ContentComponentAssetElement.displayName = 'ContentComponentAssetElement';
