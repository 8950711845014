import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { ContentItemEditing_ContentGroup_Selected } from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: Immutable.Map<Uuid, Uuid | null> = Immutable.Map<Uuid, Uuid | null>();

export function selectedContentGroups(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, Uuid | null> {
  switch (action.type) {
    case ContentItemEditing_ContentGroup_Selected: {
      const { contentGroupTabsId, contentGroupId } = action.payload;
      return state.set(contentGroupTabsId, contentGroupId);
    }
    default: {
      return state;
    }
  }
}
