import {
  SubscriptionManagement_RouteEntered,
  SubscriptionManagement_RouteLeft,
  Subscription_SelectSubscription_Clear,
  Subscription_SubscriptionUsers_Changed,
} from '../constants/subscriptionManagementActionTypes.ts';

export const clearSelectedSubscription = () =>
  ({
    type: Subscription_SelectSubscription_Clear,
  }) as const;

export const changeSelectedSubscriptionUsers = (userIds: ReadonlySet<UserId>) =>
  ({
    type: Subscription_SubscriptionUsers_Changed,
    payload: {
      userIds,
    },
  }) as const;

export const subscriptionManagementRouteEntered = () =>
  ({
    type: SubscriptionManagement_RouteEntered,
  }) as const;

export const subscriptionManagementRouteLeft = () =>
  ({
    type: SubscriptionManagement_RouteLeft,
  }) as const;
