import { createGuid } from '@kontent-ai/utils';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { initializeRequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import { loadLanguages } from '../../../../data/actions/thunkDataActions.ts';
import { getFilterStorage } from '../../../../localStorages/getFilterStorage.ts';
import { trackFindRightContentInInventory } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/trackFindRightContentInInventory.ts';
import { buildFilterWithContinuationForContentStatusListing } from '../utils/buildFilterWithContinuationForContentStatusListing.ts';
import {
  contentStatusCommentCountsLoaded,
  contentStatusCommentCountsStarted,
  contentStatusTaskCountsLoaded,
  contentStatusTaskCountsStarted,
} from './commentOrTasks.ts';
import { createFilterContentItemsAction } from './thunks/filterContentItems.ts';
import { createInitializeContentStatusAction } from './thunks/initializeContentStatus.ts';
import { createLoadContentStatusCommentOrTaskCountsAction } from './thunks/loadContentStatusCommentOrTaskCounts.ts';
import { createLoadContentStatusContentItemsForInventoryAction } from './thunks/loadContentStatusContentItemForInventory.ts';
import {
  createLoadContentStatusContentItemsAction,
  createTokenizedContentStatusItemsLoadingStarted,
} from './thunks/loadContentStatusContentItems.ts';

const { missionControlRepository } = repositoryCollection;

const listingRequestTokenFactory = initializeRequestTokenFactory(
  (state) => state.data.contentStatusContentItems.raceConditionToken,
  createTokenizedContentStatusItemsLoadingStarted,
  createGuid,
);

export const loadContentStatusCommentCounts = createLoadContentStatusCommentOrTaskCountsAction({
  getCommentOrTaskCounts: (contentItemIds, abortSignal) =>
    missionControlRepository.getUnresolvedCommentCounts(contentItemIds, abortSignal),
  startAction: contentStatusCommentCountsStarted,
  successAction: contentStatusCommentCountsLoaded,
});

export const loadContentStatusTaskCounts = createLoadContentStatusCommentOrTaskCountsAction({
  getCommentOrTaskCounts: (contentItemIds, abortSignal) =>
    missionControlRepository.getUnresolvedTaskCounts(contentItemIds, abortSignal),
  startAction: contentStatusTaskCountsStarted,
  successAction: contentStatusTaskCountsLoaded,
});

export const loadContentStatusContentItems = createLoadContentStatusContentItemsAction({
  missionControlRepository,
  buildFilterWithContinuationForContentStatusListing,
  loadContentStatusCommentCounts,
  loadContentStatusTaskCounts,
  requestTokenFactory: listingRequestTokenFactory,
});

export const loadListingContentItemsForContentStatus =
  createLoadContentStatusContentItemsForInventoryAction({
    loadContentStatusContentItems,
  });

export const filterContentItems = createFilterContentItemsAction({
  loadListingContentItemsForInventory: loadListingContentItemsForContentStatus,
  ContentItemFilterStorage: getFilterStorage(ContentItemFilterOrigin.ContentStatus),
  trackIntercomFindRightContentEvent: trackFindRightContentInInventory,
});

export const initializeContentStatus = createInitializeContentStatusAction({
  loadLanguages,
});
