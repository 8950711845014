import { Dispatch, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { ByStatus } from '../../../../../_shared/constants/userListingFilter.ts';
import { defaultUserFilter } from '../../reducers/internalReducers/filter.ts';
import {
  collectionsFilterUpdated,
  languagesFilterUpdated,
  rolesFilterUpdated,
  statusFilterUpdated,
} from '../usersActions.ts';

interface IDeps {
  readonly updateSavedConfig: () => ThunkFunction;
}

export type SearchAllUsersByNameActionType = ReturnType<
  | typeof collectionsFilterUpdated
  | typeof languagesFilterUpdated
  | typeof rolesFilterUpdated
  | typeof statusFilterUpdated
>;

export const createSearchAllUsersByNameAction =
  ({ updateSavedConfig }: IDeps) =>
  (): ThunkFunction =>
  (dispatch: Dispatch): void => {
    dispatch(collectionsFilterUpdated(defaultUserFilter.byCollection));
    dispatch(languagesFilterUpdated(defaultUserFilter.byLanguage));
    dispatch(rolesFilterUpdated(defaultUserFilter.byRoles));
    dispatch(statusFilterUpdated(ByStatus.ActiveAndDeactivated));
    dispatch(updateSavedConfig());
  };
