import { isAbortError } from '@kontent-ai/errors';
import { notNull } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  MapiKeyListing_Loading_Failed,
  MapiKeyListing_Loading_Finished,
  MapiKeyListing_Loading_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { IApiKeyFilter, createApiKeyFilterServerModel } from '../../models/ApiKeyFilter.ts';
import {
  IApiKeyListingData,
  createApiKeyListingDataFromServerModel,
} from '../../models/ApiKeyListingData.ts';

const started = () =>
  ({
    type: MapiKeyListing_Loading_Started,
  }) as const;

const failed = () =>
  ({
    type: MapiKeyListing_Loading_Failed,
  }) as const;

const success = (apiKeyListingData: ReadonlyArray<IApiKeyListingData>) =>
  ({
    type: MapiKeyListing_Loading_Finished,
    payload: { apiKeyListingData },
  }) as const;

interface ILoadMapiKeyListingDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'getApiKeyListing'>;
}

export type LoadMapiKeyListingActionsType = ReturnType<
  typeof started | typeof failed | typeof success
>;

export const loadMapiKeyListingCreator =
  (dependencies: ILoadMapiKeyListingDependencies) =>
  (filter: IApiKeyFilter, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    try {
      dispatch(started());
      const filterModel = createApiKeyFilterServerModel(filter, [
        ApiKeyType.MAPIPat,
        ApiKeyType.MAPI,
      ]);
      const serverModel = await dependencies.apiKeysRepository.getApiKeyListing(
        filterModel,
        abortSignal,
      );

      const apiKeyListingData = serverModel
        .map(createApiKeyListingDataFromServerModel)
        .filter(notNull);

      dispatch(success(apiKeyListingData));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
