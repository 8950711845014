import { InvariantException } from '@kontent-ai/errors';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';

export const getSubFolderIds = (
  taxonomyGroup: ITaxonomyGroup,
  childFolderIds: UuidArray,
): UuidArray => {
  const result = [...childFolderIds];

  childFolderIds.forEach((childId: Uuid) => {
    const childFolder = taxonomyGroup.terms.get(childId);

    if (!childFolder) {
      throw InvariantException(`Folder with id ${childId} does not exist`);
    }

    const newResults = getSubFolderIds(taxonomyGroup, childFolder.childIds);
    result.push(...newResults);
  });

  return result;
};
