import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { DataUiElement } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IncompleteItemElementsNotification } from '../../containers/details/IncompleteItemElementsNotification.tsx';
import { IncompleteItemElementsButtonNotification } from '../incompleteItemElementsNotifications/IncompleteItemElementsButtonNotification.tsx';

export type IncompleteNotificationBarDispatchProps = {
  readonly onClosePanel: () => void;
};

export type IncompleteNotificationBarStateProps = {
  readonly isAnyIncompleteElementAccessible: boolean;
  readonly message: string | null;
};

type IncompleteNotificationBarProps = IncompleteNotificationBarDispatchProps &
  IncompleteNotificationBarStateProps;

export const IncompleteItemNotificationBar: React.FC<IncompleteNotificationBarProps> = ({
  isAnyIncompleteElementAccessible,
  message,
  onClosePanel,
}) => (
  <NotificationBar
    type={NotificationBarType.Error}
    uiElement={DataUiElement.NotificationMessageError}
    message={message}
    onClosePanel={onClosePanel}
    tools={
      isAnyIncompleteElementAccessible && (
        <IncompleteItemElementsNotification
          render={(highlightIncompleteElements, incompleteElementsCount) => (
            <IncompleteItemElementsButtonNotification
              highlightIncompleteElements={highlightIncompleteElements}
              incompleteElementsCount={incompleteElementsCount}
            />
          )}
        />
      )
    }
  />
);

IncompleteItemNotificationBar.displayName = 'IncompleteItemNotificationBar';
