import { Spacing, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const StyledMonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-right: ${px(Spacing.XL)};
  padding-top: ${px(Spacing.L)};
`;
