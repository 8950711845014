import {
  ApiValidationErrorCode,
  IApiValidationError,
  IServerApiError,
  ServerApiErrorCode,
} from '../../../repositories/serverModels/ServerApiError.ts';
import {
  EditedContentItemFailure,
  FailureStatus,
  IEditedContentItemCommonFailure,
} from '../models/contentItem/edited/EditedContentItemStatus.ts';

export function apiErrorToFailureStatus(
  apiError: IServerApiError | null,
): EditedContentItemFailure {
  switch (apiError?.code) {
    case ServerApiErrorCode.NonLocalizableContentTooLarge:
      return {
        status: FailureStatus.NonLocalizableContentTooLarge,
        variantIds: apiError?.variant_ids ?? [],
      };
    case ServerApiErrorCode.ContentTooLarge:
      return { status: FailureStatus.ContentTooLarge };
    case ServerApiErrorCode.CodenameIsNotUnique:
      return { status: FailureStatus.CodenameIsNotUnique };
    case ServerApiErrorCode.MissingActivePermission:
    case ServerApiErrorCode.PermissionDenied:
      return { status: FailureStatus.MissingCapability };
    case ServerApiErrorCode.ValidationFailed:
      return processValidationErrors(apiError?.validation_errors ?? null);
    case ServerApiErrorCode.ContentWasModified:
      return { status: FailureStatus.ContentWasModified };
    default:
      return { status: FailureStatus.UnknownFailure };
  }
}

function processValidationErrors(
  validationErrors: ReadonlyArray<IApiValidationError> | null,
): IEditedContentItemCommonFailure {
  const validationErrorCode = validationErrors?.at(0)?.code;

  switch (validationErrorCode) {
    case ApiValidationErrorCode.ElementTooLarge:
      return { status: FailureStatus.ElementTooLarge };
    default:
      return { status: FailureStatus.ModelValidationFailed };
  }
}
