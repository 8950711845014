import { ContentStatusLanguagesFilterStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

type LanguageIds = ReadonlySet<Uuid>;

const contentStatusLanguagesFilterStorageParams: StorageParams<LanguageIds> = {
  key: ContentStatusLanguagesFilterStorageKey,
  initialState: new Set(),
  fromJS: (rawLanguageIds: readonly Uuid[]) => new Set([...rawLanguageIds]),
  toJS: (languageIds) => [...languageIds],
};

export const ContentStatusLanguagesFilterStorage = getProjectSpecificStorage<LanguageIds>(
  contentStatusLanguagesFilterStorageParams,
);
