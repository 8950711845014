import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { NonClickableTasksSection } from '../../../containers/details/Tasks/SectionTypes/NonClickableTasksSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

export const TasksSection = () => (
  <Section
    ClickableSectionComponent={() => null}
    NonClickableSectionComponent={NonClickableTasksSection}
    ReadonlySectionComponent={() => null}
    title="Tasks"
    type={SectionType.NonClickable}
    uiObjectNameAttribute={ItemDetailSection.TasksSection}
  />
);

TasksSection.displayName = 'TasksSection';
