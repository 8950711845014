import {
  UrlSlugModeServerModel,
  UrlSlugTypeElementServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { UnitOption } from '../../../../contentInventory/content/models/textLengthOptions.ts';
import { UrlSlugMode } from '../../../../itemEditor/constants/urlSlugMode.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  ITypeElementDataWithValidationRegex,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IUrlSlugTypeElementData
  extends IBaseTypeElementData,
    ITypeElementDataWithValidationRegex {
  readonly _canBeGeneratedFromDeprecatedItemName: boolean;
  readonly _lengthUnitOption: UnitOption;
  readonly dependedOnTextElementId: Uuid | null;
  readonly maxChars: string | null;
  readonly mode: UrlSlugMode;
}

export const EmptyUrlSlugTypeElementData: IUrlSlugTypeElementData = {
  ...EmptyBaseTypeElementData,
  _canBeGeneratedFromDeprecatedItemName: false,
  _configurability: TypeElementConfigurability.Required,
  _lengthUnitOption: UnitOption.Words,
  dependedOnTextElementId: null,
  maxChars: null,
  mode: UrlSlugMode.Auto,
  type: ElementType.UrlSlug,
  validationRegex: null,
};

export function getUrlSlugTypeElementDataFromServerModel(
  serverModel: UrlSlugTypeElementServerModel,
): IUrlSlugTypeElementData {
  const mode = serverModel.mode
    ? serverModel.mode === UrlSlugModeServerModel.Custom
      ? UrlSlugMode.Custom
      : UrlSlugMode.Auto
    : UrlSlugMode.Auto;
  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _canBeGeneratedFromDeprecatedItemName: !serverModel.dependentStringTypeElementId,
    _configurability: EmptyUrlSlugTypeElementData._configurability,
    _lengthUnitOption: serverModel.maxChars ? UnitOption.Characters : UnitOption.Words,
    dependedOnTextElementId: serverModel.dependentStringTypeElementId,
    maxChars: serverModel.maxChars
      ? serverModel.maxChars.toString()
      : EmptyUrlSlugTypeElementData.maxChars,
    mode,
    type: EmptyUrlSlugTypeElementData.type,
    validationRegex: serverModel.validationRegex,
  };
}
