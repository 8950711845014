import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { isAiReviewEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import {
  Data_AiGuidelines_Failed,
  Data_AiGuidelines_Started,
  Data_AiGuidelines_Success,
} from '../../../../../data/constants/dataActionTypes.ts';
import { IAiGuidelinesRepository } from '../../../repositories/IAiGuidelinesRepository.type.ts';
import { IAiGuidelinesResponseServerModel } from '../../../repositories/serverModels/AiGuidelinesServerModels.type.ts';

interface IDeps {
  readonly aiGuidelinesRepository: Pick<IAiGuidelinesRepository, 'getAllAiGuidelines'>;
}

const start = () => ({ type: Data_AiGuidelines_Started }) as const;
const success = (data: ReadonlyArray<IAiGuidelinesResponseServerModel>) =>
  ({
    type: Data_AiGuidelines_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_AiGuidelines_Failed }) as const;

export type LoadAiGuidelinesActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadAiGuidelinesAction =
  ({ aiGuidelinesRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    if (!isAiReviewEnabled(state)) {
      return;
    }

    dispatch(start());

    try {
      const aiGuidelinesServerModels = await aiGuidelinesRepository.getAllAiGuidelines(abortSignal);
      dispatch(success(aiGuidelinesServerModels));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
