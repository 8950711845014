import React from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { hasProjectMultipleWorkflows } from '../../../../../../../_shared/selectors/Workflows/hasProjectMultipleWorkflows.ts';
import { WorkflowSection as WorkflowSectionComponent } from '../../../components/details/Workflow/WorkflowSection.tsx';
import { SectionType } from '../../../components/sidebar/sectionTypes/SectionType.tsx';
import { isChangeWorkflowAvailable } from '../../../utils/editingActions/availabilityUtils/areAssignmentChangesAvailable.ts';

export const WorkflowSection: React.FC = () => {
  const hasMultipleWorkflows = useSelector(hasProjectMultipleWorkflows);
  const isClickable = useSelector(isChangeWorkflowAvailable);

  const sectionType = isClickable ? SectionType.Clickable : SectionType.Readonly;

  return hasMultipleWorkflows ? <WorkflowSectionComponent sectionType={sectionType} /> : null;
};
