import { isElementHorizontallyOverflowing, isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { Column } from '../../layout/Row/Column.tsx';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { transitionBgHover } from '../../tokens/decision/transitions.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { StyledComplementaryText } from './components/StyledComplementaryText.tsx';
import { menuItemPrimaryLabel, menuItemSecondaryLabel } from './decisionTokens.ts';
import { MenuItemState } from './menuItemState.ts';

export type MenuItemBodyProps = {
  readonly complementaryText?: string;
  readonly isExpanded?: boolean;
  readonly isHovered?: boolean;
  readonly renderComplementaryText?: (
    text: string | undefined,
    menuItemState: MenuItemState,
  ) => React.ReactNode;
  readonly renderLabel?: (text: string, menuItemState: MenuItemState) => React.ReactNode;
  readonly state: MenuItemState;
  readonly text: string;
  readonly onTruncated: (truncated: boolean) => void;
};

const labelCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  transition: color ${transitionBgHover};
`;

const StyledPrimaryLabel = styled.span`
  ${menuItemPrimaryLabel};
  ${labelCss};
`;

const StyledSecondaryLabel = styled.span`
  ${menuItemSecondaryLabel};
  ${labelCss};
`;

export const MenuItemBody = React.forwardRef<HTMLDivElement, MenuItemBodyProps>(
  (props, forwardedRef) => {
    const { complementaryText, renderComplementaryText } = props;
    const finalComplementaryText = renderComplementaryText
      ? renderComplementaryText(complementaryText, props.state)
      : complementaryText;

    const labelRef = useRef<HTMLDivElement>(null);
    const complementaryTextRef = useRef<HTMLDivElement>(null);

    useResizeObserver({
      ref: labelRef,
      onResize: () => {
        const labelOverflow =
          isElementHorizontallyOverflowing(labelRef.current) ||
          isElementVerticallyOverflowing(labelRef.current);
        const complementaryTextOverflow =
          isElementHorizontallyOverflowing(complementaryTextRef.current) ||
          isElementVerticallyOverflowing(complementaryTextRef.current);

        props.onTruncated(labelOverflow || complementaryTextOverflow);
      },
    });

    return (
      <Column ref={forwardedRef}>
        <Stack spacing={Spacing.XS}>
          <StyledPrimaryLabel ref={labelRef}>
            {props.renderLabel?.(props.text, props.state) ?? props.text}
          </StyledPrimaryLabel>

          {finalComplementaryText && (
            <StyledComplementaryText
              $state={props.state}
              $isExpanded={props.isExpanded}
              $isHovered={props.isHovered}
            >
              <StyledSecondaryLabel ref={complementaryTextRef}>
                {finalComplementaryText}
              </StyledSecondaryLabel>
            </StyledComplementaryText>
          )}
        </Stack>
      </Column>
    );
  },
);

MenuItemBody.displayName = 'MenuItemBody';
