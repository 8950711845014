import React from 'react';
import { HorizontalTabs } from '../../../../../component-library/components/StatusBar/HorizontalTabs/HorizontalTabs.tsx';
import {
  DataUiCollection,
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ContentModelsTab = {
  readonly dataUiAttributes: ObjectWithDataAttribute;
  readonly id: Uuid;
  readonly label: string;
  readonly pathname: string;
};

type ContentModelsTabsProps = {
  readonly contentModelsTabs: readonly ContentModelsTab[];
};

export const ContentModelsTabs: React.FC<ContentModelsTabsProps> = ({ contentModelsTabs }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    // Current status bar layout is not robust enough so we need to force shrinking here.
    // We should be able to remove this once the status bar layout is build using new layout components.
    <div
      css={`
        min-width: 0;
        flex: 1 1 auto
      `}
      ref={ref}
    >
      <HorizontalTabs
        containerRef={ref}
        items={contentModelsTabs}
        getItemProps={(tabInfo) => ({
          label: tabInfo.label,
          pathname: tabInfo.pathname,
          ...getDataUiElementAttribute(DataUiElement.NavigationTab),
          ...tabInfo.dataUiAttributes,
        })}
        {...getDataUiCollectionAttribute(DataUiCollection.TabItems)}
      />
    </div>
  );
};

ContentModelsTabs.displayName = 'ContentModelsTabs';
