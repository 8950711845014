import { ContentState } from 'draft-js';
import { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  commentThreadFormCanceled,
  commentThreadFormOpened,
  commentThreadFormTextFocusLost,
} from '../../../actions/contentItemEditingActions.ts';
import { saveNewCommentThreadToServer } from '../../../actions/thunkContentItemEditingActions.ts';
import { NewCommentThread as NewCommentThreadComponent } from '../../../components/comments/threadItem/NewCommentThread.tsx';

export const NewCommentThread = () => {
  const isEditing = useSelector(
    (s) => !!s.contentApp.editedContentItemVariantComments.newCommentThreadForm.isEditing,
  );
  const areButtonsDisplayed = useSelector(
    (s) => !!s.contentApp.editedContentItemVariantComments.newCommentThreadForm.isVisible,
  );
  const isSubmitting = useSelector(
    (s) => !!s.contentApp.editedContentItemVariantComments.newCommentThreadForm.isSubmitting,
  );

  const dispatch = useDispatch();
  const focus = useCallback(() => dispatch(commentThreadFormOpened()), []);
  const cancel = useCallback(() => dispatch(commentThreadFormCanceled()), []);
  const blur = useCallback(
    (isCommentPending: boolean) => dispatch(commentThreadFormTextFocusLost(isCommentPending)),
    [],
  );
  const submit = useCallback(
    (commentContent: ContentState) => dispatch(saveNewCommentThreadToServer(commentContent)),
    [],
  );

  return (
    <NewCommentThreadComponent
      onCancel={cancel}
      onBlur={blur}
      onSubmit={submit}
      onFocus={focus}
      isEditing={isEditing}
      areButtonsDisplayed={areButtonsDisplayed}
      isSubmitting={isSubmitting}
    />
  );
};
