import { Box } from '@kontent-ai/component-library/Box';
import { Button, IconButton } from '@kontent-ai/component-library/Button';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  ErrorTag,
  GeneralTag,
  Option,
  RenderSelectMenuOptionProps,
} from '@kontent-ai/component-library/Selects';
import { Stack } from '@kontent-ai/component-library/Stack';
import { OptionalTooltip } from '@kontent-ai/component-library/Tooltip';
import { BaseColor, Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React, { ComponentProps } from 'react';
import { DefaultTag } from '../../../../component-library/components/Tag/DefaultTag.tsx';
import { Tag } from '../../../../component-library/components/Tag/Tag.tsx';
import { BarItemActionButtons } from '../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemError } from '../../../_shared/components/BarItems/BarItemError.tsx';
import { BarItemExpandedSimpleHeader } from '../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { SearchPhraseHighlighterElement } from '../../../_shared/components/Highlighting/SearchPhraseHighlighterElement.tsx';
import { HotkeysHandler } from '../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../_shared/constants/shortcutSymbols.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
  getDataUiObjectNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SpacesMap } from '../../../data/models/space/space.ts';
import { areSpacesEnabledForCurrentProject } from '../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';
import { IPreviewUrlPatternError } from '../stores/IPreviewConfigurationAppStoreState.type.ts';
import { SpaceOption, anySpacesOptionId, getSpacesOptions } from '../utils/getSpacesOptions.ts';

const renderSelectedOption =
  (invalidSpaceIds: ReadonlyArray<string> | undefined) =>
  (_id: Uuid, selectedItem: SpaceOption, defaultTagProps: ComponentProps<typeof DefaultTag>) => {
    if (invalidSpaceIds?.some((id) => id === selectedItem.id)) {
      return <ErrorTag {...defaultTagProps} />;
    }
    if (selectedItem.id === anySpacesOptionId) {
      return <Tag {...defaultTagProps} background={BaseColor.Gray30} />;
    }
    return <DefaultTag {...defaultTagProps} />;
  };
const renderGeneralTag = (
  count: number,
  defaultTagProps: Omit<ComponentProps<typeof DefaultTag>, 'children'>,
): React.ReactNode => (
  <GeneralTag countPlacement="before" countValue={count} label="Spaces" {...defaultTagProps} />
);

const renderMenuOption =
  (invalidSpaceIds: ReadonlyArray<string> | undefined) =>
  (props: RenderSelectMenuOptionProps<SpaceOption>) =>
    props.item.value && (
      <Option
        {...props}
        {...getDataUiObjectNameAttribute(props.item.value.label)}
        isInErrorState={invalidSpaceIds?.some((invalidId) => invalidId === props.item.value?.id)}
      />
    );

type Props = {
  readonly contentTypeName: string;
  readonly errorMessage: string | undefined;
  readonly errors: ReadonlyMap<Uuid, IPreviewUrlPatternError>;
  readonly onAddNewPattern: () => void;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly onDelete: () => void;
  readonly onDeletePattern: (spaceRowId: Uuid) => void;
  readonly onChange: (previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>) => void;
  readonly previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>;
  readonly selectedSpaceIds: ReadonlySet<Uuid>;
  readonly spaces: SpacesMap;
  readonly searchPhrase: string;
};

export const ExpandedPreviewUrlBar: React.FC<Props> = ({
  contentTypeName,
  errorMessage,
  errors,
  onAddNewPattern,
  onDeletePattern,
  onCancel,
  onConfirm,
  onDelete,
  onChange,
  previewUrlPatterns,
  selectedSpaceIds,
  spaces,
  searchPhrase,
}) => {
  const areSpacesEnabled = useSelector(areSpacesEnabledForCurrentProject);

  const disabledAddUrlPatternTooltipText = getDisabledAddUrlPatternTooltipText(
    previewUrlPatterns,
    spaces,
  );
  const disabledDeleteUrlPatternTooltipText =
    getDisabledDeleteUrlPatternTooltipText(previewUrlPatterns);
  const spacesOptions = getSpacesOptions(spaces, previewUrlPatterns);

  return (
    <HotkeysHandler
      className="bar-item__wrapper"
      handlers={{
        onEscape: onCancel,
        onEnter: onConfirm,
      }}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded">
        <BarItemExpandedSimpleHeader
          additionalTitleClass="bar-item__title--with-flex"
          dataUiObjectName={contentTypeName}
          renderTitle={() => (
            <OptionalTooltip placement="bottom-start" text={contentTypeName}>
              <SearchPhraseHighlighterElement searchPhrase={searchPhrase} text={contentTypeName} />
            </OptionalTooltip>
          )}
        />
        <div className="bar-item__expansion">
          <Stack
            spacing={Spacing.XL}
            {...getDataUiCollectionAttribute(DataUiCollection.PreviewUrls)}
          >
            {previewUrlPatterns.map((previewUrlPattern, index) => {
              const includeLabels = index === 0;
              const error = errors.get(previewUrlPattern.rowId);
              const selectedItemIds = previewUrlPattern.spaces;

              const changePreviewUrlPattern = (
                updatedPreviewUrlPattern: IPreviewUrlPattern,
              ): void => {
                const updatedPreviewUrlPatterns = Collection.replace(
                  previewUrlPatterns,
                  index,
                  updatedPreviewUrlPattern,
                );
                onChange(updatedPreviewUrlPatterns);
              };

              const changeUrlPattern = (urlPattern: string): void => {
                changePreviewUrlPattern({
                  ...previewUrlPattern,
                  urlPattern,
                });
              };

              const changeSpaces = (newSelectedIds: ReadonlySet<Uuid>): void => {
                if (newSelectedIds.has(anySpacesOptionId)) {
                  // If "any" option was previously selected and there are additionally selected standard options, dismiss the "any" option
                  if (newSelectedIds.size > 1 && selectedItemIds.has(anySpacesOptionId)) {
                    changePreviewUrlPattern({
                      ...previewUrlPattern,
                      spaces: new Set([...newSelectedIds].filter((id) => id !== anySpacesOptionId)),
                    });
                  } else {
                    // If the "any" option is new, keep just the "any" option
                    changePreviewUrlPattern({
                      ...previewUrlPattern,
                      spaces: new Set([anySpacesOptionId]),
                    });
                  }
                } else {
                  changePreviewUrlPattern({
                    ...previewUrlPattern,
                    spaces: newSelectedIds,
                  });
                }
              };

              const disabledItemIds = spacesOptions
                .map((option) => option.id)
                .filter((id) => selectedSpaceIds.has(id) && !previewUrlPattern.spaces.has(id));

              return (
                <Row
                  key={previewUrlPattern.rowId}
                  spacingX={Spacing.L}
                  {...getDataUiElementAttribute(DataUiElement.PreviewUrl)}
                >
                  {areSpacesEnabled && (
                    <Column flexFactor={1}>
                      <MultiSelect<SpaceOption>
                        label={includeLabels ? 'Used in spaces' : undefined}
                        ariaLabel="Used in spaces"
                        inputState={error?.spaces ? InputState.Alert : InputState.Default}
                        caption={error?.spaces?.message}
                        items={spacesOptions}
                        renderSelectedOption={renderSelectedOption(error?.spaces?.invalidSpaceIds)}
                        renderGeneralTag={renderGeneralTag}
                        renderMenuOption={renderMenuOption(error?.spaces?.invalidSpaceIds)}
                        generalTagThreshold={3}
                        selectedItemIds={selectedItemIds}
                        onSelectionChange={changeSpaces}
                        disabledItemIds={disabledItemIds}
                        {...getDataUiCollectionAttribute(DataUiCollection.Spaces)}
                      />
                    </Column>
                  )}
                  <Column flexFactor={1}>
                    <Input
                      label={includeLabels ? 'Preview URL' : undefined}
                      ariaLabel="Preview URL"
                      inputState={error?.urlPattern ? InputState.Alert : InputState.Default}
                      caption={error?.urlPattern}
                      value={previewUrlPattern.urlPattern}
                      onChange={(e) => changeUrlPattern(e.currentTarget.value)}
                      auxiliaryElements={
                        areSpacesEnabled && (
                          <IconButton
                            tooltipText={disabledDeleteUrlPatternTooltipText ?? ''}
                            tooltipPlacement="bottom"
                            iconName="Bin"
                            onClick={() => onDeletePattern(previewUrlPattern.rowId)}
                            size="medium"
                            buttonStyle="tertiary"
                            buttonState={
                              disabledDeleteUrlPatternTooltipText ? 'disabled' : 'default'
                            }
                            {...getDataUiActionAttribute(DataUiAction.Remove)}
                          />
                        )
                      }
                      {...getDataUiInputAttribute(DataUiInput.UrlPattern)}
                    />
                  </Column>
                </Row>
              );
            })}
            {areSpacesEnabled && (
              <Box>
                <Button
                  buttonStyle="tertiary"
                  onClick={onAddNewPattern}
                  disabled={!!disabledAddUrlPatternTooltipText}
                  tooltipText={disabledAddUrlPatternTooltipText}
                  {...getDataUiActionAttribute(DataUiAction.Add)}
                >
                  Define new URL
                </Button>
              </Box>
            )}
          </Stack>
        </div>
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancel,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: 'Confirm',
            handler: onConfirm,
            dataUIActionName: DataUiAction.Confirm,
            shortcut: ShortcutSymbols.Enter,
          }}
          destructiveAction={{
            text: 'Delete',
            handler: onDelete,
            icon: 'Bin',
            dataUIActionName: DataUiAction.Delete,
          }}
        />
      </div>
      <BarItemError showError={!!errorMessage} error={errorMessage} />
    </HotkeysHandler>
  );
};

ExpandedPreviewUrlBar.displayName = 'ExpandedPreviewUrlBar';

const getDisabledAddUrlPatternTooltipText = (
  previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>,
  spaces: SpacesMap,
): string | undefined => {
  if (previewUrlPatterns.length >= spaces.size + 1) {
    return 'You’ve already assigned all spaces.';
  }

  return undefined;
};

const getDisabledDeleteUrlPatternTooltipText = (
  previewUrlPatterns: ReadonlyArray<IPreviewUrlPattern>,
): string | undefined => {
  if (previewUrlPatterns.length <= 1) {
    return 'Each content type needs to have at least 1 preview URL.';
  }

  return undefined;
};
