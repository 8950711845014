import { useEffect, useState } from 'react';
import {
  feedbackModalSubmitted,
  modalDismissed,
} from '../../../../_shared/actions/sharedActions.ts';
import { dismissUserFeedback } from '../../../../_shared/actions/thunks/dismissUserFeedback.ts';
import { showUserFeedback } from '../../../../_shared/actions/thunks/showUserFeedback.ts';
import { submitUserFeedback } from '../../../../_shared/actions/thunks/submitUserFeedback.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentModelingFeedbackServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../../../_shared/models/events/UserFeedbackEventData.type.ts';
import { ContentModelingSurveyQuestionnaire } from './ContentModelingSurveyQuestionnaire.tsx';

export const ContentModelingSurveyModal = () => {
  const dispatch = useDispatch();

  const feedbackTimestamps = useSelector((s) => s.sharedApp.userProperties.contentModelingFeedback);

  const dismissFeedback = () => {
    dispatch(
      dismissUserFeedback(
        UserFeedbackSource.ContentModeling,
        ContentModelingFeedbackServerKey,
        feedbackTimestamps,
      ),
    );
    dispatch(modalDismissed());
  };

  const submit = async (ease: string, requirements: string, comment: string): Promise<void> => {
    await dispatch(
      submitUserFeedback({
        source: UserFeedbackSource.ContentModeling,
        propertyServerKey: ContentModelingFeedbackServerKey,
        feedbackTimestamps,
        ease,
        requirements,
        comment,
      }),
    );
    dispatch(feedbackModalSubmitted());
  };

  const [userFeedbackShown, setUserFeedbackShown] = useState(false);
  useEffect(() => {
    if (!userFeedbackShown) {
      dispatch(
        showUserFeedback(
          UserFeedbackSource.ContentModeling,
          ContentModelingFeedbackServerKey,
          feedbackTimestamps,
        ),
      );
      setUserFeedbackShown(true);
    }
  }, [userFeedbackShown, feedbackTimestamps]);

  return <ContentModelingSurveyQuestionnaire onClose={dismissFeedback} onSubmit={submit} />;
};
