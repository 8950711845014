import { ContentItemEditingWorkflowStepChangeFeatures } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IAssignment } from '../../../models/contentItem/Assignment.ts';
import { IParsedItemVariant } from './parseContentItem.ts';

type ValueWithAssignment = IParsedItemVariant | IAssignment;

const isParsedItemVariant = (
  valueWithAssignment: ValueWithAssignment,
): valueWithAssignment is IParsedItemVariant =>
  !!(valueWithAssignment as IParsedItemVariant).editedContentItemVariant;

const getAssignment = (valueWithAssignment: ValueWithAssignment): IAssignment => {
  if (isParsedItemVariant(valueWithAssignment)) {
    return valueWithAssignment.editedContentItemVariant.assignment;
  }

  return valueWithAssignment;
};

const getWorkflowStepChangeFeatures = (
  assignment: IAssignment,
): ReadonlyArray<ContentItemEditingWorkflowStepChangeFeatures> => {
  const stepChangeFeatureMap: Record<ContentItemEditingWorkflowStepChangeFeatures, boolean> = {
    [ContentItemEditingWorkflowStepChangeFeatures.HasDueDate]: !!assignment.due,
    [ContentItemEditingWorkflowStepChangeFeatures.HasNote]: !!assignment.note,
    [ContentItemEditingWorkflowStepChangeFeatures.HasScheduledToPublish]:
      !!assignment.scheduledToPublishAt,
    [ContentItemEditingWorkflowStepChangeFeatures.HasScheduledToUnpublish]:
      !!assignment.scheduledToUnpublishAt,
  };

  return Object.entries(stepChangeFeatureMap)
    .filter(([, value]) => value)
    .map(([feature]) => feature);
};

type WorkflowTrackingData = {
  readonly workflowId: Uuid;
  readonly workflowStepId: Uuid;
};

type FeaturesTrackingData = {
  readonly features: ReadonlyArray<ContentItemEditingWorkflowStepChangeFeatures>;
};

export const prepareWorkflowStepTrackingDataWithFeatures = <TEventSpecificData>(
  valueWithAssignment: ValueWithAssignment,
  eventSpecificData: TEventSpecificData,
): TEventSpecificData & WorkflowTrackingData & FeaturesTrackingData => {
  const assignment = getAssignment(valueWithAssignment);

  return {
    ...prepareWorkflowStepTrackingData(assignment, eventSpecificData),
    features: getWorkflowStepChangeFeatures(assignment),
  };
};

export const prepareWorkflowStepTrackingData = <TEventSpecificData>(
  valueWithAssignment: ValueWithAssignment,
  eventSpecificData: TEventSpecificData,
): TEventSpecificData & WorkflowTrackingData => {
  const assignment = getAssignment(valueWithAssignment);

  return {
    ...eventSpecificData,
    workflowId: assignment.workflowStatus.workflowId,
    // Please note that even in Publish step, some old/Sample projects might have different ID in default and other than default workflows
    workflowStepId: assignment.workflowStatus.id,
  };
};
