import { QuinaryButton } from '@kontent-ai/component-library/Button';
import React, { useState } from 'react';
import {
  DataUiCollection,
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  TopLevelBlockCategoryFeature,
  isTextFeatureAllowed,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { ToolbarButtonMenuPositioner } from '../../toolbars/components/block/ToolbarButtonMenuPositioner.tsx';
import { EditorToolbarDivider } from '../../toolbars/components/buttons/EditorToolbarDivider.tsx';
import { EditorCommandMenu } from '../../toolbars/components/menu/EditorCommandMenu.tsx';
import {
  ToolbarButtonInputProps,
  getToolbarButtonProps,
} from '../../toolbars/utils/toolbarUtils.ts';
import { OrderedListButton } from './buttons/OrderedListButton.tsx';
import { UnorderedListButton } from './buttons/UnorderedListButton.tsx';
import { getTextBlockTypeButtonTooltip } from './getTextBlockTypeButtonTooltip.ts';
import { headingMenuItems } from './textBlockTypeMenuItems.ts';

export type BlockTypeInlineToolbarButtonsProps = Pick<
  ToolbarButtonInputProps,
  | 'entityMap'
  | 'fullBlockTypesAtSelection'
  | 'hidesDisallowedFeatures'
  | 'limitations'
  | 'onCommand'
>;

export const BlockTypeInlineToolbarButtons: React.FC<BlockTypeInlineToolbarButtonsProps> = ({
  entityMap,
  fullBlockTypesAtSelection,
  hidesDisallowedFeatures,
  limitations,
  onCommand,
}) => {
  const buttonProps: ToolbarButtonInputProps = {
    currentVisualStyle: null,
    entityMap,
    fullBlockTypesAtSelection,
    hidesDisallowedFeatures,
    limitations,
    metadataAtSelection: null,
    onCommand,
    selectionContainsText: null,
  };

  const orderedListProps = getToolbarButtonProps(RichTextInputCommand.OrderedList, buttonProps);
  const unorderedListProps = getToolbarButtonProps(RichTextInputCommand.UnorderedList, buttonProps);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const closeMenu = (): void => {
    setIsMenuVisible(false);
  };

  const openMenu = (): void => {
    setIsMenuVisible(true);
  };

  const textAllowed = isTextFeatureAllowed(
    TopLevelBlockCategoryFeature.Text,
    fullBlockTypesAtSelection,
    limitations,
  );

  const selectedMenuItem = headingMenuItems.find(
    (item) => getToolbarButtonProps(item.command, buttonProps)?.isActive,
  );
  const selectedItemProps = selectedMenuItem
    ? getToolbarButtonProps(selectedMenuItem.command, buttonProps)
    : null;

  return (
    <>
      <ToolbarButtonMenuPositioner
        isDropDownVisible={isMenuVisible}
        menuUiCollection={DataUiCollection.RteBlockTypeMenuOptions}
        onClose={closeMenu}
        renderTrigger={(triggerProps) => (
          <QuinaryButton
            {...triggerProps}
            activated={isMenuVisible}
            buttonStyle={selectedItemProps?.isViolated ? 'error-selected' : 'default'}
            disabled={!textAllowed}
            onClick={isMenuVisible ? closeMenu : openMenu}
            tooltipText={
              isMenuVisible ? '' : getTextBlockTypeButtonTooltip(textAllowed, selectedItemProps)
            }
            {...getDataUiActionAttribute(DataUiRteAction.ChangeBlockType)}
          >
            {selectedMenuItem?.label ?? 'Normal text'}
            <QuinaryButton.Chevron direction={isMenuVisible ? 'up' : 'down'} />
          </QuinaryButton>
        )}
        renderDropDown={() => (
          <EditorCommandMenu
            entityMap={entityMap}
            fullBlockTypesAtSelection={fullBlockTypesAtSelection}
            hidesDisallowedFeatures={hidesDisallowedFeatures}
            items={headingMenuItems}
            limitations={limitations}
            onCommand={onCommand}
          />
        )}
      />
      {orderedListProps || unorderedListProps ? (
        <>
          <EditorToolbarDivider />
          {orderedListProps && <OrderedListButton {...orderedListProps} />}
          {unorderedListProps && <UnorderedListButton {...unorderedListProps} />}
        </>
      ) : null}
    </>
  );
};
