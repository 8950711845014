import { ConditionTriggerNotSet } from '../../../../constants/errorMessageTemplates.ts';
import {
  OptionsError,
  TriggerError,
  TypeElementConditionValidationResult,
} from '../../types/TypeElementWithConditionValidationResult.ts';

const optionsErrorMessages: Record<OptionsError, ReadonlyArray<string>> = {
  [OptionsError.DeletedOption]: ['Remove the deleted options from the visibility condition.'],
  [OptionsError.NoOptionSet]: ['Select options to save the condition or disable the condition.'],
  [OptionsError.None]: [],
};

const triggerErrorMessages: Record<TriggerError, ReadonlyArray<string>> = {
  [TriggerError.DeletedTrigger]: [
    'Select an existing multiple choice element for the visibility condition or disable the visibility condition.',
  ],
  [TriggerError.None]: [],
  [TriggerError.TriggerIsNotMultipleChoice]: [
    'Select a trigger element that’s of multiple choice type.',
  ],
  [TriggerError.TriggerNotSet]: [ConditionTriggerNotSet],
};

export const createErrorMessagesFromConditionValidationResult = (
  result: TypeElementConditionValidationResult,
): ReadonlyArray<string> => [
  ...optionsErrorMessages[result.optionsError],
  ...triggerErrorMessages[result.triggerError],
];
