import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { LoadContentTypeAsComponentUsageParams } from '../../../../../../../data/actions/thunks/models/loadContentTypeAsComponentUsage.ts';
import { listingFilterChanged } from '../contentItemInventoryActions.ts';

type Deps = {
  readonly loadListingContentItems: (params: LoadContentTypeAsComponentUsageParams) => ThunkPromise;
};

export const createFilterItemsWithContentTypeAsComponentUsage =
  (deps: Deps) =>
  (contentTypeId: Uuid): ThunkPromise =>
  async (dispatch) => {
    dispatch(listingFilterChanged());

    await dispatch(deps.loadListingContentItems({ contentTypeId, scrollPositionChanged: false }));
  };
