import { delay } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { bulkActionTakingTooLong } from '../../contentItemInventoryActions.ts';

export const setTimeoutForMessageChange =
  (operationId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    await delay(5000);

    const lastAction = getState().contentApp.listingUi.statusInfo.lastAction;
    if (lastAction.operationId === operationId) {
      dispatch(bulkActionTakingTooLong());
    }
  };
