import { memoize } from '@kontent-ai/memoization';
import {
  ICollectionGroupRoles,
  IProjectContributorRole,
} from '../../../../data/models/users/ProjectContributor.ts';
import { roleBuilderAnyCollectionOptionId } from '../constants/roleBuilder.ts';
import { getBasicRoleBuilderErrorMessage } from './getBasicRoleBuilderErrorMessage.ts';

export type GetAdvancedRoleBuilderErrorMessageDeps = {
  readonly getBasicRoleBuilderErrorMessage: (
    userRoles: readonly IProjectContributorRole[],
    areLanguageRolesEnabled: boolean,
  ) => string | undefined;
};

export type GetAdvancedRoleBuilderErrorMessage = (
  collectionGroups: readonly ICollectionGroupRoles[],
  areLanguageRolesEnabled: boolean,
  areCollectionPermissionsEnabled: boolean,
  isProjectManagerSelectionValid: boolean,
) => string | undefined;

export const getAdvancedRoleBuilderErrorMessageFactory = (
  deps: GetAdvancedRoleBuilderErrorMessageDeps,
): GetAdvancedRoleBuilderErrorMessage => {
  return memoize.maxOne(
    (
      collectionGroups,
      areLanguageRolesEnabled,
      areCollectionPermissionsEnabled,
      isProjectManagerSelectionValid,
    ) => {
      const allIds = collectionGroups.flatMap((group) => group.collectionIds);
      const uniqueIds = new Set(allIds);

      if (
        !areCollectionPermissionsEnabled &&
        (uniqueIds.size > 1 ||
          (uniqueIds.size === 1 && !uniqueIds.has(roleBuilderAnyCollectionOptionId)))
      ) {
        return 'Assign a single collection group to “Any collection” to save your changes.';
      }

      if (uniqueIds.size !== allIds.length) {
        return 'Select unique collections.';
      }

      const allSectionsHaveCollections = collectionGroups.every(
        (group) => group.collectionIds.length !== 0,
      );

      if (!allSectionsHaveCollections) {
        return 'You’ve left the collection field empty.';
      }

      const someGroupHasAnyOptionWithRegularOptions = collectionGroups.some(
        (group) =>
          group.collectionIds.length >= 2 &&
          group.collectionIds.includes(roleBuilderAnyCollectionOptionId),
      );

      if (someGroupHasAnyOptionWithRegularOptions) {
        return 'You can not select the “Any” option together with other options.';
      }

      for (const group of collectionGroups) {
        const errorMessage = deps.getBasicRoleBuilderErrorMessage(
          group.roles,
          areLanguageRolesEnabled,
        );
        if (errorMessage) {
          return errorMessage;
        }
      }

      if (!isProjectManagerSelectionValid) {
        return 'Assign the Project manager role to all collections and languages.';
      }

      return undefined;
    },
  );
};

export const getAdvancedRoleBuilderErrorMessage = getAdvancedRoleBuilderErrorMessageFactory({
  getBasicRoleBuilderErrorMessage,
});
