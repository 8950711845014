import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { loadContentItemUsage } from '../../../../../../../data/actions/thunkDataActions.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../utils/contentItemListModelConverters.ts';

export const createLoadSelectedContentItemUsage =
  () =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const query = getItemListingOperationQueryFromFilter(state);

    if (query.includedItemIds?.length === 1) {
      const selectedItemId = query.includedItemIds[0] ?? '';
      const item = state.data.listingContentItems.byId.get(selectedItemId);

      if (item?.variant) {
        await dispatch(
          loadContentItemUsage(selectedItemId, item.variant.id.variantId, abortSignal),
        );
      }
    }
  };
