import { notNullNorUndefined } from '@kontent-ai/utils';
import { ContentBlock, ContentState, RawDraftContentState, convertFromRaw } from 'draft-js';
import {
  IContentComponentDomainModelConversionDependencies,
  IContentComponentServerModelConversionDependencies,
  contentComponentConversionForServer,
  contentComponentConversionToDomainModel,
} from '../../../itemEditor/features/ContentComponent/utils/contentComponentConversionUtils.ts';
import { IContentComponentServerModel } from '../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import {
  getContentComponentIds,
  updateContentComponentIds,
} from '../../plugins/contentComponents/api/editorContentComponentUtils.ts';
import { createEmptyContent, removeMetadata } from '../blocks/editorBlockUtils.ts';
import { ensureContentConsistency } from '../consistency/editorConsistencyUtils.ts';
import { createSelection } from '../editorSelectionUtils.ts';
import { GetValueFromContentState } from '../export/GetValueFromContentState.type.ts';
import { convertFromDraftStateToRaw } from '../export/convertFromDraftStateToRaw/convertFromDraftStateToRaw.ts';
import { changeBlock } from '../general/editorContentUtils.ts';
import {
  removeNonPersistentBlocks,
  removeNonPersistentEntities,
} from '../general/nonPersistentContentUtils.ts';
import {
  RichTextServerModel,
  convertRawContentToServerModel,
  convertServerModelToRawContent,
} from './editorServerModel.ts';

export const getContentStateForServer = (
  content: ContentState,
  prepareBlock?: (block: ContentBlock) => ContentBlock,
): ContentState => {
  const selection = createSelection(content.getFirstBlock().getKey());
  const input = {
    content,
    selection,
  };

  const withoutBlockMetadata = changeBlock(input, prepareBlock ?? removeMetadata);

  return withoutBlockMetadata.content;
};

export const convertContentToServerModel = (content: ContentState): RichTextServerModel =>
  convertRawContentToServerModel(convertFromDraftStateToRaw(getContentStateForServer(content)));

interface ICreateRichTextServerModelDependencies {
  readonly contentComponentConversionDependencies?: IContentComponentServerModelConversionDependencies;
  readonly getValue: (content: ContentState) => string;
}

export type ICreateRichTextServerModel = (
  content: ContentState,
  dependencies: ICreateRichTextServerModelDependencies,
) => IRichTextServerModel;

export const createRichTextServerModel: ICreateRichTextServerModel = (
  content,
  dependencies,
): IRichTextServerModel => {
  const forServer = getContentStateForServer(content);

  const contentComponentConversionDependencies =
    dependencies.contentComponentConversionDependencies;
  const getContentComponent =
    contentComponentConversionDependencies?.dataDependencies.getContentComponent;
  const contentComponentsForServer = getContentComponent
    ? getContentComponentIds(content)
        .map(getContentComponent)
        .filter(notNullNorUndefined)
        .map((contentComponent) =>
          contentComponentConversionForServer(
            contentComponent,
            contentComponentConversionDependencies,
          ),
        )
    : undefined;

  return {
    ...getRichTextServerValues(forServer, dependencies.getValue),
    contentComponents: contentComponentsForServer,
  };
};

export interface IRichTextServerValues {
  readonly value?: string;
  readonly jsonValue?: string;
}

export interface IRichTextServerModel extends IRichTextServerValues {
  readonly contentComponents?: ReadonlyArray<IContentComponentServerModel>;
}

type IGetRawContentStateFromValue = (value: string | undefined) => RawDraftContentState;

export const serializeRichTextServerModel = (serverModel: RichTextServerModel): string => {
  return JSON.stringify(serverModel);
};

export const serializeContentState = (content: ContentState): string => {
  const serverModel = convertContentToServerModel(content);
  return serializeRichTextServerModel(serverModel);
};

export const deserializeRichTextServerModel = (jsonValue: string): RawDraftContentState => {
  const deserialized: RichTextServerModel = JSON.parse(jsonValue);
  return convertServerModelToRawContent(deserialized);
};

function getRawContentStateFromServerModel(
  serverModel: IRichTextServerModel,
  importFromValue: IGetRawContentStateFromValue,
): RawDraftContentState {
  const rawState = serverModel.jsonValue;
  if (rawState) {
    return deserializeRichTextServerModel(rawState);
  }

  return importFromValue(serverModel.value ?? '');
}

interface ICreateRichTextContentDomainModelDependencies {
  readonly contentComponentConversionDependencies?: IContentComponentDomainModelConversionDependencies;
  readonly importFromValue: IGetRawContentStateFromValue;
}

export function createRichTextContentDomainModelFromRaw(
  rawState: RawDraftContentState,
): ContentState {
  const convertedState = convertFromRaw(rawState);

  // If the content from server has no blocks at all, we need to fallback to some valid empty content
  // we experienced this in KCL-10762, but not sure how it happened so we are rather fixing the symptoms than the cause
  if (convertedState.getBlockMap().isEmpty()) {
    return createEmptyContent();
  }

  const withoutNonPersistentBlocks =
    removeNonPersistentBlocks(convertedState) ?? createEmptyContent();
  const withoutNonPersistentEntities = removeNonPersistentEntities(withoutNonPersistentBlocks);

  return withoutNonPersistentEntities;
}

export const createRichTextContentDomainModel = (
  serverModel: IRichTextServerModel,
  dependencies: ICreateRichTextContentDomainModelDependencies,
): ContentState => {
  const rawState = getRawContentStateFromServerModel(serverModel, dependencies.importFromValue);
  return createRichTextContentDomainModelFromRaw(rawState);
};

function getRichTextServerValues(
  content: ContentState,
  getValue: GetValueFromContentState,
): IRichTextServerValues {
  const html = getValue(content);
  const json = serializeContentState(content);

  return {
    value: html,
    jsonValue: json,
  };
}

export interface ICreateRichTextDomainModelDependencies
  extends ICreateRichTextContentDomainModelDependencies {
  readonly getValue: GetValueFromContentState;
}

export type ICreateRichTextDomainModel = (
  element: IRichTextServerModel,
  dependencies: ICreateRichTextDomainModelDependencies,
) => {
  readonly content: ContentState;
  readonly updatedServerValues?: IRichTextServerValues;
};

export const createRichTextDomainModel: ICreateRichTextDomainModel = (
  serverModel: IRichTextServerModel,
  dependencies: ICreateRichTextDomainModelDependencies,
) => {
  const content = createRichTextContentDomainModel(serverModel, dependencies);

  // Store content components when supported
  const contentComponentConversionDependencies =
    dependencies.contentComponentConversionDependencies;
  const storeContentComponent =
    contentComponentConversionDependencies?.dataDependencies.storeContentComponent;
  const newContentComponentIds: ReadonlyMap<Uuid, Uuid> =
    serverModel.contentComponents && storeContentComponent
      ? new Map<Uuid, Uuid>(
          serverModel.contentComponents
            .map((serverContentComponent) => {
              const contentComponent = contentComponentConversionToDomainModel(
                serverContentComponent,
                contentComponentConversionDependencies,
              );
              const storedContentComponent = storeContentComponent(contentComponent);
              return storedContentComponent.id !== contentComponent.id
                ? ([contentComponent.id, storedContentComponent.id] satisfies [Uuid, Uuid])
                : null;
            })
            .filter(notNullNorUndefined),
        )
      : new Map();
  const withUpdatedContentComponentIds = updateContentComponentIds(content, newContentComponentIds);

  // Make sure that any content transformations are made before other code starts with the content
  // to prevent automatic change detection for not really changed content
  const consistentContent = ensureContentConsistency(withUpdatedContentComponentIds);

  const originalContentHasChanged = consistentContent !== content;
  const updatedServerValues = originalContentHasChanged
    ? getRichTextServerValues(consistentContent, dependencies.getValue)
    : undefined;

  return {
    content: consistentContent,
    updatedServerValues,
  };
};
