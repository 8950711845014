import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import { ContentItemEditing_SuggestionApproved } from '../../constants/contentItemEditingActionTypes.ts';

interface ISuggestionApprovedPayload {
  readonly commentThread: ICommentThread;
  readonly suggestionId: Uuid;
}

const suggestionApproved = (payload: ISuggestionApprovedPayload) =>
  ({
    type: ContentItemEditing_SuggestionApproved,
    payload,
  }) as const;

export type MarkSuggestionAsApprovedActionsType = ReturnType<typeof suggestionApproved>;

interface IDeps {
  readonly commentRepository: Pick<ICommentRepository, 'markSuggestionAsApproved'>;
}

export const createMarkSuggestionAsApprovedAction =
  (deps: IDeps) =>
  (commentThreadId: Uuid, suggestionId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { editedContentItemVariant } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw InvariantException('markSuggestionAsApproved: "editedContentItemVariant" is null');
    }

    const id = editedContentItemVariant.id;

    const markedThreadServerModel = await deps.commentRepository.markSuggestionAsApproved(
      id.itemId,
      id.variantId,
      commentThreadId,
      suggestionId,
    );
    const markedThread = createCommentThreadDomainModel(markedThreadServerModel);

    dispatch(suggestionApproved({ commentThread: markedThread, suggestionId }));
  };
