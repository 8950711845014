import { IAssignment } from '../../../../applications/itemEditor/models/contentItem/Assignment.ts';
import { IChangeWorkflowStepModalData } from '../reducers/IChangeWorkflowStepModalData.ts';

export const isWorkflowStepModalOpenedInBulk = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): boolean => {
  return changeWorkflowStepModalData.isInBulk;
};

export const isWorkflowStepModalOpenedInItemEditing = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): boolean => {
  return !changeWorkflowStepModalData.isInBulk;
};

export const createUpdatedAssignmentForUpdateWorkflowStep = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
): Partial<IAssignment> => {
  return {
    assignees: changeWorkflowStepModalData.contributors,
    due: changeWorkflowStepModalData.dueDate.value,
    note: changeWorkflowStepModalData.note,
    workflowStatus: changeWorkflowStepModalData.workflowStep,
  };
};
