import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  Subscription_ResendInvitation_Failed,
  Subscription_ResendInvitation_Finished,
  Subscription_ResendInvitation_Started,
} from '../../constants/actionTypes.ts';

interface IResendInvitationToAdminDependencies {
  readonly subscriptionRepository: {
    readonly resendInvitationToAdmin: (subscriptionId: Uuid, userId: Uuid) => Promise<void>;
  };
}

const started = (userId: Uuid) =>
  ({
    type: Subscription_ResendInvitation_Started,
    payload: {
      userId,
    },
  }) as const;

const finished = (userId: Uuid) =>
  ({
    type: Subscription_ResendInvitation_Finished,
    payload: {
      userId,
    },
  }) as const;

const failed = (userId: Uuid) =>
  ({
    type: Subscription_ResendInvitation_Failed,
    payload: {
      userId,
    },
  }) as const;

export type ResendInvitationToAdminActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const resendInvitationToAdminCreator =
  (dependencies: IResendInvitationToAdminDependencies) =>
  (subscriptionId: Uuid, userId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(started(userId));
      await dependencies.subscriptionRepository.resendInvitationToAdmin(subscriptionId, userId);
      dispatch(finished(userId));
    } catch (error) {
      dispatch(failed(userId));
      throw error;
    }
  };
