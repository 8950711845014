import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../../_shared/models/SidebarNames.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  comparePlanWithUsage,
  getExtraUsage,
  isPlanDowngrade,
} from '../../shared/utils/planUtils.ts';
import { closeChangePlanDialog } from '../actions/planSelectionActions.ts';
import { changeSubscriptionPlan } from '../actions/thunkPlanSelectionActions.ts';
import { ChangePlanModal as ChangePlanModalComponent } from '../components/ChangePlanModal.tsx';

export const ChangePlanModal: React.FC = () => {
  const changeInProgress = useSelector((state) => state.subscriptionApp.plans.changeInProgress);
  const selectedSubscription = useSelector(getSelectedSubscription);

  const targetPlan = useSelector((state) =>
    state.data.plans.byId.get(state.subscriptionApp.plans.selectedPlanIdToChange),
  );
  const subscriptionUsage = useSelector((state) =>
    state.data.subscriptions.subscriptionUsages.get(selectedSubscription?.subscriptionId ?? ''),
  );
  const planComparison = comparePlanWithUsage(targetPlan, subscriptionUsage);
  const extraUsage =
    targetPlan && subscriptionUsage
      ? getExtraUsage(targetPlan, subscriptionUsage.currentActiveUsers)
      : false;
  const currentPlan = useSelector((state) =>
    getSubscriptionPlan(state, selectedSubscription?.subscriptionId ?? ''),
  );

  const showSidebar = useSelector((state) => {
    const sidebarStatus = state.sharedApp.sidebarStatus;

    return sidebarStatus.isVisible && sidebarStatus.sidebarName === SidebarNames.ChangePlanModal;
  });

  const dispatch = useDispatch();
  const closeDialog = () => dispatch(closeChangePlanDialog());
  const onChangeSubscriptionPlan = (planName: string, id: Uuid, skipCreditCardCheck?: boolean) =>
    dispatch(changeSubscriptionPlan(planName, id, skipCreditCardCheck));

  if (!selectedSubscription) {
    return null;
  }

  return (
    <ChangePlanModalComponent
      closeDialog={closeDialog}
      showSidebar={showSidebar}
      changeIsInProgress={changeInProgress}
      changeSubscriptionPlan={onChangeSubscriptionPlan}
      currentSubscription={selectedSubscription}
      planComparison={planComparison}
      targetPlan={targetPlan}
      currentPlan={currentPlan}
      isPlanDowngrade={!!targetPlan && isPlanDowngrade(currentPlan, targetPlan)}
      hasExtraUsage={!!extraUsage}
    />
  );
};

ChangePlanModal.displayName = 'ChangePlanModalContainer';
