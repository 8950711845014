import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { LocalizedRouteLeft } from '../../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { Calendar_Page_Left } from '../../../../applications/contentPlanning/calendar/constants/calendarActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../applications/features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { SearchMethod } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { Data_ListingContentItems_Success } from '../../../constants/dataActionTypes.ts';

const initialState: SearchMethod = SearchMethod.Standard;

export const usedSearchMethod = (
  state: SearchMethod = initialState,
  action: Action,
): SearchMethod => {
  switch (action.type) {
    case Data_ListingContentItems_Success:
      return action.payload.searchMethod;

    case LocalizedRouteLeft:
    case Shared_CurrentProjectId_Updated:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case Calendar_Page_Left:
      return initialState;

    default:
      return state;
  }
};
