import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ContextualHelpSettingsServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { IContextualHelpServerModel } from '../../../../repositories/serverModels/ContextualHelpServerModel.type.ts';
import { ContextualHelpType } from '../../../models/contextualHelp/ContextualHelpTypeEnum.ts';

interface IDeps {
  readonly upsertUserProperty: (propertyKey: string, propertyValue: string) => ThunkPromise;
}

const mapToServerModel = (
  contextualHelp: Immutable.Map<ContextualHelpType, boolean>,
): IContextualHelpServerModel => ({
  showForContentType: contextualHelp.get(ContextualHelpType.ContentType, false),
  showForContentTypes: contextualHelp.get(ContextualHelpType.ContentTypes, false),
  showForContentItem: contextualHelp.get(ContextualHelpType.ContentItem, false),
  showForContentItems: contextualHelp.get(ContextualHelpType.ContentItems, false),
  showForMyContent: contextualHelp.get(ContextualHelpType.YourContent, false),
});

export const dismissContextualHelpCreator =
  (deps: IDeps) =>
  (contextualHelpType: ContextualHelpType): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      sharedApp: {
        userProperties: { contextualHelpSettings },
      },
    } = getState();

    try {
      const updatedContextualHelp = contextualHelpSettings.set(contextualHelpType, false);
      const apiModel = mapToServerModel(updatedContextualHelp);
      await dispatch(
        deps.upsertUserProperty(ContextualHelpSettingsServerKey, JSON.stringify(apiModel)),
      );
    } catch (e) {
      logError('dismissContextualHelp.ts: Failed to upsert property.', e);
    }
  };
