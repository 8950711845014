import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  isAiLinkedItemsEnabled,
  isAiSemanticSearchEnabled,
} from '../../../../../_shared/selectors/aiSelectors.ts';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeatureWithSemanticSearch } from '../../thunks/deactivateInnovationLabFeatureWithSemanticSearch.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';
import { FeatureActivationDialog } from '../FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../InnovationLabFeatureGenericallyUnavailable.tsx';
import {
  InnovationLabActivationState,
  InnovationLabStatus,
} from '../InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericConsent } from '../InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../InnovationLabGenericDeactivationWarning.tsx';

const useGetLinkedItemsActivationState = (): InnovationLabActivationState => {
  const isSemanticSearchActivated = useSelector(isAiSemanticSearchEnabled);
  const isLinkedItemsActivated = useSelector(AiLinkedItemsCard.isFeatureEnabledSelector);

  if (isSemanticSearchActivated && isLinkedItemsActivated) {
    return 'active';
  }

  if (isSemanticSearchActivated && !isLinkedItemsActivated) {
    return 'activation-pending';
  }

  return 'inactive';
};

export const AiLinkedItemsCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const featureName = AiLinkedItemsCard.featureName;
  const activationState = useGetLinkedItemsActivationState();

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('ai-semantic-search'),
    deactivateInnovationLabFeatureWithSemanticSearch('ai-linked-items'),
  );

  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard component="section" featureState={featureState}>
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>
              Let AI analyze your content and suggest relevant content items. Make your content
              richer and more connected by{' '}
              <OutwardLink href={documentationLinks.aiSuggestedLinkedItems}>
                linking items together
              </OutwardLink>
              .
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
            />
          </Stack>
        </Card.Body>
        <Card.Footer>
          <Stack spacing={Spacing.XL}>
            <InnovationLabFeatureButton
              activationState={activationState}
              featureName={featureName}
              featureState={featureState}
              isAsyncOperationInProgress={isAsyncOperationInProgress}
              onClick={onCardActionButtonClick}
            />
            {activationState === 'activation-pending' && (
              <Callout calloutType="friendlyWarning">
                Feature is being activated, which could take from a couple of minutes to a few days,
                depending on your project’s complexity. While this is in progress, you can continue
                your work as usual.
              </Callout>
            )}
          </Stack>
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline={`Activate ${featureName}`}
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <Callout calloutType="quickTip" hideSubheadline>
            <p>
              <b>Innovation Lab feature activation</b>
            </p>
            <p>You’re about to activate the {featureName} feature.</p>
            <p>
              AI can analyze your content and suggest relevant linked items. You can provide
              feedback on the suggestions you receive from AI to help us improve your experience.
            </p>
          </Callout>
          <InnovationLabGenericConsent />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline={`Deactivate ${featureName}`}
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName={featureName} />
      </FeatureDeactivationDialog>
    </>
  );
};

AiLinkedItemsCard.isFeatureEnabledSelector = isAiLinkedItemsEnabled;
AiLinkedItemsCard.featureName = 'Suggest linked items with AI';
AiLinkedItemsCard.releaseDate = '2024-05-16T00:00:00.000Z';
