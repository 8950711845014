import { combineReducers } from '@reduxjs/toolkit';
import { collectionGroups } from './userDetail/collectionGroups.ts';
import { isInitialized } from './userDetail/isInitialized.ts';
import { isSaving } from './userDetail/isSaving.ts';
import { savingError } from './userDetail/savingError.ts';

export const userDetail = combineReducers({
  collectionGroups,
  isInitialized,
  isSaving,
  savingError,
});
