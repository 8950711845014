import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_SelectedLanguageChanged,
} from '../../constants/newContentItemActionTypes.ts';

export function languageId(state: Uuid = '', action: Action): Uuid {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.languageId;

    case NewContentItem_SelectedLanguageChanged:
      return action.payload.languageId;

    default:
      return state;
  }
}
