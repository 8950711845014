import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { AssetTilePreview } from '../../../../../../../_shared/components/AssetTile/AssetTilePreview.tsx';
import { AssetTileProgressBar } from '../../../../../../../_shared/components/AssetTile/AssetTileProgressBar.tsx';
import { AssetTileSummary } from '../../../../../../../_shared/components/AssetTile/AssetTileSummary.tsx';
import { getAssetDescriptionBySelectedLanguage } from '../../../../../../../_shared/selectors/AssetTile/getAssetDescription.ts';
import { getDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAssetRendition } from '../../../../../../../data/models/assetRenditions/AssetRendition.ts';
import { Asset, IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { AssetThumbnailBottomClassname } from '../../../../../../contentInventory/assets/components/AssetTile/AssetTile.tsx';
import { CannotViewAssetsMessage } from '../../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import {
  ElementAttributes,
  getAssetIdAttribute,
} from '../../../../../constants/elementAttributes.ts';
import { AssetTileActions } from '../../../containers/elements/asset/AssetTileActions.tsx';
import { AssetTileAction } from './AssetTileActions.tsx';

export interface IAssetValidationResult {
  readonly isAssetFileSizeValid: boolean;
  readonly isAssetFileTypeValid: boolean;
  readonly isAssetHeightValid: boolean;
  readonly isAssetWidthValid: boolean;
}

export interface IAssetTileOwnProps {
  readonly actions: ReadonlyArray<AssetTileAction>;
  readonly className?: string;
  readonly commentSegmentId?: Uuid;
  readonly commentThreadId: Uuid | null;
  readonly connectDragSource?: ConnectDragSource;
  readonly contentComponentId: Uuid | null;
  readonly disabled: boolean;
  readonly elementId: Uuid;
  readonly hasFocusedComment: boolean;
  readonly isDragging: boolean;
  readonly onEdit?: () => void;
  readonly onNewComment: () => void;
  readonly onOpenRenditionDialog?: () => void;
  readonly onRemove?: () => void;
  readonly renditionId?: Uuid;
}

export interface IAssetTileStateProps {
  readonly actions: ReadonlyArray<AssetTileAction>;
  readonly asset: IAsset;
  readonly canViewAnyActiveLanguage: boolean;
  readonly canViewAsset: boolean;
  readonly collectionName: string | null;
  readonly isHeightLimitationSet: boolean;
  readonly isUncategorized: boolean | null;
  readonly isWidthLimitationSet: boolean;
  readonly onEdit?: () => void;
  readonly onOpenRenditionDialog?: () => void;
  readonly rendition?: IAssetRendition;
  readonly searchPhrase: string;
  readonly selectedLanguageId: Uuid | null;
  readonly validationResult: IAssetValidationResult;
}

interface IAssetTileProps extends IAssetTileOwnProps, IAssetTileStateProps {}

export const AssetTile = forwardRef<HTMLDivElement, IAssetTileProps>(
  (
    {
      actions,
      asset,
      canViewAnyActiveLanguage,
      canViewAsset,
      collectionName,
      commentThreadId,
      className,
      commentSegmentId,
      connectDragSource,
      disabled,
      hasFocusedComment,
      isDragging,
      isHeightLimitationSet,
      isUncategorized,
      isWidthLimitationSet,
      onEdit,
      onNewComment,
      onOpenRenditionDialog,
      onRemove,
      rendition,
      renditionId,
      searchPhrase,
      selectedLanguageId,
      validationResult,
    },
    ref,
  ) => {
    const { refObject: assetTileRef, refToForward } = useAttachRef(ref);

    const edit = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      onEdit?.();
    };

    const remove = (): void => {
      if (onRemove) {
        assetTileRef.current?.blur();
        onRemove();
      }
    };

    const altText =
      (selectedLanguageId &&
        getAssetDescriptionBySelectedLanguage(asset.descriptions, selectedLanguageId)) ??
      '';
    const isClickable =
      canViewAnyActiveLanguage && canViewAsset && !asset._failed && !asset._uploading;

    return (
      <Tooltip
        tooltipText={canViewAnyActiveLanguage ? undefined : CannotViewAssetsMessage}
        placement="right"
      >
        <div
          tabIndex={0}
          ref={refToForward}
          className={classNames('asset-thumbnail', className, {
            'asset-thumbnail--is-disabled': disabled || asset._uploading,
            'asset-thumbnail--is-not-clickable': !isClickable,
            'asset-thumbnail--is-invalid': !Asset.exists(asset),
            'asset-thumbnail--is-dragging': isDragging,
            'asset-thumbnail--is-not-allowed': !validationResult.isAssetFileTypeValid,
          })}
          {...getDataUiObjectNameAttribute(asset.title || asset.filename)}
          {...getAssetIdAttribute(asset.id)}
          {...getDataAttribute(
            ElementAttributes.RichTextCommentSegmentId,
            commentSegmentId ?? undefined,
          )}
        >
          <AssetTileActions
            actions={actions}
            asset={asset}
            commentThreadId={commentThreadId}
            disabled={disabled}
            hasFocusedComment={hasFocusedComment}
            isUncategorized={canViewAsset && isUncategorized}
            onNewComment={onNewComment}
            transformation={rendition?.transformation}
            onOpenRenditionDialog={onOpenRenditionDialog}
            onRemove={remove}
            connectDragSource={connectDragSource}
          />
          <AssetTileProgressBar asset={asset} />
          <div
            onClick={isClickable ? edit : undefined}
            {...getDataUiActionAttribute(DataUiAction.EditAsset)}
          >
            <AssetTilePreview
              asset={asset}
              altText={altText}
              canViewAsset={canViewAsset}
              transformation={rendition?.transformation}
            />
            <div
              className={classNames(
                AssetThumbnailBottomClassname,
                `${AssetThumbnailBottomClassname}--with-padding`,
              )}
            >
              <AssetTileSummary
                asset={asset}
                canViewAsset={canViewAsset}
                collectionName={collectionName}
                isHeightLimitationSet={isHeightLimitationSet}
                isWidthLimitationSet={isWidthLimitationSet}
                rendition={rendition}
                renditionId={renditionId}
                searchPhrase={searchPhrase}
                validationResult={validationResult}
              />
            </div>
          </div>
        </div>
      </Tooltip>
    );
  },
);

AssetTile.displayName = 'AssetTile';
