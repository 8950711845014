import { getContrast as getContrastPolished, mix } from 'polished';
import { Color } from '../tokens/quarks/colors.ts';
import { GradientAngle, GradientType } from '../tokens/quarks/gradients.ts';

export const linearGradient = (angle: GradientAngle, from: Color, to: Color): GradientType => {
  return {
    toString: () => `linear-gradient(${angle}deg, ${from}, ${to})`,
    from,
    to,
  };
};

const referenceGradientProperties = Object.keys(linearGradient(90, '#000000', '#000000'));

export const isGradient = (something: GradientType | Color): something is GradientType => {
  return (
    typeof something === 'object' &&
    referenceGradientProperties.every((prop) => Object.hasOwn(something, prop))
  );
};

export const convertToColor = (colorOrGradient: Color | GradientType): string => {
  if (isGradient(colorOrGradient)) {
    return mix(0.5, colorOrGradient.from, colorOrGradient.to);
  }
  return colorOrGradient;
};

export const getContrast = (
  colorOrGradient1: Color | GradientType,
  colorOrGradient2: Color | GradientType,
): number => {
  const color1 = convertToColor(colorOrGradient1);
  const color2 = convertToColor(colorOrGradient2);

  return getContrastPolished(color1, color2);
};
