import { makeId } from '../idUtils.ts';

const combineIds = (id1: string, id2: string, id3: string) => `${id1};${id2};${id3}`;

export type VariantTranslationTaskId = string & { readonly __typename: unique symbol };

export const makeVariantTranslationTaskId = (
  environmentId: string,
  itemId: string,
  variantId: string,
): VariantTranslationTaskId =>
  makeId<VariantTranslationTaskId>(combineIds(environmentId, itemId, variantId));
