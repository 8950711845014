import { usePrevious } from '@kontent-ai/hooks';
import { nameof } from '@kontent-ai/utils';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { SidebarNames } from '../../../../_shared/models/SidebarNames.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
  getSubscriptionAvailableProjectLocations,
  getSubscriptionsSortedByName,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { closeCloneProjectModal } from '../../actions/projectsActions.ts';
import { cloneProject } from '../../actions/thunkProjectsActions.ts';
import { CloneProjectModal } from '../../components/projects/CloneProjectModal.tsx';
import { ICloneProjectFormShape } from '../../models/ICloneProjectFormShape.type.ts';
import { getCloneProjectFormDefaultValues } from '../../selectors/getCloneProjectFormDefaultValues.ts';
import { canProjectBeCopied, getCopyProjectValidationData } from '../../utils/copyProjectUtils.ts';

const getSidebarVisibility = (state: IStore): boolean => {
  const { administratedIds, byId } = state.data.subscriptions;
  const { isVisible, sidebarName } = state.sharedApp.sidebarStatus;
  const administratedSubscriptions = getAdministratedSubscriptions(administratedIds, byId);
  const hasAnyActiveSubscription = getActiveSubscriptions(administratedSubscriptions).length > 0;

  return isVisible && sidebarName === SidebarNames.CloneProjectModal && hasAnyActiveSubscription;
};

const getDestinationLocationAvailability = (
  state: IStore,
  destinationSubscriptionId: Uuid,
  destinationLocationId: Uuid,
): boolean => {
  const availableProjectLocations = getSubscriptionAvailableProjectLocations(
    state,
    destinationSubscriptionId,
  );

  return !!availableProjectLocations.find(
    (loc: IProjectLocation) => loc.projectLocationId === destinationLocationId,
  );
};

export const CloneProjectSidebarModal: React.FC = () => {
  const administratedSubscriptions = useSelector((state) =>
    getAdministratedSubscriptions(
      state.data.subscriptions.administratedIds,
      state.data.subscriptions.byId,
    ),
  );
  const sortedSubscriptions = getSubscriptionsSortedByName(administratedSubscriptions);
  const activeSubscriptions = getActiveSubscriptions(sortedSubscriptions);

  const sourceProjectId = useSelector(
    (state) => state.projectsApp.cloneProjectInfo.sourceProjectId,
  );

  const projectIsBeingCloned = useSelector(
    (state) => state.projectsApp.cloneProjectInfo.projectIsBeingCloned,
  );
  const errorMessage = useSelector((state) => state.projectsApp.cloneProjectInfo.errorMessage);

  const initialValues = useSelector((state) =>
    getCloneProjectFormDefaultValues(state, sourceProjectId, activeSubscriptions),
  );

  const showSidebar = useSelector(getSidebarVisibility);

  const formProps = useForm<ICloneProjectFormShape>({
    defaultValues: initialValues,
    resolver: createFormValidationResolver<ICloneProjectFormShape>(
      {
        projectName: [nonEmptyValidationBuilder('project name')],
      },
      {},
    ),
  });

  const { handleSubmit, setValue, watch } = formProps;
  const prevShowSidebar = usePrevious(showSidebar);

  useEffect(() => {
    if (!prevShowSidebar && showSidebar) {
      Object.keys(initialValues).forEach((initialProperty) => {
        setValue(initialProperty, initialValues[initialProperty]);
      });
    }
  }, [setValue, initialValues, prevShowSidebar, showSidebar]);

  const dispatch = useDispatch();
  const onCloseDialog = useCallback(() => {
    dispatch(closeCloneProjectModal());
  }, []);

  const { destinationLocationId, destinationSubscriptionId } = watch();

  /* As we obtain copyProjectDataInfo data from server while trying to submit form, this limitation is not based on form state
  and form is not re-validated after this object is being received. Also it's impossible to start form validation manually.
  That's why we have to validate it separately from the rest of the form */
  const copyProjectValidationData = useSelector((state) =>
    getCopyProjectValidationData(state, sourceProjectId, destinationSubscriptionId),
  );
  const canBeCloned = canProjectBeCopied(copyProjectValidationData);

  const submitForm = handleSubmit((values) => {
    return dispatch(cloneProject(sourceProjectId, values));
  });

  const isDestinationLocationAvailable = useSelector((state) =>
    getDestinationLocationAvailability(state, destinationSubscriptionId, destinationLocationId),
  );

  useEffect(() => {
    // Ensure default datacenter when location selection disabled or when destination subscription was changed and selected datacenter is not available
    if (!isDestinationLocationAvailable) {
      setValue(
        nameof<ICloneProjectFormShape>('destinationLocationId'),
        initialValues.destinationLocationId,
      );
    }
  }, [isDestinationLocationAvailable, setValue, initialValues.destinationLocationId]);

  return (
    <CloneProjectModal
      activeSubscriptions={activeSubscriptions}
      canSubmitForm={canBeCloned}
      cloningProject={projectIsBeingCloned}
      closeDialog={onCloseDialog}
      destinationSubscriptionId={destinationSubscriptionId}
      errorMessage={errorMessage}
      formProps={formProps}
      onSubmit={submitForm}
      showSidebar={showSidebar}
      subscriptionProjectsLimit={copyProjectValidationData.targetPlanLimits.maxSubscriptionProjects}
      subscriptionProjectsLimitExceeded={
        copyProjectValidationData.validationErrors.maxSubscriptionProjectsReached
      }
    />
  );
};

CloneProjectSidebarModal.displayName = 'CloneProjectSidebarModal';
