import { EditorState } from 'draft-js';
import { IValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { getCharacterCount } from '../../../richText/plugins/textApi/api/editorTextUtils.ts';
import { ElementError, ElementErrorResult, emptyElementErrorResult } from './types/Errors.ts';

interface IParams {
  readonly value: EditorState;
}

const invalidElementResult: ElementErrorResult = {
  errors: [ElementError.TextTooLong],
};

export const createRichTextElementErrorChecker =
  (validationConstants: IValidationConstants) =>
  ({ value }: IParams): ElementErrorResult => {
    const characterCount = getCharacterCount(value.getCurrentContent());
    const isValid = characterCount <= validationConstants.RichTextElementValueMaxLength;

    return isValid ? emptyElementErrorResult : invalidElementResult;
  };
