import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentModels_TypeEditor_LinkedItemsDefaultValueReferences_Added } from '../../constants/sharedContentModelsActionTypes.ts';
import { IDefaultValueLinkedItem } from '../../reducers/IContentModelsAppStoreState.type.ts';

const loaded = (defaultValueReferences: ReadonlyArray<IDefaultValueLinkedItem>) =>
  ({
    type: ContentModels_TypeEditor_LinkedItemsDefaultValueReferences_Added,
    payload: {
      defaultValueReferences,
    },
  }) as const;

export type LoadNewLinkedItemsDefaultValueReferencesActionType = ReturnType<typeof loaded>;

export const createLoadNewLinkedItemsDefaultValueReferences =
  () =>
  (addedItemIds: ReadonlyArray<Uuid>): ThunkFunction =>
  (dispatch, getState) => {
    const loadedContentItems = getState().data.listingContentItems.byId;
    const linkedItems = loadedContentItems.filter((_, id) => addedItemIds.includes(id)).toArray();

    dispatch(loaded(mapLinkedItems(linkedItems)));
  };

const mapLinkedItems = (
  result: ReadonlyArray<IListingContentItem>,
): ReadonlyArray<IDefaultValueLinkedItem> =>
  result.map(
    (listingItem): IDefaultValueLinkedItem => ({
      activeCapabilities: listingItem.activeCapabilities,
      collectionId: listingItem.item.collectionId,
      isItemArchived: listingItem.item.archived,
      isTranslated: !!listingItem.variant && !listingItem.variant.isArchived,
      itemId: listingItem.item.id,
      itemName: listingItem.item.name,
      typeId: listingItem.item.typeId,
    }),
  );
