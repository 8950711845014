import { TreeProps } from '@react-stately/tree';
import { VerticalMenu, VerticalMenuProps } from './VerticalMenu.tsx';
import {
  VirtualizedVerticalMenu,
  VirtualizedVerticalMenuProps,
} from './VirtualizedVerticalMenu.tsx';
import { VerticalMenuItem, VerticalMenuSection } from './types.ts';
import { useNonAccessibleVerticalMenu } from './useNonAccessibleVerticalMenu.ts';

export const useVerticalMenu = <TItem extends VerticalMenuItem<TItem>>(
  items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined,
  treeProps?: Omit<TreeProps<TItem>, 'items' | 'children'>,
) => {
  const { verticalMenuState } = useNonAccessibleVerticalMenu(items, treeProps);

  return {
    verticalMenuState,
    verticalMenuProps: { items },
    VerticalMenu: VerticalMenu as React.FC<VerticalMenuProps<TItem>>,
    VirtualizedVerticalMenu: VirtualizedVerticalMenu as React.FC<
      VirtualizedVerticalMenuProps<TItem>
    >,
  };
};
