import { getElementOffset } from '@kontent-ai/DOM';
import {
  DataUiAttributes,
  DataUiElement,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ContentGroupTabCssClass,
  SelectedContentGroupTabCssClass,
} from '../../../../itemEditor/features/ContentItemEditing/components/ContentGroupTab.tsx';
import { ContentGroupTabsCssClass } from '../../../../itemEditor/features/ContentItemEditing/components/ContentGroupTabs.tsx';
import { IComponentPathItem } from '../api/editorContentComponentUtils.ts';

export const ContentComponentCssClass = 'content-component';
export const CollapsedContentComponentCssClass = 'content-component--is-collapsed';
export const ContentComponentHeaderCssClass = 'content-component__header';

export function getDeepestCollapsedComponentPosition(
  path: ReadonlyArray<IComponentPathItem>,
): number | null {
  for (let i = path.length - 1; i >= 0; i--) {
    const contentComponent = path[i];
    if (!contentComponent) {
      return null;
    }

    const componentSelector = `.${ContentComponentCssClass}.${CollapsedContentComponentCssClass}[${DataUiAttributes.Element}="${DataUiElement.ContentComponentItem}"][${DataUiAttributes.ComponentId}="${contentComponent.contentComponentId}"]`;
    const componentPosition = getElementOffset(componentSelector);
    if (componentPosition !== null) {
      return componentPosition;
    }
  }

  return null;
}

export function getDeepestUnselectedContentGroupPositionInComponent(
  path: ReadonlyArray<IComponentPathItem>,
): number | null {
  for (let i = path.length - 1; i >= 0; i--) {
    const contentComponent = path[i];
    if (!contentComponent) {
      return null;
    }

    const groupSelector = `.${ContentGroupTabsCssClass}[${DataUiAttributes.ComponentId}="${contentComponent.contentComponentId}"] .${ContentGroupTabCssClass}:not(.${SelectedContentGroupTabCssClass})[${DataUiAttributes.ObjectName}="${contentComponent.contentGroupId}"]`;
    const groupPosition = getElementOffset(groupSelector);
    if (groupPosition !== null) {
      return groupPosition;
    }
  }

  return null;
}
