import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  ItemEditorOperationId,
  itemEditorOperationIds,
  parseElementOperationId,
} from '../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  ContentItemEditing_CodenameEditor_Closed,
  ContentItemEditing_CodenameEditor_ErrorStatusCleared,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemElementsSaving_Failed,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemElementsSaving_Started,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_Saving_Failed,
  ContentItemEditing_Saving_Started,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  EditedContentItemFailure,
  IEditedContentItemStatus,
  InitialEditedContentItemStatus,
} from '../../models/contentItem/edited/EditedContentItemStatus.ts';
import { apiErrorToFailureStatus } from '../../utils/apiErrorToFailureStatus.ts';
import { shouldResetEditedItemState } from '../utils/shouldResetEditedItemState.ts';

const initialState: IEditedContentItemStatus = InitialEditedContentItemStatus;

export function editedContentItemStatus(
  state = initialState,
  action: Action,
): IEditedContentItemStatus {
  if (shouldResetEditedItemState(action)) {
    return initialState;
  }

  switch (action.type) {
    case ContentItemEditing_Saving_Started: {
      return {
        ...state,
        saving: Collection.add(state.saving, action.payload.operationId),
      };
    }

    case ContentItemEditing_ItemElementsSaving_Started: {
      return {
        ...state,
        saving: Collection.addMany(state.saving, action.payload.operationIds),
      };
    }

    case ContentItemEditing_ItemSaving_Finished: {
      return {
        ...state,
        saving: Collection.remove(state.saving, action.payload.operationId),
        failures: Collection.remove(state.failures, action.payload.operationId),
      };
    }

    case ContentItemEditing_ItemElementsSaving_Finished: {
      return {
        ...state,
        saving: Collection.removeMany(state.saving, action.payload.operationIds),
        failures: removeSuccessfullySavedElementsFromFailures(
          action.payload.operationIds,
          state.failures,
        ),
      };
    }

    case ContentItemEditing_ItemElementsSaving_Failed: {
      const payload = action.payload;
      const failure = payload.failure ?? apiErrorToFailureStatus(payload.apiError);

      return {
        ...state,
        saving: Collection.removeMany(state.saving, payload.operationIds),
        failures: Collection.addMany(
          state.failures,
          payload.operationIds.map((operationId) => [operationId, failure]),
        ),
      };
    }

    case ContentItemEditing_CodenameEditor_Closed:
    case ContentItemEditing_CodenameEditor_ErrorStatusCleared: {
      return {
        ...state,
        failures: Collection.remove(state.failures, itemEditorOperationIds.codename),
      };
    }

    case ContentItemEditing_Saving_Failed: {
      const payload = action.payload;

      return {
        ...state,
        saving: Collection.remove(state.saving, payload.operationId),
        failures: Collection.add(state.failures, [
          payload.operationId,
          payload.failure ?? apiErrorToFailureStatus(payload.apiError),
        ]),
      };
    }

    case ContentItemEditing_Init_Ready:
      return initialState;

    default:
      return state;
  }
}

function removeSuccessfullySavedElementsFromFailures(
  savedElementsOperationIds: ReadonlyArray<ItemEditorOperationId>,
  failures: ReadonlyMap<ItemEditorOperationId, EditedContentItemFailure>,
): ReadonlyMap<ItemEditorOperationId, EditedContentItemFailure> {
  const savedElementIds = savedElementsOperationIds.map(
    (operationId) => parseElementOperationId(operationId).elementId,
  );
  const unsavedElementFailures = Array.from(failures).filter(([operationId]) => {
    const failedElementId = parseElementOperationId(operationId).elementId;
    return !savedElementIds.includes(failedElementId);
  });

  return new Map(unsavedElementFailures);
}
