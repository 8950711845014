import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { getUserRolesTitle } from '../../../utils/getUserRolesTitle.ts';

interface IUserRolesProps {
  readonly user: IProjectContributor;
}

export const UserRolesCell: React.FC<IUserRolesProps> = ({ user }) => {
  const allRoles = useSelector((state) => state.data.roles.rolesById);
  const rolesTitle = getUserRolesTitle(user.collectionGroups, allRoles);

  return <span title={rolesTitle}>{rolesTitle}</span>;
};

UserRolesCell.displayName = 'UserRolesCell';
