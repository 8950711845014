import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { CommonPlanName } from '../../../../data/constants/CommonPlanName.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getPlanName } from '../../shared/utils/planUtils.ts';
import { loadSubscriptionCurrentSpending } from '../actions/thunkCurrentUsageActions.ts';
import { CurrentUsage as CurrentUsageComponent } from '../components/CurrentUsage.tsx';

const plansAllowingChange: ReadonlyArray<string> = [
  CommonPlanName.Trial,
  CommonPlanName.Starter,
  CommonPlanName.Developer,
  CommonPlanName.Professional,
  CommonPlanName.Business,
  CommonPlanName.Premium,
];

export const CurrentUsage = () => {
  const subscriptionCurrentSpending = useSelector(
    (state) => state.data.subscriptions.subscriptionCurrentSpending,
  );

  const selectedSubscription = useSelector(getSelectedSubscription);
  const plan = useSelector((state) =>
    getSubscriptionPlan(state, selectedSubscription?.subscriptionId ?? ''),
  );
  const canChangePlan = plansAllowingChange.includes(getPlanName(plan.name));

  useThunkPromise(loadCurrentSpending, selectedSubscription?.subscriptionId ?? null, {
    canRun: !!selectedSubscription?.subscriptionId,
  });

  if (!selectedSubscription) {
    return null;
  }

  return (
    <CurrentUsageComponent
      canChangePlan={canChangePlan}
      currentPlan={plan}
      report={subscriptionCurrentSpending}
      subscription={selectedSubscription}
    />
  );
};

const loadCurrentSpending =
  (subscriptionId: Uuid | null, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    if (subscriptionId) {
      await dispatch(loadSubscriptionCurrentSpending(subscriptionId, abortSignal));
    }
  };
