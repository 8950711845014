import { Path } from 'history';
import {
  SmartLink_HandleSuccessfulRedirect,
  SmartLink_HandleUnsuccessfulRedirect,
  SmartLink_RouteEntered,
  SmartLink_RouteLeft,
} from '../constants/smartLinkActionTypes.ts';
import { KnownLocationState } from '../reducers/internalReducers/targetHistoryEntry.ts';

export const handleUnsuccessfulRedirect = (
  linkText: string | null,
  linkMessage: string | null,
  linkLocation: string | null,
) =>
  ({
    type: SmartLink_HandleUnsuccessfulRedirect,
    payload: {
      linkText,
      linkLocation,
      linkMessage,
    },
  }) as const;

export const handleSuccessfulRedirect = (path: Path, state?: KnownLocationState) =>
  ({
    type: SmartLink_HandleSuccessfulRedirect,
    payload: {
      path,
      state,
    },
  }) as const;

export const smartLinkRouteEntered = () =>
  ({
    type: SmartLink_RouteEntered,
  }) as const;

export const smartLinkRouteLeft = () =>
  ({
    type: SmartLink_RouteLeft,
  }) as const;
