export const Content_AssignedContentItems_OrderByChanged =
  'Content_AssignedContentItems_OrderByChanged';
export const Content_ContentItemListingFilter_Clear = 'Content_ContentItemListingFilter_Clear';
export const Content_Filter_Clear = 'Content_Filter_Clear';
export const Content_LoadedItems_ListingItemsLoaded = 'Content_LoadedItems_ListingItemsLoaded';
export const Content_LoadedItems_DefaultListingItemsLoaded =
  'Content_LoadedItems_DefaultListingItemsLoaded';
export const Content_LoadedContentItemTypes_TypesLoaded =
  'Content_LoadedContentItemTypes_TypesLoaded';
export const Content_YourContent_TabOpened = 'Content_MyContent_TabOpened';
