import { Action } from '../../../../@types/Action.type.ts';
import {
  ContentItemEditing_Elements_HighlightIncomplete,
  ContentItemEditing_Elements_ResetIsHighlighing,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function areContentItemElementsHighlighting(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_Elements_HighlightIncomplete:
      return true;

    case ContentItemEditing_Elements_ResetIsHighlighing:
      return false;

    default:
      return state;
  }
}
