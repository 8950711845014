import { Action } from '../../../../../@types/Action.type.ts';
import { IRegularWorkflowStepServerModel } from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';
import {
  Workflow_Editing_StepMoved,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
  Workflow_InitializeEditing_Started,
  Workflow_StepCreator_Cancel,
  Workflow_StepCreator_Initiated,
  Workflow_StepEditor_Delete,
} from '../../constants/workflowActionTypes.ts';

const initialState: UuidArray = [];

export const regularWorkflowStepsOrder = (
  state: UuidArray = initialState,
  action: Action,
): UuidArray => {
  switch (action.type) {
    case Workflow_InitializeEditing_Started: {
      return initialState;
    }

    case Workflow_InitializeCreating_Finished:
    case Workflow_InitializeEditing_Finished: {
      return action.payload.workflow.steps.map((step) => step.id);
    }

    case Workflow_Editing_WorkflowSaveFinished: {
      const stepsOrder = action.payload.workflow.statuses.map(
        (step: IRegularWorkflowStepServerModel) => step._id,
      );
      return stepsOrder;
    }

    case Workflow_StepCreator_Cancel:
    case Workflow_StepEditor_Delete: {
      return state.filter((stepId) => stepId !== action.payload.stepId);
    }

    case Workflow_StepCreator_Initiated: {
      const { workflowStep, precedingStepId } = action.payload;
      const precedingStepIndex = state.findIndex((stepId: Uuid) => stepId === precedingStepId);

      return [
        ...state.slice(0, precedingStepIndex + 1),
        workflowStep.id,
        ...state.slice(precedingStepIndex + 1, state.length),
      ];
    }

    case Workflow_Editing_StepMoved: {
      const { sourceId, targetId } = action.payload;
      const targetStepIndex = state.indexOf(targetId);

      const filteredSteps = state.filter((stepId) => stepId !== sourceId);

      return [
        ...filteredSteps.slice(0, targetStepIndex),
        sourceId,
        ...filteredSteps.slice(targetStepIndex, filteredSteps.length),
      ];
    }

    default:
      return state;
  }
};
