import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { BaseColor, IconSize } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { RelatedItemDoesNotExistReason } from '../../../applications/itemEditor/constants/errorMessages.ts';
import { buildThumbnailLinkForCustomizedImage } from '../../../applications/itemEditor/features/ContentItemEditing/utils/renditionUtils.ts';
import { IImageTransformation } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { Asset, IAsset } from '../../../data/models/assets/Asset.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { isImageMimeType, isPdfMimeType, isSvgMimeType } from '../../utils/fileTypeDetection.ts';

const MaxImageSizeThreshold = 32 * 1024 * 1024; // < 32 MB
const MaxPdfSizeThreshold = 256 * 1024 * 1024; // < 256 MB

const getOpacityStyle = (
  fileIsUploading: boolean,
  progressPercentage: number | null,
): React.CSSProperties | undefined => {
  if (!fileIsUploading) {
    return undefined;
  }

  return {
    opacity: (progressPercentage || 0) / 125 + 0.2,
  };
};

const isSupportedFileForThumbnailGeneration = (mimeType: string, fileSize: number): boolean => {
  if (fileSize === 0) {
    return false;
  }

  if (!isSvgMimeType(mimeType) && isImageMimeType(mimeType)) {
    return fileSize < MaxImageSizeThreshold;
  }

  if (isPdfMimeType(mimeType)) {
    return fileSize < MaxPdfSizeThreshold;
  }

  return false;
};

const GeneratingPreview: React.FC = () => (
  <div className="asset-thumbnail__preview asset-thumbnail__preview--in-progress">
    Generating preview
  </div>
);

const UploadingPreview: React.FC = () => (
  <div className="asset-thumbnail__preview asset-thumbnail__preview--in-progress">
    Uploading file
  </div>
);

const NoAccessAssetPreview: React.FC = () => (
  <div className="asset-thumbnail__preview asset-thumbnail__preview--with-icon asset-thumbnail__preview--no-access">
    <Icons.DocBanSign color={BaseColor.Yellow30} size={IconSize.XXL} />
  </div>
);

const MissingAssetPreview: React.FC = () => (
  <div
    className="asset-thumbnail__preview asset-thumbnail__preview--with-icon"
    {...getDataUiElementAttribute(DataUiElement.AssetThumbnailImage)}
  >
    <Tooltip placement="bottom" tooltipText={RelatedItemDoesNotExistReason}>
      <Icons.Picture
        color={BaseColor.OceanBlue70}
        size={IconSize.XXL}
        {...getDataUiElementAttribute(DataUiElement.AssetThumbnailImageMissing)}
      />
    </Tooltip>
  </div>
);

const UnknownPreview: React.FC = () => (
  <div
    className="asset-thumbnail__preview asset-thumbnail__preview--with-icon"
    {...getDataUiElementAttribute(DataUiElement.AssetThumbnailImage)}
  >
    <Icons.Doc
      color={BaseColor.OceanBlue70}
      size={IconSize.XXL}
      {...getDataUiElementAttribute(DataUiElement.AssetThumbnailImageUnknown)}
    />
  </div>
);

interface IAssetTilePreviewProps {
  readonly asset: IAsset;
  readonly altText: string;
  readonly canViewAsset?: boolean;
  readonly transformation?: IImageTransformation;
}

export const AssetTilePreview: React.FC<IAssetTilePreviewProps> = ({
  asset,
  altText,
  canViewAsset = true,
  transformation,
}) => {
  const uploadProgressPercentage = asset._uploaded;
  const fileIsUploading = !!asset._uploading;
  const fileSize = asset.fileSize;
  const thumbnailLink =
    transformation && asset._downloadLink
      ? buildThumbnailLinkForCustomizedImage(asset._downloadLink, transformation)
      : asset._thumbnailLink;

  // filePreview is always available when an asset is freshly added and being uploaded.
  // Therefore, the preview is shown either when file is an image or it has finished uploading
  // and server has provided a preview link
  if (!canViewAsset && Asset.exists(asset) && !fileIsUploading && !asset._failed) {
    return <NoAccessAssetPreview />;
  }
  if (thumbnailLink && canViewAsset && (!fileIsUploading || isImageMimeType(asset.type))) {
    return (
      <div
        className="asset-thumbnail__preview"
        {...getDataUiElementAttribute(DataUiElement.AssetThumbnailImage)}
      >
        <img
          draggable={false}
          className="asset-thumbnail__image"
          src={thumbnailLink}
          style={getOpacityStyle(fileIsUploading, uploadProgressPercentage)}
          alt={altText}
        />
      </div>
    );
  }
  if (fileIsUploading && !asset._failed) {
    return <UploadingPreview />;
  }
  if (isSupportedFileForThumbnailGeneration(asset.type, fileSize)) {
    return <GeneratingPreview />;
  }
  if (Asset.exists(asset)) {
    return <UnknownPreview />;
  }
  return <MissingAssetPreview />;
};

AssetTilePreview.displayName = 'AssetTilePreview';
