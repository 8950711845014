import Immutable from 'immutable';
import { ItemListOperationResultServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { reduceInChunks } from '../../../../contentInventory/content/utils/executeInChunks.ts';

type ListOperation = (
  ids: Immutable.Iterable<unknown, Uuid>,
) => Promise<ItemListOperationResultServerModel>;
type ListOperationResult = {
  readonly modifiedItemIds: Immutable.Set<Uuid>;
  readonly failedItemIds: Immutable.Set<Uuid>;
};

export const emptyListOperationResult: ListOperationResult = {
  modifiedItemIds: Immutable.Set(),
  failedItemIds: Immutable.Set(),
};

export const callBulkActionInChunks = async (
  itemsIds: Immutable.Set<Uuid>,
  action: ListOperation,
  maxChunkSize: number = 200,
): Promise<ListOperationResult> => {
  const initialResult: ListOperationResult = emptyListOperationResult;

  const results = await reduceInChunks(
    itemsIds,
    maxChunkSize,
    async (accPromise, chunk) => {
      const accumulated = await accPromise;
      const current = await action(chunk);
      return {
        modifiedItemIds: accumulated.modifiedItemIds.union([...current.modifiedItemIds]),
        failedItemIds: accumulated.failedItemIds.union(current.failedItems.map((f) => f.id)),
      };
    },
    Promise.resolve(initialResult),
  );

  return results;
};
