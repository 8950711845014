import { memoize } from '@kontent-ai/memoization';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { getNewContentItemForm } from './newContentItemDialog.ts';

const _getNewContentItemFormSelectedValues = memoize.maxOne(
  (
    name: string,
    languageId: Uuid,
    collectionId: Uuid | null,
    contentTypeId: Uuid | null,
    workflowId: Uuid | null,
  ): Record<SelectorType, Uuid | null> => ({
    [SelectorType.Name]: name,
    [SelectorType.Language]: languageId,
    [SelectorType.Collection]: collectionId,
    [SelectorType.ContentType]: contentTypeId,
    [SelectorType.Workflow]: workflowId,
  }),
);

export const getNewContentItemFormSelectedValues = (
  state: IStore,
): Record<SelectorType, Uuid | null> => {
  const form = getNewContentItemForm(state);

  return _getNewContentItemFormSelectedValues(
    form.name,
    form.languageId,
    form.collectionId,
    form.contentTypeId,
    form.workflowId,
  );
};
