import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { Size } from '../../tokens/decision/spacing.ts';
import { BorderRadius } from '../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../tokens/quarks/shadow.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { transition250 } from '../../tokens/quarks/transitions.ts';
import { px } from '../../tokens/utils/utils.ts';
import { Hint } from './Hint.tsx';
import { getHintTokens } from './utils/stylingUtils.ts';

interface IStyledHintProps extends Pick<ComponentProps<typeof Hint>, 'hintStyle'> {
  readonly isFocusVisible?: boolean;
}

export const StyledHint = styled.button.attrs(getHintTokens)<IStyledHintProps>`
  ${({ backgroundColor, backgroundColorHover, iconColor, iconColorHover, isFocusVisible }) => css`

    position: relative;
    cursor: help;

    display: flex;
    align-items: center;
    justify-content: center;

    width: ${px(Size.XS)};
    height: ${px(Size.XS)};

    padding: ${px(Spacing.XS)};

    border: none;
    border-radius: ${px(BorderRadius.S)};

    color: ${iconColor};
    background: ${backgroundColor};
    transition: background ${transition250};
    transition-property: background, color;

    ${isFocusVisible && shadowFocusStyles};

    &:hover,
    &:focus {
      background-color: ${backgroundColorHover};
      color: ${iconColorHover};
    }
  `};
`;
