import { Direction } from '@kontent-ai/types';
import { ContentBlock, ContentState, SelectionState } from 'draft-js';
import { isUuid } from '../../../../../_shared/utils/validation/typeValidators.ts';
import { setBlockDataValue } from '../../../utils/blocks/editorBlockUtils.ts';
import { getValidSelection } from '../../../utils/consistency/editorConsistencyUtils.ts';
import {
  createSelection,
  getBlocksAtSelection,
  getBoundingSelection,
  getFullySelectedBlocks,
  getMetadataAtSelection,
  getSelectionOfAllContent,
  preserveSelectionOverContentChange,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  changeBlock,
  deleteAtSelection,
  preserveBlockKeys,
} from '../../../utils/general/editorContentUtils.ts';
import { pasteContent } from '../../../utils/import/editorImportUtils.ts';
import {
  applyInlineStyleForSelectedChars,
  removeInlineStyleForSelectedChars,
} from '../../inlineStyles/api/editorStyleUtils.ts';
import { DraftJSInlineStyle } from '../../inlineStyles/api/inlineStyles.ts';
import { getAiInlineStyle, getAiSessionId, getAiStyle, isAiIdStyle } from './editorAiStyleUtils.ts';

const aiSessionIdKey = 'aiSessionId';

export const markAiContent = (
  input: IContentChangeInput,
  aiSessionId: Uuid,
): IContentChangeResult => {
  const withInlineStyle = applyInlineStyleForSelectedChars(
    input,
    getAiInlineStyle(aiSessionId) as DraftJSInlineStyle,
  );
  const fullySelectedBlocks = getFullySelectedBlocks(input.content, input.selection, true);
  const withBlockData = changeBlock(withInlineStyle, (block) =>
    !block.getLength() && fullySelectedBlocks.includes(block)
      ? // We only set aiSessionId to empty blocks as non-empty blocks have the metadata in their chars,
        // and we don't want it to be applied to start/end blocks the inserted content may merge with
        setBlockDataValue(block, aiSessionIdKey, aiSessionId)
      : block,
  );

  return withBlockData;
};

export const unmarkAiContent = (
  input: IContentChangeInput,
  aiSessionId: Uuid,
): IContentChangeResult => {
  const withInlineStyle = removeInlineStyleForSelectedChars(
    input,
    getAiInlineStyle(aiSessionId) as DraftJSInlineStyle,
  );
  const blocksAtSelection = getBlocksAtSelection(input.content, input.selection);
  const withBlockData = changeBlock(withInlineStyle, (block) =>
    block.getData().get(aiSessionIdKey) === aiSessionId && blocksAtSelection.includes(block)
      ? setBlockDataValue(block, aiSessionIdKey, undefined)
      : block,
  );

  return withBlockData;
};

export const markWholeContentAsAi = (content: ContentState, aiSessionId: Uuid): ContentState =>
  markAiContent(
    {
      content,
      selection: getSelectionOfAllContent(content),
    },
    aiSessionId,
  ).content;

export const findAiContent = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
): void => {
  contentBlock.findStyleRanges(
    (ch) => ch.getStyle().some((style) => !!style && isAiIdStyle(style)),
    callback,
  );
};

export const getAiContentSelections = (
  content: ContentState,
  aiSessionId: Uuid,
): ReadonlyArray<SelectionState> => {
  const blocks = getBlocks(content);
  const selections = blocks.flatMap((block) => {
    const blockKey = block.getKey();
    const blockAiSessionId = block.getData().get(aiSessionIdKey);

    if (isUuid(blockAiSessionId) && blockAiSessionId === aiSessionId) {
      return [createSelection(blockKey, 0, blockKey, block.getLength())];
    }

    const styleRanges: Array<SelectionState> = [];
    block.findStyleRanges(
      (ch) =>
        ch
          .getStyle()
          .some((style) => !!style && isAiIdStyle(style) && getAiSessionId(style) === aiSessionId),
      (start, end) => styleRanges.push(createSelection(blockKey, start, blockKey, end)),
    );

    return styleRanges;
  });

  return selections;
};

export const replaceAiContent = (
  input: IContentChangeInput,
  aiSessionId: Uuid,
  replaceWith: ContentState,
): IContentChangeResult => {
  const content = input.content;
  const existingResultSelections = getAiContentSelections(content, aiSessionId);
  if (!existingResultSelections.length) {
    return input;
  }

  const existingResultSelection = getBoundingSelection(existingResultSelections);

  const withNewResult = pasteContent(
    {
      content,
      selection: existingResultSelection,
    },
    replaceWith,
  );

  const withPreservedBlockKeys = preserveBlockKeys(withNewResult, input.content);

  const preservedSelection = preserveSelectionOverContentChange(
    input.selection,
    existingResultSelection,
    withPreservedBlockKeys.selection,
  );

  const newContent = withPreservedBlockKeys.content;
  const isPreservedSelectionValid =
    getValidSelection(newContent, preservedSelection, Direction.Forward) === preservedSelection;
  const newSelection = isPreservedSelectionValid
    ? preservedSelection
    : createSelection(
        withPreservedBlockKeys.selection.getEndKey(),
        withPreservedBlockKeys.selection.getEndOffset(),
      );

  const newContentWithSelection = setContentSelection(newContent, input.selection, newSelection);

  return {
    content: newContentWithSelection,
    selection: newSelection,
    wasModified: true,
  };
};

export const deleteAiContent = (
  input: IContentChangeInput,
  aiSessionId: Uuid,
): IContentChangeResult => {
  const content = input.content;
  const existingResultSelections = getAiContentSelections(content, aiSessionId);
  if (!existingResultSelections.length) {
    return input;
  }

  const existingResultSelection = getBoundingSelection(existingResultSelections);

  return deleteAtSelection(
    {
      content,
      selection: existingResultSelection,
    },
    Direction.Backward,
  );
};

export const getAiSessionIdAtSelection = (
  content: ContentState,
  selection: SelectionState,
): Uuid | null => {
  if (!selection.isCollapsed()) {
    return null;
  }

  const blockAiSessionId = content
    .getBlockForKey(selection.getStartKey())
    ?.getData()
    .get(aiSessionIdKey);
  if (blockAiSessionId) {
    return blockAiSessionId;
  }

  const metadataAtSelection = getMetadataAtSelection(content, selection);
  const styles =
    metadataAtSelection?.styleAtAnyTopLevelChars ?? metadataAtSelection?.styleAtAnyTableChars;
  if (!styles) {
    return null;
  }
  const firstAiStyle = getAiStyle(styles).first();
  return firstAiStyle ? getAiSessionId(firstAiStyle) : null;
};
