import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { EditableElementType } from '../../contentInventory/content/models/ContentItemElementType.ts';

interface IElementComponentLocator<T> {
  readonly getComponent: (type: EditableElementType) => React.FC<T>;
  readonly setComponent: (
    type: EditableElementType,
    component: React.FC<T>,
  ) => IElementComponentLocator<T>;
  readonly getGuidelines: () => React.FC<Omit<T, 'elementData'>>;
  readonly setGuidelines: (component: React.FC<T>) => IElementComponentLocator<T>;
}

export class ElementComponentLocator<T extends AnyObject> implements IElementComponentLocator<T> {
  #components = Immutable.Map<EditableElementType, React.FC<T>>({});
  #guidelines: React.FC<Omit<T, 'elementData'>> = React.Fragment;

  getComponent = (type: EditableElementType): React.FC<T> => {
    const component = this.#components.get(type);
    assert(component, () => `Component for type ${type} not found.`);
    return component;
  };

  setComponent = (
    type: EditableElementType,
    component: React.FC<T>,
  ): IElementComponentLocator<T> => {
    this.#components = this.#components.set(type, component);
    return this;
  };

  getGuidelines = (): React.FC<Omit<T, 'elementData'>> => {
    return this.#guidelines;
  };

  setGuidelines = (component: React.FC<Omit<T, 'elementData'>>): IElementComponentLocator<T> => {
    this.#guidelines = component;
    return this;
  };
}
