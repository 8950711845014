import { InvariantException } from '@kontent-ai/errors';
import { GetState, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ItemEditorOperationId } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { isRevisionCurrent } from '../../../Revisions/utils/revisionUtils.ts';
import { ContentItemEditing_AutoDispatchSave_Pending } from '../../constants/contentItemEditingActionTypes.ts';

export type AutoDispatchSaveActionType = ReturnType<typeof autoDispatchSavePending>;

type IAutoDispatchSavePending = (operationIds: ItemEditorOperationId[]) => ThunkFunction;

export const createAutoDispatchSavePending =
  (): IAutoDispatchSavePending =>
  (operationIds): ThunkFunction =>
  (dispatch, getState) => {
    if (!canUpdate(getState)) {
      return;
    }

    dispatch(autoDispatchSavePending(operationIds));
  };

const canUpdate = (getState: GetState<IStore>): boolean => {
  const { editedContentItemVariant, selectedRevision, entryTimeline, compareRevisions } =
    getState().contentApp;

  // Disallow updates when revision or revision comparison is displayed
  if (
    compareRevisions ||
    (entryTimeline && selectedRevision && !isRevisionCurrent(entryTimeline, selectedRevision))
  ) {
    return false;
  }

  if (!editedContentItemVariant) {
    throw InvariantException(
      `${__filename}: editedContentItemVariant is "${editedContentItemVariant}".`,
    );
  }

  return true;
};

const autoDispatchSavePending = (operationIds: ItemEditorOperationId[]) =>
  ({
    type: ContentItemEditing_AutoDispatchSave_Pending,
    payload: {
      operationIds,
    },
  }) as const;
