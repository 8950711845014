import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { doesAssetMatchFilterQuery } from '../../../../_shared/utils/filter/assetFilterUtils.ts';
import { shouldPreserveScrollPosition } from '../../../../_shared/utils/scrollGridUtils.ts';
import {
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_Asset_ArchiveCompleted,
  AssetLibrary_Asset_ReplaceFinished,
  AssetLibrary_Assets_ArchiveCompleted,
  AssetLibrary_Assets_AssignTermsCompleted,
  AssetLibrary_Assets_CreateAssets,
  AssetLibrary_Assets_MoveBackToFolderCompleted,
  AssetLibrary_Assets_MoveToFolderCompleted,
  AssetLibrary_Assets_RemoveFailedAsset,
  AssetLibrary_Assets_RestoreCompleted,
  AssetLibrary_Assets_UploadFinished,
  AssetLibrary_Folders_ArchiveCompleted,
  AssetLibrary_Folders_Opened,
  AssetLibrary_Folders_RestoreCompleted,
  AssetLibrary_Query_Changed,
  AssetLibrary_Query_FilterCleared,
} from '../../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import {
  AssetSelector_Closed,
  AssetSelector_Opened,
} from '../../../../applications/contentInventory/assets/features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';
import {
  Data_AssetListing_Started,
  Data_AssetListing_Success,
} from '../../../constants/dataActionTypes.ts';
import { IAsset } from '../../../models/assets/Asset.ts';

const initialState = Immutable.OrderedSet<Uuid>();

const mergeRestoredAssets = (
  state: Immutable.OrderedSet<Uuid>,
  restoredAssets: Immutable.Map<Uuid, IAsset>,
  openedFolderId: Uuid,
): Immutable.OrderedSet<Uuid> =>
  restoredAssets
    .filter((asset: IAsset) => asset.folderId === openedFolderId)
    .sort(compareAssets)
    .map((asset: IAsset) => asset.id)
    .toOrderedSet()
    .merge(state);

export function listingIds(state = initialState, action: Action): Immutable.OrderedSet<Uuid> {
  switch (action.type) {
    case AssetSelector_Closed:
    case AssetSelector_Opened:
    case Shared_CurrentProjectId_Updated:
    case AssetLibrary_Folders_Opened:
    case AssetLibrary_Query_Changed:
    case AssetLibrary_Query_FilterCleared:
      return initialState;

    case Data_AssetListing_Started:
      return shouldPreserveScrollPosition(action.payload.requestTrigger) ? state : initialState;

    case Data_AssetListing_Success: {
      const loadedAssetIds = action.payload.assets.map((asset) => asset.id);

      return shouldPreserveScrollPosition(action.payload.requestTrigger)
        ? state.concat(loadedAssetIds).toOrderedSet()
        : Immutable.OrderedSet.of(...loadedAssetIds);
    }

    case AssetLibrary_AssetEditor_SaveFinished: {
      return doesAssetMatchFilterQuery(action.payload.query, action.payload.asset)
        ? state
        : state.remove(action.payload.asset.id).toOrderedSet();
    }

    case AssetLibrary_Assets_AssignTermsCompleted: {
      return state
        .filter((id: Uuid) => {
          const changedAsset = action.payload.assets.get(id);
          if (!changedAsset) {
            // assets that were not changed by the bulk action are kept in listing
            return true;
          }

          return doesAssetMatchFilterQuery(action.payload.query, changedAsset);
        })
        .toOrderedSet();
    }

    case AssetLibrary_Assets_CreateAssets: {
      const assetKeys = Collection.getKeys(action.payload.files);

      return state
        .toList()
        .unshift(...assetKeys)
        .toOrderedSet();
    }

    case AssetLibrary_Asset_ArchiveCompleted:
      return state.filterNot((id: Uuid) => id === action.payload.asset.id).toOrderedSet();

    case AssetLibrary_Folders_ArchiveCompleted:
      return state.filter((id: Uuid) => !action.payload.successfulAssetsIds.has(id)).toOrderedSet();

    case AssetLibrary_Assets_ArchiveCompleted:
      return state.filter((id: Uuid) => !action.payload.successfulIds.has(id)).toOrderedSet();

    case AssetLibrary_Assets_MoveToFolderCompleted:
      return state.filter((id: Uuid) => !action.payload.successfulIds.has(id)).toOrderedSet();

    case AssetLibrary_Assets_MoveBackToFolderCompleted: {
      const { openedFolderId, successfulIds } = action.payload;

      const updatedAssets = Collection.filter(
        successfulIds,
        (folderReference) => folderReference.id === openedFolderId,
      );

      return Immutable.OrderedSet<Uuid>(updatedAssets.keys()).merge(state);
    }

    case AssetLibrary_Folders_RestoreCompleted:
    case AssetLibrary_Assets_RestoreCompleted:
      return mergeRestoredAssets(state, action.payload.assets, action.payload.openedFolderId);

    case AssetLibrary_Assets_UploadFinished:
    case AssetLibrary_Asset_ReplaceFinished: {
      const oldAssetId = action.payload.oldAssetId;
      const newAsset = action.payload.newAsset;

      if (!newAsset) {
        return state;
      }

      return state.map((id) => (id !== oldAssetId ? id : newAsset.id)).toOrderedSet();
    }

    case AssetLibrary_Assets_RemoveFailedAsset:
      return state.remove(action.payload.oldAssetId);

    default:
      return state;
  }
}

function compareAssets(a: IAsset, b: IAsset): number {
  const x = a.created || '';
  const y = b.created || '';

  return y.localeCompare(x);
}
