import { EditorState } from 'draft-js';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { containsNonPersistentContent } from '../../../utils/general/nonPersistentContentUtils.ts';
import { UndoRedoPlugin } from '../UndoRedoPlugin.tsx';
import { resetRedoStack } from './editorUndoUtils.ts';

export const editorUndoRedoApi: EditorApiImplementation<UndoRedoPlugin> = {
  canRedo(_, editorState) {
    return !editorState.getRedoStack().isEmpty();
  },

  canUndo(_, editorState) {
    return !editorState.getUndoStack().isEmpty();
  },

  redo(api, editorState) {
    if (!api.canRedo(editorState)) {
      return editorState;
    }

    const newEditorState = EditorState.redo(editorState);
    const withRestoredFocus = editorState.getSelection().getHasFocus()
      ? EditorState.forceSelection(newEditorState, newEditorState.getSelection())
      : newEditorState;

    return withRestoredFocus;
  },

  undo(api, editorState, allowRedo = true) {
    if (!editorState.getAllowUndo() || !api.canUndo(editorState)) {
      return editorState;
    }

    const content = editorState.getCurrentContent();

    const newEditorState = EditorState.undo(editorState);
    const withRestoredFocus = editorState.getSelection().getHasFocus()
      ? EditorState.forceSelection(newEditorState, newEditorState.getSelection())
      : newEditorState;

    // We don't allow redo in case non-persistent content is present as it could reintroduce obsolete
    // temporary content such as uploading files in progress which might not finish properly
    if (!allowRedo || containsNonPersistentContent(content)) {
      return resetRedoStack(withRestoredFocus);
    }

    return withRestoredFocus;
  },
};
