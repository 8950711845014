import React from 'react';
import { WidgetListingState } from '../types/WidgetListingState.type.ts';
import { WidgetEmptyState } from './WidgetEmptyState.tsx';
import { WidgetErrorState } from './WidgetErrorState.tsx';
import { WidgetLoadingState } from './WidgetLoadingState.tsx';

type WidgetListingContentProps = React.PropsWithChildren & {
  readonly state: WidgetListingState;
  readonly emptyStateMessage: string;
};

export const WidgetListingStateContent: React.FC<WidgetListingContentProps> = ({
  children,
  emptyStateMessage,
  state,
}) => {
  switch (state) {
    case WidgetListingState.Loading:
      return <WidgetLoadingState />;
    case WidgetListingState.Error:
      return <WidgetErrorState />;
    case WidgetListingState.Empty:
      return <WidgetEmptyState message={emptyStateMessage} />;
    case WidgetListingState.Loaded:
    case WidgetListingState.LoadingMore:
      return children;
    default:
      return null;
  }
};

WidgetListingStateContent.displayName = 'WidgetListingStateContent';
