import { Button } from '@kontent-ai/component-library/Button';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../_shared/utils/stringUtils.ts';

type Props = {
  readonly contentTypesCount: number;
  readonly disabledConfigureTooltipMessage: string | undefined;
  readonly onConfigure: () => void;
};

export const PreviewUrlsHeader: React.FC<Props> = ({
  contentTypesCount,
  disabledConfigureTooltipMessage,
  onConfigure,
}) => {
  return (
    <Row alignY="center" spacing={Spacing.L}>
      <Column>
        <Label size={LabelSize.L} color={colorTextDefault}>
          {pluralizeWithCount('content type', contentTypesCount)}
        </Label>
      </Column>
      <Column width="content">
        <Button
          disabled={!!disabledConfigureTooltipMessage}
          buttonStyle="secondary"
          size="medium"
          onClick={disabledConfigureTooltipMessage ? undefined : onConfigure}
          tooltipText={disabledConfigureTooltipMessage}
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Set up preview for a content type
        </Button>
      </Column>
    </Row>
  );
};

PreviewUrlsHeader.displayName = 'PreviewUrlsHeader';
