import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_ActivateTrial_Failed,
  Subscription_ActivateTrial_Finished,
  Subscription_ActivateTrial_Started,
} from '../../constants/projectActionTypes.ts';

export const activatingTrial = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Subscription_ActivateTrial_Started:
      return true;

    case Subscription_ActivateTrial_Finished:
    case Subscription_ActivateTrial_Failed:
      return false;

    default:
      return state;
  }
};
