import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_BillingInformation_FastSpringConnected,
  Subscription_BillingInformation_Loaded,
  Subscription_BillingInformation_LoadingFailed,
} from '../../../../applications/subscriptionManagement/BillingInformation/constants/actionTypes.ts';
import {
  ISubscriptionBillingInformation,
  emptySubscriptionBillingInformation,
} from '../../../../applications/subscriptionManagement/BillingInformation/models/SubscriptionBillingInformation.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SelectSubscription_Clear,
} from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';

const emptyState = emptySubscriptionBillingInformation;

export const subscriptionBillingInformation = (
  state: ISubscriptionBillingInformation | null = emptyState,
  action: Action,
): ISubscriptionBillingInformation | null => {
  switch (action.type) {
    case Subscription_BillingInformation_Loaded:
      return action.payload.billingInformation;

    case Subscription_BillingInformation_FastSpringConnected:
    case Subscription_SelectSubscription_Clear:
    case Subscription_SelectSubscription:
      return emptyState;

    case Subscription_BillingInformation_LoadingFailed:
      return null;

    default:
      return state;
  }
};
