import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icon } from '@kontent-ai/component-library/Icons';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { CannotCreateNewVariantForItemError } from '../../../applications/itemEditor/constants/errorMessages.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly canCreate: boolean;
  readonly dataUiAction?: DataUiAction;
  readonly icon: Icon;
  readonly isAvailable: boolean;
  readonly isCompact?: boolean;
  readonly isTranslated: boolean;
  readonly renderClickableWrapper: (props: {
    readonly action: ReactNode;
    readonly className: string;
  }) => JSX.Element;
  readonly screenReaderText: string;
  readonly tooltipText: string;
}

export const LinkedItemOpenEditingAction: React.FC<IProps> = ({
  canCreate,
  dataUiAction,
  icon,
  isAvailable,
  isCompact,
  isTranslated,
  renderClickableWrapper,
  screenReaderText,
  tooltipText,
}) => {
  if (!isAvailable) {
    return null;
  }

  const isDisabled = !isTranslated && !canCreate;

  const action = (
    <QuinaryButton
      disabled={isDisabled}
      tooltipPlacement="bottom"
      tooltipText={isDisabled ? CannotCreateNewVariantForItemError : tooltipText}
      {...getDataUiActionAttribute(dataUiAction)}
    >
      <QuinaryButton.Icon icon={icon} screenReaderText={screenReaderText} />
    </QuinaryButton>
  );

  if (isDisabled) {
    return <div className="bar-item__action bar-item__action--is-disabled">{action}</div>;
  }

  return renderClickableWrapper({
    action,
    className: classNames('bar-item__action bar-item__action--is-clickable', {
      'bar-item__action--size-xs': isCompact,
    }),
  });
};

LinkedItemOpenEditingAction.displayName = 'LinkedItemOpenEditingAction';
