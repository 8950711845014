import { createGuid } from '@kontent-ai/utils';
import {
  DefaultCollectionId,
  DefaultVariantId,
  DefaultWorkflowId,
} from '../../../../../_shared/constants/variantIdValues.ts';
import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import {
  PublishedStep,
  WorkflowStepAction,
  emptyWorkflowStep,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { IListingContentItemServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IVariantWorkflowStepServerModel } from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';
import {
  emptyListingContentItem,
  emptyVariant,
} from '../../shared/constants/emptyDemoServerModels.ts';

const createDemoPublishedWorkflowStepServerModel = (
  workflowStep: PublishedStep,
): IVariantWorkflowStepServerModel => ({
  _id: workflowStep.id,
  workflowId: DefaultWorkflowId,
  name: workflowStep.name,
  codeName: workflowStep.codename,
  color: workflowStep.color,
  action: WorkflowStepAction.Publish,
  transitionsTo: [...workflowStep.transitionsTo],
});

export const createDemoUnchangedPublishedItem = (
  itemName: string,
  typeId: Uuid,
  workflowStep: PublishedStep,
  assignee: UserId,
  lastPublishedAt: Date,
): IListingContentItemServerModel => {
  const itemId = createGuid();
  const publishedWorkflowStep = createDemoPublishedWorkflowStepServerModel(workflowStep);

  return {
    ...emptyListingContentItem,
    item: {
      ...emptyListingContentItem.item,
      id: itemId,
      name: itemName,
      type: {
        _id: typeId,
      },
      collectionId: DefaultCollectionId,
    },
    variant: {
      ...emptyVariant,
      id: {
        itemId,
        variantId: DefaultVariantId,
      },
      actualWorkflowStatus: {
        ...emptyWorkflowStep,
        ...publishedWorkflowStep,
      },
      publishingState: PublishingState.Published,
      lastPublishedAt: lastPublishedAt.toISOString(),
      assignment: {
        ...emptyVariant.assignment,
        workflowStatus: {
          ...emptyWorkflowStep,
          ...publishedWorkflowStep,
        },
        assignees: [
          {
            userId: assignee,
          },
        ],
      },
    },
  };
};
