import {
  loadCollections,
  loadProjects,
  loadWorkflows,
  startPollingProjects,
} from '../../../../data/actions/thunkDataActions.ts';
import { createInitEnvironmentSettingsAction } from './thunks/initEnvironmentSettings.ts';

export const initEnvironmentSettings = createInitEnvironmentSettingsAction({
  loadCollections,
  loadWorkflows,
  loadProjects,
  startPollingProjects,
});
