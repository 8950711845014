import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ElementErrorResult } from '../../../../itemEditor/utils/elementErrorCheckers/types/Errors.ts';
import { mapElementErrorToMessage } from '../../../../itemEditor/utils/mapElementErrorToMessage.ts';
import { BaseTypeElementValidationResult } from './types/BaseTypeElementValidationResult.type.ts';

export const mapElementErrorToTypeElementErrorResult = (
  validationResults: ElementErrorResult,
  elementType: ElementType,
): BaseTypeElementValidationResult => ({
  errorMessages: validationResults.errors.map((elementError) =>
    mapElementErrorToMessage(elementError, elementType),
  ),
});
