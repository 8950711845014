import React from 'react';
import { UserListingDataTable } from '../../components/userListing/UserListingDataTable.tsx';
import { useUserListingDisplayedUsers } from '../../hooks/useUserListingDisplayedUsers.ts';
import { useUserListingTableHeadData } from '../../hooks/useUserListingTableHeadData.ts';
import { UserListingState } from '../../utils/getUserListingState.ts';

type Props = {
  readonly createUserDetailLink: (userId: Uuid) => string;
  readonly state: UserListingState;
};

export const UserListingDataTableContainer: React.FC<Props> = ({ createUserDetailLink, state }) => {
  const displayedUsers = useUserListingDisplayedUsers();

  const headData = useUserListingTableHeadData();

  return (
    <UserListingDataTable
      createUserDetailLink={createUserDetailLink}
      displayedUsers={displayedUsers}
      headData={headData}
      state={state}
    />
  );
};

export { UserListingDataTableContainer as UserListingDataTable };
