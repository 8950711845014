import { memoize } from '@kontent-ai/memoization';
import { Collection, notNull } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  ItemEditorOperationId,
  parseElementOperationId,
} from '../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';

const Minute = 60000;

interface IContentItemChangeIntercomTrackerStateProps {
  readonly isSaving: boolean;
  readonly savingElementIds: ReadonlySet<Uuid>;
}

interface IContentItemChangeIntercomTrackerDispatchProps {
  readonly trackContentItemChange: (
    autosavesCount: number,
    savedElementIds: ReadonlySet<Uuid>,
  ) => void;
}

interface IContentItemChangeIntercomTrackerState {
  readonly autosavesCount: number;
  readonly savedElementIds: ReadonlySet<Uuid>;
}

interface IContentItemChangeIntercomTrackerProps
  extends IContentItemChangeIntercomTrackerStateProps,
    IContentItemChangeIntercomTrackerDispatchProps {}

class ContentItemChangeIntercomTrackerComponent extends React.PureComponent<
  IContentItemChangeIntercomTrackerProps,
  IContentItemChangeIntercomTrackerState
> {
  static displayName = 'ContentItemChangeIntercomTrackerComponent';

  readonly state: IContentItemChangeIntercomTrackerState = {
    autosavesCount: 0,
    savedElementIds: new Set(),
  };

  private _intervalId?: number;

  componentDidMount(): void {
    this._intervalId = window.setInterval(() => {
      this._trackContentItemChange(this.state.autosavesCount, this.state.savedElementIds);

      this.setState(() => ({
        autosavesCount: 0,
        savedElementIds: new Set(),
      }));
    }, Minute);
  }

  componentDidUpdate(prevProps: IContentItemChangeIntercomTrackerProps) {
    if (prevProps.isSaving && !this.props.isSaving) {
      this.setState((state) => ({
        autosavesCount: state.autosavesCount + 1,
      }));
    }

    if (
      !Collection.isEmpty(
        Collection.removeMany(this.props.savingElementIds, [...this.state.savedElementIds]),
      )
    ) {
      const savingElementIds = this.props.savingElementIds;

      this.setState((state) => ({
        savedElementIds: Collection.addMany(state.savedElementIds, [...savingElementIds]),
      }));
    }
  }

  componentWillUnmount(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }

    this._trackContentItemChange(this.state.autosavesCount, this.state.savedElementIds);
  }

  private readonly _trackContentItemChange = (
    autosavesCount: number,
    savedElementIds: ReadonlySet<Uuid>,
  ): void => {
    if (autosavesCount > 0) {
      this.props.trackContentItemChange(autosavesCount, savedElementIds);
    }
  };

  render = () => null;
}

const parseElementIdsFromOperationId = memoize.maxOne(
  (operationIds: ReadonlySet<ItemEditorOperationId>): ReadonlySet<Uuid> =>
    new Set(
      [...operationIds]
        .map((operationId: ItemEditorOperationId) => parseElementOperationId(operationId).elementId)
        .filter(notNull),
    ),
);

const mapStateToProps = ({
  contentApp: { editedContentItemStatus },
}: IStore): IContentItemChangeIntercomTrackerStateProps => ({
  isSaving: !Collection.isEmpty(editedContentItemStatus.saving),
  savingElementIds: parseElementIdsFromOperationId(editedContentItemStatus.saving),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): IContentItemChangeIntercomTrackerDispatchProps => ({
  trackContentItemChange: (autosavesCount: number, savedElementIds: ReadonlySet<Uuid>) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentEntryNameOrElementsChanged, {
        'autosaves-count': autosavesCount,
        savedElementIds: JSON.stringify([...savedElementIds]),
      }),
    ),
});

export const ContentItemChangeIntercomTracker = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentItemChangeIntercomTrackerComponent);
