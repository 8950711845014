import { Action } from '../../../../../@types/Action.type.ts';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  IInviteAdminServerModel,
  ISubscriptionAdminServerModel,
} from '../../../../../repositories/serverModels/SubscriptionAdminServerModel.type.ts';
import {
  ISubscriptionAdmin,
  createSubscriptionAdminDomainModel,
} from '../../../shared/models/SubscriptionAdmin.ts';
import { ILoadSubscriptionUsage } from '../../../shared/types/ILoadSubscriptionUsage.type.ts';
import { IInviteAdmin } from '../../../shared/types/InviteAdmin.type.ts';
import {
  Subscription_SubscriptionAdminInvitation_Finished,
  Subscription_SubscriptionAdminInvitation_Started,
} from '../../constants/actionTypes.ts';

interface ICreateAdminDependencies {
  readonly subscriptionRepository: {
    readonly inviteNewSubscriptionAdmin: (
      inviteAdmin: IInviteAdminServerModel,
    ) => Promise<ISubscriptionAdminServerModel>;
  };
  readonly failInvitingNewAdmin: (errorMessage: string) => Action;
  readonly loadSubscriptionUsage: ILoadSubscriptionUsage;
}

const finishInvitingNewAdmin = (newAdmin: ISubscriptionAdmin, subscriptionId: Uuid) =>
  ({
    type: Subscription_SubscriptionAdminInvitation_Finished,
    payload: {
      newAdmin,
      subscriptionId,
    },
  }) as const;

const startInvitingNewAdmin = () =>
  ({
    type: Subscription_SubscriptionAdminInvitation_Started,
  }) as const;

export type CreateAdminActionsType = ReturnType<
  typeof finishInvitingNewAdmin | typeof startInvitingNewAdmin
>;

export const inviteNewAdminCreator =
  (dependencies: ICreateAdminDependencies) =>
  (subscriptionId: Uuid, subscriptionAdmin: IInviteAdmin): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(startInvitingNewAdmin());
      const inviteAdminObject: IInviteAdminServerModel = {
        subscriptionId,
        firstName: subscriptionAdmin.firstName,
        lastName: subscriptionAdmin.lastName,
        email: subscriptionAdmin.email,
      };
      const newAdminServerModel =
        await dependencies.subscriptionRepository.inviteNewSubscriptionAdmin(inviteAdminObject);
      dispatch(
        finishInvitingNewAdmin(
          createSubscriptionAdminDomainModel(newAdminServerModel),
          subscriptionId,
        ),
      );
      dispatch(dependencies.loadSubscriptionUsage(subscriptionId));
    } catch (error) {
      dispatch(
        dependencies.failInvitingNewAdmin(
          'The admin creation failed as we’re unable to reach the servers. Check ' +
            'your internet connection or try inviting admin again in a couple of minutes.',
        ),
      );
      throw error;
    }
  };
