import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { CalendarEventTypes } from '../../../_shared/models/TrackUserEventData.ts';
import {
  getSelectedLanguageId,
  isSelectedLanguageIdEnsured,
} from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { getListingLanguageSwitcherOptionsCreator } from '../../../_shared/utils/languageSwitchers/getListingLanguageSwitcherOptions.ts';
import { getPathWithReplacedVariantIdForHomeRoute } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectId } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ContentListingLanguageSwitcher } from '../../contentInventory/content/features/ContentItemInventory/components/ContentListingLanguageSwitcher/ContentListingLanguageSwitcher.tsx';

const getHomeLanguageSwitcherOptions = getListingLanguageSwitcherOptionsCreator(
  getPathWithReplacedVariantIdForHomeRoute,
);

export const HomeLanguageSwitcher: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const isSelectedLanguageEnsured = useSelector(isSelectedLanguageIdEnsured);
  const languageOptions = useSelector((store) =>
    getHomeLanguageSwitcherOptions(
      getCurrentProjectId(store),
      store.data.user,
      store.data.languages,
      pathname,
    ),
  );

  const trackEvent = useCallback(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.Calendar, {
        action: CalendarEventTypes.VariantSelected,
      }),
    );
  }, []);

  return (
    <ContentListingLanguageSwitcher
      languages={languageOptions}
      selectedLanguageId={selectedLanguageId}
      isSelectedLanguageEnsured={isSelectedLanguageEnsured}
      onPress={trackEvent}
    />
  );
};
