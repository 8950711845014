import { TypeElementCondition } from '../../../../_shared/models/utils/TypeElementCondition.ts';
import { IContentTypeBase } from '../../../../data/models/contentModelsApp/sharedContentTypeModels.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { ConditionElementId } from '../types/ConditionElementId.type.ts';
import { BaseTypeElementValidationResult } from './typeElementValidators/types/BaseTypeElementValidationResult.type.ts';
import {
  TypeElementConditionValidationResult,
  TypeElementWithConditionValidationResult,
} from './typeElementValidators/types/TypeElementWithConditionValidationResult.ts';

export const convertToTypeElementConditionValidationResultOrNull = (
  validationResult: BaseTypeElementValidationResult,
): TypeElementConditionValidationResult | null => {
  if (isTypeElementValidationResultWithCondition(validationResult)) {
    return validationResult.conditionValidationResult;
  }

  return null;
};

const isTypeElementValidationResultWithCondition = (
  validationResult: BaseTypeElementValidationResult,
): validationResult is BaseTypeElementValidationResult & TypeElementWithConditionValidationResult =>
  Object.hasOwn(validationResult, 'conditionValidationResult');

export const createActiveConditionIds = (
  editedType: IContentTypeBase,
): ReadonlyArray<ConditionElementId> =>
  editedType.typeElements.filter(isElementWithActiveCondition).map(
    (el): ConditionElementId => ({
      conditionId: el.condition.id,
      elementId: el.elementId,
    }),
  );

const isElementWithActiveCondition = (
  element: IBaseTypeElementData,
): element is BaseTypeElementDataWithCondition => !!element.condition?.isActive;

interface BaseTypeElementDataWithCondition extends IBaseTypeElementData {
  readonly condition: TypeElementCondition;
}
