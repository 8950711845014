import { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../../../_shared/hooks/useThunkPromise.ts';
import { CreateContentGroupTabsId } from '../../../../../../itemEditor/features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { ensureLoadedContentTypes } from '../../../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { IContentComponent } from '../../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { ExpandedContentComponentElements as ExpandedContentComponentElementsComponent } from '../../../components/expanded/elements/ExpandedContentComponentElements.tsx';

type Props = {
  readonly contentComponent: IContentComponent;
  readonly isHighlighted: boolean;
  readonly originalContentComponent?: IContentComponent;
};

export const ExpandedContentComponentElements = (props: Props) => {
  const contentType = useSelector(
    (state) =>
      state.contentApp.loadedContentItemTypes.get(props.contentComponent.contentTypeId) ?? null,
  );
  const missingContentTypeIds = useMemo(
    () => new Set(contentType ? [] : [props.contentComponent.contentTypeId]),
    [contentType, props.contentComponent.contentTypeId],
  );
  useThunkPromise(ensureLoadedContentTypes, missingContentTypeIds);

  const selectedContentGroupId = useSelector(
    (state) =>
      contentType &&
      getSelectedContentGroupIdFromStateOrFirst(
        CreateContentGroupTabsId.forContentComponent(props.contentComponent.id),
        contentType.contentGroups,
        state,
      ),
  );

  return (
    <ExpandedContentComponentElementsComponent
      {...props}
      contentType={contentType}
      selectedContentGroupId={selectedContentGroupId}
    />
  );
};
