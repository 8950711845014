import Immutable from 'immutable';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../constants/modalContentItemSelectorActionTypes.ts';

export const modalContentItemSelectorOpened = () =>
  ({
    type: ModalContentItemSelector_Opened,
  }) as const;

export const modalContentItemSelectorClosed = () =>
  ({
    type: ModalContentItemSelector_Closed,
  }) as const;

export const modalMultipleContentItemsSelectorOpened = (
  alreadyLinkedItemIds: Immutable.Set<Uuid>,
) =>
  ({
    type: ModalMultipleContentItemsSelector_Opened,
    payload: { alreadyLinkedItemIds },
  }) as const;

export const modalMultipleContentItemsSelectorClosed = () =>
  ({
    type: ModalMultipleContentItemsSelector_Closed,
  }) as const;
