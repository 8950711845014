import { createGuid } from '@kontent-ai/utils';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  TrackUserEventWithData,
  TrackUserEventWithDataAction,
} from '../../../../_shared/models/TrackUserEvent.type.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { ProgressCallback } from '../../../../_shared/utils/ajax.ts';
import { trackAssetUpdate } from '../../../../_shared/utils/assets/assetUtils.ts';
import { throttle } from '../../../../_shared/utils/func/throttle.ts';
import {
  loadAssetFolders,
  loadAssetType,
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadListingAssets,
  loadSpaces,
  loadTaxonomyGroups,
  loadUsers,
} from '../../../../data/actions/thunkDataActions.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { listingOrderByStorage } from '../../../../localStorages/listingOrderByStorage.ts';
import { assetService } from '../../content/features/Asset/services/assetService.ts';
import {
  createCloseAssetSelectorOnCancel,
  createCloseAssetSelectorOnInsert,
} from '../features/ModalAssetSelector/actions/thunks/closeAssetSelector.ts';
import { reportAssetUploadProgress, saveEditedAssetFinish } from './assetLibraryActions.ts';
import { createArchiveAssetAction } from './thunks/archiveAsset.ts';
import { createArchiveAssetFolderAction } from './thunks/archiveAssetFolder.ts';
import { createArchiveAssetsAction } from './thunks/archiveAssets.ts';
import { createAssignTaxonomyTermsToAssetsAction } from './thunks/assignTaxonomyTermsToAssets.ts';
import { createCreateAssetFolderAction } from './thunks/createAssetFolder.ts';
import { createCreateAssetsAction } from './thunks/createAssets.ts';
import { createDeleteEditedAssetAction } from './thunks/deleteEditedAsset.ts';
import { createEnsureAssetLibraryRelatedDataAction } from './thunks/ensureAssetLibraryRelatedData.ts';
import { createEnsureLoadedAssetFolderAction } from './thunks/ensureLoadedAssetFolder.ts';
import { createInitializeAssetEditDialogAction } from './thunks/initializeAssetEditDialog.ts';
import { createInitializeAssetEditingAction } from './thunks/initializeAssetEditing.ts';
import { createInitializeAssetLibraryAction } from './thunks/initializeAssetLibrary.ts';
import { createLoadAndFixAssetQueryAction } from './thunks/loadAndFixAssetQuery.ts';
import { createLoadAssetUsageAction } from './thunks/loadAssetUsage.ts';
import { createMoveAssetsBackToCollectionAction } from './thunks/moveAssetsBackToCollection.ts';
import { createMoveAssetsBackToFolderAction } from './thunks/moveAssetsBackToFolder.ts';
import { createMoveOpenedAssetFolderAction } from './thunks/moveOpenedFolder.ts';
import { createMoveOpenedAssetFolderBackAction } from './thunks/moveOpenedFolderBack.ts';
import { createMoveSelectedAssetsToCollectionAction } from './thunks/moveSelectedAssetsToCollection.ts';
import { createMoveSelectedAssetsToFolderAction } from './thunks/moveSelectedAssetsToFolder.ts';
import { createOpenAssetFolderAction } from './thunks/openAssetFolder.ts';
import { createOpenSingleAssetUsageAction } from './thunks/openSingleAssetUsage.ts';
import { createReplaceAssetAction } from './thunks/replaceAsset.ts';
import { createRestoreAssetFolderAction } from './thunks/restoreAssetFolder.ts';
import { createRestoreAssetsAction } from './thunks/restoreAssets.ts';
import { createUpdateAssetAction } from './thunks/updateAsset.ts';
import { createUpdateAssetFolderAction } from './thunks/updateAssetFolder.ts';
import { createUpdateAssetListingOrderingAction } from './thunks/updateAssetListingOrdering.ts';
import { createUploadAssetsAction } from './thunks/uploadAssets.ts';
import { createUploadAssetsFromEditingAction } from './thunks/uploadAssetsFromEditing.ts';
import { createValidateAssetsInEditedItemAction } from './thunks/validateAssetsInEditedItem.ts';

const { assetFolderRepository, assetRepository } = repositoryCollection;

export const loadAssetUsage = createLoadAssetUsageAction({
  assetRepository,
});

export const openAssetFolder = createOpenAssetFolderAction({
  loadAssetFolders,
  loadListingAssets,
});

export const ensureAssetLibraryRelatedData = createEnsureAssetLibraryRelatedDataAction({
  loadAssetType,
  loadCollections,
  loadSpaces,
  loadTaxonomyGroups,
});

export const loadAndFixAssetQuery = createLoadAndFixAssetQueryAction({
  ensureAssetLibraryRelatedData,
});

export const initializeAssetLibrary = createInitializeAssetLibraryAction({
  ensureAssetLibraryRelatedData,
  loadAndFixAssetQuery,
  listingOrderByStorage,
});

export const ensureLoadedAssetFolder = createEnsureLoadedAssetFolderAction({
  openAssetFolder,
});

export const initializeAssetEditDialog = createInitializeAssetEditDialogAction({
  assetService,
  loadAssetType,
  loadAssetUsage,
  loadUsers,
});

export const initializeAssetEditing = createInitializeAssetEditingAction({
  assetService,
  loadAssetUsage,
  loadAssetType,
  loadContentTypes,
  loadLanguages,
  loadTaxonomyGroups,
  loadUsers,
});

const getFileUploadProgressHandler = (assetId: Uuid, dispatch: Dispatch): ProgressCallback =>
  throttle(
    (event) => dispatch(reportAssetUploadProgress(assetId, event.total, event.loaded)),
    300,
    {
      // Don't call throttled handler when upload is done.
      trailing: false,
    },
  );

export const createAssets = createCreateAssetsAction({
  assetService,
  getFileUploadProgressHandler,
  trackUserEvent: trackUserEventWithData,
});

export const replaceAsset = createReplaceAssetAction({
  assetService,
  getFileUploadProgressHandler,
  trackUserEvent: trackUserEventWithData,
});

export const uploadAssetsFromEditing = createUploadAssetsFromEditingAction({
  createAssets,
});

export const updateAsset = createUpdateAssetAction({
  assetService,
  saveEditedAssetFinish,
});

export const archiveAsset = createArchiveAssetAction({
  assetService,
  trackUserEvent: trackUserEventWithData,
});

export const archiveAssets = createArchiveAssetsAction({
  assetService,
  trackUserEvent: trackUserEventWithData,
  loadListingAssets,
});

export const restoreAssets = createRestoreAssetsAction({
  assetService,
  loadListingAssets,
  trackUserEvent: trackUserEventWithData,
});

type InvokeTrackAssetAction = (
  trackUserEvent: TrackUserEventWithData,
  updatedAsset: IAsset,
) => void;

const createTrackUserActionOnAssetUpdate =
  (
    invokeTrackAssetUpdated: InvokeTrackAssetAction,
    trackUserEventWithDataAction: TrackUserEventWithDataAction,
  ) =>
  (editedAsset: IAsset) =>
  (dispatch: Dispatch) =>
    invokeTrackAssetUpdated(
      (...args) => dispatch(trackUserEventWithDataAction(...args)),
      editedAsset,
    );

export const trackUserActionOnAssetUpdate = createTrackUserActionOnAssetUpdate(
  trackAssetUpdate,
  trackUserEventWithData,
);

export const validateAssetsInEditedItem = createValidateAssetsInEditedItemAction();

export const deleteEditedAsset = createDeleteEditedAssetAction({
  archiveAsset,
});

export const createAssetFolder = createCreateAssetFolderAction({
  assetFolderRepository,
  generateId: createGuid,
});

export const updateAssetFolder = createUpdateAssetFolderAction({
  assetFolderRepository,
});

export const uploadAssets = createUploadAssetsAction({
  createAssets,
});

export const closeAssetSelectorOnCancel = createCloseAssetSelectorOnCancel();
export const closeAssetSelectorOnInsert = createCloseAssetSelectorOnInsert();

export const moveSelectedAssetsToFolder = createMoveSelectedAssetsToFolderAction({
  assetService,
  loadListingAssets,
  trackUserEvent: trackUserEventWithData,
});

export const moveSelectedAssetsToCollection = createMoveSelectedAssetsToCollectionAction({
  assetService,
  loadListingAssets,
  trackUserEvent: trackUserEventWithData,
});

export const moveOpenedAssetFolder = createMoveOpenedAssetFolderAction({
  assetFolderRepository,
});

export const moveOpenedAssetFolderBack = createMoveOpenedAssetFolderBackAction({
  assetFolderRepository,
});

export const moveAssetsBackToFolder = createMoveAssetsBackToFolderAction({
  assetService,
  loadListingAssets,
  trackUserEvent: trackUserEventWithData,
});

export const moveAssetsBackToCollection = createMoveAssetsBackToCollectionAction({
  assetService,
  loadListingAssets,
  trackUserEvent: trackUserEventWithData,
});

export const archiveAssetFolder = createArchiveAssetFolderAction({
  assetFolderRepository,
  trackUserEvent: trackUserEventWithData,
});

export const restoreAssetFolder = createRestoreAssetFolderAction({
  assetFolderRepository,
  assetService,
  loadListingAssets,
  trackUserEvent: trackUserEventWithData,
});

export const openSingleAssetUsage = createOpenSingleAssetUsageAction();

export const assignTaxonomyTermsToAssets = createAssignTaxonomyTermsToAssetsAction({
  assetService,
  loadAssetType,
});

export const updateAssetListingOrdering = createUpdateAssetListingOrderingAction({
  loadListingAssets,
});
