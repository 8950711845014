import {
  CONTENT_COMPONENT_MAX_NESTING_LEVEL,
  LINKED_ITEM_MAX_EXPANDABLE_LEVEL,
} from '../constants/contentNestingConstants.ts';

export const isCreationOfContentComponentAllowed = (nestedLevel: number): boolean =>
  nestedLevel < CONTENT_COMPONENT_MAX_NESTING_LEVEL;

export const isItemExpandable = (nestedLevel: number): boolean =>
  nestedLevel < LINKED_ITEM_MAX_EXPANDABLE_LEVEL;
