import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { ITextTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TextTypeElement.ts';
import { setRichTextClipboard } from '../../../../../richText/actions/thunkRichTextActions.ts';
import { SimpleMultilineTextDiff } from '../../../../../richText/editors/simpleText/SimpleMultilineTextDiff.tsx';
import { EmptyMetadata } from '../../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { EmptyContentComponents } from '../../../../models/contentItem/ContentComponent.ts';
import { IBaseTextItemElement } from '../../../../models/contentItemElements/IBaseTextItemElement.type.ts';
import { ITextItemElement } from '../../../../models/contentItemElements/TextItemElement.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../ContentItemEditing/containers/elements/ItemElementRefresher.tsx';
import { ReadonlyEmptyElementPlaceholder } from '../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../hooks/useRenderPlaceholderText.ts';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';

type StringProps = IRevisionItemElementProps<ITextItemElement, ITextTypeElement>;

export const StringElement: React.FC<StringProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  revisionElementModifiedBy,
  typeElement,
}) => {
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.StringElement,
    originalElementData,
    typeElement,
  );
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );

  return (
    <ItemElement
      disabled
      hideValidationStatus={hideValidationStatus}
      isDisplayOnly
      typeElement={typeElement}
      revisionChangedBy={revisionElementModifiedBy}
    >
      <ItemElementRefresher
        elementData={elementData}
        renderInput={({ _editorState }: IBaseTextItemElement) => (
          <SimpleMultilineTextDiff
            className="rte--in-content-item-element"
            editorState={_editorState}
            originalContent={originalElementData?._editorState.getCurrentContent()}
            placeholder={placeholderText ?? undefined}
            setRichTextClipboard={onSetRichTextClipboard}
            spellCheck
          />
        )}
      />
    </ItemElement>
  );
};

StringElement.displayName = 'StringElement (Revisions)';
