import styled, { css } from 'styled-components';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import {
  CheckboxStylingProps,
  checkboxPadding,
  checkboxSymbolSize,
  getCheckboxTokens,
} from '../utils/stylingUtils.ts';

const checkboxLabelTypography = Typography.LabelLarge;

type CheckboxLabelLineHeightPx<TNum extends number = 18> =
  `${TNum}px` extends typeof Typography.LabelLarge.lineHeight ? TNum : never;
const checkboxLabelLineHeight: CheckboxLabelLineHeightPx = 18;
const checkboxMinHeight = 2 * checkboxPadding + checkboxSymbolSize;
// Offset label so that its first line is vertically centered with the checkbox symbol
export const labelOffset = px((checkboxMinHeight - checkboxLabelLineHeight) / 2);

export const StyledCheckboxLabel = styled.span.attrs(getCheckboxTokens)<CheckboxStylingProps>`
  ${({ labelTextColor }) => css`
    // ensure proper height determined by line-height
    display: block;

    ${checkboxLabelTypography};
    color: ${labelTextColor};
    user-select: none;
    overflow-wrap: anywhere;
    transition: color ${transition250};
  `};
`;
