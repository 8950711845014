import { EntityInstance } from 'draft-js';
import { isString } from '../../../../../../_shared/utils/stringUtils.ts';
import { IGetAttributesFromEntityData } from '../../../../plugins/entityApi/api/editorEntityUtils.ts';
import { AiInstructionEntity } from '../../../../plugins/inlineAi/utils/InstructionEntity.ts';
import { FinishedMentionEntity } from '../../../../plugins/mentions/api/MentionEntity.ts';
import { GetUser, getInstructionText, getMentionText } from '../../plainText/entities.ts';
import { HtmlData } from './tags.ts';

export const getLinkData = (
  entity: EntityInstance,
  content: HtmlData,
  getAttributesFromEntityData: IGetAttributesFromEntityData,
): HtmlData | null => {
  const attributes = getAttributesFromEntityData(entity);
  if (!attributes) {
    return null;
  }

  return [
    {
      tagName: 'a',
      attributes,
      children: content,
    },
  ];
};

export const getMentionData = (
  entity: FinishedMentionEntity,
  getUser: GetUser,
): HtmlData | null => {
  const text = getMentionText(entity, getUser);
  if (!text) {
    return null;
  }

  return [
    {
      tagName: 'span',
      attributes: {
        class: 'user-mention',
        'data-userid': entity.getData().userId,
      },
      children: [text],
    },
  ];
};

export const getInstructionData = (
  entity: AiInstructionEntity,
  content: HtmlData,
): HtmlData | null => {
  const text = getInstructionText(entity, content.filter(isString).join(''));
  if (!text) {
    return null;
  }

  return [
    {
      tagName: 'span',
      attributes: {
        'data-aisessionid': entity.getData().aiSessionId,
      },
      children: [text],
    },
  ];
};
