import {
  CollectionFilterOption,
  EnvironmentFilterOption,
  LanguageFilterOption,
  ProjectFilterOption,
  RoleFilterOption,
  StatusFilterOption,
} from '../models/UserListingFilterOption.type.ts';

export enum ByStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  ActiveAndDeactivated = 'activeAndDeactivated',
}

export const anyRoleOption: RoleFilterOption = {
  id: 'any-role',
  label: 'Any role',
};

export const anyCollectionOption: CollectionFilterOption = {
  id: 'any-collection',
  label: 'Any collection',
};

export const allCollectionsOption: CollectionFilterOption = {
  id: 'all-collections',
  label: 'All collections',
};

export const anyLanguageOption: LanguageFilterOption = {
  id: 'any-language',
  label: 'Any language',
};

export const allLanguagesOption: LanguageFilterOption = {
  id: 'all-languages',
  label: 'All languages',
};

export const anyProjectOption: ProjectFilterOption = {
  id: 'any-project',
  label: 'Any project',
};

export const anyEnvironmentOption: EnvironmentFilterOption = {
  id: 'any-environment',
  label: 'Any environment',
};

export const activeUsersOption: StatusFilterOption = {
  id: ByStatus.Active,
  label: 'Active users',
};

export const deactivatedUsersOption: StatusFilterOption = {
  id: ByStatus.Deactivated,
  label: 'Deactivated users',
};

export const activeAndDeactivatedUsersOption: StatusFilterOption = {
  id: ByStatus.ActiveAndDeactivated,
  label: 'Active and deactivated users',
};

export const userStatusOptions: ReadonlyArray<StatusFilterOption> = [
  activeUsersOption,
  deactivatedUsersOption,
  activeAndDeactivatedUsersOption,
] as const;
