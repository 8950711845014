import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { commentThreadItemEditingCanceled } from '../../actions/contentItemEditingActions.ts';
import {
  blurCommentThread,
  focusCommentThread,
  saveNewInlineCommentThreadToServer,
} from '../../actions/thunkContentItemEditingActions.ts';
import {
  IUnsavedInlineCommentThreadDispatchProps,
  IUnsavedInlineCommentThreadOwnProps,
  IUnsavedInlineCommentThreadStateProps,
  UnsavedInlineCommentThread,
} from '../../components/comments/UnsavedInlineCommentThread.tsx';
import { getNewCommentThreadItemType } from '../../utils/inlineCommentUtils.ts';

function mapStateToProps(
  state: IStore,
  ownProps: IUnsavedInlineCommentThreadOwnProps,
): IUnsavedInlineCommentThreadStateProps {
  const { commentThread } = ownProps;

  const {
    contentApp: {
      editedContentItemVariantComments: { focusedCommentThreadId },
      editorUi: { newCommentThreadItemTypeMappings },
    },
    data: { user },
  } = state;

  const type = getNewCommentThreadItemType(newCommentThreadItemTypeMappings, commentThread.id);

  return {
    currentUser: user.info,
    type,
    isFocused: focusedCommentThreadId === commentThread.id,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: IUnsavedInlineCommentThreadOwnProps,
): IUnsavedInlineCommentThreadDispatchProps {
  const threadId: Uuid = ownProps.commentThread.id;

  return {
    onBlur: () => dispatch(blurCommentThread()),
    onCancel: () => dispatch(commentThreadItemEditingCanceled(threadId)),
    onSubmit: (type: CommentThreadItemType, content: ICommentThreadItemContentModel) =>
      dispatch(saveNewInlineCommentThreadToServer(threadId, type, content)),
    onFocus: () => dispatch(focusCommentThread(threadId)),
  };
}

const ConnectedUnsavedInlineCommentThread: React.ComponentType<
  IUnsavedInlineCommentThreadOwnProps & React.ComponentPropsWithRef<React.ComponentType>
> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  UnsavedInlineCommentThread,
);

export { ConnectedUnsavedInlineCommentThread as UnsavedInlineCommentThread };
