import { Color } from '../../../../tokens/quarks/colors.ts';

export const getSvgMainCircleStrokeColor = (
  isDisabled: boolean,
  isSelected: boolean,
  isLabelHovered: boolean,
  selectedStrokeColor: Color,
  strokeColor: Color,
  selectedStrokeColorHover: Color,
) => {
  if (isDisabled) {
    return strokeColor;
  }
  if (isSelected) {
    if (isLabelHovered) {
      return selectedStrokeColorHover;
    }
    return selectedStrokeColor;
  }
  return strokeColor;
};
