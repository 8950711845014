import { EditorState } from 'draft-js';
import { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { EmptyContentComponents } from '../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { setRichTextClipboard } from '../../../../actions/thunkRichTextActions.ts';
import { useContentComponents } from '../../../../hooks/useContentComponents.ts';
import { EmptyMetadata } from '../../../clipboard/thunks/setRichTextClipboard.ts';
import {
  StringAiSuggestionViewer as StringAiSuggestionViewerComponent,
  StringAiSuggestionViewerProps,
} from '../../components/viewers/StringAiSuggestionViewer.tsx';

export const StringAiSuggestionViewer = (
  props: Omit<StringAiSuggestionViewerProps, 'setRichTextClipboard'>,
) => {
  const contentComponents = useContentComponents();
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(
        setRichTextClipboard(
          e,
          editorState,
          contentComponents ?? EmptyContentComponents,
          EmptyMetadata,
        ),
      ),
    [contentComponents],
  );

  return (
    <StringAiSuggestionViewerComponent {...props} setRichTextClipboard={onSetRichTextClipboard} />
  );
};
