import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { areCollectionGroupsTheSame } from '../utils/areCollectionGroupsTheSame.ts';
import { getInitialUserDetailCollectionGroups } from './getInitialUserDetailCollectionGroups.ts';

export const hasUserDetailUnsavedChanges = (state: IStore, user: IProjectContributor): boolean => {
  const {
    usersApp: { userDetail },
  } = state;

  const initialCollectionGroups = getInitialUserDetailCollectionGroups(user.collectionGroups);

  return !areCollectionGroupsTheSame(userDetail.collectionGroups, initialCollectionGroups);
};
