import Immutable from 'immutable';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

export type ContentTypeListingOperationStatus = {
  readonly message: string;
  readonly messageStyle: StatusMessageStyle;
  readonly operationType: ContentTypeListingOperationType;
  readonly affectedContentTypeIds: Immutable.Set<Uuid>;
  readonly restorableContentTypes: Immutable.Set<IContentType>;
  readonly restorablePreviousPage?: number;
};

export enum ContentTypeListingOperationType {
  NoOperation = 'nooperation',
  Archiving = 'archiving',
  Archived = 'archived',
  Restoring = 'restoring',
  Restored = 'restored',
  Selected = 'selected',
}

const operationTypeToMessageStyleMap = {
  [ContentTypeListingOperationType.NoOperation]: StatusMessageStyle.Normal,
  [ContentTypeListingOperationType.Archiving]: StatusMessageStyle.Deleted,
  [ContentTypeListingOperationType.Archived]: StatusMessageStyle.Deleted,
  [ContentTypeListingOperationType.Restoring]: StatusMessageStyle.Updated,
  [ContentTypeListingOperationType.Restored]: StatusMessageStyle.Updated,
  [ContentTypeListingOperationType.Selected]: StatusMessageStyle.Selected,
};

const operationTypeToMessageTemplateMap = {
  [ContentTypeListingOperationType.NoOperation]: () => 'Content types',
  [ContentTypeListingOperationType.Archiving]: (typeIds: Immutable.Set<Uuid>) =>
    `Deleting ${pluralizeWithCount('content type', typeIds.size)}`,
  [ContentTypeListingOperationType.Archived]: (typeIds: Immutable.Set<Uuid>) =>
    `${pluralizeWithCount('Content type', typeIds.size)} deleted`,
  [ContentTypeListingOperationType.Restoring]: (typeIds: Immutable.Set<Uuid>) =>
    `Restoring ${pluralizeWithCount('content type', typeIds.size)}`,
  [ContentTypeListingOperationType.Restored]: (typeIds: Immutable.Set<Uuid>) =>
    `${pluralizeWithCount('Content type', typeIds.size)} restored`,
  [ContentTypeListingOperationType.Selected]: (typeIds: Immutable.Set<Uuid>) =>
    `${pluralizeWithCount('Content type', typeIds.size)} selected`,
};

export const getContentTypeListingFailedOperationMessage = (failedIds: Immutable.Set<Uuid>) =>
  failedIds.size > 0 ? `, ${failedIds.size} failed` : '';

export function getContentTypeListingOperationMessage(
  operationType: ContentTypeListingOperationType,
  affectedContentTypeIds: Immutable.Set<Uuid>,
): string {
  return operationTypeToMessageTemplateMap[operationType](affectedContentTypeIds);
}

export function getContentTypeListingOperationMessageStyle(
  operationType: ContentTypeListingOperationType,
): StatusMessageStyle {
  return operationTypeToMessageStyleMap[operationType];
}
