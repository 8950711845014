import Immutable from 'immutable';
import React from 'react';
import { filterOutNullish } from '../../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { TypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { getElementById } from '../../../../../stores/utils/contentItemElementsUtils.ts';
import { getItemElementEqualityChecker } from '../../../../../utils/getItemElementsEqualityChecker.ts';

const hasElementChanged = (
  element: ICompiledContentItemElementData,
  previousElement: ICompiledContentItemElementData,
): boolean => {
  const comparer = getItemElementEqualityChecker(element.type);
  if (comparer) {
    return !comparer(element, previousElement);
  }
  return false;
};

export interface IElementsChangeObserverOwnProps {
  readonly observeAllElements: boolean;
  readonly observedElementIds: Immutable.Set<Uuid>;
  readonly onElementsChanged: (elementCodenames: Immutable.Set<string>) => void;
}

export interface IElementsChangeObserverStateProps {
  readonly typeElements?: ReadonlyArray<TypeElement>;
  readonly elements?: ReadonlyArray<ICompiledContentItemElementData>;
}

interface IElementsChangeObserverProps
  extends IElementsChangeObserverOwnProps,
    IElementsChangeObserverStateProps {}

export class ElementsChangeObserver extends React.PureComponent<IElementsChangeObserverProps> {
  static displayName = 'ElementsChangeObserver';

  componentDidUpdate(prevProps: IElementsChangeObserverProps) {
    const { elements, typeElements } = this.props;

    const previousElements = prevProps.elements;

    if (elements && previousElements && typeElements && elements !== previousElements) {
      const filteredElements = this._getObservedElements(elements);
      const changedElements = this._getChangedElements(filteredElements, previousElements);

      if (changedElements.length > 0) {
        const changedElementCodenames = this._getElementCodenames(changedElements);

        // Defer the notification after React cycle finishes to not intercept with the React life cycle with a callback that could modify something
        window.setTimeout(() => this.props.onElementsChanged(changedElementCodenames), 0);
      }
    }
  }

  private readonly _getElementCodenames = (
    changedElements: ReadonlyArray<ICompiledContentItemElementData>,
  ): Immutable.Set<string> => {
    const { typeElements } = this.props;
    if (!typeElements) {
      return Immutable.Set();
    }

    const changedElementCodenames = Immutable.Set.of(
      ...filterOutNullish(
        changedElements.map(
          (element) => typeElements.find((e) => e.elementId === element.elementId)?.codename,
        ),
      ),
    );

    return changedElementCodenames;
  };

  private readonly _getObservedElements = (
    elements: ReadonlyArray<ICompiledContentItemElementData>,
  ): ReadonlyArray<ICompiledContentItemElementData> => {
    const { observeAllElements, observedElementIds } = this.props;

    if (observeAllElements) {
      return elements;
    }

    const filteredElements = elements.filter((element: ICompiledContentItemElementData) =>
      observedElementIds.contains(element.elementId),
    );

    return filteredElements;
  };

  private readonly _getChangedElements = (
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    previousElements: ReadonlyArray<ICompiledContentItemElementData>,
  ): ReadonlyArray<ICompiledContentItemElementData> => {
    const changedElements = elements.filter((element: ICompiledContentItemElementData) => {
      const previousElement = getElementById(element.elementId, previousElements) ?? element;
      return hasElementChanged(element, previousElement);
    });

    return changedElements;
  };

  render() {
    return null;
  }
}
