import { Typography } from '@kontent-ai/component-library/tokens';
import { createGuid } from '@kontent-ai/utils';
import { useFocusWithin } from '@react-aria/interactions';
import classNames from 'classnames';
import React, { useState } from 'react';
import { MultilineInput } from '../../../../../../../_shared/components/MultilineInput.tsx';
import { DataUiInput } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ItemNameGuidelineStatus } from '../../../utils/itemEditingUtils.ts';
import { ItemElementLabel } from '../subComponents/ItemElementLabel.tsx';
import { ItemElementNameGuidelines } from './ItemElementNameGuidelines.tsx';

export type ItemNameElementProps = {
  readonly autoFocus?: boolean;
  readonly dataUiInputName: DataUiInput;
  readonly defaultLanguageName?: string;
  readonly defaultLanguagePath?: string;
  readonly guidelinesStatus: ItemNameGuidelineStatus;
  readonly isLoading: boolean;
  readonly isNameEditingAllowed?: boolean;
  readonly loadingPlaceholder?: string;
  readonly maxLength: number;
  readonly onChange?: (newName: string) => void;
  readonly onLanguageSwitch?: () => void;
  readonly placeholder: string;
  readonly readOnly?: boolean;
  readonly title: string;
  readonly value?: string;
};

export const ItemNameElement: React.FC<ItemNameElementProps> = ({
  autoFocus,
  dataUiInputName,
  defaultLanguageName,
  defaultLanguagePath,
  guidelinesStatus,
  isLoading,
  loadingPlaceholder,
  maxLength,
  onChange,
  onLanguageSwitch,
  placeholder,
  readOnly,
  title,
  value,
}) => {
  const [isCaretFocused, setIsCaretFocused] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setIsCaretFocused(isFocusWithin),
  });

  const labelId = createGuid();

  return (
    <div
      className={classNames('content-item-element__wrapper', {
        'content-item-element__wrapper--is-disabled': readOnly,
        'content-item-element__name-wrapper--is-disabled': readOnly,
      })}
    >
      <div
        className={classNames('content-item-element', 'content-item-element--is-name', {
          'content-item-element--is-caret-focused': isCaretFocused,
          'content-item-element--is-disabled': readOnly,
        })}
        {...focusWithinProps}
      >
        <div className="content-item-element__header">
          <ItemElementLabel id={labelId}>{title}</ItemElementLabel>
          <ItemElementNameGuidelines
            defaultLanguageName={defaultLanguageName}
            onLanguageSwitch={onLanguageSwitch}
            defaultLanguagePath={defaultLanguagePath}
            guidelinesStatus={guidelinesStatus}
          />
        </div>
        <div className="content-item-element__content">
          <MultilineInput
            aria-label={title}
            autoFocus={autoFocus}
            dataUiInput={dataUiInputName}
            id={labelId}
            isDisabled={readOnly || isLoading}
            maxLength={maxLength}
            onChange={readOnly || isLoading ? undefined : onChange}
            placeholder={(isLoading && loadingPlaceholder) || placeholder}
            typography={Typography.HeadlineLarge}
            value={isLoading ? '' : value}
          />
        </div>
      </div>
    </div>
  );
};

ItemNameElement.displayName = 'ItemNameElement';
