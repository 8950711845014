import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ProjectNameLabel } from '../constants/UIConstants.ts';
import { ProjectNameEditorElement } from '../containers/ProjectNameEditorElement.tsx';

export const ProjectNameCard: React.FC = () => {
  return (
    <Card
      cardLabel={ProjectNameLabel}
      component="section"
      {...getDataUiElementAttribute(DataUiElement.ProjectNameCard)}
    >
      <Card.Headline {...getDataUiElementAttribute(DataUiElement.ProjectNameCardTitle)}>
        {ProjectNameLabel}
      </Card.Headline>
      <Card.Body>
        <Box {...getDataUiElementAttribute(DataUiElement.ProjectNameInput)}>
          <ProjectNameEditorElement />
        </Box>
      </Card.Body>
    </Card>
  );
};

ProjectNameCard.displayName = 'ProjectNameCard';
