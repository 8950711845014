import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { isCloningInProgress } from '../../../../projects/utils/copyStateUtils.ts';
import { IEnvironmentViewModel } from '../../selectors/getEnvironmentsForListing.ts';

interface IEnvironmentTransitionButtonProps {
  readonly environment: IEnvironmentViewModel;
  readonly isStatusChanging: boolean;
  readonly onActivate?: () => void;
}

const getProjectTransitionButtonText = (environment: IEnvironmentViewModel): string => {
  if (environment.isBeingDeleted) {
    return 'Deleting environment';
  }
  if (environment.isBeingRenamed) {
    return 'Renaming environment';
  }
  if (environment.isBeingSwapped) {
    return 'Deploying to production';
  }
  if (isCloningInProgress(environment.copyState)) {
    return 'Creating environment';
  }
  return '';
};

export const EnvironmentTransitionButton: React.FC<IEnvironmentTransitionButtonProps> = (props) => {
  const { environment } = props;
  return (
    <Button buttonStyle="secondary" size="medium" disabled>
      <Button.ProgressIcon />
      <Button.Label>{getProjectTransitionButtonText(environment)}</Button.Label>
    </Button>
  );
};

EnvironmentTransitionButton.displayName = 'EnvironmentTransitionButton';
