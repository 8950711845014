import { Action } from '../../../../@types/Action.type.ts';
import {
  ItemVariantTimeline_Load_MoreItems_Finished,
  ItemVariantTimeline_Load_MoreItems_Started,
} from '../../features/Timeline/constants/timelineActionTypes.ts';

const initialState: boolean = false;

export function isLoadMoreTimelineItemsInProgress(state = initialState, action: Action) {
  switch (action.type) {
    case ItemVariantTimeline_Load_MoreItems_Started: {
      return true;
    }

    case ItemVariantTimeline_Load_MoreItems_Finished: {
      return false;
    }

    default:
      return state;
  }
}
