export const Role_Create_Finished = 'Role_Create_Finished';
export const Role_Create_Failed = 'Role_Create_Failed';
export const Role_Create_Started = 'Role_Create_Started';

export const Role_Creator_NoCapabilitiesSelected = 'Role_Creator_NoCapabilitiesSelected';

export const Role_Update_Finished = 'Role_Update_Finished';
export const Role_Update_Failed = 'Role_Update_Failed';
export const Role_Update_Started = 'Role_Update_Started';

export const Role_Delete_Started = 'Role_Delete_Started';
export const Role_Delete_Finished = 'Role_Delete_Finished';
export const Role_Delete_Failed = 'Role_Delete_Failed';

export const Role_CreatorInit_Started = 'Role_CreatorInit_Started';
export const Role_CreatorInit_Finished = 'Role_CreatorInit_Finished';

export const Role_EditorInit_Finished = 'Role_EditorInit_Finished';
export const Role_EditorInit_Started = 'Role_EditorInit_Started';

export const Role_Editing_NameChanged = 'Role_Editing_NameChanged';
export const Role_Editing_CapabilitiesChecked = 'Role_Editing_CapabilitiesChecked';
export const Role_Editing_CapabilitiesUnchecked = 'Role_Editing_CapabilitiesUnchecked';
export const Role_Editing_RoleSettingsChanged = 'Role_Editing_RoleSettingsChanged';
export const Role_Editing_CanRuleTypeAdded = 'Role_Editing_CanRuleTypeAdded';
export const Role_Editing_CanRuleTypeRemoved = 'Role_Editing_CanRuleTypeRemoved';
export const Role_Editing_CanRuleGroupAdded = 'Role_Editing_CanRuleGroupAdded';
export const Role_Editing_CanRuleGroupRemoved = 'Role_Editing_CanRuleGroupRemoved';
export const Role_Editing_CannotRuleTypeAdded = 'Role_Editing_CannotRuleTypeAdded';
export const Role_Editing_CannotRuleTypeRemoved = 'Role_Editing_CannotRuleTypeRemoved';
export const Role_Editing_CannotRuleGroupAdded = 'Role_Editing_CannotRuleGroupAdded';
export const Role_Editing_CannotRuleGroupRemoved = 'Role_Editing_CannotRuleGroupRemoved';

export const Roles_Listing_Left = 'Roles_Listing_Left';

export const Roles_ListingInit_Finished = 'Roles_ListingInit_Finished';
export const Roles_ListingInit_Started = 'Roles_ListingInit_Started';

export const Role_Restore_Finished = 'Role_Restore_Finished';
export const Role_Restore_Started = 'Role_Restore_Started';

export const Role_Validation_Failed = 'Role_Validation_Failed';
export const Role_Validation_FailedNoSelectedCapabilities =
  'Role_Validation_FailedNoSelectedCapabilities';
