import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../@types/Action.type.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import {
  Workflow_ArchivedStepEditor_Save,
  Workflow_Editing_EmptyTransitionsSaveFailed,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeEditing_Started,
  Workflow_PublishedStepEditor_Save,
  Workflow_ServerValidation_ReceivedStepErrors,
  Workflow_StepCreator_Cancel,
  Workflow_StepEditor_Delete,
  Workflow_StepEditor_Save,
} from '../../constants/workflowActionTypes.ts';
import {
  defaultStepErrorText,
  emptyTransitionStepErrorText,
  stepWithoutPathToPublishedErrorText,
} from '../../constants/workflowErrorMessages.ts';
import { WorkflowStepValidationError } from '../../model/WorkflowValidationErrors.ts';

const serverStepErrorToErrorMessageMap = {
  [WorkflowStepValidationError.NoPathToPublished]: stepWithoutPathToPublishedErrorText,
  [WorkflowStepValidationError.NoTransitions]: emptyTransitionStepErrorText,
} as const;

const isKnownError = (
  error: WorkflowStepValidationError,
): error is keyof typeof serverStepErrorToErrorMessageMap =>
  (
    Object.keys(serverStepErrorToErrorMessageMap) as ReadonlyArray<WorkflowStepValidationError>
  ).includes(error);

const mapServerStepErrorToErrorMessage = (
  error: WorkflowStepValidationError | undefined,
): string => {
  return !!error && isKnownError(error)
    ? serverStepErrorToErrorMessageMap[error]
    : defaultStepErrorText;
};

const defaultState: ReadonlyMap<Uuid, string> = new Map();

export const workflowErrors = (
  state: ReadonlyMap<Uuid, string> = defaultState,
  action: Action,
): ReadonlyMap<Uuid, string> => {
  switch (action.type) {
    case Workflow_Editing_EmptyTransitionsSaveFailed: {
      return action.payload.ids.reduce(
        (e: ReadonlyMap<Uuid, string>, id: Uuid) =>
          Collection.add(e, [id, emptyTransitionStepErrorText]),
        state,
      );
    }

    case Workflow_StepEditor_Save: {
      const { stepId, transitionsTo, transitionsFrom } = action.payload;

      if (transitionsTo.size !== 0) {
        return Collection.remove(state, stepId);
      }

      return Collection.getValues(transitionsFrom).reduce(
        (e: ReadonlyMap<Uuid, string>, step: IWorkflowStep) => Collection.remove(e, step.id),
        state,
      );
    }

    case Workflow_ArchivedStepEditor_Save:
    case Workflow_PublishedStepEditor_Save: {
      const { transitionsFrom } = action.payload;

      return Collection.getValues(transitionsFrom).reduce(
        (e: ReadonlyMap<Uuid, string>, step: IWorkflowStep) => Collection.remove(e, step.id),
        state,
      );
    }

    case Workflow_StepCreator_Cancel:
    case Workflow_StepEditor_Delete: {
      return Collection.remove(state, action.payload.stepId);
    }

    case Workflow_InitializeEditing_Started:
    case Workflow_Editing_WorkflowSaveFinished: {
      return defaultState;
    }

    case Workflow_ServerValidation_ReceivedStepErrors: {
      return new Map(
        Collection.getEntries(action.payload.errors).map(([stepId, errorCodes]) => [
          stepId,
          mapServerStepErrorToErrorMessage(Collection.getValues(errorCodes)[0]),
        ]),
      );
    }

    default:
      return state;
  }
};
