import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableCell } from '../../../../_shared/components/DataTable/DataTableCell.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import {
  SubscriptionEnvironmentWebhookMessagesRoute,
  SubscriptionEnvironmentWebhookMessagesRouteParams,
  SubscriptionEnvironmentWebhooksEditingRoute,
  SubscriptionEnvironmentWebhooksEditorRouteParams,
  WebhookEditorRouteParams,
  WebhookMessagesRoute,
  WebhookMessagesRouteParams,
  WebhooksEditingRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IEntityWebhookSetting } from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { WebhookHealthStatus } from '../../../webhooks/constants/webhookHealthStatuses.ts';
import { EntityWebhookStatusTransitionState } from '../../types/EntityWebhookStatusTransitionState.ts';
import { EntityWebhookHealthStatus } from './EntityWebhookHealthStatus.tsx';
import { EntityWebhookStatusToggle } from './EntityWebhookStatusToggle.tsx';

const tableHeadColumns: Immutable.List<Column> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: '',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--1',
  },
  {
    columnName: 'Health',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: 'URL',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Status',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--3',
  },
  {
    columnName: '',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--1',
  },
]);

const header = <DataTableHeadRow columns={tableHeadColumns} />;

type EntityWebhookDataTableProps = {
  readonly webhooks: ReadonlyArray<IEntityWebhookSetting>;
  readonly onEnable: (webhookId: Uuid) => void;
  readonly onDisable: (webhookId: Uuid) => void;
  readonly onReset: (webhookId: Uuid) => void;
  readonly webhooksStatusTransitionStates: ReadonlyMap<Uuid, EntityWebhookStatusTransitionState>;
};

const getMessagesPath = (
  projectId: string,
  subscriptionId: string,
  webhook: IEntityWebhookSetting,
) => {
  return subscriptionId === ''
    ? buildPath<WebhookMessagesRouteParams>(WebhookMessagesRoute, {
        projectId,
        webhookId: webhook.id,
      })
    : buildPath<SubscriptionEnvironmentWebhookMessagesRouteParams>(
        SubscriptionEnvironmentWebhookMessagesRoute,
        {
          projectId,
          subscriptionId,
          webhookId: webhook.id,
        },
      );
};

const getEditPath = (projectId: Uuid, subscriptionId: Uuid, webhook: IEntityWebhookSetting) => {
  return subscriptionId === ''
    ? buildPath<WebhookEditorRouteParams>(WebhooksEditingRoute, {
        projectId,
        webhookId: webhook.id,
      })
    : buildPath<SubscriptionEnvironmentWebhooksEditorRouteParams>(
        SubscriptionEnvironmentWebhooksEditingRoute,
        {
          projectId,
          subscriptionId,
          webhookId: webhook.id,
        },
      );
};

export const EntityWebhookDataTable = ({
  webhooks,
  onEnable,
  onDisable,
  onReset,
  webhooksStatusTransitionStates,
}: EntityWebhookDataTableProps) => {
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);
  const subscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );

  const rows = webhooks.map((webhook) => {
    const editUrl = getEditPath(projectId, subscriptionId, webhook);
    const messagesUrl = getMessagesPath(projectId, subscriptionId, webhook);

    const webhookStatusTransitionState = webhooksStatusTransitionStates.get(webhook.id);
    const canBeReset =
      webhook.enabled &&
      (webhook.healthStatus === WebhookHealthStatus.Dead ||
        webhook.healthStatus === WebhookHealthStatus.Failing);

    return (
      <DataTableRow
        dataUiObjectName={DataUiCollection.EntityWebhooks}
        id={webhook.id}
        key={webhook.id}
      >
        <LinkDataTableCell
          linkPath={editUrl}
          focusableRowLinkAriaLabel={`visit webhook — ${webhook.name}`}
        >
          {webhook.name}
        </LinkDataTableCell>
        <DataTableCell>
          {canBeReset && (
            <QuinaryButton
              buttonStyle="default"
              tooltipText="Reset webhook"
              onClick={() => onReset(webhook.id)}
            >
              <Icon iconName={IconName.RotateLeft} />
            </QuinaryButton>
          )}
        </DataTableCell>
        <LinkDataTableCell linkPath={editUrl}>
          <EntityWebhookHealthStatus
            enabled={webhook.enabled}
            healthStatus={webhook.healthStatus}
          />
        </LinkDataTableCell>
        <LinkDataTableCell linkPath={editUrl}>{webhook.url}</LinkDataTableCell>
        <DataTableCell>
          <EntityWebhookStatusToggle
            webhook={webhook}
            webhookStatusTransitionState={webhookStatusTransitionState}
            onEnable={onEnable}
            onDisable={onDisable}
          />
        </DataTableCell>
        <LinkDataTableCell
          className="data-table__cell-item--entity-webhooks-debugging"
          linkPath={messagesUrl}
        >
          <QuinaryButton tooltipPlacement="top-start" tooltipText="Debugging">
            <Icon iconName={IconName.Bug} />
          </QuinaryButton>
        </LinkDataTableCell>
      </DataTableRow>
    );
  });

  return (
    <DataTable header={header} dataUiCollectionName={DataUiCollection.EntityWebhooks}>
      {rows}
    </DataTable>
  );
};

EntityWebhookDataTable.displayName = 'EntityWebhookDataTable';
