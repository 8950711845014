const ContentGroupTabsIdSymbol: unique symbol = Symbol('ContentGroupTabsIdSymbol');

export type ContentGroupTabsId = string & {
  contentGroupTabsIdSymbol: typeof ContentGroupTabsIdSymbol;
};

export const CreateContentGroupTabsId = Object.freeze({
  forContentComponent: (contentComponentId: Uuid): ContentGroupTabsId => {
    return contentComponentId as ContentGroupTabsId;
  },

  forContentItem: (contentItemId: Uuid): ContentGroupTabsId => {
    return contentItemId as ContentGroupTabsId;
  },

  forLinkedItem: (
    elementId: Uuid,
    contentComponentId: Uuid,
    contentItemId: Uuid,
  ): ContentGroupTabsId => {
    return `${elementId}_${contentComponentId}_${contentItemId}` as ContentGroupTabsId;
  },

  forRichTextLinkedItem: (
    blockKey: string,
    contentItemId: Uuid,
    nestingLevel: number,
  ): ContentGroupTabsId => {
    return `${blockKey}_${contentItemId}_${nestingLevel}` as ContentGroupTabsId;
  },
});
