import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadContentTypes,
  loadCustomApps,
  loadNormalizedRoleWithSettings,
  loadRoles,
  loadUsers,
  loadWorkflows,
} from '../../../../data/actions/thunkDataActions.ts';
import { makeCreateRoleAction } from './thunks/createRole.ts';
import { makeDeleteEditedRoleAction } from './thunks/deleteEditedRole.ts';
import { makeInitRoleCreatorAction } from './thunks/initRoleCreator.ts';
import { makeInitRoleEditorAction } from './thunks/initRoleEditor.ts';
import { makeInitRoleListingAction } from './thunks/initRoleListing.ts';
import { makeRestoreDeletedRoleAction } from './thunks/restoreDeletedRole.ts';
import { makeUpdateRoleAction } from './thunks/updateRole.ts';

const { roleRepository } = repositoryCollection;

export const createRole = makeCreateRoleAction({
  roleRepository,
});

export const updateRole = makeUpdateRoleAction({
  roleRepository,
  reloadNormalizedRoleWithSettings: loadNormalizedRoleWithSettings,
});

export const deleteEditedRole = makeDeleteEditedRoleAction({
  roleRepository,
});

export const initRoleListing = makeInitRoleListingAction({
  loadRoles,
  loadUsers,
});

export const initRoleEditor = makeInitRoleEditorAction({
  roleRepository,
  loadRoles,
  loadUsers,
  loadWorkflows,
  loadTypes: loadContentTypes,
  loadCustomApps,
});

export const initRoleCreator = makeInitRoleCreatorAction({
  loadRoles,
  loadTypes: loadContentTypes,
});

export const restoreDeletedRole = makeRestoreDeletedRoleAction({
  roleRepository,
  loadRoles,
});
