import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { createRef, useEffect } from 'react';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly link: string | null;
  readonly loading?: boolean;
  readonly onCreateLinkedFilter: () => void;
};

export const ShareFilterLinkForm = ({ link, loading, onCreateLinkedFilter }: Props) => {
  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!link && !loading) {
      onCreateLinkedFilter();
    }
  }, [onCreateLinkedFilter, link, loading]);

  return (
    <div className="filter-share-link">
      <LabelFor
        target={(inputId: Uuid) => (
          <div ref={containerRef}>
            <Clipboard
              containerRef={containerRef}
              id={inputId}
              overlayMessage={loading ? 'Loading…' : undefined}
              value={link || ''}
              {...getDataUiInputAttribute(DataUiInput.Url)}
            />
          </div>
        )}
      >
        Filter link
      </LabelFor>
    </div>
  );
};
