import { Auth0RedirectUriStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

let _uri: string | null = null;

export const auth0RedirectUriStorage = {
  save(uri: string): void {
    localStorage.set(Auth0RedirectUriStorageKey, uri);
    _uri = uri;
  },

  forget(): void {
    localStorage.remove(Auth0RedirectUriStorageKey);
    _uri = null;
  },

  load(): string | null {
    if (!_uri) {
      const value = localStorage.get(Auth0RedirectUriStorageKey);
      _uri = value ? value : null;
    }

    return _uri;
  },
};
