import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useCallback, useEffect, useState } from 'react';
import { DropDownFrame } from '../../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { IDropdownTippyOptions } from '../../../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EditingAction } from '../../../models/EditingAction.ts';

type QuickActionsDropDownMenuProps = {
  readonly activatedAction: EditingAction;
  readonly onClickOutside: () => void;
  readonly renderButton: (ref: React.RefObject<HTMLElement> | undefined) => React.ReactElement;
};

const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left-start', 'right-start', 'left', 'right'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: Spacing.S,
        },
      },
    ],
  },
};

export const QuickActionsDropDownMenu: React.FC<
  React.PropsWithChildren<QuickActionsDropDownMenuProps>
> = ({ activatedAction, children, onClickOutside, renderButton }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClickOutside = useCallback(() => {
    setIsMenuOpen(false);
    onClickOutside();
  }, [onClickOutside]);

  useEffect(() => {
    if (activatedAction === EditingAction.none) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }, [activatedAction]);

  return (
    <DropDownFrame
      allowAnimation={false}
      isOptionListVisible={isMenuOpen}
      longer
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.QuickActionDropdown,
      )}
      onClickOutside={isMenuOpen ? handleClickOutside : undefined}
      renderContent={() => children}
      renderSelector={renderButton}
      tippyOptions={tippyOptions}
    />
  );
};

QuickActionsDropDownMenu.displayName = 'QuickActionsDropDownMenu';
