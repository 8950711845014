import { forwardRef } from 'react';
import { ConfirmationDialog } from '../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';

type Props = IAnimatedModalDialogProps & {
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const CancelScheduleUnpublishConfirmationDialog = forwardRef<HTMLDivElement, Props>(
  ({ onCancel, onConfirm, ...props }, ref) => {
    return (
      <ConfirmationDialog
        alert
        confirmButtonText="Remove schedule"
        headerContent="Remove the unpublishing schedule"
        onClose={onCancel}
        onConfirm={onConfirm}
        ref={ref}
        {...props}
      >
        <p className="confirmation-dialog__section">
          The scheduled unpublish date will be removed and{' '}
          <strong>the item won’t be unpublished and archived.</strong>
        </p>
        <p className="confirmation-dialog__section">
          Are you sure you want to remove the scheduled unpublish date?
        </p>
      </ConfirmationDialog>
    );
  },
);

CancelScheduleUnpublishConfirmationDialog.displayName = 'CancelScheduleUnpublishConfirmationDialog';
