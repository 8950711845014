import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../../_shared/components/AppBar/BackNavigation.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getGoBackLinkByPath } from '../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';

export const ContentTypeSnippetBackNavigation: React.FC = () => {
  const location = useLocation();
  const isDisabled = useSelector(
    (state) => state.contentModelsApp.snippets.editor.contentTypeSnippetIsBeingSaved,
  );
  const to = getGoBackLinkByPath(location.pathname);

  return <BackNavigation to={to} isDisabled={isDisabled} />;
};

ContentTypeSnippetBackNavigation.displayName = 'ContentTypeSnippetBackNavigation';
