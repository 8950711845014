import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { getContentVariantState } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { getYourTaskVariantIdentifier } from '../../../../yourContent/selectors/getYourTasks.ts';
import { createContentItemId } from '../../shared/utils/createContentItemId.ts';
import { TasksAssignedToYouWidgetDetail } from '../components/TasksAssignedToYouWidgetDetail.tsx';
import { TasksAssignedToYouWidgetDetailRow } from '../components/TasksAssignedToYouWidgetDetailRow.tsx';
import { YourTaskWithPathToItem } from '../types/YourTaskWithPathToItem.type.ts';

type TasksAssignedToYouWidgetDetailContainerProps = {
  readonly ariaLabelledBy: string;
  readonly tasks: ReadonlyArray<YourTaskWithPathToItem>;
  readonly onTaskClick: (taskId: Uuid, itemIdentity: ContentItemId) => void;
};

export const TasksAssignedToYouWidgetDetailContainer: React.FC<
  TasksAssignedToYouWidgetDetailContainerProps
> = ({ ariaLabelledBy, tasks, onTaskClick }) => {
  const relatedItems = useSelector((s) => s.data.yourTasksContentItems.byId);

  const getVariantCompletionStatus = (contentItemId: Uuid, variantId: Uuid) => {
    const itemVariantKey = getYourTaskVariantIdentifier(contentItemId, variantId);
    return getContentVariantState(relatedItems.get(itemVariantKey)?.variant);
  };

  return (
    <TasksAssignedToYouWidgetDetail ariaLabelledBy={ariaLabelledBy}>
      {tasks.map((task) => (
        <TasksAssignedToYouWidgetDetailRow
          key={task.id}
          task={task}
          variantCompletionStatus={getVariantCompletionStatus(task.itemId, task.variantId)}
          onTaskClick={() => onTaskClick(task.id, createContentItemId(task.itemId, task.variantId))}
          rowFocusLinkAriaLabel={`visit content item with task — ${task.description}`}
        />
      ))}
    </TasksAssignedToYouWidgetDetail>
  );
};
