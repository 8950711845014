import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { isValidJsonObject } from '../../../../_shared/utils/jsonUtils.ts';
import { urlStartsWithHttps } from '../../../../_shared/utils/urlUtils.ts';
import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { isAbsoluteUrlBuilder } from '../../../../_shared/utils/validation/isAbsoluteUrl.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { isNotLocalhostUrlBuilder } from '../../../../_shared/utils/validation/isLocalhostUrl.ts';
import {
  ProvideValidJsonObject,
  UseSecureHTTP,
} from '../../../contentModels/shared/constants/errorMessageTemplates.ts';
import { TextTooLongToSaveError } from '../../../itemEditor/constants/errorMessages.ts';
import { deletedRoleIdBase } from '../../constants/getDeletedRole.ts';
import {
  customAppContainsDeletedRoleError,
  customAppContainsDeletedRolesError,
  customAppEmptyRolesErrorText,
  customAppLocalhostUrlErrorMessage,
} from '../constants/customAppErrorMessages.ts';
import { CustomAppFormShape } from '../models/CustomAppFormShape.type.ts';

const customAppShouldHasAbsoluteUrlAddress = isAbsoluteUrlBuilder();
const customAppSourceUrlMustNotHaveLocalhostDomain = isNotLocalhostUrlBuilder(
  customAppLocalhostUrlErrorMessage,
);
const customAppSourceUrlShouldStartWithHttps = (sourceUrl: string): string | undefined =>
  urlStartsWithHttps(sourceUrl) ? undefined : UseSecureHTTP;

const nameRequired = nonEmptyValidationBuilder('name', 'custom app');
const urlRequired = nonEmptyValidationBuilder('URL address', 'custom app');
const containsInvalidJsonObject = (config: string): string | undefined =>
  !config || isValidJsonObject(config) ? undefined : ProvideValidJsonObject;

const containsTooLongConfig = (config: string): string | undefined =>
  config && config.length > ValidationConstants.CustomAppConfigMaxLength
    ? TextTooLongToSaveError
    : undefined;

const ShouldNotContainDeletedRole = (roles: ReadonlyArray<string>): string | undefined => {
  const deletedRoles = roles.filter((roleId) => roleId.includes(deletedRoleIdBase));

  switch (deletedRoles.length) {
    case 1:
      return customAppContainsDeletedRoleError;

    case 0:
      return undefined;

    default:
      return customAppContainsDeletedRolesError;
  }
};

export const hasSelectedAnyRole = (roles: ReadonlyArray<string>): string | undefined => {
  return roles.length === 0 ? customAppEmptyRolesErrorText : undefined;
};

export const customAppEditorFormValidationConfig: FormValidatorConfig<CustomAppFormShape> = {
  name: [nameRequired],
  config: [containsInvalidJsonObject, containsTooLongConfig],
  allowedRoleIds: [ShouldNotContainDeletedRole, hasSelectedAnyRole],
  sourceUrl: [
    urlRequired,
    customAppShouldHasAbsoluteUrlAddress,
    customAppSourceUrlShouldStartWithHttps,
    customAppSourceUrlMustNotHaveLocalhostDomain,
  ],
};
