import { InvariantException } from '@kontent-ai/errors';
import { getContentVariantState } from '../../../_shared/utils/contentItemVariantUtils.ts';
import { IListingContentItemWithMetadataServerModel } from '../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { mapToAssignmentWorkflowStep } from '../workflow/WorkflowStep.ts';
import { YourContentItem } from './IAssignedListingContentItem.ts';
import { getListingVariantDataFromJS } from './IListingVariantData.ts';

export interface IRecentlyEditedListingContentItem extends YourContentItem {}

export const createRecentlyEditedContentItem = (
  serverModel: IListingContentItemWithMetadataServerModel,
): IRecentlyEditedListingContentItem => {
  const { item, variant, personalMetadata } = serverModel;
  if (!variant) {
    throw InvariantException('Content item variant wasn’t loaded.');
  }

  return {
    id: variant.id,
    contentType: '',
    contentTypeId: item.type._id,
    due: variant.assignment.due,
    language: '',
    lastUpdatedAt: personalMetadata.lastEditedByUser,
    name: item.name,
    publishingState: variant.publishingState,
    scheduledToPublishAt: variant.assignment.publishScheduleTime,
    scheduledToUnpublishAt: variant.assignment.unpublishScheduleTime,
    variantCompletionStatus: getContentVariantState(getListingVariantDataFromJS(variant)),
    workflowStatus: mapToAssignmentWorkflowStep(variant.assignment.workflowStatus),
  };
};
