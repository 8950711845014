import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { ContentGroupTabs } from '../containers/ContentGroupTabs.tsx';
import { ContentItemCollection } from '../containers/elements/ContentItemCollection.tsx';
import { ItemNameElement } from '../containers/elements/ItemNameElement.tsx';
import { ContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import { ContentItemPaneElementsGroup } from './ContentItemPaneElementsGroup.tsx';

type Props = {
  readonly areAnyContentGroupsVisible: boolean;
  readonly canEditGroupContent: boolean;
  readonly canEditName: boolean;
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentType: ICompiledContentType;
  readonly isCollectionInMainPaneEnabled: boolean;
};

export const ContentItemHeader = ({
  areAnyContentGroupsVisible,
  canEditGroupContent,
  canEditName,
  contentGroupTabsId,
  contentType,
  isCollectionInMainPaneEnabled,
}: Props) => {
  return (
    <>
      <ContentItemPaneElementsGroup
        isDisabled={!canEditName}
        hasTopRoundedCorners
        hasBottomRoundedCorners={areAnyContentGroupsVisible || isCollectionInMainPaneEnabled}
      >
        <ItemNameElement />
        {isCollectionInMainPaneEnabled && <ContentItemCollection />}
      </ContentItemPaneElementsGroup>

      {areAnyContentGroupsVisible && (
        <ContentItemPaneElementsGroup areHeaderContentGroups isDisabled={!canEditGroupContent}>
          <ContentGroupTabs
            canEditContent={canEditGroupContent}
            contentGroupTabsId={contentGroupTabsId}
            contentType={contentType}
          />
        </ContentItemPaneElementsGroup>
      )}
    </>
  );
};
