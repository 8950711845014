import { useCallback } from 'react';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { taxonomyGroupCodenameChanged } from '../../actions/taxonomyActions.ts';
import { updateEditedTaxonomyGroup } from '../../actions/thunkTaxonomyActions.ts';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';
import { getTaxonomyGroupCodenamesWithoutEditedTaxonomyGroupCodename } from '../../utils/taxonomyCodenameUtils.ts';

export const TaxonomyEditorToolbarActions = () => {
  const codename = useSelector((s) => s.taxonomyApp.editedTaxonomyGroup.codename);
  const relatedCodenames = useSelector((s) => {
    const groups = s.data.taxonomyGroups.byId;
    const editedGroupId = s.taxonomyApp.editedTaxonomyGroup.id;

    return getTaxonomyGroupCodenamesWithoutEditedTaxonomyGroupCodename(groups, editedGroupId);
  });
  const isBeingSaved = useSelector(
    (s) => s.taxonomyApp.status === TaxonomyEditorStatus.IsBeingSaved,
  );
  const isLoading = useSelector((s) => s.taxonomyApp.status === TaxonomyEditorStatus.Loading);

  const dispatch = useDispatch();
  const onCodenameSave = useCallback((newCodename: string): void => {
    dispatch(taxonomyGroupCodenameChanged(newCodename));
  }, []);
  const onSave = useCallback(() => dispatch(updateEditedTaxonomyGroup()), []);
  const onCodenameCopy = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.Codename, {
          type: CodenameEventType.Copy,
          target: CodenameTargetType.TaxonomyGroup,
        }),
      ),
    [],
  );
  const onCodenameEdit = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.Codename, {
          type: CodenameEventType.Edit,
          target: CodenameTargetType.TaxonomyGroup,
        }),
      ),
    [],
  );

  return (
    <StatusBarActions
      codename={codename}
      relatedCodenames={relatedCodenames}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      isCodenameEditable
      onCodenameSave={onCodenameSave}
      onSave={onSave}
      onCodenameCopy={onCodenameCopy}
      onCodenameEdit={onCodenameEdit}
    />
  );
};
