import { getEditedContentItemType } from '../../../../applications/itemEditor/selectors/getEditedContentItemType.ts';
import { DefaultVariantId } from '../../../constants/variantIdValues.ts';
import { ActiveCapabilityType } from '../../../models/activeCapability.type.ts';
import { getEditedContentItemDefaultVariant } from '../../../selectors/getEditedContentItemDefaultVariant.ts';
import { getSelectedLanguageId } from '../../../selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import { isScheduleToPublishWorkflowStepSelected } from '../../../utils/contentItemUtils.ts';
import { hasActiveVariantCapability } from '../../../utils/permissions/activeCapabilities.ts';

export const isPublishingAvailable = (state: IStore): boolean => {
  const selectedLanguageId = getSelectedLanguageId(state);
  if (selectedLanguageId === DefaultVariantId) {
    return true;
  }

  const itemType = getEditedContentItemType(state);
  if (!itemType) {
    return true;
  }

  const itemWithDefaultVariant = getEditedContentItemDefaultVariant(state);
  const canViewDefaultVariant = hasActiveVariantCapability(
    ActiveCapabilityType.ViewContent,
    itemWithDefaultVariant,
  );
  const dependsOnDefaultVariant =
    canViewDefaultVariant && itemType.contentElements.some((element) => element.isNonLocalizable);
  const isDefaultVariantScheduled = isScheduleToPublishWorkflowStepSelected(
    itemWithDefaultVariant?.variant?.assignment,
  );

  return !itemWithDefaultVariant || !dependsOnDefaultVariant || !isDefaultVariantScheduled;
};
