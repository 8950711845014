import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import { SubscriptionPropertyServerModel } from '../../../repositories/serverModels/SubscriptionPropertyServerModel.type.ts';
import { Shared_LoadSubscriptionProperties_Finished } from '../../constants/sharedActionTypes.ts';

interface IDependencies {
  readonly subscriptionRepository: ISubscriptionRepository;
}

const finished = (properties: ReadonlyArray<SubscriptionPropertyServerModel>) =>
  ({
    type: Shared_LoadSubscriptionProperties_Finished,
    payload: {
      properties,
    },
  }) as const;

export type LoadSubscriptionPropertiesActionsType = ReturnType<typeof finished>;

export const createLoadSubscriptionProperties =
  (deps: IDependencies) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const properties = await deps.subscriptionRepository.getProperties(subscriptionId, abortSignal);
    dispatch(finished(properties));
  };
