import { Action } from '../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentItemEditing_ItemElementsSaving_Failed,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LanguageVariantSwitched,
  ContentItemEditing_Saving_Failed,
} from '../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemSavingOrigin } from '../../itemEditor/features/ContentItemEditing/models/ContentItemSavingOrigin.ts';
import {
  WebSpotlight_LocalizedRoute_Left,
  WebSpotlight_Node_Selected,
  WebSpotlight_PreviewApiPolling_Failed,
  WebSpotlight_PreviewApiPolling_Finished,
  WebSpotlight_Preview_Refreshed,
} from '../constants/webSpotlightActionTypes.ts';
import { PreviewApiDataStatus } from '../models/PreviewApiDataStatus.ts';

const initialState: PreviewApiDataStatus = PreviewApiDataStatus.Initial;

export const previewApiDataStatus = (state = initialState, action: Action) => {
  switch (action.type) {
    case ContentItemEditing_ItemElementsSaving_Failed:
    case ContentItemEditing_LanguageVariantSwitched:
    case ContentItemEditing_Saving_Failed:
    case Shared_CurrentProjectId_Updated:
    case WebSpotlight_LocalizedRoute_Left:
    case WebSpotlight_Node_Selected: {
      return initialState;
    }

    case WebSpotlight_PreviewApiPolling_Failed: {
      return PreviewApiDataStatus.FetchingFailed;
    }

    case WebSpotlight_PreviewApiPolling_Finished: {
      return PreviewApiDataStatus.UpToDate;
    }

    case ContentItemEditing_ItemElementsSaving_Finished:
    case ContentItemEditing_ItemSaving_Finished: {
      // ignore changes made inside both regular and Web Spotlight editor
      if (action.payload.origin !== ContentItemSavingOrigin.FloatingEditor) {
        return state;
      }

      return PreviewApiDataStatus.Outdated;
    }

    case WebSpotlight_Preview_Refreshed: {
      // reset state when user triggered preview information reload by clicking on preview button
      return action.payload.manualRefresh ? initialState : state;
    }

    default: {
      return state;
    }
  }
};
