import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { Content_HideValidationResults } from '../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_Elements_HighlightIncomplete,
  ContentItemEditing_FocusValidationWarningsBeforePublish,
  ContentItemEditing_Init_Started,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: boolean = false;

export function showIncomplete(state = initialState, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_Elements_HighlightIncomplete: {
      return true;
    }
    case ContentItemEditing_FocusValidationWarningsBeforePublish: {
      return true;
    }

    case Content_HideValidationResults:
    case ContentItemEditing_Init_Started:
    case ContentListing_Init_Started: {
      return initialState;
    }

    default:
      return state;
  }
}
