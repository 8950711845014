import { createNumberValue } from '../../../../_shared/models/NumberValue.ts';
import { isNumeric } from '../../../../_shared/utils/validation/isNumeric.ts';
import { INumberItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { INumberItemElement } from '../../models/contentItemElements/NumberItemElement.ts';
import {
  getNumberOfDecimalPlaces,
  normalizeNumberString,
} from '../elementErrorCheckers/utils/isNumberTooLarge.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export const getServerNumberValue = (value: string): number | null => (value ? +value : null);

export const numberElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
  INumberItemElementServerModel,
  INumberItemElement
> = (serverElement) => {
  const { elementId, value, lastModifiedBy, lastModifiedAt } = serverElement;
  const stringValue = isNumeric(value)
    ? normalizeNumberString(value.toFixed(getNumberOfDecimalPlaces(value.toString())))
    : '';

  return {
    type: ElementType.Number,
    elementId,
    value: stringValue,
    _number: createNumberValue({
      value: stringValue,
    }),
    lastModifiedBy: lastModifiedBy ? lastModifiedBy : null,
    lastModifiedAt: lastModifiedAt ? lastModifiedAt : null,
  };
};

export const numberElementServerModelConverter: ISimpleItemElementServerModelConverter<
  INumberItemElement,
  INumberItemElementServerModel
> = (element) => {
  const { elementId, value, lastModifiedBy, lastModifiedAt } = element;
  const numberValue = getServerNumberValue(value);

  const serverModel: INumberItemElementServerModel = {
    elementId,
    type: ElementType.Number,
    value: numberValue,
    lastModifiedBy,
    lastModifiedAt,
  };
  return serverModel;
};
