import Immutable from 'immutable';
import { ITaxonomyGroupServerModel } from '../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  Taxonomy_GroupCreate_Initialized,
  Taxonomy_Group_CodenameChanged,
  Taxonomy_Group_CreationFailed,
  Taxonomy_Group_CreationFinished,
  Taxonomy_Group_CreationStarted,
  Taxonomy_Group_NameChanged,
  Taxonomy_Group_SavingFailed,
  Taxonomy_Group_SavingFinished,
  Taxonomy_Group_SavingStarted,
  Taxonomy_Groups_Loaded,
  Taxonomy_Groups_SelectionChanged,
  Taxonomy_Terms_HideCreateNewForm,
  Taxonomy_Terms_ShowCreateNewForm,
  Taxonomy_Terms_TermCodenameChanged,
  Taxonomy_Terms_TermCreated,
  Taxonomy_Terms_TermDropped,
  Taxonomy_Terms_TermEditingAbandoned,
  Taxonomy_Terms_TermEditingInitiated,
  Taxonomy_Terms_TermNameChanged,
  Taxonomy_Terms_TermRemoved,
} from '../constants/taxonomyActionTypes.ts';

export const taxonomyGroupsLoaded = (taxonomyGroups: ReadonlyArray<ITaxonomyGroupServerModel>) =>
  ({
    type: Taxonomy_Groups_Loaded,
    payload: { taxonomyGroups },
  }) as const;

export const taxonomyCreatorInitialized = () =>
  ({
    type: Taxonomy_GroupCreate_Initialized,
  }) as const;

export const taxonomyGroupSelectionChanged = (selectedTaxonomyGroupIds: Immutable.Set<Uuid>) =>
  ({
    type: Taxonomy_Groups_SelectionChanged,
    payload: { selectedTaxonomyGroupIds },
  }) as const;

export const taxonomyGroupCreationStarted = () =>
  ({
    type: Taxonomy_Group_CreationStarted,
  }) as const;

export const taxonomyGroupCreationFinished = (editedTaxonomyGroup: ITaxonomyGroupServerModel) =>
  ({
    type: Taxonomy_Group_CreationFinished,
    payload: { editedTaxonomyGroup },
  }) as const;

export const taxonomyGroupCreationFailed = (errorMessage: string) =>
  ({
    type: Taxonomy_Group_CreationFailed,
    payload: { errorMessage },
  }) as const;

export const taxonomyGroupSavingStarted = () =>
  ({
    type: Taxonomy_Group_SavingStarted,
  }) as const;

export const taxonomyGroupSavingFinished = (editedTaxonomyGroup: ITaxonomyGroupServerModel) =>
  ({
    type: Taxonomy_Group_SavingFinished,
    payload: { editedTaxonomyGroup },
  }) as const;

export const taxonomyGroupSavingFailed = (errorMessage: string) =>
  ({
    type: Taxonomy_Group_SavingFailed,
    payload: { errorMessage },
  }) as const;

export const taxonomyGroupNameChanged = (name: string) =>
  ({
    type: Taxonomy_Group_NameChanged,
    payload: { name },
  }) as const;

export const taxonomyGroupCodenameChanged = (codename: string) =>
  ({
    type: Taxonomy_Group_CodenameChanged,
    payload: { codename },
  }) as const;

export const taxonomyTermNameChanged = (termId: Uuid, termName: string) =>
  ({
    type: Taxonomy_Terms_TermNameChanged,
    payload: {
      termId,
      termName,
    },
  }) as const;

export const taxonomyTermCodenameChanged = (termId: Uuid, termCodename: string) =>
  ({
    type: Taxonomy_Terms_TermCodenameChanged,
    payload: {
      termId,
      termCodename,
    },
  }) as const;

export const taxonomyTermCreated = (termName: string) =>
  ({
    type: Taxonomy_Terms_TermCreated,
    payload: { termName },
  }) as const;

export const taxonomyTermRemoved = (termId: Uuid) =>
  ({
    type: Taxonomy_Terms_TermRemoved,
    payload: { termId },
  }) as const;

export const taxonomyTermDropped = () =>
  ({
    type: Taxonomy_Terms_TermDropped,
  }) as const;

export const taxonomyTermEditingInitiated = (termId: Uuid) =>
  ({
    type: Taxonomy_Terms_TermEditingInitiated,
    payload: { termId },
  }) as const;

export const taxonomyTermEditingAbandoned = () =>
  ({
    type: Taxonomy_Terms_TermEditingAbandoned,
  }) as const;

export const showCreateNewTermForm = () =>
  ({
    type: Taxonomy_Terms_ShowCreateNewForm,
  }) as const;

export const hideCreateNewTermForm = () =>
  ({
    type: Taxonomy_Terms_HideCreateNewForm,
  }) as const;
