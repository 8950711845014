import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentTypeSnippet_Creator_CreationFinished,
  ContentTypeSnippet_Creator_CreationStarted,
  ContentTypeSnippet_Editor_SavingFinished,
  ContentTypeSnippet_Editor_SavingStarted,
} from '../../constants/snippetActionTypes.ts';

export function contentTypeSnippetIsBeingSaved(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentTypeSnippet_Creator_CreationStarted:
    case ContentTypeSnippet_Editor_SavingStarted: {
      return true;
    }

    case ContentTypeSnippet_Creator_CreationFinished:
    case ContentTypeSnippet_Editor_SavingFinished: {
      return false;
    }

    default:
      return state;
  }
}
