import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import classNames from 'classnames';
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  forwardRef,
} from 'react';
import TextareaAutoSize from 'react-textarea-autosize';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataAttributeProps,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Icon } from '../Icon/Icon.tsx';

interface Props {
  readonly buttonConfiguration?: ButtonConfiguration;
  readonly customClassName?: string;
  readonly dataUiInputName?: DataUiInput;
  readonly disabled?: boolean;
  readonly autoFocus?: boolean;
  readonly hasError?: boolean;
  readonly inputConfiguration?: InputConfiguration;
  readonly inputId: string;
  readonly isMultiline?: boolean;
  readonly maxLength?: number;
  readonly onChange?: (event: any) => void;
  readonly placeholder: string;
  readonly readOnly?: boolean;
  readonly value?: string;
  readonly tooltipText?: string;
  readonly tooltipPlacement?: Placement;
}

type InputConfiguration = {
  readonly onBlur?: FocusEventHandler;
  readonly onChange?: ChangeEventHandler;
  readonly onClick?: MouseEventHandler;
  readonly onFocus?: FocusEventHandler;
  readonly onKeyDown?: KeyboardEventHandler;
  readonly onMouseUp?: MouseEventHandler;
  readonly onMouseDown?: MouseEventHandler;
};

type ButtonConfiguration = {
  readonly dataUiAction: DataUiAction;
  readonly tooltipPlacement: Placement;
  readonly tooltipText: string;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly onClick: () => void;
  readonly showBalloon: boolean | undefined;
};

export const TextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    buttonConfiguration,
    customClassName,
    dataUiInputName,
    disabled,
    autoFocus,
    hasError,
    inputConfiguration,
    inputId,
    maxLength,
    onChange,
    placeholder,
    readOnly,
    value,
    tooltipText,
    tooltipPlacement = 'top',
  } = props;

  return (
    <div
      className={classNames('text-field', {
        'text-field--has-error': hasError,
        'text-field--has-button': buttonConfiguration,
        'text-field--is-disabled': disabled,
      })}
    >
      {props.isMultiline ? (
        <TextareaAutoSize
          className={classNames(
            'text-field__input',
            'text-field__input--is-multiline',
            customClassName,
          )}
          disabled={disabled}
          id={inputId}
          maxLength={maxLength}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref as any}
          value={value}
          {...inputConfiguration}
          {...getDataAttributeProps(props.inputConfiguration || {})}
        />
      ) : (
        <Tooltip tooltipText={tooltipText} placement={tooltipPlacement}>
          <input
            autoComplete="off"
            className={classNames('text-field__input', customClassName, {
              'text-field__input--is-readonly': readOnly,
            })}
            disabled={disabled}
            autoFocus={autoFocus}
            id={inputId}
            maxLength={maxLength}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            type="text"
            value={value}
            {...inputConfiguration}
            {...(dataUiInputName && getDataUiInputAttribute(dataUiInputName))}
          />
        </Tooltip>
      )}
      {buttonConfiguration && (
        <div className="text-field__button-pane">
          <Tooltip
            tooltipText={
              buttonConfiguration.showBalloon && !buttonConfiguration.disabled
                ? buttonConfiguration.tooltipText
                : undefined
            }
            placement={buttonConfiguration.tooltipPlacement}
          >
            <div className="text-field__button">
              <div
                className={classNames('text-field__button-icon', {
                  'text-field__button-icon--is-disabled': buttonConfiguration.disabled,
                })}
                onClick={buttonConfiguration.disabled ? undefined : buttonConfiguration.onClick}
                {...getDataUiActionAttribute(buttonConfiguration.dataUiAction)}
              >
                <Icon iconName={buttonConfiguration.iconName} />
              </div>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
});
