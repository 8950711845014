import { Action } from '../../../../@types/Action.type.ts';
import {
  ApiKeyDetail_Creating_Finished,
  ApiKeyDetail_Loading_Finished,
  ApiKeyDetail_Loading_Started,
  ApiKeyDetail_Regeneration_Finished,
  ApiKeyDetail_Updating_Finished,
} from '../../../../applications/projectSettings/apiKeys/constants/apiKeysActionTypes.ts';
import {
  ApiKey,
  emptyApiKey,
} from '../../../../applications/projectSettings/apiKeys/models/ApiKey.ts';
import {
  SubscriptionApiKey_Creation_Finished,
  SubscriptionApiKey_Loading_Finished,
  SubscriptionApiKey_Loading_Started,
  SubscriptionApiKey_Regeneration_Finished,
} from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';

export const keyDetail = (state: ApiKey = emptyApiKey, action: Action): ApiKey => {
  switch (action.type) {
    case ApiKeyDetail_Loading_Finished:
    case ApiKeyDetail_Creating_Finished:
    case ApiKeyDetail_Regeneration_Finished:
    case ApiKeyDetail_Updating_Finished:
    case SubscriptionApiKey_Creation_Finished:
    case SubscriptionApiKey_Loading_Finished:
    case SubscriptionApiKey_Regeneration_Finished: {
      return action.payload.apiKey;
    }

    case ApiKeyDetail_Loading_Started:
    case SubscriptionApiKey_Loading_Started: {
      return emptyApiKey;
    }

    default:
      return state;
  }
};
