import { areShallowEqual } from '@kontent-ai/utils';
import classNames from 'classnames';
import escapeHtml from 'escape-html';
import React, { ClipboardEvent, EventHandler, FocusEvent, KeyboardEvent } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

const propsToSkipShallowEquality: ReadonlyArray<keyof IEditableSpanProps> = ['value'];

interface IEditableSpanProps {
  readonly className?: string;
  readonly isDisabled?: boolean;
  readonly onBlur?: (event: FocusEvent<HTMLSpanElement>) => void;
  readonly onChange: (newValue: string) => void;
  readonly onFocus?: (event: FocusEvent<HTMLSpanElement>) => void;
  readonly onKeyDown?: EventHandler<KeyboardEvent<HTMLSpanElement>>;
  readonly onKeyPress?: EventHandler<KeyboardEvent<HTMLSpanElement>>;
  readonly onKeyUp?: EventHandler<KeyboardEvent<HTMLSpanElement>>;
  readonly placeholder?: string;
  readonly uiElement?: DataUiElement;
  readonly value: string;
}

interface IEditableSpanState {
  readonly value: string;
  readonly hasFocus: boolean;
}

type DangerousHtml = {
  readonly __html: string;
};

export class EditableSpan extends React.Component<IEditableSpanProps, IEditableSpanState> {
  static displayName = 'EditableSpan';

  private editableSpan: HTMLSpanElement | null = null;

  static getDerivedStateFromProps(
    nextProps: IEditableSpanProps,
    prevState: IEditableSpanState,
  ): Partial<IEditableSpanState> | null {
    const escapedNextValue = escapeHtml(nextProps.value);
    if (escapedNextValue !== prevState.value) {
      return {
        ...prevState,
        value: escapedNextValue,
      };
    }

    return null;
  }

  constructor(props: IEditableSpanProps) {
    super(props);

    this.state = {
      value: escapeHtml(props.value),
      hasFocus: false,
    };
  }

  shouldComponentUpdate(nextProps: IEditableSpanProps, nextState: IEditableSpanState): boolean {
    const contentChanged =
      (this.editableSpan ? this.editableSpan.innerHTML : '') !== nextState.value;
    const shallowEqual = areShallowEqual(nextProps, this.props, propsToSkipShallowEquality);
    return contentChanged || !shallowEqual;
  }

  componentDidUpdate() {
    // For some reason React does not update the DOM properly sometimes (it happens when empty string should be rendered) - CMP-561
    this._ensureConsistency();
  }

  public focus = (): void => {
    if (this.editableSpan) {
      this.editableSpan.focus();
    }
  };

  private readonly _ensureConsistency = (): void => {
    if (this.editableSpan && this.editableSpan.innerHTML !== this.state.value) {
      this.editableSpan.innerHTML = this.state.value;

      // We need to fix the position of cursor since it was not preserved after changing innerHTML directly
      if (this.state.hasFocus) {
        this._setFocus();
      }
    }
  };

  private readonly _onInput = (): void => {
    if (this.editableSpan && this.state.value !== this.editableSpan.innerHTML) {
      const textChanged = this.props.value !== this.editableSpan.innerText;
      if (textChanged) {
        this._updateValue();
      } else {
        this._ensureConsistency();
      }
    }
  };

  private readonly _updateValue = (): void => {
    const newValue = this.editableSpan ? this.editableSpan.innerText : '';
    this.props.onChange(newValue);
  };

  private readonly _onFocus = (event: FocusEvent<HTMLSpanElement>): void => {
    this._setFocus();
    this.setState(() => ({ hasFocus: true }));
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  private readonly _setFocus = (): void => {
    if (this.editableSpan) {
      const lastTextFragment = this.editableSpan.lastChild;
      if (lastTextFragment?.nodeValue) {
        const range = document.createRange();
        range.setStart(lastTextFragment, lastTextFragment.nodeValue.length);
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  };

  private readonly _onBlur = (event: FocusEvent<HTMLSpanElement>): void => {
    window.getSelection()?.removeAllRanges();
    this.setState(() => ({ hasFocus: false }));
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  private readonly _onPaste = (event: ClipboardEvent<HTMLSpanElement>): void => {
    const textType = 'text/plain';
    event.preventDefault();
    const text = event.clipboardData.getData(textType);
    const sanitizedText = text.split('\n').join('');
    document.execCommand('insertText', false, sanitizedText);
  };

  private readonly _getInnerHTML = (): DangerousHtml | undefined => {
    const { value } = this.state;
    return !this.editableSpan || value !== this.editableSpan.innerHTML
      ? { __html: value }
      : undefined;
  };

  render(): JSX.Element {
    return (
      <span
        className={classNames('notranslate', this.props.className)}
        ref={(c) => {
          this.editableSpan = c;
        }}
        contentEditable={!this.props.isDisabled}
        onInput={this._onInput}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        onPaste={this._onPaste}
        onKeyPress={this.props.onKeyPress}
        onKeyDown={this.props.onKeyDown}
        onKeyUp={this.props.onKeyUp}
        dangerouslySetInnerHTML={this._getInnerHTML()}
        data-placeholder={this.props.placeholder}
        {...(this.props.uiElement ? getDataUiElementAttribute(this.props.uiElement) : {})}
      />
    );
  }
}
