import { Card } from '@kontent-ai/component-library/Card';
import { PaperLevel } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { RectangleSkeleton } from '@kontent-ai/component-library/Skeleton';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ILanguage } from '../../../data/models/languages/Language.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../data/models/listingContentItems/IListingVariantData.ts';
import { WorkflowStatusTagForVariant } from '../../containers/Workflow/WorkflowStatusTagForVariant.tsx';
import {
  DataUiElement,
  Popovers,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { getFallbackLanguageIdForItem } from '../../utils/languageUtils.ts';
import { NotTranslatedTagForVariant } from '../Workflow/NotTranslatedTagForVariant.tsx';
import { FallbacksPopoverLanguageLabel } from './FallbacksPopoverLanguageLabel.tsx';
import { LinkedContentFallbackSummary } from './LinkedContentFallbackSummary.tsx';

type Props = {
  readonly activeLanguages: ReadonlyArray<ILanguage>;
  readonly translatedVariants: ReadonlyArray<IListingVariantData> | null;
  readonly selectedLinkedItem: IListingContentItem;
  readonly selectedLanguageId: Uuid;
  readonly onClose: () => void;
};

export const FallbacksPopoverContent: React.FC<Props> = ({
  activeLanguages,
  translatedVariants,
  selectedLinkedItem,
  selectedLanguageId,
  onClose,
}) => {
  const fallbackLanguageId =
    translatedVariants &&
    getFallbackLanguageIdForItem(translatedVariants, activeLanguages, selectedLanguageId);
  const isSelectedLanguageTranslated =
    translatedVariants?.some((v) => v.id.variantId === selectedLanguageId) ?? false;

  return (
    <Card
      component="section"
      cardLabel="Available localizations"
      level={PaperLevel.Popout}
      css={{
        width: px(48 * gridUnit),
        maxHeight: '60vh',
      }}
      {...getDataUiObjectNameAttribute(Popovers.FallbacksPopover)}
    >
      <Card.CloseButton onClose={onClose} />
      <Card.Headline>Available localizations</Card.Headline>
      <Card.Body overflowMode="auto">
        <Stack spacing={Spacing.XXL}>
          <LinkedContentFallbackSummary
            activeLanguages={activeLanguages}
            translatedVariants={translatedVariants}
            fallbackLanguageId={fallbackLanguageId}
            selectedLanguageId={selectedLanguageId}
            itemName={selectedLinkedItem.item.name}
            isSelectedLanguageTranslated={isSelectedLanguageTranslated}
          />
          <Stack spacing={Spacing.L}>
            {translatedVariants === null
              ? renderVariantLanguageRowSkeletons(
                  selectedLinkedItem.translatedLanguageIds.length + 1,
                )
              : activeLanguages.map((language, key) => {
                  const variant = translatedVariants.find(
                    (item) => item?.id.variantId === language.id,
                  );

                  if (
                    (!variant && language.id !== selectedLanguageId) ||
                    !translatedVariants.length
                  ) {
                    return null;
                  }

                  return (
                    <VariantLanguageRow
                      key={key}
                      renderLanguage={() => (
                        <FallbacksPopoverLanguageLabel
                          languageName={language.name}
                          isCurrentLanguage={language.id === selectedLanguageId}
                          isFallbackLanguage={
                            language.id === fallbackLanguageId && !isSelectedLanguageTranslated
                          }
                        />
                      )}
                      renderWorkflowStatus={() =>
                        variant && !variant.isArchived && variant.assignment.workflowStatus ? (
                          <WorkflowStatusTagForVariant
                            publishingState={variant.publishingState}
                            scheduledToPublishAt={variant.assignment.scheduledToPublishAt}
                            scheduledToUnpublishAt={variant.assignment.scheduledToUnpublishAt}
                            workflowStatus={variant.assignment.workflowStatus}
                          />
                        ) : (
                          <NotTranslatedTagForVariant />
                        )
                      }
                    />
                  );
                })}
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};

FallbacksPopoverContent.displayName = 'FallbacksPopoverContent';

const VariantLanguageRow: React.FC<{
  readonly renderLanguage: () => React.ReactElement;
  readonly renderWorkflowStatus: () => React.ReactElement;
}> = ({ renderLanguage, renderWorkflowStatus }) => (
  <Row
    alignY="center"
    spacingX={Spacing.S}
    noWrap
    {...getDataUiElementAttribute(DataUiElement.VariantLanguageRow)}
  >
    <Column flexBasis="66%" flexFactor={1}>
      {renderLanguage()}
    </Column>
    <Column width="fit-content">{renderWorkflowStatus()}</Column>
  </Row>
);

VariantLanguageRow.displayName = 'VariantLanguageRow';

const renderVariantLanguageRowSkeletons = (skeletonRowCount: number) =>
  Array.from({ length: skeletonRowCount }, (_, i) => (
    <VariantLanguageRow
      key={i}
      renderLanguage={() => (
        <RectangleSkeleton width={['60%', '40%', '50%'].at(i % 3) || ''} height={14} />
      )}
      renderWorkflowStatus={() => <RectangleSkeleton width={px(10 * gridUnit)} height={24} />}
    />
  ));
