import React from 'react';

export enum WidgetRequirement {
  userIsProjectManager = 'userIsProjectManager',
}

export type WidgetDescriptor = Readonly<{
  uniqueKey: string;
  liveComponent: React.FC | null;
  demoComponent: React.FC | null;
  requirements: ReadonlyArray<WidgetRequirement>;
}>;

export type DashboardWidget = Readonly<{
  uniqueKey: string;
  component: React.FC;
}>;
