import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_PromoteAdmin_Canceled,
  Subscription_PromoteAdmin_Failed,
  Subscription_PromoteAdmin_Finished,
} from '../constants/actionTypes.ts';

export const promoteUsersErrorMessage = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Subscription_PromoteAdmin_Canceled:
    case Subscription_PromoteAdmin_Finished:
      return '';

    case Subscription_PromoteAdmin_Failed:
      return action.payload.errorMessage;

    default:
      return state;
  }
};
