import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import React from 'react';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { selectorHeaders } from '../constants/uiConstants.ts';

type Props = {
  readonly index: number | undefined;
  readonly type: SelectorType;
};

export const SelectorHeader: React.FC<Props> = ({ index, type }) => {
  const title = getSelectorHeaderTitle(index, type);

  return (
    <Label hasEmphasis size={LabelSize.L}>
      {title}
    </Label>
  );
};

SelectorHeader.displayName = 'SelectorHeader';

const getSelectorHeaderTitle = (index: number | undefined, type: SelectorType): string => {
  const correctedIndex = index ? `${index}. ` : '';

  return `${correctedIndex}${selectorHeaders[type]}`;
};
