import { BaseColor, colorOverlayBackground } from '@kontent-ai/component-library/tokens';
import { rgba } from '@kontent-ai/component-library/utils';
import { animated, useTransition } from '@react-spring/web';
import { ReactNode } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { SquareButton } from '../../uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { HotkeysHandler, HotkeysMode } from '../Hotkeys/HotkeysHandler.tsx';
import { Modal } from '../Modal/Modal.tsx';

type Props = {
  readonly children: ReactNode;
  readonly dataUiAttribute?: ObjectWithDataAttribute;
  readonly isVisible: boolean;
  readonly onClose?: () => void;
  readonly onSubmit?: () => void;
  readonly submitElement?: ReactNode;
  readonly titleElement: ReactNode;
};

const transparentOverlayColor = rgba(BaseColor.OceanBlue70, 0);

export const Sidebar = (props: Props) => {
  const underlayTransitions = useTransition(props.isVisible, {
    from: {
      background: transparentOverlayColor,
    },
    enter: {
      background: colorOverlayBackground,
    },
    leave: {
      background: transparentOverlayColor,
    },
  });

  const sidebarTransitions = useTransition(props.isVisible, {
    from: {
      transform: 'translateX(100%)',
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: 'translateX(100%)',
    },
  });

  const close = (): void => {
    props.onClose?.();
  };

  // Prevent default is needed to stop the propagation of Enter to a confirmation dialog. Otherwise, the dialog is closed immediately after opening, because it has a Cancel button focused.
  const submit = (event: KeyboardEvent): void => {
    if (props.onSubmit) {
      event.preventDefault();
      props.onSubmit();
    }
  };

  const closeButton = (
    <SquareButton
      className="sidebar__action-close"
      disabled={!props.onClose}
      iconName={IconName.Times}
      size={SquareButtonSize.Quinary}
      style={SquareButtonStyle.Quinary}
      onClick={props.onClose}
      tooltipText="Close"
      tooltipPlacement="left"
      tooltipShortcuts="Esc"
      {...getDataUiActionAttribute(DataUiAction.CloseSidebar)}
    />
  );

  const animatedSidebarPane = sidebarTransitions(
    (style, item) =>
      item && (
        <animated.div className="sidebar__pane" style={style}>
          <div
            className="sidebar__content"
            {...getDataUiElementAttribute(DataUiElement.SidebarContent)}
          >
            <div>
              <div className="sidebar__header">
                <div className="sidebar__title">{props.titleElement}</div>
                {closeButton}
              </div>
              {props.children}
            </div>
          </div>
          {props.submitElement && (
            <div
              className="sidebar__submit"
              {...getDataUiElementAttribute(DataUiElement.SidebarSubmit)}
            >
              {props.submitElement}
            </div>
          )}
        </animated.div>
      ),
  );

  return (
    <Modal
      onClose={close}
      underlayClass="mask-modal"
      underlayDataAttribute={DataUiElement.MaskModal}
      underlayTransitions={underlayTransitions}
    >
      <HotkeysHandler
        mode={HotkeysMode.Dual}
        handlers={{
          onEnter: submit,
        }}
      >
        <div className="sidebar" {...props.dataUiAttribute}>
          {animatedSidebarPane}
        </div>
      </HotkeysHandler>
    </Modal>
  );
};
