import { useLayoutEffect } from 'react';
import { boot, shutdown } from '../utils/gtmUtils.ts';

export const GoogleTagManager = () => {
  useLayoutEffect(() => {
    boot();

    return () => {
      shutdown();
    };
  });

  return <noscript />;
};
