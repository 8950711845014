import { delay } from '@kontent-ai/utils';
import { loadWebSpotlightConfiguration } from '../../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  getContentTypeCreatedEventData,
  getContentTypeDuplicatedEventData,
  getContentTypeModifiedEventData,
} from '../../../../_shared/utils/trackUserEventDataUtils.ts';
import {
  loadAssetType,
  loadCollections,
  loadContentTypes,
  loadContentTypesUsage,
  loadSnippets,
  loadTaxonomyGroups,
  loadWorkflows,
} from '../../../../data/actions/thunkDataActions.ts';
import { loadAiGuidelines } from '../../../../paperModels/aiReview/data/actions/thunkAiReviewDataActions.ts';
import { typeElementUpdated } from '../../shared/actions/sharedContentModelsActions.ts';
import {
  loadContentTypeReferencesForTypeEditing,
  removeTypeElementWithDependencies,
  validateTypeElement,
} from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { createSaveEditedMultipleChoiceOptionAction } from '../../shared/actions/thunks/saveEditedMultipleChoiceOption.ts';
import { trackAssetLimitConfigurationChanged } from '../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { trackCustomTypeElementUpserted } from '../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { convertTypeElementsToServerModel } from '../../shared/utils/typeElementDataConverter.ts';
import {
  contentGroupDeleted,
  deleteMultipleChoiceOption,
  multipleChoiceOptionDeselected,
  selectedContentGroupChanged,
  updateMultipleChoiceOption,
} from './contentTypesActions.ts';
import { createArchiveContentTypesAction } from './thunks/archiveContentTypes.ts';
import { createCreateContentTypeAction } from './thunks/createContentType.ts';
import { createDeleteContentGroupAction } from './thunks/deleteContentGroupWithElements.ts';
import { createDuplicateContentTypeAction } from './thunks/duplicateContentType.ts';
import { createInitContentTypeCreatorAction } from './thunks/initContentTypeCreator.ts';
import { createInitContentTypeEditorAction } from './thunks/initContentTypeEditor.ts';
import { createInitContentTypeListingAction } from './thunks/initContentTypeListing.ts';
import { createLoadContentTypesDataAction } from './thunks/loadContentTypesData.ts';
import { createSaveContentTypeAction } from './thunks/saveContentType.ts';
import { createSelectContentTypesAction } from './thunks/selectContentTypes.ts';
import { createUndoContentTypeListingOperationAction } from './thunks/undoContentTypeListingOperation.ts';
import { createUnselectContentTypesAction } from './thunks/unselectContentTypes.ts';

const { contentItemRepository, contentTypeRepository } = repositoryCollection;

export const loadContentTypesData = createLoadContentTypesDataAction({
  loadContentTypes,
  loadContentTypesUsage,
});

export const initContentTypeListing = createInitContentTypeListingAction({
  loadContentTypesData,
  loadWebSpotlightConfiguration,
});

export const archiveContentTypes = createArchiveContentTypesAction({
  contentTypeRepository,
  loadContentTypesData,
  trackUserEvent: trackUserEventWithData,
});

export const undoContentTypeListingOperation = createUndoContentTypeListingOperationAction({
  contentTypeRepository,
  loadContentTypesData,
  delay,
});

export const initContentTypeCreator = createInitContentTypeCreatorAction({
  loadAssetType,
  loadCollections,
  loadContentTypes,
  loadSnippets,
  loadTaxonomyGroups,
});

const saveEditedMultipleChoiceOption = createSaveEditedMultipleChoiceOptionAction({
  deleteOption: deleteMultipleChoiceOption,
  deselectOption: multipleChoiceOptionDeselected,
  getEditedMultipleChoiceElement: (state) =>
    state.contentModelsApp.contentTypes.editor.editedMultipleChoiceElement,
  getTypeElements: (state) => state.contentModelsApp.typeEditor.editedType.typeElements,
  updateOption: updateMultipleChoiceOption,
});

export const createContentType = createCreateContentTypeAction({
  contentTypeRepository,
  convertTypeElementsToServerModel,
  getContentTypeCreatedEventData,
  loadContentTypesData,
  saveEditedMultipleChoiceOption,
  trackAssetLimitConfigurationChanged,
  trackCustomTypeElementUpserted,
  trackUserEventWithData,
  validateTypeElement,
});

export const saveEditedContentType = createSaveContentTypeAction({
  contentTypeRepository,
  saveEditedMultipleChoiceOption,
  getContentTypeModifiedEventData,
  loadContentTypesData,
  trackAssetLimitConfigurationChanged,
  trackCustomTypeElementUpserted,
  trackUserEventWithData,
  validateTypeElement,
});

export const initContentTypeEditor = createInitContentTypeEditorAction({
  contentItemRepository,
  contentTypeRepository,
  loadAiGuidelines,
  loadAssetType,
  loadCollections,
  loadContentTypeReferencesForTypeEditing,
  loadContentTypes,
  loadContentTypesUsage,
  loadSnippets,
  loadTaxonomyGroups,
  loadWorkflows,
  validateTypeElement,
});

export const selectContentTypes = createSelectContentTypesAction();

export const unselectContentTypes = createUnselectContentTypesAction();

export const deleteContentGroup = createDeleteContentGroupAction({
  contentGroupDeleted,
  removeTypeElementWithDependencies,
  selectedContentGroupChanged,
  typeElementUpdated,
});

export const duplicateContentType = createDuplicateContentTypeAction({
  contentTypeRepository,
  getContentTypeDuplicatedEventData,
  trackUserEventWithData,
});
