import { memoize } from '@kontent-ai/memoization';
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  DraftInlineStyle,
  Modifier,
  SelectionState,
} from 'draft-js';
import Immutable from 'immutable';
import {
  createContent,
  isAtBlockEdge,
  setCharacterList,
} from '../../../utils/blocks/editorBlockUtils.ts';
import {
  getMetadataAtSelection,
  isSelectionWithinOneBlock,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';
import {
  aggregateAllChars,
  getMetadataForBlockChars,
} from '../../../utils/metadata/editorMetadataUtils.ts';
import { isAiIdStyle } from '../../ai/utils/editorAiStyleUtils.ts';
import { getCommentStyle, isCommentIdStyle } from '../../comments/api/editorCommentStyleUtils.ts';
import {
  DraftJSInlineStyle,
  MutuallyExclusiveStyles,
  isMutuallyExclusiveStyleKey,
} from './inlineStyles.ts';

export type FilterStyleRangesCallback = (char: CharacterMetadata) => boolean;

export function modifyStyle(
  input: IContentChangeInput,
  filterCallback: FilterStyleRangesCallback,
  stylesToRemove: Array<string>,
  stylesToAdd: Array<string>,
): IContentChangeResult {
  const content = input.content;

  const blocks = getBlocks(content);
  let changed = false;

  const newBlocks = blocks.map((block: ContentBlock) => {
    const chars = block.getCharacterList();
    let newChars = chars;

    block.findStyleRanges(filterCallback, (start, end) => {
      if (stylesToRemove) {
        stylesToRemove.forEach((style) => {
          newChars = modifyStyleInChars(newChars, style, start, end, false);
        });
      }
      if (stylesToAdd) {
        stylesToAdd.forEach((style) => {
          newChars = modifyStyleInChars(newChars, style, start, end, true);
        });
      }
    });

    if (newChars !== chars) {
      changed = true;
      return setCharacterList(block, newChars);
    }
    return block;
  });

  if (changed) {
    const newContent = createContent(newBlocks);
    const newContentWithSelection = setContentSelection(
      newContent,
      content.getSelectionBefore(),
      input.selection,
    );

    return {
      wasModified: true,
      content: newContentWithSelection,
      selection: input.selection,
    };
  }

  return input;
}

function modifyStyleInChars(
  chars: Immutable.List<CharacterMetadata>,
  style: string,
  sliceStart: number,
  sliceEnd: number,
  add: boolean,
): Immutable.List<CharacterMetadata> {
  return chars
    .map((char: CharacterMetadata, index: number) => {
      if (index < sliceStart || index >= sliceEnd) {
        return char;
      }
      if (add && !char.hasStyle(style)) {
        return CharacterMetadata.applyStyle(char, style);
      }
      if (!add && char.hasStyle(style)) {
        return CharacterMetadata.removeStyle(char, style);
      }
      return char;
    })
    .toList();
}

const removeExclusiveStyles = (
  input: IContentChangeInput,
  inlineStyle: DraftJSInlineStyle,
): IContentChangeResult => {
  if (isMutuallyExclusiveStyleKey(inlineStyle)) {
    const excludedStyles = MutuallyExclusiveStyles[
      inlineStyle
    ] as ReadonlyArray<DraftJSInlineStyle>;
    const changeResult = excludedStyles.reduce(
      (withRemovedStyles: IContentChangeResult, styleToRemove: DraftJSInlineStyle) => {
        return removeInlineStyleForSelectedChars(withRemovedStyles, styleToRemove);
      },
      input,
    );
    return changeResult;
  }

  return input;
};

export function removeInlineStyleForSelectedChars(
  input: IContentChangeInput,
  inlineStyle: DraftJSInlineStyle,
): IContentChangeResult {
  const { selection, content } = input;
  const newContent = Modifier.removeInlineStyle(content, selection, inlineStyle);

  return {
    content: newContent,
    selection: newContent.getSelectionAfter(),
    wasModified: newContent !== content,
  };
}

export const applyInlineStyleForSelectedChars = (
  input: IContentChangeInput,
  inlineStyle: DraftJSInlineStyle,
): IContentChangeResult => {
  const { content: preparedContent, selection: preparedSelection } = removeExclusiveStyles(
    input,
    inlineStyle,
  );

  const newContent = Modifier.applyInlineStyle(preparedContent, preparedSelection, inlineStyle);

  return {
    content: newContent,
    selection: newContent.getSelectionAfter(),
    wasModified: newContent !== input.content,
  };
};

type StylePredicate = (style: string) => boolean;

export const isVisualStyle: StylePredicate = (style) =>
  !isCommentIdStyle(style) && !isAiIdStyle(style);

export const getStyles = memoize.weak(
  (styles: DraftInlineStyle, ...allowedStyles: StylePredicate[]): DraftInlineStyle => {
    return styles
      .filter((style) => allowedStyles.some((predicate) => predicate(style)))
      .toOrderedSet();
  },
);

export const NoStyle: DraftInlineStyle = Immutable.OrderedSet();

export function getVisualStyleForNewContent(
  content: ContentState,
  selection: SelectionState,
  inlineStyleOverride?: DraftInlineStyle,
): DraftInlineStyle {
  if (selection.isCollapsed() && inlineStyleOverride) {
    return getStyles(inlineStyleOverride, isVisualStyle);
  }

  const metadataAtSelection = getMetadataAtSelection(content, selection);
  if (!metadataAtSelection) {
    return NoStyle;
  }

  const styles = aggregateAllChars(
    metadataAtSelection.styleAtAllTopLevelChars,
    metadataAtSelection.styleAtAllTableChars,
  );
  if (!styles) {
    return NoStyle;
  }

  return getStyles(styles, isVisualStyle);
}

export function getCommentStyleForNewContent(
  content: ContentState,
  selection: SelectionState,
): DraftInlineStyle {
  if (!isSelectionWithinOneBlock(selection)) {
    return NoStyle;
  }

  const block = content.getBlockForKey(selection.getStartKey());
  if (!block) {
    return NoStyle;
  }

  const start = selection.getStartOffset();
  const end = selection.getEndOffset();

  if (start < end) {
    const metadata = getMetadataForBlockChars(block, start, end);
    if (!metadata) {
      return NoStyle;
    }

    const styles = metadata.styleAtAllTopLevelChars
      ? metadata.styleAtAllTopLevelChars
      : metadata.styleAtAllTableChars;

    if (!styles) {
      return NoStyle;
    }

    return getCommentStyle(styles);
  }

  if (isAtBlockEdge(block, start)) {
    return NoStyle;
  }

  const metadataAround = getMetadataForBlockChars(block, start - 1, start + 1);
  if (!metadataAround) {
    return NoStyle;
  }

  const styles = metadataAround.styleAtAllTopLevelChars
    ? metadataAround.styleAtAllTopLevelChars
    : metadataAround.styleAtAllTableChars;

  if (!styles) {
    return NoStyle;
  }

  return getCommentStyle(styles);
}

export function findShortestStyle(
  styles: ReadonlyArray<string>,
  block: ContentBlock,
  startAtOffset: number,
): string | null {
  if (!styles.length) {
    return null;
  }
  if (styles.length === 1) {
    return styles[0] ?? null;
  }

  let minStyleLength = Number.MAX_VALUE;
  let shortestStyle: string | null = null;

  styles.forEach((style: string) => {
    let length = 0;

    let index = startAtOffset - 1;
    while (block.getInlineStyleAt(index).has(style)) {
      length++;
      index--;
    }
    index = startAtOffset;
    while (block.getInlineStyleAt(index).has(style)) {
      length++;
      index++;
    }

    if (length < minStyleLength) {
      minStyleLength = length;
      shortestStyle = style;
    }
  });
  return shortestStyle;
}
