import { notNullNorUndefined } from '@kontent-ai/utils';
import React from 'react';
import { PortalPlaceholder } from '../../../../../../../_shared/components/PortalPlaceholder.tsx';

export const getAiTranslateButtonPlaceholderId = (
  elementId: Uuid,
  contentComponentId?: Uuid | null,
): string =>
  `ai_translation_${[contentComponentId, elementId].filter(notNullNorUndefined).join('_')}`;

export const AiTranslateButtonPlaceholder: React.FC<{
  readonly contentComponentId?: Uuid | null;
  readonly elementId: Uuid;
}> = ({ contentComponentId, elementId }) => (
  <PortalPlaceholder id={getAiTranslateButtonPlaceholderId(elementId, contentComponentId)} />
);
