import Immutable from 'immutable';
import { IRoleServerModel } from '../../../repositories/serverModels/IRoleServerModel.type.ts';
import { IRole, getRoleFromServerModel } from './IRole.ts';
import { IRoleSettings, getRoleSettingsFromServerModel } from './IRoleSettings.ts';

export interface IRoleWithSettings extends IRole {
  readonly settings: IRoleSettings;
}

export function getRoleWithSettingsFromServerModel(
  serverModel: IRoleServerModel,
): IRoleWithSettings {
  return {
    ...getRoleFromServerModel(serverModel),
    settings: getRoleSettingsFromServerModel(serverModel.settings),
  };
}

export const defaultRoleWithSettings: IRoleWithSettings = {
  id: '',
  name: '',
  codename: null,
  isReadonly: false,
  capabilities: Immutable.Set(),
  settings: {
    canRules: [],
    cannotRules: [],
  },
};
