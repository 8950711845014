import { Callout } from '@kontent-ai/component-library/Callout';

type Props = {
  readonly featureName: string;
};

export const InnovationLabGenericActivationCallout = ({ featureName }: Props) => (
  <Callout calloutType="quickTip" hideSubheadline>
    <p>
      <b>Innovation Lab feature activation</b>
    </p>
    <p>
      You’re about to activate {featureName}. Be aware that the feature is part of the innovation
      lab and we’re still working on it to make it better. That means you may encounter occasional
      outages or longer response times. Our product team is happy to accept your feedback and
      provide guidance on using the feature.
    </p>
  </Callout>
);
