import { LoadingStatus } from '../models/LoadingStatusEnum.ts';

export interface IScrollState {
  readonly scrollHeightPx: number;
  readonly availableHeightPx: number;
  readonly scrollPositionPx: number;
}

export interface IPaging {
  readonly skip: number;
  readonly limit: number;
}

export const areScrollStatsTheSame = (a: IScrollState | null, b: IScrollState | null): boolean =>
  (!a && !b) ||
  (!!a &&
    !!b &&
    a.scrollPositionPx === b.scrollPositionPx &&
    a.availableHeightPx === b.availableHeightPx);

/**
 * Determines whether it is necessary to load fresh items based on stats from previous request
 */
export const shouldRequestNewItems = (
  loadingStatus: LoadingStatus,
  continuationToken: string | null,
  gridState: IScrollState,
): boolean => {
  // no previous request
  if (loadingStatus === LoadingStatus.InitialEmpty) {
    return true;
  }

  // all items available are already loaded
  if (!continuationToken) {
    return false;
  }

  const currentEnd = gridState.scrollPositionPx + gridState.availableHeightPx;
  const totalEnd = gridState.scrollHeightPx;

  // load the next page when there is only 50% of the viewport remaining
  return currentEnd >= totalEnd - gridState.availableHeightPx / 2;
};

export enum AssetsRequestTrigger {
  UserScroll = 'UserScroll',
  Search = 'Search',
  FetchAfterBulkAction = 'FetchAfterBulkAction',
  Ordering = 'Ordering',
}

const triggersPreservingScrollPosition: ReadonlyArray<AssetsRequestTrigger> = [
  AssetsRequestTrigger.UserScroll,
  AssetsRequestTrigger.FetchAfterBulkAction,
];

export const shouldPreserveScrollPosition = (requestTrigger: AssetsRequestTrigger): boolean =>
  triggersPreservingScrollPosition.includes(requestTrigger);
