import {
  colorAlertIcon,
  colorIconDisabled,
  colorInfoIcon,
  colorSuccessIcon,
  colorWarningIcon,
} from '@kontent-ai/component-library/tokens';
import { ContentProgressWidgetData } from '../../../unifiedMissionControl/types/ContentProgressWidget.type.ts';
import { IHorizontalStackedBarData } from '../../types/IHorizontalStackedBarData.type.ts';

export const getContentProgressChartData = (
  projectStatus: ContentProgressWidgetData | null,
): ReadonlyArray<IHorizontalStackedBarData> => [
  {
    id: 'without-due',
    value: projectStatus?.withoutDue ?? 0,
    color: colorIconDisabled,
    label: 'Without due date',
  },
  {
    id: 'on-track',
    value: projectStatus?.onTrack ?? 0,
    color: colorSuccessIcon,
    label: 'On track',
  },
  {
    id: 'scheduled',
    value: projectStatus?.scheduled ?? 0,
    color: colorInfoIcon,
    label: 'Scheduled',
  },
  {
    id: 'close-to-due',
    value: projectStatus?.nearDue ?? 0,
    color: colorWarningIcon,
    label: 'Close to due date',
  },
  {
    id: 'delayed',
    value: projectStatus?.overDue ?? 0,
    color: colorAlertIcon,
    label: 'Delayed',
  },
];
