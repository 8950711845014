import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { getConsistentSelectedTermIds } from '../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTypeElement } from '../../../contentInventory/content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { ITaxonomyItemElement } from '../../models/contentItemElements/TaxonomyItemElement.ts';
import { ItemElementFriendlyWarningResult } from './types/FriendlyWarnings.ts';
import { getBrokenTaxonomyWithDetails } from './utils/contentLinkUtils.ts';

interface IParams {
  readonly itemElement: ITaxonomyItemElement;
  readonly typeElement: ITaxonomyTypeElement;
  readonly taxonomyGroups: Immutable.Map<Uuid, ITaxonomyGroup>;
}

export const getTaxonomyElementValidationFriendlyWarning = memoize.weak(
  ({ itemElement, typeElement, taxonomyGroups }: IParams): ItemElementFriendlyWarningResult => {
    const taxonomyGroup = taxonomyGroups.get(typeElement?.taxonomyGroupId ?? '') ?? null;
    const selectedTermsIds =
      taxonomyGroup &&
      getConsistentSelectedTermIds(itemElement.groupId, itemElement.value, taxonomyGroup);

    const warningMessages = getBrokenTaxonomyWithDetails(taxonomyGroup, selectedTermsIds);

    return {
      warningMessages,
    };
  },
);
