import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

const selectWrapperMinWidth = px(12 * gridUnit);

interface ISelectWrapper {
  readonly allowShrink?: boolean;
}

export const SelectWrapper = styled.div<ISelectWrapper>`
  display: flex;
  align-items: center;
  ${({ allowShrink }) => (allowShrink ? `min-width: ${selectWrapperMinWidth}` : undefined)};
`;
