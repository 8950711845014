import { ISelectItem, ItemId } from '@kontent-ai/component-library/Selects';

export interface IWebSpotlightPreviewScaleOption
  extends ISelectItem<IWebSpotlightPreviewScaleOption> {
  readonly hidden: boolean;
}

export const webSpotlightPreviewScaleOptions: ReadonlyArray<IWebSpotlightPreviewScaleOption> = [
  { id: '50', label: '50%', hidden: false },
  { id: '75', label: '75%', hidden: false },
  { id: '100', label: '100%', hidden: false },
  { id: '125', label: '125%', hidden: false },
  { id: '150', label: '150%', hidden: false },
  { id: '200', label: '200%', hidden: false },
];

export const getWebSpotlightPreviewScaleOptions = (
  selectedScale: number,
): [ItemId, ReadonlyArray<IWebSpotlightPreviewScaleOption>] => {
  const selectedScaleId = scaleValueToId(selectedScale);
  const predefinedOption = webSpotlightPreviewScaleOptions.find(
    (opt) => opt.id === selectedScaleId,
  );

  if (predefinedOption) {
    return [predefinedOption.id, webSpotlightPreviewScaleOptions];
  }

  const extendedOptions: ReadonlyArray<IWebSpotlightPreviewScaleOption> = [
    ...webSpotlightPreviewScaleOptions,
    {
      id: selectedScaleId,
      label: `${selectedScaleId}%`,
      hidden: true,
    },
  ];

  return [selectedScaleId, extendedOptions];
};

const scaleValueToId = (scale: number): ItemId => (scale * 100).toFixed(0);

export const scaleIdToValue = (scaleId: ItemId): number => Number.parseInt(scaleId, 10) / 100;
