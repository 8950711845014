export const WorkflowStepColor = {
  Gray: 'gray',
  Red: 'red',
  Rose: 'rose',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  DarkBlue: 'dark-blue',
  LightBlue: 'light-blue',
  SkyBlue: 'sky-blue',
  MintGreen: 'mint-green',
  PersianGreen: 'persian-green',
  DarkGreen: 'dark-green',
  LightGreen: 'light-green',
  Yellow: 'yellow',
  Pink: 'pink',
  Orange: 'orange',
  Brown: 'brown',
} as const;
export type WorkflowStepColor = (typeof WorkflowStepColor)[keyof typeof WorkflowStepColor];
