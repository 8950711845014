import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React, { useContext } from 'react';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { LinkLike } from '../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { DataUiAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ListingCategory } from '../models/ProjectOverviewListingCategory.ts';
import { ProjectOverviewPageContext } from './ProjectOverviewPageContext.tsx';

type Props = {
  readonly itemsWithoutDueDateCount: number;
};

export const ItemsWithoutDueDateWarning: React.FC<Props> = ({ itemsWithoutDueDateCount }) => {
  const { changeCategory } = useContext(ProjectOverviewPageContext);

  return (
    <QuickTip>
      <p>
        <LinkLike
          onClick={() => changeCategory(ListingCategory.WithoutDueDate)}
          dataUiAction={DataUiAction.ShowItemsWithoutDueDate}
        >
          {itemsWithoutDueDateCount}{' '}
          {itemsWithoutDueDateCount === 1 ? 'content item' : 'content items'}
        </LinkLike>
        {itemsWithoutDueDateCount === 1 ? ' is ' : ' are '} missing due{' '}
        {itemsWithoutDueDateCount === 1 ? 'date' : 'dates'}.&nbsp;
        <OutwardLink href={documentationLinks.dueDates}>Setting a due date</OutwardLink>
        &nbsp;will show {itemsWithoutDueDateCount === 1 ? 'its' : 'their'} planning status.
      </p>
    </QuickTip>
  );
};
