import { FormValidatorConfig } from '../../../_shared/utils/validation/createFormValidationResolver.ts';
import { isAbsoluteUrlBuilder } from '../../../_shared/utils/validation/isAbsoluteUrl.ts';
import { nonEmptyValidationBuilder } from '../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { isNotLocalhostUrlBuilder } from '../../../_shared/utils/validation/isLocalhostUrl.ts';
import {
  IEntityWebhookFormShape,
  IEntityWebhookHeader,
  IEntityWebhookTriggers,
} from '../models/IEntityWebhookFormShape.type.ts';
import {
  areAllEntityTriggersEmpty,
  hasCheckedEntityTriggerWithNotSelectedOption,
  hasMissingContentItemCollectionFilter,
  hasMissingContentItemContentTypeFilter,
  hasMissingContentItemLanguageFilter,
  hasMissingContentTypeContentTypeFilter,
  hasMissingLanguageLanguageFilter,
  hasMissingTaxonomyTaxonomyFilter,
  hasMissingWorkflowSteps,
  hasNoCheckedEntityTrigger,
} from '../utils/entityTriggersChecks.ts';

export const localhostUrlErrorMessage = 'Localhost addresses cannot receive webhooks.';
export const tooManyHeadersErrorMessage = 'Too many headers.';
export const incorrectlyFormattedHeaderErrorMessage = 'Incorrectly formatted header key or value.';
export const reservedHeaderKeyErrorMessage = 'Incorrectly formatted header key or value.';

export const reservedHeaderKeys: string[] = [
  'host',
  'content-length',
  'x-kontent-ai-signature',
  'request-context',
  'request-id',
  'traceparent',
  'content-type',
];

const nameRequired = nonEmptyValidationBuilder('name', 'webhook');
const urlRequired = nonEmptyValidationBuilder('URL address', 'webhook');
const secretRequired = nonEmptyValidationBuilder('secret', 'webhook');

const webhookShouldHasAbsoluteUrlAddress = isAbsoluteUrlBuilder();
const webhookUrlMustNotHaveLocalhostDomain = isNotLocalhostUrlBuilder(localhostUrlErrorMessage);

const reservedKeyPrefix = 'x-kontent-ai';

const hasAnyTriggerAction = (triggers: IEntityWebhookTriggers): string | undefined =>
  areAllEntityTriggersEmpty(triggers) ? 'Required' : undefined;

const areValidHeaders = (headers: IEntityWebhookHeader[]): string | undefined => {
  if (headers.length > 10) {
    return tooManyHeadersErrorMessage;
  }

  const keyRegex = /^[a-zA-Z0-9_-]{1,200}$/;
  const valueRegex = /^[a-zA-Z0-9_\- :;.,\\/""'?!(){}[\]@<>=+*#$&`|~^%]{1,2000}$/;

  for (const header of headers) {
    if (
      header.key === null ||
      !keyRegex.test(header.key) ||
      header.value === null ||
      !valueRegex.test(header.value)
    ) {
      return incorrectlyFormattedHeaderErrorMessage;
    }

    if (reservedHeaderKeys.includes(header.key) || header.key.startsWith(reservedKeyPrefix)) {
      return reservedHeaderKeyErrorMessage;
    }
  }

  return undefined;
};

const hasCheckedTriggersOptionWithSelectedActions = (
  triggers: IEntityWebhookTriggers,
): string | undefined =>
  hasCheckedEntityTriggerWithNotSelectedOption(triggers) ? 'Required' : undefined;

const hasAnyCheckedTrigger = (triggers: IEntityWebhookTriggers): string | undefined =>
  hasNoCheckedEntityTrigger(triggers) ? 'Required' : undefined;

const hasSelectedFilters = (triggers: IEntityWebhookTriggers): string | undefined =>
  hasMissingContentItemCollectionFilter(triggers) ||
  hasMissingContentItemContentTypeFilter(triggers) ||
  hasMissingContentItemLanguageFilter(triggers) ||
  hasMissingContentTypeContentTypeFilter(triggers) ||
  hasMissingLanguageLanguageFilter(triggers) ||
  hasMissingTaxonomyTaxonomyFilter(triggers)
    ? 'Required'
    : undefined;
const hasSelectedWorkflowStep = (triggers: IEntityWebhookTriggers): string | undefined =>
  hasMissingWorkflowSteps(triggers) ? 'Required' : undefined;

export const entityWebhookItemEditorFormValidationConfig: FormValidatorConfig<IEntityWebhookFormShape> =
  {
    headers: [areValidHeaders],
    name: [nameRequired],
    secret: [secretRequired],
    url: [urlRequired, webhookShouldHasAbsoluteUrlAddress, webhookUrlMustNotHaveLocalhostDomain],
    triggers: [
      hasAnyTriggerAction,
      hasCheckedTriggersOptionWithSelectedActions,
      hasAnyCheckedTrigger,
      hasSelectedFilters,
      hasSelectedWorkflowStep,
    ],
  };
