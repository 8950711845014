import React from 'react';
import { toFuzzyDateDifference, toLocalTime } from '../../utils/dateTime/timeUtils.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

type Props = {
  readonly created: string | null;
  readonly isFileUploading: boolean;
};

export const AssetLastModified: React.FC<Props> = ({ created, isFileUploading }) => {
  if (!created || isFileUploading) {
    return null;
  }

  const localTime = toLocalTime(created);
  if (!localTime) {
    return null;
  }
  const lastModified = toFuzzyDateDifference(new Date(), localTime, false);

  return (
    <span className="asset-thumbnail__tech-detail">
      <IgnoreByGrammarly text={lastModified} />
    </span>
  );
};

AssetLastModified.displayName = 'AssetLastModified';
