import { MultipleChoiceMode } from '../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { IMultipleChoiceOptionData } from '../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  MultipleChoiceCompiledTypeElementServerModel,
  MultipleChoiceModeServerModel,
  MultipleChoiceOptionServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { ElementType } from '../ContentItemElementType.ts';
import {
  EmptyBaseTypeElement,
  IBaseTypeElement,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface IMultipleChoiceTypeElement extends IBaseTypeElement {
  readonly defaultValue: ReadonlyArray<Uuid>;
  readonly mode: MultipleChoiceMode;
  readonly options: Dictionary<IMultipleChoiceOptionData>;
  readonly optionsOrder: ReadonlyArray<Uuid>;
  readonly type: ElementType.MultipleChoice;
}

export const EmptyMultipleChoiceTypeElement: IMultipleChoiceTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  defaultValue: [],
  mode: MultipleChoiceMode.Single,
  options: {},
  optionsOrder: [],
  type: ElementType.MultipleChoice,
};

export function getMultipleChoiceTypeElementFromServerModel(
  serverModel: MultipleChoiceCompiledTypeElementServerModel,
): IMultipleChoiceTypeElement {
  const mode =
    serverModel.mode === MultipleChoiceModeServerModel.Single
      ? MultipleChoiceMode.Single
      : MultipleChoiceMode.Multiple;
  const options = serverModel.options.reduce<Mutable<Dictionary<IMultipleChoiceOptionData>>>(
    (reduced, serverItem) => {
      reduced[serverItem.id] = {
        codename: serverItem.codeName,
        id: serverItem.id,
        label: serverItem.name,
      };
      return reduced;
    },
    {},
  );
  const optionsOrder = serverModel.options.map(
    (option: MultipleChoiceOptionServerModel) => option.id,
  );

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyMultipleChoiceTypeElement._configurability,
    defaultValue: serverModel.default?.global.value ?? [],
    mode,
    options,
    optionsOrder,
    type: ElementType.MultipleChoice,
  };
}
