import { Dispatch, GetState, ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { ItemColumnCode } from '../../../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../../../../_shared/models/OrderBy.ts';
import {
  IListingOrderByStorage,
  ListingContext,
} from '../../../../../../../localStorages/listingOrderByStorage.ts';
import { contentListingOrderByUpdated } from '../contentItemInventoryActions.ts';

interface IDeps {
  readonly listingOrderByStorage: IListingOrderByStorage;
}

export type UpdateListingOrderBy = ReturnType<typeof createUpdateListingOrderByActionCreator>;

export const createUpdateListingOrderByActionCreator =
  (deps: IDeps) =>
  (orderBy: OrderBy<ItemColumnCode>, saveToLocalStorage: boolean): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    dispatch(contentListingOrderByUpdated(orderBy));

    if (!saveToLocalStorage) {
      return;
    }

    deps.listingOrderByStorage.save<OrderBy<ItemColumnCode>>(
      orderBy,
      ListingContext.ContentItemInventory,
      currentProjectId,
    );
  };
