import { AbortError, isAbortError } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { Dispatch, GetState, ThunkFunction } from '../../@types/Dispatcher.type.ts';
import { useDispatch } from './useDispatch.ts';

type Options = {
  readonly canRun: boolean;
};

const defaultOptions: Options = {
  canRun: true,
};

const isOptions = (input: any): input is AtLeastOnePropertyOf<Options> => {
  return (
    input instanceof Object &&
    Object.keys(input).length > 0 &&
    Object.keys(input).every((key) =>
      (Object.keys(defaultOptions) as ReadonlyArray<string>).includes(key),
    )
  );
};

enum Status {
  Pending = 'pending',
  Running = 'running',
  Done = 'done',
  Failed = 'failed',
  Aborted = 'aborted',
}

/**
 * Use this hook when you need to run a thunk function on-render. It dispatches the thunk function action right after the first render.
 * The start can be managed through useThunkFunction options.canRun property.
 * The hook takes care of the abortion of the previous run in cases the thunk itself, or its params change.
 * It is required to use because of React StrictMode that runs useEffect twice: https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-re-running-effects-in-development
 */
export const useThunkFunction = <
  TThunkFunction extends (dispatch: Dispatch<any>, getState: GetState<any>) => any = ThunkFunction,
  TParams extends ReadonlyArray<unknown> = never,
>(
  action: (...args: readonly [...TParams, AbortSignal]) => TThunkFunction,
  ...params: TParams extends []
    ? [options?: AtLeastOnePropertyOf<Options>]
    : [...TParams, options?: AtLeastOnePropertyOf<Options>]
): Readonly<[isDone: boolean, status: Status]> => {
  const [status, setStatus] = useState(Status.Pending);
  const dispatch = useDispatch();

  const lastParam = Collection.getLast(params);
  const { canRun } = isOptions(lastParam) ? lastParam : defaultOptions;
  const actionParams = isOptions(lastParam) ? params.toSpliced(params.length - 1) : params;

  // biome-ignore lint/correctness/useExhaustiveDependencies(actionParams): We need to spread it as actionParams array reference is new on every render.
  useEffect(() => {
    setStatus(Status.Pending);
    if (!canRun) return;

    setStatus(Status.Running);

    const abortController = new AbortController();
    try {
      dispatch(action(...(actionParams as any as TParams), abortController.signal));
      setStatus(Status.Done);
    } catch (error) {
      setStatus(() => (isAbortError(error) ? Status.Aborted : Status.Failed));
    }

    return () => {
      abortController.abort(
        new AbortError('Running thunk function aborted from useThunkFunction useEffect cleanup.'),
      );
      setStatus((prevStatus) => (prevStatus === Status.Running ? Status.Aborted : prevStatus));
    };
  }, [action, ...actionParams, canRun]);

  return [status === Status.Done, status];
};
