import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';

interface IDeps {
  readonly setDefaultRelationsRoot: (params: { itemId: Uuid }) => ThunkPromise;
}

export const setCurrentRelationsRootAsDefaultActionCreator =
  (deps: IDeps) => (): ThunkPromise => async (dispatch: Dispatch, getState: GetState) => {
    const {
      relationsApp: { selectedRootId },
    } = getState();

    if (!selectedRootId) {
      throw new Error('setCurrentRelationsRootAsDefault.ts: item is not selected.');
    }

    await dispatch(
      deps.setDefaultRelationsRoot({
        itemId: selectedRootId,
      }),
    );
  };
