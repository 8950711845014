import React, { useCallback } from 'react';
import { Task as TaskModel } from '../../../../../../../_shared/models/Task.ts';
import { LinkLike } from '../../../../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Task } from '../../../containers/details/Tasks/Task.tsx';

type TasksSectionStateProps = {
  readonly tasks: readonly TaskModel[];
};

export type TasksListProps = {
  readonly collapsedTasksList: readonly TaskModel[];
  readonly expandedTasksList: readonly TaskModel[];
  readonly highlightedTaskIds: UuidArray;
};

export type TasksListDispatchProps = {
  readonly onShowMoreClick: () => void;
};

type ITasksListProps = TasksListProps & TasksListDispatchProps;

const TasksListSection: React.FC<TasksSectionStateProps> = ({ tasks }) => (
  <ul className="content-item-sidebar__tasks-list">
    {tasks.map((task) => (
      <li id={`task-${task.id}`} key={task.id} className="content-item-sidebar__tasks-list-item">
        <Task task={task} />
      </li>
    ))}
  </ul>
);

export const CollapsedTaskListMaxItemCount = 3;

export const TasksList: React.FC<ITasksListProps> = ({
  collapsedTasksList,
  expandedTasksList,
  highlightedTaskIds,
  onShowMoreClick,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const ensureAllTasksAreVisible = useCallback(
    (taskIds: UuidArray) => {
      const taskIsOnlyInExpanded = (taskId: Uuid) =>
        !collapsedTasksList.find((task) => task.id === taskId) &&
        expandedTasksList.find((task) => task.id === taskId);

      if (taskIds.some(taskIsOnlyInExpanded)) {
        setIsExpanded(true);
      }
    },
    [collapsedTasksList, expandedTasksList],
  );

  const showLess = useCallback((): void => setIsExpanded(false), []);
  const showMore = useCallback((): void => {
    setIsExpanded(true);
    onShowMoreClick();
  }, [onShowMoreClick]);

  React.useEffect(
    () => ensureAllTasksAreVisible(highlightedTaskIds),
    [ensureAllTasksAreVisible, highlightedTaskIds],
  );

  return (
    <>
      <TasksListSection tasks={isExpanded ? expandedTasksList : collapsedTasksList} />
      {expandedTasksList.length > CollapsedTaskListMaxItemCount && (
        <div className="content-item-sidebar__tasks-control">
          <LinkLike
            onClick={isExpanded ? showLess : showMore}
            dataUiAction={isExpanded ? DataUiAction.Collapse : DataUiAction.Expand}
            className="sidebar__section-item-link"
          >
            {isExpanded ? 'Show less' : `Show all (${expandedTasksList.length})`}
          </LinkLike>
        </div>
      )}
    </>
  );
};
