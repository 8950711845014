import { memoize } from '@kontent-ai/memoization';
import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { CollectionOption } from '../components/collections/CollectionsGroupHeader.tsx';
import { roleBuilderAnyCollectionOptionId } from '../constants/roleBuilder.ts';

const getDisabledTooltip = (
  allSelectedCollectionIds: ReadonlySet<Uuid>,
  groupCollectionIds: UuidArray,
  collectionId: Uuid,
): string | undefined => {
  const isSelectedInTheGroup = groupCollectionIds.includes(collectionId);
  const isSelectedInAnyGroup = allSelectedCollectionIds.has(collectionId);

  if (!isSelectedInTheGroup && isSelectedInAnyGroup) {
    return 'You’ve already specified roles for this collection.';
  }

  return undefined;
};

export const getRoleBuilderCollectionOptions = memoize.maxOne(
  (
    collections: CollectionsMap,
    anyCollectionOptionName: string,
    allSelectedCollectionIds: ReadonlySet<Uuid>,
    groupCollectionIds: UuidArray,
  ): ReadonlyArray<CollectionOption> => {
    const anyCollectionOption: CollectionOption = {
      disabledTooltipText: getDisabledTooltip(
        allSelectedCollectionIds,
        groupCollectionIds,
        roleBuilderAnyCollectionOptionId,
      ),
      id: roleBuilderAnyCollectionOptionId,
      label: anyCollectionOptionName,
    };

    const options = [...collections.values()].map(
      (collection): CollectionOption => ({
        disabledTooltipText: getDisabledTooltip(
          allSelectedCollectionIds,
          groupCollectionIds,
          collection.id,
        ),
        id: collection.id,
        label: collection.name,
      }),
    );

    options.unshift(anyCollectionOption);

    return options;
  },
);
