import { DateTime } from '../../../../../_shared/models/DateTime.ts';
import { FormValidatorConfig } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IDueDateFormShape } from '../models/IDueDateFormShape.type.ts';

const datetimePickerValidatorBuilder = (fieldName: string) => {
  const errorMessage = `Provide valid ${fieldName}`;
  return (value?: DateTime) => (value?.isValid ? undefined : errorMessage);
};

export const dueDateFormValidationConfig: FormValidatorConfig<IDueDateFormShape> = {
  date: [datetimePickerValidatorBuilder('due date')],
};
