import { memoize } from '@kontent-ai/memoization';
import Immutable, { OrderedMap } from 'immutable';
import { replaceAssetIdentifier } from '../../../../../_shared/utils/assets/assetUtils.ts';
import { moveItem } from '../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  AssetReference,
  IAssetItemElement,
} from '../../../models/contentItemElements/AssetItemElement.ts';

export const removeAssetFromElementData = (
  assetId: Uuid,
  elementData: IAssetItemElement,
): IAssetItemElement => ({
  ...elementData,
  value: elementData.value.delete(assetId),
});

export const insertAssetsIntoElementData = (
  assetIds: ReadonlyArray<Uuid>,
  elementData: IAssetItemElement,
): IAssetItemElement => {
  const assetReferences = assetIds
    .filter((id) => !elementData.value.has(id))
    .reduce(
      (previousAssetReferences, id) =>
        previousAssetReferences.set(id, {
          id,
          renditions: [],
        }),
      OrderedMap<Uuid, AssetReference>(),
    )
    .concat(elementData.value)
    .toOrderedMap();

  return {
    ...elementData,
    value: assetReferences,
  };
};

export const replaceAssetInElementData = (
  elementData: IAssetItemElement,
  oldAssetId: Uuid,
  newAssetId: Uuid,
): IAssetItemElement => {
  return {
    ...elementData,
    value: replaceAssetIdentifier(elementData.value, oldAssetId, newAssetId),
  };
};

export const moveAssetInElementData = (
  elementData: IAssetItemElement,
  sourceId: string,
  targetId: string,
): IAssetItemElement => {
  const swappedReferences = moveItem(
    elementData.value.valueSeq().toArray(),
    sourceId,
    targetId,
    (element) => element.id,
  );
  const updatedElement: IAssetItemElement = {
    ...elementData,
    value: Immutable.OrderedMap(
      swappedReferences.map((assetReference) => [assetReference.id, { ...assetReference }]),
    ),
  };
  return updatedElement;
};

export const getAssetsForElement = memoize.weak(
  (
    assetIds: Immutable.OrderedMap<Uuid, AssetReference>,
    assetsById: Immutable.Map<Uuid, IAsset>,
    uploadingAssets: Immutable.Map<Uuid, Uuid | null>,
  ): Immutable.OrderedSet<IAsset> => {
    const assets = assetIds
      .map((_, assetId: Uuid) => {
        if (assetId) {
          const byId = assetsById.get(assetId);
          if (byId) {
            return byId;
          }

          const temporaryAssetId = uploadingAssets.get(assetId);
          if (temporaryAssetId) {
            return assetsById.get(temporaryAssetId);
          }
        }

        return null;
      })
      .filter((asset) => !!asset)
      .toOrderedSet() as Immutable.OrderedSet<IAsset>;

    return assets;
  },
);
