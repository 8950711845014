import { isEmptyOrWhitespace } from '../stringUtils.ts';

const EMAIL_REGEX =
  /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)@([a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}$/;

export const isEmail = (email: string | null | undefined): email is string =>
  !!email && !isEmptyOrWhitespace(email) && EMAIL_REGEX.test(email);

export const emailValidation = (value: string) =>
  isEmail(value) ? undefined : 'Provide a valid email address.';
