import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { AutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { getDataAttribute } from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { isSelectionCollapsed } from '../../../../../../_shared/utils/selectionUtils.ts';
import { ElementAttributes } from '../../../../../itemEditor/constants/elementAttributes.ts';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { InlineContentWithDialog } from '../../components/dialog/InlineContentWithDialog.tsx';
import { AssetLinkDetail } from '../containers/AssetLinkDetail.tsx';

export interface IAssetLinkOwnProps {
  readonly assetId: Uuid;
  readonly disabled?: boolean;
  readonly entityKey: string;
  readonly isViewOnly?: boolean;
  readonly onAssetClick?: () => void;
  readonly onEdit?: () => void;
  readonly onUnlink?: () => void;
  readonly status?: EditorLinkStatus;
}

export interface IAssetLinkStateProps {
  readonly isBrokenLink: boolean;
  readonly scrollId?: AutoScrollId;
}

interface IAssetLinkProps extends IAssetLinkOwnProps, IAssetLinkStateProps {
  readonly children: React.ReactNode;
}

export const AssetLink: React.FC<IAssetLinkProps> = (props) => {
  const {
    children,
    assetId,
    onAssetClick,
    disabled,
    entityKey,
    isBrokenLink,
    isViewOnly,
    onEdit,
    onUnlink,
    scrollId,
    status,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const contentRef = useRef<HTMLAnchorElement | null>(null);

  useAutoScroll({ scrollTargetRef: contentRef, scrollId });

  const closeDetail = (): void => setIsActive(false);

  const handleClose = (): void => {
    if (isActive) {
      closeDetail();
    }
  };

  const edit = (): void => {
    if (onEdit) {
      closeDetail();
      onEdit();
    }
  };

  const unlink = (): void => {
    if (onUnlink) {
      closeDetail();
      onUnlink();
    }
  };

  const toggleActive = (e: any): void => {
    const selectionCollapsed = isSelectionCollapsed();
    setIsActive((prevState) => selectionCollapsed && !prevState);
    e.preventDefault();
  };

  const clickAsset = (): void => {
    if (onAssetClick) {
      closeDetail();
      onAssetClick();
    }
  };

  return (
    <InlineContentWithDialog
      isInfo
      renderContent={(ref) => {
        return (
          <a
            className={classNames('rte__link', 'rte__link--is-clickable', {
              'rte__link--is-broken': isBrokenLink,
            })}
            {...getDataAttribute(ElementAttributes.AssetId, assetId)}
            onClick={toggleActive}
            ref={(c) => {
              contentRef.current = c;
              ref(c);
            }}
          >
            {children}
          </a>
        );
      }}
      renderDialog={() => {
        return (
          isActive && (
            <AssetLinkDetail
              assetId={assetId}
              disabled={disabled}
              entityKey={entityKey}
              isViewOnly={isViewOnly}
              onAssetClick={clickAsset}
              onEdit={onEdit ? edit : undefined}
              onUnlink={onUnlink ? unlink : undefined}
              status={status}
            />
          )
        );
      }}
      onClose={handleClose}
    />
  );
};

AssetLink.displayName = 'AssetLink';
