import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useContext, useEffect, useState } from 'react';
import { isMissingItemIdentifier } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { RichTextItemElementContext } from '../../ContentItemEditing/context/RichTextItemElementContext.tsx';
import { ContentComponentContext } from '../context/ContentComponentContext.tsx';
import { useItemVariantId } from '../context/ContentItemContext.tsx';
import { getDeliveryContentComponentId } from '../utils/deliveryContentComponentUtils.ts';

export function useDeliveryContentComponentId() {
  const { contentComponentId } = useContext(ContentComponentContext);
  const { itemId, variantId } = useItemVariantId();
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);

  const [deliveryContentComponentId, setDeliveryContentComponentId] = useState<Uuid | null>(null);

  useEffect(() => {
    if (!contentComponentId || isMissingItemIdentifier(itemId) || !rootRichTextElementId) {
      setDeliveryContentComponentId(null);
      return;
    }

    const getIdPromise = makeCancellablePromise(async () =>
      getDeliveryContentComponentId(itemId, variantId, rootRichTextElementId, contentComponentId),
    )
      .then((result) => setDeliveryContentComponentId(result))
      .catch(swallowCancelledPromiseError);

    return () => getIdPromise.cancel();
  }, [itemId, variantId, rootRichTextElementId, contentComponentId]);
  return deliveryContentComponentId;
}
