import { Box } from '@kontent-ai/component-library/Box';
import { Typography, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { IAssetValidationResult } from '../../../applications/itemEditor/features/ContentItemEditing/components/elements/asset/AssetTile.tsx';
import { FormattedFileSize } from '../../../applications/itemEditor/features/ContentItemEditing/components/elements/asset/FormattedFileSize.tsx';
import { IAssetRendition } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../data/models/assets/Asset.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';
import { AssetLastModified } from './AssetLastModified.tsx';

interface IProps {
  readonly asset: IAsset;
  readonly isHeightLimitationSet?: boolean;
  readonly isWidthLimitationSet?: boolean;
  readonly rendition?: IAssetRendition;
  readonly renditionId?: Uuid;
  readonly validationResult: IAssetValidationResult;
}

type DimensionProps = {
  readonly name: string;
  readonly value: number;
  readonly isValid: boolean;
};

const Dimension: React.FC<DimensionProps> = ({ name, value, isValid }) => (
  <span
    className={classNames('asset-thumbnail__tech-detail', {
      'asset-thumbnail__tech-detail--is-invalid': !isValid,
    })}
  >
    <IgnoreByGrammarly text={`${name} ${value} px`}>
      {name} {value}&nbsp;px
    </IgnoreByGrammarly>
  </span>
);

type FileSizeProps = {
  readonly fileSize: number | null;
  readonly isValid: boolean;
};

const FileSize: React.FC<FileSizeProps> = ({ fileSize, isValid }) => (
  <FormattedFileSize
    wrapperClassName="asset-thumbnail__tech-detail"
    className="asset-thumbnail__file-size"
    invalidClassName={isValid ? '' : 'asset-thumbnail__file-size--is-invalid'}
    fileSizeInBytes={fileSize}
  />
);

type TechDetailsWrapperProps = {
  readonly prefix?: string;
  readonly children: ReactNode;
};

const TechDetailsWrapper: React.FC<TechDetailsWrapperProps> = ({ children, prefix }) => (
  <Box typography={Typography.LabelMedium} color={colorTextLowEmphasis}>
    {prefix && (
      <>
        <b>{prefix}</b>&nbsp;
      </>
    )}
    {children}
  </Box>
);

export const AssetTileTechnicalDetails: React.FC<IProps> = ({
  asset,
  isHeightLimitationSet,
  isWidthLimitationSet,
  rendition,
  renditionId,
  validationResult,
}) => {
  const renditionExists = !!rendition;

  if (renditionId && !renditionExists) {
    return (
      <TechDetailsWrapper prefix="Customized:">
        Customized image doesn’t exist. Please contact your developers.
      </TechDetailsWrapper>
    );
  }

  if (renditionExists) {
    return (
      <TechDetailsWrapper prefix="Customized:">
        <Dimension
          name="width"
          value={rendition.imageWidth}
          isValid={validationResult.isAssetWidthValid}
        />
        <Dimension
          name="height"
          value={rendition.imageHeight}
          isValid={validationResult.isAssetHeightValid}
        />
        <FileSize fileSize={rendition.size} isValid={validationResult.isAssetFileSizeValid} />
      </TechDetailsWrapper>
    );
  }

  const {
    _uploaded: uploadedSoFar, // Indicates % size that has been uploaded so far
    _uploading,
    _sizeUploaded,
    created,
    fileSize,
    height,
    width,
  } = asset;

  const isFileUploading = _uploading || uploadedSoFar === 0;

  return (
    <>
      <TechDetailsWrapper>
        <FileSize
          fileSize={isFileUploading ? _sizeUploaded : fileSize}
          isValid={validationResult.isAssetFileSizeValid}
        />
        <AssetLastModified created={created} isFileUploading={isFileUploading} />
      </TechDetailsWrapper>
      <TechDetailsWrapper>
        {isWidthLimitationSet && width && (
          <Dimension name="width" value={width} isValid={validationResult.isAssetWidthValid} />
        )}
        {isHeightLimitationSet && height && (
          <Dimension name="height" value={height} isValid={validationResult.isAssetHeightValid} />
        )}
      </TechDetailsWrapper>
    </>
  );
};

AssetTileTechnicalDetails.displayName = 'AssetTileTechnicalDetails';
