import Immutable from 'immutable';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from '../ICompiledContentItemElement.type.ts';

interface ILinkedItemsElementValue {
  readonly nestedLevel: number;
  readonly value: Immutable.List<Uuid>;
}

export interface ILinkedItemsElement
  extends ILinkedItemsElementValue,
    ICompiledContentItemElementData {}

export const EmptyLinkedItemsElementValue: ILinkedItemsElementValue = {
  nestedLevel: 1,
  value: Immutable.List<Uuid>(),
};

export const EmptyLinkedItemElement: ILinkedItemsElement = {
  elementId: '',
  type: ElementType.LinkedItems,
  lastModifiedBy: null,
  lastModifiedAt: null,
  ...EmptyLinkedItemsElementValue,
};

export const newLinkedItemsElement = (
  params: Partial<ILinkedItemsElement>,
): ILinkedItemsElement => ({
  ...EmptyLinkedItemElement,
  ...params,
});
