import { ElementType } from '../../contentInventory/content/models/ContentItemElementType.ts';
import { ElementErrorResult, ItemElementErrorResult } from './elementErrorCheckers/types/Errors.ts';
import { mapElementErrorToMessage } from './mapElementErrorToMessage.ts';

export const mapElementErrorResultToItemElementErrorResult = (
  validationResults: ElementErrorResult,
  elementType: ElementType,
): ItemElementErrorResult => ({
  errorMessages: validationResults.errors.map((elementError) =>
    mapElementErrorToMessage(elementError, elementType),
  ),
});
