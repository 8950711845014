import classNames from 'classnames';
import { ReactNode } from 'react';
import { OptionalTooltip } from '../Tooltip/OptionalTooltip.tsx';

type Props = {
  readonly className?: string;
  readonly isDisabled?: boolean;
  readonly text: string;
  readonly children?: ReactNode;
};

export const DropDownOptionName = ({ children, className, isDisabled, text }: Props) => {
  return (
    <OptionalTooltip
      text={isDisabled ? '' : text}
      placement="right"
      className={classNames('dropdown-option__name', className)}
    >
      {text}
      {children}
    </OptionalTooltip>
  );
};
