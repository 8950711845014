import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  Content_Filter_Clear,
  Content_LoadedItems_ListingItemsLoaded,
} from '../../contentInventory/content/constants/contentActionTypes.ts';
import { IContentItemOverwritten } from '../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_CurrentItemOverwritten,
  Content_Editing_CurrentItemOverwrittenNotificationClosed,
  Content_Editing_DefaultVariantOverwritten,
  Content_Editing_DefaultVariantOverwrittenNotificationClosed,
  Content_Editing_ItemElementsOverwritten,
  Content_HideValidationResults,
  Content_ShowFriendlyWarnings,
} from '../constants/editorActionTypes.ts';

export function showFriendlyWarnings() {
  return {
    type: Content_ShowFriendlyWarnings,
  } as const;
}

export function hideValidationResults() {
  return {
    type: Content_HideValidationResults,
  } as const;
}

export function clearListingFilter() {
  return {
    type: Content_Filter_Clear,
  } as const;
}

export function listingItemsLoaded(contentItems: ReadonlyArray<IListingContentItem>) {
  return {
    type: Content_LoadedItems_ListingItemsLoaded,
    payload: {
      contentItems,
    },
  } as const;
}

export function currentItemOverwritten(itemId: Uuid, params: IContentItemOverwritten) {
  return {
    type: Content_Editing_CurrentItemOverwritten,
    payload: {
      itemId,
      params,
    },
  } as const;
}

export function itemDefaultVariantOverwritten(itemId: Uuid, params: IContentItemOverwritten) {
  return {
    type: Content_Editing_DefaultVariantOverwritten,
    payload: {
      itemId,
      params,
    },
  } as const;
}

export function closeCurrentItemOverwrittenNotification() {
  return {
    type: Content_Editing_CurrentItemOverwrittenNotificationClosed,
  } as const;
}

export function closeDefaultVariantOverwrittenNotification() {
  return {
    type: Content_Editing_DefaultVariantOverwrittenNotificationClosed,
  } as const;
}

export function currentItemElementsOverwritten(changedElements: UuidArray) {
  return {
    type: Content_Editing_ItemElementsOverwritten,
    payload: {
      changedElements,
    },
  } as const;
}
