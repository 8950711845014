import { Collection } from '@kontent-ai/utils';
import { Feature } from '../../../../@types/FeatureEnum.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { isFeatureEnabled } from '../../../_shared/utils/featureToggles.ts';
import { PreviewApiDataStatus } from '../models/PreviewApiDataStatus.ts';

export const isWebSpotlightEnabled = (state: IStore): boolean =>
  !!state.webSpotlightApp.configuration?.isEnabled;

export function isWebSpotlightEnabledAndInitializedForCurrentProject(state: IStore): boolean {
  const webSpotlightConfiguration = state.webSpotlightApp.configuration;
  return (
    !!webSpotlightConfiguration &&
    webSpotlightConfiguration.isEnabled &&
    !!webSpotlightConfiguration.rootTypeId
  );
}

export const isPreviewAutoRefreshFeatureAvailable = (state: IStore): boolean =>
  isFeatureEnabled(Feature.WebSpotlightPreviewAutoRefresh) ||
  state.sharedApp.projectProperties.WebSpotlightPreviewAutoRefresh === 'Enabled';

export const isPreviewAutoRefreshFeatureEnabledByUser = (state: IStore): boolean =>
  state.webSpotlightApp.isPreviewAutoRefreshEnabled;

export const getPreviewApiDataStatus = (state: IStore): PreviewApiDataStatus =>
  isPreviewAutoRefreshFeatureAvailable(state)
    ? state.webSpotlightApp.previewApiDataStatus
    : PreviewApiDataStatus.Initial;

export const getMsTimeSinceLastItemModificationInPreview = (state: IStore): number => {
  const latestModificationMs = Collection.getValues(
    state.webSpotlightApp.lastModifiedPreviewItems,
  ).reduce((latest: number, timestamp: Date) => {
    const timestampMs = timestamp.getTime();
    return !latest || timestampMs > latest ? timestampMs : latest;
  }, 0);

  return Date.now() - latestModificationMs;
};
