import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { setEditorStateSelection } from '../../../utils/editorStateUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  containsNewBlockPlaceholder,
  createNewBlockPlaceholder,
  deleteObjectBlock,
  getClosestSelectionAfter,
} from '../../../utils/general/editorContentUtils.ts';
import { CustomBlocksPlugin } from '../CustomBlocksPlugin.tsx';

export const editorCustomBlocksApi: EditorApiImplementation<CustomBlocksPlugin> = {
  // Asynchronous editing with user actions (e.g. inserting new blocks with modal dialog) may be subject to inconsistencies from unexpected changes
  // For this reason the new place is first allocated by a placeholder block, and it is used as the location to insert new blocks
  // This is similar to how placeholders for links work
  createNewBlockPlaceholder(api, editorState, selection, blockType, id) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => createNewBlockPlaceholder(input, blockType, id),
      'insert-fragment',
    );
  },

  deleteObjectBlock(api, editorState, blockKey, direction) {
    // Explicitly place selection before deleting the block so that the selection after undo lands after the deleted block
    const selectionBefore =
      getClosestSelectionAfter(getBlocks(editorState.getCurrentContent()), blockKey) ??
      editorState.getSelection();
    const withSelectionBefore = setEditorStateSelection(editorState, selectionBefore);

    return api.executeContentChange(
      withSelectionBefore,
      selectionBefore,
      (input) => deleteObjectBlock(input, blockKey, direction),
      'remove-range',
    );
  },

  undoNewBlockPlaceholder(api, editorState, placeholderBlockKey) {
    let result = editorState;
    while (
      api.canUndo(result) &&
      containsNewBlockPlaceholder(result.getCurrentContent(), placeholderBlockKey)
    ) {
      result = api.undo(result, false);
    }

    return result;
  },
};
