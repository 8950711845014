import { Box } from '@kontent-ai/component-library/Box';
import { RadioButton, RadioGroup } from '@kontent-ai/component-library/RadioGroup';
import { Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import { useController } from 'react-hook-form';
import { OptionType } from '../../../../_shared/components/Options/createOptionsListComponent.tsx';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiEntityWebhookAction,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EntityWebhookEventsFilter } from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookEventTriggerBarListing } from './EntityWebhookEventTriggerBarListing.tsx';

const options: ReadonlyArray<OptionType<DataUiEntityWebhookAction>> = [
  {
    dataUiAttribute: DataUiEntityWebhookAction.WebhookAllEventsTrigger,
    id: EntityWebhookEventsFilter.All,
    label: 'All events',
    tooltipText:
      'The webhook is triggered by any event related to assets, items, languages, taxonomies, and types.',
  },
  {
    dataUiAttribute: DataUiEntityWebhookAction.WebhookSpecificEventsTrigger,
    id: EntityWebhookEventsFilter.Specific,
    label: 'Specific events',
    tooltipText: 'The webhook is triggered by specific events for specific object types.',
  },
];

const groupTitle = 'Events';

type EntityWebhookEventTriggerProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookEventTrigger = ({
  enabled,
  formProps,
}: EntityWebhookEventTriggerProps) => {
  const { control } = formProps;
  const { field } = useController({ control, name: 'triggers.events' });

  return (
    <Stack spacing={Spacing.XXL}>
      <Stack spacing={Spacing.L}>
        <Box typography={Typography.HeadlineMedium}>{groupTitle}</Box>
        <RadioGroup
          ariaLabel={groupTitle}
          groupSubLabel="Specify the events that trigger the webhook."
          name="EventTrigger"
          radioGroupState={enabled ? 'default' : 'disabled'}
          onChange={field.onChange}
          value={field.value}
          {...getDataUiCollectionAttribute(DataUiCollection.ScheduleMethodSelector)}
        >
          {options.map((option) => (
            <Row key={option.id}>
              <RadioButton
                caption={option.tooltipText}
                radioButtonState={option.disabled ? 'disabled' : 'default'}
                tooltipText={option.tooltipText}
                value={option.id}
                {...getDataUiObjectNameAttribute(option.dataUiAttribute ?? '')}
              >
                {option.label}
              </RadioButton>
            </Row>
          ))}
        </RadioGroup>
      </Stack>
      {field.value === EntityWebhookEventsFilter.Specific && (
        <EntityWebhookEventTriggerBarListing enabled={enabled} formProps={formProps} />
      )}
    </Stack>
  );
};

EntityWebhookEventTrigger.displayName = 'EntityWebhookEventTrigger';
