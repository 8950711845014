import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  CreditCardInput as CreditCardInputComponent,
  ICreditCardInputStateProps,
} from '../components/CreditCardInput.tsx';
import { getFastSpringBaseUrl } from '../utils/fastSpringUtils.ts';

function mapStateToProps(state: IStore): ICreditCardInputStateProps {
  const isConnected = !!getSelectedSubscription(state)?.isFsSubscriptionConnected;

  return {
    baseUrl: getFastSpringBaseUrl(),
    isConnected,
  };
}

export const CreditCardInput: React.ComponentType =
  connect(mapStateToProps)(CreditCardInputComponent);
