import { isSet } from '@kontent-ai/utils';
import { Middleware, Reducer, StoreEnhancer, configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import {
  sanitizeReduxActionForDevTools,
  sanitizeReduxStateForDevTools,
} from '../../applications/richText/utils/debug/sanitizeReduxStateForDevTools.ts';

type Config<TStore> = {
  readonly customEnhancers?: ReadonlyArray<StoreEnhancer>;
  readonly customMiddlewares?: ReadonlyArray<Middleware<TStore>>;
  readonly preloadedState?: TStore;
  readonly reducer: Reducer<TStore>;
};

export const createReduxStore = <TStore>(config: Config<TStore>) => {
  return configureStore({
    reducer: config.reducer,
    preloadedState: config.preloadedState,
    devTools: !!self._clientConfig.enableReduxDevtools && {
      actionSanitizer: sanitizeReduxActionForDevTools,
      stateSanitizer: sanitizeReduxStateForDevTools,
      serialize: {
        replacer: (_key: string, value: unknown) => (isSet(value) ? [...value] : value),
        options: {
          //  We do not want to serialize everything like functions, etc. Those do not belong to state anyway.
          //  Rethink if you really need to support more data types
          //  Optional/undefined - use null
          //  Date - use established DateTimeStamp
          map: true,
        },
      },
    },
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      });

      if (config.customMiddlewares) {
        middleware.push(...config.customMiddlewares);
      }

      if (self._clientConfig.enableReduxActionLogging) {
        middleware.push(logger);
      }

      return middleware;
    },
    enhancers: (getDefaultEnhancers) => {
      const enhancers = getDefaultEnhancers();

      if (config.customEnhancers) {
        enhancers.push(...config.customEnhancers);
      }

      return enhancers;
    },
  });
};
