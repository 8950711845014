import { ItemListingBulkActionStatus } from '../../applications/contentInventory/content/models/ItemListingBulkActionStatus.ts';
import { PublishingState } from '../../data/constants/PublishingState.ts';
import { IAssignmentFullServerModel } from './IAssignmentServerModel.type.ts';
import {
  IActiveCapabilitiesForVariantServerModel,
  IContentItemIdentifierServerModel,
  IContentTypeReferenceServerModel,
} from './INewContentItemServerModel.ts';
import { IVariantWorkflowStepServerModel } from './WorkflowServerModel.type.ts';

export interface IListingItemDataServerModel {
  readonly codeName: string;
  readonly id: Uuid;
  readonly collectionId: Uuid;
  readonly spaceIds: ReadonlyArray<Uuid>;
  readonly name: string;
  readonly type: IContentTypeReferenceServerModel;
  readonly archived: boolean;
}

export interface IListingVariantDataServerModel {
  readonly actualWorkflowStatus?: IVariantWorkflowStepServerModel | null;
  readonly id: IContentItemIdentifierServerModel;
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly lastModified: DateTimeStamp | null;
  readonly publishingState: PublishingState;
  readonly assignment: IAssignmentFullServerModel;
  readonly isComplete: boolean;
  readonly archived: boolean;
  readonly urlSlug?: string;
  readonly contentLinkIds: ReadonlyArray<IContentItemIdentifierServerModel>;
  readonly modularItemIds: ReadonlyArray<IContentItemIdentifierServerModel>;
  readonly workflowLastModifiedAt: DateTimeStamp | null;
}

export interface IListingContentItemServerModel {
  readonly activeCapabilities: IActiveCapabilitiesForVariantServerModel;
  readonly highlightedName: string | null;
  readonly isPublishedVersion: boolean;
  readonly item: IListingItemDataServerModel;
  readonly variant: IListingVariantDataServerModel | null;
  readonly translatedLanguages: UuidArray;
}

export interface IContentItemPersonalMetadataServerModel {
  readonly id: IContentItemIdentifierServerModel;
  readonly lastEditedByUser: DateTimeStamp;
}

export interface IListingContentItemWithMetadataServerModel extends IListingContentItemServerModel {
  readonly personalMetadata: IContentItemPersonalMetadataServerModel;
}

export enum SearchMethod {
  Standard = 'Standard',
  StandardAsBackUp = 'StandardAsBackUp',
  FullText = 'FullText',
}

export interface IListItemsResponseServerModel {
  readonly continuationToken: ContinuationToken;
  readonly data: ReadonlyArray<IListingContentItemServerModel>;
  readonly searchMethod: SearchMethod;
}

export interface IListItemsByIdsResponseServerModel {
  readonly data: ReadonlyArray<IListingContentItemServerModel>;
}

export interface IListItemsByIdsWithVariantsByIdsResponseServerModel {
  readonly data: ReadonlyArray<IListingContentItemServerModel>;
}

export interface IListAllItemsDataByIdsWithVariantsByIdsResponseServerModel
  extends IListItemsByIdsWithVariantsByIdsResponseServerModel {
  readonly unavailableItemIds: ReadonlyArray<IContentItemIdentifierServerModel>;
  readonly deletedItemIds: ReadonlyArray<IContentItemIdentifierServerModel>;
}

export interface IListItemsByComponentsTypeIdResponseServerModel {
  readonly continuationToken: ContinuationToken;
  readonly data: ReadonlyArray<IListingContentItemServerModel>;
}

export interface IListItemsCountByComponentsTypeIdResponseServerModel {
  readonly contentTypeId: Uuid;
  readonly numberOfItems: number;
}

export interface IPlanningItemsServerModel {
  readonly data: ReadonlyArray<IListingContentItemServerModel>;
  readonly wasDataCountLimitExceeded: boolean;
}

export type FailedItemServerModel = {
  readonly id: Uuid;
  readonly code: string;
  readonly description: string;
};

export type ItemListOperationResultServerModel = {
  readonly modifiedItemIds: UuidArray;
  readonly failedItems: FailedItemServerModel[];
};

export interface IPublishWarningsServerModel {
  readonly itemsWithBrokenLinks: UuidArray;
  readonly itemsWithBrokenModulars: UuidArray;
}

export interface IItemListingOperationCheckServerModel {
  readonly archive: IItemListingOperationStatusServerModel;
  readonly cancel_schedule_publish: IItemListingOperationStatusServerModel;
  readonly move_to_collection: IItemListingOperationStatusServerModel;
  readonly publish: IItemListingOperationStatusServerModel;
  readonly restore_from_archived_step: IItemListingOperationStatusServerModel;
  readonly unpublish: IItemListingOperationStatusServerModel;
  readonly update_workflow_step: IItemListingUpdateWorkflowStatusServerModel;
}

export interface IItemListingOperationStatusServerModel {
  readonly status: ItemListingBulkActionStatus;
}

export interface IItemListingUpdateWorkflowStatusServerModel {
  readonly status: ItemListingBulkActionStatus;
  readonly availableTransitions?: UuidArray;
}
