import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { ICollectionGroupRoles } from '../../../../data/models/users/ProjectContributor.ts';
import { roleBuilderAnyCollectionOptionName } from '../constants/roleBuilder.ts';
import { getRemainingCollectionsCount } from './getRemainingCollectionsCount.ts';

type Params = {
  readonly allSelectedCollectionIds: ReadonlySet<Uuid>;
  readonly collections: CollectionsMap;
  readonly collectionsGroups: readonly ICollectionGroupRoles[];
  readonly groupCollectionIds: UuidArray;
};

export const getAnyCollectionOptionName = (params: Params) => {
  const { allSelectedCollectionIds, collections, collectionsGroups, groupCollectionIds } = params;
  const hasOnlyOneGroup = collectionsGroups.length === 1;

  if (hasOnlyOneGroup) {
    return roleBuilderAnyCollectionOptionName;
  }

  const anyCollectionsCount = getRemainingCollectionsCount({
    allSelectedCollectionIds,
    collections,
    groupCollectionIds,
  });

  return `Any remaining collections (${anyCollectionsCount})`;
};
