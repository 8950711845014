import React from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { DropTarget } from '../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { isLanguageActive } from '../../../../_shared/models/utils/isLanguageActive.ts';
import { isWithinTargetInset20 } from '../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { ILanguage, Languages } from '../../../../data/models/languages/Language.ts';
import { DraggableLanguageBar } from '../containers/languageBarItems/DraggableLanguageBar.tsx';
import { LanguageEditor } from '../containers/languageEditors/LanguageEditor.tsx';

export interface ILanguageItemDataProps {
  readonly language: ILanguage;
  readonly languages: Languages;
  readonly isEdited: boolean;
}

export interface ILanguageItemCallbackProps {
  readonly onMove: (movedItem: Uuid, targetItem: Uuid) => void;
}

type LanguageItemProps = ILanguageItemDataProps & ILanguageItemCallbackProps;

export const LanguageItem: React.FC<LanguageItemProps> = ({
  isEdited,
  language,
  languages,
  onMove,
}) => {
  return (
    <DropTarget<HTMLLIElement>
      accept={DndTypes.Language_Bar_Move}
      canDrop={!isEdited && isLanguageActive(language)}
      onMove={({ sourceId, targetId }) => onMove(sourceId, targetId)}
      parentId=""
      hoveringCollisionStrategy={isWithinTargetInset20}
      renderDroppable={(ref) => (
        <li className="bar-item__node" ref={ref}>
          <BarItemAnimation
            estimatedMaxHeightWhenExpanded={400}
            renderCollapsed={() => <DraggableLanguageBar language={language} />}
            renderExpanded={() => (
              <LanguageEditor editorLanguages={languages} language={language} />
            )}
            shouldBeExpanded={isEdited}
          />
        </li>
      )}
      targetId={language.id}
    />
  );
};

LanguageItem.displayName = 'LanguageItem';
