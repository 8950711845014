import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem, findItem } from '@kontent-ai/component-library/Selects';
import React, { ComponentProps, ReactElement } from 'react';
import { FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { HookFormProps } from '../../types/hookFormProps.type.ts';
import { showFieldError } from '../../utils/validation/showFieldError.ts';

type Props<TFormShape extends FieldValues, TItem extends ISelectItem<TItem>> = ComponentProps<
  typeof MultiSelect<TItem>
> & {
  readonly formProps: HookFormProps<TFormShape>;
  readonly normalize?: (
    newOptions: ReadonlyArray<TItem>,
    oldOptions: ReadonlyArray<TItem>,
  ) => ReadonlyArray<TItem>;
  readonly name: FieldPathByValue<TFormShape, ReadonlyArray<string>>;
};

export const ValidatedMultipleOptionSelect = <
  TFormValues extends FieldValues,
  TItem extends ISelectItem<TItem>,
>(
  props: Props<TFormValues, TItem>,
): ReactElement | null => {
  const { formProps, name, items, inputState, caption, normalize, ...otherProps } = props;

  const { field, fieldState, formState } = useController({
    control: formProps.control,
    name,
  });

  if (!items) {
    return null;
  }

  const { error } = fieldState;
  const showError = showFieldError(formState, error);

  const mapIdToOption = (id: string): TItem => {
    return findItem(items, (item) => item.id === id) as TItem;
  };

  const onChange = (newSelectedItemIds: ReadonlySet<string>) => {
    if (normalize) {
      const newSelectedItems = Array.from(newSelectedItemIds, mapIdToOption);
      const oldSelectedItems = Array.from(field.value, mapIdToOption);

      const normalizedIds = normalize(newSelectedItems, oldSelectedItems).map(
        (option) => option.id,
      );

      field.onChange(normalizedIds);
    } else {
      field.onChange(Array.from(newSelectedItemIds));
    }
  };

  return (
    <MultiSelect
      caption={showError ? error?.message : caption}
      inputState={showError ? InputState.Alert : inputState}
      items={items}
      onSelectionChange={onChange}
      ref={field.ref}
      selectedItemIds={field.value}
      {...otherProps}
    />
  );
};

(ValidatedMultipleOptionSelect as React.FC).displayName = 'ValidatedMultipleOptionSelect';
