import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { BuildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import {
  NoAccessToProjectLinkText,
  NoAccessToProjectMessage,
  ProjectNotFoundLinkText,
  ProjectNotFoundMessage,
} from '../../constants/uiConstants.ts';
import { handleSuccessfulRedirect, handleUnsuccessfulRedirect } from '../smartLinkActions.ts';

interface IOpenProjectDeps {
  readonly updateCurrentProject: (
    projectToSelect: IUserProjectInfo,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly buildPath: BuildPath;
  readonly getSignInPageUrl: (includeRedirectUri?: boolean) => string;
}

export const openProjectCreator =
  (deps: IOpenProjectDeps) =>
  (projectId?: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    if (!isUuid(projectId)) {
      dispatch(handleUnsuccessfulRedirect(ProjectNotFoundLinkText, ProjectNotFoundMessage, '/'));
      throw new Error('Provide valid project GUID.');
    }

    const {
      data: { user },
    } = getState();

    const projectToSelect = user.projectsInfoById.get(projectId);
    if (projectToSelect) {
      await dispatch(deps.updateCurrentProject(projectToSelect, abortSignal));
      dispatch(
        handleSuccessfulRedirect(
          deps.buildPath<EnvironmentRouteParams>(EnvironmentRoute, {
            projectId: projectToSelect.projectId,
          }),
        ),
      );
    } else {
      dispatch(
        handleUnsuccessfulRedirect(
          NoAccessToProjectLinkText,
          NoAccessToProjectMessage,
          deps.getSignInPageUrl(false),
        ),
      );
    }
  };
