import { Box } from '@kontent-ai/component-library/Box';
import { IconName, Icons } from '@kontent-ai/component-library/Icons';
import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import { ReactNode, forwardRef } from 'react';
import { DialogState } from '../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { ModalDialog } from '../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IAnimatedModalDialogProps } from './IAnimatedModalDialogProps.type.ts';

type Props = IAnimatedModalDialogProps & {
  readonly confirmButtonIcon?: IconName;
  readonly confirmButtonText: string;
  readonly confirmButtonTooltipText?: string;
  readonly isConfirmButtonDisabled?: boolean;

  readonly alert?: boolean;
  readonly children: ReactNode;
  readonly closeImmediatelyAfterConfirm?: boolean;
  readonly headerContent: string;
  readonly headerTooltip?: string;
  readonly isLoading?: boolean;
  readonly onCancel?: () => void;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
};

export const ConfirmationDialog = forwardRef<HTMLDivElement, Props>(
  (
    {
      isLoading,
      isOpen,
      onCancel,
      onConfirm,
      onClose,
      alert,
      headerContent,
      isConfirmButtonDisabled: disabled,
      closeImmediatelyAfterConfirm,
      children,
      confirmButtonTooltipText,
      confirmButtonText,
      confirmButtonIcon,
    },
    ref,
  ) => {
    const confirm = () => {
      onConfirm();
      if (closeImmediatelyAfterConfirm ?? true) {
        onClose();
      }
    };
    const cancel = () => {
      onCancel?.();
      onClose();
    };

    const destructive = !isLoading && alert;

    return (
      <ModalDialog
        headline={headerContent}
        isDismissable
        isOpen={isOpen}
        maxWidth={px(70 * gridUnit)}
        onClose={onClose}
        state={isLoading ? DialogState.InProgress : undefined}
        primaryAction={{
          destructive,
          // override destructive icon when specified
          destructiveIcon: confirmButtonIcon && !isLoading ? Icons[confirmButtonIcon] : undefined,
          disabled,
          onClick: confirm,
          text: confirmButtonText,
          tooltipText: confirmButtonTooltipText,
          // enable AnimatedProgressIcon when in loading state - see DialogFooterAction.tsx
          iconBefore: isLoading ? Icons.Placeholder : undefined,
          ...getDataUiActionAttribute(DataUiAction.Confirm),
        }}
        ref={ref}
        cancelAction={{ onClick: cancel, ...getDataUiActionAttribute(DataUiAction.Cancel) }}
        {...getDataUiElementAttribute(DataUiElement.ConfirmationDialog)}
      >
        <Box width={64 * gridUnit}>{children}</Box>
      </ModalDialog>
    );
  },
);

ConfirmationDialog.displayName = 'ConfirmationDialog';
