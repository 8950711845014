import classNames from 'classnames';
import { forwardRef } from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ActionButtonProps = {
  readonly buttonClassName?: string;
  readonly className?: string;
  readonly dataUiActionAttribute: DataUiAction;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly isActionConfirmationPending: boolean;
  readonly isCompact?: boolean;
  readonly onClick: () => void;
  readonly tooltipText: string;
};

export const ActionButton = forwardRef<HTMLDivElement, ActionButtonProps>(
  (
    {
      buttonClassName,
      className,
      disabled,
      dataUiActionAttribute,
      iconName,
      isActionConfirmationPending,
      isCompact,
      onClick,
      tooltipText,
      ...otherProps
    },
    forwardedRef,
  ) => (
    <SquareButton
      ref={forwardedRef}
      disabled={disabled}
      className={classNames('content-component__action', className, {
        'content-component__action--is-compact': isCompact,
      })}
      customButtonClassName={buttonClassName}
      style={SquareButtonStyle.Quinary}
      size={SquareButtonSize.Quinary}
      onClick={onClick}
      iconName={iconName}
      ariaLabel={isActionConfirmationPending ? '' : tooltipText}
      tooltipPlacement="bottom"
      tooltipText={isActionConfirmationPending ? undefined : tooltipText}
      {...getDataUiActionAttribute(dataUiActionAttribute)}
      {...otherProps}
    />
  ),
);

ActionButton.displayName = 'ActionButton';
