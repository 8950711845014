import { pluralizeWithoutCount, template } from '../../../_shared/utils/stringUtils.ts';
import { SupportedFileTypesMessage } from './responsiveImageSupportedTypes.ts';

export const TextTooLongWarning = template`Shorten the text to <b>${0} ${1}</b> before publishing.`;
export const ModularContentMinimumWarning = (count: number) =>
  `Add <b>at least ${count}</b> ${pluralizeWithoutCount('content item', count)} before publishing.`;
export const AssetMinimumWarning = (count: number) =>
  `Insert <b>at least ${count}</b> ${pluralizeWithoutCount('asset', count)} before publishing.`;
export const TaxonomyMinimumWarning = (count: number) =>
  `Select <b>at least ${count}</b> ${pluralizeWithoutCount('term', count)} before publishing.`;
export const ModularContentExactlyWarning = (count: number) =>
  `Add <b>exactly ${count}</b> ${pluralizeWithoutCount('content item', count)} before publishing.`;
export const AssetExactlyWarning = (count: number) =>
  `Insert <b>exactly ${count}</b> ${pluralizeWithoutCount('asset', count)} before publishing.`;
export const TaxonomyExactlyWarning = (count: number) =>
  `Select <b>exactly ${count}</b> ${pluralizeWithoutCount('term', count)} before publishing.`;
export const ModularContentMaximumWarning = template`Reduce the number of added items to <b>${0} or less</b>.`;
export const AssetMaximumWarning = template`Reduce the number of inserted assets to <b>${0} or less</b> before publishing.`;
export const TaxonomyMaximumWarning = template`Select <b>${0} terms or less</b> before publishing.`;
export const AssetFileSizeWarning =
  'Remove the assets exceeding the specified maximum size limit before publishing.';
export const TextIsRequiredWarning = 'Type some text before publishing.';
export const IsRequiredWarning = 'Provide a value before publishing.';
export const AssetIsRequiredWarning = 'Insert an asset before publishing.';
export const ResponsiveImageTypeOnlyWarning = `Remove assets in unsupported formats before publishing. ${SupportedFileTypesMessage}`;
export const DateTimeIsRequiredWarning = 'Select a date before publishing.';
export const ModularContentIsRequiredWarning = 'Add a content item before publishing.';
export const RichTextDisallowedContentTypes =
  'Remove components or items based on unaccepted types (highlighted in red) before publishing';
export const RichTextDisallowedItemLinkTypes =
  'Remove item links to content items based on unaccepted types before publishing.';
export const ModularElementDisallowedContentTypes =
  'Remove items based on unaccepted types (highlighted in red) before publishing.';
export const MultipleChoiceIsRequiredWarning = 'Select an option before publishing.';
export const TaxonomyIsRequiredWarning = 'Select a term before publishing.';
export const WorkflowIsRequiredWarning = 'Select a workflow';
export const RegexValidationWarning =
  'Type a value matching the pattern required in this element. Contact your developers or content managers to learn more.';

export const NonLocalizableElementPublishingWarning = (defaultLanguageName: string) =>
  `This non-localizable element needs to be filled in correctly in ${defaultLanguageName} first.`;

type Dimension = 'width' | 'height';
type ImageAction = 'Customize or remove' | 'Remove';
const ImageDimensionMinWarning = (dimension: Dimension) =>
  `Remove the assets falling below the specified minimum ${dimension} limit before publishing.`;
const ImageDimensionMaxWarning = (dimension: Dimension, action: ImageAction) =>
  `${action} the assets exceeding the specified maximum ${dimension} limit before publishing.`;
const ImageDimensionExactlyWarning = (dimension: Dimension, action: ImageAction) =>
  `${action} the assets not equal to the specified ${dimension} limit before publishing.`;

export const ImageWidthMinWarning = ImageDimensionMinWarning('width');
export const ImageHeightMinWarning = ImageDimensionMinWarning('height');

export const ImageWidthMaxWarning = (isCustomizationEnabled: boolean) =>
  ImageDimensionMaxWarning('width', isCustomizationEnabled ? 'Customize or remove' : 'Remove');
export const ImageHeightMaxWarning = (isCustomizationEnabled: boolean) =>
  ImageDimensionMaxWarning('height', isCustomizationEnabled ? 'Customize or remove' : 'Remove');

export const ImageWidthExactlyWarning = (isCustomizationEnabled: boolean) =>
  ImageDimensionExactlyWarning('width', isCustomizationEnabled ? 'Customize or remove' : 'Remove');
export const ImageHeightExactlyWarning = (isCustomizationEnabled: boolean) =>
  ImageDimensionExactlyWarning('height', isCustomizationEnabled ? 'Customize or remove' : 'Remove');
