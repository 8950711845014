import { Box } from '@kontent-ai/component-library/Box';
import { Typography } from '@kontent-ai/component-library/tokens';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookLanguageFilterSelectors as EntityWebhookLanguageFilterSelectorsComponent } from './EntityWebhookLanguageFilterSelectors.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookLanguageFilter = ({ formProps }: Props) => {
  return (
    <>
      <Box typography={Typography.HeadlineMedium}>Filters</Box>
      <Box overflowWrap="anywhere" typography={Typography.LabelLarge}>
        Limit the events to languages that match the filtering criteria. The criteria don’t apply to
        the “Language created” event.
      </Box>
      <EntityWebhookLanguageFilterSelectorsComponent formProps={formProps} />
    </>
  );
};
