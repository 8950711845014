import { Collection } from '@kontent-ai/utils';
import useLocalStorageState from 'use-local-storage-state';
import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import { ContentStatusColumnFilterStorageKey } from '../../../../_shared/constants/localStorageKeys.ts';

const getStorageKey = (projectId: Uuid, userId: Uuid): string =>
  `${ContentStatusColumnFilterStorageKey}_${projectId}_${userId}`;

const itemColumnCodeValues = Object.values(ItemColumnCode);

const filterOutNonExistentColumnCodes = (columns: ReadonlySet<ItemColumnCode>) =>
  Collection.filter(columns, (column) => itemColumnCodeValues.includes(column));

type UseContentStatusColumnSettingsResult = Readonly<{
  hiddenColumns: ReadonlySet<ItemColumnCode>;
  setHiddenColumns: (value: ReadonlySet<ItemColumnCode>) => void;
}>;

export const useContentStatusColumnSettings = (
  userId: Uuid,
  projectId: Uuid,
): UseContentStatusColumnSettingsResult => {
  const storageKey = getStorageKey(projectId, userId);

  const [hiddenColumns, setHiddenColumns] = useLocalStorageState<ReadonlySet<ItemColumnCode>>(
    storageKey,
    {
      defaultValue: new Set<ItemColumnCode>(),
      serializer: {
        stringify: (value: ReadonlySet<ItemColumnCode>) => JSON.stringify([...value]),
        parse: (value) => filterOutNonExistentColumnCodes(new Set(JSON.parse(value))),
      },
    },
  );

  return {
    hiddenColumns,
    setHiddenColumns,
  };
};
