import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type IFilterSelectorProps<TOptionSelector extends ISelectItem<any>> = {
  readonly collection: DataUiCollection;
  readonly onFilterChanged: (newSelectedOptions: ReadonlyArray<TOptionSelector>) => void;
  readonly options: ReadonlyArray<TOptionSelector>;
  readonly placeholder: string;
  readonly selectedOptions: ReadonlyArray<TOptionSelector>;
  readonly title: string;
};

export function AuditLogFilterSelector(props: IFilterSelectorProps<any>) {
  return (
    <div className="audit-log__filter__selector">
      <MultiSelect<any>
        items={props.options}
        label={props.title}
        onSelectionChange={(itemIds) => props.onFilterChanged([...(itemIds ?? [])])}
        placeholder={props.placeholder}
        selectedItemIds={props.selectedOptions}
        {...getDataUiCollectionAttribute(props.collection)}
      />
    </div>
  );
}

AuditLogFilterSelector.displayName = 'AuditLogFilterSelector';
