import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import {
  ContentItemEditing_Refresh_Failed,
  ContentItemEditing_Refresh_Finished,
  ContentItemEditing_Refresh_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { contentItemNameChanged } from '../contentItemEditingActions.ts';
import { InitContentItemEditing } from './initContentItemEditing.ts';

interface IDeps {
  readonly initContentItemEditing: InitContentItemEditing;
}

const started = () =>
  ({
    type: ContentItemEditing_Refresh_Started,
  }) as const;

const finished = () =>
  ({
    type: ContentItemEditing_Refresh_Finished,
  }) as const;

const failed = () =>
  ({
    type: ContentItemEditing_Refresh_Failed,
  }) as const;

export type RefreshItemEditingActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createRefreshItemEditing =
  (deps: IDeps) =>
  (history: History): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant, editedContentItemVariantComments },
    } = getState();

    if (!editedContentItemVariant) {
      throw InvariantException(
        'refreshItemEditing: Cannot refresh item editing when "editedContentItemVariant" is falsy.',
      );
    }
    dispatch(started());

    try {
      await dispatch(
        deps.initContentItemEditing(
          history,
          editedContentItemVariantComments.focusedCommentThreadId,
        ),
      );

      const {
        contentApp: { editedContentItem: refreshedEditedContentItem },
      } = getState();

      if (!refreshedEditedContentItem) {
        throw InvariantException(
          'refreshItemEditing: Cannot refresh breadcrumb when "refreshedEditedContentItem" is falsy.',
        );
      }

      dispatch(
        contentItemNameChanged(refreshedEditedContentItem.name, refreshedEditedContentItem.id),
      );

      dispatch(finished());
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
