import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  Data_LanguageUsages_Started,
  Data_LanguageUsages_Success,
} from '../../../constants/dataActionTypes.ts';

const initialState: Immutable.Map<Uuid, boolean> = Immutable.Map();

export function usages(
  state: Immutable.Map<Uuid, boolean> = initialState,
  action: Action,
): Immutable.Map<Uuid, boolean> {
  switch (action.type) {
    case Data_LanguageUsages_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_LanguageUsages_Success:
      return Immutable.Map(action.payload.data);

    default:
      return state;
  }
}
