import { createGuid } from '@kontent-ai/utils';
import { MultipleChoiceMode } from '../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { IMultipleChoiceOptionData } from '../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  MultipleChoiceModeServerModel,
  MultipleChoiceOptionServerModel,
  MultipleChoiceTypeElementServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface IMultipleChoiceTypeElementData extends IBaseTypeElementData {
  readonly defaultValue: ReadonlyArray<Uuid>;
  readonly mode: MultipleChoiceMode;
  readonly options: Dictionary<IMultipleChoiceOptionData>;
  readonly optionsOrder: ReadonlyArray<Uuid>;
}

export const EmptyMultipleChoiceTypeElementData: Omit<
  IMultipleChoiceTypeElementData,
  'options' | 'optionsOrder'
> = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  defaultValue: [],
  mode: MultipleChoiceMode.Single,
  type: ElementType.MultipleChoice,
};

export const createEmptyMultipleChoiceTypeElementData = (): IMultipleChoiceTypeElementData => {
  const initialOptionId = createGuid();

  return {
    ...EmptyBaseTypeElementData,
    ...EmptyMultipleChoiceTypeElementData,
    options: Object.fromEntries([
      [
        initialOptionId,
        {
          id: initialOptionId,
          label: '',
          codename: null,
        } as IMultipleChoiceOptionData,
      ],
    ]),
    optionsOrder: [initialOptionId],
  };
};

export function getMultipleChoiceTypeElementDataFromServerModel(
  serverModel: MultipleChoiceTypeElementServerModel,
): IMultipleChoiceTypeElementData {
  const mode =
    serverModel.mode === MultipleChoiceModeServerModel.Single
      ? MultipleChoiceMode.Single
      : MultipleChoiceMode.Multiple;
  const options = serverModel.options.reduce<Mutable<Dictionary<IMultipleChoiceOptionData>>>(
    (reduced, serverItem) => {
      reduced[serverItem.id] = {
        id: serverItem.id,
        label: serverItem.name,
        codename: serverItem.codeName,
      };
      return reduced;
    },
    {},
  );
  const optionsOrder = serverModel.options.map(
    (option: MultipleChoiceOptionServerModel) => option.id,
  );

  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyMultipleChoiceTypeElementData._configurability,
    defaultValue: serverModel.default?.global.value ?? [],
    mode,
    options,
    optionsOrder,
    type: ElementType.MultipleChoice,
  };
}
