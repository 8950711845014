import { isString } from '../../../../_shared/utils/stringUtils.ts';

export enum CalendarArrangement {
  Month = 'month',
  Agenda = 'agenda',
}

export const CalendarArrangementValues: ReadonlyArray<CalendarArrangement> =
  Object.values(CalendarArrangement);

export const isCalendarArrangement = (arg: unknown): arg is CalendarArrangement =>
  isString(arg) && CalendarArrangementValues.includes(arg as CalendarArrangement);
