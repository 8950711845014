import { Direction } from '@kontent-ai/types';

// Firefox handles the DOM selection a bit differently so it needs extra check, for more information see KCL-3584
const nodeIsRestricted = (anchorNode: Node | null): boolean => {
  try {
    anchorNode?.hasChildNodes();
    return false;
  } catch {
    return true;
  }
};

export type DOMSelectionLike = Pick<
  Selection,
  'anchorNode' | 'anchorOffset' | 'focusNode' | 'focusOffset' | 'isCollapsed'
> & {
  readonly range: Range | null;
};

export const getNativeDomSelection = (): Selection | null => document.getSelection();

export const getDomSelection = (): DOMSelectionLike | null => {
  const selection = getNativeDomSelection();

  // Note: Multi-range selection is not even supported in browsers
  if (!selection || selection.rangeCount > 1 || nodeIsRestricted(selection.anchorNode)) {
    return null;
  }

  return {
    anchorNode: selection.anchorNode,
    anchorOffset: selection.anchorOffset,
    focusNode: selection.focusNode,
    focusOffset: selection.focusOffset,
    isCollapsed: selection.isCollapsed,
    range: selection.rangeCount === 1 ? selection.getRangeAt(0) : null,
  };
};

export const getSelectionDirection = (selection: DOMSelectionLike): Direction => {
  if (!selection.anchorNode || !selection.focusNode) {
    return Direction.Forward;
  }

  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);

  if (
    (!position && selection.anchorOffset > selection.focusOffset) ||
    position === Node.DOCUMENT_POSITION_PRECEDING
  ) {
    return Direction.Backward;
  }

  return Direction.Forward;
};

export const getSelectedText = (): string => {
  const selection = getNativeDomSelection();
  if (selection) {
    return selection.toString();
  }
  return '';
};

export const isSelectionCollapsed = (): boolean => {
  return !!getDomSelection()?.isCollapsed;
};

const areChildrenSelectable = (node: Node): boolean => {
  if (!(node instanceof HTMLElement)) {
    return false;
  }
  const style = window.getComputedStyle(node);
  return style.userSelect !== 'none' && style.userSelect !== 'all';
};

function getNextNode(node: Node): Node | null {
  if (node.hasChildNodes() && areChildrenSelectable(node)) {
    return node.firstChild;
  }

  let currentNode: Node | null = node;
  while (currentNode && !currentNode.nextSibling) {
    currentNode = currentNode.parentNode;
  }
  if (!currentNode) {
    return null;
  }

  return currentNode.nextSibling;
}

export function getNodesInSelection(selection: DOMSelectionLike): ReadonlyArray<Node> {
  const { range } = selection;
  if (!range) {
    return [];
  }

  const endNode = range.endContainer;
  const startNode = range.startContainer;

  // Special case for a range that is contained within a single node
  if (startNode === endNode) {
    return [startNode];
  }

  // Iterate nodes until we hit the end container
  const rangeNodes: Array<Node> = [];
  for (
    let node: Node | null = getNextNode(startNode);
    node && node !== endNode;
    node = getNextNode(node)
  ) {
    rangeNodes.push(node);
  }

  // Add partially selected nodes at the start of the range
  for (
    let node: Node | null = startNode;
    node && node !== range.commonAncestorContainer;
    node = node.parentNode
  ) {
    rangeNodes.unshift(node);
  }

  return rangeNodes;
}
