import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IEntityWebhookRepository } from '../../../../repositories/interfaces/IEntityWebhookRepository.type.ts';
import {
  Data_EntityWebhooks_Started,
  Data_EntityWebhooks_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IEntityWebhookSetting,
  createEntityWebhookSettingDomainModel,
} from '../../../models/webhooks/EntityWebhookSetting.ts';

interface IDeps {
  readonly entityWebhookRepository: IEntityWebhookRepository;
}

const start = () => ({ type: Data_EntityWebhooks_Started }) as const;

const success = (data: ReadonlyArray<IEntityWebhookSetting>) =>
  ({
    type: Data_EntityWebhooks_Success,
    payload: { data },
  }) as const;

export type LoadEntityWebhooksActionsType = ReturnType<typeof start | typeof success>;

export const createLoadEntityWebhooksAction =
  ({ entityWebhookRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    const serverModels = await entityWebhookRepository.getAll(abortSignal);
    const domainModels = serverModels.map(createEntityWebhookSettingDomainModel);

    dispatch(success(domainModels));
  };
