import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../../../_shared/models/LoadingStatusEnum.ts';
import { areCollectionsVisibleForSpaces } from '../../../../../../../_shared/selectors/contentCollections.ts';
import { getEditedContentItem } from '../../../../../../../_shared/selectors/getEditedContentItem.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { SpacesSection as SpacesSectionComponent } from '../../../components/details/Spaces/SpacesSection.tsx';

const shouldShowSpacesSection = (state: IStore): boolean =>
  state.data.collections.loadingStatus === LoadingStatus.Loaded &&
  state.data.spaces.loadingStatus === LoadingStatus.Loaded &&
  areCollectionsVisibleForSpaces(state, Collection.getValues(state.data.collections.byId));

export const SpacesSection: React.FC = () => {
  const shouldShowSpaces = useSelector(shouldShowSpacesSection);
  const currentCollectionId = useSelector((s) => getEditedContentItem(s).collectionId);

  return shouldShowSpaces && currentCollectionId && <SpacesSectionComponent />;
};

SpacesSection.displayName = 'SpacesSection';
