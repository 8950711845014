import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { memoize } from '@kontent-ai/memoization';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { ILanguage, Languages } from '../../../../../data/models/languages/Language.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../data/reducers/languages/selectors/getLanguages.ts';

const getLanguageOptionsArray = memoize.maxOne(
  (options: Languages): ReadonlyArray<IBaseSelectItem> =>
    options
      .map(
        ({ id, name }: ILanguage): IBaseSelectItem => ({
          id,
          label: name,
        }),
      )
      .toArray(),
);

export const getLanguageOptions = (
  state: IStore,
  capability: Capability,
): ReadonlyArray<IBaseSelectItem> => {
  const { user, languages } = state.data;
  const { currentProjectId } = state.sharedApp;

  const options = getAllActiveLanguagesForCurrentUserInAnyCollection(
    user,
    currentProjectId,
    languages,
    capability,
  );

  return getLanguageOptionsArray(options);
};
