import React from 'react';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { IColumnVisibility } from '../../../../contentInventory/content/features/ContentItemInventory/types/ColumnVisibility.type.ts';
import { areSpacesInCollectionsAvailable } from '../../../../contentInventory/content/selectors/areSpacesInCollectionsAvailable.ts';
import {
  ColumnSettings as ColumnSettingsComponent,
  IColumnSettingsOwnProps,
} from '../../components/ContentStatusScrollTable/ColumnSettings.tsx';
import { useContentStatusColumnSettings } from '../../hooks/useContentStatusColumnSettings.ts';
import { hasProjectMultipleActiveLanguagesForCurrentUser } from '../../selectors/hasProjectMultipleActiveLanguagesForCurrentUser.ts';

const allSelectableColumns = [
  ItemColumnCode.ContentType,
  ItemColumnCode.Collection,
  ItemColumnCode.Space,
  ItemColumnCode.Language,
  ItemColumnCode.Contributors,
  ItemColumnCode.DueDate,
  ItemColumnCode.WorkflowStep,
  ItemColumnCode.Comments,
  ItemColumnCode.Tasks,
] as const;

const getFilteredSelectableColumns = (
  selectableColumns: typeof allSelectableColumns,
  isCollectionVisible: boolean,
  isLanguageVisible: boolean,
  isSpaceVisible: boolean,
) => {
  const areAllColumnsVisible = isCollectionVisible && isLanguageVisible && isSpaceVisible;
  if (areAllColumnsVisible) {
    return selectableColumns;
  }

  const filteredColumns = [
    isCollectionVisible ? null : ItemColumnCode.Collection,
    isLanguageVisible ? null : ItemColumnCode.Language,
    isSpaceVisible ? null : ItemColumnCode.Space,
  ];

  return selectableColumns.filter((column) => !filteredColumns.includes(column));
};

export const ColumnSettings: React.FC<IColumnSettingsOwnProps> = (props) => {
  const hasUserPermissionToMultipleCollections = useSelector(areCollectionsVisibleForCurrentUser);
  const hasUserPermissionToMultipleLanguages = useSelector(
    hasProjectMultipleActiveLanguagesForCurrentUser,
  );
  const isSpaceVisible = useSelector(areSpacesInCollectionsAvailable);
  const userId = useSelector((s) => s.data.user.info.userId);
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);

  const { hiddenColumns, setHiddenColumns } = useContentStatusColumnSettings(
    userId,
    currentProjectId,
  );

  const updateColumnFilter = (columnVisibility: IColumnVisibility) => {
    const { columnCode, visible } = columnVisibility;
    const newHiddenColumns = new Set<ItemColumnCode>(hiddenColumns);
    if (visible) {
      newHiddenColumns.delete(columnCode);
    } else {
      newHiddenColumns.add(columnCode);
    }

    setHiddenColumns(newHiddenColumns);
  };

  return (
    <ColumnSettingsComponent
      source={props.source}
      hiddenColumns={hiddenColumns}
      selectableColumns={getFilteredSelectableColumns(
        allSelectableColumns,
        hasUserPermissionToMultipleCollections,
        hasUserPermissionToMultipleLanguages,
        isSpaceVisible,
      )}
      updateColumnFilter={updateColumnFilter}
    />
  );
};

ColumnSettings.displayName = 'ColumnSettings';
