import useLocalStorageState from 'use-local-storage-state';
import { ContentPaceWorkflowStageStorageKey } from '../../../../_shared/constants/localStorageKeys.ts';
import { WorkflowStage } from '../../../unifiedMissionControl/constants/workflowStage.ts';

const getStorageKey = (projectId: Uuid, userId: Uuid): string =>
  `${ContentPaceWorkflowStageStorageKey}_${projectId}_${userId}`;

type UseContentPaceWorkflowStageResult = Readonly<{
  workflowStage: WorkflowStage;
  setWorkflowStage: (value: WorkflowStage) => void;
}>;

export const useContentPaceWorkflowStage = (
  userId: Uuid,
  projectId: Uuid,
): UseContentPaceWorkflowStageResult => {
  const storageKey = getStorageKey(projectId, userId);

  const [workflowStage, setWorkflowStage] = useLocalStorageState<WorkflowStage>(storageKey, {
    defaultValue: WorkflowStage.AllItems,
  });

  return {
    workflowStage,
    setWorkflowStage,
  };
};
