import { assert } from '@kontent-ai/utils';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { INormalizedProjectUserCollectionGroupsServerModel } from '../../../repositories/serverModels/IRoleServerModel.type.ts';
import { IRoleWithSettings, getRoleWithSettingsFromServerModel } from './IRoleWithSettings.ts';

export interface INormalizedRolesWithSettings {
  readonly capabilities: ReadonlyArray<Capability>;
  readonly collectionGroups: ReadonlyArray<ICollectionGroupRoles>;
}

export interface ICollectionGroupRoles {
  readonly collectionIds: UuidArray;
  readonly roles: ReadonlyArray<ILanguageBoundRole>;
}

export interface ILanguageBoundRole {
  readonly languageIds: ReadonlyArray<Uuid>;
  readonly role: IRoleWithSettings;
}

export function getNormalizedRoleWithSettingsFromServerModel(
  serverModel: INormalizedProjectUserCollectionGroupsServerModel,
): INormalizedRolesWithSettings {
  assert(!!serverModel, () => 'INormalizedProjectUserRolesServerModel is not an object');
  assert(
    serverModel.collectionGroups?.length,
    () => 'INormalizedProjectUserRolesServerModel must contain at least one collection group',
  );

  const collectionGroups: ReadonlyArray<ICollectionGroupRoles> = (
    serverModel?.collectionGroups || []
  ).map((g) => ({
    collectionIds: g.collectionIds || [],
    roles: (g?.roles || []).map((r) => ({
      languageIds: r.languageIds || [],
      role: getRoleWithSettingsFromServerModel(r.role),
    })),
  }));

  return {
    capabilities: serverModel.capabilities || [],
    collectionGroups,
  };
}

export const defaultNormalizedRolesWithSettings: INormalizedRolesWithSettings = {
  capabilities: [],
  collectionGroups: [],
};
