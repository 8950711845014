import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { colorAlertText, colorTextDefault } from '../../../tokens/decision/colors.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import {
  DataUiCLElement,
  getDataUiCLElementAttribute,
  getDataUiComponentAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { InputState } from '../inputStateEnum.ts';
import { InputStyleProps } from './inputStyleProps.type.ts';

export const InputCaption: React.FC<InputStyleProps & HTMLAttributes<HTMLInputElement>> =
  styled.span.attrs<InputStyleProps>((props) => {
    return {
      ...getDataUiComponentAttribute(InputCaption),
      ...(props.$inputState === InputState.Alert
        ? getDataUiCLElementAttribute(DataUiCLElement.Alert)
        : {}),
    };
  })<InputStyleProps>`
  ${Typography.TitleMedium};
  display: block;
  color: ${colorTextDefault};
  text-align: left;
  word-break: break-word;

  ${({ $inputState }) =>
    $inputState === InputState.Alert &&
    css`
    color: ${colorAlertText};
  `};
`;
InputCaption.displayName = 'InputCaption';
