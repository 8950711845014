import NavigationPrompt from 'react-router-navigation-prompt';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { PendingChangesDialog } from '../../PendingChangesDialog/containers/PendingChangesDialog.tsx';
import { areAnyOperationsPending } from '../../safeRedirect/selectors/areAnyOperationsPending.ts';

const getHasUnfinishedState = (state: IStore): boolean => {
  const {
    contentApp: {
      editorUi: { editedContentItemVariantUploadingAssets },
      editedContentItemStatus: { failures: saveFailures },
      itemValidationErrors,
    },
    sharedApp: { uiBlockingMessage },
  } = state;

  const hasUnsavedAssets = editedContentItemVariantUploadingAssets.length > 0;
  const hasFailedOperations = saveFailures.size > 0;
  const hasPendingOperations = areAnyOperationsPending(state);
  const hasValidationErrors = itemValidationErrors.size > 0;
  const isUiBlockingMessageDisplayed = !!uiBlockingMessage;

  return (
    hasUnsavedAssets ||
    hasFailedOperations ||
    hasPendingOperations ||
    hasValidationErrors ||
    isUiBlockingMessageDisplayed
  );
};

export const HandlePendingNavigation = () => {
  const hasUnfinishedState = useSelector(getHasUnfinishedState);

  return (
    <NavigationPrompt when={hasUnfinishedState}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && <PendingChangesDialog onCancel={onCancel} onConfirm={onConfirm} />
      }
    </NavigationPrompt>
  );
};
