import { Action } from '../../../../../../@types/Action.type.ts';
import {
  Invite_Closed,
  Invite_SubmitInvitations_Failed,
  Invite_SubmitInvitations_Finished,
  Invite_SubmitInvitations_Started,
} from '../../../constants/usersActionTypes.ts';

export const isSaving = (state = false, action: Action): boolean => {
  switch (action.type) {
    case Invite_SubmitInvitations_Started:
      return true;
    case Invite_SubmitInvitations_Failed:
    case Invite_SubmitInvitations_Finished:
    case Invite_Closed:
      return false;
    default:
      return state;
  }
};
