import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightAssetTrackedEvent,
  TrackedEvent,
} from '../../../../../_shared/constants/trackedEvent.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { AssetPickerRecentlyUsedFolderStorage } from '../../../../../localStorages/assetPickerRecentlyUsedFolderStorage.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { assetFolderExists } from '../../utils/assetFolderExists.ts';
import { assetFolderOpened } from '../assetLibraryActions.ts';

interface IDeps {
  readonly loadAssetFolders: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadListingAssets: LoadListingAssets;
}

interface IResult {
  readonly folderOpened: boolean;
}

export type IOpenAssetFolderAction = (
  args: Args,
  abortSignal?: AbortSignal,
) => ThunkPromise<IResult>;

export type OpenAssetFolderActionsType = ReturnType<typeof assetFolderOpened>;

const saveRecentlyUsedFolder = (
  { assetLibraryApp: { isAssetSelectorOpened }, sharedApp: { currentProjectId } }: IStore,
  assetFolderId: Uuid,
) => {
  if (isAssetSelectorOpened) {
    AssetPickerRecentlyUsedFolderStorage.save(assetFolderId, currentProjectId);
  }
};

type Args = {
  readonly assetFolderId: Uuid;
  readonly orderBy: AssetsOrderBy;
};

export const createOpenAssetFolderAction =
  (deps: IDeps): IOpenAssetFolderAction =>
  ({ assetFolderId, orderBy }, abortSignal): ThunkPromise<IResult> =>
  async (dispatch, getState) => {
    const event =
      assetFolderId !== RootFolderId
        ? FindRightAssetTrackedEvent.AssetLibraryFilteredByFolder
        : FindRightAssetTrackedEvent.OpenedRoot;

    dispatch(trackUserEventWithData(TrackedEvent.FindRightAsset, { name: event }));

    await dispatch(deps.loadAssetFolders(abortSignal));

    if (!assetFolderExists(assetFolderId, getState())) {
      return { folderOpened: false };
    }

    dispatch(assetFolderOpened(assetFolderId));

    await dispatch(
      deps.loadListingAssets(
        {
          orderBy,
          requestTrigger: AssetsRequestTrigger.Search,
        },
        abortSignal,
      ),
    );

    saveRecentlyUsedFolder(getState(), assetFolderId);

    return { folderOpened: true };
  };
