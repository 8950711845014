import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import { IMultipleChoiceOptionData } from '../models/MultipleChoiceOption.type.ts';

export const getOrderedOptions = memoize.weak(
  (
    options: Dictionary<IMultipleChoiceOptionData>,
    optionsOrder: ReadonlyArray<Uuid>,
  ): ReadonlyArray<IMultipleChoiceOptionData> =>
    optionsOrder.map((optionTypeId: Uuid) => options[optionTypeId] ?? null).filter(notNull),
);

export const getOrderedOptionsList = memoize.weak(
  (
    options: Dictionary<IMultipleChoiceOptionData>,
    optionsOrder: ReadonlyArray<Uuid>,
  ): ReadonlyArray<IMultipleChoiceOptionData> => getOrderedOptions(options, optionsOrder),
);
