import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { useContext } from 'react';
import { ScrollContainerContext } from './ScrollContainerContext.tsx';

/**
 * Use this hook in situations where:
 * - You need the Tippy instance to become sticky relatively to the current scrolling container
 * - Vertically doesn't overflow the scrolling container fold
 * - Horizontally can overflow the scrolling container, but doesn't overflow the viewport
 * Receives:
 * - padding - Optional, defaults to spacingPopupDistance (padding of the Tippy from the boundary)
 * Returns an object with properties:
 * - boundaryProps - Can be used in flip modifier to use flip boundary matching the overflow boundary
 * - preventOverflowModifier - Modifier that prevents overflowing of the component positioned by Tippy from the scrolling container
 */
export const usePreventOverflowFromScrollContainer = (padding: number = spacingPopupDistance) => {
  const { tippyBoundaryRef } = useContext(ScrollContainerContext);
  const tippyBoundaryRect = tippyBoundaryRef.current?.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  const boundaryPadding = tippyBoundaryRect
    ? {
        top: tippyBoundaryRect.top - bodyRect.top + padding,
        left: padding,
        bottom: bodyRect.bottom - tippyBoundaryRect.bottom + padding,
        right: padding,
      }
    : padding;
  const boundary = document.body;

  const boundaryProps = {
    padding: boundaryPadding,
    boundary,
  } as const;

  const preventOverflowModifier = {
    name: 'preventOverflow',
    options: {
      ...boundaryProps,
      altAxis: true,
      altBoundary: true,
      mainAxis: true,
      tether: false,
    },
  } as const;

  return {
    preventOverflowModifier,
    boundaryProps,
  };
};
