import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { createAddOffset } from '../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { defaultDropdownTippyOptions } from '../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { SelectGroup } from '../../../../component-library/components/StatusBar/Selects/SelectGroup.tsx';
import { EnvironmentMenuItem, EnvironmentSelect } from './selects/EnvironmentSelect.tsx';
import { ProjectMenuItem, ProjectSelect } from './selects/ProjectSelect.tsx';
import { SpaceMenuItem, SpaceSelect } from './selects/SpaceSelect.tsx';

const selectGroupEllipsisTippyOptions = createAddOffset([0, Spacing.S])(
  defaultDropdownTippyOptions,
);

enum MenuGroupId {
  Projects = 'Projects',
  Environments = 'Environments',
  Spaces = 'Spaces',
}

type MenuSelect = {
  readonly id: MenuGroupId;
};

type Props = {
  readonly environmentId: Uuid;
  readonly environmentItems: ReadonlyArray<EnvironmentMenuItem>;
  readonly environmentName: string;
  readonly productionId: Uuid;
  readonly projectItems: ReadonlyArray<ProjectMenuItem>;
  readonly projectName: string;
  readonly shouldShowEnvironmentSelect: boolean;
  readonly shouldShowSpaceSelect: boolean;
  readonly spaceItems: ReadonlyArray<SpaceMenuItem> | null;
  readonly spaceName: string | null;
};

export const ProjectMenu = ({
  environmentId,
  environmentItems,
  environmentName,
  productionId,
  projectItems,
  projectName,
  shouldShowEnvironmentSelect,
  shouldShowSpaceSelect,
  spaceItems,
  spaceName,
}: Props) => {
  const menusToShow: ReadonlyArray<MenuSelect> = [
    {
      id: MenuGroupId.Projects,
    },
    ...(shouldShowEnvironmentSelect
      ? [
          {
            id: MenuGroupId.Environments,
          },
        ]
      : []),
    ...(shouldShowSpaceSelect
      ? [
          {
            id: MenuGroupId.Spaces,
          },
        ]
      : []),
  ];

  return (
    <Box flexGrow={0} flexShrink={1} flexBasis="auto" minWidth={0} display="flex">
      <SelectGroup
        ellipsisButtonLabel="Projects and environments"
        // Temporary solution until we replace the whole status bar to properly handle shortening of SelectGroup and Breadcrumbs.
        shorteningMode={menusToShow.length >= 3 ? 'always' : 'never'}
        pinnedSelectId={MenuGroupId.Spaces}
        renderSelect={({ id }, renderMode) => {
          switch (id) {
            case MenuGroupId.Projects:
              return (
                <ProjectSelect
                  projectItems={projectItems}
                  renderMode={renderMode}
                  selectedProjectName={projectName}
                />
              );

            case MenuGroupId.Environments:
              return (
                <EnvironmentSelect
                  environmentId={environmentId}
                  environmentItems={environmentItems}
                  productionId={productionId}
                  renderMode={renderMode}
                  selectedEnvironmentName={environmentName}
                />
              );

            case MenuGroupId.Spaces:
              return (
                <SpaceSelect
                  spaceItems={spaceItems}
                  renderMode={renderMode}
                  selectedSpaceName={spaceName}
                />
              );
          }
        }}
        selects={menusToShow}
        tippyOptions={selectGroupEllipsisTippyOptions}
      />
    </Box>
  );
};
