import React, { useMemo } from 'react';
import { getScrollGridComponent } from '../../../../_shared/components/ScrollGrid/ScrollGrid.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ImageLimitsConfig } from '../../../../_shared/utils/assets/assetValidationUtils.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { getAssetsForScrollGrid } from '../../../../data/reducers/assets/selectors/getAssetsForScrollGrid.ts';
import { assetScrollGridStateChanged } from '../actions/assetLibraryActions.ts';
import { AssetSkelet } from '../components/AssetTile/AssetSkelet.tsx';
import { isAssetLibraryVisibleQueryEmpty } from '../models/IAssetLibraryQuery.ts';
import { AssetTile, AssetTileMode, IAssetItemConfig } from './AssetTile/AssetTile.tsx';

interface IAssetListContainerProps {
  readonly isWithAnchor?: boolean;
  readonly imageLimits?: ImageLimitsConfig;
  readonly onAssetClick?: (assetId: Uuid) => void;
  readonly allowSelect: boolean;
  readonly showImagesOnly?: boolean;
}

const ScrollGridComponent = getScrollGridComponent<IAsset, IAssetItemConfig>({
  TileComponent: AssetTile,
  TileSkeletComponent: AssetSkelet,
});

export const AssetList: React.FC<IAssetListContainerProps> = (props) => {
  const dispatch = useDispatch();
  const searchPhrase = useSelector((s) => s.assetLibraryApp.query.searchPhrase);
  const assetTileMode = useSelector((s) =>
    isAssetLibraryVisibleQueryEmpty(s.assetLibraryApp.query)
      ? AssetTileMode.Default
      : AssetTileMode.Search,
  );
  const assets = useSelector((s) => getAssetsForScrollGrid(s.data.assets));
  const scrollGridState = useSelector((s) => s.assetLibraryApp.assetListingScrollGridState);

  const config = useMemo(
    () => ({
      imageLimits: props.imageLimits,
      allowSelect: props.allowSelect,
      showImagesOnly: props.showImagesOnly,
      tileMode: assetTileMode,
    }),
    [props.imageLimits, props.allowSelect, props.showImagesOnly, assetTileMode],
  );

  return (
    <ScrollGridComponent
      onItemClick={props.onAssetClick}
      onChange={(newScrollGridState) => dispatch(assetScrollGridStateChanged(newScrollGridState))}
      isWithAnchor={props.isWithAnchor}
      searchPhrase={searchPhrase}
      config={config}
      items={assets}
      scrollGridState={scrollGridState}
    />
  );
};
