import React from 'react';
import { ScrollTableCellSkeleton } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCellSkeleton.tsx';
import { ScrollTableRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';

export const SubscriptionUsersScrollTableRowSkeleton: React.FC = () => (
  <ScrollTableRow objectName="skeleton" isSkeleton>
    <ScrollTableCellSkeleton size={10} />
    <ScrollTableCellSkeleton size={10} />
    <ScrollTableCellSkeleton size={10} />
    <ScrollTableCellSkeleton size={10} />
    <ScrollTableCellSkeleton size={5} />
    <ScrollTableCellSkeleton size={4} />
  </ScrollTableRow>
);
