import { ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import {
  ColumnSettingsAction,
  ColumnSettingsSource,
} from '../../../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { IContentListingHiddenColumnsStorage } from '../../../../../../../localStorages/contentListingColumnsFilterStorage.ts';
import { IColumnVisibility } from '../../types/ColumnVisibility.type.ts';
import {
  contentListingColumnHidden,
  contentListingColumnShown,
} from '../contentItemInventoryActions.ts';

interface IDeps {
  readonly contentListingColumnsFilterStorage: IContentListingHiddenColumnsStorage;
}

export const createUpdateColumnFilterAction =
  (deps: IDeps) =>
  (columnVisibility: IColumnVisibility, source: ColumnSettingsSource): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const {
      data: {
        user: {
          info: { userId },
        },
      },
      sharedApp: { currentProjectId },
    } = state;

    deps.contentListingColumnsFilterStorage.save(columnVisibility, currentProjectId, userId);

    const action = columnVisibility.visible
      ? contentListingColumnShown
      : contentListingColumnHidden;
    dispatch(action(columnVisibility.columnCode));

    dispatch(
      trackUserEventWithData(TrackedEvent.ColumnSettings, {
        action: columnVisibility.visible ? ColumnSettingsAction.Show : ColumnSettingsAction.Hide,
        column: columnVisibility.columnCode,
        source,
      }),
    );
  };
