import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_CreateSubscription_Failed,
  Subscription_CreateSubscription_Finished,
  Subscription_CreateSubscription_ShowModal,
  Subscription_CreateSubscription_Started,
} from '../contants/actionTypes.ts';

export const creatingNewSubscriptionErrorMessage = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Subscription_CreateSubscription_Failed:
      return action.payload.error;

    case Subscription_CreateSubscription_ShowModal:
    case Subscription_CreateSubscription_Started:
    case Subscription_CreateSubscription_Finished:
      return '';

    default:
      return state;
  }
};
