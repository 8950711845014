import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ApiKeyDetail_Init_Finished,
  ApiKeyDetail_Init_Started,
  ApiKeyDetail_Loading_Failed,
  NewApiKeyDetail_Loading_Finished,
  NewApiKeyDetail_Loading_Started,
} from '../../constants/apiKeysActionTypes.ts';

export const detailLoadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case ApiKeyDetail_Init_Started: {
      // we don't want to show Loader when a key with the same Id is being opened,
      // because it causes unwanted Loader after new key is created
      const isTheSameKeyLoaded =
        action.payload.loadedApiKey.tokenSeedId === action.payload.tokenSeedId;

      return isTheSameKeyLoaded ? state : LoadingStatus.Loading;
    }

    case NewApiKeyDetail_Loading_Started:
      return LoadingStatus.Loading;

    case ApiKeyDetail_Loading_Failed:
    case ApiKeyDetail_Init_Finished:
    case NewApiKeyDetail_Loading_Finished:
      return LoadingStatus.Loaded;
    default:
      return state;
  }
};
