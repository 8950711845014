import { ContentBlock } from 'draft-js';
import React from 'react';
import { UseOriginalItemElements } from '../../../../../itemEditor/features/ContentComponent/context/ItemElementsContext.tsx';
import { DiffType } from '../../../../../itemEditor/features/Revisions/utils/DiffType.ts';
import { getDiffType } from '../../../diff/api/editorDiffUtils.ts';
import { ExpandedContentComponentBlock } from './ExpandedContentComponentBlock.tsx';

interface IExpandedContentComponentBlocksProps {
  readonly contentComponentBlocks: ContentBlock[];
  readonly editorId: string;
  readonly highlightedBlockKeys: ReadonlySet<string>;
}

export const ExpandedContentComponentBlocks: React.FC<IExpandedContentComponentBlocksProps> = ({
  contentComponentBlocks,
  editorId,
  highlightedBlockKeys,
}) => {
  return (
    <div>
      {contentComponentBlocks.map((block: ContentBlock) => {
        const blockKey = block.getKey();
        const isHighlighted = highlightedBlockKeys.has(blockKey);

        const contentComponentBlock = (
          <ExpandedContentComponentBlock
            block={block}
            editorId={editorId}
            isHighlighted={isHighlighted}
            key={blockKey}
          />
        );

        // We use only original data in case the content component is removed. Normally it doesn't matter but in case it is moved (removed + added)
        // both instances display, and we want to avoid displaying of nested changes (which can be also adding text etc.) in the removed instance
        const useOnlyOriginalData = getDiffType(block) === DiffType.Removed;
        return useOnlyOriginalData ? (
          <UseOriginalItemElements key={blockKey}>{contentComponentBlock}</UseOriginalItemElements>
        ) : (
          contentComponentBlock
        );
      })}
    </div>
  );
};

ExpandedContentComponentBlocks.displayName = 'ExpandedContentComponentBlocks';
