import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getCurrentUserRoleForCollectionForLanguageOrThrow } from '../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { IWorkflowRepository } from '../../../../../../repositories/interfaces/IWorkflowRepository.type.ts';
import {
  Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Started,
  Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Success,
} from '../../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';

interface IDeps {
  readonly workflowRepository: Pick<IWorkflowRepository, 'getFirstStepRoleCanWorkWith'>;
}

const start = () =>
  ({ type: Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Started }) as const;

const success = (stepId: Uuid) =>
  ({
    type: Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Success,
    payload: {
      stepId,
    },
  }) as const;

export type LoadFirstWorkflowStepCurrentRoleCanWorkWithActionsType = ReturnType<
  typeof start | typeof success
>;

export type ILoadFirstWorkflowStepCurrentRoleCanWorkWithAction = (
  languageId: Uuid,
  collectionId: Uuid,
  workflowId: Uuid,
  abortSignal?: AbortSignal,
) => ThunkPromise;

export const createLoadFirstWorkflowStepCurrentRoleCanWorkWithAction =
  ({ workflowRepository }: IDeps): ILoadFirstWorkflowStepCurrentRoleCanWorkWithAction =>
  (languageId, collectionId, workflowId, abortSignal) =>
  async (dispatch, getState) => {
    dispatch(start());

    const state = getState();
    const currentUserRoleId = getCurrentUserRoleForCollectionForLanguageOrThrow(
      state,
      collectionId,
      languageId,
    ).id;

    const workflowStepId = await workflowRepository.getFirstStepRoleCanWorkWith(
      currentUserRoleId,
      workflowId,
      abortSignal,
    );

    dispatch(success(workflowStepId));
  };
