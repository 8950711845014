import Immutable from 'immutable';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { InitialSelectedContentTypeData } from '../actions/thunks/prepareNewContentItemDialog.ts';
import { ContentTypeSection } from '../constants/contentTypeSection.ts';
import { filterContentTypesByIds } from './filterContentTypesByIds.ts';
import { ContentTypeSelectorState } from './getContentTypeSelectorState.ts';

export const getPreselectedContentType = (
  availableContentTypes: Immutable.List<IContentType>,
  allowedContentTypeIds: Immutable.Set<Uuid> | null,
  suggestedContentTypeIds: ReadonlyArray<Uuid>,
  contentTypeSelectorState: ContentTypeSelectorState,
): InitialSelectedContentTypeData => {
  const allowedContentTypes = filterContentTypesByIds(availableContentTypes, allowedContentTypeIds);
  const firstRestrictedContentTypeId = allowedContentTypes.first()?.id;

  if (allowedContentTypes.size === 1 && firstRestrictedContentTypeId) {
    return {
      contentTypeId: firstRestrictedContentTypeId,
      contentTypeSection: ContentTypeSection.AcceptedTypes,
    };
  }

  const firstContentTypeId = availableContentTypes.first()?.id;

  if (availableContentTypes.size === 1 && firstContentTypeId) {
    const isWithoutSection = contentTypeSelectorState === ContentTypeSelectorState.ShowAllOnly;

    return {
      contentTypeId: firstContentTypeId,
      contentTypeSection: allowedContentTypes.find(
        ({ id }: IContentType) => id === firstContentTypeId,
      )
        ? ContentTypeSection.AcceptedTypes
        : isWithoutSection
          ? ContentTypeSection.None
          : ContentTypeSection.AllTypes,
    };
  }

  const suggestedContentTypes = filterContentTypesByIds(
    availableContentTypes,
    Immutable.Set(suggestedContentTypeIds),
  );
  const firstSuggestedContentTypeId = suggestedContentTypes.first()?.id;

  if (suggestedContentTypes.size === 1 && firstSuggestedContentTypeId) {
    return {
      contentTypeId: firstSuggestedContentTypeId,
      contentTypeSection: ContentTypeSection.SuggestedTypes,
    };
  }

  return {
    contentTypeId: null,
    contentTypeSection: ContentTypeSection.None,
  };
};
