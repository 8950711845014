import { isMacOS } from '../utils/agentTypeDetection.ts';

// These serve as a textual representation of shortcuts used in the app

export enum ShortcutSymbols {
  Escape = 'Esc',
  Enter = 'Enter',
}

export const ControlShortcutTemplate = (param: string | number) =>
  `${isMacOS() ? '⌘' : 'Ctrl'}+${param}`;

export const ControlAltShortcutTemplate = (param: string | number) =>
  `${isMacOS() ? '⌥+⌘' : 'Ctrl+Alt'}+${param}`;

export const ControlShiftShortcutTemplate = (param: string | number) =>
  `${isMacOS() ? '⌘' : 'Ctrl'}+Shift+${param}`;
