import { createGuid } from '@kontent-ai/utils';
import {
  DefaultCollectionId,
  DefaultWorkflowId,
} from '../../../../../_shared/constants/variantIdValues.ts';
import { emptyWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { IVariantWorkflowStepServerModel } from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';
import { UnchangedUnpublishedItemsServerModel } from '../../../../unifiedMissionControl/repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import {
  emptyListingContentItem,
  emptyVariant,
} from '../../shared/constants/emptyDemoServerModels.ts';

export const createDemoUnchangedUnpublishedItem = (
  itemName: string,
  variantId: Uuid,
  workflowStep: IVariantWorkflowStepServerModel,
  statusEnteredAt: Date,
  assignee: UserId,
): UnchangedUnpublishedItemsServerModel => {
  return {
    itemWithVariant: {
      ...emptyListingContentItem,
      item: {
        ...emptyListingContentItem.item,
        id: createGuid(),
        name: itemName,
        collectionId: DefaultCollectionId,
      },
      variant: {
        ...emptyVariant,
        id: { itemId: createGuid(), variantId },
        actualWorkflowStatus: {
          ...emptyWorkflowStep,
          ...workflowStep,
        },
        assignment: {
          ...emptyVariant.assignment,
          workflowStatus: {
            ...emptyWorkflowStep,
            ...workflowStep,
          },
          assignees: [
            {
              userId: assignee,
            },
          ],
        },
      },
    },
    lastEnteredWorkflowStep: {
      workflowId: DefaultWorkflowId,
      workflowStatusId: workflowStep._id,
      statusEnteredAt: statusEnteredAt.toISOString(),
    },
  };
};
