import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { IRichTextTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import { RichTextDiff } from '../../../../../richText/editors/richText/RichTextDiff.tsx';
import { IRichTextItemElement } from '../../../../models/contentItemElements/RichTextItemElement.ts';
import { setRichTextElementClipboard } from '../../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { ItemElement } from '../../../ContentItemEditing/containers/elements/ItemElement.tsx';
import { useItemElementReference } from '../../../ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { RichTextItemElementContextProvider } from '../../../ContentItemEditing/context/RichTextItemElementContext.tsx';
import { ReadonlyEmptyElementPlaceholder } from '../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { IRevisionItemElementProps } from '../../utils/itemElementRevisionComponentCreator.ts';

type RichStringProps = IRevisionItemElementProps<IRichTextItemElement, IRichTextTypeElement>;

export const RichString: React.FC<RichStringProps> = ({
  elementData,
  hideValidationStatus,
  originalElementData,
  typeElement,
  revisionElementModifiedBy,
}) => {
  const element = useItemElementReference(typeElement);
  const dispatch = useDispatch();
  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) => {
      if (element) {
        dispatch(setRichTextElementClipboard(e, editorState, element));
      }
    },
    [element],
  );

  return (
    <RichTextItemElementContextProvider rootRichTextElementId={typeElement.elementId}>
      <ItemElement
        disabled
        hideValidationStatus={hideValidationStatus}
        isDisplayOnly
        typeElement={typeElement}
        revisionChangedBy={revisionElementModifiedBy}
      >
        <RichTextDiff
          className="rte--in-content-item-element"
          editorState={elementData._editorState}
          originalContent={originalElementData?._editorState.getCurrentContent()}
          placeholder={
            originalElementData ? undefined : ReadonlyEmptyElementPlaceholder.StringElement
          }
          setRichTextClipboard={onSetRichTextClipboard}
          spellCheck
        />
      </ItemElement>
    </RichTextItemElementContextProvider>
  );
};

RichString.displayName = 'RichString (Revisions)';
