import { useCallback, useEffect, useRef, useState } from 'react';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { InnovationLabActivationState } from '../components/InnovationLabFeatureStatus.tsx';
import { InnovationLabFeatureInfo } from '../types/InnovationLabFeatureSet.type.ts';
import { isInnovationLabFeatureAvailable } from '../utils/innovationLabFeatureStatusUtils.ts';

type InnovationLabFeatureDialogType = 'activation' | 'deactivation' | 'none';

const resolvedPromise = Promise.resolve();

type FeatureActivationDialogProps = {
  readonly dialogDisplayed: InnovationLabFeatureDialogType;
  readonly isAsyncOperationInProgress: boolean;
  /**
   * The card actions can be sometimes async. But "sometimes" async is bad to work with,
   * so we always return a promise.
   */
  readonly onCardActionButtonClick?: () => Promise<void>;
  readonly onDialogActionClick?: () => Promise<void>;
  readonly onDialogClose?: () => void;
};

export const useFeatureActivationDialog = (
  featureInfo: InnovationLabFeatureInfo,
  activationState: InnovationLabActivationState,
  activateThunk: ThunkPromise,
  deactivateThunk: ThunkPromise,
): FeatureActivationDialogProps => {
  const featureState = featureInfo.status;
  const isFeatureAvailable = isInnovationLabFeatureAvailable(featureState);
  const [dialogDisplayed, setDialogDisplayed] = useState<'activation' | 'deactivation' | 'none'>(
    'none',
  );
  const [isAsyncOperationInProgress, setIsAsyncOperationInProgress] = useState<boolean>(false);
  const dispatch = useDispatch();
  const closeDialog = useCallback(() => setDialogDisplayed('none'), []);
  const activateThunkRef = useRef(activateThunk);
  const deactivateThunkRef = useRef(deactivateThunk);

  useEffect(() => {
    activateThunkRef.current = activateThunk;
  }, [activateThunk]);

  useEffect(() => {
    deactivateThunkRef.current = deactivateThunk;
  }, [deactivateThunk]);

  const activateFeature = useCallback(async () => {
    setIsAsyncOperationInProgress(true);
    if (activateThunkRef.current) {
      await dispatch(activateThunkRef.current);
    }
    setIsAsyncOperationInProgress(false);
    setDialogDisplayed('none');
  }, []);

  const showActivationDialog = useCallback(() => {
    setDialogDisplayed('activation');
    return resolvedPromise;
  }, []);

  const deactivateFeature = useCallback(async () => {
    setIsAsyncOperationInProgress(true);
    if (deactivateThunkRef.current) {
      await dispatch(deactivateThunkRef.current);
    }
    setIsAsyncOperationInProgress(false);
    setDialogDisplayed('none');
  }, []);

  const showDeactivationDialog = useCallback(() => {
    setDialogDisplayed('deactivation');
    return resolvedPromise;
  }, []);

  if (!isFeatureAvailable && activationState === 'inactive') {
    return {
      dialogDisplayed: 'none',
      isAsyncOperationInProgress,
    };
  }

  if (activationState === 'active') {
    return {
      dialogDisplayed,
      isAsyncOperationInProgress,
      onCardActionButtonClick: isFeatureAvailable ? deactivateFeature : showDeactivationDialog,
      onDialogActionClick: deactivateFeature,
      onDialogClose:
        dialogDisplayed !== 'none' && !isAsyncOperationInProgress ? closeDialog : undefined,
    };
  }

  if (activationState === 'inactive') {
    return {
      dialogDisplayed,
      isAsyncOperationInProgress,
      onCardActionButtonClick: showActivationDialog,
      onDialogActionClick: activateFeature,
      onDialogClose:
        dialogDisplayed !== 'none' && !isAsyncOperationInProgress ? closeDialog : undefined,
    };
  }

  return {
    dialogDisplayed: 'none',
    isAsyncOperationInProgress,
  };
};
