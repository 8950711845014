import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_Listing_Initialized,
  Collections_Listing_Left,
} from '../../constants/actionTypes.ts';

const emptyState = false;

export const isInitialized = (state = emptyState, action: Action): boolean => {
  switch (action.type) {
    case Collections_Listing_Initialized:
      return true;
    case Collections_Listing_Left:
      return false;
    default:
      return state;
  }
};
