import { Action } from '../../../../../../@types/Action.type.ts';
import {
  ContentListing_BulkActionCheck_Loaded,
  ContentListing_BulkActionCheck_Loading,
} from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { IItemListingBulkActionsCheck } from '../../../models/ItemListingBulkActionStatus.ts';

export const bulkActionCheckResult = (
  state: IItemListingBulkActionsCheck | null = null,
  action: Action,
): IItemListingBulkActionsCheck | null => {
  switch (action.type) {
    case ContentListing_BulkActionCheck_Loaded:
      return action.payload.operationStatuses;

    case ContentListing_BulkActionCheck_Loading:
      return null;

    default:
      return state;
  }
};
