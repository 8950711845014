import { Workflow, WorkflowScope } from '../../../../data/models/workflow/Workflow.ts';
import { getWorkflowsWithUnlimitedScope } from './getWorkflowsWithUnlimitedScope.ts';

type WorkflowsByLimitations = {
  workflowsWithValidScope: readonly Workflow[];
  workflowsWithUnlimitedScope: readonly Workflow[];
};

const isUsableForTypeInCollection = (
  scope: WorkflowScope,
  collectionId: Uuid,
  contentTypeId: Uuid,
): boolean =>
  (scope.contentTypes.has(contentTypeId) && scope.collections.has(collectionId)) ||
  (scope.contentTypes.size === 0 && scope.collections.has(collectionId)) ||
  (scope.contentTypes.has(contentTypeId) && scope.collections.size === 0);

const getWorkflowsForTypeAndCollection = (
  workflows: readonly Workflow[],
  collectionId: Uuid,
  contentTypeId: Uuid,
): readonly Workflow[] =>
  workflows.filter((workflow) =>
    workflow.scopes.some((scope) =>
      isUsableForTypeInCollection(scope, collectionId, contentTypeId),
    ),
  );

export const splitWorkflowsByLimitations = (
  workflows: readonly Workflow[],
  collectionId: Uuid,
  contentTypeId: Uuid,
): WorkflowsByLimitations => ({
  workflowsWithValidScope: getWorkflowsForTypeAndCollection(workflows, collectionId, contentTypeId),
  workflowsWithUnlimitedScope: getWorkflowsWithUnlimitedScope(workflows),
});
