import { logError } from '../../../../../_shared/utils/logError.ts';
import { getListingContentItemFromJS } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { UnchangedUnpublishedItemsServerModel } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { isListingContentItemWithRequiredVariant } from '../../shared/utils/isListingContentItemWithRequiredVariant.ts';
import { UnchangedUnpublishedItem } from '../types/UnchangedUnpublishedItem.type.ts';

export const createUnchangedUnpublishedItemFromServerModel = (
  model: UnchangedUnpublishedItemsServerModel,
): UnchangedUnpublishedItem | undefined => {
  const itemWithVariant = getListingContentItemFromJS(model.itemWithVariant);
  if (!isListingContentItemWithRequiredVariant(itemWithVariant)) {
    logError('UnchangedUnpublished item doesn’t have defined item variant.');
    return undefined;
  }

  return {
    itemWithVariant,
    lastEnteredWorkflowStep: new Date(model.lastEnteredWorkflowStep.statusEnteredAt),
  };
};
