import { Size } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { ExpandCollapseAnimation } from '../ExpandCollapseAnimation/ExpandCollapseAnimation.tsx';

type Props = {
  readonly estimatedMaxHeightWhenExpanded: number;
  readonly renderCollapsed: () => ReactNode;
  readonly renderExpanded: () => ReactNode;
  readonly shouldBeExpanded: boolean;
};

const collapsedBarItemHeight = Size.L;

export const BarItemAnimation = ({
  estimatedMaxHeightWhenExpanded,
  renderCollapsed,
  renderExpanded,
  shouldBeExpanded,
}: Props) => (
  <ExpandCollapseAnimation
    estimatedMaxHeightWhenExpanded={estimatedMaxHeightWhenExpanded}
    heightWhenCollapsed={collapsedBarItemHeight}
    renderCollapsed={renderCollapsed}
    renderExpanded={renderExpanded}
    shouldBeExpanded={shouldBeExpanded}
  />
);
