import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import React from 'react';

export const RoleBuilderHeaders: React.FC = () => (
  <div className="role-builder__headers">
    <Label className="role-builder__header-item" size={LabelSize.L}>
      Role
    </Label>
    <Label className="role-builder__header-item" size={LabelSize.L}>
      Language
    </Label>
  </div>
);

RoleBuilderHeaders.displayName = 'RoleBuilderHeaders';
