import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import { ILoadSubscriptionUsage } from '../../../shared/types/ILoadSubscriptionUsage.type.ts';

interface IDependencies {
  readonly loadSubscriptionUsage: ILoadSubscriptionUsage;
  readonly loadSubscriptionUsers: (subscriptionId: Uuid) => ThunkPromise;
  readonly peopleRepository: Pick<
    IPeopleRepository,
    'deactivateContributorsOnSubscriptionProjects'
  >;
}

export const createDeactivateUsersOnSubscriptionProjectsAction =
  (deps: IDependencies) =>
  (userIds: Immutable.Set<UserId>, subscriptionId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    await deps.peopleRepository.deactivateContributorsOnSubscriptionProjects(
      userIds,
      subscriptionId,
    );

    dispatch(deps.loadSubscriptionUsers(subscriptionId));
    dispatch(deps.loadSubscriptionUsage(subscriptionId));
  };
