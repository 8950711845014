export const maxNumberOfContentTypeUsage = 99;

export const limitationsCountPlaceholder = 'e.g., 3';
export const limitationsWidthPlaceholder = 'e.g., 1024';
export const limitationsHeightPlaceholder = 'e.g., 768';

export const untitledMultipleChoiceOptionName = 'Untitled option';

export const insufficientPermissionsForLinkedItemsDefaultValue =
  'You need permission to view content items in the default language so that you can select items.';
export const linkedItemsDefaultValueDescription = 'Set default value by selecting content items.';
export const linkedItemsDefaultValueDescriptionForMultilingualProjects = `${linkedItemsDefaultValueDescription} Based on the language your content creators work with, they’ll get localized variants of the selected items as the element’s default value.`;
