import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  PreviewConfiguration_FormChanged,
  PreviewConfiguration_Loading_Finished,
  PreviewConfiguration_Saving_Finished,
} from '../../constants/previewConfigurationActionTypes.ts';
import { IPreviewConfiguration } from '../../models/PreviewConfiguration.type.ts';

const initialState: IPreviewConfiguration | null = null;

export function editedPreviewConfiguration(
  state = initialState,
  action: Action,
): IPreviewConfiguration | null {
  switch (action.type) {
    case PreviewConfiguration_Loading_Finished:
    case PreviewConfiguration_Saving_Finished:
    case PreviewConfiguration_FormChanged:
      return action.payload.previewConfiguration;

    case Shared_CurrentProjectId_Updated:
      return null;

    default:
      return state;
  }
}
