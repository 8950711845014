import { isArray } from '@kontent-ai/utils';
import { isString } from './stringUtils.ts';

export const parseJsonSafe = (value: string): unknown => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};

export const parseStringArray = (value: string): ReadonlyArray<string> => {
  const json = parseJsonSafe(value);
  return isArray(json) ? json.filter(isString) : [];
};

/**
 * Parses the JSON from the value and filters allowed values.
 * JSON values are never returned, instead, they are mapped to allowed values.
 * @param value Raw, expected-to-be JSON value.
 * @param allowedValues Strings to which the JSON values will be projected.
 * @param isParsedItemMatchingAllowedItem Matching strategy.
 */
export const parseArrayOfSpecificStrings = <T extends string>(
  value: string,
  allowedValues: ReadonlyArray<T>,
  isParsedItemMatchingAllowedItem: (parsedItem: string, allowedItem: string) => boolean,
): ReadonlyArray<T> => {
  const parsedItems = parseStringArray(value);

  return allowedValues.filter((allowedItem) =>
    parsedItems.find((parsedItem) => isParsedItemMatchingAllowedItem(parsedItem, allowedItem)),
  );
};

export const matchingStrategies = {
  caseInsensitive: (a: string, b: string) => a.toLowerCase() === b.toLowerCase(),
} as const;
