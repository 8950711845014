import {
  BreadcrumbsOriginChanged,
  LocalizedRouteEntered,
  LocalizedRouteLeft,
} from '../constants/contentInventoryActionTypes.ts';
import { BreadcrumbsOriginItem } from '../reducers/IContentInventoryStoreState.type.ts';

export const localizedRouteEntered = (variantId: Uuid) =>
  ({
    type: LocalizedRouteEntered,
    payload: { selectedLanguageId: variantId },
  }) as const;

export const localizedRouteLeft = () => ({ type: LocalizedRouteLeft }) as const;

export const contentItemsBreadcrumbsOriginChanged = (...items: readonly BreadcrumbsOriginItem[]) =>
  ({
    type: BreadcrumbsOriginChanged,
    payload: {
      items,
    },
  }) as const;
