import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import {
  EditContentTypeSnippetRoute,
  EditContentTypeSnippetRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createContentTypeSnippet } from '../../actions/thunkSnippetsActions.ts';

export const ContentTypeSnippetCreatorToolbarActions = () => {
  const dispatch = useDispatch();
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const codename = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet.codename,
  );
  const isBeingSaved = useSelector(
    (s) => s.contentModelsApp.snippets.editor.contentTypeSnippetIsBeingSaved,
  );
  const isLoading = useSelector(
    (s) =>
      s.contentModelsApp.snippets.editor.contentTypeSnippetEditingLoadingStatus !==
      LoadingStatus.Loaded,
  );

  const history = useHistory();
  const onSave = useCallback(async (): Promise<void> => {
    try {
      const createdContentType = await dispatch(createContentTypeSnippet());
      history.push(
        buildPath<EditContentTypeSnippetRouteParams>(EditContentTypeSnippetRoute, {
          projectId,
          contentTypeSnippetId: createdContentType.id,
        }),
      );
    } catch {
      return;
    }
  }, [history, projectId]);

  return (
    <StatusBarActions
      codename={codename}
      isBeingSaved={isBeingSaved}
      isLoading={isLoading}
      onSave={onSave}
    />
  );
};
