import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { modalWithPropertiesOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingEventTypes,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';

export const openChangeWorkflowDialog =
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch) => {
    dispatch(
      modalWithPropertiesOpened({
        dialogType: ModalDialogType.ChangeWorkflowDialog,
        actionOrigin,
      }),
    );

    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.ChangeWorkflowSelected,
        origin: actionOrigin,
      }),
    );
  };
