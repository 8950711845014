import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentComponentActionType } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { ContentItemEditing_ContentComponent_Collapsed } from '../../constants/contentItemEditingActionTypes.ts';
import { getChildContentComponentIdsFromElements } from '../../utils/contentComponentUtils.ts';

const contentComponentCollapsed = (contentComponentId: Uuid) =>
  ({
    type: ContentItemEditing_ContentComponent_Collapsed,
    payload: { contentComponentId },
  }) as const;

export type CollapseContentComponentActionsType = ReturnType<typeof contentComponentCollapsed>;

interface IDeps {
  readonly blurCommentThread: () => ThunkFunction;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createCollapseContentComponentAction =
  (deps: IDeps) =>
  (contentComponentId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const {
      editedContentItemVariant,
      editedContentItemVariantComments,
      editedContentItemVariantElements,
    } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw new Error('This is available only in edited content item');
    }

    const focusedThread = editedContentItemVariantComments.commentThreads.find(
      (ct: ICommentThread) => ct.id === editedContentItemVariantComments.focusedCommentThreadId,
    );

    if (focusedThread && focusedThread.contentComponentId !== null) {
      const collapsedContentComponentIds = getChildContentComponentIdsFromElements(
        editedContentItemVariantElements,
        contentComponentId,
      );

      if (collapsedContentComponentIds.contains(focusedThread.contentComponentId)) {
        dispatch(deps.blurCommentThread());
      }
    }

    dispatch(contentComponentCollapsed(contentComponentId));
    dispatch(
      deps.trackUserEventWithData(TrackedEvent.ContentComponentCollapse, {
        action: ContentComponentActionType.CollapseOne,
        'item-id': editedContentItemVariant.id.itemId,
        'variant-id': editedContentItemVariant.id.variantId,
        'content-component-id': contentComponentId,
      }),
    );
  };
