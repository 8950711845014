import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import Immutable from 'immutable';
import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableCell } from '../../../../_shared/components/DataTable/DataTableCell.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  DataUiAppName,
  DataUiCollection,
  DataUiElement,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDateString } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { ISubscriptionTransaction } from '../models/SubscriptionTransaction.ts';

const transactionsColumns = Immutable.List.of<Column>(
  {
    columnName: 'Date',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--3',
  },
  {
    columnName: 'Billing period',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Type',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Amount (USD)',
    className: classNames('transactions__amount', 'data-table__column--3'),
    orderBy: OrderByDirection.None,
  },
);

type Props = {
  readonly balance: number;
  readonly endAt: DateTimeStamp;
  readonly isPrepaid: boolean;
  readonly transactions: ReadonlyArray<ISubscriptionTransaction>;
};

export const Transactions: React.FC<Props> = ({ balance, endAt, isPrepaid, transactions }) => {
  const getContent = () => {
    if (!transactions.length) {
      return;
    }

    return transactions.map((transaction, index: number) => {
      const { transactionTime, billingPeriodStart, billingPeriodEnd, description, amount } =
        transaction;

      const billingPeriod = getBillingPeriodColumn(billingPeriodStart, billingPeriodEnd);

      return (
        <DataTableRow key={index} id={index.toString()} dataUiObjectName={index.toString()}>
          <DataTableCell>{renderDateString(transactionTime)}</DataTableCell>
          <DataTableCell title={billingPeriod}>{billingPeriod}</DataTableCell>
          <DataTableCell title={description}>{description}</DataTableCell>
          <DataTableCell
            className={classNames('transactions__item-value', {
              'transactions__value--negative': transaction.amount < 0,
            })}
          >
            {formatAmount(amount)}
          </DataTableCell>
        </DataTableRow>
      );
    });
  };

  return (
    <div
      className="canvas__content-pane canvas__content-pane--is-not-project-specific subscription-pane"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionTransactions)}
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Transactions} />
      <div className="canvas__inner-section--restricted-width">
        <PageTitle css={`margin-bottom: ${Spacing.XL}px`}>
          {SubscriptionTabName.Transactions}
        </PageTitle>
        <section
          className="transactions__summary"
          {...getDataUiElementAttribute(DataUiElement.TransactionSummary)}
        >
          {isPrepaid && (
            <div className="transactions__period-container">
              <div className="transactions__summary-title">Prepaid until</div>
              <div className="transactions__summary-content">{renderDateString(endAt)}</div>
            </div>
          )}
          <div className="transactions__balance-container">
            <div className="transactions__balance-title">Available balance</div>
            <div
              className={classNames('transactions__summary-balance-content', {
                'transactions__value--negative': balance < 0,
              })}
            >
              {formatAmount(balance)}
            </div>
          </div>
        </section>
        <DataTable
          dataUiCollectionName={DataUiCollection.Transactions}
          title={
            <ListingMessage
              statusInfoMessage={{
                text: 'Transaction history',
                style: StatusMessageStyle.Normal,
              }}
            />
          }
          header={<DataTableHeadRow columns={transactionsColumns} />}
          infoMessage={
            transactions.length ? undefined : (
              <EmptyState>
                <EmptyState.Title>No transactions yet.</EmptyState.Title>
                <EmptyState.Content>
                  Changes to your available balance will show right here.
                </EmptyState.Content>
              </EmptyState>
            )
          }
        >
          {getContent()}
        </DataTable>
      </div>
    </div>
  );
};

Transactions.displayName = 'Transactions';

const formatAmount = (balance: number): string =>
  `${balance < 0 ? '- ' : ''} $${Math.abs(balance).toLocaleString('en-US')}`;

const getBillingPeriodColumn = (billingPeriodStart: string, billingPeriodEnd: string): string => {
  if (!billingPeriodStart && !billingPeriodEnd) {
    return '—';
  }

  return `${renderDateString(billingPeriodStart)} - ${renderDateString(billingPeriodEnd)}`;
};
