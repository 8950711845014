import { useMemo } from 'react';
import { ElementReference } from '../../../../applications/itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { ElementOperationTrackingData } from '../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import {
  TrackFinishedAction,
  TrackFollowingAction,
  TrackStartingAction,
  useAiActionTracking,
  useTrackingMethodWithContext,
} from './useAiActionTracking.ts';
import { ResetAiSessionId, useAiSessionId } from './useAiSessionId.ts';

// Use for AI actions that can only run one operation at time, using useAiAction
// Use more general useAiActionTracking for multiple AI simultaneous operations triggered from a single component
export const useAiActionTrackingWithSession = (
  element: ElementReference,
): {
  readonly aiSessionId: Uuid;
  readonly elementOperationTrackingData: ElementOperationTrackingData;
  readonly resetAiSessionId: ResetAiSessionId;
  readonly trackFinishedAction: TrackFinishedAction;
  readonly trackFollowingAction: TrackFollowingAction;
  readonly trackStartingAction: TrackStartingAction;
} => {
  const { aiSessionId, resetAiSessionId } = useAiSessionId();

  const {
    getElementOperationTrackingData,
    trackFinishedAction,
    trackFollowingAction,
    trackStartingAction,
  } = useAiActionTracking(element);

  const elementOperationTrackingData: ElementOperationTrackingData = useMemo(
    () => getElementOperationTrackingData(aiSessionId),
    [aiSessionId, getElementOperationTrackingData],
  );

  const sessionContext = useMemo(() => ({ aiSessionId }), [aiSessionId]);

  return {
    aiSessionId,
    elementOperationTrackingData,
    resetAiSessionId,
    trackFinishedAction: useTrackingMethodWithContext(trackFinishedAction, sessionContext),
    trackFollowingAction: useTrackingMethodWithContext(trackFollowingAction, sessionContext),
    trackStartingAction: useTrackingMethodWithContext(trackStartingAction, sessionContext),
  };
};
