import React from 'react';
import {
  FriendlyWarningReason,
  IFriendlyWarning,
} from '../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { IWorkflowStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { Warning } from '../../../../components/infos/Warning.tsx';
import { ModalSection } from '../ModalSection.tsx';
import { FriendlyWarningMessageForMultipleUsersEditing } from './FriendlyWarningMessageForMultipleUsersEditing.tsx';

interface IUpdatingWorkflowFriendlyWarningSectionOwnProps {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarningReasons: ReadonlyArray<IFriendlyWarning> | undefined;
  readonly selectedWorkflowStep: IWorkflowStep;
}

export const UpdatingWorkflowFriendlyWarningSection: React.FC<
  IUpdatingWorkflowFriendlyWarningSectionOwnProps
> = ({ activeUsersNames, friendlyWarningReasons, selectedWorkflowStep }) => {
  const filteredReasons =
    !!friendlyWarningReasons &&
    friendlyWarningReasons.filter(
      (w) => !!w && !!w.reason && w.reason === FriendlyWarningReason.ItemIsEditedByMultipleUsers,
    );

  if (!filteredReasons || !filteredReasons.length) {
    return null;
  }

  const workflowStepName = selectedWorkflowStep.name;
  const infoMessage = `They can’t work with ${workflowStepName} and won’t be able to make further content changes.`;

  return (
    <ModalSection>
      <Warning subtitle={`Change workflow step to ${workflowStepName}?`}>
        <div>
          You’re about to move this content item to the workflow step{' '}
          <strong>{workflowStepName}</strong>.
        </div>
        <FriendlyWarningMessageForMultipleUsersEditing
          activeUsersNames={activeUsersNames}
          infoMessage={infoMessage}
        />
      </Warning>
    </ModalSection>
  );
};

UpdatingWorkflowFriendlyWarningSection.displayName = 'UpdatingWorkflowFriendlyWarningSection';
