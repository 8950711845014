import { Box } from '@kontent-ai/component-library/Box';
import { IconButton } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type TranslateDescriptionFromLanguageButtonProps = {
  readonly 'aria-label'?: string;
  readonly isDisabled: boolean;
  readonly tooltipText: string;
  readonly onClick: () => void;
};

export const TranslateDescriptionFromLanguageButton: React.FC<
  TranslateDescriptionFromLanguageButtonProps
> = ({ isDisabled, tooltipText, onClick, ...otherProps }) => (
  <Box paddingLeft={Spacing.S}>
    <IconButton
      buttonStyle="tertiary"
      buttonState={isDisabled ? 'disabled' : 'default'}
      iconName="Translate"
      size="medium"
      tooltipPlacement="top-end"
      tooltipText={tooltipText}
      onClick={onClick}
      {...otherProps}
      {...getDataUiActionAttribute(DataUiAction.Translate)}
    />
  </Box>
);
