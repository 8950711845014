import { Collection, createGuid, noOperation } from '@kontent-ai/utils';
import { AiActionName } from '../../../repositories/serverModels/ai/AiActionName.type.ts';
import { AiActionNameToParameters } from '../../../repositories/serverModels/ai/AiServerModels.params.ts';
import {
  CreateAiActionResponse,
  isCumulatedOperationResponseMessage,
} from '../../../repositories/serverModels/ai/AiServerModels.response.ts';
import { aiActionNameToMessagePayloadTypeGuard } from '../../services/signalR/aiActionMessageTypeGuards.ts';
import {
  AiActionNameToMessagePayload,
  AnyAiMessagePayload,
} from '../../services/signalR/signalRClient.type.ts';
import { logError } from '../logError.ts';
import { aiActionTimeout } from './aiActionTimeout.ts';
import { AiMessageBuffer } from './aiMessageBuffer.ts';
import { findHighestSequenceNumber } from './aiMessagePayloadUtils.ts';

type AiActionStatus = { readonly hasTimedOut: boolean };

type AiTask<TActionName extends AiActionName> = {
  readonly id: Uuid;
  readonly action: {
    readonly name: TActionName;
    readonly parameters: AiActionNameToParameters[TActionName];
    readonly isFinished: boolean;
  };
  readonly context: ReadonlyRecord<string, unknown>;
  readonly management: {
    readonly messagesWhenTimedOut: ReadonlyArray<AiActionNameToMessagePayload[TActionName]> | null;
    readonly aiMessageBufferUnsubscribe: () => void;
    readonly operationId: Uuid | null;
    readonly timeoutId: number;
    readonly processMessagesCallback: (
      messages: ReadonlyArray<AiActionNameToMessagePayload[TActionName]>,
      aiActionStatus: AiActionStatus,
    ) => {
      readonly isFinished: boolean;
    };
  };
};

export type AiTaskMeta = Pick<AiTask<AiActionName>, 'id' | 'action' | 'context'>;

const getFilteredMessages = <TActionName extends AiActionName>(
  actionName: TActionName,
  messages: ReadonlyArray<AnyAiMessagePayload>,
): ReadonlyArray<AiActionNameToMessagePayload[TActionName]> => {
  const filteredMessages = messages?.filter(
    aiActionNameToMessagePayloadTypeGuard[actionName],
  ) as ReadonlyArray<AiActionNameToMessagePayload[TActionName]>;

  if (filteredMessages.length !== messages.length) {
    logError(
      `aiTaskManager: Some of messages '${JSON.stringify(
        messages,
      )}' didn’t pass the '${actionName}' type guard.`,
    );
  }

  return filteredMessages;
};

export const createAiTaskManager = (aiMessageBuffer: AiMessageBuffer) => {
  let tasks: ReadonlyMap<Uuid, AiTask<AiActionName>> = new Map();
  let listeners: ReadonlyArray<() => void> = [];

  const getAllTasks = (): ReadonlyArray<AiTaskMeta> => Collection.getValues(tasks);
  const getAllTasksSnapshot = (): ReadonlyMap<Uuid, AiTaskMeta> => tasks;

  const getTaskActionStatus = (aiTaskId: Uuid): AiActionStatus | null => {
    const task = tasks.get(aiTaskId);
    if (!task) return null;
    return { hasTimedOut: !!task.management.messagesWhenTimedOut };
  };

  const getTaskActionMessages = (aiTaskId: Uuid): ReadonlyArray<AnyAiMessagePayload> | null => {
    const task = tasks.get(aiTaskId);
    if (!task) return null;

    const operationId = tasks.get(aiTaskId)?.management.operationId ?? null;
    const messages = operationId ? (aiMessageBuffer.getMessagesFor(operationId) ?? null) : null;
    if (!messages) return null;

    const messagesWhenTimedOut = task.management.messagesWhenTimedOut ?? null;
    return getFilteredMessages(task.action.name, messagesWhenTimedOut ?? messages);
  };

  const cancel = async (
    aiTaskId: Uuid,
    cancelAiAction: (operationId: Uuid) => Promise<void>,
  ): Promise<void> => {
    const aiTask = tasks.get(aiTaskId);
    if (!aiTask) return;

    aiTask.management.aiMessageBufferUnsubscribe();
    window.clearTimeout(aiTask.management.timeoutId);
    if (aiTask.management.operationId) {
      aiMessageBuffer.ignoreMessages(aiTask.management.operationId);
    }
    tasks = Collection.remove(tasks, aiTaskId);

    notifyListeners();

    if (!aiTask.action.isFinished && aiTask.management.operationId) {
      await cancelAiAction(aiTask.management.operationId);
    }
  };

  const createTask = async <TActionName extends AiActionName>(
    actionName: TActionName,
    actionParameters: AiActionNameToParameters[TActionName],
    createAction: (
      parameters: AiActionNameToParameters[TActionName],
    ) => Promise<CreateAiActionResponse>,
    processMessagesCallback: (
      messages: ReadonlyArray<AiActionNameToMessagePayload[TActionName]>,
      aiActionStatus: AiActionStatus,
    ) => {
      readonly isFinished: boolean;
    },
    aiTaskContext?: ReadonlyRecord<string, unknown>,
  ): Promise<Uuid> => {
    const taskId = createGuid();

    const noMessageTimeoutId = window.setTimeout(() => {
      const messagesWhenTimedOut: ReadonlyArray<AiActionNameToMessagePayload[TActionName]> = [];
      tasks = Collection.replaceWith(tasks, taskId, (task) => ({
        ...task,
        management: { ...task.management, messagesWhenTimedOut },
      }));
      processMessages(messagesWhenTimedOut);
    }, aiActionTimeout);

    tasks = Collection.add(tasks, [
      taskId,
      {
        id: taskId,
        action: {
          name: actionName,
          parameters: actionParameters,
          isFinished: false,
        },
        context: aiTaskContext ?? {},
        management: {
          processMessagesCallback,
          timeoutId: noMessageTimeoutId,
          operationId: null,
          aiMessageBufferUnsubscribe: noOperation,
          messagesWhenTimedOut: null,
        },
      },
    ]);

    notifyListeners();

    const { operationId } = await createAction(actionParameters);
    aiMessageBuffer.keepMessages(operationId);

    const keepLatestCumulativeMessage = (messages: ReadonlyArray<AnyAiMessagePayload>) => {
      const highestSequenceNumber = findHighestSequenceNumber(messages);

      aiMessageBuffer.dropMessages(
        operationId,
        (message) =>
          isCumulatedOperationResponseMessage(message) &&
          message.result.sequenceNumber !== highestSequenceNumber,
      );
    };

    const processMessages = (messages: ReadonlyArray<AnyAiMessagePayload>): void => {
      const task = tasks.get(taskId);
      if (!task) return;

      window.clearTimeout(task.management.timeoutId);

      const messagesWhenTimedOut = task.management.messagesWhenTimedOut ?? null;
      const filteredMessages = getFilteredMessages(actionName, messagesWhenTimedOut ?? messages);

      const { isFinished } = task.management.processMessagesCallback(filteredMessages, {
        hasTimedOut: !!messagesWhenTimedOut,
      });

      tasks = Collection.replaceWith(tasks, taskId, (currentTask) => ({
        ...currentTask,
        action: {
          ...currentTask.action,
          isFinished,
        },
      }));

      notifyListeners();
      keepLatestCumulativeMessage(messages);

      if (isFinished || messagesWhenTimedOut) {
        task.management.aiMessageBufferUnsubscribe();
        return;
      }

      const timeoutId = window.setTimeout(() => {
        tasks = Collection.replaceWith(tasks, taskId, (currentTask) => ({
          ...currentTask,
          management: { ...currentTask.management, messagesWhenTimedOut: messages },
        }));

        processMessages(messages);
      }, aiActionTimeout);

      tasks = Collection.replaceWith(tasks, taskId, (currentTask) => ({
        ...currentTask,
        management: {
          ...currentTask.management,
          timeoutId,
        },
      }));
    };

    const aiMessageBufferUnsubscribe = aiMessageBuffer.subscribe(operationId, processMessages);

    tasks = Collection.replaceWith(tasks, taskId, (currentTask) => ({
      ...currentTask,
      management: {
        ...currentTask.management,
        aiMessageBufferUnsubscribe,
        operationId,
      },
    }));

    notifyListeners();

    return taskId;
  };

  const changeProcessMessagesCallback = <TActionName extends AiActionName>(
    aiTaskId: Uuid,
    processMessagesCallback: (
      messages: ReadonlyArray<AiActionNameToMessagePayload[TActionName]>,
      status: AiActionStatus,
    ) => {
      readonly isFinished: boolean;
    },
  ) => {
    tasks = Collection.replaceWith(tasks, aiTaskId, (task) => ({
      ...task,
      management: {
        ...task.management,
        processMessagesCallback,
      },
    }));
  };

  const subscribe = (listener: () => void): (() => void) => {
    listeners = [...listeners, listener];

    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  };

  const notifyListeners = (): void => listeners.forEach((l) => l());

  return {
    cancel,
    changeProcessMessagesCallback,
    createTask,
    getAllTasks,
    getAllTasksSnapshot,
    getTaskActionMessages,
    getTaskActionStatus,
    subscribe,
  };
};
