import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadSubscriptionBalanceCreator } from './thunk/loadSubscriptionBalance.ts';
import { loadSubscriptionTransactionsCreator } from './thunk/loadSubscriptionTransactions.ts';

const { subscriptionRepository } = repositoryCollection;

export const loadSubscriptionTransactions = loadSubscriptionTransactionsCreator({
  subscriptionRepository,
});

export const loadSubscriptionBalance = loadSubscriptionBalanceCreator({
  subscriptionRepository,
});
