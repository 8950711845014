import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';

export type GeneralSettingsProps = {
  readonly renderBody: () => ReactNode;
};

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({ renderBody }) => (
  <div {...getDataUiAppNameAttribute(DataUiAppName.General)}>
    <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.General} />
    <div className="general-settings canvas__inner-section--restricted-width">
      <Stack spacing={Spacing.XL}>
        <PageTitle>{EnvironmentSettingsAppNames.General}</PageTitle>
        {renderBody()}
      </Stack>
    </div>
  </div>
);

GeneralSettings.displayName = 'GeneralSettings';
