import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Workflow_Editing_Left,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeCreating_Started,
  Workflow_InitializeEditing_Finished,
  Workflow_InitializeEditing_Started,
} from '../../constants/workflowActionTypes.ts';

export const workflowLoadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Workflow_InitializeEditing_Started:
    case Workflow_InitializeCreating_Started:
      return LoadingStatus.Loading;

    case Workflow_InitializeEditing_Finished:
    case Workflow_InitializeCreating_Finished:
      return LoadingStatus.Loaded;

    case Workflow_Editing_Left:
      return LoadingStatus.InitialEmpty;

    default:
      return state;
  }
};
