import { areArraysShallowEqual } from '@kontent-ai/utils';
import Immutable from 'immutable';

export interface IAssetLibraryQuery {
  readonly mimeType: string;
  readonly searchPhrase: string;
  readonly selectedTaxonomyNodes: ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
  readonly selectedCollections: ReadonlyArray<Uuid | null>;
}

export const emptyAssetLibraryQuery: IAssetLibraryQuery = {
  mimeType: '',
  searchPhrase: '',
  selectedTaxonomyNodes: new Map(),
  selectedCollections: [],
};

export const areSearchPhrasesEqual = (
  x: Pick<IAssetLibraryQuery, 'searchPhrase'>,
  y: Pick<IAssetLibraryQuery, 'searchPhrase'>,
): boolean => x.searchPhrase === y.searchPhrase;

export const areSelectedTaxonomiesEqual = (
  x: Pick<IAssetLibraryQuery, 'selectedTaxonomyNodes'>,
  y: Pick<IAssetLibraryQuery, 'selectedTaxonomyNodes'>,
): boolean => Immutable.is(x.selectedTaxonomyNodes, y.selectedTaxonomyNodes);

export const areSelectedCollectionsEqual = (
  x: Pick<IAssetLibraryQuery, 'selectedCollections'>,
  y: Pick<IAssetLibraryQuery, 'selectedCollections'>,
): boolean => areArraysShallowEqual(x.selectedCollections, y.selectedCollections);

export const areAssetLibraryQueriesEqual = (
  x: IAssetLibraryQuery,
  y: IAssetLibraryQuery,
): boolean =>
  x.mimeType.trim() === y.mimeType.trim() &&
  areSearchPhrasesEqual(x, y) &&
  areSelectedTaxonomiesEqual(x, y) &&
  areSelectedCollectionsEqual(x, y);

// no mime type for now
export const isAssetLibraryVisibleFilterEmpty = (query: IAssetLibraryQuery): boolean =>
  !query.selectedTaxonomyNodes.size && !query.selectedCollections.length;

export const isAssetLibraryVisibleQueryEmpty = (query: IAssetLibraryQuery): boolean =>
  !query.searchPhrase && isAssetLibraryVisibleFilterEmpty(query);
