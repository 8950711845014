import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  IconSize,
  Spacing,
  Typography,
  colorAlertIcon,
  colorAlertText,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IEnvironmentActionFailedButtonProps {
  readonly onDelete: () => void;
}

const ErrorMessage = styled.span`
  ${Typography.LabelLarge};
  color: ${colorAlertText};
`;

const ErrorIcon = styled(Icons.ExclamationTriangleInverted)`
  color: ${colorAlertIcon};
`;

export const EnvironmentActionFailedButton: React.FC<IEnvironmentActionFailedButtonProps> = (
  props,
) => (
  <Stack spacing={Spacing.XL}>
    <Row spacing={Spacing.S} alignY="center" noWrap>
      <Column width="content">
        <ErrorIcon size={IconSize.S} />
      </Column>
      <Column>
        <ErrorMessage>The cloning failed. Please try again.</ErrorMessage>
      </Column>
    </Row>
    <Button
      buttonStyle="secondary"
      destructive
      onClick={props.onDelete}
      {...getDataUiActionAttribute(DataUiAction.Delete)}
    >
      Remove
    </Button>
  </Stack>
);

EnvironmentActionFailedButton.displayName = 'EnvironmentActionFailedButton';
