import { Action } from '../../../../../@types/Action.type.ts';
import {
  Calendar_DrillDownDateSelected,
  Calendar_Page_Left,
  Calendar_PopupClosed,
} from '../../constants/calendarActionTypes.ts';

export type DrillDownDateState = DateTimeStamp | null;

const initialState: DrillDownDateState = null;

export const drillDownDate = (
  state: DrillDownDateState = initialState,
  action: Action,
): DrillDownDateState => {
  switch (action.type) {
    case Calendar_DrillDownDateSelected: {
      return action.payload.drillDownDate;
    }

    case Calendar_PopupClosed:
    case Calendar_Page_Left:
      return initialState;

    default:
      return state;
  }
};
