import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { createGuid } from '@kontent-ai/utils';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import {
  UserDetailRoute,
  UserDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { LabelFor } from '../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { TextField } from '../../../../../_shared/uiComponents/TextField/TextField.tsx';
import { DataUiAction } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { SubscriptionCapabilitiesContext } from '../../context/SubscriptionCapabilitiesContext.tsx';
import {
  IUserCollectionGroupSettingsViewModel,
  IUserEnvironmentSettingsViewModel,
} from '../../models/UserProjectSettingsViewModel.ts';
import { shortenDefaultLanguageName } from '../../utils/userProjectSettingsFilterUtils.ts';

type UserProjectSettingsDetailProps = {
  readonly onClose: () => void;
  readonly environmentSettings?: ReadonlyArray<IUserEnvironmentSettingsViewModel>;
  readonly userId: Uuid;
};

export const UserProjectSettingsDetail: React.FC<UserProjectSettingsDetailProps> = ({
  onClose,
  environmentSettings,
  userId,
}) => {
  const capabilities = useContext(SubscriptionCapabilitiesContext);

  if (!environmentSettings) {
    return (
      <div className="bar-item__loading-indicator-pane">
        <div className="bar-item__loading-indicator" />
      </div>
    );
  }

  const renderEnvironmentBox = (settings: IUserEnvironmentSettingsViewModel) => (
    <Box key={settings.environment.projectId}>
      <Stack spacing={Spacing.L}>
        <Row alignY="center" spacingX={Spacing.L}>
          <Column width="fit-content">
            <span className="user-project-settings__environment-name">
              {settings.environment.environmentName}
            </span>
          </Column>
          <Column width="fit-content">
            <Link
              to={buildPath<UserDetailRouteParams>(UserDetailRoute, {
                projectId: settings.environment.projectId,
                userId,
              })}
              target="_blank"
            >
              <Button buttonStyle="secondary" size="small">
                Edit
                <Button.Icon icon={Icons.ArrowRightTopSquare} />
              </Button>
            </Link>
          </Column>
        </Row>
        <Stack spacing={Spacing.XS}>
          {settings.collectionGroupSettings.map((collectionGroupSettings, index) =>
            renderRoleSettingsRow(
              collectionGroupSettings,
              index,
              settings.collectionGroupSettings.length,
            ),
          )}
        </Stack>
      </Stack>
    </Box>
  );

  const renderRoleSettingsRow = (
    settings: IUserCollectionGroupSettingsViewModel,
    rowIndex: number,
    totalRows: number,
  ) => {
    const showFieldLabels = rowIndex === 0;

    const collectionNames = settings.collections.length
      ? settings.collections.map((collection) => collection.name).join(', ')
      : 'Any collection';

    const anyLanguageLabel = totalRows > 1 ? 'Any remaining languages' : 'Any language';
    const languageNames = settings.isAssignedToAnyLanguage
      ? anyLanguageLabel
      : settings.languageNames.map(shortenDefaultLanguageName).join(', ');

    return (
      <Row key={rowIndex} spacing={Spacing.M}>
        <Column width="1/3">
          {createField(settings.roleName, showFieldLabels ? 'Role' : undefined)}
        </Column>
        {capabilities.areCollectionRolesEnabled && (
          <Column width="1/3">
            {createField(collectionNames, showFieldLabels ? 'Collections' : undefined)}
          </Column>
        )}
        {capabilities.areLanguageRolesEnabled && (
          <Column width="1/3">
            {createField(languageNames, showFieldLabels ? 'Languages' : undefined)}
          </Column>
        )}
      </Row>
    );
  };

  const createInput = (value: string, inputId: string): JSX.Element => (
    <TextField readOnly inputId={inputId} placeholder={value} value={value} />
  );

  const createField = (value: string, label?: string): JSX.Element =>
    label ? (
      <LabelFor target={(id) => createInput(value, id)}>{label}</LabelFor>
    ) : (
      createInput(value, createGuid())
    );

  return (
    <>
      <div className="bar-item__expansion">
        <Stack spacing={Spacing.XL}>
          {environmentSettings.map((settings) => renderEnvironmentBox(settings))}
        </Stack>
      </div>
      <BarItemActionButtons
        secondaryAction={{
          handler: onClose,
          text: 'Close',
          dataUIActionName: DataUiAction.Cancel,
        }}
      />
    </>
  );
};

UserProjectSettingsDetail.displayName = 'UserProjectSettingsDetail';
