import {
  PreviewConfiguration_FormChanged,
  PreviewConfiguration_ServerError_Show,
} from '../constants/previewConfigurationActionTypes.ts';
import { IPreviewConfiguration } from '../models/PreviewConfiguration.type.ts';

export const showServerErrorMessage = () =>
  ({
    type: PreviewConfiguration_ServerError_Show,
  }) as const;

export const previewConfigurationFormChanged = (previewConfiguration: IPreviewConfiguration) =>
  ({
    type: PreviewConfiguration_FormChanged,
    payload: {
      previewConfiguration,
    },
  }) as const;
