import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../_shared/components/NotificationBar.tsx';
import { SubscriptionPlanMaxUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { LabelFor } from '../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { TextField } from '../../../../../_shared/uiComponents/TextField/TextField.tsx';
import {
  ICollectionGroupRoles,
  IProjectContributor,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { CollectionPermissionsQuickTip } from '../CollectionPermissionsQuickTip.tsx';
import { LanguageRolesQuickTip } from '../LanguageRolesQuickTip.tsx';
import { UserConfiguration } from '../collections/UserConfiguration.tsx';

const createInput = (value: string, inputId: string): JSX.Element => (
  <TextField readOnly inputId={inputId} placeholder={value} value={value} />
);

const createFieldWithLabel = (label: string, value: string): JSX.Element => (
  <div className="user-modal__form-item">
    <LabelFor target={(id) => createInput(value, id)}>{label}</LabelFor>
  </div>
);

type BodyProps = {
  readonly collectionGroups: readonly ICollectionGroupRoles[];
  readonly disabledTooltip: string | undefined;
  readonly errorMessage: string | undefined;
  readonly onCollectionGroupsChange: (collectionGroups: readonly ICollectionGroupRoles[]) => void;
  readonly onHideErrorNotificationBar: () => void;
  readonly savingError: string | null;
  readonly user: IProjectContributor;
  readonly isUserCountedInUsage: boolean;
};

export const UserDetailModalBody: React.FC<BodyProps> = ({
  collectionGroups,
  disabledTooltip,
  errorMessage,
  onCollectionGroupsChange,
  onHideErrorNotificationBar,
  savingError,
  user,
  isUserCountedInUsage,
}) => {
  const inputs = (
    <>
      {createFieldWithLabel('First name', user.firstName)}
      {createFieldWithLabel('Last name', user.lastName)}
      {createFieldWithLabel('Email', user.email)}
    </>
  );

  return (
    <div className="user-modal__body">
      <div className="user-modal__content">
        <div className="user-modal__form">
          <Stack spacing={Spacing.L}>
            <div className="user-modal__form-inputs-pane">
              <div className="user-modal__form-inputs">{inputs}</div>
            </div>
            {user.inactive && !isUserCountedInUsage && (
              <SubscriptionPlanMaxUserLimitReachedWarning />
            )}
            <div className="user-modal__role-builder-pane">
              <UserConfiguration
                collectionGroups={collectionGroups}
                disabledTooltip={disabledTooltip}
                initialCollectionGroups={user.collectionGroups}
                onCollectionGroupsChange={onCollectionGroupsChange}
                userId={user.userId}
              />
            </div>
            <LanguageRolesQuickTip isRoleBuilderValid={!errorMessage} />
            <CollectionPermissionsQuickTip />
          </Stack>
        </div>
      </div>
      <div className="user-modal__footer">
        <NotificationBar
          type={NotificationBarType.Error}
          message={savingError}
          onClosePanel={onHideErrorNotificationBar}
        />
      </div>
    </div>
  );
};

UserDetailModalBody.displayName = 'UserDetailModalBody';
