import { setTag } from '@sentry/react';
import { useEffect } from 'react';
import { getCurrentProjectId } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isUuid } from '../utils/validation/typeValidators.ts';
import { useSelector } from './useSelector.ts';

export const useSetCurrentProjectIdToSentry = () => {
  const projectId = useSelector(getCurrentProjectId);

  useEffect(() => {
    if (isUuid(projectId)) {
      // needed due to https://kontent-ai.atlassian.net/browse/KCL-10974
      setTag('projectId', projectId);
    }
  }, [projectId]);
};
