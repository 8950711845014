import { Action } from '../../../@types/Action.type.ts';
import { Data_UserInfo_Success } from '../../../data/constants/dataActionTypes.ts';
import { Shared_AccessUnauthorized } from '../../constants/sharedActionTypes.ts';

export function isLoggedIn(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case Data_UserInfo_Success:
      return !!action.payload.data.userId;

    case Shared_AccessUnauthorized:
      return false;

    default:
      return state;
  }
}
