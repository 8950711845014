import { useAttachRef } from '@kontent-ai/hooks';
import { usePress } from '@react-aria/interactions';
import { DismissButton } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { AriaRole, PropsWithChildren, forwardRef } from 'react';

export interface IListBoxProps {
  readonly 'aria-label'?: string;
  readonly id?: string;
  readonly onOverlayClose?: () => void;
  readonly role: AriaRole;
}

export const ListBox = forwardRef<HTMLDivElement, PropsWithChildren<IListBoxProps>>(
  ({ onOverlayClose, id, children, ...otherProps }, forwardedRef) => {
    // Handle events that should cause the popup to close,
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { pressProps } = usePress({
      ref: refObject,
      preventFocusOnPress: true,
    });

    return (
      <>
        <div id={id} ref={refToForward} {...mergeProps(pressProps, otherProps)}>
          {children}
        </div>
        <DismissButton onDismiss={onOverlayClose} />
      </>
    );
  },
);
ListBox.displayName = 'ListBox';
