import { dismissContextualHelp } from '../../../../_shared/actions/thunkSharedActions.ts';
import { ContextualHelp } from '../../../../_shared/components/ContextualHelp/ContextualHelp.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ProjectType } from '../../../../_shared/models/ProjectType.ts';
import { getProjectType } from '../../../../_shared/models/utils/quickstartUtils.ts';
import { ContextualHelpType } from '../../../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';

const bulletPoints = [
  () => (
    <>
      See content you <b>recently edited</b>
    </>
  ),
  () => (
    <>
      <b>Find</b> content assigned to you
    </>
  ),
];

export const YourContentContextualHelp = () => {
  const dispatch = useDispatch();
  const shouldShow = useSelector(
    (s) =>
      getProjectType(getCurrentProject(s)) !== ProjectType.UserProject &&
      s.sharedApp.userProperties.contextualHelpSettings.get(ContextualHelpType.YourContent, false),
  );

  return (
    <ContextualHelp
      bulletPoints={bulletPoints}
      onDismiss={() => dispatch(dismissContextualHelp(ContextualHelpType.YourContent))}
      shouldShow={shouldShow}
      title="This is your content"
      callToAction={
        <>
          <b>Open</b> your content and get to work.
        </>
      }
    />
  );
};
