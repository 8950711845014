import { useCallback, useMemo } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorFeatureLimitations } from './api/EditorFeatureLimitations.ts';
import { EditorLimitationsApi } from './api/EditorLimitationsApi.type.ts';
import { createEditorLimitationsApi } from './api/editorLimitationsApi.ts';

type ApiLimitationsPluginInternalProps = { readonly limitations: EditorFeatureLimitations };

export type ApiLimitationsPlugin = EditorPlugin<
  None,
  None,
  EditorLimitationsApi,
  ApiLimitationsPluginInternalProps
>;

export const ApiLimitationsPlugin: PluginComponent<ApiLimitationsPlugin> = (props) => {
  const { limitations } = props;
  const api = useMemo(() => createEditorLimitationsApi(limitations), [limitations]);
  const getApiMethods = useCallback(() => api, [api]);

  return useEditorWithPlugin<ApiLimitationsPlugin>(props, { getApiMethods });
};
