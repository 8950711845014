import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { selectedContentTypesUpdated } from '../contentTypesActions.ts';

export const createSelectContentTypesAction =
  () =>
  (selectedTypeIds: Immutable.Set<Uuid>): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const updatedSelectedTypeIds =
      state.contentModelsApp.contentTypes.listing.selectedContentTypes.merge(selectedTypeIds);

    dispatch(selectedContentTypesUpdated(updatedSelectedTypeIds));
  };
