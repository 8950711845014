import { Collection } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IUserListingConfig } from '../../../../../localStorages/userListingConfigStorage.ts';

interface IDeps {
  readonly userListingConfigStorage: IProjectSpecificStorage<IUserListingConfig>;
}

export const createUpdateSavedConfigAction =
  (deps: IDeps) =>
  (): ThunkFunction =>
  (_dispatch: Dispatch, getState: GetState): void => {
    const {
      usersApp: { filter, sortBy },
      sharedApp: { currentProjectId },
    } = getState();

    const updatedUserListingConfig: IUserListingConfig = {
      collectionsFilter: Collection.getValues(filter.byCollection),
      languagesFilter: Collection.getValues(filter.byLanguage),
      rolesFilter: Collection.getValues(filter.byRoles),
      sortByDirection: sortBy.direction,
      sortByField: sortBy.columnCode,
      statusFilter: filter.byStatus,
      userNameFilter: filter.byName || '',
    };

    deps.userListingConfigStorage.save(updatedUserListingConfig, currentProjectId);
  };
