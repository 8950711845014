import React from 'react';
import { IGuidelinesTypeElement } from '../../applications/contentInventory/content/models/contentTypeElements/GuidelinesTypeElement.ts';
import { changeGuidelinesElementVisibility } from '../../applications/itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { Guidelines as GuidelinesComponent } from '../components/Guidelines.tsx';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useSelector } from '../hooks/useSelector.ts';

interface IGuidelinesProps {
  readonly typeElement: IGuidelinesTypeElement;
}

export const Guidelines: React.FC<IGuidelinesProps> = (props) => {
  const dispatch = useDispatch();
  const isCollapsed = useSelector((s) =>
    s.contentApp.editorUi.collapsedGuidelinesElementIds.includes(props.typeElement.elementId),
  );

  return (
    <GuidelinesComponent
      isCollapsed={isCollapsed}
      onCollapsedStateChanged={(newIsCollapsed: boolean) =>
        dispatch(changeGuidelinesElementVisibility(props.typeElement.elementId, newIsCollapsed))
      }
      typeElement={props.typeElement}
    />
  );
};
