import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { PreviewConfiguration_Validated } from '../../constants/previewConfigurationActionTypes.ts';
import {
  ContentTypeId,
  IPreviewUrlPatternError,
  SpaceRowId,
} from '../../stores/IPreviewConfigurationAppStoreState.type.ts';

const initialState = new Map<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>>();

export const previewUrlsSectionErrors = (
  state = initialState,
  action: Action,
): ReadonlyMap<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>> => {
  switch (action.type) {
    case PreviewConfiguration_Validated:
      return action.payload.errors;

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
};
