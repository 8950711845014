import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';

export const getItemsBetweenInclusive = (
  fromChecked: Uuid,
  toChecked: Uuid,
  allIds: Immutable.Collection.Indexed<Uuid>,
): Immutable.Set<Uuid> => {
  const isForward = allIds.indexOf(fromChecked) < allIds.indexOf(toChecked);

  const fromId = isForward ? fromChecked : toChecked;
  const toId = isForward ? toChecked : fromChecked;

  return allIds
    .skipUntil((id) => id === fromId)
    .takeUntil((id) => id === toId)
    .toSet()
    .add(toId)
    .add(fromId);
};

export const rangeSelectionShouldSelect = (
  affectedIds: ReadonlySet<Uuid>,
  selectedItems: ReadonlySet<Uuid>,
  lastTouchedItemId: Uuid,
): boolean => {
  // Remove the "from" item to allow create unselect inside of the selected range
  const selected = Collection.remove(selectedItems, lastTouchedItemId);
  const toSelect = Collection.remove(affectedIds, lastTouchedItemId);

  const intersection = Collection.intersect(Array.from(selected), toSelect);

  // Deselect only in case toSelect are all already selected
  return intersection.length !== toSelect.size;
};
