import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IFormSectionProps {
  readonly children: React.ReactNode;
  readonly errorMessage?: string;
  readonly message?: string;
  readonly title: string;
}

export const FormSection: React.FC<IFormSectionProps> = ({
  children,
  errorMessage,
  message,
  title,
}) => {
  return (
    <div className="form__section" {...getDataUiElementAttribute(DataUiElement.FormSection)}>
      <div className="form__section-meta">
        <div className="form__section-title"> {title} </div>
        {errorMessage && (
          <span className="form__section-status form__section-status--failed">{errorMessage}</span>
        )}
        {message && <p className="form__section-guideline"> {message} </p>}
      </div>
      {children}
    </div>
  );
};

FormSection.displayName = 'FormSection';
