import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../stores/IStore.type.ts';
import {
  IWorkflowStepSelectorOwnProps,
  IWorkflowStepSelectorStateProps,
  WorkflowStepSelector as WorkflowStepSelectorComponent,
} from '../../components/Elements/WorkflowStepSelector.tsx';

const mapStateToProps = (state: IStore): IWorkflowStepSelectorStateProps => {
  const selectedStep = state.contentApp.changeWorkflowStepModalData.workflowStep;

  return {
    selectedOptions: [selectedStep],
  };
};

export const WorkflowStepSelector: React.ComponentType<IWorkflowStepSelectorOwnProps> = connect(
  mapStateToProps,
)(WorkflowStepSelectorComponent);
