import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_CollectionNameChanged,
  Collections_Creator_Canceled,
  Collections_Creator_Opened,
  Collections_Creator_Submitted,
  Collections_Editor_Canceled,
  Collections_Editor_Deleted,
  Collections_Editor_Opened,
  Collections_Editor_Submitted,
  Collections_Listing_Left,
} from '../../constants/actionTypes.ts';

export const editedCollectionName = (state = '', action: Action): string => {
  switch (action.type) {
    case Collections_Creator_Opened:
      return action.payload.initialCollectionName ?? '';

    case Collections_Editor_Opened:
      return action.payload.collectionName;

    case Collections_CollectionNameChanged:
      return action.payload.name;

    case Collections_Creator_Canceled:
    case Collections_Creator_Submitted:
    case Collections_Editor_Canceled:
    case Collections_Editor_Submitted:
    case Collections_Editor_Deleted:
    case Collections_Listing_Left:
      return '';

    default:
      return state;
  }
};
