import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { UseTransitionProps } from '@react-spring/web';
import { keyframes } from 'styled-components';

export const animationTime = 250;
export const getAnimationTime = () => (ComponentLibraryGlobals.skipAnimation ? 0 : animationTime);

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const modalTransitionsDefinition: UseTransitionProps = {
  from: {
    transform: `translateY(${px(-1 * Spacing.L)})`,
  },
  enter: {
    transform: 'translateY(0%)',
  },
  leave: {
    transform: `translateY(${px(-1 * Spacing.L)})`,
  },
};

export const fullScreenModalTransitionsDefinition: UseTransitionProps = {
  from: {
    transform: 'translateY(100%)',
  },
  enter: {
    transform: 'translateY(0%)',
  },
  leave: {
    transform: 'translateY(100%)',
  },
};
