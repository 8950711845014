import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../Icon/Icon.tsx';

export const DropDownOptionSubmenuMark: React.FC = () => (
  <div className="dropdown-option__icon">
    <Icon iconName={IconName.ChevronRight} />
  </div>
);

DropDownOptionSubmenuMark.displayName = 'DropDownOptionSubmenuMark';
