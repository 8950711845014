import { Action } from '../../../../../@types/Action.type.ts';
import {
  Calendar_LoadItemsWithVariantActiveInPeriod_Finished,
  Calendar_LoadItemsWithVariantActiveInPeriod_Started,
} from '../../constants/calendarActionTypes.ts';
import { CalendarView } from '../../models/CalendarView.ts';

export const calendarView = (
  state: CalendarView = CalendarView.Items,
  action: Action,
): CalendarView => {
  switch (action.type) {
    case Calendar_LoadItemsWithVariantActiveInPeriod_Started: {
      return CalendarView.Items;
    }

    case Calendar_LoadItemsWithVariantActiveInPeriod_Finished: {
      if (action.payload.contentItems.length > 0) {
        return CalendarView.Items;
      }

      if (action.payload.isFilterSet) {
        return CalendarView.EmptySearch;
      }

      return CalendarView.EmptyInventory;
    }

    default:
      return state;
  }
};
