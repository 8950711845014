import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

export const isViewCodenameAvailable: ContentItemEditingActionAvailabilityProvider = (
  state: IStore,
) => {
  return currentUserHasCapabilities(state, Capability.ConfigureCodenames);
};
