import { CheckboxGroupState } from '@react-stately/checkbox';
import React, { useMemo } from 'react';

type CheckboxGroupContext = {
  readonly state: CheckboxGroupState;
};
export const CheckboxGroupContext = React.createContext<CheckboxGroupContext | undefined>(
  undefined,
);

type CheckboxGroupContentProviderProps = CheckboxGroupContext;

export const CheckboxGroupContextProvider: React.FC<
  React.PropsWithChildren<CheckboxGroupContentProviderProps>
> = ({ children, state }) => {
  const contextValue = useMemo(
    () => ({
      state,
    }),
    [state],
  );

  return (
    <CheckboxGroupContext.Provider value={contextValue}>{children}</CheckboxGroupContext.Provider>
  );
};

CheckboxGroupContextProvider.displayName = 'CheckboxGroupContextProvider';
