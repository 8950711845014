import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { StatusMessage } from '../../../../../_shared/uiComponents/StatusMessage/StatusMessage.tsx';

export const AssetTypeSavingStatus: React.FC = () => {
  const contentTypeIsBeingSaved = useSelector(
    (s) => s.contentModelsApp.typeEditor.typeIsBeingSaved,
  );
  const editedContentTypeIsModified = useSelector(
    (s) => s.contentModelsApp.typeEditor.editedTypeIsModified,
  );

  if (editedContentTypeIsModified) {
    return <StatusMessage statusMessage={UnsavedStatusMessage} />;
  }

  return contentTypeIsBeingSaved ? (
    <StatusMessage statusMessage={SavingStatusMessage} />
  ) : (
    <StatusMessage statusMessage={SavedStatusMessage} />
  );
};

AssetTypeSavingStatus.displayName = 'AssetTypeSavingStatus';
