import React from 'react';
import { css } from 'styled-components';
import {
  PolymorphicComponent,
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../@types/PolymorphicComponent.type.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { XAlignment } from '../../types/alignments.ts';
import { Box } from '../Box/Box.tsx';

export type BaseStackProps = Readonly<{
  align?: XAlignment;
  spacing?: Spacing;
}>;

const listStackResets = css`
  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol&,
  ul& {
    /* resets for lists */
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

export const Stack = React.forwardRef(
  <TElement extends React.ElementType = 'div'>(
    {
      align = 'normal',
      children,
      component,
      spacing = 0,
      ...otherProps
    }: React.PropsWithChildren<BaseStackProps> & PolymorphicComponentProps<TElement>,
    forwardedRef: PolymorphicRef<TElement>,
  ) => (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={spacing}
      css={`${listStackResets}`}
      alignItems={align}
      component={component ?? 'div'}
      ref={forwardedRef}
      {...otherProps}
    >
      {children}
    </Box>
  ),
) as PolymorphicComponent<BaseStackProps>;

Stack.displayName = 'Stack';
