import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { Content_LoadedItems_ListingItemsLoaded } from '../../../../contentInventory/content/constants/contentActionTypes.ts';
import { Calendar_LoadItemsWithVariantActiveInPeriod_Finished } from '../../../../contentPlanning/calendar/constants/calendarActionTypes.ts';
import { ProjectOverview_LoadItemsMatchingFilter_Succeeded } from '../../../../contentPlanning/projectOverview/constants/projectOverviewActionTypes.ts';
import { Data_ContentStatusContentItems_Success } from '../../constants/contentStatusActionTypes.ts';

const initialState: Immutable.Map<Uuid, IListingContentItem> = Immutable.Map();

const addAllToState = (
  state: Immutable.Map<Uuid, IListingContentItem>,
  newItems: ReadonlyArray<IListingContentItem>,
): Immutable.Map<Uuid, IListingContentItem> =>
  state.withMutations((newState) =>
    newItems.forEach((contentItem) => {
      const contentItemId: ContentItemId = contentItem.variant?.id ?? {
        itemId: contentItem.item.id,
        variantId: DefaultVariantId,
      };
      return newState.set(stringifyContentItemId(contentItemId), contentItem);
    }),
  );

export function byId(
  state: Immutable.Map<Uuid, IListingContentItem> = initialState,
  action: Action,
): Immutable.Map<Uuid, IListingContentItem> {
  switch (action.type) {
    case Content_LoadedItems_ListingItemsLoaded:
    case Data_ContentStatusContentItems_Success:
    case Calendar_LoadItemsWithVariantActiveInPeriod_Finished:
    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      const { contentItems } = action.payload;
      if (contentItems === null) {
        return state;
      }

      return addAllToState(state, contentItems);
    }

    default:
      return state;
  }
}
