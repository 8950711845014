import { Action } from '../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IProjectRepository } from '../../../repositories/interfaces/IProjectRepository.type.ts';
import {
  IProjectLocation,
  getProjectLocationFromServerModel,
} from '../../models/ProjectLocation.ts';

interface ILoadAvailableProjectLocationsDependencies {
  readonly projectRepository: IProjectRepository;
  readonly projectLocationsLoadingFinished: (projects: ReadonlyArray<IProjectLocation>) => Action;
}

export const loadAvailableProjectLocationsCreator =
  (deps: ILoadAvailableProjectLocationsDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const response = await deps.projectRepository.getAvailableProjectLocations(abortSignal);
    const availableLocations = response.map(getProjectLocationFromServerModel);

    dispatch(deps.projectLocationsLoadingFinished(availableLocations));
  };
