import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { BaseColor, Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { IListingVariantData } from '../../../data/models/listingContentItems/IListingVariantData.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { isVariantPublished } from '../../utils/contentItemVariantUtils.ts';

export const FallbackVariantWorkflowStepBadge: React.FC<{
  readonly fallbackVariant: IListingVariantData;
}> = ({ fallbackVariant }) => {
  const hasPublishedVersion = isVariantPublished(fallbackVariant);
  const workflowStatus =
    fallbackVariant.actualWorkflowStatus || fallbackVariant.assignment.workflowStatus;

  return hasPublishedVersion ? (
    <Tooltip tooltipText="This item is published in another language." placement="top">
      <Icons.CheckCircleInverted fontSize={Spacing.L} color={BaseColor.PersianGreen60} />
    </Tooltip>
  ) : (
    <Tooltip tooltipText="This item contains content in another language." placement="top">
      <Icons.CircleInverted
        fontSize={Spacing.L}
        color={renderWorkflowStepColor(workflowStatus.color)}
      />
    </Tooltip>
  );
};

FallbackVariantWorkflowStepBadge.displayName = 'FallbackVariantWorkflowStepBadge';
