import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { ContentState } from 'draft-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { Resettable } from '../../../../../../richText/plugins/behavior/ManualResetPlugin.tsx';
import { DebouncedChanges } from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { emptyEditorState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { NewCommentThreadActionsBar } from './NewCommentThreadActionsBar.tsx';

type Props = {
  readonly areButtonsDisplayed: boolean;
  readonly isEditing: boolean;
  readonly isSubmitting: boolean;
  readonly onBlur: (isCommentPending: boolean) => void;
  readonly onCancel: () => void;
  readonly onFocus: () => void;
  readonly onSubmit: (commentContent: ContentState) => Promise<void>;
};

export const NewCommentThread = (props: Props) => {
  const [newComment, setNewComment] = useState(emptyEditorState);

  const focusableRef = useRef<IFocusable>(null);
  const resettableRef = useRef<Resettable>(null);
  const debouncedChangesRef = useRef<DebouncedChanges>(null);

  const focus = useCallback((): void => {
    focusableRef.current?.focus();
    props.onFocus();
  }, [props.onFocus]);

  useEffect(() => {
    focus();
  }, [focus]);

  useEffect(() => {
    if (props.isEditing) {
      focus();
    }
  }, [props.isEditing, focus]);

  const resetCommentInput = (): void => {
    resettableRef.current?.resetInput(emptyEditorState.getCurrentContent());
    setNewComment(emptyEditorState);
  };

  const submit = async (): Promise<void> => {
    await debouncedChangesRef.current?.propagatePendingContentChanges();
    await props.onSubmit(newComment.getCurrentContent());

    resetCommentInput();
    focus();
  };

  const blur = (): void => {
    focusableRef.current?.blur();
    props.onBlur(!newComment.getCurrentContent().getPlainText());
  };

  const cancelEditing = (): void => {
    resetCommentInput();
    props.onCancel();
  };

  useHotkeys({
    [ShortcutsConfig.ControlEnter]: submit,
  });

  return (
    <form
      className="form comment-editor__form comment-editor__form--is-on-discussion-tab"
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();
        submit();
      }}
    >
      <LabelFor
        isHidden
        target={() => (
          <CommentInput
            debouncedChangesRef={debouncedChangesRef}
            disabled={props.isSubmitting}
            editorState={emptyEditorState}
            focusableRef={focusableRef}
            onContentChange={(editorState) => {
              setNewComment(editorState);
              return Promise.resolve();
            }}
            onEscape={() => {
              cancelEditing();
              blur();
            }}
            onSelectionChange={(editorState) => {
              if (editorState.getSelection().getHasFocus()) {
                focus();
              } else {
                blur();
              }
              return Promise.resolve();
            }}
            resettableRef={resettableRef}
            {...getDataUiInputAttribute(DataUiInput.Comment)}
          />
        )}
      >
        Comment
      </LabelFor>
      {props.areButtonsDisplayed && (
        <NewCommentThreadActionsBar
          isSubmitting={props.isSubmitting}
          onCancelEditing={cancelEditing}
          isSubmitButtonDisabled={
            isEmptyOrWhitespace(newComment.getCurrentContent().getPlainText()) || props.isSubmitting
          }
        />
      )}
    </form>
  );
};
