import { BaseSyntheticEvent } from 'react';

export const not = (condition: boolean) => !condition;

export const stopPropagation = (event: Event | BaseSyntheticEvent) => event.stopPropagation();

export const preventDefault = (event: Event | BaseSyntheticEvent) => event.preventDefault();

export const always =
  <T>(result: T) =>
  () =>
    result;

export type NonUndefined<T> = T extends undefined ? never : T;

export function getDefinedValueOrDefault<T>(
  diffValue: T,
  defaultValue: NonUndefined<T>,
): NonUndefined<T> {
  return diffValue !== undefined ? (diffValue as NonUndefined<T>) : defaultValue;
}
