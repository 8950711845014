import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IAssetFolderArchiveResultServerModel } from '../../../../../repositories/serverModels/AssetFolderServerModel.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import {
  AssetLibrary_Folders_ArchiveCompleted,
  AssetLibrary_Folders_ArchiveFailed,
  AssetLibrary_Folders_ArchiveStarted,
} from '../../constants/assetLibraryActionTypes.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly archive: (folderId: Uuid) => Promise<IAssetFolderArchiveResultServerModel>;
  };
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

interface IArchiveFolderActionParams {
  readonly folderId: Uuid;
}

interface IArchiveFolderActionPayload {
  readonly failedAssetsIds: ReadonlySet<Uuid>;
  readonly successfulAssetsIds: ReadonlySet<Uuid>;
  readonly successfulFoldersIds: ReadonlySet<Uuid>;
  readonly taxonomyGroup: ITaxonomyGroupServerModel;
  readonly usedAssetsIds: ReadonlySet<Uuid>;
}

const started = () =>
  ({
    type: AssetLibrary_Folders_ArchiveStarted,
  }) as const;

const completed = (payload: IArchiveFolderActionPayload) =>
  ({
    type: AssetLibrary_Folders_ArchiveCompleted,
    payload,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Folders_ArchiveFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type ArchiveAssetFolderActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Result = {
  readonly archivedFoldersIds: ReadonlyArray<Uuid>;
};

export const createArchiveAssetFolderAction =
  (deps: IDeps) =>
  ({ folderId }: IArchiveFolderActionParams): ThunkPromise<Result> =>
  async (dispatch: Dispatch): Promise<Result> => {
    if (folderId === RootFolderId) {
      throw InvariantException(
        `${__filename}: Specified folderId is the root folder. The root folder cannot be deleted.`,
      );
    }

    dispatch(started());

    try {
      const { archivedAssetsIds, archivedFoldersIds, failedAssetsIds, folders, usedAssetsIds } =
        await deps.assetFolderRepository.archive(folderId);

      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.DeletedFolder,
          count: archivedAssetsIds.length,
        }),
      );

      dispatch(
        completed({
          failedAssetsIds: new Set(failedAssetsIds),
          successfulAssetsIds: new Set(archivedAssetsIds),
          successfulFoldersIds: new Set(archivedFoldersIds),
          taxonomyGroup: folders,
          usedAssetsIds: new Set(usedAssetsIds),
        }),
      );

      return { archivedFoldersIds };
    } catch (error) {
      dispatch(failed('Asset folder could not be archived.'));
      throw InvariantException(`${__filename}: ${error}`);
    }
  };
