import { combineReducers } from '@reduxjs/toolkit';
import { failedIds } from './reducers/failedIds.ts';
import { lastAction } from './reducers/lastAction.ts';
import { successfulIds } from './reducers/successfulIds.ts';
import { unmodifiedIds } from './reducers/unmodifiedIds.ts';
import { variantId } from './reducers/variantId.ts';

export const statusInfo = combineReducers({
  lastAction,
  failedIds,
  unmodifiedIds,
  successfulIds,
  variantId,
});
