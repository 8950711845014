import Immutable from 'immutable';
import { Action } from '../../../@types/Action.type.ts';
import {
  WebSpotlight_ExpandNode_Failed,
  WebSpotlight_ExpandNode_Started,
  WebSpotlight_Node_Collapsed,
  WebSpotlight_ReloadNode_Failed,
  WebSpotlight_ReloadNode_Finished,
  WebSpotlight_SelectedNodeAncestors_Expanded,
} from '../constants/webSpotlightActionTypes.ts';
import { NodeId } from '../types/nodeId.type.ts';

const initialState = Immutable.Map<NodeId, IExpandedNodesData>();

export interface IExpandedNodesData {
  readonly itemId: Uuid;
  readonly subpagesIds: UuidArray;
}

export const expandedNodesData = (
  state = initialState,
  action: Action,
): Immutable.Map<NodeId, IExpandedNodesData> => {
  switch (action.type) {
    case WebSpotlight_ExpandNode_Started:
    case WebSpotlight_ReloadNode_Finished:
      return state.set(action.payload.nodeId, action.payload.nodeData);

    case WebSpotlight_SelectedNodeAncestors_Expanded:
      return state.merge(action.payload.expandedNodesData);

    case WebSpotlight_Node_Collapsed:
    case WebSpotlight_ExpandNode_Failed:
    case WebSpotlight_ReloadNode_Failed:
      return state.remove(action.payload.nodeId);

    default:
      return state;
  }
};
