import React, { createContext, useContext, useMemo } from 'react';

interface ContextProps {
  readonly isDraggingFiles: boolean;
}

export const DraggedFilesContext = createContext<ContextProps>({ isDraggingFiles: false });

export const DraggedFilesContextProvider: React.FC<React.PropsWithChildren<ContextProps>> = ({
  children,
  isDraggingFiles,
}) => {
  // When parent is dragging, we want to let all descendants know, this prevents cancelling the flag out in the nested contexts
  const { isDraggingFiles: isParentDraggingFiles } = useContext(DraggedFilesContext);
  const value = useMemo(
    () => ({ isDraggingFiles: isDraggingFiles || isParentDraggingFiles }),
    [isDraggingFiles, isParentDraggingFiles],
  );

  return <DraggedFilesContext.Provider value={value}>{children}</DraggedFilesContext.Provider>;
};

DraggedFilesContextProvider.displayName = 'DraggedFilesContextProvider';
