import { Action } from '../../../../../@types/Action.type.ts';
import {
  Calendar_ItemDetailsSelected,
  Calendar_ItemDetails_NavigateBack,
  Calendar_Page_Left,
  Calendar_PopupClosed,
} from '../../constants/calendarActionTypes.ts';
import { ItemDetails } from '../../types/ItemDetails.type.ts';

export type ItemDetailsState = ItemDetails | null;

const initialState: ItemDetailsState = null;

export const itemDetails = (
  state: ItemDetailsState = initialState,
  action: Action,
): ItemDetailsState => {
  switch (action.type) {
    case Calendar_ItemDetailsSelected: {
      return action.payload;
    }

    case Calendar_ItemDetails_NavigateBack:
    case Calendar_PopupClosed:
    case Calendar_Page_Left:
      return initialState;

    default:
      return state;
  }
};
