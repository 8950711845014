import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { isSampleProject } from '../../../../../../_shared/utils/sampleItemsUtils.ts';
import { getUrlFactory } from '../../../../../../_shared/utils/urlFactory.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getContentItemPreviewUrlPattern } from '../../../../utils/previewUtils.ts';

const getPreviewUrlPattern = ({
  contentApp: { previewConfiguration, editedContentItem },
}: IStore): string | undefined => {
  const contentTypeId = editedContentItem?.editedContentItemTypeId;

  return contentTypeId && previewConfiguration
    ? getContentItemPreviewUrlPattern(contentTypeId, previewConfiguration, null)
    : undefined;
};

export const canShowSampleAppPreview = (state: IStore): boolean => {
  const demoSampleAppDomain = getUrlFactory().getDeployedSampleAppDomain();
  const previewUrlPattern = getPreviewUrlPattern(state);
  const currentProject = getCurrentProject(state);

  if (isSampleProject(currentProject) && previewUrlPattern) {
    return previewUrlPattern.includes(demoSampleAppDomain);
  }

  return false;
};
