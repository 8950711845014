import { MultipleChoiceMode } from '../../../../_shared/models/MultipleChoiceModeEnum.ts';
import {
  MultipleChoiceConditionOperator,
  TypeElementCondition,
} from '../../../../_shared/models/utils/TypeElementCondition.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { isMultipleChoiceTypeElement } from '../types/typeElementTypeGuards.ts';

const getDependentElementsWithAllOperator = (
  triggerId: Uuid,
  typeElements: ReadonlyArray<IBaseTypeElementData>,
): ReadonlyArray<IBaseTypeElementData> =>
  typeElements.filter(
    (typeElement) =>
      typeElement.condition?.isActive &&
      typeElement.condition?.trigger?.elementId === triggerId &&
      typeElement.condition?.trigger?.operator === MultipleChoiceConditionOperator.All,
  );

const getElementWithFixedConditionOperator = (
  element: IBaseTypeElementData,
): IBaseTypeElementData => {
  if (!element.condition) {
    return element;
  }

  const fixedCondition: TypeElementCondition = {
    ...element.condition,
    trigger: {
      ...element.condition.trigger,
      operator: MultipleChoiceConditionOperator.Any,
    },
  };

  return { ...element, condition: fixedCondition };
};

export const fixConditionsOperatorsInTypeElements = (
  typeElements: ReadonlyArray<IBaseTypeElementData>,
): ReadonlyArray<IBaseTypeElementData> => {
  const multipleChoiceElements = typeElements.filter(isMultipleChoiceTypeElement);

  const elementsWithWrongConditionOperator = multipleChoiceElements
    .filter((element) => element.mode === MultipleChoiceMode.Single)
    .reduce(
      (result: ReadonlyArray<IBaseTypeElementData>, element) =>
        result.concat(getDependentElementsWithAllOperator(element.elementId, typeElements)),
      [],
    );

  return typeElements.map((element) => {
    if (elementsWithWrongConditionOperator.includes(element)) {
      return getElementWithFixedConditionOperator(element);
    }
    return element;
  });
};
