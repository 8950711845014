import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { CardPageTitle } from '../../../../_shared/components/CardPageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowSteps } from '../containers/WorkflowSteps.tsx';
import { WorkflowStepsQuickTip } from './WorkflowStepsQuickTip.tsx';

type WorkflowStepListProps = {
  readonly isAdvancedEditorEnabled: boolean;
};

export const WorkflowStepList: React.FC<WorkflowStepListProps> = ({ isAdvancedEditorEnabled }) => (
  <Card
    component="section"
    cardLabel="Workflow steps"
    {...getDataUiAppNameAttribute(DataUiAppName.WorkflowStepsListing)}
  >
    {isAdvancedEditorEnabled ? (
      <Card.Headline>Workflow steps</Card.Headline>
    ) : (
      <CardPageTitle text="Workflow steps" />
    )}
    <Card.Body>
      <Stack spacing={Spacing.XL}>
        <WorkflowSteps />
        <WorkflowStepsQuickTip />
      </Stack>
    </Card.Body>
  </Card>
);

WorkflowStepList.displayName = 'WorkflowStepList';
