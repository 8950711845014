import { ReactNode } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { SubscriptionEnvironmentSettingsRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ensureSubscriptionCurrentProject } from '../actions/thunkSubscriptionManagementActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureSubscriptionCurrentProject = ({ children }: Props) => {
  const history = useHistory();
  const { projectId } = useParams<SubscriptionEnvironmentSettingsRouteParams>();

  const [isCurrentProjectLoadThunkDone] = useThunkPromise(
    ensureSubscriptionCurrentProject,
    projectId,
    history,
  );

  const isStateEnsured = useSelector((state) => {
    const currentProject = getCurrentProject(state);
    return (
      isCurrentProjectLoadThunkDone &&
      isUuid(currentProject.projectId) &&
      currentProject.projectId === projectId
    );
  });

  return isStateEnsured ? children : <Loader />;
};
