import React, { Dispatch, SetStateAction } from 'react';

interface IBreadcrumbsContextState {
  readonly allowEllipsis: boolean;
  readonly setAllowEllipsis: Dispatch<SetStateAction<boolean>>;
}

export const BreadcrumbsContext = React.createContext<IBreadcrumbsContextState | undefined>(
  undefined,
);

export const BreadcrumbsContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const [allowEllipsis, setAllowEllipsis] = React.useState(false);
  const contextValue = React.useMemo(
    () => ({
      allowEllipsis,
      setAllowEllipsis,
    }),
    [allowEllipsis],
  );

  return <BreadcrumbsContext.Provider value={contextValue}>{children}</BreadcrumbsContext.Provider>;
};

BreadcrumbsContextProvider.displayName = 'BreadcrumbsContextProvider';
