import React, { useMemo } from 'react';
import { getActiveLanguagesWithFallbackChain } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { areFallbacksForLinkedContentEnabled } from '../../selectors/fallbacksForLinkedContent.ts';
import { getSelectedLanguageId } from '../../selectors/getSelectedLanguageId.ts';
import { findNearestTranslatedFallbackLanguageId } from '../../utils/languageUtils.ts';

export const NotTranslatedOrFallbacksStatus: React.FC<{
  readonly itemId: Uuid;
  readonly renderFallbacksStatus: (itemId: Uuid) => React.ReactElement;
  readonly renderNotTranslatedStatus: () => React.ReactElement;
}> = ({ itemId, renderFallbacksStatus, renderNotTranslatedStatus }) => {
  const translatedLanguageIds = useSelector(
    (s) => s.data.listingContentItems.byId.get(itemId)?.translatedLanguageIds,
  );
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const activeLanguages = useSelector((s) => getActiveLanguagesWithFallbackChain(s.data.languages));
  const isFeatureEnabled = useSelector(areFallbacksForLinkedContentEnabled);

  const showFallbacks = !!useMemo(
    () =>
      isFeatureEnabled &&
      selectedLanguageId &&
      translatedLanguageIds &&
      findNearestTranslatedFallbackLanguageId(
        translatedLanguageIds,
        activeLanguages,
        selectedLanguageId,
      ),
    [isFeatureEnabled, translatedLanguageIds, activeLanguages, selectedLanguageId],
  );

  return showFallbacks ? renderFallbacksStatus(itemId) : renderNotTranslatedStatus();
};

NotTranslatedOrFallbacksStatus.displayName = 'NotTranslatedOrFallbacksStatus';
