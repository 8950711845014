import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { documentationLinks } from '../../../_shared/constants/documentationLinks.ts';

export const EntityWebhookQuickTip = () => (
  <Callout calloutType="quickTip" maxWidth={300} headline="Know about changes to your content">
    <Stack spacing={Spacing.L}>
      <Box>
        To know about changes in your content, you can use{' '}
        <OutwardLink href={documentationLinks.newWebhooks}>webhooks</OutwardLink> or{' '}
        <OutwardLink href={documentationLinks.apiSync}>Sync API</OutwardLink>.
      </Box>
      <ul>
        <li>If you want to get notified about changes as they happen, use webhooks.</li>
        <li>If you want to check for changes yourself regularly, use Sync API.</li>
      </ul>
    </Stack>
  </Callout>
);

EntityWebhookQuickTip.displayName = 'EntityWebhookQuickTip';
