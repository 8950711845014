import { memoize } from '@kontent-ai/memoization';
import { CommonPlanName } from '../../../../data/constants/CommonPlanName.ts';
import { IFupMetric } from '../../../../data/models/plans/FupMetric.ts';
import { IPaidMetric, MetricNames } from '../../../../data/models/plans/PaidMetric.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { getPlanNameSearchKey } from '../../../../data/reducers/plans/selectors/planSelectors.ts';
import {
  IProjectContainerUsage,
  IProjectUsage,
  ISubscriptionUsage,
} from '../models/SubscriptionUsage.ts';

export function isPlanDowngrade(currentPlan: IPlan, targetPlan: IPlan): boolean {
  const currentMaxUsers =
    currentPlan.paidMetrics.find((m: IPaidMetric) => m.name === MetricNames.SubscriptionActiveUsers)
      ?.includedUnits ?? null;
  const targetMaxUsers =
    targetPlan.paidMetrics.find((m: IPaidMetric) => m.name === MetricNames.SubscriptionActiveUsers)
      ?.includedUnits ?? null;

  if (targetMaxUsers === null) {
    return false;
  }
  if (currentMaxUsers === null) {
    return true;
  }
  return currentMaxUsers > targetMaxUsers;
}

function isTargetPlanExceedingMaxEnvironments(
  targetPlan: IPlan,
  subscriptionUsage: ISubscriptionUsage,
): boolean {
  const maxEnvironments = targetPlan.features.maxProjectEnvironments;
  if (maxEnvironments === null) {
    return false;
  }

  return subscriptionUsage.projectContainers.some(
    (container: IProjectContainerUsage) =>
      !!container && container.projects?.count() > maxEnvironments,
  );
}

function isTargetPlanExceedingLanguages(
  targetPlan: IPlan,
  subscriptionUsage: ISubscriptionUsage,
): boolean {
  const maxLanguages = targetPlan.features.maxActiveLanguages;
  if (maxLanguages === null) {
    return false;
  }

  return subscriptionUsage.projectContainers.some((container: IProjectContainerUsage) =>
    container.projects?.some(
      (projectUsage: IProjectUsage) => projectUsage.languageCount > maxLanguages,
    ),
  );
}

function areUsersWithLanguageRolesPreventingPlanDowngrade(
  targetPlan: IPlan,
  subscriptionUsage: ISubscriptionUsage,
): boolean {
  if (targetPlan.features.areLanguageRolesEnabled) {
    return false;
  }

  return subscriptionUsage.projectContainers.some((container: IProjectContainerUsage) =>
    container.projects?.some(
      (projectUsage: IProjectUsage) => projectUsage.userWithLanguageRoleCount > 0,
    ),
  );
}

function isTargetPlanExceedingMaxActiveUsers(
  targetPlan: IPlan,
  subscriptionUsage: ISubscriptionUsage,
): boolean {
  const maxUsers = targetPlan.features.maxActiveUsers;
  if (maxUsers === null) {
    return false;
  }

  return subscriptionUsage.currentActiveUsers > maxUsers;
}

function isTargetPlanExceedingMaxCustomRoles(
  targetPlan: IPlan,
  subscriptionUsage: ISubscriptionUsage,
): boolean {
  const maxCustomRoles = targetPlan.features.maxCustomRoles;
  if (maxCustomRoles === null) {
    return false;
  }

  return subscriptionUsage.projectContainers.some((container: IProjectContainerUsage) =>
    container.projects?.some(
      (projectUsage: IProjectUsage) => projectUsage.customRoleCount > maxCustomRoles,
    ),
  );
}

function isTargetPlanExceedingMaxProjects(
  targetPlan: IPlan,
  subscriptionUsage: ISubscriptionUsage,
): boolean {
  const maxProjects = targetPlan.features.maxSubscriptionProjects;
  if (maxProjects === null) {
    return false;
  }

  return subscriptionUsage.projectContainers.count() > maxProjects;
}

export type PlanUsageComparison = {
  readonly hasTooManyEnvironments: boolean;
  readonly hasTooManyLanguages: boolean;
  readonly hasTooManyProjects: boolean;
  readonly hasTooManyRoles: boolean;
  readonly hasTooManyUsers: boolean;
  readonly hasUsersWithNotAllowedLanguageRoles: boolean;
  readonly isExceeding: boolean;
};

export const comparePlanWithUsage = memoize.maxOne(
  (
    targetPlan: IPlan | undefined,
    subscriptionUsage: ISubscriptionUsage | undefined,
  ): PlanUsageComparison => {
    if (!targetPlan || !subscriptionUsage) {
      return {
        isExceeding: true,
        hasTooManyRoles: true,
        hasTooManyLanguages: true,
        hasTooManyProjects: true,
        hasTooManyUsers: true,
        hasUsersWithNotAllowedLanguageRoles: true,
        hasTooManyEnvironments: true,
      };
    }

    const hasTooManyEnvironments = isTargetPlanExceedingMaxEnvironments(
      targetPlan,
      subscriptionUsage,
    );
    const hasTooManyLanguages = isTargetPlanExceedingLanguages(targetPlan, subscriptionUsage);
    const hasTooManyUsers = isTargetPlanExceedingMaxActiveUsers(targetPlan, subscriptionUsage);
    const hasTooManyProjects = isTargetPlanExceedingMaxProjects(targetPlan, subscriptionUsage);
    const hasTooManyRoles = isTargetPlanExceedingMaxCustomRoles(targetPlan, subscriptionUsage);
    const hasUsersWithNotAllowedLanguageRoles = areUsersWithLanguageRolesPreventingPlanDowngrade(
      targetPlan,
      subscriptionUsage,
    );
    const isExceeding =
      hasTooManyEnvironments ||
      hasTooManyLanguages ||
      hasTooManyProjects ||
      hasTooManyRoles ||
      hasTooManyUsers ||
      hasUsersWithNotAllowedLanguageRoles;
    return {
      hasTooManyLanguages,
      hasTooManyProjects,
      hasTooManyRoles,
      hasTooManyUsers,
      hasUsersWithNotAllowedLanguageRoles,
      hasTooManyEnvironments,
      isExceeding,
    };
  },
);

export const getPaidMetric = (plan: IPlan, name: string): IPaidMetric | undefined =>
  plan.paidMetrics.find((p: IPaidMetric) => p.name === name);

export const getFupMetric = (plan: IPlan, name: string) =>
  plan.fupMetrics.find((p: IFupMetric) => p.name === name);

export function getPlanUserPaidMetric(plan: IPlan | null): IPaidMetric | undefined | null {
  if (plan) {
    return getPaidMetric(plan, MetricNames.SubscriptionActiveUsers);
  }
  return null;
}

export function getExtraUsage(
  plan: IPlan,
  currentActiveUsers: number,
): { extraUsers: number; extraCosts: number } | null {
  const metric = getPlanUserPaidMetric(plan);
  if (!metric) {
    return null;
  }

  const extraUsers = currentActiveUsers - metric.includedUnits;
  if (
    extraUsers > 0 &&
    (!plan.features.maxActiveUsers || currentActiveUsers <= plan.features.maxActiveUsers)
  ) {
    return {
      extraUsers,
      extraCosts: extraUsers * metric.extraPackagePrice,
    };
  }

  return null;
}

export const getMetricIncludedUnits = (plan: IPlan, name: string): number | undefined => {
  const metric = getPaidMetric(plan, name) || getFupMetric(plan, name);

  return metric?.includedUnits;
};

export const getPlanName = (planName: string): string => {
  const planNames = [
    CommonPlanName.Trial,
    CommonPlanName.Starter,
    CommonPlanName.Developer,
    CommonPlanName.Professional,
    CommonPlanName.Business,
    CommonPlanName.Premium,
  ];
  return (
    planNames.find((prefix: string) => new RegExp(`^${prefix}-v\\d+$`, 'g').test(planName)) ||
    planName
  );
};

export const isStandardPlan = memoize.maxOne((planName: string) => {
  switch (getPlanName(planName)) {
    case CommonPlanName.Starter:
    case CommonPlanName.Professional:
    case CommonPlanName.Developer:
    case CommonPlanName.Business:
    case CommonPlanName.Premium:
      return true;
    default:
      return false;
  }
});

export const isPartnerPlan = (plan: IPlan): boolean =>
  plan.name.startsWith(getPlanNameSearchKey('Partner'));
