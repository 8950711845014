import { Action } from '../../../../../../@types/Action.type.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  ContentItemEditing_CodenameEditor_Changed,
  ContentItemEditing_CodenameEditor_NotUniqueError,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_Saving_Failed,
  ContentItemEditing_Saving_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemCodenameStatus } from '../../../../models/contentItem/ContentItemCodenameStatus.ts';

export const status = (
  state: ContentItemCodenameStatus = ContentItemCodenameStatus.Saved,
  action: Action,
): ContentItemCodenameStatus => {
  switch (action.type) {
    case ContentItemEditing_Init_Ready:
      return ContentItemCodenameStatus.Saved;

    case ContentItemEditing_ItemSaving_Finished: {
      return action.payload.operationId === itemEditorOperationIds.codename
        ? ContentItemCodenameStatus.Saved
        : state;
    }

    case ContentItemEditing_Saving_Started: {
      return action.payload.operationId === itemEditorOperationIds.codename
        ? ContentItemCodenameStatus.IsBeingSaved
        : state;
    }

    case ContentItemEditing_CodenameEditor_NotUniqueError:
      return ContentItemCodenameStatus.CodenameNotUniqueError;

    case ContentItemEditing_CodenameEditor_Changed:
      return ContentItemCodenameStatus.NotSaved;

    case ContentItemEditing_Saving_Failed: {
      return action.payload.operationId === itemEditorOperationIds.codename &&
        state === ContentItemCodenameStatus.IsBeingSaved
        ? ContentItemCodenameStatus.Saved
        : state;
    }

    default:
      return state;
  }
};
