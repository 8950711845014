import { History } from 'history';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { YourContentEventActionTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createNewContentItem } from '../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { createContentItemFromContentTypeWithSelectedLanguageCreator } from '../../../../itemEditor/features/NewContentItem/actions/thunks/createContentItemFromContentType.ts';

const redirectToItem = (history: History, itemId: Uuid, variantId: Uuid): void => {
  const contentItemPath = getContentItemPath(history.location.pathname, itemId, variantId);
  history.push(contentItemPath);
};

export const createNewContentItemFromContentStatus =
  createContentItemFromContentTypeWithSelectedLanguageCreator({
    createNewContentItem,
    redirectToItem,
    trackItemCreated: (contentTypeSection, collectionSection) =>
      trackUserEventWithData(TrackedEvent.ContentStatus, {
        action: YourContentEventActionTypes.CreateContentItem,
        'collection-section': collectionSection,
        'content-type-section': contentTypeSection,
      }),
  });
