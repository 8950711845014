import { IStore } from '../stores/IStore.type.ts';
import {
  ModalProperties,
  ModalTypesWithRequiredProperties,
} from '../types/ModalDialogProperties.type.ts';

const areModalPropertiesForCorrectDialog = <
  TDialogType extends keyof ModalTypesWithRequiredProperties,
  TModalProperties extends ModalTypesWithRequiredProperties[TDialogType],
>(
  modalProperties: ModalProperties,
  dialogType: TDialogType,
): modalProperties is TModalProperties => modalProperties.dialogType === dialogType;

export const getModalDialogProperties =
  <
    TDialogType extends keyof ModalTypesWithRequiredProperties,
    TModalProperties extends ModalTypesWithRequiredProperties[TDialogType],
  >(
    dialogType: TDialogType,
  ) =>
  (state: IStore): TModalProperties | null => {
    const {
      sharedApp: {
        modalDialog: { properties },
      },
    } = state;

    return properties &&
      areModalPropertiesForCorrectDialog<TDialogType, TModalProperties>(properties, dialogType)
      ? properties
      : null;
  };
