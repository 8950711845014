import { UrlSlugTypeElementValidationResult } from '../types/UrlSlugTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const UrlSlugInitialValidationResult: UrlSlugTypeElementValidationResult = {
  isGuidelinesValid: true,
  isNameValid: true,
  isMaxCharsValid: true,
  isDependedOnValidTextElement: true,
  errorMessages: [],
  isRegexPatternValid: true,
  isRegexLengthValid: true,
  isRegexValidationMessageLengthValid: true,
  conditionValidationResult: InitialConditionValidationResult,
};
