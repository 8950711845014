import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { IApiTokenDetailServerModel } from '../../../../repositories/serverModels/IApiTokenDetailServerModel.type.ts';
import { ICreateApiTokenServerModel } from '../../../../repositories/serverModels/ICreateApiTokenServerModel.type.ts';
import { IUpdateApiTokenServerModel } from '../../../../repositories/serverModels/IUpdateApiTokenServerModel.type.ts';
import { MapiKeyCapability } from './MapiKeyCapability.type.ts';

export type ApiKey = {
  readonly apiKey: string;
  readonly environments: ReadonlyArray<Uuid>;
  readonly expiresAt: DateTimeStamp;
  readonly hasAccessToAllEnvironments: boolean;
  readonly hasPreviewDeliveryAccess: boolean;
  readonly hasSecureDeliveryAccess: boolean;
  readonly mapiKeyCapabilities: ReadonlyArray<MapiKeyCapability>;
  readonly name: string;
  readonly sharedWithUsers: ReadonlyArray<string>;
  readonly tokenSeedId: Uuid;
  readonly type: ApiKeyType;
};

export const emptyApiKey: ApiKey = {
  apiKey: '',
  environments: [],
  expiresAt: '',
  hasAccessToAllEnvironments: false,
  hasPreviewDeliveryAccess: false,
  hasSecureDeliveryAccess: false,
  mapiKeyCapabilities: [],
  name: '',
  sharedWithUsers: [],
  tokenSeedId: '',
  type: ApiKeyType.Unknown,
};

export const createApiKeyFromServerModel = (serverModel: IApiTokenDetailServerModel): ApiKey => {
  if (typeof serverModel !== 'object') {
    logError('API key is not an object');
  }

  return {
    ...emptyApiKey,
    apiKey: serverModel.api_key,
    expiresAt: serverModel.expires_at,
    environments: serverModel.environments,
    hasAccessToAllEnvironments: serverModel.has_access_to_all_environments,
    hasPreviewDeliveryAccess: serverModel.has_preview_delivery_access,
    hasSecureDeliveryAccess: serverModel.has_secure_delivery_access,
    mapiKeyCapabilities: serverModel.management_api_key_capabilities,
    name: serverModel.name,
    sharedWithUsers: serverModel.shared_with_users,
    tokenSeedId: serverModel.token_seed_id,
    type: serverModel.type,
  };
};

export const convertToUpdateServerModel = (apiKey: ApiKey): IUpdateApiTokenServerModel => ({
  environments: apiKey.environments,
  has_access_to_all_environments: apiKey.hasAccessToAllEnvironments,
  has_preview_delivery_access: apiKey.hasPreviewDeliveryAccess,
  has_secure_delivery_access: apiKey.hasSecureDeliveryAccess,
  management_api_key_capabilities: apiKey.mapiKeyCapabilities,
  name: apiKey.name,
  shared_with_users: apiKey.sharedWithUsers,
});

export const convertToCreateServerModel = (apiKey: ApiKey): ICreateApiTokenServerModel => {
  if (apiKey.type === ApiKeyType.Unknown) {
    logError('API key has unknown type');
    throw new Error('API key has unknown type');
  }

  return {
    environments: apiKey.environments,
    expires_at: apiKey.expiresAt,
    has_access_to_all_environments: apiKey.hasAccessToAllEnvironments,
    has_preview_delivery_access: apiKey.hasPreviewDeliveryAccess,
    has_secure_delivery_access: apiKey.hasSecureDeliveryAccess,
    management_api_key_capabilities: apiKey.mapiKeyCapabilities,
    name: apiKey.name,
    shared_with_users: apiKey.sharedWithUsers,
    type: apiKey.type,
  };
};
