import { InvariantException } from '@kontent-ai/errors';
import { ISubscriptionPlanServerModel } from '../../../repositories/serverModels/PlanServerModel.type.ts';

export interface ISubscriptionPlan {
  readonly endAt: DateTimeStamp;
  readonly planId: Uuid;
  readonly startAt: DateTimeStamp;
  readonly hasPaperContract: boolean;
}

export function createSubscriptionPlan(props?: Partial<ISubscriptionPlan>): ISubscriptionPlan {
  return {
    endAt: '',
    planId: '',
    startAt: '',
    hasPaperContract: false,
    ...props,
  };
}

export function createSubscriptionPlanFromServerModel(
  serverModel: ISubscriptionPlanServerModel,
): ISubscriptionPlan {
  if (typeof serverModel !== 'object') {
    throw InvariantException('Subscription plan is not an object');
  }

  const emptyObject = createSubscriptionPlan();

  return {
    endAt: serverModel.endAt ?? emptyObject.endAt,
    planId: serverModel.planId ?? emptyObject.planId,
    startAt: serverModel.startAt ?? emptyObject.startAt,
    hasPaperContract: serverModel.hasPaperContract ?? emptyObject.hasPaperContract,
  };
}
