import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_SelectedContentTypeChanged,
} from '../../constants/newContentItemActionTypes.ts';

export const contentTypeId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.contentTypeId;

    case NewContentItem_SelectedContentTypeChanged:
      return action.payload.contentTypeId;

    default:
      return state;
  }
};
