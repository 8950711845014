import { useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { openProject } from '../actions/thunkSmartLinkActions.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EnvironmentRouteParams } from '../constants/routePaths.ts';

export const OpenProjectSmartLink = () => {
  const { projectId } = useParams<EnvironmentRouteParams>();

  useThunkPromise(openProject, projectId);

  return <SmartLink />;
};
