import { usePrevious } from '@kontent-ai/hooks';
import {
  addDays,
  differenceInCalendarWeeks,
  endOfMonth,
  isSameMonth,
  isSameYear,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useEffect, useState } from 'react';

const numberOfDaysInWeek = 7;

const generateMatrix = (date: Date, weekStartsOn: DayOfWeek): Date[][] => {
  const firstDayOfMonth = startOfMonth(date);
  const lastDayOfMonth = endOfMonth(date);

  const firstDateOfFirstWeek = startOfWeek(firstDayOfMonth, { weekStartsOn });
  const numberOfWeeksBetweenLastAndFirstDay = differenceInCalendarWeeks(
    lastDayOfMonth,
    firstDayOfMonth,
    { weekStartsOn },
  );
  const totalNumberOfDays = (numberOfWeeksBetweenLastAndFirstDay + 1) * numberOfDaysInWeek;

  const isFirstDayInWeek = (dayIndex: number): boolean => dayIndex % numberOfDaysInWeek === 0;

  const splitDaysToWeeks = (matrix: Date[][], dayIndex: number, days: Date[]) =>
    isFirstDayInWeek(dayIndex)
      ? [...matrix, days.slice(dayIndex, dayIndex + numberOfDaysInWeek)]
      : matrix;

  return Array.from({ length: totalNumberOfDays })
    .map((_, dayIndex) => addDays(firstDateOfFirstWeek, dayIndex))
    .reduce((matrix, _, dayIndex, days) => splitDaysToWeeks(matrix, dayIndex, days), []);
};

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const useCalendarMatrix = (
  date: Date,
  weekStartsOn: DayOfWeek | undefined = DayOfWeek.Monday,
): Date[][] => {
  const previousDate = usePrevious(date);
  const [matrix, setMatrix] = useState(generateMatrix(date, weekStartsOn));

  useEffect(() => {
    if (isSameYear(date, previousDate) && isSameMonth(date, previousDate)) {
      return;
    }

    setMatrix(generateMatrix(date, weekStartsOn));
  }, [date, weekStartsOn, previousDate]);

  return matrix;
};
