import { InvariantException } from '@kontent-ai/errors';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
  PreviewURLsRoute,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventTypes } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { HotjarTag, tagRecording } from '../../../../../../../_shared/utils/hotjarUtils.ts';
import {
  buildPath,
  matchPath,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { deactivateContentItemEditingAction } from '../../../actions/contentItemEditingActions.ts';
import { LeafActionFormProps } from '../../../components/editingActions/EditingLeafActionForm.tsx';
import { NotSetupPreviewPopupForm as NotSetupPreviewPopupFormComponent } from '../../../components/editingActions/leafActionForms/NotSetupPreviewPopupForm.tsx';

export const NotSetupPreviewPopupForm = ({ actionOrigin }: LeafActionFormProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const matchParams = matchPath<EnvironmentRouteParams>(location.pathname, EnvironmentRoute);
  if (!matchParams) {
    throw InvariantException('Current route does not belong to ProjectRoute routes');
  }

  const settingsPath = buildPath<EnvironmentRouteParams>(PreviewURLsRoute, {
    projectId: matchParams.projectId,
  });

  useEffect(() => {
    dispatch(
      trackUserEventWithData(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingEventTypes.NotSetupPreviewSelected,
        origin: actionOrigin,
      }),
    );

    tagRecording(HotjarTag.DisabledPreviewButtonClicked);
  }, [actionOrigin]);

  const navigate = () => {
    dispatch(
      trackUserEventWithData(TrackedEvent.NavigationLinkOpened, { 'link-id': 'preview-setup' }),
    );
    dispatch(deactivateContentItemEditingAction());
  };

  return <NotSetupPreviewPopupFormComponent onNavigate={navigate} settingsPath={settingsPath} />;
};
