import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { Taxonomy_Terms_TermPickedUp } from '../../constants/taxonomyActionTypes.ts';
import { getLeftSiblingId, getParentNode } from '../../utils/taxonomyTreeUtils.ts';

interface IPickUpTaxonomyTermActionPayload {
  readonly draggedTermId: Uuid;
  readonly originalLeftSibling: Uuid | null;
  readonly originalParent: Uuid;
}

const taxonomyTermPickedUp = (payload: IPickUpTaxonomyTermActionPayload) =>
  ({
    type: Taxonomy_Terms_TermPickedUp,
    payload,
  }) as const;

export type PickUpTaxonomyTermActionsType = ReturnType<typeof taxonomyTermPickedUp>;

export const createTaxonomyTermPickedUpAction =
  () =>
  (termId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const {
      taxonomyApp: { editedTaxonomyGroup },
    } = getState();

    const parentNode = getParentNode(editedTaxonomyGroup, termId);

    if (!parentNode) {
      throw InvariantException(`Parent of term ${termId} not found`);
    }

    const originalLeftSibling = getLeftSiblingId(parentNode, termId);

    dispatch(
      taxonomyTermPickedUp({
        draggedTermId: termId,
        originalLeftSibling,
        originalParent: parentNode.id,
      }),
    );
  };
