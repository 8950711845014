import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { ReactNode } from 'react';

export const ApiKeyDetailToolbarSaveButtonElementId: Uuid = '0ea15f1f-c324-498d-86a8-32d71b40b6be';

type ApiKeyDetailToolbarActionsProps = {
  readonly renderRevokeButton: () => ReactNode;
};

export const ApiKeyDetailToolbarActions: React.FC<ApiKeyDetailToolbarActionsProps> = ({
  renderRevokeButton,
}) => (
  <Row spacing={Spacing.M}>
    <Column>
      <div id={ApiKeyDetailToolbarSaveButtonElementId} />
    </Column>
    <Column>{renderRevokeButton()}</Column>
  </Row>
);
