import { ReactNode, createContext } from 'react';

export const ContentTypeAsComponentUsageDialogContext = createContext<boolean>(false);

type Props = {
  readonly children: ReactNode;
};

export const ContentTypeAsComponentUsageDialogProvider = ({ children }: Props) => {
  // When the context is used, it always gives true
  // We need to pass the true value to the component as a variable otherwise ESLint complains about typing of the value property of the context
  const isInContentTypeAsComponentUsageDialog = true;

  return (
    <ContentTypeAsComponentUsageDialogContext.Provider
      value={isInContentTypeAsComponentUsageDialog}
    >
      {children}
    </ContentTypeAsComponentUsageDialogContext.Provider>
  );
};
