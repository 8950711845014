import Immutable from 'immutable';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { actionTypeChanged } from '../actions/auditLogFilterActions.ts';
import { AuditLogFilterSelector } from '../components/AuditLogFilterSelector.tsx';
import { ActionType, ActionTypeList } from '../constants/ActionType.ts';

export const ActionTypeFilterSelector: React.FC = () => {
  const auditLogFilter = useSelector((state: IStore) => state.auditLogApp.auditLogFilter);
  const dispatch = useDispatch();

  const selectedActionTypesMemoized = useMemo(() => {
    return auditLogFilter.selectedActionTypes.toArray();
  }, [auditLogFilter]);

  const onChange = useCallback((selectedActionTypes: ReadonlyArray<ActionType>) => {
    dispatch(actionTypeChanged(Immutable.Set(selectedActionTypes)));
  }, []);

  return (
    <AuditLogFilterSelector
      title="How it changed"
      collection={DataUiCollection.AuditLogActions}
      options={ActionTypeList}
      selectedOptions={selectedActionTypesMemoized}
      placeholder="Select an action"
      onFilterChanged={onChange}
    />
  );
};

ActionTypeFilterSelector.displayName = 'ActionTypeFilterSelector';
