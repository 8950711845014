import { combineReducers } from '@reduxjs/toolkit';
import { deletedTaxonomyGroupIds } from './internalReducers/deletedTaxonomyGroupIds.ts';
import { draggedTermDiff } from './internalReducers/draggedTermDiff.ts';
import { draggedTermId } from './internalReducers/draggedTermId.ts';
import { editedTaxonomyGroup } from './internalReducers/editedTaxonomyGroup.ts';
import { editedTaxonomyGroupIsUsedInPublishedItems } from './internalReducers/editedTaxonomyGroupIsUsedInPublishedItems.ts';
import { editedTaxonomyTermId } from './internalReducers/editedTaxonomyTermId.ts';
import { isCreateNewFormDisplayed } from './internalReducers/isCreateNewFormDisplayed.ts';
import { selectedTaxonomyGroupIds } from './internalReducers/selectedTaxonomyGroupIds.ts';
import { status } from './internalReducers/status.ts';
import { taxonomyGroupListStatusInfoMessage } from './internalReducers/taxonomyGroupListStatusInfoMessage.ts';
import { taxonomyGroups } from './internalReducers/taxonomyGroups.ts';

export const taxonomyAppReducer = combineReducers({
  deletedTaxonomyGroupIds,
  draggedTermId,
  draggedTermDiff,
  editedTaxonomyGroup,
  editedTaxonomyGroupIsUsedInPublishedItems,
  editedTaxonomyTermId,
  isCreateNewFormDisplayed,
  selectedTaxonomyGroupIds,
  status,
  taxonomyGroupListStatusInfoMessage,
  taxonomyGroups,
});
