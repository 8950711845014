import { Action } from '../../../../../../@types/Action.type.ts';
import { ContentTypeSection } from '../../constants/contentTypeSection.ts';
import {
  NewContentItem_Initialized,
  NewContentItem_SelectedContentTypeChanged,
} from '../../constants/newContentItemActionTypes.ts';

const initialState = ContentTypeSection.None;

export const contentTypeSection = (state = initialState, action: Action): ContentTypeSection => {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.initialSelectedValues.contentTypeSection;

    case NewContentItem_SelectedContentTypeChanged:
      return action.payload.contentTypeSection;

    default:
      return state;
  }
};
