import { InvariantException } from '@kontent-ai/errors';
import { createGuid } from '@kontent-ai/utils';
import { Pathname } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { baseEditorApi } from '../../../../../richText/editorCore/api/baseEditorApi.ts';
import { bindApiMethods } from '../../../../../richText/editorCore/hooks/bindApiMethods.ts';
import { PluginApi } from '../../../../../richText/editorCore/types/Editor.api.type.ts';
import { SimpleTextEditorLimitations } from '../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { createEditorLimitationsApi } from '../../../../../richText/plugins/apiLimitations/api/editorLimitationsApi.ts';
import { TextApiPlugin } from '../../../../../richText/plugins/textApi/TextApiPlugin.tsx';
import { editorTextApi } from '../../../../../richText/plugins/textApi/api/editorTextApi.ts';
import { getSimpleTextValue } from '../../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { IUrlSlugItemElement } from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { getElementById } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { SaveElementValuesToServerAction } from './saveElementValuesToServer.ts';

const api: PluginApi<TextApiPlugin> = bindApiMethods({
  ...baseEditorApi,
  ...createEditorLimitationsApi(SimpleTextEditorLimitations),
  ...editorTextApi,
});

type AutoGenerateUrlSlugDependencies = Readonly<{
  saveElementValuesToServer: SaveElementValuesToServerAction;
  contentItemRepository: IContentItemRepository;
}>;

export type AutoGenerateUrlSlugAction = (
  params: Readonly<{
    elementId: Uuid;
    pathname: Pathname;
  }>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

export const createAutoGenerateUrlSlugAction =
  (deps: AutoGenerateUrlSlugDependencies): AutoGenerateUrlSlugAction =>
  ({ elementId, pathname }, abortSignal) =>
  async (dispatch, getState) => {
    const { editedContentItemVariantElements, editedContentItemVariant } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw InvariantException('Cannot generate url slug. "editedContentItemVariant" is null');
    }

    const element = getElementById<IUrlSlugItemElement>(
      elementId,
      editedContentItemVariantElements,
    );
    if (!element) {
      return;
    }

    const urlSlugValue = await deps.contentItemRepository.generateUrlSlug(
      editedContentItemVariant.id.itemId,
      editedContentItemVariant.id.variantId,
      elementId,
      abortSignal,
    );

    const elementToUpdate = getElementById<IUrlSlugItemElement>(
      elementId,
      getState().contentApp.editedContentItemVariantElements,
    );
    if (
      !elementToUpdate ||
      (urlSlugValue === getSimpleTextValue(elementToUpdate._editorState) &&
        elementToUpdate.mode === UrlSlugMode.Auto)
    ) {
      return;
    }

    const newAutoGenerateSlugElement: IUrlSlugItemElement = {
      ...elementToUpdate,
      mode: UrlSlugMode.Auto,
      _editorState: api.replaceEditorText(elementToUpdate._editorState, urlSlugValue),
    };

    await dispatch(
      deps.saveElementValuesToServer(
        {
          elementsData: [newAutoGenerateSlugElement],
          operationId: createGuid(),
          pathname,
        },
        abortSignal,
      ),
    );
  };
