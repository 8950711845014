import { Icons } from '@kontent-ai/component-library/Icons';
import { SimpleStatusDefault, SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';
import {
  isTimeInPast,
  renderDatetimeString,
} from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { ApiKeyExpired, ApiKeyExpiresAt } from '../../constants/infoMessageTemplates.ts';

type ExpirationDateStatusProps = {
  readonly expirationDate: DateTimeStamp;
};

export const ExpirationDateStatus: React.FC<ExpirationDateStatusProps> = ({ expirationDate }) => {
  const isApiKeyExpired = isTimeInPast(new Date(), expirationDate);

  return isApiKeyExpired ? (
    <SimpleStatusError
      tooltipText="To change the expiration date, you need to regenerate the API key."
      tooltipPlacement="top-start"
      label={ApiKeyExpired(renderDatetimeString(expirationDate))}
    />
  ) : (
    <SimpleStatusDefault
      tooltipText="To change the expiration date, you need to regenerate the API key."
      tooltipPlacement="top-start"
      icon={Icons.ICircle}
      label={ApiKeyExpiresAt(renderDatetimeString(expirationDate))}
    />
  );
};

ExpirationDateStatus.displayName = 'ExpirationDateStatus';
