import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadLanguageUsages } from '../../../../data/actions/thunkDataActions.ts';
import { contentVariantIdStorage } from '../../../../localStorages/contentVariantStorage.ts';
import { loadSubscriptionUsage } from '../../../subscriptionManagement/shared/actions/thunkSubscriptionManagementActions.ts';
import { createNewLanguageAction } from './thunks/createNewLanguage.ts';
import { createInitLanguagesEditorAction } from './thunks/initLanguagesEditor.ts';
import { createLoadItemVariantCount } from './thunks/loadItemVariantCount.ts';
import { createLoadLanguagesForLocalizationEditorAction } from './thunks/loadLanguagesForLocalizationEditor.ts';
import { createSaveLanguagesToServerAction } from './thunks/saveLanguagesToServer.ts';

const { languageRepository, projectRepository } = repositoryCollection;

const loadItemVariantCount = createLoadItemVariantCount({
  projectRepository,
});

export const loadLanguagesForEditor = createLoadLanguagesForLocalizationEditorAction({
  contentVariantIdStorage,
  languageRepository,
});

export const initLanguagesEditor = createInitLanguagesEditorAction({
  loadLanguagesForEditor,
  loadLanguageUsages,
  loadSubscriptionUsage,
  loadItemVariantCount,
});

export const saveLanguagesToServer = createSaveLanguagesToServerAction({
  loadLanguagesForEditor,
  loadLanguageUsages,
  languageRepository,
});

export const createNewLanguage = createNewLanguageAction({
  trackUserEvent,
});
