import { loadUserInfo } from '../../data/actions/thunkDataActions.ts';
import { createHandleServerErrorResponseAction } from './thunks/handleServerErrorResponse.ts';
import { createHandleServerResponseAction } from './thunks/handleServerResponse.ts';
import { createInitializeRepositoriesAction } from './thunks/initializeRepositories.ts';

export const handleServerErrorResponse = createHandleServerErrorResponseAction({ loadUserInfo });

export const handleServerResponse = createHandleServerResponseAction;

export const initializeRepositories = createInitializeRepositoriesAction({
  handleServerResponse,
  handleServerErrorResponse,
});
