import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { AiMenuItem } from '../../../../../_shared/features/AI/components/AiActionMenu.tsx';

export const createAddSelectedTermsAiAction = (onClick?: () => void): AiMenuItem | null =>
  onClick
    ? {
        id: 'add-selected-terms',
        label: 'Add selected terms',
        iconName: IconName.Check,
        onClick,
      }
    : null;

export const createReplaceSelectedTermsAiAction = (onClick?: () => void): AiMenuItem | null =>
  onClick
    ? {
        id: 'replace-selected-terms',
        label: 'Replace selected terms',
        iconName: IconName.ArrowsCrooked,
        onClick,
      }
    : null;
