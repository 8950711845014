import { ReactNode, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import {
  ContentItemRevisionViewerRouteParams,
  ContentItemTimelineItemRoute,
  ContentItemTimelineRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  UseThunkPromiseStatus,
  useThunkPromise,
} from '../../../../../_shared/hooks/useThunkPromise.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { initViewRevision } from '../../Revisions/actions/thunkRevisionsActions.ts';

type Props = {
  readonly contentItemId: Uuid;
  readonly children: ReactNode;
  readonly variantId: Uuid;
  readonly timelineItemId: Uuid;
};

export const EnsureSelectedRevision = ({
  contentItemId,
  children,
  timelineItemId,
  variantId,
}: Props) => {
  const history = useHistory();
  const routeParams = useParams<ContentItemRevisionViewerRouteParams<string>>();

  const params = useMemo(
    () => ({
      contentItemId,
      timelineItemId,
      history,
      routeParams,
    }),
    [contentItemId, timelineItemId, history, routeParams],
  );
  const [isInitThunkDone, , status] = useThunkPromise(initViewRevision, params);

  const isStateEnsured = useSelector((state) => {
    const {
      contentApp: { selectedRevision },
    } = state;

    return (
      isInitThunkDone &&
      !!selectedRevision &&
      selectedRevision.contentItemId.itemId === contentItemId &&
      selectedRevision.contentItemId.variantId === variantId &&
      selectedRevision.itemId === timelineItemId
    );
  });

  useEffect(() => {
    if (status === UseThunkPromiseStatus.Failed) {
      const fallbackPath = buildPath<ContentItemTimelineRouteParams<UuidArray>>(
        ContentItemTimelineItemRoute,
        {
          app: routeParams.app,
          projectId: routeParams.projectId,
          variantId: routeParams.variantId,
          spaceId: routeParams.spaceId,
          contentItemIds: parseContentItemIds(routeParams.contentItemIds),
        },
      );
      history.push(fallbackPath);
    }
  }, [
    history,
    status,
    routeParams.app,
    routeParams.projectId,
    routeParams.variantId,
    routeParams.spaceId,
    routeParams.contentItemIds,
  ]);

  return isStateEnsured ? children : <Loader />;
};
