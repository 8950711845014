import React, { ReactNode } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { SubscriptionStatus } from '../../../../data/models/subscriptions/Subscription.ts';
import {
  getSelectedSubscription,
  isAdminOnSubscription,
} from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { acceptTermsOfService } from '../actions/thunkSubscriptionManagementActions.ts';
import { AcceptSubscriptionTermsOfService } from '../components/AcceptSubscriptionTermsOfService.tsx';

type Props = {
  readonly children: ReactNode;
};

export const EnforceServiceAgreement: React.FC<Props> = ({ children }) => {
  const subscription = useSelector((state) => {
    const selectedSubscription = getSelectedSubscription(state);

    return (
      selectedSubscription ??
      state.data.subscriptions.byId.get(getCurrentProject(state).subscriptionId)
    );
  });

  const isSubscriptionAdmin = useSelector((state) =>
    isAdminOnSubscription(state, subscription?.subscriptionId ?? ''),
  );

  const dispatch = useDispatch();

  if (!subscription) {
    return null;
  }

  const {
    subscriptionId,
    subscriptionName,
    currentPlan,
    subscriptionDetails: { serviceAgreementSignature },
  } = subscription;

  const shouldEnforceAgreement =
    isSubscriptionAdmin &&
    !currentPlan.hasPaperContract &&
    serviceAgreementSignature.isInvalid &&
    subscription.status !== SubscriptionStatus.Canceled;

  if (!shouldEnforceAgreement) {
    return <>{children}</>;
  }

  const onAcceptTermsOfService = () => dispatch(acceptTermsOfService(subscriptionId));

  return (
    <AcceptSubscriptionTermsOfService
      onAcceptTermsOfService={onAcceptTermsOfService}
      subscriptionName={subscriptionName}
      invalidationReason={serviceAgreementSignature.invalidationReason}
    />
  );
};

EnforceServiceAgreement.displayName = 'EnforceServiceAgreementContainer';
