import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';

type InnovationLabGenericConsentProps = Readonly<{
  subject?: string;
}>;

export const InnovationLabGenericConsent: React.FC<InnovationLabGenericConsentProps> = ({
  subject,
}) => (
  <Callout calloutType="friendlyWarning" hideSubheadline>
    <p>
      <b>Consent</b>
    </p>
    <p>
      By proceeding, you agree with the{' '}
      <OutwardLink href="https://kontent.ai/learn/docs/innovation-lab/terms-of-use">
        Innovation Lab terms of use
      </OutwardLink>
      . Under these terms, we may in particular:
    </p>
    <ul>
      <li>Contact you and others in this project for feedback.</li>
      <li>
        Collect information on how you and others in this project use {subject ?? 'this feature'}.
      </li>
      <li>Process your data in a region outside your project’s data center.</li>
    </ul>
  </Callout>
);
