import { Action } from '../../../../../@types/Action.type.ts';
import {
  LocalizationEditor_DefaultLangEditor_Saved,
  LocalizationEditor_Language_Activate,
  LocalizationEditor_Language_Add,
  LocalizationEditor_Language_Deactivate,
  LocalizationEditor_Language_Update,
  LocalizationEditor_Saving_Started,
} from '../../constants/localizationActionTypes.ts';

export const languagesHavePendingChanges = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case LocalizationEditor_Language_Update:
    case LocalizationEditor_Language_Add:
    case LocalizationEditor_Language_Deactivate:
    case LocalizationEditor_Language_Activate:
    case LocalizationEditor_DefaultLangEditor_Saved:
      return true;

    case LocalizationEditor_Saving_Started:
      return false;

    default:
      return state;
  }
};
