import { assert } from '@kontent-ai/utils';
import { getAssetTaxonomyGroupIds } from '../../applications/contentInventory/assets/utils/assetTaxonomyUtils.ts';
import { ICompiledContentType } from '../../applications/contentInventory/content/models/CompiledContentType.ts';
import { IPlan } from '../../data/models/plans/Plan.ts';
import { IStore } from '../stores/IStore.type.ts';

export const isEnhancedAssetManagementEnabled = (currentPlan: IPlan): boolean =>
  currentPlan.features.isEnhancedAssetManagementEnabled;

export const isDefaultAssetTypeCustomized = (
  defaultAssetType: ICompiledContentType | null,
): boolean => getAssetTaxonomyGroupIds(defaultAssetType).length > 0;

export const isAssetTypeEnabled = (
  currentPlan: IPlan,
  defaultAssetType: ICompiledContentType | null,
): boolean =>
  isEnhancedAssetManagementEnabled(currentPlan) || isDefaultAssetTypeCustomized(defaultAssetType);

export const isAssetFeatureDowngraded = (
  currentPlan: IPlan,
  defaultAssetType: ICompiledContentType | null,
): boolean =>
  !isEnhancedAssetManagementEnabled(currentPlan) && isDefaultAssetTypeCustomized(defaultAssetType);

export const isCreatingRenditionsEnabled = (currentPlan: IPlan): boolean =>
  isEnhancedAssetManagementEnabled(currentPlan);

export const getDefaultAssetTypeOrThrow = (state: IStore): ICompiledContentType => {
  const defaultAssetType = state.data.assetTypes.defaultAssetType;

  assert(defaultAssetType, () => 'Default Asset type is not loaded.');

  return defaultAssetType;
};
