import classNames from 'classnames';
import React from 'react';
import { getFileExtension, getFileName } from '../../utils/assets/assetUtils.ts';
import { SearchPhraseHighlighter } from '../Highlighting/SearchPhraseHighlighter.tsx';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';

interface IAssetFileName {
  readonly name: string;
  readonly invalidClassName?: string;
  readonly searchPhrase?: string;
}

const nameWithHighlight = (searchPhrase: string | undefined, name: string) =>
  searchPhrase ? <SearchPhraseHighlighter searchPhrase={searchPhrase} text={name} /> : name;

export const AssetFileName: React.FC<IAssetFileName> = ({
  name,
  invalidClassName,
  searchPhrase,
}: IAssetFileName): JSX.Element | null => {
  if (!name) {
    return null;
  }
  return (
    <span data-hj-suppress="">
      <IgnoreByGrammarly text={getFileName(name)}>
        {nameWithHighlight(searchPhrase, getFileName(name))}
      </IgnoreByGrammarly>
      <span className={classNames('asset-thumbnail__file-name-extension', invalidClassName)}>
        <IgnoreByGrammarly text={getFileExtension(name)}>
          .{nameWithHighlight(searchPhrase, getFileExtension(name))}
        </IgnoreByGrammarly>
      </span>
    </span>
  );
};

AssetFileName.displayName = 'AssetFileName';
