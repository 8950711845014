import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { useFieldArray } from 'react-hook-form';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { EntityWebhookCustomHeader as EntityWebhookCustomHeaderComponent } from './EntityWebhookCustomHeader.tsx';

type EntityWebhookCustomHeadersProps = {
  readonly enabled: boolean;
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookCustomHeaders = ({
  enabled,
  formProps,
}: EntityWebhookCustomHeadersProps) => {
  const { control } = formProps;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
  });

  return (
    <Stack spacing={Spacing.L}>
      <Box typography={Typography.HeadlineMedium}>Headers</Box>
      <Box typography={Typography.SubheadlineLarge}>
        Specify custom HTTP headers to add authentication details or extra information to the
        webhook notifications. Your headers are added to the{' '}
        <OutwardLink href={documentationLinks.reservedHeaders}>reserved headers</OutwardLink>.
      </Box>
      {fields.length > 0 && (
        <Box display="flex">
          <Label css="flex: 1;" size={LabelSize.L}>
            Key
          </Label>
          <Label css={`flex: 1; padding-right: ${px(gridUnit * 12)};`} size={LabelSize.L}>
            Value
          </Label>
        </Box>
      )}
      <Stack spacing={Spacing.M}>
        {fields.map((header, index) => (
          <EntityWebhookCustomHeaderComponent
            enabled={enabled}
            formProps={formProps}
            index={index}
            remove={remove}
            key={header.id}
          />
        ))}
      </Stack>
      <Box>
        <Button
          buttonStyle="tertiary"
          size="small"
          disabled={!enabled || fields.length >= 10}
          {...getDataUiActionAttribute(DataUiAction.Add)}
          onClick={(e) => {
            append({ key: '', value: '' });
            (e.target as HTMLElement).blur();
          }}
          tooltipText={
            enabled && fields.length >= 10
              ? 'You’ve reached the limit of custom headers for this webhook.'
              : ''
          }
          tooltipMaxGridUnitsWidth={100}
        >
          Add custom header
        </Button>
      </Box>
    </Stack>
  );
};

EntityWebhookCustomHeaders.displayName = 'EntityWebhookCustomHeaders';
