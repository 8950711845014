import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import {
  ByStatus,
  allCollectionsOption,
  allLanguagesOption,
} from '../../../../_shared/constants/userListingFilter.ts';
import { doesEntitySatisfyFilterPhrase } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { formatUserNameForUsersListing } from '../../../../_shared/utils/usersUtils.ts';
import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import {
  ICollectionGroupRoles,
  IProjectContributor,
  IProjectContributorRole,
  getRoleIdsInAllCollectionsAndLanguages,
  getRolesInAllCollectionsAndLanguages,
} from '../../../../data/models/users/ProjectContributor.ts';
import { ILanguagesData } from '../../../../data/reducers/languages/ILanguagesData.type.ts';
import { getActiveLanguageIds } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import { IUserFilterState } from '../reducers/internalReducers/filter.ts';
import { getUsedLanguageIds } from '../utils/getUsedLanguageIds.ts';

const filterByRole = (
  contributor: IProjectContributor,
  selectedRoles: ReadonlySet<Uuid>,
): boolean => {
  if (selectedRoles.size === 0) {
    return true;
  }

  const contributorRolesIds = getRoleIdsInAllCollectionsAndLanguages(contributor);

  return contributorRolesIds.some((roleId: Uuid) => selectedRoles.has(roleId));
};

const filterByLanguage = (
  contributor: IProjectContributor,
  selectedLanguages: ReadonlySet<Uuid>,
  languagesData: ILanguagesData,
): boolean => {
  if (
    selectedLanguages.size === 0 ||
    getRolesInAllCollectionsAndLanguages(contributor).some(
      (userRole: IProjectContributorRole) => !userRole.languageIds.length,
    )
  ) {
    return true;
  }

  const contributorLanguagesIds = getUsedLanguageIds(
    getRolesInAllCollectionsAndLanguages(contributor),
  );
  const shouldContainAllActiveLanguages = selectedLanguages.has(allLanguagesOption.id);

  return shouldContainAllActiveLanguages
    ? getActiveLanguageIds(languagesData).every((languageId: Uuid) =>
        contributorLanguagesIds.has(languageId),
      )
    : [...contributorLanguagesIds].some((languageId: Uuid) => selectedLanguages.has(languageId));
};

const filterByCollection = (
  contributor: IProjectContributor,
  selectedCollections: ReadonlySet<Uuid>,
  collectionsData: CollectionsMap,
): boolean => {
  if (
    selectedCollections.size === 0 ||
    contributor.collectionGroups.some((g) => !g.collectionIds.length)
  ) {
    return true;
  }

  const usersCollections = contributor.collectionGroups.flatMap(
    (g: ICollectionGroupRoles) => g.collectionIds,
  );
  const collections = Collection.getKeys(collectionsData);
  const shouldContainAllCollections = selectedCollections.has(allCollectionsOption.id);

  return shouldContainAllCollections
    ? collections.every((collectionId: Uuid) => usersCollections.includes(collectionId))
    : usersCollections.some((collectionId: Uuid) => selectedCollections.has(collectionId));
};

export const filterByNameOrEmail = (
  contributor: IProjectContributor,
  filterPhrase: string,
): boolean =>
  !filterPhrase ||
  doesEntitySatisfyFilterPhrase(filterPhrase, contributor, [
    (person) => person.email,
    (person) => formatUserNameForUsersListing(person),
  ]);

const filterByActivity = (
  contributor: IProjectContributor,
  userActivityStatus: ByStatus,
): boolean =>
  userActivityStatus === ByStatus.ActiveAndDeactivated ||
  (userActivityStatus === ByStatus.Deactivated ? contributor.inactive : !contributor.inactive);

export const filterUsers = memoize.maxOne(
  (
    languages: ILanguagesData,
    collections: CollectionsMap,
    projectContributors: ReadonlyMap<Uuid, IProjectContributor>,
    filter: IUserFilterState,
  ): ReadonlyArray<IProjectContributor> => {
    const { byCollection, byLanguage, byName, byRoles, byStatus } = filter;

    return Collection.getValues(projectContributors).filter(
      (contributor: IProjectContributor) =>
        filterByRole(contributor, byRoles) &&
        filterByLanguage(contributor, byLanguage, languages) &&
        filterByCollection(contributor, byCollection, collections) &&
        filterByNameOrEmail(contributor, byName) &&
        filterByActivity(contributor, byStatus),
    );
  },
);
