import { assert } from '@kontent-ai/utils';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { IContentComponent } from '../../../models/contentItem/ContentComponent.ts';
import { isMultipleChoiceElement } from '../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import {
  getTargetElementsAffectedByTriggerChange,
  isElementHiddenByTrigger,
} from '../../../utils/itemElementConditionUtils.ts';
import { clearValuesOfElementsThatBecameHidden } from '../../../utils/itemElementCreator.ts';

export type ModifiedComponent = {
  originalComponent: IContentComponent;
  updatedComponent: IContentComponent;
};

type Components = {
  readonly originalComponents: ReadonlyMap<Uuid, IContentComponent>;
  readonly updatedComponents: ReadonlyMap<Uuid, IContentComponent>;
};

export function getModifiedComponents({
  originalComponents,
  updatedComponents,
}: Components): ReadonlyArray<ModifiedComponent> {
  const modifiedComponents: ModifiedComponent[] = [];
  for (const [id, updatedComponent] of updatedComponents) {
    const originalComponent = originalComponents.get(id);
    if (originalComponent && originalComponent !== updatedComponent) {
      modifiedComponents.push({ originalComponent, updatedComponent });
    }
  }

  return modifiedComponents;
}

export function processComponentConditionalElements(
  component: ModifiedComponent,
  contentTypes: Immutable.Map<Uuid, ICompiledContentType>,
): IContentComponent {
  const componentContentType = contentTypes.get(component.updatedComponent.contentTypeId);
  assert(componentContentType, () => `${__filename}: "componentContentType" is undefined`);
  const idsOfElementsThatBecameHidden = getIdsOfElementsThatBecameHidden(
    component,
    componentContentType.contentElements,
  );

  const elementsWithValuesOfHiddenElementsCleared = clearValuesOfElementsThatBecameHidden(
    component.updatedComponent.elements,
    idsOfElementsThatBecameHidden,
  );

  return {
    ...component.updatedComponent,
    elements: elementsWithValuesOfHiddenElementsCleared,
  };
}

function getIdsOfElementsThatBecameHidden(
  { originalComponent, updatedComponent }: ModifiedComponent,
  typeElements: ReadonlyArray<TypeElement>,
): ReadonlyArray<Uuid> {
  const previousTriggerValues = originalComponent.elements.filter((element) =>
    isMultipleChoiceElement(element),
  );
  const newTriggerValues = updatedComponent.elements.filter((element) =>
    isMultipleChoiceElement(element),
  );

  const triggerPairs = newTriggerValues.map((newTriggerValue) => {
    const previousTriggerValue = previousTriggerValues.find(
      (triggerValue) => triggerValue.elementId === newTriggerValue.elementId,
    );
    assert(previousTriggerValue, () => `${__filename}: Previous trigger value was not found`);

    return { newTriggerValue, previousTriggerValue };
  });

  return triggerPairs
    .flatMap(({ newTriggerValue, previousTriggerValue }) =>
      getTargetElementsAffectedByTriggerChange(
        previousTriggerValue,
        newTriggerValue,
        updatedComponent.elements,
        typeElements,
        isElementHiddenByTrigger,
      ),
    )
    .map((el) => el.elementId);
}
