export const Sitemap_AddCollapsedNode = 'Sitemap_AddCollapsedNode';
export const Sitemap_BackupOriginal = 'Sitemap_BackupOriginal';
export const Sitemap_CancelNodeEditing = 'Sitemap_CancelNodeEditing';
export const Sitemap_ConfirmEditedNode = 'Sitemap_ConfirmEditedNode';
export const Sitemap_Deleted = 'Sitemap_Deleted';
export const Sitemap_FinishSaving = 'Sitemap_FinishSaving';
export const Sitemap_HideCreateNewForm = 'Sitemap_HideCreateNewForm';
export const Sitemap_InitSitemap = 'Sitemap_InitSitemap';
export const Sitemap_MoveNode = 'Sitemap_MoveNode';
export const Sitemap_NodeCreated = 'Sitemap_NodeCreated';
export const Sitemap_NotificationBar_Hide = 'Sitemap_NotificationBar_Hide';
export const Sitemap_RemoveCollapsedNode = 'Sitemap_RemoveCollapsedNode';
export const Sitemap_RollbackSitemap = 'Sitemap_RollbackSitemap';
export const Sitemap_SelectEditedNode = 'Sitemap_SelectEditedNode';
export const Sitemap_ShowCreateNewForm = 'Sitemap_ShowCreateNewForm';
export const Sitemap_StartDraggingNode = 'Sitemap_StartDraggingNode';
export const Sitemap_StartSaving = 'Sitemap_StartSaving';
export const Sitemap_StopDraggingNode = 'Sitemap_StopDraggingNode';
export const Sitemap_SavingFailed = 'Sitemap_SavingFailed';
