import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import {
  ListingFilter_InitSavedFilters_Finished,
  ListingFilter_InitSavedFilters_Started,
} from '../../constants/listingFilterActionTypes.ts';

interface IDeps {
  readonly loadFilters: (abortSignal?: AbortSignal) => ThunkPromise;
}

const initSavedFiltersStarted = () =>
  ({
    type: ListingFilter_InitSavedFilters_Started,
  }) as const;

const initSavedFiltersFinished = () =>
  ({
    type: ListingFilter_InitSavedFilters_Finished,
  }) as const;

export type InitSavedFiltersAction = ReturnType<
  typeof initSavedFiltersStarted | typeof initSavedFiltersFinished
>;

export const createInitializeSavedFiltersAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(initSavedFiltersStarted());

    await dispatch(deps.loadFilters(abortSignal));

    dispatch(initSavedFiltersFinished());
  };
