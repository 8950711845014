import React from 'react';
import { Controller } from 'react-hook-form';
import { HookFormProps } from '../../../../../../../_shared/types/hookFormProps.type.ts';
import { INoteFormShape } from '../../../models/INoteFormShape.type.ts';
import { FormPane } from '../FormPane.tsx';
import { NoteFormTextArea } from './NoteFormTextArea.tsx';

type Props = {
  readonly formProps: HookFormProps<INoteFormShape>;
  readonly isSectionSubmitting: boolean;
  readonly onReset: () => void;
  readonly onSubmit: () => void;
};

export const NoteFormBase = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>(
  (props, ref) => (
    <FormPane onSubmit={props.onSubmit} onReset={props.onReset} ref={ref}>
      <Controller
        control={props.formProps.control}
        name="note"
        render={({ field }) => (
          <NoteFormTextArea
            ref={field.ref}
            autoFocus
            disabled={props.isSectionSubmitting}
            placeholder="Add any additional instructions or notes for other contributors"
            rows={3}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </FormPane>
  ),
);

NoteFormBase.displayName = 'NoteForm';
