import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IUserIdentifier } from '../../../../../_shared/models/UserIdentifier.ts';
import { getUsersInfo } from '../../../../../_shared/utils/usersUtils.ts';
import { getLanguageName } from '../../../../environmentSettings/localization/utils/languageUtils.ts';
import { useTrackWidgetDetailItemClick } from '../../../../unifiedMissionControl/widgets/shared/hooks/tracking.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { UnchangedUnpublishedWidgetDetail } from '../components/UnchangedUnpublishedWidgetDetail.tsx';
import { UnchangedUnpublishedWidgetDetailRow } from '../components/UnchangedUnpublishedWidgetDetailRow.tsx';
import { UnchangedUnpublishedItem } from '../types/UnchangedUnpublishedItem.type.ts';
import { getVariantLinkPath } from '../utils/getVariantLinkPath.ts';

export type UnchangedUnpublishedContentWidgetDetailLiveContainerProps = Readonly<{
  dataState: WidgetListingState;
  unchangedItems: ReadonlyArray<UnchangedUnpublishedItem>;
  useRowLinks: boolean;
}>;

export const UnchangedUnpublishedWidgetDetailContainer: React.FC<
  UnchangedUnpublishedContentWidgetDetailLiveContainerProps
> = ({ dataState, unchangedItems, useRowLinks }) => {
  const collectionsById = useSelector((s) => s.data.collections.byId);
  const usersById = useSelector((s) => s.data.users.usersById);

  const defaultLanguage = useSelector((s) => s.data.languages.defaultLanguage);
  const languagesById = useSelector((s) => s.data.languages.byId);

  const getContributors = (assignees: ReadonlySet<IUserIdentifier>) => {
    const userIdentifiers = assignees ?? new Set<IUserIdentifier>();
    return Array.from(getUsersInfo(userIdentifiers, usersById));
  };

  const getVariantName = (variantId: Uuid): string =>
    variantId
      ? (getLanguageName(variantId, defaultLanguage, languagesById) ?? 'Unknown variant')
      : 'Unknown variant';

  const getCollectionName = (collectionId: Uuid): string =>
    collectionsById.get(collectionId)?.name ?? 'Unknown collection';

  const trackWidgetDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.UnchangedUnpublishedItems,
  );

  return (
    <UnchangedUnpublishedWidgetDetail state={dataState}>
      {unchangedItems.map(({ itemWithVariant, lastEnteredWorkflowStep }) => (
        <UnchangedUnpublishedWidgetDetailRow
          collectionName={getCollectionName(itemWithVariant.item.collectionId)}
          contributors={getContributors(itemWithVariant.variant.assignment.assignees)}
          itemName={itemWithVariant.item.name}
          key={`${itemWithVariant.variant.id.itemId}${itemWithVariant.variant.id.variantId}`}
          lastEnteredWorkflowStep={lastEnteredWorkflowStep}
          linkPath={useRowLinks ? getVariantLinkPath(itemWithVariant.variant) : null}
          onClick={useRowLinks ? trackWidgetDetailItemClick : undefined}
          variant={itemWithVariant.variant}
          variantName={getVariantName(itemWithVariant.variant.id.variantId)}
        />
      ))}
    </UnchangedUnpublishedWidgetDetail>
  );
};

UnchangedUnpublishedWidgetDetailContainer.displayName = 'UnchangedUnpublishedWidgetDetailContainer';
