import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { UsersToolbarActions as UsersToolbarActionsComponent } from '../components/UsersToolbarActions.tsx';
import { getUserInviteLink } from '../selectors/getUserInviteLink.ts';

export const UsersToolbarActions: React.FC = () => {
  const defaultInviteLink = useSelector(getUserInviteLink);

  return <UsersToolbarActionsComponent createNewItemLinkPath={defaultInviteLink} />;
};
