import React from 'react';

interface IWarningNotificationProps {
  readonly descriptionParagraphs?: readonly string[];
  readonly title: string;
}

export const WarningNotification: React.FC<IWarningNotificationProps> = ({
  descriptionParagraphs,
  title,
}) => (
  <div className="warning-notification">
    <div className="warning-notification__image" title={title} />
    <div className="warning-notification__title">{title}</div>
    {descriptionParagraphs?.map((line, index) => (
      <div className="warning-notification__message-line" key={index}>
        {line}
      </div>
    ))}
  </div>
);

WarningNotification.displayName = 'WarningNotification';
