import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../_shared/components/AppBar/BackNavigation.tsx';
import { StatusMessageTypes } from '../../../../_shared/constants/statusMessageType.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { getGoBackLinkByPath } from '../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';

export const EnvironmentSettingsBackNavigation: React.FC = () => {
  const location = useLocation();
  const goBackLink = getGoBackLinkByPath(location.pathname);

  const areEnvironmentSettingsBeingSaved = useSelector((s) => {
    const savingStatus = s.projectsApp.savingStatus;
    const previewConfigurationStatus = s.previewConfigurationApp.status;
    const webhookAppLastOperation = s.webhooksApp.lastOperation;
    return (
      savingStatus.users.status === OperationStatus.Pending ||
      savingStatus.workflow.status === OperationStatus.Pending ||
      savingStatus.collections.status === OperationStatus.Pending ||
      savingStatus.languages.status === OperationStatus.Pending ||
      savingStatus.projectName.status === OperationStatus.Pending ||
      savingStatus.environmentName.status === OperationStatus.Pending ||
      previewConfigurationStatus.messageType === StatusMessageTypes.Saving ||
      (!!webhookAppLastOperation && webhookAppLastOperation.status === OperationStatus.Pending)
    );
  });

  return <BackNavigation to={goBackLink} isDisabled={areEnvironmentSettingsBeingSaved} />;
};

EnvironmentSettingsBackNavigation.displayName = 'EnvironmentSettingsBackNavigation';
