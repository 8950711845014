import classNames from 'classnames';
import React from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

interface ITabMenuProps {
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly dataUiAttributes?: ObjectWithDataAttribute;
}

export const TabMenu: React.FC<ITabMenuProps> = ({ children, dataUiAttributes, className }) => (
  <div className={classNames('tabbed-navigation', className)} {...dataUiAttributes}>
    <div className="tabbed-navigation__tabs">{children}</div>
  </div>
);

TabMenu.displayName = 'TabMenu';
