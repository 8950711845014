import React from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  UserMention as UserMentionComponent,
  UserMentionProps,
} from '../../../components/comments/input/UserMention.tsx';

type UserMentionContainerProps = Omit<UserMentionProps, 'user' | 'isCurrentUser'> & {
  readonly userId: UserId;
};

export const UserMention: React.FC<UserMentionContainerProps> = ({
  children,
  offsetKey,
  userId,
  wasPinged,
}) => {
  const user = useSelector((state) => state.data.users.usersById.get(userId));
  const isCurrentUser = useSelector((state) => state.data.user.info.userId === user?.userId);

  return (
    <UserMentionComponent
      isCurrentUser={isCurrentUser}
      offsetKey={offsetKey}
      user={user}
      wasPinged={wasPinged}
    >
      {children}
    </UserMentionComponent>
  );
};

UserMention.displayName = 'UserMentionContainer';
