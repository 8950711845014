import React from 'react';
import { getConnectedStatusMessage } from '../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  ServerErrorStatusMessage,
  StatusMessage,
} from '../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';

const getSubscriptionSavingStatusMessage = (state: IStore): StatusMessage => {
  const { serverError, subscriptionIsBeingUpdated } = state.subscriptionApp.general;

  if (serverError) {
    return ServerErrorStatusMessage;
  }

  return subscriptionIsBeingUpdated ? SavingStatusMessage : SavedStatusMessage;
};

export const SubscriptionSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getSubscriptionSavingStatusMessage,
);
