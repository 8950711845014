import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { getDataUiObjectNameAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

type BarItemTitleProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly dataUiObjectName?: string;
  readonly onClick?: () => void;
  readonly titleTag?: JSX.Element | React.ReactNode;
  readonly tooltip?: string;
};

const StyledBarItemTitleTag = styled.div`
  display: inline-block;
  margin-left: ${px(Spacing.L)};
`;

export const BarItemTitle: React.FC<BarItemTitleProps> = (props) => (
  <Tooltip tooltipText={props.tooltip} placement="top-start">
    <div className="bar-item__title-pane" onClick={props.onClick}>
      <div
        className={classNames(
          'bar-item__title',
          { 'bar-item__title--low-emphasis': props.disabled },
          props.className,
        )}
        {...(props.dataUiObjectName && getDataUiObjectNameAttribute(props.dataUiObjectName))}
      >
        {props.children}
      </div>
      {props.titleTag ? <StyledBarItemTitleTag>{props.titleTag}</StyledBarItemTitleTag> : null}
    </div>
  </Tooltip>
);

BarItemTitle.displayName = 'BarItemTitle';
