import React from 'react';
import { IMultipleChoiceTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { IMultipleChoiceItemElement } from '../../../../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';
import { MultipleChoiceInput } from './MultipleChoiceInput.tsx';

export type MultipleChoiceProps = IItemElementComponentOwnProps<
  IMultipleChoiceItemElement,
  IMultipleChoiceTypeElement
> & {
  readonly onSelectedOptionsUpdate: (options: readonly string[]) => void;
};

export class MultipleChoice extends React.PureComponent<MultipleChoiceProps> {
  static displayName = 'MultipleChoice';

  render(): JSX.Element {
    const { typeElement, disabled, elementData, autoFocus, onSelectedOptionsUpdate } = this.props;

    return (
      <ItemElement typeElement={typeElement} disabled={disabled} isCompact>
        <ItemElementRefresher
          elementData={elementData}
          renderInput={(refresherItemElement: IMultipleChoiceItemElement) => (
            <MultipleChoiceInput
              elementData={refresherItemElement}
              typeElement={typeElement}
              onSelectedOptionsUpdate={onSelectedOptionsUpdate}
              disabled={disabled}
              autoFocus={autoFocus}
              defaultValue={typeElement.defaultValue}
            />
          )}
        />
      </ItemElement>
    );
  }
}
