import { InvariantException } from '@kontent-ai/errors';
import { delay } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKey,
  convertToCreateServerModel,
  createApiKeyFromServerModel,
} from '../../../../projectSettings/apiKeys/models/ApiKey.ts';
import {
  SubscriptionApiKey_Creation_Failed,
  SubscriptionApiKey_Creation_Finished,
  SubscriptionApiKey_Creation_Started,
  SubscriptionApiKey_GenerationStatus_Reset,
} from '../../../shared/constants/subscriptionManagementActionTypes.ts';

const started = () =>
  ({
    type: SubscriptionApiKey_Creation_Started,
  }) as const;

const failed = () =>
  ({
    type: SubscriptionApiKey_Creation_Failed,
  }) as const;

const succeed = (apiKey: ApiKey) =>
  ({
    type: SubscriptionApiKey_Creation_Finished,
    payload: { apiKey },
  }) as const;

const statusReset = () =>
  ({
    type: SubscriptionApiKey_GenerationStatus_Reset,
  }) as const;

export type CreateSubscriptionApiKeyActionsType = ReturnType<
  typeof started | typeof failed | typeof succeed | typeof statusReset
>;

interface IGenerateSubscriptionApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'generateSubscriptionApiKey'>;
}

export const generateSubscriptionApiKeyActionCreator =
  ({ apiKeysRepository }: IGenerateSubscriptionApiKeyDependencies) =>
  (subscriptionId: Uuid, apiKey: ApiKey): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started());
    try {
      const apiKeyServerModel = convertToCreateServerModel(apiKey);

      const serverModel = await apiKeysRepository.generateSubscriptionApiKey(
        subscriptionId,
        apiKeyServerModel,
      );
      dispatch(succeed(createApiKeyFromServerModel(serverModel)));
      await delay(1000);
      dispatch(statusReset());
    } catch (error) {
      dispatch(failed());

      throw InvariantException(
        `${__filename}: Could not create subscription api key because of the following error: ${JSON.stringify(
          error,
        )}`,
      );
    }
  };
