import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Pathname } from 'history';
import React, { ReactNode } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ApiKeyListingColumnCode,
  translateColumnCodeToTitle,
} from '../constants/ApiKeyListingColumnCode.tsx';
import { IApiKeyListingData } from '../models/ApiKeyListingData.ts';
import { ApiKeyDataTableRow } from './ApiKeyDataTableRow.tsx';

const headColumns = Immutable.List<Column>([
  {
    columnName: translateColumnCodeToTitle(ApiKeyListingColumnCode.Name),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: translateColumnCodeToTitle(ApiKeyListingColumnCode.Environment),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--5',
  },
  {
    columnName: translateColumnCodeToTitle(ApiKeyListingColumnCode.ExpirationDate),
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--3',
  },
]);

export interface IApiKeyDataTableProps {
  readonly apiKeyListingData: readonly IApiKeyListingData[];
  readonly createNewItemLabel?: string;
  readonly createNewItemLinkPath?: Pathname;
  readonly getEnvironmentName: (environmentId: Uuid) => string | undefined;
  readonly renderExpiration: (expiresAt: string) => JSX.Element;
  readonly renderTag?: (userId: string) => JSX.Element;
  readonly secondaryAction?: JSX.Element;
  readonly statusMessage: ReactNode | undefined;
}

export const ApiKeyDataTable: React.FC<IApiKeyDataTableProps> = ({
  apiKeyListingData,
  createNewItemLabel,
  createNewItemLinkPath,
  getEnvironmentName,
  renderExpiration,
  renderTag,
  secondaryAction,
  statusMessage,
}) => (
  <Box paddingTop={Spacing.XL}>
    <DataTable
      secondaryAction={secondaryAction}
      noBoxShadow
      dataUiCollectionName={DataUiCollection.ApiKeys}
      header={<DataTableHeadRow columns={headColumns} />}
      title={statusMessage}
      createNewItemLabel={createNewItemLabel}
      createNewItemLinkPath={createNewItemLinkPath}
    >
      {apiKeyListingData.map((apiKeyData) => (
        <ApiKeyDataTableRow
          key={apiKeyData.seedId}
          apiKeyData={apiKeyData}
          getEnvironmentName={getEnvironmentName}
          renderExpiration={renderExpiration}
          renderTag={renderTag}
        />
      ))}
    </DataTable>
  </Box>
);

ApiKeyDataTable.displayName = 'ApiKeyDataTable';
