import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ListingFilter_DeleteSavedFilter_Failed,
  ListingFilter_DeleteSavedFilter_Finished,
  ListingFilter_DeleteSavedFilter_Started,
} from '../../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialState: ReadonlySet<Uuid> = new Set<Uuid>();

export function filtersBeingDeleted(state = initialState, action: Action) {
  switch (action.type) {
    case ListingFilter_DeleteSavedFilter_Started:
      return Collection.add(state, action.payload.filter.id);

    case ListingFilter_DeleteSavedFilter_Finished:
    case ListingFilter_DeleteSavedFilter_Failed:
      return Collection.remove(state, action.payload.filter.id);

    default:
      return state;
  }
}
