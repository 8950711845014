import { InvariantException } from '@kontent-ai/errors';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ensureContentItemNameValidity } from '../../../../stores/utils/contentItemHelperMethods.ts';

interface ISaveEditedContentItemNameDependencies {
  readonly nameChanged: (newName: string, itemId: Uuid) => Action;
}

export const createSaveEditedContentItemNameAction =
  ({ nameChanged }: ISaveEditedContentItemNameDependencies) =>
  (newName: string): ThunkPromise =>
  (dispatch, getState) => {
    const validName = ensureContentItemNameValidity(newName);
    const item = getState().contentApp.editedContentItem;
    if (!item) {
      throw InvariantException('saveEditedContentItemName.ts: editedContentItem is not defined');
    }

    dispatch(nameChanged(validName, item.id));

    return Promise.resolve();
  };
