import { memoize } from '@kontent-ai/memoization';
import {
  ContentItemsAppRootRoute,
  ContentItemsAppRootRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { matchPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isValidUrlInfo } from '../../../../../../../_shared/utils/validation/isValidUrlInfo.ts';
import { canShowNotSetupPreview } from '../../../containers/selectors/canShowNotSetupPreview.ts';
import { canShowSampleAppPreview } from '../../../containers/selectors/canShowSampleAppPreview.ts';
import { selectPreviewLinks } from '../../../containers/selectors/selectPreviewLinks.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

export const isPreviewItemAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  _variant,
  currentPath,
) =>
  isPreviewItemAllowed(currentPath) &&
  !canShowSampleAppPreview(state) &&
  hasSingleValidPreviewUrl(state);

export const isPreviewItemInMultipleSpacesAvailable: ContentItemEditingActionAvailabilityProvider =
  (state, _variant, currentPath) =>
    isPreviewItemAllowed(currentPath) &&
    !canShowSampleAppPreview(state) &&
    hasValidPreviewUrlsForAllSpaces(state);

export const isDisabledPreviewItemAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  _variant,
  currentPath,
) =>
  isPreviewItemAllowed(currentPath) &&
  !canShowSampleAppPreview(state) &&
  hasInvalidPreviewUrl(state);

export const isPreviewSampleAppItemAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  _variant,
  currentPath,
) => isPreviewItemAllowed(currentPath) && canShowSampleAppPreview(state);

export const isSetupItemPreviewAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  _variant,
  currentPath,
) => isPreviewItemAllowed(currentPath) && canShowNotSetupPreview(state);

const isPreviewItemAllowed = memoize.maxOne(
  (currentPath: string) =>
    matchPath<ContentItemsAppRootRouteParams>(currentPath, ContentItemsAppRootRoute)?.app !==
    ContentItemsAppRouteSegment.WebSpotlight,
);

const hasSingleValidPreviewUrl = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.length === 1 && isValidUrlInfo(previewLinks[0]);
};

const hasValidPreviewUrlsForAllSpaces = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.length > 1 && previewLinks.every((linkInfo) => isValidUrlInfo(linkInfo));
};

const hasInvalidPreviewUrl = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.some(({ error }) => !!error);
};
