import { notUndefined } from '@kontent-ai/utils';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { PersonalManagementKeyRouteSegment } from '../../root/constants/routeSegments.ts';
import { AllEnvironmentsLabel } from '../constants/uiConstants.ts';
import { IApiKeyListingData } from '../models/ApiKeyListingData.ts';

const getLinkPath = (url: string, apiKeyType: ApiKeyType, id: string): string => {
  switch (apiKeyType) {
    case ApiKeyType.MAPIPat:
      return `${url}/${PersonalManagementKeyRouteSegment}/${id}`;
    case ApiKeyType.MAPI:
    case ApiKeyType.DAPI:
    case ApiKeyType.DAPIPrimary:
    case ApiKeyType.DAPISecondary:
    case ApiKeyType.PAPIPrimary:
    case ApiKeyType.PAPISecondary:
      return `${url}/${id}`;
    default:
      return '';
  }
};

const DataTableCellWithLink: React.FC<{
  readonly id: string;
  readonly title: string;
  readonly type: ApiKeyType;
  readonly children: React.ReactNode;
}> = ({ id, title, type, children }) => {
  const { url } = useRouteMatch();

  return (
    <LinkDataTableCell linkPath={getLinkPath(url, type, id)} title={title}>
      {children}
    </LinkDataTableCell>
  );
};

interface IApiKeyDataTableRowProps {
  readonly apiKeyData: IApiKeyListingData;
  readonly getEnvironmentName: (environmentId: Uuid) => string | undefined;
  readonly renderExpiration: (expiresAt: string) => JSX.Element;
  readonly renderTag?: (userId: string) => JSX.Element;
}

export const ApiKeyDataTableRow: React.FC<IApiKeyDataTableRowProps> = ({
  apiKeyData,
  getEnvironmentName,
  renderExpiration,
  renderTag,
}) => {
  const { environments, expiresAt, hasAccessToAllEnvironments, name, seedId, type, userId } =
    apiKeyData;
  const environmentNames = hasAccessToAllEnvironments
    ? AllEnvironmentsLabel
    : environments
        .map((environmentId) => getEnvironmentName(environmentId))
        .filter(notUndefined)
        .join(', ');

  return (
    <DataTableRow dataUiObjectName={name} id={seedId}>
      <DataTableCellWithLink id={seedId} title={name} type={type}>
        {name}
        {type === ApiKeyType.MAPIPat && renderTag?.(userId)}
      </DataTableCellWithLink>
      <DataTableCellWithLink id={seedId} title={environmentNames} type={type}>
        {environmentNames}
      </DataTableCellWithLink>
      <DataTableCellWithLink id={seedId} title={expiresAt ?? ''} type={type}>
        {expiresAt ? renderExpiration(expiresAt) : ''}
      </DataTableCellWithLink>
    </DataTableRow>
  );
};

ApiKeyDataTableRow.displayName = 'ApiKeyDataTableRow';
