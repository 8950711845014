import {
  Typography,
  colorTextDefault,
  colorTextDefaultInverse,
} from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const StyledDataPointLabel = styled.div`
  color: ${colorTextDefault};
  ${Typography.LabelLarge};
`;

export const StyledDataPointContent = styled.div`
  color: ${colorTextDefault};
  ${Typography.TitleLarge};
`;

export const StyledHighlightedDataPointLabel = styled.div`
  color: ${colorTextDefaultInverse};
  ${Typography.LabelLarge};
`;

export const StyledHighlightedDataPointContent = styled.div.attrs((props) => props)`
  color: ${colorTextDefaultInverse};
  ${Typography.HeadlineLarge};
`;
