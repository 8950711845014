import { memoize } from '@kontent-ai/memoization';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { IRole } from '../../../../data/models/roles/IRole.ts';

export const getSortedRoles = (rolesById: ReadonlyMap<Uuid, IRole>): ReadonlyArray<IRole> =>
  [...rolesById.values()].sort(
    createCompare({
      compare: alphabetically,
      select: (x) => x.name,
    }),
  );

export const getSortedRolesMemoized = memoize.maxOne(getSortedRoles);

export const getSortedRolesImmutableMemoized = memoize.maxOne(
  compose(Immutable.List<IRole>, getSortedRoles),
);
