import { identity } from '@kontent-ai/utils';
import React from 'react';
import {
  FriendlyWarningReason,
  FriendlyWarningReasonDetail,
  IFriendlyWarning,
} from '../../../../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { LinkLike } from '../../../../../uiComponents/LinkLike/LinkLike.tsx';
import { FriendlyWarningMessageForMultipleUsersEditing } from '../FriendlyWarningMessageForMultipleUsersEditing.tsx';

const isAnyDetailOfType = (
  friendlyWarnings: ReadonlyArray<IFriendlyWarning>,
  detail: FriendlyWarningReasonDetail,
): boolean =>
  friendlyWarnings.some(
    (w) => !!w && !!w.reasonDetails && w.reasonDetails.some((d) => d === detail),
  );

const isAnyDetailOfAnyType = (
  friendlyWarnings: ReadonlyArray<IFriendlyWarning>,
  details: Array<FriendlyWarningReasonDetail>,
): boolean =>
  friendlyWarnings.some(
    (w: IFriendlyWarning) =>
      !!w.reasonDetails?.some((d: FriendlyWarningReasonDetail) => details.includes(d)),
  );

const isItemBeingEditedByMultipleUsers = (
  friendlyWarnings: ReadonlyArray<IFriendlyWarning>,
): boolean =>
  !!friendlyWarnings.find(
    (re: IFriendlyWarning) => re.reason === FriendlyWarningReason.ItemIsEditedByMultipleUsers,
  );

type FriendlyWarningMessageForEditingProps = {
  readonly activeUsersNames: ReadonlyArray<string>;
  readonly friendlyWarnings: ReadonlyArray<IFriendlyWarning>;
  readonly onUnfinishedTasksWarningClick: () => void;
};

export const FriendlyWarningMessageForEditing: React.FC<FriendlyWarningMessageForEditingProps> = ({
  activeUsersNames,
  friendlyWarnings,
  onUnfinishedTasksWarningClick,
}) => {
  const brokenModulesWarnings = friendlyWarnings.filter(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.BrokenModulars,
  );
  const brokenLinksWarnings = friendlyWarnings.filter(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.BrokenLinks,
  );
  const brokenAssetsWarning = friendlyWarnings.filter(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.BrokenAssets,
  );
  const brokenTaxonomiesWarning = friendlyWarnings.filter(
    (w) => w.reason === FriendlyWarningReason.BrokenTaxonomies,
  );

  const showLinksToUnpublishedItemsWarning = isAnyDetailOfType(
    brokenModulesWarnings,
    FriendlyWarningReasonDetail.UnpublishedItem,
  );
  const showContainsDeletedItems = isAnyDetailOfType(
    brokenModulesWarnings,
    FriendlyWarningReasonDetail.DeletedItem,
  );
  const showContainsNonExistingItems = isAnyDetailOfType(
    brokenModulesWarnings,
    FriendlyWarningReasonDetail.NonExistingItem,
  );
  const showBrokenItemLinksWarnings = isAnyDetailOfAnyType(brokenLinksWarnings, [
    FriendlyWarningReasonDetail.UnpublishedItem,
    FriendlyWarningReasonDetail.DeletedItem,
    FriendlyWarningReasonDetail.NonExistingItem,
  ]);
  const showBrokenAssetLinksWarnings = isAnyDetailOfAnyType(brokenLinksWarnings, [
    FriendlyWarningReasonDetail.NonExistingAsset,
    FriendlyWarningReasonDetail.DeletedAsset,
  ]);
  const showAssetMissingDescriptionWarning = friendlyWarnings.some(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.MissingAssetDescription,
  );
  const showContainsDeletedAssets = isAnyDetailOfType(
    brokenAssetsWarning,
    FriendlyWarningReasonDetail.DeletedAsset,
  );
  const showContainsNonExistingAssets = isAnyDetailOfType(
    brokenAssetsWarning,
    FriendlyWarningReasonDetail.NonExistingAsset,
  );
  const showContainsDeletedTaxonomyTerms = isAnyDetailOfType(
    brokenTaxonomiesWarning,
    FriendlyWarningReasonDetail.DeletedTaxonomyTerm,
  );
  const showUnfinishedTasksWarning = friendlyWarnings.some(
    (w: IFriendlyWarning) => w.reason === FriendlyWarningReason.IncompleteTasks,
  );

  const potentiallyBreakingWarnings = [
    showLinksToUnpublishedItemsWarning,
    showContainsDeletedItems,
    showContainsNonExistingItems,
    showBrokenItemLinksWarnings,
    showBrokenAssetLinksWarnings,
    showAssetMissingDescriptionWarning,
    showContainsDeletedAssets,
    showContainsNonExistingAssets,
    showContainsDeletedTaxonomyTerms,
  ];

  const hasOnlyItemIsEditedByMultipleUsersWarning =
    friendlyWarnings.length === 1 && isItemBeingEditedByMultipleUsers(friendlyWarnings);
  const hasAnyPotentiallyBreakingWarning = potentiallyBreakingWarnings.some(identity);

  const infoMessage = `If you publish the item, ${
    activeUsersNames.length > 1 ? 'these users' : 'this user'
  } won’t be able to make further content changes.`;

  const getFriendlyWarningMessageForEditing = () => (
    <div className="callout__text-block">
      <p>This content item contains:</p>
      <ul>
        {showLinksToUnpublishedItemsWarning && (
          <li>Links to content items that are not published</li>
        )}
        {showContainsDeletedItems && <li>Content items that are deleted</li>}
        {showContainsNonExistingItems && <li>Content items that don’t exist</li>}
        {(showBrokenItemLinksWarnings && showBrokenAssetLinksWarnings && (
          <li>Links to items and assets that might be broken</li>
        )) ||
          (showBrokenItemLinksWarnings && <li>Links to content items that might be broken</li>) ||
          (showBrokenAssetLinksWarnings && <li>Links to assets that might be broken</li>)}
        {showAssetMissingDescriptionWarning && <li>Assets without a description</li>}
        {showContainsDeletedAssets && <li>Assets that are deleted</li>}
        {showContainsNonExistingAssets && <li>Assets that don’t exist</li>}
        {showContainsDeletedTaxonomyTerms && <li>Taxonomy terms that are deleted</li>}
        {showUnfinishedTasksWarning && <li>Unfinished tasks</li>}
      </ul>
      {hasAnyPotentiallyBreakingWarning && (
        <p>
          Publishing this item might lead to <strong>broken links</strong> or{' '}
          <strong>break your app</strong>.
        </p>
      )}
      {!hasAnyPotentiallyBreakingWarning && showUnfinishedTasksWarning && (
        <p>
          <LinkLike onClick={onUnfinishedTasksWarningClick}>See the unfinished tasks</LinkLike>.
        </p>
      )}
    </div>
  );

  return (
    <>
      {!hasOnlyItemIsEditedByMultipleUsersWarning && getFriendlyWarningMessageForEditing()}
      {isItemBeingEditedByMultipleUsers(friendlyWarnings) && (
        <FriendlyWarningMessageForMultipleUsersEditing
          activeUsersNames={activeUsersNames}
          infoMessage={infoMessage}
        />
      )}
    </>
  );
};

FriendlyWarningMessageForEditing.displayName = 'FriendlyWarningMessageForEditing';
