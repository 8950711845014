import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { forwardRef } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../../../_shared/models/Task.ts';
import { filterOutNullishOrEmpty } from '../../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  ItemEditingForms,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContributorsAvailableAsAssigneesInTasks } from '../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { cancelTaskEditing } from '../../../actions/contentItemEditingActions.ts';
import { archiveTask, updateTask } from '../../../actions/thunkContentItemEditingActions.ts';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { isTaskArchiving as isTaskArchivingSelector } from '../../../selectors/isTaskArchiving.ts';
import { isTaskSubmitting as isTaskSubmittingSelector } from '../../../selectors/isTaskSubmitting.ts';
import { TaskForm } from './TaskForm.tsx';

const getTaskAssigneeId = (task: Task | null | undefined) => {
  return Array.from(task?.assignees ?? [])[0] ?? null;
};

export const EditTaskFormDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const taskId = useSelector((s) => s.contentApp.editorUi.tasks.selectedTaskId) ?? '';
    const allActiveContributors = useSelector(
      (s) => getContributorsAvailableAsAssigneesInTasks(s).allActive,
    );
    const isTaskSubmitting = useSelector((s) => isTaskSubmittingSelector(s, taskId));
    const isTaskArchiving = useSelector((s) => isTaskArchivingSelector(s, taskId));
    const task = useSelector((s) => s.data.tasks.byId.get(taskId));

    const defaultValues: ITaskFormShape = {
      assignedContributorId: getTaskAssigneeId(task),
      taskDescription: task?.description ?? '',
      taskDueDate: task
        ? { value: task.dueDate ?? '', isValid: true }
        : { value: '', isValid: true },
    };

    const dispatch = useDispatch();

    const onConfirm = (values: ITaskFormShape) =>
      dispatch(
        updateTask(
          taskId,
          values.taskDescription,
          filterOutNullishOrEmpty([values.assignedContributorId]),
          values.taskDueDate,
        ),
      );
    const onCancel = () => dispatch(cancelTaskEditing(taskId));
    const onArchive = () => dispatch(archiveTask(taskId));

    return (
      <Box {...getDataUiObjectNameAttribute(ItemEditingForms.Tasks)}>
        <TaskForm
          isOpen={isOpen}
          ref={ref}
          modalHeadline="Edit task"
          allActiveContributors={allActiveContributors}
          defaultValues={defaultValues}
          isTaskSubmitting={isTaskSubmitting}
          isTaskArchiving={isTaskArchiving}
          submitButtonName="Save"
          submitButtonText={isTaskSubmitting ? 'Saving…' : 'Save'}
          onCancel={onCancel}
          onConfirm={onConfirm}
          extraAction={{
            text: isTaskArchiving ? 'Deleting…' : 'Delete',
            onClick: onArchive,
            destructive: true,
            destructiveIcon: Icons.Bin,
            disabled: isTaskSubmitting || isTaskArchiving,
            type: 'button',
          }}
        />
      </Box>
    );
  },
);

EditTaskFormDialog.displayName = 'EditTaskFormDialog';
