import Immutable from 'immutable';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { ItemPageContentItemsIds } from '../../../repositories/serverModels/PageContentItemsIdsServerModel.type.ts';
import { IPreviewConfiguration } from '../../previewConfiguration/models/PreviewConfiguration.type.ts';
import { PreviewError } from './previewUtils.ts';

export type PreviewContentItemInfo = {
  readonly contentItemId: Uuid;
  readonly isForLastRouteItem: boolean;
  readonly isPageContent: boolean;
};

interface IDetectedPreviewContentItemInfo {
  readonly contentItemId: Uuid;
  readonly error?: PreviewError;
  readonly isPageContent: boolean;
}

interface IPreviewContentItemSearchResult {
  readonly previewContentItemInfo: PreviewContentItemInfo | null;
  readonly error?: PreviewError;
}

export const checkHasPreviewConfigured = (
  previewConfiguration: IPreviewConfiguration,
  contentTypeId: Uuid,
  spaceId: Uuid | null,
): boolean => {
  const previewUrlPatterns = previewConfiguration.previewUrlPatterns.get(contentTypeId) ?? [];

  if (spaceId) {
    const previewUrlPattern =
      previewUrlPatterns.find((pattern) => pattern.spaces.has(spaceId)) ||
      previewUrlPatterns.find((pattern) => !pattern.spaces.size);

    return !!previewUrlPattern?.urlPattern && previewUrlPattern.enabled;
  }

  return previewUrlPatterns.some(
    (previewUrlPattern) => !!previewUrlPattern.urlPattern && previewUrlPattern.enabled,
  );
};

function searchPreviewContentItem(
  contentItem: IListingContentItem,
  listingContentItems: Immutable.Map<Uuid, IListingContentItem>,
  previewConfiguration: IPreviewConfiguration,
  itemPageContentItemsIds: ReadonlyArray<ItemPageContentItemsIds>,
  spaceId: Uuid | null,
): IDetectedPreviewContentItemInfo | null {
  const contentItemPageContentItemsIds = itemPageContentItemsIds.find(
    (item) => item.itemId === contentItem.item.id,
  )?.pageContentItemsIds;

  if (contentItemPageContentItemsIds) {
    if (contentItemPageContentItemsIds.length > 1) {
      return {
        contentItemId: contentItem.item.id,
        error: PreviewError.TooManyPageContentItemsLinked,
        isPageContent: false,
      };
    }

    if (contentItemPageContentItemsIds.length === 1) {
      const pageContentItemId = contentItemPageContentItemsIds[0];
      const pageContentItem = pageContentItemId ? listingContentItems.get(pageContentItemId) : null;
      if (pageContentItem) {
        const hasPageContentItemPreviewConfigured = checkHasPreviewConfigured(
          previewConfiguration,
          pageContentItem.item.typeId,
          spaceId,
        );
        if (hasPageContentItemPreviewConfigured) {
          return {
            contentItemId: pageContentItem.item.id,
            error: undefined,
            isPageContent: true,
          };
        }
      }
    }
  }

  const hasPreviewConfigured = checkHasPreviewConfigured(
    previewConfiguration,
    contentItem.item.typeId,
    spaceId,
  );
  if (hasPreviewConfigured) {
    return {
      contentItemId: contentItem.item.id,
      error: undefined,
      isPageContent: false,
    };
  }

  return null;
}

export const selectDeepestItemWithPreview = (
  routeContentItemIds: UuidArray,
  listingContentItems: Immutable.Map<Uuid, IListingContentItem>,
  previewConfiguration: IPreviewConfiguration,
  itemPageContentItemsIds: ReadonlyArray<ItemPageContentItemsIds>,
  spaceId: Uuid | null,
): IPreviewContentItemSearchResult => {
  for (let i = routeContentItemIds.length - 1; i >= 0; i--) {
    const contentItemId = routeContentItemIds[i];
    const contentItem = contentItemId ? listingContentItems.get(contentItemId) : null;
    if (!contentItem) {
      continue;
    }

    const detectedPreviewContentItem = searchPreviewContentItem(
      contentItem,
      listingContentItems,
      previewConfiguration,
      itemPageContentItemsIds,
      spaceId,
    );

    if (detectedPreviewContentItem) {
      return {
        previewContentItemInfo: {
          contentItemId: detectedPreviewContentItem.contentItemId,
          isForLastRouteItem: i === routeContentItemIds.length - 1,
          isPageContent: detectedPreviewContentItem.isPageContent,
        },
        error: detectedPreviewContentItem.error,
      };
    }
  }

  return {
    previewContentItemInfo: null,
    error: PreviewError.NoPreview,
  };
};
