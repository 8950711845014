import { Direction } from '@kontent-ai/types';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  getSelectionsForInlineStyle,
  isSingleWholeBlockSelection,
} from '../../../utils/editorSelectionUtils.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  deleteBlocks,
  removeRange,
} from '../../../utils/general/editorContentUtils.ts';
import { updateText } from '../../textApi/api/editorTextUtils.ts';
import { getCommentInlineStyle } from './editorCommentStyleUtils.ts';

export interface IApprovedSuggestion {
  readonly commentThreadId: Uuid;
  readonly suggestionId: Uuid;
  readonly suggestedText: string;
}

export function findSuggestionsToApply(
  oldApprovedSuggestions: ReadonlyArray<IApprovedSuggestion>,
  newApprovedSuggestions: ReadonlyArray<IApprovedSuggestion>,
): ReadonlyArray<IApprovedSuggestion> | null {
  if (!newApprovedSuggestions.length || oldApprovedSuggestions === newApprovedSuggestions) {
    return null;
  }

  const newSuggestions = newApprovedSuggestions.filter(
    (newSuggestion) =>
      !oldApprovedSuggestions.some((oldSuggestion) => oldSuggestion === newSuggestion),
  );

  return newSuggestions.length ? newSuggestions : null;
}

export function applyApprovedSuggestion(
  input: IContentChangeInput,
  suggestion: IApprovedSuggestion,
  onSuggestionApplied: (suggestion: IApprovedSuggestion) => void,
  threadIdMapping: ReadonlyMap<Uuid, Uuid>,
): IContentChangeResult {
  const segmentId = Collection.getEntries(threadIdMapping).find(
    ([, threadId]) => threadId === suggestion.commentThreadId,
  )?.[0];

  if (!segmentId) {
    return input;
  }

  const commentStyle = getCommentInlineStyle(segmentId);

  const styleSelections = getSelectionsForInlineStyle(input.content, commentStyle);
  if (!styleSelections.length) {
    return input;
  }

  const allUpdated = styleSelections.reduce((updated, selection, selectionIndex) => {
    const updateInput = {
      content: updated.content,
      selection,
    };
    const firstSegment = !selectionIndex;
    if (firstSegment) {
      onSuggestionApplied(suggestion);

      return updateText(updateInput, suggestion.suggestedText);
    }

    if (isSingleWholeBlockSelection(selection, updateInput.content)) {
      return deleteBlocks(
        updateInput,
        Immutable.Set.of(selection.getAnchorKey()),
        Direction.Backward,
      );
    }

    return removeRange(updateInput);
  }, input);

  return allUpdated;
}

export function applyApprovedSuggestions(
  input: IContentChangeInput,
  suggestions: ReadonlyArray<IApprovedSuggestion>,
  onSuggestionApplied: (suggestion: IApprovedSuggestion) => void,
  threadIdMapping: ReadonlyMap<Uuid, Uuid>,
): IContentChangeResult {
  const allApplied = suggestions.reduce(
    (result: IContentChangeInput, suggestion: IApprovedSuggestion) =>
      applyApprovedSuggestion(result, suggestion, onSuggestionApplied, threadIdMapping),
    input,
  );
  return {
    ...allApplied,
    wasModified: allApplied.content !== input.content,
  };
}
