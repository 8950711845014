import { memoize } from '@kontent-ai/memoization';
import { CollectionsMap, ICollection } from '../../../data/models/collections/Collection.ts';
import { ILanguageBoundRole } from '../../../data/models/roles/INormalizedRolesWithSettings.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { getNormalizedRolesWithSettingsForUser } from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isAllowedTypeForCapability } from './activeCapabilities.ts';
import { Capability } from './capability.ts';
import {
  getApplicableCollectionGroup,
  getCurrentUserRoleForCollectionForLanguageForUser,
} from './getContributorRole.ts';

export const filterCollectionsByCapability = memoize.maxOne(
  (
    capability: Capability,
    collections: CollectionsMap,
    projectId: Uuid,
    user: IUser,
  ): readonly ICollection[] => {
    const normalizedRoles = getNormalizedRolesWithSettingsForUser(user, projectId);
    const collectionsWithCapability: ICollection[] = [];

    collections.forEach((collection: ICollection) => {
      const rolesInCollection = getApplicableCollectionGroup(
        normalizedRoles.collectionGroups,
        collection.id,
      )?.roles;

      const hasCapabilityInCollection = rolesInCollection?.some(
        (languageBoundRole: ILanguageBoundRole) =>
          languageBoundRole.role.capabilities.contains(capability),
      );

      if (hasCapabilityInCollection) {
        collectionsWithCapability.push(collection);
      }
    });

    return collectionsWithCapability;
  },
);

export const filterCollectionsByCapabilityInLanguage = (
  selectableCollections: ReadonlyArray<ICollection>,
  languageId: Uuid,
  capability: Capability,
  projectId: Uuid,
  user: IUser,
): ReadonlyArray<ICollection> => {
  return selectableCollections.filter((collection) =>
    getCurrentUserRoleForCollectionForLanguageForUser(
      user,
      projectId,
      collection.id,
      languageId,
    )?.capabilities.contains(capability),
  );
};

export const filterCollectionsByCapabilityForTypeInLanguage = (
  selectableCollections: ReadonlyArray<ICollection>,
  languageId: Uuid,
  projectId: Uuid,
  capability: Capability,
  user: IUser,
  typeId: Uuid,
): ReadonlyArray<ICollection> => {
  return selectableCollections.filter((collection) =>
    isAllowedTypeForCapability(
      getCurrentUserRoleForCollectionForLanguageForUser(user, projectId, collection.id, languageId)
        ?.settings,
      capability,
    )(typeId),
  );
};
