import React from 'react';
import { ICustomTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import { ICustomItemElement } from '../../../models/contentItemElements/CustomItemElement.ts';
import { ElementValue } from '../../../types/CustomElementApi.ts';
import { CustomElementSandbox } from '../../ContentItemEditing/containers/elements/customElement/CustomElementSandbox.tsx';
import { CustomElementDiff } from '../../Revisions/components/elements/subelements/CustomElementDiff.tsx';
import { ExpandedItemElement } from '../containers/ExpandedItemElement.tsx';
import { ExpandedItemElementDataProps } from './types/expandedItemElement.type.ts';

type ExpandedCustomElementStateProps = ExpandedItemElementDataProps<
  ICustomTypeElement,
  ICustomItemElement
> & {
  readonly getElementValue: (elementCodename: string) => ElementValue | undefined;
};

type ExpandedCustomElementProps = ExpandedCustomElementStateProps;

export const ExpandedCustomElement: React.FC<ExpandedCustomElementProps> = ({
  className,
  elementData,
  getElementValue,
  originalElementData,
  typeElement,
}) => {
  return (
    <ExpandedItemElement
      className={className}
      disabled
      hideValidationStatus
      typeElement={typeElement}
    >
      {originalElementData ? (
        <CustomElementDiff elementData={elementData} originalElementData={originalElementData} />
      ) : (
        <CustomElementSandbox
          className="custom-element__sandbox"
          typeElement={typeElement}
          value={elementData.value}
          getElementValue={getElementValue}
          disabled
        />
      )}
    </ExpandedItemElement>
  );
};

ExpandedCustomElement.displayName = 'ExpandedCustomElement';
