import { DefaultWorkflowId } from '../../../../../_shared/constants/variantIdValues.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import {
  RegularWorkflowStep,
  WorkflowStepAction,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import {
  IVariantWorkflowStepServerModel,
  WorkflowActionServerModel,
} from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';

const parseActionFromServerModel = (action: WorkflowStepAction): WorkflowActionServerModel => {
  switch (action) {
    case WorkflowStepAction.Publish:
      return 'publish';
    case WorkflowStepAction.ScheduleToPublish:
      return 'scheduleToPublish';
    default:
      return null;
  }
};

const mapToAssignmentWorkflowStep = (
  variantWorkflowStep: RegularWorkflowStep,
  workflowId: string,
): IVariantWorkflowStepServerModel => ({
  _id: variantWorkflowStep.id,
  workflowId,
  name: variantWorkflowStep.name,
  codeName: variantWorkflowStep.codename,
  color: variantWorkflowStep.color,
  action: parseActionFromServerModel(variantWorkflowStep.action),
  transitionsTo: [...variantWorkflowStep.transitionsTo],
});

export const getDemoWorkflowStepServerModel = (
  workflow: Workflow,
  stepIndex: number,
): IVariantWorkflowStepServerModel => {
  const steps = workflow?.steps || [];
  const step = steps[stepIndex] ?? steps[0];
  return mapToAssignmentWorkflowStep(step, DefaultWorkflowId);
};
