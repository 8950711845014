import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { ContentGroupSelectionReason } from '../../../features/ContentItemEditing/constants/ContentGroupSelectionReason.ts';
import {
  ContentItemEditing_ContentComponent_Collapsed,
  ContentItemEditing_ContentComponent_Expanded,
  ContentItemEditing_ContentComponents_Collapsed,
  ContentItemEditing_ContentComponents_Expanded,
  ContentItemEditing_ContentGroup_Selected,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_Init_Started,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { getAllContentComponentIdsFromElements } from '../../../features/ContentItemEditing/utils/contentComponentUtils.ts';

const numberOfComponentsStartingToCausePerformanceIssuesWhenExpanding = 15;

const initialState: Immutable.Set<Uuid> = Immutable.Set();

export function collapsedContentComponents(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case ContentItemEditing_Init_Started: {
      return initialState;
    }

    case ContentItemEditing_Init_Ready: {
      const allContentComponentIds = getAllContentComponentIdsFromElements(
        action.payload.editedContentItemVariantElements,
      );
      if (
        allContentComponentIds.count() >=
        numberOfComponentsStartingToCausePerformanceIssuesWhenExpanding
      ) {
        return state.merge(allContentComponentIds);
      }
      return initialState;
    }

    case ContentItemEditing_ContentComponent_Collapsed: {
      const { contentComponentId } = action.payload;
      return state.add(contentComponentId);
    }

    case ContentItemEditing_ContentComponent_Expanded: {
      const { contentComponentId } = action.payload;
      return state.remove(contentComponentId);
    }

    case ContentItemEditing_ContentComponents_Collapsed: {
      const { contentComponentIds } = action.payload;
      return state.merge(contentComponentIds);
    }

    case ContentItemEditing_ContentComponents_Expanded: {
      const { contentComponentIds } = action.payload;
      return state.subtract(contentComponentIds);
    }

    case ContentItemEditing_ContentGroup_Selected: {
      if (action.payload.reason === ContentGroupSelectionReason.UserClick) {
        return state;
      }
      return state.remove(action.payload.contentGroupTabsId);
    }

    default: {
      return state;
    }
  }
}
