import { assert } from '@kontent-ai/utils';
import { Pathname } from 'history';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { GetVariantWithElementsRequestModel } from '../../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { IContentItemVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isTextTypeElement,
  isUrlSlugTypeElement,
} from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IUrlSlugItemElement } from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { IConvertElementsToDomainModel } from '../../../../stores/utils/contentItemHelperMethods.ts';
import {
  contentItemElementRefreshFailed,
  contentItemElementRefreshFetched,
  contentItemElementRefreshStarted,
} from '../contentItemEditingActions.ts';
import { AutoGenerateUrlSlugAction } from './autoGenerateUrlSlug.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

type Deps = Readonly<{
  contentItemRepository: {
    readonly getVariantWithElements: (
      itemId: Uuid,
      variantId: Uuid,
      query: GetVariantWithElementsRequestModel,
    ) => Promise<IContentItemVariantServerModel>;
  };
  loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  convertElementsToDomainModel: IConvertElementsToDomainModel;
  trackUserEventWithData: TrackUserEventWithDataAction;
  autoGenerateUrlSlug: AutoGenerateUrlSlugAction;
}>;

const isAutoGeneratedUrlSlug = (element: IUrlSlugItemElement): boolean =>
  element.type === ElementType.UrlSlug && element.mode === UrlSlugMode.Auto;

const getDependentUrlSlugElementIds = (
  editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>,
  editedContentItemTypeElements: ReadonlyArray<TypeElement>,
  refreshingElementId: Uuid,
): ReadonlyArray<string> => {
  const refreshingElementType = editedContentItemTypeElements.find(
    (elementType) => elementType.elementId === refreshingElementId,
  );
  if (!isTextTypeElement(refreshingElementType)) {
    return [];
  }

  return editedContentItemVariantElements
    .filter(isAutoGeneratedUrlSlug)
    .filter((element) => {
      const urlSlugTypeElement = editedContentItemTypeElements.find(
        (typeElement) => typeElement.elementId === element.elementId,
      );
      return (
        isUrlSlugTypeElement(urlSlugTypeElement) &&
        urlSlugTypeElement.dependentTextElementId === refreshingElementId
      );
    })
    .map((element) => element.elementId);
};

export const createStartContentItemElementRefreshAction =
  (deps: Deps) =>
  ({ elementId, pathname }: Readonly<{ elementId: Uuid; pathname: Pathname }>): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        editedContentItemVariantElements,
        loadedContentItemTypes,
      },
      data: { user },
    } = getState();

    assert(
      editedContentItem,
      () => 'startContentItemElementRefresh.ts: "editedContentItem" is not loaded.',
    );
    assert(
      editedContentItemVariant,
      () => 'startContentItemElementRefresh.ts: "editedContentItemVariant" is not loaded.',
    );

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );
    assert(
      editedContentItemVariant,
      () =>
        `startContentItemElementRefresh.ts: Cannot find item type ${editedContentItem.editedContentItemTypeId} in loadedContentItemTypes.`,
    );

    dispatch(contentItemElementRefreshStarted(elementId));

    try {
      const contentItemId = editedContentItemVariant.id;
      const elementData = editedContentItemVariantElements.find((e) => e.elementId === elementId);

      const dependentUrlSlugElementIds = getDependentUrlSlugElementIds(
        editedContentItemVariantElements,
        editedContentItemType?.contentElements ?? [],
        elementId,
      );
      const elementIds = [elementId].concat(dependentUrlSlugElementIds);

      const refreshedVariant = await deps.contentItemRepository.getVariantWithElements(
        contentItemId.itemId,
        contentItemId.variantId,
        { elementIds },
      );
      const refreshedVariantElements = deps.convertElementsToDomainModel(
        refreshedVariant?.contentElements,
      );

      await dispatch(
        deps.loadRelatedContentItemElementsData(
          contentItemId.itemId,
          contentItemId.variantId,
          refreshedVariantElements,
          null,
        ),
      );

      dispatch(contentItemElementRefreshFetched(refreshedVariantElements));

      const changedBySelf = refreshedVariant.lastModifiedBy === user.info.userId;

      const nonLocalizableElementIds = editedContentItemType?.contentElements
        .filter((e) => e.isNonLocalizable)
        .map((e) => e.elementId);
      const isRefreshedElementNonLocalizable = nonLocalizableElementIds?.includes(elementId);
      const localizableDependentUrlSlugIds = dependentUrlSlugElementIds.filter(
        (e) => !nonLocalizableElementIds?.includes(e),
      );

      if (isRefreshedElementNonLocalizable && localizableDependentUrlSlugIds.length > 0) {
        localizableDependentUrlSlugIds.forEach((id) =>
          dispatch(deps.autoGenerateUrlSlug({ elementId: id, pathname })),
        );
      }

      if (elementData) {
        dispatch(
          deps.trackUserEventWithData(TrackedEvent.ContentEntryElementRefreshed, {
            'changed-by-self': changedBySelf,
            'element-id': elementId,
            'element-type': elementData.type,
          }),
        );
      }
    } catch (e) {
      dispatch(contentItemElementRefreshFailed());
      logError(`Refreshing element with id: '${elementId}' failed.`, e);
    }
  };
