import Immutable from 'immutable';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { ActionType } from '../constants/ActionType.ts';
import { ObjectType } from '../constants/ObjectType.ts';
import {
  AuditLog_Filter_ActionTypeChanged,
  AuditLog_Filter_Clear,
  AuditLog_Filter_FromDateChanged,
  AuditLog_Filter_ObjectTypeChanged,
  AuditLog_Filter_SearchPhraseChanged,
  AuditLog_Filter_ToDateChanged,
} from '../constants/auditLogActionTypes.ts';

export const actionTypeChanged = (selectedActionTypes: Immutable.Set<ActionType>) =>
  ({
    type: AuditLog_Filter_ActionTypeChanged,
    payload: {
      selectedActionTypes,
    },
  }) as const;

export const objectTypeChanged = (selectedObjectTypes: Immutable.Set<ObjectType>) =>
  ({
    type: AuditLog_Filter_ObjectTypeChanged,
    payload: {
      selectedObjectTypes,
    },
  }) as const;

export const fromDateChanged = (selectedFromDate: DateTime, isFromDateBeforeToDate: boolean) =>
  ({
    type: AuditLog_Filter_FromDateChanged,
    payload: {
      selectedFromDate: {
        ...selectedFromDate,
        isValid: selectedFromDate.isValid && isFromDateBeforeToDate,
      },
    },
  }) as const;

export const toDateChanged = (selectedToDate: DateTime, isFromDateBeforeToDate: boolean) =>
  ({
    type: AuditLog_Filter_ToDateChanged,
    payload: {
      selectedToDate: {
        ...selectedToDate,
        isValid: selectedToDate.isValid && isFromDateBeforeToDate,
      },
    },
  }) as const;

export const clearAuditLogFilter = () => {
  return {
    type: AuditLog_Filter_Clear,
  } as const;
};

export const updateSearchPhrase = (changedSearchPhrase: string) => {
  return {
    type: AuditLog_Filter_SearchPhraseChanged,
    payload: {
      changedSearchPhrase,
    },
  } as const;
};

export type AuditLogFilterActionsType = ReturnType<
  | typeof actionTypeChanged
  | typeof objectTypeChanged
  | typeof fromDateChanged
  | typeof toDateChanged
  | typeof clearAuditLogFilter
  | typeof updateSearchPhrase
>;
