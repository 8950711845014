import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IContentTypeSnippetTypeElementData } from '../../../models/elements/ContentTypeSnippetTypeElementData.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { ContentTypeSnippetTypeElementValidationResult } from '../../../utils/typeElementValidators/types/ContentTypeSnippetTypeElementValidationResult.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';

function getContentTypeSnippetTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: IContentTypeSnippetTypeElementData,
): ContentTypeSnippetTypeElementValidationResult {
  const { getGuidelinesValidationResult, isUuid } = typeElementValidationUtils;

  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);
  const isSelectedContentTypeSnippetValid = isUuid(typeElement.snippetId);

  const errors: Array<string> = [];
  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errors.push(guidelinesValidationResult.errorMessage);
  }

  if (!isSelectedContentTypeSnippetValid) {
    errors.push('Select a content type snippet.');
  }

  return {
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isSelectedContentTypeSnippetValid,
    errorMessages: errors,
  };
}

export const createValidateTypeSnippetTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (
    typeElement: IBaseTypeElementData,
  ): ThunkFunction<ContentTypeSnippetTypeElementValidationResult> =>
  (dispatch) => {
    const validationResult = getContentTypeSnippetTypeElementValidationResult(
      deps,
      typeElement as IContentTypeSnippetTypeElementData,
    );
    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
