import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { ILanguageServerModel } from '../../../../repositories/serverModels/ProjectLanguagesServerModel.type.ts';
import { Data_Languages_Success } from '../../../constants/dataActionTypes.ts';
import { Languages, createLanguageFromServerModel } from '../../../models/languages/Language.ts';

const initialState: Languages = Immutable.OrderedMap();

export function byId(state: Languages = initialState, action: Action): Languages {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_Languages_Success: {
      const languages = action.payload.data.languages.reduce(
        (reduced: Languages, item: ILanguageServerModel) =>
          reduced.set(item.id, createLanguageFromServerModel(item)),
        initialState,
      );
      return languages;
    }

    default:
      return state;
  }
}
