import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_Transactions_Loaded } from '../../../../applications/subscriptionManagement/Transactions/constants/actionTypes.ts';
import { ISubscriptionTransaction } from '../../../../applications/subscriptionManagement/Transactions/models/SubscriptionTransaction.ts';
import { Subscription_SelectSubscription } from '../../../../applications/subscriptionManagement/shared/constants/subscriptionManagementActionTypes.ts';

export const subscriptionTransactions = (
  state: ReadonlyArray<ISubscriptionTransaction> | null = null,
  action: Action,
): ReadonlyArray<ISubscriptionTransaction> | null => {
  switch (action.type) {
    case Subscription_Transactions_Loaded:
      return action.payload.subscriptionTransactions;

    case Subscription_SelectSubscription:
      return null;

    default:
      return state;
  }
};
