import { Button } from '@kontent-ai/component-library/Button';
import { PureComponent } from 'react';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface INewCommentThreadItemActionsBarProps {
  readonly isSubmitting: boolean;
  readonly isSuggestion: boolean;
  readonly isSubmitButtonDisabled: boolean;

  readonly onCancelEditing: () => void;
}

export class NewCommentThreadItemActionsBar extends PureComponent<INewCommentThreadItemActionsBarProps> {
  static displayName = 'NewCommentThreadItemActionsBar';

  private readonly getButtonText = (): string => {
    const { isSuggestion, isSubmitting } = this.props;
    if (isSuggestion) {
      return isSubmitting ? 'Suggesting…' : 'Suggest changes';
    }

    return isSubmitting ? 'Sending…' : 'Send';
  };

  private readonly getSubmitButtonTooltip = (): string =>
    this.props.isSuggestion
      ? 'Suggest a replacement for the highlighted text'
      : 'Save your comment';

  render() {
    const { isSuggestion, onCancelEditing, isSubmitting, isSubmitButtonDisabled } = this.props;

    const cancelButtonTooltip = isSuggestion ? 'Discard your suggestion' : 'Discard your comment';

    return (
      <div className="comment-editor__actions">
        <Button
          tooltipText={isSubmitting ? undefined : cancelButtonTooltip}
          tooltipPlacement="bottom-end"
          tooltipShortcuts={ShortcutSymbols.Escape}
          buttonStyle="secondary"
          size="small"
          onClick={isSubmitting ? undefined : onCancelEditing}
          disabled={isSubmitting}
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
        >
          Cancel
        </Button>
        <Button
          tooltipText={
            !isSubmitting && !isSubmitButtonDisabled ? this.getSubmitButtonTooltip() : undefined
          }
          tooltipPlacement="bottom-end"
          tooltipShortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
          buttonStyle="primary"
          size="small"
          type="submit"
          disabled={isSubmitButtonDisabled}
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
        >
          {this.getButtonText()}
        </Button>
      </div>
    );
  }
}
