import { shouldApplyMultipleWorkflowsGovernance } from '../../../../../../../_shared/selectors/Workflows/shouldApplyMultipleWorkflowsGovernance.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { canDuplicateItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from '../../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { canRoleDoSomethingInStep } from '../../../../../../../_shared/utils/permissions/roleInWorkflowStepUtils.ts';
import { getWorkflow } from '../../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { EditedContentItemVariant } from '../../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

const canDuplicateEditedItem = (state: IStore, languageId: Uuid): boolean => {
  const {
    contentApp: { editedContentItem },
  } = state;

  return (
    !!editedContentItem &&
    canDuplicateItem(state, languageId, editedContentItem.editedContentItemTypeId)
  );
};

const canCreateItemViaDuplication = (state: IStore, variant: EditedContentItemVariant): boolean => {
  if (!shouldApplyMultipleWorkflowsGovernance(state)) {
    return true;
  }

  const {
    contentApp: { editedContentItem },
    data: { user },
    sharedApp: { currentProjectId },
  } = state;
  const {
    assignment: {
      workflowStatus: { workflowId },
    },
    id: { variantId },
  } = variant;

  const workflow = getWorkflow(state, workflowId);
  const firstStepId = workflow?.steps[0].id;

  if (!firstStepId || !editedContentItem) return false;

  const userRole = getCurrentUserRoleForCollectionForLanguageForUser(
    user,
    currentProjectId,
    editedContentItem.collectionId,
    variantId,
  );

  return canRoleDoSomethingInStep(userRole?.id, firstStepId, workflow);
};

export const isDuplicateItemWithContentAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
) => canDuplicateEditedItem(state, variant.id.variantId);

export const isDuplicateItemWithoutContentAvailable: ContentItemEditingActionAvailabilityProvider =
  (state, variant) =>
    canDuplicateEditedItem(state, variant.id.variantId) &&
    canCreateItemViaDuplication(state, variant);

export const isDuplicateItemAvailable: ContentItemEditingActionAvailabilityProvider = (
  state,
  variant,
  currentPath,
) =>
  isDuplicateItemWithContentAvailable(state, variant, currentPath) ||
  isDuplicateItemWithoutContentAvailable(state, variant, currentPath);
