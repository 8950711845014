import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface ICreateNewBarItemButtonDataProps {
  readonly itemName: string;
}

interface CreateNewBarButtonCallbacksProps {
  readonly onCreateNewClick: () => void;
}

type CreateNewBarItemButtonProps = ICreateNewBarItemButtonDataProps &
  CreateNewBarButtonCallbacksProps;

export const CreateNewBarItemButton: React.FC<CreateNewBarItemButtonProps> = (props) => (
  <li className="bar-item__list-actions">
    <Button
      buttonStyle="primary"
      size="medium"
      onClick={props.onCreateNewClick}
      {...getDataUiActionAttribute(DataUiAction.CreateNew)}
    >
      Create new {props.itemName}
    </Button>
  </li>
);

CreateNewBarItemButton.displayName = 'CreateNewBarItemButton';
