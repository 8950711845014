import { Box } from '@kontent-ai/component-library/Box';
import React from 'react';
import {
  AnimatedChevron,
  RestDirection,
} from '../../../uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import { SquareButtonShell } from '../../../uiComponents/SquareButton/SquareButtonShell.tsx';
import { SquareButtonSize } from '../../../uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

interface IBarItemExpandCollapseActionProps {
  readonly disabled?: boolean;
  readonly isCollapsed: boolean;
  readonly onExpand: () => void;
  readonly onCollapse: () => void;
}

export const BarItemExpandCollapseAction: React.FC<IBarItemExpandCollapseActionProps> = ({
  disabled,
  isCollapsed,
  onExpand,
  onCollapse,
}) => (
  <Box className="bar-item__action">
    <SquareButtonShell
      disabled={disabled}
      onClick={disabled ? undefined : isCollapsed ? onExpand : onCollapse}
      ariaLabel={isCollapsed ? 'Expand' : 'Collapse'}
      style={SquareButtonStyle.Quinary}
      size={SquareButtonSize.Quinary}
      {...getDataUiActionAttribute(isCollapsed ? DataUiAction.Expand : DataUiAction.Collapse)}
    >
      <AnimatedChevron isTurned={!isCollapsed} restDirection={RestDirection.Down} />
    </SquareButtonShell>
  </Box>
);

BarItemExpandCollapseAction.displayName = 'BarItemExpandCollapseAction';
