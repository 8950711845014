import { useCallback, useEffect, useState } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isAiImageTaggingEnabled } from '../../../../_shared/selectors/aiSelectors.ts';

enum Direction {
  Backward = 'Backward',
  Forward = 'Forward',
  WaitAtEnd = 'WaitAtEnd',
  WaitAtBeginning = 'WaitAtBeginning',
}

const pauseBetweenLettersAppearingMs = 40;

class Holder {
  private readonly waitAtEndTimes = 18; // using these values makes the animation look nicer, there’s no science behind them
  private readonly waitAtBeginningTimes = 6;

  private waitedTimes: number = 0;

  public shouldWait = (direction: Direction.WaitAtEnd | Direction.WaitAtBeginning): boolean => {
    if (this.waitedTimes < this.getWaitTimesMax(direction)) {
      this.waitedTimes++;
      return true;
    }

    this.waitedTimes = 0;
    return false;
  };

  private readonly getWaitTimesMax = (
    direction: Direction.WaitAtEnd | Direction.WaitAtBeginning,
  ): number =>
    direction === Direction.WaitAtEnd ? this.waitAtEndTimes : this.waitAtBeginningTimes;
}

export const useAssetSearchPlaceholderTypewriterAnimation = (
  prefix: string,
  suffixes: ReadonlyArray<string>,
  maxRepeat = 2,
) => {
  const isFeatureEnabled = useSelector(isAiImageTaggingEnabled);

  const [typedText, setTypedText] = useState(suffixes[0] ?? '');
  const [isEnabled, setIsEnabled] = useState(isFeatureEnabled);

  const stopAnimation = useCallback(() => setIsEnabled(false), []);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    // The following is basically a state machine for the typewriter animation.
    //
    // The animation goes like this:
    // 1. Start from the first suffix
    // 2. Type the suffix forwards (If all the suffixes have been typed out maxRepeat times, finish.)
    // 3. Wait for a short time
    // 4. Delete the typed suffix from the back
    // 5. Wait for a short time
    // 6. Take the next suffix, and go to step 2
    //
    // Note that all the suffixes get typed maxRepeat times, and then the first suffix gets typed once more,
    // after which the animation finishes.

    // This is the state machine’s state. It sets the typed text based on the currently used suffix, direction, and character position.
    let currentSuffixIndex = 0;
    const getCurrentSuffix = () => suffixes[currentSuffixIndex] ?? '';
    let characterPosition = 0;
    let direction = Direction.Forward;
    let allSuffixesTypedCount = 0;

    const holder = new Holder();

    const animate = () => {
      if (direction === Direction.Backward) {
        if (characterPosition > 0) {
          characterPosition--;
          setTypedText((t) => t.substring(0, characterPosition));
        } else {
          direction = Direction.WaitAtBeginning;
        }
      } else if (direction === Direction.Forward) {
        if (characterPosition < getCurrentSuffix().length) {
          characterPosition++;
          setTypedText(getCurrentSuffix().substring(0, characterPosition));
        } else {
          if (allSuffixesTypedCount === maxRepeat) {
            // after typing all suffixes maxRepeat times, and the first one once more, we stop
            clearInterval(interval);
            return;
          }

          direction = Direction.WaitAtEnd;
        }
      } else if (direction === Direction.WaitAtBeginning || direction === Direction.WaitAtEnd) {
        if (holder.shouldWait(direction)) {
          return;
        }

        if (direction === Direction.WaitAtBeginning) {
          direction = Direction.Forward;

          if (currentSuffixIndex + 1 === suffixes.length) {
            allSuffixesTypedCount++;
          }
          currentSuffixIndex = (currentSuffixIndex + 1) % suffixes.length;
        } else {
          direction = Direction.Backward;
        }
      }
    };

    const interval = setInterval(animate, pauseBetweenLettersAppearingMs);

    return () => {
      clearInterval(interval);
      setTypedText(getCurrentSuffix());
    };
  }, [isEnabled, maxRepeat, suffixes]);

  return {
    text: prefix + typedText,
    stopAnimation,
  };
};
