import { Action } from '../../../../@types/Action.type.ts';
import { Subscription_PlanChange_ShowDialog } from '../constants/actionTypes.ts';

export const selectedPlanIdToChange = (state: Uuid = '', action: Action): Uuid => {
  switch (action.type) {
    case Subscription_PlanChange_ShowDialog:
      return action.payload.selectedPlanId;

    default:
      return state;
  }
};
