import { Action } from '../../../../../../@types/Action.type.ts';
import { Task } from '../../../../../../_shared/models/Task.ts';
import {
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_LoadTasksForItemVariant_Finished,
  ContentItemEditing_Sidebar_Opened,
  ContentItemEditing_TaskArchive_Finished,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlyArray<Uuid> = [];

export const orderedTaskIds = (state = initialState, action: Action): ReadonlyArray<Uuid> => {
  switch (action.type) {
    case ContentItemEditing_LoadTasksForItemVariant_Finished:
    case ContentItemEditing_Sidebar_Opened: {
      return action.payload.orderedTasks.map((task: Task) => task.id);
    }

    case ContentItemEditing_CreateTask_Finished:
      return [action.payload.task.id, ...state.filter((id) => id !== action.payload.task.id)];

    case ContentItemEditing_TaskArchive_Finished:
      return state.filter((id) => id !== action.payload.taskId);

    default:
      return state;
  }
};
