import { Collection } from '@kontent-ai/utils';
import { OrderBy, OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import {
  AssetOrderingServerCode,
  IAssetQueryServerModel,
} from '../../../../repositories/serverModels/AssetServerModels.type.ts';
import { TaxonomyGroupFilterServerModel } from '../../../../repositories/serverModels/TaxonomyFilterServerModel.type.ts';
import { getTaxonomyGroupFilterServerModel } from '../../shared/utils/getTaxonomyGroupFilterServerModel.ts';
import { uncategorizedAssetFilterTaxonomyTermId } from '../constants/taxonomyConstants.ts';
import { IAssetLibraryQuery } from '../models/IAssetLibraryQuery.ts';
import { AssetOrderingCode } from './assetListingOrderingUtils.ts';

type Params = {
  readonly continuationToken: string | null;
  readonly folderId: Uuid;
  readonly includeSubFolders: boolean;
  readonly maxAssetsCount: number;
  readonly query: IAssetLibraryQuery;
  readonly orderBy: OrderBy<AssetOrderingCode>;
};

export const mapAssetQueryToServerModel = ({
  continuationToken,
  folderId,
  includeSubFolders,
  maxAssetsCount,
  query,
  orderBy,
}: Params): IAssetQueryServerModel => ({
  continuationToken,
  folderId,
  includeSubFolders,
  maxAssetsCount,
  orderBy: {
    columnName: mapClientAssetOrderingColumnCodeToServerValue(orderBy.columnCode),
    direction: orderBy.direction === OrderByDirection.Ascending ? 'asc' : 'desc',
  },

  searchPhrase: query.searchPhrase,
  mimeType: query.mimeType,

  // other user-defined filters must be null if user did not select anything
  collectionIds: query.selectedCollections.length ? query.selectedCollections : null,
  taxonomies: query.selectedTaxonomyNodes.size
    ? Object.fromEntries(
        Collection.getEntries(query.selectedTaxonomyNodes).map(([groupId, termIds]) => [
          groupId,
          getTaxonomyGroupAssetFilterServerModel([...termIds]),
        ]),
      )
    : null,
});

const mapClientAssetOrderingColumnCodeToServerValue = (name: string): AssetOrderingServerCode => {
  switch (name) {
    case AssetOrderingCode.Name:
      return AssetOrderingServerCode.Name;
    case AssetOrderingCode.CreatedAt:
      return AssetOrderingServerCode.CreatedAt;
    case AssetOrderingCode.FileSize:
      return AssetOrderingServerCode.FileSize;
    default:
      throw new Error(`The provided value ${name} is unmappable to the server value`);
  }
};

export const getTaxonomyGroupAssetFilterServerModel = (
  termIds: ReadonlyArray<Uuid>,
): TaxonomyGroupFilterServerModel =>
  getTaxonomyGroupFilterServerModel(termIds, uncategorizedAssetFilterTaxonomyTermId);
