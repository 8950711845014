import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RedirectIf } from '../../_shared/components/routing/RedirectIf.tsx';
import { AppNames } from '../../_shared/constants/applicationNames.ts';
import {
  PageNotFoundRoute,
  ProjectAccessDeniedApiKeyDetailRoute,
  ProjectAccessDeniedGeneralSettingsRoute,
  ProjectAccessDeniedRoute,
  ProjectAccessDeniedRouteParams,
  ProjectWarningRoute,
  RootRoute,
} from '../../_shared/constants/routePaths.ts';
import { IRouteContext, RouteContext } from '../../_shared/containers/routing/RouteContext.tsx';
import { AccessDenied } from './components/AccessDenied.tsx';
import { isAnyKnownAppName } from './utils/isAnyKnownAppName.ts';

export const ProjectErrorHandlingRoutes: React.FC = () => (
  <Route key={AppNames.ErrorHandling} path={ProjectWarningRoute}>
    <Switch>
      <Route path={ProjectAccessDeniedGeneralSettingsRoute}>
        <AccessDenied
          title="Do you need to change the general settings of project?"
          descriptionParagraphs={[
            'You can’t make any changes here.',
            'Contact your subscription admin for more information.',
          ]}
        />
      </Route>
      <Route path={ProjectAccessDeniedApiKeyDetailRoute}>
        <AccessDenied
          title="You don’t have permission to view this key."
          descriptionParagraphs={['Contact your project manager for more information.']}
        />
      </Route>
      <Route path={ProjectAccessDeniedRoute}>
        <RouteContext>
          {({ match }: IRouteContext<ProjectAccessDeniedRouteParams>) => (
            <RedirectIf
              redirectTo={RootRoute}
              doRedirect={!isAnyKnownAppName(decodeURIComponent(match.params.requestedAppName))}
            >
              <AccessDenied
                title={`Do you need to work with ${decodeURIComponent(
                  match.params.requestedAppName,
                )}?`}
                descriptionParagraphs={[
                  'You can’t make any changes here.',
                  'Contact your project manager for more information.',
                ]}
              />
            </RedirectIf>
          )}
        </RouteContext>
      </Route>
      <Route>
        <Redirect to={PageNotFoundRoute} />
      </Route>
    </Switch>
  </Route>
);
