import { assert } from '@kontent-ai/utils';
import { ISubscriptionBillingInformationServerModel } from '../../../../repositories/serverModels/SubscriptionServerModel.type.ts';

export interface ISubscriptionBillingInformation {
  readonly firstName: string;
  readonly lastName: string;
  readonly company: string;
  readonly email: string;
}

export const emptySubscriptionBillingInformation: ISubscriptionBillingInformation = {
  company: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const createSubscriptionBillingInformationDomainModel = (
  serverModel: ISubscriptionBillingInformationServerModel,
): ISubscriptionBillingInformation => {
  assert(!!serverModel, () => 'Subscription billing information server model is falsy.');

  return {
    firstName: serverModel.firstName ?? emptySubscriptionBillingInformation.firstName,
    lastName: serverModel.lastName ?? emptySubscriptionBillingInformation.lastName,
    email: serverModel.email ?? emptySubscriptionBillingInformation.email,
    company: serverModel.company ?? emptySubscriptionBillingInformation.company,
  };
};
