import { HtmlSettingsPageTitle } from '../../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionPermissionsEnabledForCurrentProject } from '../../../../../_shared/selectors/contentCollections.ts';
import { Tag } from '../../../../../_shared/uiComponents/Tag/Tag.tsx';
import { DataUiElement } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { isUserCountedInUsage } from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { formatUserName } from '../../../../../_shared/utils/usersUtils.ts';
import { TagColor } from '../../../../../data/constants/tagColor.ts';
import {
  ICollectionGroupRoles,
  IProjectContributor,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { EnvironmentSettingsAppNames } from '../../../root/constants/EnvironmentSettingsAppNames.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesUtils.ts';
import {
  resendInvite,
  revokeInvite,
  updateCollectionGroups,
} from '../../actions/thunkUsersActions.ts';
import {
  collectionGroupsInUserDetailChanged,
  hideNotificationBarInUserDetail,
} from '../../actions/usersActions.ts';
import { UserDetailModalBody } from '../../components/userDetail/UserDetailModalBody.tsx';
import {
  UserDetailFooterLeft,
  UserDetailFooterRight,
} from '../../components/userDetail/UserDetailModalFooters.tsx';
import { useProjectManagerSelectionValidation } from '../../hooks/useProjectManagerSelectionValidation.ts';
import { getAdvancedRoleBuilderErrorMessage } from '../../selectors/getAdvancedRoleBuilderErrorMessage.ts';
import { hasUserDetailUnsavedChanges } from '../../selectors/hasUserDetailUnsavedChanges.ts';
import { createDisabledUserDetailTooltip } from '../../selectors/userModalsMessageSelectors.ts';

interface Props {
  readonly user: IProjectContributor;
  readonly onNavigateBack: () => void;
}

export const UserDetail = ({ user, onNavigateBack }: Props) => {
  const dispatch = useDispatch();

  const subscriptionId = useSelector((s) => getCurrentProject(s).subscriptionId);
  const disabledTooltip = useSelector((s) => createDisabledUserDetailTooltip(s, user));
  const areLanguageRolesEnabled = useSelector(areLanguageRolesEnabledForCurrentProject);
  const areCollectionPermissionsEnabled = useSelector(
    areCollectionPermissionsEnabledForCurrentProject,
  );
  const collectionGroups = useSelector((s) => s.usersApp.userDetail.collectionGroups);
  const isSaving = useSelector((s) => s.usersApp.userDetail.isSaving);
  const savingError = useSelector((s) => s.usersApp.userDetail.savingError);
  const hasUnsavedChanges = useSelector((s) => hasUserDetailUnsavedChanges(s, user));
  const isProjectManagerSelectionValid = useProjectManagerSelectionValidation(collectionGroups);
  const isCountedInUsage = useSelector((s) => isUserCountedInUsage(s, user.userId));

  const onRevokeInvite = () => dispatch(revokeInvite(user.userId, subscriptionId, onNavigateBack));
  const onResendInvite = () => dispatch(resendInvite(user.userId));

  const saveChanges = async (): Promise<void> =>
    await dispatch(
      updateCollectionGroups({
        userId: user.userId,
        collectionGroups,
      }),
    );

  const onCollectionGroupsChange = (newCollectionGroups: readonly ICollectionGroupRoles[]) =>
    dispatch(
      collectionGroupsInUserDetailChanged({
        newCollectionGroups,
        originalCollectionGroups: user.collectionGroups,
      }),
    );

  const errorMessage = getAdvancedRoleBuilderErrorMessage(
    collectionGroups,
    areLanguageRolesEnabled,
    areCollectionPermissionsEnabled,
    isProjectManagerSelectionValid,
  );
  const saveDisabledTooltip = disabledTooltip || errorMessage;

  const headerContent = user.isAdminOnProject ? (
    <div className="user-modal__title">
      <div>User detail</div>
      <div className="user-modal__tag">
        <Tag color={TagColor.Gray}>Admin</Tag>
      </div>
    </div>
  ) : (
    'User detail'
  );

  return (
    <>
      <HandleUnsavedFormOnNavigation
        disabledTooltip={saveDisabledTooltip}
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isSaving}
        onSaveChanges={async (onSuccess, onFail) => {
          try {
            await saveChanges();
            onSuccess();
          } catch {
            onFail();
          }
        }}
      />
      <HtmlSettingsPageTitle
        settingsAppName={EnvironmentSettingsAppNames.Users}
        customName={formatUserName(user)}
      />
      <ModalDialog
        headerContent={headerContent}
        onClose={onNavigateBack}
        dataUiElement={DataUiElement.UserDetailModalDialog}
        bodyContent={
          <UserDetailModalBody
            collectionGroups={collectionGroups}
            disabledTooltip={disabledTooltip}
            errorMessage={errorMessage}
            onCollectionGroupsChange={onCollectionGroupsChange}
            onHideErrorNotificationBar={compose(dispatch, hideNotificationBarInUserDetail)}
            savingError={savingError}
            user={user}
            isUserCountedInUsage={isCountedInUsage}
          />
        }
        footerContentRight={
          <UserDetailFooterRight
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving}
            onClose={onNavigateBack}
            onUpdateUserRoles={async () => {
              try {
                await saveChanges();
                onNavigateBack();
              } catch {
                return;
              }
            }}
            saveDisabledTooltip={saveDisabledTooltip}
          />
        }
        footerContentLeft={
          <UserDetailFooterLeft
            user={user}
            onResendInvite={onResendInvite}
            onRevokeInvite={onRevokeInvite}
          />
        }
        withFullWidthBodyContent
        withDividers
      />
    </>
  );
};
