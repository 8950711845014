import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';

interface IGenerateWebhookSecretDependencies {
  readonly webhookRepository: IWebhookRepository;
}

export const createGenerateWebhookSecretAction =
  (deps: IGenerateWebhookSecretDependencies) =>
  (webhookId: string): ThunkPromise<string | undefined> =>
  async (_, getState) => {
    const { editedWebhookId } = getState().webhooksApp;

    if (!editedWebhookId || editedWebhookId !== webhookId) {
      return undefined;
    }

    const { secret } = await deps.webhookRepository.generateSecret();
    return secret;
  };
