import classNames from 'classnames';
import React from 'react';
import { getDataUiObjectNameAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IBarItemWithLoaderProps {
  readonly additionalBarItemClass?: string;
  readonly dataUiObjectName?: string;
  readonly hasLoader?: boolean;
  readonly children: React.ReactNode;
}

const BarItemWithLoader: React.FC<IBarItemWithLoaderProps> = ({
  additionalBarItemClass,
  children,
  dataUiObjectName,
  hasLoader,
}) => (
  <>
    {hasLoader ? (
      <div className="bar-item__bar-loader-pane">
        <div className="bar-item__bar-loader" />
      </div>
    ) : null}
    <div
      className={classNames('bar-item__bar', additionalBarItemClass)}
      {...(dataUiObjectName && getDataUiObjectNameAttribute(dataUiObjectName))}
    >
      {children}
    </div>
  </>
);

BarItemWithLoader.displayName = 'BarItemWithLoader';

export { BarItemWithLoader };
