import styled, { css } from 'styled-components';
import { CheckboxStylingProps } from '../utils/stylingUtils.ts';

export const StyledCheckboxInput = styled.input<CheckboxStylingProps>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  
  // override normalize.css
  && {
    ${({ checkboxState }) =>
      css`cursor: ${checkboxState !== 'disabled' ? 'pointer' : 'not-allowed'}`};
  }
`;
