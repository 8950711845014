import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { FeedbackComment } from './FeedbackComment.tsx';
import { FeedbackRating } from './FeedbackRating.tsx';

export type MissionControlFeedbackFormShape = Readonly<{
  rating: string;
  comment: string;
}>;

type FeedbackFormProps = Readonly<{
  formProps: HookFormProps<MissionControlFeedbackFormShape>;
}>;

export const FeedbackForm: React.FC<FeedbackFormProps> = ({ formProps }) => {
  return (
    <Stack spacing={Spacing.XXL}>
      <FeedbackRating formProps={formProps} />
      <FeedbackComment formProps={formProps} />
    </Stack>
  );
};
