import React from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IApiKeyEmptyTableProps {
  readonly infoMessage: JSX.Element | undefined;
}

export const ApiKeyEmptyTable: React.FC<IApiKeyEmptyTableProps> = ({ infoMessage }) => (
  <DataTable
    noBoxShadow
    dataUiCollectionName={DataUiCollection.ApiKeys}
    infoMessage={infoMessage}
  />
);

ApiKeyEmptyTable.displayName = 'ApiKeyEmptyDataTable';
