import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { useFeedbackDialog } from '../hooks/useFeedbackDialog.tsx';

export const FeedbackButton: React.FC = () => {
  const { FeedbackDialog, targetProps, isOpen, openDialog, closeDialog } = useFeedbackDialog();

  const toggleDialog = isOpen ? closeDialog : openDialog;

  return (
    <>
      <QuinaryButton {...targetProps} activated={isOpen} tooltipText="" onClick={toggleDialog}>
        <QuinaryButton.Icon icon={Icons.BubbleHeart} />
        Help us improve
      </QuinaryButton>

      <FeedbackDialog />
    </>
  );
};
