import classNames from 'classnames';
import React from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import { IForwardedRefProps, forwardRef } from '../../utils/forwardedRefProps.tsx';

type TagProps = IForwardedRefProps<HTMLDivElement> & {
  readonly color: TagColor;
  readonly customClass?: string;
  readonly modifier?: TagModifier;
  readonly title?: string;
};

export enum TagModifier {
  Default = '',
  IsClickable = 'tag--is-clickable',
  IsSticky = 'tag--is-sticky',
}

const Tag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  return (
    <div
      ref={props.forwardedRef}
      className={classNames(
        'tag',
        {
          [`tag--${props.color}`]: props.color !== TagColor.None,
        },
        props.modifier,
        props.customClass,
      )}
      title={props.title}
    >
      {props.children}
    </div>
  );
};

Tag.displayName = 'Tag';

const TagRFC = forwardRef(Tag);
export { TagRFC as Tag };
