import { useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { EmptySpaceNamesMetadata, SpaceNamesMetadata, spaceNames } from '../utils/spacesUtils.ts';
import { useSpacesIds } from './useSpaceIds.ts';

export const useSpaceData = (collectionId: Uuid | undefined | null): SpaceNamesMetadata => {
  const spacesMap = useSelector((s) => s.data.spaces.byId);
  const spaceIds = useSpacesIds(collectionId);

  return useMemo(() => {
    return spaceIds ? spaceNames(spacesMap, spaceIds) : EmptySpaceNamesMetadata;
  }, [spaceIds, spacesMap]);
};
