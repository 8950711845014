import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { SnippetUsageServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import {
  Data_SnippetUsages_Failed,
  Data_SnippetUsages_Started,
  Data_SnippetUsages_Success,
} from '../../../constants/dataActionTypes.ts';

interface IDeps {
  readonly contentTypeSnippetRepository: {
    readonly getSnippetUsage: (abortSignal?: AbortSignal) => Promise<SnippetUsageServerModel>;
  };
}

const start = () => ({ type: Data_SnippetUsages_Started }) as const;
const success = (data: SnippetUsageServerModel) =>
  ({
    type: Data_SnippetUsages_Success,
    payload: { data },
  }) as const;
const failure = () => ({ type: Data_SnippetUsages_Failed }) as const;

export type LoadSnippetsUsageActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const createLoadSnippetsUsageAction =
  ({ contentTypeSnippetRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const usageServerModel = await contentTypeSnippetRepository.getSnippetUsage(abortSignal);
      dispatch(success(usageServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
