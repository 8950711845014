import React from 'react';
import { BarItemActions } from './BarItemActions.tsx';
import { BarItemTitle } from './BarItemTitle.tsx';
import { BarItemWithLoader } from './BarItemWithLoader.tsx';

export interface IBarItemExpandedSimpleHeaderProps {
  readonly additionalTitleClass?: string;
  readonly dataUiObjectName: string;
  readonly hasLoader?: boolean;
  readonly isDraggable?: boolean;
  readonly renderLeadingElement?: () => JSX.Element | React.ReactNode;
  readonly renderTitle: () => JSX.Element | React.ReactNode;
  readonly renderTitleTag?: () => JSX.Element | React.ReactNode;
}

export const BarItemExpandedSimpleHeader: React.FC<IBarItemExpandedSimpleHeaderProps> = ({
  additionalTitleClass,
  dataUiObjectName,
  hasLoader,
  isDraggable,
  renderLeadingElement,
  renderTitle,
  renderTitleTag,
}) => (
  <BarItemWithLoader
    additionalBarItemClass="bar-item__bar--is-expanded"
    dataUiObjectName={dataUiObjectName}
    hasLoader={hasLoader}
  >
    <BarItemActions>{isDraggable && <div className="bar-item__action" />}</BarItemActions>
    {renderLeadingElement?.()}
    <BarItemTitle
      className={additionalTitleClass}
      dataUiObjectName={dataUiObjectName}
      titleTag={renderTitleTag?.()}
    >
      {renderTitle()}
    </BarItemTitle>
  </BarItemWithLoader>
);
