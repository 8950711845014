import { IEventTrackingListener, eventTrackingService } from '../services/eventTrackingService.ts';

export type ChurnZeroUser = {
  readonly email: string;
  readonly subscriptionId: Uuid;
};

const trackChurnZeroEvent: IEventTrackingListener = (eventName, metadata): void => {
  if (self.ChurnZero && self._envConfig.isChurnZeroEnabled) {
    self.ChurnZero.push(['trackEvent', eventName, eventName, 1, metadata]);
  }
};

export const boot = (bootObject: ChurnZeroUser): void => {
  if (!self._envConfig.isChurnZeroEnabled || !self.ChurnZero) {
    return;
  }

  if (bootObject.subscriptionId && bootObject.email) {
    self.ChurnZero.push(['setContact', bootObject.subscriptionId, bootObject.email]);
  }

  eventTrackingService.subscribe(trackChurnZeroEvent);
};

export const shutdown = (): void => {
  if (self.ChurnZero) {
    self.ChurnZero.push(['stop']);
    eventTrackingService.unsubscribe(trackChurnZeroEvent);
  }
};
