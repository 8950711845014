import { Action } from '../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../_shared/constants/sharedActionTypes.ts';
import { PreviewError, PreviewUrlInfo } from '../../itemEditor/utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../itemEditor/utils/selectDeepestItemWithPreview.ts';
import {
  WebSpotlight_ItemPreviewInfoReload_Requested,
  WebSpotlight_LoadItemPreviewInfo_Failed,
  WebSpotlight_LoadItemPreviewInfo_Finished,
  WebSpotlight_LoadItemPreviewInfo_Started,
  WebSpotlight_LoadWebSpotlightTree_Started,
} from '../constants/webSpotlightActionTypes.ts';

export type ItemPreviewInfo = {
  readonly previewContentItemInfo: PreviewContentItemInfo | null;
  readonly previewUrlInfo: PreviewUrlInfo;
  readonly routeContentItemIds: UuidArray;
};

const initialState = null;

export function itemPreviewInfo(
  state: ItemPreviewInfo | null = initialState,
  action: Action,
): ItemPreviewInfo | null {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
    case WebSpotlight_LoadWebSpotlightTree_Started:
    case WebSpotlight_ItemPreviewInfoReload_Requested:
    case WebSpotlight_LoadItemPreviewInfo_Started:
      return initialState;

    case WebSpotlight_LoadItemPreviewInfo_Finished:
      return {
        previewContentItemInfo: action.payload.previewContentItemInfo,
        previewUrlInfo: action.payload.previewUrlInfo,
        routeContentItemIds: action.payload.routeContentItemIds,
      };

    case WebSpotlight_LoadItemPreviewInfo_Failed:
      return {
        routeContentItemIds: action.payload.routeContentItemIds,
        previewContentItemInfo: action.payload.previewContentItemInfo,
        previewUrlInfo: {
          error: PreviewError.LoadFailed,
          shouldShowPreviewLink: false,
          spaceId: null,
          url: undefined,
          usedUrlSlug: null,
        },
      };

    default:
      return state;
  }
}
