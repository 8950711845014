import Immutable from 'immutable';
import React, { useCallback, ComponentProps } from 'react';
import { FindRightAssetTrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getImageLimitsConfig } from '../../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { getAssetsForScrollGrid } from '../../../../../../data/reducers/assets/selectors/getAssetsForScrollGrid.ts';
import { AssetLimitations } from '../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  closeAssetSelectorOnCancel,
  closeAssetSelectorOnInsert,
} from '../../../actions/thunkAssetLibraryActions.ts';
import { useAssetSelection } from '../../../hooks/useAssetSelection.ts';
import { AssetsOrderBy } from '../../../types/orderBy.type.ts';
import { initializeAssetDialog } from '../actions/thunkModalAssetSelectorActions.ts';
import { AssetSelectorDialog } from '../components/AssetSelectorDialog.tsx';

type Props = Pick<
  ComponentProps<typeof AssetSelectorDialog>,
  'onClose' | 'showImagesOnly' | 'isOpen' | 'primaryButtonText' | 'titleBarText'
> & {
  readonly limitations: AssetLimitations;
  readonly onSelect: (assetIds: Immutable.List<Uuid>) => void;
};

export const ModalMultipleAssetsSelector: React.FC<Props> = ({
  limitations,
  onClose,
  onSelect,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  const listingAssetsIds = useSelector((s) => s.data.assets.listingAssets.listingIds);
  const {
    clearAssetSelection,
    deselectAssets,
    selectAssets,
    selectedAssets,
    toggleAssetSelection,
  } = useAssetSelection({
    listingAssetsIds,
    reduxDispatch: dispatch,
  });

  const assets = useSelector((state: IStore) => state.data.assets);

  const onInit = useCallback(
    (orderBy: AssetsOrderBy, abortSignal: AbortSignal) =>
      initializeAssetDialog(orderBy, otherProps.showImagesOnly, abortSignal),
    [otherProps.showImagesOnly],
  );

  const onCancel = useCallback(() => {
    dispatch(closeAssetSelectorOnCancel(FindRightAssetTrackedEvent.ClosedModalSelector));
    onClose?.();
  }, [onClose]);

  const onPlace = useCallback(() => {
    onSelect(Immutable.List(Array.from(selectedAssets)));
    dispatch(closeAssetSelectorOnInsert(Array.from(selectedAssets)));
  }, [onSelect, selectedAssets]);

  const onAssetClick = useCallback(
    (assetId: Uuid) => {
      toggleAssetSelection(assetId, false);
    },
    [toggleAssetSelection],
  );

  const assetsItems = getAssetsForScrollGrid(assets);
  const isPlacingEnabled =
    !!selectedAssets.size &&
    assetsItems.every((asset: IAsset | null) => !asset || !asset._uploading);
  const imageLimits = getImageLimitsConfig(limitations);

  return (
    <AssetSelectorDialog
      {...otherProps}
      clearAssetSelection={clearAssetSelection}
      deselectAssets={deselectAssets}
      imageLimits={imageLimits}
      isPlacingEnabled={isPlacingEnabled}
      onAssetClick={onAssetClick}
      onClose={onCancel}
      onInit={onInit}
      onPlace={onPlace}
      selectAssets={selectAssets}
      selectedAssets={selectedAssets}
      toggleAssetSelection={toggleAssetSelection}
    />
  );
};
