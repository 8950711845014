import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ProjectOrderBy } from '../../../../_shared/constants/projectOrderBy.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { compareEnvironments } from '../../../../applications/projectSettings/environments/utils/environmentsUtils.ts';
import { createCompareProjectDetails } from '../../../../applications/projects/utils/projectUtils.ts';
import { IProjectDetails } from '../../../models/projects/ProjectDetails.ts';

export const getProjectsForListing = memoize.allForever(
  (
    projects: Immutable.Map<Uuid, IProjectDetails>,
    orderBy: ProjectOrderBy,
    subscriptionId: Uuid | null,
  ): ReadonlyArray<IProjectDetails> => {
    const result = projects
      .valueSeq()
      .filter(
        (p: IProjectDetails) => subscriptionId === null || p.subscriptionId === subscriptionId,
      )
      .groupBy((project: IProjectDetails) => project.masterEnvironmentId)
      .toArray()
      .map(
        (groupedProjects: Immutable.Map<number, IProjectDetails>) =>
          groupedProjects
            .valueSeq()
            .sort((a: IProjectDetails, b: IProjectDetails) => compareEnvironments(a, b))
            .first() ?? null,
      )
      .filter(notNull)
      .sort(createCompareProjectDetails(orderBy));

    return result;
  },
);

export const getProjectDetail = (state: IStore, projectId: string): IProjectDetails | undefined =>
  getProjectDetailMemoized(state.data.projects.byId, projectId);

const getProjectDetailMemoized = memoize.maxOne(
  (
    projects: Immutable.Map<Uuid, IProjectDetails>,
    projectId: string,
  ): IProjectDetails | undefined =>
    projects.find((project: IProjectDetails) => project.projectId === projectId),
);
