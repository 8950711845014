import { combineReducers } from '@reduxjs/toolkit';
import { adminsBeingReinvitedIds } from '../../Admins/reducers/adminsBeingReinvitedIds.ts';
import { adminsBeingRevokedIds } from '../../Admins/reducers/adminsBeingRevokedIds.ts';
import { adminsReinvitedIds } from '../../Admins/reducers/adminsReinvitedIds.ts';
import { createNewAdminErrorMessage } from '../../Admins/reducers/createNewAdminErrorMessage.ts';
import { deletingSubscriptionAdmins } from '../../Admins/reducers/deletingSubscriptionAdmins.ts';
import { promoteUsersErrorMessage } from '../../Admins/reducers/promoteUsersErrorMessage.ts';
import { promotingSubscriptionAdmins } from '../../Admins/reducers/promotingSubscriptionAdmins.ts';
import { selectedAdminIds } from '../../Admins/reducers/selectedAdminsIds.ts';
import { selectedSubscriptionUsers } from '../../Admins/reducers/selectedSubscriptionUsers.ts';
import { sendingSubscriptionAdminInvitation } from '../../Admins/reducers/sendingSubscriptionAdminInvitation.ts';
import { selectedItemId } from '../../BillingHistory/reducers/selectedItemId.ts';
import { editedSubscriptionName } from '../../General/reducers/editedSubscriptionName.ts';
import { serverError } from '../../General/reducers/serverError.ts';
import { subscriptionIsBeingUpdated } from '../../General/reducers/subscriptionIsBeingUpdated.ts';
import { changeInProgress } from '../../PlanSelection/reducers/changeInProgress.ts';
import { selectedPlanIdToChange } from '../../PlanSelection/reducers/selectedPlanIdToChange.ts';
import { showChangePlanSuccessMessage } from '../../PlanSelection/reducers/showChangePlanSuccessMessage.tsx';
import { creatingNewSubscription } from '../../SubscriptionListing/reducers/creatingNewSubscription.ts';
import { creatingNewSubscriptionErrorMessage } from '../../SubscriptionListing/reducers/creatingSubscriptionErrorMessage.ts';
import { showCreateNewSubscriptionSuccessMessage } from '../../SubscriptionListing/reducers/showCreateNewSubscriptionSuccessMessage.ts';
import { subscriptionUserDetailUi } from '../../SubscriptionUsers/reducers/subscriptionUserDetailUi.ts';
import { subscriptionUsersListingUi } from '../../SubscriptionUsers/reducers/subscriptionUsersListingUi.ts';
import { regenerationStatus } from './regenerationStatus.ts';
import { selectedSubscriptionId } from './subscriptionEditingUi/selectedSubscriptionId.ts';

const general = combineReducers({
  editedSubscriptionName,
  serverError,
  subscriptionIsBeingUpdated,
});

const apiKeys = combineReducers({
  regenerationStatus,
});

const billingHistory = combineReducers({
  selectedItemId,
});

const plans = combineReducers({
  changeInProgress,
  selectedPlanIdToChange,
  showChangePlanSuccessMessage,
});

const admins = combineReducers({
  adminsBeingReinvitedIds,
  adminsBeingRevokedIds,
  adminsReinvitedIds,
  createNewAdminErrorMessage,
  deletingSubscriptionAdmins,
  promoteUsersErrorMessage,
  promotingSubscriptionAdmins,
  selectedAdminIds,
  selectedSubscriptionUsers,
  sendingSubscriptionAdminInvitation,
});

const users = combineReducers({
  listingUi: subscriptionUsersListingUi,
  userDetailUi: subscriptionUserDetailUi,
});

const listingUi = combineReducers({
  creatingNewSubscription,
  creatingNewSubscriptionErrorMessage,
  showCreateNewSubscriptionSuccessMessage,
});

export const subscriptionAppReducer = combineReducers({
  admins,
  apiKeys,
  billingHistory,
  general,
  listingUi,
  plans,
  selectedSubscriptionId,
  users,
});
