import classNames from 'classnames';
import React, { useContext } from 'react';
import { Loader } from '../../../../../../_shared/components/Loader.tsx';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ContentNestingContext } from '../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import {
  ContentComponentCssClass,
  ContentComponentHeaderCssClass,
} from '../../utils/contentComponentRenderingUtils.ts';

export interface IExpandedContentComponentOwnProps {
  readonly additionalClassName?: string;
  readonly renderElements: () => JSX.Element;
}

export interface IExpandedContentComponentStateProps {
  readonly contentType: ICompiledContentType;
}

interface IExpandedContentComponentProps
  extends IExpandedContentComponentOwnProps,
    IExpandedContentComponentStateProps {}

export const ExpandedContentComponent: React.FC<IExpandedContentComponentProps> = ({
  additionalClassName,
  contentType,
  renderElements,
}) => {
  const { isTopLevel } = useContext(ContentNestingContext);

  if (!contentType) {
    return <Loader />;
  }

  return (
    <div
      className={classNames(
        ContentComponentCssClass,
        additionalClassName,
        'content-component--is-not-collapsible',
        'content-component--is-disabled',
        {
          'content-component--is-top-level': isTopLevel,
        },
      )}
    >
      <div
        className={`${ContentComponentHeaderCssClass} ${ContentComponentHeaderCssClass}--is-disabled ${ContentComponentHeaderCssClass}--is-not-collapsible`}
      >
        <div className="content-component__title">
          <p className="content-component__title-text">{contentType?.name}</p>
        </div>
      </div>
      {renderElements()}
    </div>
  );
};

ExpandedContentComponent.displayName = 'ExpandedContentComponent';
