import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { INotificationBarDispatchProps } from '../../../../../../_shared/components/NotificationBar.tsx';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  CannotPublishBecauseOfInaccessibleWarningsInEditing,
  CannotPublishBecauseOfWarningsInEditing,
} from '../../../../constants/errorMessages.ts';
import { ItemElementErrors } from '../../../../utils/elementErrorCheckers/types/Errors.ts';
import { isElementReadyForPublish } from '../../../../utils/getItemElementValidationResult.ts';
import { ItemElementWarnings } from '../../../../utils/itemElementWarningCheckers/types/Warnings.ts';
import { closeIncompleteItemNotificationBar } from '../../actions/contentItemEditingActions.ts';
import {
  IncompleteItemNotificationBar as IncompleteItemNotificationBarComponent,
  IncompleteNotificationBarStateProps,
} from '../../components/contentItemEditorNotifications/IncompleteItemNotificationBar.tsx';
import { isAnyIncompleteElementAccessible } from '../../utils/itemValidationUtils.ts';

const getMessageText = (
  errors: ItemElementErrors,
  warnings: ItemElementWarnings,
  showIncompleteItemWarningsBeforePublish: boolean,
  areSomeIncompleteElementsVisible: boolean,
): string | null => {
  if (!showIncompleteItemWarningsBeforePublish) {
    return null;
  }
  if (!errors.size && Collection.getValues(warnings).every(isElementReadyForPublish)) {
    return null;
  }

  return areSomeIncompleteElementsVisible
    ? CannotPublishBecauseOfWarningsInEditing
    : CannotPublishBecauseOfInaccessibleWarningsInEditing;
};

const mapStateToProps = (state: IStore): IncompleteNotificationBarStateProps => {
  const { itemValidationErrors, itemValidationWarnings, showIncompleteItemWarningsBeforePublish } =
    state.contentApp;

  const anyIncompleteElementAccessible = isAnyIncompleteElementAccessible(state);

  return {
    isAnyIncompleteElementAccessible: anyIncompleteElementAccessible,
    message: getMessageText(
      itemValidationErrors,
      itemValidationWarnings,
      showIncompleteItemWarningsBeforePublish,
      anyIncompleteElementAccessible,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): INotificationBarDispatchProps => ({
  onClosePanel: () => dispatch(closeIncompleteItemNotificationBar()),
});

export const IncompleteItemNotificationBar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncompleteItemNotificationBarComponent);
