import { areShallowEqual } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import {
  trackUserEvent,
  trackUserEventWithData,
} from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IContentEntryLinkedItemExpandedData } from '../../../../../../../_shared/models/TrackUserEventData.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import {
  canDuplicateItem,
  hasActiveVariantCapability,
} from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCannotViewItemMessage } from '../../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { getLinkedContentItemPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { CommentThreadItemType } from '../../../../../models/comments/CommentThreadItem.ts';
import { CommentThreadType } from '../../../../../models/comments/CommentThreads.ts';
import { startNewInlineCommentThread } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  ILinkedItemOwnProps,
  LinkedItem,
} from '../../../components/elements/linkedItems/LinkedItem.tsx';
import {
  getLinkedItemCommentThreads,
  getUnresolvedLinkedItemCommentThreads,
} from '../../../selectors/inlineCommentSelectors.ts';

export interface IElementLinkedItemProps
  extends Omit<ILinkedItemOwnProps, 'onNewComment' | 'commentThreadId'> {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly contentItemId: Uuid;
  readonly contentComponentId: Uuid | null;
  readonly elementId: Uuid;
}

export const ElementLinkedItem: React.FC<IElementLinkedItemProps> = ({
  allowedContentTypes,
  contentItemId,
  contentComponentId,
  elementId,
  ...ownProps
}) => {
  const listingContentItem = useSelector((state) =>
    state.data.listingContentItems.byId.get(contentItemId),
  );
  const isEntryTypeAllowed =
    !listingContentItem ||
    allowedContentTypes.isEmpty() ||
    (!!listingContentItem.item.typeId &&
      allowedContentTypes.some((type: IContentType) => type.id === listingContentItem.item.typeId));

  const location = useLocation();
  const contentItemPath =
    listingContentItem && getLinkedContentItemPath(location.pathname, contentItemId);

  const { firstUnresolvedThreadId, hasFocusedComment } = useSelector((state) => {
    const threads = getLinkedItemCommentThreads(
      state.contentApp.editedContentItemVariantComments.commentThreads,
      contentItemId,
      elementId,
      contentComponentId,
    );
    const unresolvedThreads =
      threads &&
      getUnresolvedLinkedItemCommentThreads(threads, contentItemId, elementId, contentComponentId);
    const focusedCommentThreadId =
      state.contentApp.editedContentItemVariantComments.focusedCommentThreadId;

    return {
      firstUnresolvedThreadId: unresolvedThreads?.[0]?.id ?? null,
      hasFocusedComment:
        !!focusedCommentThreadId &&
        !!threads?.some((thread) => thread.id === focusedCommentThreadId),
    };
  }, areShallowEqual);

  const canCreate =
    !!listingContentItem &&
    hasActiveVariantCapability(ActiveCapabilityType.CreateContent, listingContentItem);
  const canDuplicateInSomeCollection = useSelector(
    (state) =>
      !!listingContentItem &&
      canDuplicateItem(state, getSelectedLanguageIdOrThrow(state), listingContentItem.item.typeId),
  );
  const cannotViewDisabledMessage =
    listingContentItem && getCannotViewItemMessage(listingContentItem);
  const collectionName = useSelector(
    (state) =>
      (listingContentItem &&
        state.data.collections.byId.get(listingContentItem.item.collectionId)?.name) ??
      '',
  );
  const contentType = useSelector(
    (state) =>
      listingContentItem && state.data.contentTypes.byId.get(listingContentItem.item.typeId),
  );
  const currentLanguageName = useSelector(getSelectedLanguageNameOrPlaceholder);

  const isExpandable = !!listingContentItem?.variant && !listingContentItem.variant.isArchived;

  const dispatch = useDispatch();
  const onTrackLinkedItemExpanded = useCallback(
    (eventData: IContentEntryLinkedItemExpandedData) =>
      dispatch(trackUserEventWithData(TrackedEvent.ContentEntryLinkedItemExpanded, eventData)),
    [],
  );
  const onTrackLinkedItemEditOpened = useCallback(
    () => dispatch(trackUserEvent(TrackedEvent.ContentEntryLinkedItemEdited)),
    [],
  );

  const itemName = listingContentItem?.item.name ?? '';
  const onNewComment = useCallback(() => {
    dispatch(
      startNewInlineCommentThread({
        externalSegmentId: contentItemId,
        elementId,
        itemType: CommentThreadItemType.Comment,
        threadType: CommentThreadType.ElementLinkedItem,
        contentComponentId,
        elementSegment: itemName,
      }),
    );
  }, [contentItemId, itemName, elementId, contentComponentId]);

  return (
    <LinkedItem
      canCreate={canCreate}
      canDuplicateInSomeCollection={canDuplicateInSomeCollection}
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      collectionName={collectionName}
      commentThreadId={firstUnresolvedThreadId}
      contentItemId={contentItemId}
      contentItemPath={contentItemPath}
      contentType={contentType}
      currentLanguageName={currentLanguageName}
      hasFocusedComment={hasFocusedComment}
      isEntryTypeAllowed={isEntryTypeAllowed}
      isExpandable={isExpandable}
      loadedContentItem={listingContentItem}
      onNewComment={onNewComment}
      onTrackLinkedItemEditOpened={onTrackLinkedItemEditOpened}
      onTrackLinkedItemExpanded={isExpandable ? onTrackLinkedItemExpanded : null}
      {...ownProps}
    />
  );
};

ElementLinkedItem.displayName = 'ElementLinkedItemContainer';
