import { Collection, areMembersEqual } from '@kontent-ai/utils';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { WorkflowScope } from '../../../../data/models/workflow/Workflow.ts';
import {
  ArchivedStep,
  PublishedStep,
  RegularWorkflowStep,
} from '../../../../data/models/workflow/WorkflowStep.ts';

export const hasWorkflowEditorUnsavedChanges = ({
  data: { workflows },
  workflowsApp: {
    editorUi: {
      archivedWorkflowStep,
      editedWorkflowCodename,
      editedWorkflowId,
      editedWorkflowName,
      editedWorkflowScopesById,
      hasEditedWorkflowStepUnsavedChanges,
      publishedWorkflowStep,
      regularWorkflowSteps,
    },
  },
}: IStore): boolean => {
  if (hasEditedWorkflowStepUnsavedChanges) {
    return true;
  }

  const originalWorkflow = editedWorkflowId && workflows.byId.get(editedWorkflowId);

  if (!originalWorkflow) {
    // Can be a new workflow form or called right after some workflow was deleted
    return false;
  }

  return (
    editedWorkflowName !== originalWorkflow.name ||
    editedWorkflowCodename !== originalWorkflow.codename ||
    hasArchivedWorkflowStepUnsavedChanges(archivedWorkflowStep, originalWorkflow.archivedStep) ||
    hasPublishedWorkflowStepUnsavedChanges(publishedWorkflowStep, originalWorkflow.publishedStep) ||
    haveRegularWorkflowStepsUnsavedChanges(regularWorkflowSteps, originalWorkflow.steps) ||
    haveScopesUnsavedChanges(editedWorkflowScopesById, originalWorkflow.scopes)
  );
};

const hasArchivedWorkflowStepUnsavedChanges = (
  archivedStep: ArchivedStep,
  originalArchivedStep: ArchivedStep,
): boolean => !areMembersEqual(archivedStep.roleIds, originalArchivedStep.roleIds);

const hasPublishedWorkflowStepUnsavedChanges = (
  publishedStep: PublishedStep,
  originalPublishedStep: PublishedStep,
): boolean =>
  !areMembersEqual(publishedStep.roleIdsForCreateNew, originalPublishedStep.roleIdsForCreateNew) ||
  !areMembersEqual(publishedStep.roleIdsForUnpublish, originalPublishedStep.roleIdsForUnpublish);

const haveRegularWorkflowStepsUnsavedChanges = (
  regularSteps: ReadonlyArray<RegularWorkflowStep>,
  originalRegularSteps: ReadonlyArray<RegularWorkflowStep>,
): boolean =>
  regularSteps.length !== originalRegularSteps.length ||
  regularSteps.some((regularStep, index) => {
    const originalRegularStep = originalRegularSteps[index];

    return hasRegularWorkflowStepUnsavedChanges(regularStep, originalRegularStep);
  });

const hasRegularWorkflowStepUnsavedChanges = (
  regularStep: RegularWorkflowStep,
  originalRegularStep: RegularWorkflowStep | undefined,
): boolean =>
  !originalRegularStep ||
  regularStep.name !== originalRegularStep.name ||
  regularStep.color !== originalRegularStep.color ||
  regularStep.codename !== originalRegularStep.codename ||
  !areMembersEqual(regularStep.transitionsTo, originalRegularStep.transitionsTo) ||
  !areMembersEqual(regularStep.roleIds, originalRegularStep.roleIds);

const haveScopesUnsavedChanges = (
  scopes: ReadonlyMap<Uuid, WorkflowScope>,
  originalScopes: ReadonlyArray<WorkflowScope>,
): boolean =>
  scopes.size !== originalScopes.length ||
  Collection.getValues(scopes).some((scope, index) => {
    const originalScope = originalScopes[index];

    return (
      !originalScope ||
      !areMembersEqual(scope.contentTypes, originalScope.contentTypes) ||
      !areMembersEqual(scope.collections, originalScope.collections)
    );
  });
