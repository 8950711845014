import { Box } from '@kontent-ai/component-library/Box';
import { Button, IButtonProps } from '@kontent-ai/component-library/Button';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import { PropsWithChildren, useRef } from 'react';
import { DialogState } from '../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../../../_shared/selectors/getCurrentUser.ts';
import {
  DataUiAction,
  DataUiElement,
  Popovers,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  hasToUsePaidUserSlot,
  isUserCountedInUsage,
} from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { getCurrentProjectSubscription } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { toggleUserActiveState } from '../../actions/thunkUsersActions.ts';
import { createDisabledUserDetailActivationTooltip } from '../../selectors/userModalsMessageSelectors.ts';

type Props = {
  readonly user: IProjectContributor;
};

export const UserDetailActivationButton = ({ user }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const currentUserId = useSelector((s) => getCurrentUser(s).userId);
  const subscriptionId = useSelector((s) => getCurrentProjectSubscription(s).subscriptionId);
  const shouldWarn = useSelector(
    (s) => !isUserCountedInUsage(s, user.userId) && hasToUsePaidUserSlot(s, [user.email]),
  );
  const disabledTooltip = useSelector((s) => createDisabledUserDetailActivationTooltip(s, user));

  const toggle = () =>
    dispatch(toggleUserActiveState(user.userId, user.inactive, subscriptionId, false));
  const targetRef = useRef<HTMLElement>(null);

  const { closeDialog, isOpen, openDialog, PopoverDialog, popoverDialogProps, targetProps } =
    usePopoverDialog({
      dialogState: DialogState.Default,
      headline: 'Activating extra users',
      onClose: () => closeDialog(),
      placement: 'top-start',
      primaryAction: {
        onClick: () => {
          closeDialog();
          toggle();
        },
        text: 'Activate',
        ...getDataUiActionAttribute(DataUiAction.ActivateUser),
      },
      shouldCloseOnInteractOutside: (element) => !targetRef.current?.contains(element),
      targetRef,
    });

  if (user.isActivating) {
    return (
      <Button buttonStyle="primary" disabled>
        <Button.ProgressIcon />
        <Button.Label>Activating</Button.Label>
      </Button>
    );
  }

  if (user.isDeactivating) {
    return (
      <Button buttonStyle="secondary" disabled>
        <Button.ProgressIcon />
        <Button.Label>Deactivating</Button.Label>
      </Button>
    );
  }

  if (user.isAdminOnProject || currentUserId === user.userId) {
    return null;
  }

  const buttonProps: Pick<
    PropsWithChildren<IButtonProps>,
    'buttonStyle' | 'children' | 'destructive' | 'onClick'
  > = user.inactive
    ? {
        buttonStyle: 'primary',
        children: 'Activate',
        onClick: () => {
          if (isOpen) {
            return;
          }

          if (shouldWarn) {
            openDialog();
          } else {
            toggle();
          }
        },
        ...getDataUiActionAttribute(DataUiAction.Activate),
      }
    : {
        buttonStyle: 'secondary',
        children: 'Deactivate',
        destructive: true,
        onClick: toggle,
        ...getDataUiActionAttribute(DataUiAction.Deactivate),
      };

  return (
    <>
      <Button
        disabled={!!disabledTooltip}
        tooltipPlacement="top"
        tooltipText={disabledTooltip}
        {...buttonProps}
        {...targetProps}
      />
      <PopoverDialog
        {...popoverDialogProps}
        {...getDataUiElementAttribute(DataUiElement.Popover)}
        {...getDataUiObjectNameAttribute(Popovers.UserDetailActivationPopover)}
      >
        <Box marginBottom={Spacing.XL} maxWidth={gridUnit * 82}>
          <SubscriptionPlanIncludedUserLimitReachedWarning
            isHeadlineHidden
            isInActivationMode
            newEmails={[user.email]}
          />
        </Box>
      </PopoverDialog>
    </>
  );
};

UserDetailActivationButton.displayName = 'UserDetailActivationButton';
