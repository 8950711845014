import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { useController } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { MissionControlFeedbackFormShape } from './FeedbackForm.tsx';
import { FeedbackInputLabel } from './FeedbackInputLabel.tsx';
import { RatingRadioButton } from './RatingRadioButton.tsx';
import { ValidationError } from './ValidationError.tsx';

type FeedbackRatingProps = Readonly<{
  formProps: HookFormProps<MissionControlFeedbackFormShape>;
}>;

export const FeedbackRating: React.FC<FeedbackRatingProps> = ({ formProps: { control } }) => {
  const { field, fieldState } = useController({ control, name: 'rating' });
  const onChange = (e: string) => {
    field.onChange(e);
  };

  return (
    <Box>
      <FeedbackInputLabel marginBottom={Spacing.S}>
        The information in Mission Control’s widgets, dashboard, and insights shows helpful
        information.
      </FeedbackInputLabel>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <RatingRadioButton
          name="rating"
          value="1"
          description="Strongly disagree"
          onChange={onChange}
          isChecked={field.value === '1'}
        >
          1
        </RatingRadioButton>
        <RatingRadioButton
          name="rating"
          value="2"
          onChange={onChange}
          isChecked={field.value === '2'}
        >
          2
        </RatingRadioButton>
        <RatingRadioButton
          name="rating"
          value="3"
          onChange={onChange}
          isChecked={field.value === '3'}
        >
          3
        </RatingRadioButton>
        <RatingRadioButton
          name="rating"
          value="4"
          onChange={onChange}
          isChecked={field.value === '4'}
        >
          4
        </RatingRadioButton>
        <RatingRadioButton
          name="rating"
          value="5"
          description="Strongly agree"
          onChange={onChange}
          isChecked={field.value === '5'}
        >
          5
        </RatingRadioButton>
      </Box>
      <ValidationError message={fieldState.error?.message} />
    </Box>
  );
};
