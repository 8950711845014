import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ContentTypeSnippet_Creator_CreationFinished,
  ContentTypeSnippet_Editor_SavingFinished,
} from '../../../../applications/contentModels/snippets/constants/snippetActionTypes.ts';
import { ContentTypeSnippetServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import { Data_Snippets_Success } from '../../../constants/dataActionTypes.ts';
import {
  IContentTypeSnippet,
  getContentTypeSnippetFromServerModel,
} from '../../../models/contentModelsApp/snippets/ContentTypeSnippet.ts';

const initialState: Immutable.Map<Uuid, IContentTypeSnippet> = Immutable.Map();

export function byId(
  state: Immutable.Map<Uuid, IContentTypeSnippet> = initialState,
  action: Action,
): Immutable.Map<Uuid, IContentTypeSnippet> {
  switch (action.type) {
    case Data_Snippets_Success: {
      const contentTypeSnippets = action.payload.data.data.reduce(
        (
          reducedMap: Immutable.Map<Uuid, IContentTypeSnippet>,
          item: ContentTypeSnippetServerModel,
        ) => reducedMap.set(item._id, getContentTypeSnippetFromServerModel(item)),
        initialState,
      );
      return contentTypeSnippets;
    }

    case ContentTypeSnippet_Creator_CreationFinished:
      return state.set(
        action.payload.editedContentTypeSnippet.id,
        action.payload.editedContentTypeSnippet,
      );

    case ContentTypeSnippet_Editor_SavingFinished:
      return state.set(action.payload.contentTypeSnippet.id, action.payload.contentTypeSnippet);

    case Shared_CurrentProjectId_Updated:
      return initialState;

    default:
      return state;
  }
}
