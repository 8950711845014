import { Box } from '@kontent-ai/component-library/Box';
import React, { ReactNode } from 'react';

type Props = {
  readonly children: OneOrMore<ReactNode>;
};

/** Lack of way to make multiple cells responsive as a whole instead of individually */
export const ResponsiveCascadeCellsGroup: React.FC<Props> = ({ children }) => (
  <Box display="flex" flexGrow={12.5} minWidth={0} flexBasis={0}>
    {children}
  </Box>
);

ResponsiveCascadeCellsGroup.displayName = 'RowGroup';
