export const NoCapabilitySelectedErrorMessage = 'Select at least one capability.';
export const UntitledRoleSurrogateName = 'Untitled role';
export const UntitledWorkflowSurrogateName = 'Untitled workflow';
export const UntitledDeliveryKeyName = 'Untitled delivery key';
export const UntitledManagementApiKeyName = 'Untitled management API key';
export const UnableToSaveErrorMessage = 'We were unable to save this role. Please try again later.';
export const UnableToDeleteErrorMessage =
  'We were unable to delete this role. Please try again later.';
export const MaxNumberOfRolesExceededMessege =
  'You’ve reached the limit of custom roles for this project.';

export const NoSelectedTypeErrorMessage = 'You have to select at least one content type.';

export const ValidationErrorPanelMessage =
  'Changes couldn’t be saved, please review the errors below (marked red).';
