import { Action } from '../../../../../@types/Action.type.ts';
import {
  ILanguage,
  createLanguage,
  createLanguageFromServerModel,
} from '../../../../../data/models/languages/Language.ts';
import {
  LocalizationEditor_DefaultLangEditor_Saved,
  LocalizationEditor_LanguagesLoading_Success,
} from '../../constants/localizationActionTypes.ts';

const initialState: ILanguage = createLanguage();

export const defaultLanguage = (state: ILanguage = initialState, action: Action): ILanguage => {
  switch (action.type) {
    case LocalizationEditor_LanguagesLoading_Success: {
      const loadedProjectLanguages = action.payload.projectLanguages;
      if (loadedProjectLanguages.defaultLanguage) {
        return createLanguageFromServerModel(loadedProjectLanguages.defaultLanguage);
      }
      return initialState;
    }

    case LocalizationEditor_DefaultLangEditor_Saved: {
      const { formValues } = action.payload;
      return {
        etag: state.etag,
        fallbackLanguageId: state.fallbackLanguageId,
        id: state.id,
        inactive: state.inactive,
        codename: formValues.codename,
        name: formValues.name,
      };
    }

    default:
      return state;
  }
};
