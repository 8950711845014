export const BulkDeleteErrorMessage =
  'There was an error while deleting. If this keeps happening, please let us know.';
export const BulkCancelScheduledPublishErrorMessage =
  'There was an error while canceling scheduled publishing. If this keeps happening, please let us know.';
export const BulkUndoArchiveErrorMessage =
  'There was an error while restoring. If this keeps happening, please let us know.';
export const BulkUndoPublishErrorMessage =
  'There was an error while undoing publishing. If this keeps happening, please let us know.';
export const BulkUndoScheduleErrorMessage =
  'There was an error while undoing schedule publishing. If this keeps happening, please let us know.';
export const CantBulkUpdateFullTextSearchFoundItems =
  'You need to scroll until all content items found by search are loaded in order to bulk update them.';
export const CantCreateOrUseContentTypesTooltipMessage =
  'Ask your project manager to create content types you can use to create content.';
