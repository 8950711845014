import { useState } from 'react';
import { AccessibleLoader } from '../../../../../_shared/components/AccessibleLoader.tsx';
import { DefaultCollectionId } from '../../../../../_shared/constants/variantIdValues.ts';
import { ImageLimitsConfig } from '../../../../../_shared/utils/assets/assetValidationUtils.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AssetLibraryFolders } from '../../containers/AssetFolders/AssetLibraryFolders.tsx';
import { AssetFilter } from '../../containers/AssetListing/AssetFilter.tsx';
import { AssetListingActionPane } from '../../containers/AssetListing/AssetListingActionPane.tsx';
import { AssetQueryObserver } from '../../containers/AssetListing/AssetQueryObserver.tsx';
import { AssetScrollStateObserver } from '../../containers/AssetListing/AssetScrollStateObserver.tsx';
import { AssetSearchPhrase } from '../../containers/AssetListing/AssetSearchPhrase.tsx';
import { AssetsUploadToCollectionDialog } from '../../containers/AssetListing/AssetsUploadToCollectionDialog.tsx';
import { EmptyStateHandler } from '../../containers/EmptyState/EmptyStateHandler.tsx';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { IAssetLibraryQuery } from '../../models/IAssetLibraryQuery.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { AssetFolderNameDialog, AssetFolderState } from '../AssetFolders/AssetFolderNameDialog.tsx';
import { Dropzone } from '../UploadDropzone.tsx';
import { AssetLibraryLayout } from './AssetLibraryLayout.tsx';
import { AssetLibraryTiles } from './AssetLibraryTiles.tsx';
import { UnsupportedAssetFileTypeModal } from './UnsupportedAssetFileTypeModal.tsx';

type Props = {
  readonly allowSelect: boolean;
  readonly areCollectionsVisible: boolean;
  readonly canCreateAssets: boolean;
  readonly clearAssetSelection: () => void;
  readonly createFolder: ((name: string) => void) | null;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly imageLimits?: ImageLimitsConfig;
  readonly isAssetLibraryLoading: boolean;
  readonly isWithAnchor?: boolean;
  readonly onAssetClick?: (assetId: Uuid) => void;
  readonly onOrderingChange: (orderingData: AssetsOrderBy) => void;
  readonly onQueryChange?: (filter: IAssetLibraryQuery) => void;
  readonly onUpload: (files: ReadonlyArray<FileWithThumbnail>, collectionId: string | null) => void;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly showImagesOnly?: boolean;
  readonly showUnsupportedAssetFileTypeModal: boolean;
  readonly theOnlyAvailableCollectionId: Uuid | null;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
};

export const AssetLibrary = ({
  allowSelect,
  areCollectionsVisible,
  canCreateAssets,
  clearAssetSelection,
  createFolder,
  deselectAssets,
  imageLimits,
  isAssetLibraryLoading,
  isWithAnchor,
  onAssetClick,
  onOrderingChange,
  onQueryChange,
  onUpload,
  orderBy,
  selectedAssets,
  showImagesOnly,
  showUnsupportedAssetFileTypeModal,
  theOnlyAvailableCollectionId,
  toggleAssetSelection,
}: Props) => {
  const [assetsToUpload, setAssetsToUpload] = useState<ReadonlyArray<FileWithThumbnail>>([]);

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const openCreateFolderModal = () => {
    setShowCreateFolderModal(true);
  };

  const closeCreateFolderModal = () => {
    setShowCreateFolderModal(false);
  };

  const onCreateFolder = (name: string): void => {
    if (createFolder) {
      createFolder(name);
    }
    closeCreateFolderModal();
  };

  const selectFiles = (files: ReadonlyArray<FileWithThumbnail>): void => {
    if (areCollectionsVisible) {
      if (theOnlyAvailableCollectionId) {
        onUpload(files, theOnlyAvailableCollectionId);
      } else {
        setAssetsToUpload(files);
      }
    } else {
      onUpload(files, DefaultCollectionId);
    }
  };

  return (
    <>
      <Dropzone
        onDrop={selectFiles}
        disabled={!canCreateAssets}
        tagName="div"
        className="dropzone canvas__content"
      >
        <AssetQueryObserver onQueryChange={onQueryChange} orderBy={orderBy} />
        <AssetScrollStateObserver orderBy={orderBy} />
        <div className="asset-listing">
          <AssetLibraryLayout
            renderFilter={() => <AssetFilter />}
            renderContent={() => (
              <div className="asset-library">
                <AssetSearchPhrase />
                {isAssetLibraryLoading ? (
                  <AccessibleLoader screenReaderText="Loading asset library" />
                ) : (
                  <div
                    className="asset-library__asset-content"
                    {...getDataUiCollectionAttribute(DataUiCollection.Assets)}
                  >
                    <AssetListingActionPane
                      clearAssetSelection={clearAssetSelection}
                      deselectAssets={deselectAssets}
                      orderBy={orderBy}
                      selectedAssets={selectedAssets}
                    />
                    <div className="asset-library__sections-pane asset-library__sections-pane--no-top-padding">
                      <EmptyStateHandler
                        onUpload={canCreateAssets ? selectFiles : null}
                        onCreateFolder={createFolder ? openCreateFolderModal : null}
                      >
                        <AssetLibraryFolders
                          clearAssetSelection={clearAssetSelection}
                          orderBy={orderBy}
                        />
                        <AssetLibraryTiles
                          allowSelect={allowSelect}
                          imageLimits={imageLimits}
                          isWithAnchor={isWithAnchor}
                          onAssetClick={onAssetClick}
                          onOrderingChange={onOrderingChange}
                          onUpload={canCreateAssets ? selectFiles : null}
                          selectedAssets={selectedAssets}
                          showImagesOnly={showImagesOnly}
                          orderBy={orderBy}
                          toggleAssetSelection={toggleAssetSelection}
                        />
                      </EmptyStateHandler>
                    </div>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </Dropzone>
      {!!assetsToUpload.length && (
        <AssetsUploadToCollectionDialog
          onClose={() => setAssetsToUpload([])}
          onSelect={(collectionId) => onUpload(assetsToUpload, collectionId)}
        />
      )}
      {showCreateFolderModal && (
        <AssetFolderNameDialog
          onCancel={closeCreateFolderModal}
          onConfirm={onCreateFolder}
          state={AssetFolderState.New}
        />
      )}
      {showUnsupportedAssetFileTypeModal && <UnsupportedAssetFileTypeModal />}
    </>
  );
};
