import { forwardRef } from 'react';
import { useHistory } from 'react-router';
import { modalDismissed } from '../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { useAvailableCollectionsForSelectedLanguage } from '../../../../contentInventory/content/features/ContentItemInventory/selectors/useAvailableCollections.ts';
import { getCollection } from '../../../../contentInventory/content/selectors/getCollection.ts';
import { duplicateEditedContentItem } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { useIsJustCreatedItemClone } from '../../ContentItemEditing/context/IsJustCreatedItemCloneContext.tsx';
import { redirectToItem } from '../../ContentItemEditing/utils/redirectToItem.ts';
import { duplicateItemWithoutContent } from '../actions/thunkDuplicateItemToCollectionActions.ts';
import { DuplicateToCollectionDialog as DuplicateToCollectionDialogComponent } from '../components/DuplicateToCollectionDialog.tsx';

const isDuplicateDialogType = (
  dialogType: ModalDialogType,
): dialogType is
  | ModalDialogType.DuplicateItemWithoutContent
  | ModalDialogType.DuplicateItemWithContent => {
  return [
    ModalDialogType.DuplicateItemWithoutContent,
    ModalDialogType.DuplicateItemWithContent,
  ].includes(dialogType);
};

const useCreateDuplicateAction = (): ((destinationCollectionId?: Uuid) => Promise<void>) | null => {
  const dialogType = useSelector((state) => state.sharedApp.modalDialog.type);
  const dispatch = useDispatch();
  const history = useHistory();

  const { setIsJustCreatedItemClone } = useIsJustCreatedItemClone();

  if (dialogType === ModalDialogType.DuplicateItemWithContent) {
    return async (destinationCollectionId) => {
      const duplicateItem = await dispatch(duplicateEditedContentItem(destinationCollectionId));
      setIsJustCreatedItemClone(true);
      redirectToItem(history, duplicateItem.item.id);
      dispatch(modalDismissed());
    };
  }

  if (dialogType === ModalDialogType.DuplicateItemWithoutContent) {
    return async (destinationCollectionId) => {
      const duplicateItem = await dispatch(
        duplicateItemWithoutContent(
          ContentItemEditingEventOrigins.MoreActions,
          destinationCollectionId,
        ),
      );
      redirectToItem(history, duplicateItem.duplicateItemId);
      dispatch(modalDismissed());
    };
  }

  return null;
};

export const DuplicateToCollectionDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  (props, ref) => {
    const dispatch = useDispatch();

    const isExpectedDialogSet = useSelector((state) => {
      const type = state.sharedApp.modalDialog.type;
      return isDuplicateDialogType(type) ? type : null;
    });

    const editedItemName = useSelector((s) => getEditedContentItem(s).name);
    const collectionName = useSelector((s) => {
      const item = getEditedContentItem(s);
      return getCollection(s, item.collectionId)?.name || '';
    });
    const editedContentItemTypeId = useSelector(
      (s) => getEditedContentItem(s).editedContentItemTypeId,
    );
    const collectionOptions = useAvailableCollectionsForSelectedLanguage(
      Capability.CreateContent,
      editedContentItemTypeId ?? '',
    );

    const onDuplicate = useCreateDuplicateAction();
    const onClose = compose(dispatch, modalDismissed);

    if (!editedContentItemTypeId || !isExpectedDialogSet || !onDuplicate) {
      return null;
    }

    return (
      <DuplicateToCollectionDialogComponent
        collectionName={collectionName}
        collectionOptions={collectionOptions}
        itemName={editedItemName}
        onClose={onClose}
        onDuplicate={onDuplicate}
        ref={ref}
        {...props}
      />
    );
  },
);

DuplicateToCollectionDialog.displayName = 'DuplicateToCollectionDialog';
