import React, { useEffect } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { projectNotificationService } from '../../../../../_shared/services/projectNotificationService.ts';
import { itemLiveUserPresenceMonitorUnmounted } from '../actions/contentItemEditingActions.ts';

export const ItemLiveUserPresenceMonitor: React.FC = () => {
  const dispatch = useDispatch();
  const editedContentItemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);

  useEffect(() => {
    if (!editedContentItemId) {
      return;
    }

    projectNotificationService.onItemEntered(editedContentItemId);
    return () => {
      dispatch(itemLiveUserPresenceMonitorUnmounted());
      projectNotificationService.onItemLeft(editedContentItemId);
    };
  }, [editedContentItemId]);

  return null;
};

ItemLiveUserPresenceMonitor.displayName = 'ItemLiveUserPresenceMonitor';
