import { valueTrend } from '../constants/valueTrends.ts';

export const getValueTrend = (value: number): valueTrend => {
  if (value > 0) {
    return valueTrend.Positive;
  }

  if (value < 0) {
    return valueTrend.Negative;
  }

  return valueTrend.Neutral;
};
