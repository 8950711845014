import { Callout } from '@kontent-ai/component-library/Callout';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ChangeEvent, useState } from 'react';
import { ConfirmationDialog } from '../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { trimAndRemoveMultipleSpaces } from '../../../../_shared/utils/stringUtils.ts';

type Props = {
  readonly onClose: () => void;
  readonly onProjectDelete: () => void;
  readonly projectName: string;
};

export const ProjectDeleteConfirmationDialog = ({
  onProjectDelete,
  projectName,
  onClose,
}: Props) => {
  const [confirmedProjectName, setConfirmedProjectName] = useState('');
  const [isConfirmationValid, setIsConfirmationValid] = useState(false);

  const onConfirmationInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    setIsConfirmationValid(projectName === trimAndRemoveMultipleSpaces(value));
    setConfirmedProjectName(value);
  };

  const deleteProject = (): void => {
    if (isConfirmationValid) {
      onProjectDelete();
    }
  };

  const projectNameRequiresTrim = projectName.length > 45;
  const trimmedProjectName = projectNameRequiresTrim
    ? `${projectName.substr(0, 45)}…`
    : projectName;

  const hotkeysProps = useHotkeys({ enter: deleteProject });

  return (
    <ConfirmationDialog
      alert
      confirmButtonText="Delete project"
      confirmButtonTooltipText={
        isConfirmationValid ? 'Delete project' : `Project name doesn’t match “${projectName}”. `
      }
      headerContent={`Deleting the “${trimmedProjectName}” project`}
      headerTooltip={projectNameRequiresTrim ? `Deleting the “${projectName}” project` : undefined}
      isConfirmButtonDisabled={!isConfirmationValid}
      onClose={onClose}
      onConfirm={deleteProject}
      isOpen
    >
      <Stack spacing={Spacing.XL}>
        <Callout calloutType="warning" headline="This action can’t be undone.">
          <p>What happens when you delete this project?</p>
          <ul>
            <li>The project’s content will be deleted.</li>
            <li>Users will lose access to this project.</li>
            <li>All languages and project settings will be deleted.</li>
            <li>All environments in the project will be deleted.</li>
          </ul>
        </Callout>
        <Input
          label="To confirm you want to delete this project, enter its name"
          autoFocus
          id="project-name"
          inputState={
            !isConfirmationValid && confirmedProjectName !== '' ? InputState.Alert : undefined
          }
          onChange={onConfirmationInputChanged}
          placeholder="Project name"
          value={confirmedProjectName}
          {...hotkeysProps}
          {...getDataUiInputAttribute(DataUiInput.ProjectName)}
        />
      </Stack>
    </ConfirmationDialog>
  );
};
