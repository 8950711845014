import { InvariantException } from '@kontent-ai/errors';
import { IItemListingBulkActionsCheck } from '../../../../../applications/contentInventory/content/models/ItemListingBulkActionStatus.ts';
import {
  getPublishSubmitButtonTooltipMessage,
  getScheduledPublishSubmitButtonTooltipMessage,
  getScheduledUnpublishSubmitButtonTooltipMessage,
  getUpdateStepSubmitButtonTooltipErrorMessage,
} from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/workflowValidationMessages.ts';
import {
  Archive,
  ChangeWorkflowStep,
  Continue,
  Publish,
  PublishAndScheduleUnpublish,
  Restore,
  SaveChanges,
  SchedulePublish,
  SchedulePublishAndUnpublish,
  ScheduleUnpublish,
  Unpublish,
} from '../../../../constants/itemActions.ts';
import {
  DataUiWorkflowAction,
  getDataUiWorkflowActionAttribute,
} from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import { WorkflowSubmitButtonProps } from '../../components/ChangeWorkflowStepModal.tsx';
import { ContinueToCascadeActionTooltip } from '../../constants/uiConstants.ts';
import { IChangeWorkflowStepModalData } from '../../reducers/IChangeWorkflowStepModalData.ts';
import { ChangeWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';

export interface IWorkflowStepModalSubmitActions {
  readonly onMoveToArchivedStep: () => void;
  readonly onPublish: () => void;
  readonly onRestoreFromArchivedStep: () => void;
  readonly onScheduledPublish: () => void;
  readonly onScheduledUnpublish: () => void;
  readonly onUnpublish: () => void;
  readonly onUpdate: () => void;
}

const getPublishButtonText = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
  isCascadeAction: boolean,
) => {
  if (isCascadeAction) {
    return Continue;
  }

  if (!changeWorkflowStepModalData.scheduledToUnpublishAt) {
    return Publish;
  }

  return PublishAndScheduleUnpublish;
};

const getSchedulePublishButtonText = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
  isCascadeAction: boolean,
) => {
  if (isCascadeAction) {
    return Continue;
  }

  if (changeWorkflowStepModalData.isPublishingRescheduled) {
    return SaveChanges;
  }

  if (!changeWorkflowStepModalData.scheduledToUnpublishAt) {
    return SchedulePublish;
  }

  return SchedulePublishAndUnpublish;
};

export const getChangeWorkflowStepModalSubmitProps = (
  changeWorkflowStepModalData: IChangeWorkflowStepModalData,
  isCascadeAction: boolean,
  isSubmitDisabled: boolean,
  hasElementsWithWarnings: boolean,
  bulkActionCheckResult: IItemListingBulkActionsCheck | null,
  submitActions: IWorkflowStepModalSubmitActions,
): WorkflowSubmitButtonProps => {
  switch (changeWorkflowStepModalData.workflowStepAction) {
    case ChangeWorkflowStepModalAction.Publish: {
      const defaultTooltip = isCascadeAction ? ContinueToCascadeActionTooltip : undefined;
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.Publish),
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onPublish,
        text: getPublishButtonText(changeWorkflowStepModalData, isCascadeAction),
        tooltipText: isSubmitDisabled
          ? getPublishSubmitButtonTooltipMessage(
              changeWorkflowStepModalData,
              hasElementsWithWarnings,
              bulkActionCheckResult,
            )
          : defaultTooltip,
      };
    }
    case ChangeWorkflowStepModalAction.ScheduleToPublish: {
      const defaultTooltip = isCascadeAction ? ContinueToCascadeActionTooltip : undefined;
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.ScheduleToPublish),
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onScheduledPublish,
        text: getSchedulePublishButtonText(changeWorkflowStepModalData, isCascadeAction),
        tooltipText: isSubmitDisabled
          ? getScheduledPublishSubmitButtonTooltipMessage(
              changeWorkflowStepModalData,
              hasElementsWithWarnings,
              bulkActionCheckResult,
            )
          : defaultTooltip,
      };
    }
    case ChangeWorkflowStepModalAction.UnpublishAndArchive:
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.Unpublish),
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onUnpublish,
        text: Unpublish,
      };
    case ChangeWorkflowStepModalAction.ScheduleToUnpublishAndArchive: {
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.ScheduleToUnpublish),
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onScheduledUnpublish,
        text: ScheduleUnpublish,
        tooltipText: isSubmitDisabled
          ? getScheduledUnpublishSubmitButtonTooltipMessage(changeWorkflowStepModalData)
          : undefined,
      };
    }
    case ChangeWorkflowStepModalAction.MoveToArchivedStep:
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.Archive),
        destructive: true,
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onMoveToArchivedStep,
        text: Archive,
      };
    case ChangeWorkflowStepModalAction.RestoreFromArchivedStep:
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.UpdateWorkflowStep),
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onRestoreFromArchivedStep,
        text: Restore,
        tooltipText: isSubmitDisabled
          ? getUpdateStepSubmitButtonTooltipErrorMessage(changeWorkflowStepModalData)
          : undefined,
      };
    case ChangeWorkflowStepModalAction.UpdateStep:
      return {
        dataUiAttribute: getDataUiWorkflowActionAttribute(DataUiWorkflowAction.UpdateWorkflowStep),
        disabled: isSubmitDisabled,
        onSubmit: isSubmitDisabled ? undefined : submitActions.onUpdate,
        text: ChangeWorkflowStep,
        tooltipText: isSubmitDisabled
          ? getUpdateStepSubmitButtonTooltipErrorMessage(changeWorkflowStepModalData)
          : undefined,
      };
    default:
      throw InvariantException(
        `${__filename}: Unknown ChangeWorkflowStepModalAction: ${changeWorkflowStepModalData.workflowStepAction}.`,
      );
  }
};
