import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { UsageItemReference, createUsages } from '../../../../_shared/utils/usage/usageUtils.ts';
import { getContentItemVariantReferenceFromServerModel } from '../../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { Data_ListingContentItems_LoadUsages } from '../../../../data/constants/dataActionTypes.ts';
import { ContentItemUsages } from '../IListingContentItems.type.ts';

const initialState: ContentItemUsages = Immutable.Map();

export function contentItemUsages(
  state: ContentItemUsages = initialState,
  action: Action,
): ContentItemUsages {
  switch (action.type) {
    case Data_ListingContentItems_LoadUsages: {
      const { itemId, usages } = action.payload;

      const mergedUsages = usages.linkedParents.concat(usages.modularParents);

      const mappedUsages = createUsages(
        mergedUsages.map(getContentItemVariantReferenceFromServerModel),
      ).reduce(
        (acc: Immutable.Map<Uuid, UsageItemReference>, singleUsage: UsageItemReference) =>
          acc.set(singleUsage.primary.id.itemId, singleUsage),
        Immutable.Map<Uuid, UsageItemReference>(),
      );

      return state.set(itemId, mappedUsages);
    }

    default:
      return state;
  }
}
