import { ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { loadContentTypes } from '../../../../../../../../data/actions/thunkDataActions.ts';

export const ensureContentTypesLoadedForAllContentItems =
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();

    const contentTypeIdsFromContentItems = state.data.listingContentItems.byId
      .toArray()
      .map((contentItem) => contentItem.item.typeId);

    const someContentTypeWasNotLoaded = contentTypeIdsFromContentItems.some(
      (contentTypeId) => !state.data.contentTypes.byId.has(contentTypeId),
    );

    if (someContentTypeWasNotLoaded) {
      await dispatch(loadContentTypes(abortSignal));
    }
  };
