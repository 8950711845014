import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_Assets_ArchiveCompleted,
  AssetLibrary_Assets_ArchiveFailed,
  AssetLibrary_Assets_ArchiveStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IArchiveAssetDependencies {
  readonly assetService: IAssetService;
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const assetsArchiveStarted = () =>
  ({
    type: AssetLibrary_Assets_ArchiveStarted,
  }) as const;

const assetsArchiveCompleted = (successfulIds: ReadonlySet<Uuid>, failedIds: ReadonlySet<Uuid>) =>
  ({
    type: AssetLibrary_Assets_ArchiveCompleted,
    payload: {
      successfulIds,
      failedIds,
    },
  }) as const;

const assetsArchiveFailed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Assets_ArchiveFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type ArchiveAssetsActionsType = ReturnType<
  typeof assetsArchiveStarted | typeof assetsArchiveCompleted | typeof assetsArchiveFailed
>;

type Args = {
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

export const createArchiveAssetsAction =
  (deps: IArchiveAssetDependencies) =>
  ({ orderBy, selectedAssets }: Args): ThunkPromise<ReadonlySet<Uuid>> =>
  async (dispatch: Dispatch): Promise<ReadonlySet<Uuid>> => {
    dispatch(assetsArchiveStarted());

    try {
      const result = await deps.assetService.archiveAssets(selectedAssets);

      const successfulIds = Collection.filter(selectedAssets, (assetId) =>
        result.archivedAssetsIds.includes(assetId),
      );
      const failedIds = new Set(result.usedAssetsIds);

      dispatch(assetsArchiveCompleted(successfulIds, failedIds));
      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.Deleted,
          count: successfulIds.size,
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
          orderBy,
        }),
      );

      return successfulIds;
    } catch (error) {
      dispatch(assetsArchiveFailed('Assets could not be archived.'));
      throw InvariantException(`${__filename}: ${error}`);
    }
  };
