import { isUuid } from '../utils/validation/typeValidators.ts';

export type ItemId = Uuid;
export type VariantId = Uuid;

export type ContentItemId = {
  readonly itemId: ItemId;
  readonly variantId: VariantId;
};

export type ContentItemIdString = `${ItemId}:${VariantId}`;

export type MemoizedContentItemId = ContentItemId & { isMemoized: never };

export const isContentItemId = (input: unknown): input is ContentItemId =>
  !!input &&
  typeof input === 'object' &&
  'itemId' in input &&
  isUuid(input.itemId) &&
  'variantId' in input &&
  isUuid(input.variantId);
