import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { IRoleServerModel } from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import {
  SubscriptionUsers_RoleFilter_LoadRoles_Failed,
  SubscriptionUsers_RoleFilter_LoadRoles_Started,
  SubscriptionUsers_RoleFilter_LoadRoles_Succeeded,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'getProjectRoles'>;
}

const start = () =>
  ({
    type: SubscriptionUsers_RoleFilter_LoadRoles_Started,
  }) as const;

const success = (data: ReadonlyArray<IRoleServerModel>) =>
  ({
    type: SubscriptionUsers_RoleFilter_LoadRoles_Succeeded,
    payload: { data },
  }) as const;

const failure = () =>
  ({
    type: SubscriptionUsers_RoleFilter_LoadRoles_Failed,
  }) as const;

export type LoadEnvironmentRolesActionsType = ReturnType<
  typeof start | typeof success | typeof failure
>;

export const loadEnvironmentRolesCreator =
  ({ roleRepository }: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const rolesServerModel = await roleRepository.getProjectRoles(projectId, abortSignal);
      dispatch(success(rolesServerModel));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
