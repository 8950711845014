import { defaultAssetFileTypeOption } from '../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { EditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  TopLevelBlockCategoryFeature,
  allTableBlockCategoryFeatures,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
} from '../../plugins/apiLimitations/api/editorLimitationUtils.ts';

export const GuidelinesEditorLimitations: EditorLimitations = {
  allowedBlocks: new Set([
    TopLevelBlockCategoryFeature.Text,
    TopLevelBlockCategoryFeature.Tables,
    TopLevelBlockCategoryFeature.Images,
  ]),
  allowedItemLinkTypes: [],
  allowedTextBlocks: allTextBlockTypeFeatures,
  allowedTextFormatting: allTextFormattingFeatures,
  allowedTableBlocks: allTableBlockCategoryFeatures,
  allowedTableTextBlocks: allTextBlockTypeFeatures,
  allowedTableTextFormatting: allTextFormattingFeatures,
  allowedTypes: [],
  fileSize: null,
  fileType: defaultAssetFileTypeOption,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
  maxChars: null,
  maxWords: null,
};
