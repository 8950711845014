import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import { IWebhookSetting, emptyObject } from '../../../../data/models/webhooks/WebhookSetting.ts';
import { Webhooks_CreateEmpty } from '../../constants/webhooksActionTypes.ts';

const webhookCreated = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_CreateEmpty,
    payload: {
      webhook,
    },
  }) as const;

export type CreateNewWebhookSettingActionsType = ReturnType<typeof webhookCreated>;

export const createNewWebhookSettingCreator = () => (): ThunkFunction => (dispatch) => {
  const webhook = emptyObject;

  dispatch(webhookCreated(webhook));
};
