import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { Box } from '../../../layout/Box/Box.tsx';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { borderWidthHighEmphasis } from '../../../tokens/decision/border.ts';
import {
  colorAccent,
  colorBackgroundHover,
  colorTextDefault,
  colorTextDisabled,
  colorToggleText,
  colorToggleTextSelected,
} from '../../../tokens/decision/colors.ts';
import {
  spacingToggleHorizontal,
  spacingToggleVertical,
} from '../../../tokens/decision/spacing.ts';
import { transitionBgHover } from '../../../tokens/decision/transitions.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { Color } from '../../../tokens/quarks/colors.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { RouterLink } from '../../Anchor/Anchor.tsx';
import { VerticalTabsList } from './VerticalTabsList.tsx';

type Props = {
  readonly children?: OneOrMore<React.ReactNode>;
  readonly isActive?: boolean;
  readonly isDisabled?: boolean;
  readonly label: string;
  readonly onClick?: MouseEventHandler<HTMLElement>;
  readonly renderAuxElements?: () => React.ReactNode;
  readonly url: string;
};

const typographyOnActive = Typography.TitleLarge;

interface IVerticalTabsItemStyleProps {
  readonly $isActive?: boolean;
  readonly $isFocusVisible: boolean;
  readonly $isDisabled?: boolean;
}

const StyledVerticalTabsItem = styled(RouterLink).attrs<
  IVerticalTabsItemStyleProps,
  { readonly $defaultColor: Color }
>(({ $isActive, $isDisabled }) => ({
  $defaultColor: $isDisabled
    ? colorTextDisabled
    : $isActive
      ? colorToggleTextSelected
      : colorToggleText,
}))<IVerticalTabsItemStyleProps>`
  position: relative;
  display: flex;
  color: ${({ $defaultColor }) => $defaultColor};

  &:focus {
    color: ${({ $defaultColor }) => $defaultColor};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
    pointer-events: none;
    user-select: none;

    &:hover {
      color: ${colorTextDisabled}
    }
  `};

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
    &:hover {
      color: ${colorTextDefault};
    }
  `};
`;

const StyledTab = styled(Box)<IVerticalTabsItemStyleProps>`
  position: relative;

  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
  ${Typography.LabelLarge}

  transition: background-color ${transitionBgHover};

  ${({ $isActive, $isDisabled }) =>
    !$isDisabled &&
    !$isActive &&
    css`
    ${StyledVerticalTabsItem}:hover && {
      background-color: ${colorBackgroundHover};
    }
  `};

  &:after {
    content: '';
    position: absolute;
    inset: auto ${px(spacingToggleHorizontal)} 0;
    border-bottom: ${px(borderWidthHighEmphasis)} solid transparent;
    transition: border-color ${transition250};

    ${({ $isActive }) => $isActive && css`border-bottom-color: ${colorAccent}`};
  }

  ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
`;

// Adds invisible text after the label with larger font-weight so the width remains same on a change of font-weight (e.g. on active state)
export const StyledLabel = styled.span<{ readonly text?: string }>`

  &::after {
    display: block;
    content: '${({ text }) => text}';
    ${typographyOnActive};
    height: 0;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const VerticalTabsItem = React.forwardRef<HTMLAnchorElement, Props>(
  (
    { children, onClick, label, url, renderAuxElements, isActive, isDisabled, ...otherProps },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    return (
      <>
        <StyledVerticalTabsItem
          $isActive={isActive}
          $isFocusVisible={isFocusVisible}
          $isDisabled={isDisabled}
          ref={forwardedRef}
          to={url}
          onClick={onClick}
          {...otherProps}
          {...focusProps}
        >
          <StyledTab
            $isActive={isActive}
            $isFocusVisible={isFocusVisible}
            $isDisabled={isDisabled}
            borderRadius={BorderRadius.S}
            paddingX={spacingToggleHorizontal}
            paddingY={spacingToggleVertical}
          >
            {isActive && <SrOnly>Currently selected:</SrOnly>}
            <Box paddingRight={renderAuxElements ? Spacing.S : undefined}>
              <StyledLabel text={label}>{label}</StyledLabel>
            </Box>
            {renderAuxElements?.()}
          </StyledTab>
        </StyledVerticalTabsItem>
        {React.Children.count(children) > 0 && (
          <Box paddingLeft={Spacing.M}>
            <VerticalTabsList>{children}</VerticalTabsList>
          </Box>
        )}
      </>
    );
  },
);

VerticalTabsItem.displayName = 'VerticalTabsItem';
