import { InvariantException } from '@kontent-ai/errors';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { canViewContentGroup } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { IContentGroup } from '../../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import { ContentItemEditing_ContentGroup_Selected } from '../../constants/contentItemEditingActionTypes.ts';
import { ContentGroupTabsId } from '../../utils/contentGroupTabsId.ts';
import { showElementsAreInaccessibleNotification } from '../contentItemEditingActions.ts';

export interface ISelectContentGroupParams {
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentGroupId: Uuid | null;
  readonly reason: ContentGroupSelectionReason;
}

interface IContentGroupSelectedPayload extends ISelectContentGroupParams {
  readonly isInEditedContentItem: boolean;
  readonly isChanging: boolean;
}

const contentGroupSelected = (payload: IContentGroupSelectedPayload) =>
  ({
    type: ContentItemEditing_ContentGroup_Selected,
    payload,
  }) as const;

export type SelectContentGroupActionType = ReturnType<typeof contentGroupSelected>;

const isCurrentlySelectedGroup = (
  contentGroupTabsId: ContentGroupTabsId,
  contentGroupId: string | null,
  state: IStore,
) => {
  const {
    contentApp: { editedContentItem, loadedContentItemTypes },
  } = state;

  if (!editedContentItem || contentGroupTabsId !== editedContentItem.id) {
    return false;
  }

  const editedContentItemType = loadedContentItemTypes.get(
    editedContentItem.editedContentItemTypeId,
  );
  const currentGroupId = getSelectedContentGroupIdFromStateOrFirst(
    contentGroupTabsId,
    editedContentItemType?.contentGroups ?? Immutable.List(),
    state,
  );

  return contentGroupId === currentGroupId;
};

export type ISelectContentGroupAction = (payload: ISelectContentGroupParams) => ThunkFunction;

export const createSelectContentGroupAction =
  (): ISelectContentGroupAction =>
  ({ contentGroupTabsId, contentGroupId, reason }) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        editedContentItem,
        editorUi: { activeCapabilities },
        loadedContentItemTypes,
      },
    } = state;

    if (!editedContentItem) {
      throw InvariantException('createRequestContentGroupChangeAction: editedContentItem is falsy');
    }

    const isCurrent = isCurrentlySelectedGroup(contentGroupTabsId, contentGroupId, state);

    const isInEditedContentItem = contentGroupTabsId === editedContentItem.id;
    if (!isInEditedContentItem || !contentGroupId) {
      dispatch(
        contentGroupSelected({
          contentGroupTabsId,
          contentGroupId,
          isInEditedContentItem,
          isChanging: !isCurrent,
          reason,
        }),
      );
      return;
    }

    const getFirstAccessibleGroup = (): Uuid | undefined | null => {
      const itemType = loadedContentItemTypes.get(
        editedContentItem.editedContentItemTypeId,
      ) as ICompiledContentType | null;
      const accessibleContentGroups = activeCapabilities.contentGroupsForViewCapability;

      return itemType?.contentGroups.find((group: IContentGroup) =>
        accessibleContentGroups.includes(group.id),
      )?.id;
    };

    const canViewRequestedGroup = canViewContentGroup(contentGroupId, state.contentApp.editorUi);
    const groupToBeFocused = canViewRequestedGroup ? contentGroupId : getFirstAccessibleGroup();

    if (!groupToBeFocused) {
      throw InvariantException('User does not have permission to any group.');
    }

    if (!canViewRequestedGroup) {
      dispatch(showElementsAreInaccessibleNotification(reason));
    }

    dispatch(
      contentGroupSelected({
        contentGroupTabsId,
        contentGroupId: groupToBeFocused,
        isChanging: !isCurrent,
        isInEditedContentItem,
        reason,
      }),
    );
  };
