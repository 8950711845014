import { Size } from '../../tokens/decision/spacing.ts';

export const AvatarSize = {
  XS: Size.XS,
  S: Size.S,
  M: Size.M,
  L: Size.L,
} as const;

export type AvatarSize = (typeof AvatarSize)[keyof typeof AvatarSize];
