import { InputType } from '@kontent-ai/component-library/Input';
import React from 'react';
import { FormGroup } from '../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import { DataUiInput } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface ISubscriptionAdminInvitationFormShape {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
}

type Props = {
  readonly formProps: HookFormProps<ISubscriptionAdminInvitationFormShape>;
  readonly onSubmit?: () => void;
};

export const SubscriptionAdminInvitationForm: React.FC<Props> = ({ formProps, onSubmit }) => (
  <form className="form" onSubmit={onSubmit}>
    <FormGroup>
      <ValidatedInput
        name="email"
        label="Email"
        type={InputType.Email}
        maxLength={ValidationConstants.UserEmailMaxLength}
        formProps={formProps}
      />
    </FormGroup>

    <FormGroup>
      <ValidatedInput
        name="firstName"
        label="First name"
        maxLength={ValidationConstants.UserFirstNameMaxLength}
        formProps={formProps}
        dataUiInputName={DataUiInput.FirstName}
      />
    </FormGroup>

    <FormGroup>
      <ValidatedInput
        name="lastName"
        label="Last name"
        maxLength={ValidationConstants.UserLastNameMaxLength}
        formProps={formProps}
        dataUiInputName={DataUiInput.LastName}
      />
    </FormGroup>
  </form>
);

SubscriptionAdminInvitationForm.displayName = 'SubscriptionAdminInvitationForm';
