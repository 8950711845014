import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DateTime } from '../../../../../../_shared/models/DateTime.ts';
import { Task, createTaskFromServerModel } from '../../../../../../_shared/models/Task.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { TasksEventActions } from '../../../../../../_shared/models/events/TaskEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { ITaskRepository } from '../../../../../../repositories/interfaces/ITaskRepository.type.ts';
import {
  ContentItemEditing_CreateTask_Failed,
  ContentItemEditing_CreateTask_Finished,
  ContentItemEditing_CreateTask_Started,
} from '../../constants/contentItemEditingActionTypes.ts';

const started = () =>
  ({
    type: ContentItemEditing_CreateTask_Started,
  }) as const;

const failed = () =>
  ({
    type: ContentItemEditing_CreateTask_Failed,
  }) as const;

const finished = (task: Task) =>
  ({
    type: ContentItemEditing_CreateTask_Finished,
    payload: {
      task,
    },
  }) as const;

export type CreateTaskActionsType = ReturnType<typeof failed | typeof finished | typeof started>;

interface IDeps {
  readonly taskRepository: Pick<ITaskRepository, 'createTask'>;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createTaskCreator =
  (deps: IDeps) =>
  (description: string, contributorIds: ReadonlyArray<string>, dueDate: DateTime): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();
    if (!editedContentItemVariant) {
      throw InvariantException('editedContentItemVariant is falsy');
    }

    dispatch(started());

    try {
      const createdTask = await deps.taskRepository.createTask({
        assignees: contributorIds,
        description,
        due: dueDate.isValid ? dueDate.value : null,
        itemVariantId: editedContentItemVariant.id,
      });
      const parsedTask = createTaskFromServerModel(createdTask);

      dispatch(finished(parsedTask));
    } catch (error) {
      logError('Error during creating a task', error);
      dispatch(failed());
    }

    dispatch(
      deps.trackUserEventWithData(TrackedEvent.Tasks, {
        action: TasksEventActions.TaskCreated,
        workflowStepId: editedContentItemVariant.assignment.workflowStatus.id,
        hasDueDate: dueDate.isValid,
      }),
    );
  };
