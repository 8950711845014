import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForAssets } from '../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { hasCapabilityInLanguageForUser } from '../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { Asset, IAsset } from '../../../../data/models/assets/Asset.ts';
import { ILanguage, Languages } from '../../../../data/models/languages/Language.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import { IUser } from '../../../../data/reducers/user/IUser.type.ts';
import { AssetEditor as AssetEditorComponent } from '../components/AssetEditing/AssetEditor.tsx';

export enum EditingLanguages {
  AllLanguages = 'all',
  CurrentLanguage = 'current',
}

type Props = {
  readonly asset: IAsset;
  readonly editingLanguages: EditingLanguages;
  readonly isModalAssetEditor?: boolean;
  readonly onChange: (updatedAsset: IAsset) => void;
};

const isLanguageDescriptionEditable = memoize.maxOne(
  (user: IUser, projectId: Uuid, languages: Languages) =>
    languages
      .map((language: ILanguage) =>
        hasCapabilityInLanguageForUser(user, projectId, Capability.UpdateContent, language.id),
      )
      .toMap(),
);

export const AssetEditor: React.FC<Props> = ({
  asset,
  editingLanguages,
  isModalAssetEditor,
  onChange,
}) => {
  const canUpdateAssets = Asset.hasCapability(asset, ActiveCapabilityType.UpdateAssets);
  const isAssetCollectionVisible = useSelector((s) =>
    areCollectionsVisibleForAssets(s, Collection.getValues(s.data.collections.byId)),
  );

  const allLanguages = useSelector((s) => {
    const {
      sharedApp: { currentProjectId },
      data: { languages, user },
    } = s;

    return getAllActiveLanguagesForCurrentUserInAnyCollection(
      user,
      currentProjectId,
      languages,
      Capability.ViewContent,
    );
  });

  const isLanguageDescriptionEditableMap = useSelector((s) => {
    const {
      sharedApp: { currentProjectId },
      data: { user },
    } = s;

    return isLanguageDescriptionEditable(user, currentProjectId, allLanguages);
  });

  const languagesToShow = useSelector((s): Languages => {
    if (editingLanguages === EditingLanguages.AllLanguages) {
      return allLanguages;
    }

    // item editing allows to edit only current language
    const selectedLanguageId = getSelectedLanguageId(s);
    const selectedLanguage = selectedLanguageId && allLanguages.get(selectedLanguageId);

    return selectedLanguage
      ? Immutable.OrderedMap([[selectedLanguageId, selectedLanguage]])
      : Immutable.OrderedMap();
  });

  return (
    <AssetEditorComponent
      allLanguages={allLanguages}
      asset={asset}
      canUpdateAssets={canUpdateAssets}
      isAssetCollectionVisible={isAssetCollectionVisible}
      isLanguageDescriptionEditableMap={isLanguageDescriptionEditableMap}
      isModalAssetEditor={!!isModalAssetEditor}
      languagesToShow={languagesToShow}
      onChange={onChange}
    />
  );
};

AssetEditor.displayName = 'AssetEditor';
