import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ContentListing_MoveToArchivedStep_Started } from '../../../constants/contentItemInventoryActionTypes.ts';
import { createUpdateWorkflowEventDataForUpdateStep } from '../../../utils/createUpdateWorkflowStepEventData.ts';
import { BulkActionStartedPayload } from '../../contentItemInventoryActions.ts';
import {
  ICreateUpdateWorkflowStepEventData,
  IUpdateWorkflowStep,
} from './updateSelectedVariantsWorkflowStep.ts';

interface IMoveSelectedVariantsToArchivedStepWorkflowActionDependencies {
  readonly contentItemRepository: Pick<IContentItemRepository, 'updateVariantsAssignments'>;
  readonly updateSelectedVariantsWorkflowStep: (
    operationId: Uuid,
    createUpdateWorkflowStepEventData: ICreateUpdateWorkflowStepEventData,
    updateWorkflowStep: IUpdateWorkflowStep,
  ) => ThunkPromise;
}

const moveToArchivedStepStarted = (payload: BulkActionStartedPayload) =>
  ({
    type: ContentListing_MoveToArchivedStep_Started,
    payload: {
      operationId: payload.operationId,
    },
  }) as const;

export type MoveSelectedVariantsToArchivedStepWorkflowActionsType = ReturnType<
  typeof moveToArchivedStepStarted
>;

export const createMoveSelectedVariantsToArchivedStepWorkflowAction =
  (deps: IMoveSelectedVariantsToArchivedStepWorkflowActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const { workflowStep } = getState().contentApp.changeWorkflowStepModalData;

    const operationId = createGuid();

    dispatch(moveToArchivedStepStarted({ operationId }));

    await dispatch(
      deps.updateSelectedVariantsWorkflowStep(
        operationId,
        (modifiedVariantsCount: number) =>
          createUpdateWorkflowEventDataForUpdateStep(workflowStep, modifiedVariantsCount),
        deps.contentItemRepository.updateVariantsAssignments,
      ),
    );
  };
