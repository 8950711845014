import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeEditing_Started,
  Workflow_StepCreator_Cancel,
  Workflow_StepCreator_Initiated,
  Workflow_StepEditor_Cancel,
  Workflow_StepEditor_Initiated,
} from '../../constants/workflowActionTypes.ts';

export const isEditedWorkflowStepNew = (state = false, action: Action): boolean => {
  switch (action.type) {
    case Workflow_StepCreator_Initiated:
      return true;

    case Workflow_InitializeEditing_Started:
    case Workflow_StepCreator_Cancel:
    case Workflow_StepEditor_Cancel:
    case Workflow_StepEditor_Initiated:
    case Workflow_Editing_WorkflowSaveFinished:
      return false;

    default:
      return state;
  }
};
