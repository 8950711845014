export type ScrollTableColumnSize = 1 | 2 | 3 | 3.5 | 4 | 4.5 | 5 | 7 | 10 | 13 | 15;

export const translateColumnSizeToClassSuffix = (size: ScrollTableColumnSize): string => {
  switch (size) {
    case 3.5:
      return '3-and-half';
    case 4.5:
      return '4-and-half';
    default:
      return size.toString();
  }
};
