import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentType_Editor_MultipleChoiceOptionDropped,
  ContentType_Editor_MultipleChoiceOptionPickedUp,
} from '../../constants/contentTypesActionTypes.ts';

const initialState = '';

export function draggedMultipleChoiceOptionId(state = initialState, action: Action): Uuid {
  switch (action.type) {
    case ContentType_Editor_MultipleChoiceOptionPickedUp: {
      return action.payload.optionId;
    }

    case ContentType_Editor_MultipleChoiceOptionDropped: {
      return initialState;
    }

    default:
      return state;
  }
}
