import React, { createContext } from 'react';

export const FloatingEditorContext = createContext<boolean>(false);

export const FloatingEditorContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  // When the context is used, it always gives true
  // We need to pass the true value to the component as a variable otherwise ESlint complains about typing of the value property of the context
  const isInFloatingEditor = true;

  return (
    <FloatingEditorContext.Provider value={isInFloatingEditor}>
      {children}
    </FloatingEditorContext.Provider>
  );
};

FloatingEditorContextProvider.displayName = 'FloatingEditorContextProvider';
