import { useEventListener } from '@kontent-ai/hooks';
import React, { useState } from 'react';

// We use this component to disable mouse interaction with IFRAME to make sure SelectEventPlugin properly tracks mouseDown flag upon DOM selection
// See https://kontent-ai.atlassian.net/browse/KCL-7288 for details
// We apply this globally to all IFRAMEs because there are some we are not controlling directly, e.g. Intercom or browser extensions
export const DisableIframeInteractionOnMouseDown: React.FC = () => {
  const [isLeftButtonDown, setIsLeftButtonDown] = useState(false);
  const updateLeftButtonDown = (e: MouseEvent) => {
    // We update the state on every event, regardless of whether it is up/down by reading the current status of buttons
    // which is a bit array where individual buttons are represented by corresponding bits
    // eslint-disable-next-line no-bitwise
    setIsLeftButtonDown(!!(e.buttons & 1));
  };

  useEventListener('mousedown', updateLeftButtonDown, self, true);
  useEventListener('mouseup', updateLeftButtonDown, self, true);

  // We keep the style tag in DOM even when empty because it's state changes twice upon every single click,
  // and we want to reduce the number of necessary DOM updates
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: isLeftButtonDown ? 'iframe { pointer-events: none }' : '',
      }}
    />
  );
};

DisableIframeInteractionOnMouseDown.displayName = 'DisableIframeInteractionOnMouseDown';
