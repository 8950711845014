import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Data_AdministratedSubscriptions_Failed,
  Data_AdministratedSubscriptions_Success,
} from '../../../constants/dataActionTypes.ts';

const initialState: Immutable.Set<Uuid> = Immutable.Set();

export function administratedIds(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case Data_AdministratedSubscriptions_Success: {
      const { subscriptions } = action.payload.data;
      const serverModels = subscriptions ? subscriptions : [];
      const ids = serverModels.map(({ subscriptionId }) => subscriptionId);
      return Immutable.Set(ids);
    }

    case Data_AdministratedSubscriptions_Failed:
      return initialState;

    default:
      return state;
  }
}
