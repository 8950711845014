import { documentationLinks } from '../../../_shared/constants/documentationLinks.ts';
import { ResourceType } from '../models/ResourceType.ts';

export interface IResourceItem {
  readonly logoClass: string;
  readonly tabName: string;
  readonly sdkName: string;
  readonly installScript: string;
  readonly resourceType: ResourceType;
  readonly sdkResources: ReadonlyArray<IOtherResource>;
  readonly sampleAppResources?: ReadonlyArray<IOtherResource>;
  readonly stepByStepResources?: ReadonlyArray<IOtherResource>;
}

export interface IOtherResource {
  readonly linkUrl: string;
  readonly linkName: string;
  readonly linkId: string;
}

// GitHub links
const jsSdkGitHubLink = 'https://github.com/kontent-ai/delivery-sdk-js';
const netSdkGitHubLink = 'https://github.com/kontent-ai/delivery-sdk-net';
const javaSdkGitHubLink = 'https://github.com/kontent-ai/java-packages';
const rubySdkGitHubLink = 'https://github.com/kontent-ai/delivery-sdk-ruby';
const phpSdkGitHubLink = 'https://github.com/kontent-ai/delivery-sdk-php';

// Sample app links
const javascriptExpressSampleAppLink = 'https://github.com/kontent-ai/sample-app-express-js';
const javascriptReactSampleAppLink = 'https://github.com/kontent-ai/sample-app-react';
const javascriptVueSampleAppLink = 'https://github.com/kontent-ai/sample-app-vue';

const dotnetSampleAppMVCLink = 'https://github.com/kontent-ai/sample-app-net';

const javaGradleConsoleSampleAppLink = `${javaSdkGitHubLink}/tree/master/test-gradle-console-app`;
const javaSpringBootSampleAppLink = `${javaSdkGitHubLink}/tree/master/sample-app-spring-boot`;
const javaAndroidSampleAppLink = `${javaSdkGitHubLink}/tree/master/sample-app-android`;
const kotlinAndroidSampleAppLink = `${javaSdkGitHubLink}/tree/master/sample-app-android-kotlin`;

const phpLaravelSampleAppLink = 'https://github.com/kontent-ai/sample-app-php';

const rubySampleAppLink = 'https://github.com/kontent-ai/sample-app-ruby';

const expressSampleApp: IOtherResource = {
  linkName: 'Express.js',
  linkId: 'express-sample-app',
  linkUrl: javascriptExpressSampleAppLink,
};

const reactSampleApp: IOtherResource = {
  linkName: 'React',
  linkId: 'react-sample-app',
  linkUrl: javascriptReactSampleAppLink,
};

const vueSampleApp: IOtherResource = {
  linkName: 'Vue.js',
  linkId: 'vue-sample-app',
  linkUrl: javascriptVueSampleAppLink,
};

const dotnetMVCSampleApp: IOtherResource = {
  linkName: 'MVC',
  linkId: 'dotnet-sample-app-mvc',
  linkUrl: dotnetSampleAppMVCLink,
};

const razorPagesSampleApp: IOtherResource = {
  linkId: 'dotnet-sample-app-razorpages',
  linkName: 'Razor Pages',
  linkUrl: 'https://github.com/kontent-ai/sample-app-razorpages',
};

const javaGradleConsoleSampleApp: IOtherResource = {
  linkName: 'Gradle',
  linkId: 'gradle-console-sample-app',
  linkUrl: javaGradleConsoleSampleAppLink,
};

const javaSpringBootSampleApp: IOtherResource = {
  linkName: 'Spring Boot',
  linkId: 'spring-boot-sample-app',
  linkUrl: javaSpringBootSampleAppLink,
};

const javaAndroidSampleApp: IOtherResource = {
  linkName: 'Android (Java)',
  linkId: 'android-sample-app',
  linkUrl: javaAndroidSampleAppLink,
};

const kotlinAndroidSampleApp: IOtherResource = {
  linkName: 'Android (Kotlin)',
  linkId: 'android-sample-app-kotlin',
  linkUrl: kotlinAndroidSampleAppLink,
};

const rubySampleApp: IOtherResource = {
  linkName: 'Ruby on Rails',
  linkId: 'ruby-sample-app',
  linkUrl: rubySampleAppLink,
};

const phpLaravelSampleApp: IOtherResource = {
  linkName: 'PHP Laravel',
  linkId: 'php-laravel-sample-app',
  linkUrl: phpLaravelSampleAppLink,
};

//  Step-by-step build app guides
const dotnetStepByStepGuide: IOtherResource = {
  linkName: 'Build your first app',
  linkId: 'step-by-step.net',
  linkUrl: documentationLinks.buildAppNet,
};

const jsStepByStepGuide: IOtherResource = {
  linkName: 'Build your first app',
  linkId: 'step-by-step.js',
  linkUrl: documentationLinks.buildAppJs,
};

const rubyStepByStepGuide: IOtherResource = {
  linkName: 'Build your first app',
  linkId: 'step-by-step.ruby',
  linkUrl: documentationLinks.buildAppRuby,
};

// install scripts
const jsInstallScript = 'npm i @kontent-ai/delivery-sdk --save';

const netInstallScript =
  'Install-Package Kontent.Ai.Delivery\n' + '# or \n' + 'dotnet add package Kontent.Ai.Delivery\n';

const rubyInstallScript = "gem 'kontent-ai-delivery'";

const phpInstallScript = 'composer require kontent-ai/delivery-sdk-php';

const javaInstallScript =
  'repositories {\n' +
  '    mavenCentral()\n' +
  '}\n' +
  '\n' +
  'dependencies {\n' +
  "  implementation 'ai.kontent:delivery-sdk:latest.release'\n" +
  '}';

// SDK resources

const dotNetSdkDocs: IOtherResource = {
  linkName: '.NET SDK',
  linkId: 'sdk-github-.NET',
  linkUrl: netSdkGitHubLink,
};

const jsSdkDocs: IOtherResource = {
  linkName: 'JavaScript SDK',
  linkId: 'sdk-github-JavaScript',
  linkUrl: jsSdkGitHubLink,
};

const rubySdkDocs: IOtherResource = {
  linkName: 'Ruby SDK',
  linkId: 'sdk-github-Ruby',
  linkUrl: rubySdkGitHubLink,
};

const phpSdkDocs: IOtherResource = {
  linkName: 'PHP SDK',
  linkId: 'sdk-github-PHP',
  linkUrl: phpSdkGitHubLink,
};

const javaSdkDocs: IOtherResource = {
  linkName: 'Java SDK',
  linkId: 'sdk-github-Java',
  linkUrl: javaSdkGitHubLink,
};

// SDK Resources collection
const jsSdkResources = [jsSdkDocs];
const dotNetSdkResources = [dotNetSdkDocs];
const javaSdkResources = [javaSdkDocs];
const phpSdkResources = [phpSdkDocs];
const rubySdkResources = [rubySdkDocs];

// Sample app Resources collection
const jsSampleAppResources = [expressSampleApp, reactSampleApp, vueSampleApp];
const dotNetSampleAppResources = [dotnetMVCSampleApp, razorPagesSampleApp];
const javaSampleAppResources = [
  javaGradleConsoleSampleApp,
  javaSpringBootSampleApp,
  javaAndroidSampleApp,
  kotlinAndroidSampleApp,
];
const phpSampleAppResources = [phpLaravelSampleApp];
const rubySampleAppResources = [rubySampleApp];

// Step-by-step resources collection
const jsStepByStepResources = [jsStepByStepGuide];
const dotNetStepByStepResources = [dotnetStepByStepGuide];
const rubyStepByStepResources = [rubyStepByStepGuide];

export const resources: ReadonlyArray<IResourceItem> = [
  {
    logoClass: 'js-logo',
    resourceType: ResourceType.JavaScript,
    tabName: 'Javascript',
    sdkName: 'JavaScript',
    installScript: jsInstallScript,
    sdkResources: jsSdkResources,
    sampleAppResources: jsSampleAppResources,
    stepByStepResources: jsStepByStepResources,
  },
  {
    logoClass: 'dotnet-logo',
    resourceType: ResourceType.DotNet,
    tabName: '.NET',
    sdkName: '.NET',
    installScript: netInstallScript,
    sdkResources: dotNetSdkResources,
    sampleAppResources: dotNetSampleAppResources,
    stepByStepResources: dotNetStepByStepResources,
  },
  {
    logoClass: 'php-logo',
    resourceType: ResourceType.PHP,
    tabName: 'PHP',
    sdkName: 'PHP',
    installScript: phpInstallScript,
    sdkResources: phpSdkResources,
    sampleAppResources: phpSampleAppResources,
  },
  {
    logoClass: 'java-logo',
    resourceType: ResourceType.Java,
    tabName: 'Java',
    sdkName: 'Java',
    installScript: javaInstallScript,
    sdkResources: javaSdkResources,
    sampleAppResources: javaSampleAppResources,
  },
  {
    logoClass: 'ruby-logo',
    resourceType: ResourceType.Ruby,
    tabName: 'Ruby',
    sdkName: 'Ruby',
    installScript: rubyInstallScript,
    sdkResources: rubySdkResources,
    sampleAppResources: rubySampleAppResources,
    stepByStepResources: rubyStepByStepResources,
  },
];
