import { Box } from '@kontent-ai/component-library/Box';
import {
  Color,
  Typography,
  colorPrimary,
  colorTextDefault,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getTimeAndAuthorOfEntityChange } from '../../../../../../_shared/utils/entityChangeUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { SectionTitle } from './SectionTitle.tsx';

type LastUpdatedSectionProps = {
  readonly contentLastUpdatedAt: DateTimeStamp | null;
  readonly contentLastUpdatedBy: string;
  readonly currentUserId: string;
  readonly onClick: () => void;
  readonly projectContributors: ReadonlyMap<Uuid, IProjectContributor>;
  readonly revisionComparisonPath: string | null;
};

interface ILastUpdatedSectionWrapperProps {
  readonly color: Color;
  readonly title: string;
}

const LastUpdatedSectionWrapper: React.FC<
  React.PropsWithChildren<ILastUpdatedSectionWrapperProps>
> = ({ color, children, title }) => {
  const paragraphAttribute: Omit<JSX.IntrinsicElements['p'], 'color' | 'ref'> = {
    title,
  };

  return (
    <Box
      typography={Typography.BodyMedium}
      color={color}
      whiteSpace="nowrap"
      overflow="hidden"
      css="text-overflow: ellipsis"
      component="p"
      {...paragraphAttribute}
    >
      {children}
    </Box>
  );
};

export const LastUpdatedSection: React.FC<LastUpdatedSectionProps> = ({
  contentLastUpdatedAt,
  contentLastUpdatedBy,
  currentUserId,
  projectContributors,
  revisionComparisonPath,
  onClick,
}) => {
  const lastContentChangeString = getTimeAndAuthorOfEntityChange({
    currentUserId,
    projectContributors,
    entityChangedAt: contentLastUpdatedAt,
    entityChangedBy: contentLastUpdatedBy,
  });

  return (
    <div
      className="sidebar__section"
      {...getDataUiObjectNameAttribute(ItemDetailSection.LastUpdated)}
    >
      <SectionTitle>Last content change</SectionTitle>
      <LastUpdatedSectionWrapper
        color={revisionComparisonPath ? colorPrimary : colorTextDefault}
        title={lastContentChangeString}
      >
        {revisionComparisonPath ? (
          <Link
            to={revisionComparisonPath}
            onClick={onClick}
            className="sidebar__section-item-link"
          >
            {lastContentChangeString}
          </Link>
        ) : (
          lastContentChangeString
        )}
      </LastUpdatedSectionWrapper>
    </div>
  );
};

LastUpdatedSection.displayName = 'LastUpdatedSection';
