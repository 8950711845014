import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { SubscriptionNameTile } from './SubscriptionNameTile.tsx';

export const SubscriptionGeneral: React.FC = () => (
  <div
    className={classNames('canvas__content-pane', {
      ...getDataUiAppNameAttribute(DataUiAppName.SubscriptionGeneral),
    })}
  >
    <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.General} />
    <div className="canvas__inner-section--restricted-width">
      <Stack spacing={Spacing.XL}>
        <PageTitle>{SubscriptionTabName.General}</PageTitle>
        <SubscriptionNameTile />
      </Stack>
    </div>
  </div>
);
