import { InvariantException } from '@kontent-ai/errors';
import { TimeInterval } from '../../../unifiedMissionControl/constants/timeIntervals.ts';
import { WorkflowStage } from '../../../unifiedMissionControl/constants/workflowStage.ts';
import {
  ContentPaceRequestModel,
  PublishingStage,
} from '../../../unifiedMissionControl/repositories/serverModels/missionControlRepositoryServerModels.type.ts';

export const getContentPaceRequestModel = (
  timeInterval: TimeInterval,
  workflowStage: WorkflowStage,
  contentTypeId: Uuid | null,
): ContentPaceRequestModel => {
  const model: ContentPaceRequestModel = {
    contentTypeId,
    isFirstVariant: null,
    publishingStage: PublishingStage.All,
    timeInterval,
  };

  switch (workflowStage) {
    case WorkflowStage.AllItems:
      return model;

    case WorkflowStage.NewItems:
      return {
        ...model,
        isFirstVariant: true,
        publishingStage: PublishingStage.BeforeFirstPublish,
      };

    case WorkflowStage.UpdatedItems:
      return {
        ...model,
        isFirstVariant: true,
        publishingStage: PublishingStage.AfterFirstPublish,
      };

    case WorkflowStage.NewLocalizations:
      return {
        ...model,
        isFirstVariant: false,
        publishingStage: PublishingStage.BeforeFirstPublish,
      };

    case WorkflowStage.UpdatedLocalizations:
      return {
        ...model,
        isFirstVariant: false,
        publishingStage: PublishingStage.AfterFirstPublish,
      };

    default:
      throw InvariantException(`This content pace filter is not supported: ${workflowStage}`);
  }
};
