import { connect } from 'react-redux';
import {
  IModalDialogSelectorProps,
  ModalDialogSelector as ModalDialogSelectorComponent,
} from '../../components/ModalDialog/ModalDialogSelector.tsx';
import { IStore } from '../../stores/IStore.type.ts';

const mapStateToProps = (state: IStore): IModalDialogSelectorProps => ({
  currentModal: state.sharedApp.modalDialog.type,
});

export const ModalDialogSelector: React.ComponentType = connect(mapStateToProps)(
  ModalDialogSelectorComponent,
);
