import { usePrevious } from '@kontent-ai/hooks';
import { EditorState, SelectionState } from 'draft-js';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { GetEditorState, Reinit } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin, Init, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { getNewEditorStateForComponent } from '../../editorCore/utils/editorComponentUtils.ts';

type ExternalUpdatePlugin = EditorPlugin;

type ExternalUpdateHandlerProps = {
  readonly getEditorState: GetEditorState;
  readonly lastExternalEditorStateCandidate: EditorState | null;
  readonly lastForcedSelectionRef: React.MutableRefObject<SelectionState | null>;
  readonly reinit: Reinit;
};

const ExternalUpdateHandler: React.FC<ExternalUpdateHandlerProps> = ({
  getEditorState,
  lastExternalEditorStateCandidate,
  lastForcedSelectionRef,
  reinit,
}) => {
  const previousExternalEditorStateCandidate = usePrevious(lastExternalEditorStateCandidate);

  useLayoutEffect(() => {
    if (
      lastExternalEditorStateCandidate &&
      lastExternalEditorStateCandidate !== previousExternalEditorStateCandidate
    ) {
      const newState = getNewEditorStateForComponent(
        getEditorState(),
        lastExternalEditorStateCandidate,
        lastForcedSelectionRef.current,
      );
      if (newState) {
        lastForcedSelectionRef.current = newState.lastForcedSelection;
        reinit(newState.editorState);
      }
    }
  }, [
    lastExternalEditorStateCandidate,
    previousExternalEditorStateCandidate,
    reinit,
    getEditorState,
    lastForcedSelectionRef,
  ]);

  return null;
};

ExternalUpdateHandler.displayName = 'ExternalUpdateHandler';

export const ExternalUpdatePlugin: PluginComponent<ExternalUpdatePlugin> = (props) => {
  const { editorState } = props;

  const lastForcedSelectionRef = useRef<SelectionState | null>(null);

  const lastExternalEditorStateCandidateRef = useRef<EditorState | null>(null);
  if (
    editorState.mustForceSelection() ||
    lastForcedSelectionRef.current !== editorState.getSelection()
  ) {
    lastExternalEditorStateCandidateRef.current = editorState;
  }
  const lastExternalEditorStateCandidate = lastExternalEditorStateCandidateRef.current;

  const render: Decorator<Render<ExternalUpdatePlugin>> = useCallback(
    (baseRender) => (state) => (
      <>
        {baseRender(state)}
        <ExternalUpdateHandler
          getEditorState={state.getEditorState}
          lastExternalEditorStateCandidate={lastExternalEditorStateCandidate}
          lastForcedSelectionRef={lastForcedSelectionRef}
          reinit={state.reinit}
        />
      </>
    ),
    [lastExternalEditorStateCandidate],
  );

  const init: Init = useCallback((state) => {
    if (state.initialEditorState.mustForceSelection()) {
      lastForcedSelectionRef.current = state.initialEditorState.getSelection();
    }

    return {};
  }, []);

  const apply: Apply<ExternalUpdatePlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { init, apply });
};
