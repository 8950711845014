import { add } from 'date-fns';
import { FormatOptionsWithTZ, format, toZonedTime } from 'date-fns-tz';
import { DateReadableFormat } from '../../../../component-library/components/DatetimePicker/datetimeUtils.ts';
import { currentLocale } from '../../components/DatetimePicker/InternalFiles/localeUtils.ts';
import { LocalTimeZoneId } from './timeZoneUtils.ts';

const formatInTimeZone = (
  date: Date,
  formatString: string,
  timezone: string,
  options?: FormatOptionsWithTZ,
) =>
  format(toZonedTime(date, timezone), formatString, {
    timeZone: timezone,
    ...options,
  });

function _printDate(date: Date): string {
  return format(date, 'MMM d, yyyy');
}

function _printDateUtc(date: Date): string {
  return formatInTimeZone(date, 'MMM d, yyyy', 'UTC');
}

function _printTime(date: Date, includeSeconds: boolean = false): string {
  return format(date, includeSeconds ? 'pp' : 'p', { locale: currentLocale });
}

function _printTimeUtc(date: Date, includeSeconds: boolean = false): string {
  return formatInTimeZone(date, includeSeconds ? 'pp' : 'p', 'UTC', { locale: currentLocale });
}

function _printDatetime(date: Date, includeSeconds: boolean = false): string {
  return `${_printDate(date)} at ${_printTime(date, includeSeconds)}`;
}

function _printDatetimeUtc(date: Date, includeSeconds: boolean = false): string {
  return `${_printDateUtc(date)} at ${_printTimeUtc(date, includeSeconds)}`;
}

export function isTimeInPast(now: Date, timeToCheck: DateTimeStamp): boolean {
  const localTimeToCheck = toLocalTime(timeToCheck);
  if (!localTimeToCheck) {
    throw new Error('Error occurred when computing timeToCheck to local time.');
  }
  return now.getTime() - localTimeToCheck.getTime() > 0;
}

export function removeHours(date: Date): Date {
  const newDate = new Date(date.valueOf());
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

const timeScale = [
  {
    multiplier: 60,
    singular: 'minute',
    plural: 'minutes',
  },
  {
    multiplier: 60,
    singular: 'hour',
    plural: 'hours',
  },
  {
    multiplier: 24,
    singular: 'day',
    plural: 'days',
  },
];

export function toFuzzyDateDifference(
  endDate: Date,
  startDate: Date,
  withTime: boolean = false,
): string {
  const startDateTimestamp = startDate.getTime();
  const endDateTimestamp = endDate.getTime();

  // Difference of now and last modified in seconds
  let diff = Math.abs((endDateTimestamp - startDateTimestamp) / 1000);
  const isInFuture = endDateTimestamp < startDateTimestamp;

  // At the current time
  if (diff < 60) {
    return isInFuture ? 'Now' : 'Just now';
  }

  // For more than five days
  if (diff > 5 * 24 * 3600) {
    if (withTime) {
      return _printDatetime(startDate);
    }

    return _printDate(startDate);
  }

  for (let i = 0; i < timeScale.length; i++) {
    const segment = timeScale[i];
    // Diff is always > 1 here
    if (!segment) {
      break;
    }
    diff /= segment.multiplier;

    if (diff < 2) {
      return isInFuture ? `In 1 ${segment.singular}` : `1 ${segment.singular} ago`;
    }

    const nextSegment = timeScale[i + 1];

    if (!nextSegment || diff < nextSegment.multiplier) {
      return isInFuture
        ? `In ${Math.floor(diff)} ${segment.plural}`
        : `${Math.floor(diff)} ${segment.plural} ago`;
    }
  }

  // Unexpected fallback defaults to date / time
  if (withTime) {
    return _printDatetime(startDate);
  }

  return _printDate(startDate);
}

export function getUserFriendlyDateString(date: Date, now: Date): string {
  const publishTimeInMs = date.getTime();
  const oneDayInMs = 24 * 60 * 60 * 1000;
  const startOfTodayInMs = removeHours(now).getTime();
  const endOfTodayInMs = startOfTodayInMs + oneDayInMs;

  const isToday = startOfTodayInMs <= publishTimeInMs && publishTimeInMs < endOfTodayInMs;
  if (isToday) {
    return `Today at ${format(date, 'HH:mm')}`;
  }

  const startOfYesterdayInMs = startOfTodayInMs - oneDayInMs;
  const wasYesterday =
    startOfYesterdayInMs <= publishTimeInMs && publishTimeInMs < startOfTodayInMs;
  if (wasYesterday) {
    return `Yesterday at ${format(date, 'HH:mm')}`;
  }

  const publishedYear = date.getFullYear();
  const currentYear = now.getFullYear();
  if (publishedYear === currentYear) {
    return `${format(date, 'MMM dd')} at ${format(date, 'HH:mm')}`;
  }

  return `${format(date, 'MMM dd, yyyy')} at ${format(date, 'HH:mm')}`;
}

export function toLocalTime(datetimeString: string | null | undefined): Date | null {
  if (!datetimeString) {
    return null;
  }

  const date = toZonedTime(new Date(datetimeString), LocalTimeZoneId);

  return date;
}

export function renderDateString(datetimeString: string | null): string | null {
  const datetime = toLocalTime(datetimeString);
  return datetime ? _printDate(datetime) : null;
}

export function renderDateUtcString(datetimeString: string | null): string | null {
  const datetime = toLocalTime(datetimeString);
  return datetime ? _printDateUtc(datetime) : null;
}

export function renderTimeString(
  datetimeString: string | null,
  includeSeconds: boolean = false,
): string | null {
  const datetime = toLocalTime(datetimeString);
  return datetime ? _printTime(datetime, includeSeconds) : null;
}

export function renderTimeUtcString(
  datetimeString: string | null,
  includeSeconds: boolean = false,
): string | null {
  const datetime = toLocalTime(datetimeString);
  return datetime ? _printTimeUtc(datetime, includeSeconds) : null;
}

export function renderDatetimeString(
  datetimeString: string | null | undefined,
  includeSeconds: boolean = false,
): string | null {
  const datetime = toLocalTime(datetimeString);
  return datetime ? _printDatetime(datetime, includeSeconds) : null;
}

export function renderDatetimeUtcString(
  datetimeString: string | null,
  includeSeconds: boolean = false,
): string | null {
  const datetime = toLocalTime(datetimeString);
  return datetime ? _printDatetimeUtc(datetime, includeSeconds) : null;
}

export const getTimestamp = (dateTimeString: string | null | undefined): number =>
  dateTimeString ? new Date(dateTimeString).getTime() : 0;

export function getTimestampInSeconds(dateTimeString: string): number {
  return Math.floor(getTimestamp(dateTimeString) / 1000.0);
}

export const areTimestampsEqual = (
  dateTimeStringA: string | null | undefined,
  dateTimeStringB: string | null | undefined,
): boolean => getTimestamp(dateTimeStringA) === getTimestamp(dateTimeStringB);

export function isMonthsPeriodPassed(
  monthsToCheck: number,
  to: Date,
  from: DateTimeStamp,
): boolean {
  const startDate = new Date(from);
  const dateAfterMonthsPeriod = new Date(startDate);

  dateAfterMonthsPeriod.setMonth(startDate.getMonth() + monthsToCheck);

  // Because of DST we need to keep same timezones in comparing times
  const timezoneDiff = Math.abs(startDate.getTimezoneOffset() - to.getTimezoneOffset());
  dateAfterMonthsPeriod.setHours(startDate.getHours() - timezoneDiff / 60);

  return to >= dateAfterMonthsPeriod;
}

export function hasWeeksPeriodPassed(weeksToCheck: number, to: Date, from: DateTimeStamp): boolean {
  const startDate = new Date(from);
  const dateAfterWeeksPeriod = new Date(startDate);

  dateAfterWeeksPeriod.setDate(startDate.getDate() + weeksToCheck * 7);

  return to >= dateAfterWeeksPeriod;
}

export function renderShortenedDateUtcString(dateTimeString: string | null): string | null {
  const localDateTime = toLocalTime(dateTimeString);
  if (!localDateTime) {
    return localDateTime;
  }

  const shortenedEndDate = add(localDateTime, { days: -1 });
  return shortenedEndDate ? _printDateUtc(shortenedEndDate) : null;
}

export const getNextFullHourDate = (): Date => {
  const nowMs = Date.now();
  const nowToNextFullHourDiffMs = (60 - new Date(nowMs).getMinutes()) * 60 * 1000;

  return new Date(nowMs + nowToNextFullHourDiffMs);
};

export const getNextFullHourTime = (): string => {
  const defaultTime = getNextFullHourDate();
  const formattedDefaultTime = defaultTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
  return formattedDefaultTime;
};

export const getTodayReadableDate = (): string => {
  return format(new Date(), DateReadableFormat);
};

export const getMilliseconds = (time: {
  readonly hours?: number;
  readonly minutes?: number;
  readonly seconds?: number;
  readonly milliseconds?: number;
}): number => {
  const { hours = 0, minutes = 0, seconds = 0, milliseconds = 0 } = time;

  return ((hours * 60 + minutes) * 60 + seconds) * 1000 + milliseconds;
};
