import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { TimeSpentInWorkflowStepByWorkflow } from '../getTimeSpentInWorkflowStepByWorkflowFromServerModel.ts';

type LoadingState = 'loading' | 'loaded' | 'no-data';

export const getContentPaceLoadingState = (
  workflows: ReadonlyArray<Workflow> | null,
  totalDurationInWorkflowStep: TimeSpentInWorkflowStepByWorkflow | null,
): LoadingState => {
  if (workflows === null || totalDurationInWorkflowStep === null) {
    return 'loading';
  }

  if (!Object.keys(totalDurationInWorkflowStep).length) {
    return 'no-data';
  }

  return 'loaded';
};
