import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { EnvironmentEventType } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { ILoadSubscriptionUsage } from '../../../../subscriptionManagement/shared/types/ILoadSubscriptionUsage.type.ts';
import {
  Environment_DeleteEnvironment_Failed,
  Environment_DeleteEnvironment_Finished,
  Environment_DeleteEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';

interface IDeleteEnvironmentCreatorDependencies {
  readonly loadProjects: () => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadSubscriptionUsage: ILoadSubscriptionUsage;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

const deleteEnvironmentStarted = (environmentName: string, environmentId: Uuid) =>
  ({
    type: Environment_DeleteEnvironment_Started,
    payload: {
      environmentName,
      environmentId,
    },
  }) as const;

const deleteEnvironmentFinished = (environmentName: string, environmentId: Uuid) =>
  ({
    type: Environment_DeleteEnvironment_Finished,
    payload: {
      environmentName,
      environmentId,
    },
  }) as const;

const deleteEnvironmentFailed = (environmentName: string, environmentId: Uuid) =>
  ({
    type: Environment_DeleteEnvironment_Failed,
    payload: {
      environmentName,
      environmentId,
    },
  }) as const;

export type DeleteEnvironmentActionsType = ReturnType<
  | typeof deleteEnvironmentStarted
  | typeof deleteEnvironmentFailed
  | typeof deleteEnvironmentFinished
>;

export const deleteEnvironmentCreator =
  (deps: IDeleteEnvironmentCreatorDependencies) =>
  (environmentId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const environment = getState().data.projects.byId.get(environmentId);
    if (!environment) {
      throw InvariantException(`No environment with id ${environmentId} was found.`);
    }

    dispatch(deleteEnvironmentStarted(environment.environmentName, environmentId));

    try {
      await deps.projectRepository.deleteEnvironment(environmentId);
      await Promise.all([
        dispatch(deps.loadUserProjectsInfo()),
        dispatch(deps.loadProjects()),
        dispatch(deps.loadSubscriptionUsage(environment.subscriptionId)),
      ]);
      dispatch(deleteEnvironmentFinished(environment.environmentName, environmentId));
      dispatch(
        deps.trackUserEventWithData(TrackedEvent.Environment, {
          type: EnvironmentEventType.Delete,
          environmentId,
        }),
      );
    } catch (error) {
      dispatch(deleteEnvironmentFailed(environment.environmentName, environmentId));
      throw InvariantException(
        `Could not delete the environment because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
