import { Dispatch, GetState, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IWebSpotlightRepository } from '../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import {
  WebSpotlight_SetStatus_Failed,
  WebSpotlight_SetStatus_Finished,
  WebSpotlight_SetStatus_Started,
} from '../constants/webSpotlightActionTypes.ts';
import { IWebSpotlightConfiguration } from '../reducers/configuration.ts';

interface IDeps {
  readonly loadContentTypes: () => ThunkPromise;
  readonly webSpotlightRepository: IWebSpotlightRepository;
}

const started = () =>
  ({
    type: WebSpotlight_SetStatus_Started,
  }) as const;

const finished = (configuration: IWebSpotlightConfiguration) =>
  ({
    type: WebSpotlight_SetStatus_Finished,
    payload: {
      configuration,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: WebSpotlight_SetStatus_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type SetWebSpotlightStatusActionTypes = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const setWebSpotlightStatusCreator =
  (deps: IDeps) =>
  (enabled: boolean): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    dispatch(started());
    try {
      const serverResponse = await deps.webSpotlightRepository.setWebSpotlightStatus(
        currentProjectId,
        enabled,
      );

      if (enabled) {
        // Reload content types to load the WSL content types created by enabling the feature
        await dispatch(deps.loadContentTypes());
      }

      dispatch(
        finished({
          isEnabled: serverResponse.enabled,
          rootTypeId: serverResponse.rootTypeId,
        }),
      );
    } catch {
      dispatch(failed('Web Spotlight initialization failed'));
    }
  };
