import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { Project_CopyState_Finished } from '../../../../applications/projects/constants/projectActionTypes.ts';
import {
  Data_UserNormalizedRoleWithSettings_Success,
  Data_UserProjectsInfo_Failed,
  Data_UserProjectsInfo_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IUserProjectInfo,
  createUserProjectInfoDomainModel,
} from '../../../models/user/UserProjectInfo.ts';

export function projectsInfoById(
  state: Immutable.Map<Uuid, IUserProjectInfo> = Immutable.Map(),
  action: Action,
): Immutable.Map<Uuid, IUserProjectInfo> {
  switch (action.type) {
    case Data_UserProjectsInfo_Failed:
      return Immutable.Map();

    case Data_UserProjectsInfo_Success: {
      const { projects } = action.payload.data;
      return (projects || []).reduce(
        (reduced, projectInfo) =>
          reduced.set(projectInfo.projectId, createUserProjectInfoDomainModel(projectInfo)),
        Immutable.Map<Uuid, IUserProjectInfo>(),
      );
    }

    case Data_UserNormalizedRoleWithSettings_Success: {
      const { roles, projectId } = action.payload.data;
      const projectInfo = state.get(projectId);
      if (!projectInfo) {
        return state;
      }

      const updatedProjectInfo: IUserProjectInfo = {
        ...projectInfo,
        capabilities: roles.capabilities,
      };
      return state.set(projectId, updatedProjectInfo);
    }

    case Project_CopyState_Finished: {
      const { projectId } = action.payload.data;
      const projectInfo = state.get(projectId);
      if (!projectInfo) {
        return state;
      }

      const updatedProjectInfo: IUserProjectInfo = {
        ...projectInfo,
        isSetUpCorrectly: true,
      };
      return state.set(projectId, updatedProjectInfo);
    }

    default:
      return state;
  }
}
