import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  ApiKeysSettings_Init_Failed,
  ApiKeysSettings_Init_Finished,
  ApiKeysSettings_Init_Started,
  ApiKeysSettings_Route_Left,
} from '../../constants/apiKeysActionTypes.ts';

export const apiKeysSettingsLoadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case ApiKeysSettings_Init_Started:
      return LoadingStatus.Loading;
    case ApiKeysSettings_Init_Failed:
    case ApiKeysSettings_Init_Finished:
      return LoadingStatus.Loaded;
    case ApiKeysSettings_Route_Left:
      return LoadingStatus.InitialEmpty;
    default:
      return state;
  }
};
