import { IEventTrackingListener, eventTrackingService } from '../services/eventTrackingService.ts';

export const boot = () => {
  if (window.dataLayer && self._envConfig.isGtmEnabled) {
    eventTrackingService.subscribe(trackGtmEvent);
  }
};

export const shutdown = () => {
  if (window.dataLayer && self._envConfig.isGtmEnabled) {
    eventTrackingService.unsubscribe(trackGtmEvent);
  }
};

const trackGtmEvent: IEventTrackingListener = (eventName, metadata) => {
  if (
    window.dataLayer &&
    typeof window.dataLayer.push === 'function' &&
    self._envConfig.isGtmEnabled
  ) {
    window.dataLayer.push({
      event: eventName,
      eventProperties: metadata,
    });
  }
};
