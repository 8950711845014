import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { CloneProjectErrors } from '../../../components/projects/copyProjectErrors/CloneProjectErrors.tsx';
import { getCopyProjectValidationData } from '../../../utils/copyProjectUtils.ts';

type Props = {
  readonly subscriptionId: Uuid;
};

const CloneProjectErrorsContainer: React.FC<Props> = ({ subscriptionId }) => {
  const subscriptionName = useSelector((s) => getSubscription(s, subscriptionId).subscriptionName);

  const validationErrors = useSelector((s) => {
    const { sourceProjectId } = s.projectsApp.cloneProjectInfo;
    const validationData = getCopyProjectValidationData(s, sourceProjectId, subscriptionId);

    return validationData.validationErrors;
  });

  return (
    <CloneProjectErrors
      errorsConfig={validationErrors}
      subscriptionName={subscriptionName}
      subscriptionId={subscriptionId}
    />
  );
};

export { CloneProjectErrorsContainer as CloneProjectErrors };
