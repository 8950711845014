import { InvariantException } from '@kontent-ai/errors';
import { EditorState } from 'draft-js';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import { isUrlSlugTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { getSimpleTextValue } from '../../../../../richText/utils/editorSimpleTextValueUtils.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { IUrlSlugItemElement } from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { createTextElementErrorChecker } from '../../../../utils/elementErrorCheckers/textElementErrorChecker.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getItemElementValueForErrorValidation } from '../../../../utils/getItemElementValueForErrorValidation.ts';
import { emptyItemElementFriendlyWarningResult } from '../../../../utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { getUrlSlugItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/urlSlugItemElementWarningChecker.ts';
import { mapElementErrorResultToItemElementErrorResult } from '../../../../utils/mapElementErrorResultToItemElementErrorResult.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

const errorChecker = createTextElementErrorChecker(ValidationConstants);

export const createUrlSlugElementValueChangedAction =
  () =>
  (elementId: Uuid, newEditorState: EditorState): ThunkFunction =>
  (dispatch, getState) => {
    const { editedContentItem, editedContentItemVariantElements, loadedContentItemTypes } =
      getState().contentApp;

    if (!editedContentItem) {
      throw InvariantException('urlSlugElementValueChanged: "editedContentItem" is not lodaded}');
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    if (!editedContentItemType) {
      throw InvariantException(
        'urlSlugElementValueChanged: "editedContentItemType" is not loaded}',
      );
    }

    let itemElement: IUrlSlugItemElement = getElementByIdOrThrow<IUrlSlugItemElement>(
      elementId,
      editedContentItemVariantElements,
    );

    if (
      itemElement.mode === UrlSlugMode.Auto &&
      getSimpleTextValue(itemElement._editorState) !== getSimpleTextValue(newEditorState)
    ) {
      itemElement = {
        ...itemElement,
        mode: UrlSlugMode.Custom,
      };
    }
    itemElement = {
      ...itemElement,
      _editorState: newEditorState,
    };

    const errorResult = errorChecker({
      value: getItemElementValueForErrorValidation(itemElement),
    });
    const typeElement = editedContentItemType.contentElements.find(
      (elType) => elType.elementId === elementId,
    );
    if (!isUrlSlugTypeElement(typeElement)) {
      return;
    }

    const warningResult = getUrlSlugItemElementValidationWarning({
      typeElement,
      itemElement,
    });

    const validationResult = createValidationResult(
      mapElementErrorResultToItemElementErrorResult(errorResult, typeElement.type),
      warningResult,
      emptyItemElementFriendlyWarningResult,
      elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: itemElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
