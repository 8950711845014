import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { InitialSelectedContentTypeData } from '../actions/thunks/prepareNewContentItemDialog.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { IContentTypeConfig } from '../reducers/INewContentItemStoreState.type.ts';
import { getAvailableContentTypes } from '../utils/getAvailableContentTypes.ts';
import { getContentTypeSelectorState } from '../utils/getContentTypeSelectorState.ts';
import { getPreselectedContentType } from '../utils/getPreselectedContentType.ts';
import { getSuggestedContentTypeIds } from '../utils/getSuggestedContentTypeIds.ts';

export const getInitialSelectedContentTypeData = (
  state: IStore,
  origin: NewContentItemOrigin,
  languageId: Uuid,
  collectionId: Uuid | null,
  contentTypeConfig: IContentTypeConfig,
): InitialSelectedContentTypeData => {
  const availableContentTypes = getAvailableContentTypes(origin, state, languageId, collectionId);

  const suggestedContentTypeIds = getSuggestedContentTypeIds(
    availableContentTypes,
    contentTypeConfig.suggestedContentTypeIds,
    contentTypeConfig.recentlyUsedContentTypeIds,
  );
  const contentTypeSelectorState = getContentTypeSelectorState({
    allowedContentTypesCount: contentTypeConfig.allowedContentTypeIds?.size ?? 0,
    suggestedContentTypesCount: suggestedContentTypeIds.length,
  });

  return getPreselectedContentType(
    availableContentTypes,
    contentTypeConfig.allowedContentTypeIds,
    suggestedContentTypeIds,
    contentTypeSelectorState,
  );
};
