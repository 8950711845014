import { animated } from '@react-spring/web';
import classNames from 'classnames';
import { useRef } from 'react';
import { Route, Switch } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { RedirectIf } from '../../../../../_shared/components/routing/RedirectIf.tsx';
import {
  ContentItemAccessDeniedRouteParams,
  ContentItemPreviewWithEditorAccessDeniedRoute,
  RootRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  IRouteContext,
  RouteContext,
} from '../../../../../_shared/containers/routing/RouteContext.tsx';
import { EditorPaperContextProvider } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { DeferAutoScrollCssClass } from '../../../../../_shared/utils/autoScrollUtils.ts';
import { AccessDeniedToContentItemAction } from '../../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { EditedContentItemContextProvider } from '../../../../itemEditor/features/ContentComponent/context/ContentItemContext.tsx';
import { WaitForEditedItemInitialized } from '../../../../itemEditor/features/ContentItemEditing/containers/WaitForEditedItemInitialized.tsx';
import { FloatingEditorPosition } from '../../../types/floatingEditorPosition.ts';
import { FloatingEditor } from './FloatingEditor.tsx';
import { FloatingEditorAccessDenied } from './FloatingEditorAccessDenied.tsx';
import { FloatingEditorContextProvider } from './FloatingEditorContext.tsx';
import { AnimationState, useFloatingEditorPosition } from './useFloatingEditorPosition.ts';

export const FloatingEditorWrapper = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { animationState, animatedStyle, renderedPosition, togglePosition } =
    useFloatingEditorPosition();

  return (
    <animated.div
      className={classNames('floating-editor', {
        'floating-editor--is-on-left': renderedPosition === FloatingEditorPosition.Left,
        'floating-editor--is-on-right': renderedPosition === FloatingEditorPosition.Right,
        [DeferAutoScrollCssClass]: animationState !== AnimationState.None,
      })}
      style={animatedStyle}
      ref={wrapperRef}
    >
      <Switch>
        <Route path={ContentItemPreviewWithEditorAccessDeniedRoute}>
          <RouteContext>
            {({ match }: IRouteContext<ContentItemAccessDeniedRouteParams<string>>) => (
              <RedirectIf
                redirectTo={RootRoute}
                doRedirect={
                  !Object.values(AccessDeniedToContentItemAction).includes(
                    match.params.requestedAction,
                  )
                }
              >
                <FloatingEditorAccessDenied requestedAction={match.params.requestedAction} />
              </RedirectIf>
            )}
          </RouteContext>
        </Route>
        <Route>
          <FloatingEditorContextProvider>
            <EditedContentItemContextProvider>
              <EditorPaperContextProvider>
                <WaitForEditedItemInitialized
                  renderWhileWaiting={() => (
                    <div className="floating-editor__pane">
                      <Loader />
                    </div>
                  )}
                >
                  <FloatingEditor
                    wrapperRef={wrapperRef}
                    onTogglePosition={togglePosition}
                    position={renderedPosition}
                  />
                </WaitForEditedItemInitialized>
              </EditorPaperContextProvider>
            </EditedContentItemContextProvider>
          </FloatingEditorContextProvider>
        </Route>
      </Switch>
    </animated.div>
  );
};
