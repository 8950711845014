import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { Dispatch, GetState } from '../../../../../@types/Dispatcher.type.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';
import {
  loadItemVariantTimeline,
  loadMoreVariantTimelineItems,
} from '../../Timeline/actions/thunkTimelineActions.ts';

const doesTimelineHaveRevision = (
  timeline: Immutable.List<TimelineItem>,
  revisionItemId: string,
): boolean => timeline.map((i) => i.itemId).contains(revisionItemId);

export const canFetchAnotherTimelineChunk = (getState: GetState): boolean =>
  getState().contentApp.timelineContinuationToken !== null;

export const fetchTimelineUntilRevisionFound = async (
  dispatch: Dispatch,
  getState: GetState,
  contentItemId: string,
  revisionItemId: string,
  abortSignal?: AbortSignal,
) => {
  const { entryTimeline, editedContentItemVariant } = getState().contentApp;

  if (!editedContentItemVariant) {
    throw InvariantException('InitCompareRevisionAction failed, no edited item found.');
  }
  const variantId = editedContentItemVariant.id.variantId;

  let timeline = entryTimeline
    ? entryTimeline
    : await dispatch(loadItemVariantTimeline(contentItemId, variantId, abortSignal));
  if (doesTimelineHaveRevision(timeline, revisionItemId)) {
    return timeline;
  }

  while (canFetchAnotherTimelineChunk(getState)) {
    const nextTimelineChunk = await dispatch(
      loadMoreVariantTimelineItems(contentItemId, variantId, abortSignal),
    );
    timeline = Immutable.List(timeline.concat(nextTimelineChunk));
    if (doesTimelineHaveRevision(nextTimelineChunk, revisionItemId)) {
      break;
    }
  }
  return timeline;
};
