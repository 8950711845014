import { Button } from '@kontent-ai/component-library/Button';
import React, { ReactNode } from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';

type Props = {
  readonly buttonText?: string;
  readonly message: ReactNode;
  readonly onButtonClick?: () => void;
};

export const SavingFailedNotificationBar: React.FC<Props> = ({
  message,
  buttonText,
  onButtonClick,
}) => (
  <NotificationBar
    type={NotificationBarType.Error}
    message={message}
    tools={
      buttonText && (
        <Button size="small" buttonStyle="primary-inverse" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )
    }
  />
);

SavingFailedNotificationBar.displayName = 'SavingFailedNotificationBar';
