import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  InvalidStatusMessage,
  SavedStatusMessage,
  SavingStatusMessage,
  ServerErrorStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
  UploadingAssetStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

export const getItemSavingStatus = (state: IStore): StatusMessage => {
  const {
    itemValidationErrors,
    editedContentItemStatus,
    editedContentItem,
    editorUi: { temporaryItem, editedContentItemVariantUploadingAssets },
  } = state.contentApp;

  if (!editedContentItem) {
    return SavedStatusMessage;
  }

  const isItemTemporary = !!temporaryItem && temporaryItem.itemId === editedContentItem.id;

  if (itemValidationErrors.size) {
    return InvalidStatusMessage;
  }

  if (!Collection.isEmpty(editedContentItemStatus.saving)) {
    return SavingStatusMessage;
  }

  if (editedContentItemVariantUploadingAssets.length > 0) {
    return UploadingAssetStatusMessage;
  }

  if (editedContentItemStatus.failures.size > 0) {
    return ServerErrorStatusMessage;
  }

  if (!isItemTemporary) {
    return SavedStatusMessage;
  }

  return UnsavedStatusMessage;
};

export const ContentItemSavingStatus: React.ComponentType =
  getConnectedStatusMessage(getItemSavingStatus);
