import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { IBaseTypeElementData } from '../../../../../contentModels/shared/models/elements/types/TypeElementData.ts';
import { isGuidelinesTypeElement } from '../../../../../contentModels/shared/types/typeElementTypeGuards.ts';
import { EmptyContentComponents } from '../../../../models/contentItem/ContentComponent.ts';
import { ILoadRichTextReferencesAction } from './loadRichTextReferences.ts';

export type ILoadContentTypeReferencesForItemEditingAction = (
  typeElements: ReadonlyArray<TypeElement | IBaseTypeElementData>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

interface ILoadContentTypeReferencesForItemEditingActionDependencies {
  readonly loadGuidelinesRichTextReferences: ILoadRichTextReferencesAction;
}

export const createLoadContentTypeReferencesForItemEditingAction =
  (
    deps: ILoadContentTypeReferencesForItemEditingActionDependencies,
  ): ILoadContentTypeReferencesForItemEditingAction =>
  (typeElements, abortSignal) =>
  async (dispatch) => {
    const contents = typeElements
      .filter(isGuidelinesTypeElement)
      .map((typeElement) => typeElement._editorState.getCurrentContent());

    await dispatch(
      deps.loadGuidelinesRichTextReferences(contents, EmptyContentComponents, abortSignal),
    );
  };
