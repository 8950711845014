import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SubscriptionUsers_Changed,
} from '../../shared/constants/subscriptionManagementActionTypes.ts';
import {
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_PromoteAdmin_Canceled,
  Subscription_PromoteAdmin_Finished,
} from '../constants/actionTypes.ts';

const initialState = new Set<UserId>();

export const selectedSubscriptionUsers = (
  state: ReadonlySet<UserId> = initialState,
  action: Action,
): ReadonlySet<UserId> => {
  switch (action.type) {
    case Subscription_SubscriptionUsers_Changed:
      return action.payload.userIds;

    case Subscription_AdminListing_DeleteAdminsFinished:
    case Subscription_PromoteAdmin_Canceled:
    case Subscription_SelectSubscription:
    case Subscription_PromoteAdmin_Finished:
      return initialState;

    default:
      return state;
  }
};
