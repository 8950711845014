import { ContentState } from 'draft-js';
import React, { memo, useCallback, useMemo } from 'react';
import { ContentItemElementStatus } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/ContentItemElementStatus.tsx';
import { RegexValidationStatus } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/limitInfoMessages/RegexValidationStatus.tsx';
import { TextLimitStatusPane } from '../../../../../itemEditor/features/ContentItemEditing/components/elements/subComponents/limitInfoMessages/TextLimitStatusPane.tsx';
import { ITextWarningResult } from '../../../../../itemEditor/utils/itemElementWarningCheckers/types/ITextItemElementWarningResult.type.ts';
import { TextLimitations } from '../../../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  getTextLimitStatus,
  getTextSizeTooltip,
} from '../../../../plugins/textApi/api/editorTextUtils.ts';

type TextElementStatusProps = {
  readonly content: ContentState;
  readonly errorMessages: ReadonlyArray<string>;
  readonly hasFocus: boolean;
  readonly limitations: TextLimitations;
  readonly validationResult: ITextWarningResult;
};

export const TextElementStatus: React.FC<TextElementStatusProps> = memo(
  ({ content, errorMessages, hasFocus, limitations, validationResult }) => {
    const hasText = content.hasText();

    const textLimitStatus = useMemo(
      () => getTextLimitStatus(limitations, content),
      [limitations, content],
    );
    const getTooltipText = useCallback(() => getTextSizeTooltip(content), [content]);

    const isRegexValidationMet = !validationResult || validationResult.isRegexValidationMet;
    const isPublishWarningShown = errorMessages.length !== 0;
    const showRegexStatus =
      limitations.validationRegex?.isActive && (!isPublishWarningShown || isRegexValidationMet);
    const isHidden =
      textLimitStatus.isLimitValueMet && isRegexValidationMet && !hasFocus && hasText;
    const hideTextLimitStatus = textLimitStatus.isLimitValueMet && !hasFocus && !hasText;
    const regexValidationMessage = validationResult?.regexValidationMessage ?? '';

    return (
      <ContentItemElementStatus isHidden={isHidden}>
        {showRegexStatus && (
          <span className="content-item-element__status-pane">
            <RegexValidationStatus
              isRegexValidationMet={isRegexValidationMet}
              message={regexValidationMessage}
            />
          </span>
        )}
        <TextLimitStatusPane
          isHidden={hideTextLimitStatus}
          isTooltipEnabled={!isHidden}
          textLimitStatus={textLimitStatus}
          getTooltipText={getTooltipText}
        />
      </ContentItemElementStatus>
    );
  },
);

TextElementStatus.displayName = 'TextElementStatus';
