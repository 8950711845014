import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../../_shared/utils/forwardedRefProps.tsx';

export interface IWebhookMessageDialogSharedProps {
  readonly dataUiAction: DataUiAction;
  readonly isActive: boolean;
  readonly labelText: string;
  readonly onToggle: (event: React.SyntheticEvent<HTMLElement>) => void;
  readonly popover?: JSX.Element | null;
}

interface IWebhookMessageDialogTriggerProps {
  readonly onClick: () => void;
}

type Props = IWebhookMessageDialogSharedProps &
  IWebhookMessageDialogTriggerProps &
  IForwardedRefProps<HTMLDivElement>;

const WebhookMessageDialogTrigger: React.FC<Props> = ({
  dataUiAction,
  forwardedRef,
  isActive,
  labelText,
  onClick,
  onToggle,
  popover,
}): JSX.Element => (
  <div
    ref={forwardedRef}
    className={classNames('webhook-message-listing__action-item', {
      'webhook-message-listing__action-item--is-active': isActive,
    })}
    onClick={(event) => {
      onToggle(event);
      onClick();
    }}
    {...getDataUiActionAttribute(dataUiAction)}
  >
    <Icon iconName={IconName.XmlTag} screenReaderText={labelText} />
    {popover}
  </div>
);

WebhookMessageDialogTrigger.displayName = 'WebhookMessageDialogTrigger';

const WebhookMessageDialogTriggerRFC = forwardRef(WebhookMessageDialogTrigger);
export { WebhookMessageDialogTriggerRFC as WebhookMessageDialogTrigger };
