import useLocalStorageState from 'use-local-storage-state';
import { ContentPaceTimeIntervalStorageKey } from '../../../../_shared/constants/localStorageKeys.ts';
import { TimeInterval } from '../../constants/timeIntervals.ts';

const getStorageKey = (projectId: Uuid, userId: Uuid): string =>
  `${ContentPaceTimeIntervalStorageKey}_${projectId}_${userId}`;

type UseContentPaceTimeIntervalResult = Readonly<{
  timeInterval: TimeInterval;
  setTimeInterval: (value: TimeInterval) => void;
}>;

export const useContentPaceTimeInterval = (
  userId: Uuid,
  projectId: Uuid,
): UseContentPaceTimeIntervalResult => {
  const storageKey = getStorageKey(projectId, userId);

  const [timeInterval, setTimeInterval] = useLocalStorageState<TimeInterval>(storageKey, {
    defaultValue: TimeInterval.PastThirtyDays,
  });

  return {
    timeInterval,
    setTimeInterval,
  };
};
