import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { AssetFileTypeOption } from '../../../applications/contentInventory/content/models/assetFileTypeOptions.ts';
import { ResponsiveImageSupportedTypes } from '../../../applications/itemEditor/constants/responsiveImageSupportedTypes.ts';
import { AssetReference } from '../../../applications/itemEditor/models/contentItemElements/AssetItemElement.ts';
import { AssetLimitations } from '../../../applications/richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { IAssetRendition } from '../../../data/models/assetRenditions/AssetRendition.ts';
import { Asset, IAsset } from '../../../data/models/assets/Asset.ts';

export type AssetValidationData = {
  readonly width: number | null;
  readonly height: number | null;
  readonly fileSize: number;
  readonly fileType: string;
};

export const getAssetDataForValidation = (
  asset: IAsset,
  rendition?: IAssetRendition,
): AssetValidationData => ({
  width: rendition?.imageWidth ?? asset.width,
  height: rendition?.imageHeight ?? asset.height,
  fileSize: rendition?.size ?? asset.fileSize,
  fileType: asset.type,
});

export const getExistingAssignedAssetsWithRenditions = memoize.weak(
  (
    assetReferences: ReadonlyArray<AssetReference>,
    allAssets: Immutable.Map<Uuid, IAsset>,
    assetRenditions: ReadonlyMap<Uuid, IAssetRendition>,
    includeArchived: boolean = true,
  ): ReadonlyArray<AssetValidationData> =>
    assetReferences
      .map((assetReference: AssetReference) => {
        const asset = allAssets.get(assetReference.id);
        if (!asset || !Asset.exists(asset)) {
          return null;
        }
        if (asset.archived && !includeArchived) {
          return null;
        }
        const assetRendition = assetRenditions?.get(assetReference.renditions[0]?.id ?? '');
        return getAssetDataForValidation(asset, assetRendition);
      })
      .filter(notNull),
);

export const validateDimension = (
  min: number | null,
  max: number | null,
  value: number | null,
): boolean => {
  if (!value) {
    return true;
  }

  if (min && max) {
    return value === min && value === max;
  }
  if (min) {
    return value >= min;
  }
  if (max) {
    return value <= max;
  }

  return true;
};

export const validateMaxFileSize = (fileSize: number, fileSizeLimit?: number | null): boolean =>
  !fileSizeLimit || !fileSize || fileSize <= fileSizeLimit;

export const validateFileSize = (
  assetValidationData: AssetValidationData,
  fileSizeLimit: number | null | undefined,
): boolean => validateMaxFileSize(assetValidationData.fileSize, fileSizeLimit);

export const validateWidth = (
  asset: AssetValidationData,
  min: number | null,
  max: number | null,
): boolean => validateDimension(min, max, asset.width);

export const validateHeight = (
  asset: AssetValidationData,
  min: number | null,
  max: number | null,
): boolean => validateDimension(min, max, asset.height);

export const validateWidthForAssets = memoize.weak(
  (assets: ReadonlyArray<AssetValidationData>, min: number | null, max: number | null): boolean => {
    if (!assets || (!min && !max)) {
      return true;
    }
    return assets.every((asset: AssetValidationData) => {
      return validateWidth(asset, min, max);
    });
  },
);

export const validateHeightForAssets = memoize.weak(
  (assets: ReadonlyArray<AssetValidationData>, min: number | null, max: number | null): boolean => {
    if (!assets || (!min && !max)) {
      return true;
    }
    return assets.every((asset: AssetValidationData) => {
      return validateHeight(asset, min, max);
    });
  },
);

export const validateFileSizeForAssets = memoize.weak(
  (assets: ReadonlyArray<AssetValidationData>, fileSizeLimit: number | null): boolean => {
    if (!assets || !fileSizeLimit) {
      return true;
    }
    return assets.every((asset: AssetValidationData) => {
      return validateFileSize(asset, fileSizeLimit);
    });
  },
);

export const isResponsiveImage = (type: string): boolean =>
  ResponsiveImageSupportedTypes.contains(type);

export const isEveryImageResponsive = memoize.weak(
  (assets: ReadonlyArray<AssetValidationData>): boolean => {
    if (!assets) {
      return true;
    }
    return assets.every((asset: AssetValidationData) => isResponsiveImage(asset.fileType));
  },
);

export type ImageLimitsConfig = {
  onlyResponsiveImages: boolean;
  maxWidth: number | null;
  minWidth: number | null;
  maxHeight: number | null;
  minHeight: number | null;
  maxFileSize: number | null;
};

export const getImageLimitsConfig = memoize.weak(
  (limitations: AssetLimitations): ImageLimitsConfig => ({
    onlyResponsiveImages: limitations.fileType === AssetFileTypeOption.ResponsiveImages,
    maxWidth: limitations.maxWidth,
    minWidth: limitations.minWidth,
    maxHeight: limitations.maxHeight,
    minHeight: limitations.minHeight,
    maxFileSize: limitations.fileSize,
  }),
);

export const isAssetLimited = (limitations: AssetLimitations): boolean => {
  const minWidth = limitations.minWidth;
  const maxWidth = limitations.maxWidth;
  const minHeight = limitations.minHeight;
  const maxHeight = limitations.maxHeight;
  const allowResponsiveImagesOnly = limitations.fileType === AssetFileTypeOption.ResponsiveImages;
  const fileSize = limitations.fileSize;

  return (
    !!minWidth ||
    !!maxWidth ||
    !!minHeight ||
    !!maxHeight ||
    allowResponsiveImagesOnly ||
    !!fileSize
  );
};
