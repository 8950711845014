import { Box } from '@kontent-ai/component-library/Box';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import React, { ReactNode, forwardRef } from 'react';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith } from '../../../../applications/itemEditor/selectors/getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith.ts';
import { getNotEmptyItemName } from '../../../../applications/itemEditor/stores/utils/contentItemHelperMethods.ts';
import { useSelector } from '../../../hooks/useSelector.ts';
import { RestoreConfirmation } from '../../../types/RestoreConfirmation.type.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../utils/dateTime/timeUtils.ts';

type Props = {
  readonly comparingVersions: boolean;
  readonly confirmation: RestoreConfirmation;
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
};

export const RestoreRevisionConfirmationDialog = forwardRef<HTMLDivElement, Props>(
  ({ comparingVersions, confirmation, isOpen, onCancel, onConfirm }, ref) => {
    const { body, headline, primaryActionText } = createDialogContents(
      comparingVersions,
      confirmation,
    );

    return (
      <ModalDialog
        headline={headline}
        isDismissable
        isOpen={isOpen}
        onClose={onCancel}
        primaryAction={{
          onClick: onConfirm,
          text: primaryActionText,
          ...getDataUiActionAttribute(DataUiAction.Confirm),
        }}
        ref={ref}
        {...getDataUiElementAttribute(DataUiElement.Dialog)}
      >
        <Box maxWidth={70 * gridUnit}>{body}</Box>
      </ModalDialog>
    );
  },
);

RestoreRevisionConfirmationDialog.displayName = 'RestoreRevisionConfirmationDialog';

type DialogContent = {
  body: ReactNode;
  headline: string;
  primaryActionText: string;
};

const createDialogContents = (
  comparingVersions: boolean,
  confirmation: RestoreConfirmation,
): DialogContent => {
  switch (confirmation) {
    case RestoreConfirmation.ConfirmationInPublish: {
      return {
        body: <ConfirmationDialogBodyInPublished comparingVersions={comparingVersions} />,
        headline: `Restore ${comparingVersions ? 'the older' : 'this'} version?`,
        primaryActionText: `Restore ${comparingVersions ? 'older ' : ''}version`,
      };
    }
    case RestoreConfirmation.ConfirmationInArchived: {
      return {
        body: <ConfirmationDialogBodyInArchived comparingVersions={comparingVersions} />,
        headline: `Restore ${comparingVersions ? 'item from the older' : 'this'} version?`,
        primaryActionText: 'Restore item',
      };
    }
    default: {
      return {
        body: null,
        headline: `Restore ${comparingVersions ? 'the older' : 'this'} version?`,
        primaryActionText: 'Restore',
      };
    }
  }
};

const ConfirmationDialogBodyInPublished: React.FC<{ readonly comparingVersions: boolean }> = ({
  comparingVersions,
}) => {
  const itemName = useSelector((s) => s.contentApp.editedContentItem?.name || '');
  const publishedAt = useSelector((s) => s.contentApp.editedContentItemVariant?.lastPublishedAt);
  const firstStepName = useSelector(getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith);

  const version = comparingVersions ? (
    <>
      selected <strong>older version</strong>
    </>
  ) : (
    <strong>selected version</strong>
  );

  return (
    <>
      <p>
        {getNotEmptyItemName(itemName)} was last published on {renderDatetimeString(publishedAt)}.
      </p>
      <p>
        Do you want to <strong>create a new version</strong> of this content item in the{' '}
        {firstStepName} step <strong>and restore</strong> content from the {version}?
      </p>
      <p>
        The <strong>published version</strong> of this item <strong>will remain intact</strong>.
      </p>
    </>
  );
};

const ConfirmationDialogBodyInArchived: React.FC<{ readonly comparingVersions: boolean }> = ({
  comparingVersions,
}) => {
  const itemName = useSelector((s) => s.contentApp.editedContentItem?.name || '');
  const firstStepName = useSelector(getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith);

  return (
    <>
      <p>{getNotEmptyItemName(itemName)} is archived.</p>
      <p>
        Do you want to <strong>restore this content item</strong> to the {firstStepName} step{' '}
        <strong>using content from the selected {comparingVersions ? 'older' : ''} version?</strong>
      </p>
    </>
  );
};
