import {
  BaseColor,
  BorderRadius,
  colorBorderDefault,
  colorBorderHover,
  px,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { WebSpotlightZIndex } from '../../../constants/WebSpotlightZIndex.ts';

const ResizeButton = styled.button<{ readonly isHovered: boolean }>`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translateX(-50%) translateY(-50%);
  padding: 0;
  border: none;
  border-radius: ${px(BorderRadius.L)};
  cursor: nwse-resize;
  user-select: none;

  ${(props) =>
    props.isHovered
      ? `
    background-color: ${colorBorderHover};
    color: ${BaseColor.Gray20};
    z-index: ${WebSpotlightZIndex.PreviewIFrame};
  `
      : `
    background-color: ${colorBorderDefault};
    color: ${BaseColor.Gray80};
    z-index: ${WebSpotlightZIndex.InactiveResizeHandle};
  `};
`;

interface IWebSpotlightPreviewResizeElementProps {
  readonly isHovered: boolean;
  readonly onResizeStart: (event: React.MouseEvent<HTMLElement>) => void;
}

export const WebSpotlightPreviewDiagonalResizeButton: React.FC<
  IWebSpotlightPreviewResizeElementProps
> = ({ isHovered, onResizeStart, ...otherProps }) => (
  <ResizeButton isHovered={isHovered} onMouseDown={onResizeStart} {...otherProps}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isHovered ? '10' : '19'}
      height={isHovered ? '10' : '19'}
      viewBox={isHovered ? '0 0 10 10' : '0 0 19 19'}
      fill="none"
    >
      <line
        y1="-0.5"
        x2="8"
        y2="-0.5"
        stroke="currentcolor"
        transform={
          isHovered
            ? 'matrix(0.707107 -0.707107 0.675996 0.736906 3.72363 9.0332)'
            : 'matrix(0.707107 -0.707107 0.675996 0.736906 10.436 15.4355)'
        }
      />
      <line
        y1="-0.5"
        x2="8"
        y2="-0.5"
        stroke="currentcolor"
        transform={
          isHovered
            ? 'matrix(0.707107 -0.707107 0.675996 0.736906 1.25488 6.70117)'
            : 'matrix(0.707107 -0.707107 0.675996 0.736906 8.60791 13.6562)'
        }
      />
    </svg>
  </ResizeButton>
);

WebSpotlightPreviewDiagonalResizeButton.displayName = 'WebSpotlightPreviewDiagonalResizeButton';
