import React from 'react';
import { useLocation } from 'react-router';
import { trackUserEventWithData } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { YourContentTabName } from '../../../../_shared/constants/yourContentTabName.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../data/reducers/languages/selectors/getLanguages.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { AssignedContentItemsOrderBy } from '../../../itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';
import { orderAssignedContentItems } from '../../actions/thunkYourContentActions.ts';
import { ContentAssignedToYou as ContentAssignedToYouComponent } from '../../components/AssignedToYou/ContentAssignedToYou.tsx';
import { decorateItemsAssignedToYou } from '../../utils/yourContentItemDecorationUtils.ts';

export const ContentAssignedToYou: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const onColumnHeadClick = (orderBy: OrderBy<AssignedContentItemsOrderBy>) =>
    dispatch(orderAssignedContentItems(orderBy));
  const onItemClick = (itemId: Uuid, index: number) =>
    dispatch(
      trackUserEventWithData(TrackedEvent.FindRightContent, {
        index,
        itemId,
        name: FindRightContentTrackedEvent.OpenedItemEditingFromYourContent,
        tab: YourContentTabName.AssignedToYou,
      }),
    );

  const viewableLanguages = useSelector((s) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    ),
  );
  const orderBy = useSelector((s) => s.contentApp.listingUi.yourContentUi.assignedItemsOrdering);
  const contentItems = useSelector((s) => s.data.assignedContentItems.items);
  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const languages = useSelector((s) => s.data.languages.byId);
  const loadingFinished = useSelector(
    (s) =>
      s.data.contentTypes.loadingStatus === LoadingStatus.Loaded &&
      s.data.assignedContentItems.loadingStatus === LoadingStatus.Loaded &&
      s.data.languages.byIdLoadingStatus === LoadingStatus.Loaded,
  );

  const decoratedItems = loadingFinished
    ? decorateItemsAssignedToYou(contentItems, languages, viewableLanguages, contentTypes)
    : contentItems.toList();

  return (
    <ContentAssignedToYouComponent
      loadingFinished={loadingFinished}
      onColumnHeadClick={onColumnHeadClick}
      contentItems={decoratedItems}
      orderBy={orderBy}
      currentPath={location.pathname}
      onItemClick={onItemClick}
    />
  );
};

ContentAssignedToYou.displayName = 'ContentAssignedToYou';
