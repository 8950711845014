import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loaded,
  ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loading,
  ContentModels_TypeEditor_LinkedItemsDefaultValueReferences_Added,
} from '../../constants/sharedContentModelsActionTypes.ts';
import { IDefaultValueLinkedItem } from '../IContentModelsAppStoreState.type.ts';

const initialState: ReadonlyArray<IDefaultValueLinkedItem> = [];

export function linkedItemsDefaultValueReferences(
  state = initialState,
  action: Action,
): ReadonlyArray<IDefaultValueLinkedItem> {
  switch (action.type) {
    case ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loaded:
      return action.payload.defaultValueReferences;

    case ContentModels_TypeEditor_LinkedItemsDefaultValueReferences_Added:
      return action.payload.defaultValueReferences.concat(state);

    case ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loading:
      return initialState;

    default:
      return state;
  }
}
