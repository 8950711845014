import { Button } from '@kontent-ai/component-library/Button';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface Props {
  readonly isSubmitting: boolean;
  readonly isSubmitButtonDisabled: boolean;
  readonly onCancelEditing: () => void;
}

export const NewCommentThreadActionsBar = (props: Props) => {
  return (
    <div className="comment-editor__actions">
      <Button
        tooltipText={props.isSubmitting ? undefined : 'Cancel'}
        tooltipPlacement="bottom-end"
        tooltipShortcuts={ShortcutSymbols.Escape}
        buttonStyle="secondary"
        size="small"
        onClick={props.isSubmitting ? undefined : props.onCancelEditing}
        disabled={props.isSubmitting}
        {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
      >
        Cancel
      </Button>
      <Button
        tooltipText={!props.isSubmitting && !props.isSubmitButtonDisabled ? 'Comment' : undefined}
        tooltipPlacement="bottom-end"
        tooltipShortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
        buttonStyle="primary"
        size="small"
        type="submit"
        disabled={props.isSubmitButtonDisabled}
        {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
      >
        {props.isSubmitting ? 'Commenting…' : 'Comment'}
      </Button>
    </div>
  );
};
