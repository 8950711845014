import { mapObjectValues, notNullNorUndefined, removeUndefinedProps } from '@kontent-ai/utils';
import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js';
import { EntityMutability } from '../../plugins/entityApi/api/Entity.ts';
import { BlockType } from '../blocks/blockType.ts';

export type RichTextServerModel = DeepPartial<RawDraftContentState>;

const isValidInlineStyleRange = (
  range: Partial<RawDraftInlineStyleRange> | undefined,
): range is RawDraftInlineStyleRange =>
  !!range?.style && !!range.length && notNullNorUndefined(range.offset);

const isValidEntityRange = (
  range: Partial<RawDraftEntityRange> | undefined,
): range is RawDraftEntityRange =>
  notNullNorUndefined(range?.key) && !!range.length && notNullNorUndefined(range.offset);

const omitDefaultBlockValues = (block: RawDraftContentBlock): DeepPartial<RawDraftContentBlock> => {
  const { data, depth, entityRanges, inlineStyleRanges, text, type, ...mandatoryValues } = block;

  return {
    ...mandatoryValues,
    ...(data && Object.keys(data).length ? { data } : undefined),
    ...(depth ? { depth } : undefined),
    ...(entityRanges.length ? { entityRanges } : undefined),
    ...(inlineStyleRanges.length ? { inlineStyleRanges } : undefined),
    ...(text ? { text } : undefined),
    ...(type !== BlockType.Unstyled ? { type } : undefined),
  };
};

const ensureDefaultBlockValues = (
  block: DeepPartial<RawDraftContentBlock>,
): RawDraftContentBlock | null =>
  block?.key
    ? {
        ...(block.data ? { data: block.data } : undefined),
        depth: block.depth ?? 0,
        entityRanges: block.entityRanges?.filter(isValidEntityRange) ?? [],
        inlineStyleRanges: block.inlineStyleRanges?.filter(isValidInlineStyleRange) ?? [],
        key: block.key,
        text: block.text ?? '',
        type: block.type ?? BlockType.Unstyled,
      }
    : null;

const omitDefaultEntityValues = (entity: RawDraftEntity): DeepPartial<RawDraftEntity> => {
  const { data, mutability, ...mandatoryValues } = entity;

  return {
    ...mandatoryValues,
    ...(Object.keys(data).length ? { data } : undefined),
    ...(mutability !== EntityMutability.Mutable ? { mutability } : undefined),
  };
};

const ensureDefaultEntityValues = (
  entity: DeepPartial<RawDraftEntity> | undefined,
): RawDraftEntity | undefined =>
  entity?.type
    ? {
        type: entity.type,
        data: entity.data ?? {},
        mutability: entity.mutability ?? EntityMutability.Mutable,
      }
    : undefined;

// These should be kept in sync with the EditorStateModel serialization at BE, as the jsonValue of elements may originate both from FE and from the BE
export const convertRawContentToServerModel = (
  content: RawDraftContentState,
): RichTextServerModel => ({
  blocks: content.blocks.map(omitDefaultBlockValues),
  ...(Object.keys(content.entityMap).length
    ? {
        entityMap: mapObjectValues(content.entityMap, omitDefaultEntityValues),
      }
    : undefined),
});

export const convertServerModelToRawContent = (
  partialRawContentState: RichTextServerModel,
): RawDraftContentState => ({
  blocks:
    partialRawContentState.blocks?.map(ensureDefaultBlockValues).filter(notNullNorUndefined) ?? [],
  entityMap: removeUndefinedProps(
    mapObjectValues(partialRawContentState.entityMap ?? {}, ensureDefaultEntityValues),
  ),
});
