import { Action } from '../../../../../@types/Action.type.ts';
import {
  Project_CloneProject_Failed,
  Project_Clone_ShowModal,
} from '../../../constants/projectActionTypes.ts';

export const sourceProjectId = (state: string = '', action: Action): string => {
  switch (action.type) {
    case Project_Clone_ShowModal:
    case Project_CloneProject_Failed:
      return action.payload.sourceProjectId;

    default:
      return state;
  }
};
