import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ArchivedVariantAssignment } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Assignment/ArchivedVariantAssignment.tsx';
import { DefaultVariantAssignment } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Assignment/DefaultVariantAssignment.tsx';
import { PublishedVariantAssignment } from '../../../applications/itemEditor/features/ContentItemEditing/containers/details/Assignment/PublishedVariantAssignment.tsx';
import { IVariantStatusVariant } from '../../models/VariantModels.type.ts';
import { isArchivedWorkflowStep } from '../../utils/contentItemUtils.ts';
import { isVariantPublished } from '../../utils/contentItemVariantUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface Props {
  readonly canDiscard?: boolean;
  readonly variant: IVariantStatusVariant;
  readonly showDueDate?: boolean;
}

type ImmediatePublishingState = 'archived' | 'published' | 'none';

const getImmediatePublishingState = (variant: IVariantStatusVariant): ImmediatePublishingState => {
  if (isVariantPublished(variant)) {
    return 'published';
  }
  if (isArchivedWorkflowStep(variant.assignment.workflowStatus)) {
    return 'archived';
  }

  return 'none';
};

export const VariantAssignment: React.FC<Props> = ({
  canDiscard = false,
  showDueDate,
  variant,
}) => {
  const state = getImmediatePublishingState(variant);
  const isPublished = state === 'published';
  const shouldEnableDiscard = isPublished && canDiscard;

  return (
    <Stack spacing={Spacing.L} {...getDataUiElementAttribute(DataUiElement.Assignment)}>
      {isPublished && <PublishedVariantAssignment variant={variant} />}
      {state === 'archived' && <ArchivedVariantAssignment />}
      <DefaultVariantAssignment
        variant={variant}
        showDueDate={showDueDate}
        enableDiscard={shouldEnableDiscard}
      />
    </Stack>
  );
};
VariantAssignment.displayName = 'VariantAssignment';
