import classNames from 'classnames';
import { forwardRef } from 'react';
import { getDropDown } from '../../../../_shared/components/DropDown.tsx';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getShortenedTextWithEllipsis } from '../../../../_shared/utils/stringUtils.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';

export interface ISubscriptionSelectorStateProps {
  readonly dropdownId?: string;
  readonly selectedSubscriptionId?: string | null;
  readonly className?: string;
  readonly activeSubscriptions: ReadonlyArray<ISubscription> | null;
  readonly disabled?: boolean;
}

interface ISubscriptionSelectorDispatchProps {
  readonly onSelectionChanged: (subscriptionId: string) => void;
}

type SubscriptionSelectorStateProps = ISubscriptionSelectorStateProps &
  ISubscriptionSelectorDispatchProps;

const DropDown = getDropDown<ISubscription>();

export const SubscriptionSelector = forwardRef<HTMLDivElement, SubscriptionSelectorStateProps>(
  (
    {
      disabled,
      dropdownId,
      selectedSubscriptionId,
      activeSubscriptions,
      className,
      onSelectionChanged,
    },
    ref,
  ): JSX.Element | null => {
    if (!activeSubscriptions) {
      return null;
    }

    const selectedOption = activeSubscriptions.find(
      (subscription) => !!subscription && subscription.subscriptionId === selectedSubscriptionId,
    );
    const onSelect = (subscription: ISubscription) => {
      if (!disabled) {
        onSelectionChanged(subscription.subscriptionId);
      }
    };

    const maxSubscriptionNameLength = 50;

    return (
      <EnterHotkeyDisabler>
        <DropDown
          ref={ref}
          id={dropdownId}
          disabled={disabled}
          className={classNames('combo-box', className)}
          onSelect={onSelect}
          renderOptionName={(subscription) =>
            subscription &&
            getShortenedTextWithEllipsis(subscription.subscriptionName, maxSubscriptionNameLength)
          }
          getOptionId={(subscription) => subscription.subscriptionId}
          options={activeSubscriptions}
          selectedOption={selectedOption}
          dataUiCollectionName={DataUiCollection.Subscriptions}
        />
      </EnterHotkeyDisabler>
    );
  },
);

SubscriptionSelector.displayName = 'SubscriptionSelector';
