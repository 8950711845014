import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Calendar_Initialization_Started,
  Calendar_LoadItemsWithVariantActiveInPeriod_Finished,
  Calendar_LoadItemsWithVariantActiveInPeriod_Started,
  Calendar_Page_Left,
} from '../../constants/calendarActionTypes.ts';

export const itemsLoadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case Calendar_Initialization_Started:
    case Calendar_LoadItemsWithVariantActiveInPeriod_Started: {
      return LoadingStatus.Loading;
    }

    case Calendar_LoadItemsWithVariantActiveInPeriod_Finished: {
      return LoadingStatus.Loaded;
    }

    case Calendar_Page_Left: {
      return LoadingStatus.InitialEmpty;
    }

    default:
      return state;
  }
};
