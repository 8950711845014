import { combineReducers } from '@reduxjs/toolkit';
import { collapsedNodeIds } from './reducerComponents/collapsedNodeIds.ts';
import { draggedNodeId } from './reducerComponents/draggedNodeId.ts';
import { editedNodeId } from './reducerComponents/editedNodeId.ts';
import { isCreateNewFormDisplayed } from './reducerComponents/isCreateNewFormDisplayed.ts';
import { originalSitemap } from './reducerComponents/originalSitemap.ts';
import { projectContainsPublishedItems } from './reducerComponents/projectContainsPublishedItems.ts';
import { savingSitemap } from './reducerComponents/savingSitemap.ts';
import { sitemap } from './reducerComponents/sitemap.ts';

export const sitemapAppReducer = combineReducers({
  collapsedNodeIds,
  draggedNodeId,
  editedNodeId,
  isCreateNewFormDisplayed,
  projectContainsPublishedItems,
  originalSitemap,
  savingSitemap,
  sitemap,
});
