import { RefObject, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver, { ObservedSize } from 'use-resize-observer';

type UseScrollInfoResult = {
  readonly isScrollable: boolean;
  readonly height: number;
};

const throttleIntervalMs = 50;

/**
 * Gets information about scrolling
 * @param elementRef Reference to scrollable element
 */
export const useScrollInfo = <T extends HTMLElement>(
  elementRef: RefObject<T>,
): UseScrollInfoResult => {
  const [isScrollable, setIsScrollable] = useState(false);
  // when you use onResize function the hook itself returns undefined height, so we need to save it like this.
  const [elementHeight, setElementHeight] = useState(0);

  const onResize = useThrottledCallback(({ height = 0 }: ObservedSize) => {
    if (elementRef.current) {
      setElementHeight(height);
      const scrollHeight = elementRef.current.scrollHeight;
      setIsScrollable(scrollHeight > height);
    }
  }, throttleIntervalMs);

  useResizeObserver({ ref: elementRef, onResize });

  return {
    isScrollable,
    height: elementHeight,
  };
};
