import { memoize } from '@kontent-ai/memoization';
import { ICollection } from '../../data/models/collections/Collection.ts';
import { allCollectionsOption } from '../constants/userListingFilter.ts';
import {
  CollectionFilterOption,
  LanguageFilterOption,
  RoleFilterOption,
} from '../models/UserListingFilterOption.type.ts';

export const getSelectedLanguagesFilterOptions = memoize.maxOne(
  (
    languages: ReadonlyArray<LanguageFilterOption>,
    selectedIds: ReadonlySet<Uuid>,
  ): ReadonlyArray<LanguageFilterOption> =>
    languages.filter((language: LanguageFilterOption) => selectedIds.has(language.id)),
);

export const getSelectedRolesFilterOptions = memoize.maxOne(
  (
    roles: ReadonlyArray<RoleFilterOption>,
    selectedIds: ReadonlySet<Uuid>,
  ): ReadonlyArray<RoleFilterOption> => roles.filter((role) => selectedIds.has(role.id)),
);

export const getSelectedCollectionsFilterOptions = memoize.maxOne(
  (
    collections: ReadonlyArray<CollectionFilterOption>,
    selectedIds: ReadonlySet<Uuid>,
  ): ReadonlyArray<CollectionFilterOption> =>
    collections.filter((collection: CollectionFilterOption) => selectedIds.has(collection.id)),
);

export const getCollectionsFilterOptions = memoize.maxOne(
  (collections: ReadonlyArray<ICollection>): ReadonlyArray<CollectionFilterOption> => [
    allCollectionsOption,
    ...collections.map(({ id, name }) => ({
      id,
      label: name,
    })),
  ],
);
