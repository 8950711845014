import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import React, { useState } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getRelevantCollections } from '../../../../../_shared/selectors/getRelevantCollections.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { isAssetCollectionMandatoryForCurrentSubscriptionOrProject } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { AssetsSelectCollectionDialog as AssetsSelectCollectionDialogComponent } from '../../components/AssetListing/AssetsSelectCollectionDialog.tsx';
import { notInCollectionSelectItem } from '../../constants/assetNullCollectionConstants.ts';
import { cannotEditAssetsInCollection } from '../../constants/uiConstants.ts';
import { useMoveSelectedAssetsToCollection } from '../../hooks/useMoveSelectedAssetsToCollection.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';
import { getCollectionItems } from '../../utils/getCollectionItems.ts';

type Props = {
  readonly closeDialog: () => void;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
};

export const AssetsMoveToCollectionDialog: React.FC<Props> = (props) => {
  const [selectedOption, setSelectedOption] = useState<IBaseSelectItem | null>(null);

  const assets = useSelector((s) => s.data.assets.byId);
  const moveSelectedAssets = useMoveSelectedAssetsToCollection({
    assetsById: assets,
    orderBy: props.orderBy,
    selectedAssets: props.selectedAssets,
  });

  const isAssetCollectionMandatory = useSelector(
    isAssetCollectionMandatoryForCurrentSubscriptionOrProject,
  );

  const availableSelectItems = useSelector((s) =>
    getCollectionItems(
      getRelevantCollections(s, Capability.UpdateAssets),
      !isAssetCollectionMandatory,
    ),
  );
  const disabledSelectItems = useSelector((s) =>
    getCollectionItems(getRelevantCollections(s, Capability.ViewAssets), false)
      .filter((item) => !availableSelectItems.some(({ id }) => item.id === id))
      .map((item) => ({ ...item, disabledTooltipText: cannotEditAssetsInCollection })),
  );

  const disabledTooltipText = getDisabledTooltipText(assets, props.selectedAssets, selectedOption);

  return (
    <AssetsSelectCollectionDialogComponent
      collectionSelectItems={[...availableSelectItems, ...disabledSelectItems]}
      disabledCollectionSelectItemIds={disabledSelectItems.map((item) => item.id)}
      disabledTooltipText={disabledTooltipText}
      handleClose={props.closeDialog}
      handleSelect={(option) =>
        moveSelectedAssets(option.id === notInCollectionSelectItem.id ? null : option.id)
      }
      handleSelectedOptionChange={setSelectedOption}
      headlineText="Change collection"
      primaryActionText="Change"
      selectedOption={selectedOption}
    />
  );
};

AssetsMoveToCollectionDialog.displayName = 'AssetsMoveToCollectionDialog';

const getDisabledTooltipText = (
  assets: Immutable.Map<Uuid, IAsset>,
  selectedAssetIds: ReadonlySet<Uuid>,
  selectedOption: IBaseSelectItem | null,
): string | undefined => {
  if (!selectedOption) {
    return 'Select a collection first.';
  }

  const selectedAssets = assets.filter((_, assetId: Uuid) => selectedAssetIds.has(assetId));
  const areAllAssetsFromTargetCollection = selectedAssets.every((asset: IAsset) =>
    selectedOption.id === notInCollectionSelectItem.id
      ? asset.collectionId === null
      : asset.collectionId === selectedOption.id,
  );

  if (areAllAssetsFromTargetCollection) {
    return 'All of the selected assets are associated with the selected collection.';
  }

  return undefined;
};
