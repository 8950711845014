import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { FullScreenModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { TimeInterval, timeIntervals } from '../../constants/timeIntervals.ts';
import { WorkflowStage } from '../../constants/workflowStage.ts';
import { ContentPaceBodyContainerProps } from '../containers/ContentPaceBodyLiveContainer.tsx';
import { ContentPaceContentTypeSelector } from '../containers/ContentPaceContentTypeSelector.tsx';
import { ContentPaceDetailProps } from '../containers/ContentPaceDetailLiveContainer.tsx';
import { ContentPaceWorkflowStageSelector } from './ContentPaceWorkflowStageSelector.tsx';

type OpenDetail = Readonly<{
  averageTimeInSeconds: number;
  workflowId: Uuid;
  workflowStepId: Uuid;
  workflowStepName: string;
}>;

type ContentPaceProps = Readonly<{
  BodyComponent: React.FC<ContentPaceBodyContainerProps>;
  DetailComponent: React.FC<ContentPaceDetailProps>;
  workflowStage: WorkflowStage;
  timeInterval: TimeInterval;
  contentTypeId: Uuid | null;
  onContentTypeIdChange: (newContentTypeId: Uuid | null) => void;
  onTimeIntervalChange: (newTimeFrame: TimeInterval) => void;
  onWorkflowStageChange: (newVariantType: WorkflowStage) => void;
}>;

export const ContentPace: React.FC<ContentPaceProps> = ({
  BodyComponent,
  DetailComponent,
  workflowStage,
  timeInterval,
  contentTypeId,
  onContentTypeIdChange,
  onTimeIntervalChange,
  onWorkflowStageChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState<OpenDetail | null>(null);

  const handleOpenDetail = (
    averageTimeInSeconds: number,
    workflowId: Uuid,
    workflowStepId: Uuid,
    workflowStepName: string,
  ) => {
    setIsDialogOpen(true);
    setOpenDetail({
      averageTimeInSeconds,
      workflowId,
      workflowStepId,
      workflowStepName,
    });
  };

  return (
    <div className="canvas">
      <div className="canvas__workspace">
        <div className="canvas__content">
          <div className="canvas__content-pane">
            <HtmlPageTitle appName={AppNames.ContentPace} />

            <Stack spacing={Spacing.XL}>
              <Row alignX="end" alignY="center" spacing={Spacing.M}>
                <Column width="fit-content">
                  <ContentPaceWorkflowStageSelector
                    selectedWorkflowStage={workflowStage}
                    onSelectionChange={onWorkflowStageChange}
                  />
                </Column>

                <Column width="fit-content">
                  <ContentPaceContentTypeSelector
                    selectedContentTypeId={contentTypeId}
                    onSelectionChange={onContentTypeIdChange}
                  />
                </Column>

                <Column width="fit-content">
                  <Inline align="center" spacingX={Spacing.S}>
                    <Box<'label'>
                      component="label"
                      color={colorTextDefault}
                      htmlFor="time-frame-label"
                      typography={Typography.BodyMedium}
                    >
                      Time frame:
                    </Box>
                    <SingleSelect
                      id="time-frame-label"
                      items={timeIntervals}
                      selectedItemId={timeInterval}
                      onSelectionChange={onTimeIntervalChange}
                    />
                  </Inline>
                </Column>
              </Row>

              <BodyComponent
                contentTypeId={contentTypeId}
                workflowStage={workflowStage}
                timeInterval={timeInterval}
                onChartItemClick={handleOpenDetail}
              />
            </Stack>

            <FullScreenModalDialog
              headline={`Average time spent in “${openDetail?.workflowStepName}” workflow step`}
              isOpen={isDialogOpen}
              isDismissable
              onClose={() => setIsDialogOpen(false)}
            >
              {openDetail && (
                <DetailComponent
                  averageTimeInSeconds={openDetail.averageTimeInSeconds ?? 0}
                  contentTypeId={contentTypeId}
                  timeInterval={timeInterval}
                  workflowId={openDetail.workflowId}
                  workflowStage={workflowStage}
                  workflowStepId={openDetail.workflowStepId}
                />
              )}
            </FullScreenModalDialog>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentPace.displayName = 'ContentPace';
