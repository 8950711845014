import React, { ReactNode, createContext, useRef, useState } from 'react';
import {
  EditorScrollContextValue,
  EditorScrollProviderState,
  createMemoizedEditorScrollContextValue,
} from '../utils/editorViewContext/editorScrollContext/createEditorScrollContextValue.ts';

export const EditorScrollContextDefaultGracePeriodMs = 100;

export const initialEditorScrollContextState: EditorScrollProviderState = {
  isCandid: false,
  offsetTopPx: 0,
  scrollTopPx: 0,
};

export const initialEditorScrollContextValue: EditorScrollContextValue = {
  hasUserScrolledUp: false,
  isCandid: false,
  isPaperScrolledOut: false,
};

type UpdateContext = (getUpdate: () => Partial<EditorScrollProviderState>) => void;

type EditorScrollContextProviderProps = {
  readonly children: (updateContext: UpdateContext) => ReactNode;
};

export const EditorScrollContext = createContext<EditorScrollContextValue>(
  initialEditorScrollContextValue,
);

export const EditorScrollContextProvider: React.FC<EditorScrollContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState(initialEditorScrollContextState);
  const previousState = useRef(initialEditorScrollContextState);
  const previousValue = useRef(initialEditorScrollContextValue);

  const contextValue = createMemoizedEditorScrollContextValue(
    state,
    previousState.current,
    previousValue.current,
  );

  const updateContext: UpdateContext = (getUpdate) =>
    setState((prevState) => {
      previousValue.current = createMemoizedEditorScrollContextValue(
        prevState,
        previousState.current,
        previousValue.current,
      );
      previousState.current = prevState;

      return {
        ...prevState,
        ...getUpdate(),
      };
    });

  return (
    <EditorScrollContext.Provider value={contextValue}>
      {children(updateContext)}
    </EditorScrollContext.Provider>
  );
};
