import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export enum CalloutType {
  Info = 'callout--is-info',
  Light = 'callout--is-light',
  Warning = 'callout--is-warning',
}

type CalloutProps = {
  readonly className?: string;
  readonly iconName?: IconName;
  readonly subtitle?: string;
  readonly title?: string;
  readonly type: CalloutType;
  readonly uiElement?: DataUiElement;
};

const Title: React.FC<{
  readonly iconName?: IconName;
  readonly text: string;
}> = ({ text, iconName }) => (
  <div className="callout__title">
    {iconName && <Icon className="callout__title-icon" iconName={iconName} />}
    {text}
  </div>
);

const Subtitle: React.FC<{ readonly text: string }> = ({ text }) => (
  <p className="callout__subtitle">{text}</p>
);

export const Callout: React.FC<React.PropsWithChildren<CalloutProps>> = ({
  children,
  className,
  iconName,
  subtitle,
  title,
  type,
  uiElement,
}) => (
  <div
    className={classNames('callout', type, className)}
    {...(uiElement && getDataUiElementAttribute(uiElement))}
  >
    {title && <Title text={title} iconName={iconName} />}
    <div className="callout__message">
      {subtitle && <Subtitle text={subtitle} />}
      {children}
    </div>
  </div>
);

Callout.displayName = 'Callout';
