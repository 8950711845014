import { Action } from '../../../../../@types/Action.type.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { AssetLibrary_Folders_Opened } from '../../constants/assetLibraryActionTypes.ts';
import { AssetSelector_Opened } from '../../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';

const initialState: Uuid = RootFolderId;

export const openedFolderId = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case AssetLibrary_Folders_Opened:
      return action.payload.assetFolderId;

    case AssetSelector_Opened:
      return initialState;

    default:
      return state;
  }
};
