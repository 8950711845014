import { usePrevious } from '@kontent-ai/hooks';
import { ContentState } from 'draft-js';
import { useCallback, useEffect, useState } from 'react';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { getContentStateActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { usePendingAiActionWithSession } from '../../../../../../_shared/features/AI/hooks/usePendingAiActionWithSession.ts';
import { AiActionProps } from '../../../../../../_shared/features/AI/types/AiActionProps.type.ts';
import { AiError } from '../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { AiActionSource } from '../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { repositoryCollection } from '../../../../../../_shared/repositories/repositories.ts';
import { getLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createTranslateContentRequest } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.translateContent.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { loadRichTextReferences } from '../../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { EmptyContentComponents } from '../../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { IBaseTextItemElement } from '../../../../../itemEditor/models/contentItemElements/IBaseTextItemElement.type.ts';
import { getElementById } from '../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { convertElementsToDomainModel } from '../../../../../itemEditor/stores/utils/contentItemHelperMethods.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../editorCore/utils/decorable.ts';
import { isContentComponent } from '../../../../utils/blocks/blockTypeUtils.ts';
import { createContent, filterBlocks } from '../../../../utils/blocks/editorBlockUtils.ts';
import {
  getSelectionOfAllContent,
  moveCaretToSelectionEnd,
} from '../../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  insertNewChars,
  isContentEmpty,
  splitBlock,
} from '../../../../utils/general/editorContentUtils.ts';
import { emptyContentState } from '../../../../utils/general/editorEmptyValues.ts';
import { pasteContent } from '../../../../utils/import/editorImportUtils.ts';
import { StylesPlugin } from '../../../visuals/StylesPlugin.tsx';
import { createAiActionResultSelector } from '../../helpers/createAiActionResultSelector.ts';
import { AiTranslateAction } from './containers/AiTranslateAction.tsx';

export type TranslateContentPlugin = EditorPlugin<None, AiActionProps, None, None, [StylesPlugin]>;

export const TranslateContentPlugin: PluginComponent<TranslateContentPlugin> = (props) => {
  const { element } = props;

  const [lastError, setLastError] = useState<AiError | null>(null);
  const [initialContentWithSelection, setInitialContentWithSelection] =
    useState<IContentChangeInput | null>(null);

  const languages = useSelector((s) => s.data.languages);
  const dispatch = useDispatch();

  const { aiSessionId, elementOperationTrackingData, trackFinishedAction, trackStartingAction } =
    useAiActionTrackingWithSession(element);

  const { decorateWithEditorStateCallbacks, executeChange, getApi, getEditorState } =
    useEditorStateCallbacks<TranslateContentPlugin>();

  const { aiOperationState, startPendingAiAction, finishPendingAiAction } =
    usePendingAiActionWithSession(aiSessionId);

  const { run, result } = useAiTask(
    AiActionName.TranslateContent,
    createAiActionResultSelector(props.element.elementType, getContentStateActionResult),
  );

  useOnFinishedAiActionTask(result.isFinished, () => {
    if (result.trackingParams) {
      trackFinishedAction(result.trackingParams);
    }
    setLastError(result.error);
    setInitialContentWithSelection(null);
    finishPendingAiAction();
  });

  const startTranslateContentAction = useCallback(
    async (sourceVariantId: Uuid) => {
      if (!element.itemId) {
        return;
      }

      const {
        itemId: { itemId, variantId: targetVariantId },
        elementId,
      } = element;

      const sourceLanguage = getLanguage(languages, sourceVariantId);
      const targetLanguage = getLanguage(languages, targetVariantId);
      if (!sourceLanguage || !targetLanguage) {
        return;
      }

      startPendingAiAction();

      const content = await loadSourceContent(itemId, sourceVariantId, elementId, dispatch);

      run(
        createTranslateContentRequest(
          content,
          sourceLanguage.name,
          sourceLanguage.codename,
          targetLanguage.name,
          targetLanguage.codename,
          elementOperationTrackingData,
        ),
        { element },
      );

      trackStartingAction({
        action: AiActionName.TranslateContent,
        source: AiActionSource.Element,
        sourceVariantId,
        sourceLanguageCode: sourceLanguage.codename,
        targetLanguageCode: targetLanguage.codename,
      });
    },
    [
      languages,
      run,
      element,
      elementOperationTrackingData,
      startPendingAiAction,
      trackStartingAction,
    ],
  );

  const previousAiResultContent = usePrevious(result.content);

  useEffect(() => {
    if (result.content && !previousAiResultContent) {
      const currentContent = getEditorState().getCurrentContent();
      setInitialContentWithSelection(
        moveCaretToSelectionEnd({
          content: currentContent,
          selection: getSelectionOfAllContent(currentContent),
        }),
      );
    }
  }, [getEditorState, previousAiResultContent, result.content]);

  useEffect(() => {
    const appendTranslationToInitialContent = (
      input: IContentChangeInput,
    ): IContentChangeResult => {
      if (!initialContentWithSelection || !result.content) {
        return input;
      }

      const resultWithoutComponents = createContent(
        filterBlocks(getBlocks(result.content), (b) => !isContentComponent(b)).blocks,
      );

      if (isContentEmpty(initialContentWithSelection.content)) {
        return pasteContent(initialContentWithSelection, resultWithoutComponents);
      }

      const initialContentWithNewLine =
        element.elementType === ElementType.RichText
          ? splitBlock(initialContentWithSelection)
          : insertNewChars(initialContentWithSelection, '\n');

      return pasteContent(initialContentWithNewLine, resultWithoutComponents);
    };

    if (result.content) {
      executeChange((editorState) =>
        getApi().executeContentChange(
          editorState,
          editorState.getSelection(),
          appendTranslationToInitialContent,
          'insert-fragment',
          false,
        ),
      );
    }
  }, [executeChange, getApi, initialContentWithSelection, element.elementType, result.content]);

  const renderOverlays: Decorator<Render<TranslateContentPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => (
      <>
        {baseRenderOverlays(state)}
        <AiTranslateAction
          aiOperationState={aiOperationState}
          element={element}
          error={lastError}
          perform={startTranslateContentAction}
        />
      </>
    ),
    [aiOperationState, element, lastError, startTranslateContentAction],
  );

  const apply: Apply<TranslateContentPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      state.renderOverlays.decorate(renderOverlays);

      return {};
    },
    [decorateWithEditorStateCallbacks, renderOverlays],
  );

  return useEditorWithPlugin(props, { apply });
};

const loadSourceContent = async (
  itemId: Uuid,
  sourceVariantId: Uuid,
  elementId: Uuid,
  dispatch: Dispatch,
): Promise<ContentState> => {
  const variantServerModel =
    await repositoryCollection.contentItemRepository.getVariantWithElements(
      itemId,
      sourceVariantId,
      { elementIds: [elementId] },
    );
  const elements = convertElementsToDomainModel(variantServerModel.contentElements);
  const content =
    getElementById<IBaseTextItemElement>(elementId, elements)?._editorState?.getCurrentContent() ||
    emptyContentState;

  // The translated content will be streamed to the AI result view together with its original references
  // We need to make sure the referenced data is available before running the action
  await dispatch(loadRichTextReferences([content], EmptyContentComponents));

  return content;
};
