import { assert } from '@kontent-ai/utils';
import { ISuggestionServerModel } from '../../../../repositories/serverModels/CommentThreadServerModel.type.ts';
import { IComment, ICommentContentModel } from './Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemBaseContentModel,
  ICommentThreadItemContentModel,
} from './CommentThreadItem.ts';

export interface ISuggestion extends ICommentThreadItem {
  readonly suggestedText: string;
  readonly suggestionApprovedAt: DateTimeStamp | null;
  readonly suggestionApprovedBy: UserId | null;
}

export interface ISuggestionContentModel extends ICommentThreadItemBaseContentModel {
  readonly suggestedText: string;
}

export const emptySuggestion: ISuggestion = {
  id: '',
  type: CommentThreadItemType.Suggestion,
  suggestedText: '',
  suggestionApprovedAt: null,
  suggestionApprovedBy: null,
  createdAt: '',
  updatedAt: '',
  createdBy: '',
  isSubmitting: false,
  isEditing: false,
};

export function isSuggestion(arg: ISuggestion | IComment | ICommentThreadItem): arg is ISuggestion {
  return arg.type === CommentThreadItemType.Suggestion;
}

export function isSuggestionContent(
  arg: ICommentThreadItemContentModel | ISuggestionContentModel | ICommentContentModel,
): arg is ISuggestionContentModel {
  return arg.type === CommentThreadItemType.Suggestion;
}

export function createSuggestionDomainModel(rawSuggestion: ISuggestionServerModel): ISuggestion {
  assert(!!rawSuggestion, () => 'Suggestion server model is falsy.');

  return {
    ...emptySuggestion,
    id: rawSuggestion._id ?? emptySuggestion.id,
    createdAt: rawSuggestion.createdAt ?? emptySuggestion.createdAt,
    updatedAt: rawSuggestion.updatedAt ?? emptySuggestion.updatedAt,
    createdBy: rawSuggestion.createdBy ?? emptySuggestion.createdBy,
    suggestedText: rawSuggestion.suggestedText ?? emptySuggestion.suggestedText,
    suggestionApprovedAt:
      rawSuggestion.suggestionApprovedAt ?? emptySuggestion.suggestionApprovedAt,
    suggestionApprovedBy:
      rawSuggestion.suggestionApprovedBy ?? emptySuggestion.suggestionApprovedBy,
  };
}
