import { Box } from '@kontent-ai/component-library/Box';
import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import {
  alphabetically,
  createCompare,
  makeCancellablePromise,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column as DataTableColumn,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  createCustomAppEditingLink,
  createNewCustomAppLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  CustomApp,
  createCustomAppDomainModel,
} from '../../../../data/models/customApps/CustomApp.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { CustomAppsEmptyState } from './listing/CustomAppsEmptyState.tsx';

const { customAppRepository } = repositoryCollection;

type CustomAppItem = {
  readonly id: Uuid;
  readonly name: string;
  readonly editorPath: string;
};

type CustomAppListingProps = {
  readonly subscriptionId?: Uuid;
};

const dataTableHeadColumns: Immutable.List<DataTableColumn> = Immutable.List([
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
]);

const createListingItem = (
  domainModel: CustomApp,
  projectId: Uuid,
  subscriptionId?: Uuid,
): CustomAppItem => ({
  id: domainModel.id,
  name: domainModel.name,
  editorPath: createCustomAppEditingLink({
    projectId,
    subscriptionId,
    customAppId: domainModel.id,
  }),
});

export const CustomAppListing: React.FC<CustomAppListingProps> = ({ subscriptionId }) => {
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);
  const createNewCustomAppPath = createNewCustomAppLink({ projectId, subscriptionId });

  const [customApps, setCustomApps] = useState<ReadonlyArray<CustomAppItem> | null>(null);
  useEffect(() => {
    const { cancel } = makeCancellablePromise(async () => await customAppRepository.getAll())
      .then((value) =>
        setCustomApps(
          value
            .map(createCustomAppDomainModel)
            .map((item) => createListingItem(item, projectId, subscriptionId))
            .sort(createCompare({ compare: alphabetically, select: (item) => item.name })),
        ),
      )
      .catch(swallowCancelledPromiseError);

    return cancel;
  }, [projectId, subscriptionId]);

  if (!customApps) {
    return <Loader />;
  }

  if (customApps.length === 0) {
    return (
      <div {...getDataUiAppNameAttribute(DataUiAppName.CustomApps)}>
        <Stack spacing={Spacing.XL}>
          <PageTitle>{EnvironmentSettingsAppNames.CustomApps}</PageTitle>
          <CustomAppsEmptyState createNewCustomAppPath={createNewCustomAppPath} />
        </Stack>
      </div>
    );
  }

  return (
    <div
      className="canvas__inner-section canvas__inner-section--restricted-width"
      {...getDataUiAppNameAttribute(DataUiAppName.CustomApps)}
    >
      <Stack spacing={Spacing.XL}>
        <Row>
          <Column>
            <PageTitle>{EnvironmentSettingsAppNames.CustomApps}</PageTitle>
          </Column>
          <Column width="fit-content">
            <RouterLinkButton
              buttonStyle="primary"
              to={createNewCustomAppPath}
              {...getDataUiActionAttribute(DataUiAction.CreateNew)}
            >
              Create new custom app
            </RouterLinkButton>
          </Column>
        </Row>
        <Row>
          <Box typography={Typography.SubheadlineLarge} color={colorTextDefault}>
            {customApps.length} {customApps.length === 1 ? 'custom app' : 'custom apps'}
          </Box>
        </Row>
        <DataTable
          dataUiCollectionName={DataUiCollection.CustomAppList}
          header={<DataTableHeadRow columns={dataTableHeadColumns} />}
        >
          {customApps.map((customApp: CustomAppItem) => (
            <DataTableRow id={customApp.id} key={customApp.id} dataUiObjectName={customApp.name}>
              <LinkDataTableCell linkPath={customApp.editorPath}>
                {customApp.name}
              </LinkDataTableCell>
            </DataTableRow>
          ))}
        </DataTable>
      </Stack>
    </div>
  );
};
