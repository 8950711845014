import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_ItemsLimitExceeded,
  ProjectOverview_LoadItemsMatchingFilter_Started,
  ProjectOverview_LoadItemsMatchingFilter_Succeeded,
  ProjectOverview_Page_Left,
} from '../constants/projectOverviewActionTypes.ts';

export const projectOverviewLoadItemsMatchingFilterFinished = (
  contentItems: ReadonlyArray<IListingContentItem>,
  now: Date,
) =>
  ({
    type: ProjectOverview_LoadItemsMatchingFilter_Succeeded,
    payload: {
      contentItems,
      now,
    },
  }) as const;

export const projectOverviewLoadItemsMatchingFilterStarted = (cancellationToken: Uuid) =>
  ({
    type: ProjectOverview_LoadItemsMatchingFilter_Started,
    payload: { cancellationToken },
  }) as const;

export const createTokenizedProjectOverviewLoadItemsMatchingFilterStarted =
  (cancellationToken: Uuid) => () =>
    projectOverviewLoadItemsMatchingFilterStarted(cancellationToken);

export const projectOverviewLoadItemsMatchingFilterLimitExceeded = () =>
  ({ type: ProjectOverview_LoadItemsMatchingFilter_ItemsLimitExceeded }) as const;

export const projectOverviewLeft = () => ({ type: ProjectOverview_Page_Left }) as const;
