import { px } from '@kontent-ai/component-library/tokens';
import { gridUnit } from '../../constants/styleConstants.ts';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../uiComponents/DropDown/dropDownTippyOptions.ts';

export const optionMaxWidth = px(50 * gridUnit);

export const projectMenuTippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'bottom-start',
};
