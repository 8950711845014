import React from 'react';
import { SpacesRoute, SpacesRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useSpaceData } from '../../spaces/hooks/useSpaceData.ts';
import { CollectionExpandedFormSpaces as CollectionExpandedFormSpacesComponent } from '../components/BarItems/CollectionExpandedFormSpaces.tsx';

type Props = {
  readonly collectionId: Uuid;
};

export const CollectionExpandedFormSpaces: React.FC<Props> = ({ collectionId }) => {
  const spacesMetadata = useSpaceData(collectionId);

  const spacesPath = useSelector((s) =>
    buildPath<SpacesRouteParams>(SpacesRoute, { projectId: s.sharedApp.currentProjectId }),
  );

  return (
    <CollectionExpandedFormSpacesComponent spaceNames={spacesMetadata} spacesPath={spacesPath} />
  );
};

CollectionExpandedFormSpaces.displayName = 'CollectionExpandedFormSpaces';
