import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import { uuidPattern } from '../../../_shared/utils/validation/typeValidators.ts';

export const SmartLinkRootRoute = '/goto';

const comment = `comment-thread/:commentThreadId(${uuidPattern})`;
const elementCodename = 'element/:elementCodename';
const filter = `filter/:filterId(${uuidPattern})`;
const item = `item/:itemGuid(${uuidPattern})`;
const contentComponent = `component/:deliveryContentComponentId(${uuidPattern})`;
const project = `project/:projectId(${uuidPattern})`;
const space = `space/:spaceId(${uuidPattern})`;
const task = `task/:taskId(${uuidPattern})`;
const variant = `variant/:variantGuid(${uuidPattern})`;
const variantCodename = 'variant-codename/:variantCodename';
export const itemElementRouteSegment = `${item}/${elementCodename}`;
export const componentElementRouteSegment = `${item}/${contentComponent}/${elementCodename}`;

export const AssetsLibraryRoute = `${SmartLinkRootRoute}/assets`;
export const CommentRoute = `${SmartLinkRootRoute}/comment/${comment}/${item}/${variant}/${project}`;
export const EditItemElementVariantCodenameRoute = `${SmartLinkRootRoute}/edit-item/${project}/${variantCodename}/${itemElementRouteSegment}`;
export const EditComponentElementVariantCodenameRoute = `${SmartLinkRootRoute}/edit-item/${project}/${variantCodename}/${componentElementRouteSegment}`;
export const EditItemRoute = `${SmartLinkRootRoute}/edit-item/${item}/${variant}/${project}`;
export const EditItemVariantCodenameRoute = `${SmartLinkRootRoute}/edit-item/${project}/${variantCodename}/${item}`;
export const FilterRoute = `${SmartLinkRootRoute}/view-filter/${project}/${filter}`;
export const OpenProjectRoute = `${SmartLinkRootRoute}/open-project/${project}`;
export const TaskRoute = `${SmartLinkRootRoute}/view-task/${task}/${item}/${variant}/${project}`;
export const WebSpotlightSharedPreviewRoute = `${SmartLinkRootRoute}/preview/${project}/${variant}/${space}/${item}`;

export type EnvironmentRouteParams = {
  readonly projectId: Uuid;
};

export type EditItemRouteParams = EnvironmentRouteParams & {
  readonly itemGuid: Uuid;
  readonly variantGuid: Uuid;
};

export type EditItemVariantCodenameRouteParams = EnvironmentRouteParams & {
  readonly itemGuid: Uuid;
  readonly variantCodename: string;
};

export type EditItemElementVariantCodenameRouteParams = EditItemVariantCodenameRouteParams & {
  readonly elementCodename: string;
};

export type EditComponentElementVariantCodenameRouteParams =
  EditItemElementVariantCodenameRouteParams & {
    readonly deliveryContentComponentId: Uuid;
  };

export type CommentRouteParams = EditItemRouteParams & {
  readonly commentThreadId: Uuid;
};

export type TaskRouteParams = EditItemRouteParams & {
  readonly taskId: Uuid;
};

export type FilterRouteParams = EnvironmentRouteParams & {
  readonly filterId: Uuid;
};

export type SharedWebSpotlightPreviewParams = EditItemRouteParams & {
  readonly spaceId: Uuid;
};

export const AssetsLibraryComponentKey = 'assets-library';
export const CommentComponentKey = 'comment';
export const EditItemComponentKey = 'edit-item';
export const EditItemVariantCodenameComponentKey = 'edit-item-variant-codename';
export const FilterComponentKey = 'filter-key';
export const OpenProjectComponentKey = 'open-project';
export const OtherComponentKey = 'any';
export const TaskComponentKey = 'task';
export const WebSpotlightSharedPreviewComponentKey = 'web-spotlight-shared-preview';

export const AssetsLibraryRouteKey = `${AppNames.SmartLink}-assets-library`;
export const CommentRouteKey = `${AppNames.SmartLink}-comment`;
export const EditItemElementVariantCodenameRouteKey = `${AppNames.SmartLink}-edit-item-element-variant-codename`;
export const EditComponentElementVariantCodenameRouteKey = `${AppNames.SmartLink}-edit-component-element-variant-codename`;
export const EditItemRouteKey = `${AppNames.SmartLink}-edit-item`;
export const EditItemVariantCodenameRouteKey = `${AppNames.SmartLink}-edit-item-variant-codename`;
export const FilterRouteKey = `${AppNames.SmartLink}-filter`;
export const OpenProjectRouteKey = `${AppNames.SmartLink}-open-project`;
export const OtherRouteKey = `${AppNames.SmartLink}-any`;
export const SmartLinkRootRouteKey = AppNames.SmartLink;
export const TaskRouteKey = `${AppNames.SmartLink}-task`;
export const WebSpotlightSharedPreviewRouteKey = `${AppNames.SmartLink}-web-spotlight-shared-preview`;
