import React from 'react';
import { DataDraftJsAttributes } from '../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { getAiClassName } from '../../ai/utils/editorAiStyleUtils.ts';

export interface IActiveFinishedInstructionHighlighterProps {
  readonly activeInstructionAiSessionId: Uuid | null;
}

export const ActiveFinishedInstructionHighlighter: React.FC<
  IActiveFinishedInstructionHighlighterProps
> = ({ activeInstructionAiSessionId }): JSX.Element | null => {
  if (!activeInstructionAiSessionId) {
    return null;
  }

  // Important styles needed to overwrite default AI highlight in the inline style
  const inlineStyleString = `
.rte__content .${getAiClassName(activeInstructionAiSessionId)}[${DataDraftJsAttributes.OffsetKey}] {
  z-index: var(--z-200) !important;
  background-color: var(--suggestion-highlighted-bg-color) !important;
}`;

  return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
};

ActiveFinishedInstructionHighlighter.displayName = 'ActiveFinishedInstructionHighlighter';
