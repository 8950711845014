import Immutable from 'immutable';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';

export type ICheckAssetDescriptionRequirements = (
  isImage: boolean,
  currentLanguageDescription: string | null,
  otherLanguagesHaveAnyDescription: boolean,
) => boolean;

export const checkAssetDescriptionRequirements: ICheckAssetDescriptionRequirements = (
  isImage: boolean,
  currentLanguageDescription: string | null,
  otherLanguagesHaveAnyDescription: boolean,
): boolean => {
  if (currentLanguageDescription) {
    return true;
  }
  if (!isImage) {
    return !otherLanguagesHaveAnyDescription;
  }
  return false;
};

export type IAssetHasRequiredDescription = (
  asset: IAsset,
  currentLanguageGuid: Uuid,
  inactiveLanguagesId: Immutable.List<Uuid>,
) => boolean;

const assetHasRequiredDescription = (
  assetDescriptionsRequirementCheck: ICheckAssetDescriptionRequirements,
  isImage: (type: string) => boolean,
  asset: IAsset,
  currentLanguageGuid: Uuid,
  inactiveLanguagesId: Immutable.List<Uuid>,
): boolean => {
  const assetIsImage = isImage(asset.type);
  if (asset.descriptions.isEmpty()) {
    return assetDescriptionsRequirementCheck(assetIsImage, null, false);
  }
  const currentLanguageDescription = asset.descriptions.get(currentLanguageGuid) ?? null;
  const descriptionsExistsInOtherActiveLanguage = asset.descriptions
    .filter(
      (_description, languageId) =>
        !!languageId &&
        languageId !== currentLanguageGuid &&
        !inactiveLanguagesId.contains(languageId),
    )
    .some((description) => !!description);
  return assetDescriptionsRequirementCheck(
    assetIsImage,
    currentLanguageDescription,
    descriptionsExistsInOtherActiveLanguage,
  );
};

export const assetHasRequiredDescriptionCreator =
  (
    requirementChecker: ICheckAssetDescriptionRequirements,
    isImageMimeType: (type: string) => boolean,
  ): IAssetHasRequiredDescription =>
  (asset: IAsset, currentLanguageGuid: Uuid, inactiveLanguagesId: Immutable.List<Uuid>): boolean =>
    assetHasRequiredDescription(
      requirementChecker,
      isImageMimeType,
      asset,
      currentLanguageGuid,
      inactiveLanguagesId,
    );
