import { Action } from '../../../../../@types/Action.type.ts';
import {
  ProjectOverview_LoadItemsMatchingFilter_ItemsLimitExceeded,
  ProjectOverview_LoadItemsMatchingFilter_Succeeded,
} from '../../constants/projectOverviewActionTypes.ts';

export const itemsLimitExceeded = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case ProjectOverview_LoadItemsMatchingFilter_ItemsLimitExceeded: {
      return true;
    }

    case ProjectOverview_LoadItemsMatchingFilter_Succeeded: {
      return false;
    }

    default:
      return state;
  }
};
