import { gridUnit, px } from '@kontent-ai/component-library/tokens';
import React from 'react';

type Props = {
  readonly title?: string;
  readonly url: string;
};

export const EmptyStateImage: React.FC<Props> = ({ title = '', url }) => {
  return (
    <div
      css={`
        content: url(${url});
        width: ${px(30 * gridUnit)};
      `}
      title={title}
    />
  );
};

EmptyStateImage.displayName = 'EmptyStateImage';
