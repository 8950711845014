import React, { useCallback } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isWebLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { convertOpenInNewWindow } from '../api/editorLinkUtils.ts';
import { WebLink } from './components/WebLink.tsx';

export type DisplayWebLinksPlugin = EditorPlugin<None, None, None, None, [DisplayLinksPlugin]>;

type WebLinkEntityProps = EntityDecoratorProps & {
  readonly children: ReadonlyArray<React.ReactNode>;
};

const WebLinkEntity: React.FC<WebLinkEntityProps> = ({
  contentState,
  decoratedText,
  entityKey,
  children,
}) => {
  const entity = contentState.getEntity(entityKey);
  if (!isWebLink(entity)) {
    return children;
  }

  const { url, openInNewWindow, title } = entity.getData();

  return (
    <WebLink
      disabled
      key={entityKey}
      openInNewWindow={convertOpenInNewWindow(openInNewWindow)}
      text={decoratedText}
      title={title}
      url={url}
    >
      {children}
    </WebLink>
  );
};

WebLinkEntity.displayName = 'WebLinkEntity';

export const DisplayWebLinksPlugin: PluginComponent<DisplayWebLinksPlugin> = (props) => {
  const apply: Apply<DisplayWebLinksPlugin> = useCallback((state) => {
    const getLinkEntityComponent: Decorator<GetLinkEntityComponent<None>> =
      (baseGetLinkEntityComponent) => (linkType) => {
        switch (linkType) {
          case LinkType.Web:
            return {
              component: WebLinkEntity,
              props: {},
            };

          default:
            return baseGetLinkEntityComponent(linkType);
        }
      };

    state.getLinkEntityComponent.decorate(getLinkEntityComponent);

    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
