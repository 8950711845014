import { Action } from '../../../../../@types/Action.type.ts';
import {
  Environment_SwapEnvironment_CloseDialog,
  Environment_SwapEnvironment_Failed,
  Environment_SwapEnvironment_Finished,
  Environment_SwapEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';

export const swappingEnvironmentFailed = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Environment_SwapEnvironment_Failed:
      return true;

    case Environment_SwapEnvironment_CloseDialog:
    case Environment_SwapEnvironment_Started:
    case Environment_SwapEnvironment_Finished:
      return false;

    default:
      return state;
  }
};
