import { usePrevious } from '@kontent-ai/hooks';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';

export const SmartLink = () => {
  const history = useHistory();
  const { targetHistoryEntry, linkText, linkLocation, linkMessage, progress } = useSelector(
    (s) => s.smartLinkApp,
  );

  const previousTargetLocation = usePrevious(targetHistoryEntry?.path);

  useEffect(() => {
    if (targetHistoryEntry?.path && targetHistoryEntry.path !== previousTargetLocation) {
      history.push(targetHistoryEntry.path, targetHistoryEntry.state);
    }
  }, [history, targetHistoryEntry, previousTargetLocation]);

  if (targetHistoryEntry?.path) return null;

  return (
    <div
      className={classNames('utility-message utility-message--centered', {
        'utility-message--in-progress': progress,
      })}
    >
      <h1 className="utility-message__title">{linkMessage}</h1>
      {linkText && linkLocation && (
        <p className="utility-message__content">
          <a href={linkLocation}>{linkText}</a>
        </p>
      )}
    </div>
  );
};
