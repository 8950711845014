import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { IProjectServerModel } from '../../../../../repositories/serverModels/IProjectServerModel.type.ts';
import { UntitledProjectSurrogateName } from '../../constants/UIConstants.ts';
import {
  Project_RenameProjectOnServer_Failed,
  Project_RenameProjectOnServer_Finished,
  Project_RenameProjectOnServer_Started,
} from '../../constants/generalSettingsActionTypes.ts';
import { ProjectObservedState } from '../../containers/ProjectNameEditorElement.tsx';

interface IDeps {
  readonly projectRepository: Pick<IProjectRepository, 'renameProject'>;
  readonly loadUserProjectsInfo: () => ThunkPromise;
}

const started = (name: string, projectId: Uuid) =>
  ({
    type: Project_RenameProjectOnServer_Started,
    payload: {
      name,
      projectId,
    },
  }) as const;

const finished = (renamedProject: IProjectServerModel) =>
  ({
    type: Project_RenameProjectOnServer_Finished,
    payload: {
      renamedProject,
    },
  }) as const;

const failed = () =>
  ({
    type: Project_RenameProjectOnServer_Failed,
  }) as const;

export type SaveProjectNameToServerActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createSaveProjectNameToServerAction =
  (deps: IDeps) =>
  (observedState: ProjectObservedState): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();

    const currentMasterEnvironmentId = getCurrentProjectContainer(state).masterEnvironmentId;

    const newName = observedState.projectName || UntitledProjectSurrogateName;
    try {
      dispatch(started(newName, currentMasterEnvironmentId));

      const renamedProject = await deps.projectRepository.renameProject(
        newName,
        currentMasterEnvironmentId,
      );
      await dispatch(deps.loadUserProjectsInfo());

      dispatch(finished(renamedProject));
    } catch {
      dispatch(failed());
    }
  };
