import { IconButton } from '@kontent-ai/component-library/Button';
import { useEffect, useState } from 'react';
import { IntercomUtils } from '../../utils/intercomUtils.ts';

const launcherButtonId = 'intercomLauncher';

export const IntercomLauncherButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  useEffect(() => {
    IntercomUtils.update({
      alignment: 'left',
      horizontal_padding: 68,
      vertical_padding: 20,
      custom_launcher_selector: `#${launcherButtonId}`,
      hide_default_launcher: true,
    });
    IntercomUtils.onUnreadCountChange(setUnreadMessageCount);
    IntercomUtils.onShow(() => setIsChatOpen(true));
    IntercomUtils.onHide(() => setIsChatOpen(false));

    return () => {
      IntercomUtils.update({
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: 20,
        custom_launcher_selector: '',
        hide_default_launcher: false,
      });
    };
  }, []);

  return (
    <IconButton
      id={launcherButtonId}
      activated={isChatOpen}
      badgeValue={isChatOpen ? 0 : unreadMessageCount}
      buttonStyle="secondary"
      iconName="Intercom"
      ariaLabel="Open chat with customer support."
      size="medium"
      tooltipPlacement="right"
      tooltipText={isChatOpen ? 'Close chat' : 'Got any questions? Drop us a line!'}
    />
  );
};
