import React from 'react';
import { ContentItemUsages } from '../../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { Warning } from '../../../../components/infos/Warning.tsx';
import { ContentItemUsagesLinks } from '../../../../containers/ContentItemUsagesLinks/ContentItemUsagesLinks.tsx';
import { useSelector } from '../../../../hooks/useSelector.ts';
import { UnpublishMethodSection } from '../../containers/Elements/UnpublishMethodSection.tsx';
import { ModalSection } from '../ModalSection.tsx';

type UnpublishingSectionProps = {
  readonly contentItemUsages: ContentItemUsages;
  readonly itemIds: ReadonlySet<Uuid>;
};

export const UnpublishingSection: React.FC<UnpublishingSectionProps> = ({
  contentItemUsages,
  itemIds,
}) => {
  const isSelectAllItemsChecked = useSelector(
    (s) => s.contentApp.listingUi.itemsSelection.selectAll,
  );
  const isMoreThanOneItemSelected = itemIds.size > 1;

  return (
    <>
      <ModalSection>
        <UnpublishingSectionWarning
          contentItemUsages={contentItemUsages}
          itemIds={itemIds}
          showWarningForMoreItems={isSelectAllItemsChecked || isMoreThanOneItemSelected}
        />
      </ModalSection>
      <UnpublishMethodSection />
    </>
  );
};

UnpublishingSection.displayName = 'UnpublishingSection';

type UnpublishingSectionWarningProps = {
  readonly contentItemUsages: ContentItemUsages;
  readonly itemIds: ReadonlySet<Uuid>;
  readonly showWarningForMoreItems: boolean;
};

const UnpublishingSectionWarning: React.FC<UnpublishingSectionWarningProps> = ({
  contentItemUsages,
  itemIds,
  showWarningForMoreItems,
}) => {
  if (showWarningForMoreItems) {
    return (
      <Warning>
        Unpublishing the following items may cause <strong>broken links</strong> because they may be
        used in other content items.
      </Warning>
    );
  }

  const itemId = [...itemIds][0];

  if (itemId && !contentItemUsages.get(itemId)?.isEmpty()) {
    return (
      <Warning>
        Unpublishing this item may cause <strong>broken links</strong> because it’s used in other
        content {(contentItemUsages.get(itemId)?.size ?? 0) > 1 ? 'items' : 'item'}:
        <ContentItemUsagesLinks contentItemId={itemId} />
      </Warning>
    );
  }

  return (
    <Warning subtitle="What will happen?">
      <ul>
        <li>Unpublished content won’t be available live.</li>
        <li>The content item will be archived and become read-only.</li>
      </ul>
    </Warning>
  );
};

UnpublishingSectionWarning.displayName = 'UnpublishingSectionWarning';
