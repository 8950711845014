import { combineReducers } from '@reduxjs/toolkit';
import { contentTypesRootReducer } from '../../contentTypes/reducers/contentTypesRoot.ts';
import { snippetsRootReducer } from '../../snippets/reducers/snippetsRoot.ts';
import { activeConditionsBeforeLastSave } from './activeConditionsBeforeLastSave.ts';
import { selectedTab } from './selectedTab.ts';
import { typeEditorReducer } from './typeEditor.ts';

export const contentModelsAppReducer = combineReducers({
  activeConditionsBeforeLastSave,
  contentTypes: contentTypesRootReducer,
  snippets: snippetsRootReducer,
  typeEditor: typeEditorReducer,
  selectedTab,
});
