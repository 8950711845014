import { forwardRef } from 'react';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { DiscardCurrentVersion } from '../../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getEditedContentItemVariant } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { discardNewVersion } from '../../actions/thunkContentItemEditingActions.ts';

export const DiscardNewVersionConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();
  const editedContentItemVariant = useSelector(getEditedContentItemVariant);

  if (!editedContentItemVariant) {
    logError(
      () => 'DiscardNewVersionConfirmationDialog.tsx: Edited content item variant is not set.',
    );
    return null;
  }
  const versionStepName = editedContentItemVariant.assignment.workflowStatus.name;

  return (
    <ConfirmationDialog
      alert
      headerContent={`Discard the ${versionStepName}`}
      confirmButtonText={DiscardCurrentVersion}
      onClose={compose(dispatch, modalDismissed)}
      onConfirm={compose(dispatch, discardNewVersion)}
      ref={ref}
      {...props}
    >
      <p className="confirmation-dialog__section">
        This will return the content to the last <b>Published</b> version.
      </p>
      <p className="confirmation-dialog__section">
        Do you want to discard the selected {versionStepName}?
      </p>
    </ConfirmationDialog>
  );
});
