import { FocusEventHandler, ReactElement, forwardRef } from 'react';
import {
  EmptyObject,
  FieldError,
  FieldPathByValue,
  FieldValues,
  useController,
} from 'react-hook-form';
import { ValidationConstants } from '../../constants/validationConstants.ts';
import { HookFormProps } from '../../types/hookFormProps.type.ts';
import { ClipboardFormField } from '../../uiComponents/Clipboard/ClipboardFormField.tsx';
import { showFieldError } from '../../utils/validation/showFieldError.ts';

type CodenameEditorProps = {
  readonly error: FieldError | undefined;
  readonly onBlur?: FocusEventHandler<HTMLInputElement>;
  readonly onChange: (value: string) => void;
  readonly readOnly?: boolean;
  readonly showError: boolean;
  readonly value: string;
};

export const CodenameEditorBase = forwardRef<HTMLInputElement, CodenameEditorProps>(
  ({ error, onBlur, readOnly, value, showError, onChange }, ref) => {
    const errorMessage = showError ? error?.message : undefined;

    const changeCodename = (codename: string): void => {
      const hasWhiteSpace = /\s/g.test(codename);

      if (!hasWhiteSpace) {
        onChange(codename);
      }
    };

    return (
      <div>
        <ClipboardFormField
          ref={ref}
          inputErrorMessage={errorMessage}
          inputIsDisabled={readOnly}
          inputMaxLength={ValidationConstants.LanguageCodenameMaxLength}
          inputName="codename"
          inputText={value}
          labelText="Codename"
          onInputBlur={onBlur}
          onInputChange={changeCodename}
        />
      </div>
    );
  },
);

CodenameEditorBase.displayName = 'CodenameEditorBase';

type Props<TFormShape extends FieldValues> = {
  readonly formProps: HookFormProps<TFormShape>;
  readonly name: FieldPathByValue<TFormShape, string>;
};

export const CodenameEditor = <TFormShape extends FieldValues = EmptyObject>({
  formProps,
  name,
}: Props<TFormShape>): ReactElement => {
  const { control } = formProps;
  const { field, fieldState, formState } = useController({ control, name });
  const { error } = fieldState;

  return (
    <CodenameEditorBase
      ref={field.ref}
      error={error}
      onBlur={field.onBlur}
      onChange={field.onChange}
      showError={showFieldError(formState, error)}
      value={field.value as string}
    />
  );
};
