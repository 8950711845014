import { isAbortError } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ISpacesRepository } from '../../../../repositories/interfaces/ISpacesRepository.type.ts';
import { ISpaceServerResponseModel } from '../../../../repositories/serverModels/SpacesServerModel.type.ts';
import {
  Data_Spaces_Failed,
  Data_Spaces_Started,
  Data_Spaces_Success,
} from '../../../constants/dataActionTypes.ts';

interface Deps {
  readonly spacesRepository: Pick<ISpacesRepository, 'getAll'>;
}

const start = () => ({ type: Data_Spaces_Started }) as const;

const success = (spaces: ReadonlyArray<ISpaceServerResponseModel>) =>
  ({
    type: Data_Spaces_Success,
    payload: {
      spaces,
    },
  }) as const;

const failure = () => ({ type: Data_Spaces_Failed }) as const;

export type LoadSpacesActionsType = ReturnType<typeof start | typeof success | typeof failure>;

export const createLoadSpacesAction =
  (deps: Deps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());

    try {
      const response = await deps.spacesRepository.getAll(abortSignal);

      validateResponse(response);
      dispatch(success(response));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };

function validateResponse(response: ReadonlyArray<ISpaceServerResponseModel>): asserts response {
  const createErrorMessage = (): string =>
    `${__filename}: invalid spaces server model: ${JSON.stringify(response)}`;

  assert(Array.isArray(response), createErrorMessage);

  response.forEach((space) => {
    assert(typeof space === 'object', createErrorMessage);
  });
}
