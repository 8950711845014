import { chronologically, createCompare } from '@kontent-ai/utils';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IApiKeyListingData } from '../models/ApiKeyListingData.ts';

export const getMapiKeyListingData = (state: IStore): ReadonlyArray<IApiKeyListingData> =>
  [...state.data.apiKeys.mapiListingData].sort(
    createCompare({
      compare: chronologically,
      select: (apiKey) => apiKey.expiresAt,
    }),
  );
