import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ContentItemRevisionCompareRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { closeComparison } from '../actions/thunks/closeComparison.ts';
import { RevisionCompareTimeline as RevisionCompareTimelineComponent } from '../components/RevisionCompareTimeline.tsx';
import { getTimelineItemComparePathsMap } from '../selectors/getTimelineItemComparePathsMap.ts';

export const RevisionCompareTimeline: React.FC = () => {
  const pathParams = useParams<ContentItemRevisionCompareRouteParams<string>>();
  const { compareItemId, timelineItemId, contentItemIds, projectId, app, variantId, spaceId } =
    pathParams;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const entryTimeline = useSelector((s) => s.contentApp.entryTimeline);
  const lastSelectedRevisionId = useSelector((s) => s.contentApp.lastSelectedRevisionId);
  const paths = useMemo(
    () =>
      getTimelineItemComparePathsMap(
        entryTimeline,
        {
          app,
          compareItemId,
          spaceId,
          timelineItemId,
          contentItemIds,
          projectId,
          variantId,
        },
        location.pathname,
        lastSelectedRevisionId,
      ),
    [
      entryTimeline,
      app,
      compareItemId,
      spaceId,
      timelineItemId,
      contentItemIds,
      projectId,
      variantId,
      location.pathname,
      lastSelectedRevisionId,
    ],
  );

  return (
    <RevisionCompareTimelineComponent
      paths={paths}
      isInitialized={!!entryTimeline}
      onCloseComparison={() => dispatch(closeComparison(history, pathParams))}
    />
  );
};

RevisionCompareTimeline.displayName = 'RevisionCompareTimeline';
