import { IUserIdentifier } from './UserIdentifier.ts';

export interface IUserInfo extends IUserIdentifier {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
}

export const unknownUserInfo: IUserInfo = {
  userId: '',
  firstName: '[username',
  lastName: 'redacted]',
  email: '',
};

export const createUnknownUserInfo = (userId: string): IUserInfo => ({
  ...unknownUserInfo,
  userId: userId ?? '',
});
