import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_GuidelinesElement_Collapsed,
  ContentItemEditing_GuidelinesElement_Expanded,
  ContentItemEditing_Init_Started,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlyArray<Uuid> = [];

export function collapsedGuidelinesElementIds(
  state: ReadonlyArray<Uuid> = initialState,
  action: Action,
): ReadonlyArray<Uuid> {
  switch (action.type) {
    case ContentItemEditing_Init_Started: {
      return action.payload.collapsedGuidelines;
    }

    case ContentItemEditing_GuidelinesElement_Collapsed: {
      const newState = [...state];
      newState.push(action.payload.guidelinesElementId);
      return newState;
    }

    case ContentItemEditing_GuidelinesElement_Expanded: {
      return [...state].filter((id) => id !== action.payload.guidelinesElementId);
    }

    default: {
      return state;
    }
  }
}
