export type TypeElementWithConditionValidationResult = {
  readonly conditionValidationResult: TypeElementConditionValidationResult;
};

export type TypeElementConditionValidationResult = {
  readonly optionsError: OptionsError;
  readonly triggerError: TriggerError;
};

export enum OptionsError {
  DeletedOption = 'DeletedOption',
  NoOptionSet = 'NoOptionSet',
  None = 'None',
}

export enum TriggerError {
  DeletedTrigger = 'DeletedTrigger',
  None = 'None',
  TriggerIsNotMultipleChoice = 'TriggerIsNotMultipleChoice',
  TriggerNotSet = 'TriggerNotSet',
}
