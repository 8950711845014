import { Dispatch, GetState } from '../../../../../@types/Dispatcher.type.ts';
import {
  ITaxonomyGroupServerModel,
  ITaxonomyTermServerModel,
} from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  ITaxonomyPatchOperation,
  TaxonomyPatchOperationType,
} from '../../../../../repositories/utils/createTaxonomyPatchDocument.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { AssetLibrary_Folders_Created } from '../../constants/assetLibraryActionTypes.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly patch: (operation: ITaxonomyPatchOperation) => Promise<ITaxonomyGroupServerModel>;
  };
  readonly generateId: () => Uuid;
}

const assetFolderCreated = (taxonomyGroup: ITaxonomyGroupServerModel) =>
  ({
    type: AssetLibrary_Folders_Created,
    payload: {
      taxonomyGroup,
    },
  }) as const;

export type CreateAssetFolder = ReturnType<typeof assetFolderCreated>;

export const createCreateAssetFolderAction =
  (deps: IDeps) => (name: string) => async (dispatch: Dispatch, getState: GetState) => {
    const {
      assetLibraryApp: { openedFolderId },
    } = getState();

    const termModel: ITaxonomyTermServerModel = {
      id: deps.generateId(),
      name,
      children: null,
      codeName: null,
      lastModified: null,
      lastModifiedBy: null,
    };

    const operation: ITaxonomyPatchOperation = {
      operation: TaxonomyPatchOperationType.Add,
      reference: openedFolderId === RootFolderId ? null : openedFolderId,
      value: {
        node: termModel,
      },
    };

    const taxonomyGroup = await deps.assetFolderRepository.patch(operation);

    dispatch(assetFolderCreated(taxonomyGroup));
  };
