import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { TooltipPropsExtension } from '@kontent-ai/component-library/component-utils';
import classNames from 'classnames';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { DragAction } from '../../DragDrop/DragAction.tsx';

interface IBarItemDragActionProps extends TooltipPropsExtension {
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled?: boolean;
  readonly isCompact?: boolean;
}

export const BarItemDragAction: React.FC<IBarItemDragActionProps> = ({
  connectDragSource,
  disabled,
  isCompact,
  tooltipMaxGridUnitsWidth,
  tooltipPlacement = 'top',
  tooltipShortcuts,
  tooltipText,
}) => (
  <Tooltip
    maxGridUnitsWidth={tooltipMaxGridUnitsWidth}
    placement={tooltipPlacement}
    shortcuts={tooltipShortcuts}
    tooltipText={tooltipText}
  >
    <DragAction
      connectDragSource={connectDragSource}
      className={classNames('bar-item__action', { 'bar-item__action--is-compact': isCompact })}
      disabled={disabled}
    />
  </Tooltip>
);

BarItemDragAction.displayName = 'BarItemDragAction';
