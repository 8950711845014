import { DatetimeElementValidationResult } from '../types/DatetimeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const DateTimeInitialValidationResult: DatetimeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  errorMessages: [],
  isDefaultValueValid: true,
  isGuidelinesValid: true,
  isNameValid: true,
};
