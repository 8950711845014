import { memoize } from '@kontent-ai/memoization';
import { add } from 'date-fns';
import { ICurrentUserInfo } from '../../data/models/user/CurrentUserInfo.ts';
import { IUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import { isProjectTrial } from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  parseDatetime,
  shiftByUTCOffsetForward,
} from '../components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isGettingStartedProject, isSampleProject } from './sampleItemsUtils.ts';
import { isEmployee } from './subscriptionUsageUtils.ts';

function hasAccountLessDaysThan(numberOfDays: number, userInfo: ICurrentUserInfo): boolean {
  const { isValid, value } = parseDatetime(userInfo.createdAt);
  if (!isValid) {
    return false;
  }

  return add(shiftByUTCOffsetForward(value), { days: numberOfDays }) > new Date();
}

export const isAccountLessThan30DaysOld = memoize.maxAge(
  (userInfo: ICurrentUserInfo): boolean => hasAccountLessDaysThan(30, userInfo),
  // Cache for 1 hour, so it can still change if the user account gets older while staying in the browser
  60 * 60 * 1000,
  { maxSize: 1 },
);

const projectIsOneOfSampleProjects = (project: IUserProjectInfo) => {
  return isSampleProject(project) || isGettingStartedProject(project);
};

export function projectHasQuickstart(state: IStore, project: IUserProjectInfo): boolean {
  const {
    data: { user },
  } = state;

  return (
    projectIsOneOfSampleProjects(project) &&
    (isProjectTrial(state, project.projectId) ||
      isAccountLessThan30DaysOld(user.info) ||
      isEmployee(user.info.email))
  );
}
