import { memoize } from '@kontent-ai/memoization';
import { Collection, notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IMultipleChoiceOptionData } from '../../../../_shared/models/MultipleChoiceOption.type.ts';
import { filterOutNullish } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { isSnippetTypeElement } from '../types/typeElementTypeGuards.ts';
import { getComposedElementCodename } from './typeElementCodenameComposer.ts';

const getContentTypeAndSnippetCodenames = (
  contentTypesById: Immutable.Map<Uuid, IContentType>,
  snippetsById: Immutable.Map<Uuid, IContentTypeSnippet>,
): ReadonlySet<string> => {
  const contentTypeCodenames: ReadonlyArray<string> = contentTypesById
    .toArray()
    .map((contentType: IContentType) => contentType.codename)
    .filter((codename): codename is string => codename !== null);

  const snippetsCodenames: ReadonlyArray<string> = snippetsById
    .toArray()
    .map((snippet: IContentTypeSnippet) => snippet.codename)
    .filter((codename): codename is string => codename !== null);

  return new Set(contentTypeCodenames.concat(snippetsCodenames));
};

export const getTypeCodenamesWithoutEditedContentTypeCodename = memoize.maxOne(
  (
    contentTypesById: Immutable.Map<Uuid, IContentType>,
    snippetsById: Immutable.Map<Uuid, IContentTypeSnippet>,
    editedContentTypeId: Uuid,
  ): ReadonlySet<string> => {
    const codenames = getContentTypeAndSnippetCodenames(contentTypesById, snippetsById);
    const editedContentType = contentTypesById.get(editedContentTypeId);

    if (!!editedContentType && editedContentType.codename) {
      Collection.remove(codenames, editedContentType.codename);
    }

    return codenames;
  },
);

export const getTypeCodenamesWithoutEditedSnippetCodename = memoize.maxOne(
  (
    contentTypesById: Immutable.Map<Uuid, IContentType>,
    snippetsById: Immutable.Map<Uuid, IContentTypeSnippet>,
    editedSnippetId: Uuid,
  ): ReadonlySet<string> => {
    const codenames = getContentTypeAndSnippetCodenames(contentTypesById, snippetsById);
    const editedSnippet = snippetsById.get(editedSnippetId);

    if (!!editedSnippet && editedSnippet.codename) {
      return Collection.remove(codenames, editedSnippet.codename);
    }

    return codenames;
  },
);

const getElementCodenames = memoize.maxOne(
  (editedTypeElements: ReadonlyArray<IBaseTypeElementData>): Immutable.Set<string> => {
    const codenames = filterOutNullish(
      editedTypeElements.map((typeElement) => typeElement.codename),
    );
    return Immutable.Set(codenames);
  },
);

const getElementCodenamesFromSnippet = (snippet: IContentTypeSnippet): Immutable.Set<string> => {
  return snippet.typeElements.reduce(
    (snippetElementCodenames: Immutable.Set<string>, typeElement: IBaseTypeElementData) => {
      const codename = getComposedElementCodename(typeElement.codename, snippet.codename);
      return codename ? snippetElementCodenames.add(codename) : snippetElementCodenames;
    },
    Immutable.Set<string>(),
  );
};

const getElementCodenamesFromSnippets = memoize.weak(
  (
    editedTypeElements: ReadonlyArray<IBaseTypeElementData>,
    snippets: Immutable.Map<Uuid, IContentTypeSnippet>,
  ): Immutable.Set<string> => {
    const usedSnippetsIds = editedTypeElements
      .filter(isSnippetTypeElement)
      .map((element) => element.snippetId);
    const usedSnippets = snippets.filter((snippet: IContentTypeSnippet) =>
      usedSnippetsIds.includes(snippet.id),
    );
    return usedSnippets.reduce((codenames: Immutable.Set<string>, snippet: IContentTypeSnippet) => {
      return codenames.union(getElementCodenamesFromSnippet(snippet));
    }, Immutable.Set<string>());
  },
);

export const getElementCodenamesWithoutEditedCodename = memoize.weak(
  (
    editedTypeElements: ReadonlyArray<IBaseTypeElementData>,
    editedElementId: Uuid,
    snippets?: Immutable.Map<Uuid, IContentTypeSnippet>,
  ): ReadonlySet<string> => {
    const codenames = getElementCodenames(editedTypeElements);
    const editedElement = editedTypeElements.find((el) => el.elementId === editedElementId);
    const codenamesWithoutEditedElementCodename = editedElement?.codename
      ? codenames.remove(editedElement.codename)
      : codenames;
    const usedSnippetElementCodenames = snippets
      ? getElementCodenamesFromSnippets(editedTypeElements, snippets)
      : Immutable.Set.of<string>();

    return new Set(
      codenamesWithoutEditedElementCodename.union(usedSnippetElementCodenames).toArray(),
    );
  },
);

export const getMultipleChoiceOptionCodenames = memoize.weak(
  (options: Dictionary<IMultipleChoiceOptionData>): ReadonlySet<string> =>
    new Set(
      Object.values(options)
        .map((o) => o?.codename ?? null)
        .filter(notNull),
    ),
);

export const isCodenameUnique = (
  codename: string | null,
  relatedCodenames?: ReadonlySet<string>,
): boolean => codename !== null && !!relatedCodenames && !relatedCodenames.has(codename);

export const isCodenameValid = (codename: string | null): boolean => {
  if (codename === null) {
    return false;
  }
  const codenameRegex = /^[_a-z]+[_a-z0-9]*$/;
  return codenameRegex.test(codename);
};
