import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { distinctFilter } from '../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { IUiPreferenceRepository } from '../../../../../../repositories/interfaces/IUiPreferenceRepository.type.ts';
import { UiPreference } from '../../../../../../repositories/uiPreferenceRepository.ts';

type Deps = {
  readonly getCurrentPreference: (state: IStore) => ReadonlyArray<Uuid> | null;
  readonly uiPreference: UiPreference;
  readonly uiPreferenceRepository: IUiPreferenceRepository;
  readonly updateNewPreference: (preference: ReadonlyArray<Uuid>) => Action;
};

export const createUpdateNewContentItemUiPreferenceAction =
  ({ getCurrentPreference, uiPreference, uiPreferenceRepository, updateNewPreference }: Deps) =>
  (newPreferenceItem: string): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const currentPreference = getCurrentPreference(getState()) ?? [];
    const newPreference = [newPreferenceItem]
      .concat(currentPreference)
      .filter(distinctFilter)
      .slice(0, 3);

    await Promise.all([
      uiPreferenceRepository.upsertOne(uiPreference, JSON.stringify(newPreference)),
      dispatch(updateNewPreference(newPreference)),
    ]);
  };
