import {
  ContentItemAccessDeniedRoute,
  ContentItemAccessDeniedRouteParams,
  ContentItemPreviewWithEditorAccessDeniedRoute,
  ContentItemPreviewWithEditorAccessDeniedRouteParams,
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { AccessDeniedToContentItemAction } from '../../../errorHandling/constants/AccessDeniedToContentItemAction.ts';
import { getSmartLinkTarget } from '../../../smartLink/utils/smartLinkUtils.ts';

export const getAccessDeniedToContentItemRoute = (
  projectId: Uuid,
  variantId: Uuid,
  contentItemId: Uuid,
  requestedAction: AccessDeniedToContentItemAction,
  currentPath: string,
) => {
  // Floating editor route keeps the context in the floating editor
  const floatingEditorMatch = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
    currentPath,
    ContentItemPreviewWithEditorRoute,
  );
  if (
    floatingEditorMatch &&
    floatingEditorMatch.editedItemId === contentItemId &&
    floatingEditorMatch.variantId === variantId &&
    floatingEditorMatch.projectId === projectId
  ) {
    return buildPath<ContentItemPreviewWithEditorAccessDeniedRouteParams<UuidArray>>(
      ContentItemPreviewWithEditorAccessDeniedRoute,
      {
        app: floatingEditorMatch.app,
        projectId: floatingEditorMatch.projectId,
        variantId: floatingEditorMatch.variantId,
        spaceId: floatingEditorMatch.spaceId,
        contentItemIds: parseContentItemIds(floatingEditorMatch.contentItemIds),
        editedItemId: floatingEditorMatch.editedItemId,
        requestedAction,
      },
    );
  }

  // Other routes treat the route with the standard smart link handling (landing at the top most item when needed)
  const target = getSmartLinkTarget<ContentItemAccessDeniedRouteParams<UuidArray>>(
    projectId,
    variantId,
    contentItemId,
    currentPath,
    ContentItemAccessDeniedRoute,
    { requestedAction },
  );
  return target.path;
};
