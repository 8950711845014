import { Collection } from '@kontent-ai/utils';
import { useCallback, useEffect, useRef } from 'react';
import { itemEditorOperationIds } from '../../../../applications/contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { contentItemAiActionFinished, contentItemAiActionStarted } from '../actions/aiActions.ts';

// Use to register pending AI action with item editor to prevent leaving it while AI action is running
// Note there are more specific variants usePendingAiActionWithSession and usePendingAiActionWithCallback with smaller boilerplate for typical scenarios
export const usePendingAiAction = (): {
  readonly finishPendingAiAction: (aiSessionId: Uuid) => void;
  readonly startPendingAiAction: (aiSessionId: Uuid) => void;
} => {
  const pendingAiSessionIds = useRef<ReadonlySet<Uuid>>(new Set());
  const dispatch = useDispatch();

  const startPendingAiAction = useCallback((aiSessionId: Uuid) => {
    pendingAiSessionIds.current = Collection.add(pendingAiSessionIds.current, aiSessionId);
    dispatch(contentItemAiActionStarted(itemEditorOperationIds.aiOperation(aiSessionId)));
  }, []);

  const finishPendingAiAction = useCallback((aiSessionId: Uuid) => {
    if (pendingAiSessionIds.current.has(aiSessionId)) {
      Collection.remove(pendingAiSessionIds.current, aiSessionId);
      dispatch(contentItemAiActionFinished(itemEditorOperationIds.aiOperation(aiSessionId)));
    }
  }, []);

  useEffect(
    () => () =>
      pendingAiSessionIds.current.forEach((aiSessionId) => finishPendingAiAction(aiSessionId)),
    [finishPendingAiAction],
  );

  return {
    startPendingAiAction,
    finishPendingAiAction,
  };
};
