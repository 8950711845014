import { Action } from '../../../../../@types/Action.type.ts';
import { Notifications } from '../../../../../_shared/services/signalR/signalRClient.type.ts';
import {
  ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted,
  ContentItemEditing_LockedElements_Changed,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

type LockedElementSessions =
  Notifications['lockedElementsChange']['payload']['lockedElementSessions'];

const initialState: LockedElementSessions = [];

export function lockedElements(
  state: LockedElementSessions = initialState,
  action: Action,
): LockedElementSessions {
  switch (action.type) {
    case ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted: {
      return initialState;
    }

    case ContentItemEditing_LockedElements_Changed: {
      return action.payload.lockedElements;
    }

    default:
      return state;
  }
}
