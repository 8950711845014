import { notNullNorUndefined } from '@kontent-ai/utils';
import React from 'react';
import { PortalPlaceholder } from '../../../../../../_shared/components/PortalPlaceholder.tsx';

export const getAiReviewButtonPlaceholderId = (
  elementId: Uuid,
  contentComponentId?: Uuid | null,
): string => `ai_review_${[contentComponentId, elementId].filter(notNullNorUndefined).join('_')}`;

export const AiReviewButtonPlaceholder: React.FC<{
  readonly contentComponentId?: Uuid | null;
  readonly elementId: Uuid;
}> = ({ contentComponentId, elementId }) => (
  <PortalPlaceholder id={getAiReviewButtonPlaceholderId(elementId, contentComponentId)} />
);
