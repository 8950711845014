import styled from 'styled-components';
import { colorIconDefaultInverse } from '../../../tokens/decision/colors.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { gridUnit } from '../../../tokens/quarks/spacing.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Icons } from '../../Icons/components/icons.ts';

export const StyledToggleIcon = styled(Icons.CbCheckSign).attrs({
  size: IconSize.S,
  color: colorIconDefaultInverse,
  screenReaderText: 'Toggle ON',
})` 
  position: absolute;
  left: ${px(0.25 * gridUnit)};
`;
