import Immutable from 'immutable';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  AutoScrollId,
  AutoScrollTargetType,
  ComponentElementAutoScrollId,
  ContentComponentAutoScrollId,
  ElementAutoScrollId,
  isComponentElementAutoScrollId,
  isContentComponentAutoScrollId,
  isElementAutoScrollId,
} from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import {
  IElementPathItem,
  IFindElementResult,
  traverseFindElement,
  traverseFindElementAsync,
} from '../../../../../richText/plugins/contentComponents/api/traverseComponentUtils.ts';
import { IEditedContentItem } from '../../../../models/contentItem/edited/EditedContentItem.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { AssetReference } from '../../../../models/contentItemElements/AssetItemElement.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  getAssetReferencesInElementWithoutNested,
  getReferencedContentItemIdsInElementWithoutNested,
} from '../../../../utils/itemReferences/itemElementReferencesUtils.ts';
import { getDeliveryContentComponentId } from '../../../ContentComponent/utils/deliveryContentComponentUtils.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import { ISelectContentGroupForElement } from './selectContentGroupForElement.ts';
import { ISelectContentGroupsByElementPath } from './selectContentGroupsByElementPath.ts';

interface IDeps {
  readonly selectContentGroupForElement: ISelectContentGroupForElement;
  readonly selectContentGroupsByElementPath: ISelectContentGroupsByElementPath;
}

function getElementId(
  loadedContentItemTypes: Immutable.Map<string, ICompiledContentType>,
  contentTypeId: Uuid,
  elementCodename: string,
) {
  const contentItemType = loadedContentItemTypes.get(contentTypeId);
  if (contentItemType) {
    const element = contentItemType.contentElements.find((e) => e.codename === elementCodename);
    return element ? element.elementId : null;
  }
  return null;
}

const getElementIdForElementAutoScrollId = (
  autoScrollId: ElementAutoScrollId,
  contentItem: IEditedContentItem,
  loadedContentItemTypes: Immutable.Map<string, ICompiledContentType>,
): Uuid | null => {
  if (autoScrollId.contentItemId === contentItem.id) {
    return getElementId(
      loadedContentItemTypes,
      contentItem.editedContentItemTypeId,
      autoScrollId.elementCodename,
    );
  }
  return null;
};

const findElementForReferenceAutoScrollId = (
  editedContentItem: IEditedContentItem,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  scrollId: AutoScrollId,
): IFindElementResult => {
  return traverseFindElement(editedContentItem, variantElements, (element) => {
    switch (scrollId.targetType) {
      case AutoScrollTargetType.Asset:
        return !!getAssetReferencesInElementWithoutNested(element).find(
          (assetReference: AssetReference) => assetReference.id === scrollId.key,
        );

      case AutoScrollTargetType.ContentItem:
        return getReferencedContentItemIdsInElementWithoutNested(element).contains(scrollId.key);

      default:
        return false;
    }
  });
};

export const findElementForComponentElementAutoScrollId = async (
  autoScrollId: ComponentElementAutoScrollId,
  editedContentItem: IEditedContentItem,
  editedContentItemVariant: EditedContentItemVariant,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
  loadedContentItemTypes: Immutable.Map<string, ICompiledContentType>,
): Promise<IFindElementResult> => {
  return await traverseFindElementAsync(
    editedContentItem,
    variantElements,
    async (
      element: ICompiledContentItemElementData,
      currentPath: Immutable.List<IElementPathItem>,
    ) => {
      const rootElementId = currentPath.first()?.elementId;
      const lastPathItem = currentPath.last();
      if (currentPath.size <= 1 || !rootElementId || !lastPathItem) {
        return false;
      }

      const contentComponentId = lastPathItem.itemId;

      const elementId = getElementId(
        loadedContentItemTypes,
        lastPathItem.typeId,
        autoScrollId.elementCodename,
      );
      if (element.elementId !== elementId) {
        return false;
      }

      const deliveryComponentId = await getDeliveryContentComponentId(
        editedContentItem.id,
        editedContentItemVariant.id.variantId,
        rootElementId,
        contentComponentId,
      );
      return autoScrollId.deliveryContentComponentId === deliveryComponentId;
    },
  );
};

export const findContentComponentForAutoScrollId = async (
  autoScrollId: ContentComponentAutoScrollId,
  editedContentItem: IEditedContentItem,
  editedContentItemVariant: EditedContentItemVariant,
  variantElements: ReadonlyArray<ICompiledContentItemElementData>,
): Promise<IFindElementResult> => {
  return await traverseFindElementAsync(
    editedContentItem,
    variantElements,
    async (
      _element: ICompiledContentItemElementData,
      currentPath: Immutable.List<IElementPathItem>,
    ) => {
      const rootElementId = currentPath.first()?.elementId;
      const contentComponentId = currentPath.last()?.itemId;
      if (currentPath.size <= 1 || !rootElementId || !contentComponentId) {
        return false;
      }

      const deliveryComponentId = await getDeliveryContentComponentId(
        editedContentItem.id,
        editedContentItemVariant.id.variantId,
        rootElementId,
        contentComponentId,
      );
      return autoScrollId.deliveryContentComponentId === deliveryComponentId;
    },
  );
};

export const createSelectContentGroupForAutoScrollAction =
  (deps: IDeps) =>
  (currentPath: string): ThunkFunction =>
  async (dispatch, getState) => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        editedContentItemVariantElements,
        loadedContentItemTypes,
      },
      sharedApp: { scrollIdByPath },
    } = getState();

    const autoScrollId = scrollIdByPath.get(currentPath);
    if (autoScrollId && editedContentItem && editedContentItemVariant) {
      if (isElementAutoScrollId(autoScrollId)) {
        const elementId = getElementIdForElementAutoScrollId(
          autoScrollId,
          editedContentItem,
          loadedContentItemTypes,
        );
        if (elementId) {
          dispatch(
            deps.selectContentGroupForElement(
              ContentGroupSelectionReason.AutoScroll,
              elementId,
              undefined,
            ),
          );
        }
      } else if (isComponentElementAutoScrollId(autoScrollId)) {
        const findElementResult = await findElementForComponentElementAutoScrollId(
          autoScrollId,
          editedContentItem,
          editedContentItemVariant,
          editedContentItemVariantElements,
          loadedContentItemTypes,
        );
        if (findElementResult.found) {
          dispatch(
            deps.selectContentGroupsByElementPath(
              ContentGroupSelectionReason.AutoScroll,
              findElementResult.path,
            ),
          );
        }
      } else if (isContentComponentAutoScrollId(autoScrollId)) {
        const findElementResult = await findContentComponentForAutoScrollId(
          autoScrollId,
          editedContentItem,
          editedContentItemVariant,
          editedContentItemVariantElements,
        );
        if (findElementResult.found) {
          dispatch(
            deps.selectContentGroupsByElementPath(
              ContentGroupSelectionReason.AutoScroll,
              findElementResult.path,
            ),
          );
        }
      } else {
        const findElementResult = findElementForReferenceAutoScrollId(
          editedContentItem,
          editedContentItemVariantElements,
          autoScrollId,
        );
        if (findElementResult.found) {
          dispatch(
            deps.selectContentGroupsByElementPath(
              ContentGroupSelectionReason.AutoScroll,
              findElementResult.path,
            ),
          );
        }
      }
    }
  };
