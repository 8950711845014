import { NumberValue, createNumberValue } from '../../../../../_shared/models/NumberValue.ts';
import { NumberTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyBaseTypeElementData,
  IBaseTypeElementData,
  getBaseTypeElementDataFromServerModel,
} from './types/TypeElementData.ts';
import { TypeElementConfigurability } from './types/elementConfigurabilityOptions.ts';

export interface INumberTypeElementData extends IBaseTypeElementData {
  readonly defaultValue: NumberValue;
}

export const EmptyNumberTypeElementData: INumberTypeElementData = {
  ...EmptyBaseTypeElementData,
  _configurability: TypeElementConfigurability.Recommended,
  defaultValue: createNumberValue(),
  type: ElementType.Number,
};

export function getNumberTypeElementDataFromServerModel(
  serverModel: NumberTypeElementServerModel,
): INumberTypeElementData {
  return {
    ...getBaseTypeElementDataFromServerModel(serverModel),
    _configurability: EmptyNumberTypeElementData._configurability,
    defaultValue: createNumberValue({
      value: serverModel.default?.global.value.toString() ?? '',
    }),
    type: ElementType.Number,
  };
}
