import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { IPublishWarnings } from '../../../../../itemEditor/models/PublishWarnings.ts';
import { ContentListing_CheckPublishWarnings_Finished } from '../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

const initial: IPublishWarnings = {
  itemsWithBrokenLinks: Immutable.Set(),
  itemsWithBrokenModulars: Immutable.Set(),
};

export function publishWarnings(
  state: IPublishWarnings = initial,
  action: Action,
): IPublishWarnings {
  switch (action.type) {
    case ContentListing_CheckPublishWarnings_Finished:
      return action.payload.publishWarnings;

    default:
      return state;
  }
}
