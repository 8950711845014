import { memoize } from '@kontent-ai/memoization';
import { ContentBlock, EditorState } from 'draft-js';
import Immutable from 'immutable';
import { filterOutNullish } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  AssetValidationData,
  getAssetDataForValidation,
} from '../../../../../_shared/utils/assets/assetValidationUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { getImageAssetReference } from '../../../../richText/plugins/images/api/editorImageUtils.ts';
import { isImage } from '../../../../richText/utils/blocks/blockTypeUtils.ts';
import { getBlocks } from '../../../../richText/utils/general/editorContentGetters.ts';

export type GetInlineImagesUsedInEditorState = (
  editorState: EditorState,
  loadedEntries: Immutable.Map<Uuid, IAsset>,
  predicate?: (block: ContentBlock) => boolean,
) => ReadonlyArray<AssetValidationData>;

export const getInlineImagesUsedInEditorState: GetInlineImagesUsedInEditorState = memoize.weak(
  (
    editorState: EditorState,
    loadedAssets: Immutable.Map<Uuid, IAsset>,
    predicate?: (block: ContentBlock) => boolean,
  ): ReadonlyArray<AssetValidationData> => {
    const content = editorState.getCurrentContent();
    const blocks = getBlocks(content);

    return filterOutNullish(
      blocks
        .filter((block) => isImage(block) && (!predicate || predicate(block)))
        .map((block) => {
          const assetReference = getImageAssetReference(block);
          return assetReference && loadedAssets.get(assetReference.id);
        }),
    ).map((asset) => getAssetDataForValidation(asset));
  },
);
