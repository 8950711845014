import React, { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-restricted-paths
import { useLayoutSynchronizationUpdateTick } from '../../../../client/app/_shared/contexts/LayoutSynchronizationContext.tsx';
import { getDataUiComponentAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { OverlayPortalContainer } from '../utils/OverlayPortalContainer.tsx';
import { StyledClippedOverlay } from './components/StyledClippedOverlay.tsx';

interface IClippedOverlayProps {
  readonly onTargetBoundingClientRectUpdate?: () => void;
  readonly visible: boolean;
  readonly targetRef: RefObject<HTMLElement | null | undefined>;
  readonly children?: never;
}

const areRectanglesTheSame = (a: DOMRectReadOnly | null, b: DOMRectReadOnly | null): boolean =>
  (!a && !b) ||
  (!!a &&
    !!b &&
    a.top === b.bottom &&
    b.top === a.bottom &&
    a.left === b.right &&
    b.left === a.right);

function useBoundingClientRect(ref: React.RefObject<HTMLElement | null | undefined>) {
  const [rect, setRect] = useState<DOMRect | null>(null);

  const updateRect = useCallback(() => {
    const newRect = ref?.current?.getBoundingClientRect() ?? null;
    setRect((current) => (areRectanglesTheSame(newRect, current) ? current : newRect));
  }, [ref]);

  // Initial load
  useLayoutEffect(() => {
    updateRect();
  }, [updateRect]);

  // Slightly delayed update in case layout around changes and doesn't influence rendering of the target - covers all previously uncovered changes
  // Note: If there is a need to have more synchronous updates upon window resize or scroll, just attach updateRect to respective events
  useLayoutSynchronizationUpdateTick(updateRect);

  return rect;
}

export const ClippedOverlay = React.forwardRef<HTMLDivElement, IClippedOverlayProps>(
  (props, forwardedRef) => {
    const { onTargetBoundingClientRectUpdate, targetRef, visible } = props;

    const rect = useBoundingClientRect(targetRef);

    const onTargetBoundingClientRectUpdateRef = useRef(onTargetBoundingClientRectUpdate);
    onTargetBoundingClientRectUpdateRef.current = onTargetBoundingClientRectUpdate;

    useLayoutEffect(() => {
      if (rect) {
        onTargetBoundingClientRectUpdateRef.current?.();
      }
    }, [rect]);

    if (visible && rect) {
      return (
        <OverlayPortalContainer>
          <StyledClippedOverlay
            ref={forwardedRef}
            rect={rect}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            {...getDataUiComponentAttribute(ClippedOverlay)}
          />
        </OverlayPortalContainer>
      );
    }

    return null;
  },
);

ClippedOverlay.displayName = 'ClippedOverlay';
