import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getMemoizedContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getListingContentItem } from '../../../../../_shared/selectors/getListingContentItem.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { isDefaultVariantRequiredForPublish } from '../../ContentItemEditing/utils/itemValidationUtils.ts';
import { DefaultVariantRequiredTooltip } from '../constants/uiConstants.ts';
import { getCannotPublishReasonForContext } from '../utils/getCannotPublishReason.ts';
import { getSelectedItems } from './getSelectedItems.ts';

export const getTransitiveDependencyRequiredMessage = (
  state: IStore,
  contentItemId: ContentItemId,
): string | null => {
  const { itemId, variantId } = contentItemId;
  const selectedLanguageId = getSelectedLanguageId(state);
  const item = getListingContentItem(state, contentItemId);

  if (!selectedLanguageId || !item) {
    return null;
  }

  const itemType = state.contentApp.loadedContentItemTypes.get(item.item.typeId);
  if (!itemType) {
    return null;
  }

  const selectedLanguageName = getSelectedLanguageNameOrPlaceholder(state);
  const editingDefaultVariant = selectedLanguageId === DefaultVariantId;
  const nonDefaultContentItemId = getMemoizedContentItemId(itemId, selectedLanguageId);

  const isDefaultVariant = variantId === DefaultVariantId;
  if (!isDefaultVariant) {
    return null;
  }

  const isNonDefaultSelected = getSelectedItems(
    state,
    getCannotPublishReasonForContext(state.sharedApp.modalDialog.type),
  ).includes(nonDefaultContentItemId);
  if (!isNonDefaultSelected) {
    return null;
  }

  const canViewDefaultVariant = hasActiveVariantCapability(ActiveCapabilityType.ViewContent, item);
  const isRequired = isDefaultVariantRequiredForPublish(
    itemType,
    editingDefaultVariant,
    canViewDefaultVariant,
    item,
  );

  return isRequired ? DefaultVariantRequiredTooltip(selectedLanguageName) : null;
};
