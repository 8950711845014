import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_NotificationBar_Close } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  ApiKeyDetail_Alert_Dismissed,
  ApiKeyDetail_Creating_Failed,
  ApiKeyDetail_Creating_Started,
  ApiKeyDetail_Loading_Failed,
  ApiKeyDetail_Loading_Started,
  ApiKeyDetail_Regeneration_Failed,
  ApiKeyDetail_Regeneration_Started,
  ApiKeyDetail_Revoke_Failed,
  ApiKeyDetail_Revoke_Started,
  ApiKeyDetail_SavingInterrupted_InvalidForm,
  ApiKeyDetail_Updating_Failed,
  ApiKeyDetail_Updating_Started,
  NewApiKeyDetail_Loading_Started,
} from '../../constants/apiKeysActionTypes.ts';
import { ApiKeyActionStatus } from '../IApiKeysAppStoreState.type.ts';

export const detailActionStatus = (
  state: ApiKeyActionStatus = ApiKeyActionStatus.None,
  action: Action,
): ApiKeyActionStatus => {
  switch (action.type) {
    case ApiKeyDetail_Regeneration_Failed:
      return ApiKeyActionStatus.RegenerationFailed;

    case ApiKeyDetail_Revoke_Failed:
      return ApiKeyActionStatus.RevokingFailed;

    case ApiKeyDetail_Updating_Failed:
    case ApiKeyDetail_Creating_Failed:
      return ApiKeyActionStatus.SavingFailed;

    case ApiKeyDetail_Loading_Failed:
      return ApiKeyActionStatus.LoadingFailed;

    case ApiKeyDetail_SavingInterrupted_InvalidForm:
      return ApiKeyActionStatus.SavingInterrupted;

    case ApiKeyDetail_Alert_Dismissed:
    case ApiKeyDetail_Loading_Started:
    case NewApiKeyDetail_Loading_Started:
    case ApiKeyDetail_Regeneration_Started:
    case ApiKeyDetail_Revoke_Started:
    case ApiKeyDetail_Updating_Started:
    case ApiKeyDetail_Creating_Started:
    case Shared_NotificationBar_Close:
      return ApiKeyActionStatus.None;
    default:
      return state;
  }
};
