import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import { IContentItemVariantServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { convertElementsToDomainModel } from '../../stores/utils/contentItemHelperMethods.ts';
import { ICompiledContentItemElementData } from '../contentItemElements/ICompiledContentItemElement.type.ts';
import { IAssignment, emptyAssignment, getAssignmentFromServerModel } from './Assignment.ts';

export interface IContentItemVariant {
  readonly id: ContentItemId;
  readonly elements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly contentLastUpdatedAt: DateTimeStamp | null;
  readonly contentLastUpdatedBy: string;
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly lastPublishedBy: string;
  readonly publishingState: PublishingState;
  readonly assignment: IAssignment;
  readonly isArchived: boolean;
  readonly isComplete: boolean;
}

export function mapContentItemVariantFromServerModel(
  serverModel: IContentItemVariantServerModel,
): IContentItemVariant {
  const {
    id,
    contentElements,
    contentLastUpdated,
    contentLastUpdatedBy,
    lastPublishedAt,
    lastPublishedBy,
    publishingState,
    assignment,
    archived,
    isComplete,
  } = serverModel;
  const assignmentRecord = assignment ? getAssignmentFromServerModel(assignment) : emptyAssignment;

  return {
    id: {
      itemId: id.itemId,
      variantId: id.variantId,
    },
    elements: convertElementsToDomainModel(contentElements),
    contentLastUpdatedAt: contentLastUpdated,
    contentLastUpdatedBy,
    lastPublishedAt,
    lastPublishedBy,
    publishingState,
    assignment: assignmentRecord,
    isArchived: archived,
    isComplete,
  };
}
