import { Action } from '../../../../../../@types/Action.type.ts';
import { NewContentItem_Initialized } from '../../constants/newContentItemActionTypes.ts';
import { ICollectionConfig } from '../INewContentItemStoreState.type.ts';

const initialState: ICollectionConfig = {
  recentlyUsedCollectionIds: null,
  suggestedCollectionIds: [],
};

export const collection = (state = initialState, action: Action): ICollectionConfig => {
  switch (action.type) {
    case NewContentItem_Initialized:
      return action.payload.collectionSelectorConfig;

    default:
      return state;
  }
};
