import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  AuditLog_Listing_Left,
  AuditLog_Search_Started,
} from '../../../../applications/environmentSettings/auditLog/constants/auditLogActionTypes.ts';
import {
  Data_AuditEvents_Failed,
  Data_AuditEvents_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IAuditEvent,
  createAuditEventsFromServerModel,
} from '../../../models/auditLog/AuditEvent.ts';

const initialState: Immutable.List<IAuditEvent> = Immutable.List();

export function events(
  state: Immutable.List<IAuditEvent> = initialState,
  action: Action,
): Immutable.List<IAuditEvent> {
  switch (action.type) {
    case Data_AuditEvents_Failed:
    case AuditLog_Listing_Left:
    case AuditLog_Search_Started:
      return initialState;

    case Data_AuditEvents_Success: {
      const auditEvents = createAuditEventsFromServerModel(action.payload).auditEvents;

      return auditEvents.reduce((list: Immutable.List<IAuditEvent>, auditEvent: IAuditEvent) => {
        return list.push(auditEvent);
      }, state);
    }

    default:
      return state;
  }
}
