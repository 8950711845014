import { Button } from '@kontent-ai/component-library/Button';
import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { modalWithPropertiesOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { ApiKeyDetailRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ApiKeyDetailToolbarActions as ApiKeyDetailToolbarActionsComponent } from '../../components/ApiKeyDetail/ApiKeyDetailToolbarActions.tsx';
import { canRevokeApiKey } from '../../selectors/canRevokeApiKey.ts';

export const ApiKeyDetailToolbarActions: React.FC = () => {
  const dispatch = useDispatch();
  const canRevokeKey = useSelector(canRevokeApiKey);
  const { tokenSeedId } = useParams<ApiKeyDetailRouteParams>();
  const revoke = useCallback(
    () =>
      dispatch(
        modalWithPropertiesOpened({
          dialogType: ModalDialogType.RevokeApiKeyDialog,
          tokenSeedId,
        }),
      ),
    [tokenSeedId],
  );

  const renderRevokeButton = () =>
    canRevokeKey && !!tokenSeedId ? (
      <Button
        buttonStyle="secondary"
        onClick={revoke}
        destructive
        {...getDataUiActionAttribute(DataUiAction.Revoke)}
      >
        Revoke
      </Button>
    ) : null;

  return <ApiKeyDetailToolbarActionsComponent renderRevokeButton={renderRevokeButton} />;
};

ApiKeyDetailToolbarActions.displayName = 'ApiKeyDetailToolbarActions';
