import { Action } from '../../../../@types/Action.type.ts';
import { Shared_OnboardingNotification_Closed } from '../../../../_shared/constants/sharedActionTypes.ts';
import { OnboardingNotification_Triggered } from '../../../actions/onboardingNotificationsActions.ts';
import {
  AdditionalOnboardingNotificationData,
  OnboardingNotification,
} from '../../../models/user/OnboardingNotification.ts';

interface ICurrentOnboardingNotificationData {
  readonly notification: OnboardingNotification;
  readonly additionalData: AdditionalOnboardingNotificationData;
}

export type CurrentOnboardingNotification = ICurrentOnboardingNotificationData | null;

export function currentOnboardingNotification(
  state: CurrentOnboardingNotification = null,
  action: Action,
): CurrentOnboardingNotification {
  switch (action.type) {
    case Shared_OnboardingNotification_Closed:
      return null;

    case OnboardingNotification_Triggered: {
      return action.payload.shouldBeDisplayed
        ? {
            notification: action.payload.triggeredNotification,
            additionalData: action.payload.additionalData,
          }
        : null;
    }

    default:
      return state;
  }
}
