import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import { WebSpotlight_Configuration_Loaded } from '../../constants/webSpotlightActionTypes.ts';
import { IWebSpotlightConfiguration } from '../../reducers/configuration.ts';

interface IDeps {
  readonly webSpotlightRepository: IWebSpotlightRepository;
}

const loaded = (configuration: IWebSpotlightConfiguration) =>
  ({
    type: WebSpotlight_Configuration_Loaded,
    payload: {
      configuration,
    },
  }) as const;

export type LoadWebSpotlightConfigurationActionType = ReturnType<typeof loaded>;

export const loadWebSpotlightConfigurationActionCreator =
  (deps: IDeps) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const response = await deps.webSpotlightRepository.getWebSpotlightConfiguration(
      projectId,
      abortSignal,
    );

    dispatch(
      loaded({
        isEnabled: response.enabled,
        rootTypeId: response.rootTypeId,
      }),
    );
  };
