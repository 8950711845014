import { DOMRectLike, isElement } from '@kontent-ai/DOM';
import { Direction } from '@kontent-ai/types';
import { Collection } from '@kontent-ai/utils';
import {
  DOMSelectionLike,
  getSelectionDirection,
} from '../../../../../_shared/utils/selectionUtils.ts';
import { SpaceTakenByActionButtons } from '../../../../itemEditor/features/ContentItemEditing/constants/uiConstants.ts';
import {
  NodePredicate,
  NumberRange,
  getRelevantRectanglesForPositioning,
  isObjectBlockWrapper,
} from './toolbarPositioningUtils.ts';

const isNodeRelevantForBlockToolbar: NodePredicate = (node) => !isObjectBlockWrapper(node);

export const SpaceForHorizontalToolbar = 72;
export const ToolbarOffsetFromContainer = 16;

type BlockToolbarPosition = {
  top: number;
};

function getVerticalPositionWithinBounds(
  desiredTop: number,
  bounds: NumberRange,
  toolbarRectangle: DOMRectLike,
  scrollContainerRectangle: DOMRectLike,
): number {
  const containerMinTop =
    scrollContainerRectangle.top +
    SpaceTakenByActionButtons +
    toolbarRectangle.height / 2 +
    ToolbarOffsetFromContainer;
  const containerMaxTop =
    scrollContainerRectangle.bottom - toolbarRectangle.height / 2 - ToolbarOffsetFromContainer;

  const desiredTopWithinContainer = Math.max(
    containerMinTop,
    Math.min(containerMaxTop, desiredTop),
  );
  const limitedToBounds = Math.max(bounds.min, Math.min(bounds.max, desiredTopWithinContainer));

  return limitedToBounds;
}

function getBestFallbackSelectionRect(domSelection: DOMSelectionLike): DOMRect | null {
  // Selection may be in an element, but for some reason the default overlap check cannot use it as proper selection rectangle
  // In that case we can use the element rectangle
  if (isElement(domSelection.anchorNode)) {
    const anchorRectangle = domSelection.anchorNode.getBoundingClientRect();
    return anchorRectangle;
  }
  // Selection may be a text caret just after a text node which doesn't really overlap with it, in that case can use the DOM selection rectangle itself
  if (domSelection.range) {
    const rangeRectangles = domSelection.range.getClientRects();
    if (rangeRectangles.length === 1) {
      return rangeRectangles[0] ?? null;
    }
  }
  return null;
}

function getBlockToolbarPositionForEmptySelection(
  domSelection: DOMSelectionLike,
  toolbarRectangle: DOMRectLike,
  editorRectangle: DOMRectLike,
  scrollContainerRectangle: DOMRectLike,
): BlockToolbarPosition | null {
  const selectionRect = getBestFallbackSelectionRect(domSelection);
  if (selectionRect) {
    const desiredTop = selectionRect.top + selectionRect.height / 2;
    const top = getVerticalPositionWithinBounds(
      desiredTop,
      {
        min: selectionRect.top,
        max: selectionRect.bottom,
      },
      toolbarRectangle,
      scrollContainerRectangle,
    );
    const relativeTop = top - editorRectangle.top;

    return {
      top: relativeTop,
    };
  }
  return null;
}

export const getBlockToolbarPosition = (
  domSelection: DOMSelectionLike,
  toolbarRectangle: DOMRectLike,
  editorRectangle: DOMRectLike,
  scrollParentRectangle: DOMRectLike,
): BlockToolbarPosition | null => {
  if (!domSelection.range) {
    return getBlockToolbarPositionForEmptySelection(
      domSelection,
      toolbarRectangle,
      editorRectangle,
      scrollParentRectangle,
    );
  }

  const selectionDirection = getSelectionDirection(domSelection);
  const selectionRepresentingRectangles = getRelevantRectanglesForPositioning(
    domSelection,
    isNodeRelevantForBlockToolbar,
  );

  const firstSelectionRectangle = Collection.getFirst(selectionRepresentingRectangles);
  const lastSelectionRectangle = Collection.getLast(selectionRepresentingRectangles);
  if (!firstSelectionRectangle || !lastSelectionRectangle) {
    return getBlockToolbarPositionForEmptySelection(
      domSelection,
      toolbarRectangle,
      editorRectangle,
      scrollParentRectangle,
    );
  }

  const startTop = firstSelectionRectangle.top + firstSelectionRectangle.height / 2;
  const endTop = lastSelectionRectangle.top + lastSelectionRectangle.height / 2;

  const desiredTop = selectionDirection === Direction.Backward ? startTop : endTop;

  const top = getVerticalPositionWithinBounds(
    desiredTop,
    {
      min: firstSelectionRectangle.top,
      max: lastSelectionRectangle.bottom,
    },
    toolbarRectangle,
    scrollParentRectangle,
  );
  const relativeTop = top - editorRectangle.top;

  return { top: relativeTop };
};
