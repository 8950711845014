import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import { CollectionSelectOption } from '../../../../../_shared/containers/CollectionSelectOption.tsx';
import {
  getOriginalItemId,
  getSectionItemId,
} from '../../../../../_shared/utils/component-library/selectSections.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CollectionSection } from '../constants/collectionSection.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { SelectorHeader } from './SelectorHeader.tsx';

type Props = {
  readonly collectionOptions: ReadonlyArray<ISelectSection<IBaseSelectItem>>;
  readonly disabledTooltip: string | undefined;
  readonly index: number;
  readonly inputRef?: RefObject<HTMLInputElement>;
  readonly onCollectionSelected: (collectionId: Uuid) => void;
  readonly selectedCollectionId: Uuid | null;
  readonly selectedCollectionSection: CollectionSection;
};

const collectionDropdownMaxWidth = 90 * gridUnit;

export const CollectionSelector: React.FC<Props> = ({
  collectionOptions,
  disabledTooltip,
  index,
  inputRef,
  onCollectionSelected,
  selectedCollectionId,
  selectedCollectionSection,
}) => (
  <Stack spacing={Spacing.L}>
    <SelectorHeader index={index} type={SelectorType.Collection} />
    <SingleSelect<IBaseSelectItem>
      inputState={disabledTooltip ? InputState.Disabled : undefined}
      items={collectionOptions}
      onSelectionChange={(itemId) => {
        if (itemId) {
          onCollectionSelected(getOriginalItemId(itemId));
        }
      }}
      placeholder="Select a collection"
      renderMenuOption={(optionProps) =>
        optionProps.item.value && (
          <Box maxWidth={collectionDropdownMaxWidth}>
            <CollectionSelectOption
              collectionId={getOriginalItemId(optionProps.item.value.id)}
              {...optionProps}
              {...getDataUiObjectNameAttribute(getOriginalItemId(optionProps.item.value.id))}
            />
          </Box>
        )
      }
      selectedItemId={
        selectedCollectionId && getSectionItemId(selectedCollectionId, selectedCollectionSection)
      }
      tooltipText={disabledTooltip}
      verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
      inputRef={inputRef}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
    />
  </Stack>
);

CollectionSelector.displayName = 'CollectionSelector';
