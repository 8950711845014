import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography, colorTextDefault, px } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly children: React.ReactNode;
};

export const EmptyStateTitle: React.FC<Props> = ({ children }) => {
  return (
    // The div is there just to reset margin-bottom from its parent, so it could be set in the Box below.
    <div>
      <Box
        color={colorTextDefault}
        typography={Typography.HeadlineLarge}
        marginBottom={px(-Spacing.L)}
        {...getDataUiElementAttribute(DataUiElement.EmptyStateTitle)}
      >
        {children}
      </Box>
    </div>
  );
};

EmptyStateTitle.displayName = 'EmptyStateTitle';
