import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { getParentNode } from '../../../contentModels/taxonomy/utils/taxonomyTreeUtils.ts';
import { RootFolderId } from '../constants/assetFolderConstants.ts';

export const getParentFolderId = (taxonomyGroup: ITaxonomyGroup, folderId: Uuid): Uuid => {
  const parentFolder = getParentNode(taxonomyGroup, folderId);

  if (!parentFolder) {
    return RootFolderId;
  }

  return parentFolder.id === taxonomyGroup.id ? RootFolderId : parentFolder.id;
};
