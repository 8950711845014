import { IUrlSlugTypeElement } from '../../../contentInventory/content/models/contentTypeElements/UrlSlugTypeElement.ts';
import {
  IUrlSlugItemElement,
  newUrlSlugItemElement,
} from '../../models/contentItemElements/UrlSlugItemElement.ts';
import { ItemElementCreator } from '../itemElementCreator.ts';

export const createUrlSlugItemElement: ItemElementCreator<
  IUrlSlugTypeElement,
  IUrlSlugItemElement
> = (urlSlugTypeElement): IUrlSlugItemElement =>
  newUrlSlugItemElement({
    elementId: urlSlugTypeElement.elementId,
  });
