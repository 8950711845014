import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { revisionViewerLeft } from '../actions/revisionsActions.ts';
import {
  IRevisionViewerDispatchProps,
  IRevisionViewerOwnProps,
  IRevisionViewerStateProps,
  RevisionViewer as RevisionViewerComponent,
} from '../components/RevisionViewer.tsx';

function mapStateToProps(state: IStore): IRevisionViewerStateProps {
  const {
    contentApp: {
      editedContentItem,
      editedContentItemVariant,
      selectedComparisonRevisionElements,
      selectedRevisionElements,
      selectedRevision,
      selectedComparisonRevision,
    },
  } = state;

  const originalIsNewer =
    !!selectedRevision &&
    !!selectedComparisonRevision &&
    selectedRevision.time < selectedComparisonRevision.time;
  const showComparisonKey = originalIsNewer
    ? !!selectedRevisionElements
    : !!selectedComparisonRevisionElements;

  return {
    editedContentItem,
    editedContentItemVariant,
    showComparisonKey,
  };
}

const mapDispatchToProps = (dispatch: Dispatch): IRevisionViewerDispatchProps => ({
  onLeave: (editedItemId?) => dispatch(revisionViewerLeft(editedItemId)),
});

export const RevisionViewer: React.ComponentType<IRevisionViewerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RevisionViewerComponent);
