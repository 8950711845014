import { getCurrentUserId } from '../../../../_shared/selectors/getCurrentUser.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { getEnvironmentsDetails } from './getEnvironmentsDetails.ts';

const isProjectManagerInEnvironment = (userId: string, environment: IProjectDetails): boolean =>
  !!environment.projectManagers.find((projectManager) => projectManager?.userId === userId);

export const isProjectManagerInAnyEnvironment = (state: IStore): boolean => {
  const environmentDetails = getEnvironmentsDetails(state);
  const userId = getCurrentUserId(state);

  return !!environmentDetails.find((environment) =>
    isProjectManagerInEnvironment(userId, environment),
  );
};
