export enum SidebarNames {
  ActivateUserWarningModal = 'ActivateUserWarningModal',
  ChangePlanModal = 'ChangePlanModal',
  CreateNewSubscriptionModal = 'CreateNewSubscriptionModal',
  CreateProjectModal = 'CreateProjectModal',
  CloneProjectModal = 'CloneProjectModal',
  CreateSampleProjectModal = 'CreateSampleProjectModal',
  DiscussionsSidebar = 'DiscussionsSidebar',
  PromoteUsersModal = 'PromoteUsersModal',
  ResolvedCommentsSidebar = 'ResolvedCommentsSidebar',
  SubscriptionAdminInvitationModal = 'SubscriptionAdminInvitationModal',
  TrialActivationModal = 'TrialActivationModal',
}
