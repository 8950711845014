import { noOperation } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { debounce } from '../../../../_shared/utils/func/debounce.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { OnUpdate } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin } from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  RichTextContentChangeCallbackDebounce,
  RichTextSelectionChangeCallbackDebounce,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { removeForcedSelection } from '../../utils/consistency/editorConsistencyUtils.ts';

export type DebouncedChanges = {
  readonly propagatePendingContentChanges: () => Promise<void>;
};

const nonPropagatingChangeReasons: ReadonlyArray<EditorChangeReason> = [
  EditorChangeReason.ExternalUpdate,
  EditorChangeReason.Drag,
];

export type EditorChangeCallback = (
  editorState: EditorState,
  reason: EditorChangeReason,
) => Promise<void>;

type OnChangePluginProps = {
  readonly onContentChange: EditorChangeCallback;
  readonly onSelectionChange?: EditorChangeCallback;
  readonly debouncedChangesRef?: React.Ref<DebouncedChanges>;
};

type PropagateChanges = (editorState: EditorState, changeReason: EditorChangeReason) => void;

type OnChangePluginState = {
  readonly propagateChanges: PropagateChanges;
  readonly propagatePendingContentChanges: () => Promise<void>;
  readonly areContentChangesPending: () => boolean;
};

export type OnChangePlugin = EditorPlugin<OnChangePluginState, OnChangePluginProps>;

export const OnChangePlugin: PluginComponent<OnChangePlugin> = (props) => {
  const { debouncedChangesRef, onContentChange, onSelectionChange } = props;

  const debouncedPropagateContentChange = useMemo(
    () => debounce(onContentChange, RichTextContentChangeCallbackDebounce),
    [onContentChange],
  );
  useEffect(() => debouncedPropagateContentChange.cancel, [debouncedPropagateContentChange]);

  const debouncedPropagateSelectionChange = useMemo(
    () => debounce(onSelectionChange ?? noOperation, RichTextSelectionChangeCallbackDebounce),
    [onSelectionChange],
  );
  useEffect(() => debouncedPropagateSelectionChange.cancel, [debouncedPropagateSelectionChange]);

  const propagatePendingContentChanges = useCallback(async (): Promise<void> => {
    // Pending changes need to be propagated in case action based on selection is executed outside the editor
    // to ensure consistency of selection with the state available in the application state and prevent race conditions over state updates
    const promise = debouncedPropagateContentChange.now();
    if (promise) {
      await promise;
    }
  }, [debouncedPropagateContentChange]);

  useImperativeHandle(debouncedChangesRef, () => ({ propagatePendingContentChanges }));

  const lastPropagatedState = useRef<EditorState | null>(null);

  const propagateChanges: PropagateChanges = useCallback(
    (editorState, changeReason) => {
      const contentChanged =
        editorState.getCurrentContent() !== lastPropagatedState.current?.getCurrentContent();
      const selectionChanged =
        editorState.getSelection() !== lastPropagatedState.current?.getSelection();

      lastPropagatedState.current = editorState;

      if (contentChanged) {
        // Editor never sends out forced selection within content change to be able to detect forced selection from outside
        const newStateWithoutForcedSelection = removeForcedSelection(editorState);
        debouncedPropagateContentChange(newStateWithoutForcedSelection, changeReason);
      }

      if (selectionChanged) {
        debouncedPropagateSelectionChange(editorState);
      }
    },
    [debouncedPropagateSelectionChange, debouncedPropagateContentChange],
  );

  const onUpdate: Decorator<OnUpdate> = useCallback(
    (baseOnUpdate) => (params) => {
      const { editorState, changeReason } = params;

      if (nonPropagatingChangeReasons.includes(changeReason)) {
        return;
      }

      propagateChanges(editorState, changeReason);
      baseOnUpdate(params);
    },
    [propagateChanges],
  );

  const areContentChangesPending = useCallback(
    () => debouncedPropagateContentChange.isPending(),
    [debouncedPropagateContentChange],
  );

  const apply: Apply<OnChangePlugin> = useCallback(
    (state) => {
      state.onUpdate.decorate(onUpdate);

      return {
        propagateChanges,
        propagatePendingContentChanges,
        areContentChangesPending,
      };
    },
    [onUpdate, propagateChanges, propagatePendingContentChanges, areContentChangesPending],
  );

  return useEditorWithPlugin(props, { apply });
};
