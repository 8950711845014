import { isAbortError } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IPreviewConfigurationRepository } from '../../../../repositories/interfaces/IPreviewConfigurationRepository.type.ts';
import { PreviewConfiguration_Loading_Finished } from '../../constants/previewConfigurationActionTypes.ts';
import { IPreviewConfiguration } from '../../models/PreviewConfiguration.type.ts';
import { filterPreviewConfigurationToExistingSpaces } from '../../utils/filterPreviewConfigurationToExistingSpaces.ts';
import { getPreviewConfigurationFromServerModel } from '../../utils/getPreviewConfigurationFromServerModel.ts';
import { showServerErrorMessage } from '../previewConfigurationActions.ts';

interface IDeps {
  readonly previewConfigurationRepository: Pick<IPreviewConfigurationRepository, 'get'>;
}

const previewConfigurationLoaded = (previewConfiguration: IPreviewConfiguration) =>
  ({
    type: PreviewConfiguration_Loading_Finished,
    payload: { previewConfiguration },
  }) as const;

export type LoadPreviewConfigurationActionsType = ReturnType<typeof previewConfigurationLoaded>;

export const createLoadPreviewConfigurationAction =
  ({ previewConfigurationRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { spaces },
      sharedApp: { currentProjectId },
    } = getState();

    assert(spaces.loadingStatus === LoadingStatus.Loaded, () => 'Spaces need to be loaded first');

    try {
      const previewConfigurationServerModel = await previewConfigurationRepository.get(
        currentProjectId,
        abortSignal,
      );
      const previewConfiguration = getPreviewConfigurationFromServerModel(
        previewConfigurationServerModel,
        null,
        true,
      );
      const filteredPreviewConfiguration = filterPreviewConfigurationToExistingSpaces(
        previewConfiguration,
        spaces.byId,
      );

      dispatch(previewConfigurationLoaded(filteredPreviewConfiguration));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(showServerErrorMessage());
      }

      throw error;
    }
  };
