import { DateTimeItemElement } from '../../models/contentItemElements/DateTimeItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export function areDateTimeItemElementsTheSame(
  itemElement1: DateTimeItemElement,
  itemElement2: DateTimeItemElement,
): boolean {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }

  const timeZonesAreTheSame = itemElement1.displayTimeZone === itemElement2.displayTimeZone;
  const valuesAreTheSame = itemElement1.value === itemElement2.value;

  return timeZonesAreTheSame && valuesAreTheSame;
}
