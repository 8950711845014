import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { TypeElementConfigurability } from '../../../shared/models/elements/types/elementConfigurabilityOptions.ts';
import {
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_HideConfiguration,
  ContentTypeSnippet_Editor_InitStarted,
  ContentTypeSnippet_Editor_ShowConfiguration,
  ContentTypeSnippet_Editor_TypeElementCreated,
  ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd,
} from '../../constants/snippetActionTypes.ts';

const initialState = Immutable.Map<Uuid, boolean>();

export function showConfigurationForElements(
  state = initialState,
  action: Action,
): Immutable.Map<Uuid, boolean> {
  switch (action.type) {
    case ContentTypeSnippet_Editor_ShowConfiguration: {
      const elementId = action.payload.elementId;
      return state.set(elementId, true);
    }

    case ContentTypeSnippet_Editor_HideConfiguration: {
      const elementId = action.payload.elementId;
      return state.set(elementId, false);
    }

    case ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd:
    case ContentTypeSnippet_Editor_TypeElementCreated: {
      const typeElementId = action.payload.typeElementData.elementId;
      const elementConfigurability = action.payload.typeElementData._configurability;
      if (
        elementConfigurability === TypeElementConfigurability.Required ||
        elementConfigurability === TypeElementConfigurability.Recommended
      ) {
        return state.set(typeElementId, true);
      }
      return state;
    }

    case ContentTypeSnippet_Creator_InitStarted:
    case ContentTypeSnippet_Editor_InitStarted: {
      return initialState;
    }

    default:
      return state;
  }
}
