import React from 'react';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { ItemNameElement } from '../../../../itemEditor/features/ContentItemEditing/components/elements/itemNameElement/ItemNameElement.tsx';
import { ItemNameGuidelineStatus } from '../../../../itemEditor/features/ContentItemEditing/utils/itemEditingUtils.ts';

type Props = {
  readonly editedAsset: IAsset;
  readonly readOnly: boolean;
  readonly onChangeAsset: (updatedAsset: IAsset) => void;
};

export const AssetTitleEditorInModalDialogElement: React.FC<Props> = ({
  editedAsset,
  readOnly,
  onChangeAsset,
}) => {
  const filename = useSelector((s) => s.data.assets.byId.get(editedAsset.id)?.filename ?? '');

  return (
    <ItemNameElement
      dataUiInputName={DataUiInput.ModalDialogAssetName}
      isLoading={false}
      maxLength={ValidationConstants.AssetTitleMaxLength}
      placeholder={filename}
      guidelinesStatus={ItemNameGuidelineStatus.Hidden}
      title="Asset title"
      value={editedAsset.title ?? ''}
      readOnly={readOnly}
      onChange={(name: string) =>
        onChangeAsset({
          ...editedAsset,
          title: name,
        })
      }
    />
  );
};

AssetTitleEditorInModalDialogElement.displayName = 'AssetTitleEditorInModalDialogElement';
