import {
  ItemElementErrorResult,
  ItemElementErrors,
} from '../../../applications/itemEditor/utils/elementErrorCheckers/types/Errors.ts';
import {
  ItemElementFriendlyWarningResult,
  ItemElementFriendlyWarnings,
} from '../../../applications/itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import {
  ItemElementWarningResult,
  ItemElementWarnings,
} from '../../../applications/itemEditor/utils/itemElementWarningCheckers/types/Warnings.ts';

export interface IValidationResult {
  readonly errors: ItemElementErrors;
  readonly warnings: ItemElementWarnings;
  readonly friendlyWarnings: ItemElementFriendlyWarnings;
}

export const emptyValidationResult: IValidationResult = {
  warnings: new Map<UuidPath, ItemElementWarningResult>(),
  friendlyWarnings: new Map<UuidPath, ItemElementFriendlyWarningResult>(),
  errors: new Map<UuidPath, ItemElementErrorResult>(),
};

export const isResultEmpty = (result: IValidationResult) =>
  !result.friendlyWarnings.size && !result.warnings.size && !result.errors.size;
