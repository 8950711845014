import { colorBorderDisabled } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';

export const StyledDataTable = styled(DataTable)`
  & > table {
    border-collapse: collapse;
  }
`;

export const StyledDataTableRow = styled(DataTableRow)`
  box-shadow: none;
  border-radius: 0;
  border-collapse: collapse;
`;

export const StyledDataTableCell = styled(LinkDataTableCell)`
  border-radius: 0 !important;
  border-top: 1px solid ${colorBorderDisabled};
  border-bottom: 1px solid ${colorBorderDisabled};
  
  &:first-of-type {
    width: 50%;
  }
`;
