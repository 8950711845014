import { NumberCompiledTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { ElementType } from '../ContentItemElementType.ts';
import {
  EmptyBaseTypeElement,
  IBaseTypeElement,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export interface INumberTypeElement extends IBaseTypeElement {
  readonly defaultValue: string;
  readonly type: ElementType.Number;
}

export const EmptyNumberTypeElement: INumberTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  defaultValue: '',
  type: ElementType.Number,
};

export const getNumberTypeElementFromServerModel = (
  serverModel: NumberCompiledTypeElementServerModel,
): INumberTypeElement => ({
  ...getBaseTypeElementFromServerModel(serverModel),
  _configurability: EmptyNumberTypeElement._configurability,
  defaultValue: serverModel.default?.global.value.toString() ?? '',
  type: ElementType.Number,
});
