import { assert } from '@kontent-ai/utils';
import { IUserProjectSettingsServerModel } from '../../../../repositories/serverModels/SubscriptionUsersUsageServerModel.type.ts';

export interface IUserProjectSettings {
  readonly collectionGroups: ReadonlyArray<ICollectionGroup>;
  readonly inactive: boolean;
  readonly isActivating: boolean;
  readonly isDeactivating: boolean;
  readonly isInviteRevoking: boolean;
  readonly isInviteSending: boolean;
  readonly isInviteSent: boolean;
  readonly lastLoginAt: DateTimeStamp | null;
  readonly masterEnvironmentId: Uuid;
  readonly projectId: Uuid;
}

const emptyUserProjectSettings: IUserProjectSettings = {
  collectionGroups: [],
  inactive: false,
  isActivating: false,
  isDeactivating: false,
  isInviteRevoking: false,
  isInviteSending: false,
  isInviteSent: false,
  lastLoginAt: null,
  masterEnvironmentId: '',
  projectId: '',
};

export const createUserProjectSettings = (
  params?: Partial<IUserProjectSettings>,
): IUserProjectSettings => ({
  ...emptyUserProjectSettings,
  ...params,
});

export interface ICollectionGroup {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly roles: ReadonlyArray<ICollectionRole>;
}

const emptyCollectionGroup: ICollectionGroup = {
  collectionIds: [],
  roles: [],
};

export const createCollectionGroup = (params?: Partial<ICollectionGroup>): ICollectionGroup => ({
  ...emptyCollectionGroup,
  ...params,
});

export interface ICollectionRole {
  readonly id: Uuid;
  readonly isAssignedToAnyLanguage: boolean;
  readonly isProjectManagerRole: boolean;
  readonly languages: ReadonlyArray<IRoleLanguage>;
  readonly name: string;
}

const emptyCollectionRole: ICollectionRole = {
  id: '',
  isAssignedToAnyLanguage: false,
  isProjectManagerRole: false,
  languages: [],
  name: '',
};

export const createCollectionRole = (params?: Partial<ICollectionRole>): ICollectionRole => ({
  ...emptyCollectionRole,
  ...params,
});

export interface IRoleLanguage {
  readonly id: Uuid;
  readonly inactive: boolean;
  readonly name: string;
}

const emptyRoleLanguage: IRoleLanguage = {
  id: '',
  inactive: false,
  name: '',
};

export const createRoleLanguage = (params?: Partial<IRoleLanguage>): IRoleLanguage => ({
  ...emptyRoleLanguage,
  ...params,
});

export function getUserProjectSettingsFromServerModel(
  serverModel: IUserProjectSettingsServerModel,
): IUserProjectSettings {
  assert(!!serverModel, () => 'UserProjectSettings server model is falsy.');

  return createUserProjectSettings({
    projectId: serverModel.projectId,
    masterEnvironmentId: serverModel.masterEnvironmentId,
    lastLoginAt: serverModel.lastLoginAt,
    inactive: serverModel.inactive,
    collectionGroups: serverModel.collectionGroups,
  });
}
