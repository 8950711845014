import Immutable from 'immutable';
import { useCallback, useRef } from 'react';
import { HoveringCollisionStrategy } from '../../components/DragDrop/dragDrop.type.ts';
import { getItemsDirection } from '../arrayUtils/arrayUtils.ts';
import { crossedHalfTargetHeight, isWithinTargetInset } from './dragDropUtils.ts';

export const useCrossedHalfTargetHeight = <TItem, TKey extends string>(
  items: Immutable.List<TItem> | ReadonlyArray<TItem>,
  getKey: (item: TItem) => TKey,
): HoveringCollisionStrategy => {
  // We need to access the latest items via ref to prevent re-rendering of the whole list of draggable items when order of the items changes via drag
  const itemsRef = useRef(items);
  itemsRef.current = items;

  return useCallback<HoveringCollisionStrategy>(
    (args) => {
      const dragDirection = getItemsDirection(
        itemsRef.current,
        args.sourceId,
        args.targetId,
        getKey,
      );
      return (
        !!dragDirection &&
        crossedHalfTargetHeight(args.targetBoundingRect, args.pointer, dragDirection)
      );
    },
    [getKey],
  );
};

export const isWithinTargetInset20: HoveringCollisionStrategy = ({ targetBoundingRect, pointer }) =>
  isWithinTargetInset(targetBoundingRect, pointer, 0.2);
