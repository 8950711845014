import { assert } from '@kontent-ai/utils';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { IApiTokenFilterServerModel } from '../../../../repositories/serverModels/IApiTokenFilterServerModel.type.ts';

export interface IApiKeyFilter {
  readonly environments: ReadonlyArray<Uuid>;
  readonly query: string;
}

export const emptyApiKeyFilter: IApiKeyFilter = {
  environments: [],
  query: '',
};

export const createApiKeyFilterServerModel = (
  apiKeyFilter: IApiKeyFilter,
  apiKeyTypes: ReadonlyArray<ApiKeyType>,
): IApiTokenFilterServerModel => {
  assert(!!apiKeyFilter, () => 'Api key filter model is falsy');

  return {
    environments: apiKeyFilter.environments,
    query: apiKeyFilter.query,
    api_key_types: apiKeyTypes,
  };
};
