import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetInitialValidationResult } from './typeElementValidators/initialValidationResults/assetInitialValidationResult.ts';
import { ContentTypeSnippetInitialValidationResult } from './typeElementValidators/initialValidationResults/contentTypeSnippetInitialValidationResult.ts';
import { CustomInitialValidationResult } from './typeElementValidators/initialValidationResults/customInitialValidationResult.ts';
import { DateTimeInitialValidationResult } from './typeElementValidators/initialValidationResults/dateTimeInitialValidationResult.ts';
import { GuidelinesInitialValidationResult } from './typeElementValidators/initialValidationResults/guidelinesInitialValidationResult.ts';
import { ModularContentInitialValidationResult } from './typeElementValidators/initialValidationResults/modularContentInitialValidationResult.ts';
import { MultipleChoiceInitialValidationResult } from './typeElementValidators/initialValidationResults/multipleChoiceInitialValidationResult.ts';
import { NumberInitialValidationResult } from './typeElementValidators/initialValidationResults/numberInitialValidationResult.ts';
import { RichTextInitialValidationResult } from './typeElementValidators/initialValidationResults/richTextInitialValidationResult.ts';
import { TaxonomyInitialValidationResult } from './typeElementValidators/initialValidationResults/taxonomyInitialValidationResult.ts';
import { TextInitialValidationResult } from './typeElementValidators/initialValidationResults/textInitialValidationResult.ts';
import { UrlSlugInitialValidationResult } from './typeElementValidators/initialValidationResults/urlSlugInitialValidationResult.ts';
import { TypeElementValidationResult } from './typeElementValidators/types/TypeElementValidationResult.type.ts';

const initialResultsMap: ReadonlyRecord<TypeElementType, TypeElementValidationResult> = {
  [ElementType.Asset]: AssetInitialValidationResult,
  [ElementType.Subpages]: ModularContentInitialValidationResult,
  [ElementType.ContentTypeSnippet]: ContentTypeSnippetInitialValidationResult,
  [ElementType.Custom]: CustomInitialValidationResult,
  [ElementType.DateTime]: DateTimeInitialValidationResult,
  [ElementType.Guidelines]: GuidelinesInitialValidationResult,
  [ElementType.LinkedItems]: ModularContentInitialValidationResult,
  [ElementType.MultipleChoice]: MultipleChoiceInitialValidationResult,
  [ElementType.Number]: NumberInitialValidationResult,
  [ElementType.RichText]: RichTextInitialValidationResult,
  [ElementType.Taxonomy]: TaxonomyInitialValidationResult,
  [ElementType.Text]: TextInitialValidationResult,
  [ElementType.UrlSlug]: UrlSlugInitialValidationResult,
};

export function getInitialTypeElementValidationResult(
  elementType: TypeElementType,
): TypeElementValidationResult {
  return initialResultsMap[elementType];
}
