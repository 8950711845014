import { assert } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../_shared/actions/sharedActions.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { JsonPatchOperation } from '../../../../../../repositories/utils/jsonPatchConstants.ts';
import { ItemEditorOperationId } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { getContentItemSavingOriginFromRoute } from '../../utils/itemEditingUtils.ts';
import {
  contentItemSavingFailed,
  contentItemSavingFinished,
  contentItemSavingStarted,
} from '../contentItemEditingActions.ts';

interface IDeps {
  readonly contentItemRepository: Pick<IContentItemRepository, 'patchItemProperty'>;
}

export const createPatchItemPropertyAction =
  (deps: IDeps) =>
  (operationId: ItemEditorOperationId, value: any, patchFailedErrorMessage: string): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore) => {
    const {
      contentApp: {
        editedContentItem,
        listingUi: { orderBy, filter },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    assert(
      editedContentItem,
      () =>
        'patchItemProperty.ts: No edited content item when dispatching update of item property.',
    );

    try {
      dispatch(contentItemSavingStarted(operationId));

      const origin = getContentItemSavingOriginFromRoute(window.location.pathname);
      const itemServerModel = await deps.contentItemRepository.patchItemProperty(
        editedContentItem.id,
        JsonPatchOperation.Replace,
        operationId,
        value,
      );

      dispatch(
        contentItemSavingFinished({
          filter,
          orderBy,
          itemPreviouslyUpdatedAt: editedContentItem.lastModifiedAt,
          itemServerModel,
          operationId,
          origin,
          usedSearchMethod,
        }),
      );
    } catch {
      dispatch(showErrorNotification(patchFailedErrorMessage));
      dispatch(
        contentItemSavingFailed({
          operationId,
          apiError: null,
        }),
      );
    }
  };
