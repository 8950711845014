import { ContentItemVariantsCallout } from '../../../../components/ContentItemVariantsCallout/ContentItemVariantsCallout.tsx';
import { ContentItemVariantWithLanguageName } from '../../../../utils/getContentItemVariantsWithLanguageNames.tsx';

type Props = {
  readonly defaultLanguageName: string;
  readonly dependentVariants: ReadonlyArray<ContentItemVariantWithLanguageName>;
};

export const DependentsScheduledEarlierQuickTip = ({
  defaultLanguageName,
  dependentVariants,
}: Props) => {
  return (
    <ContentItemVariantsCallout
      calloutType="quickTip"
      variants={dependentVariants}
      headline="Keep the other languages in mind"
    >
      <p>
        You may want to schedule this item in {defaultLanguageName} for earlier than its other
        language variants that use the {defaultLanguageName} content in non-localizable elements:
      </p>
    </ContentItemVariantsCallout>
  );
};
