import { Box } from '@kontent-ai/component-library/Box';
import React from 'react';
import { UnfinishedStatusIcon } from '../../../applications/contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/UnfinishedStatusIcon.tsx';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import { LinkDataTableCell } from './LinkDataTableCell.tsx';

type ContentItem = Readonly<{
  name: string;
  linkTo: string;
  isComplete: boolean;
}>;

type Props = Readonly<{
  contentItem: ContentItem;
  onClick?: () => void;
}>;

export const DataTableNameCell: React.FC<Props> = ({ contentItem, onClick }) => {
  const { name, linkTo, isComplete } = contentItem;

  return (
    <LinkDataTableCell linkPath={linkTo} title={name} onClick={onClick} openInNewTab>
      <Box display="flex" minWidth={0}>
        {!isComplete && <UnfinishedStatusIcon tooltipPlacement="top" />}
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {name}
        </Box>
      </Box>
      <div className="btn btn--square btn--quinary btn--square-quinary">
        <Icon iconName={IconName.ArrowRightTopSquare} />
      </div>
    </LinkDataTableCell>
  );
};

DataTableNameCell.displayName = 'DataTableNameCell';
