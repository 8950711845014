import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { IAccountRepository } from '../../../repositories/interfaces/IAccountRepository.type.ts';
import { UserPropertyServerModel } from '../../../repositories/serverModels/UserPropertyServerModel.type.ts';
import { Shared_UserProperties_LoadingFinished } from '../../constants/sharedActionTypes.ts';

interface IDependencies {
  readonly accountRepository: IAccountRepository;
}

const userPropertiesLoadingFinished = (properties: ReadonlyArray<UserPropertyServerModel>) =>
  ({
    type: Shared_UserProperties_LoadingFinished,
    payload: {
      properties,
    },
  }) as const;

export type LoadUserPropertiesActionsType = ReturnType<typeof userPropertiesLoadingFinished>;

export const createLoadUserPropertiesAction =
  (deps: IDependencies) =>
  (userId: UserId, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const properties = await deps.accountRepository.getProperties(userId, abortSignal);
    dispatch(userPropertiesLoadingFinished(properties));
  };
