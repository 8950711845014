import Immutable from 'immutable';
import { IRole } from '../../../data/models/roles/IRole.ts';

export const allRolesRole: IRole = {
  id: 'allRolesRole',
  capabilities: Immutable.Set(),
  isReadonly: true,
  codename: '',
  name: 'All roles',
};
