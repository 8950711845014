import { TimeSpentInWorkflowStepByWorkflowServerModel } from '../repositories/serverModels/missionControlRepositoryServerModels.type.ts';

type WorkflowId = Uuid;
type WorkflowStepId = Uuid;

export type TimeSpentInWorkflowStepByWorkflow = ReadonlyRecord<
  WorkflowId,
  ReadonlyRecord<WorkflowStepId, number>
>;

export const getTimeSpentInWorkflowStepByWorkflowFromServerModel = (
  timeSpentFromServer: TimeSpentInWorkflowStepByWorkflowServerModel | null,
): TimeSpentInWorkflowStepByWorkflow | null => {
  if (timeSpentFromServer === null) {
    return null;
  }

  return timeSpentFromServer.reduce<TimeSpentInWorkflowStepByWorkflow>((result, item) => {
    const workflow = result[item.workflowId];

    return {
      ...result,
      [item.workflowId]: {
        ...workflow,
        [item.workflowStatusId]: item.durationInSeconds,
      },
    };
  }, {});
};
