import { noOperation } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getDefaultLanguage } from '../../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { closeDefaultVariantOverwrittenNotification } from '../../../../../actions/contentActions.ts';
import { getExtendedContentChange } from '../../../selectors/getExtendedContentChange.ts';
import { OverwriteNotificationBar } from './OverwriteNotificationBar.tsx';

export const DefaultVariantOverwriteNotificationBar: React.FC = () => {
  const dispatch = useDispatch();
  const usersById = useSelector((state) => state.data.users.usersById);
  const overwrittenLanguageName = useSelector(getDefaultLanguage).name;
  const onClose = useCallback(() => {
    dispatch(closeDefaultVariantOverwrittenNotification());
  }, []);
  const contentChange = useSelector((s) =>
    getExtendedContentChange(s, s.contentApp.defaultVariantOverwritten),
  );
  const changeByUser = contentChange.changeBy ? usersById.get(contentChange.changeBy) : undefined;

  return (
    <OverwriteNotificationBar
      contentChange={contentChange}
      changeByUser={changeByUser}
      overwrittenLanguageName={overwrittenLanguageName}
      onClose={onClose}
      onDisplay={noOperation}
      onRefresh={noOperation}
    />
  );
};

DefaultVariantOverwriteNotificationBar.displayName = 'DefaultVariantOverwriteNotificationBar';
