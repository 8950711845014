import classNames from 'classnames';
import React, { useRef } from 'react';
import { useScrollOnDragEvents } from '../../../../_shared/hooks/useScrollOnDragEvents.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { ScheduledOrPublished } from '../constants/publishedStepValues.ts';
import { ArchivedStepEditor } from '../containers/stepEditors/ArchivedStepEditor.tsx';
import { PublishedStepEditor } from '../containers/stepEditors/PublishedStepEditor.tsx';
import { StepEditor } from '../containers/stepEditors/StepEditor.tsx';
import { WorkflowStep } from './WorkflowStep.tsx';

export interface IWorkflowStepsProps {
  readonly archivedStep: IWorkflowStep;
  readonly editedStepId?: Uuid;
  readonly onMove: (sourceId: Uuid, targetId: Uuid) => void;
  readonly publishedStep: IWorkflowStep;
  readonly regularSteps: ReadonlyArray<IWorkflowStep>;
}

export const WorkflowSteps: React.FC<IWorkflowStepsProps> = ({
  archivedStep,
  editedStepId,
  onMove,
  publishedStep,
  regularSteps,
}) => {
  const firstStepId = regularSteps[0]?.id;
  const isDragging = useSelector((state) => !!state.workflowsApp.editorUi.draggedWorkflowStepId);

  const dndScrollAreaRef = useRef<HTMLUListElement | null>(null);
  useScrollOnDragEvents(dndScrollAreaRef);

  return (
    <ul
      className={classNames('bar-item__list', {
        'bar-item__list--is-dragging': isDragging,
      })}
      ref={dndScrollAreaRef}
      {...getDataUiCollectionAttribute(DataUiCollection.WorkflowSteps)}
    >
      {regularSteps.map((step: IWorkflowStep) => (
        <WorkflowStep
          canAddNewStep
          codename={step.codename}
          color={step.color}
          editedId={editedStepId}
          id={step.id}
          isDraggable={step.id !== firstStepId}
          key={step.id}
          name={step.name}
          onMove={onMove}
        >
          <StepEditor id={step.id} />
        </WorkflowStep>
      ))}
      <WorkflowStep
        canAddNewStep={false}
        codename={publishedStep.codename}
        color={publishedStep.color}
        editedId={editedStepId}
        id={publishedStep.id}
        isDraggable={false}
        name={ScheduledOrPublished}
        onMove={onMove}
      >
        <PublishedStepEditor id={publishedStep.id} />
      </WorkflowStep>
      <WorkflowStep
        canAddNewStep={false}
        codename={archivedStep.codename}
        color={archivedStep.color}
        editedId={editedStepId}
        id={archivedStep.id}
        isDraggable={false}
        name={archivedStep.name}
        onMove={onMove}
      >
        <ArchivedStepEditor id={archivedStep.id} />
      </WorkflowStep>
    </ul>
  );
};

WorkflowSteps.displayName = 'WorkflowStepList';
