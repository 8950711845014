import { assert, Collection } from '@kontent-ai/utils';
import { isEmptyOrWhitespace } from '../../../_shared/utils/stringUtils.ts';
import { UntitledWorkflowSurrogateName } from '../../../applications/environmentSettings/roles/constants/UIConstants.ts';
import {
  IRegularWorkflowStepServerModel,
  IWorkflowRequestServerModel,
  IWorkflowScopeRequestServerModel,
  IWorkflowScopeServerModel,
  IWorkflowServerModel,
} from '../../../repositories/serverModels/WorkflowServerModel.type.ts';
import {
  ArchivedStep,
  IWorkflowStep,
  PublishedStep,
  RegularWorkflowStep,
  ScheduledStep,
  getArchivedWorkflowStepFromServerModel,
  getPublishedWorkflowStepFromServerModel,
  getScheduledWorkflowStepFromServerModel,
  getWorkflowStepFromServerModel,
} from './WorkflowStep.ts';

export type WorkflowScope = {
  readonly id: Uuid;
  readonly collections: ReadonlySet<Uuid>;
  readonly contentTypes: ReadonlySet<Uuid>;
};

export type Workflow = {
  readonly archivedStep: ArchivedStep;
  readonly codename: string;
  readonly id: Uuid;
  readonly name: string;
  readonly scopes: ReadonlyArray<WorkflowScope>;
  readonly steps: NonEmptyReadonlyArray<RegularWorkflowStep>;
  readonly scheduledStep: ScheduledStep;
  readonly publishedStep: PublishedStep;
};

const getWorkflowScopeFromServerModel = ({
  id,
  collections,
  contentTypes,
}: IWorkflowScopeServerModel): WorkflowScope => ({
  id,
  collections: new Set<Uuid>(collections),
  contentTypes: new Set<Uuid>(contentTypes),
});

export function getWorkflowFromServerModel(serverModel: IWorkflowServerModel): Workflow {
  assert(serverModel.scopes, () => 'There are not any workflow scopes in the workflow.');

  const [firstStatus, ...otherStatuses] = serverModel.statuses.map(getWorkflowStepFromServerModel);
  assert(firstStatus, () => 'There aren’t any workflow statuses in the workflow.');

  return {
    id: serverModel.id,
    codename: serverModel.codeName,
    name: serverModel.name,
    steps: [firstStatus, ...otherStatuses],
    publishedStep: getPublishedWorkflowStepFromServerModel(serverModel.publishedStatus),
    scheduledStep: getScheduledWorkflowStepFromServerModel(serverModel.scheduledStatus),
    archivedStep: getArchivedWorkflowStepFromServerModel(serverModel.archivedStatus),
    scopes: serverModel.scopes.map(getWorkflowScopeFromServerModel),
  };
}

type GetServerModelParams = {
  readonly archivedWorkflowStep: ArchivedStep;
  readonly codename: string | null;
  readonly name: string;
  readonly scopes: readonly WorkflowScope[];
  readonly publishedWorkflowStep: PublishedStep;
  readonly regularWorkflowSteps: readonly IWorkflowStep[];
  readonly regularWorkflowStepsOrder: UuidArray;
};

const getServerModelWorkflowScopes = ({
  id,
  collections,
  contentTypes,
}: WorkflowScope): IWorkflowScopeRequestServerModel => ({
  id,
  collections: Array.from(collections),
  contentTypes: Array.from(contentTypes),
});

export function getServerModelFromWorkflow({
  archivedWorkflowStep,
  codename,
  name,
  publishedWorkflowStep,
  regularWorkflowSteps,
  regularWorkflowStepsOrder,
  scopes,
}: GetServerModelParams): IWorkflowRequestServerModel {
  return {
    name: isEmptyOrWhitespace(name) ? UntitledWorkflowSurrogateName : name,
    codename,
    scopes: scopes.map(getServerModelWorkflowScopes),
    statuses: regularWorkflowStepsOrder
      .map((id: Uuid) => regularWorkflowSteps.find((step) => step.id === id))
      .map(
        (step: RegularWorkflowStep): IRegularWorkflowStepServerModel => ({
          _id: step.id,
          name: step.name,
          codeName: step.codename,
          color: step.color,
          transitionsTo: Collection.getValues(step.transitionsTo),
          roles: Collection.getValues(step.roleIds),
        }),
      ),
    publishedStatus: {
      createNewVersionRoleIds: Collection.getValues(publishedWorkflowStep.roleIdsForCreateNew),
      unpublishRoleIds: Collection.getValues(publishedWorkflowStep.roleIdsForUnpublish),
    },
    archivedStatus: {
      roles: Collection.getValues(archivedWorkflowStep.roleIds),
    },
  };
}
