import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { convertTypeElementsToServerModel } from '../../../../applications/contentModels/shared/utils/typeElementDataConverter.ts';
import { UntitledContentTypeSnippetName } from '../../../../applications/contentModels/snippets/constants/UntitledContentTypeSnippetName.ts';
import { ContentTypeSnippetServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import {
  IContentTypeBase,
  convertContentTypeBaseToServerModel,
  emptyContentTypeBase,
  getContentTypeBaseFromServerModel,
} from '../sharedContentTypeModels.ts';

export interface IContentTypeSnippet extends IContentTypeBase {}

export const emptyContentTypeSnippet: IContentTypeSnippet = emptyContentTypeBase;

export const getContentTypeSnippetFromServerModel = (
  serverModel: ContentTypeSnippetServerModel,
): IContentTypeSnippet => ({
  ...getContentTypeBaseFromServerModel(serverModel, { subpagesElementEnabled: false }),
  name: isEmptyOrWhitespace(serverModel.name) ? UntitledContentTypeSnippetName : serverModel.name,
});

export const convertContentTypeSnippetToServerModel = (
  contentTypeSnippet: IContentTypeSnippet,
): ContentTypeSnippetServerModel => ({
  ...convertContentTypeBaseToServerModel(contentTypeSnippet),
  contentElements: convertTypeElementsToServerModel(contentTypeSnippet.typeElements),
  name: contentTypeSnippet.name || UntitledContentTypeSnippetName,
});
