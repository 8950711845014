import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { SelectionAfter } from '../../../utils/editorStateUtils.ts';
import { UploadFilesPlugin } from '../UploadFilesPlugin.tsx';
import { insertFiles, replaceAsset } from './editorFileUtils.ts';

export const editorUploadFilesApi: EditorApiImplementation<UploadFilesPlugin> = {
  insertFiles(api, editorState, selection, files) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => insertFiles(input, files),
      'insert-fragment',
    );
  },

  replaceAsset(api, editorState, oldAssetId, newAssetId) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => replaceAsset(input, oldAssetId, newAssetId),
      'change-block-data',
      false,
      SelectionAfter.Original,
    );
  },
};
