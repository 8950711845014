import { Button } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Link } from 'react-router-dom';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { EnvironmentRouteParams, WebhooksRoute } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { EnvironmentSettingsAppNames } from '../../../environmentSettings/root/constants/EnvironmentSettingsAppNames.ts';

interface IWebhookEmptyState {
  readonly onCreateButtonNewClick: () => void;
}

export const WebhookEmptyState: React.FC<IWebhookEmptyState> = ({ onCreateButtonNewClick }) => {
  const { projectId } = useSelector(getCurrentProject);
  const newWebhooksPath = buildPath<EnvironmentRouteParams>(WebhooksRoute, { projectId });
  return (
    <>
      <PageTitle css={`margin-bottom: ${Spacing.XL}px`}>
        {EnvironmentSettingsAppNames.LegacyWebhooks}
      </PageTitle>
      <EmptyState>
        <EmptyState.Title>Legacy webhooks reach end of life soon</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>
              Build your new integrations using <Link to={newWebhooksPath}>default webhooks</Link>{' '}
              to enjoy advanced filtering, better performance, and more.
            </p>
            <p>
              Legacy webhooks have been deprecated in late 2024 and aren’t recommended for new
              projects.
            </p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
        <EmptyState.Footer>
          <Button buttonStyle="primary" onClick={onCreateButtonNewClick}>
            Create new legacy Webhook
          </Button>
        </EmptyState.Footer>
      </EmptyState>
    </>
  );
};

WebhookEmptyState.displayName = 'WebhookEmptyState';
