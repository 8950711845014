import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import {
  IListingContentItemServerModel,
  IListingVariantDataServerModel,
} from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IVariantWorkflowStepServerModel } from '../../../../../repositories/serverModels/WorkflowServerModel.type.ts';

const emptyWorkflowStep: IVariantWorkflowStepServerModel = {
  _id: '',
  workflowId: '',
  codeName: '',
  action: null,
  name: '',
  color: '',
  transitionsTo: [],
};

export const emptyVariant: IListingVariantDataServerModel = {
  actualWorkflowStatus: {
    ...emptyWorkflowStep,
  },
  id: { itemId: '', variantId: '' },
  lastPublishedAt: null,
  lastModified: null,
  publishingState: PublishingState.Unpublished,
  assignment: {
    id: '',
    createdAt: null,
    note: null,
    noteAddedBy: null,
    noteAddedAt: null,
    assignees: [],
    assignedBy: null,
    due: null,
    publishScheduleTime: null,
    publishScheduleDisplayTimeZone: null,
    unpublishScheduleTime: null,
    unpublishScheduleDisplayTimeZone: null,
    workflowStatus: {
      ...emptyWorkflowStep,
    },
  },
  isComplete: false,
  archived: false,
  urlSlug: undefined,
  contentLinkIds: [],
  modularItemIds: [],
  workflowLastModifiedAt: null,
};

export const emptyListingContentItem: IListingContentItemServerModel = {
  activeCapabilities: {
    variantCapabilities: [],
  },
  item: {
    codeName: '',
    id: '',
    collectionId: '',
    spaceIds: [],
    name: '',
    type: {
      _id: '',
    },
    archived: false,
  },
  highlightedName: '',
  isPublishedVersion: false,
  variant: {
    ...emptyVariant,
  },
  translatedLanguages: [],
};
