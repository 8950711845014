import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

const getContentTypeSavingStatusMessage = (state: IStore): StatusMessage => {
  const { typeIsBeingSaved, editedTypeIsModified } = state.contentModelsApp.typeEditor;

  if (editedTypeIsModified) {
    return UnsavedStatusMessage;
  }

  return typeIsBeingSaved ? SavingStatusMessage : SavedStatusMessage;
};

export const ContentTypeSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getContentTypeSavingStatusMessage,
);
