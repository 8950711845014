import styled from 'styled-components';
import { gridUnit } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import {
  DataUiCLElement,
  getDataUiCLElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

const contentMinWidth = 20 * gridUnit;

const elementAttribute = getDataUiCLElementAttribute(DataUiCLElement.NotificationBarContent);

export const StyledNotificationBarContent = styled.div.attrs(elementAttribute)`
  min-width: ${px(contentMinWidth)};
  word-break: break-word;
  ${Typography.TitleLarge};
`;
