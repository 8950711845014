import { Dispatch, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { UserListing_SearchPhrase_Cleared } from '../../constants/usersActionTypes.ts';

interface IDeps {
  readonly updateSavedConfig: () => ThunkFunction;
}

const userListingSearchPhraseCleared = () =>
  ({
    type: UserListing_SearchPhrase_Cleared,
  }) as const;

export type ClearFilterActionsType = ReturnType<typeof userListingSearchPhraseCleared>;

export const createClearFilterAction =
  ({ updateSavedConfig }: IDeps) =>
  (): ThunkFunction =>
  (dispatch: Dispatch): void => {
    dispatch(userListingSearchPhraseCleared());
    dispatch(updateSavedConfig());
  };
