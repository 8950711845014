import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import {
  AssetValidationData,
  getExistingAssignedAssetsWithRenditions,
  isEveryImageResponsive,
  validateFileSizeForAssets,
  validateHeightForAssets,
  validateWidthForAssets,
} from '../../../../_shared/utils/assets/assetValidationUtils.ts';
import { validateNumberOfItems } from '../../../../_shared/utils/validation/limitations/validateNumberOfItems.ts';
import { IAssetRendition } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { defaultAssetFileTypeOption } from '../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { IAssetTypeElement } from '../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import { validAssetItemWarningResult } from '../../constants/validElementWarningResults.ts';
import {
  AssetExactlyWarning,
  AssetFileSizeWarning,
  AssetIsRequiredWarning,
  AssetMaximumWarning,
  AssetMinimumWarning,
  ImageHeightExactlyWarning,
  ImageHeightMaxWarning,
  ImageHeightMinWarning,
  ImageWidthExactlyWarning,
  ImageWidthMaxWarning,
  ImageWidthMinWarning,
  ResponsiveImageTypeOnlyWarning,
} from '../../constants/warningMessageTemplates.ts';
import {
  AssetReference,
  IAssetItemElement,
} from '../../models/contentItemElements/AssetItemElement.ts';
import { IAssetItemElementWarningResult } from './types/IAssetItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from './types/Warnings.ts';

const isRequiredLimitValid = (
  typeElement: IAssetTypeElement,
  assets: ReadonlyArray<AssetValidationData>,
): boolean => (typeElement.isRequired ? assets.length > 0 : true);

const getInvalidNumberOfItemsErrorMessage = (
  minItems: number | null,
  maxItems: number | null,
): string => {
  if (minItems && maxItems && minItems === maxItems) {
    return AssetExactlyWarning(minItems);
  }
  if (minItems) {
    return AssetMinimumWarning(minItems);
  }
  if (maxItems) {
    return AssetMaximumWarning(maxItems);
  }

  return '';
};

interface IParams {
  readonly typeElement: IAssetTypeElement;
  readonly itemElement: IAssetItemElement;
  readonly loadedAssets: Immutable.Map<Uuid, IAsset>;
  readonly loadedAssetRenditions: ReadonlyMap<Uuid, IAssetRendition>;
  readonly areAssetRenditionsEnabled: boolean;
}

const getMemoizedResult = memoize.allForever(
  (
    hasMinWidth: boolean,
    hasMaxWidth: boolean,
    hasMinHeight: boolean,
    hasMaxHeight: boolean,
    minItems: number | null,
    maxItems: number | null,
    isWidthLimitMet: boolean,
    isHeightLimitMet: boolean,
    isFileSizeLimitMet: boolean,
    isNumberOfItemsLimitMet: boolean,
    isRequiredLimitMet: boolean,
    isResponsiveImageTypeLimitMet: boolean,
    areAssetRenditionsEnabled: boolean,
  ): IAssetItemElementWarningResult => {
    const warningMessages: Array<string> = [];

    if (!isWidthLimitMet) {
      if (hasMinWidth && hasMaxWidth) {
        warningMessages.push(ImageWidthExactlyWarning(areAssetRenditionsEnabled));
      } else if (hasMinWidth) {
        warningMessages.push(ImageWidthMinWarning);
      } else if (hasMaxWidth) {
        warningMessages.push(ImageWidthMaxWarning(areAssetRenditionsEnabled));
      }
    }

    if (!isHeightLimitMet) {
      if (hasMinHeight && hasMaxHeight) {
        warningMessages.push(ImageHeightExactlyWarning(areAssetRenditionsEnabled));
      } else if (hasMinHeight) {
        warningMessages.push(ImageHeightMinWarning);
      } else if (hasMaxHeight) {
        warningMessages.push(ImageHeightMaxWarning(areAssetRenditionsEnabled));
      }
    }

    if (!isFileSizeLimitMet) {
      warningMessages.push(AssetFileSizeWarning);
    }

    if (isRequiredLimitMet && !isNumberOfItemsLimitMet) {
      warningMessages.push(getInvalidNumberOfItemsErrorMessage(minItems, maxItems));
    }

    if (!isResponsiveImageTypeLimitMet) {
      warningMessages.push(ResponsiveImageTypeOnlyWarning);
    }

    return {
      ...emptyItemElementWarningResult,
      isWidthLimitMet,
      isHeightLimitMet,
      isFileSizeLimitMet,
      isNumberOfItemsLimitMet,
      isResponsiveImageTypeLimitMet,
      limitationMessages: [...warningMessages],
      requiredMessage: isRequiredLimitMet ? null : AssetIsRequiredWarning,
    };
  },
);

const areConditionsToSkipValidationMet = (
  typeElement: IAssetTypeElement,
  isEmpty: boolean,
): boolean => !typeElement.isRequired && isEmpty;

// We need memoize the conversion of asset element value to array so that the below memoization works
// When asset element has array value that can be passed directly, this is no longer necessary
const memoizedGetAssetReferences = memoize.weak(
  (assetReferences: Immutable.OrderedMap<Uuid, AssetReference>) => assetReferences.toArray(),
);

export const getAssetItemElementValidationWarning = ({
  typeElement,
  itemElement,
  loadedAssets,
  loadedAssetRenditions,
  areAssetRenditionsEnabled,
}: IParams): IAssetItemElementWarningResult => {
  const minItems = typeElement.minItems;
  const maxItems = typeElement.maxItems;
  const fileSize = typeElement.fileSize;
  const assignedAssets = getExistingAssignedAssetsWithRenditions(
    memoizedGetAssetReferences(itemElement.value),
    loadedAssets,
    loadedAssetRenditions,
  );
  const minWidth = typeElement.minWidth;
  const maxWidth = typeElement.maxWidth;
  const minHeight = typeElement.minHeight;
  const maxHeight = typeElement.maxHeight;
  const isEmpty = assignedAssets.length === 0;

  const isRequiredLimitMet = isRequiredLimitValid(typeElement, assignedAssets);

  if (areConditionsToSkipValidationMet(typeElement, isEmpty)) {
    return validAssetItemWarningResult;
  }

  const isNumberOfItemsLimitMet = validateNumberOfItems(minItems, maxItems, assignedAssets.length);
  const isFileSizeLimitMet = validateFileSizeForAssets(assignedAssets, fileSize);
  const isResponsiveImageTypeLimitMet =
    typeElement.fileType === defaultAssetFileTypeOption || isEveryImageResponsive(assignedAssets);
  const isWidthLimitMet = validateWidthForAssets(assignedAssets, minWidth, maxWidth);
  const isHeightLimitMet = validateHeightForAssets(assignedAssets, minHeight, maxHeight);

  return getMemoizedResult(
    !!minWidth,
    !!maxWidth,
    !!minHeight,
    !!maxHeight,
    minItems,
    maxItems,
    isWidthLimitMet,
    isHeightLimitMet,
    isFileSizeLimitMet,
    isNumberOfItemsLimitMet,
    isRequiredLimitMet,
    isResponsiveImageTypeLimitMet,
    areAssetRenditionsEnabled,
  );
};
