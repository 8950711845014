import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusDefault,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { usePrevious } from '@kontent-ai/hooks';
import React from 'react';
import { Divider } from '../../../../../component-library/components/Dividers/Divider.tsx';
import { DividerDirection } from '../../../../../component-library/components/Dividers/components/StyledDivider.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebSpotlightTileTooltip } from '../constants/UIConstants.ts';

const getButtonText = (
  isSettingToggleInProgress: boolean,
  isWebSpotlightEnabled: boolean,
): string => {
  if (isWebSpotlightEnabled) {
    if (isSettingToggleInProgress) {
      return 'Deactivating';
    }
    return 'Deactivate Web Spotlight';
  }
  if (isSettingToggleInProgress) {
    return 'Activating';
  }
  return 'Activate Web Spotlight';
};

type WebSpotlightTileProps = {
  readonly isSettingToggleInProgress: boolean;
  readonly isWebSpotlightEnabled: boolean;
  readonly toggleWebSpotlightStatus: () => void;
  readonly spacesAppRoute: string;
};

export const WebSpotlightStatusTile: React.FC<WebSpotlightTileProps> = ({
  isSettingToggleInProgress,
  isWebSpotlightEnabled,
  spacesAppRoute,
  toggleWebSpotlightStatus,
}) => {
  const previousIsWebSpotlightEnabled = usePrevious(isWebSpotlightEnabled);
  const isActive = isSettingToggleInProgress
    ? previousIsWebSpotlightEnabled
    : isWebSpotlightEnabled;

  return (
    <Card
      component="section"
      {...(WebSpotlightStatusTile.displayName &&
        getDataUiObjectNameAttribute(WebSpotlightStatusTile.displayName))}
    >
      <Card.Headline
        renderAuxiliaryElements={() =>
          isActive ? (
            <SimpleStatusSuccess icon={Icons.CheckCircle} label="Active" />
          ) : (
            <SimpleStatusDefault icon={Icons.ICircle} label="Inactive" />
          )
        }
      >
        Web Spotlight
      </Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <Box>
            Web Spotlight lets you view your website structure directly in Kontent.ai.
            <br />
            You can edit your content and preview the changes as you go.
          </Box>
          <Button
            buttonStyle={isActive ? 'secondary' : 'primary'}
            destructive={isActive}
            destructiveIcon={Icons.Drawers}
            disabled={isSettingToggleInProgress}
            onClick={toggleWebSpotlightStatus}
            {...getDataUiActionAttribute(DataUiAction.ToggleWebSpotlightFeature)}
          >
            {isSettingToggleInProgress && <Button.ProgressIcon />}
            <Button.Label>{getButtonText(isSettingToggleInProgress, isActive)}</Button.Label>
          </Button>
          {isActive && (
            <>
              <Divider direction={DividerDirection.Horizontal} offsetBefore={0} offsetAfter={0} />
              <Box>
                Web Spotlight is active. To use it with spaces, create a space and set a root item
                for Web Spotlight.
                <Box marginLeft={Spacing.XS} component="span">
                  <Hint tooltipText={WebSpotlightTileTooltip} tooltipPlacement="right" />
                </Box>
              </Box>
              <Box>
                <RouterLinkButton buttonStyle="primary" to={spacesAppRoute}>
                  Go to spaces
                </RouterLinkButton>
              </Box>
            </>
          )}
          <p>
            Find out{' '}
            <OutwardLink href={documentationLinks.webSpotlight}>
              how Web Spotlight works
            </OutwardLink>{' '}
            in detail.
          </p>
        </Stack>
      </Card.Body>
    </Card>
  );
};

WebSpotlightStatusTile.displayName = 'WebSpotlightStatusTile';
