import { SelectionManager } from '@react-stately/selection';
import { TreeProps, TreeState, useTreeState } from '@react-stately/tree';
import { useCallback, useId, useRef } from 'react';
import { VerticalMenuItem, VerticalMenuSection } from './types.ts';
import { mapItemsToCollectionComponents } from './utils/utils.tsx';

export type VerticalMenuState<TItem extends VerticalMenuItem<TItem>> = TreeState<
  VerticalMenuSection<TItem> | TItem
> & {
  /** selectionManager property is changed on every selected/focused item change
   *  We provide a stable getSelectionManager callback for memoization purposes
   */
  readonly getSelectionManager: () => SelectionManager;
  readonly componentId: string;
};

/** @deprecated Use useVerticalMenu instead.*/
export const useNonAccessibleVerticalMenu = <TItem extends VerticalMenuItem<TItem>>(
  items: ReadonlyArray<VerticalMenuSection<TItem> | TItem> | undefined,
  treeProps?: Omit<TreeProps<TItem>, 'items' | 'children'>,
) => {
  const treeState = useTreeState<VerticalMenuSection<TItem> | TItem>({
    items,
    children: mapItemsToCollectionComponents,
    ...treeProps,
  });

  // We store SelectionManager in ref to aid us in memoization of components (for performance reasons)
  const selectionManagerRef = useRef<SelectionManager>(treeState.selectionManager);
  selectionManagerRef.current = treeState.selectionManager;

  // In tandem with the selectionManagerRef this stable callback provides a way of accessing the selection manager when needed without depending on its ever-changing reference
  const getSelectionManager = useCallback(() => selectionManagerRef.current, []);

  const componentId = useId();

  const verticalMenuState: VerticalMenuState<TItem> = {
    ...treeState,
    getSelectionManager,
    componentId,
  };

  return {
    verticalMenuState,
  };
};
