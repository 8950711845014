import React from 'react';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';

export const ItemsCountLimitWarning: React.FC = () => (
  <div className="items-count-limit-warning">
    <Warning hideTitle subtitle="You’ve got even more content">
      <p>
        Your month is so full of content that it can’t all fit here. Use the filters on the left to
        narrow down your content to see what’s relevant for you.
      </p>
    </Warning>
  </div>
);
