import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { RegexMatch, getAllMatches } from '../regexUtils.ts';

type selectorType<T> = (item: T) => string;

export const whiteSpaceRegExp = /\s+/;

export const whiteSpaceAndUnderscoreRegExp = /[\s,_]+/;
const wordRegex = /[^\s,_]+/;

export type HighlightedIndexes = { from: number; to: number };

const getAllMatchedWordPrefixIndexes = (
  searchedWord: string,
  text: string,
): Immutable.List<HighlightedIndexes> =>
  getAllMatches(wordRegex, text)
    .filter((match: RegexMatch) => match.matchedString.startsWith(searchedWord))
    .map((match: RegexMatch) => ({
      from: match.index,
      to: match.index + searchedWord.length - 1,
    }))
    .toList();

const findAllMatchingWordsInWordPrefixes = (
  searchedWord: string,
  text: string,
): Immutable.List<HighlightedIndexes> =>
  searchedWord.length === 0
    ? Immutable.List()
    : getAllMatchedWordPrefixIndexes(searchedWord.toLocaleLowerCase(), text.toLocaleLowerCase());

const findAllMatchingWords = (
  word: string,
  text: string,
  startingIndex: number = 0,
): Immutable.List<HighlightedIndexes> => {
  if (startingIndex >= text.length) {
    return Immutable.List();
  }
  const index = text.toLocaleLowerCase().indexOf(word.toLocaleLowerCase(), startingIndex);
  if (index < 0) {
    return Immutable.List();
  }
  return Immutable.List([
    {
      from: index,
      to: index + word.length - 1,
    },
  ])
    .concat(findAllMatchingWords(word, text, index + word.length))
    .toList();
};

const normalizeHighlightedIndexes = (input: HighlightedIndexes[]) =>
  input
    .sort((a, b) => a.from - b.from)
    .reduce((result: HighlightedIndexes[], highlightedIndex) => {
      const lastResult = Collection.getLast(result);
      if (
        highlightedIndex.from > highlightedIndex.to ||
        (lastResult && lastResult.to >= highlightedIndex.to)
      ) {
        return result;
      }
      if (!lastResult || lastResult.to < highlightedIndex.from) {
        return result.concat(highlightedIndex);
      }
      const newFrom = lastResult.from;
      return result.slice(0, result.length - 1).concat({
        from: newFrom,
        to: highlightedIndex.to,
      });
    }, []);

type IndexesFinder = (word: string, text: string) => Immutable.List<HighlightedIndexes>;
const findAllMatchingIndexesCore = (
  words: string[],
  text: string,
  indexesFinder: IndexesFinder,
): HighlightedIndexes[] => {
  const rawResult = words.reduce((result: HighlightedIndexes[], word) => {
    if (word.length === 0) {
      return result;
    }

    return result.concat(indexesFinder(word, text).toArray());
  }, []);

  return normalizeHighlightedIndexes(rawResult).sort((a, b) => a.from - b.from);
};

export const findMatchingIndexes = (words: string[], text: string): HighlightedIndexes[] =>
  findAllMatchingIndexesCore(words, text, findAllMatchingWords);

export const findMatchingIndexesInWordPrefixes = (
  words: string[],
  itemName: string,
): HighlightedIndexes[] =>
  findAllMatchingIndexesCore(words, itemName, findAllMatchingWordsInWordPrefixes);

type PhraseMatchValidator = (phrase: string, text: string) => boolean;
const doesEntitySatisfyFilterPhraseCore = <T>(
  filterPhrase: string,
  item: T,
  selectors: selectorType<T>[],
  phraseMatchValidator: PhraseMatchValidator,
) =>
  filterPhrase
    .toLocaleLowerCase()
    .split(whiteSpaceAndUnderscoreRegExp)
    .every((word) =>
      selectors.some((selector: selectorType<T>) => phraseMatchValidator(word, selector(item))),
    );

export const doesEntitySatisfyFilterPhrase = <T>(
  filterPhrase: string,
  item: T,
  selectors: selectorType<T>[],
) =>
  doesEntitySatisfyFilterPhraseCore(filterPhrase, item, selectors, (word, text) =>
    text.toLocaleLowerCase().includes(word),
  );

export const doesItemSatisfyFilterPhrase = <T>(
  filterPhrase: string,
  item: T,
  selectors: selectorType<T>[],
) =>
  doesEntitySatisfyFilterPhraseCore(filterPhrase, item, selectors, (word, text) =>
    text
      .toLocaleLowerCase()
      .split(whiteSpaceAndUnderscoreRegExp)
      .some((namePart) => namePart.startsWith(word)),
  );

export const filterEntitiesMap = <T>(
  filterPhrase: string,
  items: Immutable.Map<Uuid, T>,
  selectors: selectorType<T>[],
) =>
  items.filter((item: T) => doesEntitySatisfyFilterPhrase(filterPhrase, item, selectors)).toMap();
