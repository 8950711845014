import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { ModalContentItemSelectorLayout } from '../../../../_shared/features/ContentItemModalLayout/ModalContentItemSelectorLayout.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { capitalizeFirstLetter } from '../../../../_shared/utils/stringUtils.ts';
import { loadContentTypeAsComponentUsageCount } from '../../../../data/actions/thunkDataActions.ts';
import {
  clearContentItemListingFilter,
  filterItemsWithContentTypeAsComponentUsage,
} from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { filterSearchPhraseChanged } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunks/updateFilterSearchPhrase.ts';
import {
  ContentItemFilter,
  isContentItemFilterInitialized,
} from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { contentTypeAsComponentUsageModalDismissed } from '../actions/contentTypesActions.ts';
import { ContentTypeAsComponentUsageDialogProvider } from '../context/ContentTypeAsComponentUsageDialogContext.tsx';
import { getContentTypeIdInContentTypeAsComponentUsageDialog } from '../selectors/getContentTypeId.ts';
import { ContentTypeUsedAsComponentScrollTable } from './ContentTypeUsedAsComponentScrollTable.tsx';

const getContentTypeName = (state: IStore): string => {
  const contentTypeId = getContentTypeIdInContentTypeAsComponentUsageDialog(state);
  const contentType = state.data.contentTypes.byId.get(contentTypeId);

  return contentType ? contentType.name : '';
};

const isInitialLoading = (state: IStore): boolean => {
  const { loadingStatus, items } = state.data.contentTypeComponentsUsage;
  return loadingStatus === LoadingStatus.InitialEmpty || items === null;
};

export const ContentTypeUsedAsComponentItemListingModal = () => {
  const dispatch = useDispatch();
  const contentTypeId = useSelector(getContentTypeIdInContentTypeAsComponentUsageDialog);
  const contentTypeName = useSelector(getContentTypeName);
  const isInitRetrieving = useSelector(isInitialLoading);
  const loadingStatus = useSelector((s) => s.data.contentTypeComponentsUsage.loadingStatus);
  const renderScrollTable = useSelector(
    (s) =>
      !isInitRetrieving &&
      isContentItemFilterInitialized(
        ContentItemFilterOrigin.ModalContentItemSelector,
        s.contentApp.listingUi.filterStatus,
      ),
  );

  const closeModal = () => {
    dispatch(filterSearchPhraseChanged(''));
    dispatch(clearContentItemListingFilter());
    dispatch(modalDismissed());
    dispatch(contentTypeAsComponentUsageModalDismissed());
  };

  useThunkPromise(loadContentTypeAsComponentUsageCount, contentTypeId);

  return (
    <ContentTypeAsComponentUsageDialogProvider>
      <ModalContentItemSelectorLayout
        titleBarText={`${capitalizeFirstLetter(
          contentTypeName,
        )} content type is used in these items as a component`}
        isFullTextSearchForceDisabled
        isInitRetrieving={isInitRetrieving}
        onClose={closeModal}
        renderItemFilter={() => (
          <ContentItemFilter
            clearFilter={() => dispatch(clearContentItemListingFilter())}
            onFilterChange={() =>
              dispatch(filterItemsWithContentTypeAsComponentUsage(contentTypeId))
            }
            origin={ContentItemFilterOrigin.ModalContentItemSelector}
            listingItemsLoadingStatus={loadingStatus}
          />
        )}
        renderScrollTable={(ref) =>
          renderScrollTable ? (
            <ContentTypeUsedAsComponentScrollTable
              isInitRetrieving={isInitRetrieving}
              parentContainerRef={ref}
              closeModal={closeModal}
            />
          ) : (
            <Loader />
          )
        }
      />
    </ContentTypeAsComponentUsageDialogProvider>
  );
};
