import { CollectionSection } from '../constants/collectionSection.ts';
import { ContentTypeSection } from '../constants/contentTypeSection.ts';
import {
  NewContentItem_DialogPropertiesSet,
  NewContentItem_NameChanged,
  NewContentItem_SelectedCollectionChanged,
  NewContentItem_SelectedContentTypeChanged,
  NewContentItem_SelectedLanguageChanged,
  NewContentItem_SelectedWorkflowChanged,
  RecentlyUsedCollectionsForEditing_Loaded,
  RecentlyUsedCollectionsForListing_Loaded,
  RecentlyUsedContentTypesForEditing_Loaded,
  RecentlyUsedContentTypesForListing_Loaded,
} from '../constants/newContentItemActionTypes.ts';
import { INewContentItemDialogProperties } from '../reducers/INewContentItemStoreState.type.ts';

export const newContentItemNameChanged = (name: string) =>
  ({
    type: NewContentItem_NameChanged,
    payload: {
      name,
    },
  }) as const;

export const newContentItemSelectedLanguageChanged = (languageId: Uuid) =>
  ({
    type: NewContentItem_SelectedLanguageChanged,
    payload: { languageId },
  }) as const;

export const newContentItemSelectedCollectionChanged = (
  collectionId: Uuid | null,
  collectionSection: CollectionSection,
) =>
  ({
    type: NewContentItem_SelectedCollectionChanged,
    payload: {
      collectionId,
      collectionSection,
    },
  }) as const;

export const newContentItemSelectedWorkflowChanged = (workflowId: Uuid | null) =>
  ({
    type: NewContentItem_SelectedWorkflowChanged,
    payload: { workflowId },
  }) as const;

export const newContentItemSelectedContentTypeChanged = (
  contentTypeId: Uuid | null,
  contentTypeSection: ContentTypeSection,
) =>
  ({
    type: NewContentItem_SelectedContentTypeChanged,
    payload: {
      contentTypeId,
      contentTypeSection,
    },
  }) as const;

export const recentlyUsedContentTypesForEditingLoaded = (
  recentlyUsedTypeIds: ReadonlyArray<Uuid>,
) =>
  ({
    type: RecentlyUsedContentTypesForEditing_Loaded,
    payload: { recentlyUsedTypeIds },
  }) as const;

export const recentlyUsedContentTypesForListingLoaded = (
  recentlyUsedTypeIds: ReadonlyArray<Uuid>,
) =>
  ({
    type: RecentlyUsedContentTypesForListing_Loaded,
    payload: { recentlyUsedTypeIds },
  }) as const;

export const recentlyUsedCollectionsForEditingLoaded = (
  recentlyUsedCollectionIds: ReadonlyArray<Uuid>,
) =>
  ({
    type: RecentlyUsedCollectionsForEditing_Loaded,
    payload: { recentlyUsedCollectionIds },
  }) as const;

export const recentlyUsedCollectionsForListingLoaded = (
  recentlyUsedCollectionIds: ReadonlyArray<Uuid>,
) =>
  ({
    type: RecentlyUsedCollectionsForListing_Loaded,
    payload: { recentlyUsedCollectionIds },
  }) as const;

export const newContentItemDialogPropertiesSet = (payload: INewContentItemDialogProperties) =>
  ({
    type: NewContentItem_DialogPropertiesSet,
    payload,
  }) as const;
