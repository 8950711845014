import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { changeDueDate } from '../../../../actions/thunkContentItemEditingActions.ts';
import { ClickableSection } from '../../../../components/sidebar/sectionTypes/ClickableSection.tsx';
import { getDueDateState } from '../../../selectors/getDueDateState.ts';

export const ClickableDueDateSection = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeDueDate(
        ContentItemEditingEventOrigins.ItemDetails,
        ModalDialogType.ContentItemDueDateDialog,
      ),
    );

  const { initialDueDate } = useSelector(getDueDateState);

  return (
    <ClickableSection
      dataUiActionAttribute={DataUiWorkflowAction.EditDueDate}
      dataUiNameAttribute={ItemEditingForms.DueDate}
      onEditClick={onClick}
    >
      {renderDatetimeString(initialDueDate)}
    </ClickableSection>
  );
};
