import { EditorState } from 'draft-js';
import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import {
  getFullBlockTypesAtSelection,
  getSelectionOfAllContent,
  isSameSelectionRange,
  setContentSelection,
} from '../../../utils/editorSelectionUtils.ts';
import {
  deleteAtSelection,
  insertNewChars,
  splitBlock,
} from '../../../utils/general/editorContentUtils.ts';
import {
  TextBlockTypeFeature,
  isTextFeatureAllowed,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import { getVisualStyleForNewContent } from '../../inlineStyles/api/editorStyleUtils.ts';
import { mustBecomeBoundary } from '../../undoRedo/api/editorUndoUtils.ts';
import { TextApiPlugin } from '../TextApiPlugin.tsx';
import { applyDashShorthand, updateText } from './editorTextUtils.ts';

export const editorTextApi: EditorApiImplementation<TextApiPlugin> = {
  getCurrentVisualStyle(_, editorState) {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const inlineStyleOverride = editorState.getInlineStyleOverride();

    return getVisualStyleForNewContent(content, selection, inlineStyleOverride);
  },

  handleDeleteAtSelection(api, editorState, selection, direction) {
    let isUnhandled = false;
    const newEditorState = api.executeContentChange(
      editorState,
      selection,
      (input) => {
        const result = deleteAtSelection(input, direction);
        isUnhandled = !!result.isUnhandled;
        return result;
      },
      'remove-range',
    );

    return {
      editorState: newEditorState,
      isUnhandled,
    };
  },

  splitBlock(api, editorState) {
    const visualStyle = api.getCurrentVisualStyle(editorState);
    const selection = editorState.getSelection();

    const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(
      editorState.getCurrentContent(),
      selection,
    );
    const isParagraphAllowed = isTextFeatureAllowed(
      TextBlockTypeFeature.Paragraph,
      fullBlockTypesAtSelection,
      api.getLimitations(),
    );

    const withBlockSplit = api.executeContentChange(
      editorState,
      selection,
      (input) => splitBlock(input, isParagraphAllowed),
      'split-block',
    );

    // Make sure the style continues with the same visual style that was present before
    const withStyleOverride = EditorState.setInlineStyleOverride(withBlockSplit, visualStyle);

    return withStyleOverride;
  },

  insertNewChars(api, editorState, chars, forceUndo = false) {
    api.getStatistics?.()?.charactersWritten(chars.length);

    const initialEditorState = api.ensureInitialAllowedBlockType?.(editorState) ?? editorState;
    const selection = initialEditorState.getSelection();
    const content = initialEditorState.getCurrentContent();
    const selectionChangedAfterLastModification = !isSameSelectionRange(
      selection,
      content.getSelectionAfter(),
    );
    const allowUndo =
      forceUndo ||
      selectionChangedAfterLastModification ||
      mustBecomeBoundary(initialEditorState.getLastChangeType(), 'insert-characters');

    const visualStyle = api.getCurrentVisualStyle(initialEditorState);
    const updated = api.executeContentChange(
      initialEditorState,
      selection,
      (input) => {
        const newContent = insertNewChars(input, chars, visualStyle);
        if (newContent.wasModified) {
          // If undo is merged with previous, we need to apply selection before to the new content to provide correct selection for undo
          const newContentWithSelection = allowUndo
            ? newContent.content
            : setContentSelection(
                newContent.content,
                content.getSelectionBefore(),
                newContent.content.getSelectionAfter(),
              );

          return {
            wasModified: true,
            content: newContentWithSelection,
            selection: newContent.selection,
          };
        }
        return input;
      },
      'insert-characters',
      allowUndo,
    );

    const space = ' ';
    if (chars === space) {
      return api.executeContentChange(
        updated,
        updated.getSelection(),
        applyDashShorthand,
        'insert-fragment',
        true,
      );
    }

    return updated;
  },

  insertSoftNewline(api, editorState, forceUndo = false) {
    return api.insertNewChars(editorState, '\n', forceUndo);
  },

  insertNonBreakingSpace(api, editorState, forceUndo = false) {
    return api.insertNewChars(editorState, '\u00A0', forceUndo);
  },

  replaceEditorText(api, editorState, newText, allowUndo = false) {
    const content = editorState.getCurrentContent();
    const selection = getSelectionOfAllContent(content);

    const updated = api.executeContentChange(
      editorState,
      selection,
      (input) => updateText(input, newText),
      'insert-fragment',
      allowUndo,
    );
    return updated;
  },
};
