import { combineReducers } from '@reduxjs/toolkit';
import { currentFilterLink } from './currentFilterLink.ts';
import { currentFilterLinkLoading } from './currentFilterLinkLoading.ts';
import { filtersBeingDeleted } from './filtersBeingDeleted.ts';
import { filtersBeingUpdated } from './filtersBeingUpdated.ts';
import { isFilterBeingCreated } from './isFilterBeingCreated.ts';
import { lastDeletedFilterId } from './lastDeletedFilterId.ts';
import { lastFilteredUsingSavedFilter } from './lastFilteredUsingSavedFilter.ts';
import { restoreActionInProgress } from './restoreActionInProgress.ts';

export const savedFiltersUi = combineReducers({
  currentFilterLink,
  currentFilterLinkLoading,
  filtersBeingDeleted,
  lastDeletedFilterId,
  filtersBeingUpdated,
  isFilterBeingCreated,
  lastFilteredUsingSavedFilter,
  restoreActionInProgress,
});
