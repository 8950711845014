import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React, { useState } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableAction } from '../../../../_shared/components/DataTable/DataTableActions.tsx';
import { DataTableCell } from '../../../../_shared/components/DataTable/DataTableCell.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  renderDateUtcString,
  renderShortenedDateUtcString,
} from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { IBillingHistoryItem, PaymentStatusCode } from '../models/BillingHistoryItem.ts';

const billingHistoryItemsColumns = Immutable.List.of<Column>(
  {
    columnName: 'Billing period',
    className: 'billing-history__billing-period-column',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Plan',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Total price',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Payment status',
    orderBy: OrderByDirection.None,
  },
);

type Props = {
  readonly billingHistoryItems: Immutable.List<IBillingHistoryItem>;
  readonly onExportBillingHistoryItemToPdf: () => Promise<void>;
  readonly onSelectBillingHistoryItem: (selectedBillingHistoryItemId: Uuid) => void;
  readonly onUnselectBillingHistoryItem: () => void;
  readonly selectedBillingHistoryItemId: Uuid | null;
};

export const BillingHistory: React.FC<Props> = ({
  billingHistoryItems,
  onExportBillingHistoryItemToPdf,
  onSelectBillingHistoryItem,
  onUnselectBillingHistoryItem,
  selectedBillingHistoryItemId,
}) => {
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const exportBillingHistoryItemToPdf = async () => {
    setDownloadInProgress(true);
    await onExportBillingHistoryItemToPdf();
    setDownloadInProgress(false);
  };

  const exportToPdfAction: DataTableAction = {
    text: downloadInProgress ? 'Export in progress…' : 'Export to PDF',
    onClick: exportBillingHistoryItemToPdf,
    isDestructive: false,
    isDisabled: !selectedBillingHistoryItemId || downloadInProgress,
    tooltipMessage: selectedBillingHistoryItemId ? undefined : 'Select a report to download',
    dataUiAction: DataUiAction.ExportToPdf,
  };

  if (billingHistoryItems.isEmpty()) {
    return (
      <div
        className="canvas__content"
        {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionBillingHistory)}
      >
        <div
          className="canvas__content-pane canvas__content-pane--is-not-project-specific project-management-pane"
          data-hj-suppress=""
        >
          <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.BillingHistory} />
          <Stack spacing={Spacing.XL}>
            <PageTitle>{SubscriptionTabName.BillingHistory}</PageTitle>
            <EmptyState size="fullOffset">
              <EmptyState.Title>No billing history yet</EmptyState.Title>
            </EmptyState>
          </Stack>
        </div>
      </div>
    );
  }

  return (
    <div
      className="canvas__content-pane"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionBillingHistory)}
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.BillingHistory} />
      <div className="row">
        <div className="col-sm-23">
          <DataTable
            dataUiCollectionName={DataUiCollection.BillingHistory}
            actions={[exportToPdfAction]}
            title={
              <ListingMessage
                statusInfoMessage={{ text: 'Billing history', style: StatusMessageStyle.Headline }}
              />
            }
            header={
              <DataTableHeadRow
                preventMultipleSelection
                showCheckboxes
                columns={billingHistoryItemsColumns}
              />
            }
          >
            {billingHistoryItems.toArray().map((item, index) => {
              if (!item) {
                return;
              }

              const isRowSelected = selectedBillingHistoryItemId === item.id;

              return (
                <DataTableRow
                  showCheckboxes
                  id={item.id}
                  key={item.id}
                  isSelected={isRowSelected}
                  onSelectionChange={() => {
                    if (isRowSelected) {
                      onUnselectBillingHistoryItem();
                    } else {
                      onSelectBillingHistoryItem(item.id);
                    }
                  }}
                  dataUiObjectName={index.toString()}
                >
                  <DataTableCell>
                    {`${renderDateUtcString(item.periodStart)} - ${renderShortenedDateUtcString(
                      item.periodEnd,
                    )}`}
                  </DataTableCell>
                  <DataTableCell>{item.plan}</DataTableCell>
                  <DataTableCell>${item.price}</DataTableCell>
                  <DataTableCell>
                    <Tag color={getTagColor(item.paymentStatusCode)}>{item.paymentStatus}</Tag>
                  </DataTableCell>
                </DataTableRow>
              );
            })}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

BillingHistory.displayName = 'BillingHistory';

const getTagColor = (paymentStatusCode: PaymentStatusCode): TagColor => {
  switch (paymentStatusCode) {
    case PaymentStatusCode.Success:
      return TagColor.PersianGreen;
    case PaymentStatusCode.Error:
      return TagColor.Red;
    default:
      return TagColor.Yellow;
  }
};
