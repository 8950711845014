const imageMimeTypePrefix = 'image/';

export const isImageMimeType = (mimeType: string): boolean =>
  mimeType.startsWith(imageMimeTypePrefix);

export const getImageType = (mimeType: string): string | null =>
  isImageMimeType(mimeType) ? mimeType.substring(imageMimeTypePrefix.length) : null;

export const isPdfMimeType = (mimeType: string): boolean =>
  mimeType === 'application/pdf' || mimeType === 'application/x-pdf';

export const isSvgMimeType = (mimeType: string): boolean => mimeType === 'image/svg+xml';
