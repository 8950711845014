import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Subscription_AdminListing_DeleteAdminsFinished,
  Subscription_PromoteAdmin_Finished,
  Subscription_RevokeInvitation_Finished,
  Subscription_SubscriptionAdminInvitation_Finished,
} from '../../../../applications/subscriptionManagement/Admins/constants/actionTypes.ts';
import {
  ISubscriptionAdmin,
  createSubscriptionAdminDomainModel,
} from '../../../../applications/subscriptionManagement/shared/models/SubscriptionAdmin.ts';
import {
  Data_AdministratedSubscriptions_Failed,
  Data_AdministratedSubscriptions_Success,
} from '../../../constants/dataActionTypes.ts';

const initialState: Immutable.Map<Uuid, Immutable.List<ISubscriptionAdmin>> = Immutable.Map();

export function subscriptionAdministrators(
  state: Immutable.Map<Uuid, Immutable.List<ISubscriptionAdmin>> = initialState,
  action: Action,
): Immutable.Map<Uuid, Immutable.List<ISubscriptionAdmin>> {
  switch (action.type) {
    case Data_AdministratedSubscriptions_Success: {
      const { administrators } = action.payload.data;
      if (!administrators) {
        return initialState;
      }

      const subscriptionIds = Object.keys(administrators);
      const result = subscriptionIds.reduce((reduced, subscriptionId) => {
        const serverAdmins = administrators[subscriptionId] || [];
        const domainAdmins = serverAdmins.map(createSubscriptionAdminDomainModel);
        return reduced.set(subscriptionId, Immutable.List(domainAdmins));
      }, state);

      return result;
    }

    case Subscription_PromoteAdmin_Finished: {
      const { subscriptionId, promotedUsers } = action.payload;

      return state.update(subscriptionId, (admins) => admins.push(...promotedUsers));
    }

    case Subscription_AdminListing_DeleteAdminsFinished: {
      const { subscriptionId, deletedAdmins } = action.payload;

      const deletedAdminIds = deletedAdmins
        .map((admin: ISubscriptionAdmin) => admin.userId)
        .toSet();

      const removeAdmins = (oldAdmins: Immutable.List<ISubscriptionAdmin>) =>
        oldAdmins
          .filter((user: ISubscriptionAdmin) => !deletedAdminIds.contains(user.userId))
          .toList();

      return state
        .map((oldAdmins: Immutable.List<ISubscriptionAdmin>, subId) =>
          subId === subscriptionId ? removeAdmins(oldAdmins) : oldAdmins,
        )
        .toMap();
    }

    case Subscription_SubscriptionAdminInvitation_Finished: {
      const { subscriptionId, newAdmin } = action.payload;

      return state.update(subscriptionId, (admins) => admins.push(newAdmin));
    }

    case Subscription_RevokeInvitation_Finished: {
      const { subscriptionId, revokedAdmin } = action.payload;
      const removeAdmin = (oldAdmins: Immutable.List<ISubscriptionAdmin>) =>
        oldAdmins
          .filter((user: ISubscriptionAdmin) => user.userId !== revokedAdmin.userId)
          .toList();

      return state
        .map((oldAdmins: Immutable.List<ISubscriptionAdmin>, subId) =>
          subId === subscriptionId ? removeAdmin(oldAdmins) : oldAdmins,
        )
        .toMap();
    }

    case Data_AdministratedSubscriptions_Failed:
      return initialState;

    default:
      return state;
  }
}
