import { InvariantException } from '@kontent-ai/errors';
import { IItemListingOperationCheckServerModel } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';

// no need for typed record here, objects will never be modified, and always obtained from server
export interface IItemListingBulkActionsCheck {
  readonly archive: IItemListingBulkActionCheckResult;
  readonly cancelSchedulePublish: IItemListingBulkActionCheckResult;
  readonly moveToCollection: IItemListingBulkActionCheckResult;
  readonly publish: IItemListingBulkActionCheckResult;
  readonly restoreFromArchivedStep: IItemListingBulkActionCheckResult;
  readonly unpublish: IItemListingBulkActionCheckResult;
  readonly updateWorkflowStep: IItemListingUpdateWorkflowStepCheckStatusResult;
}

export interface IItemListingBulkActionCheckResult {
  readonly status: ItemListingBulkActionStatus;
}

export interface IItemListingUpdateWorkflowStepCheckStatusResult {
  readonly status: ItemListingBulkActionStatus;
  readonly availableTransitions: ReadonlySet<Uuid>;
}

export enum ItemListingBulkActionStatus {
  DifferentWorkflows = 'different_workflows',
  ItemInArchivedStep = 'item_in_archived_step',
  NoItemsForOperation = 'no_items_for_operation',
  NoTranslatedItems = 'no_translated_items',
  Ok = 'ok',
  IncompleteItem = 'incomplete_item',
  MissingPermission = 'missing_permission',
  NoAvailableTransitions = 'no_available_transitions',
  ItemNotInArchivedStep = 'item_not_in_archived_step',
  ItemInPublishedStep = 'item_in_published_step',
  ItemInScheduledStep = 'item_in_scheduled_step',
  TooManyItems = 'too_many_items',
  NotSupportedForHomePageItem = 'not_supported_for_home_page_item',
  AllItemsInArchivedStep = 'all_items_in_archived_step',
  MustPublishNonLocalizableElements = 'must_publish_non_localizable_elements',
}

export function getListingOperationsCheckFromServerModel(
  serverModel: IItemListingOperationCheckServerModel,
): IItemListingBulkActionsCheck {
  if (
    serverModel.update_workflow_step.status === ItemListingBulkActionStatus.Ok &&
    (!serverModel.update_workflow_step.availableTransitions ||
      !serverModel.update_workflow_step.availableTransitions.length)
  ) {
    throw InvariantException(
      `Update workflow action check returned ${ItemListingBulkActionStatus.Ok} but no available transitions exists.`,
    );
  }

  return {
    archive: serverModel.archive,
    cancelSchedulePublish: serverModel.cancel_schedule_publish,
    moveToCollection: serverModel.move_to_collection,
    publish: serverModel.publish,
    restoreFromArchivedStep: serverModel.restore_from_archived_step,
    unpublish: serverModel.unpublish,
    updateWorkflowStep: {
      status: serverModel.update_workflow_step.status,
      availableTransitions: new Set(serverModel.update_workflow_step.availableTransitions || []),
    },
  };
}
