import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ILanguage } from '../../../../data/models/languages/Language.ts';
import { onLanguageMove } from '../actions/localizationActions.ts';
import {
  ILanguageItemCallbackProps,
  ILanguageItemDataProps,
  LanguageItem as LanguageItemComponent,
} from '../components/LanguageItem.tsx';

interface ILanguageItemProps {
  readonly language: ILanguage;
}

const mapStateToProps = (state: IStore, ownProps: ILanguageItemProps): ILanguageItemDataProps => {
  const {
    localizationApp: { editedLanguageId, languages },
  } = state;
  const isEdited = editedLanguageId === ownProps.language.id;

  return {
    language: ownProps.language,
    languages,
    isEdited,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ILanguageItemCallbackProps => ({
  onMove: (movedItem: Uuid, targetItem: Uuid) => dispatch(onLanguageMove(movedItem, targetItem)),
});

export const LanguageItem: React.ComponentType<ILanguageItemProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageItemComponent);
