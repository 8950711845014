import { PortalContainerContext } from '@kontent-ai/component-library/context';
import { useAttachRef } from '@kontent-ai/hooks';
import { useDialog } from '@react-aria/dialog';
import { useModal, useOverlay } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { TippyProps } from '@tippyjs/react';
import React, {
  HTMLAttributes,
  Ref,
  RefCallback,
  RefObject,
  useCallback,
  useContext,
  useId,
} from 'react';
import { IDialogFooterProps } from '../components/DialogFooter.tsx';
import { IDialogAction } from '../components/DialogFooterAction.tsx';
import { IPopoverFrameProps, PopoverFrame } from './components/PopoverFrame.tsx';
import { IPopoverProps } from './types.type.ts';
import { getPopperOffset } from './utils/placementUtils.ts';
import { defaultPlacement, getDefaultPopoverTippyOptions } from './utils/tippyOptionsUtils.ts';

// Default options which are applied always but can be overridden by the custom tippyOptions prop
const requiredTippyOptions: TippyProps = {
  interactive: true,
  offset: getPopperOffset,
};

export interface IPopoverTargetProps {
  readonly 'aria-expanded': boolean;
  readonly 'aria-controls': string;
  readonly role: 'button';
  readonly ref: RefCallback<HTMLElement>;
}

interface IPopoverFramePropsWithRef extends IPopoverFrameProps {
  readonly ref: Ref<HTMLDivElement>;
}

export type IAdjustTippyOptions = (suggestedTippyOptions: TippyProps) => TippyProps;

export interface IPopoverConfig extends Omit<IPopoverProps, 'children'> {
  readonly adjustTippyOptions?: IAdjustTippyOptions;
  readonly cancelActionRef?: IDialogAction['ref'];
  readonly children?: never;
  readonly onClose?: IDialogFooterProps['onClose'];
}

export interface IPopoverInfo {
  readonly cancelActionRef: RefObject<HTMLButtonElement>;
  readonly dialogTitleProps: Omit<HTMLAttributes<HTMLElement>, 'children'>;
  readonly Popover: typeof PopoverFrame;
  readonly popoverProps: IPopoverFramePropsWithRef;
  readonly targetProps: IPopoverTargetProps;
  /**
   * For when you need to work with the target and want to avoid further re-attaching the ref.
   */
  readonly targetRefObject: RefObject<HTMLElement>;
}

export const usePopover = ({
  adjustTippyOptions,
  allowAnimation = true,
  arrowColor,
  autoFocusRef,
  cancelActionRef,
  __disabledFocusLock,
  popoverRef,
  isOpen,
  onClose,
  placement,
  shouldCloseOnBlur = true,
  shouldCloseOnInteractOutside,
  targetRef,
  ...otherProps
}: IPopoverConfig): IPopoverInfo => {
  const { refToForward: targetRefToForward, refObject: targetRefObject } =
    useAttachRef<HTMLElement>(targetRef);
  const preparedPlacement = placement ?? defaultPlacement;
  const { refToForward: popoverRefToForward, refObject: popoverRefObject } =
    useAttachRef<HTMLElement>(popoverRef);

  const defaultShouldCloseOnInteractOutside = useCallback(
    (element: Element): boolean => {
      if (targetRefObject.current?.contains(element)) {
        // Don't close if target has been clicked
        return false;
      }
      return true;
    },
    [targetRefObject],
  );

  const cancelActionFallbackRef = React.useRef<HTMLButtonElement>(null);
  const ensuredCancelActionRef = cancelActionRef ?? cancelActionFallbackRef;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps } = useOverlay(
    {
      isDismissable: true,
      isKeyboardDismissDisabled: false,
      isOpen,
      onClose,
      shouldCloseOnBlur,
      shouldCloseOnInteractOutside:
        shouldCloseOnInteractOutside ?? defaultShouldCloseOnInteractOutside,
    },
    popoverRefObject,
  );

  // Hide content outside the modal from screen readers.
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(otherProps, popoverRefObject);

  const popoverId = useId();
  const targetProps: IPopoverTargetProps = {
    'aria-expanded': isOpen,
    'aria-controls': popoverId,
    role: 'button',
    ref: targetRefToForward,
  };

  const preparedProps = mergeProps(overlayProps, dialogProps, modalProps, { allowAnimation });

  const { portalContainerRef } = useContext(PortalContainerContext);

  const preparedTippyOptions = {
    ...requiredTippyOptions,
    appendTo: portalContainerRef.current ?? document.body,
    ...getDefaultPopoverTippyOptions(preparedPlacement),
  };

  const tippyOptions = adjustTippyOptions
    ? adjustTippyOptions(preparedTippyOptions)
    : preparedTippyOptions;

  const popoverProps: IPopoverFramePropsWithRef = {
    arrowColor,
    autoFocusRef: autoFocusRef ?? ensuredCancelActionRef,
    __disabledFocusLock: !!__disabledFocusLock,
    isOpen,
    popoverId,
    animatedPopoverDialogProps: preparedProps,
    ref: popoverRefToForward,
    tippyOptions,
    targetRef: targetRefObject,
  };

  const { children, ...dialogTitleProps } = titleProps;

  return {
    cancelActionRef: ensuredCancelActionRef,
    dialogTitleProps,
    Popover: PopoverFrame,
    targetProps,
    targetRefObject,
    popoverProps,
  };
};
