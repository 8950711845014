import { InvariantException } from '@kontent-ai/errors';
import { isScheduledToPublishWorkflowStep } from '../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../_shared/utils/contentItemVariantUtils.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../../data/models/listingContentItems/IListingVariantData.ts';
import { AgendaItemStatus } from '../constants/AgendaItemStatus.ts';
import {
  ICalendarEvent,
  IListingContentItemWithEnsuredVariant,
} from '../models/CalendarModels.type.ts';
import {
  getAgendaStateForDueItems,
  getCurrentDate as getCurrentDateUtil,
} from '../utils/calendarUtils.ts';

export const itemHasVariant = (
  item: IListingContentItem | IListingContentItemWithEnsuredVariant,
): item is IListingContentItemWithEnsuredVariant => !!item.variant;

const createCalendarEvent = (
  { item, variant }: IListingContentItemWithEnsuredVariant,
  getDate: (variant: IListingVariantData) => DateTimeStamp | null,
  getStatus: (date: DateTimeStamp) => AgendaItemStatus,
): ICalendarEvent => {
  const date = getDate(variant);
  if (!date) {
    throw InvariantException('Content item with no date encountered');
  }
  return {
    itemId: item.id,
    name: item.name,
    date,
    status: getStatus(date),
  };
};

function* getCalendarEvents(
  current: IListingContentItemWithEnsuredVariant,
  getCurrentDate: () => Date,
): IterableIterator<ICalendarEvent> {
  if (current.variant.assignment.due) {
    yield createCalendarEvent(
      current,
      (variant) => variant.assignment.due,
      (date) => getAgendaStateForDueItems(date, getCurrentDate),
    );
  }

  if (isVariantPublished(current.variant)) {
    yield createCalendarEvent(
      current,
      (variant) => variant.lastPublishedAt,
      () => AgendaItemStatus.Published,
    );
  }

  if (isScheduledToPublishWorkflowStep(current.variant.assignment.workflowStatus)) {
    yield createCalendarEvent(
      current,
      (variant) => variant.assignment.scheduledToPublishAt,
      () => AgendaItemStatus.Scheduled,
    );
  }
}

export const convertContentItemsToCalendarEventsCreator =
  (getCurrentDate: () => Date) =>
  (...items: readonly IListingContentItem[]): readonly ICalendarEvent[] =>
    items.reduce((reduction: readonly ICalendarEvent[], current: IListingContentItem) => {
      if (itemHasVariant(current)) {
        const events = Array.from(getCalendarEvents(current, getCurrentDate));
        return [...reduction, ...events];
      }

      return reduction;
    }, []);

export const convertContentItemsToCalendarEvents =
  convertContentItemsToCalendarEventsCreator(getCurrentDateUtil);
