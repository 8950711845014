import { Action } from '../../../../../@types/Action.type.ts';
import {
  Subscription_SelectSubscription,
  Subscription_SelectSubscription_Clear,
} from '../../constants/subscriptionManagementActionTypes.ts';

export const selectedSubscriptionId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Subscription_SelectSubscription:
      return action.payload.subscription.subscriptionId;

    case Subscription_SelectSubscription_Clear:
      return null;

    default: {
      return state;
    }
  }
};
