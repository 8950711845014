import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { WebSpotlight_Node_Selected } from '../../constants/webSpotlightActionTypes.ts';
import { NodeId } from '../../types/nodeId.type.ts';
import { checkIfItemHasSubpages } from '../../utils/webSpotlightUtils.ts';
import { INodeParams } from './expandWebSpotlightNode.ts';

interface IDeps {
  readonly expandWebSpotlightNode: (params: INodeParams) => ThunkPromise;
}

interface IParams {
  readonly itemId: Uuid;
  readonly nodeId: NodeId;
}

const selected = () =>
  ({
    type: WebSpotlight_Node_Selected,
  }) as const;

export type SelectWebSpotlightNodeActionType = ReturnType<typeof selected>;

export const selectWebSpotlightNodeActionCreator =
  (deps: IDeps) =>
  ({ itemId, nodeId }: IParams): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      data: { listingContentItems },
      webSpotlightApp: { expandedNodesData, subpagesById },
    } = getState();

    dispatch(selected());

    const nodeIsCollapsed = !expandedNodesData.has(nodeId);
    const nodeCanBeExpanded = checkIfItemHasSubpages(
      subpagesById,
      listingContentItems.byId,
      itemId,
    );
    if (nodeIsCollapsed && nodeCanBeExpanded) {
      await dispatch(
        deps.expandWebSpotlightNode({
          itemId,
          nodeId,
        }),
      );
    }
  };
