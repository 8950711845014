import styled, { css, keyframes } from 'styled-components';
import { ComponentLibraryGlobals } from '../../../globals/componentLibraryGlobals.ts';
import { SkeletonContentGradient } from '../../../tokens/decision/gradients.ts';
import { BaseColor } from '../../../tokens/quarks/colors.ts';
import { SkeletonBorderRadius } from '../types.ts';

export type StyledSkeletonProps = {
  readonly $width: string | number;
  readonly $height?: string | number;
  readonly borderRadius: SkeletonBorderRadius;
};

const moveToRightAnimation = keyframes`
  100% { background-position: 100vw; }
`;

export const StyledSkeleton = styled.span.attrs<StyledSkeletonProps>(({ $width, $height }) => ({
  style: {
    width: $width,
    height: $height,
  },
}))<StyledSkeletonProps>`
  position: relative;
  // it has to be a 'span' with display of 'block' because 'div' is not a valid child of paragraph, headings, and such
  display: block;
  max-width: 100%;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  
  ${
    ComponentLibraryGlobals.skipAnimation
      ? css`
      background: ${SkeletonContentGradient.toString()};
  `
      : css`
    --base-color: ${BaseColor.Gray30};
    --highlight-color: ${BaseColor.Gray20};
    --animation-duration: 1.5s;
    --animation-direction: normal;
    background-image: linear-gradient( 90deg,var(--base-color),var(--highlight-color),var(--base-color) );
    background-attachment: fixed;
    background-position: 0;
    // sometimes 'background-attachment: fixed' doesn’t work (e.g. when used inside an element with CSS transform), we have to manually set background-size
    background-size: 100vw 1px;
    animation: ${moveToRightAnimation};
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  `
  };
`;
