import { IBaseSelectItem, ISelectSection, ItemId } from '@kontent-ai/component-library/Selects';
import { memoize } from '@kontent-ai/memoization';
import { assert, Collection, groupBy } from '@kontent-ai/utils';
import {
  createTaxonomyFilterItemId,
  splitTaxonomyFilterItemId,
} from '../../../applications/contentInventory/shared/utils/taxonomyFilterItemIdUtils.ts';
import { getDeletedTaxonomyTermIds } from '../../../applications/contentModels/taxonomy/utils/deletedTaxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { OptionType } from '../../components/MultipleOptionSelect/MultipleSelectDropDownOption.tsx';
import { UntitledTaxonomyGroup } from '../../constants/defaultNames.ts';
import {
  IBaseMultipleOptionItem,
  IHierarchicalItem,
  createFlattenItems,
} from '../hierarchicalOptionsUtils.ts';

const MinimumTermCountForCompactSelector = 11;

type NamedTaxonomyGroup = {
  readonly name: string | null;
};

export const renderTaxonomyGroupName = (taxonomyGroup?: NamedTaxonomyGroup): string => {
  return taxonomyGroup?.name || UntitledTaxonomyGroup;
};

export const renderTaxonomyTermPath = (path: ReadonlyArray<string>): string => {
  return path.join('  >  ');
};

export const isTaxonomyGroupFlat = memoize.maxOne((taxonomyGroup: ITaxonomyGroup): boolean =>
  taxonomyGroup.childIds.every((termId) => !taxonomyGroup.terms.get(termId)?.childIds.length),
);

export const shouldTaxonomyBeCompact = (taxonomyGroup: ITaxonomyGroup | undefined): boolean =>
  !!taxonomyGroup && taxonomyGroup.terms.size >= MinimumTermCountForCompactSelector;

export const removeNonExistingTaxonomyTerms = (
  termIds: ReadonlySet<Uuid>,
  taxonomyGroup: ITaxonomyGroup,
): ReadonlySet<Uuid> => {
  const existingIds = new Set(Collection.getKeys(taxonomyGroup.terms));

  return new Set([...termIds].filter((id) => existingIds.has(id)));
};

export const getConsistentSelectedTermIds = (
  itemElementGroupId: Uuid | null,
  selectedTermIds: ReadonlySet<Uuid>,
  typeElementGroup: ITaxonomyGroup | undefined,
): ReadonlySet<Uuid> => {
  const taxonomyGroupDoesNotExist = !typeElementGroup?.id || !typeElementGroup;
  if (taxonomyGroupDoesNotExist) {
    return new Set();
  }

  // The taxonomy elements inside content components were not migrated when the `groupId` property was added.
  // For them, this property is set to null, until they are touched in the UI or using MAPI.
  // Without `groupId` it is impossible to tell if the taxonomy group changed, so we have to use
  // the `removeNonexistingTaxonomyTerms` function.
  if (!itemElementGroupId) {
    return removeNonExistingTaxonomyTerms(selectedTermIds, typeElementGroup);
  }

  const taxonomyGroupChanged = typeElementGroup.id !== itemElementGroupId;
  return taxonomyGroupChanged ? new Set() : selectedTermIds;
};

export const getSelectedTermIdsIncludingDeleted = (
  taxonomyGroup: ITaxonomyGroup | null,
  selectedTermIds: ReadonlySet<Uuid>,
): ReadonlyArray<Uuid> =>
  taxonomyGroup
    ? taxonomyGroup.childIds.concat(
        getDeletedTaxonomyTermIds(selectedTermIds, flattenTaxonomyGroupTerms(taxonomyGroup)),
      )
    : [];

export const flattenTaxonomyGroupTerms = memoize.weak(
  (taxonomyGroup: ITaxonomyGroup): ReadonlyArray<IHierarchicalItem> => {
    const mapTaxonomyTerm = (termId: Uuid): IBaseMultipleOptionItem => {
      const term = taxonomyGroup.terms.get(termId);
      return {
        id: term?.id || '',
        name: term?.name || '',
        selectedName: term?.name || '',
        type: OptionType.Item,
      };
    };

    const getTaxonomyNodeChildIds = (termId: Uuid): ReadonlyArray<Uuid> =>
      taxonomyGroup.terms.get(termId)?.childIds ?? [];
    const flattenNode = createFlattenItems(mapTaxonomyTerm, getTaxonomyNodeChildIds);

    const flattenedTerms: Array<IHierarchicalItem> = [];
    taxonomyGroup.childIds.forEach((termId) => {
      const branch = flattenNode(termId, []);
      flattenedTerms.push(...branch);
    });

    return flattenedTerms;
  },
);

export const getTaxonomyTermByIdOrThrow = (group: ITaxonomyGroup, termId: Uuid): ITaxonomyTerm => {
  const taxonomyTerm = group.terms.get(termId);
  assert(taxonomyTerm, () => `${__filename}: term with id ${termId} was not found.`);
  return taxonomyTerm;
};

const termToSelectItem = (id: Uuid, group: ITaxonomyGroup): IBaseSelectItem => {
  const term = getTaxonomyTermByIdOrThrow(group, id);
  return toSelectItem(term, group);
};

const toSelectItem = (term: ITaxonomyTerm, group: ITaxonomyGroup): IBaseSelectItem => ({
  id: createTaxonomyFilterItemId(group.id, term.id),
  label: term.name,
  type: 'item',
  items: term.childIds.map((id) => termToSelectItem(id, group)),
});

export const toSelectSection = (group: ITaxonomyGroup): ISelectSection<IBaseSelectItem> => ({
  id: group.id,
  label: group.name,
  type: 'section',
  items: group.childIds.map((id) => termToSelectItem(id, group)),
});

export const hasAnySelectableOptions = (taxonomies: readonly ITaxonomyGroup[]): boolean =>
  taxonomies.some((group) => !!group.terms.size);

export const onTaxonomySelectionChange = (
  selectedIds: ReadonlySet<ItemId>,
  onChange: (selectedTermsByGroup: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void,
) => {
  const idPairs = [...selectedIds].map(splitTaxonomyFilterItemId);
  const groupedIdPairs = groupBy(idPairs, ([groupId]) => groupId);
  const selectedTaxonomy = new Map(
    Collection.getEntries(groupedIdPairs).map(
      ([groupId, value]) => [groupId, new Set(value.map(([, termId]) => termId))] as const,
    ),
  );
  onChange(selectedTaxonomy);
};
