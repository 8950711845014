export const CascadePublishErrorMessage =
  'There was an error during cascade publishing. If this keeps happening, please let us know.';
export const CascadeSchedulingErrorMessage =
  'There was an error during cascade scheduled publishing. If this keeps happening, please let us know.';
export const CascadeActionUndoErrorMessage =
  'There was an error during undoing cascade publishing/scheduled publishing. If this keeps happening, please let us know.';
export const DefaultVariantRequiredTooltip = (selectedLanguageName: string) =>
  `This item contains required non-localizable elements. The default language variant needs to be published together with the ${selectedLanguageName} variant.`;
export const DefaultVariantScheduledTooltip = (
  selectedLanguageName: string,
  defaultLanguageName: string,
  scheduledAt: string,
) =>
  `You can’t publish this item in ${selectedLanguageName} before it’s published in ${defaultLanguageName}. That’s scheduled for ${scheduledAt}.`;
