import { ISmartLinkSdkMessageMetadata } from '../types/SmartLinkSdkApi.ts';
import { FloatingEditorPosition } from '../types/floatingEditorPosition.ts';

export function getStartingPositionForFloatingEditor(
  iframe: HTMLIFrameElement,
  metadata: ISmartLinkSdkMessageMetadata,
): FloatingEditorPosition {
  const { elementRect } = metadata;

  const iframeWidth = iframe.offsetWidth ?? 0;

  const spaceOnTheLeft = elementRect.left;
  const spaceOnTheRight = iframeWidth - elementRect.right;

  return spaceOnTheLeft > spaceOnTheRight
    ? FloatingEditorPosition.Left
    : FloatingEditorPosition.Right;
}
