import React from 'react';
import { Link } from 'react-router-dom';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { Assignment, AssignmentWorkflowStatus } from '../../uiComponents/Assignment/Assignment.tsx';
import { ItemDetailSection } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';

type Props = {
  readonly pathToLastPublishedRevision: string;
  readonly publishedAt: DateTimeStamp | null;
};

const publishedVariantAssignment: AssignmentWorkflowStatus = {
  color: WorkflowStepColor.PersianGreen,
  name: 'Published',
};

export const PublishedVariantAssignment: React.FC<Props> = ({
  publishedAt,
  pathToLastPublishedRevision,
}) => {
  const publishedDate = publishedAt ? (
    <>
      On <Link to={pathToLastPublishedRevision}>{renderDatetimeString(publishedAt)}</Link>
    </>
  ) : undefined;

  return (
    <Assignment
      dataUiObjectName={ItemDetailSection.PublishedStatus}
      workflowStatusColor={publishedVariantAssignment.color}
    >
      <Assignment.Details suffix={publishedDate}>
        {publishedVariantAssignment.name}
      </Assignment.Details>
    </Assignment>
  );
};

PublishedVariantAssignment.displayName = 'PublishedVariantAssignment';
