import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../../constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { IForwardedRefProps, forwardRef } from '../../../utils/forwardedRefProps.tsx';

interface IBarItemActionProps extends IForwardedRefProps<HTMLDivElement> {
  readonly buttonClassName?: string;
  readonly className?: string;
  readonly dataUiActionName: DataUiAction;
  readonly disabled?: boolean;
  readonly iconName: IconName;
  readonly isActive?: boolean;
  readonly isCompact?: boolean;
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  readonly onFocus?: (e: React.FocusEvent) => void;
  readonly screenReaderText: string;
  readonly tooltipText?: string;
}

const BarItemAction: React.FC<React.PropsWithChildren<IBarItemActionProps>> = ({
  children,
  buttonClassName,
  className,
  dataUiActionName,
  disabled,
  forwardedRef,
  iconName,
  isActive,
  isCompact,
  onClick,
  onFocus,
  screenReaderText,
  tooltipText,
  ...otherProps
}) => (
  <div className="bar-item__action-wrapper" ref={forwardedRef}>
    <SquareButton
      className={classNames('bar-item__action', className, {
        'bar-item__action--is-disabled': disabled,
        'bar-item__action--size-xs': isCompact,
      })}
      customButtonClassName={buttonClassName}
      disabled={disabled}
      iconName={iconName}
      isActive={isActive}
      onClick={onClick}
      onFocus={onFocus}
      ariaLabel={screenReaderText}
      size={SquareButtonSize.Quinary}
      style={SquareButtonStyle.Quinary}
      tooltipText={tooltipText}
      {...getDataUiActionAttribute(dataUiActionName)}
      {...otherProps}
    />
    {children}
  </div>
);
BarItemAction.displayName = 'BarItemAction';

const BarItemActionFRC = forwardRef(BarItemAction);
export { BarItemActionFRC as BarItemAction };
