import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import {
  DefaultValueDoesNotMeetLimitations,
  DeletedLinkedItemsInDefaultValue,
  ProvideAPositiveNumberForLimit,
} from '../../../constants/errorMessageTemplates.ts';
import { ILinkedItemsTypeElementData } from '../../../models/elements/LinkedItemsTypeElementData.ts';
import { IDefaultValueLinkedItem } from '../../../reducers/IContentModelsAppStoreState.type.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { ModularContentTypeElementValidationResult } from '../../../utils/typeElementValidators/types/ModularContentTypeElementValidationResult.type.ts';
import { areLimitationsInLinkedItemsDefaultValueMet } from '../../../utils/typeElementValidators/utils/areLimitationsInLinkedItemsDefaultValueMet.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';

function getModularContentTypeElementValidationResult(
  { typeElementValidationUtils }: ITypeElementValidatorDependencies,
  typeElement: ILinkedItemsTypeElementData,
  allReferencedLinkedItems: ReadonlyArray<IDefaultValueLinkedItem>,
  conditionValidationData: ConditionValidationData,
): ModularContentTypeElementValidationResult {
  const { isLimitValueValid, getNameValidationResult, getGuidelinesValidationResult } =
    typeElementValidationUtils;

  const nameValidationResult = getNameValidationResult(typeElement);
  const guidelinesValidationResult = getGuidelinesValidationResult(typeElement);
  const minItems = typeElement.minItems;
  const maxItems = typeElement.maxItems;
  const isNumberOfItemsValid = isLimitValueValid(minItems) && isLimitValueValid(maxItems);

  const defaultValueMeetsLimitations = areLimitationsInLinkedItemsDefaultValueMet(
    typeElement,
    allReferencedLinkedItems,
  );

  const warnings: Array<string> = [];
  const errors: Array<string> = [];
  if (!isNumberOfItemsValid) {
    errors.push(ProvideAPositiveNumberForLimit());
  }

  if (!nameValidationResult.isValid && nameValidationResult.errorMessage) {
    errors.push(nameValidationResult.errorMessage);
  }

  if (!guidelinesValidationResult.isValid && guidelinesValidationResult.errorMessage) {
    errors.push(guidelinesValidationResult.errorMessage);
  }

  const defaultValueContainsDeletedLinkedItem = typeElement.defaultValue.some(
    (id) => allReferencedLinkedItems.find((item) => item.itemId === id)?.isItemArchived,
  );

  if (defaultValueContainsDeletedLinkedItem) {
    errors.push(DeletedLinkedItemsInDefaultValue);
  }

  if (!defaultValueMeetsLimitations) {
    warnings.push(DefaultValueDoesNotMeetLimitations);
  }

  const conditionValidationResult = getConditionValidationResult(
    typeElement,
    conditionValidationData,
  );

  return {
    conditionValidationResult,
    isNameValid: nameValidationResult.isValid,
    isGuidelinesValid: guidelinesValidationResult.isValid,
    isNumberOfItemsValid,
    errorMessages: [
      ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
      ...errors,
    ],
    warningMessages: warnings,
  };
}

export const createValidateModularContentTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (
    typeElement: ILinkedItemsTypeElementData,
  ): ThunkFunction<ModularContentTypeElementValidationResult> =>
  (dispatch, getState) => {
    const linkedItems = getState().contentModelsApp.typeEditor.linkedItemsDefaultValueReferences;
    const validationResult = getModularContentTypeElementValidationResult(
      deps,
      typeElement,
      linkedItems,
      deps.getConditionValidationData(getState),
    );

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
