import styled from 'styled-components';
import { colorTextDefault } from '../../../tokens/decision/colors.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';

export const StyledToggleText = styled.span`
  ${Typography.LabelLarge};
  color: ${colorTextDefault};
  word-break: break-word;
  user-select: none;
`;
