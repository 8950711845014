import { assert } from '@kontent-ai/utils';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getLanguageOptions } from './getLanguageOptions.ts';

export const getInitialSelectedLanguageIdForYourContent = (state: IStore): Uuid => {
  const languageCreateOptions = getLanguageOptions(state, Capability.CreateContent);
  const firstOption = languageCreateOptions[0];
  assert(
    !!firstOption,
    () => 'getInitialSelectedLanguageId.ts: Did not find any language options to select from.',
  );

  return firstOption.id;
};
