import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AppNames } from '../constants/applicationNames.ts';

export enum SpecialAppNames {
  None = 'None',
  Project = 'Project',
}

type HtmlPageTitleProps =
  | {
      readonly appName: AppNames;
      readonly customName?: string;
    }
  | {
      readonly appName: SpecialAppNames.None;
    }
  | {
      readonly appName: SpecialAppNames.Project;
      readonly customName: string;
    };

const baseTitle = 'Kontent.ai';

const getPageTitle = (props: HtmlPageTitleProps): string => {
  switch (props.appName) {
    case SpecialAppNames.None:
      return baseTitle;

    case SpecialAppNames.Project:
      return `${props.customName} | ${baseTitle}`;

    default:
      return `${props.customName || props.appName} | ${baseTitle}`;
  }
};

export const HtmlPageTitle: React.FC<HtmlPageTitleProps> = (props) => (
  <Helmet>
    <title>{getPageTitle(props)}</title>
  </Helmet>
);

HtmlPageTitle.displayName = 'HtmlPageTitle';
