import Immutable from 'immutable';
import React from 'react';
import { OptionWithObjectName } from '../../../../../_shared/components/Options/Option.tsx';
import { TreeNode } from '../../../../../_shared/components/TreeList/TreeNode.tsx';
import { OptionMode } from '../../../../../_shared/models/optionMode.ts';
import { ISitemapNode } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';

interface ISitemapSelectorNodeDataProps {
  readonly allNodes: ReadonlyMap<Uuid, ISitemapNode>;
  readonly collapsedNodes: Immutable.Set<Uuid>;
  readonly currentNode: ISitemapNode;
  readonly isDisabled?: boolean;
  readonly selectedLocations: Immutable.Set<Uuid>;
}

interface ISitemapSelectorNodeCallbackProps {
  readonly onNodeCollapseToggle: (toggledNodeId: Uuid) => void;
  readonly onToggleSitemapLocation: (selectedNodeId: Uuid) => void;
}

type ISitemapSelectorNodeProps = ISitemapSelectorNodeDataProps & ISitemapSelectorNodeCallbackProps;

export class SitemapSelectorNode extends React.PureComponent<ISitemapSelectorNodeProps> {
  static displayName = 'SitemapSelectorNode';

  private readonly collapseExpandNode = (): void => {
    this.props.onNodeCollapseToggle(this.props.currentNode.id);
  };

  private readonly onSitemapLocationClick = (): void => {
    this.props.onToggleSitemapLocation(this.props.currentNode.id);
  };

  render(): JSX.Element {
    const {
      currentNode,
      allNodes,
      selectedLocations,
      collapsedNodes,
      isDisabled,
      onNodeCollapseToggle,
    } = this.props;

    const children = currentNode.childIds
      .map((childId: Uuid) => allNodes.get(childId))
      .map((childNode: ISitemapNode) => (
        <SitemapSelectorNode
          key={childNode.id}
          currentNode={childNode}
          isDisabled={isDisabled}
          allNodes={allNodes}
          collapsedNodes={collapsedNodes}
          onNodeCollapseToggle={onNodeCollapseToggle}
          selectedLocations={this.props.selectedLocations}
          onToggleSitemapLocation={this.props.onToggleSitemapLocation}
        />
      ));

    const hasChildNodes = !!currentNode.childIds.length;

    return (
      <TreeNode
        collapsed={collapsedNodes.contains(currentNode.id)}
        onToggle={hasChildNodes ? this.collapseExpandNode : undefined}
        disabledExpand={!hasChildNodes}
        nodeItem={
          <OptionWithObjectName
            mode={OptionMode.TreeMultiple}
            isSelected={selectedLocations.contains(currentNode.id)}
            disabled={isDisabled}
            onOptionSelected={isDisabled ? undefined : this.onSitemapLocationClick}
            label={currentNode.name}
            dataUiAttribute={currentNode.name}
          />
        }
      >
        {children}
      </TreeNode>
    );
  }
}
