import { ContentItemVariantsCallout } from '../../../../../_shared/components/ContentItemVariantsCallout/ContentItemVariantsCallout.tsx';
import { ContentItemVariantWithLanguageName } from '../../../../../_shared/utils/getContentItemVariantsWithLanguageNames.tsx';

type Props = {
  readonly defaultLanguageName: string;
  readonly dependentVariants: ReadonlyArray<ContentItemVariantWithLanguageName>;
};

export const DependentScheduledVariantsFriendlyWarning = ({
  defaultLanguageName,
  dependentVariants,
}: Props) => {
  return (
    <ContentItemVariantsCallout calloutType="friendlyWarning" variants={dependentVariants}>
      <p>
        Removing the publishing schedule of this item in {defaultLanguageName} may cause its
        scheduled language variants to publish with empty elements:
      </p>
    </ContentItemVariantsCallout>
  );
};
