import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  ListingFilter_DeleteSavedFilter_Finished,
  ListingFilter_RestoreSavedFilter_Finished,
  ListingFilter_SaveFilter_Finished,
  ListingFilter_UpdateSavedFilter_Finished,
} from '../../../../applications/contentInventory/content/features/ListingFilter/constants/listingFilterActionTypes.ts';
import { Data_Filters_Started, Data_Filters_Success } from '../../../constants/dataActionTypes.ts';
import {
  ISavedFilter,
  getSavedFilterFromServerModel,
} from '../../../models/filters/ISavedFilter.ts';

const initialState: ReadonlyMap<Uuid, ISavedFilter> = new Map();

export function byId(
  state: ReadonlyMap<Uuid, ISavedFilter> = initialState,
  action: Action,
): ReadonlyMap<Uuid, ISavedFilter> {
  switch (action.type) {
    case Shared_CurrentProjectId_Updated:
    case Data_Filters_Started:
      return initialState;

    case Data_Filters_Success: {
      const data = action.payload.data.data.map<[string, ISavedFilter]>((f) => [
        f.id,
        getSavedFilterFromServerModel(f),
      ]);
      return Collection.addMany(state, data);
    }

    case ListingFilter_RestoreSavedFilter_Finished:
    case ListingFilter_SaveFilter_Finished:
    case ListingFilter_UpdateSavedFilter_Finished: {
      const { filter } = action.payload;
      return Collection.add(state, [filter.id, getSavedFilterFromServerModel(filter)]);
    }

    case ListingFilter_DeleteSavedFilter_Finished:
      return Collection.remove(state, action.payload.filter.id);

    default:
      return state;
  }
}
