import { areShallowEqual, createGuid } from '@kontent-ai/utils';
import { ContentState, SelectionState } from 'draft-js';
import { importFromPastedText } from '../../../../../../applications/richText/plugins/clipboard/api/editorClipboardUtils.ts';
import { getTextSelections } from '../../../../../../applications/richText/plugins/textApi/api/editorTextUtils.ts';
import { areContentsTheSame } from '../../../../../../applications/richText/utils/compare/areTextEditorStatesTheSame.ts';
import { exportToPlainText } from '../../../../../../applications/richText/utils/export/plainText/exportToPlainText.ts';
import { ReviewCommentServerModel } from './reviewCommentServerModel.ts';

export type ReviewComment = {
  readonly comment: string;
  readonly original: ContentState;
  readonly replacement: ContentState;
};

// We don't need project ID for parsing HTML until we process components and until we expect the HTML to contain
const nonExistingProjectId = createGuid();

export const getReviewComments = (
  parsedComments: ReadonlyArray<ReviewCommentServerModel>,
): ReadonlyArray<ReviewComment> => {
  const comments = parsedComments.map(
    (comment) =>
      ({
        comment: comment.comment,
        original: importFromPastedText(comment.userHtml, false, nonExistingProjectId).content,
        replacement: importFromPastedText(comment.replacementHtml, false, nonExistingProjectId)
          .content,
      }) satisfies ReviewComment,
  );

  return comments.filter((comment) => !areContentsTheSame(comment.original, comment.replacement));
};

export const getCommentPlainText = (content: ContentState): string =>
  exportToPlainText(content, { getEntityText: () => null });

export const getCommentSelection = (
  content: ContentState,
  comment: ReviewComment,
): SelectionState | null => {
  const commentPlainText = getCommentPlainText(comment.original);

  return getTextSelections(content, commentPlainText)[0] ?? null;
};

export const getNewComments = (
  comments: ReadonlyArray<ReviewCommentServerModel>,
  previousComments: ReadonlyArray<ReviewCommentServerModel>,
): ReadonlyArray<ReviewCommentServerModel> =>
  comments.filter(
    (comment) =>
      // We compare the comment properties rather than comment instances as each array of comments comes from a newly parsed response
      !previousComments.find((previousComment) => areShallowEqual(previousComment, comment)),
  );
