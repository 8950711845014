import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import {
  IMoveAssetItemToCollectionServerModel,
  IMoveAssetsToCollectionResultServerModel,
} from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  AssetLibrary_Assets_MoveBackToCollectionCompleted,
  AssetLibrary_Assets_MoveBackToCollectionFailed,
  AssetLibrary_Assets_MoveBackToCollectionStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import {
  CollectionReference,
  isMoveAssetsToCollectionAction,
} from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IDeps {
  readonly assetService: {
    readonly moveAssetsToCollection: (
      items: ReadonlyArray<IMoveAssetItemToCollectionServerModel>,
    ) => Promise<IMoveAssetsToCollectionResultServerModel>;
  };
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

const started = () =>
  ({
    type: AssetLibrary_Assets_MoveBackToCollectionStarted,
  }) as const;

const completed = (successfulIds: ReadonlyMap<Uuid, CollectionReference | null>) =>
  ({
    type: AssetLibrary_Assets_MoveBackToCollectionCompleted,
    payload: {
      successfulIds,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Assets_MoveBackToCollectionFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type MoveAssetsBackToCollectionActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Args = {
  readonly orderBy: AssetsOrderBy;
};

export const createMoveAssetsBackToCollectionAction =
  (deps: IDeps) =>
  ({ orderBy }: Args): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const {
      assetLibraryApp: { lastAction },
    } = getState();

    if (!isMoveAssetsToCollectionAction(lastAction)) {
      throw new Error(
        `${__filename}: Expected last action to be ${
          StatusMessageAction.MovedAssetsToCollection
        }, but was ${JSON.stringify(lastAction)}.`,
      );
    }

    dispatch(started());

    try {
      const itemsToMove = Collection.getEntries(lastAction.successfulIds).map(
        ([assetId, collectionReference]): IMoveAssetItemToCollectionServerModel => ({
          assetId,
          targetCollectionId: collectionReference?.id ?? null,
        }),
      );

      const result = await deps.assetService.moveAssetsToCollection(itemsToMove);
      const movedAssetsIds = new Map(
        Collection.getEntries(lastAction.successfulIds).filter(([assetId]) =>
          result.movedAssetsIds.includes(assetId),
        ),
      );

      dispatch(completed(movedAssetsIds));
      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.MovedBackToCollection,
          count: movedAssetsIds.size,
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          orderBy,
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
        }),
      );
    } catch (error) {
      dispatch(failed('Assets could not be moved back.'));
      throw InvariantException(`${__filename}: ${error}`);
    }
  };
