import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  ITaxonomyPatchOperation,
  TaxonomyPatchOperationType,
} from '../../../../../repositories/utils/createTaxonomyPatchDocument.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import {
  AssetLibrary_Folders_MovingCompleted,
  AssetLibrary_Folders_MovingFailed,
  AssetLibrary_Folders_MovingStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { getParentFolderId } from '../../utils/getParentFolderId.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly patch: (operation: ITaxonomyPatchOperation) => Promise<ITaxonomyGroupServerModel>;
  };
}

const started = () =>
  ({
    type: AssetLibrary_Folders_MovingStarted,
  }) as const;

const completed = (movedFolderOriginalParentId: Uuid, taxonomyGroup: ITaxonomyGroupServerModel) =>
  ({
    type: AssetLibrary_Folders_MovingCompleted,
    payload: {
      movedFolderOriginalParentId,
      taxonomyGroup,
    },
  }) as const;

const failed = () =>
  ({
    type: AssetLibrary_Folders_MovingFailed,
  }) as const;

export type MoveOpenedFolderActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

export const createMoveOpenedAssetFolderAction =
  (deps: IDeps) =>
  (targetFolderId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      assetLibraryApp: { openedFolderId },
      data: {
        assetFolders: { taxonomyGroup },
      },
    } = getState();

    if (openedFolderId === RootFolderId) {
      throw InvariantException('No opened folder was found');
    }

    try {
      dispatch(started());

      const openedFolderParentId = getParentFolderId(taxonomyGroup, openedFolderId);

      const operation: ITaxonomyPatchOperation = {
        operation: TaxonomyPatchOperationType.Move,
        reference: targetFolderId === RootFolderId ? null : targetFolderId,
        source: openedFolderId,
      };

      const patchedTaxonomyGroup = await deps.assetFolderRepository.patch(operation);

      dispatch(completed(openedFolderParentId, patchedTaxonomyGroup));
    } catch (e) {
      dispatch(failed());
      throw InvariantException(`${__filename}: Folder ${targetFolderId} could not be moved: ${e}`);
    }
  };
