import { useAttachRef } from '@kontent-ai/hooks';
import { areShallowEqual, createGuid } from '@kontent-ai/utils';
import React, { PropsWithChildren, RefObject, forwardRef, useCallback } from 'react';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import {
  CustomOnScrollCallback,
  useAutoScroll,
} from '../../../../../_shared/hooks/useAutoScroll.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { shouldShowAiReviewButton } from '../../../../../paperModels/aiReview/richText/plugins/ReviewByGuidelines/utils/shouldShowAiReviewButton.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { CommentThreadItemType } from '../../../models/comments/CommentThreadItem.ts';
import { IInlineCommentThread } from '../../../models/comments/CommentThreads.ts';
import {
  areElementContentLimitationsMet,
  isElementReadyForPublish,
} from '../../../utils/getItemElementValidationResult.ts';
import { getIsFocused, getIsHighlighted } from '../../../utils/itemHighlightingUtils.ts';
import { focusFirstIncompleteItemElement } from '../../ContentItemEditing/actions/contentItemEditingActions.ts';
import { startNewInlineCommentThread } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import {
  IItemElementOwnProps,
  ItemElement as ItemElementComponent,
} from '../../ContentItemEditing/components/elements/ItemElement.tsx';
import {
  getElementCommentThreads,
  getUnresolvedElementCommentThreads,
} from '../../ContentItemEditing/selectors/inlineCommentSelectors.ts';
import {
  areCommentSupported,
  getCommentThreadType,
} from '../../ContentItemEditing/utils/inlineCommentUtils.ts';
import {
  getErrorMessages,
  getErrorValidationResult,
  getFriendlyWarningMessages,
  getIncompleteElementIdPaths,
  getWarningValidationResult,
} from '../../ContentItemEditing/utils/itemValidationUtils.ts';
import { useDeliveryContentComponentId } from '../hooks/useDeliveryContentComponentId.tsx';

const getComponentElementErrorMessages = (
  s: IStore,
  validationResultSelectorId: string,
): ReadonlyArray<string> => {
  const { itemValidationErrors, itemValidationWarnings, showIncompleteItemWarningsBeforePublish } =
    s.contentApp;
  return getErrorMessages(
    itemValidationErrors,
    itemValidationWarnings,
    validationResultSelectorId,
    showIncompleteItemWarningsBeforePublish,
  );
};

const getValidationPassed = (s: IStore, validationResultSelectorId: string): boolean => {
  const warningValidationResult = getWarningValidationResult(
    s.contentApp.itemValidationWarnings,
    validationResultSelectorId,
  );
  const errorValidationResult = getErrorValidationResult(
    s.contentApp.itemValidationErrors,
    validationResultSelectorId,
  );
  return !errorValidationResult && isElementReadyForPublish(warningValidationResult || undefined);
};

const getShowLimitWarning = (
  s: IStore,
  validationResultSelectorId: string,
  hideValidationStatus?: boolean,
): boolean => {
  const warningValidationResult = getWarningValidationResult(
    s.contentApp.itemValidationWarnings,
    validationResultSelectorId,
  );
  return (
    !!warningValidationResult &&
    !areElementContentLimitationsMet(warningValidationResult) &&
    !hideValidationStatus
  );
};

const getComponentElementFriendlyWarningMessages = (
  s: IStore,
  validationResultSelectorId: string,
): ReadonlyArray<string> => {
  const { itemValidationFriendlyWarnings, showFriendlyWarnings } = s.contentApp;
  return getFriendlyWarningMessages(
    itemValidationFriendlyWarnings,
    validationResultSelectorId,
    showFriendlyWarnings,
  );
};

const getIsContentElementHighlighted = (
  s: IStore,
  validationResultSelectorId: string,
  hideValidationStatus?: boolean,
): boolean => {
  const incompleteElementIdPaths = getIncompleteElementIdPaths(
    s.contentApp.itemValidationErrors,
    s.contentApp.itemValidationWarnings,
  );
  return (
    getIsHighlighted(validationResultSelectorId, incompleteElementIdPaths) &&
    s.contentApp.showIncomplete &&
    !hideValidationStatus
  );
};

const getIsComponentElementFocused = (s: IStore, validationResultSelectorId: string): boolean => {
  return getIsFocused(validationResultSelectorId, s.contentApp.firstIncompleteElementId);
};

const getThreads = (
  s: IStore,
  elementId: Uuid,
  contentComponentId: Uuid,
): ReadonlyArray<IInlineCommentThread> => {
  return getElementCommentThreads(
    s.contentApp.editedContentItemVariantComments.commentThreads,
    elementId,
    contentComponentId,
  );
};

const getUnresolvedThreads = (
  s: IStore,
  elementId: Uuid,
  contentComponentId: Uuid,
): ReadonlyArray<IInlineCommentThread> => {
  const threads = getThreads(s, elementId, contentComponentId);
  return getUnresolvedElementCommentThreads(threads, elementId, contentComponentId);
};

const getHasFocusedComment = (
  s: IStore,
  typeElement: TypeElement,
  contentComponentId: Uuid,
): boolean => {
  const threads = getThreads(s, typeElement.elementId, contentComponentId);
  const areCommentsSupported = areCommentSupported(typeElement.type);
  const { focusedCommentThreadId } = s.contentApp.editedContentItemVariantComments;
  return (
    areCommentsSupported &&
    !!focusedCommentThreadId &&
    threads.some((thread) => thread.id === focusedCommentThreadId)
  );
};

const useAutoScrollComponentItemElement = (
  typeElement: TypeElement,
  scrollTargetRef: RefObject<HTMLElement>,
  customOnScroll?: CustomOnScrollCallback,
): void => {
  const deliveryContentComponentId = useDeliveryContentComponentId();
  const scrollId = useSelector((s) => {
    const editedContentItem = s.contentApp.editedContentItem;
    return editedContentItem && deliveryContentComponentId
      ? CreateAutoScrollId.forComponentElement(
          typeElement.codename ?? '',
          deliveryContentComponentId,
          editedContentItem.id,
        )
      : undefined;
  });

  useAutoScroll({
    scrollTargetRef,
    scrollId,
    customOnScroll,
  });
};

type Props = {
  readonly typeElement: TypeElement;
  readonly scrollTargetRef: React.RefObject<HTMLElement>;
  readonly onCustomScrollToTarget?: CustomOnScrollCallback;
};

const ContentComponentItemElementAutoScroll = ({
  typeElement,
  scrollTargetRef,
  onCustomScrollToTarget,
}: Props) => {
  useAutoScrollComponentItemElement(typeElement, scrollTargetRef, onCustomScrollToTarget);
  return null;
};

interface IContentComponentItemElementContainerProps
  extends Omit<IItemElementOwnProps, 'isDisplayOnly'> {
  readonly contentComponentId: Uuid;
  readonly hideValidationStatus?: boolean;
  readonly validationResultSelectorId: string;
}

export const ContentComponentItemElement = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IContentComponentItemElementContainerProps>
>(
  (
    {
      children,
      className,
      contentComponentId,
      defaultTabIndex,
      disabled,
      escapeHandledByEditor,
      focusEditor,
      headerClassName,
      hideValidationStatus,
      isCompact,
      onContentClick,
      onCustomScrollToTarget,
      onHeaderClick,
      title,
      typeElement,
      validationResultSelectorId,
    },
    ref,
  ) => {
    const areContentItemElementsHighlighting = useSelector(
      (s) => s.contentApp.areContentItemElementsHighlighting,
    );
    const canUpdateContent = useSelector((s) =>
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s),
    );
    const errorMessages = useSelector(
      (s) => getComponentElementErrorMessages(s, validationResultSelectorId),
      areShallowEqual,
    );
    const friendlyWarningMessages = useSelector(
      (s) => getComponentElementFriendlyWarningMessages(s, validationResultSelectorId),
      areShallowEqual,
    );
    const isFocused = useSelector((s) =>
      getIsComponentElementFocused(s, validationResultSelectorId),
    );
    const isHighlighted = useSelector((s) =>
      getIsContentElementHighlighted(s, validationResultSelectorId, hideValidationStatus),
    );
    const showLimitationWarning = useSelector((s) =>
      getShowLimitWarning(s, validationResultSelectorId, hideValidationStatus),
    );

    const { firstUnresolvedThreadId, hasFocusedComment } = useSelector((state) => {
      const unresolvedThreads = getUnresolvedThreads(
        state,
        typeElement.elementId,
        contentComponentId,
      );
      return {
        firstUnresolvedThreadId: unresolvedThreads?.[0]?.id ?? null,
        hasFocusedComment: getHasFocusedComment(state, typeElement, contentComponentId),
      };
    }, areShallowEqual);

    const validationPassed = useSelector((s) => getValidationPassed(s, validationResultSelectorId));

    const dispatch = useDispatch();
    const focusItemElement = useCallback(() => dispatch(focusFirstIncompleteItemElement()), []);

    const { refObject: scrollTargetRef, refToForward } = useAttachRef(ref);

    const onNewComment = useCallback(() => {
      dispatch(
        startNewInlineCommentThread({
          externalSegmentId: createGuid(),
          elementId: typeElement.elementId,
          itemType: CommentThreadItemType.Comment,
          threadType: getCommentThreadType(typeElement.type),
          contentComponentId,
          elementSegment: null,
        }),
      );
    }, [typeElement, contentComponentId]);

    const showAiReviewButton = useSelector((s) =>
      shouldShowAiReviewButton(s, typeElement, contentComponentId),
    );

    return (
      <>
        <ItemElementComponent
          ref={refToForward}
          areContentItemElementsHighlighting={areContentItemElementsHighlighting}
          canUpdateContent={canUpdateContent}
          className={className}
          commentThreadId={firstUnresolvedThreadId}
          contentComponentId={contentComponentId}
          defaultTabIndex={defaultTabIndex}
          disabled={disabled}
          elementSupportsComments={areCommentSupported(typeElement.type)}
          errorMessages={errorMessages}
          escapeHandledByEditor={escapeHandledByEditor}
          focusEditor={focusEditor}
          focusItemElement={focusItemElement}
          friendlyWarningMessages={friendlyWarningMessages}
          onNewComment={onNewComment}
          hasFocusedComment={hasFocusedComment}
          headerClassName={headerClassName}
          hideErrorMessages={disabled}
          isCompact={isCompact}
          isFocused={isFocused}
          isHighlighted={isHighlighted}
          isLocked={false}
          lockedByUser={null}
          onContentClick={onContentClick}
          onHeaderClick={onHeaderClick}
          showAiReviewButton={showAiReviewButton}
          showElementStatus={typeElement.isRequired && !hideValidationStatus}
          showLimitationWarning={showLimitationWarning}
          title={title}
          typeElement={typeElement}
          validationPassed={validationPassed}
        >
          {children}
        </ItemElementComponent>
        <ContentComponentItemElementAutoScroll
          typeElement={typeElement}
          scrollTargetRef={scrollTargetRef}
          onCustomScrollToTarget={onCustomScrollToTarget}
        />
      </>
    );
  },
);
