import {
  Subscription_PlanChange_CloseDialog,
  Subscription_PlanChange_ShowDialog,
  Subscription_PlanSelection_ClearConfirmation,
} from '../constants/actionTypes.ts';

export const closeChangePlanDialog = () =>
  ({
    type: Subscription_PlanChange_CloseDialog,
  }) as const;

export const showChangePlanDialog = (selectedPlanId: Uuid) =>
  ({
    type: Subscription_PlanChange_ShowDialog,
    payload: {
      selectedPlanId,
    },
  }) as const;

export const clearConfirmationMessage = () =>
  ({
    type: Subscription_PlanSelection_ClearConfirmation,
  }) as const;
