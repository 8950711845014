import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { LocalizedRouteLeft } from '../../../../applications/contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { Calendar_Page_Left } from '../../../../applications/contentPlanning/calendar/constants/calendarActionTypes.ts';
import {
  ModalContentItemSelector_Closed,
  ModalContentItemSelector_Opened,
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../applications/features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFailed,
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFailed,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFailed,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  Data_ListingContentItems_Started,
  Data_ListingContentItems_Success,
} from '../../../constants/dataActionTypes.ts';

export const loadingStatus = (
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus => {
  switch (action.type) {
    case LocalizedRouteLeft:
    case Shared_CurrentProjectId_Updated:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
    case ModalContentItemSelector_Closed:
    case ModalContentItemSelector_Opened:
    case Calendar_Page_Left:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_CascadeModal_PublishingFailed:
    case ContentEditing_CascadeModal_SchedulingFailed:
    case ContentEditing_CascadeAction_UndoFailed:
      return LoadingStatus.InitialEmpty;

    case Data_ListingContentItems_Success:
      return LoadingStatus.Loaded;

    case Data_ListingContentItems_Started:
      return LoadingStatus.Loading;

    default:
      return state;
  }
};
