import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  Spacing,
  Typography,
  colorSuggestionBackgroundHighlighted,
} from '@kontent-ai/component-library/tokens';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { TextToParagraph } from '../../../../_shared/components/StringRenderers.tsx';
import { CommentThreadItemContent } from '../../../itemEditor/features/ContentItemEditing/components/comments/threadItem/CommentThreadItemContent.tsx';
import { isComment } from '../../../itemEditor/models/comments/Comment.ts';
import { ICommentThreadItem } from '../../../itemEditor/models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../itemEditor/models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../itemEditor/models/comments/Suggestion.ts';

const SuggestionCellContentWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <Inline css="min-width:0" spacingY={Spacing.S} spacingX={Spacing.XS} noWrap>
    <Box typography={Typography.LabelLarge}>Suggestion:</Box>
    <Box backgroundColor={colorSuggestionBackgroundHighlighted} minWidth={0}>
      {children}
    </Box>
  </Inline>
);
SuggestionCellContentWrapper.displayName = 'SuggestionCellContentWrapper';

const CommentCellContentWrapper = styled(Box).attrs({
  minWidth: 0,
  overflow: 'hidden',
  paddingY: Spacing.S,
  whiteSpace: 'nowrap',
})`
  // Override the default RTE styles to make it one-line and truncated
  & .rte__content .rte__paragraph {
    white-space: nowrap;

    & > .public-DraftStyleDefault-block {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
CommentCellContentWrapper.displayName = 'CommentCellContentWrapper';

type CommentContentProps = Readonly<{
  commentThread: ICommentThread;
  commentThreadItem: ICommentThreadItem;
}>;

export const CommentCellContent: React.FC<CommentContentProps> = ({
  commentThreadItem,
  commentThread,
}) => {
  if (isSuggestion(commentThreadItem)) {
    return (
      <SuggestionCellContentWrapper>
        <TextToParagraph
          css="overflow: hidden; text-overflow: ellipsis;"
          text={commentThreadItem.suggestedText}
        />
      </SuggestionCellContentWrapper>
    );
  }

  if (isComment(commentThreadItem)) {
    return (
      <CommentCellContentWrapper>
        <CommentThreadItemContent
          commentThread={commentThread}
          commentThreadItem={commentThreadItem}
          suggestionApprovedBy={null}
          isApproveAnimating={false}
        />
      </CommentCellContentWrapper>
    );
  }

  return null;
};

CommentCellContent.displayName = 'CommentCellContent';
