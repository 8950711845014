import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { AssetDownloadButton } from '../../../../../../../_shared/components/AssetTile/AssetDownloadButton.tsx';
import { AssetUncategorizedLabel } from '../../../../../../../_shared/components/AssetTile/AssetUncategorizedLabel.tsx';
import { FailedAssetUploadActions } from '../../../../../../../_shared/components/AssetTile/FailedAssetUploadActions.tsx';
import { DragAction } from '../../../../../../../_shared/components/DragDrop/DragAction.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { TrackUserEventWithData } from '../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiAssetRenditionAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../../_shared/utils/func/compose.ts';
import { stopPropagation } from '../../../../../../../_shared/utils/func/functionalTools.ts';
import { IImageTransformation } from '../../../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { CommentsContext } from '../../../../../components/CommentsContext.tsx';
import { CommentButton } from '../../../containers/elements/subComponents/CommentButton.tsx';

export interface IAssetTileActionsStateProps {
  readonly isDragDisabled?: boolean;
  readonly openRenditionDialogDisabledMessage: string | null;
}

export interface IAssetTileActionsDispatchProps {
  readonly trackUserEvent: TrackUserEventWithData;
}

export enum AssetTileAction {
  AddComment = 'addComment',
  Edit = 'edit',
  Delete = 'delete',
  Download = 'download',
  OpenRendition = 'openRendition',
}

export interface IAssetTileActionsOwnProps {
  readonly actions: ReadonlyArray<AssetTileAction>;
  readonly asset: IAsset;
  readonly commentThreadId: Uuid | null;
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled: boolean;
  readonly hasFocusedComment: boolean;
  readonly isUncategorized: boolean | null;
  readonly onNewComment: () => void;
  readonly onOpenRenditionDialog: (() => void) | undefined;
  readonly onRemove: (() => void) | undefined;
  readonly transformation?: IImageTransformation;
}

type AssetTileActionsProps = IAssetTileActionsStateProps &
  IAssetTileActionsDispatchProps &
  IAssetTileActionsOwnProps;

export const AssetTileActions: React.FC<AssetTileActionsProps> = ({
  actions,
  asset,
  commentThreadId,
  connectDragSource,
  disabled,
  hasFocusedComment,
  isDragDisabled,
  isUncategorized,
  onNewComment,
  onOpenRenditionDialog,
  onRemove,
  openRenditionDialogDisabledMessage,
  trackUserEvent,
  transformation,
}) => {
  const { allowNewComments } = useContext(CommentsContext);

  if (asset._failed) {
    return <FailedAssetUploadActions onRemove={onRemove} />;
  }

  if (asset.archived || asset._uploading) {
    return null;
  }

  const hasComment = !!commentThreadId;
  const hasRendition = !!transformation;

  return (
    actions && (
      <div
        className={classNames('asset-thumbnail__actions-pane', {
          'asset-thumbnail__actions-pane--has-comment': hasComment,
          'asset-thumbnail__actions-pane--has-focused-comment': hasFocusedComment,
        })}
      >
        <div className="asset-thumbnail__actions-left">
          <DragAction connectDragSource={connectDragSource} disabled={isDragDisabled} />
          {isUncategorized && (
            <Box css="display: flex; align-items: center;" paddingLeft={Spacing.XS}>
              <AssetUncategorizedLabel />
            </Box>
          )}
        </div>

        {actions.includes(AssetTileAction.OpenRendition) && (
          <Box paddingRight={Spacing.S}>
            <QuinaryButton
              onClick={onOpenRenditionDialog ? onOpenRenditionDialog : () => undefined}
              tooltipPlacement="bottom"
              tooltipText={openRenditionDialogDisabledMessage ?? 'Customize'}
              disabled={!!openRenditionDialogDisabledMessage}
              {...getDataUiActionAttribute(
                hasRendition
                  ? DataUiAssetRenditionAction.ChangeAssetRendition
                  : DataUiAssetRenditionAction.CreateAssetRendition,
              )}
            >
              <QuinaryButton.Icon icon={Icons.Crop} screenReaderText="Customize" />
            </QuinaryButton>
          </Box>
        )}

        {actions.includes(AssetTileAction.AddComment) && (allowNewComments || hasComment) && (
          <CommentButton
            commentThreadId={commentThreadId}
            hasFocusedComment={hasFocusedComment}
            onNewComment={onNewComment}
          />
        )}

        {actions.includes(AssetTileAction.Download) && (
          <AssetDownloadButton
            asset={asset}
            trackUserEvent={trackUserEvent}
            transformation={transformation}
          />
        )}

        {actions.includes(AssetTileAction.Delete) && !disabled && (
          <Tooltip tooltipText="Remove" placement="bottom">
            <div
              className="asset-thumbnail__action asset-thumbnail__action--remove"
              onClick={compose(onRemove || noOperation, stopPropagation)}
              // Do not propagate focus into ItemElement.
              // This is needed so that ItemElement doesn't stay focused when asset tile is removed.
              onFocus={stopPropagation}
            >
              <Icon
                iconName={IconName.Times}
                dataAttribute={getDataUiActionAttribute(DataUiAction.Delete)}
              />
            </div>
          </Tooltip>
        )}
      </div>
    )
  );
};

AssetTileActions.displayName = 'AssetTileActions';
