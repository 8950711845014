import { forwardRef, useCallback } from 'react';
import { modalDismissed } from '../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEventWithData } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { AppNames } from '../../../../../../_shared/constants/applicationNames.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { ConfirmationDialogEventTypes } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { moveSelectedVariantsToArchivedStep } from '../actions/thunkContentItemInventoryActions.ts';

export const MoveItemsToArchivedStepConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();

  const handleConfirm = useCallback(() => {
    dispatch(moveSelectedVariantsToArchivedStep());
    dispatch(
      trackUserEventWithData(TrackedEvent.MoveItemsToArchivedStepConfirmationDialog, {
        action: ConfirmationDialogEventTypes.Confirm,
      }),
    );
  }, []);

  const handleCancel = useCallback(
    () =>
      dispatch(
        trackUserEventWithData(TrackedEvent.MoveItemsToArchivedStepConfirmationDialog, {
          action: ConfirmationDialogEventTypes.Cancel,
        }),
      ),
    [],
  );

  const handleClose = useCallback(() => dispatch(modalDismissed()), []);

  return (
    <ConfirmationDialog
      alert
      confirmButtonIcon="Box"
      confirmButtonText="Archive"
      headerContent="Archive items"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onClose={handleClose}
      ref={ref}
      {...props}
    >
      <p className="confirmation-dialog__section">
        Archiving makes the item read-only and moves it to the Archived step. You can still find the
        item in {AppNames.Content} and it can be restored later if needed.
      </p>
    </ConfirmationDialog>
  );
});

MoveItemsToArchivedStepConfirmationDialog.displayName = 'MoveItemsToArchivedStepConfirmationDialog';
