import { ActiveCapabilityType } from '../../_shared/models/activeCapability.type.ts';
import {
  IActiveCapabilitiesForVariantServerModel,
  IActiveCapabilitiesServerModel,
} from '../../repositories/serverModels/INewContentItemServerModel.ts';

export interface IActiveCapabilitiesForVariant {
  readonly variantCapabilities: ReadonlyArray<ActiveCapabilityType>;
}

export interface IActiveCapabilities extends IActiveCapabilitiesForVariant {
  readonly contentGroupsForViewCapability: UuidArray;
  readonly contentGroupsForUpdateCapability: UuidArray;
}

export const createActiveCapabilitiesForVariantFromServerModel = (
  serverModel: IActiveCapabilitiesForVariantServerModel,
): IActiveCapabilitiesForVariant => {
  if (!serverModel || typeof serverModel !== 'object') {
    throw new Error(`activeCapabilities.ts, invalid server model: ${JSON.stringify(serverModel)}`);
  }

  return {
    variantCapabilities: serverModel?.variantCapabilities ?? [],
  };
};

export const createActiveCapabilitiesFromServerModel = (
  serverModel: IActiveCapabilitiesServerModel,
): IActiveCapabilities => {
  return {
    ...createActiveCapabilitiesForVariantFromServerModel(serverModel),
    contentGroupsForViewCapability: serverModel?.contentGroupsForViewCapability ?? [],
    contentGroupsForUpdateCapability: serverModel?.contentGroupsForUpdateCapability ?? [],
  };
};
