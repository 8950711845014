import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { createHandleCurrentItemOverwrittenAction } from './thunks/handleCurrentItemOverwritten.ts';
import { createHandleDefaultVariantOverwrittenAction } from './thunks/handleDefaultVariantOverwritten.ts';

const { contentItemRepository } = repositoryCollection;

export const handleCurrentItemOverwritten = createHandleCurrentItemOverwrittenAction({
  contentItemRepository,
});

export const handleDefaultVariantOverwritten = createHandleDefaultVariantOverwrittenAction({
  contentItemRepository,
});
