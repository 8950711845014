import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { IntersectionObserverRootContextProvider } from '../../hooks/IntersectionObserverRootContext.tsx';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = Omit<BodyProps, 'containerRef' | 'bodyRef'> & {
  readonly fillAvailableSpace?: boolean;
  readonly noShadow?: boolean;
  readonly renderTableActions?: () => JSX.Element;
  readonly renderTitle?: () => JSX.Element;
};

export const SimpleScrollTable: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  fillAvailableSpace,
  noShadow,
  renderTableActions,
  renderTitle,
  ...bodyProps
}) => {
  const renderedTitle = renderTitle?.();
  const renderedTableActions = renderTableActions?.();
  const hasStatus = renderedTitle || renderedTableActions;

  const bodyRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames('scroll-table', {
        'scroll-table--fill-available-space': fillAvailableSpace,
        'scroll-table--no-shadow': noShadow,
      })}
      {...getDataUiElementAttribute(DataUiElement.ScrollTable)}
    >
      {hasStatus && (
        <div className="scroll-table__status">
          {renderedTitle}
          {renderedTableActions}
        </div>
      )}

      {bodyProps.noScroll ? (
        <SimpleScrollTableBody {...bodyProps} bodyRef={bodyRef} containerRef={containerRef}>
          {children}
        </SimpleScrollTableBody>
      ) : (
        // Only use root context for intersection observer if the table is scrollable, otherwise we want to keep root from parent scrolling container
        <IntersectionObserverRootContextProvider rootRef={containerRef}>
          <SimpleScrollableScrollTableBody
            bodyRef={bodyRef}
            containerRef={containerRef}
            {...bodyProps}
          >
            {children}
          </SimpleScrollableScrollTableBody>
        </IntersectionObserverRootContextProvider>
      )}
    </div>
  );
};

SimpleScrollTable.displayName = 'SimpleScrollTable';

type BodyProps = {
  readonly bodyRef: React.RefObject<HTMLDivElement>;
  readonly collectionName: DataUiCollection;
  readonly containerRef: React.RefObject<HTMLDivElement>;
  readonly noScroll?: boolean;
  readonly renderEmptyState?: () => JSX.Element;
  readonly renderHead: (hasScrollbar: boolean) => JSX.Element;
};

const SimpleScrollTableBody: React.FC<React.PropsWithChildren<BodyProps>> = ({
  bodyRef,
  children,
  collectionName,
  containerRef,
  noScroll,
  renderEmptyState,
  renderHead,
}) => (
  <>
    {renderHead(!noScroll)}
    {!children && renderEmptyState ? (
      renderEmptyState()
    ) : (
      <div
        className={classNames('scroll-table__body-container', {
          'scroll-table__body-container--no-scroll': noScroll,
        })}
        ref={containerRef}
        {...getDataUiCollectionAttribute(collectionName)}
      >
        <div className="scroll-table__body" ref={bodyRef}>
          {children}
        </div>
      </div>
    )}
  </>
);

SimpleScrollTableBody.displayName = 'SimpleScrollTableBody';

const SimpleScrollableScrollTableBody: React.FC<React.PropsWithChildren<BodyProps>> = (props) => {
  const { bodyRef, containerRef } = props;

  const [isScrollable, setIsScrollable] = useState(false);

  const { height: tableBodyHeight = 0 } = useResizeObserver({ ref: bodyRef });
  const { height: tableContainerHeight = 0 } = useResizeObserver({ ref: containerRef });

  useEffect(() => {
    setIsScrollable(tableBodyHeight > tableContainerHeight);
  }, [tableBodyHeight, tableContainerHeight]);

  return <SimpleScrollTableBody {...props} noScroll={!isScrollable || props.noScroll} />;
};

SimpleScrollableScrollTableBody.displayName = 'SimpleScrollableScrollTableBody';
