import React from 'react';

type Props = {
  readonly level?: number;
};

const Indent: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { level } = props;

  if (!level || level <= 0) {
    return <>{props.children}</>;
  }

  return (
    <div className="indent">
      <Indent level={level - 1}>{props.children}</Indent>
    </div>
  );
};

Indent.displayName = 'Indent';

export { Indent };
