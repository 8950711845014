import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { listingFilterChanged } from '../../../../contentInventory/content/features/ContentItemInventory/actions/contentItemInventoryActions.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';

interface IFilterContentItemActionDependencies {
  readonly ContentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadListingContentItemsForInventory: (params: {
    variantIds: ReadonlySet<Uuid>;
  }) => ThunkPromise;
  readonly trackIntercomFindRightContentEvent: (
    oldFilterState: IListingFilter,
    newFilterState: IListingFilter,
  ) => ThunkFunction;
}

export const createFilterContentItemsAction =
  ({
    ContentItemFilterStorage,
    loadListingContentItemsForInventory,
    trackIntercomFindRightContentEvent,
  }: IFilterContentItemActionDependencies) =>
  (variantIds: ReadonlySet<Uuid>): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
    } = getState();
    const storedFilter = ContentItemFilterStorage?.load(currentProjectId);

    dispatch(listingFilterChanged());

    if (!savedFiltersUi.lastFilteredUsingSavedFilter && storedFilter) {
      dispatch(trackIntercomFindRightContentEvent(storedFilter, filter));
    }
    ContentItemFilterStorage?.save(filter, currentProjectId);

    await dispatch(loadListingContentItemsForInventory({ variantIds }));
  };
