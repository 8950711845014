import React, { memo } from 'react';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { useIsElementEditableInEditedVariant } from '../hooks/useIsElementEditableInEditedVariant.ts';
import { EditableContentItemElement } from './EditableContentItemElement.tsx';
import { NonEditableContentItemElement } from './NonEditableContentItemElement.tsx';
import { Guidelines } from './elements/Guidelines.tsx';

interface IContentItemElementProps {
  readonly canEdit: boolean;
  readonly typeElement: TypeElement;
}

const ContentItemElement: React.FC<IContentItemElementProps> = ({
  canEdit,
  typeElement,
}): JSX.Element | null => {
  const isEditableInVariant = useIsElementEditableInEditedVariant(typeElement);

  if (isGuidelinesTypeElement(typeElement)) {
    return <Guidelines typeElement={typeElement} disabled={!canEdit} />;
  }

  if (!isEditableInVariant) {
    return <NonEditableContentItemElement typeElement={typeElement} />;
  }

  return <EditableContentItemElement typeElement={typeElement} canEdit={canEdit} />;
};

ContentItemElement.displayName = 'ContentItemElement';

const ContentItemElementMemo = memo(ContentItemElement);

export { ContentItemElementMemo as ContentItemElement };
