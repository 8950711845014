import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  AssetLibrary_Query_Loaded,
  AssetLibrary_Query_Loading,
  AssetLibrary_Route_Left,
} from '../../constants/assetLibraryActionTypes.ts';
import { AssetQueryOrigin } from '../../utils/assetLibraryQueryUtils.ts';

const initialStatus: Immutable.Map<AssetQueryOrigin, LoadingStatus> = Immutable.Map([
  [AssetQueryOrigin.AssetLibrary, LoadingStatus.InitialEmpty],
]);

export function queryLoadingStatus(
  state: Immutable.Map<AssetQueryOrigin, LoadingStatus> = initialStatus,
  action: Action,
): Immutable.Map<AssetQueryOrigin, LoadingStatus> {
  switch (action.type) {
    case AssetLibrary_Route_Left: {
      const origin = action.payload.origin;
      return state.set(origin, LoadingStatus.InitialEmpty);
    }

    case AssetLibrary_Query_Loading: {
      const origin = action.payload.origin;
      return state.set(origin, LoadingStatus.Loading);
    }

    case AssetLibrary_Query_Loaded: {
      const origin = action.payload.origin;
      return state.set(origin, LoadingStatus.Loaded);
    }

    default:
      return state;
  }
}
