import { assert } from '@kontent-ai/utils';
import { ReactNode, createContext, useContext, useRef } from 'react';
import { createAiTaskManager } from '../utils/aiTasks/aiTaskManager.ts';
import { useAiMessageBuffer } from './AiMessageBufferProvider.tsx';

type AiTaskManager = ReturnType<typeof createAiTaskManager>;

const AiTaskManagerContext = createContext<AiTaskManager | null>(null);
AiTaskManagerContext.displayName = 'AiTaskManager';

type Props = {
  readonly children: ReactNode;
};

export const AiTaskManagerProvider = (props: Props) => {
  const aiMessageBuffer = useAiMessageBuffer();
  const aiTaskManagerRef = useRef(createAiTaskManager(aiMessageBuffer));

  return (
    <AiTaskManagerContext.Provider value={aiTaskManagerRef.current}>
      {props.children}
    </AiTaskManagerContext.Provider>
  );
};

export const useAiTaskManager = (): AiTaskManager => {
  const aiTaskManager = useContext(AiTaskManagerContext);
  assert(
    aiTaskManager,
    () => `"${AiTaskManagerContext.displayName}" is missing in the current tree.`,
  );
  return aiTaskManager;
};
