import Immutable from 'immutable';
import { withAutoDispatcher } from '../../../../_shared/components/AutoDispatcher.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { isLanguageActive } from '../../../../_shared/models/utils/isLanguageActive.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ILanguage, Languages } from '../../../../data/models/languages/Language.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ISubscriptionUsage } from '../../../subscriptionManagement/shared/models/SubscriptionUsage.ts';
import { showLanguageCreateForm } from '../actions/localizationActions.ts';
import { initLanguagesEditor, saveLanguagesToServer } from '../actions/thunkLocalizationActions.ts';
import { LanguageListing as LanguageListingComponent } from '../components/LanguageListing.tsx';

const isSubscriptionLimitReached = (
  subscriptionUsage: ISubscriptionUsage | undefined,
  languages: Immutable.Map<Uuid, ILanguage>,
  plans: Immutable.Map<Uuid, IPlan>,
): boolean => {
  if (!subscriptionUsage) {
    return true;
  }

  const plan = plans.get(subscriptionUsage.planId);
  const maxLanguages = plan ? plan.features.maxActiveLanguages : null;
  if (!maxLanguages) {
    return false;
  }

  return languages.count(isLanguageActive) + 1 >= maxLanguages;
};

const ConnectedLanguagesListing = () => {
  useThunkPromise(initLanguagesEditor);

  const languageLimitReached = useSelector((s) => {
    const {
      data: {
        plans,
        subscriptions: { subscriptionUsages },
      },
      localizationApp: { languages },
    } = s;
    const subscriptionUsage = subscriptionUsages.get(getCurrentProject(s).subscriptionId);
    return isSubscriptionLimitReached(subscriptionUsage, languages, plans.byId);
  });

  const isCreatingAllowed = useSelector(
    (s) =>
      !s.localizationApp.editedLanguageId &&
      !s.localizationApp.isDefaultLanguageEdited &&
      !languageLimitReached,
  );

  const isEditedLanguageNew = useSelector((s) => s.localizationApp.isEditedLanguageNew);
  const shouldRenderIntro = useSelector((s) => s.localizationApp.languages.isEmpty());
  const isLocalizationEditorInitialized = useSelector(
    (s) => s.localizationApp.loadingStatus === LoadingStatus.Loaded,
  );

  const languages = useSelector((s) => {
    return Immutable.List(
      s.localizationApp.languageItemsOrder
        .toArray()
        .map((item) => s.localizationApp.languages.get(item))
        .filter((item) => !!item),
    );
  });

  const dispatch = useDispatch();

  return (
    <LanguageListingComponent
      onCreateNewClick={() => dispatch(showLanguageCreateForm())}
      isEditedLanguageNew={isEditedLanguageNew}
      isLimitReached={languageLimitReached}
      shouldRenderIntro={shouldRenderIntro}
      isCreatingAllowed={isCreatingAllowed}
      isLocalizationEditorInitialized={isLocalizationEditorInitialized}
      languages={languages}
    />
  );
};

type ObservedState = {
  readonly defaultLanguage: ILanguage;
  readonly languages: Languages;
  readonly languageItemsOrder: Immutable.List<Uuid>;
};

const mapObservedState = (state: IStore): ObservedState => ({
  defaultLanguage: state.localizationApp.defaultLanguage,
  languages: state.localizationApp.languages,
  languageItemsOrder: state.localizationApp.languageItemsOrder,
});

const shouldDispatch = (_oldState: IStore, newState: IStore): boolean => {
  const serverErrorNextState = newState.projectsApp.serverError;
  const languagesHavePendingChanges = newState.localizationApp.languagesHavePendingChanges;

  return !serverErrorNextState && languagesHavePendingChanges;
};

export const LanguagesListing = withAutoDispatcher<NoProps, ObservedState>(
  mapObservedState,
  saveLanguagesToServer,
  500,
  shouldDispatch,
)(ConnectedLanguagesListing);
