import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  ITaxonomySelectorDataProps,
  ITaxonomySelectorOwnProps as ITaxonomySelectorOwnComponentProps,
  TaxonomySelector as TaxonomySelectorComponent,
} from '../../../components/elements/taxonomy/TaxonomySelector.tsx';

interface ITaxonomySelectorOwnProps extends ITaxonomySelectorOwnComponentProps {
  readonly taxonomyGroupId: Uuid | null;
}

function mapStateToProps(
  state: IStore,
  ownProps: ITaxonomySelectorOwnProps,
): ITaxonomySelectorDataProps {
  const {
    data: { taxonomyGroups },
  } = state;

  if (!ownProps.taxonomyGroupId) {
    throw InvariantException('[TaxonomySelector.tsx] ownProps.taxonomyGroupId is falsy.');
  }

  return {
    taxonomyGroup: taxonomyGroups.byId.get(ownProps.taxonomyGroupId) ?? null,
    taxonomyGroupsLoadingStatus: taxonomyGroups.loadingStatus,
  };
}

export const TaxonomySelector: React.ComponentType<ITaxonomySelectorOwnProps> =
  connect(mapStateToProps)(TaxonomySelectorComponent);
