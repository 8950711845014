import { getDeliveryContentComponentId } from '../../../itemEditor/features/ContentComponent/utils/deliveryContentComponentUtils.ts';
import { IContentComponent } from '../../../itemEditor/models/contentItem/ContentComponent.ts';

export const getDeliveryContentComponentIdMap = async (
  itemId: Uuid,
  variantId: Uuid,
  rootRichTextElementId: Uuid,
  contentComponents: ReadonlyArray<IContentComponent>,
): Promise<ReadonlyMap<Uuid, string>> => {
  const promises = contentComponents.map(async (component) => {
    const deliverId = await getDeliveryContentComponentId(
      itemId,
      variantId,
      rootRichTextElementId,
      component.id,
    );
    return [component.id, deliverId] as const;
  });

  return new Map(await Promise.all(promises));
};
