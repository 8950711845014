import { combineReducers } from '@reduxjs/toolkit';
import { byId } from './reducers/byId.ts';
import { loadingStatus } from './reducers/loadingStatus.ts';
import { usage } from './reducers/usage.ts';

export const workflows = combineReducers({
  byId,
  loadingStatus,
  usage,
});
