import React from 'react';
import { modalDismissed } from '../../../../actions/sharedActions.ts';
import {
  WorkflowEditingRoute,
  WorkflowEditingRouteParams,
} from '../../../../constants/routePaths.ts';
import { useDispatch } from '../../../../hooks/useDispatch.ts';
import { useSelector } from '../../../../hooks/useSelector.ts';
import { Capability } from '../../../../utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../utils/permissions/capabilityUtils.ts';
import { buildPath } from '../../../../utils/routing/routeTransitionUtils.ts';
import { ConfigureWorkflowLink } from '../../components/Elements/ConfigureWorkflowLink.tsx';

type Props = {
  readonly workflowId: Uuid;
};

const ConfigureWorkflowLinkContainer: React.FC<Props> = ({ workflowId }) => {
  const workflowPath = useSelector((state) =>
    buildPath<WorkflowEditingRouteParams>(WorkflowEditingRoute, {
      projectId: state.sharedApp.currentProjectId,
      workflowId,
    }),
  );

  const canManageWorkflow = useSelector((state) =>
    currentUserHasCapabilities(state, Capability.ConfigureWorkflow),
  );

  const dispatch = useDispatch();
  const dismissModal = () => dispatch(modalDismissed());

  if (!canManageWorkflow) {
    return null;
  }

  return <ConfigureWorkflowLink onClick={dismissModal} workflowPath={workflowPath} />;
};

ConfigureWorkflowLinkContainer.displayName = 'ConfigureWorkflowLinkContainer';

export { ConfigureWorkflowLinkContainer as ConfigureWorkflowLink };
