import { Action } from '../../../../../@types/Action.type.ts';
import {
  Workflow_Editing_CodenameChanged,
  Workflow_Editing_Left,
  Workflow_Editing_WorkflowSaveFinished,
  Workflow_InitializeCreating_Finished,
  Workflow_InitializeEditing_Finished,
} from '../../constants/workflowActionTypes.ts';

export const editedWorkflowCodename = (
  state: string | null = null,
  action: Action,
): string | null => {
  switch (action.type) {
    case Workflow_InitializeEditing_Finished:
      return action.payload.workflow.codename;

    case Workflow_InitializeCreating_Finished:
      return null;

    case Workflow_Editing_WorkflowSaveFinished:
      return action.payload.workflow.codeName;

    case Workflow_Editing_CodenameChanged:
      return action.payload.codename;

    case Workflow_Editing_Left:
      return null;

    default:
      return state;
  }
};
