import { combineReducers } from '@reduxjs/toolkit';
import { draggedContentGroupId } from './editor/draggedContentGroupId.ts';
import { draggedMultipleChoiceOptionId } from './editor/draggedMultipleChoiceOptionId.ts';
import { editedMultipleChoiceElement } from './editor/editedMultipleChoiceElement.ts';
import { isEditedContentTypeUsedInPublishedItem } from './editor/isEditedContentTypeUsedInPublishedItem.ts';
import { selectedContentGroupId } from './editor/selectedContentGroupId.ts';
import { selectedTypeElementIds } from './editor/selectedTypeElementIds.ts';
import { showConfigurationForElements } from './editor/showConfigurationForElements.ts';

export const editorReducer = combineReducers({
  draggedContentGroupId,
  draggedMultipleChoiceOptionId,
  editedMultipleChoiceElement,
  isEditedContentTypeUsedInPublishedItem,
  selectedContentGroupId,
  selectedTypeElementIds,
  showConfigurationForElements,
});
