import { ElementType } from '../../contentInventory/content/models/ContentItemElementType.ts';

export const CannotPublishBecauseOfInaccessibleWarningsInEditing =
  'This content item can’t be published yet. There are incomplete elements you can not access because of your permissions.';
export const CannotPublishBecauseOfWarningsInEditing =
  'This content item can’t be published yet. Make sure the highlighted elements are filled in correctly.';
export const CommentThreadIsInaccessible =
  'You do not have permissions to view the comment thread. For more information reach out to your supervisor.';
export const DateTimeInvalidValue =
  'Provide a date in the "month day, year" format, for example Oct 4, 2020, 4:00 PM.';
export const IncompleteElementsAreInaccessible =
  'There are incomplete elements you can not access because of your permissions.';
export const LinkedItemsInvalidValue = (elementType: ElementType) =>
  `Value must be a list of ${elementType === ElementType.Subpages ? 'pages' : 'Linked items'}.`;
export const NumberIsNotValid =
  'Provide a number in a format that you are used to, otherwise you can leave the field empty.';
export const NumberIsTooLargeError =
  'Provide a smaller number. This number is too large and cannot be saved.';
export const SelectedItemsCannotBePublished =
  'Some items cannot be published as they were filled incorrectly.';
export const TaxonomyIsNotValid = 'Value of Taxonomy Elements is not a set of GUIDs.';
export const TextTooLongToSaveError = 'Provide a shorter value. You hit the maximum limit.';
export const RelatedItemDoesNotExistReason =
  'This could be caused by a failed import or issues when cloning the project.';
export const CannotCreateNewVariantForItemError =
  'You can’t create a new language variant of this item.';
