import { Action } from '../../../../../@types/Action.type.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { moveToIndex } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { moveItem } from '../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { IContentTypeSnippet } from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { IMultipleChoiceTypeElementData } from '../../../shared/models/elements/MultipleChoiceTypeElementData.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { isMultipleChoiceTypeElement } from '../../../shared/types/typeElementTypeGuards.ts';
import { getUpdatedElements } from '../../../shared/utils/typeUtils.ts';
import {
  ContentTypeSnippet_Creator_CreationFinished,
  ContentTypeSnippet_Creator_InitStarted,
  ContentTypeSnippet_Editor_ChangeCodename,
  ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename,
  ContentTypeSnippet_Editor_ChangeName,
  ContentTypeSnippet_Editor_ChangeTypeElementCodename,
  ContentTypeSnippet_Editor_InitFinished,
  ContentTypeSnippet_Editor_InitStarted,
  ContentTypeSnippet_Editor_MoveTypeElement,
  ContentTypeSnippet_Editor_MultipleChoiceCreateOption,
  ContentTypeSnippet_Editor_MultipleChoiceDeleteOption,
  ContentTypeSnippet_Editor_MultipleChoiceMoveOption,
  ContentTypeSnippet_Editor_MultipleChoiceUpdateOption,
  ContentTypeSnippet_Editor_RemoveTypeElement,
  ContentTypeSnippet_Editor_SavingFinished,
  ContentTypeSnippet_Editor_TypeElementCreated,
  ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd,
  ContentTypeSnippet_Editor_TypeElementUpdated,
} from '../../constants/snippetActionTypes.ts';

const initialState: IContentTypeSnippet = {
  id: '',
  name: '',
  codename: null,
  typeElements: [],
  lastModified: null,
  lastModifiedBy: null,
  isArchived: false,
};

export function editedContentTypeSnippet(
  state: IContentTypeSnippet = initialState,
  action: Action,
): IContentTypeSnippet {
  switch (action.type) {
    case ContentTypeSnippet_Creator_CreationFinished:
    case ContentTypeSnippet_Editor_InitFinished: {
      return action.payload.editedContentTypeSnippet;
    }

    case ContentTypeSnippet_Editor_SavingFinished: {
      return action.payload.contentTypeSnippet;
    }

    case ContentTypeSnippet_Editor_InitStarted:
    case ContentTypeSnippet_Creator_InitStarted: {
      return initialState;
    }

    case ContentTypeSnippet_Editor_ChangeName: {
      const name = action.payload.name;
      if (isEmptyOrWhitespace(name)) {
        return {
          ...state,
          name: initialState.name,
        };
      }
      const nameWithEnsuredLength = name.substring(0, ValidationConstants.SnippetNameMaxLength);

      return {
        ...state,
        name: nameWithEnsuredLength,
      };
    }

    case ContentTypeSnippet_Editor_ChangeCodename: {
      const codename = action.payload.codename;

      return {
        ...state,
        codename,
      };
    }

    case ContentTypeSnippet_Editor_TypeElementCreated: {
      const { position, typeElementData } = action.payload;

      return {
        ...state,
        typeElements: [
          ...state.typeElements.slice(0, position + 1),
          typeElementData,
          ...state.typeElements.slice(position + 1),
        ],
      };
    }

    case ContentTypeSnippet_Editor_TypeElementCreated_AtTheEnd: {
      return {
        ...state,
        typeElements: [...state.typeElements, action.payload.typeElementData],
      };
    }

    case ContentTypeSnippet_Editor_MoveTypeElement: {
      const { sourceId, targetId } = action.payload;
      if (sourceId === targetId) {
        return state;
      }

      const newTypeElements = moveItem(
        state.typeElements,
        sourceId,
        targetId,
        (element) => element.elementId,
      );
      if (newTypeElements === state.typeElements) {
        return state;
      }

      return {
        ...state,
        typeElements: newTypeElements,
      };
    }

    case ContentTypeSnippet_Editor_TypeElementUpdated: {
      const updatedTypeElement = action.payload.updatedSnippetTypeElement;
      const newState: IBaseTypeElementData = {
        ...updatedTypeElement,
        name: isEmptyOrWhitespace(updatedTypeElement.name) ? '' : updatedTypeElement.name,
        guidelines: isEmptyOrWhitespace(updatedTypeElement.guidelines)
          ? ''
          : updatedTypeElement.guidelines,
      };
      const elements = state.typeElements.map((element) =>
        element && element.elementId === updatedTypeElement.elementId ? newState : element,
      );

      return {
        ...state,
        typeElements: elements,
      };
    }

    case ContentTypeSnippet_Editor_RemoveTypeElement: {
      const newTypeElements = state.typeElements.filter(
        (element) => element?.elementId !== action.payload.typeElementId,
      );

      return {
        ...state,
        typeElements: newTypeElements,
      };
    }

    case ContentTypeSnippet_Editor_ChangeTypeElementCodename: {
      const { codename, elementId } = action.payload;
      const oldElement = state.typeElements.find(
        (element) => element && !!elementId && element.elementId === elementId,
      );
      if (!oldElement) {
        return state;
      }

      const newElement: IBaseTypeElementData = {
        ...oldElement,
        codename,
      };

      const elements = state.typeElements.map((element) =>
        element.elementId === newElement.elementId ? newElement : element,
      );

      return {
        ...state,
        typeElements: elements,
      };
    }

    case ContentTypeSnippet_Editor_MultipleChoiceCreateOption: {
      const { elementId, option } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements
        .filter(isMultipleChoiceTypeElement)
        .find((element) => element.elementId === elementId);
      if (
        !editedMultipleChoiceTypeElement ||
        Object.keys(editedMultipleChoiceTypeElement.options).length >=
          ValidationConstants.MaxNumberOfMultipleChoiceOptions
      ) {
        return state;
      }

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        options: {
          ...editedMultipleChoiceTypeElement.options,
          [option.id]: {
            id: option.id,
            label: option.label.substring(0, ValidationConstants.MultipleChoiceOptionNameMaxLength),
            codename: option.codename,
          },
        },
        optionsOrder: editedMultipleChoiceTypeElement.optionsOrder.concat([option.id]),
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentTypeSnippet_Editor_MultipleChoiceUpdateOption: {
      const { elementId, option } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements
        .filter(isMultipleChoiceTypeElement)
        .find((element) => element.elementId === elementId);
      const optionExists = !!editedMultipleChoiceTypeElement?.options[option.id];
      if (!option.label || !editedMultipleChoiceTypeElement || !optionExists) {
        return state;
      }

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        options: {
          ...editedMultipleChoiceTypeElement.options,
          [option.id]: {
            id: option.id,
            label: option.label.substring(0, ValidationConstants.MultipleChoiceOptionNameMaxLength),
            codename: option.codename,
          },
        },
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentTypeSnippet_Editor_MultipleChoiceDeleteOption: {
      const { elementId, optionId } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements
        .filter(isMultipleChoiceTypeElement)
        .find((element) => element.elementId === elementId);
      if (!editedMultipleChoiceTypeElement || !optionId) {
        return state;
      }
      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        defaultValue: editedMultipleChoiceTypeElement.defaultValue.filter((id) => id !== optionId),
        options: Object.fromEntries(
          Object.entries(editedMultipleChoiceTypeElement.options).filter(
            ([key]) => key !== optionId,
          ),
        ),
        optionsOrder: editedMultipleChoiceTypeElement.optionsOrder.filter((id) => id !== optionId),
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentTypeSnippet_Editor_MultipleChoiceMoveOption: {
      const { elementId, draggedOptionId, targetOptionId } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements
        .filter(isMultipleChoiceTypeElement)
        .find((element) => element.elementId === elementId);
      if (!editedMultipleChoiceTypeElement || !draggedOptionId || !targetOptionId) {
        return state;
      }

      const editedOptionsOrder = editedMultipleChoiceTypeElement.optionsOrder;
      const draggedOptionIndex = editedOptionsOrder.indexOf(draggedOptionId);
      const targetOptionIndex = editedOptionsOrder.indexOf(targetOptionId);
      if (draggedOptionIndex < 0 || targetOptionIndex < 0) {
        return state;
      }

      const updatedOptionsOrder = moveToIndex(
        editedOptionsOrder,
        draggedOptionId,
        targetOptionIndex,
      );

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        optionsOrder: updatedOptionsOrder,
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    case ContentTypeSnippet_Editor_ChangeMultipleChoiceOptionCodename: {
      const { codename, elementId, optionId } = action.payload;
      const editedMultipleChoiceTypeElement = state.typeElements
        .filter(isMultipleChoiceTypeElement)
        .find((element) => element.elementId === elementId);

      if (!editedMultipleChoiceTypeElement) {
        return state;
      }

      const originalOption = editedMultipleChoiceTypeElement.options[optionId];
      if (!originalOption) {
        return state;
      }

      const updatedElement: IMultipleChoiceTypeElementData = {
        ...editedMultipleChoiceTypeElement,
        options: {
          ...editedMultipleChoiceTypeElement.options,
          [optionId]: {
            ...originalOption,
            id: originalOption.id,
            label: originalOption.label,
            codename,
          },
        },
      };

      return {
        ...state,
        typeElements: getUpdatedElements(state.typeElements, updatedElement),
      };
    }

    default:
      return state;
  }
}
