import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ICollectionConfig } from '../reducers/INewContentItemStoreState.type.ts';
import {
  getRecentlyUsedCollectionIdsForEditing,
  getRecentlyUsedCollectionIdsForListing,
} from './getRecentlyUsedCollectionIds.ts';
import { getSuggestedCollectionIdsForListing } from './getSuggestedCollectionIds.ts';

export const getCollectionSelectorConfigForContentComponent = (): ICollectionConfig => ({
  recentlyUsedCollectionIds: null,
  suggestedCollectionIds: [],
});

export const getCollectionSelectorConfigForLinkedItems = (state: IStore): ICollectionConfig => ({
  recentlyUsedCollectionIds: getRecentlyUsedCollectionIdsForEditing(state),
  suggestedCollectionIds: [],
});

export const getCollectionSelectorConfigForWebSpotlight = (): ICollectionConfig => ({
  recentlyUsedCollectionIds: null,
  suggestedCollectionIds: [],
});

export const getCollectionSelectorConfigForSpaces = (): ICollectionConfig => ({
  recentlyUsedCollectionIds: null,
  suggestedCollectionIds: [],
});

export const getCollectionSelectorConfigForListing = (state: IStore): ICollectionConfig => ({
  recentlyUsedCollectionIds: getRecentlyUsedCollectionIdsForListing(state),
  suggestedCollectionIds: getSuggestedCollectionIdsForListing(state),
});
