import { parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DatetimePicker } from '../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getTodayReadableDate } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { DatetimePickerPlaceholder } from '../../../projects/constants/UIConstants.ts';
import {
  DateTimePickerDefaultTime,
  SupportedAuditLogHistoryInDays,
} from '../constants/auditLogProperties.ts';
import { ActionTypeFilterSelector } from '../containers/ActionTypeFilterSelector.tsx';
import { ObjectTypeFilterSelector } from '../containers/ObjectTypeFilterSelector.tsx';
import { IAuditLogFilter, isFilterSet as isFilterSetFunc } from '../models/AuditEventFilter.ts';
import { AuditLogClearFilterButton } from './AuditLogClearFilterButton.tsx';
import { AuditLogPhraseSearch } from './AuditLogPhraseSearch.tsx';

export interface IAuditLogFilterProps {
  readonly currentFilterState: IAuditLogFilter;
  readonly maxCalendarDate: Date;
  readonly minCalendarDate: Date;
  readonly onClearFilter: () => void;
  readonly onFromDateChange: (dateTime: DateTime, isFromDateBeforeToDate: boolean) => void;
  readonly onToDateChange: (dateTime: DateTime, isFromDateBeforeToDate: boolean) => void;
}

const isDatetimeStampInDateRange = (date: DateTimeStamp, from: Date, to: Date): boolean => {
  if (date) {
    const parsedDate = parseISO(date);
    return parsedDate.getTime() > from.getTime() && parsedDate.getTime() < to.getTime();
  }
  return false;
};

const isDatetimeBeforeAnother = (beforeDate: DateTimeStamp, afterDate: DateTimeStamp): boolean =>
  Date.parse(afterDate) >= Date.parse(beforeDate);

const isRangeValid = (from: DateTimeStamp, to: DateTimeStamp) => {
  return !from || !to || isDatetimeBeforeAnother(from, to);
};

export const AuditLogFilter: React.FC<IAuditLogFilterProps> = ({
  onClearFilter,
  onFromDateChange,
  onToDateChange,
  currentFilterState,
  maxCalendarDate,
  minCalendarDate,
}) => {
  const selectedFromDate = currentFilterState.selectedFromDate;
  const selectedToDate = currentFilterState.selectedToDate;

  const isFilterSet = isFilterSetFunc(currentFilterState);

  // for validation error messages
  const [isFromDateInRange, setIsFromDateInRange] = useState(true);
  const [isToDateInRange, setIsToDateInRange] = useState(true);
  const [isDateRangeValid, setIsDateRangeValid] = useState(true);

  useEffect(() => {
    setIsDateRangeValid(isRangeValid(selectedFromDate, selectedToDate));
  }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    const isFromDateValid =
      !selectedFromDate ||
      isDatetimeStampInDateRange(selectedFromDate, minCalendarDate, maxCalendarDate);
    setIsFromDateInRange(isFromDateValid);
  }, [selectedFromDate, maxCalendarDate, minCalendarDate]);

  useEffect(() => {
    const isFromDateValid =
      !selectedToDate || isDatetimeBeforeAnother(minCalendarDate.toDateString(), selectedToDate);
    setIsToDateInRange(isFromDateValid);
  }, [selectedToDate, minCalendarDate]);

  return (
    <div className="audit-log__filter" {...getDataUiElementAttribute(DataUiElement.AuditLogFilter)}>
      <div className="audit-log__filter__search-area">
        <AuditLogPhraseSearch autofocus />
        {isFilterSet && (
          <div className="audit-log__filter__clear-button">
            <AuditLogClearFilterButton onClick={onClearFilter} />
          </div>
        )}
      </div>
      <div className="audit-log__filter__search-area">
        <div className="audit-log__filter__selector">
          <LabelFor
            className="audit-log__filter__selector-title"
            target={() => (
              <DatetimePicker
                autoFocus={false}
                defaultDate={getTodayReadableDate()}
                defaultTime={DateTimePickerDefaultTime}
                hasError={!isFromDateInRange || !isDateRangeValid}
                errorMessage={getErrorMessage(isFromDateInRange, isDateRangeValid)}
                maxValue={maxCalendarDate}
                minValue={minCalendarDate}
                onChange={(dateTime: DateTime) => {
                  if (dateTime.isValid) {
                    const isFromDateBeforeToDate =
                      !selectedToDate ||
                      (isToDateInRange && isRangeValid(dateTime.value, selectedToDate));
                    onFromDateChange(dateTime, isFromDateBeforeToDate);
                  }
                }}
                placeholder={DatetimePickerPlaceholder}
                value={selectedFromDate}
              />
            )}
          >
            From
          </LabelFor>
        </div>
        <div className="audit-log__filter__selector">
          <LabelFor
            className="audit-log__filter__selector-title"
            target={() => (
              <DatetimePicker
                autoFocus={false}
                defaultDate={getTodayReadableDate()}
                defaultTime={DateTimePickerDefaultTime}
                hasError={!isToDateInRange || !isDateRangeValid}
                minValue={minCalendarDate}
                onChange={(dateTime: DateTime) => {
                  if (dateTime.isValid) {
                    const isValid =
                      !selectedFromDate ||
                      (isFromDateInRange && isRangeValid(selectedFromDate, dateTime.value));
                    onToDateChange(dateTime, isValid);
                  }
                }}
                placeholder={DatetimePickerPlaceholder}
                value={selectedToDate}
              />
            )}
          >
            To
          </LabelFor>
        </div>
        <ObjectTypeFilterSelector />
        <ActionTypeFilterSelector />
      </div>
    </div>
  );
};

AuditLogFilter.displayName = 'AuditLogFilter';

const getErrorMessage = (isDateInRange: boolean, isTimePeriodValid: boolean) => {
  if (!isTimePeriodValid) {
    return 'The selected From and To dates don’t form a valid date range. Check whether the dates follow after one another.';
  }
  if (!isDateInRange) {
    return `Specify a date range within the last ${SupportedAuditLogHistoryInDays} days.`;
  }
  return undefined;
};
