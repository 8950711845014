import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { expirationDateIsValidBuilder } from './expirationDateValidation.ts';

export type PersonalMapiKeyFormShape = {
  readonly expiresAt: DateTime;
};

const validExpirationDateRequired = expirationDateIsValidBuilder(ApiKeyType.MAPIPat);

export const personalMapiKeyValidationConfig: FormValidatorConfig<PersonalMapiKeyFormShape> = {
  expiresAt: [validExpirationDateRequired],
};
