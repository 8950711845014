import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  Data_LanguageUsages_Started,
  Data_LanguageUsages_Success,
} from '../../../constants/dataActionTypes.ts';

export function usagesLoadingStatus(
  state: LoadingStatus = LoadingStatus.InitialEmpty,
  action: Action,
): LoadingStatus {
  switch (action.type) {
    case Data_LanguageUsages_Started:
      return LoadingStatus.Loading;

    case Data_LanguageUsages_Success:
      return LoadingStatus.Loaded;

    case Shared_CurrentProjectId_Updated:
      return LoadingStatus.InitialEmpty;

    default:
      return state;
  }
}
