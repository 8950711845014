import React from 'react';
import { TimeInterval } from '../../../../unifiedMissionControl/constants/timeIntervals.ts';
import { MissionControlRepository } from '../../../../unifiedMissionControl/repositories/MissionControlRepository.type.ts';
import { ITasksDoneOnTime } from '../../../../unifiedMissionControl/repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { createDemoTasksDoneOnTimeCounts } from '../../shared/utils/createDemoTasksDoneOnTimeCounts.ts';
import { TasksDoneOnTimeWidget } from '../components/TasksDoneOnTimeWidget.tsx';

export const demoTasksDoneOnTimeData: Record<TimeInterval, ITasksDoneOnTime> = {
  [TimeInterval.CurrentMonth]: createDemoTasksDoneOnTimeCounts(3, 6),
  [TimeInterval.CurrentWeek]: createDemoTasksDoneOnTimeCounts(2, 2),
  [TimeInterval.PastThirtyDays]: createDemoTasksDoneOnTimeCounts(2, 10),
};

export const TasksDoneOnTimeWidgetDemoContainer: React.FC = () => {
  const tasksDoneOnTimeFetcher: MissionControlRepository['getTasksDoneOnTime'] = (
    selectedInterval,
  ) => Promise.resolve(demoTasksDoneOnTimeData[selectedInterval]);

  return <TasksDoneOnTimeWidget tasksDoneOnTimeFetcher={tasksDoneOnTimeFetcher} />;
};

TasksDoneOnTimeWidgetDemoContainer.displayName = 'TasksDoneOnTimeWidgetDemoContainer';
