import { ThunkFunction, ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../../../localStorages/projectSpecificStorage.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import { listingFilterChanged } from '../contentItemInventoryActions.ts';

interface IFilterContentItemActionDependencies {
  readonly ContentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadListingContentItemsForInventory: () => ThunkPromise;
  readonly trackIntercomFindRightContentEvent: (
    oldFilterState: IListingFilter,
    newFilterState: IListingFilter,
  ) => ThunkFunction;
}

export const createFilterContentItemsAction =
  ({
    ContentItemFilterStorage,
    loadListingContentItemsForInventory,
    trackIntercomFindRightContentEvent,
  }: IFilterContentItemActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
    } = getState();
    const storedFilter = ContentItemFilterStorage?.load(currentProjectId);

    dispatch(listingFilterChanged());

    if (!savedFiltersUi.lastFilteredUsingSavedFilter && storedFilter) {
      dispatch(trackIntercomFindRightContentEvent(storedFilter, filter));
    }
    ContentItemFilterStorage?.save(filter, currentProjectId);

    await dispatch(loadListingContentItemsForInventory());
  };
