import { Box } from '@kontent-ai/component-library/Box';
import { Column } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { BreadcrumbsContext } from '../BreadcrumbsContext.tsx';

const StyledBox = styled(Box)`
  &:empty {
    /* so  that there is no empty space when child component renders null (e.g. saving status) */
    display: none; 
  }
`;

export const BreadcrumbsCell = React.forwardRef<HTMLDivElement, React.PropsWithChildren>(
  ({ children, ...restProps }, forwardedRef) => {
    const context = React.useContext(BreadcrumbsContext);
    if (context === undefined) {
      throw new Error('Breadcrumbs.Cell must be used within a Breadcrumbs component');
    }
    const { allowEllipsis } = context;

    return (
      <Column width={allowEllipsis ? 'fit-content' : 'content'} ref={forwardedRef} {...restProps}>
        <StyledBox paddingX={Spacing.XS}>{children}</StyledBox>
      </Column>
    );
  },
);

BreadcrumbsCell.displayName = 'BreadcrumbsCell';
