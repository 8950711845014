import { Box } from '@kontent-ai/component-library/Box';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ListingMessage } from '../../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { pluralizeWithoutCount } from '../../../../../../_shared/utils/stringUtils.ts';
import { getStatusMessageStyle } from '../../../reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

const getItemsHiddenToUserCount = (state: IStore, contentTypeId: Uuid): number => {
  const {
    data: {
      contentTypeUsages,
      contentTypeComponentsUsage: { visibleItemsToUserCount },
    },
  } = state;

  const contentTypeUsage = contentTypeUsages.byId.get(contentTypeId);
  const totalItemsCount = contentTypeUsage?.contentComponentCount ?? 0;
  return totalItemsCount - visibleItemsToUserCount;
};

const getHiddenItemsMessage = (state: IStore, contentTypeId: Uuid): string => {
  const hiddenItemsCount = getItemsHiddenToUserCount(state, contentTypeId);
  const hiddenItems = `${hiddenItemsCount} ${pluralizeWithoutCount('item', hiddenItemsCount)}`;

  return `There are ${hiddenItems} more which you can’t see in the list due to your role permissions.`;
};

const getContentTypeUsageStatusText = (state: IStore): string => {
  const itemsCount = state.data.contentTypeComponentsUsage.items?.length ?? 0;
  const itemsCountMessage = `${itemsCount}${
    state.data.contentTypeComponentsUsage.continuationToken ? '+' : ''
  }`;

  return `${itemsCountMessage} ${pluralizeWithoutCount('content item', itemsCount)}`;
};

interface Props {
  readonly contentTypeId: Uuid;
}

export const ContentTypeUsageScrollTableTitle: React.FC<Props> = ({ contentTypeId }) => {
  const statusMessageText = useSelector(getContentTypeUsageStatusText);
  const statusMessageStyle = useSelector(getStatusMessageStyle);
  const hiddenItemsCount = useSelector((state) => getItemsHiddenToUserCount(state, contentTypeId));
  const hiddenItemsMessageText = useSelector((state) =>
    getHiddenItemsMessage(state, contentTypeId),
  );

  return (
    <ListingMessage
      statusInfoMessage={{
        text: statusMessageText,
        style: statusMessageStyle,
      }}
    >
      {hiddenItemsCount > 0 && (
        <Box paddingLeft={Spacing.XS}>
          <Hint tooltipText={hiddenItemsMessageText} tooltipPlacement="right" />
        </Box>
      )}
    </ListingMessage>
  );
};
