import Immutable from 'immutable';
import { IProjectListingUser } from '../../../applications/projects/models/ProjectListingUser.ts';
import { loadSubscriptionUsers } from '../../../applications/subscriptionManagement/shared/actions/thunkSubscriptionManagementActions.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { AvailableSubscriptionAdmins } from '../../components/infos/AvailableSubscriptionAdmins.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';

const MAX_ADMINS_IN_DIALOG = 10;

type Props = {
  readonly onClose: () => void;
  readonly subscriptionId: Uuid;
};

export const AvailableSubscriptionAdminsWithoutCurrentProject = ({
  onClose,
  subscriptionId,
}: Props) => {
  const projectDetail = useSelector(
    (state) =>
      state.data.projects.byId
        .groupBy((project: IProjectDetails) => project.subscriptionId)
        .get(subscriptionId)
        ?.first() ?? null,
  );

  const admins: Immutable.List<IProjectListingUser> | null = projectDetail
    ? projectDetail.projectManagers
        .filter((manager: IProjectListingUser) =>
          projectDetail.subscriptionAdmins.contains(manager.userId),
        )
        .slice(0, MAX_ADMINS_IN_DIALOG)
        .toList()
    : null;

  useThunkPromise(loadSubscriptionUsers, subscriptionId);

  return (
    <AvailableSubscriptionAdmins
      admins={admins}
      onClose={onClose}
      subscriptionName={projectDetail?.subscriptionName ?? ''}
    />
  );
};
