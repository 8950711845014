import { ContentState } from 'draft-js';
import { useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { EmptyContentComponents } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import { convertRichTextToClipboardHtml } from '../../../actions/thunkRichTextActions.ts';
import { useContentComponents } from '../../../hooks/useContentComponents.ts';
import { ObjectDataType } from '../../../utils/export/html/elements/objects.ts';
import { copyContentToClipboard } from '../helpers/clipboardUtils.ts';

type CopyToClipboard = (suggestedContent: ContentState, aiSessionId: Uuid) => void;

export const useCopyToClipboard = (): {
  readonly copyToClipboard: CopyToClipboard;
} => {
  const dispatch = useDispatch();

  const contentComponents = useContentComponents();

  const copyToClipboard: CopyToClipboard = useCallback(
    (suggestedContent, aiSessionId) => {
      if (!suggestedContent) {
        return;
      }

      const convertedContentState = dispatch(
        convertRichTextToClipboardHtml(
          suggestedContent,
          contentComponents ?? EmptyContentComponents,
          new Map([[ObjectDataType.AiSession, aiSessionId]]),
        ),
      );
      copyContentToClipboard(convertedContentState);
    },
    [contentComponents],
  );

  return {
    copyToClipboard,
  };
};
