import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  Spaces_Create_Succeeded,
  Spaces_Delete_Succeeded,
  Spaces_Restore_Succeeded,
  Spaces_Update_Started,
  Spaces_Update_Succeeded,
} from '../../../../applications/environmentSettings/spaces/constants/spacesActionTypes.ts';
import { Data_Spaces_Failed, Data_Spaces_Success } from '../../../constants/dataActionTypes.ts';
import {
  ISpace,
  SpacesMap,
  createSpaceFromServerModel,
  createSpacesFromServerModel,
} from '../../../models/space/space.ts';

const initialState: SpacesMap = new Map<Uuid, ISpace>();

export const byId = (state = initialState, action: Action): SpacesMap => {
  switch (action.type) {
    case Data_Spaces_Success:
      return createSpacesFromServerModel(action.payload.spaces);

    case Spaces_Update_Started: {
      const { space } = action.payload;

      return new Map(state).set(space.id, space);
    }

    case Spaces_Create_Succeeded:
    case Spaces_Update_Succeeded:
    case Spaces_Restore_Succeeded: {
      const space = createSpaceFromServerModel(action.payload.space);

      return new Map(state).set(space.id, space);
    }

    case Spaces_Delete_Succeeded: {
      const newState = new Map(state);

      newState.delete(action.payload.spaceId);

      return newState;
    }

    case Shared_CurrentProjectId_Updated:
    case Data_Spaces_Failed:
      return initialState;

    default:
      return state;
  }
};
