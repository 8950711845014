import { assert } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import { getUrlFactory } from '../../../../_shared/utils/urlFactory.ts';
import { PreviewConfiguration_Validated } from '../../constants/previewConfigurationActionTypes.ts';
import {
  ContentTypeId,
  IPreviewUrlPatternError,
  SpaceRowId,
} from '../../stores/IPreviewConfigurationAppStoreState.type.ts';
import { createPreviewConfigurationValidator } from '../../validation/previewConfigurationValidator.ts';

const validated = (
  errors: ReadonlyMap<ContentTypeId, ReadonlyMap<SpaceRowId, IPreviewUrlPatternError>>,
) =>
  ({
    type: PreviewConfiguration_Validated,
    payload: {
      errors,
    },
  }) as const;

export type ValidatePreviewConfigurationActionsType = ReturnType<typeof validated>;

export const createValidatePreviewConfigurationAction =
  () =>
  (): ThunkFunction =>
  (dispatch, getState): void => {
    const {
      data: { contentTypes },
      previewConfigurationApp: { editedPreviewConfiguration },
    } = getState();

    const { getDeployedSampleAppDomain } = getUrlFactory();
    const validator = createPreviewConfigurationValidator({
      contentTypes: contentTypes.byId,
      getDeployedSampleAppDomain,
    });

    assert(
      editedPreviewConfiguration,
      () => `${__filename}: Edited preview configuration was not found.`,
    );

    const errors = validator(editedPreviewConfiguration);

    dispatch(validated(errors));
  };
