import Immutable from 'immutable';

export const ResponsiveImageSupportedTypes = Immutable.List<string>([
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
]);

export const ResponsiveImageExtensionsMessage = 'jpeg, png, gif, or webp';
export const SupportedFileTypesMessage = `Only files with the following extensions are allowed: ${ResponsiveImageExtensionsMessage}.`;
