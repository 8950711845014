import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ICollectionGroupRoles } from '../../../../data/models/users/ProjectContributor.ts';
import { isCollectionGroupRolesValid } from '../utils/isCollectionGroupRolesValid.ts';

export const useProjectManagerSelectionValidation = (
  collectionGroups: readonly ICollectionGroupRoles[],
): boolean => {
  return useSelector((s) =>
    isCollectionGroupRolesValid(collectionGroups, [...s.data.roles.rolesById.values()]),
  );
};
