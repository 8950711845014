import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { ContentItemId, ContentItemIdString } from '../../../../../_shared/models/ContentItemId.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { shouldUseLastContinuationToken } from '../../../../../_shared/utils/scrollTableUtils.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  Data_ContentStatusContentItems_Started,
  Data_ContentStatusContentItems_Success,
} from '../../constants/contentStatusActionTypes.ts';

const initialState: Immutable.List<ContentItemIdString> | null = null;

export function allIds(
  state: Immutable.List<ContentItemIdString> | null = initialState,
  action: Action,
): Immutable.List<ContentItemIdString> | null {
  switch (action.type) {
    case Data_ContentStatusContentItems_Started: {
      return shouldUseLastContinuationToken(action.payload.requestTrigger) ? state : initialState;
    }

    case Data_ContentStatusContentItems_Success: {
      const newItemIds = action.payload.contentItems.map((item: IListingContentItem) => {
        const contentItemId: ContentItemId = item.variant?.id ?? {
          itemId: item.item.id,
          variantId: DefaultVariantId,
        };
        return stringifyContentItemId(contentItemId);
      });

      return state === null || !shouldUseLastContinuationToken(action.payload.requestTrigger)
        ? Immutable.List(newItemIds)
        : state.concat(newItemIds).toList();
    }

    default:
      return state;
  }
}
