import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getWorkflowsCurrentUserCanCreateVariantIn } from '../../../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';

export const getInitialSelectedWorkflowId = (
  state: IStore,
  languageId: Uuid,
  collectionId: Uuid | null,
  contentTypeId: Uuid | null,
): Uuid | null => {
  const filteredWorkflows = getWorkflowsCurrentUserCanCreateVariantIn(
    state,
    languageId,
    collectionId,
    contentTypeId,
  );

  const firstFilteredWorkflow = filteredWorkflows[0]?.id;
  if (filteredWorkflows.length === 1 && firstFilteredWorkflow) {
    return firstFilteredWorkflow;
  }

  return null;
};
