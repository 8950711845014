import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

export type ContentTypeOption = IContentType & ISelectItem<ContentTypeOption>;

export const mapContentTypeToOption = (contentType: IContentType): ContentTypeOption => {
  return {
    ...contentType,
    label: contentType.name,
  };
};
