import { Button } from '@kontent-ai/component-library/Button';
import React, { ComponentProps, PropsWithChildren } from 'react';

type ActivateFeatureButtonProps = {
  readonly activationUnavailableExplanation?: string;
  readonly canBeActivated: boolean;
  readonly children?: never;
  readonly featureName: string;
  readonly isActivating: boolean;
} & Omit<ComponentProps<typeof Button>, 'disabled' | 'size' | 'tooltipText' | 'buttonStyle'>;
export const ActivateFeatureButton: React.FC<PropsWithChildren<ActivateFeatureButtonProps>> = ({
  children,
  canBeActivated,
  activationUnavailableExplanation = 'This feature is currently unavailable.',
  featureName,
  isActivating,
  ...restProps
}) => {
  return (
    <Button
      buttonStyle="tertiary"
      disabled={!canBeActivated}
      size="small"
      tooltipText={canBeActivated ? null : activationUnavailableExplanation}
      {...restProps}
    >
      {isActivating && <Button.ProgressIcon />}
      <Button.Label>
        {isActivating ? 'Activating' : 'Activate'} {featureName}
      </Button.Label>
    </Button>
  );
};
