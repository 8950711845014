import { useEventListener } from '@kontent-ai/hooks';
import { useState } from 'react';
import { getNativeDomSelection } from '../utils/selectionUtils.ts';

export enum IsSelected {
  No = 'No',
  Fully = 'Fully',
  Partially = 'Partially',
}

const getIsSelected = (element: HTMLElement | null): IsSelected => {
  const domSelection = getNativeDomSelection();
  if (!element || !domSelection) {
    return IsSelected.No;
  }
  if (domSelection.containsNode(element, false)) {
    return IsSelected.Fully;
  }
  return domSelection.containsNode(element, true) ? IsSelected.Partially : IsSelected.No;
};

/**
 * Use this hook to track whether a particular element is contained within DOM selection.
 * E.g. to apply some highlight styles to it
 * Receives:
 * -- ref - Ref to the element to track
 * Returns:
 * - IsSelected - Enum that defined whether the element is selected fully, partially or not at all
 */
export const useIsSelected = (ref: React.RefObject<HTMLElement>): IsSelected => {
  const [isSelected, setIsSelected] = useState(() => getIsSelected(ref.current));

  useEventListener(
    'selectionchange',
    () => {
      const newIsSelected = getIsSelected(ref.current);
      if (newIsSelected !== isSelected) {
        setIsSelected(newIsSelected);
      }
    },
    document,
  );

  return isSelected;
};
