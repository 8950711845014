import { Box } from '@kontent-ai/component-library/Box';
import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useContext } from 'react';
import { SortBy, Status } from '../constants/filters.ts';
import { InnovationLabAppFilterContext } from './InnovationLabAppFilterContext.tsx';

const isSortByIdentifier = (identifier: string | null): identifier is SortBy =>
  identifier ? Object.values(SortBy).includes(identifier as SortBy) : false;

interface SortingItem extends ISelectItem<SortingItem> {
  readonly id: SortBy;
  readonly label: string;
}

const sortingOptions: ReadonlyArray<SortingItem> = [
  {
    id: SortBy.Newest,
    label: 'Newest first',
  },
  {
    id: SortBy.Oldest,
    label: 'Oldest first',
  },
  {
    id: SortBy.ByNameAsc,
    label: 'Name, A-Z',
  },
  {
    id: SortBy.ByNameDesc,
    label: 'Name, Z-A',
  },
] as const;

const isStatusIdentifier = (identifier: string | null): identifier is Status =>
  identifier ? Object.values(Status).includes(identifier as Status) : false;

interface StatusItem extends ISelectItem<StatusItem> {
  readonly id: Status;
  readonly label: string;
}

const statusOptions: ReadonlyArray<StatusItem> = [
  {
    id: Status.All,
    label: 'All',
  },
  {
    id: Status.Active,
    label: 'Active',
  },
  {
    id: Status.Inactive,
    label: 'Not active',
  },
] as const;

export const InnovationLabFilters = () => {
  const { sortBy, setSortBy, status, setStatus } = useContext(InnovationLabAppFilterContext);

  return (
    <Box gap={Spacing.XL} display="flex">
      <SingleSelect<StatusItem>
        items={statusOptions}
        label="Status"
        inputState={InputState.Default}
        onSelectionChange={(id) => isStatusIdentifier(id) && setStatus(id)}
        selectedItemId={status}
      />
      <SingleSelect<SortingItem>
        items={sortingOptions}
        label="Sort by"
        inputState={InputState.Default}
        onSelectionChange={(id) => isSortByIdentifier(id) && setSortBy(id)}
        selectedItemId={sortBy}
      />
    </Box>
  );
};
