import classNames from 'classnames';
import React from 'react';
import { TextLimitStatus } from '../../../../../../../richText/plugins/textApi/api/editorTextUtils.ts';
import { ItemLimitStatusMessage } from './ItemLimitStatusMessage.tsx';

interface ITextLimitStatusPane {
  readonly isHidden?: boolean;
  readonly isTooltipEnabled: boolean;
  readonly textLimitStatus: TextLimitStatus;
  readonly getTooltipText: () => string;
}

export const TextLimitStatusPane: React.FC<ITextLimitStatusPane> = ({
  getTooltipText,
  isHidden,
  isTooltipEnabled,
  textLimitStatus: { textSizeCurrentValue, limitValue, limitType, isLimitValueMet },
}): JSX.Element => (
  <div
    className={classNames(
      'content-item-element__status-pane',
      'content-item-element__status-pane--right',
      {
        'content-item-element__status-pane--is-hidden': isHidden,
      },
    )}
  >
    <ItemLimitStatusMessage
      currentValue={textSizeCurrentValue}
      max={limitValue}
      type={limitType}
      isLimitValueMet={isLimitValueMet}
      getTooltipText={getTooltipText}
      isTooltipEnabled={isTooltipEnabled}
    />
  </div>
);

TextLimitStatusPane.displayName = 'TextLimitStatusPane';
