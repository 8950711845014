import React from 'react';
import { UserListingState } from '../../utils/getUserListingState.ts';
import { UserListingDefaultState } from './UserListingDefaultState.tsx';
import { UserListingFilterEmptyState } from './UserListingFilterEmptyState.tsx';
import { UserListingUserIsNotInvitedYetEmptyState } from './UserListingUserIsNotInvitedYetEmptyState.tsx';

type Props = {
  readonly state: UserListingState;
};

export const UserListingInfoMessage: React.FC<Props> = ({ state }) => {
  switch (state) {
    case UserListingState.NotInvitedYet:
      return <UserListingUserIsNotInvitedYetEmptyState />;

    case UserListingState.NoMatch:
      return <UserListingFilterEmptyState />;

    case UserListingState.EmptyProject:
      return <UserListingDefaultState />;

    default:
      return null;
  }
};

UserListingInfoMessage.displayName = 'UserListingInfoMessage';
