import { IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { InvariantException } from '@kontent-ai/errors';
import { getSectionItemId } from '../../../../../_shared/utils/component-library/selectSections.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { CollectionSection } from '../constants/collectionSection.ts';
import { CollectionSelectorState } from './getCollectionSelectorState.ts';

const createCollectionOption =
  (section: CollectionSection) =>
  ({ id, name }: ICollection): IBaseSelectItem => ({
    //  Id must be unique amongst all options, even if the same option is in multiple sections
    id: getSectionItemId(id, section),
    label: name,
  });

export const createCollectionSelectorOptions = (
  suggestedCollections: ReadonlyArray<ICollection>,
  availableCollections: ReadonlyArray<ICollection>,
  state: CollectionSelectorState,
): ReadonlyArray<ISelectSection<IBaseSelectItem>> => {
  switch (state) {
    case CollectionSelectorState.ShowSuggested:
      return [
        {
          id: CollectionSection.SuggestedCollections,
          items: suggestedCollections.map(
            createCollectionOption(CollectionSection.SuggestedCollections),
          ),
          type: 'section',
          label: 'Suggested collections',
        },
        {
          id: CollectionSection.AllCollections,
          items: availableCollections.map(createCollectionOption(CollectionSection.AllCollections)),
          type: 'section',
          label: 'All collections',
        },
      ];

    case CollectionSelectorState.ShowAllOnly:
      return [
        {
          id: CollectionSection.AllCollections,
          items: availableCollections.map(createCollectionOption(CollectionSection.AllCollections)),
          type: 'section',
        },
      ];

    default:
      throw InvariantException(`${__filename}: Unknown collection selector state: ${state}.`);
  }
};
