import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  Role_Create_Finished,
  Role_Delete_Finished,
  Role_Update_Finished,
} from '../../../../applications/environmentSettings/roles/constants/rolesActionTypes.ts';
import { Data_Roles_Started, Data_Roles_Success } from '../../../constants/dataActionTypes.ts';
import {
  IRoleWithSettings,
  getRoleWithSettingsFromServerModel,
} from '../../../models/roles/IRoleWithSettings.ts';

const initialState: ReadonlyMap<Uuid, IRoleWithSettings> = new Map();

export function rolesById(
  state: ReadonlyMap<Uuid, IRoleWithSettings> = initialState,
  action: Action,
): ReadonlyMap<Uuid, IRoleWithSettings> {
  switch (action.type) {
    case Data_Roles_Started:
    case Shared_CurrentProjectId_Updated:
      return initialState;

    case Data_Roles_Success: {
      const { data } = action.payload;

      return data.reduce(
        (reduced, roleServerModel) =>
          reduced.set(roleServerModel.id, getRoleWithSettingsFromServerModel(roleServerModel)),
        new Map<Uuid, IRoleWithSettings>(),
      );
    }

    case Role_Create_Finished:
    case Role_Update_Finished: {
      const { role } = action.payload;
      const newState = new Map(state);

      return newState.set(role.id, getRoleWithSettingsFromServerModel(role));
    }

    case Role_Delete_Finished: {
      const { role } = action.payload;
      const newState = new Map(state);

      newState.delete(role.id);

      return newState;
    }

    default:
      return state;
  }
}
