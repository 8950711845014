import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, canvasInnerSectionRestrictedWidth } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ProjectSettingsAppNames } from '../../root/constants/ProjectSettingsAppNames.ts';
import { ProjectNameCard } from '../containers/ProjectNameCard.tsx';

export const GeneralSettings: React.FC = () => (
  <div {...getDataUiAppNameAttribute(DataUiAppName.General)}>
    <HtmlSettingsPageTitle settingsAppName={ProjectSettingsAppNames.General} />
    <Box maxWidth={canvasInnerSectionRestrictedWidth}>
      <Stack spacing={Spacing.XL}>
        <PageTitle>{ProjectSettingsAppNames.General}</PageTitle>
        <ProjectNameCard />
      </Stack>
    </Box>
  </div>
);

GeneralSettings.displayName = 'GeneralSettings';
