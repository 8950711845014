import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../_shared/constants/sharedActionTypes.ts';
import { Relations_InitFinished } from '../../../../applications/contentInventory/relations/constants/relationsActionTypes.ts';
import {
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../../applications/itemEditor/features/CascadePublish/constants/cascadeModalActionTypes.ts';
import { ContentItemEditing_Init_Finished } from '../../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { Data_ListingContentItemsWithAllVariants_Success } from '../../../constants/dataActionTypes.ts';
import { IListingContentItem } from '../../../models/listingContentItems/IListingContentItem.ts';

const initialState: ReadonlyMap<Uuid, ReadonlyArray<IListingContentItem>> = new Map();

export const byId = (
  state = initialState,
  action: Action,
): ReadonlyMap<Uuid, ReadonlyArray<IListingContentItem>> => {
  switch (action.type) {
    case Data_ListingContentItemsWithAllVariants_Success: {
      return Collection.addMany(
        state,
        Collection.getEntries(action.payload.itemsByIdsWithAllVariants),
      );
    }

    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished: {
      return Collection.removeMany(
        state,
        Collection.getValues(action.payload.modifiedItemIds).map((id) => id.itemId),
      );
    }

    case Shared_CurrentProjectId_Updated:
    case ContentItemEditing_Init_Finished:
    case Relations_InitFinished: {
      return initialState;
    }

    default:
      return state;
  }
};
