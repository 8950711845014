import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { useState } from 'react';
import { EnterHotkeyDisabler } from '../../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import {
  ContentChangesWarningModal,
  ContentChangesWarningSubtitle,
} from '../../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { validateLanguageDeletion } from '../../validation/languageDeletionValidation.ts';
import { LanguageDeactivationModal } from '../languageEditors/LanguageDeactivationModal.tsx';

interface ILanguageEditorDeactivateButton {
  readonly onCancel: () => void;
  readonly onDeactivate: (() => void) | null;
  readonly onDismissLongProcessingChangesWarning: () => void;
  readonly showLargeProjectSaveWarning: boolean;
  readonly isSetAsFallbackLanguage: boolean;
  readonly setWarningMessage: (message: string | undefined) => void;
  readonly isLanguageAlreadyUsed: boolean;
  readonly areLanguageRolesEnabled: boolean;
  readonly language: ILanguage;
}

export const LanguageEditorDeactivateButton: React.FC<ILanguageEditorDeactivateButton> = ({
  areLanguageRolesEnabled,
  onDeactivate,
  isSetAsFallbackLanguage,
  setWarningMessage,
  isLanguageAlreadyUsed,
  showLargeProjectSaveWarning,
  onDismissLongProcessingChangesWarning,
  onCancel,
  language,
}) => {
  const [showDeactivationModal, setShowDeactivationModal] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const tryDeactivateLanguage = (): void => {
    if (onDeactivate) {
      const error = validateLanguageDeletion(isSetAsFallbackLanguage);

      setWarningMessage(error);

      if (!error) {
        if (isLanguageAlreadyUsed) {
          setShowDeactivationModal(true);
        } else {
          onDeactivationConfirm();
        }
      }
    }
  };

  const onDeactivationConfirm = () => {
    setShowDeactivationModal(false);

    if (showLargeProjectSaveWarning) {
      setShowWarningModal(true);
    } else {
      onDeactivate?.();
    }
  };

  const deactivateLanguage = () => {
    setShowWarningModal(false);
    onDeactivate?.();
  };

  return (
    <>
      <EnterHotkeyDisabler>
        <Button
          buttonStyle="secondary"
          destructive
          destructiveIcon={Icons.Drawers}
          onClick={tryDeactivateLanguage}
          size="small"
          tooltipPlacement="bottom-start"
          {...getDataUiActionAttribute(DataUiAction.Deactivate)}
        >
          Deactivate
        </Button>
      </EnterHotkeyDisabler>
      <ContentChangesWarningModal
        onCancel={() => setShowWarningModal(false)}
        onConfirm={deactivateLanguage}
        onDiscardChanges={onCancel}
        onDismissWarning={onDismissLongProcessingChangesWarning}
        isOpen={showWarningModal}
        warningSubtitle={ContentChangesWarningSubtitle.LanguageSettingsEditing}
      />
      {showDeactivationModal && (
        <LanguageDeactivationModal
          areLanguageRolesEnabled={areLanguageRolesEnabled}
          language={language}
          onCancel={() => setShowDeactivationModal(false)}
          onConfirm={onDeactivationConfirm}
        />
      )}
    </>
  );
};

LanguageEditorDeactivateButton.displayName = 'LanguageEditorDeactivateButton';
