import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { IProjectContributorRole } from '../../../../data/models/users/ProjectContributor.ts';
import { getUsedRoleIds } from './getUsedRoleIds.ts';

export const getRemainingRoles = memoize.maxOne(
  (
    userRoles: readonly IProjectContributorRole[],
    roles: Immutable.List<IRole>,
  ): Immutable.List<IRole> => {
    const usedRolesIds = getUsedRoleIds(userRoles);

    return roles.filter((role: IRole) => !usedRolesIds.has(role.id)).toList();
  },
);
