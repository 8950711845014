import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';

const getTaxonomySavingStatusMessage = (state: IStore): StatusMessage => {
  const { editedTaxonomyTermId, isCreateNewFormDisplayed, status } = state.taxonomyApp;

  if (status === TaxonomyEditorStatus.IsBeingSaved) {
    return SavingStatusMessage;
  }

  const taxonomyGroupEditingIsOpen = editedTaxonomyTermId || isCreateNewFormDisplayed;
  const editedTaxonomyGroupIsModified = status === TaxonomyEditorStatus.Modified;

  if (editedTaxonomyGroupIsModified || taxonomyGroupEditingIsOpen) {
    return UnsavedStatusMessage;
  }

  return SavedStatusMessage;
};

export const TaxonomySavingStatus: React.ComponentType = getConnectedStatusMessage(
  getTaxonomySavingStatusMessage,
);
