import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentTypeSnippet_Editor_TypeElementDropped,
  ContentTypeSnippet_Editor_TypeElementPickedUp,
} from '../../constants/snippetActionTypes.ts';

const initialState = '';

export const NewElementId = '00000000-0000-0000-0000-000000000000';

export function draggedElementId(state = initialState, action: Action): Uuid {
  switch (action.type) {
    case ContentTypeSnippet_Editor_TypeElementPickedUp: {
      return action.payload.typeElementId;
    }

    case ContentTypeSnippet_Editor_TypeElementDropped: {
      return initialState;
    }

    default:
      return state;
  }
}
