import { createCompare, naturally } from '@kontent-ai/utils';
import { ISpace } from '../../../data/models/space/space.ts';

export const sortSpacesByName = (spaces: Iterable<ISpace>): ReadonlyArray<ISpace> =>
  Array.from(spaces).sort(
    createCompare({
      compare: naturally,
      select: (space) => space.name,
    }),
  );
