import { Card } from '@kontent-ai/component-library/Card';
import { BaseColor } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { InnovationLabFeatureStatus } from '../types/InnovationLabFeatureStatus.ts';
import { isInnovationLabFeatureOnlyForEmployees } from '../utils/innovationLabFeatureStatusUtils.ts';

export const InnovationLabFeatureCard = styled(Card)<{
  featureState: InnovationLabFeatureStatus;
}>`
  background-image: ${(props) =>
    isInnovationLabFeatureOnlyForEmployees(props.featureState) &&
    `repeating-linear-gradient(-45deg, ${BaseColor.Rose10}, ${BaseColor.White} 20px, ${BaseColor.White} 20px, ${BaseColor.Rose10} 40px);`};
`;
