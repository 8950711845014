import { YourContentTabName } from '../../../_shared/constants/yourContentTabName.ts';
import { OrderBy } from '../../../_shared/models/OrderBy.ts';
import {
  Content_AssignedContentItems_OrderByChanged,
  Content_YourContent_TabOpened,
} from '../../contentInventory/content/constants/contentActionTypes.ts';
import { AssignedContentItemsOrderBy } from '../../itemEditor/reducers/editorUi/reducers/assignedItemsOrdering.ts';
import { YourContent_PageLeft } from '../constants/yourContentActionTypes.ts';

export const changeOrderByColumn = (orderBy: OrderBy<AssignedContentItemsOrderBy>) =>
  ({
    type: Content_AssignedContentItems_OrderByChanged,
    payload: {
      orderBy,
    },
  }) as const;

export const openYourContentTab = (tabName: YourContentTabName) =>
  ({
    type: Content_YourContent_TabOpened,
    payload: {
      tabName,
    },
  }) as const;

export const yourContentPageLeft = () => ({ type: YourContent_PageLeft }) as const;
