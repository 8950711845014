import { ContentTypeConversionOptions } from '../../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import {
  LinkedItemsCompiledTypeElementServerModel,
  LinkedItemsRelationTypeServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { TypeElementConfigurability } from '../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import { getQuantityUnitOption } from '../../../../contentModels/shared/utils/typeElementDataConverters/utils/typeElementDataConvertersUtils.ts';
import { ElementType } from '../ContentItemElementType.ts';
import { QuantityOption } from '../quantityOptions.ts';
import {
  EmptyBaseTypeElement,
  IBaseTypeElement,
  ITypeElementWithAllowedContentTypesLimit,
  ITypeElementWithNumberOfItemsLimit,
  getBaseTypeElementFromServerModel,
} from './types/TypeElement.ts';

export enum LinkedItemsRelationType {
  LinkedItems = 'linked-items',
  Subpages = 'subpages',
}

type LinkedItemsElementType = ElementType.LinkedItems | ElementType.Subpages;

export interface ILinkedItemsTypeElement
  extends IBaseTypeElement,
    ITypeElementWithNumberOfItemsLimit,
    ITypeElementWithAllowedContentTypesLimit {
  readonly defaultValue: ReadonlyArray<Uuid>;
  readonly relationType: LinkedItemsRelationType;
  readonly type: LinkedItemsElementType;
}

export const EmptyLinkedItemsTypeElement: ILinkedItemsTypeElement = {
  ...EmptyBaseTypeElement,
  _configurability: TypeElementConfigurability.Recommended,
  _quantityUnitOption: QuantityOption.Exactly,
  allowedTypes: [],
  defaultValue: [],
  maxItems: null,
  minItems: null,
  relationType: LinkedItemsRelationType.LinkedItems,
  type: ElementType.LinkedItems,
};

function getElementType(
  serverModel: LinkedItemsCompiledTypeElementServerModel,
  conversionOptions: ContentTypeConversionOptions,
): LinkedItemsElementType {
  if (!conversionOptions.subpagesElementEnabled) {
    return ElementType.LinkedItems;
  }

  return serverModel.relationType === LinkedItemsRelationTypeServerModel.Subpages
    ? ElementType.Subpages
    : ElementType.LinkedItems;
}

export function getLinkedItemsTypeElementFromServerModel(
  serverModel: LinkedItemsCompiledTypeElementServerModel,
  conversionOptions: ContentTypeConversionOptions,
): ILinkedItemsTypeElement {
  const quantityUnitOption = getQuantityUnitOption(serverModel.minItems, serverModel.maxItems);

  return {
    ...getBaseTypeElementFromServerModel(serverModel),
    _configurability: EmptyLinkedItemsTypeElement._configurability,
    _quantityUnitOption: quantityUnitOption,
    allowedTypes: serverModel.allowedTypes,
    defaultValue: serverModel.default?.global.value.map(({ _id }) => _id) ?? [],
    maxItems: serverModel.maxItems ? serverModel.maxItems : EmptyLinkedItemsTypeElement.maxItems,
    minItems: serverModel.minItems ? serverModel.minItems : EmptyLinkedItemsTypeElement.minItems,
    relationType:
      serverModel.relationType === LinkedItemsRelationTypeServerModel.Subpages
        ? LinkedItemsRelationType.Subpages
        : LinkedItemsRelationType.LinkedItems,
    type: getElementType(serverModel, conversionOptions),
  };
}
