import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { getSelectedSubscriptionPlan } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ISubscriptionUsage } from '../../shared/models/SubscriptionUsage.ts';
import {
  PlanUsageComparison,
  comparePlanWithUsage,
  getPlanName,
} from '../../shared/utils/planUtils.ts';
import { ButtonAction } from '../containers/PlanCard.tsx';

type ChangePlanButtonProps = {
  readonly action: ButtonAction;
  readonly plan: IPlan;
  readonly subscriptionUsage: ISubscriptionUsage;
};

const formatMessage = (message: string): string => `\r\n\r\n${message}`;

const getMessage = (plan: IPlan, planComparison: PlanUsageComparison): string => {
  let message = `You are exceeding the limits of the ${plan.displayName} plan.`;

  if (planComparison.hasUsersWithNotAllowedLanguageRoles) {
    message += formatMessage(
      'Users: One or more of your users are assigned\r\nto multiple roles in different languages.',
    );
  }

  if (planComparison.hasTooManyUsers) {
    message += formatMessage(
      `Users: You have more than ${pluralizeWithCount(
        'active user',
        plan.features.maxActiveUsers,
      )} among\r\nall your projects (including admins).`,
    );
  }

  if (planComparison.hasTooManyRoles) {
    message += formatMessage(
      `Roles: One or more of your projects exceeds\r\nthe limit of ${pluralizeWithCount(
        'custom role',
        plan.features.maxCustomRoles,
      )}.`,
    );
  }

  if (planComparison.hasTooManyLanguages) {
    message += formatMessage(
      `Languages: One or more of your projects exceeds\r\nthe limit of ${pluralizeWithCount(
        'active language',
        plan.features.maxActiveLanguages,
      )}.`,
    );
  }

  if (planComparison.hasTooManyEnvironments) {
    message += formatMessage(
      `Environments: One or more of your projects exceeds\r\nthe limit of ${pluralizeWithCount(
        'environment',
        plan.features.maxProjectEnvironments,
      )}.`,
    );
  }

  if (planComparison.hasTooManyProjects) {
    message += formatMessage(
      `Projects: You have more than ${pluralizeWithCount(
        'project',
        plan.features.maxSubscriptionProjects,
      )} in your subscription.`,
    );
  }

  return message;
};

const getTooltipMessage = (
  planIsSelected: boolean,
  planIsExceeding: boolean,
  plan: IPlan,
  planComparison: PlanUsageComparison,
): string | undefined => {
  if (planIsSelected) {
    return `You are currently using the ${plan.displayName} plan`;
  }

  if (planIsExceeding) {
    return getMessage(plan, planComparison);
  }

  return undefined;
};

const getPlanIsSelected = (s: IStore, plan: IPlan): boolean => {
  const currentPlan = getSelectedSubscriptionPlan(s);
  if (!currentPlan) {
    return false;
  }

  return getPlanName(plan.name) === getPlanName(currentPlan.name);
};

export const ChangePlanButton: React.FC<ChangePlanButtonProps> = ({
  action,
  plan,
  subscriptionUsage,
}): JSX.Element => {
  const planIsSelected = useSelector((s) => getPlanIsSelected(s, plan));
  const planComparison = comparePlanWithUsage(plan, subscriptionUsage);
  const changeAllowed = !planIsSelected && !planComparison.isExceeding;

  return (
    <Button
      buttonStyle="primary"
      buttonDisplay="block"
      onClick={changeAllowed ? action.handler : undefined}
      disabled={!changeAllowed}
      tooltipText={getTooltipMessage(
        planIsSelected,
        planComparison.isExceeding,
        plan,
        planComparison,
      )}
      tooltipPlacement="top"
      {...getDataUiActionAttribute(DataUiAction.ChangePlan)}
    >
      {action.title}
    </Button>
  );
};

ChangePlanButton.displayName = 'ChangePlanButton';
