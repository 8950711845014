import { useCallback } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { CanCreateComment, CommentsPlugin } from '../../../plugins/comments/CommentsPlugin.tsx';
import { AutoGeneratedUrlSlugPlugin } from './AutoGeneratedUrlSlugPlugin.tsx';

export type DisableCommentsForAutoGeneratedUrlSlugPlugin = EditorPlugin<
  None,
  None,
  None,
  None,
  [CommentsPlugin, AutoGeneratedUrlSlugPlugin]
>;

export const DisableCommentsForAutoGeneratedUrlSlugPlugin: PluginComponent<
  DisableCommentsForAutoGeneratedUrlSlugPlugin
> = (props) => {
  const { customMode } = props;

  const canCreateComment: Decorator<CanCreateComment> = useCallback(
    (baseCanCreateComment) => () => customMode && baseCanCreateComment(),
    [customMode],
  );

  const apply: Apply<DisableCommentsForAutoGeneratedUrlSlugPlugin> = useCallback(
    (state) => {
      state.canCreateComment.decorate(canCreateComment);
      return {};
    },
    [canCreateComment],
  );

  return useEditorWithPlugin(props, { apply });
};
