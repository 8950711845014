import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ProjectOverviewEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';

interface IDeps {
  readonly projectOverviewFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadItemsMatchingFilter: () => ThunkPromise;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createFilterProjectOverviewAction =
  (deps: IDeps) => (): ThunkPromise => async (dispatch, getState) => {
    const state = getState();
    const { projectId } = getCurrentProject(state);
    const { filter } = state.contentApp.listingUi;
    deps.projectOverviewFilterStorage?.save(filter, projectId);
    await dispatch(deps.loadItemsMatchingFilter());
    dispatch(
      deps.trackUserEventWithData(TrackedEvent.ProjectOverview, {
        action: ProjectOverviewEventTypes.ItemsFiltered,
      }),
    );
  };
