import { StatusMessageAction } from '../stores/IAssetLibraryState.ts';

export type ArchiveAssetsAction = {
  readonly type: StatusMessageAction.ArchivedAssets;
  readonly successfulIds: ReadonlySet<Uuid>;
  readonly failedIds: ReadonlySet<Uuid>;
};

export type RestoreAssetsAction = {
  readonly type: StatusMessageAction.RestoredAssets;
  readonly successfulIds: ReadonlySet<Uuid>;
};

export type AssetFolderReference = {
  readonly id: Uuid;
};

export type MoveAssetsToFolderAction = {
  readonly type: StatusMessageAction.MovedAssetsToFolder;
  readonly successfulIds: ReadonlyMap<Uuid, AssetFolderReference>;
};

export type MoveAssetsBackToFolderAction = {
  readonly type: StatusMessageAction.MovedAssetsBackToFolder;
  readonly successfulIds: ReadonlyMap<Uuid, AssetFolderReference>;
};

export type CollectionReference = {
  readonly id: Uuid;
};

export type MoveAssetsToCollectionAction = {
  readonly type: StatusMessageAction.MovedAssetsToCollection;
  readonly successfulIds: ReadonlyMap<Uuid, CollectionReference | null>;
};

export type MoveAssetsBackToCollectionAction = {
  readonly type: StatusMessageAction.MovedAssetsBackToCollection;
  readonly successfulIds: ReadonlyMap<Uuid, CollectionReference | null>;
};

export type ArchiveFolderAction = {
  readonly type: StatusMessageAction.ArchivedFolder;
  readonly failedAssetsIds: ReadonlySet<Uuid>;
  readonly successfulAssetsIds: ReadonlySet<Uuid>;
  readonly successfulFoldersIds: ReadonlySet<Uuid>;
  readonly usedAssetsIds: ReadonlySet<Uuid>;
};

export type RestoreFolderAction = {
  readonly type: StatusMessageAction.RestoredFolder;
  readonly successfulAssetsIds: ReadonlySet<Uuid>;
  readonly successfulFoldersIds: ReadonlySet<Uuid>;
};

export type MoveFolderAction = {
  readonly type: StatusMessageAction.MovedFolder;
  readonly originalParentFolderId: Uuid;
};

export type AssignTaxonomyTermsToAssetsAction = {
  readonly type: StatusMessageAction.AssignedTerms;
  readonly successfulIds: ReadonlySet<Uuid>;
  readonly failedIds: ReadonlySet<Uuid>;
};

type AssetsActionWithPayload =
  | ArchiveAssetsAction
  | ArchiveFolderAction
  | AssignTaxonomyTermsToAssetsAction
  | MoveAssetsBackToCollectionAction
  | MoveAssetsBackToFolderAction
  | MoveAssetsToCollectionAction
  | MoveAssetsToFolderAction
  | MoveFolderAction
  | RestoreAssetsAction
  | RestoreFolderAction;

type AssetsActionWithoutPayload = {
  readonly type: Exclude<StatusMessageAction, AssetsActionWithPayload['type']>;
};

export type LastAssetsAction = AssetsActionWithPayload | AssetsActionWithoutPayload;

export const isArchiveAssetsAction = (action: LastAssetsAction): action is ArchiveAssetsAction =>
  action.type === StatusMessageAction.ArchivedAssets;

export const isRestoreAssetsAction = (action: LastAssetsAction): action is RestoreAssetsAction =>
  action.type === StatusMessageAction.RestoredAssets;

export const isMoveAssetsToFolderAction = (
  action: LastAssetsAction,
): action is MoveAssetsToFolderAction => action.type === StatusMessageAction.MovedAssetsToFolder;

export const isMoveAssetsBackToFolderAction = (
  action: LastAssetsAction,
): action is MoveAssetsBackToFolderAction =>
  action.type === StatusMessageAction.MovedAssetsBackToFolder;

export const isMoveAssetsToCollectionAction = (
  action: LastAssetsAction,
): action is MoveAssetsToCollectionAction =>
  action.type === StatusMessageAction.MovedAssetsToCollection;

export const isMoveAssetsBackToCollectionAction = (
  action: LastAssetsAction,
): action is MoveAssetsBackToCollectionAction =>
  action.type === StatusMessageAction.MovedAssetsBackToCollection;

export const isArchiveFolderAction = (action: LastAssetsAction): action is ArchiveFolderAction =>
  action.type === StatusMessageAction.ArchivedFolder;

export const isRestoreFolderAction = (action: LastAssetsAction): action is RestoreFolderAction =>
  action.type === StatusMessageAction.RestoredFolder;

export const isMoveFolderAction = (action: LastAssetsAction): action is MoveFolderAction =>
  action.type === StatusMessageAction.MovedFolder;

export const isAssignTaxonomyTermsToAssetsAction = (
  action: LastAssetsAction,
): action is AssignTaxonomyTermsToAssetsAction => action.type === StatusMessageAction.AssignedTerms;
