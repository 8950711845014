import { memoize } from '@kontent-ai/memoization';
import { getAllWorkflowSteps } from '../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { WorkflowStepColor } from '../../../../data/constants/WorkflowStepColor.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';

export interface IWorkflowStepOverview {
  readonly color: WorkflowStepColor;
  readonly count: number;
  readonly id: Uuid;
  readonly name: string;
}

export interface IWorkflowOverview {
  readonly id: Uuid;
  readonly name: string;
  readonly steps: ReadonlyArray<IWorkflowStepOverview>;
}

const createStepsOverview = (
  itemsByWorkflowByStep: ReadonlyMap<Uuid, ReadonlyMap<Uuid, ReadonlySet<Uuid>>>,
  selectedWorkflowNodes: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
  workflow: Workflow,
): ReadonlyArray<IWorkflowStepOverview> => {
  const workflowSteps = getAllWorkflowSteps(workflow);

  return workflowSteps
    .filter((step) => {
      return (
        selectedWorkflowNodes.size === 0 ||
        selectedWorkflowNodes.get(workflow.id)?.size === 0 ||
        selectedWorkflowNodes.get(workflow.id)?.has(step.id)
      );
    })
    .map(
      (step): IWorkflowStepOverview => ({
        color: step.color,
        count: itemsByWorkflowByStep.get(workflow.id)?.get(step.id)?.size ?? 0,
        id: step.id,
        name: step.name,
      }),
    );
};

export const createWorkflowOverview = memoize.weak(
  (
    itemsByWorkflowByStep: ReadonlyMap<Uuid, ReadonlyMap<Uuid, ReadonlySet<Uuid>>>,
    selectedWorkflowNodes: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    workflowsById: ReadonlyMap<Uuid, Workflow>,
  ): ReadonlyArray<IWorkflowOverview> =>
    getWorkflowsSortedByName(workflowsById)
      .filter(
        (workflow) => selectedWorkflowNodes.size === 0 || selectedWorkflowNodes.has(workflow.id),
      )
      .map(
        (workflow: Workflow): IWorkflowOverview => ({
          id: workflow.id,
          name: workflow.name,
          steps: createStepsOverview(itemsByWorkflowByStep, selectedWorkflowNodes, workflow),
        }),
      ),
);
