import { Action } from '../../../../../../@types/Action.type.ts';
import { ICollectionGroupRoles } from '../../../../../../data/models/users/ProjectContributor.ts';
import {
  UserDetail_Closed,
  UserDetail_CollectionGroupsChanged,
  UserDetail_Initialized,
  UserDetail_UpdateCollectionGroups_Finished,
  User_RevokeInvite_Finished,
} from '../../../constants/usersActionTypes.ts';
import { getInitialUserDetailCollectionGroups } from '../../../selectors/getInitialUserDetailCollectionGroups.ts';

const initialState: readonly ICollectionGroupRoles[] = [];

export const collectionGroups = (
  state: readonly ICollectionGroupRoles[] = initialState,
  action: Action,
): readonly ICollectionGroupRoles[] => {
  switch (action.type) {
    case UserDetail_CollectionGroupsChanged:
      return action.payload.newCollectionGroups;

    case UserDetail_Initialized:
      return getInitialUserDetailCollectionGroups(action.payload.collectionGroups);

    case UserDetail_Closed:
    case UserDetail_UpdateCollectionGroups_Finished:
    case User_RevokeInvite_Finished:
      return initialState;

    default:
      return state;
  }
};
