import { Action } from '../../../../@types/Action.type.ts';
import { AuditLog_Listing_Left } from '../../../../applications/environmentSettings/auditLog/constants/auditLogActionTypes.ts';
import {
  Data_AuditEvents_Failed,
  Data_AuditEvents_Success,
} from '../../../constants/dataActionTypes.ts';

const initialState: string | null = null;

export function continuationToken(
  state: string | null = initialState,
  action: Action,
): string | null {
  switch (action.type) {
    case Data_AuditEvents_Failed:
    case AuditLog_Listing_Left:
      return initialState;

    case Data_AuditEvents_Success: {
      return action.payload.continuationToken || null;
    }

    default:
      return state;
  }
}
