import React from 'react';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { getPreviewErrorMessage } from '../../../../../../utils/previewUtils.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { selectFirstErrorInPreviewLinks } from '../../../selectors/selectPreviewLinks.ts';

const noAction = () => undefined;

export const DemonstrateDisabledPreviewAction: React.FC<
  EditingActionDynamicDetailProviderOwnProps
> = ({ children, ...props }) => {
  const error = useSelector((s) => selectFirstErrorInPreviewLinks(s));
  return (
    <EditingActionDynamicDetailProvider
      {...props}
      tooltip={getPreviewErrorMessage(error)}
      performAction={noAction}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
