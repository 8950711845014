import { useCallback, useEffect, useState } from 'react';
import { AiOperationState } from '../types/AiOperationState.ts';
import { usePendingAiAction } from './usePendingAiAction.ts';

// Use together with useAiActionTrackingWithSession for AI actions that can only run one operation at time, using useAiAction
// Use more general usePendingAiAction or usePendingAiActionWithCallback for multiple AI simultaneous operations triggered from a single component
export const usePendingAiActionWithSession = (
  aiSessionId?: Uuid,
): {
  readonly aiOperationState: AiOperationState;
  readonly finishPendingAiAction: () => void;
  readonly startPendingAiAction: () => void;
} => {
  const { startPendingAiAction: start, finishPendingAiAction: finish } = usePendingAiAction();

  const [aiOperationState, setAiOperationState] = useState<AiOperationState>(
    AiOperationState.NotExecuted,
  );

  const startPendingAiAction = useCallback(() => {
    if (aiSessionId) {
      setAiOperationState(AiOperationState.Pending);
      start(aiSessionId);
    }
  }, [aiSessionId, start]);

  const finishPendingAiAction = useCallback(() => {
    if (aiSessionId) {
      setAiOperationState((state) =>
        state === AiOperationState.Pending ? AiOperationState.Finished : state,
      );
      finish(aiSessionId);
    }
  }, [aiSessionId, finish]);

  useEffect(() => finishPendingAiAction, [finishPendingAiAction]);

  return {
    aiOperationState,
    finishPendingAiAction,
    startPendingAiAction,
  };
};
