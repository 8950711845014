import { Action } from '../../../../../@types/Action.type.ts';
import { getDefinedValueOrDefault } from '../../../../../_shared/utils/func/functionalTools.ts';
import { IVariantActiveCapabilities } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_CurrentItemOverwritten,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_Init_Started,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentEditing_MoveItemToCollection_Finished } from '../../../features/MoveToCollectionDialog/constants/moveToCollectionDialogActionTypes.ts';

const initialState: IVariantActiveCapabilities = {
  variantCapabilities: [],
  contentGroupsForUpdateCapability: [],
  contentGroupsForViewCapability: [],
};

export function activeCapabilities(
  state = initialState,
  action: Action,
): IVariantActiveCapabilities {
  switch (action.type) {
    case ContentItemEditing_Init_Started: {
      return initialState;
    }

    case ContentItemEditing_Init_Ready: {
      return action.payload.activeCapabilities;
    }

    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentEditing_MoveItemToCollection_Finished:
    case ContentItemEditing_CreateNewVersion_Finished: {
      const { itemWithVariant } = action.payload;
      return itemWithVariant.activeCapabilities;
    }

    case Content_Editing_CurrentItemOverwritten: {
      const variantCapabilities =
        action.payload.params.difference.workflowStep?.variantCapabilities;
      return {
        ...state,
        variantCapabilities: getDefinedValueOrDefault(
          variantCapabilities,
          state.variantCapabilities,
        ),
      };
    }

    default:
      return state;
  }
}
