import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { UnpublishErrorMessage } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { TrackUserEventWithDataAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_UnpublishItems_Failed,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UnpublishItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IUnpublishSelectedContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly trackUserEvent: TrackUserEventWithDataAction;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_UnpublishItems_Started>(
  ContentListing_UnpublishItems_Started,
);
const actionFinished = createBulkActionFinished<typeof ContentListing_UnpublishItems_Finished>(
  ContentListing_UnpublishItems_Finished,
);
const actionFailed = createBulkActionFailed<typeof ContentListing_UnpublishItems_Failed>(
  ContentListing_UnpublishItems_Failed,
);

export type UnpublishSelectedContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished | typeof actionFailed
>;

export const unpublishSelectedContentItemsActionCreator =
  (deps: IUnpublishSelectedContentItemsActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<any> => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());

    const query = getItemListingOperationQueryFromFilter(getState());

    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.unpublishVariants(
        selectedLanguageId,
        query,
      );
      dispatch(actionFinished(createBulkActionResult(operationResult, selectedLanguageId)));

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentEntryBulkUnpublished, {
          count: operationResult.modifiedItemIds.length,
        }),
      );
    } catch (error) {
      logError('Error while bulk unpublishing', error);
      dispatch(actionFailed(UnpublishErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
