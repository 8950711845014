import React from 'react';
import { TypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isGuidelinesTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ContentComponentGuidelinesElement } from '../../../../../../itemEditor/features/ContentComponent/components/ContentComponentGuidelinesElement.tsx';
import { EditableContentComponentItemElement } from './EditableContentComponentItemElement.tsx';

interface IContentComponentItemElementProps {
  readonly autoFocus: boolean;
  readonly contentComponentId: Uuid;
  readonly disabled: boolean;
  readonly typeElement: TypeElement;
}

export const ContentComponentItemElement: React.FC<IContentComponentItemElementProps> = React.memo(
  ({ autoFocus, contentComponentId, disabled, typeElement }) => {
    if (isGuidelinesTypeElement(typeElement)) {
      return <ContentComponentGuidelinesElement disabled={disabled} typeElement={typeElement} />;
    }

    return (
      <EditableContentComponentItemElement
        autoFocus={autoFocus}
        contentComponentId={contentComponentId}
        disabled={disabled}
        typeElement={typeElement}
      />
    );
  },
);

ContentComponentItemElement.displayName = 'ContentComponentItemElement';
