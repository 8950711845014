import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_FirstInvalidElement_Focused,
  ContentModels_TypeEditor_FirstInvalidElement_Set,
} from '../../constants/sharedContentModelsActionTypes.ts';

export function firstInvalidElementId(
  state: Uuid | undefined | null = null,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case ContentModels_TypeEditor_FirstInvalidElement_Set: {
      return action.payload.element.elementId;
    }

    case ContentModels_TypeEditor_FirstInvalidElement_Focused: {
      return null;
    }

    default:
      return state;
  }
}
