import { ContentState } from 'draft-js';
import { convertContentToServerModel } from '../../../../applications/richText/utils/serverModel/editorServerModelUtils.ts';
import { DraftJsOperationInput } from '../AiServerModels.input.type.ts';
import {
  ElementOperationTrackingData,
  MultipleInputsOperationParamsModelBase,
} from '../AiServerModels.params.ts';
import {
  CumulatedOperationResponseMessageTemplate,
  ErrorOperationResponseMessage,
  OperationResponseMessage,
  isErrorOperationMessage,
} from '../AiServerModels.response.ts';
import {
  CumulatedDraftJsResult,
  isCumulatedDraftJsOperationResponseMessage,
} from '../AiServerModels.result.ts';

export type MakeShorterParams =
  MultipleInputsOperationParamsModelBase<ElementOperationTrackingData> & {
    readonly actionName: 'MakeShorter';
    readonly inputs: {
      readonly content: DraftJsOperationInput;
    };
  };

export const createMakeShorterParams = (
  contentState: ContentState,
  trackingData: ElementOperationTrackingData,
): MakeShorterParams => ({
  actionName: 'MakeShorter',
  type: 'multiple-inputs-request-v1',
  inputs: {
    content: {
      type: 'draft-js',
      value: convertContentToServerModel(contentState),
    },
  },
  trackingData,
});

export type MakeShorterResponseMessage =
  CumulatedOperationResponseMessageTemplate<CumulatedDraftJsResult>;

export const isMakeShorterMessage = (
  message: OperationResponseMessage,
): message is MakeShorterResponseMessage | ErrorOperationResponseMessage =>
  isCumulatedDraftJsOperationResponseMessage(message) || isErrorOperationMessage(message);
