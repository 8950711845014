import {
  IAssetRenditionRequestServerModel,
  IAssetRenditionResponseServerModel,
} from '../../../repositories/serverModels/AssetRenditionServerModel.type.ts';

export interface IAssetRenditionRequestModel {
  readonly transformation: IImageTransformation;
}

export interface IAssetRendition {
  readonly renditionId: Uuid;
  readonly assetId: Uuid;
  readonly presetId: Uuid;
  readonly transformation: IImageTransformation;
  readonly imageWidth: number;
  readonly imageHeight: number;
  readonly size: number;
  readonly createdAt: Date;
  readonly createdBy: UserId;
  readonly lastModifiedAt: Date;
  readonly lastModifiedBy: UserId;
}

export interface ICropRectangle {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
}

export interface IImageTransformation {
  readonly width: number;
  readonly height: number;
  readonly format?: 'gif' | 'png' | 'png8' | 'jpg' | 'pjpg' | 'webp';
  readonly rect: ICropRectangle;
}

const EmptyCropRectangle: ICropRectangle = {
  height: 0,
  width: 0,
  x: 0,
  y: 0,
};

const EmptyImageTransformation: IImageTransformation = {
  height: 0,
  rect: EmptyCropRectangle,
  width: 0,
};

export const EmptyAssetRendition: IAssetRendition = {
  assetId: '',
  createdAt: new Date(),
  createdBy: '',
  imageHeight: 0,
  imageWidth: 0,
  lastModifiedAt: new Date(),
  lastModifiedBy: '',
  presetId: '',
  renditionId: '',
  size: 0,
  transformation: EmptyImageTransformation,
};

export const mapAssetRenditionFromServerModel = (
  serverModel: IAssetRenditionResponseServerModel,
): IAssetRendition => ({
  ...serverModel,
  transformation: {
    width: serverModel.transformation.width,
    height: serverModel.transformation.height,
    rect: { ...serverModel.transformation.rect },
  },
});

export const mapImageTransformationToRequestServerModel = (
  transformation: IImageTransformation,
): IAssetRenditionRequestServerModel => ({
  transformation: {
    ...transformation,
    fit: 'clip',
    mode: 'rect',
  },
});
