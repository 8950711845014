import { isLanguageActive } from '../../../../../../_shared/models/utils/isLanguageActive.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { Languages } from '../../../../../../data/models/languages/Language.ts';
import { IAssetHasRequiredDescription } from './assetHasRequiredDescription.ts';

export type IAssetsHaveRequiredDescriptions = (
  assets: ReadonlyArray<IAsset>,
  currentLanguageGuid: Uuid,
  languages: Languages,
) => boolean;

const assetsHaveRequiredDescriptions = (
  assets: ReadonlyArray<IAsset>,
  currentLanguageGuid: Uuid,
  languages: Languages,
  assetHasRequiredDescription: IAssetHasRequiredDescription,
): boolean => {
  if (assets.length === 0) {
    return true;
  }

  const inactiveLanguagesId = languages
    .filter((language) => !isLanguageActive(language))
    .keySeq()
    .toList();

  return assets.every((asset: IAsset) => {
    return assetHasRequiredDescription(asset, currentLanguageGuid, inactiveLanguagesId);
  });
};

export function assetsHaveRequiredDescriptionsCreator(
  assetHasRequiredDescription: IAssetHasRequiredDescription,
): IAssetsHaveRequiredDescriptions {
  return (
    assets: ReadonlyArray<IAsset>,
    currentLanguageGuid: Uuid,
    languages: Languages,
  ): boolean =>
    assetsHaveRequiredDescriptions(
      assets,
      currentLanguageGuid,
      languages,
      assetHasRequiredDescription,
    );
}
