import { FormattedMessage } from '../../../../../../../_shared/components/FormattedMessage.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly messageBarClassName: string;
  readonly messageClassName: string;
  readonly errors?: ReadonlyArray<string>;
  readonly hideErrors?: boolean;
};

export const ErrorMessage = (props: Props) => {
  if (props.hideErrors || !props.errors?.length) {
    return null;
  }

  return (
    <div
      className={props.messageBarClassName}
      {...getDataUiElementAttribute(DataUiElement.NotificationMessageError)}
    >
      {props.errors.map((error, index) => (
        <FormattedMessage
          key={`errorMessage${index}`}
          className={props.messageClassName}
          messageWithFormattingTags={error}
          {...getDataUiElementAttribute(DataUiElement.ElementErrorText)}
        />
      ))}
    </div>
  );
};
