import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_TaskArchive_Failed,
  ContentItemEditing_TaskArchive_Finished,
  ContentItemEditing_TaskArchive_Started,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlySet<Uuid> = new Set();

export function archivingTasks(state = initialState, action: Action): ReadonlySet<Uuid> {
  switch (action.type) {
    case ContentItemEditing_TaskArchive_Started:
      return Collection.add(state, action.payload.taskId);

    case ContentItemEditing_TaskArchive_Failed:
    case ContentItemEditing_TaskArchive_Finished:
      return Collection.remove(state, action.payload.taskId);

    default:
      return state;
  }
}
