import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { CloneProjectErrors } from '../../../components/projects/copyProjectErrors/CloneProjectErrors.tsx';
import { getCopyProjectValidationData } from '../../../utils/copyProjectUtils.ts';

type ContainerProps = {
  readonly selectedSubscriptionId: Uuid;
  readonly sourceProjectId: Uuid;
};

const TemplateErrorsContainer: React.FC<ContainerProps> = ({
  selectedSubscriptionId,
  sourceProjectId,
}) => {
  const subscriptionName = useSelector((s) => {
    const selectedSubscription = getSubscription(s, selectedSubscriptionId);
    return selectedSubscription.subscriptionName;
  });

  const validationErrors = useSelector((s) => {
    const validationData = getCopyProjectValidationData(s, sourceProjectId, selectedSubscriptionId);
    return validationData.validationErrors;
  });

  return (
    <CloneProjectErrors
      errorsConfig={validationErrors}
      isCreatingEmptyProject={!sourceProjectId}
      subscriptionId={selectedSubscriptionId}
      subscriptionName={subscriptionName}
    />
  );
};

TemplateErrorsContainer.displayName = 'TemplateErrorsContainer';
export { TemplateErrorsContainer as TemplateErrors };
