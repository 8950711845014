import { isElementFullyVisible } from '@kontent-ai/DOM';
import { memoize } from '@kontent-ai/memoization';
import { CancellableExecutor, Executor, createDeferredFunctionWithCheck } from '@kontent-ai/utils';
import React from 'react';

const ScrollDeferredCheckInterval = 10; // ms

export const DeferAutoScrollCssClass = 'content--deferring-auto-scroll';

const isScrollAndFocusDeferred = memoize.maxAge((excludedClassnames?: ReadonlyArray<string>) => {
  const excluded =
    excludedClassnames && excludedClassnames.length > 0
      ? excludedClassnames.map((className) => `:not(.${className})`).join('')
      : '';
  const selectors = `.${DeferAutoScrollCssClass}${excluded}`;
  return !!document.querySelector(selectors);
}, ScrollDeferredCheckInterval);

export const waitUntilFocusAndScrollAreNotDeferred = <T extends any[]>(
  executor: Executor<T>,
  excludedClassnames?: ReadonlyArray<string>,
): CancellableExecutor<T> => {
  return createDeferredFunctionWithCheck(
    () => isScrollAndFocusDeferred(excludedClassnames),
    ScrollDeferredCheckInterval,
    executor,
  );
};

const InViewDeferredCheckInterval = 10; // ms

export const waitUntilFullyVisible = <T extends any[], E extends HTMLElement>(
  ref: React.RefObject<E>,
  executor: Executor<T>,
): CancellableExecutor<T> => {
  return createDeferredFunctionWithCheck(
    () => !!ref.current && !isElementFullyVisible(ref.current),
    InViewDeferredCheckInterval,
    executor,
  );
};
