import { px } from '@kontent-ai/component-library/tokens';
import { forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { ModalDialog } from '../../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { modalDismissed } from '../../../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ShortcutSymbols } from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { MinDialogWidth } from '../../../../../../projectSettings/environments/constants/uiConstants.ts';
import { getModalDialogActionOrigin } from '../../../../../selectors/getModalDialogActionOrigin.ts';
import { abandonAssignmentSectionChange } from '../../../actions/contentItemEditingActions.ts';
import { updateNote } from '../../../actions/thunkContentItemEditingActions.ts';
import { NoteFormBase } from '../../../components/details/Note/NoteFormBase.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';
import { INoteFormShape } from '../../../models/INoteFormShape.type.ts';
import {
  isOtherAssignmentSectionSubmitting as isOtherAssignmentSectionSubmittingSelector,
  isSectionSubmitting as isSectionSubmittingSelector,
} from '../../../selectors/isSectionSubmitting.ts';

const getSubmitButtonText = (
  initialNote: string | null | undefined,
  withIngForm: boolean,
): string => {
  if (!initialNote) {
    return withIngForm ? 'Adding...' : 'Add';
  }

  return withIngForm ? 'Changing...' : 'Change';
};

export const NoteFormDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const actionOrigin = useSelector(getModalDialogActionOrigin);

    const noteText = useSelector(
      (s) => s.contentApp.editedContentItemVariant?.assignment.note?.noteText ?? '',
    );
    const defaultValues: INoteFormShape = {
      note: noteText,
    };

    const isOtherAssignmentSectionSubmitting = useSelector((s) =>
      isOtherAssignmentSectionSubmittingSelector(s, AssignmentSections.Note),
    );
    const isSectionSubmitting = useSelector((s) =>
      isSectionSubmittingSelector(s, AssignmentSections.Note),
    );

    const formRef = useRef<HTMLFormElement>(null);
    const formProps = useForm<INoteFormShape>({
      defaultValues,
    });

    const { handleSubmit } = formProps;

    const submitForm = handleSubmit((values) =>
      dispatch(updateNote(history, values.note, actionOrigin)),
    );

    const resetForm = (): void => {
      dispatch(abandonAssignmentSectionChange(AssignmentSections.Note));
      dispatch(modalDismissed());
    };

    return (
      <ModalDialog
        autoFocusRef={formRef}
        headline={noteText ? 'Edit note' : 'Add note'}
        isDismissable
        isOpen={isOpen}
        minWidth={px(MinDialogWidth)}
        onClose={resetForm}
        ref={ref}
        shouldCloseOnInteractOutside={() => false}
        primaryAction={{
          disabled: isSectionSubmitting || isOtherAssignmentSectionSubmitting,
          onClick: submitForm,
          text: getSubmitButtonText(noteText, isSectionSubmitting),
          tooltipText: getSubmitButtonText(noteText, false),
          type: 'button',
          tooltipShortcuts: ShortcutSymbols.Enter,
        }}
        cancelAction={{
          disabled: isSectionSubmitting,
          tooltipShortcuts: ShortcutSymbols.Escape,
        }}
        {...getDataUiElementAttribute(DataUiElement.ContentItemNoteDialog)}
      >
        <NoteFormBase
          formProps={formProps}
          ref={formRef}
          isSectionSubmitting={isSectionSubmitting}
          onReset={resetForm}
          onSubmit={submitForm}
        />
      </ModalDialog>
    );
  },
);

NoteFormDialog.displayName = 'NoteFormDialog';
