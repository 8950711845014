import { RouterNavLink } from '@kontent-ai/component-library/Anchor';
import { Label } from '@kontent-ai/component-library/Label';
import {
  BorderRadius,
  Spacing,
  colorAccent,
  colorBackgroundHoverInverse,
  colorTextDefaultInverse,
  gridUnit,
  px,
  shadowFocusStyles,
  sizeHorizontalTabHeight,
  transition250,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

interface IStyledCell {
  readonly $isFocusVisible?: boolean;
  readonly $skipAnimation?: boolean;
}

const cellHeight = px(sizeHorizontalTabHeight);

export const StyledLabel = styled(Label)`
  position: relative;
  line-height: ${cellHeight};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: ${px(0.25 * gridUnit)};
    background-color: ${colorAccent};
    opacity: 0;
    transition: opacity ${transition250};
  }
`;

export const StyledCell = styled(RouterNavLink)<IStyledCell>`
  padding: 0 ${px(Spacing.S)};
  height: ${cellHeight};
  flex-shrink: 0;
  border-radius: ${px(BorderRadius.M)};

  /* Text styles */
  text-align: center;
  white-space: nowrap;

  /* Others */
  overflow: hidden;
  ${({ $skipAnimation }) =>
    !$skipAnimation &&
    css`
    transition: background-color ${transition250};
  `};

  &:hover {
    background-color: ${colorBackgroundHoverInverse};
  }

  &&,
  &&:hover,
  &&:focus {
    color: ${colorTextDefaultInverse};
  }

  ${({ $isFocusVisible }) =>
    $isFocusVisible &&
    css`
    ${shadowFocusStyles};
    z-index: 1;
  `};

  &.is-active ${StyledLabel}:after {
    opacity: 1;
  }
`;
