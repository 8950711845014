import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { createSelection } from '../../../utils/editorSelectionUtils.ts';
import { ImagesPlugin } from '../ImagesPlugin.tsx';
import { insertImagesToPlaceholder } from './editorImageUtils.ts';

export const editorImageApi: EditorApiImplementation<ImagesPlugin> = {
  insertImages(api, editorState, placeholderBlockKey, assetIds, allowUndo) {
    const selection = allowUndo
      ? createSelection(placeholderBlockKey)
      : editorState.getCurrentContent().getSelectionBefore();

    return api.executeContentChange(
      editorState,
      selection,
      (input) => insertImagesToPlaceholder(input, placeholderBlockKey, assetIds),
      'insert-fragment',
      allowUndo,
    );
  },
};
