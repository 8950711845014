import React from 'react';
import { useSelector } from '../hooks/useSelector.ts';
import { getSelectedLanguageId } from '../selectors/getSelectedLanguageId.ts';

export const WaitForSelectedVariantId: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  if (!selectedLanguageId) {
    return null;
  }
  return <>{children}</>;
};
WaitForSelectedVariantId.displayName = 'WaitForSelectedVariantId';
