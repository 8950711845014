import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetFolderRestoreResultServerModel } from '../../../../../repositories/serverModels/AssetFolderServerModel.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  IAssetService,
  processRestoredAssets,
} from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_Folders_RestoreCompleted,
  AssetLibrary_Folders_RestoreFailed,
  AssetLibrary_Folders_RestoreStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { isArchiveFolderAction } from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly restore: (
      folderIds: ReadonlyArray<Uuid>,
      assetsIds: ReadonlyArray<Uuid>,
    ) => Promise<IAssetFolderRestoreResultServerModel>;
  };
  readonly assetService: IAssetService;
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventWithDataAction;
}

interface IRestoreAssetFolderPayload {
  readonly assets: Immutable.Map<Uuid, IAsset>;
  readonly openedFolderId: Uuid;
  readonly successfulFoldersIds: ReadonlySet<Uuid>;
  readonly taxonomyGroup: ITaxonomyGroupServerModel | null;
}

const started = () =>
  ({
    type: AssetLibrary_Folders_RestoreStarted,
  }) as const;

const completed = (payload: IRestoreAssetFolderPayload) =>
  ({
    type: AssetLibrary_Folders_RestoreCompleted,
    payload,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Folders_RestoreFailed,
    payload: { errorMessage },
  }) as const;

export type RestoreAssetFolderActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Args = {
  readonly orderBy: AssetsOrderBy;
};

export const createRestoreAssetFolderAction =
  (deps: IDeps) =>
  ({ orderBy }: Args): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      assetLibraryApp: { lastAction, openedFolderId },
    } = getState();

    if (!isArchiveFolderAction(lastAction)) {
      throw new Error(
        `${__filename}: Expected last action to be ${
          StatusMessageAction.ArchivedFolder
        }, but was ${JSON.stringify(lastAction)}.`,
      );
    }

    dispatch(started());

    let restoredAssets: Immutable.Map<Uuid, IAsset> = Immutable.Map();
    let folderIds: ReadonlySet<Uuid> = new Set();
    let taxonomyGroup: ITaxonomyGroupServerModel | null = null;

    const assetIdsToRestore = Collection.getValues(lastAction.successfulAssetsIds);
    const folderIdsToRestore = Collection.getValues(lastAction.successfulFoldersIds);

    try {
      if (folderIdsToRestore.length) {
        const { assets, assetsLinks, folders, restoredFolders } =
          await deps.assetFolderRepository.restore(folderIdsToRestore, assetIdsToRestore);

        restoredAssets = processRestoredAssets(assets, assetsLinks);
        folderIds = new Set(restoredFolders);
        taxonomyGroup = folders;
      } else {
        restoredAssets = await deps.assetService.restoreAssets(assetIdsToRestore);
      }

      dispatch(
        completed({
          assets: restoredAssets,
          openedFolderId,
          successfulFoldersIds: folderIds,
          taxonomyGroup,
        }),
      );

      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.RestoredFolder,
          count: folderIds.size,
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          orderBy,
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
        }),
      );
    } catch (error) {
      dispatch(failed('Asset folder could not be restored.'));
      throw InvariantException(`${__filename}: ${error}`);
    }
  };
