import {
  AssetFileTypeOption,
  defaultAssetFileTypeOption,
} from '../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { ValidationRegex } from '../../../../contentModels/shared/models/elements/types/TypeElementData.ts';
import {
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
  allTableBlockCategoryFeatures,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
  allTopLevelBlockCategoryFeatures,
} from './editorLimitationUtils.ts';

export type AssetLimitations = {
  readonly fileSize: number | null;
  readonly fileType: AssetFileTypeOption;
  readonly maxHeight: number | null;
  readonly maxWidth: number | null;
  readonly minHeight: number | null;
  readonly minWidth: number | null;
};

export type BaseTextLimitations = {
  readonly maxChars: number | null;
  readonly maxWords: number | null;
};

type RegexLimitations = {
  readonly validationRegex: ValidationRegex | null;
};

export type TextLimitations = BaseTextLimitations & RegexLimitations;

export type UrlSlugLimitations = RegexLimitations;

export type LinkedItemsLimitations = {
  readonly allowedTypes: ReadonlyArray<Uuid>;
};

export type EditorFeatureLimitations = {
  readonly allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature>;
  readonly allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlySet<TextFormattingFeature>;
  readonly allowedTableBlocks: ReadonlySet<TableBlockCategoryFeature>;
  readonly allowedTableTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTableTextFormatting: ReadonlySet<TextFormattingFeature>;
};

export type ItemLinkLimitations = {
  readonly allowedItemLinkTypes: ReadonlyArray<Uuid>;
};

export type EditorLimitations = AssetLimitations &
  LinkedItemsLimitations &
  ItemLinkLimitations &
  BaseTextLimitations &
  EditorFeatureLimitations;

export const SimpleTextEditorLimitations: EditorLimitations = {
  allowedBlocks: new Set([TopLevelBlockCategoryFeature.Text]),
  allowedItemLinkTypes: [],
  allowedTextFormatting: new Set([TextFormattingFeature.Unstyled]),
  allowedTextBlocks: new Set([TextBlockTypeFeature.Paragraph]),
  allowedTableBlocks: new Set(),
  allowedTableTextBlocks: new Set(),
  allowedTableTextFormatting: new Set(),
  allowedTypes: [],
  fileSize: null,
  fileType: defaultAssetFileTypeOption,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
  maxChars: null,
  maxWords: null,
};

export const UnlimitedEditorLimitations: EditorLimitations = {
  allowedBlocks: allTopLevelBlockCategoryFeatures,
  allowedItemLinkTypes: [],
  allowedTextFormatting: allTextFormattingFeatures,
  allowedTextBlocks: allTextBlockTypeFeatures,
  allowedTableBlocks: allTableBlockCategoryFeatures,
  allowedTableTextFormatting: allTextFormattingFeatures,
  allowedTableTextBlocks: allTextBlockTypeFeatures,
  allowedTypes: [],
  fileSize: null,
  fileType: defaultAssetFileTypeOption,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
  maxChars: null,
  maxWords: null,
};
