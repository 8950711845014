import React from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { languageVariantSwitched } from '../../actions/contentItemEditingActions.ts';
import {
  ContentEditorLanguageSwitcher as ContentEditorLanguageSwitcherComponent,
  IContentEditorLanguageSwitcherProps,
} from '../../components/statusBar/ContentEditorLanguageSwitcher.tsx';

export const ContentEditorLanguageSwitcher: React.FC<
  Omit<IContentEditorLanguageSwitcherProps, 'onLanguageSwitch'>
> = (props) => {
  const dispatch = useDispatch();

  const onLanguageSwitch = () => {
    dispatch(languageVariantSwitched());
    dispatch(trackUserEvent(TrackedEvent.LanguageSwitched));
  };

  return (
    <ContentEditorLanguageSwitcherComponent
      onLanguageSwitch={onLanguageSwitch}
      options={props.options}
      selectedLanguageId={props.selectedLanguageId}
    />
  );
};
