import { combineReducers } from '@reduxjs/toolkit';
import { dataReducer } from './data.ts';
import { editorReducer } from './editor.ts';
import { listingReducer } from './listing.ts';

export const contentTypesRootReducer = combineReducers({
  data: dataReducer,
  listing: listingReducer,
  editor: editorReducer,
});
