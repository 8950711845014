import {
  IWorkflowStep,
  WorkflowStepAction,
} from '../../../../../data/models/workflow/WorkflowStep.ts';
import { LastCascadeAction } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';

export const isInPublishingStepOrAfterUndo = (
  workflowStatus: IWorkflowStep,
  lastAction: LastCascadeAction,
): boolean => {
  const isAfterUndo = [LastCascadeAction.UndoPublish, LastCascadeAction.UndoSchedule].includes(
    lastAction,
  );
  const isInPublishingStep =
    workflowStatus.action === WorkflowStepAction.Publish ||
    workflowStatus.action === WorkflowStepAction.ScheduleToPublish;

  return isInPublishingStep || isAfterUndo;
};
