import { memoize } from '@kontent-ai/memoization';
import { notNullNorUndefined } from '@kontent-ai/utils';
import { ContentState } from 'draft-js';
import Immutable from 'immutable';
import { distinctFilter } from '../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { AssetValidationData } from '../../../../_shared/utils/assets/assetValidationUtils.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { defaultRichTextFileTypeOption } from '../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { IRichTextTypeElement } from '../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import { getViolatedFeaturesWarning } from '../../../richText/editors/richText/plugins/utils/richTextTypeLimitationUtils.ts';
import {
  GetViolatedFeaturesInContentState,
  IViolatedFeaturesResult,
  TableBlockCategoryFeature,
  TopLevelBlockCategoryFeature,
} from '../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { getAllContentLinkIds } from '../../../richText/plugins/links/api/editorLinkUtils.ts';
import { isTableCellContent } from '../../../richText/utils/blocks/blockTypeUtils.ts';
import { IGetReferencesFromContentState } from '../../../richText/utils/general/editorContentUtils.ts';
import {
  AssetFileSizeWarning,
  ImageHeightExactlyWarning,
  ImageHeightMaxWarning,
  ImageHeightMinWarning,
  ImageWidthExactlyWarning,
  ImageWidthMaxWarning,
  ImageWidthMinWarning,
  ResponsiveImageTypeOnlyWarning,
  RichTextDisallowedContentTypes,
  RichTextDisallowedItemLinkTypes,
  TextIsRequiredWarning,
  TextTooLongWarning,
} from '../../constants/warningMessageTemplates.ts';
import { IRichTextItemElement } from '../../models/contentItemElements/RichTextItemElement.ts';
import { IGetItemElementWarnings } from '../getItemElementWarnings.ts';
import { IRichTextWarningResult } from './types/IRichTextItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from './types/Warnings.ts';
import { GetInlineImagesUsedInEditorState } from './utils/getInlineImagesUsedInEditorState.ts';

const validateRequiredLimit = (
  params: IParams,
  hasExistingImage: (
    contentState: ContentState,
    loadedAssets: Immutable.Map<Uuid, IAsset>,
  ) => boolean,
  hasExistingModularContent: (
    contentState: ContentState,
    loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
  ) => boolean,
  hasNonWhitespaceChar: (contentState: ContentState) => boolean,
  hasContentComponent: (contentState: ContentState) => boolean,
): boolean => {
  const {
    itemElement: { _editorState: editorState },
    loadedAssets,
    loadedEntries,
    typeElement: { isRequired },
  } = params;

  if (!isRequired) {
    return true;
  }

  const contentState = editorState.getCurrentContent();
  const containsNonWhitespaceChar = hasNonWhitespaceChar(contentState);
  const hasImages = hasExistingImage(contentState, loadedAssets);
  const containsModularContent = hasExistingModularContent(contentState, loadedEntries);
  const containsContentComponent = hasContentComponent(contentState);

  return (
    containsNonWhitespaceChar || hasImages || containsModularContent || containsContentComponent
  );
};

const hasOnlyAllowedContentTypes = (
  allowedTypesIds: ReadonlyArray<Uuid>,
  referencedItemTypeIds: ReadonlyArray<Uuid>,
): boolean => {
  if (!allowedTypesIds || !allowedTypesIds.length) {
    return true;
  }

  return referencedItemTypeIds.every((referencedItemTypeId: Uuid) => {
    return allowedTypesIds.includes(referencedItemTypeId);
  });
};

const hasOnlyAllowedItemLinkTypes = (
  content: ContentState,
  loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
  typeElement: IRichTextTypeElement,
): boolean => {
  const itemLinkIds = getAllContentLinkIds(content);
  const itemLinkTypeIds = loadedEntries
    .filter(
      (item: IListingContentItem) =>
        item && !item.item.archived && itemLinkIds.includes(item.item.id),
    )
    .toArray()
    .map((item) => item.item.typeId)
    .filter(distinctFilter);

  return hasOnlyAllowedContentTypes(typeElement.allowedItemLinkTypes, itemLinkTypeIds);
};

interface IParams {
  readonly contentComponentTypeIds: ReadonlyMap<Uuid, Uuid>;
  readonly itemElement: IRichTextItemElement;
  readonly loadedAssets: Immutable.Map<Uuid, IAsset>;
  readonly loadedEntries: Immutable.Map<Uuid, IListingContentItem>;
  readonly typeElement: IRichTextTypeElement;
}

export interface IGetRichTextItemElementValidationWarningDependencies {
  readonly getCharacterCount: (content: ContentState) => number;
  readonly getContentComponentIds: IGetReferencesFromContentState;
  readonly getWordCount: (content: ContentState) => number;
  readonly getViolatedFeaturesInContentState: GetViolatedFeaturesInContentState;
  readonly getInlineImagesUsedInEditorState: GetInlineImagesUsedInEditorState;
  readonly getModularContentItemIds: IGetReferencesFromContentState;
  readonly hasContentComponent: (content: ContentState) => boolean;
  readonly hasExistingImage: (
    content: ContentState,
    loadedAssets: Immutable.Map<Uuid, IAsset>,
  ) => boolean;
  readonly hasExistingModularContent: (
    content: ContentState,
    loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
  ) => boolean;
  readonly hasNonWhitespaceChar: (content: ContentState) => boolean;
  readonly isEveryImageResponsive: (assets: ReadonlyArray<AssetValidationData>) => boolean;
  readonly validateFileSizeForAssets: (
    assets: ReadonlyArray<AssetValidationData>,
    fileSizeLimit: number | null,
  ) => boolean;
  readonly validateHeightForAssets: (
    assets: ReadonlyArray<AssetValidationData>,
    min: number | null,
    max: number | null,
  ) => boolean;
  readonly validateMaxChars: (maxChars: number | null, charsCount: number) => boolean;
  readonly validateMaxWords: (maxWords: number | null, wordsCount: number) => boolean;
  readonly validateWidthForAssets: (
    assets: ReadonlyArray<AssetValidationData>,
    min: number | null,
    max: number | null,
  ) => boolean;
}

const getMemoizedResult = memoize.allForever(
  (
    hasMinWidth: boolean,
    hasMaxWidth: boolean,
    hasMinHeight: boolean,
    hasMaxHeight: boolean,
    maxChars: number | null,
    maxWords: number | null,
    isWidthLimitMet: boolean,
    isHeightLimitMet: boolean,
    isFileSizeLimitMet: boolean,
    isResponsiveImageTypeLimitMet: boolean,
    isMaxCharsLimitMet: boolean,
    isMaxWordsLimitMet: boolean,
    isRequiredLimitMet: boolean,
    containsOnlyAllowedContentTypes: boolean,
    containsOnlyAllowedItemLinkTypes: boolean,
    violatedFeatures: IViolatedFeaturesResult,
  ): IRichTextWarningResult => {
    const { violatedTopLevelFeatures, violatedTableFeatures } = violatedFeatures;
    const warningMessages: Array<string> = [];

    if (violatedTopLevelFeatures.size) {
      warningMessages.push(getViolatedFeaturesWarning(violatedTopLevelFeatures));
    }

    if (violatedTableFeatures.size) {
      warningMessages.push(getViolatedFeaturesWarning(violatedTableFeatures, true));
    }

    const containsInvalidTextBlocks = violatedTopLevelFeatures.has(
      TopLevelBlockCategoryFeature.Text,
    );
    const containsInvalidTopLevelImageBlocks = violatedTopLevelFeatures.has(
      TopLevelBlockCategoryFeature.Images,
    );
    const containsInvalidTableImageBlocks = violatedTableFeatures.has(
      TopLevelBlockCategoryFeature.Images,
    );
    const containsInvalidComponentBlocks = violatedTopLevelFeatures.has(
      TopLevelBlockCategoryFeature.ComponentsAndItems,
    );

    if (!containsInvalidTopLevelImageBlocks && !containsInvalidTableImageBlocks) {
      warningMessages.push(
        ...getImageValidationWarningMessages(
          hasMinWidth,
          hasMaxWidth,
          hasMinHeight,
          hasMaxHeight,
          isWidthLimitMet,
          isHeightLimitMet,
          isFileSizeLimitMet,
          isResponsiveImageTypeLimitMet,
        ),
      );
    }

    if (!containsInvalidTextBlocks) {
      warningMessages.push(
        ...getTextValidationWarningMessages(
          maxChars,
          maxWords,
          isMaxCharsLimitMet,
          isMaxWordsLimitMet,
        ),
      );
    }

    if (!containsInvalidComponentBlocks) {
      warningMessages.push(
        ...getComponentValidationWarningMessages(containsOnlyAllowedContentTypes),
      );
    }

    if (!containsOnlyAllowedItemLinkTypes) {
      warningMessages.push(RichTextDisallowedItemLinkTypes);
    }

    return {
      ...emptyItemElementWarningResult,
      containsOnlyAllowedContentTypes,
      containsOnlyAllowedItemLinkTypes,
      isFileSizeLimitMet,
      isHeightLimitMet,
      isMaxCharsLimitMet,
      isMaxWordsLimitMet,
      isResponsiveImageTypeLimitMet,
      isWidthLimitMet,
      limitationMessages: [...warningMessages],
      requiredMessage: isRequiredLimitMet ? null : TextIsRequiredWarning,
      violatedFeatures,
    };
  },
);

function getImageValidationWarningMessages(
  hasMinWidth: boolean,
  hasMaxWidth: boolean,
  hasMinHeight: boolean,
  hasMaxHeight: boolean,
  isWidthLimitMet: boolean,
  isHeightLimitMet: boolean,
  isFileSizeLimitMet: boolean,
  isResponsiveImageTypeLimitMet: boolean,
): ReadonlyArray<string> {
  const warningMessages: Array<string> = [];

  if (!isWidthLimitMet) {
    if (hasMinWidth && hasMaxWidth) {
      warningMessages.push(ImageWidthExactlyWarning(false));
    } else if (hasMinWidth) {
      warningMessages.push(ImageWidthMinWarning);
    } else if (hasMaxWidth) {
      warningMessages.push(ImageWidthMaxWarning(false));
    }
  }

  if (!isHeightLimitMet) {
    if (hasMinHeight && hasMaxHeight) {
      warningMessages.push(ImageHeightExactlyWarning(false));
    } else if (hasMinHeight) {
      warningMessages.push(ImageHeightMinWarning);
    } else if (hasMaxHeight) {
      warningMessages.push(ImageHeightMaxWarning(false));
    }
  }

  if (!isFileSizeLimitMet) {
    warningMessages.push(AssetFileSizeWarning);
  }

  if (!isResponsiveImageTypeLimitMet) {
    warningMessages.push(ResponsiveImageTypeOnlyWarning);
  }

  return warningMessages;
}

function getTextValidationWarningMessages(
  maxChars: number | null,
  maxWords: number | null,
  isMaxCharsLimitMet: boolean,
  isMaxWordsLimitMet: boolean,
): ReadonlyArray<string> {
  const warningMessages: Array<string> = [];

  if (!isMaxCharsLimitMet) {
    warningMessages.push(TextTooLongWarning(maxChars, 'characters'));
  }

  if (!isMaxWordsLimitMet) {
    warningMessages.push(TextTooLongWarning(maxWords, 'words'));
  }

  return warningMessages;
}

function getComponentValidationWarningMessages(
  containsOnlyAllowedContentTypes: boolean,
): ReadonlyArray<string> {
  const warningMessages: Array<string> = [];

  if (!containsOnlyAllowedContentTypes) {
    warningMessages.push(RichTextDisallowedContentTypes);
  }

  return warningMessages;
}

export const getRichTextItemElementValidationWarningCreator =
  (
    deps: IGetRichTextItemElementValidationWarningDependencies,
  ): IGetItemElementWarnings<IRichTextItemElement, IParams> =>
  (params: IParams): IRichTextWarningResult => {
    const { contentComponentTypeIds, itemElement, loadedAssets, loadedEntries, typeElement } =
      params;

    const editorState = itemElement._editorState;
    const content = editorState.getCurrentContent();

    const violatedFeatures = deps.getViolatedFeaturesInContentState(content, typeElement);

    const contentComponentIds = deps.getContentComponentIds(content);
    const usedContentComponentTypeIds = contentComponentIds
      .map((contentComponentId) => contentComponentTypeIds.get(contentComponentId))
      .filter(notNullNorUndefined)
      .filter(distinctFilter);

    const linkedItemsIds = deps.getModularContentItemIds(content);
    const linkedItemTypeIds = linkedItemsIds
      .map((linkedItemId) => loadedEntries.get(linkedItemId))
      .filter(notNullNorUndefined)
      .filter((linkedItem) => !linkedItem.item.archived)
      .map((linkedItem) => linkedItem.item.typeId)
      .filter(distinctFilter);

    const containsOnlyAllowedItemLinkTypes = hasOnlyAllowedItemLinkTypes(
      content,
      loadedEntries,
      typeElement,
    );

    const containsOnlyAllowedContentTypes =
      hasOnlyAllowedContentTypes(typeElement.allowedTypes, usedContentComponentTypeIds) &&
      hasOnlyAllowedContentTypes(typeElement.allowedTypes, linkedItemTypeIds);
    const isTextAllowed = typeElement.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
    const isTableAllowed = typeElement.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
    const isImageAllowedInTopLevel = typeElement.allowedBlocks.has(
      TopLevelBlockCategoryFeature.Images,
    );
    const isImageAllowedInTable = typeElement.allowedTableBlocks.has(
      TableBlockCategoryFeature.Images,
    );

    const charsCount = deps.getCharacterCount(content);
    const wordsCount = deps.getWordCount(content);
    const maxChars = typeElement.maxChars;
    const maxWords = typeElement.maxWords;
    const minWidth = typeElement.minWidth;
    const maxWidth = typeElement.maxWidth;
    const minHeight = typeElement.minHeight;
    const maxHeight = typeElement.maxHeight;

    const allowedAssets = deps.getInlineImagesUsedInEditorState(
      editorState,
      loadedAssets,
      (block) =>
        isTableCellContent(block)
          ? isTableAllowed && isImageAllowedInTable
          : isImageAllowedInTopLevel,
    );
    const ignoreCharsAndWordsLimit = !isTextAllowed;
    const ignoreAssetLimitations = !isImageAllowedInTopLevel;
    const isMaxCharsLimitMet =
      ignoreCharsAndWordsLimit || deps.validateMaxChars(maxChars, charsCount);
    const isMaxWordsLimitMet =
      ignoreCharsAndWordsLimit || deps.validateMaxWords(maxWords, wordsCount);
    const isRequiredLimitMet = validateRequiredLimit(
      params,
      deps.hasExistingImage,
      deps.hasExistingModularContent,
      deps.hasNonWhitespaceChar,
      deps.hasContentComponent,
    );
    const isFileSizeLimitMet =
      ignoreAssetLimitations || deps.validateFileSizeForAssets(allowedAssets, typeElement.fileSize);
    const isResponsiveImageTypeLimitMet =
      ignoreAssetLimitations ||
      typeElement.fileType === defaultRichTextFileTypeOption ||
      deps.isEveryImageResponsive(allowedAssets);
    const isWidthLimitMet =
      ignoreAssetLimitations || deps.validateWidthForAssets(allowedAssets, minWidth, maxWidth);
    const isHeightLimitMet =
      ignoreAssetLimitations || deps.validateHeightForAssets(allowedAssets, minHeight, maxHeight);

    return getMemoizedResult(
      !!minWidth,
      !!maxWidth,
      !!minHeight,
      !!maxHeight,
      maxChars,
      maxWords,
      isWidthLimitMet,
      isHeightLimitMet,
      isFileSizeLimitMet,
      isResponsiveImageTypeLimitMet,
      isMaxCharsLimitMet,
      isMaxWordsLimitMet,
      isRequiredLimitMet,
      containsOnlyAllowedContentTypes,
      containsOnlyAllowedItemLinkTypes,
      violatedFeatures,
    );
  };
