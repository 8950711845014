import { FormValidatorConfig } from '../../../_shared/utils/validation/createFormValidationResolver.ts';
import { isAbsoluteUrlBuilder } from '../../../_shared/utils/validation/isAbsoluteUrl.ts';
import { nonEmptyValidationBuilder } from '../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { isNotLocalhostUrlBuilder } from '../../../_shared/utils/validation/isLocalhostUrl.ts';
import { IWebhookFormShape } from '../models/IWebhookFormShape.type.ts';
import { areAllTriggersEmpty } from '../utils/areAllTriggersEmpty.ts';

export const localhostUrlErrorMessage = 'Localhost addresses cannot receive webhooks.';

const nameRequired = nonEmptyValidationBuilder('name', 'webhook');
const urlRequired = nonEmptyValidationBuilder('URL address', 'webhook');
const secretRequired = nonEmptyValidationBuilder('secret', 'webhook');
const webhookShouldHasAbsoluteUrlAddress = isAbsoluteUrlBuilder();
const webhookUrlMustNotHaveLocalhostDomain = isNotLocalhostUrlBuilder(localhostUrlErrorMessage);

const hasAnyTrigger = (_: unknown, formValues: IWebhookFormShape): string | undefined =>
  areAllTriggersEmpty(formValues.triggers) ? 'Required' : undefined;

export const webhookItemEditorFormValidationConfig: FormValidatorConfig<IWebhookFormShape> = {
  name: [nameRequired],
  url: [urlRequired, webhookShouldHasAbsoluteUrlAddress, webhookUrlMustNotHaveLocalhostDomain],
  secret: [secretRequired],
  triggers: [hasAnyTrigger],
};
