import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ITaxonomyItemElementWarningResult } from '../../../utils/itemElementWarningCheckers/types/ITaxonomyItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from '../../../utils/itemElementWarningCheckers/types/Warnings.ts';
import { getValidationSelectorId } from '../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import {
  ContentComponentTaxonomyElement as ContentComponentTaxonomyElementComponent,
  IContentComponentTaxonomyElementOwnProps,
} from '../components/ContentComponentTaxonomyElement.tsx';

const defaultValidationResult: ITaxonomyItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  isNumberOfTermsLimitMet: true,
};

export const ContentComponentTaxonomyElement: React.FC<IContentComponentTaxonomyElementOwnProps> = (
  props,
) => {
  const taxonomyGroup = useSelector((s) =>
    s.data.taxonomyGroups.byId.get(props.typeElement.taxonomyGroupId ?? ''),
  );
  const isNumberOfTermsLimitMet = useSelector((s) => {
    const validationSelectorId = getValidationSelectorId(
      props.typeElement.elementId,
      props.contentComponentId,
    );
    const validationWarning =
      (s.contentApp.itemValidationWarnings.get(
        validationSelectorId,
      ) as ITaxonomyItemElementWarningResult) ?? defaultValidationResult;
    return validationWarning.isNumberOfTermsLimitMet;
  });

  return (
    <ContentComponentTaxonomyElementComponent
      {...props}
      taxonomyGroup={taxonomyGroup}
      isNumberOfTermsLimitMet={isNumberOfTermsLimitMet}
    />
  );
};

ContentComponentTaxonomyElement.displayName = 'ContentComponentTaxonomyElement';
