import {
  TypeElementCondition,
  convertConditionFromServerModel,
} from '../../../../../../_shared/models/utils/TypeElementCondition.ts';
import { CompiledContentTypeElementServerModel } from '../../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ValidationRegex } from '../../../../../contentModels/shared/models/elements/types/TypeElementData.ts';
import { TypeElementConfigurability } from '../../../../../contentModels/shared/models/elements/types/elementConfigurabilityOptions.ts';
import {
  TableBlockCategoryFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  TopLevelBlockCategoryFeature,
} from '../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { AssetFileTypeOption } from '../../assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../fileSizeOptions.ts';
import { QuantityOption } from '../../quantityOptions.ts';
import { UnitOption } from '../../textLengthOptions.ts';

export interface IBaseTypeElement {
  readonly _configurability: TypeElementConfigurability;
  readonly codename: string | null;
  readonly condition: TypeElementCondition | null;
  readonly contentGroupId: Uuid | null;
  readonly elementId: Uuid;
  readonly guidelines: string;
  readonly isNonLocalizable: boolean;
  readonly isRequired: boolean;
  readonly name: string;
}

export const EmptyBaseTypeElement: Omit<IBaseTypeElement, '_configurability'> = {
  codename: null,
  condition: null,
  contentGroupId: null,
  elementId: '',
  guidelines: '',
  isNonLocalizable: false,
  isRequired: false,
  name: '',
};

export interface ITypeElementWithNumberOfItemsLimit {
  readonly _quantityUnitOption: QuantityOption;
  readonly maxItems: number | null;
  readonly minItems: number | null;
}

export interface ITypeElementWithAllowedItemLinkTypesLimit {
  readonly allowedItemLinkTypes: ReadonlyArray<Uuid>;
}

export interface ITypeElementWithAllowedContentTypesLimit {
  readonly allowedTypes: ReadonlyArray<Uuid>;
}

export interface ITypeElementWithFileSizeLimit {
  readonly _fileSizeUnitOption: FileSizeUnit;
  readonly fileSize: number | null;
}

export interface ITypeElementWithImageDimensions {
  readonly maxHeight: number | null;
  readonly maxWidth: number | null;
  readonly minHeight: number | null;
  readonly minWidth: number | null;
}

export interface ITypeElementWithTextLimit {
  readonly _lengthUnitOption: UnitOption;
  readonly maxChars: number | null;
  readonly maxWords: number | null;
}

export interface ITypeElementWithValidationRegex {
  readonly validationRegex: ValidationRegex | null;
}

export interface ITypeElementWithAssetTypeLimitation {
  readonly fileType: AssetFileTypeOption;
}

export interface ITypeElementWithAssetLimit
  extends ITypeElementWithImageDimensions,
    ITypeElementWithFileSizeLimit,
    ITypeElementWithAssetTypeLimitation {}

export interface ITypeElementWithLimitationCategories {
  readonly allowedBlocks: ReadonlySet<TopLevelBlockCategoryFeature>;
  readonly allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlySet<TextFormattingFeature>;
  readonly allowedTableBlocks: ReadonlySet<TableBlockCategoryFeature>;
  readonly allowedTableTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTableTextFormatting: ReadonlySet<TextFormattingFeature>;
}

export interface ITypeElementWithAiGuidelinesIds {
  readonly aiGuidelinesIds: ReadonlyArray<Uuid>;
}

export function getBaseTypeElementFromServerModel(
  serverModel: CompiledContentTypeElementServerModel,
): Omit<IBaseTypeElement, '_configurability'> {
  return {
    codename: serverModel.codeName,
    condition: convertConditionFromServerModel(serverModel.conditions),
    contentGroupId: serverModel.contentGroupId,
    elementId: serverModel.elementId,
    guidelines: serverModel.guidelines || EmptyBaseTypeElement.guidelines,
    isNonLocalizable: !!serverModel.isNonLocalizable,
    isRequired: !!serverModel.isRequired,
    name: serverModel.name,
  };
}
