import { Action } from '../../../../../@types/Action.type.ts';
import {
  Sitemap_StartDraggingNode,
  Sitemap_StopDraggingNode,
} from '../../constants/sitemapActionTypes.ts';

export const draggedNodeId = (state: Uuid | null = null, action: Action): Uuid | null => {
  switch (action.type) {
    case Sitemap_StartDraggingNode:
      return action.payload.nodeId;

    case Sitemap_StopDraggingNode:
      return null;

    default:
      return state;
  }
};
