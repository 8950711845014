import { IAsset } from '../../../../data/models/assets/Asset.ts';

export const wasAssetMatchedOnlyByTag = (asset: IAsset, searchPhrase: string): boolean => {
  const searchPhraseLowercase = searchPhrase.trim().toLowerCase();
  const searchPhraseWords = searchPhraseLowercase.split(/[_\s]+/);

  const assetDescriptions = asset.descriptions.toArray();
  const matchedByDescriptionOrTitleOrFilename = searchPhraseWords.every((word) => {
    const searchRegex = new RegExp(word, 'i');

    return (
      !!asset.title?.match(searchRegex) ||
      !!asset.filename.match(searchRegex) ||
      assetDescriptions.find((d) => d.match(searchRegex))
    );
  });

  if (matchedByDescriptionOrTitleOrFilename) {
    return false;
  }

  const tagSearchStrings = [...searchPhraseWords, searchPhraseLowercase];
  const lowercaseTags = asset.tags.map((tag) => tag.toLowerCase());
  const matchedByTag = !!tagSearchStrings.find(
    (searchString) => !!lowercaseTags.find((tag) => tag.startsWith(searchString)),
  );

  return matchedByTag;
};
