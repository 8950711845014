import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IUserListingConfig } from '../../../../../localStorages/userListingConfigStorage.ts';
import {
  UserListing_Init_Finished,
  UserListing_Init_Started,
} from '../../constants/usersActionTypes.ts';

interface IInitializeLanguageEditingForProjectDependencies {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadExistingUsers: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjectContributors: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadRoles: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadUsersCountedInUsage: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly UserListingConfigStorage: IProjectSpecificStorage<IUserListingConfig>;
}

const userListingInitStarted = () =>
  ({
    type: UserListing_Init_Started,
  }) as const;

const userListingInitFinished = (userListingConfig: IUserListingConfig) =>
  ({
    type: UserListing_Init_Finished,
    payload: {
      userListingConfig,
    },
  }) as const;

export type InitializePeopleListingForProjectActionsType = ReturnType<
  typeof userListingInitStarted | typeof userListingInitFinished
>;

export const initializePeopleListingForProjectCreator =
  (deps: IInitializeLanguageEditingForProjectDependencies) =>
  (projectId: Uuid, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { user },
    } = getState();

    dispatch(userListingInitStarted());

    const configuration = deps.UserListingConfigStorage.load(projectId);

    await Promise.all([
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadProjectContributors(abortSignal)),
      dispatch(deps.loadProjects(abortSignal)),
      dispatch(deps.loadRoles(abortSignal)),
      dispatch(
        deps.loadSubscriptionUsage(
          user.projectsInfoById.get(projectId)?.subscriptionId || '',
          abortSignal,
        ),
      ),
      dispatch(deps.loadExistingUsers(abortSignal)),
      dispatch(deps.loadUsersCountedInUsage(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
    ]);

    dispatch(userListingInitFinished(configuration));
  };
