import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { editItemVariantCodenameByItemId } from '../actions/thunks/editItemVariantCodenameByItemId.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { EditItemVariantCodenameRouteParams } from '../constants/routePaths.ts';

export const EditItemVariantCodenameSmartLink = () => {
  const {
    projectId,
    itemGuid: itemId,
    variantCodename,
  } = useParams<EditItemVariantCodenameRouteParams>();

  const params = useMemo(
    () => ({
      projectId,
      variantCodename,
      itemId,
    }),
    [projectId, variantCodename, itemId],
  );

  useThunkPromise(editItemVariantCodenameByItemId, params);

  return <SmartLink />;
};
