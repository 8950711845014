import { Box } from '@kontent-ai/component-library/Box';
import { Toggle } from '@kontent-ai/component-library/Toggle';
import React, { ComponentProps } from 'react';
import { DialogState } from '../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { addFlipping } from '../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { IDialogActionWithMoreOptions } from '../../../component-library/components/Dialogs/components/DialogFooterAction.tsx';
import {
  DataUiAction,
  DataUiSwitchAction,
  Popovers,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export interface IUserActiveStatus {
  readonly isActivating: boolean;
  readonly isActive: boolean;
  readonly isDeactivating: boolean;
  readonly isInvitationPending: boolean;
}

export interface IPopoverTexts {
  readonly headlineText: string;
  readonly primaryActionText: string;
}

interface IUserActiveStatusToggleWithConfirmationDialogProps {
  readonly activatingUserOverMaxLimit: boolean;
  readonly disabled: boolean;
  readonly isCurrentUserAdmin: boolean;
  readonly isCustomPlan: boolean;
  readonly popoverTexts: IPopoverTexts;
  readonly showConfirmationDialog: boolean;
  readonly tooltipText?: string;
  readonly userActiveStatus: IUserActiveStatus;

  readonly onSelectPlan: () => void;
  readonly onShowIntercom: () => void;
  readonly onToggleUserActiveState: () => void;
  readonly renderPopoverDialogContent: () => React.ReactNode;
}

const getUserStatusText = (userActiveStatus: IUserActiveStatus): string => {
  if (userActiveStatus.isActivating) {
    return 'Activating';
  }
  if (userActiveStatus.isDeactivating) {
    return 'Deactivating';
  }
  if (userActiveStatus.isInvitationPending) {
    return 'Pending';
  }
  if (userActiveStatus.isActive) {
    return 'Active';
  }

  return 'Deactivated';
};

export const UserActiveStatusToggleWithConfirmationDialog: React.FC<
  IUserActiveStatusToggleWithConfirmationDialogProps
> = ({
  activatingUserOverMaxLimit,
  disabled,
  isCurrentUserAdmin,
  isCustomPlan,
  popoverTexts,
  showConfirmationDialog,
  tooltipText,
  userActiveStatus,
  onSelectPlan,
  onShowIntercom,
  onToggleUserActiveState,
  renderPopoverDialogContent,
}) => {
  const { isActive, isActivating, isDeactivating } = userActiveStatus;

  const statusText = getUserStatusText(userActiveStatus);
  const status = (isActive && !isDeactivating) || isActivating ? 'on' : 'off';

  const onConfirm = () => {
    closeDialog();
    onToggleUserActiveState();
  };

  const planLimitReachedAction = isCurrentUserAdmin
    ? {
        text: isCustomPlan ? 'Contact us' : 'Select plan',
        onClick: isCustomPlan ? onShowIntercom : onSelectPlan,
      }
    : undefined;

  const confirmAction: IDialogActionWithMoreOptions = {
    text: popoverTexts.primaryActionText,
    onClick: onConfirm,
    ...getDataUiActionAttribute(DataUiAction.Confirm),
  };

  const { closeDialog, openDialog, isOpen, targetProps, popoverDialogProps, PopoverDialog } =
    usePopoverDialog({
      placement: 'bottom-end',
      headline: popoverTexts.headlineText,
      primaryAction: activatingUserOverMaxLimit ? planLimitReachedAction : confirmAction,
      dialogState: DialogState.Default,
      adjustTippyOptions: addFlipping,
      shouldCloseOnBlur: false,
      shouldCloseOnInteractOutside: () => false,
    });

  const toggleProps: ComponentProps<typeof Toggle> = {
    disabled,
    labelText: statusText,
    status,
    tooltipPlacement: 'top',
    tooltipText,
    ...getDataUiActionAttribute(DataUiSwitchAction.SwitchUserStatus),
  };

  if (!showConfirmationDialog) {
    return <Toggle {...toggleProps} onChange={onToggleUserActiveState} />;
  }

  return (
    <>
      <Toggle {...toggleProps} onChange={isOpen ? closeDialog : openDialog} {...targetProps} />
      {isOpen && (
        <PopoverDialog
          {...popoverDialogProps}
          {...getDataUiObjectNameAttribute(Popovers.UserStateChangePopover)}
        >
          <Box maxWidth={600}>{renderPopoverDialogContent()}</Box>
        </PopoverDialog>
      )}
    </>
  );
};

UserActiveStatusToggleWithConfirmationDialog.displayName =
  'UserActiveStatusToggleWithConfirmationDialog';
