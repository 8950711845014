import { Action } from '../../../../../@types/Action.type.ts';
import {
  Environment_CreateEnvironment_CloseDialog,
  Environment_CreateEnvironment_Failed,
  Environment_CreateEnvironment_Finished,
  Environment_CreateEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';

export const creatingEnvironmentFailed = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Environment_CreateEnvironment_Started:
    case Environment_CreateEnvironment_Finished:
    case Environment_CreateEnvironment_CloseDialog:
      return false;

    case Environment_CreateEnvironment_Failed:
      return true;

    default:
      return state;
  }
};
