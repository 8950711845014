import { useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { areSpacesInCollectionsEnabled } from '../../selectors/allowedFeaturesUtils.ts';
import { spacesByCollectionId } from '../utils/spacesUtils.ts';

export const useSpacesIds = (collectionId: Uuid | undefined | null): readonly Uuid[] => {
  const spacesMap = useSelector((s) => s.data.spaces.byId);
  const spacesInCollectionsEnabled = useSelector(areSpacesInCollectionsEnabled);

  return useMemo(() => {
    return spacesInCollectionsEnabled && collectionId
      ? spacesByCollectionId(spacesMap, collectionId).map((i) => i.id)
      : [];
  }, [collectionId, spacesMap, spacesInCollectionsEnabled]);
};
