import { isImageMimeType } from '../../../../../_shared/utils/fileTypeDetection.ts';
import {
  assetHasRequiredDescriptionCreator,
  checkAssetDescriptionRequirements,
} from './internalUtilsCreators/assetHasRequiredDescription.ts';
import { assetsHaveRequiredDescriptionsCreator } from './internalUtilsCreators/assetsHaveRequiredDescriptions.ts';

const assetHasRequiredDescriptions = assetHasRequiredDescriptionCreator(
  checkAssetDescriptionRequirements,
  isImageMimeType,
);

export const assetsHaveRequiredDescriptions = assetsHaveRequiredDescriptionsCreator(
  assetHasRequiredDescriptions,
);
