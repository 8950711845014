import React, { useCallback } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getConsistentSelectedTermIds } from '../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { TaxonomySelector } from '../../../../../itemEditor/features/ContentItemEditing/containers/elements/taxonomy/TaxonomySelector.tsx';
import { ITaxonomyItemElement } from '../../../../../itemEditor/models/contentItemElements/TaxonomyItemElement.ts';
import { ITaxonomyTypeElement } from '../../../../content/models/contentTypeElements/TaxonomyTypeElement.ts';
import { IAssetElementProps } from './AssetElementProps.type.ts';
import { AssetElementWrapper } from './AssetElementWrapper.tsx';

export const TaxonomyAssetElement: React.FC<
  IAssetElementProps<ITaxonomyItemElement, ITaxonomyTypeElement>
> = ({ disabled, elementData, onChange, typeElement }) => {
  const taxonomyGroups = useSelector((state: IStore) => state.data.taxonomyGroups.byId);
  const selectedTermIds = getConsistentSelectedTermIds(
    elementData.groupId,
    elementData.value,
    taxonomyGroups.get(typeElement.taxonomyGroupId ?? ''),
  );

  const onTermSelectionChanged = useCallback(
    (updatedTermIds: ReadonlySet<Uuid>) => {
      onChange({
        ...elementData,
        // When the element value wasn't updated yet (e.g. right after upload or when )
        // the groupId is missing from the element and is not populated until the actual save
        // But the detection of (un)categorized status needs it earlier, therefore we make sure that it is already populated within the editor value change
        groupId: typeElement.taxonomyGroupId,
        value: updatedTermIds,
      });
    },
    [onChange, elementData, typeElement],
  );

  return (
    <AssetElementWrapper disabled={disabled} typeElement={typeElement}>
      <TaxonomySelector
        disabled={disabled}
        taxonomyGroupId={typeElement.taxonomyGroupId}
        selectedTermIds={selectedTermIds}
        onTermSelectionChanged={disabled ? undefined : onTermSelectionChanged}
        defaultValue={typeElement.defaultValue}
        maxItems={typeElement.maxItems}
        minItems={typeElement.minItems}
      />
    </AssetElementWrapper>
  );
};

TaxonomyAssetElement.displayName = 'TaxonomyAssetElement';
