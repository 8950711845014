import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getSelectedSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { ISubscriptionServerModel } from '../../../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import { UntitledSubscription } from '../../../shared/constants/UIConstants.ts';
import {
  Subscription_SubscriptionRename_Failed,
  Subscription_SubscriptionRename_Finished,
  Subscription_SubscriptionRename_Started,
} from '../../constants/actionTypes.ts';

interface ISaveSubscriptionNameToServerDependencies {
  readonly subscriptionRepository: {
    readonly renameSubscription: (
      name: string,
      subscriptionId: Uuid,
    ) => Promise<ISubscriptionServerModel>;
  };
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
}

const started = () =>
  ({
    type: Subscription_SubscriptionRename_Started,
  }) as const;

const finished = (name: string, subscriptionId: Uuid) =>
  ({
    type: Subscription_SubscriptionRename_Finished,
    payload: {
      name,
      subscriptionId,
    },
  }) as const;

const failed = (serverError: string) =>
  ({
    type: Subscription_SubscriptionRename_Failed,
    payload: {
      serverError,
    },
  }) as const;

export type SaveSubscriptionNameToServerActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const saveSubscriptionNameToServerCreator =
  (dependencies: ISaveSubscriptionNameToServerDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    dispatch(started());
    try {
      const selectedSubscription = getSelectedSubscription(getState());
      if (!selectedSubscription) {
        throw InvariantException('Selected subscription is falsy');
      }
      const editedSubscriptionName =
        getState().subscriptionApp.general.editedSubscriptionName || UntitledSubscription;
      await dependencies.subscriptionRepository.renameSubscription(
        editedSubscriptionName,
        selectedSubscription.subscriptionId,
      );
      dispatch(finished(editedSubscriptionName, selectedSubscription.subscriptionId));

      await dispatch(dependencies.loadAdministratedSubscriptions());
    } catch (e) {
      dispatch(failed(`Subscription could not be renamed: ${e}`));
    }
  };
