import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

export const getRecentlyUsedCollectionIdsForListing = (
  state: IStore,
): ReadonlyArray<Uuid> | null =>
  state.data.collections.byId.size > 5
    ? state.sharedApp.uiPreferences.collectionsRecentlyUsedInListing
    : null;

export const getRecentlyUsedCollectionIdsForEditing = (state: IStore): ReadonlyArray<Uuid> | null =>
  state.sharedApp.uiPreferences.collectionsRecentlyUsedInEditing;
