import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IFailedActionButtonProps {
  readonly onDelete: () => void;
  readonly isAdminOnProject: boolean;
  readonly isBeingDeleted: boolean;
}

export const FailedActionButton: FC<IFailedActionButtonProps> = ({
  isAdminOnProject,
  isBeingDeleted,
  onDelete,
}) => (
  <div className="my-project__button-wrapper">
    <div className="my-project__message">
      <Icon iconName={IconName.ExclamationTriangle} className="my-project__failed-icon" />
      <span className="my-project__message my-project__message--failed">
        The cloning failed. Please try again.
      </span>
    </div>
    {isAdminOnProject && (
      <Button
        buttonStyle="secondary"
        destructive
        size="medium"
        onClick={onDelete}
        disabled={isBeingDeleted}
      >
        {isBeingDeleted && (
          <Button.ProgressIcon
            {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
          />
        )}
        <Button.Label>{isBeingDeleted ? 'Deleting project' : 'Remove'}</Button.Label>
      </Button>
    )}
  </div>
);

FailedActionButton.displayName = 'FailedActionButton';
