import { History } from 'history';
import {
  AssetListingRoute,
  AssetListingRouteParams,
  AssetRoute,
  AssetRouteParams,
  AssetsRoute,
  AssetsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';

interface IBuildAssetListingRoutePathParams {
  readonly projectId: Uuid;
  readonly assetFolderId: Uuid;
}

interface IBuildAssetRoutePathParams {
  readonly assetId: Uuid;
  readonly projectId: Uuid;
}

interface IBuildAssetsRoutePathParams {
  readonly projectId: Uuid;
}

interface IRedirectToAssetFolderParams extends IBuildAssetListingRoutePathParams {
  readonly history: History;
}

export const buildAssetListingRoutePath = ({
  projectId,
  assetFolderId,
}: IBuildAssetListingRoutePathParams): string =>
  buildPath<AssetListingRouteParams>(AssetListingRoute, {
    projectId,
    assetFolderId,
  });

export const buildAssetRoutePath = ({ assetId, projectId }: IBuildAssetRoutePathParams): string =>
  buildPath<AssetRouteParams>(AssetRoute, {
    assetId,
    projectId,
  });

export const buildAssetsRoutePath = ({ projectId }: IBuildAssetsRoutePathParams): string =>
  buildPath<AssetsRouteParams>(AssetsRoute, { projectId });

export const redirectToAssetFolder = ({
  assetFolderId,
  projectId,
  history,
}: IRedirectToAssetFolderParams): void => {
  const path = buildAssetListingRoutePath({ projectId, assetFolderId });
  history.push(path);
};
