import React from 'react';
import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';

type CustomAppCreatorToolbarActionsProps = {
  readonly customAppActionIsInProgress: boolean;
  readonly onSubmit: (e?: KeyboardEvent | React.BaseSyntheticEvent) => Promise<void>;
};

export const CustomAppCreatorToolbarActions = ({
  customAppActionIsInProgress,
  onSubmit,
}: CustomAppCreatorToolbarActionsProps) => {
  return (
    <StatusBarActions
      isBeingSaved={customAppActionIsInProgress}
      isLoading={false}
      onSave={onSubmit}
    />
  );
};
