import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';
import {
  ContentRevisions_ComparisonClosed,
  ContentRevisions_ComparisonEnabled,
  ContentRevisions_ComparisonRevisionLoaded,
  ContentRevisions_RevisionLoaded,
  ContentRevisions_ViewerLeft,
} from '../constants/revisionActionTypes.ts';

export const revisionLoaded = (
  selectedRevision: TimelineItem,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
) =>
  ({
    type: ContentRevisions_RevisionLoaded,
    payload: {
      selectedRevision,
      elements,
    },
  }) as const;

export const comparisonRevisionLoaded = (
  comparisonRevision: TimelineItem,
  elements: ReadonlyArray<ICompiledContentItemElementData>,
) =>
  ({
    type: ContentRevisions_ComparisonRevisionLoaded,
    payload: {
      comparisonRevision,
      elements,
    },
  }) as const;

export const comparisonClosed = () =>
  ({
    type: ContentRevisions_ComparisonClosed,
  }) as const;

export const comparisonEnabled = () =>
  ({
    type: ContentRevisions_ComparisonEnabled,
  }) as const;

export const revisionViewerLeft = (editedItemId?: Uuid) =>
  ({
    type: ContentRevisions_ViewerLeft,
    payload: {
      editedItemId,
    },
  }) as const;
