import { useCallback } from 'react';
import { useEditorApi } from '../../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { ClipboardPlugin, PostProcessPastedContent } from '../../clipboard/ClipboardPlugin.tsx';
import {
  CanHandleNewCharsNatively,
  CustomInputHandlingPlugin,
  PostProcessAfterReturn,
  PostProcessInsertedChars,
} from '../../customInputHandling/CustomInputHandlingPlugin.tsx';
import { EditorLinkApi } from '../api/EditorLinkApi.type.ts';
import { evaluateLinkConversion } from '../api/automation/linkConversionUtils.ts';
import { editorLinkApi } from '../api/editorLinkApi.ts';

export type AutomaticWebLinkConversionPlugin = EditorPlugin<
  None,
  None,
  EditorLinkApi,
  None,
  [CustomInputHandlingPlugin, ClipboardPlugin]
>;

const canHandleNewCharsNatively: Decorator<CanHandleNewCharsNatively> =
  (baseCanHandleNewCharsNatively) => (params) => {
    if (!baseCanHandleNewCharsNatively(params)) {
      return false;
    }

    const { chars, editorState } = params;
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const block = content.getBlockForKey(selection.getStartKey());
    if (block) {
      const offset = selection.getStartOffset();
      const text = block.getText();
      const newTextBeforeCaret = text.substring(0, offset) + chars;

      return !evaluateLinkConversion(newTextBeforeCaret, chars[chars.length - 1] ?? null);
    }

    return true;
  };

export const AutomaticWebLinkConversionPlugin: PluginComponent<AutomaticWebLinkConversionPlugin> = (
  props,
) => {
  const apply: Apply<AutomaticWebLinkConversionPlugin> = useCallback((state) => {
    const postProcessInsertedChars: Decorator<PostProcessInsertedChars> =
      (basePostProcessInsertedChars) => (params) => {
        const newEditorState = basePostProcessInsertedChars(params);
        const withLinksConverted = state
          .getApi()
          .applyAutomaticLinkConversion(newEditorState, params.chars);

        return withLinksConverted;
      };

    const postProcessAfterReturn: Decorator<PostProcessAfterReturn> =
      (basePostProcessContentAfterReturn) => (params) => {
        const newEditorState = basePostProcessContentAfterReturn(params);
        const withLinksConverted = state
          .getApi()
          .applyAutomaticLinkConversion(newEditorState, null, params.originalSelection);

        return withLinksConverted;
      };

    const postProcessPastedContent: Decorator<PostProcessPastedContent> =
      (basePostProcessPastedContent) => (editorState) => {
        const newEditorState = basePostProcessPastedContent(editorState);
        const withLinksConverted = state
          .getApi()
          .applyAutomaticLinkConversion(newEditorState, null);

        return withLinksConverted;
      };

    state.canHandleNewCharsNatively.decorate(canHandleNewCharsNatively);
    state.postProcessInsertedChars.decorate(postProcessInsertedChars);
    state.postProcessAfterReturn.decorate(postProcessAfterReturn);
    state.postProcessPastedContent.decorate(postProcessPastedContent);

    return {};
  }, []);

  const { getApiMethods } = useEditorApi<AutomaticWebLinkConversionPlugin>(editorLinkApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
