import { Action } from '../../../../../@types/Action.type.ts';
import {
  Project_CloneProjectValidation_Failed,
  Project_CloneProject_Failed,
  Project_CloneProject_Finished,
  Project_CloneProject_Started,
} from '../../../constants/projectActionTypes.ts';

export const projectIsBeingCloned = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case Project_CloneProject_Started:
      return true;

    case Project_CloneProject_Finished:
    case Project_CloneProject_Failed:
    case Project_CloneProjectValidation_Failed:
      return false;

    default:
      return state;
  }
};
