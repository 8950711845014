import { SpacesFilterStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { isString } from '../_shared/utils/stringUtils.ts';
import { isNumeric } from '../_shared/utils/validation/isNumeric.ts';
import { StorageParams, getProjectSpecificStorage } from './projectSpecificStorage.ts';

export type SpacesFilterStorageData = {
  readonly searchPhrase: string;
  readonly version: number;
};

const initialStorageValue: SpacesFilterStorageData = {
  searchPhrase: '',
  version: 1,
};

const getFilterSearchPhraseFromJS = (rawData: JsonParseOutput): SpacesFilterStorageData => {
  if (!rawData || typeof rawData !== 'object' || !('version' in rawData)) {
    return initialStorageValue;
  }

  const normalizedSearchPhrase = isString(rawData.searchPhrase)
    ? rawData.searchPhrase
    : initialStorageValue.searchPhrase;

  const normalizedVersion = isNumeric(rawData.version)
    ? rawData.version
    : initialStorageValue.version;

  return {
    searchPhrase: normalizedSearchPhrase,
    version: normalizedVersion,
  };
};

const collectionsFilterStorageParams: StorageParams<SpacesFilterStorageData> = {
  key: SpacesFilterStorageKey,
  initialState: initialStorageValue,
  fromJS: getFilterSearchPhraseFromJS,
};

export const SpacesFilterStorage = getProjectSpecificStorage<SpacesFilterStorageData>(
  collectionsFilterStorageParams,
);
