import { Action } from '../../../../@types/Action.type.ts';
import {
  Project_Create_CloseModal,
  Project_Create_ShowModal,
  Project_NewProject_ClearCopyProjectDataInfo,
  Project_NewProject_Failed,
  Project_NewProject_Finished,
  Project_NewProject_Started,
  Project_TrialActivation_Close,
} from '../../constants/projectActionTypes.ts';

export const createProjectErrorMessage = (state: Uuid = '', action: Action): string => {
  switch (action.type) {
    case Project_NewProject_Started:
    case Project_NewProject_Finished:
    case Project_Create_CloseModal:
    case Project_Create_ShowModal:
    case Project_TrialActivation_Close:
    case Project_NewProject_ClearCopyProjectDataInfo:
      return '';

    case Project_NewProject_Failed:
      return action.payload.errorMessage;

    default:
      return state;
  }
};
