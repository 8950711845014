import Immutable from 'immutable';
import {
  ContentItemRevisionCompareRoute,
  ContentItemRevisionCompareRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  parseContentItemIds,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

export const getTimelineItemComparePathsMap = (
  entryTimeline: Immutable.List<TimelineItem> | null,
  match: ContentItemRevisionCompareRouteParams<string>,
  currentPath: string,
  lastSelectedRevisionId: Uuid | null,
): Immutable.Map<Uuid, string> | null => {
  const params: ContentItemRevisionCompareRouteParams<UuidArray> = {
    app: match.app,
    projectId: match.projectId,
    variantId: match.variantId,
    spaceId: match.spaceId,
    contentItemIds: parseContentItemIds(match.contentItemIds),
    timelineItemId: match.timelineItemId,
    compareItemId: match.compareItemId,
  };

  const lastSelectedWasComparison = lastSelectedRevisionId === params.compareItemId;

  const paths = entryTimeline
    ?.map((timelineItem: TimelineItem) => {
      if (
        timelineItem.itemId === params.timelineItemId ||
        timelineItem.itemId === params.compareItemId
      ) {
        // Keep the path for already selected items
        return [timelineItem.itemId, currentPath];
      }

      const timelineItemPath = lastSelectedWasComparison
        ? buildPath<ContentItemRevisionCompareRouteParams<UuidArray>>(
            ContentItemRevisionCompareRoute,
            {
              ...params,
              timelineItemId: timelineItem.itemId,
            },
          )
        : buildPath<ContentItemRevisionCompareRouteParams<UuidArray>>(
            ContentItemRevisionCompareRoute,
            {
              ...params,
              compareItemId: timelineItem.itemId,
            },
          );

      return [timelineItem.itemId, timelineItemPath];
    })
    .toArray();

  return paths ? Immutable.Map<Uuid, string>(paths) : null;
};
