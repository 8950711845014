import { Collection } from '@kontent-ai/utils';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import {
  ApiKeyDetailAccessDeniedRouteSegment,
  ApiKeyDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getCurrentProjectContainer } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { areCustomAppsEnabled as areCustomAppsEnabledSelector } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { createApiKey, initNewApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import {
  AllEnvironmentsTagId,
  getAllEnvironmentOptions,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { getApiKeyRedirectRoute } from '../../utils/getApiKeyRedirectRoute.ts';
import { getMapiKeyCapabilitiesFromPermissions } from '../../utils/getMapiKeyCapabilitiesFromPermissions.ts';
import { MapiKeyFormShape } from '../../validation/mapiKeyValidationConfig.ts';
import { MapiKeyDetailForm } from './MapiKeyDetailForm.tsx';

export const NewMapiKeyDetail = () => {
  const [isInitThunkDone] = useThunkPromise(initNewApiKey, ApiKeyType.MAPI);

  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectContainer = useSelector(getCurrentProjectContainer);
  const allSet = useSelector(
    (state) => isInitThunkDone && state.apiKeysApp.detailLoadingStatus === LoadingStatus.Loaded,
  );
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.MAPI),
  );
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const projectContainerActiveUsersById = useSelector(
    (state) => state.data.projectContainerActiveUsers.byId,
  );
  const areCustomAppsEnabled = useSelector(areCustomAppsEnabledSelector);

  const projectContainerActiveUsers = useMemo(
    () => Collection.getValues(projectContainerActiveUsersById),
    [projectContainerActiveUsersById],
  );

  const getApiKey = useCallback((mapiKeyFormShape: MapiKeyFormShape): ApiKey => {
    const hasAccessToAllEnvironments = mapiKeyFormShape.environments.includes(AllEnvironmentsTagId);
    return {
      ...emptyApiKey,
      name: mapiKeyFormShape.name,
      environments: hasAccessToAllEnvironments ? [] : mapiKeyFormShape.environments,
      expiresAt: mapiKeyFormShape.expiresAt.value,
      hasAccessToAllEnvironments,
      mapiKeyCapabilities: getMapiKeyCapabilitiesFromPermissions(mapiKeyFormShape.permissions),
      sharedWithUsers: mapiKeyFormShape.sharedWithUsers,
      type: ApiKeyType.MAPI,
    };
  }, []);

  const createNewApiKey = useCallback(
    async (mapiKeyFormShape: MapiKeyFormShape): Promise<void> => {
      const apiKey = getApiKey(mapiKeyFormShape);
      const createdApiKey = await dispatch(createApiKey(apiKey));
      history.push(
        buildPath<ApiKeyDetailRouteParams>(getApiKeyRedirectRoute(apiKey.type), {
          projectContainerId: currentProjectContainer.projectContainerId,
          tokenSeedId: createdApiKey.token_seed_id,
        }),
      );
    },
    [history, currentProjectContainer.projectContainerId, getApiKey],
  );

  const unsavedNavigationHandler = useCallback(
    async (
      onSuccess: () => void,
      onFail: () => void,
      mapiKeyFormShape: MapiKeyFormShape,
    ): Promise<void> => {
      try {
        const apiKey = getApiKey(mapiKeyFormShape);
        await dispatch(createApiKey(apiKey));
        onSuccess();
      } catch {
        onFail();
      }
    },
    [getApiKey],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={isProjectManager}
      projectId={currentProjectContainer.masterEnvironmentId}
      projectContainerId={currentProjectContainer.projectContainerId}
    >
      <MapiKeyDetailForm
        allEnvironmentOptions={allEnvironmentOptions}
        apiKey={getNewApiKey()}
        apiKeyActionStatus={actionStatus}
        areCustomAppsEnabled={areCustomAppsEnabled}
        isProjectManager={isProjectManager}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onSave={createNewApiKey}
        onUnsavedNavigation={unsavedNavigationHandler}
        projectContainerActiveUsers={projectContainerActiveUsers}
      />
    </AuthorizedSection>
  );
};

const getNewApiKey = (): ApiKey => ({
  ...emptyApiKey,
  type: ApiKeyType.MAPI,
});
