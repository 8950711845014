import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const linkStyle = css`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:focus-visible {
		 outline: none;
	}
`;

export const Anchor = styled.a`${linkStyle};`;

export type RouterLinkProps = Omit<React.ComponentPropsWithRef<Link>, 'component'>;

export const RouterLink = styled(Link)`${linkStyle};` as React.FC<RouterLinkProps>;

export type RouterNavLinkProps = Omit<React.ComponentPropsWithRef<NavLink>, 'component'>;

export const RouterNavLink = styled(NavLink)`${linkStyle};` as React.FC<RouterNavLinkProps>;
