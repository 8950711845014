import Immutable from 'immutable';
import { debounce } from '../_shared/utils/func/debounce.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

export interface IProjectSpecificStorage<T> {
  readonly save: (config: T, projectId: Uuid) => void;
  readonly update: (item: Partial<T>, projectId: Uuid) => void;
  readonly forget: (projectId: Uuid) => void;
  readonly load: (projectId: Uuid) => T;
}

export interface StorageParams<TStorageModel> {
  readonly initialState: TStorageModel;
  readonly key: string;
  readonly fromJS: (value: JsonParseOutput) => TStorageModel;
  readonly toJS?: (value: TStorageModel) => JsonParseOutput;
  readonly shouldReplaceStoredValueOnLoad?: (value: TStorageModel) => boolean;
}

export const getProjectSpecificStorage: <T>(
  params: StorageParams<T>,
) => IProjectSpecificStorage<T> = <T>(params: StorageParams<T>) => {
  let _loadedStates = Immutable.Map<Uuid, T>();

  function getStorageKey(projectId: Uuid): string {
    return `${params.key}-${projectId}`;
  }

  const saveToLocalStorage = debounce((item: T, projectId: Uuid) => {
    localStorage.set(getStorageKey(projectId), JSON.stringify(params.toJS?.(item) ?? item));
  }, 100);

  function save(item: T, projectId: Uuid): void {
    _loadedStates = _loadedStates.set(projectId, item);
    saveToLocalStorage(item, projectId);
  }

  function update(item: Partial<T>, projectId: Uuid): void {
    const originalItem = load(projectId);
    const updatedItem: T = {
      ...originalItem,
      ...item,
    };

    save(updatedItem, projectId);
  }

  function forget(projectId: Uuid): void {
    localStorage.remove(getStorageKey(projectId));
    _loadedStates = _loadedStates.remove(projectId);
  }

  function parseItem(itemString: string): T | null {
    try {
      return params.fromJS(JSON.parse(itemString));
    } catch {
      return null;
    }
  }

  function load(projectId: Uuid): T {
    const loadedState = _loadedStates.get(projectId);

    if (!loadedState) {
      const stringValue = localStorage.get(getStorageKey(projectId));
      if (stringValue) {
        const state = parseItem(stringValue);
        if (state) {
          _loadedStates = _loadedStates.set(projectId, state);

          if (params.shouldReplaceStoredValueOnLoad?.(state)) {
            saveToLocalStorage(state, projectId);
          }

          return state;
        }
      }

      const initialState = params.initialState;
      _loadedStates = _loadedStates.set(projectId, initialState);
      save(initialState, projectId);
      return initialState;
    }

    return loadedState;
  }

  return {
    save,
    update,
    forget,
    load,
  };
};
