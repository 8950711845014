import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../actions/sharedActions.ts';
import { PublishErrorMessage } from '../../constants/uiConstants.ts';
import { OpenWorkflowStepModalAction } from '../../types/workflowStepModalActionTypes.ts';
import { IOpenBulkChangeWorkflowStepModal } from './openBulkChangeWorkflowStepModal.ts';

interface IOpenBulkPublishModalActionDeps {
  readonly checkPublishWarnings: () => ThunkPromise;
  readonly openBulkChangeWorkflowStepModal: IOpenBulkChangeWorkflowStepModal;
}

export const createOpenBulkPublishModalAction =
  ({ checkPublishWarnings, openBulkChangeWorkflowStepModal }: IOpenBulkPublishModalActionDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch) => {
    try {
      await dispatch(checkPublishWarnings());
      dispatch(openBulkChangeWorkflowStepModal(OpenWorkflowStepModalAction.Publish));
    } catch {
      dispatch(showErrorNotification(PublishErrorMessage));
    }
  };
