import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_Init_Started,
  ContentItemEditing_SitemapLocationCollapsedState_Toggled,
} from '../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: Immutable.Set<Uuid> = Immutable.Set();

export function collapsedSitemapNodes(
  state: Immutable.Set<Uuid> = initialState,
  action: Action,
): Immutable.Set<Uuid> {
  switch (action.type) {
    case ContentItemEditing_Init_Started: {
      return initialState;
    }

    case ContentItemEditing_SitemapLocationCollapsedState_Toggled: {
      const { toggledNodeId } = action.payload;
      return state.contains(toggledNodeId) ? state.remove(toggledNodeId) : state.add(toggledNodeId);
    }

    default: {
      return state;
    }
  }
}
