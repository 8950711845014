import { combineReducers } from '@reduxjs/toolkit';
import { collectionIds } from './internalReducers/collectionIds.ts';
import { draggedCollectionId } from './internalReducers/draggedCollectionId.ts';
import { editedCollectionId } from './internalReducers/editedCollectionId.ts';
import { editedCollectionName } from './internalReducers/editedCollectionName.ts';
import { editedCollections } from './internalReducers/editedCollections.ts';
import { editedCollectionsETag } from './internalReducers/editedCollectionsETag.ts';
import { filterSearchPhrase } from './internalReducers/filterSearchPhrase.ts';
import { isBeingSaved } from './internalReducers/isBeingSaved.ts';
import { isCreatorOpen } from './internalReducers/isCreatorOpen.ts';
import { isInitialized } from './internalReducers/isInitialized.ts';
import { recentlyCreatedCollectionIds } from './internalReducers/recentlyCreatedCollectionIds.ts';

export const collectionsAppReducer = combineReducers({
  collectionIds,
  draggedCollectionId,
  editedCollectionId,
  editedCollectionName,
  editedCollections,
  editedCollectionsETag,
  isBeingSaved,
  isCreatorOpen,
  isInitialized,
  filterSearchPhrase,
  recentlyCreatedCollectionIds,
});
