import { EditorApiImplementation } from '../../../editorCore/types/Editor.api.type.ts';
import { TextBlockTypeSequence, isTableCellContent } from '../../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../../../utils/blocks/editorBlockGetters.ts';
import {
  getBaseBlockTypes,
  getFullBlockTypesAtSelection,
} from '../../../utils/editorSelectionUtils.ts';
import { adjustBlocksDepth, changeBlocksType } from '../../../utils/general/editorContentUtils.ts';
import {
  TopLevelBlockCategoryFeature,
  getAllDisallowedFeatures,
  isTextFeatureAllowed,
} from '../../apiLimitations/api/editorLimitationUtils.ts';
import {
  BlockTypeCommandFeatureMap,
  EditorCommandStatus,
  TextBlockFeatureBlockTypeMap,
  canCommandExecute,
  getAllowedFallbackForTextBlock,
  getFeatureForBlockType,
  getNextAllowedBlockTypeForSelection,
  isBlockAllowed,
} from '../../keyboardShortcuts/api/editorCommandUtils.ts';
import { TextBlockTypesPlugin } from '../TextBlockTypesPlugin.tsx';

import { Collection } from '@kontent-ai/utils';
import { applyAutomaticMarkdownBlockConversion as applyAutomaticMarkdownBlockConversionInContent } from './editorBlockMarkdownUtils.ts';

export const editorTextBlockTypeApi: EditorApiImplementation<TextBlockTypesPlugin> = {
  adjustBlocksDepth(api, editorState, selection, offset) {
    return api.executeContentChange(
      editorState,
      selection,
      (input) => adjustBlocksDepth(input, offset),
      'change-block-data',
    );
  },

  applyAutomaticMarkdownBlockConversion(api, editorState, onMarkdownConversion) {
    const withMarkDownApplied = api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) =>
        applyAutomaticMarkdownBlockConversionInContent(
          input,
          api.getLimitations(),
          onMarkdownConversion,
        ),
      'change-block-type',
    );

    return withMarkDownApplied;
  },

  changeBlocksType(api, editorState, blockType, predicate) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) => changeBlocksType(input, () => blockType, predicate),
      'change-block-type',
    );
  },

  changeBlocksTypeWithAllowedFallback(api, editorState, blockType, predicate) {
    return api.executeContentChange(
      editorState,
      editorState.getSelection(),
      (input) =>
        changeBlocksType(
          input,
          (block) =>
            getAllowedFallbackForTextBlock(
              blockType,
              api.getLimitations(),
              isTableCellContent(block),
            ),
          predicate,
        ),
      'change-block-type',
    );
  },

  ensureInitialAllowedBlockType(api, editorState) {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const blockKey = selection.getStartKey();
    const block = content.getBlockForKey(blockKey);

    const fullBlockTypesAtSelection = getFullBlockTypesAtSelection(content, selection);
    const baseBlockTypesAtSelection = getBaseBlockTypes(fullBlockTypesAtSelection);

    const blockType = Collection.getFirst(Collection.getValues(baseBlockTypesAtSelection));
    const textBlockFeature = blockType ? getFeatureForBlockType(blockType) : null;

    const isBlockTypeAllowed =
      !textBlockFeature ||
      isTextFeatureAllowed(textBlockFeature, fullBlockTypesAtSelection, api.getLimitations());

    if (!isBlockTypeAllowed && !block.getLength()) {
      const nextBlockType = getNextAllowedBlockTypeForSelection(
        TextBlockTypeSequence,
        content,
        selection,
        api.getLimitations(),
      );

      if (nextBlockType) {
        return api.changeBlocksType(editorState, nextBlockType);
      }
    }

    return editorState;
  },

  executeBlockTypeCommand(api, editorState, command) {
    const status = api.getCommandStatus(editorState, command);
    if (!canCommandExecute(status)) {
      return editorState;
    }

    const feature = BlockTypeCommandFeatureMap[command];
    if (!feature) {
      return editorState;
    }

    const blockType = TextBlockFeatureBlockTypeMap[feature];
    if (!blockType) {
      return editorState;
    }

    const { allowedBlocks } = api.getLimitations();
    const isTextAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
    const isTableAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);

    switch (status) {
      case EditorCommandStatus.ActiveAllowed: {
        return api.changeBlocksTypeWithAllowedFallback(
          editorState,
          blockType,
          (block) =>
            getBaseBlockType(block) === blockType &&
            (isTableCellContent(block) ? isTableAllowed : isTextAllowed),
        );
      }

      case EditorCommandStatus.ActiveNotAllowed: {
        return api.changeBlocksTypeWithAllowedFallback(
          editorState,
          blockType,
          (block) =>
            getBaseBlockType(block) === blockType && !isBlockAllowed(block, api.getLimitations()),
        );
      }

      case EditorCommandStatus.InactiveAllowed: {
        const { disallowedTopLevelFeatures, disallowedTableFeatures } = getAllDisallowedFeatures(
          api.getLimitations(),
        );
        const isAllowedInTopLevel = !disallowedTopLevelFeatures.has(feature);
        const isAllowedInTable = !disallowedTableFeatures.has(feature);
        return api.changeBlocksType(editorState, blockType, (block) =>
          isTableCellContent(block)
            ? isAllowedInTable && isTableAllowed
            : isAllowedInTopLevel && isTextAllowed,
        );
      }

      default:
        return editorState;
    }
  },
};
