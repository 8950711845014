import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  IconSize,
  Spacing,
  Typography,
  colorAlertIcon,
  colorAlertText,
} from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
}

export const EntityWebhookValidationErrorWithLink = ({ children }: Props) => {
  return (
    <Row spacing={Spacing.XS} noWrap>
      <Column width="content">
        <Icons.ExclamationTriangleInverted color={colorAlertIcon} size={IconSize.S} />
      </Column>
      <Column>
        <Box color={colorAlertText} overflowWrap="anywhere" typography={Typography.TitleMedium}>
          {children}
        </Box>
      </Column>
    </Row>
  );
};
