import { ISelectItem, ISelectSection } from '../types.ts';

/**
 * Returns the first item in the provided array that satisfies the provided predicate using pre-order DFS. If no values satisfy the predicate, undefined is returned.
 * @param options
 * @param predicate
 */
export const findItem = <TItem extends ISelectItem<TItem>>(
  options: ReadonlyArray<ISelectSection<TItem> | TItem>,
  predicate: (option: ISelectSection<TItem> | TItem) => boolean,
): ISelectSection<TItem> | TItem | undefined => {
  for (const option of options) {
    if (predicate(option)) {
      return option;
    }

    if (option.items?.length) {
      const result = findItem(option.items, predicate);

      if (result) {
        return result;
      }
    }
  }

  return undefined;
};
