import classNames from 'classnames';
import { RefObject, useRef } from 'react';
import { ReactPopperOptions, usePopper } from 'react-popper';
import useResizeObserver from 'use-resize-observer';
import { defaultPopperModifiers } from '../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { ScrollContainerContextProvider } from '../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { ScrollOptionsContextProvider } from '../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import { useUpdatePaperContext } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import { useFixAutoCompleteOnScroll } from '../../../../../_shared/hooks/useFixAutoCompleteOnScroll.ts';
import {
  isEntryFullyVisibleCallback,
  useIntersectionObserver,
} from '../../../../../_shared/hooks/useIntersectionObserver.ts';
import { usePopperRef } from '../../../../../_shared/hooks/usePopperRef.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FloatingEditorScrollOptions } from '../../../../itemEditor/features/ContentItemEditing/constants/uiConstants.ts';
import { ContentGroupTabs } from '../../../../itemEditor/features/ContentItemEditing/containers/ContentGroupTabs.tsx';
import { ContentItemElements } from '../../../../itemEditor/features/ContentItemEditing/containers/ContentItemElements.tsx';
import { ElementLocksObserver } from '../../../../itemEditor/features/ContentItemEditing/containers/ElementLocksObserver.tsx';
import { InlineCommentPane } from '../../../../itemEditor/features/ContentItemEditing/containers/comments/InlineCommentPane.tsx';
import { ItemNameElement } from '../../../../itemEditor/features/ContentItemEditing/containers/elements/ItemNameElement.tsx';
import { CreateContentGroupTabsId } from '../../../../itemEditor/features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { getAreAnyContentGroupsVisible } from '../../../../itemEditor/selectors/getAreAnyContentGroupsVisible.ts';
import { getCanEditGroupContent } from '../../../../itemEditor/selectors/getCanEditGroupContent.ts';
import { getEditedContentItemType } from '../../../../itemEditor/selectors/getEditedContentItemType.ts';
import { FloatingEditorPosition } from '../../../types/floatingEditorPosition.ts';
import { FloatingEditorHeader } from './Header/FloatingEditorHeader.tsx';

type Props = {
  readonly onTogglePosition: () => void;
  readonly position: FloatingEditorPosition;
  readonly wrapperRef: RefObject<HTMLDivElement>;
};

export const FloatingEditor = ({ onTogglePosition, position, wrapperRef }: Props) => {
  const editorScrollContainerRef = useRef<HTMLDivElement>(null);
  useFixAutoCompleteOnScroll(editorScrollContainerRef);

  const threadListRef = useRef<HTMLDivElement>(null);

  const [setEditorContentRef, editorContentRef] = usePopperRef<HTMLDivElement>();
  const [setCommentsContainerRef, commentsContainerRef] = usePopperRef<HTMLDivElement>();

  const { width: editorWidth } = useResizeObserver({ ref: wrapperRef });
  const { width: commentsContainerWidth } = useResizeObserver({ ref: commentsContainerRef });
  const { height: threadListHeight } = useResizeObserver({ ref: threadListRef });

  // Do not render item content until paper context is updated because anything using it would rerender after it initializes
  // this mainly causes performance problems in case of many linked items
  const { isEditorPaperSet: canRenderPaperContent } =
    useUpdatePaperContext(editorScrollContainerRef);

  const popperOptions: ReactPopperOptions = {
    placement: 'left-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -((editorWidth ?? 0) + (commentsContainerWidth ?? 0))],
        },
        enabled: position === FloatingEditorPosition.Left,
      },
      ...defaultPopperModifiers,
    ],
  };

  const { styles } = usePopper(
    editorContentRef.current,
    commentsContainerRef.current,
    popperOptions,
  );

  const contentType = useSelector(getEditedContentItemType);
  const canEditGroupContent = useSelector(getCanEditGroupContent);
  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);

  const isContainerFullyVisible = useIntersectionObserver({
    targetRef: editorContentRef,
    isIntersectingCallback: isEntryFullyVisibleCallback,
    options: {
      delay: 100,
      root: editorScrollContainerRef.current,
      threshold: 1,
      trackVisibility: true,
    },
  });

  const editedContentItem = useSelector((state) => state.contentApp.editedContentItem);
  const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(editedContentItem?.id ?? '');

  return (
    <IntersectionObserverRootContextProvider rootRef={editorScrollContainerRef}>
      <div
        className="floating-editor__pane content-item-editor"
        {...getDataUiElementAttribute(DataUiElement.ContentItemEditor)}
      >
        <FloatingEditorHeader onTogglePosition={onTogglePosition} position={position} />
        <ScrollContainerContextProvider
          scrollContainerRef={editorScrollContainerRef}
          tippyBoundaryRef={editorScrollContainerRef}
        >
          <div className="floating-editor__content-pane" ref={editorScrollContainerRef}>
            {canRenderPaperContent && (
              <ScrollOptionsContextProvider scrollOptions={FloatingEditorScrollOptions}>
                <div
                  className={classNames('floating-editor__content', {
                    'floating-editor__content--is-scrollable': !isContainerFullyVisible,
                  })}
                  ref={setEditorContentRef}
                  // We need to make sure that the editor content is at least as high as the comments list
                  // to make sure it can be scrolled till the end of the last comment
                  style={{ minHeight: threadListHeight }}
                >
                  <ItemNameElement />
                  {areAnyContentGroupsVisible && contentType && (
                    <ContentGroupTabs
                      canEditContent={canEditGroupContent}
                      contentGroupTabsId={contentGroupTabsId}
                      contentType={contentType}
                    />
                  )}
                  <ContentItemElements />
                </div>
              </ScrollOptionsContextProvider>
            )}
          </div>
        </ScrollContainerContextProvider>
        <ElementLocksObserver />
      </div>
      <div
        className="floating-editor__comments-pane"
        style={styles.popper}
        ref={setCommentsContainerRef}
      >
        <InlineCommentPane
          showsOnlyActiveComments
          threadListRef={threadListRef}
          scrollContainerRef={editorScrollContainerRef}
        />
      </div>
    </IntersectionObserverRootContextProvider>
  );
};
