import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AssignedSpacesMessage } from '../../../../../_shared/components/Spaces/AssignedSpacesMessage.tsx';
import {
  SpaceNamesMetadata,
  defaultAllSpacesPlaceholder,
} from '../../../spaces/utils/spacesUtils.ts';

const StyledSpacesLabel = styled.span`
  color: ${colorTextDefault};
  ${Typography.BodyMediumEmphasis};
`;

type Props = {
  readonly spaceNames: SpaceNamesMetadata;
  readonly spacesPath: string;
};

const renderSpaceNamesLabel: ComponentProps<typeof AssignedSpacesMessage>['renderSpaceName'] = ({
  name,
}) => <StyledSpacesLabel>{name}</StyledSpacesLabel>;

export const CollectionExpandedFormSpaces: React.FC<Props> = ({ spaceNames, spacesPath }) => {
  return (
    <Box paddingTop={Spacing.XL}>
      <Stack spacing={Spacing.L}>
        <Box typography={Typography.HeadlineMedium}>Spaces tied to this collection</Box>
        <Box typography={Typography.BodyMedium}>
          <AssignedSpacesMessage
            spaceData={spaceNames}
            renderSpaceName={renderSpaceNamesLabel}
            allSpacesPlaceholderText={defaultAllSpacesPlaceholder.toLowerCase()}
          />
          &nbsp;You can assign collections to spaces in{' '}
          <Link to={spacesPath}>spaces’ settings</Link>.
        </Box>
      </Stack>
    </Box>
  );
};

CollectionExpandedFormSpaces.displayName = 'CollectionExpandedFormSpaces';
