import { createGuid } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { PluginApi } from '../../../../../richText/editorCore/types/Editor.api.type.ts';
import { CommentsPlugin } from '../../../../../richText/plugins/comments/CommentsPlugin.tsx';
import { getCommentSegmentIdFromBlock } from '../../../../../richText/plugins/comments/api/editorCommentUtils.ts';
import { getImageAssetReference } from '../../../../../richText/plugins/images/api/editorImageUtils.ts';
import { getModularContentItemId } from '../../../../../richText/plugins/linkedItems/api/editorModularUtils.ts';
import { BlockType } from '../../../../../richText/utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../../../../richText/utils/blocks/editorBlockGetters.ts';
import { getCustomBlockForSleeve } from '../../../../../richText/utils/blocks/editorBlockUtils.ts';
import { getSelectedText } from '../../../../../richText/utils/editorSelectionUtils.ts';
import { CommentThreadItemType } from '../../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  IInlineCommentThread,
} from '../../../../models/comments/CommentThreads.ts';
import { isThreadResolved } from '../../../../utils/commentUtils.ts';
import { ElementReference } from '../../containers/hooks/useItemElementReference.ts';
import { getElementCommentThreads } from '../../selectors/inlineCommentSelectors.ts';
import { IStartNewInlineCommentThread } from './startNewInlineCommentThread.ts';

type IAddCommentToRichTextElementAction = (
  elementId: ElementReference,
  editorState: EditorState,
  type: CommentThreadItemType,
  api: PluginApi<CommentsPlugin>,
) => ThunkFunction<EditorState>;

interface IDeps {
  readonly startNewInlineCommentThread: IStartNewInlineCommentThread;
}

export const createAddCommentToRichTextElementAction =
  (deps: IDeps): IAddCommentToRichTextElementAction =>
  (element, editorState, type, api) =>
  (dispatch, getState) => {
    const {
      data: {
        assets: { byId: assets },
        listingContentItems: { byId: listingContentItems },
      },
      contentApp: {
        editedContentItemVariantComments: { commentThreads },
      },
    } = getState();

    const { elementId, contentComponentId } = element;

    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
      // We don't allow creating suggestions on custom block comments as we can't provide replacement support. Maybe sometime in future
      if (type !== CommentThreadItemType.Comment) {
        return editorState;
      }

      const customBlock = getCustomBlockForSleeve(
        editorState.getCurrentContent(),
        selection.getStartKey(),
      );
      if (customBlock) {
        // Do not create new comment if there is an existing unresolved comment, we could lose it otherwise
        const existingSegmentId = getCommentSegmentIdFromBlock(customBlock);
        const elementThreads = getElementCommentThreads(
          commentThreads,
          elementId,
          contentComponentId,
        );
        const hasUnresolvedThread = elementThreads.some(
          (thread: IInlineCommentThread) =>
            !isThreadResolved(thread) && thread.externalSegmentId === existingSegmentId,
        );
        if (hasUnresolvedThread) {
          return editorState;
        }

        // When there is an existing segment ID, use that one to prevent disconnecting existing resolved comments from the custom block
        const segmentId = existingSegmentId ?? createGuid();
        const newEditorState = api.setBlockCommentSegmentId(
          editorState,
          customBlock.getKey(),
          segmentId,
        );
        if (newEditorState !== editorState) {
          const blockType = getBaseBlockType(customBlock);
          switch (blockType) {
            case BlockType.ContentModule: {
              const linkedItemId = getModularContentItemId(customBlock);
              if (linkedItemId) {
                dispatch(
                  deps.startNewInlineCommentThread({
                    externalSegmentId: segmentId,
                    elementId,
                    itemType: type,
                    threadType: CommentThreadType.RichTextLinkedItem,
                    elementSegment: listingContentItems.get(linkedItemId)?.item.name ?? null,
                    contentComponentId,
                  }),
                );
              }
              break;
            }

            case BlockType.Image: {
              const assetId = getImageAssetReference(customBlock)?.id;
              if (assetId) {
                dispatch(
                  deps.startNewInlineCommentThread({
                    externalSegmentId: segmentId,
                    elementId,
                    itemType: type,
                    threadType: CommentThreadType.InlineImage,
                    elementSegment: assets.get(assetId)?.filename ?? null,
                    contentComponentId,
                  }),
                );
              }
              break;
            }

            default:
              throw new Error(`Creating comment for block type ${blockType} is not supported`);
          }

          return newEditorState;
        }
      }
    } else {
      // Each text comment has a unique ID as we can bind multiple IDs to each character's metadata.
      const segmentId = createGuid();
      const newEditorState = api.addCommentToText(editorState, selection, segmentId);
      if (newEditorState !== editorState) {
        const selectedText = getSelectedText(editorState.getCurrentContent(), selection);

        dispatch(
          deps.startNewInlineCommentThread({
            externalSegmentId: segmentId,
            elementId,
            itemType: type,
            threadType: CommentThreadType.RichText,
            elementSegment: selectedText,
            contentComponentId,
          }),
        );

        return newEditorState;
      }
    }

    return editorState;
  };
