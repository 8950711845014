import { Action } from '../../../../../@types/Action.type.ts';
import {
  Collections_Editor_CollectionDraggingFinished,
  Collections_Editor_CollectionDraggingStarted,
  Collections_Listing_Initialized,
} from '../../constants/actionTypes.ts';

const initialState = null;

export const draggedCollectionId = (state = initialState, action: Action): Uuid | null => {
  switch (action.type) {
    case Collections_Editor_CollectionDraggingStarted:
      return action.payload.collectionId;

    case Collections_Editor_CollectionDraggingFinished:
    case Collections_Listing_Initialized:
      return initialState;

    default:
      return state;
  }
};
