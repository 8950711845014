import { History } from 'history';
import {
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';

interface IRedirectToPlanSelection {
  readonly history: History;
  readonly subscriptionId: Uuid;
}

export const buildPlanSelectionPath = (subscriptionId: Uuid): string => {
  return buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, { subscriptionId });
};

export const redirectToPlanSelection = ({
  history,
  subscriptionId,
}: IRedirectToPlanSelection): void => {
  const path = buildPlanSelectionPath(subscriptionId);
  history.push(path);
};
