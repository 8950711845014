export enum ProjectSettingsAppNames {
  General = 'General',
  Environments = 'Environments',
  ApiKeyListing = 'API keys',
  DeliveryKeys = 'Delivery keys',
  ManagementKeys = 'Management keys',
}

export const isProjectSettingAppName = (name: string): name is ProjectSettingsAppNames =>
  Object.values<string>(ProjectSettingsAppNames).includes(name);
