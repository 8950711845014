import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadProjects } from '../../../../data/actions/thunkDataActions.ts';
import { loadSubscriptionUsage } from '../../shared/actions/thunkSubscriptionManagementActions.ts';
import { createInitSubscriptionUsers } from './thunk/initSubscriptionUsers.ts';
import { loadEnvironmentCollectionsCreator } from './thunk/loadEnvironmentCollections.ts';
import { loadEnvironmentLanguagesCreator } from './thunk/loadEnvironmentLanguages.ts';
import { loadEnvironmentRolesCreator } from './thunk/loadEnvironmentRoles.ts';
import { createLoadSubscriptionUserListingConfigAction } from './thunk/loadSubscriptionUserListingConfig.ts';
import { createLoadSubscriptionUserListingFilterDepsAction } from './thunk/loadSubscriptionUserListingFilterDeps.ts';
import { createLoadSubscriptionUsersUsageAction } from './thunk/loadSubscriptionUsersUsage.ts';
import { createResendInviteToProjects } from './thunk/resendInviteToProjects.ts';
import { createRevokeInviteToProjects } from './thunk/revokeInviteToProjects.ts';
import { createToggleUserActiveStateOnProjects } from './thunk/toggleUserActiveStateOnProjects.ts';
import { createTriggerUserActiveStateOnAllSubscriptionProjectsAction } from './thunk/triggerUserActiveStateOnAllSubscriptionProjects.ts';

const {
  collectionsRepository,
  inviteRepository,
  languageRepository,
  peopleRepository,
  roleRepository,
  subscriptionRepository,
} = repositoryCollection;

export const loadEnvironmentRoles = loadEnvironmentRolesCreator({
  roleRepository,
});

export const loadEnvironmentCollections = loadEnvironmentCollectionsCreator({
  collectionsRepository,
});

export const loadEnvironmentLanguages = loadEnvironmentLanguagesCreator({
  languageRepository,
});

export const loadSubscriptionUserListingFilterDeps =
  createLoadSubscriptionUserListingFilterDepsAction({
    loadEnvironmentCollections,
    loadEnvironmentLanguages,
    loadEnvironmentRoles,
  });

export const loadSubscriptionUserListingConfig = createLoadSubscriptionUserListingConfigAction({
  loadSubscriptionUserListingFilterDeps,
});

export const resendInviteToProjects = createResendInviteToProjects({ inviteRepository });

export const loadSubscriptionUsersUsage = createLoadSubscriptionUsersUsageAction({
  subscriptionRepository,
});

export const revokeInviteToProjects = createRevokeInviteToProjects({
  loadSubscriptionUsage,
  loadSubscriptionUsersUsage,
  peopleRepository,
});

export const toggleUserActiveStateOnProjects = createToggleUserActiveStateOnProjects({
  loadSubscriptionUsage,
  loadSubscriptionUsersUsage,
  peopleRepository,
});

export const initSubscriptionUsers = createInitSubscriptionUsers({
  loadSubscriptionUsersUsage,
  loadProjects,
});

export const triggerUserActiveStateOnAllSubscription =
  createTriggerUserActiveStateOnAllSubscriptionProjectsAction({
    loadSubscriptionUsage,
    loadSubscriptionUsersUsage,
    peopleRepository,
  });
