import classNames from 'classnames';
import { useCallback } from 'react';
import { UrlSlugAutogenerateButton } from '../../../../itemEditor/features/ContentItemEditing/components/elements/urlSlug/UrlSlugAutogenerateButton.tsx';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply, EditorPlugin, Render } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { WrapperPlugin } from '../../../plugins/visuals/WrapperPlugin.tsx';

type AutoGeneratedUrlSlugPluginProps = {
  readonly customMode: boolean;
  readonly onRegenerate?: () => void;
  readonly regenerateDisabled?: boolean;
};

export type AutoGeneratedUrlSlugPlugin = EditorPlugin<
  None,
  AutoGeneratedUrlSlugPluginProps,
  None,
  None,
  [WrapperPlugin]
>;

// Plugin that wraps the editor in another div whose properties can be customized
export const AutoGeneratedUrlSlugPlugin: PluginComponent<AutoGeneratedUrlSlugPlugin> = (props) => {
  const { customMode, onRegenerate, regenerateDisabled, disabled } = props;

  const render: Decorator<Render<AutoGeneratedUrlSlugPlugin>> = useCallback(
    (baseRender) => (state) => (
      <div
        className={classNames('text-field text-field--is-growing', {
          'text-field--is-disabled': disabled,
          'text-field--is-disabled-in-content-editor': disabled,
          'text-field--has-button': customMode && !regenerateDisabled,
        })}
      >
        <div className="text-field__input text-field__input--is-multiline u-transparent-border u-no-left-padding">
          {baseRender(state)}
        </div>
        {customMode && !disabled && !regenerateDisabled && onRegenerate && (
          <div className="text-field__button-pane">
            <UrlSlugAutogenerateButton onClick={onRegenerate} />
          </div>
        )}
      </div>
    ),
    [customMode, disabled, regenerateDisabled, onRegenerate],
  );

  const apply: Apply<AutoGeneratedUrlSlugPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      return {};
    },
    [render],
  );

  return useEditorWithPlugin(props, { apply });
};
