import { Action } from '../../../../@types/Action.type.ts';
import { ContentType_LoadComponentUsage_LoadUsageCount } from '../../../../applications/contentModels/contentTypes/constants/contentTypesActionTypes.ts';

const initialState = 0;

export const visibleItemsToUserCount = (state: number = initialState, action: Action): number => {
  switch (action.type) {
    case ContentType_LoadComponentUsage_LoadUsageCount:
      return action.payload.visibleItemsToUserCount;

    default:
      return state;
  }
};
