import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import {
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { isAllowedTypeForCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IRoleSettings } from '../../../../../data/models/roles/IRoleSettings.ts';
import { IContentItemVariantReference } from '../../../models/contentItem/ContentItemVariantReference.ts';
import { ContentItemSavingOrigin } from '../models/ContentItemSavingOrigin.ts';

const isExistingVariant = (
  defaultVariant: IContentItemVariantReference | null | undefined,
): boolean => {
  return !!defaultVariant && !defaultVariant.isArchived;
};

export const isContentItemNameAndCodenameEditingAllowed = memoize.maxOne(
  (
    contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
    defaultLanguageId: Uuid,
    selectedLanguageId: Uuid,
  ): boolean => {
    const defaultLanguageVariant = contentItemVariants.get(defaultLanguageId);
    const isSelectedLanguageDefault = selectedLanguageId === defaultLanguageId;

    return isSelectedLanguageDefault || !isExistingVariant(defaultLanguageVariant);
  },
);

export enum ItemNameGuidelineStatus {
  Hidden = 0,
  NoRoleForDefaultLanguage = 1,
  NoUpdatePermissionForDefaultLanguage = 2,
  UpdatingNameWillAffectOtherVariants = 3,
  RedirectToDefaultLanguage = 4,
}

const getGuidelinesStatusForReadonlyItemName = (
  hasRoleInDefaultLanguage: boolean,
  hasUpdateCapabilityInDefaultLanguage: boolean,
): ItemNameGuidelineStatus => {
  if (!hasRoleInDefaultLanguage) {
    return ItemNameGuidelineStatus.NoRoleForDefaultLanguage;
  }

  if (hasUpdateCapabilityInDefaultLanguage) {
    return ItemNameGuidelineStatus.RedirectToDefaultLanguage;
  }

  return ItemNameGuidelineStatus.NoUpdatePermissionForDefaultLanguage;
};

const getGuidelinesStatusEditableItemName = (
  hasUpdateCapabilityInSelectedLanguage: boolean,
  isSingleVariant: boolean,
): ItemNameGuidelineStatus => {
  if (!hasUpdateCapabilityInSelectedLanguage || isSingleVariant) {
    return ItemNameGuidelineStatus.Hidden;
  }

  return ItemNameGuidelineStatus.UpdatingNameWillAffectOtherVariants;
};

export const getItemNameGuidelinesStatus = memoize.maxOne(
  (
    contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
    typeId: Uuid,
    selectedLanguage: Uuid,
    selectedLanguageRoleSettings: IRoleSettings,
    defaultLanguage: Uuid,
    defaultLanguageRoleSettings: IRoleSettings | null | undefined,
  ): ItemNameGuidelineStatus => {
    const isItemNameReadonly = !isContentItemNameAndCodenameEditingAllowed(
      contentItemVariants,
      defaultLanguage,
      selectedLanguage,
    );

    if (isItemNameReadonly) {
      const hasRoleInDefaultLanguage = !!defaultLanguageRoleSettings;
      const hasUpdateCapabilityInDefaultLanguage = isAllowedTypeForCapability(
        defaultLanguageRoleSettings,
        Capability.UpdateContent,
      )(typeId);
      return getGuidelinesStatusForReadonlyItemName(
        hasRoleInDefaultLanguage,
        hasUpdateCapabilityInDefaultLanguage,
      );
    }
    const isSingleVariant = contentItemVariants.filter(isExistingVariant).size === 1;
    const hasUpdateCapabilityInSelectedLanguage = isAllowedTypeForCapability(
      selectedLanguageRoleSettings,
      Capability.UpdateContent,
    )(typeId);
    return getGuidelinesStatusEditableItemName(
      hasUpdateCapabilityInSelectedLanguage,
      isSingleVariant,
    );
  },
);

export const getContentItemSavingOriginFromRoute = memoize.maxOne(
  (currentPath: string): ContentItemSavingOrigin => {
    const floatingEditorMatch = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
      currentPath,
      ContentItemPreviewWithEditorRoute,
    );

    if (floatingEditorMatch?.editedItemId) {
      return ContentItemSavingOrigin.FloatingEditor;
    }

    const contentItemMatch = matchPath<ContentItemRouteParams<string>>(
      currentPath,
      ContentItemRoute,
    );

    if (contentItemMatch) {
      switch (contentItemMatch.app) {
        case 'content-inventory':
          return ContentItemSavingOrigin.ContentItemEditor;
        case 'web-spotlight':
          return ContentItemSavingOrigin.WebSpotlightEditor;
        default:
          throw InvariantException(`Unknown element value change origin: ${currentPath}`);
      }
    }

    throw InvariantException(`Unknown element value change origin: ${currentPath}`);
  },
);

export const areIdsEquivalent = (first: ContentItemId, second: ContentItemId): boolean =>
  first.itemId === second.itemId && first.variantId === second.variantId;
