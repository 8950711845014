import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DueDateSection as DueDateSectionComponent } from '../../../components/details/DueDate/DueDateSection.tsx';
import { SectionType } from '../../../components/sidebar/sectionTypes/SectionType.tsx';
import { getDueDateState } from '../../selectors/getDueDateState.ts';

export const DueDateSection = () => {
  const { canEdit, initialDueDate, isHidden } = useSelector(getDueDateState);

  const sectionType = canEdit && initialDueDate ? SectionType.Clickable : SectionType.NonClickable;

  return isHidden ? null : <DueDateSectionComponent sectionType={sectionType} />;
};
