import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import {
  ActionsPlacement,
  PendingInvitationActions,
} from '../../../../../_shared/components/PendingInvitationActions.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { UserDetailActivationButton } from './UserDetailActivationButton.tsx';

type FooterRightProps = {
  readonly hasUnsavedChanges: boolean;
  readonly isSaving: boolean;
  readonly onClose: () => void;
  readonly onUpdateUserRoles: () => void;
  readonly saveDisabledTooltip: string | undefined;
};

export const UserDetailFooterRight: FC<FooterRightProps> = ({
  hasUnsavedChanges,
  isSaving,
  onClose,
  onUpdateUserRoles,
  saveDisabledTooltip,
}) => (
  <>
    <Button
      buttonStyle="secondary"
      onClick={onClose}
      {...getDataUiActionAttribute(DataUiAction.CloseDialog)}
    >
      Cancel
    </Button>
    <Button
      tooltipText={saveDisabledTooltip}
      tooltipPlacement="top-end"
      buttonStyle="primary"
      disabled={!!saveDisabledTooltip}
      onClick={hasUnsavedChanges ? onUpdateUserRoles : onClose}
      {...getDataUiActionAttribute(DataUiAction.Save)}
    >
      {isSaving && (
        <Button.ProgressIcon
          {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
        />
      )}
      <Button.Label>Save</Button.Label>
    </Button>
  </>
);
UserDetailFooterRight.displayName = 'UserDetailFooterRight';

type FooterLeftProps = {
  readonly user: IProjectContributor;
  readonly onResendInvite: () => void;
  readonly onRevokeInvite: () => void;
};

export const UserDetailFooterLeft: FC<FooterLeftProps> = ({
  user,
  onResendInvite,
  onRevokeInvite,
}) =>
  user.isInvitationPending ? (
    <PendingInvitationActions
      isAdminOnProject={user.isAdminOnProject}
      isInviteRevoking={user.isInviteRevoking}
      isInviteSending={user.isInviteSending}
      isInviteSent={user.isInviteSent}
      onResendInvite={onResendInvite}
      onRevokeInvite={onRevokeInvite}
      placement={ActionsPlacement.UserDetail}
    />
  ) : (
    <UserDetailActivationButton user={user} />
  );

UserDetailFooterLeft.displayName = 'UserDetailFooterLeft';
