import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  ICollectionGroupRoles,
  createCollectionGroupRolesServerModel,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { getSubscriptionIdFromCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IInviteRepository } from '../../../../../repositories/interfaces/IInviteRepository.type.ts';
import { IBulkInviteUsersServerModel } from '../../../../../repositories/serverModels/InviteUserServerModel.type.ts';
import { IBulkProjectContributorServerModel } from '../../../../../repositories/serverModels/ProjectDetailsServerModel.type.ts';
import { ILoadSubscriptionUsage } from '../../../../subscriptionManagement/shared/types/ILoadSubscriptionUsage.type.ts';
import { inviteFailed } from '../../constants/errorMessages.ts';
import {
  Invite_SubmitInvitations_Failed,
  Invite_SubmitInvitations_Finished,
  Invite_SubmitInvitations_Started,
} from '../../constants/usersActionTypes.ts';
import { Invitation } from '../../reducers/IUsersAppState.type.ts';
import { createIsNewInvitationSelector } from '../../selectors/createIsNewInvitation.ts';

const started = () =>
  ({
    type: Invite_SubmitInvitations_Started,
  }) as const;

const finished = ({ people, failedInvitations }: IBulkProjectContributorServerModel) =>
  ({
    type: Invite_SubmitInvitations_Finished,
    payload: {
      users: people,
      failedInvitations,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Invite_SubmitInvitations_Failed,
    payload: { errorMessage },
  }) as const;

export type SubmitInvitationsActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

const createInvitationsRequestModel = (
  collectionGroups: readonly ICollectionGroupRoles[],
  invitations: Immutable.List<Invitation>,
): IBulkInviteUsersServerModel => ({
  users: invitations.toArray(),
  collectionGroups: createCollectionGroupRolesServerModel(collectionGroups),
});

const filterDuplicateInvitations = (
  invitations: Immutable.List<Invitation>,
): Immutable.List<Invitation> =>
  invitations
    .reduce(
      (result: Immutable.Map<string, Invitation>, i: Invitation) => result.set(i.email, i),
      Immutable.Map(),
    )
    .valueSeq()
    .toList();

type Deps = {
  readonly inviteRepository: Pick<IInviteRepository, 'sendBulkInvite'>;
  readonly loadSubscriptionUsage: ILoadSubscriptionUsage;
};

export const createSubmitInvitations =
  (deps: Deps) => (): ThunkPromise => async (dispatch, getState) => {
    const { invitations, collectionGroups } = getState().usersApp.userInvitation;

    dispatch(started());

    const shouldReloadSubscriptionUsage = invitations.some(
      createIsNewInvitationSelector(getState()),
    );
    const uniqueInvitations = filterDuplicateInvitations(invitations);
    const preparedInvitations = createInvitationsRequestModel(collectionGroups, uniqueInvitations);
    const subscriptionId = getSubscriptionIdFromCurrentProject(getState());

    assert(
      subscriptionId,
      () => `${__filename}: Subscription id for current project was not found.`,
    );

    try {
      const response = await deps.inviteRepository.sendBulkInvite(preparedInvitations);
      if (shouldReloadSubscriptionUsage) {
        await dispatch(deps.loadSubscriptionUsage(subscriptionId));
      }
      dispatch(finished(response));
    } catch (error) {
      dispatch(failed(inviteFailed));
      throw error;
    }
  };
