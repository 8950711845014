import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadAdministratedSubscriptions } from '../../../../data/actions/thunkDataActions.ts';
import { newSubscriptionCreator } from './thunk/createNewSubscription.ts';

const { subscriptionRepository } = repositoryCollection;

export const createNewSubscription = newSubscriptionCreator({
  subscriptionRepository,
  loadAdministratedSubscriptions,
});
