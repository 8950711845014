import { cardBorderRadius } from '@kontent-ai/component-library/Card';
import {
  BorderRadius,
  Size,
  Spacing,
  spacingCard,
  spacingPopupDistance,
} from '@kontent-ai/component-library/tokens';
import { Placement, placements } from '@kontent-ai/component-library/types';
import { InvariantException } from '@kontent-ai/errors';
import {
  AutoPlacement,
  BasePlacement,
  Placement as PopperPlacement,
  Rect,
  Variation,
} from '@popperjs/core';
import { cartesianMultiply } from '../../../../../app/_shared/utils/arrayUtils/arrayUtils.ts';
import { arrowSize } from '../tokens.ts';

interface IPlacementInfo {
  readonly basePlacement: BasePlacement | AutoPlacement;
  readonly variation?: Variation;
}

// These are declared here instead of import because of https://github.com/popperjs/popper-core/issues/1325
export const bottom: BasePlacement = 'bottom';
export const left: BasePlacement = 'left';
export const right: BasePlacement = 'right';
export const top: BasePlacement = 'top';
export const end = 'end';
export const start = 'start';
export const auto = 'auto';
export const autoStart = 'auto-start';
export const autoEnd = 'auto-end';

export const basePlacements: ReadonlyArray<BasePlacement> = [top, bottom, right, left];
export const variations: ReadonlyArray<Variation> = [start, end];

export const isAutoPlacement = (str: string): str is AutoPlacement => str === auto;
export const isBasePlacement = (str: string): str is BasePlacement =>
  (basePlacements as ReadonlyArray<string>).includes(str);
export const isVariation = (str: string): str is Variation =>
  (variations as ReadonlyArray<string>).includes(str);
export const isPopoverPlacement = (placement: PopperPlacement): placement is Placement =>
  placements.includes(placement as Placement);

export const parsePlacement = (placement: PopperPlacement): IPlacementInfo => {
  const [firstPart, secondPart] = placement.split('-');
  if (!firstPart || (!isBasePlacement(firstPart) && !isAutoPlacement(firstPart))) {
    throw InvariantException(
      `While parsing placement '${placement}', the first part '${firstPart}' is not considered a BasePlacement nor is it the AutoPlacement ('auto'). Only the following are considered BasePlacement: ${basePlacements.join(
        ', ',
      )}.`,
    );
  }
  if (typeof secondPart !== 'undefined' && !isVariation(secondPart)) {
    throw InvariantException(
      `While parsing placement '${placement}', the second part '${secondPart}' is not considered a Variation. Only the following are: ${variations.join(
        ', ',
      )}.`,
    );
  }

  return {
    basePlacement: firstPart,
    variation: secondPart,
  };
};

export const shouldApplySkidding = (
  reference: Rect,
  placement: Placement,
  popperArrowSize: Size,
  arrowPadding: Spacing,
): boolean => {
  const arrowSizeWithPadding = popperArrowSize + arrowPadding;

  switch (placement) {
    case 'top-start':
    case 'top-end':
    case 'bottom-start':
    case 'bottom-end':
      return reference.width < arrowSizeWithPadding;
    case 'left-start':
    case 'left-end':
    case 'right-start':
    case 'right-end':
      return reference.height < arrowSizeWithPadding;
    default:
      return false;
  }
};

// https://popper.js.org/docs/v2/modifiers/offset/
export type GetPopperOffset = (props: {
  readonly popper: Rect;
  readonly reference: Rect;
  readonly placement: Placement;
}) => [skidding: number, distance: number];

export const createGetPopperOffset =
  (popperArrowSize: Size, arrowPadding: Spacing, borderRadius: BorderRadius): GetPopperOffset =>
  ({ reference, placement }) => {
    const distance = spacingPopupDistance + 0.5 * popperArrowSize;
    const skidding = shouldApplySkidding(reference, placement, popperArrowSize, arrowPadding)
      ? borderRadius
      : 0;
    const { variation } = parsePlacement(placement);

    switch (variation) {
      case start:
        return [-1 * skidding, distance];
      case end:
        return [skidding, distance];
      default:
        return [0, distance];
    }
  };

export const getPopperOffset: GetPopperOffset = createGetPopperOffset(
  arrowSize,
  spacingCard,
  cardBorderRadius,
);

export function getPlacement(pair: Pair<BasePlacement, Variation | null | undefined>): Placement;
export function getPlacement(
  pair: Pair<BasePlacement | AutoPlacement, Variation | null | undefined>,
): PopperPlacement;
export function getPlacement(
  pair: Pair<BasePlacement | AutoPlacement, Variation | null | undefined>,
): Placement | PopperPlacement {
  const result = pair.filter(Boolean).join('-') as PopperPlacement;
  const popoverPlacementIndex = placements.indexOf(result as Placement);
  if (popoverPlacementIndex > 0) {
    return placements[popoverPlacementIndex] as Placement;
  }

  return result;
}

export const getPrioritizedFallbackBasePlacements = (
  basePlacement: BasePlacement | AutoPlacement,
  isInlinePositioningPluginEnabled: boolean,
): ReadonlyArray<BasePlacement> => {
  switch (basePlacement) {
    case right:
      return [right, left, bottom, top];
    case top:
      // Inline positioning is problematic with flipping to left and right as well.
      return [top, bottom, ...(isInlinePositioningPluginEnabled ? [] : ([left, right] as const))];
    case left:
      return [left, right, top, bottom];
    case bottom:
    case auto:
    case autoStart:
    case autoEnd:
      return [bottom, top, ...(isInlinePositioningPluginEnabled ? [] : ([right, left] as const))];
  }
};

export const getPrioritizedFallbackVariations = (
  defaultVariation?: Variation,
): ReadonlyArray<Variation | null> => {
  switch (defaultVariation) {
    case end:
      return [end, null, start];
    case start:
      return [start, null, end];
    default:
      return [null, start, end];
  }
};

export const getFallbackPlacements = (
  placement: PopperPlacement,
  isInlinePositioningPluginEnabled: boolean,
): ReadonlyArray<PopperPlacement> => {
  const { variation, basePlacement } = parsePlacement(placement);

  const fallbackBasePlacements = getPrioritizedFallbackBasePlacements(
    basePlacement,
    isInlinePositioningPluginEnabled,
  );
  const fallbackVariations = getPrioritizedFallbackVariations(variation);

  return cartesianMultiply<BasePlacement, Variation | null>(
    fallbackBasePlacements,
    fallbackVariations,
  )
    .map(getPlacement)
    .filter((fallback) => fallback !== placement) as ReadonlyArray<PopperPlacement>;
};
