import { InvariantException } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import {
  ISitemapNodeServerModel,
  ISitemapServerModel,
} from '../../../../repositories/serverModels/contentModels/SitemapServerModel.type.ts';

export interface ISitemapNode {
  readonly name: string;
  readonly codename: string | null;
  readonly id: Uuid;
  readonly childIds: ReadonlyArray<Uuid>;
}

export interface ISitemap extends ISitemapNode {
  readonly lastModified: string | null;
  readonly lastModifiedBy: string | null;
  readonly nodes: ReadonlyMap<Uuid, ISitemapNode>;
  readonly etag: string | null;
}

export const emptySitemapNode: ISitemapNode = {
  id: '',
  name: '',
  codename: null,
  childIds: [],
};

export const emptySitemap: ISitemap = {
  id: '',
  name: '',
  codename: null,
  childIds: [],
  nodes: new Map(),
  lastModified: '',
  lastModifiedBy: '',
  etag: null,
};

function stringify(whatever: any) {
  return JSON.stringify(whatever, null, 4);
}

function throwIfSitemapNodeErroneous(rawSitemapNode: ISitemapNodeServerModel): void {
  assert(rawSitemapNode, () => `Sitemap node is falsy:\n${stringify(rawSitemapNode)}`);

  if (typeof rawSitemapNode._id !== 'string') {
    throw InvariantException(
      `Sitemap node’s id is not a string:\n${stringify(rawSitemapNode._id)}`,
    );
  }

  if (typeof rawSitemapNode.name !== 'string') {
    throw InvariantException(
      `Sitemap node’s name is not a string:\n${stringify(rawSitemapNode.name)}`,
    );
  }

  if (rawSitemapNode.codeName !== null && typeof rawSitemapNode.codeName !== 'string') {
    throw InvariantException(
      `Sitemap node’s codename is neither a string nor null:\n${stringify(
        rawSitemapNode.codeName,
      )}`,
    );
  }

  if (!Array.isArray(rawSitemapNode.children)) {
    throw InvariantException(
      `Sitemap node’s children is not an array\n${stringify(rawSitemapNode.children)}`,
    );
  }
}

function throwIfSitemapErroneous(rawSitemap: ISitemapServerModel): void {
  assert(rawSitemap, () => `Sitemap is falsy:\n${stringify(rawSitemap)}`);

  if (typeof rawSitemap._id !== 'string') {
    throw InvariantException(`Sitemap’s id is not a string:\n${stringify(rawSitemap._id)}`);
  }

  if (typeof rawSitemap.name !== 'string') {
    throw InvariantException(`Sitemap’s name is not a string:\n${stringify(rawSitemap.name)}`);
  }

  if (typeof rawSitemap.lastModified !== 'string' && rawSitemap.lastModified !== null) {
    throw InvariantException(
      `Sitemap’s lastModified is not a string:\n${stringify(rawSitemap.lastModified)}`,
    );
  }

  if (typeof rawSitemap.lastModifiedBy !== 'string' && rawSitemap.lastModifiedBy !== null) {
    throw InvariantException(
      `Sitemap’s lastModifiedBy is not a string:\n${stringify(rawSitemap.lastModifiedBy)}`,
    );
  }

  if (rawSitemap.codeName !== null && typeof rawSitemap.codeName !== 'string') {
    throw InvariantException(
      `Sitemap’s codename is neither a string nor null:\n${stringify(rawSitemap.codeName)}`,
    );
  }

  if (!Array.isArray(rawSitemap.children)) {
    throw InvariantException(
      `Sitemap’s children is not an array\n${stringify(rawSitemap.children)}`,
    );
  }
}

function createSitemapNodeFromServerModel(
  rawSitemapNode: ISitemapNodeServerModel,
  saveChildren: (childNodes: ReadonlyArray<ISitemapNode>) => void,
): ISitemapNode {
  throwIfSitemapNodeErroneous(rawSitemapNode);

  const childNodes = (rawSitemapNode.children ?? []).map((child) =>
    createSitemapNodeFromServerModel(child, saveChildren),
  );
  saveChildren(childNodes);

  return {
    name: rawSitemapNode.name ?? emptySitemapNode.name,
    codename: rawSitemapNode.codeName ?? emptySitemapNode.codename,
    id: rawSitemapNode._id ?? emptySitemapNode.id,
    childIds: rawSitemapNode.children.map((child) => child._id),
  };
}

function createSitemapNodeServerModel(
  currentNodeId: Uuid,
  allNodes: ReadonlyMap<Uuid, ISitemapNode>,
): ISitemapNodeServerModel {
  const currentNode = allNodes.get(currentNodeId);
  if (!currentNode) {
    throw InvariantException(
      `Cannot convert sitemap node with id ${currentNodeId} to server model as there is no node with such id.`,
    );
  }

  const children = currentNode.childIds.map((id: Uuid) =>
    createSitemapNodeServerModel(id, allNodes),
  );
  return {
    _id: currentNode.id,
    name: currentNode.name,
    codeName: currentNode.codename,
    children,
  };
}

export function createSitemapFromServerModel(rawSitemap: ISitemapServerModel): ISitemap {
  throwIfSitemapErroneous(rawSitemap);

  const allChildNodes = new Map<Uuid, ISitemapNode>();
  const saveNodes = (nodesToSave: ReadonlyArray<ISitemapNode>) => {
    nodesToSave.forEach((node) => {
      if (node) {
        allChildNodes.set(node.id, node);
      }
    });
  };

  const rootNodes = rawSitemap.children.map((child) =>
    createSitemapNodeFromServerModel(child, saveNodes),
  );
  saveNodes(rootNodes);

  return {
    name: rawSitemap.name ?? emptySitemap.name,
    codename: rawSitemap.codeName ?? emptySitemap.codename,
    lastModified: rawSitemap.lastModified ?? emptySitemap.lastModified,
    lastModifiedBy: rawSitemap.lastModifiedBy ?? emptySitemap.lastModifiedBy,
    id: rawSitemap._id ?? emptySitemap.id,
    childIds: rawSitemap.children.map((child) => child._id),
    nodes: allChildNodes,
    etag: rawSitemap.etag ?? emptySitemap.etag,
  };
}

export function createSitemapServerModel(sitemap: ISitemap): ISitemapServerModel {
  const { id, name, codename, childIds, nodes, lastModified, lastModifiedBy, etag } = sitemap;
  const children = childIds.map((childId: Uuid) => createSitemapNodeServerModel(childId, nodes));
  return {
    _id: id,
    name,
    codeName: codename,
    lastModified,
    lastModifiedBy,
    children,
    etag,
  };
}
