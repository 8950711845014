import React from 'react';
import { ItemPublishedWithNewVersionTooltipText } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import { PublishingState } from '../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { getWorkflow } from '../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { WorkflowStatusTagForVariant } from '../../components/Workflow/WorkflowStatusTagForVariant.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { hasProjectMultipleWorkflows } from '../../selectors/Workflows/hasProjectMultipleWorkflows.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';
import { isDefined } from '../../utils/filter/isDefined.ts';
import { capitalizeFirstLetter } from '../../utils/stringUtils.ts';
import { getWorkflowTooltip } from '../../utils/workflow/getWorkflowTooltip.ts';
import { isVariantScheduledActionInProgress } from '../../utils/workflow/isVariantScheduledActionInProgress.ts';

const getItemScheduledToUnpublishTooltipText = (scheduledToUnpublishAt: string | null) => {
  if (!scheduledToUnpublishAt) {
    return '';
  }

  return isVariantScheduledActionInProgress(scheduledToUnpublishAt)
    ? `This item’s being unpublished and archived now. It’s scheduled for ${renderDatetimeString(
        scheduledToUnpublishAt,
      )}. It’ll be done in a few minutes.`
    : `Item will be unpublished on ${renderDatetimeString(scheduledToUnpublishAt)}.`;
};

const getItemScheduledToPublishTooltipText = (scheduledToPublishAt: string | null) => {
  if (!scheduledToPublishAt) {
    return undefined;
  }

  return isVariantScheduledActionInProgress(scheduledToPublishAt)
    ? `This item’s being published now. It’s scheduled for ${renderDatetimeString(
        scheduledToPublishAt,
      )}. It’ll be done in a few minutes.`
    : `Item will be published on ${renderDatetimeString(scheduledToPublishAt)}.`;
};

interface IWorkflowStatusTagForVariantProps {
  readonly publishingState: PublishingState;
  readonly scheduledToPublishAt: DateTimeStamp | null;
  readonly scheduledToUnpublishAt: DateTimeStamp | null;
  readonly workflowStatus: IAssignmentWorkflowStep;
}

const getWorkflowTooltipText =
  (workflowStatus: IAssignmentWorkflowStep) =>
  (state: IStore): string | undefined => {
    const workflow = getWorkflow(state, workflowStatus.workflowId);

    return workflow && hasProjectMultipleWorkflows(state)
      ? capitalizeFirstLetter(getWorkflowTooltip(workflow.name))
      : undefined;
  };

const WorkflowStatusTagForVariantContainer: React.FC<IWorkflowStatusTagForVariantProps> = ({
  publishingState,
  scheduledToPublishAt,
  scheduledToUnpublishAt,
  workflowStatus,
}) => {
  const workflowTooltipText = useSelector(getWorkflowTooltipText(workflowStatus));

  const scheduledToPublishAtTooltipText =
    getItemScheduledToPublishTooltipText(scheduledToPublishAt);
  const scheduledToUnpublishAtTooltipText =
    getItemScheduledToUnpublishTooltipText(scheduledToUnpublishAt);

  const primaryTooltipText = [scheduledToPublishAtTooltipText, workflowTooltipText]
    .filter(isDefined)
    .join('\n');
  const secondaryTooltipText = [ItemPublishedWithNewVersionTooltipText, workflowTooltipText]
    .filter(isDefined)
    .join('\n');

  return (
    <WorkflowStatusTagForVariant
      primaryTooltipText={primaryTooltipText}
      publishingState={publishingState}
      scheduledToUnpublishAt={scheduledToUnpublishAt}
      scheduledToUnpublishAtTooltipText={scheduledToUnpublishAtTooltipText}
      secondaryTooltipText={secondaryTooltipText}
      workflowStatus={workflowStatus}
    />
  );
};

WorkflowStatusTagForVariantContainer.displayName = 'WorkflowStatusTagForVariantContainer';
export { WorkflowStatusTagForVariantContainer as WorkflowStatusTagForVariant };
