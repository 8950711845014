export const ContentEditing_CascadeModal_NodeCollapsed =
  'ContentEditing_CascadeModal_NodeCollapsed';

export const ContentEditing_CascadeModal_NodeDeselected =
  'ContentEditing_CascadeModal_NodeDeselected';
export const ContentEditing_CascadeModal_NodesSelected =
  'ContentEditing_CascadeModal_NodesSelected';

export const ContentEditing_CascadeModal_ExpandNodeStarted =
  'ContentEditing_CascadeModal_ExpandNodeStarted';
export const ContentEditing_CascadeModal_ExpandNodeFinished =
  'ContentEditing_CascadeModal_ExpandNodeFinished';
export const ContentEditing_CascadeModal_ChildNodesInitialized =
  'ContentEditing_CascadeModal_ChildNodesInitialized';
export const ContentEditing_CascadeModal_InitFinished = 'ContentEditing_CascadeModal_InitFinished';
export const ContentEditing_CascadeModal_InitStarted = 'ContentEditing_CascadeModal_InitStarted';

export const ContentEditing_CascadeModal_PublishingFailed =
  'ContentEditing_CascadeModal_PublishingFailed';
export const ContentEditing_CascadeModal_PublishingFinished =
  'ContentEditing_CascadeModal_PublishingFinished';
export const ContentEditing_CascadeModal_PublishingStarted =
  'ContentEditing_CascadeModal_PublishingStarted';

export const ContentEditing_CascadeModal_SchedulingFailed =
  'ContentEditing_CascadeModal_SchedulingFailed';
export const ContentEditing_CascadeModal_SchedulingFinished =
  'ContentEditing_CascadeModal_SchedulingFinished';
export const ContentEditing_CascadeModal_SchedulingStarted =
  'ContentEditing_CascadeModal_SchedulingStarted';

export const ContentEditing_CascadeAction_UndoStarted = 'ContentEditing_CascadeAction_UndoStarted';
export const ContentEditing_CascadeAction_UndoFinished =
  'ContentEditing_CascadeAction_UndoFinished';
export const ContentEditing_CascadeAction_UndoFailed = 'ContentEditing_CascadeAction_UndoFailed';
