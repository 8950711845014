import { ElementComponentLocator } from '../../../utils/elementComponentLocator.ts';
import {
  ExpandedItemElementDataProps,
  NestableExpandedItemElementDataProps,
} from '../../LinkedItems/components/types/expandedItemElement.type.ts';
import {
  IContentComponentItemElementOwnProps,
  INestableContentComponentItemElementOwnProps,
} from '../IContentComponentItemElementOwnProps.type.ts';

export const contentComponentElementComponentLocator = new ElementComponentLocator<
  IContentComponentItemElementOwnProps | INestableContentComponentItemElementOwnProps
>();

export const expandedContentComponentElementComponentLocator = new ElementComponentLocator<
  ExpandedItemElementDataProps | NestableExpandedItemElementDataProps
>();
