import { Action } from '../../../../@types/Action.type.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed,
  ChangeWorkflowStep_ScheduledToUnpublish_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

const emptyState = null;

export function scheduledToUnpublishAt(state = emptyState, action: Action) {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.scheduledToUnpublishAt;

    case ChangeWorkflowStep_ScheduleToUnpublishMethod_Changed:
      return emptyState;

    case ChangeWorkflowStep_ScheduledToUnpublish_Changed:
      return action.payload.scheduledToUnpublishAt;

    default:
      return state;
  }
}
