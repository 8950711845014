import { Collection, chronologically, notNull } from '@kontent-ai/utils';
import { useEffect } from 'react';
import { modalOpened } from '../../actions/sharedActions.ts';
import { showUserFeedback } from '../../actions/thunks/showUserFeedback.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { AiFeedbackTimestamps } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { getCurrentUser } from '../../selectors/getCurrentUser.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { hasWeeksPeriodPassed } from '../../utils/dateTime/timeUtils.ts';
import { isEmployee } from '../../utils/subscriptionUsageUtils.ts';

const isEnabled = false;
const showAiFeedbackModalAfterWeeks = 2;

const shouldShowAiFeedbackModalAutomatically = (state: IStore): boolean => {
  const {
    sharedApp: {
      userProperties: {
        aiFeedbackTimestamps: { answeredAt, dismissedAt, displayedAt },
      },
    },
  } = state;

  if (!isEnabled) {
    return false;
  }

  if (isEmployee(getCurrentUser(state).email)) {
    return false;
  }

  if (!answeredAt && !dismissedAt && !displayedAt) {
    return false;
  }

  const newestDate = Collection.getLast(
    [answeredAt, dismissedAt, displayedAt].filter(notNull).sort(chronologically),
  );

  return (
    !!newestDate && hasWeeksPeriodPassed(showAiFeedbackModalAfterWeeks, new Date(), newestDate)
  );
};

export const ShowAiFeedbackModalAutomatically = () => {
  const shouldShowDialog = useSelector(shouldShowAiFeedbackModalAutomatically);
  const feedbackTimestamps = useSelector((s) => s.sharedApp.userProperties.aiFeedbackTimestamps);

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldShowDialog) {
      dispatch(showUserFeedback(UserFeedbackSource.Ai, AiFeedbackTimestamps, feedbackTimestamps));
      dispatch(modalOpened(ModalDialogType.AiFeedbackDialog));
    }
  }, [shouldShowDialog, feedbackTimestamps]);

  return null;
};
