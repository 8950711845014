import { Collection } from '@kontent-ai/utils';
import { ITaxonomyItemElement } from '../../models/contentItemElements/TaxonomyItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export function taxonomyItemElementsEqualityChecker(
  itemElement1: ITaxonomyItemElement,
  itemElement2: ITaxonomyItemElement,
): boolean {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }

  const values1 = itemElement1.value;
  const values2 = itemElement2.value;
  const isNumberOfValuesTheSame = values1.size === values2.size;
  const valuesAreTheSame = Collection.isSubset([...values1], values2);

  return isNumberOfValuesTheSame && valuesAreTheSame;
}
