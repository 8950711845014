import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { pluralizeWithCount } from '../../../../../../_shared/utils/stringUtils.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { Content_LoadedContentItemTypes_TypesLoaded } from '../../../../../contentInventory/content/constants/contentActionTypes.ts';
import {
  ICompiledContentType,
  getCompiledContentTypeFromServerModel,
} from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import { getContentTypeConversionOptions } from '../../../../../contentModels/shared/selectors/contentTypeElementSelector.ts';
import { ILoadContentTypeReferencesForItemEditingAction } from './loadContentTypeReferencesForItemEditing.ts';

const contentTypesLoaded = (contentTypes: ReadonlyArray<ICompiledContentType>) =>
  ({
    type: Content_LoadedContentItemTypes_TypesLoaded,
    payload: {
      contentTypes,
    },
  }) as const;

export type ContentTypesLoadedActionType = ReturnType<typeof contentTypesLoaded>;

export type IEnsureLoadedContentTypesAction = (
  contentTypeIds: ReadonlySet<Uuid>,
  abortSignal?: AbortSignal,
) => ThunkPromise<ReadonlyArray<ICompiledContentType>>;

interface IEnsureLoadedContentTypesActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly loadContentTypeReferencesForItemEditing: ILoadContentTypeReferencesForItemEditingAction;
}

export const createEnsureLoadedContentTypesAction =
  ({
    contentItemRepository,
    loadContentTypeReferencesForItemEditing,
  }: IEnsureLoadedContentTypesActionDependencies): IEnsureLoadedContentTypesAction =>
  (contentTypeIds, abortSignal) =>
  async (dispatch, getState) => {
    const rawTypes = await contentItemRepository.getContentTypesByIds(contentTypeIds, abortSignal);
    const conversionOptions = getContentTypeConversionOptions(getState());
    const contentTypes = rawTypes.map((serverModel) =>
      getCompiledContentTypeFromServerModel(serverModel, conversionOptions),
    );

    assert(
      contentTypeIds.size === contentTypes.length,
      () =>
        `${__filename}: Requested ${pluralizeWithCount('type', contentTypeIds.size)}, but got ${
          contentTypes.length
        }.`,
    );

    const allContentElements = contentTypes.flatMap((contentType) => contentType.contentElements);
    await dispatch(loadContentTypeReferencesForItemEditing(allContentElements, abortSignal));

    dispatch(contentTypesLoaded(contentTypes));

    return contentTypes;
  };
