import { colorTextDefault, colorTextDefaultInverse } from '../tokens/decision/colors.ts';
import { BaseColor, Color } from '../tokens/quarks/colors.ts';
import { GradientType } from '../tokens/quarks/gradients.ts';
import { getContrast } from './gradient.ts';

/**
 * Returns 'colorTextDefault' or 'colorTextDefaultInverse', whichever one has better contrast with your provided background color or gradient.
 */
export const getReadableTextColor = (background: Color | GradientType): BaseColor => {
  const contrastWithLight = getContrast(background, colorTextDefault);
  const contrastWithDark = getContrast(background, colorTextDefaultInverse);

  return contrastWithLight > contrastWithDark ? colorTextDefault : colorTextDefaultInverse;
};
