import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IStatusInfoMessage } from '../../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { StatusMessageStyle } from '../../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import {
  ICascadeResult,
  LastCascadeAction,
} from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';

export const getStatusInfoMessage = (
  editedItemName: string,
  numberOfSelectedItems: number,
): IStatusInfoMessage =>
  numberOfSelectedItems === 0
    ? {
        style: StatusMessageStyle.Normal,
        text: `${editedItemName} selected`,
      }
    : {
        style: StatusMessageStyle.Selected,
        text: `${editedItemName} and ${pluralizeWithCount(
          'more item',
          numberOfSelectedItems,
        )} selected`,
      };

export const getStatusInfoMessageForFailedDialog = memoize.maxOne(
  (cascadeResult: ICascadeResult): IStatusInfoMessage => {
    const modifiedItemsCount = cascadeResult.modifiedItemIds.size;
    const failedItemsCount = cascadeResult.failedItemIds.size;

    switch (cascadeResult.lastAction) {
      case LastCascadeAction.Publish:
        return {
          style: StatusMessageStyle.Normal,
          text: `${pluralizeWithCount(
            'content item',
            modifiedItemsCount,
          )} published, ${failedItemsCount} failed`,
        };
      case LastCascadeAction.Schedule:
        return {
          style: StatusMessageStyle.Normal,
          text: `${pluralizeWithCount(
            'content item',
            modifiedItemsCount,
          )} scheduled, ${failedItemsCount} failed`,
        };
      case LastCascadeAction.UndoPublish:
        return {
          style: StatusMessageStyle.Normal,
          text: `${pluralizeWithCount(
            'content item',
            modifiedItemsCount,
          )} unpublished, ${failedItemsCount} failed`,
        };
      case LastCascadeAction.UndoSchedule:
        return {
          style: StatusMessageStyle.Normal,
          text: `${pluralizeWithCount(
            'content item',
            modifiedItemsCount,
          )} unscheduled, ${failedItemsCount} failed`,
        };
      default:
        throw InvariantException(
          `Status info message for last action ${cascadeResult.lastAction} is not supported`,
        );
    }
  },
);
