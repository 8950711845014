import { ILanguage } from '../../../data/models/languages/Language.ts';
import { ILanguagesData } from '../../../data/reducers/languages/ILanguagesData.type.ts';
import { EditedContentItemVariant } from '../models/contentItem/edited/EditedContentItemVariant.ts';

export const getVariantLanguage = (
  variant: EditedContentItemVariant,
  languages: ILanguagesData,
): ILanguage => getVariantIdLanguage(variant.id.variantId, languages);

export const getVariantIdLanguage = (
  variantId: Uuid,
  { byId, defaultLanguage }: ILanguagesData,
): ILanguage => byId.get(variantId, defaultLanguage);
