import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEventWithData } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ProjectOverviewEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { RequestTokenFactory } from '../../../../../_shared/utils/requestTokenUtils.ts';
import { getListingContentItemFromJS } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import { IContentItemRepository } from '../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  ContentItemFilterWithContinuationServerModel,
  DateTimeField,
} from '../../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import {
  projectOverviewLoadItemsMatchingFilterFinished,
  projectOverviewLoadItemsMatchingFilterLimitExceeded,
} from '../projectOverviewActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
  readonly createContentItemFilterServerModelFromFilter: (
    filter: IListingFilter,
    workflows: ReadonlyMap<Uuid, Workflow>,
  ) => ContentItemFilterWithContinuationServerModel;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly requestTokenFactory: RequestTokenFactory;
}

const MaximalNumberOfItemsToRequestFromServer = 5000;
const ItemsDaysSinceLastModificationLimit = 90;
const msInADay = 24 * 3600 * 1000;

const getLastModificationRange = () => {
  const now = new Date();
  const lastModifiedLimit = new Date(
    now.valueOf() - ItemsDaysSinceLastModificationLimit * msInADay,
  );

  return [
    {
      fields: [DateTimeField.LastModifiedAt],
      from: lastModifiedLimit.toISOString(),
      to: now.toISOString(),
    },
  ];
};

export const createLoadItemsMatchingFilter =
  (deps: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());

    const {
      contentApp: {
        listingUi: { filter },
      },
      data: { workflows },
    } = getState();
    const {
      tokenizedActionCreator: projectOverviewLoadItemsMatchingFilterStarted,
      isCurrentTokenValid,
    } = deps.requestTokenFactory(getState);

    dispatch(projectOverviewLoadItemsMatchingFilterStarted());

    const userFilter = deps.createContentItemFilterServerModelFromFilter(filter, workflows.byId);
    const lastModificationRange = getLastModificationRange();
    const completeFilter: ContentItemFilterWithContinuationServerModel = {
      ...userFilter,
      dateRanges: lastModificationRange,
    };

    const response = await deps.contentItemRepository.getPlanningItems(
      selectedLanguageId,
      completeFilter,
      MaximalNumberOfItemsToRequestFromServer,
      isCurrentTokenValid,
      abortSignal,
    );

    if (response.wasDataCountLimitExceeded) {
      dispatch(projectOverviewLoadItemsMatchingFilterLimitExceeded());
      dispatch(
        deps.trackUserEventWithData(TrackedEvent.ProjectOverview, {
          action: ProjectOverviewEventTypes.MaxItemsLimitExceeded,
        }),
      );
      return;
    }

    if (isCurrentTokenValid()) {
      const now = new Date();
      const items = response.data.map(getListingContentItemFromJS);

      if (!items.length) {
        dispatch(
          trackUserEventWithData(TrackedEvent.ProjectOverview, {
            action: ProjectOverviewEventTypes.NoItemsFound,
          }),
        );
      }

      dispatch(projectOverviewLoadItemsMatchingFilterFinished(items, now));
    }
  };
