import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  EntityWebhooks_InitCreate_Completed,
  EntityWebhooks_InitCreate_Started,
} from '../../constants/entityWebhooksActionTypes.ts';

interface IDeps {
  readonly loadContentTypes: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadTaxonomyGroups: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadWorkflows: (abortSignal?: AbortSignal) => ThunkPromise;
}

const started = () =>
  ({
    type: EntityWebhooks_InitCreate_Started,
  }) as const;

const completed = () =>
  ({
    type: EntityWebhooks_InitCreate_Completed,
  }) as const;

export type InitEntityWebhookCreatorActionsType = ReturnType<typeof started | typeof completed>;

export const initEntityWebhookCreatorAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(started());

    await Promise.all([
      dispatch(deps.loadContentTypes(abortSignal)),
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
      dispatch(deps.loadTaxonomyGroups(abortSignal)),
      dispatch(deps.loadWorkflows(abortSignal)),
    ]);

    dispatch(completed());
  };
