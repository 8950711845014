import { DraftDecorator } from 'draft-js';
import React, { PropsWithChildren, useCallback, useRef } from 'react';
import { logError } from '../../../../_shared/utils/logError.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, EditorPlugin, Init } from '../../editorCore/types/Editor.plugins.type.ts';
import { DecorableFunction, decorable } from '../../editorCore/utils/decorable.ts';
import { EntityDecoratorProps } from '../entityApi/api/editorEntityUtils.ts';
import { LinkType } from './api/LinkType.ts';
import { NewLinkType } from './api/NewLinkType.ts';
import { findLinks, getLinkType } from './api/editorLinkUtils.ts';

export type GetLinkEntityComponent<TCustomProps extends ReadonlyRecord<string, any>> = (
  linkType: LinkType | NewLinkType,
) => {
  readonly component: React.FC<EntityDecoratorProps>;
  readonly props: TCustomProps;
} | null;

type GetUnknownLinkEntityComponent = GetLinkEntityComponent<ReadonlyRecord<string, unknown>>;

type DisplayLinksPluginState = {
  readonly getLinkEntityComponent: DecorableFunction<GetUnknownLinkEntityComponent>;
};

export type DisplayLinksPlugin = EditorPlugin<DisplayLinksPluginState>;

type LinkCustomProps = {
  readonly getLinkEntityComponentRef: React.RefObject<GetUnknownLinkEntityComponent>;
};

const LinkEntity = (props: PropsWithChildren<EntityDecoratorProps<LinkCustomProps>>) => {
  const { getLinkEntityComponentRef, ...entityProps } = props;
  const contentState = props.contentState;
  const entity = contentState.getEntity(props.entityKey);
  const linkType = getLinkType(entity);

  const componentResult = linkType && getLinkEntityComponentRef.current?.(linkType);
  if (componentResult) {
    return componentResult.component({
      ...entityProps,
      ...componentResult.props,
    });
  }

  if (linkType) {
    logError(
      `Cannot find link entity component for link type '${linkType}'. Make sure that a plugin for this link type is registered and decorates method getLinkEntityComponent.`,
    );
  }
  return props.children;
};

export const DisplayLinksPlugin: PluginComponent<DisplayLinksPlugin> = (props) => {
  // We need to access methods using state callbacks via handle to editor component
  // as we need to pass them to the decorator, and they are not yet available in the init phase
  const getLinkEntityComponentRef = useRef<GetUnknownLinkEntityComponent | null>(null);

  const init: Init = useCallback((state) => {
    const linkCustomProps: LinkCustomProps = { getLinkEntityComponentRef };
    const linkDecorator: DraftDecorator = {
      strategy: findLinks,
      component: LinkEntity,
      props: linkCustomProps,
    };

    return {
      decorators: [...state.decorators, linkDecorator],
    };
  }, []);

  const apply: Apply<DisplayLinksPlugin> = useCallback(() => {
    const getLinkEntityComponent = decorable<GetUnknownLinkEntityComponent>(() => null);
    getLinkEntityComponentRef.current = getLinkEntityComponent;

    return {
      getLinkEntityComponent,
    };
  }, []);

  return useEditorWithPlugin(props, { init, apply });
};
