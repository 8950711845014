import { Collection } from '@kontent-ai/utils';
import { useSyncExternalStore } from 'react';
import { createCancelOperationParams } from '../../../../../repositories/serverModels/ai/AiServerModels.cancelOperation.ts';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { repositoryCollection } from '../../../../repositories/repositories.ts';
import { MatchAiTasks } from './matchAiTask.type.ts';

export const useCancelAiTasks = (matchAiTasks?: MatchAiTasks): (() => Promise<void>) => {
  const aiTaskManager = useAiTaskManager();
  const allTasksSnapshot = useSyncExternalStore(
    aiTaskManager.subscribe,
    aiTaskManager.getAllTasksSnapshot,
  );

  const allTasks = Collection.getValues(allTasksSnapshot);
  const matchingTasks = matchAiTasks ? matchAiTasks(allTasks) : allTasks;

  return async () => {
    await Promise.all(
      matchingTasks.map((task) =>
        aiTaskManager.cancel(task.id, (operationId) =>
          repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
        ),
      ),
    );
  };
};
