import React from 'react';

export const ScrollTableDefaultRowSkelet: React.FC = () => (
  <div className="scroll-table__row scroll-table__row--is-skeleton">
    <div className="scroll-table__cell">
      <div className="cell-item-skelet">
        <div className="cell-item-skelet__pane" />
      </div>
    </div>
  </div>
);

ScrollTableDefaultRowSkelet.displayName = 'ScrollTableDefaultRowSkelet';
