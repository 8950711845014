import { Action } from '../../../../../@types/Action.type.ts';
import {
  AssetSelector_Closed,
  AssetSelector_Opened,
} from '../../features/ModalAssetSelector/constants/assetSelectorActionTypes.ts';

export const isAssetSelectorOpened = (state = false, action: Action): boolean => {
  switch (action.type) {
    case AssetSelector_Opened:
      return true;

    case AssetSelector_Closed:
      return false;

    default:
      return state;
  }
};
