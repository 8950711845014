import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { AssignmentSections } from '../constants/AssignmentSections.ts';

export const isSectionSubmitting = (state: IStore, section: AssignmentSections): boolean =>
  state.contentApp.editorUi.assignmentMetadata.submittingSection === section;

export const isOtherAssignmentSectionSubmitting = (
  state: IStore,
  section: AssignmentSections,
): boolean =>
  !!state.contentApp.editorUi.assignmentMetadata.submittingSection &&
  !isSectionSubmitting(state, section);
