import Immutable from 'immutable';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import {
  IRoleServerModel,
  IRoleUpdateServerModel,
} from '../../../../../repositories/serverModels/IRoleServerModel.type.ts';
import { UnableToSaveErrorMessage } from '../../constants/UIConstants.ts';
import {
  Role_Create_Failed,
  Role_Create_Finished,
  Role_Create_Started,
} from '../../constants/rolesActionTypes.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'createRole'>;
}

const started = () =>
  ({
    type: Role_Create_Started,
  }) as const;

const finished = (role: IRoleServerModel, allTypes: Immutable.Map<Uuid, IContentType>) =>
  ({
    type: Role_Create_Finished,
    payload: {
      role,
      allTypes,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Role_Create_Failed,
    payload: { errorMessage },
  }) as const;

export type CreateRoleActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const makeCreateRoleAction =
  (deps: IDeps) =>
  (
    role: IRoleUpdateServerModel,
    contentTypesById: Immutable.Map<Uuid, IContentType>,
  ): ThunkPromise<IRoleServerModel> =>
  async (dispatch) => {
    try {
      dispatch(started());
      const serverModel = await deps.roleRepository.createRole(role);
      dispatch(finished(serverModel, contentTypesById));
      dispatch(trackUserEvent(TrackedEvent.CustomRoleCreated));
      return serverModel;
    } catch (error) {
      dispatch(failed(UnableToSaveErrorMessage));
      throw error;
    }
  };
