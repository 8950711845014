import { gridUnit } from '@kontent-ai/component-library/tokens';

export const CustomAssetDomainCreateNewWarningText =
  'You can’t use your custom asset domain setup in the new environment. Not even after you mark it as production.';
export const CustomAssetDomainSwapWarningText =
  'Your custom asset domain won’t be used. Assets will use the default URL instead.';
export const EnvironmentsLimitReachedTooltipMessage =
  'You’ve reached the limit of environments for this project.';
export const MissingEnvironmentPermissionsMessage =
  'You don’t have permission to manage this environment.';
export const NoEnvironmentWithCapabilityToCloneFromTooltipMessage =
  'You don’t have permission to clone any environment.';
export const ProductionEnvironmentDefaultName = 'Production';

export const MinDialogWidth = gridUnit * 70;
