import { WorkflowScope } from '../../../../data/models/workflow/Workflow.ts';
import { ContentTypeOption } from '../components/limitations/ContentTypesLimitations.tsx';

export const createDeletedContentType = (id: Uuid): ContentTypeOption => ({
  id,
  label: 'Deleted content type',
  isArchived: true,
});

export const getSelectedContentTypes = (
  editedWorkflowScopesById: ReadonlyMap<Uuid, WorkflowScope>,
  scopeId: Uuid | undefined,
  contentTypes: readonly ContentTypeOption[],
): readonly ContentTypeOption[] => {
  if (!scopeId) {
    return [];
  }

  const workflowScope = editedWorkflowScopesById.get(scopeId);

  if (!workflowScope) {
    return [];
  }

  return Array.from(workflowScope.contentTypes).map(
    (id) =>
      contentTypes.find((contentType) => contentType.id === id) ?? createDeletedContentType(id),
  );
};
