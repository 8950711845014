import { Collection, alphabetically, createCompare } from '@kontent-ai/utils';
import React, { useMemo } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { updateAssetFolder } from '../../actions/thunkAssetLibraryActions.ts';
import { AssetLibraryFolders as AssetLibraryFoldersComponent } from '../../components/AssetFolders/AssetLibraryFolders.tsx';
import { getSubFolderIdsOfOpenedAssetFolder } from '../../selectors/getOpenedAssetFolder.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

type Props = {
  readonly clearAssetSelection: () => void;
  readonly orderBy: AssetsOrderBy;
};

export const AssetLibraryFolders: React.FC<Props> = ({ clearAssetSelection, orderBy }) => {
  const assetFolders = useAssetFolders();
  const dispatch = useDispatch();
  const canUpdateAssets = useSelector((s) =>
    currentUserHasCapabilities(s, Capability.UpdateAssets),
  );
  const isAssetSelectorOpened = useSelector((s) => s.assetLibraryApp.isAssetSelectorOpened);

  return (
    <AssetLibraryFoldersComponent
      canManageFolders={canUpdateAssets}
      clearAssetSelection={clearAssetSelection}
      folders={assetFolders}
      isAssetSelectorOpened={isAssetSelectorOpened}
      onFolderUpdate={(folder) => dispatch(updateAssetFolder(folder))}
      orderBy={orderBy}
    />
  );
};

const useAssetFolders = () => {
  const terms = useSelector((s) => s.data.assetFolders.taxonomyGroup.terms);
  const childIds = useSelector(getSubFolderIdsOfOpenedAssetFolder);

  return useMemo(() => getSortedFolders(terms, childIds), [terms, childIds]);
};

const getSortedFolders = (
  folders: ReadonlyMap<Uuid, ITaxonomyTerm>,
  folderIds: ReadonlyArray<Uuid>,
): ReadonlyArray<ITaxonomyTerm> =>
  Collection.getValues(folders)
    .filter((folder: ITaxonomyTerm) => folderIds.includes(folder.id))
    .sort(
      createCompare({
        select: (folder) => folder.name,
        compare: alphabetically,
      }),
    );
