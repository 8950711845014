/* This is an alternative implementation to https://github.com/facebook/draft-js/blob/master/src/model/encoding/convertFromDraftStateToRaw.js
 * which is slow for large contents
 *
 * It leverages memoization to provide the same output, but only recalculating whole if
 * It is optimized for scenarios where user types regular text into an existing block
 * When entities change, whole content is re-evaluated
 */
import { memoize } from '@kontent-ai/memoization';
import Draft, {
  ContentBlock,
  ContentState,
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js';
import Immutable from 'immutable';
import { getEntityType } from '../../../plugins/entityApi/api/Entity.ts';
import { getFullBlockType } from '../../blocks/editorBlockGetters.ts';
import { EntityMap, getBlocks, getEntityMap } from '../../general/editorContentGetters.ts';
import { strlen } from './unicodeUtils.ts';

type RawEntityMap = ReadonlyRecord<string, RawDraftEntity>;

type RawBlock = {
  readonly rawBlock: RawDraftContentBlock;
  readonly entityKeys: Immutable.List<string>;
};

// This memmoization is needed to ensure stable entity results for currently edited block(s) to not break memoization of subsequent chain
// getEntityKeyMap -> encodeRawEntityMap in case entities are not changed
const getEntityKeysResult = memoize.maxN(
  (...entityKeys: ReadonlyArray<string>): Immutable.List<string> => {
    return Immutable.List.of<string>(...entityKeys);
  },
  2,
);

const encodeEntityRanges = (
  block: ContentBlock,
  entitiesBefore: number,
): {
  readonly entityRanges: Array<RawDraftEntityRange>;
  readonly entityKeys: Immutable.List<string>;
} => {
  const entityKeys = Array<string>();
  let entityIndex = entitiesBefore;

  const entityRanges = Array<RawDraftEntityRange>();
  const characterList = block.getCharacterList();
  const text = block.getText();

  const filterEmptyRange = (ch: Draft.CharacterMetadata) => !!ch.getEntity();
  const processEntityRange = (start: number, end: number) => {
    entityRanges.push({
      offset: strlen(text.slice(0, start)),
      length: strlen(text.slice(start, end)),
      key: entityIndex,
    });
    const entityKey = characterList.get(start)?.getEntity() || '';
    entityKeys.push(entityKey);
    entityIndex++;
  };

  block.findEntityRanges(filterEmptyRange, processEntityRange);

  return {
    entityRanges,
    entityKeys: getEntityKeysResult(...entityKeys),
  };
};

type RawStyleList = Array<RawDraftInlineStyleRange>;
type RawStyleLists = Record<string, RawStyleList>;

const ensureRawStyleList = (rawStyles: RawStyleLists, style: string): RawStyleList => {
  const existingList = rawStyles[style];
  if (existingList) {
    return existingList;
  }
  const newList = Array<RawDraftInlineStyleRange>();
  rawStyles[style] = newList;
  return newList;
};

const encodeInlineStyleRanges = (block: ContentBlock): Array<RawDraftInlineStyleRange> => {
  const rawStyles: RawStyleLists = {};
  const lastRawStyles: Record<string, RawDraftInlineStyleRange> = {};
  const characterList = block.getCharacterList();
  const text = block.getText();

  block.findStyleRanges(
    (ch) => !ch.getStyle().isEmpty(),
    (start, end) => {
      const styles = characterList.get(start)?.getStyle() ?? Immutable.OrderedSet<string>();
      styles.forEach((style: string) => {
        const offset = strlen(text.slice(0, start));
        const length = strlen(text.slice(start, end));

        const rawStyleList = ensureRawStyleList(rawStyles, style);

        const lastRawStyle = lastRawStyles[style];
        if (!!lastRawStyle && lastRawStyle.offset + lastRawStyle.length === offset) {
          // Continue with the last
          lastRawStyle.length += length;
          return;
        }

        const rawStyle = {
          offset,
          length,
          style: style as DraftInlineStyleType,
        };
        rawStyleList.push(rawStyle);
        lastRawStyles[style] = rawStyle;
      });
    },
  );

  const result: RawDraftInlineStyleRange[] = [];
  for (const style in rawStyles) {
    if (Object.hasOwn(rawStyles, style)) {
      const rawStyleList = rawStyles[style];
      if (rawStyleList) {
        result.push(...rawStyleList);
      }
    }
  }
  return result;
};

const createRawBlock = memoize.weak((block: ContentBlock, entitiesBefore: number): RawBlock => {
  const entities = encodeEntityRanges(block, entitiesBefore);
  const rawBlock = {
    key: block.getKey(),
    text: block.getText(),
    type: getFullBlockType(block),
    depth: block.getDepth(),
    inlineStyleRanges: encodeInlineStyleRanges(block),
    entityRanges: entities.entityRanges,
    data: block.getData().toObject(),
  };
  return {
    rawBlock,
    entityKeys: entities.entityKeys,
  };
});

const encodeRawBlocks = (blocks: ReadonlyArray<ContentBlock>): ReadonlyArray<RawBlock> => {
  let totalEntities = 0;
  const rawBlocks = blocks.map((block: ContentBlock) => {
    const rawBlock = createRawBlock(block, totalEntities);
    totalEntities += rawBlock.entityKeys.size;
    return rawBlock;
  });
  return rawBlocks;
};

type EntityKeyMap = Immutable.Map<string, number>;

const encodeRawEntityMap = memoize.weak(
  (entityMap: EntityMap, entityKeyMap: EntityKeyMap): RawEntityMap => {
    const rawEntityMap: Mutable<RawEntityMap> = {};

    entityKeyMap.forEach((entityIndex: number, entityKey: string) => {
      const entity = entityMap.__get(entityKey);
      if (entity) {
        rawEntityMap[entityIndex] = {
          type: getEntityType(entity),
          mutability: entity.getMutability(),
          data: entity.getData(),
        };
      }
    });

    return rawEntityMap;
  },
);

const getEntityKeyMap = memoize.maxOne(
  (...entityKeys: ReadonlyArray<Immutable.List<string>>): EntityKeyMap => {
    let entityIndex = 0;
    const allEntityKeys = Immutable.Map<string, number>().withMutations((map) =>
      entityKeys.map((blockEntityKeys) =>
        blockEntityKeys.forEach((key: string) => {
          map.set(key, entityIndex++);
        }),
      ),
    );
    return allEntityKeys;
  },
);

export const convertFromDraftStateToRaw = (content: ContentState): RawDraftContentState => {
  const entityMap = getEntityMap(content);

  const blocks = encodeRawBlocks(getBlocks(content));
  const rawBlocks = blocks.map((block) => block.rawBlock);

  const blockEntityKeys = blocks.map((block) => block.entityKeys).filter((keys) => !!keys.size);
  const entityKeys = getEntityKeyMap(...blockEntityKeys);
  const rawEntityMap = encodeRawEntityMap(entityMap, entityKeys);

  return {
    blocks: rawBlocks,
    entityMap: rawEntityMap,
  };
};
