import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { isEmptyOrWhitespace } from '../../../../../../_shared/utils/stringUtils.ts';
import { LinkInfo } from '../../components/LinkInfo.tsx';

export interface IEmailLinkInfoProps {
  readonly disabled?: boolean;
  readonly emailAddress: string;
  readonly emailSubject: string;
}

export const EmailLinkInfo: React.FC<IEmailLinkInfoProps> = ({
  disabled,
  emailAddress,
  emailSubject,
}) =>
  disabled ? (
    <Box padding={Spacing.S}>
      <Stack spacing={Spacing.XS}>
        <LinkInfo>
          <LinkInfo.Label>Email:</LinkInfo.Label>
          <LinkInfo.Value>
            <a
              href={`mailto:${emailAddress}${emailSubject ? `?subject=${emailSubject}` : ''}`}
              rel="noopener noreferrer"
            >
              {emailAddress}
            </a>
          </LinkInfo.Value>
        </LinkInfo>
        {!isEmptyOrWhitespace(emailSubject) && (
          <LinkInfo>
            <LinkInfo.Label>Subject:</LinkInfo.Label>
            <LinkInfo.Value>
              <span title={emailSubject}>{emailSubject}</span>
            </LinkInfo.Value>
          </LinkInfo>
        )}
      </Stack>
    </Box>
  ) : (
    <a
      className="link-dialog__detail-link-pane"
      href={`mailto:${emailAddress}${emailSubject ? `?subject=${emailSubject}` : ''}`}
      rel="noopener noreferrer"
    >
      <div className="link-dialog__detail-link">{emailAddress}</div>
    </a>
  );

EmailLinkInfo.displayName = 'EmailLinkInfo';
