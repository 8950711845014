import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import { PublishedWorkflowStepId } from '../../../data/models/workflow/WorkflowStep.ts';
import { DefaultWorkflowId } from '../../constants/variantIdValues.ts';

export enum PublishedStepType {
  UniqueDefault = 'UniqueDefault',
  UniqueCustoms = 'UniqueCustom',
  Shared = 'Shared',
}

type PublishedStepTypeInIsolatedContext = Exclude<PublishedStepType, 'Shared'>;

const getPublishedStepTypeInIsolatedContext = (
  workflow: Workflow,
): PublishedStepTypeInIsolatedContext =>
  // in old projects (and sample projects copies), there are two IDs for published steps
  // - one belongs to any custom workflow and has a well known id (the condition below)
  // - the other belongs to the default workflow but doesn't have a well-known id (mutually exclusive with width precondition above)
  workflow.id === DefaultWorkflowId
    ? PublishedStepType.UniqueDefault
    : PublishedStepType.UniqueCustoms;

export const getPublishedStepTypeInProjectContext = (
  workflow: Workflow,
  defaultWorkflow: Workflow,
): PublishedStepType =>
  // in new projects, there is only one ID for all publish steps, they can be identified by default workflow having the well-known ID
  defaultWorkflow.publishedStep.id === PublishedWorkflowStepId
    ? PublishedStepType.Shared
    : getPublishedStepTypeInIsolatedContext(workflow);
