import {
  UrlSlugModeServerModel,
  UrlSlugTypeElementServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { UrlSlugMode } from '../../../../itemEditor/constants/urlSlugMode.ts';
import {
  IUrlSlugTypeElementData,
  getUrlSlugTypeElementDataFromServerModel,
} from '../../models/elements/UrlSlugTypeElementData.ts';
import { convertBaseTypeElementDataToServerModel } from './typeElementDataConverter.ts';
import { ITypeElementDataConverters } from './types/ITypeElementDataConverters.type.ts';
import { getIntegerFromString } from './utils/typeElementDataConvertersUtils.ts';

function convertTypeElementRecordToServerModel(
  typeElement: IUrlSlugTypeElementData,
): UrlSlugTypeElementServerModel {
  const maxChars = typeElement.maxChars && getIntegerFromString(typeElement.maxChars);
  const mode = typeElement.mode
    ? typeElement.mode === UrlSlugMode.Custom
      ? UrlSlugModeServerModel.Custom
      : UrlSlugModeServerModel.Auto
    : UrlSlugModeServerModel.Auto;

  return {
    ...convertBaseTypeElementDataToServerModel(typeElement),
    dependentStringTypeElementId: typeElement.dependedOnTextElementId,
    maxChars: maxChars && maxChars > 0 ? maxChars : null,
    mode,
    type: ElementType.UrlSlug,
    validationRegex: typeElement.validationRegex,
  };
}

export function createUrlSlugTypeElementDataConverter(): ITypeElementDataConverters {
  return {
    convertTypeElementServerModelToRecord: (serverTypeElement: UrlSlugTypeElementServerModel) =>
      getUrlSlugTypeElementDataFromServerModel(serverTypeElement),
    convertTypeElementRecordToServerModel: (typeElement: IUrlSlugTypeElementData) =>
      convertTypeElementRecordToServerModel(typeElement),
  };
}
