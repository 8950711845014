import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Size, Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { DataUiAction } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AssetFolderDeleteDialog } from '../../../containers/AssetFolders/AssetFolderDeleteDialog.tsx';
import { AssetFolderMoveDialog } from '../../../containers/AssetFolders/AssetFolderMoveDialog.tsx';
import { AssetListingInfoMessage } from '../../../containers/AssetListing/AssetListingInfoMessage.tsx';
import { AssetsMoveToCollectionDialog } from '../../../containers/AssetListing/AssetsMoveToCollectionDialog.tsx';
import { AssetsMoveToFolderDialog } from '../../../containers/AssetListing/AssetsMoveToFolderDialog.tsx';
import { AssignTermsAssetsDialog } from '../../../containers/AssetListing/AssignTermsAssetsDialog.tsx';
import { AssetFoldersBreadcrumbsInAssetSelector } from '../../../containers/Breadcrumbs/AssetFoldersBreadcrumbsInAssetSelector.tsx';
import { AssetFoldersBreadcrumbsInLibrary } from '../../../containers/Breadcrumbs/AssetFoldersBreadcrumbsInLibrary.tsx';
import { AssetsOrderBy } from '../../../types/orderBy.type.ts';
import { AssetListingActionPaneButtons } from './AssetListingActionPaneButtons.tsx';
import { BreadcrumbsWrapper } from './BreadcrumbsWrapper.tsx';

type AssetListingActionPaneProps = {
  readonly clearAssetSelection: () => void;
  readonly deleteActionTooltip: string | undefined;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly editActionTooltip: string | undefined;
  readonly folderActionTooltip: string | undefined;
  readonly isDeleteConfirmationRequired: boolean;
  readonly isInAssetSelector: boolean;
  readonly isUpdateActionInProgress: boolean;
  readonly onDeleteAssetFolder: (() => void) | null;
  readonly onDeleteAssets: (() => void) | null;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly showAssignTermsButton: boolean;
  readonly showMoveAssetsToCollectionButton: boolean;
  readonly showMoveAssetsToFolderButton: boolean;
  readonly showMoveFolderButton: boolean;
};

enum OpenedDialog {
  AssignTaxonomyTerms = 'AssignTaxonomyTerms',
  None = 'None',
  MoveAssetsToCollection = 'MoveAssetsToCollection',
  MoveAssetsToFolder = 'MoveAssetsToFolder',
  DeleteFolder = 'DeleteFolder',
  MoveFolder = 'MoveFolder',
}

const assetListingActionPaneButtonsSize = Size.S;

export const AssetListingActionPane: React.FC<AssetListingActionPaneProps> = ({
  clearAssetSelection,
  deleteActionTooltip,
  deselectAssets,
  editActionTooltip,
  folderActionTooltip,
  isDeleteConfirmationRequired,
  isInAssetSelector,
  isUpdateActionInProgress,
  onDeleteAssetFolder,
  onDeleteAssets,
  orderBy,
  selectedAssets,
  showAssignTermsButton,
  showMoveAssetsToCollectionButton,
  showMoveAssetsToFolderButton,
  showMoveFolderButton,
}) => {
  const [openedDialog, setOpenedDialog] = useState(OpenedDialog.None);

  const closeDialog = (): void => setOpenedDialog(OpenedDialog.None);

  const openAssetFolderDeletionConfirmationDialog = (): void =>
    setOpenedDialog(OpenedDialog.DeleteFolder);
  const openAssignTermsAssetsModal = showAssignTermsButton
    ? (): void => setOpenedDialog(OpenedDialog.AssignTaxonomyTerms)
    : null;
  const openMoveAssetsToCollectionModal = showMoveAssetsToCollectionButton
    ? (): void => setOpenedDialog(OpenedDialog.MoveAssetsToCollection)
    : null;
  const openMoveAssetsToFolderModal = showMoveAssetsToFolderButton
    ? (): void => setOpenedDialog(OpenedDialog.MoveAssetsToFolder)
    : null;
  const openMoveFolderModal = showMoveFolderButton
    ? (): void => setOpenedDialog(OpenedDialog.MoveFolder)
    : null;

  const onDeleteAssetFolderWithConfirmationHandling = onDeleteAssetFolder
    ? (): void => {
        if (isDeleteConfirmationRequired) {
          openAssetFolderDeletionConfirmationDialog();
        } else {
          onDeleteAssetFolder();
        }
      }
    : null;

  return (
    <>
      <Box marginTop={Spacing.XL} marginBottom={Spacing.L}>
        <Row alignY="center">
          <Column width="fit-content">
            <Box css={`margin-left: ${-Spacing.L}px`} minHeight={assetListingActionPaneButtonsSize}>
              <AssetListingInfoMessage
                orderBy={orderBy}
                selectedAssetsCount={selectedAssets.size}
                isAnyAssetActionAvailable={
                  showAssignTermsButton ||
                  showMoveAssetsToCollectionButton ||
                  showMoveAssetsToFolderButton ||
                  showMoveFolderButton
                }
              />
            </Box>
          </Column>

          <AssetListingActionPaneButtons
            areAllButtonsDisabled={isUpdateActionInProgress}
            deleteActionTooltip={deleteActionTooltip}
            editActionTooltip={editActionTooltip}
            folderActionTooltip={folderActionTooltip}
            onAssignTerms={openAssignTermsAssetsModal}
            onDeleteAssetFolder={onDeleteAssetFolderWithConfirmationHandling}
            onDeleteAssets={onDeleteAssets}
            onMoveAssetsToCollection={openMoveAssetsToCollectionModal}
            onMoveAssetsToFolder={openMoveAssetsToFolderModal}
            onMoveFolder={openMoveFolderModal}
            selectedAssetsCount={selectedAssets.size}
          />
        </Row>
      </Box>

      <BreadcrumbsWrapper>
        {isInAssetSelector ? (
          <AssetFoldersBreadcrumbsInAssetSelector
            clearAssetSelection={clearAssetSelection}
            orderBy={orderBy}
          />
        ) : (
          <AssetFoldersBreadcrumbsInLibrary clearAssetSelection={clearAssetSelection} />
        )}
      </BreadcrumbsWrapper>

      {openedDialog === OpenedDialog.AssignTaxonomyTerms && (
        <AssignTermsAssetsDialog
          closeDialog={closeDialog}
          deselectAssets={deselectAssets}
          isOpen
          selectedAssets={selectedAssets}
        />
      )}

      {openedDialog === OpenedDialog.MoveAssetsToFolder && (
        <AssetsMoveToFolderDialog
          closeDialog={closeDialog}
          dataUiAction={DataUiAction.MoveAssetsToFolder}
          deselectAssets={deselectAssets}
          label="Select where to move your assets:"
          primaryActionText="Move assets"
          orderBy={orderBy}
          selectedAssets={selectedAssets}
        />
      )}

      {openedDialog === OpenedDialog.MoveAssetsToCollection && (
        <AssetsMoveToCollectionDialog
          closeDialog={closeDialog}
          orderBy={orderBy}
          selectedAssets={selectedAssets}
        />
      )}

      {openedDialog === OpenedDialog.MoveFolder && (
        <AssetFolderMoveDialog
          closeDialog={closeDialog}
          dataUiAction={DataUiAction.MoveAssetFolder}
          label="Select where to move your folder and its content:"
          primaryActionText="Move folder"
        />
      )}

      {openedDialog === OpenedDialog.DeleteFolder && onDeleteAssetFolder && (
        <AssetFolderDeleteDialog closeDialog={closeDialog} onDelete={onDeleteAssetFolder} />
      )}
    </>
  );
};

AssetListingActionPane.displayName = 'AssetListingActionPane';
