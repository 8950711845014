export const localStorage = {
  get(key: string): string | null {
    return window.localStorage.getItem(key);
  },

  set(key: string, data: string): void {
    window.localStorage.setItem(key, data);
  },

  remove(key: string): void {
    window.localStorage.removeItem(key);
  },
};
