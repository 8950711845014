import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../stores/IStore.type.ts';
import {
  IWorkflowStatusSelectorSectionOwnProps,
  IWorkflowStatusSelectorSectionStateProps,
  WorkflowStatusSelectorSection as WorkflowStatusSelectorSectionComponent,
} from '../components/Elements/WorkflowStatusSelectorSection.tsx';
import { getSelectableWorkflowSteps } from '../selectors/getSelectableWorkflowSteps.ts';
import { isUpdatingWorkflowStepOrPublishing } from '../utils/changeWorkflowStepModalActionUtils.ts';
import { getFromWorkflowStep } from './selectors/getFromWorkflowStep.ts';

const mapStateToProps = (state: IStore): IWorkflowStatusSelectorSectionStateProps => {
  const { workflowStepAction, workflowStep } = state.contentApp.changeWorkflowStepModalData;

  return {
    fromWorkflowStep: getFromWorkflowStep(state),
    selectableSteps: getSelectableWorkflowSteps(state),
    selectedWorkflowStep: workflowStep,
    showConfigureWorkflowLink: isUpdatingWorkflowStepOrPublishing(workflowStepAction),
  };
};

export const WorkflowStatusSelectorSection: React.ComponentType<IWorkflowStatusSelectorSectionOwnProps> =
  connect(mapStateToProps)(WorkflowStatusSelectorSectionComponent);
