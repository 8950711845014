import { usePrevious } from '@kontent-ai/hooks';
import { EditorState } from 'draft-js';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GetEditorId } from '../../../editorCore/types/Editor.base.type.ts';
import { getAiSessionIdAtSelection } from '../../ai/utils/editorAiUtils.ts';
import { ActiveMenuContext } from '../instructions/ActiveMenuContext.tsx';
import {
  getInstructionMenuId,
  isInstructionMenuForEditor,
} from '../instructions/FinishedInstruction.tsx';

export const useActiveFinishedInstruction = (
  getEditorId: GetEditorId,
): {
  readonly activeFinishedInstructionSessionId: Uuid | null;
  readonly resetActiveFinishedInstruction: (aiSessionId: Uuid) => void;
  readonly updateActiveFinishedInstruction: (editorState: EditorState) => void;
} => {
  const { setActiveMenuId } = useContext(ActiveMenuContext);

  const [activeFinishedInstructionSessionId, setActiveFinishedInstructionSessionId] =
    useState<Uuid | null>(null);
  const lastActiveFinishedInstructionSessionId = usePrevious(activeFinishedInstructionSessionId);

  const autoMenuOnNextFocusedInstruction = useRef(true);
  useEffect(() => {
    if (!activeFinishedInstructionSessionId) {
      // If no instruction is focused, we hide the menu, but only in case instruction menu was displayed
      // We don't want to influence other potential menus
      setActiveMenuId((prev) => (isInstructionMenuForEditor(prev, getEditorId()) ? null : prev));
      return;
    }
    if (!autoMenuOnNextFocusedInstruction.current) {
      // Do not show menu at all if the last modification requested it. This is used in case we just submitted instruction
      autoMenuOnNextFocusedInstruction.current = true;
      return;
    }
    if (activeFinishedInstructionSessionId !== lastActiveFinishedInstructionSessionId) {
      // Another instruction focused - Show its menu
      setActiveMenuId(getInstructionMenuId(getEditorId(), activeFinishedInstructionSessionId));
    }
  }, [
    activeFinishedInstructionSessionId,
    getEditorId,
    lastActiveFinishedInstructionSessionId,
    setActiveMenuId,
  ]);

  const resetActiveFinishedInstruction = useCallback(
    (aiSessionId: Uuid) => {
      setActiveMenuId((prev) => (isInstructionMenuForEditor(prev, getEditorId()) ? null : prev));
      setActiveFinishedInstructionSessionId(aiSessionId);
      autoMenuOnNextFocusedInstruction.current = false;
    },
    [getEditorId, setActiveMenuId],
  );

  const updateActiveFinishedInstruction = useCallback((editorState: EditorState): void => {
    const selection = editorState.getSelection();
    if (!selection.getHasFocus()) {
      setActiveFinishedInstructionSessionId(null);
      return;
    }

    const content = editorState.getCurrentContent();
    const activeAiSessionId = getAiSessionIdAtSelection(content, selection);

    setActiveFinishedInstructionSessionId(activeAiSessionId);
  }, []);

  return {
    activeFinishedInstructionSessionId,
    resetActiveFinishedInstruction,
    updateActiveFinishedInstruction,
  };
};
