import { Key } from '@react-types/shared';
import { isItem } from '../../VerticalMenu/utils/utils.tsx';
import { ISelectItem, ISelectSection } from '../types.ts';

export const getExpandableItemIds = <TItem extends ISelectItem<TItem>>(
  items: ReadonlyArray<ISelectSection<TItem> | TItem>,
): ReadonlyArray<Key> =>
  items.reduce<Key[]>((result, item) => {
    if (item.items) {
      const keys = getExpandableItemIds(item.items);

      if (isItem(item)) {
        result.push(item.id);
      }

      result.push(...keys);
    }

    return result;
  }, []);
