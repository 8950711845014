import { History } from 'history';
import React, { useCallback } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../_shared/models/SidebarNames.ts';
import { INewSubscriptionFormShape } from '../../subscriptionManagement/SubscriptionListing/components/NewSubscriptionForm.tsx';
import { SubscriptionFormContextProvider } from '../../subscriptionManagement/shared/components/SubscriptionFormContext.tsx';
import { closeTrialActivationModal } from '../actions/projectsActions.ts';
import { activateTrialSubscription } from '../actions/thunkProjectsActions.ts';
import { TrialActivationModal } from '../components/TrialActivationModal.tsx';

const TrialActivationModalContainer: React.FC = () => {
  const showSidebar = useSelector((s) => {
    const { isVisible, sidebarName } = s.sharedApp.sidebarStatus;
    return isVisible && sidebarName === SidebarNames.TrialActivationModal;
  });
  const startingTrialInProgress = useSelector((s) => s.projectsApp.activatingTrial);

  const dispatch = useDispatch();
  const onClose = () => dispatch(closeTrialActivationModal());
  const onFormSubmit = useCallback(
    (values: INewSubscriptionFormShape, history: History) =>
      dispatch(activateTrialSubscription(values, history)),
    [],
  );

  return (
    <SubscriptionFormContextProvider>
      <TrialActivationModal
        onClose={onClose}
        onFormSubmit={onFormSubmit}
        showSidebar={showSidebar}
        startingTrialInProgress={startingTrialInProgress}
      />
    </SubscriptionFormContextProvider>
  );
};

TrialActivationModalContainer.displayName = 'TrialActivationModalContainer';

export { TrialActivationModalContainer as TrialActivationModal };
