import { Action } from '../../../../../../@types/Action.type.ts';
import {
  ContentItemEditing_Init_Started,
  ContentItemEditing_Sidebar_Initialized,
} from '../../../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

export function isInitialized(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentItemEditing_Sidebar_Initialized: {
      return true;
    }

    case ContentItemEditing_Init_Started: {
      return false;
    }

    default:
      return state;
  }
}
