import { memoize } from '@kontent-ai/memoization';
import { createGuid } from '@kontent-ai/utils';
import { ContentBlock, ContentState } from 'draft-js';
import Immutable from 'immutable';
import { filterOutNullishOrEmpty } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import { isContentComponent, isContentModule } from '../../../utils/blocks/blockTypeUtils.ts';
import {
  IRawBlock,
  RichTextCommentSegment,
  createRawBlock,
  getBlockDataValue,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  insertBlocksAtSelection,
  replaceBlock,
} from '../../../utils/general/editorContentUtils.ts';

const LinkedItemGuidKey = 'guid';

export function hasExistingModularContent(
  content: ContentState,
  loadedEntries: Immutable.Map<Uuid, IListingContentItem>,
): boolean {
  const blocks = getBlocks(content);
  return blocks.some((block: ContentBlock) => {
    if (isContentModule(block)) {
      const itemId = getModularContentItemId(block);
      const item = itemId && loadedEntries.get(itemId);
      return item && !item.item.archived;
    }
    return false;
  });
}

export function hasContentComponent(content: ContentState): boolean {
  return getBlocks(content).some(isContentComponent);
}

export function getModularContentItemId(block: ContentBlock): Uuid | null {
  return getBlockDataValue(block, LinkedItemGuidKey) ?? null;
}

// We memoize this method, because it is used from multiple places within the same React life cycle, it helps speed things up
export const getModularContentItemIds = memoize.weak(
  (content: ContentState): ReadonlyArray<Uuid> => {
    const contentItemIds = getBlocks(content).filter(isContentModule).map(getModularContentItemId);

    return filterOutNullishOrEmpty(contentItemIds);
  },
);

function getModularContentRawBlocks(contentItemIds: UuidArray): ReadonlyArray<IRawBlock> {
  const blocks = contentItemIds.map((id: string) => {
    const block: IRawBlock = createRawBlock({
      type: BlockType.ContentModule,
      data: {
        [LinkedItemGuidKey]: id,
        [RichTextCommentSegment]: createGuid(),
      },
    });
    return block;
  });
  return blocks;
}

export function insertModularContentItemsToPlaceholder(
  input: IContentChangeInput,
  placeholderBlockKey: string,
  contentItemIds: UuidArray,
): IContentChangeResult {
  const rawBlocks = getModularContentRawBlocks(contentItemIds);

  return replaceBlock(input, placeholderBlockKey, rawBlocks);
}

export function insertMultipleModularContentItems(
  input: IContentChangeInput,
  contentItemIds: UuidArray,
): IContentChangeResult {
  if (!contentItemIds.length) {
    return input;
  }

  const blocks = getModularContentRawBlocks(contentItemIds);

  return insertBlocksAtSelection(input, blocks);
}
