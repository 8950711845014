import { Action } from '../../../../../@types/Action.type.ts';
import {
  AssetLibrary_AssetEditorDialog_InitFinished,
  AssetLibrary_AssetEditorDialog_InitStarted,
  AssetLibrary_AssetEditor_InitFinished,
  AssetLibrary_AssetEditor_InitStarted,
  AssetLibrary_AssetEditor_SaveFinished,
  AssetLibrary_Asset_CodenameChanged,
  AssetLibrary_Asset_Edit,
  AssetLibrary_Asset_TitleChanged,
  AssetLibrary_EditedAsset_DeletionStarted,
} from '../../constants/assetLibraryActionTypes.ts';

export function isEditedAssetModified(state: boolean = false, action: Action): boolean {
  switch (action.type) {
    case AssetLibrary_AssetEditor_InitStarted:
    case AssetLibrary_AssetEditor_InitFinished:
    case AssetLibrary_AssetEditor_SaveFinished:
    case AssetLibrary_AssetEditorDialog_InitStarted:
    case AssetLibrary_AssetEditorDialog_InitFinished:
    case AssetLibrary_EditedAsset_DeletionStarted: {
      return false;
    }

    case AssetLibrary_Asset_TitleChanged:
    case AssetLibrary_Asset_CodenameChanged:
    case AssetLibrary_Asset_Edit: {
      return true;
    }

    default:
      return state;
  }
}
