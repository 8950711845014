import { useObserveElementPresence } from '@kontent-ai/hooks';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { StatusBarRightPaneId } from '../../../../_shared/components/StatusBar/StatusBarContainer.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OperationStatus } from '../../../../_shared/models/OperationStatus.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/selectors/Workflows/isMultipleWorkflowsConfigurationVisible.ts';
import { createWorkflowEditingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { initWorkflowCreating, saveWorkflowToServer } from '../actions/thunkWorkflowActions.ts';
import { leaveWorkflowEditor } from '../actions/workflowActions.ts';
import { WorkflowEditor } from '../components/WorkflowEditor.tsx';
import { WorkflowSettingsPage } from '../components/WorkflowSettingsPage.tsx';
import { hasWorkflowEditorUnsavedChanges } from '../utils/hasWorkflowEditorUnsavedChanges.ts';
import { WorkflowCreatorToolbarActions } from './WorkflowCreatorToolbarActions.tsx';

export const WorkflowCreator = () => {
  const [isInitThunkDone] = useThunkPromise(initWorkflowCreating);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(leaveWorkflowEditor());
    };
  }, []);

  const history = useHistory();

  const workflowName = useSelector((s) => s.workflowsApp.editorUi.editedWorkflowName);
  const hasUnsavedChanges = useSelector(hasWorkflowEditorUnsavedChanges);
  const isBeingSaved = useSelector(
    (s) => s.projectsApp.savingStatus.workflow.status === OperationStatus.Pending,
  );
  const allSet = useSelector(
    (s) =>
      isInitThunkDone && s.workflowsApp.editorUi.workflowLoadingStatus === LoadingStatus.Loaded,
  );
  const isAdvancedEditorEnabled = useSelector(isMultipleWorkflowsConfigurationVisible);

  const subscription = useSelector(getSelectedSubscription);
  const project = useSelector(getCurrentProject);

  const statusBarRightPaneRef = useObserveElementPresence(StatusBarRightPaneId);

  return (
    <WorkflowSettingsPage>
      {allSet ? (
        <>
          {statusBarRightPaneRef.current &&
            createPortal(<WorkflowCreatorToolbarActions />, statusBarRightPaneRef.current)}
          <WorkflowEditor
            hasUnsavedChanges={hasUnsavedChanges}
            isAdvancedEditorEnabled={isAdvancedEditorEnabled}
            isBeingSaved={isBeingSaved}
            isCentered={isAdvancedEditorEnabled}
            pageTitle={isAdvancedEditorEnabled ? workflowName : undefined}
            onSaveWorkflow={async (): Promise<void> => {
              try {
                const savedWorkflow = await dispatch(saveWorkflowToServer());
                history.push(
                  createWorkflowEditingLink({
                    projectId: project.projectId,
                    subscriptionId: subscription?.subscriptionId,
                    workflowId: savedWorkflow.id,
                  }),
                );
              } catch {
                return;
              }
            }}
            onUnsavedNavigation={async (onSuccess, onFail) => {
              try {
                await dispatch(saveWorkflowToServer());
                onSuccess();
              } catch {
                onFail();
              }
            }}
          />
        </>
      ) : (
        <Loader />
      )}
    </WorkflowSettingsPage>
  );
};
