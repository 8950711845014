import { Collection } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  VariantTranslationTaskId,
  makeVariantTranslationTaskId,
} from '../../../../_shared/utils/translations/variantTranslationTaskId.ts';
import {
  ContentItemEditing_VariantTranslation_Finished,
  ContentItemEditing_VariantTranslation_Started,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';

const initialState: ReadonlySet<VariantTranslationTaskId> = new Set();

export function variantTranslationTaskIdsInProgress(
  state = initialState,
  action: Action,
): ReadonlySet<VariantTranslationTaskId> {
  switch (action.type) {
    case ContentItemEditing_VariantTranslation_Started: {
      const taskId = makeVariantTranslationTaskId(
        action.payload.projectEnvironmentId,
        action.payload.itemId,
        action.payload.variantId,
      );

      return Collection.add(state, taskId);
    }

    case ContentItemEditing_VariantTranslation_Finished: {
      const taskId = makeVariantTranslationTaskId(
        action.payload.projectEnvironmentId,
        action.payload.itemId,
        action.payload.variantId,
      );

      return Collection.remove(state, taskId);
    }

    default: {
      return state;
    }
  }
}
