import { useCallback } from 'react';
import { AiActionName } from '../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createCancelOperationParams } from '../../../../../repositories/serverModels/ai/AiServerModels.cancelOperation.ts';
import { AiActionNameToParameters } from '../../../../../repositories/serverModels/ai/AiServerModels.params.ts';
import { isFinished } from '../../../../../repositories/serverModels/ai/AiServerModels.response.ts';
import { useAiTaskManager } from '../../../../contexts/AiTaskManagerProvider.tsx';
import { repositoryCollection } from '../../../../repositories/repositories.ts';
import {
  AiActionNameToMessagePayload,
  AnyAiMessagePayload,
} from '../../../../services/signalR/signalRClient.type.ts';
import { AiTaskMeta } from '../../../../utils/aiTasks/aiTaskManager.ts';
import { MessageThrottleWaitMs } from '../../constants/uiConstants.ts';
import { OnAiTaskUpdate } from '../../types/OnAiTaskUpdate.type.ts';
import { throttleUntil } from '../../utils/throttleUntil.ts';
import { MatchAiTask } from './matchAiTask.type.ts';

/**
 * Cancels a previously-run task and notifies backend about cancellation intent
 *
 * @param matchAiTask A function that selects relevant tasks only (typically based on action type)
 */
export type Cancel = (matchAiTask: MatchAiTask) => Promise<void>;

/**
 * Invoking an instance of this type is to represent a will of the hook user to process task even
 * not rendered at all.
 *
 * Intended usage:
 * ```ts
 *   useEffect(() => {
 *     const matchAiTask: MatchAiTask = (aiTasks) => {
 *       return (
 *         aiTasks.find(<relevant tasks>) ?? null
 *       );
 *     };
 *
 *     aiTaskTools.takeOver(matchAiTask, …`);
 *
 *     return () => {
 *       aiTaskTools.handOver(matchAiTask, (messages, context) => {
 *         <expect component content not to be visible or rendered>;
 *         return { isFinished };
 *       });
 *     };
 *   }, []);
 * ```
 *
 *  @param matchAiTask A function that selects relevant AI tasks only (typically based on at least action type)
 *  @param onUpdateInBackground A callback that is called when the hook is not rendered and new AI message related to the matching AI task is received.
 *  @returns {AiTaskMeta}
 *  @see `useAiTasks.uitest.tsx`
 */
export type HandOver = (
  matchAiTask: MatchAiTask,
  onUpdateInBackground: OnAiTaskUpdate<AiActionNameToMessagePayload[AiActionName]>,
) => void;

/**
 * Creates a new task for given AI action
 *
 *  @param actionName A name of the action that should be started
 *  @param actionParameters A set of parameters required for the action to run
 *  @param onUpdateInForeground A callback that is called when the hook is rendered and new AI message related to the AI task is received.
 *  @param aiTaskContext Any additional context client was to receive back with each result
 *  @returns a cancellable promise of request starting the task
 */
export type Run = <TActionName extends AiActionName>(
  actionName: TActionName,
  actionParameters: AiActionNameToParameters[TActionName],
  onUpdateInForeground: OnAiTaskUpdate<AiActionNameToMessagePayload[TActionName]>,
  aiTaskContext?: ReadonlyRecord<string, unknown>,
) => Promise<{
  readonly cancel: () => Promise<void>;
}>;

/**
 * Invoking an instance of this type is to represent a will of the hook user to render incoming
 * messages (re-render its content) based on AI tasks received while running an AI action.
 *
 * Intended usage:
 * ```ts
 *   useEffect(() => {
 *     const matchAiTask: MatchAiTask = (aiTasks) => {
 *       return (
 *         aiTasks.find(<relevant tasks>) ?? null
 *       );
 *     };
 *
 *     aiTaskTools.takeOver(matchAiTask, (messages, context) => {
 *       <update component's data>
 *       return { isFinished };
 *     });
 *
 *     return () => {
 *       aiTaskTools.handOver(matchAiTask, …);
 *     };
 *   }, []);
 * ```
 *
 *  @param matchAiTask A function that selects relevant tasks only (typically based on action type)
 *  @param onUpdateInForeground A callback that is called when the hook is rendered and new AI message related to the AI task is received.
 *  @returns {AiTaskMeta}
 *  @see `useAiTasks.uitest.tsx`
 */
export type TakeOver = (
  matchAiTask: MatchAiTask,
  onUpdateInForeground: OnAiTaskUpdate<AnyAiMessagePayload>,
) => AiTaskMeta | null;

type UseAiTaskResult = {
  // cancels a previously-run task and notifies backend about cancellation intent
  readonly cancel: Cancel;
  // when invoked, registers provided callback to tasks matching the criteria (see HandOver)
  readonly handOver: HandOver;
  // creates a new task for given action
  readonly run: Run;
  // when invoked, registers provided callback to tasks matching the criteria (see TakeOver)
  readonly takeOver: TakeOver;
};

/**
 * A hook that can manage AI tasks. It does not store any information about the task. It serves as a
 * React facade over the AI task manager. Hook also controls how often it notifies its users about a
 * change by throttling how often the `onUpdateInForeground` value is invoked.
 *
 * @returns {UseAiTaskResult}
 */
export const useAiTasks = (): UseAiTaskResult => {
  const aiTaskManager = useAiTaskManager();

  const cancel = useCallback<Cancel>(
    async (matchAiTask) => {
      const aiTask = matchAiTask(aiTaskManager.getAllTasks());
      if (!aiTask) return;
      await aiTaskManager.cancel(aiTask.id, (operationId) =>
        repositoryCollection.aiRepository.cancelAction(createCancelOperationParams(operationId)),
      );
    },
    [aiTaskManager],
  );

  const handOver = useCallback<HandOver>(
    (matchAiTask, onUpdateInBackground) => {
      const aiTask = matchAiTask(aiTaskManager.getAllTasks());
      if (aiTask) aiTaskManager.changeProcessMessagesCallback(aiTask.id, onUpdateInBackground);
    },
    [aiTaskManager],
  );

  const run = useCallback<Run>(
    async (actionName, actionParameters, onUpdateInForeground, aiTaskContext) => {
      const updateInForegroundThrottled = throttleUntil(
        onUpdateInForeground,
        (message) => !message.some(isFinished),
        MessageThrottleWaitMs,
      );

      const aiTaskId = await aiTaskManager.createTask(
        actionName,
        actionParameters,
        (parameters) => repositoryCollection.aiRepository.createAction(parameters),
        updateInForegroundThrottled,
        aiTaskContext,
      );

      return {
        cancel: async () => {
          await aiTaskManager.cancel(aiTaskId, (operationId) =>
            repositoryCollection.aiRepository.cancelAction(
              createCancelOperationParams(operationId),
            ),
          );
        },
      };
    },
    [aiTaskManager],
  );

  const takeOver = useCallback<TakeOver>(
    (matchAiTask, onUpdateInForeground) => {
      const aiTask = matchAiTask(aiTaskManager.getAllTasks());
      if (!aiTask) return null;
      const currentTaskActionStatus = aiTaskManager.getTaskActionStatus(aiTask.id);
      const currentTaskActionMessages = aiTaskManager.getTaskActionMessages(aiTask.id);
      if (!currentTaskActionStatus || !currentTaskActionMessages) return null;
      const updateInForegroundThrottled = throttleUntil(
        onUpdateInForeground,
        (message) => !message.some(isFinished),
        MessageThrottleWaitMs,
      );
      aiTaskManager.changeProcessMessagesCallback(aiTask.id, updateInForegroundThrottled);
      return aiTask;
    },
    [aiTaskManager],
  );

  return {
    cancel,
    handOver,
    run,
    takeOver,
  };
};
