import { useAttachRef, useEnsuredContext } from '@kontent-ai/hooks';
import { AriaMenuItemProps, useMenuItem } from '@react-aria/menu';
import { mergeProps } from '@react-aria/utils';
import React, { PropsWithChildren, ComponentProps } from 'react';
import { MenuItem as MenuItemComponent } from '../../MenuItem/MenuItem.tsx';
import { MenuItemState } from '../../MenuItem/menuItemState.ts';
import { VerticalMenuItem } from '../../VerticalMenu/types.ts';
import { VerticalMenuState } from '../../VerticalMenu/useNonAccessibleVerticalMenu.ts';
import { DescendantContext, useDescendant } from '../contexts/DescendantContext.tsx';

type InitializedMenuItemProps = MenuItemProps &
  Readonly<{
    menuItemState: MenuItemState;
    state: VerticalMenuState<any>;
  }>;

const InitializedMenuItem = React.forwardRef<
  HTMLDivElement | HTMLAnchorElement,
  InitializedMenuItemProps
>(
  (
    { id, closeOnSelect, menuItemState = 'default', onAction, state, ...otherProps },
    forwardedRef,
  ) => {
    const { refObject, refToForward } = useAttachRef<HTMLDivElement | HTMLAnchorElement>(
      forwardedRef,
    );

    const { menuItemProps } = useMenuItem(
      {
        key: id,
        onAction,
        closeOnSelect,
      },
      state,
      refObject,
    );

    const selectionManager = state.getSelectionManager();
    const isFocused = selectionManager.focusedKey === id;

    return (
      <MenuItemComponent
        isHovered={isFocused}
        menuItemState={menuItemState}
        ref={refToForward}
        text={otherProps.label}
        {...mergeProps(menuItemProps, otherProps)}
      />
    );
  },
);

export type MenuItemProps = Pick<VerticalMenuItem<any>, 'id' | 'label'> &
  Omit<
    ComponentProps<typeof MenuItemComponent>,
    'disabledTooltipText' | 'menuItemState' | 'onPress' | 'text'
  > &
  Pick<AriaMenuItemProps, 'closeOnSelect'> &
  Readonly<{
    menuItemState?: MenuItemState;
    onAction?: () => void;
  }>;

export const MenuItem = React.forwardRef<
  HTMLDivElement | HTMLAnchorElement,
  PropsWithChildren<MenuItemProps>
>(({ menuItemState = 'default', ...otherProps }, forwardedRef) => {
  const descendantContext = useEnsuredContext(DescendantContext);

  const { initializedRef, uninitializedRef } = useDescendant<HTMLDivElement | HTMLAnchorElement>(
    otherProps,
    forwardedRef,
  );

  if (descendantContext.state.collection.getItem(otherProps.id)) {
    return (
      <InitializedMenuItem
        menuItemState={menuItemState}
        state={descendantContext.state}
        ref={initializedRef}
        {...otherProps}
      />
    );
  }

  const { onAction, ...propsWithoutOnAction } = otherProps;

  return (
    <MenuItemComponent
      menuItemState={menuItemState}
      text={otherProps.label}
      ref={uninitializedRef}
      {...propsWithoutOnAction}
    />
  );
});
