import { ItemId } from '@kontent-ai/component-library/Selects';
import React, { ComponentProps, RefObject } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  getOriginalItemId,
  getSectionItemId,
} from '../../../../../_shared/utils/component-library/selectSections.ts';
import { newContentItemSelectedContentTypeChanged } from '../actions/newContentItemActions.ts';
import { validateSelectedValues } from '../actions/thunkNewContentItemActions.ts';
import { ContentTypeSelector } from '../components/ContentTypeSelector.tsx';
import { ContentTypeSection } from '../constants/contentTypeSection.ts';
import { disabledContentTypeSelectorTooltip } from '../constants/uiConstants.ts';
import { getContentTypeOptions } from '../selectors/getContentTypeOptions.ts';
import { getContentTypeSelectorState } from '../selectors/getContentTypeSelectorState.ts';
import { getNewContentItemForm } from '../selectors/newContentItemDialog.ts';
import { ContentTypeSelectorState } from '../utils/getContentTypeSelectorState.ts';

type Props = {
  readonly disabledTooltip: string | undefined;
  readonly index: number | undefined;
  readonly inputRef?: RefObject<HTMLInputElement>;
};

const ContentTypeSelectorContainer: React.FC<Props> = ({ disabledTooltip, index, inputRef }) => {
  const options = useSelector(getContentTypeOptions);
  const isWithoutSections = useSelector(
    (s) => getContentTypeSelectorState(s) === ContentTypeSelectorState.ShowAllOnly,
  );
  const isOnlyOneContentTypeAvailable =
    options.find((option) => option.id === ContentTypeSection.AllTypes)?.items.length === 1;
  const disabledTooltipText =
    disabledTooltip ||
    (isOnlyOneContentTypeAvailable ? disabledContentTypeSelectorTooltip : undefined);
  const isSelectedValueShown = !disabledTooltipText || isOnlyOneContentTypeAvailable;
  const selectedTypeId = useSelector(getSelectedTypeId(isSelectedValueShown));

  const dispatch = useDispatch();

  const selectType = (contentTypeId: Uuid | null, contentTypeSection: ContentTypeSection) => {
    dispatch(newContentItemSelectedContentTypeChanged(contentTypeId, contentTypeSection));
    dispatch(validateSelectedValues());
  };

  const handleTypeSelection: ComponentProps<typeof ContentTypeSelector>['onTypeSelection'] = (
    itemId,
  ) => {
    const originalItemId = itemId && getOriginalItemId(itemId);

    if (isWithoutSections) {
      selectType(originalItemId, ContentTypeSection.None);
    } else if (itemId) {
      const section = options.find((s) => s.items.some((item) => item.id.endsWith(itemId)));
      if (section) {
        selectType(originalItemId, section.id as ContentTypeSection);
      }
    }
  };

  return (
    <ContentTypeSelector
      disabledTooltip={disabledTooltipText}
      index={index}
      onTypeSelection={handleTypeSelection}
      selectedTypeId={selectedTypeId}
      selectItems={options}
      inputRef={inputRef}
    />
  );
};

ContentTypeSelectorContainer.displayName = 'ContentTypeSelectorContainer';

export { ContentTypeSelectorContainer as ContentTypeSelector };

const getSelectedTypeId =
  (isSelectedValueShown: boolean) =>
  (state: IStore): ItemId | null => {
    if (!isSelectedValueShown) {
      return null;
    }

    const { contentTypeId, contentTypeSection } = getNewContentItemForm(state);

    return contentTypeId && getSectionItemId(contentTypeId, contentTypeSection);
  };
