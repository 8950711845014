import { memoize } from '@kontent-ai/memoization';
import { ContentBlock } from 'draft-js';
import Immutable from 'immutable';
import { NextBlockType, PreviousBlockType } from './blockData.ts';
import { AdjacentBlockType, BaseBlockType, BlockType, getBaseType } from './blockType.ts';

// Gets the full block type stored in the data, which includes parent types for nested blocks
export const getFullBlockType = memoize.weak(
  (block: ContentBlock): BlockType => block.getType() as any,
);

// Gets only the base type of the block (image / paragraph etc.)
export const getBaseBlockType = memoize.weak(
  (block: ContentBlock): BaseBlockType => getBaseType(getFullBlockType(block)),
);

export const getBlockLength = memoize.weak((block: ContentBlock): number => block.getLength());

export const getBlockData = memoize.weak(
  (block: ContentBlock): Immutable.Map<any, any> => block.getData(),
);

export const getPreviousBlockType = memoize.weak((block: ContentBlock): AdjacentBlockType | null =>
  getBlockData(block).get(PreviousBlockType),
);

export const getNextBlockType = memoize.weak((block: ContentBlock): AdjacentBlockType | null =>
  getBlockData(block).get(NextBlockType),
);

export const getBlockKey = memoize.weak((block: ContentBlock): string => block.getKey());
