import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { ICollection } from '../../../data/models/collections/Collection.ts';

export type CollectionOption = ICollection & ISelectItem<CollectionOption>;

export const mapCollectionToOption = (collection: ICollection): CollectionOption => {
  return {
    ...collection,
    label: collection.name,
  };
};
