import { matchAnyTranslateDescriptionAiTasksInProgress } from '../../../applications/contentInventory/assets/utils/matchAnyTranslateDescriptionAiTasksInProgress.tsx';
import { matchDescribeImageAiTasks } from '../../../applications/contentInventory/assets/utils/matchDescribeImageAiTasks.tsx';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../features/AI/hooks/aiTasks/useAiTaskProgress.ts';

export const useIsAiWorkingOnImageDescription = (assetId: Uuid) => {
  const describeImageProgress = useAiTaskProgress(matchDescribeImageAiTasks(assetId));
  const translateDescriptionProgress = useAiTaskProgress(
    matchAnyTranslateDescriptionAiTasksInProgress(assetId),
  );

  return (
    describeImageProgress === AiTaskProgress.Running ||
    translateDescriptionProgress === AiTaskProgress.Running
  );
};
