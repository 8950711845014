import { Action } from '../../../../@types/Action.type.ts';
import { ContentListing_Init_Started } from '../../../contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { ContentItemEditing_Init_Started } from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentRevisions_RevisionLoaded } from '../../features/Revisions/constants/revisionActionTypes.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';

const initialState: ReadonlyArray<ICompiledContentItemElementData> | null = null;

export function selectedRevisionElements(
  state = initialState,
  action: Action,
): ReadonlyArray<ICompiledContentItemElementData> | null {
  switch (action.type) {
    case ContentRevisions_RevisionLoaded:
      return action.payload.elements;

    case ContentItemEditing_Init_Started:
    case ContentListing_Init_Started:
      return initialState;

    default:
      return state;
  }
}
