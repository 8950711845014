import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { EditableTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { CommentsContextProvider } from '../../../components/CommentsContext.tsx';
import { getItemElementEditComponent } from '../../../utils/itemElementEditComponentCreator.tsx';

interface IEditableContentItemElementProps {
  readonly canEdit: boolean;
  readonly typeElement: EditableTypeElement;
}

export const EditableContentItemElement: React.FC<IEditableContentItemElementProps> = ({
  canEdit,
  typeElement,
}): JSX.Element | null => {
  const currentUserId = useSelector((state) => state.data.user.info.userId);

  const elementId = typeElement.elementId;

  const isLocked = useSelector(
    (state) =>
      !!state.contentApp.editorUi.lockedElements.find(
        (lockedElement) =>
          !!lockedElement &&
          lockedElement.elementId === elementId &&
          lockedElement.userId !== currentUserId,
      ),
  );
  const isOutdated = useSelector((state) =>
    state.contentApp.editorUi.outdatedElementIds.contains(elementId),
  );

  const ElementComponent = getItemElementEditComponent(typeElement.type);

  return (
    <CommentsContextProvider elementCanReceiveComments={!isLocked && !isOutdated}>
      <ElementComponent
        typeElement={typeElement}
        autoFocus={false}
        disabled={!canEdit || isLocked || isOutdated}
      />
    </CommentsContextProvider>
  );
};

EditableContentItemElement.displayName = 'EditableContentItemElement';
