import { DateTime, createDateTime } from '../../../../_shared/models/DateTime.ts';
import { DefaultDisplayTimeZoneId } from '../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.type.ts';

type DateTimeItemElementValue = {
  readonly _dateTime: DateTime;
  readonly displayTimeZone: string;
  readonly value: DateTimeStamp;
};

export type DateTimeItemElement = DateTimeItemElementValue & ICompiledContentItemElementData;

export const EmptyDateTimeItemElementValue: DateTimeItemElementValue = {
  _dateTime: createDateTime(),
  displayTimeZone: DefaultDisplayTimeZoneId,
  value: '',
};

export const EmptyDateTimeItemElement: DateTimeItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.DateTime,
  ...EmptyDateTimeItemElementValue,
};

export const newDateTimeElement = (params: Partial<DateTimeItemElement>): DateTimeItemElement => ({
  ...EmptyDateTimeItemElement,
  ...params,
});
