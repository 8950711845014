import { Callout } from '@kontent-ai/component-library/Callout';
import React, { PropsWithChildren, useEffect } from 'react';
import { DialogState } from '../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { IPopoverTargetProps } from '../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import {
  IPopoverDialogConfig,
  usePopoverDialog,
} from '../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { addFlipping } from '../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

const FriendlyWarningPopoverCalloutMaxWidth = 480;

interface IFriendlyWarningPopover
  extends PropsWithChildren<Omit<IPopoverDialogConfig, 'children' | 'dialogState'>> {
  readonly renderTarget: (targetProps: IPopoverTargetProps) => React.ReactElement;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export const FriendlyWarningPopover: React.FC<React.PropsWithChildren<IFriendlyWarningPopover>> = ({
  children,
  isOpen,
  onClose,
  renderTarget,
  ...popoverConfig
}) => {
  const {
    PopoverDialog,
    popoverDialogProps,
    isOpen: isOpenRead,
    openDialog,
    closeDialog,
    targetProps,
  } = usePopoverDialog({
    ...popoverConfig,
    onClose,
    dialogState: DialogState.Default,
    adjustTippyOptions: (tippyOptions) => {
      const preparedOptions = popoverConfig.adjustTippyOptions?.(tippyOptions) ?? tippyOptions;
      return addFlipping(preparedOptions);
    },
  });

  useEffect(() => {
    if (isOpenRead !== isOpen) {
      if (isOpen) {
        openDialog();
      } else {
        closeDialog();
      }
    }
  }, [closeDialog, isOpen, isOpenRead, openDialog]);

  return (
    <>
      {renderTarget(targetProps)}
      {isOpen && (
        <PopoverDialog
          {...popoverDialogProps}
          {...getDataUiElementAttribute(DataUiElement.Popover)}
        >
          <Callout calloutType="friendlyWarning" maxWidth={FriendlyWarningPopoverCalloutMaxWidth}>
            {children}
          </Callout>
        </PopoverDialog>
      )}
    </>
  );
};

FriendlyWarningPopover.displayName = 'FriendlyWarningPopover';
