import { RadioGroupState } from '@react-stately/radio';
import React, { useMemo } from 'react';

type RadioGroupContext = {
  readonly state: RadioGroupState;
};

export const RadioGroupContext = React.createContext<RadioGroupContext | undefined>(undefined);

type RadioGroupContextProviderProps = RadioGroupContext;

export const RadioGroupContextProvider: React.FC<
  React.PropsWithChildren<RadioGroupContextProviderProps>
> = ({ children, state }) => {
  const contextValue = useMemo(
    () => ({
      state,
    }),
    [state],
  );

  return <RadioGroupContext.Provider value={contextValue}>{children}</RadioGroupContext.Provider>;
};

RadioGroupContextProvider.displayName = 'RadioGroupContextProvider';
