import styled, { css } from 'styled-components';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { transition250 } from '../../../tokens/quarks/transitions.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { CheckboxStylingProps, getCheckboxTokens } from '../utils/stylingUtils.ts';

type StyledCheckboxWrapper = CheckboxStylingProps & {
  readonly isFocusVisible?: boolean;
};

export const StyledCheckboxWrapper = styled.label.attrs(getCheckboxTokens)<StyledCheckboxWrapper>`
  ${({ backgroundColor, backgroundColorHover, checkboxState, isFocusVisible }) => css`
    display: block;
    position: relative;
    border-radius: ${px(BorderRadius.S)};
    background-color: ${backgroundColor};
    
    cursor: ${checkboxState !== 'disabled' ? 'pointer' : 'not-allowed'};
    transition: background-color ${transition250};
    
    ${
      checkboxState !== 'disabled' &&
      css`
      &:hover {
        background-color: ${backgroundColorHover};
      }
    `
    };
    
    ${isFocusVisible && shadowFocusStyles};
  `};
`;
