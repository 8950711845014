import { Action } from '../../../../../@types/Action.type.ts';
import { Shared_CurrentProjectId_Updated } from '../../../../../_shared/constants/sharedActionTypes.ts';
import {
  Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Started,
  Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Success,
} from '../../../../contentInventory/shared/constants/contentInventoryActionTypes.ts';
import { UserDetail_UpdateCollectionGroups_Finished } from '../../../../environmentSettings/users/constants/usersActionTypes.ts';
import { Workflow_Editing_WorkflowSaveFinished } from '../../../../environmentSettings/workflow/constants/workflowActionTypes.ts';

const initialState: Uuid | null = null;

export function firstWorkflowStepCurrentRoleCanWorkWithId(
  state: Uuid | null = initialState,
  action: Action,
): Uuid | null {
  switch (action.type) {
    case Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Started:
    case Shared_CurrentProjectId_Updated:
    case Workflow_Editing_WorkflowSaveFinished:
    case UserDetail_UpdateCollectionGroups_Finished:
      return initialState;

    case Content_LoadFirstWorkflowStepCurrentRoleCanWorkWith_Success: {
      return action.payload.stepId;
    }

    default:
      return state;
  }
}
