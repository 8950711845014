import { ThunkFunction } from '../../../@types/Dispatcher.type.ts';
import { itemLiveUsersChanged } from '../../../applications/itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { Notifications } from '../../services/signalR/signalRClient.type.ts';
import { getEditedContentItemIdFromPath } from '../../utils/routing/routeTransitionUtils.ts';

export const onItemLiveUsersChange =
  (params: Notifications['itemLiveUsersChange']['payload']): ThunkFunction =>
  (dispatch) => {
    const currentContentItemId = getEditedContentItemIdFromPath(self.location.pathname);

    if (
      currentContentItemId &&
      params.itemId === currentContentItemId.itemId &&
      params.variantId === currentContentItemId.variantId
    ) {
      dispatch(itemLiveUsersChanged(new Set(params.itemLiveUserIds)));
    }
  };
