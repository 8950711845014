export const Calendar_ArrangementSelected = 'Calendar_ArrangementSelected';
export const Calendar_DrillDownDateSelected = 'Calendar_DrillDownDateSelected';
export const Calendar_Initialization_Finished = 'Calendar_Initialization_Finished';
export const Calendar_Initialization_Started = 'Calendar_Initialization_Started';
export const Calendar_ItemDetails_NavigateBack = 'Calendar_ItemDetails_NavigateBack';
export const Calendar_ItemDetailsSelected = 'Calendar_ItemDetailsSelected';
export const Calendar_LoadItemsWithVariantActiveInPeriod_Finished =
  'Calendar_LoadItemsWithVariantActiveInPeriod_Finished';
export const Calendar_LoadItemsWithVariantActiveInPeriod_Started =
  'Calendar_LoadItemsWithVariantActiveInPeriod_Started';
export const Calendar_Page_Left = 'Calendar_Page_Left';
export const Calendar_PopupClosed = 'Calendar_PopupClosed';
