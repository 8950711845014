import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { WebSpotlight_Space_Selected } from '../../constants/webSpotlightActionTypes.ts';

type Deps = {
  readonly loadWebSpotlightTree: (
    rootItemId: Uuid,
    spaceId: Uuid | undefined,
    path: string,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
};

const spaceSelected = (spaceId: Uuid | null, rootItemId: Uuid | null) =>
  ({
    type: WebSpotlight_Space_Selected,
    payload: {
      spaceId,
      rootItemId,
    },
  }) as const;

export type SelectSpaceActionsType = ReturnType<typeof spaceSelected>;

export const createSelectSpaceAction =
  (deps: Deps) =>
  (
    spaceId: Uuid | null,
    rootItemId: Uuid | null,
    path: string,
    abortSignal?: AbortSignal,
  ): ThunkPromise =>
  async (dispatch) => {
    dispatch(spaceSelected(spaceId, rootItemId));

    if (rootItemId) {
      await dispatch(
        deps.loadWebSpotlightTree(rootItemId, spaceId || undefined, path, abortSignal),
      );
    }
  };
