import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IRoleRepository } from '../../../../../repositories/interfaces/IRoleRepository.type.ts';
import { Role_Restore_Finished, Role_Restore_Started } from '../../constants/rolesActionTypes.ts';
import { RoleListingOperationType } from '../../models/RoleListingOperation.ts';
import { getUpdateServerModelFromEditedRole } from '../../utils/getUpdateServerModelFromEditedRole.ts';

interface IDeps {
  readonly roleRepository: Pick<IRoleRepository, 'createRole'>;
  readonly loadRoles: () => ThunkPromise;
}

const roleRestoreFinished = { type: Role_Restore_Finished } as const;
const roleRestoreStarted = { type: Role_Restore_Started } as const;

export type RestoreDeletedRoleActionsType = typeof roleRestoreFinished | typeof roleRestoreStarted;

export const makeRestoreDeletedRoleAction =
  (deps: IDeps) => (): ThunkPromise => async (dispatch, getState) => {
    const {
      rolesApp: {
        listingUi: {
          operationStatus: { operationType, affectedRole },
        },
      },
      data: { contentTypes },
    } = getState();

    if (operationType !== RoleListingOperationType.Deleted || !affectedRole) {
      throw InvariantException(
        'restoreDeletedRole.ts: affectedRole is falsy or operation type does not match',
      );
    }

    dispatch(roleRestoreStarted);
    await deps.roleRepository.createRole(
      getUpdateServerModelFromEditedRole(affectedRole, contentTypes.byId),
    );
    await dispatch(deps.loadRoles());
    dispatch(roleRestoreFinished);
  };
