import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface ISubscriptionUserScrollTableEmptyStateDataProps {
  readonly noFilteredResults: boolean;
  readonly searchPhrase: string;
}

export interface ISubscriptionUserScrollTableEmptyStateCallbackProps {
  readonly onClearFilter: () => void;
  readonly onClearSearchPhrase: () => void;
}

type SubscriptionUserScrollTableEmptyStateProps = ISubscriptionUserScrollTableEmptyStateDataProps &
  ISubscriptionUserScrollTableEmptyStateCallbackProps;

const _getScrollTableInfoMessage = (props: SubscriptionUserScrollTableEmptyStateProps) => {
  if (props.noFilteredResults) {
    const buttonText = props.searchPhrase ? 'Clear your search phrase' : 'Clear filters';

    const handleButtonClick = props.searchPhrase ? props.onClearSearchPhrase : props.onClearFilter;

    return (
      <EmptyState>
        <EmptyState.Title>We couldn’t find any match.</EmptyState.Title>
        <EmptyState.Content>
          Try rephrasing your search phrase or using different filters.
        </EmptyState.Content>
        <EmptyState.Footer>
          <Button
            buttonStyle="primary"
            onClick={handleButtonClick}
            {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
          >
            {buttonText}
          </Button>
        </EmptyState.Footer>
      </EmptyState>
    );
  }
  return null;
};

export const SubscriptionUsersScrollTableEmptyState: React.FC<
  SubscriptionUserScrollTableEmptyStateProps
> = (props) => (
  <div {...getDataUiCollectionAttribute(DataUiCollection.SubscriptionUsers)}>
    {_getScrollTableInfoMessage(props)}
  </div>
);

SubscriptionUsersScrollTableEmptyState.displayName = 'SubscriptionUsersScrollTableEmptyState';
