import { Placement } from '@kontent-ai/component-library/types';
import React from 'react';
import { WorkflowStatus } from '../../../../component-library/components/WorkflowStatus/WorkflowStatus.tsx';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';

interface IWorkflowStepTagProps {
  readonly color: WorkflowStepColor;
  readonly title: string;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
}

export const WorkflowStepTag: React.FC<IWorkflowStepTagProps> = ({
  color,
  title,
  tooltipPlacement = 'top',
  tooltipText,
}) => (
  <WorkflowStatus
    primary={{
      tooltipPlacement,
      tooltipText,
      name: title,
      background: renderWorkflowStepColor(color),
    }}
  />
);

WorkflowStepTag.displayName = 'WorkflowStepTag';
