import { IRole } from '../../../data/models/roles/IRole.ts';
import { allRolesRole } from '../constants/allRolesRole.ts';
import { getDeletedRoles } from './getDeletedRoles.ts';

export const handleAllRolesRoleOnSubmit = (roleIds: ReadonlyArray<Uuid>): ReadonlySet<Uuid> => {
  // Handle 'All roles' option because it is not a real role
  return roleIds.length === 1 && roleIds.some((roleId) => roleId === allRolesRole.id)
    ? new Set<Uuid>()
    : new Set<Uuid>(roleIds);
};

export const getDefaultRolesForFormInput = (
  roleIds: ReadonlySet<Uuid>,
  allRoles: ReadonlyArray<IRole>,
  isNew: boolean,
): ReadonlyArray<IRole> =>
  roleIds.size === 0 && !isNew
    ? [allRolesRole]
    : allRoles
        .filter((role: IRole) => roleIds.has(role.id))
        .concat(getDeletedRoles(allRoles, roleIds));

export const handleAllRolesRoleOnRolesChangedInFormInput = <TRole extends IRole>(
  formValues: ReadonlyArray<TRole>,
  previousValues: ReadonlyArray<TRole>,
): ReadonlyArray<TRole> => {
  const oldValuesContainAllRoles = previousValues.some((role) => role.id === allRolesRole.id);
  const newValuesContainAllRoles = formValues.some((role) => role.id === allRolesRole.id);
  const newValuesContainNormalRole = formValues.some((role) => role.id !== allRolesRole.id);
  if ((oldValuesContainAllRoles && newValuesContainNormalRole) || !newValuesContainAllRoles) {
    return formValues.filter((r) => r.id !== allRolesRole.id);
  }
  return formValues.filter((role) => role.id === allRolesRole.id);
};
