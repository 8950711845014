import { css } from 'styled-components';
import { rgba } from '../../utils/color.ts';
import { colorAlertFocus, colorFocus } from '../decision/colors.ts';
import { widthFocus } from '../decision/focus.ts';
import { px } from '../utils/utils.ts';
import { BaseColor } from './colors.ts';
import { gridUnit } from './spacing.ts';
import { transition250 } from './transitions.ts';

const innerShadowFocus = (color: BaseColor) => `inset 0px 0px 3px 0px ${color}`;
const outerShadowFocus = (color: BaseColor) => `0 0 0 ${px(widthFocus)} ${color}`;

export const shadowFocusStyles = css`
  box-shadow: ${outerShadowFocus(colorFocus)}, ${innerShadowFocus(colorFocus)};
  transition: box-shadow ${transition250};
`;
export const shadowAlertFocusStyles = css`
  box-shadow: ${outerShadowFocus(colorAlertFocus)}, ${innerShadowFocus(colorAlertFocus)};
  transition: box-shadow ${transition250};
`;

const baseShadowColor = BaseColor.Black;
const additionalShadowColor = BaseColor.OceanBlue90;

const baseShadow = `0 0 ${px(gridUnit)} ${rgba(baseShadowColor, 0.03)}` as const;

export const BoxShadow = {
  None: 'none',
  XS: baseShadow,
  S: `${baseShadow}, 0 ${px(gridUnit)} ${px(1.75 * gridUnit)} ${rgba(additionalShadowColor, 0.08)}`,
  M: `${baseShadow}, 0 ${px(gridUnit)} ${px(1.75 * gridUnit)} ${rgba(additionalShadowColor, 0.16)}`,
  L: `${baseShadow}, 0 ${px(gridUnit)} ${px(4 * gridUnit)} ${rgba(additionalShadowColor, 0.24)}`,
} as const;
export type BoxShadow = (typeof BoxShadow)[keyof typeof BoxShadow];

const baseDropShadow = `drop-shadow(${baseShadow})`;

export const DropShadow = {
  XS: baseDropShadow,
  S: `${baseDropShadow} drop-shadow(0 ${px(gridUnit)} ${px(1.75 * gridUnit)} ${rgba(
    additionalShadowColor,
    0.08,
  )})`,
  M: `${baseDropShadow} drop-shadow(0 ${px(gridUnit)} ${px(1.75 * gridUnit)} ${rgba(
    additionalShadowColor,
    0.16,
  )})`,
  L: `${baseDropShadow} drop-shadow(0 ${px(gridUnit)} ${px(4 * gridUnit)} ${rgba(
    additionalShadowColor,
    0.24,
  )})`,
} as const;
export type DropShadow = (typeof DropShadow)[keyof typeof DropShadow];
