import React from 'react';
import { DataUiWorkflowAction } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Placeholder } from './Placeholder.tsx';

export interface SectionItemLinkProps {
  readonly dataUiAttribute: DataUiWorkflowAction;
  readonly text: string;
  readonly onEditClick: () => void;
}

export const SectionLink: React.FC<SectionItemLinkProps> = ({
  dataUiAttribute,
  onEditClick,
  text,
}) => (
  <Placeholder
    dataUiAttribute={dataUiAttribute}
    onClick={onEditClick}
    className="sidebar__section-item-link"
  >
    {text}
  </Placeholder>
);
