// Dialog actions
export const Subscription_AddNewSubscriptionAdmin_ShowModal =
  'Subscription_AddNewSubscriptionAdmin_ShowModal';
export const Subscription_PromoteExistingUsers_ShowModal =
  'Subscription_PromoteExistingUsers_ShowModal';
// Create new subscription admin promoting existing users actions
export const Subscription_PromoteAdmin_Started = 'Subscription_PromoteAdmin_Started';
export const Subscription_PromoteAdmin_Finished = 'Subscription_PromoteAdmin_Finished';
export const Subscription_PromoteAdmin_Canceled = 'Subscription_PromoteAdmin_Canceled';
export const Subscription_PromoteAdmin_Failed = 'Subscription_PromoteAdmin_Failed';
// Create new subscription admin using form actions
export const Subscription_SubscriptionAdminInvitation_Canceled =
  'Subscription_SubscriptionAdminInvitation_Canceled';
export const Subscription_SubscriptionAdminInvitation_Finished =
  'Subscription_SubscriptionAdminInvitation_Finished';
export const Subscription_SubscriptionAdminInvitation_Started =
  'Subscription_SubscriptionAdminInvitation_Started';
export const Subscription_SubscriptionAdminInvitation_Failed =
  'Subscription_SubscriptionAdminInvitation_Failed';
// Resend subscription admin invitation
export const Subscription_ResendInvitation_Started = 'Subscription_ResendInvitation_Started';
export const Subscription_ResendInvitation_Finished = 'Subscription_ResendInvitation_Finished';
export const Subscription_ResendInvitation_Failed = 'Subscription_ResendInvitation_Failed';
// Revoke admin invitation
export const Subscription_RevokeInvitation_Started = 'Subscription_RevokeInvitation_Started';
export const Subscription_RevokeInvitation_Finished = 'Subscription_RevokeInvitation_Finished';
export const Subscription_RevokeInvitation_Failed = 'Subscription_RevokeInvitation_Failed';
// Delete subscription admins
export const Subscription_AdminListing_Select = 'Subscription_AdminListing_Select';
export const Subscription_AdminListing_Unselect = 'Subscription_AdminListing_Unselect';
export const Subscription_AdminListing_DeleteAdminsStarted =
  'Subscription_AdminListing_DeleteAdminsStarted';
export const Subscription_AdminListing_DeleteAdminsFinished =
  'Subscription_AdminListing_DeleteAdminsFinished';
export const Subscription_AdminListing_DeleteAdminsFailed =
  'Subscription_AdminListing_DeleteAdminsFailed';
