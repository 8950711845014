import Immutable from 'immutable';
import { Asset } from '../../../../data/models/assets/Asset.ts';
import { IAssetItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyAssetItemElement,
  IAssetItemElement,
} from '../../models/contentItemElements/AssetItemElement.ts';
import {
  IItemElementServerModelConverter,
  IItemElementServerModelConverterDataDependencies,
  ISimpleItemElementDomainModelConverter,
} from './types/IItemElementDataConverters.type.ts';

function convertAssetElementToDomainModel(
  element: IAssetItemElementServerModel,
): IAssetItemElement {
  return {
    ...EmptyAssetItemElement,
    elementId: element.elementId,
    value: Immutable.OrderedMap(
      element.value.map((asset) => [asset._id, { id: asset._id, renditions: asset.renditions }]),
    ),
    lastModifiedBy: element.lastModifiedBy ? element.lastModifiedBy : null,
    lastModifiedAt: element.lastModifiedAt ? element.lastModifiedAt : null,
  };
}

const convertAssetElementToServerModel = (
  { getAssets }: IItemElementServerModelConverterDataDependencies,
  element: IAssetItemElement,
): IAssetItemElementServerModel => {
  const assets = getAssets();
  const assetsWithMetadata = element.value
    .filter((_, id: Uuid) => {
      const asset = assets.get(id);
      // Do not save pending or failed assets but keep non-existing ones
      // as they may be references to not yet existing assets via external ID
      return !asset || (!asset._failed && !asset._uploading);
    })
    .map((item) => Asset.toAssetReferenceServerModel(item))
    .toArray();

  return {
    elementId: element.elementId,
    type: ElementType.Asset,
    value: assetsWithMetadata,
    lastModifiedBy: element.lastModifiedBy,
    lastModifiedAt: element.lastModifiedAt,
  };
};

export const createAssetElementDomainModelConverter =
  (): ISimpleItemElementDomainModelConverter<IAssetItemElementServerModel, IAssetItemElement> =>
  (element) =>
    convertAssetElementToDomainModel(element);

export const createAssetElementServerModelConverter =
  (): IItemElementServerModelConverter<IAssetItemElement, IAssetItemElementServerModel> =>
  (element, deps) =>
    convertAssetElementToServerModel(deps, element);
