import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { TextSkeleton } from '@kontent-ai/component-library/Skeleton';
import { Stack } from '@kontent-ai/component-library/Stack';
import { SrOnly } from '@kontent-ai/component-library/styles';
import {
  BaseColor,
  Spacing,
  Typography,
  colorTextDefault,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ChartSection } from '../../../contentStatus/components/HorizontalStackedBarChart/ChartSection.tsx';
import { IHorizontalStackedBarData } from '../../../types/IHorizontalStackedBarData.type.ts';
import { getRoundedPercentages } from '../../../utils/getRoundedPercentages.ts';
import { getDaysAndHoursFromHours } from '../../utils/getDaysAndHoursFromHours.ts';
import { LegendItem } from './LegendItem.tsx';

type ContentPaceBarChartProps = Readonly<{
  chartLabel: string;
  data: ReadonlyArray<IHorizontalStackedBarData> | null;
  isLoading: boolean;
  onItemClick?: (itemId: string, item: IHorizontalStackedBarData) => void;
}>;

const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${px(gridUnit * 3)};
  gap: 2px;
  box-sizing: content-box;
`;

const ChartLegend: React.FC<React.PropsWithChildren> = (props) => (
  <Inline spacingX={Spacing.L} spacingY={Spacing.S} {...props} />
);

const ChartTotal: React.FC<React.PropsWithChildren> = (props) => (
  <Box
    color={colorTextDefault}
    typography={Typography.TitleMedium}
    width={gridUnit * 25}
    {...props}
  />
);

const loadingData: ReadonlyArray<IHorizontalStackedBarData> = [...Array(5).keys()].map((id) => ({
  id: id.toString(),
  color: BaseColor.Gray60,
  label: 'Loading',
  value: 0,
  isValueRoundedToZero: false,
}));

export const ContentPaceBarChart: React.FC<ContentPaceBarChartProps> = ({
  chartLabel,
  data,
  isLoading,
  onItemClick,
}) => {
  const total = useMemo(() => data?.reduce((sum, section) => sum + section.value, 0) ?? 0, [data]);
  const roundedPercentageMap = useMemo(() => {
    if (!data) {
      return new Map();
    }
    return getRoundedPercentages(data);
  }, [data]);

  if (isLoading || data === null) {
    return (
      <Stack spacing={Spacing.XL}>
        <Row alignY="center" spacing={Spacing.M} noWrap>
          <Column>
            <ChartContainer>
              <TextSkeleton width="100%" />
            </ChartContainer>
          </Column>
          <Column width="content">
            <ChartTotal>
              <TextSkeleton width={gridUnit * 10} />
            </ChartTotal>
          </Column>
        </Row>
        <ChartLegend>
          {loadingData.map((section) => (
            <LegendItem key={section.id} isLoading item={section} roundedPercentage={0} />
          ))}
        </ChartLegend>
        <SrOnly>Loading data for the content progress widget.</SrOnly>
      </Stack>
    );
  }

  if (total === 0) {
    return (
      <>
        <Box paddingY={Spacing.S} typography={Typography.HeadlineLarge}>
          &nbsp;
        </Box>
        <Box typography={Typography.TitleMedium} color={colorTextDefault}>
          There are no insights yet for the set time frame.
        </Box>
      </>
    );
  }

  return (
    <Stack spacing={Spacing.XL}>
      <Row alignY="center" spacing={Spacing.M} noWrap>
        <Column>
          <ChartContainer role="img" aria-label={chartLabel}>
            {data.map((section) => {
              const roundedPercentage = roundedPercentageMap.get(section.id);
              if (!roundedPercentage || section.value <= 0) {
                return null;
              }

              return (
                <ChartSection
                  item={section}
                  key={section.id}
                  onItemClick={onItemClick}
                  tooltipText={`${section.label}: ${getDaysAndHoursFromHours(
                    section.value,
                  )} (${roundedPercentage}%)`}
                  total={total}
                />
              );
            })}
          </ChartContainer>
        </Column>
        <Column width="content">
          <ChartTotal>{getDaysAndHoursFromHours(total)}</ChartTotal>
        </Column>
      </Row>

      <ChartLegend>
        {data.map((section) => {
          const roundedPercentage = roundedPercentageMap.get(section.id);
          if (roundedPercentage === undefined) {
            return null;
          }

          return (
            <LegendItem
              key={section.id}
              item={section}
              onItemClick={onItemClick}
              roundedPercentage={roundedPercentage}
            />
          );
        })}
      </ChartLegend>
    </Stack>
  );
};

ContentPaceBarChart.displayName = 'ContentPaceBarChart';
