import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentItemOverwritten } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ExtendedContentChange } from '../components/contentItemEditorNotifications/overwriteNotification/OverwriteNotificationBar.tsx';
import { emptyContentChange } from '../constants/emptyContentChange.ts';
import { extendContentChange } from '../utils/extendContentChange.ts';

export const getExtendedContentChange = (
  state: IStore,
  contentChange: IContentItemOverwritten | null,
): ExtendedContentChange =>
  contentChange
    ? extendContentChange(contentChange, state.data.user.info.userId)
    : emptyContentChange;
