import { combineReducers } from '@reduxjs/toolkit';
import { assetEditorLoadingStatus } from './assetUiEditor/assetEditorLoadingStatus.ts';
import { editedAsset } from './assetUiEditor/editedAsset.ts';
import { hasConflict } from './assetUiEditor/hasConflict.ts';
import { isEditedAssetBeingSaved } from './assetUiEditor/isEditedAssetBeingSaved.ts';
import { isEditedAssetModified } from './assetUiEditor/isEditedAssetModified.ts';

export const assetUiEditor = combineReducers({
  assetEditorLoadingStatus,
  editedAsset,
  hasConflict,
  isEditedAssetBeingSaved,
  isEditedAssetModified,
});
