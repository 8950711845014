import { ContentState, EntityInstance } from 'draft-js';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { IContentComponentServerModel } from '../../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import { BaseBlockType, BlockType } from '../../../utils/blocks/blockType.ts';
import { isContentComponent } from '../../../utils/blocks/blockTypeUtils.ts';
import { getBlockHierarchy } from '../../../utils/blocks/editorHierarchyUtils.ts';
import {
  VirtualBlockType,
  getBlockTagData as defaultGetBlockTagData,
} from '../../../utils/export/html/elements/blocks.ts';
import {
  getInstructionData,
  getLinkData,
  getMentionData,
} from '../../../utils/export/html/elements/entities.ts';
import {
  ObjectDataType,
  getObjectBlockData as defaultGetObjectBlockData,
  getContentComponentWithValueTagData,
} from '../../../utils/export/html/elements/objects.ts';
import { HtmlData, TagData } from '../../../utils/export/html/elements/tags.ts';
import { generateHtml } from '../../../utils/export/html/generateHtml.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import { getAttributesFromEntityData } from '../../entityApi/api/editorEntityUtils.ts';
import { isAiInstruction } from '../../inlineAi/utils/InstructionEntity.ts';
import { isLink } from '../../links/api/LinkEntity.ts';
import { isFinishedMention } from '../../mentions/api/MentionEntity.ts';

const blockTagDataForClipboard = (blockType: BaseBlockType | VirtualBlockType): TagData => {
  switch (blockType) {
    case VirtualBlockType.Table:
      return {
        tagName: 'table',
        attributes: {
          style: 'border-spacing: 0; border-collapse: collapse;',
        },
      };

    case BlockType.TableCell:
      return {
        tagName: 'td',
        attributes: {
          style: 'padding: 4px; border: 1px solid black;',
        },
      };

    default:
      return defaultGetBlockTagData(blockType);
  }
};

export type GetUsers = () => ReadonlyMap<UserId, IProjectContributor>;

export type GetEntityTagsForClipboardDependencies = {
  readonly getUsers: GetUsers;
};

const getEntityTagsForClipboard = (
  entity: EntityInstance,
  content: HtmlData,
  deps: GetEntityTagsForClipboardDependencies,
): HtmlData | null => {
  if (isFinishedMention(entity)) {
    return getMentionData(entity, (userId) => deps.getUsers().get(userId) ?? null);
  }
  if (isLink(entity)) {
    return getLinkData(entity, content, getAttributesFromEntityData);
  }
  if (isAiInstruction(entity)) {
    return getInstructionData(entity, content);
  }
  return null;
};

export type ExportToClipboardHtml = (
  contentState: ContentState,
  contentComponents: ReadonlyMap<Uuid, IContentComponentServerModel>,
  metadata: ReadonlyMap<ObjectDataType, Uuid>,
  deps: GetEntityTagsForClipboardDependencies,
) => string;

export const exportToClipboardHtml: ExportToClipboardHtml = (
  content,
  contentComponents,
  metadata,
  deps: GetEntityTagsForClipboardDependencies,
) =>
  generateHtml(
    {
      entityMap: content.getEntityMap(),
      metadata,
      nodes: getBlockHierarchy(getBlocks(content)),
    },
    {
      newLine: '',
      indent: '',
      getObjectBlockData: (block) => {
        if (isContentComponent(block)) {
          const tagData = contentComponents
            ? getContentComponentWithValueTagData(block, contentComponents)
            : null;
          return tagData && [tagData];
        }

        return defaultGetObjectBlockData(block);
      },
      getBlockTagData: blockTagDataForClipboard,
      getEntityData: (entity, htmlData) => getEntityTagsForClipboard(entity, htmlData, deps),
    },
  );
