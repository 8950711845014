import React from 'react';
import { StyledTabs } from './StyledTabs.tsx';

type ObjectWithId = { readonly id: string };

type ExpandedHorizontalTabsProps<HorizontalTabItem extends ObjectWithId> = {
  readonly children?: never;
  readonly items: readonly HorizontalTabItem[];
  readonly renderItem: (item: HorizontalTabItem) => React.ReactNode;
};

export const ExpandedHorizontalTabs = React.forwardRef<
  HTMLDivElement,
  ExpandedHorizontalTabsProps<any>
>(({ items, renderItem, ...restProps }, forwardedRef) => (
  <StyledTabs ref={forwardedRef} {...restProps}>
    {items.map((item) => (
      <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
    ))}
  </StyledTabs>
));

ExpandedHorizontalTabs.displayName = 'ExpandedHorizontalTabs';
