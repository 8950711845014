import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
} from '../../../constants/routePaths.ts';
import { buildPath } from '../../../utils/routing/routeTransitionUtils.ts';

type Props = {
  readonly subscriptionId: string;
};

export const BillingInformationAction: React.FC<Props> = ({ subscriptionId }) => (
  <RouterLinkButton
    size="small"
    buttonStyle="primary-inverse"
    to={buildPath<EnsuredSubscriptionRouteParams>(BillingInformationRoute, { subscriptionId })}
  >
    Select a payment method
  </RouterLinkButton>
);

BillingInformationAction.displayName = 'BillingInformationAction';
