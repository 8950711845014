import { AssetTypeElementValidationResult } from '../types/AssetTypeElementValidationResult.type.ts';
import { InitialConditionValidationResult } from '../utils/conditions/getConditionValidationResult.ts';

export const AssetInitialValidationResult: AssetTypeElementValidationResult = {
  conditionValidationResult: InitialConditionValidationResult,
  isGuidelinesValid: true,
  isNameValid: true,
  isFileSizeValid: true,
  isNumberOfItemsValid: true,
  isWidthValid: true,
  isHeightValid: true,
  errorMessages: [],
  warningMessages: [],
};
