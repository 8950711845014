import { Action } from '../../../../../@types/Action.type.ts';
import {
  Taxonomy_App_Initialized,
  Taxonomy_GroupCreate_Initialized,
  Taxonomy_GroupEdit_Cleared,
  Taxonomy_GroupEdit_Initialized,
  Taxonomy_Group_CodenameChanged,
  Taxonomy_Group_CreationFailed,
  Taxonomy_Group_CreationFinished,
  Taxonomy_Group_CreationStarted,
  Taxonomy_Group_NameChanged,
  Taxonomy_Group_SavingFailed,
  Taxonomy_Group_SavingFinished,
  Taxonomy_Group_SavingStarted,
  Taxonomy_Terms_TermCodenameChanged,
  Taxonomy_Terms_TermCreated,
  Taxonomy_Terms_TermMoved,
  Taxonomy_Terms_TermNameChanged,
  Taxonomy_Terms_TermRemoved,
} from '../../constants/taxonomyActionTypes.ts';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';

const initialState = TaxonomyEditorStatus.InitialEmpty;

export const status = (
  state: TaxonomyEditorStatus = initialState,
  action: Action,
): TaxonomyEditorStatus => {
  switch (action.type) {
    case Taxonomy_App_Initialized:
      return initialState;

    case Taxonomy_GroupEdit_Cleared:
      return TaxonomyEditorStatus.Loading;

    case Taxonomy_GroupEdit_Initialized:
    case Taxonomy_GroupCreate_Initialized:
    case Taxonomy_Group_CreationFinished:
    case Taxonomy_Group_SavingFinished:
      return TaxonomyEditorStatus.Unmodified;

    case Taxonomy_Group_SavingStarted:
    case Taxonomy_Group_CreationStarted:
      return TaxonomyEditorStatus.IsBeingSaved;

    case Taxonomy_Group_CreationFailed:
    case Taxonomy_Group_SavingFailed:
    case Taxonomy_Group_NameChanged:
    case Taxonomy_Group_CodenameChanged:
    case Taxonomy_Terms_TermCreated:
    case Taxonomy_Terms_TermRemoved:
    case Taxonomy_Terms_TermMoved:
    case Taxonomy_Terms_TermNameChanged:
    case Taxonomy_Terms_TermCodenameChanged:
      return TaxonomyEditorStatus.Modified;

    default:
      return state;
  }
};
