import { assert } from '@kontent-ai/utils';
import React, { createContext, useContext, useMemo } from 'react';

type AssetTileSelection = {
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
};

const AssetTileSelectionContext = createContext<AssetTileSelection | undefined>(undefined);

type Props = {
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly toggleAssetSelection: (assetId: Uuid, shiftPressed: boolean) => void;
};

export const AssetTileSelectionContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  selectedAssets,
  toggleAssetSelection,
}) => {
  const contextValue = useMemo(
    () => ({ selectedAssets, toggleAssetSelection }),
    [selectedAssets, toggleAssetSelection],
  );

  return (
    <AssetTileSelectionContext.Provider value={contextValue}>
      {children}
    </AssetTileSelectionContext.Provider>
  );
};

AssetTileSelectionContextProvider.displayName = 'AssetTileSelectionContextProvider';

export const useAssetTileSelection = (): AssetTileSelection => {
  const value = useContext(AssetTileSelectionContext);
  assert(
    value,
    () =>
      `useAssetTileSelection: ${AssetTileSelectionContextProvider.displayName} is missing in the current tree`,
  );
  return value;
};
