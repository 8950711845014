export const validateNumberOfItems = (
  minItems: number | null,
  maxItems: number | null,
  numberOfItems: number,
): boolean => {
  if (minItems && maxItems) {
    return numberOfItems === minItems && numberOfItems === maxItems;
  }
  if (minItems) {
    return numberOfItems >= minItems;
  }
  if (maxItems) {
    return numberOfItems <= maxItems;
  }

  return true;
};
