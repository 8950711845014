import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { ScrollTableCell } from './ScrollTableCell.tsx';

export interface IScrollTableCheckboxProps {
  readonly disabledMessage?: string;
  readonly id: string;
  readonly isItemChecked: boolean;
  readonly name: string;
  readonly onCheckboxClick: (shiftPressed: boolean) => void;
  readonly tabIndex?: number;
}

export const ScrollTableCheckboxCell: React.FC<IScrollTableCheckboxProps> = ({
  disabledMessage,
  id,
  isItemChecked,
  name,
  onCheckboxClick,
  tabIndex = 0,
}) => {
  return (
    <ScrollTableCell
      customClassName="scroll-table__cell-item--is-checkbox"
      size={1}
      disabledTooltip={disabledMessage}
      tooltipPlacement="right"
    >
      <Checkbox
        ariaLabel={name}
        checkboxState={disabledMessage ? 'disabled' : 'default'}
        checked={isItemChecked}
        id={id}
        name={name}
        onToggle={(_isChecked, keyboardModifiers) => onCheckboxClick(keyboardModifiers.shiftKey)}
        tabIndex={tabIndex}
        {...getDataUiActionAttribute(DataUiAction.Select)}
      />
    </ScrollTableCell>
  );
};

ScrollTableCheckboxCell.displayName = 'ScrollTableCheckboxCell';
