import { Button, ButtonSize } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import { ControlShortcutTemplate } from '../constants/shortcutSymbols.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

interface ISaveChangesButtonStateProps {
  /** Use 'inverse' on dark backgrounds, e.g. status bar, otherwise use 'default'. */
  readonly buttonStyle: 'default' | 'inverse';
  readonly dataUiAction?: DataUiAction;
  readonly disabled?: boolean;
  readonly isBeingSaved: boolean;
  readonly shortcuts?: string;
  readonly size?: ButtonSize;
  readonly text?: string;
  readonly tooltipText?: string;
}

interface ISaveChangesButtonDispatchProps {
  readonly onSave: () => void;
}

type SaveChangesButtonProps = ISaveChangesButtonStateProps & ISaveChangesButtonDispatchProps;

export const SaveChangesButton: FC<SaveChangesButtonProps> = (props) => {
  const {
    buttonStyle = 'default',
    dataUiAction,
    disabled,
    isBeingSaved,
    onSave,
    shortcuts,
    size,
    text,
    tooltipText,
  } = props;

  const saveText = text || 'Save changes';
  const uiAction = dataUiAction || DataUiAction.Save;

  const isDisabled = isBeingSaved || disabled;

  return (
    <Button
      tooltipText={isDisabled ? tooltipText : (tooltipText ?? saveText)}
      tooltipPlacement="bottom-end"
      tooltipShortcuts={isDisabled ? undefined : (shortcuts ?? ControlShortcutTemplate('s'))}
      buttonStyle={buttonStyle === 'default' ? 'primary' : 'primary-inverse'}
      size={size}
      disabled={isDisabled}
      onClick={() => onSave()}
      {...getDataUiActionAttribute(uiAction)}
    >
      {isBeingSaved && (
        <Button.ProgressIcon
          {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
        />
      )}
      <Button.Label>{isBeingSaved ? 'Saving' : saveText}</Button.Label>
    </Button>
  );
};

SaveChangesButton.displayName = 'SaveChangesButton';
