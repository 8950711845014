import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewContentItem_DialogPropertiesSet,
  NewContentItem_Initialized,
} from '../../constants/newContentItemActionTypes.ts';

const initialState = false;

export const isLoaded = (state = initialState, action: Action): boolean => {
  switch (action.type) {
    case NewContentItem_DialogPropertiesSet:
      return false;

    case NewContentItem_Initialized:
      return true;

    default:
      return state;
  }
};
