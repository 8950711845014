import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { generateSubscriptionApiKeyActionCreator } from './thunks/generateSubscriptionApiKey.tsx';
import { initSubscriptionApiKeyActionCreator } from './thunks/initSubscriptionApiKey.ts';
import { regenerateSubscriptionApiKeyActionCreator } from './thunks/regenerateSubscriptionApiKey.ts';

const { apiKeysRepository } = repositoryCollection;

export const initSubscriptionApiKey = initSubscriptionApiKeyActionCreator({
  apiKeysRepository,
});

export const regenerateSubscriptionApiKey = regenerateSubscriptionApiKeyActionCreator({
  apiKeysRepository,
});

export const generateSubscriptionApiKey = generateSubscriptionApiKeyActionCreator({
  apiKeysRepository,
});
