import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { MaximumLimitPerRequest } from '../../../../_shared/constants/scrollGridConstants.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { IAsset } from '../../../models/assets/Asset.ts';
import { IAssets } from '../IAssets.type.ts';

export const getAssetsForScrollGrid = memoize.allForever(
  (assetsStore: IAssets): Immutable.List<IAsset | null> => {
    const skelets: ReadonlyArray<null> =
      assetsStore.loadingStatus === LoadingStatus.Loading
        ? new Array(MaximumLimitPerRequest).fill(null)
        : [];

    return Immutable.List(
      assetsStore.listingAssets.listingIds
        .toArray()
        .map((id: Uuid) => assetsStore.byId.get(id) ?? null)
        .filter(notNull),
    )
      .concat(skelets)
      .toList();
  },
);
