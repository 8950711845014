import Immutable from 'immutable';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { IStatusInfoMessage } from '../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';

type TaxonomyGroupListStatusInfoOptions = {
  readonly deletedTaxonomyGroups?: Immutable.Set<Uuid>;
  readonly restoredTaxonomyGroups?: Immutable.Set<Uuid>;
  readonly selectedTaxonomyGroupIds?: Immutable.Set<Uuid>;
  readonly undoing?: Immutable.Set<Uuid>;
};

export function getTaxonomyGroupListStatusInfoMessage({
  selectedTaxonomyGroupIds,
  deletedTaxonomyGroups,
  undoing,
  restoredTaxonomyGroups,
}: TaxonomyGroupListStatusInfoOptions): IStatusInfoMessage {
  if (undoing) {
    return {
      text: 'Undoing',
      style: StatusMessageStyle.Progress,
    };
  }

  if (selectedTaxonomyGroupIds && selectedTaxonomyGroupIds.size > 0) {
    return {
      text: `${pluralizeWithCount('Taxonomy group', selectedTaxonomyGroupIds.size)} selected`,
      style: StatusMessageStyle.Selected,
    };
  }

  if (deletedTaxonomyGroups && deletedTaxonomyGroups.size > 0) {
    return {
      text: `${pluralizeWithCount('Taxonomy group', deletedTaxonomyGroups.size)} deleted`,
      style: StatusMessageStyle.Deleted,
    };
  }

  if (restoredTaxonomyGroups && restoredTaxonomyGroups.size > 0) {
    return {
      text: `${pluralizeWithCount('Taxonomy group', restoredTaxonomyGroups.size)} restored`,
      style: StatusMessageStyle.Updated,
    };
  }

  return {
    text: 'Taxonomy groups',
    style: StatusMessageStyle.Normal,
  };
}
