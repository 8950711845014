import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { showIntercom } from '../../../applications/projects/actions/projectsActions.ts';
import { IProjects } from '../../../data/reducers/projects/IProjects.type.ts';
import { getSelectedSubscription } from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  SubscriptionPlanMaxUserLimitReachedWarning as Component,
  ISubscriptionPlanLimitReachedWarningCallbackProps,
  ISubscriptionPlanLimitReachedWarningDataProps,
} from '../../components/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { IStore } from '../../stores/IStore.type.ts';
import { compose } from '../../utils/func/compose.ts';
import {
  getNumberOfUsersInActivation,
  getSubscriptionUsage,
  hasAvailableUserSlots,
} from '../../utils/subscriptionUsageUtils.ts';
import { isAdmin } from '../../utils/usersUtils.ts';

const getCurrentProjectOrThrow = (projects: IProjects, currentProjectId: Uuid) => {
  const result = projects.byId.get(currentProjectId);
  if (!result) {
    throw InvariantException(
      `[SubscriptionPlanLimitReachedWarning.tsx] Container: The projectId : ${currentProjectId} has no project in projects.byId in the data store. Projects available have these IDs: '${JSON.stringify(
        projects.byId.keySeq().toArray(),
      )}'.`,
    );
  }

  return result;
};

interface IOwnProps {
  readonly hideButton?: boolean;
}

function mapStateToProps(
  state: IStore,
  ownProps: IOwnProps,
): ISubscriptionPlanLimitReachedWarningDataProps {
  const {
    data: {
      user,
      projects,
      plans,
      subscriptions: { subscriptionUsages },
    },
    sharedApp: { currentProjectId },
  } = state;

  const selectedSubscription = getSelectedSubscription(state);

  const subscriptionId = selectedSubscription
    ? selectedSubscription.subscriptionId
    : getCurrentProjectOrThrow(projects, currentProjectId).subscriptionId;
  const currentUserIsAdmin =
    !!selectedSubscription ||
    isAdmin(
      getCurrentProjectOrThrow(projects, currentProjectId).subscriptionAdmins.toArray(),
      user.info.userId,
    );

  const subscriptionUsage = getSubscriptionUsage(subscriptionUsages, subscriptionId);
  const plan = plans.byId.get(subscriptionUsage ? subscriptionUsage.planId : '');

  const numberOfUsersInActivation = getNumberOfUsersInActivation(state);

  return {
    currentUserIsAdmin,
    isVisible: !hasAvailableUserSlots(subscriptionUsage, plans.byId, numberOfUsersInActivation),
    planIsCustom: !!plan && plan.isCustom,
    subscriptionId,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch): ISubscriptionPlanLimitReachedWarningCallbackProps {
  return {
    closeInvitationModalDialog: compose(dispatch, showIntercom),
  };
}

export const SubscriptionPlanMaxUserLimitReachedWarning: React.ComponentType<IOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
