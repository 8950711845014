import React from 'react';
import { HorizontalTabs } from '../../../../../component-library/components/StatusBar/HorizontalTabs/HorizontalTabs.tsx';
import {
  DataUiCollection,
  DataUiElement,
  ObjectWithDataAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type ContentInventoryTab = {
  readonly id: string;
  readonly label: string;
  readonly path: string;
  readonly dataUiAttributes: ObjectWithDataAttribute;
};

type ContentInventoryTabsProps = {
  readonly containerRef: React.RefObject<HTMLDivElement>;
  readonly tabsStructure: readonly ContentInventoryTab[];
};

export const ContentInventoryTabs: React.FC<ContentInventoryTabsProps> = ({
  containerRef,
  tabsStructure,
}) => (
  <HorizontalTabs
    items={tabsStructure}
    containerRef={containerRef}
    getItemProps={(tab) => ({
      label: tab.label,
      pathname: tab.path,
      ...getDataUiElementAttribute(DataUiElement.NavigationTab),
      ...tab.dataUiAttributes,
    })}
    {...getDataUiCollectionAttribute(DataUiCollection.TabItems)}
  />
);

ContentInventoryTabs.displayName = 'ContentInventoryTabs';
