export type NumberValue = {
  readonly value: string;
  readonly isValid: boolean;
};

export const createNumberValue = (props?: Partial<NumberValue>): NumberValue => ({
  value: '',
  isValid: true,
  ...props,
});
