export const WebSpotlight_LocalizedRoute_Entered = 'WebSpotlight_LocalizedRoute_Entered';
export const WebSpotlight_LocalizedRoute_Left = 'WebSpotlight_LocalizedRoute_Left';

export const WebSpotlight_ItemPreviewInfoReload_Requested =
  'WebSpotlight_ItemPreviewInfoReload_Requested';

export const WebSpotlight_Preview_Refreshed = 'WebSpotlight_Preview_Refreshed';

export const WebSpotlight_Configuration_Loaded = 'WebSpotlight_Configuration_Loaded';

export const WebSpotlight_Subpages_Loaded = 'WebSpotlight_Subpages_Loaded';

export const WebSpotlight_LoadWebSpotlightTree_Failed = 'WebSpotlight_LoadWebSpotlightTree_Failed';
export const WebSpotlight_LoadWebSpotlightTree_Succeeded =
  'WebSpotlight_LoadWebSpotlightTree_Succeeded';
export const WebSpotlight_LoadWebSpotlightTree_Started =
  'WebSpotlight_LoadWebSpotlightTree_Started';

export const WebSpotlight_ExpandNode_Failed = 'WebSpotlight_ExpandNode_Failed';
export const WebSpotlight_ExpandNode_Finished = 'WebSpotlight_ExpandNode_Finished';
export const WebSpotlight_ExpandNode_Started = 'WebSpotlight_ExpandNode_Started';

export const WebSpotlight_SelectedNodeAncestors_Expanded =
  'WebSpotlight_SelectedNodeAncestors_Expanded';

export const WebSpotlight_ReloadNode_Failed = 'WebSpotlight_ReloadNode_Failed';
export const WebSpotlight_ReloadNode_Finished = 'WebSpotlight_ReloadNode_Finished';
export const WebSpotlight_ReloadNode_Started = 'WebSpotlight_ReloadNode_Started';

export const WebSpotlight_Preferences_Loaded = 'WebSpotlight_Preferences_Loaded';
export const WebSpotlight_PreviewAutoRefresh_Changed = 'WebSpotlight_PreviewAutoRefresh_Changed';

export const WebSpotlight_ElementPermissionCheck_Failed =
  'WebSpotlight_ElementPermissionCheck_Failed';

export const WebSpotlight_SubpagesPermissionCheck_Started =
  'WebSpotlight_SubpagesPermissionCheck_Started';
export const WebSpotlight_SubpagesPermissionCheck_Finished =
  'WebSpotlight_SubpagesPermissionCheck_Finished';
export const WebSpotlight_SubpagesPermissionCheck_Failed =
  'WebSpotlight_SubpagesPermissionCheck_Failed';

export const WebSpotlight_Node_Collapsed = 'WebSpotlight_Node_Collapsed';

export const WebSpotlight_Node_Selected = 'WebSpotlight_Node_Selected';
export const WebSpotlight_NewNode_Linked = 'WebSpotlight_NewNode_Linked';

export const WebSpotlight_CreatePageItem_Started = 'WebSpotlight_CreatePageItem_Started';

export const WebSpotlight_Node_Focused = 'WebSpotlight_Node_Focused';
export const WebSpotlight_Node_Blurred = 'WebSpotlight_Node_Blurred';

export const WebSpotlight_LinkPageItems_Failed = 'WebSpotlight_LinkPageItems_Failed';
export const WebSpotlight_LinkPageItems_Finished = 'WebSpotlight_LinkPageItems_Finished';
export const WebSpotlight_LinkPageItems_Started = 'WebSpotlight_LinkPageItems_Started';

export const WebSpotlight_LoadItemPreviewInfo_Started = 'WebSpotlight_LoadItemPreviewInfo_Started';
export const WebSpotlight_LoadItemPreviewInfo_Finished =
  'WebSpotlight_LoadItemPreviewInfo_Finished';
export const WebSpotlight_LoadItemPreviewInfo_Failed = 'WebSpotlight_LoadItemPreviewInfo_Failed';

export const WebSpotlight_SetStatus_Started = 'WebSpotlight_SetStatus_Started';
export const WebSpotlight_SetStatus_Finished = 'WebSpotlight_SetStatus_Finished';
export const WebSpotlight_SetStatus_Failed = 'WebSpotlight_SetStatus_Failed';

export const WebSpotlight_PreviewApiPolling_Started = 'WebSpotlight_PreviewApiPolling_Started';
export const WebSpotlight_PreviewApiPolling_ChangeReady =
  'WebSpotlight_PreviewApiPolling_ChangeReady';
export const WebSpotlight_PreviewApiPolling_Finished = 'WebSpotlight_PreviewApiPolling_Finished';
export const WebSpotlight_PreviewApiPolling_Failed = 'WebSpotlight_PreviewApiPolling_Failed';
export const WebSpotlight_PreviewApiPolling_Aborted = 'WebSpotlight_PreviewApiPolling_Aborted';

export const WebSpotlight_Space_Selected = 'WebSpotlight_Space_Selected';
