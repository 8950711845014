import { isXMLHttpRequest } from '@kontent-ai/errors';
import {
  makeCancellablePromise,
  notNullNorUndefined,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { isAiLinkedItemsScoreEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { getContentItemListingHiddenColumns } from '../../../../contentInventory/content/selectors/getContentItemListingHiddenColumns.ts';
import { useItemVariantId } from '../../ContentComponent/context/ContentItemContext.tsx';
import { loadListingItems } from '../../LoadedItems/actions/thunkLoadedItemsActions.ts';
import { RecommendedLinkedItemsModalBody as RecommendedLinkedItemsModalBodyComponent } from '../components/RecommendedLinkedItemsModalBody.tsx';
import { ListingContentItemWithScore } from '../types/ListingContentItemWithScore.type.ts';
import { RecommendedLinkedItemsLoadingStatus as LoadingStatus } from '../types/recommendedLinkedItemsLoadingStatus.type.ts';

const { recommendedLinkedItemsRepository } = repositoryCollection;

type Props = {
  readonly allowedContentTypeIds: UuidArray;
  readonly onAddItem: (itemId: Uuid, score: number) => void;
};

export const RecommendedLinkedItemsModalBody: React.FC<Props> = ({
  allowedContentTypeIds,
  onAddItem,
}) => {
  const { itemId, variantId } = useItemVariantId();
  const [listingItems, setListingItems] = useState<ReadonlyArray<ListingContentItemWithScore>>([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Loading);

  const hiddenColumns = useSelector(getContentItemListingHiddenColumns);
  const showScoreColumn = useSelector(isAiLinkedItemsScoreEnabled);

  const dispatch = useDispatch();

  useEffect(() => {
    const { cancel } = makeCancellablePromise(async () => {
      const recommendedItems = (
        await recommendedLinkedItemsRepository.getRecommendedLinkedItems(itemId, variantId, {
          contentTypeIds: allowedContentTypeIds.length ? allowedContentTypeIds : undefined,
        })
      ).items;
      const loadedItems = await dispatch(
        loadListingItems(recommendedItems.map((item) => item.itemId)),
      );
      return recommendedItems
        .map((recommendedItem) => {
          const loadedItem = loadedItems?.find((item) => item.item.id === recommendedItem.itemId);
          return loadedItem ? { ...loadedItem, ...recommendedItem } : null;
        })
        .filter(notNullNorUndefined);
    })
      .then((items) => {
        setListingItems(items);
        setLoadingStatus(LoadingStatus.Loaded);
      })
      .catch(swallowCancelledPromiseError)
      .catch((e) => {
        if (isXMLHttpRequest(e) && e.status === 404) {
          setLoadingStatus(LoadingStatus.OriginIndexNotFound);
        }
      });

    return cancel;
  }, [allowedContentTypeIds, itemId, variantId]);

  const onItemClick = useCallback(
    (selectedItemId: Uuid) => {
      const listingItem = listingItems.find((item) => item.item.id === selectedItemId);
      if (listingItem) {
        onAddItem(selectedItemId, listingItem.score);
      }
    },
    [listingItems, onAddItem],
  );

  return (
    <RecommendedLinkedItemsModalBodyComponent
      hiddenColumns={hiddenColumns}
      items={listingItems}
      loadingStatus={loadingStatus}
      onItemClick={onItemClick}
      showScoreColumn={showScoreColumn}
    />
  );
};
