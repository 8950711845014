import React from 'react';
import { HtmlPageTitle } from '../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WarningNotification } from './WarningNotification.tsx';

export interface IAccessDeniedDataProps {
  readonly descriptionParagraphs?: readonly string[];
  readonly title: string;
}

export const AccessDenied: React.FC<IAccessDeniedDataProps> = ({
  descriptionParagraphs,
  title,
}) => {
  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.AccessDenied)}>
      <HtmlPageTitle appName={AppNames.AccessDenied} />
      <WarningNotification title={title} descriptionParagraphs={descriptionParagraphs} />
    </div>
  );
};

AccessDenied.displayName = 'AccessDenied';
