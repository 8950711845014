import { ICollection } from '../../../data/models/collections/Collection.ts';
import { anyCollectionCollection } from '../constants/anyCollectionCollection.ts';

export const handleAnyCollectionCollectionOnCollectionsChangedInFormInput = <
  TCollection extends ICollection,
>(
  formValues: ReadonlyArray<TCollection>,
  previousValues: ReadonlyArray<TCollection>,
): ReadonlyArray<TCollection> => {
  const oldValuesContainAnyCollection = previousValues.some(
    (collection) => collection.id === anyCollectionCollection.id,
  );
  const newValuesContainAnyCollection = formValues.some(
    (collection) => collection.id === anyCollectionCollection.id,
  );
  const newValuesContainNormalCollection = formValues.some(
    (collection) => collection.id !== anyCollectionCollection.id,
  );
  return (oldValuesContainAnyCollection && newValuesContainNormalCollection) ||
    !newValuesContainAnyCollection
    ? formValues.filter((collection) => collection.id !== anyCollectionCollection.id)
    : formValues.filter((collection) => collection.id === anyCollectionCollection.id);
};
