import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  SavedStatusMessage,
  SavingStatusMessage,
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';

const getContentTypeSnippetSavingStatusMessage = (state: IStore): StatusMessage => {
  const { contentTypeSnippetIsBeingSaved, editedContentTypeSnippetIsModified } =
    state.contentModelsApp.snippets.editor;

  if (editedContentTypeSnippetIsModified) {
    return UnsavedStatusMessage;
  }

  return contentTypeSnippetIsBeingSaved ? SavingStatusMessage : SavedStatusMessage;
};

export const ContentTypeSnippetSavingStatus: React.ComponentType = getConnectedStatusMessage(
  getContentTypeSnippetSavingStatusMessage,
);
