import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import {
  ContentItemFilterServerModel,
  ContentItemFilterWithContinuationServerModel,
} from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { IListingFilter } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { getOrderBy } from '../../../contentInventory/content/models/filter/contentItemFilterUtils.ts';
import { createContentItemFilterServerModelFromFilter } from '../../../contentInventory/content/utils/contentItemListModelConverters.ts';
import { ContentStatusFilterRepositoryServerModel } from '../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';

export type BuildFilterWithContinuationForContentStatusListingParams = {
  continuationToken: string | null;
  filter: IListingFilter;
  includePublishedVersions: boolean;
  maxItemsCount: number | null;
  orderBy: OrderBy<ItemColumnCode> | null;
  useBackUpSearchMethod: boolean;
  variantIds: ReadonlyArray<Uuid>;
};

export function buildFilterWithContinuationForContentStatusListing({
  continuationToken,
  filter,
  includePublishedVersions,
  maxItemsCount,
  orderBy,
  useBackUpSearchMethod,
  variantIds,
}: BuildFilterWithContinuationForContentStatusListingParams): Readonly<ContentStatusFilterRepositoryServerModel> {
  const result: ContentItemFilterWithContinuationServerModel =
    createContentItemFilterServerModelFromFilter(filter);

  if (orderBy) {
    result.orderBy = getOrderBy(orderBy);
  }

  if (continuationToken) {
    result.continuationToken = continuationToken;
  }

  if (maxItemsCount !== null) {
    result.maxItemsCount = maxItemsCount;
  }

  result.useBackUpSearchMethod = useBackUpSearchMethod;
  result.includePublishedVersions = includePublishedVersions;

  return createContentStatusFilterModelWithLanguages(result, variantIds);
}

const createContentStatusFilterModelWithLanguages = (
  filter: ContentItemFilterServerModel,
  variantIds: ReadonlyArray<Uuid>,
): ContentStatusFilterRepositoryServerModel => ({
  ...filter,
  variantIds,
});
