import Immutable from 'immutable';
import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ModalMultipleContentItemsSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { ContentItemEditing_Archiving_Finished } from '../../../../../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ContentListing_CancelScheduledPublish_Failed,
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_DeleteItems_Finished,
  ContentListing_Filter_Changed,
  ContentListing_MoveToCollection_Finished,
  ContentListing_Page_Left,
  ContentListing_PublishItems_Finished,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_StatusInfo_Reset,
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UndoPublishItems_Finished,
  ContentListing_UndoScheduledPublish_Finished,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UpdateWorkflowStep_Failed,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';

export const successfulIds = (
  state = Immutable.Set<Uuid>(),
  action: Action,
): Immutable.Set<Uuid> => {
  switch (action.type) {
    case ContentListing_MoveToCollection_Finished:
    case ContentListing_PublishItems_Finished:
    case ContentListing_UndoPublishItems_Finished:
    case ContentListing_ScheduledPublishItems_Finished:
    case ContentListing_UndoScheduledPublish_Finished:
    case ContentListing_UnpublishItems_Finished:
    case ContentListing_ScheduledUnpublishItems_Finished:
    case ContentListing_CancelScheduledPublish_Finished:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentListing_DeleteItems_Finished:
    case ContentListing_UndoArchiveItems_Finished:
      return action.payload.successfulIds;

    case ContentListing_Filter_Changed:
    case ContentListing_StatusInfo_Reset:
    case ContentListing_CancelScheduledPublish_Failed:
    case ContentListing_UpdateWorkflowStep_Failed:
    case ContentListing_Page_Left:
    case ModalMultipleContentItemsSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
      return Immutable.Set<Uuid>();

    case ContentItemEditing_Archiving_Finished:
      return Immutable.Set<Uuid>([action.payload.itemId]);

    default:
      return state;
  }
};
