import { Action } from '../../../../@types/Action.type.ts';
import { Relations_SetRoot_Started } from '../constants/relationsActionTypes.ts';

const initialState: Uuid | null = null;

export function selectedRootId(state = initialState, action: Action) {
  switch (action.type) {
    case Relations_SetRoot_Started:
      return action.payload.itemId;

    default:
      return state;
  }
}
