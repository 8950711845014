import { Action } from '../../../../../@types/Action.type.ts';
import {
  ICollection,
  createCollectionFromServerModel,
} from '../../../../../data/models/collections/Collection.ts';
import {
  SubscriptionUsers_CollectionFilter_LoadCollections_Started,
  SubscriptionUsers_CollectionFilter_LoadCollections_Succeeded,
  SubscriptionUsers_EnvironmentFilter_Updated,
  SubscriptionUsers_ProjectFilter_Updated,
} from '../../constants/actionTypes.ts';

const initialState: ReadonlyArray<ICollection> = [];

export const collections = (
  state: ReadonlyArray<ICollection> = initialState,
  action: Action,
): ReadonlyArray<ICollection> => {
  switch (action.type) {
    case SubscriptionUsers_CollectionFilter_LoadCollections_Started:
    case SubscriptionUsers_ProjectFilter_Updated:
    case SubscriptionUsers_EnvironmentFilter_Updated: {
      return initialState;
    }

    case SubscriptionUsers_CollectionFilter_LoadCollections_Succeeded: {
      const { data } = action.payload;
      return data.map((collectionServerModel) =>
        createCollectionFromServerModel(collectionServerModel),
      );
    }

    default:
      return state;
  }
};
