import Immutable from 'immutable';
import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IExistingUserToInvite } from '../../../../../data/models/users/ExistingUserToInvite.ts';
import { InvitationEmailInput as InvitationEmailInputComponent } from '../../components/invitations/InvitationEmailInput.tsx';
import { Invitation } from '../../reducers/IUsersAppState.type.ts';

interface IOwnProps {
  readonly changeInvitations: (newSelected: Immutable.List<Invitation>) => void;
  readonly changeSearchText: (newEmail: string) => void;
  readonly collidingContributorEmails: ReadonlySet<string>;
  readonly createUserDetailLink: (userId: UserId) => string;
  readonly emailInputValidationMessages: ReadonlyArray<string>;
  readonly emailsCountedInSubscriptionLimits: ReadonlyArray<string>;
  readonly existingUsersByEmail: Immutable.Map<string, IExistingUserToInvite>;
  readonly invitations: Immutable.List<Invitation>;
  readonly isInviting: boolean;
  readonly shouldHighlightNewUsers: boolean;
}

export const InvitationEmailInput: React.FC<IOwnProps> = (props) => {
  const searchText = useSelector((s) => s.usersApp.userInvitation.searchText);

  return (
    <InvitationEmailInputComponent
      autoFocus
      searchText={searchText}
      changeInvitations={props.changeInvitations}
      changeSearchText={props.changeSearchText}
      collidingContributorEmails={props.collidingContributorEmails}
      createUserDetailLink={props.createUserDetailLink}
      emailInputValidationMessages={props.emailInputValidationMessages}
      emailsCountedInSubscriptionLimits={props.emailsCountedInSubscriptionLimits}
      existingUsersByEmail={props.existingUsersByEmail}
      invitations={props.invitations}
      isInviting={props.isInviting}
      shouldHighlightNewUsers={props.shouldHighlightNewUsers}
    />
  );
};

InvitationEmailInput.displayName = 'InvitationEmailInput';
