import { isInputAReasonableInteger } from '../../../contentModels/shared/utils/typeElementValidators/utils/isInputAReasonableInteger.ts';
import { MaximumOutputDimensions } from '../ContentItemEditing/utils/renditionUtils.ts';

export const isValidInput = (
  input: string | null,
  originalDimension: number,
  dimensionName: string,
): boolean => !getInvalidInputReason(input, originalDimension, dimensionName);

export const getInvalidInputReason = (
  input: string | null,
  originalDimension: number,
  dimensionName: string,
): string | undefined => {
  if (!input) {
    return 'Value must be provided.';
  }

  const inputNumber = Number(input);

  if (inputNumber > originalDimension) {
    return `Value must be smaller than the original ${dimensionName.toLowerCase()}.`;
  }

  if (inputNumber > MaximumOutputDimensions) {
    return `Value can’t exceed the hard limit of ${MaximumOutputDimensions} px.`;
  }

  if (!isInputAReasonableInteger(input) || inputNumber < 1) {
    return 'Value is filled incorrectly.';
  }

  return undefined;
};
