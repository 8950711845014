import { Action } from '../../../../../@types/Action.type.ts';
import {
  ContentModels_TypeEditor_TypeElementCreated,
  ContentModels_TypeEditor_TypeElementDropped,
  ContentModels_TypeEditor_TypeElementMoved,
} from '../../constants/sharedContentModelsActionTypes.ts';
import {
  ITypeElementAddition,
  ITypeElementMovement,
  LastAction,
} from '../../types/LastAction.type.ts';
import { LastActionType } from '../../types/LastActionTypes.ts';

export function lastAction(state: LastAction = null, action: Action): LastAction {
  switch (action.type) {
    case ContentModels_TypeEditor_TypeElementCreated: {
      return {
        id: action.payload.typeElementData.elementId,
        type: LastActionType.ElementAddition,
      } as ITypeElementAddition;
    }

    case ContentModels_TypeEditor_TypeElementDropped: {
      return state;
    }

    case ContentModels_TypeEditor_TypeElementMoved: {
      if (state === null) {
        return {
          source: action.payload.sourceId,
          target: action.payload.targetId,
          type: LastActionType.ElementMovement,
        } as ITypeElementMovement;
      }

      return {
        ...state,
        source: action.payload.sourceId,
      } as ITypeElementMovement;
    }

    default:
      return null;
  }
}
