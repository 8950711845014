import { CopyState } from '../constants/copyState.ts';

export const isCloningInProgress = (copyState: CopyState): boolean => {
  return [CopyState.CopyingDeliver, CopyState.CopyingDraft].includes(copyState);
};

export const isCloningFailed = (copyState: CopyState): boolean => {
  return copyState === CopyState.Failed;
};

export const isCloningDone = (copyState: CopyState): boolean => {
  return copyState === CopyState.Done;
};

export const parseCopyState = (copyState: string): CopyState => {
  switch (copyState) {
    case 'CopyingDraftData':
      return CopyState.CopyingDraft;
    case 'CopyingDeliverData':
      return CopyState.CopyingDeliver;
    case 'Done':
      return CopyState.Done;
    case 'Failed':
      return CopyState.Failed;
    case '':
      return CopyState.NotCloned;
    default:
      throw new Error(`Unexpected project copyState ${copyState}`);
  }
};
