import { IDoneOnTimeCountSummary } from '../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';

export const getDoneOnTimePercentage = (
  countSummary: IDoneOnTimeCountSummary | undefined | null,
): number => {
  if (!countSummary || !countSummary?.onTimeCount || !countSummary?.allCount) {
    return 0;
  }

  return (countSummary.onTimeCount / countSummary.allCount) * 100;
};

export const getWidgetComparisonStatus = (diff: number) => {
  if (diff === 0) {
    return 'Same as in the previous period';
  }

  return diff < 0 ? 'less than in the previous period' : 'more than in the previous period';
};

export const getCorrectFormOfBeVerbWithCountOnTime = (onTimeCount: number) => {
  switch (onTimeCount) {
    case 0:
      return 'none were';
    case 1:
      return `${onTimeCount} was`;
    default:
      return `${onTimeCount} were`;
  }
};
