import { createGuid } from '@kontent-ai/utils';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { CommentThreadItemType } from '../../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
  IInlineCommentThread,
  emptyCommentThread,
} from '../../../../models/comments/CommentThreads.ts';

export type IStartNewInlineCommentThread = (
  newCommentThreadData: NewCommentThreadData,
) => ThunkFunction<ICommentThread | null>;

interface IDeps {
  readonly inlineCommentThreadAdded: (
    newThread: ICommentThread,
    type: CommentThreadItemType,
  ) => Action;
}

export type NewCommentThreadData = {
  readonly externalSegmentId: string;
  readonly elementId: Uuid;
  readonly itemType: CommentThreadItemType;
  readonly threadType: CommentThreadType;
  readonly elementSegment: string | null;
  readonly contentComponentId: Uuid | null;
};

export const createStartNewInlineCommentThread =
  (deps: IDeps): IStartNewInlineCommentThread =>
  (newCommentThreadData) =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
    } = getState();

    const contentItemId = editedContentItemVariant?.id;
    if (!contentItemId) {
      return null;
    }

    const tempThread: IInlineCommentThread = {
      ...emptyCommentThread,
      id: createGuid(),
      contentItemId: contentItemId.itemId,
      variantId: contentItemId.variantId,
      threadType: newCommentThreadData.threadType,
      contentComponentId: newCommentThreadData.contentComponentId,
      elementId: newCommentThreadData.elementId,
      externalSegmentId: newCommentThreadData.externalSegmentId,
      elementSegment: newCommentThreadData.elementSegment,
      isUnsaved: true,
      isInUndoResolvedState: false,
    };

    dispatch(deps.inlineCommentThreadAdded(tempThread, newCommentThreadData.itemType));

    return tempThread;
  };
