import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import {
  Subscription_CurrentSpending_Clear,
  Subscription_CurrentSpending_LoadFinished,
} from '../../constants/actionTypes.ts';
import {
  IBillingPeriodReport,
  createBillingPeriodReportDomainModel,
} from '../../models/BillingPeriodReport.ts';

interface ILoadSubscriptionCurrentSpendingDependencies {
  readonly subscriptionRepository: Pick<
    ISubscriptionRepository,
    'getSubscriptionCurrentSpendingReport'
  >;
}

const clearCurrentSpendingReport = () =>
  ({
    type: Subscription_CurrentSpending_Clear,
  }) as const;

const currentSpendingLoadingFinished = (report: IBillingPeriodReport | null) =>
  ({
    type: Subscription_CurrentSpending_LoadFinished,
    payload: { report },
  }) as const;

export type LoadSubscriptionCurrentSpendingActionsType = ReturnType<
  typeof clearCurrentSpendingReport | typeof currentSpendingLoadingFinished
>;

export const loadSubscriptionCurrentSpendingCreator =
  (dependencies: ILoadSubscriptionCurrentSpendingDependencies) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    dispatch(clearCurrentSpendingReport());

    const reportServerModel =
      await dependencies.subscriptionRepository.getSubscriptionCurrentSpendingReport(
        subscriptionId,
        abortSignal,
      );

    const report = reportServerModel
      ? createBillingPeriodReportDomainModel(reportServerModel)
      : null;

    dispatch(currentSpendingLoadingFinished(report));
  };
