import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import {
  SubscriptionUserDetail_RevokeInviteToProjects_Failed,
  SubscriptionUserDetail_RevokeInviteToProjects_Finished,
  SubscriptionUserDetail_RevokeInviteToProjects_Started,
} from '../../constants/actionTypes.ts';

interface IDeps {
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadSubscriptionUsersUsage: () => ThunkPromise;
  readonly peopleRepository: Pick<IPeopleRepository, 'revokeInviteToProject'>;
}

const started = (userId: UserId, projectIds: ReadonlyArray<Uuid>) =>
  ({
    type: SubscriptionUserDetail_RevokeInviteToProjects_Started,
    payload: {
      userId,
      projectIds,
    },
  }) as const;

const finished = (userId: UserId, projectIds: ReadonlyArray<Uuid>) =>
  ({
    type: SubscriptionUserDetail_RevokeInviteToProjects_Finished,
    ppayload: {
      userId,
      projectIds,
    },
  }) as const;

const failed = (userId: UserId, projectIds: ReadonlyArray<Uuid>) =>
  ({
    type: SubscriptionUserDetail_RevokeInviteToProjects_Failed,
    payload: {
      userId,
      projectIds,
    },
  }) as const;

export type RevokeInviteToProjectsActionTypes = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createRevokeInviteToProjects =
  (deps: IDeps) =>
  (
    userId: UserId,
    subscriptionId: Uuid,
    projectIds: ReadonlyArray<Uuid>,
    onSuccess?: () => void,
  ): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started(userId, projectIds));

    try {
      const sentRequests = projectIds.reduce((requests: Array<Promise<void>>, projectId: Uuid) => {
        const request = deps.peopleRepository.revokeInviteToProject(
          userId,
          subscriptionId,
          projectId,
        );
        requests.push(request);
        return requests;
      }, new Array<Promise<void>>());

      await Promise.all(sentRequests);

      dispatch(finished(userId, projectIds));
      onSuccess?.();
    } catch (e) {
      logError(e);
      dispatch(failed(userId, projectIds));
    } finally {
      dispatch(deps.loadSubscriptionUsage(subscriptionId));
      dispatch(deps.loadSubscriptionUsersUsage());
    }
  };
