import { InvariantException } from '@kontent-ai/errors';
import { ISubscriptionServerModel } from '../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import {
  EmptySubscriptionDetails,
  ISubscriptionDetails,
  createSubscriptionDetailsDomainModel,
} from './SubscriptionDetails.ts';
import {
  ISubscriptionPlan,
  createSubscriptionPlan,
  createSubscriptionPlanFromServerModel,
} from './SubscriptionPlan.ts';

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Expired = 'expired',
  Suspended = 'suspended',
}

export interface ISubscription {
  readonly azureMarketplaceSubscriptionId: string;
  readonly createdAt: DateTimeStamp;
  readonly currentPlan: ISubscriptionPlan;
  readonly isActive: boolean;
  readonly isFsSubscriptionConnected: boolean;
  readonly status: SubscriptionStatus;
  readonly statusLastChanged: DateTimeStamp;
  readonly statusReason: string | null;
  readonly subscriptionDetails: ISubscriptionDetails;
  readonly subscriptionId: Uuid;
  readonly subscriptionName: string;
}

export const emptySubscription: ISubscription = {
  azureMarketplaceSubscriptionId: '',
  createdAt: '',
  currentPlan: createSubscriptionPlan(),
  isActive: false,
  isFsSubscriptionConnected: false,
  status: SubscriptionStatus.Expired,
  statusLastChanged: '',
  statusReason: null,
  subscriptionDetails: EmptySubscriptionDetails,
  subscriptionId: '',
  subscriptionName: '',
};

export function createSubscriptionFromServerModel(
  serverModel: ISubscriptionServerModel,
): ISubscription {
  if (typeof serverModel !== 'object') {
    throw InvariantException('Administrated subscription is not an object');
  }

  return {
    azureMarketplaceSubscriptionId:
      serverModel.azureMarketplaceSubscriptionId ??
      emptySubscription.azureMarketplaceSubscriptionId,
    createdAt: serverModel.createdAt ?? emptySubscription.createdAt,
    currentPlan: serverModel.currentPlan
      ? createSubscriptionPlanFromServerModel(serverModel.currentPlan)
      : emptySubscription.currentPlan,
    isActive: serverModel.isActive ?? emptySubscription.isActive,
    isFsSubscriptionConnected: !!serverModel.fsSubscriptionRef,
    status: getSubscriptionStatus(serverModel),
    statusLastChanged: serverModel.statusLastChanged ?? emptySubscription.statusLastChanged,
    statusReason: serverModel.statusReason ?? emptySubscription.statusReason,
    subscriptionDetails: serverModel.subscriptionDetails
      ? createSubscriptionDetailsDomainModel(serverModel.subscriptionDetails)
      : emptySubscription.subscriptionDetails,
    subscriptionId: serverModel.subscriptionId ?? emptySubscription.subscriptionId,
    subscriptionName: serverModel.subscriptionName ?? emptySubscription.subscriptionName,
  };
}

function getSubscriptionStatus(serverModel: ISubscriptionServerModel): SubscriptionStatus {
  switch (serverModel.status) {
    case 'active':
      return SubscriptionStatus.Active;
    case 'canceled':
      return SubscriptionStatus.Canceled;
    case 'suspended':
      return SubscriptionStatus.Suspended;
    case 'expired':
      return SubscriptionStatus.Expired;
    default:
      throw InvariantException(`Unknown subscription status: ${serverModel.statusReason}`);
  }
}
