import { assert } from '@kontent-ai/utils';
import React from 'react';
import { AutoScrollProps } from '../../../../../../_shared/components/AutoScroll/autoScrollProps.type.ts';
import {
  ContentLinkState,
  getContentLinkState,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RedactedItemName } from '../../../../../contentInventory/content/constants/uiConstants.ts';
import {
  ArchivedContentLink,
  NonExistingContentLink,
  NonTranslatedContentLink,
  ReadonlyContentLink,
  ValidContentLink,
} from './ContentLinkDetailVariants.tsx';

interface ISpecificContentLinkProps extends AutoScrollProps {
  readonly canCreateContent: boolean;
  readonly cannotCreateDisabledMessage: string | undefined;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly contentItem?: IListingContentItem;
  readonly contentItemPath?: string;
  readonly contentTypeName: string | null;
  readonly isViewOnly?: boolean;
}

export const SpecificContentLink: React.FC<ISpecificContentLinkProps> = ({
  alternativeScrollIds,
  canCreateContent,
  cannotViewDisabledMessage,
  cannotCreateDisabledMessage,
  contentItem,
  contentItemPath,
  contentTypeName,
  isViewOnly,
  scrollId,
  scrollOptions,
}) => {
  const contentLinkState = getContentLinkState(
    contentItem,
    canCreateContent,
    !cannotViewDisabledMessage,
  );
  switch (contentLinkState) {
    case ContentLinkState.Archived: {
      assert(contentItem, () => 'Content item was not provided.');
      return <ArchivedContentLink contentItem={contentItem} contentTypeName={contentTypeName} />;
    }

    case ContentLinkState.NonTranslated: {
      assert(contentItem, () => 'Content item was not provided.');
      return (
        <NonTranslatedContentLink
          alternativeScrollIds={alternativeScrollIds}
          contentItem={contentItem}
          // Opening not translated link in a new window is not allowed in view mode
          // as it could create a new empty language version outside the context of the current editing session
          contentItemPath={isViewOnly ? '' : contentItemPath}
          contentTypeName={contentTypeName}
          isViewOnly={isViewOnly}
          scrollId={scrollId}
          scrollOptions={scrollOptions}
        />
      );
    }

    case ContentLinkState.NonTranslatedReadonly: {
      assert(contentItem, () => 'Content item was not provided.');
      return (
        <ReadonlyContentLink
          contentItemVariant={contentItem?.variant}
          cannotViewMessage={undefined}
          cannotCreateMessage={cannotCreateDisabledMessage}
          itemName={contentItem.item.name}
          typeName={contentTypeName}
        />
      );
    }

    case ContentLinkState.Redacted: {
      assert(contentItem, () => 'Content item was not provided.');
      return (
        <ReadonlyContentLink
          contentItemVariant={contentItem?.variant}
          cannotViewMessage={cannotViewDisabledMessage}
          cannotCreateMessage={undefined}
          itemName={RedactedItemName}
          typeName={null}
        />
      );
    }

    case ContentLinkState.Valid: {
      assert(contentItem?.variant, () => 'Content item or its variant was not provided.');
      return (
        <ValidContentLink
          alternativeScrollIds={alternativeScrollIds}
          contentItem={contentItem}
          contentItemPath={contentItemPath || ''}
          contentTypeName={contentTypeName}
          isViewOnly={isViewOnly}
          publishingState={contentItem.variant.publishingState}
          scrollId={scrollId}
          scrollOptions={scrollOptions}
          workflowStatus={contentItem.variant.assignment.workflowStatus}
        />
      );
    }

    case ContentLinkState.NonExisting: {
      return <NonExistingContentLink />;
    }

    default:
      return null;
  }
};
SpecificContentLink.displayName = 'SpecificContentLink';
