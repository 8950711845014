import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadCollections,
  loadContentTypes,
  loadSpaces,
} from '../../../../data/actions/thunkDataActions.ts';
import { createContentItemVariant } from '../../../itemEditor/features/ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import {
  ensureLoadedContentTypes,
  loadListingItems,
} from '../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { createContentItem } from '../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { createCreateSpaceAction } from './thunks/createSpace.ts';
import { createDeleteSpaceAction } from './thunks/deleteSpace.ts';
import { createEnsureRootItemCreatedAction } from './thunks/ensureRootItemCreated.ts';
import { createInitializeSpacesAppAction } from './thunks/initializeSpacesApp.ts';
import { createPreloadWebSpotlightRootItem } from './thunks/preloadWebSpotlightRootItem.ts';
import { createRestoreSpaceAction } from './thunks/restoreSpace.ts';
import { createUpdateSpaceAction } from './thunks/updateSpace.ts';

const { spacesRepository } = repositoryCollection;

export const initializeSpacesApp = createInitializeSpacesAppAction({
  ensureLoadedContentTypes,
  loadCollections,
  loadContentTypes,
  loadSpaces,
});

const ensureRootItemCreated = createEnsureRootItemCreatedAction({
  createContentItem,
  createContentItemVariant,
});

export const createSpace = createCreateSpaceAction({
  spacesRepository,
  ensureRootItemCreated,
});

export const updateSpace = createUpdateSpaceAction({
  spacesRepository,
  ensureRootItemCreated,
});

export const deleteSpace = createDeleteSpaceAction({
  spacesRepository,
});

export const restoreSpace = createRestoreSpaceAction({
  spacesRepository,
});

export const preloadWebSpotlightRootItem = createPreloadWebSpotlightRootItem({
  loadListingItems,
});
