import styled, { css } from 'styled-components';
import { radiusFocus } from '../../../tokens/decision/focus.ts';
import { spacingSimpleStatusProtectiveArea } from '../../../tokens/decision/spacing.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';

export const StyledSimpleStatus = styled.div<{ readonly isFocusVisible: boolean }>`
    display: inline-block;
    max-width: 100%;
    padding: ${px(spacingSimpleStatusProtectiveArea)};
    ${({ isFocusVisible }) =>
      isFocusVisible &&
      css`
      border-radius: ${px(radiusFocus)};
      ${shadowFocusStyles};
    `};
`;
