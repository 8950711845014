import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IFormGroupProps {
  readonly children: React.ReactNode;
  readonly isCompact?: boolean;
}

const FormGroup: React.FC<IFormGroupProps> = ({ children, isCompact }) => (
  <div
    className={classNames('form__group', {
      'form__group--is-compact': isCompact,
    })}
    {...getDataUiElementAttribute(DataUiElement.FormGroup)}
  >
    {children}
  </div>
);

FormGroup.displayName = 'FormGroup';

export { FormGroup };
