import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState } from '@kontent-ai/component-library/Input';
import { SimpleStatusError } from '@kontent-ai/component-library/SimpleStatus';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { SubscriptionIdLoadingFailed } from '../constants/errorMessageTemplates.ts';

type Props = {
  readonly subscriptionId: Uuid;
};

export const SubscriptionIdCard: React.FC<Props> = ({ subscriptionId }) => {
  const errorMessage = isUuid(subscriptionId) ? undefined : SubscriptionIdLoadingFailed;

  return (
    <Card
      component="section"
      cardLabel="Subscription ID"
      {...getDataUiElementAttribute(DataUiElement.SubscriptionIdCard)}
    >
      <Card.Headline>Subscription ID</Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <Box>
            Use the following subscription ID when making API calls against this subscription.
          </Box>
          <Stack spacing={Spacing.XS}>
            <Clipboard
              inputState={errorMessage ? InputState.Alert : InputState.ReadOnly}
              label="Subscription ID"
              value={subscriptionId}
              renderClipboardButton={(props) => (
                <Clipboard.Button
                  {...props}
                  {...getDataUiActionAttribute(DataUiAction.CopyToClipboard)}
                />
              )}
              {...getDataUiInputAttribute(DataUiInput.Id)}
            />
            {errorMessage && <SimpleStatusError label={errorMessage} />}
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};
SubscriptionIdCard.displayName = 'SubscriptionIdCard';
