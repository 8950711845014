import React from 'react';

export const ConditionalWrapper: React.FC<
  React.PropsWithChildren<{
    readonly condition: boolean;
    readonly wrapper: (children: React.ReactNode) => React.ReactElement;
  }>
> = ({ children, condition, wrapper }) => (condition ? wrapper(children) : <>{children}</>);

ConditionalWrapper.displayName = 'ConditionalWrapper';
