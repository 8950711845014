export enum EditorChangeReason {
  // New value came from external source
  ExternalUpdate = 'ExternalUpdate',
  // Changes made natively by the DraftJS editor (typing text, spell check from browser, etc.)
  Native = 'Native',
  // Normal changes from editor
  Regular = 'Regular',
  // Internal changes typically as a follow-up to some async action (thunk, modal dialog)
  Internal = 'Internal',
  // Adding comment metadata
  Comment = 'Comment',
  // Dragging an item, which is a physical content change, but not dispatched until the item is dropped
  Drag = 'Drag',

  Undo = 'Undo',
  Redo = 'Redo',
}

export const internalChangeReasons: ReadonlySet<EditorChangeReason> = new Set([
  EditorChangeReason.Internal,
  EditorChangeReason.Undo,
  EditorChangeReason.Redo,
]);
