import { BaseTextTypeElementValidationResult } from '../types/BaseTextTypeElementValidationResult.type.ts';

export const BaseTextInitialValidationResult: BaseTextTypeElementValidationResult = {
  isGuidelinesValid: true,
  isNameValid: true,
  isMaxCharsValid: true,
  isMaxWordsValid: true,
  errorMessages: [],
  warningMessages: [],
};
