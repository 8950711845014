import { addMilliseconds } from 'date-fns';
import {
  renderDatetimeString,
  toFuzzyDateDifference,
  toLocalTime,
} from '../../../utils/dateTime/timeUtils.ts';

const THREE_DAYS_IN_MILLISECONDS = 3600 * 24 * 3 * 1000;
const dash = '—';

export interface IFormattedDueDate {
  readonly dueDateFuzzy: string;
  readonly dueDateReadable: string | null;
  readonly overdue: boolean;
  readonly almostDue: boolean;
}

export const getFormattedDueDate = (dueDate: DateTimeStamp | null): IFormattedDueDate => {
  const now = new Date();
  if (dueDate) {
    const dueDateLocal = toLocalTime(dueDate);
    if (dueDateLocal) {
      return {
        dueDateFuzzy: toFuzzyDateDifference(now, dueDateLocal, false),
        dueDateReadable: renderDatetimeString(dueDate),
        overdue: now > dueDateLocal,
        almostDue: addMilliseconds(now, THREE_DAYS_IN_MILLISECONDS) >= dueDateLocal, // Due in three days
      };
    }
  }

  return {
    dueDateFuzzy: dash,
    dueDateReadable: null,
    overdue: false,
    almostDue: false,
  };
};
