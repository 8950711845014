import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IWebSpotlightRepository } from '../../../../repositories/interfaces/IWebSpotlightRepository.type.ts';
import { ItemSubpagesIds } from '../../../../repositories/serverModels/SubpageItemIdsServerModel.type.ts';
import { WebSpotlight_Subpages_Loaded } from '../../constants/webSpotlightActionTypes.ts';

interface IDeps {
  readonly webSpotlightRepository: IWebSpotlightRepository;
}

const loaded = (itemSubpagesById: ReadonlyArray<ItemSubpagesIds>) =>
  ({
    type: WebSpotlight_Subpages_Loaded,
    payload: {
      itemSubpagesById,
    },
  }) as const;

export type LoadSubpagesIdsActionType = ReturnType<typeof loaded>;

export const loadSubpagesIdsActionCreator =
  (deps: IDeps) =>
  (contentItemIds: UuidArray, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const variantId = getSelectedLanguageIdOrThrow(getState());
    const response = await deps.webSpotlightRepository.getSubpagesIds(
      variantId,
      contentItemIds,
      abortSignal,
    );

    dispatch(loaded(response.data));
  };
