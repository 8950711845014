import { memoize } from '@kontent-ai/memoization';
import { ContentBlock, DraftInlineStyle, EntityInstance } from 'draft-js';
import { always } from '../../../../../_shared/utils/func/functionalTools.ts';
import { getEntityRanges } from '../../../plugins/entityApi/api/getEntityRanges.ts';
import { getStyles, isVisualStyle } from '../../../plugins/inlineStyles/api/editorStyleUtils.ts';
import {
  ICoordinates,
  getCoordinatesFromDepth,
} from '../../../plugins/tables/api/depthCoordinatesConversion.ts';
import { BaseBlockType, BlockType } from '../../blocks/blockType.ts';
import {
  isCustomBlockSleeve,
  isListItem,
  isTableCell,
  isUnstyledBlock,
} from '../../blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../../blocks/editorBlockGetters.ts';
import { IBlockHierarchyNode } from '../../blocks/editorHierarchyUtils.ts';
import { EntityMap } from '../../general/editorContentGetters.ts';
import { VirtualBlockType, getBlockTagData as defaultGetBlockTagData } from './elements/blocks.ts';
import {
  ObjectDataType,
  getObjectBlockData as defaultGetObjectBlockData,
  getObjectTagData,
} from './elements/objects.ts';
import { GetStyleData, getStyleData as defaultGetStyleData } from './elements/styles.ts';
import { INDENT } from './elements/symbols.ts';
import {
  HtmlData,
  TagData,
  getEndHtml,
  getEndTagHtml,
  getHtml,
  getStartHtml,
  getStartTagHtml,
} from './elements/tags.ts';

const preserveWhitespace = (text: string): string => {
  // Prevent leading/trailing/consecutive whitespace collapse.
  const chars = Array.from(text);
  const newChars = chars.map((char, index) =>
    char === ' ' && (index === 0 || index === chars.length - 1 || chars[index - 1] === ' ')
      ? '\xA0'
      : char,
  );

  return newChars.join('');
};

const areVisualStylesTheSame = (first: DraftInlineStyle, second: DraftInlineStyle): boolean => {
  const firstVisualStyle = getStyles(first, isVisualStyle);
  const secondVisualStyle = getStyles(second, isVisualStyle);

  return firstVisualStyle.equals(secondVisualStyle);
};

const getTextBlockData = memoize.weak(
  (
    block: ContentBlock,
    entityMap: EntityMap,
    getEntityData: GetEntityData,
    getStyleData: GetStyleData,
  ): HtmlData => {
    const blockText = preserveWhitespace(block.getText());
    if (!blockText) {
      // Prevent element collapse if completely empty.
      return [
        {
          tagName: 'br',
          selfClosing: true,
        },
      ];
    }
    const charMetaList = block.getCharacterList();
    const entityPieces = getEntityRanges(blockText, charMetaList, areVisualStylesTheSame);

    return entityPieces.flatMap(([entityKey, stylePieces]) => {
      const entityHtmlContent = stylePieces.flatMap(
        ([text, style]) => getStyleData(style, text) ?? text,
      );

      const entity = entityKey ? entityMap.__get(entityKey) : null;
      if (entity) {
        const entityTags = getEntityData(entity, entityHtmlContent);
        if (entityTags) {
          return entityTags;
        }
      }

      return entityHtmlContent;
    });
  },
);

const getFirstListItemInDepth = (
  blocks: ReadonlyArray<ContentBlock>,
  currentBlockIndex: number,
  depth: number,
): ContentBlock | null => {
  let index = currentBlockIndex;
  while (index < blocks.length) {
    const block = blocks[index];
    if (!block || !isListItem(block)) {
      return null;
    }
    const blockDepth = block.getDepth();
    if (blockDepth === depth) {
      return block;
    }
    if (blockDepth < depth) {
      return null;
    }
    index++;
  }

  return null;
};

type GenerateHtmlData = {
  readonly entityMap: EntityMap;
  readonly metadata?: ReadonlyMap<ObjectDataType, Uuid>;
  readonly nodes: ReadonlyArray<IBlockHierarchyNode>;
};

type GetBlockTagData = (blockType: BaseBlockType | VirtualBlockType) => TagData;
type GetObjectBlockData = (block: ContentBlock) => HtmlData | null;
type GetEntityData = (entity: EntityInstance, content: HtmlData) => HtmlData | null;

type GenerateHtmlOptions = {
  readonly defaultIndent: string;
  readonly getBlockTagData: GetBlockTagData;
  readonly getEntityData: GetEntityData;
  readonly getObjectBlockData: GetObjectBlockData;
  readonly getStyleData: GetStyleData;
  readonly indent: string;
  readonly newLine: string;
};

const defaultOptions: GenerateHtmlOptions = {
  defaultIndent: '',
  getBlockTagData: defaultGetBlockTagData,
  getEntityData: always(null),
  getObjectBlockData: defaultGetObjectBlockData,
  getStyleData: defaultGetStyleData,
  indent: INDENT,
  newLine: '\n',
} as const;

export const generateHtml = (
  data: GenerateHtmlData,
  options?: Partial<GenerateHtmlOptions>,
): string => {
  const { entityMap, metadata, nodes } = data;

  const blocks = nodes.map((node) => node.block);
  let currentBlockIndex: number = 0;

  const output: Array<string> = [];
  let indentLevel: number = 0;

  const {
    defaultIndent,
    getBlockTagData,
    getEntityData,
    getObjectBlockData,
    getStyleData,
    indent,
    newLine,
  } = {
    ...defaultOptions,
    ...options,
  };

  const processNested = (nestedNodes: ReadonlyArray<IBlockHierarchyNode>): void => {
    const nestedHtml = generateHtml(
      {
        nodes: nestedNodes,
        entityMap,
        metadata,
      },
      {
        ...options,
        defaultIndent: '',
      },
    );

    output.push(`${nestedHtml}${newLine}`);
  };

  const processBlocks = (): void => {
    while (currentBlockIndex < blocks.length) {
      processBlock();
    }
  };

  const processBlock = (): void => {
    const block = blocks[currentBlockIndex];
    if (!block) {
      return;
    }
    const blockType = getBaseBlockType(block);

    switch (blockType) {
      case BlockType.TableCell: {
        processTable();
        return;
      }

      case BlockType.Image:
      case BlockType.ContentModule:
      case BlockType.ContentComponent: {
        processObjectBlock(block);
        return;
      }

      case BlockType.OrderedListItem:
      case BlockType.UnorderedListItem: {
        processLists(0);
        return;
      }

      case BlockType.Unstyled: {
        if (isCustomBlockSleeve(currentBlockIndex, blocks)) {
          currentBlockIndex++;
          return;
        }
        break;
      }

      case BlockType.NewBlockPlaceholder: {
        currentBlockIndex++;
        return;
      }

      default:
        break;
    }

    processTextBlock(block);
  };

  const processTextBlock = (block: ContentBlock): void => {
    writeStartTag(block);
    const textBlockData = getTextBlockData(block, entityMap, getEntityData, getStyleData);
    output.push(getHtml(textBlockData));
    writeEndTag(block);
    currentBlockIndex++;
  };

  const processObjectBlock = (block: ContentBlock): void => {
    const objectBlockData = getObjectBlockData(block);
    if (objectBlockData?.length) {
      output.push(`${getHtml(objectBlockData)}${newLine}`);
    }
    currentBlockIndex++;
  };

  const processListItem = (block: ContentBlock): void => {
    writeIndent();
    writeStartTag(block);
    const listItemContent = getTextBlockData(block, entityMap, getEntityData, getStyleData);
    output.push(getHtml(listItemContent));

    // Look ahead and see if we will nest list.
    const nextBlock = peekNextBlock();
    if (nextBlock && isListItem(nextBlock)) {
      const nextBlockDepth = nextBlock.getDepth();
      const itemDepth = block.getDepth();
      if (nextBlockDepth > itemDepth) {
        // Nested list
        output.push(newLine);
        indentLevel++;
        currentBlockIndex++;

        processLists(itemDepth + 1);

        indentLevel--;
        writeIndent();
        writeEndTag(block);
        return;
      }
    }

    currentBlockIndex++;
    writeEndTag(block);
  };

  const processListItems = (itemBlockType: BaseBlockType, depth: number): void => {
    while (currentBlockIndex < blocks.length) {
      const block = blocks[currentBlockIndex];
      if (!block) {
        return;
      }
      const blockType = getBaseBlockType(block);
      if (blockType !== itemBlockType || block.getDepth() !== depth) {
        return;
      }
      processListItem(block);
    }
  };

  const processList = (depth: number): void => {
    // The current item doesn't have to be at the right level, we need to find the first child at the right level to correctly choose the wrapper tag
    const firstDirectChild =
      getFirstListItemInDepth(blocks, currentBlockIndex, depth) || blocks[currentBlockIndex];
    if (!firstDirectChild) {
      return;
    }
    const firstListBlockType = getBaseBlockType(firstDirectChild);

    const wrapperTag = getBlockTagData(
      firstListBlockType === BlockType.OrderedListItem
        ? VirtualBlockType.OrderedList
        : VirtualBlockType.UnorderedList,
    );
    if (wrapperTag) {
      writeOpeningWrapperTag(wrapperTag);
    }

    const block = blocks[currentBlockIndex];
    if (block && block.getDepth() > depth) {
      // Make enough levels of list in case the indentation is deeper than 1 level
      processList(depth + 1);
    }

    // Process child items, should there be any
    const firstDirectChildDepth = firstDirectChild.getDepth();
    if (firstDirectChildDepth === depth) {
      processListItems(firstListBlockType, firstDirectChildDepth);
    }

    if (wrapperTag) {
      writeClosingWrapperTag(wrapperTag);
    }
  };

  const processLists = (depth: number): void => {
    processList(depth);

    // Continue with siblings at the same level, they are potentially part of the same list parent
    const firstBlockAfterList = blocks[currentBlockIndex];
    if (
      firstBlockAfterList &&
      firstBlockAfterList.getDepth() === depth &&
      isListItem(firstBlockAfterList)
    ) {
      processLists(depth);
    }
  };

  const processHierarchicalNode = (): void => {
    const node = nodes[currentBlockIndex];
    if (!node) {
      return;
    }

    if (!node.childNodes.length) {
      // Node without child nodes - Just use its content
      const cellContent = getTextBlockData(node.block, entityMap, getEntityData, getStyleData);
      output.push(getHtml(cellContent));
    } else if (
      node.childNodes.length === 1 &&
      node.childNodes[0] &&
      isUnstyledBlock(node.childNodes[0].block)
    ) {
      // Single unstyled child node - output just its content, no wrapping P tag
      const unstyledContent = getTextBlockData(
        node.childNodes[0].block,
        entityMap,
        getEntityData,
        getStyleData,
      );
      output.push(getHtml(unstyledContent));
    } else {
      // Rich text children, process recursively as nested content
      processNested(node.childNodes);
    }
  };

  const processTableCell = (): void => {
    const tagData = getBlockTagData(BlockType.TableCell);
    output.push(getStartTagHtml(tagData));
    processHierarchicalNode();
    output.push(getEndTagHtml(tagData));
    currentBlockIndex++;
  };

  const processTable = (): void => {
    const tableTags = [
      getBlockTagData(VirtualBlockType.Table),
      getBlockTagData(VirtualBlockType.TableBody),
    ];
    const rowTagData = getBlockTagData(VirtualBlockType.TableRow);

    output.push(`${getStartHtml(tableTags)}${newLine}${indent}${getStartTagHtml(rowTagData)}`);

    let lastCoords: ICoordinates | null = null;
    let currentBlock = blocks[currentBlockIndex];
    while (currentBlock && isTableCell(currentBlock)) {
      const coords = getCoordinatesFromDepth(currentBlock.getDepth());
      if (lastCoords && coords.y !== lastCoords.y) {
        output.push(
          `${getEndTagHtml(rowTagData)}${newLine}${indent}${getStartTagHtml(rowTagData)}`,
        );
      }
      processTableCell();
      lastCoords = coords;
      currentBlock = blocks[currentBlockIndex];
    }

    output.push(`${getEndTagHtml(rowTagData)}${newLine}${getEndHtml(tableTags)}${newLine}`);
  };

  const writeMetadataNodes = (): void => {
    metadata?.forEach((value, key) => {
      const tagData = getObjectTagData(key, value);
      output.push(`${getStartTagHtml(tagData)}${getEndTagHtml(tagData)}${newLine}`);
    });
  };

  const peekNextBlock = (): ContentBlock | null => {
    return blocks[currentBlockIndex + 1] ?? null;
  };

  const writeStartTag = (block: ContentBlock): void => {
    const tagData = getBlockTagData(getBaseBlockType(block));
    output.push(getStartTagHtml(tagData));
  };

  const writeEndTag = (block: ContentBlock): void => {
    const tagData = getBlockTagData(getBaseBlockType(block));
    output.push(`${getEndTagHtml(tagData)}${newLine}`);
  };

  const writeOpeningWrapperTag = (tagData: TagData): void => {
    writeIndent();
    output.push(`${getStartTagHtml(tagData)}${newLine}`);
    indentLevel += 1;
  };

  const writeClosingWrapperTag = (tagData: TagData): void => {
    indentLevel -= 1;
    writeIndent();
    output.push(`${getEndTagHtml(tagData)}${newLine}`);
  };

  const writeIndent = (): void => {
    output.push(`${defaultIndent}${indent.repeat(indentLevel)}`);
  };

  writeMetadataNodes();
  processBlocks();

  return output.join('').trim();
};
