import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ExpandedAssetElement } from '../../LinkedItems/components/ExpandedAssetElement.tsx';
import { ExpandedDateTimeElement } from '../../LinkedItems/components/ExpandedDateTimeElement.tsx';
import { ExpandedGuidelinesElement } from '../../LinkedItems/components/ExpandedGuidelinesElement.tsx';
import { ExpandedLinkedItemsElement } from '../../LinkedItems/components/ExpandedLinkedItemsElement.tsx';
import { ExpandedMultipleChoiceElement } from '../../LinkedItems/components/ExpandedMultipleChoiceElement.tsx';
import { ExpandedNumberElement } from '../../LinkedItems/components/ExpandedNumberElement.tsx';
import { ExpandedRichTextElement } from '../../LinkedItems/components/ExpandedRichTextElement.tsx';
import { ExpandedTaxonomyElement } from '../../LinkedItems/components/ExpandedTaxonomyElement.tsx';
import { ExpandedTextElement } from '../../LinkedItems/components/ExpandedTextElement.tsx';
import { ContentComponentAssetElement } from '../components/ContentComponentAssetElement.tsx';
import { ContentComponentCustomElement } from '../components/ContentComponentCustomElement.tsx';
import { ContentComponentDateTimeElement } from '../components/ContentComponentDateTimeElement.tsx';
import { ContentComponentModularContentElement } from '../components/ContentComponentModularContentElement.tsx';
import { ContentComponentMultipleChoice } from '../components/ContentComponentMultipleChoice.tsx';
import { ContentComponentNumberElement } from '../components/ContentComponentNumberElement.tsx';
import { ContentComponentRichStringElement } from '../components/ContentComponentRichStringElement.tsx';
import { ContentComponentStringElement } from '../components/ContentComponentStringElement.tsx';
import { ContentComponentUrlSlugElement } from '../components/ContentComponentUrlSlugElement.tsx';
import { ContentComponentTaxonomyElement } from '../containers/ContentComponentTaxonomyElement.tsx';
import {
  contentComponentElementComponentLocator,
  expandedContentComponentElementComponentLocator,
} from './contentComponentElementComponentLocator.ts';

export const bootstrapContentComponentElementComponents = () => {
  contentComponentElementComponentLocator
    .setComponent(ElementType.Asset, ContentComponentAssetElement)
    .setComponent(ElementType.Subpages, ContentComponentModularContentElement)
    .setComponent(ElementType.Custom, ContentComponentCustomElement)
    .setComponent(ElementType.DateTime, ContentComponentDateTimeElement)
    .setComponent(ElementType.MultipleChoice, ContentComponentMultipleChoice)
    .setComponent(ElementType.Number, ContentComponentNumberElement)
    .setComponent(ElementType.RichText, ContentComponentRichStringElement)
    .setComponent(ElementType.Text, ContentComponentStringElement)
    // Custom element is handled directly in ContentComponentItemElements.tsx
    .setComponent(ElementType.Taxonomy, ContentComponentTaxonomyElement)
    .setComponent(ElementType.LinkedItems, ContentComponentModularContentElement)
    .setComponent(ElementType.UrlSlug, ContentComponentUrlSlugElement);

  expandedContentComponentElementComponentLocator
    .setGuidelines(ExpandedGuidelinesElement)
    .setComponent(ElementType.Asset, ExpandedAssetElement)
    .setComponent(ElementType.Subpages, ExpandedLinkedItemsElement)
    .setComponent(ElementType.DateTime, ExpandedDateTimeElement)
    .setComponent(ElementType.LinkedItems, ExpandedLinkedItemsElement)
    .setComponent(ElementType.MultipleChoice, ExpandedMultipleChoiceElement)
    .setComponent(ElementType.Number, ExpandedNumberElement)
    .setComponent(ElementType.RichText, ExpandedRichTextElement)
    // Custom element is handled directly in ExpandedContentComponentElements.tsx
    .setComponent(ElementType.Text, ExpandedTextElement)
    .setComponent(ElementType.Taxonomy, ExpandedTaxonomyElement)
    .setComponent(ElementType.UrlSlug, ExpandedTextElement);
};
