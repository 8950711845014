import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import React from 'react';
import { DebouncedFunction, debounce } from '../../../../../../../_shared/utils/func/debounce.ts';
import { isString } from '../../../../../../../_shared/utils/stringUtils.ts';
import { getUrlOrigin } from '../../../../../../../_shared/utils/urlUtils.ts';
import { ICustomTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import { TypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  CustomElementClientMessageType,
  ElementValue,
  FileType,
  IAssetDetails,
  ICustomElementClientMessage,
  ICustomElementHostMessage,
  IGetAssetDetailsRequestMessageData,
  IGetItemDetailsRequestMessageData,
  IGetValueRequestMessageData,
  IItemChangedDetails,
  IItemCollectionReference,
  IItemExtendedData,
  IObserveElementChangesMessageData,
  ISelectAssetsRequestMessageData,
  ISelectItemsRequestMessageData,
  ISelectedAsset,
  ISelectedItem,
  ISetHeightRequestMessageData,
  ISetValueRequestMessageData,
} from '../../../../../types/CustomElementApi.ts';
import { CustomElementSelectionDialog } from '../../../containers/elements/customElement/CustomElementSelectionDialog.tsx';
import {
  createAssetsSelectedResponseMessage,
  createDisabledChangeMessage,
  createElementsChangedResponseMessage,
  createGetAssetDetailsFailedResponseMessage,
  createGetAssetDetailsResponseMessage,
  createGetElementValueFailedResponseMessage,
  createGetElementValueResponseMessage,
  createGetItemDetailsFailedResponseMessage,
  createGetItemDetailsResponseMessage,
  createInitResponseMessage,
  createItemChangedResponseMessage,
  createItemsSelectedResponseMessage,
  createSelectAssetsResponseMessage,
  createSelectItemsResponseMessage,
  createSetValueResponseMessage,
} from '../../../utils/customElementHostMessageCreator.ts';
import { CustomElementSandboxIframe } from './CustomElementSandboxIframe.tsx';
import { ElementsChangeObserver } from './ElementsChangeObserver.tsx';
import { ItemChangeObserver } from './ItemChangeObserver.tsx';
import { CustomElementDialogSelectionMode, CustomElementDialogType } from './customElementTypes.ts';

// The maximal amount of time for auto-scroll postponing
const HEIGHT_AUTO_SCROLL_TIMEOUT = 1000;
const DEFAULT_HEIGHT = 200;

export interface IItemObservedData {
  readonly codename: string;
  readonly collection: IItemCollectionReference;
  readonly name: string;
}

export interface ICustomElementSandboxOwnProps {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly disabled: boolean;
  readonly elements?: ReadonlyArray<ICompiledContentItemElementData>;
  readonly getElementValue: (elementCodename: string) => ElementValue | undefined;
  readonly onChange?: (value: string | null, searchableValue: string | null) => void;
  readonly typeElement: ICustomTypeElement;
  readonly typeElements?: ReadonlyArray<TypeElement>;
  readonly value: string | null;
}

export interface ICustomElementSandboxStateProps {
  readonly getAssetDetails: (assetId: UuidArray) => Promise<ReadonlyArray<IAssetDetails> | null>;
  readonly getItemDetails: (itemId: UuidArray) => Promise<ReadonlyArray<IItemExtendedData> | null>;
  readonly itemId: Uuid;
  readonly languageCodename: string;
  readonly projectId: Uuid;
  readonly variantId: string;
  readonly itemObservedData: IItemObservedData;
}

export type CustomElementSandboxProps = ICustomElementSandboxStateProps &
  ICustomElementSandboxOwnProps;

interface ICustomElementSandboxState {
  readonly dialogRequestId?: Uuid;
  readonly dialogSelectionMode: CustomElementDialogSelectionMode | null;
  readonly dialogType: CustomElementDialogType | null;
  readonly height: number;
  readonly heightInitialized: boolean;
  readonly isDialogOpen: boolean;
  readonly observeAllElements: boolean;
  readonly observedElementCodenamesByRequestId: Immutable.Map<Uuid, Immutable.Set<string>>;
  readonly observedElementIds: Immutable.Set<Uuid>;
  readonly observingItemChangesRequestIds: Array<Uuid>;
}

export class CustomElementSandbox extends React.PureComponent<
  CustomElementSandboxProps,
  ICustomElementSandboxState
> {
  static displayName = 'CustomElementSandbox';

  private readonly iframe = React.createRef<HTMLIFrameElement>();
  private readonly debouncedOnChange: DebouncedFunction;
  private heightTimeoutId?: number;

  constructor(props: CustomElementSandboxProps) {
    super(props);
    this.debouncedOnChange = debounce(this.onChange, 300);
    this.state = {
      dialogRequestId: undefined,
      dialogSelectionMode: null,
      dialogType: null,
      height: DEFAULT_HEIGHT,
      heightInitialized: false,
      isDialogOpen: false,
      observeAllElements: false,
      observedElementCodenamesByRequestId: Immutable.Map<Uuid, Immutable.Set<string>>(),
      observedElementIds: Immutable.Set<Uuid>(),
      observingItemChangesRequestIds: new Array<Uuid>(),
    };
  }

  componentDidMount(): void {
    window.addEventListener('message', this.onMessage, true);
    this.heightTimeoutId = window.setTimeout(this.onHeightTimeout, HEIGHT_AUTO_SCROLL_TIMEOUT);
  }

  componentDidUpdate(prevProps: Readonly<CustomElementSandboxProps>): void {
    if (prevProps.disabled !== this.props.disabled) {
      const message = createDisabledChangeMessage(this.props.disabled);
      this.sendResponse(message);
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.onMessage);
    if (this.heightTimeoutId) {
      clearTimeout(this.heightTimeoutId);
    }
    this.debouncedOnChange.cancel();
  }

  private readonly getIframeWindow = (): Window | null => {
    return this.iframe.current?.contentWindow ?? null;
  };

  private isCustomElementIFrame(eventSource: MessageEventSource | null): boolean {
    return !!eventSource && eventSource === this.getIframeWindow();
  }

  private eventOriginEqualsToCustomElementSourceUrl(eventOrigin: string): boolean {
    const sourceUrlOrigin = getUrlOrigin(this.props.typeElement.sourceUrl);
    return sourceUrlOrigin === eventOrigin;
  }

  private readonly onMessage = (event: MessageEvent): void => {
    this.processMessage(event);
  };

  private readonly processMessage = async (event: MessageEvent): Promise<void> => {
    const { source, origin, data } = event;

    if (!this.isCustomElementIFrame(source)) {
      return;
    }

    if (!this.eventOriginEqualsToCustomElementSourceUrl(origin)) {
      throw InvariantException(
        `Incoming message origin ${origin} does not correspond with provided custom element’s url.`,
      );
    }

    const message = data as ICustomElementClientMessage;
    switch (message.type) {
      case CustomElementClientMessageType.InitDataRequest: {
        this.sendResponse(createInitResponseMessage(this.props, message.requestId));
        break;
      }

      case CustomElementClientMessageType.SetValueRequest: {
        const valueMessageData = message.data as ISetValueRequestMessageData;
        this.debouncedOnChange(
          valueMessageData.value,
          valueMessageData.searchableValue,
          message.requestId,
        );
        break;
      }

      case CustomElementClientMessageType.SetHeightRequest: {
        const heightMessageData = message.data as ISetHeightRequestMessageData;
        this.setHeight(heightMessageData.height);
        break;
      }

      case CustomElementClientMessageType.SelectItemsRequest: {
        if (this.props.disabled) {
          const responseMessage = createSelectItemsResponseMessage(
            message.requestId,
            'You can’t select items because you can’t edit this custom element.',
          );
          this.sendResponse(responseMessage);
          return;
        }
        if (this.state.dialogRequestId) {
          const responseMessage = createSelectItemsResponseMessage(
            message.requestId,
            'You can’t open a selection dialog because another selection dialog has been already opened.',
          );
          this.sendResponse(responseMessage);
        }

        const configData = message.data as ISelectItemsRequestMessageData;
        const selectionMode = configData.config.allowMultiple
          ? CustomElementDialogSelectionMode.Multiple
          : CustomElementDialogSelectionMode.Single;

        this.showDialog(CustomElementDialogType.ItemSelector, selectionMode, message.requestId);
        break;
      }

      case CustomElementClientMessageType.SelectAssetsRequest: {
        if (this.props.disabled) {
          const responseMessage = createSelectAssetsResponseMessage(
            message.requestId,
            'You can’t select assets because you can’t edit this custom element.',
          );
          this.sendResponse(responseMessage);
          return;
        }
        if (this.state.dialogRequestId) {
          const responseMessage = createSelectAssetsResponseMessage(
            message.requestId,
            'You can’t open a selection dialog because another selection dialog has been already opened.',
          );
          this.sendResponse(responseMessage);
        }

        const configData = message.data as ISelectAssetsRequestMessageData;
        const selectionMode = configData.config.allowMultiple
          ? CustomElementDialogSelectionMode.Multiple
          : CustomElementDialogSelectionMode.Single;
        const dialogType =
          configData.config.fileType === FileType.Images
            ? CustomElementDialogType.ImageSelector
            : CustomElementDialogType.AssetSelector;
        this.showDialog(dialogType, selectionMode, message.requestId);
        break;
      }

      case CustomElementClientMessageType.GetValueRequest: {
        const valueMessageData = message.data as IGetValueRequestMessageData;
        const value = this.props.getElementValue(valueMessageData.codename);

        if (value === undefined) {
          this.sendResponse(
            createGetElementValueFailedResponseMessage(
              this.props.typeElement.codename || 'N/A',
              valueMessageData.codename,
              message.requestId,
            ),
          );
        } else {
          this.sendResponse(createGetElementValueResponseMessage(value, message.requestId));
        }
        break;
      }

      case CustomElementClientMessageType.GetItemDetailsRequest: {
        const valueMessageData = message.data as IGetItemDetailsRequestMessageData;
        const value = await this.props.getItemDetails(valueMessageData.itemIds);

        if (value === null) {
          this.sendResponse(
            createGetItemDetailsFailedResponseMessage(valueMessageData.itemIds, message.requestId),
          );
        } else {
          this.sendResponse(createGetItemDetailsResponseMessage(value, message.requestId));
        }
        break;
      }

      case CustomElementClientMessageType.GetAssetDetailsRequest: {
        const valueMessageData = message.data as IGetAssetDetailsRequestMessageData;
        const value = await this.props.getAssetDetails(valueMessageData.assetIds);

        if (value === null) {
          this.sendResponse(
            createGetAssetDetailsFailedResponseMessage(
              valueMessageData.assetIds,
              message.requestId,
            ),
          );
        } else {
          this.sendResponse(createGetAssetDetailsResponseMessage(value, message.requestId));
        }
        break;
      }

      case CustomElementClientMessageType.ObserveElementChanges: {
        const observeMessageData = message.data as IObserveElementChangesMessageData;
        if (message.requestId) {
          this.observeElementChanges(
            Immutable.Set.of<string>(...observeMessageData.elements),
            message.requestId,
          );
        }
        break;
      }

      case CustomElementClientMessageType.ObserveItemChanges: {
        if (message.requestId) {
          this.observeItemChanges(message.requestId);
        }
        break;
      }

      default:
        return;
    }
  };

  private readonly sendResponse = (message: ICustomElementHostMessage): void => {
    const targetWindow = this.getIframeWindow();
    if (targetWindow) {
      // Send the message only to origin generated from sourceUrl to prevent others from sniffing
      const targetOrigin = getUrlOrigin(this.props.typeElement.sourceUrl);
      targetWindow.postMessage(message, targetOrigin);
    }
  };

  private readonly observeElementChanges = (
    elementCodenames: Immutable.Set<string>,
    requestId: Uuid,
  ) => {
    const { typeElements } = this.props;
    if (typeElements) {
      const elementIds = elementCodenames
        .map((codename) => typeElements.find((e) => e.codename === codename)?.elementId)
        .toArray()
        .filter(isString);

      this.setState((prevState) => ({
        observeAllElements: prevState.observeAllElements || elementCodenames.isEmpty(),
        observedElementIds: prevState.observedElementIds.union([...elementIds]),
        observedElementCodenamesByRequestId: prevState.observedElementCodenamesByRequestId.set(
          requestId,
          elementCodenames,
        ),
      }));
    }
  };

  private readonly observeItemChanges = (requestId: Uuid) => {
    this.setState((prevState) => ({
      observingItemChangesRequestIds: [...prevState.observingItemChangesRequestIds, requestId],
    }));
  };

  private readonly onElementsChanged = (elementCodenames: Immutable.Set<string>) => {
    this.state.observedElementCodenamesByRequestId.forEach(
      (elements: Immutable.Set<string>, requestId: Uuid) => {
        const notifyAll = elements.isEmpty();
        const notifyElements = notifyAll ? elementCodenames : elementCodenames.intersect(elements);
        if (!notifyElements.isEmpty()) {
          this.sendResponse(createElementsChangedResponseMessage(notifyElements, requestId));
        }
      },
    );
  };

  private readonly onItemChanged = (itemChangedDetails: IItemChangedDetails): void => {
    this.state.observingItemChangesRequestIds.forEach((requestId: Uuid) => {
      this.sendResponse(createItemChangedResponseMessage(itemChangedDetails, requestId));
    });
  };

  private readonly sendItemsSelectedMessage = (ids: Array<ISelectedItem> | null): void => {
    if (!this.state.dialogRequestId) {
      throw InvariantException('Request id should be set.');
    }

    this.sendResponse(createItemsSelectedResponseMessage(ids, this.state.dialogRequestId));
  };

  private readonly sendAssetsSelectedMessage = (ids: Array<ISelectedAsset> | null): void => {
    if (!this.state.dialogRequestId) {
      throw InvariantException('Request id should be set.');
    }

    this.sendResponse(createAssetsSelectedResponseMessage(ids, this.state.dialogRequestId));
  };

  private readonly onChange = (
    value: string | null,
    searchableValue: string | null,
    requestId: Uuid,
  ): void => {
    if (!this.props.onChange) {
      return;
    }

    if (this.props.disabled) {
      const responseMessage = createSetValueResponseMessage(
        requestId,
        'Cannot set value of the Custom element as it is disabled for editing.',
      );
      this.sendResponse(responseMessage);
      return;
    }

    this.props.onChange(value, searchableValue);
    // No need to await the onChange for now, as we do not perform any additional validation here.
    this.sendResponse(createSetValueResponseMessage(requestId));
  };

  private readonly onHeightTimeout = () => {
    this.setState(() => ({
      heightInitialized: true,
    }));
  };

  private readonly setHeight = (height: number) => {
    if (!Number.isInteger(height) || height < 0) {
      return;
    }

    this.setState(() => ({
      height,
      heightInitialized: true,
    }));
  };

  private readonly closeDialog = () => {
    this.setState(() => ({
      dialogRequestId: undefined,
      isDialogOpen: false,
    }));
  };

  private readonly closeAssetSelectionDialog = () => {
    this.sendItemsSelectedMessage(null);
    this.closeDialog();
  };

  private readonly closeItemSelectionDialog = () => {
    this.sendAssetsSelectedMessage(null);
    this.closeDialog();
  };

  private readonly showDialog = (
    type: CustomElementDialogType,
    selectionMode: CustomElementDialogSelectionMode,
    requestId: Uuid,
  ) => {
    this.setState(() => ({
      dialogRequestId: requestId,
      dialogSelectionMode: selectionMode,
      dialogType: type,
      isDialogOpen: true,
    }));
  };

  private readonly onItemSelected = (itemId: Uuid): void => {
    const ids: Array<ISelectedItem> = [{ id: itemId }];
    this.sendItemsSelectedMessage(ids);
    this.closeDialog();
  };

  private readonly onItemsSelected = (itemIds: ReadonlyArray<Uuid>): void => {
    const ids: Array<ISelectedItem> = itemIds.map((itemId: Uuid) => {
      return {
        id: itemId,
      };
    });
    this.sendItemsSelectedMessage(ids);
    this.closeDialog();
  };

  private readonly onAssetsSelected = (assetIds: Immutable.List<Uuid>): void => {
    const ids: Array<ISelectedItem> = assetIds
      .map((assetId: Uuid) => {
        return {
          id: assetId,
        };
      })
      .toArray();
    this.sendAssetsSelectedMessage(ids);
    this.closeDialog();
  };

  private readonly onAssetSelected = (assetId: Uuid): void => {
    const ids: Array<ISelectedAsset> = [{ id: assetId }];
    this.sendAssetsSelectedMessage(ids);
    this.closeDialog();
  };

  render() {
    const { className, elements, typeElement, typeElements } = this.props;
    const {
      dialogSelectionMode,
      dialogType,
      height,
      heightInitialized,
      isDialogOpen,
      observeAllElements,
      observedElementIds,
      observingItemChangesRequestIds,
    } = this.state;

    return (
      <>
        <CustomElementSandboxIframe
          className={className}
          heightInitialized={heightInitialized}
          height={height}
          ref={this.iframe}
          src={typeElement.sourceUrl}
        />
        {observingItemChangesRequestIds.length > 0 && (
          <ItemChangeObserver
            onItemDetailsChange={this.onItemChanged}
            itemDataToObserve={this.props.itemObservedData}
          />
        )}
        {(observeAllElements || !observedElementIds.isEmpty()) && (
          <ElementsChangeObserver
            elements={elements}
            observeAllElements={observeAllElements}
            observedElementIds={observedElementIds}
            onElementsChanged={this.onElementsChanged}
            typeElements={typeElements}
          />
        )}
        {dialogType && (
          <CustomElementSelectionDialog
            elementName={typeElement.name}
            isOpen={isDialogOpen}
            mode={dialogSelectionMode || CustomElementDialogSelectionMode.Single}
            onAssetSelected={this.onAssetSelected}
            onAssetsSelected={this.onAssetsSelected}
            onCloseAssetSelector={this.closeAssetSelectionDialog}
            onCloseItemSelector={this.closeItemSelectionDialog}
            onItemSelected={this.onItemSelected}
            onItemsSelected={this.onItemsSelected}
            type={dialogType}
          />
        )}
      </>
    );
  }
}
