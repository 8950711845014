import { Box } from '@kontent-ai/component-library/Box';
import { BorderRadius, Color, Spacing, Typography, px } from '@kontent-ai/component-library/tokens';
import { getReadableTextColor } from '@kontent-ai/component-library/utils';
import { Property } from 'csstype';
import React from 'react';
import styled from 'styled-components';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';

interface IHorizontalProgressBarFillProps {
  readonly total: number;
  readonly value: number;
  readonly $color: Color;
}

const scaleFactor = 0.8;
const getProgressBarWidth = (total: number, value: number): Property.Width<number | string> => {
  if (!total || !value) {
    return 0;
  }

  return `${((value * 100) / total) * scaleFactor}%`;
};

const HorizontalProgressBarFill = styled.div.attrs<IHorizontalProgressBarFillProps>(
  ({ total, value }) => ({
    style: {
      width: getProgressBarWidth(total, value),
    },
  }),
)<IHorizontalProgressBarFillProps>`
  display: flex;
  height: ${px(Spacing.XL)};
  background-color: ${({ $color }) => $color};
  border-radius: ${px(BorderRadius.Pill)};
  align-items: center;
  justify-content: start;
  padding: 0 ${px(Spacing.M)};
`;

HorizontalProgressBarFill.displayName = 'HorizontalProgressBarFill';

const HorizontalProgressBarLabel = styled.div<{
  readonly backgroundColor: Color;
}>`
  ${Typography.TitleMedium};
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ backgroundColor }) => getReadableTextColor(backgroundColor)};
`;

HorizontalProgressBarLabel.displayName = 'HorizontalProgressBarLabel';

interface IHorizontalProgressBarProps extends IHorizontalProgressBarFillProps {
  readonly itemTypeName: string;
}

const HorizontalProgressBarWrapper = ({
  total,
  value,
  $color,
  itemTypeName,
  children,
  ...props
}: React.PropsWithChildren<IHorizontalProgressBarProps>) => {
  const caption = pluralizeWithCount(itemTypeName, value);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="start"
      alignItems="center"
      width="100%"
      gap={Spacing.S}
      {...props}
    >
      <HorizontalProgressBarFill
        aria-label={caption}
        aria-valuemax={total}
        aria-valuenow={value}
        role="meter"
        value={value}
        total={total}
        $color={$color}
      >
        {children}
      </HorizontalProgressBarFill>
      <Box typography={Typography.Caption}>{caption}</Box>
    </Box>
  );
};

export const HorizontalProgressBar = Object.assign(HorizontalProgressBarWrapper, {
  Label: HorizontalProgressBarLabel,
});
