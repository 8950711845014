import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { TextFilter } from '../../../../../_shared/components/TextFilter.tsx';
import { UserListingFilterClearButton } from '../../../../../_shared/components/UserListingFilterClearButton.tsx';
import {
  anyCollectionOption,
  anyLanguageOption,
  anyRoleOption,
} from '../../../../../_shared/constants/userListingFilter.ts';
import {
  CollectionFilterOption,
  LanguageFilterOption,
  RoleFilterOption,
  StatusFilterOption,
} from '../../../../../_shared/models/UserListingFilterOption.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type UserListingProps = {
  readonly areCollectionsEnabled: boolean;
  readonly areLanguageRolesEnabled: boolean;
  readonly clearSearchPhrase: () => void;
  readonly collections: ReadonlyArray<CollectionFilterOption>;
  readonly languages: ReadonlyArray<LanguageFilterOption>;
  readonly nameFilter: string;
  readonly onUpdateCollectionFilter: (
    updatedCollectionIds: ReadonlySet<CollectionFilterOption['id']>,
  ) => void;
  readonly onUpdateLanguageFilter: (
    updatedLanguageIds: ReadonlySet<LanguageFilterOption['id']>,
  ) => void;
  readonly onUpdateNameFilter: (name: string) => void;
  readonly onUpdateRoleFilter: (updatedRolesIds: ReadonlySet<RoleFilterOption['id']>) => void;
  readonly onUpdateStatusFilter: (statusId: StatusFilterOption['id']) => void;
  readonly roles: ReadonlyArray<RoleFilterOption>;
  readonly selectedCollectionIds: ReadonlySet<CollectionFilterOption['id']>;
  readonly selectedLanguageIds: ReadonlySet<LanguageFilterOption['id']>;
  readonly selectedRoleIds: ReadonlySet<RoleFilterOption['id']>;
  readonly selectedStatusId: StatusFilterOption['id'];
  readonly shouldTriggerCollectionOptionsReload?: boolean;
  readonly shouldTriggerLanguageOptionsReload?: boolean;
  readonly showClearFilterButton: boolean;
  readonly statusOptions: ReadonlyArray<StatusFilterOption>;
};

export const UserListingFilter: React.FC<UserListingProps> = ({
  areCollectionsEnabled,
  areLanguageRolesEnabled,
  clearSearchPhrase,
  collections,
  languages,
  nameFilter,
  onUpdateCollectionFilter,
  onUpdateLanguageFilter,
  onUpdateNameFilter,
  onUpdateRoleFilter,
  onUpdateStatusFilter,
  roles,
  selectedCollectionIds,
  selectedLanguageIds,
  selectedRoleIds,
  selectedStatusId,
  showClearFilterButton,
  statusOptions,
}) => {
  return (
    <Stack spacing={Spacing.XL}>
      <Row spacingX={Spacing.M}>
        <Column>
          <TextFilter
            autofocus
            onChange={onUpdateNameFilter}
            placeholder="Filter by name or email"
            text={nameFilter}
            ariaLabel="Search user listing"
          />
        </Column>
        {showClearFilterButton && (
          <Column width="fit-content">
            <UserListingFilterClearButton onClear={clearSearchPhrase} />
          </Column>
        )}
      </Row>
      <Row spacing={Spacing.S}>
        <Column flexFactor={1}>
          <MultiSelect<RoleFilterOption>
            items={roles}
            label="Role"
            onSelectionChange={onUpdateRoleFilter}
            placeholder={anyRoleOption.label}
            placeholderType="tag"
            selectedItemIds={selectedRoleIds}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.RoleList)}
            {...getDataUiCollectionAttribute(DataUiCollection.RoleList)}
          />
        </Column>
        {areCollectionsEnabled && (
          <Column flexFactor={1}>
            <MultiSelect<CollectionFilterOption>
              items={collections}
              label="Collection"
              onSelectionChange={onUpdateCollectionFilter}
              placeholder={anyCollectionOption.label}
              placeholderType="tag"
              selectedItemIds={selectedCollectionIds}
              verticalMenuDataAttributes={getDataUiCollectionAttribute(
                DataUiCollection.ContentCollections,
              )}
              {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
            />
          </Column>
        )}
        {areLanguageRolesEnabled && (
          <Column flexFactor={1}>
            <MultiSelect<LanguageFilterOption>
              items={languages}
              label="Language"
              onSelectionChange={onUpdateLanguageFilter}
              placeholder={anyLanguageOption.label}
              placeholderType="tag"
              selectedItemIds={selectedLanguageIds}
              verticalMenuDataAttributes={getDataUiCollectionAttribute(
                DataUiCollection.LanguageList,
              )}
              {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
            />
          </Column>
        )}
        <Column flexFactor={1}>
          <SingleSelect<StatusFilterOption>
            items={statusOptions}
            label="Status"
            onSelectionChange={onUpdateStatusFilter}
            selectedItemId={selectedStatusId}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.UserStatuses)}
            {...getDataUiCollectionAttribute(DataUiCollection.UserStatuses)}
          />
        </Column>
      </Row>
    </Stack>
  );
};

UserListingFilter.displayName = 'UserListingFilter';
