import React, { ReactElement } from 'react';
import {
  HighlightedIndexes,
  findMatchingIndexes as findMatchingIndexesDefault,
  whiteSpaceAndUnderscoreRegExp,
} from '../../utils/filter/nameFilterUtils.ts';

const highlightPhrasesInText = (
  phrases: ReadonlyArray<HighlightedIndexes>,
  text: string,
  startingIndex: number = 0,
): ReadonlyArray<ReactElement> => {
  if (startingIndex >= text.length) {
    // Highlighted till the end => Append nothing
    return [];
  }

  const phrase = phrases[0];

  if (!phrase) {
    // Append the non-highlighted rest
    return [<span key={`rest${startingIndex}`}>{text.substring(startingIndex, text.length)}</span>];
  }

  const result: JSX.Element[] = [];
  const from = Math.max(phrase.from, startingIndex);
  const to = Math.min(phrase.to, text.length - 1);

  // Continue in the middle of the text with a phrase to highlight
  if (startingIndex < from) {
    result.push(
      <span key={result.length + startingIndex}>{text.substring(startingIndex, from)}</span>,
    );
  }
  if (from <= to) {
    result.push(<b key={result.length + startingIndex}>{text.substring(from, to + 1)}</b>);
  }

  return result.concat(
    highlightPhrasesInText(phrases.slice(1), text, Math.max(to + 1, startingIndex)),
  );
};

type Props = {
  readonly findMatchingIndexes?: (
    words: ReadonlyArray<string>,
    text: string,
  ) => ReadonlyArray<HighlightedIndexes>;
  readonly searchPhrase: string;
  readonly text: string;
};

export const SearchPhraseHighlighterElement: React.FC<Props> = ({
  findMatchingIndexes = findMatchingIndexesDefault,
  searchPhrase,
  text,
}) => {
  const indexesToHighlight = findMatchingIndexes(
    searchPhrase.split(whiteSpaceAndUnderscoreRegExp),
    text,
  );

  return (
    <span className="notranslate">
      {indexesToHighlight.length ? highlightPhrasesInText(indexesToHighlight, text) : text}
    </span>
  );
};

SearchPhraseHighlighterElement.displayName = 'SearchPhraseHighlighterElement';
