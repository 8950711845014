import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { SidebarNames } from '../../../../../../_shared/models/SidebarNames.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { CommentEventTypes } from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { isThreadInline, isThreadResolved } from '../../../../utils/commentUtils.ts';
import { ContentGroupSelectionReason } from '../../constants/ContentGroupSelectionReason.ts';
import { getCommentsOnRemovedContent } from '../../selectors/inlineCommentSelectors.ts';
import { getParentContentComponentIdsFromElements } from '../../utils/contentComponentUtils.ts';
import {
  discussionSidebarOpened,
  resolvedCommentsSidebarOpened,
} from '../contentItemEditingActions.ts';
import { ISelectContentGroupForElement } from './selectContentGroupForElement.ts';

interface IDeps {
  readonly ensureThreadInViewportVisible: (focusedThread: ICommentThread) => ThunkFunction;
  readonly focusedCommentThreadChanged: (threadId: Uuid | null) => Action;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

interface IEnsureDeps {
  readonly contentComponentsExpanded: (contentComponentIds: Immutable.Set<Uuid>) => Action;
  readonly selectContentGroupForElement: ISelectContentGroupForElement;
}

export const createEnsureThreadInViewportVisible =
  (deps: IEnsureDeps) =>
  (focusedThread: ICommentThread): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: {
        editorUi: { collapsedContentComponents },
        editedContentItemVariantElements,
      },
      sharedApp: { sidebarStatus },
    } = getState();

    const commentsOnRemovedContent = getCommentsOnRemovedContent(getState());
    const isCommentOnRemovedContent = commentsOnRemovedContent.some(
      (thread) => thread.id === focusedThread.id,
    );

    const isResolvedCommentsSidebarOpened =
      SidebarNames.ResolvedCommentsSidebar && sidebarStatus.isVisible;
    if (isThreadResolved(focusedThread) && !isResolvedCommentsSidebarOpened) {
      dispatch(resolvedCommentsSidebarOpened());
    }

    const isDiscussionSidebarOpened =
      sidebarStatus.sidebarName === SidebarNames.DiscussionsSidebar && sidebarStatus.isVisible;
    const isDiscussionComment = !isThreadResolved(focusedThread) && !isThreadInline(focusedThread);
    if ((isDiscussionComment || isCommentOnRemovedContent) && !isDiscussionSidebarOpened) {
      dispatch(discussionSidebarOpened());
    }

    const contentComponentId = focusedThread.contentComponentId || undefined;
    if (contentComponentId) {
      const contentComponentIds = [
        contentComponentId,
        ...getParentContentComponentIdsFromElements(
          editedContentItemVariantElements,
          contentComponentId,
        ),
      ];

      if (
        collapsedContentComponents.some((componentId: Uuid) =>
          contentComponentIds.includes(componentId),
        )
      ) {
        dispatch(deps.contentComponentsExpanded(Immutable.Set.of(...contentComponentIds)));
      }
    }

    if (focusedThread.elementId !== null) {
      dispatch(
        deps.selectContentGroupForElement(
          ContentGroupSelectionReason.FocusCommentThread,
          focusedThread.elementId,
          contentComponentId,
        ),
      );
    }
  };

export const createFocusCommentThreadAction =
  (deps: IDeps) =>
  (threadId: Uuid | null): ThunkFunction =>
  (dispatch, getState) => {
    const { editedContentItemVariantComments } = getState().contentApp;

    const focusedThread = editedContentItemVariantComments.commentThreads.find(
      (thread: ICommentThread) => thread.id === threadId,
    );
    const stateFocusThreadId = editedContentItemVariantComments.focusedCommentThreadId;

    if (!focusedThread) {
      return;
    }

    if (focusedThread.id === stateFocusThreadId) {
      return;
    }

    dispatch(
      deps.trackUserEventWithData(TrackedEvent.Comments, {
        action: CommentEventTypes.CommentHighlighted,
      }),
    );

    dispatch(deps.ensureThreadInViewportVisible(focusedThread));
    dispatch(deps.focusedCommentThreadChanged(threadId));
  };
