import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState } from '../../../../../../../@types/Dispatcher.type.ts';
import { getSavedFilterUpdateServerModelFromListingFilter } from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { IFilterRepository } from '../../../../../../../repositories/interfaces/IFilterRepository.type.ts';
import { IFilterServerModel } from '../../../../../../../repositories/serverModels/FilterServerModels.type.ts';
import { isListingFilterEmpty } from '../../../../models/filter/IListingFilter.ts';
import {
  ListingFilter_SaveFilter_Failed,
  ListingFilter_SaveFilter_Finished,
  ListingFilter_SaveFilter_Started,
} from '../../constants/listingFilterActionTypes.ts';
import { UnableToSaveErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly filterRepository: IFilterRepository;
}

interface IParams {
  readonly name: string;
}

const failed = (errorMessage: string) =>
  ({
    type: ListingFilter_SaveFilter_Failed,
    payload: { errorMessage },
  }) as const;

const finished = (filter: IFilterServerModel) =>
  ({
    type: ListingFilter_SaveFilter_Finished,
    payload: { filter },
  }) as const;

const started = () =>
  ({
    type: ListingFilter_SaveFilter_Started,
  }) as const;

export type SaveFilterActionsType = ReturnType<typeof started | typeof finished | typeof failed>;

export const createSaveFilterAction =
  (deps: IDeps) =>
  (params: IParams) =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        listingUi: { filter },
      },
    } = getState();

    if (isListingFilterEmpty(filter)) {
      throw InvariantException('saveFilter.ts: listing filter is empty');
    }

    dispatch(started());

    try {
      const createFilterServerModel = getSavedFilterUpdateServerModelFromListingFilter(
        filter,
        params.name,
      );
      const serverModel = await deps.filterRepository.createFilter(createFilterServerModel);
      dispatch(finished(serverModel));
    } catch (e) {
      dispatch(failed(UnableToSaveErrorMessage));
      throw e;
    }
  };
