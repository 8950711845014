import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICompiledContentItemElementData } from './ICompiledContentItemElement.type.ts';

interface ICustomItemElementValue {
  readonly value: string | null;
  readonly searchableValue: string | null;
}

export interface ICustomItemElement
  extends ICustomItemElementValue,
    ICompiledContentItemElementData {}

export const EmptyCustomItemElementValue: ICustomItemElementValue = {
  searchableValue: null,
  value: null,
};

export const EmptyCustomItemElement: ICustomItemElement = {
  elementId: '',
  lastModifiedAt: null,
  lastModifiedBy: null,
  type: ElementType.Custom,
  ...EmptyCustomItemElementValue,
};

export const newCustomItemElement = (params: Partial<ICustomItemElement>): ICustomItemElement => ({
  ...EmptyCustomItemElement,
  ...params,
});
