import { IMultipleChoiceItemElementServerModel } from '../../../../repositories/serverModels/elementServerModels.type.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  EmptyMultipleChoiceItemElement,
  IMultipleChoiceItemElement,
} from '../../models/contentItemElements/MultipleChoiceItemElement.ts';
import {
  ISimpleItemElementDomainModelConverter,
  ISimpleItemElementServerModelConverter,
} from './types/IItemElementDataConverters.type.ts';

export const multipleChoiceElementDomainModelConverter: ISimpleItemElementDomainModelConverter<
  IMultipleChoiceItemElementServerModel,
  IMultipleChoiceItemElement
> = (serverElement) => {
  const { elementId, options, lastModifiedBy, lastModifiedAt } = serverElement;

  const element: IMultipleChoiceItemElement = {
    ...EmptyMultipleChoiceItemElement,
    elementId,
    options,
    lastModifiedBy: lastModifiedBy ? lastModifiedBy : null,
    lastModifiedAt: lastModifiedAt ? lastModifiedAt : null,
  };

  return element;
};

export const multipleChoiceElementServerModelConverter: ISimpleItemElementServerModelConverter<
  IMultipleChoiceItemElement,
  IMultipleChoiceItemElementServerModel
> = (serverElement) => {
  const { elementId, options, lastModifiedBy, lastModifiedAt } = serverElement;

  const serverModel: IMultipleChoiceItemElementServerModel = {
    elementId,
    type: ElementType.MultipleChoice,
    options,
    lastModifiedBy,
    lastModifiedAt,
  };

  return serverModel;
};
