import { createGuid } from '@kontent-ai/utils';
import {
  Collections_CollectionNameChanged,
  Collections_Creator_Canceled,
  Collections_Creator_Opened,
  Collections_Creator_Submitted,
  Collections_Editor_Canceled,
  Collections_Editor_CollectionDraggingFinished,
  Collections_Editor_CollectionDraggingStarted,
  Collections_Editor_CollectionMoved,
  Collections_Editor_Deleted,
  Collections_Editor_Opened,
  Collections_Editor_Submitted,
  Collections_FilterSearchPhrase_Changed,
  Collections_Listing_Left,
} from '../constants/actionTypes.ts';

export const submitCreator = (collectionName: string) =>
  ({
    type: Collections_Creator_Submitted,
    payload: {
      collectionId: createGuid(),
      collectionName,
    },
  }) as const;

export const cancelCreator = () =>
  ({
    type: Collections_Creator_Canceled,
  }) as const;

export const openCreator = (initialCollectionName?: string) =>
  ({
    type: Collections_Creator_Opened,
    payload: {
      initialCollectionName,
    },
  }) as const;

export const submitEditor = (collectionId: Uuid, collectionName: string) =>
  ({
    type: Collections_Editor_Submitted,
    payload: {
      collectionId,
      collectionName,
    },
  }) as const;

export const cancelEditor = () =>
  ({
    type: Collections_Editor_Canceled,
  }) as const;

export const deleteCollection = (collectionId: Uuid) =>
  ({
    type: Collections_Editor_Deleted,
    payload: {
      collectionId,
    },
  }) as const;

export const openEditor = (collectionId: Uuid, collectionName: string) =>
  ({
    type: Collections_Editor_Opened,
    payload: {
      collectionId,
      collectionName,
    },
  }) as const;

export const leaveCollectionsApp = () =>
  ({
    type: Collections_Listing_Left,
  }) as const;

export const startCollectionDragging = (collectionId: Uuid) =>
  ({
    type: Collections_Editor_CollectionDraggingStarted,
    payload: { collectionId },
  }) as const;

export const finishCollectionDragging = () =>
  ({
    type: Collections_Editor_CollectionDraggingFinished,
  }) as const;

export const moveCollection = (draggedCollectionId: Uuid, targetCollectionId: Uuid) =>
  ({
    type: Collections_Editor_CollectionMoved,
    payload: {
      draggedCollectionId,
      targetCollectionId,
    },
  }) as const;

export const filterSearchPhraseChanged = (filterSearchPhrase: string) =>
  ({
    type: Collections_FilterSearchPhrase_Changed,
    payload: {
      filterSearchPhrase,
    },
  }) as const;

export const editedCollectionNameChanged = (name: string) =>
  ({
    type: Collections_CollectionNameChanged,
    payload: {
      name,
    },
  }) as const;
