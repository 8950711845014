import React from 'react';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { changeNote } from '../../../../actions/thunkContentItemEditingActions.ts';
import { SectionLink } from '../../../../components/details/SectionLink.tsx';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';

export const NonClickableNoteSection: React.FC = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeNote(ContentItemEditingEventOrigins.ItemDetails, ModalDialogType.ContentItemNoteDialog),
    );

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.Note}>
      <SectionLink
        dataUiAttribute={DataUiWorkflowAction.EditNote}
        onEditClick={onClick}
        text="Add note"
      />
    </NonClickableSection>
  );
};

NonClickableNoteSection.displayName = 'NonClickableNoteSection';
