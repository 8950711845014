import { InvariantException } from '@kontent-ai/errors';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ITaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

export const backTrackOpenedAssetFolders = (
  taxonomyGroup: ITaxonomyGroup,
  currentFolder: ITaxonomyTerm | null,
  openedFolderId: Uuid,
  traversedIds: Uuid[] = [],
): readonly ITaxonomyTerm[] => {
  if (currentFolder) {
    const currentFolderId = currentFolder.id;

    if (traversedIds.includes(currentFolderId)) {
      return [];
    }

    traversedIds.push(currentFolderId);

    if (currentFolderId === openedFolderId) {
      return [currentFolder];
    }
  }

  const childIds = currentFolder ? currentFolder.childIds : taxonomyGroup.childIds;

  const allFolders = taxonomyGroup.terms;
  const result =
    childIds
      .map((id: Uuid) => {
        const folder = allFolders.get(id);

        if (!folder) {
          throw InvariantException(`Folder with id: ${id} was not found`);
        }

        return backTrackOpenedAssetFolders(taxonomyGroup, folder, openedFolderId, traversedIds);
      })
      .find((recursiveResult: ITaxonomyTerm[]) => !!recursiveResult.length) || [];

  return currentFolder && result.length ? [currentFolder, ...result] : result;
};
