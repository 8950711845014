import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { TaxonomyTermAssignedSourceType } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { isUuid } from '../../../../../../_shared/utils/validation/typeValidators.ts';
import { isTaxonomyTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ITaxonomyItemElement } from '../../../../models/contentItemElements/TaxonomyItemElement.ts';
import { getElementByIdOrThrow } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { createTaxonomyElementErrorChecker } from '../../../../utils/elementErrorCheckers/taxonomyElementErrorChecker.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getItemElementValueForErrorValidation } from '../../../../utils/getItemElementValueForErrorValidation.ts';
import { getTaxonomyElementValidationFriendlyWarning } from '../../../../utils/itemElementFriendlyWarningCheckers/taxonomyElementFriendlyWarningChecker.ts';
import { getTaxonomyItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/taxonomyItemElementWarningChecker.ts';
import { mapElementErrorResultToItemElementErrorResult } from '../../../../utils/mapElementErrorResultToItemElementErrorResult.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

interface ITaxonomyElementValueChangedDependencies {
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

const errorChecker = createTaxonomyElementErrorChecker(isUuid);

export const createTaxonomyElementValueChangedAction =
  ({ trackUserEventWithData }: ITaxonomyElementValueChangedDependencies) =>
  (
    elementId: Uuid,
    termIds: ReadonlySet<Uuid>,
    source: TaxonomyTermAssignedSourceType,
  ): ThunkFunction =>
  (dispatch, getState) => {
    const {
      data: { taxonomyGroups },
      contentApp: { editedContentItem, editedContentItemVariantElements, loadedContentItemTypes },
    } = getState();

    if (!editedContentItem) {
      throw InvariantException(
        'taxonomyElementValueChanged.ts action: "editedContentItem" is not loaded',
      );
    }

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    if (!editedContentItemType) {
      throw InvariantException(
        'taxonomyElementValueChanged.ts action : "editedContentItemType" is not loaded',
      );
    }
    const typeElement = editedContentItemType.contentElements.find(
      (elType) => elType.elementId === elementId,
    );

    if (!isTaxonomyTypeElement(typeElement) || !typeElement.taxonomyGroupId) {
      throw InvariantException(
        'taxonomyElementValueChanged.ts action : "taxonomyGroupId" is not loaded',
      );
    }

    const taxonomyGroup = taxonomyGroups.byId.get(typeElement.taxonomyGroupId);
    if (!taxonomyGroup) {
      throw InvariantException(`Taxonomy group ${typeElement.taxonomyGroupId} doesn’t exist.`);
    }
    const taxonomyElement = getElementByIdOrThrow<ITaxonomyItemElement>(
      elementId,
      editedContentItemVariantElements,
    );

    const updatedElement: ITaxonomyItemElement = {
      ...taxonomyElement,
      groupId: typeElement.taxonomyGroupId,
      value: termIds,
    };

    const errorResult = errorChecker({
      value: getItemElementValueForErrorValidation(updatedElement),
    });

    const warningResult = getTaxonomyItemElementValidationWarning({
      typeElement,
      itemElement: updatedElement,
      taxonomyGroups: taxonomyGroups.byId,
    });

    const newlyAssignedTermsCount = Collection.removeMany(updatedElement.value, [
      ...taxonomyElement.value,
    ]).size;

    if (newlyAssignedTermsCount) {
      dispatch(
        trackUserEventWithData(TrackedEvent.TaxonomyTermAssigned, {
          'assigned-terms-count': newlyAssignedTermsCount,
          'element-id': elementId,
          source,
        }),
      );
    }

    const friendlyWarningResult = getTaxonomyElementValidationFriendlyWarning({
      itemElement: updatedElement,
      typeElement,
      taxonomyGroups: taxonomyGroups.byId,
    });

    const validationResult = createValidationResult(
      mapElementErrorResultToItemElementErrorResult(errorResult, typeElement.type),
      warningResult,
      friendlyWarningResult,
      elementId,
    );

    dispatch(
      elementValueChanged({
        elementData: updatedElement,
        itemId: editedContentItem.id,
        typeElement,
        validationResult,
      }),
    );
  };
