import { Collection } from '@kontent-ai/utils';
import { IMultipleChoiceItemElement } from '../../models/contentItemElements/MultipleChoiceItemElement.ts';
import { basePropertiesAreTheSame } from './basePropertiesAreTheSame.ts';

export function areMultipleChoicesItemElementsTheSame(
  itemElement1: IMultipleChoiceItemElement,
  itemElement2: IMultipleChoiceItemElement,
): boolean {
  if (!basePropertiesAreTheSame(itemElement1, itemElement2)) {
    return false;
  }

  const options1 = itemElement1.options;
  const options2 = itemElement2.options;
  const isNumberOfOptionsTheSame = options1.length === options2.length;
  const areOptionsTheSame = Collection.isSubset(options1, options2);

  return isNumberOfOptionsTheSame && areOptionsTheSame;
}
