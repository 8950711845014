import Immutable from 'immutable';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RootDepth, RootParentId } from '../constants/relationsConstants.ts';

export function getModularItemIds(item: IListingContentItem): Immutable.Set<Uuid> {
  return item && !item.item.archived && item.variant && !item.variant.isArchived
    ? Immutable.Set(item.variant.modularItemIds)
    : Immutable.Set<Uuid>();
}

export const getRelationsNodeId = (
  itemId: Uuid,
  parentId: string = RootParentId,
  depth: number = RootDepth,
): string => `${parentId}-${itemId}-${depth}`;
