import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { IUrlSlugTypeElementData } from '../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { isUrlSlugTypeElement } from '../types/typeElementTypeGuards.ts';

export const getUrlSlugGeneratedByTextElement = memoize.allForever(
  (
    contentElements: ReadonlyArray<IBaseTypeElementData>,
    textElementId: Uuid,
  ): IUrlSlugTypeElementData | undefined => {
    const urlSlug = contentElements
      .filter(isUrlSlugTypeElement)
      .find((element) => element.dependedOnTextElementId === textElementId);
    return urlSlug;
  },
);

export const textElementGeneratesUrlSlug = (
  contentElements: ReadonlyArray<IBaseTypeElementData>,
  textElementId: Uuid,
): boolean => {
  const urlSlug = getUrlSlugGeneratedByTextElement(contentElements, textElementId);
  return !!urlSlug;
};

export const textElementGeneratesUrlSlugInAnyContentType = memoize.allForever(
  (contentTypes: Immutable.Map<Uuid, IContentType>, textElementId: Uuid): boolean => {
    return contentTypes.some((type: IContentType) =>
      textElementGeneratesUrlSlug(type.typeElements, textElementId),
    );
  },
);

export const getUrlSlugGeneratedByContentTypeSnippetElement = memoize.allForever(
  (
    contentElements: ReadonlyArray<IBaseTypeElementData>,
    snippets: Immutable.Map<Uuid, IContentTypeSnippet>,
    snippetId: Uuid | null,
  ): IUrlSlugTypeElementData | undefined => {
    const snippet = snippets.find((s: IContentTypeSnippet) => s.id === snippetId);
    const urlSlug = contentElements.find(isUrlSlugTypeElement);
    const snippetGeneratesUrlSlug =
      !!urlSlug &&
      !!snippet &&
      snippet.typeElements.some((element) => urlSlug.dependedOnTextElementId === element.elementId);
    return snippetGeneratesUrlSlug ? urlSlug : undefined;
  },
);

export const contentTypeSnippetElementGeneratesUrlSlug = (
  contentElements: ReadonlyArray<IBaseTypeElementData>,
  snippets: Immutable.Map<Uuid, IContentTypeSnippet>,
  snippetId: Uuid | null,
): boolean => {
  const urlSlug = getUrlSlugGeneratedByContentTypeSnippetElement(
    contentElements,
    snippets,
    snippetId,
  );
  return !!urlSlug;
};
