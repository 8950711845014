import { useAttachRef } from '@kontent-ai/hooks';
import { forwardRef } from 'react';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../_shared/hooks/useAutoScroll.ts';
import {
  RichTextAssetTile,
  RichTextAssetTileProps,
} from '../../../../itemEditor/features/ContentItemEditing/containers/elements/asset/RichTextAssetTile.tsx';

export const InlineImageWithAutoScroll = forwardRef<HTMLDivElement, RichTextAssetTileProps>(
  (props, ref) => {
    const { refObject: scrollTargetRef, refToForward } = useAttachRef(ref);
    const scrollId = CreateAutoScrollId.forAsset(props.assetId);
    useAutoScroll({ scrollTargetRef, scrollId });

    return <RichTextAssetTile ref={refToForward} {...props} />;
  },
);

InlineImageWithAutoScroll.displayName = 'InlineImageWithAutoScroll';
