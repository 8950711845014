import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography } from '@kontent-ai/component-library/tokens';
import { naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ReactNode } from 'react';
import { WorkflowWithApplicableScopes } from '../../../../../_shared/utils/workflow/workflowLimitations.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { getContentTypeNames } from '../../utils/getCollectionApplicableWorkflows.ts';

type Props = {
  readonly bottomLabel: ReactNode;
  readonly contentTypes: Immutable.Map<Uuid, IContentType>;
  readonly limitedWorkflows: ReadonlyArray<WorkflowWithApplicableScopes>;
  readonly unlimitedWorkflows: ReadonlyArray<WorkflowWithApplicableScopes>;
};

export const CollectionWorkflowLimitations = ({
  bottomLabel,
  contentTypes,
  limitedWorkflows,
  unlimitedWorkflows,
}: Props) => {
  const getInfixText = (contentTypeNames: ReadonlySet<string>) => {
    switch (contentTypeNames.size) {
      case 0:
        return 'for all content types';
      case 1:
        return 'for content type';
      default:
        return 'for content types';
    }
  };

  const getLimitedWorkflowRow = (workflow: WorkflowWithApplicableScopes) => {
    const contentTypeNames = getContentTypeNames(workflow, contentTypes);
    if (!contentTypeNames) {
      return null;
    }
    const infixText = getInfixText(contentTypeNames);
    const contentTypesText = Array.from(contentTypeNames).sort(naturally).join(', ');
    return (
      <Box key={workflow.id} typography={Typography.BodyMedium}>
        Workflow <b>{workflow.name}</b> {infixText} <b>{contentTypesText}</b>
      </Box>
    );
  };

  const getUnlimitedWorkflowsRow = (workflows: ReadonlyArray<WorkflowWithApplicableScopes>) => {
    if (workflows.length === 0) {
      return null;
    }
    const workflowNames = workflows
      .map((workflow) => workflow.name)
      .sort(naturally)
      .join(', ');
    const infixText = limitedWorkflows.length === 0 ? 'for all' : 'for all remaining';
    return (
      <Box typography={Typography.BodyMedium}>
        Workflow <b>{workflowNames}</b> {infixText} content types
      </Box>
    );
  };

  return (
    <Box paddingTop={Spacing.XL}>
      <Stack spacing={Spacing.L}>
        <Box typography={Typography.HeadlineMedium}>Workflow settings for this collection</Box>

        <Stack spacing={Spacing.S}>
          {limitedWorkflows.map(getLimitedWorkflowRow)}
          {getUnlimitedWorkflowsRow(unlimitedWorkflows)}
        </Stack>

        <Callout calloutType="quickTip" hideSubheadline>
          {bottomLabel}
        </Callout>
      </Stack>
    </Box>
  );
};
