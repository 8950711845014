import { noOperation } from '@kontent-ai/utils';
import { useEffect } from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { areSpacesInCollectionsEnabled } from '../../selectors/allowedFeaturesUtils.ts';
import { leaveCollectionsApp } from '../actions/baseActions.ts';
import {
  initCollectionsListing,
  saveEditedCollections,
} from '../actions/collectionsThunkActions.ts';
import { CollectionsApp as CollectionsAppComponent } from '../components/CollectionsApp.tsx';
import { haveCollectionsUnsavedChanges } from '../selectors/haveCollectionsUnsavedChanges.ts';

export const CollectionsApp = () => {
  const dispatch = useDispatch();
  const isBeingSaved = useSelector((s) => s.collectionsApp.isBeingSaved);
  const collectionIds = useSelector((s) => s.collectionsApp.collectionIds);
  const editedCollections = useSelector((s) => s.collectionsApp.editedCollections);
  const hasUnsavedChanges = useSelector(haveCollectionsUnsavedChanges);
  const showSpaces = useSelector(areSpacesInCollectionsEnabled);

  const [isInitThunkDone] = useThunkPromise(initCollectionsListing);
  const isInitialized = useSelector((s) => isInitThunkDone && s.collectionsApp.isInitialized);

  useEffect(() => {
    return () => {
      dispatch(leaveCollectionsApp());
    };
  }, []);

  if (!isInitialized) {
    return <Loader />;
  }

  const onControlS = (event: KeyboardEvent): void => {
    event.preventDefault();

    if (!isBeingSaved) {
      dispatch(saveEditedCollections()).catch(noOperation);
    }
  };

  return (
    <HotkeysHandler
      mode={HotkeysMode.Dual}
      handlers={{
        onControlS,
      }}
    >
      <HandleUnsavedFormOnNavigation
        isBeingSaved={isBeingSaved}
        onSaveChanges={async (onSuccess, onFail): Promise<void> => {
          try {
            await dispatch(saveEditedCollections());
            onSuccess();
          } catch {
            onFail();
          }
        }}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <CollectionsAppComponent
        collectionIds={collectionIds}
        collectionsMap={editedCollections}
        showSpaces={showSpaces}
      />
    </HotkeysHandler>
  );
};
