const automaticallySuspendedReasonPrefix = 'AUTO:';
const automaticallySuspendedReasonNoTos = 'AUTO: MISSING SERVICE AGREEMENT SIGNATURE';
const automaticallySuspendedReasonStarterPlan = 'AUTO: SUSPENDED DUE TO USING STARTER PLAN';

export const isSubscriptionSuspendedAutomatically = (
  subscriptionStatusReason: string | null,
): boolean =>
  !!subscriptionStatusReason &&
  subscriptionStatusReason.toUpperCase().startsWith(automaticallySuspendedReasonPrefix);

export const isSubscriptionSuspendedDueToMissingToS = (
  subscriptionStatusReason: string | null,
): boolean =>
  !!subscriptionStatusReason &&
  subscriptionStatusReason.toUpperCase() === automaticallySuspendedReasonNoTos;

export const isSubscriptionSuspendedDueToUsingStarterPlan = (
  subscriptionStatusReason: string | null,
): boolean =>
  !!subscriptionStatusReason &&
  subscriptionStatusReason.toUpperCase() === automaticallySuspendedReasonStarterPlan;
