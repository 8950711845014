import { FormValidatorConfig } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import {
  uniqueCodenameValidationBuilder,
  uniquenessValidationBuilder,
} from '../../../../_shared/utils/validation/isPropertyUnique.ts';
import { OtherLanguage } from '../models/OtherLanguage.type.ts';
import { LanguageFormShape } from '../models/languageFormShape.type.ts';

export type LanguageDependantProps = {
  readonly otherLanguages: ReadonlyArray<OtherLanguage>;
};

const nameRequired = nonEmptyValidationBuilder('name', 'language');
const codenameRequired = nonEmptyValidationBuilder('codename', 'language');

const nameUnique = uniquenessValidationBuilder<LanguageDependantProps, OtherLanguage>(
  'name',
  (props) => props.otherLanguages,
  (settings) => settings.name,
);

const codenameUnique = uniqueCodenameValidationBuilder<LanguageDependantProps>(
  (props) => props.otherLanguages,
);

export const languageValidationConfig: FormValidatorConfig<
  LanguageFormShape,
  LanguageDependantProps
> = {
  name: [nameRequired, nameUnique],
  codename: [codenameRequired, codenameUnique],
};
