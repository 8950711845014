import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadContentTypes,
  loadRoles,
  loadWorkflows,
  loadWorkflowsUsage,
} from '../../../../data/actions/thunkDataActions.ts';
import { createDeleteWorkflowAction } from './thunks/deleteWorkflow.ts';
import { createInitWorkflowCreatingAction } from './thunks/initWorkflowCreating.ts';
import { createInitWorkflowEditingAction } from './thunks/initWorkflowEditing.ts';
import { createInitWorkflowsListingAction } from './thunks/initWorkflowsListing.ts';
import { createLoadWorkflowStepsUsageAction } from './thunks/loadWorkflowStepsUsage.ts';
import { createRestoreWorkflowAction } from './thunks/restoreWorkflow.ts';
import { createSaveWorkflowToServerAction } from './thunks/saveWorkflowToServer.ts';

const { workflowRepository } = repositoryCollection;

export const loadWorkflowStepsUsage = createLoadWorkflowStepsUsageAction({ workflowRepository });

export const initWorkflowCreating = createInitWorkflowCreatingAction({
  loadContentTypes,
  loadRoles,
});

export const initWorkflowEditing = createInitWorkflowEditingAction({
  loadContentTypes,
  loadRoles,
  loadWorkflows,
  loadWorkflowStepsUsage,
  loadWorkflowsUsage,
});

export const saveWorkflowToServer = createSaveWorkflowToServerAction({
  workflowRepository,
  loadWorkflowStepsUsage,
  loadWorkflowsUsage,
});

export const deleteWorkflow = createDeleteWorkflowAction({
  workflowRepository,
});

export const restoreWorkflow = createRestoreWorkflowAction({
  workflowRepository,
  loadWorkflows,
});

export const initWorkflowsListing = createInitWorkflowsListingAction({
  loadWorkflows,
  loadWorkflowsUsage,
});
