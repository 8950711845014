import { DOMRectLike } from '@kontent-ai/DOM';
import { Direction } from '@kontent-ai/types';
import { XYCoord } from 'react-dnd';
import { DirectionItemEnum } from '../../../applications/contentModels/sitemap/constants/DirectionItemEnum.ts';
import { WaitBehavior, throttle } from '../func/throttle.ts';

export const dragMoveThrottleIntervalMs = 100;

export const moveItemThrottled = throttle(
  <TParams extends AnyObject>(onMove: (params: TParams) => void, params: TParams): void =>
    onMove(params),
  dragMoveThrottleIntervalMs,
  { immediate: false, behavior: WaitBehavior.IdleTimeBetweenCalls },
);

export function crossedHalfTargetHeight(
  targetBoundingRect: DOMRectLike,
  clientOffset: XYCoord,
  dragDirection: Direction,
): boolean {
  const middleY = (targetBoundingRect.bottom - targetBoundingRect.top) / 2;
  const offsetY = clientOffset.y - targetBoundingRect.top;

  return (
    (dragDirection === Direction.Forward && offsetY >= middleY) ||
    (dragDirection === Direction.Backward && offsetY <= middleY)
  );
}

type Inset = 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1;

/**
 * Ignores X pixels (defined as percentage) from the edge of the element.
 * The ignored area takes into account element asymmetry, so it is derived from the shortest side.
 * @param targetBoundingRect Droppable element rect
 * @param pointer User's pointer coordinates
 * @param insetRelativeToShorterSide Percentage of ignored pixels from the edge of the droppable element
 */
export function isWithinTargetInset(
  targetBoundingRect: DOMRectLike,
  pointer: XYCoord,
  insetRelativeToShorterSide: Inset,
): boolean {
  /**
   *    targetA ----------------
   *    ¦  insetA -----------  ¦
   *    ¦  ¦                ¦  ¦
   *    ¦  ¦                ¦  ¦
   *    ¦  ----------- insetC  ¦
   *    ---------------- targetC
   */
  const targetA = { x: targetBoundingRect.left, y: targetBoundingRect.top };
  const targetC = { x: targetBoundingRect.right, y: targetBoundingRect.bottom };

  const lengthOfShorterSide = Math.min(targetC.x - targetA.x, targetC.y - targetA.y);
  const ignoredPixelsPerSide = (lengthOfShorterSide * insetRelativeToShorterSide) / 2;

  const insetA = { x: targetA.x + ignoredPixelsPerSide, y: targetA.y + ignoredPixelsPerSide };
  const insetC = { x: targetC.x - ignoredPixelsPerSide, y: targetC.y - ignoredPixelsPerSide };

  return (
    pointer.x > insetA.x && pointer.x < insetC.x && pointer.y > insetA.y && pointer.y < insetC.y
  );
}

export function getTreeNodeMoveDirections(
  clientOffset: XYCoord,
  targetBoundingRect: DOMRectLike,
): ReadonlySet<DirectionItemEnum> {
  const directions = new Set<DirectionItemEnum>();
  if (clientOffset.x - targetBoundingRect.left >= 25) {
    directions.add(DirectionItemEnum.Right);
  }

  if (clientOffset.y - targetBoundingRect.top < 20) {
    directions.add(DirectionItemEnum.Up);
  } else if (targetBoundingRect.bottom - clientOffset.y < 20) {
    directions.add(DirectionItemEnum.Down);
  }

  return directions;
}

export function moveItem<TItem, TKey>(
  items: ReadonlyArray<TItem>,
  sourceId: TKey,
  targetId: TKey,
  getKey: (item: TItem) => TKey,
): ReadonlyArray<TItem> {
  if (sourceId === targetId) {
    return items;
  }

  const sourceIndex = items.findIndex((element) => getKey(element) === sourceId);
  const targetIndex = items.findIndex((element) => getKey(element) === targetId);
  if (sourceIndex < 0 || targetIndex < 0) {
    return items;
  }

  const sourceIndexItem = items[sourceIndex];
  const wrappedSourceIndexItem = sourceIndexItem === undefined ? [] : [sourceIndexItem];

  const newItems =
    sourceIndex < targetIndex
      ? // Move forward (behind target)
        [
          ...items.slice(0, sourceIndex),
          ...items.slice(sourceIndex + 1, targetIndex + 1),
          ...wrappedSourceIndexItem,
          ...items.slice(targetIndex + 1),
        ]
      : // Move backward (before target)
        [
          ...items.slice(0, targetIndex),
          ...wrappedSourceIndexItem,
          ...items.slice(targetIndex, sourceIndex),
          ...items.slice(sourceIndex + 1),
        ];

  return newItems;
}
