import React from 'react';
import { InnovationLabFeatureStatus } from '../types/InnovationLabFeatureStatus.ts';
import { isInnovationLabFeatureAvailable } from '../utils/innovationLabFeatureStatusUtils.ts';
import { ActivateFeatureButton } from './ActivateFeatureButton.tsx';
import { DeactivateFeatureButton } from './DeactivateFeatureButton.tsx';
import { InnovationLabActivationState } from './InnovationLabFeatureStatus.tsx';

type Props = {
  readonly activationState: InnovationLabActivationState;
  readonly canBeDeactivated?: boolean;
  readonly deactivationUnavailableExplanation?: string;
  readonly featureName: string;
  readonly featureState: InnovationLabFeatureStatus;
  readonly isAsyncOperationInProgress: boolean;
  readonly onClick?: () => Promise<void>;
};

export const InnovationLabFeatureButton: React.FC<Props> = ({
  activationState,
  canBeDeactivated,
  deactivationUnavailableExplanation,
  featureName,
  featureState,
  isAsyncOperationInProgress,
  onClick,
}) => {
  switch (activationState) {
    case 'active':
      return (
        <DeactivateFeatureButton
          canBeDeactivated={canBeDeactivated}
          deactivationUnavailableExplanation={deactivationUnavailableExplanation}
          featureName={featureName}
          isDeactivating={isAsyncOperationInProgress}
          onClick={onClick}
        />
      );

    case 'inactive':
      return (
        <ActivateFeatureButton
          canBeActivated={isInnovationLabFeatureAvailable(featureState)}
          featureName={featureName}
          isActivating={isAsyncOperationInProgress}
          onClick={onClick}
        />
      );

    case 'activation-pending':
      return (
        <ActivateFeatureButton
          activationUnavailableExplanation="Feature activation is still in progress and may take additional time to complete."
          canBeActivated={false}
          featureName={featureName}
          isActivating
        />
      );

    case 'unknown':
      return null;
  }
};

InnovationLabFeatureButton.displayName = 'InnovationLabFeatureButton';
