import React from 'react';
import { PreviewLink } from '../../../containers/selectors/selectPreviewLinks.ts';
import { LeafActionFormProps } from '../EditingLeafActionForm.tsx';
import { ActionsMenuItem } from '../actionsMenu/ActionsMenuItem.tsx';

type Props = LeafActionFormProps & {
  readonly previewLinks: ReadonlyArray<PreviewLink>;
  readonly onPreviewOpened: (spaceId: Uuid | null) => void;
};

export const PreviewLinksForm: React.FC<Props> = ({ previewLinks, onPreviewOpened }) => (
  <>
    {previewLinks
      .filter(({ url }) => !!url)
      .map((link) => (
        <ActionsMenuItem
          key={link.spaceId ?? 'no-space'}
          iconName="ArrowRightTopSquare"
          outwardLinkUrl={link.url}
          onClick={() => onPreviewOpened(link.spaceId)}
          text={link.spaceName ?? 'No space'}
        />
      ))}
  </>
);

PreviewLinksForm.displayName = 'PreviewLinksForm';
