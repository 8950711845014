export enum VariantTranslationStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
  PartiallyFailed = 'PartiallyFailed',
  Cancelled = 'Cancelled',
}

export enum ElementTranslationStatus {
  Failed = 'Failed',
  Skipped = 'Skipped',
  Succeeded = 'Succeeded',
}
