import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { emptySubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import { getSelectedSubscription } from '../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { loadSubscriptionBillingInformation } from '../actions/thunkBillingInformationActions.ts';
import { BillingInformation as BillingInformationComponent } from '../components/BillingInformation.tsx';
import { emptySubscriptionBillingInformation } from '../models/SubscriptionBillingInformation.ts';

export const BillingInformation = () => {
  const selectedSubscription = useSelector((s) => getSelectedSubscription(s) ?? emptySubscription);
  const plan = useSelector((s) => s.data.plans.byId.get(selectedSubscription.currentPlan.planId));

  const shouldLoadBillingInformationDetails =
    selectedSubscription.isFsSubscriptionConnected && !plan?.isPrepaid;
  const billingInformation = useSelector((s) =>
    shouldLoadBillingInformationDetails
      ? s.data.subscriptions.subscriptionBillingInformation
      : null,
  );

  const [isLoadBillingInfoThunkDone] = useThunkPromise(
    loadSubscriptionBillingInformation,
    selectedSubscription.subscriptionId,
    {
      canRun: shouldLoadBillingInformationDetails,
    },
  );

  if (
    (shouldLoadBillingInformationDetails && !isLoadBillingInfoThunkDone) ||
    billingInformation === emptySubscriptionBillingInformation
  ) {
    return <Loader />;
  }

  return (
    <BillingInformationComponent
      billingInformation={billingInformation}
      isMarketplaceSubscription={!!selectedSubscription.azureMarketplaceSubscriptionId}
      subscriptionId={selectedSubscription.subscriptionId}
    />
  );
};
