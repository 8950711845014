import { Action } from '../../../../../../@types/Action.type.ts';
import {
  NewVariantDialog_Initialized,
  NewVariantDialog_SelectedWorkflowChanged,
} from '../../constants/newVariantActionTypes.ts';

const initialState: Uuid | null = null;

export const selectedWorkflowId = (state = initialState, action: Action): Uuid | null => {
  switch (action.type) {
    case NewVariantDialog_Initialized:
      return initialState;

    case NewVariantDialog_SelectedWorkflowChanged:
      return action.payload.workflowId;

    default:
      return state;
  }
};
