import React from 'react';
import { ItemDetailSection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ClickableDueDateSection } from '../../../containers/details/DueDate/SectionTypes/ClickableDueDateSection.tsx';
import { NonClickableDueDateSection } from '../../../containers/details/DueDate/SectionTypes/NonClickableDueDateSection.tsx';
import { Section } from '../../sidebar/Section.tsx';
import { SectionType } from '../../sidebar/sectionTypes/SectionType.tsx';

interface IDueDateSectionProps {
  readonly sectionType: SectionType;
}

export const DueDateSection: React.FC<IDueDateSectionProps> = ({ sectionType }) => (
  <Section
    ClickableSectionComponent={ClickableDueDateSection}
    NonClickableSectionComponent={NonClickableDueDateSection}
    ReadonlySectionComponent={() => null}
    title="Due date"
    type={sectionType}
    uiObjectNameAttribute={ItemDetailSection.DueDateSection}
  />
);

DueDateSection.displayName = 'DueDateSection';
