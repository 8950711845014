import { History } from 'history';
import { ThunkFunction } from '../../../../@types/Dispatcher.type.ts';
import {
  AutoScrollId,
  CreateAutoScrollId,
} from '../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { IEditButtonElementClickedMessageData } from '../../types/SmartLinkSdkApi.ts';
import { navigateToElementBase } from './navigateToElementBase.ts';

const getElementScrollId = (messageData: IEditButtonElementClickedMessageData): AutoScrollId => {
  return messageData.contentComponentId
    ? CreateAutoScrollId.forComponentElement(
        messageData.elementCodename,
        messageData.contentComponentId,
        messageData.itemId,
      )
    : CreateAutoScrollId.forElement(messageData.elementCodename, messageData.itemId);
};

export const navigateToEditElementButtonTarget =
  (messageData: IEditButtonElementClickedMessageData, history: History): ThunkFunction =>
  (dispatch) => {
    const elementScrollId = getElementScrollId(messageData);
    dispatch(navigateToElementBase(messageData, history, elementScrollId));
  };
