import React, { ReactNode } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IFormAlertProps {
  readonly children: ReactNode;
  readonly isDisplayed: boolean;
}

export const FormFieldError: React.FC<IFormAlertProps> = ({ children, isDisplayed }) =>
  isDisplayed && children ? (
    <div className="form__alert" {...getDataUiElementAttribute(DataUiElement.NotificationAlert)}>
      {children}
    </div>
  ) : null;

FormFieldError.displayName = 'FormFieldError';
