import { IconButton } from '@kontent-ai/component-library/Button';
import { IconName } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps } from 'react';

type StatusBarIconButtonProps = {
  readonly iconName: IconName;
  readonly label: string;
  readonly isActive?: boolean;
  readonly disabled?: boolean;
  readonly component?: ComponentProps<typeof IconButton>['component'];
};

export const StatusBarIconButton = React.forwardRef<HTMLButtonElement, StatusBarIconButtonProps>(
  ({ iconName, label, isActive = false, ...restProps }: StatusBarIconButtonProps, ref) => {
    return (
      <IconButton
        activated={isActive}
        buttonStyle="secondary-inverse"
        iconName={iconName}
        ref={ref}
        size="medium"
        tooltipPlacement="bottom"
        tooltipText={label}
        {...restProps}
      />
    );
  },
);
