import Immutable from 'immutable';
import { ISubscriptionUsageServerModel } from '../../../../repositories/serverModels/SubscriptionUsageServerModel.type.ts';

export interface ISubscriptionUsage {
  readonly planId: string;
  readonly currentActiveUsers: number;
  readonly projectContainers: Immutable.Map<Uuid, IProjectContainerUsage>;
}

export interface IProjectContainerUsage {
  readonly projects: Immutable.Map<Uuid, IProjectUsage>;
}

export interface IProjectUsage {
  readonly languageCount: number;
  readonly customRoleCount: number;
  readonly userWithLanguageRoleCount: number;
}

export function createSubscriptionUsageDomainModel(
  rawSubscriptionUsage: Partial<ISubscriptionUsageServerModel>,
): ISubscriptionUsage {
  if (typeof rawSubscriptionUsage !== 'object') {
    throw new Error('Subscription usage is not an object');
  }

  const containers = Immutable.Map<Uuid, IProjectContainerUsage>(
    rawSubscriptionUsage.projectContainers || [],
  );
  const containersWithProjects = containers
    .map((container) => {
      const projects = Immutable.Map<Uuid, IProjectUsage>(container?.projects || []);
      return { projects } as IProjectContainerUsage;
    })
    .toMap();

  return {
    planId: rawSubscriptionUsage.planId || '',
    currentActiveUsers: rawSubscriptionUsage.currentActiveUsers || 0,
    projectContainers: containersWithProjects,
  };
}
