export const ContentListing_Columns_HideColumn = 'ContentListing_Columns_HideColumn';
export const ContentListing_Columns_Init = 'ContentListing_Columns_Init';
export const ContentListing_Columns_ShowColumn = 'ContentListing_Columns_ShowColumn';
export const ContentListing_Filter_Changed = 'ContentListing_Filter_Changed';
export const ContentListing_Filter_SearchPhraseChanged =
  'ContentListing_Filter_SearchPhraseChanged';
export const ContentListing_FullTextSearchContext_Changed =
  'ContentListing_FullTextSearchContext_Changed';
export const ContentListing_Init_Finished = 'ContentListing_Init_Finished';
export const ContentListing_Init_Started = 'ContentListing_Init_Started';
export const ContentListing_OrderBy_Changed = 'ContentListing_OrderBy_Changed';
export const ContentListing_ScrollTableState_Changed = 'ContentListing_ScrollTableState_Changed';

export const ContentListing_SelectAll_On = 'ContentListing_SelectAll_On';
export const ContentListing_SelectAll_Off = 'ContentListing_SelectAll_Off';
export const ContentListing_Items_Selected = 'ContentListing_Items_Selected';
export const ContentListing_Items_Unselected = 'ContentListing_Items_Unselected';
export const ContentListing_BulkActionCheck_Loading = 'ContentListing_BulkActionCheck_Loading';
export const ContentListing_BulkActionCheck_Loaded = 'ContentListing_BulkActionCheck_Loaded';

export const ContentListing_StatusInfo_Reset = 'ContentListing_StatusInfo_Reset';
export const ContentListing_Page_Left = 'ContentListing_Page_Left';

export const ContentListing_BulkAction_ExecutionTakingTooLong =
  'ContentListing_BulkAction_ExecutionTakingTooLong';

export const ContentListing_PublishItems_Started = 'ContentListing_PublishItems_Started';
export const ContentListing_PublishItems_Finished = 'ContentListing_PublishItems_Finished';
export const ContentListing_PublishItems_Failed = 'ContentListing_PublishItems_Failed';
export const ContentListing_UndoPublishItems_Started = 'ContentListing_UndoPublishItems_Started';
export const ContentListing_UndoPublishItems_Finished = 'ContentListing_UndoPublishItems_Finished';

export const ContentListing_UnpublishItems_Started = 'ContentListing_UnpublishItems_Started';
export const ContentListing_UnpublishItems_Finished = 'ContentListing_UnpublishItems_Finished';
export const ContentListing_UnpublishItems_Failed = 'ContentListing_UnpublishItems_Failed';

export const ContentListing_CancelScheduledPublish_Started =
  'ContentListing_CancelScheduledPublish_Started';
export const ContentListing_CancelScheduledPublish_Finished =
  'ContentListing_CancelScheduledPublish_Finished';
export const ContentListing_CancelScheduledPublish_Failed =
  'ContentListing_CancelScheduledPublish_Failed';

export const ContentListing_MoveToCollection_Started = 'ContentListing_MoveToCollection_Started';
export const ContentListing_MoveToCollection_Finished = 'ContentListing_MoveToCollection_Finished';
export const ContentListing_MoveToCollection_Failed = 'ContentListing_MoveToCollection_Failed';

export const ContentListing_ScheduledPublishItems_Started =
  'ContentListing_ScheduledPublishItems_Started';
export const ContentListing_ScheduledPublishItems_Finished =
  'ContentListing_ScheduledPublishItems_Finished';
export const ContentListing_ScheduledPublishItems_Failed =
  'ContentListing_ScheduledPublishItems_Failed';
export const ContentListing_UndoScheduledPublish_Started =
  'ContentListing_UndoScheduledPublish_Started';
export const ContentListing_UndoScheduledPublish_Finished =
  'ContentListing_UndoScheduledPublish_Finished';

export const ContentListing_ScheduledUnpublishItems_Started =
  'ContentListing_ScheduledUnpublishItems_Started';
export const ContentListing_ScheduledUnpublishItems_Finished =
  'ContentListing_ScheduledUnpublishItems_Finished';
export const ContentListing_ScheduledUnpublishItems_Failed =
  'ContentListing_ScheduledUnpublishItems_Failed';

export const ContentListing_MoveToArchivedStep_Started =
  'ContentListing_MoveToArchivedStep_Started';
export const ContentListing_RestoreFromArchivedStep_Started =
  'ContentListing_RestoreFromArchivedStep_Started';
export const ContentListing_UpdateStandardStep_Started =
  'ContentListing_UpdateStandardStep_Started';
export const ContentListing_UpdateWorkflowStep_Finished =
  'ContentListing_UpdateWorkflowStep_Finished';
export const ContentListing_UpdateWorkflowStep_Failed = 'ContentListing_UpdateWorkflowStep_Failed';

export const ContentListing_DeleteItems_Started = 'ContentListing_DeleteItems_Started';
export const ContentListing_DeleteItems_Finished = 'ContentListing_DeleteItems_Finished';
export const ContentListing_UndoArchiveItems_Started = 'ContentListing_UndoArchiveItems_Started';
export const ContentListing_UndoArchiveItems_Finished = 'ContentListing_UndoArchiveItems_Finished';

export const ContentListing_CheckPublishWarnings_Finished =
  'ContentListing_CheckPublishWarnings_Finished';
