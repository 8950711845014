import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { areSpacesInCollectionsEnabled } from '../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import { getCanEditGroupContent } from '../../../selectors/getCanEditGroupContent.ts';
import { getEditedContentItemType } from '../../../selectors/getEditedContentItemType.ts';
import { ContentItemHeader as ContentItemHeaderComponent } from '../components/ContentItemHeader.tsx';
import { CreateContentGroupTabsId } from '../utils/contentGroupTabsId.ts';
import { isContentItemNameAndCodenameEditingAllowed } from '../utils/itemEditingUtils.ts';

export const ContentItemHeader = () => {
  const isCollectionInMainPaneEnabled = useSelector(
    (s) => areSpacesInCollectionsEnabled(s) && areCollectionsVisibleForCurrentUser(s),
  );

  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);

  const contentType = useSelector(getEditedContentItemType);

  const contentGroupTabsId = useSelector((s) => {
    const contentItemId = s.contentApp.editedContentItem?.id;
    return contentItemId ? CreateContentGroupTabsId.forContentItem(contentItemId) : null;
  });

  const canEditGroupContent = useSelector(getCanEditGroupContent);

  const canEditName = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    return (
      !!selectedLanguageId &&
      isContentItemNameAndCodenameEditingAllowed(
        s.contentApp.contentItemVariants,
        s.data.languages.defaultLanguage.id,
        selectedLanguageId,
      ) &&
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s)
    );
  });

  return contentType && contentGroupTabsId ? (
    <ContentItemHeaderComponent
      areAnyContentGroupsVisible={areAnyContentGroupsVisible}
      canEditGroupContent={canEditGroupContent}
      canEditName={canEditName}
      contentGroupTabsId={contentGroupTabsId}
      contentType={contentType}
      isCollectionInMainPaneEnabled={isCollectionInMainPaneEnabled}
    />
  ) : null;
};
