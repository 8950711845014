import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { rememberScrollId } from '../../../../../_shared/actions/sharedActions.ts';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useAutoScroll } from '../../../../../_shared/hooks/useAutoScroll.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';

interface IPlaceholderProps {
  readonly blockKey: string;
}

export const Placeholder: React.FC<IPlaceholderProps> = ({ blockKey }) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      rememberScrollId({ path: pathname, scrollId: CreateAutoScrollId.forRichTextBlock(blockKey) }),
    );
  }, [blockKey, pathname]);

  const scrollId = CreateAutoScrollId.forRichTextBlock(blockKey);
  useAutoScroll({ scrollTargetRef: placeholderRef, scrollId });

  return <div ref={placeholderRef} />;
};

Placeholder.displayName = 'Placeholder';
