import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { RefCallback } from '@kontent-ai/utils';
import { ReactNode, forwardRef } from 'react';
import {
  DropDownMenuPositioner,
  DropdownTippyOptions,
} from '../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';

type ActionMenuPositionerProps = Readonly<{
  preferMenuOnTop: boolean;
  renderMenu: () => ReactNode;
  renderResult: (ref: RefCallback<HTMLElement>) => ReactNode;
}>;

export const ActionMenuPositioner = forwardRef<HTMLElement, ActionMenuPositionerProps>(
  ({ preferMenuOnTop, renderMenu, renderResult }, ref) => {
    const { preventOverflowModifier, boundaryProps } = usePreventOverflowFromScrollContainer();

    const actionMenuTippyOptions: DropdownTippyOptions = {
      appendTo: 'parent',
      offset: [0, spacingPopupDistance],
      placement: preferMenuOnTop ? 'top-start' : 'bottom-start',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              ...boundaryProps,
              fallbackPlacements: preferMenuOnTop
                ? ['left-start', 'right-start', 'bottom-start']
                : ['left-start', 'right-start', 'top-start'],
            },
          },
          preventOverflowModifier,
        ],
      },
    };

    return (
      <DropDownMenuPositioner
        allowAnimation={false}
        isDropDownVisible
        renderDropDown={renderMenu}
        renderTrigger={({ ref: triggerRef }) => renderResult(triggerRef)}
        tippyOptions={actionMenuTippyOptions}
        triggerRef={ref}
      />
    );
  },
);

ActionMenuPositioner.displayName = 'ActionMenuPositioner';
