import { useState } from 'react';
import { useEventListener } from '../../../../../_shared/hooks/useEventListener.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { filterOutNullish } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { AssetUploadFinishedEventForLocalState } from '../../../../../_shared/utils/assets/AssetUploadFinishedEvent.ts';

export const useAssetUploadProgress = (): Readonly<{
  progress: number | null;
  someAssetFailed: boolean;
}> => {
  const uploadingAssetIds = useSelector(
    (state) => state.contentApp.editorUi.editedContentItemVariantUploadingAssets,
  );

  // We need to keep the asset IDs locally as while assets finish uploading, they will be disappearing from the uploaded asset IDs
  const [trackedAssetIds, setTrackedAssetIds] = useState(uploadingAssetIds);

  // We also need to track changes to their IDs upon finished upload to keep the finished assets part of the progress
  const assetUploadFinished = (e: AssetUploadFinishedEventForLocalState) => {
    setTrackedAssetIds((prevState) =>
      prevState.map((assetId) => {
        const { oldAssetId, newAssetId } = e.detail;
        return assetId === oldAssetId && newAssetId ? newAssetId : assetId;
      }),
    );
  };

  useEventListener('assetUploadFinishedForLocalState', assetUploadFinished, self);

  const result = useSelector((state) => {
    if (trackedAssetIds.length === 0) {
      return {
        progress: null,
        someAssetFailed: false,
      };
    }

    const assets = state.data.assets.byId;
    const uploadingAssets = filterOutNullish(trackedAssetIds.map((assetId) => assets.get(assetId)));

    const aggregated = uploadingAssets.reduce<
      Readonly<{ totalSize: number; finishedSize: number; someAssetFailed: boolean }>
    >(
      (acc, asset) => {
        const assetFinishedSize = asset._failed
          ? asset.fileSize
          : (asset._sizeUploaded ?? asset.fileSize);
        return {
          totalSize: acc.totalSize + asset.fileSize,
          finishedSize: acc.finishedSize + assetFinishedSize,
          someAssetFailed: acc.someAssetFailed || !!asset._failed,
        };
      },
      {
        totalSize: 0,
        finishedSize: 0,
        someAssetFailed: false,
      },
    );

    return {
      progress: aggregated.totalSize
        ? (aggregated.finishedSize / aggregated.totalSize) * 100
        : null,
      someAssetFailed: aggregated.someAssetFailed,
    };
  });

  return result;
};
