import { ItemColumnCode } from '../../constants/itemColumnCode.ts';

export enum ColumnSettingsSource {
  ContentInventory = 'content-inventory',
  ModalItemSelector = 'modal-item-selector',
}

export enum ColumnSettingsAction {
  Hide = 'hide',
  Show = 'show',
}

export interface IColumnSettingsData {
  readonly action: ColumnSettingsAction;
  readonly column: ItemColumnCode;
  readonly source: ColumnSettingsSource;
}
