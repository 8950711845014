import { ContentVariantIdStorageKey } from '../_shared/constants/localStorageKeys.ts';
import { DefaultVariantId } from '../_shared/constants/variantIdValues.ts';
import { localStorage } from '../_shared/utils/localStorage.ts';

let _variantId: Uuid | null = null;

export const contentVariantIdStorage = {
  save(variantId: Uuid): void {
    localStorage.set(ContentVariantIdStorageKey, variantId);
    _variantId = variantId;
  },

  forget(): void {
    localStorage.remove(ContentVariantIdStorageKey);
    _variantId = DefaultVariantId;
  },

  load(): Uuid | null {
    if (_variantId === null) {
      _variantId = localStorage.get(ContentVariantIdStorageKey);
    }

    return _variantId;
  },
};
