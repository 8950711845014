import { loadUserProperties } from '../../../_shared/actions/thunkSharedActions.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { loadUserInfo } from '../../../data/actions/thunkDataActions.ts';
import { createRequestChangePasswordAction } from './thunks/requestChangePassword.ts';
import { createSubmitProfileFormAction } from './thunks/submitProfileForm.ts';

const { accountRepository } = repositoryCollection;

export const submitProfileForm = createSubmitProfileFormAction({
  accountRepository,
  loadUserInfo,
  loadUserProperties,
});

export const requestChangePassword = createRequestChangePasswordAction();
