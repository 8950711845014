export const Webhooks_CancelEditing = 'Webhooks_CancelEditing';
export const Webhooks_CancelEditingInitial = 'Webhooks_CancelEditingInitial';
export const Webhooks_CreateEmpty = 'Webhooks_CreateEmpty';
export const Webhooks_InitEdit = 'Webhooks_InitEdit';
export const Webhooks_ListingClose_Finished = 'Webhooks_ListingClose_Finished';
export const Webhooks_ListingInit_Finished = 'Webhooks_ListingInit_Finished';
export const Webhooks_ListingInit_Started = 'Webhooks_ListingInit_Started';
export const Webhooks_OnboardingStep_Updated = 'Webhooks_OnboardingStep_Updated';
export const Webhooks_Webhook_ArchiveCompleted = 'Webhooks_Webhook_ArchiveCompleted';
export const Webhooks_Webhook_ArchiveFailed = 'Webhooks_Webhook_ArchiveFailed';
export const Webhooks_Webhook_ArchiveStarted = 'Webhooks_Webhook_ArchiveStarted';
export const Webhooks_Webhook_DisableCompleted = 'Webhooks_Webhook_DisableCompleted';
export const Webhooks_Webhook_DisableFailed = 'Webhooks_Webhook_DisableFailed';
export const Webhooks_Webhook_DisableStarted = 'Webhooks_Webhook_DisableStarted';
export const Webhooks_Webhook_EnableCompleted = 'Webhooks_Webhook_EnableCompleted';
export const Webhooks_Webhook_EnableFailed = 'Webhooks_Webhook_EnableFailed';
export const Webhooks_Webhook_EnableStarted = 'Webhooks_Webhook_EnableStarted';
export const Webhooks_Webhook_ResetCompleted = 'Webhooks_Webhook_ResetCompleted';
export const Webhooks_Webhook_ResetFailed = 'Webhooks_Webhook_ResetFailed';
export const Webhooks_Webhook_ResetStarted = 'Webhooks_Webhook_ResetStarted';
export const Webhooks_Webhook_RestoreCompleted = 'Webhooks_Webhook_RestoreCompleted';
export const Webhooks_Webhook_RestoreFailed = 'Webhooks_Webhook_RestoreFailed';
export const Webhooks_Webhook_RestoreStarted = 'Webhooks_Webhook_RestoreStarted';
export const Webhooks_Webhook_InsertCompleted = 'Webhooks_Webhook_InsertCompleted';
export const Webhooks_Webhook_UpdateCompleted = 'Webhooks_Webhook_UpdateCompleted';
export const Webhooks_Webhook_SaveFailed = 'Webhooks_Webhook_SaveFailed';
export const Webhooks_Webhook_SaveStarted = 'Webhooks_Webhook_SaveStarted';
export const Webhooks_WebhookMessageListing_LoadFinished =
  'Webhooks_WebhookMessageListing_LoadFinished';
export const Webhooks_WebhookMessageListing_LoadStarted =
  'Webhooks_WebhookMessageListing_LoadStarted';
export const Webhooks_WebhookMessageContent_LoadFinished =
  'Webhooks_WebhookMessageContent_LoadFinished';
export const Webhooks_WebhookMessageContent_LoadStarted =
  'Webhooks_WebhookMessageContent_LoadStarted';
export const Webhooks_WebhookMessageListing_Filtered = 'Webhooks_WebhookMessageListing_Filtered';
