import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';

type Props = {
  readonly onCreate: () => void;
  readonly searchPhrase: string;
};

export const SpacesFilterEmptyState: React.FC<Props> = ({ onCreate, searchPhrase }) => {
  return (
    <EmptyState size="shrink">
      <EmptyState.Title>We couldn’t find any match.</EmptyState.Title>
      <EmptyState.Content>
        {`Looks like a space named “${searchPhrase}” doesn’t exist. Do you want to create it?`}
      </EmptyState.Content>
      <EmptyState.Footer>
        <Button buttonStyle="primary" onClick={onCreate}>
          {`Create “${searchPhrase}”`}
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};

SpacesFilterEmptyState.displayName = 'SpacesFilterEmptyState';
