import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { ICollectionGroupRoles } from '../../../data/models/roles/INormalizedRolesWithSettings.ts';
import { IRoleWithSettings } from '../../../data/models/roles/IRoleWithSettings.ts';
import {
  ICollectionGroupRoles as IContributorCollectionGroupRoles,
  IProjectContributor,
} from '../../../data/models/users/ProjectContributor.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import {
  getCurrentProject,
  getNormalizedRolesWithSettingsForUser,
} from '../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../stores/IStore.type.ts';

export const getApplicableRole = <T extends { readonly languageIds: ReadonlyArray<Uuid> }>(
  roles: ReadonlyArray<T>,
  languageId: Uuid,
): T | null =>
  roles.find((r: T) => r.languageIds.includes(languageId)) ??
  roles.find((r: T) => !r.languageIds.length) ??
  null;

export const getApplicableCollectionGroup = <T extends { readonly collectionIds: UuidArray }>(
  collectionGroups: ReadonlyArray<T>,
  collectionId: Uuid,
): T | null =>
  collectionGroups.find((g: T) => g.collectionIds.includes(collectionId)) ??
  collectionGroups.find((g: T) => g.collectionIds.length === 0) ??
  null;

export const getAllApplicableRolesForLanguage = (
  collectionGroups: ReadonlyArray<ICollectionGroupRoles>,
  languageId: Uuid,
): ReadonlyArray<IRoleWithSettings> =>
  collectionGroups
    .map((g) => getApplicableRole(g.roles, languageId)?.role)
    .filter((r) => !!r) as ReadonlyArray<IRoleWithSettings>;

export const getAllApplicableContributorRoleIdsForLanguage = (
  collectionGroups: ReadonlyArray<IContributorCollectionGroupRoles>,
  languageId: Uuid,
): UuidArray =>
  collectionGroups
    .map((g) => getApplicableRole(g.roles, languageId)?.roleId)
    .filter((r) => !!r) as UuidArray;

export const getApplicableContributorRoleId = (
  contributor: IProjectContributor,
  languageId: Uuid,
  collectionId: Uuid | null,
): Uuid | null => {
  const group = collectionId
    ? getApplicableCollectionGroup(contributor.collectionGroups, collectionId)
    : null;

  return (group && getApplicableRole(group.roles, languageId)?.roleId) ?? null;
};

export const getCurrentUserRoleForCollectionForLanguageForUser = memoize.maxOne(
  (
    userState: IUser,
    projectId: Uuid,
    collectionId: Uuid | null,
    languageId: Uuid,
  ): IRoleWithSettings | null => {
    const normalizedRoles = getNormalizedRolesWithSettingsForUser(userState, projectId);
    const applicableCollectionGroup = collectionId
      ? getApplicableCollectionGroup(normalizedRoles.collectionGroups, collectionId)
      : null;

    return (
      (applicableCollectionGroup &&
        getApplicableRole(applicableCollectionGroup?.roles, languageId)?.role) ??
      null
    );
  },
);

export const getCurrentUserRoleForCollectionForLanguage = (
  state: IStore,
  collectionId: Uuid,
  languageId: Uuid,
): IRoleWithSettings | null =>
  getCurrentUserRoleForCollectionForLanguageForUser(
    state.data.user,
    getCurrentProject(state).projectId,
    collectionId,
    languageId,
  );

/**
 * @summary use only withing content/ app, where the existence of role is ensured by routing
 */
export const getCurrentUserRoleForCollectionForLanguageOrThrow = (
  state: IStore,
  collectionId: Uuid,
  languageId: Uuid,
): IRoleWithSettings => {
  const result = getCurrentUserRoleForCollectionForLanguage(state, collectionId, languageId);
  if (!result) {
    throw InvariantException(
      `No user role was found for collection ${collectionId} for language ${languageId}`,
    );
  }

  return result;
};
