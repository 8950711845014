import { LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { colorTextDefault } from '../../../tokens/decision/colors.ts';
import { Label } from '../../Label/Label.tsx';

export const InputLabel = styled(Label).attrs({
  component: 'label',
})<LabelHTMLAttributes<HTMLLabelElement>>`
  color: ${colorTextDefault};
  text-align: left;
  word-break: break-word;
`;
