import { InvariantException } from '@kontent-ai/errors';
import { ContentTypeConversionOptions } from '../../../../data/models/contentModelsApp/ContentTypeConversionOptions.type.ts';
import { CompiledContentTypeElementServerModel } from '../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  CompiledTypeElementTypeServerModel,
  ElementType,
} from '../models/ContentItemElementType.ts';
import { getAssetTypeElementFromServerModel } from '../models/contentTypeElements/AssetTypeElement.ts';
import { createCustomTypeElementDomainModel } from '../models/contentTypeElements/CustomTypeElement.ts';
import { getDateTimeTypeElementFromServerModel } from '../models/contentTypeElements/DateTimeTypeElement.ts';
import { getGuidelinesTypeElementFromServerModel } from '../models/contentTypeElements/GuidelinesTypeElement.ts';
import { getLinkedItemsTypeElementFromServerModel } from '../models/contentTypeElements/LinkedItemsTypeElement.ts';
import { getMultipleChoiceTypeElementFromServerModel } from '../models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { getNumberTypeElementFromServerModel } from '../models/contentTypeElements/NumberTypeElement.ts';
import { getRichTextTypeElementFromServerModel } from '../models/contentTypeElements/RichTextTypeElement.ts';
import { getTaxonomyTypeElementFromServerModel } from '../models/contentTypeElements/TaxonomyTypeElement.ts';
import { getTextTypeElementFromServerModel } from '../models/contentTypeElements/TextTypeElement.ts';
import { TypeElement } from '../models/contentTypeElements/TypeElement.type.ts';
import { getUrlSlugTypeElementFromServerModel } from '../models/contentTypeElements/UrlSlugTypeElement.ts';

type ElementFromServerModelConverter = (
  serverModel: CompiledContentTypeElementServerModel,
  conversionOptions?: ContentTypeConversionOptions,
) => TypeElement;

const elementTypeConverterMap: ReadonlyRecord<
  CompiledTypeElementTypeServerModel,
  ElementFromServerModelConverter
> = {
  [ElementType.Asset]: getAssetTypeElementFromServerModel,
  [ElementType.Custom]: createCustomTypeElementDomainModel,
  [ElementType.DateTime]: getDateTimeTypeElementFromServerModel,
  [ElementType.Guidelines]: getGuidelinesTypeElementFromServerModel,
  [ElementType.LinkedItems]: getLinkedItemsTypeElementFromServerModel,
  [ElementType.MultipleChoice]: getMultipleChoiceTypeElementFromServerModel,
  [ElementType.Number]: getNumberTypeElementFromServerModel,
  [ElementType.RichText]: getRichTextTypeElementFromServerModel,
  [ElementType.Taxonomy]: getTaxonomyTypeElementFromServerModel,
  [ElementType.Text]: getTextTypeElementFromServerModel,
  [ElementType.UrlSlug]: getUrlSlugTypeElementFromServerModel,
};

const isKnownKey = (
  key: CompiledContentTypeElementServerModel['type'],
): key is keyof typeof elementTypeConverterMap =>
  (
    Object.keys(elementTypeConverterMap) as ReadonlyArray<
      CompiledContentTypeElementServerModel['type']
    >
  ).includes(key);

export const getCompiledTypeElementFromServerModel = (
  serverModel: CompiledContentTypeElementServerModel,
  conversionOptions: ContentTypeConversionOptions,
) => {
  if (isKnownKey(serverModel.type)) {
    return elementTypeConverterMap[serverModel.type](serverModel, conversionOptions);
  }

  throw InvariantException(`Converter for type "${serverModel.type}" does not exists.`);
};
