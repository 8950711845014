import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { ContextualHelp } from '../../../../_shared/components/ContextualHelp/ContextualHelp.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';

type Props = {
  readonly headline: string;
  readonly isUpsellVisible: boolean;
  readonly onDismiss: () => void;
  readonly trackVisitingManageWorkflowsDocs: () => void;
};

export const UpsellMultipleWorkflows = ({
  headline,
  isUpsellVisible,
  onDismiss,
  trackVisitingManageWorkflowsDocs,
}: Props) => {
  return (
    <ContextualHelp
      bulletPoints={[]}
      onDismiss={onDismiss}
      shouldShow={isUpsellVisible}
      title={headline}
      callToAction={
        <>
          <p>
            You can now create separate workflows so each type of your content has the right
            workflow.
          </p>
          <p>Get in touch with us to get this premium feature.</p>
          <p>
            <OutwardLink
              className="contextual-help__link"
              href={documentationLinks.manageWorkflows}
              onClick={trackVisitingManageWorkflowsDocs}
            >
              More information
            </OutwardLink>
          </p>
        </>
      }
    />
  );
};
