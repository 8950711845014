import React from 'react';
import { ContentProgressWidgetData } from '../../../types/ContentProgressWidget.type.ts';
import { ContentProgressWidget } from '../components/ContentProgressWidget.tsx';

const demoData: ContentProgressWidgetData = {
  nearDue: 10,
  onTrack: 20,
  overDue: 2,
  scheduled: 2,
  withoutDue: 5,
};

export const ContentProgressWidgetDemoContainer: React.FC = () => {
  return <ContentProgressWidget contentProgressFetcher={() => Promise.resolve(demoData)} />;
};

ContentProgressWidgetDemoContainer.displayName = 'ContentProgressWidgetDemoContainer';
