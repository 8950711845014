import { ReactNode, useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { apiKeysSettingsLeft } from '../actions/apiKeysActions.ts';
import { initApiKeysSettings } from '../actions/thunkApiKeysActions.ts';
import { isApiKeysSettingsInitialized } from '../selectors/apiKeysLoadedSelectors.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureApiKeysSettingsInitialized = ({ children }: Props) => {
  const [isInitThunkDone] = useThunkPromise(initApiKeysSettings);
  const isStateInitialized = useSelector(isApiKeysSettingsInitialized) && isInitThunkDone;

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(apiKeysSettingsLeft());
    };
  }, []);

  return isStateInitialized ? children : <Loader />;
};
