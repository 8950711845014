import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  TrackUserEventData,
  TrackUserEventWithData,
  TrackUserEventWithDataAction,
} from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  IContentTypeSnippet,
  getContentTypeSnippetFromServerModel,
} from '../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import {
  ContentTypeSnippetServerModel,
  CreateNewContentTypeSnippetServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeSnippetServerModels.type.ts';
import { ContentTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import { typeEditorSaveAttempted } from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { createActiveConditionIds } from '../../../shared/utils/conditionUtils.ts';
import { ITrackAssetLimitConfigurationChanged } from '../../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { ITrackCustomTypeElementUpserted } from '../../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { UntitledContentTypeSnippetName } from '../../constants/UntitledContentTypeSnippetName.ts';
import {
  ContentTypeSnippet_Creator_CreationFinished,
  ContentTypeSnippet_Creator_CreationStarted,
} from '../../constants/snippetActionTypes.ts';
import { isContentTypeSnippetValid } from '../../utils/isContentTypeSnippetValid.ts';
import { contentTypeSnippetInvalidElementShowWarning } from '../snippetsActions.ts';

interface IDeps {
  readonly contentTypeSnippetRepository: {
    readonly createContentTypeSnippet: (
      contentTypeSnippet: CreateNewContentTypeSnippetServerModel,
    ) => Promise<ContentTypeSnippetServerModel>;
  };
  readonly convertTypeElementsToServerModel: (
    typeElementRecord: ReadonlyArray<IBaseTypeElementData>,
  ) => Array<ContentTypeElementServerModel>;
  readonly loadSnippetsData: () => ThunkPromise;
  readonly saveEditedMultipleChoiceOption: () => ThunkFunction;
  readonly trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged;
  readonly trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted;
  readonly trackUserEvent: TrackUserEventData;
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

const contentTypeSnippetCreationStarted = () =>
  ({ type: ContentTypeSnippet_Creator_CreationStarted }) as const;

const contentTypeSnippetCreationFinished = (
  editedContentTypeSnippet: IContentTypeSnippet,
  editedContentTypeSnippetElements: ReadonlyArray<IBaseTypeElementData>,
) =>
  ({
    type: ContentTypeSnippet_Creator_CreationFinished,
    payload: {
      editedContentTypeSnippet,
      editedContentTypeSnippetElements,
    },
  }) as const;

export type CreateContentTypeSnippetActionsType = ReturnType<
  typeof contentTypeSnippetCreationStarted | typeof contentTypeSnippetCreationFinished
>;

export const createCreateContentTypeSnippetAction =
  (deps: IDeps) => (): ThunkPromise<IContentTypeSnippet> => async (dispatch, getState) => {
    dispatch(deps.saveEditedMultipleChoiceOption());

    const {
      contentModelsApp: {
        snippets: {
          editor: { editedContentTypeSnippet },
        },
      },
    } = getState();

    dispatch(typeEditorSaveAttempted(createActiveConditionIds(editedContentTypeSnippet)));

    editedContentTypeSnippet.typeElements.forEach((element: IBaseTypeElementData) =>
      dispatch(deps.validateTypeElement(element)),
    );
    if (!isContentTypeSnippetValid(getState().contentModelsApp.snippets.editor.validationResults)) {
      dispatch(contentTypeSnippetInvalidElementShowWarning());
      throw new Error('Validation results is invalid.');
    }

    dispatch(contentTypeSnippetCreationStarted());

    const name = editedContentTypeSnippet.name
      ? editedContentTypeSnippet.name
      : UntitledContentTypeSnippetName;
    const contentElements = deps.convertTypeElementsToServerModel(
      editedContentTypeSnippet.typeElements,
    );

    const contentTypeSnippetForServer: CreateNewContentTypeSnippetServerModel = {
      name,
      contentElements,
    };
    const createdContentTypeServerModel =
      await deps.contentTypeSnippetRepository.createContentTypeSnippet(contentTypeSnippetForServer);
    const createdContentType = getContentTypeSnippetFromServerModel(createdContentTypeServerModel);
    const createdContentTypeElements = createdContentType.typeElements;

    const trackEvent: TrackUserEventWithData = (...args) =>
      dispatch(deps.trackUserEventWithData(...args));
    deps.trackAssetLimitConfigurationChanged(trackEvent, createdContentTypeElements);
    deps.trackCustomTypeElementUpserted(trackEvent, createdContentTypeElements);
    await dispatch(deps.loadSnippetsData());
    dispatch(deps.trackUserEvent(TrackedEvent.ContentTypeSnippetCreated));
    dispatch(contentTypeSnippetCreationFinished(createdContentType, createdContentTypeElements));

    return createdContentType;
  };
