import { Action } from '../../../../../../@types/Action.type.ts';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  ContentItemFilter_Left,
  ContentItemFilter_SetUp,
} from '../../../features/ListingFilter/constants/listingFilterActionTypes.ts';

const initialStatus: ReadonlyRecord<ContentItemFilterOrigin, LoadingStatus> = {
  [ContentItemFilterOrigin.Calendar]: LoadingStatus.InitialEmpty,
  [ContentItemFilterOrigin.ContentInventory]: LoadingStatus.InitialEmpty,
  [ContentItemFilterOrigin.ModalContentItemSelector]: LoadingStatus.InitialEmpty,
  [ContentItemFilterOrigin.ModalMultipleContentItemsSelector]: LoadingStatus.InitialEmpty,
  [ContentItemFilterOrigin.ProjectOverview]: LoadingStatus.InitialEmpty,
  [ContentItemFilterOrigin.ContentStatus]: LoadingStatus.InitialEmpty,
};

export function filterStatus(
  state: ReadonlyRecord<ContentItemFilterOrigin, LoadingStatus> = initialStatus,
  action: Action,
): ReadonlyRecord<ContentItemFilterOrigin, LoadingStatus> {
  switch (action.type) {
    case ContentItemFilter_SetUp: {
      return {
        ...initialStatus,
        [action.payload.origin]: LoadingStatus.Loaded,
      };
    }
    case ContentItemFilter_Left: {
      return {
        ...initialStatus,
        [action.payload.origin]: LoadingStatus.InitialEmpty,
      };
    }
    default:
      return state;
  }
}
