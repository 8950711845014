import { Action } from '../../../../@types/Action.type.ts';
import {
  ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set,
  ChangeWorkflowStep_ScheduleToPublishMethod_Changed,
  ChangeWorkflowStep_ScheduledToPublish_Changed,
} from '../constants/changeWorkflowStepActionTypes.ts';

const emptyState: string | null = null;

export function scheduledToPublishAt(state = emptyState, action: Action): string | null {
  switch (action.type) {
    case ChangeWorkflowStep_InitialChangeWorkflowStepModalData_Set:
      return action.payload.changeWorkflowStepModalData.scheduledToPublishAt;

    case ChangeWorkflowStep_ScheduleToPublishMethod_Changed:
      return emptyState;

    case ChangeWorkflowStep_ScheduledToPublish_Changed:
      return action.payload.scheduledToPublishAt;

    default:
      return state;
  }
}
