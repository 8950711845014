import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiRevisionAction,
  getDataUiRevisionActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly children: string;
  readonly onClick: () => void;
};

export const RestoreRevisionButton: React.FC<Props> = ({ children, onClick }) => (
  <Box marginLeft={Spacing.S}>
    <Button
      buttonStyle="secondary"
      size="medium"
      onClick={onClick}
      {...getDataUiRevisionActionAttribute(DataUiRevisionAction.RestoreRevision)}
    >
      {children}
    </Button>
  </Box>
);
