import { IBillingHistoryItemServerModel } from '../../../../repositories/serverModels/BillingHistoryItemServerModel.type.ts';

export enum PaymentStatusCode {
  Success = 'Success',
  Error = 'Error',
  Unknown = 'Unknown',
}

export interface IBillingHistoryItem {
  readonly id: string;
  readonly periodEnd: DateTimeStamp | null;
  readonly periodStart: DateTimeStamp | null;
  readonly plan: string;
  readonly price: number;
  readonly paymentStatus: string;
  readonly paymentStatusCode: PaymentStatusCode;
}

export const getBillingHistoryItemFromServerModel = (
  rawBillingHistoryItem?: IBillingHistoryItemServerModel,
): IBillingHistoryItem => {
  if (typeof rawBillingHistoryItem !== 'object') {
    throw new Error('Billing History item is not an object');
  }

  return {
    id: rawBillingHistoryItem.id ?? '',
    periodEnd: rawBillingHistoryItem.periodEnd ?? null,
    periodStart: rawBillingHistoryItem.periodStart ?? null,
    plan: rawBillingHistoryItem.plan ?? '',
    price: rawBillingHistoryItem.price ?? 0,
    paymentStatus: rawBillingHistoryItem.paymentStatus ?? '',
    paymentStatusCode: getPaymentStatusCodeFromServerModel(rawBillingHistoryItem.paymentStatusCode),
  };
};

const getPaymentStatusCodeFromServerModel = (statusCode: string): PaymentStatusCode => {
  if (statusCode === 'success') {
    return PaymentStatusCode.Success;
  }
  if (statusCode === 'error') {
    return PaymentStatusCode.Error;
  }

  return PaymentStatusCode.Unknown;
};
