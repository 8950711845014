import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventWithDataAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentComponentActionType } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { ContentItemEditing_ContentComponent_Expanded } from '../../constants/contentItemEditingActionTypes.ts';

const contentComponentExpanded = (contentComponentId: Uuid) =>
  ({
    type: ContentItemEditing_ContentComponent_Expanded,
    payload: { contentComponentId },
  }) as const;

export type ExpandContentComponentActionsType = ReturnType<typeof contentComponentExpanded>;

interface IDeps {
  readonly trackUserEventWithData: TrackUserEventWithDataAction;
}

export const createExpandContentComponentAction =
  (deps: IDeps) =>
  (contentComponentId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    const { editedContentItemVariant } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw new Error('This is available only in edited content item');
    }

    dispatch(contentComponentExpanded(contentComponentId));
    dispatch(
      deps.trackUserEventWithData(TrackedEvent.ContentComponentCollapse, {
        action: ContentComponentActionType.ExpandOne,
        'item-id': editedContentItemVariant.id.itemId,
        'variant-id': editedContentItemVariant.id.variantId,
        'content-component-id': contentComponentId,
      }),
    );
  };
