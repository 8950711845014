import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ICommentThread } from '../../../../../../applications/itemEditor/models/comments/CommentThreads.ts';
import { AiAssistantId } from '../../../../constants/commentsConstants.ts';

export const getUnresolvedCommentThreadsByAiAssistant = (
  state: IStore,
  elementId: Uuid,
): ReadonlyArray<ICommentThread> =>
  state.contentApp.editedContentItemVariantComments.commentThreads.filter(
    (c) =>
      !c.resolvedAt &&
      c.elementId === elementId &&
      c.threadItems.first()?.createdBy === AiAssistantId,
  );
