import { Action } from '../../../../../@types/Action.type.ts';
import { ContentModels_TypeEditor_InitStarted } from '../../../shared/constants/sharedContentModelsActionTypes.ts';
import {
  ContentType_Creator_InitStarted,
  ContentType_Editor_InitFinished,
} from '../../constants/contentTypesActionTypes.ts';

export function isEditedContentTypeUsedInPublishedItem(state = false, action: Action): boolean {
  switch (action.type) {
    case ContentType_Editor_InitFinished: {
      return action.payload.isEditedContentTypeUsedInPublishedItem;
    }

    case ContentType_Creator_InitStarted:
    case ContentModels_TypeEditor_InitStarted: {
      return false;
    }

    default:
      return state;
  }
}
