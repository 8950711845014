import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import {
  Role_Editing_CapabilitiesChecked,
  Role_Editing_CapabilitiesUnchecked,
} from '../../constants/rolesActionTypes.ts';

export const editedCapabilities = (
  state = Immutable.Set<Capability>(),
  action: Action,
): Immutable.Set<Capability> => {
  switch (action.type) {
    case Role_Editing_CapabilitiesChecked:
      return state.union(action.payload.capabilities);

    case Role_Editing_CapabilitiesUnchecked:
      return state.subtract(action.payload.capabilities);

    default:
      return state;
  }
};
