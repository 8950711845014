import React from 'react';
import { getConnectedStatusMessage } from '../../../../../_shared/containers/StatusBar/StatusMessage.tsx';
import {
  StatusMessage,
  UnsavedStatusMessage,
} from '../../../../../_shared/models/StatusMessage.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getMessageFromOperationStatus } from '../../../utils/savingStatusUtils.ts';

const getSavingStatusMessage = ({
  localizationApp: { editedLanguageId, isDefaultLanguageEdited },
  projectsApp: { savingStatus },
}: IStore): StatusMessage => {
  const isLanguageEditedOrCreated = !!editedLanguageId;
  if (isLanguageEditedOrCreated || isDefaultLanguageEdited) {
    return UnsavedStatusMessage;
  }

  return getMessageFromOperationStatus(savingStatus.languages.status);
};

export const LanguageSavingStatus: React.ComponentType =
  getConnectedStatusMessage(getSavingStatusMessage);
