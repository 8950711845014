import { filterOutDuplicates } from '../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  WebhookHealthStatus,
  mapWebhookHealthStatusFromServerValue,
} from '../../../applications/webhooks/constants/webhookHealthStatuses.ts';
import { DefaultWebhookId } from '../../../applications/webhooks/constants/webhookValues.ts';
import { IEntityWebhookServerModel } from '../../../repositories/serverModels/IEntityWebhookServerModel.type.ts';

export enum EntityWebhookAction {
  Changed = 'changed',
  Created = 'created',
  Deleted = 'deleted',
  MetadataChanged = 'metadata_changed',
  TermChanged = 'term_changed',
  TermCreated = 'term_created',
  TermDeleted = 'term_deleted',
  TermsMoved = 'terms_moved',
  Published = 'published',
  Unpublished = 'unpublished',
  WorkflowStepChanged = 'workflow_step_changed',
}

export enum EntityWebhookDeliverySlot {
  Preview = 'preview',
  Published = 'published',
}

export enum EntityWebhookEventsFilter {
  All = 'all',
  Specific = 'specific',
}

export interface IWorkflowStatusReference {
  readonly stepId: Uuid;
  readonly workflowId: Uuid;
}

interface IEntityWebhookTrigger {
  readonly actions: ReadonlyArray<EntityWebhookAction>;
  readonly enabled: boolean;
}

export interface IAssetTrigger extends IEntityWebhookTrigger {}

export interface IContentItemTrigger extends IEntityWebhookTrigger {
  readonly collectionFilters: ReadonlyArray<Uuid>;
  readonly contentTypeFilters: ReadonlyArray<Uuid>;
  readonly languageFilters: ReadonlyArray<Uuid>;
  readonly workflowSteps: ReadonlyArray<IWorkflowStatusReference>;
}

export interface IContentTypeTrigger extends IEntityWebhookTrigger {
  readonly contentTypeFilters: ReadonlyArray<Uuid>;
}

export interface IEntityWebhookHeader {
  readonly key: string;
  readonly value: string;
}

export interface ILanguageTrigger extends IEntityWebhookTrigger {
  readonly languageFilters: ReadonlyArray<Uuid>;
}

export interface ITaxonomyTrigger extends IEntityWebhookTrigger {
  readonly taxonomyFilters: ReadonlyArray<Uuid>;
}

export interface IEntityWebhookTriggers {
  readonly assetTrigger: IAssetTrigger;
  readonly contentItemTrigger: IContentItemTrigger;
  readonly contentTypeTrigger: IContentTypeTrigger;
  readonly events: EntityWebhookEventsFilter;
  readonly languageTrigger: ILanguageTrigger;
  readonly slot: EntityWebhookDeliverySlot;
  readonly taxonomyTrigger: ITaxonomyTrigger;
}

export interface IEntityWebhookSetting {
  readonly enabled: boolean;
  readonly headers: ReadonlyArray<IEntityWebhookHeader>;
  readonly healthStatus: WebhookHealthStatus;
  readonly id: Uuid;
  readonly name: string;
  readonly secret: string;
  readonly triggers: IEntityWebhookTriggers;
  readonly url: string;
}

export const contentItemLiveTriggerActions: ReadonlyArray<EntityWebhookAction> = [
  EntityWebhookAction.MetadataChanged,
  EntityWebhookAction.Published,
  EntityWebhookAction.Unpublished,
];

export const contentItemPreviewTriggerActions: ReadonlyArray<EntityWebhookAction> = [
  EntityWebhookAction.MetadataChanged,
  EntityWebhookAction.Created,
  EntityWebhookAction.Changed,
  EntityWebhookAction.Deleted,
  EntityWebhookAction.WorkflowStepChanged,
];

export const emptyEntityWebhook: IEntityWebhookSetting = {
  enabled: true,
  headers: [],
  healthStatus: WebhookHealthStatus.Unknown,
  id: DefaultWebhookId,
  name: '',
  secret: '',
  triggers: {
    assetTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
        EntityWebhookAction.MetadataChanged,
      ],
      enabled: true,
    },
    contentItemTrigger: {
      actions: filterOutDuplicates([
        ...contentItemLiveTriggerActions,
        ...contentItemPreviewTriggerActions,
      ]),
      collectionFilters: [],
      contentTypeFilters: [],
      enabled: true,
      languageFilters: [],
      workflowSteps: [],
    },
    contentTypeTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
      ],
      enabled: true,
      contentTypeFilters: [],
    },
    events: EntityWebhookEventsFilter.All,
    languageTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
      ],
      enabled: true,
      languageFilters: [],
    },
    slot: EntityWebhookDeliverySlot.Published,
    taxonomyTrigger: {
      actions: [
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
        EntityWebhookAction.MetadataChanged,
        EntityWebhookAction.TermChanged,
        EntityWebhookAction.TermCreated,
        EntityWebhookAction.TermDeleted,
        EntityWebhookAction.TermsMoved,
      ],
      enabled: true,
      taxonomyFilters: [],
    },
  },
  url: '',
};

export const createEntityWebhookSettingDomainModel = (
  rawWebhook: IEntityWebhookServerModel,
): IEntityWebhookSetting => ({
  enabled: rawWebhook.enabled,
  healthStatus: mapWebhookHealthStatusFromServerValue(rawWebhook.healthStatus),
  id: rawWebhook.id,
  name: rawWebhook.name,
  secret: rawWebhook.secret,
  headers: rawWebhook.headers.map((h) => ({ key: h.key, value: h.value })),
  triggers: {
    assetTrigger: {
      actions: rawWebhook.deliveryTriggers.asset.actions.map((a) => a.action),
      enabled: rawWebhook.deliveryTriggers.asset.enabled,
    },
    contentItemTrigger: {
      actions: rawWebhook.deliveryTriggers.contentItem.actions.map((a) => a.action),
      collectionFilters: rawWebhook.deliveryTriggers.contentItem.filters.collections.map(
        (c) => c.id,
      ),
      contentTypeFilters: rawWebhook.deliveryTriggers.contentItem.filters.contentTypes.map(
        (c) => c.id,
      ),
      enabled: rawWebhook.deliveryTriggers.contentItem.enabled,
      languageFilters: rawWebhook.deliveryTriggers.contentItem.filters.languages.map((l) => l.id),
      workflowSteps:
        rawWebhook.deliveryTriggers.contentItem.actions.find(
          (a) => a.action === EntityWebhookAction.WorkflowStepChanged,
        )?.transitionTo ?? ([] as IWorkflowStatusReference[]),
    },
    contentTypeTrigger: {
      actions: rawWebhook.deliveryTriggers.contentType.actions.map((a) => a.action),
      enabled: rawWebhook.deliveryTriggers.contentType.enabled,
      contentTypeFilters: rawWebhook.deliveryTriggers.contentType.filters.contentTypes.map(
        (c) => c.id,
      ),
    },
    events: rawWebhook.deliveryTriggers.events,
    languageTrigger: {
      actions: rawWebhook.deliveryTriggers.language.actions.map((a) => a.action),
      enabled: rawWebhook.deliveryTriggers.language.enabled,
      languageFilters: rawWebhook.deliveryTriggers.language.filters.languages.map((l) => l.id),
    },
    slot: rawWebhook.deliveryTriggers.slot,
    taxonomyTrigger: {
      actions: rawWebhook.deliveryTriggers.taxonomy.actions.map((a) => a.action),
      enabled: rawWebhook.deliveryTriggers.taxonomy.enabled,
      taxonomyFilters: rawWebhook.deliveryTriggers.taxonomy.filters.taxonomies.map((t) => t.id),
    },
  },
  url: rawWebhook.url,
});

export const createEntityWebhookSettingServerModel = (
  webhookSetting: IEntityWebhookSetting,
): IEntityWebhookServerModel => ({
  deliveryTriggers: {
    asset: {
      actions: webhookSetting.triggers.assetTrigger.actions.map((a) => ({ action: a })),
      enabled: webhookSetting.triggers.assetTrigger.enabled,
    },
    contentItem: {
      actions: webhookSetting.triggers.contentItemTrigger.actions.map((a) => ({
        action: a,
        transitionTo:
          a === EntityWebhookAction.WorkflowStepChanged
            ? webhookSetting.triggers.contentItemTrigger.workflowSteps
            : null,
      })),
      enabled: webhookSetting.triggers.contentItemTrigger.enabled,
      filters: {
        collections: webhookSetting.triggers.contentItemTrigger.collectionFilters.map((c) => ({
          id: c,
        })),
        contentTypes: webhookSetting.triggers.contentItemTrigger.contentTypeFilters.map((c) => ({
          id: c,
        })),
        languages: webhookSetting.triggers.contentItemTrigger.languageFilters.map((l) => ({
          id: l,
        })),
      },
    },
    contentType: {
      actions: webhookSetting.triggers.contentTypeTrigger.actions.map((a) => ({ action: a })),
      enabled: webhookSetting.triggers.contentTypeTrigger.enabled,
      filters: {
        contentTypes: webhookSetting.triggers.contentTypeTrigger.contentTypeFilters.map((c) => ({
          id: c,
        })),
      },
    },
    events: webhookSetting.triggers.events,
    language: {
      actions: webhookSetting.triggers.languageTrigger.actions.map((a) => ({ action: a })),
      enabled: webhookSetting.triggers.languageTrigger.enabled,
      filters: {
        languages: webhookSetting.triggers.languageTrigger.languageFilters.map((l) => ({
          id: l,
        })),
      },
    },
    slot: webhookSetting.triggers.slot,
    taxonomy: {
      actions: webhookSetting.triggers.taxonomyTrigger.actions.map((a) => ({ action: a })),
      enabled: webhookSetting.triggers.taxonomyTrigger.enabled,
      filters: {
        taxonomies: webhookSetting.triggers.taxonomyTrigger.taxonomyFilters.map((t) => ({
          id: t,
        })),
      },
    },
  },
  enabled: webhookSetting.enabled,
  healthStatus: webhookSetting.healthStatus,
  id: webhookSetting.id,
  name: webhookSetting.name,
  secret: webhookSetting.secret,
  headers: webhookSetting.headers.map((h) => ({ key: h.key, value: h.value })),
  url: webhookSetting.url,
});
