export enum SquareButtonStyle {
  DestructivePrimary = 'btn--destructive-primary',
  DestructiveSecondary = 'btn--destructive-secondary',
  Primary = 'btn--primary',
  PrimaryInverted = 'btn--primary-inverted',
  Secondary = 'btn--secondary',
  SecondaryInverted = 'btn--secondary-inverted',
  Tertiary = 'btn--tertiary',
  Quinary = 'btn--quinary',
  None = '',
}
