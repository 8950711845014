import {
  IActiveCapabilities,
  createActiveCapabilitiesFromServerModel,
} from '../../../../data/models/activeCapabilities.ts';
import { IContentItemWithVariantServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { IContentItemVariant, mapContentItemVariantFromServerModel } from './ContentItemVariant.ts';
import {
  IEditedContentItem,
  mapEditedContentItemFromServerModel,
} from './edited/EditedContentItem.ts';

export interface IContentItemWithVariant {
  readonly item: IEditedContentItem;
  readonly variant: IContentItemVariant | null;
  readonly activeCapabilities: IActiveCapabilities;
}

export function mapContentItemWithVariantFromServerModel(
  contentItem: IContentItemWithVariantServerModel,
): IContentItemWithVariant {
  return {
    item: mapEditedContentItemFromServerModel(contentItem.item),
    variant: contentItem.variant && mapContentItemVariantFromServerModel(contentItem.variant),
    activeCapabilities: createActiveCapabilitiesFromServerModel(contentItem.activeCapabilities),
  };
}
