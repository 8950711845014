import { delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { ComponentLibraryGlobals } from '../globals/componentLibraryGlobals.ts';

type GetDelayInMs<TValue> = (currentValue: TValue, newValue: TValue) => number;

/**
 * Debounce propagation of the value. Using getDelayInMs argument you can set different delay in milliseconds based on the specific value change.
 * For static value debounce, use useDebounce from use-debounce package.
 */
export const useDynamicDebounce = <TValue>(
  value: TValue,
  getDelayInMs: GetDelayInMs<TValue>,
): TValue => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (currentValue !== value) {
      const ms = ComponentLibraryGlobals.reduceDelay ? 0 : getDelayInMs(currentValue, value);
      const promise = delay(ms)
        .then(() => setCurrentValue(value))
        .catch(swallowCancelledPromiseError);

      return () => promise.cancel();
    }

    return undefined;
  }, [currentValue, getDelayInMs, value]);

  return currentValue;
};
