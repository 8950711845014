import React, { useRef, useState } from 'react';
import { HandleClickOutside } from '../../../../../../../_shared/components/HandleClickOutside.tsx';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../../_shared/components/NotificationBar.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export type MenuOption = {
  readonly variantId: Uuid;
  readonly name: string;
  readonly isDisabled: boolean;
};

type IVariantQuickActionsNotificationBarDataProps = {
  readonly notificationBarTools: ReadonlyArray<React.ReactNode>;
  readonly notificationBarMessage: React.ReactNode | null;
};

type IVariantQuickActionsNotificationBarCallbackProps = {
  readonly onClose: () => void;
};

type IVariantQuickActionsNotificationBarProps = IVariantQuickActionsNotificationBarDataProps &
  IVariantQuickActionsNotificationBarCallbackProps;

export const VariantQuickActionsNotificationBar: React.FC<
  IVariantQuickActionsNotificationBarProps
> = ({ notificationBarTools, notificationBarMessage, onClose }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const innerRef = useRef<HTMLDivElement>(null);

  const closeMenu = (): void => {
    setIsMenuOpen(false);
  };

  if (!notificationBarTools?.length) {
    return null;
  }

  return (
    <HandleClickOutside observedRefs={[innerRef]} onClickOutside={closeMenu}>
      <NotificationBar
        ref={innerRef}
        message={notificationBarMessage}
        tools={notificationBarTools}
        type={NotificationBarType.Blank}
        onClosePanel={onClose}
        uiElement={DataUiElement.CopyFromLanguageNotification}
        className={isMenuOpen ? 'canvas__notification-pane--is-over-another' : undefined}
      />
    </HandleClickOutside>
  );
};

VariantQuickActionsNotificationBar.displayName = 'VariantQuickActionsNotificationBar';
