import { swallowAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import {
  populateProjectsToClone,
  projectCloningInitialized,
} from '../../../../applications/projects/actions/projectsActions.ts';
import { startPollingForProjectCopyState } from '../../../../applications/projects/actions/thunks/cloneProject.ts';
import { CopyState } from '../../../../applications/projects/constants/copyState.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';

interface IStartPollingProjectsDependencies {
  readonly projectRepository: IProjectRepository;
  readonly loadProjects: (abortSignal?: AbortSignal) => ThunkPromise;
}

export const createStartPollingProjectsAction =
  ({ projectRepository, loadProjects }: IStartPollingProjectsDependencies) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const projects = await projectRepository.getMyProjects(abortSignal);

    const cloningProjects = projects.filter(
      (project) =>
        (!!project && project.copyState === CopyState.CopyingDeliver) ||
        project.copyState === CopyState.CopyingDraft,
    );
    cloningProjects.map((project) => {
      if (project.copyState === CopyState.CopyingDeliver) {
        dispatch(populateProjectsToClone(project.projectId));
      } else {
        dispatch(projectCloningInitialized(project.projectId));
      }
    });

    dispatch(startPollingForProjectCopyState(projectRepository, loadProjects, abortSignal)).catch(
      swallowAbortError,
    );
  };
