export const ApiKeyDetail_Alert_Dismissed = 'ApiKeyDetail_Alert_Dismissed';
export const ApiKeyDetail_Creating_Finished = 'ApiKeyDetail_Creating_Finished';
export const ApiKeyDetail_Creating_Failed = 'ApiKeyDetail_Creating_Failed';
export const ApiKeyDetail_Creating_Started = 'ApiKeyDetail_Creating_Started';
export const ApiKeyDetail_OldDapiRegeneration_Finished =
  'ApiKeyDetail_OldDapiRegeneration_Finished';
export const ApiKeyDetail_OldDapiRegeneration_Failed = 'ApiKeyDetail_OldDapiRegeneration_Failed';
export const ApiKeyDetail_OldDapiRegeneration_Started = 'ApiKeyDetail_OldDapiRegeneration_Started';
export const ApiKeyDetail_Init_Started = 'ApiKeyDetail_Init_Started';
export const ApiKeyDetail_Init_Finished = 'ApiKeyDetail_Init_Finished';
export const ApiKeyDetail_Loading_Finished = 'ApiKeyDetail_Loading_Finished';
export const ApiKeyDetail_Loading_Failed = 'ApiKeyDetail_Loading_Failed';
export const ApiKeyDetail_Loading_Started = 'ApiKeyDetail_Loading_Started';
export const ApiKeyDetail_Updating_Finished = 'ApiKeyDetail_Updating_Finished';
export const ApiKeyDetail_Updating_Failed = 'ApiKeyDetail_Updating_Failed';
export const ApiKeyDetail_Updating_Started = 'ApiKeyDetail_Updating_Started';
export const ApiKeyDetail_Regeneration_Failed = 'ApiKeyDetail_Regeneration_Failed';
export const ApiKeyDetail_Regeneration_Finished = 'ApiKeyDetail_Regeneration_Finished';
export const ApiKeyDetail_Regeneration_Started = 'ApiKeyDetail_Regeneration_Started';
export const ApiKeyDetail_Revoke_Failed = 'ApiKeyDetail_Revoke_Failed';
export const ApiKeyDetail_Revoke_Finished = 'ApiKeyDetail_Revoke_Finished';
export const ApiKeyDetail_Revoke_Started = 'ApiKeyDetail_Revoke_Started';
export const ApiKeyDetail_RevokeAndRedirect_Finished = 'ApiKeyDetail_RevokeAndRedirect_Finished';
export const ApiKeyDetail_RevokeAndRedirect_Started = 'ApiKeyDetail_RevokeAndRedirect_Started';
export const ApiKeyDetail_SavingInterrupted_InvalidForm =
  'ApiKeyDetail_SavingInterrupted_InvalidForm';
export const ApiKeys_ApiStatus_Loaded = 'ApiKeys_ApiStatus_Loaded';
export const ApiKeysSettings_Init_Failed = 'ApiKeysSettings_Init_Failed';
export const ApiKeysSettings_Init_Finished = 'ApiKeysSettings_Init_Finished';
export const ApiKeysSettings_Init_Started = 'ApiKeysSettings_Init_Started';
export const ApiKeysSettings_Route_Left = 'ApiKeysSettings_Route_Left';
export const DapiKeyListing_Loading_Failed = 'DapiKeyListing_Loading_Failed';
export const DapiKeyListing_Loading_Finished = 'DapiKeyListing_Loading_Finished';
export const DapiKeyListing_Loading_Started = 'DapiKeyListing_Loading_Started';
export const MapiKeyDetail_Editor_NameLoaded = 'MapiKeyDetail_Editor_NameLoaded';
export const MapiKeyListing_Loading_Failed = 'MapiKeyListing_Loading_Failed';
export const MapiKeyListing_Loading_Finished = 'MapiKeyListing_Loading_Finished';
export const MapiKeyListing_Loading_Started = 'MapiKeyListing_Loading_Started';
export const ApiKeyListing_Route_Left = 'ApiKeyListing_Route_Left';
export const NewApiKeyDetail_Loading_Started = 'NewApiKeyDetail_Loading_Started';
export const NewApiKeyDetail_Loading_Finished = 'NewApiKeyDetail_Loading_Finished';
