import React from 'react';
import { SearchPhraseHighlighterElement } from '../Highlighting/SearchPhraseHighlighterElement.tsx';

interface IHighlightedOptionProps {
  readonly optionName: string;
  readonly pattern: string;
}

export const HighlightedOption: React.FC<IHighlightedOptionProps> = ({ optionName, pattern }) => {
  return <SearchPhraseHighlighterElement searchPhrase={pattern} text={optionName} />;
};

HighlightedOption.displayName = 'HighlightedOption';
