import React, { MouseEventHandler, RefObject } from 'react';
import { IconName } from '../constants/iconEnumGenerated.ts';
import { createDropDown } from '../uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownOptionSeparator } from '../uiComponents/DropDown/DropDownOptionSeparator.tsx';
import { IDropdownTippyOptions } from '../uiComponents/DropDown/dropDownTippyOptions.ts';
import { Icon } from '../uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export type ActionItem = {
  readonly dataUiAction?: DataUiAction;
  readonly disabledState?: IDisabledState;
  readonly hasCapabilityToExecuteAction: boolean;
  readonly isDestructive: boolean;
  readonly loading?: boolean;
  readonly onClick?: () => void;
  readonly text: string;
  readonly withSeparator?: boolean;
};

export interface IDisabledState {
  readonly disabledMessage?: string;
  readonly isDisabled: boolean;
}

interface IActionMenuStateProps {
  readonly actions: ReadonlyArray<ActionItem>;
  readonly renderToggleButton: (
    ref: RefObject<HTMLElement>,
    onClick: MouseEventHandler<HTMLElement>,
    isOpen: boolean,
  ) => React.ReactNode;
  readonly tippyOptions?: IDropdownTippyOptions;
}

const DropDown = createDropDown<ActionItem>();

export const ActionMenu: React.FC<IActionMenuStateProps> = (props) => {
  return (
    <DropDown
      hasTrailingElements
      optionListDataUiAttributes={getDataUiCollectionAttribute(
        DataUiCollection.MoreActionsDropdown,
      )}
      options={props.actions.filter((action) => action.hasCapabilityToExecuteAction)}
      renderSelectedOption={props.renderToggleButton}
      renderOption={(hideMenu, option: ActionItem) => {
        return [
          <DropDownOption
            onClick={() => {
              hideMenu();
              option.onClick?.();
            }}
            key={option.text}
            isDisabled={option.disabledState?.isDisabled || option.loading}
            tooltipText={option.disabledState?.disabledMessage}
            isDestructive={option.isDestructive}
            dataUiAttributes={getDataUiActionAttribute(option.dataUiAction)}
          >
            <DropDownOptionName text={option.text} />
            {option.loading && (
              <Icon
                iconName={IconName.Spinner}
                className="dropdown-option__icon dropdown-option__icon--loading"
              />
            )}
          </DropDownOption>,
          option.withSeparator && <DropDownOptionSeparator key="DropDownSeparator" />,
        ];
      }}
      tippyOptions={props.tippyOptions}
    />
  );
};
