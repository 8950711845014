import React from 'react';
import { DataUiAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { VariantQuickActionMenu } from './VariantQuickActionMenu.tsx';
import { MenuOption } from './VariantQuickActionsNotificationBar.tsx';

type CopyFromLanguageQuickMenuProps = {
  readonly menuOptions: ReadonlyArray<MenuOption>;
  readonly handleVariantSelect: (variantId: Uuid) => void;
};

export const CopyFromLanguageQuickMenu: React.FC<CopyFromLanguageQuickMenuProps> = ({
  handleVariantSelect,
  menuOptions,
}) => (
  <VariantQuickActionMenu
    buttonContent="Copy from language"
    dataUiAction={DataUiAction.CopyFromLanguage}
    disabledOptionTooltipText="You can only copy from language variants assigned to you."
    menuOptions={menuOptions}
    onVariantSelect={handleVariantSelect}
  />
);

CopyFromLanguageQuickMenu.displayName = 'CopyFromLanguageQuickMenu';
