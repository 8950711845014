import { Action } from '../../../../../@types/Action.type.ts';
import {
  Environment_DeleteEnvironment_ShowDialog,
  Environment_SwapEnvironment_ShowDialog,
} from '../../constants/environmentActionTypes.ts';

const initialState = '';

export const editedEnvironmentId = (state: Uuid = initialState, action: Action): Uuid => {
  switch (action.type) {
    case Environment_DeleteEnvironment_ShowDialog:
    case Environment_SwapEnvironment_ShowDialog:
      return action.payload.environmentId;

    default:
      return state;
  }
};
