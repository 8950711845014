import React, { memo } from 'react';
import { IAssetTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import { IAssetItemElement } from '../../../../../models/contentItemElements/AssetItemElement.ts';
import { ItemElement } from '../../../containers/elements/ItemElement.tsx';
import { ItemElementRefresher } from '../../../containers/elements/ItemElementRefresher.tsx';
import { AssetComponent } from '../../../containers/elements/asset/AssetComponent.tsx';
import { IItemElementComponentOwnProps } from '../IItemElementOwnProps.type.ts';

export type AssetProps = IItemElementComponentOwnProps<IAssetItemElement, IAssetTypeElement> & {
  readonly onChange: (elementData: IAssetItemElement) => void;
};

export const Asset: React.FC<AssetProps> = memo(
  ({ disabled, elementData, onChange, typeElement }) => {
    return (
      <ItemElementRefresher
        elementData={elementData}
        renderInput={(refresherItemElement: IAssetItemElement) => (
          <AssetComponent
            disabled={disabled}
            elementData={refresherItemElement}
            typeElement={typeElement}
            onChange={onChange}
            validationResultSelectorId={refresherItemElement.elementId}
            renderWrapper={(innerComponent) => (
              <ItemElement typeElement={typeElement} disabled={disabled}>
                {innerComponent}
              </ItemElement>
            )}
          />
        )}
      />
    );
  },
);

Asset.displayName = 'Asset';
