import { Collection } from '@kontent-ai/utils';
import React, { ReactNode } from 'react';
import { LoadedLinkedItemContent as LoadedLinkedItemContentComponent } from '../../components/linkedItems/LoadedLinkedItemContent.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { areCollectionsVisibleForSpaces } from '../../selectors/contentCollections.ts';

type Props = {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly collectionId: Uuid | undefined;
  readonly contentItemName: string;
  readonly contentTypeName: string;
  readonly hideDescription?: boolean;
  readonly isCompact?: boolean;
  readonly isInvalid: boolean | undefined;
  readonly isUnfinished?: boolean;
  readonly onClick?: (() => void) | undefined;
  readonly renderExpandToggle?: () => ReactNode;
  readonly renderWorkflowStep?: () => ReactNode;
};

export const LoadedLinkedItemContent: React.FC<Props> = ({
  cannotViewDisabledMessage,
  collectionId,
  contentItemName,
  contentTypeName,
  hideDescription,
  isCompact,
  isInvalid,
  isUnfinished,
  onClick,
  renderExpandToggle,
  renderWorkflowStep,
}) => {
  const areCollectionsVisible = useSelector((s) =>
    areCollectionsVisibleForSpaces(s, Collection.getValues(s.data.collections.byId)),
  );
  const collectionName = useSelector(
    (state) => collectionId && state.data.collections.byId.get(collectionId)?.name,
  );

  return (
    <LoadedLinkedItemContentComponent
      areCollectionsVisible={areCollectionsVisible}
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      collectionName={collectionName}
      contentItemName={contentItemName}
      contentTypeName={contentTypeName}
      hideDescription={hideDescription}
      isCompact={isCompact}
      isInvalid={isInvalid}
      isUnfinished={isUnfinished}
      onClick={onClick}
      renderExpandToggle={renderExpandToggle}
      renderWorkflowStep={renderWorkflowStep}
    />
  );
};

LoadedLinkedItemContent.displayName = 'LoadedLinkedItemContent';
