import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { FC } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly onClick: () => void;
  readonly text?: string;
}

export const ClearFilterButton: FC<IProps> = ({ onClick, text }) => (
  <Button
    buttonStyle="tertiary"
    size="small"
    onClick={onClick}
    {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
  >
    <Button.Icon icon={Icons.Times} />
    <Button.Label>{text || 'Clear'}</Button.Label>
  </Button>
);

ClearFilterButton.displayName = 'ClearFilterButton';
