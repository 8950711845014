import { ComponentProps } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableHeadRow } from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { YourContentTabName } from '../../../../_shared/constants/yourContentTabName.ts';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { YourContentTabSaver } from '../YourContentTabSaver.tsx';
import { YourTask, tableHeadColumns } from './YourTask.tsx';
import { YourTasksEmptyState } from './YourTasksEmptyState.tsx';

type TaskProps = Omit<ComponentProps<typeof YourTask>, 'onNavigateToTask'> & ContentItemId;

type Props = {
  readonly tasks: ReadonlyArray<TaskProps>;
  readonly tasksLoaded: boolean;
  readonly onNavigateToTask: (task: TaskProps) => void;
};

export const YourTasks = ({ tasks, tasksLoaded, onNavigateToTask }: Props) => {
  if (!tasksLoaded) {
    return (
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={
            <EmptyState>
              <Loader />
            </EmptyState>
          }
        />
      </section>
    );
  }

  return (
    <YourContentTabSaver tabName={YourContentTabName.YourTasks} itemCount={tasks.length}>
      <section>
        <DataTable
          noBoxShadow
          dataUiCollectionName={DataUiCollection.ContentItems}
          header={<DataTableHeadRow columns={tableHeadColumns} />}
          infoMessage={tasks.length === 0 ? <YourTasksEmptyState /> : undefined}
        >
          {tasks.map((task) => (
            <YourTask {...task} onNavigateToTask={() => onNavigateToTask(task)} key={task.id} />
          ))}
        </DataTable>
      </section>
    </YourContentTabSaver>
  );
};
