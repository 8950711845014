import React from 'react';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonStyle } from '../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';

export const PlaceholderOuterAction: React.FC = () => (
  <div className="bar-item__outer-action bar-item__outer-action--is-hidden">
    <SquareButton style={SquareButtonStyle.Secondary} iconName={IconName.Plus} ariaLabel="" />
  </div>
);

PlaceholderOuterAction.displayName = 'PlaceholderOuterAction';
