import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ISubscriptionRepository } from '../../../../../repositories/interfaces/ISubscriptionRepository.type.ts';
import {
  Subscription_BillingInformation_Loaded,
  Subscription_BillingInformation_LoadingFailed,
} from '../../constants/actionTypes.ts';
import {
  ISubscriptionBillingInformation,
  createSubscriptionBillingInformationDomainModel,
} from '../../models/SubscriptionBillingInformation.ts';

interface IDeps {
  readonly subscriptionRepository: ISubscriptionRepository;
}

const billingInformationLoaded = (billingInformation: ISubscriptionBillingInformation) =>
  ({
    type: Subscription_BillingInformation_Loaded,
    payload: { billingInformation },
  }) as const;

const billingInformationLoadingFailed = () =>
  ({
    type: Subscription_BillingInformation_LoadingFailed,
  }) as const;

export type LoadSubscriptionBillingInformationActionsType = ReturnType<
  typeof billingInformationLoaded | typeof billingInformationLoadingFailed
>;

export const loadSubscriptionBillingInformationCreator =
  ({ subscriptionRepository }: IDeps) =>
  (subscriptionId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    try {
      const response = await subscriptionRepository.getLatestBillingInformation(
        subscriptionId,
        abortSignal,
      );

      const billingInformation = createSubscriptionBillingInformationDomainModel(response);
      dispatch(billingInformationLoaded(billingInformation));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(billingInformationLoadingFailed());
      }

      throw error;
    }
  };
