import { getTopLevelContentComponentIdsFromElements } from '../../contentComponentUtils.ts';
import { ContentItemEditingActionAvailabilityProvider } from '../getAvailableContentItemEditingActions.ts';

export const isOpenComponentsAvailable: ContentItemEditingActionAvailabilityProvider = ({
  contentApp: { editedContentItemVariantElements, editorUi },
}) => {
  const topLevelContentComponentIds = getTopLevelContentComponentIdsFromElements(
    editedContentItemVariantElements,
  );
  return (
    !topLevelContentComponentIds.isEmpty() &&
    topLevelContentComponentIds.subtract(editorUi.collapsedContentComponents).isEmpty()
  );
};

export const isCloseComponentsAvailable: ContentItemEditingActionAvailabilityProvider = ({
  contentApp: { editedContentItemVariantElements, editorUi },
}) => {
  const topLevelContentComponentIds = getTopLevelContentComponentIdsFromElements(
    editedContentItemVariantElements,
  );
  return (
    !topLevelContentComponentIds.isEmpty() &&
    !topLevelContentComponentIds.subtract(editorUi.collapsedContentComponents).isEmpty()
  );
};
