import { combineReducers } from '@reduxjs/toolkit';
import { byId } from './reducers/byId.ts';
import { byIdLoadingStatus } from './reducers/byIdLoadingStatus.ts';
import { defaultLanguage } from './reducers/defaultLanguage.ts';
import { usages } from './reducers/usages.ts';
import { usagesLoadingStatus } from './reducers/usagesLoadingStatus.ts';

export const languages = combineReducers({
  byId,
  byIdLoadingStatus,
  defaultLanguage,
  usages,
  usagesLoadingStatus,
});
