import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { createTextElementErrorChecker } from '../../../../../itemEditor/utils/elementErrorCheckers/textElementErrorChecker.ts';
import { DefaultValueDoesNotMeetLimitations } from '../../../constants/errorMessageTemplates.ts';
import { ITextTypeElementData } from '../../../models/elements/TextTypeElementData.ts';
import { mapElementErrorToTypeElementErrorResult } from '../../../utils/typeElementValidators/mapElementErrorToTypeElementErrorResult.ts';
import { ITypeElementValidatorDependencies } from '../../../utils/typeElementValidators/types/ITypeElementValidatorDependencies.type.ts';
import { TextTypeElementValidationResult } from '../../../utils/typeElementValidators/types/TextTypeElementValidationResult.type.ts';
import { areLimitationsInTextDefaultValueMet } from '../../../utils/typeElementValidators/utils/areLimitationsInTextDefaultValueMet.ts';
import { createErrorMessagesFromConditionValidationResult } from '../../../utils/typeElementValidators/utils/conditions/createErrorMessagesFromConditionValidationResult.ts';
import {
  ConditionValidationData,
  getConditionValidationResult,
} from '../../../utils/typeElementValidators/utils/conditions/getConditionValidationResult.ts';
import { getBaseTextTypeElementValidationResult } from './validateBaseTextTypeElement.ts';
import { getTypeElementRegexValidationResult } from './validateTypeElementRegex.ts';

const getTextTypeElementValidator = (deps: ITypeElementValidatorDependencies) => {
  const getTextContentElementErrors = createTextElementErrorChecker(deps.ValidationConstants);

  return (
    typeElement: ITextTypeElementData,
    conditionValidationData: ConditionValidationData,
  ): TextTypeElementValidationResult => {
    const baseTextResult = getBaseTextTypeElementValidationResult(deps, typeElement, true);
    const regexValidationResult = getTypeElementRegexValidationResult(
      deps,
      typeElement.validationRegex,
    );

    const conditionValidationResult = getConditionValidationResult(
      typeElement,
      conditionValidationData,
    );

    const defaultValueResult = getTextContentElementErrors({
      value: typeElement.defaultValue,
    });

    const defaultValueMeetsLimitations = areLimitationsInTextDefaultValueMet(typeElement);
    const warnings: Array<string> = [];

    if (!defaultValueMeetsLimitations) {
      warnings.push(DefaultValueDoesNotMeetLimitations);
    }

    return {
      ...baseTextResult,
      conditionValidationResult,
      isDefaultValueValid: !defaultValueResult.errors.length,
      isRegexPatternValid: regexValidationResult.isRegexPatternValid,
      isRegexLengthValid: regexValidationResult.isRegexLengthValid,
      isRegexValidationMessageLengthValid:
        regexValidationResult.isRegexValidationMessageLengthValid,
      errorMessages: [
        ...createErrorMessagesFromConditionValidationResult(conditionValidationResult),
        ...baseTextResult.errorMessages,
        ...regexValidationResult.errorMessages,
        ...mapElementErrorToTypeElementErrorResult(defaultValueResult, typeElement.type)
          .errorMessages,
      ],
      warningMessages: warnings,
    };
  };
};

export const createValidateTextTypeElementAction =
  (deps: ITypeElementValidatorDependencies) =>
  (typeElement: ITextTypeElementData): ThunkFunction<TextTypeElementValidationResult> =>
  (dispatch, getState) => {
    const validate = getTextTypeElementValidator(deps);
    const validationResult = validate(typeElement, deps.getConditionValidationData(getState));

    dispatch(deps.typeElementValidated(typeElement.elementId, validationResult));
    return validationResult;
  };
