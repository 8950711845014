import { useMemo } from 'react';
import { loadListingItemsWithAllVariants } from '../../../data/actions/thunkDataActions.ts';
import { getActiveLanguagesWithFallbackChain } from '../../../data/reducers/languages/selectors/getLanguages.ts';
import { FallbacksPopoverContent as Component } from '../../components/FallbacksForLinkedContent/FallbacksPopoverContent.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';
import { getSelectedLanguageId } from '../../selectors/getSelectedLanguageId.ts';
import { getTranslatedVariants } from '../../selectors/getTranslatedVariants.ts';

type Props = {
  readonly itemId: Uuid;
  readonly onClose: () => void;
};

export const FallbacksPopoverContent = ({ itemId, onClose }: Props) => {
  const activeLanguages = useSelector((s) => getActiveLanguagesWithFallbackChain(s.data.languages));
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const selectedLinkedItem = useSelector((s) => s.data.listingContentItems.byId.get(itemId));
  const translatedVariants = useSelector((s) => getTranslatedVariants(itemId, s));

  const itemIds = useMemo(() => [itemId], [itemId]);
  useThunkPromise(loadListingItemsWithAllVariants, itemIds, { canRun: !translatedVariants });

  if (!selectedLinkedItem || !selectedLanguageId) {
    return null;
  }

  return (
    <Component
      activeLanguages={activeLanguages}
      translatedVariants={translatedVariants}
      selectedLinkedItem={selectedLinkedItem}
      selectedLanguageId={selectedLanguageId}
      onClose={onClose}
    />
  );
};
